import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Button, Paper, Divider } from "@material-ui/core";
import { resourceValidation } from "../../../shared/resource";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { postDHLRate, getDHLZoneList } from "../masterActions";
import { StateContext } from "../../../shared/globalState";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import InputAdornment from "@material-ui/core/InputAdornment";
import { addStyles } from "../../../shared/styles";

const checkrate = new Validator("checkdecimalpoint");

const date = new Date();
const year = date.getFullYear().toString();

class AddDHLRate extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      dhlRateValues: {
        dhlZone: {},
        dhlZoneID: "",
        dhlWeight: "0.000",
        dhlRate: "0.00",
        dhlYear: year,
        createdBy: 1,
      },
      dhlZoneList: [],
      errors: {},
      dhlZoneIDValid: false,
      dhlWeightValid: false,
      dhlRateValid: false,
      dhlYearValid: true,
      formValid: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    getDHLZoneList().then((r) => {
      r.data &&
        r.data.length > 0 &&
        this.setState({
          dhlZoneList: r.data.sort((a, b) =>
            a.dhlZoneCode.toLowerCase() > b.dhlZoneCode.toLowerCase() ? 1 : -1
          ),
        });
    });
  }

  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  checkDHlWeightDec1(value) {
    var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  handleChange = (event) => {
    const { dhlRateValues } = this.state;
    let dhlValues = event.target.value;
    dhlValues = this.checkDHlWeightDec(event.target.value.toString());

    dhlRateValues[event.target.name] = dhlValues;
    this.validateField(event.target.name, dhlValues);
    this.setState({ dhlRateValues });
  };
  handleChange1 = (event) => {
    const { dhlRateValues } = this.state;
    let dhlValues = event.target.value;
    dhlValues = this.checkDHlWeightDec1(event.target.value.toString());

    dhlRateValues[event.target.name] = dhlValues;
    this.validateField(event.target.name, dhlValues);
    this.setState({ dhlRateValues });
  };

  handledhlZoneChange = (event, values) => {
    const { dhlRateValues } = this.state;
    dhlRateValues["dhlZone"] = values;
    let dhlZoneID = values && values.dhlZoneID;

    this.validateField("dhlZone", dhlZoneID);
    this.setState({ dhlRateValues });
  };

  handleDateChange = (year) => {
    const { dhlRateValues } = this.state;
    let selectedYear = year && year.getFullYear().toString();
    if (selectedYear) {
      dhlRateValues["dhlYear"] = selectedYear;
      this.validateField("dhlYear", selectedYear);
      this.setState({ dhlRateValues });
    } else {
      this.setState({ dhlYearValid: false });
    }
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let dhlWeightValid = this.state.dhlWeightValid;
    let dhlRateValid = this.state.dhlRateValid;
    let dhlYearValid = this.state.dhlYearValid;
    let dhlZoneIDValid = this.state.dhlZoneIDValid;
    switch (fieldName) {
      case "dhlWeight":
        if (value !== "") {
          dhlWeightValid = checkrate(value);
          fieldValidationErrors.dhlWeight = dhlWeightValid
            ? ""
            : resourceValidation.dhlWeight;
        }
        break;
      case "dhlRate":
        if (value !== "") {
          dhlRateValid = checkrate(value);
          fieldValidationErrors.dhlRate = dhlRateValid
            ? ""
            : resourceValidation.dhlRate;
        }

        break;
      case "dhlYear":
        if (value !== "") {
          dhlYearValid = true;
          fieldValidationErrors.dhlYear = dhlYearValid
            ? ""
            : " Please select year";
        } else {
          dhlYearValid = false;
          fieldValidationErrors.dhlYear = dhlYearValid
            ? ""
            : " Please select year";
        }
        break;
      case "dhlZone":
        if (value !== "") {
          value === null ? (dhlZoneIDValid = false) : (dhlZoneIDValid = true);

          fieldValidationErrors.dhlZone = dhlZoneIDValid
            ? ""
            : "Please select dhl zone";
        }
        break;
      default:
        break;
    }

    this.setState(
      {
        errors: fieldValidationErrors,
        dhlWeightValid: dhlWeightValid,
        dhlRateValid: dhlRateValid,
        dhlYearValid: dhlYearValid,
        dhlZoneIDValid: dhlZoneIDValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.dhlWeightValid &&
        this.state.dhlRateValid &&
        this.state.dhlYearValid &&
        this.state.dhlZoneIDValid,
    });

    return this.state.formValid;
  }

  onBlur = (event) => {
    const { dhlRateValues } = this.state;
    let dhlValues = event.target.value;
    dhlValues = this.addZeroes(dhlValues.toString(), 2);
    dhlRateValues[event.target.name] = dhlValues;
    this.setState({ dhlRateValues });
  };
  onBlur1 = (event) => {
    const { dhlRateValues } = this.state;
    let dhlValues = event.target.value;
    dhlValues = this.addZeroes(dhlValues.toString(), 3);
    dhlRateValues[event.target.name] = dhlValues;
    this.setState({ dhlRateValues });
  };
  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      const { dhlRateValues } = this.state;
      let dhlZoneID = dhlRateValues.dhlZoneID;

      if (dhlRateValues.dhlZone != null) {
        dhlZoneID = dhlRateValues.dhlZone.dhlZoneID;
      }
      let dhlWeight = dhlRateValues.dhlWeight;
      let dhlRate = dhlRateValues.dhlRate;
      let dhlYear = dhlRateValues.dhlYear;
      let createdBy = dhlRateValues.createdBy;

      // dhlWeight = this.addZeroes(dhlWeight.toString(), 2);
      // dhlRate = this.addZeroes(dhlRate.toString(), 2);

      let reqBody = { dhlZoneID, dhlWeight, dhlRate, dhlYear, createdBy };
      postDHLRate(reqBody, this.props.history);
    }
  };
  render() {
    let { dhlRateValues, dhlZoneList } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New DHL Rate</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    required
                    includeInputInList
                    id="dhlZone"
                    options={dhlZoneList}
                    className={classes.comboBox}
                    onChange={this.handledhlZoneChange}
                    value={dhlRateValues.dhlZone}
                    getOptionLabel={(option) => option.dhlZoneName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="DHL Zone"
                        style={{ width: "100%" }}
                        name="dhlZone"
                        id="dhlZone"
                        margin="normal"
                        error={this.state.errors.dhlZone}
                        helperText={
                          this.state.errors.dhlZone
                            ? this.state.errors.dhlZone
                            : ""
                        }
                      />
                    )}
                  />
                  <TextField
                    required
                    label="DHL Weight(Kg)"
                    id="txtdhlWeight"
                    name="dhlWeight"
                    onKeyUp={this._onDecimalKeyPress}
                    className={classes.textField}
                    inputProps={{
                      maxLength: 8,
                    }}
                    margin="normal"
                    autoComplete="off"
                    value={dhlRateValues.dhlWeight}
                    error={this.state.errors.dhlWeight}
                    onChange={this.handleChange}
                    onBlur={this.onBlur1}
                    helperText={
                      this.state.errors.dhlWeight
                        ? this.state.errors.dhlWeight
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Kg</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    required
                    label="DHL Rate($S)"
                    id="txtdhlRate"
                    name="dhlRate"
                    placeholder="DHL Rate"
                    autoComplete="off"
                    inputProps={{
                      maxLength: 8,
                    }}
                    onKeyPress={this._onDecimalKeyPress}
                    className={classes.textField}
                    margin="normal"
                    value={dhlRateValues.dhlRate}
                    error={this.state.errors.dhlRate}
                    onChange={this.handleChange1}
                    onBlur={this.onBlur}
                    helperText={
                      this.state.errors.dhlRate ? this.state.errors.dhlRate : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$S</InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    views={["year"]}
                    required
                    className={classes.KeyboardDatePicker}
                    label="DHL Year"
                    id="txtdhlYear"
                    name="dhlYear"
                    margin="normal"
                    value={dhlRateValues.dhlYear}
                    onChange={this.handleDateChange}
                  />
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/dhlRate")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddDHLRate));
