import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Button, Paper, Divider } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import {
  postStockOut,
  getCustomerFromItemMaster,
  getStockInByCustIdList,
  getStockOutsBQList,
} from "../genericActions";
import { StateContext } from "../../../shared/globalState";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Autocomplete } from "@material-ui/lab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";

const Reorderqty = new Validator("Reorderqty");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  comboBox: {
    margin: "normal",
    width: "100%",
    fontSize: "11px !important",
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
    display: "flex",

    flex: 1,
    alignItems: "left",
    overflow: "hidden",
  },
  textField1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "31%",
    fontSize: "11px !important",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
  table: {
    minWidth: 650,
  },
});

const StockTable = (props) => {
  const { stockInList } = props;
  return (
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell className={"headcells"}>
            {" "}
            <span style={{ color: "#306dca" }}>Stock</span>
          </TableCell>
          <TableCell className={"headcells"}>
            <span style={{ color: "#306dca" }}>Warehouse Location</span>
          </TableCell>
          <TableCell className={"headcells"}>
            <span style={{ color: "#306dca" }}>Received Date</span>
          </TableCell>
          <TableCell className={"headcells"}>
            <span style={{ color: "#306dca" }}>Balance Qty</span>
          </TableCell>
          <TableCell className={"headcells"}>
            <span style={{ color: "#306dca" }}>Stock Out Qty</span>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {stockInList &&
          stockInList.map((row) => (
            <TableRow key={row.name}>
              <TableCell
                component="th"
                scope="row"
                padding="default"
                className="table-content-cell"
              >
                {row.stockCode}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                padding="default"
                className="table-content-cell"
                style={{ minWidth: "150px" }}
              >
                {row.warehouseLocationName}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                padding="default"
                className="table-content-right-cell"
              >
                {" "}
                {moment(row.receivedDate).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                padding="default"
                className="table-content-right-cell"
              >
                {row.stockOutQty}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                padding="default"
                className="table-content-right-cell"
              >
                {row.balanceQty}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

class AddStockOut extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      stockOut: {
        customer: "",
        customerID: "",
        Stocks: "",
        stockID: "",
        stockQty: "0",
        stockDate: new Date().toLocaleDateString(),
        reason: "",
        createdBy: localStorage.loggedInId,
        isActive: true,
      },
      stockInList: [],
      customerList: [],
      ItemMasterList: [],
      errors: {},
      StockIDValid: false,
      StockIndentValid: false,
      StockQtyValid: false,
      customerIDValid: false,
      ReasonValid: false,
      formValid: false,
      stockInListLoading: false,
      enableSearchStock: false,
      sumOfStockOutQty: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({ ItemMasterList: [] });
    getCustomerFromItemMaster().then((r) => {
      this.getAllCustomers(r.data);
    });
  }

  getAllCustomers = (list) => {
    let customerList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        customerList.findIndex((p) => p.id === c.id) > -1
          ? customerList
          : customerList.push(c)
      );

    this.setState({
      customerList:
        customerList &&
        customerList.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };

  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleChange = (event) => {
    const { stockOut } = this.state;
    let StockValues = event.target.value;
    stockOut[event.target.name] = StockValues;
    this.validateField(event.target.name, StockValues);
    this.setState({ stockOut });
    if (event.target.name === "stockQty")
      this.handleStockInList();
  };

  handleStockChange = (event, values) => {
    const { stockOut } = this.state;

    let stockID = values && values.stockID;
    stockOut["Stocks"] = values;
    stockOut["stockID"] = stockID;

    this.validateField("Stocks", stockID);
    if (
      !this.state.StockQtyValid ||
      this.state.stockOut.stockQty === "" ||
      this.state.stockOut.stockQty !== 0 ||
      this.state.stockOut.stockQty !== "0"
    ) {
      this.setState({ stockOut, enableSearchStock: true });
    } else {
      this.setState({ stockOut, enableSearchStock: false });
    }
  };

  handleCustomerChange = (event, values) => {
    const { stockOut, ItemMasterList } = this.state;

    let customerID = values && values.id;
    stockOut["customer"] = values;
    stockOut["customerID"] = customerID;

    getStockInByCustIdList(customerID).then((r) => {
      this.setState({
        ItemMasterList:
          r.data &&
          r.data.sort((a, b) =>
            a.stockCode.toLowerCase() > b.stockCode.toLowerCase() ? 1 : -1
          ),
      });
    });
    stockOut["Stocks"] = null;
    stockOut["stockID"] = "";
    stockOut["stockQty"] = "";
    //this.validateField("stockQty", "");
    this.validateField("stockID", null);
    this.validateField("customerID", customerID);
    this.setState({ stockOut, ItemMasterList });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let StockIndentValid = this.state.StockIndentValid;
    let StockQtyValid = this.state.StockQtyValid;
    let customerIDValid = this.state.customerIDValid;
    let StockIDValid = this.state.StockIDValid;
    let ReasonValid = this.state.ReasonValid;
    switch (fieldName) {
      case "customerID":
        if (value && value !== "") {
          customerIDValid = true;
          fieldValidationErrors.customerID = customerIDValid ? "" : "";
        } else {
          customerIDValid = false;
          fieldValidationErrors.customerID = customerIDValid
            ? ""
            : "Please select customer";
        }
        break;
      case "reason":
        if (value && value !== "") {
          ReasonValid = true;
        } else {
          ReasonValid = false;
        }

        fieldValidationErrors.reason = ReasonValid ? "" : "Please enter reason";

        break;
      case "stockQty":
        if (value === "0") {
          StockQtyValid = false;
          fieldValidationErrors.stockQty = StockQtyValid
            ? ""
            : "Please enter valid Stock Out Quantity";
        } else {
          //StockQtyValid = Reorderqty(value);
          if (
            this.state.sumOfStockOutQty > 0 &&
            parseInt(value) > this.state.sumOfStockOutQty
          ) {
            StockQtyValid = false;
            fieldValidationErrors.stockQty = StockQtyValid
              ? ""
              : "StockOut quantity should not exceed " +
              this.state.sumOfStockOutQty;
          } else {
            fieldValidationErrors.stockQty = StockQtyValid
              ? ""
              : "Please enter Stock Out Quantity";
          }
        }

        break;
      case "Stocks":
        if (value && value !== "") {
          StockIDValid = true;
          fieldValidationErrors.stockID = StockIDValid ? "" : "";
        } else {
          StockIDValid = false;
          fieldValidationErrors.stockID = StockIDValid
            ? ""
            : "Please select Stock";
        }

        break;
      // case 'Stocks':
      //   if (value != "") {
      //     StockIDValid = true;
      //     fieldValidationErrors.Stocks = StockIDValid ? '' : resourceValidation.stockoutid;
      //   }

      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        StockIDValid: StockIDValid,
        StockIndentValid: StockIndentValid,
        customerIDValid: customerIDValid,
        StockQtyValid: StockQtyValid,
        ReasonValid: ReasonValid,
      },
      this.validateForm
      // this.validateSearchStock
    );
  }

  onBlur = (event) => {
    this.handleStockInList();
  };

  validateForm() {
    this.setState({
      formValid:
        this.state.StockIDValid &&
        this.state.customerIDValid &&
        this.state.StockQtyValid &&
        this.state.stockOut.stockQty > 0 &&
        this.state.ReasonValid,
    });
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      stockID: "",
      stockIndentID: "",
      reason: "",
      stockDate: "",
      stockQty: "",
      errors: {},
    });
  };

  handleStockInList = () => {
    let errorThrown = false;
    const { stockOut } = this.state;
    let fieldValidationErrors = this.state.errors;

    if (!this.state.StockIDValid) {
      fieldValidationErrors.stockID = "Please select Stock";
      errorThrown = true;
    }

    if (!this.state.customerIDValid) {
      fieldValidationErrors.customerID = "Please select Customer";
      errorThrown = true;
    }

    if (
      // !this.state.StockQtyValid ||
      this.state.stockOut.stockQty === "" ||
      this.state.stockOut.stockQty === "0" ||
      this.state.stockOut.stockQty === 0
    ) {
      fieldValidationErrors.stockQty = "Please enter valid Stockout Quantity";
      errorThrown = true;
    }
    this.setState({ errors: fieldValidationErrors });

    if (!errorThrown) {
      let sumOfQty = 0;
      let stockMasterID = stockOut.stockID;
      let stockOutQty = stockOut.stockQty;
      let reqBody = { stockMasterID, stockOutQty };
      getStockOutsBQList(reqBody).then((r) => {
        // r && r.data &&
        //   this.setState({
        //     stockInList: r.data,
        //     sumOfStockOutQty: sumOfQty,
        //     // errors: fieldValidationErrors,
        //     // StockQtyValid: true,
        //   });
        r.data &&
          r.data.forEach((stockIn, i) => {
            sumOfQty = sumOfQty + stockIn.stockOutQty;
          });
        if (parseInt(stockOutQty) > sumOfQty) {
          if (sumOfQty === 0) {
            fieldValidationErrors.stockQty = "StockOut quantity not available";
          } else {
            fieldValidationErrors.stockQty =
              "StockOut quantity should not exceed " + sumOfQty;
          }

          this.setState({
            stockInList: [],
            sumOfStockOutQty: sumOfQty,
            StockQtyValid: false,
            errors: fieldValidationErrors,
            formValid: false,
          });
        } else {
          fieldValidationErrors.stockQty = "";
          this.setState({
            stockInList: r.data,
            sumOfStockOutQty: sumOfQty,
            errors: fieldValidationErrors,
            StockQtyValid: true,
            // formValid: true,
          });
          if (this.state.stockOut.reason) {
            this.setState({
              formValid: true
            })
          }
        }
      });
    } else {
      this.setState({
        stockInList: [],
        sumOfStockOutQty: 0,
        errors: fieldValidationErrors,
        formValid: false,
      });
    }
  };

  handleSubmit = (event) => {
    const { stockOut, stockInList } = this.state;
    let stockMasterID = stockOut.stockID.toString();
    let customerID = stockOut.customerID;
    let stockOutQty = stockOut.stockQty;
    let stockOutdate = stockOut.stockDate;
    let reason = stockOut.reason;
    let createdBy = localStorage.loggedInId;
    let isActive = stockOut.isActive;
    let stockInDetails = [];
    stockInList.forEach((stockIn, i) => {
      if (stockIn.balanceQty > 0) {
        stockInDetails.push(stockIn);
      }
    });

    let reqBody = {
      stockInDetails,
      stockMasterID,
      customerID,
      stockOutQty,
      stockOutdate,
      reason,
      isActive,
      createdBy,
    };

    postStockOut(reqBody, this.props.history, window.localStorage.token);
  };
  render() {
    // console.log(this.state.sumOfStockOutQty)
    let {
      stockOut,
      ItemMasterList,
      customerList,
      enableSearchStock,
    } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Stock Out</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div
                  style={{ display: "flex", marginLeft: "0px", width: "100%" }}
                >
                  <TextField
                    required
                    id="stockOutDate"
                    label="Stock Out Date"
                    variant="filled"
                    value={moment(stockOut.stockDate).format("DD/MM/YYYY")}
                    className={classes.textField1}
                    margin="normal"
                    style={{ width: "46.5%" }}
                    readOnly
                  />
                </div>
                <div
                  style={{ display: "flex", marginLeft: "8px", width: "100%" }}
                >
                  <Autocomplete
                    autoComplete
                    className={classes.comboBox}
                    id="customerID"
                    includeInputInList
                    options={customerList}
                    value={stockOut.customer}
                    onChange={this.handleCustomerChange}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        autoFocus
                        {...params}
                        label="Customer"
                        className={classes.textField}
                        name="customerID"
                        margin="normal"
                        style={{ marginLeft: "0px" }}
                        required
                        error={this.state.errors.customerID}
                        helperText={
                          this.state.errors.customerID
                            ? this.state.errors.customerID
                            : ""
                        }
                      />
                    )}
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="Stocks"
                    className={classes.comboBox}
                    options={ItemMasterList}
                    style={{ margin: "0 30px" }}
                    onChange={this.handleStockChange}
                    value={stockOut.Stocks}
                    getOptionLabel={(option) => option.stockCode}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Stock Code"
                        name="Stocks"
                        id="Stocks"
                        className={classes.textField}
                        style={{ width: "100%" }}
                        margin="normal"
                        error={this.state.errors.stockID}
                        helperText={
                          this.state.errors.stockID
                            ? this.state.errors.stockID
                            : ""
                        }
                      />
                    )}
                  />
                </div>
                <div style={{ display: "flex", width: "48.2%" }}>
                  {" "}
                  <TextField
                    required
                    label="Stock Out Quantity"
                    id="stockQty"
                    name="stockQty"
                    onKeyPress={this._onNumberKeyPress}
                    className={classes.textField}
                    inputProps={{
                      maxLength: 10,
                    }}
                    style={{}}
                    margin="normal"
                    autoComplete="off"
                    onBlur={this.onBlur}
                    onChange={this.handleChange}
                    value={stockOut.stockQty}
                    error={this.state.errors.stockQty}
                    helperText={
                      this.state.errors.stockQty
                        ? this.state.errors.stockQty
                        : ""
                    }
                  />
                </div>
                {this.state.stockInListLoading && <LinearProgress />}
                <div
                  style={{ display: "flex", marginLeft: "8px", width: "100%" }}
                >
                  <TextField
                    required
                    label="Reason"
                    id="reason"
                    name="reason"
                    placeholder="reason"
                    autoComplete="off"
                    style={{ display: "flex", marginLeft: "0px", width: "47%" }}
                    inputProps={{
                      maxLength: 50,
                    }}
                    className={classes.textField1}
                    margin="normal"
                    value={stockOut.reason}
                    error={this.state.errors.reason}
                    onChange={this.handleChange}
                    helperText={
                      this.state.errors.reason ? this.state.errors.reason : ""
                    }
                  />
                </div>
                <Paper
                  style={{
                    boxShadow: "none",
                    marginTop: "10px",
                    marginLeft: "7px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      // border: "1px solid #eaeaea",
                      // borderBottom: "none",
                      // borderRadius: "4px",
                      margin: "4 .8em 5 .6em",
                    }}
                  >
                    {/* <div>
                      {!this.state.enableSearchStock ? (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            boxShadow: "none",
                            fontSize: "11px",
                            marginBottom: "10px",
                          }}
                          onClick={this.handleStockInList}
                          // style={
                          //   enableSearchStock
                          //     ? {
                          //         color: "#fff",
                          //         background: "#3982ea",
                          //       }
                          //     : { color: "#3982ea", fontSize: "11px" }
                          // }
                        >
                          <SearchIcon style={{ fontSize: "18px" }} /> Search
                          Stock{" "}
                        </Button>
                      ) : (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{
                            boxShadow: "none",
                            padding: "2px",
                            margin: ".6em",
                            fontSize: "11px",
                          }}
                          onClick={this.handleStockInList}
                          // style={
                          //   enableSearchStock
                          //     ? {
                          //         color: "#fff",
                          //         background: "#3982ea",
                          //       }
                          //     : { color: "#3982ea", fontSize: "11px" }
                          // }
                        >
                          <SearchIcon style={{ fontSize: "18px" }} /> Search
                          Stock{" "}
                        </Button>
                      )}
                    </div> */}
                  </div>
                  <Divider
                    style={{
                      marginTop: "3px",
                      border: "solid 0px #979797",
                      height: "1px",
                      width: "100%",
                    }}
                  />
                  <div
                    style={{
                      maxHeight: "700px",
                      width: "70vw",
                      //  margin: "0 .8em 0 .6em"
                    }}
                  >
                    <div style={{ maxHeight: 650, overflow: "auto" }}>
                      <StockTable stockInList={this.state.stockInList} />
                    </div>
                  </div>
                </Paper>

                <Divider
                  style={{
                    marginTop: "25px",
                  }}
                />
                {/* 
                <Divider
                  style={{
                    marginTop: "10px",
                    border: "rgba(0, 0, 0, 0.12)",
                    height: "1px",
                    width: "100%"
                  }}
                /> */}
                <div
                  style={{ display: "flex", marginLeft: "6px", width: "100%" }}
                >
                  <div className="button-wrapper">
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={(e) => this.props.history.push("/stockOut")}
                      >
                        CANCEL
                      </Button>
                    </div>
                    <div style={{ marginLeft: "1em" }}>
                      {!this.state.formValid ? (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                            onClick={this.handleSubmit}
                          >
                            SUBMIT
                          </Button>
                        )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(AddStockOut));
