import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { resourceValidation } from "../../../shared/resource";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { postProcessType } from "../../masters/masterActions";
import { addStyles } from "../../../shared/styles";
const validateNotRequired100 = new Validator("maxlength100AllAllow");
const validateCode = new Validator("maxlengthWithNumber4");

class AddProcessType extends React.Component {
  constructor() {
    super();

    this.state = {
      processType: {
        processTypeName: "",
        processTypeCode: "",
      },
      errors: {},
      processTypeNameValid: false,
      processTypeCodeValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.handleSettingHeader("Process Type");
  }

  handleChange = (event) => {
    const { processType } = this.state;

    processType[event.target.name] = event.target.value;
    if (event.target.name === "processTypeCode")
      processType[event.target.name] = event.target.value.toUpperCase();

    this.setState({ processType });

    this.validateField(event.target.name, event.target.value);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let processTypeNameValid = this.state.processTypeNameValid;
    let processTypeCodeValid = this.state.processTypeCodeValid;
    switch (fieldName) {
      case "processTypeName":
        if (value !== "") {
          processTypeNameValid = validateNotRequired100(value);
          fieldValidationErrors.processTypeName = processTypeNameValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          processTypeNameValid = false;
          fieldValidationErrors.processTypeName = "";
        }
        break;
      case "processTypeCode":
        if (value !== "") {
          processTypeCodeValid = validateCode(value);
          fieldValidationErrors.processTypeCode = processTypeCodeValid
            ? ""
            : resourceValidation.onlyAlphanumeric;
        } else {
          processTypeCodeValid = false;
          fieldValidationErrors.processTypeCode = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        processTypeNameValid: processTypeNameValid,
        processTypeCodeValid: processTypeCodeValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.processTypeNameValid && this.state.processTypeCodeValid,
    });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      processTypeName: "",
      processTypeCode: "",
      errors: {},
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      const { processType } = this.state;

      let processTypeName = processType.processTypeName;
      let processTypeCode = processType.processTypeCode;
      let createdBy = localStorage.loggedInId;

      let reqBody = { processTypeName, processTypeCode, createdBy };
      postProcessType(reqBody).then((r) => {
        this.props.history.push("/ProcessType");
        //toastr.success("ProcessType Created Successfully");
      });
    }
  };
  render() {
    let { classes } = this.props;
    let { processType } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Process Type</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    label="Process Type Code"
                    name="processTypeCode"
                    inputProps={{
                      maxLength: 3,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={processType.processTypeCode}
                    onChange={this.handleChange}
                    error={this.state.errors.processTypeCode}
                    helperText={
                      this.state.errors.processTypeCode
                        ? this.state.errors.processTypeCode
                        : ""
                    }
                  />
                  <TextField
                    required
                    label="Process Type Name"
                    name="processTypeName"
                    inputProps={{
                      maxLength: 50,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={processType.processTypeName}
                    onChange={this.handleChange}
                    error={this.state.errors.processTypeName}
                    helperText={
                      this.state.errors.processTypeName
                        ? this.state.errors.processTypeName
                        : ""
                    }
                  />
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/processType")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddProcessType));
