import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import { getUserDetails } from "../userAccessAction";
import { printDetails } from "../../../shared/tableCommonFunctions";
import { StateContext } from "../../../shared/globalState";

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { selectedUserID } = props;
  let acnArr = props.roles.filter((u) => u.menuName === "User");
  let acnObj = acnArr[0];

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_user/${selectedUserID}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewUser extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      user: {
        fName: "",
        mName: "",
        lName: "",
        loginUserID: "",
        isSingpostUser: "",
        customerID: "",
        customer: {},
        customerPPIID: "",
        customerPPI: {},
        role: {},
        roleName: "",
        departmentID: "",
        department: {},
        eMailID: "",
        isActive: true,
      },
    };
  }

  componentDidMount() {
    this.props.handleSettingHeader("User");
    const dispatch = this.context[1];
    let userId = this.props.match.params.userId;

    getUserDetails(userId).then((r) => {
      dispatch({
        type: "getUserDetails",
        newStore: r,
      });
      this.setState({ user: r });
      this.setState({
        roleID: r.role && r.role.roleID,
        departmentID: r.department && r.department.departmentID,
      });

      r.customer && this.setState({ customerID: r.customer.customerCode });
      r.customerPPI &&
        this.setState({ customerPPIID: r.customerPPI.ppiNumber });
    });
  }

  render() {
    let { user } = this.state;
    let userId = this.props.match.params.userId;

    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selectedUserID={userId}
            roles={this.props.roles}
          />
          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">User Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">First Name</div>
                <div className="view-page-value">{user.fName}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Middle Name</div>
                <div className="view-page-value">{user.mName}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Last Name</div>
                <div className="view-page-value">{user.lName}</div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Email-Id</div>
                <div className="view-page-value">{user.eMailID}</div>
              </div>
            </div>
            <div className="view-page-headings">Login Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Username</div>
                <div className="view-page-value">{user.loginUserID}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Role</div>
                <div className="view-page-value">
                  {user.role && user.role.roleName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Department</div>
                <div className="view-page-value">
                  {user.department && user.department.departmentName}
                </div>
              </div>
            </div>
            {user.customer && user.customerPPI ? (
              <div>
                <div className="view-page-contents-container">
                  <div className="view-page-contents-sub-container">
                    <div className="view-page-label">Customer</div>
                    <div className="view-page-value">
                      {user.customer.customerCode}
                    </div>
                  </div>
                  <div className="view-page-contents-sub-container">
                    <div className="view-page-label">CustomerPPI</div>
                    <div className="view-page-value">
                      {user.customerPPI.ppiNumber}
                    </div>
                  </div>
                  <div className="view-page-contents-sub-container">
                    <div className="view-page-label">SingpostUser</div>
                    <div className="view-page-value">
                      {user.isSingpostUser === true ? "Yes" : "No"}
                    </div>
                  </div>
                </div>
                <div className="view-page-contents-container">
                  <div className="view-page-contents-sub-container">
                    <div className="view-page-label">Active</div>
                    <div className="view-page-value">
                      {user.isActive === true ? "Yes" : "No"}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">SingpostUser</div>
                  <div className="view-page-value">
                    {user.isSingpostUser === true ? "Yes" : "No"}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Ative</div>
                  <div className="view-page-value">
                    {user.isActive === true ? "Yes" : "No"}
                  </div>
                </div>
              </div>
            )}{" "}
            <Divider style={{ marginTop: "25px" }} />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.props.history.push("/user")}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewUser);
