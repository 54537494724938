import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { StateContext } from "../../../shared/globalState";
import { styles } from "../../../shared/styles";

import {
  getWareLocationDD,
  getRoutingCustomerList,
  getLocationDropdownList,
  getCountryDropdownList,
  getDispatchModeList,
  getDDistributorList,
  getMailingSchemeLists,
} from "../../masters/masterActions";
// import { getStatusForPreAlertList, getCustomerForPreAlertList } from "./action";
//import { getCustomerList, serchCustomer } from "../masterActions";
const notRequiredAlpNum10 = new Validator("notRequiredAlpNum10");
const notRequired100AllAllow = new Validator("notRequired100AllAllow");
const maxlength10Alphanumaric = new Validator("maxlength10Alphanumaric");

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {false ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
class EmloStockinAdvanceSearch extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    locationID: 0,
    location: "",
    country: "",
    countryID: 0,
    customer: "",
    customerID: 0,
    mailingID: 0,
    mailing: "",
    dispatch: "",
    dispatchID: 0,
    distributor: "",
    distributorID: 0,
    carrierSheetMode: "",
    carrierSheetScheme: "",
    routePPI: "",
    modifiedDateTo: null,
    modifiedDateFrom: null,
    customersList: [],
    LocationList: [],
    countryList: [],
    DispatchModeList: [],
    DistributorList: [],
    mailingSchemeList: [],

    openDialog: false,
  };

  componentDidMount() {
    const [{ store }, dispatch] = this.context;
    // this.props.handleSettingHeader("Routing UK US");
    // this.props.setSelectedList("masterDataOpen", "routingUKUS");
    getMailingSchemeLists().then((r) => {
      r.data &&
        this.setState({
          mailingSchemeList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              //a.countryName.toLowerCase() > b.countryName.toLowerCase() ? 1 : -1
              a.mailingScheme.toLowerCase() > b.mailingScheme.toLowerCase()
                ? 1
                : -1
            ),
        });
    });
    getCountryDropdownList().then((r) => {
      r.data &&
        this.setState({
          countryList: r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
        });
    });
    getRoutingCustomerList(window.localStorage.token).then((r) => {
      let list = r.data;

      list &&
        list.length > 0 &&
        this.setState({
          customersList: list.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
        });
    });
    getLocationDropdownList(window.localStorage.token).then((r) => {
      let list = r.data;
      list &&
        list.length > 0 &&
        this.setState({
          LocationList: list.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
        });
    });
    getDispatchModeList().then((r) => {
      r.data &&
        this.setState({
          DispatchModeList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.dispatchModeName.toLowerCase() >
              b.dispatchModeName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });
    getDDistributorList().then((r) => {
      r.data &&
        this.setState({
          DistributorList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.distributorName.toLowerCase() > b.distributorName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      open: false,
      locationID: "",
      location: "",
      country: "",
      countryID: "",
      customer: "",
      customerID: "",
      mailingID: "",
      mailing: "",
      dispatch: "",
      dispatchID: "",
      distributor: "",
      distributorID: "",
      carrierSheetMode: "",
      carrierSheetScheme: "",
      routePPI: "",
      modifiedDateTo: null,
      modifiedDateFrom: null,
      customersList: [],
      LocationList: [],
      countryList: [],
      DispatchModeList: [],
      DistributorList: [],
      mailingSchemeList: [],

      openDialog: false,
    });
  };
  _onKeyPressNoOnly(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  onSearchClicked = () => {
    let reqBody = {};

    reqBody = {
      customerID: this.state.customerID,
      locationID: this.state.locationID,

      ppiNumber: this.state.routePPI,
      dispatchModeID: this.state.dispatchID,
      mailingSchemeID: this.state.mailingID,
      distributorID: this.state.distributorID,

      carrierSheetScheme: this.state.carrierSheetScheme,
      carrierSheetMode: this.state.carrierSheetMode,
    };

    this.props.searchPopup(reqBody);
    this.setState({
      open: false,
      locationID: "",
      location: "",
      country: "",
      countryID: "",
      customer: "",
      customerID: "",
      mailingID: "",
      mailing: "",
      dispatch: "",
      dispatchID: "",
      distributor: "",
      distributorID: "",
      carrierSheetMode: "",
      carrierSheetScheme: "",
      routePPI: "",
      modifiedDateTo: null,
      modifiedDateFrom: null,
      customersList: [],
      LocationList: [],
      countryList: [],
      DispatchModeList: [],
      DistributorList: [],
      mailingSchemeList: [],

      openDialog: false,
    });
  };

  checkButtonDisability = () => {
    let { modifiedDateFrom, modifiedDateTo } = this.state;
    if (
      !this.state.customerID &&
      !this.state.locationID &&
      !this.state.countryID &&
      !this.state.routePPI &&
      !this.state.dispatchID &&
      !this.state.mailingID &&
      !this.state.distributorID &&
      !this.state.carrierSheetScheme &&
      !this.state.carrierSheetMode
      //!(modifiedDateFrom && modifiedDateTo)
    )
      return true;
    else return false;
  };

  handleDateChange = (date) => {
    let { modifiedDateFrom } = this.state;
    modifiedDateFrom = date && date.toLocaleDateString();
    this.setState({ modifiedDateFrom });
  };
  handleDateChange1 = (date) => {
    console.log(date);
    let { modifiedDateTo } = this.state;
    modifiedDateTo = date && date.toLocaleDateString();
    this.setState({ modifiedDateTo });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleAutocompleteCustomer = (e, values) => {
    let name = "";
    let ID = "";
    if (values) {
      name = values;
      ID = values.id;
    }

    this.setState({ customer: name, customerID: ID });
  };
  handleAutocompleteLocation = (e, values) => {
    let name = "";
    let ID = "";
    if (values) {
      name = values;
      ID = values.id;
    }

    this.setState({ location: name, locationID: ID });
  };
  handleAutocompleteCountry = (e, values) => {
    let name = "";
    let ID = "";
    if (values) {
      name = values;
      ID = values.id;
    }

    this.setState({ country: name, countryID: ID });
  };

  handleAutocompleteMailing = (e, values) => {
    let name = "";
    let ID = "";
    if (values) {
      name = values;
      ID = values.mailingSchemeID;
    }

    this.setState({ mailing: name, mailingID: ID });
  };
  handleAutocompleteDispatch = (e, values) => {
    let name = "";
    let ID = "";
    if (values) {
      name = values;
      ID = values.dispatchModeID;
    }

    this.setState({ dispatch: name, dispatchID: ID });
  };
  handleAutocompleteDistributor = (e, values) => {
    let name = "";
    let ID = "";
    if (values) {
      name = values;
      ID = values.distributorID;
    }

    this.setState({ distributor: name, distributorID: ID });
  };
  render() {
    const { classes, ...other } = this.props;

    return (
      <React.Fragment>
        <div>
          <Tooltip title="Search">
            <IconButton
              aria-label="Search"
              className={classes.iconHover}
              onClick={this.handleClickOpen}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
            maxWidth="sm"
            fullWidth={true}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
              style={{ padding: "10px 24px" }}
            >
              Advanced Search
            </DialogTitle>
            <DialogContent
              dividers
              id="preAlertID"
              style={{ paddingTop: "0px!important" }}
            >
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Autocomplete
                    options={this.state.customersList}
                    value={this.state.customer}
                    style={{
                      flex: 1,
                      marginRight: "1em",
                    }}
                    onChange={this.handleAutocompleteCustomer}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Customer"
                        style={{
                          width: "100%",
                        }}
                        margin="normal"
                      />
                    )}
                  />
                  <Autocomplete
                    options={this.state.LocationList}
                    value={this.state.location}
                    style={{
                      flex: 1,
                      marginRight: "1em",
                    }}
                    onChange={this.handleAutocompleteLocation}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Location"
                        style={{
                          width: "100%",
                        }}
                        margin="normal"
                      />
                    )}
                  />
                  {/* <Autocomplete
                    options={this.state.countryList}
                    value={this.state.country}
                    style={{
                      flex: 1,
                    }}
                    onChange={this.handleAutocompleteCountry}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        style={{
                          width: "100%",
                        }}
                        margin="normal"
                      />
                    )}
                  /> */}
                  <Autocomplete
                    options={this.state.DispatchModeList}
                    value={this.state.dispatch}
                    style={{
                      flex: 1,
                      marginRight: "1em",
                      marginLeft: "",
                    }}
                    onChange={this.handleAutocompleteDispatch}
                    getOptionLabel={(option) => option.dispatchModeName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Dispatch Mode"
                        style={{
                          width: "100%",
                        }}
                        margin="normal"
                      />
                    )}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Autocomplete
                    options={this.state.DistributorList}
                    value={this.state.distributor}
                    style={{
                      flex: 1,
                      marginRight: "1em",
                      marginLeft: "",
                    }}
                    onChange={this.handleAutocompleteDistributor}
                    getOptionLabel={(option) => option.distributorName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Distributor"
                        style={{
                          width: "100%",
                        }}
                        margin="normal"
                      />
                    )}
                  />
                  <Autocomplete
                    options={this.state.mailingSchemeList}
                    value={this.state.mailing}
                    style={{
                      flex: 1,
                      marginLeft: "",
                    }}
                    onChange={this.handleAutocompleteMailing}
                    getOptionLabel={(option) => option.mailingScheme}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Mailing Scheme"
                        style={{
                          width: "100%",
                        }}
                        margin="normal"
                      />
                    )}
                  />
                  <TextField
                    label="Route PPI"
                    name="routePPI"
                    inputProps={{
                      maxLength: 100,
                    }}
                    style={{
                      flex: 1,
                      marginRight: "1em",
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    value={this.state.routePPI}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    label="Carrier Sheet Scheme"
                    name="carrierSheetScheme"
                    inputProps={{
                      maxLength: 100,
                    }}
                    style={{
                      width: "31%",
                      marginRight: "0.4em",
                      marginLeft: "0px",
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    value={this.state.carrierSheetScheme}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Carrier Sheet Mode"
                    name="carrierSheetMode"
                    inputProps={{
                      maxLength: 100,
                    }}
                    style={{
                      width: "31%",
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    value={this.state.carrierSheetMode}
                    onChange={this.handleChange}
                  />
                </div>
                {/* <div style={{ display: "flex", alignItems: "center" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="modifiedDateFrom"
                      label="Modified Date From"
                      onChange={this.handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      style={{ marginRight: "24px", width: "31%" }}
                      value={this.state.modifiedDateFrom}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      style={{ width: "30%" }}
                      id="modifiedDateTo"
                      label="Modified Date To"
                      value={this.state.modifiedDateTo}
                      onChange={this.handleDateChange1}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <div
                    className={[classes.textFieldAlign, classes.lastChild].join(
                      " "
                    )}
                  ></div> */}
              </div>
            </DialogContent>
            <DialogActions style={{ padding: "10px 24px" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={this.handleClose}
              >
                CANCEL
              </Button>
              {this.checkButtonDisability() ? (
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  SEARCH
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.onSearchClicked()}
                >
                  SEARCH
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(EmloStockinAdvanceSearch));
