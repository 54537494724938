import React, { Component } from "react";

class PublisherStockIn extends Component {
  state = {};
  componentDidMount() {
    this.props.handleSettingHeader("Back Label Stock In");
    this.props.setSelectedList("publisherReportsOpen", "PublisherStockIn");
  }
  render() {
    // alert("collectionnote");
    //const url = "http://172.22.43.196/report/reportviewer?Reportname=ReorderLevel";
    return (
      <div>
      <iframe
        style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
        src={`${window.localStorage.ReportURLByID}` +"PublisherStockIn"}
        />
      </div>
    );
  }
}

export default PublisherStockIn;
