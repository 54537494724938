import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { withRouter } from "react-router-dom";
import { getUserList, getPlannerList } from "../../userAccess/userAccessAction";
import {
  getCustomerOrderStatus,
  getCustomerDropdown,
  getProcessCategoryList,
  getProcessDropDown,
  getProcessTypes,
  getUserOrderStatus,
} from "../../masters/masterActions";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { StateContext } from "../../../shared/globalState";
import { styles } from "../../../shared/styles";
import { resourceValidation } from "../../../shared/resource";
//import { getCustomerList, serchCustomer } from "../masterActions";
const notRequiredAlpNum10 = new Validator("notRequiredAlpNum10");
const notRequired100AllAllow = new Validator("notRequired100AllAllow");
const maxlength10Alphanumaric = new Validator("maxlength10Alphanumaric");

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
class UserOrderAdvanceSearch extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    orderId: "",
    groupId: "",
    workOrderId: "",
    orderTitle: "",
    status: "",
    plannerId: "",
    statusObj: {},
    modifiedDateFrom: null,
    modifiedDateTo: null,
    userObj: {},
    categoryObj: {},
    nameObj: {},
    typeObj: {},
    userId: "",
    categoryId: "",
    nameId: "",
    typeId: "",
    planner: {},
    plannerList: [],
    statusList: [],
    userList: [],
    errors: {
      orderId: false,
      groupId: false,
      orderTitle: false,
      status: false,
      plannerId: false,
      ogCustomer: false,
      ogOrderCode: false,
      processCategory: false,
      process: false,
      processType: false,
    },
    ogCustomer: "",
    ogOrderCode: "",
    processCategory: "",
    process: "",
    processType: "",
    processCategoryList: [],
    processNameList: [],
    processTypeList: [],
  };

  componentDidMount() {
    const [{ store }, dispatch] = this.context;
    // this.props.setSelectedList("masterDataOpen", "customer");
    //this.props.setSelectedList("masterDataOpen", "customer");
    getPlannerList().then((r) => {
      dispatch({
        type: "getUserList",
        newStore: r.data,
      });
      this.getAllPlanners(r.data);
    });
    getUserOrderStatus().then((r) => {
      this.getAllOrderStatus(r.data);
    });
    getCustomerDropdown().then((r) => {
      this.getAllCustomer(r.data);
    });

    getProcessCategoryList().then((r) => {
      this.setState({
        processCategoryList:
          r &&
          r.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });

    getProcessDropDown().then((r) => {
      this.setState({
        processNameList:
          r &&
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });
    getProcessTypes().then((r) => {
      this.setState({
        processTypeList:
          r &&
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });
  }

  getAllCustomer = (list) => {
    let userList = [];
    list &&
      list.length > 0 &&
      (userList = list.sort((a, b) =>
        a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
      ));
    let newCustomerList = [];
    userList.map((c) => {
      newCustomerList.push({
        key: c.id,
        value: c.value,
        name: c.text,
      });
    });
    this.setState({ userList: newCustomerList });
  };
  getAllPlanners = (list) => {
    let plannerList = [];
    list &&
      list.length > 0 &&
      (plannerList = list.sort((a, b) =>
        a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
      ));
    let newPlannerList = [];
    plannerList.map((c) => {
      newPlannerList.push({
        key: c.id,
        value: c.text,
        name: c.text,
      });
    });
    this.setState({ plannerList: newPlannerList });
  };
  getAllOrderStatus = (list) => {
    let statusList = [];
    list &&
      list.length > 0 &&
      (statusList = list
        .filter((c) => c.value !== "OR")
        .sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ));
    let newStatusList = [];
    statusList.map((c) => {
      newStatusList.push({
        key: c.id,
        value: c.value,
        name: c.text,
      });
    });
    this.setState({ statusList: newStatusList });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      open: false,
      orderId: "",
      groupId: "",
      workOrderId: "",
      orderTitle: "",
      status: "",
      plannerId: "",
      statusObj: {},
      userObj: {},
      userId: "",
      modifiedDateFrom: null,
      modifiedDateTo: null,
      categoryId: "",
      nameId: "",
      typeId: "",
      planner: {},
      errors: {
        orderId: false,
        orderTitle: false,
        status: false,
        plannerId: false,
        ogCustomer: false,
        ogOrderCode: false,
        processCategory: false,
        process: false,
        processType: false,
      },
      ogCustomer: "",
      ogOrderCode: "",
      processCategory: "",
      process: "",
      processType: "",
    });
  };
  _onKeyPressNoOnly(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  onInputChanged = (e, name, type) => {
    let newError = { ...this.state.errors };
    if (type === "notRequiredAlpNum10") {
      let result = notRequiredAlpNum10(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else if (type === "maxlength10Alphanumaric") {
      let result = maxlength10Alphanumaric(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else if (type === "notRequired100AllAllow") {
      let result = notRequired100AllAllow(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    }
    this.setState({ [name]: e.target.value });
  };
  handleAutocompletePlanner = (e, val) => {
    this.setState({ plannerId: val && val.key, planner: val && val });
  };
  handleAutocompleteStatus = (e, val) => {
    this.setState({ status: val && val.key, statusObj: val && val });
  };
  handleAutocompleteCustomer = (e, val) => {
    //console.log(val);
    this.setState({
      userId: val && val.value,
      ogCustomer: val && val.key,
      userObj: val && val,
    });
  };

  handleAutocompleteCategory = (e, val) => {
    this.setState({
      categoryId: val && val.value,
      processCategory: val && val.id,
      categoryObj: val && val,
    });
  };

  handleAutocompleteName = (e, val) => {
    this.setState({
      nameId: val && val.value,
      process: val && val.id,
      nameObj: val && val,
    });
  };

  handleAutocompleteType = (e, val) => {
    this.setState({
      typeId: val && val.value,
      processType: val && val.id,
      typeObj: val && val,
    });
  };

  onSearchClicked = (mode) => {
    const [{ store }, dispatch] = this.context;
    let loggedInId = localStorage.getItem("loggedInId");
    let { orderId, orderTitle, status, plannerId } = this.state;
    let reqBody = {};
    if (mode === "orderGroup")
      reqBody = {
        customer: this.state.ogCustomer ? this.state.ogCustomer : "",
        orderCode: this.state.ogOrderCode,
        statusID: status,
        plannerID: plannerId,
        userID: loggedInId,
        modifiedDateFrom: this.state.modifiedDateFrom,
        modifiedDateTo: this.state.modifiedDateTo
      };
    if (mode === "orderList")
      reqBody = {
        customer: this.state.ogCustomer ? this.state.ogCustomer : "",
        groupID: this.state.groupId,
        orderID: orderId,
        workOrderID: this.state.workOrderId,
        statusID: status,
        userID: loggedInId,
        modifiedDateFrom: this.state.modifiedDateFrom,
        modifiedDateTo: this.state.modifiedDateTo
      };
    if (mode === "groupProcess")
      reqBody = {
        customer: this.state.ogCustomer ? this.state.ogCustomer : "",
        groupID: this.state.groupId,
        processCategory: this.state.processCategory,
        process: this.state.process,
        processType: this.state.processType,
        statusID: status,
        userID: loggedInId,
        modifiedDateFrom: this.state.modifiedDateFrom,
        modifiedDateTo: this.state.modifiedDateTo
      };
    this.props.onSearchClicked(reqBody);
    this.setState({
      open: false,
      orderId: "",
      groupId: "",
      workOrderId: "",
      orderTitle: "",
      status: "",
      plannerId: "",
      statusObj: {},
      modifiedDateFrom: null,
      modifiedDateTo: null,
      userObj: {},
      userId: "",
      categoryId: "",
      nameId: "",
      typeId: "",
      planner: {},
      errors: {
        orderId: false,
        orderTitle: false,
        status: false,
        plannerId: false,
        ogCustomer: false,
        ogOrderCode: false,
        processCategory: false,
        process: false,
        processType: false,
      },
      ogCustomer: "",
      ogOrderCode: "",
      processCategory: "",
      process: "",
      processType: "",
    });
  };
  checkButtonDisability = (mode) => {
    let {
      orderId,
      status,
      plannerId,
      ogOrderCode,
      ogCustomer,
      groupId,
      workOrderId,
      processCategory,
      process,
      processType,
    } = this.state;
    if (mode === "orderGroup") {
      if (
        !(this.state.modifiedDateFrom
          ? this.state.modifiedDateTo
          : this.state.ogOrderCode ||
          this.state.ogCustomer ||
          this.state.status || this.state.plannerId)
      )
        return true;
      else return false;
    }

    if (mode === "orderList") {
      if (
        this.state.errors.ogCustomer ||
        this.state.errors.groupId ||
        this.state.errors.status ||
        this.state.errors.orderId ||
        this.state.errors.workOrderId ||
        (!workOrderId && !orderId && !status && !ogCustomer && !groupId)
      )
        return true;
      else return false;
    }

    if (mode === "groupProcess") {
      if (
        this.state.errors.ogCustomer ||
        this.state.errors.groupId ||
        this.state.errors.status ||
        this.state.errors.processCategory ||
        this.state.errors.process ||
        this.state.errors.processType ||
        (!processType &&
          !process &&
          !status &&
          !processCategory &&
          !groupId &&
          !ogCustomer)
      )
        return true;
      else return false;
    }
  };
  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };
  render() {
    let {
      orderId,
      orderTitle,
      status,
      plannerId,
      plannerList,
      statusList,
      userList,
    } = this.state;
    const { classes, ...other } = this.props;
    //console.log(!isVendor && this.checkButtonDisability());
    // console.log(this.state)
    return (
      <React.Fragment>
        {this.props.searchMode === "orderGroup" ? (
          <div>
            <Tooltip title="Search">
              <IconButton
                aria-label="Search"
                className={classes.iconHover}
                onClick={this.handleClickOpen}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
            <Dialog
              onClose={this.handleClose}
              aria-labelledby="customized-dialog-title"
              open={this.state.open}
              maxWidth="sm"
              disableBackdropClick
              disableEscapeKeyDown
              fullWidth={true}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={this.handleClose}
              >
                {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
                Advanced Search
              </DialogTitle>
              <DialogContent dividers>

                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <Autocomplete
                      options={this.state.userList}
                      value={this.state.userObj}
                      onChange={this.handleAutocompleteCustomer}
                      getOptionLabel={(option) => option.name}
                      // style={{
                      //   width: "100%",
                      //   marginLeft: ".5em",
                      //   marginRight: ".5em"
                      // }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Customer"
                          style={{
                            width: "93%",
                            fontSize: "11px !important",
                            marginLeft: ".5em",
                            marginRight: ".5em",
                            maxHeight: "400px",
                          }}
                          // name="plannerId"
                          margin="normal"
                        // required
                        />
                      )}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <TextField
                      label="Group Code"
                      style={{
                        width: "93%", marginLeft: ".5em",
                        marginRight: ".5em",
                      }}
                      className={classes.textField}
                      margin="normal"
                      value={this.state.ogOrderCode}
                      inputProps={{
                        maxLength: 100,
                      }}
                      error={this.state.errors.ogOrderCode}
                      onChange={(e) =>
                        this.onInputChanged(
                          e,
                          "ogOrderCode",
                          "notRequired100AllAllow"
                        )
                      }
                      helperText={
                        this.state.errors.ogOrderCode
                          ? resourceValidation.max100char
                          : ""
                      }
                      onKeyPress={this._onKeyPress}
                    />
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <Autocomplete
                      options={this.state.statusList}
                      value={this.state.statusObj}
                      // style={{
                      //   width: "100%",
                      //   marginLeft: ".5em",
                      //   marginRight: ".5em"
                      // }}
                      onChange={this.handleAutocompleteStatus}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Status"
                          style={{
                            width: "93%",
                            fontSize: "11px !important",
                            marginLeft: ".5em",
                            marginRight: ".5em",
                            maxHeight: "400px",
                          }}
                          // name="plannerId"
                          margin="normal"
                        // required
                        />
                      )}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <Autocomplete
                      options={this.state.plannerList}
                      value={this.state.planner}
                      // style={{
                      //   width: "100%",
                      //   marginLeft: ".5em",
                      //   marginRight: ".5em"
                      // }}
                      onChange={this.handleAutocompletePlanner}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Planner"
                          style={{
                            width: "93%",
                            fontSize: "11px !important",
                            marginLeft: ".5em",
                            marginRight: ".5em",
                            maxHeight: "400px",
                          }}
                          // name="plannerId"
                          margin="normal"
                        // required
                        />
                      )}
                    />
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        style={{
                          width: "93%",
                          // display: "inline-block",
                          marginLeft: ".5em",
                          marginRight: ".5em",
                        }}
                        id="modifiedDateFrom"
                        label="Modified Date From"
                        // onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        name="modifiedDateFrom"
                        value={this.state.modifiedDateFrom}
                        onChange={(e) =>
                          this.handleDateChange(e, "modifiedDateFrom")
                        }
                      //    value={preAlertSearchValue.modifiedDateFrom}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div style={{ flex: 1 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        style={{
                          width: "93%",
                          // display: "inline-block",
                          marginLeft: ".5em",
                          marginRight: ".5em",
                        }}
                        name="modifiedDateTo"
                        value={this.state.modifiedDateTo}
                        id="modifiedDateTo"
                        label="Modified Date To"
                        //   value={preAlertSearchValue.modifiedDateTo}
                        onChange={(e) =>
                          this.handleDateChange(e, "modifiedDateTo")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </DialogContent>
              <DialogActions style={{ padding: "10px 33px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={this.handleClose}
                >
                  CANCEL
                </Button>

                <Button
                  variant="contained"
                  disabled={!(this.state.modifiedDateFrom
                    ? this.state.modifiedDateTo
                    : this.state.ogOrderCode ||
                    this.state.ogCustomer ||
                    this.state.status || this.state.plannerId)}
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.onSearchClicked("orderGroup")}
                >
                  SEARCH
                  </Button>


                {/* <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={this.handleClose}
              >
                CANCEL
              </Button> */}
              </DialogActions>
            </Dialog>
          </div>
        ) : this.props.searchMode === "orderList" ? (
          <div>
            <Tooltip title="Search">
              <IconButton
                aria-label="Search"
                className={classes.iconHover}
                onClick={this.handleClickOpen}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
            <Dialog
              onClose={this.handleClose}
              aria-labelledby="customized-dialog-title"
              open={this.state.open}
              maxWidth="sm"
              fullWidth={true}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={this.handleClose}
              >
                {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
                Advanced Search
              </DialogTitle>
              <DialogContent dividers>
                <div>
                  <div>
                    {/* <TextField
                      inputProps={{
                        maxLength: 100
                      }}
                      label="Customer"
                      className={classes.textField}
                      margin="normal"
                      value={this.state.ogCustomer}
                      error={this.state.errors.ogCustomer}
                      onChange={e =>
                        this.onInputChanged(
                          e,
                          "ogCustomer",
                          "notRequired100AllAllow"
                        )
                      }
                      helperText={
                        this.state.errors.ogCustomer
                          ? resourceValidation.max10char
                          : ""
                      }
                      onKeyPress={this._onKeyPress}
                    /> */}
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1 }}>
                        <Autocomplete
                          options={this.state.userList}
                          value={this.state.userObj}
                          onChange={this.handleAutocompleteCustomer}
                          getOptionLabel={(option) => option.name}
                          // style={{
                          //   width: "100%",
                          //   marginLeft: ".5em",
                          //   marginRight: ".5em"
                          // }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Customer"
                              style={{
                                width: "93%",
                                fontSize: "11px !important",
                                marginLeft: ".5em",
                                marginRight: ".5em",
                                maxHeight: "400px",
                              }}
                              // name="plannerId"
                              margin="normal"
                            // required
                            />
                          )}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <TextField
                          label="Group Id"
                          className={classes.textField}
                          margin="normal"
                          value={this.state.groupId}
                          inputProps={{
                            maxLength: 100,
                          }}
                          style={{
                            width: "93%",
                            fontSize: "11px !important",
                            marginLeft: ".5em",
                            marginRight: ".5em",
                            maxHeight: "400px",
                          }}
                          error={this.state.errors.groupId}
                          onChange={(e) =>
                            this.onInputChanged(
                              e,
                              "groupId",
                              "notRequired100AllAllow"
                            )
                          }
                          helperText={
                            this.state.errors.groupId
                              ? resourceValidation.max100char
                              : ""
                          }
                          onKeyPress={this._onKeyPress}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <TextField
                        label="Order Id"
                        className={classes.textField}
                        margin="normal"
                        value={this.state.orderId}
                        inputProps={{
                          maxLength: 100,
                        }}
                        style={{
                          width: "93%",
                          fontSize: "11px !important",
                          marginLeft: ".5em",
                          marginRight: ".5em",
                          maxHeight: "400px",
                        }}
                        error={this.state.errors.orderId}
                        onChange={(e) =>
                          this.onInputChanged(
                            e,
                            "orderId",
                            "notRequired100AllAllow"
                          )
                        }
                        helperText={
                          this.state.errors.orderId
                            ? resourceValidation.max100char
                            : ""
                        }
                        onKeyPress={this._onKeyPress}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <TextField
                        label="Work Order Id"
                        className={classes.textField}
                        margin="normal"
                        value={this.state.workOrderId}
                        inputProps={{
                          maxLength: 100,
                        }}
                        style={{
                          width: "93%",
                          fontSize: "11px !important",
                          marginLeft: ".5em",
                          marginRight: ".5em",
                          maxHeight: "400px",
                        }}
                        error={this.state.errors.workOrderId}
                        onChange={(e) =>
                          this.onInputChanged(
                            e,
                            "workOrderId",
                            "notRequired100AllAllow"
                          )
                        }
                        helperText={
                          this.state.errors.workOrderId
                            ? resourceValidation.max100char
                            : ""
                        }
                        onKeyPress={this._onKeyPress}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          autoOk
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          style={{
                            width: "93%",
                            // display: "inline-block",
                            marginLeft: ".5em",
                            marginRight: ".5em",
                          }}
                          id="modifiedDateFrom"
                          label="Modified Date From"
                          // onChange={handleDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          name="modifiedDateFrom"
                          value={this.state.modifiedDateFrom}
                          onChange={(e) =>
                            this.handleDateChange(e, "modifiedDateFrom")
                          }
                        //    value={preAlertSearchValue.modifiedDateFrom}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div style={{ flex: 1 }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          autoOk
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          style={{
                            width: "93%",
                            // display: "inline-block",
                            marginLeft: ".5em",
                            marginRight: ".5em",
                            // marginRight: ".5em",
                          }}
                          name="modifiedDateTo"
                          value={this.state.modifiedDateTo}
                          id="modifiedDateTo"
                          label="Modified Date To"
                          //   value={preAlertSearchValue.modifiedDateTo}
                          onChange={(e) =>
                            this.handleDateChange(e, "modifiedDateTo")
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <Autocomplete
                        options={this.state.statusList}
                        value={this.state.statusObj}
                        // style={{
                        //   width: "100%",
                        //   marginLeft: ".5em",
                        //   marginRight: ".5em"
                        // }}
                        onChange={this.handleAutocompleteStatus}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status"
                            style={{
                              width: "93%",
                              fontSize: "11px !important",
                              marginLeft: "10px",
                              marginRight: ".5em",
                              maxHeight: "400px",
                            }}
                            // name="plannerId"
                            margin="normal"
                          // required
                          />
                        )}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <div
                        style={{
                          width: "93%",
                          fontSize: "11px !important",
                          marginLeft: "10px",
                          marginRight: ".5em",
                          maxHeight: "400px",
                        }}
                      />
                    </div>
                  </div>

                  <div></div>
                </div>
              </DialogContent>
              <DialogActions style={{ padding: "10px 33px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={this.handleClose}
                >
                  CANCEL
                </Button>

                <Button
                  disabled={!(this.state.modifiedDateFrom
                    ? this.state.modifiedDateTo
                    : this.state.workOrderId ||
                    this.state.orderId ||
                    this.state.ogCustomer ||
                    this.state.groupId ||
                    this.state.status
                  )}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.onSearchClicked("orderList")}
                >
                  SEARCH
                  </Button>


                {/* <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={this.handleClose}
              >
                CANCEL
              </Button> */}
              </DialogActions>
            </Dialog>
          </div>
        ) : (
              <div>
                <Tooltip title="Search">
                  <IconButton
                    aria-label="Search"
                    className={classes.iconHover}
                    onClick={this.handleClickOpen}
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
                <Dialog
                  onClose={this.handleClose}
                  aria-labelledby="customized-dialog-title"
                  open={this.state.open}
                  maxWidth="sm"
                  fullWidth={true}
                >
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={this.handleClose}
                  >
                    Advanced Search
              </DialogTitle>
                  <DialogContent dividers>
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: 1 }}>
                          <Autocomplete
                            options={this.state.userList}
                            value={this.state.userObj}
                            onChange={this.handleAutocompleteCustomer}
                            getOptionLabel={(option) => option.name}
                            style={{ width: "100%", marginRight: "10px", flex: "1" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Customer"
                                style={{
                                  width: "93%",
                                  fontSize: "11px !important",
                                  marginLeft: "10px",
                                  marginRight: ".5em",
                                  maxHeight: "400px",
                                }}
                                // name="plannerId"
                                margin="normal"
                              // required
                              />
                            )}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <TextField
                            label="Group Id"
                            className={classes.textField}
                            margin="normal"
                            value={this.state.groupId}
                            inputProps={{
                              maxLength: 100,
                            }}
                            style={{
                              width: "93%",
                              fontSize: "11px !important",
                              marginLeft: "10px",
                              marginRight: ".5em",
                              maxHeight: "400px",
                            }}
                            error={this.state.errors.groupId}
                            onChange={(e) =>
                              this.onInputChanged(
                                e,
                                "groupId",
                                "notRequired100AllAllow"
                              )
                            }
                            helperText={
                              this.state.errors.groupId
                                ? resourceValidation.max100char
                                : ""
                            }
                            onKeyPress={this._onKeyPress}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}>
                          <Autocomplete
                            options={this.state.processCategoryList}
                            value={this.state.categoryObj}
                            onChange={this.handleAutocompleteCategory}
                            getOptionLabel={(option) => option.text}
                            style={{ width: "100%", marginRight: "10px" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Category"
                                style={{
                                  width: "93%",
                                  fontSize: "11px !important",
                                  marginLeft: "10px",
                                  marginRight: ".5em",
                                  maxHeight: "400px",
                                }}
                                // name="plannerId"
                                margin="normal"
                              // required
                              />
                            )}
                          />
                        </div>   <div style={{ flex: 1 }}>
                          <Autocomplete
                            options={this.state.processNameList}
                            value={this.state.nameObj}
                            onChange={this.handleAutocompleteName}
                            getOptionLabel={(option) => option.text}
                            style={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Name"
                                style={{
                                  width: "93%",
                                  fontSize: "11px !important",
                                  marginLeft: "10px",
                                  marginRight: ".5em",
                                  maxHeight: "400px",
                                }}
                                // name="plannerId"
                                margin="normal"
                              // required
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}>
                          <Autocomplete
                            options={this.state.processTypeList}
                            value={this.state.typeObj}
                            onChange={this.handleAutocompleteType}
                            getOptionLabel={(option) => option.text}
                            style={{ width: "100%", marginRight: "10px" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Type"
                                style={{
                                  width: "93%",
                                  fontSize: "11px !important",
                                  marginLeft: "10px",
                                  marginRight: ".5em",
                                  maxHeight: "400px",
                                }}
                                // name="plannerId"
                                margin="normal"
                              // required
                              />
                            )}
                          />
                        </div>   <div style={{ flex: 1 }}>
                          <Autocomplete
                            options={this.state.statusList}
                            value={this.state.statusObj}
                            // style={{
                            //   width: "100%",
                            //   marginLeft: ".5em",
                            //   marginRight: ".5em"
                            // }}
                            style={{ width: "100%" }}
                            onChange={this.handleAutocompleteStatus}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Status"
                                style={{
                                  width: "93%",
                                  fontSize: "11px !important",
                                  marginLeft: "10px",
                                  marginRight: ".5em",
                                  maxHeight: "400px",
                                }}
                                // name="plannerId"
                                margin="normal"
                              // required
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              autoOk
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              style={{
                                width: "93%",
                                // display: "inline-block",
                                marginLeft: ".5em",
                                marginRight: ".5em",
                              }}
                              id="modifiedDateFrom"
                              label="Modified Date From"
                              // onChange={handleDateChange}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              name="modifiedDateFrom"
                              value={this.state.modifiedDateFrom}
                              onChange={(e) =>
                                this.handleDateChange(e, "modifiedDateFrom")
                              }
                            //    value={preAlertSearchValue.modifiedDateFrom}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div style={{ flex: 1 }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              autoOk
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              style={{
                                width: "93%",
                                // display: "inline-block",
                                marginLeft: ".5em",
                                marginRight: ".5em",
                              }}
                              name="modifiedDateTo"
                              value={this.state.modifiedDateTo}
                              id="modifiedDateTo"
                              label="Modified Date To"
                              //   value={preAlertSearchValue.modifiedDateTo}
                              onChange={(e) =>
                                this.handleDateChange(e, "modifiedDateTo")
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions style={{ padding: "10px 33px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={this.handleClose}
                    >
                      CANCEL
                </Button>

                    <Button
                      // disabled={ 
                      //    (!this.state.processType ||
                      //   !this.state.process ||
                      //   !this.state.status ||
                      //   !this.state.processCategory ||
                      //   !this.state.groupId ||
                      //   !this.state.ogCustomer)}
                      disabled={!(this.state.modifiedDateFrom
                        ? this.state.modifiedDateTo
                        : this.state.processType ||
                        this.state.process ||
                        this.state.categoryId ||

                        this.state.nameId ||
                        this.state.typeId ||

                        this.state.status ||
                        this.state.processCategory ||
                        this.state.groupId ||
                        this.state.ogCustomer
                      )}
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.onSearchClicked("groupProcess")}
                    >
                      SEARCH
                  </Button>


                    {/* <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={this.handleClose}
              >
                CANCEL
              </Button> */}
                  </DialogActions>
                </Dialog>
              </div>
            )}
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(UserOrderAdvanceSearch));
