import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Button, Paper, Divider } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import Validator from "../../../shared/validator";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { addDispatchStyles } from "../../../shared/styles";
import { resourceValidation } from "../../../shared/resource";
import { StateContext } from "../../../shared/globalState";
import LinearProgress from "@material-ui/core/LinearProgress";
import InfoIcon from "@material-ui/icons/Info";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { updateUploadCustomerOrderFiles, onEditOrderCancel } from "./actions";
import DescriptionIcon from "@material-ui/icons/Description";
import FileImg from "../../../assests/images/svg/filesupload-illustration.svg";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  getParticularOrderEdit,
  getParticularOrder,
  deleteOrder,
  //getCustomerExcelData,
  getCustomerStagingExcelData,
  submitCustomerOrder,
} from "./actions";
import { getUserList, getPlannerList } from "../../userAccess/userAccessAction";
import { ExportCSV } from "../../../shared/ExportCSV";
const maxlength50 = new Validator("maxlength50");
const emailValidator = new Validator("email");
const maxlength200AllAllow = new Validator("maxlength200AllAllow");
const notRequired100AllAllow = new Validator("notRequired100AllAllow");
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
const exportToCSV = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
function createData(
  salutation,
  first_name,
  last_name,
  designation,
  department,
  company_name,
  addr1,
  addr2,
  addr3,
  addr4,
  city,
  state,
  postal_code,
  country_name,
  copies,
  reference_no,
  job_sheet_no,
  membership_no,
  isValid,
  exceptionMessage
) {
  return {
    salutation,
    first_name,
    last_name,
    designation,
    department,
    company_name,
    addr1,
    addr2,
    addr3,
    addr4,
    city,
    state,
    postal_code,
    country_name,
    copies,
    reference_no,
    job_sheet_no,
    membership_no,
    isValid,
    exceptionMessage,
  };
}
function DetectionControl(file) {
  //console.log(file);
  return (
    <div
      style={{
        width: "45%",
        display: "flex",
        alignItems: "center",
        marginTop: "-1px",
      }}
    >
      <span>
        {file.fileName && file.fileName.isValid ? (
          <CheckCircleIcon
            style={{ color: "rgba(59, 218, 182, 0.87)", fontSize: "19px" }}
          />
        ) : (
            <InfoIcon style={{ color: "red", fontSize: "19px" }} />
          )}
      </span>
      <span
        style={{
          marginTop: "-5px",
          marginLeft: "5px",
          fontSize: "11px",
        }}
      >
        {file.fileName && file.fileName.isValid
          ? "Uploaded successfully"
          : file.fileName && file.fileName.message}
      </span>
    </div>
  );
}
function checkBeforeFileUpolad(makeError, name) {
  document.getElementById(name) && document.getElementById(name).focus();
  makeError(name);
}
function Basic(props) {
  return (
    <div
      style={{
        margin: "1em .8em 1em .5em",
        border: "1px solid #eaeaea",
        // width: "97.6%",
        borderRadius: "4px",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0)",
      }}
    >
      <section
        style={{ marginLeft: "1.5em", marginRight: "1.5em", marginTop: "1em" }}
      >
        <Dropzone
          onDrop={props.onDrop}
          accept=".xlsx"
          minSize={0}
          maxSize={512000}
          disabled={props.checkValidData()}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({})}>
              <input
                id="inputId"
                {...getInputProps()}
                onClick={(e) => {
                  if (!props.validEmail) e.preventDefault();
                  return !props.validOrderTitle
                    ? checkBeforeFileUpolad(props.makeError, "email")
                    : "";
                }}
              />

              <div
                style={{
                  width: "auto",
                  border: "1.5px dashed #eaeaea",
                  marginBottom: "1em",
                  borderRadius: "4px",
                  minHeight: "70px",
                  cursor: "pointer",
                }}
              >
                <label
                  className="upload excel"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "70px",
                    cursor: "pointer",
                    padding: ".3em",
                  }}
                >
                  <img
                    style={{ height: "60px", marginTop: "4px" }}
                    src={FileImg}
                    alt="File upload img"
                  />

                  <div
                    style={{
                      display: "inline-block",
                      width: "auto",
                      marginLeft: "12px",
                      width: "320px",
                    }}
                  >
                    <div className="file-upload-head">Add files</div>
                    <div className="file-upload-contant">
                      Drop files here or click{" "}
                      <span
                        style={{
                          color: "#306dca",
                          textDecoration: "underline",
                        }}
                      >
                        browse
                      </span>{" "}
                      through your machine{" "}
                    </div>
                    <div className="file-upload-footer">
                      Allowed only “.xlsx” format, 500KB file limit.
                    </div>
                  </div>
                </label>
              </div>
            </div>
          )}
        </Dropzone>
      </section>
    </div>
  );
}
class EditOrperPlacement extends React.Component {
  static contextType = StateContext;
  state = {
    orderTitle: "",
    finalSubmitPopUpOpen: false,
    email: "",
    splInstruction: "",
    isActive: true,
    errors: {
      orderTitle: false,
      email: false,
      userId: false,
    },
    userId: "",
    status: "",
    plannerList: [],
    fileList: [],
    loading: false,
    stateFiles: [],
    customerOrderHeaderID: 0,
    submitLoading: false,
    showError: true,
    isFileDelete: false,
  };
  componentDidMount() {
    const [{ store }, dispatch] = this.context;
    this.props.handleSettingHeader("Generic Customer");
    this.props.setSelectedList("genericCustomOpen", "orderPlacement");
    let orderId = "";
    this.setState({ showError: false });
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    getPlannerList().then((r) => {
      dispatch({
        type: "getUserList",
        newStore: r.data,
      });
      this.getAllPlanners(r.data);
    });

    getParticularOrderEdit(orderId).then((r) => {
      let val = r.specialInstruction;
      if (r.specialInstruction === null) {
        val = "";
      }
      let orderTitle = r.orderTitle;
      let email = r.contactEmailID;
      let userId = r.plannerID;
      let status = r.status;
      let splInstruction = val;
      let stateFiles = r.customerOrderFileHeaderList;
      let fileList = r.customerOrderFileHeaderList;
      this.setState({
        orderTitle,
        email,
        userId,
        status,
        splInstruction,
        stateFiles,
        fileList,
      });
    });
  }
  getExcelFileData = (id, name) => {
    name = name.slice(0, name.length - 5);

    name = name + "_" + moment(new Date()).format("DD/MM/YYYY");
    //getCustomerExcelData(id).then(r => {
    getCustomerStagingExcelData(id).then((r) => {
      let newRows = [];
      r.map((d) => {
        newRows.push(
          createData(
            d.salutation,
            d.first_name,
            d.last_name,
            d.designation,
            d.department,
            d.company_name,
            d.addr1,
            d.addr2,
            d.addr3,
            d.addr4,
            d.city,
            d.state,
            d.postal_code,
            d.country_name,
            d.copies,
            d.reference_no,
            d.job_sheet_no,
            d.membership_no,
            d.isValid,
            d.exceptionMessage
          )
        );
      });
      //  console.log(r);
      exportToCSV(newRows, name);
    });
  };

  getAllPlanners = (list) => {
    let plannerList = [];
    list &&
      list.length > 0 &&
      (plannerList = list
        // .filter(x => x.isActive === true)
        .sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ));
    let newPlannerList = [];
    plannerList.map((c) => {
      newPlannerList.push({
        key: c.id,
        value: c.text,
        name: c.text,
      });
    });
    this.setState({ plannerList: newPlannerList });
  };
  handleChange = (e, validate) => {
    let newRrrors = { ...this.state.errors };
    let result = validate(e.target.value);
    newRrrors[e.target.name] = !result;
    this.setState({ [e.target.name]: e.target.value, errors: newRrrors });
  };

  handleClose = () => {
    this.setState({
      orderTitle: "",
      email: "",
    });
  };
  checkValidInputs = () => {
    let validFiles = false;
    let duplicatefound = false;
    let templeteFound = false;
    let errorFound = false;
    let success = false;
    if (!this.state.orderTitle || this.state.errors.orderTitle) return true;
    if (!this.state.email || this.state.errors.email) return true;
    if (!this.state.userId) return true;
    // if (!this.state.splInstruction) return true;

    if (!this.state.firstCall) {
      // if (this.state.stateFiles.length === 0) return true;
      this.state.stateFiles.map((s) => {
        if (!s.isValid) {
          if (
            s.message &&
            s.message ===
            "Error Records Found, please download the exception file for more details"
          )
            errorFound = true;
          if (s.message && s.message.includes("already exists"))
            duplicatefound = true;
          if (s.message && s.message.includes("Max"))
            templeteFound = true;
        } else {
          success = true;
        }
      });
      if (templeteFound) validFiles = true;
      else if (errorFound) validFiles = true;
      else if (duplicatefound) {
        if (!success) validFiles = true;
      } else if ((!templeteFound && !errorFound) || duplicatefound)
        validFiles = false;
      else if (!templeteFound && !errorFound && !duplicatefound)
        validFiles = false;
    }
    if (validFiles) return true;
    else return false;
  };
  checkValidData = () => {
    if (!this.state.orderTitle || this.state.errors.orderTitle) return true;
    else if (!this.state.email || this.state.errors.email) return true;
    else if (!this.state.userId) return true;
    else return false;
  };
  deleteSelectedFile = (i, id) => {
    let newStateFiles = [...this.state.stateFiles];
    let newFileList = [...this.state.fileList];
    id &&
      deleteOrder(id, window.localStorage.loggedInId).then((r) => {
        this.setState({ isFileDelete: true });
      });
    newStateFiles.splice(i, 1);
    newFileList.splice(i, 1);
    this.setState({ stateFiles: newStateFiles, fileList: newFileList });
  };
  onDrop = (acceptedFiles) => {
    let orderId = "";
    let loggedInId = localStorage.getItem("loggedInId");
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    getParticularOrder(orderId).then((r) => {
      let stateFiles = r.customerOrderFileHeaderList;
      let fileList = r.customerOrderFileHeaderList;
      this.setState(
        {
          stateFiles,
          fileList,
        },
        () => {
          this.setState({
            loading: true,
            stateFiles: [...this.state.stateFiles, ...acceptedFiles],
          });
        }
      );
    });

    const formData = new FormData();
    acceptedFiles.map((f) => {
      formData.append("Uploadfiles", f);
    });
    formData.append("orderTitle", this.state.orderTitle);
    formData.append("contactEmailID", this.state.email);
    formData.append("specialInstruction", this.state.splInstruction);
    formData.append("noOfOrderFiles", acceptedFiles.length);
    formData.append("plannerID", this.state.userId);
    formData.append("isEmailSent", false);
    formData.append("modifiedBy", loggedInId);
    formData.append("customerOrderHeaderID", orderId);
    updateUploadCustomerOrderFiles(formData)
      .then((r) => {
        let isValidFile = false;
        r.map((file) => {
          if (file.message.includes("already exists")) isValidFile = true;
        });
        this.setState({
          loading: false,
          fileList: r,
          stateFiles: r,
          showError: isValidFile,
        });
      }, () => {
        if (document.getElementById("inputId"))
          document.getElementById("inputId").value = "";
      })
      .catch((e) => {
        // this.setState({
        //   loading: false
        // });
      });
  };
  onCloseClicked = () => {
    let newStateFiles = [];
    this.state.stateFiles.map((s) => {
      if (s.message.includes("already exists") === false) {
        newStateFiles.push(s);
      }
    });
    this.setState({
      showError: false,
      stateFiles: newStateFiles,
      fileList: newStateFiles,
    });
  };
  handleSubmitPopUp = (val) => {
    this.setState({ finalSubmitPopUpOpen: !this.state.finalSubmitPopUpOpen })
    if (val === "Yes")
      this.handleSubmit("e", true, "Order Updated Successfully")
  }
  handleSubmit = (event, status, msg) => {
    //console.log(this.state.fileList)
    this.setState({ submitLoading: true, open: true });
    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    let loggedInId = localStorage.getItem("loggedInId");
    let { fileList, stateFiles } = this.state;
    const [{ store }, dispatch] = this.context;
    const formData = new FormData();
    let count = 0;
    stateFiles.map((f) => {
      if (f.orderFileHeaderID) {
        formData.append("Uploadfiles", f);
        count++;
      }
    });
    formData.append("orderTitle", this.state.orderTitle);
    formData.append("contactEmailID", this.state.email);
    formData.append("specialInstruction", this.state.splInstruction);
    formData.append("noOfOrderFiles", count);
    formData.append("plannerID", this.state.userId);
    formData.append("isEmailSent", false);
    formData.append("modifiedBy", loggedInId);
    formData.append(
      "customerOrderHeaderID",
      fileList.length > 0 ? fileList[0].customerOrderHeaderID : orderId
    );
    formData.append("IsFinalSubmit", status);
    submitCustomerOrder(formData, this.props.history, "updated", msg)
      .then((r) => {
        this.setState({
          loading: false,
          fileList: r,
          submitLoading: false,
          open: false,
        });
      })
      .catch((e) => {
        this.setState({
          submitLoading: false,
          open: false,
        });
      });
    //reqBody.customerOrderHeaderID=fileList[0].customerOrderHeaderID

    // submitCustomerOrder(reqBody).then(r=>{

    // })
  };
  makeError = (name) => {
    let err = this.state.errors;
    err[name] = true;
    this.setState({ errors: err });
  };
  onCancel = () => {
    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    let reqBody = {};
    let fileNames = [];
    this.state.stateFiles.length >= 0 &&
      this.state.stateFiles.map((r) => {
        fileNames.push(r.name);
      });
    reqBody.CustomerOrderHeaderID = orderId;
    //reqBody.FileName=fileNames.join(",");
    reqBody.IsFileDeleted = this.state.isFileDelete;
    //console.log(reqBody.FileName)

    onEditOrderCancel(orderId, this.props.history, reqBody).then((r) => { });
  };
  render() {
    let { classes } = this.props;
    const [{ store }, dispatch] = this.context;
    let {
      orderTitle,
      email,
      splInstruction,
      errors,
      plannerList,
      userId,
      fileList,
      stateFiles,
    } = this.state;
    //console.log(fileList);
    // console.log(this.state.status);
    return (
      <React.Fragment>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.finalSubmitPopUpOpen}
          onClose={this.handleSubmitPopUp}
          maxWidth="xl"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Proceed to submit
            </DialogTitle>
          <DialogContent>
            <div style={{ padding: "1.5em 2px" }}>

              {/* <div style={{ fontSize: "13", fontWeight: "400" }}>
                {" "}
              You have submitted your order.
            </div> */}
              <div style={{ fontSize: "13", fontWeight: "400" }}>
                {" "}
             No amendment is allowed after submitting.
            </div>
              <div style={{ fontSize: "13", fontWeight: "400" }}>
                Please confirm to submit your Order.
            </div>
            </div>

          </DialogContent>
          <DialogActions style={{ padding: "10px 24px" }}>

            <Button
              onClick={this.handleSubmitPopUp}
              color="primary"
              variant="contained"
              style={{ boxShadow: "none" }}
            >
              Cancel
              </Button>
            <Button
              onClick={e => this.handleSubmitPopUp("Yes")}
              color="primary"
              variant="contained"
              style={{ boxShadow: "none", marginLeft: "1em" }}
            >
              Confirm
              </Button>
          </DialogActions>
        </Dialog>
        <Dialog aria-labelledby="simple-dialog-title" open={this.state.open}>
          <div style={{ padding: "1em" }}>
            <CircularProgress />
          </div>
        </Dialog>
        <Paper>
          <div
            className="view-page-header"
            style={{
              justifyContent: "flex-start",
              fontWeight: "500",
              marginLeft: "1.2em",
              fontSize: "18px",
              border: "none",
            }}
          >
            Update Order Placement
          </div>
          <Divider />
          <div className={classes.container}>
            <div style={{ margin: "-15px .5em 0px .5em" }}>
              <div>
                <TextField
                  required
                  label="Order Title"
                  name="orderTitle"
                  inputProps={{
                    maxLength: 50,
                  }}
                  InputProps={{ readOnly: true }}
                  variant={"filled"}
                  margin="normal"
                  className={classes.textFieldModified}
                  value={orderTitle}
                  onChange={(e) => this.handleChange(e, maxlength50)}
                  error={errors.orderTitle}
                  helperText={
                    errors.orderTitle ? resourceValidation.alphaNumaric : ""
                  }
                />
                <TextField
                  inputProps={{
                    maxLength: 100,
                  }}
                  required
                  //onKeyPress={this._onKeyPress}
                  label="Email"
                  name="email"
                  id="email"
                  margin="normal"
                  className={classes.textFieldModified}
                  value={email}
                  onChange={(e) => this.handleChange(e, emailValidator)}
                  error={errors.email}
                  helperText={
                    errors.email ? resourceValidation.validEmailId : ""
                  }
                />
              </div>
              <div>
                <TextField
                  required
                  id="standard-select-currency"
                  select
                  label="Select Planner"
                  InputProps={{ readOnly: true }}
                  variant={"filled"}
                  name="userId"
                  style={{
                    width: "31%",
                    fontSize: "11px !important",
                    marginLeft: ".5em",
                    marginRight: ".5em",
                    maxHeight: "400px"
                  }}
                  inputProps={{
                    maxLength: 100,
                  }}
                  margin="normal"
                  value={userId ? userId : ""}
                  error={errors.userId}
                  helperText={errors.userId ? "User can't be empty" : ""}
                  onChange={(e) => this.handleChange(e, notRequired100AllAllow)}
                >
                  {plannerList.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  inputProps={{
                    maxLength: 200,
                  }}
                  // required
                  //onKeyPress={this._onKeyPress}
                  label="Special Instruction"
                  name="splInstruction"
                  margin="normal"
                  className={classes.textFieldModified}
                  value={splInstruction}
                  onChange={(e) => this.handleChange(e, notRequired100AllAllow)}
                  error={errors.splInstruction}
                  helperText={
                    errors.splInstruction ? resourceValidation.alphaNumaric : ""
                  }
                />
              </div>
              <div>
                <Basic
                  loading={this.state.loading}
                  fileList={this.state.fileList}
                  stateFiles={this.state.stateFiles}
                  onDrop={this.onDrop}
                  checkValidData={this.checkValidData}
                  makeError={this.makeError}
                  validOrderTitle={
                    this.state.orderTitle && !this.state.errors.orderTitle
                  }
                  validEmail={this.state.email && !this.state.errors.email}
                  validPlanner={this.state.userId && !this.state.errors.userId}
                />
              </div>
              {fileList.length > 0 &&
                stateFiles.length > 0 &&
                this.state.showError && (
                  <div
                    style={{
                      textAlign: "right",
                      right: "67px",
                      position: "fixed",
                      top: "422px",
                    }}
                  >
                    <span>
                      <CloseIcon
                        style={{
                          color: "gray",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        onClick={this.onCloseClicked}
                      />
                    </span>
                  </div>
                )}
              {fileList.length > 0 &&
                stateFiles.length > 0 &&
                this.state.showError && (
                  <div
                    style={{
                      width: "auto",
                      border: "1px solid #eaeaea",
                      margin: "1em .8em 1em .5em",
                      borderRadius: "4px",
                      // minHeight: "70px",
                      //  cursor: "pointer",
                      //marginBottom: "1em",

                      fontSize: "12px",
                      padding: ".5em 1em .5em 1em",
                      boxShadow:
                        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0)",
                    }}
                  >
                    {fileList.length > 0 &&
                      fileList.map((file, i) => {
                        if (
                          file &&
                          !file.isValid &&
                          file.message.includes("already exists")
                        )
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              key={i}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span>
                                  <InfoIcon
                                    style={{
                                      color: "#f4b43c",
                                      fontSize: "19px",
                                    }}
                                  />
                                </span>
                                <span
                                  style={{
                                    marginLeft: ".5em",
                                    marginTop: "-2px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {file.name}
                                </span>
                                <span
                                  style={{
                                    marginLeft: "1em",
                                    marginTop: "-2px",
                                    color: "rgb(48, 109, 202)",
                                  }}
                                >
                                  "{file.message}"
                                </span>
                              </div>
                            </div>
                          );
                      })}
                  </div>
                )}
              <div>
                {this.state.stateFiles.map((file, i) => {
                  return (
                    <div key={i}>
                      {i === 0 && (
                        <div
                          style={{
                            display: "flex",
                            margin: "1em",
                            // marginTop: "2em",
                            paddingBottom: ".5em",
                            borderBottom: "1px solid #eaeaea",
                            fontSize: "14px",
                          }}
                        >
                          <div style={{ width: "30%", fontWeight: "500" }}>
                            File Info
                          </div>
                          <div style={{ width: "10%", fontWeight: "500" }}>
                            Size
                          </div>
                          <div style={{ width: "45%", fontWeight: "500" }}>
                            Detection
                          </div>
                          <div
                            style={{
                              width: "15%",
                              fontWeight: "500",
                              textAlign: "right",
                            }}
                          >
                            Actions
                          </div>
                        </div>
                      )}
                      {file.customerOrderHeaderID ? (
                        <div
                          style={{
                            display: "flex",
                            margin: "1em",
                            paddingBottom: ".5em",
                            borderBottom: "1px solid #eaeaea",
                            fontSize: "12px",
                          }}
                        >
                          <div style={{ width: "30%", fontWeight: "500" }}>
                            <div style={{ display: "flex" }}>
                              <DescriptionIcon
                                style={{
                                  color: "#306dca",
                                  marginTop: "-4px",
                                  marginRight: "5px",
                                }}
                              />{" "}
                              <span> {file.name}</span>
                            </div>
                          </div>
                          <div style={{ width: "10%" }}>
                            {/* {Math.ceil(file.size / 1024) + " kb"} */}
                            {file.size}
                          </div>

                          {!this.state.loading ? (
                            <DetectionControl
                              fileName={this.state.fileList[i]}
                            />
                          ) : (
                              <div style={{ width: "45%", marginTop: ".6em" }}>
                                <LinearProgress />
                              </div>
                            )}

                          <div style={{ width: "15%", textAlign: "right" }}>
                            {!file.isValid && !file.filePath && (
                              <IconButton
                                size="small"
                                style={{ marginTop: "-5px" }}
                                onClick={(e) =>
                                  this.getExcelFileData(
                                    file.orderFileHeaderID,
                                    file.name
                                  )
                                }
                              >
                                <GetAppIcon style={{ fontSize: "16px" }} />
                              </IconButton>
                            )}

                            <IconButton
                              size="small"
                              style={{ marginTop: "-7px" }}
                              onClick={(e) => {
                                this.deleteSelectedFile(
                                  i,
                                  fileList[i].orderFileHeaderID
                                );
                              }}
                            >
                              <DeleteIcon style={{ fontSize: "16px" }} />
                            </IconButton>
                            {/* 
                          <Link
                            to={`/${file.filePath}`}
                            target="_blank"
                            download
                          >
                            Download
                          </Link> */}
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                    </div>
                  );
                })}
              </div>
            </div>
            <Divider style={{ margin: "0 1.2em 0 1em" }} />

            <div className="button-wrapper">
              <div style={{ paddingLeft: "0px", marginLeft: "4px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.onCancel()}
                >
                  CANCEL
                </Button>
              </div>

              {this.state.status.includes("Cancelled") ? (
                ""
              ) : (
                  <div style={{ marginLeft: "1em" }}>
                    {this.checkValidInputs() ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={(e) =>
                          this.handleSubmit(e, false, "Customer Order Saved")
                        }
                      >
                        Save Draft
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={(e) =>
                            this.handleSubmit(e, false, "Customer Order Saved")
                          }
                        >
                          Save Draft
                        </Button>
                      )}
                  </div>
                )}
              {this.state.status.includes("Cancelled") ? (
                ""
              ) : (
                  <div style={{ marginLeft: "1em" }}>
                    {this.checkValidInputs() ||
                      !this.state.stateFiles.length > 0 ? (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={(e) =>
                            // this.handleSubmit(e, true, "Order Updated Successfully")
                            this.handleSubmitPopUp(e, true, "Order Updated Successfully")
                          }
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={(e) =>
                            // this.handleSubmit(e, true, "Order Updated Successfully")
                            this.handleSubmitPopUp(e, true, "Order Updated Successfully")
                          }
                        >
                          Submit
                        </Button>
                      )}
                  </div>
                )}
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addDispatchStyles)(EditOrperPlacement));
