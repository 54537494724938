import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import clone from "clone";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { resourceValidation } from "../../../shared/resource";
import { ExportCSV } from "../../../shared/ExportCSV";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  SearchIcon,
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import {
  stableSort,
  getSorting,
  DialogActions,
} from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import Validator from "../../../shared/validator";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import types from "../../masters/masterTypes";
import { StateContext } from "../../../shared/globalState";
import {
  getProcessTypeList,
  deleteProcessType,
  getFilteredProcessTypeList,
} from "../../masters/masterActions";

const columns = [
  {
    name: "Active",
    id: 3,
    show: false,
  },
  {
    name: "Modified Date",
    id: 4,
    show: false,
  },
  {
    name: "Modified By",
    id: 5,
  },
  {
    name: "Created Date",
    id: 6,
  },
  {
    name: "Created By",
    id: 7,
  },
];

const validateNotRequired100 = new Validator("maxlength100AllAllow");
const validateCode = new Validator("maxlengthWithNumber4");

function createData(
  processTypeID,
  processTypeCode,
  processTypeName,
  isActive,
  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate
) {
  return {
    processTypeID,
    processTypeCode,
    processTypeName,
    isActive,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all processType" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    processTypeCode: "",
    processTypeName: "",
  });
  const [formValid, setIsformValid] = useState(false);

  const [processTypeSearchValue, setValues] = useState({
    processTypeCode: "",
    processTypeName: "",
  });

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);

  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handleDeleteProcessType,
    searchPopupOpen,
    //selectedProcessType,
    // selectedProcessTypeId,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchProcessType,
    searchClicked,
    hendleVewColums,
    exportDataValues,
    processTypeList,
    selected,
    isActive,
  } = props;
  //const selectedProcessTypeId = selected && selected.length > 0 && selected[0];
  const selectedProcessTypeId = selected[0];
  const selectedProcessType = processTypeList.find(
    (x) => x.processTypeID === selectedProcessTypeId
  );
  let acnArr = props.roles.filter((u) => u.menuName === "Process Type");
  let acnObj = acnArr[0];

  let expData = clone(processTypeList);
  expData.map((d) => {
    let Val = "No";
    if (d["isActive"]) {
      Val = "Yes";
    }
    d["Process Type Code"] = d["processTypeCode"];
    d["Process Type"] = d["processTypeName"];

    d["Active"] = Val;
    d["Created By"] = d["createdBy"];
    d["Created Date"] = moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss");
    d["Modified By"] = d["modifiedBy"];

    d["Modified Date"] = moment(d["modifiedDate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    );

    delete d["processTypeCode"];

    delete d["processTypeName"];
    delete d["isActive"];

    delete d["createdBy"];

    delete d["createdDate"];

    delete d["modifiedBy"];

    delete d["modifiedDate"];
    delete d["processTypeID"];
  });
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteProcessType = () => {
    handleDeleteProcessType();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = processTypeSearchValue;
    searchValues.processTypeName = "";
    searchValues.processTypeCode = "";
    searchValues = processTypeSearchValue;
  };

  const searchPopup = () => {
    handleSearchProcessType(processTypeSearchValue);
    clearSearchValues();
  };

  const handleSearchInput = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;
    setValues((processTypeSearchValue) => ({
      ...processTypeSearchValue,
      [event.target.name]: event.target.value,
    }));
    switch (name) {
      case "processTypeName":
        let processTypeNameValid = false;
        if (value !== "") {
          processTypeNameValid = validateNotRequired100(value);
          if (errors.processTypeCode !== "") setIsformValid(false);
          else setIsformValid(processTypeNameValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: processTypeNameValid
              ? ""
              : resourceValidation.ValidName,
          }));
        } else {
          if (
            processTypeSearchValue.processTypeCode === "" ||
            errors.processTypeCode !== ""
          )
            setIsformValid(false);
          else setIsformValid(true);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: processTypeNameValid ? "" : "",
          }));
        }
        break;
      case "processTypeCode":
        let ProcessTypeCodeValid = false;
        if (value !== "") {
          ProcessTypeCodeValid = validateCode(value);
          if (errors.processTypeName !== "") setIsformValid(false);
          else setIsformValid(ProcessTypeCodeValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: ProcessTypeCodeValid
              ? ""
              : resourceValidation.onlyAlphanumeric,
          }));
        } else {
          if (
            processTypeSearchValue.processTypeName === "" ||
            errors.processTypeName !== ""
          )
            setIsformValid(false);
          else setIsformValid(true);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: ProcessTypeCodeValid ? "" : "",
          }));
        }
        break;
      default:
        break;
    }

    // if(formValid){
    //   setFormvalid();
    // }
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_processType/${selectedProcessTypeId}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <Link to={`/edit_processType/${selectedProcessTypeId}`}>
                      <IconButton aria-label="edit">
                        <EditIcon className={classes.iconHover} />
                      </IconButton>
                    </Link>

                    {/* this.props.history.push(/home:${this.state.userID}) onClick={e => props.history.push('/edit_processType/${selectedProcessTypeId}')} */}
                  </Tooltip>
                )}
              </div>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={handleClickOpen}
                  disabled={!isActive}
                >
                  <DeleteIcon className={classes.iconHover} />
                </IconButton>
              </Tooltip>
            )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete Process Type
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1
                      ? " ?"
                      : selectedProcessType.processTypeCode + " ?"}
                    {/* {selectedProcessType.processTypeCode} */}
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteProcessType}
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {searchClicked ? (
              <Tooltip title="Clear search result">
                <IconButton
                  aria-label="clear"
                  className={classes.iconHover}
                  onClick={props.clearSearch}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <form onSubmit={searchPopup}>
                {acnObj && acnObj.advanceSearchAction && (
                  <Tooltip title="Search">
                    <IconButton
                      aria-label="Search"
                      className={classes.iconHover}
                      onClick={handleSearchOpen}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Dialog
                  disableBackdropClick
                  disableEscapeKeyDown
                  onClose={handleSearchPopupClose}
                  aria-labelledby="customized-dialog-title"
                  open={searchPopupOpen}
                  maxWidth="md"
                >
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleSearchPopupClose}
                  >
                    Advanced Search
                  </DialogTitle>
                  <DialogContent dividers>
                    <React.Fragment>
                      <div>
                        <TextField
                          label="Process Type Code"
                          name="processTypeCode"
                          inputProps={{
                            maxLength: 3,
                          }}
                          margin="normal"
                          style={{ marginRight: "1em" }}
                          className={classes.textField}
                          value={processTypeSearchValue.processTypeCode}
                          onChange={handleSearchInput}
                          error={errors.processTypeCode}
                          helperText={
                            errors.processTypeCode ? errors.processTypeCode : ""
                          }
                        />
                        <TextField
                          id="standard-required"
                          label="Process Type Name"
                          name="processTypeName"
                          className={classes.textField}
                          margin="normal"
                          value={processTypeSearchValue.processTypeName}
                          inputProps={{
                            maxLength: 50,
                          }}
                          onChange={handleSearchInput}
                          error={errors.processTypeName}
                          helperText={
                            errors.processTypeName ? errors.processTypeName : ""
                          }
                        />
                      </div>
                    </React.Fragment>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={handleSearchClose}
                    >
                      CANCEL
                    </Button>
                    {!formValid ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={searchPopup}
                      >
                        SEARCH
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={searchPopup}
                      >
                        SEARCH
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
              </form>
            )}
            {acnObj && acnObj.addAction && (
              <Tooltip title="Add">
                <IconButton
                  aria-label="add"
                  className={classes.iconHover}
                  onClick={(e) => props.history.push("/add_processType")}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
            {acnObj && acnObj.exportAction && (
              <Tooltip title="Download">
                <ExportCSV csvData={expData} fileName={"ProcessType"} />
              </Tooltip>
            )}

            <Tooltip title="View Column">
              <IconButton
                aria-label="viewColumn"
                className={classes.iconHover}
                onClick={handleClickListItem}
              >
                <ViewColumnIcon />
              </IconButton>
            </Tooltip>
            <EnhancedShowColumn
              id="columns-menu"
              keepMounted
              open={open}
              onClose={handleShowColClose}
              column={column}
              hendleVewColums={hendleVewColums}
              headCells={props.headCells}
            />
          </div>
        )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class ProcessTypeMater extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    processTypeList: [],
    rows: [],
    activeRows: 0,
    isActive: false,
    refreshData: false,
    selectedProcessType: {},
    //selectedProcessTypeId: 1,
    loading: false,
    searchPopupOpen: false,
    processTypeSearchValues: {},
    searchClicked: false,
    exportDataValues: [],
    selectedShowColumns: [],
    headCells: [
      {
        id: 1,
        code: "processTypeCode",
        numeric: false,
        disablePadding: true,
        label: "Process Type Code",
        show: true,
      },
      {
        id: 2,
        code: "processTypeName",
        numeric: false,
        disablePadding: true,
        label: "Process Type",
        show: true,
      },
      {
        id: 3,
        code: "isActive",
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true,
      },
      {
        id: 4,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 5,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 6,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 7,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };

  componentDidMount() {
    this.props.handleSettingHeader("Process Type");
    this.props.setSelectedList("masterDataOpen", "processType");
    const dispatch = this.context[1];

    getProcessTypeList(window.localStorage.token).then((r) => {
      dispatch({
        type: types.PROCESSTYPE_LIST,
        newStore: r.data,
      });
      this.getAllProcessTypes(r.data);
      this.exportProcessTypes(r.data);
    });
    this.setState({ loading: true });
  }
  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };
  exportProcessTypes = (procesTypeList) => {
    let list = procesTypeList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          ProcessTypeCode: d.processTypeCode,
          ProcessTypeName: d.processTypeName,
          Active: d.isActive === true ? "Yes" : "No",
          ModifiedDate: d.modifiedDate,
          ModifiedBy: d.modifiedBy,
          CreatedDate: d.createdDate,
          CreatedBy: d.createdBy,
        });
      });
    this.setState({ exportDataValues: rows });
  };
  getAllProcessTypes = (processTypeList) => {
    let list = processTypeList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.processTypeID,
            d.processTypeCode,
            d.processTypeName,
            d.isActive,
            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate
          )
        );
      });
    this.sortStateList(rows);
    this.setState({
      processTypeList: rows,
      loading: false,
      selected: [],
      activeRows: rows.filter((x) => x.isActive).map((n) => n.processTypeID),
    });
  };

  sortStateList = (list) => {
    let z = list.sort(function (a, b) {
      let x = a.isActive && a.isActive;
      let y = b.isActive && b.isActive;
      let u = a.modifiedDate && a.modifiedDate;
      let v = b.modifiedDate && b.modifiedDate;
      if (x < y) {
        return 1;
      }
      if (u < v) {
        return -1;
      }
      if (x > y) {
        return -1;
      }
      if (u > v) {
        return 1;
      }
      return 0;
    });
    // console.log(z);
    this.setState({ rows: z });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.processTypeList && store.processTypeList.length > 0)
      if (this.state.processTypeList.length !== store.processTypeList.length) {
        this.getAllProcessTypes(store.processTypeList);
      }
    return true;
  }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { processTypeList } = this.state;
    if (event.target.checked) {
      const newSelecteds = processTypeList
        .filter((x) => x.isActive === true)
        .map((n) => n.processTypeID);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, data) {
    let processTypeID = data.processTypeID;
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(processTypeID);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, processTypeID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedProcessType: data,
        selectedProcessTypeId: processTypeID,
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        //selectedProcessType: {}
        selectedProcessType: data,
        selectedProcessTypeId: processTypeID,
      });
    }
  }
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchProcessType = (handleSearchProcessType) => {
    let processTypeSearchValue = handleSearchProcessType;
    const dispatch = this.context[1];
    let processTypeCode = processTypeSearchValue.processTypeCode;
    let processTypeName = processTypeSearchValue.processTypeName;

    let reqBody = { processTypeName, processTypeCode };

    getFilteredProcessTypeList(reqBody).then((r) => {
      dispatch({
        type: types.PROCESSTYPE_LIST,
        newStore: r.data,
      });
      this.getAllProcessTypes(r.data);
    });
    this.setState({
      loading: true,
      searchPopupOpen: false,
      searchClicked: true,
      page: 0,
    });
  };

  handleDeleteProcessType = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteProcessType(modifiedBy, deletionIds).then((r) => {
      getProcessTypeList().then((r) => {
        dispatch({
          type: types.PROCESSTYPE_LIST,
          newStore: r.data,
        });
        this.getAllProcessTypes(r.data);
      });
      this.props.history.push("/ProcessType");
      this.setState({ deletePopupOpen: false, selected: [] });
      //toastr.success("ProcessType Deleted Successfully");
    });
  };

  clearSearch = () => {
    const dispatch = this.context[1];
    getProcessTypeList().then((r) => {
      dispatch({
        type: types.PROCESSTYPE_LIST,
        newStore: r.data,
      });
      this.getAllProcessTypes(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      processTypeList,
      exportDataValues,
      headCells,
    } = this.state;
    const isSelected = (processTypeID) =>
      selected.indexOf(processTypeID) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteProcessType={this.handleDeleteProcessType}
            selectedProcessType={this.state.selectedProcessType}
            selectedProcessTypeId={this.state.selectedProcessTypeId}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchProcessType={this.handleSearchProcessType}
            processTypeSearchValue={this.state.processTypeSearchValues}
            searchClicked={this.state.searchClicked}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            processTypeList={processTypeList}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 8em)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
                  : {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 330px)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {processTypeList.length > 0 ? (
                    stableSort(processTypeList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.processTypeID);
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              this.handleClick(
                                event,
                                // row.processTypeID,
                                //row.processTypeName
                                row
                              )
                            }
                            processType="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            //key={row.processTypeID}
                            key={index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.processTypeCode} */}
                                {row.processTypeCode &&
                                row.processTypeCode.length > 16 ? (
                                  <Tooltip
                                    title={row.processTypeCode}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.processTypeCode}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.processTypeCode}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.processTypeName}
                                </Typography> */}
                                {row.processTypeName &&
                                row.processTypeName.length > 16 ? (
                                  <Tooltip
                                    title={row.processTypeName}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.processTypeName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.processTypeName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                // id={labelId}
                              >
                                {status}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                // id={labelId}
                              >
                                {row.modifiedDate &&
                                row.modifiedDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                // id={labelId}
                              >
                                {/* {row.modifiedBy} */}
                                {row.modifiedBy &&
                                row.modifiedBy.length > 16 ? (
                                  <Tooltip
                                    title={row.modifiedBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.modifiedBy}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdDate &&
                                row.createdDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {/* {row.createdBy} */}
                                {row.createdBy && row.createdBy.length > 16 ? (
                                  <Tooltip
                                    title={row.createdBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.createdBy}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      {this.state.loading ? (
                        <TableCell colSpan={15} style={{ textAlign: "center" }}>
                          <LinearProgress />
                        </TableCell>
                      ) : (
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                          No result found
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={processTypeList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(ProcessTypeMater);
