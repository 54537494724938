import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_GENRIC_DOCKET_POSTING;
const pgsService = axios.create({
  baseURL,
});
let count = 1;

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getDocketList = async (docketId) => {
  let getDocketList = {};
  await pgsService
    .get("/GetDocketByIDView/" + docketId)
    .then((response) => {
      getDocketList = response.data;
    })
    .catch((e) => { });
  return getDocketList;
};
export const getDocketHeaderDetails = async (docketId) => {
  let pol = [];
  await pgsService
    .get(`/GPGetAll/${docketId}`)
    .then((r) => {
      // console.log(r);
      pol = r;
    })
    .catch((e) => { });
  return pol;
};
export const serchCustomerOrder = async (data) => {
  let customerOrderList = [];
  await pgsService
    .post("/DocketPostingAdvanceSearch", data)
    .then((r) => {
      customerOrderList = r;
    })
    .catch((e) => { });
  return customerOrderList;
};
