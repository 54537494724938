import React, { Component } from "react";
import { Autocomplete } from "@material-ui/lab";
import { addStyles } from "../../../shared/styles";
import { IconButton, Tooltip, Divider, Button, Input } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import { withRouter } from "react-router-dom";
import TableHead from "@material-ui/core/TableHead";
import { getLocationDropdownList } from "../../masters/masterActions";
import Paper from "@material-ui/core/Paper";
import { getDHLRateUponAWeight } from "../mainLabelOrderPlacement/action"
import { withStyles } from "@material-ui/core/styles";
import clone from "clone";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from "@date-io/date-fns";
import {
  getEMLOListforAdd,
  putEmloProcessList,
} from "../emloOrderPlacement/action";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
let autoCId = "";
const headCells = [
  {
    id: "emlo",
    numeric: false,
    disablePadding: true,
    label: "EMLO#",
  },
  {
    id: "issueDescription",
    numeric: false,
    disablePadding: false,
    label: "Issue Description",
  },
  {
    id: "recipient",
    numeric: false,
    disablePadding: false,
    label: "Recipient",
  },
  {
    id: "orderQuantity",
    numeric: false,
    disablePadding: false,
    label: "Order Qty",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "deliveryMode",
    numeric: false,
    disablePadding: false,
    label: "Delivery Mode",
  },
  // {
  //   id: "orderFileName",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Order File Name",
  //       show: true,
  // },
  // {
  //   id: "splInstruction",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Spl Instruction",
  // },
  {
    id: "country",
    numeric: false,
    disablePadding: false,
    label: "Country",
  },
  {
    id: "trackingNumber",
    numeric: false,
    disablePadding: false,
    label: "Tracking Nos*",
  },
  {
    id: "noOfCartons",
    numeric: false,
    disablePadding: false,
    label: "No Of Cartons",
  },
  {
    id: "actualWeight",
    numeric: false,
    disablePadding: false,
    label: "Actual Weight*",
  },
  {
    id: "deliveryDate",
    numeric: false,
    disablePadding: false,
    label: "Delivery Date*",
  },
  {
    id: "dhlRate",
    numeric: false,
    disablePadding: false,
    label: "DHL Rate",
  },
];
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
          // style={
          //   i === headCells.length - 1
          //     ? {
          //         borderRight: "1px solid #eaeaea",
          //       }
          //     : // :
          //       // i === 0
          //       // ? {
          //       //     borderLeft: "1px solid #eaeaea"
          //       //   }
          //       {}
          // }
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
class AddEMLOBP extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      list: [],
      countryList: [],
      countryId: "",
      countryObj: {},
      loading: false,
      // isValid: false,
    };
  }

  componentDidMount() {
    this.props.handleSettingHeader("EMLO Batch Processing");
    this.props.setSelectedList(
      "publisherMainLabelOpen",
      "pubEmloBatchProcessing"
    );
    this.props.handleDrawerClose();
    getLocationDropdownList().then((r) => {
      this.getAllLocation(r.data);
    });
    this.setState({ loading: true });

    getEMLOListforAdd().then((r) => {
      this.getAllList(r && r.data);
    });
  }

  getAllList = (data) => {
    let emloList = [];
    data &&
      data.length > 0 &&
      data.map((res) => {
        emloList.push({
          emloOrderID: res.emloOrderID,
          emlo: res.emloNo,
          issueDescription: res.issueDescription,
          recipient: res.recipientName,
          orderQuantity: res.quantity,
          category: res.category,
          deliveryMode: res.deliveryMethod,
          orderFileName: res.fileName,
          splInstruction: res.additionalInstructions,
          country: res.countryName,
          trackingNumber: res.trackingNumber,
          noOfCartons: res.noOfCartons ? res.noOfCartons : 0,
          dhlRate: res.dhlRate ? res.dhlRate : "",
          actualWeight: res.actualWeight ? res.actualWeight : "0.000",
          deliveryDate: res.deliveryDate,
          isValid:
            res.trackingNumber && res.actualWeight && res.deliveryDate
              ? true
              : false,
          countryID: res.countryID
        });
      });
    this.setState({
      list: emloList,
      loading: false,
    });
  };

  getAllLocation = (list) => {
    let locationList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        locationList.push({ key: c.id, value: c.text, name: c.text });
      });
    this.sortDDList(locationList, "locationList");
  };
  sortDDList = (list, name) => {
    let z = list.sort(function (a, b) {
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ [name]: z });
  };
  handleAutoOpenClick = (id) => {
    autoCId = id;
  };
  handleAutocompleteCountry = (e, val) => {
    let newList = clone(this.state.list);
    let newTS = clone(this.state.listToNext);
    newList[autoCId].countryId = val && val.id;
    newList[autoCId].countryObj = val && val;
    if (newTS[autoCId]) newTS[autoCId].countryId = val && val.id;
    if (newTS[autoCId]) newTS[autoCId].countryObj = val && val;
    this.setState({ list: newList, listToNext: newTS });
  };

  handleSubmit = (e) => {
    let list = [],
      reqBody = [];
    let loggedInId = localStorage.getItem("loggedInId");

    this.state.list.map((val) => {
      if (val.isValid)
        reqBody.push({
          ...val,
          modifiedBy: +loggedInId,
          // trackingNumber: val.trackingNos,
          actualWeight: val.actualWeight,
          deliveryDate: val.deliveryDate,
        });
    });
    putEmloProcessList(reqBody, this.props.history);
  };

  handleChange = (event, index, row) => {
    const { list } = this.state;
    const newList = clone(list);

    if (event.target && event.target.name == "actualWeight") {
      let emloValues = event.target.value;
      emloValues = this.checkDHlWeightDec(event.target.value.toString());
      newList[index][event.target.name] = emloValues;
      // let roundUpVal = 0
      // let noS = event.target.value ? event.target.value : 0
      // roundUpVal = Math.ceil(noS * 2) / 2
      // // roundUpVal = this.checkDHlRateDec(roundUpVal.toString())
      // newList[index]["dhlRate"] = roundUpVal;
      if (event.target.value === "") {
        newList[index]["dhlRate"] = "";
        return
      }
      if (event.target && event.target.value && row && row.countryID)
        getDHLRateUponAWeight(row.countryID, event.target.value).then(r => {
          // console.log(r)
          if (r && r.data) {
            newList[index]["dhlRate"] = r.data.dhlRate ? r.data.dhlRate : "";
          }
          else newList[index]["dhlRate"] = "";
        })
    }
    else if (event.target.name === "trackingNumber") {
      newList[index][event.target.name] = event.target.value;
      if (row && row.actualWeight !== "" && row.countryID)
        getDHLRateUponAWeight(row.countryID, row.actualWeight).then(r => {
          if (r && r.data) {
            newList[index]["dhlRate"] = r.data.dhlRate ? r.data.dhlRate : "";
            this.setState({ list: newList });
          }
          else newList[index]["dhlRate"] = "";
        })
    } else {
      newList[index][event.target.name] = event.target.value;
    }
    if (newList[index][event.target.name] !== list[index][event.target.name]) {
      document.getElementById(
        event.target.id
      ).parentElement.parentElement.parentElement.style =
        "background: rgb(0, 105, 217,0.3)";
    }
    if (
      newList[index].actualWeight &&
      newList[index].trackingNumber &&
      newList[index].deliveryDate
    ) {
      newList[index].isValid = true;
    } else {
      newList[index].isValid = false;
    }
    this.setState({ list: newList });
    // this.validateField(event.target.name, event.target.value);
  };

  handleDateChange = (date, index, name) => {
    const { list } = this.state;
    const newList = clone(list);
    list[index]["deliveryDate"] = date;
    if (
      list[index].actualWeight &&
      list[index].trackingNumber &&
      list[index].deliveryDate
    ) {
      list[index].isValid = true;
    } else {
      list[index].isValid = false;
    }
    this.setState({ list });

    if (newList[index]["deliveryDate"] !== list[index]["deliveryDate"]) {
      document.getElementById(
        "deliveryDate" + index
      ).parentElement.parentElement.parentElement.style =
        "background: rgb(0, 105, 217,0.3)";
    }
  };

  onBlur = (event, index) => {
    const { list } = this.state;
    let emloValues = event.target.value;
    emloValues = this.addZeroes(emloValues.toString(), 3);
    list[index][event.target.name] = emloValues;
    this.setState({ list });
  };

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  checkDHlRateDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  _onNumberPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkButtonDisability = () => {
    if (this.state.list && this.state.list.some((e) => e.isValid == true)) {
      return false;
    } else return true;
  };

  render() {
    let { classes } = this.props;
    let { list } = this.state;
    console.log(list)
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">
            <span>Update Tracking Info</span>
          </div>
          <Divider />
          <div className={classes.container} style={{ marginTop: "25px" }}>
            <div style={{ marginTop: "-15px" }}>
              <div className={classes.container}>
                <Divider />
                <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
                  <div style={
                    !this.props.clearOpenList
                      ? {
                        overflow: "auto",
                        maxWidth: "calc(100vw - 180px)",
                        maxHeight: "400px",
                      }
                      : {
                        overflow: "auto",
                        maxWidth: "calc(100vw - 390px)",
                        maxHeight: "400px",
                      }
                  }>
                    {/* //overflow: "auto" */}
                    <Table
                      aria-labelledby="tableTitle"
                      size="small"
                      stickyHeader
                      aria-label="sticky table"
                    // style={{ overflow: "auto" }}
                    >
                      <EnhancedTableHead
                        tabValue={this.state.value}
                        onSelectAllClicked={this.onSelectAllClicked}
                      />
                      {list && list.length > 0 ? (
                        <TableBody style={{ overflow: "hidden" }}>
                          {list &&
                            list.length > 0 &&
                            list.map((row, index) => {
                              // const isItemSelected = isSelected(row.id);
                              const labelId = `enhanced-table-checkbox-${index}`;

                              return (
                                <TableRow
                                  id={`tableRow${index}`}
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={index}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.emlo && row.emlo.length > 16 ? (
                                        <Tooltip
                                          title={row.emlo}
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.emlo}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.emlo}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={{
                                      maxWidth: "150px!important",
                                    }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.issueDescription &&
                                        row.issueDescription.length > 16 ? (
                                          <Tooltip
                                            title={row.issueDescription}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.issueDescription}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.issueDescription}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={{
                                      maxWidth: "100px!important",
                                    }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.recipient &&
                                        row.recipient.length > 16 ? (
                                          <Tooltip
                                            title={row.recipient}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.recipient}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.recipient}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.orderQuantity &&
                                        row.orderQuantity.length > 16 ? (
                                          <Tooltip
                                            title={row.orderQuantity}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.orderQuantity}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.orderQuantity}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.category &&
                                        row.category.length > 16 ? (
                                          <Tooltip
                                            title={row.category}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.category}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.category}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.deliveryMode &&
                                        row.deliveryMode.length > 16 ? (
                                          <Tooltip
                                            title={row.deliveryMode}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.deliveryMode}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.deliveryMode}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={{ minWidth: "100px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.country &&
                                        row.country.length > 16 ? (
                                          <Tooltip
                                            title={row.country}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.country}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.country}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                    style={{ minWidth: "100px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      <Input
                                        defaultValue=""
                                        id={"trackingNumber" + index}
                                        inputProps={{
                                          "aria-label": "trackingNumber",
                                          maxLength: 200,
                                        }}
                                        style={{ width: "100%" }}
                                        name="trackingNumber"
                                        value={row.trackingNumber}
                                        onChange={(e) =>
                                          this.handleChange(e, index, row)
                                        }
                                      />
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                    style={{ minWidth: "100px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      <Input
                                        defaultValue=""
                                        id={"noOfCartons" + index}
                                        inputProps={{
                                          "aria-label": "noOfCartons",
                                          maxLength: 200,
                                        }}
                                        style={{ width: "100%" }}
                                        name="noOfCartons"
                                        value={row.noOfCartons}
                                        onChange={(e) =>
                                          this.handleChange(e, index)
                                        }
                                      />
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                    style={{ minWidth: "100px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      <Input
                                        defaultValue=""
                                        id={"actualWeight" + index}
                                        inputProps={{
                                          "aria-label": "actualWeight",
                                          maxLength: 200,
                                        }}
                                        onKeyPress={this._onNumberPress}
                                        style={{ width: "100%" }}
                                        name="actualWeight"
                                        value={row.actualWeight}
                                        onChange={(e) =>
                                          this.handleChange(e, index, row)
                                        }
                                        onBlur={(e) => this.onBlur(e, index)}
                                      />
                                    </Typography>
                                  </TableCell>{" "}
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                    style={{ "min-width": "140px" }}
                                  >
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <KeyboardDatePicker
                                        autoOk
                                        // disablePast
                                        required
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        className={classes.textField}
                                        id={"deliveryDate" + index}
                                        name="deliveryDate"
                                        value={row.deliveryDate}
                                        onChange={(e) =>
                                          this.handleDateChange(
                                            e,
                                            index,
                                            "deliveryDate"
                                          )
                                        }
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                        style={{
                                          width: "100%",
                                          textAlign: "left",
                                          margin: "0",
                                        }}
                                        ref={this.myRef}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.dhlRate &&
                                        row.dhlRate.length > 16 ? (
                                          <Tooltip
                                            title={row.dhlRate}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.dhlRate}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.dhlRate}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      ) : (
                          <TableBody>
                            {this.state.loading ? (
                              <TableCell
                                colSpan={10}
                                style={{ textAlign: "center" }}
                              >
                                <LinearProgress />
                              </TableCell>
                            ) : (
                                <TableCell
                                  colSpan={10}
                                  style={{ textAlign: "center" }}
                                >
                                  No result found
                                </TableCell>
                              )}
                          </TableBody>
                        )}
                    </Table>
                  </div>
                </div>
              </div>
            </div>
            <Divider style={{ margin: "0 1em" }} />

            <div
              className="button-wrapper"
              style={{ paddingLeft: "0px", margin: "0 1em" }}
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) =>
                    this.props.history.push("/pubEmloBatchProcessing")
                  }
                >
                  CANCEL
                </Button>
              </div>
              <div style={{ marginLeft: "1em" }}>
                <Button
                  disabled={this.checkButtonDisability()}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={this.handleSubmit}
                >
                  SUBMIT
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddEMLOBP));
