import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { resourceValidation } from "../../../shared/resource";
import toastr from "toastr"
import AddCircleIcon from "@material-ui/icons/AddCircle";
import InfoIcon from "@material-ui/icons/Info";
import DateFnsUtils from "@date-io/date-fns";
import DescriptionIcon from "@material-ui/icons/Description";
import LinearProgress from "@material-ui/core/LinearProgress";
import moment from "moment"
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DeleteIcon from "@material-ui/icons/Delete";
import { getCustomerForInsertMasterList } from "../action";
import {
  postEmailInsertMaster,
  deleteInsertmasterByID,
  getEMLOList,
  getEMLONumberList,
  validateEmailEMLO,
} from "./action";
import { getDHLRateUponAWeight } from "../mainLabelOrderPlacement/action"
import { Autocomplete } from "@material-ui/lab";
import { getCountryDropdownList } from "../../masters/masterActions";
import { postEmloList } from "./action";
import IndividualSearchAddDialog from "../../publisherBackLabel/ebloOrderPlacement/IndividualSearchAddDialog";

const mobileValidator = new Validator("mobile");
const validateMaxLength2 = new Validator("maxlength2");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginRight: "16px",
    fontSize: "11px !important",
    flex: 1,
  },
  emptyField: {
    marginRight: "16px",
    flexGrow: 1,
  },
  lastChild: {
    marginRight: 0,
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
});

function DetectionControl(props) {
  return (
    <div
      style={{
        width: "45%",
        display: "flex",
        alignItems: "center",
      }}
    >
      {props.message && props.message.toLowerCase().indexOf("present") > -1 || props.message && props.message.toLowerCase().includes("contain") ? (
        <span>
          <InfoIcon style={{ color: "red", fontSize: "19px" }} />
          <span style={{ position: "relative", top: "-5px", left: "4px" }}>
            {props.message}
          </span>
        </span>
      ) : (
          <span>
            <CheckCircleIcon
              style={{ color: "rgba(59, 218, 182, 0.87)", fontSize: "19px" }}
            />
            <span style={{ position: "relative", top: "-5px", left: "4px" }}>
              {props.message}
            </span>
          </span>
        )}
    </div>
  );
}

class AddEmloOrderPlacement extends React.Component {
  constructor() {
    super();

    this.state = {
      customerName: "",
      customersList: [],
      journalID: "",
      customer: {},
      customerId: "",
      countryList: [],
      country: {},
      countryId: "",
      countryCode: "",
      emlo: "",
      orderCreatedBy: "",
      department: "",
      issueType: "",
      category: "",
      acronym: "",
      volume: "",
      issue: "",
      support: "",
      part: "",
      issueDescription: "",
      quantity: "",
      unitWeight: "0.000",
      totalWeight: "0.000",
      orderDate: null,
      distributerDate: null,
      deliveryMethod: "",
      deliveryDetails: "",
      companyName: "",
      recipientName: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      uploadFiles: "",
      state: "",
      postCode: "",
      areaCode: "",
      number: "",
      trackingNumber: "",
      noOfCartons: 0,
      actualWeight: "0.000",
      deliveryDate: null,
      dhlRate: "",
      dept: "",
      errors: {},
      formValid: false,
      stateFiles: [],
      isManual: false,
      loading: false,
      isDuplicateData: false,
      wholeList: [],
      isTrackingInfoRequired: "",
      additionalInstructions: "",
      msg: [],
      msgDispArray: [],
      msgDispObj: {},
      phoneValid: false,
      deletePopupOpen: false,
      openDialog: false,
      journalData: {
        journalID: "",
        issueDescription: "",
        acronym: "",
        journalTitle: "",
        volume: "",
        issue: "",
        supplement: "",
      },
    };

    this.myRef = React.createRef();
  }
  componentDidMount() {
    this.props.handleSettingHeader("EMLO Order Placement");
    this.props.setSelectedList(
      "publisherMainLabelOpen",
      "pubEmloOrderPlacement"
    );

    getEMLONumberList().then((r) => {
      this.getEMLO(r.data);
    });

    getCustomerForInsertMasterList().then((r) => {
      r &&
        r.data &&
        this.setState({
          customerList:
            r &&
            r.data &&
            r.data
              .filter((x) => x.text.toLowerCase().indexOf("wiley") != -1)
              .sort((a, b) =>
                a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
              ),
        });

      const filtered =
        r &&
        r.data &&
        r.data.reduce(
          (a, o) => (
            o.text.toLowerCase().indexOf("wiley") != -1 && a.push(o.id), a
          ),
          []
        );
      const filteredText =
        r &&
        r.data &&
        r.data.reduce(
          (a, o) => (
            o.text.toLowerCase().indexOf("wiley") != -1 && a.push(o.text), a
          ),
          []
        );
      this.setState({
        customerId: filtered && filtered[0],
        customerName: filteredText && filteredText[0],
      });
    });

    getCountryDropdownList().then((r) => {
      r.data &&
        this.setState({
          countryList:
            r &&
            r.data &&
            r.data.sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
    });
  }

  getEMLO = (lpList) => {
    let list = lpList;
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        newRows.push(d.emloNo);
      });
    this.setState({ wholeList: newRows, loading: false });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let phoneValid = this.state.phoneValid;
    switch (fieldName) {
      case "number":
        if (value !== "") {
          phoneValid = true;
          fieldValidationErrors.number = phoneValid
            ? ""
            : resourceValidation.number;
        }
        break;

      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        phoneValid: phoneValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.phoneValid,
    });

    return this.state.formValid;
  }

  handleAddJournalClick = () => {
    this.setState({
      openDialog: true,
    });
  };

  handleAddJournalClose = () => {
    this.setState({
      openDialog: false,
    });
  };

  handleChange = async (e, validate) => {
    if (e.target && e.target.name == "emlo") {
      let newRrrors = { ...this.state.errors };
      let result = this.state.wholeList.includes(e.target.value);
      newRrrors[e.target.name] = result;
      await this.setState({
        [e.target.name]: e.target.value,
        errors: newRrrors,
      });
    } else if ((e.target) &&
      e.target.name == "actualWeight" ||
      e.target.name == "totalWeight" ||
      e.target.name == "unitWeight"
    ) {
      let newRrrors = { ...this.state.errors };
      let result = e.target.value;
      newRrrors[e.target.name] = !result;
      let emloValues = e.target.value;
      emloValues = this.checkDHlWeightDec(e.target.value.toString());
      this.setState({ [e.target.name]: emloValues });
    } else {
      let newRrrors = { ...this.state.errors };
      let result = e.target.value;
      newRrrors[e.target.name] = !result;
      this.setState({ [e.target.name]: e.target.value });
    }
    if (e.target && e.target.name == "number") {
      let newRrrors = { ...this.state.errors };
      let result = mobileValidator(e.target.value);
      newRrrors[e.target.name] = !result;
      this.setState({ [e.target.name]: e.target.value });
    }
    if (e.target && e.target.name == "actualWeight") {
      // let roundUpVal = 0
      // let noS = e.target.value ? e.target.value : 0
      // roundUpVal = Math.ceil(noS * 2) / 2
      // this.setState({ dhlRate: roundUpVal });
      // console.log(this.state)
      if (e.target && e.target.value && this.state.country && this.state.country.id)
        getDHLRateUponAWeight(this.state.country.id, e.target.value).then(r => {
          // console.log(r)
          if (r && r.data) {
            this.setState({ dhlRate: r.data.dhlRate ? r.data.dhlRate : "" });
          }
          else this.setState({ dhlRate: "" });
        })
    }
  };

  handleClose = () => {
    // clean up state and errors
    let newZone = { ...this.state.Zone };
    newZone.ZoneCode = "";
    newZone.ZoneName = "";
    this.setState({
      Zone: newZone,
      errors: {},
    });
  };
  onYorNKeyPress(event) {
    const re = /[yYnN]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  onSwitch = () => {
    this.setState({ deletePopupOpen: true });
    // this.setState({ isManual: !this.state.isManual });
  };

  handleDeleteConfirmModalClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleDeleteConfirmModalSwitch = () => {
    if (this.state.isManual) {
      let journalData = {
        acronym: "",
        volume: "",
        issue: "",
        supplement: "",
        issueDescription: "",
      };
      this.setState({
        emlo: "",
        orderCreatedBy: "",
        city: "",
        additionalInstructions: "",
        isTrackingInfoRequired: "",
        department: "",
        warehouseEmail: "",
        recipient: "",
        specialInstructions: "",
        deliveryMethod: "",
        deliveryDetails: "",
        addressLine: "",
        state: "",
        postCode: "",
        PGSCountry: "",
        category: "",
        country: "",
        countryCode: "",
        customerCountryCode: "",
        areaCode: "",
        quantity: "",
        number: "",
        issueType: "",
        journalDispatch: "",
        unitWeight: "",
        totalWeight: "",
        orderDate: null,
        distributerDate: null,
        deliveryMethod: "",
        deliveryDetails: "",
        companyName: "",
        recipientName: "",
        department: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        uploadFiles: "",
        state: "",
        postCode: "",
        areaCode: "",
        number: "",
        trackingNumber: "",
        actualWeight: "0.000",
        deliveryDate: null,
        dept: "",
        errors: {},
        part: "",
        journalData: journalData,
      });
    } else {
      this.setState({
        msg: [],
        msgDispArray: [],
        msgDispObj: {},
      });
    }
    this.setState({ isManual: !this.state.isManual, deletePopupOpen: false });
  };

  handleCapture = ({ target }) => {
    const arrayOfObjOld = Object.values(target.files);
    let arrayOfObj = []
    let invalidfilesArr = []
    let testMsgFile = false
    arrayOfObjOld.map(x => {
      if (x.name.toLowerCase().endsWith(".msg")) {
        testMsgFile = true
        if (x.name.toLowerCase().includes("emlo")) {
          arrayOfObj.push(x)
        }
        else {
          invalidfilesArr.push({
            name: x.name,
            size: x.size,
            message: "File name must contain EMLO",
          })
        }
      }
      else {
        toastr.error("Only .msg files can be uploaded !")
      }
    })
    if (!testMsgFile)
      return
    let msgArray = this.state.msg;
    let msgDispArray = this.state.msgDispArray;
    let msgDispObj = this.state.msgDispObj;
    let arrayOfObjFiltered = arrayOfObj.filter(
      (x) => !msgArray.filter((y) => y.name === x.name).length
    );
    this.setState({ msg: [...this.state.msg, ...arrayOfObjFiltered] }, () => {
      this.state.msg.map((file, i) => {
        msgDispArray[i] = msgDispArray[i] || "Uploaded Successfully";
        msgDispObj[file] = msgDispArray[file] || "Uploaded Successfully";
      });
      this.setState({
        msgDispArray: msgDispArray,
        msgDispObj: msgDispObj,
      });

      let files = [...this.state.msg];
      let _error = "";

      if (files.length > 0) {
        let customerId = this.state.customerId;
        let createdBy = window.localStorage.loggedInId;
        let modifiedBy = window.localStorage.loggedInId;
        let customerName = this.state.customerName;
        let formData = new FormData();
        let stf = [];
        let secondCheck = false
        Array.from(files).map((f) => {
          if (!f.apiCallNotRequired) {
            secondCheck = true
            formData.append("Uploadfiles", f);
            stf.push({
              name: f.name,
              size: f.size,
              message: "",
            });
          }
        });

        formData.append("CustomerID", customerId);
        formData.append("CreatedBy", createdBy);
        formData.append("ModifiedBy", modifiedBy);
        formData.append("CustomerName", customerName);
        if (secondCheck)
          validateEmailEMLO(formData).then((r) => {
            // this.setState({ loading: true })
            let msgDispArray = [];
            let msgDispObj = {};
            r.data.map((val, i) => {
              if (val.exceptionMessage == "") {
                msgDispArray[i] = "Uploaded Successfully";
                msgDispObj["expMsg"] = "Uploaded Successfully";
              } else {
                msgDispArray[i] = val.exceptionMessage;
                msgDispObj["expMsg"] = val.exceptionMessage;
              }
            });


            this.setState({
              msgDispArray: msgDispArray,
              msgDispObj: msgDispObj,
              // msg: r.data,
              // loading: false
            });
          });

      }
      let xp = []
      let msgDispArray2 = this.state.msgDispArray
      let msgDispObj2 = this.state.msgDispObj
      let filteredXP = invalidfilesArr.filter(
        (x) => !msgArray.filter((y) => y.name === x.name).length
      );
      filteredXP.length > 0 && filteredXP.map(sd => {
        xp.push({
          name: sd.name,
          size: sd.size,
          message: sd.message,
          apiCallNotRequired: true
        });
        msgDispArray2.push("File name must contain EMLO")
        msgDispObj2["expMsg"] = "File name must contain EMLO"
      })
      let pc = [...this.state.msg, ...xp]
      this.setState({ msg: pc, msgDispArray: msgDispArray2, msgDispObj: msgDispObj2 })
    });
  };

  deleteSelectedFile = (e, i, id) => {
    e.persist();
    this.myRef.current.value = "";
    let msgDispArray = [...this.state.msgDispArray];
    let msgDispObj = this.state.msgDispObj;
    let newStateFiles = [...this.state.msg];
    newStateFiles.splice(i, 1);
    msgDispArray.splice(i, 1);
    delete msgDispObj[id.name];
    this.setState({
      msg: newStateFiles,
      msgDispArray: msgDispArray,
      msgDispObj: msgDispObj,
    });
  };

  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertKeyPress(event) {
    const re = /[a-zA-Z0-9-#]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextKeyPress(event) {
    const re = /[a-z A-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusNumberKeyPress(event) {
    const re = /[0-9 a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onWeightKeyPress(event) {
    const re = /[0-9 a-zA-Z.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertSizeKeyPress(event) {
    const re = /[0-9 a-zA-Z-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusAllPress(event) {
    const re = /[a-z A-Z0-9=.;()&@]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleJournalUpdate = (selectedJournalList) => {
    this.setState({
      journalData: selectedJournalList[0],
      openDialog: false,
    });
  };

  clearJournalInfo = (i) => {
    let newjournalData = { ...this.state.journalData };
    newjournalData = {};
    this.setState({ workOrder: newjournalData });
  };

  handleSubmit = (event, manual) => {
    let { journalData } = this.state;
    let journalID = journalData.journalID;
    let acronym = journalData.acronym;
    let volume = journalData.volume;
    let issue = journalData.issue;
    let supp = journalData.supplement;
    let issueDescription = journalData.issueDescription;

    let customerID = this.state.customerId;
    let isManual = this.state.isManual;
    let jouranlUniqueID = journalData.issueDescription;
    let emloNo = this.state.emlo;
    let orderCreatedBy = this.state.orderCreatedBy;
    let department = this.state.department;
    let issueType = this.state.issueType;
    let category = this.state.category;
    let part = this.state.part;
    let quantity = this.state.quantity;
    let estUnitWeight = this.state.unitWeight;
    let estTotalWeight = this.state.totalWeight;
    let orderDate = this.state.orderDate ? moment(this.state.orderDate).format("YYYY-MM-DD") : null;;
    let stocktoDistributorDate = this.state.distributerDate ? moment(this.state.distributerDate).format("YYYY-MM-DD") : null;;
    let deliveryMethod = this.state.deliveryMethod;
    let deliveryDetails = this.state.deliveryDetails;
    let companyName = this.state.companyName;
    let recipientName = this.state.recipientName;
    let dept = this.state.dept;
    let addressLine1 = this.state.addressLine1;
    let addressLine2 = this.state.addressLine2;
    let addressLine3 = this.state.addressLine3;
    let city = this.state.city;
    let state = this.state.state;
    let postCode = this.state.postCode;
    let countryID = this.state.countryId;
    let countryCode = this.state.countryCode;
    let areaCode = this.state.areaCode;
    let number = this.state.number;
    let uploadedFiles = this.state.uploadFiles;

    let isTrackingInfoRequired = this.state.isTrackingInfoRequired;
    let additionalInstructions = this.state.additionalInstructions;
    let trackingNumber = this.state.trackingNumber;
    let noOfCartons = this.state.noOfCartons ? this.state.noOfCartons : 0
    let dhlRate = this.state.dhlRate
    let actualWeight = this.state.actualWeight;
    let deliveryDate = this.state.deliveryDate ? moment(this.state.deliveryDate).format("YYYY-MM-DD") : null;

    let isActive = true;
    let createdBy = localStorage.loggedInId;
    let modifiedBy = localStorage.loggedInId;
    let countryName =
      this.state.country &&
      this.state.country.text &&
      this.state.country.text.split("- ")[1];

    let reqbody1 = {
      journalID,
      acronym,
      volume,
      issue,
      supp,
      issueDescription,
      uploadedFiles,
      customerID,
      dept,
      isManual,
      jouranlUniqueID,
      emloNo,
      orderCreatedBy,
      department,
      issueType,
      category,
      part,
      quantity,
      estUnitWeight,
      estTotalWeight,
      orderDate,
      stocktoDistributorDate,
      deliveryMethod,
      deliveryDetails,
      companyName,
      recipientName,
      dept,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      state,
      postCode,
      countryID,
      countryCode,
      areaCode,
      number,
      isTrackingInfoRequired,
      additionalInstructions,
      trackingNumber,
      noOfCartons,
      dhlRate,
      actualWeight,
      deliveryDate,
      isActive,
      createdBy,
      modifiedBy,
      countryName,
    };

    let files = this.state.msg;
    var reader = new FileReader();
    let _error = "";

    if (files.length > 0 && manual == false) {
      reader.onload = function (e) {
        let data = e.target.result;
        // let readedData = XLSX.read(data, { type: "binary" });
        // const wsname = readedData.SheetNames[0];
        // const ws = readedData.Sheets[wsname];
        // const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
        // document.getElementById("result").innerHTML = resultArray;
      };

      reader.readAsBinaryString(files[0]);

      let loggedInId = localStorage.getItem("loggedInId");
      const formData = new FormData();
      let stf = [];
      Array.from(files).map((f) => {
        formData.append("Uploadfiles", f);
        stf.push({
          name: f.name,
          size: f.size,
          message: "",
          isValid: f.isValid,
          // insertMasterFileHeaderID: f.insertMasterFileHeaderID,
        });
      });
      this.setState({ msg: stf });

      formData.append("CustomerID", customerID);
      formData.append("CreatedBy", createdBy);
      formData.append("ModifiedBy", modifiedBy);
      formData.append("CustomerName", this.state.customerName);
      this.setState({ loading: true });

      postEmailInsertMaster(formData, this.props.history).then((r) => {
        let errorMessage =
          r &&
          r.data &&
          r.data.map((val) => {
            return val.exceptionMessage;
          });
        let isValid =
          r &&
          r.data &&
          r.data.map((val) => {
            return val.isValid;
          });
        let insertMasterFileHeaderID =
          r &&
          r.data &&
          r.data.map((val) => {
            return val.insertMasterFileHeaderID;
          });
        Array.from(files).map((f, index) => {
          stf[index].message = errorMessage[index];
          stf[index].isValid = isValid[index];
          stf[index].insertMasterFileHeaderID = insertMasterFileHeaderID[index];
          if (errorMessage[index] == "") {
            this.setState({
              isDuplicateData: false,
            });
          } else {
            this.setState({
              isDuplicateData: true,
            });
          }
        });
      });

      this.setState({ msg: stf });
    }

    if (this.state.isManual && manual == true) {
      postEmloList(reqbody1, this.props.history);
    }
  };

  handleAutocompleteCustomer = (e, val) => {
    this.setState({ customerId: val && val.id, customer: val && val });
  };

  handleAutocompleteCountry = (e, val) => {
    this.setState({
      countryId: val && val.id,
      country: val && val,
      countryCode: val == null ? "" : val && val.value,
    });
    if (val && val.id && this.state.actualWeight)
      getDHLRateUponAWeight(val.id, this.state.actualWeight).then(r => {
        // console.log(r)
        if (r && r.data) {
          this.setState({ dhlRate: r.data.dhlRate ? r.data.dhlRate : "" });
        }
        else this.setState({ dhlRate: "" });
      })
  };

  _freetext(event) {
    const re = /[#.0-9a-zA-Z\s,-@()%!$%^&*{}/<>_;:'"()+ |]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  onBlur = (event) => {
    // const { dhlRateValues } = this.state;
    let emloValues = event.target.value;
    emloValues = this.addZeroes(emloValues.toString(), 3);
    this.setState({ [event.target.name]: emloValues });
  };

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  _onMobileNumberKeyPress(event) {
    const re = /[0-9+ ()-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  checkInvalidFiles = () => {
    let valid = false
    this.state.msg && this.state.msg.length > 0 && this.state.msg.map(mg => {
      if (mg.apiCallNotRequired)
        valid = true
    })
    return valid
  }
  render() {
    let { classes } = this.props;
    let {
      msg,
      countryList,
      customersList,
      customer,
      emlo,
      orderCreatedBy,
      department,
      issueType,
      category,
      part,
      quantity,
      unitWeight,
      totalWeight,
      orderDate,
      distributerDate,
      deliveryMethod,
      deliveryDetails,
      companyName,
      recipientName,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      state,
      postCode,
      country,
      countryCode,
      areaCode,
      number,
      trackingNumber,
      noOfCartons,
      actualWeight,
      deliveryDate,
      dhlRate,
      journalData,
      isManual,
    } = this.state;

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create EMLO Order Placement</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <div className={classes.container}>
                <div style={{ display: "flex" }}>
                  <TextField
                    inputProps={{
                      readOnly: true,
                    }}
                    label="Customer"
                    name="customer"
                    value={this.state.customerName}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    className={classes.textField}
                    variant="filled"
                  />
                  {/* <Autocomplete
                    //autoFocus
                    options={customersList}
                    value={this.state.customer}
                    onChange={this.handleAutocompleteCustomer}
                    getOptionLabel={(option) => option.text}
                    style={{
                      width: "33.33%",
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoFocus
                        label="Customer"
                        style={{
                          width: "100%",
                          fontSize: "11px !important",
                          marginLeft: ".5em",
                          marginRight: ".5em",
                          maxHeight: "400px",
                          marginLeft: "0px",
                          marginRight: "0px",
                        }}
                        // name="plannerId"
                        margin="normal"
                        required
                      />
                    )}
                  /> */}
                  <div
                    className={classes.textField}
                    style={{ alignSelf: "flex-end", margin: "16px 16px 8px 0" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => this.onSwitch()}
                      style={{
                        height: "auto",
                        display: " inline-block",
                        width: "100%",
                        verticalAlign: "middle",
                        boxShadow: "none",
                      }}
                    >
                      {isManual ? "File Upload Mode" : "Manual Mode"}
                    </Button>
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      onClose={this.handleDeleteConfirmModalClose}
                      aria-labelledby="customized-dialog-title"
                      open={this.state.deletePopupOpen}
                      maxWidth="lg"
                    >
                      <DialogTitle id="customized-dialog-title">
                        Switch to{" "}
                        {isManual ? "File Upload Mode" : "Manual Mode"}
                      </DialogTitle>
                      <DialogContent dividers>
                        <div style={{ minWidth: "300px" }}>
                          Are you sure you want to switch to{" "}
                          {isManual ? "File Upload Mode" : "Manual Mode"} ?
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          // autoFocus
                          onClick={this.handleDeleteConfirmModalClose}
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                        >
                          Close
                        </Button>
                        <Button
                          //autoFocus
                          onClick={this.handleDeleteConfirmModalSwitch}
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                        >
                          Switch
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  <div className={classes.emptyField}></div>
                  <div
                    className={[classes.emptyField, classes.lastChild].join(
                      " "
                    )}
                  ></div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      backgroundColor: isManual ? "#9e9e9e" : "white",
                      color: "#515151",
                      borderRadius: "4px",
                      padding: ".3em 1em .3em 1em",
                      width: "122px",
                      height: "24px",
                      marginTop: "1.5em",
                      border: isManual
                        ? "1px dashed #9e9e9e"
                        : "1px dashed #3982ea",
                      cursor: isManual ? "default" : "pointer",
                      pointerEvents: isManual ? "none" : "all",
                    }}
                  >
                    <label
                      className="upload excel"
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => this.handleCapture(e)}
                        accept=".msg"
                        multiple={true}
                        // ref={(ref) => (this.myRef = ref)}
                        ref={this.myRef}
                      />
                      {!isManual ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <AddCircleIcon
                            style={{ color: "#3982ea", marginLeft: "-10px" }}
                          />{" "}
                          <span
                            style={{ marginLeft: "2px", fontWeight: "500" }}
                          >
                            Upload E-Mail
                          </span>
                        </div>
                      ) : (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <AddCircleIcon
                              style={{ color: "#fff", marginLeft: "-10px" }}
                            />{" "}
                            <span
                              style={{
                                marginLeft: "2px",
                                fontWeight: "500",
                                color: "#fff",
                              }}
                            >
                              Upload E-Mail
                          </span>
                          </div>
                        )}
                    </label>
                  </div>
                  {isManual ? (
                    <div
                      style={{
                        padding: ".3em 1em .3em 1em",
                        marginTop: "1.5em",
                        marginLeft: "1em",
                        borderRadius: "4px",
                        boxShadow:
                          "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                        // border: "1px solid #fff",
                        background: "#9e9e9e",
                      }}
                    >
                      <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <InfoIcon
                            style={{ color: "#fff", marginLeft: "-10px" }}
                          />{" "}
                          <span
                            style={{
                              marginLeft: "4px",
                              fontSize: "11px",
                              color: "#fff",
                            }}
                          >
                            Upload outlook email .msg file only
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                      <div
                        style={{
                          padding: ".3em 1em .3em 1em",
                          marginTop: "1.5em",
                          marginLeft: "1em",
                          borderRadius: "4px",
                          boxShadow:
                            "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                        }}
                      >
                        <div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <InfoIcon
                              style={{ color: "#3982ea", marginLeft: "-10px" }}
                            />{" "}
                            <span
                              style={{
                                marginLeft: "4px",
                                fontSize: "11px",
                                color: "gray",
                              }}
                            >
                              Upload outlook email .msg file only
                          </span>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                <div style={{ marginTop: "30px" }}>
                  <div>
                    {msg.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          margin: "1em",
                          // marginTop: "2em",
                          paddingBottom: ".5em",
                          borderBottom: "1px solid #eaeaea",
                          fontSize: "14px",
                          marginLeft: "0px",
                          marginRight: "0px",
                        }}
                      >
                        <div style={{ width: "30%", fontWeight: "500" }}>
                          File Info
                        </div>
                        <div
                          style={{
                            width: "10%",
                            fontWeight: "500",
                            margin: "0 10px",
                          }}
                        >
                          Size
                        </div>
                        <div style={{ width: "45%", fontWeight: "500" }}>
                          Detection
                        </div>
                        <div style={{ width: "10%", fontWeight: "500" }}>
                          Action
                        </div>
                      </div>
                    )}

                    {msg.map((file, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            margin: "1em",
                            paddingBottom: ".5em",
                            borderBottom: "1px solid #eaeaea",
                            fontSize: "12px",
                            marginLeft: "0px",
                            marginRight: "0px",
                          }}
                        >
                          <div style={{ width: "30%", fontWeight: "500" }}>
                            <div style={{ display: "flex" }}>
                              <DescriptionIcon
                                style={{
                                  color: "#306dca",
                                  marginTop: "-4px",
                                  marginRight: "5px",
                                }}
                              />{" "}
                              <span> {file.name}</span>

                            </div>
                          </div>
                          <div style={{ width: "10%", margin: "0 10px" }}>
                            {Math.ceil(file.size / 1024) + " kb"}
                            {/* {file.fileSize} */}
                          </div>
                          <DetectionControl
                            message={file.message ? file.message : "Uploaded Successfully"}
                          />
                          {/* {this.state.msgDispObj["expMsg"] ? (
                            <DetectionControl
                               message={this.state.msgDispArray[i]}
                              message={this.state.msgDispObj["expMsg"]}
                            />
                          ) : (
                              <LinearProgress style={{ width: "45%" }} />
                            )} */}
                          {/* {
                            this.state.loading ? <LinearProgress style={{ width: "45%" }} /> : <DetectionControl >{file.exceptionMessage} ? {file.exceptionMessage} : "Upload Successfully"</DetectionControl>
                          } */}
                          <IconButton
                            size="small"
                            style={{ marginTop: "-7px", width: "4%" }}
                            onClick={(e) => {
                              this.deleteSelectedFile(e, i, msg[i]);
                            }}
                          >
                            <DeleteIcon style={{ fontSize: "16px" }} />
                          </IconButton>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {this.state.isManual && (
                  <div>
                    {" "}
                    <p
                      className={classes.subHeader}
                      style={{ fontWeight: "500" }}
                    >
                      Manual EMLO Order Placement
                    </p>
                    <div>
                      <p
                        style={{ display: "inline-block", marginRight: "1em" }}
                        className={classes.subHeader}
                      >
                        Journal Details
                      </p>
                      <Button
                        disabled={isManual ? false : true}
                        onClick={this.handleAddJournalClick}
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{
                          width: "172px",
                          padding: ".2em",
                          marginTop: "-1em",
                          boxShadow: "none",
                        }}
                      >
                        <span>CHOOSE JOURNAL</span>
                      </Button>
                    </div>
                    {this.state.openDialog && (
                      // <PopupSearch
                      //   // jobGroupList={jobGroupList}
                      //   clearJournalInfo={this.clearJournalInfo}
                      //   handleJournalInfo={this.handleJournalUpdate}
                      //   selectedJournal={this.state.journalData}
                      // />
                      <IndividualSearchAddDialog
                        title="Journal Pick Up"
                        showDialog={this.state.openDialog}
                        handleAddJournalClose={this.handleAddJournalClose}
                        handleJournalUpdate={this.handleJournalUpdate}
                      />
                    )}
                    <div style={{ display: "flex" }}>
                      <TextField
                        required
                        label="Acronym"
                        name="acronym"
                        inputProps={{
                          maxLength: 50,
                          readOnly: true,
                        }}
                        onKeyPress={this._onNumberKeyPress}
                        margin="normal"
                        className={classes.textField}
                        disabled
                        variant="filled"
                        value={journalData.acronym}
                        onChange={this.handleChange}
                      />
                      <TextField
                        required
                        label="Volume"
                        name="volume"
                        inputProps={{
                          maxLength: 50,
                          readOnly: true,
                        }}
                        onKeyPress={this._onTextPlusNumberKeyPress}
                        margin="normal"
                        className={classes.textField}
                        disabled
                        variant="filled"
                        value={journalData.volume}
                        onChange={this.handleChange}
                      />
                      <TextField
                        required
                        label="Issue"
                        name="issue"
                        inputProps={{
                          maxLength: 50,
                          readOnly: true,
                        }}
                        onKeyPress={this._onTextKeyPress}
                        margin="normal"
                        className={classes.textField}
                        disabled
                        variant="filled"
                        value={journalData.issue}
                        onChange={this.handleChange}
                      />
                      <TextField
                        required
                        label="Supplement"
                        name="supplement"
                        inputProps={{
                          maxLength: 50,
                          readOnly: true,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={[classes.textField, classes.lastChild].join(
                          " "
                        )}
                        disabled
                        variant="filled"
                        value={journalData.supplement}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <TextField
                        required
                        label="Issue Description"
                        name="issueDescription"
                        inputProps={{
                          maxLength: 50,
                          readOnly: true,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={classes.textField}
                        disabled
                        variant="filled"
                        value={journalData.issueDescription}
                        onChange={this.handleChange}
                      />
                      <TextField
                        label="Part"
                        name="part"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._onWeightKeyPress}
                        margin="normal"
                        className={classes.textField}
                        value={part}
                        disabled={isManual ? false : true}
                        onChange={this.handleChange}
                      />
                      <div style={{ flexGrow: 1, marginRight: "16px" }}></div>
                      <div style={{ flexGrow: 1 }}></div>
                    </div>
                    <p className={classes.subHeader}>EMLO Details</p>
                    <div style={{ display: "flex" }}>
                      <TextField
                        required
                        label="EMLO#"
                        name="emlo"
                        inputProps={{
                          maxLength: 30,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={classes.textField}
                        value={emlo}
                        disabled={isManual ? false : true}
                        onChange={this.handleChange}
                        error={this.state.errors.emlo}
                        helperText={
                          this.state.errors.emlo
                            ? resourceValidation.emloNumber
                            : ""
                        }
                      />
                      <TextField
                        label="Order Created By"
                        name="orderCreatedBy"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={classes.textField}
                        value={orderCreatedBy}
                        disabled={isManual ? false : true}
                        onChange={this.handleChange}
                      />
                      <TextField
                        label="Department"
                        name="department"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={classes.textField}
                        value={department}
                        disabled={isManual ? false : true}
                        onChange={this.handleChange}
                      />
                      <TextField
                        label="Issue Type"
                        name="issueType"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={classes.textField}
                        value={issueType}
                        className={[classes.textField, classes.lastChild].join(
                          " "
                        )}
                        disabled={isManual ? false : true}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <TextField
                        label="Category"
                        name="category"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={classes.textField}
                        value={category}
                        disabled={isManual ? false : true}
                        onChange={this.handleChange}
                      />
                      <TextField
                        required
                        label="Quantity"
                        name="quantity"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._onNumberKeyPress}
                        margin="normal"
                        className={classes.textField}
                        value={quantity}
                        disabled={isManual ? false : true}
                        onChange={this.handleChange}
                      />
                      <TextField
                        label="Estd Unit Weight"
                        name="unitWeight"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={classes.textField}
                        value={unitWeight}
                        disabled={isManual ? false : true}
                        onChange={this.handleChange}
                        onBlur={(e) => this.onBlur(e, "unitWeight")}
                      />
                      <TextField
                        label="Estd Total Weight"
                        name="totalWeight"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={[classes.textField, classes.lastChild].join(
                          " "
                        )}
                        value={totalWeight}
                        disabled={isManual ? false : true}
                        onChange={this.handleChange}
                        onBlur={(e) => this.onBlur(e, "totalWeight")}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          autoOk
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          minDateMessage={""}
                          name="orderDate"
                          className={classes.textField}
                          id="orderDate"
                          label="Order Date"
                          disabled={isManual ? false : true}
                          value={orderDate}
                          onChange={(e) =>
                            this.handleDateChange(e, "orderDate")
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          disabled={isManual ? false : true}
                        />
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          autoOk
                          // disablePast
                          //required
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          error={false}
                          minDateMessage={""}
                          name="distributerDate"
                          className={classes.textField}
                          id="distributerDate"
                          label="Distributor Date"
                          value={distributerDate}
                          onChange={(e) =>
                            this.handleDateChange(e, "distributerDate")
                          }
                          disabled={isManual ? false : true}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <TextField
                        label="Delivery Method"
                        name="deliveryMethod"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={classes.textField}
                        value={deliveryMethod}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />
                      <TextField
                        label="Delivery Details"
                        name="deliveryDetails"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={[classes.textField, classes.lastChild].join(
                          " "
                        )}
                        value={deliveryDetails}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        label="Company Name"
                        name="companyName"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={classes.textField}
                        value={companyName}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />
                      <TextField
                        label="Recipient Name"
                        name="recipientName"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={classes.textField}
                        value={recipientName}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />
                      <TextField
                        label="Dept"
                        name="dept"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={classes.textField}
                        value={this.state.dept}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />
                      <TextField
                        required={!this.state.addressLine1 && !this.state.addressLine2 && !this.state.addressLine3}
                        label="Address Line 1"
                        name="addressLine1"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        multiline
                        rowsMax="4"
                        className={[classes.textField, classes.lastChild].join(
                          " "
                        )}
                        value={addressLine1}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        required={!this.state.addressLine1 && !this.state.addressLine2 && !this.state.addressLine3}
                        label="Address Line 2"
                        name="addressLine2"
                        inputProps={{
                          maxLength: 100,
                        }}
                        multiline
                        rowsMax="4"
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={classes.textField}
                        value={addressLine2}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />
                      <TextField
                        required={!this.state.addressLine1 && !this.state.addressLine2 && !this.state.addressLine3}
                        label="Address Line 3"
                        name="addressLine3"
                        inputProps={{
                          maxLength: 100,
                        }}
                        multiline
                        rowsMax="4"
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={classes.textField}
                        value={addressLine3}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />
                      <Autocomplete
                        //autoFocus
                        disabled={isManual ? false : true}
                        options={countryList}
                        value={this.state.country}
                        onChange={this.handleAutocompleteCountry}
                        getOptionLabel={(option) => option.text}
                        className={classes.textField}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country"
                            margin="normal"
                            required
                            fullWidth
                          />
                        )}
                      />
                      <TextField
                        label="Country Code"
                        name="countryCode"
                        inputProps={{
                          maxLength: 100,
                          readOnly: true,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={[classes.textField, classes.lastChild].join(
                          " "
                        )}
                        value={this.state.countryCode}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <TextField
                        label="City"
                        name="city"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={classes.textField}
                        value={city}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />
                      <TextField
                        label="State"
                        name="state"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={classes.textField}
                        value={state}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />
                      <TextField
                        label="Area Code"
                        name="areaCode"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={classes.textField}
                        value={areaCode}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />
                      <TextField
                        required
                        label="Postal Code"
                        name="postCode"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        className={[classes.textField, classes.lastChild].join(
                          " "
                        )}
                        value={postCode}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        label="Phone Number"
                        name="number"
                        inputProps={{
                          maxLength: 20,
                        }}
                        onKeyPress={this._onMobileNumberKeyPress}
                        margin="normal"
                        className={classes.textField}
                        value={number}
                        // onChange={this.handleChange}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      // error={this.state.errors.number}
                      // helperText={
                      //   this.state.errors.number
                      //     ? resourceValidation.validPhoneNo
                      //     : ""
                      // }
                      />

                      <TextField
                        // label="Do you want tracking information automatically emailed to you upon dispatch via courier?
                        // "
                        name="isTrackingInfoRequired"
                        inputProps={{
                          maxLength: 1,
                        }}
                        helperText="Please enter Y or N"
                        onKeyPress={this.onYorNKeyPress}
                        margin="normal"
                        className={classes.textField}
                        value={this.state.isTrackingInfoRequired}
                        label={"Tracking information ?"}
                        multiline
                        style={{ position: "relative", top: "20px" }}
                        rowsMax="4"
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />
                      <TextField
                        label="Additional Instructions"
                        name="additionalInstructions"
                        // label="Please send tracking to jrussel2@wiley.com."
                        // name="additionalInstructions"
                        inputProps={{
                          maxLength: 100,
                        }}
                        // helperText="Please send tracking to jrussel2@wiley.com."
                        // style={{ marginTop: "13px" }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        multiline
                        // id="isTrackingInfoLabel"
                        rowsMax="4"
                        className={classes.textField}
                        value={this.state.additionalInstructions}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />
                      <TextField
                        label="Uploaded Files"
                        name="uploadFiles"
                        inputProps={{
                          maxLength: 50,
                        }}
                        disabled={isManual ? false : true}
                        // helperText="Please send ROWversion."
                        onKeyPress={this._freetext}
                        margin="normal"
                        multiline
                        rowsMax="4"
                        className={[classes.textField, classes.lastChild].join(
                          " "
                        )}
                        value={this.state.uploadFiles}
                        onChange={this.handleChange}
                      />
                    </div>
                    <p className={classes.subHeader}>Actual Info</p>
                    <div style={{ display: "flex", }}>
                      <TextField
                        label="Actual Weight"
                        name="actualWeight"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._onNumberKeyPress}
                        margin="normal"
                        className={classes.textField}
                        value={actualWeight}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                        onBlur={(e) => this.onBlur(e, "actualWeight")}
                      />
                      <TextField
                        label="Tracking Number"
                        name="trackingNumber"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._freetext}

                        margin="normal"
                        className={classes.textField}
                        value={trackingNumber}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />
                      <TextField
                        label="No Of Cartons"
                        name="noOfCartons"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._onNumberKeyPress}
                        margin="normal"
                        className={classes.textField}
                        value={noOfCartons}
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          autoOk
                          // disablePast
                          //required
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          error={false}
                          minDateMessage={""}
                          name="deliveryDate"
                          // className={classes.textField}
                          className={[classes.textField, classes.lastChild].join(
                            " "
                          )}
                          id="deliveryDate"
                          label="Delivery Date"
                          disabled={isManual ? false : true}
                          value={deliveryDate}
                          onChange={(e) =>
                            this.handleDateChange(e, "deliveryDate")
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>

                      {/* <div style={{ flexGrow: 1 }}></div> */}

                    </div>
                    <div style={{ display: "flex" }}>
                      <TextField
                        label="DHL Rate"
                        name="dhlRate"
                        inputProps={{
                          maxLength: 50,
                        }}

                        inputProps={{
                          readOnly: true
                        }}
                        onKeyPress={this._onNumberKeyPress}
                        style={{ paddingRight: "26px" }}
                        margin="normal"
                        className={classes.textField}
                        value={dhlRate}

                        variant="filled"
                        onChange={this.handleChange}
                        disabled={isManual ? false : true}
                      />

                      <div style={{ flexGrow: 3 }}></div>
                    </div>
                  </div>
                )}
                <Divider style={{ margin: "15px 0 5px" }} />

                <div
                  className="button-wrapper"
                  style={{ paddingLeft: "0px", marginLeft: "0px" }}
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/pubEmloOrderPlacement")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.isManual ? (
                      <Button
                        disabled={
                          !this.state.msg.length ||
                            Object.values(this.state.msgDispObj).includes(
                              "File is already Present."
                            ) || this.checkInvalidFiles()
                            ? true
                            : false
                        }
                        autoFocus
                        color="primary"
                        variant="contained"
                        style={{ boxShadow: "none" }}
                        onClick={(e) => this.handleSubmit(e, false)}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          disabled={
                            !this.state.journalData.volume ||
                              !this.state.journalData.issueDescription ||
                              !this.state.journalData.acronym ||
                              (!this.state.journalData.issue &&
                                !this.state.journalData.supplement) ||
                              !this.state.emlo ||
                              this.state.errors.emlo ||
                              !this.state.quantity ||
                              (!this.state.addressLine1 && !this.state.addressLine2 && !this.state.addressLine3) ||
                              !this.state.postCode ||
                              !this.state.country
                              ? true
                              : false
                          }
                          autoFocus
                          color="primary"
                          variant="contained"
                          style={{ boxShadow: "none" }}
                          onClick={(e) => this.handleSubmit(e, true)}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(AddEmloOrderPlacement));
