import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { Tooltip, IconButton, Button, Divider } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import { useToolbarStyles } from "../../../shared/styles";
import { withRouter } from "react-router-dom";
import { getCustomer } from "../../masters/masterActions";
import { NewEditIcon } from "../../../shared/customIcons";
import "../../app/App.css";
import { printDetails } from "../../../shared/tableCommonFunctions";

const ViewHeader = props => {
  const classes = useToolbarStyles();
  let acnArr = props.roles.filter(u => u.menuName === "Customer");
  let acnObj = acnArr[0];

  return (
    <div className="view-page-header">
      {acnObj && acnObj.editAction && (
        <Tooltip title="Edit">
          <IconButton
            aria-label="clear"
            className={classes.iconHover}
            onClick={e =>
              props.history.push(`/manage_customer/${props.customerId}`)
            }
          >
            <NewEditIcon />
          </IconButton>
        </Tooltip>
      )}
      {/* <Tooltip title="Download">
        <IconButton
          aria-label="clear"
          className={classes.iconHover}
          onClick={e => pdfDownloadDetails("customer_details.pdf")}
        >
          <DownloadIcon />
        </IconButton>
      </Tooltip> */}
      {acnObj && acnObj.printAction && (
        <Tooltip title="Print" style={{ marginRight: "11px" }}>
          <Tooltip title="Print">
            <IconButton
              aria-label="Print"
              className={classes.iconHover}
              onClick={(e) => printDetails()}
            >
              <PrintIcon />
            </IconButton>
          </Tooltip>
        </Tooltip>

      )}
    </div>
  );
};

class ViewCustomer extends Component {
  state = {
    selectedCustomer: {}
  };
  componentDidMount() {
    this.props.setSelectedList("masterDataOpen", "customer");
    let customerId = "";
    if (process.browser) customerId = window.location.pathname.split("/")[2];

    getCustomer(customerId).then(r => {
      let newSelectedCustomer = r.data;
      let customisedCustomerPPIList = [];
      let ppiInfo = {};
      let cciInfo = [];
      let lsiInfo = [];
      r.data &&
        r.data.customerPPIList &&
        r.data.customerPPIList.length > 0 &&
        r.data.customerPPIList.map(p => {
          ppiInfo = p;
          cciInfo = [];
          lsiInfo = [];
          r.data &&
            r.data.ppiLetterShopList &&
            r.data.ppiLetterShopList.length > 0 &&
            r.data.ppiLetterShopList.map(l => {
              if (p.customerPPIID === l.customerPPIID) lsiInfo.push(l);
            });
          r.data &&
            r.data.ppiCostCenterList &&
            r.data.ppiCostCenterList.length > 0 &&
            r.data.ppiCostCenterList.map(c => {
              if (p.customerPPIID === c.customerPPIID) cciInfo.push(c);
            });
          customisedCustomerPPIList.push({ ppiInfo, cciInfo, lsiInfo });
        });
      newSelectedCustomer.ppiInfo = customisedCustomerPPIList;
      this.setState({ selectedCustomer: newSelectedCustomer });
    });
  }
  handleCancel = () => {
    this.props.history.push("/customer");
  };
  render() {
    let { selectedCustomer } = this.state;
    let customerId = "";
    if (process.browser) customerId = window.location.pathname.split("/")[2];
    return (
      <div>
        <Paper id="">
          <ViewHeader
            history={this.props.history}
            customerId={customerId}
            roles={this.props.roles}
          />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">Customer Information</div>
            <div
              className="view-page-contents-container"
              id="view-page-contents-container"
            >
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Customer Code</div>
                <div className="view-page-value">
                  {selectedCustomer &&
                    selectedCustomer.customerCode &&
                    selectedCustomer.customerCode}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Customer Name</div>
                <div className="view-page-value">
                  {selectedCustomer &&
                    selectedCustomer.customerName &&
                    selectedCustomer.customerName}
                </div>
              </div>
            </div>
            {selectedCustomer && selectedCustomer.addressLine1 && (
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Address 1</div>
                  <div className="view-page-value">
                    {selectedCustomer.addressLine1}
                  </div>
                </div>
              </div>
            )}
            {selectedCustomer && selectedCustomer.addressLine2 && (
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Address 2</div>
                  <div className="view-page-value">
                    {selectedCustomer.addressLine2}
                  </div>
                </div>
              </div>
            )}
            {selectedCustomer && selectedCustomer.addressLine3 && (
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Address 3</div>
                  <div className="view-page-value">
                    {selectedCustomer.addressLine3}
                  </div>
                </div>
              </div>
            )}
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Postal Code</div>
                <div className="view-page-value">
                  {selectedCustomer &&
                    selectedCustomer.postalCode &&
                    selectedCustomer.postalCode}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Country</div>
                <div className="view-page-value">
                  {selectedCustomer &&
                    selectedCustomer.country &&
                    selectedCustomer.country}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Contact Person</div>
                <div className="view-page-value">
                  {selectedCustomer &&
                    selectedCustomer.contactPersonName &&
                    selectedCustomer.contactPersonName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Phone Number</div>
                <div className="view-page-value">
                  {selectedCustomer &&
                    selectedCustomer.phoneNumber &&
                    selectedCustomer.phoneNumber}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Email</div>
                <div className="view-page-value">
                  {selectedCustomer &&
                    selectedCustomer.emailID &&
                    selectedCustomer.emailID}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Vendor</div>
                <div className="view-page-value">
                  {selectedCustomer &&
                    selectedCustomer.isVendor &&
                    selectedCustomer.isVendor
                    ? "Yes"
                    : "No"}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Active</div>
                <div className="view-page-value">
                  {selectedCustomer &&
                    selectedCustomer.isActive &&
                    selectedCustomer.isActive
                    ? "Yes"
                    : "No"}
                </div>
              </div>
            </div>
            {selectedCustomer.ppiInfo &&
              selectedCustomer.ppiInfo.length > 0 &&
              selectedCustomer.ppiInfo.map((ppi, i) => {
                return (
                  <div key={i}>
                    <div className="view-page-headings">PPI Information</div>
                    <div
                      className="view-page-contents-container"
                      id="view-page-contents-container"
                    >
                      <div className="view-page-contents-sub-container">
                        <div className="view-page-label">Service</div>
                        <div className="view-page-value">
                          {ppi.ppiInfo &&
                            ppi.ppiInfo.serviceCode &&
                            ppi.ppiInfo.serviceCode}
                        </div>
                      </div>
                      <div className="view-page-contents-sub-container">
                        <div className="view-page-label">PPI Number</div>
                        <div className="view-page-value">
                          {ppi.ppiInfo &&
                            ppi.ppiInfo.ppiNumber &&
                            ppi.ppiInfo.ppiNumber}
                        </div>
                      </div>
                      <div className="view-page-contents-sub-container">
                        <div className="view-page-label">
                          Billing Account Number
                        </div>
                        <div className="view-page-value">
                          {ppi.ppiInfo &&
                            ppi.ppiInfo.billingAccountNo &&
                            ppi.ppiInfo.billingAccountNo}
                        </div>
                      </div>
                      <div className="view-page-contents-sub-container">
                        <div className="view-page-label">Planner</div>
                        <div className="view-page-value">
                          {ppi.ppiInfo &&
                            ppi.ppiInfo.plannerName &&
                            ppi.ppiInfo.plannerName}
                        </div>
                      </div>
                    </div>
                    <div className="view-page-contents-container">
                      <div className="view-page-contents-sub-container" style={{ width: "auto", overflowWrap: "normal" }}>
                        <div className="view-page-label-bold">
                          Lettershop Code
                        </div>
                        <div className="view-page-value">
                          {ppi.lsiInfo &&
                            ppi.lsiInfo.length > 0 &&
                            ppi.lsiInfo.map((lsi, j) => {
                              return (
                                <span
                                  key={j}
                                  style={{
                                    marginRight: "1em"
                                  }}
                                >
                                  {lsi.letterShopCode}
                                </span>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div className="view-page-contents-container">
                      <div className="view-page-contents-sub-container" style={{ width: "auto", overflowWrap: "normal" }}>
                        <div className="view-page-label-bold">
                          Cost Centre Info
                        </div>
                        <div className="view-page-value">
                          {" "}
                          {ppi.cciInfo &&
                            ppi.cciInfo.length > 0 &&
                            ppi.cciInfo.map((cci, j) => {
                              return (
                                <span
                                  key={j}
                                  style={{
                                    marginRight: "1em"
                                  }}
                                >
                                  {cci.costCenterName},
                                </span>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            <Divider style={{ marginTop: "25px" }} />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                  onClick={e => this.handleCancel()}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}
export default withRouter(ViewCustomer);
