import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  getCustomerForInsertMasterList,
  postInsertMaster,
  postEmailInsertMaster,
  deleteInsertmasterByID,
  getInsertMasterNumberList,
  validateEmailInsertMaster,
} from "../action";
import * as XLSX from "xlsx";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import InfoIcon from "@material-ui/icons/Info";
import toastr from "toastr"
import { Autocomplete } from "@material-ui/lab";
import { resourceValidation } from "../../../shared/resource";
import DescriptionIcon from "@material-ui/icons/Description";
import LinearProgress from "@material-ui/core/LinearProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import IconButton from "@material-ui/core/IconButton";
import PopupSearch from "../../app/poupSearch/popupAndSearch";
import IndividualSearchAddDialog from "../../publisherBackLabel/ebloOrderPlacement/IndividualSearchAddDialog";

const notRequiredAlpNum10 = new Validator("notRequiredAlpNum10");
const emailValidator = new Validator("email");
const dataValidator = new Validator("dataPresent");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    // marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: "23%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
});

function DetectionControl(props) {
  return (
    <div
      style={{
        width: "45%",
        display: "flex",
        alignItems: "center",
      }}
    >
      {props.message && props.message.toLowerCase().indexOf("present") > -1 ? (
        <span>
          <InfoIcon style={{ color: "red", fontSize: "19px" }} />
          <span style={{ position: "relative", top: "-5px", left: "4px" }}>
            {props.message}
          </span>
        </span>
      ) : (
          <span>
            <CheckCircleIcon
              style={{ color: "rgba(59, 218, 182, 0.87)", fontSize: "19px" }}
            />
            <span style={{ position: "relative", top: "-5px", left: "4px" }}>
              {props.message}
            </span>
          </span>
        )}
    </div>
  );
}

class AddInsertMaster extends React.Component {
  constructor() {
    super();

    this.state = {
      deletePopupOpen: false,
      customersList: [],
      jouranlID: "",
      customerId: "",
      customer: {},
      insert: "",
      acronym: "",
      vol: "",
      iss: "",
      part: "",
      supp: "",
      issueDesc: "",
      insertName: "",
      type: "",
      insertWeight: "",
      quantity: "",
      noOfpages: "",
      insertSize: "",
      foldingRequired: "",
      applyTo: "",
      recipients: "",
      placement: "",
      oversHandling: "",
      societyInsert: "",
      jdsrInstruction: "",
      contactName: "",
      actualQuantity: "",
      actualWeight: "0.000",
      email: "",
      errors: {},
      insertValid: false,
      insertNameValid: false,
      accronymValid: false,
      volumeValid: false,
      issueValid: false,
      suppValid: false,
      issueDescriptionValid: false,
      typeValid: false,
      insertWeightValid: false,
      quantityValid: false,
      emailValid: false,
      formValid: false,
      stateFiles: [],
      msg: [],
      msgDispArray: [],
      msgDispObj: {},
      loading: false,
      isManual: false,
      isDuplicateData: false,
      wholeList: [],
      customerName: "",
      openDialog: false,
      journalData: {
        journalID: "",
        issueDescription: "",
        acronym: "",
        journalTitle: "",
        volume: "",
        issue: "",
        supplement: "",
      },
    };
    this.myRef = React.createRef();
  }

  handleJournalUpdate = (selectedJournalList) => {
    this.setState({
      journalData: selectedJournalList[0],
      openDialog: false,
    });
  };

  clearJournalInfo = (i) => {
    let newjournalData = { ...this.state.journalData };
    newjournalData = {};
    this.setState({ workOrder: newjournalData });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let insertValid = this.state.insertValid;
    let insertNameValid = this.state.insertNameValid;
    let accronymValid = this.state.accronymValid;
    let volumeValid = this.state.volumeValid;
    let issueValid = this.state.issueValid;
    let suppValid = this.state.suppValid;
    let issueDescriptionValid = this.state.issueDescriptionValid;
    let typeValid = this.state.typeValid;
    let emailValid = this.state.emailValid;
    let insertWeightValid = this.state.insertWeightValid;
    let quantityValid = this.state.quantityValid;
    switch (fieldName) {
      case "insert":
        if (value !== "") {
          insertValid = notRequiredAlpNum10(value);
          fieldValidationErrors.insert = insertValid
            ? ""
            : resourceValidation.insert;
        }
        break;
      case "insertName":
        if (value !== "") {
          insertNameValid = notRequiredAlpNum10(value);
          fieldValidationErrors.insertName = insertNameValid
            ? ""
            : resourceValidation.insertName;
        }
        break;
      case "accronym":
        if (value !== "") {
          accronymValid = notRequiredAlpNum10(value);
          fieldValidationErrors.accronym = accronymValid
            ? ""
            : resourceValidation.accronym;
        }

        break;
      case "volume":
        if (value !== "") {
          volumeValid = true;
          fieldValidationErrors.volume = volumeValid
            ? ""
            : " Please select volume";
        }
        break;
      case "issue":
        if (value !== "") {
          issueValid = true;
          fieldValidationErrors.issue = issueValid ? "" : "Please select Issue";
        }
        break;
      case "supp":
        if (value !== "") {
          suppValid = true;
          fieldValidationErrors.supp = suppValid
            ? ""
            : "Please select supplement";
        }
        break;
      case "issueDescription":
        if (value !== "") {
          issueDescriptionValid = true;
          fieldValidationErrors.issueDescription = issueDescriptionValid
            ? ""
            : "Please select Issue Description";
        }
        break;
      case "type":
        if (value !== "") {
          typeValid = true;
          fieldValidationErrors.type = typeValid ? "" : "Please select type";
        }
        break;
      case "insertWeight":
        if (value !== "") {
          insertWeightValid = true;
          fieldValidationErrors.insertWeight = insertWeightValid
            ? ""
            : "Please select weight";
        }
        break;
      case "quantity":
        if (value !== "") {
          quantityValid = true;
          fieldValidationErrors.quantity = quantityValid
            ? ""
            : "Please select quantity";
        }
        break;
      case "email":
        if (value !== "") {
          emailValid = emailValidator(value);
          fieldValidationErrors.email = emailValid
            ? ""
            : resourceValidation.validEmailId;
        }
        break;

      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        insertValid: insertValid,
        insertNameValid: insertNameValid,
        accronymValid: accronymValid,
        volumeValid: volumeValid,
        issueValid: issueValid,
        suppValid: suppValid,
        issueDescriptionValid: issueDescriptionValid,
        typeValid: typeValid,
        insertWeightValid: insertWeightValid,
        emailValid: emailValid,
        quantityValid: quantityValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.insertValid &&
        this.state.insertNameValid &&
        this.state.accronymValid &&
        this.state.volumeValid &&
        this.state.issueValid &&
        this.state.suppValid &&
        this.state.issueDescriptionValid &&
        this.state.typeValid &&
        this.state.insertWeightValid &&
        this.state.emailValid &&
        this.state.quantityValid,
    });

    return this.state.formValid;
  }

  handleCapture = ({ target }) => {
    const arrayOfObjOld = Object.values(target.files);
    let arrayOfObj = []
    let testMsgFile = false
    arrayOfObjOld.map(x => {
      if (x.name.toLowerCase().endsWith(".msg")) {
        arrayOfObj.push(x)
        testMsgFile = true
      }
      else {
        toastr.error("Only .msg files can be uploaded !")
      }
    })
    if (!testMsgFile)
      return
    let msgArray = this.state.msg;
    let msgDispArray = this.state.msgDispArray;
    let msgDispObj = this.state.msgDispObj;
    let arrayOfObjFiltered = arrayOfObj.filter(
      (x) => !msgArray.filter((y) => y.name === x.name).length
    );
    this.setState({ msg: [...this.state.msg, ...arrayOfObjFiltered] }, () => {
      this.state.msg.map((file, i) => {
        msgDispArray[i] = msgDispArray[i] || "Uploaded Successfully";
        msgDispObj[file] = msgDispArray[file] || "Uploaded Successfully";
      });
      this.setState({
        msgDispArray: msgDispArray,
        msgDispObj: msgDispObj,
      });

      let files = [...this.state.msg];
      let _error = "";

      if (files.length > 0) {
        let customerId = this.state.customerId;
        let createdBy = window.localStorage.loggedInId;
        let modifiedBy = window.localStorage.loggedInId;
        let customerName = this.state.customerName;
        let formData = new FormData();
        let stf = [];
        Array.from(files).map((f) => {
          formData.append("Uploadfiles", f);
          stf.push({
            name: f.name,
            size: f.size,
            message: "",
          });
        });

        formData.append("CustomerID", customerId);
        formData.append("CreatedBy", createdBy);
        formData.append("ModifiedBy", modifiedBy);
        formData.append("CustomerName", customerName);

        validateEmailInsertMaster(formData).then((r) => {
          let msgDispArray = [];
          let msgDispObj = {};
          r.data.map((val, i) => {
            if (val.exceptionMessage == "") {
              msgDispArray[i] = "Uploaded Successfully";
              msgDispObj["expMsg"] = "Uploaded Successfully";
            } else {
              msgDispArray[i] = val.exceptionMessage;
              msgDispObj["expMsg"] = val.exceptionMessage;
            }
          });
          this.setState({
            msgDispArray: msgDispArray,
            msgDispObj: msgDispObj,
            // msg:r.data
          });
        });
      }
    });
  };

  onSwitch = () => {
    this.setState({ deletePopupOpen: true });
  };

  handleDeleteConfirmModalClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleDeleteConfirmModalSwitch = () => {
    let journalData = {
      acronym: "",
      volume: "",
      issue: "",
      supplement: "",
      issueDescription: "",
    };
    if (this.state.isManual) {
      this.setState({
        insert: "",
        acronym: "",
        vol: "",
        iss: "",
        part: "",
        supp: "",
        issueDesc: "",
        journalData: journalData,
        insertName: "",
        type: "",
        insertWeight: "",
        quantity: "",
        noOfpages: "",
        insertSize: "",
        foldingRequired: "",
        applyTo: "",
        recipients: "",
        placement: "",
        oversHandling: "",
        societyInsert: "",
        jdsrInstruction: "",
        contactName: "",
        actualQuantity: "",
        actualWeight: "0.000",
        email: "",
        errors: {},
      });
    } else {
      this.setState({
        msg: [],
        msgDispArray: [],
        msgDispObj: {},
      });
    }
    this.setState({ isManual: !this.state.isManual, deletePopupOpen: false });
  };

  componentDidMount() {
    this.props.handleSettingHeader("Insert Master");
    this.props.setSelectedList("publisherMainLabelOpen", "pubInsertMaster");
    getInsertMasterNumberList().then((r) => {
      // this.getAllInsertMaster(r.data);
      this.getAllInsertMaster(r.data);
    });

    getCustomerForInsertMasterList().then((r) => {
      r.data &&
        this.setState({
          customersList:
            r &&
            r.data &&
            r.data
              .filter((x) => x.text.toLowerCase().indexOf("wiley") != -1)
              .sort((a, b) =>
                a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
              ),
        });

      const filtered =
        r &&
        r.data &&
        r.data.reduce(
          (a, o) => (
            o.text.toLowerCase().indexOf("wiley") != -1 && a.push(o.id), a
          ),
          []
        );

      const filteredText =
        r &&
        r.data &&
        r.data.reduce(
          (a, o) => (
            o.text.toLowerCase().indexOf("wiley") != -1 && a.push(o.text), a
          ),
          []
        );
      this.setState({
        customerId: filtered && filtered[0],
        customerName: filteredText && filteredText[0],
      });
    });
  }

  getAllInsertMaster = (lpList) => {
    let list = lpList;
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        newRows.push(d.insertNo);
      });
    this.setState({ wholeList: newRows, loading: false });
  };

  handleAddJournalClick = () => {
    this.setState({
      openDialog: true,
    });
  };

  handleAddJournalClose = () => {
    this.setState({
      openDialog: false,
    });
  };

  handleChange = async (e, validate) => {
    if (e.target.name == "actualWeight") {
      let newRrrors = { ...this.state.errors };
      let result = e.target.value;
      newRrrors[e.target.name] = !result;
      let dhlValues = e.target.value;
      dhlValues = this.checkDHlWeightDec(e.target.value.toString());
      this.setState({ [e.target.name]: dhlValues });
    } else {
      let newRrrors = { ...this.state.errors };
      let result = e.target.value;
      newRrrors[e.target.name] = !result;
      this.setState({ [e.target.name]: e.target.value });
    }

    if (e.target.name == "email") {
      let newRrrors = { ...this.state.errors };
      let result = validate(e.target.value);
      newRrrors[e.target.name] = !result;
      this.setState({ email: e.target.value, errors: newRrrors });
    }

    if (e.target.name == "insert") {
      let newRrrors = { ...this.state.errors };
      let result = this.state.wholeList.includes(e.target.value);
      if (e.target.value) {
        newRrrors[e.target.name] = result;
      }
      await this.setState({
        [e.target.name]: e.target.value,
        errors: newRrrors,
      });
    }
  };

  handleAutocompleteCustomer = (e, val) => {
    this.setState({ customerId: val && val.id, customer: val && val });
  };

  handleClose = () => {
    // clean up state and errors
    let newZone = { ...this.state.Zone };
    newZone.ZoneCode = "";
    newZone.ZoneName = "";
    this.setState({
      Zone: newZone,
      errors: {},
    });
  };

  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertKeyPress(event) {
    const re = /[a-zA-Z0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _freetext(event) {
    const re = /[#.0-9a-zA-Z\s,-@()%!$%^&*{}/<>_;:'" |]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onTextKeyPress(event) {
    const re = /[a-z A-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusNumberKeyPress(event) {
    const re = /[0-9 a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onWeightKeyPress(event) {
    const re = /[0-9 a-zA-Z.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertSizeKeyPress(event) {
    const re = /[0-9 a-zA-Z-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusAllPress(event) {
    const re = /[a-z A-Z0-9=.;()&@]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  onBlur = (event) => {
    // const { dhlRateValues } = this.state;
    let insertMasterValues = event.target.value;
    insertMasterValues = this.addZeroes(insertMasterValues.toString(), 3);
    this.setState({ [event.target.name]: insertMasterValues });
  };

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  deleteSelectedFile = (e, i, id) => {
    e.persist();
    this.myRef.current.value = "";
    let msgDispArray = [...this.state.msgDispArray];
    let msgDispObj = this.state.msgDispObj;
    let newStateFiles = [...this.state.msg];
    newStateFiles.splice(i, 1);
    msgDispArray.splice(i, 1);
    delete msgDispObj[id.name];
    this.setState({
      msg: newStateFiles,
      msgDispArray: msgDispArray,
      msgDispObj: msgDispObj,
    });
  };

  handleSubmit = (event, manual) => {
    let { journalData } = this.state;
    let jouranlID = journalData.journalID;
    let insertNo = this.state.insert;
    let acronym = journalData.acronym;
    let volume = journalData.volume;
    let issue = journalData.issue;
    let suppNumber = journalData.supplement;
    let issueDescription = journalData.issueDescription;
    let insertName = this.state.insertName;
    let type = this.state.type;
    let insertWeight = this.state.insertWeight;
    let quantity = this.state.quantity;
    let actualInsertWeight = this.state.actualWeight;
    let actualQuantity = this.state.actualQuantity;
    let noofPages = this.state.noOfpages;
    let insertSize = this.state.insertSize;
    let foldingRequired = this.state.foldingRequired;
    let applyTo = this.state.applyTo;
    let recipients = this.state.recipients;
    let placement = this.state.placement;
    let oversHandling = this.state.oversHandling;
    let societyInsert = this.state.societyInsert;
    let contactName = this.state.contactName;
    let email = this.state.email;
    let customerId = this.state.customerId;
    let createdBy = window.localStorage.loggedInId;
    let modifiedBy = window.localStorage.loggedInId;
    let part = this.state.part;
    let jdsrInstruction = this.state.jdsrInstruction;

    let reqbody1 = {
      jouranlID,
      insertNo,
      acronym,
      volume,
      issue,
      suppNumber,
      issueDescription,
      insertName,
      type,
      insertWeight,
      quantity,
      actualInsertWeight,
      actualQuantity,
      noofPages,
      insertSize,
      foldingRequired,
      applyTo,
      recipients,
      placement,
      oversHandling,
      societyInsert,
      contactName,
      email,
      customerId,
      createdBy,
      modifiedBy,
      part,
      jdsrInstruction,
    };

    if (manual) {
      postInsertMaster(reqbody1, this.props.history);
    } else {
      let files = [...this.state.msg];
      let _error = "";

      if (files.length > 0) {
        let loggedInId = localStorage.getItem("loggedInId");
        let formData = new FormData();
        let stf = [];
        Array.from(files).map((f) => {
          formData.append("Uploadfiles", f);
          stf.push({
            name: f.name,
            size: f.size,
            message: "",
          });
        });

        formData.append("CustomerID", customerId);
        formData.append("CreatedBy", createdBy);
        formData.append("ModifiedBy", modifiedBy);
        formData.append("CustomerName", this.state.customerName);

        postEmailInsertMaster(formData, this.props.history).then((r) => {
          // let msgDispArray = [];
          // r.data.map((val, i) => {
          //   if (val.exceptionMessage == "")
          //     msgDispArray[i] = "Uploaded Successfully";
          //   else msgDispArray[i] = val.exceptionMessage;
          // });
          // this.setState({
          //   msgDispArray: msgDispArray,
          // });
        });
      }
    }

    // let files = this.state.msg;
    // var reader = new FileReader();
    // let _error = "";

    // if (files.length > 0 && manual == false) {
    //   reader.onload = function (e) {
    //     let data = e.target.result;
    //   };

    //   let loggedInId = localStorage.getItem("loggedInId");
    //   const formData = new FormData();
    //   let stf = [];
    //   this.state.msg.map((f) => {
    //     if (f.insertMasterFileHeaderID) {
    //       return false;
    //     } else {
    //       formData.append("Uploadfiles", f);
    //       stf.push({
    //         name: f.name,
    //         size: f.size,
    //         message: "",
    //         isValid: f.isValid,
    //         insertMasterFileHeaderID: f.insertMasterFileHeaderID,
    //       });
    //     }
    //   });
    //   this.setState({ msg: stf });

    //   formData.append("CustomerID", customerId);
    //   formData.append("CreatedBy", createdBy);
    //   formData.append("ModifiedBy", modifiedBy);
    //   formData.append("CustomerName", this.state.customerName);
    //   this.setState({ loading: true });
    //   postEmailInsertMaster(formData, this.props.history).then((r) => {
    //     let errorMessage =
    //       r &&
    //       r.data &&
    //       r.data.map((val) => {
    //         return val.exceptionMessage;
    //       });
    //     let isValid =
    //       r &&
    //       r.data &&
    //       r.data.map((val) => {
    //         return val.isValid;
    //       });
    //     let insertMasterFileHeaderID =
    //       r &&
    //       r.data &&
    //       r.data.map((val) => {
    //         return val.insertMasterFileHeaderID;
    //       });
    //     Array.from(files).map((f, index) => {
    //       stf[index].message = errorMessage[index];
    //       stf[index].isValid = isValid[index];
    //       stf[index].insertMasterFileHeaderID = insertMasterFileHeaderID[index];
    //       if (errorMessage[index] == "") {
    //         this.setState({
    //           isDuplicateData: false,
    //         });
    //       } else {
    //         this.setState({
    //           isDuplicateData: true,
    //         });
    //       }
    //     });
    //   });

    //   this.setState({ msg: stf });
    // }

    // if (this.state.isManual && manual == true) {
    //   postInsertMaster(reqbody1, this.props.history);
    // }
  };

  render() {
    let { classes } = this.props;
    let {
      customersList,
      customerId,
      customer,
      insert,
      acronym,
      vol,
      iss,
      part,
      supp,
      issueDesc,
      insertName,
      type,
      insertWeight,
      quantity,
      noOfpages,
      insertSize,
      foldingRequired,
      applyTo,
      recipients,
      placement,
      oversHandling,
      societyInsert,
      jdsrInstruction,
      contactName,
      email,
      msg,
      isManual,
      journalData,
    } = this.state;

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create Insert Master</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <div className={classes.container}>
                <div style={{ display: "flex" }}>
                  {/* <Autocomplete
                    //autoFocus
                    options={customersList}
                    value={this.state.customer}
                    onChange={this.handleAutocompleteCustomer}
                    getOptionLabel={(option) => option.text}
                    style={{ width: "33.33%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoFocus
                        label="Customer"
                        style={{
                          width: "100%",
                          fontSize: "11px !important",
                          marginLeft: ".5em",
                          marginRight: ".5em",
                          maxHeight: "400px",
                          marginLeft: "0px",
                          marginRight: "0px",
                        }}
                        // name="plannerId"
                        margin="normal"
                        required
                      />
                    )}
                  /> */}
                  <TextField
                    inputProps={{
                      readOnly: true,
                    }}
                    label="Customer"
                    name="customer"
                    variant="filled"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.customerName}
                    margin="normal"
                  />
                  <div
                    style={{
                      flex: 1,
                      alignSelf: "flex-end",
                      margin: "16px 16px 8px 0",
                    }}
                  >
                    {" "}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => this.onSwitch()}
                      style={{
                        height: "auto",
                        display: " inline-block",
                        width: "100%",
                        verticalAlign: "middle",
                        boxShadow: "none",
                      }}
                    >
                      {isManual ? "File Upload Mode" : "Manual Mode"}
                    </Button>
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      onClose={this.handleDeleteConfirmModalClose}
                      aria-labelledby="customized-dialog-title"
                      open={this.state.deletePopupOpen}
                      maxWidth="lg"
                    >
                      <DialogTitle id="customized-dialog-title">
                        Switch to{" "}
                        {isManual ? "File Upload Mode" : "Manual Mode"}
                      </DialogTitle>
                      <DialogContent dividers>
                        <div style={{ minWidth: "300px" }}>
                          Are you sure you want to switch to{" "}
                          {isManual ? "File Upload Mode" : "Manual Mode"} ?
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          // autoFocus
                          onClick={this.handleDeleteConfirmModalClose}
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                        >
                          Close
                        </Button>
                        <Button
                          //autoFocus
                          onClick={this.handleDeleteConfirmModalSwitch}
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                        >
                          Switch
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  <div style={{ flexGrow: 1, marginRight: "16px" }}></div>
                  <div style={{ flexGrow: 1 }}></div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      backgroundColor: isManual ? "#9e9e9e" : "white",
                      color: "#515151",
                      borderRadius: "4px",
                      padding: ".3em 1em .3em 1em",
                      width: "122px",
                      height: "24px",
                      marginTop: "1.5em",
                      border: isManual
                        ? "1px dashed #9e9e9e"
                        : "1px dashed #3982ea",
                      cursor: isManual ? "default" : "pointer",
                      pointerEvents: isManual ? "none" : "all",
                    }}
                  >
                    <label
                      className="upload email"
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => this.handleCapture(e)}
                        multiple={true}
                        accept=".msg"
                        ref={this.myRef}
                      />
                      {!isManual ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <AddCircleIcon
                            style={{ color: "#3982ea", marginLeft: "-10px" }}
                          />{" "}
                          <span
                            style={{ marginLeft: "2px", fontWeight: "500" }}
                          >
                            Upload E-Mail
                          </span>
                        </div>
                      ) : (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <AddCircleIcon
                              style={{ color: "#fff", marginLeft: "-10px" }}
                            />{" "}
                            <span
                              style={{
                                marginLeft: "2px",
                                fontWeight: "500",
                                color: "#fff",
                              }}
                            >
                              Upload E-Mail
                          </span>
                          </div>
                        )}
                    </label>
                  </div>

                  {isManual ? (
                    <div
                      style={{
                        padding: ".3em 1em .3em 1em",
                        marginTop: "1.5em",
                        marginLeft: "1em",
                        borderRadius: "4px",
                        boxShadow:
                          "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                        // border: "1px solid #fff",
                        background: "#9e9e9e",
                      }}
                    >
                      <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <InfoIcon
                            style={{ color: "#fff", marginLeft: "-10px" }}
                          />{" "}
                          <span
                            style={{
                              marginLeft: "4px",
                              fontSize: "11px",
                              color: "#fff",
                            }}
                          >
                            Upload outlook email .msg file only
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                      <div
                        style={{
                          padding: ".3em 1em .3em 1em",
                          marginTop: "1.5em",
                          marginLeft: "1em",
                          borderRadius: "4px",
                          boxShadow:
                            "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                        }}
                      >
                        <div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <InfoIcon
                              style={{ color: "#3982ea", marginLeft: "-10px" }}
                            />{" "}
                            <span
                              style={{
                                marginLeft: "4px",
                                fontSize: "11px",
                                color: "gray",
                              }}
                            >
                              Upload outlook email .msg file only
                          </span>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                <div style={{ marginTop: "30px" }}>
                  <div>
                    {msg.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          margin: "1em",
                          // marginTop: "2em",
                          paddingBottom: ".5em",
                          borderBottom: "1px solid #eaeaea",
                          fontSize: "14px",
                          marginLeft: "0px",
                          marginRight: "0px",
                        }}
                      >
                        <div style={{ width: "30%", fontWeight: "500" }}>
                          File Info
                        </div>
                        <div
                          style={{
                            width: "10%",
                            fontWeight: "500",
                            margin: "0 10px",
                          }}
                        >
                          Size
                        </div>
                        <div style={{ width: "45%", fontWeight: "500" }}>
                          Detection
                        </div>
                        <div style={{ width: "10%", fontWeight: "500" }}>
                          Action
                        </div>
                      </div>
                    )}

                    {msg.map((file, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            margin: "1em",
                            paddingBottom: ".5em",
                            borderBottom: "1px solid #eaeaea",
                            fontSize: "12px",
                            marginLeft: "0px",
                            marginRight: "0px",
                          }}
                        >
                          <div style={{ width: "30%", fontWeight: "500" }}>
                            <div style={{ display: "flex" }}>
                              <DescriptionIcon
                                style={{
                                  color: "#306dca",
                                  marginTop: "-4px",
                                  marginRight: "5px",
                                }}
                              />{" "}
                              <span> {file.name}</span>
                            </div>
                          </div>
                          <div style={{ width: "10%", margin: "0 10px" }}>
                            {Math.ceil(file.size / 1024) + " kb"}
                            {/* {file.fileSize} */}
                          </div>

                          {this.state.msgDispObj["expMsg"] ? (
                            <DetectionControl
                              // message={this.state.msgDispArray[i]}
                              message={this.state.msgDispObj["expMsg"]}
                            />
                          ) : (
                              <LinearProgress style={{ width: "45%" }} />
                            )}
                          <IconButton
                            size="small"
                            style={{ marginTop: "-7px", width: "4%" }}
                            onClick={(e) => {
                              this.deleteSelectedFile(e, i, msg[i]);
                            }}
                          >
                            <DeleteIcon style={{ fontSize: "16px" }} />
                          </IconButton>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {this.state.isManual && (
                  <React.Fragment>
                    <p
                      className={classes.subHeader}
                      style={{ fontWeight: "500" }}
                    >
                      Manual Insert
                    </p>
                    <div>
                      <p
                        style={{ display: "inline-block", marginRight: "1em" }}
                        className={classes.subHeader}
                      >
                        Journal Details
                      </p>
                      <Button
                        disabled={isManual ? false : true}
                        onClick={this.handleAddJournalClick}
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{
                          width: "172px",
                          padding: ".2em",
                          marginTop: "-1em",
                          boxShadow: "none",
                        }}
                      >
                        <span>CHOOSE JOURNAL</span>
                      </Button>
                    </div>
                    {this.state.openDialog && (
                      <IndividualSearchAddDialog
                        title="Journal Pick Up"
                        showDialog={this.state.openDialog}
                        handleAddJournalClose={this.handleAddJournalClose}
                        handleJournalUpdate={this.handleJournalUpdate}
                      />
                    )}
                    <div style={{ display: "flex" }}>
                      <TextField
                        required
                        label="Acronym"
                        name="acronym"
                        inputProps={{
                          maxLength: 4,
                          readOnly: true,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        variant="filled"
                        style={{ flex: 1, marginRight: "16px" }}
                        inputProps={{
                          readOnly: true,
                        }}
                        value={journalData.acronym}
                        onChange={this.handleChange}
                      />
                      <TextField
                        required
                        label="Volume"
                        name="volume"
                        inputProps={{
                          maxLength: 4,
                          readOnly: true,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        inputProps={{
                          readOnly: true,
                        }}
                        variant="filled"
                        style={{ flex: 1, marginRight: "16px" }}
                        value={journalData.volume}
                        onChange={this.handleChange}
                      />
                      <TextField
                        required={!this.state.supp}
                        label="Issue"
                        name="issue"
                        inputProps={{
                          maxLength: 7,
                          readOnly: true,
                        }}
                        inputProps={{
                          readOnly: true,
                        }}
                        variant="filled"
                        onKeyPress={this._freetext}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        value={journalData.issue}
                        onChange={this.handleChange}
                      />
                      <TextField
                        required={!this.state.iss}
                        label="Supp"
                        name="supplement"
                        inputProps={{
                          maxLength: 7,
                          readOnly: true,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        variant="filled"
                        style={{ flex: 1 }}
                        value={journalData.supplement}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <TextField
                        required
                        label="Issue Description"
                        name="issueDescription"
                        inputProps={{
                          maxLength: 100,
                          readOnly: true,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        variant="filled"
                        style={{ flex: 1, marginRight: "16px" }}
                        value={journalData.issueDescription}
                        onChange={this.handleChange}
                      />
                      <TextField
                        multiline={true}
                        rows={1}
                        rowsMax={4}
                        label="JDSR Instruction"
                        name="jdsrInstruction"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        disabled={!isManual ? true : false}
                        value={jdsrInstruction}
                        onChange={this.handleChange}
                      />
                      <TextField
                        label="Part"
                        name="part"
                        inputProps={{
                          maxLength: 10,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        disabled={!isManual ? true : false}
                        value={part}
                        onChange={this.handleChange}
                      />
                      <div style={{ flexGrow: 1 }}></div>
                    </div>
                    <p className={classes.subHeader}>Insert Details</p>
                    <div style={{ display: "flex" }}>
                      <TextField
                        required
                        label="Insert No."
                        name="insert"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        disabled={!isManual ? true : false}
                        value={insert}
                        onChange={this.handleChange}
                        error={this.state.errors.insert}
                        helperText={
                          this.state.errors.insert
                            ? resourceValidation.insertNumber
                            : ""
                        }
                      />
                      <TextField
                        required
                        label="Insert Name"
                        name="insertName"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        disabled={!isManual ? true : false}
                        value={insertName}
                        onChange={this.handleChange}
                      />
                      <TextField
                        required
                        label="Type"
                        name="type"
                        inputProps={{
                          maxLength: 20,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        disabled={!isManual ? true : false}
                        value={type}
                        onChange={this.handleChange}
                      />

                      <TextField
                        label="No Of Pages"
                        name="noOfpages"
                        inputProps={{
                          maxLength: 10,
                        }}
                        onKeyPress={this._onNumberKeyPress}
                        margin="normal"
                        style={{ flex: 1 }}
                        disabled={!isManual ? true : false}
                        value={noOfpages}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <TextField
                        required
                        label="Insert Weight"
                        name="insertWeight"
                        inputProps={{
                          maxLength: 20,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        disabled={!isManual ? true : false}
                        value={insertWeight}
                        onChange={this.handleChange}
                      // onBlur={(e) => this.onBlur(e, "insertWeight")}
                      />
                      <TextField
                        required
                        label="Quantity"
                        name="quantity"
                        inputProps={{
                          maxLength: 20,
                        }}
                        onKeyPress={this._onNumberKeyPress}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        disabled={!isManual ? true : false}
                        value={quantity}
                        onChange={this.handleChange}
                      />
                      <TextField
                        label="Insert Size"
                        name="insertSize"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        disabled={!isManual ? true : false}
                        value={insertSize}
                        onChange={this.handleChange}
                      />
                      <TextField
                        label="Folding Required"
                        name="foldingRequired"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        style={{ flex: 1 }}
                        disabled={!isManual ? true : false}
                        value={foldingRequired}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <TextField
                        label="Apply to"
                        name="applyTo"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        disabled={!isManual ? true : false}
                        value={applyTo}
                        onChange={this.handleChange}
                      />
                      <TextField
                        label="Recipients"
                        name="recipients"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        disabled={!isManual ? true : false}
                        value={recipients}
                        onChange={this.handleChange}
                      />
                      <TextField
                        label="Placement"
                        name="placement"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        disabled={!isManual ? true : false}
                        value={placement}
                        onChange={this.handleChange}
                      />
                      <TextField
                        label="Overs Handling"
                        name="oversHandling"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        style={{ flex: 1 }}
                        disabled={!isManual ? true : false}
                        value={oversHandling}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <TextField
                        label="Society Insert"
                        name="societyInsert"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        disabled={!isManual ? true : false}
                        value={societyInsert}
                        onChange={this.handleChange}
                      />
                      <TextField
                        label="Contact Name"
                        name="contactName"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        disabled={!isManual ? true : false}
                        value={contactName}
                        onChange={this.handleChange}
                      />
                      <TextField
                        label="Email"
                        name="email"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._freetext}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        disabled={!isManual ? true : false}
                        value={email}
                        onChange={(e) => this.handleChange(e, emailValidator)}
                        error={this.state.errors.email}
                        helperText={
                          this.state.errors.email
                            ? resourceValidation.validEmailId
                            : ""
                        }
                      />
                      <div style={{ flexGrow: 1 }}></div>
                    </div>
                    <p className={classes.subHeader}>Actual Info</p>
                    <div style={{ display: "flex" }}>
                      <TextField
                        label="Actual Weight (Kgs)"
                        name="actualWeight"
                        inputProps={{
                          maxLength: 20,
                        }}
                        onKeyPress={this._onWeightKeyPress}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        disabled={!isManual ? true : false}
                        value={this.state.actualWeight}
                        onChange={this.handleChange}
                        onBlur={(e) => this.onBlur(e, "actualWeight")}
                      />
                      <TextField
                        label="Actual Quantity"
                        name="actualQuantity"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._onNumberKeyPress}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        disabled={!isManual ? true : false}
                        value={this.state.actualQuantity}
                        onChange={this.handleChange}
                      />
                      <div style={{ flexGrow: 1, marginRight: "16px" }}></div>
                      <div style={{ flexGrow: 1 }}></div>
                    </div>
                  </React.Fragment>
                )}
                <Divider style={{ margin: "15px 0 5px" }} />

                <div
                  className="button-wrapper"
                  style={{ paddingLeft: "0px", marginLeft: "0px" }}
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/pubInsertMaster")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.isManual ? (
                      <Button
                        disabled={
                          !this.state.msg.length ||
                            Object.values(this.state.msgDispObj).includes(
                              "File is already Present."
                            )
                            ? true
                            : false
                        }
                        autoFocus
                        color="primary"
                        variant="contained"
                        style={{ boxShadow: "none" }}
                        onClick={(e) => this.handleSubmit(e, false)}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          disabled={
                            !this.state.journalData.volume ||
                              !this.state.journalData.issueDescription ||
                              !this.state.journalData.acronym ||
                              (!this.state.journalData.issue &&
                                !this.state.journalData.supplement) ||
                              !this.state.quantity ||
                              !this.state.insertWeight ||
                              !this.state.type ||
                              !this.state.insertName ||
                              !this.state.insert ||
                              this.state.errors.insert ||
                              this.state.errors.email
                              ? true
                              : false
                          }
                          autoFocus
                          color="primary"
                          variant="contained"
                          style={{ boxShadow: "none" }}
                          onClick={(e) => this.handleSubmit(e, true)}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(AddInsertMaster));
