import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import { StateContext } from "../../shared/globalState";
import PermDataSettingIcon from "@material-ui/icons/PermDataSetting";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import FlareIcon from "@material-ui/icons/Flare";
import {
  HomeIcon,
  AuthoriationIcon,
  AdminIcon,
  MDMIcon,
  ReportIcon,
  GenericCustomerIcon,
} from "../../shared/customIcons";
import { getRoleAccess } from "../userAccess/userAccessAction";
import PublisherEmloCpt from "../reports/publisherReports/emloCPT";
const styles = (theme) => ({
  listItemText: {
    fontSize: "15px",
    // color: "#6d7381",
    fontFamily: "Roboto",
  },
  subListItemText: {
    fontSize: "14px",
    paddingLeft: "7px",
    height: "15px",
    fontFamily: "Roboto",
  },
  iconHover: {
    "&:hover": {
      color: blue[800],
    },
  },
  listItemIconText: {
    "&:focus": {
      backgroundColor: "rgb(203, 219, 241)",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#3982ea",
      },
    },
  },
  selectedList: {
    backgroundColor: "#e2edfc",
    color: "#3982ea",
    borderRadius: "4px",
    width: "267px",
    marginLeft: "6px",
    height: "36px",
    // width: "fit-content"
  },
  notSelectedList: {
    backgroundColor: "white",
    color: "gray",
    width: "267px",
    marginLeft: "6px",
    height: "36px",
  },
  selectedListWarehouse: {
    backgroundColor: "#e2edfc",
    color: "#3982ea",
    borderRadius: "4px",
    paddingLeft: "1em",
    marginLeft: "8px",
    margin: "3px",
    height: "26px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  notSelectedListWarehouse: {
    backgroundColor: "white",
    color: "gray",
    height: "26px",
    paddingLeft: "1em",
    marginLeft: "8px",
    margin: "3px",
    borderRadius: "4px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  notSelectedSubList: {
    //width: "142px",
    backgroundColor: "white",
    color: "gray",
    paddingLeft: "1em",
    marginLeft: "8px",
    margin: "3px",
    borderRadius: "4px",
    height: "26px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  selectedSubList: {
    "&:hover": {
      backgroundColor: "#3982ea",
      marginLeft: "8px",
      //width: "142px",
      borderRadius: "4px",
      color: "white",
      height: "26px",
      paddingLeft: "1em",
      margin: "3px",
      opacity: 1,
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    backgroundColor: "#3982ea",
    marginLeft: "8px",
    // width: "142px",
    borderRadius: "4px",
    color: "white",
    height: "26px",
    paddingLeft: "1em",
    margin: "3px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
});

class PrimaryList extends React.Component {
  static contextType = StateContext;
  state = {
    roleAccess: [],
    warehouseClicked: false,
    selected: "",
    openArr: {
      home: false,
      authorizationOpen: false,
      adminSettingOpen: false,
      masterDataOpen: false,
      reportsOpen: false,
      publisherReportsOpen: false,
      genericSystemOpen: false,
      genericCustomOpen: false,
      publisherMainLabelOpen: false,
      publisherBackLabelOpen: false,
      bpaMarketing: false,
      warehouseManagementOpen: false,
    },
    selectSubList: {
      home: false,
      adminSettings: false,
      department: false,
      zone: false,
      role: false,
      superMenu: false,
      menu: false,
      subMenu: false,
      roleAction: false,
      country: false,
      user: false,
      mailingScheme: false,
      customer: false,
      warehouseLocation: false,
      service: false,
      letterShop: false,
      cptCode: false,
      criteria: false,
      acronym: false,
      distributor: false,
      processType: false,
      processRate: false,
      province: false,
      processMaster: false,
      codeMaster: false,
      outworkerClaimRate: false,
      workorder: false,
      collectionNote: false,
      deliveryNote: false,
      process: false,
      DHLRate: false,
      dispatchMode: false,
      journalVersionMatrix: false,
      routingTitle: false,
      routingEMLOEBLO: false,
      routingZon: false,
      routingCountry: false,
      routingUKUS: false,
      routingPriority: false,
      localPostage: false,
      orderPlacement: false,
      genericSystem: false,
      productionLine: false,
      itemMaster: false,
      stockout: false,
      stockIndent: false,
      jobScheduler: false,
      stockInReport: false,
      stockOutReport: false,
      stockMovementReport: false,
      stockBalanceReport: false,
      reOrderReport: false,
      collectionNoteManifest: false,
      deliveryNoteManifest: false,
      stockAgingReport: false,
      workOrderManifest: false,
      MBAManifestReport: false,
      DocketPostingManifestReport: false,
      RevenueReport: false,
      ProductionSummaryReport: false,
      ProductionReport: false,
      docketPosting: false,
      pubJournalItemMaster: false,
      pubPreAlert: false,
      receivingReport: false,
      BPLOversReport: false,
      MailLodgementVolume: false,
      BLCPTVolumeReport: false,
      BLCPTSummaryReport: false,
      BLOrderCancellationReport: false,
      BLDetailedCostingReport: false,
      MLCPTWiley: false,
      MLCPTMacMillan: false,
      MLCPTWS: false,
      PublisherStockIn: false,
      PublisherStockOut: false,
      PublisherStockTransfer: false,
      PublisherStockBalance: false,
      PublisherStockMovement: false,
      pubInsertMaster: false,
      pubMainLabelOrderPlacement: false,
      pubMainLabelBatchPlacement: false,
      pubEmloOrderPlacement: false,
      pubEmloBatchProcessing: false,
      pubEbloBatchProcessing: false,
      pubDocketPosting: false,
      pubDocketBagging: false,
      backLabelStockIn: false,
      backLabelStockOut: false,
      backLabelStockTransfer: false,
      pubEbloOrderPlacement: false,
      backLabelBatchProcessing: false,
      backLabelBPAOrderProcessing: false,
      BackLabelDocketPosting: false,
      backLabelBillingProcess: false,
      bpaOrderPlacement: false,
      bpaRecipientList: false,
      dataMapping: false,
      pubMainLabelDocketBagging: false,
      pubReportMLJDR: false,
      pubReportMLJRR: false,
      PublisherEmloCpt: false,
      PublisherEMLOSummery: false,
      PublisherEbloCpt: false,
      PublisherBLCPTG: false
    },
    menu: {
      menuID: "",
      menuName: "",
      isActive: false,
    },
    subMenu: {
      subMenuName: "",
      subMenuID: "",
      menuName: "",
      menuID: "",
      isActive: "",
    },
    menuList: [],
    subMenuList: [],
    normalFlow: false,
    count: 0,
  };

  displayReport = (reportName) => {
    let reportURL =
      "http://172.22.43.196/report/reportviewer?Reportname=" + reportName;
    window.open(
      reportURL,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=200,width=1000,height=600"
    );
    // let reportUname = localStorage.getItem("reportUname");
    // let reportPwd = localStorage.getItem("reportPwd");
    // let reportURL;
    // if (reportName === "stockInReport")
    //   reportURL =
    //     "http://" +
    //     reportUname +
    //     ":" +
    //     reportPwd +
    //     "@10.10.11.131:81/ReportServer/Pages/ReportViewer.aspx?%2fPGS_Report_QA%2fStockIN&rs:Command=Render";
    // else if (reportName === "collectionManifest")
    //   reportURL =
    //     "http://" +
    //     reportUname +
    //     ":" +
    //     reportPwd +
    //     "@10.10.11.131:81/ReportServer/Pages/ReportViewer.aspx?%2fPGS_Report_QA%2fReorderLevel&rs:Command=Render&rs:Embed=true//collectionNoteManifest";
    // window.open(
    //   reportURL,
    //   "_blank",
    //   "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=200,width=1000,height=600"
    // );
  };

  handleClick = (e, name, warehouseManagementOpen) => {
    // if (process.browser) if (window.location.pathname.slice(1) === name) return;
    let newOpenArr = { ...this.state.openArr };
    let newselectSubList = { ...this.state.selectSubList };
    for (let k in newOpenArr) {
      newOpenArr[k] = false;
    }
    newOpenArr[name] = !this.state.openArr[name];
    if (name == "home") {
      this.props.history.push("/home");
      newOpenArr["home"] = true;
      this.setState({
        openArr: newOpenArr,
        normalFlow: false,
        count: 0,
        selectSubList: {},
        selected: "",
      });
    } else {
      if (warehouseManagementOpen) {
        // for (let k in newOpenArr) {
        //   newOpenArr[k] = false;
        // }
        newOpenArr["genericSystemOpen"] = true;
        newOpenArr[warehouseManagementOpen] = !this.state.openArr
          .warehouseManagementOpen;

        for (let k in newselectSubList) {
          newselectSubList[k] = false;
        }
        this.setState({
          warehouseClicked: !this.state.openArr.warehouseManagementOpen,
        });
      }
      if (!warehouseManagementOpen) {
        this.setState({ warehouseClicked: false });
      }
      this.setState({
        openArr: newOpenArr,
        normalFlow: true,
        count: 0,
        selectSubList: newselectSubList,
        selected: name,
      });
      this.props.handleDrawerOpen();
    }
  };
  handleSubListClick = (e, name) => {
    // let newOpenArr = { ...this.state.openArr };
    // for (let k in newOpenArr) {
    //   newOpenArr[k] = false;
    // }
    // newOpenArr[this.props.selectedPrimaryListItem] = !this.state.openArr[
    //   this.props.selectedPrimaryListItem
    // ];
    //this.setState({ openArr: newOpenArr });

    if (process.browser) if (window.location.pathname.slice(1) === name) return;

    // console.log(window.location.pathname.slice(1))
    let newselectSubList = { ...this.state.selectSubList };
    for (let k in newselectSubList) {
      newselectSubList[k] = false;
    }
    newselectSubList[name] = !this.state.selectSubList[name];
    if (
      name === "itemMaster" ||
      name === "stockIn" ||
      name === "stockout" ||
      name === "stockIndent"
    ) {
      let newOpenArr = { ...this.state.openArr };
      for (let k in newOpenArr) {
        newOpenArr[k] = false;
      }
      newOpenArr["genericSystem"] = true;
      newOpenArr["warehouseManagementOpen"] = true;
      // this.setState({ openArr: newOpenArr, normalFlow: true, count: 0 });
      this.setState({ openArr: newOpenArr });
    } else {
      this.setState({ warehouseClicked: false });
    }
    this.setState(
      {
        selectSubList: newselectSubList,
        normalFlow: true,
        count: 0,
      },
      () => {
        this.props.history.push(`/${name}`);
      }
    );
  };
  componentDidMount() {
    let userID = window.localStorage.loggedInId;

    getRoleAccess(userID).then((r) => {
      this.setState({ roleAccess: r });
    });

    let val = "";
    if (process.browser) val = window.location.pathname.split("/")[1];
    if (
      val === "add_deliveryNote" ||
      val === "add_collectionNote" ||
      val === "edit_collectionNote" ||
      val === "edit_deliveryNote" ||
      val === "add_userOrder" ||
      val === "edit_userOrder" ||
      val === "add_mbaReport" ||
      val === "add_pubMainLabelBatchPlacement" ||
      val === "create_preProcess"
    )
      this.props.handleDrawerClose();
  }
  shouldComponentUpdate(p, s) {
    let newOpenArr = { ...this.state.openArr };
    let newSelList = { ...this.state.selectedList }
    let newselectSubList = { ...this.state.selectSubList };
    if (p.selectedPrimaryListItem === "home" && this.props.selectedPrimaryListItem !== "home") {
      for (let k in newOpenArr) {
        newOpenArr[k] = false;
      }
      for (let k in newselectSubList) {
        newselectSubList[k] = false;
      }
      newOpenArr.home = true
      newSelList.home = true
      newselectSubList.pubReportMLJDR = false
      newselectSubList.pubReportMLJRR = false
      this.setState({ openArr: newOpenArr, selectSubList: newselectSubList, selectedList: newSelList })
      return true
    }

    for (let k in newOpenArr) {
      //if (newOpenArr[k] === true) setSelectedList = true;
      newOpenArr[k] = false;
    }
    // let newselectSubList = { ...this.state.selectSubList };

    if (p.selectedPrimaryListItem !== this.props.selectedPrimaryListItem) {
      //newOpenArr[p.selectedPrimaryListItem] = true;
      this.setState({ openArr: newOpenArr });
    }
    if (p.clearOpenList && this.state.normalFlow && this.state.count === 0) {
      //newOpenArr[p.selectedPrimaryListItem] = true;
      if (this.state.warehouseClicked) {
        newOpenArr["warehouseManagementOpen"] = true;
        newOpenArr["genericSystemOpen"] = true;
      }
      this.setState({ openArr: newOpenArr, normalFlow: false, count: 1 });
    }
    //
    if (
      p.selectedPrimaryListItem &&
      p.clearOpenList &&
      !this.state.normalFlow
    ) {
      if (
        p.selectedsecondaryListItem === "stockIn" ||
        p.selectedsecondaryListItem === "StockOut" ||
        p.selectedsecondaryListItem === "itemMaster" ||
        p.selectedsecondaryListItem === "stockIndent"
      )
        newOpenArr["warehouseManagementOpen"] = true;
      if (this.state.warehouseClicked) {
        newOpenArr["warehouseManagementOpen"] = true;
        newOpenArr["genericSystemOpen"] = true;
      } else newOpenArr[this.state.selected] = true;
      this.setState({ openArr: newOpenArr, normalFlow: true });
    }
    if (this.state.count < 1)
      if (!p.clearOpenList) {
        this.setState({ normalFlow: false, count: 1 });
      }
    if (!p.clearOpenList && this.state.count === 1 && this.state.normalFlow) {
      this.setState({ normalFlow: false, count: 0 });
    }
    // if (this.state.count === 1 && !p.clearOpenList)
    //   this.setState({ normalFlow: false, count: 0 });
    // if (
    //   p.clearOpenList &&
    //   !setSelectedList &&
    //   p.selectedPrimaryListItem != ""
    // ) {
    //   newOpenArr[p.selectedPrimaryListItem] = true;
    //   this.setState({ openArr: newOpenArr });
    // }
    // if(p.clearOpenList && this.state.selectSubList.)

    if (p.selectedsecondaryListItem !== this.props.selectedsecondaryListItem) {
      newselectSubList[p.selectedsecondaryListItem] = true;
      this.setState({ selectSubList: newselectSubList });
    }
    if (p.clearOpenList !== this.props.clearOpenList) {
      let newOpenArr = { ...this.state.openArr };
      for (let k in newOpenArr) {
        newOpenArr[k] = false;
      }
      this.setState({ openArr: newOpenArr });
    }

    if (p.selectedPrimaryListItem === "") return true;
    return true;
  }
  render() {
    // console.log(this.state.openArr);
    let { classes } = this.props;

    //----------------Super menu--------------------------
    var masterDataManage = this.state.roleAccess.filter((r) => {
      return r.superMenuName === "Master Data Management";
    });
    var masterDataManageFlag = masterDataManage.some(
      (r) => r.viewAction === true
    );
    var adminManage = this.state.roleAccess.filter((r) => {
      return r.superMenuName === "Admin Settings";
    });
    var adminManageFlag = adminManage.some((r) => r.viewAction === true);
    var genericManage = this.state.roleAccess.filter((r) => {
      return r.superMenuName === "Generic System";
    });
    var genericManageFlag = genericManage.some((r) => r.viewAction === true);

    var genericManage = this.state.roleAccess.filter((r) => {
      return r.superMenuName === "Generic System";
    });
    var genericManageFlag = genericManage.some((r) => r.viewAction === true);
    var genericCustManage = this.state.roleAccess.filter((r) => {
      return r.superMenuName === "Generic Customer";
    });
    var genericCustManageFlag = genericCustManage.some(
      (r) => r.viewAction === true
    );

    var publisherMainLabelManage = this.state.roleAccess.filter((r) => {
      return r.superMenuName === "Publisher Main Label";
    });
    var publisherMainLabelManageFlag = publisherMainLabelManage.some(
      (r) => r.viewAction === true
    );

    var publisherBackLabelManage = this.state.roleAccess.filter((r) => {
      return r.superMenuName === "Publisher Back Label";
    });
    var publisherBackLabelManageFlag = publisherBackLabelManage.some(
      (r) => r.viewAction === true
    );

    var BPAMarketManage = this.state.roleAccess.filter((r) => {
      return r.superMenuName === "BPA Markerting";
    });
    var BPAMarketManageFlag = BPAMarketManage.some(
      (r) => r.viewAction === true
    );

    var genericReports = this.state.roleAccess.filter((r) => {
      return r.superMenuName === "Generic Reports";
    });
    var genericReportsFlag = genericReports.some((r) => r.viewAction === true);

    var publisherReports = this.state.roleAccess.filter((r) => {
      return r.superMenuName === "Publisher Reports";
    });
    var publisherReportsFlag = publisherReports.some(
      (r) => r.viewAction === true
    );
    //========================================================================================
    let acnArr = this.state.roleAccess.filter((u) => u.menuName === "Acronym");
    var acnObj = acnArr[0];
    let roleArr = this.state.roleAccess.filter((u) => u.menuName === "Role");
    var roleObj = roleArr[0];
    let roleActionArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Role Action"
    );
    var roleActionObj = roleActionArr[0];

    let cdmArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Code Master"
    );
    var cdmObj = cdmArr[0];
    let cdtArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Code Type"
    );
    var cdtObj = cdtArr[0];
    let cptArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Cost Per Title"
    );
    var cptObj = cptArr[0];
    let cntArr = this.state.roleAccess.filter((u) => u.menuName === "Country");
    var cntObj = cntArr[0];
    let custArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Customer"
    );
    var custObj = custArr[0];
    let dptArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Department"
    );
    var dptObj = dptArr[0];
    let dpmArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Dispatch Mode"
    );
    var dpmObj = dpmArr[0];
    let disArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Distributor"
    );
    var disObj = disArr[0];
    let jvmArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Journal Version Matrix"
    );
    var jvmObj = jvmArr[0];
    let ltsArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Lettershop"
    );
    var ltsObj = ltsArr[0];
    let lcpArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Local Postage"
    );
    var lcpObj = lcpArr[0];

    let dhlArr = this.state.roleAccess.filter((u) => u.menuName === "DHL Rate");
    var dhlObj = dhlArr[0];

    let mscArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Mailing Scheme"
    );
    var mscObj = mscArr[0];

    let ocrArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Outworker Claim Rate"
    );

    var ocrObj = ocrArr[0];
    let pdlArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Production Line"
    );

    var pdlObj = pdlArr[0];
    let prrArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Process Rate"
    );
    var prrObj = prrArr[0];
    let prmArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Process Master"
    );
    var prmObj = prmArr[0];
    let prtArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Process Type"
    );
    var prtObj = prtArr[0];

    let pceArr = this.state.roleAccess.filter((u) => u.menuName === "Provice");
    var pceObj = pceArr[0];

    let czgArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Country Zone Grouping"
    );
    var czgObj = czgArr[0];

    let rbcArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Routing By Country"
    );
    var rbcObj = rbcArr[0];

    let emloArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Routing By EMLO EBLO"
    );
    var emloObj = emloArr[0];

    let rtlArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Routing Title"
    );
    var rtlObj = rtlArr[0];

    let rUKArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Routing UK US"
    );
    var rUKObj = rUKArr[0];
    let rprArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Routing Priority"
    );
    var rprObj = rprArr[0];
    let userArr = this.state.roleAccess.filter((u) => u.menuName === "User");
    var userObj = userArr[0];
    let wareLArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Warehouse Location"
    );
    var wareLObj = wareLArr[0];

    let zoneLArr = this.state.roleAccess.filter((u) => u.menuName === "Zone");
    var zoneLObj = zoneLArr[0];

    let uopArr = this.state.roleAccess.filter(
      (u) => u.menuName === "User Order Placement"
    );
    var uopObj = uopArr[0];
    let workArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Work Order"
    );
    var workObj = workArr[0];
    let jobsArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Job Scheduler"
    );
    var jobsObj = jobsArr[0];
    let cNoteArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Colletion Note"
    );
    var cNoteObj = cNoteArr[0];
    let dNoteArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Delivery Note"
    );
    var dNoteObj = dNoteArr[0];
    let dpArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Docket Posting"
    );
    var dpObj = dpArr[0];
    let stockIndentArr = this.state.roleAccess.filter(
      (u) => u.subMenuName === "Stock Indent"
    );
    var stockIndentObj = stockIndentArr[0];
    let stockOutArr = this.state.roleAccess.filter(
      (u) => u.subMenuName === "Stock Out"
    );
    var stockOutObj = stockOutArr[0];
    let itemArr = this.state.roleAccess.filter(
      (u) => u.subMenuName === "Item Master"
    );
    var itemObj = itemArr[0];
    let stockInArr = this.state.roleAccess.filter(
      (u) => u.subMenuName === "Stock In"
    );
    var stockInObj = stockInArr[0];
    let opArr = this.state.roleAccess.filter(
      (u) => u.superMenuName === "Generic Customer"
    );
    var opObj = opArr[0];
    //=======================Publisher====================================================

    let jimArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Journal Item Master"
    );
    var jimObj = jimArr[0];

    let preAlertArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Pre Alert"
    );
    var preAlertObj = preAlertArr[0];

    let insertMasterArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Insert Master"
    );
    var insertMasterObj = insertMasterArr[0];

    let mlopArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Main Label Order Placement"
    );
    var mlopObj = mlopArr[0];

    let mlbpArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Main Label Batch Processing"
    );
    var mlbpObj = mlbpArr[0];

    let eopArr = this.state.roleAccess.filter(
      (u) => u.menuName === "EMLO Order Placement"
    );
    var eopObj = eopArr[0];
    let dataMappingArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Data Mapping"
    );
    var dataMappingObj = dataMappingArr[0];

    let docketPostingArr =
      publisherMainLabelManage.length > 0 &&
      publisherMainLabelManage.filter(
        (u) => u.menuName === "Docket Posting/Bagging"
      );
    var docketPostingObj = docketPostingArr[0];

    let blsiArr =
      publisherBackLabelManage.length > 0 &&
      publisherBackLabelManage.filter((u) => u.menuName === "Stock In");
    var blsiObj = blsiArr[0];
    let blsoArr =
      publisherBackLabelManage.length > 0 &&
      publisherBackLabelManage.filter((u) => u.menuName === "Stock Out");
    var blsoObj = blsoArr[0];

    let blstArr =
      publisherBackLabelManage.length > 0 &&
      publisherBackLabelManage.filter((u) => u.menuName === "Stock Transfer");
    var blstObj = blstArr[0];

    let blebloArr =
      publisherBackLabelManage.length > 0 &&
      publisherBackLabelManage.filter(
        (u) => u.menuName === "EBLO Order Placement"
      );
    var blebloObj = blebloArr[0];

    let bpManage =
      publisherBackLabelManage.length > 0 &&
      publisherBackLabelManage.filter((u) => u.menuName === "Batch Processing");
    var bpManageObj = bpManage[0];

    let dpbManage =
      publisherBackLabelManage.length > 0 &&
      publisherBackLabelManage.filter(
        (u) => u.menuName === "Docket Posting/Bagging"
      );
    var dpbManageObj = dpbManage[0];

    let bpcptManage =
      publisherBackLabelManage.length > 0 &&
      publisherBackLabelManage.filter(
        (u) => u.menuName === "Billing Process CPT"
      );
    var bpcptManageObj = bpcptManage[0];

    let BPAorderplaceManage =
      BPAMarketManage.length > 0 &&
      BPAMarketManage.filter((u) => u.menuName === "Order Placement");
    var BPAorderplaceManageObj = BPAorderplaceManage[0];

    let bpaRLArr =
      BPAMarketManage.length > 0 &&
      BPAMarketManage.filter((u) => u.menuName === "BPA Recipient List");
    var bpaRLObj = bpaRLArr[0];

    //===================Reports=======================================

    let stockReportArr =
      genericReports.length > 0 &&
      genericReports.filter((u) => u.menuName === "Stock In");
    var stockReportObj = stockReportArr[0];

    let stockOutReportArr =
      genericReports.length > 0 &&
      genericReports.filter((u) => u.menuName === "Stock Out");
    var stockOutReportObj = stockOutReportArr[0];

    let stockBalReportArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Stock Balance"
    );
    var stockBalReportObj = stockBalReportArr[0];

    let stockMovReportArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Stock Movement"
    );
    var stockMovReportObj = stockMovReportArr[0];

    let reorderReportArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Reorder"
    );
    var reorderReportObj = reorderReportArr[0];

    let cnmArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Collection Note Manifest"
    );
    var cnmObj = cnmArr[0];

    let dnmArr = this.state.roleAccess.filter(
      (u) => u.menuName === "DeliveryNote Manifest"
    );
    var dnmObj = dnmArr[0];

    let stockAgingArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Stock Aging"
    );
    var stockAgingObj = stockAgingArr[0];
    let WOManifestArr = this.state.roleAccess.filter(
      (u) => u.menuName === "WorkOrder Manifest"
    );
    var WOManifestObj = WOManifestArr[0];

    let MBAManifestReportArr = this.state.roleAccess.filter(
      (u) => u.menuName === "MBA Manifest"
    );
    var MBAManifestReportObj = MBAManifestReportArr[0];

    let DocketPostingManifestReportArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Docket Posting Manifest"
    );
    var DocketPostingManifestReportObj = DocketPostingManifestReportArr[0];

    let RevenueReportArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Revenue"
    );
    var RevenueReportObj = RevenueReportArr[0];

    let ProductionSummaryReportArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Production Summary"
    );
    var ProductionSummaryReportObj = ProductionSummaryReportArr[0];

    let ProductionReportArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Production"
    );
    var ProductionReportObj = ProductionReportArr[0];

    let JSRArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Receiving"
    );
    var JSRObj = JSRArr[0];

    let publisherStockInObjArr = this.state.roleAccess.filter(
      (u) => u.menuName === "BL Stock In"
    );
    var publisherStockInObj = publisherStockInObjArr[0];

    let publisherStockOutArr = this.state.roleAccess.filter(
      (u) => u.menuName === "BL Stock Out"
    );
    var publisherStockOutObj = publisherStockOutArr[0];
    // console.log(this.state.roleAccess,)
    let publisherStockTransferArr = this.state.roleAccess.filter(
      (u) => u.menuName === "BL Stock Transfer"
    );
    var publisherStockTransferObj = publisherStockTransferArr[0];

    // console.log(this.state.roleAccess,)
    let BPLOvers = this.state.roleAccess.filter(
      (u) => u.menuName === "BPL Overs Report"
    );
    var bplOversObj = BPLOvers[0];



    let dcrArr = this.state.roleAccess.filter(
      (u) => u.menuName === "Docket Checklist"
    );
    let publisherJRRArr = this.state.roleAccess.filter(
      (u) => u.menuName === "ML Journal Received(JRR)"
    );
    var publisherJRRObj = publisherJRRArr[0];

    let publisherJDRArr = this.state.roleAccess.filter(
      (u) => u.menuName === "ML Journal Dispatch(JDR)"
    );
    var publisherJDRObj = publisherJDRArr[0];



    let publisherSB = this.state.roleAccess.filter(
      (u) => u.menuName === "Publisher Stock Balance"
    );
    let publisherSM = this.state.roleAccess.filter(
      (u) => u.menuName === "Publisher Stock Movement"
    );
    let publisherMLV = this.state.roleAccess.filter(
      (u) => u.menuName === "Mail Lodgement Volume Report"
    );
    let publisherBLCV = this.state.roleAccess.filter(
      (u) => u.menuName === "BL CPT Volume Report"
    );
    let publisherSBObj = publisherSB[0]
    let publisherSMObj = publisherSM[0]
    let publisherMLVObj = publisherMLV[0]
    let publisherBLCVObj = publisherBLCV[0]


    let pubMLCPTWiley = this.state.roleAccess.filter(
      (u) => u.menuName === "ML CPT Wiley"
    );
    let pubMLCPTMacMillan = this.state.roleAccess.filter(
      (u) => u.menuName === "ML CPT MacMillan"
    );
    let pubMLCPTWS = this.state.roleAccess.filter(
      (u) => u.menuName === "ML CPT WS"
    );
    let pubMLCPTWileyObj = pubMLCPTWiley[0]
    let pubMLCPTMacMillanObj = pubMLCPTMacMillan[0]
    let pubMLCPTWSObj = pubMLCPTWS[0]


    let pubBLCPTSum = this.state.roleAccess.filter(
      (u) => u.menuName === "BL CPT Summary"
    );
    let pubBLOrdCncl = this.state.roleAccess.filter(
      (u) => u.menuName === "BL Order Cancellation"
    );
    let pubBLDC = this.state.roleAccess.filter(
      (u) => u.menuName === "BL Detailed Costing"
    );
    let pubEMLOCPT = this.state.roleAccess.filter(
      (u) => u.menuName === "EMLO CPT"
    );
    let pubEMLOSummery = this.state.roleAccess.filter(
      (u) => u.menuName === "EMLO Summery"
    );
    let pubEBLOCPT = this.state.roleAccess.filter(
      (u) => u.menuName === "EBLO CPT"
    );
    let pubBLCPTG = this.state.roleAccess.filter(
      (u) => u.menuName === "BL CPT G3"
    );
    let pubBLCPTSumObj = pubBLCPTSum[0]
    let pubBLOrdCnclObj = pubBLOrdCncl[0]
    let pubBLDCObj = pubBLDC[0]
    let pubEMLOCPTObj = pubEMLOCPT[0]
    let pubEMLOSummeryObj = pubEMLOSummery[0]
    let pubEBLOCPTObj = pubEBLOCPT[0]
    let pubBLCPTGObj = pubBLCPTG[0]
    // console.log(pubBLCPTSum)
    masterDataManageFlag = false
    adminManageFlag = false
    genericManageFlag = false
    // genericCustManageFlag = false
    publisherMainLabelManageFlag = false
    publisherBackLabelManageFlag = false
    // genericReportsFlag = false
    // publisherReportsFlag = false
    var dcrObj = dcrArr[0];
    return (
      <div>
        <List aria-labelledby="nested-list-subheader">
          <ListItem
            button
            onClick={(e) => this.handleClick(e, "home")}
            className={
              this.state.openArr.home === true
                ? classes.selectedList
                : classes.notSelectedList
            }
          >
            <ListItemIcon style={{ minWidth: "auto", paddingRight: "10px" }}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              primary="Home"
              classes={{ primary: classes.listItemText }}
            />

          </ListItem>
          {/* <ListItem
            // selected={this.state.authorizationOpen ? true : false}
            button
            onClick={e => this.handleClick(e, "authorizationOpen")}
            className={
              this.state.openArr.authorizationOpen === true
                ? classes.selectedList
                : classes.notSelectedList
            }
          >
            <ListItemIcon style={{ minWidth: "auto", paddingRight: "10px" }}>
              <AuthoriationIcon />
            </ListItemIcon>
            <ListItemText
              primary="Authorization"
              classes={{ primary: classes.listItemText }}
            />
            {this.state.openArr.authorizationOpen ? (
              <ExpandLess style={{ color: "rgb(57, 130, 234)" }} />
            ) : (
              <ExpandMore style={{ color: "#6d7381" }} />
            )}
          </ListItem> */}
          {/* <Collapse
            in={this.state.openArr.authorizationOpen}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem button style={{ paddingLeft: "2em" }}>
                <ListItemIcon
                  style={{ minWidth: "auto", paddingRight: "10px" }}
                >
                  <StarBorder />
                </ListItemIcon>
                <ListItemText
                  primary="Authorization"
                  classes={{ primary: classes.listItemText }}
                />
              </ListItem>
            </List>
          </Collapse> */}
          {adminManage.length > 0 && adminManageFlag ? (
            <ListItem
              button
              // selected={this.state.adminSettingOpen ? true : false}
              onClick={(e) => this.handleClick(e, "adminSettingOpen")}
              className={
                this.state.openArr.adminSettingOpen === true
                  ? classes.selectedList
                  : classes.notSelectedList
              }
            >
              <ListItemIcon style={{ minWidth: "auto", paddingRight: "10px" }}>
                <AdminIcon />
              </ListItemIcon>
              <ListItemText
                primary="Admin Settings"
                classes={{ primary: classes.listItemText }}
              />
              {this.state.openArr.adminSettingOpen ? (
                <ExpandLess style={{ color: "rgb(57, 130, 234)" }} />
              ) : (
                  <ExpandMore style={{ color: "#6d7381" }} />
                )}
            </ListItem>
          ) : null}

          <Collapse
            in={this.state.openArr.adminSettingOpen}
            timeout="auto"
            unmountOnExit
          >
            <div style={{ display: "flex", marginTop: "4px" }}>
              <div
                style={{
                  borderRight: "1px solid rgb(199, 193, 193)",
                  height: "auto",
                  marginLeft: "2.9em",
                }}
              ></div>
              <List component="div" disablePadding>
                {this.state.roleAccess.length > 0 &&
                  roleObj &&
                  roleObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.role
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "role")}
                    >
                      <ListItemText
                        primary="Role"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  roleActionObj &&
                  roleActionObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.roleAction
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "roleAction")}
                    >
                      <ListItemText
                        primary="Role Action"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
              </List>
            </div>
          </Collapse>
          {masterDataManage.length > 0 && masterDataManageFlag ? (
            <ListItem
              button
              onClick={(e) => this.handleClick(e, "masterDataOpen")}
              className={
                this.state.openArr.masterDataOpen === true
                  ? classes.selectedList
                  : classes.notSelectedList
              }
            >
              <ListItemIcon style={{ minWidth: "auto", paddingRight: "10px" }}>
                <MDMIcon />
              </ListItemIcon>
              <ListItemText
                primary="Master Data Manage..."
                classes={{ primary: classes.listItemText }}
              />
              {this.state.openArr.masterDataOpen ? (
                <ExpandLess style={{ color: "rgb(57, 130, 234)" }} />
              ) : (
                  <ExpandMore style={{ color: "#6d7381" }} />
                )}
            </ListItem>
          ) : null}

          <Collapse
            in={this.state.openArr.masterDataOpen}
            timeout="auto"
            unmountOnExit
          >
            <div style={{ display: "flex", marginTop: "4px" }}>
              <div
                style={{
                  borderRight: "1px solid rgb(199, 193, 193)",
                  height: "auto",
                  marginLeft: "2.9em",
                }}
              ></div>
              <List component="div" disablePadding>
                {this.state.roleAccess.length > 0 &&
                  acnObj &&
                  acnObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.acronym
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "acronym")}
                    >
                      <ListItemText
                        primary="Acronym"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  cdmObj &&
                  cdmObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.codeMaster
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "codeMaster")}
                    >
                      <ListItemText
                        primary="Code Master"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  cdtObj &&
                  cdtObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.codeType
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "codeType")}
                    >
                      <ListItemText
                        primary="Code Type"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  cptObj &&
                  cptObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.cptCode
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "cptCode")}
                    >
                      <ListItemText
                        primary="Cost Per Title"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  cntObj &&
                  cntObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.country
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "country")}
                    >
                      <ListItemText
                        primary="Country"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  custObj &&
                  custObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.customer
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "customer")}
                    >
                      <ListItemText
                        primary="Customer"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  dptObj &&
                  dptObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.department
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "department")}
                    >
                      <ListItemText
                        primary="Department"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  dhlObj &&
                  dhlObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.DHLRate
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "DHLRate")}
                    >
                      <ListItemText
                        primary="DHL Rate"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  dpmObj &&
                  dpmObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.dispatchMode
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "dispatchMode")}
                    >
                      <ListItemText
                        primary="Dispatch Mode"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  disObj &&
                  disObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.distributor
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "distributor")}
                    >
                      <ListItemText
                        primary="Distributor"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  jvmObj &&
                  jvmObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.journalVersionMatrix
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "journalVersionMatrix")
                      }
                    >
                      <ListItemText
                        primary="Journal Version Matrix"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  ltsObj &&
                  ltsObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.letterShop
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "letterShop")}
                    >
                      <ListItemText
                        primary="Lettershop"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  lcpObj &&
                  lcpObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.localPostage
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "localPostage")}
                    >
                      <ListItemText
                        primary="Local Postage"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  mscObj &&
                  mscObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.mailingScheme
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "mailingScheme")}
                    >
                      <ListItemText
                        primary="Mailing Scheme"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {false ? (
                  <ListItem
                    button
                    className={
                      this.state.selectSubList.outworkerClaimRate
                        ? classes.selectedSubList
                        : classes.notSelectedSubList
                    }
                    onClick={(e) =>
                      this.handleSubListClick(e, "outworkerClaimRate")
                    }
                  >
                    <ListItemText
                      primary="Outworker Claim Rate"
                      classes={{ primary: classes.subListItemText }}
                    />
                  </ListItem>
                ) : null}

                {this.state.roleAccess.length > 0 &&
                  pdlObj &&
                  pdlObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.productionLine
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "productionLine")
                      }
                    >
                      <ListItemText
                        primary="Production Line"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  prrObj &&
                  prrObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.processRate
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "processRate")}
                    >
                      <ListItemText
                        primary="Process Rate"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  prmObj &&
                  prmObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.processMaster
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "processMaster")}
                    >
                      <ListItemText
                        primary="Process Master"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  prtObj &&
                  prtObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.processType
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "processType")}
                    >
                      <ListItemText
                        primary="Process Type"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  pceObj &&
                  pceObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.province
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "province")}
                    >
                      <ListItemText
                        primary="Province"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  czgObj &&
                  czgObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.routingZone
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "countryZoneGrouping")
                      }
                    >
                      <ListItemText
                        primary="Country Zone Grouping"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  rbcObj &&
                  rbcObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.routingCountry
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "routingCountry")
                      }
                    >
                      <ListItemText
                        primary="Routing By Country"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  emloObj &&
                  emloObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.routingEMLOEBLO
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "routingEMLOEBLO")
                      }
                    >
                      <ListItemText
                        primary="Routing EMLO EBLO"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  rtlObj &&
                  rtlObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.routingTitle
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "routingTitle")}
                    >
                      <ListItemText
                        primary="Routing Title"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  rUKObj &&
                  rUKObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.routingUKUS
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "routingUKUS")}
                    >
                      <ListItemText
                        primary="Routing UK US"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  rprObj &&
                  rprObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.routingPriority
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "routingPriority")
                      }
                    >
                      <ListItemText
                        primary="Routing Priority"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  userObj &&
                  userObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.user
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "user")}
                    >
                      <ListItemText
                        primary="User"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  wareLObj &&
                  wareLObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.warehouseLocation
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "warehouseLocation")
                      }
                    >
                      <ListItemText
                        primary="Warehouse Location"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  zoneLObj &&
                  zoneLObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.zone
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "zone")}
                    >
                      <ListItemText
                        primary="Zone"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
              </List>
            </div>
          </Collapse>
          {/* <ListItem
            button
            onClick={e => this.handleClick(e, "warehouseManagementOpen")}
            className={
              this.state.openArr.warehouseManagementOpen === true
                ? classes.selectedList
                : classes.notSelectedList
            }
          >
            <ListItemIcon style={{ minWidth: "auto", paddingRight: "10px" }}>
              <AdminIcon />
            </ListItemIcon>
            <ListItemText
              primary="Warehouse Management"
              classes={{ primary: classes.listItemText }}
            />
            {this.state.openArr.warehouseManagementOpen ? (
              <ExpandLess style={{ color: "rgb(57, 130, 234)" }} />
            ) : (
              <ExpandMore style={{ color: "#6d7381" }} />
            )}
          </ListItem>
          <Collapse
            in={this.state.openArr.warehouseManagementOpen}
            timeout="auto"
            unmountOnExit
          >
            <div style={{ display: "flex", marginTop: "4px" }}>
              <div
                style={{
                  borderRight: "1px solid rgb(199, 193, 193)",
                  height: "auto",
                  marginLeft: "2.9em"
                }}
              ></div>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={
                    this.state.selectSubList.itemMaster
                      ? classes.selectedSubList
                      : classes.notSelectedSubList
                  }
                  onClick={e => this.handleSubListClick(e, "itemMaster")}
                >
                  <ListItemText
                    primary="Item Master"
                    classes={{ primary: classes.subListItemText }}
                  />
                </ListItem>
                <ListItem
                  button
                  className={
                    this.state.selectSubList.stockIn
                      ? classes.selectedSubList
                      : classes.notSelectedSubList
                  }
                  onClick={e => this.handleSubListClick(e, "stockIn")}
                >
                  <ListItemText
                    primary="Stock In"
                    classes={{ primary: classes.subListItemText }}
                  />
                </ListItem>
                <ListItem
                  button
                  className={
                    this.state.selectSubList.stockout
                      ? classes.selectedSubList
                      : classes.notSelectedSubList
                  }
                  onClick={e => this.handleSubListClick(e, "stockout")}
                >
                  <ListItemText
                    primary="Stock Out"
                    classes={{ primary: classes.subListItemText }}
                  />
                </ListItem>
              </List>
            </div>
          </Collapse> */}
          {genericManage.length > 0 && genericManageFlag ? (
            <ListItem
              button
              onClick={(e) => this.handleClick(e, "genericSystemOpen")}
              className={
                this.state.openArr.genericSystemOpen === true
                  ? classes.selectedList
                  : classes.notSelectedList
              }
            >
              <ListItemIcon style={{ minWidth: "auto", paddingRight: "10px" }}>
                <PermDataSettingIcon />
              </ListItemIcon>
              <ListItemText
                primary="Generic System"
                classes={{ primary: classes.listItemText }}
              />
              {this.state.openArr.genericSystemOpen ? (
                <ExpandLess style={{ color: "rgb(57, 130, 234)" }} />
              ) : (
                  <ExpandMore style={{ color: "#6d7381" }} />
                )}
            </ListItem>
          ) : null}

          <Collapse
            in={this.state.openArr.genericSystemOpen}
            timeout="auto"
            unmountOnExit
          >
            <div style={{ display: "flex", marginTop: "4px" }}>
              <div
                style={{
                  borderRight: "1px solid rgb(199, 193, 193)",
                  height: "auto",
                  marginLeft: "2.9em",
                }}
              ></div>
              <List component="div" disablePadding>
                {this.state.roleAccess.length > 0 &&
                  uopObj &&
                  uopObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.genericSystem
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "genericSystem")}
                    >
                      <ListItemText
                        primary="User Order Placement"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  workObj &&
                  workObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.workOrder
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "workOrders")}
                    >
                      <ListItemText
                        primary="Work Order"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  jobsObj &&
                  jobsObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.jobScheduler
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "jobScheduler")}
                    >
                      <ListItemText
                        primary="Job Scheduler"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  cNoteObj &&
                  cNoteObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.collectionNote
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "collectionNote")
                      }
                    >
                      <ListItemText
                        primary="Collection Note"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  dNoteObj &&
                  dNoteObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.deliveryNote
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "deliveryNote")}
                    >
                      <ListItemText
                        primary="Delivery Note"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  dpObj &&
                  dpObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.docketPosting
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "docketPosting")}
                    >
                      <ListItemText
                        primary="Docket Posting"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {/* warehouse////////////////////////////////////////////////////////////// */}
                {this.state.roleAccess.length > 0 &&
                  (itemObj.viewAction ||
                    stockInObj.viewAction ||
                    stockOutObj.viewAction ||
                    stockIndentObj.viewAction) ? (
                    <div>
                      <ListItem
                        button
                        onClick={(e) =>
                          this.handleClick(
                            e,
                            "genericSystemOpen",
                            "warehouseManagementOpen"
                          )
                        }
                        className={
                          this.state.openArr.warehouseManagementOpen === true
                            ? classes.selectedListWarehouse
                            : classes.notSelectedListWarehouse
                        }
                      >
                        <ListItemText
                          primary="Warehouse Manage"
                          classes={{ primary: classes.subListItemText }}
                        />
                        {this.state.openArr.warehouseManagementOpen ? (
                          <ExpandLess
                            style={{
                              color: "rgb(57, 130, 234)",
                              marginLeft: "18px",
                            }}
                          />
                        ) : (
                            <ExpandMore
                              style={{ color: "#6d7381", marginLeft: "18px" }}
                            />
                          )}
                      </ListItem>

                      <Collapse
                        in={this.state.openArr.warehouseManagementOpen}
                        timeout="auto"
                        unmountOnExit
                      >
                        <div style={{ display: "flex", marginTop: "4px" }}>
                          <div
                            style={{
                              borderRight: "1px solid rgb(199, 193, 193)",
                              height: "auto",
                              marginLeft: "2.9em",
                            }}
                          ></div>
                          <List component="div" disablePadding>
                            {this.state.roleAccess.length > 0 &&
                              itemObj &&
                              itemObj.viewAction ? (
                                <ListItem
                                  button
                                  className={
                                    this.state.selectSubList.itemMaster
                                      ? classes.selectedSubList
                                      : classes.notSelectedSubList
                                  }
                                  onClick={(e) =>
                                    this.handleSubListClick(e, "itemMaster")
                                  }
                                >
                                  <ListItemText
                                    primary="Item Master"
                                    classes={{ primary: classes.subListItemText }}
                                  />
                                </ListItem>
                              ) : null}
                            {this.state.roleAccess.length > 0 &&
                              stockInObj &&
                              stockInObj.viewAction ? (
                                <ListItem
                                  button
                                  className={
                                    this.state.selectSubList.stockIn
                                      ? classes.selectedSubList
                                      : classes.notSelectedSubList
                                  }
                                  onClick={(e) =>
                                    this.handleSubListClick(e, "stockIn")
                                  }
                                >
                                  <ListItemText
                                    primary="Stock In"
                                    classes={{ primary: classes.subListItemText }}
                                  />
                                </ListItem>
                              ) : null}
                            {this.state.roleAccess.length > 0 &&
                              stockOutObj &&
                              stockOutObj.viewAction ? (
                                <ListItem
                                  button
                                  className={
                                    this.state.selectSubList.stockout
                                      ? classes.selectedSubList
                                      : classes.notSelectedSubList
                                  }
                                  onClick={(e) =>
                                    this.handleSubListClick(e, "stockout")
                                  }
                                >
                                  <ListItemText
                                    primary="Stock Out"
                                    classes={{ primary: classes.subListItemText }}
                                  />
                                </ListItem>
                              ) : null}
                            {this.state.roleAccess.length > 0 &&
                              stockIndentObj &&
                              stockIndentObj.viewAction ? (
                                <ListItem
                                  button
                                  className={
                                    this.state.selectSubList.stockIndent
                                      ? classes.selectedSubList
                                      : classes.notSelectedSubList
                                  }
                                  onClick={(e) =>
                                    this.handleSubListClick(e, "stockIndent")
                                  }
                                >
                                  <ListItemText
                                    primary="Stock Indent"
                                    classes={{ primary: classes.subListItemText }}
                                  />
                                </ListItem>
                              ) : null}
                          </List>
                        </div>
                      </Collapse>
                    </div>
                  ) : null}
                {/* warehouse closed///////////////////////////////////////////////////////////////////////// */}
              </List>
            </div>
          </Collapse>
          {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
          {genericCustManage.length > 0 && genericCustManageFlag ? (
            <ListItem
              button
              onClick={(e) => this.handleClick(e, "genericCustomOpen")}
              className={
                this.state.openArr.genericCustomOpen === true
                  ? classes.selectedList
                  : classes.notSelectedList
              }
            >
              <ListItemIcon style={{ minWidth: "auto", paddingRight: "10px" }}>
                <GenericCustomerIcon />
              </ListItemIcon>
              <ListItemText
                primary="Generic Customer"
                classes={{ primary: classes.listItemText }}
              />
              {this.state.openArr.genericCustomOpen ? (
                <ExpandLess style={{ color: "rgb(57, 130, 234)" }} />
              ) : (
                  <ExpandMore style={{ color: "#6d7381" }} />
                )}
            </ListItem>
          ) : null}

          <Collapse
            in={this.state.openArr.genericCustomOpen}
            timeout="auto"
            unmountOnExit
          >
            <div style={{ display: "flex", marginTop: "4px" }}>
              <div
                style={{
                  borderRight: "1px solid rgb(199, 193, 193)",
                  height: "auto",
                  marginLeft: "2.9em",
                }}
              ></div>
              <List component="div" disablePadding>
                {this.state.roleAccess.length > 0 &&
                  opObj &&
                  opObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.orderPlacement
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "orderPlacement")
                      }
                    >
                      <ListItemText
                        primary="Order Placement"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
              </List>
            </div>
          </Collapse>
          {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
          {genericReports.length > 0 && genericReportsFlag ? (
            <ListItem
              button
              onClick={(e) => this.handleClick(e, "reportsOpen")}
              className={
                this.state.openArr.reportsOpen === true
                  ? classes.selectedList
                  : classes.notSelectedList
              }
            >
              <ListItemIcon style={{ minWidth: "auto", paddingRight: "10px" }}>
                <ReportIcon />
              </ListItemIcon>
              <ListItemText
                primary="Generic Reports"
                classes={{ primary: classes.listItemText }}
              />
              {this.state.openArr.reportsOpen ? (
                <ExpandLess style={{ color: "rgb(57, 130, 234)" }} />
              ) : (
                  <ExpandMore style={{ color: "#6d7381" }} />
                )}
            </ListItem>
          ) : null}

          <Collapse
            in={this.state.openArr.reportsOpen}
            timeout="auto"
            unmountOnExit
          >
            <div style={{ display: "flex", marginTop: "4px" }}>
              <div
                style={{
                  borderRight: "1px solid rgb(199, 193, 193)",
                  height: "auto",
                  marginLeft: "2.9em",
                }}
              ></div>
              <List component="div" disablePadding>
                {/* <ListItem
                  button
                  className={
                    this.state.selectSubList.stockOutReport
                      ? classes.selectedSubList
                      : classes.notSelectedSubList
                  }
                  // onClick={(e) => this.handleSubListClick(e, "reportsDashboard")}
                  onClick={() => this.displayReport("StockIn")}
                >
                  <ListItemText
                    primary="Stock In Report - New"
                    classes={{ primary: classes.subListItemText }}
                  />
                </ListItem>
                <ListItem
                  button
                  className={
                    this.state.selectSubList.stockOutReport
                      ? classes.selectedSubList
                      : classes.notSelectedSubList
                  }
                  // onClick={(e) => this.handleSubListClick(e, "reportsDashboard")}
                  onClick={() => this.displayReport("ReorderLevel")}
                >
                  <ListItemText
                    primary="Collection Note Manifest - New"
                    classes={{ primary: classes.subListItemText }}
                  />
                </ListItem> */}
                {this.state.roleAccess.length > 0 &&
                  stockReportObj &&
                  stockReportObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.stockInReport
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "stockInReport")}
                    >
                      <ListItemText
                        primary="Stock In"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  stockOutReportObj &&
                  stockOutReportObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.stockOutReport
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "stockOutReport")
                      }
                    >
                      <ListItemText
                        primary="Stock Out"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  stockBalReportObj &&
                  stockBalReportObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.stockBalanceReport
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "stockBalanceReport")
                      }
                    >
                      <ListItemText
                        primary="Stock Balance"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  stockMovReportObj &&
                  stockMovReportObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.stockMovementReport
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "stockMovementReport")
                      }
                    >
                      <ListItemText
                        primary="Stock Movement"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  reorderReportObj &&
                  reorderReportObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.reOrderReport
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "reOrderReport")}
                    >
                      <ListItemText
                        primary="ReOrder"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  cnmObj &&
                  cnmObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.collectionNoteManifest
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "collectionNoteManifest")
                      }
                    >
                      <ListItemText
                        primary="Collection Note Manifest"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  dnmObj &&
                  dnmObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.deliveryNoteManifest
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "deliveryNoteManifest")
                      }
                    >
                      <ListItemText
                        primary="Delivery Note Manifest"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  stockAgingObj &&
                  stockAgingObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.stockAgingReport
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "stockAgingReport")
                      }
                    >
                      <ListItemText
                        primary="Stock Aging"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  WOManifestObj &&
                  WOManifestObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.workOrderManifest
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "workOrderManifest")
                      }
                    >
                      <ListItemText
                        primary="WorkOrder Manifest"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  MBAManifestReportObj &&
                  MBAManifestReportObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.MBAManifestReport
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "MBAManifestReport")
                      }
                    >
                      <ListItemText
                        primary="MBA Manifest"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  DocketPostingManifestReportObj &&
                  DocketPostingManifestReportObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.DocketPostingManifestReport
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "DocketPostingManifestReport")
                      }
                    >
                      <ListItemText
                        primary="Docket Posting Manifest"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  RevenueReportObj &&
                  RevenueReportObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.RevenueReport
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "RevenueReport")}
                    >
                      <ListItemText
                        primary="Revenue"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  ProductionSummaryReportObj &&
                  ProductionSummaryReportObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.ProductionSummaryReport
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "ProductionSummaryReport")
                      }
                    >
                      <ListItemText
                        primary="Production Summary"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  ProductionReportObj &&
                  ProductionReportObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.ProductionReport
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "ProductionReport")
                      }
                    >
                      <ListItemText
                        primary="Production"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {/* </ListItem> */}
              </List>
            </div>
          </Collapse>
          {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
          {publisherMainLabelManage.length > 0 &&
            publisherMainLabelManageFlag ? (
              <ListItem
                button
                onClick={(e) => this.handleClick(e, "publisherMainLabelOpen")}
                className={
                  this.state.openArr.publisherMainLabelOpen === true
                    ? classes.selectedList
                    : classes.notSelectedList
                }
              >
                <ListItemIcon style={{ minWidth: "auto", paddingRight: "10px" }}>
                  <ArrowRightIcon
                    style={{
                      fontSize: "41px",
                      marginLeft: "-11px",
                      marginRight: "-7px",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Publisher Main Label"
                  classes={{ primary: classes.listItemText }}
                />
                {this.state.openArr.publisherMainLabelOpen ? (
                  <ExpandLess style={{ color: "rgb(57, 130, 234)" }} />
                ) : (
                    <ExpandMore style={{ color: "#6d7381" }} />
                  )}
              </ListItem>
            ) : null}

          <Collapse
            in={this.state.openArr.publisherMainLabelOpen}
            timeout="auto"
            unmountOnExit
          >
            <div style={{ display: "flex", marginTop: "4px" }}>
              <div
                style={{
                  borderRight: "1px solid rgb(199, 193, 193)",
                  height: "auto",
                  marginLeft: "2.9em",
                }}
              ></div>
              {/* comments.................................. */}

              <List component="div" disablePadding>
                {this.state.roleAccess.length > 0 &&
                  jimObj &&
                  jimObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.pubJournalItemMaster
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "pubJournalItemMaster")
                      }
                    >
                      <ListItemText
                        primary="Journal Item Master"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {/* comments.................................. */}
                {this.state.roleAccess.length > 0 &&
                  preAlertObj &&
                  preAlertObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.pubPreAlert
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "pubPreAlert")}
                    >
                      <ListItemText
                        primary="Pre Alert"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  JSRObj &&
                  JSRObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.receivingReport
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "receivingReport")
                      }
                    >
                      <ListItemText
                        primary="Receiving"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  dataMappingObj &&
                  dataMappingObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.dataMapping
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) => this.handleSubListClick(e, "dataMapping")}
                    >
                      <ListItemText
                        primary="Data Mapping"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  insertMasterObj &&
                  insertMasterObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.pubInsertMaster
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "pubInsertMaster")
                      }
                    >
                      <ListItemText
                        primary="Insert Master"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  mlopObj &&
                  mlopObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.pubMainLabelOrderPlacement
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "pubMainLabelOrderPlacement")
                      }
                    >
                      <ListItemText
                        primary="Order Placement"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  mlbpObj &&
                  mlbpObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.pubMainLabelBatchPlacement
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "pubMainLabelBatchPlacement")
                      }
                    >
                      <ListItemText
                        primary="Batch Processing"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  eopObj &&
                  eopObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.pubEmloOrderPlacement
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "pubEmloOrderPlacement")
                      }
                    >
                      <ListItemText
                        primary="EMLO Order Placement"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {/* <ListItem
                  button
                  className={
                    this.state.selectSubList.pubEmloBatchProcessing
                      ? classes.selectedSubList
                      : classes.notSelectedSubList
                  }
                  onClick={(e) =>
                    this.handleSubListClick(e, "pubEmloBatchProcessing")
                  }
                >
                  <ListItemText
                    primary="EMLO Batch Processing"
                    classes={{ primary: classes.subListItemText }}
                  />
                </ListItem> */}

                {/* <ListItem
                  button
                  className={
                    this.state.selectSubList.pubDocketPosting
                      ? classes.selectedSubList
                      : classes.notSelectedSubList
                  }
                  onClick={(e) =>
                    this.handleSubListClick(e, "pubDocketPosting")
                  }
                >
                  <ListItemText
                    primary="Docket Posting"
                    classes={{ primary: classes.subListItemText }}
                  />
                </ListItem> */}
                {this.state.roleAccess.length > 0 &&
                  docketPostingObj &&
                  docketPostingObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.pubMainLabelDocketBagging
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "pubMainLabelDocketBagging")
                      }
                    >
                      <ListItemText
                        primary="Docket Posting/Bagging"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
              </List>
            </div>
          </Collapse>
          {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
          {publisherBackLabelManage.length > 0 &&
            publisherBackLabelManageFlag ? (
              <ListItem
                button
                onClick={(e) => this.handleClick(e, "publisherBackLabelOpen")}
                className={
                  this.state.openArr.publisherBackLabelOpen === true
                    ? classes.selectedList
                    : classes.notSelectedList
                }
              >
                <ListItemIcon style={{ minWidth: "auto", paddingRight: "10px" }}>
                  <ArrowLeftIcon
                    style={{
                      fontSize: "41px",
                      marginLeft: "-11px",
                      marginRight: "-7px",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Publisher Back Label "
                  classes={{ primary: classes.listItemText }}
                />
                {this.state.openArr.publisherBackLabelOpen ? (
                  <ExpandLess style={{ color: "rgb(57, 130, 234)" }} />
                ) : (
                    <ExpandMore style={{ color: "#6d7381" }} />
                  )}
              </ListItem>
            ) : null}

          <Collapse
            in={this.state.openArr.publisherBackLabelOpen}
            timeout="auto"
            unmountOnExit
          >
            <div style={{ display: "flex", marginTop: "4px" }}>
              <div
                style={{
                  borderRight: "1px solid rgb(199, 193, 193)",
                  height: "auto",
                  marginLeft: "2.9em",
                }}
              ></div>
              <List component="div" disablePadding>
                {this.state.roleAccess.length > 0 &&
                  blsiObj &&
                  blsiObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.backLabelStockIn
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "backLabelStockIn")
                      }
                    >
                      <ListItemText
                        primary="Stock In"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  blsoObj &&
                  blsoObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.backLabelStockOut
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "backLabelStockOut")
                      }
                    >
                      <ListItemText
                        primary="Stock Out"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {this.state.roleAccess.length > 0 &&
                  blstObj &&
                  blstObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.backLabelStockTransfer
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "backLabelStockTransfer")
                      }
                    >
                      <ListItemText
                        primary="Stock Transfer"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  blebloObj &&
                  blebloObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.pubEbloOrderPlacement
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "pubEbloOrderPlacement")
                      }
                    >
                      <ListItemText
                        primary="EBLO Order Placement"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  bpManageObj &&
                  bpManageObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.backLabelBatchProcessing
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "backLabelBatchProcessing")
                      }
                    >
                      <ListItemText
                        primary="Batch Processing"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {/* <ListItem
                  button
                  className={
                    this.state.selectSubList.backLabelBPAOrderProcessing
                      ? classes.selectedSubList
                      : classes.notSelectedSubList
                  }
                  onClick={(e) =>
                    this.handleSubListClick(e, "backLabelBPAOrderProcessing")
                  }
                >
                  <ListItemText
                    primary="BPA Order Processing"
                    classes={{ primary: classes.subListItemText }}
                  />
                </ListItem> */}
                {this.state.roleAccess.length > 0 &&
                  dpbManageObj &&
                  dpbManageObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.backLabelDocketPosting
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "backLabelDocketPosting")
                      }
                    >
                      <ListItemText
                        primary="Docket Posting/Bagging"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  bpcptManageObj &&
                  bpcptManageObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.backLabelBillingProcess
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "backLabelBillingProcess")
                      }
                    >
                      <ListItemText
                        primary="Billing Process CPT"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
              </List>
            </div>
          </Collapse>
          {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
          {BPAMarketManage.length > 0 && BPAMarketManageFlag ? (
            <ListItem
              button
              onClick={(e) => this.handleClick(e, "bpaMarketing")}
              className={
                this.state.openArr.bpaMarketing === true
                  ? classes.selectedList
                  : classes.notSelectedList
              }
            >
              <ListItemIcon style={{ minWidth: "auto", paddingRight: "10px" }}>
                <FlareIcon />
              </ListItemIcon>
              <ListItemText
                primary="BPA Marketing"
                classes={{ primary: classes.listItemText }}
              />
              {this.state.openArr.bpaMarketing ? (
                <ExpandLess style={{ color: "rgb(57, 130, 234)" }} />
              ) : (
                  <ExpandMore style={{ color: "#6d7381" }} />
                )}
            </ListItem>
          ) : null}

          <Collapse
            in={this.state.openArr.bpaMarketing}
            timeout="auto"
            unmountOnExit
          >
            <div style={{ display: "flex", marginTop: "4px" }}>
              <div
                style={{
                  borderRight: "1px solid rgb(199, 193, 193)",
                  height: "auto",
                  marginLeft: "2.9em",
                }}
              ></div>
              <List component="div" disablePadding>
                {this.state.roleAccess.length > 0 &&
                  BPAorderplaceManageObj &&
                  BPAorderplaceManageObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.bpaOrderPlacement
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "bpaOrderPlacement")
                      }
                    >
                      <ListItemText
                        primary="Order Placement"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  bpaRLObj &&
                  bpaRLObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.bpaRecipientList
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "bpaRecipientList")
                      }
                    >
                      <ListItemText
                        primary="Recipient List"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
              </List>
            </div>
          </Collapse>
          {publisherReports.length > 0 && publisherReportsFlag ? (
            <ListItem
              button
              onClick={(e) => this.handleClick(e, "publisherReportsOpen")}
              className={
                this.state.openArr.publisherReportsOpen === true
                  ? classes.selectedList
                  : classes.notSelectedList
              }
            >
              <ListItemIcon style={{ minWidth: "auto", paddingRight: "10px" }}>
                <ReportIcon />
              </ListItemIcon>
              <ListItemText
                primary="Publisher Reports"
                classes={{ primary: classes.listItemText }}
              />
              {this.state.openArr.publisherReportsOpen ? (
                <ExpandLess style={{ color: "rgb(57, 130, 234)" }} />
              ) : (
                  <ExpandMore style={{ color: "#6d7381" }} />
                )}
            </ListItem>
          ) : null}

          <Collapse
            in={this.state.openArr.publisherReportsOpen}
            timeout="auto"
            unmountOnExit
          >
            <div style={{ display: "flex", marginTop: "4px" }}>
              <div
                style={{
                  borderRight: "1px solid rgb(199, 193, 193)",
                  height: "auto",
                  marginLeft: "2.9em",
                }}
              ></div>
              <List component="div" disablePadding>
                {this.state.roleAccess.length > 0 &&
                  bplOversObj &&
                  bplOversObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.BPLOversReport
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "BPLOversReport")
                      }
                    >
                      <ListItemText
                        primary="BPL Overs"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  publisherMLVObj &&
                  publisherMLVObj.viewAction ?
                  <ListItem
                    button
                    className={
                      this.state.selectSubList.MailLodgementVolume
                        ? classes.selectedSubList
                        : classes.notSelectedSubList
                    }
                    onClick={(e) =>
                      this.handleSubListClick(e, "MailLodgementVolume")
                    }
                  >
                    <ListItemText
                      primary="Mail Lodgement Volume"
                      classes={{ primary: classes.subListItemText }}
                    />
                  </ListItem>
                  : null}
                {this.state.roleAccess.length > 0 &&
                  pubMLCPTWileyObj &&
                  pubMLCPTWileyObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.MLCPTWiley
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "MLCPTWiley")
                      }
                    >
                      <ListItemText
                        primary="ML CPT Wiley"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  pubMLCPTMacMillanObj &&
                  pubMLCPTMacMillanObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.MLCPTMacMillan
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "MLCPTMacMillan")
                      }
                    >
                      <ListItemText
                        primary="ML CPT MacMillan"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  pubMLCPTWSObj &&
                  pubMLCPTWSObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.MLCPTWS
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "MLCPTWS")
                      }
                    >
                      <ListItemText
                        primary="ML CPT WS"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  publisherJRRObj &&
                  publisherJRRObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.pubReportMLJRR
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "pubReportMLJRR")
                      }
                    >
                      <ListItemText
                        primary="ML Journal Received/JRR"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  publisherJDRObj &&
                  publisherJDRObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.pubReportMLJDR
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "pubReportMLJDR")
                      }
                    >
                      <ListItemText
                        primary="ML Journal Dispatch/JDR"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  publisherStockInObj &&
                  publisherStockInObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.PublisherStockIn
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "PublisherStockIn")
                      }
                    >
                      <ListItemText
                        primary="BL Stock In"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  publisherStockOutObj &&
                  publisherStockOutObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.PublisherStockOut
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "PublisherStockOut")
                      }
                    >
                      <ListItemText
                        primary="BL Stock Out"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}
                {this.state.roleAccess.length > 0 &&
                  publisherStockTransferObj &&
                  publisherStockTransferObj.viewAction ? (
                    <ListItem
                      button
                      className={
                        this.state.selectSubList.PublisherStockTransfer
                          ? classes.selectedSubList
                          : classes.notSelectedSubList
                      }
                      onClick={(e) =>
                        this.handleSubListClick(e, "PublisherStockTransfer")
                      }
                    >
                      <ListItemText
                        primary="BL Stock Transfer"
                        classes={{ primary: classes.subListItemText }}
                      />
                    </ListItem>
                  ) : null}

                {/* {this.state.roleAccess.length > 0 &&
                  dcrObj &&
                  dcrObj.viewAction ? ( */}
                {this.state.roleAccess.length > 0 &&
                  publisherSBObj &&
                  publisherSBObj.viewAction ?
                  <ListItem
                    button
                    className={
                      this.state.selectSubList.PublisherStockBalance
                        ? classes.selectedSubList
                        : classes.notSelectedSubList
                    }
                    onClick={(e) =>
                      this.handleSubListClick(e, "PublisherStockBalance")
                    }
                  >
                    <ListItemText
                      primary="BL Stock Balance"
                      classes={{ primary: classes.subListItemText }}
                    />
                  </ListItem>
                  : null}

                {/* {this.state.roleAccess.length > 0 &&
                  dcrObj &&
                  dcrObj.viewAction ? ( */}
                {this.state.roleAccess.length > 0 &&
                  publisherSMObj &&
                  publisherSMObj.viewAction ?
                  <ListItem
                    button
                    className={
                      this.state.selectSubList.PublisherStockMovement
                        ? classes.selectedSubList
                        : classes.notSelectedSubList
                    }
                    onClick={(e) =>
                      this.handleSubListClick(e, "PublisherStockMovement")
                    }
                  >
                    <ListItemText
                      primary="BL Stock Movement"
                      classes={{ primary: classes.subListItemText }}
                    />
                  </ListItem>
                  : null}
                {this.state.roleAccess.length > 0 &&
                  publisherBLCVObj &&
                  publisherBLCVObj.viewAction ?
                  <ListItem
                    button
                    className={
                      this.state.selectSubList.BLCPTVolumeReport
                        ? classes.selectedSubList
                        : classes.notSelectedSubList
                    }
                    onClick={(e) =>
                      this.handleSubListClick(e, "BLCPTVolumeReport")
                    }
                  >
                    <ListItemText
                      primary="BL CPT Volume"
                      classes={{ primary: classes.subListItemText }}
                    />
                  </ListItem>
                  : null}
                {this.state.roleAccess.length > 0 &&
                  pubBLCPTSumObj &&
                  pubBLCPTSumObj.viewAction ?
                  <ListItem
                    button
                    className={
                      this.state.selectSubList.BLCPTSummaryReport
                        ? classes.selectedSubList
                        : classes.notSelectedSubList
                    }
                    onClick={(e) =>
                      this.handleSubListClick(e, "BLCPTSummaryReport")
                    }
                  >
                    <ListItemText
                      primary="BL CPT Summary"
                      classes={{ primary: classes.subListItemText }}
                    />
                  </ListItem>
                  : null}
                {this.state.roleAccess.length > 0 &&
                  pubBLOrdCnclObj &&
                  pubBLOrdCnclObj.viewAction ?
                  <ListItem
                    button
                    className={
                      this.state.selectSubList.BLOrderCancellationReport
                        ? classes.selectedSubList
                        : classes.notSelectedSubList
                    }
                    onClick={(e) =>
                      this.handleSubListClick(e, "BLOrderCancellationReport")
                    }
                  >
                    <ListItemText
                      primary="BL Order Cancellation"
                      classes={{ primary: classes.subListItemText }}
                    />
                  </ListItem>
                  : null}
                {this.state.roleAccess.length > 0 &&
                  pubBLDCObj &&
                  pubBLDCObj.viewAction ?
                  <ListItem
                    button
                    className={
                      this.state.selectSubList.BLDetailedCostingReport
                        ? classes.selectedSubList
                        : classes.notSelectedSubList
                    }
                    onClick={(e) =>
                      this.handleSubListClick(e, "BLDetailedCostingReport")
                    }
                  >
                    <ListItemText
                      primary="BL Detailed Costing"
                      classes={{ primary: classes.subListItemText }}
                    />
                  </ListItem> : null}
                {this.state.roleAccess.length > 0 &&
                  pubEMLOCPTObj &&
                  pubEMLOCPTObj.viewAction ?
                  <ListItem
                    button
                    className={
                      this.state.selectSubList.PublisherEmloCpt
                        ? classes.selectedSubList
                        : classes.notSelectedSubList
                    }
                    onClick={(e) =>
                      this.handleSubListClick(e, "PublisherEmloCpt")
                    }
                  >
                    <ListItemText
                      primary="EMLO CPT"
                      classes={{ primary: classes.subListItemText }}
                    />
                  </ListItem>
                  : null}
                {/* {this.state.roleAccess.length > 0 &&
                  pubEMLOSummeryObj &&
                  pubEMLOSummeryObj.viewAction ? <ListItem
                    button
                    className={
                      this.state.selectSubList.PublisherEMLOSummery
                        ? classes.selectedSubList
                        : classes.notSelectedSubList
                    }
                    onClick={(e) =>
                      this.handleSubListClick(e, "PublisherEMLOSummery")
                    }
                  >
                    <ListItemText
                      primary="EMLO Summery"
                      classes={{ primary: classes.subListItemText }}
                    />
                  </ListItem> : null} */}
                {/* {this.state.roleAccess.length > 0 &&
                  pubEBLOCPTObj &&
                  pubEBLOCPTObj.viewAction ?
                  <ListItem
                    button
                    className={
                      this.state.selectSubList.PublisherEbloCpt
                        ? classes.selectedSubList
                        : classes.notSelectedSubList
                    }
                    onClick={(e) =>
                      this.handleSubListClick(e, "PublisherEbloCpt")
                    }
                  >
                    <ListItemText
                      primary="EBLO CPT"
                      classes={{ primary: classes.subListItemText }}
                    />
                  </ListItem> : null} */}
                {/* {this.state.roleAccess.length > 0 &&
                  pubBLCPTGObj &&
                  pubBLCPTGObj.viewAction ?
                  <ListItem
                    button
                    className={
                      this.state.selectSubList.PublisherBLCPTG
                        ? classes.selectedSubList
                        : classes.notSelectedSubList
                    }
                    onClick={(e) =>
                      this.handleSubListClick(e, "PublisherBLCPTG")
                    }
                  >
                    <ListItemText
                      primary="BL CPT G3"
                      classes={{ primary: classes.subListItemText }}
                    />
                  </ListItem>
                  : null} */}
              </List>
            </div>
          </Collapse>
        </List>
        {/* <List aria-labelledby="nested-list-subheader">
        <ListItem
          button
          // selected={this.state.adminSettingOpen ? true : false}
          onClick={e => this.handleClick(e, "adminSettingOpen")}
          className={
            this.state.openArr.adminSettingOpen === true
              ? classes.selectedList
              : classes.notSelectedList
          }
        >
          <ListItemIcon style={{minWidth:"auto",paddingRight:"10px"}}>
            <AdminIcon />
          </ListItemIcon>
          <ListItemText
            primary="Admin Setting"
            classes={{ primary: classes.listItemText }}
          />
          {this.state.openArr.adminSettingOpen ? (
            <ExpandLess style={{ color: "rgb(57, 130, 234)" }} />
          ) : (
            <ExpandMore style={{ color: "#6d7381" }} />
          )}
        </ListItem>
        <Collapse
          in={this.state.openArr.adminSettingOpen}
          timeout="auto"
          unmountOnExit
        >
          <div style={{ display: "flex", marginTop: "4px" }}>
            <div
              style={{
                borderRight: "1px solid rgb(199, 193, 193)",
                height: "auto",
                marginLeft: "2.9em"
              }}
            ></div>
            <List component="div" disablePadding>
              <ListItem
                button
                className={
                  this.state.selectSubList.superMenu
                    ? classes.selectedSubList
                    : classes.notSelectedSubList
                }
                onClick={e => this.handleSubListClick(e, "superMenu")}
              >
                <ListItemText
                  primary="Super Menu"
                  classes={{ primary: classes.subListItemText }}
                />
              </ListItem>
              </List>
              </div>
              </Collapse>
      </List> */}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(PrimaryList));
