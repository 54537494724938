// import React, { Component } from "react";

// class PublisherStockBalance extends Component {
//   state = {};
//   componentDidMount() {
//     this.props.handleSettingHeader("Back Label Stock Balance");
//     this.props.setSelectedList("publisherReportsOpen", "PublisherStockBalance");
//   }
//   render() {
//     return (
//       <div>
//         <iframe
//           style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
//           src={`${window.localStorage.ReportURLByID}` + "PublisherStockBalance"}
//         />
//       </div>
//     );
//   }
// }

// export default PublisherStockBalance;

import React, { Component } from "react";
import { Autocomplete } from "@material-ui/lab";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import toastr from "toastr"
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  getStockInByCustIdList,
  getCustomerFromItemMaster,
} from "../../generic/genericActions";

import {
  getAllAcronymList,
  getAllVolumeList,
  getAllIssueList,
  getAllSuppList,

} from "../../publisher/action"
import "../../app/App.css"
import LinearProgress from "@material-ui/core/LinearProgress";

import { getStockTypeDD, getWarehouseLocationStockBalance, getWarehouseSuffixStockBalance } from "../../publisherBackLabel/stockaction"
const BackgroundHead = {
  backgroundImage: 'url(' + LinearProgress + ')'
}
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  comboBox: {
    //marginTop: theme.spacing(1),
    //marginRight: theme.spacing(1),
    margin: "normal",
    width: "100%",
    fontSize: "11px !important",
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
    display: "flex",

    flex: 1,
    alignItems: "left",
    overflow: "hidden",
  },
  textField1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
    fontSize: "11px !important",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
});
class StockBalance extends Component {
  state = {
    warehouseSuffix: "",
    showPopup: false,
    fromDate: null,
    toDate: null,
    date: new Date(),
    stockIn: {
      stockID: "",
      stock: "",
      customerID: "",
      customer: "",
    },
    accList: [],
    accObj: {},
    accId: "",
    volList: [],
    volObj: {},
    volId: "",
    issueList: [],
    issueObj: {},
    issueId: "",
    suppList: [],
    suppObj: {},
    suppId: "",
    stockTypeList: [],
    stockTypeObj: {},
    stockTypeId: "",
    warehouseLocationList: [],
    warehouseLocationObj: {},
    warehouseLocationId: "",
    publicationYearFrom: null,
    publicationYearTo: null,
    calledFirstTime: false,
    warehouseSuffixId: "",
    warehouseSuffixList: [],
    warehouseSuffixObj: {}
  };
  componentDidMount() {
    this.props.handleSettingHeader("Back Label Stock Balance");
    this.props.setSelectedList("publisherReportsOpen", "PublisherStockBalance");
    // getCustomerFromItemMaster().then((r) => {
    //   this.getAllCustomers(r.data);
    // });
    this.getAllApiLists()
    // this.setSortedList("accList", [{ id: "ALL", text: "Bll", value: "Bll" }, { id: "ALL", text: "Tll", value: "Tll" }, { id: "ALL", text: "Dll", value: "Dll" }])

  }
  getAllApiLists = () => {
    getStockTypeDD().then((r) => {
      this.getStockyStypeDD(r.data);
    });
    getAllAcronymList().then(r => {
      this.setState({ accObj: { id: "ALL", text: "ALL", value: "ALL" }, accId: "ALL" })
      if (r && r.data)
        this.setSortedList("accList", r.data)
      else
        this.setSortedList("accList", [])
    })
    getAllVolumeList("ALL",).then(r => {
      this.setState({ volObj: { id: "ALL", text: "ALL", value: "ALL" }, volId: "ALL" })
      if (r && r.data)
        this.setSortedList("volList", r.data)
      else
        this.setSortedList("volList", [])
    })
    getAllIssueList("ALL", "ALL").then(r => {
      this.setState({ issueObj: { id: "ALL", text: "ALL", value: "ALL" }, issueId: "ALL" })
      if (r && r.data)
        this.setSortedList("issueList", r.data)
      else
        this.setSortedList("issueList", [])
    })
    getAllSuppList("ALL", "ALL").then(r => {
      this.setState({ suppObj: { id: "ALL", text: "ALL", value: "ALL" }, suppId: "ALL" })
      if (r && r.data)
        this.setSortedList("suppList", r.data)
      else
        this.setSortedList("suppList", [])
    })
    getWarehouseSuffixStockBalance().then(r => {
      if (r && r.data) {
        this.setSortedList("warehouseSuffixList", [{ id: "ALL", text: "ALL", value: "ALL" }, ...r.data])
        this.setState({ warehouseSuffixObj: { id: "ALL", text: "ALL", value: "ALL" }, warehouseSuffixId: "ALL" })
        this.setSortedList("warehouseLocationList", [{ id: 0, text: "ALL", value: "ALL" }])
        this.setState({ warehouseLocationId: 0, warehouseLocationObj: { id: 0, text: "ALL", value: "ALL" } })
      }
      else
        this.setSortedList("warehouseSuffixList", [])
    })
    this.setSortedList("warehouseLocationList", [])
    this.setState({ publicationYearFrom: null, publicationYearTo: null })
    // this.setState({ warehouseSuffixObj: {}, warehouseSuffixId: "", publicationYearTo: null })
  }
  shouldComponentUpdate() {
    if (!this.state.calledFirstTime) {
      if (this.state.date &&
        this.state.accId &&
        this.state.accObj.text &&
        this.state.volId &&
        this.state.volObj.text &&
        this.state.stockTypeId &&
        this.state.stockTypeObj.id &&
        this.state.issueObj.text || this.state.suppObj.text &&
        this.state.issueId || this.state.suppId)
        this.setState({ calledFirstTime: true }, () => {
          setTimeout(() => {
            this.handleSubmit()
          }, 100)
        })

    }

    return true
  }
  getStockyStypeDD = async (data) => {
    await this.setState({
      stockTypeList:
        data &&
        data.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    }, () => {
      if (this.state.stockTypeList.length > 0)
        this.setState({ stockTypeObj: this.state.stockTypeList[0], stockTypeId: this.state.stockTypeList[0].id })
    });
  };
  getAllCustomers = (list) => {
    let customerList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        customerList.findIndex((p) => p.id === c.id) > -1
          ? customerList
          : customerList.push(c)
      );

    this.setState({
      customerList:
        customerList &&
        customerList.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };
  setSortedList = (name, list) => {
    // let defaultList = [{ id: "ALL", text: "ALL", value: "ALL" }]
    let sortedList = []
    // if (list && list.length > 0)
    //   sortedList = list.sort((a, b) =>
    //     a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
    //   )
    this.setState({ [name]: list })
    // this.setState({ [name]: [...defaultList, ...sortedList] })
  }
  handleDateChange = (date) => {
    this.setState({ ["date"]: date });
  };
  handleDateChange1 = (date) => {
    this.setState({ ["toDate"]: date });
  };
  handleStockChange = (event, values) => {
    const { stockIn } = this.state;
    let stockID = values && values.stockID;
    stockIn["stock"] = values;
    stockIn["stockID"] = stockID;
    //this.validateField("stockID", stockID);
    this.setState({ stockIn });
  };
  handleWLChange = (e, values) => {
    if (values)
      this.setState({ warehouseLocationObj: values, warehouseLocationId: values.id })
    else
      this.setState({ warehouseLocationObj: {}, warehouseLocationId: "" })

  }
  handleWSChange = (e, values) => {
    this.setState({ warehouseLocationId: 0, warehouseLocationObj: { id: 0, text: "ALL", value: "ALL" } })
    if (values) {
      getWarehouseLocationStockBalance(values.text).then(r => {
        if (r && r.data)
          this.setSortedList("warehouseLocationList", [{ id: 0, text: "ALL", value: "ALL" }, ...r.data])
        else
          this.setSortedList("warehouseLocationList", [{ id: 0, text: "ALL", value: "ALL" }])
      })
      this.setState({ warehouseSuffixObj: values, warehouseSuffixId: values.text })
    }
    else {
      this.setSortedList("warehouseLocationList", [{ id: 0, text: "ALL", value: "ALL" }])
      // this.setState({ warehouseLocationId: "", warehouseLocationObj: {} })
      this.setState({ warehouseSuffixObj: {}, warehouseSuffixId: "" })
    }

  }
  handleCustomerChange = (event, values) => {
    const { stockIn, ItemMasterList } = this.state;

    let customerID = values && values.id;
    stockIn["customer"] = values;
    stockIn["customerID"] = customerID;
    //this.validateField("customerID", customerID);
    getStockInByCustIdList(customerID).then((r) => {
      this.setState({
        stockList:
          r.data &&
          r.data.sort((a, b) =>
            a.stockCode.toLowerCase() > b.stockCode.toLowerCase() ? 1 : -1
          ),
      });
    });
    stockIn["stock"] = null;
    stockIn["stockID"] = 0;
    //this.validateField("stockID", null);
    this.setState({ stockIn, ItemMasterList });
  };
  handleAccChange = (event, values) => {
    if (values) {
      this.setState({ accObj: values, accId: values.text })
      getAllVolumeList(values.text).then(r => {
        let allFound = false
        if (r && r.data) {
          this.setState({ volObj: { id: "ALL", text: "ALL", value: "ALL" }, volId: "ALL" })
          if (r.data.length > 0) {
            r.data.map(xy => {
              if (xy.text === "ALL") {
                allFound = true
              }
            })
            if (allFound)
              this.setSortedList("volList", r.data)
            else
              this.setSortedList("volList", [{ id: "ALL", text: "ALL", value: "ALL" }, ...r.data])
          }
          else
            this.setSortedList("volList", { id: "ALL", text: "ALL", value: "ALL" })
        }
        else
          this.setSortedList("volList", [{ id: "ALL", text: "ALL", value: "ALL" }])
      })
      getAllIssueList(values.text, "ALL").then(r => {
        let allFound = false
        if (r && r.data) {
          if (r.data.length > 0) {
            r.data.map(xy => {
              if (xy.text === "ALL") {
                allFound = true
              }
            })
            if (allFound)
              this.setSortedList("issueList", r.data)
            else
              this.setSortedList("issueList", [{ id: "ALL", text: "ALL", value: "ALL" }, ...r.data])
          }
          else
            this.setSortedList("issueList", { id: "ALL", text: "ALL", value: "ALL" })
        }
        else
          this.setSortedList("issueList", [{ id: "ALL", text: "ALL", value: "ALL" }])
        this.setState({ issueObj: { id: "ALL", text: "ALL", value: "ALL" }, issueId: "ALL" })

      })
      getAllSuppList(values.text, "ALL").then(r => {
        let allFound = false
        if (r && r.data) {
          if (r.data.length > 0) {
            r.data.map(xy => {
              if (xy.text === "ALL") {
                allFound = true
              }
            })
            if (allFound)
              this.setSortedList("suppList", r.data)
            else
              this.setSortedList("suppList", [{ id: "ALL", text: "ALL", value: "ALL" }, ...r.data])
          }
          else
            this.setSortedList("suppList", [{ id: "ALL", text: "ALL", value: "ALL" }])
        }
        // if (r && r.data)
        //   this.setSortedList("suppList", r.data)
        else
          this.setSortedList("suppList", [{ id: "ALL", text: "ALL", value: "ALL" }])
        this.setState({ suppObj: { id: "ALL", text: "ALL", value: "ALL" }, suppId: "ALL" })

      })
      // if (values.text && values.text.toLowerCase() !== "all") {
      //   this.setState({
      //     volObj: {},
      //     volId: "",
      //     issueObj: {},
      //     issueId: "",
      //     suppObj: {},
      //     suppId: ""
      //   })
      // }
    }
    else
      this.setState({ accObj: { id: "ALL", text: "ALL", value: "ALL" }, accId: "ALL" })
  };
  handleVolChange = (event, values) => {
    if (values) {
      this.setState({ volObj: values, volId: values.text })
      getAllIssueList(this.state.accObj.text || "ALL", values.text).then(r => {
        let allFound = false
        if (r && r.data) {
          if (r.data.length > 0) {
            r.data.map(xy => {
              if (xy.text === "ALL") {
                allFound = true
              }
            })
            if (allFound)
              this.setSortedList("issueList", r.data)
            else
              this.setSortedList("issueList", [{ id: "ALL", text: "ALL", value: "ALL" }, ...r.data])
          }
          else
            this.setSortedList("issueList", [{ id: "ALL", text: "ALL", value: "ALL" }])
        }
        else
          this.setSortedList("issueList", [{ id: "ALL", text: "ALL", value: "ALL" }])
        this.setState({ issueObj: { id: "ALL", text: "ALL", value: "ALL" }, issueId: "ALL" })
      })
      getAllSuppList(this.state.accObj.text || "ALL", values.text).then(r => {
        let allFound = false
        if (r && r.data) {
          if (r.data.length > 0) {
            r.data.map(xy => {
              if (xy.text === "ALL") {
                allFound = true
              }
            })
            if (allFound)
              this.setSortedList("suppList", r.data)
            else
              this.setSortedList("suppList", [{ id: "ALL", text: "ALL", value: "ALL" }, ...r.data])
          }
          else
            this.setSortedList("suppList", [{ id: "ALL", text: "ALL", value: "ALL" }])
        }
        // if (r && r.data)
        //   this.setSortedList("suppList", r.data)
        else
          this.setSortedList("suppList", [{ id: "ALL", text: "ALL", value: "ALL" }])
        this.setState({ suppObj: { id: "ALL", text: "ALL", value: "ALL" }, suppId: "ALL" })
      })

    }
    else
      this.setState({ volObj: { id: "ALL", text: "ALL", value: "ALL" }, volId: "ALL" })
  };
  handleIssueChange = (event, values) => {
    if (values)
      this.setState({ issueObj: values, issueId: values.text })
    else
      this.setState({ issueObj: {}, issueId: "" })
  };
  handleSuppChange = (event, values) => {
    if (values)
      this.setState({ suppObj: values, suppId: values.text })
    else
      this.setState({ suppObj: {}, suppId: "" })
  };
  handleStockTypeChange = (event, values) => {
    if (values)
      this.setState({ stockTypeObj: values, stockTypeId: values.id })
    else
      this.setState({ stockTypeObj: {}, stockTypeId: "" })
  };

  handleSubmit = () => {
    this.setState({ showPopup: true });
  };
  handleHidePopup = () => {
    this.setState({
      showPopup: false,
    });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })

  }
  handleYearChange = (date, name) => {
    if (name === "publicationYearFrom") {
      this.setState({ publicationYearTo: null });
      if (date)
        if (date.getFullYear() <= 2050 && date.getFullYear() >= 2010) {
          this.setState({ [name]: date });
        }
        else {
          this.setState({ [name]: "" });
        }
      else
        this.setState({ [name]: "" });

    }
    else {
      if (date && this.state.publicationYearFrom) {
        if (this.state.publicationYearFrom.getFullYear() > date.getFullYear()) {
          this.setState({ [name]: "" });
          toastr.error("Publication To Year can not be less than Publication From Year !")
          return
        }
        else {
          if (date) {
            if (date.getFullYear() <= 2050 && date.getFullYear() >= 2010) {
              this.setState({ [name]: date });
            }
            else {
              this.setState({ [name]: "" });
            }
          }
          else {
            this.setState({ [name]: "" });

          }
        }
      }
    }
  };
  render() {
    let { classes } = this.props;
    let para1 = this.state.date ? moment(this.state.date).format("MM/DD/YYYY") : ""
    let para2 = this.state.accObj.text ? encodeURIComponent(this.state.accObj.text) : "ALL"
    let para3 = this.state.volObj.text ? encodeURIComponent(this.state.volObj.text) : "ALL"
    let para4 = this.state.issueObj.text ? encodeURIComponent(this.state.issueObj.text) : "ALL"
    let para5 = this.state.suppObj.text ? encodeURIComponent(this.state.suppObj.text) : "ALL"
    let para6 = this.state.warehouseLocationId ? this.state.warehouseLocationId : 0
    let para7 = this.state.publicationYearFrom ? this.state.publicationYearFrom.getFullYear() : 0
    let para8 = this.state.publicationYearTo ? this.state.publicationYearTo.getFullYear() : 0
    let para9 = this.state.warehouseSuffixObj.text ? this.state.warehouseSuffixObj.text : "ALL"

    return (
      <Paper>
        <div style={{
          // border: "1px solid grey",
          margin: "0",
          padding: "10px 15px",
          // background: "#ECE9D8",
          display: "flex"
        }}>
          <div style={{ width: "75%", marginLeft: ".5em" }}>
            <div style={{ display: "flex", width: "100%", marginTop: ".2em" }}>
              <Autocomplete
                autoComplete
                disableClearable
                className={classes.comboBox}
                id="acc"

                includeInputInList
                options={this.state.accList}
                style={{ marginTop: 0, flex: 1, marginRight: "15px" }}
                value={this.state.accObj}
                onChange={this.handleAccChange}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Acronym"
                    name="acc"
                    fullWidth
                    required
                  />
                )}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  margin="normal"
                  disableToolbar
                  variant="inline"
                  required
                  id="date-picker-dialog"
                  label="Select Date"
                  format="dd/MM/yyyy"
                  style={{ flex: 1, marginTop: 0, visibility: "hidden" }}
                  value={this.state.date}
                  // onChange={this.handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />


                {/* <KeyboardDatePicker
                  autoOk
                  disabled={!this.state.fromDate}
                  disableToolbar
                  required
                  margin="normal"
                  disableToolbar
                  variant="inline"
                  required
                  id="date-picker-dialog"
                  label="To Date"
                  minDate={this.state.fromDate}
                  format="dd/MM/yyyy"
                  style={{ flex: 1, marginTop: 0 }}
                  value={this.state.toDate}
                  onChange={this.handleDateChange1}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                /> */}
              </MuiPickersUtilsProvider>
            </div>
            {/* <div style={{ display: "flex", width: "100%", marginTop: ".2em" }}>
              <Autocomplete
                autoComplete
                disableClearable
                className={classes.comboBox}
                id="customerID"
                includeInputInList
                options={this.state.customerList}
                style={{ marginTop: 0, marginRight: "15px" }}
                Value={this.state.customer}
                onChange={this.handleCustomerChange}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer"
                    name="customerID"
                    fullWidth
                  />
                )}
              />
              <Autocomplete
                autoComplete
                disableClearable
                className={classes.comboBox}
                id="stock"
                style={{ marginTop: 0 }}
                includeInputInList
                options={this.state.stockList}
                value={this.state.stockIn.stock}
                onChange={this.handleStockChange}
                getOptionLabel={(option) => option.stockCode}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Stock"
                    name="stock"
                    fullWidth
                  />
                )}
              />
            </div> */}
            <div style={{ display: "flex", width: "100%", marginTop: ".2em" }}>

              <Autocomplete
                autoComplete
                disableClearable
                className={classes.comboBox}
                id="vol"

                style={{ marginTop: 0, marginRight: "15px" }}
                includeInputInList
                options={this.state.volList}
                value={this.state.volObj}
                onChange={this.handleVolChange}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Volume"
                    name="vol"
                    fullWidth
                  // required
                  />
                )}
              />
              <Autocomplete
                autoComplete
                disableClearable
                className={classes.comboBox}
                id="issue"
                includeInputInList
                options={this.state.issueList}
                style={{ marginTop: 0, }}
                value={this.state.issueObj}
                onChange={this.handleIssueChange}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Issue"
                    name="issue"
                    fullWidth
                  // required={this.state.suppId ? false : true}

                  />
                )}
              />
            </div>
            <div style={{ display: "flex", width: "100%", marginTop: ".2em" }}>

              <Autocomplete
                autoComplete
                disableClearable
                className={classes.comboBox}
                id="supp"
                style={{ marginTop: 0, marginRight: "15px", flex: 1 }}
                includeInputInList
                options={this.state.suppList}
                value={this.state.suppObj}
                onChange={this.handleSuppChange}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Supp"
                    name="supp"
                    fullWidth
                  // required={this.state.issueId ? false : true}

                  />
                )}
              />

              <Autocomplete
                autoComplete
                disableClearable
                className={classes.comboBox}
                id="stockType"
                includeInputInList
                options={this.state.stockTypeList}
                style={{ marginTop: 0, flex: 1 }}
                value={this.state.stockTypeObj}
                onChange={this.handleStockTypeChange}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Stock Type"
                    name="stockType"
                    fullWidth
                    required

                  />
                )}
              />
            </div>
            <div style={{ display: "flex", width: "100%", marginTop: ".2em", }}>
              {/* <TextField
                label="Warehouse Suffix"
                name="warehouseSuffix"
                style={{ marginTop: 0, marginRight: "15px", flex: 1 }}
                fullWidth
                required
                onChange={this.handleChange}
                value={this.state.warehouseSuffix}
                inputProps={{
                  maxLength: 2
                }}
              /> */}
              <Autocomplete
                autoComplete
                className={classes.comboBox}
                disableClearable
                id="wl"
                style={{ marginTop: 0, flex: 1, marginRight: "15px", }}
                includeInputInList
                options={this.state.warehouseSuffixList}
                value={this.state.warehouseSuffixObj}
                onChange={this.handleWSChange}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Warehouse Suffix"
                    name="warehouseSuffix"
                    fullWidth
                  />
                )}
              />
              <Autocomplete
                autoComplete
                className={classes.comboBox}
                disableClearable
                id="wl"
                style={{ marginTop: 0, flex: 1 }}
                includeInputInList
                options={this.state.warehouseLocationList}
                value={this.state.warehouseLocationObj}
                onChange={this.handleWLChange}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Warehouse Location"
                    name="warehouseLocation"
                    fullWidth
                  />
                )}
              />

            </div>
            <div style={{ display: "flex", width: "100%", marginTop: ".2em", marginBottom: "2em" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  //disablePast
                  views={["year"]}
                  disableToolbar
                  variant="inline"
                  format="yyyy"
                  margin="normal"
                  name="publicationYearFrom"
                  minDate={new Date("01-01-2010")}
                  maxDate={new Date("01-01-2050")}
                  error={false}
                  // minDateMessage={""}
                  style={{ flex: 1, marginRight: "15px" }}
                  id="pickupDate"
                  label="Publication Year From"
                  value={this.state.publicationYearFrom}
                  onChange={(e) =>
                    this.handleYearChange(e, "publicationYearFrom")
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  invalidDateMessage="Invalid Year ! Please select from 2010 to 2050"
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  //disablePast
                  views={["year"]}
                  disableToolbar
                  variant="inline"
                  format="yyyy"
                  disabled={!this.state.publicationYearFrom}
                  margin="normal"
                  name="publicationYearTo"
                  minDate={new Date("01-01-2010")}
                  maxDate={new Date("01-01-2050")}
                  required={this.state.publicationYearFrom}
                  error={false}
                  // minDateMessage={""}
                  style={{ flex: 1 }}
                  id="pickupDate"
                  label="Publication Year To"
                  value={this.state.publicationYearTo}
                  onChange={(e) =>
                    this.handleYearChange(e, "publicationYearTo")
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  invalidDateMessage="Invalid Year ! Please select from 2010 to 2050"
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div style={{
            width: "23%",
            marginLeft: "2%",
            padding: "15px",
            borderLeft: "1px solid #ccc"
          }}>
            <Button
              // disabled={
              //   (this.state.fromDate ?
              //     !this.state.toDate : true)
              // }
              disabled={this.state.publicationYearFrom ?
                (!this.state.date ||
                  // !this.state.accId ||
                  // !this.state.volId ||
                  !this.state.stockTypeId ||
                  !this.state.publicationYearTo
                  // ||
                  // !this.state.issueId && !this.state.suppId
                ) :
                (!this.state.date ||
                  // !this.state.accId ||
                  // !this.state.volId ||
                  !this.state.stockTypeId
                  // ||
                  // !this.state.issueId && !this.state.suppId
                )
              }
              variant="contained"
              color="primary"
              style={{ boxShadow: "none", marginLeft: "1em" }}
              onClick={this.handleSubmit}
            >
              View Report
            </Button>
            <Button

              variant="contained"
              color="primary"
              style={{ boxShadow: "none", margin: "1em" }}
              onClick={this.getAllApiLists}
            >
              Reset
            </Button>
          </div>

        </div>
        <div>
          {this.state.showPopup && (
            <iframe
              className="iframe-ClassName"
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    width: "calc(100vw - 8em)",
                    height: "calc(100vh - 281px)",
                    borderWidth: "1px 0 0",
                    margin: "0 auto",

                  }
                  : {
                    overflow: "auto",
                    width: "calc(100vw - 335px)",
                    height: "calc(100vh - 281px)",
                    borderWidth: "1px 0 0",
                    margin: "0 auto",
                  }

              }
              src={
                `${window.localStorage.ReportURLByID}` +
                "PublisherStockBalance&IsDirectPrint=False&ReportParam=Date&Param1=" +
                `${para1}` + "&ReportParam=Acronym&Param2=" +
                `${para2}` + "&ReportParam=Volume&Param3=" +
                `${para3}` + "&ReportParam=Issue&Param4=" +
                `${para4}` + "&ReportParam=SuppNumber&Param5=" +
                `${para5}` + "&ReportParam=LocationID&Param6=" +
                `${para6}` + "&ReportParam=FromDate&Param7=" +
                `${para7}` + "&ReportParam=Todate&Param8=" +
                `${para8}` + "&ReportParam=WarehouseSuffix&Param9=" +
                `${para9}` + "&ReportParam=StockType&Param10=" +
                `${this.state.stockTypeObj.id}`
              }
            />
          )}
        </div>
      </Paper>
    );
  }
}

export default withRouter(withStyles(styles)(StockBalance));
