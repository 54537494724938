import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { Autocomplete } from "@material-ui/lab";
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { postCodeMaster, getCodeTypeList } from "../../masters/masterActions";
import "date-fns";
import { addStyles } from "../../../shared/styles";

const validateNotRequired100 = new Validator("maxlength100AllAllow");
const validateNotRequired500 = new Validator("maxlength100AllAllow");
const maxlength100AllAllowFwdSlash = new Validator("maxlength100AllAllowFwdSlash");
const onlyNumbers = new Validator("onlyNumbers");

class AddCodeMaster extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      codeMaster: {
        codeTypeName: "",
        code: "",
        codeValue: "",
        effectiveStartDate: null,
        remarks: "",
      },
      errors: {},
      codeValid: false,
      codeValueValid: false,
      codeTypeNameValid: false,
      remarksValid: true,
      effectiveStartDateValid: true,
      codeTypeList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Code Master");
    this.props.setSelectedList("masterDataOpen", "codeMaster");
    getCodeTypeList().then((r) => {
      // dispatch({
      //   type: "getCodeTypeList",
      //   newStore: r.data
      // });
      this.getAllCodeType(r.data);
    });
  }
  getAllCodeType = (list) => {
    let codeTypeList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        c.isActive && c.canAddNew
          ? codeTypeList.push({
            key: c.codeTypeID,
            value: c.codeType,
            active: c.isActive,
          })
          : codeTypeList
      );
    this.setState({
      codeTypeList: codeTypeList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      ),
    });
  };
  handleChange = (event) => {
    const { codeMaster } = this.state;
    let val = event.target.value;

    if (event.target.name === "code" && val)
      val = val.toUpperCase()
    codeMaster[event.target.name] = val
    this.setState({ codeMaster });

    this.validateField(event.target.name, val);
  };

  handleCodeTypeChange = (event, values) => {
    const { codeMaster } = this.state;
    if (values === null) {
      codeMaster["codeTypeName"] = "null";
    } else {
      let codeTypeNameID = values.key;
      codeMaster["codeTypeName"] = codeTypeNameID;
    }
    this.setState({ codeMaster });

    this.validateField("codeTypeName", codeMaster["codeTypeName"]);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let codeValid = this.state.codeValid;
    let codeValueValid = this.state.codeValueValid;
    let effectiveStartDateValid = this.state.effectiveStartDateValid;
    let codeTypeNameValid = this.state.codeTypeNameValid;
    let remarksValid = this.state.remarksValid;
    switch (fieldName) {
      case "code":
        if (value !== "") {
          codeValid = validateNotRequired100(value);
          fieldValidationErrors.code = codeValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          codeValid = false;
          fieldValidationErrors.code = "";
        }
        break;
      case "codeValue":
        if (value !== "") {
          codeValueValid = maxlength100AllAllowFwdSlash(value);
          fieldValidationErrors.codeValue = codeValueValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          codeValueValid = false;
          fieldValidationErrors.codeValue = "";
        }
        break;
      case "effectiveStartDate":
        if (value !== "") {
          effectiveStartDateValid = onlyNumbers(value);
          fieldValidationErrors.effectiveStartDate = effectiveStartDateValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          effectiveStartDateValid = false;
          fieldValidationErrors.effectiveStartDate = "";
        }
        break;
      case "remarks":
        if (value !== "") {
          remarksValid = validateNotRequired500(value);
          fieldValidationErrors.remarks = remarksValid
            ? ""
            : resourceValidation.validRemarks;
        } else {
          remarksValid = true;
          fieldValidationErrors.remarks = "";
        }
        break;
      case "codeTypeName":
        if (value !== "null") {
          codeTypeNameValid = validateNotRequired100(value);
          fieldValidationErrors.codeTypeName = codeTypeNameValid
            ? ""
            : " Please select Code Type";
        } else {
          codeTypeNameValid = false;
          fieldValidationErrors.codeTypeName = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        codeValid: codeValid,
        codeValueValid: codeValueValid,
        effectiveStartDateValid: effectiveStartDateValid,
        remarksValid: remarksValid,
        codeTypeNameValid: codeTypeNameValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.codeValid &&
        this.state.codeValueValid &&
        this.state.codeTypeNameValid &&
        this.state.remarksValid,
    });
    return this.state.formValid;
  }
  handleDateChange = (date) => {
    const { codeMaster } = this.state;
    codeMaster["effectiveStartDate"] = date;
    this.setState({ codeMaster });
  };
  handleClose = () => {
    // clean up state and errors
    this.setState({
      code: "",
      codeValue: "",
      effectiveStartDate: null,
      remarks: "",
      codeTypeName: "",
      errors: {},
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      const { codeMaster } = this.state;

      let code = codeMaster.code;
      let codeValue = codeMaster.codeValue;
      let effectiveStartDate =
        codeMaster.effectiveStartDate &&
        codeMaster.effectiveStartDate.toLocaleDateString();
      if (effectiveStartDate === null) {
        effectiveStartDate = "";
      }
      let remarks = codeMaster.remarks;
      let codeType = codeMaster.codeTypeName;
      if (codeType === "") codeType = 0;
      let createdBy = localStorage.loggedInId;
      let isActive = true;

      let reqBody = {
        codeType,
        code,
        codeValue,
        effectiveStartDate,
        remarks,
        isActive,
        createdBy,
      };
      postCodeMaster(reqBody).then((r) => {
        this.props.history.push("/CodeMaster");
      });
    }
  };
  render() {
    let { classes } = this.props;
    let { codeMaster, codeTypeList } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Code Master</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <TextField
                    required
                    label="Code"
                    name="code"
                    inputProps={{
                      maxLength: 10,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={codeMaster.code}
                    onChange={this.handleChange}
                    error={this.state.errors.code}
                    helperText={
                      this.state.errors.code ? this.state.errors.code : ""
                    }
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="codeTypeName"
                    name="codeTypeName"
                    hintText="code Type Name"
                    className={classes.comboBox}
                    options={codeTypeList}
                    onChange={this.handleCodeTypeChange}
                    getOptionLabel={(option) => option.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Code Type"
                        name="codeTypeName"
                        id="codeTypeName"
                        margin="normal"
                        required
                        style={{ width: "100%" }}
                        // className={classes.autocompleteText}
                        value={codeMaster.codeTypeName}
                        error={this.state.errors.codeTypeName}
                        helperText={
                          this.state.errors.codeTypeName
                            ? this.state.errors.codeTypeName
                            : ""
                        }
                      />
                    )}
                  />


                </div>
                <div>

                  <TextField
                    inputProps={{
                      maxLength: 2000,
                    }}
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    //required
                    //onKeyPress={this._onKeyPress}
                    required
                    label="Code Value"
                    name="codeValue"
                    margin="normal"
                    value={codeMaster.codeValue}
                    // style={{ width: "93%", marginLeft: "3px" }}

                    onChange={this.handleChange}
                    className={classes.textField}
                    error={this.state.errors.codeValue}
                    helperText={
                      this.state.errors.codeValue
                        ? this.state.errors.codeValue
                        : ""
                    }
                  />
                  <TextField
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    label="Remarks"
                    name="remarks"
                    inputProps={{
                      maxLength: 500,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={codeMaster.remarks}
                    onChange={this.handleChange}
                    error={this.state.errors.remarks}
                    helperText={
                      this.state.errors.remarks ? this.state.errors.remarks : ""
                    }
                  />
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/codeMaster")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddCodeMaster));
