import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import { Autocomplete } from "@material-ui/lab";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { addStyles } from "../../../shared/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import clone from "clone";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import moment from "moment";
import {
  SearchIcon,
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import {
  stableSort,
  getSorting,
  DialogActions,
} from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import types from "../masterTypes";
import { StateContext } from "../../../shared/globalState";
import {
  getJournalVersionList,
  getCountryList,
  deleteJournalVersion,
  getFliteredJournalVersionList,
} from "../masterActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ExportCSV } from "../../../shared/ExportCSV";

const columns = [
  {
    name: "Active",
    id: 9,
  },
  {
    name: "Modified Date",
    id: 10,
  },
  {
    name: "Modified By",
    id: 11,
  },
  {
    name: "Created Date",
    id: 12,
  },
  {
    name: "Created By",
    id: 13,
  },
];

function createData(
  journalVersionMatrixID,
  countryID,
  countryCode,
  countryName,
  isROW,
  isROE,
  isNordic,
  isEne,
  isAsean,
  priorityOrder,
  isActive,
  createdByName,
  createdDate,
  modifiedByName,
  modifiedDate
) {
  return {
    journalVersionMatrixID,
    countryID,
    countryCode,
    countryName,
    isROW,
    isROE,
    isNordic,
    isEne,
    isAsean,
    priorityOrder,
    isActive,
    createdByName,
    createdDate,
    modifiedByName,
    modifiedDate,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all journalVersion" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [formValid, setIsformValid] = useState(false);

  const [searchValue, setValues] = useState({
    country: "",
  });

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);

  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handleDeleteJournalVersion,
    searchPopupOpen,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchJournalVersion,
    searchClicked,
    hendleVewColums,
    countryList,
    exportDataValues,
    journalVersionList,
    selected,
    isActive,
  } = props;
  // const selectedID = selected && selected.length > 0 && selected[0];

  const selectedID = selected[0];
  const selectedJournalVersion = journalVersionList.find(
    (x) => x.journalVersionMatrixID === selectedID
  );
  let acnArr = props.roles.filter(
    (u) => u.menuName === "Journal Version Matrix"
  );
  let acnObj = acnArr[0];
  let expData = clone(journalVersionList);
  // console.log(expData)
  expData.map((d) => {
    let Val = "No";
    let isROWVal = "No";
    let isROEVal = "No";
    let isNordicVal = "No";
    let isEneVal = "No";
    let isAseanVal = "No";
    if (d["isActive"]) {
      Val = "Yes";
    }
    if (d["isROW"]) {
      isROWVal = "Yes";
    }
    if (d["isROE"]) {
      isROEVal = "Yes";
    }
    if (d["isNordic"]) {
      isNordicVal = "Yes";
    }
    if (d["isEne"]) {
      isEneVal = "Yes";
    }
    if (d["isAsean"]) {
      isAseanVal = "Yes";
    }

    d["Country Code"] = d["countryCode"];
    d["Country"] = d["countryName"];
    d["ROW"] = isROWVal;
    d["ROE	"] = isROEVal;
    d["NORDIC"] = isNordicVal;
    d["ENE"] = isEneVal;
    d["ASEAN"] = isAseanVal;
    d["Active"] = Val;
    d["Priority Order"] = d["priorityOrder"];
    d["Modified Date"] = d["modifiedDate"] ? moment(d["modifiedDate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    ) : ""
    d["Modified By"] = d["modifiedByName"];
    d["Created Date"] = d["createdDate"] ? moment(d["createdDate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    ) : ""
    d["Created By"] = d["createdByName"];


    delete d["priorityOrder"];

    delete d["countryCode"];

    delete d["countryName"];

    delete d["isROW"];

    delete d["isROE"];

    delete d["isNordic"];

    delete d["isEne"];

    delete d["isAsean"];

    delete d["modifiedDate"];

    delete d["isActive"];

    delete d["createdByName"];

    delete d["createdDate"];

    delete d["modifiedByName"];
    delete d["modifiedDate"];
    delete delete d["isActive"];

    delete d["createdByName"];

    delete d["createdDate"];

    delete d["modifiedByName"];

    delete d["modifiedDate"];
    delete d["journalVersionMatrixID"];
    delete d["countryID"];
  });
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteJournalVersion = () => {
    handleDeleteJournalVersion();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };
  const handleSearchCountryValuesChange = (event, values) => {
    event.persist();
    let name = event.target.name;
    setValues((searchValue) => ({
      ...searchValue,
      country: values,
    }));
    setIsformValid(true);
    switch (name) {
    }
  };
  const handleChangeCkBx = (e, name1) => {
    let val = e.target.checked
    let name = name1
    let newSv = searchValue
    newSv[name] = val
    // setValues((newSv) =>
    //   newSv
    // )
    setValues((searchValue) => ({
      ...searchValue,
      [name]: val,
    }));

  }
  const clearSearchValues = () => {
    let searchValues = searchValue;
    searchValues.country = "";
    searchValues.ASEAN = false
    searchValues.ENE = false
    searchValues.NORDIC = false
    searchValues.ROE = false
    searchValues.ROW = false
  };

  const searchPopup = () => {
    handleSearchJournalVersion(searchValue);
    clearSearchValues();
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
  };
  // console.log(searchValue, "jkk")
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_journalVersion/${selectedID}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <Link to={`/edit_journalVersion/${selectedID}`}>
                      <IconButton
                        aria-label="edit"
                        className={classes.iconHover}
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={handleClickOpen}
                  disabled={!isActive}
                >
                  <DeleteIcon className={classes.iconHover} />
                </IconButton>
              </Tooltip>
            )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete Journal Version Matrix
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1
                      ? " ?"
                      : selectedJournalVersion.countryCode + " ?"}
                    {/* {selectedJournalVersion.countryCode} */}
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteJournalVersion}
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  <form onSubmit={searchPopup}>
                    {acnObj && acnObj.advanceSearchAction && (
                      <Tooltip title="Search">
                        <IconButton
                          aria-label="Search"
                          className={classes.iconHover}
                          onClick={handleSearchOpen}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      onClose={handleSearchPopupClose}
                      aria-labelledby="customized-dialog-title"
                      open={searchPopupOpen}
                      maxWidth="md"
                    >
                      <DialogTitle
                        id="customized-dialog-title"
                        onClose={handleSearchPopupClose}
                      >
                        Advanced Search
                  </DialogTitle>
                      <DialogContent dividers>
                        <React.Fragment>
                          <div style={{ minWidth: "400px", display: "flex" }}>
                            {/* <TextField
                          id="standard-required"
                          label="Priority Order"
                          name="priorityOrder"
                          className={classes.textField}
                          margin="normal"
                          value={searchValue.priorityOrder}
                          inputProps={{
                            maxLength: 50
                          }}
                          autoComplete="off"
                          onChange={handleSearchInput}
                          error={errors.priorityOrder}
                          helperText={
                            errors.priorityOrder ? errors.priorityOrder : ""
                          }
                        /> */}
                            <Autocomplete
                              autoComplete
                              className={classes.comboBox}
                              id="country"
                              includeInputInList
                              value={searchValue.country}
                              options={countryList}
                              onChange={handleSearchCountryValuesChange}
                              //onChange={handleSearchInput}
                              getOptionLabel={(option) => option.countryName}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Country"
                                  className={classes.textField}
                                  name="country"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                          <div style={{ display: "flex" }}>
                            <FormControlLabel
                              className={classes.checkBox}
                              style={{ marginLeft: "-4px" }}
                              margin="normal"
                              name="isROW"
                              control={
                                <Checkbox
                                  checked={searchValue.isROW}
                                  onChange={e => handleChangeCkBx(e, "ROW")}
                                  value={searchValue.isROW}
                                  color="primary"
                                />
                              }
                              label="ROW"
                            />
                            <FormControlLabel
                              className={classes.checkBox}
                              style={{ marginLeft: "1em" }}
                              margin="normal"
                              name="isROE"
                              control={
                                <Checkbox
                                  checked={searchValue.isROE}
                                  onChange={e => handleChangeCkBx(e, "ROE")}
                                  value={searchValue.isROE}
                                  color="primary"
                                />
                              }
                              label="ROE"
                            />
                            <FormControlLabel
                              style={{ marginLeft: "1em" }}
                              className={classes.checkBox}
                              margin="normal"
                              name="isNordic"
                              control={
                                <Checkbox
                                  checked={searchValue.isNordic}
                                  onChange={e => handleChangeCkBx(e, "NORDIC")}
                                  value={searchValue.isNordic}
                                  color="primary"
                                />
                              }
                              label="NORDIC"
                            />
                            <FormControlLabel
                              className={classes.checkBox}
                              style={{ marginLeft: "1em" }}
                              margin="normal"
                              name="isEne"
                              control={
                                <Checkbox
                                  checked={searchValue.isEne}
                                  onChange={e => handleChangeCkBx(e, "ENE")}
                                  value={searchValue.isEne}
                                  color="primary"
                                />
                              }
                              label="ENE"
                            />

                            <FormControlLabel
                              className={classes.checkBox}
                              style={{ marginLeft: "1em" }}
                              margin="normal"
                              name="isAsean"
                              control={
                                <Checkbox
                                  checked={searchValue.isAsean}
                                  onChange={e => handleChangeCkBx(e, "ASEAN")}
                                  value={searchValue.isAsean}
                                  color="primary"
                                />
                              }
                              label="ASEAN"
                            />
                          </div>
                        </React.Fragment>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                          onClick={handleSearchClose}
                        >
                          CANCEL
                    </Button>
                        {
                          <Button
                            disabled={
                              !searchValue.country &&
                              !searchValue.ASEAN &&
                              !searchValue.ENE &&
                              !searchValue.NORDIC &&
                              !searchValue.ROE &&
                              !searchValue.ROW
                              // ? Object.keys(searchValue.country).length === 0
                              // : 1
                            }
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                            onClick={searchPopup}
                          >
                            SEARCH
                      </Button>
                        }
                      </DialogActions>
                    </Dialog>
                  </form>
                )}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="add"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/add_journalVersion")}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {acnObj && acnObj.exportAction && (
                <Tooltip title="Download">
                  <ExportCSV
                    csvData={expData}
                    fileName={"Journal_version"}
                  />
                </Tooltip>
              )}
              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                hendleVewColums={hendleVewColums}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class JournalMatrixMaster extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    journalVersionList: [],
    activeRows: 0,
    isActive: false,
    refreshData: false,
    selectedJournalVersion: "",
    selectedJournalVersionId: 1,
    loading: false,
    searchPopupOpen: false,
    cptSearchValues: {},
    countryList: [],
    searchClicked: false,
    selectedShowColumns: [],
    exportDataValues: [],
    headCells: [
      {
        id: 1,
        code: "countryCode",
        numeric: false,
        disablePadding: true,
        label: "Country code",
        show: true,
      },
      {
        id: 2,
        code: "countryName",
        numeric: false,
        disablePadding: true,
        label: "Country",
        show: true,
      },
      {
        id: 3,
        code: "isROW",
        numeric: false,
        disablePadding: false,
        label: "ROW",
        show: true,
      },
      {
        id: 4,
        code: "isROE",
        numeric: false,
        disablePadding: false,
        label: "ROE",
        show: true,
      },
      {
        id: 5,
        code: "isNordic",
        numeric: false,
        disablePadding: false,
        label: "NORDIC",
        show: true,
      },

      {
        id: 6,
        code: "ene",
        numeric: false,
        disablePadding: false,
        label: "ENE",
        show: true,
      },
      {
        id: 7,
        code: "asean",
        numeric: false,
        disablePadding: false,
        label: "ASEAN",
        show: true,
      },
      {
        id: 8,
        code: "priorityOrder",
        numeric: false,
        disablePadding: false,
        label: "Priority Order",
        show: true,
      },
      {
        id: 9,
        code: "isActive",
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true,
      },
      {
        id: 10,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 11,
        code: "modifiedByName",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 12,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 13,
        code: "createdByName",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };
  componentDidMount() {
    this.props.handleSettingHeader("Journal Version Matrix");
    this.props.setSelectedList("masterDataOpen", "journalVersionMatrix");
    const dispatch = this.context[1];
    getCountryList(window.localStorage.token).then((r) => {
      let list = r.data;
      list &&
        list.length > 0 &&
        this.setState({
          countryList: list.sort((a, b) =>
            a.countryCode.toLowerCase() > b.countryCode.toLowerCase() ? 1 : -1
          ),
        });
    });

    getJournalVersionList(window.localStorage.token).then((r) => {
      dispatch({
        type: "getJournalVersionMatrixsList",
        newStore: r.data,
      });
      this.getAllJournalVersions(r.data);
      // this.exportJournalversionMatrix(r.data);
    });
    this.setState({ loading: true });
  }

  sortStateList = (list) => {
    let z = list.sort(function (a, b) {
      let x = a.isActive && a.isActive;
      let y = b.isActive && b.isActive;
      let u = a.modifiedDate && a.modifiedDate;
      let v = b.modifiedDate && b.modifiedDate;
      if (x < y) {
        return 1;
      }
      if (u < v) {
        return -1;
      }
      if (x > y) {
        return -1;
      }
      if (u > v) {
        return 1;
      }
      return 0;
    });
    // console.log(z);
    this.setState({ rows: z });
  };

  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };
  // exportJournalversionMatrix = (journalVersionList) => {
  //   let list = journalVersionList;
  //   let rows = [];
  //   list &&
  //     list.length > 0 &&
  //     list.map((d) => {
  //       return rows.push({
  //         Country: d.countryCode,
  //         ROW: d.isROW === true ? "Yes" : "No",
  //         ROE: d.isROE === true ? "Yes" : "No",
  //         NORDIC: d.isNordic === true ? "Yes" : "No",
  //         ENE: d.isEne === true ? "Yes" : "No",
  //         ASEAN: d.isAsean === true ? "Yes" : "No",
  //         "Priority Order": d.priorityOrder,
  //         Active: d.isActive === true ? "Yes" : "No",
  //         "Created By": d.createdByName,
  //         "Created Date": moment(d.createdDate).format("DD/MM/YYYY HH:mm:ss"),
  //         "Modified By": d.modifiedByName,

  //         "Modified Date": moment(d.modifiedDate).format("DD/MM/YYYY HH:mm:ss"),
  //       });
  //     });
  //   this.setState({ exportDataValues: rows });
  // };

  getAllJournalVersions = (journalVersionList) => {
    let list = journalVersionList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.journalVersionMatrixID,
            d.countryID,
            d.countryCode,
            d.countryName,
            d.isROW,
            d.isROE,
            d.isNordic,
            d.isEne,
            d.isAsean,
            d.priorityOrder,
            d.isActive,
            d.createdByName,
            d.createdDate,
            d.modifiedByName,
            d.modifiedDate
          )
        );
      });
    this.sortStateList(rows);
    this.setState({
      journalVersionList: rows,
      selected: [],
      loading: false,
      activeRows: rows
        .filter((x) => x.isActive)
        .map((n) => n.journalVersionMatrixID),
    });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.journalVersionList && store.journalVersionList.length > 0) {
      if (
        this.state.journalVersionList.length !==
        store.journalVersionList.length &&
        !this.state.searchClicked
      ) {
        !this.state.searchClicked &&
          this.getAllJournalVersions(store.journalVersionList);
      }
    }

    return true;
  }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { journalVersionList } = this.state;
    if (event.target.checked) {
      const newSelecteds = journalVersionList
        .filter((x) => x.isActive === true)
        .map((n) => n.journalVersionMatrixID);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, data) {
    let journalVersionMatrixID = data.journalVersionMatrixID;
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(journalVersionMatrixID);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, journalVersionMatrixID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedJournalVersion: data, //: selected[0]
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedJournalVersion: {}, //, selectedJournalVersionId: 0
      });
    }
  }
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchJournalVersion = (handleSearchJournalVersion) => {
    this.setState({ searchPopupOpen: false, searchClicked: true, page: 0 });
    let searchValue = handleSearchJournalVersion;
    const dispatch = this.context[1];

    let countryID = searchValue.country.countryID;
    let isAsean = searchValue.ASEAN
    let isEne = searchValue.ENE
    let isNordic = searchValue.NORDIC
    let isROE = searchValue.ROE
    let isROW = searchValue.ROW
    let reqBody = {
      countryID, isAsean,
      isEne,
      isNordic,
      isROE,
      isROW
    };
    getFliteredJournalVersionList(reqBody).then((r) => {
      dispatch({
        type: "getJournalVersionList",
        newStore: r.data,
      });
      this.getAllJournalVersions(r.data);
    });
  };

  handleDeleteJournalVersion = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteJournalVersion(modifiedBy, deletionIds).then((r) => {
      getJournalVersionList().then((r) => {
        dispatch({
          type: "getJournalVersionList",
          newStore: r.data,
        });
        this.getAllJournalVersions(r.data);
      });
      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };

  clearSearch = () => {
    const [{ store }, dispatch] = this.context;
    getJournalVersionList().then((r) => {
      dispatch({
        type: types.CPTCODE_LIST,
        newStore: r.data,
      });
      this.getAllJournalVersions(store.journalVersionList);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      journalVersionList,
      headCells,
      exportDataValues,
    } = this.state;
    // console.log(journalVersionList)
    const isSelected = (journalVersionMatrixID) =>
      selected.indexOf(journalVersionMatrixID) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteJournalVersion={this.handleDeleteJournalVersion}
            selectedJournalVersion={this.state.selectedJournalVersion}
            selectedJournalVersionId={this.state.selectedJournalVersionId}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchJournalVersion={this.handleSearchJournalVersion}
            searchValue={this.state.cptSearchValues}
            searchClicked={this.state.searchClicked}
            countryList={this.state.countryList}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            journalVersionList={journalVersionList}
            isActive={this.state.isActive}
            exportDataValues={exportDataValues}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {journalVersionList.length > 0 ? (
                    stableSort(journalVersionList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(
                          row.journalVersionMatrixID
                        );

                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              this.handleClick(
                                event,
                                //row.journalVersionMatrixID,
                                //row.countryCode
                                row
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            //key={row.journalVersionMatrixID}
                            ket={index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.countryCode}
                                </Typography> */}
                                {row.countryCode &&
                                  row.countryCode.length > 16 ? (
                                    <Tooltip
                                      title={row.countryCode}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.countryCode}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.countryCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.countryCode}
                                </Typography> */}
                                {row.countryName &&
                                  row.countryName.length > 16 ? (
                                    <Tooltip
                                      title={row.countryName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.countryName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.countryName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.isROW === true ? "Yes" : "No"}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.isROE === true ? "Yes" : "No"}
                              </TableCell>
                            )}

                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                // id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.isNordic === true ? "Yes" : "No"}
                                </Typography>
                              </TableCell>
                            )}

                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                // id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.isEne === true ? "Yes" : "No"}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                // id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.isAsean === true ? "Yes" : "No"}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                // id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.priorityOrder} */}
                                {row.priorityOrder &&
                                  row.priorityOrder.length > 16 ? (
                                    <Tooltip
                                      title={row.priorityOrder}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.priorityOrder}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.priorityOrder}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {status}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {/* {row.modifiedBy} */}
                                {row.modifiedByName &&
                                  row.modifiedByName.length > 16 ? (
                                    <Tooltip
                                      title={row.modifiedByName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {/* {row.createdBy} */}
                                {row.createdByName &&
                                  row.createdByName.length > 16 ? (
                                    <Tooltip
                                      title={row.createdByName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.createdByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={8} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={journalVersionList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(JournalMatrixMaster);
