import React from "react";
import {
  TextField,
  Button,
  FormControlLabel,
  Typography,
  Checkbox,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { StateContext } from "../../../shared/globalState";
import { getAllList } from "../../app/poupSearch/action";
import { getJournalData } from "../../app/poupSearch/action";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getAllListActive } from "../stockaction";
import { getBPAOrder } from "../../bpaMarketing/orderPlacement/action";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "96%",
    fontSize: "11px !important",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "29%",
    fontSize: "11px !important",
  },
  autocompleteText: {
    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
  search: {
    position: "relative",
    minWidth: "500px",
    display: "flex",
  },
  comboBox: {
    margin: "normal",
    width: "31%",
    fontSize: "11px !important",
  },
  searchIcon: {
    position: "absolute",
    right: "0",
  },
  tableContentCell: {
    borderRight: "1px solid #eaeaea",
    borderLeft: "1px solid #eaeaea",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Roboto",
    fontSize: "14px !important",
    padding: "0.5em !important",
  },
});

function createData(
  journalID,
  issueDescription,
  acronym,
  journalTitle,
  volume,
  issue,
  supplement
) {
  return {
    journalID,
    issueDescription,
    acronym,
    journalTitle,
    volume,
    issue,
    supplement,
  };
}

class IndividualSearchAddDialog extends React.Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      dialogTitle: this.props.title,
      stockPopupOpen: this.props.showDialog,
      selected: [],
      selectedJournalsList: [],
      filteredJournalsList: this.props.filteredJournalsList,
      displayJournalsList: [],
      initialFilteredJournalistsList: [],
      searchText: "",
      journalIDSearchText: "",
      issueDescSearchText: "",
      acronymSearchText: "",
      journalTitleSearchText: "",
      volumeSearchText: "",
      issueSearchText: "",
      suppSearchText: "",
      journalLoading: false,
      page: 0,
      rowsPerPage: 100,
      journalsList: [
        {
          issueDesc: "issueDesc 1",
          quantity: 5,
          trackingNumber: "RDNL98987DF",
          actualWeight: 0.45,
          deliveryDate: "2020-03-31T11:36:13.047",
        },
        {
          issueDesc: "issueDesc 2",
          quantity: 4,
          trackingNumber: "RDNL34457DF",
          actualWeight: 0.87,
          deliveryDate: "2020-04-21T11:36:13.047",
        },
        {
          issueDesc: "issueDesc 3",
          quantity: 1,
          trackingNumber: "RDTH34457DF",
          actualWeight: 0.36,
          deliveryDate: "2020-04-13T11:36:13.047",
        },
        {
          issueDesc: "issueDesc 4",
          quantity: 10,
          trackingNumber: "RDFR34457DF",
          actualWeight: 0.98,
          deliveryDate: "2020-05-13T11:36:13.047",
        },
        {
          issueDesc: "issueDesc 5",
          quantity: 4,
          trackingNumber: "RDRT34457DF",
          actualWeight: 1.87,
          deliveryDate: "2019-04-13T11:36:13.047",
        },
      ],
      headCells: [
        {
          id: 1,
          code: "issueDescription",
          numeric: false,
          disablePadding: false,
          label: "Issue Description",
          show: true,
          width: "160px",
        },
        {
          id: 2,
          code: "journalTitle",
          numeric: false,
          disablePadding: false,
          label: "Journal Title",
          show:
            this.props.type === "stockOut" ||
            this.props.type === "stockTransfer"
              ? false
              : true,
          width: "180px",
        },
        {
          id: 3,
          code: "acronym",
          numeric: false,
          disablePadding: false,
          label: "Acronym",
          show: true,
          width: "58px",
        },
        {
          id: 4,
          code: "volume",
          numeric: false,
          disablePadding: false,
          label: "Volume",
          show: true,
          width: "50px",
        },
        {
          id: 5,
          code: "issue",
          numeric: false,
          disablePadding: false,
          label: "Issue",
          show: true,
          width: "56px",
        },
        {
          id: 6,
          code: "supplement",
          numeric: false,
          disablePadding: false,
          label: "Supplement",
          show: true,
          width: "85px",
        },
        {
          id: 7,
          code: "action",
          numeric: false,
          disablePadding: false,
          label: "Action",
          show: true,
        },
      ],
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    this.setState({
      journalLoading: true,
    });
    if (this.props.type === "stockOut" || this.props.type === "stockTransfer") {
      getAllListActive().then((r) => {
        this.getJournalInformation(r.data);
      });
    } else if (this.props.mode === "BPAOrderPlacement") {
      getBPAOrder().then((r) => {
        this.getJournalInformation(r.data);
      });
    } else {
      getAllList().then((r) => {
        dispatch({
          //type: types.CPTCODE_LIST,
          type: "getJobGroup",
          newStore: r.data,
        });
        this.getJournalInformation(r.data);
      });
    }
  }

  getJournalInformation = (journalList) => {
    let list = journalList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d, index) => {
        if (index < 100) {
          return rows.push(
            createData(
              d.journalID ? d.journalID : "",
              d.issueDescription ? d.issueDescription : "",
              d.acronym ? d.acronym : "",
              d.journalTitle ? d.journalTitle : "",
              d.volume ? d.volume : "",
              d.issue ? d.issue : "",
              d.supplement ? d.supplement : ""
            )
          );
        }
      });
    this.setState({
      displayJournalsList: rows,
      initialFilteredJournalistsList: rows,
      journalLoading: false,
    });
  };

  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleClick = (event, data) => {
    let journalID = data.journalID,
      selected = [],
      newSelected = [],
      selectedArray = [];
    if (event.target.checked) {
      newSelected = newSelected.concat(selected, journalID);
      selectedArray.push(data);
      this.setState({
        selected: newSelected,
        selectedJournalsList: selectedArray,
      });
    } else {
      selectedArray.pop();
      this.setState({
        selected: newSelected,
        selectedJournalsList: selectedArray,
      });
    }
  };

  handleAddJournalPopupSubmit = () => {
    this.setState(
      {
        stockPopupOpen: false,
        selected: [],
      },
      () => {
        this.props.handleJournalUpdate(this.state.selectedJournalsList);
      }
    );
  };

  clearSearchJournals = () => {
    getAllList().then((r) => {
      this.getJournalInformation(r.data);
    });

    this.setState({
      issueDescSearchText: "",
      acronymSearchText: "",
      journalTitleSearchText: "",
      volumeSearchText: "",
      issueSearchText: "",
      suppSearchText: "",
    });
  };

  handleSearchJournals = () => {
    const {
      journalIDSearchText,
      issueDescSearchText,
      acronymSearchText,
      journalTitleSearchText,
      volumeSearchText,
      issueSearchText,
      suppSearchText,
    } = this.state;

    let reqBody = {
      issueDescription: issueDescSearchText,
      acronym: acronymSearchText,
      volume: volumeSearchText,
      journalTitle: journalTitleSearchText,
      issue: issueSearchText,
      supplement: suppSearchText,
    };

    getJournalData(reqBody).then((r) => {
      this.getJournalInformation(r);
    });
  };

  handlePopupClose = () => {
    this.setState({
      stockPopupOpen: false,
      selected: [],
    });
    this.props.handleAddJournalClose();
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  render() {
    const {
      dialogTitle,
      selected,
      displayJournalsList,
      journalIDSearchText,
      issueDescSearchText,
      acronymSearchText,
      journalTitleSearchText,
      volumeSearchText,
      issueSearchText,
      suppSearchText,
      rowsPerPage,
      page,
    } = this.state;
    let { classes } = this.props;
    const numSelected = selected.length,
      rowCount = displayJournalsList.length;
    const isSelected = (journalID) =>
      selected && selected.indexOf(journalID) !== -1;
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={this.state.stockPopupOpen}
        onClose={this.handlePopupClose}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent dividers>
          <div className={classes.search}>
            <div style={{ display: "flex", width: "100%" }}>
              <TextField
                margin="dense"
                id="name"
                autoComplete="off"
                name="issueDescSearchText"
                onChange={this.handleChange}
                value={issueDescSearchText}
                placeholder="Issue Desc"
                style={{
                  flex: 1,
                  border: "1px solid #eee",
                  padding: "6px 6px 0",
                  maxWidth: "165px",
                  marginRight: "10px",
                }}
              />
              {this.props.type === "stockOut" ||
              this.props.type === "stockTransfer" ? null : (
                <TextField
                  margin="dense"
                  id="name"
                  autoComplete="off"
                  name="journalTitleSearchText"
                  onChange={this.handleChange}
                  value={journalTitleSearchText}
                  placeholder="Journal Title"
                  style={{
                    flex: 1,
                    border: "1px solid #eee",
                    padding: "6px 6px 0",
                    maxWidth: "165px",
                    marginRight: "10px",
                  }}
                />
              )}
              <TextField
                margin="dense"
                id="name"
                autoComplete="off"
                name="acronymSearchText"
                onChange={this.handleChange}
                value={acronymSearchText}
                placeholder="Acr"
                style={{
                  flex: 1,
                  border: "1px solid #eee",
                  padding: "6px 6px 0",
                  maxWidth: "60px",
                  marginRight: "10px",
                }}
              />

              <TextField
                margin="dense"
                id="name"
                autoComplete="off"
                name="volumeSearchText"
                onChange={this.handleChange}
                value={volumeSearchText}
                placeholder="Vol"
                style={{
                  flex: 1,
                  border: "1px solid #eee",
                  padding: "6px 6px 0",
                  maxWidth: "51px",
                  marginRight: "10px",
                }}
              />
              <TextField
                margin="dense"
                id="name"
                autoComplete="off"
                name="issueSearchText"
                onChange={this.handleChange}
                value={issueSearchText}
                placeholder="Issue"
                style={{
                  flex: 1,
                  border: "1px solid #eee",
                  padding: "6px 6px 0",
                  maxWidth: "57px",
                  marginRight: "10px",
                }}
              />
              <TextField
                margin="dense"
                id="name"
                autoComplete="off"
                name="suppSearchText"
                onChange={this.handleChange}
                value={suppSearchText}
                placeholder="Supp"
                style={{
                  flex: 1,
                  border: "1px solid #eee",
                  padding: "6px 6px 0",
                  maxWidth: "66px",
                }}
              />
              <div style={{ width: "71px", display: "flex" }}>
                <div style={{ flex: 1, width: "50%", textAlign: "center" }}>
                  <IconButton
                    aria-label="view"
                    className={classes.iconHover}
                    onClick={this.handleSearchJournals}
                  >
                    <SearchIcon />
                  </IconButton>
                </div>
                <div style={{ flex: 1, width: "50%", textAlign: "center" }}>
                  <IconButton
                    aria-label="view"
                    className={classes.iconHover}
                    onClick={this.clearSearchJournals}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
          {this.state.journalLoading ? (
            <div style={{ textAlign: "center" }}>
              <LinearProgress />
            </div>
          ) : null}
          <Table
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
            style={{
              border: "1px solid  rgba(224, 224, 224, 1)",
              height: "350px",
              display: "block",
              overflowY: "auto",
            }}
            stickyHeader
          >
            <TableHead>
              <TableRow style={{width:"100%"}}>
                <TableCell
                  component="th"
                  scope="row"
                  padding="checkbox"
                  className={classes.tableContentCell}
                ></TableCell>
                {this.state.headCells.map((headCell, i) => {
                  return (
                    headCell.show === true &&
                    i != this.state.headCells.length - 1 && (
                      <TableCell
                        component="th"
                        scope="row"
                        padding="default"
                        className={classes.tableContentCell}
                        style={{
                          maxWidth: headCell.width,
                          width: headCell.width,
                        }}
                      >
                        {" "}
                        <span style={{ color: "#306dca" }}>
                          {headCell.label}
                        </span>{" "}
                      </TableCell>
                    )
                  );
                })}
              </TableRow>
            </TableHead>
            {displayJournalsList.length > 0 ? (
              <TableBody>
                {displayJournalsList.map((row, index) => {
                  const isItemSelected = isSelected(row.journalID);
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell padding="checkbox">
                        <FormControlLabel
                          onClick={(event) => this.handleClick(event, row)}
                          control={
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="default"
                        align="left"
                        className="table-content-cell"
                      >
                        <Typography noWrap style={{ fontSize: "14px" }}>
                          {row.issueDescription}
                        </Typography>
                      </TableCell>
                      {this.props.type === "stockOut" ||
                      this.props.type === "stockTransfer" ? null : (
                        <TableCell
                          component="th"
                          scope="row"
                          padding="default"
                          align="left"
                          className="table-content-cell"
                        >
                          {row.journalTitle && row.journalTitle.length > 16 ? (
                            <Tooltip title={row.journalTitle} aria-label={row.journalTitle}>
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.journalTitle}
                              </Typography>
                            </Tooltip>
                          ) : (
                          <Typography noWrap style={{ fontSize: "14px" }}>
                            {row.journalTitle}
                          </Typography>
                           )}
                        </TableCell>
                      )}
                      <TableCell
                        component="th"
                        scope="row"
                        padding="default"
                        align="left"
                        className="table-content-cell"
                      >
                        <Typography noWrap style={{ fontSize: "14px" }}>
                          {row.acronym}
                        </Typography>
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        padding="default"
                        align="right"
                        className="table-content-cell"
                      >
                        <Typography noWrap style={{ fontSize: "14px" }}>
                          {row.volume}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="default"
                        align="right"
                        className="table-content-cell"
                      >
                        <Typography noWrap style={{ fontSize: "14px" }}>
                          {row.issue}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="default"
                        align="right"
                        className="table-content-cell"
                      >
                        <Typography noWrap style={{ fontSize: "14px" }}>
                          {row.supplement}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : !this.state.journalLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8} style={{ textAlign: "center" }}>
                    No result found
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : null}
          </Table>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={displayJournalsList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </DialogContent>
        <DialogActions style={{ padding: "10px 24px" }}>
          <Button
            onClick={this.handlePopupClose}
            variant="contained"
            color="primary"
            style={{ boxShadow: "none" }}
          >
            CANCEL
          </Button>
          {Object.keys(selected).length === 0 ? (
            <Button
              disabled
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={this.handleAddJournalPopupSubmit}
            >
              SUBMIT
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={this.handleAddJournalPopupSubmit}
            >
              SUBMIT
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withRouter(withStyles(styles)(IndividualSearchAddDialog));
