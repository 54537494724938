import React, { Component } from "react";
import { Autocomplete } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { withRouter } from "react-router";
import {
  TextField,
  Button,
  Divider,
  Typography,
  InputBase,
} from "@material-ui/core";
import moment from "moment";
import {
  GetDisplayWOInJG,
  GetJobSchedulerStatusDropdown,
  GetJobSchedulerStatusDropdownWO,
  postJobSchedulers,
  GetJobSchedulerByJobGroupID,
  updateJobSchedulers,
  updateSchedulers,
  GetJobGroupsListByWOID,
  GetJobGrpDetails
} from "./action";
import {
  getProductionLineList,
  getProductionLineListDD,
} from "../../masters/masterActions";

let workOrderMode = "";
class EditJobScheduler extends Component {
  constructor() {
    super();
    this.state = {
      woDisplayDetails: {
        workOrderID: "",
        customerName: "",
        workOrderNumber: "",
        projectTitle: "",
        jobGroupNumber: "",
      },
      changesUpdated: false,
      errors: {},
      formValid: false,
      productionLineList: [],
      statusList: [],
      jGObj: {},
      jobSchedulers: {
        statusForEdit: "",
        jobSchedulerID: 0,
        jobGroupID: 0,
        productionLine: "",
        productionLineID: null,
        materialReceivedDate: new Date().toLocaleDateString(),
        productionDate: new Date().toLocaleDateString(),
        mailingDate: new Date().toLocaleDateString(),
        jobQuantity: 0,
        remarks: "",
        etsete: "",
        etp: "",
        priority: 0,
        parentJobSchedulerID: null,
        isProductionLineCompleted: true,
        productionLineCompletedDate: new Date().toLocaleDateString(),
        statusValue: "",
        statusID: null,
      },
    };
  }

  componentDidMount() {
    this.props.handleDrawerClose();

    let workOrderID = this.props.workOrderID;
    workOrderMode =
      this.props.location.state && this.props.location.state.workOrderMode;

    GetDisplayWOInJG(workOrderID, this.props.jobGroupID).then((r) => {
      this.setState({ woDisplayDetails: r });
    });
    GetJobGrpDetails(this.props.jobGroupID).then(jGData => {
      getProductionLineListDD().then((r) => {
        GetJobSchedulerByJobGroupID(this.props.jobGroupID).then((xy) => {
          let jsObj = xy;
          // console.log(xy, jGData)
          // console.log(this.state.jobSchedulers)
          // let newJSState=clone(this.state.jobSchedulers)
          // newJSState.productionDate=xy.productionDate
          jsObj["statusForEdit"] = xy.status
          jsObj["materialReceivedDate"] = xy.materialReceivedDate ? xy.materialReceivedDate : jGData.materialReceivedDate ? jGData.materialReceivedDate : new Date().toLocaleDateString()
          jsObj["productionDate"] = xy.productionDate ? xy.productionDate : jGData.productionDate ? jGData.productionDate : new Date().toLocaleDateString()
          jsObj["mailingDate"] = xy.mailingDate ? xy.mailingDate : jGData.mailingDate ? jGData.mailingDate : new Date().toLocaleDateString()
          jsObj["productionLine"] =
            r && r.data && r.data.find((x) => x.id === xy.productionLineID);
          this.setState({
            jobSchedulers: jsObj,
            //  formValid: true
          }, () => {
            this.validateForm()
            GetJobSchedulerStatusDropdownWO().then((r) => {
              console.log(r, xy)
              jsObj["statusValue"] =
                r && r.data && r.data.find((x) => x.text === xy.status);
              this.setState({
                jobSchedulers: jsObj,
                //  formValid: true
              });

              r &&
                this.setState({
                  statusList:
                    r &&
                    r.data &&
                    r.data.sort((a, b) =>
                      a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
                    ),
                });

              this.setState(
                {
                  statusList: r.data.sort((a, b) => {
                    return a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1;
                  }),
                },
                () => {
                  let newJS = { ...this.state.jobSchedulers };
                  let inQueueObj = this.state.statusList.filter(
                    (r) => r.value == "JQUE",
                    {}
                  );
                  let inOperationObj = this.state.statusList.filter(
                    (r) => r.value == "JOP",
                    {}
                  );
                  if (!this.state.productionLineID) {
                    newJS["statusValue"] = inQueueObj && inQueueObj[0];
                    newJS["statusID"] = inQueueObj && inQueueObj[0].id;
                    this.setState({ jobSchedulers: newJS });
                  }
                }
                // () => {
                //   let newStatusList = [...this.state.statusList];
                //   newStatusList.push({
                //     text: "None",
                //     id: null
                //   });
                //   this.setState({ statusList: newStatusList });
              );
            });
          });
          //if (this.state.jobSchedulers.length > 0) this.validateForm();
        });
        r.data &&
          this.setState({
            productionLineList: r.data
              // .filter(x => x.isActive === true)
              .sort((a, b) =>
                a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
              ),
          });
      });
    })
    //this.validateForm();
  }

  // shouldComponentUpdate(s, p) {
  //   let schedulerDetails = { ...this.state.jobSchedulers };
  //   schedulerDetails["statusValue"] = this.state.statusList.find(
  //     x => x.id === this.state.jobSchedulers.statusID
  //   );
  //   schedulerDetails["productionLine"] = this.state.productionLineList.find(
  //     x => x.productionLineID === this.state.jobSchedulers.productionLineID
  //   );
  //   if (
  //     schedulerDetails.statusID &&
  //     (schedulerDetails.statusValue === "" ||
  //       schedulerDetails.statusValue === undefined ||
  //       schedulerDetails.statusValue === null) &&
  //     this.state.statusList.length > 0 &&
  //     schedulerDetails.productionLineID &&
  //     (schedulerDetails.productionLine === "" ||
  //       schedulerDetails.productionLine === undefined ||
  //       schedulerDetails.productionLine === null) &&
  //     this.state.productionLineList.length > 0
  //   ) {
  //     this.setState({ jobSchedulers: schedulerDetails });
  //   }

  //   // }

  //   return true;
  // }

  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleMaterialRecvDateChange = (date) => {
    //const { jobSchedulers } = this.state;
    let newJS = { ...this.state.jobSchedulers };
    newJS["materialReceivedDate"] = date.toLocaleDateString();
    this.setState({ jobSchedulers: newJS, changesUpdated: true });
    this.validateForm();
  };

  handleProdutionDateChange = (date) => {
    let newJS = { ...this.state.jobSchedulers };
    newJS["productionDate"] = date.toLocaleDateString();
    this.setState({ jobSchedulers: newJS, changesUpdated: true });
    this.validateForm();
  };

  handleMailingDateChange = (date) => {
    let newJS = { ...this.state.jobSchedulers };
    newJS["mailingDate"] = date.toLocaleDateString();
    this.setState({ jobSchedulers: newJS, changesUpdated: true });
    this.validateForm();
  };

  handleProductionLineChange = async (event, values) => {
    let newJS = { ...this.state.jobSchedulers };
    let inQueueObj = this.state.statusList.filter((r) => r.value == "JQUE", {});
    console.log(this.state.statusList)
    let inOperationObj = this.state.statusList.filter(
      (r) => r.value == "JSTANDBY",
      {}
    );
    if (values != null) {
      let productionLineID = values && values.id;
      newJS["productionLine"] = values;
      newJS["productionLineID"] = productionLineID;
      newJS["statusValue"] = inOperationObj && inOperationObj[0];
      newJS["statusID"] = inOperationObj && inOperationObj[0].id;
    } else {
      let productionLineID = values && values.id;
      newJS["productionLine"] = values;
      newJS["productionLineID"] = productionLineID;
      newJS["statusValue"] = inQueueObj && inQueueObj[0];
      newJS["statusID"] = inQueueObj && inQueueObj[0].id;
    }

    await this.setState({ jobSchedulers: newJS, changesUpdated: true });
    this.validateForm();
  };

  handleStatusChange = (event, values) => {
    // const { jobSchedulers } = this.state;
    let newJS = { ...this.state.jobSchedulers };
    let statusID = values && values.id;
    newJS["statusValue"] = values;
    newJS["statusID"] = statusID;
    this.setState({ jobSchedulers: newJS, changesUpdated: true });
    this.validateForm();
  };

  handleChange = (event) => {
    let newJS = { ...this.state.jobSchedulers };
    newJS[event.target.name] = event.target.value;
    this.validateField(event.target.name, event.target.value);
    this.setState({ jobSchedulers: newJS, changesUpdated: true });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let priorityValid = this.state.priorityValid;
    let jobQuantityValid = this.state.jobQuantityValid;
    switch (fieldName) {
      case "priority":
        if (value !== "") priorityValid = true;
        fieldValidationErrors.priority = priorityValid
          ? ""
          : "Please enter valid priority";
        break;
      case "jobQuantity":
        if (value !== "") jobQuantityValid = true;
        fieldValidationErrors.jobQuantity = jobQuantityValid
          ? ""
          : "Please enter valid job quantity";
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        priorityValid: priorityValid,
        jobQuantityValid: jobQuantityValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    // if (this.state.productionLineList.length > 0) {
    // console.log(this.state)
    this.setState({
      formValid:
        // (this.state.priorityValid ||
        //   this.state.jobSchedulers.priority !== "") &&
        // (this.state.jobQuantityValid ||
        //   this.state.jobSchedulers.jobQuantity !== ""),

        this.state.jobSchedulers.jobQuantity !== "" && this.state.jobSchedulers.priority !== ""
      //    &&
      // this.state.jobSchedulers.productionLineID !== undefined &&
      // this.state.jobSchedulers.productionLineID !== null &&
      // this.state.jobSchedulers.productionLineID !== "" &&
      // this.state.jobSchedulers.productionLineID !== 0
    });

    return this.state.formValid;
    // }
  }
  handleSubmit = (event) => {
    event.preventDefault();

    const { jobSchedulers } = this.state;
    let materialReceivedDate =
      jobSchedulers.materialReceivedDate != null
        ? jobSchedulers.materialReceivedDate
        : new Date().toLocaleDateString();
    let productionDate =
      jobSchedulers.productionDate != null
        ? jobSchedulers.productionDate
        : new Date().toLocaleDateString();
    let mailingDate =
      jobSchedulers.mailingDate != null
        ? jobSchedulers.mailingDate
        : new Date().toLocaleDateString();
    let jobGroupID = this.props.jobGroupID;
    let productionLineID =
      jobSchedulers.productionLineID > 0
        ? jobSchedulers.productionLineID
        : null;
    let jobQuantity = jobSchedulers.jobQuantity;
    let remarks = jobSchedulers.remarks;
    let etsete = jobSchedulers.etsete;
    let etp = jobSchedulers.etp;
    let priority = jobSchedulers.priority;
    let parentJobSchedulerID = jobSchedulers.parentJobSchedulerID;
    let isProductionLineCompleted = jobSchedulers.isProductionLineCompleted;
    let productionLineCompletedDate = jobSchedulers.productionLineCompletedDate;
    let statusID =
      jobSchedulers.statusID === 0 || jobSchedulers.statusID === undefined
        ? null
        : jobSchedulers.statusID;
    let isActive = true;
    let createdBy = localStorage.loggedInId;
    if (this.props.history.location.state.comingFrom == "jobscheduler") {
      let reqBody = {
        materialReceivedDate,
        productionDate,
        mailingDate,
        jobGroupID,
        productionLineID,
        jobQuantity,
        remarks,
        etsete,
        etp,
        priority,
        parentJobSchedulerID,
        isProductionLineCompleted,
        productionLineCompletedDate,
        statusID,
        isActive,
        modifiedBy: localStorage.loggedInId,
      };

      return updateSchedulers(
        jobSchedulers.jobSchedulerID,
        reqBody,
        this.props.history
      );
    }
    if (
      this.props.workOrderMode === "edit" &&
      jobSchedulers.jobSchedulerID !== 0
    ) {
      let reqBody = {
        materialReceivedDate,
        productionDate,
        mailingDate,
        jobGroupID,
        productionLineID,
        jobQuantity,
        remarks,
        etsete,
        etp,
        priority,
        parentJobSchedulerID,
        isProductionLineCompleted,
        productionLineCompletedDate,
        statusID,
        isActive,
        modifiedBy: localStorage.loggedInId,
      };

      updateJobSchedulers(
        jobSchedulers.jobSchedulerID,
        reqBody,
        this.props.history
      );
    } else {
      let reqBody = {
        materialReceivedDate,
        productionDate,
        mailingDate,
        jobGroupID,
        productionLineID,
        jobQuantity,
        remarks,
        etsete,
        etp,
        priority,
        parentJobSchedulerID,
        isProductionLineCompleted,
        productionLineCompletedDate,
        statusID,
        isActive,
        createdBy,
      };

      postJobSchedulers(reqBody, this.props.history);
    }
  };

  handlCancelClicked = (event, workOrderMode) => {

    this.props.setSelectedList("genericSystem", "createWorkOrder");
    GetJobGroupsListByWOID(this.props.workOrderID).then((r) => {
      if (r && r.length === 1) {
        this.props.history.push("/workOrders")
      }
      else if (workOrderMode === "view")
        // this.props.history.push("/view_WorkOrder?workOrderID=114")
        this.props.history.push({
          pathname: "/view_WorkOrder",
          search: "?workOrderID=" + this.props.workOrderID,
          state: { workOrderMode: workOrderMode },
        });
      else if (workOrderMode === "edit")
        // this.props.history.push("/view_WorkOrder?workOrderID=this.props.workOrderID")
        this.props.history.push({
          pathname: "/edit_WorkOrder",
          search: "?workOrderID=" + this.props.workOrderID,
          state: { workOrderMode: workOrderMode },
        });
      else if (this.props.workOrderMode === "create") {
        this.props.history.push("/workOrders")
      }
    });
  };
  render() {
    let { woDisplayDetails } = this.state;
    // console.log(this.state.jobSchedulers.statusValue, this.state.formValid)
    return (
      <div
        style={{
          padding: "20px",
          paddingBottom: "0",
          background: "#fff",
          boxShadow: "rgba(0, 0, 0, 0.26)",
        }}
      >
        <div style={{ width: "100%" }}>
          <Typography
            component="div"
            className="blueBox"
            style={{ width: "auto" }}
          >
            <div className="blueBoxLabel">
              <div>Customer: </div>
              <div className="blueBoxText">{woDisplayDetails.customerName}</div>
            </div>
            <div className="blueBoxLabel">
              <div>Work Order: </div>
              <div className="blueBoxText">
                {woDisplayDetails.workOrderNumber}
              </div>
            </div>
            <div className="blueBoxLabel">
              <div>Project Title: </div>
              <div className="blueBoxText">{woDisplayDetails.projectTitle}</div>
            </div>
          </Typography>
          <Typography
            component="div"
            className="blueBox"
            style={{ marginTop: "2px", width: "auto" }}
          >
            <div className="blueBoxLabel">
              <div>Job Group: </div>
              <div className="blueBoxText">
                {woDisplayDetails.jobGroupNumber}
              </div>
            </div>
          </Typography>
        </div>
        <div>
          {workOrderMode !== "view" && this.props.status != "Completed" ? (
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "33.33%" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      //disablePast
                      disableToolbar
                      //disabled={workOrderMode === "view"}
                      // inputProps={
                      //   ({
                      //     maxLength: 20
                      //   },
                      //   { readOnly: workOrderMode === "view" ? true : false })
                      // }
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      style={{ width: "100%" }}
                      id="materialRecivedDate"
                      label="Material Recieved Date"
                      value={this.state.jobSchedulers.materialReceivedDate}
                      onChange={this.handleMaterialRecvDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div style={{ width: "33.33%", margin: "0 40px" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      //disablePast
                      disableToolbar
                      //disabled={workOrderMode === "view"}
                      // inputProps={
                      //   ({
                      //     maxLength: 20
                      //   },
                      //   { readOnly: workOrderMode === "view" ? true : false })
                      // }
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      style={{ width: "100%" }}
                      id="productionDate"
                      label="Production Date"
                      value={this.state.jobSchedulers.productionDate}
                      onChange={this.handleProdutionDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>{" "}
                <div style={{ width: "33.33%" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      //disablePast
                      disableToolbar
                      // inputProps={
                      //   ({
                      //     maxLength: 20
                      //   },
                      //   { readOnly: workOrderMode === "view" ? true : false })
                      // }
                      variant="inline"
                      //disabled={workOrderMode === "view"}
                      format="dd/MM/yyyy"
                      margin="normal"
                      style={{ width: "100%" }}
                      id="mailingDate"
                      label="Mailing Date"
                      value={this.state.jobSchedulers.mailingDate}
                      onChange={this.handleMailingDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "33.33%" }}>
                  <TextField
                    required
                    label="Priority"
                    name="priority"
                    inputProps={{
                      maxLength: 3,
                    }}
                    // inputProps={
                    //   ({
                    //     maxLength: 3
                    //   },
                    //   // { readOnly: workOrderMode === "view" ? true : false })
                    // }
                    margin="normal"
                    style={{ width: "100%" }}
                    autoComplete="off"
                    value={this.state.jobSchedulers.priority}
                    onChange={this.handleChange}
                    onKeyPress={this._onNumberKeyPress}
                    error={this.state.errors.priority}
                    helperText={
                      this.state.errors.priority
                        ? this.state.errors.priority
                        : ""
                    }
                  />
                </div>
                <div style={{ width: "33.33%", margin: "0 40px" }}>
                  <TextField
                    label="ETP"
                    name="etp"
                    inputProps={
                      {
                        maxLength: 10,
                      }
                      // { readOnly: workOrderMode === "view" ? true : false })
                    }
                    style={{ width: "100%" }}
                    margin="normal"
                    autoComplete="off"
                    value={this.state.jobSchedulers.etp}
                    onChange={this.handleChange}
                  />
                </div>{" "}
                <div style={{ width: "33.33%" }}>
                  <TextField
                    label="ETS/ETE"
                    name="etsete"
                    inputProps={{
                      maxLength: 20,
                    }}
                    // disabled={workOrderMode === "view"}
                    style={{ width: "100%" }}
                    margin="normal"
                    autoComplete="off"
                    value={this.state.jobSchedulers.etsete}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "33.33%" }}>
                  <TextField
                    required
                    label="Job Quantity"
                    name="jobQuantity"
                    inputProps={{
                      maxLength: 5,
                    }}
                    // disabled={workOrderMode === "view"}
                    style={{ width: "100%" }}
                    margin="normal"
                    autoComplete="off"
                    onKeyPress={this._onDecimalKeyPress}
                    value={this.state.jobSchedulers.jobQuantity}
                    onChange={this.handleChange}
                    error={this.state.errors.jobQuantity}
                    helperText={
                      this.state.errors.jobQuantity
                        ? this.state.errors.jobQuantity
                        : ""
                    }
                  />
                </div>
                <div style={{ width: "33.33%", margin: "0 40px" }}>
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="productionLine"
                    style={{ width: "100%" }}
                    // disabled={workOrderMode === "view"}
                    options={this.state.productionLineList}
                    onChange={this.handleProductionLineChange}
                    value={this.state.jobSchedulers.productionLine}
                    getOptionLabel={(option) => option.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // required
                        label="Production Line"
                        name="productionLine"
                        id="productionLine"
                        style={{ width: "100%" }}
                        error={this.state.errors.productionLine}
                        helperText={
                          this.state.errors.productionLine
                            ? this.state.errors.productionLine
                            : ""
                        }
                      />
                    )}
                  />
                </div>{" "}
                <div style={{ width: "33.33%" }}>
                  {workOrderMode !== "edit" ? <Autocomplete
                    autoComplete
                    includeInputInList
                    defaultValue={this.state.statusList}
                    id="status"
                    // disabled={workOrderMode === "edit"}
                    // disabled={workOrderMode === "edit" ? true : false}

                    style={{ width: "100%" }}
                    variant={"outlined"}
                    options={this.state.statusList}
                    onChange={this.handleStatusChange}
                    value={this.state.jobSchedulers.statusValue}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Status"
                        // variant={"filled"}
                        // inputProps={workOrderMode === "edit" ? {
                        //   readOnly: true,
                        // } : { readOnly: false }}
                        // disabled={workOrderMode === "edit" ? true : false}
                        name="status"
                        id="status"
                        style={{ width: "100%" }}
                      />
                    )}
                  /> : <TextField
                      required
                      label="Status"
                      name="status"
                      inputProps={{
                        maxLength: 5,
                      }}
                      disabled
                      style={{ width: "100%" }}
                      margin="normal"
                      autoComplete="off"
                      // onKeyPress={this._onDecimalKeyPress}
                      value={this.state.jobSchedulers.statusForEdit}

                    />}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "33.33%" }}>
                  <TextField
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    label="Remarks"
                    name="remarks"
                    margin="normal"
                    disabled={workOrderMode === "view"}
                    autoComplete="off"
                    inputProps={{
                      maxLength: 100,
                    }}
                    value={this.state.jobSchedulers.remarks}
                    style={{ width: "100%" }}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
          ) : (
              <React.Fragment>
                <div style={{ margin: ".8em 0 1em 0" }}>
                  {/* <div className="view-page-container" id="printable"> */}
                  <div
                    className="view-page-contents-container"
                    id="view-page-contents-container"
                  >
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">
                        Material Recieved Date
                    </div>
                      <div className="view-page-value">
                        {this.state.jobSchedulers.materialReceivedDate != null
                          ? moment(
                            this.state.jobSchedulers.materialReceivedDate
                          ).format("DD/MM/YYYY")
                          : ""}
                      </div>
                    </div>
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">Production Date</div>
                      <div className="view-page-value">
                        {this.state.jobSchedulers.productionDate != null
                          ? moment(
                            this.state.jobSchedulers.productionDate
                          ).format("DD/MM/YYYY")
                          : ""}
                      </div>
                    </div>
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">Mailing Date</div>
                      <div className="view-page-value">
                        {this.state.jobSchedulers.mailingDate != null
                          ? moment(this.state.jobSchedulers.mailingDate).format(
                            "DD/MM/YYYY"
                          )
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div
                    className="view-page-contents-container"
                    id="view-page-contents-container"
                  >
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">Priority</div>
                      <div className="view-page-value">
                        {this.state.jobSchedulers.priority}
                      </div>
                    </div>
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">ETP</div>
                      <div className="view-page-value">
                        {this.state.jobSchedulers.etp}
                      </div>
                    </div>
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">ETS/ETE</div>
                      <div className="view-page-value">
                        {this.state.jobSchedulers.etsete}
                      </div>
                    </div>
                  </div>
                  <div
                    className="view-page-contents-container"
                    id="view-page-contents-container"
                  >
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">Job Quantity</div>
                      <div className="view-page-value">
                        {this.state.jobSchedulers.jobQuantity}
                      </div>
                    </div>
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">Production Line</div>
                      <div className="view-page-value">
                        {this.state.jobSchedulers.productionLineCode}
                      </div>
                    </div>
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">Status</div>
                      <div className="view-page-value">
                        {this.state.jobSchedulers.status}
                      </div>
                    </div>
                  </div>
                  <div
                    className="view-page-contents-container"
                    id="view-page-contents-container"
                  >
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">Remarks</div>
                      <div className="view-page-value">
                        {this.state.jobSchedulers.remarks}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}

          <Divider
            style={{
              marginTop: "25px",
            }}
          />

          <div className="button-wrapper">
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={
                  this.props.history.location.state.comingFrom &&
                    this.props.history.location.state.comingFrom == "jobscheduler"
                    ? () => this.props.history.push("/jobScheduler")
                    : (e) => this.handlCancelClicked(e, workOrderMode)
                }
              // onClick={(e) => this.handlCancelClicked(e, workOrderMode)}

              >
                CANCEL
              </Button>
            </div>
            <div style={{ marginLeft: "1em" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => {
                  this.props.history.location.state.comingFrom == "jobscheduler"
                    ? this.props.history.push("/jobScheduler")
                    : this.props.history.push({
                      pathname: "/stock",
                      search:
                        "?workOrderID=" +
                        this.props.workOrderID +
                        "&jobGroupID=" +
                        this.props.jobGroupID,
                      state: { workOrderMode: workOrderMode },
                    });
                }}
              >
                BACK
              </Button>
            </div>
            {workOrderMode !== "view" && this.props.status != "Completed" && (
              <div style={{ marginLeft: "1em" }}>
                {/* {!this.state.changesUpdated || !this.state.formValid ? ( */}
                {!this.state.formValid ? (

                  <Button
                    // disabled={
                    //   this.state.jobSchedulers.statusValue
                    //     ? Object.keys(this.state.jobSchedulers.statusValue)
                    //       .length === 0
                    //     : 1
                    // }
                    disabled
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={this.handleSubmit}
                  >
                    SUBMIT
                  </Button>
                ) : (
                    <Button
                      disabled={
                        this.state.jobSchedulers.statusValue
                          && Object.keys(this.state.jobSchedulers.statusValue).length === 0 ? true
                          : false
                      }
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={this.handleSubmit}
                    >
                      SUBMIT
                    </Button>
                  )}
              </div>
            )}

            {workOrderMode == "edit" && this.props.status == "Completed" && (
              <div style={{ marginLeft: "1em" }}>
                {" "}
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={this.handleSubmit}
                >
                  SUBMIT
                </Button>
              </div>
            )}

            {workOrderMode === "view" && (
              <div style={{ marginLeft: "1em" }}>
                <Button
                  disabled={this.props.status == "Completed"}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) =>
                    this.props.history.push({
                      pathname: "/distribution",
                      search:
                        "?workOrderID=" +
                        this.props.workOrderID +
                        "&jobGroupID=" +
                        this.props.jobGroupID,
                      state: { workOrderMode: workOrderMode },
                    })
                  }
                >
                  NEXT
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditJobScheduler);
