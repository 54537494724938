import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Paper, Divider } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { addDispatchStyles } from "../../../shared/styles";
import { StateContext } from "../../../shared/globalState";
import { getParticularLocalPostage } from "../../masters/masterActions";
import { printDetails } from "../../../shared/tableCommonFunctions";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { addZeros } from "../../../shared/tableCommonFunctions";

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { id } = props;
  let acnArr = props.roles.filter(u => u.menuName === "Local Postage");
  let acnObj = acnArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%"
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_localPostage/${id}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {/* <Tooltip title="Download">
            <IconButton aria-label="download" className={classes.iconHover}  onClick={e => pdfDownloadDetails("local_postage.pdf")}>
              <DownloadIcon />
            </IconButton>
          </Tooltip> */}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={e => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewLocalPostage extends React.Component {
  static contextType = StateContext;
  state = {
    customerName: "",
    coustomerID: "",
    noCopiesFrom: "",
    noCopiesTo: "",
    weightFrom: 0.0,
    weightTo: 0.0,
    postageRate: 0,
    isActive: ""
  };
  componentDidMount() {
    this.props.handleSettingHeader("Local Postage");
    this.props.setSelectedList("masterDataOpen", "localPostage");
    let id = "";
    if (process.browser) id = window.location.pathname.split("/")[2];
    if (id) {
      getParticularLocalPostage(id).then(r => {
        this.setState({
          customerID: r && r.data && r.data.customerID && r.data.customerID,
          customerName:
            r && r.data && r.data.customerName && r.data.customerName,
          noCopiesFrom:
            r && r.data && r.data.noCopiesFrom && r.data.noCopiesFrom,
          noCopiesTo: r && r.data && r.data.noCopiesTo && r.data.noCopiesTo,
          weightFrom: r && r.data && r.data.weightFrom && r.data.weightFrom,
          weightTo: r && r.data && r.data.weightTo && r.data.weightTo,
          postageRate: r && r.data && r.data.postageRate && r.data.postageRate,
          isActive: r && r.data && r.data.isActive && r.data.isActive
        });
      });
    }
  }
  render() {
    let {
      customerName,
      noCopiesFrom,
      noCopiesTo,
      weightFrom,
      weightTo,
      postageRate,
      isActive
    } = this.state;
    let id = "";
    if (process.browser) id = window.location.pathname.split("/")[2];
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar id={id} roles={this.props.roles} />
          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">Local Postage Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Customer Name</div>
                <div className="view-page-value">
                  {customerName && customerName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Postage Rate (S$)</div>
                <div className="view-page-value">
                  {addZeros(postageRate.toString(), 2) &&
                    addZeros(postageRate.toString(), 2)}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No of Copies From</div>
                <div className="view-page-value">
                  {noCopiesFrom && noCopiesFrom}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No of Copies To</div>
                <div className="view-page-value">
                  {noCopiesTo && noCopiesTo}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Weight From (Kg)</div>
                <div className="view-page-value">
                  {addZeros(weightFrom.toString(), 3) &&
                    addZeros(weightFrom.toString(), 3)}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Weight To (kg)</div>
                <div className="view-page-value">
                  {addZeros(weightTo.toString(), 3) &&
                    addZeros(weightTo.toString(), 3)}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Active</div>
                <div className="view-page-value">
                  {isActive && isActive ? "Yes" : "No"}
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "25px" }} />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                  onClick={e => this.props.history.push("/localPostage")}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addDispatchStyles)(ViewLocalPostage));
