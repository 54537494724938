import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import {
  SearchIcon,
  ViewColumnIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import {
  stableSort,
  getSorting,
  DialogActions,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import { StateContext } from "../../../shared/globalState";
import {
  getStockInList,
  getItemMasterList,
  getFliteredStockInList,
} from "../genericActions";
import {
  getWarehouseLocationList,
  getCustomerList,
} from "../../masters/masterActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ExportCSV } from "../../../shared/ExportCSV";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import clone from "clone";

const columns = [
  // {
  //   name: "Active",
  //   id: 8,
  //   show: false,
  // },
  {
    name: "Modified Date",
    id: 8,
    show: false,
  },
  {
    name: "Modified By",
    id: 9,
  },
  {
    name: "Created Date",
    id: 10,
  },
  {
    name: "Created By",
    id: 11,
  },
];

function createData(
  stockINID,
  stockID,
  stockCode,
  stockName,
  customerCode,
  customerName,
  warehouseLocationID,
  warehouseLocation,
  receivedQty,
  usedQty,
  receivedDate,
  remarks,
  isActive,

  createdName,
  createdDate,

  modifiedName,
  modifiedDate
) {
  return {
    stockINID,
    stockID,
    stockCode,
    stockName,
    customerCode,
    customerName,
    warehouseLocationID,
    warehouseLocation,
    receivedQty,
    usedQty,
    receivedDate,
    remarks,
    isActive,

    createdName,
    createdDate,

    modifiedName,
    modifiedDate,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          {/* <FormControlLabel
            control={
              <CustomCheckbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all cptCode" }}
              />
            }
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [formValid, setIsformValid] = useState(false);
  let acnArr = props.roles.filter((u) => u.subMenuName === "Stock In");
  let acnObj = acnArr[0];

  const [stockInSearchValue, setValues] = useState({
    stock: 0,
    stockID: 0,
    modifiedDateFrom: null,
    modifiedDateTo: null,
    warehouseLocation: 0,
    warehouseLocationID: 0,
    customerID: 0,
    customer: "",
  });

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);

  const classes = useToolbarStyles();
  const {
    numSelected,
    searchPopupOpen,
    exportDataValues,
    selectedStockINID,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchStockIn,
    searchClicked,
    hendleVewColums,
    ItemMasterList,
    customersList,
    warehouseLocationList,
  } = props;

  console.log(props.exportDataValues, "exportDataValues");

  let expData = clone(
    props.exportDataValues &&
    props.exportDataValues.length > 0 &&
    props.exportDataValues
  );
  expData &&
    expData.length > 0 &&
    expData.map((d) => {
      // d["Stock Code"] = d["stockCode"];
      // d["Customer Code"] = d["Customer Code"];
      // d["Customer Name"] = d["customerName"];
      // d["Warehouse Location"] = d["Warehouse Location"];
      // d["Warehouse Location ID"] = d["warehouseLocationID"];
      // d["Received Date"] = moment(d["receivedDate"]).format(
      //   "DD/MM/YYYY HH:mm:ss"
      // );
      // d["Received Qty"] = d["receivedQty"];
      // d["Used Qty"] = d["usedQty"];
      // d["Remarks"] = d["Remarks"];
      // d["Active"] = d["isActive"];

      // d["Modified By"] = d["modifiedName"];

      d["Modified Date"] = moment(d["modifiedDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );

      // d["Created By"] = d["createdName"];
      d["Created Date"] = moment(d["createdDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );

      delete d["stockID"];
      delete d["stockCode"];
      delete d["stockName"]
      delete d["customerCode"];
      delete d["customerName"];
      delete d["warehouseLocation"];
      delete d["warehouseLocationID"];
      delete d["receivedDate"];
      delete d["receivedQty"];
      delete d["usedQty"];
      delete d["remarks"];
      delete d["isActive"];
      delete d["createdDate"];
      delete d["createdName"];
      delete d["modifiedDate"];
      delete d["modifiedName"];
    });

  const handleSearchOpen = () => {
    setIsformValid(false);
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = stockInSearchValue;
    searchValues.stock = 0;
    searchValues.warehouseLocation = 0;
    searchValues.modifiedDateFrom = null;
    searchValues.modifiedDateTo = null;
    searchValues.customerID = 0;
    searchValues = stockInSearchValue;
  };

  const searchPopup = () => {
    console.log(stockInSearchValue);
    handleSearchStockIn(stockInSearchValue);
    clearSearchValues();
  };

  const handleSearchInput = (event, values) => {
    if (values === null) {
      let name2 = "stock";
      let name = event.target.name;
      setValues((stockInSearchValue) => ({
        ...stockInSearchValue,
        [name2]: values && values.stockID,
      }));
    }
    event.persist();
    let name2 = "stock";
    let name = event.target.name;
    setValues((stockInSearchValue) => ({
      ...stockInSearchValue,
      [name2]: values && values.stockID,
    }));
    setIsformValid(true);
    switch (name) {
      default:
        break;
    }
  };

  const handleWarehouselocationChange = (event, values) => {
    if (values === null) {
      let name2 = "warehouseLocation";
      let name = event.target.name;
      setValues((stockInSearchValue) => ({
        ...stockInSearchValue,
        [name2]: values && values.warehouseLocationID,
      }));
    }
    event.persist();
    let name2 = "warehouseLocation";
    let name = event.target.name;
    setValues((stockInSearchValue) => ({
      ...stockInSearchValue,
      [name2]: values && values.warehouseLocationID,
    }));
    setIsformValid(true);
    switch (name) {
      default:
        break;
    }
  };
  const handleCustValuesChange = (event, values) => {
    if (values === null) {
      let name2 = "customerID";
      let name = event.target.name;
      setValues((stockInSearchValue) => ({
        ...stockInSearchValue,
        [name2]: values && values.customerID,
      }));
    }
    event.persist();
    let name2 = "customerID";
    let name = event.target.name;
    setValues((stockInSearchValue) => ({
      ...stockInSearchValue,
      [name2]: values && values.customerID,
    }));
    setIsformValid(true);
    switch (name) {
      default:
        break;
    }
  };
  const handleDateChange = (date) => {
    let name = "modifiedDateFrom";
    setValues((stockInSearchValue) => ({
      ...stockInSearchValue,
      [name]: date && date.toLocaleDateString(),
    }));
    setIsformValid(true);
  };
  const handleDateChange1 = (date) => {
    let name = "modifiedDateTo";
    setValues((stockInSearchValue) => ({
      ...stockInSearchValue,
      [name]: date && date.toLocaleDateString(),
    }));
    setIsformValid(true);
    // const { stockInSearchValue } = this.state;
    // stockInSearchValue["modifiedDateTo"] = date;
    // this.setState({ stockInSearchValue });
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_StockIn/${selectedStockINID}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  <form onSubmit={searchPopup}>
                    {acnObj && acnObj.advanceSearchAction && (
                      <Tooltip title="Search">
                        <IconButton
                          aria-label="Search"
                          className={classes.iconHover}
                          onClick={handleSearchOpen}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      onClose={handleSearchPopupClose}
                      aria-labelledby="customized-dialog-title"
                      open={searchPopupOpen}
                      maxWidth="md"
                    >
                      <DialogTitle
                        id="customized-dialog-title"
                        onClose={handleSearchPopupClose}
                      >
                        Advanced Search
                  </DialogTitle>
                      <DialogContent dividers>
                        <React.Fragment>
                          <div style={{ width: "100%", display: "flex" }}>
                            <Autocomplete
                              autoComplete
                              includeInputInList
                              id="customer"
                              name="customer"
                              hintText="process Name"
                              options={customersList}
                              style={{ flex: 1, marginRight: "16px" }}
                              onChange={handleCustValuesChange}
                              getOptionLabel={(option) => option.customerName}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Customer"
                                  name="customerId"
                                  id="customerId"
                                  margin="normal"
                                  style={{ width: "100%" }}
                                  //className={classes.textField}
                                  value={stockInSearchValue.customerId}
                                // error={this.state.errors.stockID}
                                // helperText={
                                //   this.state.errors.stockID
                                //     ? this.state.errors.stockID
                                //     : ""
                                // }
                                />
                              )}
                            />
                            <div style={{ flex: 1 }}></div>
                            {/* <Autocomplete
                          autoComplete
                          className={classes.comboBox}
                          id="warehouseLocation"
                          name="warehouseLocation"
                          includeInputInList
                          options={warehouseLocationList}
                          value={warehouseLocationList}
                          onChange={handleWarehouselocationChange}
                          getOptionLabel={option => option.locationCode}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Select Warehouse Location"
                              className={classes.textField}
                              name="warehouseLocation"
                              margin="normal"
                              // error={this.state.errors.warehouseLocation}
                              // helperText={
                              //   this.state.errors.warehouseLocation
                              //     ? this.state.errors.warehouseLocation
                              //     : ""
                              // }
                            />
                          )}
                        /> */}
                          </div>
                          <div style={{ width: "100%", display: "flex" }}>
                            <Autocomplete
                              autoComplete
                              includeInputInList
                              id="stock"
                              name="stock"
                              hintText="process Name"
                              options={ItemMasterList}
                              style={{ flex: 1, marginRight: "16px" }}
                              onChange={handleSearchInput}
                              getOptionLabel={(option) => option.stockCode}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Stock"
                                  name="stockID"
                                  id="stockID"
                                  margin="normal"
                                  style={{ width: "100%" }}
                                  //className={classes.textField}
                                  value={stockInSearchValue.stockID}
                                // error={this.state.errors.stockID}
                                // helperText={
                                //   this.state.errors.stockID
                                //     ? this.state.errors.stockID
                                //     : ""
                                // }
                                />
                              )}
                            />
                            <Autocomplete
                              autoComplete
                              includeInputInList
                              id="warehouseLocation"
                              name="warehouseLocation"
                              hintText="Warehouse Location"
                              style={{ flex: 1 }}
                              options={warehouseLocationList}
                              onChange={handleWarehouselocationChange}
                              getOptionLabel={(option) => option.locationCode}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Warehouse Location"
                                  name="warehouseLocation"
                                  id="warehouseLocation"
                                  margin="normal"
                                  //className={classes.textField}
                                  style={{ width: "100%" }}
                                  value={stockInSearchValue.warehouseLocationID}
                                // error={this.state.errors.stockID}
                                // helperText={
                                //   this.state.errors.stockID
                                //     ? this.state.errors.stockID
                                //     : ""
                                // }
                                />
                              )}
                            />
                          </div>

                          <div style={{ width: "100%", display: "flex" }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                // disablePast
                                disableToolbar
                                autoOk
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                style={{ flex: 1, marginRight: "16px" }}
                                id="modifiedDateFrom"
                                label="Modified Date From"
                                value={stockInSearchValue.modifiedDateFrom}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                //disablePast
                                disableToolbar
                                autoOk
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Modified Date To"
                                style={{ flex: 1 }}
                                value={stockInSearchValue.modifiedDateTo}
                                onChange={handleDateChange1}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </React.Fragment>
                      </DialogContent>
                      <DialogActions style={{ padding: "16px 24px" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          //
                          // color="primary"
                          onClick={handleSearchClose}
                        >
                          CANCEL
                    </Button>
                        {!stockInSearchValue.stock &&
                          !stockInSearchValue.warehouseLocation &&
                          !stockInSearchValue.customerID &&
                          !(
                            stockInSearchValue.modifiedDateFrom &&
                            stockInSearchValue.modifiedDateTo
                          ) ? (
                            <Button
                              disabled
                              variant="contained"
                              color="primary"
                              style={{ boxShadow: "none" }}
                              onClick={searchPopup}
                            >
                              SEARCH
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ boxShadow: "none" }}
                              onClick={searchPopup}
                            >
                              SEARCH
                            </Button>
                          )}
                      </DialogActions>
                    </Dialog>
                  </form>
                )}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="add"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/add_StockIn")}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {acnObj && acnObj.printAction && (
                <ExportCSV csvData={expData} fileName={"Stock_In"} />
              )}
              {/* <IconButton aria-label="download" className={classes.iconHover}>
                  <DownloadIcon />
                </IconButton> */}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                hendleVewColums={hendleVewColums}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class StockInMater extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    stockInList: [],
    refreshData: false,
    selectedStockIn: "",
    selectedStockINID: 1,
    loading: false,
    searchPopupOpen: false,
    cptSearchValues: {},
    searchClicked: false,
    selectedShowColumns: [],
    exportDataValues: [],
    ItemMasterList: [],
    customersList: [],
    warehouseLocationList: [],
    headCells: [
      {
        id: 1,
        code: "stockCode",
        numeric: false,
        disablePadding: true,
        label: "Stock Code",
        show: true,
      },
      {
        id: 2,
        code: "stockName",
        numeric: false,
        disablePadding: true,
        label: "Stock Name",
        show: true,
      },
      {
        id: 3,
        code: "customerCode",
        numeric: false,
        disablePadding: true,
        label: "Customer Code",
        show: true,
      },
      {
        id: 4,
        code: "customerName",
        numeric: false,
        disablePadding: true,
        label: "Customer",
        show: true,
      },

      {
        id: 5,
        code: "warehouseLocation",
        numeric: false,
        disablePadding: false,
        label: "Warehouse Location",
        show: true,
      },
      {
        id: 6,
        code: "receivedQty",
        numeric: false,
        disablePadding: false,
        label: "Received Qty",
        show: true,
      },
      {
        id: 7,
        code: "usedQty",
        numeric: false,
        disablePadding: false,
        label: "Used Qty",
        show: true,
      },
      {
        id: 8,
        code: "receivedDate",
        numeric: false,
        disablePadding: false,
        label: "Received Date",
        show: true,
      },
      {
        id: 9,
        code: "remarks",
        numeric: false,
        disablePadding: false,
        label: "Remarks",
        show: true,
      },
      // {
      //   id: 9,
      //   code: "status",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Active",
      //   show: true,
      // },
      {
        id: 10,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 11,
        code: "modifiedName",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 12,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 13,
        code: "createdName",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };
  componentDidMount() {
    this.props.handleSettingHeader("Stock In");
    this.props.setSelectedList(
      "genericSystemOpen",
      "stockIn",
      "warehouseManagementOpen"
    );
    const dispatch = this.context[1];
    getStockInList().then((r) => {
      dispatch({
        type: "getStockInList",
        newStore: r.data,
      });
      this.getAllStockIn(r.data);
      this.exportCptDatas(r.data);
    });
    this.setState({ loading: true });

    getCustomerList().then((r) => {
      this.setState({
        customersList:
          r.data &&
          r.data.sort((a, b) =>
            a.customerName.toLowerCase() > b.customerName.toLowerCase() ? 1 : -1
          ),
      });
    });

    getWarehouseLocationList().then((r) => {
      this.setState({
        warehouseLocationList:
          r.data &&
          r.data.sort((a, b) =>
            a.locationCode.toLowerCase() > b.locationCode.toLowerCase() ? 1 : -1
          ),
      });
    });
    getItemMasterList().then((r) => {
      this.setState({
        ItemMasterList:
          r.data &&
          r.data.sort((a, b) =>
            a.stockCode.toLowerCase() > b.stockCode.toLowerCase() ? 1 : -1
          ),
      });
    });
  }

  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };
  exportCptDatas = (stockInList) => {
    let list = stockInList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          "Stock Code": d.stockCode,
          "Stock Name": d.stockName,
          "Customer Code": d.customerCode,
          Customer: d.customerName,

          "Warehouse Location": d.warehouseLocation,
          "Received Qty": d.receivedQty,
          "Used Qty": d.usedQty,
          "Received Date": moment(d.receivedDate).format("DD/MM/YYYY HH:mm:ss"),
          Remarks: d.remarks,
          "Modified Date": moment(d.modifiedDate).format("DD/MM/YYYY HH:mm:ss"),
          "Modified By": d.modifiedName,
          "Created Date": moment(d.createdDate).format("DD/MM/YYYY HH:mm:ss"),
          "Created By": d.createdName,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getAllStockIn = (stockInList) => {
    let list = stockInList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.stockINID,
            d.stockID,
            d.stockCode,
            d.stockName,
            d.customerCode,
            d.customerName,
            d.warehouseLocationID,
            d.warehouseLocation,
            d.receivedQty,
            d.usedQty,
            d.receivedDate,
            d.remarks,
            d.isActive,
            d.createdName,
            d.createdDate,
            d.modifiedName,
            d.modifiedDate
          )
        );
      });
    this.setState({ stockInList: rows, selected: [], loading: false });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.stockInList && store.stockInList.length > 0)
      if (this.state.stockInList.length !== store.stockInList.length) {
        this.getAllStockIn(store.stockInList);
      }

    return true;
  }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { stockInList } = this.state;
    if (event.target.checked) {
      const newSelecteds = stockInList.map((n) => n.stockINID);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, stockINID, StockIn) {
    let newSelected = [];
    let selected = [];
    if (event.target.checked) {
      newSelected = newSelected.concat(selected, stockINID);
      this.setState({
        selected: newSelected,
        selectedStockIn: StockIn,
        selectedStockINID: stockINID,
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedStockIn: StockIn,
        selectedStockINID: stockINID,
      });
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true, page: 0 });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchStockIn = (handleSearchStockIn) => {
    const dispatch = this.context[1];
    console.log(handleSearchStockIn);
    let stockInSearchValue = handleSearchStockIn;
    let stockID = stockInSearchValue.stock;
    let warehouseLocationID = stockInSearchValue.warehouseLocation;
    let customerID = stockInSearchValue.customerID;
    let modifiedDateFrom =
      stockInSearchValue.modifiedDateFrom &&
      moment(stockInSearchValue.modifiedDateFrom).format("DD/MM/YYYY");
    let modifiedDateTo =
      stockInSearchValue.modifiedDateTo &&
      moment(stockInSearchValue.modifiedDateTo).format("DD/MM/YYYY");
    let reqBody = {
      stockID,
      customerID,
      warehouseLocationID,
      createdDateFrom: modifiedDateFrom,
      createdDateTo: modifiedDateTo,
    };

    getFliteredStockInList(reqBody).then((r) => {
      dispatch({
        type: "getFliteredStockInList",
        newStore: r.data,
      });
      this.getAllStockIn(r.data);
      this.setState({ searchPopupOpen: false, searchClicked: true });
    });
  };

  clearSearch = () => {
    const dispatch = this.context[1];
    getStockInList().then((r) => {
      dispatch({
        //type: types.CPTCODE_LIST,
        type: "getStockInList",
        newStore: r.data,
      });
      this.getAllStockIn(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      stockInList,
      exportDataValues,

      headCells,
    } = this.state;
    const isSelected = (stockINID) => selected.indexOf(stockINID) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteCptCode={this.handleDeleteCptCode}
            selectedStockIn={this.state.selectedStockIn}
            selectedStockINID={this.state.selectedStockINID}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchStockIn={this.handleSearchStockIn}
            stockInSearchValue={this.state.cptSearchValues}
            searchClicked={this.state.searchClicked}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            customersList={this.state.customersList}
            ItemMasterList={this.state.ItemMasterList}
            warehouseLocationList={this.state.warehouseLocationList}
            stockInList={stockInList}
            exportDataValues={exportDataValues}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ whiteSpace: "nowrap" }}
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={stockInList.length}
                  headCells={this.state.headCells}
                  stockInList={this.state.stockInList}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {stockInList.length > 0 ? (
                    stableSort(stockInList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.stockINID);
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              this.handleClick(
                                event,
                                row.stockINID,
                                row.stockName
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.stockINID}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.stockCode && row.stockCode.length > 16 ? (
                                  <Tooltip title={row.stockCode}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.stockCode}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.stockCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.stockName && row.stockName.length > 16 ? (
                                  <Tooltip title={row.stockName}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.stockName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.stockName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.customerCode &&
                                  row.customerCode.length > 16 ? (
                                    <Tooltip title={row.customerCode}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.customerCode}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.customerName &&
                                  row.customerName.length > 16 ? (
                                    <Tooltip title={row.customerName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.customerName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.warehouseLocation &&
                                  row.warehouseLocation.length > 16 ? (
                                    <Tooltip title={row.warehouseLocation}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.warehouseLocation}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.warehouseLocation}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                // id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.receivedQty &&
                                  row.receivedQty.length > 16 ? (
                                    <Tooltip title={row.receivedQty}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.receivedQty}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.receivedQty}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                // id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.usedQty && row.usedQty.length > 16 ? (
                                  <Tooltip title={row.usedQty}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.usedQty}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.usedQty}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                // id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.receivedDate &&
                                  row.receivedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.receivedDate).format(
                                        "DD/MM/YYYY  HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.receivedDate).format(
                                          "DD/MM/YYYY  HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.receivedDate).format(
                                        "DD/MM/YYYY  HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                // id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.remarks && row.remarks.length > 16 ? (
                                  <Tooltip title={row.remarks}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.remarks}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.remarks}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {/* {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                // id={labelId}
                              >
                                {status}
                              </TableCell>
                            )} */}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.modifiedName &&
                                  row.modifiedName.length > 16 ? (
                                    <Tooltip title={row.modifiedName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdName &&
                                  row.createdName.length > 16 ? (
                                    <Tooltip title={row.createdName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.createdName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={8} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={stockInList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(StockInMater);
