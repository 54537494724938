import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
//import { getCptCode, updateCptCodeID, getCPTCodeList } from "../masterActions";
import Validator from "../../../shared/validator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { addStyles } from "../../../shared/styles";
import {
  getCustomerList,
  getCountryList,
  getCountryDropdownList,
  getCustomerDDList,
  getRoutingCustomerList,
} from "../../masters/masterActions";
import { getRoutingZone, putRoutingZone } from "../routingActions";
// Include new StateContext
import { StateContext } from "../../../shared/globalState";
const onlyNumbers = new Validator("onlyNumbers");

class EditRoutingZone extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      routingZone: {
        customerID: "",
        countryID: "",
        customerName: "",
        countryName: "",
        isByZone: true,
        isActive: true,
        country: "",
        customer: "",
        modifiedBy: localStorage.loggedInId,
      },
      errors: {},
      active: false,
      countryValid: true,
      customerValid: true,
      countryList: [],
      customersList: [],
    };
    this.state.routingZone.isByZone = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let routingZoneGroupID = this.props.match.params.routingZoneGroupID;

    getRoutingCustomerList().then((r) => {
      r.data &&
        this.setState({
          customersList: r.data
            // .filter(x => x.isActive === true)
            .sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
    });
    // getCountryList().then(r => {
    //   r.data &&
    //     this.setState({
    //       countryList: r.data
    //         .filter(x => x.isActive === true)
    //         .sort((a, b) =>
    //           a.countryName.toLowerCase() > b.countryName.toLowerCase() ? 1 : -1
    //         )
    //     });
    // });
    getCountryDropdownList().then((r) => {
      // let newCountryList = [];
      // r.data &&
      //   r.data.map(d => {
      //     newCountryList.push({
      //       countryID: d.countryID,
      //       countryCode: d.countryCode,
      //       countryName: d.countryCode + "-" + d.countryName,
      //       zoneID: d.zoneID,
      //       zoneName: d.zoneName,
      //       zoneCode: d.zoneCode,
      //       dhlZoneID: d.zoneCode,
      //       dhlZoneName: d.zoneCode,
      //       dhlZoneCode: d.dhlZoneCode,
      //       alternateCountryCode: d.alternateCountryCode,
      //       alternateCountryName: d.alternateCountryName,
      //       isActive: d.isActive,
      //       createdByID: d.createdByID,
      //       createdBy: d.createdBy,
      //       createdDate: d.createdDate,
      //       modifiedByID: d.modifiedByID,
      //       modifiedBy: d.modifiedBy,
      //       modifiedDate: d.modifiedDate
      //     });
      //   });

      r.data &&
        this.setState({
          countryList: r.data
            //.filter(x => x.isActive === true)
            .sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
    });

    getRoutingZone(routingZoneGroupID).then((r) => {
      dispatch({
        type: "getRoutingZone",
        newStore: r,
      });

      let newCountryList1 = r.country;
      newCountryList1.countryName =
        newCountryList1 &&
        newCountryList1.countryCode + "-" + newCountryList1.countryName;

      r.country = newCountryList1;
      this.setState({ routingZone: r, active: r.isActive });
    });

    this.props.handleSettingHeader("Country Zone Grouping");
  }
  handleChange = (event) => {
    const { routingZone } = this.state;
    if (event.target.name === "isByZone" || event.target.name === "isActive") {
      routingZone[event.target.name] = event.target.checked;
      this.setState({ formValid: true });
    } else {
      routingZone[event.target.name] = event.target.value;
    }

    this.validateField(event.target.name, event.target.value);
    this.setState({ routingZone });
  };
  handleCountryChange = (event, values) => {
    const { routingZone } = this.state;
    let countryID = values && values.id;
    routingZone["country"] = values;
    routingZone["countryID"] = countryID;
    this.validateField("country", countryID);
    this.setState({ routingZone });
  };
  handleCustomerChange = (event, values) => {
    const { routingZone } = this.state;
    let customerID = values && values.id;
    routingZone["customer"] = values;
    routingZone["customerID"] = customerID;
    this.validateField("customer", customerID);
    this.setState({ routingZone });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let customerValid = this.state.customerValid;
    let countryValid = this.state.countryValid;
    switch (fieldName) {
      case "country":
        countryValid = onlyNumbers(value);
        fieldValidationErrors.country = countryValid
          ? ""
          : " Please select Country";
        break;
      case "customer":
        customerValid = onlyNumbers(value);
        fieldValidationErrors.customer = customerValid
          ? ""
          : " Please select Customer";
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        customerValid: customerValid,
        countryValid: countryValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.countryValid && this.state.customerValid, // ||
      //this.state.isByZone
    });
    return this.state.formValid;
  }

  handleSubmit(e) {
    e.preventDefault();
    let routingZoneGroupID = this.props.match.params.routingZoneGroupID;
    const { routingZone } = this.state;

    routingZone.modifiedBy = localStorage.loggedInId;
    putRoutingZone(routingZoneGroupID, routingZone, this.props.history);
  }

  render() {
    let { classes } = this.props;
    let {
      routingZone,
      errors,
      active,
      customersList,
      countryList,
    } = this.state;
    //const country = this.state.countryList;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Country Zone Grouping</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    //disabled
                    includeInputInList
                    className={classes.textField}
                    id="customer"
                    options={customersList}
                    onChange={this.handleCustomerChange}
                    value={routingZone.customer}
                    getOptionLabel={(option) =>
                      option.customerName || option.text
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Customer"
                        name="customer"
                        id="customer"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={routingZone.customer}
                        error={this.state.errors.customer}
                        helperText={
                          this.state.errors.customer
                            ? this.state.errors.customer
                            : ""
                        }
                      />
                    )}
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    className={classes.textField}
                    id="country"
                    options={countryList}
                    onChange={this.handleCountryChange}
                    value={routingZone.country}
                    getOptionLabel={(option) =>
                      option.countryName || option.text
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Country"
                        name="country"
                        id="country"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={routingZone.country}
                        error={this.state.errors.country}
                        helperText={
                          this.state.errors.country
                            ? this.state.errors.country
                            : ""
                        }
                      />
                    )}
                  />
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      name="isActive"
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          color="primary"
                          checked={routingZone.isActive}
                          onChange={this.handleChange}
                          value={routingZone.isActive}
                        />
                      }
                      label="Active"
                    />
                  )}
                  {/* <FormControlLabel
                    name="isByZone"
                    className={classes.checkBox}
                    control={
                      <Checkbox
                        color="primary"
                        checked={routingZone.isByZone}
                        onChange={this.handleChange}
                        value={routingZone.isByZone}
                      />
                    }
                    label="Zone"
                  />
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      name="isActive"
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          color="primary"
                          checked={routingZone.isActive}
                          onChange={this.handleChange}
                          value={routingZone.isActive}
                        />
                      }
                      label="Active"
                    />
                  )} */}
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/countryZoneGrouping")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditRoutingZone));
