import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_PUBLISHER_MASTER;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getJournalitemMasterList = async () => {
  let jimList = [];
  await pgsService
    .get("/JournalItemGetAll")
    .then((r) => {
      jimList = r;
    })
    .catch((e) => { });
  return jimList;
};

export const getJrnlStageList = async () => {
  let jimList = [];
  await pgsService
    .get("/GetJournalStageList")
    .then((r) => {
      jimList = r;
    })
    .catch((e) => { });
  return jimList;
};
export const getPublisherCustomerList = async () => {
  let jimList = [];
  await pgsService
    .get("/GetPublisherCustomer")
    .then((r) => {
      jimList = r;
    })
    .catch((e) => { });
  return jimList;
};
export const getPublisherJournalTypeList = async () => {
  let jimList = [];
  await pgsService
    .get("/GetJournalType")
    .then((r) => {
      jimList = r;
    })
    .catch((e) => { });
  return jimList;
};
export const getOneJournalitemMasterList = async (id) => {
  let jimList = [];
  await pgsService
    .get(`GetJournalForView/${id}`)
    .then((r) => {
      jimList = r;
    })
    .catch((e) => { });
  return jimList;
};

export const getOneJournalitemMasterListEdit = async (id) => {
  let jimList = [];
  await pgsService
    .get(`JournalItemGetByID/${id}`)
    .then((r) => {
      jimList = r;
    })
    .catch((e) => { });
  return jimList;
};
export const getStagingJournalitemMasterList = async (id) => {
  let jimList = [];
  await pgsService
    .get(`GetJournalStageByID/${id}`)
    .then((r) => {
      jimList = r;
    })
    .catch((e) => { });
  return jimList;
};

export const postJournalitemMasterList = async (reqBody, history) => {
  let jimList = [];
  await pgsService
    .post("/JournalItemCreate", reqBody)
    .then((r) => {
      toastr.success("Journal created successfully !");
      history.push("/pubJournalItemMaster");
    })
    .catch((e) => {
      toastr.error("Something went wrong !");
    });
};
export const searchJournalitemMasterList = async (reqBody, history) => {
  let jimList = [];
  await pgsService
    .post("/JournalItemMasterAdvanceSerach", reqBody)
    .then((r) => {
      jimList = r.data;
    })
    .catch((e) => { });
  return jimList;
};

export const updateJournalitemMasterList = async (id, reqBody, history) => {
  let jimList = [];
  await pgsService
    .put(`/JournalItemUpdate/${id}`, reqBody)
    .then((r) => {
      toastr.success("Journal updated successfully !");
      history.push("/pubJournalItemMaster");
    })
    .catch((e) => {
      toastr.error("Something went wrong!");
    });
};

export const getJournalIssueID = async (reqBody, history) => {
  let jimList = {};
  await pgsService
    .get(
      `/GetJournalIssueID/${reqBody.Acronym}/${reqBody.Volume}/${
      reqBody.journalType
      }/${reqBody.journalType === "ISS" ? reqBody.Issue : reqBody.SuppNumber}/${
      reqBody.customerName
      }`
    )
    .then((r) => {
      if (r.data && r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
      }
      jimList = r.data;
    })
    .catch((e) => { });
  return jimList;
};

export const getOfficeName = async (reqBody, history) => {
  let jimList = "";
  await pgsService
    .get(`/GetOfficeName/${reqBody}`)
    .then((r) => {
      jimList = r.data;
    })
    .catch((e) => { });
  return jimList;
};
