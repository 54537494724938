import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Validator from "../../../shared/validator";
import { StateContext } from "../../../shared/globalState";
import { getZoneList, getDHLZoneList, postCountry } from "../masterActions";
import { addStyles } from "../../../shared/styles";
import { resourceValidation } from "../../../shared/resource";
const validateMaxlength2 = new Validator("maxlength2");
const shouldNotEmpty = new Validator("general");

const onlyNumbers = new Validator("onlyNumbers");

const sortAlphaNum = (a, b) =>
  a.dhlZoneCode.localeCompare(b.dhlZoneCode, "en", { numeric: false });

class AddCountry extends Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      country: {
        countryCode: "",
        countryName: "",
        dhlZoneID: "",
        alternateCountryCode: "",
        alternateCountryName: "",
        zoneID: "",
        dhlZone: "",
        // isActive: true,
        //createdBy: 1,
      },
      errors: {},
      countryCodeValid: false,
      countryNameValid: false,
      dhlZoneList: [],
      // dhlZoneValid: false,
      alternateCountryCodeValid: false,
      alternateCountryNameValid: false,
      zoneValid: false,
      formValid: false,
      zoneList: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange = (event) => {
    const { country } = this.state;
    this.validateField(event.target.name, event.target.value);
    country[event.target.name] = event.target.value;
    this.setState({ country });
  };
  handleChangeZone = (event, val) => {
    const { country } = this.state;
    if (val !== null) {
      // this.validateField("zoneID", val.zoneId);
      country["zoneID"] = val.zoneId;
      country["zoneCode"] = val;
      this.setState({ country });
    } else {
      country["zoneID"] = "";
      country["zoneCode"] = "";
      this.setState({ country });
    }
  };
  handleChangeDHL = (event, val) => {
    const { country } = this.state;
    if (val !== null) {
      // this.validateField("dhlZone", val.dhlZoneID);
      country["dhlZoneID"] = val.dhlZoneID;
      country["dhlZone"] = val;
      this.setState({ country });
    } else {
      country["dhlZoneID"] = "";
      country["dhlZone"] = "";
      this.setState({ country });
    }
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let countryCodeValid = this.state.countryCodeValid;
    let countryNameValid = this.state.countryNameValid;
    let alternateCountryCodeValid = this.state.alternateCountryCodeValid;
    let alternateCountryNameValid = this.state.alternateCountryNameValid;
    let dhlZoneValid = this.state.dhlZoneValid;
    let zoneValid = this.state.zoneValid;
    switch (fieldName) {
      case "countryCode":
        countryCodeValid = validateMaxlength2(value);
        if (!countryCodeValid) {
          fieldValidationErrors.countryCode = countryCodeValid
            ? ""
            : resourceValidation.onlyAlphanumeric;
        } else {
          if (value.length !== 2) {
            countryCodeValid = false;
            fieldValidationErrors.countryCode = resourceValidation.max2char;
          } else {
            countryCodeValid = true;
            fieldValidationErrors.countryCode = "";
          }
        }
        break;
      case "countryName":
        countryNameValid = shouldNotEmpty(value);
        fieldValidationErrors.countryName = countryNameValid
          ? ""
          : resourceValidation.countryName;
        break;
      case "alternateCountryCode":
        alternateCountryCodeValid = true;
        fieldValidationErrors.alternateCountryCode = "";

        // alternateCountryCodeValid = notRequired100(value);
        // fieldValidationErrors.alternateCountryCode = alternateCountryCodeValid
        //   ? ""
        //   : resourceValidation.onlyAlphabet;
        break;
      case "alternateCountryName":
        alternateCountryNameValid = true;
        fieldValidationErrors.alternateCountryName = "";
        // alternateCountryNameValid = validateNotRequired1000(value);
        // fieldValidationErrors.alternateCountryName = alternateCountryNameValid
        //   ? ""
        //   : resourceValidation.onlyAlphabet;
        break;

      case "zoneID":
        zoneValid = onlyNumbers(value);
        fieldValidationErrors.zone = zoneValid ? "" : " Please select Zone";
        break;
      case "dhlZone":
        dhlZoneValid = validateMaxlength2(value);
        if (!dhlZoneValid) {
          fieldValidationErrors.dhlZone = dhlZoneValid
            ? ""
            : resourceValidation.onlyAlphabet;
        } else {
          if (value.length !== 2) {
            dhlZoneValid = false;
            fieldValidationErrors.dhlZone = resourceValidation.max2char;
          } else {
            dhlZoneValid = true;
            fieldValidationErrors.dhlZone = "";
          }
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,

        countryCodeValid: countryCodeValid,
        countryNameValid: countryNameValid,
        alternateCountryCodeValid: alternateCountryCodeValid,
        dhlZoneValid: dhlZoneValid,
        alternateCountryNameValid: alternateCountryNameValid,
        zoneValid: zoneValid,
      },
      this.validateForm
    );
  }

  validateForm = () => {
    if (
      // !this.state.country.dhlZone ||
      // (this.state.country.dhlZone
      //   ? Object.keys(this.state.country.dhlZone).length === 0
      //   : 1) ||
      !this.state.country.zoneCode ||
      (this.state.country.zoneCode
        ? Object.keys(this.state.country.zoneCode).length === 0
        : 1) ||
      this.state.country.countryCode == "" ||
      this.state.country.countryName == ""
    ) {
      return true;
    }
    return false;
  };

  handleClose = () => {
    // clean up state and errors when modal closed
    this.setState({
      countryCode: "",
      countryName: "",
      zone: "",
      dhlZone: "",
      alternateCountryCode: "",
      alternateCountryName: "",
      errors: {},
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { country } = this.state;
    let countryCode = country.countryCode ? country.countryCode.toUpperCase() : "";
    let countryName = country.countryName;
    let alternateCountryCode = country.alternateCountryCode;
    let alternateCountryName = country.alternateCountryName;
    let zoneID = country.zoneID;
    let dhlZoneID = country.dhlZoneID;
    let reqBody = {
      countryCode,
      countryName,
      zoneID,
      dhlZoneID,
      alternateCountryCode,
      alternateCountryName,
    };
    reqBody.createdBy = localStorage.loggedInId;
    reqBody.isActive = "true";
    postCountry(reqBody, this.props.history);
  };
  _onCodeKeyPress(event) {
    const re = /[a-zA-Z0-9:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  componentDidMount() {
    this.props.handleSettingHeader("Country");

    getZoneList().then((r) => {
      this.setState({
        zoneList:
          r.data &&
          r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.zoneCode.toLowerCase() > b.zoneCode.toLowerCase() ? 1 : -1
            ),
      });
    });

    getDHLZoneList().then((r) => {
      this.setState({
        dhlZoneList: r.data && r.data.sort(sortAlphaNum),
      });
    });
  }

  render() {
    let { classes } = this.props;
    let { country, zoneList, dhlZoneList } = this.state;
    console.log(this.state, "state");
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Country</div>
          <Divider />

          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    label="Country Code"
                    name="countryCode"
                    inputProps={{
                      maxLength: 2,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={country.countryCode.toUpperCase()}
                    onChange={this.handleChange}
                    error={this.state.errors.countryCode}
                    helperText={
                      this.state.errors.countryCode
                        ? this.state.errors.countryCode
                        : ""
                    }
                    onKeyPress={this._onCodeKeyPress}
                  />
                  <TextField
                    required
                    label="Country Name"
                    name="countryName"
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={country.countryName}
                    onChange={this.handleChange}
                    error={this.state.errors.countryName}
                    helperText={
                      this.state.errors.countryName
                        ? this.state.errors.countryName
                        : ""
                    }
                  />
                </div>
                <div>
                  <Autocomplete
                    label="Zone"
                    name="zoneID"
                    style={{ display: "inline-flex" }}
                    options={zoneList}
                    className={classes.comboBox}
                    onChange={this.handleChangeZone}
                    value={country.zoneCode}
                    getOptionLabel={(option) => option.zoneCode}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Zone"
                        name="zoneID"
                        style={{ width: "100%" }}
                        margin="normal"
                        error={this.state.errors.zone}
                        helperText={
                          this.state.errors.zone ? this.state.errors.zone : ""
                        }
                      />
                    )}
                  />
                  <Autocomplete
                    label="DHL Zone"
                    name="dhlZoneID"
                    style={{ display: "inline-flex" }}
                    options={dhlZoneList}
                    className={classes.comboBox}
                    onChange={this.handleChangeDHL}
                    value={country.dhlZone}
                    getOptionLabel={(option) => option.dhlZoneName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // required
                        label="DHL Zone"
                        name="dhlZoneID"
                        style={{ width: "100%" }}
                        margin="normal"
                        error={this.state.errors.dhlZone}
                        helperText={
                          this.state.errors.dhlZone
                            ? this.state.errors.dhlZone
                            : ""
                        }
                      />
                    )}
                  />
                </div>
                <div>
                  <TextField
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    label="Alternate Country Code"
                    name="alternateCountryCode"
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={country.alternateCountryCode}
                    onChange={this.handleChange}
                    error={this.state.errors.alternateCountryCode}
                    helperText={
                      this.state.errors.alternateCountryCode
                        ? this.state.errors.alternateCountryCode
                        : ""
                    }
                  />
                  <TextField
                    label="Alternate Country Name"
                    name="alternateCountryName"
                    inputProps={{
                      maxLength: 1000,
                    }}
                    margin="normal"
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    autoComplete="off"
                    className={classes.textField}
                    value={country.alternateCountryName}
                    onChange={this.handleChange}
                    error={this.state.errors.alternateCountryName}
                    helperText={
                      this.state.errors.alternateCountryName
                        ? this.state.errors.alternateCountryName
                        : ""
                    }
                  />
                </div>

                <Divider style={{ marginTop: "25px" }} />
                <div className="button-wrapper">
                  <Button
                    variant="contained"
                    color="primary"
                    //disableElevation
                    style={{ boxShadow: "none" }}
                    onClick={(e) => this.props.history.push("/country")}
                  >
                    CANCEL
                  </Button>

                  <div style={{ marginLeft: "1em" }}>
                    {this.validateForm() ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddCountry));
