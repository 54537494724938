import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ReportViewer from "../../app/ReportViewer";
import {
  EditIcon,
  PrintIcon,
  CustomPrintIcon,
} from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import PropTypes from "prop-types";
import {
  getDeliveryNote,
  getDeliveryNoteDetails,
} from "../reportServiceAction";
import { StateContext } from "../../../shared/globalState";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { stableSort, getSorting } from "../../../shared/tableCommonFunctions";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import moment from "moment"
import { printDetails } from "../../../shared/tableCommonFunctions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { selectedDeliveryNoteId, status } = props;
  let isActive = !(status === "Item Delivered" || status === "Cancelled");
  let acnArr = props.roles.filter((u) => u.menuName === "Delivery Note");
  let acnObj = acnArr[0];

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              {isActive ? (
                <Link to={`/edit_deliveryNote/${selectedDeliveryNoteId}`}  >
                  <IconButton aria-label="edit" className={classes.iconHover}>
                    <EditIcon />
                  </IconButton>
                </Link>
              ) : (
                  <IconButton aria-label="edit" disabled={!isActive} className={classes.iconHover}>
                    <EditIcon />
                  </IconButton>
                )}
            </Tooltip>
          )}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"

            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                style={{ padding: "5px", paddingTop: "12px", width: "47px", paddingLeft: "10px" }}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};
const EnhancedTableToolbar1 = (props) => {
  return <div></div>;
};

function createData(
  stockDescription,
  noOfPts,
  totalPackage,
  noOfCopies,
  totalQuantity,
  weightPerCortanBox,
  netWeight
) {
  return {
    stockDescription,
    noOfPts,
    totalPackage,
    noOfCopies,
    totalQuantity,
    weightPerCortanBox,
    netWeight,
  };
}
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

class ViewDeliveryNote extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      deliveryNote: {
        deliveryNoteNo: "",
        clientname: "",
        workOrderNo: "",
        title: "",
        jobGroupNumber: "",
        shipmentType: "",
        isUrgent: false,
        deliverFromAddress: "",
        deliveryNoteAddress2: "",
        deliveryNoteAddress3: "",
        description: "",
        contactPersonName: "",
        contactNumber: "",
        zipCode: "",
        country: "",
        deliveryToAddress: "",
        totalQuantity: "",
        status: "",
        deliveryDate: "",
        deliveryTime: "",
        agentCode: "",
        driverName: "",
        remarks: "",
      },
      deliveryNoteDetails: [],
      page: 0,
      rowsPerPage: 100,
      refreshData: false,
      loading: false,
      order: "desc",
      orderBy: "modifiedDate",
      headCells: [
        {
          id: 1,
          code: "lineNo",
          numeric: false,
          disablePadding: true,
          label: "Line No",
          show: true,
        },
        {
          id: 2,
          code: "stockDescription",
          numeric: false,
          disablePadding: true,
          label: "Description",
          show: true,
        },
        {
          id: 3,
          code: "noOfPts",
          numeric: false,
          disablePadding: false,
          label: "No Of Plts",
          show: true,
        },
        {
          id: 4,
          code: "totalPackage",
          numeric: false,
          disablePadding: false,
          label: "Total Packages",
          show: true,
        },
        {
          id: 5,
          code: "noOfCopies",
          numeric: false,
          disablePadding: true,
          label: "No of Cps/Pk",
          show: true,
        },
        {
          id: 6,
          code: "totalQuantity",
          numeric: false,
          disablePadding: true,
          label: "Qty",
          show: true,
        },
        {
          id: 7,
          code: "weightPerCortanBox",
          numeric: false,
          disablePadding: true,
          label: "Wt / Carton Box(Kg)",
          show: true,
        },
        {
          id: 8,
          code: "netWeight",
          numeric: false,
          disablePadding: true,
          label: "Net Wt(Kg)",
          show: true,
        },
      ],
      showPopup: false,
    };
  }

  handleShowPopup = () => {
    this.setState({
      showPopup: true,
    });
  };

  handleHidePopup = () => {
    this.setState({
      showPopup: false,
    });
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };
  componentDidMount() {
    const dispatch = this.context[1];
    let deliveryNoteID = this.props.match.params.deliveryNoteId;
    this.props.handleSettingHeader("Delivery Note");
    this.props.setSelectedList("genericSystemOpen", "deliveryNote");
    getDeliveryNote(deliveryNoteID).then((r) => {
      dispatch({
        type: "getDeliveryNote",
        newStore: r,
      });
      this.setState({ deliveryNote: r });
    });
    getDeliveryNoteDetails(deliveryNoteID).then((r) => {
      dispatch({
        type: "getDeliveryNoteDetails",
        newStore: r.data,
      });
      this.getAllDeliveryNoteDetails(r.data);
    });
  }
  getAllDeliveryNoteDetails = (deliveryNoteDetail) => {
    let list = deliveryNoteDetail;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return d.isActive
          ? rows.push(
            createData(
              d.stockDescription,
              d.noOfPts,
              d.totalPackage,
              d.noOfCopies,
              d.totalQuantity,
              d.weightPerCortanBox &&
              this.addZeroes(d.weightPerCortanBox.toString(), 3),
              d.netWeight && this.addZeroes(d.netWeight.toString(), 3)
            )
          )
          : d;
      });
    this.setState({ deliveryNoteDetails: rows });
  };
  addZeroes(num, decimalPlaces) {
    var value = Number(num);
    var res = num.split(".");
    if (res.length === 1 || res[1].length < decimalPlaces) {
      value = value.toFixed(decimalPlaces);
    }
    if (value !== "") {
      value = String(value);
    }
    return value;
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  render() {
    let {
      deliveryNote,
      deliveryNoteDetails,
      page,
      rowsPerPage,
      order,
      orderBy,
      headCells,
    } = this.state;
    let deliveryNoteID = this.props.match.params.deliveryNoteId;

    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selectedDeliveryNoteId={deliveryNoteID}
            status={deliveryNote.status}
            roles={this.props.roles}
          />
          <Divider />
          {/* <div style={{ overflow: "auto", maxHeight: "650px" }} id="printable"> */}
          <div>
            <div
              className="view-page-container"
              style={{ overflow: "auto" }}
              id="printable"
            >
              <div className="view-page-headings">
                Delivery Note Information
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Delivery Note Number</div>
                  <div className="view-page-value">
                    {deliveryNote.deliveryNoteNo}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Total Quantity</div>
                  <div className="view-page-value">
                    {deliveryNote.totalQuantity}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Customer Name</div>
                  <div className="view-page-value">
                    {deliveryNote.clientname}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Work Order Number</div>
                  <div className="view-page-value">
                    {deliveryNote.workOrderNo}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Title</div>
                  <div className="view-page-value">{deliveryNote.title}</div>
                </div>
                {/* <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Job Group Number</div>
                  <div className="view-page-value">
                    {deliveryNote.jobGroupNumber}
                  </div>
                </div> */}
              </div>
              <div className="view-page-headings">From Address</div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container1">
                  <div className="view-page-label">Deliver From Address</div>
                  <div className="view-page-value">
                    {deliveryNote.deliverFromAddress}
                  </div>
                </div>
              </div>
              <div className="view-page-headings">To Address</div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container1">
                  <div className="view-page-label">Company</div>
                  <div className="view-page-value">
                    {deliveryNote.description}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Deliver To Address</div>
                  <div className="view-page-value">
                    {deliveryNote.deliveryToAddress}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Deliver To Address 2</div>
                  <div className="view-page-value">
                    {deliveryNote.deliveryNoteAddress2}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Deliver To Address 3</div>
                  <div className="view-page-value">
                    {deliveryNote.deliveryNoteAddress3}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Zip Code</div>
                  <div className="view-page-value">{deliveryNote.zipCode}</div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Country</div>
                  <div className="view-page-value">{deliveryNote.country}</div>
                </div>
              </div>
              <div className="view-page-headings">Contact Info</div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Contact Person Name</div>
                  <div className="view-page-value">
                    {deliveryNote.contactPersonName}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Contact Number</div>
                  <div className="view-page-value">
                    {deliveryNote.contactNumber}
                  </div>
                </div>
              </div>
              <div className="view-page-headings">Delivery Details</div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Delivery Date</div>
                  <div className="view-page-value">
                    {/* {deliveryNote.deliveryDate} */}
                    {moment(deliveryNote.deliveryDate).format("DD/MM/YYYY")}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Delivery Time</div>
                  <div className="view-page-value">
                    {/* {deliveryNote.deliveryTime} */}
                    {deliveryNote.deliveryTime ? moment(deliveryNote.deliveryTime, 'h:mm A').format("hh:mm A") : ""}

                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Priority</div>
                  <div className="view-page-value">
                    {deliveryNote.isUrgent ? "Urgent" : "Normal"}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Agent Code</div>
                  <div className="view-page-value">
                    {deliveryNote.agentCode}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Driver Name</div>
                  <div className="view-page-value">
                    {deliveryNote.driverName}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Shipment Type</div>
                  <div className="view-page-value">
                    {deliveryNote.shipmentType}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container1">
                  <div className="view-page-label">Special Instruction</div>
                  <div className="view-page-value">
                    {deliveryNote.remarks && deliveryNote.remarks}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Status</div>
                  <div className="view-page-value">
                    {deliveryNote.status && deliveryNote.status}
                  </div>
                </div>
              </div>
              <div className="view-page-headings">Delivery Items</div>
              <div
                style={{
                  // width: "100%",
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
                className="view-page-contents-container"
              >
                <EnhancedTableToolbar1
                  history={this.props.history}
                  headCells={headCells}
                  deliveryNoteDetails={deliveryNoteDetails}
                />
                <Table
                  stickyHeader
                  aria-labelledby="tableTitle"
                  size="small"
                  aria-label="enhanced table"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={deliveryNoteDetails.length}
                    headCells={this.state.headCells}
                  />
                  <TableBody style={{ overflow: "hidden" }}>
                    {deliveryNoteDetails.length > 0 ? (
                      stableSort(
                        deliveryNoteDetails,
                        getSorting(order, orderBy)
                      )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={row.deliveryNoteID}
                              style={{ cursor: "pointer" }}
                            >
                              {this.state.headCells[0].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {index + 1}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[1].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="left"
                                  style={{
                                    maxWidth: "128px",
                                    fontFamily: "Roboto",
                                    fontSize: "14px",
                                    paddingLeft: "0.5em",
                                    borderRight: "1px solid #eaeaea",
                                    whiteSpace: "normal",
                                  }}
                                //className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockDescription}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[2].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.noOfPts}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[3].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.totalPackage}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[4].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.noOfCopies}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[5].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.totalQuantity}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[6].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.weightPerCortanBox}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[7].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.netWeight}
                                  </Typography>
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })
                    ) : (
                        <TableRow>
                          {this.state.loading ? (
                            <TableCell
                              colSpan={8}
                              style={{ textAlign: "center" }}
                            >
                              <LinearProgress />
                            </TableCell>
                          ) : (
                              <TableCell
                                colSpan={8}
                                style={{ textAlign: "center" }}
                              >
                                No result found
                              </TableCell>
                            )}
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </div>
              {/* <TablePagination
                rowsPerPageOptions={[100, 200, 300]}
                component="div"
                count={deliveryNoteDetails.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "previous page",
                }}
                nextIconButtonProps={{
                  "aria-label": "next page",
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              /> */}
              <Divider />
              <div className="button-wrapper">
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={(e) => this.props.history.push("/deliveryNote")}
                  >
                    CANCEL
                  </Button>
                </div>
                <div style={{ marginLeft: "1em" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={() => this.handleShowPopup()}
                  >
                    <IconButton
                      aria-label="print"
                      size="small"
                      style={{ color: "#fff" }}
                    >
                      <CustomPrintIcon style={{ color: "#fff" }} />
                    </IconButton>
                    {"PRINT DELIVERY NOTE"}
                  </Button>
                </div>
                {this.state.showPopup && (
                  <ReportViewer
                    url={
                      `${window.localStorage.ReportURLByID}` +
                      "DeliveryNote&IsDirectPrint=True&ReportParam=DeliveryNoteID&Param1=" +
                      `${this.props.match.params.deliveryNoteId}`
                    }
                    showPopup={this.state.showPopup}
                    title={"Delivery Note"}
                    onClose={() => this.handleHidePopup()}
                    handleHidePopup={() => this.handleHidePopup()}
                  />
                )}
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewDeliveryNote);
