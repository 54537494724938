const $ = window.$
// import $ from "./testFlexiCol"
export var tbFunc = function () {
    var startX,
        startWidth,
        $handle,
        $table,
        pressed = false;
    $(document).on({
        mousemove: function (event) {
            if (pressed) {
                $handle.width(startWidth + (event.pageX - startX));
                let sWid = $handle.width(startWidth + (event.pageX - startX))[0].style.width && $handle.width(startWidth + (event.pageX - startX))[0].style.width.slice(0, $handle.width(startWidth + (event.pageX - startX))[0].style.width.length - 2)
                $handle.width(startWidth + (event.pageX - startX))[0].style.minWidth =
                    +sWid > 60 ? $handle.width(startWidth + (event.pageX - startX))[0].style.width : "40px"
            }
        },
        mouseup: function () {
            if (pressed) {
                $table.removeClass('resizing');
                pressed = false;
            }
        }
    }).on('mousedown', '.MuiTable-root .MuiTableCell-head', function (event) {
        $handle = $(this);
        pressed = true;
        startX = event.pageX;
        startWidth = $handle.width();

        $table = $handle.closest('.MuiTable-root').addClass('resizing');
    }).on('dblclick', '.MuiTable-root thead', function () {
        // Reset column sizes on double click
        $(this).find('th[style]').css('width', '');
        $(this).find('th[style]').css('min-width', '');
    })
}