import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { printDetails, addZeros } from "../../../shared/tableCommonFunctions";
import { Divider } from "@material-ui/core";

import { getRoutingCountry } from "../routingActions";
import { StateContext } from "../../../shared/globalState";

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { selectedroutingCountryID } = props;
  let acnArr = props.roles.filter(u => u.menuName === "Routing By Country");
  let acnObj = acnArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%"
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_RoutingCountry/${selectedroutingCountryID}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={e => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewRoutingCountry extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      routingCountry: {
        customer: {},
        location: {},
        country: {},
        dispatchType: {},
        dispatchMode: {},
        mailingScheme: {},
        distributor: {},
        customerName: "",
        locationName: "",
        countryName: "",
        noOfOrdersFrom: 0,
        noOfOrdersTo: 0,
        noOfCopiesFrom: 0,
        noOfCopiesTo: 0,
        weightFrom: 0.0,
        weightTo: 0.0,
        ppiNumber: "",
        ppiLicenseNo: "",
        dispatchTypeCodeName: "",
        dispatchTypeID: "",
        dispatchTypeCode: "",
        dispatchModeName: "",
        dispatchModeID: "",
        dispatchModeCode: "",
        mailingSchemeName: "",
        mailingSchemeID: "",
        distributorName: "",
        distributorCode: "",
        returnAddress: "",
        carrierSheetScheme: "",
        carrierSheetMode: ""
      }
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let routingCountryID = this.props.match.params.routingCountryID;

    getRoutingCountry(routingCountryID).then(r => {
      dispatch({
        type: "getRoutingCountry",
        newStore: r
      });
      this.setState({ routingCountry: r });
    });
  }

  render() {
    let { routingCountry } = this.state;
    let routingCountryID = this.props.match.params.routingCountryID;
    let { status } = "";
    if (routingCountry.isActive === true) {
      status = "Yes";
    } else {
      status = "No";
    }
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selectedroutingCountryID={routingCountryID}
            roles={this.props.roles}
          />
          {/* <div className="view-page-header">Cost Per Title Information</div> */}
          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">Country Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Customer Name</div>
                <div className="view-page-value">
                  {routingCountry.customer.customerName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Location Name</div>
                <div className="view-page-value">
                  {routingCountry.location.locationName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Country Code</div>
                <div className="view-page-value">
                  {routingCountry.country.countryCode}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Country Name</div>
                <div className="view-page-value">
                  {routingCountry.country.countryName}
                </div>
              </div>
            </div>
            <div className="view-page-headings">Criteria Setting</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No Of Orders From</div>
                <div className="view-page-value">
                  {routingCountry.noOfOrdersFrom}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No Of Orders To</div>
                <div className="view-page-value">
                  {routingCountry.noOfOrdersTo}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No Of Copies From</div>
                <div className="view-page-value">
                  {routingCountry.noOfCopiesFrom}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No Of Copies To</div>
                <div className="view-page-value">
                  {routingCountry.noOfCopiesTo}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Weight From</div>
                <div className="view-page-value">
                  {addZeros(routingCountry.weightFrom.toString(), 2)}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Weight To</div>
                <div className="view-page-value">
                  {addZeros(routingCountry.weightTo.toString(), 2)}
                </div>
              </div>
            </div>
            <div className="view-page-headings">Routing Configuration</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Route PPI</div>
                <div className="view-page-value">
                  {routingCountry.ppiNumber}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">PPI LicenseNo</div>
                <div className="view-page-value">
                  {routingCountry.ppiLicenseNo}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Dispatch Type</div>
                <div className="view-page-value">
                  {routingCountry.dispatchType.dispatchTypeCodeName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Dispatch Mode</div>
                <div className="view-page-value">
                  {routingCountry.dispatchMode &&
                    routingCountry.dispatchMode.dispatchModeName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Mailing Scheme</div>
                <div className="view-page-value">
                  {routingCountry.mailingScheme &&
                    routingCountry.mailingScheme.mailingScheme}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Distributor</div>
                <div className="view-page-value">
                  {routingCountry.distributor &&
                    routingCountry.distributor.distributorName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Return Address</div>
                <div className="view-page-value">
                  {routingCountry.returnAddress}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Carrier Sheet Mode</div>
                <div className="view-page-value">
                  {routingCountry.carrierSheetMode}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Carrier Sheet Scheme</div>
                <div className="view-page-value">
                  {routingCountry.carrierSheetScheme}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Active</div>
                <div className="view-page-value">{status}</div>
              </div>
            </div>
            <Divider style={{ marginTop: "25px" }} />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={e => this.props.history.push("/routingCountry")}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewRoutingCountry);
