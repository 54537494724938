import React, { Component } from "react";
export default class NotFound extends Component {
    createMarkup = () => {
        return {
            __html: "&#128560"
        };
    };
    render() {
        return (
            <div style={{ width: "100%", height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ fontWeight: "400," }}>
                    <span dangerouslySetInnerHTML={this.createMarkup()} style={{ marginRight: "5px" }}></span>
                    Requested page is not available !
               </div>
            </div>
        );
    }
}
