const USER_LIST = 'USER_LIST';
const ROLE_LIST = 'ROLE_LIST';
const ROLEACTION_LIST = 'ROLEACTION_LIST';
const SUPERMENU_LIST = 'SUPERMENU_LIST';
const MENU_LIST = 'MENU_LIST';
const SUBMENU_LIST = 'SUBMENU_LIST';
export default {
    ROLE_LIST,
    SUPERMENU_LIST,
    MENU_LIST,
    SUBMENU_LIST,
    ROLEACTION_LIST,
    USER_LIST,
}