import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_PUBLISHER_DOCKET_BAGGING;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getDocInfo = async (id) => {
  let data = [];
  await pgsService
    .get("/GetDocketInfo/" + id)
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};
export const getDocHeaderForView = async (id) => {
  let data = [];
  await pgsService
    .get("/GetDocketHeader/" + id)
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};
export const getDocDetailsForView = async (id) => {
  let data = [];
  await pgsService
    .get("/GetDocketDetail/" + id)
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};
export const updateDocketPublisher = async (id, reqBody, tOA, history) => {
  let data = [];
  // tOA = tOA.toString()
  let url = encodeURIComponent(tOA) + "/" + id

  await pgsService
    .put(`/UpdateDocketDetails/${url}`, reqBody)
    .then((r) => {
      data = r;
      toastr.success("Docket detalis updated successfully");
      history.goBack();
    })
    .catch((e) => { });
  return data;
};
// export const generateLocalEDocket = async (ids, mode) => {
//   let data = [];
//   let x = false
//   if (mode === "auto")
//     x = false
//   if (mode === "manual")
//     x = true
//   await pgsService
//     .post(`/CreateLocalDocket/${ids}/${x}`)
//     .then((r) => {
//       data = r;
//       //toastr.success("E Docket generated successfully")
//     })
//     .catch((e) => { });
//   return data;
// };
// export const generateInternationalEDocket = async (ids, mode) => {
//   let data = [];
//   let x = false
//   if (mode === "auto")
//     x = false
//   if (mode === "manual")
//     x = true
//   await pgsService
//     .post(`/CreateInternationalDocket/${ids}/${x}`)
//     .then((r) => {
//       // toastr.success("E Docket generated successfully")
//       data = r;
//     })
//     .catch((e) => { });
//   return data;
// };
export const generateLocalEDocket = async (ids, mode, ldgDate, ldgTime) => {
  let data = [];
  let x = false
  if (mode === "auto")
    x = false
  if (mode === "manual")
    x = true
  let reqBody = {}
  reqBody.docketIds = ids
  reqBody.isManual = x
  reqBody.estimatedLodgementDate = ldgDate
  reqBody.estimatedLodgementTime = ldgTime
  await pgsService
    .post(`/CreateLocalDocket`, reqBody)
    .then((r) => {
      data = r;
      //toastr.success("E Docket generated successfully")
    })
    .catch((e) => { });
  return data;
};
export const generateInternationalEDocket = async (ids, mode, ldgDate, ldgTime) => {
  let data = [];
  let x = false
  if (mode === "auto")
    x = false
  if (mode === "manual")
    x = true
  let reqBody = {}
  reqBody.docketIds = ids
  reqBody.isManual = x
  reqBody.estimatedLodgementDate = ldgDate
  reqBody.estimatedLodgementTime = ldgTime
  await pgsService
    .post(`/CreateInternationalDocket`, reqBody)
    .then((r) => {
      // toastr.success("E Docket generated successfully")
      data = r;
    })
    .catch((e) => { });
  return data;
};

export const getDocketBaggingList = async () => {
  let jimList = [];
  await pgsService
    .get("/GetBLBPHeader")
    .then((r) => {
      jimList = r;
    })
    .catch((e) => { });
  return jimList;
};
export const getEdsErrorDetails = async (docketId) => {
  let getDocketList = "";
  await pgsService
    .get("/GetEDSErrorForView/" + docketId)
    .then((response) => {
      getDocketList = response.data;
    })
    .catch((e) => { });
  return getDocketList;
};
export const getViewDetailsForMainLabelDocketBagging = async (id) => {
  let data = [];
  await pgsService
    .get("/GetDoketBaggingByBatchHeaderID/" + id)
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};

// export const postDocketBaggingList = async (reqBody, history, id) => {
//   let jimList = [];
//   await pgsService
//     .post("/DBSave", reqBody)
//     .then((r) => {
//       toastr.success("Docket created successfully !");
//       history.push("/edit_pubMainLabelDocketBagging/" + id);
//     })
//     .catch((e) => {
//       toastr.error("Something went wrong !");
//     });
// };
export const postDocketBaggingList = async (reqBody, history, id, uId) => {
  let jimList = [];
  await pgsService
    .post(`/DBSave`, reqBody)
    .then((r) => {
      toastr.success("Docket Updated Successfully !");
      history.push("/edit_pubBackLabelDocketBagging/" + id);
    })
    .catch((e) => {
      toastr.error("Something went wrong !");
    });
};
export const getMailSchemeForDocketBagging = async (id) => {
  let data = [];
  await pgsService
    .get("/GetDBMailingSchemeDropdown/" + id)
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};

// export const getDestinationForDocketBagging = async (id) => {
//   let data = [];
//   await pgsService
//     .get("/GetDBDestinationDropdown/" + id)
//     .then((r) => {
//       data = r;
//     })
//     .catch((e) => { });
//   return data;
// };
export const getDestinationForDocketBagging = async (id, msId) => {
  let data = [];
  await pgsService
    .get(`/GetDBDestinationDropdown/${id}/${msId}`)
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};
export const getDocketBaggingHeaderInfo = async (id) => {
  let data = [];
  await pgsService
    .get("/GetDoketBaggingHeaderInfo/" + id)
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};
export const searchPubMLDB = async (rBody) => {
  let pmlopList = [];
  await pgsService
    .post("/BLDPAdvanceSerach", rBody)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
