import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { StateContext } from "../../../shared/globalState";
import { Autocomplete } from "@material-ui/lab";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { resourceValidation } from "../../../shared/resource";
import { addZeros } from "../../../shared/tableCommonFunctions";
import {
  getCustomerList,
  getUOMIMList,
  getStockCategory1,
} from "../../masters/masterActions";
import { addStyles } from "../../../shared/styles";
import { getItemMasterCode, updateItemMaster } from "../genericActions";
const checkstockcode = new Validator("checkstockcode");
const checkrate = new Validator("checkdecimalpoint");
const Reorderqty = new Validator("Reorderqty");
const Alphanumeric = new Validator("Alphanumeric");
const checklettershopname = new Validator("checklettershopname");
const checklettershopname1 = new Validator("checklettershopname1");
const generalValidator = new Validator("general");

class EditItemMaster extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      ItemMaster: {
        customer: "",
        customerName: "",
        customerCode: "",
        customerID: "",
        stockCode: "",
        stockName: "",
        uom: "",
        uomid: "",
        uomName: "",
        uomCode: "",
        StockCategory: "",
        stockCategoryID: "",
        stockCategoryName: "",
        stockCategoryCode: "",
        reOrderLevelQty: "",
        price: 0,
        cost: 0,
        isConsumable: false,
        remarks: "",
        dimension: "",
        modifiedBy: localStorage.loggedInId,
        isActive: true,
        weightPerItem: "0.000",
      },
      updatedValues: false,
      errors: {},
      active: false,
      selectedDDL: {},
      selectedStockCategory: {},
      StockNameValid: true,
      UOMIdValid: true,
      StockCategoryValid: true,
      ReOrderlevelQtyValid: true,
      PriceValid: true,
      CostValid: true,
      RemarksValid: true,
      WeightValid: true,
      DimensionValid: true,
      customersList: [],
      UOMList: [],
      StockCategoryList1: [],
      formValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.handleSettingHeader("Item Master");
    getCustomerList().then((r) => {
      r &&
        r.data &&
        r.data &&
        this.setState({
          customersList:
            r &&
            r.data &&
            r.data.sort((a, b) =>
              a.customerName.toLowerCase() > b.customerName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });

    getUOMIMList().then((r) => {
      this.setState({
        selectedDDL:
          r &&
          r.data &&
          r.data.find((x) => x.uomid === this.state.ItemMaster.uomid),
      });
      this.setState({
        UOMList:
          r &&
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });

    getStockCategory1().then((r) => {
      this.setState({
        selectedStockCategory:
          r &&
          r.data &&
          r.data.find(
            (x) => x.stockCategoryID === this.state.ItemMaster.stockCategoryID
          ),
      });

      this.setState({
        StockCategoryList1: r.data.sort((a, b) =>
          a.stockCategoryName.toLowerCase() > b.stockCategoryName.toLowerCase()
            ? 1
            : -1
        ),
      });
    });

    let StockID = this.props.match.params.StockID;
    getItemMasterCode(StockID).then((r) => {
      let data = r;
      data = {
        StockID: data.StockID,
        stockCode: data.stockCode,
        stockName: data.stockName,
        customerID: data.customerID,
        customerName: data.customerName,
        stockCategoryCode: data.stockCategoryCode,
        stockCategoryID: data.stockCategoryID,
        stockCategoryName: data.stockCategoryName,
        reOrderLevelQty: data.reOrderLevelQty,
        price: data.price,
        uomid: data.uomid,
        uom: { id: data.uomid, text: data.uomName, value: data.uomCode },
        cost: data.cost && addZeros(data.cost.toString(), 2),
        weightPerItem:
          data.weightPerItem && addZeros(data.weightPerItem.toString(), 3),
        isActive: data.isActive,
        customer: data.customer,
        stockCategory: data.stockCategory,
        modifiedBy: data.modifiedBy,
        isConsumable: data.isConsumable,
        remarks: data.remarks,
        dimension: data.dimension,
      };

      this.setState({ ItemMaster: data });
      if (r.isActive) this.setState({ active: r.isActive });
      this.validateField("StockCode", r.stockCode);
      this.validateField("StockName", r.stockName);
      this.validateField("Customer", r.customerID);
      this.validateField("stockCategoryID", r.stockCategoryID);
      this.validateField("stockCategoryName", r.stockCategoryName);
      this.validateField(
        "reOrderLevelQty",
        r.reOrderLevelQty && r.reOrderLevelQty.toString()
      );
      this.validateField("Price", r.price);
      this.validateField("UOM", r.uomid);
      this.validateField("Cost", r.cost);
    });
  }

  onBlur = (event, decimalPlaces) => {
    const { ItemMaster } = this.state;
    let weight = event.target.value;
    weight = this.addZeroes(weight.toString(), decimalPlaces);
    ItemMaster[event.target.name] = weight;
    this.setState({ ItemMaster });
  };

  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }
  checkDHlWeightDec1(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  handleChange = (event) => {
    this.setState({ updatedValues: true });
    const { ItemMaster } = this.state;

    if (
      event.target.name === "isActive" ||
      event.target.name === "isConsumable"
    ) {
      ItemMaster[event.target.name] = event.target.checked;
    } else {
      ItemMaster[event.target.name] = event.target.value;
    }

    this.validateField(event.target.name, event.target.value);
    this.setState({ ItemMaster });
  };
  handleweight = (event) => {
    this.setState({ updatedValues: true });

    const { ItemMaster } = this.state;
    let weightValues = event.target.value;
    weightValues = this.checkDHlWeightDec(event.target.value.toString());

    ItemMaster[event.target.name] = weightValues;
    this.validateField(event.target.name, weightValues);
    this.setState({ ItemMaster });
  };
  handleweight1 = (event) => {
    this.setState({ updatedValues: true });
    const { ItemMaster } = this.state;
    let weightValues = event.target.value;
    weightValues = this.checkDHlWeightDec1(event.target.value.toString());

    ItemMaster[event.target.name] = weightValues;
    this.validateField(event.target.name, weightValues);
    this.setState({ ItemMaster });
  };

  handleCustChange = (event, values) => {
    const { ItemMaster } = this.state;

    this.setState({ updatedValues: true });
    //this.setState({ItemMaster, customersList: []})
    let CustomerId = values && values.customerID;
    ItemMaster["Customer"] = values;
    ItemMaster["CustomerId"] = CustomerId;
    ItemMaster["IsVendor"] = values && values.isVendor;
    if (ItemMaster["IsVendor"] === true) {
      ItemMaster["isConsumable"] = true;
    } else {
      ItemMaster["isConsumable"] = false;
    }
    this.validateField("Customer", CustomerId);
    this.setState({ ItemMaster });
  };

  handleUOMChange = (event, values) => {
    console.log(values);
    this.setState({ updatedValues: true });
    const { ItemMaster } = this.state;
    let selectedDDL = this.state.selectedDDL;
    if (!values) {
      ItemMaster["uom"] = "null";
      ItemMaster["uomid"] = "";
      selectedDDL = -1;
      this.validateField("uomId", "");
    } else {
      selectedDDL = values;
      ItemMaster["uom"] = values;
      let uomid = values.id;
      // selectedDDL = uomId;
      ItemMaster["uomid"] = uomid;
      this.validateField("uomId", uomid);
    }

    this.setState({ ItemMaster, selectedDDL });

    this.setState({ ItemMaster });
  };

  handleStockChange = (event, values) => {
    // selectedStockCategory
    this.setState({ updatedValues: true });
    let selectedStockCategory = this.state.selectedStockCategory;
    const { ItemMaster } = this.state;

    if (values === null) {
      ItemMaster["StockCategory"] = "null";
      ItemMaster["stockCategoryID"] = "";
      selectedStockCategory = -1;
    } else {
      selectedStockCategory = values;
      ItemMaster["StockCategory"] = values;
      let StockCategoryId = values.stockCategoryID;
      ItemMaster["stockCategoryID"] = StockCategoryId;
    }

    this.setState({ ItemMaster, selectedStockCategory });
    this.validateField("stockCategoryID", ItemMaster["stockCategoryID"]);
    this.setState({ ItemMaster });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let CustomerValid = this.state.CustomerValid;
    let StockCodeValid = this.state.StockCodeValid;
    let StockNameValid = this.state.StockNameValid;
    let UOMIdValid = this.state.UOMIdValid;

    let StockCategoryValid = this.state.StockCategoryValid;
    let ReOrderlevelQtyValid = this.state.ReOrderlevelQtyValid;
    let PriceValid = this.state.PriceValid;
    let CostValid = this.state.CostValid;
    let RemarksValid = this.state.RemarksValid;
    let WeightValid = this.state.WeightValid;
    let DimensionValid = this.state.DimensionValid;

    switch (fieldName) {
      case "StockCode":
        if (value !== "") {
          StockCodeValid = checkstockcode(value);
          fieldValidationErrors.StockCode = StockCodeValid
            ? ""
            : resourceValidation.onlyAlphanumeric;
        } else {
          StockCodeValid = false;
          fieldValidationErrors.StockCode = "";
        }

        break;
      case "stockName":
        if (value !== "") {
          StockNameValid = checklettershopname1(value);
          fieldValidationErrors.stockName = StockNameValid
            ? ""
            : resourceValidation.stockName;
        } else {
          StockNameValid = false;
          fieldValidationErrors.stockName = "";
        }

        break;
      case "reOrderLevelQty":
        if (value !== "") {
          ReOrderlevelQtyValid = Reorderqty(value);
          fieldValidationErrors.ReOrderlevelQty = ReOrderlevelQtyValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          ReOrderlevelQtyValid = false;
          fieldValidationErrors.ReOrderlevelQty = "";
        }

        break;
      case "Price":
        if (value !== "" && value !== 0) {
          PriceValid = checkrate(value);
          fieldValidationErrors.Price = PriceValid
            ? ""
            : resourceValidation.price;
        } else {
          PriceValid = false;
          fieldValidationErrors.Price = "";
        }

        break;
      case "Cost":
        if (value !== "" && value !== 0) {
          CostValid = checkrate(value);
          fieldValidationErrors.Cost = CostValid
            ? ""
            : resourceValidation.decimalpointby2;
        } else {
          CostValid = false;
          fieldValidationErrors.Cost = "";
        }

        break;
      case "Weight":
      case "weightPerItem":
        WeightValid = checkrate(value);
        fieldValidationErrors.Weight = WeightValid
          ? ""
          : resourceValidation.itemweight;
        break;
      case "dimension":
        DimensionValid = generalValidator(value);
        fieldValidationErrors.dimension = DimensionValid
          ? ""
          : // resourceValidation.onlyAlphabet;
            "Please enter valid dimension";
        break;
      case "Customer":
        if (value !== "") {
          CustomerValid = true;
          fieldValidationErrors.CustomerId = CustomerValid ? "" : "";
        } else {
          CustomerValid = false;
          fieldValidationErrors.CustomerId = CustomerValid
            ? ""
            : "Please select customer";
        }
        break;
      case "uomId":
        if (value !== "") {
          UOMIdValid = true;
          fieldValidationErrors.UOM = UOMIdValid ? "" : "";
        } else {
          UOMIdValid = false;
          fieldValidationErrors.UOM = UOMIdValid ? "" : "Please select UOM";
        }
        break;
      case "stockCategoryID":
        if (value !== "") {
          //this.state.StockCategoryList1.map(option =>

          //   option.stockCategoryID === this.state.ItemMaster.stockCategoryID
          //     ? (this.state.selectedStockCategory = loop1)
          //     : (loop1 = loop1 + 1)
          //);

          StockCategoryValid = true;
          fieldValidationErrors.StockCategory = StockCategoryValid ? "" : "";
        } else {
          StockCategoryValid = false;
          fieldValidationErrors.StockCategory = StockCategoryValid
            ? ""
            : "Please select stock category";
        }
        break;
      case "Remarks":
        //RemarksValid = checkremarks(value);
        RemarksValid = true;
        fieldValidationErrors.Remarks = RemarksValid
          ? ""
          : "Please enter remarks";

        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,

        CustomerValid: CustomerValid,
        StockCodeValid: StockCodeValid,
        StockNameValid: StockNameValid,
        UOMIdValid: UOMIdValid,

        StockCategoryValid: StockCategoryValid,
        ReOrderlevelQtyValid: ReOrderlevelQtyValid,
        PriceValid: PriceValid,
        CostValid: CostValid,
        RemarksValid: RemarksValid,
        WeightValid: WeightValid,
        DimensionValid: DimensionValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.state.updatedValues &&
      this.setState({
        formValid:
          this.state.ItemMaster.customerID !== 0 &&
          this.state.ItemMaster.customerID != null &&
          this.state.StockNameValid &&
          this.state.StockNameValid &&
          this.state.StockCategoryValid &&
          this.state.ReOrderlevelQtyValid &&
          // this.state.PriceValid &&
          // this.state.CostValid &&
          this.state.UOMIdValid,
      });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      // Customer:"",
      // StockCode: "",
      StockName: "",
      UOMId: "",
      stockCategoryID: "",
      ReOrderlevelQty: "",
      Price: "",
      Cost: "",
      // IConsummable:"",
      Remarks: "",
      Weight: "",
      dimension: "",
      modifiedBy: "",

      errors: {},
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let stockID =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];

    if (this.validateForm()) {
      const { ItemMaster } = this.state;
      let customerID = ItemMaster.customerID;
      let stockCode = ItemMaster.stockCode;
      let stockName = ItemMaster.stockName;
      let uomid = ItemMaster.uomid;
      let stockCategoryID = ItemMaster.stockCategoryID;
      let reOrderLevelQty = ItemMaster.reOrderLevelQty;
      let price = ItemMaster.price;
      let cost = ItemMaster.cost;
      let remarks = ItemMaster.remarks;
      let weightPerItem = ItemMaster.weightPerItem;
      let dimension = ItemMaster.dimension;
      let isActive = ItemMaster.isActive;
      let modifiedBy = ItemMaster.modifiedBy;
      let isConsumable = ItemMaster.isConsumable;
      let reqBody = {
        customerID,
        stockCode,
        stockName,
        uomid,
        stockCategoryID,
        reOrderLevelQty,
        price,
        cost,
        remarks,
        isActive,
        modifiedBy,
        weightPerItem,
        dimension,
        isConsumable,
      };

      reqBody.modifiedBy = localStorage.loggedInId;
      updateItemMaster(stockID, reqBody, this.props.history);
    }
  };
  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  render() {
    let { classes } = this.props;
    let {
      ItemMaster,
      UOMList,
      StockCategoryList1,
      selectedDDL,
      active,
      selectedStockCategory,
    } = this.state;

    let weight = ItemMaster && ItemMaster.weightPerItem;
    if (weight == null) {
      weight = 0.0;
    }

    // let loop = 0;
    // UOMList.map(option =>
    //   option.id === this.state.ItemMaster.uomid
    //     ? (selectedDDL = loop)
    //     : (loop = loop + 1)
    // );

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Item Master</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <TextField
                    required
                    label="Customer"
                    name="customer"
                    inputProps={{
                      maxLength: 30,
                    }}
                    margin="normal"
                    disabled
                    variant="filled"
                    className={classes.textField}
                    value={ItemMaster && ItemMaster.customerName}
                  />
                  <TextField
                    required
                    label="Stock Code"
                    name="StockCode"
                    inputProps={{
                      maxLength: 30,
                    }}
                    margin="normal"
                    disabled
                    variant="filled"
                    autoComplete="off"
                    className={classes.textField}
                    value={ItemMaster.stockCode}
                    error={this.state.errors.StockCode}
                    helperText={
                      this.state.errors.StockCode
                        ? this.state.errors.StockCode
                        : ""
                    }
                  />
                  <TextField
                    required
                    label="Stock Name"
                    name="stockName"
                    inputProps={{
                      maxLength: 50,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={ItemMaster.stockName}
                    onChange={this.handleChange}
                    error={this.state.errors.StockName}
                    helperText={
                      this.state.errors.StockName
                        ? this.state.errors.StockName
                        : ""
                    }
                    // onKeyPress={this._onKeyPress}
                  />

                  {/* )} */}
                  {/* <TextField
                    required
                    label="stockCategory"
                    name="stockCategory"
                    inputProps={{
                      maxLength: 30
                    }}
                    margin="normal"
                    disabled
                    variant="filled"
                    autoComplete="off"
                    className={classes.textField}
                    value={ItemMaster.stockCategoryName}
                    error={this.state.errors.stockCategory}
                    helperText={
                      this.state.errors.stockCategory
                        ? this.state.errors.stockCategory
                        : ""
                    }
                  /> */}
                </div>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    variant="filled"
                    id="stockCategory"
                    name="stockCategory"
                    options={StockCategoryList1}
                    className={classes.comboBox}
                    onChange={this.handleStockChange}
                    getOptionLabel={(option) => option.stockCategoryName}
                    value={selectedStockCategory}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Stock Category"
                        name="stockCategory"
                        id="stockCategory"
                        style={{ width: "100%" }}
                        margin="normal"
                        error={this.state.errors.stockCategory}
                        value={ItemMaster.stockCategoryName}
                        helperText={
                          this.state.errors.stockCategory
                            ? this.state.errors.stockCategory
                            : ""
                        }
                      ></TextField>
                    )}
                  />
                  <Autocomplete
                    options={UOMList}
                    className={classes.comboBox}
                    onChange={this.handleUOMChange}
                    getOptionLabel={(option) => option.text}
                    value={ItemMaster.uom}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="UOM"
                        value={ItemMaster.uom}
                        margin="normal"
                        style={{ width: "100%" }}
                        error={this.state.errors.UOMId}
                        helperText={
                          this.state.errors.UOMId ? this.state.errors.UOMId : ""
                        }
                      ></TextField>
                    )}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Cost"
                    name="cost"
                    margin="normal"
                    autoComplete="off"
                    // required
                    inputProps={{
                      maxLength: 10,
                    }}
                    className={classes.textField}
                    value={ItemMaster && ItemMaster.cost}
                    onChange={this.handleweight}
                    error={this.state.errors.Cost}
                    helperText={
                      this.state.errors.Cost ? this.state.errors.Cost : ""
                    }
                    onBlur={(e) => this.onBlur(e, 2)}
                    onKeyPress={this._onDecimalKeyPress}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$S</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Price"
                    name="price"
                    margin="normal"
                    autoComplete="off"
                    // required
                    inputProps={{
                      maxLength: 10,
                    }}
                    className={classes.textField}
                    value={ItemMaster && ItemMaster.price}
                    onChange={this.handleweight}
                    error={this.state.errors.Price}
                    helperText={
                      this.state.errors.Price ? this.state.errors.Price : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$S</InputAdornment>
                      ),
                    }}
                    onKeyPress={this._onDecimalKeyPress}
                  />
                  <TextField
                    label="Weight Per Item"
                    name="weightPerItem"
                    autoComplete="off"
                    margin="normal"
                    inputProps={{
                      maxLength: 10,
                    }}
                    className={classes.textField}
                    value={weight}
                    onChange={this.handleweight1}
                    error={this.state.errors.Weight}
                    helperText={
                      this.state.errors.Weight ? this.state.errors.Weight : ""
                    }
                    onBlur={(e) => this.onBlur(e, 3)}
                    onKeyPress={this._onDecimalKeyPress1}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Kg</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div>
                  <TextField
                    label="Dimension"
                    name="dimension"
                    margin="normal"
                    inputProps={{
                      maxLength: 100,
                    }}
                    autoComplete="off"
                    className={classes.textField}
                    value={ItemMaster.dimension}
                    onChange={this.handleChange}
                    error={this.state.errors.dimension}
                    helperText={
                      this.state.errors.dimension
                        ? resourceValidation.max100char
                        : ""
                    }
                    //onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    required
                    label="Reorder Level Qty"
                    name="reOrderLevelQty"
                    inputProps={{
                      maxLength: 5,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={ItemMaster && ItemMaster.reOrderLevelQty}
                    onChange={this.handleChange}
                    error={this.state.errors.ReOrderlevelQty}
                    helperText={
                      this.state.errors.ReOrderlevelQty
                        ? this.state.errors.ReOrderlevelQty
                        : ""
                    }
                    onKeyPress={this._onNumberKeyPress}
                  />
                  <FormControlLabel
                    className={classes.checkBox}
                    name="isConsumable"
                    margin="normal"
                    // style={{ marginTop: "25px", marginLeft: "7px" }}
                    control={
                      <Checkbox
                        // disabled
                        //className={classes.Checkbox}
                        checked={ItemMaster.isConsumable}
                        onChange={this.handleChange}
                        value={ItemMaster.isConsumable}
                        color="primary"
                      />
                    }
                    label="Consumable"
                  />
                  {
                    // active ? (
                    //   <React.Fragment />
                    // ) : (
                    <FormControlLabel
                      className={classes.checkBox}
                      name="isActive"
                      margin="normal"
                      //style={{ marginTop: "25px", marginLeft: "7px" }}
                      control={
                        <Checkbox
                          //className={classes.Checkbox}
                          checked={ItemMaster.isActive}
                          onChange={this.handleChange}
                          value={ItemMaster.isActive}
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  }
                </div>

                <div>
                  <TextField
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    label="Remarks"
                    name="remarks"
                    margin="normal"
                    autoComplete="off"
                    inputProps={{
                      maxLength: 500,
                    }}
                    className={classes.textField}
                    value={ItemMaster && ItemMaster.remarks}
                    onChange={this.handleChange}
                    error={this.state.errors.Remarks}
                    helperText={
                      this.state.errors.Remarks ? this.state.errors.Remarks : ""
                    }
                    //onKeyPress={this._onKeyPress}
                  />
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/ItemMaster")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditItemMaster));
