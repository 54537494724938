import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { StateContext } from "../../../shared/globalState";
import { styles } from "../../../shared/styles";
const notRequiredAlpNum10 = new Validator("notRequiredAlpNum10");
const notRequired100AllAllow = new Validator("notRequired100AllAllow");
const maxlength10Alphanumaric = new Validator("maxlength10Alphanumaric");

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        ></IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
class BackLabelBillingAdvanceSearch extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    billingAccount: "",
    invoiceNumber: "",
    shipmentNumber: "",
    modifiedDateFrom: null,
    modifiedDateTo: null,
  };

  componentDidMount() {
    const [{ store }, dispatch] = this.context;
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      open: false,
      billingAccount: "",
      invoiceNumber: "",
      shipmentNumber: "",
      modifiedDateFrom: null,
      modifiedDateTo: null,
    });
  };
  _onKeyPressNoOnly(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  onSearchClicked = (mode) => {
    let {
      billingAccount,
      shipmentNumber,
      invoiceNumber,
      modifiedDateFrom,
      modifiedDateTo,
    } = this.state;
    let reqBody = {};

    reqBody = {
      billingAccount,
      shipmentNumber,
      invoiceNumber,
      modifiedDateTo: modifiedDateTo || "",
      modifiedDateFrom: modifiedDateFrom || "",
    };
    this.props.onSearchClicked(reqBody);
    this.setState({
      open: false,
      billingAccount: "",
      shipmentNumber: "",
      invoiceNumber: "",
      modifiedDateFrom: null,
      modifiedDateTo: null,
    });
  };

  handleSearchInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  checkButtonDisability = () => {
    let {
      billingAccount,
      shipmentNumber,
      invoiceNumber,
      modifiedDateFrom,
      modifiedDateTo,
    } = this.state;
    if (
      !billingAccount &&
      !shipmentNumber &&
      !invoiceNumber &&
      !(modifiedDateFrom && modifiedDateTo)
    )
      return true;
    else return false;
  };

  handleDateChange = (date) => {
    let { modifiedDateFrom } = this.state;
    modifiedDateFrom = date && date.toLocaleDateString();
    this.setState({ modifiedDateFrom });
  };
  handleDateChange1 = (date) => {
    let { modifiedDateTo } = this.state;
    modifiedDateTo = date && date.toLocaleDateString();
    this.setState({ modifiedDateTo });
  };

  render() {
    let {
      status,
      statusList,
      errors,
      billingAccount,
      shipmentNumber,
      invoiceNumber,
    } = this.state;
    const { classes, ...other } = this.props;

    return (
      <React.Fragment>
        <div>
          <Tooltip title="Search">
            <IconButton
              aria-label="Search"
              className={classes.iconHover}
              onClick={this.handleClickOpen}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
            maxWidth="sm"
            fullWidth={true}
          >
            <DialogTitle id="customized-dialog-title">
              {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
              Advanced Search
            </DialogTitle>
            <DialogContent
              dividers
              id="preAlertID"
              style={{ paddingTop: "0px!important" }}
            >
              <div>
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      label="Billing Account"
                      name="billingAccount"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={billingAccount}
                      onChange={this.handleSearchInput}
                      margin="normal"
                    />
                    <TextField
                      label="Invoice No"
                      name="invoiceNumber"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={invoiceNumber}
                      onChange={this.handleSearchInput}
                      margin="normal"
                    />
                    <TextField
                      label="Shipment No"
                      name="shipmentNumber"
                      style={{ flex: 1 }}
                      value={shipmentNumber}
                      onChange={this.handleSearchInput}
                      margin="normal"
                    />
                  </div>
                  {/* <div style={{ display: "flex", alignItems: "center" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        id="modifiedDateFrom"
                        label="Modified From"
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        value={this.state.modifiedDateFrom}
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                      disabled={!this.state.modifiedDateFrom}
                        disableToolbar
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        id="modifiedDateTo"
                        label="Modified To"
                        value={this.state.modifiedDateTo}
                        onChange={this.handleDateChange1}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <div style={{ flexGrow: 1 }}></div>
                  </div> */}
                </div>
              </div>
            </DialogContent>
            <DialogActions style={{ padding: "10px 24px" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={this.handleClose}
              >
                CANCEL
              </Button>
              {this.checkButtonDisability("preAlert") ? (
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  SEARCH
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.onSearchClicked("preAlert")}
                >
                  SEARCH
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(BackLabelBillingAdvanceSearch));
