import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_PUBLISHER_ORDER_PLACEMENT;
const pgsService = axios.create({
  baseURL,
});
pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
export const getAllList = async () => {
  let data = [];
  await pgsService
    .get("/GetJournalDataAsync")
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};

export const getJournalData = async (reqBody) => {
  let JournalData = {};
  await pgsService
    .post("/JournalPickup", reqBody)
    .then((response) => {
      JournalData = response.data;
      console.log(response.data);
      // JournalData = {
      //   jobGroupID: data.jobGroupID,
      //   customerName: data.customerName,
      //   workOrderNumber: data.workOrderNumber,
      //   projectTitle: data.projectTitle,
      //   jobGroupNumber: data.jobGroupNumber,
      // };
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return JournalData;
};
