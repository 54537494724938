import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { useToolbarStyles } from "../../../shared/styles";

class BackLabelDocketPosting extends Component {
  state = {};
  // componentDidMount() {
  //   this.props.handleSettingHeader("Back Label Docket Posting");
  //   this.props.setSelectedList("publisherBackLabelOpen",
  //   "BackLabelDocketPosting");
  // }
  render() {
    // const classes = useToolbarStyles();
    return (
      // <React.Fragment>
      //   <Paper>
      //     <div className="pop-up-header">Back Label Docket Posting</div>
      //     <Divider />
      //     <div className={classes.container}>
      //       <div style={{ marginTop: "-15px" }}>
      //         <div className={classes.container}>
                <p></p>
      //         </div>
      //       </div>
      //     </div>
      //   </Paper>
      // </React.Fragment>
    );
  }
}


export default BackLabelDocketPosting;
