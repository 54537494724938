import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Tooltip, IconButton, Button } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import { useToolbarStyles } from "../../../shared/styles";
import TableCell from "@material-ui/core/TableCell";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { TextareaAutosize, Typography } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import { withRouter } from "react-router-dom";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";
import { StateContext } from "../../../shared/globalState";
import { Link } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  getOneJournalitemMasterList,
  getStagingJournalitemMasterList,
} from "./action";
import "../../app/App.css";
import {
  printDetails,
  pdfDownloadDetails,
} from "../../../shared/tableCommonFunctions";
import { EditIcon } from "../../../shared/customIcons";
const ViewHeader = (props) => {
  let acnArr = props.roles.filter((u) => u.menuName === "Journal Item Master");
  let acnObj = acnArr[0];
  const classes = useToolbarStyles();
  let orderId = "";
  if (process.browser) orderId = window.location.pathname.split("/")[2];
  return (
    <div className="view-page-header">
      {acnObj && acnObj.editAction && (
        <Link to={`/edit_journalItem/${orderId}`}>
          <IconButton aria-label="edit" className={classes.iconHover}>
            <EditIcon />
          </IconButton>
        </Link>
      )}
      {acnObj && acnObj.printAction && (
        <Tooltip title="Print" style={{ paddingTop: "12px" }}>
          <IconButton
            //aria-label="clear"
            className={classes.iconHover}
            onClick={(e) => printDetails()}
          >
            <PrintIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};
function StageModal(props) {
  const classes = useToolbarStyles();
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={props.open}
      onClose={props.handleChooseOFDialog}
      maxWidth="xl"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Stage View</DialogTitle>
      <DialogContent dividers>
        {/* <div style={{display:"flex"}}><span></span> <span>{props.journalDesc}</span></div> */}
        <div
        // style={{ margin: "0px 2em 0px 2em" }}
        >
          <Typography
            component="div"
            className="blueBox"
            style={{ padding: ".5em 0 .5em 0" }}
          >
            <div className="blueBoxLabel">
              <div className="blueBoxText">
                {props.issueDesc}
                {props.journalDesc ? ` (${props.journalDesc}) ` : ""}
              </div>
            </div>
          </Typography>
        </div>
        <div style={{ width: "100%", overflow: "auto" }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
            style={{ marginTop: "10px" }}
          >
            <TableHead>
              <TableRow style={{ borderTop: "1px solid #eaeaea" }}>
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className="table-content-cell"
                  style={{ color: "#306dca", minWidth: "40px" }}
                >
                  Sr No
                </TableCell>
                <TableCell
                  padding="default"
                  style={{ minWidth: "145px" }}
                  className="table-content-cell"
                >
                  {" "}
                  <span style={{ color: "#306dca" }}>Stage Date Time</span>{" "}
                </TableCell>
                <TableCell
                  className="table-content-cell"
                  padding="default"
                  style={{ color: "#306dca", minWidth: "120px" }}
                >
                  Action By
                </TableCell>
                <TableCell
                  padding="default"
                  style={{ minWidth: "145px" }}
                  className="table-content-cell"
                >
                  {" "}
                  <span style={{ color: "#306dca" }}>
                    Modified Date Time
                  </span>{" "}
                </TableCell>
                <TableCell
                  padding="default"
                  style={{ minWidth: "290px" }}
                  className="table-content-cell"
                >
                  {" "}
                  <span style={{ color: "#306dca" }}>
                    Task description
                  </span>{" "}
                </TableCell>{" "}
                <TableCell
                  padding="default"
                  style={{ color: "#306dca", minWidth: "72px" }}
                  className="table-content-cell"
                >
                  Completed
                </TableCell>{" "}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.list.length > 0 &&
                props.list.map((row, index) => {
                  // const isItemSelected = isSelected(index);
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={index}>
                      <TableCell
                        //component="th"
                        //scope="row"
                        padding="none"
                        className="table-content-cell"
                      >
                        {" "}
                        <Typography noWrap style={{ fontSize: "14px" }}>
                          {+index + 1}
                        </Typography>{" "}
                      </TableCell>
                      <TableCell className="table-content-cell">
                        {" "}
                        {row.stageDateTime
                          ? moment(row.stageDateTime).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                          : ""}
                      </TableCell>
                      <TableCell className="table-content-cell">
                        {" "}
                        <Typography noWrap style={{ fontSize: "14px" }}>
                          {row.actionBy}
                        </Typography>{" "}
                      </TableCell>
                      <TableCell className="table-content-cell">
                        {" "}
                        {row.dateTime
                          ? moment(row.dateTime).format("DD/MM/YYYY HH:mm:ss")
                          : ""}
                      </TableCell>
                      <TableCell className="table-content-cell">
                        {row.taskDescription}
                      </TableCell>
                      <TableCell className="table-content-cell">
                        {row.completed ? "Yes" : "No"}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "10px 24px" }}>
        <Button
          variant="contained"
          color="primary"
          style={{ boxShadow: "none" }}
          onClick={props.handleStageOpen}
        >
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
}
class ViewJournalItemMaster extends Component {
  state = {
    selectedOrder: {},
    stateFiles: [],
    responseData: {},
    loading: false,
    open: false,
    stageList: [],
  };
  componentDidMount() {
    this.props.handleSettingHeader("Journal Item Master");
    this.props.setSelectedList(
      "publisherMainLabelOpen",
      "pubJournalItemMaster"
    );
    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    let newResData = {};
    this.setState({ loading: true });
    getOneJournalitemMasterList(orderId).then((r) => {
      newResData.customer = r.data.customerName;
      newResData.office = r.data.office;
      newResData.acronym = r.data.acronym;
      newResData.volume = r.data.volume;
      newResData.issue = r.data.issue;
      newResData.suppNumber = r.data.suppNumber;
      newResData.partNumber = r.data.partNumber;
      newResData.expectedDeliveryDate = r.data.expectedDeliveryDate;
      newResData.issueDesc = r.data.issueDescription;
      newResData.detailDescription = r.data.detailDescription;
      newResData.Remarks = r.data.remarks;
      newResData.location = r.data.locationName;
      newResData.journalDesc = r.data.journalDescription;
      newResData.productType = r.data.productType;
      newResData.uniqueIdentifier = r.data.journalUniqueIdentifier;
      newResData.insert = r.data.insert;
      newResData.specialInstruction = r.data.specialInstruction;
      newResData.hcCopies = r.data.hcCopies;
      newResData.emloManual = r.data.emloManual;
      newResData.emloEmailUpload = r.data.emloEmailUpload;
      newResData.offlineQty = r.data.offineFlag;
      newResData.issnNo = r.data.issnNo;
      newResData.publicationDate = r.data.publicationDate;
      newResData.publishingYear = r.data.publishingYear;
      newResData.createdBy = r.data.createdBy;
      newResData.createdDate = r.data.createdDate;
      newResData.modifiedBy = r.data.modifiedBy;
      newResData.modifiedDate = r.data.modifiedDate;
      newResData.printer = r.data.printer;
      newResData.expectedDimension = r.data.expectedDimension;
      newResData.estUnitWeight = r.data.estUnitWeight;
      newResData.expectedPrintRunQty = r.data.expectedPrintRunQty;
      //........................
      newResData.expectedOMR = r.data.expectedOMR;
      newResData.expectedMLR = r.data.expectedMLR;
      newResData.expectedWeight = r.data.expectedWeight;
      // newResData.expectedPrintRunQty=r.data.expectedPrintRunQty
      // newResData.expectedPrintRunQty=r.data.expectedPrintRunQty
      newResData.hardCopyQty = r.data.hardCopyQty;
      newResData.actualDimension = r.data.actualDimension;
      newResData.actualWeight = r.data.actualWeight;
      newResData.CPTWeight = r.data.cptWeight;
      newResData.BLWeight = r.data.blWeight;
      newResData.actualPrintRunQty = r.data.actualPrintRunQty;
      newResData.actualOMR = r.data.actualOMR;
      newResData.actualMLR = r.data.actualMLR;
      newResData.estBalanceofStock = r.data.estBalanceofStock;
      newResData.expectedReturnbyAir = r.data.expectedReturnbyAir;
      newResData.expectedReturnbySea = r.data.expectedReturnbySea;
      newResData.ceaTrackingNO = r.data.ceaTrackingNO;
      newResData.cesTrackingNO = r.data.cesTrackingNO;
      newResData.actualPrintRunQty = r.data.actualPrintRunQty;
      newResData.actualReturnbyAir = r.data.actualReturnbyAir;
      newResData.actualReturnbySea = r.data.actualReturnbySea;
      newResData.actualBalanceStock = r.data.actualBalanceStock;
      newResData.journalInsertsView = r.data.journalInsertsView;
      newResData.status = r.data.status;
      newResData.otCopies = r.data.otCopies;
      newResData.bpaMarketingQty = r.data.bpaMarketingQty;
      newResData.detailDescription = r.data.detailDescription
      newResData.specialDeliveryInstructions = r.data.specialDeliveryInstructions
      newResData.specialHandling = r.data.specialHandling
      // newResData.expectedPrintRunQty=r.data.expectedPrintRunQty
      this.setState({ responseData: newResData }, () => {
        this.setState({ loading: false });
      });
    });
  }
  handleCancel = () => {
    this.props.history.push("/pubJournalItemMaster");
  };
  handleStageOpen = (mode) => {
    this.setState({ open: !this.state.open });
    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    if (mode === "apiRequired")
      getStagingJournalitemMasterList(orderId).then((r) => {
        this.setState({ stageList: r.data });
      });
  };
  render() {
    let { selectedOrder, responseData } = this.state;
    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    // console.log(responseData.detailDescription)
    return (
      <div>
        <Paper
        //id="convertToPdf"
        >
          {!this.state.loading ? (
            <div>
              {" "}
              <ViewHeader roles={this.props.roles} />
              <div
                className={
                  this.props.clearOpenList
                    ? "view-page-container"
                    : "view-page-container-wide"
                }
                id="printable"
              >
                <div className="view-page-fieldset">
                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-headings-fieldset"
                        : "view-page-headings-fieldset-wide"
                    }
                  >
                    Journal Primary Info
                  </div>
                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                    id={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                  >
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">Customer</div>
                      <div className="view-page-value">
                        {this.state.responseData.customer}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">Office</div>
                      <div
                        className="view-page-value"
                        style={{ paddingRight: "1em" }}
                      >
                        {responseData.office}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "110px" }}
                    >
                      <div className="view-page-label">Acronym</div>
                      <div className="view-page-value">
                        {responseData.acronym}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">
                        Vol: Iss / Supp No / Parts
                      </div>
                      <div className="view-page-value">
                        {responseData.volume} : {responseData.issue}
                        {responseData.suppNumber}
                        {responseData.partNumber}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "180px" }}
                    >
                      <div className="view-page-label">
                        Expected Delivery Date
                      </div>
                      <div className="view-page-value">
                        {" "}
                        {responseData.expectedDeliveryDate &&
                          moment(responseData.expectedDeliveryDate).format(
                            "DD/MM/YYYY"
                          )}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "180px" }}
                    >
                      <div className="view-page-label">Printer</div>
                      <div className="view-page-value">
                        {responseData.printer}
                      </div>
                    </div>
                  </div>
                  {/* .......................................................... */}
                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                    id={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                  >
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">Issue Description</div>
                      <div
                        className="view-page-value"
                        style={{ paddingRight: "1em" }}
                      >
                        {responseData.issueDesc}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">Title Description</div>
                      <div
                        className="view-page-value"
                        style={{ paddingRight: "1em" }}
                      >
                        {responseData.journalDesc}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "110px" }}
                    >
                      <div className="view-page-label">Journal Type</div>
                      <div className="view-page-value">
                        {responseData.productType}
                      </div>
                    </div>

                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">Unique Issue Id</div>
                      <div className="view-page-value">
                        {responseData.uniqueIdentifier}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "180px" }}
                    >
                      <div className="view-page-label">Insert</div>
                      <div className="view-page-value">
                        {responseData.insert}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "180px" }}
                    >
                      <div className="view-page-label">Special Instruction</div>
                      <div className="view-page-value">
                        {responseData.specialInstruction}
                      </div>
                    </div>
                  </div>
                  {/* .......................................................... */}
                  {/* .......................................................... */}
                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                    id={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                  >
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">Detail Description</div>
                      <div className="view-page-value">
                        {responseData.detailDescription}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">Remarks</div>
                      <div
                        className="view-page-value"
                        style={{ paddingRight: "1em" }}
                      >
                        {responseData.Remarks}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "110px" }}
                    >
                      <div className="view-page-label">BL Location</div>
                      <div
                        className="view-page-value"
                        style={{ paddingRight: "1em" }}
                      >
                        {responseData.location}
                      </div>
                    </div>


                  </div>
                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                    id={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                  >
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "950px" }}
                    >
                      <div className="view-page-label">Special Delivery Instruction</div>
                      <div
                        className="view-page-value"
                        style={{ paddingRight: "1em" }}
                      >
                        {responseData.specialDeliveryInstructions}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                    id={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                  >
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "950px" }}
                    >
                      <div className="view-page-label">Special Handling</div>
                      <div
                        className="view-page-value"
                        style={{ paddingRight: "1em" }}
                      >
                        {responseData.specialHandling}
                      </div>
                    </div>
                  </div>
                  {/* .......................................................... */}
                </div>
                {/* ......................................................... */}
                <div className="view-page-fieldset">
                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-headings-fieldset"
                        : "view-page-headings-fieldset-wide"
                    }
                  >
                    Journal Instruction
                  </div>

                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                  >
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "320px" }}
                    >
                      <div
                        className="view-page-value"
                        style={{ fontWeight: "500", fontSize: "18px" }}
                      >
                        Expected
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "150px" }}
                    >
                      <div
                        className="view-page-value"
                        style={{ fontWeight: "500", fontSize: "18px" }}
                      >
                        Actual
                      </div>
                    </div>
                  </div>
                  {/* .............................. */}
                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                  >
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "320px" }}
                    >
                      <div className="view-page-label">Dimension</div>
                      <div className="view-page-value">
                        {responseData.expectedDimension}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "150px" }}
                    >
                      <div className="view-page-label">Dimension</div>
                      <div className="view-page-value">
                        {responseData.actualDimension}
                      </div>
                    </div>
                  </div>
                  {/* .............................. */}
                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                  >
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "320px" }}
                    >
                      <div className="view-page-label">Weight</div>
                      <div className="view-page-value">
                        {responseData.expectedWeight}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "150px" }}
                    >
                      <div className="view-page-label">Weight</div>
                      <div className="view-page-value">
                        {responseData.actualWeight}
                      </div>
                    </div>
                  </div>
                  {/* .............................. */}
                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                  >
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "320px" }}
                    >
                      <div className="view-page-label">Print Run Qty Order</div>
                      <div className="view-page-value">
                        {responseData.expectedPrintRunQty}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "150px" }}
                    >
                      <div className="view-page-label">Print Run Qty Order</div>
                      <div className="view-page-value">
                        {responseData.actualPrintRunQty}
                      </div>
                    </div>
                  </div>
                  {/* .............................. */}
                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                  >
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "320px" }}
                    >
                      <div className="view-page-label">Offline Members</div>
                      <div className="view-page-value">
                        {responseData.expectedOMR}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "150px" }}
                    >
                      <div className="view-page-label">Offline Members</div>
                      <div className="view-page-value">
                        {responseData.actualOMR}
                      </div>
                    </div>
                  </div>
                  {/* .............................. */}
                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                  >
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "320px" }}
                    >
                      <div className="view-page-label">MLRs(Subscribers)</div>
                      <div className="view-page-value">
                        {responseData.expectedMLR}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "150px" }}
                    >
                      <div className="view-page-label">MLRs(Subscribers)</div>
                      <div className="view-page-value">
                        {responseData.actualMLR}
                      </div>
                    </div>
                  </div>
                  {/* .............................. */}
                  <div className="view-page-fieldset">
                    <div
                      className={
                        this.props.clearOpenList
                          ? "view-page-headings-fieldset"
                          : "view-page-headings-fieldset-wide"
                      }
                    >
                      Others
                    </div>
                  </div>
                  {/* .............................. */}
                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                  >
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">Hard Copy Qty</div>
                      <div className="view-page-value">
                        {responseData.hardCopyQty}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">EMLO Manual</div>
                      <div className="view-page-value">
                        {responseData.emloManual}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">EMLO Email Upload</div>
                      <div className="view-page-value">
                        {responseData.emloEmailUpload}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">Culling Copies</div>
                      <div className="view-page-value">
                        {responseData.otCopies}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">BPA Qty</div>
                      <div className="view-page-value">
                        {responseData.bpaMarketingQty}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                  >
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">CPT Weight</div>
                      <div className="view-page-value">
                        {responseData.CPTWeight}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">BL Weight  </div>
                      <div className="view-page-value">
                        {responseData.BLWeight}
                      </div>
                    </div>
                  </div>
                </div>
                {/* .............................. */}
                <div className="view-page-fieldset">
                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-headings-fieldset"
                        : "view-page-headings-fieldset-wide"
                    }
                  >
                    Journal Inserts
                  </div>

                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                  >
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">Insert Number</div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">Weight</div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">Quantity</div>
                    </div>
                  </div>
                  {responseData.journalInsertsView &&
                    responseData.journalInsertsView.length > 0 &&
                    responseData.journalInsertsView.map((v, i) => {
                      return (
                        <div
                          key={i}
                          className={
                            this.props.clearOpenList
                              ? "view-page-contents-container"
                              : "view-page-contents-container-wide"
                          }
                        >
                          <div
                            className="view-page-contents-sub-container"
                            style={{ width: "160px" }}
                          >
                            <div className="view-page-value">{v.insertNo}</div>
                          </div>
                          <div
                            className="view-page-contents-sub-container"
                            style={{ width: "160px" }}
                          >
                            <div className="view-page-value">
                              {v.insertWeight}
                            </div>
                          </div>
                          <div
                            className="view-page-contents-sub-container"
                            style={{ width: "160px" }}
                          >
                            <div className="view-page-value">{v.quantity}</div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {/* .............................. */}
                <div className="view-page-fieldset">
                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-headings-fieldset"
                        : "view-page-headings-fieldset-wide"
                    }
                  >
                    Journal WSR Info
                  </div>

                  <div
                    className={
                      this.props.clearOpenList
                        ? "view-page-contents-container"
                        : "view-page-contents-container-wide"
                    }
                  >
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">ISSN No</div>
                      <div className="view-page-value">
                        {responseData.issnNo}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">Publication Date</div>
                      <div className="view-page-value">
                        {responseData.publicationDate &&
                          moment(responseData.publicationDate).format(
                            "DD/MM/YYYY"
                          )}
                      </div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                      style={{ width: "160px" }}
                    >
                      <div className="view-page-label">Publication Year</div>
                      <div className="view-page-value">
                        {responseData.publishingYear}
                      </div>
                    </div>
                  </div>
                  {/* ......................................................... */}
                  <div style={{ marginTop: "1em" }}>
                    <div
                      className={
                        this.props.clearOpenList
                          ? "view-page-headings-fieldset"
                          : "view-page-headings-fieldset-wide"
                      }
                    >
                      Dispatch Summary
                    </div>

                    <div
                      className={
                        this.props.clearOpenList
                          ? "view-page-contents-container"
                          : "view-page-contents-container-wide"
                      }
                    >
                      <div
                        className="view-page-contents-sub-container"
                        style={{ width: "320px" }}
                      >
                        <div
                          className="view-page-value"
                          style={{ fontWeight: "500", fontSize: "18px" }}
                        >
                          Expected
                        </div>
                      </div>
                      <div
                        className="view-page-contents-sub-container"
                        style={{ width: "150px" }}
                      >
                        <div
                          className="view-page-value"
                          style={{ fontWeight: "500", fontSize: "18px" }}
                        >
                          Actual
                        </div>
                      </div>
                    </div>
                    {/* .............................. */}
                    <div
                      className={
                        this.props.clearOpenList
                          ? "view-page-contents-container"
                          : "view-page-contents-container-wide"
                      }
                    >
                      <div
                        className="view-page-contents-sub-container"
                        style={{ width: "320px" }}
                      >
                        <div className="view-page-label">Balance Of Stock</div>
                        <div className="view-page-value">
                          {responseData.estBalanceofStock
                            ? responseData.estBalanceofStock
                            : ""}
                        </div>
                      </div>
                      <div
                        className="view-page-contents-sub-container"
                        style={{ width: "150px" }}
                      >
                        <div className="view-page-label">Balance Of Stock</div>
                        <div className="view-page-value">
                          {responseData.actualBalanceStock
                            ? responseData.actualBalanceStock
                            : ""}
                        </div>
                      </div>
                    </div>
                    {/* .............................. */}
                    <div
                      className={
                        this.props.clearOpenList
                          ? "view-page-contents-container"
                          : "view-page-contents-container-wide"
                      }
                    >
                      <div
                        className="view-page-contents-sub-container"
                        style={{ width: "320px" }}
                      >
                        <div className="view-page-label">Return By Air</div>
                        <div className="view-page-value">
                          {responseData.expectedReturnbyAir
                            ? responseData.expectedReturnbyAir
                            : ""}
                        </div>
                      </div>
                      <div
                        className="view-page-contents-sub-container"
                        style={{ width: "150px" }}
                      >
                        <div className="view-page-label">Return By Air</div>
                        <div className="view-page-value">
                          {responseData.actualReturnbyAir
                            ? responseData.actualReturnbyAir
                            : ""}
                        </div>
                      </div>
                    </div>
                    {/* .............................. */}
                    <div
                      className={
                        this.props.clearOpenList
                          ? "view-page-contents-container"
                          : "view-page-contents-container-wide"
                      }
                    >
                      <div
                        className="view-page-contents-sub-container"
                        style={{ width: "320px" }}
                      >
                        <div className="view-page-label">
                          Return By Air Tracking No
                        </div>
                        <div className="view-page-value"></div>
                      </div>
                      <div
                        className="view-page-contents-sub-container"
                        style={{ width: "150px" }}
                      >
                        <div className="view-page-label">
                          Return By Air Tracking No
                        </div>
                        <div className="view-page-value">
                          {responseData.ceaTrackingNO}
                        </div>
                      </div>
                    </div>
                    {/* .............................. */}
                    <div
                      className={
                        this.props.clearOpenList
                          ? "view-page-contents-container"
                          : "view-page-contents-container-wide"
                      }
                    >
                      <div
                        className="view-page-contents-sub-container"
                        style={{ width: "320px" }}
                      >
                        <div className="view-page-label">Return By Sea</div>
                        <div className="view-page-value">
                          {responseData.expectedReturnbySea
                            ? responseData.expectedReturnbySea
                            : ""}
                        </div>
                      </div>
                      <div
                        className="view-page-contents-sub-container"
                        style={{ width: "150px" }}
                      >
                        <div className="view-page-label">Return By Sea</div>
                        <div className="view-page-value">
                          {responseData.actualReturnbySea
                            ? responseData.actualReturnbySea
                            : ""}
                        </div>
                      </div>
                    </div>
                    {/* .............................. */}
                    <div
                      className={
                        this.props.clearOpenList
                          ? "view-page-contents-container"
                          : "view-page-contents-container-wide"
                      }
                    >
                      <div
                        className="view-page-contents-sub-container"
                        style={{ width: "320px" }}
                      >
                        <div className="view-page-label">
                          Return By Sea Tracking No
                        </div>
                        <div className="view-page-value"></div>
                      </div>
                      <div
                        className="view-page-contents-sub-container"
                        style={{ width: "150px" }}
                      >
                        <div className="view-page-label">
                          Return By Sea Tracking No
                        </div>
                        <div className="view-page-value">
                          {responseData.cesTrackingNO}
                        </div>
                      </div>
                    </div>
                    {/* .............................. */}
                    <div className="view-page-fieldset">
                      <div
                        className={
                          this.props.clearOpenList
                            ? "view-page-headings-fieldset"
                            : "view-page-headings-fieldset-wide"
                        }
                      >
                        Others
                      </div>
                    </div>
                    {/* .............................. */}
                    <div
                      className={
                        this.props.clearOpenList
                          ? "view-page-contents-container"
                          : "view-page-contents-container-wide"
                      }
                    >
                      <div
                        className="view-page-contents-sub-container"
                        style={{ width: "160px" }}
                      >
                        <div className="view-page-label">Created By</div>
                        <div className="view-page-value">
                          {responseData.createdBy}
                        </div>
                      </div>
                      <div
                        className="view-page-contents-sub-container"
                        style={{ width: "160px" }}
                      >
                        <div className="view-page-label">Created Date</div>
                        <div className="view-page-value">
                          {responseData.createdDate &&
                            moment(responseData.createdDate).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                        </div>
                      </div>
                      <div
                        className="view-page-contents-sub-container"
                        style={{ width: "160px" }}
                      >
                        <div className="view-page-label">Modified By</div>
                        <div className="view-page-value">
                          {responseData.modifiedBy}
                        </div>
                      </div>
                      <div
                        className="view-page-contents-sub-container"
                        style={{ width: "160px" }}
                      >
                        <div className="view-page-label">Modified Date</div>
                        <div className="view-page-value">
                          {responseData.modifiedDate &&
                            moment(responseData.modifiedDate).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                        </div>
                      </div>
                      {/* <div
                        className="view-page-contents-sub-container"
                        style={{ width: "160px" }}
                      >
                        <div className="view-page-label">Status</div>
                        <div className="view-page-value">
                          {responseData.status}
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <div
                className="view-page-footer"
                style={{ paddingLeft: "0px", display: "flex" }}
              >
                <div style={{ marginTop: "1em" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={(e) => this.handleCancel()}
                  >
                    CANCEL
                  </Button>
                </div>
                <div style={{ marginTop: "1em", marginLeft: "1em" }}>
                  <StageModal
                    open={this.state.open}
                    handleStageOpen={this.handleStageOpen}
                    list={this.state.stageList}
                    issueDesc={responseData.issueDesc}
                    journalDesc={responseData.journalDesc}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={(e) => this.handleStageOpen("apiRequired")}
                  >
                    Journal Stage
                  </Button>
                </div>
              </div>{" "}
            </div>
          ) : (
              <LinearProgress />
            )}
        </Paper>
      </div>
    );
  }
}
export default withRouter(ViewJournalItemMaster);
