import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withRouter } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";
import Tooltip from "@material-ui/core/Tooltip";
import QueueIcon from '@material-ui/icons/Queue';
import IconButton from "@material-ui/core/IconButton";
import { Autocomplete } from "@material-ui/lab";
import Table from "@material-ui/core/Table";
import toastr from "toastr";
import TableBody from "@material-ui/core/TableBody";
import CheckIcon from "@material-ui/icons/Check";
import TableCell from "@material-ui/core/TableCell";
import clone from "clone";
import { StateContext } from "../../../shared/globalState";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { getUserList, getPlannerList } from "../../userAccess/userAccessAction";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  getCustomerDropdown,
  getPrintingProcess,
  getProcessTypes,
  getUOProcess,
  getCountryDropdownList,
  getUpdatedCountryDropdownList,
  getPlannerBasedOnCusList,
} from "../../masters/masterActions";
import * as XLSX from "xlsx";
import {
  uploaduserOrderFiles,
  submitUserOrder,
  submitGroupProcess,
  getParticularUserOrderEdit
} from "../orderPlacement/actions";
import { TextField, Button, Paper, Divider } from "@material-ui/core";
import { addDispatchStyles } from "../../../shared/styles";
import Typography from "@material-ui/core/Typography";
import { EditIcon } from "../../../shared/customIcons";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import DeleteIcon from "@material-ui/icons/Delete";
import Validator from "../../../shared/validator";
import "../../app/App.css";
let autoCId = ""
let autoOGLId = ""
const notRequired100AllAllow = new Validator("notRequired100AllAllow");
function createData(
  fileName,
  country,
  noOfCopies,
  quantity,
  remark,
  routing,
  exception,
  id
) {
  return {
    fileName,
    country,
    noOfCopies,
    quantity,
    remark,
    routing,
    exception,
    id,
  };
}
const headCells = [
  {
    id: "fileName",
    numeric: false,
    disablePadding: true,
    label: "File Name",
  },
  {
    id: "country",
    numeric: false,
    disablePadding: false,
    label: "Country",
  },
  {
    id: "noOfCopies",
    numeric: false,
    disablePadding: false,
    label: "Copies",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "remark",
    numeric: false,
    disablePadding: false,
    label: "Remark",
  },
  {
    id: "routing",
    numeric: false,
    disablePadding: false,
    label: "Routing",
  },
  {
    id: "exception",
    numeric: false,
    disablePadding: false,
    label: "Exception",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];
const groupprocess = [
  {
    id: "print",
    numeric: false,
    disablePadding: false,
    label: "Print",
  },
  {
    id: "processName",
    numeric: false,
    disablePadding: false,
    label: "Process Name",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow style={{ background: "#eaeaea" }}>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderBottom: "2px solid #eaeaea" }}

            style={
              i === headCells.length - 1
                ? {
                  borderRight: "1px solid #eaeaea"
                }
                : i === 0
                  ? {
                    borderLeft: "1px solid #eaeaea"
                  }
                  : {}
            }
          >
            <span style={{ color: "#306dca" }}>{headCell.label === "Country" || headCell.label === "Copies" || headCell.label === "Quantity" ? <span>{headCell.label}<span style={{ color: "red", marginLeft: "4px" }}>*</span></span> : headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function EnhancedTableHeadProcess(props) {
  return (
    <TableHead>
      <TableRow style={{ background: "#eaeaea" }}>
        {groupprocess.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={
              i === headCells.length - 1
                ? {
                  borderRight: "1px solid #eaeaea",
                }
                : i === 0
                  ? {
                    borderLeft: "1px solid #eaeaea",
                  }
                  : {}
            }
          >
            {/* <span style={{ color: "#306dca" }}>{headCell.label}</span> */}
            <span style={{ color: "#306dca" }}>
              {headCell.label === "Print" || headCell.label === "Process Name" || headCell.label === "Type" || headCell.label === "Quantity" ?
                <span>{headCell.label}
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span></span> : headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderTop: "1px solid #eaeaea",
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { } = props;

  return (
    <div>
      <div
        style={{
          display: "flex",
          //   width: "100%",
          border: "1px solid #eaeaea",
          borderBottom: "none",
          borderRadius: "4px",
          margin: "0 .8em 0 .6em",
        }}
      >
        {props.list && props.list.length > 0 && props.disableAdd ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={(e) => props.handleClickButton(e, "add")}
          >
            <AddIcon style={{ fontSize: "18px" }} /> Add
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              onClick={(e) => props.handleClickButton(e, "add")}
            // style={
            //   !props.disableAdd
            //     ? {
            //         color: "#fff",
            //         background: "#3982ea",
            //       }
            //     : { color: "#3982ea", fontSize: "11px" }
            // }
            >
              <AddIcon style={{ fontSize: "18px" }} /> Add
            </Button>
          )}
        {props.disableEdit ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={(e) => props.handleClickButton(e, "reset")}
          >
            <SyncIcon style={{ fontSize: "18px" }} /> Reset
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              //         padding: "2px",
              //         margin: ".5em",
              //         fontSize: "11px" }}
              // style={
              //   !props.disableEdit
              //     ? {
              //         color: "#fff",
              //         background: "#3982ea",
              //         padding: "2px",
              //         margin: ".5em",
              //         fontSize: "11px",
              //       }
              //     : { color: "#3982ea", fontSize: "11px" }
              // }
              onClick={(e) => props.handleClickButton(e, "reset")}
            >
              <SyncIcon style={{ fontSize: "18px" }} /> Reset
            </Button>
          )}
      </div>
    </div>
  );
};
const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Typography>
  );
}
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontSize: "18px",
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    fontFamily: ["Roboto"].join(","),
    "&:hover": {
      opacity: 1,
    },
    "&$selected": {
      color: "#000",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      //   color: "#40a9ff"
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

class AddUserOrder extends React.Component {
  static contextType = StateContext;
  state = {
    fileAdded: false,
    indexGP: 0,
    indexClicked: 0,
    value: 0,
    splInstruction: "",
    directory: "",
    list: [],
    editableList: [],
    fileName: "",
    disableAdd: false,
    disableEdit: true,
    disableAddGP: false,
    disableEditGP: true,
    plannerList: [],
    customerList: [],
    plannerId: "",
    planner: {},
    customerId: "",
    customer: "",
    gpPrint: "",
    gpProcessName: "",
    gpType: "",
    gpQuantity: 0,
    gpRemark: "",
    OrderGroupID: 0,
    printingProcessList: [],
    processTypeList: [],
    uoProcessList: [],
    groupProcessEditClicked: false,
    loading: false,
    orderSubmited: false,
    gpObj: {},
    gpList: [],
    errors: [{}],
    maxQty: 0,
    countryList: [],
    ogId: ""
  };
  handleChange = (event, newValue) => {
    // console.log(newValue);
    this.setState({ value: newValue });
  };
  componentDidMount() {
    const [{ store }, dispatch] = this.context;
    this.props.handleSettingHeader("User Order Placement");
    this.props.setSelectedList("genericSystemOpen", "genericSystem");
    this.props.handleDrawerClose();
    getPlannerList().then((r) => {
      dispatch({
        type: "getUserList",
        newStore: r.data,
      });
      this.getAllPlanners(r.data);
    });
    // getUserList().then(r => {
    //   dispatch({
    //     type: "getUserList",
    //     newStore: r.data
    //   });
    //   this.getAllPlanners(r.data);
    // });
    getCustomerDropdown().then((r) => {
      this.getAllCustomer(r.data);
    });
    getPrintingProcess().then((r) => {
      this.setState({ printingProcessList: r.data });
    });
    getProcessTypes().then((r) => {
      this.setState({ processTypeList: r.data });
    });
    getUOProcess().then((r) => {
      this.setState({ uoProcessList: r.data });
    });
    // getCountryDropdownList().then((r) => {

    //   console.log(r)
    // });
    getUpdatedCountryDropdownList().then(r => {
      this.getAllCountrys(r.data);
      //  console.log(r)
    })
  }
  getAllCountrys = (list) => {
    let countryList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        countryList.push({ key: c.id, value: c.text });
      });

    this.sortDDList(countryList, "countryList");
  };

  sortDDList = (list, name) => {
    let z = list.sort(function (a, b) {
      if (name === "customerList") {
        var x = a.name.toLowerCase();
        var y = b.name.toLowerCase();
      } else {
        var x = a.value.toLowerCase();
        var y = b.value.toLowerCase();
      }

      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ [name]: z });
  };
  getAllCustomer = (list) => {
    let customerList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        customerList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(customerList, "customerList");
  };
  getAllPlanners = (list) => {
    let plannerList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        // if (c.isActive)
        plannerList.push({ key: c.id, value: c.text, name: c.text });
      });
    this.sortDDList(plannerList, "plannerList");
  };
  checkOGDisability = () => {
    if (
      !this.state.customerId ||
      !this.state.orderTitle ||
      // !this.state.splInstruction ||
      !this.state.plannerId
      //||
      //!this.state.directory
    )
      return true;
    else return false;
  };
  _onAlphaKeyPress(event) {
    const re = /[a-zA-Z0-9 ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onAlphaPlusKeyPress(event) {
    const re = /[a-zA-Z +()/{}]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleUpload = (e) => {
    //e.preventDefault();
    let files = e.target.files;
    var reader = new FileReader();
    let _error = "";

    if (files[0]) {
      this.setState({
        fileName: files[0].name,
        //disableAdd: true,
        disableEdit: false,
      });
      reader.onload = function (e) {
        let data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      };

      reader.readAsBinaryString(files[0]);

      let loggedInId = localStorage.getItem("loggedInId");
      const formData = new FormData();

      formData.append("UploadFiles", files[0]);
      formData.append("CustomerID", this.state.customerId);
      formData.append("OrderTitle", this.state.orderTitle);
      formData.append("SpecialInstruction", this.state.splInstruction);
      formData.append("PlannerID", this.state.plannerId);
      formData.append("DataServiceDirectoryName", this.state.directory);
      formData.append("CreatedBy", loggedInId);
      this.setState({ loading: true });
      uploaduserOrderFiles(formData).then((r) => {
        let newList = [];
        // r &&
        //   r.length > 0 &&
        r.map((res) => {
          newList.push({
            fileName: res.fileName,
            country: res.countryName,
            noOfCopies: res.copies,
            quantity: res.quantity,
            remark: res.remraks,
            routing: res.rounting,
            exception: res.exceptionMessage,
            canDelete: true,
          });
        });
        this.setState({
          list: newList,
          editableList: newList,
          // disableAdd: true,
          fileAdded: true,
          disableEdit: false,
          OrderGroupID: r && r.length > 0 && r[0].orderGroupID,
          loading: false,
        }, () => {
          if (document.getElementById("inputId"))
            document.getElementById("inputId").value = "";
        });
        if (r && r.length > 0) {
          if (!r[0].cbdStagingID) toastr.error(r[0].message);
          // this.setState({ list: [] })
        }
        //  applicable for next bulid........................................................................ 
        if (r && r.length === 1) {
          let check = false
          if (r[0].isvalid || r[0].isValid)
            check = true
          if (!check && !r[0].cbdStagingID)
            this.setState({ list: [] })
        }
      });
    } else {
      this.setState({ fileName: "" });
    }
  };
  checkOGSubmit = () => {
    let notValid = false;
    if (this.state.list.length === 0) return true;
    else;
    {
      this.state.list.map((l) => {
        if (l.exception) notValid = true;
      });

      this.state.list.map((l) => {
        if (
          // !l.fileName ||
          !l.country ||
          !l.noOfCopies ||
          !l.quantity ||
          l.editClicked
          //  ||
          // !l.remark ||
          // !l.routing
        )
          notValid = true;
      });
      return notValid;
    }
  };
  checkGpDisability = () => {
    let notValid = false;
    let newList = clone(this.state.gpList);
    newList.map(n => {
      if (!n.gpPrint ||
        !n.gpProcessName ||
        !n.gpType ||
        n.gpQuantity === ""
        || n.editClicked
      )
        notValid = true
    })
    return notValid
  }
  handleClickButton = (e, name) => {
    let newList = clone(this.state.list);
    let newObj = {
      fileName: "",
      country: "",
      noOfCopies: "",
      quantity: "",
      remark: "",
      routing: "",
      exception: "",
      editClicked: true,
    };
    newList.push(newObj);
    if (this.state.fileAdded) {
      if (name === "add") {
        this.setState(
          {
            list: newList,
            editableList: newList,

            disableEdit: false,

            indexClicked: newList.length - 1,
          },
          () => {
            document.getElementById(`tableRow${newList.length - 1}`).focus();
          }
        );
      } else {
        this.setState({
          list: [],
          editableList: [],
          disableAdd: false,
          disableEdit: true,
          fileName: "",
          OrderGroupID: this.state.OrderGroupID,
        });
      }
    } else {
      if (name === "add") {
        this.setState(
          {
            list: newList,
            editableList: newList,

            disableEdit: false,
            OrderGroupID: this.state.OrderGroupID,
            indexClicked: newList.length - 1,
          },
          () => {
            document.getElementById(`tableRow${newList.length - 1}`).focus();
          }
        );
      } else {
        this.setState({
          list: [],
          editableList: [],
          disableAdd: false,
          disableEdit: true,
          fileName: "",
          OrderGroupID: this.state.OrderGroupID,
        });
      }
    }
  };
  handleGPClickButton = (e, name) => {
    let newList = clone(this.state.gpList);
    if (name === "add") {
      let newObj = {
        gpPrint: "",
        gpProcessName: "",
        gpType: "",
        gpQuantity: 0,
        gpRemark: "",
        editClickedGP: true,
        // for next build...........................................................................
        editClicked: true
      };
      newList.push(newObj);
      this.setState({
        gpList: newList,
        disableAddGP: true,
        disableEditGP: false,
        indexGP: newList.length - 1,
      });
    }
    else if (name === "clone") {
      if (newList.length > 0) {
        // console.log(newList[newList.length-1])
        let newObj = {
          gpPrint: newList[newList.length - 1].gpPrint,
          gpProcessName: newList[newList.length - 1].gpProcessName,
          gpType: newList[newList.length - 1].gpType,
          gpQuantity: 0,
          gpRemark: "",
          editClickedGP: true,
          editClicked: true
        };
        newList.push(newObj);
      }
      this.setState({
        gpList: newList,
        // disableAddGP: true,
        // disableEditGP: false,
        indexGP: newList.length - 1,
      });
    } else {
      this.setState({
        gpList: [],
        disableAddGP: false,
        disableEditGP: true,
        groupProcessEditClicked: false,
      });
    }
  };
  onEditClicked = (e, i) => {
    let newEditableList = clone(this.state.list);
    newEditableList[i].editClicked = !newEditableList[i].editClicked;
    this.setState({
      //  editableList: newEditableList,
      list: newEditableList,
      indexClicked: i,
    });
  };
  onDeleteClicked = (e, i) => {
    let newList = clone(this.state.list);
    newList[i].editClicked = false;
    // let newEditableList = clone(this.state.editableList);
    newList.splice(i, 1);
    // newEditableList.splice(i, 1);
    this.setState({
      // editableList: newEditableList,
      list: newList,
    });
    // if (this.state.list.length <= 1)
    //   this.setState({ disableAdd: false, disableEdit: true });
  };
  onEditClickedGP = (e, i) => {
    let newEditableList = clone(this.state.gpList);
    newEditableList[i].editClicked = !newEditableList[i].editClicked;
    this.setState({
      gpList: newEditableList,
      indexGP: i,
    });
  };
  onDeleteClickedGP = (e, i) => {
    let newList = clone(this.state.gpList);
    newList[i].editClicked = false;
    newList.splice(i, 1);
    this.setState({
      gpList: newList,
    });
  };
  onExcelDataChanged = (e, name, i) => {
    let newList = clone(this.state.list);

    newList[i][name] = e.target.value;

    if (name === "noOfCopies" || name === "quantity" || name === "fileName")
      newList[i].exception = "";
    this.setState({ list: newList });
  };
  handleCountryChange = (event, value) => {
    if (value) {
      let newList = clone(this.state.list);
      let val = value.value;
      let key = value.key;
      value = val.split(" - ")[1];
      newList[autoOGLId]["country"] = value;
      newList[autoOGLId]["countryDuplicate"] = { value };
      newList[autoOGLId]["countryID"] = key;
      newList[autoOGLId]["exception"] = "";
      this.setState({
        list: newList,
      });
    }
  };

  handleInputChange = (e, validate) => {
    let newRrrors = { ...this.state.errors };
    let result = validate(e.target.value);
    newRrrors[e.target.name] = !result;
    this.setState({ [e.target.name]: e.target.value, errors: newRrrors });
  };
  handleAutocompletePlanner = (e, val) => {
    this.setState({ plannerId: val && val.key, planner: val && val });
  };
  handleAutocompleteCustomer = (e, val) => {
    this.setState({
      customerId: val && val.key,
      customer: val && val,
      plannerId: "",
      planner: null,
    });
    if (val)
      getPlannerBasedOnCusList(val.key).then((r) => {
        if (r && r.data && r.data.length > 0)
          r.data.map((l) => {
            if (l.isSelected) {
              this.setState({
                plannerId: l.id,
                planner: { key: l.id, value: l.text, name: l.text },
              });
            }
          });
      });
  };

  handleChangeGPData1 = (e, values) => {
    let newGPL = clone(this.state.gpList);
    let targetValue = values && values.id;
    newGPL[autoCId].gpPrint = targetValue;
    this.setState({ gpList: newGPL });
  };
  handleChangeGPData2 = (e, values) => {
    let newGPL = clone(this.state.gpList);
    let targetValue = values && values.id;
    newGPL[autoCId].gpProcessName = targetValue;
    this.setState({ gpList: newGPL });
  };
  handleChangeGPData3 = (e, values) => {
    let newGPL = clone(this.state.gpList);
    let targetValue = values && values.id;
    newGPL[autoCId].gpType = targetValue;
    this.setState({ gpList: newGPL });
  };
  handleChangeGPDataNoneAutoC = (e, i) => {
    let newGPL = clone(this.state.gpList);
    newGPL[i][e.target.name] = e.target.value;
    this.setState({ gpList: newGPL });
  };
  handleAutoOpenClick = id => {
    autoCId = id
  }
  handleAutoOGLOpenClick = id => {
    autoOGLId = id
  }
  handleSubmit = (e) => {
    let reqBody = {};
    let list = [];
    let loggedInId = localStorage.getItem("loggedInId");

    // reqBody.UploadFiles= files[0]
    reqBody.CustomerID = this.state.customerId;
    reqBody.OrderTitle = this.state.orderTitle;
    reqBody.SpecialInstruction = this.state.splInstruction;
    reqBody.PlannerID = this.state.plannerId;
    reqBody.DataServiceDirectoryName = this.state.directory;
    reqBody.ModifiedBy = loggedInId;
    reqBody.OrderGroupID = this.state.OrderGroupID;
    reqBody.IsLabelCounterConfirmed = false;
    reqBody.UploadFiles = null;
    reqBody.IsActive = true;
    let newList = [...this.state.list];
    // if(this.state.orderSubmited){
    //   reqBody.DataServiceDirectoryName= null
    //   reqBody.IsLCConfirmed= false
    //   reqBody.SpecialInstruction= null
    //   reqBody.fileName= ""
    // }
    // else{
    //   reqBody.IsActive = true;
    // }
    newList = newList.map((r) => {
      return {
        fileName: r.fileName,
        countryName: r.country.value || r.country,
        copies: r.noOfCopies,
        quantity: r.quantity,
        remraks: r.remark,
        rounting: r.routing,
      };
    });

    reqBody.userOrderCountryBreakDownDetails = newList;
    submitUserOrder(reqBody, this.props.history).then((r) => {
      if (r && r.orderCode) {
        toastr.success("User Order Placed Successfully");
        this.setState({
          orderSubmited: true,
          gpObj: r,
          value: 1,
          maxQty: r.maxQuantity,
          OrderGroupID: +r.stagingOrderGroupID,
          ogId: r.orderGroupID
        });
        // getParticularUserOrderEdit(r.orderGroupID).then((r) => {
        //     let newList = [];
        //     r.userOrderPlacementList &&
        //       r.userOrderPlacementList.length > 0 &&
        //       r.userOrderPlacementList.map((res) => {
        //         newList.push({
        //           fileName: res.name,
        //           country: res.country,
        //           noOfCopies: res.noOfCopies,
        //           quantity: res.noOfQuantity,
        //           remark: res.remraks,
        //           routing: res.routing,
        //           exception: res.exceptionMessage,
        //           OGCode: res.orderCode,
        //           canDelete: res.canDelete,
        //           ordergroupid: res.orderGroupID,
        //         });
        //       });

        //     this.setState({
        //       list: newList,
        //       editableList: newList,
        //       // disableAdd: true,
        //       disableEditGP: false,
        //       disableEdit: false,
        //       OrderGroupID: r.orderId,
        //       loading: false,
        //       totalQuantity: r.userOrderDetails && r.userOrderDetails.totalQuantity,
        //       orderGroupCode: r.orderGroupCode,
        //       plannerId: r.plannerID,
        //       plannerName: r.plannerName,
        //       customerId: r.customerID,
        //       splInstruction: r.specialInstruction,
        //       directory: r.dataServiceDirectoryName,
        //       orderTitle: r.orderTitle,
        //       lcChecked: r.isLCVerified,
        //       fileName: r.fileName,
        //       UOID: r.orderGroupID,
        //       maxQty: r.userOrderDetails && r.userOrderDetails.maxQuantity,
        //     });
        //   });
      } else {
        let newOGList = [];
        r.list &&
          r.list.length > 0 &&
          r.list.map((l) => {
            newOGList.push({
              fileName: l.fileName,
              country: l.countryName,
              noOfCopies: l.copies,
              quantity: l.quantity,
              remark: l.remraks,
              routing: l.rounting,
              exception: l.exceptionMessage,
              canDelete: true,
            });
          });
        this.setState({ list: newOGList });
      }
    });
  };
  handleSubmitGP = (e) => {
    let reqBody = {};
    let loggedInId = localStorage.getItem("loggedInId");
    reqBody.OrderGroupID =
      this.state.gpObj &&
      this.state.gpObj.orderGroupID &&
      this.state.gpObj.orderGroupID;
    let list = [];

    let newListGP = [];
    this.state.gpList.map((r) => {
      newListGP.push({
        CBDProcessID: 0,
        PrintID: r.gpPrint,
        Processid: r.gpProcessName,
        Quantity: r.gpQuantity,
        ProcessTypeID: r.gpType,
        CreatedBy: loggedInId,
        Remarks: r.gpRemark,
      });
    });
    reqBody.OrderProcessdetails = newListGP;
    // reqBody.OrderProcessdetails.push({
    //   CBDProcessID: 0,
    //   PrintID: this.state.gpPrint,
    //   Processid: this.state.gpProcessName,
    //   Quantity: this.state.gpQuantity,
    //   ProcessTypeID: this.state.gpType,
    //   CreatedBy: loggedInId,
    //   Remarks: this.state.gpRemark
    // });
    submitGroupProcess(reqBody, this.props.history, "Created", this.state.ogId).then((r) => { });
  };
  render() {
    let {
      value,
      list,
      fileName,
      planner,
      customer,
      printName,
      ProcessNameName,
      typeName,
    } = this.state;
    let { classes } = this.props;

    let filteredPrintList =
      this.state.printingProcessList &&
      this.state.printingProcessList.map((val) => {
        return val;
      });

    let filteredUOList = this.state.uoProcessList.map((val) => {
      return val;
    });

    let filteredTypeList = this.state.processTypeList.map((val) => {
      return val;
    });

    return (
      <Paper>
        <div style={{ flexGrow: "1", padding: ".5em 2em 1em 2em" }}>
          <div style={{ background: "#fff" }}>
            <AntTabs
              value={value}
              onChange={this.handleChange}
              aria-label="ant example"
            >
              <AntTab label="Order Group" />
              <AntTab
                label="Group Process"
                disabled={!this.state.orderSubmited}
              />
            </AntTabs>
            <TabPanel value={value} index={0}>
              <React.Fragment>
                <div>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <Autocomplete
                        //autoFocus
                        options={this.state.customerList}
                        value={customer}
                        onChange={this.handleAutocompleteCustomer}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // autoFocus
                            label="Customer"
                            style={{
                              width: "93%",
                              fontSize: "11px !important",
                              marginLeft: ".5em",
                              marginRight: ".5em",
                              maxHeight: "400px",
                            }}
                            // name="plannerId"
                            margin="normal"
                            required
                          />
                        )}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <TextField
                        inputProps={{
                          maxLength: 100,
                        }}
                        required
                        //onKeyPress={this._onAlphaKeyPress}
                        style={{
                          width: "93%",
                          fontSize: "11px !important",
                          marginLeft: "-.4em",
                          marginRight: ".5em",
                          maxHeight: "400px",
                          overflow: "auto",
                        }}
                        label="Order Title"
                        name="orderTitle"
                        margin="normal"
                        className={classes.textFieldSmall}
                        value={this.state.orderTitle}
                        onChange={(e) =>
                          this.handleInputChange(e, notRequired100AllAllow)
                        }
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <Autocomplete
                        options={this.state.plannerList}
                        value={planner}
                        onChange={this.handleAutocompletePlanner}
                        getOptionLabel={(option) => option.value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Planner"
                            style={{
                              width: "93%",
                              fontSize: "11px !important",
                              marginLeft: "-.8em",
                              marginRight: ".5em",
                              maxHeight: "400px",
                            }}
                            // name="plannerId"
                            margin="normal"
                            required
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <TextField
                      // required
                      label="Special Instruction"
                      name="splInstruction"
                      inputProps={{
                        maxLength: 100,
                      }}

                      multiline
                      rows={1}
                      rowsMax={4}
                      //onKeyPress={this._onNumberKeyPress}
                      style={{
                        width: "31%",
                        fontSize: "11px !important",
                        marginLeft: ".7em",
                        marginRight: ".5em",
                        maxHeight: "400px",
                        overflow: "auto",
                        // marginTop: "-1px"
                      }}
                      margin="normal"
                      className={classes.textFieldSmall}
                      value={this.state.splInstruction}
                      onChange={(e) =>
                        this.handleInputChange(e, notRequired100AllAllow)
                      }
                    />
                    <TextField
                      // required
                      label="Directory Name "
                      name="directory"
                      inputProps={{
                        maxLength: 50,
                      }}
                      style={{
                        width: "31%",
                        fontSize: "11px !important",
                        marginLeft: "1.3em",
                        marginRight: ".5em",
                        maxHeight: "400px",
                        overflow: "auto",
                      }}
                      //onKeyPress={this._onKeyPress}
                      margin="normal"
                      className={classes.textFieldSmall}
                      value={this.state.directory}
                      onChange={(e) =>
                        this.handleInputChange(e, notRequired100AllAllow)
                      }
                    />
                  </div>
                </div>

                <div
                  style={{
                    width: "auto",
                    border: "2px dashed #eaeaea",
                    margin: "1em .8em 1em .6em",
                    borderRadius: "4px",
                  }}
                >
                  <label
                    className="upload excel"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      minHeight: "40px",
                      // cursor: "pointer",
                      padding: ".3em",
                    }}
                  >
                    <input
                      disabled={this.checkOGDisability()}
                      type="file"
                      id="inputId"
                      style={{ display: "none" }}
                      onChange={(e) => this.handleUpload(e)}
                      accept=".xlsx"
                    />
                    <div
                      style={
                        this.checkOGDisability()
                          ? {
                            backgroundColor: "#cccccc",
                            color: "#515151",
                            borderRadius: "4px",
                            padding: ".3em 1em .3em 1em",
                          }
                          : {
                            backgroundColor: "#3982ea",
                            color: "#fff",
                            borderRadius: "4px",
                            padding: ".3em 1em .3em 1em",
                            cursor: "pointer",
                          }
                      }
                    >
                      CHOOSE FILE
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "auto",
                        marginLeft: "12px",
                        width: "320px",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "500",
                          fontSize: "13px",
                        }}
                      >
                        {this.checkOGDisability()
                          ? "Please fill all the required fields before selecting a file"
                          : fileName
                            ? fileName
                            : "No file choosen"}
                      </div>
                      <div className="file-upload-footer">
                        {this.checkOGDisability()
                          ? ""
                          : "Allowed only “.xlsx” format, 500KB file limit."}
                      </div>
                    </div>
                  </label>
                </div>
                <div>
                  <EnhancedTableToolbar
                    //numSelected={selected.length}
                    handleClickButton={this.handleClickButton}
                    // disableAdd={this.state.disableAdd}
                    disableAdd={this.checkOGSubmit() || this.checkOGDisability()}
                    disableEdit={this.state.disableEdit}
                    list={this.state.list}
                  />
                </div>
                <div
                  style={{
                    // width: "100%",
                    maxHeight: "400px",
                    margin: "0 .8em 0 .6em",
                  }}
                >
                  <div style={
                    !this.props.clearOpenList
                      ? {
                        overflow: "auto",
                        maxWidth: "calc(100vw - 190px)",
                        maxHeight: "350px",
                        // margin: "0 .8em 0 .6em"
                      }
                      : {
                        overflow: "auto",
                        maxWidth: "calc(100vw - 420px)",
                        maxHeight: "350px",
                        // margin: "0 .8em 0 .6em"
                      }
                  }>
                    {/* //overflow: "auto" */}
                    <Table
                      aria-labelledby="tableTitle"
                      size="small"
                      stickyHeader
                      aria-label="sticky table"
                    >
                      {/* <caption style={{ borderBottom: "1px solid #eaeaea" }}>
                  A barbone structure table example with a caption
                </caption> */}
                      <EnhancedTableHead
                      //numSelected={selected.length}
                      // order={order}
                      // orderBy={orderBy}
                      // onSelectAllClick={e => this.handleSelectAllClick(e)}
                      //  onRequestSort={this.handleRequestSort}
                      //  rowCount={departmentList.length}
                      />

                      {!this.state.loading ? (
                        <TableBody style={{ overflow: "hidden" }}>
                          {list.map((row, index) => {
                            // console.log(row)
                            // const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            if (row) return (
                              <TableRow
                                // hover
                                // onClick={event =>
                                //   this.handleClick(event, row.id, row)
                                // }
                                id={`tableRow${index}`}
                                role="checkbox"
                                //  aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={index}
                              //   selected={isItemSelected}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ width: "300px", minWidth: "300px" }}

                                >
                                  {row.editClicked ? (
                                    <Input
                                      onKeyPress={this._onAlphaKeyPress}
                                      defaultValue={row.fileName}
                                      style={{ width: "100%" }}
                                      inputProps={{
                                        "aria-label": "description",
                                      }}
                                      error={!row.fileName}
                                      onChange={(e) => {
                                        this.onExcelDataChanged(
                                          e,
                                          "fileName",
                                          index
                                        );
                                      }}
                                      value={row.fileName}
                                    />
                                  ) : (
                                      row.fileName
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ width: "248px", minWidth: "248px" }}

                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {" "}
                                    {row.editClicked ? (
                                      <Autocomplete
                                        options={this.state.countryList}
                                        onChange={this.handleCountryChange}
                                        onOpen={e => this.handleAutoOGLOpenClick(index)}
                                        value={
                                          row.editClicked
                                            ? Object(row.country)
                                            : row.countryDuplicate
                                        }
                                        getOptionLabel={(option) =>
                                          option.value || row.country
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            //required
                                            // label="GstType"
                                            margin="normal"
                                            style={{ width: "100%", marginTop: "10px" }}
                                          />
                                        )}
                                      />
                                    ) : row.country &&
                                      row.country.length > 16 ? (
                                          <Tooltip
                                            // title={row.country.value || row.country}
                                            title={row.country}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.country}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.country}
                                          </Typography>
                                        )}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-right-cell"
                                  style={{ width: "100px", minWidth: "100px" }}

                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {" "}
                                    {row.editClicked ? (
                                      <Input
                                        onKeyPress={this._onNumberKeyPress}
                                        defaultValue={row.noOfCopies}
                                        inputProps={{
                                          "aria-label": "description",
                                        }}
                                        onChange={(e) => {
                                          this.onExcelDataChanged(
                                            e,
                                            "noOfCopies",
                                            index
                                          );
                                        }}
                                        value={row.noOfCopies}
                                      />
                                    ) : (
                                        row.noOfCopies
                                      )}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-right-cell"
                                  style={{ width: "100px", minWidth: "100px" }}

                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {" "}
                                    {row.editClicked ? (
                                      <Input
                                        onKeyPress={this._onNumberKeyPress}
                                        defaultValue={row.quantity}
                                        inputProps={{
                                          "aria-label": "description",
                                        }}
                                        onChange={(e) => {
                                          this.onExcelDataChanged(
                                            e,
                                            "quantity",
                                            index
                                          );
                                        }}
                                        value={row.quantity}
                                      />
                                    ) : (
                                        row.quantity
                                      )}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ width: "100px", minWidth: "100px" }}

                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {" "}
                                    {row.editClicked ? (
                                      <Input
                                        // onKeyPress={this._onAlphaPlusKeyPress}
                                        defaultValue={row.remark}
                                        inputProps={{
                                          "aria-label": "description",
                                        }}
                                        onChange={(e) => {
                                          this.onExcelDataChanged(
                                            e,
                                            "remark",
                                            index
                                          );
                                        }}
                                        value={row.remark}
                                      />
                                    ) : (
                                        row.remark
                                      )}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ width: "120px", minWidth: "120px" }}

                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {" "}
                                    {row.editClicked ? (
                                      <Input
                                        onKeyPress={this._onAlphaKeyPress}
                                        defaultValue={row.routing}
                                        inputProps={{
                                          "aria-label": "description",
                                        }}
                                        onChange={(e) => {
                                          this.onExcelDataChanged(
                                            e,
                                            "routing",
                                            index
                                          );
                                        }}
                                        value={row.routing}
                                      />
                                    ) : (
                                        row.routing
                                      )}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  {row.exception &&
                                    row.exception.length > 16 ? (
                                      <Tooltip
                                        title={row.exception}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                            color: "red",
                                          }}
                                        >
                                          {row.exception}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                          color: "red",
                                        }}
                                      >
                                        {row.exception}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        color: "#6c6b6b",
                                      }}
                                      onClick={(e) =>
                                        this.onEditClicked(e, index)
                                      }
                                    >
                                      {!row.editClicked ? (
                                        <EditIcon
                                          style={{
                                            color: "#6c6b6b",
                                          }}
                                        />

                                      ) : (
                                          row.country && row.noOfCopies && row.quantity &&
                                          <CheckIcon
                                            style={{
                                              color: "#6c6b6b",
                                            }}

                                          />
                                        )}
                                    </span>

                                    <DeleteIcon
                                      style={{
                                        cursor: "pointer",
                                        color: "#6c6b6b",
                                      }}
                                      onClick={(e) =>
                                        this.onDeleteClicked(e, index)
                                      }
                                    />
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      ) : (
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan="9">
                                <LinearProgress />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                    </Table>
                  </div>
                </div>
                <Divider style={{ marginTop: "25px" }} />
                <div style={{ display: "flex" }} className="button-wrapper">
                  <div style={{}}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => {
                        this.props.history.push("/genericSystem");
                      }}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {this.checkOGSubmit() || this.checkOGDisability() ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={(e) => this.handleSubmit(e)}
                      >
                        Next
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={(e) => this.handleSubmit(e)}
                        >
                          Next
                        </Button>
                      )}
                  </div>
                </div>
              </React.Fragment>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <React.Fragment>
                {!this.state.orderSubmited ? (
                  <div
                    style={{
                      width: "100%",
                      height: "73vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ fontSize: "18px", fontWeight: "500" }}>
                      No File Choosen Yet
                    </div>
                    <div style={{ fontSize: "12px" }}>
                      Upload .xlsx file related to the process on the "order
                      group page"
                    </div>
                  </div>
                ) : (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "1em",
                          background: "#f2f7fd",
                          padding: ".5em",
                          color: "#3982ea",
                          borderRadius: "4px",
                        }}
                      >
                        <div>
                          Order Group ID :{" "}
                          <span style={{ fontWeight: "500" }}>
                            {this.state.gpObj.orderGroupCode
                              ? this.state.gpObj.orderGroupCode
                              : ""}
                          </span>
                        </div>
                        <div>
                          Total Quantity :{" "}
                          <span style={{ fontWeight: "500" }}>
                            {this.state.gpObj.totalQuantity
                              ? this.state.gpObj.totalQuantity
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          //   width: "100%",
                          border: "1px solid #eaeaea",
                          borderRadius: "4px",
                          margin: "0.8em 0 0 0",
                        }}
                      >    {<Button
                        disabled={this.checkGpDisability()}
                        variant="contained"
                        color="primary"
                        style={{
                          boxShadow: "none",
                          padding: "2px",
                          margin: ".5em",
                          fontSize: "11px",
                        }}
                        onClick={(e) =>
                          this.handleGPClickButton(e, "add")
                        }
                      >
                        <AddIcon style={{ fontSize: "18px" }} /> Add
                        </Button>}
                        {/* {this.state.disableAddGP ? (
                          <Button
                            disabled
                            variant="contained"
                            color="primary"
                            style={{
                              boxShadow: "none",
                              padding: "2px",
                              margin: ".5em",
                              fontSize: "11px",
                            }}
                            onClick={(e) => this.handleGPClickButton(e, "add")}
                          // style={
                          //   !this.state.disableAddGP
                          //     ? {
                          //         color: "#fff",
                          //         background: "#3982ea",
                          //         padding: "2px",
                          //         margin: ".5em",
                          //         fontSize: "11px",
                          //       }
                          //     : { color: "#3982ea", fontSize: "11px" }
                          // }
                          >
                            <AddIcon style={{ fontSize: "18px" }} /> Add
                          </Button>
                        ) : (
                            <Button
                              disabled={this.checkGpDisability()}
                              variant="contained"
                              color="primary"
                              style={{
                                boxShadow: "none",
                                padding: "2px",
                                margin: ".5em",
                                fontSize: "11px",
                              }}
                              onClick={(e) => this.handleGPClickButton(e, "add")}
                            >
                              <AddIcon style={{ fontSize: "18px" }} /> Add
                            </Button>
                          )} */}
                        <Button
                          disabled={this.state.disableEditGP}
                          variant="contained"
                          color="primary"
                          style={{
                            boxShadow: "none",
                            padding: "2px",
                            margin: ".5em",
                            fontSize: "11px",
                          }}
                          onClick={(e) =>
                            this.handleGPClickButton(e, "reset")
                          }
                        >
                          <SyncIcon style={{ fontSize: "18px" }} /> Reset
                          </Button>
                        {this.state.gpList.length > 0 && <Button
                          disabled={this.checkGpDisability()}
                          variant="contained"
                          color="primary"
                          style={{
                            boxShadow: "none",
                            padding: "2px",
                            margin: ".5em",
                            fontSize: "11px",
                          }}
                          onClick={(e) =>
                            this.handleGPClickButton(e, "clone")
                          }
                        >
                          <QueueIcon style={{ fontSize: "18px" }} /> Clone
                          </Button>}
                      </div>

                      {/* <div>
                      <EnhancedTableToolbar
                        //numSelected={selected.length}
                        handleClickButton={this.handleClickButton}
                        disableAdd={this.state.disableAdd}
                        disableEdit={this.state.disableEdit}
                      />
                    </div> */}
                      <div
                        style={{
                          // width: "100%",
                          maxHeight: "400px",
                          //
                        }}
                      >
                        <div style={
                          !this.props.clearOpenList
                            ? {
                              overflow: "auto",
                              maxWidth: "calc(100vw - 162px)",
                              maxHeight: "350px",
                              // margin: "0 .8em 0 .6em"
                            }
                            : {
                              overflow: "auto",
                              maxWidth: "calc(100vw - 390px)",
                              maxHeight: "350px",
                              // margin: "0 .8em 0 .6em"
                            }
                        }>
                          {/* //overflow: "auto" */}
                          <Table
                            aria-labelledby="tableTitle"
                            size="small"
                            stickyHeader
                            aria-label="sticky table"
                          >
                            {/* <caption style={{ borderBottom: "1px solid #eaeaea" }}>
                    A barbone structure table example with a caption
                       </caption> */}

                            <EnhancedTableHeadProcess />
                            {!this.state.loading ? (
                              <TableBody style={{ overflow: "hidden" }}>
                                {this.state.gpList.length > 0 &&
                                  this.state.gpList.map((gpl, ind) => {
                                    let pPrint = this.state.printingProcessList.filter(
                                      (d) => {
                                        if (gpl.gpPrint === d.id)
                                          return {
                                            id: d.id,
                                            text: d.text,
                                            value: d.text,
                                          };
                                      }
                                    );
                                    let pType = this.state.processTypeList.filter(
                                      (d) => {
                                        if (gpl.gpType === d.id)
                                          return {
                                            id: d.id,
                                            text: d.text,
                                            value: d.text,
                                          };
                                      }
                                    );
                                    let pl = this.state.uoProcessList.filter(
                                      (d) => {
                                        if (gpl.gpProcessName === d.id)
                                          return {
                                            id: d.id,
                                            text: d.text,
                                            value: d.text,
                                          };
                                      }
                                    );
                                    //   console.log(gpl)
                                    return (
                                      <TableRow
                                        // hover
                                        // onClick={event =>
                                        //   this.handleClick(event, row.id, row)
                                        // }
                                        //id={`tableRow${index}`}
                                        role="checkbox"
                                        //  aria-checked={isItemSelected}
                                        tabIndex={-1}
                                      // key={index}
                                      //   selected={isItemSelected}
                                      >
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          //id={labelId}
                                          // padding="default"
                                          className="table-content-cell"
                                          style={{
                                            // paddingRight: "10px",
                                            width: "250px",
                                            minWidth: "250px"
                                          }}
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            {gpl.editClicked ? (
                                              // <TextField
                                              //   id="standard-select-currency"
                                              //   select
                                              //   name="gpPrint"
                                              //   value={gpl.gpPrint}
                                              //   onChange={e =>
                                              //     this.handleChangeGPData(e, ind)
                                              //   }
                                              //   style={{
                                              //     fontSize: "12px",
                                              //     width: "141px"
                                              //   }}
                                              //   className="custom-select"
                                              // >
                                              //   {this.state.printingProcessList.map(
                                              //     option => (
                                              //       <MenuItem
                                              //         key={option.id}
                                              //         value={option.id}
                                              //       >
                                              //         {option.value}
                                              //       </MenuItem>
                                              //     )
                                              //   )}
                                              // </TextField>
                                              <Autocomplete
                                                autoComplete
                                                includeInputInList
                                                id="gpPrint"
                                                name="gpPrint"
                                                options={filteredPrintList}
                                                className={classes.autoComplete}
                                                // onChange={this.handleStatusChange}
                                                onChange={this.handleChangeGPData1}
                                                onOpen={e => this.handleAutoOpenClick(ind)}
                                                getOptionLabel={(option) =>
                                                  option.text
                                                }
                                                value={pPrint[0]}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    required
                                                    label=""
                                                    name="gpPrint"
                                                    id="gpPrint"
                                                    className={
                                                      classes.autocompleteText
                                                    }
                                                    style={{
                                                      width: "100%",
                                                    }}
                                                  // value={gpl.gpPrint}
                                                  />
                                                )}
                                              />
                                            ) : (
                                                this.state.printingProcessList.map(
                                                  (d) => {
                                                    if (gpl.gpPrint === d.id) {
                                                      if (d.value.length > 16) {
                                                        return (
                                                          <Tooltip
                                                            title={d.value}
                                                            aria-label="Print"
                                                          >
                                                            <Typography
                                                              noWrap
                                                              style={{
                                                                fontSize: "14px",
                                                              }}
                                                            >
                                                              {d.value}
                                                            </Typography>
                                                          </Tooltip>
                                                          // <div>{d.value}</div>
                                                        );
                                                      } else {
                                                        return d.value;
                                                      }
                                                    }
                                                  }
                                                )
                                              )}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          // id={labelId}
                                          // padding="default"
                                          // className="table-content-cell"
                                          // style={{
                                          //   width: "250px",
                                          //   paddingRight: "6px",
                                          // }}
                                          style={{
                                            // paddingRight: "10px",
                                            width: "400px",
                                            minWidth: "400px"
                                          }}
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            {gpl.editClicked ? (
                                              // <TextField
                                              //   id="standard-select-currency"
                                              //   select
                                              //   name="gpProcessName"
                                              //   value={gpl.gpProcessName}
                                              //   onChange={e =>
                                              //     this.handleChangeGPData(e, ind)
                                              //   }
                                              //   style={{
                                              //     fontSize: "12px",
                                              //     width: "100%"
                                              //   }}
                                              // >
                                              //   {this.state.uoProcessList.map(
                                              //     option => (
                                              //       <MenuItem
                                              //         key={option.id}
                                              //         value={option.id}
                                              //       >
                                              //         {option.value}
                                              //       </MenuItem>
                                              //     )
                                              //   )}
                                              // </TextField>
                                              <Autocomplete
                                                autoComplete
                                                includeInputInList
                                                // id="gpProcessName"
                                                id="processName-0"
                                                name="gpProcessName"
                                                options={filteredUOList}
                                                className={classes.autoComplete}
                                                // onChange={this.handleStatusChange}
                                                onChange={this.handleChangeGPData2}
                                                onOpen={e => this.handleAutoOpenClick(ind)}
                                                getOptionLabel={(option) =>
                                                  option.text
                                                }
                                                value={pl[0]}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    required
                                                    label=""
                                                    name="gpProcessName"
                                                    id="gpProcessName"
                                                    className={
                                                      classes.autocompleteText
                                                    }
                                                    style={{
                                                      width: "100%",
                                                    }}
                                                  // value={gpl.gpPrint}
                                                  />
                                                )}
                                              />
                                            ) : (
                                                this.state.uoProcessList.map(
                                                  (d) => {
                                                    if (
                                                      gpl.gpProcessName === d.id
                                                    ) {
                                                      if (d.text.length > 16) {
                                                        return (
                                                          <Tooltip
                                                            title={d.text}
                                                            aria-label="processName"
                                                          >
                                                            <Typography
                                                              noWrap
                                                              style={{
                                                                fontSize: "14px",
                                                              }}
                                                            >
                                                              {d.text}
                                                            </Typography>
                                                          </Tooltip>
                                                        );
                                                      } else {
                                                        return d.text;
                                                      }
                                                    }
                                                  }
                                                )
                                              )}
                                          </Typography>
                                        </TableCell>

                                        <TableCell
                                          component="th"
                                          scope="row"
                                          //id={labelId}
                                          // padding="default"
                                          // align="left"
                                          style={{
                                            paddingRight: "7px",
                                            width: "260px",
                                            minWidth: "260px"
                                          }}
                                          className="table-content-cell"
                                        // style={{
                                        //   width: "250px",
                                        //   paddingRight: "6px",
                                        // }}
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            {gpl.editClicked ? (
                                              // <TextField
                                              //   id="standard-select-currency"
                                              //   select
                                              //   name="gpType"
                                              //   value={gpl.gpType}
                                              //   onChange={e =>
                                              //     this.handleChangeGPData(e, ind)
                                              //   }
                                              //   style={{
                                              //     width: "100%"
                                              //   }}
                                              // >
                                              //   {this.state.processTypeList.map(
                                              //     option => (
                                              //       <MenuItem
                                              //         key={option.id}
                                              //         value={option.id}
                                              //       >
                                              //         {option.text}
                                              //       </MenuItem>
                                              //     )
                                              //   )}
                                              // </TextField>
                                              <Autocomplete
                                                autoComplete
                                                includeInputInList
                                                id="gpType"
                                                name="gpType"
                                                options={filteredTypeList}
                                                // className={classes.autoComplete}
                                                // onChange={this.handleStatusChange}
                                                onChange={this.handleChangeGPData3}
                                                onOpen={e => this.handleAutoOpenClick(ind)}
                                                getOptionLabel={(option) =>
                                                  option.text
                                                }
                                                style={{
                                                  width: "100%",
                                                }}
                                                value={pType[0]}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    required
                                                    label=""
                                                    name="gpType"
                                                    id="gpType"
                                                    className={
                                                      classes.autocompleteText
                                                    }
                                                    style={{
                                                      width: "100%",
                                                    }}
                                                  // value={gpl.gpPrint}
                                                  />
                                                )}
                                              />
                                            ) : (
                                                this.state.processTypeList.map(
                                                  (d) => {
                                                    if (gpl.gpType === d.id) {
                                                      if (d.text.length > 16) {
                                                        return (
                                                          <Tooltip
                                                            title={d.text}
                                                            aria-label="Type"
                                                          >
                                                            <Typography
                                                              noWrap
                                                              style={{
                                                                fontSize: "14px",
                                                              }}
                                                            >
                                                              {d.text}
                                                            </Typography>
                                                          </Tooltip>
                                                        );
                                                      } else {
                                                        return d.text;
                                                      }
                                                    }
                                                  }
                                                )
                                              )}
                                          </Typography>
                                        </TableCell>

                                        <TableCell
                                          component="th"
                                          scope="row"
                                          // id={labelId}
                                          // padding="default"
                                          align="right"
                                          className="table-content-right-cell"
                                          style={{
                                            width: "70px",
                                            minWidth: "70px",
                                            paddingRight: "6px",
                                          }}
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            {gpl.editClicked ? (
                                              <Input
                                                // defaultValue={row.remark}
                                                onKeyPress={
                                                  this._onNumberKeyPress
                                                }
                                                inputProps={{
                                                  "aria-label": "description",
                                                }}
                                                inputProps={{
                                                  maxLength: 10,
                                                }}
                                                value={gpl.gpQuantity}
                                                name="gpQuantity"
                                                onChange={e => this.handleChangeGPDataNoneAutoC(e, ind)}
                                              />
                                            ) : (
                                                gpl.gpQuantity
                                              )}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          // id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                          style={{
                                            width: "150px",
                                            minWidth: "150px",
                                            paddingRight: "6px",
                                          }}
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {" "}
                                            {gpl.editClicked ? (
                                              <Input
                                                // defaultValue={row.remark}
                                                // onKeyPress={this._onAlphaKeyPress}
                                                name="gpRemark"
                                                inputProps={{
                                                  "aria-label": "description",
                                                }}
                                                inputProps={{
                                                  maxLength: 50,
                                                }}
                                                value={gpl.gpRemark}
                                                onChange={e => this.handleChangeGPDataNoneAutoC(e, ind)}
                                              />
                                            ) : gpl.gpRemark.length > 16 ? (
                                              <Tooltip
                                                title={gpl.gpRemark}
                                                aria-label="Remarks"
                                              >
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {gpl.gpRemark}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                                  // <div>{d.value}</div>
                                                  gpl.gpRemark
                                                )}
                                          </Typography>
                                        </TableCell>

                                        <TableCell
                                          component="th"
                                          scope="row"
                                          // id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                          style={{
                                            width: "90px",
                                            minWidth: "90px",
                                            paddingRight: "6px",
                                          }}
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                              paddingTop: "7px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                cursor: "pointer",
                                                color: "#6c6b6b",
                                              }}
                                              onClick={(e) =>
                                                this.onEditClickedGP(e, ind)
                                              }
                                            >
                                              {!gpl.editClicked ? (
                                                <EditIcon
                                                  style={{
                                                    color: "#6c6b6b",
                                                  }}
                                                />
                                              ) : (
                                                  <CheckIcon
                                                    style={{
                                                      color: "#6c6b6b",
                                                    }}
                                                  />
                                                )}
                                            </span>

                                            <DeleteIcon
                                              onClick={(e) =>
                                                this.onDeleteClickedGP(e, ind)
                                              }
                                              style={{
                                                cursor: "pointer",
                                                color: "#6c6b6b",
                                              }}
                                            //  onClick={e => this.onDeleteClicked(e, index)}
                                            />
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            ) : (
                                <TableBody>
                                  <TableRow>
                                    <TableCell colSpan="9">
                                      <LinearProgress />
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              )}
                          </Table>
                        </div>
                      </div>
                    </div>
                  )}
                <Divider style={{ marginTop: "25px" }} />
                <div style={{ display: "flex" }} className="button-wrapper">
                  <div style={{}}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => {
                        this.props.history.push("/genericSystem");
                      }}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {this.checkGpDisability() ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={(e) => this.handleSubmitGP(e)}
                      >
                        Submit
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={(e) => this.handleSubmitGP(e)}
                        >
                          Submit
                        </Button>
                      )}
                  </div>
                </div>
              </React.Fragment>
            </TabPanel>
          </div>
        </div>
      </Paper>
    );
  }
}
export default withRouter(withStyles(addDispatchStyles)(AddUserOrder));
