import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_PUBLISHER_STOCK_MANAGEMENT;
const pgsService = axios.create({
  baseURL,
});
let count = 1;

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getPreAlertAll = async () => {
  let pol = [];
  await pgsService
    .get("/GetPreAlert")
    .then((r) => {
      pol = r;
    })
    .catch((e) => { });
  return pol;
};
export const getResetPreAlertStaging = async (id) => {
  let pol = [];
  await pgsService
    .put(`/ResetPreAlertStaging/${id}`)
    .then((r) => {
      pol = r;
    })
    .catch((e) => { });
  return pol;
};
export const getPreAlertByID = async (id) => {
  let pol = [];
  await pgsService
    .get("/GetPreAlertDetailID/" + id)
    .then((r) => {
      pol = r;
    })
    .catch((e) => { });
  return pol;
};

export const GetPreAlertDownLoad = async (id) => {
  let pol = [];
  await pgsService
    .get("/GetPreAlertDownLoad/" + id)
    .then((r) => {
      pol = r;
    })
    .catch((e) => { });
  return pol;
};

export const deletePreAlert = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete("/DeletePreDetail/" + `${deletionIds}` + "/" + `${modifiedBy}`)
    .then((r) => {
      // toastr.success("Pre Alert Deleted Successfully");
      if (r.data.includes("Already")) toastr.error(r.data);
      else toastr.success(r.data);
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const uploadPreAlertFiles = async (data) => {
  let result = [];
  await pgsService
    .post("/Create", data)
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else result = r.data;
    })
    .catch((e) => {
      // if (e.response.data[0].exceptionMessage !== undefined) {
      //   if (
      //     e.response &&
      //     e.response.data &&
      //     e.response.data[0].exceptionMessage ==
      //     "Template Issue An error occurred while updating the entries. See the inner exception for details."
      //   ) {
      //     toastr.error("Template Issue");
      //   } else {
      //     toastr.error(e.response.data[0].exceptionMessage);
      //   }
      // } else {
      //   toastr.error(e.response.data[0].exceptionMessage);
      // }
    });
  return result;
  // }
};

export const submitPreAlertFiles = async (data, history) => {
  let result = [];
  await pgsService
    .post("/Submit", data)
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else {
        toastr.success("Pre Alert Created Successful");
        result = r.data;
        history.push("/pubPreAlert");
      }
    })
    .catch((e) => { });
  return result;
  // }
};

export const putForPreAlert = async (data, history, id) => {
  let result = [];
  await pgsService
    .put(`/EditPreAlertDetailID/${id}`, data)
    .then((r) => {
      if (r && r.data && r.data.isUpdated) {
        toastr.success("Pre Alert Updated Successful");
        history.push("/pubPreAlert");
        result = r.data;
      } else {
        toastr.error(r && r.data && r.data.responseMessage);
      }
    })
    .catch((e) => {
      console.log(e, "e");
    });
  return result;
};

export const getCustomerForPreAlertList = async () => {
  let customerList = [];
  await pgsService
    .get("/GetPublisherCustomer")
    .then((r) => {
      customerList = r;
    })
    .catch((e) => { });
  return customerList;
};

export const putPrealert = async (preAlertStaggingID, data) => {
  let result = [];
  await pgsService
    .put("/Update/" + preAlertStaggingID, data)
    .then((r) => {
      toastr.success("Pre Alert Updated Successful");
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else result = r.data;
    })
    .catch((e) => { });
  return result;
  // }
};

export const getFliteredpreAlertList = async (data) => {
  let result = [];
  await pgsService
    .post("/PreAlertAdvanceSerach", data)
    .then((r) => {
      // toastr.success("Pre Alert Update Successful");
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else result = r.data;
    })
    .catch((e) => { });
  return result;
  // }
};
export const updateJRApi = async (reqBody) => {
  let result = [];
  let modifiedBy = window.localStorage.loggedInId;
  await pgsService
    .put("/UpdateToProceed", reqBody)
    .then((r) => {
      toastr.success("Journal Processed Successfully");
      // if (r.data.errors && r.data.errors.length > 0) {
      //   toastr.error(r.data.errors[0].message);
      //   return;
      // } else result = r.data;
    })
    .catch((e) => { });
  return result;
};

export const deletePreAlertById = async (id) => {
  await pgsService
    .delete("/DeletePreAlertStagging/" + `${id}`)
    .then((r) => {
      toastr.success("Pre Alert Staging Detail Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getStatusForPreAlertList = async () => {
  let customerList = [];
  await pgsService
    .get("/GetPreAlertStatus")
    .then((r) => {
      customerList = r;
    })
    .catch((e) => { });
  return customerList;
};
