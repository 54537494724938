import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_GENRIC_DOCKET;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getRunMailList = async (id) => {
  let runMailList = [];
  await pgsService
    .get("/RMLGetAll/" + id)
    .then((r) => {
      runMailList = r;
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return runMailList;
};

export const postDocket = async (reqBody) => {
  let docketList = false;
  await pgsService
    .post("/DPCreate", reqBody)
    .then((r) => {
      docketList = true;
      toastr.success("Docket Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
      toastr.success("Docket Creation Failed");
    });
  return docketList;
};

export const GetAllDocketsByJGID = async (jobGroupID) => {
  let docketList = [];
  await pgsService
    .get("/DPGetAll/" + jobGroupID)
    .then((r) => {
      docketList = r;
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return docketList;
};

export const GetDocketByHeaderID = async (headerID) => {
  let docketList = [];
  await pgsService
    .get("/DPGetByID/" + headerID)
    .then((r) => {
      docketList = r;
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return docketList;
};

export const updateDocket = async (reqBody) => {
  let docketList = false;
  await pgsService
    .put("/DPUpdate", reqBody)
    .then((r) => {
      docketList = true;
      // this.props.history.push("/runMailList/" + reqBody.jobGroupID);
      toastr.success("Docket Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
      toastr.error("Docket Updation Failed");
    });
  return docketList;
};

export const getRunMailListByID = async (runMailID) => {
  let runMailList = [];
  await pgsService
    .get("/RMLGetByID/" + runMailID)
    .then((r) => {
      runMailList = r;
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return runMailList;
};

export const createRunMailList = async (reqBody) => {
  let runMailList = false;
  await pgsService
    .post("/RMLCreate", reqBody)
    .then((r) => {
      runMailList = true;
      toastr.success("Run Mail List Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
      toastr.error("Run Mail List Create Failed");
    });
  return runMailList;
};

export const updateRunMailList = async (reqBody, runMailID) => {
  let runMailList = false;
  await pgsService
    .put("/RMLUpdate/" + runMailID, reqBody)
    .then((r) => {
      runMailList = true;
      toastr.success("Run Mail List Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
      toastr.error("Run Mail List Update Failed");
    });
  return runMailList;
};

export const deleteRunMailList = async (modifiedBy, deletionIds) => {
  let runMailList = false;
  await pgsService
    .delete("/RMLDelete", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      runMailList = true;
      toastr.success("Run Mail List Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
      toastr.error("Run Mail List Delete Failed");
    });
  return runMailList;
};

export const deleteDocket = async (modifiedBy, deletionIds) => {
  let runMailList = false;
  await pgsService
    .delete("/DPDelete", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      runMailList = true;
      toastr.success("Docket Deleted Successfully");
    })
    .catch((e) => {
      // if (e.response && e.response.data.errors[0].message !== undefined) {
      //   toastr.error(e.response.data.errors[0].message);
      // } else if (e.response && e.response.data.errors[0]) {
      //   toastr.error(e.response.data.errors[0]);
      // }
      // toastr.error("Docket Delete Failed");
    });
  return runMailList;
};

export const getPaymentModeTypeList = async () => {
  let paymentModeTypeList = [];
  await pgsService
    .get("/GetPaymentModeDropdown")
    .then((r) => {
      paymentModeTypeList = r;
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return paymentModeTypeList;
};
export const getArticleTypeList = async () => {
  let articleTypeList = [];
  await pgsService
    .get("/GetTypeOfArticleDropdown")
    .then((r) => {
      articleTypeList = r;
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return articleTypeList;
};
export const getMailCharacteristicsList = async () => {
  let mailCharacteristicsList = [];
  await pgsService
    .get("/GetMailCharDropdown")
    .then((r) => {
      mailCharacteristicsList = r;
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return mailCharacteristicsList;
};
export const getMarsResellerNumberList = async () => {
  let marsResellerNumberList = [];
  await pgsService
    .get("/GetMARSResellerDropdown")
    .then((r) => {
      marsResellerNumberList = r;
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return marsResellerNumberList;
};

export const getMailSizeList = async () => {
  let mailSizeList = [];
  await pgsService
    .get("/GetMailSizeDropdown")
    .then((r) => {
      mailSizeList = r;
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return mailSizeList;
};

export const getCustomerPPIDropDown = async () => {
  let PPIList = [];
  await pgsService
    .get("/GetPPIDropdownLists")
    .then((r) => {
      PPIList = r;
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return PPIList;
};
export const getFileNamesDropDownWithID = async (id) => {
  let fileNameList = [];
  await pgsService
    .get("/GetFilenameDropdownList/" + id)
    .then((r) => {
      fileNameList = r;
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return fileNameList;
};
export const getFileNamesDropDown = async () => {
  let fileNameList = [];
  await pgsService
    .get("/GetFilenameDropdownList")
    .then((r) => {
      fileNameList = r;
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return fileNameList;
};

export const getTransmissionModeDropDown = async () => {
  let transmissionModeList = [];
  await pgsService
    .get("/getTransModeDropDown")
    .then((r) => {
      transmissionModeList = r;
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return transmissionModeList;
};

export const getPreDefinedDocketData = async (workOrderID) => {
  let docketList = [];
  await pgsService
    .get("/GetWorkOrderDetails/" + workOrderID)
    .then((r) => {
      docketList = r;
    })
    .catch((e) => {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[0].message !== undefined
      ) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return docketList;
};
