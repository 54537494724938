import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import AdvanceSearch from "./search";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import clone from "clone";
import DeleteIcon from "@material-ui/icons/Delete";
import { ExportCSV } from "../../../shared/ExportCSV";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import moment from "moment";
import {
  SearchIcon,
  ViewColumnIcon,
  ViewIcon,
  EditIcon,
  AddIcon,
} from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { Checkbox } from "@material-ui/core";
import {
  stableSort,
  addZeros,
  getSorting,
  DialogActions,
  // CustomCheckbox
} from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import FormGroup from "@material-ui/core/FormGroup";
import {
  getCustomerList,
  getLocationDropdownList,
  getRoutingCustomerList,
} from "../../masters/masterActions";
import {
  getRoutingTitleList,
  deleteRoutingTitle,
  getFliteredRoutingTitleList,
} from "../routingActions";
import { StateContext } from "../../../shared/globalState";
import types from "../routingTypes";
import { Link } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
const columns = [
  {
    name: "No Of Copies From",
    id: 10,
    show: false,
  },
  {
    name: "No Of Copies To",
    id: 11,
    show: false,
  },
  {
    name: "Weight From",
    id: 12,
    show: false,
  },
  {
    name: "Weight To",
    id: 13,
    show: false,
  },
  {
    name: "PPI Number",
    id: 14,
    show: false,
  },
  {
    name: "PPI License No",
    id: 15,
    show: false,
  },
  {
    name: "Dispatch Type",
    id: 16,
    show: false,
  },
  {
    name: "Dispatch Mode",
    id: 17,
    show: false,
  },
  {
    name: "Mailing Scheme",
    id: 18,
    show: false,
  },
  {
    name: "Distributor",
    id: 19,
    show: false,
  },
  {
    name: "Return Address",
    id: 20,
    show: false,
  },
  {
    name: "Carrier Sheet Scheme",
    id: 21,
    show: false,
  },
  {
    name: "Carrier Sheet Mode",
    id: 22,
    show: false,
  },
  {
    name: "Active",
    id: 23,
    show: false,
  },
  {
    name: "Created By",
    id: 24,
  },
  {
    name: "Created Date",
    id: 25,
  },
  {
    name: "Modified By",
    id: 26,
  },
  {
    name: "Modified Date",
    id: 27,
    show: false,
  },
];

function createData(
  customerCode,
  customerName,
  locationName,
  subscriptionName,
  countryCode,
  countryName,
  acronymName,
  noOfOrdersFrom,
  noOfOrdersTo,
  noOfCopiesFrom,
  noOfCopiesTo,
  weightFrom,
  weightTo,
  ppiNumber,
  ppiLicenseNo,
  dispatchTypeName,
  dispatchModeName,
  mailingSchemeName,
  distributorName,
  returnAddress,
  carrierSheetScheme,
  carrierSheetMode,
  isActive,
  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate,
  routingTitleID
) {
  return {
    customerCode,
    customerName,
    locationName,
    subscriptionName,
    countryCode,
    countryName,
    acronymName,
    noOfOrdersFrom,
    noOfOrdersTo,
    noOfCopiesFrom,
    noOfCopiesTo,
    weightFrom,
    weightTo,
    ppiNumber,
    ppiLicenseNo,
    dispatchTypeName,
    dispatchModeName,
    mailingSchemeName,
    distributorName,
    returnAddress,
    carrierSheetScheme,
    carrierSheetMode,
    isActive,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
    routingTitleID,
  };
}
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ borderTop: "1px solid #eaeaea", background: "white" }}
          className="table-cell"
        >
          <FormControlLabel
            control={
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                color="primary"
                name="all"
                inputProps={{ "aria-label": "select all Routing Title" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [formValid, setIsformValid] = useState(false);

  const [routingTitleSearchValue, setValues] = useState({
    customer: "",
    location: "",
  });
  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);

  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handleDeleteRoutingTitle,
    searchPopupOpen,
    //selectedRoutingTitle,
    //selectedRoutingTitleID,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchRoutingTitle,
    searchClicked,
    customersList,
    LocationList,
    RoutingTitleList,
    hendleVewColums,
    isActive,
    exportDataValues,
    selected,
  } = props;
  //const selectedRoutingTitleID = selected && selected.length > 0 && selected[0];
  //const selectedRoutingTitle = routingTitleList.find(x=>x.selectedRoutingTitleID==selectedRoutingTitleID)
  const selectedRoutingTitleID = selected[0];
  const selectedRoutingTitle = RoutingTitleList.find(
    (x) => x.routingTitleID === selectedRoutingTitleID
  );
  let acnArr = props.roles.filter((u) => u.menuName === "Routing Title");
  let acnObj = acnArr[0];
  let expData = clone(RoutingTitleList);
  expData.map((d) => {
    let Val = "No";
    if (d["isActive"]) {
      Val = "Yes";
    }

    d["Customer Code"] = d["customerCode"];
    d["Customer Name"] = d["customerName"];
    d["Location Name"] = d["locationName"];
    d["Subscription"] = d["subscriptionName"];
    d["Country Code"] = d["countryCode"];

    d["Country"] = d["countryName"];
    d["Acronym"] = d["acronymName"];

    d["No Of Orders From"] = d["noOfOrdersFrom"];
    d["No Of Orders To"] = d["noOfOrdersTo"];
    d["No Of Copies From"] = d["noOfCopiesFrom"];
    d["No Of Copies To"] = d["noOfCopiesTo"];
    d["Weight From (Kg)"] = d["weightFrom"];
    d["Weight To (Kg)"] = d["weightTo"];

    d["Route PPI"] = d["ppiNumber"];
    d["PPI License No"] = d["ppiLicenseNo"];
    d["Dispatch Type"] = d["dispatchTypeName"];
    d["Dispatch Mode"] = d["dispatchModeName"];
    d["Mailing Scheme"] = d["mailingSchemeName"];
    d["Distributor"] = d["distributorName"];

    d["Return Address"] = d["returnAddress"];
    d["Carrier Sheet Scheme"] = d["carrierSheetScheme"];

    d["Carrier Sheet Mode"] = d["carrierSheetMode"];

    d["Active"] = Val;
    d["Modified By"] = d["modifiedBy"];

    d["Modified Date"] = moment(d["modifiedDate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    );
    d["Created By"] = d["createdBy"];
    d["Created Date"] = moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss");

    delete d["customerCode"];
    delete d["customerName"];
    delete d["countryCode"];
    delete d["countryName"];
    delete d["subscriptionName"];

    delete d["acronymName"];
    delete d["addressType"];
    delete d["distributorID"];
    delete d["isActive"];
    delete d["locationName"];

    delete d["noOfOrdersFrom"];
    delete d["noOfOrdersTo"];
    delete d["noOfCopiesFrom"];
    delete d["noOfCopiesTo"];
    delete d["weightFrom"];
    delete d["weightTo"];

    delete d["ppiNumber"];

    delete d["ppiLicenseNo"];

    delete d["dispatchTypeName"];
    delete d["dispatchModeName"];
    delete d["mailingSchemeName"];
    delete d["distributorName"];

    delete d["returnAddress"];
    delete d["carrierSheetScheme"];
    delete d["carrierSheetMode"];
    delete d["createdBy"];
    delete d["createdDate"];
    delete d["modifiedBy"];
    delete d["modifiedDate"];

    delete d["routingTitleID"];
  });
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteRoutingUKUS = () => {
    handleDeleteRoutingTitle();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };
  const handleSearchValuesChange = (event, values) => {
    event.persist();
    let name = event.target.name;
    setValues((routingTitleSearchValue) => ({
      ...routingTitleSearchValue,
      customer: values,
    }));
    //setValues(cptSearchValue => ({ ...cptSearchValue, [event.target.name]: event.target.value }));
    setIsformValid(true);
    switch (name) {
    }
  };
  const handleSearchLocationValuesChange = (event, values) => {
    event.persist();
    let name = event.target.name;
    setValues((routingTitleSearchValue) => ({
      ...routingTitleSearchValue,
      location: values,
    }));
    setIsformValid(true);
    switch (name) {
    }
  };

  const clearSearchValues = () => {
    let searchValues = routingTitleSearchValue;
    searchValues.customer = "";
    searchValues.location = "";
    searchValues = routingTitleSearchValue;
  };

  const searchPopup = (reqBody) => {
    handleSearchRoutingTitle(reqBody);
    clearSearchValues();
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_RoutingTitle/${selectedRoutingTitleID}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <Link to={`/edit_RoutingTitle/${selectedRoutingTitleID}`}>
                      <IconButton
                        aria-label="edit"
                        className={classes.iconHover}
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={handleClickOpen}
                  disabled={!isActive}
                >
                  <DeleteIcon className={classes.iconHover} />
                </IconButton>
              </Tooltip>
            )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete Routing Title
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1
                      ? " ?"
                      : selectedRoutingTitle.customerName + " ?"}
                    {/* {selectedRoutingTitle.customerName} */}
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteRoutingUKUS}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {searchClicked ? (
              <Tooltip title="Clear search result">
                <IconButton
                  aria-label="clear"
                  className={classes.iconHover}
                  onClick={props.clearSearch}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            ) : (
              acnObj &&
              acnObj.advanceSearchAction && (
                <AdvanceSearch searchPopup={searchPopup} />
              )
            )}
            {acnObj && acnObj.addAction && (
              <Tooltip title="Add">
                <IconButton
                  aria-label="add"
                  className={classes.iconHover}
                  onClick={(e) => props.history.push("/add_RoutingTitle")}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
            {acnObj && acnObj.exportAction && (
              <Tooltip title="Download">
                <ExportCSV csvData={expData} fileName={"RoutingByTitle"} />
              </Tooltip>
            )}

            {/* <Tooltip title="Download">
              <IconButton aria-label="download" className={classes.iconHover}>
                <DownloadIcon />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="View Column">
              <IconButton
                aria-label="viewColumn"
                className={classes.iconHover}
                onClick={handleClickListItem}
              >
                <ViewColumnIcon />
              </IconButton>
            </Tooltip>
            <EnhancedShowColumn
              id="columns-menu"
              keepMounted
              open={open}
              onClose={handleShowColClose}
              column={column}
              hendleVewColums={hendleVewColums}
              headCells={props.headCells}
            />
          </div>
        )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class RoutingTitleMaster extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    RoutingTitleList: [],
    rows: [],
    activeRows: 0,
    isActive: false,

    refreshData: false,
    selectedRoutingTitle: {},
    // selectedRoutingTitleID: 1,
    loading: false,
    searchPopupOpen: false,
    routingTitleSearchValues: {},
    searchClicked: false,
    selectedShowColumns: [],
    customersList: [],
    LocationList: [],
    exportDataValues: [],
    headCells: [
      {
        id: "1",
        code: "customerCode",
        numeric: false,
        disablePadding: true,
        label: "Customer Code",
        show: true,
      },

      {
        id: "2",
        code: "customerName",
        numeric: false,
        disablePadding: true,
        label: "Customer",
        show: true,
      },
      {
        id: "3",
        code: "locationName",
        numeric: false,
        disablePadding: false,
        label: "Location",
        show: true,
      },
      {
        id: "4",
        code: "subscriptionName",
        numeric: false,
        disablePadding: false,
        label: "Subscription",
        show: true,
      },
      {
        id: "5",
        code: "countryCode",
        numeric: false,
        disablePadding: false,
        label: "Country Code",
        show: true,
      },
      {
        id: "6",
        code: "countryName",
        numeric: false,
        disablePadding: false,
        label: "Country",
        show: true,
      },
      {
        id: "7",
        code: "acronymName",
        numeric: false,
        disablePadding: false,
        label: "Acronym",
        show: true,
      },

      {
        id: "8",
        code: "noOfOrdersFrom",
        numeric: false,
        disablePadding: false,
        label: "No Of Orders From",
        show: true,
      },
      {
        id: "9",
        code: "noOfOrdersTo",
        numeric: false,
        disablePadding: false,
        label: "No Of Orders To",
        show: true,
      },
      {
        id: "10",
        code: "noOfCopiesFrom",
        numeric: false,
        disablePadding: false,
        label: "No Of Copies From",
        show: true,
      },
      {
        id: "11",
        code: "noOfCopiesTo",
        numeric: false,
        disablePadding: false,
        label: "No Of Copies To",
        show: true,
      },
      {
        id: "12",
        code: "weightFrom",
        numeric: false,
        disablePadding: false,
        label: "Weight From (Kg)",
        show: true,
      },
      {
        id: "13",
        numeric: false,
        code: "weightTo",
        disablePadding: false,
        label: "Weight To (Kg)",
        show: true,
      },
      {
        id: "14",
        code: "ppiNumber",
        numeric: false,
        disablePadding: false,
        label: "Route PPI",
        show: true,
      },
      {
        id: "15",
        code: "ppiLicenseNo",
        numeric: false,
        disablePadding: false,
        label: "PPI License No",
        show: true,
      },
      {
        id: "16",
        code: "dispatchTypeName",
        numeric: false,
        disablePadding: false,
        label: "Dispatch Type",
        show: true,
      },
      {
        id: "17",
        code: "dispatchModeName",
        numeric: false,
        disablePadding: false,
        label: "Dispatch Mode",
        show: true,
      },
      {
        id: "18",
        code: "mailingScheme",
        numeric: false,
        disablePadding: false,
        label: "Mailing Scheme",
        show: true,
      },
      {
        id: "19",
        code: "distributorName",
        numeric: false,
        disablePadding: false,
        label: "Distributor",
        show: true,
      },
      {
        id: "20",
        code: "returnAddress",
        numeric: false,
        disablePadding: false,
        label: "Return Address",
        show: true,
      },
      {
        id: "21",
        code: "carrierSheetScheme",
        numeric: false,
        disablePadding: false,
        label: "Carrier Sheet Scheme",
        show: true,
      },
      {
        id: "22",
        code: "carrierSheetMode",
        numeric: false,
        disablePadding: false,
        label: "Carrier Sheet Mode",
        show: true,
      },
      {
        id: "23",
        code: "isActive",
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true,
      },
      {
        id: "24",
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },

      {
        id: "25",
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: "26",
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: "27",
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
    ],
  };
  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Routing Title");
    this.props.setSelectedList("masterDataOpen", "routingTitle");
    this.setState({ loading: true });

    getRoutingTitleList().then((r) => {
      dispatch({
        type: types.RoutingTitleList,
        newStore: r.data,
      });

      this.getAllRoutingTitle(r.data);
      this.exportCptDatas(r.data);
    });
  }

  onRequestSucceed = () => {
    const list = this.context;
    setTimeout(() => {
      let RoutingTitles = list.state.routingTitleList;
      this.getAllRoutingTitle(RoutingTitles);
    }, 1700);
  };
  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };
  exportCptDatas = (RoutingEMLOEBLOList) => {
    let list = RoutingEMLOEBLOList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          customerCode: d.customerCode,
          customerName: d.customerName,
          locationName: d.locationName,
          subscriptionName: d.subscriptionName,
          countryCode: d.countryCode,
          countryName: d.countryName,
          acronymName: d.acronymName,
          noOfOrdersFrom: d.noOfOrdersFrom,
          noOfOrdersTo: d.noOfOrdersTo,
          noOfCopiesFrom: d.noOfCopiesFrom,
          noOfCopiesTo: d.noOfCopiesTo,
          weightFrom: d.weightFrom,
          weightTo: d.weightTo,
          ppiNumber: d.ppiNumber,
          ppiLicenseNo: d.ppiLicenseNo,
          dispatchTypeName: d.dispatchTypeName,
          dispatchModeName: d.dispatchModeName,
          mailingSchemeName: d.mailingSchemeName,
          distributorID: d.distributorID,
          returnAddress: d.returnAddress,
          carrierSheetScheme: d.carrierSheetScheme,
          carrierSheetMode: d.carrierSheetMode,
          isActive: d.isActive === true ? "Yes" : "No",
          createdBy: d.createdBy,
          createdDate: d.createdDate,
          modifiedBy: d.modifiedBy,
          modifiedDate: d.modifiedDate,
        });
      });
    this.setState({ exportDataValues: rows });
  };
  getAllRoutingTitle = (RoutingTitleList) => {
    let list = RoutingTitleList;
    let rows = [];
    list &&
      list.map((d) => {
        return rows.push(
          createData(
            d.customerCode,
            d.customerName,
            d.locationName,
            d.subscriptionName,
            d.countryCode,
            d.countryName,
            d.acronymName,
            d.noOfOrdersFrom,
            d.noOfOrdersTo,
            d.noOfCopiesFrom,
            d.noOfCopiesTo,
            d.weightFrom,
            d.weightTo,
            d.ppiNumber,
            d.ppiLicenseNo,
            d.dispatchTypeName,
            d.dispatchModeName,
            d.mailingSchemeName,
            d.distributorName,
            d.returnAddress,
            d.carrierSheetScheme,
            d.carrierSheetMode,
            d.isActive,
            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate,
            d.routingTitleID
          )
        );
      });
    this.setState({
      RoutingTitleList: rows,
      loading: false,
      selected: [],
      activeRows: rows.filter((x) => x.isActive).map((n) => n.routingTitleID),
    });
    this.sortStateList(rows);
  };

  sortStateList = (list) => {
    let z = list.sort(function (a, b) {
      let x = a.isActive && a.isActive;
      let y = b.isActive && b.isActive;
      let u = a.modifiedDate && a.modifiedDate;
      let v = b.modifiedDate && b.modifiedDate;
      if (x < y) {
        return 1;
      }
      if (u < v) {
        return -1;
      }
      if (x > y) {
        return -1;
      }
      if (u > v) {
        return 1;
      }
      return 0;
    });

    this.setState({ rows: z });
  };

  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];

    if (store.RoutingTitleList && store.RoutingTitleList.length > 0)
      if (
        this.state.RoutingTitleList.length !== store.RoutingTitleList.length
      ) {
        this.getAllRoutingTitle(store.RoutingTitleList);
      }
    return true;
  }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { RoutingTitleList } = this.state;
    if (event.target.checked) {
      const newSelecteds = RoutingTitleList.filter(
        (x) => x.isActive === true
      ).map((n) => n.routingTitleID);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, data) {
    let routingTitleID = data.routingTitleID;
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(routingTitleID);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, routingTitleID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedRoutingTitle: data,
        selectedRoutingTitleID: routingTitleID,
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        // selectedRoutingTitle: {}
        selectedRoutingTitle: data,
        selectedRoutingTitleID: routingTitleID,
        //selectedRoutingTitleID: 0
      });
    }
  }
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchRoutingTitle = (handleSearchRoutingTitle) => {
    getFliteredRoutingTitleList(handleSearchRoutingTitle).then((r) => {
      this.getAllRoutingTitle(r.data);
      this.setState({ searchPopupOpen: false, searchClicked: true, page: 0 });
    });
  };

  handleDeleteRoutingTitle = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteRoutingTitle(modifiedBy, deletionIds).then((r) => {
      getRoutingTitleList().then((r) => {
        dispatch({
          type: types.RoutingTitleList,
          newStore: r.data,
        });
        this.getAllRoutingTitle(r.data);
      });

      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };

  clearSearch = () => {
    const dispatch = this.context[1];
    getRoutingTitleList().then((r) => {
      dispatch({
        type: types.RoutingTitleList,
        newStore: r.data,
      });
      this.getAllRoutingTitle(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      RoutingTitleList,
      exportDataValues,
      headCells,
    } = this.state;
    const isSelected = (routingTitleID) =>
      selected.indexOf(routingTitleID) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteRoutingTitle={this.handleDeleteRoutingTitle}
            selectedRoutingTitle={this.state.selectedRoutingTitle}
            selectedRoutingTitleID={this.state.selectedRoutingTitleID}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchRoutingTitle={this.handleSearchRoutingTitle}
            routingTitleSearchValue={this.state.routingTitleSearchValues}
            searchClicked={this.state.searchClicked}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            customersList={this.state.customersList}
            LocationList={this.state.LocationList}
            exportDataValues={exportDataValues}
            RoutingTitleList={this.state.RoutingTitleList}
            isActive={this.state.isActive}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 8em)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
                  : {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 330px)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ whiteSpace: "nowrap" }}
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {RoutingTitleList.length > 0 ? (
                    stableSort(RoutingTitleList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.routingTitleID);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              this.handleClick(
                                event,
                                row
                                // row.routingTitleID,
                                //row.customerName
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            //key={row.routingTitleID}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.customerName}
                                </Typography> */}
                                {row.customerCode &&
                                row.customerCode.length > 16 ? (
                                  <Tooltip
                                    title={row.customerCode}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerCode}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.customerCode}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.customerName}
                                </Typography> */}
                                {row.customerName &&
                                row.customerName.length > 16 ? (
                                  <Tooltip
                                    title={row.customerName}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.customerName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.locationName} */}
                                {row.locationName &&
                                row.locationName.length > 16 ? (
                                  <Tooltip
                                    title={row.locationName}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.locationName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.locationName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.subscriptionName} */}
                                {row.subscriptionName &&
                                row.subscriptionName.length > 16 ? (
                                  <Tooltip
                                    title={row.subscriptionName}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.subscriptionName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.subscriptionName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.countryCode} */}
                                {row.countryCode &&
                                row.countryCode.length > 16 ? (
                                  <Tooltip
                                    title={row.countryCode}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.countryCode}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.countryCode}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.countryName} */}
                                {row.countryName &&
                                row.countryName.length > 16 ? (
                                  <Tooltip
                                    title={row.countryName}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.countryName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.countryName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.acronymName} */}
                                {row.acronymName &&
                                row.acronymName.length > 16 ? (
                                  <Tooltip
                                    title={row.acronymName}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.acronymName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.acronymName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* <Typography noWrap>
                                  {row.noOfOrdersFrom}
                                </Typography> */}
                                {row.noOfOrdersFrom &&
                                row.noOfOrdersFrom.length > 16 ? (
                                  <Tooltip
                                    title={row.noOfOrdersFrom}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noOfOrdersFrom}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.noOfOrdersFrom}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.noOfOrdersTo} */}
                                {row.noOfOrdersTo &&
                                row.noOfOrdersTo.length > 16 ? (
                                  <Tooltip
                                    title={row.noOfOrdersTo}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noOfOrdersTo}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.noOfOrdersTo}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.noOfCopiesFrom} */}
                                {row.noOfCopiesFrom &&
                                row.noOfCopiesFrom.length > 16 ? (
                                  <Tooltip
                                    title={row.noOfCopiesFrom}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noOfCopiesFrom}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.noOfCopiesFrom}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.noOfCopiesTo} */}
                                {row.noOfCopiesTo &&
                                row.noOfCopiesTo.length > 16 ? (
                                  <Tooltip
                                    title={row.noOfCopiesTo}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noOfCopiesTo}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.noOfCopiesTo}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {addZeros(row.weightFrom.toString(), 2)}
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {addZeros(row.weightTo.toString(), 2)}
                              </TableCell>
                            )}
                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.ppiNumber} */}
                                {row.ppiNumber && row.ppiNumber.length > 16 ? (
                                  <Tooltip
                                    title={row.ppiNumber}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.ppiNumber}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.ppiNumber}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.ppiLicenseNo} */}
                                {row.ppiLicenseNo &&
                                row.ppiLicenseNo.length > 16 ? (
                                  <Tooltip
                                    title={row.ppiLicenseNo}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.ppiLicenseNo}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.ppiLicenseNo}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[15].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.dispatchTypeName} */}
                                {row.dispatchTypeName &&
                                row.dispatchTypeName.length > 16 ? (
                                  <Tooltip
                                    title={row.dispatchTypeName}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dispatchTypeName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.dispatchTypeName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[16].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.dispatchModeName} */}
                                {row.dispatchModeName &&
                                row.dispatchModeName.length > 16 ? (
                                  <Tooltip
                                    title={row.dispatchModeName}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dispatchModeName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.dispatchModeName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[17].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.mailingSchemeName} */}
                                {row.mailingSchemeName &&
                                row.mailingSchemeName.length > 16 ? (
                                  <Tooltip
                                    title={row.mailingSchemeName}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.mailingSchemeName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.mailingSchemeName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[18].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.distributorName} */}
                                {row.distributorName &&
                                row.distributorName.length > 16 ? (
                                  <Tooltip
                                    title={row.distributorName}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.distributorName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.distributorName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[19].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.returnAddress} */}
                                {row.returnAddress &&
                                row.returnAddress.length > 16 ? (
                                  <Tooltip
                                    title={row.returnAddress}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.returnAddress}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.returnAddress}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[20].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.carrierSheetScheme} */}
                                {row.carrierSheetScheme &&
                                row.carrierSheetScheme.length > 16 ? (
                                  <Tooltip
                                    title={row.carrierSheetScheme}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.carrierSheetScheme}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.carrierSheetScheme}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[21].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.carrierSheetMode} */}
                                {row.carrierSheetMode &&
                                row.carrierSheetMode.length > 16 ? (
                                  <Tooltip
                                    title={row.carrierSheetMode}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.carrierSheetMode}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.carrierSheetMode}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[22].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                id={labelId}
                              >
                                {status}
                              </TableCell>
                            )}
                            {this.state.headCells[23].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                id={labelId}
                                className="table-content-cell"
                              >
                                {/* {row.createdBy} */}
                                {row.createdBy && row.createdBy.length > 16 ? (
                                  <Tooltip
                                    title={row.createdBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.createdBy}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[24].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                id={labelId}
                                className="table-content-cell"
                              >
                                {row.createdDate &&
                                row.createdDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[25].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                id={labelId}
                              >
                                {/* {row.modifiedBy} */}
                                {row.modifiedBy &&
                                row.modifiedBy.length > 16 ? (
                                  <Tooltip
                                    title={row.modifiedBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.modifiedBy}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[26].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                id={labelId}
                              >
                                {row.modifiedDate &&
                                row.modifiedDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      {this.state.loading ? (
                        <TableCell colSpan={15} style={{ textAlign: "center" }}>
                          <LinearProgress />
                        </TableCell>
                      ) : (
                        <TableCell colSpan={8} style={{ textAlign: "center" }}>
                          No result found
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={RoutingTitleList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(RoutingTitleMaster);
