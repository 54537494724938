import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Tooltip, IconButton, Button } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import { useToolbarStyles } from "../../../shared/styles";
import { withRouter } from "react-router-dom";
// import jsPDF from "jspdf";
import GetAppIcon from "@material-ui/icons/GetApp";
import html2canvas from "html2canvas";
import { StateContext } from "../../../shared/globalState";
import { Link } from "react-router-dom";
import { getParticularOrderView, getCustomerExcelData } from "./actions";
import "../../app/App.css";
import {
  printDetails,
  pdfDownloadDetails,
} from "../../../shared/tableCommonFunctions";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
const exportToCSV = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName);
};
function createData(
  salutation,
  first_name,
  last_name,
  designation,
  department,
  company_name,
  addr1,
  addr2,
  addr3,
  addr4,
  city,
  state,
  postal_code,
  country_name,
  copies,
  reference_no,
  job_sheet_no,
  membership_no
  // isValid,
  // exceptionMessage
) {
  return {
    salutation,
    first_name,
    last_name,
    designation,
    department,
    company_name,
    addr1,
    addr2,
    addr3,
    addr4,
    city,
    state,
    postal_code,
    country_name,
    copies,
    reference_no,
    job_sheet_no,
    membership_no,
    // isValid,
    // exceptionMessage
  };
}
const ViewHeader = (props) => {
  const classes = useToolbarStyles();
  let acnArr = props.roles.filter((u) => u.menuName === "Order Placement");
  let acnObj = acnArr[0];

  return (
    <div className="view-page-header">
      {/* <Tooltip title="Edit">
        <IconButton
          aria-label="clear"
          className={classes.iconHover}
          onClick={e =>
            props.history.push(`/manage_customer/${props.customerId}`)
          }
        >
          <NewEditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Download">
        <IconButton
          aria-label="clear"
          className={classes.iconHover}
          onClick={e => pdfDownloadDetails("customer_details.pdf")}
        >
          <DownloadIcon />
        </IconButton>
      </Tooltip> */}
      {acnObj && acnObj.printAction && (
        <Tooltip title="Print" style={{ paddingTop: "12px" }}>
          <IconButton
            //aria-label="clear"
            className={classes.iconHover}
            onClick={(e) => printDetails()}
          >
            <PrintIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

class viewCustomerOrder extends Component {
  state = {
    selectedOrder: {},
    stateFiles: [],
  };
  componentDidMount() {
    let userOrderId = "";
    if (process.browser) userOrderId = window.location.pathname.split("/")[3];
    if (userOrderId) {
      this.props.handleSettingHeader("Generic System");
      this.props.setSelectedList("genericSystemOpen", "genericSystem");
    } else {
      this.props.handleSettingHeader("Generic Customer");
      this.props.setSelectedList("genericCustomOpen", "orderPlacement");
    }

    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    // console.log(customerId);
    getParticularOrderView(orderId).then((r) => {
      //  console.log(r);
      let newSelectedOrder = {};
      newSelectedOrder.orderTitle = r.orderTitle;
      newSelectedOrder.email = r.contactEmailID;
      newSelectedOrder.planner = r.planner;
      newSelectedOrder.status = r.status;
      newSelectedOrder.splInstruction = r.specialInstruction;
      let stateFiles = r.customerOrderFileHeaderList;
      this.setState({ selectedOrder: newSelectedOrder, stateFiles });
    });
  }
  handleCancel = () => {
    let userOrderId = "";
    if (process.browser) userOrderId = window.location.pathname.split("/")[3];

    if (userOrderId) {
      this.props.handleSettingHeader("Generic System");
      this.props.setSelectedList("genericSystemOpen", "genericSystem");
      this.props.history.push("/genericSystem");
    } else this.props.history.push("/orderPlacement");
  };
  getExcelFileData = (id, name) => {
    let orderHeaderId = "";
    if (process.browser) orderHeaderId = window.location.pathname.split("/")[2];
    let userOrderId = "";
    if (process.browser) userOrderId = window.location.pathname.split("/")[3];
    if (!userOrderId) orderHeaderId = 0;
    getCustomerExcelData(id, orderHeaderId).then((r) => {
      let newRows = [];
      r.map((d) => {
        newRows.push(
          createData(
            d.salutation,
            d.first_name,
            d.last_name,
            d.designation,
            d.department,
            d.company_name,
            d.addr1,
            d.addr2,
            d.addr3,
            d.addr4,
            d.city,
            d.state,
            d.postal_code,
            d.country_name,
            d.copies,
            d.reference_no,
            d.job_sheet_no,
            d.membership_no
            // d.isValid,
            // d.exceptionMessage
          )
        );
      });
      //  console.log(r);
      exportToCSV(newRows, name);
    });
  };
  render() {
    // console.log(this.props);
    let { selectedOrder } = this.state;
    let orderId = "";

    if (process.browser) orderId = window.location.pathname.split("/")[2];

    return (
      <div>
        <Paper id="convertToPdf">
          <ViewHeader
            history={this.props.history}
            orderId={orderId}
            roles={this.props.roles}
          />
          <div
            className={
              this.props.clearOpenList
                ? "view-page-container"
                : "view-page-container-wide"
            }
            id="printable"
          >
            <div
              className={
                this.props.clearOpenList
                  ? "view-page-headings"
                  : "view-page-headings-wide"
              }
            >
              Order Placement Details
            </div>
            <div
              className={
                this.props.clearOpenList
                  ? "view-page-contents-container"
                  : "view-page-contents-container-wide"
              }
              id={
                this.props.clearOpenList
                  ? "view-page-contents-container"
                  : "view-page-contents-container-wide"
              }
            >
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Order Title</div>
                <div className="view-page-value">
                  {selectedOrder.orderTitle}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Email</div>
                <div className="view-page-value">{selectedOrder.email}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Planner</div>
                <div className="view-page-value">{selectedOrder.planner}</div>
              </div>
            </div>
            <div
              className={
                this.props.clearOpenList
                  ? "view-page-contents-container"
                  : "view-page-contents-container-wide"
              }
            >
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Status</div>
                <div className="view-page-value">{selectedOrder.status}</div>
              </div>
              <div>
                <div className="view-page-label">Special Instruction</div>
                <div className="view-page-value">
                  {selectedOrder.splInstruction}
                </div>
              </div>
            </div>

            <div
              id={
                this.props.clearOpenList
                  ? "view-page-contents-container"
                  : "view-page-contents-container-wide"
              }
            >
              <div
                className={
                  this.props.clearOpenList
                    ? "view-page-headings1"
                    : "view-page-headings-wide1"
                }
              >
                File Details
              </div>
              <div
                style={{
                  display: "flex",
                  //justifyContent: "space-between",
                  width: "100%",
                  marginBottom: "1em",
                }}
              >
                <div className="view-page-label" style={{ width: "30%" }}>
                  File Name
                </div>
                <div className="view-page-label" style={{ width: "25%" }}>
                  No. of Records
                </div>
                <div className="view-page-label" style={{ width: "25%" }}>
                  Total Copies
                </div>
                <div className="view-page-label" style={{ width: "20%" }}>
                  Action
                </div>
              </div>
              {this.state.stateFiles.map((file, i) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-between",
                      width: "100%",
                      marginBottom: "1em",
                    }}
                    key={i}
                  >
                    <div className="view-page-value" style={{ width: "30%" }}>
                      {file.name}
                    </div>
                    <div
                      className="view-page-value"
                      style={{ width: "25%", paddingLeft: "100px" }}
                    >
                      {file.noOfOrderFiles}
                    </div>
                    <div
                      className="view-page-value"
                      style={{ width: "25%", paddingLeft: "0px" }}
                    >
                      {file.totalCopies}
                    </div>
                    <div className="view-page-value" style={{ width: "20%" }}>
                      {/* <IconButton size="small" style={{ marginTop: "-5px" }}>
                        <GetAppIcon style={{ fontSize: "18px" }} />
                      </IconButton> */}
                      <GetAppIcon
                        style={{
                          fontSize: "18px",
                          color: "#646060",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          this.getExcelFileData(
                            file.orderFileHeaderID,
                            file.name
                          )
                        }
                      />
                      {/* <a
                        href="file://10.2.0.132\var\uploads\genopl-dev-upload-claim-pvc-ff04f3f8-ae51-43bc-b903-d6cb0e4a9840\dev\cr100014.xlsx"
                        target="_blank"
                      >
                        download
                      </a> */}
                      {/* <Link
                        // to={`//file://10.2.0.132/var/uploads/genopl-dev-upload-claim-pvc-ff04f3f8-ae51-43bc-b903-d6cb0e4a9840/dev/Customer_Order_File_%20(1).xlsx`}
                        to="//smb//:\\10.2.0.132\var\uploads\genopl-dev-upload-claim-pvc-ff04f3f8-ae51-43bc-b903-d6cb0e4a9840\dev\cr100014.xlsx"
                        target="_blank"
                        download
                        type="file"
                      >
                        link
                      </Link> */}
                      {/* <button
                        onClick={e => {
                          var URL =
                            "file:///10.2.0.132/var/uploads/genopl-dev-upload-claim-pvc-ff04f3f8-ae51-43bc-b903-d6cb0e4a9840/dev/cr100014.xlsx";
                          window.open(URL);
                        }}
                      >
                        click
                      </button> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="view-page-footer" style={{ paddingLeft: "0px" }}>
            <div className="button-wrapper">
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                // color="primary"
                onClick={(e) => this.handleCancel()}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}
export default withRouter(viewCustomerOrder);
