import React, { Component } from "react";
import { Autocomplete } from "@material-ui/lab";
import { addStyles } from "../../../shared/styles";
import { IconButton, Tooltip, Divider, Button, Input } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import { withRouter } from "react-router-dom";
import TableHead from "@material-ui/core/TableHead";
import { getLocationDropdownList } from "../../masters/masterActions";
import { getDHLRateUponAWeight } from "../../publisher/mainLabelOrderPlacement/action"

import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import clone from "clone";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import { CustomCheckbox } from "../../../shared/tableCommonFunctions";
import TableRow from "@material-ui/core/TableRow";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from "@date-io/date-fns";
import { getBPAListforAdd, putBPAProcessList } from "../orderPlacement/action";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
let autoCId = "";
const headCells = [
  {
    id: "bpaCode",
    numeric: false,
    disablePadding: true,
    label: "BPA Code",
  },
  {
    id: "bpaRecipientName",
    numeric: false,
    disablePadding: false,
    label: "Recipient",
  },
  {
    id: "orderQuantity",
    numeric: false,
    disablePadding: false,
    label: "Order Qty",
  },
  {
    id: "deliveryMethodName",
    numeric: false,
    disablePadding: false,
    label: "Delivery Mode",
  },
  {
    id: "deliveryInstructions",
    numeric: false,
    disablePadding: false,
    label: "Delivery Instruction",
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
  },
  {
    id: "country",
    numeric: false,
    disablePadding: false,
    label: "Country",
  },
  {
    id: "trackingNumber",
    numeric: false,
    disablePadding: false,
    label: "Tracking Nos*",
  },
  {
    id: "noOfCartons",
    numeric: false,
    disablePadding: false,
    label: "No Of Cartons",
  },
  {
    id: "actualWeight",
    numeric: false,
    disablePadding: false,
    label: "Actual Weight*",
  },
  {
    id: "deliveredDate",
    numeric: false,
    disablePadding: false,
    label: "Delivery Date*",
  },
  {
    id: "dhlRate",
    numeric: false,
    disablePadding: false,
    label: "DHL Rate",
  },
];
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
class AddBPA extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      list: [],
      countryList: [],
      countryId: "",
      countryObj: {},
      loading: false,
      // isValid: false,
    };
  }

  componentDidMount() {
    this.props.handleSettingHeader("BPA Batch Processing");
    this.props.setSelectedList("bpaMarketing", "backLabelBPAOrderProcessing");
    this.props.handleDrawerClose();
    getBPAListforAdd().then((r) => {
      this.getAllList(r && r.data);
    });
    this.setState({ loading: true });
  }

  getAllList = (data) => {
    let bpaList = [];
    data &&
      data.length > 0 &&
      data.map((res) => {
        bpaList.push({
          bpaOrderHeaderID: res.bpaOrderHeaderID,
          bpaCode: res.bpaCode,
          bpaRecipientName: res.bpaRecipientName,
          orderQuantity: res.orderQuantity,
          deliveryMethodName: res.deliveryMethodName,
          deliveryInstructions: res.deliveryInstructions,
          remarks: res.remarks,
          country: res.countryName,
          trackingNumber: res.trackingNumber,
          noOfCartons: res.noOfCartons ? res.noOfCartons : 0,
          dhlRate: res.dhlRate ? res.dhlRate : "",
          actualWeight: res.actualWeight ? res.actualWeight : 0,
          deliveredDate: res.deliveredDate,
          isValid:
            res.trackingNumber && res.actualWeight && res.deliveredDate
              ? true
              : false,
          countryID: res.countryID
        });
      });
    this.setState({
      list: bpaList,
      loading: false,
    });
  };

  handleAutoOpenClick = (id) => {
    autoCId = id;
  };

  handleSubmit = (e) => {
    let list = [],
      reqBody = [];
    let loggedInId = localStorage.getItem("loggedInId");

    this.state.list.map((val) => {
      if (val.isValid)
        reqBody.push({
          ...val,
          modifiedBy: loggedInId,
          trackingNumber: val.trackingNumber,
          actualWeight: val.actualWeight ? val.actualWeight : 0,
          deliveredDate: val.deliveredDate,
        });
    });
    putBPAProcessList(reqBody, this.props.history);
  };

  handleChange = (event, index, row) => {
    const { list } = this.state;
    const newList = clone(list);

    if (event.target.name === "actualWeight") {
      let bpaValues = event.target.value;
      bpaValues = this.checkDHlWeightDec(event.target.value.toString());
      newList[index][event.target.name] = bpaValues;
      if (event.target.value === "") {
        newList[index]["dhlRate"] = "";
        return
      }
      if (event.target && event.target.value && row && row.countryID)
        getDHLRateUponAWeight(row.countryID, event.target.value).then(r => {
          if (r && r.data) {
            newList[index]["dhlRate"] = r.data.dhlRate ? r.data.dhlRate : "";
            this.setState({ list: newList });

          }
          else newList[index]["dhlRate"] = "";
        })
    }
    else if (event.target.name === "trackingNumber") {
      newList[index][event.target.name] = event.target.value;
      if (row && row.actualWeight !== "" && row.countryID)
        getDHLRateUponAWeight(row.countryID, row.actualWeight).then(r => {
          if (r && r.data) {
            newList[index]["dhlRate"] = r.data.dhlRate ? r.data.dhlRate : "";
          }
          else newList[index]["dhlRate"] = "";
        })
    }
    else {
      newList[index][event.target.name] = event.target.value;
    }
    if (newList[index][event.target.name] !== list[index][event.target.name]) {
      document.getElementById(
        event.target.id
      ).parentElement.parentElement.parentElement.style =
        "background: rgb(0, 105, 217,0.3)";
    }
    if (
      newList[index].actualWeight &&
      newList[index].trackingNumber &&
      newList[index].deliveredDate
    ) {
      newList[index].isValid = true;
    } else {
      newList[index].isValid = false;
    }
    this.setState({ list: newList });
    // this.validateField(event.target.name, event.target.value);
  };

  handleDateChange = (date, index, name) => {
    const { list } = this.state;
    const newList = clone(list);
    newList[index]["deliveredDate"] = date;
    if (
      newList[index].actualWeight &&
      newList[index].trackingNumber &&
      newList[index].deliveredDate
    ) {
      newList[index].isValid = true;
    } else {
      newList[index].isValid = false;
    }
    this.setState({ list: newList });
    if (newList[index]["deliveredDate"] !== list[index]["deliveredDate"]) {
      document.getElementById(
        "deliveredDate" + index
      ).parentElement.parentElement.parentElement.style =
        "background: rgb(0, 105, 217,0.3)";
    }
  };

  onBlur = (event, index) => {
    const { list } = this.state;
    let bpaValues = event.target.value;
    bpaValues = this.addZeroes(bpaValues.toString(), 3);
    list[index][event.target.name] = bpaValues;
    this.setState({ list });
  };

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  _onNumberPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkButtonDisability = () => {
    if (this.state.list && this.state.list.some((e) => e.isValid == true)) {
      return false;
    } else return true;
  };

  render() {
    let { classes, clearOpenList } = this.props;
    let { list } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">
            <span>Update Tracking Info</span>
          </div>
          <Divider />
          <div className={classes.container} style={{ marginTop: "25px" }}>
            <div style={{ marginTop: "-15px" }}>
              <div
                className={classes.container}
              >
                <Divider />
                <div style={
                  !clearOpenList
                    ? {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 164px)",
                      maxHeight: "calc(100vh - 350px)",
                      // margin: "0 .8em 0 .6em"
                    }
                    : {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 390px)",
                      maxHeight: "calc(100vh - 350px)",
                      // margin: "0 .8em 0 .6em"
                    }
                }>
                  <div
                  // style={{ maxHeight: 350, overflow: "auto", }}
                  >
                    {/* //overflow: "auto" */}
                    <Table
                      aria-labelledby="tableTitle"
                      size="small"
                      stickyHeader
                      aria-label="sticky table"
                    >
                      <EnhancedTableHead
                        tabValue={this.state.value}
                        onSelectAllClicked={this.onSelectAllClicked}
                      />

                      {list && list.length > 0 ? (
                        <TableBody style={{ overflow: "hidden" }}>
                          {list &&
                            list.length > 0 &&
                            list.map((row, index) => {
                              // const isItemSelected = isSelected(row.id);
                              const labelId = `enhanced-table-checkbox-${index}`;

                              return (
                                <TableRow
                                  id={`tableRow${index}`}
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={index}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    // className="table-content-cell"
                                    style={{ width: "auto", padding: ".5em", borderLeft: "1px solid #eaeaea" }}

                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.bpaCode &&
                                        row.bpaCode.length > 16 ? (
                                          <Tooltip
                                            title={row.bpaCode}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.bpaCode}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.bpaCode}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={{
                                      minWidth: "100px!important",
                                    }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.bpaRecipientName &&
                                        row.bpaRecipientName.length > 16 ? (
                                          <Tooltip
                                            title={row.bpaRecipientName}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.bpaRecipientName}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.bpaRecipientName}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-right-cell"
                                    style={{
                                      minWidth: "50px",
                                    }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.orderQuantity &&
                                        row.orderQuantity.length > 16 ? (
                                          <Tooltip
                                            title={row.orderQuantity}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.orderQuantity}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.orderQuantity}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.deliveryMethodName &&
                                        row.deliveryMethodName.length > 16 ? (
                                          <Tooltip
                                            title={row.deliveryMethodName}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.deliveryMethodName}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.deliveryMethodName}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.deliveryInstructions &&
                                        row.deliveryInstructions.length > 16 ? (
                                          <Tooltip
                                            title={row.deliveryInstructions}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.deliveryInstructions}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.deliveryInstructions}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.remarks &&
                                        row.remarks.length > 16 ? (
                                          <Tooltip
                                            title={row.remarks}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.remarks}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.remarks}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.country &&
                                        row.country.length > 16 ? (
                                          <Tooltip
                                            title={row.country}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.country}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.country}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                    style={{ minWidth: "70px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      <Input
                                        defaultValue=""
                                        id={"trackingNumber" + index}
                                        inputProps={{
                                          "aria-label": "trackingNumber",
                                          maxLength: 200,
                                        }}
                                        style={{ width: "100%" }}
                                        name="trackingNumber"
                                        value={row.trackingNumber}
                                        onChange={(e) =>
                                          this.handleChange(e, index, row)
                                        }
                                      />
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                    style={{ minWidth: "100px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      <Input
                                        defaultValue=""
                                        id={"noOfCartons" + index}
                                        inputProps={{
                                          "aria-label": "noOfCartons",
                                          maxLength: 200,
                                        }}
                                        style={{ width: "100%" }}
                                        name="noOfCartons"
                                        value={row.noOfCartons}
                                        onChange={(e) =>
                                          this.handleChange(e, index)
                                        }
                                      />
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                    style={{ minWidth: "100px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      <Input
                                        defaultValue=""
                                        id={"actualWeight" + index}
                                        inputProps={{
                                          "aria-label": "actualWeight",
                                          maxLength: 200,
                                        }}
                                        onKeyPress={this._onNumberPress}
                                        style={{ width: "100%" }}
                                        name="actualWeight"
                                        value={row.actualWeight}
                                        onChange={(e) =>
                                          this.handleChange(e, index, row)
                                        }
                                        onBlur={(e) => this.onBlur(e, index)}
                                      />
                                    </Typography>
                                  </TableCell>{" "}
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                    style={{ minWidth: "140px" }}
                                  >
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <KeyboardDatePicker
                                        autoOk
                                        // disablePast
                                        required
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        className={classes.textField}
                                        id={"deliveredDate" + index}
                                        name="deliveredDate"
                                        value={row.deliveredDate}
                                        onChange={(e) =>
                                          this.handleDateChange(
                                            e,
                                            index,
                                            "deliveredDate"
                                          )
                                        }
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                        style={{
                                          width: "100%",
                                          textAlign: "left",
                                          margin: "0",
                                        }}
                                        ref={this.myRef}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.dhlRate &&
                                        row.dhlRate.length > 16 ? (
                                          <Tooltip
                                            title={row.dhlRate}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.dhlRate}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.dhlRate}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      ) : (
                          <TableBody>
                            {this.state.loading ? (
                              <TableCell
                                colSpan={10}
                                style={{ textAlign: "center" }}
                              >
                                <LinearProgress />
                              </TableCell>
                            ) : (
                                <TableCell
                                  colSpan={10}
                                  style={{ textAlign: "center" }}
                                >
                                  No result found
                                </TableCell>
                              )}
                          </TableBody>
                        )}
                    </Table>
                  </div>
                </div>
              </div>
            </div>
            <Divider style={{ margin: "0 1em" }} />

            <div
              className="button-wrapper"
              style={{ paddingLeft: "0px", margin: "0 1em" }}
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) =>
                    this.props.history.push("/pubBpaBatchProcessing")
                  }
                >
                  CANCEL
                </Button>
              </div>
              <div style={{ marginLeft: "1em" }}>
                <Button
                  disabled={this.checkButtonDisability()}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={this.handleSubmit}
                >
                  SUBMIT
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddBPA));
