import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_PUBLISHER_ORDER_PLACEMENT;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getEBLOList = async () => {
  let jimList = [];
  await pgsService
    .get("/GetEBLOOrderPlacementData")
    .then((r) => {
      jimList = r;
    })
    .catch((e) => { });
  return jimList;
};

export const getEBLONumberList = async () => {
  let jimList = [];
  await pgsService
    .get("/GetAllEBLONo")
    .then((r) => {
      jimList = r;
    })
    .catch((e) => { });
  return jimList;
};

export const postEbloList = async (reqBody, history) => {
  let jimList = [];
  await pgsService
    .post("/EBLOOrderPlacementCreate", reqBody)
    .then((r) => {
      toastr.success("EBLO created successfully !");
      history.push("/pubEbloOrderPlacement");
    })
    .catch((e) => { });
};

export const postEbloMailList = async (reqBody, history) => {
  let jimList = [];
  await pgsService
    .post("/EBLOEmailHeaderCreate", reqBody)
    .then((r) => {
      jimList = r;
      let getResponse =
        r &&
        r.data &&
        r.data.map((val) => {
          return val.exceptionMessage;
        });

      if (getResponse.includes("File is already Present.")) {
        toastr.error("File is already Present");
      } else {
        history.push("/pubEbloOrderPlacement");
        toastr.success("File is Uploaded Successfully");
      }
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
      jimList = e.response.data.errors[0];
    });
  return jimList;
};

export const validateEmailEBLO = async (reqBody) => {
  let jimList = [];
  await pgsService
    .post("/EBLOEmailValidate", reqBody)
    .then((r) => {
      jimList = r;
    })
    .catch((e) => {
      // if (e.response.data.errors[0].message !== undefined) {
      //   toastr.error(e.response.data.errors[0].message);
      // } else {
      //   toastr.error(e.response.data.errors[0]);
      // }
      // jimList = e.response.data.errors[0];
      toastr.error("EBLO catch");
    });
  return jimList;
};

export const deleteEBLO = async (modifiedBy, deletionID) => {
  await pgsService
    .delete(`/DeleteEBLOOrderPlacement/${deletionID}/${modifiedBy}`)
    .then((r) => {
      toastr.success("EBLO Deleted Successfully");
    })
    .catch((e) => {
      toastr.error(e);
    });
};

export const getViewEBLOByID = async (ebloHeaderID) => {
  let data = {};
  await pgsService
    .get("/GetEBLOOrderDataForView/" + ebloHeaderID)
    .then((r) => {
      data = r && r.data;
    })
    .catch((e) => { });
  return data;
};

export const getEditEBLOByID = async (ebloHeaderID) => {
  let data = {};
  await pgsService
    .get("/GetEBLOOrderPlacementDataByID/" + ebloHeaderID)
    .then((r) => {
      data = r && r.data;
    })
    .catch((e) => { });
  return data;
};

export const getEditJournalEBLOByID = async (ebloHeaderID) => {
  let data = {};
  await pgsService
    .get("/GetDetailHeaderByID/" + ebloHeaderID)
    .then((r) => {
      data = r && r.data;
    })
    .catch((e) => { });
  return data;
};

export const getEBLOAdvancedSearch = async (reqBody) => {
  let searchList = [];
  await pgsService
    .post("/SearchEBLOOrderPlacementDetails", reqBody)
    .then(async (r) => {
      searchList = r;
    })
    .catch((e) => { });
  return searchList;
};

export const updateEBLO = async (id, reqBody, history) => {
  let jimList = [];
  await pgsService
    .put("/UpdateEBLOOrderPlacement/" + id, reqBody)
    .then((r) => {
      toastr.success("EBLO updated successfully !");
      history.push("/pubEbloOrderPlacement");
    })
    .catch((e) => { });
};

export const getEBLOStatusList = async () => {
  let jimList = [];
  await pgsService
    .get("/GetEBLOStatus")
    .then((r) => {
      jimList = r;
    })
    .catch((e) => { });
  return jimList;
};