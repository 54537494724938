import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import clone from "clone";
import Input from "@material-ui/core/Input";
import SyncIcon from "@material-ui/icons/Sync";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import DoneIcon from "@material-ui/icons/Done";
import ReportViewer from "../../app/ReportViewer";
import {
  getCollectionNote,
  getCollectionNoteDetails,
  updateCollectionNote,
  getCollectionStatusList,
} from "../reportServiceAction";
import { getCountryDropdownList } from "../../masters/masterActions";
import Validator from "../../../shared/validator";
// Include new StateContext
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import {
  EditIcon,
  CustomPrintIcon,
  CustomSmallAddIcon,
} from "../../../shared/customIcons";
import "date-fns";
import Typography from "@material-ui/core/Typography";
//import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import TimeFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const validateNotRequired100 = new Validator("maxlength100AllAllowNewForEmpty");
const validatePhoneNo = new Validator("phnNoFCN");
const validateZipCode = new Validator("pincode");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "31%",
    fontSize: "11px !important",
  },
  autocompleteText: {
    width: "100%",
    fontSize: "11px !important",
  },
  autoComplete: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: "31%",
    fontSize: "11px !important",
  },
  switchControl: {
    marginTop: "38px",
    marginLeft: "1em",
  },
  resize: {
    fontSize: 14,
  },
  textFieldWide: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: "95.5%",
    fontSize: "11px !important",
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    // marginLeft: "1.5em",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
});
const headCells = [
  {
    id: 1,
    code: "lineNo",
    numeric: false,
    disablePadding: true,
    label: "Line No",
    width: "auto",
  },
  {
    id: 2,
    code: "description",
    numeric: false,
    disablePadding: false,
    label: "Description *",
    width: "100%",
  },
  {
    id: 3,
    code: "noOfPts",
    numeric: false,
    disablePadding: false,
    label: "No of Plts",
    width: "auto",
  },
  {
    id: 4,
    code: "totalPackage",
    numeric: false,
    disablePadding: false,
    label: "Total Packages *",
    width: "auto",
  },
  {
    id: 5,
    code: "noOfCopies",
    numeric: false,
    disablePadding: false,
    label: "No of Cps/Pk",
    width: "auto",
  },
  {
    id: 6,
    code: "totalQuantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity *",
    width: "auto",
  },
  {
    id: 7,
    code: "weightPerCartBox",
    numeric: false,
    disablePadding: false,
    label: "Weight per Carton Box (Kg)",
    width: "auto",
  },
  {
    id: 8,
    code: "netWeight",
    numeric: false,
    disablePadding: false,
    label: "Net Weight (Kg)",
    width: "auto",
  },
  {
    id: 9,
    code: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    width: "auto",
  },
];
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderLeft: "1px sold #000", width: `${headCell.width}` }}
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  let { validList } = props;
  const handleAccept = () => {
    setOpen(false);
    props.handleClickButton("", "reset");
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          //   width: "100%",
          border: "1px solid #eaeaea",
          borderBottom: "none",
          borderRadius: "4px",
          margin: "0 .8em 0 .6em",
        }}
      >
        {props.disableAdd ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={(e) => props.handleClickButton(e, "add")}
          // style={
          //   !props.disableAdd
          //     ? {
          //         color: "#fff",
          //         background: "#3982ea",
          //         padding: "2px",
          //         margin: ".5em",
          //         fontSize: "11px",
          //       }
          //     : { color: "#3982ea", fontSize: "11px" }
          // }
          >
            <CustomSmallAddIcon style={{ fontSize: "18px" }} /> Add
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              onClick={(e) => props.handleClickButton(e, "add")}
            // style={
            //   !props.disableAdd
            //     ? {
            //         color: "#fff",
            //         background: "#3982ea",
            //         padding: "2px",
            //         margin: ".5em",
            //         fontSize: "11px",
            //       }
            //     : { color: "#3982ea", fontSize: "11px" }
            // }
            >
              <CustomSmallAddIcon style={{ fontSize: "18px" }} /> Add
            </Button>
          )}
        {props.editableCollectionNoteDetailList === props.resetList ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            // style={
            //   !(props.editableCollectionNoteDetailList === props.resetList)
            //     ? {
            //         color: "#fff",
            //         background: "#3982ea",
            //         padding: "2px",
            //         margin: ".5em",
            //         fontSize: "11px",
            //       }
            //     : { color: "#3982ea", fontSize: "11px" }
            // }
            onClick={handleClickOpen}
          >
            <SyncIcon style={{ fontSize: "18px" }} /> Reset
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              // style={
              //   !(props.editableCollectionNoteDetailList === props.resetList)
              //     ? {
              //         color: "#fff",
              //         background: "#3982ea",
              //         padding: "2px",
              //         margin: ".5em",
              //         fontSize: "11px",
              //       }
              //     : { color: "#3982ea", fontSize: "11px" }
              // }
              onClick={handleClickOpen}
            >
              <SyncIcon style={{ fontSize: "18px" }} /> Reset
            </Button>
          )}
        <div>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {'Are you sure you want to "Reset"?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div style={{ textAlign: "center" }}>
                  This will remove all actions immediately,
                  <br />
                  You can't undo this action!
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
              >
                No
              </Button>
              <Button
                onClick={handleAccept}
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {!validList ? (
          <div
            style={{
              marginLeft: "1%",
              marginRight: "1%",
              marginTop: "1%",
              color: "red",
              width: "70%",
              fontSize: "small",
            }}
          >
            * No of Copies, Weight Per Carton Box and Net Weight is required
          </div>
        ) : null}
      </div>
    </div>
  );
};
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
class EditCollectionNote extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      collectionNote: {
        collectionToAddress: "",
        collectionNoteNo: "",
        jobGroupID: "",
        clientname: "",
        workOrderNo: "",
        title: "",
        jobGroupNumber: "",
        contactPersonName: "",
        contactNumber: "",
        description: "",
        isUrgent: false,
        countryList: "",
        countryID: 0,
        collectionAddress: "",
        collectionAddress2: "",
        collectionAddress3: "",
        zipCode: "",
        totalQuantity: "",
        status: "",
        pickupDate: null,
        pickupTime: null,
        agentCode: "",
        driverName: "",
        remarks: "",
        isActive: true,
      },
      collectionNoteDetails: {
        stockDescription: "",
        noOfPts: 0,
        totalPackage: 0,
        noOfCopies: 0,
        totalQuantity: 0,
        weightPerCortanBox: 0,
        netWeight: 0,
      },
      workOrder: {
        jobGroupID: "",
        clientname: "",
        workOrderNo: "",
        title: "",
        jobGroupNumber: "",
      },
      formValid: false,
      submitSuccess: true,
      detailListValid: true,
      currentStatus: "",
      errors: {},
      collectionNoteDetailList: [],
      editableCollectionNoteDetailList: [],
      countryList: [],
      resetList: [],
      active: false,
      agentCodeValid: true,
      driverNameValid: true,
      descriptionValid: true,
      countryValid: true,
      pickupDateValid: true,
      pickupTimeValid: true,
      contactPersonNameValid: true,
      contactNumberValid: true,
      statusValid: true,
      collectionAddressValid: true,
      collectionAddress2Valid: true,
      collectionAddress3Valid: true,
      zipCodeValid: true,
      remarksValid: true,
      workOrderList: [],
      statusList: [],
      showPopup: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleShowPopup = () => {
    this.setState({
      showPopup: true,
    });
  };

  handleHidePopup = () => {
    this.setState({
      showPopup: false,
    });
  };

  componentDidMount() {
    const dispatch = this.context[1];
    let codeID = this.props.match.params.collectionNoteId;
    this.props.setSelectedList("genericSystemOpen", "collectionNote");
    this.props.handleSettingHeader("Collection Note");
    this.props.handleDrawerClose();
    getCollectionNote(codeID).then((r) => {
      dispatch({
        type: "getCollectionNote",
        newStore: r,
      });
      let newList = r;
      newList.pickupTime =
        r.pickupTime && moment(r.pickupTime, "HH:mm:ss").toLocaleString();
      // newList.workOrderID = r.workOrderID
      newList.status = newList.statusList && newList.statusList[0];
      let newObj = { ...newList, countryList: r.countryList[0] };
      // console.log(r)
      this.setState({ collectionNote: newObj, currentStatus: newList.status });
      this.validateField("agentCode", newList["agentCode"]);
      this.validateField("driverName", newList["driverName"]);
      this.setState({ formValid: false });
      getCountryDropdownList().then((d) => {
        dispatch({
          type: "getCountryList",
          newStore: d.data,
        });
        this.getAllCountries(d.data);
      });
    });
    getCollectionNoteDetails(codeID).then((r) => {
      dispatch({
        type: "getCollectionNoteDetail",
        newStore: r,
      });
      this.getAllCollectionNoteDetails(r.data);
    });
    getCollectionStatusList().then((r) => {
      dispatch({
        type: "getCollectionStatusList",
        newStore: r.data,
      });
      this.getAllStatus(r.data);
    });
  }
  getAllStatus = (list) => {
    this.setState({
      statusList:
        list &&
        list
          .filter((r) => r.value !== "CCAN")
          .sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
    });
  };
  getAllCountries = (list) => {
    const { collectionNote } = this.state;
    this.setState({
      countryList:
        list &&
        list.sort((a, b) =>
          a && a.text.toLowerCase() > b && b.text.toLowerCase() ? 1 : -1
        ),
    });
    // collectionNote.countryList =
    //   this.state.countryList &&
    //   this.state.countryList.filter((r) => r.id === collectionNote.id)[0];
    // this.setState({ collectionNote: collectionNote });
  };
  getAllCollectionNoteDetails = (list) => {
    let newList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        c.isActive
          ? newList.push({
            editClicked: c.editClicked,
            validRecord: true,
            stockDescription: c.stockDescription,
            noOfPts: c.noOfPts,
            totalPackage: c.totalPackage,
            noOfCopies: c.noOfCopies,
            totalQuantity: c.totalQuantity,
            weightPerCortanBox:
              c.weightPerCortanBox &&
              this.addZeroes(c.weightPerCortanBox.toString(), 3),
            netWeight:
              c.netWeight && this.addZeroes(c.netWeight.toString(), 3),
            isActive: c.isActive,
            createdBy: localStorage.loggedInId,
          })
          : c
      );
    this.setState({
      editableCollectionNoteDetailList: newList,
      collectionNoteDetailList: newList,
      resetList: newList,
    });
    this.DetailsValid(newList);
  };
  _onKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  checkWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  handleClickButton = (e, name) => {
    let newList = [...this.state.editableCollectionNoteDetailList];
    //let newList2 = [...this.state.collectionNoteDetailList];
    newList.push({});
    if (name === "add") {
      newList[newList.length - 1].editClicked = true;
      newList[newList.length - 1].validRecord = false;
      newList[newList.length - 1].stockDescription = "";
      newList[newList.length - 1].noOfPts = 0;
      newList[newList.length - 1].totalPackage = 0;
      newList[newList.length - 1].noOfCopies = 0;
      newList[newList.length - 1].totalQuantity = 0;
      newList[newList.length - 1].weightPerCortanBox = 0;
      newList[newList.length - 1].netWeight = 0;
      newList[newList.length - 1].isActive = true;
      newList[newList.length - 1].createdBy = localStorage.loggedInId;
      //newList2.push(newList[newList.length-1]);
      this.setState(
        {
          //collectionNoteDetailList: newList2,
          editableCollectionNoteDetailList: newList,
          disableAdd: true,
          disableEdit: false,
        },
        () => {
          document.getElementById(`tableRow${newList.length - 1}`).focus();
        }
      );
    } else {
      this.setState({
        collectionNoteDetailList: this.state.resetList,
        editableCollectionNoteDetailList: this.state.resetList,
        disableAdd: false,
        disableEdit: true,
      });
      this.DetailsValid(this.state.resetList);
    }
    this.validateField("", "");
  };
  onEditClicked = (e, i) => {
    let newList = clone(this.state.editableCollectionNoteDetailList);
    newList[i].editClicked = true;
    this.setState({
      editableCollectionNoteDetailList: newList,
      disableAdd: true,
    });
    this.validateField("", "");
  };

  onDeleteClicked = (e, i, row) => {
    let newList = clone(this.state.editableCollectionNoteDetailList);
    newList.isActive = false;
    newList[i].editClicked = false;
    this.setState({ collectionNoteDetailList: newList });
    newList.splice(i, 1);
    this.setState({ editableCollectionNoteDetailList: newList });
    this.DetailsValid(newList);
    this.validateField(e.target.name, e.target.value);
  };

  onDoneClicked = (e, i, record) => {
    let newList = [...this.state.editableCollectionNoteDetailList];
    newList[i] = record;
    newList[i].editClicked = false;
    // if (record.netWeight === "" || parseFloat(record.netWeight) === 0) {
    //   newList[i].netWeight =
    //     newList[i].weightPerCortanBox &&
    //     newList[i].totalQuantity &&
    //     parseFloat(newList[i].weightPerCortanBox) *
    //     parseInt(newList[i].totalQuantity).toFixed(3);
    // }
    this.setState({
      editableCollectionNoteDetailList: newList,
      collectionNoteDetailList: newList,
      disableAdd: false,
    });
    this.DetailsValid(newList);
    this.validateField(e.target.name, e.target.value);
  };

  DetailsValid = (List) => {
    let valid = true;

    List.forEach((element) => {
      if (
        this.state.collectionNote.status.text === "Item Received" &&
        (element.noOfCopies === "" ||
          element.noOfCopies === null ||
          parseInt(element.noOfCopies) === 0 ||
          element.weightPerCortanBox === "" ||
          element.weightPerCortanBox === null ||
          parseFloat(element.weightPerCortanBox) === 0)
      ) {
        valid = false;
      }
    });
    this.setState({ detailListValid: valid, formValid: valid });
  };

  onCancelClicked = (e, i, record) => {
    let newList = clone(this.state.editableCollectionNoteDetailList);
    let newList2 = clone(this.state.collectionNoteDetailList);
    newList = newList2;
    this.setState({
      editableCollectionNoteDetailList: newList,
      disableAdd: false,
    });
    this.DetailsValid(newList);
    this.validateField("", "");
  };

  handleDecimalValueChange = (event, index) => {
    let values = event.target.value;
    values = this.checkWeightDec(event.target.value.toString());
    let newEditableList = clone(this.state.editableCollectionNoteDetailList);
    newEditableList[index][event.target.name] = values;
    // if (event.target.name === "weightPerCortanBox") {
    //   newEditableList[index].netWeight =
    //     newEditableList[index].weightPerCortanBox &&
    //     newEditableList[index].totalQuantity &&
    //     parseFloat(newEditableList[index].weightPerCortanBox) *
    //     parseInt(newEditableList[index].totalQuantity);
    //   newEditableList[index].netWeight = parseFloat(
    //     this.addZeroes(newEditableList[index].netWeight.toString(), 3)
    //   ).toFixed(3);
    // }
    if (
      newEditableList[index].description !== "" &&
      newEditableList[index].totalPackage !== "" &&
      newEditableList[index].totalQuantity !== ""
    ) {
      newEditableList[index].validRecord = true;
    } else {
      newEditableList[index].validRecord = false;
    }
    this.setState({ editableCollectionNoteDetailList: newEditableList });
    //this.validateField(event.target.name, event.target.value);
  };

  handleRowChange = (event, index) => {
    let newEditableList = clone(this.state.editableCollectionNoteDetailList);
    newEditableList[index][event.target.name] = event.target.value;

    if (event.target.name === "totalPackage") {
      newEditableList[index].totalQuantity = +event.target.value * newEditableList[index].noOfCopies
      newEditableList[index].netWeight = +event.target.value * newEditableList[index].weightPerCortanBox
    }
    if (event.target.name === "noOfCopies") {
      newEditableList[index].totalQuantity = +event.target.value * newEditableList[index].totalPackage
    }

    if (event.target.name === "weightPerCortanBox") {
      newEditableList[index].netWeight = +event.target.value * newEditableList[index].totalPackage
    }


    // if (event.target.name === "totalQuantity") {
    //   newEditableList[index].netWeight =
    //     newEditableList[index].weightPerCortanBox &&
    //     newEditableList[index].totalQuantity &&
    //     parseFloat(newEditableList[index].weightPerCortanBox) *
    //     parseInt(newEditableList[index].totalQuantity);
    //   newEditableList[index].netWeight = parseFloat(
    //     this.addZeroes(newEditableList[index].netWeight.toString(), 3)
    //   ).toFixed(3);
    // }
    if (
      newEditableList[index].description !== "" &&
      newEditableList[index].totalPackage !== "" &&
      newEditableList[index].totalQuantity !== ""
    ) {
      newEditableList[index].validRecord = true;
    } else {
      newEditableList[index].validRecord = false;
    }
    this.setState({ editableCollectionNoteDetailList: newEditableList });
    //this.validateField(event.target.name, event.target.value);
  };
  handleChange = (event) => {
    const { collectionNote } = this.state;
    if (event.target.name === "isActive") {
      collectionNote[event.target.name] = event.target.checked;
    } else {
      collectionNote[event.target.name] = event.target.value;
    }
    this.setState({ collectionNote });

    this.validateField(event.target.name, event.target.value);
  };
  handlePriorityChange = (name) => (event) => {
    const { collectionNote } = this.state;
    collectionNote[name] = event.target.checked;
    this.setState({ collectionNote });
    this.validateField(event.target.name, event.target.value);
  };
  handleCountryChange = (event, values) => {
    const { collectionNote } = this.state;
    if (values === null) {
      collectionNote["countryList"] = "null";
      collectionNote["countryID"] = "";
    } else {
      let countryList = values;
      collectionNote["countryList"] = countryList;
      collectionNote["countryID"] = countryList.id;
    }
    this.setState({ collectionNote });

    this.validateField("countryID", collectionNote["countryID"]);
  };
  handleStatusChange = (event, values) => {
    const {
      collectionNote,
      collectionNoteDetailList,
      currentStatus,
    } = this.state;
    let tempList = collectionNoteDetailList;
    if (values === null) {
      collectionNote["status"] = currentStatus;
    } else {
      let status = values;
      collectionNote["status"] = status;
      if (status.text === "Item Received") {
        //TODO - Check details table
        //tempList && tempList.map(r=>(r.weightPerCortanBox>0 && r.netWeight>0 && r.noOfCopies>0)?r.validRecord=false:r.validRecord=true);
      }
    }
    this.validateField("agentCode", collectionNote["agentCode"]);
    this.validateField("driverName", collectionNote["driverName"]);
    this.setState({ collectionNote, collectionNoteDetailList: tempList });
    this.DetailsValid(tempList);
    this.validateField("status", collectionNote["status"]);
  };
  onBlur = (event, index) => {
    const { editableCollectionNoteDetailList } = this.state;
    let values = event.target.value;
    values = this.addZeroes(values.toString(), 3);
    editableCollectionNoteDetailList[index][event.target.name] = values;
    this.setState({ editableCollectionNoteDetailList });
  };
  addZeroes(num, decimalPlaces) {
    var value = Number(num);
    var res = num.split(".");
    if (res.length === 1 || res[1].length < decimalPlaces) {
      value = value.toFixed(decimalPlaces);
    }
    if (value !== "") {
      value = String(value);
    }
    return value;
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let agentCodeValid = this.state.agentCodeValid;
    let driverNameValid = this.state.driverNameValid;
    let descriptionValid = this.state.descriptionValid;
    let pickupDateValid = this.state.pickupDateValid;
    let pickupTimeValid = this.state.pickupTimeValid;
    let contactPersonNameValid = this.state.contactPersonNameValid;
    let contactNumberValid = this.state.contactNumberValid;
    let collectionAddressValid = this.state.collectionAddressValid;
    let collectionAddress2Valid = this.state.collectionAddress2Valid;
    let collectionAddress3Valid = this.state.collectionAddress3Valid;
    let zipCodeValid = this.state.zipCodeValid;
    let countryValid = this.state.countryValid;
    let remarksValid = this.state.remarksValid;
    let statusValid = this.state.statusValid;
    let currentStatus =
      this.state.collectionNote.status && this.state.collectionNote.status.text;
    switch (fieldName) {
      case "agentCode":
        if (value !== "") {
          agentCodeValid = validateNotRequired100(value);
          this.setState({
            agentCodeValid,
          });
          fieldValidationErrors.agentCode = agentCodeValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          if (currentStatus !== "In Progress") {
            this.setState({ agentCodeValid: false });
            fieldValidationErrors.agentCode = " Please enter Agent Code";
          } else {
            this.setState({ agentCodeValid: true });
            fieldValidationErrors.agentCode = "";
          }
        }
        break;
      case "status":
        if (value !== "null") {
          statusValid = true;
          fieldValidationErrors.status = statusValid
            ? ""
            : " Please select Status";
        } else {
          statusValid = true;
          fieldValidationErrors.status = "";
        }
        break;
      case "description":
        if (value !== "") {
          descriptionValid = validateNotRequired100(value);
          fieldValidationErrors.description = descriptionValid
            ? ""
            : resourceValidation.ValidDescription;
        } else {
          descriptionValid = false;
          fieldValidationErrors.description = "";
        }
        break;
      case "driverName":
        if (value !== "") {
          driverNameValid = validateNotRequired100(value);
          this.setState({
            driverNameValid,
          });
          fieldValidationErrors.driverName = driverNameValid
            ? ""
            : resourceValidation.validName;
        } else {
          if (currentStatus !== "In Progress") {
            this.setState({ driverNameValid: false });
            fieldValidationErrors.driverName = " Please enter Driver Name";
          } else {
            this.setState({ driverNameValid: true });
            fieldValidationErrors.driverName = "";
          }
        }
        break;
      case "contactPersonName":
        if (value) {
          contactPersonNameValid = validateNotRequired100(value);
          // console.log(value, contactPersonNameValid)
          fieldValidationErrors.contactPersonName = contactPersonNameValid
            ? ""
            : resourceValidation.ValidName;
          this.setState({ contactPersonNameValid })
        } else {
          contactPersonNameValid = false;
          fieldValidationErrors.contactPersonName = "";
          this.setState({ contactPersonNameValid })
        }
        break;
      case "contactNumber":
        if (value !== "") {
          contactNumberValid = validatePhoneNo(value);
          fieldValidationErrors.contactNumber = contactNumberValid
            ? ""
            : resourceValidation.validContact;
          this.setState({ contactNumberValid })
        } else {
          contactNumberValid = false;
          fieldValidationErrors.contactNumber = "";
          this.setState({ contactNumberValid })
        }
        break;
      case "collectionAddress":
        if (value !== "") {
          collectionAddressValid = validateNotRequired100(value);
          fieldValidationErrors.collectionAddress = collectionAddressValid
            ? ""
            : resourceValidation.validAddress;
          this.setState({ collectionAddressValid })
        } else {
          collectionAddressValid = false;
          fieldValidationErrors.collectionAddress = "";
          this.setState({ collectionAddressValid })
        }
        break;
      case "collectionAddress2":
        if (value !== "") {
          collectionAddress2Valid = validateNotRequired100(value);
          fieldValidationErrors.collectionAddress2 = collectionAddress2Valid
            ? ""
            : resourceValidation.validAddress;
          this.setState({ collectionAddress2Valid })
        } else {
          collectionAddress2Valid = true;
          fieldValidationErrors.collectionAddress2 = "";
          // this.setState({ collectionAddress2Valid })

        }
        break;
      case "collectionAddress3":
        if (value !== "") {
          collectionAddress3Valid = validateNotRequired100(value);
          fieldValidationErrors.collectionAddress3 = collectionAddress3Valid
            ? ""
            : resourceValidation.validAddress;
          this.setState({ collectionAddress3Valid })
        } else {
          collectionAddress3Valid = true;
          fieldValidationErrors.collectionAddress3 = "";
          // this.setState({ collectionAddress3Valid })
        }
        break;
      case "zipCode":
        if (value !== "") {
          zipCodeValid = validateZipCode(value);
          fieldValidationErrors.zipCode = zipCodeValid
            ? ""
            : resourceValidation.validZipCode;
          this.setState({ zipCodeValid })
        } else {
          zipCodeValid = false;
          fieldValidationErrors.zipCode = "";
          this.setState({ zipCodeValid })
        }
        break;
      case "countryID":
        if (value) {
          countryValid = true;
          // fieldValidationErrors.countryValid = countryValid
          //   ? ""
          //   : resourceValidation.validAddress;
          this.setState({ countryValid })
        } else {
          countryValid = false;
          // fieldValidationErrors.countryValid = "";
          this.setState({ countryValid })
        }
        break;
      case "remarks":
        if (value !== "") {
          remarksValid = validateNotRequired100(value);
          fieldValidationErrors.remarks = remarksValid
            ? ""
            : resourceValidation.validRemarks;
        } else {
          remarksValid = true;
          fieldValidationErrors.remarks = "";
        }
        break;
      case "pickupDate":
        if (value !== null) {
          pickupDateValid = value.toString() === "Invalid Date" ? false : true;
          fieldValidationErrors.pickupDate = pickupDateValid
            ? ""
            : " Invalid Date Format";
        } else {
          pickupDateValid = false;
          fieldValidationErrors.pickupDate = "";
        }
        break;
      case "pickupTime":
        if (value !== null) {
          pickupTimeValid = value.toString() === "Invalid Date" ? false : true;
          fieldValidationErrors.pickupTime = pickupTimeValid
            ? ""
            : " Invalid Time Format";
        } else {
          pickupTimeValid = false;
          fieldValidationErrors.pickupTime = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        // contactPersonNameValid: contactPersonNameValid,
        // contactNumberValid: contactNumberValid,
        descriptionValid: descriptionValid,
        // agentCodeValid: agentCodeValid,
        //driverNameValid: driverNameValid,
        remarksValid: remarksValid,
        // collectionAddressValid: collectionAddressValid,
        // collectionAddress2Vaild: collectionAddress2Valid,
        // collectionAddress3Valid: collectionAddress3Valid,
        // zipCodeValid: zipCodeValid,
        // countryValid: countryValid,
        statusValid: statusValid,
        pickupDateValid: pickupDateValid,
        pickupTimeValid: pickupTimeValid,
        submitSuccess: false,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.collectionNote.collectionToAddress &&
        this.state.contactPersonNameValid &&
        this.state.contactNumberValid &&
        this.state.agentCodeValid &&
        this.state.descriptionValid &&
        this.state.remarksValid &&
        this.state.driverNameValid &&
        this.state.collectionAddressValid &&
        this.state.collectionAddress2Valid &&
        this.state.collectionAddress3Valid &&
        this.state.zipCodeValid &&
        this.state.pickupDateValid &&
        this.state.pickupTimeValid &&
        this.state.countryValid &&
        this.state.statusValid &&
        this.detailListValid(),
    });

    return this.state.formValid;
  }
  detailListValid() {
    let valid = false;
    const { collectionNoteDetailList } = this.state;
    if (collectionNoteDetailList.length > 0) {
      valid = true;
      collectionNoteDetailList.map((r) => (valid = valid && r.validRecord));
      valid = valid && this.state.detailListValid ? true : false;
    }
    return valid;
  }
  handleDateChange = (date) => {
    const { collectionNote } = this.state;
    collectionNote["pickupDate"] = date && date.toLocaleDateString();
    this.setState({ collectionNote });
    this.validateField("pickupDate", date && date.toLocaleDateString());
  };
  handleTimeChange = (time) => {
    const { collectionNote } = this.state;
    collectionNote["pickupTime"] = time && time;
    this.setState({ collectionNote });
    this.validateField("pickupTime", time && time);
  };
  handleClose = () => {
    // clean up state and errors
    this.setState({
      contactPersonName: "",
      contactNumber: "",
      collectionAddress: "",
      zipCode: "",
      pickupDate: null,
      pickupTime: null,
      agentCode: "",
      driverName: "",
      remarks: "",
      errors: {},
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let codeID = this.props.match.params.collectionNoteId;
    if (this.validateForm()) {
      const { collectionNote, collectionNoteDetailList } = this.state;
      let workOrderID = collectionNote.workOrderID;
      let contactPersonName = collectionNote.contactPersonName;
      let contactNumber = collectionNote.contactNumber;
      let description = collectionNote.description;
      let collectionAddress = collectionNote.collectionAddress;
      let collectionToAddress = collectionNote.collectionToAddress;
      let collectionNoteAddress2 = collectionNote.collectionAddress2;
      let collectionNoteAddress3 = collectionNote.collectionAddress3;
      let zipCode = collectionNote.zipCode;
      let countryID = collectionNote.countryID;
      let isUrgent = collectionNote.isUrgent;
      let totalQuantity = 0;
      let pickupDate = collectionNote.pickupDate && collectionNote.pickupDate;
      let pickupTime =
        // moment(collectionNote.pickupTime) &&
        // moment(collectionNote.pickupTime).hours() +
        // ":" +
        // moment(collectionNote.pickupTime).minutes() +
        // ":" +
        // moment(collectionNote.pickupTime).seconds();
        moment(collectionNote.pickupTime).format("HH:mm")
      let remarks = collectionNote.remarks;
      let statusID = collectionNote.status.id;
      let agentCode = collectionNote.agentCode;
      let driverName = collectionNote.driverName;
      let modifiedBy = localStorage.loggedInId;
      let isActive = true;
      let CollectionNoteDetailList = collectionNoteDetailList;
      CollectionNoteDetailList.map(
        (r) =>
          (totalQuantity = parseInt(totalQuantity) + parseInt(r.totalQuantity))
      );
      let reqBody = {
        workOrderID,
        collectionToAddress,
        contactPersonName,
        contactNumber,
        collectionAddress,
        collectionNoteAddress2,
        collectionNoteAddress3,
        description,
        isUrgent,
        countryID,
        zipCode,
        totalQuantity,
        pickupDate,
        pickupTime,
        agentCode,
        driverName,
        statusID,
        isActive,
        modifiedBy,
        remarks,
      };
      updateCollectionNote(codeID, reqBody, CollectionNoteDetailList).then(
        (r) => {
          this.setState({ submitSuccess: r });
          if (r === true) {
            this.setState({
              resetList: CollectionNoteDetailList,
              formValid: false,
              currentStatus: collectionNote.status,
            });
            if (collectionNote.status.text === "Item Received") {
              this.props.history.push("/view_collectionNote/" + codeID);
            }
            // else if(collectionNote.status.text==="In Progress"){
            //   //TODO - Filter status
            // }
            // else{
            //   //TODO - Filter status
            // }
          }
        }
      );
    }
  };

  render() {
    let { classes } = this.props;
    // console.log(this.state.collectionNote.pickupTime, moment(this.state.collectionNote.pickupTime).format("HH:mm"))
    let {
      collectionNote,
      statusList,
      countryList,
      disableAdd,
      currentStatus,
      editableCollectionNoteDetailList,
    } = this.state;
    let totalQuantity = 0;
    editableCollectionNoteDetailList.map(
      (r) =>
        (totalQuantity = r.totalQuantity
          ? parseInt(totalQuantity) + parseInt(r.totalQuantity)
          : totalQuantity)
    );
    let filteredStatusList = [];
    if (currentStatus.text === "In Progress") {
      statusList &&
        statusList
          .filter((r) => r.value === "COC")
          .map((r) => filteredStatusList.push(r));
    } else if (currentStatus.text === "Out For Collection") {
      statusList &&
        statusList
          .filter((r) => r.value === "CIR")
          .map((r) => filteredStatusList.push(r));
    }
    let currentStatusValue = "";
    currentStatusValue = currentStatus && currentStatus.text;
    return (
      <React.Fragment>
        <Paper
          style={
            !this.props.clearOpenList
              ? {
                overflow: "auto",
                border: "1px solid #eaeaea",
                maxWidth: "calc(100vw - 8em)",
                //maxHeight: "calc(100vh - 300px)"
              }
              : {
                overflow: "auto",
                border: "1px solid #eaeaea",
                maxWidth: "calc(100vw - 330px)",
                //maxHeight: "calc(100vh - 300px)"
              }
          }
        >
          <div className="pop-up-header">Update Collection Note</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-4%" }}>
              <div className={classes.container} style={{ marginTop: "5%" }}>
                <div style={{ width: "99%" }}>
                  <Typography component="div" className="blueBox">
                    <div className="blueBoxLabel">
                      <div>Customer: </div>
                      <div className="blueBoxText">
                        {collectionNote.clientname}
                      </div>
                    </div>
                    <div className="blueBoxLabel">
                      <div>Work Order: </div>
                      <div className="blueBoxText">
                        {collectionNote.workOrderNo}
                      </div>
                    </div>
                    <div className="blueBoxLabel">
                      <div>Project Title: </div>
                      <div className="blueBoxText">{collectionNote.title}</div>
                    </div>
                    {/* <div className="blueBoxLabel">
                      <div>Job Group: </div>
                      <div className="blueBoxText">
                        {collectionNote.jobGroupNumber}
                      </div>
                    </div> */}
                  </Typography>
                  <Typography
                    component="div"
                    className="blueBox"
                    style={{
                      marginTop: "2px",
                    }}
                  >
                    <div className="blueBoxLabel">
                      <div>Collection Note Number: </div>
                      <div className="blueBoxText">
                        {collectionNote.collectionNoteNo}
                      </div>
                    </div>
                  </Typography>
                </div>
              </div>
              <form
                style={{ marginTop: "-1%" }}
                className={classes.container}
                onSubmit={this.handleSubmit}
              >
                <div
                  style={{
                    overflow: "auto",
                    // maxHeight: "calc(100vh - 350px)",
                    // minHeight: "300px"
                  }}
                ><p className={classes.subHeader}>To Address</p>
                  <div>

                    <TextField
                      required
                      multiline={true}
                      rows={1}
                      rowsMax={6}
                      inputProps={{
                        maxLength: 500,
                      }}
                      label="Collection To Address"
                      name="collectionToAddress"
                      autoComplete="off"
                      margin="normal"
                      // inputProps={{
                      //   readOnly: true,
                      // }}
                      className={classes.textField}
                      value={collectionNote.collectionToAddress}
                      onChange={this.handleChange}
                      error={this.state.errors.collectionToAddress}
                      helperText={
                        this.state.errors.collectionToAddress
                          ? this.state.errors.collectionToAddress
                          : ""
                      }
                    />
                  </div>
                  <p className={classes.subHeader}>From Address</p>
                  <div>
                    <TextField
                      required
                      rows={1}
                      rowsMax={4}
                      label="Company"
                      name="description"
                      inputProps={{
                        maxLength: 200,
                      }}
                      margin="normal"
                      className={classes.textFieldWide}
                      value={collectionNote.description}
                      onChange={this.handleChange}
                      error={this.state.errors.description}
                      helperText={
                        this.state.errors.description
                          ? this.state.errors.description
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      required
                      label="Address 1"
                      name="collectionAddress"
                      autoComplete="off"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      value={collectionNote.collectionAddress}
                      onChange={this.handleChange}
                      error={this.state.errors.collectionAddress}
                      helperText={
                        this.state.errors.collectionAddress
                          ? this.state.errors.collectionAddress
                          : ""
                      }
                    />
                    <TextField
                      label="Address 2"
                      name="collectionAddress2"
                      autoComplete="off"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      value={collectionNote.collectionAddress2}
                      onChange={this.handleChange}
                      error={this.state.errors.collectionAddress2}
                      helperText={
                        this.state.errors.collectionAddress2
                          ? this.state.errors.collectionAddress2
                          : ""
                      }
                    />
                    <TextField
                      label="Address 3"
                      name="collectionAddress3"
                      autoComplete="off"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      value={collectionNote.collectionAddress3}
                      onChange={this.handleChange}
                      error={this.state.errors.collectionAddress3}
                      helperText={
                        this.state.errors.collectionAddress3
                          ? this.state.errors.collectionAddress3
                          : ""
                      }
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      required
                      label="Zip Code"
                      name="zipCode"
                      margin="normal"
                      inputProps={{
                        maxLength: 6,
                      }}
                      className={classes.textField}
                      value={collectionNote.zipCode}
                      onChange={this.handleChange}
                      error={this.state.errors.zipCode}
                      helperText={
                        this.state.errors.zipCode
                          ? this.state.errors.zipCode
                          : ""
                      }
                    />
                    <Autocomplete
                      autoComplete
                      includeInputInList
                      id="countryList"
                      options={countryList}
                      className={classes.autoComplete}
                      onChange={this.handleCountryChange}
                      value={collectionNote.countryList}
                      getOptionLabel={(option) => option.text}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Select Country"
                          name="countryList"
                          id="countryList"
                          className={classes.autocompleteText}
                          error={this.state.errors.countryList}
                          helperText={
                            this.state.errors.countryList
                              ? this.state.errors.countryList
                              : ""
                          }
                        />
                      )}
                    />
                  </div>
                  <p className={classes.subHeader}>Contact Info</p>
                  <div>
                    <TextField
                      required
                      label="Contact Person"
                      name="contactPersonName"
                      inputProps={{
                        maxLength: 100,
                      }}
                      margin="normal"
                      autoComplete="off"
                      className={classes.textField}
                      value={collectionNote.contactPersonName}
                      onChange={this.handleChange}
                      error={this.state.errors.contactPersonName}
                      helperText={
                        this.state.errors.contactPersonName
                          ? this.state.errors.contactPersonName
                          : ""
                      }
                    />
                    <TextField
                      required
                      label="Contact Person Number"
                      name="contactNumber"
                      inputProps={{
                        maxLength: 20,
                      }}
                      margin="normal"
                      autoComplete="off"
                      className={classes.textField}
                      value={collectionNote.contactNumber}
                      onChange={this.handleChange}
                      error={this.state.errors.contactNumber}
                      helperText={
                        this.state.errors.contactNumber
                          ? this.state.errors.contactNumber
                          : ""
                      }
                    />
                  </div>
                  <p className={classes.subHeader}>Pickup Details</p>
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        disablePast
                        required
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        className={classes.textField}
                        id="pickupDate"
                        label="Pickup Date"
                        // minDate={new Date("01-01-2020")}
                        // error={false}
                        // minDateMessage={""}
                        value={collectionNote.pickupDate}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={TimeFnsUtils}>
                      <KeyboardTimePicker
                        autoOk
                        required
                        variant="inline"
                        margin="normal"
                        className={classes.textField}
                        id="pickupTime"
                        label="Pickup Time"
                        value={collectionNote.pickupTime}
                        onChange={this.handleTimeChange}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={collectionNote.isUrgent}
                          onChange={this.handlePriorityChange("isUrgent")}
                          value="isUrgent"
                          color="primary"
                        />
                      }
                      label="Urgent"
                      className={classes.switchControl}
                    />
                  </div>
                  <div>
                    <TextField
                      label="Agent Code"
                      name="agentCode"
                      margin="normal"
                      inputProps={{
                        maxLength: 50,
                      }}
                      className={classes.textField}
                      value={collectionNote.agentCode}
                      onChange={this.handleChange}
                      error={this.state.errors.agentCode}
                      helperText={
                        this.state.errors.agentCode
                          ? this.state.errors.agentCode
                          : ""
                      }
                    />
                    <TextField
                      label="Driver Name"
                      name="driverName"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      value={collectionNote.driverName}
                      onChange={this.handleChange}
                      error={this.state.errors.driverName}
                      helperText={
                        this.state.errors.driverName
                          ? this.state.errors.driverName
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      multiline={true}
                      rows={1}
                      rowsMax={4}
                      label="Special Instructions"
                      name="remarks"
                      inputProps={{
                        maxLength: 500,
                      }}
                      margin="normal"
                      className={classes.textField}
                      value={collectionNote.remarks}
                      onChange={this.handleChange}
                      error={this.state.errors.remarks}
                      helperText={
                        this.state.errors.remarks
                          ? this.state.errors.remarks
                          : ""
                      }
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <Autocomplete
                      autoComplete
                      includeInputInList
                      id="status"
                      options={filteredStatusList}
                      className={classes.autoComplete}
                      onChange={this.handleStatusChange}
                      getOptionLabel={(option) => option.text}
                      value={collectionNote.status}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Update Status"
                          name="status"
                          id="status"
                          className={classes.autocompleteText}
                          error={this.state.errors.status}
                          value={collectionNote.status}
                          helperText={
                            this.state.errors.status
                              ? this.state.errors.status
                              : ""
                          }
                        />
                      )}
                    />
                    <TextField
                      label="Current Status"
                      name="status"
                      margin="normal"
                      inputProps={{
                        readOnly: true,
                      }}
                      className={classes.textField}
                      value={currentStatusValue}
                    />
                  </div>
                  <div style={{ marginTop: "2%" }}>
                    <p className={classes.subHeader}>Collection Items</p>
                    <div
                      style={{
                        display: "flex",
                        //width: "100%",
                        border: "1px solid #eaeaea",
                        borderBottom: "none",
                        borderRadius: "4px",
                        margin: "0 .8em 0 .6em",
                      }}
                    >
                      <Typography
                        component="div"
                        style={{
                          width: "100%",
                        }}
                        className="blueBox"
                      >
                        <div className="blueBoxLabel">
                          <div>Total Quantity: </div>
                          <div className="blueBoxText">{totalQuantity}</div>
                        </div>
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <EnhancedTableToolbar
                      //numSelected={selected.length}
                      editableCollectionNoteDetailList={
                        this.state.editableCollectionNoteDetailList
                      }
                      resetList={this.state.resetList}
                      handleClickButton={this.handleClickButton}
                      disableAdd={this.state.disableAdd}
                      disableEdit={this.state.disableEdit}
                      validList={this.state.detailListValid}
                    />
                  </div>
                  <div
                    style={{
                      // width: "100%",
                      maxHeight: "700px",
                      margin: "0 .8em 0 .6em",
                    }}
                  >
                    <div
                      // style={{
                      //   maxHeight: 200,
                      //   overflow: "auto",
                      //   border: "1px solid #eaeaea"
                      // }}
                      style={
                        !this.props.clearOpenList
                          ? {
                            overflow: "auto",
                            border: "1px solid #eaeaea",
                            maxWidth: "calc(100vw - 8em)",
                            maxHeight: "calc(100vh - 300px)",
                          }
                          : {
                            overflow: "auto",
                            border: "1px solid #eaeaea",
                            maxWidth: "calc(100vw - 330px)",
                            maxHeight: "calc(100vh - 300px)",
                          }
                      }
                    >
                      {/* //overflow: "auto" */}
                      <Table
                        aria-labelledby="tableTitle"
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                      >
                        {/* <caption style={{ borderBottom: "1px solid #eaeaea" }}>
                  A barbone structure table example with a caption
                </caption> */}
                        <EnhancedTableHead
                        //numSelected={selected.length}
                        // order={order}
                        // orderBy={orderBy}
                        // onSelectAllClick={e => this.handleSelectAllClick(e)}
                        //  onRequestSort={this.handleRequestSort}
                        //  rowCount={departmentList.length}
                        />

                        <TableBody style={{ overflow: "hidden" }}>
                          {editableCollectionNoteDetailList.map(
                            (row, index) => {
                              // const isItemSelected = isSelected(row.id);
                              const labelId = `enhanced-table-checkbox-${index}`;

                              return (
                                <TableRow
                                  // hover
                                  // onClick={event =>
                                  //   this.handleClick(event, row.id, row)
                                  // }
                                  role="checkbox"
                                  //  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.id}
                                  id={`tableRow${index}`}
                                //   selected={isItemSelected}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                  >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {editableCollectionNoteDetailList[index]
                                        .editClicked ? (
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "description",
                                              maxLength: 200,
                                            }}
                                            style={{ width: "100%" }}
                                            name="stockDescription"
                                            value={row.stockDescription}
                                            onChange={(e) =>
                                              this.handleRowChange(e, index)
                                            }
                                          />
                                        ) : (
                                          row.stockDescription
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {editableCollectionNoteDetailList[index]
                                        .editClicked ? (
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "description",
                                              maxLength: 10,
                                            }}
                                            name="noOfPts"
                                            onKeyPress={this._onKeyPress}
                                            style={{ width: "100%" }}
                                            value={row.noOfPts}
                                            onChange={(e) =>
                                              this.handleRowChange(e, index)
                                            }
                                          />
                                        ) : (
                                          row.noOfPts
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {editableCollectionNoteDetailList[index]
                                        .editClicked ? (
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "description",
                                              maxLength: 10,
                                            }}
                                            name="totalPackage"
                                            style={{ width: "100%" }}
                                            value={row.totalPackage}
                                            onKeyPress={this._onKeyPress}
                                            onChange={(e) =>
                                              this.handleRowChange(e, index)
                                            }
                                          />
                                        ) : (
                                          row.totalPackage
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {editableCollectionNoteDetailList[index]
                                        .editClicked ? (
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "description",
                                              maxLength: 10,
                                            }}
                                            name="noOfCopies"
                                            onKeyPress={this._onKeyPress}
                                            style={{ width: "100%" }}
                                            value={row.noOfCopies}
                                            onChange={(e) =>
                                              this.handleRowChange(e, index)
                                            }
                                          />
                                        ) : (
                                          row.noOfCopies
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {/* {" "}
                                      {editableCollectionNoteDetailList[index]
                                        .editClicked ? (
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "description",
                                              maxLength: 10,
                                            }}
                                            name="totalQuantity"
                                            style={{ width: "100%" }}
                                            onKeyPress={this._onKeyPress}
                                            value={row.totalQuantity}
                                            onChange={(e) =>
                                              this.handleRowChange(e, index)
                                            }
                                          />
                                        ) : (
                                          row.totalQuantity
                                        )} */}
                                      {row.totalQuantity}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {editableCollectionNoteDetailList[index]
                                        .editClicked ? (
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "description",
                                              maxLength: 10,
                                            }}
                                            name="weightPerCortanBox"
                                            value={row.weightPerCortanBox}
                                            style={{ width: "100%" }}
                                            onBlur={(e) => this.onBlur(e, index)}
                                            onKeyPress={this._onDecimalKeyPress}
                                            // onChange={(e) =>
                                            //   this.handleDecimalValueChange(
                                            //     e,
                                            //     index
                                            //   )
                                            // }
                                            onChange={(e) =>
                                              this.handleRowChange(e, index)
                                            }
                                          />
                                        ) : (
                                          row.weightPerCortanBox
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {/* {" "}
                                      {editableCollectionNoteDetailList[index]
                                        .editClicked ? (
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "description",
                                              maxLength: 10,
                                            }}
                                            name="netWeight"
                                            value={row.netWeight}
                                            style={{ width: "100%" }}
                                            onBlur={(e) => this.onBlur(e, index)}
                                            onKeyPress={this._onDecimalKeyPress}
                                            onChange={(e) =>
                                              this.handleDecimalValueChange(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                        ) : (
                                          row.netWeight
                                        )} */}
                                      {(+row.netWeight).toFixed(3)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="center"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                        display: "flex",
                                      }}
                                    >
                                      {editableCollectionNoteDetailList[index]
                                        .editClicked ? (
                                          <div
                                            style={{
                                              alignContent: "center",
                                              width: "100%",
                                            }}
                                          >
                                            {row.validRecord ? (
                                              <span
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                                onClick={(e) =>
                                                  this.onDoneClicked(
                                                    e,
                                                    index,
                                                    row
                                                  )
                                                }
                                              >
                                                <DoneIcon
                                                  style={{
                                                    color: "#6c6b6b",
                                                  }}
                                                />
                                              </span>
                                            ) : (
                                                <React.Fragment></React.Fragment>
                                              )}
                                            <span
                                              style={{
                                                cursor: "pointer",
                                                color: "#6c6b6b",
                                              }}
                                              onClick={(e) =>
                                                this.onCancelClicked(
                                                  e,
                                                  index,
                                                  row
                                                )
                                              }
                                            >
                                              <CloseIcon
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                              />
                                            </span>
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              alignContent: "center",
                                              width: "100%",
                                            }}
                                          >
                                            {!disableAdd ? (
                                              <span
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                                onClick={(e) =>
                                                  this.onEditClicked(e, index)
                                                }
                                              >
                                                <EditIcon
                                                  style={{
                                                    color: "#6c6b6b",
                                                  }}
                                                />
                                              </span>
                                            ) : (
                                                <React.Fragment></React.Fragment>
                                              )}
                                            <span
                                              style={{
                                                cursor: "pointer",
                                                color: "#6c6b6b",
                                              }}
                                              onClick={(e) =>
                                                this.onDeleteClicked(
                                                  e,
                                                  index,
                                                  row
                                                )
                                              }
                                            >
                                              <DeleteIcon
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                              />
                                            </span>
                                          </div>
                                        )}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/collectionNote")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        onClick={this.handleSubmit}
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          onClick={this.handleSubmit}
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={() => this.handleShowPopup()}
                    >
                      <IconButton
                        aria-label="print"
                        size="small"
                        style={{ color: "#fff" }}
                      >
                        <CustomPrintIcon style={{ color: "#fff" }} />
                      </IconButton>
                      {"PRINT COLLECTION NOTE"}
                    </Button>
                  </div>
                  {this.state.showPopup && (
                    <ReportViewer
                      url={
                        `${window.localStorage.ReportURLByID}` +
                        "CollectionNote&IsDirectPrint=True&ReportParam=CollectionNoteID&Param1=" +
                        `${this.props.match.params.collectionNoteId}`
                      }
                      showPopup={this.state.showPopup}
                      title={"Collection Note"}
                      onClose={() => this.handleHidePopup()}
                      handleHidePopup={() => this.handleHidePopup()}
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(EditCollectionNote));
