import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import ReportViewer from "../../app/ReportViewer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { Checkbox, Tooltip, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
// import { serchCustomerOrder } from "../docketPosting/actions";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AdvanceSearch from "./search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { serchCustomerOrder, } from "./actions";
import EdsErrorModal from "./edsErrorModal"
import {
  getDockets,
  generateEDocket,
  generateEDocketInternational,
  getEdsErrorDetails
} from "./actionsDocket";
import { StateContext } from "../../../shared/globalState";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import Validator from "../../../shared/validator";
import StatusText from "../../../shared/statusText";
// import DeleteOrder from "./delete";
import {
  desc,
  stableSort,
  getSorting,
  DialogActions,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import moment from "moment";
import "../../app/App.css";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { ExportCSV } from "../../../shared/ExportCSV";
import {
  SearchIcon,
  ViewColumnIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import LinearProgress from "@material-ui/core/LinearProgress";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import clone from "clone";

const validateMaxlength2 = new Validator("maxlength2");

const columns = [
  {
    id: 5,
    name: "Status",
  },
  {
    id: 6,
    name: "Exception",
  },
  {
    id: 12,
    name: "Modified Date",
  },
  {
    id: 13,
    name: "Modified By",
  },
  {
    id: 14,
    name: "Created Date",
  },
  {
    id: 15,
    name: "Created By",
  },
];

let initailData = [];
function createData(
  docketHeaderID,
  customerName,
  workOrderNumber,
  projectTitle,
  jobGroupNumber,
  // planner,
  pgsDocketNO,
  eDocketNO,
  // pgsDocketNO,
  // edsDocketNO,
  // accountNo,
  estimatedLodgementDate,
  submittedDate,
  serviceType,
  statusName,
  createdByName,
  statusId,
  createdByNameName,
  createdDate,
  modifiedBy,
  modifiedByName,
  modifiedDate,
  isLocal,
  statusCode,
  edsExceptionMessage
) {
  return {
    docketHeaderID,
    customerName,
    workOrderNumber,
    projectTitle,
    jobGroupNumber,
    // planner,
    pgsDocketNO,
    eDocketNO,
    // pgsDocketNO,
    // edsDocketNO,
    // accountNo,
    estimatedLodgementDate,
    submittedDate,
    serviceType,
    statusName,
    createdByName,
    statusId,
    createdByNameName,
    createdDate,
    modifiedBy,
    modifiedByName,
    modifiedDate,
    isLocal,
    statusCode,
    edsExceptionMessage,
  };
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{
            border: "1px solid #eaeaea",
            //  background: "white", 
            width: "10px",
            borderBottom: "1px solid #eaeaea !important"
          }}
          className="table-cell"
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "Select All Countrys" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
            //);
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  // console.log(props.selectedDocket);
  const [errors, setErrors] = useState({
    countryCode: "",
    countryName: "",
  });
  const [formValid, setIsformValid] = useState(false);
  let acnArr = props.roles.filter((u) => u.menuName === "Docket Posting");
  let acnObj = acnArr[0];

  const [ImSearchValue, setValues] = useState({
    customerName: "",
    workOrderNumber: "",
    status: "",
    jobTitle: "",
    jobGroupNumber: "",
    planner: "",
    pgsDocketNO: "",
    edsDocketNO: "",
    accountNo: "",
    estimatedLodgementDate: null,
    submittedDate: null,
    serviceType: "",
    modifiedDateFrom: null,
    modifiedDateTo: null,
  });

  const classes = useToolbarStyles();
  const {
    numSelected,
    searchPopupOpen,
    handleViewColumns,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    selected,
    docketPostingList,
    isOpen,
    exportDataValues,
  } = props;

  const selectedDocketID = selected[0];
  const selectedDocket = docketPostingList.find(
    (x) => x.docketID === selectedDocketID
  );

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  // const classes = searchPopupOpen();

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = ImSearchValue;
    searchValues.customerName = "";
    searchValues.workOrderNumber = "";
    searchValues.status = "";
    searchValues.jobTitle = "";
    searchValues.jobGroupNumber = "";
    searchValues.planner = "";
    searchValues.pgsDocketNO = "";
    searchValues.edsDocketNO = "";
    searchValues.accountNo = "";
    searchValues.estimatedLodgementDate = null;
    searchValues.submittedDate = null;
    searchValues.serviceType = "";
    searchValues.modifiedDateFrom = null;
    searchValues.modifiedDateTo = null;
    searchValues = ImSearchValue;
  };

  const searchPopup = () => {
    props.handleSearchItemMaster(ImSearchValue);

    clearSearchValues();
  };

  const validateNotRequired100 = new Validator("maxlength100AllAllow");

  const handleSearchInput = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;
    setValues((ImSearchValue) => ({
      ...ImSearchValue,
      [event.target.name]: event.target.value,
    }));
    switch (name) {
      case "customerName":
        let customerNameValid = false;
        if (value !== "") {
          customerNameValid = validateNotRequired100(value);
          setIsformValid(customerNameValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: customerNameValid ? "" : "",
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: customerNameValid ? "" : "",
          }));
        }
        break;
      case "workOrderNumber":
        let workOrderNumberValid = false;
        if (value !== "") {
          workOrderNumberValid = validateNotRequired100(value);
          setIsformValid(workOrderNumberValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: workOrderNumberValid ? "" : "",
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: workOrderNumberValid ? "" : "",
          }));
        }
        break;
      case "jobTitle":
        let jobTitleValid = false;
        if (value !== "") {
          jobTitleValid = validateNotRequired100(value);
          setIsformValid(jobTitleValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: jobTitleValid ? "" : "",
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: jobTitleValid ? "" : "",
          }));
        }
        break;
      case "jobGroupNumber":
        let jobGroupNumberValid = false;
        if (value !== "") {
          jobGroupNumberValid = validateNotRequired100(value);
          setIsformValid(jobGroupNumberValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: jobGroupNumberValid ? "" : "",
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: jobGroupNumberValid ? "" : "",
          }));
        }
        break;
      case "planner":
        let plannerValid = false;
        if (value !== "") {
          plannerValid = validateNotRequired100(value);
          setIsformValid(plannerValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: plannerValid ? "" : "",
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: plannerValid ? "" : "",
          }));
        }
        break;
      case "pgsDocketNO":
        let pgsDocketNOValid = false;
        if (value !== "") {
          pgsDocketNOValid = validateNotRequired100(value);
          setIsformValid(pgsDocketNOValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: pgsDocketNOValid ? "" : "",
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: pgsDocketNOValid ? "" : "",
          }));
        }
        break;
      case "edsDocketNO":
        let edsDocketNOValid = false;
        if (value !== "") {
          edsDocketNOValid = validateNotRequired100(value);
          setIsformValid(edsDocketNOValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: edsDocketNOValid ? "" : "",
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: edsDocketNOValid ? "" : "",
          }));
        }
        break;
      case "accountNo":
        let accountNoValid = false;
        if (value !== "") {
          accountNoValid = validateNotRequired100(value);
          setIsformValid(accountNoValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: accountNoValid ? "" : "",
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: accountNoValid ? "" : "",
          }));
        }
        break;
      case "serviceType":
        let serviceTypeValid = false;
        if (value !== "") {
          serviceTypeValid = validateNotRequired100(value);
          setIsformValid(serviceTypeValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: serviceTypeValid ? "" : "",
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: serviceTypeValid ? "" : "",
          }));
        }
        break;
      default:
        break;
    }
  };

  const handleClickListItem = () => {
    setOpen(true);
  };
  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
  };

  const handleDateChange = (date) => {
    let name = "modifiedDateFrom";
    setValues((ImSearchValue) => ({
      ...ImSearchValue,
      [name]: date && date.toLocaleDateString(),
    }));
    isDateValid(name, date);
  };
  const handleDateChange1 = (date) => {
    let name = "modifiedDateTo";
    setValues((ImSearchValue) => ({
      ...ImSearchValue,
      [name]: date && date.toLocaleDateString(),
    }));
    isDateValid(name, date);
  };

  const handleLodgementDateChange = (date) => {
    let name = "estimatedLodgementDate";
    setValues((ImSearchValue) => ({
      ...ImSearchValue,
      [name]: date && date.toLocaleDateString(),
    }));
    isDateValid(name, date);
  };

  const handleSubmittedDateChange = (date) => {
    let name = "submittedDate";
    setValues((ImSearchValue) => ({
      ...ImSearchValue,
      [name]: date && date.toLocaleDateString(),
    }));
    isDateValid(name, date);
  };

  const isDateValid = (name, date) => {
    let searchValues = ImSearchValue;
    if (name === "modifiedDateFrom") {
      if (date !== null && searchValues.modifiedDateTo !== null) {
        setIsformValid(
          date.toString() !== "Invalid Date" &&
            searchValues.modifiedDateTo.toString() !== "Invalid Date"
            ? true
            : false
        );
      }
    } else {
      if (date !== null && searchValues.modifiedDateFrom !== null) {
        setIsformValid(
          date.toString() !== "Invalid Date" &&
            searchValues.modifiedDateFrom.toString() !== "Invalid Date"
            ? true
            : false
        );
      }
    }
  };

  const handleSearchStatusValuesChange = (event, values) => {
    event.persist();
    let value = event.target.value;
    setValues((jobScheduleList) => ({
      ...jobScheduleList,
      status: values,
    }));
    if (value !== undefined) setIsformValid(true);
    else setIsformValid(false);
  };

  // console.log(isOpen, "isOpen");
  // console.log(numSelected)
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          minHeight: "50px",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {props.gedBtnChk
              &&
              numSelected > 0 && (
                <React.Fragment>
                  <div style={{ marginTop: "7px", marginLeft: "1em" }}>
                    {" "}
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        padding: "0px .5em 0px .5em",
                        textTransform: "none",
                        //fontWeight: "400",
                        // fontSize: "13px",
                        height: "35px",
                        boxShadow: "none",
                      }}
                      onClick={props.handleSubmitEDocket}
                    >
                      Generate E-Docket
                    </Button>
                  </div>
                  {/* <div style={{ marginTop: "7px", marginLeft: "1em" }}>
                    {" "}
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        boxShadow: "none",
                        padding: "0px .5em 0px .5em",
                        textTransform: "none",
                        //fontWeight: "400",
                        // fontSize: "13px",
                        height: "35px",
                      }}
                    >
                      Generate Manual Docket Report
                    </Button>
                  </div> */}
                </React.Fragment>
              )}
            {numSelected === 1 && (
              <div style={{ marginTop: "7px", marginLeft: "1em" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    padding: "0px .5em 0px .5em",
                    textTransform: "none",
                    //fontWeight: "400",
                    // fontSize: "13px",
                    height: "35px",
                    boxShadow: "none",
                  }}
                  onClick={props.handleReportClick}
                >
                  Docket Report
                </Button>
              </div>
            )}
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link
                      to={`/view_docketPosting/${props.selectedDocket.docketHeaderID}`}
                    >
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {props.searchClicked ? (
                <IconButton
                  aria-label="clear"
                  className={classes.iconHover}
                  onClick={(e) => props.clearSearch()}
                >
                  <HighlightOffIcon />
                </IconButton>
              ) : (
                  <AdvanceSearch onSearchClicked={props.onSearchClicked}
                    onRequestSucceed={props.onRequestSucceed} />
                )}
              {acnObj && acnObj.exportAction && (
                <Tooltip title="Download">
                  <ExportCSV
                    csvData={exportDataValues}
                    fileName={"DocketPosting"}
                  />
                </Tooltip>
              )}
              <IconButton
                aria-label="viewColumn"
                className={classes.iconHover}
                onClick={handleClickListItem}
              >
                <ViewColumnIcon />
              </IconButton>

              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                handleViewColumns={handleViewColumns}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 10.2;
  const left = 77;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    handleViewColumns,
    open,
    ...other
  } = props;
  const [column, setColumnIds] = React.useState(valueProp);
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  }, [valueProp, open]);

  const handleClose = () => {
    onClose(setColumnIds);
  };

  const handleChange = (event) => {
    setColumnIds(event.target.value);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => handleViewColumns(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class DocketPosting extends React.Component {
  static contextType = StateContext;
  state = {
    exportDataValues: [],
    showPopupLocal: false,
    showPopupInternational: false,
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    editable: true,
    docketPostingList: [],
    loading: false,
    docketHeader: {},
    docketHeaderID: "",
    selectedShowColumns: [],
    searchPopupOpen: false,
    countrySearchValues: {},
    searchClicked: false,
    isDisabled: [],
    gedBtnChk: true,
    openEdsErrorModal: false,
    headCells: [
      {
        code: "customerName",
        id: 1,
        show: false,
        numeric: false,
        disablePadding: true,
        label: "Customer Code",
        show: true,
      },
      {
        code: "workOrderNumber",
        id: 2,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Work Order No",
        show: true,
      },
      {
        code: "projectTitle",
        id: 3,
        numeric: false,
        disablePadding: false,
        label: "Title/Desc",
        show: true,
      },
      {
        id: 4,
        code: "jobGroupNumber",
        numeric: false,
        disablePadding: false,
        label: "Job Group No",
        show: true,
      },
      {
        id: 5,
        code: "statusName",
        numeric: false,
        disablePadding: false,
        label: "Status",
        show: true,
      },
      {
        id: 6,
        code: "edsExceptionMessage",
        numeric: false,
        disablePadding: false,
        label: "Exception",
        show: true,
      },
      {
        id: 7,
        code: "eDocketNO",
        numeric: false,
        disablePadding: false,
        label: "EDS Docket No",
        show: true,
      },
      // {
      //   id: 8,
      //   code: "accountNo",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Account No",
      //   show: true,
      // },

      {
        id: 8,
        code: "estimatedLodgementDate",
        numeric: false,
        disablePadding: false,
        label: "Estimated Lodgement Date",
        show: true,
      },

      {
        id: 9,
        code: "submittedDate",
        numeric: false,
        disablePadding: false,
        label: "Submitted Date",
        show: true,
      },

      {
        id: 10,
        code: "serviceType",
        numeric: false,
        disablePadding: false,
        label: "Service Type",
        show: true,
      },

      // {
      //   id: 12,
      //   code: "planner",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Planner",
      //   show: true,
      // },

      {
        id: 11,
        code: "pgsDocketNO",
        numeric: false,
        disablePadding: false,
        label: "PGS Docket No",
        show: true,
      },
      {
        id: 12,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 13,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 14,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 15,
        code: "createdByName",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
    localDocketList: [],
    internationalDocketList: [],
    isOpen: [],
    currentStatusCode: null,
    edsErrorData: ""
  };
  handleViewColumns = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  handleSearchItemMaster = (handleSearchItemMaster) => {
    let ImSearchValue = handleSearchItemMaster;
  };

  componentDidMount() {
    this.props.handleSettingHeader("Docket Posting");
    this.props.setSelectedList("genericSystemOpen", "docketPosting");

    const [{ store }, dispatch] = this.context;
    getDockets().then((r) => {
      //console.log(r.data, "data");
      dispatch({
        type: "getPlacedOrderList",
        newStore: r.data,
      });
      this.setState({ loading: true });
      this.exportDeliveryNotes(r.data);
      this.getAllPlacedOrder(r.data);
    });
    this.setState({ loading: true });
  }
  shouldComponentUpdate(s, p) {
    const [{ store }, dispatch] = this.context;
    return true;
  }

  onRequestSucceed = (deleted) => {
    const [{ store }, dispatch] = this.context;
    getDockets().then((r) => {
      dispatch({
        type: "getPlacedOrderList",
        newStore: r.data,
      });
      if (deleted) this.setState({ searchClicked: false, selected: [] });
      this.getAllPlacedOrder(r.data);
    });
  };
  exportDeliveryNotes = (deliveryNoteList) => {
    let list = deliveryNoteList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          Customer: d.customerName,

          "Work Order No": d.workOrderNumber,
          "Title/Desc": d.projectTitle,
          "Job Group No": d.jobGroupNumber,
          "Status": d.statusName,
          "Exception": d.edsExceptionMessage,
          "EDS Docket No": d.eDocketNO,
          "Account No": d.accountNo,
          "Estimated Lodgement Date": moment(d.estimatedLodgementDate).format(
            "DD/MM/YYYY"
          ),
          "Total Qty": d.totalQuantity,
          "Submitted Date": d.submittedDate,
          "Service Type": d.serviceType,

          "Planner": d.planner,
          "PGS Docket No": d.pgsDocketNO,
          "Modified Date": moment(d.modifiedDate).format("DD/MM/YYYY HH:mm:ss"),

          "Modified By": d.modifiedByName,
          "Created Date": moment(d.createdDate).format("DD/MM/YYYY HH:mm:ss"),

          "Created By": d.createdByName,
        });
      });
    this.setState({ exportDataValues: rows });
  };
  getAllPlacedOrder = (lpList) => {
    // console.log(lpList);
    let list = lpList;
    let newRows = [];
    //  console.log(list);
    list &&
      list.length > 0 &&
      list.map((d) => {
        newRows.push(
          createData(
            d.docketHeaderID,
            d.customerCode,
            d.workOrderNumber,
            d.projectTitle,
            d.jobGroupNumber,
            // d.planner,
            d.pgsDocketNO,
            d.eDocketNO,
            // d.accountNo,
            d.estimatedLodgementDate,
            d.submittedDate,
            d.serviceType,
            d.statusName,
            d.createdByName,
            d.statusId,
            d.createdByNameName,
            d.createdDate,
            d.modifiedBy,
            d.modifiedByName,
            d.modifiedDate,
            d.isLocal,
            d.statusCode,
            d.edsExceptionMessage
          )
        );
      });
    this.setState({ rows: newRows, loading: false });
    initailData = newRows;
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;

    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };
  handleSelectAllClick = (event) => {
    let { rows } = this.state;
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.docketHeaderID);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = async (event, data) => {
    if (data.statusCode !== "DCCAN") {
      this.setState({
        currentStatusCode: data.statusCode,
      });
      let rowSelected = [];
      let docketHeaderID = data.docketHeaderID;
      let { selected, selectedArray = [] } = this.state;
      const selectedIndex = selected.indexOf(docketHeaderID);
      const selectedIndexForArr = selectedArray.indexOf(data.docketHeaderID);
      let newSelected = [];
      let newSelectedArray = []
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, docketHeaderID);
        newSelectedArray = newSelectedArray.concat(selectedArray, data);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        newSelectedArray = newSelectedArray.concat(selectedArray.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        newSelectedArray = newSelectedArray.concat(selectedArray.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
        newSelectedArray = newSelectedArray.concat(
          selectedArray.slice(0, selectedIndex),
          selectedArray.slice(selectedIndex + 1)
        );
      }

      if (newSelected.length === 1) {
        rowSelected = this.state.rows.filter(
          (r) => r.docketHeaderID === newSelected[0]
        );
        this.setState({
          selected: newSelected,
          selectedDocket: data,
          selectedDocketID: docketHeaderID,
          selectedArray: newSelectedArray
        });
      } else {
        this.setState({
          selected: newSelected,
          selectedDocket: data,
          selectedDocketID: docketHeaderID,
          selectedArray: newSelectedArray
        });
      }
      let gedBtnChk = true
      newSelectedArray.map(d => {
        if (d.statusCode === "DCMDOC"
          || d.statusCode === "DCEDOC") {
          gedBtnChk = false
        }
        this.setState({ gedBtnChk })
      })
      const { store } = this.context[0];
      const dispatch = this.context[1];
      // console.log(newSelectedArray)
      let docketListIds = clone(newSelected);
      let docketList =
        this.state.rows &&
        this.state.rows.filter(
          (d) => docketListIds.indexOf(d.docketHeaderID) >= 0
        );
      this.setState({
        docketList: docketList,
      });

      const arr = docketList;
      const filtered = arr.reduce(
        (a, o) => (o.isLocal && a.push(o.docketHeaderID), a),
        []
      );
      const filtered1 = arr.reduce(
        (a, o) => (o.isLocal === false && a.push(o.docketHeaderID), a),
        []
      );
      const filteredOpen = this.state.rows.reduce(
        (a, o) => (o.statusName == "Open" && a.push(o.docketHeaderID), a),
        []
      );

      this.setState({
        localDocketList: filtered,
        internationalDocketList: filtered1,
        isOpen: filteredOpen,
      });
    } else {
      this.setState({
        currentStatusCode: data.statusCode,
      });
      event.preventDefault();
    }
  };

  handleSubmitEDocket = (event, values) => {
    const { localDocketList, internationalDocketList } = this.state;

    let localIds = localDocketList.join(",");

    let internationalIds = internationalDocketList.join(",");
    const dispatch = this.context[1];

    if (localDocketList.length > 0) {
      this.setState({ loading: true });
      generateEDocket(localIds)
        .then((r) => {
          // console.log(r);
          dispatch({
            type: "getPlacedOrderList",
            newStore: r.data,
          });
          getDockets().then((r) => {
            dispatch({
              type: "getPlacedOrderList",
              newStore: r.data,
            });

            this.setState({ loading: false });
            // this.getAllPlacedOrder(r.data);
            this.getAllPlacedOrder(r.data);
          });
          // this.getAllPlacedOrder(r.data);
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    }

    if (internationalDocketList.length > 0) {
      this.setState({ loading: true });
      generateEDocketInternational(internationalIds)
        .then((r) => {
          // console.log(r);
          dispatch({
            type: "getPlacedOrderList",
            newStore: r.data,
          });
          getDockets().then((r) => {
            dispatch({
              type: "getPlacedOrderList",
              newStore: r.data,
            });

            this.setState({ loading: false });
            // this.getAllPlacedOrder(r.data);
            this.getAllPlacedOrder(r.data);
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    }
    // }
  };
  handleReportClick = () => {
    if (this.state.selectedDocket.isLocal) {
      this.setState({
        showPopupInternational: false,
        showPopupLocal: true,
      });
    } else {
      this.setState({
        showPopupInternational: true,
        showPopupLocal: false,
      });
    }
  };

  handleHidePopup = () => {
    this.setState({
      showPopupLocal: false,
      showPopupInternational: false,
    });
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true, page: 0 });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  onSearchClicked = (reqBody) => {
    let loggedInId = localStorage.getItem("loggedInId");

    serchCustomerOrder(reqBody).then((r) => {
      this.getAllPlacedOrder(r.data);
      this.exportDeliveryNotes(r.data);
    });
    this.setState({ searchClicked: true });
  };
  clearSearch = () => {
    const [{ store }, dispatch] = this.context;
    getDockets().then((r) => {
      this.exportDeliveryNotes(r.data);
      this.getAllPlacedOrder(r.data);
    });
    this.setState({
      loading: true,
      searchClicked: false,
      searchPopupOpen: false,
    });
  };
  handleEdsErrorModal = (e, id) => {
    getEdsErrorDetails(id).then(r => {
      this.setState({ edsErrorData: r })
    })
    this.setState({ openEdsErrorModal: true })
  }
  handleEdsErrorModalClose = () => {
    this.setState({ openEdsErrorModal: false })
  }
  render() {
    const [{ store }, dispatch] = this.context;
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      rows,
      docketHeader,
      docketHeaderID,
      headCells,
      exportDataValues,
    } = this.state;
    //console.log(makeStatus("Completed"));
    const isSelected = (id) => selected.indexOf(id) !== -1;
    // console.log(rows);
    return (
      <div>
        { this.state.openEdsErrorModal && <EdsErrorModal open={this.state.openEdsErrorModal}
          handleEdsErrorModalClose={this.handleEdsErrorModalClose} data={this.state.edsErrorData} />}
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            docketHeader={docketHeader}
            docketHeaderID={docketHeaderID}
            history={this.props.history}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchCountry={this.handleSearchCountry}
            countrySearchValue={this.state.countrySearchValue}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteCountry={this.handleDeleteCountry}
            handleViewColumns={this.handleViewColumns}
            headCells={headCells}
            docketPostingList={rows}
            selected={selected}
            onSearchClicked={this.onSearchClicked}
            editable={this.state.editable}
            handleSearchItemMaster={this.handleSearchItemMaster}
            handleSubmitEDocket={this.handleSubmitEDocket}
            handleReportClick={this.handleReportClick}
            isOpen={this.state.isOpen}
            roles={this.props.roles}
            exportDataValues={exportDataValues}
            selectedDocket={this.state.selectedDocket}
            gedBtnChk={this.state.gedBtnChk}
          />
          {this.state.showPopupLocal && (
            <ReportViewer
              url={
                `${window.localStorage.ReportURLByID}` +
                "Posting Docket Local&IsDirectPrint=True&ReportParam=DocketHeaderID&Param1=" +
                `${this.state.selected && this.state.selected[0]}`
              }
              showPopup={this.state.showPopupLocal}
              onClose={() => this.handleHidePopup()}
              title={"Posting Docket Local"}
              handleHidePopup={() => this.handleHidePopup()}
            />
          )}
          {this.state.showPopupInternational && (
            <ReportViewer
              url={
                `${window.localStorage.ReportURLByID}` +
                "PostingDocket(International)&IsDirectPrint=True&ReportParam=DocketHeaderID&Param1=" +
                `${this.state.selected && this.state.selected[0]}`
              }
              showPopup={this.state.showPopupInternational}
              onClose={() => this.handleHidePopup()}
              title={"PostingDocket(International)"}
              handleHidePopup={() => this.handleHidePopup()}
            />
          )}
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 340px)",
                    maxHeight: "calc(100vh - 225px)",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={e => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={rows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {rows.length > 0 && !this.state.loading ? (
                    stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.docketHeaderID);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive == true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }

                        return (
                          <TableRow
                            // hover={row.statusCode === "DCCAN" ? false : true}
                            // onClick={
                            //   row.statusCode === "DCCAN"
                            //     ? false
                            //     : (event) => this.handleClick(event, row)
                            // }
                            className="doc-bag-tab-row"
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={
                              row.statusCode === "DCCAN"
                                ? false
                                : isItemSelected
                            }
                            style={
                              row.statusCode === "DCCAN"
                                ? { backgroundColor: "#EAEAEA" }
                                : {}
                            }
                          >
                            {/* // row.statusCode === "DCCAN" ? true : false */}
                            <TableCell padding="checkbox"
                              style={{ borderTop: "1px solid #eaeaea " }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      row.statusCode === "DCCAN"
                                        ? false
                                        : isItemSelected
                                    }
                                    onClick={
                                      row.statusCode === "DCCAN"
                                        ? false
                                        : (event) => this.handleClick(event, row)
                                      // this.handleClick(
                                      //   event,
                                      //   row.customerName,
                                      //   row,
                                      //   row.docketHeaderID
                                      // )
                                    }
                                    disabled={
                                      row.statusCode === "DCCAN" ? true : false
                                    }
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.customerName &&
                                  row.customerName.length > 16 ? (
                                    <Tooltip title={row.customerName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.customerName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.workOrderNumber &&
                                  row.workOrderNumber.length > 16 ? (
                                    <Tooltip title={row.workOrderNumber}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.workOrderNumber}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.workOrderNumber}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.projectTitle &&
                                  row.projectTitle.length > 16 ? (
                                    <Tooltip title={row.projectTitle}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.projectTitle}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.projectTitle}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.jobGroupNumber &&
                                  row.jobGroupNumber.length > 16 ? (
                                    <Tooltip title={row.jobGroupNumber}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.jobGroupNumber}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.jobGroupNumber}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell-eds-error"
                                style={{ minWidth: "180px", }}
                                onClick={e => this.handleEdsErrorModal(e, row.docketHeaderID)}
                              >
                                {row.statusName == "Open" ? (
                                  <StatusText color={"#16831F"}>
                                    Open
                                  </StatusText>
                                ) : row.statusName == "In Progress" ? (
                                  <StatusText color={"#0091ff"}>
                                    In Progress
                                  </StatusText>
                                ) : row.statusName == "E Docket Generated" ? (
                                  <StatusText color={"#3960b4"}>
                                    E-Docket Generated
                                  </StatusText>
                                ) : row.statusName ==
                                  "Manual Docket Generated" ? (
                                          <StatusText color={"#f5a623"}>
                                            Manual Docket Generated
                                          </StatusText>
                                        ) : row.statusName == "Cancelled" ? (
                                          <StatusText color={"red"}>
                                            Cancelled
                                          </StatusText>
                                        ) : (
                                            <StatusText color={"grey"}>
                                              {row.statusName}
                                            </StatusText>
                                          )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.edsExceptionMessage &&
                                  row.edsExceptionMessage.length > 16 ? (
                                    <Tooltip title={row.edsExceptionMessage}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.edsExceptionMessage}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.edsExceptionMessage}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.eDocketNO && row.eDocketNO.length > 16 ? (
                                  <Tooltip title={row.eDocketNO}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.eDocketNO}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.eDocketNO}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {/* {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.accountNo && row.accountNo.length > 16 ? (
                                  <Tooltip title={row.accountNo}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.accountNo}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.accountNo}
                                    </Typography>
                                  )}
                              </TableCell>
                            )} */}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.estimatedLodgementDate &&
                                  row.estimatedLodgementDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(
                                        row.estimatedLodgementDate
                                      ).format("DD/MM/YYYY")}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(
                                          row.estimatedLodgementDate
                                        ).format("DD/MM/YYYY")}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.estimatedLodgementDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.submittedDate &&
                                  row.submittedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.submittedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.submittedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.submittedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.serviceType &&
                                  row.serviceType.length > 16 ? (
                                    <Tooltip title={row.serviceType}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.serviceType}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.serviceType}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {/* {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                style={{ minWidth: "150px" }}
                              >
                    
                                {row.planner && row.planner.length > 16 ? (
                                  <Tooltip title={row.planner}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.planner}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.planner}
                                    </Typography>
                                  )}

                              </TableCell>
                            )} */}

                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >

                                {row.pgsDocketNO &&
                                  row.pgsDocketNO.length > 16 ? (
                                    <Tooltip title={row.pgsDocketNO}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.pgsDocketNO}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.pgsDocketNO}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap>
                                  {row.modifiedByName}
                                </Typography>
                              </TableCell>
                            )}

                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap>
                                  {row.createdByName}
                                </Typography>
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={10} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(DocketPosting);
