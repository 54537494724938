import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withRouter } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import ReportViewer from "../../app/ReportViewer";
import { Autocomplete } from "@material-ui/lab";
import toastr from "toastr";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import clone from "clone";
import Dialog from "@material-ui/core/Dialog";
import Checkbox from "@material-ui/core/Checkbox";
import QueueIcon from "@material-ui/icons/Queue";
import { StateContext } from "../../../shared/globalState";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { getUserList, getPlannerList } from "../../userAccess/userAccessAction";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  getCustomerDropdown,
  getPrintingProcess,
  getProcessTypes,
  getUOProcess,
  getUpdatedCountryDropdownList,
  getPlannerBasedOnCusList
} from "../../masters/masterActions";
import * as XLSX from "xlsx";
import {
  uploaduserOrderFilesEdit,
  submitUserOrder,
  submitGroupProcess,
  getParticularUserOrderEdit,
  getParticularGroupProcessView,
} from "../orderPlacement/actions";
import { TextField, Button, Paper, Divider } from "@material-ui/core";
import { addDispatchStyles } from "../../../shared/styles";
import Typography from "@material-ui/core/Typography";
import { EditIcon } from "../../../shared/customIcons";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import DeleteIcon from "@material-ui/icons/Delete";
import Validator from "../../../shared/validator";
import "../../app/App.css";
let orderGroupIID = "";
let autoCId = "";
let autoOGLId = "";
const notRequired100AllAllow = new Validator("notRequired100AllAllow");
function createData(
  orderId,
  fileName,
  country,
  noOfCopies,
  quantity,
  remark,
  routing,
  exception,
  id
) {
  return {
    orderId,
    fileName,
    country,
    noOfCopies,
    quantity,
    remark,
    routing,
    exception,
    id,
  };
}
const headCells = [
  {
    id: "orderId",
    numeric: false,
    disablePadding: true,
    label: "Order Id",
  },
  {
    id: "fileName",
    numeric: false,
    disablePadding: true,
    label: "File Name",
  },
  {
    id: "country",
    numeric: false,
    disablePadding: false,
    label: "Country",
  },
  {
    id: "noOfCopies",
    numeric: false,
    disablePadding: false,
    label: "Copies",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "remark",
    numeric: false,
    disablePadding: false,
    label: "Remark",
  },
  {
    id: "routing",
    numeric: false,
    disablePadding: false,
    label: "Routing",
  },
  {
    id: "exception",
    numeric: false,
    disablePadding: false,
    label: "Exception",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];
const groupprocess = [
  {
    id: "print",
    numeric: false,
    disablePadding: false,
    label: "Print",
  },
  {
    id: "processName",
    numeric: false,
    disablePadding: false,
    label: "Process Name",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow style={{ background: "#eaeaea", }}>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderLeft: "1px sold #000", borderBottom: "2px solid #eaeaea" }}
          >
            {/* <span style={{ color: "#306dca" }}>{headCell.label}</span> */}
            <span style={{ color: "#306dca" }}>{headCell.label === "Country" || headCell.label === "Copies" || headCell.label === "Quantity" ? <span>{headCell.label}<span style={{ color: "red", marginLeft: "4px" }}>*</span></span> : headCell.label}</span>

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function EnhancedTableHeadProcess(props) {
  return (
    <TableHead>
      <TableRow style={{ background: "#eaeaea" }}>
        {groupprocess.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderLeft: "1px sold #000" }}
          >
            {/* <span style={{ color: "#306dca" }}>{headCell.label}</span> */}
            <span style={{ color: "#306dca" }}>
              {headCell.label === "Print" || headCell.label === "Process Name" || headCell.label === "Type" || headCell.label === "Quantity" ?
                <span>{headCell.label}
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span></span> : headCell.label}</span>

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderTop: "1px solid #eaeaea",
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { } = props;

  return (
    <div>
      <div
        style={{
          display: "flex",
          //   width: "100%",
          border: "1px solid #eaeaea",
          borderBottom: "none",
          borderRadius: "4px",
          margin: "0 .8em 0 .6em",
        }}
      >
        {props.list && props.list.length > 0 && props.disableAdd ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={(e) => props.handleClickButton(e, "add")}
          // style={
          //   !props.disableAdd
          //     ? {
          //         color: "#fff",
          //         background: "#3982ea",
          //         padding: "2px",
          //         margin: ".5em",
          //         fontSize: "11px",
          //       }
          //     : { color: "#3982ea", fontSize: "11px" }
          // }
          >
            <AddIcon style={{ fontSize: "18px" }} /> Add
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              onClick={(e) => props.handleClickButton(e, "add")}
            // style={
            //   !props.disableAdd
            //     ? {
            //         color: "#fff",
            //         background: "#3982ea",
            //       }
            //     : { color: "#3982ea", fontSize: "11px" }
            // }
            >
              <AddIcon style={{ fontSize: "18px" }} /> Add
            </Button>
          )}
        {props.resetDisable ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            // style={
            //   !props.disableEdit
            //     ? {
            //         color: "#fff",
            //         background: "#3982ea",
            //         padding: "2px",
            //         margin: ".5em",
            //         fontSize: "11px",
            //       }
            //     : { color: "#3982ea", fontSize: "11px" }
            // }
            onClick={(e) => props.handleClickButton(e, "reset")}
          >
            <SyncIcon style={{ fontSize: "18px" }} /> Reset
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              // style={
              //   !props.disableEdit
              //     ? {
              //         color: "#fff",
              //         background: "#3982ea",
              //       }
              //     : { color: "#3982ea", fontSize: "11px" }
              // }
              onClick={(e) => props.handleClickButton(e, "reset")}
            >
              <SyncIcon style={{ fontSize: "18px" }} /> Reset
            </Button>
          )}
      </div>
    </div>
  );
};
const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Typography>
  );
}
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontSize: "18px",
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    fontFamily: ["Roboto"].join(","),
    "&:hover": {
      opacity: 1,
    },
    "&$selected": {
      color: "#000",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      //   color: "#40a9ff"
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

class EditUserOrder extends React.Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }
  static contextType = StateContext;
  state = {
    indexGP: 0,
    indexClicked: 0,
    value: 0,
    list: [],
    editableList: [],
    fileName: "",
    disableAdd: false,
    disableEdit: false,
    disableAddGP: false,
    disableEditGP: false,
    plannerList: [],
    customerList: [],
    plannerId: "",
    plannerName: "",
    customerId: "",
    gpPrint: "",
    gpProcessName: "",
    gpType: "",
    gpQuantity: 0,
    gpRemark: "",
    OrderGroupID: 0,
    printingProcessList: [],
    processTypeList: [],
    uoProcessList: [],
    groupProcessEditClicked: false,
    loading: false,
    orderGroupCode: "",
    splInstruction: "",
    directory: "",
    orderTitle: "",
    totalQuantity: "",
    lcChecked: false,
    allowToAddFiles: false,
    allowFileUpoladMsgDialog: false,
    selectedEvent: "",
    UOID: "",
    inputFile: "",
    gpList: [],
    countryList: [],
    gpPrintName: "",
    gpProcessNameName: "",
    gpTypeName: "",
    showPopup: false,
    showPopup1: false,
    ordergroupid: 0,
    IsNewCountryBreakdown: false
  };
  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
    if (newValue === 0) {
      let orderId = "";
      if (process.browser) orderId = window.location.pathname.split("/")[2];

      getParticularUserOrderEdit(orderId).then((r) => {
        let newList = [];
        r.userOrderPlacementList &&
          r.userOrderPlacementList.length > 0 &&
          r.userOrderPlacementList.map((res) => {
            newList.push({
              fileName: res.name,
              country: res.country,
              noOfCopies: res.noOfCopies,
              quantity: res.noOfQuantity,
              remark: res.remraks,
              routing: res.routing,
              exception: res.exceptionMessage,
              OGCode: res.orderCode,
              canDelete: res.canDelete,
              ordergroupid: res.orderGroupID,
            });
          });

        this.setState({
          list: newList,
          editableList: newList,
          // disableAdd: true,
          disableEditGP: false,
          disableEdit: false,
          OrderGroupID: r.orderId,
          loading: false,
          totalQuantity: r.userOrderDetails && r.userOrderDetails.totalQuantity,
          orderGroupCode: r.orderGroupCode,
          plannerId: r.plannerID,
          plannerName: r.plannerName,
          customerId: r.customerID,
          splInstruction: r.specialInstruction,
          directory: r.dataServiceDirectoryName,
          orderTitle: r.orderTitle,
          // lcChecked: r.isLCVerified,
          lcChecked: false,
          fileName: r.fileName,
          UOID: r.orderGroupID,
          maxQty: 0,
        });
      });
    }
  };
  handleLC = (e) => {
    this.setState({ lcChecked: e.target.checked });
  };
  onEditClickedGP = (e, i) => {
    let newEditableList = clone(this.state.gpList);
    newEditableList[i].editClicked = !newEditableList[i].editClicked;
    this.setState({
      gpList: newEditableList,

      indexGP: i,
    });
  };
  onDeleteClickedGP = (e, i) => {
    let newList = clone(this.state.gpList);
    newList[i].editClicked = false;
    newList.splice(i, 1);
    this.setState({
      gpList: newList,
    });
  };
  componentDidMount() {
    const [{ store }, dispatch] = this.context;
    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    this.props.handleSettingHeader("User Order Placement");
    this.props.setSelectedList("genericSystemOpen", "genericSystem");
    this.props.handleDrawerClose();
    orderGroupIID = orderId;
    getPlannerList().then((r) => {
      dispatch({
        type: "getUserList",
        newStore: r.data,
      });
      this.getAllPlanners(r.data);
    });
    getCustomerDropdown().then((r) => {
      this.getAllCustomer(r.data);
    });
    getPrintingProcess().then((r) => {
      this.setState({ printingProcessList: r.data });
    });
    getProcessTypes().then((r) => {
      this.setState({ processTypeList: r.data });
    });
    getUOProcess().then((r) => {
      this.setState({ uoProcessList: r.data });
    });

    getParticularGroupProcessView(orderId).then((rr) => {
      let newGPL = [];
      rr.map((r) => {
        newGPL.push({
          gpPrint: r.printID,
          gpProcessName: r.processID,
          gpType: r.processTypeID,
          gpQuantity: r.processQuantity,
          gpRemark: r.remarks,
          editClicked: true,
          ptlObj: this.state.processTypeList.filter(
            (p) => p.id === r.processTypeID
          ),
        });
      });
      this.setState({ gpList: newGPL });
    });
    getParticularUserOrderEdit(orderId).then((r) => {
      let newList = [];
      r.userOrderPlacementList &&
        r.userOrderPlacementList.length > 0 &&
        r.userOrderPlacementList.map((res) => {
          newList.push({
            fileName: res.name,
            country: res.country,
            noOfCopies: res.noOfCopies,
            quantity: res.noOfQuantity,
            remark: res.remraks,
            routing: res.routing,
            exception: res.exceptionMessage,
            OGCode: res.orderCode,
            canDelete: res.canDelete,
            ordergroupid: res.orderGroupID,
          });
        });

      this.setState({
        list: newList,
        editableList: newList,
        // disableAdd: true,
        disableEditGP: false,
        disableEdit: false,
        OrderGroupID: r.orderId,
        loading: false,
        totalQuantity: r.userOrderDetails && r.userOrderDetails.totalQuantity,
        orderGroupCode: r.orderGroupCode,
        plannerId: r.plannerID,
        plannerName: r.plannerName,
        customerId: r.customerID,
        customer: { key: r.customerID, value: r.customer, name: r.customer },
        planner: { key: r.plannerID, value: r.plannerName, name: r.plannerName },
        customerName: r.customer,
        splInstruction: r.specialInstruction,
        directory: r.dataServiceDirectoryName,
        orderTitle: r.orderTitle,
        // lcChecked: r.isLCVerified,
        lcChecked: false,
        fileName: r.fileName,
        UOID: r.orderGroupID,
        maxQty: r.userOrderDetails && r.userOrderDetails.maxQuantity,
        resetDisable: true,
        resetDisableGP: true
      });
    });
    getUpdatedCountryDropdownList().then((r) => {
      this.getAllCountrys(r.data);
    });
  }
  getAllCountrys = (list) => {
    let countryList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        countryList.push({ key: c.id, value: c.text });
      });

    this.sortDDList(countryList, "countryList");
  };

  sortDDList = (list, name) => {
    let z = list.sort(function (a, b) {
      if (name === "customerList") {
        var x = a.name.toLowerCase();
        var y = b.name.toLowerCase();
      } else {
        var x = a.value.toLowerCase();
        var y = b.value.toLowerCase();
      }

      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ [name]: z });
  };
  getAllCustomer = (list) => {
    let customerList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        customerList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(customerList, "customerList");
  };
  getAllPlanners = (list) => {
    let plannerList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        // if (c.isActive)
        plannerList.push({ key: c.id, value: c.text, name: c.value });
      });
    this.sortDDList(plannerList, "plannerList");
  };
  checkOGDisability = () => {
    if (
      !this.state.customerId ||
      !this.state.orderTitle ||
      !this.state.plannerId

      //  ||
      //!this.state.splInstruction
      // !this.state.directory
    )
      return true;
    else return false;
  };
  checkGpDisability = () => {
    let notValid = false;
    let newList = clone(this.state.gpList);
    newList.map((n) => {
      if (
        !n.gpPrint ||
        !n.gpProcessName ||
        !n.gpType ||
        n.gpQuantity === "" ||
        !n.editClicked
      )
        notValid = true;
    });
    return notValid;
  };
  _onAlphaKeyPress(event) {
    const re = /[a-zA-Z0-9 ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onAlphaPlusKeyPress(event) {
    const re = /[a-zA-Z +()/{}]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleUpload = (e) => {
    //e.preventDefault();
    let files = e.target.files;
    var reader = new FileReader();
    let _error = "";
    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];

    if (files[0]) {
      this.setState({
        fileName: files[0].name,
        //disableAdd: true,
        disableEdit: false,
      });

      reader.onload = function (e) {
        let data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      };

      reader.readAsBinaryString(files[0]);

      let loggedInId = localStorage.getItem("loggedInId");
      const formData = new FormData();

      formData.append("UploadFiles", files[0]);
      formData.append("CustomerID", this.state.customerId);
      formData.append("OrderTitle", this.state.orderTitle);
      formData.append("SpecialInstruction", this.state.splInstruction);
      formData.append("OrderGroupID", this.state.UOID);
      formData.append("PlannerID", this.state.plannerId);
      formData.append("DataServiceDirectoryName", this.state.directory);
      formData.append("ModifiedBy", loggedInId);
      formData.append("IsNewCountryBreakdown", false);

      this.setState({ loading: true });
      uploaduserOrderFilesEdit(formData).then((r) => {
        let newList = [];
        // console.log(r)
        r.map((res) => {
          newList.push({
            fileName: res.fileName,
            country: res.countryName,
            noOfCopies: res.copies,
            quantity: res.quantity,
            remark: res.remraks,
            routing: res.rounting,
            exception: res.exceptionMessage,
            canDelete: true,
          });
        });
        this.setState({
          list: newList,
          editableList: newList,
          //disableAdd: true,
          disableEdit: false,
          // OrderGroupID: r.length > 0 && r[0].orderGroupID,
          loading: false,
          allowToAddFiles: false,

          resetDisable: false
        }, () => {
          if (document.getElementById("inputId"))
            document.getElementById("inputId").value = "";
        });
        if (r && r.length > 0) {
          if (!r[0].cbdStagingID) toastr.error(r[0].message);
          // this.setState({ list: [] })
        }
        //  applicable for next bulid........................................................................
        if (r && r.length === 1) {
          // if (!r[0].isvalid && !r[0].cbdStagingID)
          //   this.setState({ list: [] })
          let check = false
          if (r[0].isvalid || r[0].isValid)
            check = true
          if (!check && !r[0].cbdStagingID)
            this.setState({ list: [] })
        }
      });
    } else {
      this.setState({ fileName: "", allowToAddFiles: false });
    }
  };
  checkOGSubmit = () => {
    // console.log(this.state.list)
    let notValid = false;
    if (this.state.list.length === 0) return true;
    else;
    {
      this.state.list.map((l) => {
        if (l.exception) notValid = true;
      });
      this.state.list.map((l) => {
        if (
          // !l.fileName ||
          !l.country ||
          !l.noOfCopies ||
          !l.quantity ||
          l.editClicked
          // !l.routing
        )
          notValid = true;
      });
      return notValid;
    }
  };
  handleClickButton = (e, name) => {
    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    let newList = clone(this.state.list);
    let newObj = {
      fileName: "",
      country: "",
      noOfCopies: "",
      quantity: "",
      remark: "",
      routing: "",
      exception: "",
      canDelete: true,
      editClicked: true,
    };
    newList.push(newObj);
    if (name === "add") {
      this.setState(
        {
          list: newList,
          editableList: newList,
          // disableAdd: true,
          disableEdit: false,
          // OrderGroupID: 0
          indexClicked: newList.length - 1,
          resetDisable: false,

        },
        () => {
          document.getElementById(`tableRow${newList.length - 1}`).focus();
        }
      );
    } else {
      getParticularUserOrderEdit(orderId).then((r) => {
        let newList = [];
        r.userOrderPlacementList &&
          r.userOrderPlacementList.length > 0 &&
          r.userOrderPlacementList.map((res) => {
            newList.push({
              fileName: res.name,
              country: res.country,
              noOfCopies: res.noOfCopies,
              quantity: res.noOfQuantity,
              remark: res.remraks,
              routing: res.routing,
              exception: res.exceptionMessage,
              OGCode: res.orderCode,
              canDelete: res.canDelete,
              ordergroupid: res.orderGroupID,
            });
          });

        this.setState({
          list: newList,
          editableList: newList,
          // disableAdd: true,
          disableEditGP: false,
          disableEdit: false,
          OrderGroupID: r.orderId,
          loading: false,
          totalQuantity: r.userOrderDetails && r.userOrderDetails.totalQuantity,
          orderGroupCode: r.orderGroupCode,
          plannerId: r.plannerID,
          plannerName: r.plannerName,
          customerId: r.customerID,
          customerName: r.customer,
          splInstruction: r.specialInstruction,
          directory: r.dataServiceDirectoryName,
          orderTitle: r.orderTitle,
          // lcChecked: r.isLCVerified,
          resetDisable: true,
          lcChecked: false,
          fileName: r.fileName,
          UOID: r.orderGroupID,
          maxQty: r.userOrderDetails && r.userOrderDetails.maxQuantity,
        });
      });
    }
  };
  handleGPClickButton = (e, name) => {
    let newList = clone(this.state.gpList);

    if (name === "add") {
      let newObj = {
        gpPrint: "",
        gpProcessName: "",
        gpType: "",
        gpQuantity: 0,
        gpRemark: "",
        editClickedGP: true,


      };
      newList.push(newObj);
      this.setState({
        gpList: newList,
        // disableAddGP: true,
        disableEditGP: false,
        indexGP: newList.length - 1,
        resetDisableGP: false
      });
    } else if (name === "clone") {
      if (newList.length > 0) {
        // console.log(newList[newList.length-1])
        let newObj = {
          gpPrint: newList[newList.length - 1].gpPrint,
          gpProcessName: newList[newList.length - 1].gpProcessName,
          gpType: newList[newList.length - 1].gpType,
          gpQuantity: 0,
          gpRemark: "",
          editClickedGP: true,


        };
        newList.push(newObj);
      }
      this.setState({
        gpList: newList,
        // disableAddGP: true,
        // disableEditGP: false,
        indexGP: newList.length - 1,
        resetDisableGP: false
      });
    } else {
      let orderId = "";
      if (process.browser) orderId = window.location.pathname.split("/")[2];
      getParticularGroupProcessView(orderId).then((rr) => {
        let newGPL = [];
        rr.map((r) => {
          newGPL.push({
            gpPrint: r.printID,
            gpProcessName: r.processID,
            gpType: r.processTypeID,
            gpQuantity: r.processQuantity,
            gpRemark: r.remarks,
            editClicked: true,
            ptlObj: this.state.processTypeList.filter(
              (p) => p.id === r.processTypeID
            ),

          });
        });

        this.setState({ gpList: newGPL, resetDisableGP: true });
      });
    }
  };
  onEditClicked = (e, i) => {
    let newEditableList = clone(this.state.list);
    newEditableList[i].editClicked = !newEditableList[i].editClicked;
    this.setState({
      //  editableList: newEditableList,
      list: newEditableList,
      indexClicked: i,
    });
  };

  onDeleteClicked = (e, i) => {
    let newList = clone(this.state.list);
    newList[i].editClicked = false;
    newList.splice(i, 1);
    this.setState({
      // editableList: newEditableList,
      list: newList,
    });
  };
  onExcelDataChanged = (e, name, i) => {
    let newList = clone(this.state.list);
    newList[i][name] = e.target.value;
    if (
      name === "country" ||
      name === "noOfCopies" ||
      name === "quantity" ||
      name === "fileName"
    )
      newList[i].exception = "";
    this.setState({ list: newList });
  };
  handleInputChange = (e, validate) => {
    let newRrrors = { ...this.state.errors };
    let result = validate(e.target.value);
    newRrrors[e.target.name] = !result;
    this.setState({ [e.target.name]: e.target.value, errors: newRrrors });
  };

  handleChangeGPData1 = (e, values) => {
    let newGPL = clone(this.state.gpList);
    let targetValue = values && values.id;
    newGPL[autoCId].gpPrint = targetValue;
    this.setState({ gpList: newGPL });
  };
  handleChangeGPData2 = (e, values) => {
    let newGPL = clone(this.state.gpList);
    let targetValue = values && values.id;
    newGPL[autoCId].gpProcessName = targetValue;
    this.setState({ gpList: newGPL });
  };
  handleChangeGPData3 = (e, values) => {
    let newGPL = clone(this.state.gpList);
    let targetValue = values && values.id;
    newGPL[autoCId].gpType = targetValue;
    this.setState({ gpList: newGPL });
  };
  handleChangeGPDataNoneAutoC = (e, i) => {
    let newGPL = clone(this.state.gpList);
    newGPL[i][e.target.name] = e.target.value;
    this.setState({ gpList: newGPL });
  };
  handleAutoOpenClick = (id) => {
    autoCId = id;
  };
  handleAutoOGLOpenClick = (id) => {
    autoOGLId = id;
  };
  handleSubmit = (e) => {
    let reqBody = {};
    let list = [];
    let loggedInId = localStorage.getItem("loggedInId");
    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    reqBody.CustomerID = this.state.customerId;
    reqBody.OrderTitle = this.state.orderTitle;
    reqBody.SpecialInstruction = this.state.splInstruction;
    reqBody.PlannerID = this.state.plannerId;
    reqBody.DataServiceDirectoryName = this.state.directory;
    reqBody.ModifiedBy = loggedInId;
    reqBody.OrderGroupID = this.state.UOID;
    reqBody.IsLabelCounterConfirmed = false;
    reqBody.IsLCConfirmed = this.state.lcChecked;
    reqBody.UploadFiles = null;
    reqBody.fileName = this.state.fileName;
    reqBody.IsNewCountryBreakdown = this.state.IsNewCountryBreakdown
    this.state.list.map((r) => {
      list.push({
        fileName: r.fileName,
        countryName: r.country,
        copies: r.noOfCopies,
        quantity: r.quantity,
        remraks: r.remark,
        rounting: r.routing,
      });
    });
    reqBody.userOrderCountryBreakDownDetails = list;
    submitUserOrder(reqBody, this.props.history).then((r) => {
      if (r && r.orderCode) {
        toastr.success("User Order Updated Successfully");
        this.setState({
          orderSubmited: true,
          gpObj: r,
          value: 1,
          totalQuantity: r.totalQuantity,
          maxQty: r.maxQuantity,
        });
      } else {
        let newOGList = [];
        r.list.map((l) => {
          newOGList.push({
            fileName: l.fileName,
            country: l.countryName,
            noOfCopies: l.copies,
            quantity: l.quantity,
            remark: l.remraks,
            routing: l.rounting,
            exception: l.exceptionMessage,
            canDelete: true,
          });
        });
        this.setState({ list: newOGList });
      }
    });
    this.setState({ IsNewCountryBreakdown: false })
  };
  handleSubmitGP = (e) => {
    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    let reqBody = {};
    let loggedInId = localStorage.getItem("loggedInId");
    // reqBody.OrderGroupID = this.state.UOID;
    reqBody.OrderGroupID = orderId;
    let list = [];
    // reqBody.OrderProcessdetails = [];
    // reqBody.OrderProcessdetails.push({
    //   CBDProcessID: 0,
    //   PrintID: this.state.gpPrint,
    //   Processid: this.state.gpProcessName,
    //   Quantity: this.state.gpQuantity,
    //   ProcessTypeID: this.state.gpType,
    //   CreatedBy: loggedInId,
    //   Remarks: this.state.gpRemark
    // });
    let newListGP = [];
    this.state.gpList.map((r) => {
      newListGP.push({
        CBDProcessID: 0,
        PrintID: r.gpPrint,
        Processid: r.gpProcessName,
        Quantity: r.gpQuantity,
        ProcessTypeID: r.gpType,
        CreatedBy: loggedInId,
        Remarks: r.gpRemark,
      });
    });
    reqBody.OrderProcessdetails = newListGP;
    submitGroupProcess(
      reqBody,
      this.props.history,
      "Updated",
      orderId
    ).then((r) => { });
  };
  onFileDialogActionClicked = (e, res) => {
    if (res === "Yes")
      this.setState({ IsNewCountryBreakdown: true })
    else
      this.setState({ IsNewCountryBreakdown: false })
    if (res === "Yes") {
      let eve = e;
      this.setState({ allowToAddFiles: true }, () => {
        this.uploadClick(eve);
      });
    }
    this.setState({ allowFileUpoladMsgDialog: false });
  };

  onAllowFileUpload = (e, val) => {
    // this.setState({ allowFileUpoladMsgDialog: true });
    if (!this.state.allowToAddFiles && this.state.totalQuantity) {
      e.preventDefault();
      this.setState({ allowFileUpoladMsgDialog: true });
    } else return true;
  };
  uploadClick = (e) => {
    e.preventDefault();
    // this.setState({ allowFileUpoladMsgDialog: false });
    this.state.inputFile.click();
    return false;
  };

  handleCountryChange = (event, value) => {
    if (value) {
      let newList = clone(this.state.list);
      let val = value.value;
      let key = value.key;
      value = val.split(" - ")[1];
      newList[autoOGLId]["country"] = value;
      newList[autoOGLId]["countryDuplicate"] = { value };
      newList[autoOGLId]["countryID"] = key;
      newList[autoOGLId]["exception"] = "";
      this.setState({
        list: newList,
      });
    }
  };
  handleShowPopup = (event, name) => {
    if (name == "routingForRemarks") {
    }
    this.setState({
      showPopup: true,
    });
  };

  handleHidePopup = (event, name) => {
    if (name == "routingForRemarks") {
    }
    this.setState({
      showPopup: false,
    });
  };

  handleShowPopup1 = (event, name) => {
    if (name == "routingForRemarks") {
    }
    this.setState({
      showPopup1: true,
    });
  };

  handleHidePopup1 = (event, name) => {
    if (name == "routingForRemarks") {
    }
    this.setState({
      showPopup1: false,
    });
  };
  handleAutocompleteCustomer = (e, val) => {
    // console.log(val)
    this.setState({
      customerId: val && val.key,
      customer: val && val,
      plannerId: "",
      planner: null,
    });
    if (val)
      getPlannerBasedOnCusList(val.key).then((r) => {
        if (r && r.data && r.data.length > 0)
          r.data.map((l) => {
            if (l.isSelected) {
              this.setState({
                plannerId: l.id,
                planner: { key: l.id, value: l.text, name: l.text },
              });
            }
          });
      });
  };
  handleAutocompletePlanner = (e, val) => {
    this.setState({ plannerId: val && val.key, planner: val && val });
  };
  openFileDialog = (e) => { };
  render() {
    // console.log(this.state.planner, this.state.plannerList);
    let {
      value,
      list,
      fileName,
      editableList,
      plannerName,
      customerId,
      printName,
      ProcessNameName,
      typeName,
    } = this.state;

    let { classes } = this.props;
    // let inputFile = "";
    let lcVal = localStorage.getItem("departmentCode");
    let filteredPrintList =
      this.state.printingProcessList &&
      this.state.printingProcessList.map((val) => {
        return val;
      });

    let filteredUOList = this.state.uoProcessList.map((val) => {
      return val;
    });

    let filteredTypeList = this.state.processTypeList.length > 0 && this.state.processTypeList.map((val) => {
      return val;
    });

    let particluarPlanner = this.state.plannerList.reduce(
      (a, o) => (o.key == this.state.plannerId && a.push(o), a),
      []
    );

    return (
      <React.Fragment>
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.allowFileUpoladMsgDialog}
        >
          <div style={{ padding: "4em" }}>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>
              {" "}
              Are you sure you want to "Overwrite" ?
            </div>
            <div style={{ fontSize: "13px" }}>
              The file will be replaced immediately. You Can't Undo this Action{" "}
            </div>
            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => this.onFileDialogActionClicked(e, "no")}
                style={{ marginRight: "1em", boxShadow: "none" }}
              >
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.onFileDialogActionClicked(e, "Yes")}
              >
                Yes
              </Button>
            </div>
          </div>
        </Dialog>
        <Paper>
          <div style={{ flexGrow: "1", padding: ".5em 2em 1em 2em" }}>
            <div style={{ background: "#fff" }}>
              <AntTabs
                value={value}
                onChange={this.handleChange}
                aria-label="ant example"
              >
                <AntTab label="Order Group" />
                <AntTab label="Group Process" />
              </AntTabs>
              <TabPanel value={value} index={0}>
                <React.Fragment>
                  <div>
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1 }}>
                        <TextField
                          required
                          label="Order Group Id"
                          name="orderGroupCode"
                          inputProps={{
                            maxLength: 100,
                          }}
                          InputProps={{ readOnly: true }}
                          variant={"filled"}
                          //onKeyPress={this._onNumberKeyPress}
                          style={{
                            width: "93%",
                            fontSize: "11px !important",
                            marginLeft: ".7em",
                            marginRight: ".5em",
                            maxHeight: "400px",
                            overflow: "auto",
                          }}
                          margin="normal"
                          className={classes.textFieldSmall}
                          value={this.state.orderGroupCode}
                          onChange={(e) =>
                            this.handleInputChange(e, notRequired100AllAllow)
                          }
                        />
                      </div>
                      {/* <TextField
                      required
                      label="Customer"
                      InputProps={{ readOnly: true }}
                      variant={"filled"}
                      //className={classes.textField}
                      name="customerId"
                      style={{
                        width: "31%",
                        fontSize: "11px !important",
                        marginLeft: ".5em",
                        marginRight: ".5em",
                        maxHeight: "400px",
                        overflow: "auto",
                      }}
                      inputProps={{
                        maxLength: 100,
                      }}
                      margin="normal"
                      value={
                        this.state.customerName ? this.state.customerName : ""
                      }
                    ></TextField> */}
                      <div style={{ flex: 1 }}>
                        <Autocomplete
                          //autoFocus
                          options={this.state.customerList}
                          value={this.state.customer ? this.state.customer : {}}
                          onChange={this.handleAutocompleteCustomer}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // autoFocus
                              InputProps={{ readOnly: true }}
                              variant={"filled"}
                              label="Customer"
                              style={{
                                width: "93%",
                                fontSize: "11px !important",
                                marginLeft: ".5em",
                                marginRight: ".5em",
                                maxHeight: "400px",
                              }}
                              // name="plannerId"
                              margin="normal"
                              required
                            />
                          )}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <TextField
                          inputProps={{
                            maxLength: 100,
                          }}
                          required
                          // InputProps={{ readOnly: true }}
                          // variant={"filled"}
                          //onKeyPress={this._onAlphaKeyPress}
                          style={{
                            width: "93%",
                            fontSize: "11px !important",
                            marginLeft: ".5em",
                            marginRight: ".5em",
                            maxHeight: "400px",
                            overflow: "auto",
                          }}
                          label="Order Title"
                          name="orderTitle"
                          margin="normal"
                          className={classes.textFieldSmall}
                          value={this.state.orderTitle}
                          onChange={(e) =>
                            this.handleInputChange(e, notRequired100AllAllow)
                          }
                        />
                      </div>
                    </div>
                    <div style={{
                      display: "flex",
                      // alignItems: "flex-end"
                    }}>
                      {this.state.plannerList &&
                        this.state.plannerList.length > 0 && (
                          <div style={{ flex: 1 }}>
                            {/* <TextField
                              required
                              label="Planner"
                              InputProps={{ readOnly: true }}
                              variant={"filled"}
                              //className={classes.textField}
                              name="plannerName"
                              style={{
                                width: "93%",
                                fontSize: "11px !important",
                                marginLeft: ".5em",
                                marginRight: ".5em",
                                maxHeight: "400px",
                                overflow: "auto",
                              }}
                              inputProps={{
                                maxLength: 100,
                              }}
                              margin="normal"
                              value={particluarPlanner.map((val) => {
                                return val.value;
                              })}
                            ></TextField> */}
                            <Autocomplete
                              options={this.state.plannerList}
                              value={this.state.planner ? this.state.planner : {}}

                              onChange={this.handleAutocompletePlanner}
                              getOptionLabel={(option) => option.value}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputProps={{ readOnly: true }}
                                  variant={"filled"}
                                  label="Planner"
                                  style={{
                                    width: "93%",
                                    fontSize: "11px !important",
                                    marginLeft: ".5em",
                                    marginRight: ".5em",
                                    maxHeight: "400px",
                                  }}
                                  // name="plannerId"
                                  margin="normal"
                                  required
                                />
                              )}
                            />
                          </div>
                        )}
                      <div style={{ flex: 1 }}>
                        <TextField
                          //required
                          label="Special Instruction"
                          name="splInstruction"
                          inputProps={{
                            maxLength: 100,
                          }}
                          multiline
                          rows={1}
                          rowsMax={4}
                          // InputProps={{ readOnly: true }}
                          // variant={"filled"}
                          //onKeyPress={this._onNumberKeyPress}
                          style={{
                            width: "93%",
                            fontSize: "11px !important",
                            marginLeft: ".7em",
                            marginRight: ".5em",
                            maxHeight: "400px",
                            overflow: "auto",
                            // marginTop: "-1px"
                          }}
                          margin="normal"
                          className={classes.textFieldSmall}
                          value={this.state.splInstruction}
                          onChange={(e) =>
                            this.handleInputChange(e, notRequired100AllAllow)
                          }
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <TextField
                          // required
                          label="Directory Name "
                          name="directory"
                          inputProps={{
                            maxLength: 50,
                          }}
                          style={{
                            width: "93%",
                            fontSize: "11px !important",
                            marginLeft: ".5em",
                            marginRight: ".5em",
                            maxHeight: "400px",
                            overflow: "auto",
                          }}
                          //onKeyPress={this._onKeyPress}
                          margin="normal"
                          className={classes.textFieldSmall}
                          value={this.state.directory}
                          onChange={(e) =>
                            this.handleInputChange(e, notRequired100AllAllow)
                          }
                        />
                      </div>

                    </div>

                    <div
                      className="view-page-contents-sub-container"
                      style={{ marginLeft: "6px" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!this.props.location.state.approve}
                            name="lcChecked"
                            checked={this.state.lcChecked}
                            onChange={(e) => this.handleLC(e)}
                            value={this.state.lcChecked}
                            color="primary"
                          />
                        }
                        label="LC Verified"
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      width: "auto",
                      border: "2px dashed #eaeaea",
                      margin: "1em .8em 1em .6em",
                      borderRadius: "4px",
                    }}
                  >
                    <label
                      className="upload excel"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        minHeight: "40px",
                        // cursor: "pointer",
                        padding: ".3em",
                      }}
                    >
                      <input
                        disabled={this.checkOGDisability()}
                        type="file"
                        id="inputId"
                        // ref={this.fileInput}
                        // ref={input => (this.inputElement = input)}
                        ref={(input) => {
                          this.state.inputFile = input;
                          //this.setState({ inputFile: input });
                        }}
                        // id="customInputId"
                        style={{ display: "none" }}
                        onChange={(e) => this.handleUpload(e)}
                        onClick={(e) => this.onAllowFileUpload(e, "first")}
                        accept=".xlsx"
                      />
                      <div
                        style={
                          this.checkOGDisability()
                            ? {
                              backgroundColor: "#cccccc",
                              color: "#515151",
                              borderRadius: "4px",
                              padding: ".3em 1em .3em 1em",
                            }
                            : {
                              backgroundColor: "#3982ea",
                              color: "#fff",
                              borderRadius: "4px",
                              padding: ".3em 1em .3em 1em",
                              cursor: "pointer",
                            }
                        }
                      >
                        CHOOSE FILE
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          width: "auto",
                          marginLeft: "12px",
                          width: "320px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "500",
                            fontSize: "13px",
                          }}
                        >
                          {this.checkOGDisability()
                            ? "Please fill all the required fields before selecting a file"
                            : fileName
                              ? fileName
                              : "No file choosen"}
                        </div>
                        <div className="file-upload-footer">
                          {this.checkOGDisability()
                            ? ""
                            : "Allowed only “.xlsx” format, 500KB file limit."}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div>
                    <EnhancedTableToolbar
                      //numSelected={selected.length}
                      handleClickButton={this.handleClickButton}
                      disableAdd={
                        this.checkOGSubmit() || this.checkOGDisability()
                      }
                      disableEdit={this.state.disableEdit}
                      list={this.state.list}
                      resetDisable={this.state.resetDisable}
                      resetDisableGP={this.state.resetDisableGP}
                    />
                  </div>
                  <div
                    style={{
                      // width: "100%",
                      maxHeight: "400px",
                      margin: "0 .8em 0 .6em",
                    }}
                  >
                    <div
                      // style={{ maxHeight: 350, overflow: "auto" }}
                      style={
                        !this.props.clearOpenList
                          ? {
                            overflow: "auto",
                            maxWidth: "calc(100vw - 190px)",
                            maxHeight: "350px",
                            // margin: "0 .8em 0 .6em"
                          }
                          : {
                            overflow: "auto",
                            maxWidth: "calc(100vw - 420px)",
                            maxHeight: "350px",
                            // margin: "0 .8em 0 .6em"
                          }
                      }
                    >
                      {/* //overflow: "auto" */}
                      <Table
                        aria-labelledby="tableTitle"
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <EnhancedTableHead />

                        {!this.state.loading ? (
                          <TableBody style={{ overflow: "hidden" }}>
                            {list.map((row, index) => {
                              const labelId = `enhanced-table-checkbox-${index}`;
                              return (
                                <TableRow
                                  id={`tableRow${index}`}
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.id}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={{ width: "168px", minWidth: "168px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.OGCode && row.OGCode}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={{ width: "300px", minWidth: "300px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.editClicked && !row.OGCode ? (
                                        <Input
                                          onKeyPress={this._onAlphaKeyPress}
                                          defaultValue={row.fileName}
                                          style={{ width: "100%" }}
                                          inputProps={{
                                            "aria-label": "description",
                                          }}
                                          onChange={(e) => {
                                            this.onExcelDataChanged(
                                              e,
                                              "fileName",
                                              index
                                            );
                                          }}
                                          value={row.fileName}
                                        />
                                      ) : (
                                          row.fileName
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={{ width: "180px", minWidth: "180px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.editClicked ? (
                                        <Autocomplete
                                          options={this.state.countryList}
                                          onOpen={(e) =>
                                            this.handleAutoOGLOpenClick(index)
                                          }
                                          onChange={this.handleCountryChange}
                                          value={
                                            row.editClicked
                                              ? Object(row.country)
                                              : row.countryDuplicate
                                          }
                                          getOptionLabel={(option) =>
                                            option.value || row.country
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              //required
                                              // label="GstType"
                                              margin="normal"
                                              style={{
                                                width: "100%",
                                                marginTop: "10px",
                                              }}
                                            />
                                          )}
                                        />
                                      ) : row.country &&
                                        row.country.length > 16 ? (
                                            <Tooltip
                                              title={row.country}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.country}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.country}
                                            </Typography>
                                          )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                    style={{ width: "100px", minWidth: "100px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.editClicked ? (
                                        <Input
                                          onKeyPress={this._onNumberKeyPress}
                                          defaultValue={row.noOfCopies}
                                          inputProps={{
                                            "aria-label": "description",
                                          }}
                                          onChange={(e) => {
                                            this.onExcelDataChanged(
                                              e,
                                              "noOfCopies",
                                              index
                                            );
                                          }}
                                          value={row.noOfCopies}
                                        />
                                      ) : (
                                          row.noOfCopies
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-right-cell"
                                    style={{ width: "100px", minWidth: "100px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.editClicked ? (
                                        <Input
                                          onKeyPress={this._onNumberKeyPress}
                                          defaultValue={row.quantity}
                                          inputProps={{
                                            "aria-label": "description",
                                          }}
                                          onChange={(e) => {
                                            this.onExcelDataChanged(
                                              e,
                                              "quantity",
                                              index
                                            );
                                          }}
                                          value={row.quantity}
                                        />
                                      ) : (
                                          row.quantity
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.editClicked ? (
                                        <Input
                                          // onKeyPress={this._onAlphaPlusKeyPress}
                                          defaultValue={row.remark}
                                          inputProps={{
                                            "aria-label": "description",
                                          }}
                                          onChange={(e) => {
                                            this.onExcelDataChanged(
                                              e,
                                              "remark",
                                              index
                                            );
                                          }}
                                          value={row.remark}
                                        />
                                      ) : (
                                          row.remark
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.editClicked ? (
                                        <Input
                                          onKeyPress={this._onAlphaKeyPress}
                                          defaultValue={row.routing}
                                          inputProps={{
                                            "aria-label": "description",
                                          }}
                                          onChange={(e) => {
                                            this.onExcelDataChanged(
                                              e,
                                              "routing",
                                              index
                                            );
                                          }}
                                          value={row.routing}
                                        />
                                      ) : (
                                          row.routing
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    {row.exception &&
                                      row.exception.length > 16 ? (
                                        <Tooltip
                                          title={row.exception}
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                              color: "red",
                                            }}
                                          >
                                            {row.exception}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                            color: "red",
                                          }}
                                        >
                                          {row.exception}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: "#6c6b6b",
                                        }}
                                        onClick={(e) =>
                                          this.onEditClicked(e, index)
                                        }
                                      >
                                        {!row.editClicked ? (
                                          <EditIcon
                                            style={{
                                              color: "#6c6b6b",
                                            }}
                                          />
                                        ) : (
                                            row.country && row.noOfCopies && row.quantity &&
                                            <CheckIcon
                                              style={{
                                                color: "#6c6b6b",
                                              }}
                                            />
                                          )}
                                      </span>

                                      {row.canDelete && (
                                        <DeleteIcon
                                          style={{
                                            cursor: "pointer",
                                            color: "#6c6b6b",
                                          }}
                                          onClick={(e) =>
                                            this.onDeleteClicked(e, index)
                                          }
                                        />
                                      )}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        ) : (
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan="10">
                                  <LinearProgress />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          )}

                        {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 25 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                      </Table>
                    </div>
                  </div>
                  <Divider style={{ marginTop: "25px" }} />
                  <div style={{ display: "flex" }} className="button-wrapper">
                    <div style={{}}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={(e) => {
                          this.props.history.push("/genericSystem");
                        }}
                      >
                        CANCEL
                      </Button>
                    </div>
                    <div style={{ marginLeft: "1em", marginRight: "1em" }}>
                      {this.checkOGSubmit() || this.checkOGDisability() ? (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={(e) => this.handleSubmit(e)}
                        >
                          Next
                        </Button>
                      ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                            onClick={(e) => this.handleSubmit(e)}
                          >
                            Next
                          </Button>
                        )}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        id="non-printable"
                        variant="contained"
                        color="primary"
                        style={{ marginRight: "1em" }}
                        onClick={(e) =>
                          this.handleShowPopup(e, "remarksForRouting")
                        }
                      >
                        CML Remarks By Routing
                      </Button>
                      <Button
                        id="non-printable"
                        variant="contained"
                        color="primary"
                        onClick={(e) =>
                          this.handleShowPopup1(e, "routingForRemarks")
                        }
                      >
                        CML Routing By Remarks
                      </Button>
                    </div>
                    {this.state.showPopup && (
                      <ReportViewer
                        url={
                          `${window.localStorage.ReportURLByID}` +
                          "BreakDownRemarksRouting&IsDirectPrint=False&ReportParam=OrderGroupID,FileName,CountryName&Param1=" +
                          `${orderGroupIID}` +
                          "&Param2=ALL&Param3=ALL"
                        }
                        showPopup={this.state.showPopup}
                        onClose={(e) =>
                          this.handleHidePopup(e, "remarksForRouting")
                        }
                        title={"Remarks By Routing"}
                        handleHidePopup={(e) =>
                          this.handleHidePopup(e, "remarksForRouting")
                        }
                      />
                    )}

                    {this.state.showPopup1 && (
                      <ReportViewer
                        url={
                          `${window.localStorage.ReportURLByID}` +
                          "BREAKDOWNROUTINGBYREMARKS&IsDirectPrint=False&ReportParam=OrderGroupID,FileName,CountryName&Param1=" +
                          `${orderGroupIID}` +
                          "&Param2=ALL&Param3=ALL"
                        }
                        showPopup={this.state.showPopup1}
                        onClose={(e) =>
                          this.handleHidePopup1(e, "routingForRemarks")
                        }
                        title={"Routing By Remarks"}
                        handleHidePopup={(e) =>
                          this.handleHidePopup1(e, "routingForRemarks")
                        }
                      />
                    )}
                  </div>
                </React.Fragment>
              </TabPanel>
              <TabPanel value={value} index={1}>
                {/* <div>
                  <EnhancedTableToolbar
                    //numSelected={selected.length}
                    handleClickButton={this.handleClickButton}
                    disableAdd={this.state.disableAdd}
                    disableEdit={this.state.disableEdit}
                  />
                </div> */}
                <React.Fragment>
                  {!this.state.UOID ? (
                    <div
                      style={{
                        width: "100%",
                        height: "73vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ fontSize: "18px", fontWeight: "500" }}>
                        No File Choosen Yet
                      </div>
                      <div style={{ fontSize: "12px" }}>
                        Upload .xlsx file related to the process on the "order
                        group page"
                      </div>
                    </div>
                  ) : (
                      <div>
                        <div
                          style={{
                            width: "100%",
                            marginTop: "1em",
                            overflow: "hidden",
                          }}
                        >
                          <Typography component="div" className="blueBox">
                            <div className="blueBoxLabel">
                              <div>Order Group ID: </div>
                              <div className="blueBoxText">
                                {this.state.orderGroupCode}
                              </div>
                            </div>
                            <div className="blueBoxLabel">
                              <div>Total Quantity: </div>
                              <div className="blueBoxText">
                                {this.state.totalQuantity}
                              </div>
                            </div>
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            //width: "100%",
                            border: "1px solid #eaeaea",
                            borderRadius: "4px",
                            margin: "0.8em 0 0 0",
                          }}
                        >
                          {this.state.disableAddGP ? (
                            <Button
                              disabled
                              variant="contained"
                              color="primary"
                              style={{
                                boxShadow: "none",
                                padding: "2px",
                                margin: ".5em",
                                fontSize: "11px",
                              }}
                              onClick={(e) => this.handleGPClickButton(e, "add")}
                            // style={
                            //   !this.state.disableAddGP
                            //     ? {
                            //         color: "#fff",
                            //         background: "#3982ea",
                            //       }
                            //     : { color: "#3982ea", fontSize: "11px" }
                            // }
                            >
                              <AddIcon style={{ fontSize: "18px" }} /> Add
                            </Button>
                          ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                style={{
                                  boxShadow: "none",
                                  padding: "2px",
                                  margin: ".5em",
                                  fontSize: "11px",
                                }}
                                disabled={this.checkGpDisability()}
                                onClick={(e) => this.handleGPClickButton(e, "add")}
                              // style={
                              //   !this.state.disableAddGP
                              //     ? {
                              //         color: "#fff",
                              //         background: "#3982ea",
                              //         padding: "2px",
                              //         margin: ".5em",
                              //         fontSize: "11px",
                              //       }
                              //     : { color: "#3982ea", fontSize: "11px" }
                              // }
                              >
                                <AddIcon style={{ fontSize: "18px" }} /> Add
                              </Button>
                            )}

                          <Button
                            // disabled={this.state.disableEditGP}
                            disabled={this.state.resetDisableGP}
                            variant="contained"
                            color="primary"
                            style={{
                              boxShadow: "none",
                              padding: "2px",
                              margin: ".5em",
                              fontSize: "11px",
                            }}
                            onClick={(e) => this.handleGPClickButton(e, "reset")}
                          >
                            <SyncIcon style={{ fontSize: "18px" }} /> Reset
                        </Button>

                          {this.state.gpList.length > 0 && (
                            <Button
                              disabled={this.checkGpDisability()}
                              variant="contained"
                              color="primary"
                              style={{
                                boxShadow: "none",
                                padding: "2px",
                                margin: ".5em",
                                fontSize: "11px",
                              }}
                              onClick={(e) =>
                                this.handleGPClickButton(e, "clone")
                              }
                            >
                              <QueueIcon style={{ fontSize: "18px" }} /> Clone
                            </Button>
                          )}
                        </div>
                        <div
                          style={{
                            // width: "100%",
                            maxHeight: "400px",
                          }}
                        >
                          <div
                            // style={{ maxHeight: 350, overflow: "auto" }}
                            style={
                              !this.props.clearOpenList
                                ? {
                                  overflow: "auto",
                                  maxWidth: "calc(100vw - 162px)",
                                  maxHeight: "350px",
                                  // margin: "0 .8em 0 .6em"
                                }
                                : {
                                  overflow: "auto",
                                  maxWidth: "calc(100vw - 390px)",
                                  maxHeight: "350px",
                                  // margin: "0 .8em 0 .6em"
                                }
                            }
                          >
                            <Table
                              aria-labelledby="tableTitle"
                              size="small"
                              stickyHeader
                              aria-label="sticky table"
                            >
                              <EnhancedTableHeadProcess />

                              {!this.state.loading ? (
                                <TableBody style={{ overflow: "hidden" }}>
                                  {this.state.gpList.length > 0 &&
                                    this.state.gpList.map((gpl, ind) => {
                                      let pPrint = this.state.printingProcessList.filter(
                                        (d) => {
                                          if (gpl.gpPrint === d.id)
                                            return {
                                              id: d.id,
                                              text: d.text,
                                              value: d.text,
                                            };
                                        }
                                      );
                                      let pType = this.state.processTypeList.filter(
                                        (d) => {
                                          if (gpl.gpType === d.id)
                                            return {
                                              id: d.id,
                                              text: d.text,
                                              value: d.text,
                                            };
                                        }
                                      );
                                      let pl = this.state.uoProcessList.filter(
                                        (d) => {
                                          if (gpl.gpProcessName === d.id)
                                            return {
                                              id: d.id,
                                              text: d.text,
                                              value: d.text,
                                            };
                                        }
                                      );
                                      // const labelId = `enhanced-table-checkbox-${index}`;
                                      return (
                                        <TableRow
                                          // id={`tableRow${index}`}
                                          role="checkbox"
                                          tabIndex={-1}
                                          key={ind}
                                        >
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            //id={labelId}
                                            // padding="default"
                                            // align="left"
                                            className="table-content-cell"
                                            style={{
                                              // paddingRight: "10px",
                                              width: "250px",
                                              minWidth: "250px"
                                            }}
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {!gpl.editClicked ? (
                                                <Autocomplete
                                                  autoComplete
                                                  includeInputInList
                                                  id="gpPrint"
                                                  name="gpPrint"
                                                  options={filteredPrintList}
                                                  className={classes.autoComplete}
                                                  // onChange={this.handleStatusChange}
                                                  onChange={
                                                    this.handleChangeGPData1
                                                  }
                                                  onOpen={(e) =>
                                                    this.handleAutoOpenClick(ind)
                                                  }
                                                  getOptionLabel={(option) =>
                                                    option.text
                                                  }
                                                  value={pPrint[0]}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      required
                                                      label=""
                                                      name="gpPrint"
                                                      id="gpPrint"
                                                      className={
                                                        classes.autocompleteText
                                                      }
                                                      style={{
                                                        width: "100%",
                                                      }}
                                                    // value={gpl.gpPrint}
                                                    />
                                                  )}
                                                />
                                              ) : (
                                                  this.state.printingProcessList.map(
                                                    (d) => {
                                                      if (gpl.gpPrint === d.id) {
                                                        if (d.value.length > 16) {
                                                          return (
                                                            <Tooltip
                                                              title={d.value}
                                                              aria-label="Print"
                                                            >
                                                              <Typography
                                                                noWrap
                                                                style={{
                                                                  fontSize: "14px",
                                                                }}
                                                              >
                                                                {d.value}
                                                              </Typography>
                                                            </Tooltip>
                                                            // <div>{d.value}</div>
                                                          );
                                                        } else {
                                                          return d.value;
                                                        }
                                                      }
                                                    }
                                                  )
                                                )}
                                            </Typography>
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            // id={labelId}
                                            padding="default"
                                            // align="left"
                                            className="table-content-cell"
                                            style={{
                                              // paddingRight: "10px",
                                              width: "450px",
                                              minWidth: "450px"
                                            }}
                                          >
                                            {" "}
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {" "}
                                              {!gpl.editClicked ? (
                                                // <TextField
                                                //   id="standard-select-currency"
                                                //   select
                                                //   name="gpProcessName"
                                                //   value={gpl.gpProcessName}
                                                //   onChange={e =>
                                                //     this.handleChangeGPData(e, ind)
                                                //   }
                                                //   style={{
                                                //     fontSize: "12px",
                                                //     width: "190px"
                                                //   }}
                                                // >
                                                //   {this.state.uoProcessList.map(
                                                //     option => (
                                                //       <MenuItem
                                                //         key={option.id}
                                                //         value={option.id}
                                                //       >
                                                //         {option.value}
                                                //       </MenuItem>
                                                //     )
                                                //   )}
                                                // </TextField>
                                                <Autocomplete
                                                  autoComplete
                                                  includeInputInList
                                                  id="processName-0"
                                                  name="gpProcessName"
                                                  options={filteredUOList}
                                                  className={classes.autoComplete}
                                                  // onChange={this.handleStatusChange}
                                                  onChange={
                                                    this.handleChangeGPData2
                                                  }
                                                  onOpen={(e) =>
                                                    this.handleAutoOpenClick(ind)
                                                  }
                                                  getOptionLabel={(option) =>
                                                    option.text
                                                  }
                                                  value={pl[0]}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      required
                                                      label=""
                                                      name="gpProcessName"
                                                      id="gpProcessName"
                                                      className={
                                                        classes.autocompleteText
                                                      }
                                                      style={{
                                                        width: "100%",
                                                      }}
                                                    // value={gpl.gpPrint}
                                                    //value={}
                                                    />
                                                  )}
                                                />
                                              ) : (
                                                  this.state.uoProcessList.map(
                                                    (d) => {
                                                      if (
                                                        gpl.gpProcessName === d.id
                                                      ) {
                                                        if (d.value.length > 16) {
                                                          return (
                                                            <Tooltip
                                                              title={d.value}
                                                              aria-label="processName"
                                                            >
                                                              <Typography
                                                                noWrap
                                                                style={{
                                                                  fontSize: "14px",
                                                                }}
                                                              >
                                                                {d.value}
                                                              </Typography>
                                                            </Tooltip>
                                                          );
                                                        } else {
                                                          return d.value;
                                                        }
                                                      }
                                                    }
                                                  )
                                                )}
                                            </Typography>
                                          </TableCell>

                                          <TableCell
                                            component="th"
                                            scope="row"
                                            //id={labelId}
                                            // padding="default"
                                            // align="left"
                                            className="table-content-cell"

                                            style={{
                                              paddingRight: "7px",
                                              width: "230px",
                                              minWidth: "230px"
                                            }}
                                          >
                                            {" "}
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {" "}
                                              {!gpl.editClicked ? (
                                                // <TextField
                                                //   id="standard-select-currency"
                                                //   select
                                                //   name="gpType"
                                                //   value={gpl.gpType}
                                                //   onChange={e =>
                                                //     this.handleChangeGPData(e, ind)
                                                //   }
                                                //   style={{
                                                //     fontSize: "12px",
                                                //     width: "120px"
                                                //   }}
                                                // >
                                                //   {this.state.processTypeList.map(
                                                //     option => (
                                                //       <MenuItem
                                                //         key={option.id}
                                                //         value={option.id}
                                                //       >
                                                //         {option.text}
                                                //       </MenuItem>
                                                //     )
                                                //   )}
                                                // </TextField>
                                                <Autocomplete
                                                  autoComplete
                                                  includeInputInList
                                                  id="gpType"
                                                  name="gpType"
                                                  options={filteredTypeList}
                                                  // className={classes.autoComplete}
                                                  // onChange={this.handleStatusChange}
                                                  onChange={
                                                    this.handleChangeGPData3
                                                  }
                                                  onOpen={(e) =>
                                                    this.handleAutoOpenClick(ind)
                                                  }
                                                  getOptionLabel={(option) =>
                                                    option.text
                                                  }
                                                  style={{
                                                    width: "100%",
                                                  }}
                                                  value={pType[0]}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      required
                                                      label=""
                                                      name="gpType"
                                                      id="gpType"
                                                      className={
                                                        classes.autocompleteText
                                                      }
                                                      style={{
                                                        width: "100%",
                                                      }}
                                                    // value={gpl.gpPrint}
                                                    />
                                                  )}
                                                />
                                              ) : (
                                                  this.state.processTypeList.map(
                                                    (d) => {
                                                      if (gpl.gpType === d.id) {
                                                        if (d.text.length > 16) {
                                                          return (
                                                            <Tooltip
                                                              title={d.text}
                                                              aria-label="Type"
                                                            >
                                                              <Typography
                                                                noWrap
                                                                style={{
                                                                  fontSize: "14px",
                                                                }}
                                                              >
                                                                {d.text}
                                                              </Typography>
                                                            </Tooltip>
                                                            // <div>{d.value}</div>
                                                          );
                                                        } else {
                                                          return d.text;
                                                        }
                                                      }
                                                    }
                                                  )
                                                )}
                                            </Typography>
                                          </TableCell>

                                          <TableCell
                                            component="th"
                                            scope="row"
                                            // id={labelId}
                                            // padding="default"
                                            // align="left"
                                            className="table-content-right-cell"
                                            style={{
                                              width: "70px",
                                              minWidth: "70px",
                                              paddingRight: "6px",
                                            }}
                                          >
                                            {" "}
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {" "}
                                              {!gpl.editClicked ? (
                                                <Input
                                                  // defaultValue={row.remark}
                                                  onKeyPress={
                                                    this._onNumberKeyPress
                                                  }
                                                  inputProps={{
                                                    "aria-label": "description",
                                                  }}
                                                  inputProps={{
                                                    maxLength: 10,
                                                  }}
                                                  value={gpl.gpQuantity}
                                                  name="gpQuantity"
                                                  onChange={(e) =>
                                                    this.handleChangeGPDataNoneAutoC(
                                                      e,
                                                      ind
                                                    )
                                                  }
                                                />
                                              ) : (
                                                  gpl.gpQuantity
                                                )}
                                            </Typography>
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            // id={labelId}
                                            // padding="default"
                                            // align="left"
                                            className="table-content-cell"
                                            style={{
                                              width: "150px",
                                              minWidth: "150px",
                                              paddingRight: "6px",
                                            }}
                                          >
                                            {" "}
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {" "}
                                              {!gpl.editClicked ? (
                                                <Input
                                                  // defaultValue={row.remark}
                                                  // onKeyPress={
                                                  //   this._onAlphaKeyPress
                                                  // }
                                                  name="gpRemark"
                                                  inputProps={{
                                                    "aria-label": "description",
                                                  }}
                                                  inputProps={{
                                                    maxLength: 50,
                                                  }}
                                                  value={gpl.gpRemark}
                                                  onChange={(e) =>
                                                    this.handleChangeGPDataNoneAutoC(
                                                      e,
                                                      ind
                                                    )
                                                  }
                                                />
                                              ) : gpl.gpRemark.length > 16 ? (
                                                <Tooltip
                                                  title={gpl.gpRemark}
                                                  aria-label="Remarks"
                                                >
                                                  <Typography
                                                    noWrap
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    {gpl.gpRemark}
                                                  </Typography>
                                                </Tooltip>
                                              ) : (
                                                    // <div>{d.value}</div>
                                                    gpl.gpRemark
                                                  )}
                                            </Typography>
                                          </TableCell>

                                          <TableCell
                                            component="th"
                                            scope="row"
                                            // id={labelId}
                                            // padding="default"
                                            // align="left"
                                            className="table-content-cell"
                                            style={{
                                              width: "90px",
                                              minWidth: "90px",
                                              paddingRight: "6px",
                                            }}
                                          >
                                            <Typography
                                              noWrap
                                              style={{
                                                fontSize: "14px",
                                                paddingTop: "7px",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                                onClick={(e) =>
                                                  this.onEditClickedGP(e, ind)
                                                }
                                              >
                                                {gpl.editClicked ? (
                                                  <EditIcon
                                                    style={{
                                                      color: "#6c6b6b",
                                                    }}
                                                  />
                                                ) : (
                                                    <CheckIcon
                                                      style={{
                                                        color: "#6c6b6b",
                                                      }}
                                                    />
                                                  )}
                                              </span>

                                              <DeleteIcon
                                                onClick={(e) =>
                                                  this.onDeleteClickedGP(e, ind)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                              //  onClick={e => this.onDeleteClicked(e, index)}
                                              />
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                </TableBody>
                              ) : (
                                  <TableBody>
                                    <TableRow>
                                      <TableCell colSpan="10">
                                        <LinearProgress />
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                )}
                            </Table>
                          </div>
                        </div>
                      </div>
                    )}
                  <Divider style={{ marginTop: "25px" }} />
                  <div style={{ display: "flex" }} className="button-wrapper">
                    <div style={{}}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={(e) => {
                          this.props.history.push("/genericSystem");
                        }}
                      >
                        CANCEL
                      </Button>
                    </div>
                    <div style={{ marginLeft: "1em" }}>
                      {this.checkGpDisability() ? (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={(e) => this.handleSubmitGP(e)}
                        // className={
                        //   this.checkOGSubmit() || this.checkOGDisability()
                        //     ? "disabled-button"
                        //     : "cancel-button "
                        // }
                        >
                          Submit
                        </Button>
                      ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                            onClick={(e) => this.handleSubmitGP(e)}
                          >
                            Submit
                          </Button>
                        )}
                    </div>
                  </div>
                </React.Fragment>
              </TabPanel>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addDispatchStyles)(EditUserOrder));
