import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_PUBLISHER_RECIPIENT_LIST;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getBpaOrderAll = async (userID) => {
  console.log("userID", userID);
  let data = [];
  await pgsService
    .get(`/GetBPAOrderPlacementData/${userID}`)
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};
export const getBpaFileDownload = async (bpaId) => {
  console.log("userID", bpaId);
  let data = [];
  await pgsService
    .get(`/Download/${bpaId}`)
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};
export const getOrderRecepientList = async (userID) => {
  let data = [];
  try {
    let response = await pgsService.get(`/GetRecipientListDropdown/${userID}`);
    data = response;
    return data;
  } catch (e) { }
};

export const getDeliveryList = async () => {
  let data = [];
  try {
    let response = await pgsService.get("/GetDeliveryListDropDown");
    data = response;
    return data;
  } catch (e) { }
};

export const getStatusDropdownList = async () => {
  let data = [];
  try {
    let response = await pgsService.get("/GetBPAStatus");
    data = response;
    return data;
  } catch (e) { }
};
export const getOrderRecepientListbyID = async (BPARecipientID) => {
  let data = [];
  try {
    let response = await pgsService.get(
      `/GetBPAReceipientListData/${BPARecipientID}`
    );
    data = response;
    return data;
  } catch (e) { }
};

export const getAllData = async (BPAOrderHeaderID) => {
  let data = [];
  try {
    let response = await pgsService.get(
      `/GetBPAOrderDetailByID/${BPAOrderHeaderID}`
    );
    data = response;
    return data;
  } catch (e) { }
};

export const getDataInformation = async (BPAOrderHeaderID) => {
  let data = [];
  try {
    let response = await pgsService.get(
      `/GetBPAHeaderDataByID/${BPAOrderHeaderID}`
    );
    data = response;
    return data;
  } catch (e) { }
};

export const getOrderDetail = async (BPAOrderHeaderID) => {
  let data = [];
  try {
    let response = await pgsService.get(
      `/GetBPAOrderDetailByID/${BPAOrderHeaderID}`
    );
    data = response;
    return data;
  } catch (e) { }
};
export const getJournalQty = async (JournalID) => {
  let data = [];
  try {
    let response = await pgsService.get(`/GetJournalQty/${JournalID}`);
    data = response;
    return data;
  } catch (e) { }
};
export const serchBPAOrder = async (reqBody) => {
  let data = [];
  try {
    let response = await pgsService.post(
      "/SearchBPAOrderPlacementDetails/",
      reqBody
    );
    console.log(response);
    data = response;
    return data;
  } catch (e) { }
};

export const serchBPAOrderByID = async (BPAOrderHeaderID) => {
  let data = [];
  try {
    let response = await pgsService.get(
      `/GetBPAOrderDataForView/${BPAOrderHeaderID}`
    );
    data = response;
    return data;
  } catch (e) { }
};

export const deleteBPAOrder = async (BPAOrderHeaderID, ModifiedBy) => {
  await pgsService
    .delete(`/DeleteBPAOrderPlacement/${BPAOrderHeaderID}/${ModifiedBy}`)
    .then((r) => {
      toastr.success("Order Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const deleteJournalInsideBPA = async (BPAOrderHeaderID, ModifiedBy) => {
  await pgsService
    .delete(`/DeleteBPAOrderPlacementDetail/${BPAOrderHeaderID}/${ModifiedBy}`)
    .then((r) => {
      toastr.success("Journal Deleted Successfully");
    })
    .catch((e) => {

    });
};
export const updateBPAOrder = async (id, reqBody, history) => {
  let jimList = [];
  await pgsService
    .put("/UpdateBPAOrderPlacement/" + id, reqBody)
    .then((r) => {
      if (r.data.mode == true) {
        // toastr.success("Order Saved As Draft Successfully !");
        toastr.success("Order Submitted Successfully !");
        // history.push("/bpaOrderPlacement");
      } else {
        toastr.success("Order Saved Successfully !");
      }
    })
    .catch((e) => { });
};
export const fileUploadBPA = async (
  files,

  history
) => {
  let jimList = [];
  await pgsService
    .put(`/UpdateBPAFileUpload/`, files)
    .then((r) => {
      console.log(r);
      // toastr.success("Order Saved As Draft Successfully !");

      history.push("/bpaOrderPlacement");
    })
    .catch((e) => { });
};
export const submitBPAOrder = async (id, reqBody, history) => {
  let jimList = [];
  await pgsService
    .put("/UpdateBPAOrderPlacementSubmitted/" + id, reqBody)
    .then((r) => {
      console.log(r);
      toastr.success("Order Updated Successfully !");
      history.push("/bpaOrderPlacement");
    })
    .catch((e) => { });
};

export const postBPAOrder = async (reqBody, history) => {
  let jimList = [];
  await pgsService
    .post("/BPAOrderPlacementCreate", reqBody)
    .then((r) => {
      jimList = r;
    })
    .catch((e) => { });
  return jimList;
};

export const CreateBPADetail = async (id, Mode, reqBody, history) => {
  let jimList = [];
  await pgsService
    .post(`/CreateBPADetail/${id}/${Mode}`, reqBody)
    .then((r) => {
      toastr.success("BPA created successfully !");
      history.push("/bpaOrderPlacement");
    })
    .catch((e) => { });
};

//// bpa order processing

export const getBPAProcessList = async () => {
  let data = [];
  await pgsService
    .get("/GetBPAOrderPlacementSubmittedData")
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};

export const getBPAListforAdd = async () => {
  let jimList = [];
  await pgsService
    .get("/GetProcessedBPAData")
    .then((r) => {
      jimList = r;
    })
    .catch((e) => { });
  return jimList;
};

export const putBPAProcessList = async (reqBody, history) => {
  let jimList = [];
  await pgsService
    .put("/UpdateBPATrackingNo", reqBody)
    .then((r) => {
      toastr.success("BPA Tracking info Updated Successfully");
      history.push("/pubBpaBatchProcessing");
    })
    .catch((e) => { });
};

export const UpdateBPAOrderPlacementToInProgess = async (reqBody, history) => {
  let jimList = [];
  await pgsService
    .put(
      "/UpdateBPAToInProgressByID                                                                                    ",
      reqBody
    )
    .then((r) => {
      toastr.success(
        "Selected orders will be processed and you will be notified"
      );
      // history.push("/pubEmloBatchProcessing");
    })
    .catch((e) => { });
};

export const getFileDetail = async (BPAOrderHeaderID) => {
  let data = [];
  try {
    let response = await pgsService.get(
      `/GetBPAFileDetailByID/${BPAOrderHeaderID}`
    );
    data = response;
    return data;
  } catch (e) { }
};

export const getBPAOrder = async () => {
  let data = [];
  await pgsService
    .get("/GetBPAJournalDataAsync")
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};
