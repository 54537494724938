import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_PUBLISHER_ORDER_PROCESSING;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getPubBatchLabelOrderPlacement = async () => {
  let pmlopList = [];
  await pgsService
    .get("/BLBatchProcess")
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const searchPubBatchLabelOrderPlacement = async (rBody) => {
  let pmlopList = [];
  await pgsService
    .post("/BLBatchProcessAdvSerach", rBody)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};

export const getOrderFiles = async (cId, lId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetOrderFileReady/${cId}/${lId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getOnHoldFiles = async (cId, lId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetOrderFileOnHold/${cId}/${lId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getBatchSummaryList = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetBLBatchProcessSummary/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getprocessingList1 = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetBLBatchProcessing/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => {
      toastr.error("Something went wrong")
    });
  return pmlopList;
};
export const getprocessingList2 = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetBLBatchResult/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getprocessingList3 = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetBatchRoutingException/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => {
      // toastr.error("Something went wrong")
    });
  return pmlopList;
};
export const putOnhold = async (mId, lId) => {
  let pmlopList = [];
  await pgsService
    .post(`/PutOnHold/${mId}/${lId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};

export const postOrderDetails = async (rBody) => {
  let pmlopList = [];
  await pgsService
    .post(`/BLBatchProcess`, rBody)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => {
      if (e && e.response && e.response.data && e.response.data.errors && e.response.data.errors.length > 0)
        toastr.error(e.response.data.errors[0].message)
    });
  return pmlopList;
};
export const postCriteriaByOF = async (rBody) => {
  let pmlopList = [];
  await pgsService
    .post(`/InsertByOrderFile`, rBody)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};

export const getSecondListForView = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetInsertCriteriaList/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};

export const getbatchDetails = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetDisplayBLBatchDetails/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getRec1List = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetBLStockSummary/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};

export const getMainOrderFile = async (cId, lId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetBLPickOrderFile/${cId}/${lId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getSuplimentOrderFile = async (cId, lId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetPickSupplimentOrderFile/${cId}/${lId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const createBatch = async (rBody, history) => {
  let pmlopList = [];
  await pgsService
    .post(`/PreProcess`, rBody)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
      history.push(
        `/add_pubMainLabelBatchPlacement/${rBody.customerID}/${rBody.locationID}/${r.data}`
      );
      //history.push(`/add_pubMainLabelBatchPlacement/${rBody.customerID}/${rBody.locationID}/34`)
    })
    .catch((e) => { });
  return pmlopList;
};

export const postCriteriaByZC = async (rBody) => {
  let pmlopList = [];
  await pgsService
    .post(`/InsertByCountry`, rBody)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const postPickInsert = async (rBody, mhId) => {
  let pmlopList = [];
  await pgsService
    .post(`/InsertCriteria`, rBody)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const postNextInsert = async (rBody, mhId) => {
  let pmlopList = [];
  await pgsService
    .post(`/InsertCriteria`, rBody)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const blPutOnHold = async (rBody, mhId) => {
  let res = false;
  await pgsService
    .post(`/PutOnHold`, rBody)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      res = r;
      toastr.success("Successfully put on hold");
    })
    .catch((e) => {
      toastr.error("Something went wrong");
    });
  return res;
};
export const blRemoveOnHold = async (rBody, mhId) => {
  let res = false;
  await pgsService
    .post(`/RemoveOnHold`, rBody)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      res = r;
      toastr.success("Successfully removed on hold");
    })
    .catch((e) => {
      toastr.error("Something went wrong");
    });
  return res;
};
export const blMakeInactive = async (rBody, mhId) => {
  let res = false;
  await pgsService
    .delete(`/RecipientPickList`, { data: rBody })
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      res = r;
      toastr.success("Successfully made in active");
    })
    .catch((e) => {
      toastr.error("Something went wrong");
    });
  return res;
};
export const getPickRecpientList = async (fhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetBLJobOrderFileDetails/${fhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getPickInsertList = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetPickInsertList/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getCriteriaFilesList = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetPickCriteriaByOrderFile/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getBPStatus = async () => {
  let pmlopList = [];
  await pgsService
    .get("/GetBatchStatus")
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getRec2List = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetBLBatcDetails/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getRec2ListView = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetViewBLBatcDetails/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const makeReady = async (mId, lId) => {
  let pmlopList = [];
  await pgsService
    .post(`/MoveToReady/${mId}/${lId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};

export const getListForView = async (mhId) => {
  let pmlopList = [];

  return pmlopList;
};
