import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_PUBLISHER_RECIPIENT_LIST;
const pgsService = axios.create({
  baseURL,
});

// ----------------------- Recipient List ----------------//

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getRecipientList = async (id) => {
  let recipientListData = [];
  await pgsService
    .get(`/RecipientList/${id}`)
    .then((r) => {
      recipientListData = r;
    })
    .catch((e) => {});
  return recipientListData;
};
export const getRecipientListByID = async (RecipientId, loggedInId) => {
  let recipientListData = {};
  await pgsService
    .get(`/RecipientList/${RecipientId}/${loggedInId}`)
    .then((r) => {
      recipientListData = r.data;
    })
    .catch((e) => {});
  return recipientListData;
};
export const updateRecipientList = async (ID, reqBody) => {
  let success = false;
  await pgsService
    .put("/RecipientList/" + ID, reqBody)
    .then((response) => {
      success = true;
      toastr.success("Recipient Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e && e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return success;
};

export const createRecipientList = async (reqBody, history) => {
  let success = false;
  await pgsService
    .post("/RecipientList", reqBody)
    .then((response) => {
      success = true;
      toastr.success("Recipient Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e && e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return success;
};

export const deleteJobScheduler = async (deletionIds) => {
  let recipientListData = {};
  let modifiedBy = localStorage.loggedInId;
  await pgsService
    .delete("/RecipientList/", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((response) => {
      toastr.success("Recipient Deleted Successfully");
    })
    .catch((e) => {});
  return recipientListData;
};

export const postJobScheduler = async (data, history) => {
  await pgsService
    .put("/UpdateRecipientList", data)
    .then((r) => {
      if (r.data === 200) toastr.success("Recipient Data Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message != undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getFliteredRecipientList = async (reqBody) => {
  let jobSchedulerList = [];
  await pgsService
    .post("/RecipientListAdvSerach", reqBody)
    .then((r) => {
      jobSchedulerList = r;
    })
    .catch((e) => {});
  return jobSchedulerList;
};

export const deleteRecipient = async (deletionIds, history, token) => {
  let modifiedBy = localStorage.loggedInId;

  await pgsService
    .delete("/RecipientList", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      history.push("/bpaRecipientList");
      toastr.success("Recipient Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      }
    });
};
// ----------------------- Recipient List ends ----------------//
