import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Button, Paper, Divider } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { addStyles } from "../../../shared/styles";
import { StateContext } from "../../../shared/globalState";
import { getDispatchMode, postDispatchMode } from "../masterActions";
const validateMaxLength3 = new Validator("maxlength3");
const validateMaxLength100 = new Validator("maxlength100");
// const styles = theme => ({
//   container: {
//     flexWrap: "wrap",
//     margin: "1em"
//   },
//   textField: {
//     marginLeft: theme.spacing(1.5),
//     marginRight: theme.spacing(1.5),
//     width: "31%",
//     fontSize: "11px !important"
//   },
//   comboBox: {
//     marginLeft: theme.spacing(1.5),
//     marginRight: theme.spacing(1.5),
//     width: "31%",
//     fontSize: "11px !important"
//   },
//   checkBox: {
//     marginTop: theme.spacing(1.5),
//     marginLeft: theme.spacing(1.5),
//     marginRight: "1.5em !important"
//   }
// });

class AddDispatchMode extends React.Component {
  static contextType = StateContext;
  state = {
    dispatchModeCode: "",
    dispatchModeName: "",
    isActive: true,
    errors: {
      dispatchModeCode: false,
      dispatchModeName: false
    }
  };
  componentDidMount() {
    this.props.handleSettingHeader("Dispatch Mode");
    this.props.setSelectedList("masterDataOpen", "dispatchMode");
  }
  handleChange = (e, validate) => {
    let newRrrors = { ...this.state.errors };
    let result = validate(e.target.value);
    newRrrors[e.target.name] = !result;
    this.setState({ [e.target.name]: e.target.value, errors: newRrrors });
  };
  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleClose = () => {
    this.setState({
      dispatchModeCode: "",
      dispatchModeName: ""
    });
  };
  checkValidInputs = () => {
    if (!this.state.dispatchModeCode) return true;
    if (!this.state.dispatchModeName) return true;
    else return false;
  };
  handleSubmit = event => {
    const dispatch = this.context[1];
    let reqBody = {};
    reqBody.dispatchModeCode = this.state.dispatchModeCode;
    reqBody.dispatchModeName = this.state.dispatchModeName;
    reqBody.createdBy = localStorage.loggedInId;
    postDispatchMode(this.props.history, reqBody)
      .then(r => {
        getDispatchMode()
          .then(r => {
            dispatch({
              type: "getDispatchModeList",
              newStore: r.data
            });
          })
          .catch(e => {});
      })
      .catch(e => {});
  };
  render() {
    let { classes } = this.props;
    let { dispatchModeCode, dispatchModeName, errors } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Dispatch Mode</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container}>
                <div>
                  <TextField
                    required
                    label="Dispatch Mode Code"
                    name="dispatchModeCode"
                    inputProps={{
                      maxLength: 3
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={dispatchModeCode.toUpperCase()}
                    onChange={e => this.handleChange(e, validateMaxLength3)}
                    error={errors.dispatchModeCode}
                    helperText={
                      errors.dispatchModeCode
                        ? "Please Enter Dispatch Mode Code"
                        : ""
                    }
                  />

                  <TextField
                    inputProps={{
                      maxLength: 100
                    }}
                    required
                    label="Dispatch Mode Name"
                    name="dispatchModeName"
                    margin="normal"
                    className={classes.textField}
                    value={dispatchModeName}
                    onChange={e => this.handleChange(e, validateMaxLength100)}
                    error={errors.dispatchModeName}
                    helperText={
                      errors.dispatchModeName
                        ? "Please enter valid dispatch mode name"
                        : ""
                    }
                  />
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={e => this.props.history.push("/DispatchMode")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {this.checkValidInputs() ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddDispatchMode));
