import React, { useEffect } from "react";
import clsx from "clsx";
import { deepOrange, deepPurple } from "@material-ui/core/colors";

import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Route, Switch, Redirect } from "react-router-dom";
import { useStyles } from "../../shared/styles";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import MoreIcon from "@material-ui/icons/MoreVert";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { withRouter } from "react-router-dom";

import logo from "../../assests/images/svg/navLogo.svg";
import shortLogo from "../../assests/images/jpg/shortLogo.JPG";
import PrimaryList from "../app/primaryList";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Routes from "./routes";
import Home from "./home";

//for ripple effect

import { withStyles } from "@material-ui/core/styles";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    // "&::after": {
    //   position: "absolute",
    //   top: 0,
    //   left: 0,
    //   width: "100%",
    //   height: "100%",
    //   borderRadius: "50%",
    //   animation: "$ripple 1.2s infinite ease-in-out",
    //   border: "1px solid currentColor",
    //   content: '""'
  },
}))(Badge);
// "@keyframes ripple": {
//   "0%": {
//     transform: "scale(.8)",
//     opacity: 1
//   },
//   "100%": {
//     transform: "scale(2.4)",
//     opacity: 0
//   }
// }

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

function Navbar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [data, setData] = React.useState("");
  const [userName] = React.useState(localStorage.getItem("username"));
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [header, setHeader] = React.useState("");
  const [selectedPrimaryListItem, setPrimaryListItem] = React.useState("");
  const [selectedsecondaryListItem, setSecondaryListItem] = React.useState("");
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const setSelectedList = (p, s, warehouseManagementOpen) => {
    setPrimaryListItem(p);
    setSecondaryListItem(s);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleSettingHeader = (val) => {
    setHeader(val);
  };

  const handleClick = (event) => {
    setAnchorEl(!anchorEl);
  };

  const handleClose = (event) => {
    setAnchorEl(anchorEl);
  };

  const handleLogout = () => {
    const isSingpostUser = localStorage.getItem("isSingpostUser");
    localStorage.clear();
    const baseURL = process.env.REACT_APP_PGS_SERVER_ADFS;
    if (isSingpostUser == "true")
      window.location.replace(baseURL + "/Home/logout");
    else props.history.push("/");
  };

  const handleChangePassword = () => {
    props.history.push("/ChangePassword");
  };

  let user = localStorage.getItem("userName");
  let auth = localStorage.getItem("auth");
  return (
    <div className={classes.navRoot}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{
          boxShadow: "none",
          background: "#f4f5fa",
          color: "#333",
          zIndex: 552,
        }}
      >
        <Toolbar>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              // marginRight: "1em"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "-1.5em",
              }}
            >
              <Fab
                size="small"
                aria-label="add"
                style={{
                  marginRight: "1em",
                  background: "#fff",
                  color: "#777",
                }}
                onClick={open ? handleDrawerClose : handleDrawerOpen}
              >
                {open ? <MoreIcon /> : <FormatListBulletedIcon />}
              </Fab>
              <Typography variant="h6" noWrap style={{ color: "#777" }}>
                {header}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ marginRight: "20px" }}>
                {/* <Badge color="secondary" variant="dot">
                  <NotificationsNoneOutlinedIcon />
                </Badge> */}
              </div>
              <div style={{ marginRight: "10px" }}>
                <div style={{ fontSize: "11px", fontWeight: "bold" }}>
                  {window.localStorage.sub}
                </div>
                <div style={{ fontSize: "9px" }}>
                  {window.localStorage.department}
                </div>
              </div>

              <div
                // style={{
                //   background: "#999",
                //   width: "29px",
                //   height: "28px",
                //   borderRadius: "50%",
                //   color: "#fff"
                //   // display: "flex",
                //   // justifyContent: "center",
                //   // alignItems: "center"
                // }}
                // aria-controls="simple-menu"
                // aria-haspopup="true"
                aria-controls="fade-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              // style={{position:"absolute",top: "60px",right: "30px",border:"1px solid red"}}
              >
                <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  variant="dot"
                >
                  <Avatar>{user ? user[0].toUpperCase() : ""}</Avatar>
                </StyledBadge>

                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  onClick={handleClose}
                  style={{
                    position: "absolute",
                    top: "0",
                    bottom: "0px",
                    right: "11px",
                    left: "auto",
                    width: "200px",
                    // left: "calc(100vw - 14%)",

                    top: "calc(100vh - 139%)",
                  }}
                >
                  <MenuItem
                    // {...props}
                    onClick={handleChangePassword}
                    // onClick={handleChangePassword}
                    // onClick={e => this.props.history.push("/changePassword")}
                    style={localStorage.isSingpostUser == "true" ?
                      {
                        pointerEvents: 'none',
                        color: "#bbb"
                      } : null
                    }
                  >
                    Change Password
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          {open ? (
            <div style={{ textAlign: "center", padding: "5px" }}>
              <div>
                <a href="/">
                  <img src={logo} alt="logo" style={{ width: "131px" }} />
                </a>
              </div>
              <div
                style={{
                  fontSize: "12px",
                  marginTop: "-6px",
                  fontWeight: "600",
                  color: "#21529f",
                }}
              >
                Publisher & Generic System
              </div>
            </div>
          ) : (
              <div>
                <img src={shortLogo} alt="shortLogo" />
              </div>
            )}
        </div>
        <Divider />
        <PrimaryList
          selectedPrimaryListItem={selectedPrimaryListItem}
          selectedsecondaryListItem={selectedsecondaryListItem}
          clearOpenList={open}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        // normalFlow={open ? true : false}
        />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div>
          {/* <CriteriaMaster /> */}
          {props.location.pathname === "/" && (
            <Home handleSettingHeader={handleSettingHeader} />
          )}

          <Routes
            handleSettingHeader={handleSettingHeader}
            setSelectedList={setSelectedList}
            clearOpenList={open}
            selectedsecondaryListItem={selectedsecondaryListItem}
            handleDrawerClose={handleDrawerClose}
          />

          {/* <Redirect to={window.location.pathname} /> */}
        </div>
      </main>
    </div>
  );
}
export default withRouter(Navbar);
