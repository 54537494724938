import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_STOCK_MANAGEMENT;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
/*------------ Item Master functions starts----------------*/
export const getItemMasterList = async () => {
  let ItemMasterList = [];
  await pgsService
    .get("/ItemMasters")
    .then((r) => {
      ItemMasterList = r;
    })
    .catch((e) => {});
  return ItemMasterList;
};
export const updateItemMaster = async (stockID, data, history) => {
  await pgsService

    .put(
      "/ItemMasters/" + stockID,
      data
      //   headers: {
      //     Authorization: "Bearer " + window.localStorage.token
      //   }
      // }
    )

    .then((r) => {
      history.push("/ItemMaster");
      toastr.success("Item Master Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const deleteItemMaster = async (modifiedBy, deletionIds) => {
  //let lettershoplist = [];

  //let modifiedBy = localStorage.loggedInId;
  await pgsService
    .delete("/ItemMasters", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Item Master Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const getItemMasterCode = async (stockID) => {
  let ItemMaster = {};
  await pgsService
    .get("/ItemMasters/" + stockID)
    .then((response) => {
      let data = response.data;
      ItemMaster = {
        stockID: data.stockID,
        customerID: data.customerID,
        customerName: data.customerName,
        stockCode: data.stockCode,
        stockName: data.stockName,
        uomid: data.uomid,
        uomName: data.uomName,
        uomCode: data.uomCode,
        stockCategoryCode: data.stockCategoryCode,
        stockCategoryName: data.stockCategoryName,
        weightPerItem: data.weightPerItem,
        dimension: data.dimension,
        stockCategoryID: data.stockCategoryID,
        reOrderLevelQty: data.reOrderLevelQty,
        price: data.price,
        cost: data.cost,
        isConsumable: data.isConsumable,
        remarks: data.remarks,
        isActive: data.isActive,
        createdBy: data.createdBy,
        createdDate: data.createdDate,
        modifiedBy: data.modifiedBy,
        modifiedDate: data.modifiedDate,
      };
    })
    .catch((e) => {});
  return ItemMaster;
};
export const getFliteredItemMasterList = async (reqBody) => {
  let ItemMasterList = [];

  await pgsService
    .post("/ItemMasterAdvanceSearch", reqBody)
    .then((r) => {
      ItemMasterList = r;
    })
    .catch((e) => {});
  return ItemMasterList;
};
export const postItemMaster = async (data, history) => {
  await pgsService
    .post("/ItemMasters", data)
    .then((r) => {
      history.push("/ItemMaster");
      toastr.success("Item Master Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const getCustomerDetailsOnCustID = async (lettershopId) => {
  let ItemMaster = {};
  await pgsService
    .get("/LetterShop/" + lettershopId)
    .then((response) => {
      let data = response.data;
      ItemMaster = {
        letterShopCode: data.IsVendor,
        letterShopName: data.StockId,
      };
    })
    .catch((e) => {});
  return ItemMaster;
};
export const getCustomer = async (id) => {
  let customer = {};
  await pgsService
    .get(`/GetCustomerByIDDetailsAsync/${id}`)
    .then((r) => {
      customer = r;
    })
    .catch((e) => {});
  return customer;
};
export const getCustomerList = async () => {
  let customerList = [];
  await pgsService
    .get("/CustomerDropdown")
    .then((r) => {
      customerList = r;
    })
    .catch((e) => {});
  return customerList;
};

export const getCustomerFromItemMaster = async () => {
  let customerList = [];
  await pgsService
    .get("/ItemMasterForCustDropdown")
    .then((r) => {
      customerList = r;
    })
    .catch((e) => {});
  return customerList;
};

/*------------ Item Master functions ends----------------*/

//----------------------------------------Stock IN
export const getStockInList = async () => {
  let stockInList = [];
  await pgsService
    .get("/StockIns")
    .then((r) => {
      stockInList = r;
    })

    .catch((e) => {});
  return stockInList;
};

export const getStockIn = async (StockINID) => {
  let StockInValue = {};
  await pgsService
    .get("/StockIns/" + StockINID)
    .then((response) => {
      let data = response.data;
      StockInValue = {
        stockName: data.stockName,
        stockID: data.stockID,
        stockCode: data.stockCode,
        customerCode: data.customerCode,
        customerName: data.customerName,
        warehouseLocation: data.warehouseLocation,
        warehouseLocationID: data.warehouseLocationID,
        receivedQty: data.receivedQty,
        usedQty: data.usedQty,
        receivedDate: data.receivedDate,
        remarks: data.remarks,
      };
    })
    .catch((e) => {});
  return StockInValue;
};
// export const getStockIn = async stockINID => {

//   let StockIn = {};
//   await pgsService
//     .get("/StockIns/" + stockINID)
//     .then(response => {
//       StockIn = response.data;
//     })
//     .catch(e => {});
//   return StockIn;
// };

export const getStockInByCustIdList = async (custId) => {
  let stockInList = [];
  await pgsService
    .get("/GetItemMasterByCustIdLists/" + custId)
    .then((r) => {
      stockInList = r;
    })
    .catch((e) => {});
  return stockInList;
};

export const postStockIn = async (data, history) => {
  await pgsService
    .post("/StockIns", data)
    .then((r) => {
      history.push("/stockIn");
      toastr.success("Stock In Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const getFliteredStockInList = async (reqBody) => {
  let stockInList = [];

  await pgsService
    .post("/StockInsAdvanceSearch", reqBody)
    .then((r) => {
      stockInList = r;
    })
    .catch((e) => {});
  return stockInList;
};
// export const getCustomerList = async () => {
//   let customerList = [];
//   await pgsService
//     .get("/Customer")
//     .then(r => {
//       customerList = r;
//     })
//     .catch(e => {});
//   return customerList;
// };
/*------------ stock out functions starts----------------*/

export const getStockOutList = async () => {
  let StockoutList = [];
  await pgsService
    .get("/StockOuts")
    .then((r) => {
      StockoutList = r;
    })
    .catch((e) => {});
  return StockoutList;
};

export const getStockOutData = async (stockOutID) => {
  let StockoutData = {};
  await pgsService
    .get("/StockOuts/" + stockOutID)
    .then((response) => {
      let data = response.data;
      StockoutData = {
        stockID: data.stockID,
        stockName: data.stockName,
        stockCode: data.stockCode,
        warehouseLocation: data.warehouseLocation,
        stockIndentID: data.stockIndentID,
        stockOutQty: data.stockOutQty,
        customerName: data.customerName,
        stockOutdate: data.stockOutdate,
        reason: data.reason,
        isActive: data.isActive,
        createdBy: data.createdBy,
        createdDate: data.createdDate,
        modifiedBy: data.modifiedBy,
        modifiedDate: data.modifiedDate,
      };
    })
    .catch((e) => {});
  return StockoutData;
};
export const getFliteredStockOutList = async (reqBody) => {
  let StockoutList = [];

  await pgsService
    .post("/StockOutAdvanceSearch", reqBody)
    .then((r) => {
      StockoutList = r;
    })
    .catch((e) => {});
  return StockoutList;
};
export const postStockOut = async (data, history) => {
  await pgsService
    .post("/StockOuts", data)
    .then((r) => {
      history.push("/stockOut");
      toastr.success("StockOut Created Successfully");
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getStockOutsBQList = async (reqBody) => {
  let StockInList = [];

  await pgsService
    .post("/StockOutsBQAdvanceSerach", reqBody)
    .then((r) => {
      StockInList = r;
    })
    .catch((e) => {});
  return StockInList;
};

/**---stock out ends */

export const getWorkOrderList = async () => {
  let workOrderList = [];
  await pgsService
    .get("/WorkOrder")
    .then((r) => {
      workOrderList = r;
    })

    .catch((e) => {});
  return workOrderList;
};

export const getFliteredWorkOrderList = async (reqBody) => {
  let workOrderList = [];
  await pgsService
    .post("/WorkOrderAdvSerach", reqBody)
    .then((r) => {
      workOrderList = r;
    })
    .catch((e) => {});
  return workOrderList;
};
/**---stock out ends */
