import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { printDetails, addZeros } from "../../../shared/tableCommonFunctions";
import { Divider } from "@material-ui/core";

import { getRoutingEMLOEBLO } from "../routingActions";
import { StateContext } from "../../../shared/globalState";

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { selectedroutingEMLOEBLOID } = props;
  let acnArr = props.roles.filter((u) => u.menuName === "Routing By EMLO EBLO");
  let acnObj = acnArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_RoutingEMLOEBLO/${selectedroutingEMLOEBLOID}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewRoutingEMLOEBLO extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      routingEMLOEBLO: {
        customer: {},
        location: {},
        subscription: {},
        dispatchType: {},
        dispatchMode: {},
        mailingScheme: {},
        distributor: {},
        customerName: "",
        locationName: "",
        subscriptionCode: "",
        addressType: "",
        noOfOrdersFrom: "",
        noOfOrdersTo: "",
        noOfCopiesFrom: "",
        noOfCopiesTo: "",
        weightFrom: "",
        weightTo: "",
        ppiNumber: "",
        ppiLicenseNo: "",
        dispatchTypeName: "",
        dispatchModeName: "",
        mailingSchemeName: "",
        distributorName: "",
        returnAddress: "",
        carrierSheetScheme: "",
        carrierSheetMode: "",
      },
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let routingEMLOEBLOID = this.props.match.params.routingEMLOEBLOID;

    getRoutingEMLOEBLO(routingEMLOEBLOID).then((r) => {
      dispatch({
        type: "getRoutingEMLOEBLO",
        newStore: r,
      });
      this.setState({ routingEMLOEBLO: r });
    });
  }

  render() {
    let { routingEMLOEBLO } = this.state;
    let routingEMLOEBLOID = this.props.match.params.routingEMLOEBLOID;
    let { status } = "";
    if (routingEMLOEBLO.isActive === true) {
      status = "Yes";
    } else {
      status = "No";
    }
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selectedroutingEMLOEBLOID={routingEMLOEBLOID}
            roles={this.props.roles}
          />
          {/* <div className="view-page-header">Cost Per Title Information</div> */}
          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">Customer Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Customer</div>
                <div className="view-page-value">
                  {routingEMLOEBLO &&
                    routingEMLOEBLO.customer &&
                    routingEMLOEBLO.customer.customerName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Location</div>
                <div className="view-page-value">
                  {routingEMLOEBLO &&
                    routingEMLOEBLO.location &&
                    routingEMLOEBLO.location.locationName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Subscription</div>
                <div className="view-page-value">
                  {routingEMLOEBLO &&
                    routingEMLOEBLO.subscription &&
                    routingEMLOEBLO.subscription.subscriptionName}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Address Type</div>
                <div className="view-page-value">
                  {routingEMLOEBLO && routingEMLOEBLO.addressType
                    ? "Yes"
                    : "No"}
                </div>
              </div>
            </div>
            <div className="view-page-headings">Criteria Setting</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No Of Orders From</div>
                <div className="view-page-value">
                  {routingEMLOEBLO && routingEMLOEBLO.noOfOrdersFrom}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No Of Orders To</div>
                <div className="view-page-value">
                  {routingEMLOEBLO && routingEMLOEBLO.noOfOrdersTo}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No Of Copies From</div>
                <div className="view-page-value">
                  {routingEMLOEBLO && routingEMLOEBLO.noOfCopiesFrom}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No Of Copies To</div>
                <div className="view-page-value">
                  {routingEMLOEBLO && routingEMLOEBLO.noOfCopiesTo}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Weight From</div>
                <div className="view-page-value">
                  {addZeros(
                    routingEMLOEBLO && routingEMLOEBLO.weightFrom.toString(),
                    2
                  )}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Weight To</div>
                <div className="view-page-value">
                  {addZeros(
                    routingEMLOEBLO && routingEMLOEBLO.weightTo.toString(),
                    2
                  )}
                </div>
              </div>
            </div>
            <div className="view-page-headings">Routing Configuration</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Route PPI</div>
                <div className="view-page-value">
                  {routingEMLOEBLO && routingEMLOEBLO.ppiNumber}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">PPI LicenseNo</div>
                <div className="view-page-value">
                  {routingEMLOEBLO && routingEMLOEBLO.ppiLicenseNo}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Dispatch Type</div>
                <div className="view-page-value">
                  {routingEMLOEBLO &&
                    routingEMLOEBLO.dispatchType &&
                    routingEMLOEBLO.dispatchType.dispatchTypeCodeName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Dispatch Mode</div>
                <div className="view-page-value">
                  {routingEMLOEBLO.dispatchMode &&
                    routingEMLOEBLO.dispatchMode.dispatchModeName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Mailing Scheme</div>
                <div className="view-page-value">
                  {routingEMLOEBLO.mailingScheme &&
                    routingEMLOEBLO.mailingScheme.mailingScheme}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Distributor</div>
                <div className="view-page-value">
                  {routingEMLOEBLO.distributor &&
                    routingEMLOEBLO.distributor.distributorName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Return Address</div>
                <div className="view-page-value">
                  {routingEMLOEBLO.returnAddress}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Carrier Sheet Scheme</div>
                <div className="view-page-value">
                  {routingEMLOEBLO.carrierSheetScheme}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Carrier Sheet Mode</div>
                <div className="view-page-value">
                  {routingEMLOEBLO.carrierSheetMode}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Active</div>
                <div className="view-page-value">{status}</div>
              </div>
            </div>{" "}
            <Divider style={{ marginTop: "25px" }} />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.props.history.push("/routingEMLOEBLO")}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewRoutingEMLOEBLO);
