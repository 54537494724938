import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
// import { getCustomerList, getCustomer } from "../../masters/masterActions";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import moment from "moment";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { StateContext } from "../../../shared/globalState";
import LinearProgress from "@material-ui/core/LinearProgress";
import DateFnsUtils from "@date-io/date-fns";
import toastr from "toastr";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "../../app/App.css";
import {
  desc,
  stableSort,
  getSorting,
  DialogActions,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import Validator from "../../../shared/validator";
import { Link } from "react-router-dom";
import { ExportCSV } from "../../../shared/ExportCSV";
import DuplicateJobModal from "./duplicateJobModal";
import {
  useToolbarStyles,
  useShownColumnStyles,
  styles,
} from "../../../shared/styles";
import {
  SearchIcon,
  DownloadIcon,
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import {
  getjobScheduleList,
  getjobScheduleStatusDropDownList,
  postJobScheduler,
  proceedToComplete,
  deleteJobScheduler,
  getFliteredJobSchedulerList,
  postForReOpenJS
} from "./action";

import { Divider, Input, InputBase } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { getjobScheduleProductionDropDownList } from "../../masters/masterActions";
import { resourceValidation } from "../../../shared/resource";
import StatusText from "../../../shared/statusText";
import clone from "clone";
import { ContactSupportOutlined } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > span": {
      margin: theme.spacing(2),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
      color: "red",
    },
    color: "red",
  },
}));
const columns = [
  {
    name: "Status",
    id: 2,
  },
  {
    name: "JG Number",
    id: 4,
  },
  {
    name: "Modified By",
    id: 16,
  },
  {
    name: "Modified Date",
    id: 17,
    show: false,
  },
  {
    name: "Created By",
    id: 18,
  },
  {
    name: "Created Date",
    id: 19,
  },
];

const notRequiredAlpNum10 = new Validator("notRequiredAlpNum10");

function createData(
  jobSchedulerID,
  productionLineID,
  woNo,
  jgNo,
  client,
  descTitle,
  matRecDate,
  priority,
  jobQty,
  prodDate,
  mailDate,
  etp,
  etsEte,
  planner,
  remarks,
  status,
  createdby,
  createdbyName,
  createdDate,
  modifiedby,
  modifiedbyName,
  modifiedDate,
  productionLineCode,
  statusID,
  workOrderID,
  jobGroupID,
  prodCode,
  statusCode
) {
  return {
    jobSchedulerID,
    productionLineID,
    woNo,
    jgNo,
    client,
    descTitle,
    matRecDate,
    priority,
    jobQty,
    prodDate,
    mailDate,
    etp,
    etsEte,
    planner,
    remarks,
    status,
    createdby,
    createdbyName,
    createdDate,
    modifiedby,
    modifiedbyName,
    modifiedDate,
    productionLineCode,
    statusID,
    workOrderID,
    jobGroupID,
    prodCode,
    statusCode,
  };
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells, editMode } = props;

  const classess = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ borderTop: "1px solid #eaeaea", background: "white" }}
          className="table-cell"
        >
          {/* {!props.editMode && (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={numSelected === rowCount}
                  onChange={onSelectAllClick}
                  color="primary"
                  name="all"
                  inputProps={{ "aria-label": "select all Job Schedule" }}
                />
              }
            />
          )} */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                {editMode ? <span style={{ color: "#306dca" }}>{headCell.label}</span> :
                  <TableSortLabel
                    active={orderBy === headCell.code}
                    direction={order}
                    onClick={createSortHandler(headCell.code)}
                  >
                    <span style={{ color: "#306dca" }}>{headCell.label}</span>
                  </TableSortLabel>}
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    jobSchedulerID: "",
    productionLineID: "",
    woNo: "",
    jgNo: "",
    client: "",
    descTitle: "",
    matRecDate: "",
    priority: "",
    jobQty: "",
    prodDate: "",
    mailDate: "",
    etp: "",
    etsEte: "",
    planner: "",
    remarks: "",
    status: "",
    productionLineCode: "",
    modifiedDateFrom: "",
    modifiedDateTo: "",
  });

  const [formValid, setIsformValid] = useState(false);
  const [ImSearchValue, setValues] = useState({
    woNumber: "",
    jobTitle: "",
    status: "",
    modifiedDateFrom: null,
    modifiedDateTo: null,
    prodLine: ""
  });
  let acnArr = props.roles.filter((u) => u.menuName === "Job Scheduler");
  let acnObj = acnArr[0];
  // console.log(acnObj)
  const [open, setOpen] = React.useState(false);
  const [duplicateOpen, setDuplicateOpen] = React.useState(false);

  const [editMode, setEditMode] = React.useState(false);

  const [column, setColumnIds] = React.useState([]);
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handleDeleteItemMaster,
    searchPopupOpen,
    //selectedItemMaster,
    selected,
    exportDataValues,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchItemMaster,
    searchClicked,
    jobScheduleList,
    handleEditMode,
    isActive,
    hendleVewColums,
    hdC
  } = props;

  // console.log(hdC, exportDataValues)
  let eData = clone(exportDataValues)
  eData.forEach(d => {
    if (hdC.length > 0 && !hdC[1].show)
      delete d.Status
    if (hdC.length > 0 && !hdC[3].show)
      delete d["JG No"]
    if (hdC.length > 0 && !hdC[15].show)
      delete d["Modified By"]
    if (hdC.length > 0 && !hdC[16].show)
      delete d["Modified Date"]
    if (hdC.length > 0 && !hdC[17].show)
      delete d["Created By"]
    if (hdC.length > 0 && !hdC[18].show)
      delete d["Created Date"]
  })

  // console.log(eData)

  const selectedItemMasterID = selected[0];
  const selectedItemMaster = jobScheduleList.find(
    (x) => x.jobSchedulerID === selectedItemMasterID
  );

  // x => x.id == selectedItemMasterID
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteItemMasterdata = () => {
    handleDeleteItemMaster();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const handleDuplicateOpen = () => {
    setDuplicateOpen(true);
  };

  const handleDuplicateClose = () => {
    setDuplicateOpen(false);
  };

  const clearSearchValues = () => {
    let searchValues = ImSearchValue;
    searchValues.woNumber = "";
    searchValues.jobTitle = "";
    searchValues.status = "";
    searchValues.prodLine = "";
    // searchValues.modifiedDateFrom = null;
    // searchValues.modifiedDateTo = null;
    searchValues = ImSearchValue;
  };

  const searchPopup = () => {
    handleSearchItemMaster(ImSearchValue);
    setValues((ImSearchValue) => ({
      woNumber: "",
      jobTitle: "",
      status: {},
      modifiedDateFrom: null,
      modifiedDateTo: null,
      prodLine: {}
    }));
  };

  const validateNotRequired100 = new Validator("maxlength100AllAllow");

  const handleSearchInput = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;
    setValues((ImSearchValue) => ({
      ...ImSearchValue,
      [event.target.name]: event.target.value,
    }));

    switch (name) {
      case "woNumber":
        let woNumberValid = false;
        if (value !== "") {
          woNumberValid = validateNotRequired100(value);
          setIsformValid(woNumberValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: woNumberValid
              ? ""
              : resourceValidation.ValidName,
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: woNumberValid ? "" : "",
          }));
        }
        break;
      case "jobTitle":
        let jobTitleValid = false;
        if (value !== "") {
          jobTitleValid = validateNotRequired100(value);
          setIsformValid(jobTitleValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: jobTitleValid
              ? ""
              : resourceValidation.ValidName,
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: jobTitleValid ? "" : "",
          }));
        }
        break;
      default:
        break;
    }
  };

  const handleClickListItem = () => {
    setOpen(true);
  };
  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
  };

  const cancelHandler = async (e) => {
    setEditMode(false);
    handleEditMode(false);
    await getjobScheduleList().then((r) => {
      props.getAllItemMaster(r.data);
    });
    setValues((ImSearchValue) => ({
      woNumber: "",
      jobTitle: "",
      status: {},
      modifiedDateFrom: null,
      modifiedDateTo: null,
      prodLine: {}
    }));

  };

  const toggleButton = (e) => {
    if (!editMode) {
      setEditMode(true);
      handleEditMode(true);
    } else {
      setEditMode(false);
      handleEditMode(false);
      if (props.jobScheduleList.length > 0) {
        props.handleSubmit();
      }
      props.clearSearch();
    }
  };

  const handleDateChange = (date) => {
    let name = "modifiedDateFrom";
    setValues((ImSearchValue) => ({
      ...ImSearchValue,
      [name]: date && date.toLocaleDateString(),
    }));
    isDateValid(name, date);
  };
  const handleDateChange1 = (date) => {
    let name = "modifiedDateTo";
    setValues((ImSearchValue) => ({
      ...ImSearchValue,
      [name]: date && date.toLocaleDateString(),
    }));
    isDateValid(name, date);
  };

  const isDateValid = (name, date) => {
    let searchValues = ImSearchValue;
    if (name === "modifiedDateFrom") {
      if (date !== null && searchValues.modifiedDateTo !== null) {
        setIsformValid(
          date.toString() !== "Invalid Date" &&
            searchValues.modifiedDateTo.toString() !== "Invalid Date"
            ? true
            : false
        );
      }
    } else {
      if (date !== null && searchValues.modifiedDateFrom !== null) {
        setIsformValid(
          date.toString() !== "Invalid Date" &&
            searchValues.modifiedDateFrom.toString() !== "Invalid Date"
            ? true
            : false
        );
      }
    }
  };

  const proceedToComplete = () => {
    if (numSelected > 0) {
      props.handleProceedToComplete(selected);
    }
  };

  const handleSearchStatusValuesChange = (event, values) => {
    event.persist();
    let value = event.target.value;
    setValues((jobScheduleList) => ({
      ...jobScheduleList,
      status: values,
    }));
    if (value !== undefined) setIsformValid(true);
    else setIsformValid(false);
  };

  const handleSearchProdLineValuesChange = (event, values) => {
    event.persist();
    let value = event.target.value;
    setValues((jobScheduleList) => ({
      ...jobScheduleList,
      prodLine: values,
    }));
    if (value !== undefined) setIsformValid(true);
    else setIsformValid(false);
  };


  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              position: "relative",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >{(props && props.reOpenJS) && (acnObj && acnObj.approveAction) && <Button
            variant="contained"
            color="primary"
            style={{
              // fontSize: "11px",
              //fontWeight: "bold",
              margin: "5px",
              // marginBottom: "5px",
              boxShadow: "none",
            }}
            onClick={(e) =>
              props.handleReOpenJS(e)
            }
          >
            Re-open JS
          </Button>}
            {numSelected > 0 && props.enableEdit && (
              <React.Fragment>
                <Button
                  variant="contained"
                  color="primary"
                  height="25px"
                  // margin="10em"
                  style={{
                    // width: "30%",

                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    boxShadow: "none",
                  }}
                  onClick={proceedToComplete}
                >
                  {" "}
                  {/* <CheckCircleIcon
                    fontSize="small"
                    style={{
                      marginRight: "3px",
                      background: "rgb(0, 105, 217)",
                      color: "#fff"
                    }}
                  /> */}
                  Proceed To Complete
                </Button>
                {/* <div
                    style={{
                      paddingRight: "10px"
                    }}
                  > */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    cursor: "pointer",
                    position: "relative",
                    width: "auto",
                  }}
                >
                  {selected && selected.length > 0 && (
                    <React.Fragment>
                      {numSelected > 1 ? null : (
                        <Tooltip title="Duplicate">
                          <IconButton
                            aria-label="duplicate"
                            className={classes.iconHover}
                            onClick={handleDuplicateOpen}
                          >
                            <FileCopyOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      <React.Fragment>
                        {duplicateOpen ? (
                          <DuplicateJobModal
                            handleDuplicateClose={handleDuplicateClose}
                            duplicateOpen={duplicateOpen}
                            history={props.history}
                            selectedItemMaster={selectedItemMaster}
                            ParentJobSchedulerID={selected && selected[0]}
                            getAllItemMaster={props.getAllItemMaster}
                          />
                        ) : null}
                      </React.Fragment>
                      {/* 
                      {numSelected > 1
                        ? null
                        : acnObj &&
                        acnObj.editAction && props.enableEdit && (
                          <Tooltip title="Edit">
                            <Link
                              to={{
                                pathname: "/schedule",
                                search:
                                  "?workOrderID=" +
                                  props.workOrderId +
                                  "&jobGroupID=" +
                                  props.jobGroupId,
                                state: {
                                  workOrderMode: "edit",
                                  comingFrom: "jobscheduler",
                                },
                              }}
                            >
                              <IconButton
                                aria-label="edit"
                                className={classes.iconHover}
                              >
                                <EditIcon />
                              </IconButton>
                            </Link>
                          </Tooltip>
                        )} */}
                    </React.Fragment>
                  )}
                </div>
              </React.Fragment>
            )}
            {acnObj && acnObj.deleteAction && selected && selected.length > 0 && props.enableEdit && (
              <React.Fragment>
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    onClick={handleClickOpen}
                    disabled={selected && selected.length <= 0 && !isActive}
                  >
                    <DeleteIcon className={classes.iconHover} />
                  </IconButton>
                </Tooltip>
                <Dialog
                  disableBackdropClick
                  disableEscapeKeyDown
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                  open={deletePopupOpen}
                  maxWidth="lg"
                >
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                  >
                    Delete Job Scheduler
                  </DialogTitle>
                  <DialogContent dividers>
                    <div style={{ minWidth: "400px" }}>
                      Are you sure you want to delete{" "} ?
                      <span style={{ fontWeight: "600" }}>
                        {/* {selected.length > 1 ? " ?" : selectedItemMaster.stockName} */}
                        {/* {selectedItemMaster.stockName} */}
                      </span>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                    >
                      Close
                    </Button>
                    <Button
                      onClick={deleteItemMasterdata}
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </React.Fragment>
            )}
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                cursor: "pointer",
                position: "relative",
                width: "100%",
              }}
            >
              {searchClicked === false && (
                <React.Fragment>
                  {" "}
                  {selected && selected.length > 0 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      height="25px"
                      // margin="10em"
                      style={{
                        width: "30%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        boxShadow: "none",
                        pointerEvents:
                          props.jobScheduleList.length == 0
                            ? "default"
                            : "pointer",
                        cursor:
                          props.jobScheduleList.length == 0 ? "none" : "all",
                      }}
                      onClick={proceedToComplete}
                    >
                      {" "}
                      {/* <CheckCircleIcon
                      fontSize="small"
                      style={{
                        marginRight: "3px",
                        background: "rgb(0, 105, 217)",
                        color: "#fff"
                      }}
                    /> */}
                    Proceed To Complete
                    </Button>
                  ) : (
                      <React.Fragment>
                        {acnObj && acnObj.editAction && (
                          <Button
                            disabled={props.jobScheduleList.length == 0}
                            variant="contained"
                            color="primary"
                            style={{
                              display: "flex",
                              alignItems: "end",
                              boxShadow: "none",
                            }}
                            onClick={(e) => toggleButton(e)}
                          >
                            <div
                              style={{
                                cursor: searchClicked === true && "default",
                                pointerEvents: searchClicked === true && "none",
                              }}
                            >
                              {" "}
                              {/* <EditIcon
                          fontSize="small"
                          style={{
                            marginTop: "5px",
                            marginBottom: "5px"
                          }}
                        /> */}
                            </div>

                            {editMode === false ? "Edit Mode" : "Update Mode"}
                          </Button>
                        )}
                        {editMode === true ? (
                          <Tooltip title="Cancel">
                            <IconButton
                              aria-label="cancel"
                              className={classes.iconHover}
                              onClick={cancelHandler.bind(this)}
                            >
                              <CancelOutlinedIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                            ""
                          )}
                      </React.Fragment>
                    )}
                </React.Fragment>
              )}
              {searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  <form onSubmit={searchPopup}>
                    {acnObj && acnObj.advanceSearchAction && (
                      <Tooltip title="Search">
                        <IconButton
                          aria-label="Search"
                          className={classes.iconHover}
                          onClick={handleSearchOpen}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      onClose={handleSearchPopupClose}
                      aria-labelledby="customized-dialog-title"
                      open={searchPopupOpen}
                      maxWidth="md"
                    >
                      <DialogTitle
                        id="customized-dialog-title"
                        onClose={handleSearchPopupClose}
                      >
                        Advanced Search
                  </DialogTitle>
                      <DialogContent dividers>
                        <React.Fragment>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <TextField
                              id="standard-required"
                              label="WO Number"
                              name="woNumber"
                              margin="normal"
                              value={ImSearchValue.woNumber}
                              inputProps={{
                                maxLength: 50,
                              }}
                              style={{ marginLeft: "0", width: "200px" }}
                              autoComplete="off"
                              onChange={handleSearchInput}
                              error={errors.woNumber}
                              helperText={errors.woNumber ? errors.woNumber : ""}
                            />
                            <TextField
                              id="standard-required"
                              label="Job Title"
                              name="jobTitle"
                              className={classes.textField}
                              margin="normal"
                              value={ImSearchValue.jobTitle}
                              inputProps={{
                                maxLength: 50,
                              }}
                              style={{
                                margin: "0 0 0 10px", marginTop: "8px", width: "200px",

                              }}
                              autoComplete="off"
                              onChange={handleSearchInput}
                              error={errors.jobTitle}
                              helperText={errors.jobTitle ? errors.jobTitle : ""}
                            />
                            {/* <Autocomplete
                              autoComplete
                              includeInputInList
                              id="status-index"
                              name="status"
                              hintText="Status"
                              options={props.IdsList}
                              style={{
                                width: "200px",
                              }}
                              onChange={handleSearchStatusValuesChange}
                              getOptionLabel={(option) => option.text}
                              value={ImSearchValue.status}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={"Select Status"}
                                  name="status"
                                  id="status"
                                  margin="normal"
                                  style={{ width: "100%" }}
                                  value={ImSearchValue.status}
                                />
                              )}
                            /> */}
                          </div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                autoOk
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                style={{ width: "31%" }}
                                id="modifiedDateFrom"
                                label="Modified Date From"
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                value={ImSearchValue.modifiedDateFrom}
                              />
                            </MuiPickersUtilsProvider> */}
                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                autoOk
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                style={{
                                  margin: "0 10px",
                                  width: "31%",
                                  marginTop: "8px",
                                }}
                                id="modifiedDateTo"
                                label="Modified Date To"
                                value={ImSearchValue.modifiedDateTo}
                                onChange={handleDateChange1}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider> */}
                            <Autocomplete
                              autoComplete
                              includeInputInList
                              id="status-index"
                              name="status"
                              hintText="Status"
                              options={props.IdsList}
                              style={{
                                width: "200px",
                              }}
                              onChange={handleSearchStatusValuesChange}
                              getOptionLabel={(option) => option.text}
                              value={ImSearchValue.status}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={"Status"}
                                  name="status"
                                  id="status"
                                  margin="normal"
                                  style={{ width: "100%" }}
                                  value={ImSearchValue.status}
                                />
                              )}
                            />
                            <Autocomplete
                              autoComplete
                              includeInputInList
                              id="status-index"
                              name="prodLine"
                              hintText="Prod Line"
                              options={props.prodLList}
                              style={{
                                width: "200px",
                                margin: "0 0px 0 10px",
                              }}
                              onChange={handleSearchProdLineValuesChange}
                              getOptionLabel={(option) => option.value}
                              value={ImSearchValue.prodLine ? ImSearchValue.prodLine : {}}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={"Production Line"}
                                  name="prodLine"
                                  id="prodLine"
                                  margin="normal"
                                  style={{ width: "100%" }}
                                  value={ImSearchValue.prodLine}
                                />
                              )}
                            />
                          </div>
                        </React.Fragment>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={handleSearchClose}
                        >
                          CANCEL
                    </Button>
                        {!formValid ? (
                          <Button
                            disabled
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                            onClick={searchPopup}
                          >
                            SEARCH
                          </Button>
                        ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ boxShadow: "none" }}
                              onClick={searchPopup}
                            >
                              SEARCH
                            </Button>
                          )}
                      </DialogActions>
                    </Dialog>
                  </form>
                )}
              {acnObj && acnObj.exportAction && (
                <ExportCSV
                  csvData={eData}
                  fileName={"JobScheduler_Details"}
                />
              )}
              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                hendleVewColums={hendleVewColums}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const [column, setColumnIds] = React.useState(valueProp);
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  }, [valueProp, open]);

  const handleClose = () => {
    onClose(setColumnIds);
  };

  const handleChange = (event) => {
    setColumnIds(event.target.value);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

class JobScheduler extends React.Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      reOpenJS: false,
      reOpenModalOpen: false,
      enableEdit: true,
      enableDelete: true,
      proceedModalOpen: false,
      aggreedToProceed: false,
      jobScheduler: {
        jobSchedulerID: "",
        productionLineID: "",
        woNo: "",
        jgNo: "",
        client: "",
        descTitle: "",
        matRecDate: null,
        priority: "",
        jobQty: "",
        prodDate: null,
        mailDate: null,
        etp: "",
        etsEte: "",
        planner: "",
        remarks: "",
        status: "",
        productionLineCode: "",
        statusID: "",
        workOrderID: "",
        jobGroupID: "",
      },
      deletePopupOpen: false,
      order: "desc",
      //  orderBy: "modifiedDate",
      orderBy: "",
      selected: [],
      page: 0,
      rowsPerPage: 100,
      rows: [],
      activeRows: 0,
      isActive: false,
      loading: false,
      refreshData: false,
      selectedItemMaster: {},
      jobScheduleList: [],
      loading: false,
      searchPopupOpen: false,
      ItemSearchValues: {},
      searchClicked: false,
      selectedShowColumns: [],
      customersList: [],
      isEditMode: false,
      exportDataValues: [],
      headCells: [
        {
          code: "productionLineCode",
          id: 1,
          numeric: false,
          disablePadding: false,
          label: "Prod Line",
          show: true,
        },
        {
          code: "status",
          id: 2,
          numeric: false,
          disablePadding: false,
          label: "Status",
          show: true,
        },
        {
          code: "woNo",
          id: 3,
          numeric: false,
          disablePadding: false,
          label: "Work Order No",
          show: true,
        },
        {
          code: "jgNo",
          id: 4,
          numeric: false,
          disablePadding: false,
          label: "JG No",
          show: false,
        },
        {
          code: "client",
          id: 5,
          numeric: false,
          disablePadding: false,
          label: "Client",
          show: true,
        },
        {
          code: "descTitle",
          id: 6,
          numeric: false,
          disablePadding: false,
          label: "Desc / Title",
          show: true,
        },
        {
          code: "matRecDate",
          id: 7,
          numeric: false,
          disablePadding: false,
          label: "Mat Rec Date",
          show: true,
        },
        {
          code: "priority",
          id: 8,
          numeric: true,
          disablePadding: false,
          label: "Pri",
          show: true,
        },
        {
          code: "jobQty",
          id: 9,
          numeric: false,
          disablePadding: false,
          label: "Job Qty",
          show: true,
        },
        {
          code: "prodDate",
          id: 10,
          numeric: false,
          disablePadding: false,
          label: "Prod Date",
          show: true,
        },
        {
          code: "mailDate",
          id: 11,
          numeric: false,
          disablePadding: false,
          label: "Mail Date",
          show: true,
        },
        {
          code: "etp",
          id: 12,
          numeric: false,
          disablePadding: false,
          label: "ETP",
          show: true,
        },
        {
          code: "etsEte",
          id: 13,
          numeric: false,
          disablePadding: false,
          label: " ETS/ETE",
          show: true,
        },
        {
          code: "planner",
          id: 14,
          numeric: false,
          disablePadding: false,
          label: "Planner",
          show: true,
        },
        {
          code: "remarks",
          id: 15,
          numeric: false,
          disablePadding: false,
          label: "Remarks",
          show: true,
        },
        {
          id: 16,
          code: "modifiedbyName",
          numeric: false,
          disablePadding: false,
          label: "Modified By",
          show: false,
        },

        {
          id: 17,
          code: "modifiedDate",
          numeric: false,
          disablePadding: false,
          label: "Modified Date",
          show: false,
        },

        {
          id: 18,
          code: "createdbyName",
          numeric: false,
          disablePadding: false,
          label: "Created By",
          show: false,
        },
        {
          id: 19,
          code: "createdDate",
          numeric: false,
          disablePadding: false,
          label: "Created Date",
          show: false,
        },
      ],
      currentEdit: "",
      value: 0,
      list: [],
      prodLineId: [],
      statusList: [],

      IdsList: [],
      ParentJobSchedulerID: [],
      duplicateJobSchedulerList: {
        productionLineID: "",
        jobQuantity: "",
        priority: "",
        projectTitle: "",
        createdby: "",
      },
      jobGroupList: [],
      jobGroupId: null,
      workOrderId: null,
    };
  }

  handleWorkOrderInfo = (e, selectedWO) => {
    this.setState({ duplicateJobSchedulerList: e });
    this.validateField("", "");
  };
  clearWorkOrderInfo = (i) => {
    let newWorkOrder = { ...this.state.duplicateJobSchedulerList };
    newWorkOrder = {};
    this.setState({ duplicateJobSchedulerList: newWorkOrder });
  };

  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };
  proceedModalBtnClicked = (val) => {
    if (val === "Yes") {
      let index = clone(this.state.selected)
      const dispatch = this.context[1];
      let deletionIds = index.length > 1 ? index.join(",") : index[0];

      let reqBody = {
        modifiedby: localStorage.loggedInId,
        proceedIDs: deletionIds, //js id
      };
      proceedToComplete(reqBody).then((r) => {
        getjobScheduleList().then((r) => {
          dispatch({
            type: "jobScheduleList",
            newStore: r.data,
          });
          this.getAllItemMaster(r.data);
        });
      });
    }
    this.setState({ proceedModalOpen: false })
  }
  handleProceedToComplete = async (index) => {
    // let agreed = window.confirm("Are you sure you want to proceed !");
    this.setState({ proceedModalOpen: true })
  };

  clearSearch = () => {
    const [{ store }, dispatch] = this.context;
    getjobScheduleList().then((r) => {
      dispatch({
        type: "jobScheduleList",
        newStore: r.data,
      });
      this.getAllItemMaster(r.data);
      this.setState({ loading: true, searchClicked: false });
    });
  };

  componentDidMount() {
    const [{ store }, dispatch] = this.context;
    this.props.handleSettingHeader("Job Scheduler");
    this.props.setSelectedList("genericSystemOpen", "jobScheduler");
    this.props.handleDrawerClose();
    this.setState({ loading: true });
    getjobScheduleList().then((r) => {
      dispatch({
        type: "jobScheduleList",
        newStore: r.data,
      });
      this.getAllItemMaster(r.data);
      this.exportDatas(r.data);
    });

    // get productionLine dropdown data
    getjobScheduleProductionDropDownList().then((r) => {
      dispatch({
        type: "getjobScheduleProductionDropDownList",
        newStore: r.data,
      });
      this.getAllStatus(r.data);
    });

    getjobScheduleStatusDropDownList().then((r) => {
      dispatch({
        type: "getjobScheduleStatusDropDownList",
        newStore: r.data,
      });
      this.getAllStatusCodes(r.data);
    });
  }
  getAllStatus = (list) => {
    this.setState({
      prodLineId:
        list &&
        list.sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
        ),
    });
  };

  getAllStatusCodes = (list) => {
    this.setState({
      statusList:
        list &&
        list
          .filter((val) => val.text != "CLN")
          .sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
    });
    this.setState({
      IdsList:
        list &&
        list
          .filter((val) => val.id != "Cancelled")
          .sort((a, b) => (a.id > b.id ? 1 : -1)),
    });
  };

  // this.setState({ loading: true });

  exportDatas = (jobScheduleList) => {
    let list = jobScheduleList;
    // console.log(this.state.headCells)
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        rows.push({
          "Prod Line": d.productionLineCode,
          Status: d.status,
          "Work Order No": d.workOrderNumber,
          "JG No": d.jobGroupNumber,
          CLient: d.customerCode,
          "Desc/Title": d.jobTitle,
          "Mat Rec Date": moment(d.materialReceivedDate).format("DD/MM/YYYY"),
          Priority: d.priority,
          "Job Qty": d.jobQuantity,
          "Production Date": moment(d.productionDate).format("DD/MM/YYYY"),
          "Mail Date": moment(d.mailingDate).format("DD/MM/YYYY"),
          ETP: d.etp,
          "ETS/ETE": d.etsete,
          Planner: d.planner,
          Remarks: d.remarks,
          "Modified By": d.modifiedbyName,
          "Modified Date": moment(d.modifiedDate).format("DD/MM/YYYY HH:mm:ss"),
          "Created By": d.createdbyName,
          "Created Date": moment(d.createdDate).format("DD/MM/YYYY HH:mm:ss"),
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getAllItemMaster = (jobScheduleList) => {
    let list = jobScheduleList;

    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        newRows.push(
          createData(
            d.jobSchedulerID,
            d.productionLineID,
            d.workOrderNumber,
            d.jobGroupNumber,
            d.customerCode,
            d.jobTitle,
            d.materialReceivedDate,
            d.priority,
            d.jobQuantity,
            d.productionDate,
            d.mailingDate,
            d.etp,
            d.etsete,
            d.planner,
            d.remarks,
            d.status,
            d.createdby,
            d.createdbyName,
            d.createdDate,
            d.modifiedby,
            d.modifiedbyName,
            d.modifiedDate,
            d.productionLineCode,

            d.statusID,
            d.workOrderID,
            d.jobGroupID,
            { id: d.productionLineID, text: "", value: d.productionLineCode },
            { id: d.statusID, text: d.status, value: "" }
          )
        );

      });
    this.setState({
      jobScheduleList: newRows,
      selected: [],

      loading: false,
      activeRows: newRows
        .filter((x) => x.isActive)
        .map((n) => n.jobSchedulerID),
    });
    // this.sortStateList(newRows);
  };
  shouldComponentUpdate(s, p) {
    const { jobScheduleList } = this.state;
    const newjobScheduleList = clone(jobScheduleList);
    if (jobScheduleList === newjobScheduleList) {
      // if (this.state.isEditMode === false) {
      // }
      this.setState({});
    }
    return true;
  }
  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    // console.log(property)
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleClick = (event, data) => {
    if (this.state.isEditMode === false) {
      let id = data.jobSchedulerID;
      let { selected } = this.state;

      const jobGroupId = data.jobGroupID;
      const workOrderId = data.workOrderID;
      let enableEdit = true
      let enableDelete = true

      this.setState({
        jobGroupId: jobGroupId,
        workOrderId: workOrderId,
      });

      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      if (newSelected.length == 1) {
        if (data.status === "ZZ" || data.status === "CLN")
          enableEdit = false
        if (data.status === "CLN")
          enableDelete = false
        this.checkActiveStatus(newSelected);
        this.setState({
          selected: newSelected,
          selectedItemMaster: data,
          selectedItemMasterID: id,
          enableEdit,
          enableDelete
        });


      } else {
        this.checkActiveStatus(newSelected);
        this.setState({
          selected: newSelected,
          selectedItemMaster: data,
          selectedItemMasterID: id,
          //selectedItemMaster: {}
          // selectedItemMasterID: 0
        });
      }
      let matchFound = true
      if (newSelected.length > 0)
        this.state.jobScheduleList.map(x => {
          newSelected.map(y => {
            if (x.jobSchedulerID === y) {
              if (x.status !== "ZZ") {
                matchFound = false
              }
            }
          })
        })
      this.setState({ reOpenJS: matchFound })

    } else {
      event.preventDefault();
    }

  };
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleEditMode = () => {
    this.setState({ isEditMode: !this.state.isEditMode });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true, page: 0 });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };
  handleSearchItemMaster = (handleSearchItemMaster) => {
    let ImSearchValue = handleSearchItemMaster;

    let woNo = ImSearchValue.woNumber;
    let jobTitle = ImSearchValue.jobTitle;
    let modifiedDateFrom = ImSearchValue.modifiedDateFrom;
    let modifiedDateTo = ImSearchValue.modifiedDateTo;
    let statusid = ImSearchValue.status;
    let prodLine = ImSearchValue.prodLine
    let reqBody = {
      workOrderNumber: woNo,
      jobTitle: jobTitle,
      modifiedDateFrom: modifiedDateFrom,
      modifiedDateTo: modifiedDateTo,
      statusID: statusid && statusid.id ? statusid.id : 0,
      productionLineID: prodLine && prodLine.id ? prodLine.id : 0
    };
    const dispatch = this.context[1];
    getFliteredJobSchedulerList(reqBody).then((r) => {
      dispatch({
        type: "getFliteredJobSchedulerList",
        newStore: r.data,
      });

      this.getAllItemMaster(r.data);
      this.setState({ searchPopupOpen: false, searchClicked: true });
    });
  };

  handleDeleteItemMaster = async (index) => {
    //give update api call
    let { selected } = this.state;
    const dispatch = this.context[1];
    let deletionIds = selected.length > 1 ? selected.join(",") : selected[0];

    await deleteJobScheduler(deletionIds).then((r) => {
      getjobScheduleList().then((r) => {
        dispatch({
          type: "jobScheduleData",
          newStore: r.data,
        });
        if (r.status === 200) {
          this.getAllItemMaster(r.data);
          this.exportDatas(r.data);
          this.setState({ deletePopupOpen: false, selected: [] });
          // window.location.reload();
        }
        // this.getAllItemMaster(r.data);
      });
    });
  };

  handleProductionLineIdChange = (event, index, newValue) => {
    const { jobScheduleList } = this.state;
    let newList = [...jobScheduleList];

    let inQueueObj = this.state.statusList.filter((r) => r.value == "JQUE", {});
    let inOperationObj = this.state.statusList.filter(
      (r) => r.value == "JOP",
      {}
    );

    if (newValue) {
      newList[index]["prodCode"] = newValue;
      newList[index]["productionLineCode"] = newValue.value;
      newList[index]["productionLineID"] = newValue.id;

      newList[index]["statusCode"] = inOperationObj && inOperationObj[0];
      newList[index]["status"] = inOperationObj && inOperationObj[0].text;
      newList[index]["statusID"] = inOperationObj && inOperationObj[0].id;
    } else {
      newList[index]["prodCode"] = "";
      newList[index]["productionLineCode"] = "";
      newList[index]["productionLineID"] = "";
      if (newList[index]["productionLineCode"] == "") {
        newList[index]["statusCode"] = inQueueObj && inQueueObj[0];
        newList[index]["status"] = inQueueObj && inQueueObj[0].text;
        newList[index]["statusID"] = inQueueObj && inQueueObj[0].id;
      }
    }

    this.setState({ jobScheduleList: newList });
    document.getElementById("productionLine" + index).style =
      " background: rgb(0, 105, 217,0.3)";
  };

  handleStatusChange = (event, index, newValue) => {
    const { jobScheduleList, IdsList } = this.state;
    let newList = [...jobScheduleList];
    let id = "";
    if (newValue) {
      newList[index]["statusCode"] = newValue;
      newList[index]["status"] = newValue.text;
      newList[index]["statusID"] = newValue.id;
    } else {
      newList[index]["statusCode"] = "";
      newList[index]["status"] = "";
      newList[index]["statusID"] = "";
    }

    this.setState({ jobScheduleList: newList });
    document.getElementById("statusCell" + index).style =
      "background: rgb(0, 105, 217,0.3)";
  };

  handleChange = (event, index) => {
    const { jobScheduleList } = this.state;
    const newjobScheduleList = clone(jobScheduleList);

    jobScheduleList[index][event.target.name] = event.target.value;
    this.setState({ jobScheduleList });
    if (
      newjobScheduleList[index][event.target.name] !==
      jobScheduleList[index][event.target.name]
    ) {
      if (event.target.id.includes("etp")) {
        document.getElementById(event.target.id).style =
          "background: rgb(0, 105, 217,0.3)";
      } else {
        document.getElementById(
          event.target.id
        ).parentElement.parentElement.parentElement.style =
          "background: rgb(0, 105, 217,0.3)";
      }
    }
    // this.validateField(event.target.name, event.target.value);
  };

  handleMatRecDateChange = (date, index, name, event) => {
    const { jobScheduleList } = this.state;

    jobScheduleList[index][name] = date && date.toLocaleDateString();
    this.setState({ jobScheduleList });

    document.getElementById(name + index).style =
      "background: rgb(0, 105, 217,0.3)";
  };

  handleSubmit = async () => {
    const { jobScheduleList, IdsList } = this.state;
    let getIdForJs = IdsList.map((val) => {
      return { text: val.text, id: val.id };
    });
    let getIndexForJs = [];

    jobScheduleList.map((val, index) => {
      return getIndexForJs.push({
        jobSchedulerID: val.jobSchedulerID,
        productionLineID: val.productionLineID,
        materialReceivedDate: val.matRecDate,
        priority: Number(val.priority),
        productionDate: val.prodDate,
        mailingDate: val.mailDate,
        etsete: val.etsEte,
        etp: val.etp,
        remarks: val.remarks,
        statusID: val.statusID,
        modifiedby: window.localStorage.loggedInId,
        jobQuantity: val.jobQty ? val.jobQty : 0,
      });
    });
    let reqBody = {
      getIndexForJs,
    };

    const [{ store }, dispatch] = this.context;
    await postJobScheduler(reqBody.getIndexForJs, this.props.history).then(
      (r) =>
        getjobScheduleList().then((r) => {
          dispatch({
            type: "jobScheduleList",
            newStore: r.data,
          });
          // if (r.status === 200) {
          this.getAllItemMaster(r.data);
          this.exportDatas(r.data);
          // window.location.reload();
          // }
        })
    );
  };

  sortStateList = (list) => {
    let z = list.sort(function (a, b) {
      //console.log(a, b);
      let x = a.isActive && a.isActive;
      let y = b.isActive && b.isActive;
      let u = a.modifiedDate && a.modifiedDate;
      let v = b.modifiedDate && b.modifiedDate;
      if (x < y) {
        return 1;
      }
      if (u < v) {
        return -1;
      }
      if (x > y) {
        return -1;
      }
      if (u > v) {
        return 1;
      }
      return 0;
    });

    // console.log(z);
    this.setState({ rows: z });
  };
  handleReOpenJS = (e, id) => {
    // console.log(id)
    this.setState({ reOpenModalOpen: true })

  }
  reOpenModalBtnClicked = (val) => {
    if (val === "Yes") {
      let id = this.state.selected.join(",")

      let lId = localStorage.loggedInId;
      let rBody = {
        modifiedBy: lId,
        jobSchedulerIds: id
      }
      postForReOpenJS(rBody).then(r => {
        this.clearSearch()
      })
      this.setState({ selected: [], selectedWorkOrder: {}, reOpenJS: false })
    }
    this.setState({ reOpenModalOpen: false })

  }
  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      selectedItemMaster,
      jobScheduleList,
      headCells,
      exportDataValues,
      value,
      prodLineId,
      statusList,
      modifiedbyName,
    } = this.state;
    const { classes } = this.props;
    const isSelected = (id) => selected.indexOf(id) !== -1;
    // console.log(jobScheduleList);
    return (
      <div>
        {/* <Dialog
          open={this.state.proceedModalOpen}
        >
          <DialogContent dividers> Are you sure you want to proceed</DialogContent>
          <DialogActions>
            <Button onClick={e => this.proceedModalBtnClicked("No")}>No</Button>
            <Button onClick={e => this.proceedModalBtnClicked("Yes")}>Yes</Button>
          </DialogActions>
        </Dialog> */}
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.reOpenModalOpen}
        >
          <div style={{ padding: "4em" }}>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>
              {" "}
              Are you sure you want to "Re-open" ?
            </div>

            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={e => this.reOpenModalBtnClicked("No")}
                style={{ marginRight: "1em", boxShadow: "none" }}
              >
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={e => this.reOpenModalBtnClicked("Yes")}
              >
                Yes
              </Button>
            </div>
          </div>
        </Dialog>
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.proceedModalOpen}
        >
          <div style={{ padding: "4em" }}>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>
              {" "}
              Are you sure you want to "Proceed" ?
            </div>

            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={e => this.proceedModalBtnClicked("No")}
                style={{ marginRight: "1em", boxShadow: "none" }}
              >
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={e => this.proceedModalBtnClicked("Yes")}
              >
                Yes
              </Button>
            </div>
          </div>
        </Dialog>
        <Paper>
          <EnhancedTableToolbar
            handleEditMode={this.handleEditMode}
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedItemMaster={selectedItemMaster}
            onSearchClicked={this.onSearchClicked}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            selected={this.state.selected}
            selectedItemMasterID={this.state.selectedItemMasterID}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteItemMaster={this.handleDeleteItemMaster}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchItemMaster={this.handleSearchItemMaster}
            ImSearchValue={this.state.ImSearchValue}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            customersList={this.state.customersList}
            jobScheduleList={jobScheduleList}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            editMode={this.state.isEditMode}
            IdsList={this.state.IdsList}
            handleSubmit={this.handleSubmit}
            handleProceedToComplete={this.handleProceedToComplete}
            handleStatusChange={this.handleStatusChange}
            ItemSearchValue={this.state.ItemSearchValues}
            handleWorkOrderInfo={this.handleWorkOrderInfo}
            clearWorkOrderInfo={this.clearWorkOrderInfo}
            jobGroupList={this.state.jobGroupList}
            ParentJobSchedulerID={this.state.ParentJobSchedulerID}
            workOrderId={this.state.workOrderId}
            jobGroupId={this.state.jobGroupId}
            getAllItemMaster={this.getAllItemMaster}
            roles={this.props.roles}
            prodLList={this.state.prodLineId}
            enableEdit={this.state.enableEdit}
            enableDelete={this.state.enableDelete}
            reOpenJS={this.state.reOpenJS}
            handleReOpenJS={this.handleReOpenJS}
            hdC={this.state.headCells}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ whiteSpace: "nowrap" }}
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={e => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                  editMode={this.state.isEditMode}
                />
                {this.state.isEditMode ? <TableBody style={{ overflow: "hidden" }}>
                  {jobScheduleList.length > 0 ? (
                    // stableSort(jobScheduleList, getSorting(order, orderBy))
                    //   .slice(
                    //     page * rowsPerPage,
                    //     page * rowsPerPage + rowsPerPage
                    //   )

                    jobScheduleList.map((row, index) => {
                      const isItemSelected = isSelected(row.jobSchedulerID);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => this.handleClick(event, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            {this.state.isEditMode === false && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            )}
                          </TableCell>

                          {this.state.headCells[0].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={"productionLine" + index}
                              style={{
                                padding: "0px 6px",
                                background:
                                  this.state.isEditMode === false &&
                                  "transparent",
                                minWidth:
                                  this.state.isEditMode === true
                                    ? "120px"
                                    // : "80px",
                                    : ""
                              }}
                              padding="default"
                              className="table-content-cell-wm"
                            >
                              {this.state.isEditMode ? (
                                <div
                                  style={{
                                    marginTop: "0px !important",
                                    minWidth: "120px",
                                  }}
                                >
                                  <Autocomplete
                                    autoComplete
                                    includeInputInList
                                    id={"productionLineID" + index}
                                    name="productionLineID"
                                    hintText="Production Line ID"
                                    options={this.state.prodLineId}
                                    style={{
                                      marginTop: "0px",
                                      width: "100%",
                                    }}
                                    onChange={(e, newValue) =>
                                      this.handleProductionLineIdChange(
                                        e,
                                        index,
                                        newValue
                                      )
                                    }
                                    getOptionLabel={(option) => option.value}
                                    value={row.prodCode}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name="productionLineID"
                                        id={"productionLineID" + index}
                                        style={{
                                          margin: "0px !important",
                                          width: "100%",
                                        }}
                                        required
                                      />
                                    )}
                                  />
                                </div>
                              ) : row.productionLineCode &&
                                row.productionLineCode > 16 ? (
                                    <Tooltip title={row.productionLineCode}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.productionLineCode}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography noWrap style={{ fontSize: "14px" }}>
                                      {row.productionLineCode}
                                    </Typography>
                                  )}
                            </TableCell>
                          )}
                          {this.state.headCells[1].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={"statusCell" + index}
                              padding="default"
                              className="table-content-cell-wm"
                              // style={{ padding: "0 25px" }}
                              style={{
                                padding: "0px 6px",
                                background:
                                  this.state.isEditMode === false &&
                                  "transparent",
                                minWidth:
                                  this.state.isEditMode === true
                                    ? "120px"
                                    // : "100px",
                                    : ""
                              }}
                            >
                              {this.state.isEditMode ? (
                                <div
                                  style={{ marginTop: "0px !important" }}
                                  id={"statusCell" + index}
                                  name={"status" + index}
                                  style={{
                                    minWidth: "120px",
                                  }}
                                >
                                  <Autocomplete
                                    disabled
                                    autoComplete
                                    includeInputInList
                                    id={"status" + index}
                                    name="status"
                                    className="statusBox"
                                    hintText="Status"
                                    options={this.state.statusList}
                                    style={{
                                      marginTop: "0px",
                                      width: "100%",
                                    }}
                                    onChange={(e, newValue) =>
                                      this.handleStatusChange(
                                        e,
                                        index,
                                        newValue
                                      )
                                    }
                                    getOptionLabel={(option) => option.text}
                                    value={row.statusCode}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        // name="status"
                                        // id={"status" + index}
                                        style={{
                                          marginTop: "0px !important",
                                        }}
                                        required
                                      />
                                    )}
                                  />
                                </div>
                              ) : (
                                  row.status && (
                                    <React.Fragment>
                                      {row.status === "OP" ? (
                                        <StatusText color="#65d0ff">
                                          {/* OP */}
                                          {row.status}
                                        </StatusText>
                                      ) : row.status === "In Operation" ? (
                                        <StatusText color="#f5a623">
                                          {row.status}
                                        </StatusText>
                                      ) : row.status === "ZZ" ? (
                                        <StatusText color="#096c08">
                                          {/* ZZ */}
                                          {row.status}
                                        </StatusText>
                                      ) : row.status === "WIP" ? (
                                        <StatusText color="#f5a623">
                                          {/* WIP */}
                                          {row.status}
                                        </StatusText>
                                      ) : row.status === "CLN" ? (
                                        <StatusText color="red">
                                          {/* CLN */}
                                          {row.status}
                                        </StatusText>
                                      ) : row.status === "QQ" ? (
                                        <StatusText color="red">
                                          {/* QQ */}
                                          {row.status}
                                        </StatusText>
                                      ) : (
                                                    <StatusText color="grey">
                                                      {row.status}
                                                    </StatusText> // to get the other status
                                                  )}
                                    </React.Fragment>
                                  )
                                )}
                            </TableCell>
                          )}

                          {this.state.headCells[2].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell-wm"
                            >
                              {row.woNo && row.woNo.length > 16 ? (
                                <Tooltip title={row.woNo}>
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.woNo}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {row.woNo}
                                  </Typography>
                                )}
                            </TableCell>
                          )}
                          {this.state.headCells[3].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {row.jgNo && row.jgNo.length > 16 ? (
                                <Tooltip title={row.jgNo}>
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.jgNo}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {row.jgNo}
                                  </Typography>
                                )}
                            </TableCell>
                          )}
                          {this.state.headCells[4].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell-wm"
                            >
                              {row.client && row.client.length > 9 ? (
                                <Tooltip title={row.client}>
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.client}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {row.client}
                                  </Typography>
                                )}
                            </TableCell>
                          )}
                          {this.state.headCells[5].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell-wm"
                              style={{
                                maxWidth: "75px"
                              }}
                            >
                              {row.descTitle && row.descTitle.length > 9 ? (
                                <Tooltip title={row.descTitle}>
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.descTitle}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {row.descTitle}
                                  </Typography>
                                )}
                            </TableCell>
                          )}
                          {this.state.headCells[6].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={"matRecDate" + index}
                              padding="default"
                              className="table-content-cell-wm"
                              style={{
                                padding: "0 5px",
                                minWidth: this.state.isEditMode ? "150px" : "10px",
                                background:
                                  this.state.isEditMode === false &&
                                  "transparent",
                              }}
                            >
                              {this.state.isEditMode ? (
                                <div>
                                  <KeyboardDatePicker
                                    autoOk
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="matRecDate"
                                    name="matRecDate"
                                    // label=""
                                    value={row.matRecDate}
                                    onChange={(e, event) =>
                                      this.handleMatRecDateChange(
                                        e,
                                        index,
                                        "matRecDate",
                                        event
                                      )
                                    }
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                  />
                                </div>
                              ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {" "}
                                    {moment(row.matRecDate).format("DD/MM/YYYY")}
                                  </Typography>
                                )}
                            </TableCell>
                          )}
                          {this.state.headCells[7].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-right-cell"
                              style={{
                                padding: "0px 5px",
                                background:
                                  this.state.isEditMode === false &&
                                  "transparent",
                              }}
                            >
                              {this.state.isEditMode ? (
                                <div>
                                  <Input
                                    defaultValue=""
                                    id={"priority" + index}
                                    inputProps={{
                                      "aria-label": "priority",
                                      maxLength: 200,
                                    }}
                                    style={{ width: "100%" }}
                                    name="priority"
                                    value={row.priority}
                                    onChange={(e) =>
                                      this.handleChange(e, index)
                                    }
                                  />
                                </div>
                              ) : row.priority && row.priority.length > 16 ? (
                                <Tooltip title={row.priority}>
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.priority}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                    <Typography noWrap style={{ fontSize: "14px" }}>
                                      {row.priority}
                                    </Typography>
                                  )}
                            </TableCell>
                          )}
                          {this.state.headCells[8].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-right-cell"
                              style={{
                                padding: "0px 5px",
                                background:
                                  this.state.isEditMode === false &&
                                  "transparent",
                              }}
                            >
                              {/* {row.jobQty && row.jobQty.length > 16 ? (
                                  <Tooltip title={row.jobQty}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.jobQty}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.jobQty}
                                  </Typography>
                                )} */}
                              {this.state.isEditMode ? (
                                <div>
                                  <Input
                                    defaultValue=""
                                    id={"jobQty" + index}
                                    inputProps={{
                                      "aria-label": "jobQty",
                                      maxLength: 200,
                                    }}
                                    style={{ width: "100%" }}
                                    name="jobQty"
                                    value={row.jobQty}
                                    onChange={(e) =>
                                      this.handleChange(e, index)
                                    }
                                  />
                                </div>
                              ) : row.jobQty && row.jobQty.length > 16 ? (
                                <Tooltip title={row.jobQty}>
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.jobQty}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                    <Typography noWrap style={{ fontSize: "14px" }}>
                                      {row.jobQty}
                                    </Typography>
                                  )}
                            </TableCell>
                          )}
                          {this.state.headCells[9].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={"prodDate" + index}
                              padding="default"
                              className="table-content-cell"
                              style={{
                                padding: "0 5px",
                                minWidth: this.state.isEditMode ? "150px" : "10px",
                                background:
                                  this.state.isEditMode === false &&
                                  "transparent",
                              }}
                            >
                              {this.state.isEditMode ? (
                                <div>
                                  <KeyboardDatePicker
                                    autoOk
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="prodDate"
                                    name="prodDate"
                                    // label="Production Date"
                                    value={row.prodDate}
                                    onChange={(e, event) =>
                                      this.handleMatRecDateChange(
                                        e,
                                        index,
                                        "prodDate",
                                        event
                                      )
                                    }
                                    KeyboardButtonProps={{
                                      "aria-label": "prodDate",
                                    }}
                                  />
                                </div>
                              ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {" "}
                                    {moment(row.prodDate).format("DD/MM/YYYY")}
                                  </Typography>
                                )}
                            </TableCell>
                          )}

                          {this.state.headCells[10].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={"mailDate" + index}
                              padding="default"
                              className="table-content-cell"
                              style={{
                                padding: "0 5px",
                                minWidth: this.state.isEditMode ? "150px" : "10px",
                                background:
                                  this.state.isEditMode === false &&
                                  "transparent",
                              }}
                            >
                              {this.state.isEditMode ? (
                                <div>
                                  <KeyboardDatePicker
                                    autoOk
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="mailDate"
                                    name="mailDate"
                                    // label="Mail Date"
                                    value={row.mailDate}
                                    onChange={(e, event) =>
                                      this.handleMatRecDateChange(
                                        e,
                                        index,
                                        "mailDate",
                                        event
                                      )
                                    }
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                  />
                                </div>
                              ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {" "}
                                    {moment(row.mailDate).format("DD/MM/YYYY")}
                                  </Typography>
                                )}
                            </TableCell>
                          )}
                          {this.state.headCells[11].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={"etp" + index}
                              padding="default"
                              className="table-content-cell-wm"
                              style={{
                                background:
                                  this.state.isEditMode === false &&
                                  "transparent",
                                padding: "0px 6px",
                                minWidth: "75px",
                                maxWidth: "75px"
                              }}
                            >
                              {this.state.isEditMode ? (
                                <div
                                  style={{
                                    marginTop: "0px !important",
                                    minWidth: "75px",
                                    maxWidth: "75px"
                                  }}
                                >
                                  <Input
                                    // defaultValue=""
                                    id={"etp" + index}
                                    inputProps={{
                                      "aria-label": "etp",
                                      maxLength: 20,
                                    }}
                                    style={{
                                      marginTop: "0px",
                                      width: "100%",
                                    }}
                                    name="etp"
                                    value={row.etp}
                                    onChange={(e) =>
                                      this.handleChange(e, index)
                                    }
                                  />
                                </div>
                              ) : row.etp && row.etp.length > 7 ? (
                                <Tooltip title={row.etp}>
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.etp}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                    <Typography noWrap style={{ fontSize: "14px" }}>
                                      {row.etp}
                                    </Typography>
                                  )}
                            </TableCell>
                          )}
                          {this.state.headCells[12].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell-wm"
                              style={{
                                padding: "0px 6px",
                                background:
                                  this.state.isEditMode === false &&
                                  "transparent",
                                minWidth: "75px",
                                maxWidth: "75px"
                              }}
                            >
                              {this.state.isEditMode ? (
                                <div
                                  style={{
                                    marginTop: "0px !important",
                                    minWidth: "75px",
                                    maxWidth: "75px"
                                  }}
                                >
                                  <Input
                                    defaultValue=""
                                    id={"etsEte" + index}
                                    inputProps={{
                                      "aria-label": "etsEte",
                                      maxLength: 20,
                                    }}
                                    style={{
                                      marginTop: "0px",
                                      width: "100%",
                                    }}
                                    name="etsEte"
                                    value={row.etsEte}
                                    onChange={(e) =>
                                      this.handleChange(e, index)
                                    }
                                  />
                                </div>
                              ) : row.etsEte && row.etsEte.length > 7 ? (
                                <Tooltip title={row.etsEte}>
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.etsEte}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                    <Typography noWrap style={{ fontSize: "14px" }}>
                                      {row.etsEte}
                                    </Typography>
                                  )}
                            </TableCell>
                          )}
                          {this.state.headCells[13].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell-wm"
                              style={{
                                maxWidth: "75px"
                              }}
                            >
                              {row.planner && row.planner.length > 9 ? (
                                <Tooltip title={row.planner}>
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.planner}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {row.planner}
                                  </Typography>
                                )}
                            </TableCell>
                          )}
                          {this.state.headCells[14].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell-wm"
                              style={{
                                padding: "0px 6px",
                                background:
                                  this.state.isEditMode === false &&
                                  "transparent",
                                minWidth: "75px",
                                maxWidth: "75px"
                              }}
                            >
                              {this.state.isEditMode ? (
                                <div
                                  style={{
                                    marginTop: "0px !important",
                                    minWidth: "75px",
                                    maxWidth: "75px"
                                  }}
                                >
                                  <Input
                                    defaultValue=""
                                    id={"remarks" + index}
                                    inputProps={{
                                      "aria-label": "remarks",
                                      maxLength: 200,
                                    }}
                                    style={{
                                      marginTop: "0px",
                                      width: "100%",
                                    }}
                                    name="remarks"
                                    value={row.remarks}
                                    onChange={(e) =>
                                      this.handleChange(e, index)
                                    }
                                  />
                                </div>
                              ) : row.remarks && row.remarks.length > 9 ? (
                                <Tooltip title={row.remarks}>
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.remarks}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                    <Typography noWrap style={{ fontSize: "14px" }}>
                                      {row.remarks}
                                    </Typography>
                                  )}
                            </TableCell>
                          )}

                          {this.state.headCells[15].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.modifiedbyName}
                              </Typography>
                            </TableCell>
                          )}
                          {this.state.headCells[16].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {row.modifiedDate &&
                                row.modifiedDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                            </TableCell>
                          )}
                          {this.state.headCells[17].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.createdbyName}
                              </Typography>
                            </TableCell>
                          )}
                          {this.state.headCells[18].show && (
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {row.createdDate &&
                                row.createdDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={12} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                        {/* {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )} */}
                      </TableRow>
                    )}
                </TableBody> :
                  <TableBody style={{ overflow: "hidden" }}>
                    {jobScheduleList.length > 0 ? (
                      stableSort(jobScheduleList, getSorting(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )

                        .map((row, index) => {
                          const isItemSelected = isSelected(row.jobSchedulerID);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) => this.handleClick(event, row)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={index}
                              selected={isItemSelected}
                              style={{ cursor: "pointer" }}
                            >
                              <TableCell padding="checkbox">
                                {this.state.isEditMode === false && (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                      />
                                    }
                                  />
                                )}
                              </TableCell>

                              {this.state.headCells[0].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={"productionLine" + index}
                                  style={{
                                    padding: "0px 6px",
                                    background:
                                      this.state.isEditMode === false &&
                                      "transparent",
                                    minWidth:
                                      this.state.isEditMode === true
                                        ? "120px"
                                        // : "80px",
                                        : ""
                                  }}
                                  padding="default"
                                  className="table-content-cell-wm"
                                >
                                  {this.state.isEditMode ? (
                                    <div
                                      style={{
                                        marginTop: "0px !important",
                                        minWidth: "120px",
                                      }}
                                    >
                                      <Autocomplete
                                        autoComplete
                                        includeInputInList
                                        id={"productionLineID" + index}
                                        name="productionLineID"
                                        hintText="Production Line ID"
                                        options={this.state.prodLineId}
                                        style={{
                                          marginTop: "0px",
                                          width: "100%",
                                        }}
                                        onChange={(e, newValue) =>
                                          this.handleProductionLineIdChange(
                                            e,
                                            index,
                                            newValue
                                          )
                                        }
                                        getOptionLabel={(option) => option.value}
                                        value={row.prodCode}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            name="productionLineID"
                                            id={"productionLineID" + index}
                                            style={{
                                              margin: "0px !important",
                                              width: "100%",
                                            }}
                                            required
                                          />
                                        )}
                                      />
                                    </div>
                                  ) : row.productionLineCode &&
                                    row.productionLineCode > 16 ? (
                                        <Tooltip title={row.productionLineCode}>
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.productionLineCode}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography noWrap style={{ fontSize: "14px" }}>
                                          {row.productionLineCode}
                                        </Typography>
                                      )}
                                </TableCell>
                              )}
                              {this.state.headCells[1].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={"statusCell" + index}
                                  padding="default"
                                  className="table-content-cell-wm"
                                  // style={{ padding: "0 25px" }}
                                  style={{
                                    padding: "0px 6px",
                                    background:
                                      this.state.isEditMode === false &&
                                      "transparent",
                                    minWidth:
                                      this.state.isEditMode === true
                                        ? "120px"
                                        // : "100px",
                                        : ""
                                  }}
                                >
                                  {this.state.isEditMode ? (
                                    <div
                                      style={{ marginTop: "0px !important" }}
                                      id={"statusCell" + index}
                                      name={"status" + index}
                                      style={{
                                        minWidth: "120px",
                                      }}
                                    >
                                      <Autocomplete
                                        disabled
                                        autoComplete
                                        includeInputInList
                                        id={"status" + index}
                                        name="status"
                                        className="statusBox"
                                        hintText="Status"
                                        options={this.state.statusList}
                                        style={{
                                          marginTop: "0px",
                                          width: "100%",
                                        }}
                                        onChange={(e, newValue) =>
                                          this.handleStatusChange(
                                            e,
                                            index,
                                            newValue
                                          )
                                        }
                                        getOptionLabel={(option) => option.text}
                                        value={row.statusCode}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            // name="status"
                                            // id={"status" + index}
                                            style={{
                                              marginTop: "0px !important",
                                            }}
                                            required
                                          />
                                        )}
                                      />
                                    </div>
                                  ) : (
                                      row.status && (
                                        <React.Fragment>
                                          {row.status === "OP" ? (
                                            <StatusText color="#65d0ff">
                                              {/* OP */}
                                              {row.status}
                                            </StatusText>
                                          ) : row.status === "In Operation" ? (
                                            <StatusText color="#f5a623">
                                              {row.status}
                                            </StatusText>
                                          ) : row.status === "ZZ" ? (
                                            <StatusText color="#096c08">
                                              {/* ZZ */}
                                              {row.status}
                                            </StatusText>
                                          ) : row.status === "WIP" ? (
                                            <StatusText color="#f5a623">
                                              {/* WIP */}
                                              {row.status}
                                            </StatusText>
                                          ) : row.status === "CLN" ? (
                                            <StatusText color="red">
                                              {/* CLN */}
                                              {row.status}
                                            </StatusText>
                                          ) : row.status === "QQ" ? (
                                            <StatusText color="red">
                                              {/* QQ */}
                                              {row.status}
                                            </StatusText>
                                          ) : (
                                                        <StatusText color="grey">
                                                          {row.status}
                                                        </StatusText> // to get the other status
                                                      )}
                                        </React.Fragment>
                                      )
                                    )}
                                </TableCell>
                              )}

                              {this.state.headCells[2].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  className="table-content-cell-wm"
                                >
                                  {row.woNo && row.woNo.length > 16 ? (
                                    <Tooltip title={row.woNo}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.woNo}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography noWrap style={{ fontSize: "14px" }}>
                                        {row.woNo}
                                      </Typography>
                                    )}
                                </TableCell>
                              )}
                              {this.state.headCells[3].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  className="table-content-cell"
                                >
                                  {row.jgNo && row.jgNo.length > 16 ? (
                                    <Tooltip title={row.jgNo}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.jgNo}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography noWrap style={{ fontSize: "14px" }}>
                                        {row.jgNo}
                                      </Typography>
                                    )}
                                </TableCell>
                              )}
                              {this.state.headCells[4].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  className="table-content-cell-wm"
                                >
                                  {row.client && row.client.length > 9 ? (
                                    <Tooltip title={row.client}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.client}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography noWrap style={{ fontSize: "14px" }}>
                                        {row.client}
                                      </Typography>
                                    )}
                                </TableCell>
                              )}
                              {this.state.headCells[5].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  className="table-content-cell-wm"
                                  style={{
                                    maxWidth: "75px"
                                  }}
                                >
                                  {row.descTitle && row.descTitle.length > 9 ? (
                                    <Tooltip title={row.descTitle}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.descTitle}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography noWrap style={{ fontSize: "14px" }}>
                                        {row.descTitle}
                                      </Typography>
                                    )}
                                </TableCell>
                              )}
                              {this.state.headCells[6].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={"matRecDate" + index}
                                  padding="default"
                                  className="table-content-cell-wm"
                                  style={{
                                    padding: "0 5px",
                                    minWidth: this.state.isEditMode ? "150px" : "10px",
                                    background:
                                      this.state.isEditMode === false &&
                                      "transparent",
                                  }}
                                >
                                  {this.state.isEditMode ? (
                                    <div>
                                      <KeyboardDatePicker
                                        autoOk
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="matRecDate"
                                        name="matRecDate"
                                        // label=""
                                        value={row.matRecDate}
                                        onChange={(e, event) =>
                                          this.handleMatRecDateChange(
                                            e,
                                            index,
                                            "matRecDate",
                                            event
                                          )
                                        }
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                      <Typography noWrap style={{ fontSize: "14px" }}>
                                        {" "}
                                        {moment(row.matRecDate).format("DD/MM/YYYY")}
                                      </Typography>
                                    )}
                                </TableCell>
                              )}
                              {this.state.headCells[7].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  className="table-content-right-cell"
                                  style={{
                                    padding: "0px 5px",
                                    background:
                                      this.state.isEditMode === false &&
                                      "transparent",
                                  }}
                                >
                                  {this.state.isEditMode ? (
                                    <div>
                                      <Input
                                        defaultValue=""
                                        id={"priority" + index}
                                        inputProps={{
                                          "aria-label": "priority",
                                          maxLength: 200,
                                        }}
                                        style={{ width: "100%" }}
                                        name="priority"
                                        value={row.priority}
                                        onChange={(e) =>
                                          this.handleChange(e, index)
                                        }
                                      />
                                    </div>
                                  ) : row.priority && row.priority.length > 16 ? (
                                    <Tooltip title={row.priority}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.priority}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                        <Typography noWrap style={{ fontSize: "14px" }}>
                                          {row.priority}
                                        </Typography>
                                      )}
                                </TableCell>
                              )}
                              {this.state.headCells[8].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  className="table-content-right-cell"
                                  style={{
                                    padding: "0px 5px",
                                    background:
                                      this.state.isEditMode === false &&
                                      "transparent",
                                  }}
                                >
                                  {/* {row.jobQty && row.jobQty.length > 16 ? (
                                  <Tooltip title={row.jobQty}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.jobQty}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.jobQty}
                                  </Typography>
                                )} */}
                                  {this.state.isEditMode ? (
                                    <div>
                                      <Input
                                        defaultValue=""
                                        id={"jobQty" + index}
                                        inputProps={{
                                          "aria-label": "jobQty",
                                          maxLength: 200,
                                        }}
                                        style={{ width: "100%" }}
                                        name="jobQty"
                                        value={row.jobQty}
                                        onChange={(e) =>
                                          this.handleChange(e, index)
                                        }
                                      />
                                    </div>
                                  ) : row.jobQty && row.jobQty.length > 16 ? (
                                    <Tooltip title={row.jobQty}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.jobQty}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                        <Typography noWrap style={{ fontSize: "14px" }}>
                                          {row.jobQty}
                                        </Typography>
                                      )}
                                </TableCell>
                              )}
                              {this.state.headCells[9].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={"prodDate" + index}
                                  padding="default"
                                  className="table-content-cell"
                                  style={{
                                    padding: "0 5px",
                                    minWidth: this.state.isEditMode ? "150px" : "10px",
                                    background:
                                      this.state.isEditMode === false &&
                                      "transparent",
                                  }}
                                >
                                  {this.state.isEditMode ? (
                                    <div>
                                      <KeyboardDatePicker
                                        autoOk
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="prodDate"
                                        name="prodDate"
                                        // label="Production Date"
                                        value={row.prodDate}
                                        onChange={(e, event) =>
                                          this.handleMatRecDateChange(
                                            e,
                                            index,
                                            "prodDate",
                                            event
                                          )
                                        }
                                        KeyboardButtonProps={{
                                          "aria-label": "prodDate",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                      <Typography noWrap style={{ fontSize: "14px" }}>
                                        {" "}
                                        {moment(row.prodDate).format("DD/MM/YYYY")}
                                      </Typography>
                                    )}
                                </TableCell>
                              )}

                              {this.state.headCells[10].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={"mailDate" + index}
                                  padding="default"
                                  className="table-content-cell"
                                  style={{
                                    padding: "0 5px",
                                    minWidth: this.state.isEditMode ? "150px" : "10px",
                                    background:
                                      this.state.isEditMode === false &&
                                      "transparent",
                                  }}
                                >
                                  {this.state.isEditMode ? (
                                    <div>
                                      <KeyboardDatePicker
                                        autoOk
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="mailDate"
                                        name="mailDate"
                                        // label="Mail Date"
                                        value={row.mailDate}
                                        onChange={(e, event) =>
                                          this.handleMatRecDateChange(
                                            e,
                                            index,
                                            "mailDate",
                                            event
                                          )
                                        }
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                      <Typography noWrap style={{ fontSize: "14px" }}>
                                        {" "}
                                        {moment(row.mailDate).format("DD/MM/YYYY")}
                                      </Typography>
                                    )}
                                </TableCell>
                              )}
                              {this.state.headCells[11].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={"etp" + index}
                                  padding="default"
                                  className="table-content-cell-wm"
                                  style={{
                                    background:
                                      this.state.isEditMode === false &&
                                      "transparent",
                                    padding: "0px 6px",
                                    minWidth: "75px",
                                    maxWidth: "75px"
                                  }}
                                >
                                  {this.state.isEditMode ? (
                                    <div
                                      style={{
                                        marginTop: "0px !important",
                                        minWidth: "75px",
                                        maxWidth: "75px"
                                      }}
                                    >
                                      <Input
                                        // defaultValue=""
                                        id={"etp" + index}
                                        inputProps={{
                                          "aria-label": "etp",
                                          maxLength: 200,
                                        }}
                                        style={{
                                          marginTop: "0px",
                                          width: "100%",
                                        }}
                                        name="etp"
                                        value={row.etp}
                                        onChange={(e) =>
                                          this.handleChange(e, index)
                                        }
                                      />
                                    </div>
                                  ) : row.etp && row.etp.length > 7 ? (
                                    <Tooltip title={row.etp}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.etp}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                        <Typography noWrap style={{ fontSize: "14px" }}>
                                          {row.etp}
                                        </Typography>
                                      )}
                                </TableCell>
                              )}
                              {this.state.headCells[12].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  className="table-content-cell-wm"
                                  style={{
                                    padding: "0px 6px",
                                    background:
                                      this.state.isEditMode === false &&
                                      "transparent",
                                    minWidth: "75px",
                                    maxWidth: "75px"
                                  }}
                                >
                                  {this.state.isEditMode ? (
                                    <div
                                      style={{
                                        marginTop: "0px !important",
                                        minWidth: "75px",
                                        maxWidth: "75px"
                                      }}
                                    >
                                      <Input
                                        defaultValue=""
                                        id={"etsEte" + index}
                                        inputProps={{
                                          "aria-label": "etsEte",
                                          maxLength: 200,
                                        }}
                                        style={{
                                          marginTop: "0px",
                                          width: "100%",
                                        }}
                                        name="etsEte"
                                        value={row.etsEte}
                                        onChange={(e) =>
                                          this.handleChange(e, index)
                                        }
                                      />
                                    </div>
                                  ) : row.etsEte && row.etsEte.length > 7 ? (
                                    <Tooltip title={row.etsEte}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.etsEte}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                        <Typography noWrap style={{ fontSize: "14px" }}>
                                          {row.etsEte}
                                        </Typography>
                                      )}
                                </TableCell>
                              )}
                              {this.state.headCells[13].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  className="table-content-cell-wm"
                                  style={{
                                    maxWidth: "75px"
                                  }}
                                >
                                  {row.planner && row.planner.length > 9 ? (
                                    <Tooltip title={row.planner}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.planner}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography noWrap style={{ fontSize: "14px" }}>
                                        {row.planner}
                                      </Typography>
                                    )}
                                </TableCell>
                              )}
                              {this.state.headCells[14].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  className="table-content-cell-wm"
                                  style={{
                                    padding: "0px 6px",
                                    background:
                                      this.state.isEditMode === false &&
                                      "transparent",
                                    minWidth: "75px",
                                    maxWidth: "75px"
                                  }}
                                >
                                  {this.state.isEditMode ? (
                                    <div
                                      style={{
                                        marginTop: "0px !important",
                                        minWidth: "75px",
                                        maxWidth: "75px"
                                      }}
                                    >
                                      <Input
                                        defaultValue=""
                                        id={"remarks" + index}
                                        inputProps={{
                                          "aria-label": "remarks",
                                          maxLength: 200,
                                        }}
                                        style={{
                                          marginTop: "0px",
                                          width: "100%",
                                        }}
                                        name="remarks"
                                        value={row.remarks}
                                        onChange={(e) =>
                                          this.handleChange(e, index)
                                        }
                                      />
                                    </div>
                                  ) : row.remarks && row.remarks.length > 9 ? (
                                    <Tooltip title={row.remarks}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.remarks}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                        <Typography noWrap style={{ fontSize: "14px" }}>
                                          {row.remarks}
                                        </Typography>
                                      )}
                                </TableCell>
                              )}

                              {this.state.headCells[15].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  className="table-content-cell"
                                >
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {" "}
                                    {row.modifiedbyName}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[16].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  className="table-content-cell"
                                >
                                  {row.modifiedDate &&
                                    row.modifiedDate.length > 16 ? (
                                      <Tooltip
                                        title={moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {moment(row.modifiedDate).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                          )}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography noWrap style={{ fontSize: "14px" }}>
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    )}
                                </TableCell>
                              )}
                              {this.state.headCells[17].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  className="table-content-cell"
                                >
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {" "}
                                    {row.createdbyName}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[18].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  className="table-content-cell"
                                >
                                  {row.createdDate &&
                                    row.createdDate.length > 16 ? (
                                      <Tooltip
                                        title={moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {moment(row.createdDate).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                          )}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography noWrap style={{ fontSize: "14px" }}>
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    )}
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })
                    ) : (
                        <TableRow>
                          {this.state.loading ? (
                            <TableCell colSpan={15} style={{ textAlign: "center" }}>
                              <LinearProgress />
                            </TableCell>
                          ) : (
                              <TableCell colSpan={12} style={{ textAlign: "center" }}>
                                No result found
                              </TableCell>
                            )}
                          {/* {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )} */}
                        </TableRow>
                      )}
                  </TableBody>
                }
              </Table>
            </div>
          </div>

          {!this.state.isEditMode && (
            <TablePagination
              rowsPerPageOptions={[100, 200, 300]}
              component="div"
              count={jobScheduleList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </div>
    );
  }
}
export default withRouter(JobScheduler);
