import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";

import { Tooltip, Link, IconButton } from "@material-ui/core";
import { getEMLOByID } from "./action";
import { printDetails } from "../../../shared/tableCommonFunctions";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import moment from "moment";
const validateMaxLength2 = new Validator("maxlength2");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    // marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: "23%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    // marginLeft: "7px",
    // marginLeft: "1.5em",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
});

class ViewEmloOrderPlacement extends React.Component {
  constructor() {
    super();

    this.state = {
      emloData: {
        customersList: [],
        customer: "",
        emloNo: "",
        orderCreatedBy: "",
        department: "",
        issueType: "",
        category: "",
        acronym: "",
        volume: "",
        issue: "",
        support: "",
        part: "",
        dept: "",
        issueDescription: "",
        quantity: "",
        estUnitWeight: "0.000",
        estTotalWeight: "0.000",
        orderDate: null,
        stocktoDistributorDate: null,
        deliveryMethod: "",
        deliveryDetails: "",
        companyName: "",
        recipientName: "",
        department: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        city: "",
        state: "",
        postCode: "",
        countryName: "",
        countryCode: "",
        areaCode: "",
        number: "",
        trackingNumber: "",
        actualWeight: "0.000",
        uploadedFiles: "",
        deliveryDate: "",
      },
      errors: {},

      emloValid: true,
      orderCreatedByValid: true,
      departmentValid: true,
      issueTypeValid: true,
      categoryValid: true,
      acronymValid: true,
      volumeValid: true,
      issueValid: true,
      supportValid: true,
      partValid: true,
      issueDescriptionValid: true,
      quantityValid: true,
      estUnitWeightValid: true,
      estTotalWeightValid: true,
      orderDateValid: true,
      stocktoDistributorDateValid: true,
      deliveryMethodValid: true,
      deliveryDetailsValid: true,
      companyNameValid: true,
      recipientNameValid: true,
      departmentValid: true,
      addressLine1Valid: true,
      addressLine2Valid: true,
      addressLine3Valid: true,
      cityValid: true,
      stateValid: true,
      postCodeValid: true,
      countryValid: true,
      countryCodeValid: true,
      areaCodeValid: true,
      numberValid: true,
      trackingNumberValid: true,
      actualWeightValid: true,
      deliveryDateValid: true,
      formValid: false,
    };
  }

  componentDidMount() {
    this.props.handleSettingHeader("EMLO Order Placement");
    this.props.setSelectedList(
      "publisherMainLabelOpen",
      "pubEmloOrderPlacement"
    );

    this.getAllData();
  }

  handleSubmit = () => { };

  getAllData = async () => {
    let emloID = window.location.pathname.split("/")[2];
    await getEMLOByID(emloID).then((r) => {
      this.setState({
        emloData: r,
        // emlo: r.emloNo,
        // orderCreatedBy: r.orderCreatedBy,
        // department: r.department,
        // issueType: r.issueType,
        // category: r.category,
        // acronym: r.acronym.trim(),
        // volume: r.volume.trim(),
        // issue: r.issue.trim(),
        // support: r.supp.trim(),
        // part: r.part.trim(),
        // issueDescription: r.issueDescription,
        // quantity: r.quantity,
        // estUnitWeight: r.estUnitWeight,
        // estTotalWeight: r.estTotalWeight,
        // orderDate: r.orderDate,
        // stocktoDistributorDate: r.stocktoDistributorDate,
        // deliveryMethod: r.deliveryMethod,
        // deliveryDetails: r.deliveryDetails.trim(),
        // companyName: r.companyName,
        // recipientName: r.recipientName,
        // department: r.department,
        // addressLine1: r.addressLine1,
        // addressLine2: r.addressLine2,
        // addressLine3: r.addressLine3,
        // city: r.city,
        // state: r.state,
        // postCode: r.postCode,
        // country: r.country,
        // countryCode: r.countryCode.trim(),
        // areaCode: r.areaCode.trim(),
        // number: r.number,
        // trackingNumber: r.trackingNumber,
        // actualWeight: r.actualWeight,
        // deliveryDate: r.deliveryDate,
      });
    });
  };

  handleChange = (e) => {
    this.setState({ formValid: true });
    this.setState({ [e.target.name]: e.target.value });
  };
  handleClose = () => {
    // clean up state and errors
    let newZone = { ...this.state.Zone };
    newZone.ZoneCode = "";
    newZone.ZoneName = "";
    this.setState({
      Zone: newZone,
      errors: {},
    });
  };

  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertKeyPress(event) {
    const re = /[a-zA-Z0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _freetext(event) {
    const re = /[#.0-9a-zA-Z\s,-@()%!$%^&*{}/<>_;:'" |]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onTextKeyPress(event) {
    const re = /[a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusNumberKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onWeightKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertSizeKeyPress(event) {
    const re = /[0-9 a-zA-Z-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusAllPress(event) {
    const re = /[a-z A-Z0-9=.;()&@]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onPhoneNumber(event) {
    const re = /[a-z A-Z0-9+]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onEmail(event) {
    const re = /[a-z A-Z0-9=.;()&@]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  validateForm() {
    if (
      !this.state.emloValid &&
      !this.state.orderCreatedByValid &&
      !this.state.departmentValid &&
      !this.state.issueTypeValid &&
      !this.state.categoryValid &&
      !this.state.acronymValid &&
      !this.state.volumeValid &&
      !this.state.issueValid &&
      !this.state.supportValid &&
      !this.state.partValid &&
      !this.state.issueDescriptionValid &&
      !this.state.quantityValid &&
      !this.state.estUnitWeightValid &&
      !this.state.estTotalWeightValid &&
      !this.state.orderDateValid &&
      !this.state.stocktoDistributorDateValid &&
      !this.state.deliveryMethodValid &&
      !this.state.deliveryDetailsValid &&
      !this.state.companyNameValid &&
      !this.state.recipientNameValid &&
      !this.state.departmentValid &&
      !this.state.addressLine1Valid &&
      !this.state.addressLine2Valid &&
      !this.state.addressLine3Valid &&
      !this.state.cityValid &&
      !this.state.stateValid &&
      !this.state.postCodeValid &&
      !this.state.countryValid &&
      !this.state.countryCodeValid &&
      !this.state.areaCodeValid &&
      !this.state.numberValid &&
      !this.state.trackingNumberValid &&
      !this.state.actualWeightValid &&
      !this.state.deliveryDateValid
    ) {
      return true;
    }

    if (!this.state.formValid) return true;
  }

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  onBlur = (event) => {
    // const { dhlRateValues } = this.state;
    let emloValues = event.target.value;
    emloValues = this.addZeroes(emloValues.toString(), 3);
    this.setState({ [event.target.name]: emloValues });
  };

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  handleDateChange = (date, name) => {
    let k = {
      ...this.state.emloData,
      orderDate: date,
      stocktoDistributorDate: date,
      deliveryDate: date,
    };
    this.setState({ emloData: k });
  };

  render() {
    let { classes } = this.props;
    let { emloData } = this.state;
    let acnArr = this.props.roles.filter((u) => u.menuName === "EMLO Order Placement");
    let acnObj = acnArr && acnArr.length > 0 && acnArr[0];
    let selectedEmloId = this.props.history.location.pathname.split("/")[2];
    return (
      <React.Fragment>
        <Paper>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 8px 0px 16px",
            }}
          >
            <div style={{ width: "100%", textAlign: "right" }}>
              {/* <Tooltip title="Edit">
                <Link to={`/edit_pubEmloOrderPlacement/${selectedEmloId}`}>
                  <IconButton aria-label="edit" className={classes.iconHover}>
                    <EditIcon />
                  </IconButton>
                </Link>
              </Tooltip> */}

              {acnObj && acnObj.printAction && <Tooltip
                title="Print"
                style={{
                  padding: "16px 9px 8px 15px",
                  marginTop: "0px",
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
              >
                <IconButton
                  aria-label="print"
                  className={classes.iconHover}
                  onClick={(e) => printDetails()}
                >
                  <PrintIcon />
                </IconButton>
              </Tooltip>}
            </div>
          </div>
          <Divider />
          <div
            className="view-page-container"
            id="printable"
            style={{ marginTop: "15px" }}
          >
            <div>
              <div className="view-page-headings">Journal Details</div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Acronym</div>
                  <div className="view-page-value">
                    {emloData.acronym.trim()}
                  </div>
                </div>

                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Volume</div>
                  <div className="view-page-value">
                    {emloData.volume.trim()}
                  </div>
                </div>

                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Issue</div>
                  <div className="view-page-value">{emloData.issue.trim()}</div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Supplement</div>
                  <div className="view-page-value">{emloData.support}</div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Part</div>
                  <div className="view-page-value">{emloData.part.trim()}</div>
                </div>

                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Issue Description</div>
                  <div className="view-page-value">
                    {emloData.issueDescription}
                  </div>
                </div>
              </div>
              <div className="view-page-headings">EMLO Details</div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Emlo#</div>
                  <div className="view-page-value">{emloData.emloNo}</div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Order Created By</div>
                  <div className="view-page-value">
                    {emloData.orderCreatedBy}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Order Date</div>
                  <div className="view-page-value">
                    {emloData.orderDate ? moment(emloData.orderDate).format("DD-MM-YYYY") : ""}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Distributor Date</div>
                  <div className="view-page-value">
                    {emloData.stocktoDistributorDate ? moment(emloData.stocktoDistributorDate).format(
                      "DD-MM-YYYY"
                    ) : ""}
                  </div>
                </div>
              </div>

              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Department</div>
                  <div className="view-page-value">{emloData.department}</div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Issue Type</div>
                  <div className="view-page-value">{emloData.issueType}</div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Category</div>
                  <div className="view-page-value">{emloData.category}</div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Quantity</div>
                  <div className="view-page-value">{emloData.quantity}</div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Delivery Method</div>
                  <div className="view-page-value">
                    {emloData.deliveryMethod}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Delivery Details</div>
                  <div className="view-page-value">
                    {emloData.deliveryDetails.trim()}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Company Name</div>
                  <div className="view-page-value">{emloData.companyName}</div>
                </div>

                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Recipient Name</div>
                  <div className="view-page-value">
                    {emloData.recipientName}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Country</div>
                  <div className="view-page-value">{emloData.countryName}</div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">CountryCode</div>
                  <div className="view-page-value">
                    {emloData.countryCode}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">State</div>
                  <div className="view-page-value">{emloData.state}</div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">City</div>
                  <div className="view-page-value">{emloData.city}</div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">AddressLine1</div>
                  <div className="view-page-value">{emloData.addressLine1}</div>
                </div>

                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">AddressLine2</div>
                  <div className="view-page-value">{emloData.addressLine2}</div>
                </div>

                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">AddressLine3</div>
                  <div className="view-page-value">{emloData.addressLine3}</div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">PostCode</div>
                  <div className="view-page-value">{emloData.postCode}</div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">AreaCode</div>
                  <div className="view-page-value">
                    {emloData.areaCode.trim()}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Dept</div>
                  <div className="view-page-value">{emloData.dept}</div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Phone Number</div>
                  <div className="view-page-value">{emloData.number}</div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Additional Instructions</div>
                  <div className="view-page-value">{emloData.additionalInstructions}</div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Estd Unit Weight</div>
                  <div className="view-page-value">
                    {emloData.estUnitWeight}
                  </div>
                </div>

                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Estd Total Weight</div>
                  <div className="view-page-value">
                    {emloData.estTotalWeight}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Uploaded Files</div>
                  <div className="view-page-value">
                    {emloData.uploadedFiles}
                  </div>
                </div>
              </div>
              <div className="view-page-headings">Actual Info</div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Actual Weight</div>
                  <div className="view-page-value">
                    {emloData.actualWeight ? emloData.actualWeight : "0.000"}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Tracking Number</div>
                  <div className="view-page-value">
                    {emloData.trackingNumber}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">No Of Cartons</div>
                  <div className="view-page-value">
                    {emloData.noOfCartons}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Delivery Date</div>
                  <div className="view-page-value">
                    {emloData.deliveryDate &&
                      moment(emloData.deliveryDate).format("DD-MM-YYYY")}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">DHL Rate</div>
                  <div className="view-page-value">
                    {emloData.dhlRate}
                  </div>
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "25px" }} />
            <div
              className="button-wrapper"
              style={{ paddingLeft: "0px", marginLeft: "0px" }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) =>
                  this.props.history.push("/pubEmloOrderPlacement")
                }
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(ViewEmloOrderPlacement));
