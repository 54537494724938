import React, { Component } from "react";
import { Paper, Divider } from "@material-ui/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Tooltip, IconButton, Button } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import { useToolbarStyles } from "../../../shared/styles";
import { withRouter } from "react-router-dom";
import GetAppIcon from "@material-ui/icons/GetApp";
import { StateContext } from "../../../shared/globalState";
import { Link } from "react-router-dom";
import "../../app/App.css";
import {
  printDetails,
  pdfDownloadDetails,
} from "../../../shared/tableCommonFunctions";
import { EditIcon } from "../../../shared/customIcons";
import { getPreAlertByID } from "./action";
import moment from "moment";

const ViewHeader = (props) => {
  let acnArr = props.roles.filter((u) => u.menuName === "Pre Alert");
  let acnObj = acnArr[0];
  const classes = useToolbarStyles();
  console.log(props)
  return (
    <div className="view-page-header">
      {acnObj && acnObj.editAction && props.status !== "Cancelled" &&
        props.status !== "Processed" && (
          // <Link to={`/edit_journalItem/${orderId}`}>
          <Link to={`/edit_preAlert/${props.orderId}`}>
            <IconButton aria-label="edit" className={classes.iconHover}>
              <EditIcon />
            </IconButton>
          </Link>
        )}
      {acnObj && acnObj.printAction && (
        <Tooltip title="Print" style={{ paddingTop: "12px" }}>
          <IconButton
            //aria-label="clear"
            className={classes.iconHover}
            onClick={(e) => printDetails()}
          >
            <PrintIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

class ViewJournalItemMaster extends Component {
  state = {
    selectedOrder: {},
    stateFiles: [],
    client: "",
    location: "",
    preAlertDate: "",
    office: "",
    acronym: "",
    title: "",
    statusID: "",
    volume: "",
    issue: "",
    suppNumber: "",
    issueDescription: "",
    invoiceNos: "",
    totalInvoiceAmt: "",
    status: "",
    printRun: "",
    processedQuantity: "",
    wpc: "",
    totalWeight: "",
    totalPallet: "",
    newWeight: "",
    thickness: "",
    catagory: "",
    version: "",
    safeOrUnsafe: "",
    inserted: "",
    cover: "",
    isOnHoldJRReport: "",
    isJRRGenerated: "",
    exceptionMessage: "",
    createdDate: "",
    createdName: "",
    modifiedDate: "",
    modifiedByName: "",
  };
  componentDidMount() {
    this.props.handleSettingHeader("Pre Alert");
    this.props.setSelectedList("publisherMainLabelOpen", "pubPreAlert");

    let preAlertId =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];

    getPreAlertByID(preAlertId).then((r) => {
      // dispatch({
      //   type: "getPreAlert",
      //   newStore: r.data,
      // });
      this.setState({
        client: r.data.client,
        location: r.data.locationName,
        preAlertDate: r.data.preAlertDate,
        office: r.data.office,
        acronym: r.data.acronym,
        title: r.data.title,
        statusID: r.data.statusID,
        volume: r.data.volume,
        issue: r.data.issue,
        suppNumber: r.data.suppNumber,
        issueDescription: r.data.issueDescription,
        invoiceNos: r.data.invoiceNos,
        totalInvoiceAmt: r.data.totalInvoiceAmt,
        status: r.data.status,
        printRun: r.data.printRun,
        processedQuantity: r.data.processedQuantity,
        wpc: r.data.wpc,
        totalWeight: r.data.totalWeight,
        totalPallet: r.data.totalPallet,
        newWeight: r.data.newWeight,
        thickness: r.data.thickness,
        catagory: r.data.catagory,
        version: r.data.version,
        safeOrUnsafe: r.data.safeOrUnsafe,
        inserted: r.data.inserted,
        cover: r.data.cover,
        isOnHoldJRReport: r.data.isOnHoldJRReport,
        isJRRGenerated: r.data.isJRRGenerated,
        exceptionMessage: r.data.exceptionMessage,
        createdDate: r.data.createdDate,
        createdName: r.data.createdName,
        modifiedDate: r.data.modifiedDate,
        modifiedByName: r.data.modifiedByName,
      });
    });
  }
  handleCancel = () => {
    this.props.history.push("/pubPreAlert");
  };
  render() {
    let { selectedOrder } = this.state;
    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    return (
      <div>
        <Paper id="convertToPdf">
          <ViewHeader
            history={this.props.history}
            orderId={orderId}
            roles={this.props.roles}
            status={this.state.status}
          />
          <div
            className={
              this.props.clearOpenList
                ? "view-page-container"
                : "view-page-container-wide"
            }
            id="printable"
          >
            <div
              className={
                this.props.clearOpenList
                  ? "view-page-headings"
                  : "view-page-headings-wide"
              }
            >
              Pre Alert Info
            </div>
            <div
              className={
                this.props.clearOpenList
                  ? "view-page-contents-container"
                  : "view-page-contents-container-wide"
              }
              id={
                this.props.clearOpenList
                  ? "view-page-contents-container"
                  : "view-page-contents-container-wide"
              }
            >
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Customer</div>
                <div className="view-page-value">{this.state.client}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Location</div>
                <div className="view-page-value">{this.state.location}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Office</div>
                <div className="view-page-value">{this.state.office}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Acronym</div>
                <div className="view-page-value">{this.state.acronym}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Volume</div>
                <div className="view-page-value">{this.state.volume}</div>
              </div>

              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Issue No.</div>
                <div className="view-page-value">{this.state.issue}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Supplement No.</div>
                <div className="view-page-value">{this.state.suppNumber}</div>
              </div>

              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Issue Description</div>
                <div className="view-page-value">{this.state.issueDescription}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Invoice Number</div>
                <div className="view-page-value">{this.state.invoiceNos}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Invoice Amount</div>
                <div className="view-page-value">
                  {this.state.totalInvoiceAmt}
                </div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Status</div>
                <div className="view-page-value">{this.state.status}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Print Run</div>
                <div className="view-page-value">{this.state.printRun}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Processed Quantity</div>
                <div className="view-page-value">
                  {this.state.processedQuantity}
                </div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">WPC (Kgs)</div>
                <div className="view-page-value">{this.state.wpc}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Total Weight (Kgs)</div>
                <div className="view-page-value">{this.state.totalWeight}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Total Pallet</div>
                <div className="view-page-value">{this.state.totalPallet}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">New Weight (Kgs)</div>
                <div className="view-page-value">{this.state.newWeight}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Thickness</div>
                <div className="view-page-value">{this.state.thickness}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Category</div>
                <div className="view-page-value">{this.state.catagory}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Version</div>
                <div className="view-page-value">{this.state.version}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Safe/Unsafe</div>
                <div className="view-page-value">{this.state.safeOrUnsafe}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Insert</div>
                <div className="view-page-value">{this.state.inserted}</div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "33.33%", marginBottom: "1em" }}
              >
                <div className="view-page-label">Cover</div>
                <div className="view-page-value">{this.state.cover}</div>
              </div>
            </div>
            {/* .......................................................... */}

            <div>
              <div className="view-page-headings" style={{ marginTop: "2em" }}>
                Others
              </div>
              <div
                className={
                  this.props.clearOpenList
                    ? "view-page-contents-container"
                    : "view-page-contents-container-wide"
                }
              >
                <div
                  className="view-page-contents-sub-container"
                  style={{
                    width: "33.33%",
                    marginBottom: "1em",
                    marginBottom: "0.8em",
                  }}
                >
                  <div className="view-page-label">Created By</div>
                  <div className="view-page-value">
                    {this.state.createdName}
                  </div>
                </div>
                <div
                  className="view-page-contents-sub-container"
                  style={{ width: "33.33%", marginBottom: "1em" }}
                >
                  <div className="view-page-label">Created Date</div>
                  <div className="view-page-value">
                    {moment(this.state.createdDate).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </div>
                </div>
                <div
                  className="view-page-contents-sub-container"
                  style={{ width: "33.33%", marginBottom: "1em" }}
                >
                  <div className="view-page-label">Modified By</div>
                  <div className="view-page-value">
                    {this.state.modifiedByName}
                  </div>
                </div>
                <div
                  className="view-page-contents-sub-container"
                  style={{ width: "33.33%", marginBottom: "1em" }}
                >
                  <div className="view-page-label">Modified Date</div>
                  <div className="view-page-value">
                    {moment(this.state.modifiedDate).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Divider />
          </div>
          <div className="button-wrapper" style={{ padding: "0 2em 1em 2em" }}>
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.handleCancel()}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}
export default withRouter(ViewJournalItemMaster);
