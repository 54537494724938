import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {
  postProcess,
  getProcessCategoryList,
  getUOMPMList,
} from "../masterActions";
import Validator from "../../../shared/validator";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { resourceValidation } from "../../../shared/resource";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Autocomplete } from "@material-ui/lab";
import {
  getAcronymDetails,
  updateAcronym,
  getProcessDetails,
  updateProcess,
} from "../masterActions";
import { StateContext } from "../../../shared/globalState";
import { addStyles } from "../../../shared/styles";

const validateMaxlength4to10 = new Validator("maxlength2to10");
const notRequired100 = new Validator("notRequired100");
const Alphanumeric = new Validator("Alphanumeric");
let oWCRVal = 0
class EditProcess extends Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      update: false,
      process: {
        processID: "",
        categoryCode: "",
        processCategoryID: "",
        categoryName: "",
        processName: "",
        processDescription: "",
        isWOProcess: true,
        isActive: true,
        acct: "",
        pic: "",
        rc: "",
        gstType: true,
        uom: "",

        price: "0.00",
        outWorkerClaimRate: "0.00",
        updatedValues: false,
      },
      errors: {},
      selectedUOM: 0,
      selectedProcess: 0,
      active: false,
      processIDValid: false,
      processCategoryIDValid: false,
      categoryCodeValid: false,
      processNameValid: false,
      acctValid: false,
      picValid: false,
      rcValid: false,
      gstTypeValid: false,
      uomList: [],
      processList: [],
      processCategoryList: [],
      processCategoryObj: {},
      uomObj: {},

      processID: "",
      uomValid: false,
      priceValid: false,
      outWorkerClaimRateValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let processID = this.props.history.location.pathname.split("/")[2];
    getUOMPMList().then((r) => {
      this.setState({
        uomObj: r.data,
      });
      this.setState({
        uomList:
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });

    getProcessDetails(processID).then((r) => {
      let newObj = {
        ...r,
        statusCk: r.isActive,
        categoryName: {
          id: r.processCategoryID,
          text: r.categoryName,
          value: r.categoryCode,
        },
      };
      this.setState({ process: newObj });

      this.validateField("processCategoryID", r.processCategoryID);
      this.validateField("processName", r.processName);
      this.validateField("processDescription", r.processDescription);
      this.validateField("isWOProcess", r.isWOProcess);
      this.validateField("acct", r.acct);
      this.validateField("pic", r.pic);
      this.validateField("rc", r.rc);
      this.validateField("gstType", r.gstType);
      this.validateField("uom", r.uom);
      this.validateField("price", r.price);
      this.validateField("outWorkerClaimRate", r.outWorkerClaimRate);
      oWCRVal = r.outWorkerClaimRate
      getProcessCategoryList(window.localStorage.token).then((pcr) => {
        this.setState({
          processCategoryList:
            pcr &&
            pcr.sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
      });
    });
  }
  handleUomChange = (event, val) => {
    const { process } = this.state;
    if (val) {
      process["uomName"] = val.text;
      process["uom"] = val.id;

      this.setState({ process, update: true });
    } else {
      process["uomName"] = "";
      process["uom"] = "";

      this.setState({ process, update: true });
    }
  };

  handleChange = (event) => {
    const { process } = this.state;
    if (
      event.target.name === "isWOProcess" ||
      event.target.name === "isActive" ||
      event.target.name === "gstType"
    ) {
      process[event.target.name] = event.target.checked;
    } else {
      process[event.target.name] = event.target.value;
      this.validateField(event.target.name, event.target.value);
    }
    this.setState({ process, update: true });
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    // processIDValid = this.state.processIDValid;
    let processCategoryIDValid = this.state.processCategoryIDValid;
    //let categoryCodeValid = this.state.categoryCodeValid;
    let processNameValid = this.state.processNameValid;
    let acctValid = this.state.acctValid;
    let picValid = this.state.picValid;
    let rcValid = this.state.rcValid;
    let processList = this.state.processList;
    let uomValid = this.state.uomValid;
    let priceValid = this.state.priceValid;
    let outWorkerClaimRateValid = this.state.outWorkerClaimRateValid;

    switch (fieldName) {
      // case "processCategoryID":
      //   processCategoryIDValid = true;
      //   fieldValidationErrors.processCategoryID = processCategoryIDValid
      //     ? ""
      //     : resourceValidation.onlyNumbers;
      //   break;
      // case "categoryCode":
      //   categoryCodeValid = true;
      //   fieldValidationErrors.categoryCode = categoryCodeValid
      //     ? ""
      //     : resourceValidation.onlyAlphabet;
      //   break;
      case "processName":
        if (value !== "") {
          processNameValid = true;
          fieldValidationErrors.processNameValid = processNameValid
            ? ""
            : resourceValidation.onlyAlphabet;
        } else {
          processNameValid = false;
          fieldValidationErrors.officeName = "Please Enter Mandatory Field";
        }
        break;
      case "acct":
        acctValid = Alphanumeric(value);
        fieldValidationErrors.acct = acctValid
          ? ""
          : resourceValidation.checkdecimalpoint;
        break;
      case "price":
        picValid = true;
        fieldValidationErrors.price = priceValid
          ? ""
          : resourceValidation.checkdecimalpoint;
        break;
      case "rc":
        if (value !== "") {
          rcValid = true;
          fieldValidationErrors.rc = rcValid
            ? ""
            : resourceValidation.Alphanumeric;
          break;
        }
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,

        //processIDValid: processIDValid,
        processCategoryIDValid: processCategoryIDValid,
        //categoryCodeValid: categoryCodeValid,
        processNameValid: processNameValid,
        acctValid: acctValid,
        priceValid: priceValid,
        rcValid: rcValid,
      },
      this.validateForm
    );
  }
  validateForm = () => {
    this.state.updatedValues &&
      this.setState({
        formValid:
          this.state.processCategoryIDValid &&
          this.state.processNameValid &&
          this.state.acctValid &&
          this.state.priceValid &&
          this.state.rcValid,
      });

    return this.state.formValid;
  };

  handleProcessChange = (event, val) => {
    const { process } = this.state;
    let obj = {
      ...process,
      processCategoryID: val && val.id,
      categoryName: val && val,
    };
    this.setState({
      process: obj,
      update: true,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { process } = this.state;
    let processID = process.processID;
    let processCategoryID = process.processCategoryID;
    let categoryCode = process.categoryCode;
    let processName = process.processName;
    let processDescription = process.processDescription;
    let isWOProcess = process.isWOProcess;
    let isActive = process.isActive;
    let acct = process.acct;
    let pic = process.pic;
    let rc = process.rc;
    let gstType = process.gstType;
    let uom = process.uom;
    let price = process.price;

    let outWorkerClaimRate = process.outWorkerClaimRate;

    let reqBody = {
      //   processID,
      processCategoryID,
      categoryCode,
      processName,
      processDescription,
      isWOProcess,
      isActive,
      acct,
      pic,
      rc,
      gstType,
      uom,
      price,
      outWorkerClaimRate,
    };
    reqBody.modifiedBy = localStorage.loggedInId;

    updateProcess(processID, reqBody, this.props.history);
  };
  _onKeyPress(event) {
    const re = /[0-9a-zA-Z ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onAllCharPress(event) {
    const re = /[a-z A-Z0-9=.;(){}\/*%#$@&,-_]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onAlphaKeyPress(event) {
    const re = /[a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onDimensionKeyPress(event) {
    const re = /[0-9*]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  render() {
    let { classes } = this.props;
    console.log(this.state.process.outWorkerClaimRate, oWCRVal)
    let {
      process,
      errors,
      active,
      processCategoryList,
      uomList,
      selectedUOM,
      selectedProcess,
    } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Process</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  {processCategoryList.length > 0 && (
                    <Autocomplete
                      autoComplete
                      includeInputInList
                      style={{ width: "31%", marginRight: "22px" }}
                      margin="normal"
                      value={process.categoryName}
                      options={processCategoryList}
                      onChange={this.handleProcessChange}
                      getOptionLabel={(option) => option.text}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Process Category"
                          margin="normal"
                          style={{ width: "100%" }}
                          className={classes.textField}
                          error={this.state.errors.processCategoryID}
                          helperText={
                            this.state.errors.processCategoryID
                              ? this.state.errors.processCategoryID
                              : ""
                          }
                        />
                      )}
                      onKeyPress={this._onAlphaKeyPress}
                    />
                  )}

                  <TextField
                    required
                    label="Process Name"
                    name="processName"
                    inputProps={{
                      maxLength: 200,
                    }}
                    autoComplete="off"
                    margin="normal"
                    // style={{ width: "100%", margin: "0" }}
                    className={classes.textField}
                    value={process.processName}
                    onChange={this.handleChange}
                    error={this.state.errors.processName}
                    helperText={
                      this.state.errors.processName ? errors.processName : ""
                    }
                    onKeyPress={this._onAllCharPress}
                  />
                </div>
                <div>
                  <TextField
                    //required
                    label="Process Description"
                    name="processDescription"
                    inputProps={{
                      maxLength: 500,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={process.processDescription}
                    onChange={this.handleChange}
                    error={this.state.errors.processDescription}
                    helperText={
                      this.state.errors.processDescription
                        ? errors.processDescription
                        : ""
                    }
                    onKeyPress={this._onAllCharPress}
                  />
                </div>

                <div>
                  <TextField
                    required
                    label="Price"
                    name="price"
                    inputProps={{
                      maxLength: 12,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={process.price}
                    onChange={this.handleChange}
                    onKeyPress={this._onDecimalKeyPress}
                    error={this.state.errors.price}
                    helperText={
                      this.state.errors.price
                        ? resourceValidation.checkdecimalpoint
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$S</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    required
                    label="Out Worker Claim Rate"
                    name="outWorkerClaimRate"
                    inputProps={{
                      maxLength: 12,
                    }}
                    margin="normal"
                    className={classes.textField}
                    onKeyPress={this._onDecimalKeyPress}
                    value={process.outWorkerClaimRate}
                    onChange={this.handleChange}
                    error={this.state.errors.outWorkerClaimRate}
                    helperText={
                      this.state.errors.outWorkerClaimRate
                        ? resourceValidation.max1000char
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$S</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div>
                  <TextField
                    required
                    label="ACCT"
                    name="acct"
                    inputProps={{
                      maxLength: 50,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={process.acct}
                    onChange={this.handleChange}
                    error={this.state.errors.acct}
                    helperText={this.state.errors.acct ? errors.acct : ""}
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    required
                    label="PIC"
                    name="pic"
                    inputProps={{
                      maxLength: 1000,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={process.pic}
                    onChange={this.handleChange}
                    error={this.state.errors.pic}
                    helperText={
                      this.state.errors.pic
                        ? resourceValidation.max1000char
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    required
                    label="RC"
                    name="rc"
                    inputProps={{
                      maxLength: 20,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={process.rc}
                    onChange={this.handleChange}
                    error={this.state.errors.rc}
                    helperText={
                      this.state.errors.rc
                        ? resourceValidation.checkdecimalpoint
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                </div>
                <div>
                  {uomList && uomList.length > 0 && (
                    <Autocomplete
                      //required
                      autoComplete
                      includeInputInList
                      id="uomList"
                      name="uomList"
                      hintText="UOM"
                      value={process.uom}
                      //style={{ width: "95%" }}
                      options={uomList}
                      onChange={this.handleUomChange}
                      getOptionLabel={(option) =>
                        option.text || process.uomName
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="UOM"
                          required
                          name="uomList"
                          id="uomList"
                          margin="normal"
                          style={{ width: "31%", marginLeft: "12px" }}
                          //className={classes.textField}
                          value={this.state.process.uomList}
                        // error={this.state.errors.stockID}
                        // helperText={
                        //   this.state.errors.stockID
                        //     ? this.state.errors.stockID
                        //     : ""
                        />
                      )}
                    />
                  )}
                </div>
                <div>
                  <FormControlLabel
                    className={classes.checkBox}
                    name="isWOProcess"
                    control={
                      <Checkbox
                        color="primary"
                        checked={process.isWOProcess}
                        value={process.isWOProcess}
                        onChange={this.handleChange}
                      />
                    }
                    label="Customised"
                  />
                  {!process.statusCk && <FormControlLabel
                    className={classes.checkBox}
                    name="isActive"
                    control={
                      <Checkbox
                        color="primary"
                        checked={process.isActive}
                        value={process.isActive}
                        onChange={this.handleChange}
                      // disabled={process.isActive}
                      />
                    }
                    label="Is Active"
                  />}
                  <FormControlLabel
                    className={classes.checkBox}
                    name="gstType"
                    control={
                      <Checkbox
                        color="primary"
                        checked={process.gstType}
                        value={process.gstType}
                        onChange={this.handleChange}
                      />
                    }
                    // label={process.gstType ? "Inclusive" : "Exclusive"}
                    label="Inclusive"
                  />
                </div>
                {/* {active ? (
                  <React.Fragment />
                ) : (
                  <FormControlLabel
                    className={classes.checkBox}
                    margin="normal"
                    name="isActive"
                    control={
                      <Checkbox
                        color="primary"
                        checked={process.isActive}
                        value={process.isActive}
                        onChange={this.handleChange}
                      />
                    }
                    label="Active"
                  />
                )} */}

                <Divider style={{ marginTop: "25px" }} />
                <div className="button-wrapper">
                  {this.state.loading ? (
                    ""
                  ) : (
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                          onClick={(e) =>
                            this.props.history.push("/processMaster")
                          }
                        >
                          CANCEL
                      </Button>
                      </div>
                    )}
                  <div style={{ marginLeft: "1em" }}>
                    {/* {(oWCRVal == this.state.process.outWorkerClaimRate) ? true : !this.state.update ||
                      !this.state.process.processCategoryID ||
                      !this.state.process.processName ||
                      //!this.state.process.processDescription ||
                      !this.state.process.acct ||
                      !this.state.process.price ||
                      !this.state.process.uom ||
                      !this.state.process.pic ||
                      !this.state.process.rc
                      ? ( */}

                    <Button
                      onClick={this.handleSubmit}
                      disabled={
                        // (oWCRVal == this.state.process.outWorkerClaimRate) ? true : 
                        !this.state.update ||
                        !this.state.process.processCategoryID ||
                        !this.state.process.processName ||
                        //!this.state.process.processDescription ||
                        this.state.process.outWorkerClaimRate === "" ||
                        !this.state.process.acct ||
                        this.state.process.price === "" ||
                        !this.state.process.uom ||
                        !this.state.process.pic ||
                        !this.state.process.rc}
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                    >
                      SUBMIT
                        </Button>


                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditProcess));
