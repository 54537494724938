import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import clone from "clone";
import SyncIcon from "@material-ui/icons/Sync";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";

import { printDetails } from "../../../shared/tableCommonFunctions";
//import { postRunMailList } from "../reportServiceAction";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Autocomplete } from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import { Checkbox, Tooltip } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import {
  EditIcon,
  AddIcon,
  CustomPrintIcon,
  CustomAddIcon,
  PrintIcon,
} from "../../../shared/customIcons";
import IconButton from "@material-ui/core/IconButton";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import TimeFnsUtils from "@date-io/date-fns";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
// import WorkOrderModal from "../docketHeader/workOrderModel";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { getZoneList, getProvinceList } from "../../masters/masterActions";
import { getCountryDropdownList } from "../reportServiceAction";
import { getMailSizeList, getRunMailList } from "../genericDocketAction";
import { getDocketList, getDocketHeaderDetails } from "./actions";
import moment from "moment";

const validateNotRequired100 = new Validator("maxlength100AllAllow");
const validatePhoneNo = new Validator("mobile");
const validateZipCode = new Validator("pincode");
const onlyNumbers = new Validator("onlyNumbers");

const headCells = [
  {
    id: 1,
    code: "fileName",
    numeric: false,
    disablePadding: true,
    label: "File Name",
    width: "auto",
  },
  {
    id: 2,
    code: "zone",
    numeric: false,
    disablePadding: false,
    label: "Zone",
    width: "auto",
  },
  {
    id: 3,
    code: "country",
    numeric: false,
    disablePadding: false,
    label: "Country",
    width: "auto",
  },
  // {
  //   id: 4,
  //   code: "province",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Province",
  //   width: "auto",
  // },
  {
    id: 4,
    code: "copies",
    numeric: false,
    disablePadding: false,
    label: "Copies",
    width: "auto",
  },
  {
    id: 5,
    code: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
    width: "auto",
  },
  // {
  //   id: 7,
  //   code: "totalOrderQuantity",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Tot Order Qty",
  //   width: "auto",
  // },
  // {
  //   id: 8,
  //   code: "remarks",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Remarks",
  //   width: "auto",
  // },
  {
    id: 6,
    code: "routing",
    numeric: false,
    disablePadding: false,
    label: "Routing",
    width: "auto",
  },
  // {
  //   id: 10,
  //   code: "cop",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "COP",
  //   width: "auto",
  // },
  {
    id: 7,
    code: "isBM1500Countries",
    numeric: false,
    disablePadding: false,
    label: "BM 1500 Countries",
    width: "auto",
  },
  // {
  //   id: 9,
  //   code: "mailSize",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Mail Size",
  //   width: "auto"
  // },
  {
    id: 8,
    code: "weightPerItem",
    numeric: false,
    disablePadding: false,
    label: "Wt per Item(gm)",
    width: "auto",
  },
  {
    id: 9,
    code: "noOfArticles",
    numeric: false,
    disablePadding: false,
    label: "No of Articles",
    width: "auto",
  },
  // {
  //   id: 12,
  //   code: "noOfBag",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "No of Bag(kg)",
  //   width: "auto"
  // },
  {
    id: 10,
    code: "totalWeight",
    numeric: false,
    disablePadding: false,
    label: "Total Chargeable Wt(kg)",
    width: "auto",
  },
  {
    id: 11,
    code: "postageCharges",
    numeric: false,
    disablePadding: false,
    label: "Postage Charges",
    width: "auto",
  },
  {
    id: 12,
    code: "totalCharge",
    numeric: false,
    disablePadding: false,
    label: "Total Charge",
    width: "auto",
  },
];

const headCellslocal = [
  {
    id: 1,
    code: "fileName",
    numeric: false,
    disablePadding: true,
    label: "File Name",
    width: "auto",
  },
  // {
  //   id: 2,
  //   code: "zone",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Zone",
  //   width: "auto"
  // },
  {
    id: 2,
    code: "country",
    numeric: false,
    disablePadding: false,
    label: "Country",
    width: "auto",
  },
  // {
  //   id: 3,
  //   code: "province",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Province",
  //   width: "auto",
  // },
  {
    id: 3,
    code: "copies",
    numeric: false,
    disablePadding: false,
    label: "Copies",
    width: "auto",
  },
  {
    id: 4,
    code: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
    width: "auto",
  },
  // {
  //   id: 5,
  //   code: "totalOrderQuantity",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Tot Order Qty",
  //   width: "auto",
  // },
  // {
  //   id: 6,
  //   code: "remarks",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Remarks",
  //   width: "auto",
  // },
  {
    id: 5,
    code: "routing",
    numeric: false,
    disablePadding: false,
    label: "Routing",
    width: "auto",
  },
  // {
  //   id: 8,
  //   code: "cop",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "COP",
  //   width: "auto",
  // },
  {
    id: 6,
    code: "mailSize",
    numeric: false,
    disablePadding: false,
    label: "Mail Size",
    width: "auto",
  },
  {
    id: 7,
    code: "weightPerItem",
    numeric: false,
    disablePadding: false,
    label: "Wt per Item(gm)",
    width: "auto",
  },
  {
    id: 8,
    code: "noOfArticles",
    numeric: false,
    disablePadding: false,
    label: "No of Articles",
    width: "auto",
  },

  // {
  //   id: 12,
  //   code: "noOfBag",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "No of Bag(kg)",
  //   width: "auto"
  // },
  {
    id: 9,
    code: "totalWeight",
    numeric: false,
    disablePadding: false,
    label: "Total Chargeable Wt(kg)",
    width: "auto",
  },
  {
    id: 10,
    code: "postageCharges",
    numeric: false,
    disablePadding: false,
    label: "Postage Charges",
    width: "auto",
  },
  {
    id: 11,
    code: "totalCharges",
    numeric: false,
    disablePadding: false,
    label: "Total Charges",
    width: "auto",
  },
];

function createData(
  docketHeaderID,
  fileName,
  zone,
  country,
  province,
  copies,
  quantity,
  totalOrderQuantity,
  remarks,
  routing,
  //pgsDocketNumber,
  cop,
  isBM1500Countries,
  // mailSize,
  //noOfBags,
  //weightPerBag,
  weightPerItem,
  noOfArticles,
  totalWeight,
  postageCharges,
  totalCharges,
  isActive
) {
  return {
    docketHeaderID,
    fileName,
    zone,
    country,
    province,
    copies,
    quantity,
    totalOrderQuantity,
    remarks,
    routing,
    //pgsDocketNumber,
    cop,
    isBM1500Countries,
    // mailSize,
    //noOfBags,
    //weightPerBag,
    weightPerItem,
    noOfArticles,
    totalWeight,
    postageCharges,
    totalCharges,
    isActive,
  };
}
function createDatalocal(
  docketHeaderID,
  fileName,
  // zone,
  country,
  province,
  copies,
  quantity,
  totalOrderQuantity,
  remarks,
  routing,
  //pgsDocketNumber,
  cop,
  mailSize,
  //noOfBags,
  //weightPerBag,
  weightPerItem,
  noOfArticles,
  totalWeight,
  postageCharges,
  totalCharges,
  isActive
) {
  return {
    docketHeaderID,
    fileName,
    // zone,
    country,
    province,
    copies,
    quantity,
    totalOrderQuantity,
    remarks,
    routing,
    //pgsDocketNumber,
    cop,
    mailSize,
    //noOfBags,
    //weightPerBag,
    weightPerItem,
    noOfArticles,
    totalWeight,
    postageCharges,
    totalCharges,
    isActive,
  };
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "31%",
    fontSize: "11px !important",
  },
  autocompleteText: {
    marginRight: theme.spacing(2),
    width: "100%",
    fontSize: "11px !important",
  },
  autocomplete: {
    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
});

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderLeft: "1px sold #000", width: `${headCell.width}` }}
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function EnhancedTableHeadlocal(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  return (
    <TableHead>
      <TableRow>
        {headCellslocal.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderLeft: "1px sold #000", width: `${headCell.width}` }}
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    //borderTop: "1px solid #eaeaea"
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let acnArr = props.roles.filter((u) => u.menuName === "Docket Posting");
  let acnObj = acnArr[0];

  return (
    <div className={classes.root}>
      {/* <div
        style={{
          display: "flex",
          width: "100%"
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Tooltip title="Print" style={{ padding: "5px", paddingTop: "12px" }}>
            <IconButton
              aria-label="print"
              style={{}}
              className={classes.iconHover}
              onClick={e => printDetails()}
            >
              <PrintIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div> */}
    </div>
  );
};
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const EnhancedTableToolbar1 = (props) => {
  const classes = useToolbarStyles();
  const { } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let acnArr = props.roles.filter((u) => u.menuName === "Docket Posting");
  let acnObj = acnArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                style={{}}
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};
EnhancedTableToolbar1.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

class ViewDocketPosting extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      docketHeader: {
        docketHeaderID: "",
        customerName: "",
        workOrderNumber: "",
        title: "",
        docketHeaderNumber: "",
        pgsDocketNO: "",
        eDocketNO: "",
        status: "",
        modifiedBy: "",
        modifiedDate: "",
      },
      docketHeaderDetails: [],
      page: 0,
      rowsPerPage: 100,
      refreshData: false,
      loading: false,
      order: "desc",
      orderBy: "modifiedDate",
      headCells: [
        {
          id: 1,
          code: "fileName",
          numeric: false,
          disablePadding: true,
          label: "File Name",
          width: "auto",
        },
        {
          id: 2,
          code: "zone",
          numeric: false,
          disablePadding: false,
          label: "Zone",
          width: "auto",
        },
        {
          id: 3,
          code: "country",
          numeric: false,
          disablePadding: false,
          label: "Country",
          width: "auto",
        },
        {
          id: 4,
          code: "province",
          numeric: false,
          disablePadding: false,
          label: "Province",
          width: "auto",
        },
        {
          id: 5,
          code: "copies",
          numeric: false,
          disablePadding: false,
          label: "Copies",
          width: "auto",
        },
        {
          id: 6,
          code: "quantity",
          numeric: false,
          disablePadding: false,
          label: "Quantity",
          width: "auto",
        },
        {
          id: 7,
          code: "totalOrderQuantity",
          numeric: false,
          disablePadding: false,
          label: "Tot Order Qty",
          width: "auto",
        },
        {
          id: 8,
          code: "remarks",
          numeric: false,
          disablePadding: false,
          label: "Remarks",
          width: "auto",
        },
        {
          id: 9,
          code: "routing",
          numeric: false,
          disablePadding: false,
          label: "Routing",
          width: "auto",
        },
        {
          id: 10,
          code: "cop",
          numeric: false,
          disablePadding: false,
          label: "COP",
          width: "auto",
        },
        {
          id: 11,
          code: "isBM1500Countries",
          numeric: false,
          disablePadding: false,
          label: "COP",
          width: "BM 1500 Countries",
        },
        // {
        //   id: 9,
        //   code: "mailSize",
        //   numeric: false,
        //   disablePadding: false,
        //   label: "Mail Size",
        //   width: "auto"
        // },
        {
          id: 12,
          code: "weightPerItem",
          numeric: false,
          disablePadding: false,
          label: "Wt per Item(gm)",
          width: "auto",
        },
        {
          id: 13,
          code: "noOfArticles",
          numeric: false,
          disablePadding: false,
          label: "No of Articles",
          width: "auto",
        },
        // {
        //   id: 12,
        //   code: "noOfBag",
        //   numeric: false,
        //   disablePadding: false,
        //   label: "No of Bag(kg)",
        //   width: "auto"
        // },
        {
          id: 14,
          code: "totalWeight",
          numeric: false,
          disablePadding: false,
          label: "Total Chargeable Wt(kg)",
          width: "auto",
        },
        {
          id: 15,
          code: "postageCharges",
          numeric: false,
          disablePadding: false,
          label: "Postage Charges",
          width: "auto",
        },
        {
          id: 16,
          code: "totalCharge",
          numeric: false,
          disablePadding: false,
          label: "Total Charge",
          width: "auto",
        },
      ],
      headCellslocal: [
        {
          id: 1,
          code: "fileName",
          numeric: false,
          disablePadding: true,
          label: "File Name",
          width: "auto",
        },
        // {
        //   id: 2,
        //   code: "zone",
        //   numeric: false,
        //   disablePadding: false,
        //   label: "Zone",
        //   width: "auto"
        // },
        {
          id: 2,
          code: "country",
          numeric: false,
          disablePadding: false,
          label: "Country",
          width: "auto",
        },
        {
          id: 3,
          code: "province",
          numeric: false,
          disablePadding: false,
          label: "Province",
          width: "auto",
        },
        {
          id: 3,
          code: "copies",
          numeric: false,
          disablePadding: false,
          label: "Copies",
          width: "auto",
        },
        {
          id: 4,
          code: "quantity",
          numeric: false,
          disablePadding: false,
          label: "Quantity",
          width: "auto",
        },
        {
          id: 5,
          code: "totalOrderQuantity",
          numeric: false,
          disablePadding: false,
          label: "Tot Order Qty",
          width: "auto",
        },
        {
          id: 6,
          code: "remarks",
          numeric: false,
          disablePadding: false,
          label: "Remarks",
          width: "auto",
        },
        {
          id: 7,
          code: "routing",
          numeric: false,
          disablePadding: false,
          label: "Routing",
          width: "auto",
        },
        {
          id: 8,
          code: "cop",
          numeric: false,
          disablePadding: false,
          label: "COP",
          width: "auto",
        },
        {
          id: 9,
          code: "mailSize",
          numeric: false,
          disablePadding: false,
          label: "Mail Size",
          width: "auto",
        },
        {
          id: 10,
          code: "weightPerItem",
          numeric: false,
          disablePadding: false,
          label: "Wt per Item(gm)",
          width: "auto",
        },
        {
          id: 11,
          code: "noOfArticles",
          numeric: false,
          disablePadding: false,
          label: "No of Articles",
          width: "auto",
        },

        // {
        //   id: 12,
        //   code: "noOfBag",
        //   numeric: false,
        //   disablePadding: false,
        //   label: "No of Bag(kg)",
        //   width: "auto"
        // },
        {
          id: 12,
          code: "totalWeight",
          numeric: false,
          disablePadding: false,
          label: "Total Chargeable Wt(kg)",
          width: "auto",
        },
        {
          id: 13,
          code: "postageCharges",
          numeric: false,
          disablePadding: false,
          label: "Postage Charges",
          width: "auto",
        },
        {
          id: 14,
          code: "totalCharges",
          numeric: false,
          disablePadding: false,
          label: "Total Charges",
          width: "auto",
        },
      ],
    };
  }
  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Docket Posting");
    this.props.setSelectedList("genericSystemOpen", "docketPosting");

    let docketHeaderID = this.props.history.location.pathname.split(
      "/view_docketPosting/"
    )[1];
    this.setState({ disableEdit: true });
    getDocketList(docketHeaderID).then((r) => {
      dispatch({
        type: "docketHeaderData",
        newStore: r,
      });
      this.setState({
        docketHeader: r,
      });
      this.getAllDocketHeaderDetails(r);
    });
  }

  getAllDocketHeaderDetails = (list) => {
    this.setState({ docketHeaderDetails: list.runmailListViewModal });
  };

  addZeroes(num, decimalPlaces) {
    var value = Number(num);
    var res = num.split(".");
    if (res.length === 1 || res[1].length < decimalPlaces) {
      value = value.toFixed(decimalPlaces);
    }
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  render() {
    let { classes } = this.props;
    let {
      docketHeader,
      docketHeaderDetails,
      selected,
      order,
      orderBy,
      editableDocketDetailList,
      workOrder,
    } = this.state;
    // const isSelected = id => selected.indexOf(id) !== -1;

    let docketHeaderID = this.props.history.location.pathname.split(
      "/view_docketPosting/"
    )[1];

    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar1 roles={this.props.roles} />
          <Divider />
          <div id="printable" className="container">
            <div
              className="pop-up-header"
              style={{
                marginBottom: ".2em",
                paddingTop: ".em",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: "500",
                paddingLeft: "5px",
              }}
            >
              View Docket
            </div>{" "}
            <Divider
              style={{
                margin: "0px 19px 0px 32px",
                borderBottom: ".1px solid #c1baba",
              }}
            />
            <div style={{ margin: "1em" }}>
              <div
                className={classes.container}
                style={{ marginBottom: "2px", marginTop: "2px" }}
              >
                <Typography component="div" noWrap className="blueBox">
                  <div className="blueBoxLabel">
                    <div>Customer: </div>
                    <div className="blueBoxText">
                      {docketHeader.customerName}
                    </div>
                  </div>
                  <div className="blueBoxLabel">
                    <div>Work Order: </div>
                    <div className="blueBoxText">
                      {docketHeader.workOrderNumber}
                    </div>
                  </div>
                  <div className="blueBoxLabel">
                    <div>Project Title: </div>
                    <div className="blueBoxText">
                      {docketHeader.projectTitle}
                    </div>
                  </div>
                </Typography>
              </div>
              <div
                className={classes.container}
                style={{ marginBottom: "2px", marginTop: "2px" }}
              >
                <Typography
                  component="div"
                  noWrap
                  component="div"
                  noWrap
                  className="blueBox"
                >
                  <div className="blueBoxLabel">
                    <div>Job Group: </div>
                    <div className="blueBoxText">
                      {docketHeader.jobGroupNumber}
                    </div>
                  </div>
                  <div className="blueBoxLabel">
                    <div>Planner: </div>
                    <div className="blueBoxText">{docketHeader.planner}</div>
                  </div>
                  <div className="blueBoxLabel">
                    <div>PGS Docket No: </div>
                    <div className="blueBoxText">
                      {docketHeader.pgsDocketNO}
                    </div>
                  </div>
                </Typography>
              </div>
              <div
                className={classes.container}
                style={{ marginBottom: "2px", marginTop: "2px" }}
              >
                <Typography
                  component="div"
                  noWrap
                  component="div"
                  noWrap
                  className="blueBox"
                >
                  <div className="blueBoxLabel">
                    <div>EDS Docket No: </div>
                    <div className="blueBoxText">{docketHeader.eDocketNO}</div>
                  </div>
                  <div className="blueBoxLabel">
                    <div>Status: </div>
                    <div className="blueBoxText">{docketHeader.status}</div>
                  </div>
                  <div className="blueBoxLabel">
                    <div> </div>
                    <div className="blueBoxText"></div>
                  </div>
                </Typography>
              </div>
              <div
                className={classes.container}
                style={{ marginBottom: "30px", marginTop: "2px" }}
              >
                <Typography
                  component="div"
                  noWrap
                  component="div"
                  noWrap
                  className="blueBox"
                >
                  <div className="blueBoxLabel">
                    <div>Modified By: </div>
                    <div className="blueBoxText">{docketHeader.modifiedBy}</div>
                  </div>
                  <div className="blueBoxLabel">
                    <div>Modified Date: </div>
                    <div className="blueBoxText">{`${moment(
                      docketHeader.modifiedDate
                    ).format("DD/MM/YYYY")}`}</div>
                  </div>{" "}
                  <div className="blueBoxLabel">
                    <div> </div>
                    <div className="blueBoxText"></div>
                  </div>
                </Typography>
              </div>
            </div>
            <Divider />
            <div className="view-page-container" id="printable">
              <div className="view-page-headings">
                Docket Header Information
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Service Type(*)</div>
                  <div className="view-page-value">
                    {docketHeader.serviceCode}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">PPI Permit No.</div>
                  <div className="view-page-value">
                    {docketHeader.ppiNumber}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Account No.</div>
                  <div className="view-page-value">
                    {docketHeader.billingAccountNo}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Name of Contact Person</div>
                  <div className="view-page-value">
                    {docketHeader.nameContactPerson}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Fax of Contact Person</div>
                  <div className="view-page-value">
                    {docketHeader.faxContactPerson}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Mode of Payment</div>
                  <div className="view-page-value">
                    {docketHeader.modeOfPayment}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Type of articles</div>
                  <div className="view-page-value">
                    {docketHeader.typeofArticle}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Mailing Scheme</div>
                  <div className="view-page-value">
                    {docketHeader.mailingScheme}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Mail characteristics</div>
                  <div className="view-page-value">
                    {docketHeader.mailCharacteristics}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">MARS reseller number</div>
                  <div className="view-page-value">
                    {docketHeader.marsEesellerNo}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">
                    SP Branding MARS reseller number
                  </div>
                  <div className="view-page-value">
                    {docketHeader.spBrandingMARSReseller}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">
                    Amendment agreed by PPI permit holder/ mailing house/
                    reseller
                  </div>
                  <div className="view-page-value">
                    {/* {docketHeader.mailingHouse} */}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">
                    {/* Estimated */}
                    Lodgement Date
                  </div>
                  <div className="view-page-value">
                    {moment(docketHeader.estimatedLodgementDate).format(
                      "DD/MM/YYYY"
                    )}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">
                    {/* Estimated */}
                    Lodgement Time
                  </div>
                  <div className="view-page-value">
                    {docketHeader.estimatedLodgementTime}
                  </div>
                </div>
                {/* <div className="view-page-contents-sub-container">
                <div className="view-page-label">
                  Title of Article
                  {/* (please indicate the full title) For annual
                  report please indicate the name of the company
                </div>
                <div className="view-page-value">
                  {docketHeader.titleOfArticleID}
                </div>
              </div> */}
                {/* <div className="view-page-contents-sub-container">
                <div className="view-page-label">
                  Latest notification/response dates of addresses
                </div>
                <div className="view-page-value">
                </div>
              </div> */}
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Tel of contact person</div>
                  <div className="view-page-value">
                    {docketHeader.telephoneContactPerson}
                  </div>
                </div>

                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Cost centre</div>
                  <div className="view-page-value">
                    {docketHeader.costCenterName}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Customer's Ref no</div>
                  <div className="view-page-value">
                    {docketHeader.customersRefNo}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">
                    Total weight of consignment
                  </div>
                  <div className="view-page-value">
                    {docketHeader.totalWeightConsignment}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">No of Bags/ receptacle</div>
                  <div className="view-page-value">
                    {docketHeader.noOfBagsReceptacle}
                  </div>
                </div>
                {/* <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Letter Shop</div>
                  <div className="view-page-value">
                    {docketHeader.letterShopCode}
                  </div>
                </div> */}
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Work Order Number</div>
                  <div className="view-page-value">
                    {docketHeader.workOrderNumber}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">
                    Net weight per receptacle
                  </div>
                  <div className="view-page-value">
                    {docketHeader.netWtReceptacle}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Owner of PPI</div>
                  <div className="view-page-value">
                    {docketHeader.ownerofPPI}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Mailing house</div>
                  <div className="view-page-value">
                    {docketHeader.mailingHouse}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Remarks</div>
                  <div className="view-page-value">
                    {docketHeader.remarks}
                  </div>
                </div>
                {/* <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Work Order Number</div>
                  <div className="view-page-value">
                    {docketHeader.workOrderNumber}
                  </div>
                </div> */}
                {/* <div className="view-page-contents-sub-container">
                <div className="view-page-label">Sender signature and date</div>
                <div className="view-page-value">
                </div>
              </div> */}
              </div>
              {/* <div className="view-page-contents-container">
              
            </div> */}
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Company Name</div>
                  <div className="view-page-value">
                    {docketHeader.companyName}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Title of Article</div>
                  <div className="view-page-value">{docketHeader.title}</div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Title of Article1</div>
                  <div className="view-page-value">
                    {docketHeader.titleOfArticle}
                  </div>
                </div>

                {/* <div className="view-page-contents-sub-container">
                  <div className="view-page-label">No Of Units</div>
                  <div className="view-page-value">
                    {docketHeader.noOfUnits}
                  </div>
                </div> */}
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">AR</div>
                  <div className="view-page-value">
                    {docketHeader.isAR ? "Yes" : "No"}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">RA</div>
                  <div className="view-page-value">
                    {docketHeader.isRA ? "Yes" : "No"}
                  </div>
                </div>

                {!docketHeader.isLocal && (
                  <div className="view-page-contents-sub-container">
                    <div className="view-page-label">Transmission Mode</div>
                    <div className="view-page-value">
                      {docketHeader.transmissionMode}
                    </div>
                  </div>
                )}
              </div>
              {this.state.docketHeader && this.state.docketHeader.docketLetterShops && this.state.docketHeader.docketLetterShops.length > 0 &&
                this.state.docketHeader.docketLetterShops.map((dls, lsId) => {
                  return (
                    <div className="view-page-contents-container" key={lsId}>
                      <div className="view-page-contents-sub-container">
                        <div className="view-page-label">Letter Shop</div>
                        <div className="view-page-value">
                          {dls.lettershopCode}
                        </div>
                      </div>
                      <div className="view-page-contents-sub-container">
                        <div className="view-page-label">No Of Units</div>
                        <div className="view-page-value">
                          {dls.noOfUnits}
                        </div>
                      </div>


                    </div>
                  )
                })}
            </div>
            <Divider />
            <form
              className={classes.container}
              style={{ marginBottom: "2px" }}
              onSubmit={this.handleSubmit}
            >
              <div
                style={
                  !this.props.clearOpenList
                    ? {
                      maxWidth: "1230px",
                      height: "100%",
                    }
                    : {
                      width: "1000px",
                      height: "100%",
                    }
                }
              >
                <EnhancedTableToolbar
                  numSelected={selected && selected.length}
                  history={this.props.history}
                  selected={this.state.selected}
                  deletePopupOpen={this.state.deletePopupOpen}
                  handleDeletePopupOpen={this.handleDeletePopupOpen}
                  handleDeletePopupClose={this.handleDeletePopupClose}
                  handleDeleteRunMailList={this.handleDeleteRunMailList}
                  selectedRunMailList={this.state.selectedRunMailList}
                  selecteddocketHeaderID={this.state.selecteddocketHeaderID}
                  clearSearch={this.clearSearch}
                  searchPopupOpen={this.state.searchPopupOpen}
                  handleSearchPopupOpen={this.handleSearchPopupOpen}
                  handleSearchPopupClose={this.handleSearchPopupClose}
                  handleSearchRunMailList={this.handleSearchRunMailList}
                  runMailListSearchValue={this.state.RunMailListSearchValues}
                  searchClicked={this.state.searchClicked}
                  hendleVewColums={this.hendleVewColums}
                  headCells={headCells}
                  isActive={this.state.isActive}
                  handleClickButton={this.handleClickButton}
                  editableDocketDetailList={this.state.editableDocketDetailList}
                  resetList={this.state.resetList}
                  disableAdd={this.state.disableAdd}
                  disableEdit={this.state.disableEdit}
                  roles={this.props.roles}
                />
                <div
                  style={{
                    maxHeight: "180px",
                    overflow: "auto",
                    border: ".1px solid #eaeaea",
                    marginLeft: "12px",
                    marginRight: "12px",
                  }}
                >
                  <div
                    style={{
                      maxHeight: "180px",
                      overflow: "auto",
                      border: "1px solid #eaeaea",
                    }}
                  >
                    {docketHeader.isLocal ? (
                      <Table
                        aria-labelledby="tableTitle"
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <EnhancedTableHeadlocal
                          numSelected={selected && selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                          onRequestSort={this.handleRequestSort}
                        // rowCount={editableDocketDetailList.length}
                        />
                        <TableBody style={{ overflow: "hidden" }}>
                          {docketHeaderDetails &&
                            docketHeaderDetails.length > 0 &&
                            docketHeaderDetails &&
                            docketHeaderDetails.map((row, index) => {
                              // console.log(row, "rooooow");
                              const labelId = `enhanced-table-checkbox-${index}`;

                              return (
                                <TableRow
                                  hover
                                  // onClick={event =>
                                  //   this.handleClick(
                                  //     event,
                                  //     row.docketHeaderID,
                                  //     row
                                  //   )
                                  // }
                                  // role="checkbox"
                                  // aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.docketHeaderID}
                                  id={`tableRow${index}`}
                                // selected={isItemSelected}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="none"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    {row.fileName &&
                                      row.fileName.length > 16 ? (
                                        <Tooltip title={row.fileName}>
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.fileName}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.fileName}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="none"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    {row.countryName &&
                                      row.countryName.length > 16 ? (
                                        <Tooltip title={row.countryName}>
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.countryName}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.countryName}
                                        </Typography>
                                      )}
                                  </TableCell>

                                  {/* <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="none"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    {" "}
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.province}
                                    </Typography>
                                  </TableCell> */}
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="none"
                                    style={{ padding: "0px 8px 0px 16px" }}
                                    align="right"
                                    className="table-content-right-cell"
                                  // style={{ padding: "0px 30px 0px 16px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.copies}
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-right-cell"
                                    style={{ padding: "0px 8px 0px 16px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.quantity}
                                    </Typography>
                                  </TableCell>
                                  {/* <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-right-cell"
                                    style={{ padding: "0px 5px 0px 16px" }}
                                  >
                                    {row.totalQty}
                                  </TableCell> */}
                                  {/* <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                    style={{ padding: "0px 8px 0px 16px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.remarks}
                                    </Typography>
                                  </TableCell> */}
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={{ padding: "0px 8px 0px 16px" }}
                                  >
                                    {row.routing && row.routing.length > 16 ? (
                                      <Tooltip title={row.routing}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.routing}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.routing}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  {/* <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.isCOP === true ? "Yes" : "No"}
                                    </Typography>
                                  </TableCell> */}
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="none"
                                    align="right"
                                    className="table-content-cell"
                                    style={{ padding: "0px 6px 0px 16px" }}
                                  >
                                    {" "}
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.mailSizeName}
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="none"
                                    align="right"
                                    className="table-content-cell"
                                    style={{ padding: "0px 8px 0px 16px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.weightPerItem}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                    style={{ padding: "0px 8px 0px 16px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.noOfArticles}
                                    </Typography>
                                  </TableCell>
                                  {/* <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    align="right"
                                    className="table-content-cell"
                                    style={{ padding: "0px 8px 0px 16px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.noOfBags}
                                    </Typography>
                                  </TableCell> */}
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    align="right"
                                    className="table-content-cell"
                                    style={{ padding: "0px 8px 0px 16px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.totalChargeableWeight}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                    style={{ padding: "0px 8px 0px 16px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.postageRate}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    align="right"
                                    className="table-content-cell"
                                    style={{ padding: "0px 8px 0px 16px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.totalCharges}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    ) : (
                        <Table
                          aria-labelledby="tableTitle"
                          size="small"
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <EnhancedTableHead
                            numSelected={selected && selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                            onRequestSort={this.handleRequestSort}
                          // rowCount={editableDocketDetailList.length}
                          />
                          <TableBody style={{ overflow: "hidden" }}>
                            {docketHeaderDetails &&
                              docketHeaderDetails.length > 0 &&
                              docketHeaderDetails &&
                              docketHeaderDetails.map((row, index) => {
                                // console.log(row, "rooooow");
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    hover
                                    // onClick={event =>
                                    //   this.handleClick(
                                    //     event,
                                    //     row.docketHeaderID,
                                    //     row
                                    //   )
                                    // }
                                    // role="checkbox"
                                    // aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.docketHeaderID}
                                    id={`tableRow${index}`}
                                  // selected={isItemSelected}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="none"
                                      align="left"
                                      className="table-content-cell"
                                    >
                                      {row.fileName &&
                                        row.fileName.length > 16 ? (
                                          <Tooltip title={row.fileName}>
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.fileName}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.fileName}
                                          </Typography>
                                        )}
                                    </TableCell>

                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="none"
                                      align="left"
                                      className="table-content-cell"
                                    >
                                      {" "}
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {row.zoneName}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="none"
                                      align="left"
                                      className="table-content-cell"
                                    >
                                      {row.countryName &&
                                        row.countryName.length > 16 ? (
                                          <Tooltip title={row.countryName}>
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.countryName}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.countryName}
                                          </Typography>
                                        )}
                                    </TableCell>
                                    {/* <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="none"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    {" "}
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.province}
                                    </Typography>
                                  </TableCell> */}
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="none"
                                      style={{ padding: "0px 8px 0px 16px" }}
                                      align="right"
                                      className="table-content-right-cell"
                                    // style={{ padding: "0px 30px 0px 16px" }}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {row.copies}
                                      </Typography>
                                    </TableCell>

                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-right-cell"
                                      style={{ padding: "0px 8px 0px 16px" }}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {row.quantity}
                                      </Typography>
                                    </TableCell>
                                    {/* <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-right-cell"
                                    style={{ padding: "0px 5px 0px 16px" }}
                                  >
                                    {row.totalQty}
                                  </TableCell> */}
                                    {/* <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                    style={{ padding: "0px 8px 0px 16px" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {" "}
                                      {row.remarks}
                                    </Typography>
                                  </TableCell> */}
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="left"
                                      className="table-content-cell"
                                      style={{ padding: "0px 8px 0px 16px" }}
                                    >
                                      {row.routing && row.routing.length > 16 ? (
                                        <Tooltip title={row.routing}>
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.routing}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.routing}
                                          </Typography>
                                        )}
                                    </TableCell>
                                    {/* <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.isCOP === true ? "Yes" : "No"}
                                    </Typography>
                                  </TableCell> */}
                                    {/* <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="none"
                                align="right"
                                className="table-content-cell"
                                style={{ padding: "0px 6px 0px 16px" }}
                              >
                                {" "}
                                <Typography
                                  noWrap
                                  style={{ fontSize: "14px" }}
                                >
                                  {" "}
                                  {row.mailSize}
                                </Typography>
                              </TableCell> */}
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.isBM1500Countries !== null &&
                                          row.isBM1500Countries === true
                                          ? "Yes"
                                          : "No"}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="none"
                                      align="right"
                                      className="table-content-cell"
                                      style={{ padding: "0px 8px 0px 16px" }}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {row.weightPerItem}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                      style={{ padding: "0px 8px 0px 16px" }}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {row.noOfArticles}
                                      </Typography>
                                    </TableCell>

                                    {/* <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                align="right"
                                className="table-content-cell"
                                style={{ padding: "0px 8px 0px 16px" }}
                              >
                                <Typography
                                  noWrap
                                  style={{ fontSize: "14px" }}
                                >
                                  {" "}
                                  {row.noOfBags}
                                </Typography>
                              </TableCell> */}
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      align="right"
                                      className="table-content-cell"
                                      style={{ padding: "0px 8px 0px 16px" }}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {row.totalChargeableWeight}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                      style={{ padding: "0px 8px 0px 16px" }}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {row.postageRate}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                      style={{ padding: "0px 8px 0px 16px" }}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {row.totalCharges}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      )}
                  </div>
                </div>{" "}
                <Divider style={{ marginTop: "25px" }} />
                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.goBack()}
                    >
                      CANCEL
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(ViewDocketPosting));
