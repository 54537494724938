import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { resourceValidation } from "../../../shared/resource";
import moment from "moment";
import {
  SearchIcon,
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import { Autocomplete } from "@material-ui/lab";
import {
  stableSort,
  getSorting,
  DialogActions,
} from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import Validator from "../../../shared/validator";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { StateContext } from "../../../shared/globalState";
import {
  getWorkOrderList,
  getFliteredWorkOrderList,
  DeleteWorkOrder,
  postForReOpenWO
} from "./action";
import { getCustomerList, getStatusList } from "../../masters/masterActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ExportCSV } from "../../../shared/ExportCSV";
import StatusText from "../../../shared/statusText";

const columns = [
  {
    name: "Customer",
    id: 2,
  },
  {
    name: "Modified Date",
    id: 9,
  },
  {
    name: "Modified By",
    id: 10,
  },
  {
    name: "Created Date",
    id: 11,
  },
  {
    name: "Created By",
    id: 12,
  },
];

const notRequiredAlpNum20 = new Validator("notRequiredAlpNum20");

function createData(
  WorkOrderID,
  customerID,
  customerCode,
  customerName,
  woSuffix,
  woRunningID,
  workOrderNumber,
  contactPerson,
  projectTitle,
  plannerID,
  planner,
  noOfOrders,
  splInstructions,
  statusID,
  status,
  service,
  serviceID,
  customerPPIID,
  billingAddress,
  isActive,
  createdByName,
  createdDate,
  modifiedByName,
  modifiedDate
) {
  return {
    WorkOrderID,
    customerID,
    customerCode,
    customerName,
    woSuffix,
    woRunningID,
    workOrderNumber,
    contactPerson,
    projectTitle,
    plannerID,
    planner,
    noOfOrders,
    splInstructions,
    statusID,
    status,
    service,
    serviceID,
    customerPPIID,
    billingAddress,
    isActive,
    createdByName,
    createdDate,
    modifiedByName,
    modifiedDate,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          {/* <FormControlLabel
            control={
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all work order" }}
              />
            }
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  //rowCount: PropTypes.number.isRequired
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    customerID: "",
    woSuffix: "",
    woRunningID: "",
    workOrderNumber: "",
  });
  const [formValid, setIsformValid] = useState(false);
  let acnArr = props.roles.filter((u) => u.menuName === "Work Order");
  let acnObj = acnArr[0];

  const [workOrderSearchValues, setValues] = useState({
    customer: "",
    customerID: 0,
    status: "",
    statusID: 0,
    projectTitle: "",
    workOrderNumber: "",
    modifiedDateFrom: null,
    modifiedDateTo: null,
  });

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);

  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handleDeleteWorkOrder,
    searchPopupOpen,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchWorkOrder,
    searchClicked,
    hendleVewColums,
    exportDataValues,
    workOrderList,
    selected,
    isActive,
    customersList,
    statusList,
  } = props;

  const selectedWorkOrderID = selected[0];
  const selectedWorkOrder = workOrderList.find(
    (x) => x.WorkOrderID === selectedWorkOrderID
  );
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteWorkOrder = () => {
    handleDeleteWorkOrder();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const handleModifiedFromDateChange = (date) => {
    let name = "modifiedDateFrom";
    setValues((workOrderSearchValues) => ({
      ...workOrderSearchValues,
      [name]: date && date,
    }));
    setIsformValid(true);
  };

  const handleModifiedToDateChange = (date) => {
    let name = "modifiedDateTo";
    setValues((workOrderSearchValues) => ({
      ...workOrderSearchValues,
      [name]: date && date,
    }));
    setIsformValid(true);
  };

  const clearSearchValues = () => {
    let searchValues = workOrderSearchValues;
    searchValues.customer = "";
    searchValues.customerID = 0;
    searchValues.status = "";
    searchValues.statusID = 0;
    searchValues.projectTitle = "";
    searchValues.modifiedDateFrom = null;
    searchValues.modifiedDateTo = null;
    searchValues.workOrderNumber = "";
    searchValues = workOrderSearchValues;
  };

  const searchPopup = () => {
    handleSearchWorkOrder(workOrderSearchValues);
    clearSearchValues();
  };

  const handleCustChange = (event, values) => {
    let customerID = values && values.customerID;
    setValues((workOrderSearchValue) => ({
      ...workOrderSearchValue,
      customer: values,
    }));
    setValues((workOrderSearchValue) => ({
      ...workOrderSearchValue,
      customerID: customerID,
    }));
    setIsformValid(true);
  };

  const handleStatusChange = (event, values) => {
    let statusID = values && values.genStatusID;
    setValues((workOrderSearchValue) => ({
      ...workOrderSearchValue,
      status: values,
    }));
    setValues((workOrderSearchValue) => ({
      ...workOrderSearchValue,
      statusID: statusID,
    }));
    setIsformValid(true);
  };

  const handleSearchInput = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;
    setValues((workOrderSearchValue) => ({
      ...workOrderSearchValue,
      [event.target.name]: event.target.value,
    }));

    switch (name) {
      // case "woSuffix":
      //   let woSuffixvalid = notRequiredAlpNum20(value);
      //   setIsformValid(woSuffixvalid);
      //   setErrors(errors => ({
      //     ...errors,
      //     [event.target.name]: woSuffixvalid
      //       ? ""
      //       : resourceValidation.woSuffixvalid
      //   }));
      //   break;
      // case "woRunningID":
      //   let woRunningIDValid = notRequiredAlpNum20(value);
      //   setIsformValid(woRunningIDValid);
      //   setErrors(errors => ({
      //     ...errors,
      //     [event.target.name]: woRunningIDValid
      //       ? ""
      //       : resourceValidation.woRunningIDValid
      //   }));
      //   break;
      case "workOrderNumber":
        let workOrderNumberValid = notRequiredAlpNum20(value);
        setIsformValid(workOrderNumberValid);
        setErrors((errors) => ({
          ...errors,
          [event.target.name]: workOrderNumberValid
            ? ""
            : resourceValidation.workOrderNumberValid,
        }));
        break;
      default:
        setIsformValid(true);
        break;
    }
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };

  const _onKeyAlpNumPress = (event) => {
    const re = /[a-zA-Z0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  };
  const _onKeyNumberPress = (event) => {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  };

  // handleCustomerChange = (event, values) => {
  //   const { routingTitle } = this.state;
  //   routingTitle["customer"] = values;
  //   routingTitle["customerID"] = customerID;
  //   this.validateField("customer", customerID);
  //   this.setState({ routingTitle });
  // };
  let selectedStatus = props.selectedWorkOrder.status;
  // console.log(acnObj)
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div
            color="inherit"
            style={{ width: "50%" }}
            variant="subtitle1"
            className="row-selected-div"
          >
            {numSelected} row(s) selected
          </div>
        )}
        {/* {numSelected > 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%"
            }}
          ></div>
        )} */}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                marginRight: "5px",
                //fontSize: "11px",
                // fontWeight: "bold",
                marginTop: "5px",
                marginBottom: "5px",
                boxShadow: "none",
              }}
              onClick={(e) =>
                props.history.push(`/view_mbaReport/${selectedWorkOrderID}`)
              }
            >
              Manual Billing Advice
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                // fontSize: "11px",
                //fontWeight: "bold",
                marginTop: "5px",
                marginBottom: "5px",
                boxShadow: "none",
              }}
              onClick={(e) =>
                props.history.push(
                  "/view_owcReport/" + `${selectedWorkOrderID}`
                )
              }
            >
              Out Worker Claim
            </Button>
            {(props && props.reOpenWO) && (acnObj && acnObj.approveAction) && <Button
              variant="contained"
              color="primary"
              style={{
                // fontSize: "11px",
                //fontWeight: "bold",
                margin: "5px",
                // marginBottom: "5px",
                boxShadow: "none",
              }}
              onClick={(e) =>
                props.handleReOpenWO(e, selectedWorkOrderID)
              }
            >
              Re-open WO
            </Button>}
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link
                      to={{
                        pathname: "/view_WorkOrder",
                        search: "?workOrderID=" + selectedWorkOrderID,
                        state: {
                          workOrderMode: "view",
                        },
                        data: { status: selectedStatus },
                      }}
                    >
                      {/* <Link
                    to={`/view_WorkOrder?workOrderID=${selectedWorkOrderID}`}
                  > */}
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj &&
                  acnObj.editAction &&
                  numSelected === 1 &&
                  selectedStatus !== "Closed" &&
                  selectedStatus !== "Cancelled" && (
                    <Tooltip title="Edit">
                      <Link
                        to={{
                          pathname: "/edit_WorkOrder",
                          search: "?workOrderID=" + selectedWorkOrderID,
                          state: {
                            workOrderMode: "edit",
                          },
                          data: { status: selectedStatus },
                          status: props.selectedWorkOrder.status,
                        }}
                      >
                        <IconButton
                          aria-label="edit"
                          className={classes.iconHover}
                        >
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  )}
              </div>
            )}
            {acnObj &&
              acnObj.deleteAction &&
              numSelected === 1 &&
              selectedStatus !== "Closed" &&
              selectedStatus !== "Cancelled" && (
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    onClick={handleClickOpen}
                    disabled={!isActive}
                  >
                    <DeleteIcon className={classes.iconHover} />
                  </IconButton>
                </Tooltip>
              )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete Work Order
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1
                      ? " ?"
                      : selectedWorkOrder.projectTitle + " ?"}{" "}
                    ?
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteWorkOrder}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  <form onSubmit={searchPopup}>
                    {acnObj && acnObj.advanceSearchAction && (
                      <Tooltip title="Search">
                        <IconButton
                          aria-label="Search"
                          className={classes.iconHover}
                          onClick={handleSearchOpen}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      onClose={handleSearchPopupClose}
                      aria-labelledby="customized-dialog-title"
                      open={searchPopupOpen}
                      maxWidth="md"
                    >
                      <DialogTitle
                        id="customized-dialog-title"
                        onClose={handleSearchPopupClose}
                      >
                        Advanced Search
                  </DialogTitle>
                      <DialogContent dividers>
                        <React.Fragment>
                          <div style={{ minWidth: "400px", display: "flex" }}>
                            <Autocomplete
                              autoComplete
                              style={{ width: "50%" }}
                              id="customer"
                              includeInputInList
                              value={workOrderSearchValues.customer}
                              options={customersList}
                              onChange={handleCustChange}
                              getOptionLabel={(option) => option.customerName}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Customer"
                                  style={{ width: "94%" }}
                                  name="customer"
                                  margin="normal"
                                  error={errors.customerID}
                                  helperText={
                                    errors.customerID ? errors.customerID : ""
                                  }
                                />
                              )}
                            />

                            <TextField
                              label="Work Order Number"
                              name="workOrderNumber"
                              margin="normal"
                              inputProps={{
                                maxLength: 21,
                              }}
                              autoComplete="off"
                              className={classes.textField}
                              onKeyPress={_onKeyAlpNumPress}
                              onChange={handleSearchInput}
                              value={workOrderSearchValues.workOrderNumber}
                              error={errors.workOrderNumber}
                              helperText={
                                errors.workOrderNumber ? errors.workOrderNumber : ""
                              }
                            />
                          </div>
                          <div style={{ minWidth: "400px", display: "flex" }}>
                            <Autocomplete
                              autoComplete
                              style={{ width: "50%" }}
                              id="statusID"
                              includeInputInList
                              options={statusList}
                              value={workOrderSearchValues.projectTitle}
                              onChange={handleStatusChange}
                              getOptionLabel={(option) => option.genStatusName}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Status"
                                  name="statusID"
                                  margin="normal"
                                  style={{ width: "94%" }}
                                />
                              )}
                            />
                            <TextField
                              label="Project Title"
                              name="projectTitle"
                              inputProps={{
                                maxLength: 30,
                              }}
                              autoComplete="off"
                              margin="normal"
                              className={classes.textField}
                              onChange={handleSearchInput}
                              value={workOrderSearchValues.projectTitle}
                              error={errors.woRunningID}
                              helperText={
                                errors.woRunningID ? errors.woRunningID : ""
                              }
                            />
                          </div>
                          <div>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                // disablePast
                                disableToolbar
                                autoOk
                                variant="inline"
                                autoComplete="off"
                                format="dd/MM/yyyy"
                                margin="normal"
                                style={{ width: "48%" }}
                                id="modifiedDateFrom"
                                label="Modified Date From"
                                value={workOrderSearchValues.modifiedDateFrom}
                                onChange={handleModifiedFromDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                //disablePast
                                disableToolbar
                                autoOk
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                autoComplete="off"
                                style={{ marginLeft: "8px", width: "48%" }}
                                id="date-picker-inline"
                                label="Modified Date To"
                                value={workOrderSearchValues.modifiedDateTo}
                                onChange={handleModifiedToDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </React.Fragment>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={handleSearchClose}
                        >
                          CANCEL
                    </Button>
                        {!formValid ? (
                          <Button
                            disabled
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                            onClick={searchPopup}
                          >
                            SEARCH
                          </Button>
                        ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ boxShadow: "none" }}
                              onClick={searchPopup}
                            >
                              SEARCH
                            </Button>
                          )}
                      </DialogActions>
                    </Dialog>
                  </form>
                )}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="add"
                    className={classes.iconHover}
                    onClick={(e) =>
                      props.history.push({
                        pathname: "/workOrder",
                        state: {
                          workOrderMode: "create",
                          status: selectedStatus && selectedStatus,
                        },
                      })
                    }
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {acnObj && acnObj.exportAction && (
                <ExportCSV csvData={exportDataValues} fileName={"WorkOrder"} />
              )}
              {/* <IconButton aria-label="download" className={classes.iconHover}>
                  <DownloadIcon />
                </IconButton> */}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                hendleVewColums={hendleVewColums}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const [column, setColumnIds] = React.useState(valueProp);
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  }, [valueProp, open]);

  const handleClose = () => {
    onClose(setColumnIds);
  };

  const handleChange = (event) => {
    setColumnIds(event.target.value);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

const getColor = (status) => {
  switch (status) {
    case "In Progress":
      return "#03A6FD"; //In Progress
    case "Out For Collection":
      return "#ECB507"; //Out For Collection
    case "Item Received":
    case "Closed":
      return "#16831F"; //Item Recieved
    case "Cancelled":
      return "#AD2219"; //Cancelled

    default:
      return "#03A6FD";
  }
};

class WorkOrders extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    proceedModalOpen: false,
    customerList: [],
    statusList: [],
    page: 0,
    rowsPerPage: 100,
    workOrderList: [],
    refreshData: false,
    selectedWorkOrder: {},
    selectedWorkOrderId: 1,
    rows: [],
    activeRows: 0,
    isActive: false,
    loading: false,
    searchPopupOpen: false,
    workOrderSearchValues: {},
    searchClicked: false,
    selectedShowColumns: [],
    exportDataValues: [],
    customersList: [],
    headCells: [
      {
        id: 1,
        code: "customerCode",
        numeric: false,
        disablePadding: true,
        label: "Customer Code",
        show: true,
      },
      {
        id: 2,
        code: "customerName",
        numeric: false,
        disablePadding: true,
        label: "Customer",
        show: true,
      },
      {
        id: 3,
        code: "workOrderNumber",
        numeric: false,
        disablePadding: false,
        label: "Work Order No",
        show: true,
      },
      {
        id: 4,
        code: "projectTitle",
        numeric: false,
        disablePadding: false,
        label: "Project Title",
        show: true,
      },
      {
        id: 5,
        code: "splInstructions",
        numeric: false,
        disablePadding: false,
        label: "Spl.Instructions",
        show: true,
      },
      {
        id: 6,
        code: "noOfOrders",
        numeric: false,
        disablePadding: false,
        label: "No.of Job(s)",
        show: true,
      },
      {
        id: 7,
        code: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        show: true,
      },
      {
        id: 8,
        code: "planner",
        numeric: false,
        disablePadding: false,
        label: "Planner",
        show: true,
      },

      {
        id: 9,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 10,
        code: "modifiedByName",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 11,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 12,
        code: "createdByName",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };
  componentDidMount() {
    this.props.handleSettingHeader("Work Order");
    this.props.setSelectedList("genericSystemOpen", "workOrder");

    getWorkOrderList().then((r) => {
      this.getAllWorkOrder(r.data);
      this.exportWorkOrderDatas(r.data);
    });

    this.setState({ loading: true });

    getCustomerList().then((r) => {
      r.data &&
        this.setState({
          customersList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.customerName.toLowerCase() > b.customerName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });

    getStatusList().then((r) => {
      r &&
        this.setState({
          statusList: r.sort((a, b) =>
            a.genStatusName.toLowerCase() > b.genStatusName.toLowerCase()
              ? 1
              : -1
          ),
        });
    });
  }

  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };
  exportWorkOrderDatas = (workOrderList) => {
    let list = workOrderList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        rows.push({
          "Customer Code": d.customerCode,
          "Customer Name": d.customerName,
          "WorkOrder Number": d.workOrderNumber,
          "Contact Person": d.contactPerson,
          "Project Title": d.projectTitle,
          "Special Instructions": d.remarks,
          "No Of Orders": d.noOfOrders,
          Status: d.status,
          Planner: d.planner,

          "Modified Date": moment(d.modifiedDate).format("DD/MM/YYYY HH:mm:ss"),
          "Modified By": d.modifiedByName,
          "Created Date": moment(d.createdDate).format("DD/MM/YYYY HH:mm:ss"),
          "Created By": d.createdByName,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getAllWorkOrder = (workOrderList) => {
    let list = workOrderList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        rows.push(
          createData(
            d.workOrderID,
            d.customerID,
            d.customerCode,
            d.customerName,
            d.woSuffix,
            d.woRunningID,
            d.workOrderNumber,
            d.contactPerson,
            d.projectTitle,
            d.plannerID,
            d.planner,
            d.noOfOrders,
            d.splInstructions,
            d.statusID,
            d.status,
            d.service,
            d.serviceID,
            d.customerPPIID,
            d.billingAddress,
            d.isActive,
            d.createdByName,
            d.createdDate,
            d.modifiedByName,
            d.modifiedDate
          )
        );
      });
    this.setState({
      workOrderList: rows,
      selected: [],
      loading: false,
      activeRows: rows.filter((x) => x.isActive).map((n) => n.WorkOrderID),
    });
  };

  // shouldComponentUpdate(s, p) {
  //   //   const [{ store }, dispatch] = this.context;
  //   //   if (store.cptCodeList && store.cptCodeList.length > 0) {
  //   //     if (
  //   //       this.state.cptCodeList.length !== store.cptCodeList.length &&
  //   //       !this.state.searchClicked
  //   //     ) {
  //   //       !this.state.searchClicked && this.getAllCptCodes(store.cptCodeList);
  //   //     }
  //   //   }

  //   return true;
  // }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { workOrderList } = this.state;
    if (event.target.checked) {
      const newSelecteds = workOrderList
        .filter((x) => x.isActive === true)
        .map((n) => n.workOrderID);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, data) {
    let workOrderID = data.WorkOrderID,
      selected = [],
      newSelected = [];
    if (event.target.checked) {
      newSelected = newSelected.concat(selected, workOrderID);
      this.checkActiveStatus(newSelected);
      this.setState({ selected: newSelected, selectedWorkOrder: data });
      if (data.status === "Closed") {
        this.setState({ reOpenWO: true })
      }
      else {
        this.setState({ reOpenWO: false })
      }
    } else {
      this.setState({ selected: newSelected, selectedWorkOrder: {}, reOpenWO: false });
    }
    // let { selected } = this.state;
    // const selectedIndex = selected.indexOf(workOrderID);
    // let newSelected = [];
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, workOrderID);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1)
    //   );
    // }
    // if (newSelected.length === 1) {
    //   this.checkActiveStatus(newSelected);
    //   this.setState({
    //     selected: newSelected,
    //     selectedWorkOrder: data
    //   });
    // } else {
    //   this.checkActiveStatus(newSelected);
    //   this.setState({
    //     selected: newSelected,
    //     selectedWorkOrder: {}
    //   });
    // }
  }
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true, page: 0 });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchWorkOrder = (handleSearchWorkOrder) => {
    this.setState({ searchPopupOpen: false, searchClicked: true });
    let workOrderSearchValue = handleSearchWorkOrder;

    let customerID = workOrderSearchValue.customerID;
    let statusID = workOrderSearchValue.statusID;
    let projectTitle = workOrderSearchValue.projectTitle;
    let workOrderNumber = workOrderSearchValue.workOrderNumber;
    let modifiedDateFrom =
      workOrderSearchValue.modifiedDateFrom &&
      workOrderSearchValue.modifiedDateFrom;
    let modifiedDateTo =
      workOrderSearchValue.modifiedDateTo &&
      workOrderSearchValue.modifiedDateTo;

    let reqBody = {
      customerID,
      workOrderNumber,
      statusID,
      projectTitle,
      modifiedDateFrom,
      modifiedDateTo,
    };
    getFliteredWorkOrderList(reqBody).then((r) => {
      this.getAllWorkOrder(r.data);
    });
  };

  handleDeleteWorkOrder = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let workOrderID = selected.join(",");
    let modifiedBy = localStorage.loggedInId;
    DeleteWorkOrder(workOrderID, modifiedBy).then((r) => {
      this.setState({ deletePopupOpen: false, selected: [] });
      getWorkOrderList().then((r) => {
        dispatch({
          type: "WorkOrders",
          newStore: r.data,
        });
        this.getAllWorkOrder(r.data);
      });
    });
  };

  clearSearch = () => {
    getWorkOrderList().then((r) => {
      this.getAllWorkOrder(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  handleReOpenWO = (e, id) => {
    // console.log(id)
    this.setState({ proceedModalOpen: true })

  }
  proceedModalBtnClicked = (val) => {
    if (val === "Yes") {
      let id = this.state.selectedWorkOrder.WorkOrderID
      let lId = localStorage.loggedInId;
      postForReOpenWO(id, lId).then(r => {
        this.clearSearch()
      })
      this.setState({ selected: [], selectedWorkOrder: {}, reOpenWO: false })
    }
    this.setState({ proceedModalOpen: false })

  }
  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      workOrderList,
      // selectedWorkOrder,
      // selectedWorkOrderId,
      // workOrderSearchValue,
      headCells,
      exportDataValues,
    } = this.state;
    const isSelected = (workOrderID) => selected.indexOf(workOrderID) !== -1;
    //const isSelected = workOrderID => selected === workOrderID;

    return (
      <div>
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.proceedModalOpen}
        >
          <div style={{ padding: "4em" }}>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>
              {" "}
              Are you sure you want to "Re-open" ?
            </div>

            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={e => this.proceedModalBtnClicked("No")}
                style={{ marginRight: "1em", boxShadow: "none" }}
              >
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={e => this.proceedModalBtnClicked("Yes")}
              >
                Yes
              </Button>
            </div>
          </div>
        </Dialog>
        <Paper>
          <EnhancedTableToolbar
            statusList={this.state.statusList}
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteWorkOrder={this.handleDeleteWorkOrder}
            selectedWorkOrder={this.state.selectedWorkOrder}
            selectedWorkOrderId={this.state.selectedWorkOrderId}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchWorkOrder={this.handleSearchWorkOrder}
            workOrderSearchValues={this.state.workOrderSearchValues}
            searchClicked={this.state.searchClicked}
            hendleVewColums={this.hendleVewColums}
            workOrderList={this.state.workOrderList}
            headCells={headCells}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            customersList={this.state.customersList}
            roles={this.props.roles}
            reOpenWO={this.state.reOpenWO}
            handleReOpenWO={this.handleReOpenWO}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ whiteSpace: "nowrap" }}
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                  statusList={this.state.statusList}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {workOrderList.length > 0 ? (
                    stableSort(workOrderList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.WorkOrderID);
                        return (
                          <TableRow
                            hover
                            onClick={(event) => this.handleClick(event, row)}
                            role="checkbox"
                            // aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.customerCode &&
                                  row.customerCode.length > 16 ? (
                                    <Tooltip title={row.customerCode}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.customerCode}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.customerName &&
                                  row.customerName.length > 16 ? (
                                    <Tooltip title={row.customerName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.customerName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.workOrderNumber &&
                                  row.workOrderNumber.length > 16 ? (
                                    <Tooltip title={row.workOrderNumber}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.workOrderNumber}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.workOrderNumber}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell-250"
                              >
                                {row.projectTitle &&
                                  row.projectTitle.length > 16 ? (
                                    <Tooltip title={row.projectTitle}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.projectTitle}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.projectTitle}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.splInstructions &&
                                  row.splInstructions.length > 16 ? (
                                    <Tooltip title={row.splInstructions}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.splInstructions}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.splInstructions}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.noOfOrders &&
                                  row.noOfOrders.length > 16 ? (
                                    <Tooltip title={row.noOfOrders}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.noOfOrders}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noOfOrders}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  <StatusText color={getColor(row.status)}>
                                    {row.status}
                                  </StatusText>
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {/* {moment(row.modifiedDate).format("DD/MM/YYYY")} */}
                                {row.planner && row.planner.length > 16 ? (
                                  <Tooltip title={row.planner}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.planner}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.planner}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {/* {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.isActive ? "Yes" : "No"}
                                </Typography>
                              </TableCell>
                            )} */}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.modifiedByName &&
                                  row.modifiedByName.length > 16 ? (
                                    <Tooltip title={row.modifiedByName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              >

                                {row.createdByName &&
                                  row.createdByName.length > 10 ? (
                                    <Tooltip title={row.createdByName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.createdByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow hover>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={11} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={workOrderList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(WorkOrders);
