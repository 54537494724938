import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { postZone } from "../masterActions";
import { addStyles } from "../../../shared/styles";
const validateMaxLength2 = new Validator("maxlength2");

class AddZone extends React.Component {
  constructor() {
    super();

    this.state = {
      Zone: {
        ZoneCode: "",
        ZoneName: ""
      },
      errors: {},
      ZoneCodeValid: false,
      ZoneNameValid: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.handleSettingHeader("Zone");
  }

  handleChange = e => {
    const { Zone } = this.state;
    Zone[e.target.name] = e.target.value;
    this.validateField(e.target.name, e.target.value);
    this.setState({ Zone });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let ZoneCodeValid = this.state.ZoneCodeValid;
    let ZoneNameValid = this.state.ZoneNameValid;
    switch (fieldName) {
      case "ZoneCode":
        ZoneCodeValid = validateMaxLength2(value);
        fieldValidationErrors.ZoneCode = ZoneCodeValid
          ? ""
          : " Please enter alphabet characters only";
        break;
      case "ZoneName":
        ZoneNameValid = true;
        fieldValidationErrors.ZoneName = "";
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        ZoneCodeValid: ZoneCodeValid,
        ZoneNameValid: ZoneNameValid
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.ZoneCodeValid
    });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    let newZone = { ...this.state.Zone };
    newZone.ZoneCode = "";
    newZone.ZoneName = "";
    this.setState({
      Zone: newZone,
      errors: {}
    });
  };

  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleSubmit = event => {
    event.preventDefault();

    if (this.validateForm()) {
      const {
        Zone: { ZoneCode, ZoneName }
      } = this.state;

      let isActive = true;
      let createdBy = localStorage.loggedInId;
      let reqBody = { ZoneCode: ZoneCode.toUpperCase(), ZoneName, isActive, createdBy };
      postZone(reqBody, this.props.history);
    }
  };
  render() {
    let { classes } = this.props;
    let { Zone } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Zone</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <TextField
                  required
                  label="Zone Code"
                  name="ZoneCode"
                  inputProps={{
                    maxLength: 2
                  }}
                  margin="normal"
                  className={classes.textField}
                  value={Zone.ZoneCode.toUpperCase()}
                  onChange={this.handleChange}
                  error={this.state.errors.ZoneCode}
                  helperText={
                    this.state.errors.ZoneCode ? this.state.errors.ZoneCode : ""
                  }
                  autoComplete="off"
                  onKeyPress={this._onCodeKeyPress}
                />
                <TextField
                  inputProps={{
                    maxLength: 100
                  }}
                  label="Zone Name"
                  name="ZoneName"
                  margin="normal"
                  className={classes.textField}
                  value={Zone.ZoneName}
                  onChange={this.handleChange}
                  error={this.state.errors.ZoneName}
                  helperText={
                    this.state.errors.ZoneName ? this.state.errors.ZoneName : ""
                  }
                  autoComplete="off"
                />
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={e => this.props.history.push("/Zone")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddZone));
