import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Button, Paper, Divider } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { resourceValidation } from "../../../shared/resource";
import { StateContext } from "../../../shared/globalState";
import { getMailingSchemeLists, postMailingScheme } from "../masterActions";

import { addStyles } from "../../../shared/styles";
const maxlength50 = new Validator("maxlength50");
const maxlength200AllAllow = new Validator("maxlength200AllAllow");

class AddMailingScheme extends React.Component {
  static contextType = StateContext;
  state = {
    mailingScheme: "",
    description: "",
    isActive: true,
    errors: {
      mailingScheme: false,
      description: false
    }
  };
  componentDidMount() {
    this.props.handleSettingHeader("Mailing Scheme");
    this.props.setSelectedList("masterDataOpen", "mailingScheme");
  }
  handleChange = (e, validate) => {
    let newRrrors = { ...this.state.errors };
    let result = validate(e.target.value);
    newRrrors[e.target.name] = !result;
    this.setState({ [e.target.name]: e.target.value, errors: newRrrors });
  };

  handleClose = () => {
    this.setState({
      mailingScheme: "",
      description: ""
    });
  };
  checkValidInputs = () => {
    if (!this.state.mailingScheme) return true;
    if (!this.state.description) return true;
    else return false;
  };
  handleSubmit = event => {
    const dispatch = this.context[1];
    let reqBody = {};
    reqBody.mailingScheme = this.state.mailingScheme;
    reqBody.description = this.state.description;
    reqBody.createdBy = localStorage.loggedInId;
    postMailingScheme(this.props.history, reqBody)
      .then(o => {
        getMailingSchemeLists()
          .then(r => {
            dispatch({
              type: "getMailingSchemeList",
              newStore: r.data
            });
          })
          .catch(e => {});
      })
      .catch(e => {});
  };
  render() {
    let { classes } = this.props;
    let { mailingScheme, description, errors } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Mailing Scheme</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    label="Mailing Scheme"
                    name="mailingScheme"
                    inputProps={{
                      maxLength: 50
                    }}
                    // style={{ width: "33.33%" }}
                    //onKeyPress={this._onKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={mailingScheme}
                    onChange={e => this.handleChange(e, maxlength50)}
                    error={errors.mailingScheme}
                    helperText={
                      errors.mailingScheme
                        ? resourceValidation.alphaNumaric
                        : ""
                    }
                  />
                  <TextField
                    multiline={false}
                    rows={1}
                    rowsMax={4}
                    inputProps={{
                      maxLength: 200
                    }}
                    required
                    // style={{ width: "33.33%" }}
                    //onKeyPress={this._onKeyPress}
                    label="Description"
                    name="description"
                    margin="normal"
                    className={classes.textField}
                    value={description}
                    onChange={e => this.handleChange(e, maxlength200AllAllow)}
                    error={errors.description}
                    helperText={
                      errors.description ? resourceValidation.alphaNumaric : ""
                    }
                  />
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={e => this.props.history.push("/mailingScheme")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {this.checkValidInputs() ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddMailingScheme));
