import React, { Component } from "react";

class PublisherStockTransfer extends Component {
  state = {};
  componentDidMount() {
    this.props.handleSettingHeader("Back Label Stock Transfer");
    this.props.setSelectedList("publisherReportsOpen", "PublisherStockTransfer");
  }
  render() {
    return (
      <div>
      <iframe
        style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
        src={`${window.localStorage.ReportURLByID}` +"PublisherStockTransfer"}
        />
      </div>
    );
  }
}

// export default MLRunMailListReport;
export default PublisherStockTransfer;
