import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { resourceValidation } from "../../../shared/resource";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { Autocomplete } from "@material-ui/lab";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import clone from "clone";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { StateContext } from "../../../shared/globalState";
import {
  postJournalitemMasterList,
  getPublisherCustomerList,
  getJournalIssueID,
  getOfficeName,
  getPublisherJournalTypeList,
} from "./action";
import { getLocationDropdownList } from "../../masters/masterActions"
import moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TextareaAutosize } from "@material-ui/core";
import { validate } from "email-validator";
const Reorderqty = new Validator("Reorderqty");
const onlyNumbers = new Validator("onlyNumbers");
const generalValidator = new Validator("general");
const checkrate = new Validator("checkdecimalpoint");
const checkAVIS = new Validator("aVIS");
const checkAVISForWS = new Validator("aVISForWS")
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    // marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: "31%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    // marginLeft: "7px",
    // marginLeft: "1.5em",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
});

class AddJournalItemMaster extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      switchPopUpOpen:false,
      locationId: "",
      locationObj: {},
      locationList: [],
      customerList: [],
      customerId: 0,
      customer: {},
      journalTypeList: [],
      jtObj: {},
      type: "",
      acronym: "",
      volume: "",
      issue: "",
      title: "",
      Remarks: "",
      suppNo: "",
      partNo: "",
      issueDesc: "",
      issueId: "",
      insert: "N",
      office: "",
      expectedDeliveryDate: null,
      printRun: "",
      estMLR: "",
      estOMR: "",
      estBalanceOfStock: "",
      estUnitWeight: "0.000",
      estTotalWeight: "0.000",
      returnByAir: "",
      returnBySea: "",
      division: "",
      dimension: "",
      printer: "",
      splInstruction: "",
      splDeliveryInstruction: "",
      offlineFlag: "",
      distributor: "",
      uspsPermit: "",
      priorityTitle: "",
      journalId: "",
      splHandling: "",
      journalReceivedDate: null,
      actualPrintRunReceived: "",
      actualWeight: "0.000",
      CPTWeight: "0.000",
      BLWeight: "0.000",
      mlCopies: "",
      omCopies: "",
      otCopies: "",
      hcCopies: "",
      eboCopies: "",
      issnNo: "",
      publicationDate: null,
      publicationYear: null,
      offlineQty: "",
      cea: "",
      ces: "",
      status: "",
      active: "",
      emloEmailUpload: "",
      emloManual: "",
      routingCountry: {
        customerID: "",
        countryID: "",
        locationID: "",
        dispatchTypeID: "",
        dispatchModeID: "",
        mailingSchemeID: "",
        distributorID: "",
        noOfOrdersFrom: "0",
        noOfOrdersTo: "0",
        noOfCopiesFrom: "0",
        noOfCopiesTo: "0",
        weightFrom: "0",
        weightTo: "0",
        ppiNumber: "",
        ppiLicenseNo: "",
        bpaMarketingQty: "",
        country: "",
        returnAddress: "",
        carrierSheetScheme: "",
        carrierSheetMode: "",
        customer: "",
        location: "",
        dispatchType: "",
        dispatchMode: "",
        mailingScheme: "",
        distributor: "",
      },
      errors: {},
      countryValid: false,
      customerValid: false,
      locationValid: false,
      dispatchTypeValid: false,
      dispatchModeValid: false,
      mailingschemeValid: false,
      distributorValid: false,
      noOfOrdersFromValid: true,
      noOfOrdersToValid: true,
      noOfCopiesFromValid: true,
      noOfCopiesToValid: true,
      weightFromValid: true,
      weightToValid: true,
      ppiLicenseNoValid: false,
      ppiNumberValid: false,
      returnAddressValid: false,
      carrierSheetSchemeValid: false,
      carrierSheetModeValid: false,
      customersList: [],
      // LocationList: [],
      journalTypeList2: [],
    };
  }
  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  _onCodeKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onCodeKeyPress1(event) {
    const re = /^[0-9.]{1,3}(?:\.[0-9]{1,2})?$/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    //Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }
  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.props.handleSettingHeader("Journal Item Master");
    this.props.setSelectedList(
      "publisherMainLabelOpen",
      "pubJournalItemMaster"
    );
    getPublisherCustomerList().then((r) => {
      let oldCus = clone(r.data);
      r.data &&
        this.setState({
          customerList: r.data.sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
          ),
        });
      oldCus &&
        this.setState({ customerId: oldCus[0].id, customer: oldCus[0] });
    });
    getPublisherJournalTypeList().then((r) => {
      let res = clone(r.data);
      let secList = [];
      r.data &&
        this.setState({
          journalTypeList: r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
        });

      secList.push(res.shift());

      this.setState({ journalTypeList2: secList });
    });
    // getLocationList().then(r => {
    //   r.data &&
    //     this.setState({
    //       LocationList: r.data
    //         .filter(x => x.isActive === true)
    //         .sort((a, b) =>
    //           //a.countryName.toLowerCase() > b.countryName.toLowerCase() ? 1 : -1
    //           a.locationName.toLowerCase() > b.locationName.toLowerCase()
    //             ? 1
    //             : -1
    //         )
    //     });
    // });
    getLocationDropdownList().then((rr) => {
      this.getAllLocation(rr.data);
    })
  }
  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onAllCharPress(event) {
    const re = /[a-z A-Z0-9=.;(){}\/*%#$@&,-_]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextKeyPress(event) {
    const re = /[a-z A-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusNumberKeyPress(event) {
    const re = /[0-9 a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusNumberWithNoSpaceKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onPasteCheck(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
    // const re = /[0-9a-zA-Z]+/g;
    // let splCharFound = false
    // if (event) {
    //   let valArr = event.split("")
    //   valArr.map(v => {
    //     console.log(v, !re.test(v))
    //     if (v) {
    //       console.log("check", !re.test(v) === true)
    //       if (!re.test(v) === true) {
    //         console.log("mking TextareaAutosize....")
    //         splCharFound = true
    //       }
    //     }
    //   })
    // }
    // console.log(splCharFound)
    // return splCharFound
  }
  _onWSTextPlusNumberWithNoSpaceKeyPress(event) {
    const re = /[0-9a-zA-Z&-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onUSPSKeyPress(event) {
    const re = /[0-9a-zA-Z-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  onYorNKeyPress(event) {
    const re = /[yYnN]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusDotPress(event) {
    const re = /[a-z A-Z.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusHyphenPress(event) {
    const re = /[a-z A-Z-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberPlusHyphenPress(event) {
    const re = /[0-9-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onTextPlusAllPress(event) {
    const re = /[a-z A-Z0-9=.;()-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  getAllLocation = (list) => {
    let locationList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        locationList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(locationList, "locationList");
  };
  sortDDList = (list, name) => {
    let oldCus = clone(list);

    if (name === "locationList") {
      oldCus &&
        oldCus.length > 0 &&
        this.setState({
          locationId: oldCus.filter((d) => d.value === "SG")[0].key,
          locationObj: oldCus.filter((d) => d.value === "SG")[0],
        });
    }
    let z = list.sort(function (a, b) {
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ [name]: z });
  };
  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };
  onAccKeyDown = (e) => {
    // e.keyCode === 8
    // getOfficeName(e.target.value.toUpperCase()).then((r) => {
    //   if (r) this.setState({ office: r.officeName, title: r.journalTitle });
    //   else this.setState({ office: "", title: "" });
    // });
    if (e.keyCode === 8)
      this.setState({ office: "", title: "" });
  }
  handleChange = async (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let eve = e
    let rBody = {};

    if (!e.target.value) {
      if (name === "acronym" || name === "volume" || name === "issue" || name === "suppNo") {
        this.setState({ [e.target.name]: e.target.value })
        this.setState({ journalId: "", issueId: "" });
        return
      }
      else{
        this.setState({ [e.target.name]: e.target.value })
      }
    }
    else
      this.setState({ [e.target.name]: e.target.value }, () => {
        if (name === "acronym") {
          // let x = ""
          // console.log(this._onPasteCheck(value))

          rBody = {
            Acronym: value && value.toUpperCase(),
            Volume: this.state.volume && this.state.volume.toUpperCase(),
            Issue: this.state.issue && this.state.issue.toUpperCase(),
            SuppNumber: this.state.suppNo && this.state.suppNo.toUpperCase(),
            journalType: this.state.jtObj && this.state.jtObj.text,
            customerName:
              this.state.customer &&
              this.state.customer.text &&
              this.state.customer.text.split("-")[0].trim(),
          };
        }
        if (name === "volume") {
          rBody = {
            Acronym: this.state.acronym && this.state.acronym.toUpperCase(),
            Volume: value && value.toUpperCase(),
            Issue: this.state.issue && this.state.issue.toUpperCase(),
            SuppNumber: this.state.suppNo && this.state.suppNo.toUpperCase(),
            journalType: this.state.jtObj && this.state.jtObj.text,
            customerName:
              this.state.customer &&
              this.state.customer.text &&
              this.state.customer.text.split("-")[0].trim(),
          };
        }
        if (name === "issue") {
          rBody = {
            Acronym: this.state.acronym && this.state.acronym.toUpperCase(),
            Volume: this.state.volume && this.state.volume.toUpperCase(),
            Issue: value && value.toUpperCase(),
            SuppNumber: this.state.suppNo && this.state.suppNo.toUpperCase(),
            journalType: this.state.jtObj && this.state.jtObj.text,
            customerName:
              this.state.customer &&
              this.state.customer.text &&
              this.state.customer.text.split("-")[0].trim(),
          };
        }
        if (name === "suppNo") {
          rBody = {
            Acronym: this.state.acronym && this.state.acronym.toUpperCase(),
            Volume: this.state.volume && this.state.volume.toUpperCase(),
            Issue: this.state.issue && this.state.issue.toUpperCase(),
            SuppNumber: value && value.toUpperCase(),
            journalType: this.state.jtObj && this.state.jtObj.text,
            customerName:
              this.state.customer &&
              this.state.customer.text &&
              this.state.customer.text.split("-")[0].trim(),
          };
        }
        if (
          name === "acronym" ||
          name === "volume" ||
          name === "issue" ||
          name === "suppNo"
        ) {
          if (value)
            this.setState({ [name]: value.toUpperCase() })
          if (
            this.state.acronym &&
            this.state.volume &&
            (this.state.customer && this.state.customer.id) &&
            (this.state.jtObj && this.state.jtObj.id) &&
            (this.state.suppNo || this.state.issue)
          )
            getJournalIssueID(rBody).then((r) => {
              if (r)
                this.setState({
                  journalId: r.journalUniqueID,
                  issueId: r.uniqueIssueID,
                });
              else this.setState({ journalId: "", issueId: "" });
            });
          else this.setState({ journalId: "", issueId: "" });
        }
      });
    if (e.target.name === "acronym" && value) {
      getOfficeName(e.target.value.toUpperCase()).then((r) => {
        if (r) this.setState({ office: r.officeName, title: r.journalTitle });
        else this.setState({ office: "", title: "" });
      });
    }
    // if (e.target.name === "acronym" && !value) {
    //   this.setState({ office: "", title: "" });
    // }
    if (name === "title") {
      this.setState({ title: value })
    }
  };

  handleClose = () => {
    // clean up state and errors
    this.setState({
      country: "",
      customer: "",

      location: "",
      dispatchType: "",
      noOfOrdersFrom: "",
      noOfOrdersTo: "",
      noOfCopiesFrom: "",
      noOfCopiesTo: "",
      weightFrom: "",
      weightTo: "",
      ppiNumber: "",
      ppiLicenseNo: "",
      returnAddress: "",
      carrierSheetScheme: "",
      carrierSheetMode: "",

      errors: {},
    });
  };
  onBlur = (event) => {
    const { routingCountry } = this.state;
    let dhlValues = event.target.value;
    dhlValues = this.addZeroes(dhlValues.toString(), 2);
    routingCountry[event.target.name] = dhlValues;
    this.setState({ routingCountry });
  };
  checkSubmit = () => {
    if (
      this.state.customerId &&
      this.state.title &&
      this.state.journalId &&
      this.state.acronym &&
      this.state.volume &&
      (this.state.issue || this.state.suppNo)
    )
      return true;
    else return false;
  };
  handleSubmit = () => {
    let loggedInId = localStorage.getItem("loggedInId");
    let reqBody = {
      locationID: this.state.locationId ? this.state.locationId : 0,
      customerID: this.state.customerId && this.state.customerId,
      acronym: this.state.acronym,
      volume: this.state.volume,
      issue: this.state.issue,
      uniqueIssueID: this.state.issueId,
      journalUniqueID: this.state.journalId,
      suppNumber: this.state.suppNo,
      partNumber: this.state.partNo,
      titleDescription: this.state.title,
      Remarks: this.state.Remarks,
      detailDescription: this.state.issueDesc,
      insert: this.state.insert,
      journalType: this.state.jtObj && this.state.jtObj.text,
      office: this.state.office,
      expectedDeliveryDate: this.state.expectedDeliveryDate,
      printRun: this.state.printRun ? +this.state.printRun : "",
      estMLR: this.state.estMLR ? +this.state.estMLR : "",
      estOMR: this.state.estOMR ? +this.state.estOMR : "",
      estBalanceofStock: this.state.estBalanceOfStock ? +this.state.estBalanceOfStock : "",
      estUnitWeight: this.state.estUnitWeight ? +this.state.estUnitWeight : "",
      returnbyAir: this.state.returnByAir ? +this.state.returnByAir : "",
      returnbySea: this.state.returnBySea ? +this.state.returnBySea : "",
      division: this.state.division,
      dimensions: this.state.dimension,
      printer: this.state.printer,
      specialInstruction: this.state.splInstruction,
      specialDeliveryInstructions: this.state.splDeliveryInstruction,
      offineFlag: this.state.offlineFlag,
      distributor: this.state.distributor,
      uspsPermit: this.state.uspsPermit,
      priorityTitle: this.state.priorityTitle,
      specialHandling: this.state.splHandling,
      journalReceivedDate: this.state.journalReceivedDate,
      actualPrintRunReceived: this.state.actualPrintRunReceived ? +this.state.actualPrintRunReceived : "",
      actualWeight: this.state.actualWeight ? +this.state.actualWeight : "",
      CPTWeight: this.state.CPTWeight ? +this.state.CPTWeight : "",
      BLWeight: this.state.BLWeight ? +this.state.BLWeight : "",
      mlCopies: this.state.mlCopies ? +this.state.mlCopies : "",
      omCopies: this.state.omCopies ? +this.state.omCopies : "",
      otCopies: this.state.otCopies ? +this.state.otCopies : "",
      hcCopies: this.state.hcCopies ? +this.state.hcCopies : "",
      eboCopies: this.state.eboCopies ? +this.state.eboCopies : "",
      issnNo: this.state.issnNo,
      publicationDate: this.state.publicationDate,
      publishingYear: +moment(this.state.publicationYear).format("YYYY"),
      cea: this.state.cea ? +this.state.cea : "",
      ces: this.state.ces ? +this.state.ces : "",
      emloManual: this.state.emloManual ? +this.state.emloManual : "",
      emloEmailUpload: this.state.emloEmailUpload ? +this.state.emloEmailUpload : "",
      bpaMarketingQty: this.state.bpaMarketingQty
        ? +this.state.bpaMarketingQty
        : "",
      createdBy: +loggedInId,
      modifiedBy: +loggedInId,
      estTotalWeight: this.state.estTotalWeight ? +this.state.estTotalWeight : "",
      isActive: true,
      statusID: null,
      ceaTrackingNO: "",
      cesTrackingNO: "",
      //offlineQty:this.state.offlineQty,
      createdDate: new Date(),
      modifiedDate: new Date(),
    };

    postJournalitemMasterList(reqBody, this.props.history).then((r) => { });
  };
  handleAutocompleteCustomer = (e, val) => {
    if (!val) {
      this.setState({ journalId: "", issueId: "" })
    }
    this.setState({ customerId: val && val.id, customer: val && val, issue: "", supp: "" });
    let newAcc = this.state.acronym;
    if (val) {
      if (val.value === "Wiley") {
        if (this.state.acronym.length > 4) {
          newAcc = this.state.acronym.slice(0, 4);
          this.setState({ acronym: this.state.acronym.slice(0, 4) });

          getOfficeName(this.state.acronym.slice(0, 4).toUpperCase()).then((r) => {
            if (r) this.setState({ office: r.officeName, title: r.journalTitle });
            else this.setState({ office: "", title: "" });
          });
        }
      }
      else if (this.state.acronym)
        getOfficeName(this.state.acronym.toUpperCase()).then((r) => {
          if (r) this.setState({ office: r.officeName, title: r.journalTitle });
          else this.setState({ office: "", title: "" });
        });
    }
    let rBody = {
      Acronym: newAcc && newAcc.toUpperCase(),
      Volume: this.state.volume && this.state.volume.toUpperCase(),
      Issue: this.state.issue && this.state.issue.toUpperCase(),
      SuppNumber: this.state.suppNo && this.state.suppNo.toUpperCase(),
      journalType: this.state.jtObj && this.state.jtObj.text,
      customerName: val && val.text.split("-")[0].trim(),
      // customerName:val && val.value
    };

    // if(val && val.text.includes("MacMillan")||val.text.includes("World Scienctific"))
    if (
      (val && val.value.includes("Npub_Mac")) ||
      (val && val.value.includes("WS"))
    )
      this.setState({
        type: this.state.journalTypeList[0].id,
        jtObj: this.state.journalTypeList[0],
      });
    if (
      val &&
      this.state.acronym &&
      this.state.volume &&

      (this.state.jtObj && this.state.jtObj.id) &&
      (this.state.suppNo || this.state.issue)
    )
      getJournalIssueID(rBody).then((r) => {
        if (r)
          this.setState({
            journalId: r.journalUniqueID,
            issueId: r.uniqueIssueID,
          });
        else this.setState({ journalId: "", issueId: "" });
      });

    else this.setState({ journalId: "", issueId: "" })

  };
  handleAutocompleteJT = (e, val) => {
    this.setState({ issue: "", suppNo: "" });
    this.setState({ type: val && val.id + "", jtObj: val && val });
    // console.log(val)
    if (!val) {
      this.setState({ journalId: "", issueId: "" })
    }
    if (this.state.acronym)
      getOfficeName(this.state.acronym.toUpperCase()).then((r) => {
        if (r) this.setState({ office: r.officeName, title: r.journalTitle });
        else this.setState({ office: "", title: "" });
      });
  };
  handleAutocompleteLocation = (e, val) => {
    this.setState({ locationId: val && val.key, locationObj: val && val });
  };
  handleSwitchPopUpOpen=()=>{
    this.setState({switchPopUpOpen:true})
  }
  closeSwitchPopUpOpen=()=>{
    this.setState({switchPopUpOpen:false})
  }
  redirectToFileUploadMode=()=>{
    this.setState({switchPopUpOpen:false})
  }
  render() {
    let { classes } = this.props;
    // console.log(this.state.customer)

    let {
      routingCountry,
      customersList,
      LocationList,
      customer,
      acronym,
      volume,
      issue,
      title,
      Remarks,
      suppNo,
      partNo,
      issueDesc,
      issueId,
      insert,
      type,
      office,
      expectedDeliveryDate,
      printRun,
      estMLR,
      estOMR,
      estBalanceOfStock,
      estUnitWeight,
      estTotalWeight,
      returnByAir,
      returnBySea,
      division,
      dimension,
      printer,
      splInstruction,
      splDeliveryInstruction,
      offlineFlag,
      distributor,
      uspsPermit,
      priorityTitle,
      splHandling,
      journalReceivedDate,
      actualPrintRunReceived,
      actualWeight,
      CPTWeight,
      BLWeight,
      mlCopies,
      omCopies,
      otCopies,
      hcCopies,
      eboCopies,
      issnNo,
      publicationDate,
      publicationYear,
      offlineQty,
      cea,
      ces,
      status,
      active,
      emloEmailUpload,
      emloManual,
    } = this.state;
    return (
      <React.Fragment>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            // onClose={this.handleSwitchPopUpOpen}
            aria-labelledby="customized-dialog-title"
            open={this.state.switchPopUpOpen}
            maxWidth="lg"
            >
                <DialogTitle id="customized-dialog-title">
                        Switch to Mass Upload 
                        
                      </DialogTitle>
                      <DialogContent dividers>
                        <div style={{ minWidth: "300px" }}>
                          Are you sure you want to switch to File Upload Mode
                          
                        </div>
                        <div>

                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          // autoFocus
                           onClick={this.closeSwitchPopUpOpen}
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                        >
                          Close
                        </Button>
                        <Button
                          //autoFocus
                           onClick={this.redirectToFileUploadMode}
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                        >
                          Switch
                        </Button>
          </DialogActions>
      </Dialog>
        <Paper>
          <div className="pop-up-header" style={{ paddingTop: ".5em", marginBottom: ".5em" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <span >Create Journal Item Master</span>
              {/* <Button variant="contained"
                color="primary" style={{ height: "42px", padding: "1em 2.2em", marginRight: "2em" }}
                onClick={this.handleSwitchPopUpOpen}
                >Try Mass Upload</Button> */}
            </div>
          </div>
          <Divider />
          <div className={classes.container}>
            <div>
              <div className={classes.container}>
                <div style={{ marginTop: "2em" }}>

                  <p className={classes.subHeader}>Journal Primary Info</p>

                  <div style={{ display: "flex" }}>
                    <Autocomplete
                      options={this.state.customerList}
                      value={this.state.customer}
                      onChange={this.handleAutocompleteCustomer}
                      getOptionLabel={(option) => option.text}
                      style={{ flex: 1, marginRight: "16px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Customer"
                          margin="normal"
                          required
                          fullWidth
                        />
                      )}
                    />
                    <Autocomplete
                      options={
                        this.state.customer &&
                          this.state.customer.text &&
                          (this.state.customer.text.includes("Wiley")
                            || this.state.customer.text.includes("WS")
                          )
                          ? this.state.journalTypeList
                          : this.state.journalTypeList2
                      }
                      value={this.state.jtObj}
                      onChange={this.handleAutocompleteJT}
                      getOptionLabel={(option) => option.text}
                      style={{ flex: 1, marginRight: "16px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Journal type"
                          margin="normal"
                          required
                          fullWidth
                        />
                      )}
                    />
                    <TextField
                      required
                      label="Acronym"
                      name="acronym"
                      inputProps={{
                        maxLength:
                          this.state.customer &&
                            this.state.customer.value === "Wiley"
                            ? 4
                            : 14,
                      }}
                      onKeyPress={this._onTextPlusNumberWithNoSpaceKeyPress}
                      // onPaste={e => {
                      //   e.preventDefault()
                      //   // this._onPasteCheck(e)
                      //   // console.log("fg")
                      // }}
                      margin="normal"
                      style={{ flex: 1 }}
                      value={acronym}
                      onChange={this.handleChange}
                      onKeyDown={this.onAccKeyDown}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      required
                      label="Volume"
                      name="volume"
                      inputProps={{
                        maxLength: 4,
                      }}
                      onChange={this.handleChange}
                      onKeyPress={this._onTextPlusNumberWithNoSpaceKeyPress}
                      // onPaste={this._onTextPlusNumberWithNoSpaceKeyPress}
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={volume}
                    />
                    <TextField
                      label="Issue"
                      name="issue"
                      inputProps={{
                        maxLength: 7,
                      }}
                      required={
                        this.state.jtObj && this.state.jtObj.text === "ISS"
                      }
                      disabled={
                        this.state.jtObj && this.state.jtObj.text === "SUPP"
                      }
                      onKeyPress={this.state.customer && this.state.customer.value === "WS" ? this._onWSTextPlusNumberWithNoSpaceKeyPress : this._onTextPlusNumberWithNoSpaceKeyPress}
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={issue}
                      onChange={this.handleChange}

                    />
                    <TextField
                      required={
                        this.state.jtObj && this.state.jtObj.text === "SUPP"
                      }
                      label="Supp Number"
                      name="suppNo"
                      disabled={
                        this.state.jtObj && this.state.jtObj.text === "ISS"
                      }
                      inputProps={{
                        maxLength: 6,
                      }}
                      // InputProps={{
                      //   startAdornment: (
                      //     <InputAdornment position="start">S</InputAdornment>
                      //   ),
                      // }}
                      // onKeyPress={this._onTextPlusNumberWithNoSpaceKeyPress}

                      onKeyPress={this.state.customer && this.state.customer.value === "WS" ? this._onWSTextPlusNumberWithNoSpaceKeyPress : this._onTextPlusNumberWithNoSpaceKeyPress}
                      margin="normal"
                      style={{ flex: 1 }}
                      value={suppNo}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      label="Part Number"
                      name="partNo"
                      inputProps={{
                        maxLength: 7,
                      }}
                      onKeyPress={this._onNumberKeyPress}
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={partNo}
                      onChange={this.handleChange}
                    />
                    <TextField
                      label="Title Description"
                      name="title"
                      required
                      inputProps={{
                        maxLength: 50,
                      }}
                      onKeyPress={this._onAllCharPress}
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={title}
                      onChange={this.handleChange}
                    />
                    <TextField
                      label="Detail Description"
                      name="issueDesc"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onKeyPress={this._onAllCharPress}
                      margin="normal"
                      style={{ flex: 1 }}
                      value={issueDesc}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      label="Unique Issue Id"
                      name="issueId"
                      inputProps={{
                        maxLength: 36,
                      }}
                      InputProps={{ readOnly: true }}
                      //onKeyPress={this._onTextPlusNumberKeyPress}
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={issueId ? issueId : ""}
                    //onChange={this.handleChange}
                    />
                    <TextField
                      label="Issue Description"
                      name="journalId"
                      InputProps={{ readOnly: true }}
                      inputProps={{
                        maxLength: 36,
                      }}
                      // onKeyPress={this._onTextPlusNumberKeyPress}
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={this.state.journalId ? this.state.journalId : ""}
                    // onChange={this.handleChange}
                    />
                    <TextField
                      label="Insert"
                      name="insert"
                      inputProps={{
                        maxLength: 1,
                      }}
                      helperText="Please enter Y or N"
                      onKeyPress={this.onYorNKeyPress}
                      margin="normal"
                      style={{ flex: 1 }}
                      value={insert}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    {/* <TextField
                        required
                        label="Journal Type"
                        name="type"
                        inputProps={{
                          maxLength: 50
                        }}
                        onKeyPress={this._onTextKeyPress}
                        // onKeyPress={this._onCodeKeyPress}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        value={type}
                        onChange={this.handleChange}
                      /> */}
                    <TextField
                      //required
                      label="Office"
                      name="office"
                      inputProps={{
                        maxLength: 20,
                      }}
                      onKeyPress={this._onTextPlusNumberKeyPress}
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={office}
                      onChange={this.handleChange}
                    />
                    <TextField
                      label="Remarks"
                      name="Remarks"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onKeyPress={this._onAllCharPress}
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={Remarks}
                      onChange={this.handleChange}
                    />

                    <Autocomplete
                      options={this.state.locationList}
                      value={this.state.locationObj}
                      disableClearable
                      style={{ flex: 1 }}
                      onChange={this.handleAutocompleteLocation}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="BL Location"
                          margin="normal"
                          required
                          fullWidth
                        />
                      )}
                    />

                  </div>
                </div>
                {/* ............................................................... */}
                <p className={classes.subHeader}>Journal Primary Instruction</p>
                <div style={{ display: "flex" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast
                      //required
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      name="expectedDeliveryDate"
                      style={{ flex: 1, marginRight: "16px" }}
                      id="pickupDate"
                      label="Expected Delivery Date"
                      value={expectedDeliveryDate}
                      minDate={new Date("2000-01-01")}
                      onChange={(e) =>
                        this.handleDateChange(e, "expectedDeliveryDate")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <TextField
                    label="Print Run"
                    name="printRun"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={printRun}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Est MLR"
                    name="estMLR"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={estMLR}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Est OMR"
                    name="estOMR"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={estOMR}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Est Balance Of Stock"
                    name="estBalanceOfStock"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={estBalanceOfStock}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Est Unit Weight"
                    name="estUnitWeight"
                    inputProps={{
                      maxLength: 10,
                    }}
                    //defaultValue="0.000"
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={estUnitWeight}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Est Total Weight"
                    name="estTotalWeight"
                    inputProps={{
                      maxLength: 10,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={estTotalWeight}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Return By Air"
                    name="returnByAir"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={returnByAir}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Return By Sea"
                    name="returnBySea"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={returnBySea}
                    onChange={this.handleChange}
                  />
                </div>
                {/* .................................................................. */}
                <p className={classes.subHeader}>
                  Journal Secondary Instruction
                </p>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Division"
                    name="division"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onAllCharPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={division}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Dimension"
                    name="dimension"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={dimension}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Printer"
                    name="printer"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={printer}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "flex-end"  }}>
                  <TextField
                    multiline
                    label="Special Instruction"
                    name="splInstruction"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onKeyPress={this._onAllCharPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={splInstruction}
                    onChange={this.handleChange}
                  />
                  <TextField
                    multiline
                    label="Special Delivery Instruction"
                    name="splDeliveryInstruction"
                    inputProps={{
                      maxLength: 300,
                    }}
                    onKeyPress={this._onAllCharPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={splDeliveryInstruction}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Offline Flag"
                    name="offlineFlag"
                    inputProps={{
                      maxLength: 1,
                    }}
                    helperText="Please enter Y or N"
                    onKeyPress={this.onYorNKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginBottom: "-12px"}}
                    value={offlineFlag}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Distributor"
                    name="distributor"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onAllCharPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={distributor}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="USPS Permit"
                    name="uspsPermit"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onUSPSKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={uspsPermit}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Priority Title"
                    name="priorityTitle"
                    inputProps={{
                      maxLength: 1,
                    }}
                    onKeyPress={this.onYorNKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={priorityTitle}
                    onChange={this.handleChange}
                    helperText="Please enter Y or N"
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                  multiline
                    label="Special Handling"
                    name="splHandling"
                    inputProps={{
                      maxLength: 300,
                    }}
                    onKeyPress={this._onAllCharPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={splHandling}
                    onChange={this.handleChange}
                  />
                  <div style={{ flexGrow: 1, marginRight: "16px" }}></div>
                  <div style={{ flexGrow: 1 }}></div>
                </div>
                {/* .............................................................. */}
                <p className={classes.subHeader}>Journal Actual Info</p>
                <div style={{ display: "flex" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      name="journalReceivedDate"
                      style={{ flex: 1, marginRight: "16px" }}
                      id="pickupDate"
                      label="Journal Received Date"

                      value={journalReceivedDate}
                      onChange={(e) =>
                        this.handleDateChange(e, "journalReceivedDate")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <TextField
                    label="Actual Print Run Received"
                    name="actualPrintRunReceived"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={actualPrintRunReceived}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Actual Weight"
                    name="actualWeight"
                    inputProps={{
                      maxLength: 15,
                    }}
                    //defaultValue="0.000"
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={actualWeight}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="ML Copies"
                    name="mlCopies"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={mlCopies}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="OM Copies"
                    name="omCopies"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={omCopies}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Culling Copies"
                    name="otCopies"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={otCopies}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="EMLO Email Upload"
                    name="emloEmailUpload"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={emloEmailUpload}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="EMLO Manual"
                    name="emloManual"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={emloManual}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="HC Copies"
                    name="hcCopies"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={hcCopies}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  {/* <TextField
                    label="Offline Qty"
                    name="offlineQty"
                    inputProps={{
                      maxLength: 50
                    }}
                    
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{flex: 1, marginRight: "16px"}}
                    value={offlineQty}
                    onChange={this.handleChange}
                  /> */}
                  <TextField
                    label="CEA"
                    name="cea"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={cea}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="CES"
                    name="ces"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={ces}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Bpa Marketing Qty"
                    name="bpaMarketingQty"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={this.state.bpaMarketingQty}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="CPT Weight"
                    name="CPTWeight"
                    inputProps={{
                      maxLength: 15,
                    }}
                    //defaultValue="0.000"
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={CPTWeight}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="BL Weight"
                    name="BLWeight"
                    inputProps={{
                      maxLength: 15,
                    }}
                    //defaultValue="0.000"
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={BLWeight}
                    onChange={this.handleChange}
                  />
                  <div style={{ flexGrow: 1 }}></div>
                </div>
                <p className={classes.subHeader}>Journal WSM Info</p>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="ISSN No"
                    name="issnNo"
                    inputProps={{
                      maxLength: 15,
                    }}
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={issnNo}
                    onChange={this.handleChange}
                  />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      name="publicationDate"
                      style={{ flex: 1, marginRight: "16px" }}
                      id="pickupDate"
                      label="Publication Date"
                      value={publicationDate}
                      onChange={(e) =>
                        this.handleDateChange(e, "publicationDate")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <KeyboardDatePicker
                    autoOk
                    //disablePast

                    views={["year"]}
                    disableToolbar
                    variant="inline"
                    format="yyyy"
                    margin="normal"
                    name="publicationYear"
                    style={{ flex: 1 }}
                    id="pickupDate"
                    label="Publication Year"
                    value={publicationYear}
                    onChange={(e) =>
                      this.handleDateChange(e, "publicationYear")
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </div>
                <Divider style={{ margin: "15px 0 5px" }} />
                <div
                  className="button-wrapper"
                  style={{ paddingLeft: "0px", marginLeft: "0px" }}
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/pubJournalItemMaster")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    <Button
                      disabled={!this.checkSubmit()}
                      autoFocus
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={this.handleSubmit}
                    //className="enabled-button"
                    // className={
                    //   !this.state.formValid
                    //     ? "disabled-button"
                    //     : "enabled-button "
                    // }
                    >
                      SUBMIT
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(AddJournalItemMaster));
