import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { getEMLOByID, putEmloList } from "./action";
import DateFnsUtils from "@date-io/date-fns";
import IndividualSearchAddDialog from "../../publisherBackLabel/ebloOrderPlacement/IndividualSearchAddDialog";
import { getDHLRateUponAWeight } from "../mainLabelOrderPlacement/action"
import { resourceValidation } from "../../../shared/resource";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { getCountryDropdownList } from "../../masters/masterActions";
import { Autocomplete } from "@material-ui/lab";
const validateMaxLength2 = new Validator("maxlength2");
const mobileValidator = new Validator("mobile");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginRight: "16px",
    fontSize: "11px !important",
    flex: 1,
  },
  emptyField: {
    marginRight: "16px",
    flexGrow: 1,
  },
  lastChild: {
    marginRight: 0,
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    // marginLeft: "7px",
    // marginLeft: "1.5em",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
});

class AddInsertMaster extends React.Component {
  constructor() {
    super();

    this.state = {
      openDialog: false,
      customersList: [],
      customer: "",
      emlo: "",
      journalID: 0,
      orderCreatedBy: "",
      countryList: [],
      country: {},
      countryID: "",
      countryCode: "",
      countryName: "",
      department: "",
      issueType: "",
      category: "",
      acronym: "",
      volume: "",
      issue: "",
      supplement: "",
      part: "",
      uploadFiles: "",
      issueDescription: "",
      quantity: "",
      unitWeight: "0.000",
      totalWeight: "0.000",
      orderDate: null,
      distributerDate: null,
      deliveryMethod: "",
      deliveryDetails: "",
      companyName: "",
      recipientName: "",
      department: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      dept: "",
      state: "",
      postCode: "",
      country: "",
      countryCode: "",
      areaCode: "",
      number: "",
      trackingNumber: "",
      noOfCartons: 0,
      dhlRate: "0.00",
      actualWeight: "0.000",
      deliveryDate: null,
      isTrackingInfoRequired: "",
      additionalInstructions: "",
      formValid: false,
      updatedValues: false,
      errors: {},
      orderDate: null,
      distributerDate: null,

      emloValid: true,
      orderCreatedByValid: true,
      departmentValid: true,
      issueTypeValid: true,
      categoryValid: true,
      acronymValid: true,
      volumeValid: true,
      issueValid: true,
      supplementValid: true,
      partValid: true,
      issueDescriptionValid: true,
      quantityValid: true,
      unitWeightValid: true,
      totalWeightValid: true,
      orderDateValid: true,
      distributerDateValid: true,
      deliveryMethodValid: true,
      deliveryDetailsValid: true,
      companyNameValid: true,
      recipientNameValid: true,
      departmentValid: true,
      addressLine1Valid: true,
      addressLine2Valid: true,
      addressLine3Valid: true,
      cityValid: true,
      stateValid: true,
      postCodeValid: true,
      countryValid: true,
      countryCodeValid: true,
      areaCodeValid: true,
      numberValid: true,
      trackingNumberValid: true,
      actualWeightValid: true,
      deliveryDateValid: true,
      journalData: {
        journalID: "",
        issueDescription: "",
        acronym: "",
        journalTitle: "",
        volume: "",
        issue: "",
        supplement: "",
      },
    };
  }

  handleAddJournalClick = () => {
    this.setState({
      openDialog: true,
    });
  };

  handleAddJournalClose = () => {
    this.setState({
      openDialog: false,
    });
  };

  handleJournalUpdate = (selectedJournalList) => {
    this.setState({
      journalData: selectedJournalList[0],
      openDialog: false,
      formValid: true,
      updatedValues: true,
    });
  };

  componentDidMount() {
    this.props.handleSettingHeader("EMLO Order Placement");
    this.props.setSelectedList(
      "publisherMainLabelOpen",
      "pubEmloOrderPlacement"
    );

    this.getAllData();

    getCountryDropdownList().then((r) => {
      r.data &&
        this.setState({
          countryList:
            r &&
            r.data &&
            r.data.sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
    });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let phoneValid = this.state.phoneValid;
    switch (fieldName) {
      case "number":
        if (value !== "") {
          phoneValid = true;
          fieldValidationErrors.number = phoneValid
            ? ""
            : resourceValidation.number;
        }
        break;

      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        phoneValid: phoneValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    if (
      this.state.quantity === "" ||
      (!this.state.addressLine1 && !this.state.addressLine2 && !this.state.addressLine3) ||
      this.state.postCode === "" ||
      (this.state.country && Object.keys(this.state.country).length === 0) ||
      this.state.countryCode === ""
    ) {
      return true;
    }
    else {
      return false;
    }
  }

  handleAutocompleteCountry = (e, val) => {
    this.setState({
      countryName: val && val.text && val.text.split("- ")[1],
      countryID: val && val.id,
      country: val && val,
      countryCode: val == null ? "" : val && val.value,
      updatedValues: true,
    });
    this.validateField(e.target.name, val);
    if (val && val.id && this.state.actualWeight)
      getDHLRateUponAWeight(val.id, this.state.actualWeight).then(r => {
        // console.log(r)
        if (r && r.data) {
          this.setState({ dhlRate: r.data.dhlRate ? r.data.dhlRate : "" });
        }
        else this.setState({ dhlRate: "" });
      })
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let insertMasterId =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];

    let acronym =
      this.state.journalData.acronym == ""
        ? this.state.acronym
        : this.state.journalData.acronym;
    let volume =
      this.state.journalData.volume == ""
        ? this.state.volume
        : this.state.journalData.volume;
    let issue =
      this.state.journalData.issue == ""
        ? this.state.issue
        : this.state.journalData.issue;
    let supp =
      this.state.journalData.supplement == ""
        ? this.state.supplement
        : this.state.journalData.supplement;
    let issueDescription =
      this.state.journalData.issueDescription == ""
        ? this.state.issueDescription
        : this.state.journalData.issueDescription;

    // let customerID = this.state.customerId;

    let journalID =
      this.state.journalData.journalID == ""
        ? this.state.journalID
        : this.state.journalData.journalID;
    let emloNo = this.state.emlo;
    let orderCreatedBy = this.state.orderCreatedBy;
    let department = this.state.department;
    let issueType = this.state.issueType;
    let category = this.state.category;
    let part = this.state.part;
    let quantity = this.state.quantity;
    let estUnitWeight = this.state.unitWeight;
    let estTotalWeight = this.state.totalWeight;
    let orderDate = this.state.orderDate ? moment(this.state.orderDate).format("YYYY-MM-DD") : null;;
    let stocktoDistributorDate = this.state.distributerDate ? moment(this.state.distributerDate).format("YYYY-MM-DD") : null;;
    let deliveryMethod = this.state.deliveryMethod;
    let deliveryDetails = this.state.deliveryDetails;
    let companyName = this.state.companyName;
    let recipientName = this.state.recipientName;
    let dept = this.state.dept;
    let addressLine1 = this.state.addressLine1;
    let addressLine2 = this.state.addressLine2;
    let addressLine3 = this.state.addressLine3;
    let city = this.state.city;
    let state = this.state.state;
    let postCode = this.state.postCode;
    let countryID = this.state.countryID;
    let countryCode = this.state.countryCode;
    let areaCode = this.state.areaCode;
    let number = this.state.number;
    let isTrackingInfoRequired = this.state.isTrackingInfoRequired;
    let additionalInstructions = this.state.additionalInstructions;
    let trackingNumber = this.state.trackingNumber;
    let dhlRate = this.state.dhlRate;
    // let noOfCartons = this.state.noOfCartons?;
    let noOfCartons = this.state.noOfCartons ? this.state.noOfCartons : 0

    let actualWeight = this.state.actualWeight;
    let deliveryDate = this.state.deliveryDate ? moment(this.state.deliveryDate).format("YYYY-MM-DD") : null;
    let uploadedFiles = this.state.uploadFiles;
    let isActive = true;
    let modifiedBy = localStorage.loggedInId;
    let countryName = this.state.countryName;
    let reqbody1 = {
      journalID,
      orderCreatedBy,
      department,
      issueType,
      category,
      part,
      quantity,
      estUnitWeight,
      estTotalWeight,
      orderDate,
      stocktoDistributorDate,
      deliveryMethod,
      deliveryDetails,
      companyName,
      recipientName,
      dept,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      state,
      postCode,
      countryID,

      countryCode,
      areaCode,
      number,
      isTrackingInfoRequired,
      additionalInstructions,
      uploadedFiles,
      trackingNumber,
      noOfCartons,
      dhlRate,

      actualWeight,
      deliveryDate,
      isActive,
      modifiedBy,
      countryName,
      acronym,
      volume,
      issue,
      issueDescription,
      supp,
    };

    putEmloList(insertMasterId, reqbody1, this.props.history);
  };

  getAllData = async () => {
    let emloID = window.location.pathname.split("/")[2];
    await getEMLOByID(emloID).then((r) => {
      this.setState({
        // emloData: r,
        emlo: r.emloNo,
        orderCreatedBy: r.orderCreatedBy,
        department: r.department,
        issueType: r.issueType,
        category: r.category,
        acronym: r.acronym,
        volume: r.volume,
        issue: r.issue,
        supplement: r.supp,
        part: r.part,
        issueDescription: r.issueDescription,
        quantity: r.quantity,
        unitWeight: r.estUnitWeight,
        totalWeight: r.estTotalWeight,
        orderDate: r.orderDate,
        distributerDate: r.stocktoDistributorDate,
        deliveryMethod: r.deliveryMethod,
        deliveryDetails: r.deliveryDetails.trim(),
        companyName: r.companyName,
        recipientName: r.recipientName,
        department: r.department,
        addressLine1: r.addressLine1,
        addressLine2: r.addressLine2,
        addressLine3: r.addressLine3,
        city: r.city,
        state: r.state,
        postCode: r.postCode,
        country: {
          id: r.countryID,
          text: r.countryName,
          value: r.countryName,
        },
        dept: r.dept,
        countryCode: r.countryCode,
        countryID: r.countryID,
        countryName: r.countryName,
        areaCode: r.areaCode,
        number: r.number,
        trackingNumber: r.trackingNumber,
        dhlRate: r.dhlRate ? r.dhlRate : "0.00",
        noOfCartons: r.noOfCartons ? r.noOfCartons : 0,
        actualWeight: r.actualWeight,
        deliveryDate: r.deliveryDate,
        isTrackingInfoRequired: r.isTrackingInfoRequired,
        additionalInstructions: r.additionalInstructions,
        uploadFiles: r.uploadedFiles,
        journalID: r.journalID,
        status: r.status.pubStatusName,
      });
    });
  };

  handleChange = (e) => {
    this.setState({ updatedValues: true });
    if (e.target.name == "number") {
      let newRrrors = { ...this.state.errors };
      let result = mobileValidator(e.target.value);
      newRrrors[e.target.name] = !result;
      this.setState({ [e.target.name]: e.target.value });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    this.validateField(e.target.name, e.target.value);
    if (e.target && e.target.name == "actualWeight") {
      if (e.target && e.target.value && this.state.country && this.state.country.id)
        getDHLRateUponAWeight(this.state.country.id, e.target.value).then(r => {
          // console.log(r)
          if (r && r.data) {
            this.setState({ dhlRate: r.data.dhlRate ? r.data.dhlRate : "" });
          }
          else this.setState({ dhlRate: "" });
        })

    }
  };
  handleClose = () => {
    // clean up state and errors
    let newZone = { ...this.state.Zone };
    newZone.ZoneCode = "";
    newZone.ZoneName = "";
    this.setState({
      Zone: newZone,
      errors: {},
    });
  };

  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertKeyPress(event) {
    const re = /[a-zA-Z0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _freetext(event) {
    const re = /[#.0-9a-zA-Z\s,-@()%!$%^&*{}/<>_;:'"()+ |]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  onYorNKeyPress(event) {
    const re = /[yYnN]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onTextKeyPress(event) {
    const re = /[a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusNumberKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onWeightKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertSizeKeyPress(event) {
    const re = /[0-9 a-zA-Z-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusAllPress(event) {
    const re = /[a-z A-Z0-9=.;()&@]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onMobileNumberKeyPress(event) {
    const re = /[0-9+ ()-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onEmail(event) {
    const re = /[a-z A-Z0-9=.;()&@]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  onBlur = (event) => {
    // const { dhlRateValues } = this.state;
    let emloValues = event.target.value;
    emloValues = this.addZeroes(emloValues.toString(), 3);
    this.setState({ [event.target.name]: emloValues });
  };

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  handleDateChange = (date, name) => {
    this.setState({ [name]: date, updatedValues: true });
  };

  render() {
    let { classes } = this.props;
    let { emloData, journalData } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update EMLO</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <div className={classes.container}>
                {/* <p className={classes.subHeader} style={{ fontWeight: "500" }}>
                  Updat Insert Master
                </p> */}

                <p
                  className={classes.subHeader}
                  style={{ display: "inline-block" }}
                >
                  Journal Details
                </p>
                {this.state.status != "Processed" && (
                  <Button
                    onClick={this.handleAddJournalClick}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{
                      display: "inline-block",
                      alignItems: "text-bottom",
                      width: "172px",
                      padding: ".2em",
                      marginLeft: "1em",
                      boxShadow: "none",
                    }}
                  >
                    <span>CHOOSE JOURNAL</span>
                  </Button>
                )}
                {this.state.openDialog && (
                  // <PopupSearch
                  //   // jobGroupList={jobGroupList}
                  //   clearJournalInfo={this.clearJournalInfo}
                  //   handleJournalInfo={this.handleJournalUpdate}
                  //   selectedJournal={this.state.journalData}
                  // />

                  <IndividualSearchAddDialog
                    title="Journal Pick Up"
                    showDialog={this.state.openDialog}
                    handleAddJournalClose={this.handleAddJournalClose}
                    handleJournalUpdate={this.handleJournalUpdate}
                  />
                )}
                <div style={{ display: "flex" }}>
                  <TextField
                    required
                    label="Acronym"
                    name="acronym"
                    inputProps={{
                      maxLength: 4,
                      readOnly: true,
                    }}
                    variant="filled"
                    onKeyPress={this._onTextPlusNumberKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={journalData.acronym || this.state.acronym}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required
                    label="Volume"
                    name="volume"
                    inputProps={{
                      maxLength: 4,
                      readOnly: true,
                    }}
                    variant="filled"
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={journalData.volume || this.state.volume}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required
                    label="Issue"
                    name="issue"
                    inputProps={{
                      maxLength: 7,
                      readOnly: true,
                    }}
                    variant="filled"
                    onKeyPress={this._onTextPlusNumberKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={journalData.issue || this.state.issue}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required
                    label="Supplement"
                    name="supplement"
                    inputProps={{
                      maxLength: 7,
                      readOnly: true,
                    }}
                    variant="filled"
                    onKeyPress={this._onTextPlusNumberKeyPress}
                    margin="normal"
                    className={[classes.textField, classes.lastChild].join(" ")}
                    value={journalData.supplement || this.state.supplement}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    required
                    label="Issue Description"
                    name="issueDescription"
                    inputProps={{
                      maxLength: 50,
                      readOnly: true,
                    }}
                    variant="filled"
                    onKeyPress={this._onTextPlusNumberKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={
                      journalData.issueDescription ||
                      this.state.issueDescription
                    }
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Part"
                    name="part"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.part}
                    onChange={this.handleChange}
                  />
                  <div style={{ flexGrow: 1, marginRight: "16px" }}></div>
                  <div style={{ flexGrow: 1 }}></div>
                </div>
                <p className={classes.subHeader}>EMLO Details</p>
                <div style={{ display: "flex" }}>
                  <TextField
                    required
                    label="EMLO#"
                    name="emlo"
                    variant={"filled"}
                    inputProps={{
                      maxLength: 30,
                      readOnly: true,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    value={this.state.emlo}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Order Created By"
                    name="orderCreatedBy"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.orderCreatedBy}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Department"
                    name="department"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.department}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Issue Type"
                    name="issueType"
                    inputProps={{
                      maxLength: 30,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={[classes.textField, classes.lastChild].join(" ")}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.issueType}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Category"
                    name="category"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.category}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required
                    label="Quantity"
                    name="quantity"
                    inputProps={{
                      maxLength: 50,
                    }}
                    //onKeyPress={this._onTextKeyPress}
                    onKeyPress={this._onNumberKeyPress}
                    // onKeyPress={this._onCodeKeyPress}
                    margin="normal"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.quantity}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Estd Unit Weight"
                    name="unitWeight"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onWeightKeyPress}
                    margin="normal"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.unitWeight || "0.000"}
                    onChange={this.handleChange}
                    onBlur={(e) => this.onBlur(e, "unitWeight")}
                  />
                  <TextField
                    label="Estd Total Weight"
                    name="totalWeight"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onWeightKeyPress}
                    margin="normal"
                    className={[classes.textField, classes.lastChild].join(" ")}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.totalWeight || "0.000"}
                    onChange={this.handleChange}
                    onBlur={(e) => this.onBlur(e, "totalWeight")}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  {this.state.status == "Processed" ? (
                    <TextField
                      label="Order Date"
                      name="orderDate"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onKeyPress={this._onWeightKeyPress}
                      margin="normal"
                      className={classes.textField}
                      variant={
                        this.state.status == "Processed" ? "filled" : "standard"
                      }
                      value={moment(this.state.orderDate).format("DD-MM-YYYY")}
                      onBlur={(e) => this.onBlur(e, "orderDate")}
                    />
                  ) : (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          autoOk
                          disableToolbar
                          format="dd/MM/yyyy"
                          margin="normal"
                          minDateMessage={""}
                          name="orderDate"
                          className={classes.textField}
                          id="orderDate"
                          label="Order Date"
                          variant={"inline"}
                          value={this.state.orderDate}
                          onChange={(e) => this.handleDateChange(e, "orderDate")}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  {this.state.status == "Processed" ? (
                    <TextField
                      label="Distributor Date"
                      name="distributerDate"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onKeyPress={this._onWeightKeyPress}
                      margin="normal"
                      className={classes.textField}
                      variant={
                        this.state.status == "Processed" ? "filled" : "standard"
                      }
                      value={moment(this.state.distributerDate).format(
                        "DD-MM-YYYY"
                      )}
                      onBlur={(e) => this.onBlur(e, "distributerDate")}
                    />
                  ) : (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          autoOk
                          // disablePast
                          //required
                          disableToolbar
                          format="dd/MM/yyyy"
                          margin="normal"
                          minDate={new Date("01-01-2020")}
                          error={false}
                          minDateMessage={""}
                          name="distributerDate"
                          className={classes.textField}
                          id="distributerDate"
                          label="Distributor Date"
                          variant={
                            this.state.status == "Processed"
                              ? "filled"
                              : "standard"
                          }
                          value={this.state.distributerDate}
                          onChange={(e) =>
                            this.handleDateChange(e, "distributerDate")
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  <TextField
                    label="Delivery Method"
                    name="deliveryMethod"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.deliveryMethod}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Delivery Details"
                    name="deliveryDetails"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={[classes.textField, classes.lastChild].join(" ")}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.deliveryDetails.trim()}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    label="Company Name"
                    name="companyName"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.companyName}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Recipient Name"
                    name="recipientName"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.recipientName}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Dept"
                    name="dept"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.dept}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required={!this.state.addressLine1 && !this.state.addressLine2 && !this.state.addressLine3}
                    label="Address Line 1"
                    name="addressLine1"
                    inputProps={{
                      maxLength: 50,
                    }}
                    multiline
                    rowsMax="4"
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={[classes.textField, classes.lastChild].join(" ")}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.addressLine1}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    required={!this.state.addressLine1 && !this.state.addressLine2 && !this.state.addressLine3}
                    label="Address Line 2"
                    name="addressLine2"
                    inputProps={{
                      maxLength: 50,
                    }}
                    multiline
                    rowsMax="4"
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.addressLine2}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required={!this.state.addressLine1 && !this.state.addressLine2 && !this.state.addressLine3}
                    label="Address Line 3"
                    name="addressLine3"
                    inputProps={{
                      maxLength: 50,
                    }}
                    multiline
                    rowsMax="4"
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.addressLine3}
                    onChange={this.handleChange}
                  />
                  <Autocomplete
                    options={this.state.countryList}
                    value={this.state.country}
                    onChange={this.handleAutocompleteCountry}
                    getOptionLabel={(option) => option.text}
                    className={classes.textField}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        // name="plannerId"
                        variant={
                          this.state.status == "Processed"
                            ? "filled"
                            : "standard"
                        }
                        margin="normal"
                        required
                        fullWidth
                      />
                    )}
                  />
                  <TextField
                    label="Country Code"
                    name="countryCode"
                    inputProps={{
                      maxLength: 50,
                      readOnly: true,
                    }}
                    onKeyPress={this._onTextKeyPress}
                    margin="normal"
                    className={[classes.textField, classes.lastChild].join(" ")}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.countryCode}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="City"
                    name="city"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.city}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="State"
                    name="state"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.state}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Area Code"
                    name="areaCode"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.areaCode.trim()}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required
                    label="Postal Code"
                    name="postCode"
                    inputProps={{
                      maxLength: 10,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    className={[classes.textField, classes.lastChild].join(" ")}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.postCode}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    label="Phone Number"
                    name="number"
                    inputProps={{
                      maxLength: 20,
                    }}
                    onKeyPress={this._onMobileNumberKeyPress}
                    margin="normal"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.number}
                    onChange={this.handleChange}
                  // error={this.state.errors.number}
                  // helperText={
                  //   this.state.errors.number
                  //     ? resourceValidation.validPhoneNo
                  //     : ""
                  // }
                  />

                  <TextField
                    // label="Do you want tracking information automatically emailed to you upon dispatch via courier?
                    // "
                    name="isTrackingInfoRequired"
                    inputProps={{
                      maxLength: 1,
                    }}
                    helperText="Please enter Y or N"
                    onKeyPress={this.onYorNKeyPress}
                    margin="normal"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.isTrackingInfoRequired}
                    label={"Tracking information ?"}
                    multiline
                    style={{ position: "relative", top: "20px" }}
                    rowsMax="4"
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Additional Instructions"
                    name="additionalInstructions"
                    inputProps={{
                      maxLength: 100,
                    }}
                    // helperText="Please send ROWversion."
                    onKeyPress={this._freetext}
                    margin="normal"
                    multiline
                    rowsMax="4"
                    className={classes.textField}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.additionalInstructions}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Uploaded Files"
                    name="uploadFiles"
                    inputProps={{
                      maxLength: 50,
                    }}
                    // helperText="Please send ROWversion."
                    onKeyPress={this._freetext}
                    margin="normal"
                    multiline
                    rowsMax="4"
                    className={[classes.textField, classes.lastChild].join(" ")}
                    variant={
                      this.state.status == "Processed" ? "filled" : "standard"
                    }
                    value={this.state.uploadFiles}
                    onChange={this.handleChange}
                  />
                </div>
                <p className={classes.subHeader}>Actual Info</p>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Actual Weight"
                    name="actualWeight"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onWeightKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={this.state.actualWeight || "0.000"}
                    onChange={this.handleChange}
                    onBlur={(e) => this.onBlur(e, "actualWeight")}
                  />
                  <TextField
                    label="Tracking Number"
                    name="trackingNumber"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    value={this.state.trackingNumber}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="No Of Cartons"
                    name="noOfCartons"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={this.state.noOfCartons}
                    onChange={this.handleChange}
                  // disabled={isManual ? false : true}
                  />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      // disablePast
                      //required
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      // minDate={new Date("01-01-2020")}
                      error={false}
                      minDateMessage={""}
                      name="deliveryDate"
                      // className={classes.textField}
                      className={[classes.textField, classes.lastChild].join(
                        " "
                      )}
                      id="deliveryDate"
                      label="Delivery Date"
                      value={this.state.deliveryDate}
                      onChange={(e) => this.handleDateChange(e, "deliveryDate")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>

                  {/* <div style={{ flexGrow: 1 }}></div> */}
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="DHL Rate"
                    name="dhlRate"
                    inputProps={{
                      maxLength: 50,
                    }}

                    inputProps={{
                      readOnly: true
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    style={{ paddingRight: "26px" }}
                    margin="normal"
                    className={classes.textField}
                    value={this.state.dhlRate}

                    variant="filled"
                    onChange={this.handleChange}
                  // disabled={isManual ? false : true}
                  />

                  <div style={{ flexGrow: 3 }}></div>
                </div>
                <Divider style={{ margin: "15px 0 5px" }} />

                <div
                  className="button-wrapper"
                  style={{ paddingLeft: "0px", marginLeft: "0px" }}
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/pubEmloOrderPlacement")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {
                      <Button
                        disabled={
                          this.state.status == "UnSubmitted" ? (this.validateForm()
                            ? true
                            : false) : (!this.state.updatedValues || this.validateForm()
                              ? true
                              : false)
                        }
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(AddInsertMaster));
