import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withRouter } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import {
  getCodeMaster,
  updateCodeMasterID,
  getCodeTypeList,
} from "../../masters/masterActions";
import Validator from "../../../shared/validator";
// Include new StateContext
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";
import "date-fns";
import { addStyles } from "../../../shared/styles";

const validateNotRequired100 = new Validator("maxlength100AllAllow");
const onlyNumbers = new Validator("onlyNumbers");

class EditCodeMaster extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      codeMaster: {
        codeID: "",
        codeTypeName: "",
        codeType: "",
        code: "",
        codeValue: "",
        effectiveStartDate: null,
        remarks: "",
        isActive: false,
        modifiedBy: 1,
      },
      errors: {},
      selectedDDL: 0,
      active: false,
      codeTypeValid: true,
      codeValid: true,
      codeValueValid: true,
      effectiveStartDateValid: true,
      remarksValid: true,
      codeTypeList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let codeID = this.props.match.params.codeMasterId;
    this.props.setSelectedList("masterDataOpen", "codeMaster");
    getCodeMaster(codeID).then((r) => {
      dispatch({
        type: "getCodeMaster",
        newStore: r,
      });
      this.setState({ codeMaster: r });
      if (r.isActive) this.setState({ active: r.isActive });
    });
    getCodeTypeList().then((r) => {
      // dispatch({
      //   type: "getCodeTypeList",
      //   newStore: r.data,
      // });
      //this.setState({codeTypeList: r.data});
      this.getAllCodeType(r.data);
    });
  }
  getAllCodeType = (list) => {
    let codeTypeList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        c.isActive && c.canEditValue
          ? codeTypeList.push({
            key: c.codeTypeID,
            value: c.codeType,
            active: c.isActive,
          })
          : codeTypeList
      );
    this.setState({
      codeTypeList: codeTypeList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      ),
    });
  };

  handleChange = (event) => {
    const { codeMaster } = this.state;
    if (event.target.name === "isActive") {
      codeMaster[event.target.name] = event.target.checked;
    } else {
      codeMaster[event.target.name] = event.target.value;
    }
    this.setState({ codeMaster });

    this.validateField(event.target.name, event.target.value);
  };

  handleCodeTypeChange = (event, values) => {
    const { codeMaster } = this.state;
    let selectedDDL = this.state.selectedDDL;
    if (values === null) {
      codeMaster["codeType"] = "null";
      codeMaster["codeTypeName"] = "";
      selectedDDL = -1;
    } else {
      let codeType = values.key;
      codeMaster["codeType"] = codeType;
    }
    this.setState({ codeMaster, selectedDDL });

    this.validateField("codeType", codeMaster["codeType"]);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let codeValid = this.state.codeValid;
    let codeValueValid = this.state.codeValueValid;
    let remarksValid = this.state.remarksValid;
    let effectiveStartDateValid = this.state.effectiveStartDateValid;
    let codeTypeValid = this.state.codeTypeValid;
    switch (fieldName) {
      case "code":
        if (value !== "") {
          codeValid = validateNotRequired100(value);
          fieldValidationErrors.code = codeValid
            ? ""
            : resourceValidation.validRate;
        } else {
          codeValid = false;
          fieldValidationErrors.code = "";
        }
        break;
      case "codeValue":
        if (value !== "") {
          codeValueValid = validateNotRequired100(value);
          fieldValidationErrors.codeValue = codeValueValid
            ? ""
            : resourceValidation.validUOM;
        } else {
          codeValueValid = false;
          fieldValidationErrors.codeValue = "";
        }
        break;
      case "effectiveStartDate":
        if (value !== "") {
          effectiveStartDateValid = validateNotRequired100(value);
          fieldValidationErrors.effectiveStartDate = effectiveStartDateValid
            ? ""
            : resourceValidation.validUOM;
        } else {
          effectiveStartDateValid = false;
          fieldValidationErrors.effectiveStartDate = "";
        }
        break;
      case "remarks":
        if (value !== "") {
          remarksValid = validateNotRequired100(value);
          fieldValidationErrors.remarks = remarksValid
            ? ""
            : resourceValidation.validRemarks;
        } else {
          remarksValid = true;
          fieldValidationErrors.remarks = "";
        }
        break;
      case "codeType":
        if (value !== "null") {
          codeTypeValid = onlyNumbers(value);
          fieldValidationErrors.codeType = codeTypeValid
            ? ""
            : " Please select CodeType";
        } else {
          codeTypeValid = false;
          fieldValidationErrors.codeType = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        codeValid: codeValid,
        codeValueValid: codeValueValid,
        remarksValid: remarksValid,
        effectiveStartDateValid: effectiveStartDateValid,
        codeTypeValid: codeTypeValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.codeValid &&
        this.state.codeValueValid &&
        this.state.remarksValid &&
        this.state.codeTypeValid,
    });
    return this.state.formValid;
  }

  handleSubmit(e) {
    e.preventDefault();
    let codeID = this.props.match.params.codeMasterId;
    const { codeMaster } = this.state;
    codeMaster.modifiedBy = localStorage.loggedInId;
    updateCodeMasterID(codeID, codeMaster).then((r) => {
      this.props.history.push("/CodeMaster");
      //toastr.success("CodeMaster Updated Successfully");
    });
  }
  handleDateChange = (date) => {
    const { codeMaster } = this.state;
    codeMaster["effectiveStartDate"] = date;
    this.setState({ codeMaster });
  };
  render() {
    let { classes } = this.props;
    let { codeMaster, selectedDDL, active, codeTypeList } = this.state;
    let loop = 0;
    codeTypeList.map((option) =>
      option.key === this.state.codeMaster.codeType
        ? (selectedDDL = loop)
        : (loop = loop + 1)
    );
    //('#codeTypeName').val(selectedCodeTypeName);

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update CodeMaster</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                {/* {codeTypeList.length > 0 && ( */}
                <div style={{ display: "flex" }}>
                  <TextField
                    disabled={true}
                    required
                    label="Code"
                    name="code"
                    inputProps={{
                      maxLength: 10,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={codeMaster.code.toUpperCase()}
                    onChange={this.handleChange}
                    error={this.state.errors.code}
                    helperText={
                      this.state.errors.code ? this.state.errors.code : ""
                    }
                  />

                  {codeTypeList.length > 0 && (
                    <Autocomplete
                      disabled={true}
                      autoComplete
                      includeInputInList
                      id="codeType"
                      name="codeType"
                      options={codeTypeList}
                      onChange={this.handleCodeTypeChange}
                      getOptionLabel={(option) => option.value}
                      value={codeTypeList[selectedDDL]}
                      style={{
                        width: "31%",
                        fontSize: " 11px !important",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Code Type"}
                          name="codeTypeName"
                          id="codeTypeName"
                          margin="normal"
                          style={{ width: "100%" }}
                          className={classes.autocompleteText}
                          value={codeMaster.codeTypeName}
                          error={this.state.errors.codeType}
                          helperText={
                            this.state.errors.codeType
                              ? this.state.errors.codeType
                              : ""
                          }
                        />
                      )}
                    />
                  )}
                  {/* )} */}

                  {/* <TextField
                    required
                    label="Code Value"
                    name="codeValue"
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={codeMaster.codeValue}
                    onChange={this.handleChange}
                    error={this.state.errors.codeValue}
                    helperText={
                      this.state.errors.codeValue
                        ? this.state.errors.codeValue
                        : ""
                    }
                  /> */}
                </div>

                <div>
                  <TextField
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    label="Remarks"
                    name="remarks"
                    inputProps={{
                      maxLength: 500,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={codeMaster.remarks}
                    onChange={this.handleChange}
                    error={this.state.errors.remarks}
                    helperText={
                      this.state.errors.remarks ? this.state.errors.remarks : ""
                    }
                  />
                  <TextField
                    inputProps={{
                      maxLength: 2000,
                    }}
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    //required
                    //onKeyPress={this._onKeyPress}
                    required
                    label="Code Value"
                    name="codeValue"
                    margin="normal"
                    value={codeMaster.codeValue}
                    // style={{ width: "93%", marginLeft: "3px" }}

                    onChange={this.handleChange}
                    className={classes.textField}
                    error={this.state.errors.codeValue}
                    helperText={
                      this.state.errors.codeValue
                        ? this.state.errors.codeValue
                        : ""
                    }
                  />
                  {active ? (
                    <React.Fragment></React.Fragment>
                  ) : (
                      <FormControlLabel
                        name="isActive"
                        className={classes.checkBox}
                        control={
                          <Checkbox
                            color="primary"
                            checked={codeMaster.isActive}
                            onChange={this.handleChange}
                            value={codeMaster.isActive}
                          />
                        }
                        label="Active"
                      />
                    )}
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/codeMaster")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        onClick={this.handleSubmit}
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          onClick={this.handleSubmit}
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditCodeMaster));
