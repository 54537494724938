import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import { getSubMenu } from "../../userAccess/userAccessAction";
import { StateContext } from "../../../shared/globalState";
import { printDetails } from "../../../shared/tableCommonFunctions";

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { selectedSubMenuId } = props;
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%"
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Tooltip title="Edit">
            <Link to={`/edit_subMenu/${selectedSubMenuId}`}>
              <IconButton aria-label="edit" className={classes.iconHover}>
                <EditIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Print" style={{ padding: "5px", paddingTop: "12px" }}>
            <IconButton
              aria-label="print"
              className={classes.iconHover}
              onClick={e => printDetails()}
            >
              <PrintIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

class ViewSubMenu extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      subMenu: {
        menuName: "",
        subMenuName: "",
        description: "",
        orderNo: "",
        isReport: ""
      }
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let subMenuId = this.props.match.params.subMenuId;

    getSubMenu(subMenuId).then(r => {
      dispatch({
        type: "getSubMenu",
        newStore: r
      });
      this.setState({ subMenu: r });
    });
  }

  render() {
    let { subMenu } = this.state;
    let subMenuId = this.props.match.params.subMenuId;
    let { statusReport } = "";
    if (subMenu.isReport === true) {
      statusReport = "Yes";
    } else {
      statusReport = "No";
    }
    let { status } = "";
    if (subMenu.isActive === true) {
      status = "Yes";
    } else {
      status = "No";
    }
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar selectedSubMenuId={subMenuId} />
          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">Sub Menu Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Sub Menu Name</div>
                <div className="view-page-value">{subMenu.subMenuName}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Menu Name</div>
                <div className="view-page-value">{subMenu.menuName}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Description</div>
                <div className="view-page-value">{subMenu.description}</div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Order Number</div>
                <div className="view-page-value">{subMenu.orderNo}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Report</div>
                <div className="view-page-value">{statusReport}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Active</div>
                <div className="view-page-value">{status}</div>
              </div>
            </div>
          </div>
          <Divider />
          <div className="button-wrapper">
            <div>
              <Button
                variant="outlined"
                color="primary"
                onClick={e => this.props.history.push("/subMenu")}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewSubMenu);
