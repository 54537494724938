import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { ExportCSV } from "../../../shared/ExportCSV";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  Tooltip,
  LinearProgress,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import ManageZone from "./zoneManage";
import AdvanceSearch from "./ZoneAdvanceSearch";
import moment from "moment";
import clone from "clone";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { StateContext } from "../../../shared/globalState";
import { getZoneList } from "../masterActions";
import { Link } from "react-router-dom";
import { ViewIcon, EditIcon, AddIcon } from "../../../shared/customIcons";
import {
  stableSort,
  getSorting,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import "../../app/App.css";

function createData(
  code,
  name,
  id,
  createdby,
  createdDate,
  modifiedby,
  modifiedDate,
  isActive
) {
  return {
    code,
    name,
    id,
    createdby,
    createdDate,
    modifiedby,
    modifiedDate,
    isActive,
  };
}

const headCells = [
  {
    id: "code",
    numeric: false,
    disablePadding: true,
    label: "Zone Code",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Zone Name",
  },
  {
    id: "isActive",
    numeric: false,
    disablePadding: false,
    label: "Active",
  },
  {
    id: "modifiedDate",
    numeric: false,
    disablePadding: false,
    label: "Modified Date",
  },
  {
    id: "modifiedby",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
  {
    id: "createdDate",
    numeric: false,
    disablePadding: false,
    label: "Created Date",
  },
  {
    id: "createdby",
    numeric: false,
    disablePadding: false,
    label: "Created By",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all Zones" }}
              />
            }
          />
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all Zones" }}
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              <span style={{ color: "#306dca" }}>{headCell.label}</span>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderTop: "1px solid #eaeaea",
  },

  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    // selectedZone,
    selected,
    zoneList,
    exportDataValues,
  } = props;
  const SelectedZoneId = selected[0];
  const selectedZone = zoneList.find((x) => x.id === SelectedZoneId);
  let acnArr = props.roles.filter((u) => u.menuName === "Zone");
  let acnObj = acnArr[0];
  let expData = clone(zoneList);
  expData.map((d) => {
    let Val = "No";
    if (d["isActive"]) {
      Val = "Yes";
    }
    d["Zone Code"] = d["code"];
    d["Zone Name"] = d["name"];

    d["Active"] = Val;
    d["Created By"] = d["createdby"];
    d["Created Date"] = moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss");
    d["Modified By"] = d["modifiedby"];

    d["Modified Date"] = moment(d["modifiedDate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    );

    delete d["code"];

    delete d["name"];

    delete d["isActive"];

    delete d["createdby"];

    delete d["createdDate"];

    delete d["modifiedby"];

    delete d["modifiedDate"];
    delete d["id"];
  });
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <React.Fragment>
                <div>
                  {acnObj && acnObj.viewAction && (
                    <Tooltip title="View">
                      <Link to={`/view_zone/${SelectedZoneId}`}>
                        <IconButton
                          aria-label="view"
                          className={classes.iconHover}
                        >
                          <ViewIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  )}
                  {acnObj && acnObj.editAction && (
                    <Tooltip title="Edit">
                      <Link to={`/edit_zone/${SelectedZoneId}`}>
                        <IconButton
                          aria-label="edit"
                          className={classes.iconHover}
                        >
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  )}
                </div>
              </React.Fragment>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <ManageZone
                  mode="delete"
                  selected={selected}
                  data={{
                    zoneName: selectedZone.name,
                    zoneCode: selectedZone.code,
                    zoneID: selectedZone.id,
                  }}
                  onRequestSucceed={props.onRequestSucceed}
                  count={props.selected.length}
                  isActive={props.isActive}
                />
              </Tooltip>
            )}
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {props.searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  acnObj &&
                  acnObj.advanceSearchAction && (
                    <AdvanceSearch
                      onSearchClicked={props.onSearchClicked}
                      onRequestSucceed={props.onRequestSucceed}
                    />
                  )
                )}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="add"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/add_zone")}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {acnObj && acnObj.exportAction && (
                <Tooltip title="Download">
                  <ExportCSV csvData={expData} fileName={"Zone"} />
                </Tooltip>
              )}
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

class ZoneMaster extends React.Component {
  static contextType = StateContext;
  state = {
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 10,
    rows: [],
    activeRows: 0,
    isActive: false,
    refreshData: false,
    zoneList: [],
    selectedZone: {},
    searchClicked: false,
    loading: false,
    exportDataValues: [],
  };
  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Zone");
    this.props.setSelectedList("masterDataOpen", "zone");
    this.setState({ loading: true });
    getZoneList(window.localStorage.token).then((r) => {
      dispatch({
        type: "getZoneList",
        newStore: r.data,
      });
      if (!this.state.searchClicked) {
        this.getAllZone(r.data);
        this.exportZoneDatas(r.data);
      }
    });
  }
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    // if (store.zoneList && store.zoneList.length > 0)
    //   if (this.state.zoneList.length !== store.zoneList.length) {
    //     if (!this.state.searchClicked) this.getAllZone(store.zoneList);
    //   }
    // if (this.state.zoneList.length !== 0) {
    //   this.state.zoneList.map((cl, i) => {
    //     if (cl.isActive !== store.zoneList[i].isActive)
    //       if (!this.state.searchClicked) this.getAllZone(store.zoneList);
    //   });
    // }
    return true;
  }
  onRequestSucceed = (deleted) => {
    const [{ store }, dispatch] = this.context;
    getZoneList().then((r) => {
      dispatch({
        type: "getZoneList",
        newStore: r.data,
      });
      this.setState({ selected: [] });
      if (deleted) this.setState({ searchClicked: false });
      this.getAllZone(store.zoneList);
    });
  };

  exportZoneDatas = (zoneList) => {
    let list = zoneList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          ZoneCode: d.zoneCode,
          ZoneName: d.zoneName,
          Active: d.isActive === true ? "Yes" : "No",
          CreatedBy: d.createdBy,
          createdDate: d.createdDate,
          ModifiedBy: d.modifiedBy,
          modifiedDate: d.modifiedDate,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getAllZone = (zoneList) => {
    let newRows = [];
    zoneList &&
      zoneList.length > 0 &&
      zoneList.map((d) => {
        return newRows.push(
          createData(
            d.zoneCode,
            d.zoneName,
            d.zoneId,
            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate,
            d.isActive
          )
        );
      });
    this.sortStateList(newRows);
    this.setState({
      zoneList: newRows,
      loading: false,
      selected: [],
      activeRows: newRows.filter((x) => x.isActive).map((n) => n.id),
    });
  };
  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };
  sortStateList = (list) => {
    let z = list.sort(function (a, b) {
      let x = a.isActive && a.isActive;
      let y = b.isActive && b.isActive;
      let u = a.modifiedDate && a.modifiedDate;
      let v = b.modifiedDate && b.modifiedDate;
      if (x < y) {
        return 1;
      }
      if (u < v) {
        return -1;
      }
      if (x > y) {
        return -1;
      }
      if (u > v) {
        return 1;
      }
      return 0;
    });
    // console.log(z);
    this.setState({ rows: z });
  };

  handleSelectAllClick = (event) => {
    let { zoneList } = this.state;
    if (event.target.checked) {
      const newSelecteds = zoneList
        .filter((x) => x.isActive === true)
        .map((n) => n.id);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, data) => {
    let id = data.id;
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedZone: data,
        SelectedZoneId: id,
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        // selectedZone: {}
        selectedZone: data,
        SelectedZoneId: id,
      });
    }
  };
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  onSearchClicked = () => {
    this.setState({ searchClicked: true, page: 0 });
  };
  clearSearch = () => {
    const [{ store }, dispatch] = this.context;
    getZoneList().then((r) => {
      dispatch({
        type: "getZoneList",
        newStore: r.data,
      });
      this.getAllZone(store.zoneList);
      this.setState({ searchClicked: false });
    });
  };
  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      selectedZone,
      zoneList,
    } = this.state;
    const isSelected = (code) => selected.indexOf(code) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedZone={selectedZone}
            onSearchClicked={this.onSearchClicked}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            selected={this.state.selected}
            rows={zoneList}
            zoneList={zoneList}
            exportDataValues={this.state.exportDataValues}
            isActive={this.state.isActive}
            roles={this.props.roles}
          />
          <div
            style={{
              width: "99%",
              maxHeight: "calc(100vh - 225px)",
              margin: "0 auto",
              border: "1px solid  rgba(224, 224, 224, 1)",
            }}
          >
            <div
              style={{ maxHeight: " calc(100vh - 225px)", overflow: "auto" }}
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="sticky table"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                />

                <TableBody style={{ overflow: "hidden" }}>
                  {zoneList.length > 0 ? (
                    stableSort(zoneList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )

                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        console.log(row)
                        return (
                          <TableRow
                            hover
                            onClick={(event) => this.handleClick(event, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            //key={row.id}
                            key={index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              {/* <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                                color="primary"
                              /> */}
                              <FormControlLabel
                                control={
                                  <CustomCheckbox
                                    checked={isItemSelected}
                                  // onChange={handleChange('checkedG')}
                                  // value="checkedG"
                                  />
                                }
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.code && row.code.length > 16 ? (
                                <Tooltip title={row.code} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.code}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {row.code}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.name}
                              </Typography> */}
                              {row.name && row.name.length > 16 ? (
                                <Tooltip title={row.name} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.name}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {row.name}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {status}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {row.modifiedDate &&
                                row.modifiedDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.modifiedby} */}
                              {row.modifiedby && row.modifiedby.length > 16 ? (
                                <Tooltip
                                  title={row.modifiedby}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.modifiedby}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {row.modifiedby}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* <Moment format="MM/DD/YYYY">{row.createdDate}</Moment> */}
                              {/* moddate */}

                              {row.createdDate &&
                                row.createdDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.createdby} */}
                              {row.createdby && row.createdby.length > 16 ? (
                                <Tooltip title={row.createdby} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.createdby}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {row.createdby}
                                  </Typography>
                                )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={8} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>

          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={zoneList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(ZoneMaster);
