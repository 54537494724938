import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import { getDHLRateDetails } from "../masterActions";
import { StateContext } from "../../../shared/globalState";
import { printDetails, addZeros } from "../../../shared/tableCommonFunctions";

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { selecteddhlRateId } = props;
  let acnArr = props.roles.filter((u) => u.menuName === "DHL Rate");
  let acnObj = acnArr[0];

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_dhlRate/${selecteddhlRateId}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};
class ViewDHLRate extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.state = {
      dhlRateValues: {
        dhlZoneName: "",
        dhlZone: {},
        dhlWeight: 0.0,
        dhlRate: 0,
        dhlYear: "",
        createdBy: 1,
        isActive: false,
      },
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let dhlRateId = this.props.match.params.dhlRateId;
    getDHLRateDetails(dhlRateId).then((r) => {
      dispatch({
        type: "getDHLRate",
        newStore: r,
      });

      let dhlRateValues = r;
      dhlRateValues.dhlZoneName =
        dhlRateValues.dhlZone != null ? dhlRateValues.dhlZone.dhlZoneName : "";
      this.setState({ dhlRateValues: dhlRateValues });
    });
  }

  render() {
    let { dhlRateValues } = this.state;
    let dhlRateId = this.props.match.params.dhlRateId;
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selecteddhlRateId={dhlRateId}
            roles={this.props.roles}
          />
          {/* <div className="view-page-header">Cost Per Title Information</div> */}
          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">DHL Rate Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">DHL Zone</div>
                <div className="view-page-value">
                  {dhlRateValues.dhlZoneName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">DHL Weight (Kg)</div>
                <div className="view-page-value">
                  {addZeros(dhlRateValues.dhlWeight.toString(), 3)}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">DHL Rate($S)</div>
                {/* <div className="view-page-value">{dhlRateValues.dhlRate}</div> */}
                <div className="view-page-value">
                  {addZeros(dhlRateValues.dhlRate.toString(), 2)}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">DHL Year</div>
                <div className="view-page-value">{dhlRateValues.dhlYear}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Active</div>
                <div className="view-page-value">
                  {dhlRateValues.isActive === true ? "Yes" : "No"}
                </div>
              </div>
            </div>{" "}
            <Divider style={{ marginTop: "25px" }} />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.props.history.push("/DHLRate")}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewDHLRate);
