import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import clone from "clone";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { resourceValidation } from "../../../shared/resource";
import moment from "moment";
import {
  SearchIcon,
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import {
  stableSort,
  getSorting,
  DialogActions,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import Validator from "../../../shared/validator";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import types from "../masterTypes";
import { StateContext } from "../../../shared/globalState";
import {
  getProductionLineList,
  deleteProductionLineCode,
  getFliteredproductionLineCodeList,
  //   getProductionLineList
} from "../masterActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ExportCSV } from "../../../shared/ExportCSV";

const columns = [
  {
    name: "Active",
    id: 5,
    show: false,
  },
  {
    name: "Modified Date",
    id: 6,
    show: false,
  },
  {
    name: "Modified By",
    id: 7,
  },
  {
    name: "Created Date",
    id: 8,
  },
  {
    name: "Created By",
    id: 9,
  },
];

const notRequiredAlpNum10 = new Validator("notRequiredAlpNum10");
const notRequiredAlpNumHypDot50 = new Validator("notRequiredAlpNumHypDot50");

function createData(
  productionLineID,
  productionLineCode,
  productionLineName,
  productionLineDescription,
  isInternal,
  isActive,
  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate
) {
  return {
    productionLineID,
    productionLineCode,
    productionLineName,
    productionLineDescription,
    isInternal,
    isActive,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <CustomCheckbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all production code" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    productionLineCode: "",
    productionLineName: "",
  });
  const [formValid, setIsformValid] = useState(false);

  const [cptSearchValue, setValues] = useState({
    productionLineCode: "",
    productionLineName: "",
  });

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);

  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handledeleteProductionLineCode,
    searchPopupOpen,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchCptCode,
    searchClicked,
    hendleVewColums,
    exportDataValues,
    selected,
    prodLineList,
    isActive,
  } = props;

  // const selectedProductionID = selected && selected.length > 0 && selected[0];
  const selectedProductionID = selected[0];
  const selectedProdLineCode = prodLineList.find(
    (x) => x.productionLineID === selectedProductionID
  );
  let acnArr = props.roles.filter((u) => u.menuName === "Production Line");
  let acnObj = acnArr[0];

  let expData = clone(prodLineList);
  expData.map((d) => {
    let Val = "No";
    if (d["isActive"]) {
      Val = "Yes";
    }
    if (d["isInternal"]) {
      Val = "Yes";
    }
    d["Production Line Code"] = d["productionLineCode"];
    d["Production Line Name"] = d["productionLineName"];
    d["Description"] = d["productionLineDescription"];
    d["Internal"] = Val;

    d["Active"] = Val;
    d["Created By"] = d["createdBy"];
    d["Created Date"] = moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss");
    d["Modified By"] = d["modifiedBy"];

    d["Modified Date"] = moment(d["modifiedDate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    );

    delete d["productionLineCode"];
    delete d["productionLineName"];
    delete d["productionLineDescription"];
    delete d["isInternal"];
    delete d["isActive"];

    delete d["createdBy"];

    delete d["createdDate"];

    delete d["modifiedBy"];

    delete d["modifiedDate"];
    delete d["productionLineID"];
  });
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteProductionLineCode = () => {
    handledeleteProductionLineCode();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = cptSearchValue;
    searchValues.productionLineCode = "";
    searchValues.productionLineName = "";
    searchValues = cptSearchValue;
  };

  const searchPopup = () => {
    handleSearchCptCode(cptSearchValue);
    clearSearchValues();
  };

  const handleSearchInput = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;
    setValues((cptSearchValue) => ({
      ...cptSearchValue,
      [event.target.name]: event.target.value,
    }));

    switch (name) {
      case "productionLineCode":
        let productionLineCode = notRequiredAlpNumHypDot50(value);
        setIsformValid(productionLineCode);
        setErrors((errors) => ({
          ...errors,
          [event.target.name]: productionLineCode
            ? ""
            : resourceValidation.productionLineCode,
        }));
        break;
      case "productionLineName":
        let productionLineName = notRequiredAlpNum10(value);
        setIsformValid(productionLineName);
        setErrors((errors) => ({
          ...errors,
          [event.target.name]: productionLineName
            ? ""
            : resourceValidation.productionLineName,
        }));
        break;
      default:
        break;
    }

    // if(formValid){
    //   setFormvalid();
    // }
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_prodLine/${selectedProductionID}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <Link to={`/edit_ProdLine/${selectedProductionID}`}>
                      <IconButton
                        aria-label="edit"
                        className={classes.iconHover}
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={handleClickOpen}
                  disabled={!isActive}
                >
                  <DeleteIcon className={classes.iconHover} />
                </IconButton>
              </Tooltip>
            )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete Production Line
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1
                      ? " ?"
                      : selectedProdLineCode.productionLineCode + " ?"}
                    {/* {selectedProdLineCode.productionLineCode} */}
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteProductionLineCode}
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  <form onSubmit={searchPopup}>
                    {acnObj && acnObj.advanceSearchAction && (
                      <Tooltip title="Search">
                        <IconButton
                          aria-label="Search"
                          className={classes.iconHover}
                          onClick={handleSearchOpen}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      onClose={handleSearchPopupClose}
                      aria-labelledby="customized-dialog-title"
                      open={searchPopupOpen}
                      maxWidth="md"
                    >
                      <DialogTitle
                        id="customized-dialog-title"
                        onClose={handleSearchPopupClose}
                      >
                        Advanced Search
                  </DialogTitle>
                      <DialogContent dividers>
                        <React.Fragment>
                          <div>
                            <TextField
                              id="standard-required"
                              label="Production Line Code"
                              name="productionLineCode"
                              className={classes.textField}
                              margin="normal"
                              style={{ marginRight: "1em" }}
                              value={cptSearchValue.productionLineCode}
                              inputProps={{
                                maxLength: 50,
                              }}
                              autoComplete="off"
                              onChange={handleSearchInput}
                              error={errors.productionLineCode}
                              helperText={
                                errors.productionLineCode
                                  ? errors.productionLineCode
                                  : ""
                              }
                            />
                            <TextField
                              label="Production Line Name"
                              name="productionLineName"
                              inputProps={{
                                maxLength: 10,
                              }}
                              autoComplete="off"
                              margin="normal"
                              className={classes.textField}
                              value={cptSearchValue.productionLineName}
                              onChange={handleSearchInput}
                              error={errors.productionLineName}
                              helperText={
                                errors.productionLineName
                                  ? errors.productionLineName
                                  : ""
                              }
                            />
                          </div>
                        </React.Fragment>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                          onClick={handleSearchClose}
                        >
                          CANCEL
                    </Button>
                        {!cptSearchValue.productionLineCode && !cptSearchValue.productionLineName ? (
                          <Button
                            disabled
                            //autoFocus
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                            onClick={searchPopup}
                          >
                            SEARCH
                          </Button>
                        ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              //disableElevation
                              style={{ boxShadow: "none" }}
                              onClick={searchPopup}
                            >
                              SEARCH
                            </Button>
                          )}
                      </DialogActions>
                    </Dialog>
                  </form>
                )}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="add"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/add_ProdLine")}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {acnObj && acnObj.exportAction && (
                <Tooltip>
                  <ExportCSV csvData={expData} fileName={"Production_Line"} />
                </Tooltip>
              )}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                hendleVewColums={hendleVewColums}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class CPTCodeMater extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    cptCodeList: [],
    prodLineList: [],
    rows: [],
    activeRows: 0,
    isActive: false,
    refreshData: false,
    selectedProdLineCode: {},
    //selectedProdLineCodeId: 1,
    loading: false,
    searchPopupOpen: false,
    cptSearchValues: {},
    searchClicked: false,
    selectedShowColumns: [],
    exportDataValues: [],
    headCells: [
      {
        id: 1,
        code: "productionLineCode",
        numeric: false,
        disablePadding: true,
        label: "Production Line Code",
        show: true,
      },
      {
        id: 2,
        code: "productionLineName",
        numeric: false,
        disablePadding: false,
        label: "Production Line Name",
        show: true,
      },
      {
        id: 3,
        code: "productionLineDescription",
        numeric: false,
        disablePadding: false,
        label: "Description",
        show: true,
      },
      {
        id: 4,
        code: "isInternal",
        numeric: false,
        disablePadding: false,
        label: "Internal",
        show: true,
      },
      {
        id: 5,
        code: "isActive",
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true,
      },
      {
        id: 6,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 7,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 8,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 9,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };
  componentDidMount() {
    this.props.handleSettingHeader("Production Line");
    this.props.setSelectedList("masterDataOpen", "productionLine");
    const dispatch = this.context[1];

    getProductionLineList(window.localStorage.token).then((r) => {
      // dispatch({
      //   type: types.PRODLINE_LIST,
      //   newStore: r.data,
      // });
      this.getProductionLineCode(r.data);
      // this.exportCptDatas(r.data);
    });
    this.setState({ loading: true });
  }

  sortStateList = (list) => {
    let z = list.sort(function (a, b) {
      let x = a.isActive && a.isActive;
      let y = b.isActive && b.isActive;
      let u = a.modifiedDate && a.modifiedDate;
      let v = b.modifiedDate && b.modifiedDate;
      if (x < y) {
        return 1;
      }
      if (u < v) {
        return -1;
      }
      if (x > y) {
        return -1;
      }
      if (u > v) {
        return 1;
      }
      return 0;
    });
    // console.log(z);
    this.setState({ rows: z });
  };

  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  exportCptDatas = (cptCodeList) => {
    let list = cptCodeList;
    let rows = [];

    list &&
      list.length > 0 &&
      list.map((d) => {
        let { status } = "";
        if (d.isActive === true) {
          status = "Yes";
        } else {
          status = "No";
        }

        let { status1 } = "";
        if (d.isInternal === true) {
          status1 = "Yes";
        } else {
          status1 = "No";
        }
        return rows.push({
          ProductionLineCode: d.productionLineCode,
          ProductionLineName: d.productionLineName,
          ProductionLineDescription: d.productionLineDescription,
          // isInternal: d.isInternal,
          // Active: d.isActive,
          Internal: status1,
          Active: status,
          CreatedBy: d.createdBy,
          CreatedDate: d.createdDate,
          ModifiedBy: d.modifiedBy,
          ModifiedDate: d.modifiedDate,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getProductionLineCode = (prodLineList) => {
    let list = prodLineList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.productionLineID,
            d.productionLineCode,
            d.productionLineName,
            d.productionLineDescription,
            d.isInternal,
            d.isActive,
            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate
          )
        );
      });
    this.sortStateList(rows);
    this.setState({
      cptCodeList: rows,
      selected: [],
      loading: false,
      activeRows: rows.filter((x) => x.isActive).map((n) => n.productionLineID),
    });
  };
  // shouldComponentUpdate(s, p) {
  //   const { store } = this.context[0];
  //   if (store.cptCodeList && store.cptCodeList.length > 0)
  //     if (this.state.cptCodeList.length !== store.cptCodeList.length) {
  //       !this.state.searchClicked &&
  //         this.getProductionLineCode(store.cptCodeList);
  //     }
  //   return true;
  // }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { cptCodeList } = this.state;
    if (event.target.checked) {
      const newSelecteds = cptCodeList
        .filter((x) => x.isActive === true)
        .map((n) => n.productionLineID);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, data) {
    let productionLineID = data.productionLineID;
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(productionLineID);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, productionLineID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedProdLineCode: data.productionLineCode,
        // selectedProdLineCodeId: productionLineID
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedProdLineCode: {},
        // selectedProdLineCodeId: 0
      });
    }
  }
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchCptCode = (handleSearchCptCode) => {
    let cptSearchValue = handleSearchCptCode;
    const dispatch = this.context[1];

    let productionLineCode = cptSearchValue.productionLineCode;
    let productionLineName = cptSearchValue.productionLineName;

    let reqBody = { productionLineCode, productionLineName };

    getFliteredproductionLineCodeList(reqBody).then((r) => {
      dispatch({
        type: "getProductionLineCode",
        newStore: r.data,
      });
      this.getProductionLineCode(r.data);
      this.setState({ searchPopupOpen: false, searchClicked: true, page: 0 });
    });
  };

  handledeleteProductionLineCode = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteProductionLineCode(modifiedBy, deletionIds).then((r) => {
      getProductionLineList().then((r) => {
        dispatch({
          type: "getProductionLineCode",
          newStore: r.data,
        });
        this.getProductionLineCode(r.data);
      });
      this.props.history.push("/productionLine");
      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };

  clearSearch = () => {
    const dispatch = this.context[1];
    getProductionLineList().then((r) => {
      dispatch({
        type: types.PRODLINE_LIST,
        newStore: r.data,
      });
      this.getProductionLineCode(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      cptCodeList,
      headCells,
      exportDataValues,
    } = this.state;
    const isSelected = (productionLineID) =>
      selected.indexOf(productionLineID) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handledeleteProductionLineCode={this.handledeleteProductionLineCode}
            selectedProdLineCode={this.state.selectedProdLineCode}
            selectedProdLineCodeId={this.state.selectedProdLineCodeId}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchCptCode={this.handleSearchCptCode}
            cptSearchValue={this.state.cptSearchValues}
            searchClicked={this.state.searchClicked}
            hendleVewColums={this.hendleVewColums}
            prodLineList={cptCodeList}
            headCells={headCells}
            cptCodeList={cptCodeList}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {cptCodeList.length > 0 ? (
                    stableSort(cptCodeList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.productionLineID);
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }

                        let { isInternal } = "";
                        if (row.isInternal === true) {
                          isInternal = "Yes";
                        } else {
                          isInternal = "No";
                        }
                        return (
                          <TableRow
                            hover
                            onClick={(event) => this.handleClick(event, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            // key={row.productionLineID}
                            key={index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <CustomCheckbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.productionLineCode}
                                </Typography> */}
                                {row.productionLineCode &&
                                  row.productionLineCode.length > 16 ? (
                                    <Tooltip
                                      title={row.productionLineCode}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.productionLineCode}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.productionLineCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.productionLineName} */}
                                {row.productionLineName &&
                                  row.productionLineName.length > 16 ? (
                                    <Tooltip
                                      title={row.productionLineName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.productionLineName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.productionLineName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.productionLineDescription} */}
                                {row.productionLineDescription &&
                                  row.productionLineDescription.length > 16 ? (
                                    <Tooltip
                                      title={row.productionLineDescription}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.productionLineDescription}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.productionLineDescription}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                // id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {isInternal}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {status}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {/* {row.modifiedBy} */}
                                {row.modifiedBy &&
                                  row.modifiedBy.length > 16 ? (
                                    <Tooltip
                                      title={row.modifiedBy}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedBy}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {/* {row.createdBy} */}
                                {row.createdBy && row.createdBy.length > 16 ? (
                                  <Tooltip
                                    title={row.createdBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={8} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={cptCodeList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(CPTCodeMater);
