import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_PUBLISHER_DOCKET_BAGGING;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const uploadCPTFiles = async (data) => {
  let result = [];
  await pgsService
    .post("/Create", data)
    .then((r) => {
      if (r.data.exceptionMessage && r.data.exceptionMessage.length > 0) {
        // toastr.error(r.data.exceptionMessage);
        result = r.data;
        return;
      } else result = r.data;
    })
    .catch((e) => {});
  return result;
  // }
};

export const downloadFileForCPT = async (id) => {
  let dataMappingList = [];
  await pgsService
    .get("/GetDownLoad/" + id)
    .then((r) => {
      dataMappingList = r;
    })
    .catch((e) => {});
  return dataMappingList;
};

export const submitCPTFiles = async (data, history) => {
  let result = [];
  await pgsService
    .post("/Submit", data)
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else {
        toastr.success("CPT Created Successful");
        result = r.data;
        history.push("/backLabelBillingProcess");
      }
    })
    .catch((e) => {});
  return result;
  // }
};

export const getBillingList = async () => {
  let jimList = [];
  await pgsService
    .get("/GetAll")
    .then((r) => {
      jimList = r;
    })
    .catch((e) => {});
  return jimList;
};

export const advanceSearchForBilling = async (reqBody) => {
  let searchList = [];
  await pgsService
    .post("/DHLInvoiceAdvanceSerach", reqBody)
    .then(async (r) => {
      searchList = r;
    })
    .catch((e) => {});
  return searchList;
};
