import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import { withRouter } from "react-router-dom";
import clone from "clone";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AdvanceSearch from "./dhlRateSearch";
import moment from "moment";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { StateContext } from "../../../shared/globalState";
import { deleteDHLRate, getDHLRateList } from "../masterActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ExportCSV } from "../../../shared/ExportCSV";
import {
  stableSort,
  getSorting,
  DialogActions,
} from "../../../shared/tableCommonFunctions";
import { Link } from "react-router-dom";
import "../../app/App.css";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import {
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";

const columns = [
  {
    id: 5,
    name: "Active",
  },
  {
    id: 6,
    name: "Modified Date",
  },
  {
    id: 7,
    name: "Modified By",
  },
  {
    id: 8,
    name: "Created Date",
  },
  {
    id: 9,
    name: "Created By",
  },
];

function createData(
  dhlRateID,
  dhlZoneID,
  dhlZoneCode,
  dhlZoneName,
  dhlWeight,
  dhlYear,
  dhlRate,
  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate,
  isActive
) {
  return {
    dhlRateID,
    dhlZoneID,
    dhlZoneCode,
    dhlZoneName,
    dhlWeight,
    dhlYear,
    dhlRate,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
    isActive,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all DHLRate" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    selecteddhlRate,
    selected,
    handleViewColumns,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handleDeleteDhlRate,
    exportDataValues,
    dhlRateList,
    isActive,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  //const selectedDHLRateID = selected && selected.length > 0 && selected[0];

  const selectedDHLRateID = selected[0];
  // const selecteddhlRate = dhlRateList.find(
  //   (x) => x.dhlRateID === selectedDHLRateID
  // );
  let acnArr = props.roles.filter((u) => u.menuName === "DHL Rate");
  let acnObj = acnArr[0];
  let expData = clone(dhlRateList);
  expData.map((d) => {
    let Val = "No";
    if (d["isActive"]) {
      Val = "Yes";
    }
    //d["DHL Zone"] = d["dhlZoneCode"];
    d["DHL Zone"] = d["dhlZoneName"];
    d["DHL Weight"] = d["dhlWeight"];
    d["DHL Year"] = d["dhlYear"];
    d["DHL Rate"] = d["dhlRate"];

    d["Active"] = Val;
    d["Modified By"] = d["modifiedBy"];

    d["Modified Date"] = moment(d["modifiedDate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    );
    d["Created By"] = d["createdBy"];
    d["Created Date"] = moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss");

    delete d["dhlZoneCode"];
    delete d["dhlZoneName"];
    delete d["dhlWeight"];

    delete d["dhlYear"];

    delete d["dhlRate"];

    delete d["isActive"];

    delete d["createdBy"];

    delete d["createdDate"];

    delete d["modifiedBy"];

    delete d["modifiedDate"];
    delete d["dhlZoneID"];
    delete d["dhlRateID"];
  });
  const handleClickDeleteOpen = () => {
    handleDeletePopupOpen();
  };

  const handleDeleteClose = () => {
    handleDeletePopupClose();
  };

  const deleteDhlRate = () => {
    handleDeleteDhlRate();
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <React.Fragment>
                <div>
                  {acnObj && acnObj.viewAction && (
                    <Tooltip title="View">
                      <Link to={`/view_dhlRate/${selectedDHLRateID}`}>
                        <IconButton
                          aria-label="view"
                          className={classes.iconHover}
                        >
                          <ViewIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  )}
                  {acnObj && acnObj.editAction && (
                    <Tooltip title="Edit">
                      <Link to={`/edit_dhlRate/${selectedDHLRateID}`}>
                        <IconButton
                          aria-label="edit"
                          className={classes.iconHover}
                        >
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  )}
                </div>
              </React.Fragment>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={handleClickDeleteOpen}
                  disabled={!isActive}
                >
                  <DeleteIcon className={classes.iconHover} />
                </IconButton>
              </Tooltip>
            )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleDeleteClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={handleDeleteClose}
              >
                Delete DHL Rate
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1 ? " ?" : selecteddhlRate.dhlZoneName + " ?"}
                    {/* {selecteddhlRate.dhlZoneCode} */}
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleDeleteClose}
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteDhlRate}
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {props.searchClicked ? (
              <Tooltip title="Clear search result">
                <IconButton
                  aria-label="clear"
                  className={classes.iconHover}
                  onClick={props.clearSearch}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            ) : (
              acnObj &&
              acnObj.advanceSearchAction && (
                <AdvanceSearch
                  onSearchClicked={props.onSearchClicked}
                  onRequestSucceed={props.onRequestSucceed}
                />
              )
            )}
            {acnObj && acnObj.addAction && (
              <Tooltip title="Add">
                <IconButton
                  aria-label="add"
                  className={classes.iconHover}
                  onClick={(e) => props.history.push("/add_dhlRate")}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
            {acnObj && acnObj.exportAction && (
              <ExportCSV csvData={expData} fileName={"DHLRate"} />
            )}
            <Tooltip title="View Column">
              <IconButton
                aria-label="viewColumn"
                className={classes.iconHover}
                onClick={handleClickListItem}
              >
                <ViewColumnIcon />
              </IconButton>
            </Tooltip>
            <EnhancedShowColumn
              id="columns-menu"
              keepMounted
              open={open}
              onClose={handleShowColClose}
              column={column}
              handleViewColumns={handleViewColumns}
              headCells={props.headCells}
            />
          </div>
        )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    handleViewColumns,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => handleViewColumns(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

class DHLRateMaster extends React.Component {
  static contextType = StateContext;

  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    activeRows: 0,
    isActive: false,
    refreshData: false,
    selecteddhlRate: {},
    dhlRateList: [],
    searchClicked: false,
    loading: false,
    exportDataValues: [],
    selectedShowColumns: [],
    headCells: [
      {
        code: "dhlZoneID",
        id: 1,
        numeric: false,
        disablePadding: true,
        label: "DHL Zone",
        show: true,
      },
      {
        code: "dhlWeight",
        id: 2,
        numeric: false,
        disablePadding: false,
        label: "DHL Weight (Kg)",
        show: true,
      },
      {
        code: "dhlYear",
        id: 3,
        numeric: false,
        disablePadding: false,
        label: "DHL Year",
        show: true,
      },
      {
        id: 4,
        code: "dhlRate",
        numeric: false,
        disablePadding: true,
        label: "DHL Rate ($S)",
        show: true,
      },
      {
        id: 5,
        code: "isActive",
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true,
      },
      {
        id: 6,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 7,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 8,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 9,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };
  componentDidMount() {
    this.props.handleSettingHeader("DHL Rate");
    this.props.setSelectedList("masterDataOpen", "DHLRate");
    const dispatch = this.context[1];

    getDHLRateList(window.localStorage.token).then((r) => {
      dispatch({
        type: "getDHLRateList",
        newStore: r.data,
      });
      this.getAllDHLRates(r.data);
      this.exportDatas(r.data);
    });
    this.setState({ loading: true });
  }
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.dhlRateList && store.dhlRateList.length > 0)
      if (this.state.rows.length !== store.dhlRateList.length) {
        !this.state.searchClicked && this.getAllDHLRates(store.dhlRateList);
      }
    return true;
  }

  handleViewColumns = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  onRequestSucceed = (deleted) => {
    const [{ store }, dispatch] = this.context;
    getDHLRateList().then((r) => {
      dispatch({
        type: "getDHLRateList",
        newStore: r.data,
      });
      this.setState({ selected: [],loading: false });
      if (deleted) this.setState({ searchClicked: false });
      this.getAllDHLRates(store.dhlRateList);
    });
  };

  sortStateList = (list) => {
    let z = list.sort(function (a, b) {
      let x = a.isActive && a.isActive;
      let y = b.isActive && b.isActive;
      let u = a.modifiedDate && a.modifiedDate;
      let v = b.modifiedDate && b.modifiedDate;
      if (x < y) {
        return 1;
      }
      if (u < v) {
        return -1;
      }
      if (x > y) {
        return -1;
      }
      if (u > v) {
        return 1;
      }
      return 0;
    });
    // console.log(z);
    this.setState({ rows: z });
  };

  getAllDHLRates = (dhlRateList) => {
    let list = dhlRateList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.dhlRateID,
            d.dhlZoneID,
            d.dhlZoneCode,
            d.dhlZoneName,
            d.dhlWeight,
            d.dhlYear,
            d.dhlRate,
            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate,
            d.isActive
          )
        );
      });
    this.sortStateList(rows);
    this.setState({
      rows: rows,
      selected: [],
      loading: false,
      activeRows: rows.filter((x) => x.isActive).map((n) => n.dhlRateID),
    });
  };

  exportDatas = (dhlRateList) => {
    let list = dhlRateList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          DhlZoneCode: d.dhlZoneCode,
          DhlYear: d.dhlYear,
          DhlWeight: d.dhlWeight,
          DhlRate: d.dhlRate,
          Active: d.isActive === true ? "Yes" : "No",
          ModifiedDate: d.modifiedDate,
          ModifiedBy: d.modifiedBy,
          CreatedDate: d.createdDate,
          CreatedBy: d.createdBy,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { rows } = this.state;
    if (event.target.checked) {
      const newSelecteds = rows
        .filter((x) => x.isActive === true)
        .map((n) => n.dhlRateID);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, data) => {
    let dhlRateID = data.dhlRateID;
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(dhlRateID);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, dhlRateID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({ selected: newSelected, selecteddhlRate: data });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({ selected: newSelected, selecteddhlRate: {} });
    }
  };
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleDeleteDhlRate = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let deletionIds = selected.join(",");

    this.setState({ loading: true });

    deleteDHLRate(deletionIds, this.props.history).then((r) => {
      getDHLRateList().then((r) => {
        dispatch({
          type: "getDHLRateList",
          newStore: r.data,
        });
        this.getAllDHLRates(r.data);
      });
      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };
  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  onSearchClicked = (dc) => {
    this.setState({ loading: true, rows: [],searchClicked: true, page: 0 });
  };
  clearSearch = () => {
    const [{ store }, dispatch] = this.context;
    this.setState({ loading: true, rows: [] });
    getDHLRateList().then((r) => {
      dispatch({
        type: "getDHLRateList",
        newStore: r.data,
      });
      this.getAllDHLRates(store.dhlRateList);
      this.setState({ searchClicked: false });
    });
  };
  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      rows,
      selecteddhlRate,
      headCells,
    } = this.state;
    const isSelected = (dhlRateID) => selected.indexOf(dhlRateID) !== -1;
    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selecteddhlRate={selecteddhlRate}
            onSearchClicked={this.onSearchClicked}
            searchClicked={this.state.searchClicked}
            deleteClicked={this.state.deleteClicked}
            clearSearch={this.clearSearch}
            selected={this.state.selected}
            handleViewColumns={this.handleViewColumns}
            headCells={headCells}
            rows={rows}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteDhlRate={this.handleDeleteDhlRate}
            exportDataValues={this.state.exportDataValues}
            dhlRateList={rows}
            isActive={this.state.isActive}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 8em)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
                  : {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 330px)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  //classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />

                <TableBody style={{ overflow: "hidden" }}>
                  {rows.length > 0 ? (
                    stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )

                      .map((row, index) => {
                        const isItemSelected = isSelected(row.dhlRateID);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return (
                          <TableRow
                            hover
                            onClick={(event) => this.handleClick(event, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.dhlRateID}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                className="table-content-cell"
                              >
                                {/* {row.dhlZoneCode} */}
                                {row.dhlZoneName &&
                                row.dhlZoneName.length > 16 ? (
                                  <Tooltip
                                    title={row.dhlZoneName}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dhlZoneName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.dhlZoneName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {this.addZeroes(row.dhlWeight, 3)} */}
                                {/* {row.dhlWeight} */}
                                {row.dhlWeight && row.dhlWeight.length > 16 ? (
                                  <Tooltip
                                    title={row.dhlWeight}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dhlWeight}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.dhlWeight}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.dhlYear} */}
                                {row.dhlYear && row.dhlYear.length > 16 ? (
                                  <Tooltip title={row.dhlYear} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dhlYear}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.dhlYear}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.dhlRate} */}
                                {row.dhlRate && row.dhlRate.length > 16 ? (
                                  <Tooltip title={row.dhlRate} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dhlRate}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.dhlRate}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {status}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.modifiedDate &&
                                row.modifiedDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.modifiedBy} */}
                                {row.modifiedBy &&
                                row.modifiedBy.length > 16 ? (
                                  <Tooltip
                                    title={row.modifiedBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.modifiedBy}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.createdDate &&
                                row.createdDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.createdBy} */}
                                {row.createdBy && row.createdBy.length > 16 ? (
                                  <Tooltip
                                    title={row.createdBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.createdBy}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      {this.state.loading ? (
                        <TableCell colSpan={15} style={{ textAlign: "center" }}>
                          <LinearProgress />
                        </TableCell>
                      ) : (
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                          No result found
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                  {/* {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </div>
          </div>

          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(DHLRateMaster);
