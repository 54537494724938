import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { withRouter } from "react-router-dom";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import ReportViewer from "../../app/ReportViewer";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Button, Paper } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import PrintIcon from "@material-ui/icons/Print";
import Divider from "@material-ui/core/Divider";
import { addDispatchStyles } from "../../../shared/styles";
import Typography from "@material-ui/core/Typography";
import { EditIcon } from "../../../shared/customIcons";
import {
  getParticularUserOrderView,
  getParticularGroupProcessView,
} from "../orderPlacement/actions";
import {
  printDetails,
  pdfDownloadDetails,
} from "../../../shared/tableCommonFunctions";
let orderGroupIID = ""

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Typography>
  );
}
function createData(
  orderCode,
  name,
  workOrderNumber,
  country,
  noOfCopies,
  noOfQuantity,
  orderStatus
) {
  return {
    orderCode,
    name,
    workOrderNumber,
    country,
    noOfCopies,
    noOfQuantity,
    orderStatus,
  };
}
function createData2(gpPrint, gpProcess, gpType, gpRemark, gpQuantity) {
  return {
    gpPrint,
    gpProcess,
    gpType,
    gpRemark,
    gpQuantity,
  };
}
const headCells = [
  {
    id: 1,
    code: "orderCode",
    numeric: false,
    disablePadding: true,
    label: "Order ID",
    show: true,
    align: "left",
  },
  {
    id: 2,
    code: "name ",
    numeric: false,
    disablePadding: true,
    label: "File Name",
    show: true,
  },
  {
    id: 3,
    code: "workOrderNumber",
    numeric: false,
    disablePadding: false,
    label: "Work Order No",
    show: true,
  },
  {
    id: 4,
    code: "country",
    numeric: false,
    disablePadding: false,
    label: "Country",
    show: true,
  },
  {
    id: 5,
    code: "noOfCopies",
    numeric: false,
    disablePadding: true,
    label: "Copies",
    show: true,
  },

  {
    id: 6,
    code: "noOfQuantity",
    numeric: false,
    align: "right",
    disablePadding: true,
    label: "Qty",
    show: true,
  },
  {
    id: 7,
    code: "remarks",
    numeric: false,
    align: "right",
    disablePadding: true,
    label: "Remarks",
    show: true,
  },
  {
    id: 8,
    code: "routing",
    numeric: false,
    align: "right",
    disablePadding: true,
    label: "Routing",
    show: true,
  },
  // {
  //   id: 7,
  //   code: "orderStatus",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Status",
  //   show: true,
  // },
];
const headCells2 = [
  {
    id: 1,
    code: "gpPrint",
    numeric: false,
    disablePadding: true,
    label: "Print",
    show: true,
    align: "left",
  },
  {
    id: 2,
    code: "gpProcess",
    numeric: false,
    disablePadding: true,
    label: "Process",
    show: true,
  },
  {
    id: 3,
    code: "gpType",
    numeric: false,
    disablePadding: false,
    label: "Type",
    show: true,
  },
  {
    id: 4,
    code: "gpRemark",
    numeric: false,
    disablePadding: false,
    label: "Remark",
    show: true,
  },
  {
    id: 5,
    code: "gpQuantity",
    numeric: false,
    disablePadding: true,
    label: "Quantity",
    show: true,
  },
];
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow style={{ background: "#eaeaea" }}>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            //align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderBottom: "2px solid #eaeaea" }}

            style={
              i === headCells.length - 1
                ? {
                  borderRight: "1px solid #eaeaea",
                }
                : i === 0
                  ? {
                    borderLeft: "1px solid #eaeaea",
                  }
                  : {}
            }
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function EnhancedTableHead2(props) {
  return (
    <TableHead>
      <TableRow style={{ background: "#eaeaea" }}>
        {headCells2.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderRight: "2px solid #eaeaea" }}
            style={
              i === headCells.length - 1
                ? {
                  borderRight: "1px solid #eaeaea",
                }
                : i === 0
                  ? {
                    borderLeft: "1px solid #eaeaea",
                  }
                  : {}
            }
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontSize: "18px",
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    fontFamily: ["Roboto"].join(","),
    "&:hover": {
      opacity: 1,
    },
    "&$selected": {
      color: "#000",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      //   color: "#40a9ff"
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

class ViewUserOrder extends React.Component {
  state = {
    value: 0,
    list: [],
    editableList: [],
    fileName: "",
    disableAdd: false,
    disableEdit: true,
    orderGroupCode: "",
    companyName: "",
    orderTitle: "",
    directory: "",
    totalOrderQty: "",
    totalNoOfOrder: "",
    status: "",
    gpPrint: "",
    gpProcess: "",
    gpType: "",
    gpQuantity: "",
    gpRemark: "",
    gpList: [],
    lcCkecked: "",
    planner: "",
  };
  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  componentDidMount() {
    this.props.handleSettingHeader("User Order Placement");
    this.props.setSelectedList("genericSystemOpen", "genericSystem");
    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    let gpSubmit = ""
    if (process.browser) gpSubmit = window.location.pathname.split("/")[3];
    orderGroupIID = orderId
    getParticularUserOrderView(orderId).then((r) => {
      this.setState({
        orderGroupCode: r.orderGroupCode,
        companyName: r.customer,
        orderTitle: r.orderTitle,
        directory: r.dataServiceDirectoryName,
        totalOrderQty: r.totalNoOfQuantity,
        totalNoOfOrder: r.totalNoOfOrders,
        status: r.status,
        list: r.userOrderPlacementList,
        lcCkecked: r.isLCVerified,
        planner: r.plannerName,
        remarks: r.remarks,
        routing: r.routing
      });
    });
    getParticularGroupProcessView(orderId).then((r) => {
      let newGPL = [];
      r.map((res) => {
        newGPL.push({
          gpPrint: res.print,
          gpProcess: res.processName,
          gpType: res.processType,
          gpRemark: res.remarks,
          gpQuantity: res.processQuantity,
        });
      });
      this.setState({
        // gpPrint: r.length > 0 && r[0].print,
        // gpProcess: r.length > 0 && r[0].processName,
        // gpType: r.length > 0 && r[0].processType,
        // gpRemark: r.length > 0 && r[0].remarks,
        // gpQuantity: r.length > 0 && r[0].processQuantity
        gpList: newGPL,
      }, () => {
        if (gpSubmit) {
          this.setState({ value: 1 })
        }
      });
    });
  }
  handleShowPopup = (event, name) => {
    if (name == "routingForRemarks") {
    }
    this.setState({
      showPopup: true,
    });
  };

  handleHidePopup = (event, name) => {
    if (name == "routingForRemarks") {
    }
    this.setState({
      showPopup: false,
    });
  };

  handleShowPopup1 = (event, name) => {
    if (name == "routingForRemarks") {
    }
    this.setState({
      showPopup1: true,
    });
  };

  handleHidePopup1 = (event, name) => {
    if (name == "routingForRemarks") {
    }
    this.setState({
      showPopup1: false,
    });
  };
  render() {
    let acnArr = this.props.roles.filter(
      (u) => u.menuName === "User Order Placement"
    );

    let acnObj = acnArr[0];
    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    let {
      value,
      orderGroupCode,
      companyName,
      orderTitle,
      directory,
      totalOrderQty,
      totalNoOfOrder,
      status,
      list,
      planner,
    } = this.state;
    let { classes } = this.props;
    return (
      <Paper>
        <div style={{ textAlign: "right", marginRight: "2em" }}>
          {acnObj && acnObj.editAction && status !== "Completed" && (
            <Tooltip title="Edit">
              <Link
                to={{
                  pathname: `/edit_userOrder/${orderId}`,
                  state: { approve: acnObj.approveAction }
                }}
              >
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {acnObj && acnObj.printAction && (
            <Tooltip title="Print">
              <IconButton
                aria-label="Print"
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div
          style={{
            //   flexGrow: "1",
            padding: ".5em 2em 1em 2em",
          }}
          // className="view-page-container"
          id="printableUserOrder"
        >
          <div style={{ background: "#fff" }}>
            <AntTabs
              value={value}
              onChange={this.handleChange}
              aria-label="ant example"
              style={{ marginTop: "-28px" }}
              id="order-List-printable"
            >
              <AntTab label="Order Group" />
              <AntTab label="Group Process" />
            </AntTabs>
            <TabPanel value={value} index={0}>
              <React.Fragment>
                <div style={{ margin: ".8em 0 1em 0" }}>
                  {/* <div className="view-page-container" id="printable"> */}
                  <div
                    className="view-page-contents-container"
                    id="view-page-contents-container"
                  >
                    <div
                      className="view-page-contents-sub-container"
                    // style={{ width: "440px" }}
                    >
                      <div className="view-page-label">Order Group ID</div>
                      <div className="view-page-value">{orderGroupCode}</div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                    // style={{ width: "440px" }}
                    >
                      <div className="view-page-label">Customer Name</div>
                      <div className="view-page-value">{companyName}</div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                    // style={{ width: "440px" }}
                    >
                      <div className="view-page-label">Planner</div>
                      <div className="view-page-value">{planner}</div>
                    </div>
                  </div>
                  <div
                    className="view-page-contents-container"
                    id="view-page-contents-container"
                  >
                    <div
                      className="view-page-contents-sub-container"
                    // style={{ width: "440px" }}
                    >
                      <div className="view-page-label">Order Title</div>
                      <div className="view-page-value">{orderTitle}</div>
                    </div>
                    <div
                      className="view-page-contents-sub-container"
                    // style={{ width: "440px" }}
                    >
                      <div className="view-page-label">Directory Name</div>
                      <div className="view-page-value">{directory}</div>
                    </div>
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">Status</div>
                      <div className="view-page-value">{status}</div>
                    </div>
                  </div>
                  <div
                    className="view-page-contents-container"
                    id="view-page-contents-container"
                  >
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">
                        Total Order Quantity
                      </div>
                      <div className="view-page-value">{totalOrderQty}</div>
                    </div>
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">Total No of Orders</div>
                      <div className="view-page-value">{totalNoOfOrder}</div>
                    </div>
                  </div>
                  <div style={{ marginTop: "2em" }}>
                    <div
                      style={{ fontSize: "16px", fontWeight: "600" }}
                      id="order-List-printable"
                    >
                      Order List
                    </div>
                    <Divider />
                  </div>
                  <div style={
                    !this.props.clearOpenList
                      ? {
                        overflow: "auto",
                        maxWidth: "calc(100vw - 190px)",
                        maxHeight: "350px",
                        // margin: "0 .8em 0 .6em"
                      }
                      : {
                        overflow: "auto",
                        maxWidth: "calc(100vw - 420px)",
                        maxHeight: "350px",
                        // margin: "0 .8em 0 .6em"
                      }
                  }>
                    <Table
                      aria-labelledby="tableTitle"
                      size="small"
                      stickyHeader
                      aria-label="sticky table"
                    >
                      {" "}
                      <EnhancedTableHead />
                      <TableBody style={{ overflow: "hidden" }}>
                        {list &&
                          list.length > 0 &&
                          list.map((l, i) => {
                            // console.log(l)
                            return (
                              <TableRow
                                // hover
                                // onClick={event =>
                                //   this.handleClick(event, row.id, row)
                                // }
                                // id={`tableRow${index}`}
                                role="checkbox"
                                //  aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={i}
                              //   selected={isItemSelected}
                              >
                                {" "}
                                <TableCell
                                  component="th"
                                  scope="row"
                                  //id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ width: "168px", minWidth: "168px" }}

                                >
                                  {l.orderCode}
                                </TableCell>
                                {/* <div
                          className="view-page-contents-container"
                          id="view-page-contents-container"
                          style={{ marginTop: ".5em" }}
                          key={i}
                        >
                          <div
                            className="view-page-contents-sub-container"
                            style={{ width: "auto", marginRight: "1.5em" }}
                          >
                            {" "}
                            <div
                              className="view-page-value"
                              style={{ width: "120px" }}
                            > */}
                                {/* {l.orderCode} */}
                                <TableCell
                                  component="th"
                                  scope="row"
                                  //id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ width: "200px", minWidth: "200px" }}

                                >
                                  {l.name && l.name.length > 10 ? (
                                    <Tooltip title={l.name} aria-label="add">
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {l.name}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {l.name}
                                      </Typography>
                                    )}
                                </TableCell>
                                {/* <div
                            className="view-page-contents-sub-container"
                            style={{ width: "auto", marginRight: "1.5em" }}
                          >
                            <div
                              className="view-page-value"
                              style={{ width: "100px" }}
                            > */}
                                {/* </div>
                          </div> */}
                                {/* <div
                            className="view-page-contents-sub-container"
                            style={{ width: "auto", marginRight: "1.5em" }}
                          >
                            <div
                              className="view-page-value"
                              style={{ width: "120px" }}
                            > */}
                                <TableCell
                                  component="th"
                                  scope="row"
                                  //id={labelId}
                                  padding="default"
                                  className="table-content-cell"
                                  style={{ width: "120px", minWidth: "120px" }}

                                >
                                  {" "}
                                  {l.workOrderNumber}
                                </TableCell>
                                {/* </div>
                          </div> */}
                                {/* <div
                            className="view-page-contents-sub-container"
                            style={{ width: "auto", marginRight: "1.5em" }}
                          >
                            <div
                              className="view-page-value"
                              style={{ width: "160px" }}
                            > */}
                                <TableCell
                                  component="th"
                                  scope="row"
                                  //id={labelId}
                                  padding="default"
                                  className="table-content-cell"
                                  style={{ width: "300px", minWidth: "300px" }}

                                >
                                  {l.country}
                                </TableCell>
                                {/* </div>
                          </div> */}
                                {/* <div
                            className="view-page-contents-sub-container"
                            style={{ width: "auto", marginRight: "1.5em" }}
                          >
                            <div
                              className="view-page-value"
                              style={{ width: "50px", textAlign: "right" }}
                            > */}
                                <TableCell
                                  component="th"
                                  scope="row"
                                  //id={labelId}
                                  padding="default"
                                  className="table-content-right-cell"
                                  style={{ width: "100px", minWidth: "100px" }}

                                >
                                  {l.noOfCopies}
                                </TableCell>
                                {/* </div>
                          </div> */}
                                {/* <div
                            className="view-page-contents-sub-container"
                            style={{ width: "auto", marginRight: "1.5em" }}
                          >
                            <div
                              className="view-page-value"
                              style={{ width: "50px", textAlign: "right" }}
                            > */}
                                <TableCell
                                  component="th"
                                  scope="row"
                                  //id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-right-cell"
                                >
                                  {l.noOfQuantity}
                                </TableCell>
                                {/* {l.noOfQuantity}
                            </div>
                          </div> */}
                                {/* <div
                            className="view-page-contents-sub-container"
                            style={{ width: "auto" }}
                          >
                            <div
                              className="view-page-value"
                              style={{ width: "120px" }}
                            > */}
                                <TableCell
                                  component="th"
                                  scope="row"
                                  //id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ width: "120px", minWidth: "120px" }}

                                >
                                  {l.remraks}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  //id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  {l.routing}
                                </TableCell>
                                {/* <TableCell
                                component="th"
                                scope="row"
                                //id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                              >
                                {" "}
                                {l.orderStatus}
                              </TableCell> */}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </div>
                  <Divider style={{ marginTop: "25px" }} />
                  <div style={{ display: "flex" }}>
                    <div className="button-wrapper">
                      <Button
                        id="non-printable"
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={(e) => {
                          this.props.history.push("/genericSystem");
                        }}
                      >
                        CANCEL
                    </Button>

                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        id="non-printable"
                        variant="contained"
                        color="primary"
                        style={{ marginRight: "1em" }}
                        onClick={(e) =>
                          this.handleShowPopup(e, "remarksForRouting")
                        }
                      >
                        CML Remarks By Routing
                      </Button>
                      <Button
                        id="non-printable"
                        variant="contained"
                        color="primary"
                        onClick={(e) =>
                          this.handleShowPopup1(e, "routingForRemarks")
                        }
                      >
                        CML Routing By Remarks
                      </Button>
                    </div>
                    {this.state.showPopup && (
                      <ReportViewer
                        url={
                          `${window.localStorage.ReportURLByID}` +
                          "BreakDownRemarksRouting&IsDirectPrint=False&ReportParam=OrderGroupID,FileName,CountryName&Param1=" +
                          `${orderGroupIID}` +
                          "&Param2=ALL&Param3=ALL"
                        }
                        showPopup={this.state.showPopup}
                        onClose={(e) =>
                          this.handleHidePopup(e, "remarksForRouting")
                        }
                        title={"Remarks By Routing"}
                        handleHidePopup={(e) =>
                          this.handleHidePopup(e, "remarksForRouting")
                        }
                      />
                    )}

                    {this.state.showPopup1 && (
                      <ReportViewer
                        url={
                          `${window.localStorage.ReportURLByID}` +
                          "BREAKDOWNROUTINGBYREMARKS&IsDirectPrint=False&ReportParam=OrderGroupID,FileName,CountryName&Param1=" +
                          `${orderGroupIID}` +
                          "&Param2=ALL&Param3=ALL"
                        }
                        showPopup={this.state.showPopup1}
                        onClose={(e) =>
                          this.handleHidePopup1(e, "routingForRemarks")
                        }
                        title={"Routing By Remarks"}
                        handleHidePopup={(e) =>
                          this.handleHidePopup1(e, "routingForRemarks")
                        }
                      />
                    )}
                  </div>
                </div>
              </React.Fragment>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <React.Fragment>
                <div style={{ margin: ".8em 0 1em 0" }}>
                  {/* <div className="view-page-container" id="printable"> */}
                  <div
                    className="view-page-contents-container"
                    id="view-page-contents-container"
                  >
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">Order Group ID</div>
                      <div className="view-page-value">{orderGroupCode}</div>
                    </div>
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">Customer Name</div>
                      <div className="view-page-value">{companyName}</div>
                    </div>
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">Order Title</div>
                      <div className="view-page-value">{orderTitle}</div>
                    </div>
                  </div>
                  <div
                    className="view-page-contents-container"
                    id="view-page-contents-container"
                  >
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">
                        Total Order Quantity
                      </div>
                      <div className="view-page-value">{totalOrderQty}</div>
                    </div>
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">Total No of Orders</div>
                      <div className="view-page-value">{totalNoOfOrder}</div>
                    </div>
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">Status</div>
                      <div className="view-page-value">{status}</div>
                    </div>
                  </div>
                  <div
                    className="view-page-contents-container"
                    id="view-page-contents-container"
                  >
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">LC Verified</div>
                      <div className="view-page-value">
                        {this.state.lcCkecked ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="view-page-contents-sub-container">
                      <div className="view-page-label">Planner</div>
                      <div className="view-page-value">{planner}</div>
                    </div>
                  </div>
                  <div style={{ marginTop: "2em" }}>
                    <div
                      style={{ fontSize: "16px", fontWeight: "600" }}
                      id="order-List-printable"
                    >
                      Process List
                    </div>
                    <Divider />
                  </div>
                  <div
                    // style={{ maxHeight: 350, overflow: "auto" }}
                    style={
                      !this.props.clearOpenList
                        ? {
                          overflow: "auto",
                          maxWidth: "calc(100vw - 170px)",
                          maxHeight: "350px",
                          // margin: "0 .8em 0 .6em"
                        }
                        : {
                          overflow: "auto",
                          maxWidth: "calc(100vw - 400px)",
                          maxHeight: "350px",
                          // margin: "0 .8em 0 .6em"
                        }
                    }
                  >
                    <Table
                      aria-labelledby="tableTitle"
                      size="small"
                      stickyHeader
                      aria-label="sticky table"
                    >
                      {" "}
                      <EnhancedTableHead2 />
                      <TableBody style={{ overflow: "hidden" }}>
                        {this.state.gpList.map((gpl, i) => {
                          return (
                            <TableRow
                              // hover
                              // onClick={event =>
                              //   this.handleClick(event, row.id, row)
                              // }
                              // id={`tableRow${index}`}
                              role="checkbox"
                              //  aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={i}
                            //   selected={isItemSelected}
                            >
                              {" "}
                              <TableCell
                                component="th"
                                scope="row"
                                //id={labelId}
                                padding="default"
                                className="table-content-cell"
                                style={{
                                  paddingRight: "10px",
                                  width: "245px",
                                  minWidth: "245px"
                                }}
                              >
                                {gpl.gpPrint}
                              </TableCell>{" "}
                              <TableCell
                                component="th"
                                scope="row"
                                //id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                                id="processName-1"
                                style={{
                                  // paddingRight: "10px",
                                  width: "450px",
                                  minWidth: "450px"
                                }}
                              >
                                {gpl.gpProcess}
                              </TableCell>{" "}
                              <TableCell
                                component="th"
                                scope="row"
                                //id={labelId}
                                padding="default"
                                // align="left"
                                className="table-content-cell"
                                style={{
                                  paddingRight: "7px",
                                  width: "220px",
                                  minWidth: "220px"
                                }}
                              >
                                {gpl.gpType}
                              </TableCell>{" "}
                              <TableCell
                                component="th"
                                scope="row"
                                //id={labelId}
                                padding="default"
                                // align="left"
                                style={{
                                  width: "160px",
                                  minWidth: "160px",
                                  paddingRight: "6px",
                                }}
                                className="table-content-cell"
                              >
                                {gpl.gpRemark}
                              </TableCell>{" "}
                              <TableCell
                                component="th"
                                scope="row"
                                //id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-right-cell"
                                style={{
                                  // width: "190px",
                                  // minWidth: "190px",
                                  paddingRight: "6px",
                                }}
                              >
                                {gpl.gpQuantity}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                  <div style={{ marginTop: "2em" }}>
                    <Divider />
                  </div>
                  <div style={{ marginTop: ".5em", marginLeft: "0em" }}>
                    <Button
                      id="non-printable"
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => {
                        this.props.history.push("/genericSystem");
                      }}
                    >
                      CANCEL
                    </Button>
                  </div>
                </div>
              </React.Fragment>
            </TabPanel>
          </div>
        </div>
      </Paper>
    );
  }
}
export default withRouter(withStyles(addDispatchStyles)(ViewUserOrder));
