import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import clone from "clone";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import moment from "moment";
import { ViewColumnIcon } from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import { stableSort, getSorting } from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import Validator from "../../../shared/validator";
import { Checkbox } from "@material-ui/core";
import { StateContext } from "../../../shared/globalState";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ExportCSV } from "../../../shared/ExportCSV";
import StatusText from "../../../shared/statusText";
import {
  getEMLOProcessList,
  getEMLOList,
  deleteEMLO,
  seacrhEmloAdvanceSearch,
  UpdateEMLOOrderPlacementToInProgess,
} from "../emloOrderPlacement/action";

const columns = [
  {
    name: "Modified Date",
    id: 18,
    // show: false
  },
  {
    name: "Modified By",
    id: 19,
  },
  {
    name: "Created Date",
    id: 20,
  },
  {
    name: "Created By",
    id: 21,
  },
];
const notRequiredAlpNumHypDot50 = new Validator("notRequiredAlpNumHypDot50");

function createData(
  emloOrderID,
  client,
  emloNo,
  type,
  status,
  acronym,
  volume,
  issue,
  part,
  supp,
  trackingNumber,
  orderCreatedBy,
  department,
  issueType,
  category,
  quantity,
  deliveryMethod,
  modifiedDate,
  modifiedByName,
  createdDate,
  createdByName,
  countryName,
  recipientName
) {
  return {
    emloOrderID,
    client,
    emloNo,
    type,
    status,
    acronym,
    volume,
    issue,
    part,
    supp,
    trackingNumber,
    orderCreatedBy,
    department,
    issueType,
    category,
    quantity,
    deliveryMethod,
    modifiedDate,
    modifiedByName,
    createdDate,
    createdByName,
    countryName,
    recipientName,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                //checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all EMLO's" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={"left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.code}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
            //);
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    client: "",
    emlo: "",
    type: "",
    status: "",
    description: "",
    acronym: "",
    volume: "",
    issue: "",
    part: "",
    supp: "",
    trackingNumber: "",
    orderDate: "",
    orderCreated: "",
    department: "",
    issueType: "",
    category: "",
    quantity: "",
    deliveryMethod: "",
  });
  const [formValid, setIsformValid] = useState(false);

  const [emploSearchValue, setValues] = useState({
    client: "",
    emlo: "",
    type: "",
    status: "",
    description: "",
    acronym: "",
    volume: "",
    issue: "",
    part: "",
    supp: "",
    trackingNumber: "",
    orderDate: "",
    orderCreated: "",
    department: "",
    issueType: "",
    category: "",
    quantity: "",
    deliveryMethod: "",
  });

  const classes = useToolbarStyles();
  const { numSelected, hendleVewColums, selected } = props;

  //const selectedEMLOID = selected && selected.length > 0 && selected[0];
  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  const selectedEMLOID = selected[0];
  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
  };

  let expData = clone(
    props.emloList && props.emloList.length > 0 && props.emloList
  );
  expData &&
    expData.length > 0 &&
    expData.map((d) => {
      // d["emloOrderID"]=d["emloOrderID"];
      d["Customer"] = d["client"];
      d["Emlo No"] = d["emloNo"];
      d["Type"] = d["type"];
      d["Status"] = d["status"];
      d["Acronym"] = d["acronym"];
      d["Volume"] = d["volume"];
      d["Issue"] = d["issue"];
      d["Part"] = d["part"];
      d["Supp"] = d["supp"];
      d["Tracking Number"] = d["trackingNumber"];
      d["Order Created By"] = d["orderCreatedBy"];
      d["Department"] = d["department"];
      d["Issue Type"] = d["issueType"];
      d["Category"] = d["category"];
      d["Quantity"] = d["quantity"];
      d["Delivery Method"] = d["deliveryMethod"];

      d["Created By Name"] = d["createdByName"];
      d["Created Date"] = moment(d["createdDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      d["Modified By Name"] = d["modifiedByName"];

      d["Modified Date"] = moment(d["modifiedDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      delete d["countryName"];
      delete d["recipientName"];
      delete d["createdDate"];
      delete d["createdByName"];
      delete d["modifiedDate"];
      delete d["modifiedByName"];
      delete d["emloOrderID"];
      delete d["client"];
      delete d["emloNo"];
      delete d["type"];
      delete d["status"];
      delete d["acronym"];
      delete d["volume"];
      delete d["issue"];
      delete d["part"];
      delete d["supp"];
      delete d["trackingNumber"];
      delete d["orderCreatedBy"];
      delete d["department"];
      delete d["issueType"];
      delete d["category"];
      delete d["quantity"];
      delete d["deliveryMethod"];
    });

  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          margin: numSelected > 0 && "5px 0",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={() => props.startProcessingHandler(selected)}
              >
                {" "}
                Start Processing{" "}
              </Button>
            </div>
            {/* {
              <Tooltip title="Cancel">
                <IconButton
                  aria-label="cancel"
                  className={classes.iconHover}
                  onClick={(e) => props.history.push("/pubEmloOrderPlacement")}
                >
                  <CancelOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            } */}
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            >
              {
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) =>
                    props.history.push("/add_pubEmloBatchProcessing")
                  }
                >
                  {" "}
                Update Tracking Info{" "}
                </Button>
              }
              {
                <Tooltip title="Cancel">
                  <IconButton
                    aria-label="cancel"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/pubEmloOrderPlacement")}
                  >
                    <CancelOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              }
              <ExportCSV csvData={expData} fileName={"EMLO_Batch_Processing"} />

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                hendleVewColums={hendleVewColums}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class EmloBatchProcessing extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    emloList: [],
    rows: [],
    activeRows: 0,
    isActive: false,
    loading: false,
    refreshData: false,
    selectedemlo: {},
    selectedemloID: 1,
    searchPopupOpen: false,
    emploSearchValues: {},
    searchClicked: false,
    selectedShowColumns: [],
    exportDataValues: [],
    headCells: [
      {
        id: 1,
        code: "client",
        numeric: false,
        disablePadding: false,
        label: "Customer",
        show: true,
      },
      {
        id: 2,
        code: "emloNo",
        numeric: false,
        disablePadding: false,
        label: "EMLO",
        show: true,
      },

      {
        id: 3,
        code: "type",
        numeric: false,
        disablePadding: false,
        label: "Type",
        show: true,
      },
      {
        id: 4,
        code: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        show: true,
      },
      {
        id: 5,
        code: "description",
        numeric: false,
        disablePadding: false,
        label: "Issue Description",
        show: false,
      },
      {
        id: 6,
        code: "acronym",
        numeric: false,
        disablePadding: false,
        label: "Acronym",
        show: true,
      },
      {
        id: 7,
        code: "volume",
        numeric: false,
        disablePadding: false,
        label: "Volume",
        show: true,
      },
      {
        id: 8,
        code: "issue",
        numeric: false,
        disablePadding: false,
        label: "Issue",
        show: true,
      },
      {
        id: 9,
        code: "part",
        numeric: false,
        disablePadding: false,
        label: "Part",
        show: true,
      },
      {
        id: 10,
        code: "supp",
        numeric: false,
        disablePadding: false,
        label: "Supp",
        show: true,
      },
      {
        id: 11,
        code: "trackingNumber",
        numeric: false,
        disablePadding: false,
        label: "Tracking Number",
        show: true,
      },
      {
        id: 12,
        code: "orderCreatedBy",
        numeric: false,
        disablePadding: false,
        label: "Order Created By",
        show: true,
      },
      {
        id: 13,
        code: "department",
        numeric: false,
        disablePadding: false,
        label: "Department",
        show: true,
      },
      {
        id: 14,
        code: "issueType",
        numeric: false,
        disablePadding: false,
        label: "Issue Type",
        show: true,
      },
      {
        id: 15,
        code: "category",
        numeric: false,
        disablePadding: false,
        label: "Category",
        show: true,
      },
      {
        id: 16,
        code: "quantity",
        numeric: false,
        disablePadding: false,
        label: "Quantity",
        show: true,
      },
      {
        id: 17,
        code: "deliveryMethod",
        numeric: false,
        disablePadding: false,
        label: "Delivery Method",
        show: true,
      },
      {
        id: 18,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 19,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 20,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 21,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };

  onSearchClicked = (reqBody) => {
    this.setState({ rows: [], loading: true });
    seacrhEmloAdvanceSearch(reqBody).then((r) => {
      this.getEMLO(r);
    });
    this.setState({ searchClicked: true });
  };
  clearSearch = async () => {
    this.setState({ rows: [], loading: true });
    await getEMLOList().then((r) => {
      this.getEMLO(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };
  componentDidMount() {
    this.props.handleSettingHeader("EMLO Batch Processing");
    this.props.setSelectedList(
      "publisherMainLabelOpen",
      "pubEmloBatchProcessing"
    );
    const dispatch = this.context[1];

    getEMLOProcessList().then((r) => {
      this.getEMLO(r.data);
      this.exportemloDatas(r.data);
    });
    this.setState({ loading: true });
  }

  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  exportemloDatas = (data) => {
    let list = data;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          Client: d.client,
          EMLO: d.emlo,
          Type: d.type,
          Description: d.description,
          Acronym: d.acronym,
          Volume: d.volume,
          Issue: d.issue,
          Part: d.part,
          Supp: d.supp,
          TrackingNumber: d.trackingNumber,
          OrderData: d.orderDate,
          OrderCreated: d.orderCreated,
          Department: d.department,
          IssueType: d.issueType,
          Category: d.category,
          Quantity: d.quantity,
          DeliveryMethod: d.deliveryMethod,
          Status: d.status,
          CreatedBy: d.createdBy,
          CreatedDate: d.createdDate,
          ModifiedBy: d.modifiedBy,
          ModifiedDate: d.modifiedDate,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getEMLO = (data) => {
    // const [{ store }, dispatch] = this.context;
    let list = data;
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return newRows.push(
          createData(
            d.emloOrderID,
            d.client,
            d.emloNo,
            d.type,
            d.status,
            d.acronym,
            d.volume,
            d.issue,
            d.part,
            d.supp,
            d.trackingNumber,
            d.orderCreatedBy,
            d.department,
            d.issueType,
            d.category,
            d.quantity,
            d.deliveryMethod,
            d.modifiedDate,
            d.modifiedByName,
            d.createdDate,
            d.createdByName,
            d.countryName,
            d.recipientName
          )
        );
      });
    this.setState({
      emloList: newRows,
      selected: [],
      rows: newRows,
      loading: false,
      activeRows: newRows.filter((x) => x.isActive).map((n) => n.codeTypeID),
    });
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleClick = (event, data, id) => {
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedemlo: data,
        selectedemloID: id,
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedAcronym: data,
        selectedemloID: id,
      });
    }
  };

  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleDeleteEMLO = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteEMLO(modifiedBy, deletionIds).then((r) => {
      getEMLOList().then((r) => {
        this.getEMLO(r.data);
        this.exportemloDatas(r.data);
      });
      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };

  clearSearch = () => {
    const dispatch = this.context[1];

    this.setState({ rows: [], loading: true });
    getEMLOList().then((r) => {
      this.getEMLO(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  handleSelectAllClick = (event) => {
    let { emloList } = this.state;
    if (event.target.checked) {
      const newSelecteds = emloList.map((n) => n.emloOrderID);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  startProcessingHandler = (ids) => {
    let emloIds = ids.join(",");
    let modifiedBy = window.localStorage.loggedInId;
    let reqBody = { emloIds, modifiedBy };
    UpdateEMLOOrderPlacementToInProgess(reqBody).then((r) => {
      getEMLOProcessList().then((r) => {
        this.getEMLO(r.data);
      });
    });
    this.setState({ loading: true });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      emloList,
      headCells,
      exportDataValues,
    } = this.state;
    const isSelected = (codeTypeID) => selected.indexOf(codeTypeID) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteEMLO={this.handleDeleteEMLO}
            selectedemloID={this.state.selectedemloID}
            selectedemloIDId={this.state.selectedemloIDId}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchemloCode={this.handleSearchemloCode}
            emploSearchValue={this.state.emploSearchValues}
            searchClicked={this.state.searchClicked}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            emloList={emloList}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            onSearchClicked={this.onSearchClicked}
            rows={this.state.rows}
            startProcessingHandler={this.startProcessingHandler}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 110px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
                style={{
                  borderLeft: "1px solid  rgba(224, 224, 224, 1)",
                  whiteSpace: "nowrap",
                }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.rows.length}
                  headCells={this.state.headCells}
                  acronymList={clone(this.state.rows)}
                  startProcessingHandler={this.startProcessingHandler}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {emloList.length > 0 ? (
                    stableSort(emloList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.emloOrderID); //paste the given id
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    onClick={(event) =>
                                      this.handleClick(
                                        event,
                                        row,
                                        row.emloOrderID
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.client}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.emloNo}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.type}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.status && row.status.length > 10 ? (
                                    <Tooltip title={row.status}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.status == "Submitted" ? (
                                          <StatusText color={"#16831F"}>
                                            Submitted
                                          </StatusText>
                                        ) : row.status == "UnSubmitted" ? (
                                          <StatusText color={"#F4B43C"}>
                                            UnSubmitted
                                          </StatusText>
                                        ) : row.status == "Processed" ? (
                                          <StatusText color={"#0091ff"}>
                                            Processed
                                          </StatusText>
                                        ) : row.status == "Cancelled" ? (
                                          <StatusText color={"red"}>
                                            Cancelled
                                          </StatusText>
                                        ) : (
                                                  <StatusText color={"grey"}>
                                                    {row.status}
                                                  </StatusText>
                                                )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.status == "Submitted" ? (
                                          <StatusText color={"#16831F"}>
                                            Submitted
                                          </StatusText>
                                        ) : row.status == "UnSubmitted" ? (
                                          <StatusText color={"#F4B43C"}>
                                            UnSubmitted
                                          </StatusText>
                                        ) : row.status == "Processed" ? (
                                          <StatusText color={"#0091ff"}>
                                            Processed
                                          </StatusText>
                                        ) : row.status == "Cancelled" ? (
                                          <StatusText color={"red"}>
                                            Cancelled
                                          </StatusText>
                                        ) : (
                                                  <StatusText color={"grey"}>
                                                    {row.statusName}
                                                  </StatusText>
                                                )}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.acronym}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.volume}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.issue}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.part}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.supp}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.trackingNumber}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.orderCreatedBy}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.department}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.issueType}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.category}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[15].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.quantity}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[16].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.deliveryMethod}
                              </TableCell>
                            )}
                            {this.state.headCells[17].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[18].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.modifiedByName}
                              </TableCell>
                            )}
                            {this.state.headCells[19].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-right-cell"
                                visibility="hidden"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[20].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdByName}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={12} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={emloList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(EmloBatchProcessing);
