import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { putEmailInsertMaster } from "../action";
import { StateContext } from "../../../shared/globalState";
// import { addStyles } from "../../../shared/styles";
import { getViewDetailsForInsertMaster, putInsertMaster } from "../action";
import { resourceValidation } from "../../../shared/resource";
import IndividualSearchAddDialog from "../../publisherBackLabel/ebloOrderPlacement/IndividualSearchAddDialog";
const emailValidator = new Validator("email");
const validateMaxLength2 = new Validator("maxlength2");
const maxMinlength2 = new Validator("maxMinlength2");
const maxlength10AllAllow = new Validator("maxlength10AllAllow");
const maxlength10 = new Validator("maxlength10");
const onlyNumbers = new Validator("onlyNumbers");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    // marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "31%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
});

class EditinsertMaster extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      jouranlID: "",
      insertNo: "",
      acronym: "",
      volume: "",
      issue: "",
      part: "",
      suppNumber: "",
      issueDescription: "",
      insertName: "",
      type: "",
      insertWeight: "",
      quantity: "",
      noOfpages: "",
      insertSize: "",
      foldingRequired: "",
      applyTo: "",
      recipients: "",
      placement: "",
      oversHandling: "",
      societyInsert: "",
      jdsrInstruction: "",
      contactName: "",
      actualQuantity: "",
      actualInsertWeight: "0.000",
      eMail: "",
      isActive: true,
      modifiedBy: window.localStorage.loggedInId,
      openDialog: false,
      errors: {},
      active: false,
      statusName: "",
      customerNameValid: true,
      insertNoValid: true,
      acronymValid: true,
      volumeValid: true,
      issueValid: true,
      suppNumberValid: true,
      issueDescriptionValid: true,
      insertNameValid: true,
      typeValid: true,
      insertWeightValid: true,
      quantityValid: true,
      actualInsertWeightValid: true,
      actualQuantityValid: true,
      noofPagesValid: true,
      insertSizeValid: true,
      foldingRequiredValid: true,
      applyToValid: true,
      recipientsValid: true,
      placementValid: true,
      oversHandlingValid: true,
      societyInsertValid: true,
      contactNameValid: true,
      eMailValid: true,
      formValid: false,
      openDialog: false,
      updatedValues: false,
      journalData: {
        journalID: "",
        issueDescription: "",
        acronym: "",
        journalTitle: "",
        volume: "",
        issue: "",
        supplement: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleAddJournalClick = () => {
    this.setState({
      openDialog: true,
    });
  };

  handleAddJournalClose = () => {
    this.setState({
      openDialog: false,
    });
  };

  componentDidMount() {
    this.props.handleSettingHeader("Insert Master");
    this.props.setSelectedList("publisherMainLabelOpen", "pubInsertMaster");
    const dispatch = this.context[1];
    let insertMasterId =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];

    getViewDetailsForInsertMaster(insertMasterId).then((r) => {
      dispatch({
        type: "getInsertmaster",
        newStore: r.data,
      });
      this.setState({
        jouranlID: r.data.jouranlID,
        insertNo: r.data.insertNo,
        acronym: r.data.acronym,
        volume: r.data.volume,
        issue: r.data.issue,
        part: r.data.part,
        suppNumber: r.data.suppNumber,
        issueDescription: r.data.issueDescription,
        insertName: r.data.insertName,
        type: r.data.type != null && r.data.type,
        insertWeight: r.data.insertWeight || 0,
        quantity: r.data.quantity || 0,
        noOfpages: r.data.noofPages,
        insertSize: r.data.insertSize,
        foldingRequired: r.data.foldingRequired,
        applyTo: r.data.applyTo,
        recipients: r.data.recipients,
        placement: r.data.placement,
        oversHandling: r.data.oversHandling,
        societyInsert: r.data.societyInsert,
        jdsrInstruction: r.data.jdsrInstruction,
        contactName: r.data.contactName,
        actualQuantity: r.data.actualQuantity || 0,
        actualInsertWeight: r.data.actualInsertWeight || 0,
        eMail: r.data.eMail,
        statusName: r.data.statusName,
      });
    });
  }

  handleChange = (e, validate) => {
    this.setState({ updatedValues: true });
    if (e.target.name == "actualInsertWeight") {
      let newRrrors = { ...this.state.errors };
      let result = e.target.value;
      newRrrors[e.target.name] = !result;
      let dhlValues = e.target.value;
      dhlValues = this.checkDHlWeightDec(e.target.value.toString());
      this.setState({ [e.target.name]: dhlValues });
    } else {
      let newRrrors = { ...this.state.errors };
      let result = e.target.value;
      newRrrors[e.target.name] = !result;
      this.setState({ [e.target.name]: e.target.value });
    }

    if (e.target.name == "eMail") {
      let newRrrors = { ...this.state.errors };
      let result = validate(e.target.value);
      newRrrors[e.target.name] = !result;
      this.setState({ [e.target.name]: e.target.value, errors: newRrrors });
    }
    this.validateField(e.target.name, e.target.value);
  };

  handleJournalUpdate = async (selectedJournalList) => {
    this.setState({ formValid: true });
    await this.setState({
      journalData: selectedJournalList[0],
      jouranlID: selectedJournalList[0].journalID,
      acronym: selectedJournalList[0] && selectedJournalList[0].acronym,
      volume: selectedJournalList[0] && selectedJournalList[0].volume,
      issue: selectedJournalList[0] && selectedJournalList[0].issue,
      suppNumber: selectedJournalList[0] && selectedJournalList[0].suppNumber,
      issueDescription:
        selectedJournalList[0] && selectedJournalList[0].issueDescription,
      openDialog: false,
    });
  };

  clearJournalInfo = (i) => {
    let newjournalData = { ...this.state.journalData };
    newjournalData = {};
    this.setState({ workOrder: newjournalData });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let insertNoValid = this.state.insertNoValid;
    let acronymValid = this.state.acronymValid;
    let volumeValid = this.state.volumeValid;
    let issueValid = this.state.issueValid;
    let suppNumberValid = this.state.suppNumberValid;
    let issueDescriptionValid = this.state.issueDescriptionValid;
    let insertNameValid = this.state.insertNameValid;
    let typeValid = this.state.typeValid;
    let insertWeightValid = this.state.insertWeightValid;
    let quantityValid = this.state.quantityValid;
    let actualInsertWeightValid = this.state.actualInsertWeightValid;
    let actualQuantityValid = this.state.actualQuantityValid;
    let noofPagesValid = this.state.noofPagesValid;
    let insertSizeValid = this.state.insertSizeValid;
    let foldingRequiredValid = this.state.foldingRequiredValid;
    let applyToValid = this.state.applyToValid;
    let recipientsValid = this.state.recipientsValid;
    let placementValid = this.state.placementValid;
    let oversHandlingValid = this.state.oversHandlingValid;
    let societyInsertValid = this.state.societyInsertValid;
    let contactNameValid = this.state.contactNameValid;
    let emailValid = this.state.emailValid;
    switch (fieldName) {
      case "insertNo":
        insertNoValid = maxlength10AllAllow(value);
        fieldValidationErrors.insertNo = insertNoValid
          ? ""
          : " Please enter the insertNo";
        break;
      case "acronym":
        acronymValid = maxlength10(value);
        fieldValidationErrors.acronym = acronymValid
          ? ""
          : " Please enter the acronym";
        break;
      case "volume":
        volumeValid = maxlength10(value);
        fieldValidationErrors.volume = volumeValid
          ? ""
          : " Please enter the volume";
        break;
      case "issue":
        issueValid = maxlength10(value);
        fieldValidationErrors.issue = issueValid
          ? ""
          : " Please enter the issue";
        break;
      case "suppNumber":
        suppNumberValid = maxlength10(value);
        fieldValidationErrors.suppNumber = suppNumberValid
          ? ""
          : " Please enter the suppNumber";
        break;
      case "issueDescription":
        issueDescriptionValid = maxlength10(value);
        fieldValidationErrors.issueDescription = issueDescriptionValid
          ? ""
          : " Please enter the issueDescription";
        break;
      case "insertName":
        insertNameValid = maxlength10(value);
        fieldValidationErrors.insertName = insertNameValid
          ? ""
          : " Please enter the insertName";
        break;
      case "type":
        typeValid = maxlength10(value);
        fieldValidationErrors.type = typeValid ? "" : " Please enter the value";
        break;
      case "insertWeight":
        insertWeightValid = maxlength10(value);
        fieldValidationErrors.insertWeight = insertWeightValid
          ? ""
          : " Please enter the insertWeight";
        break;
      case "quantity":
        quantityValid = maxlength10(value);
        fieldValidationErrors.quantity = quantityValid
          ? ""
          : " Please enter the quantity";
        break;
      case "actualInsertWeight":
        actualInsertWeightValid = onlyNumbers(value);
        fieldValidationErrors.actualInsertWeight = actualInsertWeightValid
          ? ""
          : " Please enter the actualInsertWeight";
        break;
      case "actualQuantity":
        actualQuantityValid = onlyNumbers(value);
        fieldValidationErrors.actualQuantity = actualQuantityValid
          ? ""
          : " Please enter the actualQuantity";
        break;
      case "noofPages":
        noofPagesValid = maxlength10(value);
        fieldValidationErrors.noofPages = noofPagesValid
          ? ""
          : " Please enter the noofPages";
        break;
      case "insertSize":
        insertSizeValid = maxlength10(value);
        fieldValidationErrors.insertSize = insertSizeValid
          ? ""
          : " Please enter the insertSize";
        break;
      case "foldingRequired":
        foldingRequiredValid = maxlength10(value);
        fieldValidationErrors.foldingRequired = foldingRequiredValid
          ? ""
          : " Please enter the foldingRequired";
        break;
      case "applyTo":
        applyToValid = maxlength10(value);
        fieldValidationErrors.applyTo = applyToValid
          ? ""
          : " Please enter the applyTo";
        break;
      case "recipients":
        recipientsValid = maxlength10(value);
        fieldValidationErrors.recipients = recipientsValid
          ? ""
          : " Please enter the recipients";
        break;
      case "placement":
        placementValid = maxlength10(value);
        fieldValidationErrors.placement = placementValid
          ? ""
          : " Please enter the placement";
        break;
      case "oversHandling":
        oversHandlingValid = maxlength10(value);
        fieldValidationErrors.oversHandling = oversHandlingValid
          ? ""
          : " Please enter the oversHandling";
        break;
      case "societyInsert":
        societyInsertValid = maxlength10(value);
        fieldValidationErrors.societyInsert = societyInsertValid
          ? ""
          : " Please enter the societyInsert";
        break;
      case "contactName":
        contactNameValid = maxlength10(value);
        fieldValidationErrors.contactName = contactNameValid
          ? ""
          : " Please enter the contactName";
        break;
      case "eMail":
        if (value !== "") {
          emailValid = emailValidator(value);
          fieldValidationErrors.email = emailValid
            ? ""
            : resourceValidation.validEmailId;
        }
        break;

      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        insertNoValid,
        // acronymValid,
        // volumeValid,
        // issueValid,
        // suppNumberValid,
        // issueDescriptionValid,
        insertNameValid,
        typeValid,
        insertWeightValid,
        quantityValid,
        actualInsertWeightValid,
        actualQuantityValid,
        noofPagesValid,
        insertSizeValid,
        foldingRequiredValid,
        applyToValid,
        recipientsValid,
        placementValid,
        oversHandlingValid,
        societyInsertValid,
        contactNameValid,
        emailValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.state.updatedValues &&
      this.setState({
        formValid:
          this.state.insertName &&
          this.state.type &&
          this.state.insertWeight &&
          this.state.quantity
      });

    return this.state.formValid;
  }

  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertKeyPress(event) {
    const re = /[a-zA-Z0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _freetext(event) {
    const re = /[#.0-9a-zA-Z\s,-@()%!$%^&*{}/<>_;:'" |]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onTextKeyPress(event) {
    const re = /[a-z A-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusNumberKeyPress(event) {
    const re = /[0-9 a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onWeightKeyPress(event) {
    const re = /[0-9 a-zA-Z.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertSizeKeyPress(event) {
    const re = /[0-9 a-zA-Z-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusAllPress(event) {
    const re = /[a-z A-Z0-9=.;()&@]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  onBlur = (event) => {
    // const { dhlRateValues } = this.state;
    let insertMasterValues = event.target.value;
    insertMasterValues = this.addZeroes(insertMasterValues.toString(), 3);
    this.setState({ [event.target.name]: insertMasterValues });
  };

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let insertMasterId =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];
    const {
      jouranlID,
      insertNo,
      acronym,
      volume,
      issue,
      part,
      suppNumber,
      issueDescription,
      insertName,
      type,
      insertWeight,
      quantity,
      noOfpages,
      insertSize,
      foldingRequired,
      applyTo,
      recipients,
      placement,
      oversHandling,
      societyInsert,
      jdsrInstruction,
      contactName,
      actualQuantity,
      actualInsertWeight,
      eMail,
      isActive,
      modifiedBy,
    } = this.state;

    let reqBody = {
      jouranlID,
      insertNo,
      acronym,
      volume,
      issue,
      part,
      suppNumber,
      issueDescription,
      insertName,
      type,
      insertWeight,
      quantity,
      noofPages: noOfpages,
      insertSize,
      foldingRequired,
      applyTo,
      recipients,
      placement,
      oversHandling,
      societyInsert,
      jdsrInstruction,
      contactName,
      actualQuantity,
      actualInsertWeight,
      eMail,
      isActive,
      modifiedBy,
    };
    this.state.modifiedBy = localStorage.loggedInId;

    putInsertMaster(insertMasterId, reqBody, this.props.history);
  };
  render() {
    let { classes } = this.props;
    let { journalData } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Insert Master</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <p
                    style={{ display: "inline-block", marginRight: "1em" }}
                    className={classes.subHeader}
                  >
                    Journal Details
                  </p>
                  <Button
                    onClick={this.handleAddJournalClick}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{
                      width: "172px",
                      padding: ".2em",
                      marginTop: "-1em",
                      boxShadow: "none",
                    }}
                  >
                    {" "}
                    <span>CHOOSE JOURNAL</span>
                  </Button>
                  {this.state.openDialog && (
                    <IndividualSearchAddDialog
                      title="Journal Pick Up"
                      showDialog={this.state.openDialog}
                      handleAddJournalClose={this.handleAddJournalClose}
                      handleJournalUpdate={this.handleJournalUpdate}
                    />
                  )}
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    required
                    label="Acronym"
                    name="acronym"
                    inputProps={{
                      maxLength: 4,
                      readOnly: true,
                    }}
                    variant="filled"
                    onKeyPress={this._onInsertKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.acronym}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required
                    label="Volume"
                    name="volume"
                    inputProps={{
                      maxLength: 4,
                      readOnly: true,
                    }}
                    variant="filled"
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.volume}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required
                    label="Issue"
                    name="issue"
                    inputProps={{
                      maxLength: 7,
                      readOnly: true,
                    }}
                    variant="filled"
                    onKeyPress={this._onInsertKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.issue}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required
                    label="Supp"
                    name="suppNumber"
                    inputProps={{
                      maxLength: 100,
                      readOnly: true,
                    }}
                    variant="filled"
                    style={{ width: "22.5%" }}
                    onKeyPress={this._onInsertKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={this.state.suppNumber}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Issue Description"
                    name="issueDescription"
                    inputProps={{
                      maxLength: 100,
                      readOnly: true,
                    }}
                    variant="filled"
                    // style={{ width: "22.5%" }}
                    onKeyPress={this._onInsertKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={
                      this.state.issueDescription ||
                      journalData.issueDescription
                    }
                    onChange={this.handleChange}
                  />
                  <TextField
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    style={{ width: "22.5%" }}
                    label="JDSR Instruction"
                    name="jdsrInstruction"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.jdsrInstruction}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Part"
                    name="part"
                    inputProps={{
                      maxLength: 10,
                    }}
                    style={{ flex: 1, marginRight: "16px" }}
                    onKeyPress={this._onInsertKeyPress}
                    margin="normal"
                    value={this.state.part}
                    onChange={this.handleChange}
                  />
                  <div style={{ flexGrow: 1 }}></div>
                </div>
                <p className={classes.subHeader}>Insert Details</p>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Insert No."
                    name="insert"
                    variant={"filled"}
                    inputProps={{
                      maxLength: 50,
                      readOnly: true,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.insertNo}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required
                    label="Insert Name"
                    name="insertName"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.insertName}
                    onChange={this.handleChange}
                  />

                  <TextField
                    required
                    label="Type"
                    name="type"
                    inputProps={{
                      maxLength: 20,
                    }}
                    onKeyPress={this._onInsertKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.type}
                    onChange={this.handleChange}
                  />

                  <TextField
                    label="No Of Pages"
                    name="noOfpages"
                    inputProps={{
                      maxLength: 10,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={this.state.noOfpages}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    required
                    label="Insert Weight"
                    name="insertWeight"
                    inputProps={{
                      maxLength: 20,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.insertWeight}
                    onChange={this.handleChange}
                    // onBlur={(e) => this.onBlur(e, "insertWeight")}
                  />
                  <TextField
                    required
                    label="Quantity"
                    name="quantity"
                    inputProps={{
                      maxLength: 20,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.quantity}
                    onChange={this.handleChange}
                  />

                  <TextField
                    label="Insert Size"
                    name="insertSize"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.insertSize}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Folding Required"
                    name="foldingRequired"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onKeyPress={this._onInsertKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={this.state.foldingRequired}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Apply to"
                    name="applyTo"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.applyTo}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Recipients"
                    name="recipients"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.recipients}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Placement"
                    name="placement"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.placement}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Overs Handling"
                    name="oversHandling"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={this.state.oversHandling}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Society Insert"
                    name="societyInsert"
                    inputProps={{
                      maxLength: 100,
                    }}
                    style={{ width: "22.5%" }}
                    onKeyPress={this._onInsertKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.societyInsert}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Contact Name"
                    name="contactName"
                    inputProps={{
                      maxLength: 100,
                    }}
                    style={{ width: "22.5%" }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.contactName}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Email"
                    name="eMail"
                    inputProps={{
                      maxLength: 100,
                    }}
                    style={{ width: "22.5%" }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.eMail}
                    onChange={(e) => this.handleChange(e, emailValidator)}
                    error={this.state.errors.eMail}
                    helperText={
                      this.state.errors.eMail
                        ? resourceValidation.validEmailId
                        : ""
                    }
                  />
                  <div style={{ flexGrow: 1 }}></div>
                </div>
                <p className={classes.subHeader}>Actual Info</p>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Actual Weight (Kgs)"
                    name="actualInsertWeight"
                    inputProps={{
                      maxLength: 20,
                    }}
                    style={{ width: "22.5%" }}
                    onKeyPress={this._onWeightKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.actualInsertWeight}
                    onChange={this.handleChange}
                    onBlur={(e) => this.onBlur(e, "actualInsertWeight")}
                  />
                  <TextField
                    label="Actual Quantity"
                    name="actualQuantity"
                    inputProps={{
                      maxLength: 50,
                    }}
                    style={{ width: "22.5%" }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.actualQuantity}
                    onChange={this.handleChange}
                  />
                  <div style={{ flexGrow: 1, marginRight: "16px" }}></div>
                  <div style={{ flexGrow: 1 }}></div>
                </div>

                <Divider style={{ margin: "15px 0 5px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/pubInsertMaster")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    <Button
                      disabled={!this.state.formValid}
                      variant={"contained"}
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={this.handleSubmit}
                    >
                      SUBMIT
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(EditinsertMaster));
