import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { StateContext } from "../../../shared/globalState";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { resourceValidation } from "../../../shared/resource";
import {
  getWarehouseLocationCode,
  updateWarehouseLocation,
} from "../masterActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import { addStyles } from "../../../shared/styles";
const checkdecimal = new Validator("checkdecimalpoint");
const validateMax2 = new Validator("maxmin2length");
const checkonlyNumbers = new Validator("onlyNumbers");
const checkhousefloor = new Validator("checkwarehousefloor");
const checksection = new Validator("checkwarehousefloor");
const checkarea = new Validator("checkwarehousefloor");

class EditWarehouseLocation extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      warehouse: {
        modifiedBy: 1,
        isActive: true,
        warehouseSuffix: "",
        warehouseFloor: "",
        warehouseBay: "",
        warehouseBin: "",
        warehouseStack: "",
        warehouseSection: "",
        holdingWeight: 0.0,
        warehouseArea: "",
        warehouseSequenceNo: "",
        locationCode: "",
      },
      active: false,
      updatedValues: false,
      errors: {},
      formValid: false,
      // locationCodeValid:false,
      warehouseSuffixValid: false,
      warehouseFloorValid: false,
      warehouseBayValid: false,
      warehouseBinValid: false,
      warehouseStackValid: false,
      warehouseSectionValid: false,
      holdingWeightValid: false,
      warehouseAreaValid: false,
      warehouseSequenceNoValid: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let warehouseLocationID = this.props.match.params.warehouseLocationID;
    getWarehouseLocationCode(warehouseLocationID).then((r) => {
      dispatch({
        type: "getWarehouseLocationCode",
        newStore: r,
      });
      this.setState({ warehouse: r });
      if (r.isActive) this.setState({ active: r.isActive });
      this.validateField("warehouseSuffix", r.warehouseSuffix);
      this.validateField("warehouseFloor", r.warehouseFloor);
      this.validateField("warehouseArea", r.warehouseArea);
      this.validateField("warehouseBay", r.warehouseBay);
      this.validateField("warehouseSequenceNo", r.warehouseSequenceNo);
    });
    //this.props.handleSettingHeader("letterShop");
  }
  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onAlphaKeyPress(event) {
    const re = /[a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  onBlur = (event) => {
    const { warehouse } = this.state;
    let weightValues = event.target.value;
    weightValues = this.addZeroes(weightValues.toString(), 2);
    warehouse[event.target.name] = weightValues;
    this.setState({ warehouse });
  };

  handleChange = (e) => {
    const { warehouse } = this.state;
    this.setState({ updatedValues: true });
    if (e.target.name === "isActive") {
      warehouse[e.target.name] = e.target.checked;
    } else {
      warehouse[e.target.name] = e.target.value;
    }
    this.validateField(e.target.name, e.target.value);
    this.setState({ warehouse });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    // let locationCodeValid = this.state.locationCodeValid;
    let warehouseSuffixValid = this.state.warehouseSuffixValid;
    let warehouseFloorValid = this.state.warehouseFloorValid;
    let warehouseBayValid = this.state.warehouseBayValid;
    let warehouseBinValid = this.state.warehouseBinValid;
    let warehouseStackValid = this.state.warehouseStackValid;
    let warehouseSectionValid = this.state.warehouseSectionValid;
    let holdingWeightValid = this.state.holdingWeightValid;
    let warehouseAreaValid = this.state.warehouseAreaValid;
    let warehouseSequenceNoValid = this.state.warehouseSequenceNoValid;
    switch (fieldName) {
      // case 'Code':
      //   locationCodeValid = validateMaxLength100(value);
      //   fieldValidationErrors.Code = locationCodeValid ? '' : ' Please enter alphabet characters only';
      //   break;
      case "warehouseSuffix":
        if (value) {
          warehouseSuffixValid = validateMax2(value);
          fieldValidationErrors.Suffix = warehouseSuffixValid
            ? ""
            : resourceValidation.onlyAlphabet;
        } else {
          warehouseSuffixValid = false;
        }
        break;
      case "warehouseFloor":
        if (value) {
          warehouseFloorValid = checkhousefloor(value);
          fieldValidationErrors.Floor = warehouseFloorValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          warehouseFloorValid = false;
        }
        break;
      case "warehouseBay":
        if (value) {
          warehouseBayValid = checkarea(value);
          fieldValidationErrors.Bay = warehouseBayValid
            ? ""
            : resourceValidation.onlyAlphabet;
        } else {
          warehouseBayValid = false;
        }
        break;
      case "warehouseBin":
        warehouseBinValid = checkhousefloor(value);
        fieldValidationErrors.Bin = warehouseBinValid
          ? ""
          : resourceValidation.onlyNumbers;
        break;
      case "warehouseStack":
        warehouseStackValid = checkhousefloor(value);
        fieldValidationErrors.Stack = warehouseStackValid
          ? ""
          : resourceValidation.onlyNumbers;
        break;
      case "warehouseSection":
        if (value) {
          warehouseSectionValid = checksection(value);
          fieldValidationErrors.Section = warehouseSectionValid
            ? ""
            : resourceValidation.onlyAlphabet;
        }
        break;
      case "holdingWeight":
        if (value) {
          holdingWeightValid = checkdecimal(value);
          fieldValidationErrors.Weight = holdingWeightValid
            ? ""
            : resourceValidation.onlyDecimal;
        }
        break;
      case "warehouseArea":
        warehouseAreaValid = checkarea(value);
        fieldValidationErrors.Area = warehouseAreaValid
          ? ""
          : resourceValidation.onlyAlphabet;
        break;
      case "warehouseSequenceNo":
        if (value) {
          warehouseSequenceNoValid = checkonlyNumbers(value);
          fieldValidationErrors.SequenceNo = warehouseSequenceNoValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          warehouseSequenceNoValid = false;
          //fieldValidationErrors.SequenceNo='Please enter sequence number';
        }
        break;

      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        //locationCodeValid: locationCodeValid,
        warehouseSuffixValid: warehouseSuffixValid,
        warehouseFloorValid: warehouseFloorValid,
        warehouseBayValid: warehouseBayValid,
        warehouseAreaValid: warehouseAreaValid,
        warehouseSequenceNoValid: warehouseSequenceNoValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.state.updatedValues &&
      this.setState({
        formValid:
          this.state.warehouseSuffixValid &&
          this.state.warehouseFloorValid &&
          this.state.warehouseBayValid &&
          this.state.warehouseAreaValid &&
          this.state.warehouseSequenceNoValid,
      });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors when modal closed
    this.setState({
      warehouseSuffix: "",
      warehouseFloor: "",
      warehouseBay: "",
      warehouseBin: "",
      warehouseStack: "",
      warehouseSection: "",
      holdingWeight: "",
      warehouseArea: "",
      warehouseSequenceNo: "",
      locationCode: "",
      modifiedBy: "",
      errors: {},
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let warehouseLocationID = this.props.match.params.warehouseLocationID;
    // if (this.validateForm()) {
    const { warehouse } = this.state;
    // console.log(warehouse, "ware");
    let warehouseSuffix = warehouse.warehouseSuffix;
    let warehouseFloor = warehouse.warehouseFloor;
    let warehouseBay = warehouse.warehouseBay;
    let warehouseBin = warehouse.warehouseBin;
    let warehouseStack = warehouse.warehouseStack;
    let warehouseSection = warehouse.warehouseSection;
    let holdingWeight = warehouse.holdingWeight;
    let warehouseArea = warehouse.warehouseArea;
    let warehouseSequenceNo = warehouse.warehouseSequenceNo;

    let locationCode =
      warehouseSuffix +
      "-" +
      warehouseFloor +
      "-" +
      warehouseArea +
      "-" +
      warehouseBay +
      (warehouseBin ? "-" + warehouseBin : "") +
      (warehouseStack ? "-" + warehouseStack : "") +
      (warehouseSection ? "-" + warehouseSection : "");
    let modifiedBy = window.localStorage.loggedInId;
    let isActive = warehouse.isActive;

    // console.log(locationCode, "locationCode");
    let reqBody = {
      locationCode,
      warehouseSuffix,
      warehouseFloor,
      warehouseBay,
      warehouseBin,
      warehouseStack,
      warehouseSection,
      holdingWeight,
      isActive,
      warehouseArea,
      warehouseSequenceNo,
      modifiedBy,
    };
    updateWarehouseLocation(warehouseLocationID, reqBody, this.props.history);
    // }
  };
  render() {
    let { classes } = this.props;
    let { warehouse, active } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Warehouse Location</div>
          {/* <Divider /> */}
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                {/* <p className={classes.subHeader}>Enter User details</p> */}
                <div>
                  {" "}
                  <TextField
                    required
                    disabled
                    label="Location Code"
                    name="locationCode"
                    margin="normal"
                    variant="filled"
                    autoComplete="off"
                    className={classes.textField}
                    value={warehouse.locationCode}
                    onChange={this.handleChange}
                    error={this.state.errors.locationCode}
                    helperText={
                      this.state.errors.locationCode
                        ? this.state.errors.locationCode
                        : ""
                    }
                  />
                  <TextField
                    inputProps={{
                      maxLength: 2,
                    }}
                    required
                    autoComplete="off"
                    label="Warehouse Suffix"
                    name="warehouseSuffix"
                    margin="normal"
                    className={classes.textField}
                    value={warehouse.warehouseSuffix}
                    onChange={this.handleChange}
                    error={this.state.errors.warehouseSuffix}
                    helperText={
                      this.state.errors.warehouseSuffix
                        ? this.state.errors.warehouseSuffix
                        : ""
                    }
                    onKeyPress={this._onAlphaKeyPress}
                  />
                  <TextField
                    inputProps={{
                      maxLength: 4,
                    }}
                    required
                    autoComplete="off"
                    label="Warehouse Floor"
                    name="warehouseFloor"
                    margin="normal"
                    className={classes.textField}
                    value={warehouse.warehouseFloor}
                    onChange={this.handleChange}
                    error={this.state.errors.warehouseFloor}
                    helperText={
                      this.state.errors.warehouseFloor
                        ? this.state.errors.warehouseFloor
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    inputProps={{
                      maxLength: 4,
                    }}
                    required
                    autoComplete="off"
                    label="Warehouse Area"
                    name="warehouseArea"
                    margin="normal"
                    className={classes.textField}
                    value={warehouse.warehouseArea}
                    onChange={this.handleChange}
                    error={this.state.errors.warehouseArea}
                    helperText={
                      this.state.errors.warehouseArea
                        ? this.state.errors.warehouseArea
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                </div>
                <div>
                  <TextField
                    inputProps={{
                      maxLength: 4,
                    }}
                    required
                    autoComplete="off"
                    label="Warehouse Bay"
                    name="warehouseBay"
                    margin="normal"
                    className={classes.textField}
                    value={warehouse.warehouseBay}
                    onChange={this.handleChange}
                    error={this.state.errors.warehouseBay}
                    helperText={
                      this.state.errors.warehouseBay
                        ? this.state.errors.warehouseBay
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />

                  <TextField
                    inputProps={{
                      maxLength: 4,
                    }}
                    autoComplete="off"
                    label="Warehouse Bin"
                    name="warehouseBin"
                    margin="normal"
                    className={classes.textField}
                    value={warehouse.warehouseBin}
                    onChange={this.handleChange}
                    error={this.state.errors.warehouseBin}
                    helperText={
                      this.state.errors.warehouseBin
                        ? this.state.errors.warehouseBin
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    inputProps={{
                      maxLength: 4,
                    }}
                    autoComplete="off"
                    label="Warehouse Stack"
                    name="warehouseStack"
                    margin="normal"
                    className={classes.textField}
                    value={warehouse.warehouseStack}
                    onChange={this.handleChange}
                    error={this.state.errors.warehouseStack}
                    helperText={
                      this.state.errors.warehouseStack
                        ? this.state.errors.warehouseStack
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    inputProps={{
                      maxLength: 4,
                    }}
                    autoComplete="off"
                    label="Warehouse Section"
                    name="warehouseSection"
                    margin="normal"
                    className={classes.textField}
                    value={warehouse.warehouseSection}
                    onChange={this.handleChange}
                    error={this.state.errors.warehouseSection}
                    helperText={
                      this.state.errors.warehouseSection
                        ? this.state.errors.warehouseSection
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                </div>
                <div>
                  <TextField
                    inputProps={{
                      maxLength: 20,
                    }}
                    autoComplete="off"
                    label="Holding Weight"
                    name="holdingWeight"
                    margin="normal"
                    className={classes.textField}
                    value={warehouse.holdingWeight}
                    onChange={this.handleChange}
                    error={this.state.errors.holdingWeight}
                    helperText={
                      this.state.errors.holdingWeight
                        ? this.state.errors.holdingWeight
                        : ""
                    }
                    onKeyPress={this._onDecimalKeyPress}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Kg</InputAdornment>
                      ),
                    }}
                    onBlur={this.onBlur}
                  />

                  <TextField
                    inputProps={{
                      maxLength: 2,
                    }}
                    required
                    autoComplete="off"
                    label="Warehouse Sequence No"
                    name="warehouseSequenceNo"
                    margin="normal"
                    className={classes.textField}
                    value={warehouse.warehouseSequenceNo}
                    onChange={this.handleChange}
                    error={this.state.errors.warehouseSequenceNo}
                    helperText={
                      this.state.errors.warehouseSequenceNo
                        ? this.state.errors.warehouseSequenceNo
                        : ""
                    }
                    onKeyPress={this._onNumberKeyPress}
                  />
                </div>
                {active ? (
                  <React.Fragment />
                ) : (
                  <FormControlLabel
                    name="isActive"
                    // style={{ marginTop: "25px", marginLeft: "7px" }}
                    className={classes.checkBox}
                    control={
                      <Checkbox
                        color="primary"
                        checked={warehouse.isActive}
                        onChange={this.handleChange}
                        value={warehouse.isActive}
                      />
                    }
                    label="Active"
                  />
                )}
                <Divider style={{ marginTop: "25px" }} />
                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/warehouseLocation")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditWarehouseLocation));
