import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import clone from "clone";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { Checkbox } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { getPlacedOrder } from "../../generic/orderPlacement/actions";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
//import ManageCountry from "./countryManage";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AdvanceSearch from "./search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { StateContext } from "../../../shared/globalState";
import Typography from "@material-ui/core/Typography";
//import CreateCountry from "./countryAdd";
import FormGroup from "@material-ui/core/FormGroup";
import Validator from "../../../shared/validator";
//import DeleteOrder from "./delete";
import {
  desc,
  stableSort,
  getSorting,
  DialogActions,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import toastr from "toastr";
import moment from "moment";
import "../../app/App.css";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { ExportCSV } from "../../../shared/ExportCSV";
import {
  SearchIcon,
  DownloadIcon,
  ViewColumnIcon,
  EditIcon,
  ViewIcon,
  AddIcon,
} from "../../../shared/customIcons";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getStockInAll, advanceSearchForStockIn } from "../stockaction";
const validateMaxlength2 = new Validator("maxlength2");

const columns = [
  {
    id: 16,
    name: "Created By",
  },

  {
    id: 17,
    name: "Created Date",
  },
];

// const DialogActions = withStyles(theme => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1)
//   }
// }))(MuiDialogActions);
let initailData = [];
function createData(
  blStockInID,
  customerID,
  journalID,
  acronym,
  volume,
  issue,
  supp,
  issueDescription,
  location,
  height,
  pubYear,
  warehouseLocationID,
  warehouseLocationName,
  receivedQty,
  receivedDate,
  usedQty,
  balanceQty,
  varience,
  remarks,
  stockType,
  strockTransferID,
  reason,
  isActive,
  createdBy,
  createdDate,

) {
  return {
    blStockInID,
    customerID,
    journalID,
    acronym,
    volume,
    issue,
    supp,
    issueDescription,
    location,
    height,
    pubYear,
    warehouseLocationID,
    warehouseLocationName,
    receivedQty,
    receivedDate,
    usedQty,
    balanceQty,
    varience,
    remarks,
    stockType,
    strockTransferID,
    reason,
    isActive,
    createdBy,
    createdDate,

  };
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ borderTop: "1px solid #eaeaea", background: "white" }}
          className="table-cell"
        >
          {/* <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "Select All Countrys" }}
              />
            }
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.code}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
            //);
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    countryCode: "",
    countryName: "",
  });
  const [formValid, setIsformValid] = useState(false);

  const [countrySearchValue, setValues] = useState({
    countryCode: "",
    countryName: "",
  });
  var publisherBackLabelManage = props.roles.filter((r) => {
    return r.superMenuName === "Publisher Back Label";
  });
  let blsiArr =
    publisherBackLabelManage.length > 0 &&
    publisherBackLabelManage.filter((u) => u.menuName === "Stock In");
  var acnObj = blsiArr[0];

  const classes = useToolbarStyles();
  const {
    numSelected,
    selectedLocalPostage,
    selectedLocalPostageID,
    handleDeletePopupOpen,
    deletePopupOpen,
    searchPopupOpen,
    handleDeletePopupClose,
    handleDeleteCountry,
    handleViewColumns,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchCountry,
    searchClicked,
    selected,
    localPostage,
    stockInList,
    editable,
    removable,
    selectedRows,
  } = props;

  let excelFileData = clone(stockInList);

  excelFileData &&
    excelFileData.length > 0 &&
    excelFileData.map((d) => {
      d["Acronym"] = d["acronym"];
      d["Volume"] = d["volume"];
      d["Issue"] = d["issue"];
      d["Supplement"] = d["supp"];
      d["Issue Description"] = d["issueDescription"];
      d["Location"] = d["warehouseLocationName"];
      d["Received Qty"] = d["receivedQty"];
      d["Received Date"] = d["receivedDate"] ? moment(d["receivedDate"]).format("DD/MM/YYYY") : "";
      // d["Used Qty"] = d["usedQty"];
      // d["Balance Qty"] = d["balanceQty"];
      d["Stock Type"] = d["stockType"];
      d["Remarks"] = d["remarks"];
      d["Created By"] = d["createdBy"];
      d["Created Date"] = moment(d["createdDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      // d["remarks"]=d["remarks"];
      // d["varience"]=d["varience"];

      // d["isActive"]=d["isActive"];
      delete d["location"];

      delete d["acronym"];
      delete d["blStockInID"];
      delete d["createdBy"];
      delete d["createdDate"];
      delete d["customerID"];
      delete d["height"];
      delete d["isActive"];
      delete d["issue"];
      delete d["issueDescription"];
      delete d["journalID"];
      delete d["pubYear"];
      delete d["reason"];
      delete d["remarks"];
      delete d["receivedDate"];
      delete d["receivedQty"];
      delete d["remarks"];
      delete d["stockType"];
      delete d["strockTransferID"];
      delete d["supp"];
      delete d["usedQty"];
      delete d["balanceQty"];
      delete d["varience"];
      delete d["volume"];
      delete d["warehouseLocationID"];
      delete d["warehouseLocationName"];
    });

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteCountry = () => {
    handleDeleteCountry();
  };
  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = countrySearchValue;
    searchValues.countryCode = "";
    searchValues.countryName = "";
    searchValues = countrySearchValue;
    setIsformValid(false);
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_backLabelStockIn/${selected[0]}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {/* {acnObj && acnObj.editAction && false && (
                  <Tooltip title="Edit">
                    { (
                      <Link to={`/edit_backLabelStockIn/${selected[0]}`}>
                        <IconButton
                          aria-label="edit"
                          className={classes.iconHover}
                        >
                          <EditIcon />
                        </IconButton>
                      </Link>
                    )}
                  </Tooltip>
                )} */}
              </div>
            )}
            {/* <Tooltip title="Delete"> */}
            {/* {removable && (
              <DeleteOrder
                mode="delete"
                selected={selected}
                selectedRows={selectedRows}
                onRequestSucceed={props.onRequestSucceed}
                // count={props.selected.length}
                // isActive={props.isActive}
              />
            )} */}
            {/* </Tooltip> */}
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {props.searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={(e) => props.clearSearch()}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  acnObj &&
                  acnObj.advanceSearchAction && (
                    <AdvanceSearch
                      onSearchClicked={props.onSearchClicked}
                      onRequestSucceed={props.onRequestSucceed}
                    />
                  )
                )}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <Link to="/add_backLabelStockIn">
                    {" "}
                    <IconButton aria-label="add" className={classes.iconHover}>
                      <AddIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              )}
              {acnObj && acnObj.exportAction && (
                <ExportCSV csvData={excelFileData} fileName={"Stock In"} />
              )}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                handleViewColumns={handleViewColumns}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 10.2;
  const left = 77;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    handleViewColumns,
    open,
    ...other
  } = props;
  const [column, setColumnIds] = React.useState(valueProp);
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  }, [valueProp, open]);

  const handleClose = () => {
    onClose(setColumnIds);
  };

  const handleChange = (event) => {
    setColumnIds(event.target.value);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => handleViewColumns(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class BackLabelStockIn extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "createdDate",
    selected: [],
    selectedRows: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    editable: true,
    removable: true,
    stockInList: [],
    loading: false,
    selectedLocalPostage: {},
    selectedLocalPostageID: 1,
    selectedShowColumns: [],
    searchPopupOpen: false,
    countrySearchValues: {},
    searchClicked: false,
    headCells: [
      {
        code: "acronym",
        id: 1,
        show: false,
        numeric: false,
        disablePadding: true,
        label: "Acronym",
        show: true,
      },
      {
        code: "volume",
        id: 2,
        show: false,
        numeric: false,
        disablePadding: true,
        label: "Volume",
        show: true,
      },
      {
        code: "issue",
        id: 3,
        numeric: false,
        disablePadding: false,
        label: "Issue",
        show: true,
      },
      {
        id: 4,
        code: "supp",
        numeric: false,
        disablePadding: false,
        label: "Supplement",
        show: true,
      },
      {
        id: 5,
        code: "issueDescription",
        numeric: false,
        disablePadding: false,
        label: "Issue Description",
        show: true,
      },
      {
        id: 6,
        code: "year",
        numeric: false,
        disablePadding: false,
        label: "Year",
        show: false,
      },
      {
        id: 7,
        code: "location",
        numeric: false,
        disablePadding: false,
        label: "Location",
        show: true,
      },
      {
        id: 8,
        code: "receivedQty",
        numeric: false,
        disablePadding: false,
        label: "Received Qty",
        show: true,
      },
      {
        id: 9,
        code: "receivedDate",
        numeric: false,
        disablePadding: false,
        label: "Received Date",
        show: true,
      },
      {
        id: 10,
        code: "usedQty",
        numeric: false,
        disablePadding: false,
        label: "Used Qty",
        show: false,
      },
      {
        id: 11,
        code: "balanceQty",
        numeric: false,
        disablePadding: false,
        label: "Balance Qty",
        show: false,
      },
      {
        id: 12,
        code: "stockoutQty",
        numeric: false,
        disablePadding: false,
        label: "StockOut Qty",
        show: false,
      },

      {
        id: 13,
        code: "stockOutDate",
        numeric: false,
        disablePadding: false,
        label: "StockOut Date",
        show: false,
      },

      {
        id: 14,
        code: "stockType",
        numeric: false,
        disablePadding: false,
        label: "Stock Type",
        show: true,
      },

      {
        id: 15,
        code: "remarks",
        numeric: false,
        disablePadding: false,
        label: "Remarks",
        show: true,
      },
      {
        id: 16,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
      {
        id: 17,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
    ],
  };
  handleViewColumns = (e, id) => {
    let newheadCells = [...this.state.headCells];

    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  componentDidMount() {
    this.props.handleSettingHeader("Back Label Stock In");
    this.props.setSelectedList("publisherBackLabelOpen", "backLabelStockIn");

    const [{ store }, dispatch] = this.context;

    this.setState({ loading: true });
    getStockInAll().then((r) => {
      this.getAllPlacedOrder(r && r.data);
    });
  }

  onRequestSucceed = (deleted) => {
    const [{ store }, dispatch] = this.context;
    getPlacedOrder().then((r) => {
      dispatch({
        type: "getPlacedOrderList",
        newStore: r.data,
      });
      if (deleted) this.setState({ searchClicked: false, selected: [] });
      this.getAllPlacedOrder(r.data);
    });
  };
  getAllPlacedOrder = (lpList) => {
    let list = lpList;
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        newRows.push(
          createData(
            d.blStockInID,
            d.customerID,
            d.journalID,
            d.acronym,
            d.volume,
            d.issue,
            d.supp,
            d.issueDescription,
            d.warehouseLocationName,
            d.height,
            d.pubYear,
            d.warehouseLocationID,
            d.warehouseLocationName,
            d.receivedQty,
            d.receivedDate,
            d.usedQty,
            d.balanceQty,
            d.varience,
            d.remarks,
            d.stockType,
            d.strockTransferID,
            d.reason,
            d.isActive,
            d.createdBy,
            d.createdDate,

          )
        );
      });
    this.setState({ rows: newRows, loading: false });
    initailData = newRows;
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { rows } = this.state;
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, data, id) {
    let newSelected = [];
    let selected = [];
    let newSelectedRows = [];

    if (event.target.checked) {
      newSelected = newSelected.concat(selected, id);
      newSelectedRows = newSelected.concat(id);
      this.setState({
        selected: newSelected,
        selectedemloID: data,
        selectedemloIDId: id,
        selectedRows: newSelectedRows,
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedemloID: data,
        selectedemloIDId: id,
        selectedRows: newSelectedRows,
      });
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleDeleteCountry = () => {
    let { selected } = this.state;
    const [{ store }, dispatch] = this.context;
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");
  };
  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchCountry = (handleSearchCountry) => {
    const [{ store }, dispatch] = this.context;

    let countryValues = handleSearchCountry;
    let countryCode = countryValues.countryCode;
    let countryName = countryValues.countryName;
    let reqBody = { countryCode, countryName };

    this.setState({ searchPopupOpen: false, searchClicked: true });
  };

  onSearchClicked = (reqBody) => {
    this.setState({ rows: [], loading: true });
    advanceSearchForStockIn(reqBody).then((r) => {
      this.getAllPlacedOrder(r && r.data);
    });
    this.setState({ searchClicked: true });
  };
  clearSearch = () => {
    this.setState({ rows: [], loading: true });
    const [{ store }, dispatch] = this.context;
    getStockInAll().then((r) => {
      this.getAllPlacedOrder(r && r.data);
    });
    this.setState({ searchClicked: false });
  };

  render() {
    const [{ store }, dispatch] = this.context;
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      rows,
      selectedLocalPostage,
      selectedLocalPostageID,
      headCells,
      selectedRows,
    } = this.state;

    const isSelected = (id) => selected.indexOf(id) !== -1;
    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedLocalPostage={selectedLocalPostage}
            selectedLocalPostageID={selectedLocalPostageID}
            history={this.props.history}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchCountry={this.handleSearchCountry}
            countrySearchValue={this.state.countrySearchValue}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteCountry={this.handleDeleteCountry}
            handleViewColumns={this.handleViewColumns}
            headCells={headCells}
            stockInList={rows}
            selected={selected}
            onSearchClicked={this.onSearchClicked}
            editable={this.state.editable}
            removable={this.state.removable}
            selectedRows={selectedRows}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{
                  borderLeft: "1px solid  rgba(224, 224, 224, 1)",
                  whiteSpace: "nowrap",
                }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={rows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {rows.length > 0 ? (
                    stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.blStockInID);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive == true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return (
                          <TableRow
                            //  hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    onClick={(event) =>
                                      this.handleClick(
                                        event,
                                        row,
                                        row.blStockInID
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.acronym && row.acronym.length > 16 ? (
                                  <Tooltip title={row.acronym} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.acronym}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.acronym}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.volume && row.volume.length > 16 ? (
                                    <Tooltip
                                      title={row.volume}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.volume}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.volume}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.issue && row.issue.length > 16 ? (
                                    <Tooltip title={row.issue} aria-label="add">
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.issue}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.issue}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.supp && row.supp.length > 16 ? (
                                    <Tooltip title={row.supp} aria-label="add">
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.supp}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.supp}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.issueDescription &&
                                    row.issueDescription.length > 16 ? (
                                      <Tooltip
                                        title={row.issueDescription}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.issueDescription}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.issueDescription}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.pubYear}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.email} */}
                                {row.warehouseLocationName &&
                                  row.warehouseLocationName.length > 16 ? (
                                    <Tooltip
                                      title={row.warehouseLocationName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.warehouseLocationName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.warehouseLocationName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.email} */}
                                {row.receivedQty &&
                                  row.receivedQty.length > 16 ? (
                                    <Tooltip
                                      title={row.receivedQty}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.receivedQty}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.receivedQty}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.email} */}
                                {row.receivedDate &&
                                  row.receivedDate.length > 16 ? (
                                    <Tooltip
                                      title={row.receivedDate ? moment(row.receivedDate).format("DD/MM/YYYY") : ""}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.receivedDate ? moment(row.receivedDate).format("DD/MM/YYYY") : ""}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.receivedDate ? moment(row.receivedDate).format("DD/MM/YYYY") : ""}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.email} */}
                                {row.usedQty && row.usedQty.length > 16 ? (
                                  <Tooltip title={row.usedQty} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.usedQty}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.usedQty}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.email} */}
                                {row.balanceQty && row.balanceQty.length > 16 ? (
                                  <Tooltip title={row.balanceQty} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.balanceQty}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.balanceQty}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.email} */}
                                {row.stockOutQty &&
                                  row.stockOutQty.length > 16 ? (
                                    <Tooltip
                                      title={row.stockOutQty}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.stockOutQty}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.stockOutQty}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.stockOutDate &&
                                  row.stockOutDate.length > 10 ? (
                                    <Tooltip
                                      title={moment(row.stockOutDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {moment(row.stockOutDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.stockOutDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.email} */}
                                {row.stockType && row.stockType.length > 16 ? (
                                  <Tooltip
                                    title={row.stockType}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.stockType}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.stockType}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.email} */}
                                {row.reason && row.reason.length > 16 ? (
                                  <Tooltip title={row.reason} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.reason}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.remarks}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[15].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.createdBy && row.createdBy.length > 16 ? (
                                  <Tooltip
                                    title={row.createdBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[16].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={10} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(BackLabelStockIn);
