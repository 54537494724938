import React, { Component } from "react";

class PublisherEMLOSummery extends Component {
    state = {};
    componentDidMount() {
        this.props.handleSettingHeader("EMLO Summery");
        this.props.setSelectedList("publisherReportsOpen", "PublisherEMLOSummery");
    }
    render() {
        // alert("collectionnote");
        //const url = "http://172.22.43.196/report/reportviewer?Reportname=ReorderLevel";
        return (
            <div>
                <iframe
                    style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
                    src={`${window.localStorage.ReportURLByID}` + "EMLO_SUMMERY"}
                />
            </div>
        );
    }
}

export default PublisherEMLOSummery;
