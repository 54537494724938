import React, { Component } from "react";

class BLDetailedCostingReport extends Component {
    state = {};
    componentDidMount() {
        this.props.handleSettingHeader("Back Label Detail Costing");
        this.props.setSelectedList("publisherReportsOpen", "BLDetailedCostingReport");
    }
    render() {
        return (
            <div>
                <iframe
                    style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
                    src={`${window.localStorage.ReportURLByID}` + "BLDetailedCostingReport"}
                />
            </div>
        );
    }
}

export default BLDetailedCostingReport;
