import React, { Component } from "react";
import { Autocomplete } from "@material-ui/lab";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import ReportViewer from "../app/ReportViewer";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  getStockInByCustIdList,
  getCustomerFromItemMaster,
} from "../generic/genericActions";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  comboBox: {
    //marginTop: theme.spacing(1),
    //marginRight: theme.spacing(1),
    margin: "normal",
    width: "100%",
    fontSize: "11px !important",
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
    display: "flex",

    flex: 1,
    alignItems: "left",
    overflow: "hidden",
  },
  textField1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
    fontSize: "11px !important",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
});
class StockBalance extends Component {
  state = {
    showPopup: false,
    fromDate: null,
    toDate: null,
    stockIn: {
      stockID: "",
      stock: "",
      customerID: "",
      customer: "",
    },
  };
  componentDidMount() {
    this.props.handleSettingHeader("Stock Balance Report");
    this.props.setSelectedList("masterDataOpen", "stockBalanceReport");
    getCustomerFromItemMaster().then((r) => {
      this.getAllCustomers(r.data);
    });
  }
  getAllCustomers = (list) => {
    let customerList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        customerList.findIndex((p) => p.id === c.id) > -1
          ? customerList
          : customerList.push(c)
      );

    this.setState({
      customerList:
        customerList &&
        customerList.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };
  handleDateChange = (date) => {
    this.setState({ ["fromDate"]: date });
  };
  handleDateChange1 = (date) => {
    this.setState({ ["toDate"]: date });
  };
  handleStockChange = (event, values) => {
    const { stockIn } = this.state;
    let stockID = values && values.stockID;
    stockIn["stock"] = values;
    stockIn["stockID"] = stockID;
    //this.validateField("stockID", stockID);
    this.setState({ stockIn });
  };
  handleCustomerChange = (event, values) => {
    const { stockIn, ItemMasterList } = this.state;

    let customerID = values && values.id;
    stockIn["customer"] = values;
    stockIn["customerID"] = customerID;
    //this.validateField("customerID", customerID);
    getStockInByCustIdList(customerID).then((r) => {
      this.setState({
        stockList:
          r.data &&
          r.data.sort((a, b) =>
            a.stockCode.toLowerCase() > b.stockCode.toLowerCase() ? 1 : -1
          ),
      });
    });
    stockIn["stock"] = null;
    stockIn["stockID"] = 0;
    //this.validateField("stockID", null);
    this.setState({ stockIn, ItemMasterList });
  };
  handleSubmit = () => {
    this.setState({ showPopup: true });
  };
  handleHidePopup = () => {
    this.setState({
      showPopup: false,
    });
  };
  render() {
    let { classes } = this.props;
    return (
      <Paper>
        <div style={{
          // border: "1px solid grey",
          margin: "0",
          padding: "10px 15px",
          // background: "#ECE9D8",
          display: "flex"
        }}>
          <div style={{width: "75%"}}>
            <div style={{ display: "flex", width: "100%" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  margin="normal"
                  disableToolbar
                  variant="inline"
                  id="date-picker-dialog"
                  label="From Date"
                  format="dd/MM/yyyy"
                  style={{ flex: 1, marginTop: 0, marginRight: "15px" }}
                  value={this.state.fromDate}
                  onChange={this.handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />

                <KeyboardDatePicker
                  autoOk
                  disabled={!this.state.fromDate}
                  disableToolbar
                  margin="normal"
                  disableToolbar
                  variant="inline"
                  id="date-picker-dialog"
                  label="To Date"
                  minDate={this.state.fromDate}
                  format="dd/MM/yyyy"
                  style={{ flex: 1, marginTop: 0 }}
                  value={this.state.toDate}
                  onChange={this.handleDateChange1}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <Autocomplete
                autoComplete
                className={classes.comboBox}
                id="customerID"
                includeInputInList
                options={this.state.customerList}
                style={{ marginTop: 0, marginRight: "15px" }}
                Value={this.state.customer}
                onChange={this.handleCustomerChange}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer"
                    name="customerID"
                    fullWidth
                  />
                )}
              />
              <Autocomplete
                autoComplete
                className={classes.comboBox}
                id="stock"
                style={{ marginTop: 0 }}
                includeInputInList
                options={this.state.stockList}
                value={this.state.stockIn.stock}
                onChange={this.handleStockChange}
                getOptionLabel={(option) => option.stockCode}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Stock"
                    name="stock"
                    fullWidth
                  />
                )}
              />
            </div>
          </div>
          <div style={{
                width: "23%",
                marginLeft: "2%",
                padding: "15px",
                borderLeft: "1px solid #ccc"
          }}>
            <Button
              disabled={
                !(this.state.fromDate && 
                this.state.toDate &&  
                this.state.stockIn.customerID)
                // this.state.stockIn.stockID)
              }
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={this.handleSubmit}
            >
              View Report
            </Button>
          </div>
        </div>
        <div>
          {this.state.showPopup && (
            <iframe
              style={{ width: "calc(100vw - 325px)", height: "calc(100vh - 281px)", borderWidth: "1px 0 0" }}
              src={`${window.localStorage.ReportURLByID}`+"StockBalance&IsDirectPrint=False&ReportParam=FromDate&Param1="+`${moment(this.state.fromDate).format("MM/DD/YYYY")}`+"&ReportParam=ToDate&Param2="+`${moment(this.state.toDate).format("MM/DD/YYYY")}`+"&ReportParam=CustomerName&Param3="+`${this.state.stockIn.customerID}`+"&ReportParam=StockID&Param4="+`${this.state.stockIn.stockID}`}
            />
          )}
        </div>
      </Paper>
    );
  }
}

export default withRouter(withStyles(styles)(StockBalance));
