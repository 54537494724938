import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Tabs, Tab } from "@material-ui/core";
import TabContainer from "./workOrderTabContainer";
import WorkOrder from "./workOrder";
import JobGroup from "./jobGroup";
import Process from "./process";
import Stock from "./stock";
import Distribution from "./distribution";
import Schedule from "./schedule";
import {
  getCustomerList,
  getServiceList,
  getStatusList,
} from "../../masters/masterActions";

import { getChooseWorkOrderList, GetJobGrpDetails } from "./action";
import { getUserList } from "../../userAccess/userAccessAction";
import * as QueryString from "query-string";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  colorPrimary: {
    color: "red",
  },
});

const WorkOrderTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontSize: "18px",
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    fontFamily: ["Roboto"].join(","),
    "&:hover": {
      opacity: 1,
    },
    "&$selected": {
      color: "#000",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      //   color: "#40a9ff"
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
let workOrderMode = "";

class WorkOrderTabs extends React.Component {
  state = {
    statusName: "",
    activeTab: 1,
    workOrderID: 0,
    woSubmit: false,
    tabs: [
      {
        id: 1,
        label: "Work Order",
        component: <WorkOrder />,
        disabled: false,
      },
      {
        id: 2,
        label: "Job Group",
        component: <JobGroup />,
        disabled: true,
      },
      {
        id: 3,
        label: "Process",
        component: <Process />,
        disabled: true,
      },
      {
        id: 4,
        label: "Stock",
        component: <Stock />,
        disabled: true,
      },
      {
        id: 5,
        label: "Schedule",
        // component: <Schedule />
        disabled: true,
      },
      {
        id: 6,
        label: "Distribution",
        // component: <Schedule />
        disabled: true,
      },
    ],
  };

  componentDidMount() {
    this.props.handleSettingHeader("Work Order");
    this.props.setSelectedList("genericSystem", "createWorkOrder");
    workOrderMode =
      this.props.location.state && this.props.location.state.workOrderMode;
    let jobGroupID =
      this.props.location.search && this.props.location.search.split("=")[2];
    let activeTab = this.props.activeTab;
    GetJobGrpDetails(jobGroupID).then((r) => {
      this.setState({
        statusName: r.statusName,
      });
    });
    let { tabs } = this.state;
    if (activeTab && activeTab !== "1") {
      tabs.forEach(function (item, index) {
        if (index === 0) {
          item.disabled = true;
        } else {
          item.disabled = false;
        }
      });
    }

    if (this.props.activeTab && this.props.activeTab !== "") {
      this.setState({
        activeTab: parseInt(this.props.activeTab),
        tabs,
      });
    }
  }

  handleWOSubmit = (value) => {
    let { tabs } = this.state;
    tabs.forEach(function (item, index) {
      if (index === 0) {
        item.disabled = true;
      } else {
        item.disabled = false;
      }
    });
    this.setState({ woSubmit: value, tabs });
  };

  GetTabSelection = (id) => {
    const params = QueryString.parse(this.props.location.search);

    switch (id) {
      case 1:
        return (
          <WorkOrder
            workOrderMode={workOrderMode}
            // customerList={this.state.customerList}
            // plannerList={this.state.plannerList}
            // statusList={this.state.statusList}
            // serviceList={this.state.serviceList}
            // chooseWOList={this.state.chooseWOList}
            history={this.props.history}
            handleTabChange={this.handleTabChange}
            handleWOSubmit={this.handleWOSubmit}
            workOrderID={params.workOrderID}
            handleDrawerClose={this.props.handleDrawerClose}
            status={this.state.statusName}
          />
        );
      case 2:
        return (
          <JobGroup
            workOrderMode={workOrderMode}
            workOrderID={params.workOrderID}
            jobGroupID={params.jobGroupID}
            handleDrawerClose={this.props.handleDrawerClose}
            status={this.state.statusName}
          />
        );
      case 3:
        return (
          <Process
            workOrderMode={workOrderMode}
            workOrderID={params.workOrderID}
            handleDrawerClose={this.props.handleDrawerClose}
            jobGroupID={params.jobGroupID}
            status={this.state.statusName}
            clearOpenList={this.props.clearOpenList}
          />
        );
      case 4:
        return (
          <Stock
            workOrderMode={workOrderMode}
            workOrderID={params.workOrderID}
            jobGroupID={params.jobGroupID}
            handleDrawerClose={this.props.handleDrawerClose}
            status={this.state.statusName}
          />
        );
      case 5:
        return (
          <Schedule
            workOrderMode={workOrderMode}
            workOrderID={params.workOrderID}
            jobGroupID={params.jobGroupID}
            handleDrawerClose={this.props.handleDrawerClose}
            setSelectedList={this.props.setSelectedList}
            handleSettingHeader={this.props.handleSettingHeader}
            status={this.state.statusName}
          />
        );
      case 6:
        return (
          <Distribution
            workOrderMode={workOrderMode}
            workOrderID={params.workOrderID}
            jobGroupID={params.jobGroupID}
            handleDrawerClose={this.props.handleDrawerClose}
            status={this.state.statusName}
          />
        );
      default:
        return null;
    }
  };

  pushHistory = (id) => {
    const params = QueryString.parse(this.props.location.search);
    switch (id) {
      case 1:
        this.props.history.push({
          pathname: "/workOrder",
          search: "?workOrderID=" + params.workOrderID,
          state: { workOrderMode: workOrderMode },
          status: this.state.statusName,
        });
        break;
      case 2:
        this.props.history.push({
          pathname: "/jobGroup",
          search:
            "?workOrderID=" +
            params.workOrderID +
            "&jobGroupID=" +
            params.jobGroupID,
          state: { workOrderMode: workOrderMode },
          status: this.state.statusName,
        });
        break;
      case 3:
        this.props.history.push({
          pathname: "/process",
          search:
            "?workOrderID=" +
            params.workOrderID +
            "&jobGroupID=" +
            params.jobGroupID,
          state: { workOrderMode: workOrderMode },
          status: this.state.statusName,
        });
        break;
      case 4:
        this.props.history.push({
          pathname: "/stock",
          search:
            "?workOrderID=" +
            params.workOrderID +
            "&jobGroupID=" +
            params.jobGroupID,
          state: { workOrderMode: workOrderMode },
          status: this.state.statusName,
        });
        break;
      case 5:
        this.props.history.push({
          pathname: "/schedule",
          search:
            "?workOrderID=" +
            params.workOrderID +
            "&jobGroupID=" +
            params.jobGroupID,
          state: { workOrderMode: workOrderMode },
          status: this.state.statusName,
        });
        break;
      case 6:
        this.props.history.push({
          pathname: "/distribution",
          search:
            "?workOrderID=" +
            params.workOrderID +
            "&jobGroupID=" +
            params.jobGroupID,
          state: { workOrderMode: workOrderMode },
          status: this.state.statusName,
        });
        break;
      default:
        return;
    }
  };

  handleTabChange = (event, activeTab) => {
    this.setState((state) => ({ activeTab }));
    this.pushHistory(parseInt(activeTab));
  };

  render() {
    const { classes } = this.props;
    const { activeTab, tabs } = this.state;
    return (
      <Paper>
        <div style={{ flexGrow: "1", padding: ".5em 2em 1em 2em" }}>
          <div style={{ background: "#fff" }}>
            <WorkOrderTabsStyle
              value={activeTab}
              onChange={this.handleTabChange}
            >
              {tabs.map((tab, index) => {
                if (workOrderMode !== "view") {
                  if (index !== 5) {
                    return (
                      <WorkOrderTab
                        key={tab.id}
                        label={tab.label}
                        value={tab.id}
                        disabled={tab.disabled}
                      />
                    );
                  } else if (this.state.statusName === "Completed") {
                    if (index === 0 || index == 1)
                      return (
                        <WorkOrderTab
                          key={tab.id}
                          label={tab.label}
                          value={tab.id}
                          disabled={tab.disabled}
                        />
                      );
                  }
                } else {
                  return (
                    <WorkOrderTab
                      key={tab.id}
                      label={tab.label}
                      value={tab.id}
                      disabled={tab.disabled}
                    />
                  );
                }
              })}
            </WorkOrderTabsStyle>
          </div>
          {tabs.map((tab) =>
            activeTab === tab.id ? (
              <TabContainer className={classes.wrapper} key={tab.id}>
                {" "}
                {this.GetTabSelection(tab.id)}
              </TabContainer>
            ) : null
          )}
        </div>
      </Paper>
    );
  }
}

WorkOrderTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  // tabs: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     label: PropTypes.string.isRequired,
  //     id: PropTypes.number.isRequired,
  //     component: PropTypes.object.isRequired
  //   }).isRequired
  // ).isRequired
};

const WorkOrderTabsStyle = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);

export default withRouter(withStyles(styles)(WorkOrderTabs));
