import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import { PrintIcon } from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import { getStockOutData } from "../genericActions";
import { StateContext } from "../../../shared/globalState";
import { printDetails } from "../../../shared/tableCommonFunctions";

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  let acnArr = props.roles.filter((u) => u.subMenuName === "Stock Out");
  let acnObj = acnArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                style={{}}
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewStockOut extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      StockOut: {
        stockCode: "",
        stockName: "",
        stockIndentID: "",
        warehouseLocation: "",
        stockOutQty: "",
        stockOutdate: "",
        reason: "",
        isActive: "",
        customerName: "",
      },
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let stockOutID = this.props.match.params.stockOutID;
    getStockOutData(stockOutID).then((r) => {
      dispatch({
        type: "getStockOutData",
        newStore: r,
      });
      this.setState({ StockOut: r });
    });
  }

  render() {
    let { StockOut } = this.state;
    let stockOutID = this.props.match.params.stockOutID;

    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selectedStockOutID={stockOutID}
            roles={this.props.roles}
          />
          {/* <div className="view-page-header">Cost Per Title Information</div> */}
          <Divider />

          <div className="view-page-container" id="printable">
            <div className="view-page-headings">Stock Out Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Customer Name</div>
                <div className="view-page-value">{StockOut.customerName}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Stock Code</div>
                <div className="view-page-value">{StockOut.stockCode}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Stock Out Qty</div>
                <div className="view-page-value">{StockOut.stockOutQty}</div>
              </div>
              {/* <div className="view-page-contents-sub-container">
                <div className="view-page-label">Stock IndentId</div>
                <div className="view-page-value">
                {StockOut.stockIndentID}
                </div>               
              </div> */}
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Warehouse Location</div>
                <div className="view-page-value">
                  {StockOut.warehouseLocation}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Stock Out date</div>
                <div className="view-page-value">
                  {moment(StockOut.stockOutdate).format("DD/MM/YYYY  HH:mm:ss")}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Reason</div>
                <div className="view-page-value">{StockOut.reason}</div>
              </div>
            </div>

            {/* <div className="view-page-contents-container">
            <div className="view-page-contents-sub-container">
                <div className="view-page-label">isActive</div>
                <div className="view-page-value">
                {status}
                </div>               
              </div>
              </div> */}
          </div>
          <Divider />
          <div className="button-wrapper">
            <div style={{ marginLeft: "2em" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.props.history.push("/StockOut")}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewStockOut);
