import React, { Component } from "react";

class MailLodgementVolume extends Component {
  state = {};
  componentDidMount() {
    this.props.handleSettingHeader("Mail Lodgement Volume");
    this.props.setSelectedList("publisherReportsOpen", "MailLodgementVolume");
  }
  render() {
    return (
      <div>
      <iframe
        style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
        src={`${window.localStorage.ReportURLByID}` +"MailLodgementVolumeReport"}
        />
      </div>
    );
  }
}

export default MailLodgementVolume;
