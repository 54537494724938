import React, { Component } from "react"
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import FileCopyIcon from '@material-ui/icons/FileCopy';
export default class EdsErrorModal extends Component {
  copyCodeToClipboard = () => {
    const el = this.textArea
    el.select()
    document.execCommand("copy")
  }
  render() {
    let { open, handleEdsErrorModalClose, data } = this.props
    return (
      <React.Fragment>
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={open}
          maxWidth="md"
        >
          <DialogTitle id="customized-dialog-title"  >
            <div style={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              height: "30px"
            }}>

              <span >EDS API Logs</span>
              {/* <Button onClick={() => this.copyCodeToClipboard()} style={{ float: "right" }}> */}
              {/* </Button> */}
              {data && <Tooltip title="Copy to Clipboard">
                <IconButton
                  aria-label="Copy to Clipboard"
                  // className={classes.iconHover}
                  onClick={() => this.copyCodeToClipboard()}
                >
                  <FileCopyIcon />
                </IconButton>

              </Tooltip>}
            </div>
          </DialogTitle>
          <Divider style={{ height: ".05em" }} />

          <div style={{ padding: "2em 1.5em", minWidth: "800px", maxHeight: "400px", overflowY: "auto" }}>
            <textarea
              ref={(textarea) => this.textArea = textarea}
              value={data}
              style={{ minWidth: "900px", minHeight: "350px" }}
            />

          </div>
          <div></div>
          <Divider style={{ height: ".05em" }} />
          <MuiDialogActions>
            <Button variant="contained"
              color="primary" onClick={e => handleEdsErrorModalClose(e)}>Close</Button>
          </MuiDialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}