import React, { Component } from "react";
import styles from "./login.module.css";
import { TextField, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import Validator from "../../shared/validator";
import { postForgotPassword } from "../userAccess/userAccessAction";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const username = new Validator("username");
const password = new Validator("password");
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      USERNAME: {
        userName: "",
        password: "",
      },
      message: "",
      passwordSend: false,
      failed: false,
      errors: {},
      formValid: false,
      usernameValid: false,
      passwordValid: false,
      showPassword: false,
      isSubmitting: false,
      errorMessage: null,
      auth: false,
    };
  }

  _onKeyPress(event) {
    const re = /[a-zA-Z0-9\s\!#%^&=(),_.@-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  validateForm() {
    this.setState({
      formValid: this.state.userNameValid && this.state.passwordValid,
    });
    return this.state.formValid;
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let usernameValid = this.state.usernameValid;
    let passwordValid = this.state.passwordValid;
    switch (fieldName) {
      case "userName":
        usernameValid = username(value);
        fieldValidationErrors.username = usernameValid ? "" : "";
        break;
      case "password":
        passwordValid = password(value);
        fieldValidationErrors.password = passwordValid ? "" : "";
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        usernameValid: usernameValid,
        passwordValid: passwordValid,
      },
      this.validateForm
    );
  }

  handleChange = (e) => {
    const { USERNAME } = this.state;
    USERNAME[e.target.name] = e.target.value;

    this.setState({ USERNAME, failed: false, passwordSend: false });
    this.validateField(e.target.name, e.target.value);
  };

  checkValidInputs = () => {
    if (!this.state.USERNAME.userName) return true;
    else return false;
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let { USERNAME } = this.state;

    let userLoginID = USERNAME.userName;

    let reqBody = {
      userLoginID: userLoginID,
    };

    postForgotPassword(reqBody, this.props.history).then((r) => {
      console.log(r);
      if (r === true) {
        this.setState({ passwordSend: true });
      } else {
        let message = r.data.message;
        this.setState({ failed: true, message });
      }
    });
    // localStorage.setItem("userName", userLoginID);
    // localStorage.setItem("auth", true);
    // localStorage.setItem("reportUname", "pgsadmin");
    // localStorage.setItem("reportPwd", "Pg$@dmin");
  };

  componentDidMount() {
    // localStorage.clear();
    // let newUSERNAME = { ...this.state.USERNAME };
    // const userName = localStorage.getItem("userName") === "true";
    //  newUSERNAME.username = userName;
    //  this.setState({ USERNAME: newUSERNAME });
  }

  // onKeyPress = event => {
  //   if (event.key === 13) {
  //     console.log("enter press here! ");
  //     this.handleSubmit();
  //   }
  // };

  render() {
    let { classes } = this.props;
    let { USERNAME } = this.state;
    return (
      <div className={styles.base}>
        <div className={styles.main}>
          <div>
            <div className={styles.header} />
            <div className={styles.headerText}>Publisher & Generic System</div>
          </div>
          <div className={styles.userInputField}>
            <form className={classes.container} onSubmit={this.handleSubmit}>
              <div>
                <TextField
                  label="User Name"
                  name="userName"
                  inputProps={{
                    maxLength: 50,
                    background: "transparent!important",
                  }}
                  type="text"
                  autoComplete="off"
                  style={{ marginTop: "40px", width: "100%" }}
                  className={classes.textField}
                  onChange={this.handleChange}
                  value={USERNAME.userName ? USERNAME.userName : ""}
                  error={this.state.errors.userName}
                  helperText={
                    this.state.errors.userName ? this.state.errors.userName : ""
                  }
                  onKeyPress={this._onKeyPress}
                />
              </div>

              {this.state.passwordSend && (
                <div
                  style={{ textAlign: "left", color: "red", fontSize: "11px", fontWeight: "bold" }}
                >
                  <p>We have sent you an Email</p>
                </div>
              )}
              {this.state.failed && (
                <div
                  style={{ textAlign: "left", color: "red", fontSize: "11px", fontWeight: "bold" }}
                >
                  <p>{this.state.message}</p>
                </div>
              )}

              <div style={{ textAlign: "4", maxWidth: "199px" }}>
                {this.state.errorMessage ? (
                  <span className={styles.errorMessage}>
                    {this.state.errorMessage}
                  </span>
                ) : null}
              </div>

              {this.state.failed || this.state.passwordSend ? null : (
                <div className={styles.button}>
                  {this.checkValidInputs() ? (
                    <Button
                      type="submit"
                      disabled
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={this.handleSubmit}
                      // onKeyPress={this.onKeyPress}

                      style={{
                        width: "100%",
                        height: "40px",
                      }}
                    >
                      SUBMIT
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={this.handleSubmit}
                      // onKeyPress={this.onKeyPress}

                      style={{
                        width: "100%",
                        height: "40px",
                      }}
                    >
                      SUBMIT
                    </Button>
                  )}
                </div>
              )}
              <div
                style={{
                  textAlign: "left",
                  marginTop: "1em",
                }}
              >
                <Link
                  to={"/"}
                  style={{
                    textDecoration: "none",
                    color: "rgb(60, 103, 171)",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  <ArrowBackIcon
                    fontSize="small"
                    style={{ verticalAlign: "text-bottom" }}
                  />
                  Go to Login
                </Link>
              </div>
            </form>
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ForgotPassword));
