import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import axios from "axios";
import toastr from "toastr";
import { styles } from "../../../shared/styles";
import { StateContext } from "../../../shared/globalState";
import { getLocalPostageLists, deleteLocalPostage } from "../masterActions";
// const baseURL = process.env.REACT_APP_PGS_SERVER_MASTER_DATA_MANAGEMENT;
// const pgsService = axios.create({
//   baseURL
// });

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

class DeleteLocalPostage extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    modifiedBy: 1
  };
  onDeleteLocalPostage = () => {
    const dispatch = this.context[1];
    let { selected } = this.props;
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");
    deleteLocalPostage(modifiedBy, deletionIds).then(r => {
      getLocalPostageLists().then(r => {
        this.handleClose();
        this.props.onRequestSucceed();
      });

      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };
  //   pgsService
  //     .delete(`/LocalPostages`, {
  //       data: {
  //         modifiedBy,
  //         deletionIds
  //       }
  //     })
  //     .then(r => {
  //       // axios.delete(`https://localhost:5003/api/V1/Zones/${zoneID}`).then(r => {
  //       this.handleClose();
  //       toastr.success("Local Postage Deleted successfully");
  //       getLocalPostageLists()
  //         .then(r => {
  //           dispatch({
  //             type: "getLocalPostage",
  //             newStore: r.data
  //           });
  //         })
  //         .catch(e => {});
  //       this.props.history.push("/localPostage");
  //       this.props.onRequestSucceed();
  //     })
  //     .catch(e => {
  //       if (e.response.data.errors[0].message !== undefined) {
  //         toastr.error(e.response.data.errors[0].message);
  //       } else if (e.response.data.errors[0]) {
  //         toastr.error(e.response.data.errors[0]);
  //       }
  //       this.handleClose();
  //       this.props.onRequestSucceed();
  //     });
  // };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const { classes, mode, selected, coustomerName, isActive } = this.props;
    return (
      <div>
        <IconButton
          aria-label="add"
          className={classes.iconHover}
          onClick={this.handleClickOpen}
          disabled={!isActive}
        >
          <DeleteIcon />
        </IconButton>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="lg"
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Delete Local Postage
          </DialogTitle>

          <DialogContent dividers>
            <div className={classes.deleteContainer}>
              Are you sure you want to delete {""}
              <span style={{ fontWeight: "600" }}>
                {selected.length > 1 ? " ?" : coustomerName + " ?"}
                {/* {coustomerName} */}
              </span>
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              //disableElevation
              style={{ boxShadow: "none" }}
              onClick={this.handleClose}
            >
              CANCEL
            </Button>
            {mode && mode !== "view" && (
              <Button
                //variant="outlined"
                variant="contained"
                color="primary"
                //disableElevation
                style={{ boxShadow: "none" }}
                onClick={this.onDeleteLocalPostage}
              >
                Delete
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(DeleteLocalPostage));
