import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";
import clone from "clone";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReportViewer from "../../app/ReportViewer";
import { withRouter } from "react-router-dom";
import TableHead from "@material-ui/core/TableHead";
import moment from "moment"
import { EditIcon } from "../../../shared/customIcons";
import { Divider, Tooltip, Icon } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import DeleteIcon from "@material-ui/icons/Delete";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CheckIcon from "@material-ui/icons/Check";
import StyleIcon from '@material-ui/icons/Style';
import { Autocomplete } from "@material-ui/lab";
import { StateContext } from "../../../shared/globalState";
import { addDispatchStyles } from "../../../shared/styles";
import { addZeros } from "../../../shared/tableCommonFunctions";
import {
  getViewDetailsForMainLabelDocketBagging,
  postDocketBaggingList,
  getMailSchemeForDocketBagging,
  getDestinationForDocketBagging,
  getDocketBaggingHeaderInfo,
} from "./action";
let proceedToCheck = false
let sIds = []
let autoCId = "";
const headCells = [
  {
    id: "mailingScheme",
    numeric: false,
    disablePadding: true,
    label: "Mailing Scheme*",
  },
  {
    id: "destination",
    numeric: false,
    disablePadding: false,
    label: "Destination*",
  },
  {
    id: "noofBags",
    numeric: false,
    disablePadding: false,
    label: "No. of Bags",
  },
  {
    id: "bagWeight",
    numeric: false,
    disablePadding: false,
    label: "Bag Weight(kg)",
  },
  {
    id: "totalWeight",
    numeric: false,
    disablePadding: false,
    label: "Total Bag Weight(kg)",
  },

  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderTop: "1px solid #eaeaea",
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow style={{ background: "#eaeaea" }}>
        {headCells.map((headCell, i) => (
          <React.Fragment>
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
              className={i !== headCells.length - 1 ? "headcells" : "headcell"}
              style={{ borderLeft: "1px sold #000" }}
            >
              <span style={{ color: "#306dca" }}>{headCell.label}</span>
            </TableCell>
          </React.Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { } = props;
  return (
    <div>
      {props.currentLocation === "view" ? (
        <div
          style={{
            display: "flex",
            marginTop: "1em",
          }}
        />
      ) : (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                //   width: "100%",
                border: "1px solid #eaeaea",
                borderBottom: "none",
                borderRadius: "4px",
                marginTop: "1em",
              }}
            >
              {/* {props.disableAdd ? (
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  style={{
                    boxShadow: "none",
                    padding: "2px",
                    margin: ".5em",
                    fontSize: "11px",
                  }}
                  onClick={(e) => props.handleClickButton(e, "add")}
                // style={
                //   !props.disableAdd
                //     ? {
                //         color: "#fff",
                //         background: "#3982ea",
                //       }
                //     : { color: "#3982ea", fontSize: "11px" }
                // }
                >
                  <AddIcon style={{ fontSize: "18px" }} /> Add
                </Button>
              ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      boxShadow: "none",
                      padding: "2px",
                      margin: ".5em",
                      fontSize: "11px",
                    }}
                    onClick={(e) => props.handleClickButton(e, "add")}
                  // style={
                  //   !props.disableAdd
                  //     ? {
                  //         color: "#fff",
                  //         background: "#3982ea",
                  //       }
                  //     : { color: "#3982ea", fontSize: "11px" }
                  // }
                  >
                    <AddIcon style={{ fontSize: "18px" }} /> Add
                  </Button>
                )} */}
              {props.disableEdit ? (
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  style={{
                    boxShadow: "none",
                    padding: "2px",
                    margin: ".5em",
                    fontSize: "11px",
                  }}
                  // style={
                  //   !props.disableEdit
                  //     ? {
                  //         color: "#fff",
                  //         background: "#3982ea",
                  //       }
                  //     : { color: "#3982ea", fontSize: "11px" }
                  // }
                  onClick={(e) => props.handleClickButton(e, "reset")}
                >
                  <SyncIcon style={{ fontSize: "18px" }} /> Reset
                </Button>
              ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      boxShadow: "none",
                      padding: "2px",
                      margin: ".5em",
                      fontSize: "11px",
                    }}
                    // style={
                    //   !props.disableEdit
                    //     ? {
                    //         color: "#fff",
                    //         background: "#3982ea",
                    //       }
                    //     : { color: "#3982ea", fontSize: "11px" }
                    // }
                    onClick={(e) => props.handleClickButton(e, "reset")}
                  >
                    <SyncIcon style={{ fontSize: "18px" }} /> Reset
                  </Button>
                )}
            </div>
          </React.Fragment>
        )}
    </div>
  );
};

class DocketBagging extends React.Component {
  static contextType = StateContext;
  state = {
    selectedFilteredMSObj: {},
    selectedFilteredMSId: "",
    selectedFilteredDesObj: {},
    selectedFilteredDesId: "",
    destinationListForFiltering: [],
    disableSubmitButton: true,
    disableAdd: false,
    disableEdit: true,
    list: [],
    editableList: [],
    originalList: [],
    selectedEditIndex: "",
    mailingSchemeList: [],
    mailingScheme: {},
    destinationList: [],
    destination: {},
    destinationId: "",
    mailingSchemeId: "",
    showPopup: false,
    docketbaggingHeaderInfo: {},
    lodgementDate: null
  };

  handleShowPopup = () => {
    this.setState({
      showPopup: true,
    });
  };

  handleHidePopup = () => {
    this.setState({
      showPopup: false,
    });
  };

  // onBlur = (event, name, index) => {
  //   let newList = clone(this.state.list);
  //   let values = event.target.value;
  //   values = this.addZeroes(values.toString(), 3);
  //   newList[index][name] = values;
  //   this.setState({ list: newList });
  // };

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    return value;
  }

  componentDidMount() {
    this.props.handleSettingHeader("Main Label Docket Bagging");
    this.props.setSelectedList(
      "publisherMainLabelOpen",
      "pubMainLabelDocketBagging"
    );

    this.props.handleDrawerClose();
    const [{ store }, dispatch] = this.context;
    let batchHeaderId = "";
    if (process.browser) batchHeaderId = window.location.pathname.split("/")[2];
    // batchHeaderId = window.location.pathname.split("/")[2];



    getMailSchemeForDocketBagging(batchHeaderId).then((r) => {
      r.data &&
        this.setState({
          mailingSchemeList: r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
        });
    });
    // getDestinationForDocketBagging(batchHeaderId).then((r) => {
    //   r.data &&
    //     this.setState({
    //       destinationList: r.data.sort((a, b) =>
    //         a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
    //       ),
    //     });
    // });
    getDocketBaggingHeaderInfo(batchHeaderId).then((r) => {
      this.setState({
        docketbaggingHeaderInfo: r.data,
        lodgementDate: r.data.lodgementDate
        // ? moment(r.data.lodgementDate).format("DD/MM/YYYY") : null
      });
    });
    this.getListData()
  }
  getListData = () => {
    let batchHeaderId = "";
    let newList = []
    if (process.browser) batchHeaderId = window.location.pathname.split("/")[2];
    getViewDetailsForMainLabelDocketBagging(batchHeaderId).then((r) => {
      r &&
        r.data &&
        r.data.length > 0 &&
        r.data.map((l) => {
          newList.push({
            mailingScheme: {
              id: l.mailingSchemeID,
              value: l.mailingScheme,
              text: l.mailingScheme,
            },
            destinationId: !l.countryID ? l.zoneID : l.countryID,
            mailingSchemeId: l.mailingSchemeID,
            destination: {
              id: !l.countryID ? l.zoneID : l.countryID,
              value: l.destination,
              text: l.destination,
            },
            canDelete: l.canDelete,
            lodgementWeight: l.lodgementWeight,
            noofBags: l.noofBags ? l.noofBags : "",
            bagWeight: l.bagWeight ? l.bagWeight : "",
            totalWeight: l.totalWeight ? l.totalWeight.toFixed(3) : "",
            createdBy: window.localStorage.loggedInId,
            mlDocketBagID: l.mlDocketBagID,
            id: l.mlDocketBagID,
            // mlDocketBagID: l.mlDocketBagID,
            // countryID: l.countryID,
            // destination: l.destination,
            // mailingSchemeID: l.mailingSchemeID,
            // mlDocketBagID: l.mlDocketBagID,
            editClicked: false,
            //zoneID: l.zoneID,
            isActive: l.isActive,
            // mlDocketBagID: l.mlDocketBagID
            displayInLiist: true
          });
        });
      newList.map(s => {
        if (document.getElementById("row" + s.mlDocketBagID)) {
          document.getElementById("row" + s.mlDocketBagID).style.background = "#fff"
        }
      })
      this.setState({
        list: newList,
        disableEdit: false,
        subordinate: newList,
        originalList: newList,
      }, () => {
        if (document.getElementById("bgW0")) {
          document.getElementById("bgW0").focus()
        }
      });
    });
  }

  getAllCustomer = (list) => {
    this.setState({
      customerList:
        list &&
        list.sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
        ),
    });
  };

  getAllCategory = (list) => {
    let categoryList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        categoryList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.setState({ categoryList });
  };

  handleClickButton = (e, name) => {
    e.preventDefault()

    let newList = clone(this.state.list);
    let newObj = {
      mailingScheme: {},
      destination: {},
      noofBags: "",
      bagWeight: "",
      totalWeight: "",
      canDelete: true,
      editClicked: true,
      manualEntry: true,
      isActive: true,
      createdBy: window.localStorage.loggedInId,
    };
    newList.push(newObj);
    if (name === "add") {
      newList.map((c, idn) => {
        newList[idn].displayInLiist = true
      })
      this.setState({
        list: newList,
        editableList: newList,
        disableSubmitButton: true,
        // disableAdd: true,
        disableEdit: false,
        OrderGroupID: 0,
        // selectedEditIndex: newList.length - 1,
        originalList: [...this.state.originalList, []], selectedFilteredMSObj: {},
        selectedFilteredMSId: "",
        selectedFilteredDesObj: {},
        selectedFilteredDesId: "",
        destinationListForFiltering: []
      });
    } else {
      this.getListData()
      this.setState({
        disableSubmitButton: true, selectedFilteredMSObj: {},
        selectedFilteredMSId: "",
        selectedFilteredDesObj: {},
        selectedFilteredDesId: "",
        destinationListForFiltering: []

      })
    }
  };
  onCloneClicked = (e, row, i) => {
    e.preventDefault()
    let nos = i + 1
    let id = "bgW" + nos
    // this.setState({ selectedEditIndex: i });
    let newRow = clone(row)
    let newEditableList = clone(this.state.list);
    newRow.noofBags = ""
    newRow.bagWeight = ""
    newRow.totalWeight = ""
    newRow.lodgementWeight = 0
    newRow.canDelete = true
    newRow.id = "colonnedID" + Math.random()
    delete newRow.mlDocketBagID
    newEditableList.splice(i + 1, 0, newRow);
    newEditableList[i].editClicked = !newEditableList[i].editClicked;
    this.setState({
      list: newEditableList,
    }, () => {
      if (document.getElementById(id)) {
        let x = document.getElementById(id)
        x.focus()
      }
      // this.checkSubmitDisability();

    })
  };
  onDeleteClicked = (e, i, sRow) => {
    let newList = clone(this.state.list);
    newList[i].editClicked = false;
    newList[i].isActive = false;
    newList[i].displayInLiist = false
    // newList.splice(i, 1);
    this.setState({
      list: newList,
      // disableSubmitButton: false
    });
    let totLW = 0
    let totBG = 0
    sIds = []

    newList.map(xy => {
      if (xy && xy.displayInLiist && xy.mailingSchemeId === sRow.mailingSchemeId && xy.destinationId === sRow.destinationId) {
        totLW = totLW + xy.lodgementWeight
        totBG = totBG + (+xy.totalWeight)
        sIds.push("row" + xy.id)
      }

    })
    let val = e.target.value;
    if (totLW >= totBG) {
      proceedToCheck = false
      sIds.map(s => {
        if (document.getElementById(s)) {
          document.getElementById(s).style.background = "rgba(206, 17, 38, 0.35)"
        }
      })
    }
    else {
      proceedToCheck = true
      sIds.map(s => {
        if (document.getElementById(s)) {
          document.getElementById(s).style.background = "#fff"
        }
      })

    }
    // this.setState({ disableSubmitButton: true })
    this.checkSubmitDisability();
  };

  onSPLInstructionChanged = (e) => {
    this.setState({ specialInstruction: e.target.value });
  };

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, 5);
    }
    return value;
  }

  handleChange = (e, name, i, sRow) => {
    let newList = clone(this.state.list);
    // if (name === "noofBags")
    newList[i][name] = e.target.value;
    // else
    //   newList[i][name] = e.target.value.toFixed(3);
    // let t = e.target.value ? e.target.value : 0

    // if (true) {
    //   if (name === "bagWeight" || name === "totalWeight") {
    //     let x = ""
    //     if (t)
    //       x = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 4)) : t;
    //     newList[i][name] = +x

    //   }
    let t = e.target.value

    if (e.target.value) {
      if (name === "bagWeight" || name === "totalWeight") {
        let x = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 4)) : t;
        newList[i][name] = x

      }
      if (name === "totalWeight") {
        if (newList[i].noofBags) {
          newList[i].bagWeight = (newList[i].totalWeight / newList[i].noofBags).toFixed(3)
        }
        else if (newList[i].bagWeight) {
          newList[i].noofBags = (newList[i].totalWeight / newList[i].bagWeight).toFixed(0)
          newList[i].bagWeight = (newList[i].totalWeight / newList[i].noofBags).toFixed(3)
        }

      }
      if (name === "noofBags") {
        if (newList[i].bagWeight) {
          newList[i].totalWeight = (newList[i].noofBags * newList[i].bagWeight).toFixed(3);
        }
        else if (newList[i].totalWeight)
          newList[i].bagWeight = (newList[i].totalWeight / newList[i].noofBags).toFixed(3)

      }
      if (name === "bagWeight") {
        if (newList[i].noofBags) {
          newList[i].totalWeight = (newList[i].noofBags * newList[i].bagWeight).toFixed(3);
        }
        else if (newList[i].totalWeight) {
          newList[i].noofBags = (newList[i].totalWeight / newList[i].bagWeight).toFixed(0)
          newList[i].totalWeight = (newList[i].noofBags * newList[i].bagWeight).toFixed(3);
        }
      }
    }
    else {
      if (name === "noofBags") {
        newList[i].bagWeight = newList[i].bagWeight ? newList[i].bagWeight : 0
        newList[i].totalWeight = "0.000"
      }
      else if (name === "bagWeight") {
        newList[i].noofBags = newList[i].noofBags ? newList[i].noofBags : 0
        newList[i].totalWeight = "0.000"
      }
      else if (name === "totalWeight") {
        newList[i].noofBags = newList[i].noofBags ? newList[i].noofBags : 0
        newList[i].bagWeight = newList[i].bagWeigh ? newList[i].bagWeigh : 0
      }
    }
    // if (name !== "totalWeight"){

    // }
    //   if (newList[i].noofBags && newList[i].bagWeight)
    //     newList[i].totalWeight = newList[i].noofBags * newList[i].bagWeight;
    // if (name === "totalWeight") {
    //   if (newList[i].noofBags) {
    //     newList[i].bagWeight = (e.target.value / newList[i].noofBags).toFixed(3)
    //   }
    //   else if (newList[i].bagWeight)
    //     newList[i].noofBags = (e.target.value / newList[i].bagWeight).toFixed(1)
    // }
    let totLW = 0
    let totBG = 0
    sIds = []

    newList.map(xy => {
      if (xy && xy.displayInLiist && xy.mailingSchemeId === sRow.mailingSchemeId && xy.destinationId === sRow.destinationId) {
        totLW = totLW + xy.lodgementWeight
        totBG = totBG + (+xy.totalWeight)
        sIds.push("row" + xy.id)
      }

    })
    let val = e.target.value;
    if (totLW >= totBG) {
      proceedToCheck = false
      sIds.map(s => {
        if (document.getElementById(s)) {
          document.getElementById(s).style.background = "rgba(206, 17, 38, 0.35)"
        }
      })
    }
    else {
      proceedToCheck = true
      sIds.map(s => {
        if (document.getElementById(s)) {
          document.getElementById(s).style.background = "#fff"
        }
      })

    }
    this.setState({ list: newList }, () => {
      // if (name === "bagWeight" || name === "noofBags") {

      this.checkSubmitDisability();
      // }
    });
  };
  checkSubmitDisability = () => {
    let ck = true
    let disableSubmit = false;
    this.state.list.length > 0 && this.state.list.map(s => {
      if (s && s.displayInLiist && document.getElementById("row" + s.id)) {
        if (document.getElementById("row" + s.id).style.background === "rgba(206, 17, 38, 0.35)") {
          ck = false
        }
      }
    })
    if (!proceedToCheck) {
      ck = false
    }
    this.setState({ disableSubmitButton: !ck });

    // this.state.list.map((r) => {
    //   // console.log(r)
    //   if (
    //     !r.destinationId ||
    //     !r.mailingSchemeId
    //     // || !r.noofBags
    //     // || !r.bagWeight
    //     // || "editClicked" in r ? r.editClicked : false
    //   )
    //     disableSubmit = true;
    // });
    // this.setState({ disableSubmitButton: disableSubmit });

  };

  handleBagWeightChange = (e, name, i) => {
    let newList = clone(this.state.list);
    newList[i][name] = e.target.value;
    let r = e.target.value;
    r = this.checkDHlWeightDec(e.target.value.toString());
    this.checkSubmitDisability();
    newList[i].bagWeight = r;
    newList[i].totalWeight = newList[i].noofBags * newList[i].bagWeight;
    this.setState({ list: newList });
  };

  handleAutoCompleteChange1 = (event, value) => {
    let newEditableList = clone(this.state.list);
    if (value) {
      newEditableList[autoCId].destination = value;
      newEditableList[autoCId].destinationId = value.id;
    }
    this.setState(
      {
        list: newEditableList,
        editableList: newEditableList,
      },
      () => {
        this.checkSubmitDisability();
      }
    );
  };
  handleAutoCompleteChange = (event, value) => {
    let newEditableList = clone(this.state.list);
    if (value) {
      newEditableList[autoCId].mailingScheme = value;
      newEditableList[autoCId].mailingSchemeId = value.id;
      newEditableList[autoCId].destination = null
      newEditableList[autoCId].destinationId = ""

    }
    this.setState(
      {
        list: newEditableList,
        editableList: newEditableList,
      },
      () => {
        this.checkSubmitDisability();
      }
    );
  };
  handleMSFilterAutoComplete = (e, value) => {

    let newList = clone(this.state.list)
    if (value) {
      this.state.list.map((d, idn) => {
        if (d.mailingSchemeId === value.id)
          newList[idn].displayInLiist = true
        else
          newList[idn].displayInLiist = false
      })
      this.setState({
        selectedFilteredMSObj: value,
        selectedFilteredMSId: value.id,
        selectedFilteredDesObj: {},
        selectedFilteredDesId: "",
        list: newList,
        destinationListForFiltering: []


      }, () => {
        if (document.getElementById("bgW0")) {
          document.getElementById("bgW0").focus()
        }
      })
    }
    else {
      this.state.list.map((d, idn) => {
        newList[idn].displayInLiist = true
      })
      this.setState({
        selectedFilteredMSObj: {},
        selectedFilteredMSId: "",
        selectedFilteredDesObj: {},
        selectedFilteredDesId: "",
        list: newList,
        destinationListForFiltering: []
      }, () => {
        console.log(document.getElementById("bgW0"))
        if (document.getElementById("bgW0"))
          document.getElementById("bgW0").focus()
      })
    }
  };
  handleDesFilterAutoComplete = (e, value) => {
    let newList = clone(this.state.list)
    if (value) {
      this.state.list.map((d, idn) => {
        if (d.mailingSchemeId === this.state.selectedFilteredMSId && d.destinationId === value.id)
          newList[idn].displayInLiist = true
        else
          newList[idn].displayInLiist = false
      })
      this.setState({
        selectedFilteredDesObj: value,
        selectedFilteredDesId: value.id,
      })
    }
    else if (this.state.selectedFilteredMSId) {
      this.state.list.map((d, idn) => {
        if (d.mailingSchemeId === this.state.selectedFilteredMSId)
          newList[idn].displayInLiist = true
        else
          newList[idn].displayInLiist = false
      })
      this.setState({
        selectedFilteredDesObj: {},
        selectedFilteredDesId: "",
      })
    }
    else {
      this.state.list.map((d, idn) => {
        newList[idn].displayInLiist = true
      })
      this.setState({
        selectedFilteredDesObj: {},
        selectedFilteredDesId: "",
      })
    }
    this.setState({
      list: newList
    }, () => {
      if (document.getElementById("bgW0")) {
        document.getElementById("bgW0").focus()
      }
    })

  };
  _onAlphaKeyPress(event) {
    const re = /[a-zA-Z 0-9. ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  checkDHlWeightDec1(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onOnlyNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyWithDecimalPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleSubmit = (e) => {
    let batchHeaderId = window.location.pathname.split("/")[2];
    let createdBy = window.localStorage.loggedInId;
    // console.log(this.state.list)
    let countryID = null;
    let zoneID = null;
    let newList = clone(this.state.list);
    let reqBody = {}
    reqBody.batchHeaderID = +batchHeaderId
    reqBody.userId = +createdBy
    reqBody.lodgementDate = this.state.lodgementDate ? moment(this.state.lodgementDate).format("MM/DD/YYYY") : null
    reqBody.docketBaggingdetails = this.state.list.map((val) => {
      return {
        ...val,
        // bagWeight: val.bagWeight,
        // createdBy: val.createdBy,
        // isActive: val.isActive,
        // noofBags: val.noofBags,
        // totalWeight: val.totalWeight,
        // manualEntry: val.manualEntry,
        noofBags: val.noofBags ? val.noofBags : 0,
        countryID:
          val &&
            val.destination &&
            val.destination.text &&
            val.destination.text.includes("Zone")
            ? null
            : val && val.destination && val.destination.id,
        zoneID:
          val &&
            val.destination &&
            val.destination.text &&
            val.destination.text.includes("Zone")
            ? val && val.destination && val.destination.id
            : null,
        mailingSchemeId: val && val.mailingScheme && val.mailingScheme.id,
        // destinationId: val && val.destination && val.destination.id

      };
    });
    if (this.state.list.length === 0) {
      reqBody = [{ mlBatchHeaderID: +batchHeaderId, createdBy: +createdBy }]
    }

    postDocketBaggingList(
      reqBody,
      this.props.history,
      batchHeaderId,
      createdBy
    ).then((r) => { });
  };
  handleAutoOpenClick = (id) => {
    autoCId = id;
  };
  handleAPIOnAutoOpenClick = (id) => {
    autoCId = id;
    let batchHeaderId = "";
    if (process.browser) batchHeaderId = window.location.pathname.split("/")[2];
    if (this.state.list[autoCId].mailingSchemeId) {
      this.setState({ destinationList: [{ id: 4, text: "Loading...", value: "Loading..." }] })
      getDestinationForDocketBagging(batchHeaderId, this.state.list[autoCId].mailingSchemeId).then((r) => {
        r.data &&
          this.setState({
            destinationList: r.data.sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
          });
        if (!r.data)
          this.setState({ destinationList: [] })
      });

    }
    else this.setState({ destinationList: [] })
  };
  handleDesFilterOnAutoOpenClick = (id) => {
    let batchHeaderId = "";
    if (process.browser) batchHeaderId = window.location.pathname.split("/")[2];
    if (this.state.selectedFilteredMSId) {
      this.setState({ destinationListForFiltering: [{ id: 4, text: "Loading...", value: "Loading..." }] })
      getDestinationForDocketBagging(batchHeaderId, this.state.selectedFilteredMSId).then((r) => {
        r.data &&
          this.setState({
            destinationListForFiltering: r.data.sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
          });
        if (!r.data)
          this.setState({ destinationListForFiltering: [] })
      });

    }
    else this.setState({ destinationList: [] })
  };
  focusNextNOB = (e, i,) => {
    let idd = i + 1
    let idBG = "bgW" + idd
    if (e.keyCode === 9) {
      e.preventDefault();
      if (document.getElementById(idBG))
        document.getElementById(idBG).focus()
    }

  }
  focusNextNOBPress = (e, i,) => {
    let idd = i + 1
    let idBG = "bgW" + idd
    if (e.keyCode === 13) {
      e.preventDefault();
      if (document.getElementById(idBG))
        document.getElementById(idBG).focus()
    }
  }
  focusNextNOBPress1 = (e, i,) => {
    let idd = i
    let idBG = "bgWW" + idd
    if (e.keyCode === 13) {
      e.preventDefault();
      if (document.getElementById(idBG))
        document.getElementById(idBG).focus()
    }
  }
  focusNextNOBPress2 = (e, i,) => {
    let idd = i
    let idBG = "bgWWW" + idd
    if (e.keyCode === 13) {
      e.preventDefault();
      if (document.getElementById(idBG))
        document.getElementById(idBG).focus()
    }
  }
  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
    // this.checkSubmitDisability();
    if (date != null || date !== "Invalid Date") {
      let ck = true
      let disableSubmit = false;
      this.state.list.length > 0 && this.state.list.map(s => {
        if (s && s.displayInLiist && document.getElementById("row" + s.id)) {
          if (document.getElementById("row" + s.id).style.background === "rgba(206, 17, 38, 0.35)") {
            ck = false
          }
        }
      })
      this.setState({ disableSubmitButton: !ck });
    }
  };
  render() {
    // console.log(this.state.lodgementDate)
    let {
      list,
      customerList,
      categoryList,
      processList,
      docketbaggingHeaderInfo,
    } = this.state;
    let noBag = 0
    let noWeight = 0
    list && list.length > 0 && list.map(g => {
      if (g.displayInLiist) {
        noBag = noBag + (+g.noofBags)
        noWeight = noWeight + (+g.totalWeight)
      }
    })
    let { classes } = this.props;
    // console.log(this.state.list)
    let newList = clone(list);

    return (
      <React.Fragment>
        <Paper>
          <Divider />
          <div
            className="view-page-container"
            style={{ paddingTop: "2em" }}
            id="printable"
          >
            <div>
              <div style={{ marginBottom: "1em" }}>
                <Typography
                  component="div"
                  className="blueBox"
                  style={{ width: "auto" }}
                >
                  <div className="blueBoxLabel">
                    <div>Batch ID: </div>
                    <div className="blueBoxText">
                      {docketbaggingHeaderInfo.mlBatchHeader}
                    </div>
                  </div>
                  <div className="blueBoxLabel">
                    <div>Customer: </div>
                    <div className="blueBoxText">
                      {docketbaggingHeaderInfo.customerName}
                    </div>
                  </div>
                  <div className="blueBoxLabel">
                    <div>Status: </div>
                    <div className="blueBoxText">
                      {docketbaggingHeaderInfo.status}
                    </div>
                  </div>
                </Typography>
              </div>

              <EnhancedTableToolbar
                //numSelected={selected.length}
                handleClickButton={this.handleClickButton}
                disableAdd={this.state.disableAdd}
                disableEdit={this.state.disableEdit}
              />

              <div style={!this.props.clearOpenList
                ? {
                  maxWidth: "calc(100vw - 8em)", border: "1px solid #eaeaea", borderRadius: "4px", display: "flex"
                }
                : {
                  maxWidth: "calc(100vw - 330px)", border: "1px solid #eaeaea", borderRadius: "4px", display: "flex"
                }}>
                <div
                  style={!this.props.clearOpenList
                    ? {
                      width: "28%", maxWidth: "28%", margin: ".5em"
                    }
                    : {
                      width: "26%", maxWidth: "26%", margin: ".5em"
                    }}
                >
                  <Autocomplete
                    autoComplete
                    // includeInputInList
                    // disableClearable
                    options={this.state.mailingSchemeList}
                    // onOpen={(e) =>
                    //   this.handleAutoOpenClick(index)
                    // }
                    onChange={this.handleMSFilterAutoComplete}
                    // id={"mailingScheme-" + index}
                    name="mailingScheme"
                    hintText="mailingScheme"
                    value={this.state.selectedFilteredMSObj}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        // value={row.mailingScheme}
                        placeholder="Filter by Mailing Scheme"

                        margin="normal"
                        style={{
                          width: "100%",
                          margin: "0px",
                          padding: "0px",
                        }}
                      />
                    )}
                  />
                </div>

                <div

                  style={!this.props.clearOpenList
                    ? {
                      width: "34.5%", maxWidth: "34.5%", margin: ".5em"
                    }
                    : {
                      width: "31%", maxWidth: "31%", margin: ".5em"
                    }}>
                  <Autocomplete
                    //  autoComplete
                    //  includeInputInList
                    // disableClearable
                    options={this.state.destinationListForFiltering}
                    onOpen={(e) =>
                      this.handleDesFilterOnAutoOpenClick(e)
                    }
                    onChange={this.handleDesFilterAutoComplete}
                    //  id={"destination-" + index}
                    //  name="destination"
                    // hintText="destination"
                    value={this.state.selectedFilteredDesObj}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        // value={row.destination}
                        placeholder="Filter by Destination"
                        style={{
                          width: "100%",
                          margin: "0px",
                          padding: "0px",
                        }}
                      // label="Description"
                      // margin="normal"
                      />
                    )}
                  />
                </div>
                <div
                  style={!this.props.clearOpenList
                    ? {
                      width: "10%", maxWidth: "10%", margin: ".5em", display: "flex", alignItems: "flex-end"
                    }
                    : {
                      width: "15%", maxWidth: "15%", margin: ".5em", display: "flex", alignItems: "flex-end"
                    }}

                // style={{ width: "18%", maxWidth: "18%", margin: ".5em", display: "flex", alignItems: "flex-end" }}
                >
                  <span className="blueBoxLabel" style={{ padding: "0px" }}>Total Bags : <span style={{ color: "#306dca", fontWeight: "600", marginLeft: ".5em" }}> {noBag}</span></span>
                  {/* <span style={{ display: "flex" }}> <span className="blueBoxLabel" style={{ padding: "0px" }}>Total Bags :</span>
                    <span style={{ color: "#306dca", fontWeight: "600", marginLeft: ".5em" }}> {noBag}</span></span> */}

                </div>
                <div

                  style={!this.props.clearOpenList
                    ? {
                      width: "23%", maxWidth: "23%", margin: ".5em", display: "flex", alignItems: "flex-end"
                    }
                    : {
                      width: "18%", maxWidth: "18%", margin: ".5em", display: "flex", alignItems: "flex-end"
                    }}>
                  <span className="blueBoxLabel" style={{ padding: "0px" }}>Total Weight :<span style={{ color: "#306dca", fontWeight: "600", marginLeft: ".5em" }}> {noWeight.toFixed(3)}</span></span>

                </div>

              </div>
            </div>
            <div style={!this.props.clearOpenList
              ? {
                maxWidth: "calc(100vw - 8em)", border: "1px solid #eaeaea", borderRadius: "4px", display: "flex"
              }
              : {
                maxWidth: "calc(100vw - 330px)", border: "1px solid #eaeaea", borderRadius: "4px", display: "flex"
              }}> <span style={{ margin: ".8em .5em", color: "#306dca", fontWeight: "600" }}>Docket Bagging lists</span>
            </div>
            <div
              style={{
                // width: "900px",
                maxHeight: "400px",
                margin: "0  0 1em",
                borderLeft: "1px Solid #eaeaea",
                // borderRight: "1px solid #eaeaea"
              }}
            >
              <div
                // style={{ maxHeight: 350, overflow: "auto" }}
                style={
                  !this.props.clearOpenList
                    ? {
                      overflow: "auto",
                      border: "1px solid #eaeaea",
                      maxWidth: "calc(100vw - 8em)",
                      maxHeight: "400px",
                    }
                    : {
                      overflow: "auto",
                      border: "1px solid #eaeaea",
                      maxWidth: "calc(100vw - 330px)",
                      maxHeight: "400px",
                    }
                }
              >
                {/* //overflow: "auto" */}
                <Table
                  aria-labelledby="tableTitle"
                  size="small"
                  stickyHeader
                  aria-label="sticky table"
                // style={{ width: "1800px", overflow: "auto" }}
                >
                  {" "}
                  <EnhancedTableHead />
                  {!this.state.loading ? (
                    list.length > 0 ? (
                      <TableBody style={{ overflow: "hidden" }}>
                        {list.map((row, index) => {
                          // console.log(row)
                          const labelId = `enhanced-table-checkbox-${index}`;
                          let focusMe = ""
                          let focusFirst = true
                          if (row.isActive && row.displayInLiist && focusFirst) {
                            focusMe = "focusMe"
                            focusFirst = false
                          }
                          return (
                            row.isActive && row.displayInLiist && <TableRow
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                              id={"row" + row.id}
                              hover={false}
                              className="doc-bag-tab-row"
                            // style={{ background: "#ffa773" }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                                style={{
                                  width: "450px",
                                  maxWidth: "450px",
                                  //  padding: " 6px 6px",
                                  //   paddingTop: "0px",
                                }}
                              >

                                <Autocomplete
                                  autoComplete
                                  // includeInputInList
                                  disableClearable
                                  options={this.state.mailingSchemeList}
                                  onOpen={(e) =>
                                    this.handleAutoOpenClick(index)
                                  }
                                  onChange={this.handleAutoCompleteChange}
                                  id={"mailingScheme-" + index}
                                  name="mailingScheme"
                                  hintText="mailingScheme"
                                  value={row.mailingScheme}
                                  getOptionLabel={(option) => option.text}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      // value={row.mailingScheme}
                                      InputProps={{ readOnly: true }}
                                      margin="normal"
                                      style={{
                                        width: "100%",
                                        margin: "0px",
                                        padding: "0px",
                                      }}
                                    />
                                  )}
                                />

                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                                style={{
                                  width: "550px",
                                  maxWidth: "550px",

                                  // padding: " 6px 6px",
                                  // paddingTop: "0px",
                                }}
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>

                                  <Autocomplete
                                    //  autoComplete
                                    //  includeInputInList
                                    disableClearable
                                    options={this.state.destinationList}
                                    onOpen={(e) =>
                                      this.handleAPIOnAutoOpenClick(index)
                                    }
                                    onChange={this.handleAutoCompleteChange1}
                                    //  id={"destination-" + index}
                                    //  name="destination"
                                    // hintText="destination"
                                    value={row.destination}
                                    getOptionLabel={(option) => option.text}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        required
                                        // value={row.destination}
                                        InputProps={{ readOnly: true }}
                                        style={{
                                          width: "100%",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      // label="Description"
                                      // margin="normal"
                                      />
                                    )}
                                  />

                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                align="left"
                                className="table-content-right-cell"
                                style={{
                                  width: "10%",
                                  padding: " 6px 6px",
                                }}
                              >
                                <Typography noWrap style={{ fontSize: "14px" }} >

                                  <TextField
                                    autoFocus
                                    defaultValue={row.noofBags}
                                    inputProps={{
                                      "aria-label": "instruction",
                                    }}
                                    inputProps={{
                                      maxLength: 10,
                                    }}
                                    id={"bgW" + index}
                                    // id={}
                                    // className={"bgW" + index}
                                    onKeyPress={this._onOnlyNumberKeyPress}
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                      this.handleChange(e, "noofBags", index, row);
                                    }}
                                    value={row.noofBags}
                                    onKeyUp={e => this.focusNextNOBPress1(e, index, row)}
                                  />

                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"

                                padding="default"
                                align="left"
                                className="table-content-right-cell"
                                style={{ width: "5%", padding: " 6px 6px" }}
                              >
                                <Typography
                                  noWrap
                                  style={{ fontSize: "14px" }}
                                // className="table-content-right-cell"
                                >

                                  <TextField
                                    autoFocus
                                    defaultValue={row.bagWeight}
                                    inputProps={{
                                      "aria-label": "instruction",
                                    }}
                                    inputProps={{
                                      maxLength: 10,
                                    }}
                                    id={`bgWW${index}`}
                                    name="bagWeight"
                                    onKeyPress={this._onNumberKeyPress}
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                      this.handleChange(e, "bagWeight", index, row);
                                    }}
                                    // value={row.bagWeight ? (+row.bagWeight).toFixed(3) : ""}
                                    value={row.bagWeight}
                                    onKeyUp={e => this.focusNextNOBPress2(e, index, row)}
                                  />

                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-right-cell"
                                style={{ width: "5%", padding: " 6px 6px" }}
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {/* {" "}
                                  {row.editClicked &&
                                    !row.fromDB &&
                                    row.manualEntry === true ? (
                                      <Input
                                        disabled
                                        onKeyPress={this._onAlphaKeyPress}
                                        defaultValue={row.totalWeight}
                                        inputProps={{
                                          "aria-label": "description",
                                        }}
                                        onChange={(e) => {
                                          this.handleChange(e);
                                        }}
                                        value={(
                                          row.noofBags * row.bagWeight
                                        ).toFixed(3)}
                                      />
                                    ) : ( */}
                                  <TextField
                                    autoFocus
                                    defaultValue={row.totalWeight}
                                    inputProps={{
                                      "aria-label": "instruction",
                                    }}
                                    id={`bgWWW${index}`}
                                    name="totalWeight"
                                    onKeyPress={this._onNumberKeyPress}
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                      this.handleChange(e, "totalWeight", index, row);
                                    }}
                                    // value={row.totalWeight ? (+row.totalWeight).toFixed(3) : ""}
                                    value={row.totalWeight}
                                    onKeyDown={e => this.focusNextNOB(e, index, row)}
                                    onKeyUp={e => this.focusNextNOBPress(e, index, row)}
                                  />
                                  {/* {row.totalWeight.toFixed(3)} */}


                                </Typography>
                              </TableCell>
                              {
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ width: "5%", padding: " 6px 6px" }}
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {!row.fromDB && (
                                      <span
                                        style={{ marginRight: ".5em" }}

                                      >
                                        {row.mailingSchemeId &&
                                          row.destinationId &&
                                          row.noofBags >= 0 &&
                                          row.bagWeight >= 0 ? (
                                            <Tooltip title="Clone">
                                              <StyleIcon
                                                style={{
                                                  color: "#6c6b6b",
                                                  cursor: "pointer",
                                                }}
                                                onClick={(e) =>
                                                  this.onCloneClicked(e, row, index)
                                                }
                                              />
                                            </Tooltip>
                                          ) : <StyleIcon
                                            style={{
                                              color: "#6c6b6b",
                                              opacity: ".5"
                                            }}
                                          />}
                                      </span>
                                    )}
                                    {
                                      <Tooltip title="Remove">
                                        {row.canDelete ? <DeleteIcon
                                          style={{
                                            cursor: "pointer",
                                            color: "#6c6b6b",
                                          }}
                                          onClick={(e) =>
                                            this.onDeleteClicked(e, index, row)
                                          }
                                        /> : <DeleteIcon
                                            style={{
                                              color: "#6c6b6b",
                                              opacity: ".5"
                                            }}
                                          />}
                                      </Tooltip>
                                    }
                                  </Typography>
                                </TableCell>
                              }
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : (
                        <TableBody>
                          <TableRow
                            style={{
                              borderRight: "1px solid #eaeaea",
                              borderLeft: "1px solid #eaeaea",
                            }}
                          >
                            <TableCell
                              colSpan="7"
                              style={{ textAlign: "center" }}
                            >
                              No data found
                          </TableCell>
                          </TableRow>
                        </TableBody>
                      )
                  ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan="9">
                            <LinearProgress />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                </Table>
              </div>
            </div>
            <Table>

            </Table>
            <div><MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                // disablePast
                //required
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                name="lodgementDate"
                style={{ marginLeft: "2px" }}
                id="lodgementDate"
                label="Lodgement Date"
                value={this.state.lodgementDate}
                minDate={new Date("2000-01-01")}
                onChange={(e) =>
                  this.handleDateChange(e, "lodgementDate")
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider></div>
            <Divider style={{ marginTop: "25px" }} />

            <div className="button-wrapper">
              <div>
                {newList.length !== list.length ? (
                  <Button
                    disabled
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                  >
                    CANCEL
                  </Button>
                ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.goBack()}
                    >
                      CANCEL
                    </Button>
                  )}
              </div>
              {
                <div
                  style={{
                    marginLeft: "1em",
                  }}
                >
                  {
                    <Button
                      // disabled={
                      //   this.state.list.length === 0 ||
                      //   (this.state.list.length > 0
                      //     ? this.state.list[0]["noofBags"] <= 0
                      //     : 1) ||
                      //   (this.state.list.length > 0
                      //     ? this.state.list[0]["bagWeight"] <= 0
                      //     : 1) ||
                      //   !this.state.mailingScheme ||
                      //   (this.state.mailingScheme
                      //     ? Object.keys(this.state.mailingScheme).length === 0
                      //     : 1) ||
                      //   !this.state.destination ||
                      //   (this.state.destination
                      //     ? Object.keys(this.state.destination).length === 0
                      //     : 1)
                      //     ? true
                      //     : false
                      // }
                      disabled={this.state.disableSubmitButton || !this.state.lodgementDate || this.state.lodgementDate == "Invalid Date" || list.length === 0}
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.handleSubmit(e)}
                    >
                      Save and Recalculate
                    </Button>
                  }
                </div>
              }
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
//export default withRouter();
export default withRouter(withStyles(addDispatchStyles)(DocketBagging));
