import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles, addStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import { PrintIcon } from "../../../shared/customIcons";
import ReportViewer from "../../app/ReportViewer";
import {
  Divider,
  TableRow,
  TablePagination,
  TableCell,
  LinearProgress,
  Typography,
  TextField,
  Table,
  TableHead,
  TableSortLabel,
  TableBody,
  withStyles,
} from "@material-ui/core";
import { getStockIndentData } from "./action";
import { StateContext } from "../../../shared/globalState";
import { printDetails } from "../../../shared/tableCommonFunctions";
import PropTypes from "prop-types";
import { stableSort, getSorting } from "../../../shared/tableCommonFunctions";
import clone from "clone";
import StatusText from "../../../shared/statusText";
import { postStockIndent } from "./action";

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  let acnArr = props.roles.filter((u) => u.subMenuName === "Stock Indent");
  let acnObj = acnArr[0];

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                style={{}}
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

function createData(
  stockDescription,
  noOfPts,
  totalPackage,
  noOfCopies,
  totalQuantity,
  weightPerCortanBox,
  netWeight
) {
  return {
    stockDescription,
    noOfPts,
    totalPackage,
    noOfCopies,
    totalQuantity,
    weightPerCortanBox,
    netWeight,
  };
}
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells &&
          headCells.map((headCell, i) => {
            return (
              headCell.show === true && (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "default"}
                  // sortDirection={orderBy === headCell.code ? order : false}
                  className={
                    i !== headCells.length - 1 ? "headcells" : "headcell"
                  }
                  style={{
                    borderLeft: "1px sold #000",
                    width: `${headCell.width}`,
                  }}
                >
                  {/* <TableSortLabel 
                  // active={orderBy === headCell.id}
                  // direction={order}
                  // onClick={createSortHandler(headCell.code)}
                  // >*/}
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                  {/* </TableSortLabel> */}
                </TableCell>
              )
            );
          })}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

class ViewStockIndent extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      indentCode: "",
      productionLineID: "",
      productionLineName: "",
      requestedBy: "",
      requestedDate: "",
      workOrderNumber: "",
      orderTitle: "",
      jobGroupNumber: "",
      status: "",
      statusName: "",
      completedBy: "",
      completedDate: "",
      remarks: "",

      page: 0,
      order: "desc",
      orderBy: "modifiedDate",
      list: [],
      rowsPerPage: 100,
      headCells: [
        {
          id: 1,
          code: "stockCode",
          numeric: false,
          disablePadding: true,
          label: "Stock Code",
          width: "auto",
          show: true,
        },
        {
          id: 2,
          code: "stockName",
          numeric: false,
          disablePadding: false,
          label: "Stock Name",
          width: "auto",
          show: true,
        },
        {
          id: 3,
          code: "stockType",
          numeric: false,
          disablePadding: false,
          label: "Stock Type",
          width: "auto",
          align: "center",
          show: true,
        },

        {
          id: 4,
          code: "isConsumable",
          numeric: false,
          disablePadding: false,
          label: "Is Consumable",
          width: "auto",
          show: true,
        },
        {
          id: 5,
          code: "indentQty",
          numeric: false,
          disablePadding: false,
          label: "Indent Qty",
          width: "auto",
          show: true,
        },
        {
          id: 6,
          code: "stockOutQty",
          numeric: false,
          disablePadding: false,
          label: "StockOut Qty",
          width: "auto",
          show: true,
        },
        {
          id: 7,
          code: "outstandingQty",
          numeric: false,
          disablePadding: false,
          label: "Outstanding Qty",
          width: "auto",
          show: true,
        },

        // {
        //   id: 6,
        //   code: "stockCategoryID",
        //   numeric: false,
        //   disablePadding: false,
        //   label: "StockCategory ID",
        //   width: "auto",
        //   show: true
        // },
      ],
      showPopup: false,
    };
  }

  handleShowPopup = () => {
    this.setState({
      showPopup: true,
    });
  };

  handleHidePopup = () => {
    this.setState({
      showPopup: false,
    });
  };

  componentDidMount() {
    const dispatch = this.context[1];
    let StockIndentID = this.props.history.location.pathname.split(
      "/view_StockIndent/"
    )[1];
    let newList = clone(this.state.list);

    this.props.handleSettingHeader("Stock Indent");
    this.props.setSelectedList(
      "genericSystemOpen",
      "StockIndent",
      "warehouseManagementOpen"
    );
    // statusName === "Pending"
    getStockIndentData(StockIndentID).then((r) => {
      console.log(r)
      this.setState({
        indentCode: r && r[0].indentCode,
        productionLineID: r && r[0].productionLineID,
        productionLineName: r && r[0].productionLineName,
        requestedBy: r && r[0].requestedBy,
        requestedDate: r && r[0].requestedDate,
        workOrderNumber: r && r[0].workOrderNumber,
        orderTitle: r && r[0].orderTitle,
        jobGroupNumber: r && r[0].jobGroupNumber,
        status: r && r[0].status,
        statusName: r && r[0].statusName,
        completedBy: r && r[0].completedBy,
        completedDate: r && r[0].completedDate,
        remarks: r && r[0] && r[0].statusName === "Pending" ? r[0].productionLineName : r[0].remarks,
      });
      r &&
        r[0] &&
        r[0].stockIndentDetails.map((l) => {
          newList.push({
            stockCode: l.stockCode,
            indentQty: l.indentQty,
            stockOutQty: l.stockOutQty,
            outstandingQty: l.outstandingQty,
            stockName: l.stockName,
            stockCategoryID: l.stockCategoryID,
            stockType: l.stockType,
            isConsumable: l.isConsumable,
          });
        });

      this.setState({
        list: newList,
        subordinate: newList,
      });
    });
  }
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleChange = (event) => {
    this.setState({ remarks: event.target.value });
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleIndentCompleted = async () => {
    let StockIndentID = this.props.history.location.pathname.split(
      "/view_StockIndent/"
    )[1];
    let reqBody = {
      remarks: this.state.remarks,
      completedBy: this.state.completedBy,
    };
    await postStockIndent(StockIndentID, reqBody, this.props.history);
  };

  printIframe = (id) => {
    const iframe = document.frames
      ? window.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe.contentDocument;

    if (iframeWindow.document) {
      iframeWindow = iframeWindow.document;
      // iframeWindow.body.style.backgroundColor = "red";

      //   iframe.focus();
      iframeWindow.print();
    }

    return false;
  };

  render() {
    let StockIndentID = this.props.match.params.StockIndentID;
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selectedStockIndentID={StockIndentID}
            roles={this.props.roles}
          />
          <Divider />

          <div className="view-page-container" id="printable">
            <div className="view-page-headings">Stock Indent Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Indent Number</div>
                <div className="view-page-value">{this.state.indentCode}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Production Line</div>
                <div className="view-page-value">
                  {this.state.productionLineName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Requested By</div>
                <div className="view-page-value">{this.state.requestedBy}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Requested Date</div>
                <div className="view-page-value">
                  {moment(this.state.requestedDate).format(
                    "DD/MM/YYYY  HH:mm:ss"
                  )}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">WorkOrder Number</div>
                <div className="view-page-value">
                  {this.state.workOrderNumber}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Order Title</div>
                <div className="view-page-value">{this.state.orderTitle}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Job Group No</div>
                <div className="view-page-value">
                  {this.state.jobGroupNumber}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Status</div>
                <div className="view-page-value">
                  {this.state.statusName == "Completed" ? (
                    <div color={"green"}>Completed</div>
                  ) : this.state.statusName == "Pending" ? (
                    <div color={"#0091ff"}>Pending</div>
                  ) : this.state.statusName == "Cancelled" ? (
                    <div color={"red"}>Cancelled</div>
                  ) : this.state.statusName == "OnHold" ? (
                    <div color={"gray"}>On Hold</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Completed By</div>
                <div className="view-page-value">{this.state.completedBy}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Completed Date</div>
                <div className="view-page-value">
                  {moment(this.state.currentDate).format(
                    "DD/MM/YYYY  HH:mm:ss"
                  )}
                </div>
              </div>
              {/* <div className="view-page-contents-sub-container">
                <div className="view-page-label">Current Date</div>
                <div className="view-page-value">
                  {moment(this.state.currentDate).format(
                    "DD/MM/YYYY  HH:mm:ss"
                  )}
                </div>
              </div> */}
            </div>

            <div className="view-page-headings">Collection Items</div>
            <div
              // style={{ maxHeight: "400px", border: "1px solid #eaeaea" }}
              className="view-page-contents-container1"
            >
              <div
                style={
                  !this.props.clearOpenList
                    ? {
                      overflow: "auto",
                      border: "1px solid #eaeaea",
                      // maxWidth: "calc(100vw - 8em)",
                      // maxHeight: "calc(100vh - 300px)",
                    }
                    : {
                      overflow: "auto",
                      border: "1px solid #eaeaea",
                      // maxWidth: "calc(100vw - 370px)",
                      // maxHeight: "calc(100vh - 300px)",
                    }
                }
              >
                <Table
                  aria-labelledby="tableTitle"
                  size="small"
                  stickyHeader
                  aria-label="sticky table"
                // style={{ width: "1800px", overflow: "auto" }}
                >
                  <EnhancedTableHead
                    order={this.state.order}
                    orderBy={this.state.orderBy}
                    // onRequestSort={this.handleRequestSort}
                    rowCount={this.state.list.length}
                    headCells={this.state.headCells}
                  />
                  <TableBody>
                    {this.state.list.length > 0 ? (
                      stableSort(
                        this.state.list,
                        getSorting(this.state.order, this.state.orderBy)
                      )
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              // hover
                              tabIndex={-1}
                              key={row.stockID}
                              style={{ cursor: "pointer" }}
                            >
                              {this.state.headCells[0].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockCode}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[1].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockName}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[2].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockType}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[3].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {/* {row.isConsumable} */}
                                    {row.isConsumable === true ? "Yes" : "No"}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[4].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  style={{
                                    maxWidth: "128px",
                                    fontFamily: "Roboto",
                                    fontSize: "14px",
                                    paddingLeft: "0.5em",
                                    borderRight: "1px solid #eaeaea",
                                    whiteSpace: "normal",
                                  }}
                                //className="table-content-cell"
                                >
                                  <Typography style={{ fontSize: "14px" }}>
                                    {row.indentQty}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[5].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockOutQty}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[6].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.outstandingQty}
                                  </Typography>
                                </TableCell>
                              )}

                              {/* {this.state.headCells[5].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockCategoryID}
                                  </Typography>
                                </TableCell>
                              )} */}
                            </TableRow>
                          );
                        })
                    ) : (
                        <TableRow>
                          {this.state.loading ? (
                            <TableCell
                              colSpan={8}
                              style={{ textAlign: "center" }}
                            >
                              <LinearProgress />
                            </TableCell>
                          ) : (
                              <TableCell
                                colSpan={8}
                                style={{ textAlign: "center" }}
                              >
                                No result found
                              </TableCell>
                            )}
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </div>
            </div>
            {this.state.statusName === "Pending" ? (
              <div style={{ display: "flex", alignItems: "baseLine" }}>
                {/* <div style={{ marginRight: "20px" }}>Remarks:</div> */}
                <TextField
                  // required
                  multiline={true}
                  rows={1}
                  rowsMax={4}
                  label="Remarks"
                  name="remarks"
                  inputProps={{
                    maxLength: 200,
                  }}
                  margin="normal"
                  value={this.state.remarks}
                  onChange={this.handleChange}
                />
              </div>
            ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseLine",
                    marginTop: "20px",
                  }}
                >
                  {" "}
                  <div style={{ marginRight: "20px" }}>Remarks:</div>
                  {this.state.remarks}
                </div>
              )}
            <Divider style={{ marginTop: "25px" }} />

            {/* <div style={{ width: "calc(100vw)", height: "100vh" }}> */}
            {/* <iframe
              id="printReport"
              style={{
                width: "calc(100vw)",
                height: "100vh",
                display: "none"
              }}
              // src="http://10.10.11.127//ReportTest//Report//collectionNoteManifest"

              src={"http://10.10.11.127/ReportTest/Reports/SSRSReportViewer.aspx?ReportFilePath=/PGS_Report_QA/Indent&IsDirectPrint=True&ReportParam=IndentHeaderID&Param1="+  `${row && row.mbaHeaderID}`} 
              // src="http://103.254.230.180//SIT_PGSReport//Reports//SSRSReportViewer.aspx?ReportFilePath=Indent&IsDirectPrint=True&ReportParam=IndentHeaderID&Param1=19"
              //src="http://103.254.230.180//SIT_PGSReport//Reports//SSRSReportViewer.aspx?ReportFilePath=Indent&IsDirectPrint=True&ReportParam=IndentHeaderID&Param1=19"
            /> */}
            {/* </div> */}
          </div>

          <div className="view-button-wrapper">
            <div style={{ margin: "0 1em 0 1em" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.props.history.push("/StockIndent")}
              >
                CANCEL
              </Button>
            </div>
            {this.state.statusName === "Pending" || this.state.statusName === "OnHold" ? (
              <div style={{ marginRight: "1em" }}>
                {/* {!this.state.remarks ? (
                  <Button
                    disabled
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={() => this.handleIndentCompleted()}
                  >
                    Indent Completed
                  </Button>
                ) :
                  ( */}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={this.state.statusName === "OnHold"}
                  style={{ boxShadow: "none" }}
                  onClick={() => this.handleIndentCompleted()}
                >
                  Indent Completed
                    </Button>
                {/* )
                } */}
              </div>
            ) : null}
            {/* {this.state.statusName === "Pending" && ( */}
            <div style={{ marginRight: "1em" }}>
              <Button
                // disabled={!this.state.remarks}
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={() => this.handleShowPopup()}
              >
                Print Indent Form
                </Button>
            </div>
            {/* )} */}
            {this.state.showPopup && (
              <ReportViewer
                url={
                  `${window.localStorage.ReportURLByID}` +
                  "Indent&IsDirectPrint=True&ReportParam=IndentHeaderID&Param1=" +
                  `${
                  this.props.history.location.pathname.split(
                    "/view_StockIndent/"
                  )[1]
                  }`
                }
                showPopup={this.state.showPopup}
                title={"Stock Indent"}
                onClose={() => this.handleHidePopup()}
                handleHidePopup={() => this.handleHidePopup()}
              />
            )}
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(ViewStockIndent));
