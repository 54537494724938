import React, { Component } from "react";

class CollectionNoteManifest extends Component {
  state = {};
  componentDidMount() {
    this.props.handleSettingHeader("Collection Note Manifest Report");
    this.props.setSelectedList("masterDataOpen", "collectionNoteManifest");
  }
  render() {
    // alert("collectionnote");
    //const url = "http://172.22.43.196/report/reportviewer?Reportname=ReorderLevel";
    return (
      <div>
      <iframe
        style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
        src={`${window.localStorage.ReportURLByID}` +"collectionNoteManifest"}
        />
      </div>
    );
  }
}

// "http://10.10.11.131:81/ReportServer/Pages/ReportViewer.aspx?%2fPGS_Report_QA%2fReorderLevel&rs:Command=Render&rs:Embed=true"

export default CollectionNoteManifest;
