import React, { Component } from "react";
export default class Footer extends Component {
  render() {
    return (
      <div
        style={
          !localStorage.token ||
          this.props.history.location.pathname === "/ChangePassword"
            ? {
                textAlign: "center",
                fontSize: "12px",
                padding: "7px 0",
                width: "100%",
                right: "0px",
                color: "#fff",
                position: "absolute",
                width: "100%",
                bottom: 0
              }
            : {
                textAlign: "right",
                fontSize: "12px",
                marginBottom: "1em",
                // marginTop: "-45px",
                width: "auto",
                padding: "0",
                color: "#000",
                position: "relative",
                right: "20px"
              }
        }
      >
        {/* <button
          //primaryColor={theme.primary}
          style={{ backgroundColor: theme.primary }}
          onClick={() =>
            dispatch({
              type: "getDeptList",
              deptList: list
            })
          }
        >
          Make me blue!
        </button> */}
        <span>© 2020 Singapore Post Limited. All Rights Reserved.</span>
      </div>
    );
  }
}
