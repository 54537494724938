import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {Autocomplete} from "@material-ui/lab" ;
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { EditIcon } from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import { stableSort, getSorting } from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import types from "../userAccessTypes";
import { StateContext } from "../../../shared/globalState";
import {
  getRoleActionList,
  deleteRoleAction,
  getRoleActionByRoleId,
  updateRoleActionByRoleID,
} from "../userAccessAction";

const columns = [
  {
    name: "Created By",
    id: 14,
  },
  {
    name: "Created Date",
    id: 15,
  },
  {
    name: "Modified By",
    id: 16,
  },
  {
    name: "Modified Date",
    id: 17,
    show: false,
  },
  {
    name: "Active",
    id: 18,
    show: false,
  },
];

function createData(
  roleMappingID,
  roleID,
  superMenuID,
  menuID,
  subMenuID,
  roleName,
  superMenuName,
  menuName,
  subMenuName,
  viewAction,
  addAction,
  editAction,
  deleteAction,
  advanceSearchAction,
  exportAction,
  approveAction,
  reportAction,
  description,
  printAction,
  isReport,
  isActive
) {
  return {
    roleMappingID,
    roleID,
    superMenuID,
    menuID,
    subMenuID,
    roleName,
    superMenuName,
    menuName,
    subMenuName,
    viewAction,
    addAction,
    editAction,
    deleteAction,
    advanceSearchAction,
    exportAction,
    approveAction,
    reportAction,
    description,
    printAction,
    isReport,
    isActive,
  };
}
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, selectedRoleActionId } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          ></div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Tooltip title="Edit">
              <Link to={`/edit_roleAction/${selectedRoleActionId}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
            {/* <Tooltip title="Download">
            <IconButton aria-label="download" className={classes.iconHover} onClick={e => pdfDownloadDetails("sub_menu_details.pdf")}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Print" style={{padding: "5px",paddingTop: "12px"}}>
            <IconButton aria-label="print" style={{    
}} className={classes.iconHover}  onClick={e => printDetails()}>
              <PrintIcon />
            </IconButton>
          </Tooltip> */}
          </div>
        )}
      </div>
    </div>
  );
  // return (
  //   <div
  //     className={classes.root}
  //   >
  //     <div
  //       style={{
  //         display: "flex",
  //         width: "100%"
  //       }}
  //     >

  //     </div>
  //   </div>
  // );
  // if(formValid){
  //   setFormvalid();
  // }
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

class ViewRoleAction extends React.Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      role: {
        roleMappingID: "",
        roleID: "",
        superMenuID: "",
        superMenuName: "",
        roleName: "",
        menuID: "",
        menuName: "",
        subMenuID: "",
        subMenuName: "",
        viewAction: false,
        addAction: false,
        editAction: false,
        deleteAction: false,
        advanceSearchAction: false,
        exportAction: false,
        approveAction: false,
        reportAction: false,
        description: "",
        printAction: false,
        isReport: false,
        isActive: false,
        modifiedBy: 1,
      },
      deletePopupOpen: false,
      order: "desc",
      orderBy: "modifiedDate",
      selected: [],
      page: 0,
      rowsPerPage: 100,
      roleActionList: [],
      refreshData: false,
      selectedRoleAction: "",
      selectedRoleActionId: 1,
      selectedRoleId: 0,
      selectedDDL: 0,
      loading: false,
      searchPopupOpen: false,
      roleActionSearchValues: {},
      searchClicked: false,
      selectedShowColumns: [],
      headCells: [
        {
          id: 1,
          code: "superMenuName",
          numeric: false,
          disablePadding: true,
          label: "Super Menu Name",
          show: true,
        },
        {
          id: 2,
          code: "menuName",
          numeric: false,
          disablePadding: true,
          label: "Menu Name",
          show: true,
        },
        {
          id: 3,
          code: "subMenuName",
          numeric: false,
          disablePadding: true,
          label: "Sub Menu Name",
          show: true,
        },
        {
          id: 4,
          code: "viewAction",
          numeric: false,
          disablePadding: false,
          label: "View",
          show: true,
        },
        {
          id: 5,
          code: "addAction",
          numeric: false,
          disablePadding: false,
          label: "Add",
          show: true,
        },
        {
          id: 6,
          code: "editAction",
          numeric: false,
          disablePadding: false,
          label: "Edit",
          show: true,
        },
        {
          id: 7,
          code: "deleteAction",
          numeric: false,
          disablePadding: false,
          label: "Delete",
          show: true,
        },
        {
          id: 8,
          code: "advanceSearchAction",
          numeric: false,
          disablePadding: false,
          label: "Adv Search",
          show: true,
        },
        {
          id: 9,
          code: "exportAction",
          numeric: false,
          disablePadding: false,
          label: "Export",
          show: true,
        },
        {
          id: 10,
          code: "approveAction",
          numeric: false,
          disablePadding: false,
          label: "Approve",
          show: true,
        },
        {
          id: 11,
          code: "reportAction",
          numeric: false,
          disablePadding: false,
          label: "Report",
          show: false,
        },
        {
          id: 12,
          code: "printAction",
          numeric: false,
          disablePadding: false,
          label: "Print",
          show: true,
        },
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event) => {
    const { role } = this.state;

    let labelName = event.target.name;
    let index = parseInt(labelName.split("-")[1]);
    let fieldName = labelName.split("-")[0];
    role.map((c) =>
      index === c.roleMappingID
        ? ((c[fieldName] = event.target.checked), (c.modifiedBy = 1))
        : c
    );
    this.setState({ role });
  };

  componentDidMount() {
    this.props.handleSettingHeader("Role Action");
    this.props.setSelectedList("adminSettingOpen", "roleAction");
    const dispatch = this.context[1];
    let roleId = this.props.match.params.roleId;
    let roleActionList = this.state.roleActionList;
    let selectedDDL = this.state.selectedDDL;
    let selectedRoleId = roleId;
    this.setState({ selectedRoleActionId: roleId });

    getRoleActionByRoleId(roleId).then((r) => {
      dispatch({
        type: "getRoleActionByRoleId",
        newStore: r.data,
      });
      this.getAllRoleActionsByRole(r.data);
    });
    getRoleActionList().then((r) => {
      dispatch({
        type: "getRoleActionList",
        newStore: r.data,
      });
      //this.setState({roleActionList: r.data});
      this.getAllRoles(r.data);
    });
    let loop = 0;
    roleActionList.map((option) =>
      option.key === selectedRoleId ? (selectedDDL = loop) : (loop = loop + 1)
    );
    this.setState({ selectedDDL, selectedRoleId });
  }

  getAllRoles = (list) => {
    let roleActionList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        roleActionList.findIndex((p) => p.key === c.roleID) > -1
          ? roleActionList
          : roleActionList.push({ key: c.roleID, value: c.roleName })
      );

    this.setState({
      roleActionList: roleActionList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      ),
    });
  };
  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  getAllRoleActionsByRole = (role) => {
    let list = role;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.roleMappingID,
            d.roleID,
            d.superMenuID,
            d.menuID,
            d.subMenuID,
            d.roleName,
            d.superMenuName,
            d.menuName,
            d.subMenuName,
            d.viewAction,
            d.addAction,
            d.editAction,
            d.deleteAction,
            d.advanceSearchAction,
            d.exportAction,
            d.approveAction,
            d.reportAction,
            d.description,
            d.printAction,
            d.isReport,
            d.isActive,
            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate
          )
        );
      });
    this.setState({ role: rows, loading: false });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.role && store.role.length > 0)
      if (this.state.role.length !== store.role.length) {
        this.getAllRoleActionsByRole(store.role);
      }
    return true;
  }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { role } = this.state;
    if (event.target.checked) {
      const newSelecteds = role.map((n) => n.roleMappingID);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, roleActionID, roleAction) {}

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchRoleAction = (handleSearchRoleAction) => {};

  handleDeleteRoleAction = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteRoleAction(modifiedBy, deletionIds).then((r) => {
      getRoleActionList().then((r) => {
        dispatch({
          type: types.ROLEACTION_LIST,
          newStore: r.data,
        });
        this.getAllRoleActions(r.data);
      });
      this.props.history.push("/RoleAction");
      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };

  handleRoleChange = (event, values) => {
    let selectedRoleId = this.state.selectedRoleId;
    if (values === null) {
    } else {
      selectedRoleId = values.key;
    }
    this.setState({ selectedRoleId });
  };

  handleSubmit(e) {
    e.preventDefault();
    let roleId = this.props.match.params.roleId;
    const { role } = this.state;
    updateRoleActionByRoleID(roleId, role).then((r) => {
      this.props.history.push("/RoleAction");
    });
  }

  handleRoleSubmit = (e) => {
    e.preventDefault();
    const dispatch = this.context[1];
    const { selectedRoleId, roleActionList } = this.state;
    let selectedRole = 0;
    getRoleActionByRoleId(selectedRoleId).then((r) => {
      getRoleActionByRoleId(selectedRoleId).then((r) => {
        dispatch({
          type: "getRoleActionByRoleId",
          newStore: r.data,
        });
        this.getAllRoleActionsByRole(r.data);
      });
      this.props.history.push(`/view_roleAction/${selectedRoleId}`);
    });

    let loop = 0;
    roleActionList.map((option) =>
      option.key === this.state.selectedRoleId
        ? (selectedRole = loop)
        : (loop = loop + 1)
    );

    this.setState({ selectedDDL: selectedRole });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      roleActionList,
      role,
      selectedDDL,
      selectedRoleId,
    } = this.state;
    const isSelected = (roleMappingID) =>
      selected.indexOf(roleMappingID) !== -1;
    let loop = 0;
    roleActionList.map((option) =>
      option.key === parseInt(selectedRoleId)
        ? (selectedDDL = loop)
        : (loop = loop + 1)
    );

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            selectedRoleActionId={selectedRoleId}
          ></EnhancedTableToolbar>
          <Divider />
          <div
            className="pop-up-header"
            style={{
              marginLeft: "1em",
              paddingTop: ".7em",
              marginBottom: ".7em",
            }}
          >
            Role Access Mapping
          </div>
          <Divider />
          <div>
            <div style={{ marginTop: "-15px" }}>
              <form onSubmit={this.handleRoleSubmit}>
                <div style={{ marginLeft: "13px", marginBottom: "20px" }}>
                  <table>
                    <tr>
                      <td>
                        {roleActionList.length > 0 && (
                          <Autocomplete
                            disabled
                            autoComplete
                            includeInputInList
                            required
                            id="role"
                            name="role"
                            hintText="role"
                            options={roleActionList}
                            value={roleActionList[selectedDDL]}
                            style={{ marginTop: "20px", width: "300px" }}
                            onChange={this.handleRoleChange}
                            getOptionLabel={(option) => option.value}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Role"
                                name="roleName"
                                id="roleName"
                                margin="normal"
                                style={{ marginTop: "20px", width: "300px" }}
                                width="300px"
                              />
                            )}
                          />
                        )}
                      </td>
                      <td>
                        <div style={{ marginLeft: "1em" }}></div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div
                  style={{ width: "100%", maxHeight: "calc(100vh - 250px)" }}
                >
                  <div
                    style={
                      !this.props.clearOpenList
                        ? {
                            overflow: "auto",
                            maxWidth: "calc(100vw - 8em)",
                            maxHeight: "calc(100vh - 300px)",
                            margin: "0 auto",
                          }
                        : {
                            overflow: "auto",
                            maxWidth: "calc(100vw - 330px)",
                            maxHeight: "calc(100vh - 300px)",
                            margin: "0 auto",
                          }
                    }
                  >
                    <Table
                      stickyHeader
                      aria-labelledby="tableTitle"
                      size="small"
                      aria-label="enhanced table"
                      style={{
                        borderLeft: "1px solid  rgba(224, 224, 224, 1)",
                      }}
                    >
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                        onRequestSort={this.handleRequestSort}
                        rowCount={role.length}
                        headCells={this.state.headCells}
                      />
                      <TableBody style={{ overflow: "hidden" }}>
                        {role.length > 0 ? (
                          stableSort(role, getSorting(order, orderBy))
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              const isItemSelected = isSelected(
                                row.roleMappingID
                              );
                              return (
                                <TableRow
                                  hover
                                  onClick={(event) =>
                                    this.handleClick(
                                      event,
                                      row.roleMappingID,
                                      row.roleActionName
                                    )
                                  }
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.roleActionID}
                                  selected={isItemSelected}
                                  style={{ cursor: "pointer" }}
                                >
                                  {/* <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell> */}
                                  {this.state.headCells[0].show && (
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="default"
                                      className="table-content-cell"
                                    >
                                      {row.superMenuName}
                                    </TableCell>
                                  )}
                                  {this.state.headCells[1].show && (
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="default"
                                      className="table-content-cell"
                                    >
                                      {row.menuName && row.menuName.length > 16 ? (
                                        <Tooltip title={row.menuName} aria-label={row.menuName}>
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.menuName}
                                          </Typography>
                                        </Tooltip>
                                        ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.menuName}
                                        </Typography>
                                      )}
                                    </TableCell>
                                  )}
                                  {this.state.headCells[2].show && (
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="default"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.subMenuName}
                                      </Typography>
                                    </TableCell>
                                  )}
                                  {this.state.headCells[3].show && (
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="default"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.viewAction ? "Yes" : "No"}
                                      </Typography>
                                    </TableCell>
                                  )}
                                  {this.state.headCells[4].show && (
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="default"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.addAction ? "Yes" : "No"}
                                      </Typography>
                                    </TableCell>
                                  )}
                                  {this.state.headCells[5].show && (
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="default"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.editAction ? "Yes" : "No"}
                                      </Typography>
                                    </TableCell>
                                  )}
                                  {this.state.headCells[6].show && (
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="default"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.deleteAction ? "Yes" : "No"}
                                      </Typography>
                                    </TableCell>
                                  )}
                                  {this.state.headCells[7].show && (
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="default"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.advanceSearchAction ? "Yes" : "No"}
                                      </Typography>
                                    </TableCell>
                                  )}
                                  {this.state.headCells[8].show && (
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="default"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.exportAction ? "Yes" : "No"}
                                      </Typography>
                                    </TableCell>
                                  )}
                                  {this.state.headCells[9].show && (
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="default"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.approveAction ? "Yes" : "No"}
                                      </Typography>
                                    </TableCell>
                                  )}
                                  {this.state.headCells[10].show && (
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="default"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.reportAction ? "Yes" : "No"}
                                      </Typography>
                                    </TableCell>
                                  )}
                                  {this.state.headCells[11].show && (
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="default"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.printAction ? "Yes" : "No"}
                                      </Typography>
                                    </TableCell>
                                  )}
                                  {/* {this.state.headCells[12].show && (
                                    <TableCell
                                      padding="checkbox"
                                      className="table-content-cell"
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name={
                                              "isReport-" + row.roleMappingID
                                            }
                                            component="th"
                                            scope="row"
                                            color="primary"
                                            checked={row.isReport}
                                            onChange={this.handleChange}
                                            value={row.isReport}
                                          />
                                        }
                                      />
                                    </TableCell>
                                  )}
                                  {this.state.headCells[13].show && (
                                    <TableCell
                                      padding="checkbox"
                                      className="table-content-cell"
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name={
                                              "isActive-" + row.roleMappingID
                                            }
                                            component="th"
                                            scope="row"
                                            color="primary"
                                            checked={row.isActive}
                                            onChange={this.handleChange}
                                            value={row.isActive}
                                          />
                                        }
                                      />
                                    </TableCell>
                                  )} */}
                                </TableRow>
                              );
                            })
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={8}
                              style={{ textAlign: "center" }}
                            >
                              No result found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                </div>
                <TablePagination
                  rowsPerPageOptions={[100, 200, 300]}
                  component="div"
                  count={role.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
                <Divider />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none", marginLeft: "1em" }}
                      onClick={(e) => this.props.history.push("/roleAction")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  {/* <div style={{ marginLeft: "1em" }}>
                    <Button
                      onClick={this.handleSubmit}
                      //disabled={!this.state.formValid}
                      autoFocus
                      variant="contained"
                      className={
                        "enabled-button "
                        // !this.state.formValid
                        //   ? "disabled-button"
                        //   : "enabled-button "
                      }
                    >
                      SUBMIT
                    </Button>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

export default withRouter(ViewRoleAction);
