import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";

import moment from "moment";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { StateContext } from "../../../shared/globalState";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  getjobScheduleProductionDropDownList,
  getCustomerOrderStatus,
} from "../../masters/masterActions";
import { resourceValidation } from "../../../shared/resource";
import StatusText from "../../../shared/statusText";
import {
  stableSort,
  getSorting,
  DialogActions,
} from "../../../shared/tableCommonFunctions";
import { Link } from "react-router-dom";
import { ExportCSV } from "../../../shared/ExportCSV";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import {
  SearchIcon,
  ViewColumnIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";

import { Autocomplete } from "@material-ui/lab";
import {
  getFliteredStockIndentList,
  getStockIndentList,
  getItemMasterList,
  getCustomerFromItemMaster,
  deleteStockIndent,
  getStockIndentsFilterList,
} from "./action";
import Validator from "../../../shared/validator";
import { getjobScheduleStatusDropDownList } from "../jobScheduler/action";
const validateNotRequired100 = new Validator("maxlength100AllAllow");
const columns = [
  {
    name: "Completed By",
    id: 8,
  },
  {
    name: "Completed Date",
    id: 9,
  },
  {
    name: "Requested By",
    id: 10,
  },
  {
    name: "Requested Date",
    id: 11,
  },
];
const validateCode = new Validator("maxlengthWithNumber4");
function createData(
  indentCode,
  customerCode,
  productionLineName,
  requestedBy,
  requestedDate,
  workOrderNumber,
  orderTitle,
  jobGroupNumber,
  statusName,
  completedBy,
  completedDate,
  indentHeaderID
) {
  return {
    indentCode,
    customerCode,
    productionLineName,
    requestedBy,
    requestedDate,
    workOrderNumber,
    orderTitle,
    jobGroupNumber,
    statusName,
    completedBy,
    completedDate,
    indentHeaderID,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    handleDeleteItemMaster,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          {/* <FormControlLabel
            control={
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all StockIndent" }}
              />
            }
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.code}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    indentCode: "",
    productionLineID: "",
    requestedFrom: "",
    requestedTo: "",
    workOrderNumber: "",
    orderTitle: "",
    jobGroupNumber: "",
    statusName: "",
    stockIndentDateFrom: null,
    stockIndentDateTo: null,
  });
  let acnArr = props.roles.filter((u) => u.subMenuName === "Stock Indent");
  let acnObj = acnArr[0];
  const [formValid, setIsformValid] = useState(false);
  const [ImSearchValue, setValues] = useState({
    indentCode: "",
    productionLineID: "",
    requestedFrom: "",
    requestedTo: "",
    workOrderNumber: "",
    orderTitle: "",
    jobGroupNumber: "",
    statusName: "",
    stockIndentDateFrom: null,
    stockIndentDateTo: null,
  });

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  const classes = useToolbarStyles();
  const {
    numSelected,
    searchPopupOpen,
    exportDataValues,
    selectedStockIndentID,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchStockIndent,
    searchClicked,
    ItemMasterList,
    customerList,
    hendleVewColums,
    prodLineId,
    handleDeleteItemMaster,
    selected,
    deletePopupOpen,
    handleDeletePopupOpen,
    handleDeletePopupClose,
  } = props;

  // console.log(props);

  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const deleteItemMasterdata = () => {
    handleDeleteItemMaster();
  };
  const clearSearchValues = () => {
    let searchValues = ImSearchValue;

    searchValues.indentCode = "";
    searchValues.productionLineID = "";
    searchValues.requestedFrom = "";
    searchValues.requestedTo = "";
    searchValues.workOrderNumber = "";
    searchValues.orderTitle = "";
    searchValues.jobGroupNumber = "";
    searchValues.statusName = "";
    searchValues.stockIndentDateFrom = null;
    searchValues.stockIndentDateTo = null;
    searchValues = ImSearchValue;
  };

  const searchPopup = () => {
    console.log(ImSearchValue);
    handleSearchStockIndent(ImSearchValue);
    clearSearchValues();
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
  };

  const handleFromDateChange = (date) => {
    setValues((ImSearchValue) => ({
      ...ImSearchValue,
      stockIndentDateFrom: date && date.toLocaleDateString(),
    }));

    setIsformValid(true);
  };
  const handleToDateChange = (date) => {
    setValues((ImSearchValue) => ({
      ...ImSearchValue,
      stockIndentDateTo: date && date.toLocaleDateString(),
    }));
    setIsformValid(true);
  };

  const handleSearchInput = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;
    setValues((ImSearchValue) => ({
      ...ImSearchValue,
      [event.target.name]: event.target.value,
    }));
    switch (name) {
      case "indentCode":
        let indentCodeValid = false;
        if (value !== "") {
          indentCodeValid = validateNotRequired100(value);
          if (errors.workOrderNumber !== "") setIsformValid(false);
          else setIsformValid(indentCodeValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: indentCodeValid
              ? ""
              : resourceValidation.ValidName,
          }));
        }
        break;
      case "workOrderNumber":
        let workOrderNumberValid = false;
        if (value !== "") {
          workOrderNumberValid = validateCode(value);
          if (errors.workOrderNumber !== "") setIsformValid(false);
          else setIsformValid(workOrderNumberValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: workOrderNumberValid
              ? ""
              : resourceValidation.ValidName,
          }));
        }
        break;
      case "orderTitle":
        let orderTitleValid = false;
        if (value !== "") {
          orderTitleValid = validateCode(value);
          if (errors.processTypeName !== "") setIsformValid(false);
          else setIsformValid(orderTitleValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: orderTitleValid
              ? ""
              : resourceValidation.ValidName,
          }));
        }
        break;
      case "jobGroupNumber":
        let jobGroupNumberValid = false;
        if (value !== "") {
          jobGroupNumberValid = validateCode(value);
          if (errors.processTypeName !== "") setIsformValid(false);
          else setIsformValid(jobGroupNumberValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: jobGroupNumberValid
              ? ""
              : resourceValidation.ValidName,
          }));
        }
        break;
      default:
        break;
    }
  };

  const handleSearchProductionValuesChange = (event, values) => {
    event.persist();
    let value = event.target.value;
    const filtered = props.prodLineId.reduce(
      (a, o) => (o.value === values && a.push(o.id), a),
      []
    );
    let productionLineID = filtered.join(",");

    setValues((ImSearchValue) => ({
      ...ImSearchValue,
      productionLineID: productionLineID,
    }));
    if (value !== undefined) setIsformValid(true);
    else setIsformValid(false);
  };

  const handleSearchStatusValuesChange = (event, values) => {
    event.persist();
    let value = event.target.value;
    const filteredID = props.statusNameList.reduce(
      (a, o) => (o.text === values && a.push(o.id), a),
      []
    );
    let statusID = filteredID.join(",");

    const filteredStatus = props.statusNameList.reduce(
      (a, o) => (o.text === values && a.push(o.text), a),
      []
    );
    let status = filteredStatus.join(",");
    // console.log(statusID, "statusID");
    // console.log(filteredID, "filteredID");
    // console.log(status, "status");
    setValues((ImSearchValue) => ({
      ...ImSearchValue,
      statusName: status,
      statusId: statusID,
    }));
    if (value !== undefined) setIsformValid(true);
    else setIsformValid(false);
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      style={{ minHeight: "48px" }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                <React.Fragment>
                  {acnObj && acnObj.deleteAction && (
                    <Tooltip title="Delete">
                      <IconButton
                        aria-label="delete"
                        onClick={handleClickOpen}
                        disabled={
                          props.selectedStockIndent.statusName == "Completed" ||
                          props.selectedStockIndent.statusName == "Cancelled"
                        }
                      >
                        <DeleteIcon className={classes.iconHover} />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={deletePopupOpen}
                    maxWidth="lg"
                  >
                    <DialogTitle
                      id="customized-dialog-title"
                      onClose={handleClose}
                    >
                      Delete Stock Indent
                    </DialogTitle>
                    <DialogContent dividers>
                      <div style={{ minWidth: "400px" }}>
                        Are you sure you want to delete{" "} ?
                        <span style={{ fontWeight: "600" }}></span>
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        //
                        // color="primary"

                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        Close
                      </Button>
                      <Button
                        onClick={deleteItemMasterdata}
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                </React.Fragment>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_StockIndent/${selectedStockIndentID}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  <form onSubmit={searchPopup}>
                    {acnObj && acnObj.advanceSearchAction && (
                      <Tooltip title="Search">
                        <IconButton
                          aria-label="Search"
                          className={classes.iconHover}
                          onClick={handleSearchOpen}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      onClose={handleSearchPopupClose}
                      aria-labelledby="customized-dialog-title"
                      open={searchPopupOpen}
                      maxWidth="md"
                    >
                      <DialogTitle
                        id="customized-dialog-title"
                        onClose={handleSearchPopupClose}
                      >
                        Advanced Search
                  </DialogTitle>
                      <DialogContent dividers>
                        <React.Fragment>
                          <div
                            style={{
                              minWidth: "400px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <TextField
                              id="standard-required"
                              label="Indent Number"
                              name="indentCode"
                              margin="normal"
                              value={ImSearchValue.indentCode}
                              inputProps={{
                                maxLength: 50,
                              }}
                              style={{ margin: "0", width: "33.33%" }}
                              autoComplete="off"
                              onChange={handleSearchInput}
                            />
                            <TextField
                              id="standard-required"
                              label="WO Number"
                              name="workOrderNumber"
                              margin="normal"
                              value={ImSearchValue.workOrderNumber}
                              inputProps={{
                                maxLength: 50,
                              }}
                              style={{ margin: "0 15px", width: "33.33%" }}
                              autoComplete="off"
                              onChange={handleSearchInput}
                            />
                            <Autocomplete
                              autoComplete
                              includeInputInList
                              id="productionLineID"
                              name="productionLineID"
                              options={
                                props &&
                                props.ItemMasterList &&
                                props.ItemMasterList.map((val) => {
                                  return val.text;
                                })
                              }
                              onChange={handleSearchProductionValuesChange}
                              style={{ margin: "0", width: "33.33%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="ProductionLine"
                                  name="productionLineID"
                                  id="productionLineID"
                                  style={{ width: "100%" }}
                                  value={ImSearchValue.productionLineID}
                                />
                              )}
                            />
                          </div>
                          <div
                            style={{
                              minWidth: "400px",
                              display: "flex",
                              marginTop: "1em",
                              alignItems: "center",
                            }}
                          >
                            <TextField
                              id="standard-required"
                              label="Job Title"
                              name="orderTitle"
                              margin="normal"
                              value={ImSearchValue.orderTitle}
                              inputProps={{
                                maxLength: 50,
                              }}
                              style={{ margin: "0", width: "33.33%" }}
                              autoComplete="off"
                              onChange={handleSearchInput}
                            />
                            <TextField
                              id="standard-required"
                              label="Job Group Number"
                              name="jobGroupNumber"
                              margin="normal"
                              value={ImSearchValue.jobGroupNumber}
                              inputProps={{
                                maxLength: 50,
                              }}
                              style={{ margin: "0 15px", width: "33.33%" }}
                              autoComplete="off"
                              onChange={handleSearchInput}
                            />
                            <Autocomplete
                              autoComplete
                              includeInputInList
                              id="statusName-index"
                              name="statusName"
                              hintText="Status"
                              options={
                                props &&
                                props.statusNameList &&
                                props.statusNameList.map((val) => {
                                  return val.text;
                                })
                              }
                              style={{
                                width: "200px",
                              }}
                              onChange={handleSearchStatusValuesChange}
                              getOptionLabel={(option) => option}
                              value={ImSearchValue.statusName}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={"Status"}
                                  name="statusName"
                                  id="statusName"
                                  margin="normal"
                                  style={{ width: "100%", margin: "0" }}
                                  value={ImSearchValue.statusName}
                                />
                              )}
                            />
                          </div>
                          <div style={{ minWidth: "400px", display: "flex" }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                style={{ marginRight: "15px", width: "32%" }}
                                id="FromDate"
                                label="Completed From Date"
                                value={ImSearchValue.stockIndentDateFrom}
                                onChange={handleFromDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                autoOk
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                style={{ width: "32%" }}
                                id="ToDate"
                                label="Completed To Date"
                                value={ImSearchValue.stockIndentDateTo}
                                onChange={handleToDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </React.Fragment>
                      </DialogContent>
                      {console.log(ImSearchValue)}
                      <DialogActions>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          //
                          // color="primary"
                          onClick={handleSearchClose}
                        >
                          CANCEL
                    </Button>
                        {
                          <Button
                            disabled={
                              !(ImSearchValue.stockIndentDateFrom
                                ? ImSearchValue.stockIndentDateTo
                                : ImSearchValue.indentCode ||
                                ImSearchValue.workOrderNumber ||
                                ImSearchValue.productionLineID ||
                                ImSearchValue.orderTitle ||
                                ImSearchValue.jobGroupNumber ||
                                ImSearchValue.statusId)
                            }
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                            onClick={searchPopup}
                          >
                            SEARCH
                      </Button>
                        }
                      </DialogActions>
                    </Dialog>
                  </form>
                )}
              {/* <Tooltip title="Add">
              <IconButton
                aria-label="add"
                className={classes.iconHover}
                onClick={e => props.history.push("/add_StockIndent")}
              >
                <AddIcon />
              </IconButton>
            </Tooltip> */}
              {acnObj && acnObj.exportAction && (
                <ExportCSV
                  csvData={exportDataValues}
                  fileName={"StockIndent_Details"}
                />
              )}
              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                hendleVewColums={hendleVewColums}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };
  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class StockIndent extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "createdDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    refreshData: false,
    selectedStockIndent: "",
    StockindentList: [],
    selectedStockIndentID: 1,
    loading: false,
    searchPopupOpen: false,
    //SearchValues: {},
    searchClicked: false,
    ItemMasterList: [],
    ImSearchValue: {},
    selectedShowColumns: [],
    exportDataValues: [],
    customerList: [],
    headCells: [
      {
        code: "indentCode",
        id: 1,
        numeric: false,
        disablePadding: false,
        label: "Indent Number",
        show: true,
      },
      {
        code: "customerCode",
        id: 2,
        numeric: false,
        disablePadding: false,
        label: "Customer",
        show: true,
      },
      {
        code: "productionLineName",
        id: 3,
        numeric: false,
        disablePadding: true,
        label: "Production Line",
        show: true,
      },

      {
        id: 4,
        code: "workOrderNumber",
        numeric: false,
        disablePadding: false,
        label: "Work Order Number",
        show: true,
      },
      {
        id: 5,
        code: "orderTitle",
        numeric: false,
        disablePadding: false,
        label: "Job Title",
        show: true,
      },
      {
        id: 6,
        code: "jobGroupNumber",
        numeric: false,
        disablePadding: false,
        label: "Job Group No",
        show: true,
      },
      {
        id: 7,
        code: "statusName",
        numeric: false,
        disablePadding: false,
        label: "Status",
        show: true,
      },
      {
        id: 8,
        code: "completedBy",
        numeric: false,
        disablePadding: false,
        label: "Completed By",
        show: true,
      },
      {
        id: 9,
        code: "completedDate",
        numeric: false,
        disablePadding: false,
        label: "Completed Date",
        show: true,
      },
      {
        code: "requestedBy",
        id: 10,
        numeric: false,
        disablePadding: false,
        label: "Requested By",
        show: true,
      },

      {
        code: "requestedDate",
        id: 11,
        numeric: false,
        disablePadding: false,
        label: "Requested Date",
        show: true,
      },
    ],
    prodLineId: [],
    selectedStockIndent: {
      indentCode: null,
      workOrderNumber: null,
      prodLine: null,
      orderTitle: null,
      jobGroupNumber: null,
      stockIndentDateFrom: null,
      stockIndentDateTo: null,
    },
    statusNameList: [],
    IdsList: [],
    jobScheduleList: [],
  };
  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  handleDeleteItemMaster = async (index) => {
    //give update api call
    let { selected } = this.state;
    const dispatch = this.context[1];
    let deletionIds = selected.length > 1 ? selected.join(",") : selected[0];

    await deleteStockIndent(deletionIds).then((r) => {
      getStockIndentList().then((r) => {
        dispatch({
          type: "getStockIndentList",
          newStore: r.data,
        });
        this.getAllStockIndent(r.data);
        this.exportDatas(r.data);
      });
    });
  };

  componentDidMount() {
    this.props.handleSettingHeader("Stock Indent");
    this.props.setSelectedList(
      "genericSystemOpen",
      "stockIndent",
      "warehouseManagementOpen"
    );
    const dispatch = this.context[1];
    getStockIndentList().then((r) => {
      dispatch({
        type: "getStockIndentList",
        newStore: r.data,
      });
      this.getAllStockIndent(r.data);
      this.exportDatas(r.data);
    });
    this.setState({ loading: true });

    getCustomerFromItemMaster().then((r) => {
      this.getAllCustomers(r.data);
    });

    getjobScheduleProductionDropDownList().then((r) => {
      dispatch({
        type: "getProductionLineList",
        newStore: r.data,
      });
      this.getAllStatus(r.data);
    });

    getStockIndentsFilterList().then((r) => {
      console.log(r);
      dispatch({
        type: "getjobScheduleStatusDropDownList",
        newStore: r.data,
      });
      this.getAllStatusCodes(r.data);
    });
  }

  getAllStatusCodes = (list) => {
    // console.log(list);
    this.setState({
      statusNameList:
        list &&
        list
          //.filter((val) => val.text != "Cancelled")
          .sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
    });
    this.setState({
      IdsList:
        list &&
        list
          //.filter((val) => val.id != "Cancelled")
          .sort((a, b) => (a.id > b.id ? 1 : -1)),
    });
  };

  getAllStatus = (list) => {
    this.setState({
      prodLineId:
        list &&
        list.sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
        ),
    });
    this.setState({
      ItemMasterList: list && list.sort((a, b) => (a.id > b.id ? 1 : -1)),
    });
  };

  getAllCustomers = (list) => {
    let customerList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        customerList.findIndex((p) => p.customerID === c.customerID) > -1
          ? customerList
          : customerList.push(c)
      );

    this.setState({
      customerList:
        customerList &&
        customerList.sort((a, b) =>
          a.customerName.toLowerCase() > b.customerName.toLowerCase() ? 1 : -1
        ),
    });
  };

  handleproductionLineNameChange = (event, name) => {
    const { jobScheduleList } = this.state;

    let lastIndexValue = event.target.id.split("option-")[1];
    jobScheduleList["productionLineID"] = this.state.prodLineId[
      lastIndexValue
    ].id;
    this.setState({ jobScheduleList });
  };

  handleStatusChange = (event, name) => {
    const { jobScheduleList } = this.state;

    let lastIndexValue = event.target.id.split("option-")[1];
    jobScheduleList["statusName"] = this.state.statusNameList[
      lastIndexValue
    ].id;
    this.setState({ jobScheduleList });
  };

  exportDatas = (StockindentList) => {
    let list = StockindentList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          "Indent Number": d.indentCode,
          "Customer": d.customerCode,
          "Production Line": d.productionLineName,
          "Work Order Number": d.workOrderNumber,
          "Job Title": d.orderTitle,
          "Job Group No": d.jobGroupNumber,
          Status: d.statusName,
          "Completed By": d.completedBy,
          "Completed Date": d.completedDate ? moment(d.completedDate).format(
            "DD/MM/YYYY HH:mm:ss"
          ) : "",
          "Requested By": d.requestedBy,
          "Requested Date": d.requestedDate ? moment(d.requestedDate).format(
            "DD/MM/YYYY HH:mm:ss"
          ) : "",
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getAllStockIndent = (StockindentList) => {
    let list = StockindentList;
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        // console.log(d, "requestedBy");
        return newRows.push(
          createData(
            d.indentCode,
            d.customerCode,
            d.productionLineName,
            d.requestedBy,
            d.requestedDate,
            d.workOrderNumber,
            d.orderTitle,
            d.jobGroupNumber,
            d.statusName,
            d.completedDate ? d.completedBy : "",
            d.completedDate,
            d.indentHeaderID
          )
        );
      });
    this.setState({ StockindentList: newRows, selected: [], loading: false });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.StockindentList && store.StockindentList.length > 0)
      if (this.state.StockindentList.length !== store.StockindentList.length) {
        this.getAllStockIndent(store.StockindentList);
      }
    return true;
  }
  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { StockindentList } = this.state;
    if (event.target.checked) {
      const newSelecteds = StockindentList.map((n) => n.id);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id, letterShop) => {
    let newSelected = [];
    let selected = [];
    if (event.target.checked) {
      newSelected = newSelected.concat(selected, id);
      this.setState({
        selected: newSelected,
        selectedStockIndent: letterShop,
        selectedStockIndentID: id,
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedStockIndent: letterShop,
        selectedStockIndentID: id,
      });
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true, page: 0 });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchStockIndent = (handleSearchStockIndent) => {
    const dispatch = this.context[1];
    let ImSearchValue = handleSearchStockIndent;
    let indentCode = ImSearchValue.indentCode;
    let productionLineID = ImSearchValue.productionLineID;
    let requestedBy = ImSearchValue.requestedBy;
    let requestedDate = ImSearchValue.requestedDate;
    let workOrderNumber = ImSearchValue.workOrderNumber;
    let orderTitle = ImSearchValue.orderTitle;
    let jobGroupNumber = ImSearchValue.jobGroupNumber;
    let status = ImSearchValue.statusId;
    // let statusId = ImSearchValue.statusId;
    let stockIndentDateTo =
      ImSearchValue.stockIndentDateTo && ImSearchValue.stockIndentDateTo;
    let stockIndentDateFrom =
      ImSearchValue.stockIndentDateFrom && ImSearchValue.stockIndentDateFrom;

    if (stockIndentDateFrom == null) {
      stockIndentDateFrom = "";
    }
    if (stockIndentDateTo == null) {
      stockIndentDateTo = "";
    }

    let reqBody = {
      indentCode,
      productionLineID: productionLineID,
      requestedBy,
      requestedDate,
      workOrderNumber,
      orderTitle,
      jobGroupNumber,
      status,
      modifiedDateFrom: stockIndentDateFrom,
      modifiedDateTo: stockIndentDateTo,
    };
    getFliteredStockIndentList(reqBody).then((r) => {
      dispatch({
        type: "getFliteredStockIndentList",
        newStore: r,
      });
      this.setState({ searchPopupOpen: false, searchClicked: true });

      this.getAllStockIndent(r);
      this.exportDatas(r);
    });
  };

  clearSearch = () => {
    const dispatch = this.context[1];
    getStockIndentList().then((r) => {
      dispatch({
        type: "getStockIndentList",
        newStore: r.data,
      });
      this.getAllStockIndent(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      selectedStockIndent,
      StockindentList,
      headCells,
      exportDataValues,
      jobScheduleList,
    } = this.state;
    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedStockIndent={selectedStockIndent}
            onSearchClicked={this.onSearchClicked}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            selected={this.state.selected}
            selectedStockIndentID={this.state.selectedStockIndentID}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchStockIndent={this.handleSearchStockIndent}
            ImSearchValue={this.state.ImSearchValue}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            StockindentList={this.state.StockindentList}
            exportDataValues={exportDataValues}
            ItemMasterList={this.state.ItemMasterList}
            customerList={this.state.customerList}
            handleproductionLineNameChange={this.handleproductionLineNameChange}
            prodLineId={this.state.prodLineId}
            statusNameList={this.state.statusNameList}
            handleStatusChange={this.handleStatusChange}
            jobScheduleList={jobScheduleList}
            handleDeleteItemMaster={this.handleDeleteItemMaster}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ whiteSpace: "nowrap" }}
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={e => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={StockindentList.length}
                  headCells={this.state.headCells}
                />

                <TableBody style={{ overflow: "hidden" }}>
                  {StockindentList.length > 0 ? (
                    stableSort(StockindentList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )

                      .map((row, index) => {
                        // console.log(row, "row");
                        const isItemSelected = isSelected(row.indentHeaderID);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { statusName } = "";
                        if (row.isActive === true) {
                          statusName = "Yes";
                        } else {
                          statusName = "No";
                        }
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              this.handleClick(event, row.indentHeaderID, row)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.indentCode &&
                                  row.indentCode.length > 16 ? (
                                    <Tooltip title={row.indentCode}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.indentCode}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.indentCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.customerCode &&
                                  row.customerCode.length > 16 ? (
                                    <Tooltip title={row.customerCode}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.customerCode}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.productionLineName &&
                                  row.productionLineName.length > 16 ? (
                                    <Tooltip title={row.productionLineName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.productionLineName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.productionLineName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.workOrderNumber &&
                                  row.workOrderNumber.length > 16 ? (
                                    <Tooltip title={row.workOrderNumber}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.workOrderNumber}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.workOrderNumber}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.orderTitle &&
                                  row.orderTitle.length > 16 ? (
                                    <Tooltip title={row.orderTitle}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.orderTitle}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.orderTitle}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.jobGroupNumber &&
                                  row.jobGroupNumber.length > 16 ? (
                                    <Tooltip title={row.jobGroupNumber}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.jobGroupNumber}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.jobGroupNumber}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {
                                    <Typography>
                                      {row.statusName === "Pending" ? (
                                        <StatusText color="rgb(244, 180, 60)">
                                          {row.statusName}
                                        </StatusText>
                                      ) : row.statusName === "Cancelled" ? (
                                        <StatusText color="red">
                                          {row.statusName}
                                        </StatusText>
                                      ) : row.statusName === "Completed" ? (
                                        <StatusText color="#096c08">
                                          {row.statusName}
                                        </StatusText>
                                      ) : row.statusName === "OnHold" ? (
                                        <StatusText color="gray">
                                          {"On Hold"}
                                        </StatusText>
                                      ) : row.statusName}
                                    </Typography>
                                  }
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Moment format="MM/DD/YYYY">{row.createddate}</Moment> */}
                                {/* moddate */}

                                {row.completedDate ? row.completedBy : ""}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.completedDate &&
                                  row.completedDate.length > 16 ? (
                                    <Tooltip
                                      title={row.completedDate && moment(row.completedDate).format(
                                        "DD/MM/YYYY  HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.completedDate
                                          ? moment(row.completedDate).format(
                                            "DD/MM/YYYY  HH:mm:ss"
                                          )
                                          : null}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.completedDate
                                        ? moment(row.completedDate).format(
                                          "DD/MM/YYYY  HH:mm:ss"
                                        )
                                        : null}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.requestedBy &&
                                  row.requestedBy.length > 16 ? (
                                    <Tooltip title={row.requestedBy}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.requestedBy}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.requestedBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.requestedDate &&
                                  row.requestedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.requestedDate).format(
                                        "DD/MM/YYYY  HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.requestedDate).format(
                                          "DD/MM/YYYY  HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.requestedDate).format(
                                        "DD/MM/YYYY  HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={12} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                        {/* {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )} */}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>

          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={StockindentList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(StockIndent);
