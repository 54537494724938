import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";
import clone from "clone";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { Checkbox } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import StatusText from "../../../shared/statusText";
import {
  serchCustomerOrder,
  getPlacedOrder,
} from "../../generic/orderPlacement/actions";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { blue } from "@material-ui/core/colors";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
//import ManageCountry from "./countryManage";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AdvanceSearch from "./search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { StateContext } from "../../../shared/globalState";
import Typography from "@material-ui/core/Typography";
//import CreateCountry from "./countryAdd";
import FormGroup from "@material-ui/core/FormGroup";
import Validator from "../../../shared/validator";
//import DeleteOrder from "./delete";
import {
  desc,
  stableSort,
  getSorting,
  DialogActions,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import toastr from "toastr";
import moment from "moment";
import "../../app/App.css";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { ExportCSV } from "../../../shared/ExportCSV";
import {
  SearchIcon,
  DownloadIcon,
  ViewColumnIcon,
  EditIcon,
  ViewIcon,
  AddIcon,
} from "../../../shared/customIcons";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getDocketBaggingList, searchPubMLDB } from "./action";
const validateMaxlength2 = new Validator("maxlength2");

const getColor = (status) => {
  switch (status) {
    case "In Progress":
      return "#03A6FD"; //In Progress
    case "Out For Collection":
      return "#ECB507"; //Out For Collection
    case "Item Received":
      return "#16831F"; //Item Recieved
    case "Cancelled":
      return "#AD2219"; //Cancelled
    case "Complete":
      return "#16831F";
    default:
      return "#03A6FD";
  }
};

const columns = [
  {
    id: 7,
    name: "Status",
  },
  {
    id: 8,
    name: "Modified By",
  },

  {
    id: 9,
    name: "Modified Date",
  },
  {
    id: 10,
    name: "Created By",
  },

  {
    id: 11,
    name: "Created Date",
  },
];

// const DialogActions = withStyles(theme => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1)
//   }
// }))(MuiDialogActions);
let initailData = [];
function createData(
  mlBatchHeaderID,
  customerID,
  customerName,
  locationID,
  locationCode,
  batchCode,
  batchProcessedDate,
  lodgementDate,
  totalNoOfDockets,
  satusID,
  statusName,
  isDocketSubmitted,
  createdBy,
  createdByName,
  createdDate,
  modifiedBy,
  modifiedName,
  modifiedDate
) {
  return {
    mlBatchHeaderID,
    customerID,
    customerName,
    locationID,
    locationCode,
    batchCode,
    batchProcessedDate,
    lodgementDate,
    totalNoOfDockets,
    satusID,
    statusName,
    isDocketSubmitted,
    createdBy,
    createdByName,
    createdDate,
    modifiedBy,
    modifiedName,
    modifiedDate,
  };
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ borderTop: "1px solid #eaeaea", background: "white" }}
          className="table-cell"
        >
          {/* <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "Select All Countrys" }}
              />
            }
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <Tooltip title={headCell.label}>
                    <span style={{ color: "#306dca" }}>
                      {headCell.shortLabel}
                    </span>
                  </Tooltip>
                </TableSortLabel>
              </TableCell>
            )
            //);
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    countryCode: "",
    countryName: "",
  });
  let status = ""
  if (props && props.selectedRows && props.selectedRows.statusName === "Complete")
    status = 1
  const [formValid, setIsformValid] = useState(false);
  var publisherBackLabelManage = props.roles.filter((r) => {
    return r.superMenuName === "Publisher Back Label";
  });
  let bpManage =
    publisherBackLabelManage.length > 0 &&
    publisherBackLabelManage.filter(
      (u) => u.menuName === "Docket Posting/Bagging"
    );
  var acnObj = bpManage[0];
  const [countrySearchValue, setValues] = useState({
    countryCode: "",
    countryName: "",
  });

  const classes = useToolbarStyles();
  const {
    numSelected,
    selectedLocalPostage,
    selectedLocalPostageID,
    handleDeletePopupOpen,
    deletePopupOpen,
    searchPopupOpen,
    handleDeletePopupClose,
    handleDeleteCountry,
    handleViewColumns,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchCountry,
    searchClicked,
    selected,
    localPostage,
    stockInList,
    editable,
    removable,
    selectedRows,
  } = props;

  let excelFileData = clone(stockInList);

  excelFileData &&
    excelFileData.length > 0 &&
    excelFileData.map((d) => {
      d["Customer Name"] = d["customerName"];
      d["Location"] = d["locationCode"];
      d["Batch Code"] = d["batchCode"];
      // d["Batch Processed Date"] = d["batchProcessedDate"];
      d["Processed Date"] = d["batchProcessedDate"] ? moment(d["batchProcessedDate"]).format("DD/MM/YYYY") : "";
      d["Lodgement Date"] = d["lodgementDate"] ? moment(d["lodgementDate"]).format("DD/MM/YYYY") : "";

      d["Total No Of Dockets"] = d["totalNoOfDockets"];
      d["Batch Status"] = d["statusName"];
      d["Modified By"] = d["modifiedBy"];
      d["Modified Date"] = moment(d["modifiedDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      d["Created By"] = d["createdByName"];
      d["Created Date"] = moment(d["createdDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      delete d["mlBatchHeaderID"];
      delete d["customerID"];
      delete d["customerName"];
      delete d["locationID"];
      delete d["locationCode"];
      delete d["batchCode"];
      delete d["batchProcessedDate"];
      delete d["lodgementDate"];
      delete d["totalNoOfDockets"];
      delete d["satusID"];
      delete d["statusName"];
      delete d["isDocketSubmitted"];
      delete d["createdBy"];
      delete d["createdByName"];
      delete d["createdDate"];
      delete d["modifiedBy"];
      delete d["modifiedName"];
      delete d["modifiedDate"];
    });

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteCountry = () => {
    handleDeleteCountry();
  };
  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = countrySearchValue;
    searchValues.countryCode = "";
    searchValues.countryName = "";
    searchValues = countrySearchValue;
    setIsformValid(false);
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link
                      to={`/view_pubBackLabelDocketBaggingDetails/${selected[0]}`}
                    >
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <Link to={`/edit_pubBackLabelDocketBagging/${selected[0]}/${status}`}>
                      <IconButton
                        aria-label="edit"
                        className={classes.iconHover}
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            )}
            {/* <Tooltip title="Delete"> */}
            {/* {removable && (
              <DeleteOrder
                mode="delete"
                selected={selected}
                selectedRows={selectedRows}
                onRequestSucceed={props.onRequestSucceed}
                // count={props.selected.length}
                // isActive={props.isActive}
              />
            )} */}
            {/* </Tooltip> */}
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {
                props.searchClicked ? (
                  <Tooltip title="Clear search result">
                    <IconButton
                      aria-label="clear"
                      className={classes.iconHover}
                      onClick={(e) => props.clearSearch()}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Tooltip>
                ) :
                  acnObj &&
                  acnObj.advanceSearchAction && <Tooltip title="Advance search">
                    <AdvanceSearch
                      onSearchClicked={props.onSearchClicked}
                      onRequestSucceed={props.onRequestSucceed}
                    />
                  </Tooltip>
              }

              {/* </Tooltip> */}
              {acnObj && acnObj.exportAction && (
                <ExportCSV
                  csvData={excelFileData}
                  fileName={"BACK_LABEL_DOCKET_BAGGING_AND_POSTING"}
                />
              )}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                handleViewColumns={handleViewColumns}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 10.2;
  const left = 77;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    handleViewColumns,
    open,
    ...other
  } = props;
  const [column, setColumnIds] = React.useState(valueProp);
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  }, [valueProp, open]);

  const handleClose = () => {
    onClose(setColumnIds);
  };

  const handleChange = (event) => {
    setColumnIds(event.target.value);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => handleViewColumns(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class MainLabelDocketBagging extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "createdDate",
    selected: [],
    selectedRows: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    editable: true,
    removable: true,
    stockInList: [],
    loading: false,
    selectedLocalPostage: {},
    selectedLocalPostageID: 1,
    selectedShowColumns: [],
    searchPopupOpen: false,
    countrySearchValues: {},
    searchClicked: false,
    headCells: [
      {
        code: "customerName",
        id: 1,
        show: false,
        numeric: false,
        disablePadding: true,
        label: "Customer",
        shortLabel: "Customer",
        show: true,
      },
      {
        code: "locationCode",
        id: 2,
        show: false,
        numeric: false,
        disablePadding: true,
        label: "Location",
        shortLabel: "Location",
        show: true,
      },
      {
        code: "batchCode",
        id: 3,
        numeric: false,
        disablePadding: false,
        label: "Batch Code",
        shortLabel: "Batch Code",
        show: true,
      },
      {
        id: 4,
        code: "batchProcessedDate",
        numeric: false,
        disablePadding: false,
        label: "Processed Date",
        shortLabel: "Prc Date",
        show: true,
      },
      {
        id: 5,
        code: "lodgementDate",
        numeric: false,
        disablePadding: false,
        label: "Lodgement Date",
        shortLabel: "Lodgement Date",
        show: true,
      },
      {
        id: 6,
        code: "totalNoOfDockets",
        numeric: false,
        disablePadding: false,
        label: "Total No Of Dockets",
        shortLabel: "Dockets",
        show: true,
      },
      {
        id: 7,
        code: "statusName",
        numeric: false,
        disablePadding: false,
        label: "Batch Status",
        shortLabel: "Batch Status",
        show: true,
      },
      {
        id: 8,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        shortLabel: "Mdf By",
        show: true,
      },
      {
        id: 9,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        shortLabel: "Mdf Date",
        show: true,
      },
      {
        id: 10,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        shortLabel: "Ctd By",
        show: true,
      },
      {
        id: 11,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        shortLabel: "Ctd Date",
        show: true,
      },
    ],
  };
  handleViewColumns = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  componentDidMount() {
    this.props.handleSettingHeader("Back Label Docket Bagging");
    this.props.setSelectedList(
      "publisherBackLabelOpen",
      "backLabelDocketPosting"
    );

    const [{ store }, dispatch] = this.context;

    this.setState({ loading: true });
    getDocketBaggingList().then((r) => {
      this.getAllPlacedOrder(r && r.data);
    });
  }

  onRequestSucceed = (deleted) => {
    const [{ store }, dispatch] = this.context;
    getPlacedOrder().then((r) => {
      dispatch({
        type: "getPlacedOrderList",
        newStore: r.data,
      });
      if (deleted) this.setState({ searchClicked: false, selected: [] });
      this.getAllPlacedOrder(r.data);
    });
  };
  getAllPlacedOrder = (lpList) => {
    let list = lpList;
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        newRows.push(
          createData(
            d.mlBatchHeaderID,
            d.customerID,
            d.customerName,
            d.locationID,
            d.locationCode,
            d.batchCode,
            d.batchProcessedDate,
            d.lodgementDate,
            d.totalNoOfDockets,
            d.satusID,
            d.statusName,
            d.isDocketSubmitted,
            d.createdBy,
            d.createdByName,
            d.createdDate,
            d.modifiedBy,
            d.modifiedName,
            d.modifiedDate
          )
        );
      });
    this.setState({ rows: newRows, loading: false });
    initailData = newRows;
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { rows } = this.state;
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, data, id) {
    let newSelected = [];
    let selected = [];
    let newSelectedRows = [];

    if (event.target.checked) {
      newSelected = newSelected.concat(selected, id);
      newSelectedRows = newSelected.concat(id);
      this.setState({
        selected: newSelected,
        selectedemloID: data,
        selectedemloIDId: id,
        selectedRows: newSelectedRows,
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedemloID: data,
        selectedemloIDId: id,
        selectedRows: newSelectedRows,
      });
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleDeleteCountry = () => {
    let { selected } = this.state;
    const [{ store }, dispatch] = this.context;
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");
  };
  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchCountry = (handleSearchCountry) => {
    const [{ store }, dispatch] = this.context;

    let countryValues = handleSearchCountry;
    let countryCode = countryValues.countryCode;
    let countryName = countryValues.countryName;
    let reqBody = { countryCode, countryName };

    this.setState({ searchPopupOpen: false, searchClicked: true });
  };

  onSearchClicked = (reqBody) => {
    this.setState({ rows: [], loading: true });
    searchPubMLDB(reqBody).then((r) => {
      this.getAllPlacedOrder(r.data);
    });
    this.setState({ searchClicked: true });
  };
  clearSearch = () => {
    this.setState({ rows: [], loading: true });
    getDocketBaggingList().then((r) => {
      this.getAllPlacedOrder(r && r.data);
    });
    this.setState({ searchClicked: false });
  };

  render() {
    const [{ store }, dispatch] = this.context;
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      rows,
      selectedLocalPostage,
      selectedLocalPostageID,
      headCells,
      selectedRows,
    } = this.state;
    const isSelected = (id) => selected.indexOf(id) !== -1;
    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedLocalPostage={selectedLocalPostage}
            selectedLocalPostageID={selectedLocalPostageID}
            history={this.props.history}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchCountry={this.handleSearchCountry}
            countrySearchValue={this.state.countrySearchValue}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteCountry={this.handleDeleteCountry}
            handleViewColumns={this.handleViewColumns}
            headCells={headCells}
            stockInList={rows}
            selected={selected}
            onSearchClicked={this.onSearchClicked}
            editable={this.state.editable}
            removable={this.state.removable}
            selectedRows={selectedRows}
            roles={this.props.roles}
            selectedRows={this.state.selectedemloID}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div

              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 335px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{
                  borderLeft: "1px solid  rgba(224, 224, 224, 1)",
                  whiteSpace: "nowrap",
                }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={rows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {rows.length > 0 ? (
                    stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.mlBatchHeaderID);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive == true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return (
                          <TableRow
                            //  hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    onClick={(event) =>
                                      this.handleClick(
                                        event,
                                        row,
                                        row.mlBatchHeaderID
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.acronym && row.acronym.length > 16 ? (
                                  <Tooltip title={row.acronym} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.customerName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.locationCode &&
                                    row.locationCode.length > 16 ? (
                                      <Tooltip
                                        title={row.locationCode}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.locationCode}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.locationCode}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.batchCode &&
                                    row.batchCode.length > 16 ? (
                                      <Tooltip
                                        title={row.batchCode}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.batchCode}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.batchCode}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.batchProcessedDate &&
                                    row.batchProcessedDate.length > 16 ? (
                                      <Tooltip
                                        title={
                                          row.batchProcessedDate
                                            ? moment(
                                              row.batchProcessedDate
                                            ).format("DD/MM/YYYY")
                                            : ""
                                        }
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.batchProcessedDate
                                            ? moment(
                                              row.batchProcessedDate
                                            ).format("DD/MM/YYYY")
                                            : ""}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.batchProcessedDate
                                          ? moment(row.batchProcessedDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.lodgementDate &&
                                    row.lodgementDate.length > 16 ? (
                                      <Tooltip
                                        title={
                                          row.lodgementDate
                                            ? moment(
                                              row.lodgementDate
                                            ).format("DD/MM/YYYY")
                                            : ""
                                        }
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.lodgementDate
                                            ? moment(
                                              row.lodgementDate
                                            ).format("DD/MM/YYYY")
                                            : ""}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.lodgementDate
                                          ? moment(row.lodgementDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                // className="table-content-right-cell"
                                style={{
                                  maxWidth: "87px",
                                  padding: ".5em",
                                  textAlign: "right",
                                }}
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.totalNoOfDockets &&
                                    row.totalNoOfDockets.length > 16 ? (
                                      <Tooltip
                                        title={row.totalNoOfDockets}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.totalNoOfDockets}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.totalNoOfDockets}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.statusName &&
                                    row.statusName.length > 16 ? (
                                      <Tooltip
                                        title={row.statusName}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          <StatusText
                                            color={getColor(row.statusName)}
                                          >
                                            {row.statusName}
                                          </StatusText>
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        <StatusText
                                          color={getColor(row.statusName)}
                                        >
                                          {row.statusName}
                                        </StatusText>
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.modifiedName &&
                                  row.modifiedName.length > 16 ? (
                                    <Tooltip
                                      title={row.modifiedName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.modifiedName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.modifiedDate &&
                                  moment(row.modifiedDate).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                  ).length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.createdByName &&
                                  row.createdByName.length > 16 ? (
                                    <Tooltip
                                      title={row.createdByName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.createdByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.createdDate &&
                                  moment(row.createdDate).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                  ).length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={11} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(MainLabelDocketBagging);
