import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Paper, Divider } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { addDispatchStyles } from "../../../shared/styles";
import { StateContext } from "../../../shared/globalState";
import { getPerticularMailingScheme } from "../masterActions";
import { printDetails } from "../../../shared/tableCommonFunctions";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { selectedMailingSchemeID } = props;
  let acnArr = props.roles.filter(u => u.menuName === "Mailing Scheme");
  let acnObj = acnArr[0];

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%"
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_mailingScheme/${selectedMailingSchemeID}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {/* <Tooltip title="Download">
            <IconButton aria-label="download" className={classes.iconHover}  onClick={e => pdfDownloadDetails("country_details.pdf")}>
              <DownloadIcon />
            </IconButton>
          </Tooltip> */}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={e => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewDispatchMode extends React.Component {
  static contextType = StateContext;
  state = {
    mailingScheme: "",
    description: "",
    isActive: true
  };
  componentDidMount() {
    this.props.handleSettingHeader("Mailing Scheme");
    this.props.setSelectedList("masterDataOpen", "mailingScheme");
    let id = "";
    if (process.browser) id = window.location.pathname.split("/")[2];
    if (id) {
      getPerticularMailingScheme(id).then(r => {
        this.setState({
          mailingScheme:
            r && r.data && r.data.mailingScheme && r.data.mailingScheme,
          description: r && r.data && r.data.description && r.data.description,
          isActive: r && r.data && r.data.isActive
        });
      });
    }
  }
  render() {
    let { mailingScheme, description, isActive } = this.state;
    let mailingSchemeID = "";
    if (process.browser)
      mailingSchemeID = window.location.pathname.split("/")[2];
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selectedMailingSchemeID={mailingSchemeID}
            roles={this.props.roles}
          />
          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">Mailing Scheme Information</div>
            <div>
              {/* <div style={{ display: "flex", width: "100%" }}> */}
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Mailing Scheme</div>
                  <div className="view-page-value">
                    {mailingScheme && mailingScheme}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Description</div>
                  <div className="view-page-value">
                    {description && description}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Active</div>
                  <div className="view-page-value">
                    {isActive === true ? "Yes" : "No"}
                  </div>
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "25px" }} />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                  onClick={e => this.props.history.push("/mailingScheme")}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addDispatchStyles)(ViewDispatchMode));
