import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Button, Paper, Divider } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
// import { styles } from "../../../shared/styles";
import { resourceValidation } from "../../../shared/resource";
import { StateContext } from "../../../shared/globalState";
import {
  getMailingSchemeLists,
  putMailingScheme,
  getPerticularMailingScheme
} from "../masterActions";
import { addStyles } from "../../../shared/styles";
const maxlength50 = new Validator("maxlength50");
const maxlength200AllAllow = new Validator("maxlength200AllAllow");

const styles = theme => ({
  container: {
    flexWrap: "wrap",
    margin: "1em"
  },
  textField: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "31%",
    fontSize: "11px !important"
  },
  comboBox: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "31%",
    fontSize: "11px !important"
  },
  checkBox: {
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    marginRight: "1.5em !important"
  }
});

class EditMailingScheme extends React.Component {
  static contextType = StateContext;
  state = {
    mailingScheme: "",
    description: "",
    isActive: false,
    errors: {
      mailingScheme: false,
      description: false
    },
    apiMailingScheme: "",
    apiDescription: "",
    apiIsActive: "",
    active: false
  };
  componentDidMount() {
    this.props.handleSettingHeader("Mailing Scheme");
    this.props.setSelectedList("masterDataOpen", "mailingScheme");
    let id = "";
    if (process.browser) id = window.location.pathname.split("/")[2];
    if (id) {
      getPerticularMailingScheme(id).then(r => {
        this.setState({
          mailingScheme:
            r && r.data && r.data.mailingScheme && r.data.mailingScheme,
          apiMailingScheme:
            r && r.data && r.data.mailingScheme && r.data.mailingScheme,
          description: r && r.data && r.data.description && r.data.description,
          apiDescription:
            r && r.data && r.data.description && r.data.description,
          isActive: r && r.data && r.data.isActive && r.data.isActive,
          apiIsActive: r && r.data && r.data.isActive && r.data.isActive
        });
        if (r && r.data && r.data.isActive)
          this.setState({ active: r.data.isActive });
      });
    }
  }
  handleChange = (e, validate) => {
    let newRrrors = { ...this.state.errors };
    let result = validate(e.target.value);
    newRrrors[e.target.name] = !result;
    this.setState({ [e.target.name]: e.target.value, errors: newRrrors });
  };
  handleIsActive = (e, name) => {
    this.setState({ [name]: e.target.checked });
  };
  //   _onKeyPress(event) {
  //     const re = /[0-9a-zA-Z]+/g;
  //     if (!re.test(event.key)) {
  //       event.preventDefault();
  //     }
  //   }
  handleClose = () => {
    this.setState({
      mailingScheme: "",
      description: ""
    });
  };
  checkValidInputs = () => {
    if (!this.state.mailingScheme) return true;
    if (!this.state.description) return true;
    // if (this.state.dispatchModeCode === this.state.apiDispatchModeCode)
    //   return true;
    if (
      this.state.description === this.state.apiDescription &&
      this.state.isActive === this.state.apiIsActive
    )
      return true;
    else return false;
  };
  handleSubmit = event => {
    let id = "";
    if (process.browser) id = window.location.pathname.split("/")[2];
    const dispatch = this.context[1];
    let reqBody = {};
    reqBody.mailingScheme = this.state.mailingScheme;
    reqBody.description = this.state.description;
    reqBody.isActive = this.state.isActive;
    reqBody.modifiedBy = localStorage.loggedInId;
    putMailingScheme(this.props.history, id, reqBody)
      .then(o => {
        getMailingSchemeLists()
          .then(r => {
            dispatch({
              type: "getMailingSchemeList",
              newStore: r.data
            });
          })
          .catch(e => {});
      })
      .catch(e => {});
  };
  render() {
    let { classes } = this.props;
    let { mailingScheme, description, active, isActive, errors } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Mailing Scheme</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <TextField
                    required
                    disabled
                    variant="filled"
                    label="Mailing Scheme"
                    name="mailingScheme"
                    inputProps={{
                      maxLength: 2
                    }}
                    // onKeyPress={this._onKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={mailingScheme}
                    onChange={e => this.handleChange(e, maxlength50)}
                    error={errors.mailingScheme}
                    helperText={
                      errors.mailingScheme
                        ? resourceValidation.alphaNumaric
                        : ""
                    }
                  />
                  <TextField
                    inputProps={{
                      maxLength: 100
                    }}
                    required
                    //onKeyPress={this._onKeyPress}
                    label="Description"
                    name="description"
                    margin="normal"
                    className={classes.textField}
                    value={description}
                    onChange={e => this.handleChange(e, maxlength200AllAllow)}
                    error={errors.description}
                    helperText={
                      errors.description ? resourceValidation.alphaNumaric : ""
                    }
                  />{" "}
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      name="isActive"
                      // style={{ marginTop: "33px", marginLeft: "7px" }}
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          color="primary"
                          checked={isActive}
                          onChange={e => this.handleIsActive(e, "isActive")}
                          value={isActive}
                        />
                      }
                      label="Active"
                    />
                  )}
                </div>
                <Divider style={{ marginTop: "25px" }} />
                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={e => this.props.history.push("/mailingScheme")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {this.checkValidInputs() ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditMailingScheme));
