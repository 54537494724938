import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";
import clone from "clone";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import { withRouter } from "react-router-dom";
import TableHead from "@material-ui/core/TableHead";
import { EditIcon } from "../../../shared/customIcons";
import { Divider, Tooltip } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import DeleteIcon from "@material-ui/icons/Delete";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CheckIcon from "@material-ui/icons/Check";
import { Autocomplete } from "@material-ui/lab";
import { StateContext } from "../../../shared/globalState";
import { addDispatchStyles } from "../../../shared/styles";
import {
  getCustomerDropdown,
  getjobScheduleProductionDropDownList,
  getProcessCategory,
} from "../../masters/masterActions";
import {
  GetOWCByWOID,
  getprocessCategoryDropDownList,
  CreateOWC,
  getjobScheduleStatusDropDownList,
} from "../workOrder/action";
import moment from "moment";
const headCells = [
  {
    id: "processCategoryName",
    numeric: false,
    disablePadding: true,
    label: "Category Name ",
  },
  {
    id: "processDescription",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "processInstruction",
    numeric: false,
    disablePadding: false,
    label: "Instruction",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "rate",
    numeric: false,
    disablePadding: false,
    label: "Rate",
  },
  {
    id: "addedQuantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "spRate",
    numeric: false,
    disablePadding: false,
    label: "SpRate",
  },
  {
    id: "totalAmount",
    numeric: false,
    disablePadding: false,
    label: "Total Amount",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderTop: "1px solid #eaeaea",
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderLeft: "1px sold #000" }}
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { } = props;

  return (
    <div>
      <div
        style={{
          display: "flex",
          //   width: "100%",
          border: "1px solid #eaeaea",
          borderBottom: "none",
          borderRadius: "4px",
          marginTop: "1em",
        }}
      >
        {props.disableAdd ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={(e) => props.handleClickButton(e, "add")}
          // style={
          //   !props.disableAdd
          //     ? {
          //         color: "#fff",
          //         background: "#3982ea",
          //       }
          //     : { color: "#3982ea", fontSize: "11px" }
          // }
          >
            <AddIcon style={{ fontSize: "18px" }} /> Add
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              onClick={(e) => props.handleClickButton(e, "add")}
            >
              <AddIcon style={{ fontSize: "18px" }} /> Add
            </Button>
          )}

        {props.disableEdit ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={(e) => props.handleClickButton(e, "reset")}
          >
            <SyncIcon style={{ fontSize: "18px" }} /> Reset
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              onClick={(e) => props.handleClickButton(e, "reset")}
            >
              <SyncIcon style={{ fontSize: "18px" }} /> Reset
            </Button>
          )}
      </div>
    </div>
  );
};
class ViewOWCReport extends React.Component {
  static contextType = StateContext;
  state = {
    disableAdd: false,
    disableEdit: true,
    list: [],
    customer: "",
    workOrder: "",
    title: "",
    planner: "",
    mbaNo: "",
    productionLineName: "",
    productionLineID: "",
    processCategoryName: "",
    processCategoryID: "",
    specialInstruction: "",
    defaultGST: 0,
    customerList: [],
    workOrderID: "",
    categoryList: [],
    billingDate: "",
    owcHeaderID: "",
    subordinate: [],
  };
  componentDidMount() {
    this.props.handleSettingHeader("Work Order");
    this.props.setSelectedList("genericSystemOpen", "workOrder");
    getjobScheduleStatusDropDownList().then((r) => {
      this.getAllCustomer(r.data);
    });
    const [{ store }, dispatch] = this.context;
    let wkOrderId = "";
    if (process.browser) wkOrderId = window.location.pathname.split("/")[2];

    let newList = clone(this.state.list);
    let totalAmount = 0;
    GetOWCByWOID(wkOrderId).then((r) => {
      this.setState({
        customer: r.custmerName,
        workOrder: r.workOrderNumber,
        title: r.orderTitle,
        planner: r.planner ? r.planner : "",
        owcNo: r.owcNo ? r.owcNo : "",
        defaultGST: +r.gsTpercentage,
        workOrderID: r.workOrderID,
        billingDate: r.billingDate,
        owcHeaderID: r.owcHeaderID,
        processID: r.processID,
      });
      r.getOutWorkerClaimDetails &&
        r.getOutWorkerClaimDetails.length > 0 &&
        r.getOutWorkerClaimDetails.map((l) => {
          newList.push({
            processCategoryName: l.processCategoryName,
            processDescription: l.processDescription,
            processInstruction: l.processInstruction,
            quantity: l.quantity,
            rate: l.rate,
            addedQuantity: l.addedQuantity,
            spRate: l.spRate,
            totalAmount: l.totalAmount,
            owcHeaderID: l.owcHeaderID,
            processID: null,
            createdBy: window.localStorage.loggedInId,
          });
        });
      this.setState({
        list: newList,
        disableEdit: false,
        subordinate: newList,
      });
    });

    getjobScheduleProductionDropDownList().then((r) => {
      dispatch({
        type: "getProductionLineList",
        newStore: r.data,
      });
      this.getAllCustomer(r.data);
    });

    getProcessCategory().then((r) => {
      dispatch({
        type: "getProcessTypeList",
        newStore: r.data,
      });
      this.getAllCategory(r.data);
    });
  }

  getAllCustomer = (list) => {
    this.setState({
      customerList:
        list &&
        list.sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
        ),
    });
  };

  getAllCategory = (list) => {
    let categoryList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        categoryList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.setState({ categoryList });
  };

  handleClickButton = (e, name) => {
    let newList = clone(this.state.list);
    let newObj = {
      processCategoryName: "",
      processDescription: "",
      processInstruction: "",
      quantity: "",
      rate: "",
      addedQuantity: "",
      spRate: "",
      totalAmount: "",
      editClicked: true,
      manualEntry: true,
      processID: null,
      createdBy: window.localStorage.loggedInId,
    };
    newList.push(newObj);
    if (name === "add") {
      this.setState({
        list: newList,
        editableList: newList,
        // disableAdd: true,
        disableEdit: false,
        OrderGroupID: 0,
      });
    } else {
      this.setState({
        list: [],
        editableList: [],
        disableAdd: false,
        disableEdit: true,
        fileName: "",
        OrderGroupID: 0,
      });
    }
  };
  onEditClicked = (e, i) => {
    let newEditableList = clone(this.state.list);
    newEditableList[i].editClicked = !newEditableList[i].editClicked;
    this.setState({
      list: newEditableList,
    });
  };
  onDeleteClicked = (e, i) => {
    let newList = clone(this.state.list);
    newList[i].editClicked = false;
    newList.splice(i, 1);
    this.setState({
      list: newList,
    });
  };
  onSPLInstructionChanged = (e) => {
    this.setState({ specialInstruction: e.target.value });
  };
  onExcelDataChanged = (e, name, i) => {
    let newList = clone(this.state.list);
    newList[i][name] = e.target.value;

    if (name === "quantity")
      newList[i].totalAmount =
        Math.round(
          (e.target.value * newList[i].rate +
            newList[i].addedQuantity * newList[i].spRate +
            Number.EPSILON) *
          100
        ) / 100;
    if (name === "rate")
      newList[i].totalAmount =
        Math.round(
          (e.target.value * newList[i].quantity +
            newList[i].addedQuantity * newList[i].spRate +
            Number.EPSILON) *
          100
        ) / 100;
    if (name === "addedQuantity")
      newList[i].totalAmount =
        Math.round(
          (newList[i].quantity * newList[i].rate +
            e.target.value * newList[i].spRate +
            Number.EPSILON) *
          100
        ) / 100;
    if (name === "spRate")
      newList[i].totalAmount =
        Math.round(
          (newList[i].quantity * newList[i].rate +
            newList[i].spRate * e.target.value +
            Number.EPSILON) *
          100
        ) / 100;
    this.setState({ list: newList });
  };
  _onAlphaKeyPress(event) {
    const re = /[a-zA-Z 0-9 ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleSubmit = (e) => {
    let newList = clone(this.state.list);
    if (!this.state.productionLineName && !this.state.productionLineID) {
      document.getElementById("productionLineID-index") &&
        document.getElementById("productionLineID-index").focus();
      return;
    }
    let GrandTotalAmount = 0;
    let workOrderID = this.state.workOrderID;
    let productionLineID = this.state.productionLineID;
    newList.map((l) => {
      GrandTotalAmount = GrandTotalAmount + l.totalAmount;
    });

    let isActive = true;
    let createdBy = window.localStorage.loggedInId;
    let createOutWorkerClaimDetails = this.state.list.map((val) => {
      return val;
    });

    let reqBody = {
      workOrderID,
      productionLineID,
      GrandTotalAmount,
      isActive,
      createdBy,
      createOutWorkerClaimDetails,
    };

    let wkOrderId = "";
    if (process.browser) wkOrderId = window.location.pathname.split("/")[2];
    CreateOWC(reqBody, this.props.history, wkOrderId).then((r) => { });
  };

  handleProductionLineIdChange = (event, index) => {
    let lastIndexValue = event.target.id.split("option-")[1];
    this.setState({
      productionLineName: this.state.customerList[lastIndexValue].value,
      productionLineID: this.state.customerList[lastIndexValue].id,
    });
  };

  handleProcessCategoryChange = (event, name, index) => {
    let newList = clone(this.state.list);

    let lastIndexValue = event.target.id.split("option-")[1];
    this.setState({
      processCategoryName: this.state.categoryList[lastIndexValue].name,
      processCategoryID: this.state.categoryList[lastIndexValue].key,
    });
    newList[index][name] = this.state.categoryList[lastIndexValue].name;

    this.setState({ list: newList });
  };

  render() {
    let {
      customer,
      workOrder,
      title,
      planner,
      mbaNo,
      list,
      owcNo,
      customerList,
      productionLineName,
      processCategoryName,
      productionLineID,
      categoryList,
    } = this.state;
    let { classes } = this.props;

    let productionLineIds =
      customerList &&
      customerList.map((val) => {
        return val.value;
      });
    let categoryProcessList =
      categoryList &&
      categoryList.map((val) => {
        return val.name;
      });
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Out Worker Claim </div>
          <Divider />
          <div className="view-page-container" id="printable">
            <div style={{ display: "flex", marginTop: "1em" }}>
              <div className="blueBox">
                <div className="blueBoxLabel">
                  <span>Customer : </span>
                  <span className="blueBoxText">{customer}</span>
                </div>
                <div className="blueBoxLabel">
                  <span>Work Order : </span>
                  <span className="blueBoxText">{workOrder}</span>
                </div>
                <div className="blueBoxLabel">
                  <span>Planner : </span>
                  <span className="blueBoxText">{planner}</span>
                </div>
              </div>
            </div>
            <div className="blueBox" style={{ marginTop: "2px" }}>
              <div className="blueBoxLabel">
                <span>Order Title : </span>
                <span className="blueBoxText">{title}</span>
              </div>
              <div className="blueBoxLabel">
                <span>OWC No : </span>
                <span className="blueBoxText">{owcNo}</span>
              </div>
            </div>
            <div style={{ marginLeft: "-8px" }}>
              <Autocomplete
                options={productionLineIds}
                value={productionLineName}
                id="productionLineID-index"
                name="productionLineID"
                label="Production Line"
                includeInputInList
                onChange={(e) =>
                  this.handleProductionLineIdChange(e, "productionLineID")
                }
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Production Line"
                    style={{
                      width: "31%",
                      fontSize: "11px !important",
                      marginLeft: ".5em",
                      marginRight: ".5em",
                      maxHeight: "400px",
                    }}
                    // name="plannerId"
                    margin="normal"
                    required
                  />
                )}
              />
            </div>
            <div>
              <div>
                <EnhancedTableToolbar
                  //numSelected={selected.length}
                  handleClickButton={this.handleClickButton}
                  disableAdd={this.state.disableAdd}
                  disableEdit={this.state.disableEdit}
                />
              </div>
              <div
                style={{
                  // width: "900px",
                  maxHeight: "400px",
                  margin: "0  0 1em",
                  borderLeft: "1px Solid #eaeaea",
                  borderRight: "1px solid #eaeaea",
                }}
              >
                <div
                  // style={{ maxHeight: 350, overflow: "auto" }}
                  style={
                    !this.props.clearOpenList
                      ? {
                        overflow: "auto",
                        border: "1px solid #eaeaea",
                        maxWidth: "calc(100vw - 8em)",
                        maxHeight: "calc(100vh - 300px)",
                      }
                      : {
                        overflow: "auto",
                        border: "1px solid #eaeaea",
                        maxWidth: "calc(100vw - 330px)",
                        maxHeight: "calc(100vh - 300px)",
                      }
                  }
                >
                  {/* //overflow: "auto" */}
                  <Table
                    aria-labelledby="tableTitle"
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                  // style={{ width: "1800px", overflow: "auto" }}
                  >
                    {" "}
                    <EnhancedTableHead />
                    <TableBody style={{ overflow: "hidden" }}>
                      {list.map((row, index) => {
                        // const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return !this.state.loading ? (
                          <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              {row.editClicked && !row.fromDB ? (
                                <Autocomplete
                                  autoComplete
                                  includeInputInList
                                  options={
                                    categoryProcessList && categoryProcessList
                                  }
                                  onChange={(e) =>
                                    this.handleProcessCategoryChange(
                                      e,
                                      "processCategoryName",
                                      index
                                    )
                                  }
                                  value={processCategoryName}
                                  getOptionLabel={(option) => option}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      label="Category"
                                      margin="normal"
                                    />
                                  )}
                                />
                              ) : row.processCategoryName &&
                                row.processCategoryName.length > 10 ? (
                                    <Tooltip
                                      title={row.processCategoryName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.processCategoryName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography noWrap style={{ fontSize: "14px" }}>
                                      {row.processCategoryName}
                                    </Typography>
                                  )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked && !row.fromDB ? (
                                  <Input
                                    onKeyPress={this._onAlphaKeyPress}
                                    defaultValue={row.processDescription}
                                    inputProps={{ "aria-label": "description" }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "processDescription",
                                        index
                                      );
                                    }}
                                    value={row.processDescription}
                                  />
                                ) : row.processDescription &&
                                  row.processDescription.length > 10 ? (
                                      <Tooltip
                                        title={row.processDescription}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.processDescription}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.processDescription}
                                      </Typography>
                                    )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked ? (
                                  <Input
                                    onKeyPress={this._onNumberKeyPress}
                                    defaultValue={row.processInstruction}
                                    inputProps={{
                                      "aria-label": "instruction",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "processInstruction",
                                        index
                                      );
                                    }}
                                    value={row.processInstruction}
                                  />
                                ) : (
                                    row.processInstruction
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked ? (
                                  <Input
                                    onKeyPress={this._onNumberKeyPress}
                                    defaultValue={row.quantity}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "quantity",
                                        index
                                      );
                                    }}
                                    value={row.quantity}
                                  />
                                ) : (
                                    row.quantity
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked ? (
                                  <Input
                                    onKeyPress={this._onAlphaKeyPress}
                                    defaultValue={row.rate}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(e, "rate", index);
                                    }}
                                    value={row.rate}
                                  />
                                ) : (
                                    row.rate
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked ? (
                                  <Input
                                    onKeyPress={this._onAlphaKeyPress}
                                    defaultValue={row.addedQuantity}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "addedQuantity",
                                        index
                                      );
                                    }}
                                    value={row.addedQuantity}
                                  />
                                ) : (
                                    row.addedQuantity
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked ? (
                                  <Input
                                    onKeyPress={this._onAlphaKeyPress}
                                    defaultValue={row.spRate}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "spRate",
                                        index
                                      );
                                    }}
                                    value={row.spRate}
                                  />
                                ) : (
                                    row.spRate
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked && !row.fromDB ? (
                                  <Input
                                    onKeyPress={this._onAlphaKeyPress}
                                    defaultValue={row.totalAmount}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "totalAmount",
                                        index
                                      );
                                    }}
                                    value={row.totalAmount}
                                  />
                                ) : (
                                    row.quantity * row.rate +
                                    row.addedQuantity * row.spRate
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {!row.fromDB && (
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      color: "#6c6b6b",
                                    }}
                                    onClick={(e) =>
                                      this.onEditClicked(e, index)
                                    }
                                  >
                                    {!row.editClicked ? (
                                      <EditIcon
                                        style={{
                                          color: "#6c6b6b",
                                        }}
                                      />
                                    ) : (
                                        <CheckIcon
                                          style={{
                                            color: "#6c6b6b",
                                          }}
                                        />
                                      )}
                                  </span>
                                )}

                                <DeleteIcon
                                  style={{
                                    cursor: "pointer",
                                    color: "#6c6b6b",
                                  }}
                                  onClick={(e) =>
                                    this.onDeleteClicked(e, index)
                                  }
                                />
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                            <TableRow>
                              <TableCell colSpan="8">
                                <LinearProgress />
                              </TableCell>
                            </TableRow>
                          );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div className="button-wrapper" style={{ paddingTop: "0px" }}>
            <div style={{ marginLeft: "2em" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.props.history.push("/workOrders")}
              >
                CANCEL
              </Button>
            </div>
            <div>
              <Button
                // autoFocus
                // disabled={this.}
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.handleSubmit(e)}
              >
                Submit
              </Button>
            </div>
            <div>
              <Button
                // autoFocus
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.handleSubmitReport(e)}
              >
                Print OWC Report
              </Button>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
//export default withRouter();
export default withRouter(withStyles(addDispatchStyles)(ViewOWCReport));
