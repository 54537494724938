import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import {
  getCustomerList,
  getCountryList,
  getLocationList,
  getDispatchTypeList,
  getDispatchModeList,
  getMailingSchemeLists,
  getDDistributorList,
  getPublisherCustomerList,
} from "../../masters/masterActions";
import { getRoutingCountry, putRoutingCountry } from "../routingActions";
import { Autocomplete } from "@material-ui/lab";
import { addZeros } from "../../../shared/tableCommonFunctions";
import Validator from "../../../shared/validator";
import { Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { resourceValidation } from "../../../shared/resource";
import InputAdornment from "@material-ui/core/InputAdornment";
import { addStyles } from "../../../shared/styles";
// Include new StateContext
import { StateContext } from "../../../shared/globalState";
const Reorderqty = new Validator("Reorderqty");
const onlyNumbers = new Validator("onlyNumbers");
const generalValidator = new Validator("general");
const checkrate = new Validator("checkdecimalpoint");

class EditRoutingCountry extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      update: false,
      routingCountry: {
        customerID: "",
        customer: "",
        country: "",
        countryID: "",
        locationID: "",
        location: "",
        dispatchTypeID: "",
        dispatchModeID: "",
        mailingSchemeID: "",
        distributor: "",
        distributorID: "",
        noOfOrdersFrom: "0",
        noOfOrdersTo: "0",
        noOfCopiesFrom: "0",
        noOfCopiesTo: "0",
        weightFrom: 0.0,
        weightTo: 0.0,
        ppiNumber: "",
        ppiLicenseNo: "",
        returnAddress: "",
        carrierSheetScheme: "",
        carrierSheetMode: "",

        dispatchType: "",
        dispatchMode: "",
        mailingScheme: "",

        isActive: true,
        modifiedBy: localStorage.loggedInId,
      },
      errors: {},
      active: false,
      countryValid: false,
      customerValid: false,
      locationValid: false,
      dispatchTypeValid: true,
      dispatchModeValid: true,
      mailingschemeValid: true,
      distributorValid: true,
      noOfOrdersFromValid: true,
      noOfOrdersToValid: true,
      noOfCopiesFromValid: true,
      noOfCopiesToValid: true,
      weightFromValid: true,
      weightToValid: true,
      ppiLicenseNoValid: true,
      ppiNumberValid: true,
      returnAddressValid: true,
      carrierSheetSchemeValid: true,
      carrierSheetModeValid: true,
      countryList: [],
      customersList: [],
      LocationList: [],
      dispatchModeList: [],
      DispatchTypeList: [],
      DistributorList: [],
      mailingSchemeList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  _onCodeKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onCodeKeyPress1(event) {
    const re = /^[0-9.]{1,3}(?:\.[0-9]{1,2})?$/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  componentDidMount() {
    let routingCountryID = this.props.match.params.routingCountryID;
    getRoutingCountry(routingCountryID).then((r) => {
      let data = r;
      if (data.dispatchModeID === null) {
        data.dispatchMode = "None";
      }
      if (data.mailingSchemeID === null) {
        data.mailingScheme = "None";
      }
      if (data.distributorID === null) {
        data.distributor = "None";
      }
      data = {
        routingCountryID: data.routingCountryID,
        customerID: data.customerID,
        countryID: data.countryID,
        locationID: data.locationID,
        dispatchTypeID: data.dispatchType && data.dispatchType.dispatchTypeID,
        dispatchModeID:
          data.dispatchMode &&
          data.dispatchMode.dispatchModeID &&
          data.dispatchMode.dispatchModeID,
        mailingSchemeID:
          data.mailingScheme && data.mailingScheme.mailingSchemeID,
        distributorID: data.distributor && data.distributor.distributorID,
        noOfOrdersFrom: data.noOfOrdersFrom,
        noOfOrdersTo: data.noOfOrdersTo,
        noOfCopiesFrom: data.noOfCopiesFrom,
        noOfCopiesTo: data.noOfCopiesTo,
        weightFrom: data.weightFrom && addZeros(data.weightFrom.toString(), 2),
        weightTo: data.weightTo && addZeros(data.weightTo.toString(), 2),
        ppiNumber: data.ppiNumber,
        ppiLicenseNo: data.ppiLicenseNo,
        returnAddress: data.returnAddress,
        carrierSheetMode: data.carrierSheetMode,
        carrierSheetScheme: data.carrierSheetScheme,
        isActive: data.isActive,
        customer: data.customer,
        location: data.location,
        dispatchType: data.dispatchType,
        dispatchMode: data.dispatchMode,
        mailingScheme: data.mailingScheme,
        distributor: data.distributor,
      };

      this.setState({ routingCountry: data });
      if (r.isActive) this.setState({ active: r.isActive });

      let newCountryList1 = r.country;
      newCountryList1.countryName =
        newCountryList1.countryCode + "-" + newCountryList1.countryName;

      //routingCountry.country = newCountryList1;
      data.country = newCountryList1;
      this.setState({ routingCountry: data });
    });

    getPublisherCustomerList().then((r) => {
      r.data &&
        this.setState({
          customersList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.customerName.toLowerCase() > b.customerName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });
    getCountryList().then((r) => {
      let newCountryList = [];
      r.data &&
        r.data.map((d) => {
          return newCountryList.push({
            countryID: d.countryID,
            countryCode: d.countryCode,
            countryName: d.countryCode + "-" + d.countryName,
            zoneID: d.zoneID,
            zoneName: d.zoneName,
            zoneCode: d.zoneCode,
            dhlZoneID: d.zoneCode,
            dhlZoneName: d.zoneCode,
            dhlZoneCode: d.dhlZoneCode,
            alternateCountryCode: d.alternateCountryCode,
            alternateCountryName: d.alternateCountryName,
            isActive: d.isActive,
            createdByID: d.createdByID,
            createdBy: d.createdBy,
            createdDate: d.createdDate,
            modifiedByID: d.modifiedByID,
            modifiedBy: d.modifiedBy,
            modifiedDate: d.modifiedDate,
          });
        });

      r.data &&
        this.setState({
          countryList: newCountryList
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.countryName.toLowerCase() > b.countryName.toLowerCase() ? 1 : -1
            ),
        });
    });

    getLocationList().then((r) => {
      r.data &&
        this.setState({
          LocationList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.locationName.toLowerCase() > b.locationName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });

    getDispatchModeList().then((r) => {
      r.data &&
        this.setState(
          {
            DispatchModeList: r.data
              .filter((x) => x.isActive === true)
              .sort((a, b) => {
                return a.dispatchModeCode.toLowerCase() >
                  b.dispatchModeCode.toLowerCase()
                  ? 1
                  : -1;
              }),
          },
          () => {
            let newDispatchModeList = [...this.state.DispatchModeList];
            newDispatchModeList.push({
              dispatchModeCode: "None",
              dispatchModeID: null,
            });
            this.setState({ DispatchModeList: newDispatchModeList });
          }
        );
    });

    getDispatchTypeList().then((r) => {
      r.data &&
        this.setState({
          DispatchTypeList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              //a.countryName.toLowerCase() > b.countryName.toLowerCase() ? 1 : -1
              a.dispatchTypeCodeName.toLowerCase() >
              b.dispatchTypeCodeName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });
    // getDDistributorList().then(r => {
    //   r.data &&
    //     this.setState({
    //       DistributorList: r.data
    //         .filter(x => x.isActive === true)
    //         .sort((a, b) =>
    //           a.distributorCode.toLowerCase() > b.distributorCode.toLowerCase()
    //             ? 1
    //             : -1
    //         )
    //     });
    // });
    getDDistributorList().then((r) => {
      r.data &&
        this.setState(
          {
            DistributorList: r.data
              .filter((x) => x.isActive === true)
              .sort((a, b) => {
                // console.log(a);
                return a.distributorName.toLowerCase() >
                  b.distributorName.toLowerCase()
                  ? 1
                  : -1;
              }),
          },
          () => {
            let newDistributorList = [...this.state.DistributorList];
            newDistributorList.push({
              distributorName: "None",
              distributorID: null,
            });
            this.setState({ DistributorList: newDistributorList });
          }
        );
    });
    getMailingSchemeLists().then((r) => {
      r.data &&
        this.setState(
          {
            msl: r.data
              .filter((x) => x.isActive === true)
              .sort((a, b) => {
                return a.mailingScheme.toLowerCase() >
                  b.mailingScheme.toLowerCase()
                  ? 1
                  : -1;
              }),
          },
          () => {
            let newMSL = [...this.state.msl];
            newMSL.push({
              mailingScheme: "None",
              mailingSchemeID: null,
            });
            this.setState({ msl: newMSL });
          }
        );
    });
  }
  handleChange1 = (event) => {
    const { routingCountry } = this.state;
    let dhlValues = event.target.value;
    dhlValues = this.checkDHlWeightDec(event.target.value.toString());

    routingCountry[event.target.name] = dhlValues;
    this.validateField(event.target.name, dhlValues);
    this.setState({ routingCountry, update: true });
  };

  handleChange = (event) => {
    const { routingCountry } = this.state;
    if (event.target.name === "isActive") {
      routingCountry[event.target.name] = event.target.checked;
      this.setState({ formValid: true, update: true });
    } else {
      routingCountry[event.target.name] = event.target.value;
    }

    this.validateField(event.target.name, event.target.value);
    this.setState({ routingCountry, update: true });
  };

  handleCountryChange = (event, values) => {
    const { routingCountry } = this.state;
    let countryID = values && values.countryID;
    routingCountry["country"] = values;
    routingCountry["countryID"] = countryID;
    this.validateField("country", countryID);
    this.setState({ routingCountry, update: true });
  };
  handleCustomerChange = (event, values) => {
    const { routingCountry } = this.state;
    let customerID = values && values.customerID;
    routingCountry["customer"] = values;
    routingCountry["customerID"] = customerID;
    this.validateField("customer", customerID);
    this.setState({ routingCountry, update: true });
  };
  handleLocationChange = (event, values) => {
    const { routingCountry } = this.state;
    let locationID = values && values.locationID;
    routingCountry["location"] = values;
    routingCountry["locationID"] = locationID;
    this.validateField("location", locationID);
    this.setState({ routingCountry, update: true });
  };
  handleDistributorChange = (event, values) => {
    const { routingCountry } = this.state;
    let distributorID = values && values.distributorID;
    routingCountry["distributor"] = values;
    routingCountry["distributorID"] = distributorID;
    //this.validateField("distributor", distributorID);
    this.setState({ routingCountry, update: true });
  };
  handleDispatchChange = (event, values) => {
    const { routingCountry } = this.state;
    let dispatchTypeID = values && values.dispatchTypeID;
    routingCountry["dispatchType"] = values;
    routingCountry["dispatchTypeID"] = dispatchTypeID;
    this.validateField("dispatch", dispatchTypeID);
    this.setState({ routingCountry, update: true });
  };
  handleDispatchModeChange = (event, values) => {
    const { routingCountry } = this.state;
    let dispatchModeID = values && values.dispatchModeID;
    routingCountry["dispatchMode"] = values;
    routingCountry["dispatchModeID"] = dispatchModeID;
    // this.validateField("dispatchMode", dispatchModeID);
    this.setState({ routingCountry, update: true });
  };
  handleMailingSchemeChange = (event, values) => {
    const { routingCountry } = this.state;
    let mailingSchemeID = values && values.mailingSchemeID;
    routingCountry["mailingScheme"] = values;
    routingCountry["mailingSchemeID"] = mailingSchemeID;
    //this.validateField("mailingScheme", mailingSchemeID);
    this.setState({ routingCountry, update: true });
  };

  validateField(fieldName, value) {
    // console.log(fieldName, value);
    let fieldValidationErrors = this.state.errors;
    let customerValid = this.state.customerValid;
    let countryValid = this.state.countryValid;
    let locationValid = this.state.locationValid;
    let dispatchTypeValid = this.state.dispatchTypeValid;
    let distributorValid = this.state.distributorValid;
    let dispatchModeValid = this.state.dispatchModeValid;
    let mailingschemeValid = this.state.mailingschemeValid;

    let noOfOrdersFromValid = this.state.noOfOrdersFromValid;
    let noOfOrdersToValid = this.state.noOfOrdersToValid;
    let noOfCopiesFromValid = this.state.noOfCopiesFromValid;
    let noOfCopiesToValid = this.state.noOfCopiesToValid;

    let weightFromValid = this.state.weightFromValid;
    let weightToValid = this.state.weightToValid;
    let ppiNumberValid = this.state.ppiNumberValid;
    let ppiLicenseNoValid = this.state.ppiLicenseNoValid;

    let returnAddressValid = this.state.returnAddressValid;
    let carrierSheetSchemeValid = this.state.carrierSheetSchemeValid;
    let carrierSheetModeValid = this.state.carrierSheetModeValid;

    switch (fieldName) {
      case "returnAddress":
        //fieldValidationErrors.returnAddress = "";
        if (value !== "") {
          returnAddressValid = generalValidator(value);
          fieldValidationErrors.returnAddress = returnAddressValid
            ? ""
            : " Please Enter Alphabets only";
        } else {
          returnAddressValid = false;
          fieldValidationErrors.returnAddress = "";
        }
        break;
      case "carrierSheetScheme":
        //fieldValidationErrors.carrierSheetScheme = "";
        if (value !== "") {
          carrierSheetSchemeValid = generalValidator(value);
          fieldValidationErrors.carrierSheetScheme = carrierSheetSchemeValid
            ? ""
            : " Please Enter AlphaNumeric only";
        } else {
          carrierSheetSchemeValid = false;
          fieldValidationErrors.carrierSheetScheme = "";
        }
        break;
      case "carrierSheetMode":
        // fieldValidationErrors.carrierSheetMode = "";
        if (value !== "") {
          carrierSheetModeValid = generalValidator(value);
          fieldValidationErrors.carrierSheetMode = carrierSheetModeValid
            ? ""
            : " Please Enter AlphaNumeric only";
        } else {
          carrierSheetModeValid = false;
          fieldValidationErrors.carrierSheetMode = "";
        }
        break;

      case "ppiNumber":
        if (value !== "") {
          ppiNumberValid = generalValidator(value);
          fieldValidationErrors.ppiNumber = ppiNumberValid
            ? ""
            : " Please Enter Alpha Numeric only";
        } else {
          ppiNumberValid = false;
          fieldValidationErrors.ppiNumber = "";
        }

        break;
      case "ppiLicenseNo":
        if (value !== "") {
          ppiLicenseNoValid = generalValidator(value);
          fieldValidationErrors.ppiLicenseNo = ppiLicenseNoValid
            ? ""
            : " Please Enter AlphaNumeric only";
        } else {
          ppiLicenseNoValid = false;
          fieldValidationErrors.ppiLicenseNo = "";
        }
        break;
      case "weightFrom":
        //fieldValidationErrors.weightFrom = "";
        if (value !== "") {
          weightFromValid = checkrate(value);
          fieldValidationErrors.weightFrom = weightFromValid
            ? ""
            : " Please Enter valid Weight From";
        } else {
          weightFromValid = false;
          fieldValidationErrors.weightFrom = "";
        }

        break;
      case "weightTo":
        //fieldValidationErrors.weightTo = "";
        if (value !== "") {
          weightToValid = checkrate(value);
          fieldValidationErrors.weightTo = weightToValid
            ? ""
            : " Please Enter valid Weight To";
        } else {
          weightToValid = false;
          fieldValidationErrors.weightTo = "";
        }
        break;
      case "noOfCopiesFrom":
        // noOfCopiesFromValid = Numberspecial(value);
        // fieldValidationErrors.noOfCopiesFrom = noOfCopiesFromValid
        //   ? ""
        //   : " Please Enter Numeric only";
        if (value !== "") {
          noOfCopiesFromValid = Reorderqty(value);
          fieldValidationErrors.noOfCopiesFrom = noOfCopiesFromValid
            ? ""
            : " Please Enter Numeric only";
        } else {
          noOfCopiesFromValid = false;
          fieldValidationErrors.noOfCopiesFrom = "";
        }
        break;
      case "noOfCopiesTo":
        // noOfCopiesToValid = onlyNumbers(value);
        // fieldValidationErrors.noOfCopiesTo = noOfCopiesToValid
        //   ? ""
        //   : " Please Enter Numeric only";
        if (value !== "") {
          noOfCopiesToValid = Reorderqty(value);
          fieldValidationErrors.noOfCopiesTo = noOfCopiesToValid
            ? ""
            : " Please Enter Numeric only";
        } else {
          noOfCopiesToValid = false;
          fieldValidationErrors.noOfCopiesTo = "";
        }
        break;
      case "noOfOrdersTo":
        // noOfOrdersToValid = onlyNumbers(value);
        // fieldValidationErrors.noOfOrdersTo = noOfOrdersToValid
        //   ? ""
        //   : " Please Enter Numeric only";
        if (value !== "") {
          noOfOrdersToValid = Reorderqty(value);
          fieldValidationErrors.noOfOrdersTo = noOfOrdersToValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          noOfOrdersToValid = false;
          fieldValidationErrors.noOfOrdersTo = "";
        }

        break;
      case "noOfOrdersFrom":
        //noOfOrdersFromValid = onlyNumbers(value);
        // fieldValidationErrors.noOfOrdersFrom = noOfOrdersFromValid
        //   ? ""
        //   : " Please Enter Numeric only";
        if (value !== "") {
          noOfOrdersFromValid = Reorderqty(value);
          fieldValidationErrors.noOfOrdersFrom = noOfOrdersFromValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          noOfOrdersFromValid = false;
          fieldValidationErrors.noOfOrdersFrom = "";
        }

        break;
      case "location":
        locationValid = onlyNumbers(value);
        fieldValidationErrors.location = locationValid
          ? ""
          : " Please select Location";
        break;
      case "country":
        countryValid = onlyNumbers(value);
        fieldValidationErrors.country = countryValid
          ? ""
          : " Please select Country";
        break;
      case "customer":
        customerValid = onlyNumbers(value);
        fieldValidationErrors.customer = customerValid
          ? ""
          : " Please select Customer";
        break;
      case "dispatch":
        dispatchTypeValid = onlyNumbers(value);
        fieldValidationErrors.dispatchType = dispatchTypeValid
          ? ""
          : " Please select Dispatch Type";
        break;
      case "dispatchMode":
        if (value == null) {
          fieldValidationErrors.dispatchMode = "";
        } else {
          dispatchModeValid = onlyNumbers(value);
          fieldValidationErrors.dispatchMode = dispatchModeValid
            ? ""
            : " Please select Dispatch Mode";
        }
        break;

      case "mailingscheme":
        mailingschemeValid = onlyNumbers(value);
        fieldValidationErrors.mailingscheme = mailingschemeValid
          ? ""
          : " Please select MailingScheme";
        break;
      case "distributor":
        if (value == null) {
          fieldValidationErrors.distributor = "";
        } else {
          distributorValid = onlyNumbers(value);
          fieldValidationErrors.distributor = distributorValid
            ? ""
            : " Please select Distributor";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        customerValid: customerValid,
        countryValid: countryValid,
        locationValid: locationValid,
        dispatchTypeValid: dispatchTypeValid,
        dispatchModeValid: dispatchModeValid,
        mailingschemeValid: mailingschemeValid,
        distributorValid: distributorValid,
        noOfOrdersFromValid: noOfOrdersFromValid,
        noOfOrdersToValid: noOfOrdersToValid,
        noOfCopiesFromValid: noOfCopiesFromValid,
        noOfCopiesToValid: noOfCopiesToValid,
        weightFromValid: weightFromValid,
        weightToValid: weightToValid,
        ppiNumberValid: ppiNumberValid,
        ppiLicenseNoValid: ppiLicenseNoValid,
        returnAddressValid: returnAddressValid,
        carrierSheetModeValid: carrierSheetModeValid,
        carrierSheetSchemeValid: carrierSheetSchemeValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.dispatchTypeValid &&
        this.state.noOfOrdersFromValid &&
        this.state.noOfOrdersToValid &&
        this.state.noOfCopiesFromValid &&
        this.state.noOfCopiesToValid &&
        this.state.weightFromValid &&
        this.state.weightToValid &&
        this.state.ppiNumberValid &&
        this.state.ppiLicenseNoValid,
      // this.state.returnAddressValid &&
      // this.state.carrierSheetSchemeValid &&
      // this.state.carrierSheetModeValid &&
      //this.state.dispatchModeValid &&
      // this.state.distributorValid &&
      //this.state.mailingschemeValid,
    });
    //return this.state.formValid;
  }
  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onKeyPress(event) {
    const re = /[0-9a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  onBlur = (event) => {
    const { routingCountry } = this.state;
    let dhlValues = event.target.value;
    dhlValues = this.addZeroes(dhlValues.toString(), 2);
    routingCountry[event.target.name] = dhlValues;
    this.setState({ routingCountry });
  };

  handleSubmit(e) {
    e.preventDefault();
    let routingCountryID = this.props.match.params.routingCountryID;
    const { routingCountry } = this.state;

    routingCountry.modifiedBy = localStorage.loggedInId;
    putRoutingCountry(routingCountryID, routingCountry, this.props.history);
  }

  render() {
    //console.log(this.state.msl);
    let { classes } = this.props;
    let {
      routingCountry,
      customersList,
      LocationList,
      countryList,
      DispatchModeList,
      msl,
      DispatchTypeList,
      DistributorList,
      active,
    } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Routing Country</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <p className={classes.subHeader}>Enter Customer Information</p>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    disabled
                    includeInputInList
                    className={classes.textField}
                    id="customer"
                    options={customersList}
                    onChange={this.handleCustomerChange}
                    value={routingCountry.customer}
                    getOptionLabel={(option) => option.customerName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Customer"
                        name="customer"
                        id="customer"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={routingCountry.customer}
                        error={this.state.errors.customer}
                        helperText={
                          this.state.errors.customer
                            ? this.state.errors.customer
                            : ""
                        }
                      />
                    )}
                  />

                  <Autocomplete
                    autoComplete
                    disabled
                    includeInputInList
                    className={classes.textField}
                    id="location"
                    options={LocationList}
                    onChange={this.handleLocationChange}
                    value={routingCountry.location}
                    getOptionLabel={(option) => option.locationName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Location"
                        name="location"
                        id="location"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={routingCountry.location}
                        error={this.state.errors.location}
                        helperText={
                          this.state.errors.location
                            ? this.state.errors.location
                            : ""
                        }
                      />
                    )}
                  />

                  <Autocomplete
                    autoComplete
                    disabled
                    includeInputInList
                    className={classes.textField}
                    id="country"
                    options={countryList}
                    onChange={this.handleCountryChange}
                    value={routingCountry.country}
                    getOptionLabel={(option) => option.countryName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        name="country"
                        id="country"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={routingCountry.country}
                        error={this.state.errors.country}
                        helperText={
                          this.state.errors.country
                            ? this.state.errors.country
                            : ""
                        }
                      />
                    )}
                  />
                </div>
                <p className={classes.subHeader}>Enter Criteria Setting</p>
                <div>
                  <TextField
                    required
                    label="No Of Orders From"
                    name="noOfOrdersFrom"
                    inputProps={{
                      maxLength: 5,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    // onKeyPress={this._onCodeKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={routingCountry.noOfOrdersFrom}
                    onChange={this.handleChange}
                    error={this.state.errors.noOfOrdersFrom}
                    helperText={
                      this.state.errors.noOfOrdersFrom
                        ? this.state.errors.noOfOrdersFrom
                        : ""
                    }
                  />
                  <TextField
                    required
                    label="No Of Orders To"
                    name="noOfOrdersTo"
                    inputProps={{
                      maxLength: 5,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    // onKeyPress={this._onCodeKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={routingCountry.noOfOrdersTo}
                    onChange={this.handleChange}
                    error={this.state.errors.noOfOrdersTo}
                    helperText={
                      this.state.errors.noOfOrdersTo
                        ? this.state.errors.noOfOrdersTo
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    required
                    label="No Of Copies From"
                    name="noOfCopiesFrom"
                    inputProps={{
                      maxLength: 5,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    // onKeyPress={this._onCodeKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={routingCountry.noOfCopiesFrom}
                    onChange={this.handleChange}
                    error={this.state.errors.noOfCopiesFrom}
                    helperText={
                      this.state.errors.noOfCopiesFrom
                        ? this.state.errors.noOfCopiesFrom
                        : ""
                    }
                  />

                  <TextField
                    required
                    label="No Of Copies To"
                    name="noOfCopiesTo"
                    inputProps={{
                      maxLength: 5,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    //onKeyPress={this._onCodeKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={routingCountry.noOfCopiesTo}
                    onChange={this.handleChange}
                    error={this.state.errors.noOfCopiesTo}
                    helperText={
                      this.state.errors.noOfCopiesTo
                        ? this.state.errors.noOfCopiesTo
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    required
                    label="Weight From"
                    id="txtdhlWeight"
                    name="weightFrom"
                    onKeyUp={this._onDecimalKeyPress}
                    className={classes.textField}
                    inputProps={{
                      maxLength: 10,
                      minLength: 2,
                      pattern: /^[0-9]\d*(?:\.\d{0,2})?$/,
                    }}
                    margin="normal"
                    autoComplete="off"
                    value={routingCountry.weightFrom}
                    error={this.state.errors.weightFrom}
                    onChange={this.handleChange1}
                    onBlur={this.onBlur}
                    helperText={
                      this.state.errors.weightFrom
                        ? this.state.errors.weightFrom
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Kg</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    required
                    label="Weight To"
                    id="txtdhlWeight"
                    name="weightTo"
                    onKeyUp={this._onDecimalKeyPress}
                    className={classes.textField}
                    inputProps={{
                      maxLength: 10,
                      minLength: 2,
                      pattern: /^[0-9]\d*(?:\.\d{0,2})?$/,
                    }}
                    margin="normal"
                    autoComplete="off"
                    value={routingCountry.weightTo}
                    error={this.state.errors.weightTo}
                    onChange={this.handleChange1}
                    onBlur={this.onBlur}
                    helperText={
                      this.state.errors.weightTo
                        ? this.state.errors.weightTo
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Kg</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <p className={classes.subHeader}>Enter Routing Configuration</p>
                <div style={{ display: "flex", marginLeft: "1px" }}>
                  <TextField
                    required
                    label="Route PPI"
                    name="ppiNumber"
                    inputProps={{
                      maxLength: 20,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingCountry.ppiNumber}
                    onChange={this.handleChange}
                    error={this.state.errors.ppiNumber}
                    helperText={
                      this.state.errors.ppiNumber
                        ? this.state.errors.ppiNumber
                        : ""
                    }
                  />
                  <TextField
                    required
                    label="PPI License No"
                    name="ppiLicenseNo"
                    inputProps={{
                      maxLength: 20,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingCountry.ppiLicenseNo}
                    onChange={this.handleChange}
                    error={this.state.errors.ppiLicenseNo}
                    helperText={
                      this.state.errors.ppiLicenseNo
                        ? this.state.errors.ppiLicenseNo
                        : ""
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    className={classes.textField}
                    id="dispatchType"
                    options={DispatchTypeList}
                    onChange={this.handleDispatchChange}
                    value={routingCountry.dispatchType}
                    getOptionLabel={(option) => option.dispatchTypeCodeName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Dispatch Type"
                        name="dispatchType"
                        id="dispatchType"
                        margin="normal"
                        required
                        style={{ width: "100%" }}
                        value={routingCountry.dispatchType}
                        error={this.state.errors.dispatchType}
                        helperText={
                          this.state.errors.dispatchType
                            ? this.state.errors.dispatchType
                            : ""
                        }
                      />
                    )}
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    className={classes.textField}
                    id="dispatchMode"
                    options={DispatchModeList}
                    onChange={this.handleDispatchModeChange}
                    value={routingCountry.dispatchMode}
                    getOptionLabel={(option) =>
                      option.dispatchModeCode || routingCountry.dispatchMode
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Dispatch Mode"
                        name="dispatchMode"
                        id="dispatchMode"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={routingCountry.dispatchMode}
                        error={this.state.errors.dispatchMode}
                        helperText={
                          this.state.errors.dispatchMode
                            ? this.state.errors.dispatchMode
                            : ""
                        }
                      />
                    )}
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    className={classes.textField}
                    id="mailingScheme"
                    options={msl}
                    onChange={this.handleMailingSchemeChange}
                    value={routingCountry.mailingScheme}
                    getOptionLabel={(option) =>
                      option.mailingScheme || routingCountry.mailingScheme
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Mailing Scheme"
                        name="mailingScheme"
                        id="mailingScheme"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={routingCountry.mailingScheme}
                        error={this.state.errors.mailingScheme}
                        helperText={
                          this.state.errors.mailingScheme
                            ? this.state.errors.mailingScheme
                            : ""
                        }
                      />
                    )}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    className={classes.textField}
                    id="distributor"
                    options={DistributorList}
                    onChange={this.handleDistributorChange}
                    value={routingCountry.distributor}
                    getOptionLabel={(option) =>
                      option.distributorName || routingCountry.distributor
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Distributor"
                        name="distributor"
                        id="distributor"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={routingCountry.distributor}
                        error={this.state.errors.distributor}
                        helperText={
                          this.state.errors.distributor
                            ? this.state.errors.distributor
                            : ""
                        }
                      />
                    )}
                  />
                  <TextField
                    label="Return Address"
                    name="returnAddress"
                    inputProps={{
                      maxLength: 200,
                    }}
                    margin="normal"
                    // required
                    className={classes.textField}
                    value={routingCountry.returnAddress}
                    onChange={this.handleChange}
                    error={this.state.errors.returnAddress}
                    helperText={
                      this.state.errors.returnAddress
                        ? this.state.errors.returnAddress
                        : ""
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Carrier Sheet Mode"
                    name="carrierSheetMode"
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    //required
                    className={classes.textField}
                    value={routingCountry.carrierSheetMode}
                    onChange={this.handleChange}
                    error={this.state.errors.carrierSheetMode}
                    helperText={
                      this.state.errors.carrierSheetMode
                        ? this.state.errors.carrierSheetMode
                        : ""
                    }
                  />
                  <TextField
                    label="Carrier Sheet Scheme"
                    name="carrierSheetScheme"
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    //required
                    className={classes.textField}
                    value={routingCountry.carrierSheetScheme}
                    onChange={this.handleChange}
                    error={this.state.errors.carrierSheetScheme}
                    helperText={
                      this.state.errors.carrierSheetScheme
                        ? this.state.errors.carrierSheetScheme
                        : ""
                    }
                  />
                </div>
                <div>
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      name="isActive"
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          color="primary"
                          checked={routingCountry.isActive}
                          onChange={this.handleChange}
                          value={routingCountry.isActive}
                        />
                      }
                      label="Active"
                    />
                  )}
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div
                  className="button-wrapper"
                  style={{ paddingLeft: "0px", marginLeft: "0px" }}
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/routingCountry")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid && !this.state.update ? (
                      <Button
                        onClick={this.handleSubmit}
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditRoutingCountry));
