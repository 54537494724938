import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  Paper,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { resourceValidation } from "../../../shared/resource";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import {
  updateDHLRate,
  getDHLZoneList,
  getDHLRateDetails,
} from "../masterActions";
import { StateContext } from "../../../shared/globalState";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import InputAdornment from "@material-ui/core/InputAdornment";
import { addZeros } from "../../../shared/tableCommonFunctions";
import { addStyles } from "../../../shared/styles";
const checkrate = new Validator("checkdecimalpoint");

const date = new Date();
const year = date.getFullYear().toString();

class EditDHLRate extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      dhlRateValues: {
        dhlZone: {},
        dhlZoneID: "",
        dhlWeight: "0.000",
        dhlRate: 0,
        dhlYear: year,
        isActive: true,
        createdBy: 1,
      },
      updatedValues: false,
      dhlZoneList: [],
      errors: {},
      active: false,
      dhlZoneIDValid: false,
      dhlWeightValid: false,
      dhlRateValid: false,
      dhlYearValid: false,
      formValid: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    getDHLZoneList().then((r) => {
      this.setState({
        dhlZoneList:
          r.data &&
          r.data.sort((a, b) =>
            a.dhlZoneCode.toLowerCase() > b.dhlZoneCode.toLowerCase() ? 1 : -1
          ),
      });
    });

    let dhlRateId = this.props.match.params.dhlRateId;
    getDHLRateDetails(dhlRateId).then((r) => {
      let data = r;
      data = {
        dhlWeight: addZeros(data.dhlWeight.toString(), 3),
        dhlRate: addZeros(data.dhlRate.toString(), 2),
        dhlZone: data.dhlZone,
        dhlYear: data.dhlYear,
        isActive: data.isActive,
      };

      // let dhlRateValues = r;
      data.dhlYear = r.dhlYear.toString();
      this.setState({ dhlRateValues: data });
      if (r.isActive) this.setState({ active: r.isActive });
      this.validateField("dhlWeight", r.dhlWeight);
      this.validateField("dhlRate", r.dhlRate);
      this.validateField("dhlZone", r.dhlZoneID);
      this.validateField("dhlYear", r.dhlYear);
    });
  }

  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  checkDHlWeightDec1(value) {
    var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    return value;
  }

  handleChange = (event) => {
    this.setState({ updatedValues: true });
    const { dhlRateValues } = this.state;
    if (event.target.name === "isActive") {
      dhlRateValues[event.target.name] = event.target.checked;
    } else {
      let dhlValues = event.target.value;
      dhlValues = this.checkDHlWeightDec(dhlValues.toString());
      dhlRateValues[event.target.name] = dhlValues;
    }
    this.validateField(event.target.name, event.target.value);
    this.setState({ dhlRateValues });
  };
  handleChange1 = (event) => {
    this.setState({ updatedValues: true });
    const { dhlRateValues } = this.state;
    if (event.target.name === "isActive") {
      dhlRateValues[event.target.name] = event.target.checked;
    } else {
      let dhlValues = event.target.value;
      dhlValues = this.checkDHlWeightDec1(dhlValues.toString());
      dhlRateValues[event.target.name] = dhlValues;
    }
    this.validateField(event.target.name, event.target.value);
    this.setState({ dhlRateValues });
  };

  handledhlZoneChange = (event, values) => {
    this.setState({ updatedValues: true });
    const { dhlRateValues } = this.state;
    dhlRateValues["dhlZone"] = values;
    this.setState({ dhlRateValues, formValid: true });
  };

  handleDateChange = (year) => {
    this.setState({ updatedValues: true });
    const { dhlRateValues } = this.state;
    let selectedYear = year && year.getFullYear().toString();
    if (selectedYear) {
      dhlRateValues["dhlYear"] = selectedYear;
      this.validateField("dhlYear", selectedYear);
      this.setState({ dhlRateValues });
    } else {
      this.setState({ dhlYearValid: false });
    }
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let dhlWeightValid = this.state.dhlWeightValid;
    let dhlRateValid = this.state.dhlRateValid;
    let dhlYearValid = this.state.dhlYearValid;
    let dhlZoneIDValid = this.state.dhlZoneIDValid;
    switch (fieldName) {
      case "dhlWeight":
        if (value !== "") {
          dhlWeightValid = checkrate(value);
          fieldValidationErrors.dhlWeight = dhlWeightValid
            ? ""
            : resourceValidation.dhlWeight;
        }
        break;
      case "dhlRate":
        if (value !== "") {
          dhlRateValid = checkrate(value);
          fieldValidationErrors.dhlRate = dhlRateValid
            ? ""
            : resourceValidation.dhlRate;
        }

        break;
      case "dhlYear":
        if (value !== "") {
          dhlYearValid = true;
          fieldValidationErrors.dhlYear = dhlYearValid
            ? ""
            : " Please select year";
        }
        break;
      case "dhlZone":
        if (value !== "") {
          dhlZoneIDValid = true;
          fieldValidationErrors.dhlZone = dhlZoneIDValid
            ? ""
            : "Please select dhl zone";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        dhlWeightValid: dhlWeightValid,
        dhlRateValid: dhlRateValid,
        dhlYearValid: dhlYearValid,
        dhlZoneIDValid: dhlZoneIDValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.state.updatedValues &&
      this.setState({
        formValid:
          this.state.dhlWeightValid &&
          this.state.dhlRateValid &&
          this.state.dhlYearValid &&
          this.state.dhlZoneIDValid,
      });

    return this.state.formValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { dhlRateValues } = this.state;
    let dhlRateId = this.props.match.params.dhlRateId;

    if (dhlRateValues.dhlZone != null) {
      dhlRateValues.dhlZoneID = dhlRateValues.dhlZone.dhlZoneID;
    }

    dhlRateValues.modifiedBy = localStorage.loggedInId;
    updateDHLRate(dhlRateId, dhlRateValues, this.props.history);
  };

  onBlur = (event, decimals) => {
    const { dhlRateValues } = this.state;
    let dhlValues = event.target.value;
    dhlValues = this.addZeroes(dhlValues.toString(), decimals);
    dhlRateValues[event.target.name] = dhlValues;
    this.setState({ dhlRateValues });
  };

  render() {
    let { dhlRateValues, dhlZoneList, active, addZeroes } = this.state;

    const { classes } = this.props;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update DHL Rate</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    disabled
                    variant="filled"
                    autoComplete
                    includeInputInList
                    className={classes.comboBox}
                    id="dhlZone"
                    options={dhlZoneList}
                    onChange={this.handledhlZoneChange}
                    value={dhlRateValues.dhlZone}
                    getOptionLabel={(option) => option.dhlZoneName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="DHL Zone"
                        style={{ width: "100%" }}
                        name="dhlZone"
                        id="dhlZone"
                        margin="normal"
                      />
                    )}
                  />
                  <TextField
                    required
                    label="DHL Weight(Kg)"
                    id="txtdhlWeight"
                    name="dhlWeight"
                    onKeyPress={this._onDecimalKeyPress}
                    className={classes.textField}
                    inputProps={{
                      maxLength: 8,
                    }}
                    margin="normal"
                    autoComplete="off"
                    value={dhlRateValues.dhlWeight}
                    error={this.state.errors.dhlWeight}
                    onChange={this.handleChange}
                    onBlur={(e) => this.onBlur(e, 3)}
                    helperText={
                      this.state.errors.dhlWeight
                        ? this.state.errors.dhlWeight
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Kg</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    required
                    label="DHL Rate($S)"
                    id="txtdhlRate"
                    name="dhlRate"
                    placeholder="DHL Rate"
                    autoComplete="off"
                    inputProps={{
                      maxLength: 8,
                    }}
                    onKeyPress={this._onDecimalKeyPress}
                    className={classes.textField}
                    margin="normal"
                    value={dhlRateValues.dhlRate}
                    error={this.state.errors.dhlRate}
                    onChange={this.handleChange1}
                    onBlur={(e) => this.onBlur(e, 2)}
                    helperText={
                      this.state.errors.dhlRate ? this.state.errors.dhlRate : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$S</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    views={["year"]}
                    required
                    label="DHL Year"
                    id="txtdhlYear"
                    name="dhlYear"
                    margin="normal"
                    value={dhlRateValues.dhlYear}
                    onChange={this.handleDateChange}
                    className={classes.KeyboardDatePicker}
                  />
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      name="isActive"
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          color="primary"
                          checked={dhlRateValues.isActive}
                          onChange={this.handleChange}
                          value={dhlRateValues.isActive}
                        />
                      }
                      label="Active"
                    />
                  )}
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/DHLRate")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditDHLRate));
