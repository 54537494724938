import React from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Paper,
  Divider,
  Typography,
  InputBase,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ViewIcon } from "../../../shared/customIcons";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { GetDisplayWOInJG, getWorkOrderDistributionsList, GetJobGroupsListByWOID } from "./action";
import moment from "moment";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "96%",
    fontSize: "11px !important",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "29%",
    fontSize: "11px !important",
  },
  autocompleteText: {
    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
  search: {
    position: "relative",
    minWidth: "500px",
    display: "flex",
    // "&:onChange": {
    //     color: blue[800]
    //   }
  },
  comboBox: {
    margin: "normal",
    width: "31%",
    fontSize: "11px !important",
  },
  searchIcon: {
    position: "absolute",
    right: "0",
  },
});

class Distribution extends React.Component {
  constructor() {
    super();
    this.state = {
      woDisplayDetails: {
        workOrderID: "",
        customerName: "",
        workOrderNumber: "",
        projectTitle: "",
        jobGroupNumber: "",
      },
      docketsList: [],
    };
  }

  componentDidMount() {
    let workOrderID = this.props.workOrderID;
    let jobGroupID = this.props.jobGroupID;
    GetDisplayWOInJG(workOrderID, jobGroupID).then((r) => {
      this.setState({ woDisplayDetails: r });
    });

    getWorkOrderDistributionsList(workOrderID).then((r) => {
      this.setState({ docketsList: r.data });
    });
  }
  handlCancelClicked = (event, workOrderMode) => {

    // this.props.setSelectedList("genericSystem", "createWorkOrder");
    GetJobGroupsListByWOID(this.props.workOrderID).then((r) => {
      if (r && r.length === 1) {
        this.props.history.push("/workOrders")
      }
      else if (workOrderMode === "view")
        // this.props.history.push("/view_WorkOrder?workOrderID=114")
        this.props.history.push({
          pathname: "/view_WorkOrder",
          search: "?workOrderID=" + this.props.workOrderID,
          state: { workOrderMode: workOrderMode },
        });
      else if (workOrderMode === "edit")
        // this.props.history.push("/view_WorkOrder?workOrderID=this.props.workOrderID")
        this.props.history.push({
          pathname: "/edit_WorkOrder",
          search: "?workOrderID=" + this.props.workOrderID,
          state: { workOrderMode: workOrderMode },
        });
    });
  };
  render() {
    let { woDisplayDetails, docketsList } = this.state;
    let { classes, workOrderMode } = this.props;
    return (
      <div className={classes.root}>
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <Typography
                component="div"
                className="blueBox"
                style={{ width: "auto" }}
              >
                <div className="blueBoxLabel">
                  <div>Customer: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.customerName}
                  </div>
                </div>
                <div className="blueBoxLabel">
                  <div>Work Order: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.workOrderNumber}
                  </div>
                </div>
                <div className="blueBoxLabel">
                  <div>Project Title: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.projectTitle}
                  </div>
                </div>
                <div className="blueBoxLabel">
                  <div>Job Group: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.jobGroupNumber}
                  </div>
                </div>
              </Typography>
            </div>
            <Paper style={{ width: "100%", marginTop: "2%" }}>
              <div
                style={
                  !this.props.clearOpenList
                    ? {
                      overflow: "auto",
                      maxHeight: "calc(100vh - 400px)",
                      maxWidth: "calc(100vw - 167px)",
                    }
                    : {
                      overflow: "auto",
                      maxHeight: "calc(100vh - 400px)",
                      maxWidth: "calc(100vw - 390px)",
                    }
                }
              >
                <Table
                  aria-labelledby="tableTitle"
                  size="small"
                  stickyHeader
                  aria-label="sticky table"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell padding="default" className="headcells">
                        {" "}
                        <span style={{ color: "#306dca" }}>
                          PGS Docket No
                        </span>{" "}
                      </TableCell>
                      <TableCell padding="default" className="headcells">
                        {" "}
                        <span style={{ color: "#306dca" }}>
                          E-Docket No
                        </span>{" "}
                      </TableCell>
                      <TableCell padding="default" className="headcells">
                        {" "}
                        <span style={{ color: "#306dca" }}>
                          Account NO
                        </span>{" "}
                      </TableCell>
                      <TableCell padding="default" className="headcells">
                        {" "}
                        <span style={{ color: "#306dca" }}>
                          Estimated Lodgement date
                        </span>{" "}
                      </TableCell>
                      <TableCell
                        padding="default"
                        align="left"
                        className="headcells"
                      >
                        {" "}
                        <span style={{ color: "#306dca" }}>
                          Submitted Date
                        </span>{" "}
                      </TableCell>
                      <TableCell
                        padding="default"
                        align="left"
                        className="headcells"
                      >
                        {" "}
                        <span style={{ color: "#306dca" }}>Service</span>{" "}
                      </TableCell>
                      <TableCell
                        padding="default"
                        align="left"
                        className="headcells"
                      >
                        {" "}
                        <span style={{ color: "#306dca" }}>PPI No</span>{" "}
                      </TableCell>
                      <TableCell
                        padding="default"
                        align="left"
                        className="headcells"
                      >
                        {" "}
                        <span style={{ color: "#306dca" }}>Type</span>{" "}
                      </TableCell>
                      <TableCell
                        padding="default"
                        align="left"
                        className="headcells"
                      >
                        {" "}
                        <span style={{ color: "#306dca" }}>
                          Tot No of Articles
                        </span>{" "}
                      </TableCell>
                      <TableCell
                        padding="default"
                        align="left"
                        className="headcells"
                      >
                        {" "}
                        <span style={{ color: "#306dca" }}>Action</span>{" "}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ overflow: "hidden" }}>
                    {docketsList.map((row, index) => {
                      return (
                        <TableRow role="checkbox" tabIndex={-1}>
                          <TableCell
                            component="th"
                            scope="row"
                            //padding="none"
                            className="table-content-cell"
                          >
                            <Typography noWrap style={{ fontSize: "14px" }}>
                              {row.pgsDocketNo}
                            </Typography>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            //padding="none"
                            className="table-content-cell"
                          >
                            <Typography noWrap style={{ fontSize: "14px" }}>
                              {row.eDocketNo}
                            </Typography>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            // padding="none"
                            className="table-content-cell"
                          >
                            {" "}
                            <Typography noWrap style={{ fontSize: "14px" }}>
                              {row.accountNo}
                            </Typography>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            //padding="none"
                            className="table-content-right-cell"
                          >
                            <Typography noWrap style={{ fontSize: "14px" }}>
                              {moment(row.estimatedLodgementDate).format(
                                "DD/MM/YYYY"
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            //padding="none"
                            className="table-content-right-cell"
                          >
                            <Typography noWrap style={{ fontSize: "14px" }}>
                              {moment(row.submittedDate).format("DD/MM/YYYY")}
                            </Typography>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            // padding="none"
                            className="table-content-cell"
                          >
                            <Typography noWrap style={{ fontSize: "14px" }}>
                              {row.service}
                            </Typography>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            //padding="none"
                            className="table-content-cell"
                          >
                            <Typography noWrap style={{ fontSize: "14px" }}>
                              {row.ppiNumber}
                            </Typography>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            // padding="none"
                            className="table-content-cell"
                          >
                            <Typography noWrap style={{ fontSize: "14px" }}>
                              {row.type}
                            </Typography>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            // padding="none"
                            className="table-content-right-cell"
                          >
                            <Typography noWrap style={{ fontSize: "14px" }}>
                              {row.totalNoArticles}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              noWrap
                              style={{
                                fontSize: "14px",
                                display: "flex",
                              }}
                            >
                              <div>
                                <span>
                                  <Tooltip title="View">
                                    <Link
                                      to={{
                                        pathname:
                                          "/view_docketPosting/" +
                                          row.docketHeaderID,
                                      }}
                                    >
                                      <span
                                        aria-label="view"
                                        style={{
                                          color: "#6c6b6b",
                                        }}
                                      >
                                        <ViewIcon />
                                      </span>
                                    </Link>
                                  </Tooltip>
                                </span>
                              </div>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </Paper>
            <Divider
              style={{
                marginTop: "25px",
              }}
            />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  // onClick={(e) => this.props.history.push("/workOrders")}
                  onClick={(e) => this.handlCancelClicked(e, workOrderMode)}

                >
                  CANCEL
                </Button>
              </div>
              <div style={{ marginLeft: "1em" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) =>
                    this.props.history.push({
                      pathname: "/schedule",
                      search:
                        "?workOrderID=" +
                        this.props.workOrderID +
                        "&jobGroupID=" +
                        this.props.jobGroupID,
                      state: { workOrderMode: this.props.workOrderMode },
                    })
                  }
                >
                  BACK
                </Button>
              </div>
              <div style={{ marginLeft: "1em" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.props.history.push("/workOrders")}
                >
                  FINISH
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Distribution));
