import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";
import clone from "clone";
import Table from "@material-ui/core/Table";
import StatusText from "../../../shared/statusText";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { Checkbox } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AdvanceSearch from "./search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { StateContext } from "../../../shared/globalState";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import Validator from "../../../shared/validator";
import {
  desc,
  stableSort,
  getSorting,
  DialogActions,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import toastr from "toastr";
import moment from "moment";
import "../../app/App.css";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { ExportCSV } from "../../../shared/ExportCSV";
import {
  SearchIcon,
  DownloadIcon,
  ViewColumnIcon,
  EditIcon,
  ViewIcon,
  AddIcon,
} from "../../../shared/customIcons";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  getInsertMasterList,
  deleteInsertmaster,
  seacrhItemMasterAdvanceSearch,
} from "../action";

const validateMaxlength2 = new Validator("maxlength2");

const columns = [
  {
    id: 17,
    name: "Modified By",
  },

  {
    id: 18,
    name: "Modified Date",
  },
  {
    id: 19,
    name: "Created By",
  },

  {
    id: 20,
    name: "Created Date",
  },
];

// const DialogActions = withStyles(theme => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1)
//   }
// }))(MuiDialogActions);
let initailData = [];
function createData(
  jouranlID,
  insertMasterID,
  customerName,
  insertNo,
  insertName,
  statusName,
  issueDescription,
  acronym,
  volume,
  issue,
  part,
  suppNumber,
  type,
  insertWeight,
  quantity,
  noofPages,
  insertSize,
  foldingRequired,
  modifiedByName,
  modifiedDate,
  createdByName,
  createdDate
) {
  return {
    jouranlID,
    insertMasterID,
    customerName,
    insertNo,
    insertName,
    statusName,
    issueDescription,
    acronym,
    volume,
    issue,
    part,
    suppNumber,
    type,
    insertWeight,
    quantity,
    noofPages,
    insertSize,
    foldingRequired,
    modifiedByName,
    modifiedDate,
    createdByName,
    createdDate,
  };
}

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    isActive,
    rows,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ borderTop: "1px solid #eaeaea", background: "white" }}
          className="table-cell"
        >
          {/* <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "Select All Countrys" }}
              />
            }
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric == true ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.code}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
            //);
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    InsertMasterCode: "",
    InsertMasterName: "",
  });
  const [formValid, setIsformValid] = useState(false);

  const [InsertMasterSearchValue, setValues] = useState({
    InsertMasterCode: "",
    InsertMasterName: "",
  });
  let acnArr = props.roles.filter((u) => u.menuName === "Insert Master");
  let acnObj = acnArr[0];
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    handleDeletePopupClose,
    handleDeleteInsertMaster,
    handleViewColumns,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    selected,
    orderPlacementList,
    editable,
  } = props;

  let excelFileData = clone(orderPlacementList);
  excelFileData.map((d) => {
    delete d["insertMasterID"];
    delete d["jouranlID"];
  });
  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteInsertMaster = () => {
    handleDeleteInsertMaster();
  };
  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = InsertMasterSearchValue;
    searchValues.InsertMasterCode = "";
    searchValues.InsertMasterName = "";
    searchValues = InsertMasterSearchValue;
    setIsformValid(false);
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };
  let expData = clone(
    props.orderPlacementList &&
    props.orderPlacementList.length > 0 &&
    props.orderPlacementList
  );

  expData &&
    expData.length > 0 &&
    expData.map((d) => {
      d["Customer Name"] = d["customerName"];
      d["Insert No."] = d["insertNo"];
      d["Insert Name"] = d["insertName"];
      d["Status"] = d["statusName"];
      d["Issue Description"] = d["issueDescription"];
      d["Acronym"] = d["acronym"];
      d["Volume"] = d["volume"];
      d["Issue"] = d["issue"];
      d["Part"] = d["part"];
      d["Supp Number"] = d["suppNumber"];
      d["Type"] = d["type"];
      d["Act. Weight"] = d["insertWeight"];
      d["Act. Quantity"] = d["quantity"];
      d["No of Pages"] = d["noofPages"];
      d["Insert Size"] = d["insertSize"];
      d["Folding Required"] = d["foldingRequired"];
      d["Modified By"] = d["modifiedByName"];

      d["Modified Date"] = moment(d["modifiedDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );

      d["Created By"] = d["createdByName"];
      d["Created Date"] = moment(d["createdDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );

      delete d["jouranlID"];
      delete d["insertMasterID"];
      delete d["modifiedByName"];
      delete d["modifiedDate"];
      delete d["createdByName"];
      delete d["createdDate"];
      delete d["customerName"];
      delete d["insertNo"];
      delete d["insertName"];
      delete d["statusName"];
      delete d["issueDescription"];
      delete d["acronym"];
      delete d["volume"];
      delete d["issue"];
      delete d["part"];
      delete d["suppNumber"];
      delete d["type"];
      delete d["insertWeight"];
      delete d["quantity"];
      delete d["noofPages"];
      delete d["insertSize"];
      delete d["foldingRequired"];
    });
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {/* {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )} */}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_pubInsertMaster/${selected[0]}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}

                {acnObj &&
                  acnObj.editAction &&
                  editable &&
                  props.selectedInsert != "Cancelled" &&
                  props.selectedInsert != "Processed" && (
                    <Tooltip title="Edit">
                      <Link to={`/edit_pubInsertMaster/${selected[0]}`}>
                        <IconButton
                          aria-label="edit"
                          className={classes.iconHover}
                        >
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  )}
              </div>
            )}
            {acnObj &&
              acnObj.deleteAction &&
              props.selectedInsert != "Cancelled" &&
              props.selectedInsert != "In-Progress" &&
              props.selectedInsert != "Processed" && (
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    onClick={handleClickOpen}
                    disabled={!props.isActive}
                  >
                    <DeleteIcon className={classes.iconHover} />
                  </IconButton>
                </Tooltip>
              )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={props.deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete Insert Master
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to cancel the Insert Master{" "}
                  <span style={{ fontWeight: "600" }}>
                    {props.getInsertNumber && props.getInsertNumber} ?
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={handleDeleteInsertMaster}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {props.searchClicked ? (
                // <Tooltip title="Clear search result">
                <IconButton
                  aria-label="clear"
                  className={classes.iconHover}
                  onClick={(e) => props.clearSearch()}
                >
                  <HighlightOffIcon />
                </IconButton>
              ) : (
                  acnObj &&
                  acnObj.advanceSearchAction && (
                    // </Tooltip>
                    // <Tooltip title="Advance search">
                    <AdvanceSearch
                      onSearchClicked={props.onSearchClicked}
                      onRequestSucceed={props.onRequestSucceed}
                    />
                  )
                  // </Tooltip>
                )}

              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="add"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/add_pubInsertMaster")}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}

              {acnObj && acnObj.exportAction && (
                <ExportCSV csvData={expData} fileName={"Insert_Master"} />
              )}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                handleViewColumns={handleViewColumns}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 10.2;
  const left = 77;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    handleViewColumns,
    open,
    ...other
  } = props;
  const [column, setColumnIds] = React.useState(valueProp);
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  }, [valueProp, open]);

  const handleClose = () => {
    onClose(setColumnIds);
  };

  const handleChange = (event) => {
    setColumnIds(event.target.value);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => handleViewColumns(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class InsertMaster extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    selectedRows: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    editable: true,
    removable: true,
    orderPlacementList: [],
    loading: false,
    selectedInsert: {},
    selectedInsertId: [],
    selectedShowColumns: [],
    searchPopupOpen: false,
    InsertMasterSearchValues: {},
    searchClicked: false,
    isActive: true,
    headCells: [
      {
        code: "customerName",
        id: 1,
        show: false,
        numeric: false,
        disablePadding: true,
        label: "Customer",
        show: true,
      },
      {
        code: "insertNo",
        id: 2,
        show: false,
        numeric: false,
        disablePadding: true,
        label: "Insert No.",
        show: true,
      },
      {
        code: "insertName",
        id: 3,
        numeric: false,
        disablePadding: false,
        label: "Insert Name",
        show: true,
      },
      {
        id: 4,
        code: "statusName",
        numeric: false,
        disablePadding: false,
        label: "Status",
        show: true,
      },
      {
        id: 5,
        code: "issueDescription",
        numeric: false,
        disablePadding: false,
        label: "Issue Description",
        show: true,
      },
      {
        id: 6,
        code: "acronym",
        numeric: false,
        disablePadding: false,
        label: "Acronym",
        show: true,
      },
      {
        id: 7,
        code: "volume",
        numeric: false,
        disablePadding: false,
        label: "Volume",
        show: true,
      },
      {
        id: 8,
        code: "issue",
        numeric: false,
        disablePadding: false,
        label: "Issue",
        show: true,
      },

      {
        id: 9,
        code: "part",
        numeric: false,
        disablePadding: false,
        label: "Part",
        show: true,
      },

      {
        id: 10,
        code: "suppNumber",
        numeric: false,
        disablePadding: false,
        label: "Supp Number",
        show: true,
      },

      {
        id: 11,
        code: "type",
        numeric: false,
        disablePadding: false,
        label: "Type",
        show: true,
      },
      {
        id: 12,
        code: "insertWeight",
        numeric: false,
        disablePadding: false,
        label: "Act. Weight",
        show: true,
      },
      {
        id: 13,
        code: "quantity",
        numeric: false,
        disablePadding: false,
        label: "Act. Quantity",
        show: true,
      },
      {
        id: 14,
        code: "noofPages",
        numeric: false,
        disablePadding: false,
        label: "No Of Pages",
        show: true,
      },
      {
        id: 15,
        code: "insertSize",
        numeric: false,
        disablePadding: false,
        label: "Insert Size",
        show: true,
      },
      {
        id: 16,
        code: "foldingRequired",
        numeric: false,
        disablePadding: false,
        label: "Folding Required",
        show: true,
      },
      {
        id: 17,
        code: "modifiedByName",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 18,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 19,
        code: "createdByName",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
      {
        id: 20,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
    ],
  };
  handleViewColumns = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  componentDidMount() {
    this.props.handleSettingHeader("Insert Master");
    this.props.setSelectedList("publisherMainLabelOpen", "pubInsertMaster");

    const [{ store }, dispatch] = this.context;
    getInsertMasterList().then((r) => {
      dispatch({
        type: "getInsertMasterList",
        newStore: r.data,
      });
      // this.getAllInsertMaster(r.data);
      this.getAllInsertMaster(r.data);
    });
    this.setState({ loading: true });
  }
  shouldComponentUpdate(s, p) {
    const [{ store }, dispatch] = this.context;
    // if (this.state.rows.length !== store.InsertMasterList.length) {
    //   !this.state.searchClicked &&
    //     this.getAllInsertMaster(store.InsertMasterList);
    // }
    // if (this.state.rows.length !== 0) {
    //   this.state.rows.map((cl, i) => {
    //     if (cl.ststus != store.InsertMasterList[i].isActive)
    //       !this.state.searchClicked &&
    //         this.getAllPostageList(store.InsertMasterList);
    //   });
    // }
    return true;
  }
  // onRequestSucceed = deleted => {
  //   const [{ store }, dispatch] = this.context;
  //   getInsertMaster().then(r => {
  //     dispatch({
  //       type: "getInsertMasterList",
  //       newStore: r.data
  //     });
  //     if (deleted) this.setState({ searchClicked: false, selected: [] });
  //     this.getAllInsertMaster(r.data);
  //   });
  // };
  getAllInsertMaster = (lpList) => {
    let list = lpList;
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        newRows.push(
          createData(
            d.jouranlID,
            d.insertMasterID,
            d.customerName,
            d.insertNo,
            d.insertName,
            d.statusName,
            d.issueDescription,
            d.acronym,
            d.volume,
            d.issue,
            d.part,
            d.suppNumber,
            d.type,
            d.actualInsertWeight,
            d.actualQuantity,
            d.noofPages ? +d.noofPages : d.noofPages,
            d.insertSize,
            d.foldingRequired,
            d.modifiedByName,
            d.modifiedDate,
            d.createdByName,
            d.createdDate
          )
        );
      });
    this.setState({ rows: newRows, loading: false });
    initailData = newRows;
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleClick(event, data, id) {
    let newSelected = [];
    let selected = [];
    let newSelectedRows = [];

    if (event.target.checked) {
      newSelected = newSelected.concat(selected, id.insertMasterID);
      newSelectedRows = newSelected.concat(id.insertNo);
      this.setState({
        selected: newSelected,
        selectedInsert: id.statusName,
        selectedInsertId: id.insertMasterID,
        selectedRows: newSelectedRows,
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedInsert: id.statusName,
        selectedinsertId: id.insertMasterID,
        selectedRows: newSelectedRows,
      });
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleDeleteInsertMaster = () => {
    let { selected } = this.state;
    const [{ store }, dispatch] = this.context;
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteInsertmaster(modifiedBy, deletionIds).then((r) => {
      getInsertMasterList().then((r) => {
        dispatch({
          type: "getInsertMasterList",
          newStore: r.data,
        });
        this.getAllInsertMaster(r.data);
      });
      // this.props.history.push("/CollectionNote");
      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };
  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchInsertMaster = (handleSearchInsertMaster) => {
    const [{ store }, dispatch] = this.context;

    let InsertMasterValues = handleSearchInsertMaster;
    let InsertMasterCode = InsertMasterValues.InsertMasterCode;
    let InsertMasterName = InsertMasterValues.InsertMasterName;
    let reqBody = { InsertMasterCode, InsertMasterName };

    this.setState({ searchPopupOpen: false, searchClicked: true });
  };

  onSearchClicked = (reqBody) => {
    this.setState({ rows: [], loading: true });
    seacrhItemMasterAdvanceSearch(reqBody).then((r) => {
      this.getAllInsertMaster(r.data);
    });
    this.setState({ searchClicked: true });
  };
  clearSearch = () => {
    const [{ store }, dispatch] = this.context;
    this.setState({ rows: [], loading: true });
    getInsertMasterList().then((r) => {
      this.getAllInsertMaster(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  render() {
    const [{ store }, dispatch] = this.context;
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      rows,
      selectedInsert,
      selectedInsertId,
      headCells,
      selectedRows,
    } = this.state;
    const isSelected = (id) => selected.indexOf(id) !== -1;

    let getInsertNumber =
      selectedRows &&
      selectedRows.length > 0 &&
      selectedRows.map((a) => {
        return a;
      });

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedInsert={selectedInsert}
            selectedInsertId={selectedInsertId}
            history={this.props.history}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchInsertMaster={this.handleSearchInsertMaster}
            InsertMasterSearchValue={this.state.InsertMasterSearchValue}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteInsertMaster={this.handleDeleteInsertMaster}
            handleViewColumns={this.handleViewColumns}
            headCells={headCells}
            orderPlacementList={rows}
            selected={selected}
            onSearchClicked={this.onSearchClicked}
            editable={this.state.editable}
            removable={this.state.removable}
            selectedRows={selectedRows}
            isActive={this.state.isActive}
            getInsertNumber={getInsertNumber[1]}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  rows={this.state.rows}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={e => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={rows.length}
                  headCells={this.state.headCells}
                  getInsertNumber={getInsertNumber[1]}
                  acronymList={clone(this.state.rows)}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {rows.length > 0 ? (
                    stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.insertMasterID);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive == true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    onClick={(event) =>
                                      this.handleClick(
                                        event,
                                        row.insertNo,
                                        row,
                                        row.insertMasterID
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.customerName &&
                                  row.customerName.length > 10 ? (
                                    <Tooltip title={row.customerName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.customerName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.insertNo && row.insertNo.length > 10 ? (
                                    <Tooltip title={row.insertNo}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.insertNo}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.insertNo}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.insertName &&
                                    row.insertName.length > 10 ? (
                                      <Tooltip title={row.insertName}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.insertName}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.insertName}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.statusName &&
                                    row.statusName.length > 10 ? (
                                      <Tooltip title={row.statusName}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.statusName == "Submitted" ? (
                                            <StatusText color={"#16831F"}>
                                              Submitted
                                            </StatusText>
                                          ) : row.statusName == "UnSubmitted" ? (
                                            <StatusText color={"#F4B43C"}>
                                              UnSubmitted
                                            </StatusText>
                                          ) : row.statusName == "Recieved" ? (
                                            <StatusText color={"#0091ff"}>
                                              Recieved
                                            </StatusText>
                                          ) : row.statusName == "Cancelled" ? (
                                            <StatusText color={"red"}>
                                              Cancelled
                                            </StatusText>
                                          ) : row.statusName == "Processed" ? (
                                            <StatusText
                                              color={"rgb(0, 145, 255)"}
                                            >
                                              Processed
                                            </StatusText>
                                          ) : (
                                                      <StatusText color={"grey"}>
                                                        {row.statusName}
                                                      </StatusText>
                                                    )}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.statusName == "Submitted" ? (
                                          <StatusText color={"#16831F"}>
                                            Submitted
                                          </StatusText>
                                        ) : row.statusName == "UnSubmitted" ? (
                                          <StatusText color={"#F4B43C"}>
                                            UnSubmitted
                                          </StatusText>
                                        ) : row.statusName == "Processed" ? (
                                          <StatusText color={"#0091ff"}>
                                            Processed
                                          </StatusText>
                                        ) : row.statusName == "Cancelled" ? (
                                          <StatusText color={"red"}>
                                            Cancelled
                                          </StatusText>
                                        ) : (
                                                  <StatusText color={"grey"}>
                                                    {row.statusName}
                                                  </StatusText>
                                                )}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap>
                                  {/* style={{ color: makeStatus(row.status) }} */}
                                  {row.issueDescription &&
                                    row.issueDescription.length > 10 ? (
                                      <Tooltip title={row.issueDescription}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.issueDescription}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.issueDescription}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.acronym && row.acronym.length > 10 ? (
                                    <Tooltip title={row.acronym}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.acronym}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.acronym}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.volume && row.volume.length > 10 ? (
                                  <Tooltip title={row.volume}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.volume}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.volume}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.issue && row.issue.length > 10 ? (
                                  <Tooltip title={row.issue}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issue}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issue}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.part && row.part.length > 10 ? (
                                  <Tooltip title={row.part}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.part}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.part}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.suppNumber &&
                                  row.suppNumber.length > 10 ? (
                                    <Tooltip title={row.suppNumber}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.suppNumber}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.suppNumber}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.type && row.type.length > 10 ? (
                                  <Tooltip title={row.type}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.type}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.type}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                <Typography
                                  noWrap
                                  style={{ fontSize: "14px" }}
                                >
                                  {row.insertWeight}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                alight="right"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                <Typography
                                  noWrap
                                  style={{ fontSize: "14px" }}
                                >
                                  {row.quantity}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                className="table-content-right-cell"
                                padding="default"
                              >
                                {row.noofPages && row.noofPages.length > 10 ? (
                                  <Tooltip title={row.noofPages}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noofPages}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noofPages}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.insertSize &&
                                  row.insertSize.length > 10 ? (
                                    <Tooltip title={row.insertSize}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.insertSize}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.insertSize}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[15].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.foldingRequired &&
                                  row.foldingRequired.length > 10 ? (
                                    <Tooltip title={row.foldingRequired}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.foldingRequired}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.foldingRequired}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[16].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.modifiedByName &&
                                  row.modifiedByName.length > 10 ? (
                                    <Tooltip title={row.modifiedByName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[17].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[18].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.createdByName &&
                                  row.createdByName.length > 10 ? (
                                    <Tooltip title={row.createdByName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.createdByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[19].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={10} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(InsertMaster);
