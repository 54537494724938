import React from "react";
import { withRouter } from "react-router-dom";
import {
  Input,
  TextField,
  Button,
  Paper,
  Divider,
  FormControlLabel,
  Typography,
  InputBase,
  Checkbox,
  Switch,
  Tooltip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import "date-fns";
import SyncIcon from "@material-ui/icons/Sync";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SearchIcon from "@material-ui/icons/Search";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {
  GetDisplayWOInJG,
  // getOrderList,
  DeleteJobGroupProcess,
  getProcessList2,
  postJobGroupProcesses,
  GetProcesListByJobGroupID,
  GetSelectOrdersForProcess,
  DeleteJobGroupProcessOrderMapping,
  GetJobGroupsListByWOID
} from "./action";
import IconButton from "@material-ui/core/IconButton";
import clone from "clone";
const headCells = [
  {
    id: 1,
    code: "processCategory",
    numeric: false,
    disablePadding: true,
    label: "Process Category",
    show: true,
    align: "left",
  },
  {
    id: 2,
    code: "processDescription",
    numeric: false,
    disablePadding: true,
    label: "Process Description",
    show: true,
  },
  {
    id: 3,
    code: "processInstruction",
    numeric: false,
    disablePadding: false,
    label: "Process Instruction",
    show: true,
  },
  {
    id: 4,
    code: "noOfOrder",
    //numeric: false,
    disablePadding: false,
    label: "No of Orders",
    show: true,
  },
  {
    id: 5,
    code: "quantity",
    numeric: false,
    disablePadding: true,
    label: "Quantity",
    show: true,
  },
  {
    id: 6,
    code: "billable",
    numeric: false,
    disablePadding: true,
    label: "Billable",
    show: true,
  },
  {
    id: 7,
    code: "owc",
    numeric: false,
    disablePadding: true,
    label: "OWC",
    show: true,
  },
  {
    id: 8,
    code: "action",
    numeric: false,
    disablePadding: true,
    label: "Actions",
    show: true,
  },
];
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            //align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={
              i === headCells.length - 1
                ? {
                  borderRight: "1px solid #eaeaea",
                }
                : i === 0
                  ? {
                    borderLeft: "1px solid #eaeaea",
                  }
                  : {}
            }
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em 0",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "29%",
    fontSize: "11px !important",
  },
  autocompleteText: {
    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
  search: {
    position: "relative",
    minWidth: "500px",
    display: "flex",
  },
  searchIcon: {
    position: "absolute",
    right: "0",
  },
});
let workOrderMode = "";
class Process extends React.Component {
  constructor() {
    super();
    this.state = {
      materialRecvDate: new Date().toLocaleDateString(),
      produtionDate: new Date(),
      mailingDate: new Date(),
      orderPopupOpen: false,
      processPopupOpen: false,
      ordersList: [],
      processList: [],
      selectedProcessList: [],
      selectedOrdersList: [],
      selected: [],
      selectedProcess: [],
      selectedOrder: [],
      filteredProcessList: [],
      filteredOrdersList: [],
      orderFilterStr: "",
      processFilterStr: "",
      rows: [],
      selectedEnable: [],
      addEnable: true,
      changesUpdated: false,
      resetEnable: false,
      pickOrderEnable: false,
      woDisplayDetails: {
        workOrderID: "",
        customerName: "",
        workOrderNumber: "",
        projectTitle: "",
        jobGroupNumber: "",
      },
    };
  }

  componentDidMount() {
    this.props.handleDrawerClose();
    workOrderMode =
      this.props.location.state && this.props.location.state.workOrderMode;
    let workOrderID = this.props.workOrderID;

    let jobGroupID = +this.props.jobGroupID;
    this.props.jobGroupID &&
      GetProcesListByJobGroupID(jobGroupID).then((r) => {
        //  let enable = r.length > 0 ? true : false;
        this.setState({ selectedProcessList: r });
      });
    GetDisplayWOInJG(workOrderID, jobGroupID).then((r) => {
      this.setState({ woDisplayDetails: r }, () => {
        console.log(this.state.woDisplayDetails);
        let dsId = localStorage.departmentCode
        getProcessList2(this.state.woDisplayDetails.customerID, dsId).then((r) => {
          let newFiltered = this.GetNewFilteredProcessList(r.data);

          r &&
            r.data &&
            this.setState({
              processList:
                r &&
                r.data.sort((a, b) =>
                  a.processCategory.toLowerCase() > b.processCategory.toLowerCase()
                    ? 1
                    : -1
                ),
              filteredProcessList: newFiltered.sort((a, b) =>
                a.processCategory.toLowerCase() > b.processCategory.toLowerCase()
                  ? 1
                  : -1
              ),
            });
        });
      });
    });



    if (this.state.orderFilterStr === "") {
      this.setState({ filteredOrdersList: this.state.ordersList });
    }

    if (this.state.processFilterStr === "") {
      let newFiltered = this.GetNewFilteredProcessList(this.state.processList);
      this.setState({ filteredProcessList: newFiltered });
    }
  }

  getPickOrderData = async (r) => {
    let jobGroupID = this.props.jobGroupID;
    await GetSelectOrdersForProcess(jobGroupID, r.customerID).then((r) => {
      r &&
        r.data &&
        this.setState({
          ordersList:
            r &&
            r.data.sort((a, b) =>
              a.orderTitle.toLowerCase() > b.orderTitle.toLowerCase() ? 1 : -1
            ),
          filteredOrdersList:
            r &&
            r.data.sort((a, b) =>
              a.orderTitle.toLowerCase() > b.orderTitle.toLowerCase() ? 1 : -1
            ),
        });
    });
  };

  handleProcessPopupOpen = () => {
    this.setState({
      processPopupOpen: true,
      //filteredProcessList: this.state.processList
    });
  };

  GetNewFilteredProcessList = (processList) => {
    let newFiltered = [];
    if (processList && processList.length > 0) {
      newFiltered = processList;
    } else {
      newFiltered = this.state.filteredProcessList;
    }

    this.state.selectedProcessList.forEach(function (item) {
      const index = newFiltered.findIndex(
        (x) => x.processID === item.processID
      );

      // if (index > -1) {
      //   newFiltered = newFiltered
      //     .slice(0, index)
      //     .concat(newFiltered.slice(index + 1, newFiltered.length));
      // }
    });

    newFiltered = newFiltered.sort((a, b) =>
      a.processCategory.toLowerCase() > b.processCategory.toLowerCase() ? 1 : -1
    );

    return newFiltered;
  };

  handlePickOrderPopupOpen = () => {
    GetDisplayWOInJG(this.props.workOrderID, this.props.jobGroupID).then(
      (r) => {
        if (r) {
          this.getPickOrderData(r);
        }
      }
    );
    this.setState({
      orderPopupOpen: true,
      //filteredOrdersList: this.state.ordersList
    });
  };

  handleOrderPopupClose = () => {
    this.setState({
      orderPopupOpen: false,
      selectedOrder: [],
    });
  };

  handleProcessPopupClose = () => {
    this.setState({ processPopupOpen: false, selectedProcess: [] });
  };

  handleAddOrderPopupSubmit = async () => {
    let selectedList = [...this.state.selectedOrder];

    let selectedOrdersList = [],
      quantity = 0;
    let orderList = [...this.state.ordersList];
    selectedList.forEach(function (item) {
      let order = orderList.find((x) => x.orderID === item);
      if (order && order !== 0) {
        selectedOrdersList.push(order);
      }
    });

    selectedOrdersList.forEach((order, i) => {
      quantity = quantity + order.orderQty;
    });

    let checkedProcess = [...this.state.selected];
    let newselectedProcessList = [];
    this.state.selectedProcessList.forEach(function (item, index) {
      if (checkedProcess.indexOf(item.processID) !== -1) {
        var ids = new Set(item["ordersGroupList"].map(d => d.orderID));
        var merged = [...item["ordersGroupList"], ...selectedOrdersList.filter(d => !ids.has(d.orderID))];
        console.log(merged);
        // item["ordersGroupList"] = [
        //   ...item["ordersGroupList"],
        //   ...selectedOrdersList,
        // ];
        item["ordersGroupList"] = merged;
        item["noOfOrders"] = selectedOrdersList.length;
        item["quantity"] = quantity;
      }

      newselectedProcessList.push(item);
    });
    await this.setState({
      selectedOrdersList: selectedOrdersList,
      orderPopupOpen: false,
      selectedProcessList: newselectedProcessList,
      changesUpdated: true,
      selectedOrder: [],
      selectedEnable: this.state.selected,
      selected: [],
    });
  };

  handleChange = (event, index) => {
    const { selectedProcessList } = this.state;
    if (event.target.name === "isBillable" || event.target.name === "isOWC") {
      selectedProcessList[index][event.target.name] = event.target.checked;
    } else {
      selectedProcessList[index][event.target.name] = event.target.value;
    }
    this.setState({ selectedProcessList, changesUpdated: true });

    //this.validateField(event.target.name, event.target.value);
  };

  handleAddProcessPopupSubmit = () => {
    let {
      selectedProcess,
      selectedProcessList,
      filteredProcessList,
      processList,
    } = { ...this.state };
    let newSelectedProcessList = [],
      newProcess = {};
    this.setState({ processFilterStr: "" });
    let newFiltered1 = this.GetNewFilteredProcessList(this.state.processList);
    this.setState({ filteredProcessList: newFiltered1 });
    let newFilteredProcessList = filteredProcessList;

    if (selectedProcessList.length > 0) {
      newSelectedProcessList = selectedProcessList;
    }

    if (filteredProcessList.length > 0) {
      newFilteredProcessList = processList;
    }
    // console.log(processList)
    selectedProcess.forEach(function (item) {
      let process = processList.find((x) => x.processID === item);
      // if (Object.keys(process).map(k => {
      //   console.log(k)
      // }))
      // console.log("processCategoryID" in process)
      // console.log("isBillable" in process)
      if (process && process !== 0) {
        newProcess = {
          processID: process.processID,
          processCategoryID: process.processCategoryID,
          processCategory: process.processCategory,
          processDescription: process.processDescription,
          quantity: process.quantity ? process.quantity : 0,
          isBillable: "isBillable" in process ? process.isBillable : true,
          isOWC: process.isOWC ? process.isOWC : false,
          noOfOrders: process.noOfOrders ? process.noOfOrders : 0,
          processInstruction:
            process.processInstruction !== undefined &&
              process.processInstruction !== null
              ? process.processInstruction
              : "",
          isActive: true,
          ordersGroupList: [],
        };
        newSelectedProcessList.push(newProcess);

        const index = newFilteredProcessList.findIndex(
          (x) => x.processID === item
        );
        if (index > -1) {
          newFilteredProcessList = newFilteredProcessList
            .slice(0, index)
            .concat(
              newFilteredProcessList.slice(
                index + 1,
                newFilteredProcessList.length
              )
            );
        }
      }
    });

    if (newFilteredProcessList.length > 0) {
      newFilteredProcessList = newFilteredProcessList.sort((a, b) =>
        a.processCategory.toLowerCase() > b.processCategory.toLowerCase()
          ? 1
          : -1
      );
    }

    this.setState({
      // filteredProcessList: newFilteredProcessList,
      selectedProcessList: newSelectedProcessList,
      processPopupOpen: false,
      resetEnable: true,
      changesUpdated: true,
      selectedProcess: [],
      selectedEnable: [],
    });
  };

  handleSearchOrder = () => {
    let { orderFilterStr, ordersList } = this.state;

    let filter = ordersList.filter((r) => {
      return (
        //r.orderID.toString().includes(orderFilterStr.toLowerCase()) ||
        r.orderCode.toLowerCase().includes(orderFilterStr.toLowerCase()) ||
        r.orderGroupCode.toLowerCase().includes(orderFilterStr.toLowerCase()) ||
        r.fileName.toLowerCase().includes(orderFilterStr.toLowerCase()) ||
        r.orderTitle.toLowerCase().includes(orderFilterStr.toLowerCase()) ||
        r.orderQty.toString().includes(orderFilterStr.toLowerCase())
      );
    });
    this.setState({ filteredOrdersList: filter });
  };

  handleSearchProcess = () => {
    let { processFilterStr, filteredProcessList } = this.state;

    let filter = filteredProcessList.filter((r) => {
      return (
        r.processCategory
          .toLowerCase()
          .includes(processFilterStr.toLowerCase()) ||
        r.processDescription
          .toLowerCase()
          .includes(processFilterStr.toLowerCase())
      );
    });
    this.setState({ filteredProcessList: filter });
  };

  handleChangeSearchProcess = (event) => {
    this.setState({ processFilterStr: event.target.value });
    if (event.target.value === "") {
      let newFiltered = this.GetNewFilteredProcessList(this.state.processList);
      this.setState({ filteredProcessList: newFiltered });
    }
  };

  handleChangeSearchOrder = (event) => {
    this.setState({ orderFilterStr: event.target.value });
    if (event.target.value === "") {
      this.setState({ filteredOrdersList: this.state.ordersList });
    }
  };

  handleProcessClick = (event, processID) => {
    const selectedIndex = this.state.selectedProcess.indexOf(processID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selectedProcess, processID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selectedProcess.slice(1));
    } else if (selectedIndex === this.state.selectedProcess.length - 1) {
      newSelected = newSelected.concat(this.state.selectedProcess.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selectedProcess.slice(0, selectedIndex),
        this.state.selectedProcess.slice(selectedIndex + 1)
      );
    }
    this.setState({ selectedProcess: newSelected });
  };

  handleOrderClick = (event, orderID) => {
    const selectedIndex = this.state.selectedOrder.indexOf(orderID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selectedOrder, orderID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selectedOrder.slice(1));
    } else if (selectedIndex === this.state.selectedOrder.length - 1) {
      newSelected = newSelected.concat(this.state.selectedOrder.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selectedOrder.slice(0, selectedIndex),
        this.state.selectedOrder.slice(selectedIndex + 1)
      );
    }
    this.setState({ selectedOrder: newSelected });
  };

  handleProcessSubmit = (event) => {
    let createdBy = localStorage.loggedInId;
    let processList = [];
    let workOrderID = this.props.workOrderID;
    let jobGroupID = this.props.jobGroupID;
    if (
      this.state.changesUpdated &&
      this.state.selectedProcessList.length > 0
    ) {
      this.state.selectedProcessList.forEach(function (process) {
        let jobStockOrderMappingList = [];
        let jobGroupProcessID = 0,
          processInstruction = "";

        if (process.jobGroupProcessID) {
          jobGroupProcessID = process.jobGroupProcessID;
        }

        if (
          process.processInstruction !== undefined &&
          process.processInstruction !== null
        ) {
          processInstruction = process.processInstruction;
        }

        if (process.ordersGroupList && process.ordersGroupList.length > 0) {
          process.ordersGroupList &&
            process.ordersGroupList.length > 0 &&
            process.ordersGroupList.forEach(function (order) {
              let jobOrder = {
                jobOrderID: order.jobOrderID,
                isActive: true,
                createdBy,
              };
              jobStockOrderMappingList.push(jobOrder);
            });
        }
        let newProcess = {
          jobGroupProcessID: jobGroupProcessID,
          jobGroupID: jobGroupID,
          processID: process.processID,
          quantity: process.quantity,
          isBillable: process.isBillable ? process.isBillable : false,
          isOWC: process.isOWC ? process.isOWC : false,
          noOfOrders: process.noOfOrders,
          processInstruction: processInstruction,
          jobStockOrderMappingList,
          isActive: true,
          createdBy,
        };
        processList.push(newProcess);
      });

      postJobGroupProcesses(
        this.props.workOrderID,
        this.props.jobGroupID,
        processList,
        this.props.history,
        workOrderMode
      );
    } else {
      this.props.history.push({
        pathname: "/stock",
        search: "?workOrderID=" + workOrderID + "&jobGroupID=" + jobGroupID,
        state: { workOrderMode: workOrderMode },
      });
    }
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = this.state.selectedProcessList.map(
        (n) => n.processID
      );
      this.setState({
        selected: newSelecteds,
        pickOrderEnable: true,
      });
      return;
    }
    this.setState({ selected: [], pickOrderEnable: true });
  };

  handleClick = (event, name) => {
    let selected = this.state.selected;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({
      selected: newSelected,
      pickOrderEnable: true,
    });
  };

  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handlePanelChange = (processID) => (event) => {
    const { selectedEnable } = this.state;
    const selectedIndex = selectedEnable.indexOf(processID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedEnable, processID);
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedEnable.slice(0, selectedIndex),
        selectedEnable.slice(selectedIndex + 1)
      );
    }
    this.setState({
      selectedEnable: newSelected,
    });
  };

  handleProcessSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = this.state.filteredProcessList.map(
        (n) => n.processID
      );
      this.setState({ selectedProcess: newSelecteds });
      return;
    }
    this.setState({ selectedProcess: [] });
  };

  handleOrdersSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = this.state.filteredOrdersList.map((n) => n.orderID);
      this.setState({ selectedOrder: newSelecteds });
      return;
    }
    this.setState({ selectedOrder: [] });
  };

  handleClickResetButton = (event) => {
    let newProcessList = this.state.selectedProcessList,
      newFilteredProcessList = this.state.filteredProcessList,
      processList = this.state.processList,
      newSelectedList = [];
    newProcessList.forEach(function (item, index) {
      if (
        item.jobGroupProcessID !== undefined &&
        item.jobGroupProcessID !== null &&
        item.jobGroupProcessID !== 0
      ) {
        newSelectedList.push(item);
      }

      const processIndex = newFilteredProcessList.findIndex(
        (x) => x.processID === item.processID
      );

      if (processIndex === -1) {
        let newProcess = newProcessList.find(
          (x) => x.processID === item.processID
        );
        if (newProcess && newProcess !== 0) {
          newFilteredProcessList.push(newProcess);
        }
      }
    });

    this.setState({
      filteredProcessList: newFilteredProcessList.sort((a, b) =>
        a.processCategory.toLowerCase() > b.processCategory.toLowerCase()
          ? 1
          : -1
      ),
      selectedProcessList: newSelectedList,
      changesUpdated: true,
      resetEnable: false,
    });
  };

  handlCancelClicked = (event, workOrderMode) => {
    // if (workOrderMode === "edit") {
    //   this.props.history.push({
    //     pathname: "/edit_WorkOrder",
    //     search: "?workOrderID=" + this.props.workOrderID,
    //     state: { workOrderMode: this.props.workOrderMode }
    //   });
    // } else if (workOrderMode === "view" || this.props.status == "Completed") {
    //   this.props.history.push({
    //     pathname: "/view_WorkOrder",
    //     search: "?workOrderID=" + this.props.workOrderID,
    //     state: { workOrderMode: this.props.workOrderMode }
    //   });
    // } else {
    //   this.props.history.push("/workOrders");
    // }
    GetJobGroupsListByWOID(this.props.workOrderID).then((r) => {
      if (r && r.length === 1) {
        this.props.history.push("/workOrders")
      }
      else if (workOrderMode === "view")
        // this.props.history.push("/view_WorkOrder?workOrderID=114")
        this.props.history.push({
          pathname: "/view_WorkOrder",
          search: "?workOrderID=" + this.props.workOrderID,
          state: { workOrderMode: workOrderMode },
        });
      else if (workOrderMode === "edit")
        // this.props.history.push("/view_WorkOrder?workOrderID=this.props.workOrderID")
        this.props.history.push({
          pathname: "/edit_WorkOrder",
          search: "?workOrderID=" + this.props.workOrderID,
          state: { workOrderMode: workOrderMode },
        });
      else if (this.props.workOrderMode === "create") {
        this.props.history.push("/workOrders")
      }
    });
  };
  onDeleteJobGroupProcessOrderMappingClicked = (
    event,
    index,
    row,
    selected
  ) => {
    let selectedProcess = [...this.state.selectedProcessList];
    if (
      row.jobProcessOrderMappingID !== undefined &&
      row.jobProcessOrderMappingID !== null &&
      row.jobProcessOrderMappingID !== 0
    ) {
      DeleteJobGroupProcessOrderMapping(row.jobProcessOrderMappingID);
    }
    let newList = selected.ordersGroupList.filter((r) => {
      return r.orderID !== row.orderID;
    });
    let quantity = 0;
    newList.forEach((order, i) => {
      quantity = quantity + order.orderQty;
    });
    let newProcessList = selectedProcess.map((r) => {
      if (r.processID === selected.processID) {
        return { ...r, ordersGroupList: newList, quantity };
      }
      return r;
    });

    this.setState({ selectedProcessList: newProcessList });
  };

  handleDeleteClicked = (event, index, row) => {
    let { selectedProcessList, processList, filteredProcessList } = this.state;
    if (
      row.jobGroupProcessID !== undefined &&
      row.jobGroupProcessID !== null &&
      row.jobGroupProcessID !== 0
    ) {
      DeleteJobGroupProcess(row.jobGroupProcessID);
    }

    selectedProcessList = selectedProcessList
      .slice(0, index)
      .concat(selectedProcessList.slice(index + 1, selectedProcessList.length));

    const processIndex = filteredProcessList.findIndex(
      (x) => x.processID === row.processID
    );

    if (processIndex === -1) {
      let newProcess = processList.find((x) => x.processID === row.processID);
      if (newProcess && newProcess !== 0) {
        filteredProcessList.push(newProcess);
      }
    }

    if (selectedProcessList.length === 0) {
      this.setState({ changesUpdated: false, resetEnable: false });
    }
    this.setState({
      selectedProcessList,
      filteredProcessList: filteredProcessList.sort((a, b) =>
        a.processCategory.toLowerCase() > b.processCategory.toLowerCase()
          ? 1
          : -1
      ),
    });
  };

  render() {
    // console.log(this.props.history.location.state.comingFrom)


    let {
      ordersList,
      processList,
      selectedProcessList,
      selected,
      selectedProcess,
      selectedOrder,
      woDisplayDetails,
      selectedEnable,
      filteredOrdersList,
      filteredProcessList,
    } = this.state;
    let { classes, workOrderMode } = this.props;
    const numSelected = selected.length,
      rowCount = selectedProcessList.length;
    const numProcessSelected = selectedProcess.length,
      rowProcessCount = filteredProcessList.length;
    const numOrdersSelected = selectedOrder.length,
      rowOrderCount = filteredOrdersList.length;
    const isSelected = (id) => selected.indexOf(id) !== -1;
    const isProcessSelected = (id) => selectedProcess.indexOf(id) !== -1;
    const isOrdersSelected = (id) => selectedOrder.indexOf(id) !== -1;
    const isExpandable = (processID) =>
      selectedEnable.indexOf(processID) !== -1;

    return (
      <div className={classes.root} style={!this.props.clearOpenList
        ? { width: "calc(100vw - 202px)" }
        : { width: "calc(100vw - 440px)" }} >
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <div>
              <Typography
                component="div"
                className="blueBox"
                style={{ width: "auto" }}
              >
                <div className="blueBoxLabel">
                  <div>Customer: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.customerName}
                  </div>
                </div>
                <div className="blueBoxLabel">
                  <div>Work Order: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.workOrderNumber}
                  </div>
                </div>
                <div className="blueBoxLabel">
                  <div>Project Title: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.projectTitle}
                  </div>
                </div>
              </Typography>
              <Typography
                component="div"
                className="blueBox"
                style={{ marginTop: "2px", width: "auto" }}
              >
                <div className="blueBoxLabel">
                  <div>Job Group: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.jobGroupNumber}
                  </div>
                </div>
              </Typography>
            </div>

            <Paper>
              {(workOrderMode === "view" ||
                this.props.status == "Completed") && (
                  <div
                    style={{
                      marginTop: "12px",
                    }}
                  ></div>
                )}


              {workOrderMode !== "view" && this.props.status !== "Completed" && (
                <div
                  style={{
                    display: "flex",
                    margin: "12px .8em 0 1.6em",
                  }}
                >
                  <div>
                    {!this.state.addEnable ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{
                          boxShadow: "none",
                          padding: "2px",
                          margin: ".5em",
                          fontSize: "11px",
                        }}
                        onClick={this.handleProcessPopupOpen}
                      >
                        <AddIcon style={{ fontSize: "18px" }} /> Add
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            boxShadow: "none",
                            padding: "2px",
                            margin: ".5em",
                            fontSize: "11px",
                          }}
                          onClick={this.handleProcessPopupOpen}
                        >
                          <AddIcon style={{ fontSize: "18px" }} /> Add
                        </Button>
                      )}

                    {!this.state.resetEnable ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{
                          boxShadow: "none",
                          padding: "2px",
                          margin: ".5em",
                          fontSize: "11px",
                        }}
                        onClick={this.handleClickResetButton}
                      >
                        <SyncIcon style={{ fontSize: "18px" }} /> Reset
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            boxShadow: "none",
                            padding: "2px",
                            margin: ".5em",
                            fontSize: "11px",
                          }}
                          onClick={this.handleClickResetButton}
                        >
                          <SyncIcon style={{ fontSize: "18px" }} /> Reset
                        </Button>
                      )}

                    {this.state.selected.length == 0 ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{
                          boxShadow: "none",
                          padding: "2px",
                          margin: ".5em",
                          fontSize: "11px",
                        }}
                        onClick={this.handlePickOrderPopupOpen}
                      >
                        <AddIcon style={{ fontSize: "18px" }} /> Pick Order
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            boxShadow: "none",
                            padding: "2px",
                            margin: ".5em",
                            fontSize: "11px",
                          }}
                          onClick={this.handlePickOrderPopupOpen}
                        >
                          <AddIcon style={{ fontSize: "18px" }} /> Pick Order
                        </Button>
                      )}
                  </div>
                  <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.orderPopupOpen}
                    onClose={this.handleOrderPopupClose}
                    maxWidth="md"
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">
                      Add Order Group
                    </DialogTitle>
                    <DialogContent dividers>
                      <div className={classes.search}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          autoComplete="off"
                          onChange={this.handleChangeSearchOrder}
                          placeholder="Search Order Code, Group Code, File Name, Order Title,..."
                          //type="search"
                          fullWidth
                        />
                        <div className={classes.searchIcon}>
                          <IconButton
                            aria-label="view"
                            className={classes.iconHover}
                            onClick={this.handleSearchOrder}
                          >
                            <SearchIcon />
                          </IconButton>
                        </div>
                      </div>
                      <Table
                        className={classes.table}
                        // stickyHeader
                        aria-labelledby="tableTitle"
                        size={"small"}
                        aria-label="enhanced table"
                      // style={{ marginTop: "10px" }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell className="headcells" padding="checkbox">
                              <Checkbox
                                color="primary"
                                indeterminate={
                                  numOrdersSelected > 0 &&
                                  numOrdersSelected < rowOrderCount
                                }
                                checked={numOrdersSelected === rowOrderCount}
                                onChange={this.handleOrdersSelectAllClick}
                                inputProps={{
                                  "aria-label": "select all orders",
                                }}
                              />
                            </TableCell>
                            <TableCell padding="default" className="headcells">
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Order Group Code
                              </span>{" "}
                            </TableCell>
                            {/* <TableCell padding="default" className="headcells">
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Order Code
                              </span>{" "}
                            </TableCell> */}
                            <TableCell
                              padding="default"
                              className="headcells"
                              style={{ width: "200px" }}
                            >
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                File Name
                              </span>{" "}
                            </TableCell>
                            <TableCell
                              padding="default"
                              className="headcells"
                              style={{ width: "200px" }}
                            >
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Order Title
                              </span>{" "}
                            </TableCell>
                            <TableCell padding="default" className="headcells">
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Order Qty
                              </span>{" "}
                            </TableCell>
                            {/* <TableCell padding="default" className="headcells">
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Actions
                              </span>{" "}
                            </TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredOrdersList.map((row, index) => {
                            const isItemSelected = isOrdersSelected(
                              row.orderID
                            );
                            return (
                              <TableRow
                                role="checkbox"
                                tabIndex={-1}
                                key={row.orderID}
                                className="table-content-cell"
                              >
                                <TableCell padding="checkbox">
                                  <FormControlLabel
                                    onClick={(event) =>
                                      this.handleOrderClick(event, row.orderID)
                                    }
                                    control={
                                      <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                      />
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-cell"
                                  align="left"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.orderGroupCode}
                                  </Typography>
                                </TableCell>
                                {/* <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-cell"
                                  align="left"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.orderCode}
                                  </Typography>
                                </TableCell> */}
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-cell"
                                  align="left"
                                >
                                  {" "}
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.fileName &&
                                      row.fileName.length > 16 ? (
                                        <Tooltip
                                          title={row.fileName}
                                          aria-label={row.fileName}
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.fileName}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.fileName}
                                        </Typography>
                                      )}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-cell"
                                  align="left"
                                >
                                  {" "}
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.orderTitle &&
                                      row.orderTitle.length > 16 ? (
                                        <Tooltip
                                          title={row.orderTitle}
                                          aria-label={row.orderTitle}
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.orderTitle}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.orderTitle}
                                        </Typography>
                                      )}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-right-cell"
                                  align="right"
                                >
                                  {" "}
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.orderQty}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={this.handleOrderPopupClose}
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        CANCEL
                      </Button>
                      {Object.keys(selectedOrder).length === 0 ? (
                        <Button
                          onClick={this.handleAddOrderPopupSubmit}
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                        >
                          SUBMIT
                        </Button>
                      ) : (
                          <Button
                            onClick={this.handleAddOrderPopupSubmit}
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                          >
                            SUBMIT
                          </Button>
                        )}
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.processPopupOpen}
                    onClose={this.handleProcessPopupClose}
                    maxWidth="lg"
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">
                      Add Process
                    </DialogTitle>
                    <DialogContent dividers>
                      <div className={classes.search}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          autoComplete="off"
                          onChange={this.handleChangeSearchProcess}
                          placeholder="Search Process Category, Process Description"
                          fullWidth
                        />
                        <div className={classes.searchIcon}>
                          <IconButton
                            aria-label="view"
                            className={classes.iconHover}
                            onClick={this.handleSearchProcess}
                          >
                            <SearchIcon />
                          </IconButton>
                        </div>
                      </div>
                      <Table
                        aria-labelledby="tableTitle"
                        size={"small"}
                        aria-label="enhanced table"
                        style={{
                          marginTop: "10px",
                          border: "1px solid  rgba(224, 224, 224, 1)",
                          height: "400px",
                          display: "block",
                          overflowY: "auto",
                        }}
                        stickyHeader
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="checkbox"
                              className="headcells"
                            >
                              <Checkbox
                                indeterminate={
                                  numProcessSelected > 0 &&
                                  numProcessSelected < rowProcessCount
                                }
                                checked={numProcessSelected === rowProcessCount}
                                onChange={this.handleProcessSelectAllClick}
                                inputProps={{
                                  "aria-label": "select all orders",
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              className="headcells"
                              style={{ width: "150px" }}
                            >
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Process Category
                              </span>{" "}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              className="headcells"
                              style={{ width: "400px" }}
                            >
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Process Description
                              </span>{" "}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredProcessList.map((row, index) => {
                            const isProcessItemSelected = isProcessSelected(
                              row.processID
                            );
                            return (
                              <TableRow tabIndex={-1} key={row.processID}>
                                <TableCell padding="checkbox">
                                  <FormControlLabel
                                    onClick={(event) =>
                                      this.handleProcessClick(
                                        event,
                                        row.processID
                                      )
                                    }
                                    control={
                                      <Checkbox
                                        color="primary"
                                        checked={isProcessItemSelected}
                                      />
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  {" "}
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.processCategory}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  {row.processDescription}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          {/* {filteredProcessList.length === 0 && <TableCell colSpan="3"><span style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>No data found</span></TableCell>} */}
                        </TableBody>
                      </Table>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={this.handleProcessPopupClose}
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        CANCEL
                      </Button>
                      {Object.keys(selectedProcess).length === 0 ? (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={this.handleAddProcessPopupSubmit}
                        >
                          SUBMIT
                        </Button>
                      ) : (
                          <Button
                            onClick={this.handleAddProcessPopupSubmit}
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                          >
                            SUBMIT
                          </Button>
                        )}
                    </DialogActions>
                  </Dialog>
                </div>
              )}
              <div style={{ paddingBottom: "1em", paddingTop: "1em" }}>
                {selectedProcessList.map((row, index) => {
                  const isItemSelected = isSelected(row.processID);
                  const isExpand = isExpandable(row.processID);
                  return (
                    <ExpansionPanel
                      expanded={isExpand}
                      id="processExpansionStyle"
                      style={{ boxShadow: "none" }}
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      onChange={this.handlePanelChange(row.processID)}
                    >
                      <ExpansionPanelSummary
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        expandIcon={
                          <ExpandMoreIcon
                            style={{
                              position: "absolute",
                              left: "0",
                            }}
                          />
                        }
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                        style={
                          !this.props.clearOpenList
                            ? {
                              position: "relative",
                              flexDirection: "row-reverse",
                              padding: "0",
                              width: "calc(100vw - 13em)",
                              //maxHeight: "calc(100vh - 300px)"
                            }
                            : {
                              position: "relative",
                              flexDirection: "row-reverse",
                              padding: "0",
                              width: "calc(100vw - 441px)",
                              //maxHeight: "calc(100vh - 300px)"
                            }
                        }
                      >
                        <div

                          style={
                            !this.props.clearOpenList
                              ? {
                                display: "flex",
                                margin: "0",
                                position: "relative",
                                padding: "0",
                                width: "100%",
                                paddingLeft: "8px",
                                width: "calc(100vw - 15.5em)",
                                //maxHeight: "calc(100vh - 300px)"
                              }
                              : {
                                display: "flex",
                                margin: "0",
                                position: "relative",
                                padding: "0",
                                width: "100%",
                                paddingLeft: "8px",
                                width: "calc(100vw - 474px)",
                                //maxHeight: "calc(100vh - 300px)"
                              }
                          }
                          id="processId"
                          onClick={(event) => event.stopPropagation()}
                          onFocus={(event) => event.stopPropagation()}
                        >
                          <div
                            style={{ width: "4%", minWidth: "4%", maxWidth: "4%", border: "1px solid rgba(224, 224, 224, 1)", borderRight: "0" }}
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}

                          >
                            {index == 0 && <div style={{ height: "26px", border: "1px solid rgba(224, 224, 224, 1)", borderWidth: "0 0 1px 0", padding: "0px 3px 12px 3px" }}>
                              {workOrderMode !== "view" &&
                                this.props.status !== "Completed" && (
                                  <Checkbox
                                    color="primary"
                                    indeterminate={
                                      numSelected > 0 && numSelected < rowCount
                                    }
                                    checked={numSelected === rowCount}
                                    onChange={this.handleSelectAllClick}
                                    inputProps={{
                                      "aria-label": "select all orders",
                                    }}
                                  />
                                )}
                            </div>}
                            {workOrderMode !== "view" &&
                              this.props.status !== "Completed" && (
                                <FormControlLabel
                                  onClick={(event) =>
                                    this.handleClick(event, row.processID)
                                  }
                                  control={
                                    <Checkbox
                                      color="primary"
                                      checked={isItemSelected}
                                      style={{ left: "13px" }}
                                    />
                                  }
                                />
                              )}
                          </div>
                          <div
                            style={{ width: "16%", minWidth: "16%", maxWidth: "16%", border: "1px solid rgba(224, 224, 224, 1)", borderRight: "0" }}
                          >
                            {index == 0 && <div style={{ height: "26px", border: "1px solid rgba(224, 224, 224, 1)", borderWidth: "0 0 1px 0", padding: "12px 6px 0px 6px" }}>
                              <span className="processTableTitle">
                                Process Category
                            </span>
                            </div>}
                            <Typography
                              noWrap
                              style={{
                                fontSize: "14px",
                                // display: "flex",
                                height: "26px",
                                alignItems: "center",
                                padding: "12px 6px 0px 6px"
                              }}
                              onClick={(event) => event.stopPropagation()}
                              onFocus={(event) => event.stopPropagation()}
                            >
                              {row.processCategory}
                            </Typography>
                          </div>
                          <div
                            style={{ width: "22%", minWidth: "22%", maxWidth: "22%", border: "1px solid rgba(224, 224, 224, 1)", borderRight: "0" }}
                          >
                            {index == 0 && <div style={{ height: "26px", border: "1px solid rgba(224, 224, 224, 1)", borderWidth: "0 0 1px 0", padding: "12px 6px 0px 6px" }}>
                              <span className="processTableTitle">
                                Process Description
                            </span>
                            </div>}
                            <Typography
                              noWrap
                              style={{
                                fontSize: "14px",
                                // display: "flex",
                                height: "26px",
                                alignItems: "center",
                                padding: "12px 6px 0px 6px"
                              }}
                              onClick={(event) => event.stopPropagation()}
                              onFocus={(event) => event.stopPropagation()}
                            >
                              {row.processDescription &&
                                row.processDescription.length > 10 ? (
                                  <Tooltip
                                    title={row.processDescription}
                                    aria-label={row.processDescription}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.processDescription}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.processDescription}
                                  </Typography>
                                )}
                            </Typography>
                          </div>

                          <div
                            style={{ width: "18%", minWidth: "18%", maxWidth: "18%", border: "1px solid rgba(224, 224, 224, 1)", borderRight: "0" }}
                          >
                            {index == 0 && <div style={{ height: "26px", border: "1px solid rgba(224, 224, 224, 1)", borderWidth: "0 0 1px 0", padding: "12px 6px 0px 6px" }}>
                              <span className="processTableTitle">
                                Process Instruction
                            </span>
                            </div>}
                            <Typography
                              noWrap
                              style={{
                                fontSize: "14px",
                                padding: "6px 6px 0px 6px"
                              }}
                            >
                              {workOrderMode !== "view" &&
                                this.props.status !== "Completed" ? (
                                  <Input
                                    defaultValue=""
                                    inputProps={{
                                      "aria-label": "processInstruction",
                                      maxLength: 300,
                                    }}
                                    multiline="true"
                                    rows={1}
                                    rowsMax={7}
                                    style={{
                                      width: "100%",
                                      fontSize: "14px",
                                      // height: "26px",
                                      // padding: "12px 6px 0px 6px"

                                    }}
                                    name="processInstruction"
                                    value={row.processInstruction}
                                    // onKeyPress={this._onKeyPress}
                                    onChange={(e) =>
                                      this.handleChange(e, index)
                                    }
                                  />
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{
                                      fontSize: "14px",
                                      height: "26px",
                                      alignItems: "center",
                                      padding: "6px 6px 0px 1px"
                                    }}
                                  >
                                    {row.processInstruction &&
                                      row.processInstruction.length > 10 ? (
                                        <Tooltip
                                          title={row.processInstruction}
                                          aria-label={row.processInstruction}
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.processInstruction}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.processInstruction}
                                        </Typography>
                                      )}
                                  </Typography>
                                )}
                            </Typography>
                          </div>

                          <div
                            style={{ width: "10%", minWidth: "10%", maxWidth: "10%", border: "1px solid rgba(224, 224, 224, 1)", borderRight: "0" }}
                          >
                            {index == 0 && <div style={{ height: "26px", border: "1px solid rgba(224, 224, 224, 1)", borderWidth: "0 0 1px 0", padding: "12px 6px 0px 6px" }}>
                              <span className="processTableTitle">
                                No of Orders
                            </span>
                            </div>}
                            <Typography
                              noWrap
                              style={{
                                fontSize: "14px !important",
                                height: "26px",
                                padding: "7px 7px",
                                textAlign: "right"
                              }}
                              onClick={(event) => event.stopPropagation()}
                              onFocus={(event) => event.stopPropagation()}
                            >
                              <span style={{ fontSize: "16px" }}>
                                {row.noOfOrders}</span>
                            </Typography>
                          </div>

                          <div
                            style={{ width: "10%", maxWidth: "10%", border: "1px solid rgba(224, 224, 224, 1)", borderRight: "0" }}
                          >
                            {index == 0 && <div style={{ height: "26px", border: "1px solid rgba(224, 224, 224, 1)", borderWidth: "0 0 1px 0", padding: "12px 6px 0px 6px" }}>
                              <span className="processTableTitle">Quantity *</span>
                            </div>}
                            <Typography
                              noWrap
                              style={{
                                fontSize: "14px",
                                padding: "6px 6px 0px 6px"
                              }}
                            >
                              {workOrderMode !== "view" &&
                                this.props.status !== "Completed" ? (
                                  <Input
                                    defaultValue=""
                                    inputProps={{
                                      "aria-label": "quantity",
                                      maxLength: 10,
                                    }}
                                    style={{
                                      height: "26px",
                                    }}
                                    name="quantity"
                                    value={row.quantity}
                                    onKeyPress={this._onDecimalKeyPress}
                                    onChange={(e) =>
                                      this.handleChange(e, index)
                                    }
                                  />
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{
                                      fontSize: "14px",
                                      height: "26px",
                                      textAlign: "right",
                                      padding: "6px 6px 0px 6px"
                                    }}
                                  >
                                    {row.quantity}
                                  </Typography>
                                )}
                            </Typography>
                          </div>
                          <div
                            style={{ width: "7%", border: "1px solid rgba(224, 224, 224, 1)", borderRight: "0" }}
                          >
                            {index == 0 && <div style={{ height: "26px", border: "1px solid rgba(224, 224, 224, 1)", borderWidth: "0 0 1px 0", padding: "12px 6px 0px 6px" }}>
                              <span className="processTableTitle">Billable</span>
                            </div>}
                            <Typography
                              noWrap
                              style={{
                                fontSize: "14px",
                                padding: "4px 7px"
                              }}
                              onClick={(event) => event.stopPropagation()}
                              onFocus={(event) => event.stopPropagation()}
                            >
                              <Switch
                                checked={row.isBillable}
                                onChange={(e) =>
                                  this.handleChange(e, index)
                                }
                                disabled={
                                  workOrderMode === "view" ||
                                  this.props.status == "Completed"
                                }
                                name="isBillable"
                                //  onChange={handleChange('checkedB')}
                                value="isBillable"
                                color="primary"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            </Typography>
                          </div>
                          <div
                            style={workOrderMode !== "view" &&
                              this.props.status !== "Completed" ? { width: "7%", border: "1px solid rgba(224, 224, 224, 1)", borderRight: "0" } : { width: "13%", border: "1px solid rgba(224, 224, 224, 1)" }}
                          >
                            {index == 0 && <div style={{ height: "26px", border: "1px solid rgba(224, 224, 224, 1)", borderWidth: "0 0 1px 0", padding: "12px 6px 0px 6px" }}>
                              <span className="processTableTitle">OWC</span>
                            </div>}
                            <Typography
                              noWrap
                              style={{
                                fontSize: "14px",
                                padding: "4px 7px"
                              }}
                              onClick={(event) => event.stopPropagation()}
                              onFocus={(event) => event.stopPropagation()}
                            >
                              <Switch
                                checked={row.isOWC}
                                onChange={(e) =>
                                  this.handleChange(e, index)
                                }
                                disabled={
                                  workOrderMode === "view" ||
                                  this.props.status == "Completed"
                                }
                                name="isOWC"
                                color="primary"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            </Typography>
                          </div>

                          {workOrderMode !== "view" &&
                            this.props.status !== "Completed" && (
                              <div
                                style={{ width: "6%", border: "1px solid rgba(224, 224, 224, 1)" }}
                              >
                                {index == 0 && <div style={{ height: "26px", border: "1px solid rgba(224, 224, 224, 1)", borderWidth: "0 0 1px 0", padding: "12px 6px 0px 6px" }}>
                                  {workOrderMode !== "view" &&
                                    this.props.status !== "Completed" && (<span className="processTableTitle">Actions</span>
                                    )}
                                </div>}
                                {workOrderMode !== "view" &&
                                  this.props.status !== "Completed" && (
                                    <div
                                      style={{
                                        width: "5%",
                                        border: "none",
                                        padding: "8px 7px"
                                      }}
                                    >
                                      <Tooltip title="Delete">
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            color: "#6c6b6b",
                                          }}
                                          onClick={(e) =>
                                            this.handleDeleteClicked(
                                              e,
                                              index,
                                              row
                                            )
                                          }
                                        >
                                          <DeleteIcon
                                            style={{
                                              cursor: "pointer",
                                              color: "#6c6b6b",
                                            }}
                                          />
                                        </span>
                                      </Tooltip>
                                    </div>
                                  )}
                              </div>
                            )}
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails
                        style={{
                          background: "#eaeaea",
                          // width: "93%"
                          margin: "0 .95em .5em 2em",
                        }}
                      ><div style={{
                        marginLeft: "20%",
                        width: "100%",
                        // marginBottom: "1em"

                      }}>
                          <Table
                            size="small"
                            aria-label="a dense table"
                          // style={{
                          //   marginLeft: "18%",
                          // }}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  padding="default"
                                  align="left"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {" "}
                                  <span style={{ color: "#306dca" }}>
                                    Order Group Code
                              </span>{" "}
                                </TableCell>
                                <TableCell
                                  padding="default"
                                  align="left"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {" "}
                                  <span style={{ color: "#306dca" }}>
                                    Order Code
                              </span>{" "}
                                </TableCell>
                                <TableCell
                                  padding="default"
                                  align="left"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {" "}
                                  <span style={{ color: "#306dca" }}>
                                    File Name
                              </span>{" "}
                                </TableCell>
                                <TableCell
                                  padding="default"
                                  align="left"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {" "}
                                  <span style={{ color: "#306dca" }}>
                                    Order Title
                              </span>{" "}
                                </TableCell>
                                <TableCell
                                  padding="default"
                                  align="right"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {" "}
                                  <span style={{ color: "#306dca" }}>
                                    Order Qty
                              </span>{" "}
                                </TableCell>
                                {workOrderMode !== "view" &&
                                  this.props.status !== "Completed" && (
                                    <TableCell
                                      padding="default"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      <span style={{ color: "#306dca" }}>
                                        Actions
                                  </span>{" "}
                                    </TableCell>
                                  )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {row &&
                                row.ordersGroupList &&
                                row.ordersGroupList.map((order, index) => (
                                  <TableRow key={row.orderID}>
                                    <TableCell component="th" scope="row">
                                      {order.orderGroupCode}
                                    </TableCell>
                                    <TableCell align="left">
                                      {order.orderCode}
                                    </TableCell>
                                    <TableCell align="left">
                                      {order.fileName}
                                    </TableCell>
                                    <TableCell align="left">
                                      {order.orderTitle}
                                    </TableCell>
                                    <TableCell align="right">
                                      {order.orderQty}
                                    </TableCell>
                                    {workOrderMode !== "view" &&
                                      this.props.status !== "Completed" && (
                                        <TableCell>
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              color: "#6c6b6b",
                                            }}
                                            onClick={(e) =>
                                              this.onDeleteJobGroupProcessOrderMappingClicked(
                                                e,
                                                index,
                                                order,
                                                row
                                              )
                                            }
                                          >
                                            <DeleteIcon
                                              style={{
                                                cursor: "pointer",
                                                color: "#6c6b6b",
                                              }}
                                            />
                                          </span>
                                        </TableCell>
                                      )}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                  );
                })}
              </div>
            </Paper>
          </div>
          <Divider
            style={{
              marginTop: "25px",
            }}
          />
          <div className="button-wrapper">
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.handlCancelClicked(e, workOrderMode)}
              >
                CANCEL
              </Button>
            </div>
            <div style={{ marginLeft: "1em" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) =>
                  this.props.history.push({
                    pathname: "/jobGroup",
                    search:
                      "?workOrderID=" +
                      this.props.workOrderID +
                      "&jobGroupID=" +
                      this.props.jobGroupID,
                    state: { workOrderMode: workOrderMode },
                    status: this.props.status,
                  })
                }
              >
                BACK
              </Button>
            </div>
            <div style={{ marginLeft: "1em" }}>
              <Button
                // disabled={this.props.status == "Completed"}
                // autoFocus
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={this.handleProcessSubmit}
              //className={!changesUpdated ? "disabled-button" : "enabled-button "}
              >
                NEXT
              </Button>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default withRouter(withStyles(styles)(Process));
