import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import { getProcessType } from "../../masters/masterActions";
import { StateContext } from "../../../shared/globalState";
import { printDetails } from "../../../shared/tableCommonFunctions";

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { selectedProcessTypeId } = props;

  let acnArr = props.roles.filter(u => u.menuName === "Process Type");
  let acnObj = acnArr[0];

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%"
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_processType/${selectedProcessTypeId}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {/* <Tooltip title="Download">
            <IconButton
              aria-label="download"
              className={classes.iconHover}
              onClick={e => pdfDownloadDetails("ProcessType_details.pdf")}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip> */}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={e => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewProcessType extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      processType: {
        processTypeName: "",
        processTypeCode: ""
      }
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let processTypeID = this.props.match.params.processTypeId;
    getProcessType(processTypeID).then(r => {
      dispatch({
        type: "getProcessType",
        newStore: r
      });
      this.setState({ processType: r });
    });
  }

  render() {
    let { processType } = this.state;
    let processTypeID = this.props.match.params.processTypeId;
    let { status } = "";
    if (processType.isActive === true) {
      status = "Yes";
    } else {
      status = "No";
    }
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selectedProcessTypeId={processTypeID}
            roles={this.props.roles}
          />
          {/* <div className="view-page-header">Cost Per Title Information</div> */}
          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">Process Type Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Process Type Code</div>
                <div className="view-page-value">
                  {processType.processTypeCode}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Process Type Name</div>
                <div className="view-page-value">
                  {processType.processTypeName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Active</div>
                <div className="view-page-value">{status}</div>
              </div>
            </div>{" "}
            <Divider style={{ marginTop: "25px" }} />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                  onClick={e => this.props.history.push("/processType")}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewProcessType);
