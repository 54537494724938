import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export function ViewIcon() {
  return (
    <SvgIcon>
      <path d="M12.073 10.194c0 1.288-1.008 2.333-2.25 2.333 0 1.288 1.008 2.334 2.25 2.334s2.25-1.046 2.25-2.334-1.008-2.333-2.25-2.333zm0-4.667c-4.615 0-9 5.423-9 7 0 1.578 4.385 7 9 7 4.614 0 9-5.422 9-7 0-1.577-4.386-7-9-7zm0 11.667c-2.486 0-4.5-2.09-4.5-4.667s2.014-4.666 4.5-4.666c2.485 0 4.5 2.09 4.5 4.666 0 2.577-2.015 4.667-4.5 4.667z" />
    </SvgIcon>
  );
}
export function EditIcon() {
  return (
    <SvgIcon>
      <path d="M19.428 8.43a8.444 8.444 0 0 0-1.272-1.621 8.45 8.45 0 0 0-1.65-1.276l1.005-1.006s1.455 0 2.182.728c.727.727.727 2.181.727 2.181l-.992.994zm-8.462 8.46h-2.91v-2.908l.35-.35a6.099 6.099 0 0 1 2.91 2.91l-.35.349zm7.622-7.62l-6.434 6.432a8.401 8.401 0 0 0-2.921-2.897l6.446-6.445a6.075 6.075 0 0 1 2.909 2.91zM6.602 6.71v11.635H18.24v-5.09l2.181-2.21v8.028c0 .803-.651 1.454-1.454 1.454H5.875c-.803 0-1.455-.65-1.455-1.454V5.982c0-.803.652-1.455 1.455-1.455h8.007L11.7 6.71H6.602z" />
    </SvgIcon>
  );
}
export function NewEditIcon() {
  return (
    <SvgIcon>
      <path d="M19.007 7.903a8.444 8.444 0 0 0-1.271-1.622 8.45 8.45 0 0 0-1.65-1.275L17.09 4s1.454 0 2.182.727C20 5.455 20 6.91 20 6.91l-.993.994zm-8.462 8.46H7.636v-2.908l.35-.35a6.099 6.099 0 0 1 2.91 2.91l-.35.349zm7.622-7.621l-6.433 6.433a8.401 8.401 0 0 0-2.921-2.897l6.445-6.445a6.075 6.075 0 0 1 2.91 2.909zM6.182 6.182v11.636h11.636v-5.09L20 10.517v8.027C20 19.35 19.348 20 18.545 20H5.455C4.652 20 4 19.35 4 18.545V5.455C4 4.652 4.652 4 5.455 4h8.007l-2.183 2.182H6.182z" />
    </SvgIcon>
  );
}
export function SearchIcon() {
  return (
    <SvgIcon>
      <path d="M19.73 5.167H5.5a1.5 1.5 0 0 0-1.5 1.5v.205a1.5 1.5 0 0 0 1.5 1.5h14.23a1.5 1.5 0 0 0 1.5-1.5v-.205a1.5 1.5 0 0 0-1.5-1.5zM7.423 17.987H5.5a1.5 1.5 0 0 0-1.5 1.5v.205a1.5 1.5 0 0 0 1.5 1.5h1.923a1.5 1.5 0 0 0 1.5-1.5v-.205a1.5 1.5 0 0 0-1.5-1.5zM13.577 11.577H5.5a1.5 1.5 0 0 0-1.5 1.5v.205a1.5 1.5 0 0 0 1.5 1.5h8.077a1.5 1.5 0 0 0 1.5-1.5v-.205a1.5 1.5 0 0 0-1.5-1.5z" />
    </SvgIcon>
  );
}
export function AddIcon() {
  return (
    <SvgIcon>
      <g>
        <rect width="3" height="16.667" x="11.5" y="5.167" rx="1.5" />
        <path d="M21 13.438v.124a1.5 1.5 0 0 1-1.5 1.5h-13a1.5 1.5 0 0 1-1.5-1.5v-.125a1.5 1.5 0 0 1 1.5-1.5h13a1.5 1.5 0 0 1 1.5 1.5z" />
      </g>
    </SvgIcon>
  );
}
export function CustomAddIcon() {
  return (
    <SvgIcon fontSize="small" fill="red">
      <g>
        <rect width="3" height="16.667" x="11.5" y="5.167" rx="1.5" />
        <path d="M21 13.438v.124a1.5 1.5 0 0 1-1.5 1.5h-13a1.5 1.5 0 0 1-1.5-1.5v-.125a1.5 1.5 0 0 1 1.5-1.5h13a1.5 1.5 0 0 1 1.5 1.5z" />
      </g>
    </SvgIcon>
  );
}
export function CustomSmallAddIcon() {
  return (
    <SvgIcon fontSize="small" className="smallAddIcon">
      <g>
        <rect width="3" height="16.667" x="11.5" y="5.167" rx="1.5" />
        <path d="M21 13.438v.124a1.5 1.5 0 0 1-1.5 1.5h-13a1.5 1.5 0 0 1-1.5-1.5v-.125a1.5 1.5 0 0 1 1.5-1.5h13a1.5 1.5 0 0 1 1.5 1.5z" />
      </g>
    </SvgIcon>
  );
}
export function PrintIcon() {
  return (
    <SvgIcon>
      <path d="M15 0v3.056c1.637.309 2.906 1.855 2.995 3.734L18 7v6h-3v5H4v-5H0V7c0-2.131 1.52-3.887 3.415-3.995L3.6 3H4V0h11zm-1.833 11.778H5.833v4.444h7.334v-4.444zM15.3 5c-.54 0-.9.4-.9 1s.36 1 .9 1 .9-.4.9-1-.36-1-.9-1z" />
    </SvgIcon>
  );
}
export function CustomPrintIcon() {
  return (
    <SvgIcon fontSize="small">
      <path d="M15 0v3.056c1.637.309 2.906 1.855 2.995 3.734L18 7v6h-3v5H4v-5H0V7c0-2.131 1.52-3.887 3.415-3.995L3.6 3H4V0h11zm-1.833 11.778H5.833v4.444h7.334v-4.444zM15.3 5c-.54 0-.9.4-.9 1s.36 1 .9 1 .9-.4.9-1-.36-1-.9-1z" />
    </SvgIcon>
  );
}
export function DownloadIcon() {
  return (
    <SvgIcon>
      <path d="M16.586 10H15V5a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v5H7.414a1 1 0 0 0-.707 1.707l4.586 4.586a1 1 0 0 0 1.414 0l4.586-4.586A1 1 0 0 0 16.586 10zM17.5 18h-11a1.5 1.5 0 0 0 0 3h11a1.5 1.5 0 0 0 0-3z" />
    </SvgIcon>
  );
}
export function ViewColumnIcon() {
  return (
    <SvgIcon>
      <g transform="translate(7 5.167)">
        <rect width="2.5" height="16.667" rx="1.25" />
        <rect width="2.5" height="16.667" x="5" rx="1.25" />
        <rect width="2.5" height="16.667" x="10" rx="1.25" />
      </g>
    </SvgIcon>
  );
}
export function HomeIcon() {
  return (
    <SvgIcon>
      <g fill="none">
        <path
          fill="#B6B8BF"
          d="M17 13v2h-4v3H9v5H5a1 1 0 0 1-1-1v-9H0L12 2l12 11h-7z"
        />
        <path fill="#6D7381" d="M9 23v-5h4v-3h4v-2h4v10H9z" />
      </g>
    </SvgIcon>
  );
}
export function AuthoriationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="a"
          d="M.352 7.52a6.569 6.569 0 0 0 6.571 6.571 6.57 6.57 0 0 0 6.571-6.571A6.57 6.57 0 0 0 6.923.948 6.57 6.57 0 0 0 .352 7.52z"
        />
        <path
          id="b"
          d="M.352 3.912v1.643h13.142V3.912c0-2.184-4.378-3.285-6.571-3.285C4.73.627.352 1.728.352 3.912z"
        />
      </defs>
      <g fill="none">
        <path
          fill="#6D7381"
          d="M4.469 14.29a2.468 2.468 0 1 0 .001-4.938 2.468 2.468 0 0 0-.001 4.937M4.469 7.938a2.468 2.468 0 1 0 .001-4.937 2.468 2.468 0 0 0-.001 4.937M4.469 20.755a2.468 2.468 0 1 0 .001-4.937 2.468 2.468 0 0 0-.001 4.937"
        />
        <path fill="#6D7381" d="M5.767 12.451h3.861v-1.264H5.767z" />
        <path
          fill="#B5B8BF"
          d="M15.905 18.286a6.57 6.57 0 0 0 6.571-6.57 6.57 6.57 0 0 0-6.57-6.572 6.57 6.57 0 0 0-6.572 6.571 6.569 6.569 0 0 0 6.571 6.571"
        />
        <path
          fill="#6D7381"
          d="M15.905 15.822c-2.193 0-6.57 1.101-6.57 3.285v1.643h13.141v-1.643c0-2.184-4.378-3.285-6.57-3.285"
        />
        <g transform="translate(8.982 15.195)">
          <mask id="c" fill="#fff">
            <use href="#b" />
          </mask>
          <path
            fill="#6D7381"
            d="M-30.038 586.26h1577.021v-841.078H-30.038z"
            mask="url(#c)"
          />
        </g>
        <path
          fill="#6D7381"
          d="M15.92 15.035a3 3 0 1 0 .001-5.999 3 3 0 0 0-.001 6"
        />
      </g>
    </svg>
  );
}
export function AdminIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="a"
          d="M.252 8.66a8.552 8.552 0 0 0 8.554 8.554 8.55 8.55 0 0 0 8.554-8.553A8.551 8.551 0 0 0 8.806.105 8.553 8.553 0 0 0 .252 8.661z"
        />
        <path
          id="c"
          d="M.252 3.923v1.943H17.36V3.923c0-2.58-5.699-3.88-8.554-3.88-2.854 0-8.554 1.3-8.554 3.88z"
        />
      </defs>
      <g fill="none">
        <path d="M11.806 19.214a8.551 8.551 0 0 0 8.554-8.553 8.552 8.552 0 0 0-8.554-8.556 8.552 8.552 0 0 0-8.554 8.556 8.551 8.551 0 0 0 8.554 8.553" />
        <g transform="translate(3 2)">
          <mask id="b" fill="#fff">
            <use href="#a" />
          </mask>
          <path
            d="M-87.429 1544.143h4106.025v-2189.88H-87.429z"
            mask="url(#b)"
          />
        </g>
        <path
          fill="#6D7381"
          d="M11.5 17C8.664 17 3 18.34 3 21v2h17v-2c0-2.66-5.662-4-8.5-4"
        />
        <g transform="translate(3 17)">
          <mask id="d" fill="#fff">
            <use href="#c" />
          </mask>
          <path
            fill="#6D7381"
            d="M-39.312 691.846h2053.013v-993.557H-39.312z"
            mask="url(#d)"
          />
        </g>
        <path
          fill="#6D7381"
          d="M13.758 10.507a2.259 2.259 0 0 0-2.25-2.26 2.253 2.253 0 0 0 0 4.506 2.258 2.258 0 0 0 2.25-2.246M11.143 5h.742c.008.005.014.015.022.017.588.158.835.48.836 1.086 0 .114-.034.257.02.335.053.078.2.09.306.135.141.06.296.187.42.164.129-.026.226-.199.339-.307a.983.983 0 0 1 1.366-.01c.127.12.249.245.37.37.392.403.396.989.01 1.396-.089.093-.184.179-.271.274-.023.025-.048.074-.037.1.095.235.2.466.303.704h.464c.45.004.856.319.935.762.042.24.03.492.03.738 0 .458-.32.86-.768.943-.218.04-.446.03-.672.042l-.312.753c.107.103.225.206.33.319.381.4.378.986-.007 1.384-.114.12-.232.237-.35.352a.983.983 0 0 1-1.406-.001c-.108-.106-.207-.22-.299-.316-.25.099-.474.185-.697.281-.034.014-.067.07-.07.109-.009.147-.001.294-.008.44-.02.447-.357.84-.801.898a3.349 3.349 0 0 1-.78.016c-.489-.05-.818-.389-.882-.874-.02-.152-.012-.31-.008-.467.002-.075-.023-.108-.096-.136a6.404 6.404 0 0 1-.556-.234c-.072-.033-.113-.024-.164.032-.09.097-.185.191-.283.281a.983.983 0 0 1-1.346-.004 7.064 7.064 0 0 1-.398-.398.981.981 0 0 1 .014-1.366c.086-.089.172-.18.263-.262.057-.052.064-.094.03-.165a5.996 5.996 0 0 1-.23-.556c-.031-.084-.072-.103-.15-.099-.12.005-.238.001-.357.001a.976.976 0 0 1-.942-.724c-.023-.078-.036-.158-.053-.237v-.55c.008-.03.02-.061.024-.093.06-.452.383-.795.833-.858.167-.024.339-.01.508-.01.066 0 .103-.011.13-.086.071-.197.162-.387.236-.583a.16.16 0 0 0-.022-.136c-.078-.095-.173-.177-.26-.266-.392-.409-.392-.995 0-1.406.107-.108.214-.214.322-.32.434-.427 1.019-.428 1.454 0 .1.1.19.208.279.306.13-.06.227-.109.328-.152.141-.06.341-.081.411-.188.073-.107.019-.3.024-.455A.996.996 0 0 1 11 5.04c.048-.013.095-.026.143-.041"
        />
      </g>
    </svg>
  );
}
export function MDMIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      {/* <g fill="none" transform="translate(0 4)">
        <path fill="#3982EA" d="M0 0h5v5H0zM0 12h5v5H0z" />
        <circle cx="16.5" cy="8.5" r="7.5" fill="#9CC0F4" opacity=".5" />
        <circle cx="16.5" cy="8.5" r="3.5" fill="#3982EA" />
        <path
          stroke="#3982EA"
          d="M3 15c.667-4.519 5.667-6.527 15-6.025M3 .9c.667 6 5.667 8.667 15 8"
        />
      </g> */}
      <g fill="none" fillRule="nonzero" transform="translate(0 5)">
        <path fill="#6D7381" d="M0 0h6.25v6.25H0zM0 15h6.25v6.25H0z" />
        <circle cx="19.375" cy="10.625" r="10.625" fill="#DADBDF" />
        <circle cx="19.375" cy="10.625" r="4.375" fill="#6D7381" />
        <path
          stroke="#6D7381"
          d="M3.75 18.75c.833-5.648 7.083-8.158 18.75-7.53M3.75 1.125c.833 7.5 7.083 10.833 18.75 10"
        />
      </g>
    </svg>
  );
}
export function ReportIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
    >
      <g fill="none">
        <path
          fill="#B5B8BF"
          d="M13.75 3h-7A1.75 1.75 0 0 0 5 4.75v14c0 .966.784 1.75 1.75 1.75h10.5A1.75 1.75 0 0 0 19 18.75V8.25L13.75 3z"
        />
        <path
          fill="#6D7381"
          stroke="#6D7381"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M18.25 8h-5C12.56 8 12 8.582 12 9.3v10.4c0 .718.56 1.3 1.25 1.3h7.5c.69 0 1.25-.582 1.25-1.3v-7.8L18.25 8z"
        />
      </g>
    </svg>
  );
}
export function GenericCustomerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="24"
      viewBox="0 0 23 30"
    >
      <g fill="none">
        <path
          fill="#DADBDF"
          d="M15.061 0H4.491C3.032 0 1.85 1.197 1.85 2.674v21.389c0 1.476 1.183 2.673 2.642 2.673h15.855c1.46 0 2.642-1.197 2.642-2.674V8.021L15.061 0z"
        />
        <g fill="#6D7381">
          <path d="M11.762 22.083c-3.723 0-11.157 1.77-11.157 5.279V30H22.92v-2.638c0-3.51-7.432-5.279-11.157-5.279M14.726 13.517c.002-1.643-1.325-2.981-2.953-2.983-1.622 0-2.943 1.325-2.95 2.963-.01 1.637 1.321 2.983 2.95 2.983 1.62 0 2.951-1.335 2.953-2.963M11.293 6.25h.974c.01.007.02.02.03.023.77.207 1.095.633 1.097 1.432 0 .15-.045.339.026.442.07.103.262.12.401.178.186.078.39.248.552.216.169-.033.297-.261.445-.405a1.285 1.285 0 0 1 1.793-.012c.167.157.327.323.486.487.514.532.52 1.305.013 1.843-.117.122-.242.236-.356.361-.03.033-.063.098-.049.131.125.31.262.616.398.93.201 0 .406-.002.61 0 .59.005 1.122.42 1.227 1.005.055.318.04.65.04.974 0 .604-.42 1.134-1.01 1.244-.286.055-.584.04-.881.056l-.41.993c.14.136.295.272.434.42.5.53.495 1.303-.01 1.828-.15.157-.304.312-.459.464-.534.52-1.317.52-1.845-.002-.143-.14-.273-.29-.393-.417-.328.131-.623.245-.915.372-.045.017-.088.093-.092.143-.012.194-.001.388-.01.581-.028.59-.469 1.108-1.052 1.185a4.373 4.373 0 0 1-1.024.021c-.641-.066-1.073-.513-1.157-1.153-.026-.201-.015-.41-.01-.616.002-.1-.031-.144-.127-.18a8.382 8.382 0 0 1-.73-.31c-.095-.043-.148-.03-.214.043-.118.129-.243.253-.372.372a1.285 1.285 0 0 1-1.767-.006 9.297 9.297 0 0 1-.523-.525 1.3 1.3 0 0 1 .02-1.803c.112-.117.225-.237.345-.345.074-.068.083-.124.04-.218a7.934 7.934 0 0 1-.304-.733c-.04-.112-.094-.136-.196-.131-.157.007-.313.002-.47.002a1.282 1.282 0 0 1-1.235-.957c-.03-.103-.047-.207-.07-.312v-.726c.01-.04.026-.08.031-.122.08-.597.504-1.049 1.094-1.133.219-.031.445-.014.667-.012.087 0 .135-.016.17-.113.094-.26.214-.512.31-.77a.212.212 0 0 0-.029-.18c-.102-.125-.227-.234-.34-.35-.516-.54-.516-1.313 0-1.856.139-.143.28-.282.422-.422.57-.564 1.337-.565 1.908 0 .132.131.25.274.366.403.17-.078.299-.143.43-.2.187-.079.449-.107.54-.248.096-.142.025-.396.032-.6.02-.589.405-1.084.962-1.238.062-.017.125-.035.187-.054" />
        </g>
      </g>
    </svg>
  );
}
