import clone from "clone";
import types from "./masterTypes";
//import { types } from "@babel/core";

export const masterReducers = (state, action) => {
  switch (action.type) {
    case "getDeptList":
      let newDeptState = clone(state);
      newDeptState.store.deptList = action.newStore;
      return newDeptState;
    case types.GET_DEPARTMENT_DATA:
      let newDeptData = clone(state);
      newDeptData.store.deptList = action.newStore;
      return newDeptData;
    case "getZoneList":
      let newZoneState = clone(state);
      newZoneState.store.zoneList = action.newStore;
      return newZoneState;
    case "getZone":
      let newZone = clone(state);
      newZone.store.zone = action.newStore;
      return newZone;
    case "getMasterRoleActionList":
      let newMasterRoleActionState = clone(state);
      newMasterRoleActionState.store.masterRoleActionList = action.newStore;
      return newMasterRoleActionState;
    case "getCountryList":
      let newCountryState = clone(state);
      newCountryState.store.countryList = action.newStore;
      return newCountryState;
    case "getCountryDDList":
      let newCountryDDLState = clone(state);
      newCountryDDLState.store.countryDDL = action.newStore;
      return newCountryDDLState;
    case "getCustomerList":
      let newCustomerState = clone(state);
      newCustomerState.store.customerList = action.newStore;
      return newCustomerState;
    // case "getFilteredCustomerList":
    //   let newFCustomerState = clone(state);
    //   newFCustomerState.store.filteredCustomerList = action.newStore;
    //   return newFCustomerState;
    case "getCustomerDropdownList":
      let newCustomerDDLState = clone(state);
      newCustomerDDLState.store.customerDDList = action.newStore;
      return newCustomerDDLState;
    case "getPPIList":
      let newPPIState = clone(state);
      newPPIState.store.ppiList = action.newStore;
      return newPPIState;
    case "getServiceLists":
      let newServiceState = clone(state);
      newServiceState.store.serviceList = action.newStore;
      return newServiceState;
    case "getCustomerPPIList":
      let newCPPIState = clone(state);
      newCPPIState.store.customerPPIList = action.newStore;
      return newCPPIState;

    case "getCustomer":
      let customerdata = clone(state);
      customerdata.store.customerdata = action.newStore;
      return customerdata;
    case "getCCIList":
      let newCCIState = clone(state);
      newCCIState.store.cciList = action.newStore;
      return newCCIState;
    //-------lettershopcode starts---------//
    case "getLetterShopList":
      let newLetterShopState = clone(state);
      newLetterShopState.store.letterShopList = action.newStore;
      return newLetterShopState;
    case "getLetterShopCode":
      let newLetterShopCode = clone(state);
      newLetterShopCode.store.letter = action.newStore;
      return newLetterShopCode;
    // ------------lettershopcode ends------------------//
    //-------acronym starts---------//
    case "getAcronymList":
      let newAcronymList = clone(state);
      newAcronymList.store.acronymList = action.newStore;
      return newAcronymList;
    case "getAcronym":
      let newAcronym = clone(state);
      newAcronym.store.acronym = action.newStore;
      return newAcronym;
    // ------------acronym ends------------------//
    //-------DHLRate starts---------//
    case "getDHLRateList":
      let newDHLRateState = clone(state);
      newDHLRateState.store.dhlRateList = action.newStore;
      return newDHLRateState;
    case "getDHLRate":
      let newDHLRate = clone(state);
      newDHLRate.store.dhlRate = action.newStore;
      return newDHLRate;
    // ------------DHLRate ends------------------//
    //-------Journal Version Matrixs starts---------//
    case "getJournalVersionMatrixsList":
      let newJournalStateList = clone(state);
      newJournalStateList.store.journalVersionList = action.newStore;
      return newJournalStateList;
    case "getJournalVersionMatrixs":
      let newJournal = clone(state);
      newJournal.store.journalVersion = action.newStore;
      return newJournal;
    // ------------Journal Version Matrixs ends------------------//
    case "getRoleList":
      let newRoleState = clone(state);
      newRoleState.store.roleList = action.newStore;
      return newRoleState;
    case "getSuperMenuList":
      let newSuperMenuState = clone(state);
      newSuperMenuState.store.superMenuList = action.newStore;
      return newSuperMenuState;
    case "getMenuList":
      let newMenuState = clone(state);
      newMenuState.store.menuList = action.newStore;
      return newMenuState;
    case "getSubMenuList":
      let newSubMenuState = clone(state);
      newSubMenuState.store.subMenuList = action.newStore;
      return newSubMenuState;
    case "getCptCode":
      let newCptCode = clone(state);
      newCptCode.store.lettershopdata = action.newStore;
      return newCptCode;
    case "getProcessType":
      let newProcessType = clone(state);
      newProcessType.store.processType = action.newStore;
      return newProcessType;
    case "getProcessTypeList":
      let newProcessTypeState = clone(state);
      newProcessTypeState.store.processTypeList = action.newStore;
      return newProcessTypeState;
    case "getProcessRateList":
      let newProcessRateState = clone(state);
      newProcessRateState.store.processRateList = action.newStore;
      return newProcessRateState;
    case "getProvince":
      let newProvince = clone(state);
      newProvince.store.province = action.newStore;
      return newProvince;
    case "getProvinceList":
      let newProvinceState = clone(state);
      newProvinceState.store.provinceList = action.newStore;
      return newProvinceState;
    case "getCodeMaster":
      let newCodeMaster = clone(state);
      newCodeMaster.store.codeMaster = action.newStore;
      return newCodeMaster;
    case "getCodeMasterList":
      let newCodeMasterState = clone(state);
      newCodeMasterState.store.codeMaster = action.newStore;
      return newCodeMasterState;
    case "getOutworkerClaimRate":
      let newOutworkerClaimRate = clone(state);
      newOutworkerClaimRate.store.outworkerClaimRate = action.newStore;
      return newOutworkerClaimRate;
    case "getOutworkerClaimRateList":
      let newOutworkerClaimRateState = clone(state);
      newOutworkerClaimRateState.store.outworkerClaimRateList = action.newStore;
      return newOutworkerClaimRateState;
    case "getProcess":
      let newProcess = clone(state);
      newProcess.store.process = action.newStore;
      return newProcess;
    case "getProcessList":
      let newProcessState = clone(state);
      newProcessState.store.process = action.newStore;
      return newProcessState;
    case "getRole":
      let newRole = clone(state);
      newRole.store.role = action.newStore;
      return newRole;
    case "getRoleAction":
      let newRoleAction = clone(state);
      newRoleAction.store.roleAction = action.newStore;
      return newRoleAction;
    case "getRoleActionByRoleId":
      let newRoleActionByRoleId = clone(state);
      newRoleActionByRoleId.store.roleActionByRoleId = action.newStore;
      return newRoleActionByRoleId;
    case "getFilterdDeptList":
      newDeptState = clone(state);
      newDeptState.store.deptList = action.newStore;
      return newDeptState;

    case "getMailingSchemeList":
      let newMalinigScList = clone(state);
      newMalinigScList.store.mailingSchemeList = action.newStore;
      return newMalinigScList;
    case "getUomDropDownList":
      let newUomDropDownList = clone(state);
      newUomDropDownList.store.uomDropDownList = action.newStore;
      return newUomDropDownList;
    // case "getRoutingZoneList":
    //   let newRoutingZone = clone(state);
    //   newRoutingZone.store.RoutingZoneList = action.newStore;
    //   return newRoutingZone;

    case "getCountry":
      let newCountry = clone(state);
      newCountry.store.country = action.newStore;
      return newCountry;
    case "getDispatchModeList":
      let newiDspatchModeList = clone(state);
      newiDspatchModeList.store.dispatchModeList = action.newStore;
      return newiDspatchModeList;

    case "getLocalPostage":
      let newLP = clone(state);
      newLP.store.localPostage = action.newStore;
      return newLP;
    case types.CPTCODE_LIST:
      let newCptCodeList = clone(state);
      newCptCodeList.store.cptCodeList = action.newStore;
      return newCptCodeList;
    case types.ROLE_LIST:
      let newRoleList = clone(state);
      newRoleList.store.roleList = action.newStore;
      return newRoleList;
    case types.ROLEACTION_LIST:
      let newRoleActionList = clone(state);
      newRoleActionList.store.roleActionList = action.newStore;
      return newRoleActionList;
    case types.SUPERMENU_LIST:
      let newSuperMenuList = clone(state);
      newSuperMenuList.store.superMenuList = action.newStore;
      return newSuperMenuList;
    case types.MENU_LIST:
      let newMenuList = clone(state);
      newMenuList.store.menuList = action.newStore;
      return newMenuList;
    case types.SUBMENU_LIST:
      let newSubMenuList = clone(state);
      newSubMenuList.store.subMenuList = action.newStore;
      return newSubMenuList;
    //-------warehouselocation starts---------//
    case "getWarehouseLocationList":
      let newWarehouseState = clone(state);
      newWarehouseState.store.warehouseLocationList = action.newStore;
      return newWarehouseState;
    case "getWarehouseLocationCode":
      let newWarehouseCode = clone(state);
      newWarehouseCode.store.warehouselocation = action.newStore;
      return newWarehouseCode;
    // ------------warehouselocation ends------------------//
    //-------distributor starts---------//
    case "getDistributorList":
      let newdistributorState = clone(state);
      newdistributorState.store.DistributorList = action.newStore;
      return newdistributorState;
    case "getDistributorCode":
      let newdistributorCode = clone(state);
      newdistributorCode.store.DistributorData = action.newStore;
      return newdistributorCode;
    // ------------distributor ends------------------//
    //-------production line starts---------//
    case "getProductionLineList":
      let newProdLineState = clone(state);

      newProdLineState.store.productionLineCode = action.newStore;
      return newProdLineState;
    case "getProductionLineCode":
      let newProdLineCode = clone(state);
      newProdLineCode.store.productionLineCodeList = action.newStore;
      return newProdLineCode;
    // ------------production ends------------------//
    //-------code type line starts---------//
    case "getCodeTypeList":
      let newCodeTypeState = clone(state);
      newCodeTypeState.store.codeTypeCode = action.newStore;
      return newCodeTypeState;
    case "getCodeType":
      let newCodeTypeCode = clone(state);
      newCodeTypeCode.store.codeTypeList = action.newStore;
      return newCodeTypeCode;
    // ------------code type ends------------------//
    //-------login starts---------//
    case "getLogin":
      let newLoginState = clone(state);
      newLoginState.store.getLoginUsers = action.newStore;
      return newLoginState;
    case "getLoginUser":
      let newGetUser = clone(state);
      newGetUser.store.newGetUser = action.newStore;
      return newGetUser;
    case "getSelectedDocketId":
      // console.log(action.newStore)
      let selectedDocketId = clone(state);
      selectedDocketId.store.selectedDocketIds = action.newStore;
      //console.log(selectedDocketId)
      return selectedDocketId;
    // ------------login ends------------------//

    // process master
    case "getProcessMaster":
      let newProcessMaster = clone(state);
      newProcessMaster.store.processMaster = action.newStore;
      return newProcessMaster;
    case "getProcessMasterList":
      let newProcessMasterList = clone(state);
      newProcessMasterList.store.processMasterList = action.newStore;
      return newProcessMasterList;
    default:
      return state;
  }
};
