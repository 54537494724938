import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import { getCountry } from "../masterActions";
import { StateContext } from "../../../shared/globalState";
import { printDetails } from "../../../shared/tableCommonFunctions";

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { selectedCountryID } = props;
  let acnArr = props.roles.filter((u) => u.menuName === "Country");
  let acnObj = acnArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_country/${selectedCountryID}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewCountry extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      country: {
        countryCode: "",
        countryName: "",
        zoneCode: "",
        dhlZoneCode: "",
        alternateCountryCode: "",
        alternateCountryName: "",
        isActive: "",
      },
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let countryID = this.props.match.params.countryId;
    getCountry(countryID).then((r) => {
      dispatch({
        type: "getCountry",
        newStore: r,
      });
      this.setState({ country: r });
    });
  }

  render() {
    let { country } = this.state;
    let countryId = this.props.match.params.countryId;

    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selectedCountryID={countryId}
            roles={this.props.roles}
          />
          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">Country Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Country Code</div>
                <div className="view-page-value">{country.countryCode}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Country Name</div>
                <div className="view-page-value">{country.countryName}</div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Zone</div>
                <div className="view-page-value">{country.zoneCode}</div>
              </div>

              <div className="view-page-contents-sub-container">
                <div className="view-page-label">DHL Zone</div>
                <div className="view-page-value">{country.dhlZoneName}</div>
              </div>
            </div>

            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Alternate Country Code</div>
                <div className="view-page-value">
                  {country.alternateCountryCode}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Alternate Country Name</div>
                <div className="view-page-value">
                  {country.alternateCountryName}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Active</div>
                <div className="view-page-value">
                  {country.isActive === true ? "Yes" : "No"}
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "25px" }} />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.props.history.push("/country")}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewCountry);
