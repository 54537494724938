import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import clone from "clone";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import moment from "moment";
import {
  SearchIcon,
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import {
  stableSort,
  getSorting,
  DialogActions,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import { StateContext } from "../../../shared/globalState";
import { getEBLOList, deleteEBLO, getEBLOAdvancedSearch } from "./action";
import { getCustomerForInsertMasterList } from "../../publisher/action";
import { getCountryDropdownList } from "../../masters/masterActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ExportCSV } from "../../../shared/ExportCSV";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import StatusText from "../../../shared/statusText";
import { getEBLOStatusList } from "./action";
const styles = (theme) => ({
  textField: {
    marginRight: theme.spacing(2),
    fontSize: "11px !important",
    flex: 1,
  },
  emptyField: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
  },
  lastChild: {
    marginRight: 0,
  },
});

// const columns = [
//   {
//     name: "Modified By",
//     id: 10,
//   },
//   {
//     name: "Modified Date",
//     id: 11,
//   },
//   {
//     name: "Created By",
//     id: 12,
//   },
//   {
//     name: "Created Date",
//     id: 13,
//   },
// ];
const columns = [
  {
    name: "Modified By",
    id: 21,
  },
  {
    name: "Modified Date",
    id: 22,
    // show: false
  },
  {
    name: "Created By",
    id: 23,
  },
  {
    name: "Created Date",
    id: 24,
  },
];

// function createData(
//   client,
//   ebloNo,
//   type,
//   recipient,
//   deliveryMethod,
//   countryName,
//   orderDate,
//   status,
//   trackingNumber,
//   modifiedByName,
//   modifiedDate,
//   createdByName,
//   createdDate,
//   countryID,
//   statusID,
//   ebloOrderFileHeaderID
// ) {
//   return {
//     client,
//     ebloNo,
//     type,
//     recipient,
//     deliveryMethod,
//     countryName,
//     orderDate,
//     status,
//     trackingNumber,
//     modifiedByName,
//     modifiedDate,
//     createdByName,
//     createdDate,
//     countryID,
//     statusID,
//     ebloOrderFileHeaderID,
//   };
// }
function createData(
  emloOrderID,
  client,
  type,
  status,
  ebloNo,
  acronym,
  volume,
  issue,
  part,
  supp,
  qty,
  totalQuantity,
  country,
  recipient,
  orderDate,
  deliveryDate,
  trackingNumber,
  noOfCartons,
  dhlRate,
  totalWeight,
  specialInstruction,
  modifiedByName,
  modifiedDate,
  createdByName,
  createdDate,
  id
) {
  return {
    emloOrderID,
    client,
    type,
    status,
    ebloNo,
    acronym,
    volume,
    issue,
    part,
    supp,
    qty,
    totalQuantity,
    country,
    recipient,
    orderDate,
    deliveryDate,
    trackingNumber,
    noOfCartons,
    dhlRate,
    totalWeight,
    specialInstruction,
    modifiedByName,
    modifiedDate,
    createdByName,
    createdDate,
    id
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          {/* <FormControlLabel
            control={
              <CustomCheckbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all production code" }}
              />
            }
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [formValid, setIsformValid] = useState(false);
  var publisherBackLabelManage = props.roles.filter((r) => {
    return r.superMenuName === "Publisher Back Label";
  });
  let blebloArr =
    publisherBackLabelManage.length > 0 &&
    publisherBackLabelManage.filter(
      (u) => u.menuName === "EBLO Order Placement"
    );
  var acnObj = blebloArr[0];
  const [ebploSearchValue, setValues] = useState({
    eblo: "",
    modifiedDateFrom: null,
    modifiedDateTo: null,
    status: "",
    country: "",
    trackingNumber: ""
  });

  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,

    handledeleteProductionLineCode,
    searchPopupOpen,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchebloCode,
    searchClicked,

    handleViewColumns,
    codeTypeList,
    statusList,
    statusListInput,
    countryListInput,
    countryList,
    newStatusList,
    exportDataValues,
    selected,
    isActive,
    selectedStatus,
    SelectedEBLOOrderFileHeaderID,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);

  const selectedEBLOID = selected[0];
  const selectedebloID = codeTypeList.find((x) => x.emloOrderID === SelectedEBLOOrderFileHeaderID);

  let expData = clone(codeTypeList);
  // expData.map((d) => {
  //   d["Customer"] = d["client"];
  //   d["EBLO"] = d["ebloNo"];
  //   d["Type"] = d["type"];
  //   d["Recipient"] = d["recipient"];
  //   d["Delivery Method"] = d["deliveryMethod"];
  //   d["Country Name"] = d["countryName"];
  //   d["Order Date"] = d["orderDate"]
  //     ? moment(d["orderDate"]).format("DD/MM/YYYY")
  //     : "";
  //   d["Status"] = d["status"];
  //   d["Tracking Number"] = d["trackingNumber"];
  //   d["Modified By"] = d["modifiedByName"];
  //   d["Modified Date"] = d["modifiedDate"]
  //     ? moment(d["modifiedDate"]).format("DD/MM/YYYY HH:mm:ss")
  //     : "";
  //   d["Created By"] = d["createdByName"];
  //   d["Created Date"] = d["createdDate"]
  //     ? moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss")
  //     : "";

  //   delete d["countryID"];
  //   delete d["statusID"];
  //   delete d["ebloOrderFileHeaderID"];
  //   delete d["issueDescription"];
  //   delete d["client"];
  //   delete d["ebloNo"];
  //   delete d["type"];
  //   delete d["recipient"];
  //   delete d["deliveryMethod"];
  //   delete d["countryName"];
  //   delete d["orderDate"];
  //   delete d["status"];
  //   delete d["trackingNumber"];
  //   delete d["createdByName"];
  //   delete d["createdDate"];
  //   delete d["modifiedByName"];
  //   delete d["modifiedDate"];
  // });


  expData &&
    expData.length > 0 &&
    expData.map((d) => {
      // d["emloOrderID"]=d["emloOrderID"];
      d["Customer"] = d["client"];
      d["Type"] = d["type"];
      d["Status"] = d["status"];
      d["EBLO"] = d["ebloNo"];
      d["Acronym"] = d["acronym"];
      d["Volume"] = d["volume"];
      d["Issue"] = d["issue"];
      d["Part"] = d["part"];
      d["Supp"] = d["supp"];
      d["Quantity"] = d["qty"];
      d["Total Quantity"] = d["totalQuantity"];
      d["Country"] = d["country"];
      d["Recipient"] = d["recipient"];
      d["Order Date"] = d["orderDate"] ? moment(d["orderDate"]).format("DD/MM/YYYY") : ""
      d["Delivery Date"] = d["deliveryDate"] ? moment(d["deliveryDate"]).format("DD/MM/YYYY") : "";
      d["Tracking Number"] = d["trackingNumber"];
      d["No Of Cartons"] = d["noOfCartons"];
      d["DHL Rate"] = d["dhlRate"];
      d["Total Weight"] = d["totalWeight"];
      d["Special Instruction"] = d["specialInstruction"];
      d["Modified By Name"] = d["modifiedByName"];
      d["Modified Date"] = moment(d["modifiedDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      d["Created By Name"] = d["createdByName"];
      d["Created Date"] = moment(d["createdDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      delete d["id"];
      delete d["emloOrderID"];
      delete d["status"];
      delete d["volume"];
      delete d["modifiedDate"];
      delete d["modifiedByName"];
      delete d["createdDate"];
      delete d["createdByName"];
      delete d["client"];
      delete d["ebloNo"];
      delete d["type"];
      delete d["acronym"];
      delete d["issue"];
      delete d["part"];
      delete d["supp"];
      delete d["trackingNumber"];
      delete d["deliveryDate"];
      delete d["qty"];
      delete d["totalQuantity"]
      delete d["country"];
      delete d["noOfCartons"];
      delete d["recipient"];
      delete d["orderDate"];
      delete d["totalWeight"];
      delete d["dhlRate"];
      delete d["specialInstruction"];
    });
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    clearSearchValues();
    handleDeletePopupClose();
    // setValues({
    //   ...ebploSearchValue,
    //   country: "",
    //   selectedCountryID: "",
    //   status: "",
    //   selectedStatusID: "",
    // });
  };

  const deleteProductionLineCode = () => {
    handledeleteProductionLineCode();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = ebploSearchValue;
    searchValues.eblo = "";
    searchValues.country = "";
    searchValues.status = "";
    searchValues.modifiedDateFrom = null;
    searchValues.modifiedDateTo = null;
    searchValues.trackingNumber = ""
    searchValues = ebploSearchValue;
    setValues({
      ...searchValues,
      country: "",
      selectedCountryID: null,
      status: "",
      selectedStatusID: null,
    });
  };

  const searchPopup = () => {
    handleSearchebloCode(ebploSearchValue);
    clearSearchValues();
  };

  const handleSearchInput = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;
    setValues((ebploSearchValue) => ({
      ...ebploSearchValue,
      [event.target.name]: event.target.value,
    }));
  };

  const checkButtonDisability = () => {
    let { ebploSearchValue } = this.state;
    if (
      !ebploSearchValue.eblo &&
      !ebploSearchValue.country &&
      !ebploSearchValue.status &&
      !ebploSearchValue.trackingNumber &&
      !(ebploSearchValue.modifiedDateFrom && ebploSearchValue.modifiedDateTo)
    )
      return true;
    else return false;
  };

  const handleDateChange = (date) => {
    const modifiedDateFrom = date && date.toLocaleDateString();
    setValues({ ...ebploSearchValue, modifiedDateFrom: modifiedDateFrom });
  };

  const handleDateChange1 = (date) => {
    const modifiedDateTo = date && date.toLocaleDateString();
    setValues((ebploSearchValue) => ({
      ...ebploSearchValue,
      modifiedDateTo: modifiedDateTo,
    }));
  };

  const handleAutocomplete = (e, val) => {
    setValues({
      ...ebploSearchValue,
      status: val && val.text,
      selectedStatusID: val && val.id,
    });
  };

  const handleAutocompleteCountry = (e, val) => {
    setValues({
      ...ebploSearchValue,
      country: val && val.text,
      selectedCountryID: val && val.id,
    });
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {/* {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )} */}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link
                      to={`/view_pubEbloOrderPlacement/${SelectedEBLOOrderFileHeaderID}`}
                    >
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj &&
                  acnObj.editAction &&
                  selectedStatus !== "Cancelled" &&
                  //selectedStatus !== "Processed" &&
                  // selectedStatus !== "Completed" &&
                  selectedStatus !== "In-Progress" ? (
                    <Tooltip title="Edit">
                      <Link
                        to={`/edit_pubEbloOrderPlacement/${SelectedEBLOOrderFileHeaderID}`}
                      >
                        <IconButton
                          aria-label="edit"
                          className={classes.iconHover}
                        >
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  ) : (
                    ""
                  )}
              </div>
            )}
            {acnObj &&
              acnObj.deleteAction &&
              selectedStatus !== "Cancelled" &&
              selectedStatus !== "Completed" &&
              // selectedStatus !== "Processed" &&
              selectedStatus !== "In-Progress" ? (
                <Tooltip title="Delete">
                  <IconButton aria-label="delete" onClick={handleClickOpen}>
                    <DeleteIcon className={classes.iconHover} />
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1 ? " ?" : selectedebloID.ebloNo + " ?"}
                    {/* {selectedebloID.codeType} */}
                  </span>
                </div>
              </DialogContent>
              <DialogActions style={{ padding: "10px 24px" }}>
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="contained"
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteProductionLineCode}
                  color="primary"
                  variant="contained"
                  style={{ boxShadow: "none" }}
                  className="enabled-button"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            >
              {acnObj &&
                acnObj.addAction && <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => props.history.push("/pubEbloBatchProcessing")}
                >
                  {" "}
              EBLO Batch Processing{" "}
                </Button>}
              {searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  <form onSubmit={searchPopup}>
                    {acnObj && acnObj.advanceSearchAction && (
                      <Tooltip title="Search">
                        <IconButton
                          aria-label="Search"
                          className={classes.iconHover}
                          onClick={handleSearchOpen}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      onClose={handleSearchPopupClose}
                      aria-labelledby="customized-dialog-title"
                      open={searchPopupOpen}
                      maxWidth="md"
                    >
                      <DialogTitle id="customized-dialog-title">
                        Advanced Search
                  </DialogTitle>
                      <DialogContent dividers>
                        <React.Fragment>
                          <div style={{ display: "flex" }}>
                            <TextField
                              label="EBLO No"
                              name="eblo"
                              style={{ flex: 1, marginRight: "16px" }}
                              value={ebploSearchValue.eblo}
                              onChange={handleSearchInput}
                              margin="normal"
                            />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                autoOk
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                minDateMessage={""}
                                style={{ flex: 1, marginRight: "16px" }}
                                id="modifiedDateFrom"
                                label="Modified Date From"
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                value={ebploSearchValue.modifiedDateFrom}
                              />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disabled={!ebploSearchValue.modifiedDateFrom}
                                disableToolbar
                                autoOk
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                minDate={ebploSearchValue.modifiedDateFrom}
                                minDateMessage={""}
                                style={{ flex: 1 }}
                                id="modifiedDateTo"
                                label="Modified Date To"
                                value={ebploSearchValue.modifiedDateTo}
                                onChange={handleDateChange1}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                          <div style={{ display: "flex" }}>
                            <Autocomplete
                              autoComplete
                              includeInputInList
                              name="status"
                              value={ebploSearchValue.status}
                              style={{ flex: 1, marginRight: "16px" }}
                              options={newStatusList}
                              onChange={handleAutocomplete}
                              getOptionLabel={(option) =>
                                option.text || ebploSearchValue.status
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Status"
                                  style={{ width: "100%" }}
                                  margin="normal"
                                />
                              )}
                            />
                            <Autocomplete
                              autoComplete
                              includeInputInList
                              name="country"
                              value={ebploSearchValue.country}
                              style={{ flex: 1, marginRight: "16px" }}
                              options={countryList}
                              onChange={handleAutocompleteCountry}
                              getOptionLabel={(option) =>
                                option.text || ebploSearchValue.country
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Country"
                                  style={{ width: "100%" }}
                                  margin="normal"
                                />
                              )}
                            />
                            {/* <div style={{ flexGrow: 1 }}></div> */}
                            <TextField
                              label="Tracking Number"
                              name="trackingNumber"
                              style={{ flex: 1, }}
                              value={ebploSearchValue.trackingNumber}
                              onChange={handleSearchInput}
                              margin="normal"
                            />
                            {/* <TextField
                          label="Status"
                          name="status"
                          style={{flex: 1, marginRight: "16px"}}
                          value={ebploSearchValue.status}
                          onChange={handleSearchInput}
                          margin="normal"
                        /> 
                        <TextField
                          label="Country"
                          name="country"
                          style={{ flex: 1, marginRight: "16px" }}
                          value={ebploSearchValue.country}
                          onChange={handleSearchInput}
                          margin="normal"
                        />
                        <TextField
                          label="Recipient"
                          name="recipient"
                          style={{flex: 1}}
                          value={ebploSearchValue.recipient}
                          onChange={handleSearchInput}
                          margin="normal"
                        /> */}
                          </div>
                        </React.Fragment>
                      </DialogContent>
                      <DialogActions style={{ padding: "10px 24px" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={handleSearchClose}
                        >
                          CANCEL
                    </Button>

                        <Button
                          disabled={
                            // !ebploSearchValue.eblo &&
                            // !ebploSearchValue.status &&
                            // !ebploSearchValue.country &&
                            !(ebploSearchValue.modifiedDateFrom
                              ? ebploSearchValue.modifiedDateTo
                              : ebploSearchValue.eblo ||
                              ebploSearchValue.status ||
                              ebploSearchValue.trackingNumber ||
                              ebploSearchValue.country)
                          }
                          autoFocus
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={searchPopup}
                        >
                          SEARCH
                    </Button>
                      </DialogActions>
                    </Dialog>
                  </form>
                )}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="add"
                    className={classes.iconHover}
                    onClick={(e) =>
                      props.history.push("/add_pubEbloOrderPlacement")
                    }
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {acnObj && acnObj.exportAction && (
                <ExportCSV csvData={expData} fileName={"EBLO_ORDER_PLACEMENT"} />
              )}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                handleViewColumns={handleViewColumns}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    handleViewColumns,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => handleViewColumns(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

class ebloOrderPlacement extends React.Component {
  static contextType = StateContext;
  state = {
    customerId: "",
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    codeTypeList: [],
    statusList: {},
    newStatusList: [],
    selectedStatusID: 0,
    selectedCountryID: 0,
    statusListInput: [],
    countryListInput: [],
    countryObj: {},
    countryList: [],
    rows: [],
    activeRows: 0,
    isActive: false,
    loading: false,
    refreshData: false,
    selectedebloID: {},
    selectedebloIDId: 1,
    selectedStatus: "",
    searchPopupOpen: false,

    searchClicked: false,
    selectedShowColumns: [],

    SelectedEBLOOrderFileHeaderID: "",
    headCells: [
      {
        id: 1,
        code: "client",
        numeric: false,
        disablePadding: false,
        label: "Customer",
        show: true,
      },
      {
        id: 2,
        code: "type",
        numeric: false,
        disablePadding: false,
        label: "Type",
        show: true,
      },
      {
        id: 3,
        code: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        show: true,
      },
      {
        id: 4,
        code: "ebloNo",
        numeric: false,
        disablePadding: false,
        label: "EBLO",
        show: true,
      },
      {
        id: 5,
        code: "acronym",
        numeric: false,
        disablePadding: false,
        label: "Acronym",
        show: true,
      },
      {
        id: 6,
        code: "volume",
        numeric: false,
        disablePadding: false,
        label: "Volume",
        show: true,
      },
      {
        id: 7,
        code: "issue",
        numeric: false,
        disablePadding: false,
        label: "Issue",
        show: true,
      },
      {
        id: 8,
        code: "part",
        numeric: false,
        disablePadding: false,
        label: "Part",
        show: true,
      },
      {
        id: 9,
        code: "supp",
        numeric: false,
        disablePadding: false,
        label: "Supp",
        show: true,
      },
      {
        id: 10,
        code: "qty",
        numeric: false,
        disablePadding: false,
        label: "Quantity",
        show: true,
      },
      {
        id: 11,
        code: "totalQuantity",
        numeric: false,
        disablePadding: false,
        label: "Total Quantity",
        show: true,
      },
      {
        id: 12,
        code: "country",
        numeric: false,
        disablePadding: false,
        label: "Country",
        show: true,
      },
      {
        id: 13,
        code: "recipient",
        numeric: false,
        disablePadding: false,
        label: "Recipient ",
        show: true,
      },
      {
        id: 14,
        code: "orderDate",
        numeric: false,
        disablePadding: false,
        label: "Order Date",
        show: true,
      },
      {
        id: 15,
        code: "deliveryDate",
        numeric: false,
        disablePadding: false,
        label: "Delivery Date",
        show: true,
      },
      {
        id: 16,
        code: "trackingNumber",
        numeric: false,
        disablePadding: false,
        label: "Tracking Number",
        show: true,
      },
      {
        id: 17,
        code: "noOfCartons",
        numeric: false,
        disablePadding: false,
        label: "No Of Cartons",
        show: true,
      },
      {
        id: 18,
        code: "dhlRate",
        numeric: false,
        disablePadding: false,
        label: "DHL Rate",
        show: true,
      },
      {
        id: 19,
        code: "totalWeight",
        numeric: false,
        disablePadding: false,
        label: "Total Weight",
        show: true,
      },
      {
        id: 20,
        code: "specialInstruction",
        numeric: false,
        disablePadding: false,
        label: "Special Instructions",
        show: true,
      },
      {
        id: 21,
        code: "modifiedByName",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 22,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 23,
        code: "createdByName",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
      {
        id: 24,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
    ],
    // headCells: [
    //   {
    //     id: 1,
    //     code: "client",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Customer",
    //     show: true,
    //   },
    //   {
    //     id: 2,
    //     code: "ebloNo",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "EBLO",
    //     show: true,
    //   },
    //   {
    //     id: 3,
    //     code: "type",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Type",
    //     show: true,
    //   },
    //   {
    //     id: 4,
    //     code: "recipient",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Recipient",
    //     show: true,
    //   },
    //   {
    //     id: 5,
    //     code: "deliveryMethod",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Delivery Method",
    //     show: true,
    //   },
    //   {
    //     id: 6,
    //     code: "countryName",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Country",
    //     show: true,
    //   },
    //   {
    //     id: 7,
    //     code: "orderDate",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Order Date",
    //     show: true,
    //   },
    //   {
    //     id: 8,
    //     code: "status",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Status",
    //     show: true,
    //   },
    //   {
    //     id: 9,
    //     code: "trackingNumber",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Tracking Number",
    //     show: true,
    //   },
    //   {
    //     id: 10,
    //     code: "modifiedByName",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Modified By",
    //     show: true,
    //   },
    //   {
    //     id: 11,
    //     code: "modifiedDate",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Modified Date",
    //     show: true,
    //   },
    //   {
    //     id: 12,
    //     code: "createdByName",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Created By",
    //     show: true,
    //   },
    //   {
    //     id: 13,
    //     code: "createdDate",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Created Date",
    //     show: true,
    //   },
    // ],
  };
  componentDidMount() {
    this.props.handleSettingHeader("EBLO Order Placement");
    this.props.setSelectedList(
      "publisherBackLabelOpen",
      "pubEbloOrderPlacement"
    );
    const dispatch = this.context[1];

    getCustomerForInsertMasterList().then((r) => {
      const filtered =
        r &&
        r.data &&
        r.data.reduce(
          (a, o) => (
            o.text.toLowerCase().indexOf("wiley") != -1 && a.push(o.id), a
          ),
          []
        );
      this.setState({
        customerId: filtered && filtered[0],
      });
    });

    getCountryDropdownList().then((r) => {
      this.getAllCountries(r.data);
      this.setState({ loading: true });
      getEBLOList().then((r) => {
        this.getEBLO(r.data);
        this.exportebloDatas(r.data);
      });
    });
    getEBLOStatusList().then((r) => {
      this.getAllStatusList(r.data);
    });
  }
  getAllStatusList = (list) => {
    this.setState({
      newStatusList:
        list &&
        list.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };
  getAllCountries = (list) => {
    let countryList = [],
      countryObj = {};
    // countryList = list && list;
    list &&
      list.map((list) => {
        countryObj[list.id] = list && list.text && list.text.split("- ")[1];
      });
    this.setState({
      countryObj: countryObj,
      countryList:
        list &&
        list.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };

  handleViewColumns = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  exportebloDatas = (codeTypeList) => {
    let list = codeTypeList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          Client: d.client,
          EBLO: d.ebloNo,
          Type: d.type,
          Recipient: d.recipient,
          DeliveryMethod: d.deliveryMethod,
          Country: d.countryName,
          OrderDate: d.orderDate,
          Status: d.status,
          TrackingNumber: d.trackingNumber,
          CreatedBy: d.createdByName,
          CreatedDate: d.createdDate,
          ModifiedBy: d.modifiedByName,
          ModifiedDate: d.modifiedDate,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getEBLO = (codeTypeList) => {
    let list = codeTypeList,
      statusTemp = [],
      statusTextArr = [],
      statusListInputArr = [],
      countryTextArr = [],
      countryListInputArr = [];
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d, ckBxId) => {
        return rows.push(
          // createData(
          //   d.client,
          //   d.ebloNo,
          //   d.type,
          //   d.recipient,
          //   d.deliveryMethod,
          //   this.state.countryObj[d.countryID],
          //   d.orderDate,
          //   d.status,
          //   d.trackingNumber,
          //   d.modifiedByName,
          //   d.modifiedDate,
          //   d.createdByName,
          //   d.createdDate,
          //   d.countryID,
          //   d.statusID,
          //   d.ebloOrderFileHeaderID
          // )
          createData(
            d.ebloOrderFileHeaderID,
            d.client,
            d.type,
            d.status,
            d.ebloNo,
            d.acronymn,
            d.volume,
            d.issue,
            d.partNumber,
            d.suppNumber,
            d.quantity,
            d.totalQuantity,
            d.countryName,
            d.recipient,
            d.orderDate,
            d.deliveryDate,
            d.trackingNumber,
            d.noOfCartons,
            d.dhlRate,
            d.actualWeight,
            d.specialInstructions,
            d.modifiedByName,
            d.modifiedDate,
            d.createdByName,
            d.createdDate,
            // d.ebloOrderDetailID
            ckBxId
          )
        );
      });
    list &&
      list.length > 0 &&
      list.map((list) => {
        if (!statusTextArr.includes(list.status)) {
          statusTextArr.push(list.status);
          statusTemp[list.status] = list.statusID;
          statusListInputArr.push({
            text: list.status,
            id: list.statusID,
          });
        }
        if (!countryTextArr.includes(list.countryName)) {
          countryTextArr.push(list.countryName);
          countryListInputArr.push({
            text: list.countryName,
            id: list.countryID,
          });
        }
      });
    this.setState({
      codeTypeList: rows,
      statusList: statusTemp,
      statusListInput: statusListInputArr,
      countryListInput: countryListInputArr,
      selected: [],
      loading: false,
      activeRows: rows.filter((x) => x.isActive).map((n) => n.id),
    });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    return true;
  }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleClick(event, data) {

    let id = data.id,
      selected = [],
      newSelected = [];
    if (event.target.checked) {
      newSelected = newSelected.concat(selected, id);
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedebloID: data,
        SelectedEBLOOrderFileHeaderID: data.emloOrderID,
        selectedStatus: data.status,
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedebloID: {},
        SelectedEBLOOrderFileHeaderID: "",
        selectedStatus: "",
      });
    }
  }
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchebloCode = (handleSearchebloCode) => {
    let ebploSearchValue = handleSearchebloCode;
    const dispatch = this.context[1];

    let customerID = this.state.customerId;
    let ebloNo = ebploSearchValue.eblo;
    let countryID = ebploSearchValue.selectedCountryID;
    let StatusID = ebploSearchValue.selectedStatusID;
    // let countryID = ebploSearchValue.country;
    // let status = this.state.statusList[ebploSearchValue.status];
    let modifiedDateFrom = ebploSearchValue.modifiedDateFrom;
    let modifiedDateTo = ebploSearchValue.modifiedDateTo;
    let trackingNumber = ebploSearchValue.trackingNumber
    let issueDescription = null;

    let reqBody = {
      customerID,
      ebloNo,
      // countryID,
      // StatusID,
      modifiedDateFrom,
      modifiedDateTo,
      trackingNumber
    };
    if (countryID) reqBody.countryID = countryID;
    if (StatusID) reqBody.StatusID = StatusID;
    this.setState({ codeTypeList: [], selected: [], rows: [], loading: true });
    getEBLOAdvancedSearch(reqBody).then((r) => {
      this.getEBLO(r.data);
    });
    this.setState({ searchPopupOpen: false, searchClicked: true });
  };

  handledeleteProductionLineCode = () => {
    const dispatch = this.context[1];
    const deletionID = this.state.SelectedEBLOOrderFileHeaderID;
    const modifiedBy = localStorage.loggedInId;
    deleteEBLO(modifiedBy, deletionID).then((r) => {
      getEBLOList().then((r) => {
        this.getEBLO(r.data);
        this.exportebloDatas(r.data);
      });
      this.setState({
        deletePopupOpen: false,
        selected: [],
        selectedEBLOID: "",
        SelectedEBLOOrderFileHeaderID: "",
      });
    });
  };

  clearSearch = () => {
    const dispatch = this.context[1];
    let newEbloSrcVal = clone(this.state.ebploSearchValue);
    this.setState({ codeTypeList: [], selected: [], rows: [], loading: true });
    getEBLOList().then((r) => {
      // dispatch({
      //   type: "getEBLO",
      //   newStore: r.data,
      // });
      this.getEBLO(r.data);
    });

    this.setState({ searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      codeTypeList,
      headCells,
      statusList,
      statusListInput,
      countryListInput,
      exportDataValues,
      countryList,
      newStatusList,
    } = this.state;
    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            SelectedEBLOOrderFileHeaderID={
              this.state.SelectedEBLOOrderFileHeaderID
            }
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handledeleteProductionLineCode={this.handledeleteProductionLineCode}
            selectedebloID={this.state.selectedebloID}
            selectedebloIDId={this.state.selectedebloIDId}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchebloCode={this.handleSearchebloCode}
            ebploSearchValue={this.state.ebploSearchValues}
            searchClicked={this.state.searchClicked}
            handleViewColumns={this.handleViewColumns}
            headCells={headCells}
            codeTypeList={codeTypeList}
            statusList={statusList}
            statusListInput={statusListInput}
            countryListInput={countryListInput}
            countryList={countryList}
            newStatusList={newStatusList}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            selectedStatus={this.state.selectedStatus}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
                style={{ whiteSpace: "nowrap" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {codeTypeList.length > 0 ? (
                    stableSort(codeTypeList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        return (
                          <TableRow
                            hover
                            onClick={(event) => this.handleClick(event, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {/* {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {row.client && row.client.length > 16 ? (
                                  <Tooltip title={row.client} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.client}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.client}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {row.ebloNo && row.ebloNo.length > 16 ? (
                                  <Tooltip title={row.ebloNo} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.ebloNo}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.ebloNo}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {row.type && row.type.length > 16 ? (
                                  <Tooltip title={row.type} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.type}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.type}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {row.recipient && row.recipient.length > 16 ? (
                                  <Tooltip
                                    title={row.recipient}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.recipient}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.recipient}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                align="left"
                              >
                                {row.deliveryMethod &&
                                  row.deliveryMethod.length > 16 ? (
                                    <Tooltip
                                      title={row.deliveryMethod}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.deliveryMethod}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.deliveryMethod}
                                    </Typography>
                                  )}
                              </TableCell>
                            )} */}
                            {/* {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                align="left"
                              >
                                {row.countryName &&
                                  row.countryName.length > 16 ? (
                                    <Tooltip
                                      title={row.countryName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.countryName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.countryName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                align="left"
                              >
                                {row.orderDate && row.orderDate.length > 16 ? (
                                  <Tooltip
                                    title={
                                      row.orderDate
                                        ? moment(row.orderDate).format(
                                          "DD/MM/YYYY"
                                        )
                                        : ""
                                    }
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.orderDate
                                        ? moment(row.orderDate).format(
                                          "DD/MM/YYYY"
                                        )
                                        : ""}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.orderDate
                                        ? moment(row.orderDate).format(
                                          "DD/MM/YYYY"
                                        )
                                        : ""}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                align="left"
                              >
                                {row.status && row.status.length > 16 ? (
                                  <Tooltip title={row.status} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.status == "Submitted" ? (
                                        <StatusText color={"#16831F"}>
                                          Submitted
                                        </StatusText>
                                      ) : row.status == "UnSubmitted" ? (
                                        <StatusText color={"#F4B43C"}>
                                          UnSubmitted
                                        </StatusText>
                                      ) : row.status == "Processed" ? (
                                        <StatusText color={"#0091ff"}>
                                          Processed
                                        </StatusText>
                                      ) : row.status == "In-Progress" ? (
                                        <StatusText color={"#0091ff"}>
                                          In-Progress
                                        </StatusText>
                                      ) : row.status == "Cancelled" ? (
                                        <StatusText color={"red"}>
                                          Cancelled
                                        </StatusText>
                                      ) : row.status == "Completed" ? (
                                        <StatusText color={"#16831F"}>
                                          Completed
                                        </StatusText>
                                      ) : (
                                                    <StatusText color={"grey"}>
                                                      {row.status}
                                                    </StatusText>
                                                  )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.status == "Submitted" ? (
                                        <StatusText color={"#16831F"}>
                                          Submitted
                                        </StatusText>
                                      ) : row.status == "UnSubmitted" ? (
                                        <StatusText color={"#F4B43C"}>
                                          UnSubmitted
                                        </StatusText>
                                      ) : row.status == "Processed" ? (
                                        <StatusText color={"#0091ff"}>
                                          Processed
                                        </StatusText>
                                      ) : row.status == "In-Progress" ? (
                                        <StatusText color={"#0091ff"}>
                                          In-Progress
                                        </StatusText>
                                      ) : row.status == "Cancelled" ? (
                                        <StatusText color={"red"}>
                                          Cancelled
                                        </StatusText>
                                      ) : row.status == "Completed" ? (
                                        <StatusText color={"#16831F"}>
                                          Completed
                                        </StatusText>
                                      ) : (
                                                    <StatusText color={"grey"}>
                                                      {row.status}
                                                    </StatusText>
                                                  )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                align="left"
                              >
                                {row.trackingNumber &&
                                  row.trackingNumber.length > 16 ? (
                                    <Tooltip
                                      title={row.trackingNumber}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.trackingNumber}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.trackingNumber}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                align="left"
                              >
                                {row.modifiedByName &&
                                  row.modifiedByName.length > 16 ? (
                                    <Tooltip
                                      title={row.modifiedByName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                                align="left"
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )} */}
                            {/* {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                align="left"
                              >
                                {row.createdByName &&
                                  row.createdByName.length > 16 ? (
                                    <Tooltip
                                      title={row.createdByName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.createdByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                align="left"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "} */}
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.client && row.client.length > 16 ? (
                                    <Tooltip title={row.client}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.client}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.client}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}

                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.type && row.type.length > 16 ? (
                                  <Tooltip title={row.type}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.type}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.type}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.status && row.status.length > 10 ? (
                                    <Tooltip title={row.status}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.status == "Submitted" ? (
                                          <StatusText color={"#16831F"}>
                                            Submitted
                                          </StatusText>
                                        ) : row.status == "UnSubmitted" ? (
                                          <StatusText color={"#F4B43C"}>
                                            UnSubmitted
                                          </StatusText>
                                        ) : row.status == "Processed" ? (
                                          <StatusText color={"#0091ff"}>
                                            Processed
                                          </StatusText>
                                        ) : row.status == "In-Progress" ? (
                                          <StatusText color={"#0091ff"}>
                                            In-Progress
                                          </StatusText>
                                        ) : row.status == "Cancelled" ? (
                                          <StatusText color={"red"}>
                                            Cancelled
                                          </StatusText>
                                        ) : row.status == "Completed" ? (
                                          <StatusText color={"#16831F"}>
                                            Completed
                                          </StatusText>
                                        ) : (
                                                      <StatusText color={"grey"}>
                                                        {row.status}
                                                      </StatusText>
                                                    )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.status == "Submitted" ? (
                                          <StatusText color={"#16831F"}>
                                            Submitted
                                          </StatusText>
                                        ) : row.status == "UnSubmitted" ? (
                                          <StatusText color={"#F4B43C"}>
                                            UnSubmitted
                                          </StatusText>
                                        ) : row.status == "Processed" ? (
                                          <StatusText color={"#0091ff"}>
                                            Processed
                                          </StatusText>
                                        ) : row.status == "In-Progress" ? (
                                          <StatusText color={"#0091ff"}>
                                            In-Progress
                                          </StatusText>
                                        ) : row.status == "Cancelled" ? (
                                          <StatusText color={"red"}>
                                            Cancelled
                                          </StatusText>
                                        ) : row.status == "Completed" ? (
                                          <StatusText color={"#16831F"}>
                                            Completed
                                          </StatusText>
                                        ) : (
                                                      <StatusText color={"grey"}>
                                                        {row.status}
                                                      </StatusText>
                                                    )}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.ebloNo && row.ebloNo.length > 16 ? (
                                  <Tooltip title={row.ebloNo}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.ebloNo}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.ebloNo}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.acronym && row.acronym.length > 16 ? (
                                  <Tooltip title={row.acronym}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.acronym}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.acronym}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.volume && row.volume.length > 16 ? (
                                  <Tooltip title={row.volume}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.volume}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.volume}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.issue && row.issue.length > 16 ? (
                                  <Tooltip title={row.issue}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issue}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issue}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.part && row.part.length > 16 ? (
                                  <Tooltip title={row.part}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.part}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.part}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.supp && row.supp.length > 16 ? (
                                  <Tooltip title={row.supp}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.supp}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.supp}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.qty && row.qty.length > 16 ? (
                                  <Tooltip title={row.qty}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.qty}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.qty}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.totalQuantity && row.totalQuantity.length > 16 ? (
                                  <Tooltip title={row.totalQuantity}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalQuantity}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalQuantity}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.country &&
                                  row.country.length > 16 ? (
                                    <Tooltip title={row.country}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.country}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.country}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.recipient &&
                                  row.recipient.length > 16 ? (
                                    <Tooltip title={row.recipient}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.recipient}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.recipient}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.orderDate &&
                                  moment(row.orderDate).format("DD/MM/YYYY")
                                    .length > 16 ? (
                                    <Tooltip
                                      title={
                                        row.orderDate
                                          ? moment(row.orderDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""
                                      }
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.orderDate
                                          ? moment(row.orderDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.orderDate
                                        ? moment(row.orderDate).format(
                                          "DD/MM/YYYY"
                                        )
                                        : ""}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.deliveryDate &&
                                  moment(row.deliveryDate).format("DD/MM/YYYY")
                                    .length > 16 ? (
                                    <Tooltip
                                      title={
                                        row.deliveryDate
                                          ? moment(row.deliveryDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""
                                      }
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.deliveryDate
                                          ? moment(row.deliveryDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.deliveryDate
                                        ? moment(row.deliveryDate).format(
                                          "DD/MM/YYYY"
                                        )
                                        : ""}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[15].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.trackingNumber && row.trackingNumber.length > 12 ? (
                                  <Tooltip title={row.trackingNumber}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.trackingNumber}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.trackingNumber}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[16].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.noOfCartons && row.noOfCartons.length > 16 ? (
                                  <Tooltip title={row.noOfCartons}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noOfCartons}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noOfCartons}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[17].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.dhlRate && row.dhlRate.length > 16 ? (
                                  <Tooltip title={row.dhlRate}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dhlRate}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dhlRate}
                                    </Typography>
                                  )}
                              </TableCell>

                            )}
                            {this.state.headCells[18].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.totalWeight && row.totalWeight.length > 16 ? (
                                  <Tooltip title={row.totalWeight}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalWeight}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalWeight}
                                    </Typography>
                                  )}
                              </TableCell>

                            )}
                            {this.state.headCells[19].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.specialInstruction && row.specialInstruction.length > 16 ? (
                                  <Tooltip title={row.specialInstruction}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.specialInstruction}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.specialInstruction}
                                    </Typography>
                                  )}
                              </TableCell>

                            )}
                            {this.state.headCells[20].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.modifiedByName &&
                                  row.modifiedByName.length > 16 ? (
                                    <Tooltip title={row.modifiedByName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[21].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip title={row.modifiedDate}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[22].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdByName &&
                                  row.createdByName.length > 16 ? (
                                    <Tooltip title={row.createdByName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.createdByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[23].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-right-cell"
                                visibility="hidden"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip title={row.createdDate}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={12} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={codeTypeList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ebloOrderPlacement));
