import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import ReportViewer from "../../app/ReportViewer";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import EdsErrorModal from "../docketPosting/edsErrorModal";
import clone from "clone";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { StateContext } from "../../../shared/globalState";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";
import { withRouter } from "react-router-dom";
import StatusText from "../../../shared/statusText";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Autocomplete } from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import { Checkbox } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import {
  EditIcon,
  ViewIcon,
  CustomSmallAddIcon,
} from "../../../shared/customIcons";
import "date-fns";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import {
  getZoneDropDownList,
  getProvinceDropDownList,
} from "../../masters/masterActions";
import { getCountryDropdownList } from "../../masters/masterActions";
import {
  getMailSizeList,
  getRunMailList,
  deleteDocket,
  GetAllDocketsByJGID,
  createRunMailList,
  updateRunMailList,
  deleteRunMailList,
  getFileNamesDropDown,
  getFileNamesDropDownWithID,
} from "../genericDocketAction";
import { stableSort, getSorting } from "../../../shared/tableCommonFunctions";
import { getJobGroupbyJobGroupId } from "../workOrder/action";
import { getEdsErrorDetails } from "../docketPosting/actionsDocket"
import {
  generateEDocket,
  generateEDocketInternational,
} from "../docketPosting/actionsDocket";
import { getDocketList } from "../docketPosting/actions";
let docketStatusSelection = {
  Open: true,
  "Manual Docket Generated": false,
  "EDS Error": true,
};
let docsel = [];

let manDoc = [];

const headCells = [
  {
    id: 1,
    code: "fileName",
    numeric: false,
    disablePadding: false,
    label: "File Name",
    width: "auto",
  },
  {
    id: 2,
    code: "zone",
    numeric: false,
    disablePadding: false,
    label: "Zone",
    width: "auto",
  },
  {
    id: 3,
    code: "country",
    numeric: false,
    disablePadding: false,
    label: "Country*",
    width: "auto",
  },
  // {
  //   id: 3,
  //   code: "province",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Province",
  //   width: "auto",
  // },
  {
    id: 4,
    code: "copies",
    numeric: false,
    disablePadding: false,
    label: "Copies *",
    width: "auto",
  },
  // {
  //   id: 5,
  //   code: "quantity",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Qty *",
  //   width: "auto",
  // },
  // {
  //   id: 6,
  //   code: "totalOrderQuantity",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Total Qty",
  //   width: "auto",
  // },
  // {
  //   id: 7,
  //   code: "remarks",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Remarks",
  //   width: "auto",
  // },
  {
    id: 6,
    code: "routing",
    numeric: false,
    disablePadding: false,
    label: "Routing",
    width: "auto",
  },
  {
    id: 7,
    code: "pgsDocketNumber",
    numeric: false,
    disablePadding: false,
    label: "PGS Docket No",
    width: "auto",
  },
  // {
  //   id: 7,
  //   code: "cop",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "COP",
  //   width: "auto",
  // },
  // {
  //   id: 8,
  //   code: "isBM1500Countries",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "BM 1500",
  //   width: "auto",
  // },
  {
    id: 8,
    code: "mailSize",
    numeric: false,
    disablePadding: false,
    label: "Mail Size",
    width: "auto",
  },
  {
    id: 9,
    code: "weightPerItem",
    numeric: false,
    disablePadding: false,
    label: "Wt per Item(Gm) *",
    width: "auto",
  },
  {
    id: 10,
    code: "noOfArticles",
    numeric: false,
    disablePadding: false,
    label: "No of Articles *",
    width: "auto",
  },
  {
    id: 11,
    code: "noOfBags",
    numeric: false,
    disablePadding: false,
    label: "No of Bags",
    width: "auto",
  },
  {
    id: 12,
    code: "weightPerBag",
    numeric: false,
    disablePadding: false,
    label: "Wt per Bag(Kg)",
    width: "auto",
  },
  {
    id: 13,
    code: "totalChargeableWeight",
    numeric: false,
    disablePadding: false,
    label: "Tot Chargeable Wt(kg) *",
    width: "auto",
  },
  {
    id: 14,
    code: "postageCharges",
    numeric: false,
    disablePadding: false,
    label: "Postage Charges",
    width: "auto",
  },
  {
    id: 15,
    code: "totalCharges",
    numeric: false,
    disablePadding: false,
    label: "Total Charges",
    width: "auto",
  },
  {
    id: 16,
    code: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    width: "auto",
  },
];

const headCells1 = [
  {
    id: 1,
    code: "pgsDocketNumber",
    numeric: false,
    disablePadding: true,
    label: "PGS Docket No",
    width: "auto",
  },
  {
    id: 2,
    code: "edsDocketNumber",
    numeric: false,
    disablePadding: false,
    label: "EDS Docket No",
    width: "auto",
  },
  {
    id: 3,
    code: "accountNumber",
    numeric: false,
    disablePadding: false,
    label: "Account No",
    width: "auto",
  },
  {
    id: 4,
    code: "estimatedDate",
    numeric: false,
    disablePadding: false,
    label: "Estd Lodgement Date",
    width: "auto",
  },
  {
    id: 5,
    code: "submittedDate",
    numeric: false,
    disablePadding: false,
    label: "Submitted Date",
    width: "auto",
  },
  {
    id: 6,
    code: "service",
    numeric: false,
    disablePadding: false,
    label: "Service",
    width: "auto",
  },
  {
    id: 7,
    code: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    width: "auto",
  },
  {
    id: 8,
    code: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    width: "auto",
  },
  {
    id: 9,
    code: "edsExceptionMessage",
    numeric: false,
    disablePadding: false,
    label: "Exception",
    width: "auto",
  },
  {
    id: 10,
    code: "modifiedDate",
    numeric: false,
    disablePadding: false,
    label: "Modified Date",
    width: "auto",
  },
  {
    id: 11,
    code: "modifiedBy",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
    width: "auto",
  },
  {
    id: 12,
    code: "createdDate",
    numeric: false,
    disablePadding: false,
    label: "Created Date",
    width: "auto",
  },
  {
    id: 13,
    code: "createdBy",
    numeric: false,
    disablePadding: false,
    label: "Created By",
    width: "auto",
  },
  {
    id: 14,
    code: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    width: "auto",
  },
];

function createData(
  editClicked,
  validRecord,
  runMailListID,
  fileName,
  zone,
  country,
  province,
  copies,
  quantity,
  totalOrderQuantity,
  remarks,
  routing,
  pgsDocketNumber,
  cop,
  isBM1500Countries,
  mailSize,
  noOfBags,
  weightPerBag,
  weightPerItem,
  noOfArticles,
  totalChargeableWeight,
  postageCharges,
  totalCharges,
  isLocal,
  docketHeaderID,
  isActive
) {
  return {
    editClicked,
    validRecord,
    runMailListID,
    fileName,
    zone,
    country,

    province,
    copies,
    quantity,
    totalOrderQuantity,
    remarks,
    routing,
    pgsDocketNumber,
    cop,
    isBM1500Countries,
    mailSize,
    noOfBags,
    weightPerBag,
    weightPerItem,
    noOfArticles,
    totalChargeableWeight,
    postageCharges,
    totalCharges,
    isLocal,
    docketHeaderID,
    isActive,
  };
}

function createDocket(
  docketHeaderID,
  customerName,
  workOrderNumber,
  projectTitle,
  jobGroupNumber,
  planner,
  pgsDocketNO,
  eDocketNO,
  pgsDocketNo,
  edsDocketNo,
  accountNo,
  estimatedLodgementDate,
  submittedDate,
  serviceType,
  type,
  isManual,
  statusName,
  edsExceptionMessage,
  statusId,
  modifiedByName,
  modifiedDate,
  createdByName,
  createdDate
) {
  return {
    docketHeaderID,
    customerName,
    workOrderNumber,
    projectTitle,
    jobGroupNumber,
    planner,
    pgsDocketNO,
    eDocketNO,
    pgsDocketNo,
    edsDocketNo,
    accountNo,
    estimatedLodgementDate,
    submittedDate,
    serviceType,
    type,
    isManual,
    statusName,
    edsExceptionMessage,
    statusId,
    modifiedByName,
    modifiedDate,
    createdByName,
    createdDate,
  };
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "31%",
    fontSize: "11px !important",
  },
  autocompleteText: {
    marginRight: theme.spacing(2),
    width: "100%",
    fontSize: "11px !important",
  },
  autocomplete: {
    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
});

const getColor = (status) => {
  switch (status && status.trim()) {
    case "In Progress":
      return "#03A6FD";
    case "EDS Error":
      return "gray";
    case "Open":
      return "#ECB507";
    case "E Docket Generated":
      return "#16831F";
    case "Manual Docket Generated":
      return "#16831F";
    case "Lodged":
      return "#16831F";
    case "Cancelled":
      return "#AD2219";
    default:
      return "#03A6FD";
  }
};

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell"></TableCell>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            //sortDirection={orderBy === headCell.id ? order : false}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderLeft: "1px sold #000", width: `${headCell.width}` }}
          >
            <TableSortLabel
              //active={orderBy === headCell.code}
              //direction={order}
              disabled
            // onClick={createSortHandler(headCell.code)}
            >
              <span style={{ color: "#306dca" }}>{headCell.label}</span>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableHead1(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell"></TableCell>
        {headCells1.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCell.length - 1 ? "headcells" : "headcell"}
            style={{ borderLeft: "1px sold #000", width: `${headCell.width}` }}
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
            {/* <TableSortLabel
              active={orderBy === headCell.code}
              direction={order}
              onClick={createSortHandler(headCell.code)}
            >
              
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    setOpen(false);
    props.handleClickButton("", "reset");
  };

  let checkValue = props.resetList.find(
    (o) => o.runMailListID === props.selectedRunMailListId
  );
  // console.log(checkValue);
  // console.log(checkValue && checkValue.mailSize, "checkValue");

  return (
    <div>
      <div
        style={{
          display: "flex",
          //   width: "100%",
          border: "1px solid #eaeaea",
          borderBottom: "none",
          borderRadius: "4px",
        }}
      >
        {props.loading || props.disableAdd || props.mode === "view" ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={(e) => props.handleClickButton(e, "add")}
          >
            <CustomSmallAddIcon style={{ fontSize: "18px" }} />
            {props.selected.length === 1 ? "Clone" : "Add"}
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              onClick={(e) => props.handleClickButton(e, "add")}
            >
              <CustomSmallAddIcon style={{ fontSize: "18px" }} />
              {props.selected.length === 1 ? "Clone" : "Add"}
            </Button>
          )}

        {/* <Button
          disabled={props.editableRunMailListDetailList === props.resetList}
          color="primary"
          variant="filled"
          style={
            !(props.editableRunMailListDetailList === props.resetList)
              ? {
                  color: "#fff",
                  background: "#3982ea",
                  padding: "2px",
                  margin: ".5em",
                  fontSize: "11px"
                }
              : { color: "#3982ea", fontSize: "11px" }
          }
          onClick={handleClickOpen}
        >
          <SyncIcon style={{ fontSize: "18px" }} /> Reset
        </Button> */}
        {/* {console.log(props.resetList, "props.selectedRunMailListId")} */}
        {/* {checkValue &&
        // checkValue.country.text === "Singapore" &&
        createDoc.length > 0 ? (
          <Button
            disabled={
              !(
                props.numSelected > 0 &&
                props.validSelection &&
                checkValue &&
                checkValue.mailSize != null
              )
            }
            color="primary"
            variant="filled"
            style={
              props.numSelected > 0 &&
              props.validSelection &&
              checkValue &&
              checkValue.mailSize != null
                ? {
                    color: "#fff",
                    background: "#3982ea",
                    padding: "2px",
                    margin: ".5em",
                    fontSize: "11px"
                  }
                : { color: "#3982ea", fontSize: "11px" }
            }
            onClick={e => props.handleClickButton(e, "createEDocket")}
          >
            <NoteAddIcon style={{ fontSize: "18px" }} /> Create E-Docket
          </Button>
        ) : ( */}
        {!(props.numSelected > 0 && props.validSelection && !props.showText) ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={(e) => props.handleClickButton(e, "createEDocket")}
          >
            <NoteAddIcon style={{ fontSize: "18px" }} /> Create E-Docket
          </Button>
        ) : (
            <Link
              style={{
                textDecoration: "none",
              }}
              to={{
                state: { workOrderMode: props.mode },
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  boxShadow: "none",
                  padding: "2px",
                  margin: ".5em",
                  fontSize: "11px",
                }}
                onClick={(e) => props.handleClickButton(e, "createEDocket")}
              >
                <NoteAddIcon style={{ fontSize: "18px" }} /> Create E-Docket
            </Button>
            </Link>
          )}

        {!(props.numSelected > 0 && props.validSelection && !props.showText) ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={(e) => props.handleClickButton(e, "createManualDocket")}
          >
            <NoteAddIcon style={{ fontSize: "18px" }} /> Create Manual Docket
          </Button>
        ) : (
            <Link
              style={{
                textDecoration: "none",
              }}
              to={{
                state: { workOrderMode: props.mode },
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  boxShadow: "none",
                  padding: "2px",
                  margin: ".5em",
                  fontSize: "11px",
                }}
                onClick={(e) => props.handleClickButton(e, "createManualDocket")}
              >
                <NoteAddIcon style={{ fontSize: "18px" }} /> Create Manual Docket
            </Button>
            </Link>
          )}

        {props.showText ? (
          <div
            style={{
              marginLeft: "1%",
              marginRight: "1%",
              marginTop: "1%",
              color: "red",
              width: "30%",
              fontSize: "small",
            }}
          >
            * Please select Mail Size for Singapore Location
          </div>
        ) : null}
        <div>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {'Are you sure you want to "Reset"?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div style={{ textAlign: "center" }}>
                  This will remove all actions immediately,
                  <br />
                  You can't undo this action!
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={handleClose}
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
              >
                No
              </Button>
              <Button
                onClick={handleAccept}
                variant="contained"
                color="primary"
                style={{ boxShadow: "none", marginLeft: "1em" }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const EnhancedTableToolbar2 = (props) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          border: "1px solid #eaeaea",
          borderBottom: "none",
          borderRadius: "4px",
        }}
      >
        <div style={{ fontWeight: "bolder", padding: "4px" }}>Docket Info</div>
        {props.numSelected === 1 && (
          <div>
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              onClick={props.handleReportClick}
            >
              Docket Posting Report
            </Button>
          </div>
        )}
        {!(props.numSelected > 0 && props.isGen) ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={(e) => props.generateDocketClick(e, "generateEDocket")}
          // style={
          //   props.numSelected > 0 && !props.isManual
          //     ? // && !props.isMan
          //       {
          //         color: "#fff",
          //         background: "#3982ea",
          //       }
          //     : { color: "#3982ea", fontSize: "11px" }
          // }
          >
            {" "}
            Generate E-Docket
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              onClick={(e) => props.generateDocketClick(e, "generateEDocket")}
            // style={
            //   props.numSelected > 0 && !props.isManual
            //     ? // && !props.isMan
            //       {
            //         color: "#fff",
            //         background: "#3982ea",
            //       }
            //     : { color: "#3982ea", fontSize: "11px" }
            // }
            >
              {" "}
            Generate E-Docket
            </Button>
          )}

        {/* {!(props.numSelected > 0 && props.isMan) ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={(e) =>
              props.generateDocketClick(e, "generateManualDocketReport")
            }
          >
            {" "}
            Generate Manual Docket Report
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={(e) =>
              props.generateDocketClick(e, "generateManualDocketReport")
            }
          >
            {" "}
            Generate Manual Docket Report
          </Button>
        )} */}
      </div>
    </div>
  );
};

EnhancedTableToolbar2.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

class AddRunMailList extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      disableAdd: false,
      selectedRow: {},
      showPopupInternational: false,
      showPopupLocal: false,
      selectedEditIndex: "",
      order: "",
      orderBy: "",
      confirmDelete: false,
      isManualDocketRecord: false,
      isdeleteRunMailList: false,
      workOrder: {
        jobGroupID: "",
        customerName: "",
        workOrderNumber: "",
        projectTitle: "",
        jobGroupNumber: "",
        customerID: "",
        workOrderID: "",
      },
      deleteRunMailListValues: {
        index: 0,
        id: 0,
      },
      deleteDocketListValues: {
        index: 0,
        id: 0,
      },
      createDocket: {},
      formValid: false,
      submitSuccess: false,
      localDocket: false,
      //localDocket2: false,
      manualDocket: false,
      runMailListValid: true,
      validSelection: false,
      //validDocketSelection: false,
      mailSizeRequired: false,
      isMan: false,
      isGen: false,
      loading: false,
      loading2: false,
      errors: {},
      selected: [],
      selected2: [],
      docselected: false,
      selectedDocket: [],
      localDocketList: [],
      internationalDocketList: [],
      selectedRML: [],
      fileNameList: [],
      zoneList: [],
      countryList: [],
      provinceList: [],
      mailSizeList: [],
      runMailListDetailList: [],
      editableRunMailListDetailList: [],
      resetList: [],
      docketDetailList: [],
      editableDocketDetailList: [],
      jobGroupList: [],
      isZoneOrCountrySelected: "",
      zoneValue: null,
      countryValue: null,
      showText: false,
      edsErrorData: "",
      openEdsErrorModal: false
    };
  }

  componentDidMount() {
    console.log(this.props);
    const dispatch = this.context[1];
    this.props.handleDrawerClose();
    this.props.handleSettingHeader("Run Mail List");
    this.props.setSelectedList("genericSystemOpen", "runMailList");
    let jobGroupID = this.props.match.params.jobGroupId;
    this.setState({ disableEdit: true, loading: true, loading2: true });
    getRunMailList(jobGroupID).then((r) => {
      dispatch({
        type: "getRunMailList",
        newStore: r.data,
      });
      this.getAllRunMailDetails(r.data);
    });
    GetAllDocketsByJGID(jobGroupID).then((r) => {
      dispatch({
        type: "GetAllDocketsByJGID",
        newStore: r.data,
      });
      this.getAllDocketDetails(r.data);
    });
    getFileNamesDropDownWithID(jobGroupID).then((r) => {
      dispatch({
        type: "getFileNamesDropDown",
        newStore: r.data,
      });
      if (r.data) this.getAllFileNames(r.data);
    });
    getZoneDropDownList().then((r) => {
      dispatch({
        type: "getZoneDropDownList",
        newStore: r.data,
      });
      this.getAllZones(r.data);
    });
    getCountryDropdownList().then((r) => {
      dispatch({
        type: "getCountryDropdownList",
        newStore: r.data,
      });
      this.getAllCountries(r.data);
    });
    getProvinceDropDownList().then((r) => {
      dispatch({
        type: "getProvinceDropDownList",
        newStore: r.data,
      });
      this.getAllProvince(r.data);
    });
    getMailSizeList().then((r) => {
      dispatch({
        type: "getMailSizeList",
        newStore: r.data,
      });
      this.getAllMailSize(r.data);
    });
    getJobGroupbyJobGroupId(jobGroupID).then((r) => {
      dispatch({
        type: "getJobGroupbyJobGroupId",
        newStore: r,
      });
      this.getWorkOrderInfo(r);
    });
    dispatch({
      type: "getSelectedDocketId",
      newStore: "0",
    });
  }

  getWorkOrderInfo = (info) => {
    const { workOrder } = this.state;
    workOrder.customerName = info.customerName;
    workOrder.jobGroupID = info.jobGroupID;
    workOrder.workOrderNumber = info.workOrderNumber;
    workOrder.jobGroupNumber = info.jobGroupNumber;
    workOrder.projectTitle = info.projectTitle;
    workOrder.customerID = info.customerID;
    workOrder.workOrderID = info.workOrderID;
    this.setState({ workOrder });
  };

  getAllRunMailDetails = (list) => {
    let runMailListDetailList = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return d.isActive
          ? runMailListDetailList.push(
            createData(
              false,
              true,
              d.runMailListID,
              d.fileName,
              d.zoneList && d.zoneList[0],
              d.countryList && d.countryList[0],
              d.provinceList.length > 0 ? d.provinceList[0] : null,
              d.copies,
              d.quantity,
              d.copies * d.quantity,
              d.remarks,
              d.rounting,
              d.pgsDocketNo,
              d.isCOP,
              d.isBM1500Countries,
              d.mailsizeList.length > 0 ? d.mailsizeList[0] : null,
              d.noOfBags,
              d.weightPerBag && this.addZeroes(d.weightPerBag.toString(), 3),
              d.weightPerItem,
              d.noOfArticles,
              d.totalChargeableWeight &&
              this.addZeroes(d.totalChargeableWeight.toString(), 3),
              d.postageRate && this.addZeroes(d.postageRate.toString(), 3),
              d.totalCharges && this.addZeroes(d.totalCharges.toString(), 3),
              d.isLocal,
              d.docketHeaderID,
              d.isActive
            )
          )
          : d;
      });
    this.setState({
      runMailListDetailList: runMailListDetailList,
      editableRunMailListDetailList: runMailListDetailList,
      resetList: runMailListDetailList,
      loading: false,
    });
  };

  getAllDocketDetails = (list) => {
    //console.log(list);
    let docketDetailList = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        //console.log(d, "d i n docket");
        return docketDetailList.push(
          createDocket(
            d.docketHeaderID,
            d.customerName,
            d.workOrderNumber,
            d.projectTitle,
            d.jobGroupNumber,
            d.planner,
            d.pgsDocketNO,
            d.eDocketNO,
            d.pgsDocketNo,
            d.edsDocketNo,
            d.accountNo,
            d.estimatedLodgementDate,
            d.submittedDate,
            d.serviceType,
            d.isLocal ? "Local" : "International",
            d.isManual,
            d.genStatusName,
            d.edsExceptionMessage,
            d.statusId,
            d.modifiedByName,
            d.modifiedDate,
            d.createdByName,
            d.createdDate
          )
        );
      });
    this.setState({
      docketDetailList: docketDetailList,
      editableDocketDetailList: docketDetailList,
      loading2: false,
    });
  };

  getAllFileNames = (list) => {
    let fileNameList = [];
    fileNameList = list && list;
    this.setState({
      fileNameList:
        fileNameList &&
        fileNameList.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };

  getAllZones = (list) => {
    let zoneList = [];
    zoneList = list && list;
    this.setState(
      {
        zoneList:
          zoneList &&
          zoneList.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      }
      // () => {
      //   let zoneList = [...this.state.zoneList];
      //   zoneList.unshift({
      //     text: "None",
      //     id: null
      //   });
      //   this.setState({ zoneList: zoneList });
      // }
    );
  };

  getAllCountries = (list) => {
    let countryList = [];
    countryList = list && list;
    this.setState(
      {
        countryList:
          countryList &&
          countryList.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      }
      // () => {
      //   let countryList = [...this.state.countryList];
      //   countryList.unshift({
      //     text: "None",
      //     id: null
      //   });
      //   this.setState({ countryList: countryList });
      // }
    );
  };

  getAllProvince = (list) => {
    let provinceList = [];
    provinceList = list && list;
    this.setState({
      provinceList:
        provinceList &&
        provinceList.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };

  getAllMailSize = (list) => {
    let mailSizeList = [];
    mailSizeList = list && list;
    this.setState({
      mailSizeList:
        mailSizeList &&
        mailSizeList.sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
        ),
    });
  };

  _onKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  handleClickButton = (e, name) => {
    let newList = [...this.state.editableRunMailListDetailList];

    const { selected } = this.state;
    let jobGroupID = this.props.match.params.jobGroupId;
    newList.push({});
    if (name === "add") {
      if (selected.length === 1) {
        newList[newList.length - 1] = clone(
          newList.filter((r) => r.runMailListID === selected[0])[0]
        );
        newList[newList.length - 1].runMailListID = 0;
        newList[newList.length - 1].editClicked = true;
      } else {
        newList[newList.length - 1].editClicked = true;
        newList[newList.length - 1].runMailListID = 0;
        newList[newList.length - 1].fileName = "";
        newList[newList.length - 1].zone = "";
        newList[newList.length - 1].country = "";
        newList[newList.length - 1].province = "";
        newList[newList.length - 1].copies = "";
        newList[newList.length - 1].quantity = "";
        newList[newList.length - 1].totalOrderQuantity = 0;
        newList[newList.length - 1].remarks = "";
        newList[newList.length - 1].routing = "";
        newList[newList.length - 1].pgsDocketNumber = "";
        newList[newList.length - 1].cop = false;
        newList[newList.length - 1].isBM1500Countries = false;
        newList[newList.length - 1].mailSize = null;
        newList[newList.length - 1].noOfBags = "";
        newList[newList.length - 1].weightPerBag = "";
        newList[newList.length - 1].weightPerItem = "";
        newList[newList.length - 1].noOfArticles = "";
        newList[newList.length - 1].totalChargeableWeight = "";
        newList[newList.length - 1].postageCharges = "";
        newList[newList.length - 1].totalCharges = "";
        newList[newList.length - 1].isLocal = false;
        newList[newList.length - 1].docketHeaderID = "";
        newList[newList.length - 1].isActive = "";
        newList[newList.length - 1].createdBy = localStorage.loggedInId;
      }
      this.setState(
        {
          //runMailListDetailList: newList,
          editableRunMailListDetailList: newList,
          disableAdd: true,
          disableEdit: false,
          selectedEditIndex: newList.length - 1,
        },
        () => {
          document.getElementById(`tableRow${newList.length - 1}`).focus();
        }
      );
    } else if (name === "reset") {
      this.setState({
        runMailListDetailList: this.state.resetList,
        editableRunMailListDetailList: this.state.resetList,
        disableAdd: false,
        disableEdit: true,
      });
    } else if (name === "createManualDocket") {
      let valid = false;
      let [{ store }, dispatch] = this.context;
      const { runMailListDetailList, selected } = this.state;
      let filteredList = runMailListDetailList.filter(
        (r) => selected.indexOf(r.runMailListID) >= 0
      );
      valid = filteredList.every(
        (r) =>
          r.postageCharges !== "" &&
          r.totalCharges !== "" &&
          r.postageCharges !== 0 &&
          r.totalCharges !== 0
      );
      if (valid) {
        let values = store.selectedDocketIds;
        values = values && values.concat("-", "true");
        dispatch({
          type: "getSelectedDocketId",
          newStore: values,
        });
        this.props.history.push("/add_docket/" + jobGroupID);
      } else {
        this.setState({ isManualDocketRecord: true });
      }
    } else if (name === "createEDocket") {
      let [{ store }, dispatch] = this.context;
      let values = store.selectedDocketIds;
      values = values && values.concat("-", "false");
      dispatch({
        type: "getSelectedDocketId",
        newStore: values,
      });
      this.props.history.push("/add_docket/" + jobGroupID);
    }
  };

  generateDocketClick = (event, name) => {
    if (name === "generateEDocket") {
      let jobGroupID = this.props.match.params.jobGroupId;
      const dispatch = this.context[1];
      const { localDocketList, internationalDocketList } = this.state;
      if (localDocketList.length > 0) {
        this.setState({ loading: true });
        let ids = localDocketList.join(",");
        generateEDocket(ids).then((r) => {
          GetAllDocketsByJGID(jobGroupID)
            .then((r) => {
              dispatch({
                type: "GetAllDocketsByJGID",
                newStore: r.data,
              });
              this.getAllDocketDetails(r.data);
              this.setState({ loading: false });
            })
            .catch((error) => {
              this.setState({ loading: false });
            });
        });
      }
      if (internationalDocketList.length > 0) {
        this.setState({ loading: true });
        let ids = internationalDocketList.join(",");
        generateEDocketInternational(ids).then((r) => {
          GetAllDocketsByJGID(jobGroupID)
            .then((r) => {
              dispatch({
                type: "GetAllDocketsByJGID",
                newStore: r.data,
              });
              this.setState({ loading: false });
              this.getAllDocketDetails(r.data);
            })
            .catch((error) => {
              this.setState({ loading: false });
            });
        });
      }
    } else {
      //TODO: manual Docket report
    }
  };

  onEditClicked = (e, i) => {
    this.setState({ selectedEditIndex: i });
    let newList = clone(this.state.editableRunMailListDetailList);
    let mailSizeRequired = null;
    let showText = null;
    if (newList[i].country && newList[i].country.value.toLowerCase() === "sg") {
      mailSizeRequired = true;
      showText = true;
    } else {
      mailSizeRequired = false;
      showText = false;
    }

    newList[i].editClicked = true;
    this.setState({
      editableRunMailListDetailList: newList,
      disableAdd: true,
      isZoneOrCountrySelected: "",
      mailSizeRequired: mailSizeRequired,
      showText: showText,
    });
  };

  onDocketEditClicked = (e, i, docketHeaderID) => {
    this.props.history.push({
      pathname: "/update_docket/" + docketHeaderID,
      state: { workOrderMode: this.props.location.state.workOrderMode },
    });
  };

  deleteRunMailListConfirm = (e, i, id, res) => {
    if (res === "Yes") {
      if (this.state.runMailListDetailList.length === 1) {
        this.setState({ disableAdd: false });
      }
      let modifiedBy = localStorage.loggedInId;
      const dispatch = this.context[1];
      deleteRunMailList(modifiedBy, id)
        .then((r) => {
          if (r === true) {
            let jobGroupID = this.props.match.params.jobGroupId;
            this.setState({ disableEdit: true, loading: true });
            getRunMailList(jobGroupID).then((r) => {
              dispatch({
                type: "getRunMailList",
                newStore: r.data,
              });
              this.getAllRunMailDetails(r.data);
            });
          }
          this.setState({ isdeleteRunMailList: false });
        })
        .catch(this.setState({ isdeleteRunMailList: false }));
    } else {
      this.setState({ isdeleteRunMailList: false });
    }
  };

  deleteRunMailListClicked = (e, i, row) => {
    const { deleteRunMailListValues } = this.state;
    deleteRunMailListValues.index = i;
    deleteRunMailListValues.id = row.runMailListID;
    this.setState({
      isdeleteRunMailList: true,
      deleteRunMailListValues: deleteRunMailListValues,
    });
  };

  onDeleteDocketClicked = (e, i, row) => {
    const { deleteDocketListValues } = this.state;
    deleteDocketListValues.index = i;
    deleteDocketListValues.id = row.docketHeaderID;
    this.setState({
      confirmDelete: true,
      deleteDocketListValues: deleteDocketListValues,
    });
  };

  onDeleteDocket = (e, i, id, res) => {
    if (res === "Yes") {
      let modifiedBy = localStorage.loggedInId;
      const dispatch = this.context[1];
      deleteDocket(modifiedBy, id.toString())
        .then((r) => {
          if (r === true) {
            let jobGroupID = this.props.match.params.jobGroupId;
            this.setState({ disableEdit: true, loading: true, loading2: true });
            getRunMailList(jobGroupID).then((r) => {
              dispatch({
                type: "getRunMailList",
                newStore: r.data,
              });
              this.getAllRunMailDetails(r.data);
            });
            GetAllDocketsByJGID(jobGroupID).then((r) => {
              dispatch({
                type: "GetAllDocketsByJGID",
                newStore: r.data,
              });
              this.getAllDocketDetails(r.data);
            });
          }
          this.setState({ confirmDelete: false });
        })
        .catch(this.setState({ confirmDelete: false }));
    } else {
      this.setState({ confirmDelete: false });
    }
    this.setState({ confirmDelete: false });
  };

  continueCreateDocket = () => {
    this.setState({ isManualDocketRecord: false });
  };

  onDoneClicked = (e, i, record) => {
    let newList = [...this.state.editableRunMailListDetailList];
    newList[i] = record;
    newList[i].editClicked = false;
    // Commented for new changes in Total chargeable weight Formula
    // if (
    //   newList[i].totalChargeableWeight === "" ||
    //   newList[i].totalChargeableWeight === null
    // ) {
    //   newList[i].totalChargeableWeight =
    //     newList[i].noOfArticles &&
    //     newList[i].weightPerItem &&
    //     (parseInt(newList[i].noOfArticles) *
    //       parseInt(newList[i].weightPerItem)) /
    //     1000;
    // }
    if (newList[i].totalChargeableWeight !== 0 || newList[i].totalChargeableWeight !== "0" || newList[i].totalChargeableWeight)
      newList[i].totalChargeableWeight = this.addZeroes(
        newList[i].totalChargeableWeight.toString(),
        3
      );

    let runMailList = newList[i];
    const { workOrder } = this.state;
    let jobOrderGroupID = workOrder.jobGroupID;
    let filename = runMailList.fileName && runMailList.fileName;
    let zoneID = runMailList.zone && runMailList.zone.id;
    // let zoneID = null;
    let countryID = runMailList.country && runMailList.country.id;
    let provinceID = !runMailList.province
      ? null
      : runMailList.province && runMailList.province.id;
    let mailSize = !runMailList.mailSize
      ? null
      : runMailList.mailSize && runMailList.mailSize.id;
    let copies = runMailList.copies;
    let quantity = runMailList.noOfArticles;
    let isCOP = runMailList.cop;
    let weightPerItem = runMailList.weightPerItem;
    let noOfArticles = runMailList.noOfArticles;
    let totalChargeableWeight = runMailList.totalChargeableWeight;
    let noOfBags = runMailList.noOfBags;
    let weightPerBag = runMailList.weightPerBag;
    let isLocal =
      runMailList.country && runMailList.country.value.toLowerCase() === "sg"
        ? true
        : false;
    let postageRate = runMailList.postageCharges;
    let totalCharges = runMailList.totalCharges;
    let remarks = runMailList.remarks;
    let docketHeaderID = runMailList.docketHeaderID;
    let routing = runMailList.routing;
    let modifiedBy = localStorage.loggedInId;
    let createdBy = localStorage.loggedInId;
    let isActive = true;
    let isBM1500Countries = runMailList.isBM1500Countries;

    if (parseInt(runMailList.runMailListID) === 0) {
      let reqBody = {
        filename,
        jobOrderGroupID,
        zoneID,
        countryID,
        provinceID,
        isCOP,
        mailSize,
        weightPerItem,
        noOfArticles,
        totalChargeableWeight,
        noOfBags,
        weightPerBag,
        isLocal,
        postageRate,
        totalCharges,
        remarks,
        docketHeaderID,
        createdBy,
        routing,
        isBM1500Countries,
        copies,
        quantity,
      };
      createRunMailList(reqBody).then((r) => {
        let jobGroupID = this.props.match.params.jobGroupId;
        const dispatch = this.context[1];

        // if (r === true) {
        // console.log(r, "response");
        this.setState({ loading: true });

        // window.location.reload();
        getRunMailList(jobGroupID).then((r) => {
          dispatch({
            type: "getRunMailList",
            newStore: r.data,
          });
          this.getAllRunMailDetails(r.data);
        });
        // }
      });
    } else {
      let reqBody = {
        filename,
        jobOrderGroupID,
        zoneID,
        countryID,
        provinceID,
        isCOP,
        mailSize,
        weightPerItem,
        noOfArticles,
        totalChargeableWeight,
        noOfBags,
        weightPerBag,
        isLocal,
        postageRate,
        totalCharges,
        remarks,
        docketHeaderID,
        modifiedBy,
        isActive,
        routing,
        isBM1500Countries,
        copies,
        quantity,
      };
      updateRunMailList(reqBody, runMailList.runMailListID).then((r) => {
        let jobGroupID = this.props.match.params.jobGroupId;
        const dispatch = this.context[1];

        // if (r === true) {
        // console.log(r, "response");
        this.setState({ loading: true });

        // window.location.reload();
        getRunMailList(jobGroupID).then((r) => {
          dispatch({
            type: "getRunMailList",
            newStore: r.data,
          });
          this.getAllRunMailDetails(r.data);
        });
        if (r === false) {
          //
        }
      });
    }

    this.setState({
      editableRunMailListDetailList: newList,
      runMailListDetailList: newList,
      resetList: newList,
      disableAdd: false,
      isZoneOrCountrySelected: "",
      showText: false,
    });
  };

  DetailsValid = (List) => {
    // console.log(List);
    List.map((element) => {
      return (element.validRecord =
        element.country && element.country.value && element.country.value.toLowerCase() === 'sg'
          ?
          //  element.fileName !== "" &&
          // element.zone !== "" &&
          element.country !== null &&
          element.copies !== "" &&
          element.mailSize !== null &&
          element.copies !== 0 &&
          // element.quantity !== "" &&
          // element.quantity !== 0 &&
          element.weightPerItem !== "" &&
          // element.quantity !== 0 &&
          element.noOfArticles !== "" &&
          element.noOfArticles !== 0 &&
          element.totalChargeableWeight !== "" &&
          element.totalChargeableWeight !== 0
          :
          //  element.fileName !== "" &&
          // element.zone !== "" &&
          element.country !== null &&
          element.copies !== "" &&
          element.copies !== 0 &&
          // element.quantity !== "" &&
          // element.quantity !== 0 &&
          element.weightPerItem !== "" &&
          // element.quantity !== 0 &&
          element.noOfArticles !== "" &&
          element.noOfArticles !== 0 &&
          element.totalChargeableWeight !== "" &&
          element.totalChargeableWeight !== 0);
    });
    this.setState({ editableRunMailListDetailList: List });
  };

  onCancelClicked = (e, i, record) => {
    let newList = clone(this.state.editableRunMailListDetailList);
    let newList2 = clone(this.state.runMailListDetailList);

    newList = newList2;
    this.setState({
      editableRunMailListDetailList: newList,
      disableAdd: false,
      showText: false,
      // isZoneOrCountrySelected: ""
    });
  };

  onCancelClick = () => {
    const { workOrder } = this.state;
    let workOrderID = workOrder.workOrderID;
    let jobGroupID = workOrder.jobGroupID;
    this.props.history.push({
      pathname: "/jobGroup",
      search: "?workOrderID=" + workOrderID + "&jobGroupID=" + jobGroupID,
      state: { workOrderMode: this.props.location.state.workOrderMode },
    });
  };

  onBlur = (event, index) => {
    const { editableRunMailListDetailList } = this.state;
    let values = event.target.value;
    values = this.addZeroes(values.toString(), 3);
    editableRunMailListDetailList[index][event.target.name] = values;
    this.setState({ editableRunMailListDetailList });
  };

  addZeroes(num, decimalPlaces) {
    var value = Number(num);
    var res = num.split(".");
    if (res.length === 1 || res[1].length < decimalPlaces) {
      value = value.toFixed(decimalPlaces);
    }
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  handleRowChange = (event, index) => {
    let newEditableList = clone(this.state.editableRunMailListDetailList);
    newEditableList[index][event.target.name] = event.target.value;
    if (event.target.name === "copies" || event.target.name === "quantity") {
      if (
        parseInt(newEditableList[index]["copies"]) > 0 &&
        parseInt(newEditableList[index]["quantity"]) > 0
      ) {
        newEditableList[index]["totalOrderQuantity"] =
          parseInt(newEditableList[index]["copies"]) *
          parseInt(newEditableList[index]["quantity"]);
      } else {
        newEditableList[index]["totalOrderQuantity"] = 0;
      }
    }
    // Commented for new changes in Total chargeable weight Formula
    // if (
    //   event.target.name === "weightPerItem" ||
    //   event.target.name === "noOfArticles"
    // ) {
    //   if (
    //     parseInt(newEditableList[index]["weightPerItem"]) > 0 &&
    //     parseInt(newEditableList[index]["noOfArticles"]) > 0
    //   ) {
    //     newEditableList[index]["totalChargeableWeight"] =
    //       (parseInt(newEditableList[index]["weightPerItem"]) *
    //         parseInt(newEditableList[index]["noOfArticles"])) /
    //       1000;
    //   } else {
    //     newEditableList[index]["totalChargeableWeight"] = 0;
    //   }
    // }
    // if (event.target.name === "totalChargeableWeight") {
    //   if (
    //     parseInt(newEditableList[index]["totalChargeableWeight"]) > 0 &&
    //     parseInt(newEditableList[index]["noOfArticles"]) > 0
    //   ) {
    //     newEditableList[index]["weightPerItem"] =
    //       (parseInt(newEditableList[index]["totalChargeableWeight"]) * 1000) /
    //       parseInt(newEditableList[index]["noOfArticles"]);
    //   } else {
    //     newEditableList[index]["weightPerItem"] = 0;
    //   }
    // }
    this.setState({ editableRunMailListDetailList: newEditableList });
    if (event.target.name === "totalChargeableWeight") {
      // if (event.target.value == 0 || !event.target.value)
      //   newEditableList[index].totalChargeableWeight = 0
    }
    this.DetailsValid(newEditableList);
  };

  handleReportClick = () => {
    if (this.state.selectedRow.length > 0) {
      if (this.state.selectedRow[0].type === "International") {
        this.setState({
          showPopupInternational: true,
        });
      } else {
        this.setState({
          showPopupLocal: true,
        });
      }
    }
  };

  handleHidePopup = () => {
    this.setState({
      showPopupLocal: false,
      showPopupInternational: false,
    });
  };

  handleSwitchChange = (event, index) => {
    let newEditableList = clone(this.state.editableRunMailListDetailList);
    newEditableList[index][event.target.name] = event.target.checked;
    this.setState({ editableRunMailListDetailList: newEditableList });
  };

  handleDDLChangeUpdated = (event, value) => {
    event.persist();

    let index = "";
    index = this.state.selectedEditIndex;
    console.log(event);
    let name = event.target.id.split("-")[0];
    let newEditableList = clone(this.state.editableRunMailListDetailList);
    let mailSizeRequired = this.state.mailSizeRequired;

    newEditableList[index][name] = value;
    if (event.target.localName === "svg") {
      newEditableList[index]["zone"] = "";
      newEditableList[index]["country"] = "";
    }

    if (name == "zone") {
      if (value) {
        this.setState({
          zoneValue: value.id,
        });
      } else {
        this.setState({
          zoneValue: "",
        });
      }
    }

    if (name == "country") {
      if (value !== null && value.value !== "SG")
        newEditableList[index]["mailSize"] = null;

      if (value) {
        this.setState({
          countryValue: value.id,
        });
      } else {
        this.setState({
          countryValue: "",
        });
      }
    }

    // if (this.state.countryValue) {
    //   document
    //     .getElementById("zoneCell" + index)
    //     .setAttribute("style", "pointer-events: none");
    //   newEditableList[index].zone = "";
    // } else {
    //   document
    //     .getElementById("zoneCell" + index)
    //     .setAttribute("style", "pointer-events: auto");
    // }
    // if (this.state.zoneValue) {
    //   document
    //     .getElementById("countryCell" + index)
    //     .setAttribute("style", "pointer-events: none");
    //   newEditableList[index].country = "";
    // } else {
    //   document
    //     .getElementById("countryCell" + index)
    //     .setAttribute("style", "pointer-events: auto");
    // }
    let showText = null;
    if (
      newEditableList[index].country &&
      newEditableList[index].country.value.toLowerCase() === "sg"
    ) {
      mailSizeRequired = true;
      showText = true;
    } else {
      mailSizeRequired = false;
      showText = false;
    }
    this.setState({
      editableRunMailListDetailList: newEditableList,
      mailSizeRequired: mailSizeRequired,
      showText: showText,
    });
    this.DetailsValid(newEditableList);
  };

  // handleDDLChange = async (event, index, name, list) => {
  //   console.log("index, name : ", index, name);
  //   // mailSizeRequired;
  //   let newEditableList = clone(this.state.editableRunMailListDetailList);
  //   let mailSizeRequired = this.state.mailSizeRequired;
  //   if (event.target.id !== "") {
  //     let id = event.target.id.split("-")[3];
  //     newEditableList[index][name] = list[id];
  //   } else {
  //     newEditableList[index][name] = null;
  //   }

  //   if (name == "zone") {
  //     let currentZone = event.target.id.split("option-")[1];
  //     await this.setState({
  //       zoneValue: currentZone,
  //     });
  //   }

  //   if (name == "country") {
  //     let currentCountry = event.target.id.split("option-")[1];
  //     await this.setState({
  //       countryValue: currentCountry,
  //     });
  //   }

  //   if (this.state.countryValue) {
  //     document
  //       .getElementById("zoneCell" + index)
  //       .setAttribute("style", "pointer-events: none");
  //     newEditableList[index].zone = "";
  //   } else {
  //     document
  //       .getElementById("zoneCell" + index)
  //       .setAttribute("style", "pointer-events: auto");
  //   }
  //   if (this.state.zoneValue) {
  //     document
  //       .getElementById("countryCell" + index)
  //       .setAttribute("style", "pointer-events: none");
  //     newEditableList[index].country = "";
  //   } else {
  //     document
  //       .getElementById("countryCell" + index)
  //       .setAttribute("style", "pointer-events: auto");
  //   }
  //   let showText = null;
  //   if (
  //     newEditableList[index].country &&
  //     newEditableList[index].country.value.toLowerCase() === "sg"
  //   ) {
  //     mailSizeRequired = true;
  //     showText = true;
  //   } else {
  //     mailSizeRequired = false;
  //     showText = false;
  //   }
  //   this.setState({
  //     editableRunMailListDetailList: newEditableList,
  //     mailSizeRequired: mailSizeRequired,
  //     showText: showText,
  //   });
  //   this.DetailsValid(newEditableList);
  // };

  checkedStatusArray(arr, flag, id, checked) {
    let stateObj = { flag: flag, id: id };

    if (arr.length == 0) {
      arr.push(stateObj);

      if (flag) {
        return flag;
      } else {
        return !flag;
      }
    } else {
      if (checked) {
        arr.push(stateObj);
        // console.log(arr, flag);

        // for (let i = 0; i < arr.length; i++) {
        //   //console.log(docsel[i].flag);
        //  // flag = flag ^ arr[i].flag;
        //    if(flag){

        //    }
        //  }
        // if (!flag) {
        //   arr.push(stateObj);
        //   //console.log(!flag);
        //   return !flag;
        // }
        if (flag) {
          for (let i = 0; i < arr.length; i++) {
            if (!arr[i].flag) {
              return false;
            }
          }
        } else {
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].flag) {
              return false;
            }
          }
        }
        return true;
      } else {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].id == id) {
            arr.splice(i, 1);
          }
        }
        if (arr.length == 0) {
          return false;
        } else {
          flag = arr[0].flag;
          if (flag) {
            for (let i = 0; i < arr.length; i++) {
              if (!arr[i].flag) {
                return false;
              }
            }
          } else {
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].flag) {
                return false;
              }
            }
          }
          return true;
        }
      }
    }
  }
  handleClick(event, runMailListID, runMailList) {
    //console.log(runMailListID, runMailList.isLocal);
    // if(event.target.checked){

    // }
    let flag = runMailList.isLocal;
    let id = runMailListID;
    let stateObj = { flag: flag, id: id };
    if (docsel.length == 0) {
      docsel.push(stateObj);
    } else {
      if (event.target.checked) {
        //console.log(docsel);
        for (let i = 0; i < docsel.length; i++) {
          //console.log(docsel[i].flag);
          flag = flag ^ docsel[i].flag;
        }
        if (!flag) {
          docsel.push(stateObj);
          this.setState({
            validSelection: true,
          });
        }
      } else {
        for (let i = 0; i < docsel.length; i++) {
          if (docsel[i].id == runMailListID) {
            docsel.splice(i, 1);
          }
        }
      }
    }

    // console.log(runMailList, "runMailList");
    if (runMailList.runMailListID > 0) {
      const dispatch = this.context[1];
      let { selected, runMailListDetailList } = this.state;
      const selectedIndex = selected.indexOf(runMailListID);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, runMailListID);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      if (newSelected.length === 1) {
        this.setState({
          selected: newSelected,
          selectedRunMailList: runMailList.runMailListNo,
          selectedRunMailListId: runMailListID,
        });
      } else {
        this.setState({
          selected: newSelected,
          selectedRunMailList: "",
          selectedRunMailListId: 0,
        });
      }

      let runMailListIds = clone(newSelected);
      let docketList = runMailListDetailList.filter(
        (d) => runMailListIds.indexOf(d.runMailListID) >= 0
      );

      let localDocket = false;
      let validSelection = false;
      localDocket = docketList.every((d) => d.isLocal === true);
      if (localDocket) {
        validSelection = true;
      } else {
        validSelection = docketList.every((d) => d.isLocal === false);
      }
      this.setState({
        localDocket: localDocket,
        validSelection: validSelection,
      });
      let values = newSelected.join(",");
      values = values.concat("-", localDocket);

      dispatch({
        type: "getSelectedDocketId",
        newStore: values,
      });
    }
  }
  handleRequestSort = (event, property) => {
    // console.log(property + "from handler");
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleDocketClick = async (event, docketID, docket) => {
    let currentType = docket.type;
    let type = docket.statusName;
    let flag = docketStatusSelection[type];
    let returnStatus = this.checkedStatusArray(
      manDoc,
      flag,
      docketID,
      event.target.checked
    );

    if (returnStatus && manDoc.length > 0) {
      if (manDoc[0].flag == true) {
        this.setState({ isGen: true, isMan: false });
      } else {
        this.setState({ isGen: false, isMan: true });
      }
    } else {
      this.setState({ isGen: false, isMan: false });
    }

    let { selected2, docketDetailList } = this.state;
    const selectedIndex = selected2.indexOf(docketID);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected2, docketID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected2.slice(1));
    } else if (selectedIndex === selected2.length - 1) {
      newSelected = newSelected.concat(selected2.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected2.slice(0, selectedIndex),
        selected2.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.setState({
        selected2: newSelected,
        selectedDocket: docket.DocketNo,
        selectedDocketId: docketID,
      });
    } else {
      this.setState({
        selected2: newSelected,
        selectedDocket: "",
        selectedDocketId: 0,
      });
    }
    let docketIds = clone(newSelected);
    let docketList = docketDetailList.filter(
      (d) => docketIds.indexOf(d.docketHeaderID) >= 0
    );

    if (newSelected.length === 1) {
      var selectedRow = this.state.docketDetailList.filter((r) => {
        return r.docketHeaderID == newSelected[0];
      });
    }

    //let localDocket = false;
    let manualDocket = false;
    //let validSelection = false;
    //localDocket = docketList.every((d) => d.type === "Local");
    manualDocket = docketList.some((d) => d.isManual === true);
    const arr = docketList;
    const filtered = arr.reduce(
      (a, o) => (o.type === "Local" && a.push(o.docketHeaderID), a),
      []
    );
    const filtered1 = arr.reduce(
      (a, o) => (o.type === "International" && a.push(o.docketHeaderID), a),
      []
    );

    await this.setState({
      localDocketList: filtered,
    });

    await this.setState({
      internationalDocketList: filtered1,
    });
    // const arr = docketList;
    // const filtered = arr.reduce(
    //   (a, o) => (o.type === "Local" && a.push(o.docketHeaderID), a),
    //   []
    // );
    // const filtered1 = arr.reduce(
    //   (a, o) => (o.type === "International" && a.push(o.docketHeaderID), a),
    //   []
    // );

    // await this.setState({
    //   localDocketList: filtered,
    // });

    // await this.setState({
    //   internationalDocketList: filtered1,
    // });

    // this.setState({localDocket2:localDocket, validDocketSelection: validSelection, manualDocket: manualDocket});
    this.setState({ manualDocket: manualDocket, selectedRow });
  };
  // console.log(this.state, "isManualPresent");
  handleEdsErrorModal = (e, id) => {
    getEdsErrorDetails(id).then(r => {
      this.setState({ edsErrorData: r })
    })
    this.setState({ openEdsErrorModal: true })
  }
  handleEdsErrorModalClose = () => {
    this.setState({ openEdsErrorModal: false })
  }
  render() {
    let { classes } = this.props;
    let {
      fileNameList,
      provinceList,
      countryList,
      order,
      orderBy,
      mailSizeList,
      disableAdd,
      selected,
      selected2,
      editableRunMailListDetailList,
      editableDocketDetailList,
      deleteRunMailListValues,
      deleteDocketListValues,
      workOrder,
      zoneList,
      mailSizeRequired,
    } = this.state;
    const isSelected = (id) => selected.indexOf(id) !== -1;
    const isSelected2 = (id) => selected2.indexOf(id) !== -1;

    let isLocal =
      editableRunMailListDetailList.country &&
        editableRunMailListDetailList.country.value.toLowerCase() === "sg"
        ? true
        : false;

    return (
      <React.Fragment>
        { this.state.openEdsErrorModal && <EdsErrorModal open={this.state.openEdsErrorModal}
          handleEdsErrorModalClose={this.handleEdsErrorModalClose}
          data={this.state.edsErrorData} />}
        {this.state.selected2.length === 1 && this.state.showPopupLocal && (
          <ReportViewer
            url={
              `${window.localStorage.ReportURLByID}` +
              "Posting Docket Local&IsDirectPrint=True&ReportParam=DocketHeaderID&Param1=" +
              `${this.state.selected2[0] && this.state.selected2[0]}`
            }
            showPopup={this.state.showPopupLocal}
            onClose={() => this.handleHidePopup()}
            title={"Posting Docket Local"}
            handleHidePopup={() => this.handleHidePopup()}
          />
        )}
        {this.state.selected2.length === 1 &&
          this.state.showPopupInternational && (
            <ReportViewer
              url={
                `${window.localStorage.ReportURLByID}` +
                "PostingDocket(International)&IsDirectPrint=True&ReportParam=DocketHeaderID&Param1=" +
                `${this.state.selected2[0] && this.state.selected2[0]}`
              }
              showPopup={this.state.showPopupInternational}
              onClose={() => this.handleHidePopup()}
              title={"PostingDocket(International)"}
              handleHidePopup={() => this.handleHidePopup()}
            />
          )}
        {/* Delete Docket Confirmation Dialog */}
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.confirmDelete}
        >
          <div style={{ padding: "4em" }}>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>
              {" "}
              Are you sure you want to cancel the docket ?
            </div>
            <div style={{ fontSize: "13px" }}>
              The docket will be Cancelled and you can't undo the process!!
            </div>
            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) =>
                  this.onDeleteDocket(
                    e,
                    deleteDocketListValues.index,
                    deleteDocketListValues.id,
                    "no"
                  )
                }
              >
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none", marginLeft: "1em" }}
                onClick={(e) =>
                  this.onDeleteDocket(
                    e,
                    deleteDocketListValues.index,
                    deleteDocketListValues.id,
                    "Yes"
                  )
                }
              >
                Yes
              </Button>
            </div>
          </div>
        </Dialog>
        {/* Manual Docket Confirmation Dialog */}
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.isManualDocketRecord}
        >
          <div style={{ padding: "4em" }}>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>
              {" "}
              Missing Postage charges and Total charges
            </div>
            <div style={{ fontSize: "13px" }}>
              Please provide Postage charges and Total charges for creating
              Manual Docket.
            </div>
            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={this.continueCreateDocket}
              >
                OK
              </Button>
            </div>
          </div>
        </Dialog>
        {/* Delete RunMailList Confirmation Dialog */}
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.isdeleteRunMailList}
        >
          <div style={{ padding: "4em" }}>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>
              {" "}
              Are you sure you want to delete this runmail list record ?
            </div>
            <div style={{ fontSize: "13px" }}>
              The runmail list record will be deleted and you can't undo the
              process!
            </div>
            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) =>
                  this.deleteRunMailListConfirm(
                    e,
                    deleteRunMailListValues.index,
                    deleteRunMailListValues.id,
                    "no"
                  )
                }
              >
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none", marginLeft: "1em" }}
                onClick={(e) =>
                  this.deleteRunMailListConfirm(
                    e,
                    deleteRunMailListValues.index,
                    deleteRunMailListValues.id,
                    "Yes"
                  )
                }
              >
                Yes
              </Button>
            </div>
          </div>
        </Dialog>
        <Paper
          style={{ width: "100%" }}
        // style={
        //   !this.props.clearOpenList
        //     ? {
        //         overflow: "auto",
        //         border: "1px solid #eaeaea",
        //         maxWidth: "calc(100vw - 8em)"
        //       }
        //     : {
        //         overflow: "auto",
        //         border: "1px solid #eaeaea",
        //         maxWidth: "calc(100vw - 330px)"
        //       }
        // }
        >
          {/* <div className="pop-up-header">Run Mail List</div>
          <Divider /> */}
          <div
            style={
              !this.props.clearOpenList
                ? {
                  overflow: "auto",
                  maxWidth: "calc(100vw - 106px)",
                  //maxHeight: "calc(100vh - 300px)"
                }
                : {
                  overflow: "auto",
                  maxWidth: "calc(100vw - 335px)",
                  //maxHeight: "calc(100vh - 300px)"
                }
            }
          >
            <div className={classes.container}>
              <div>
                <div className={classes.container}>
                  <Typography
                    component="div"
                    className="blueBox"
                    style={{ width: "99%" }}
                  >
                    <div className="blueBoxLabel" style={{ width: "60%" }}>
                      <div>Customer: </div>
                      <div className="blueBoxText">
                        {workOrder.customerName}
                      </div>
                    </div>
                    <div className="blueBoxLabel" style={{ width: "60%" }}>
                      <div>Work Order: </div>
                      <div className="blueBoxText">
                        {workOrder.workOrderNumber}
                      </div>
                    </div>
                    <div className="blueBoxLabel" style={{ width: "80%" }}>
                      <div>Project Title: </div>
                      <div className="blueBoxText">
                        {workOrder.projectTitle}
                      </div>
                    </div>
                    <div className="blueBoxLabel" style={{ width: "50%" }}>
                      <div>Job Group: </div>
                      <div className="blueBoxText">
                        {workOrder.jobGroupNumber}
                      </div>
                    </div>
                  </Typography>
                </div>
                <form
                  style={{ marginTop: "-1%" }}
                  className={classes.container}
                >
                  <EnhancedTableToolbar
                    numSelected={selected && selected.length}
                    history={this.props.history}
                    order={order}
                    orderBy={orderBy}
                    selected={this.state.selected}
                    selectedRunMailList={this.state.selectedRunMailList}
                    selectedRunMailListId={this.state.selectedRunMailListId}
                    headCells={headCells}
                    isActive={this.state.isActive}
                    handleClickButton={this.handleClickButton}
                    editableRunMailListDetailList={
                      this.state.editableRunMailListDetailList
                    }
                    resetList={this.state.resetList}
                    disableAdd={this.state.disableAdd}
                    disableEdit={this.state.disableEdit}
                    validSelection={this.state.validSelection}
                    mailSizeRequired={this.state.mailSizeRequired}
                    loading={this.state.loading}
                    showText={this.state.showText}
                    mode={this.props.location.state.workOrderMode}
                  />
                  <div style={{ width: "100%" }}>
                    <div
                      style={
                        !this.props.clearOpenList
                          ? {
                            overflow: "auto",
                            border: "1px solid #eaeaea",
                            //maxWidth: "calc(100vw - 8em)",
                            height: "350px",
                          }
                          : {
                            overflow: "auto",
                            border: "1px solid #eaeaea",
                            //maxWidth: "calc(100vw - 330px)",
                            height: "350px",
                          }
                      }
                    >
                      <Table
                        aria-labelledby="tableTitle"
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                        className="runMailListTable"
                      >
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={this.handleRequestSort}
                        />

                        <TableBody style={{ overflow: "hidden" }}>
                          {editableRunMailListDetailList.length > 0 ? (
                            // stableSort(
                            //   editableRunMailListDetailList,
                            //   getSorting(order, orderBy)
                            // ).
                            editableRunMailListDetailList.map((row, index) => {
                              const isItemSelected = isSelected(
                                row.runMailListID
                              );
                              const labelId = `enhanced-table-checkbox-${index}`;
                              // console.log(editableRunMailListDetailList);
                              return (
                                <TableRow
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.runMailListID}
                                  id={`tableRow${index}`}
                                  selected={isItemSelected}
                                >
                                  <TableCell
                                    disabled={
                                      this.props.location.state.workOrderMode ==
                                        "view"
                                        ? true
                                        : false
                                    }
                                    padding="checkbox"
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? { backgroundColor: "#EAEAEA" }
                                        : {}
                                    }
                                  >
                                    {row.pgsDocketNumber === "" && (
                                      <FormControlLabel
                                        disabled={
                                          this.props.location.state
                                            .workOrderMode == "view"
                                            ? true
                                            : false
                                        }
                                        control={
                                          <Checkbox
                                            disabled={
                                              this.props.location.state
                                                .workOrderMode == "view"
                                                ? true
                                                : false
                                            }
                                            color="primary"
                                            checked={isItemSelected}
                                            className="table-content-cell"
                                            onClick={(event) =>
                                              this.handleClick(
                                                event,
                                                row.runMailListID,
                                                row
                                              )
                                            }
                                          />
                                        }
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="none"
                                    align="left"
                                    className={editableRunMailListDetailList[
                                      index
                                    ].editClicked ? "table-content-cell" : ""}
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? {
                                          backgroundColor: "#EAEAEA",
                                          padding: "0px 3px 0px 3px",
                                        }
                                        : { padding: "0px 3px 0px 3px" }
                                    }
                                    style={{
                                      minWidth: editableRunMailListDetailList[
                                        index
                                      ].editClicked
                                        ? "150px"
                                        : "auto",
                                      borderLeft: "1px solid #eaeaea",
                                      padding: "0 6px",
                                    }}
                                  >
                                    {" "}
                                    {editableRunMailListDetailList[index]
                                      .editClicked ? (
                                        <div
                                          style={{ marginTop: "0px !important" }}
                                          id="editableGridCell"
                                        >
                                          <Input
                                            // required
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "weightPerItem",
                                              maxLength: 10,
                                            }}
                                            //onKeyPress={this._onKeyPress}
                                            style={{ width: "100%" }}
                                            name="fileName"
                                            value={row.fileName}
                                            onChange={(e) =>
                                              this.handleRowChange(e, index)
                                            }
                                          />
                                          {/* <Autocomplete
                                          autoComplete
                                          includeInputInList
                                          id={"fileName-" + index}
                                          name="fileName"
                                          hintText="fileName"
                                          options={fileNameList}
                                          style={{ marginTop: "0px" }}
                                          getOptionLabel={(option) =>
                                            option.text
                                          }
                                          onChange={this.handleDDLChangeUpdated}
                                          value={row.fileName}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              // autoFocus
                                              name="fileName"
                                              id="fileName"
                                              style={{
                                                marginTop: "0px !important",
                                              }}
                                              value={row.fileName}
                                              required
                                            />
                                          )}
                                        /> */}
                                        </div>
                                      ) : row.fileName !== undefined &&
                                        row.fileName &&
                                        row.fileName.length > 10 ? (
                                          <Tooltip title={row.fileName}>
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.fileName}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.fileName !== undefined &&
                                              row.fileName
                                              ? row.fileName
                                              : null}
                                          </Typography>
                                        )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={"zoneCell" + index}
                                    padding="none"
                                    align="left"
                                    className="table-content-cell"
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? {
                                          backgroundColor: "#EAEAEA",
                                          padding: "0px 6px",
                                          minWidth: "100px",
                                        }
                                        : {
                                          padding: "0px 6px",
                                          minWidth: "150px",
                                        }
                                    }
                                    style={{
                                      minWidth: editableRunMailListDetailList[
                                        index
                                      ].editClicked
                                        ? "150px"
                                        : "100px",
                                      padding: "0 6px",
                                    }}
                                  >
                                    {editableRunMailListDetailList[index]
                                      .editClicked &&
                                      !mailSizeRequired &&
                                      (editableRunMailListDetailList[index]
                                        .country == "" ||
                                        editableRunMailListDetailList[index]
                                          .country == null) ? (
                                        <div
                                          style={{ marginTop: "0px !important" }}
                                          id="editableGridCell"
                                        >
                                          <Autocomplete
                                            autoComplete
                                            includeInputInList
                                            id={"zone-" + index}
                                            name="zone"
                                            hintText="zone"
                                            options={zoneList}
                                            style={{ marginTop: "0px" }}
                                            getOptionLabel={(option) =>
                                              option.text
                                            }
                                            onChange={this.handleDDLChangeUpdated}
                                            value={row.zone}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                name="zone"
                                                id="zone"
                                                style={{
                                                  marginTop: "0px !important",
                                                  width: "100%",
                                                }}
                                                value={row.zone}
                                                required
                                              />
                                            )}
                                          />
                                        </div>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          {row.zone && row.zone.text}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={"countryCell" + index}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? {
                                          backgroundColor: "#EAEAEA",
                                          padding: "0px 3px 0px 3px",
                                        }
                                        : { padding: "0px 3px 0px 3px" }
                                    }
                                  >
                                    {editableRunMailListDetailList[index]
                                      .editClicked &&
                                      (editableRunMailListDetailList[index]
                                        .zone == "" ||
                                        editableRunMailListDetailList[index]
                                          .zone == null) ? (
                                        <div
                                          style={{ marginTop: "0px !important" }}
                                          id="editableGridCell"
                                        >
                                          <Autocomplete
                                            autoComplete
                                            includeInputInList
                                            id={"country-" + index}
                                            name="country"
                                            hintText="country"
                                            options={countryList}
                                            style={{ marginTop: "0px" }}
                                            getOptionLabel={(option) =>
                                              option.text
                                            }
                                            onChange={this.handleDDLChangeUpdated}
                                            value={row.country}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                name="country"
                                                id="country"
                                                style={{
                                                  marginTop: "0px !important",
                                                }}
                                                value={row.country}
                                                required
                                              />
                                            )}
                                          />
                                        </div>
                                      ) : row &&
                                        row.country &&
                                        row.country.text &&
                                        row.country.text.length > 10 ? (
                                          <Tooltip
                                            title={
                                              row && row.country && row.country.text
                                            }
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.country.text}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row && row.country && row.country.text}
                                          </Typography>
                                        )}
                                  </TableCell>
                                  {/* <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={{ padding: "0px 30px 0px 16px" }}
                                  >
                                    {" "}
                                    {editableRunMailListDetailList[index]
                                      .editClicked ? (
                                      <div
                                        style={{ marginTop: "0px !important" }}
                                        id="editableGridCell"
                                      >
                                        <Autocomplete
                                          autoComplete
                                          includeInputInList
                                          id={"province-" + index}
                                          name="province"
                                          hintText="province"
                                          options={provinceList}
                                          style={{ marginTop: "0px" }}
                                          getOptionLabel={(option) =>
                                            option.text
                                          }
                                          onChange={(e) =>
                                            this.handleDDLChange(
                                              e,
                                              index,
                                              "province",
                                              provinceList
                                            )
                                          }
                                          value={row.province}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              name="province"
                                              id="province"
                                              style={{
                                                marginTop: "0px !important",
                                              }}
                                              value={row.province}
                                              required
                                            />
                                          )}
                                        />
                                      </div>
                                    ) : row.province &&
                                      row.province.text.length > 10 ? (
                                      <Tooltip
                                        title={
                                          row.province && row.province.text
                                        }
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.province && row.province.text}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.province && row.province.text}
                                      </Typography>
                                    )}
                                  </TableCell> */}
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? {
                                          backgroundColor: "#EAEAEA",
                                          padding: "0px 8px 0px 16px",
                                        }
                                        : { padding: "0px 8px 0px 16px" }
                                    }
                                  >
                                    {editableRunMailListDetailList[index]
                                      .editClicked ? (
                                        <div
                                          style={{ marginTop: "0px !important" }}
                                          id="editableGridCell"
                                        >
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "copies",
                                              maxLength: 10,
                                            }}
                                            style={{
                                              width: "100%",
                                              textOverflow: "ellipsis",
                                            }}
                                            name="copies"
                                            onKeyPress={this._onKeyPress}
                                            value={row.copies}
                                            onChange={(e) =>
                                              this.handleRowChange(e, index)
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          {row.copies}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  {/* <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? {
                                          backgroundColor: "#EAEAEA",
                                          padding: "0px 8px 0px 16px",
                                        }
                                        : { padding: "0px 8px 0px 16px" }
                                    }
                                  >
                                    {editableRunMailListDetailList[index]
                                      .editClicked ? (
                                        <div
                                          style={{ marginTop: "0px !important" }}
                                          id="editableGridCell"
                                        >
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "quantity",
                                              maxLength: 10,
                                            }}
                                            style={{ width: "auto" }}
                                            name="quantity"
                                            value={row.quantity}
                                            onKeyPress={this._onKeyPress}
                                            onChange={(e) =>
                                              this.handleRowChange(e, index)
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          {row.quantity}
                                        </Typography>
                                      )}
                                  </TableCell> */}
                                  {/* <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                    style={{ padding: "0px 8px 0px 16px" }}
                                  >
                                    {row.totalOrderQuantity}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={{ padding: "0px 8px 0px 16px" }}
                                  >
                                    {editableRunMailListDetailList[index]
                                      .editClicked ? (
                                      <div
                                        style={{ marginTop: "0px !important" }}
                                        id="editableGridCell"
                                      >
                                        <Input
                                          defaultValue=""
                                          inputProps={{
                                            "aria-label": "remarks",
                                            maxLength: 200,
                                          }}
                                          style={{ width: "100%" }}
                                          name="remarks"
                                          value={row.remarks}
                                          onChange={(e) =>
                                            this.handleRowChange(e, index)
                                          }
                                        />
                                      </div>
                                    ) : row.remarks &&
                                      row.remarks.length > 16 ? (
                                      <Tooltip title={row.remarks}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.remarks}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.remarks}
                                      </Typography>
                                    )}
                                  </TableCell> */}
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? {
                                          backgroundColor: "#EAEAEA",
                                          padding: "0px 8px 0px 16px",
                                        }
                                        : { padding: "0px 8px 0px 16px" }
                                    }
                                  >
                                    {editableRunMailListDetailList[index]
                                      .editClicked ? (
                                        <div
                                          style={{ marginTop: "0px !important" }}
                                          id="editableGridCell"
                                        >
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "routing",
                                              maxLength: 200,
                                            }}
                                            style={{ width: "100%" }}
                                            name="routing"
                                            value={row.routing}
                                            onChange={(e) =>
                                              this.handleRowChange(e, index)
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          {row.routing}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? {
                                          backgroundColor: "#EAEAEA",
                                          padding: "0px 8px 0px 16px",
                                        }
                                        : { padding: "0px 8px 0px 16px" }
                                    }
                                  >
                                    {row.pgsDocketNumber}
                                  </TableCell>
                                  {/* <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    {editableRunMailListDetailList[index]
                                      .editClicked ? (
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={row.cop}
                                            onChange={(e) =>
                                              this.handleSwitchChange(e, index)
                                            }
                                            value="cop"
                                            name="cop"
                                            color="primary"
                                          />
                                        }
                                      />
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.cop ? "Yes" : "No"}
                                      </Typography>
                                    )}
                                  </TableCell> */}
                                  {/* <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? {
                                            backgroundColor: "#EAEAEA",
                                            padding: "6px 24px 6px 16px",
                                          }
                                        : { padding: "6px 24px 6px 16px" }
                                    }
                                  >
                                    {editableRunMailListDetailList[index]
                                      .editClicked && !mailSizeRequired ? (
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={row.isBM1500Countries}
                                            onChange={(e) =>
                                              this.handleSwitchChange(e, index)
                                            }
                                            value="isBM1500Countries"
                                            name="isBM1500Countries"
                                            color="primary"
                                          />
                                        }
                                      />
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.isBM1500Countries ? "Yes" : "No"}
                                      </Typography>
                                    )}
                                  </TableCell> */}
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? {
                                          backgroundColor: "#EAEAEA",
                                          padding: "0px 3px 0px 3px",
                                        }
                                        : { padding: "0px 3px 0px 3px" }
                                    }
                                  >
                                    {" "}
                                    {editableRunMailListDetailList[index]
                                      .editClicked && mailSizeRequired ? (
                                        <div
                                          style={{ marginTop: "0px !important" }}
                                          id="editableGridCell"
                                        >
                                          <Autocomplete
                                            //autoComplete
                                            includeInputInList
                                            id={"mailSize-" + index}
                                            name="mailSize"
                                            hintText="mailSize"
                                            options={mailSizeList}
                                            style={{ marginTop: "0px" }}
                                            getOptionLabel={(option) =>
                                              option.value
                                            }
                                            onChange={this.handleDDLChangeUpdated}
                                            value={row.mailSize}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                name="mailSize"
                                                id="mailSize"
                                                style={{
                                                  marginTop: "0px !important",
                                                }}
                                                value={row.mailSize}
                                                required
                                              />
                                            )}
                                          />
                                        </div>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          {row.mailSize && row.mailSize.value}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="none"
                                    align="right"
                                    className="table-content-cell"
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? {
                                          backgroundColor: "#EAEAEA",
                                          padding: "0px 8px 0px 16px",
                                        }
                                        : { padding: "0px 8px 0px 16px" }
                                    }
                                  >
                                    {editableRunMailListDetailList[index]
                                      .editClicked ? (
                                        <div
                                          style={{ marginTop: "0px !important" }}
                                          id="editableGridCell"
                                        >
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "weightPerItem",
                                              maxLength: 10,
                                            }}
                                            onKeyPress={this._onKeyPress}
                                            style={{ width: "100%" }}
                                            name="weightPerItem"
                                            value={Math.round(row.weightPerItem)}
                                            onChange={(e) =>
                                              this.handleRowChange(e, index)
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          {Math.round(row.weightPerItem)}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? {
                                          backgroundColor: "#EAEAEA",
                                          padding: "0px 8px 0px 16px",
                                        }
                                        : { padding: "0px 8px 0px 16px" }
                                    }
                                  >
                                    {editableRunMailListDetailList[index]
                                      .editClicked ? (
                                        <div
                                          style={{ marginTop: "0px !important" }}
                                          id="statusCell"
                                        >
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "noOfArticles",
                                              maxLength: 10,
                                            }}
                                            onKeyPress={this._onKeyPress}
                                            style={{ marginTop: "0px" }}
                                            name="noOfArticles"
                                            value={row.noOfArticles}
                                            onChange={(e) =>
                                              this.handleRowChange(e, index)
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          {row.noOfArticles}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    align="right"
                                    className="table-content-cell"
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? {
                                          backgroundColor: "#EAEAEA",
                                          padding: "0px 8px 0px 16px",
                                        }
                                        : { padding: "0px 8px 0px 16px" }
                                    }
                                  >
                                    {editableRunMailListDetailList[index]
                                      .editClicked ? (
                                        <div
                                          style={{ marginTop: "0px !important" }}
                                          id="editableGridCell"
                                        >
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "noOfBags",
                                              maxLength: 10,
                                            }}
                                            onKeyPress={this._onKeyPress}
                                            style={{ width: "100%" }}
                                            name="noOfBags"
                                            value={row.noOfBags}
                                            onChange={(e) =>
                                              this.handleRowChange(e, index)
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.noOfBags}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    align="right"
                                    className="table-content-cell"
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? {
                                          backgroundColor: "#EAEAEA",
                                          padding: "0px 8px 0px 16px",
                                        }
                                        : { padding: "0px 8px 0px 16px" }
                                    }
                                  >
                                    {editableRunMailListDetailList[index]
                                      .editClicked ? (
                                        <div
                                          style={{ marginTop: "0px !important" }}
                                          id="editableGridCell"
                                        >
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "weightPerBag",
                                              maxLength: 15,
                                            }}
                                            onKeyPress={this._onDecimalKeyPress}
                                            style={{ width: "100%" }}
                                            name="weightPerBag"
                                            onBlur={(e) => this.onBlur(e, index)}
                                            value={row.weightPerBag}
                                            onChange={(e) =>
                                              this.handleRowChange(e, index)
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          {row.weightPerBag}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    align="right"
                                    className="table-content-cell"
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? {
                                          backgroundColor: "#EAEAEA",
                                          padding: "0px 8px 0px 16px",
                                        }
                                        : { padding: "0px 8px 0px 16px" }
                                    }
                                  >
                                    {editableRunMailListDetailList[index]
                                      .editClicked ? (
                                        <div
                                          style={{ marginTop: "0px !important" }}
                                          id="editableGridCell"
                                        >
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label":
                                                "totalChargeableWeight",
                                              maxLength: 15,
                                            }}
                                            style={{ width: "100%" }}
                                            onBlur={(e) => this.onBlur(e, index)}
                                            name="totalChargeableWeight"
                                            onKeyPress={this._onDecimalKeyPress}
                                            value={row.totalChargeableWeight}
                                            onChange={(e) =>
                                              this.handleRowChange(e, index)
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          {row.totalChargeableWeight}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? {
                                          backgroundColor: "#EAEAEA",
                                          padding: "0px 8px 0px 16px",
                                        }
                                        : { padding: "0px 8px 0px 16px" }
                                    }
                                  >
                                    {editableRunMailListDetailList[index]
                                      .editClicked ? (
                                        <div
                                          style={{ marginTop: "0px !important" }}
                                          id="editableGridCell"
                                        >
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "postageCharges",
                                              maxLength: 15,
                                            }}
                                            onKeyPress={this._onDecimalKeyPress}
                                            onBlur={(e) => this.onBlur(e, index)}
                                            style={{ width: "100%" }}
                                            name="postageCharges"
                                            value={row.postageCharges}
                                            onChange={(e) =>
                                              this.handleRowChange(e, index)
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          {row.postageCharges}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? {
                                          backgroundColor: "#EAEAEA",
                                          padding: "0px 8px 0px 16px",
                                        }
                                        : { padding: "0px 8px 0px 16px" }
                                    }
                                  >
                                    {editableRunMailListDetailList[index]
                                      .editClicked ? (
                                        <div
                                          style={{ marginTop: "0px !important" }}
                                          id="editableGridCell"
                                        >
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "totalCharges",
                                              maxLength: 15,
                                            }}
                                            onKeyPress={this._onDecimalKeyPress}
                                            onBlur={(e) => this.onBlur(e, index)}
                                            style={{ width: "100%" }}
                                            name="totalCharges"
                                            value={row.totalCharges}
                                            onChange={(e) =>
                                              this.handleRowChange(e, index)
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          {row.totalCharges}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={
                                      row.pgsDocketNumber !== ""
                                        ? {
                                          backgroundColor: "#EAEAEA",
                                          padding: "0px 8px 0px 16px",
                                        }
                                        : { padding: "0px 8px 0px 16px" }
                                    }
                                  >
                                    {row.pgsDocketNumber === "" && (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                          display: "flex",
                                        }}
                                      >
                                        {editableRunMailListDetailList[index]
                                          .editClicked ? (
                                            <div>
                                              {row.validRecord ? (
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                  onClick={(e) =>
                                                    this.onDoneClicked(
                                                      e,
                                                      index,
                                                      row
                                                    )
                                                  }
                                                >
                                                  {this.props.location.state
                                                    .workOrderMode !== "view" ? (
                                                      <DoneIcon
                                                        style={{
                                                          color: "#6c6b6b",
                                                        }}
                                                      />
                                                    ) : null}
                                                </span>
                                              ) : (
                                                  <React.Fragment></React.Fragment>
                                                )}
                                              <span
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                                onClick={(e) =>
                                                  this.onCancelClicked(
                                                    e,
                                                    index,
                                                    row
                                                  )
                                                }
                                              >
                                                {this.props.location.state
                                                  .workOrderMode !== "view" ? (
                                                    <CloseIcon
                                                      style={{
                                                        cursor: "pointer",
                                                        color: "#6c6b6b",
                                                      }}
                                                    />
                                                  ) : null}
                                              </span>
                                            </div>
                                          ) : (
                                            <div>
                                              {!disableAdd ? (
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                  onClick={(e) =>
                                                    this.onEditClicked(e, index)
                                                  }
                                                >
                                                  {this.props.location.state
                                                    .workOrderMode !== "view" ? (
                                                      <EditIcon
                                                        style={{
                                                          color: "#6c6b6b",
                                                        }}
                                                      />
                                                    ) : null}
                                                </span>
                                              ) : (
                                                  <React.Fragment></React.Fragment>
                                                )}
                                              <span
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                                onClick={(e) =>
                                                  this.deleteRunMailListClicked(
                                                    e,
                                                    index,
                                                    row
                                                  )
                                                }
                                              >
                                                {this.props.location.state
                                                  .workOrderMode !== "view" ? (
                                                    <DeleteIcon
                                                      style={{
                                                        cursor: "pointer",
                                                        color: "#6c6b6b",
                                                      }}
                                                    />
                                                  ) : null}
                                              </span>
                                            </div>
                                          )}
                                      </Typography>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : this.state.loading ? (
                            <TableCell
                              colSpan={17}
                              style={{ textAlign: "center" }}
                            >
                              <LinearProgress />
                            </TableCell>
                          ) : (
                                <TableRow
                                  style={{ borderBottom: "none", height: "180px" }}
                                >
                                  <TableCell
                                    colSpan={17}
                                    style={{ textAlign: "center" }}
                                  >
                                    <div style={{ fontWeight: "bolder" }}>
                                      No Work Country Break Down found
                                </div>
                                    <div>
                                      Create the country break down/wait for the
                                      list to initiate the docket creation
                                </div>
                                  </TableCell>
                                </TableRow>
                              )}
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                  <div style={{ marginTop: "1em" }}>
                    <EnhancedTableToolbar2
                      numSelected={selected2.length}
                      handleClickButton={this.handleClickButton}
                      generateDocketClick={this.generateDocketClick}
                      disableAdd={this.state.disableAdd}
                      disableEdit={this.state.disableEdit}
                      order={order}
                      orderBy={orderBy}
                      headCells={headCells1}
                      handleReportClick={this.handleReportClick}
                      // validDocketSelection={this.state.validDocketSelection}
                      //type={this.state.localDocket2}
                      isManual={this.state.manualDocket}
                      isMan={this.state.isMan}
                      isGen={this.state.isGen}
                    />
                    <div style={{ width: "100%" }}>
                      <div
                        style={
                          !this.props.clearOpenList
                            ? {
                              overflow: "auto",
                              border: "1px solid #eaeaea",
                              //maxWidth: "calc(100vw - 8em)",
                              // height: "225px",
                            }
                            : {
                              overflow: "auto",
                              border: "1px solid #eaeaea",
                              //maxWidth: "calc(100vw - 330px)",
                              // height: "225px",
                            }
                        }
                      >
                        <Table
                          stickyHeader
                          aria-labelledby="tableTitle"
                          size="small"
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <EnhancedTableHead1
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                          />

                          <TableBody style={{ overflow: "hidden" }}>
                            {editableDocketDetailList.length > 0 &&
                              !this.state.loading ? (
                                // stableSort(
                                //   editableDocketDetailList,
                                //   getSorting(order, orderBy)
                                // )
                                editableDocketDetailList.map((row, index) => {
                                  //console.log(editableDocketDetailList);
                                  // console.log(row);
                                  const isItemSelected = isSelected2(
                                    row.docketHeaderID
                                  );
                                  const labelId = `enhanced-table-checkbox-${index}`;

                                  return (
                                    <TableRow
                                      role="checkbox"
                                      aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      key={row.docketHeaderID}
                                      selected2={isItemSelected}
                                      style={
                                        row.statusName.trim() === "Cancelled"
                                          ? { backgroundColor: "#EAEAEA" }
                                          : {}
                                      }
                                    >
                                      <TableCell padding="checkbox">
                                        {row.statusName.trim() !== "Cancelled" ? (
                                          <FormControlLabel
                                            disabled={
                                              this.props.location.state
                                                .workOrderMode == "view"
                                                ? true
                                                : false
                                            }
                                            control={
                                              <Checkbox
                                                disabled={
                                                  this.props.location.state
                                                    .workOrderMode == "view"
                                                    ? true
                                                    : false
                                                }
                                                color="primary"
                                                checked={isItemSelected}
                                                onClick={(event) =>
                                                  this.handleDocketClick(
                                                    event,
                                                    row.docketHeaderID,
                                                    row
                                                  )
                                                }
                                              />
                                            }
                                          />
                                        ) : null}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="right"
                                        className="table-content-cell"
                                      >
                                        {row.pgsDocketNo}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.edsDocketNo}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="right"
                                        className="table-content-cell"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.accountNo}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="none"
                                        style={{ paddingRight: "5px" }}
                                        align="right"
                                        className="table-content-cell"
                                      >
                                        {row.estimatedLodgementDate &&
                                          row.estimatedLodgementDate.length > 16 ? (
                                            <Tooltip
                                              title={moment(
                                                row.estimatedLodgementDate
                                              ).format("DD/MM/YYYY HH:mm:ss")}
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {moment(
                                                  row.estimatedLodgementDate
                                                ).format("DD/MM/YYYY HH:mm:ss")}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {moment(
                                                row.estimatedLodgementDate
                                              ).format("DD/MM/YYYY HH:mm:ss")}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="none"
                                        style={{ paddingRight: "5px" }}
                                        align="right"
                                        className="table-content-cell"
                                      >
                                        {row.submittedDate &&
                                          row.submittedDate.length > 16 ? (
                                            <Tooltip
                                              title={moment(
                                                row.submittedDate
                                              ).format("DD/MM/YYYY HH:mm:ss")}
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {moment(row.submittedDate).format(
                                                  "DD/MM/YYYY HH:mm:ss"
                                                )}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {moment(row.submittedDate).format(
                                                "DD/MM/YYYY HH:mm:ss"
                                              )}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.serviceType}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.type && row.type}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="none"
                                        align="left"
                                        className="table-content-cell-eds-error"
                                        style={{ minWidth: "180px", }}
                                        onClick={e => this.handleEdsErrorModal(e, row.docketHeaderID)}
                                      >
                                        <Typography noWrap>
                                          <StatusText
                                            color={getColor(row.statusName)}
                                          >
                                            {row.statusName &&
                                              row.statusName.length > 16 ? (
                                                <Tooltip
                                                  title={row.statusName}
                                                  aria-label="add"
                                                >
                                                  <Typography noWrap>
                                                    {row.statusName}
                                                  </Typography>
                                                </Tooltip>
                                              ) : (
                                                <Typography noWrap>
                                                  {row.statusName}
                                                </Typography>
                                              )}
                                          </StatusText>
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        {row.edsExceptionMessage &&
                                          row.edsExceptionMessage.length > 16 ? (
                                            <Tooltip
                                              title={row.edsExceptionMessage}
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.edsExceptionMessage}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.edsExceptionMessage}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="none"
                                        style={{ paddingRight: "5px" }}
                                        align="right"
                                        className="table-content-right-cell"
                                      >
                                        {row.modifiedDate &&
                                          row.modifiedDate.length > 16 ? (
                                            <Tooltip
                                              title={moment(
                                                row.modifiedDate
                                              ).format("DD/MM/YYYY HH:mm:ss")}
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {moment(row.modifiedDate).format(
                                                  "DD/MM/YYYY HH:mm:ss"
                                                )}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {moment(row.modifiedDate).format(
                                                "DD/MM/YYYY HH:mm:ss"
                                              )}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.modifiedByName &&
                                            row.modifiedByName}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="none"
                                        style={{ paddingRight: "5px" }}
                                        align="right"
                                        className="table-content-right-cell"
                                      >
                                        {row.createdDate &&
                                          row.createdDate.length > 16 ? (
                                            <Tooltip
                                              title={moment(row.createdDate).format(
                                                "DD/MM/YYYY HH:mm:ss"
                                              )}
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {moment(row.createdDate).format(
                                                  "DD/MM/YYYY HH:mm:ss"
                                                )}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {moment(row.createdDate).format(
                                                "DD/MM/YYYY HH:mm:ss"
                                              )}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.createdByName && row.createdByName}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                        disabled={
                                          this.props.location.state.workOrderMode
                                        }
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                            display: "flex",
                                          }}
                                        >
                                          <div>
                                            <span
                                              style={{
                                                cursor: "pointer",
                                                color: "#6c6b6b",
                                              }}
                                              onClick={(e) =>
                                                this.props.history.push({
                                                  pathname:
                                                    "/view_docketPosting/" +
                                                    row.docketHeaderID,
                                                  state: {
                                                    workOrderMode: this.props
                                                      .location.state
                                                      .workOrderMode,
                                                  },
                                                })
                                              }
                                            >
                                              <ViewIcon
                                                style={{
                                                  color: "#6c6b6b",
                                                }}
                                              />
                                            </span>
                                            {row.statusName.trim() !==
                                              "Cancelled" ? (
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                  onClick={(e) =>
                                                    this.onDocketEditClicked(
                                                      e,
                                                      index,
                                                      row.docketHeaderID
                                                    )
                                                  }
                                                >
                                                  {this.props.location.state
                                                    .workOrderMode !== "view" &&
                                                    !row.edsDocketNo ? (
                                                      <EditIcon
                                                        style={{
                                                          color: "#6c6b6b",
                                                        }}
                                                      />
                                                    ) : null}
                                                </span>
                                              ) : null}
                                            {row.statusName.trim() !==
                                              "Cancelled" ? (
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                  onClick={(e) =>
                                                    this.onDeleteDocketClicked(
                                                      e,
                                                      index,
                                                      row
                                                    )
                                                  }
                                                >
                                                  {this.props.location.state
                                                    .workOrderMode !== "view" ? (
                                                      <DeleteIcon
                                                        style={{
                                                          cursor: "pointer",
                                                          color: "#6c6b6b",
                                                        }}
                                                      />
                                                    ) : null}
                                                </span>
                                              ) : null}
                                          </div>
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })
                              ) : this.state.loading ? (
                                <TableCell
                                  colSpan={15}
                                  style={{ textAlign: "center" }}
                                >
                                  <LinearProgress />
                                </TableCell>
                              ) : (
                                  <TableRow
                                    style={{
                                      borderBottom: "none",
                                      // height: "180px",
                                    }}
                                  >
                                    {this.state.loading2 ? (
                                      <TableCell
                                        colSpan={13}
                                        style={{ textAlign: "center" }}
                                      >
                                        <div style={{ fontWeight: "bolder" }}>
                                          No Docket found
                                    </div>
                                        <div>
                                          Initiate from the country break down/wait
                                          for the process to initiate
                                    </div>
                                      </TableCell>
                                    ) : this.state.loading ? (
                                      <TableCell
                                        colSpan={15}
                                        style={{ textAlign: "center" }}
                                      >
                                        <LinearProgress />
                                      </TableCell>
                                    ) : null}
                                  </TableRow>
                                )}
                            <TableRow>
                              <TableCell colSpan={15} style={{ textAlign: "center" }}>
                                {!this.state.loading && editableDocketDetailList.length === 0 && "No Data Found"}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </div>
                  <Divider
                    style={{
                      marginTop: "25px",
                    }}
                  />
                  <div className="button-wrapper">
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        // onClick={e =>
                        //   this.props.history.push(
                        //     "/runMailList/" + workOrder.jobGroupID
                        //   )
                        // }
                        onClick={this.onCancelClick}
                      >
                        CANCEL
                      </Button>
                    </div>
                    {/* <div style={{ marginLeft: "1em" }}>
                    <Button
                      autoFocus
                      variant="contained"
                      onClick={e =>
                        this.props.history.push(
                          "/runMailList/" + workOrder.jobGroupID
                        )
                      }
                      className="enabled-button"
                    >
                      SUBMIT
                    </Button>
                  </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(AddRunMailList));
