import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Validator from "../../../shared/validator";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { resourceValidation } from "../../../shared/resource";
import {
  postProcess,
  getProcessCategoryList,
  getUOMPMList,
} from "../masterActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { addStyles } from "../../../shared/styles";
import { Autocomplete } from "@material-ui/lab";
import Axios from "axios";

const validateMaxlength2 = new Validator("maxlength2");
const validateMaxlength2to10 = new Validator("maxlength2to10");
const validateMaxlength100 = new Validator("maxlength100");
const validateNotRequired2 = new Validator("notRequired2");
const validateNotRequired100 = new Validator("notRequired100");
const validateNotRequired1000 = new Validator("notRequired1000");
const notRequired100 = new Validator("notRequired100");
const Alphanumeric = new Validator("Alphanumeric");

class addProcess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      process: {
        processID: "",
        processCategoryID: "",
        //categoryCode: "",
        processName: "",
        processDescription: "",
        isWOProcess: true,
        isActive: true,
        acct: "",
        pic: "",
        rc: "",
        gstType: true,
        uom: "",
        price: "0.00",
        outWorkerClaimRate: "0.00",
      },
      errors: {},
      processIDValid: false,
      processCategoryIDValid: false,
      // categoryCodeValid: false,
      processNameValid: false,
      acctValid: false,
      picValid: false,
      rcValid: false,
      gstTypeValid: false,
      processList: [],
      processCategoryList: [],
      uomList: [],
      uomValid: false,
      priceValid: false,
      outWorkerClaimRateValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleProcessChange = this.handleProcessChange.bind(this);
  }

  componentDidMount() {
    this.props.handleSettingHeader("Process Master");
    getProcessCategoryList().then((r) => {
      this.setState({
        processCategoryList:
          r &&
          r.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });
    getUOMPMList().then((r) => {
      this.setState({
        uomList:
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });
  }

  handleChange = (event) => {
    const { process } = this.state;
    if (
      event.target.name === "isWOProcess" ||
      event.target.name === "isActive" ||
      event.target.name === "gstType"
    ) {
      process[event.target.name] = event.target.checked;
    } else {
      process[event.target.name] = event.target.value;
      this.validateField(event.target.name, event.target.value);
    }
    this.setState({ process });
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let processIDValid = this.state.processIDValid;
    let processCategoryIDValid = this.state.processCategoryIDValid;
    // let categoryCodeValid = this.state.categoryCodeValid;
    let processNameValid = this.state.processNameValid;
    let acctValid = this.state.acctValid;
    let picValid = this.state.picValid;
    let rcValid = this.state.rcValid;
    let processList = this.state.processList;
    let uomValid = this.state.uomValid;
    let priceValid = this.state.priceValid;
    let outWorkerClaimRateValid = this.state.outWorkerClaimRateValid;

    switch (fieldName) {
      // case "processCategoryID":
      //   processCategoryIDValid = true;
      //   fieldValidationErrors.processCategoryID = processCategoryIDValid
      //     ? ""
      //     : resourceValidation.onlyNumbers;
      //   break;
      // case "categoryCode":
      //   categoryCodeValid = true;
      //   fieldValidationErrors.categoryCode = categoryCodeValid
      //     ? ""
      //     : resourceValidation.onlyAlphabet;
      //   break;
      case "processName":
        if (value !== "") {
          processNameValid = true;
          fieldValidationErrors.processNameValid = processNameValid
            ? ""
            : resourceValidation.onlyAlphabet;
        } else {
          processNameValid = false;
          fieldValidationErrors.officeName = "Please Enter Mandatory Field";
        }
        break;
      case "acct":
        acctValid = Alphanumeric(value);
        fieldValidationErrors.acct = acctValid
          ? ""
          : resourceValidation.checkdecimalpoint;
        break;
      case "price":
        picValid = true;
        fieldValidationErrors.price = priceValid
          ? ""
          : resourceValidation.checkdecimalpoint;
        break;
      case "rc":
        if (value !== "") {
          rcValid = true;
          fieldValidationErrors.rc = rcValid
            ? ""
            : resourceValidation.Alphanumeric;
          break;
        }
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,

        processIDValid: processIDValid,
        processCategoryIDValid: processCategoryIDValid,
        //categoryCodeValid: categoryCodeValid,
        processNameValid: processNameValid,
        acctValid: acctValid,
        priceValid: priceValid,
        rcValid: rcValid,
      },
      this.validateForm
    );
  }
  validateForm = () => {
    this.setState({
      formValid: this.state.processIDValid, // && this.state.categoryCodeValid
    });

    return this.state.formValid;
  };
  handleClose = () => {
    // clean up state and errors when modal closed
    this.setState({
      processID: "",
      processCategoryID: "",
      // categoryCode: "",
      processName: "",
      processDescription: "",
      isWOProcess: true,
      isActive: true,
      acct: "",
      pic: "",
      rc: "",
      gstType: true,
      uom: "",
      price: "0.00",
      outWorkerClaimRate: "0.00",
      errors: {},
    });
  };
  handleProcessChange = (event, values) => {
    const { process } = this.state;
    let category = values;
    process["processCategoryID"] = category.id;
    //process["processCategoryName"] = country.text;

    this.setState({ process: process });

    // this.validateField("countryID", collectionNote["countryID"]);
  };
  handleUomChange = (event, values) => {
    const { process } = this.state;
    let uomValue = values;
    process["uom"] = uomValue.id;
    //process["processCategoryName"] = country.text;

    this.setState({ process: process });

    // this.validateField("countryID", collectionNote["countryID"]);
  };
  handleSubmit = (event) => {
    event.preventDefault();

    // if (this.validateForm()) {
    // this.setState({ loading: true });
    const { process } = this.state;
    let processID = process.processID;
    let processCategoryID = process.processCategoryID;
    // let categoryCode = process.categoryCode;
    let processName = process.processName;
    let processDescription = process.processDescription;
    let isWOProcess = process.isWOProcess;
    let isActive = process.isActive;
    let acct = process.acct;
    let pic = process.pic;
    let rc = process.rc;
    let gstType = process.gstType;
    let uom = process.uom;
    let price = process.price;

    let outWorkerClaimRate = process.outWorkerClaimRate;

    let reqBody = {
      //   processID,
      processCategoryID,
      //categoryCode,
      processName,
      processDescription,
      isWOProcess,
      isActive,
      acct,
      pic,
      rc,
      gstType,
      uom,
      price,
      outWorkerClaimRate,
    };
    reqBody.createdBy = localStorage.loggedInId;
    reqBody.isActive = true;

    postProcess(reqBody, this.props.history)
      .then((r) => {
        this.setState({ loading: false });
        getProcessCategoryList().then((r) => {
          this.setState({
            processCategoryList:
              r &&
              r.sort((a, b) =>
                a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
              ),
          });
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
    // }
  };
  onInputChanged = (e, name, type) => {
    let newError = { ...this.state.errors };
    if (type === "maxlength2") {
      let result = validateMaxlength2(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else if (type === "maxlength100") {
      let result = validateMaxlength100(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else if (type === "notRequired2") {
      let result = validateNotRequired2(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else if (type === "notRequired100") {
      let result = validateNotRequired100(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else {
      let result = validateNotRequired1000(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    }
    this.setState({ [name]: e.target.value });
  };

  _onKeyPress(event) {
    const re = /[0-9a-zA-Z ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onAlphaKeyPress(event) {
    const re = /[a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onDecimalNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onAllCharPress(event) {
    const re = /[a-z A-Z0-9=.;(){}\/*%#$@&,-_]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onDimensionKeyPress(event) {
    const re = /[0-9*]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  render() {
    let { classes } = this.props;
    let { process, errors, processCategoryList, uomList } = this.state;

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Process</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: "1" }}>
                    <Autocomplete
                      //required
                      autoComplete
                      includeInputInList
                      id="processCategoryList"
                      name="processCategoryID"
                      hintText="Process Category ID"
                      style={{ width: "94%", marginLeft: ".7em" }}
                      options={processCategoryList}
                      onChange={this.handleProcessChange}
                      getOptionLabel={(option) => option.text}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Process Category"
                          name="processCategoryID"
                          id="processCategoryID"
                          margin="normal"
                          style={{ width: "99%" }}
                          value={this.state.process.processCategoryID}
                        />
                      )}
                      onKeyPress={this._onAlphaKeyPress}
                    />
                  </div>

                  <div style={{ flex: "1" }}>
                    {" "}
                    <TextField
                      required
                      label="Process Name"
                      name="processName"
                      inputProps={{
                        maxLength: 200,
                      }}
                      autoComplete="off"
                      margin="normal"
                      style={{ width: "91%" }}
                      className={classes.textField}
                      value={process.processName}
                      onChange={this.handleChange}
                      error={this.state.errors.processName}
                      helperText={
                        this.state.errors.processName ? errors.processName : ""
                      }
                      onKeyPress={this._onAllCharPress}
                    />
                  </div>
                  <div style={{ flex: "1" }}></div>
                </div>
                <div>
                  <TextField
                    // required
                    label="Process Description"
                    name="processDescription"
                    inputProps={{
                      maxLength: 500,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={process.processDescription}
                    onChange={this.handleChange}
                    error={this.state.errors.processDescription}
                    helperText={
                      this.state.errors.processDescription
                        ? errors.processDescription
                        : ""
                    }
                    onKeyPress={this._onAllCharPress}
                  />
                </div>

                <div>
                  <TextField
                    required
                    label="Price"
                    name="price"
                    inputProps={{
                      maxLength: 12,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={process.price}
                    onChange={this.handleChange}
                    error={this.state.errors.price}
                    helperText={
                      this.state.errors.price
                        ? resourceValidation.checkdecimalpoint
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$S</InputAdornment>
                      ),
                    }}
                    onKeyPress={this._onDecimalNumberKeyPress}
                  />
                  <TextField
                    required
                    label="Out Worker Claim Rate"
                    name="outWorkerClaimRate"
                    inputProps={{
                      maxLength: 12,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={process.outWorkerClaimRate}
                    onChange={this.handleChange}
                    error={this.state.errors.outWorkerClaimRate}
                    helperText={
                      this.state.errors.outWorkerClaimRate
                        ? resourceValidation.max1000char
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$S</InputAdornment>
                      ),
                    }}
                    onKeyPress={this._onDecimalNumberKeyPress}
                  />
                </div>
                <div>
                  <TextField
                    required
                    label="ACCT"
                    name="acct"
                    inputProps={{
                      maxLength: 50,
                    }}
                    // type="number"
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={process.acct}
                    onChange={this.handleChange}
                    error={this.state.errors.acct}
                    helperText={this.state.errors.acct ? errors.acct : ""}
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    required
                    label="PIC"
                    name="pic"
                    inputProps={{
                      maxLength: 1000,
                    }}
                    autoComplete="off"
                    margin="normal"
                    // type="number"
                    className={classes.textField}
                    value={process.pic}
                    onChange={this.handleChange}
                    error={this.state.errors.pic}
                    helperText={
                      this.state.errors.pic
                        ? resourceValidation.max1000char
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    required
                    label="RC"
                    name="rc"
                    inputProps={{
                      maxLength: 20,
                    }}
                    autoComplete="off"
                    margin="normal"
                    // type="number"
                    className={classes.textField}
                    value={process.rc}
                    onChange={this.handleChange}
                    error={this.state.errors.rc}
                    helperText={
                      this.state.errors.rc
                        ? resourceValidation.checkdecimalpoint
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                </div>
                <div
                  style={{
                    width: "31%",
                    fontSize: "11px !important",
                    marginLeft: "12px",
                    marginRight: "12px",
                  }}
                >
                  <Autocomplete
                    // required
                    autoComplete
                    includeInputInList
                    id="uomList"
                    name="uomList"
                    hintText="UOM"
                    //style={{ width: "95%" }}
                    options={uomList}
                    onChange={this.handleUomChange}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="UOM"
                        required
                        name="uomList"
                        id="uomList"
                        margin="normal"
                        style={{ width: "100%" }}
                        //className={classes.textField}
                        value={this.state.process.uomList}
                      // error={this.state.errors.stockID}
                      // helperText={
                      //   this.state.errors.stockID
                      //     ? this.state.errors.stockID
                      //     : ""
                      />
                    )}
                  />
                </div>
                <div>
                  <FormControlLabel
                    className={classes.checkBox}
                    name="isWOProcess"
                    control={
                      <Checkbox
                        color="primary"
                        checked={process.isWOProcess}
                        value={process.isWOProcess}
                        onChange={this.handleChange}
                      />
                    }
                    label="Customised"
                  />
                  <FormControlLabel
                    className={classes.checkBox}
                    name="isActive"
                    control={
                      <Checkbox
                        color="primary"
                        checked={process.isActive}
                        value={process.isActive}
                        onChange={this.handleChange}
                      />
                    }
                    label="Is Active"
                  />
                  <FormControlLabel
                    className={classes.checkBox}
                    name="gstType"
                    control={
                      <Checkbox
                        color="primary"
                        checked={process.gstType}
                        value={process.gstType}
                        onChange={this.handleChange}
                      />
                    }
                    // label={process.gstType ? "Inclusive" : "Exclusive"}
                    label="Inclusive"
                  />
                </div>

                <Divider style={{ marginTop: "25px" }} />
                <div className="button-wrapper">
                  {this.state.loading ? (
                    ""
                  ) : (
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                          onClick={(e) =>
                            this.props.history.push("/processMaster")
                          }
                        >
                          CANCEL
                      </Button>
                      </div>
                    )}
                  <div style={{ marginLeft: "1em" }}>
                    {this.state.loading ? (
                      <CircularProgress />
                    ) : !this.state.process.processCategoryID ||
                      !this.state.process.processName ||
                      // !this.state.process.processDescription ||
                      !this.state.process.acct ||
                      !this.state.process.price ||
                      !this.state.process.uom ||
                      !this.state.process.pic ||
                      !this.state.process.rc ||

                      //!this.state.process.isWOProcess ||
                      // !this.state.process.isActive ||
                      //!this.state.process.gstType ? (
                      !this.state.process.outWorkerClaimRate ? (
                          <Button
                            onClick={this.handleSubmit}
                            disabled
                            //autoFocus
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                          >
                            SUBMIT
                          </Button>
                        ) : (
                          <Button
                            onClick={this.handleSubmit}
                            variant="contained"
                            color="primary"
                            //disableElevation
                            style={{ boxShadow: "none" }}
                          >
                            SUBMIT
                          </Button>
                        )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(addProcess));
