import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import Divider from "@material-ui/core/Divider";
import { resourceValidation } from "../../../shared/resource";
import { withRouter } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import Validator from "../../../shared/validator";
import { createProcessRate } from "../../masters/masterActions";
import { addStyles } from "../../../shared/styles";
import { getCustomerDropdown, getUOProcessNonDS } from "../../masters/masterActions";
class AddProcessRate extends React.Component {
  state = {
    customerList: [],
    customerObj: {},
    customerId: "",
    processList: [],
    processObj: {},
    processId: "",
    unitRate: "0.00",
  };

  componentDidMount() {
    this.props.handleSettingHeader("Process Rate");
    this.props.setSelectedList("masterDataOpen", "processRate");
    getCustomerDropdown().then((r) => {
      //console.log(r);
      this.getAllCustomer(r.data);
    });
    getUOProcessNonDS().then((r) => {
      this.getAllProcess(r.data);
    });
  }
  getAllCustomer = (list) => {
    let customerList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        customerList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(customerList, "customerList");
  };
  getAllProcess = (list) => {
    let processList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        processList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(processList, "processList");
  };
  sortDDList = (list, name) => {
    let z = list.sort(function (a, b) {
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ [name]: z });
  };
  handleAutocompleteCustomer = (e, val) => {
    this.setState({ customerId: val && val.key, customerObj: val && val });
  };
  handleAutocompleteProcess = (e, val) => {
    this.setState({ processId: val && val.key, processObj: val && val });
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClose = () => {
    // clean up state and errors
    this.setState({
      processTypeName: "",
      processTypeCode: "",
      errors: {},
    });
  };
  checkValidInput = () => {
    if (
      !this.state.processObj ||
      !this.state.customerId ||
      !this.state.unitRate
    )
      return true;
    else return false;
  };
  handleSubmit = (event) => {
    event.preventDefault();
    let reqBody = {};
    let loggedInId = localStorage.getItem("loggedInId");
    reqBody.customerID = this.state.customerId;
    reqBody.processID = this.state.processId;
    reqBody.unitRate = this.state.unitRate;
    reqBody.createdBy = loggedInId;
    createProcessRate(reqBody, this.props.history);
  };
  _onKeyPress(event) {
    const re = /[0-9a-zA-Z ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onAlphaKeyPress(event) {
    const re = /[a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onDecimalNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onDimensionKeyPress(event) {
    const re = /[0-9*]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  render() {
    let { classes } = this.props;
    let { processType } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Process Rate</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <Autocomplete
                      options={this.state.customerList}
                      value={this.state.customerObj}
                      onChange={this.handleAutocompleteCustomer}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          autoFocus
                          {...params}
                          label="Customer"
                          style={{
                            width: "93%",
                            fontSize: "11px !important",
                            marginLeft: ".5em",
                            marginRight: ".5em",
                            maxHeight: "400px",
                          }}
                          margin="normal"
                          required
                        />
                      )}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <Autocomplete
                      options={this.state.processList}
                      value={this.state.processObj}
                      onChange={this.handleAutocompleteProcess}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Process"
                          style={{
                            width: "93%",
                            fontSize: "11px !important",
                            // marginLeft: "-.8em",
                            marginRight: ".5em",
                            maxHeight: "400px",
                          }}
                          // name="plannerId"
                          margin="normal"
                          required
                        />
                      )}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <TextField
                      inputProps={{
                        maxLength: 12,
                      }}
                      required
                      //onKeyPress={this._onAlphaKeyPress}
                      style={{
                        width: "93%",
                        fontSize: "11px !important",
                        marginLeft: "-.4em",
                        marginRight: ".5em",
                        maxHeight: "400px",
                        overflow: "auto",
                      }}
                      label="Unit Rate"
                      name="unitRate"
                      margin="normal"
                      value={this.state.unitRate}
                      onChange={this.handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">$S</InputAdornment>
                        ),
                      }}
                      onKeyPress={this._onDecimalNumberKeyPress}
                    />
                  </div>
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/processRate")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {this.checkValidInput() ? (
                      <Button
                        onClick={this.handleSubmit}
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddProcessRate));
