import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import {Autocomplete} from "@material-ui/lab" ;
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { postProvince, getCountryList } from "../../masters/masterActions";
import { addStyles } from "../../../shared/styles";
const validateNotRequired100 = new Validator("maxlength100AllAllow");
const onlyNumbers = new Validator("onlyNumbers");

class AddProvince extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      province: {
        provinceName: "",
        countryName: ""
      },
      errors: {},
      provinceNameValid: false,
      countryNameValid: false,
      descriptionValid: false,
      orderNoValid: false,
      countryList: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Province");
    this.props.setSelectedList("masterDataOpen", "province");
    getCountryList().then(r => {
      dispatch({
        type: "getCountryList",
        newStore: r.data
      });
      this.getAllCountries(r.data);
    });
    // getCountryList().then(r => {
    //   let newCountryList = [];
    //   r.data &&
    //     r.data.map(d => {
    //       newCountryList.push({
    //         countryID: d.countryID,
    //         countryCode: d.countryCode,
    //         countryName: d.countryCode + "-" + d.countryName,
    //         zoneID: d.zoneID,
    //         zoneName: d.zoneName,
    //         zoneCode: d.zoneCode,
    //         dhlZoneID: d.zoneCode,
    //         dhlZoneName: d.zoneCode,
    //         dhlZoneCode: d.dhlZoneCode,
    //         alternateCountryCode: d.alternateCountryCode,
    //         alternateCountryName: d.alternateCountryName,
    //         isActive: d.isActive,
    //         createdByID: d.createdByID,
    //         createdBy: d.createdBy,
    //         createdDate: d.createdDate,
    //         modifiedByID: d.modifiedByID,
    //         modifiedBy: d.modifiedBy,
    //         modifiedDate: d.modifiedDate
    //       });
    //     });

    //   r.data &&
    //     this.setState({
    //       countryList: newCountryList
    //         .filter(x => x.isActive === true)
    //         .sort((a, b) =>
    //           //a.countryName.toLowerCase() > b.countryName.toLowerCase() ? 1 : -1
    //           a.countryName.toLowerCase() > b.countryName.toLowerCase() ? 1 : -1
    //         )
    //     });
    // });
  }
  getAllCountries = list => {
    let countryList = [];
    list &&
      list.length > 0 &&
      list.map(c =>
        c.isActive
          ? countryList.push({
              key: c.countryID,
              value: c.countryCode + "-" + c.countryName,
              active: c.isActive
            })
          : countryList
      );
    this.setState({
      countryList: countryList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      )
    });
  };
  handleChange = event => {
    const { province } = this.state;
    province[event.target.name] = event.target.value;
    this.setState({ province });

    this.validateField(event.target.name, event.target.value);
  };

  handleCountryChange = (event, values) => {
    const { province } = this.state;
    if (values === null) {
      province["countryName"] = "null";
    } else {
      let countryNameID = values.key;
      province["countryName"] = countryNameID;
    }
    this.setState({ province });

    this.validateField("countryName", province["countryName"]);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let provinceNameValid = this.state.provinceNameValid;
    let countryNameValid = this.state.countryNameValid;
    switch (fieldName) {
      case "provinceName":
        if (value !== "") {
          provinceNameValid = validateNotRequired100(value);
          fieldValidationErrors.provinceName = provinceNameValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          provinceNameValid = false;
          fieldValidationErrors.provinceName = "";
        }
        break;
      case "countryName":
        if (value !== "null") {
          countryNameValid = onlyNumbers(value);
          fieldValidationErrors.countryName = countryNameValid
            ? ""
            : " Please select Country";
        } else {
          countryNameValid = false;
          fieldValidationErrors.countryName = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        provinceNameValid: provinceNameValid,
        countryNameValid: countryNameValid
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.provinceNameValid && this.state.countryNameValid
    });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      provinceName: "",
      countryName: "",
      errors: {}
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    if (this.validateForm()) {
      const { province } = this.state;

      let provinceName = province.provinceName;
      let countryID = province.countryName;
      let createdBy = localStorage.loggedInId;

      let reqBody = { countryID, provinceName, createdBy };
      postProvince(reqBody).then(r => {
        this.props.history.push("/Province");
        //toastr.success("Province Created Successfully");
      });
    }
  };
  render() {
    let { classes } = this.props;
    let { province, countryList } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Province</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="countryName"
                    name="countryName"
                    hintText="country Name"
                    className={classes.comboBox}
                    options={countryList}
                    onChange={this.handleCountryChange}
                    getOptionLabel={option => option.value}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Country"
                        name="countryName"
                        id="countryName"
                        margin="normal"
                        required
                        style={{ width: "100%" }}
                        value={province.countryName}
                        error={this.state.errors.countryName}
                        helperText={
                          this.state.errors.countryName
                            ? this.state.errors.countryName
                            : ""
                        }
                      />
                    )}
                  />
                  <TextField
                    required
                    label="Province Name"
                    name="provinceName"
                    inputProps={{
                      maxLength: 50
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={province.provinceName}
                    onChange={this.handleChange}
                    error={this.state.errors.provinceName}
                    helperText={
                      this.state.errors.provinceName
                        ? this.state.errors.provinceName
                        : ""
                    }
                  />
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={e => this.props.history.push("/province")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddProvince));
