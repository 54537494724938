import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { resourceValidation } from "../../../shared/resource";
import { StateContext } from "../../../shared/globalState";
import { getDepartment, updateDepartment } from "../masterActions";
import { addStyles } from "../../../shared/styles";
const validateMaxMinLength2 = new Validator("maxmin2length");

class EditDepartment extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      departmentCode: "",
      departmentName: "",
      errors: {},
      departmentCodeValid: false,
      departmentNameValid: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    let deptId = this.props.match.params.departmentId;
    const dispatch = this.context[1];
    getDepartment(deptId).then(r => {
      dispatch({
        type: "getDept",
        newStore: r
      });
      this.setState({
        departmentCode: r.departmentCode,
        departmentName: r.departmentName
      });
    });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.validateField(e.target.name, e.target.value);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let departmentCodeValid = this.state.departmentCodeValid;
    let departmentNameValid = this.state.departmentNameValid;
    switch (fieldName) {
      case "departmentCode":
        departmentCodeValid = validateMaxMinLength2(value);
        fieldValidationErrors.departmentCode = departmentCodeValid
          ? ""
          : resourceValidation.onlyAlphabet;
        break;
      case "departmentName":
        if (value !== "") {
          departmentNameValid = true;
          fieldValidationErrors.departmentName = "";
        } else {
          departmentNameValid = false;
          fieldValidationErrors.departmentName = departmentNameValid
            ? ""
            : "Please enter department name";
        }
        // departmentNameValid = validateMaxLength100(value);
        // fieldValidationErrors.departmentName = departmentNameValid ? '' : resourceValidation.onlyAlphabet;
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        departmentCodeValid: departmentCodeValid,
        departmentNameValid: departmentNameValid
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({ formValid: this.state.departmentNameValid });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      departmentCode: "",
      departmentName: "",
      errors: {}
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    let deptId = this.props.match.params.departmentId;
    let departmentCode = this.state.departmentCode;
    let departmentName = this.state.departmentName;
    let modifiedBy = localStorage.loggedInId;
    let data = { departmentCode, departmentName, modifiedBy };
    updateDepartment(deptId, data, this.props.history);
  };
  render() {
    let { classes } = this.props;
    let { departmentCode, departmentName } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Deparment</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    disabled
                    variant="filled"
                    label="Deparment Code"
                    name="departmentCode"
                    inputProps={{
                      maxLength: 50
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={departmentCode}
                    onChange={this.handleChange}
                    error={this.state.errors.departmentCode}
                    helperText={
                      this.state.errors.departmentCode
                        ? this.state.errors.departmentCode
                        : ""
                    }
                  />
                  <TextField
                    required
                    inputProps={{
                      maxLength: 100
                    }}
                    autoComplete="off"
                    label="Department Name"
                    name="departmentName"
                    margin="normal"
                    className={classes.textField}
                    value={departmentName}
                    onChange={this.handleChange}
                    error={this.state.errors.departmentName}
                    helperText={
                      this.state.errors.departmentName
                        ? this.state.errors.departmentName
                        : ""
                    }
                  />
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={e => this.props.history.push("/department")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditDepartment));
