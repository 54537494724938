import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";

import {
  TextField,
  Button,
  Divider,
  FormControlLabel,
  Typography,
  InputBase,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import {
  getCustomerPPIList,
  getCustomerDDList,
} from "../../masters/masterActions";
import {
  postWorkOrder,
  getWorkOrder,
  updateWorkOrder,
  getChooseWorkOrderList,
  GetJobGroupsListByWOID,
  getWorkOrderStatusChangedCriteria,
  closeWOBasedOnStatus,
  cancelWOBasedOnStatus,
  getJobTypeList
} from "./action";
import { Autocomplete } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SearchIcon from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import {
  getCustomerList,
  getServiceList,
  getStatusList,
} from "../../masters/masterActions";
import { getUserList, getPlannerList } from "../../userAccess/userAccessAction";
import styles from "./workOrder.module.css";
const useTabstyles = (theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    textTransform: "none",
    minWidth: 72,
    fontSize: "18px",
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    fontFamily: ["Roboto"].join(","),
    "&:hover": {
      opacity: 1,
    },
    "&$selected": {
      color: "#000",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {},
  },
  search: {
    position: "relative",
    minWidth: "500px",
    display: "flex",
  },
  searchIcon: {
    position: "absolute",
    right: "0",
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "31%",
    fontSize: "11px !important",
  },
  comboBox: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "31%",
    fontSize: "11px !important",
  },
  checkBox: {
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    marginRight: "1.5em !important",
  },
});

class WorkOrder extends Component {
  constructor() {
    super();
    this.state = {
      visibleCancelButton: false,
      woLoading: true,
      clacelOrCloseDialogOpen: false,
      submittingMode: "",
      clone: false,
      append: false,
      customerList: [],
      customerPPIList: [],
      jobTypeList: [],
      plannerList: [],
      statusList: [],
      serviceList: [],
      chooseWOList: [],
      filteredList: [],
      initialFilteredList: [],
      selected: {},
      filterstr: "",
      formValid: false,
      changesUpdated: false,
      woStatus: "new",
      woSearchSubmit: "",
      chooseWoOpen: false,
      errors: {
        projectTitle: "",
        contactPerson: "",
        billingAddress: "",
        billingAccountNo: "",
      },
      workOrderValue: {
        jobTypeId: "",
        jobTypeObj: null,
        workOrderID: null,
        woSuffix: "",
        workOrderNumber: "",
        customerID: null,
        customer: "",
        customerPPI: "",
        customerPPIID: null,
        planner: "",
        plannerID: 0,
        projectTitle: "",
        remarks: "",
        clientReference: "",
        status: "",
        statusID: null,
        service: {
          serviceID: 1,
          serviceCode: "PPI",
          serviceName: "Postage Paid Impression",
          tbL_PGSCustomerPPI: [],
        },
        serviceID: 1,
        contactPerson: "",
        billingAccountNo: "",
        billingAddress: "",
        isActive: true,
      },
    };
  }

  componentDidMount() {
    this.props.handleDrawerClose();
    if (
      this.props.workOrderID !== undefined ||
      this.props.workOrderMode === null
    ) {
      getWorkOrder(this.props.workOrderID).then((r) => {
        let val = "";
        let id = "";
        if (r.planner !== null) {
          val = r.planner.fName + " " + r.planner.lName;
          id = r.plannerID;
        }
        if (r.customerID) {
          getCustomerPPIList(r.customerID).then((rr) => {
            this.setState({ customerPPIList: rr.data });
          });
        }
        getJobTypeList().then(xy => {
          this.setState({ jobTypeList: xy.data });
          let jtO = {}
          xy.data && xy.data.length > 0 && xy.data.map(jt => {
            if (jt.id === r.jobType)
              jtO = jt
          })
          let newObj = { ...r, planner: { id: id, text: val, value: "" }, jobTypeObj: jtO, jobTypeId: r.jobType, };
          this.setState({ workOrderValue: newObj, formValid: true });
        })
      });
      // {
      // console.log("bhbbhjhjjh")
      getWorkOrderStatusChangedCriteria(this.props.workOrderID).then(r => {
        // console.log(r)
        if (r)
          getStatusList().then((r) => {

            r &&
              this.setState({
                statusList: r.sort((a, b) =>
                  a.genStatusName.toLowerCase() > b.genStatusName.toLowerCase()
                    ? 1
                    : -1
                ),
              });
          });
        else
          getStatusList().then((r) => {
            // console.log(this.props, r)
            let x = []
            if (r) {
              x = r.sort((a, b) =>
                a.genStatusName.toLowerCase() > b.genStatusName.toLowerCase()
                  ? 1
                  : -1
              )
              x.splice(1, 1)
              this.setState({ statusList: x })
            }

            // this.setState({
            //   statusList: ,
            // });
            // console.log(x)
          });


      })
      // }
    }
    else {
      getJobTypeList().then(xy => {
        this.setState({ jobTypeList: xy.data });
      })
    }

    getCustomerDDList().then((r) => {
      r.data &&
        this.setState({
          customerList: r.data
            //.filter(x => x.isActive === true)
            .sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
    });

    // getStatusList().then((r) => {
    //   console.log(this.props, r)
    //   r &&
    //     this.setState({
    //       statusList: r.sort((a, b) =>
    //         a.genStatusName.toLowerCase() > b.genStatusName.toLowerCase()
    //           ? 1
    //           : -1
    //       ),
    //     });
    // });

    getPlannerList().then((r) => {
      r.data &&
        this.setState({
          plannerList:
            r.data &&
            r.data
              // .filter(x => x.isActive === true)
              .sort((a, b) =>
                a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
              ),
        });
    });

    getServiceList().then((r) => {
      r.data &&
        this.setState(
          {
            serviceList:
              r &&
              r.data &&
              r.data.sort((a, b) =>
                a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
              ),
          }
          // () => {
          //   let newserviceList = [...this.state.serviceList];
          //   // newserviceList.unshift({
          //   //   serviceCode: "None",
          //   //   serviceID: null
          //   // });
          //   this.setState({ serviceList: newserviceList });
          // }
        );
    });
  }

  getAllFilteredList = async (list, modalMode) => {
    if (modalMode === "clone")
      await this.setState({
        filteredList:
          list && list.reduce((a, o) => (a.push(o), a), []),
        initialFilteredList:
          list && list.reduce((a, o) => (a.push(o), a), []),
        chooseWOList:
          list && list.reduce((a, o) => (a.push(o), a), []),
        woLoading: false
      });
    else
      await this.setState({
        filteredList:
          list && list.reduce((a, o) => (o.status == "Open" && a.push(o), a), []),
        initialFilteredList:
          list && list.reduce((a, o) => (o.status == "Open" && a.push(o), a), []),
        chooseWOList:
          list && list.reduce((a, o) => (o.status == "Open" && a.push(o), a), []),
        woLoading: false
      });
  };

  clearWorkOrderValues = () => {
    this.setState({
      workOrderValue: {
        customerID: 0,
        customer: "",
        customerPPI: "",
        customerPPIID: null,
        planner: "",
        plannerID: null,
        projectTitle: "",
        remarks: "",
        clientReference: "",
        jobTypeID: "",
        status: "",
        statusID: null,
        service: "",
        serviceID: null,
        contactPerson: "",
        billingAccountNo: "",
        billingAddress: "",
      },
    });
  };

  handleClick = (event, row) => {
    if (event.target.checked) {
      this.setState({ selected: row });
    } else {
      this.setState({ selected: {} });
    }
  };

  handlechooseWoOpenClickOpen = () => {
    this.setState({ woLoading: true, visibleCancelButton: false })
    this.clearWorkOrderValues();
    getChooseWorkOrderList().then((r) => {
      this.getAllFilteredList(r.data, "append");
    });
    this.setState({ woStatus: "choose", chooseWoOpen: true, append: true });
  };

  handlechooseWoOpenClose = () => {
    this.setState({
      filteredList: [],
      initialFilteredList: [],
      selected: {},
      filterstr: "",
      chooseWoOpen: false,
      append: false,
      visibleCancelButton: true,
    }
      // , () => {
      //   this.handleNewWorkOrder()
      // }
    );
  };

  handlechooseWoSubmit = () => {
    let selectedWo = this.state.selected.workOrderID;
    getWorkOrder(selectedWo).then((r) => {
      getCustomerPPIList(r.customerID).then((r) => {
        this.setState({ customerPPIList: r.data });
      });
      let jtO = {}
      this.state.jobTypeList && this.state.jobTypeList.length > 0 && this.state.jobTypeList.map(jt => {
        if (jt.id === r.jobType)
          jtO = jt
      })
      let val = this.state.plannerList.find((p) => p.id == r.plannerID);
      let newObj = { ...r, planner: val, jobTypeObj: jtO, jobTypeId: r.jobType };

      this.setState({
        workOrderValue: newObj,
        formValid: true,
      });
    });

    this.setState({
      woStatus: "submit",
      filterstr: "",
      chooseWoOpen: false,
      changesUpdated: true,
    });
    if (this.state.woStatus === "choose") {
      this.setState({ woSearchSubmit: "chooseSubmit" });
    } else if (this.state.woStatus === "clone") {
      this.setState({ woSearchSubmit: "cloneSubmit" });
    }
  };
  handleOpenWOCancelOrCloseDialog = (e, submittingMode) => {
    this.setState({ clacelOrCloseDialogOpen: true, submittingMode })
  }
  handleWOCloseOrCancel = (event) => {
    let workOrderValue = this.state.workOrderValue;
    let lId = localStorage.loggedInId;
    let selectedWo = workOrderValue.workOrderID;
    if (this.state.submittingMode === "closeWO") {
      // this.state.statusList && this.state.statusList.length > 0 && this.state.statusList.map(sc => {
      //   if (sc.genStatusCode === "WCLO")
      //     statusID = sc.genStatusID
      // })
      closeWOBasedOnStatus(selectedWo, lId, this.props.history)
    }
    if (this.state.submittingMode === "cancelWO") {
      //   this.state.statusList && this.state.statusList.length > 0 && this.state.statusList.map(sc => {
      //     if (sc.genStatusCode === "WCAN")
      //       statusID = sc.genStatusID
      //   })
      cancelWOBasedOnStatus(selectedWo, lId, this.props.history)
    }
    this.handleCloseWOCancelOrCloseDialog()
  }
  handleCloseWOCancelOrCloseDialog = () => {
    this.setState({ clacelOrCloseDialogOpen: false })

  }
  handleNewWoSubmit = (event, submittingMode) => {
    let workOrderValue = this.state.workOrderValue;
    let statusID = workOrderValue.statusID
    let customerID = workOrderValue.customerID;
    let contactPerson = workOrderValue.contactPerson;
    let customerPPIID = workOrderValue.customerPPIID;
    let projectTitle = workOrderValue.projectTitle;
    let plannerID = workOrderValue.plannerID;
    let remarks = workOrderValue.remarks;
    let clientReference = workOrderValue.clientReference;
    let jobTypeID = workOrderValue.jobTypeId;
    // let statusID = workOrderValue.statusID;
    let serviceID = workOrderValue.serviceID;
    let billingAddress = workOrderValue.billingAddress;
    let billingAccountNo = workOrderValue.billingAccountNo;
    let createdBy = localStorage.loggedInId;
    let modifiedBy = localStorage.loggedInId;
    let isChooseWorkOrder = false;
    let chooseenJobGroupID =
      this.state.selected && this.state.selected.jobGroupID;
    let isActive = true;
    let reqBody = {};

    if (this.state.changesUpdated) {
      if (this.props.workOrderMode === "edit") {
        isChooseWorkOrder = false;
        chooseenJobGroupID = 0;
        reqBody = {
          customerID,
          contactPerson,
          projectTitle,
          plannerID,
          remarks,
          clientReference,
          jobTypeID,

          statusID,
          serviceID,
          customerPPIID,
          billingAccountNo,
          billingAddress,
          isActive,
          modifiedBy,
          isChooseWorkOrder,
          chooseenJobGroupID,
        };
        let selectedWo = workOrderValue.workOrderID;
        updateWorkOrder(
          selectedWo,
          reqBody,
          this.props.history,
          this.props.workOrderMode,
          this.props.location.state.jobGroupID
        );
      } else {
        if (this.state.woStatus === "new") {
          statusID = 0;
          reqBody = {
            customerID,
            contactPerson,
            projectTitle,
            plannerID,
            remarks,
            clientReference,
            jobTypeID,
            statusID,
            serviceID,
            customerPPIID,
            billingAccountNo,
            billingAddress,
            isActive,
            createdBy,
          };
          postWorkOrder(
            reqBody,
            this.props.history,
            this.props.workOrderMode,
            this.state.woSearchSubmit,
            this.state.woStatus
          ).then((r) => {
            this.setState({
              workOrderValue: {
                workOrderID: r.workOrderID,
              },
            });
          });
        } else {
          if (this.state.woSearchSubmit === "chooseSubmit") {
            isChooseWorkOrder = true;
            reqBody = {
              customerID,
              contactPerson,
              projectTitle,
              plannerID,
              remarks,
              clientReference,
              jobTypeID,
              statusID,
              serviceID,
              customerPPIID,
              billingAccountNo,
              billingAddress,
              isActive,
              modifiedBy,
              isChooseWorkOrder,
              chooseenJobGroupID,
            };
            let selectedWo = workOrderValue.workOrderID;
            updateWorkOrder(
              selectedWo,
              reqBody,
              this.props.history,
              this.props.workOrderMode,
              "",
              this.state.woSearchSubmit
            );
          }

          // add cloneJobGroupId and IsCloneWorkOrder
          else if (this.state.woSearchSubmit === "cloneSubmit") {
            let IsCloneWorkOrder = false; // boolena
            let CloneJobGroupID = 0; // integer

            reqBody = {
              customerID,
              contactPerson,
              projectTitle,
              plannerID,
              remarks,
              clientReference,
              jobTypeID,
              statusID,
              serviceID,
              customerPPIID,
              billingAccountNo,
              billingAddress,
              isActive,
              createdBy,
              IsCloneWorkOrder: true,
              CloneJobGroupID: chooseenJobGroupID,
            };
            postWorkOrder(
              reqBody,
              this.props.history,
              this.props.workOrderMode,
              this.state.woSearchSubmit
            ).then((r) => {
              this.setState({
                workOrderValue: {
                  workOrderID: r.workOrderID,
                },
              });
            });
          }
        }
      }
    } else {
      this.props.history.push({
        pathname: `/${this.props.workOrderMode}_WorkOrder`,
        search: "?workOrderID=" + this.props.workOrderID,
        state: {
          workOrderMode: this.props.workOrderMode,
          woMode: this.state.woSearchSubmit,
          jgFoundNo: this.props.location.state.jobGroupID
        },
      });
    }
  };

  handleCustPPIChange = (event, values) => {
    const { workOrderValue } = this.state;
    let customerPPIID = values && values.customerPPIID;
    workOrderValue["customerPPI"] = values;
    workOrderValue["customerPPIID"] = customerPPIID;
    workOrderValue["billingAccountNo"] = values.billingAccountNo;
    this.setState({ workOrderValue, changesUpdated: true });
    this.validateField("customerPPIID", customerPPIID);
    this.validateForm();
  };
  handleCustomerChange = async (event, values) => {
    const { workOrderValue } = this.state;
    let customerID = values && values.id;
    workOrderValue["customerPPIID"] = "";
    workOrderValue["billingAccountNo"] = "";
    await getCustomerPPIList(customerID).then((r) => {
      this.setState({ customerPPIList: r.data });
    });
    this.state.customerPPIList.forEach((a) => {
      workOrderValue["billingAccountNo"] = a.billingAccountNo;
      workOrderValue["contactPerson"] = a.contactPersonName;
      workOrderValue["billingAddress"] = a.billingAddress;
    });

    workOrderValue["customer"] = values;
    workOrderValue["customerID"] = customerID;
    workOrderValue["customerPPI"] = {};
    workOrderValue["customerPPIID"] = null;
    this.setState({ workOrderValue, changesUpdated: true });
    this.validateField("customerID", customerID);
    this.validateForm();
  };
  handleJobTypeChange = async (event, values) => {
    const { workOrderValue } = this.state;
    if (values) {
      workOrderValue["jobTypeObj"] = values;
      workOrderValue["jobTypeId"] = values.id;
    }
    else {
      workOrderValue["jobTypeObj"] = {};
      workOrderValue["jobTypeId"] = "";
    }
    this.setState({ workOrderValue, changesUpdated: true });
    this.validateForm();
  };
  handlePlannerChange = (event, values) => {
    const { workOrderValue } = this.state;
    let userID = values && values.id;
    workOrderValue["planner"] = values;
    workOrderValue["plannerID"] = userID;
    this.setState({ workOrderValue, changesUpdated: true });
    this.validateField("plannerID", userID);
    this.validateForm();
  };

  handleStatusChange = (event, values) => {
    const { workOrderValue } = this.state;
    let statusID = values && values.genStatusID;
    workOrderValue["status"] = values;
    workOrderValue["statusID"] = statusID;
    this.setState({ workOrderValue, changesUpdated: true });
    this.validateField("statusID", statusID);
    this.validateForm();
  };

  handleChange = (event) => {
    event.persist();
    const { workOrderValue } = this.state;
    workOrderValue[event.target.name] = event.target.value;
    this.validateField(event.target.name, event.target.value);
    this.validateForm();
    this.setState({ workOrderValue, changesUpdated: true });
  };

  handleServiceChange = (event, values) => {
    const { workOrderValue } = this.state;
    // console.log(values, this.state.serviceList)
    // let serviceID = values && values.serviceID;
    let serviceID = values && values.id;
    workOrderValue["service"] = values;
    workOrderValue["serviceID"] = serviceID;
    this.setState({ workOrderValue, changesUpdated: true });
    this.validateField("serviceID", serviceID);
    this.validateForm();
  };

  handleNewWorkOrder = () => {
    this.clearWorkOrderValues();
    this.props.location.state.woMode = "";
    this.setState({ woStatus: "new", woSearchSubmit: "", formValid: false, visibleCancelButton: false });
  };

  handleCloneWorkOrder = () => {
    this.setState({ woLoading: true, visibleCancelButton: false })

    getChooseWorkOrderList().then((r) => {
      this.getAllFilteredList(r.data, "clone");
    });
    this.setState({
      woStatus: "clone",
      woSearchSubmit: "",
      chooseWoOpen: true,
      clone: true,
    });
  };

  validateField = (fieldName, value) => {
    let fieldValidationErrors = this.state.errors;
    switch (fieldName) {
      case "projectTitle":
        let projectTitleValid = true;
        fieldValidationErrors.projectTitle = projectTitleValid
          ? ""
          : "Please enter project title";

        break;
      default:
        break;
    }

    this.setState(
      {
        errors: fieldValidationErrors,
      },
      this.validateForm
    );
  };

  validateForm = () => {
    let workOrderValue = this.state.workOrderValue;
    let customerValid =
      workOrderValue.customer !== "" &&
      workOrderValue.customerID !== "" &&
      workOrderValue.customerID !== 0;
    let plannnerValid =
      workOrderValue.planner !== "" &&
      workOrderValue.plannerID !== "" &&
      workOrderValue.plannerID !== 0;
    let statusValid =
      workOrderValue.status !== "" &&
      workOrderValue.statusID !== "" &&
      workOrderValue.statusID !== 0;
    let projectTitleValid = workOrderValue.projectTitle !== "";
    let billingAccountNoValid = workOrderValue.billingAccountNo !== "";

    if (this.state.woStatus === "new") {
      this.setState({
        formValid:
          customerValid &&
          plannnerValid &&
          projectTitleValid &&
          billingAccountNoValid,
      });
    } else {
      this.setState({
        formValid:
          customerValid &&
          plannnerValid &&
          statusValid &&
          projectTitleValid &&
          billingAccountNoValid,
      });
    }
    return this.state.formValid;
  };
  handleChangeSearchWO = (event) => {
    this.setState({ filterstr: event.target.value });
    if (event.target.value === "") {
      this.setState({
        filteredList: this.state.chooseWOList,
        initialFilteredList: this.state.chooseWOList,
      });
    }
  };

  handleSearchWO = () => {
    let filterstr = this.state.filterstr;
    let filter = this.state.initialFilteredList.filter((r) => {
      return (
        r.customerName.toLowerCase().includes(filterstr.toLowerCase()) ||
        r.workOrderNumber.toLowerCase().includes(filterstr.toLowerCase()) ||
        r.projectTitle.toLowerCase().includes(filterstr.toLowerCase()) ||
        r.jobGroupNumber.toLowerCase().includes(filterstr.toLowerCase())
      );
    });
    this.setState({ filteredList: filter });
  };
  onWorkOrderCancelButClicked = () => {
    // console.log(this.props.workOrderMode)
    GetJobGroupsListByWOID(this.props.workOrderID).then((r) => {
      if (r && r.length === 1) {
        this.props.history.push("/workOrders")
      }
      else if (this.props.workOrderMode === "view")
        // this.props.history.push("/view_WorkOrder?workOrderID=114")
        this.props.history.push({
          pathname: "/view_WorkOrder",
          search: "?workOrderID=" + this.props.workOrderID,
          state: { workOrderMode: this.props.workOrderMode },
        });
      else if (this.props.workOrderMode === "edit")
        // this.props.history.push("/view_WorkOrder?workOrderID=114")
        this.props.history.push({
          pathname: "/edit_WorkOrder",
          search: "?workOrderID=" + this.props.workOrderID,
          state: { workOrderMode: this.props.workOrderMode },
        });
      else if (this.props.workOrderMode === "create") {
        this.props.history.push("/workOrders")
      }
    });


  }

  render() {
    let {
      selected,
      woStatus,
      woSearchSubmit,
      chooseWoOpen,
      filteredList,
      initialFilteredList,
      workOrderValue,
      errors,
      customerPPIList,
      formValid,
      customerList,
      plannerList,
      statusList,
      serviceList,
    } = this.state;

    const {
      value,
      index,

      // chooseWOList,
      history,
      workOrderMode,
      workOrderID,
    } = this.props;
    let { classes } = this.props;
    const isSelected = (jobGroupID) => selected.jobGroupID === jobGroupID;
    // console.log(statusList)
    let cancelWOCheck = false
    let closeWOCheck = false
    statusList && statusList.length > 0 && statusList.map(sd => {
      if (sd.genStatusCode === "WCAN")
        cancelWOCheck = true
      if (sd.genStatusCode === "WCLO")
        closeWOCheck = true
    })
    return (
      <React.Fragment>
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.clacelOrCloseDialogOpen}
        >
          <div style={{ padding: "4em" }}>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>
              {" "}
              Are you sure you want to {this.state.submittingMode && this.state.submittingMode === "closeWO" ? "Close WO" : "Cancel WO"} ?
            </div>

            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={e => this.handleCloseWOCancelOrCloseDialog(e)}
                style={{ marginRight: "1em", boxShadow: "none" }}
              >
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={e => this.handleWOCloseOrCancel(e)}
              >
                {this.state.submittingMode === "closeWO" ? "Close" : "Cancel"}
              </Button>
            </div>
          </div>
        </Dialog>
        <div
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          role="tabpanel"
          aria-labelledby={`simple-tab-${index}`}
        >
          {workOrderMode === "create" && (
            <div className="button-wrapper">
              <div>
                {woStatus === "new" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={this.handleNewWorkOrder}
                  >
                    {" "}
                    NEW WORK ORDER
                  </Button>
                ) : (
                    <Button
                      // disabled
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={this.handleNewWorkOrder}
                    >
                      {" "}
                    NEW WORK ORDER
                    </Button>
                  )}
              </div>
              <div style={{ marginLeft: "1em" }}>
                {woStatus === "choose" || woSearchSubmit === "chooseSubmit" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={this.handlechooseWoOpenClickOpen}
                  >
                    {" "}
                    APPEND WORK ORDER
                  </Button>
                ) : (
                    <Button
                      //disabled
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={this.handlechooseWoOpenClickOpen}
                    >
                      {" "}
                    APPEND WORK ORDER
                    </Button>
                  )}
                {/* {filteredList.length > 0 && ( */}
                <Dialog
                  disableBackdropClick
                  disableEscapeKeyDown
                  open={chooseWoOpen}
                  onClose={this.handlechooseWoOpenClose}
                  maxWidth="lg"
                  aria-labelledby="form-dialog-title"
                >
                  {this.state.append ? (
                    <DialogTitle id="form-dialog-title">
                      Append Work Order
                    </DialogTitle>
                  ) : (
                      <DialogTitle id="form-dialog-title">
                        Clone Work Order
                      </DialogTitle>
                    )}

                  <DialogContent dividers>
                    <div className={classes.search}>
                      <TextField
                        autoFocus
                        margin="dense"
                        onChange={this.handleChangeSearchWO}
                        id="name"
                        autoComplete="off"
                        placeholder="Search Customer, Work Order Number, Work Order Title,"
                        // type="search"
                        fullWidth
                      />
                      <div className={classes.searchIcon}>
                        <IconButton
                          aria-label="view"
                          className={classes.iconHover}
                          onClick={this.handleSearchWO}
                        >
                          <SearchIcon />
                        </IconButton>
                      </div>
                    </div>

                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size={"small"}
                      aria-label="enhanced table"
                      stickyHeader
                      style={{
                        border: "1px solid rgb(234, 234, 234)",
                        background: "white",
                        display: "block",
                        height: "200px",
                        overflowY: "auto",
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="col"
                            padding="checkbox"
                            className="table-content-cell"
                          ></TableCell>
                          <TableCell
                            component="th"
                            scope="col"
                            padding="default"
                            className="table-content-cell"
                          >
                            {" "}
                            <span style={{ color: "#306dca" }}>
                              Customer
                            </span>{" "}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="col"
                            padding="default"
                            className="table-content-cell"
                            style={{ minWidth: "135px" }}
                          >
                            {" "}
                            <span style={{ color: "#306dca" }}>
                              Work Order Number
                            </span>{" "}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="col"
                            padding="default"
                            className="table-content-cell"
                            style={{ minWidth: "135px" }}
                          >
                            {" "}
                            <span style={{ color: "#306dca" }}>
                              Job Group Number
                            </span>{" "}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="col"
                            padding="default"
                            className="table-content-cell"
                            style={{ minWidth: "400px" }}

                          >
                            {" "}
                            <span style={{ color: "#306dca" }}>
                              Job Title
                            </span>{" "}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredList.length > 0 ? (
                          filteredList.map((row, index) => {
                            const isItemSelected = isSelected(row.jobGroupID);
                            return (
                              <TableRow
                                role="checkbox"
                                tabIndex={-1}
                                key={row.jobGroupID}
                              >
                                <TableCell padding="checkbox">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        onClick={(event) =>
                                          this.handleClick(event, row)
                                        }
                                      />
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="col"
                                  padding="default"
                                  className="table-content-cell"
                                  style={{ width: "160px" }}
                                >
                                  {row.customerName &&
                                    row.customerName.length > 14 ? (
                                      <Tooltip title={row.customerName}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.customerName}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.customerName}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="col"
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ width: "160px" }}
                                >
                                  {row.workOrderNumber &&
                                    row.workOrderNumber.length > 14 ? (
                                      <Tooltip title={row.workOrderNumber}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.workOrderNumber}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.workOrderNumber}
                                      </Typography>
                                    )}
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="col"
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ width: "160px" }}
                                >
                                  {row.jobGroupNumber &&
                                    row.jobGroupNumber.length > 14 ? (
                                      <Tooltip title={row.jobGroupNumber}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.jobGroupNumber}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.jobGroupNumber}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="col"
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ width: "160px" }}
                                >
                                  {row.jobGroupTitle &&
                                    row.jobGroupTitle.length > 14 ? (
                                      <Tooltip title={row.jobGroupTitle}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.jobGroupTitle}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.jobGroupTitle}
                                      </Typography>
                                    )}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                            <TableRow>
                              {this.state.woLoading ? <TableCell
                                colSpan={5}
                                style={{ textAlign: "center" }}
                              >
                                <LinearProgress />
                              </TableCell> : <TableCell
                                colSpan={5}
                                style={{ textAlign: "center" }}
                              >
                                  No Result Found
                            </TableCell>}
                            </TableRow>
                          )}
                      </TableBody>
                    </Table>
                  </DialogContent>
                  <DialogActions style={{ padding: "16px 24px" }}>
                    <Button
                      onClick={this.handlechooseWoOpenClose}
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                    >
                      CANCEL
                    </Button>
                    {Object.keys(selected).length === 0 ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handlechooseWoSubmit}

                      // className="enabled-button"
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          onClick={this.handlechooseWoSubmit}
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </DialogActions>
                </Dialog>
                {/* )} */}
              </div>
              <div style={{ marginLeft: "1em" }}>
                {woStatus === "clone" || woSearchSubmit === "cloneSubmit" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={this.handleCloneWorkOrder}
                  >
                    {" "}
                    CLONE WORK ORDER
                  </Button>
                ) : (
                    <Button
                      //disabled
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={this.handleCloneWorkOrder}
                    >
                      {" "}
                    CLONE WORK ORDER
                    </Button>
                  )}
              </div>

              {this.state.visibleCancelButton && <div style={{ marginLeft: "1em" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  // onClick={(e) => history.push("/workOrders")}
                  onClick={e => this.onWorkOrderCancelButClicked()}
                >
                  CANCEL
                  </Button>
              </div>}
            </div>

          )}

          {(woStatus === "new" || woStatus === "submit") && (
            <form onSubmit={this.handleNewWoSubmit} autoComplete="off">
              {woSearchSubmit === "chooseSubmit" && (
                <div style={{ width: "100%" }}>
                  <Typography component="div" className="blueBox">
                    <div className="blueBoxLabel">
                      <div>Work Order: </div>
                      <div className="blueBoxText">
                        {workOrderValue.workOrderNumber}
                      </div>
                    </div>
                    <div className="blueBoxLabel">
                      <div>Status: </div>
                      <div className="blueBoxText">
                        {workOrderValue.status.genStatusName}
                      </div>
                    </div>
                  </Typography>
                </div>
              )}
              <div className="container">
                <div>
                  <Autocomplete
                    autoComplete
                    className={classes.comboBox}
                    id="customerID"
                    disabled={
                      woSearchSubmit === "chooseSubmit" ||
                      // woSearchSubmit === "cloneSubmit" ||
                      workOrderMode === "edit" ||
                      workOrderMode === "view" ||
                      this.props.location.state.woMode === "chooseSubmit"
                      //  ||
                      // this.props.location.state.woMode === "cloneSubmit"
                    }
                    includeInputInList
                    options={customerList}
                    value={workOrderValue.customer}
                    onChange={this.handleCustomerChange}
                    getOptionLabel={(option) =>
                      option.customerName
                        ? option.customerName
                        : woSearchSubmit === "chooseSubmit"
                          ? option.customerName
                          : workOrderMode === "edit"
                            ? option.customerName
                            : workOrderMode === "view"
                              ? option.customerName
                              : option.text
                    }
                    // style={{
                    //   maxHeight:
                    //     workOrderMode === "edit" || workOrderMode === "view"
                    //       ? "400px"
                    //       : null
                    // }}
                    renderInput={(params) => (
                      <TextField
                        // autoFocus
                        className={
                          (workOrderMode === "edit" ||
                            workOrderMode === "view") &&
                          styles.comboBoxCustomer
                        }
                        inputProps={
                          ({
                            maxLength: 20,
                            paddingTop:
                              workOrderMode === "edit" ||
                              (workOrderMode === "view" && "10px"),
                          },
                          {
                            readOnly:
                              workOrderMode === "edit" ||
                                woSearchSubmit === "chooseSubmit" ||
                                // woSearchSubmit === "cloneSubmit" ||
                                workOrderMode === "view" ||
                                this.props.location.state.woMode ===
                                "chooseSubmit"
                                //  ||
                                // this.props.location.state.woMode === "cloneSubmit"
                                ? true
                                : false,
                          })
                        }
                        {...params}
                        variant={
                          woSearchSubmit === "chooseSubmit" ||
                            // woSearchSubmit === "cloneSubmit" ||
                            workOrderMode === "edit" ||
                            workOrderMode === "view" ||
                            this.props.location.state.woMode === "chooseSubmit"
                            // ||
                            // this.props.location.state.woMode === "cloneSubmit"
                            ? "filled"
                            : "standard"
                        }
                        label="Customer"
                        name="customerID"
                        margin="normal"
                        required
                        style={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",

                  }}
                >
                  <TextField
                    required
                    inputProps={{
                      maxLength: 150,
                    }}
                    label="Project Title"
                    id="title"
                    variant={
                      woSearchSubmit === "chooseSubmit" ||
                        // woSearchSubmit === "cloneSubmit" ||
                        // workOrderMode === "edit" ||
                        workOrderMode === "view"
                        // ||
                        // this.props.location.state.woMode === "chooseSubmit"
                        //  ||
                        // this.props.location.state.woMode === "cloneSubmit"
                        ? "filled"
                        : "standard"
                    }
                    name="projectTitle"
                    disabled={
                      woSearchSubmit === "chooseSubmit" ||
                      // woSearchSubmit === "cloneSubmit" ||
                      // workOrderMode === "edit" ||
                      workOrderMode === "view"
                      // ||
                      // this.props.location.state.woMode === "chooseSubmit"
                      // ||
                      // this.props.location.state.woMode === "cloneSubmit"
                    }
                    className={classes.textField}
                    margin="normal"
                    autoComplete="off"
                    onChange={this.handleChange}
                    value={workOrderValue.projectTitle}
                    error={errors.projectTitle}
                    helperText={errors.projectTitle ? errors.projectTitle : ""}
                  />
                  <div style={{ width: "50%" }}>
                    <Autocomplete
                      autoComplete
                      className={classes.comboBox}
                      id="customerID"
                      disabled={
                        // woSearchSubmit === "chooseSubmit" ||
                        // woSearchSubmit === "cloneSubmit" ||
                        // workOrderMode === "edit" ||
                        workOrderMode === "view"
                        // ||
                        // this.props.location.state.woMode === "chooseSubmit"
                        //  ||
                        // this.props.location.state.woMode === "cloneSubmit"
                      }
                      includeInputInList
                      options={this.state.jobTypeList}
                      value={workOrderValue.jobTypeObj || {}}
                      onChange={this.handleJobTypeChange}
                      // getOptionLabel={(option) =>
                      //   option.customerName
                      //     ? option.customerName
                      //     : woSearchSubmit === "chooseSubmit"
                      //       ? option.customerName
                      //       : workOrderMode === "edit"
                      //         ? option.customerName
                      //         : workOrderMode === "view"
                      //           ? option.customerName
                      //           : option.text
                      // }
                      getOptionLabel={(option) =>
                        option.value
                      }
                      // style={{
                      //   maxHeight:
                      //     workOrderMode === "edit" || workOrderMode === "view"
                      //       ? "400px"
                      //       : null
                      // }}
                      style={{ width: "61.9%" }}
                      renderInput={(params) => (
                        <TextField
                          // autoFocus
                          className={
                            (workOrderMode === "edit" ||
                              workOrderMode === "view") &&
                            styles.comboBoxCustomer
                          }
                          inputProps={
                            ({
                              maxLength: 20,
                              paddingTop:
                                workOrderMode === "edit" ||
                                (workOrderMode === "view" && "10px"),
                            },
                            {
                              readOnly:
                                // workOrderMode === "edit" ||
                                // woSearchSubmit === "chooseSubmit" ||
                                // woSearchSubmit === "cloneSubmit" ||
                                workOrderMode === "view" ||
                                  this.props.location.state.woMode ===
                                  "chooseSubmit"
                                  //  ||
                                  // this.props.location.state.woMode === "cloneSubmit"
                                  ? true
                                  : false,
                            })
                          }
                          {...params}
                          variant={
                            // woSearchSubmit === "chooseSubmit" ||
                            // woSearchSubmit === "cloneSubmit" ||
                            // workOrderMode === "edit" ||
                            workOrderMode === "view"
                              // ||
                              // this.props.location.state.woMode === "chooseSubmit"
                              // ||
                              // this.props.location.state.woMode === "cloneSubmit"
                              ? "filled"
                              : "standard"
                          }
                          label="Job Type"
                          name="customerID"
                          margin="normal"
                          // required
                          style={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <Autocomplete
                    autoComplete
                    className={classes.comboBox}
                    id="serviceID"
                    includeInputInList
                    disabled={
                      woSearchSubmit === "chooseSubmit" ||
                      // woSearchSubmit === "cloneSubmit" ||
                      // workOrderMode === "edit" ||
                      workOrderMode === "view" ||
                      this.props.location.state.woMode === "chooseSubmit"
                      // ||
                      // this.props.location.state.woMode === "cloneSubmit"
                    }
                    options={serviceList}
                    value={workOrderValue.service}
                    onChange={this.handleServiceChange}
                    getOptionLabel={(option) =>
                      option.serviceCode || option.value
                    }
                    renderInput={(params) => (
                      <TextField
                        inputProps={
                          ({
                            maxLength: 20,
                          },
                          {
                            readOnly:
                              workOrderMode === "view" ||
                                this.props.location.state.woMode ===
                                "chooseSubmit"
                                //  ||
                                // this.props.location.state.woMode === "cloneSubmit"
                                ? true
                                : false,
                          })
                        }
                        variant={
                          woSearchSubmit === "chooseSubmit" ||
                            // woSearchSubmit === "cloneSubmit" ||
                            // workOrderMode === "edit" ||
                            workOrderMode === "view" ||
                            this.props.location.state.woMode === "chooseSubmit"
                            // ||
                            // this.props.location.state.woMode === "cloneSubmit"
                            ? "filled"
                            : "standard"
                        }
                        {...params}
                        // inputProps={{
                        //   readOnly: workOrderMode === "view" ? true : false
                        // }}
                        label="Service"
                        name="serviceID"
                        margin="normal"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                  <Autocomplete
                    className={classes.comboBox}
                    autoComplete
                    includeInputInList
                    id="customerPPI"
                    options={customerPPIList}
                    disabled={
                      woSearchSubmit === "chooseSubmit" ||
                      // woSearchSubmit === "cloneSubmit" ||
                      // workOrderMode === "edit" ||
                      workOrderMode === "view" ||
                      this.props.location.state.woMode === "chooseSubmit"
                      // ||
                      // this.props.location.state.woMode === "cloneSubmit"
                    }
                    onChange={this.handleCustPPIChange}
                    value={workOrderValue.customerPPI}
                    getOptionLabel={(option) => option.ppiNumber}
                    renderInput={(params) => (
                      <TextField
                        inputProps={
                          ({
                            maxLength: 20,
                          },
                          {
                            readOnly:
                              // workOrderMode === "edit" ||
                              workOrderMode === "view" ||
                                this.props.location.state.woMode ===
                                "chooseSubmit"
                                // ||
                                // this.props.location.state.woMode === "cloneSubmit"
                                ? true
                                : false,
                          })
                        }
                        {...params}
                        className={
                          (
                            // workOrderMode === "edit" ||
                            workOrderMode === "view") &&
                          styles.comboBoxCustomer
                        }
                        variant={
                          woSearchSubmit === "chooseSubmit" ||
                            // woSearchSubmit === "cloneSubmit" ||
                            // workOrderMode === "edit" ||
                            workOrderMode === "view" ||
                            this.props.location.state.woMode === "chooseSubmit"
                            // ||
                            // this.props.location.state.woMode === "cloneSubmit"
                            ? "filled"
                            : "standard"
                        }
                        label="Customer PPI"
                        name="customerPPI"
                        id="customerPPI"
                        margin="normal"
                        style={{
                          width: "100%",
                        }}
                        error={errors.customerPPI}
                        helperText={
                          errors.customerPPI ? errors.customerPPI : ""
                        }
                      />
                    )}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  {workOrderMode === "view" || workOrderMode === "edit" && (
                    <Autocomplete
                      autoComplete
                      disableClearable
                      className={classes.comboBox}
                      id="statusID"
                      includeInputInList
                      options={statusList}
                      value={workOrderValue.status}
                      disabled={
                        woSearchSubmit === "chooseSubmit" ||
                        woSearchSubmit === "cloneSubmit" ||
                        workOrderMode === "view" ||
                        workOrderMode === "edit" ||
                        this.props.location.state.woMode === "chooseSubmit" ||
                        this.props.location.state.woMode === "cloneSubmit"
                      }
                      onChange={this.handleStatusChange}
                      getOptionLabel={(option) => option.genStatusName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // inputProps={
                          //   ({
                          //     maxLength: 20
                          //   },
                          //   {
                          //     readOnly:
                          //       workOrderMode === "edit" ||
                          //       workOrderMode === "view"
                          //         ? true
                          //         : false
                          //   })
                          // }
                          // inputProps={{
                          //   readOnly: workOrderMode === "view" ? true : false
                          // }}
                          // variant={
                          //   workOrderMode === "edit" ||
                          //     workOrderMode === "view"

                          //     ? "filled"
                          //     : "standard"
                          // }
                          label="Status"
                          name="statusID"
                          margin="normal"
                          style={{ width: "100%" }}
                          required
                        />
                      )}
                    />
                  )}
                  <Autocomplete
                    autoComplete
                    className={classes.comboBox}
                    id="plannerID"
                    disableClearable
                    disabled={
                      woSearchSubmit === "chooseSubmit" ||
                      // woSearchSubmit === "cloneSubmit" ||
                      // workOrderMode === "edit" ||
                      workOrderMode === "view" ||
                      this.props.location.state.woMode === "chooseSubmit"
                      //  ||
                      // this.props.location.state.woMode === "cloneSubmit"
                    }
                    includeInputInList
                    options={plannerList}
                    value={workOrderValue.planner}
                    onChange={this.handlePlannerChange}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        inputProps={
                          ({
                            maxLength: 20,
                          },
                          {
                            readOnly:
                              // workOrderMode === "edit" ||
                              workOrderMode === "view" ||
                                this.props.location.state.woMode ===
                                "chooseSubmit"
                                //  ||
                                // this.props.location.state.woMode === "cloneSubmit"
                                ? true
                                : false,
                          })
                        }
                        {...params}
                        variant={
                          woSearchSubmit === "chooseSubmit" ||
                            // woSearchSubmit === "cloneSubmit" ||
                            // workOrderMode === "edit" ||
                            workOrderMode === "view" ||
                            this.props.location.state.woMode === "chooseSubmit"
                            // ||
                            // this.props.location.state.woMode === "cloneSubmit"
                            ? "filled"
                            : "standard"
                        }
                        className={
                          (
                            // workOrderMode === "edit" ||
                            workOrderMode === "view") &&
                          styles.comboBoxCustomer
                        }
                        label="Planner"
                        //className={classes.textField1}
                        name="plannerID"
                        margin="normal"
                        style={{ width: "100%" }}
                        required
                      />
                    )}
                  />
                </div>
                <div>
                  <TextField
                    required
                    label="Billing Account Number"
                    id="billingAccountNo"
                    name="billingAccountNo"
                    className={classes.textField}
                    inputProps={{
                      maxLength: 20,
                    }}
                    disabled={
                      woSearchSubmit === "chooseSubmit" ||
                      // woSearchSubmit === "cloneSubmit" ||
                      // workOrderMode === "edit" ||
                      workOrderMode === "view" ||
                      this.props.location.state.woMode === "chooseSubmit"
                      // ||
                      // this.props.location.state.woMode === "cloneSubmit"
                    }
                    margin="normal"
                    autoComplete="off"
                    onChange={this.handleChange}
                    value={workOrderValue.billingAccountNo}
                    error={errors.billingAccountNo}
                    helperText={
                      errors.billingAccountNo ? errors.billingAccountNo : ""
                    }
                    variant={
                      woSearchSubmit === "chooseSubmit" ||
                        // woSearchSubmit === "cloneSubmit" ||
                        // workOrderMode === "edit" ||
                        workOrderMode === "view" ||
                        this.props.location.state.woMode === "chooseSubmit"
                        //  ||
                        // this.props.location.state.woMode === "cloneSubmit"
                        ? "filled"
                        : "standard"
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <TextField
                    label="Billing Address"
                    id="billingAddress"
                    name="billingAddress"
                    className={classes.textField}
                    inputProps={{
                      maxLength: 50,
                    }}
                    disabled={
                      woSearchSubmit === "chooseSubmit" ||
                      // woSearchSubmit === "cloneSubmit" ||
                      // workOrderMode === "edit" ||
                      workOrderMode === "view" ||
                      this.props.location.state.woMode === "chooseSubmit"
                      //  ||
                      // this.props.location.state.woMode === "cloneSubmit"
                    }
                    margin="normal"
                    autoComplete="off"
                    onChange={this.handleChange}
                    value={workOrderValue.billingAddress}
                    error={errors.billingAddress}
                    helperText={
                      errors.billingAddress ? errors.billingAddress : ""
                    }
                    variant={
                      woSearchSubmit === "chooseSubmit" ||
                        // woSearchSubmit === "cloneSubmit" ||
                        // workOrderMode === "edit" ||
                        workOrderMode === "view" ||
                        this.props.location.state.woMode === "chooseSubmit"
                        //  ||
                        // this.props.location.state.woMode === "cloneSubmit"
                        ? "filled"
                        : "standard"
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <TextField
                    label="Contact Person"
                    id="contactPerson"
                    name="contactPerson"
                    className={classes.textField}
                    inputProps={{
                      maxLength: 30,
                    }}
                    disabled={
                      woSearchSubmit === "chooseSubmit" ||
                      // woSearchSubmit === "cloneSubmit" ||
                      // workOrderMode === "edit" ||
                      workOrderMode === "view" ||
                      this.props.location.state.woMode === "chooseSubmit"
                      // ||
                      // this.props.location.state.woMode === "cloneSubmit"
                    }
                    margin="normal"
                    autoComplete="off"
                    onChange={this.handleChange}
                    value={workOrderValue.contactPerson}
                    error={errors.contactPerson}
                    helperText={
                      errors.contactPerson ? errors.contactPerson : ""
                    }
                    variant={
                      woSearchSubmit === "chooseSubmit" ||
                        // woSearchSubmit === "cloneSubmit" ||
                        // workOrderMode === "edit" ||
                        workOrderMode === "view" ||
                        this.props.location.state.woMode === "chooseSubmit"
                        // ||
                        // this.props.location.state.woMode === "cloneSubmit"
                        ? "filled"
                        : "standard"
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    multiline
                    rows={1}
                    rowsMax={4}
                    label="Special Instructions"
                    id="remarks"
                    name="remarks"
                    disabled={workOrderMode === "view"}
                    className={classes.textField}
                    inputProps={{
                      maxLength: 500,
                    }}
                    margin="normal"
                    autoComplete="off"
                    onChange={this.handleChange}
                    value={workOrderValue.remarks}
                  />
                  <TextField
                    // multiline
                    // rows={1}
                    // rowsMax={4}
                    label="Client & Reference"
                    id="clientReference"
                    name="clientReference"
                    disabled={workOrderMode === "view"}
                    className={classes.textField}
                    inputProps={{
                      maxLength: 500,
                    }}
                    margin="normal"
                    autoComplete="off"
                    onChange={this.handleChange}
                    value={workOrderValue.clientReference}
                  />
                </div>
              </div>

              <Divider
                style={{
                  marginTop: "25px",
                }}
              />
              <div className="button-wrapper">
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    // onClick={(e) => history.push("/workOrders")}
                    onClick={e => this.onWorkOrderCancelButClicked()}
                  >
                    CANCEL
                  </Button>
                </div>
                {workOrderMode === "edit" && closeWOCheck && <div style={{ marginLeft: "1em" }}>
                  <Button
                    disabled={!formValid}
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={e => this.handleOpenWOCancelOrCloseDialog(e, "closeWO")}
                  >
                    Close WO
                    </Button>
                </div>}
                {workOrderMode === "edit" && cancelWOCheck && <div style={{ marginLeft: "1em" }}>
                  <Button
                    disabled={!formValid}
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={e => this.handleOpenWOCancelOrCloseDialog(e, "cancelWO")}
                  >
                    Cancel WO
                    </Button>
                </div>}
                <div style={{ marginLeft: "1em" }}>
                  {!formValid ? (
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={this.handleNewWoSubmit}
                    >
                      NEXT
                    </Button>
                  ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleNewWoSubmit}
                      >
                        NEXT
                      </Button>
                    )}
                </div>
              </div>
            </form>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(useTabstyles)(WorkOrder));
{
  /* <Button
                  className={
                    woStatus === "new"
                      ? "enabled-tab-outline-button"
                      : "disabled-tab-outline-button"
                  }
                  onClick={this.handleNewWorkOrder}
                >
                  {" "}
                  NEW WORK ORDER
                </Button>
              </div>
              <div style={{ marginLeft: "1em" }}>
                <Button
                  className={
                    woStatus === "choose" || woSearchSubmit === "chooseSubmit"
                      ? "enabled-tab-outline-button"
                      : "disabled-tab-outline-button"
                  }
                  onClick={this.handlechooseWoOpenClickOpen}
                >
                  {" "}
                  APPEND WORK ORDER
                </Button> */
}
