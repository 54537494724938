import React, { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import { resourceValidation } from "../../../shared/resource";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import clone from "clone";
import { withRouter } from "react-router-dom";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AdvanceSearch from "./acronymSearch";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Typography from "@material-ui/core/Typography";
import { ExportCSV } from "../../../shared/ExportCSV";
import {
  getAcronymList,
  deleteAcronym,
  getFliteredAcronymList,
} from "../masterActions";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  ViewColumnIcon,
  ViewIcon,
  EditIcon,
  AddIcon,
  SearchIcon,
} from "../../../shared/customIcons";
import "../../app/App.css";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import {
  stableSort,
  getSorting,
  DialogActions,
} from "../../../shared/tableCommonFunctions";
import { StateContext } from "../../../shared/globalState";
import FormGroup from "@material-ui/core/FormGroup";
import LinearProgress from "@material-ui/core/LinearProgress";

const columns = [
  {
    name: "Active",
    id: 11,
  },
  {
    name: "Modified Date",
    id: 12,
  },
  {
    name: "Modified By",
    id: 13,
  },
  {
    name: "Created Date",
    id: 14,
  },
  {
    name: "Created By",
    id: 15,
  },
];

function createData(
  acronymID,
  acronymSuffix,
  acronymNumber,
  isPeriodical,
  specialInstruction,
  dimension,
  journalType,
  journalTitle,
  isInternal,
  officeName,
  businessUnit,
  isActive,
  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate
) {
  return {
    acronymID,
    acronymSuffix,
    acronymNumber,
    isPeriodical,
    specialInstruction,
    dimension,
    journalType,
    journalTitle,
    isInternal,
    officeName,
    businessUnit,
    isActive,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all Countrys" }}
          /> */}
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                //checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all Acronyms" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.code}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    //selectedAcronym,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handleDeleteAcronym,
    selected,
    exportDataValues,
    hendleVewColums,
    acronymList,
    isActive,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  //let userRole = localStorage.getItem("userRoleAccess");
  //console.log(props.roles);
  let acnArr = props.roles.filter((u) => u.menuName === "Acronym");
  let acnObj = acnArr[0];
  //console.log(acnObj);
  //const selectedAcronymID = selected && selected.length > 0 && selected[0];
  // acronymList.map(d => {
  //   delete d["acronymID"];
  // });
  let expData = clone(acronymList);

  expData.map((d) => {
    let Val = "No";
    if (d["isActive"]) {
      Val = "Yes";
    }
    d["Acronym Suffix"] = d["acronymSuffix"];
    d["Acronym Number"] = d["acronymNumber"];
    d["Periodical"] = d["isPeriodical"] ? "Yes" : "No";
    d["Special Instruction"] = d["specialInstruction"];
    d["Dimension"] = d["dimension"];
    d["Office Name"] = d["officeName"];
    d["Business Unit"] = d["businessUnit"];

    d["Journal Type"] = d["journalType"];
    d["Title Description"] = d["journalTitle"]
    d["Internal"] = d["isInternal"] ? "Yes" : "No";
    d["Active"] = Val;
    d["Modified Date"] = moment(d["modifiedDate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    );
    d["Modified By"] = d["modifiedBy"];
    d["Created Date"] = moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss");
    d["Created By"] = d["createdBy"];

    delete d["acronymID"];
    delete d["acronymNumber"];
    delete d["isPeriodical"];
    delete d["specialInstruction"];
    delete d["dimension"];
    delete d["journalType"];
    delete d["journalTitle"];
    delete d["isInternal"];
    delete d["officeName"];
    delete d["isActive"];
    delete d["businessUnit"];
    delete d["createdBy"];
    delete d["createdDate"];
    delete d["modifiedBy"];
    delete d["modifiedDate"];
    delete d["acronymSuffix"];
  });
  // const selectedUserId = selected[0];
  // const selectedUser = usersList.find(x => x.userId == selectedUserId);
  const selectedAcronymID = selected[0];
  const selectedAcronym = acronymList.find(
    (x) => x.acronymID === selectedAcronymID
  );

  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteAcronym = () => {
    handleDeleteAcronym();
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_acronym/${selectedAcronymID}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <Link to={`/edit_acronym/${selectedAcronymID}`}>
                      <IconButton
                        aria-label="edit"
                        className={classes.iconHover}
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={handleClickOpen}
                  disabled={!isActive}
                >
                  <DeleteIcon className={classes.iconHover} />
                </IconButton>
              </Tooltip>
            )}
            <Dialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="md"
            >
              <DialogTitle
                id="customized-dialog-title"
              //onClose={handleClose}
              >
                Delete Acronym
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "300px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1 ? " ?" : selectedAcronym.acronymSuffix + " ?"}
                    {/* {selectedAcronym.acronymSuffix} */}
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteAcronym}
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {props.searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  <React.Fragment>
                    {acnObj && acnObj.advanceSearchAction && (
                      <AdvanceSearch
                        onSearchClicked={props.onSearchClicked}
                        onRequestSucceed={props.onRequestSucceed}
                      />
                    )}
                    {/* <Tooltip title="Search">
                  <IconButton
                    aria-label="Search"
                    className={classes.iconHover}
                    onSearchClicked={props.onSearchClicked}
                    onRequestSucceed={props.onRequestSucceed}
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip> */}
                  </React.Fragment>
                )}

              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="Add"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/add_acronym")}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {acnObj && acnObj.exportAction && (
                <ExportCSV csvData={expData} fileName={"Acronym"} />
              )}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                hendleVewColums={hendleVewColums}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class AcronymMaster extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    acronymList: [],
    activeRows: 0,
    isActive: false,
    loading: false,
    refreshData: false,
    selectedAcronym: {},
    selectedShowColumns: [],
    exportDataValues: [],
    headCells: [
      {
        id: 1,
        code: "acronymSuffix",
        numeric: false,
        disablePadding: true,
        label: "Acronym Suffix",
        show: true,
      },
      {
        id: 2,
        code: "acronymNumber",
        numeric: false,
        disablePadding: false,
        label: "Acronym Number",
        show: true,
      },

      {
        id: 3,
        code: "isPeriodical",
        numeric: false,
        disablePadding: false,
        label: "Periodical",
        show: true,
      },
      {
        id: 4,
        code: "specialInstruction",
        numeric: false,
        disablePadding: false,
        label: "Special Instruction",
        show: true,
      },
      {
        id: 5,
        code: "dimension",
        numeric: false,
        disablePadding: false,
        label: "Dimension",
        show: true,
      },

      {
        id: 6,
        code: "officeName",
        numeric: false,
        disablePadding: false,
        label: "Office Name",
        show: true,
      },
      {
        id: 7,
        code: "businessUnit",
        numeric: false,
        disablePadding: false,
        label: "Business Unit",
        show: true,
      },
      {
        id: 8,
        code: "journalType",
        numeric: false,
        disablePadding: false,
        label: "Journal Type",
        show: true,
      },
      {
        id: 9,
        code: "journalTitle",
        numeric: false,
        disablePadding: false,
        label: "Title Description",
        show: true,
      },
      {
        id: 10,
        code: "isInternal",
        numeric: false,
        disablePadding: false,
        label: "Internal",
        show: true,
      },
      {
        id: 11,
        code: "isActive",
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true,
      },
      {
        id: 12,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 13,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 14,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 15,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };
  sortStateList = (list) => {
    let z = list.sort(function (a, b) {
      //console.log(a, b);
      let x = a.isActive && a.isActive;
      let y = b.isActive && b.isActive;
      let u = a.modifiedDate && a.modifiedDate;
      let v = b.modifiedDate && b.modifiedDate;
      if (x < y) {
        return 1;
      }
      if (u < v) {
        return -1;
      }
      if (x > y) {
        return -1;
      }
      if (u > v) {
        return 1;
      }
      return 0;
    });

    // console.log(z);
    this.setState({ rows: z });
  };

  componentDidMount() {
    // let x = [
    //   { status: "a", modifiedDate: "10/20/2020" },
    //   { status: "b", modifiedDate: "11/20/2010" },
    //   { status: "a", modifiedDate: "13/20/2040" },
    //   { status: "c", modifiedDate: "14/20/2070" },
    //   { status: "a", modifiedDate: "11/20/2090" }
    // ];
    // this.sortStateList(x);
    this.props.handleSettingHeader("Acronym");
    this.props.setSelectedList("masterDataOpen", "acronym");
    const dispatch = this.context[1];

    getAcronymList(window.localStorage.token).then((r) => {
      dispatch({
        type: "getAcronymList",
        newStore: r.data,
      });
      this.getAllAcronyms(r.data);
      this.exportDatas(r.data);
    });
    this.setState({ loading: true });
  }

  shouldComponentUpdate(p, s) {
    const { store } = this.context[0];
    if (store.rows && store.rows.length > 0)
      if (this.state.rows.length !== store.acronymList.length) {
        !this.state.searchClicked && this.getAllAcronyms(store.acronymList);
      }
    return true;
  }
  onRequestSucceed = (deleted) => {
    const [{ store }, dispatch] = this.context;
    getAcronymList().then((r) => {
      // console.log(r);
      dispatch({
        type: "getAcronymList",
        newStore: r.data,
      });
      if (deleted) this.setState({ searchClicked: false });
      this.getAllAcronyms(store.acronymList);
      // this.exportDatas(store.acronymList);
    });
  };
  exportDatas = (acronymList) => {
    //console.log(this.state.searchClicked);

    let list = acronymList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          AcronymSuffix: d.acronymSuffix,
          AcronymNumber: d.acronymNumber,
          Periodical: d.isPeriodical === true ? "Yes" : "No",
          SpecialInstruction: d.specialInstruction,
          Dimension: d.dimension,
          JournalType: d.journalType,
          journalTitle: d.journalTitle,
          Internal: d.isInternal === true ? "Yes" : "No",
          OfficeName: d.officeName,
          BusinessUnit: d.businessUnit,
          Active: d.isActive === true ? "Yes" : "No",
          CreatedBy: d.createdBy,
          CreatedDate: d.createdDate,
          ModifiedBy: d.modifiedBy,
          ModifiedDate: d.modifiedDate,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getAllAcronyms = (acronymList) => {
    let list = acronymList;
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return newRows.push(
          createData(
            d.acronymID,
            d.acronymSuffix,
            d.acronymNumber ? +d.acronymNumber : "",
            d.isPeriodical,
            d.specialInstruction,
            d.dimension,
            d.journalType,
            d.journalTitle,
            d.isInternal,
            d.officeName,
            d.businessUnit,
            d.isActive,
            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate
          )
        );
      });
    this.sortStateList(newRows);
    this.setState({
      acronymList: newRows,
      loading: false,
      selected: [],
      activeRows: newRows.filter((x) => x.isActive).map((n) => n.acronymID),
    });
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleSelectAllClick = (event) => {
    let { rows } = this.state;
    if (event.target.checked) {
      const newSelecteds = rows
        .filter((x) => x.isActive === true)
        .map((n) => n.acronymID);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, data) => {
    let acronymID = data.acronymID;
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(acronymID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, acronymID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        //selectedAcronym: data, //isActive : data.isActive
        selectedAcronym: data.acronymSuffix,

        selectedAcronymID: acronymID,
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        //selectedAcronym: {},
        selectedAcronym: data.acronymSuffix, //isActive : data.isActive
        selectedAcronymID: acronymID,
      });
    }
  };

  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleDeleteAcronym = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let deletionIds = selected.join(",");

    deleteAcronym(deletionIds, this.props.history).then((r) => {
      getAcronymList().then((r) => {
        // console.log(r);
        dispatch({
          type: "getAcronymList",
          newStore: r.data,
        });
        this.getAllAcronyms(r.data);
      });
      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };

  onSearchClicked = (
    reqBody
    // acronymSuffix,
    // acronymNumber,
    // officeName,
    // businessUnit,
    // isPeriodical
  ) => {
    this.setState({ searchClicked: true, page: 0 });
    // let reqBody = {
    //   acronymSuffix,
    //   acronymNumber,
    //   officeName,
    //   businessUnit,
    //   isPeriodical,
    // };
    getFliteredAcronymList(reqBody).then((r) => {
      //console.log(r);
      this.getAllAcronyms(r.data);
    });
  };

  clearSearch = () => {
    const dispatch = this.context[1];
    getAcronymList().then((r) => {
      dispatch({
        type: "getAcronymList",
        newStore: r.data,
      });
      this.getAllAcronyms(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  hendleVewColums = (e, id) => {
    let newheadCells = this.state.headCells;
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      rows,
      selectedAcronym,
      headCells,
      exportDataValues,
    } = this.state;
    const isSelected = (acronymID) => selected.indexOf(acronymID) !== -1;
    //console.log(this.props);
    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedAcronym={selectedAcronym}
            onSearchClicked={this.onSearchClicked}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteAcronym={this.handleDeleteAcronym}
            selected={this.state.selected}
            exportDataValues={exportDataValues}
            headCells={headCells}
            hendleVewColums={this.hendleVewColums}
            acronymList={clone(rows)}
            isActive={this.state.isActive}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {rows.length > 0 ? (
                    stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        // console.log(row);
                        const isItemSelected = isSelected(row.acronymID);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            key={index}
                            hover
                            onClick={(event) => this.handleClick(event, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.acronymSuffix}
                                </Typography> */}
                                {row.acronymSuffix &&
                                  row.acronymSuffix.length > 16 ? (
                                    <Tooltip
                                      title={row.acronymSuffix}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.acronymSuffix}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.acronymSuffix}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.acronymNumber}
                                </Typography> */}
                                {row.acronymNumber &&
                                  row.acronymNumber.length > 16 ? (
                                    <Tooltip
                                      title={row.acronymNumber}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.acronymNumber}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.acronymNumber}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.isPeriodical === true ? "Yes" : "No"}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.specialInstruction}
                                </Typography> */}
                                {row.specialInstruction &&
                                  row.specialInstruction.length > 16 ? (
                                    <Tooltip
                                      title={row.specialInstruction}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.specialInstruction}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.specialInstruction}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.dimension}
                                </Typography> */}
                                {row.dimension && row.dimension.length > 16 ? (
                                  <Tooltip
                                    title={row.dimension}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dimension}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dimension}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.officeName}
                                </Typography> */}
                                {row.officeName &&
                                  row.officeName.length > 16 ? (
                                    <Tooltip
                                      title={row.officeName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.officeName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.officeName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.businessUnit}
                                </Typography> */}
                                {row.businessUnit &&
                                  row.businessUnit.length > 16 ? (
                                    <Tooltip
                                      title={row.businessUnit}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.businessUnit}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.businessUnit}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.journalType}
                                </Typography> */}
                                {row.journalType &&
                                  row.journalType.length > 16 ? (
                                    <Tooltip
                                      title={row.journalType}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.journalType}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.journalType}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.journalType}
                                </Typography> */}
                                {row.journalTitle &&
                                  row.journalTitle.length > 16 ? (
                                    <Tooltip
                                      title={row.journalTitle}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.journalTitle}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.journalTitle}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.isInternal === true ? "Yes" : "No"}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.isActive ? "Yes" : "No"}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                align="left"
                                scope="row"
                                padding="default"
                                align="right"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.modifiedDate &&
                                    row.modifiedDate.length > 16 ? (
                                      <Tooltip
                                        title={moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {moment(row.modifiedDate).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                          )}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.modifiedBy}
                                </Typography> */}
                                {row.modifiedBy &&
                                  row.modifiedBy.length > 16 ? (
                                    <Tooltip
                                      title={row.modifiedBy}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedBy}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                align="left"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography
                                  noWrap
                                  style={{
                                    fontSize: "14px",
                                    textAlign: "left",
                                  }}
                                >
                                  {row.createdDate &&
                                    row.createdDate.length > 16 ? (
                                      <Tooltip
                                        title={moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {moment(row.createdDate).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                          )}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.createdBy}
                                </Typography> */}
                                {row.createdBy && row.createdBy.length > 16 ? (
                                  <Tooltip
                                    title={row.createdBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={8} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(AcronymMaster);
