import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { Checkbox } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AdvanceSearch from "./search";
import Dialog from "@material-ui/core/Dialog";
import clone from "clone";
//import ManageCountry from "./countryManage";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//import AdvanceSearch from "./countrySearch";
import DeleteLocalPostage from "./delete";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { getLocalPostageLists, getFilteredLocalPostage } from "../masterActions";
import { StateContext } from "../../../shared/globalState";
import Typography from "@material-ui/core/Typography";
//import CreateCountry from "./countryAdd";
import FormGroup from "@material-ui/core/FormGroup";
import {
  stableSort,
  getSorting,
  addZeros,
} from "../../../shared/tableCommonFunctions";
import moment from "moment";
import "../../app/App.css";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { ExportCSV } from "../../../shared/ExportCSV";
import {
  ViewColumnIcon,
  EditIcon,
  ViewIcon,
  AddIcon,
} from "../../../shared/customIcons";
import LinearProgress from "@material-ui/core/LinearProgress";

const columns = [
  {
    id: 8,
    name: "Active",
  },

  {
    id: 9,
    name: "Modified Date",
  },

  {
    id: 10,
    name: "Modified By",
  },

  {
    id: 11,
    name: "Created Date",
  },

  {
    id: 12,
    name: "Created By",
  },
];

function createData(
  customerCode,
  customerName,
  noCopiesFrom,
  noCopiesTo,
  weightFrom,
  weightTo,
  postageRate,
  id,
  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate,
  isActive
) {
  return {
    customerCode,
    customerName,
    noCopiesFrom,
    noCopiesTo,
    createdBy,
    weightFrom,
    weightTo,
    postageRate,
    id,
    createdDate,
    modifiedBy,
    modifiedDate,
    isActive,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ borderTop: "1px solid #eaeaea", background: "white" }}
          className="table-cell"
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "Select All Countrys" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.code}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )

            //);
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// const useToolbarStyles = makeStyles(theme => ({
//   root: {
//     paddingLeft: theme.spacing(2),
//     paddingRight: theme.spacing(1),
//     borderTop: "1px solid #eaeaea"
//   },
//   iconHover: {
//     "&:hover": {
//       color: blue[800]
//     }
//   },
//   highlight:
//     theme.palette.type === "light"
//       ? {
//           color: theme.palette.secondary.main,
//           backgroundColor: "transparent"
//         }
//       : {
//           color: theme.palette.text.primary,
//           backgroundColor: theme.palette.secondary.dark
//         },
//   title: {
//     flex: "1 1 100%"
//   }
// }));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleViewColumns,
    searchClicked,
    selected,
    localPostageList,
    exportDataValues,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  //const selectedlocalPostageID = selected && selected.length > 0 && selected[0];
  const selectedlocalPostageID = selected[0];
  const selectedLocalPostage = localPostageList.find(
    (x) => x.id === selectedlocalPostageID
  );
  let acnArr = props.roles.filter((u) => u.menuName === "Local Postage");
  let acnObj = acnArr[0];

  let expData = clone(localPostageList);
  expData.map((d) => {
    d["Customer Code"] = d["customerCode"];
    d["Customer Name"] = d["customerName"];
    d["No. Copies From"] = d["noCopiesFrom"];
    d["No. Copies To"] = d["noCopiesTo"];
    d["Weight From"] = d["weightFrom"];
    d["Weight To"] = d["weightTo"];
    d["Postage Rate"] = d["postageRate"];
    delete d["customerCode"];
    delete d["customerName"];
    delete d["noCopiesFrom"];
    delete d["noCopiesTo"];

    delete d["weightFrom"];

    delete d["weightTo"];

    delete d["postageRate"];

    d["Active"] = d["isActive"];
    d["Modified By"] = d["modifiedBy"];

    d["Modified Date"] = moment(d["modifiedDate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    );
    d["Created By"] = d["createdBy"];
    d["Created Date"] = moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss");

    delete d["isActive"];

    delete d["createdBy"];

    delete d["createdDate"];

    delete d["modifiedBy"];

    delete d["modifiedDate"];
    delete d["id"];
  });
  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_localPostage/${selectedlocalPostageID}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <Link to={`/edit_localPostage/${selectedlocalPostageID}`}>
                      <IconButton
                        aria-label="edit"
                        className={classes.iconHover}
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                {/* disabled = {!isActive}  */}
                <DeleteLocalPostage
                  // display={!isActive}
                  mode="delete"
                  selected={selected}
                  onRequestSucceed={props.onRequestSucceed}
                  count={props.selected.length}
                  customerName={selectedLocalPostage.customerName}
                  isActive={props.isActive}
                />
              </Tooltip>
            )}
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  acnObj &&
                  acnObj.advanceSearchAction && (
                    <AdvanceSearch
                      onSearchClicked={props.onSearchClicked}
                      onRequestSucceed={props.onRequestSucceed}
                    />
                  )
                )}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="add"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/add_localPostage")}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {acnObj && acnObj.exportAction && (
                <Tooltip title="Download">
                  <ExportCSV csvData={expData} fileName={"localPostage"} />
                </Tooltip>
              )}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                handleViewColumns={handleViewColumns}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    handleViewColumns,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => handleViewColumns(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class CountryMaster extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    activeRows: 0,
    isActive: false,
    localPostageList: [],
    loading: false,
    selectedLocalPostage: {},
    selectedLocalPostageID: 1,
    selectedShowColumns: [],
    searchPopupOpen: false,
    countrySearchValues: {},
    searchClicked: false,
    exportDataValues: [],
    headCells: [
      {
        code: "customerCode",
        id: 1,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Customer Code",
      },
      {
        code: "customerName",
        id: 2,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Customer Name",
      },
      {
        code: "noCopiesFrom",
        id: 3,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "No. of Copies From",
      },
      {
        code: "noCopiesTo",
        id: 4,
        numeric: false,
        disablePadding: false,
        label: "No. of Copies To",
        show: true,
      },
      {
        id: 5,
        code: "weightFrom",
        numeric: false,
        disablePadding: false,
        label: "Weight From (Kg)",
        show: true,
      },
      {
        id: 6,
        code: "weightTo",
        numeric: false,
        disablePadding: false,
        label: "Weight To (Kg)",
        show: true,
      },
      {
        id: 7,
        code: "postageRate",
        numeric: false,
        disablePadding: false,
        label: "Postage Rate (S$)",
        show: true,
      },
      {
        id: 8,
        code: "isActive",
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true,
      },
      {
        id: 9,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 10,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 11,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 12,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };
  handleViewColumns = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  componentDidMount() {
    this.props.handleSettingHeader("Local Postage");
    this.props.setSelectedList("masterDataOpen", "localPostage");

    const dispatch = this.context[1];
    getLocalPostageLists(window.localStorage.token).then((r) => {
      dispatch({
        type: "getLocalPostage",
        newStore: r.data,
      });
      this.getAllPostageList(r.data);
      this.exportDatas(r.data);
    });
    this.setState({ loading: true });
  }
  shouldComponentUpdate(s, p) {
    // const { store } = this.context[0];
    // if (
    //   this.state.rows &&
    //   store.localPostage &&
    //   this.state.rows.length !== store.localPostage.length
    // ) {
    //   !this.state.searchClicked && this.getAllPostageList(store.localPostage);
    // }
    // if (this.state.rows.length !== 0) {
    //   this.state.rows.map((cl, i) => {
    //     if (cl.isActive !== store.localPostage[i].isActive)
    //       !this.state.searchClicked &&
    //         this.getAllPostageList(store.localPostage);
    //   });
    // }
    // return true;

    // const { store } = this.context[0];
    // if (store.localPostage && store.localPostage.length > 0)
    //   if (this.state.localPostage.length !== store.localPostage.length) {
    //     !this.state.searchClicked && this.getAllPostageList(store.localPostage);
    //   }
    return true;
  }

  sortStateList = (list) => {
    let z = list.sort(function (a, b) {
      let x = a.isActive && a.isActive;
      let y = b.isActive && b.isActive;
      let u = a.modifiedDate && a.modifiedDate;
      let v = b.modifiedDate && b.modifiedDate;
      if (x < y) {
        return 1;
      }
      if (u < v) {
        return -1;
      }
      if (x > y) {
        return -1;
      }
      if (u > v) {
        return 1;
      }
      return 0;
    });
    // console.log(z);
    this.setState({ rows: z });
  };

  onRequestSucceed = (deleted) => {
    const [{ store }, dispatch] = this.context;
    getLocalPostageLists().then((r) => {

      if (deleted) this.setState({ searchClicked: false });
      this.getAllPostageList(r.data);
    });
  };
  exportDatas = (acronymList) => {
    let list = [...acronymList];
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          Customer: d.customerCode,
          CustomerName: d.customerName,
          NoCopiesFrom: d.noCopiesFrom,
          NoCopiesTo: d.noCopiesTo,
          WeightFrom: d.weightFrom,
          WeightTo: d.weightTo,
          PostageRate: d.postageRate,
          Active: d.isActive === true ? "Yes" : "No",
          CreatedBy: d.createdBy,
          CreatedDate: d.createdDate,
          ModifiedBy: d.modifiedBy,
          ModifiedDate: d.modifiedDate,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getAllPostageList = (lpList) => {
    let list = lpList;
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return newRows.push(
          createData(
            d.customerCode,
            d.customerName,
            d.noCopiesFrom,
            d.noCopiesTo,
            d.weightFrom,
            d.weightTo,
            d.postageRate,
            d.localPostageID,
            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate,
            d.isActive
          )
        );
      });
    this.sortStateList(newRows);
    this.setState({
      rows: newRows,
      selected: [],
      loading: false,
      activeRows: newRows.filter((x) => x.isActive).map((n) => n.id),
    });
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { rows } = this.state;
    if (event.target.checked) {
      const newSelecteds = rows
        .filter((x) => x.isActive === true)
        .map((n) => n.id);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, data) => {
    let id = data.id;
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedLocalPostage: data,
        // selectedLocalPostageID: data.id
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedLocalPostage: {},
        // selectedLocalPostageID: 0
      });
    }
  };
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleDeleteCountry = () => { };
  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchCountry = (handleSearchCountry) => {
    this.setState({ searchPopupOpen: false, searchClicked: true });
  };

  onSearchClicked = (reqBody) => {
    this.setState({ searchClicked: true, page: 0 });
    getFilteredLocalPostage(reqBody)
      .then((r) => {
        this.getAllPostageList(r.data);
      })
  };
  clearSearch = () => {
    const dispatch = this.context[1];
    getLocalPostageLists().then((r) => {
      dispatch({
        type: "getLocalPostage",
        newStore: r.data,
      });
      this.getAllPostageList(r.data);
    });
    this.setState({ searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      rows,
      selectedLocalPostage,
      selectedLocalPostageID,
      headCells,
      exportDataValues,
    } = this.state;

    const isSelected = (id) => selected.indexOf(id) !== -1;
    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedLocalPostage={selectedLocalPostage}
            selectedLocalPostageID={selectedLocalPostageID}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchCountry={this.handleSearchCountry}
            countrySearchValue={this.state.countrySearchValue}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteCountry={this.handleDeleteCountry}
            handleViewColumns={this.handleViewColumns}
            headCells={headCells}
            localPostageList={rows}
            selected={this.state.selected}
            onSearchClicked={this.onSearchClicked}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {rows.length > 0 ? (
                    stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              this.handleClick(
                                event,
                                // row.coustomerName,
                                row
                                //row.id
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            //key={row.id}
                            key={index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.coustomerName}
                                </Typography> */}
                                {row.customerCode &&
                                  row.customerCode.length > 16 ? (
                                    <Tooltip
                                      title={row.customerCode}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.customerCode}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.coustomerName}
                                </Typography> */}
                                {row.customerName &&
                                  row.customerName.length > 16 ? (
                                    <Tooltip
                                      title={row.customerName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.customerName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.noCopiesFrom}
                                </Typography> */}
                                {row.noCopiesFrom &&
                                  row.noCopiesFrom.length > 16 ? (
                                    <Tooltip
                                      title={row.noCopiesFrom}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.noCopiesFrom}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noCopiesFrom}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.noCopiesTo}
                                </Typography> */}
                                {row.noCopiesTo &&
                                  row.noCopiesTo.length > 16 ? (
                                    <Tooltip
                                      title={row.noCopiesTo}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.noCopiesTo}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noCopiesTo}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {addZeros(row.weightFrom.toString(), 3)}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {addZeros(row.weightTo.toString(), 3)}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {addZeros(row.postageRate.toString(), 2)}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.isActive ? "Yes" : "No"}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.modifiedBy} */}
                                {row.modifiedBy &&
                                  row.modifiedBy.length > 16 ? (
                                    <Tooltip
                                      title={row.modifiedBy}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedBy}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.createdBy} */}
                                {row.createdBy && row.createdBy.length > 16 ? (
                                  <Tooltip
                                    title={row.createdBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={12} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(CountryMaster);

// import React from "react";
// export default class LocalPostage extends React.Component {
//   render() {
//     return <div>dssdsd</div>;
//   }
// }
