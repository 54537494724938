import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import { getDepartment } from "../masterActions";
import { StateContext } from "../../../shared/globalState";
import { printDetails } from "../../../shared/tableCommonFunctions";

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { selectedDeptId } = props;
  let acnArr = props.roles.filter(u => u.menuName === "Department");
  let acnObj = acnArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%"
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_department/${selectedDeptId}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={e => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewDepartment extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      department: {
        departmentCode: "",
        departmentName: ""
      }
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let deptId = this.props.match.params.departmentId;
    getDepartment(deptId).then(r => {
      dispatch({
        type: "getDept",
        newStore: r
      });
      this.setState({ department: r });
    });
  }

  render() {
    let { department } = this.state;
    let deptId = this.props.match.params.departmentId;
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selectedDeptId={deptId}
            roles={this.props.roles}
          />
          {/* <div className="view-page-header">Cost Per Title Information</div> */}
          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">Department Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Department Code</div>
                <div className="view-page-value">
                  {department.departmentCode}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Department Name</div>
                <div className="view-page-value">
                  {department.departmentName}
                </div>
              </div>
            </div>{" "}
            <Divider style={{ marginTop: "25px" }} />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                  onClick={e => this.props.history.push("/department")}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewDepartment);
