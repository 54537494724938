import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_GENRIC_DOCKET_POSTING;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
export const getEdsErrorDetails = async (docketId) => {
  let getDocketList = "";
  await pgsService
    .get("/GetEDSErrorForView/" + docketId)
    .then((response) => {
      getDocketList = response.data;
    })
    .catch((e) => { });
  return getDocketList;
};
export const getDockets = async () => {
  let pol = [];
  let customerId = localStorage.getItem("loggedInId");
  await pgsService
    .get(`/GetAllDockets/` + `${customerId}`)
    .then((r) => {
      pol = r;
    })
    .catch((e) => { });
  return pol;
};

export const generateEDocket = async (docketIds, history) => {
  //console.log()
  let result = {};
  // console.log(docketIds,"docketIds")
  await pgsService
    .post(`/CreateLocalDocket/${docketIds}`)
    .then((r) => {
      if (r.data && r.data[0].errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else {
        result = r.data;
      }
    })
    .catch((e) => {
      //      if (e.response && e.response.data.errors[0].message != undefined) {
      //   toastr.error(e.response.data.errors[0].message);
      // } else if (e.response.data.errors[0]) {
      //   toastr.error(e.response.data.errors[0]);
      // }
    });
  return result;
};

export const generateEDocketInternational = async (docketIds, history) => {
  // console.log(docketIds,"docketIds")
  let result = {};

  await pgsService
    .post(`/CreateInternationalDocket/${docketIds}`)
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else {
        result = r.data;
      }
    })
    .catch((e) => {
      //   if (e.response && e.response.data.errors[0].message != undefined) {
      //   toastr.error(e.response.data.errors[0].message);
      // } else if (e.response.data.errors[0]) {
      //   toastr.error(e.response.data.errors[0]);
      // }
    });
  return result;
};
