import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import clone from "clone";
import moment from "moment"
import { Autocomplete } from "@material-ui/lab";
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import {
  postDeliveryNote,
  getFromAddress,
  //getCountryDropdownList
} from "../reportServiceAction";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import SyncIcon from "@material-ui/icons/Sync";
import {
  EditIcon,
  CustomPrintIcon,
  CustomSmallAddIcon,
} from "../../../shared/customIcons";
import IconButton from "@material-ui/core/IconButton";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import TimeFnsUtils from "@date-io/date-fns";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import WorkOrderModal from "./workOrderModal";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import {
  getShipmentTypeList,
  getCountryDropdownList,
} from "../../masters/masterActions";

const validateNotRequired100 = new Validator("maxlength100AllAllow");
const validatePhoneNo = new Validator("phnNoFCN");
const validateZipCode = new Validator("pincode");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "31%",
    fontSize: "11px !important",
  },
  textField1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "96%",
    fontSize: "11px !important",
  },
  requiredField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    color: "red",
    width: "95.5%",
    fontSize: "11px !important",
  },
  requiredField2: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    color: "red",
    // width: "95.5%",
    fontSize: "11px !important",
  },
  autocompleteText: {
    width: "100%",
    fontSize: "11px !important",
  },
  autoComplete: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "31%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  switchControl: {
    marginTop: "38px",
    marginLeft: "1em",
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    // marginLeft: "1.5em",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
});
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{
              borderLeft: "1px sold #000",
              minWidth: `${headCell.width}`,
            }}
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          //   width: "100%",
          border: "1px solid #eaeaea",
          borderBottom: "none",
          borderRadius: "4px",
          margin: "0 .8em 0 .6em",
        }}
      >
        {props.disableAdd ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={(e) => props.handleClickButton(e, "add")}
          // style={
          //   !props.disableAdd
          //     ? {
          //         color: "#fff",
          //         background: "#3982ea",
          //         padding: "2px",
          //         margin: ".5em",
          //         fontSize: "11px",
          //       }
          //     : { color: "#3982ea", fontSize: "11px" }
          // }
          >
            <CustomSmallAddIcon style={{ fontSize: "18px" }} /> Add
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              onClick={(e) => props.handleClickButton(e, "add")}
            // style={
            //   !props.disableAdd
            //     ? {
            //         color: "#fff",
            //         background: "#3982ea",
            //       }
            //     : { color: "#3982ea", fontSize: "11px" }
            // }
            >
              <CustomSmallAddIcon style={{ fontSize: "18px" }} /> Add
            </Button>
          )}
        {props.disableEdit ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            // style={
            //   !props.disableEdit
            //     ? {
            //         color: "#fff",
            //         background: "#3982ea",
            //         padding: "2px",
            //         margin: ".5em",
            //         fontSize: "11px",
            //       }
            //     : { color: "#3982ea", fontSize: "11px" }
            // }
            onClick={(e) => props.handleClickButton(e, "reset")}
          >
            <SyncIcon style={{ fontSize: "18px" }} /> Reset
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              // style={
              //   !props.disableEdit
              //     ? {
              //         color: "#fff",
              //         background: "#3982ea",
              //       }
              //     : { color: "#3982ea", fontSize: "11px" }
              // }
              onClick={(e) => props.handleClickButton(e, "reset")}
            >
              <SyncIcon style={{ fontSize: "18px" }} /> Reset
            </Button>
          )}
      </div>
    </div>
  );
};
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const headCells = [
  {
    id: 1,
    code: "lineNo",
    numeric: false,
    disablePadding: true,
    label: "Line No",
    width: "auto",
  },
  {
    id: 2,
    code: "description",
    numeric: false,
    disablePadding: false,
    label: "Description *",
    width: "100%",
  },
  {
    id: 3,
    code: "noOfPts",
    numeric: false,
    disablePadding: false,
    label: "No of Plts",
    width: "auto",
  },
  {
    id: 4,
    code: "totalPackage",
    numeric: false,
    disablePadding: false,
    label: "Total Packages *",
    width: "auto",
  },
  {
    id: 5,
    code: "noOfCopies",
    numeric: false,
    disablePadding: false,
    label: "No of Cps/Pk",
    width: "auto",
  },
  {
    id: 6,
    code: "totalQuantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity *",
    width: "auto",
  },
  {
    id: 7,
    code: "weightPerCortanBox",
    numeric: false,
    disablePadding: false,
    label: "Weight per Carton Box (Kg)",
    width: "auto",
  },
  {
    id: 8,
    code: "netWeight",
    numeric: false,
    disablePadding: false,
    label: "Net Weight",
    width: "auto",
  },
  {
    id: 9,
    code: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    width: "auto",
  },
];
class AddDeliveryNote extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      deliveryNote: {
        deliveryNoteNo: "",
        jobGroupID: "",
        customerName: "",
        workOrderNo: "",
        description: "",
        contactPersonName: "",
        contactNumber: "",
        zipCode: "",
        country: "",
        countryID: 0,
        shipmentType: "",
        isUrgent: false,
        deliverFromAddress: "",
        deliverToAddress: "",
        deliveryNoteAddress2: "",
        deliveryNoteAddress3: "",
        totalQuantity: 0,
        status: "",
        deliveryByDate: null,
        deliveryByTime: moment().startOf('day'),
        driverName: "",
      },
      workOrder: {
        jobGroupID: "",
        customerName: "",
        workOrderNo: "",
        title: "",
        jobGroupNo: "",
        workOrderID: ""
      },
      formValid: false,
      submitSuccess: false,
      errors: {},
      agentCodeValid: true,
      driverNameValid: true,
      descriptionValid: false,
      zipCodeValid: false,
      contactPersonNameValid: false,
      contactNumberValid: false,
      countryValid: false,
      deliveryByDateValid: false,
      deliveryByTimeValid: true,
      shipmentTypeValid: false,
      deliverFromAddressValid: true,
      deliverToAddressValid: false,
      deliveryNoteAddress2Valid: true,
      deliveryNoteAddress3Valid: true,
      remarksValid: true,
      deliveryNoteDetailList: [],
      editableDeliveryNoteDetailList: [],
      selectedWO: [],
      jobGroupList: [],
      countryList: [],
      shipmentTypeList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    const { shipmentTypeValid, countryValid } = this.state;
    let deliveryNote = { ...this.state.deliveryNote }
    let newDN = { ...this.state.deliveryNote }
    let nErrors = { ...this.state.errors }
    let errors = clone(this.state.errors)
    this.props.handleSettingHeader("Delivery Note");
    this.props.setSelectedList("genericSystemOpen", "deliveryNote");
    this.props.handleDrawerClose();
    getShipmentTypeList().then((r) => {
      dispatch({
        type: "getShipmentTypeList",
        newStore: r.data,
      });
      //this.setState({statusList: r.data});
      this.getAllShipmentTypes(r.data);
      r && r.data && r.data.length > 0 && r.data.map(st => {
        if (st.text === "Local") {
          newDN["shipmentType"] = st;
          nErrors["shipmentType"] = ""
          this.setState({ shipmentTypeValid: true })
        }
      })
      // this.setState({ deliveryNote, errors, });
      this.setState({ errors: nErrors, deliveryNote: newDN })

    });
    getCountryDropdownList().then((r) => {



      if (r && r.data) {
        r.data.map((rr) => {
          if (rr.value === "sg") {
            this.setState({ countryValid: true })
            nErrors["country"] = ""
            newDN["countryID"] = rr.id
            newDN["countryList"] = rr
          }
        });
        this.setState({ errors: nErrors, deliveryNote: newDN })

      }
      dispatch({
        type: "getCountryDropdownList",
        newStore: r.data,
      });
      //this.setState({statusList: r.data});
      this.getAllCountries(r.data);
    });
    getFromAddress().then((r) => {
      this.setState({ deliverFromAddressValid: true })
      dispatch({
        type: "getFromAddress",
        newStore: r.data,
      });
      newDN.deliverFromAddress = r;
      // this.setState({ deliveryNote: newDN });
      nErrors.deliverFromAddress = ""
      this.setState({ errors: nErrors, deliveryNote: newDN })

    });
    this.addFirstRow();
  }
  getAllShipmentTypes = (list) => {
    this.setState({
      shipmentTypeList:
        list &&
        list.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };
  getAllCountries = (list) => {
    this.setState({
      countryList:
        list &&
        list.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };
  addFirstRow() {
    let newList = [];
    newList.push({});
    newList[newList.length - 1].editClicked = true;
    newList[newList.length - 1].validRecord = false;
    newList[newList.length - 1].stockDescription = "";
    newList[newList.length - 1].noOfPts = 0;
    newList[newList.length - 1].totalPackage = 0;
    newList[newList.length - 1].noOfCopies = 0;
    newList[newList.length - 1].totalQuantity = 0;
    newList[newList.length - 1].weightPerCortanBox = 0;
    newList[newList.length - 1].netWeight = 0;
    newList[newList.length - 1].isActive = true;
    newList[newList.length - 1].createdBy = localStorage.loggedInId;
    this.setState({
      editableDeliveryNoteDetailList: newList,
      deliveryNoteDetailList: [],
      disableAdd: true,
      disableEdit: false,
    });
  }
  handleClickButton = (e, name) => {
    let newList = [...this.state.editableDeliveryNoteDetailList];
    //let newList2 = [...this.state.deliveryNoteDetailList];
    newList.push({});
    if (name === "add") {
      newList[newList.length - 1].editClicked = true;
      newList[newList.length - 1].validRecord = false;
      newList[newList.length - 1].stockDescription = "";
      newList[newList.length - 1].noOfPts = 0;
      newList[newList.length - 1].totalPackage = 0;
      newList[newList.length - 1].noOfCopies = 0;
      newList[newList.length - 1].totalQuantity = 0;
      newList[newList.length - 1].weightPerCortanBox = 0;
      newList[newList.length - 1].netWeight = 0;
      newList[newList.length - 1].isActive = true;
      newList[newList.length - 1].createdBy = localStorage.loggedInId;
      //newList2.push(newList[newList.length-1]);
      this.setState(
        {
          //deliveryNoteDetailList: newList2,
          editableDeliveryNoteDetailList: newList,
          disableAdd: true,
          disableEdit: false,
        },
        () => {
          document.getElementById(`tableRow${newList.length - 1}`).focus();
        }
      );
    } else {
      // this.setState({
      //   deliveryNoteDetailList: [],
      //   editableDeliveryNoteDetailList: [],
      //   disableAdd: false,
      //   disableEdit: true
      // });
      this.addFirstRow();
    }
    this.validateField("", "");
  };
  _onKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  onBlur = (event, index) => {
    const { editableDeliveryNoteDetailList } = this.state;
    let values = event.target.value;
    values = this.addZeroes(values.toString(), 3);
    editableDeliveryNoteDetailList[index][event.target.name] = values;
    this.setState({ editableDeliveryNoteDetailList });
  };
  addZeroes(num, decimalPlaces) {
    var value = Number(num);
    var res = num.split(".");
    if (res.length === 1 || res[1].length < decimalPlaces) {
      value = value.toFixed(decimalPlaces);
    }
    if (value !== "") {
      value = String(value);
    }
    return value;
  }
  checkWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  onEditClicked = (e, i) => {
    let newList = clone(this.state.editableDeliveryNoteDetailList);
    newList[i].editClicked = true;
    this.setState({ editableDeliveryNoteDetailList: newList });
    this.validateField("", "");
  };

  onDeleteClicked = (e, i, row) => {
    let newList = clone(this.state.deliveryNoteDetailList);
    newList[i].isActive = false;
    newList[i].editClicked = false;
    this.setState({ deliveryNoteDetailList: newList });
    newList.splice(i, 1);
    this.setState({ editableDeliveryNoteDetailList: newList });
    this.validateField("", "");
  };

  onDoneClicked = (e, i, record) => {
    let newList = [...this.state.editableDeliveryNoteDetailList];
    newList[i] = record;
    // newList[i].netWeight =
    //   newList[i].totalQuantity &&
    //   newList[i].weightPerCortanBox &&
    //   parseFloat(newList[i].weightPerCortanBox) *
    //   parseInt(newList[i].totalQuantity);
    // newList[i].netWeight = this.addZeroes(newList[i].netWeight.toString(), 3);
    newList[i].editClicked = false;
    this.setState({
      editableDeliveryNoteDetailList: newList,
      deliveryNoteDetailList: newList,
      disableAdd: false,
    });
    this.validateField("", "");
  };

  onCancelClicked = (e, i, record) => {
    let newList = clone(this.state.editableDeliveryNoteDetailList);
    let newList2 = clone(this.state.deliveryNoteDetailList);
    newList = newList2;
    this.setState({
      editableDeliveryNoteDetailList: newList,
      disableAdd: false,
    });
    this.validateField("", "");
  };
  handleWeightPerCartonChange = (event, index) => {
    let weightPerCortanBox = event.target.value;
    weightPerCortanBox = this.checkWeightDec(event.target.value.toString());
    let newEditableList = clone(this.state.editableDeliveryNoteDetailList);
    newEditableList[index][event.target.name] = weightPerCortanBox;
    // newEditableList[index].netWeight =
    //   newEditableList[index].weightPerCortanBox &&
    //   newEditableList[index].totalQuantity &&
    //   parseFloat(newEditableList[index].weightPerCortanBox) *
    //   parseInt(newEditableList[index].totalQuantity);
    // newEditableList[index].netWeight = parseFloat(
    //   this.addZeroes(newEditableList[index].netWeight.toString(), 3)
    // ).toFixed(3);
    if (
      newEditableList[index].description !== "" &&
      newEditableList[index].totalPackage !== "" &&
      newEditableList[index].totalQuantity !== ""
    ) {
      newEditableList[index].validRecord = true;
    } else {
      newEditableList[index].validRecord = false;
    }
    this.setState({ editableDeliveryNoteDetailList: newEditableList });
    //this.validateField(event.target.name, event.target.value);
  };

  handleRowChange = (event, index) => {
    let newEditableList = clone(this.state.editableDeliveryNoteDetailList);
    newEditableList[index][event.target.name] = event.target.value;

    if (event.target.name === "totalPackage") {
      newEditableList[index].totalQuantity = +event.target.value * newEditableList[index].noOfCopies
      newEditableList[index].netWeight = +event.target.value * newEditableList[index].weightPerCortanBox
    }
    if (event.target.name === "noOfCopies") {
      newEditableList[index].totalQuantity = +event.target.value * newEditableList[index].totalPackage
    }

    if (event.target.name === "weightPerCortanBox") {
      newEditableList[index].netWeight = +event.target.value * newEditableList[index].totalPackage
    }


    // if (event.target.name === "totalQuantity") {
    //   newEditableList[index].netWeight =
    //     newEditableList[index].weightPerCortanBox &&
    //     newEditableList[index].totalQuantity &&
    //     parseFloat(newEditableList[index].weightPerCortanBox) *
    //     parseInt(newEditableList[index].totalQuantity);
    //   newEditableList[index].netWeight = parseFloat(
    //     this.addZeroes(newEditableList[index].netWeight.toString(), 3)
    //   ).toFixed(3);
    // }
    if (
      newEditableList[index].description !== "" &&
      newEditableList[index].totalPackage !== "" &&
      newEditableList[index].totalQuantity !== ""
    ) {
      newEditableList[index].validRecord = true;
    } else {
      newEditableList[index].validRecord = false;
    }
    this.setState({ editableDeliveryNoteDetailList: newEditableList });

    //this.validateField(event.target.name, event.target.value);
  };
  handleChange = (event) => {
    const { deliveryNote } = this.state;
    deliveryNote[event.target.name] = event.target.value;
    this.setState({ deliveryNote });

    this.validateField(event.target.name, event.target.value);
  };
  handleCountryChange = (event, values) => {
    // const { deliveryNote } = this.state;
    // if (values === null) {
    //   deliveryNote["countryID"] = "null";
    //   deliveryNote["countryList"] = "";
    // } else {
    //   let countryID = values;
    //   deliveryNote["countryID"] = countryID.id;
    //   deliveryNote["countryList"] = countryID;
    // }
    // this.setState({ deliveryNote });

    // this.validateField("countryID", deliveryNote["countryID"]);

    let newCNote = { ...this.state.deliveryNote }
    if (values === null) {
      newCNote["countryList"] = "null";
      newCNote["countryID"] = "";
    } else {
      let countryVal = values;
      newCNote["countryID"] = countryVal.id;
      newCNote["countryList"] = countryVal;
    }
    this.setState({ deliveryNote: newCNote });

    this.validateField("countryID", newCNote["countryID"]);
  };
  handleWorkOrderChange = (event, values) => {
    const { deliveryNote } = this.state;
    if (values === null) {
      deliveryNote["workOrderName"] = "null";
    } else {
      let workOrderNameID = values.key;
      deliveryNote["workOrderName"] = workOrderNameID;
    }
    this.setState({ deliveryNote });

    this.validateField("workOrderName", deliveryNote["workOrderName"]);
  };
  handleShipmentChange = (event, values) => {
    const { deliveryNote } = this.state;
    if (values === null) {
      deliveryNote["shipmentType"] = "null";
    } else {
      let shipmentType = values;
      deliveryNote["shipmentType"] = shipmentType;
    }
    this.setState({ deliveryNote });

    this.validateField("shipmentType", deliveryNote["shipmentType"]);
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let agentCodeValid = this.state.agentCodeValid;
    let driverNameValid = this.state.driverNameValid;
    let deliveryByDateValid = this.state.deliveryByDateValid;
    let deliveryByTimeValid = this.state.deliveryByTimeValid;
    let shipmentTypeValid = this.state.shipmentTypeValid;
    let deliverFromAddressValid = this.state.deliverFromAddressValid;
    let deliverToAddressValid = this.state.deliverToAddressValid;
    let deliveryNoteAddress2Valid = this.state.deliveryNoteAddress2Valid;
    let deliveryNoteAddress3Valid = this.state.deliveryNoteAddress3Valid;
    let remarksValid = this.state.remarksValid;
    let descriptionValid = this.state.descriptionValid;
    let contactPersonNameValid = this.state.contactPersonNameValid;
    let contactNumberValid = this.state.contactNumberValid;
    let zipCodeValid = this.state.zipCodeValid;
    let countryValid = this.state.countryValid;
    switch (fieldName) {
      case "agentCode":
        if (value !== "") {
          agentCodeValid = validateNotRequired100(value);
          fieldValidationErrors.agentCode = agentCodeValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          agentCodeValid = true;
          fieldValidationErrors.agentCode = "";
        }
        break;
      case "driverName":
        if (value !== "") {
          driverNameValid = validateNotRequired100(value);
          fieldValidationErrors.driverName = driverNameValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          driverNameValid = true;
          fieldValidationErrors.driverName = "";
        }
        break;
      case "description":
        if (value !== "") {
          descriptionValid = validateNotRequired100(value);
          fieldValidationErrors.description = descriptionValid
            ? ""
            : resourceValidation.ValidDescription;
          this.setState({ descriptionValid })
        } else {
          descriptionValid = false;
          fieldValidationErrors.description = "";
          this.setState({ descriptionValid })
        }
        break;
      case "contactPersonName":
        if (value) {
          contactPersonNameValid = validateNotRequired100(value);
          // console.log(value, contactPersonNameValid)
          fieldValidationErrors.contactPersonName = contactPersonNameValid
            ? ""
            : resourceValidation.ValidName;
          this.setState({ contactPersonNameValid })
        } else {
          contactPersonNameValid = false;
          fieldValidationErrors.contactPersonName = "";
          this.setState({ contactPersonNameValid })
        }
        break;
      case "contactNumber":
        if (value !== "") {
          contactNumberValid = validatePhoneNo(value);
          fieldValidationErrors.contactNumber = contactNumberValid
            ? ""
            : resourceValidation.validContact;
          this.setState({ contactNumberValid })
        } else {
          contactNumberValid = false;
          fieldValidationErrors.contactNumber = "";
          this.setState({ contactNumberValid })
        }
        break;
      case "zipCode":
        if (value !== "") {
          zipCodeValid = validateZipCode(value);
          fieldValidationErrors.zipCode = zipCodeValid
            ? ""
            : resourceValidation.validZipCode;
          this.setState({ zipCodeValid })
        } else {
          zipCodeValid = false;
          fieldValidationErrors.zipCode = "";
          this.setState({ zipCodeValid })
        }
        break;
      case "countryID":
        if (value) {
          countryValid = true;
          // fieldValidationErrors.countryValid = countryValid
          //   ? ""
          //   : resourceValidation.validAddress;
          this.setState({ countryValid })
        } else {
          countryValid = false;
          // fieldValidationErrors.countryValid = "";
          this.setState({ countryValid })
        }
        break;
      case "shipmentType":
        if (value !== "null") {
          shipmentTypeValid = true;
          fieldValidationErrors.shipmentType = shipmentTypeValid
            ? ""
            : " Please select Shipment Type";
        } else {
          shipmentTypeValid = false;
          fieldValidationErrors.shipmentType = "";
        }
        break;
      case "deliverFromAddress":
        if (value !== "") {
          deliverFromAddressValid = validateNotRequired100(value);
          fieldValidationErrors.deliverFromAddress = deliverFromAddressValid
            ? ""
            : resourceValidation.validAddress;
        } else {
          deliverFromAddressValid = false;
          fieldValidationErrors.deliverFromAddress = "";
        }
        break;
      case "deliverToAddress":
        if (value !== "") {
          deliverToAddressValid = validateNotRequired100(value);
          fieldValidationErrors.deliverToAddress = deliverToAddressValid
            ? ""
            : resourceValidation.validAddress;
          this.setState({ deliverToAddressValid })
        } else {
          deliverToAddressValid = false;
          fieldValidationErrors.deliverToAddress = "";
          this.setState({ deliverToAddressValid })
        }
        break;
      case "deliveryNoteAddress2":

        if (value !== "") {
          deliveryNoteAddress2Valid = validateNotRequired100(value);
          fieldValidationErrors.deliveryNoteAddress2 = deliveryNoteAddress2Valid
            ? ""
            : resourceValidation.validAddress;
          this.setState({ deliveryNoteAddress2Valid })
        } else {
          deliveryNoteAddress2Valid = true;
          fieldValidationErrors.deliveryNoteAddress2 = "";
          // this.setState({ deliveryNoteAddress2Valid })

        }
        break;
      case "deliveryNoteAddress3":

        if (value !== "") {
          deliveryNoteAddress3Valid = validateNotRequired100(value);
          fieldValidationErrors.deliveryNoteAddress3 = deliveryNoteAddress3Valid
            ? ""
            : resourceValidation.validAddress;
          this.setState({ deliveryNoteAddress3Valid })
        } else {
          deliveryNoteAddress3Valid = true;
          fieldValidationErrors.deliveryNoteAddress3 = "";
          // this.setState({ deliveryNoteAddress3Valid })
        }
        break;
      case "remarks":
        if (value !== "") {
          remarksValid = validateNotRequired100(value);
          fieldValidationErrors.remarks = remarksValid
            ? ""
            : resourceValidation.validRemarks;
        } else {
          remarksValid = false;
          fieldValidationErrors.remarks = "";
        }
        break;
      case "deliveryByDate":
        if (value !== null) {
          deliveryByDateValid =
            value.toString() === "Invalid Date" ? false : true;
          fieldValidationErrors.deliveryByDate = deliveryByDateValid
            ? ""
            : " Invalid Date Format";
        } else {
          deliveryByDateValid = false;
          fieldValidationErrors.deliveryByDate = "";
        }
        break;
      case "deliveryByTime":
        if (value !== null) {
          deliveryByTimeValid =
            value.toString() === "Invalid Date" ? false : true;
          fieldValidationErrors.deliveryByTime = deliveryByTimeValid
            ? ""
            : " Invalid Time Format";
        } else {
          deliveryByTimeValid = false;
          fieldValidationErrors.deliveryByTime = "";
        }
        break;
      default:
        break;
    }

    this.setState(
      {
        errors: fieldValidationErrors,
        shipmentTypeValid: shipmentTypeValid,
        agentCodeValid: agentCodeValid,
        driverNameValid: driverNameValid,
        remarksValid: remarksValid,
        deliverFromAddressValid: deliverFromAddressValid,
        // deliverToAddressValid: deliverToAddressValid,
        // deliveryNoteAddress2Valid: deliveryNoteAddress2Valid,
        // deliveryNoteAddress3Valid: deliveryNoteAddress3Valid,
        deliveryByDateValid: deliveryByDateValid,
        deliveryByTimeValid: deliveryByTimeValid,
        // descriptionValid: descriptionValid,
        // contactPersonNameValid: contactPersonNameValid,
        // contactNumberValid: contactNumberValid,
        // zipCodeValid: zipCodeValid,
        // countryValid: countryValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.shipmentTypeValid &&
        this.state.agentCodeValid &&
        this.state.remarksValid &&
        this.state.driverNameValid &&
        this.state.deliverFromAddressValid &&
        this.state.deliverToAddressValid &&
        this.state.deliveryByDateValid &&
        this.state.deliveryByTimeValid &&
        this.state.descriptionValid &&
        this.state.deliveryNoteAddress2Valid &&
        this.state.deliveryNoteAddress3Valid &&
        this.state.contactPersonNameValid &&
        this.state.contactNumberValid &&
        this.state.zipCodeValid &&
        this.state.countryValid &&
        this.state.workOrder.workOrderID !== "" &&
        this.detailListValid(),
    });
    return this.state.formValid;
  }
  detailListValid() {
    let valid = false;
    const { deliveryNoteDetailList } = this.state;
    if (deliveryNoteDetailList.length > 0) {
      valid = true;
      deliveryNoteDetailList.map((r) => (valid = valid && r.validRecord));
    }
    return valid;
  }
  handleDateChange = (date) => {
    const { deliveryNote } = this.state;
    deliveryNote["deliveryByDate"] = date && date;
    this.setState({ deliveryNote });
    this.validateField("deliveryByDate", date && date);
  };
  handleTimeChange = (time) => {
    const { deliveryNote } = this.state;
    deliveryNote["deliveryByTime"] = time && time;
    this.setState({ deliveryNote });
    this.validateField("deliveryByTime", time && time);
  };
  handleisUrgentChange = (name) => (event) => {
    const { deliveryNote } = this.state;
    deliveryNote[name] = event.target.checked;
    this.setState({ deliveryNote });
  };
  handleClose = () => {
    // clean up state and errors
    this.setState({
      shipmentType: "",
      isUrgent: false,
      deliverFromAddress: "",
      deliverToAddress: "",
      deliveryByDate: null,
      deliveryByTime: null,
      agentCode: "",
      driverName: "",
      remarks: "",
      errors: {},
    });
  };
  // handleWorkOrderInfo = (e, selectedWO) => {
  //   //let newBA = { ...this.state.ba };
  //   this.setState({ workOrder: e });
  //   this.validateField("", "");
  // };
  handleWorkOrderInfo = (e, selectedWO) => {
    //let newBA = { ...this.state.ba };
    this.setState({ errors: {}, })
    let newCW = clone(this.state.deliveryNote)
    // let newCW = { ...this.state.deliveryNote }
    console.log(e)
    if (e && e.workOrderID) {
      newCW.customerName = e.customerName

      newCW.workOrderNumber = e.workOrderNumber ? e.workOrderNumber : ""
      newCW.projectTitle = e.projectTitle ? e.projectTitle : ""
      newCW.contactPersonName = e.contactPersonName ? e.contactPersonName : ""
      // if (e.contactPersonName)
      newCW.description = e.customerName
      this.validateField("description", e.customerName)
      this.validateField("contactPersonName", e.contactPersonName)
      newCW.contactNumber = e.phoneNumber ? e.phoneNumber : ""
      // if (e.phoneNumber)
      this.validateField("contactNumber", e.phoneNumber)
      newCW.deliverToAddress = e.addressLine1 ? e.addressLine1 : ""
      // if (e.addressLine1)
      this.validateField("deliverToAddress", e.addressLine1)
      newCW.deliveryNoteAddress2 = e.addressLine2 ? e.addressLine2 : ""
      // this.validateField("collectionAddress2", e.addressLine2)
      newCW.deliveryNoteAddress3 = e.addressLine3 ? e.addressLine3 : ""
      // this.validateField("deliveryNoteAddress3", e.addressLine3)
      newCW.zipCode = e.postalCode ? e.postalCode : ""
      // if (e.postalCode)
      this.validateField("zipCode", e.postalCode)
      newCW.countryID = e.countryList && e.countryList.length > 0 ? e.countryList[0].id : ""
      // newCW.countryList = e.countryList && e.countryList.length > 0 ? e.countryList[0] : {}
      newCW.countryList = e.countryList[0]

      this.validateField("countryID", e.countryList && e.countryList.length > 0 ? e.countryList[0].id : "")

    }
    else {
      newCW.customerName = ""
      newCW.workOrderNumber = ""
      newCW.projectTitle = ""
      newCW.contactPersonName = ""
      newCW.contactNumber = ""
      newCW.collectionAddress = ""
      newCW.collectionAddress2 = ""
      newCW.collectionAddress3 = ""
      newCW.zipCode = ""
      newCW.countryID = ""
      newCW.countryList = {}
    }
    // console.log(newCW.country)
    this.setState({ workOrder: e, deliveryNote: newCW });
    // this.validateField("", "");
  };
  clearWorkOrderInfo = (i) => {
    let newWorkOrder = { ...this.state.workOrder };
    newWorkOrder = {};
    this.setState({ workOrder: newWorkOrder });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      const { deliveryNote, workOrder } = this.state;
      let shipmentTypeID = deliveryNote.shipmentType.id;
      let workOrderID = workOrder.workOrderID;
      let isUrgent = deliveryNote.isUrgent;
      let deliverFromAddress = deliveryNote.deliverFromAddress;
      let deliveryToAddress = deliveryNote.deliverToAddress;
      let deliveryDate =
        deliveryNote.deliveryByDate &&
        deliveryNote.deliveryByDate.toLocaleDateString();

      let deliveryTime =
        deliveryNote.deliveryByTime && deliveryNote.deliveryByTime._d ?
          // deliveryNote.deliveryByTime._d.getHours() +
          // ":" +
          // deliveryNote.deliveryByTime._d.getMinutes() +
          // ":" +
          // deliveryNote.deliveryByTime._d.getSeconds() 
          moment(deliveryNote.deliveryByTime._d).format("HH:mm")
          : deliveryNote.deliveryByTime ?
            // deliveryNote.deliveryByTime.getHours() +
            //   ":" +
            //   deliveryNote.deliveryByTime.getMinutes() +
            //   ":" +
            //   deliveryNote.deliveryByTime.getSeconds()
            moment(deliveryNote.deliveryByTime).format("HH:mm")
            : null;

      let remarks = deliveryNote.remarks;
      let agentCode = deliveryNote.agentCode;
      let driverName = deliveryNote.driverName;
      let createdBy = localStorage.loggedInId;
      let isActive = true;
      let totalQuantity = 0;
      let deliveryNoteDetailList = this.state.deliveryNoteDetailList;
      deliveryNoteDetailList.map(
        (r) =>
          (totalQuantity = parseInt(totalQuantity) + parseInt(r.totalQuantity))
      );

      let deliveryNoteAddress2 = deliveryNote.deliveryNoteAddress2;
      let deliveryNoteAddress3 = deliveryNote.deliveryNoteAddress3;
      let description = deliveryNote.description;
      let contactPersonName = deliveryNote.contactPersonName;
      let contactNumber = deliveryNote.contactNumber;
      let zipCode = deliveryNote.zipCode;
      let countryID = deliveryNote.countryID;

      let reqBody = {
        workOrderID,
        shipmentTypeID,
        isUrgent,
        deliverFromAddress,
        deliveryToAddress,
        deliveryNoteAddress2,
        deliveryNoteAddress3,
        description,
        contactPersonName,
        contactNumber,
        zipCode,
        countryID,
        deliveryDate,
        deliveryTime,
        totalQuantity,
        agentCode,
        driverName,
        remarks,
        isActive,
        createdBy,
      };
      postDeliveryNote(reqBody, deliveryNoteDetailList).then((r) => {
        if (r > 0) {
          this.setState({ submitSuccess: true, formValid: false });
          this.props.history.push("/edit_deliveryNote/" + r);
        } else {
          this.setState({ submitSuccess: false });
        }
      });
    }
  };
  render() {
    let { classes } = this.props;
    console.log(this.state)
    let {
      deliveryNote,
      jobGroupList,
      shipmentTypeList,
      editableDeliveryNoteDetailList,
      countryList,
      disableAdd,
      workOrder,
    } = this.state;
    let totalQuantity = 0;
    editableDeliveryNoteDetailList.map(
      (r) =>
        (totalQuantity = r.totalQuantity
          ? parseInt(totalQuantity) + parseInt(r.totalQuantity)
          : totalQuantity)
    );
    return (
      <React.Fragment>
        <Paper style={
          !this.props.clearOpenList
            ? {
              overflow: "auto",
              border: "1px solid #eaeaea",
              maxWidth: "calc(100vw - 8em)",
              //maxHeight: "calc(100vh - 300px)"
            }
            : {
              overflow: "auto",
              border: "1px solid #eaeaea",
              maxWidth: "calc(100vw - 330px)",
              //maxHeight: "calc(100vh - 300px)"
            }
        }>
          <div
            className="pop-up-header"
          // stlye={{ paddingLeft: "1em" }}
          >
            Create New Delivery Note
          </div>
          <Divider />
          <div
            className={classes.container}

          >
            <div style={{ marginTop: "-4%" }}>
              <div className={classes.container} style={{ marginTop: "5%" }}>
                <div style={{ display: "flex", paddingTop: "1em" }}>

                  <WorkOrderModal
                    jobGroupList={jobGroupList}
                    clearWorkOrderInfo={this.clearWorkOrderInfo}
                    handleWorkOrderInfo={this.handleWorkOrderInfo}
                    selectedWO={this.selectedWO}
                  />
                  {workOrder.jobGroupID !== "" ? null : (
                    <span className={classes.requiredField2}>
                      * Please choose a work order
                    </span>
                  )}
                </div>
                {workOrder.jobGroupID === "" ? null : (
                  <div
                    style={{
                      width: "99%",
                      marginTop: ".5em",
                    }}
                  >
                    <Typography component="div" className="blueBox">
                      <div className="blueBoxLabel">
                        <div>Customer: </div>
                        <div className="blueBoxText">
                          {workOrder.customerName}
                        </div>
                      </div>
                      <div className="blueBoxLabel">
                        <div>Work Order: </div>
                        <div className="blueBoxText">
                          {workOrder.workOrderNumber}
                        </div>
                      </div>
                      <div className="blueBoxLabel">
                        <div>Project Title: </div>
                        <div className="blueBoxText">
                          {workOrder.projectTitle}
                        </div>
                      </div>
                      {/* <div className="blueBoxLabel">
                        <div>Job Group: </div>
                        <div className="blueBoxText">
                          {workOrder.jobGroupNumber}
                        </div>
                      </div> */}
                    </Typography>
                  </div>
                )}
              </div>
              <form
                style={{ marginTop: "-1%" }}
                className={classes.container}
                onSubmit={this.handleSubmit}
              >
                <div style={{ overflow: "auto" }}>
                  <p className={classes.subHeader}>From Address</p>
                  <div>
                    <TextField
                      required
                      multiline={true}
                      rows={1}
                      rowsMax={6}
                      inputProps={{
                        maxLength: 500,
                      }}
                      label="Deliver From Address"
                      name="deliverFromAddress"
                      autoComplete="off"
                      margin="normal"
                      // inputProps={{
                      //   readOnly: true,
                      // }}
                      className={classes.textField}
                      value={deliveryNote.deliverFromAddress}
                      onChange={this.handleChange}
                      error={this.state.errors.deliverFromAddress}
                      helperText={
                        this.state.errors.deliverFromAddress
                          ? this.state.errors.deliverFromAddress
                          : ""
                      }
                    />
                  </div>
                  <p className={classes.subHeader}>To Address</p>
                  <div>
                    <TextField
                      required
                      multiline={false}
                      rows={1}
                      rowsMax={4}
                      label="Company"
                      name="description"
                      inputProps={{
                        maxLength: 200,
                      }}
                      margin="normal"
                      className={classes.textField1}
                      value={deliveryNote.description}
                      onChange={this.handleChange}
                      error={this.state.errors.description}
                      helperText={
                        this.state.errors.description
                          ? this.state.errors.description
                          : ""
                      }
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      required
                      label="Address 1"
                      name="deliverToAddress"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      autoComplete="off"
                      value={deliveryNote.deliverToAddress}
                      onChange={this.handleChange}
                      error={this.state.errors.deliverToAddress}
                      helperText={
                        this.state.errors.deliverToAddress
                          ? this.state.errors.deliverToAddress
                          : ""
                      }
                    />
                    <TextField
                      label="Address 2"
                      name="deliveryNoteAddress2"
                      autoComplete="off"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      value={deliveryNote.deliveryNoteAddress2}
                      onChange={this.handleChange}
                      error={this.state.errors.deliveryNoteAddress2}
                      helperText={
                        this.state.errors.deliveryNoteAddress2
                          ? this.state.errors.deliveryNoteAddress2
                          : ""
                      }
                    />
                    <TextField
                      label="Address 3"
                      name="deliveryNoteAddress3"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      autoComplete="off"
                      value={deliveryNote.deliveryNoteAddress3}
                      onChange={this.handleChange}
                      error={this.state.errors.deliveryNoteAddress3}
                      helperText={
                        this.state.errors.deliveryNoteAddress3
                          ? this.state.errors.deliveryNoteAddress3
                          : ""
                      }
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      required
                      label="Zip Code"
                      name="zipCode"
                      margin="normal"
                      inputProps={{
                        maxLength: 6,
                      }}
                      className={classes.textField}
                      value={deliveryNote.zipCode}
                      onChange={this.handleChange}
                      error={this.state.errors.zipCode}
                      helperText={
                        this.state.errors.zipCode
                          ? this.state.errors.zipCode
                          : ""
                      }
                    />
                    <Autocomplete
                      autoComplete
                      includeInputInList
                      id="countryList"
                      options={countryList}
                      className={classes.autoComplete}
                      value={deliveryNote.countryList ? deliveryNote.countryList : {}}
                      onChange={this.handleCountryChange}
                      getOptionLabel={(option) => option.text}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Select Country"
                          name="countryList"
                          id="country"
                          className={classes.autocompleteText}
                          error={this.state.errors.country}
                          value={deliveryNote.country}
                          helperText={
                            this.state.errors.country
                              ? this.state.errors.country
                              : ""
                          }
                        />
                      )}
                    />
                  </div>
                  <p className={classes.subHeader}>Contact Info</p>
                  <div style={{ display: "flex" }}>
                    <TextField
                      required
                      label="Contact Person Name"
                      name="contactPersonName"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      value={deliveryNote.contactPersonName}
                      onChange={this.handleChange}
                      error={this.state.errors.contactPersonName}
                      helperText={
                        this.state.errors.contactPersonName
                          ? this.state.errors.contactPersonName
                          : ""
                      }
                    />
                    <TextField
                      required
                      label="Contact Number"
                      name="contactNumber"
                      inputMode="numeric"
                      margin="normal"
                      inputProps={{
                        maxLength: 20,
                      }}
                      className={classes.textField}
                      value={deliveryNote.contactNumber}
                      onChange={this.handleChange}
                      error={this.state.errors.contactNumber}
                      helperText={
                        this.state.errors.contactNumber
                          ? this.state.errors.contactNumber
                          : ""
                      }
                    />
                  </div>
                  <p className={classes.subHeader}>Delivery Details</p>
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        disablePast
                        required
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        className={classes.textField}
                        id="deliveryByDate"
                        label="Deliver By Date"
                        value={deliveryNote.deliveryByDate}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={TimeFnsUtils}>
                      <KeyboardTimePicker
                        autoOk
                        required
                        variant="inline"
                        margin="normal"
                        className={classes.textField}
                        id="deliveryByTime"
                        label="Deliver By Time"
                        value={deliveryNote.deliveryByTime}
                        onChange={this.handleTimeChange}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={deliveryNote.isUrgent}
                          onChange={this.handleisUrgentChange("isUrgent")}
                          value="isUrgent"
                          color="primary"
                        />
                      }
                      label="Urgent"
                      className={classes.switchControl}

                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      label="Agent Code"
                      name="agentCode"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      value={deliveryNote.agentCode}
                      onChange={this.handleChange}
                      error={this.state.errors.agentCode}
                      helperText={
                        this.state.errors.agentCode
                          ? this.state.errors.agentCode
                          : ""
                      }
                    />
                    <TextField
                      label="Driver Name"
                      name="driverName"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      value={deliveryNote.driverName}
                      onChange={this.handleChange}
                      error={this.state.errors.driverName}
                      helperText={
                        this.state.errors.driverName
                          ? this.state.errors.driverName
                          : ""
                      }
                    />
                    <Autocomplete
                      autoComplete
                      includeInputInList
                      id="shipmentType"
                      options={shipmentTypeList}
                      className={classes.autoComplete}
                      onChange={this.handleShipmentChange}
                      getOptionLabel={(option) => option.text}
                      value={deliveryNote.shipmentType}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Select Shipment Type"
                          name="shipmentType"
                          id="shipmentType"
                          className={classes.autocompleteText}
                          error={this.state.errors.shipmentType}
                          value={deliveryNote.shipmentType}
                          helperText={
                            this.state.errors.shipmentType
                              ? this.state.errors.shipmentType
                              : ""
                          }
                        />
                      )}
                    />
                  </div>
                  <div>
                    <TextField
                      multiline={true}
                      rows={1}
                      rowsMax={4}
                      label="Special Instructions"
                      name="remarks"
                      inputProps={{
                        maxLength: 500,
                      }}
                      margin="normal"
                      className={classes.textField}
                      value={deliveryNote.remarks}
                      onChange={this.handleChange}
                      error={this.state.errors.remarks}
                      helperText={
                        this.state.errors.remarks
                          ? this.state.errors.remarks
                          : ""
                      }
                    />
                  </div>

                  <div>
                    <div style={{ marginTop: "2%" }}>
                      <p className={classes.subHeader}>Delivery Items</p>
                      <div
                        style={{
                          width: "97%",
                          margin: "auto",
                        }}
                        className="blueBox"
                      >
                        <div className="blueBoxLabel">
                          <div>Total Quantity: </div>
                          <div className="blueBoxText">{totalQuantity}</div>
                        </div>
                      </div>
                      <div>
                        <EnhancedTableToolbar
                          //numSelected={selected.length}
                          editableDeliveryNoteDetailList={
                            this.state.editableDeliveryNoteDetailList
                          }
                          resetList={this.state.resetList}
                          handleClickButton={this.handleClickButton}
                          disableAdd={this.state.disableAdd}
                          disableEdit={this.state.disableEdit}
                          validList={this.state.detailListValid}
                        />
                      </div>
                      <div
                        style={{
                          // width: "100%",
                          maxHeight: "700px",
                          margin: "0 .8em 0 .6em",
                        }}
                      >
                        <div
                          // style={{
                          //   maxHeight: 185,
                          //   overflow: "auto",
                          //   border: "1px solid #eaeaea"
                          // }}
                          style={
                            !this.props.clearOpenList
                              ? {
                                overflow: "auto",
                                border: "1px solid #eaeaea",
                                maxWidth: "calc(100vw - 8em)",
                                maxHeight: "calc(100vh - 300px)",
                              }
                              : {
                                overflow: "auto",
                                border: "1px solid #eaeaea",
                                maxWidth: "calc(100vw - 330px)",
                                maxHeight: "calc(100vh - 300px)",
                              }
                          }
                        >
                          {/* //overflow: "auto" */}
                          <Table
                            aria-labelledby="tableTitle"
                            size="small"
                            stickyHeader
                            aria-label="sticky table"
                          >
                            {/* <caption style={{ borderBottom: "1px solid #eaeaea" }}>
                  A barbone structure table example with a caption
                </caption> */}
                            <EnhancedTableHead
                            //numSelected={selected.length}
                            // order={order}
                            // orderBy={orderBy}
                            // onSelectAllClick={e => this.handleSelectAllClick(e)}
                            //  onRequestSort={this.handleRequestSort}
                            //  rowCount={departmentList.length}
                            />

                            <TableBody style={{ overflow: "hidden" }}>
                              {editableDeliveryNoteDetailList.map(
                                (row, index) => {
                                  // const isItemSelected = isSelected(row.id);
                                  const labelId = `enhanced-table-checkbox-${index}`;

                                  return (
                                    <TableRow
                                      // hover
                                      // onClick={event =>
                                      //   this.handleClick(event, row.id, row)
                                      // }
                                      role="checkbox"
                                      //  aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      key={row.id}
                                      id={`tableRow${index}`}
                                    //   selected={isItemSelected}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="right"
                                        className="table-content-cell"
                                      >
                                        {index + 1}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                        style={{
                                          width: "35%",
                                          padding: "6px 6px 6px 6px",
                                        }}
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          {editableDeliveryNoteDetailList[index]
                                            .editClicked ? (
                                              <Input
                                                defaultValue=""
                                                inputProps={{
                                                  "aria-label": "description",
                                                  maxLength: 200,
                                                }}
                                                name="stockDescription"
                                                style={{ width: "100%" }}
                                                value={row.stockDescription}
                                                onChange={(e) =>
                                                  this.handleRowChange(e, index)
                                                }
                                              />
                                            ) : (
                                              row.stockDescription
                                            )}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="right"
                                        className="table-content-cell"
                                        style={{
                                          padding: "6px 6px 6px 6px",
                                        }}
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          {editableDeliveryNoteDetailList[index]
                                            .editClicked ? (
                                              <Input
                                                defaultValue=""
                                                inputProps={{
                                                  "aria-label": "description",
                                                  maxLength: 10,
                                                }}
                                                name="noOfPts"
                                                onKeyPress={this._onKeyPress}
                                                style={{ width: "100%" }}
                                                value={row.noOfPts}
                                                onChange={(e) =>
                                                  this.handleRowChange(e, index)
                                                }
                                              />
                                            ) : (
                                              row.noOfPts
                                            )}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="right"
                                        className="table-content-cell"
                                        style={{
                                          padding: "6px 6px 6px 6px",
                                        }}
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          {editableDeliveryNoteDetailList[index]
                                            .editClicked ? (
                                              <Input
                                                defaultValue=""
                                                inputProps={{
                                                  "aria-label": "description",
                                                  maxLength: 10,
                                                }}
                                                name="totalPackage"
                                                onKeyPress={this._onKeyPress}
                                                style={{ width: "100%" }}
                                                value={row.totalPackage}
                                                onChange={(e) =>
                                                  this.handleRowChange(e, index)
                                                }
                                              />
                                            ) : (
                                              row.totalPackage
                                            )}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="right"
                                        className="table-content-cell"
                                        style={{
                                          padding: "6px 6px 6px 6px",
                                        }}
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          {editableDeliveryNoteDetailList[index]
                                            .editClicked ? (
                                              <Input
                                                defaultValue=""
                                                inputProps={{
                                                  "aria-label": "description",
                                                  maxLength: 10,
                                                }}
                                                name="noOfCopies"
                                                onKeyPress={this._onKeyPress}
                                                style={{ width: "100%" }}
                                                value={row.noOfCopies}
                                                onChange={(e) =>
                                                  this.handleRowChange(e, index)
                                                }
                                              />
                                            ) : (
                                              row.noOfCopies
                                            )}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="right"
                                        className="table-content-cell"
                                        style={{
                                          padding: "6px 6px 6px 6px",
                                        }}
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.totalQuantity}
                                          {/* {editableDeliveryNoteDetailList[index]
                                            .editClicked ? (
                                              <Input
                                                defaultValue=""
                                                inputProps={{
                                                  "aria-label": "description",
                                                  maxLength: 10,
                                                }}
                                                name="totalQuantity"
                                                onKeyPress={this._onKeyPress}
                                                style={{ width: "100%" }}
                                                value={row.totalQuantity}
                                                onChange={(e) =>
                                                  this.handleRowChange(e, index)
                                                }
                                              />
                                            ) : (
                                              row.totalQuantity
                                            )} */}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="right"
                                        className="table-content-cell"
                                        style={{
                                          padding: "6px 6px 6px 6px",
                                        }}
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {" "}
                                          {editableDeliveryNoteDetailList[index]
                                            .editClicked ? (
                                              <Input
                                                defaultValue=""
                                                inputProps={{
                                                  "aria-label": "description",
                                                  maxLength: 10,
                                                }}
                                                name="weightPerCortanBox"
                                                onKeyPress={
                                                  this._onDecimalKeyPress
                                                }
                                                style={{ width: "100%" }}
                                                value={row.weightPerCortanBox}
                                                onBlur={(e) =>
                                                  this.onBlur(e, index)
                                                }
                                                onChange={(e) =>
                                                  this.handleRowChange(e, index)
                                                }
                                              />
                                            ) : (
                                              row.weightPerCortanBox
                                            )}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="right"
                                        className="table-content-cell"
                                        style={{
                                          padding: "6px 6px 6px 6px",
                                        }}
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {/* {" "}
                                    {editableDeliveryNoteDetailList[index]
                                      .editClicked ? (
                                      <Input
                                        defaultValue=""
                                        inputProps={{
                                          "aria-label": "description",
                                          maxLength: 10
                                        }}
                                        name="netWeight"
                                        onKeyPress={this._onDecimalKeyPress}
                                        style={{ width: "100%" }}
                                        value={row.netWeight}
                                        onChange={e =>
                                          this.handleNetWeightChange(e, index)
                                        }
                                      />
                                    ) : ( */}
                                          {(+row.netWeight).toFixed(3)}
                                          {/* )} */}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                            display: "flex",
                                          }}
                                        >
                                          {editableDeliveryNoteDetailList[index]
                                            .editClicked ? (
                                              <div>
                                                {row.validRecord ? (
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#6c6b6b",
                                                    }}
                                                    onClick={(e) =>
                                                      this.onDoneClicked(
                                                        e,
                                                        index,
                                                        row
                                                      )
                                                    }
                                                  >
                                                    <DoneIcon
                                                      style={{
                                                        color: "#6c6b6b",
                                                      }}
                                                    />
                                                  </span>
                                                ) : (
                                                    <React.Fragment></React.Fragment>
                                                  )}
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                  onClick={(e) =>
                                                    this.onCancelClicked(
                                                      e,
                                                      index,
                                                      row
                                                    )
                                                  }
                                                >
                                                  <CloseIcon
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#6c6b6b",
                                                    }}
                                                  />
                                                </span>
                                              </div>
                                            ) : (
                                              <div>
                                                {!disableAdd ? (
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#6c6b6b",
                                                    }}
                                                    onClick={(e) =>
                                                      this.onEditClicked(e, index)
                                                    }
                                                  >
                                                    <EditIcon
                                                      style={{
                                                        color: "#6c6b6b",
                                                      }}
                                                    />
                                                  </span>
                                                ) : (
                                                    <React.Fragment></React.Fragment>
                                                  )}
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                  onClick={(e) =>
                                                    this.onDeleteClicked(
                                                      e,
                                                      index,
                                                      row
                                                    )
                                                  }
                                                >
                                                  <DeleteIcon
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#6c6b6b",
                                                    }}
                                                  />
                                                </span>
                                              </div>
                                            )}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}

                              {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 25 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                            </TableBody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/deliveryNote")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                  {/* <div style={{ marginLeft: "1em" }}>
                    <Button
                      disabled={!this.state.submitSuccess}
                      autoFocus
                      variant="contained"
                      className={
                        !this.state.submitSuccess
                          ? "disabled-button"
                          : "enabled-button "
                      }
                    >
                      <IconButton aria-label="print" size="small">
                        <CustomPrintIcon />
                      </IconButton>
                      {"PRINT DELIVERY NOTE"}
                    </Button>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(AddDeliveryNote));
