const ZONE_LIST = 'ZONE_LIST';
const COUNTRY_LIST = 'COUNTRY_LIST';
const CUSTOMER_LIST = 'CUSTOMER_LIST';
const PPI_LIST = 'PPI_LIST';
const CCI_LIST = 'CCI_LIST';
const USER_LIST = 'USER_LIST';

export default {
    ZONE_LIST,
    COUNTRY_LIST,
    CUSTOMER_LIST,
    PPI_LIST,
    CCI_LIST,
    USER_LIST,
}