import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { resourceValidation } from "../../../shared/resource";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { postCptCode } from "../masterActions";
import { addStyles } from "../../../shared/styles";

const maxLenghtAlpNumHypDot50 = new Validator("maxLenghtAlpNumHypDot50");
const maxlengthValidator10WithNum = new Validator("maxlength10WithNum");
const maxlengthValidator20WithNum = new Validator("maxlength20WithNum");
const validateNotRequired100 = new Validator("maxlength100AllAllow");

const styles = (theme) => ({
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "31%",
    fontSize: "11px !important",
  },
  comboBox: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "31%",
    fontSize: "11px !important",
  },
  checkBox: {
    marginTop: theme.spacing(3.5),
    marginLeft: theme.spacing(1.5),
    marginRight: "1.5em !important",
  },
});

class AddCptCode extends React.Component {
  constructor() {
    super();

    this.state = {
      cptCode: {
        cptOfficeType: "",
        objectCode: "",
        subCode: "",
        cptDescription: "",
        sapglAccount: "",
        category: "",
        remarks: "",
      },
      errors: {},
      cptOfficeTypeValid: false,
      objectCodeValid: false,
      subCodeValid: false,
      cptDescriptionValid: false,
      sapglAccountValid: false,
      categoryValid: false,
      remarksValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.handleSettingHeader("Cost Per Title");
  }

  handleChange = (event) => {
    const { cptCode } = this.state;
    cptCode[event.target.name] = event.target.value;
    this.setState({ cptCode });

    this.validateField(event.target.name, event.target.value);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let cptOfficeTypeValid = this.state.cptOfficeTypeValid;
    let objectCodeValid = this.state.objectCodeValid;
    let subCodeValid = this.state.subCodeValid;
    let cptDescriptionValid = this.state.cptDescriptionValid;
    let sapglAccountValid = this.state.sapglAccountValid;
    let categoryValid = this.state.categoryValid;
    let remarksValid = this.state.remarksValid;
    switch (fieldName) {
      case "cptOfficeType":
        cptOfficeTypeValid = maxLenghtAlpNumHypDot50(value);
        fieldValidationErrors.cptOfficeType = cptOfficeTypeValid
          ? ""
          : resourceValidation.onlyAlphanumeric;
        break;
      case "objectCode":
        objectCodeValid = maxlengthValidator10WithNum(value);
        fieldValidationErrors.objectCode = objectCodeValid
          ? ""
          : resourceValidation.onlyAlphanumeric;
        break;
      case "subCode":
        subCodeValid = maxlengthValidator10WithNum(value);
        fieldValidationErrors.subCode = subCodeValid
          ? ""
          : resourceValidation.onlyAlphanumeric;
        break;
      case "cptDescription":
        // cptDescriptionValid = maxlengthValidator500(value);
        // fieldValidationErrors.cptDescription = cptDescriptionValid ? '' : ' Please enter alphabet characters only';
        break;
      case "sapglAccount":
        sapglAccountValid = maxlengthValidator20WithNum(value);
        fieldValidationErrors.sapglAccount = sapglAccountValid
          ? ""
          : resourceValidation.onlyAlphanumeric;
        break;
      case "category":
        // if (value !== "") {
        categoryValid = validateNotRequired100(value);
        fieldValidationErrors.category = categoryValid
          ? ""
          : resourceValidation.onlyAlphanumeric;
        // } else {
        //   categoryValid = true;
        //   fieldValidationErrors.categoryValid = "";
        // }
        break;
      case "remarks":
        if (value !== "") {
          remarksValid = validateNotRequired100(value);
          fieldValidationErrors.remarks = remarksValid
            ? ""
            : resourceValidation.validRemarks;
        } else {
          remarksValid = true;
          fieldValidationErrors = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        objectCodeValid: objectCodeValid,
        cptOfficeTypeValid: cptOfficeTypeValid,
        subCodeValid: subCodeValid,
        cptDescriptionValid: cptDescriptionValid,
        sapglAccountValid: sapglAccountValid,
        categoryValid: categoryValid,
        remarksValid: remarksValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.cptOfficeTypeValid &&
        // this.state.objectCodeValid &&
        // this.state.subCodeValid &&
        this.state.sapglAccountValid &&
        this.state.categoryValid,
      //this.state.remarksValid
    });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      cptOfficeType: "",
      objectCode: "",
      subCode: "",
      cptDescription: "",
      sapglAccount: "",
      category: "",
      remarks: "",
      errors: {},
    });
  };

  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onOfficeTypeKeyPress(event) {
    const re = /[0-9a-zA-Z-. ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      const { cptCode } = this.state;

      let cptOfficeType = cptCode.cptOfficeType;
      let objectCode = cptCode.objectCode;
      let subCode = cptCode.subCode;
      let cptDescription = cptCode.cptDescription;
      let sapglAccount = cptCode.sapglAccount;
      let category = cptCode.category;
      let remarks = cptCode.remarks;
      let createdBy = localStorage.loggedInId;

      let reqBody = {
        cptOfficeType,
        objectCode,
        subCode,
        cptDescription,
        sapglAccount,
        category,
        remarks,
        createdBy
      };
      postCptCode(reqBody, this.props.history);
    }
  };
  render() {
    let { classes } = this.props;
    let { cptCode } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Cost Per Title</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    label="Office"
                    name="cptOfficeType"
                    inputProps={{
                      maxLength: 50,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={cptCode.cptOfficeType}
                    onChange={this.handleChange}
                    error={this.state.errors.cptOfficeType}
                    helperText={
                      this.state.errors.cptOfficeType
                        ? this.state.errors.cptOfficeType
                        : ""
                    }
                    onKeyPress={this._onOfficeTypeKeyPress}
                  />
                  <TextField
                    label="Category"
                    name="category"
                    inputProps={{
                      maxLength: 50,
                    }}
                    margin="normal"
                    required
                    autoComplete="off"
                    className={classes.textField}
                    value={cptCode.category}
                    onChange={this.handleChange}
                    error={this.state.errors.category}
                    helperText={
                      this.state.errors.category
                        ? this.state.errors.category
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    label="Object Code"
                    name="objectCode"
                    inputProps={{
                      maxLength: 10,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={cptCode.objectCode.toUpperCase()}
                    onChange={this.handleChange}
                    error={this.state.errors.objectCode}
                    helperText={
                      this.state.errors.objectCode
                        ? this.state.errors.objectCode
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />

                  <TextField
                    label="Sub Code"
                    name="subCode"
                    inputProps={{
                      maxLength: 10,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={cptCode.subCode.toUpperCase()}
                    onChange={this.handleChange}
                    error={this.state.errors.subCode}
                    helperText={
                      this.state.errors.subCode ? this.state.errors.subCode : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                </div>
                <div>
                  <TextField
                    multiline={false}
                    rows={1}
                    rowsMax={4}
                    inputProps={{
                      maxLength: 500,
                    }}
                    label="Description"
                    name="cptDescription"
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={cptCode.cptDescription}
                    onChange={this.handleChange}
                    error={this.state.errors.cptDescription}
                    helperText={
                      this.state.errors.cptDescription
                        ? this.state.errors.cptDescription
                        : ""
                    }
                  />
                  <TextField
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    inputProps={{
                      maxLength: 500,
                    }}
                    label="Remarks"
                    name="remarks"
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={cptCode.remarks}
                    onChange={this.handleChange}
                    error={this.state.errors.remarks}
                    helperText={
                      this.state.errors.remarks ? this.state.errors.remarks : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    required
                    label="SAP GL Account"
                    name="sapglAccount"
                    margin="normal"
                    inputProps={{
                      maxLength: 20,
                    }}
                    className={classes.textField}
                    value={cptCode.sapglAccount}
                    onChange={this.handleChange}
                    autoComplete="off"
                    error={this.state.errors.sapglAccount}
                    helperText={
                      this.state.errors.sapglAccount
                        ? this.state.errors.sapglAccount
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/cptCode")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddCptCode));
