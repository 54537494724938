import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import clone from "clone";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { resourceValidation } from "../../../shared/resource";
import { ExportCSV } from "../../../shared/ExportCSV";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  SearchIcon,
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import {
  stableSort,
  getSorting,
  DialogActions,
} from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import Validator from "../../../shared/validator";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import types from "../../masters/masterTypes";
import { StateContext } from "../../../shared/globalState";
import {
  getProcessRates,
  deleteProcessRate,
  // getFilteredProcessRateList,
  getFilteredProcessRateList,
  getCustomerDDList,
  getProcessDropDown,
  getUOProcessNonDS,
} from "../../masters/masterActions";
import { Autocomplete } from "@material-ui/lab";

const columns = [
  {
    name: "status",
    id: 5,
    show: false,
  },
  {
    name: "Modified Date",
    id: 6,
    show: false,
  },
  {
    name: "Modified By",
    id: 7,
  },
  {
    name: "Created Date",
    id: 8,
  },
  {
    name: "Created By",
    id: 9,
  },
];

const validateNotRequired100 = new Validator("maxlength100AllAllow");
const validateCode = new Validator("maxlengthWithNumber4");

function createData(
  customerCode,
  customerName,

  processName,
  unitRate,
  isActive,
  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate,
  id
) {
  return {
    customerCode,
    customerName,
    processName,
    unitRate,
    isActive,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
    id,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all processRate" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    customerCode: "",
    customerName: "",
  });
  const [formValid, setIsformValid] = useState(false);

  const [processRateSearchValue, setValues] = useState({
    processCode: "",
    customerCode: "",
  });

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);

  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handleDeleteProcessRate,
    searchPopupOpen,
    //selectedProcessRate,
    // selectedProcessRateId,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchProcessRate,
    searchClicked,
    hendleVewColums,
    exportDataValues,
    processRateList,
    selected,
    isActive,
  } = props;

  //const selectedProcessRateId = selected && selected.length > 0 && selected[0];
  const selectedProcessRateId = selected[0];
  const selectedProcessRate = processRateList.find(
    (x) => x.id === selectedProcessRateId
  );
  let acnArr = props.roles.filter((u) => u.menuName === "Process Rate");
  let acnObj = acnArr[0];

  let expData = clone(processRateList);
  expData.map((d) => {
    let Val = "No";
    if (d["isActive"]) {
      Val = "Yes";
    }
    d["Customer Code"] = d["customerCode"];
    d["Customer Name"] = d["customerName"];
    d["Process Name"] = d["processName"];
    d["Unit Rate"] = d["unitRate"];

    d["Active"] = Val;
    d["Created By"] = d["createdBy"];
    d["Created Date"] = moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss");
    d["Modified By"] = d["modifiedBy"];

    d["Modified Date"] = moment(d["modifiedDate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    );
    delete d["customerCode"];
    delete d["customerName"];
    delete d["processName"];

    delete d["unitRate"];

    delete d["isActive"];

    delete d["createdBy"];

    delete d["createdDate"];

    delete d["modifiedBy"];

    delete d["modifiedDate"];
    delete d["id"];
  });
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteProcessRate = () => {
    handleDeleteProcessRate();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = processRateSearchValue;
    searchValues.processCode = "";
    searchValues.customerCode = "";
    searchValues = processRateSearchValue;
  };

  const searchPopup = () => {
    handleSearchProcessRate(processRateSearchValue);
    clearSearchValues();
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };

  const customerOptions = props.customersList;
  const processOptions = props.processList;

  const handleCustValuesChange = async (event, values) => {
    event.persist();
    let name = event.target.name;
    let name2 = "customerCode";
    // let name3 = "processName";
    await setValues((processRateSearchValue) => ({
      ...processRateSearchValue,
      [name2]: values && values.id,
      // [name3]: values && values.id
      //[event.target.name]: event.target.value }));
    }));
    setIsformValid(true);

    switch (name2) {
      default:
        break;
    }
  };

  const handleProcessValuesChange = async (event, values) => {
    event.persist();
    let name = event.target.name;
    let name2 = "processCode";
    await setValues((processRateSearchValue) => ({
      ...processRateSearchValue,
      [name2]: values && values.id,
      //[event.target.name]: event.target.value }));
    }));
    setIsformValid(true);
    switch (name2) {
      default:
        break;
    }
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_processRate/${selectedProcessRateId}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <Link to={`/edit_processRate/${selectedProcessRateId}`}>
                      <IconButton aria-label="edit">
                        <EditIcon className={classes.iconHover} />
                      </IconButton>
                    </Link>

                    {/* this.props.history.push(/home:${this.state.userID}) onClick={e => props.history.push('/edit_processType/${selectedProcessRateId}')} */}
                  </Tooltip>
                )}
              </div>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={handleClickOpen}
                  disabled={!isActive}
                >
                  <DeleteIcon className={classes.iconHover} />
                </IconButton>
              </Tooltip>
            )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete Process Rate
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1
                      ? " ?"
                      : selectedProcessRate.customerCode + " ?"}
                    {/* {selectedProcessRate.customerCode} */}
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteProcessRate}
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  <form onSubmit={searchPopup}>
                    {acnObj && acnObj.advanceSearchAction && (
                      <Tooltip title="Search">
                        <IconButton
                          aria-label="Search"
                          className={classes.iconHover}
                          onClick={handleSearchOpen}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      onClose={handleSearchPopupClose}
                      aria-labelledby="customized-dialog-title"
                      open={searchPopupOpen}
                      maxWidth="sm"
                      fullWidth={true}
                    >
                      <DialogTitle
                        id="customized-dialog-title"
                        onClose={handleSearchPopupClose}
                      >
                        Advanced Search
                  </DialogTitle>
                      <DialogContent dividers>
                        <React.Fragment>
                          <div style={{ display: "flex" }}>
                            <Autocomplete
                              autoComplete
                              includeInputInList
                              id="processCode"
                              name="processCode"
                              // hintText="process Name"
                              style={{ width: "50%", marginRight: "15px" }}
                              options={processOptions}
                              onChange={handleProcessValuesChange}
                              getOptionLabel={(option) => option.value}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Process"
                                  name="processCode"
                                  id="processCode"
                                  margin="normal"
                                  style={{ width: "100%" }}
                                  value={processRateSearchValue.processCode}
                                />
                              )}
                            />
                            <Autocomplete
                              autoComplete
                              includeInputInList
                              id="customerCode"
                              name="customerCode"
                              // hintText="process Name"
                              style={{ width: "50%" }}
                              options={customerOptions}
                              onChange={handleCustValuesChange}
                              getOptionLabel={(option) => option.text}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Customer"
                                  name="customerCode"
                                  id="customerCode"
                                  margin="normal"
                                  style={{ width: "100%" }}
                                  value={processRateSearchValue.customerCode}
                                />
                              )}
                            />
                          </div>
                        </React.Fragment>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                          onClick={handleSearchClose}
                        >
                          CANCEL
                    </Button>
                        {!processRateSearchValue.processCode &&
                          !processRateSearchValue.customerCode ? (
                            <Button
                              disabled
                              //autoFocus
                              variant="contained"
                              color="primary"
                              style={{ boxShadow: "none" }}
                              onClick={searchPopup}
                            >
                              SEARCH
                            </Button>
                          ) : (
                            <Button
                              onClick={searchPopup}
                              variant="contained"
                              color="primary"
                              //disableElevation
                              style={{ boxShadow: "none" }}
                            >
                              SEARCH
                            </Button>
                          )}
                      </DialogActions>
                    </Dialog>
                  </form>
                )}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="add"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/add_processRate")}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {acnObj && acnObj.exportAction && (
                <Tooltip>
                  <ExportCSV csvData={expData} fileName={"ProcessRate"} />
                </Tooltip>
              )}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                hendleVewColums={hendleVewColums}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class ProcessRateMater extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    processRateList: [],
    rows: [],
    activeRows: 0,
    isActive: false,
    refreshData: false,
    selectedProcessRate: {},
    //selectedProcessRateId: 1,
    loading: false,
    searchPopupOpen: false,
    processRateSearchValues: {},
    searchClicked: false,
    exportDataValues: [],
    selectedShowColumns: [],
    customersList: [],
    processList: [],
    headCells: [
      {
        id: 1,
        code: "customerCode",
        numeric: false,
        disablePadding: true,
        label: "Customer Code",
        show: true,
      },
      {
        id: 2,
        code: "customerName",
        numeric: false,
        disablePadding: true,
        label: "Customer Name",
        show: true,
      },
      {
        id: 3,
        code: "processName",
        numeric: false,
        disablePadding: true,
        label: "Process Name",
        show: true,
      },

      {
        id: 4,
        code: "unitRate",
        numeric: false,
        disablePadding: true,
        label: "Unit Rate $S",
        show: true,
      },
      {
        id: 5,
        code: "isActive",
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true,
      },
      {
        id: 6,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 7,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 8,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 9,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };

  componentDidMount() {
    this.props.handleSettingHeader("Process Rate");
    this.props.setSelectedList("masterDataOpen", "processRate");
    const dispatch = this.context[1];

    getProcessRates(window.localStorage.token).then((r) => {
      this.getAllProcessRates(r.data);
    });
    getCustomerDDList().then((r) => {
      this.setState({
        customersList:
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });

    getUOProcessNonDS().then((r) => {
      this.setState({
        processList:
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });

    this.setState({ loading: true });
  }
  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };
  exportProcessRates = (procesTypeList) => {
    let list = procesTypeList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          CustomerCode: d.customerCode,
          CustomerName: d.customerName,
          Active: d.isActive === true ? "Yes" : "No",
          ModifiedDate: d.modifiedDate,
          ModifiedBy: d.modifiedBy,
          CreatedDate: d.createdDate,
          CreatedBy: d.createdBy,
        });
      });
    this.setState({ exportDataValues: rows });
  };
  getAllProcessRates = (processRateList) => {
    let list = processRateList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.customerCode,
            d.customerName,

            d.processName,
            d.unitRate,
            d.isActive,
            d.createdByName,
            d.createdDate,
            d.modifiedByName,
            d.modifiedDate,
            d.processRateID
          )
        );
      });
    this.sortStateList(rows);
    this.setState({
      processRateList: rows,
      loading: false,
      selected: [],
      activeRows: rows.filter((x) => x.isActive).map((n) => n.id),
    });
  };

  sortStateList = (list) => {
    let z = list.sort(function (a, b) {
      let x = a.isActive && a.isActive;
      let y = b.isActive && b.isActive;
      let u = a.modifiedDate && a.modifiedDate;
      let v = b.modifiedDate && b.modifiedDate;
      if (x < y) {
        return 1;
      }
      if (u < v) {
        return -1;
      }
      if (x > y) {
        return -1;
      }
      if (u > v) {
        return 1;
      }
      return 0;
    });
    this.setState({ rows: z });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.processRateList && store.processRateList.length > 0)
      if (this.state.processRateList.length !== store.processRateList.length) {
        this.getAllProcessRates(store.processRateList);
      }
    return true;
  }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { processRateList } = this.state;
    if (event.target.checked) {
      const newSelecteds = processRateList
        .filter((x) => x.isActive === true)
        .map((n) => n.id);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, data) {
    let id = data.id;
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedProcessRate: data,
        selectedProcessRateId: id,
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        //selectedProcessRate: {}
        selectedProcessRate: data,
        selectedProcessRateId: id,
      });
    }
  }
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchProcessRate = (handleSearchProcessRate) => {
    let processRateSearchValue = handleSearchProcessRate;
    const dispatch = this.context[1];

    let customer = processRateSearchValue.customerCode;
    let process = processRateSearchValue.processCode;
    if (process === "") process = 0;
    if (customer === "") customer = 0;
    let reqBody = { process, customer };

    getFilteredProcessRateList(reqBody).then((r) => {
      //console.log(r);
      dispatch({
        type: types.PROCESSRATE_LIST,
        newStore: r.data,
      });

      this.getAllProcessRates(r.data);
      // getProcessRates(window.localStorage.token).then(r => {
      //   this.getAllProcessRates(r.data);
      // });
    });
    this.setState({
      loading: true,
      searchPopupOpen: false,
      searchClicked: true,
      page: 0,
    });
  };

  handleDeleteProcessRate = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteProcessRate(modifiedBy, deletionIds).then((r) => {
      getProcessRates().then((r) => {
        this.getAllProcessRates(r.data);
      });
      this.props.history.push("/ProcessRate");
      this.setState({ deletePopupOpen: false, selected: [] });
      //toastr.success("ProcessType Deleted Successfully");
    });
  };

  clearSearch = () => {
    const dispatch = this.context[1];
    getProcessRates().then((r) => {
      this.getAllProcessRates(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      processRateList,
      exportDataValues,
      headCells,
      customersList,
      processList,
    } = this.state;
    const isSelected = (id) => selected.indexOf(id) !== -1;
    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteProcessRate={this.handleDeleteProcessRate}
            selectedProcessRate={this.state.selectedProcessRate}
            selectedProcessRateId={this.state.selectedProcessRateId}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchProcessRate={this.handleSearchProcessRate}
            processRateSearchValue={this.state.processRateSearchValues}
            searchClicked={this.state.searchClicked}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            processRateList={processRateList}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            customersList={this.state.customersList}
            processList={this.state.processList}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {processRateList.length > 0 ? (
                    stableSort(processRateList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              this.handleClick(
                                event,
                                // row.id,
                                //row.customerName
                                row
                              )
                            }
                            processType="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            //key={row.id}
                            key={index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.customerCode} */}
                                {row.customerCode &&
                                  row.customerCode.length > 16 ? (
                                    <Tooltip
                                      title={row.customerCode}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.customerCode}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.customerName}
                                </Typography> */}
                                {row.customerName &&
                                  row.customerName.length > 16 ? (
                                    <Tooltip
                                      title={row.customerName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.customerName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // className="table-content-cell"
                                visibility="hidden"
                                // id={labelId}
                                style={{ borderRight: "1px solid #eaeaea", paddingLeft: ".5em" }}
                              >
                                {row.processName &&
                                  row.processName.length > 16 ? (
                                    <Tooltip
                                      title={row.processName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.processName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.processName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.unitRate && row.unitRate.length > 16 ? (
                                  <Tooltip
                                    title={row.unitRate}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.unitRate}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.unitRate}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {status}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {/* {row.modifiedBy} */}
                                {row.modifiedBy &&
                                  row.modifiedBy.length > 16 ? (
                                    <Tooltip
                                      title={row.modifiedBy}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedBy}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {/* {row.createdBy} */}
                                {row.createdBy && row.createdBy.length > 16 ? (
                                  <Tooltip
                                    title={row.createdBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={12} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={processRateList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(ProcessRateMater);
