import React from "react";

import toastr from "toastr";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TextField, Button, Paper, Divider } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import { addDispatchStyles } from "../../../shared/styles";
import moment from "moment";
import { StateContext } from "../../../shared/globalState";
import LinearProgress from "@material-ui/core/LinearProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";
import DescriptionIcon from "@material-ui/icons/Description";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  uploadDataMappingFiles,
  getSubscriptionForDataMapping,
  downloadFileForDataMapping,
} from "./action";
import { DownloadIcon } from "../../../shared/customIcons";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
const exportToCSV = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

function createData(
  Acronym,
  Volume,
  Issue,
  Supplement,
  Society,
  MemberID,
  Address1,
  Address2,
  Address3,
  Address4,
  Address5,
  State,
  PostalCode,
  Country,
  Quantity,
  Phone,
  Valid,
  ExceptionMessage
) {
  return {
    Acronym,
    Volume,
    Issue,
    Supplement,
    Society,
    MemberID,
    Address1,
    Address2,
    Address3,
    Address4,
    Address5,
    State,
    PostalCode,
    Country,
    Quantity,
    Phone,
    Valid,
    ExceptionMessage,
  };
}
let priorityList = [
  { id: "1", text: "80", value: "80" },
  { id: "2", text: "90", value: "90" },
  { id: "3", text: "95", value: "95" },
];

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    // marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: "23%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    // marginLeft: "7px",
    // marginLeft: "1.5em",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
});

const headCells = [
  {
    id: "acronym",
    numeric: false,
    disablePadding: true,
    label: "Acronym",
  },
  {
    id: "volume",
    numeric: false,
    disablePadding: false,
    label: "Volume",
  },
  {
    id: "issue",
    numeric: false,
    disablePadding: false,
    label: "Issue",
  },
  {
    id: "supp",
    numeric: false,
    disablePadding: false,
    label: "Supplement",
  },
  {
    id: "issueDescription",
    numeric: false,
    disablePadding: false,
    label: "Issue Description",
  },
  {
    id: "titleDescription",
    numeric: false,
    disablePadding: false,
    label: "Title Decription",
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{
              borderLeft: "1px sold #000",
              minWidth: i == 0 ? "0px" : "90px",
            }}
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderTop: "1px solid #eaeaea",
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { } = props;

  return (
    <div>
      {" "}
      <div
        style={{
          display: "flex",
          //   width: "100%",
          border: "1px solid #eaeaea",
          borderBottom: "none",
          borderRadius: "4px",
          margin: "0",
          padding: "10px",
          fontWeight: "bold",
        }}
      >
        {" "}
        Journal Information
      </div>
    </div>
  );
};

function DetectionControl(file) {
  return (
    <div
      style={{
        width: "45%",
        display: "flex",
        alignItems: "center",
        position: "relative",
        top: "-3px",
      }}
    >
      <span>
        {file && file.fileName && file.fileName.isValid == true ? (
          <CheckCircleIcon
            style={{ color: "rgba(59, 218, 182, 0.87)", fontSize: "19px" }}
          />
        ) : (
            <InfoIcon style={{ color: "red", fontSize: "19px" }} />
          )}
      </span>
      <span
        style={{
          marginTop: "-5px",
          marginLeft: "5px",
          fontSize: "11px",
        }}
      >
        {file && file.fileName && file.fileName.isValid == true
          ? "Uploaded successfully"
          : file &&
            file.fileName &&
            file.fileName.message &&
            file.fileName.message.includes("MissMatch")
            ? "Template Mismatch"
            : file &&
              file.fileName &&
              file.fileName.message == undefined &&
              file &&
              file.fileName &&
              file.fileName.isValid == undefined
              ? "Error Records Found, please download the exception file for more details"
              : (file &&
                file.fileName &&
                file.fileName.message.includes("Template MissMatch")) ||
                (file &&
                  file.fileName &&
                  file.fileName.message.includes("MissMatch"))
                ? "Template Mismatch"
                : file && file.fileName && file.fileName.message}
      </span>
    </div>
  );
}

class DataMapping extends React.Component {
  static contextType = StateContext;
  constructor() {
    super();
    this.state = {
      open: false,
      customer: "Wiley",
      fileType: {},
      priority: {},
      userId: "",
      fileList: [],
      loading: false,
      loading1: false,
      stateFiles: [],
      firstCall: true,
      submitLoading: false,
      showError: true,
      list: [],
      fileName: "",
      editableList: [],
      value: 0,
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const [{ store }, dispatch] = this.context;
    this.props.handleSettingHeader("Data Mapping");
    this.props.setSelectedList("publisherMainLabelOpen", "dataMapping");
    document.addEventListener("mousedown", this.handleClickOutside);

    getSubscriptionForDataMapping().then((val) => {
      this.setState({
        fileList: val.data,
      });
    });
  }

  handleChange = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleAutocomplete = (e, val) => {
    if (e.target.id.split("-option")[0] == "fileType") {
      this.setState({
        fileTypeID: val && val.id,
        fileType: val && val,
        stateFiles: [],
        list: [],
        editableList: [],
        value: 0,
        showError: false,
        fileName: "",
      });
      this.myRef.current.value = "";
    } else {
      this.setState({ priorityID: val && val.id, priority: val && val });
    }
  };

  handleUpload = async (e) => {
    let files = e.target.files;
    var reader = new FileReader();
    const formData = new FormData();


    if (files[0]) {
      this.setState({
        list: [],
        listDataMappingOrderDetails: {},
        fileName: files[0].name,
      });

      let x = false
      if ((files[0].size) <= (1024 * 5000)) {
        x = true
        formData.append("UploadFiles", files[0]);
      }
      else {
        toastr.error(`${files[0].name} cann't be processed. Max file size limit is 5 MB`)
        return
      }
      if (files[0].name.split(".")[1] != "xlsx") {
        toastr.error("Unsupported File Format");
        await this.setState({
          loading1: false,
        });
      } else {
        reader.onload = function (e) {
          let data = e.target.result;
          let readedData = XLSX.read(data, { type: "binary" });
          const wsname = readedData.SheetNames[0];
          const ws = readedData.Sheets[wsname];
          const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
        };
        reader.readAsBinaryString(files[0]);
        let loggedInId = localStorage.getItem("loggedInId");

        formData.append("SubscriptionID", this.state.fileType.id);
        formData.append("Priority", this.state.priority.value);
        formData.append("CreatedBy", loggedInId);
        if (x) {
          this.setState({
            loading1: true,
            loading: true
          });
          uploadDataMappingFiles(formData)
            .then((r) => {
              let newList = [];
              r &&
                r._DataMappingAVISResponse &&
                r._DataMappingAVISResponse.map((res) => {
                  newList.push({
                    acronym: res.acronym,
                    volume: res.volume,
                    issue: res.issue,
                    supp: res.supp,
                    issueDescription: res.issueDescription,
                    journalTitle: res.tittleDescription,
                  });
                });

              let stf = [];
              let EXCEPTIONMESSAGE =
                r &&
                r.length > 0 &&
                r.map((val) => {
                  return val.exceptionMessage;
                });
              let ISVALID =
                r &&
                r.length > 0 &&
                r.map((val) => {
                  return val.isValid;
                });
              let VALIDATIONID =
                r &&
                r.length > 0 &&
                r.map((val) => {
                  return val.validationID;
                });

              stf.push({
                name: (r && r.fileName) || (files && files[0].name),
                size: files && files[0].size,
                message:
                  (r && r.exceptionMessage) ||
                  (EXCEPTIONMESSAGE && EXCEPTIONMESSAGE[0]),
                isValid: r.isValid || (ISVALID && ISVALID[0]),
                validationID: r.validationID || (VALIDATIONID && VALIDATIONID[0]),
              });

              this.setState({
                list: stf,
                editableList: newList,
                disableEdit: false,
                loading: false,
                loading1: false,
                listDataMappingOrderDetails: r.listDataMappingOrderDetails,
              });
            })
            .catch((e) => { });
        }

      }
    } else {
      this.setState({
        fileName: "",
        loading1: false,
      });
    }
  };

  checkOGDisability = () => {
    if (!this.state.fileTypeID || !this.state.priorityID) return true;
    else return false;
  };

  deleteSelectedFile = async (i, id) => {
    let newStateFiles = [...this.state.list];
    // // let newFileList = [...this.state.fileList];
    // id && deleteOrder(id, window.localStorage.loggedInId).then(r => {});
    newStateFiles.splice(i, 1);
    // // newFileList.splice(i, 1);
    await this.setState({ list: newStateFiles });
  };

  downloadSelectedFile = async (id, name) => {
    name = name.slice(0, name.length - 5);
    // name = name + "_" + moment(new Date()).format("DD/MM/YYYY");

    let dataMappingid =
      this.state.list && this.state.list[0] && this.state.list[0].validationID;
    downloadFileForDataMapping(dataMappingid).then((r) => {
      let newRows = [];
      r &&
        r.data &&
        r.data.map((d) => {
          newRows.push(
            createData(
              d.journalAcronym,
              d.vol,
              d.issue,
              d.supp,
              d.society,
              d.memberID,
              d.add1,
              d.add2,
              d.add3,
              d.add4,
              d.add5,
              d.state,
              d.postalCode,
              d.country,
              d.quantity,
              d.phone,
              d.isValid,
              d.exceptionMessage
            )
          );
        });
      exportToCSV(newRows, name);
    });
  };

  downloadTxtFile = async (data) => {
    let dummy = this.convertToCSV(this.state.listDataMappingOrderDetails);
    const element = document.createElement("a");
    const file = new Blob([dummy], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download =
      `${this.state.fileName && this.state.fileName.split(".")[0]}` + ".txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  convertToCSV = (objArray) => {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";
    array.forEach((a) => {
      let objValue = a;
      // objValue.sentDate = moment(objValue.sentDate).format("DD/MM/YYYY");
      // objValue.receivedDate = moment(objValue.sentDate).format("DD-MM-YYYY");
      str +=
        Object.values(objValue)
          .map((a) => (a == null ? `""` : `"${a}"`))
          .join() + "\r\n";
    });

    return str;
  };

  clearData = () => {
    this.myRef.current.value = "";
    this.setState({
      stateFiles: [],
      list: [],
      editableList: [],
      value: 0,
      showError: false,
      fileName: "",
      fileType: {},
      fileTypeID: "",
      priorityID: "",
      priority: {}
    });
  };

  render() {
    let { classes } = this.props;
    const [{ store }, dispatch] = this.context;
    let { fileType, priority, fileName, list } = this.state;
    let acronymList =
      this.state._DataMappingAVISResponse &&
      this.state._DataMappingAVISResponse.reduce(
        (a, o) => (o.acronym && a.push(o.acronym), a),
        []
      );
    return (
      <React.Fragment>
        {" "}
        <Paper>
          <div className="pop-up-header">Data Mapping</div>
          <Divider />
          <div
            className={classes.container}
            style={{ paddingBottom: this.state.list.length == 0 && "1em" }}
          >
            <div>
              <form className={classes.container}>
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  <TextField
                    id="standard-required"
                    label="Customer"
                    name="recipient"
                    margin="normal"
                    variant="filled"
                    value={this.state.customer}
                    inputProps={{
                      maxLength: 50,
                      readOnly: true,
                    }}
                    style={{ flex: 1, marginRight: "16px" }}
                    autoComplete="off"
                  // onChange={handleSearchInput}
                  />
                  <Autocomplete
                    options={this.state.fileList}
                    value={fileType}
                    id="fileType"
                    onChange={this.handleAutocomplete}
                    getOptionLabel={(option) => option.text}
                    style={{ flex: 1, marginRight: "16px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="File Type"
                        margin="normal"
                        required
                        fullWidth
                      />
                    )}
                  />
                  <Autocomplete
                    options={priorityList}
                    value={priority}
                    id="priority"
                    onChange={this.handleAutocomplete}
                    getOptionLabel={(option) => option.text}
                    style={{ flex: 1 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Priority"
                        fullWidth
                        margin="normal"
                        required
                      />
                    )}
                  />
                </div>
              </form>
            </div>

            <div
              style={{
                width: "auto",
                border: "2px dashed #eaeaea",
                margin: "0px 1em",
                borderRadius: "4px",
              }}
            >
              <label
                className="upload excel"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  minHeight: "40px",
                  // cursor: "pointer",
                  padding: ".3em",
                }}
              >
                <input
                  disabled={this.checkOGDisability()}
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => this.handleUpload(e)}
                  accept=".xlsx"
                  ref={this.myRef}
                />
                <div
                  style={
                    this.checkOGDisability()
                      ? {
                        backgroundColor: "#cccccc",
                        color: "#515151",
                        borderRadius: "4px",
                        padding: ".3em 1em .3em 1em",
                      }
                      : {
                        backgroundColor: "#3982ea",
                        color: "#fff",
                        borderRadius: "4px",
                        padding: ".3em 1em .3em 1em",
                        cursor: "pointer",
                      }
                  }
                >
                  CHOOSE FILE
                </div>
                <div
                  style={{
                    display: "inline-block",
                    width: "auto",
                    marginLeft: "12px",
                    width: "320px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "13px",
                    }}
                  >
                    {this.checkOGDisability()
                      ? "Please fill all the required fields before selecting a file"
                      : fileName
                        ? fileName
                        : "No file choosen"}
                  </div>
                  <div className="file-upload-footer">
                    {this.checkOGDisability()
                      ? ""
                      : "Allowed only “.xlsx” format, 5 MB file limit."}
                  </div>
                </div>
              </label>
            </div>
            {!this.state.loading1 ? (
              <div>
                <div style={{ margin: "0 1em" }}>
                  {list.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        margin: "1em 0 1em",
                        // marginTop: "2em",
                        paddingBottom: ".5em",
                        borderBottom: "1px solid #eaeaea",
                        fontSize: "14px",
                      }}
                    >
                      <div style={{ width: "30%", fontWeight: "500" }}>
                        File Info
                      </div>
                      <div
                        style={{
                          width: "10%",
                          fontWeight: "500",
                          margin: "0 10px",
                        }}
                      >
                        Size
                      </div>
                      <div style={{ width: "45%", fontWeight: "500" }}>
                        Detection
                      </div>
                      {this.state.list.length > 0 &&
                        this.state.list[0].isValid == false && this.state.list[0].validationID != 0 && (
                          // this.state.list[0].message.includes("download") &&
                          // this.state.list[0].message.includes("download") &&
                          // this.state.list[0].message.includes("download") && (
                          <div
                            style={{
                              width: "15%",
                              fontWeight: "500",
                              textAlign: "center",
                            }}
                          >
                            Action
                          </div>
                        )}
                    </div>
                  )}
                  {list &&
                    list.map((file, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            margin: "1em 0 2em",
                            paddingBottom: ".5em",
                            borderBottom: "1px solid #eaeaea",
                            fontSize: "12px",
                          }}
                        >
                          <div style={{ width: "30%", fontWeight: "500" }}>
                            <div style={{ display: "flex" }}>
                              <DescriptionIcon
                                style={{
                                  color: "#306dca",
                                  marginTop: "-4px",
                                  marginRight: "5px",
                                }}
                              />{" "}
                              <span> {file.name}</span>
                            </div>
                          </div>
                          <div style={{ width: "10%", margin: "0 10px" }}>
                            {Math.ceil(file.size / 1024) + " kb"}
                            {/* {file.size} */}
                          </div>

                          {!this.state.loading ? (
                            <DetectionControl fileName={file} />
                          ) : (
                              <div style={{ width: "45%", marginTop: ".6em" }}>
                                <LinearProgress />
                              </div>
                            )}
                          {this.state.list.length > 0 &&
                            this.state.list[0].isValid == false && this.state.list[0].validationID != 0 && (
                              // file.message.indexOf("download") !== -1 &&
                              // file.message.indexOf("download") !== -1 &&
                              // file.message.indexOf("download") !== -1 && (

                              <IconButton
                                size="small"
                                style={{
                                  position: "relative",
                                  top: "-6px",
                                  left: "40px",
                                }}
                                onClick={(e) => {
                                  this.downloadSelectedFile(
                                    file.validationID,
                                    file.name
                                  );
                                }}
                              >
                                <DownloadIcon style={{ fontSize: "16px" }} />
                              </IconButton>
                            )}
                        </div>
                      );
                    })}
                </div>
                <div style={{ margin: "0 1em" }}>
                  {this.state.list.length > 0 && (
                    <div>
                      <EnhancedTableToolbar
                        //numSelected={selected.length}
                        handleClickButton={this.handleClickButton}
                        disableAdd={this.state.disableAdd}
                        disableEdit={this.state.disableEdit}
                      />
                      {this.state.list.length > 0 && (
                        <div style={{ maxHeight: 350, overflow: "auto" }}>
                          {/* //overflow: "auto" */}
                          <Table
                            aria-labelledby="tableTitle"
                            size="small"
                            stickyHeader
                            aria-label="sticky table"
                          >
                            <EnhancedTableHead />

                            {!this.state.loading ? (
                              <TableBody style={{ overflow: "hidden" }}>
                                {this.state.editableList.map((row, index) => {
                                  const labelId = `enhanced-table-checkbox-${index}`;
                                  return (
                                    <TableRow
                                      id={`tableRow${index}`}
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={index}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        {row.acronym}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        {row.volume}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        {row.issue}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        {row.supp}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        {row.issueDescription}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        {row.journalTitle}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            ) : (
                                <TableBody>
                                  <TableRow>
                                    <TableCell colSpan="9">
                                      <LinearProgress />
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              )}
                          </Table>
                        </div>
                      )}
                    </div>
                  )}

                  {this.state.list.length > 0 && (
                    <div
                      className="button-wrapper"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {((this.state.fileType.text === "OM" ||
                        this.state.fileType.text === "HC") &&
                        this.state._DataMappingAVISResponse &&
                        this.state._DataMappingAVISResponse.length > 1) ||
                        (acronymList &&
                          acronymList.every((val, i, arr) => val === arr[0]) &&
                          this.state.fileType.text === "OB" &&
                          this.state._DataMappingAVISResponse &&
                          this.state._DataMappingAVISResponse.length > 0) ||
                        (this.state.list &&
                          this.state.list[0].isValid == undefined) ||
                        (this.state.list &&
                          this.state.list[0].isValid == false) ? (
                          <Button
                            disabled
                            variant="contained"
                            color="primary"
                            style={{
                              marginRight: "1em",
                              alignSelf: "flex-end",
                              boxShadow: "none",
                            }}
                          >
                            Download .txt
                          </Button>
                        ) : (
                          <React.Fragment>
                            <TextField
                              required
                              label="Save As"
                              name="fileName"
                              inputProps={{
                                maxLength: 50,
                              }}
                              onKeyPress={this._freetext}
                              margin="normal"
                              className={classes.textField}
                              value={fileName && fileName.split(".")[0]}
                              onChange={this.handleChange}
                              style={{
                                width: "31%",
                                marginRight: "1em",
                                marginLeft: "0",
                                marginBottom: 0,
                              }}
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => this.downloadTxtFile()}
                              style={{
                                marginRight: "1em",
                                alignSelf: "flex-end",
                                boxShadow: "none",
                              }}
                            >
                              Download .txt
                          </Button>
                          </React.Fragment>
                        )}
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ alignSelf: "flex-end", boxShadow: "none" }}
                        onClick={() => this.clearData()}
                      >
                        Clear Data
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            ) : (
                <Dialog
                  aria-labelledby="simple-dialog-title"
                  open={this.state.loading1}
                >
                  <div style={{ padding: "1em" }}>
                    <CircularProgress />
                  </div>
                </Dialog>
              )}
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(addDispatchStyles)(DataMapping));
