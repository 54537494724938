import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import clone from "clone";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { resourceValidation } from "../../../shared/resource";
import moment from "moment";
import AdvanceSearch from "./search";
import {
  SearchIcon,
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import {
  stableSort,
  getSorting,
  DialogActions,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import Validator from "../../../shared/validator";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import { StateContext } from "../../../shared/globalState";
import { getEMLOList, deleteEMLO, seacrhEmloAdvanceSearch } from "./action";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ExportCSV } from "../../../shared/ExportCSV";
import StatusText from "../../../shared/statusText";

const columns = [
  {
    name: "Modified By",
    id: 20,
  },
  {
    name: "Modified Date",
    id: 21,
    // show: false
  },
  {
    name: "Created By",
    id: 22,
  },
  {
    name: "Created Date",
    id: 23,
  },
];
const notRequiredAlpNumHypDot50 = new Validator("notRequiredAlpNumHypDot50");

function createData(
  emloOrderID,
  client,
  type,
  status,
  emloNo,
  acronym,
  volume,
  issue,
  part,
  supp,
  qty,
  country,
  recipient,
  orderDate,
  deliveryDate,
  trackingNumber,
  noOfCartons,
  dhlRate,
  totalWeight,
  specialInstruction,
  modifiedByName,
  modifiedDate,
  createdByName,
  createdDate
) {
  return {
    emloOrderID,
    client,
    type,
    status,
    emloNo,
    acronym,
    volume,
    issue,
    part,
    supp,
    qty,
    country,
    recipient,
    orderDate,
    deliveryDate,
    trackingNumber,
    noOfCartons,
    dhlRate,
    totalWeight,
    specialInstruction,
    modifiedByName,
    modifiedDate,
    createdByName,
    createdDate
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          {/* <FormControlLabel
            control={
              <CustomCheckbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all production code" }}
              />
            }
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    client: "",
    emlo: "",
    type: "",
    status: "",
    description: "",
    acronym: "",
    volume: "",
    issue: "",
    part: "",
    support: "",
    trackingNumber: "",
    orderDate: "",
    orderCreated: "",
    department: "",
    issueType: "",
    category: "",
    quantity: "",
    deliveryDate: "",
  });
  const [formValid, setIsformValid] = useState(false);
  let acnArr = props.roles.filter((u) => u.menuName === "EMLO Order Placement");
  let acnObj = acnArr[0];
  const [emploSearchValue, setValues] = useState({
    client: "",
    emlo: "",
    type: "",
    status: "",
    description: "",
    acronym: "",
    volume: "",
    issue: "",
    part: "",
    support: "",
    trackingNumber: "",
    orderDate: "",
    orderCreated: "",
    department: "",
    issueType: "",
    category: "",
    quantity: "",
    deliveryDate: "",
  });

  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,

    handleDeleteEMLO,
    searchPopupOpen,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchemloCode,
    searchClicked,

    hendleVewColums,
    codeTypeList,
    exportDataValues,
    selected,
    isActive,
  } = props;

  //const selectedEMLOID = selected && selected.length > 0 && selected[0];
  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  const selectedEMLOID = selected[0];
  const selectedemloID = codeTypeList.find(
    (x) => x.codeTypeID === selectedEMLOID
  );

  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteProductionLineCode = () => {
    handleDeleteEMLO();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = emploSearchValue;
    searchValues.client = "";
    searchValues.emlo = "";
    searchValues.status = "";
    searchValues.type = "";
    searchValues.description = "";
    searchValues.acronym = "";
    searchValues.volume = "";
    searchValues.issue = "";
    searchValues.part = "";
    searchValues.support = "";
    searchValues.trackingNumber = "";
    searchValues.orderDate = "";
    searchValues.orderCreated = "";
    searchValues.department = "";
    searchValues.issueType = "";
    searchValues.category = "";
    searchValues.quantity = "";
    searchValues.deliveryDate = "";
    searchValues = emploSearchValue;
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
  };

  let expData = clone(
    props.codeTypeList && props.codeTypeList.length > 0 && props.codeTypeList
  );
  expData &&
    expData.length > 0 &&
    expData.map((d) => {
      // d["emloOrderID"]=d["emloOrderID"];
      d["Customer"] = d["client"];
      d["Type"] = d["type"];
      d["Status"] = d["status"];
      d["EMLO No"] = d["emloNo"];
      d["Acronym"] = d["acronym"];
      d["Volume"] = d["volume"];
      d["Issue"] = d["issue"];
      d["Part"] = d["part"];
      d["Supp"] = d["supp"];
      d["Quantity"] = d["qty"];
      d["Country"] = d["country"];
      d["Recipient"] = d["recipient"];
      d["Order Date"] = d["orderDate"] ? moment(d["orderDate"]).format("DD/MM/YYYY") : ""
      d["Delivery Date"] = d["deliveryDate"] ? moment(d["deliveryDate"]).format("DD/MM/YYYY") : "";
      d["Tracking Number"] = d["trackingNumber"];
      d["No Of Cartons"] = d["noOfCartons"];
      d["DHL Rate"] = d["dhlRate"];
      d["Total Weight"] = d["totalWeight"];
      d["Special Instruction"] = d["specialInstruction"];
      d["Modified By Name"] = d["modifiedByName"];
      d["Modified Date"] = moment(d["modifiedDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      d["Created By Name"] = d["createdByName"];
      d["Created Date"] = moment(d["createdDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      delete d["emloOrderID"];
      delete d["status"];
      delete d["volume"];
      delete d["modifiedDate"];
      delete d["modifiedByName"];
      delete d["createdDate"];
      delete d["createdByName"];
      delete d["client"];
      delete d["emloNo"];
      delete d["type"];
      delete d["acronym"];
      delete d["issue"];
      delete d["part"];
      delete d["supp"];
      delete d["trackingNumber"];
      delete d["deliveryDate"];
      delete d["qty"];
      delete d["country"];
      delete d["noOfCartons"];
      delete d["recipient"];
      delete d["orderDate"];
      delete d["totalWeight"];
      delete d["dhlRate"];
      delete d["specialInstruction"];
    });
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_pubEmloOrderPlacement/${selectedEMLOID}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj &&
                  acnObj.editAction &&
                  props.selectedemloID.status != "Cancelled" &&
                  props.selectedemloID.status !== "Completed" &&
                  props.selectedemloID.status != "In-Progress" && (
                    <Tooltip title="Edit">
                      <Link
                        to={`/edit_pubEmloOrderPlacement/${selectedEMLOID}`}
                      >
                        <IconButton
                          aria-label="edit"
                          className={classes.iconHover}
                        >
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  )}
              </div>
            )}
            {acnObj &&
              acnObj.deleteAction &&
              props.selectedemloID.status != "Cancelled" &&
              props.selectedemloID.status !== "Completed" &&
              props.selectedemloID.status != "Processed" &&
              props.selectedemloID.status != "In-Progress" && (
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    onClick={handleClickOpen}
                  // disabled={!isActive}
                  >
                    <DeleteIcon className={classes.iconHover} />
                  </IconButton>
                </Tooltip>
              )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1 ? " ?" : props.selectedRows[1] + " ?"}
                    {/* {selectedemloID.codeType} */}
                  </span>
                </div>
              </DialogContent>
              <DialogActions style={{ padding: "10px 24px" }}>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteProductionLineCode}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                //className="enabled-button"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              {acnObj && acnObj.approveAction && <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => props.history.push("/pubEmloBatchProcessing")}
              >
                {" "}
              EMLO Batch Processing{" "}
              </Button>}
              {searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  acnObj &&
                  acnObj.advanceSearchAction && (
                    <AdvanceSearch
                      onSearchClicked={props.onSearchClicked}
                      onRequestSucceed={props.onRequestSucceed}
                    />
                  )
                )}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="add"
                    className={classes.iconHover}
                    onClick={(e) =>
                      props.history.push("/add_pubEmloOrderPlacement")
                    }
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {acnObj && acnObj.exportAction && (
                <ExportCSV csvData={expData} fileName={"EMLO_ORDER_PLACEMENT"} />
              )}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                hendleVewColums={hendleVewColums}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class emloCodeMater extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    codeTypeList: [],
    rows: [],
    selectedRows: [],
    activeRows: 0,
    isActive: false,
    loading: false,
    refreshData: false,
    selectedemloID: {},
    selectedemloIDId: 1,
    searchPopupOpen: false,
    emploSearchValues: {},
    searchClicked: false,
    selectedShowColumns: [],
    exportDataValues: [],
    headCells: [
      {
        id: 1,
        code: "client",
        numeric: false,
        disablePadding: false,
        label: "Customer",
        show: true,
      },
      {
        id: 2,
        code: "type",
        numeric: false,
        disablePadding: false,
        label: "Type",
        show: true,
      },
      {
        id: 3,
        code: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        show: true,
      },
      {
        id: 4,
        code: "emloNo",
        numeric: false,
        disablePadding: false,
        label: "EMLO",
        show: true,
      },
      {
        id: 5,
        code: "acronym",
        numeric: false,
        disablePadding: false,
        label: "Acronym",
        show: true,
      },
      {
        id: 6,
        code: "volume",
        numeric: false,
        disablePadding: false,
        label: "Volume",
        show: true,
      },
      {
        id: 7,
        code: "issue",
        numeric: false,
        disablePadding: false,
        label: "Issue",
        show: true,
      },
      {
        id: 8,
        code: "part",
        numeric: false,
        disablePadding: false,
        label: "Part",
        show: true,
      },
      {
        id: 9,
        code: "supp",
        numeric: false,
        disablePadding: false,
        label: "Supp",
        show: true,
      },
      {
        id: 10,
        code: "qty",
        numeric: false,
        disablePadding: false,
        label: "Quantity",
        show: true,
      },
      {
        id: 11,
        code: "country",
        numeric: false,
        disablePadding: false,
        label: "Country",
        show: true,
      },
      {
        id: 12,
        code: "recipient",
        numeric: false,
        disablePadding: false,
        label: "Recipient",
        show: true,
      },
      {
        id: 13,
        code: "orderDate",
        numeric: false,
        disablePadding: false,
        label: "Order Date",
        show: true,
      },
      {
        id: 14,
        code: "deliveryDate",
        numeric: false,
        disablePadding: false,
        label: "Delivery Date",
        show: true,
      },
      {
        id: 15,
        code: "trackingNumber",
        numeric: false,
        disablePadding: false,
        label: "Tracking Number",
        show: true,
      },
      {
        id: 16,
        code: "noOfCartons",
        numeric: false,
        disablePadding: false,
        label: "No Of Cartons",
        show: true,
      },
      {
        id: 17,
        code: "dhlRate",
        numeric: false,
        disablePadding: false,
        label: "DHL Rate",
        show: true,
      },
      {
        id: 18,
        code: "totalWeight",
        numeric: false,
        disablePadding: false,
        label: "Total Weight",
        show: true,
      },
      {
        id: 19,
        code: "specialInstruction",
        numeric: false,
        disablePadding: false,
        label: "Special Instructions",
        show: true,
      },
      {
        id: 20,
        code: "modifiedByName",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 21,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 22,
        code: "createdByName",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
      {
        id: 23,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
    ],
    showMessage: false,
  };

  onSearchClicked = (reqBody) => {
    this.setState({ codeTypeList: [], rows: [], selected: [], loading: true });
    seacrhEmloAdvanceSearch(reqBody).then((r) => {
      this.getEMLO(r);
    });
    this.setState({ searchClicked: true });
  };
  clearSearch = async () => {
    this.setState({ codeTypeList: [], rows: [], selected: [], loading: true });
    await getEMLOList().then((r) => {
      this.getEMLO(r.data);
    });
    this.setState({ searchClicked: false });
  };
  componentDidMount() {
    this.props.handleSettingHeader("EMLO Order Placement");
    this.props.setSelectedList(
      "publisherMainLabelOpen",
      "pubEmloOrderPlacement"
    );
    const dispatch = this.context[1];

    this.setState({ loading: true });
    getEMLOList().then((r) => {
      this.getEMLO(r.data);
      this.exportemloDatas(r.data);
    });
  }

  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  exportemloDatas = (codeTypeList) => {
    let list = codeTypeList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          Client: d.client,
          EMLO: d.emlo,
          Type: d.type,
          Description: d.description,
          Acronym: d.acronym,
          Volume: d.volume,
          Issue: d.issue,
          Part: d.part,
          Support: d.support,
          TrackingNumber: d.trackingNumber,
          OrderData: d.orderDate,
          OrderCreated: d.orderCreated,
          Department: d.department,
          IssueType: d.issueType,
          Category: d.category,
          Quantity: d.quantity,
          DeliveryDate: d.deliveryDate,
          ModifiedBy: d.modifiedBy,
          ModifiedDate: d.modifiedDate,
          CreatedBy: d.createdBy,
          CreatedDate: d.createdDate,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getEMLO = (codeTypeList) => {
    // const [{ store }, dispatch] = this.context;
    let list = codeTypeList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.emloOrderID,
            d.client,
            d.type,
            d.status,
            d.emloNo,
            d.acronym,
            d.volume,
            d.issue,
            d.part,
            d.supp,
            d.quantity,
            d.countryName,
            d.recipientName,
            d.orderDate,
            d.deliveryDate,
            d.trackingNumber,
            d.noOfCartons,
            d.dhlRate,
            d.actualWeight,
            d.additionalInstructions,
            d.modifiedByName,
            d.modifiedDate,
            d.createdByName,
            d.createdDate
          )
        );
      });
    this.setState({
      codeTypeList: rows,
      selected: [],
      loading: false,
      activeRows: rows.filter((x) => x.isActive).map((n) => n.codeTypeID),
    });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    return true;
  }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleClick(event, data, id) {
    let newSelected = [];
    let selected = [];
    let newSelectedRows = [];
    if (event.target.checked) {
      newSelected = newSelected.concat(selected, id.emloOrderID);
      newSelectedRows = newSelected.concat(id.emloNo);
      this.setState({
        selected: newSelected,
        selectedemloID: id,
        selectedemloIDId: id.emloOrderID,
        selectedRows: newSelectedRows,
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedemloID: id,
        selectedemloIDId: id.emloOrderID,
        selectedRows: newSelectedRows,
      });
    }
  }

  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchemloCode = (handleSearchemloCode) => {
    this.setState({ searchPopupOpen: false, searchClicked: true });
    let emploSearchValue = handleSearchemloCode;
    const dispatch = this.context[1];
    let client = emploSearchValue.client;
    let emlo = emploSearchValue.emlo;
    let type = emploSearchValue.type;
    let description = emploSearchValue.description;
    let acronym = emploSearchValue.acronym;
    let volume = emploSearchValue.volume;
    let issue = emploSearchValue.issue;
    let part = emploSearchValue.part;
    let support = emploSearchValue.support;
    let trackingNumber = emploSearchValue.trackingNumber;
    let orderDate = emploSearchValue.orderDate;
    let orderCreated = emploSearchValue.orderCreated;
    let department = emploSearchValue.department;
    let issueType = emploSearchValue.issueType;
    let category = emploSearchValue.category;
    let quantity = emploSearchValue.quantity;
    let deliveryDate = emploSearchValue.deliveryDate;

    let reqBody = {
      client,
      emlo,
      type,
      description,
      acronym,
      volume,
      issue,
      part,
      support,
      trackingNumber,
      orderDate,
      orderCreated,
      department,
      issueType,
      category,
      quantity,
      deliveryDate,
    };
  };

  handleDeleteEMLO = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteEMLO(modifiedBy, deletionIds).then((r) => {
      getEMLOList().then((r) => {
        this.getEMLO(r.data);
        this.exportemloDatas(r.data);
      });
      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };

  clearSearch = () => {
    const dispatch = this.context[1];

    this.setState({ codeTypeList: [], rows: [], selected: [], loading: true });
    getEMLOList().then((r) => {
      this.getEMLO(r.data);
    });
    this.setState({ searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      codeTypeList,
      headCells,
      exportDataValues,
    } = this.state;
    const isSelected = (codeTypeID) => selected.indexOf(codeTypeID) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteEMLO={this.handleDeleteEMLO}
            selectedemloID={this.state.selectedemloID}
            selectedemloIDId={this.state.selectedemloIDId}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchemloCode={this.handleSearchemloCode}
            emploSearchValue={this.state.emploSearchValues}
            searchClicked={this.state.searchClicked}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            codeTypeList={codeTypeList}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            onSearchClicked={this.onSearchClicked}
            rows={this.state.rows}
            selectedRows={this.state.selectedRows}
            showMessage={this.state.showMessage}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
                style={{ whiteSpace: "nowrap" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {codeTypeList.length > 0 ? (
                    stableSort(codeTypeList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.emloOrderID); //paste the given id
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              this.handleClick(
                                event,
                                row.emloOrderID,
                                row,
                                row.emloNo
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.emloOrderID}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <CustomCheckbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.client && row.client.length > 16 ? (
                                    <Tooltip title={row.client}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.client}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.client}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}

                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.type && row.type.length > 16 ? (
                                  <Tooltip title={row.type}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.type}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.type}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.status && row.status.length > 10 ? (
                                    <Tooltip title={row.status}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.status == "Submitted" ? (
                                          <StatusText color={"#16831F"}>
                                            Submitted
                                          </StatusText>
                                        ) : row.status == "UnSubmitted" ? (
                                          <StatusText color={"#F4B43C"}>
                                            UnSubmitted
                                          </StatusText>
                                        ) : row.status == "Processed" ? (
                                          <StatusText color={"#0091ff"}>
                                            Processed
                                          </StatusText>
                                        ) : row.status == "In-Progress" ? (
                                          <StatusText color={"#0091ff"}>
                                            In-Progress
                                          </StatusText>
                                        ) : row.status == "Cancelled" ? (
                                          <StatusText color={"red"}>
                                            Cancelled
                                          </StatusText>
                                        ) : row.status == "Completed" ? (
                                          <StatusText color={"#16831F"}>
                                            Completed
                                          </StatusText>
                                        ) : (
                                                      <StatusText color={"grey"}>
                                                        {row.status}
                                                      </StatusText>
                                                    )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.status == "Submitted" ? (
                                          <StatusText color={"#16831F"}>
                                            Submitted
                                          </StatusText>
                                        ) : row.status == "UnSubmitted" ? (
                                          <StatusText color={"#F4B43C"}>
                                            UnSubmitted
                                          </StatusText>
                                        ) : row.status == "Processed" ? (
                                          <StatusText color={"#0091ff"}>
                                            Processed
                                          </StatusText>
                                        ) : row.status == "In-Progress" ? (
                                          <StatusText color={"#0091ff"}>
                                            In-Progress
                                          </StatusText>
                                        ) : row.status == "Cancelled" ? (
                                          <StatusText color={"red"}>
                                            Cancelled
                                          </StatusText>
                                        ) : row.status == "Completed" ? (
                                          <StatusText color={"#16831F"}>
                                            Completed
                                          </StatusText>
                                        ) : (
                                                      <StatusText color={"grey"}>
                                                        {row.status}
                                                      </StatusText>
                                                    )}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.emloNo && row.emloNo.length > 16 ? (
                                  <Tooltip title={row.emloNo}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.emloNo}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.emloNo}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.acronym && row.acronym.length > 16 ? (
                                  <Tooltip title={row.acronym}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.acronym}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.acronym}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.volume && row.volume.length > 16 ? (
                                  <Tooltip title={row.volume}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.volume}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.volume}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.issue && row.issue.length > 16 ? (
                                  <Tooltip title={row.issue}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issue}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issue}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.part && row.part.length > 16 ? (
                                  <Tooltip title={row.part}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.part}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.part}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.supp && row.supp.length > 16 ? (
                                  <Tooltip title={row.supp}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.supp}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.supp}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.qty && row.qty.length > 16 ? (
                                  <Tooltip title={row.qty}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.qty}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.qty}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.country &&
                                  row.country.length > 16 ? (
                                    <Tooltip title={row.country}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.country}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.country}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.recipient &&
                                  row.recipient.length > 16 ? (
                                    <Tooltip title={row.recipient}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.recipient}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.recipient}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.orderDate &&
                                  moment(row.orderDate).format("DD/MM/YYYY")
                                    .length > 16 ? (
                                    <Tooltip
                                      title={
                                        row.orderDate
                                          ? moment(row.orderDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""
                                      }
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.orderDate
                                          ? moment(row.orderDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.orderDate
                                        ? moment(row.orderDate).format(
                                          "DD/MM/YYYY"
                                        )
                                        : ""}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.deliveryDate &&
                                  moment(row.deliveryDate).format("DD/MM/YYYY")
                                    .length > 16 ? (
                                    <Tooltip
                                      title={
                                        row.deliveryDate
                                          ? moment(row.deliveryDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""
                                      }
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.deliveryDate
                                          ? moment(row.deliveryDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.deliveryDate
                                        ? moment(row.deliveryDate).format(
                                          "DD/MM/YYYY"
                                        )
                                        : ""}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.trackingNumber && row.trackingNumber.length > 12 ? (
                                  <Tooltip title={row.trackingNumber}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.trackingNumber}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.trackingNumber}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[15].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.noOfCartons && row.noOfCartons.length > 16 ? (
                                  <Tooltip title={row.noOfCartons}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noOfCartons}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noOfCartons}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[16].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.dhlRate && row.dhlRate.length > 16 ? (
                                  <Tooltip title={row.dhlRate}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dhlRate}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dhlRate}
                                    </Typography>
                                  )}
                              </TableCell>

                            )}
                            {this.state.headCells[17].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.totalWeight && row.totalWeight.length > 16 ? (
                                  <Tooltip title={row.totalWeight}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalWeight}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalWeight}
                                    </Typography>
                                  )}
                              </TableCell>

                            )}
                            {this.state.headCells[18].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.specialInstruction && row.specialInstruction.length > 16 ? (
                                  <Tooltip title={row.specialInstruction}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.specialInstruction}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.specialInstruction}
                                    </Typography>
                                  )}
                              </TableCell>

                            )}
                            {this.state.headCells[19].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.modifiedByName &&
                                  row.modifiedByName.length > 16 ? (
                                    <Tooltip title={row.modifiedByName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[20].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip title={row.modifiedDate}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[21].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdByName &&
                                  row.createdByName.length > 16 ? (
                                    <Tooltip title={row.createdByName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.createdByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[22].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-right-cell"
                                visibility="hidden"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip title={row.createdDate}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={8} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={codeTypeList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(emloCodeMater);
