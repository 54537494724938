import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { printDetails, addZeros } from "../../../shared/tableCommonFunctions";
import { Divider } from "@material-ui/core";

import { getRoutingPriority } from "../routingActions";
import { StateContext } from "../../../shared/globalState";

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { selectedroutingPriorityID } = props;
  let acnArr = props.roles.filter(u => u.menuName === "Routing Priority");
  let acnObj = acnArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%"
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_RoutingPriority/${selectedroutingPriorityID}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={e => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewRoutingPriority extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      routingPriority: {
        customer: {},
        location: {},
        subscription: {},
        country: {},
        supplementaryIndicator: "",
        consolidationType: "",
        isPeriodical: "",
        dispatchType: {},
        dispatchMode: {},
        mailingScheme: {},
        distributor: {},
        customerName: "",
        locationName: "",
        subscriptionCode: "",
        noOfOrdersFrom: "",
        noOfOrdersTo: "",
        noOfCopiesFrom: "",
        noOfCopiesTo: "",
        weightFrom: "",
        weightTo: "",
        ppiNumber: "",
        ppiLicenseNo: "",
        dispatchTypeName: "",
        dispatchModeName: "",
        mailingSchemeName: "",
        distributorName: "",
        returnAddress: "",
        carrierSheetScheme: "",
        carrierSheetMode: ""
      }
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let routingPriorityID = this.props.match.params.routingPriorityID;

    getRoutingPriority(routingPriorityID).then(r => {
      dispatch({
        type: "getRoutingPriority",
        newStore: r
      });
      this.setState({ routingPriority: r });
    });
  }

  render() {
    let { routingPriority } = this.state;
    let routingPriorityID = this.props.match.params.routingPriorityID;
    let { status } = "";
    if (routingPriority && routingPriority.isActive === true) {
      status = "Yes";
    } else {
      status = "No";
    }
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selectedroutingPriorityID={routingPriorityID}
            roles={this.props.roles}
          />
          {/* <div className="view-page-header">Cost Per Title Information</div> */}
          <Divider />
          <div className="view-page-container" id="printable">
            {/* <div className="view-page-headings">
              Routing By Priority Information
            </div> */}
            <div className="view-page-headings">Customer Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Customer</div>
                <div className="view-page-value">
                  {routingPriority &&
                    routingPriority.customer &&
                    routingPriority.customer.customerName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Location</div>
                <div className="view-page-value">
                  {routingPriority &&
                    routingPriority.location &&
                    routingPriority.location.locationName}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Priority Code</div>
                <div className="view-page-value">
                  {routingPriority && routingPriority.priorityCode}
                </div>
              </div>
            </div>
            {/* <div className="view-page-contents-sub-container">
                <div className="view-page-label">Subscription</div>
                <div className="view-page-value">
                  {routingPriority && routingPriority.subscription.subscriptionCode}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Country</div>
                <div className="view-page-value">
                  {routingPriority && routingPriority.country.countryCode}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Is Periodical</div>
                <div className="view-page-value">{status}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Supplementary Indicator</div>
                <div className="view-page-value">
                  {routingPriority && routingPriority.supplementaryIndicator}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Consolidation Type</div>
                <div className="view-page-value">
                  {routingPriority && routingPriority.consolidationType}
                </div>
              </div> */}
            <div className="view-page-headings"> Criteria Setting</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No Of Orders From</div>
                <div className="view-page-value">
                  {routingPriority && routingPriority.noOfOrdersFrom}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No Of Orders To</div>
                <div className="view-page-value">
                  {routingPriority && routingPriority.noOfOrdersTo}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No Of Copies From</div>
                <div className="view-page-value">
                  {routingPriority && routingPriority.noOfCopiesFrom}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No Of Copies To</div>
                <div className="view-page-value">
                  {routingPriority && routingPriority.noOfCopiesTo}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Weight From</div>
                <div className="view-page-value">
                  {addZeros(
                    routingPriority && routingPriority.weightFrom.toString(),
                    2
                  )}
                </div>
              </div>

              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Weight To</div>
                <div className="view-page-value">
                  {addZeros(
                    routingPriority && routingPriority.weightTo.toString(),
                    2
                  )}
                </div>
              </div>
            </div>
            <div className="view-page-headings">Routing Configuration</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Dispatch Type</div>
                <div className="view-page-value">
                  {routingPriority &&
                    routingPriority.dispatchType &&
                    routingPriority.dispatchType.dispatchTypeCodeName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Dispatch Mode</div>
                <div className="view-page-value">
                  {routingPriority &&
                    routingPriority.dispatchMode &&
                    routingPriority.dispatchMode.dispatchModeName}
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Mailing Scheme</div>
                  <div className="view-page-value">
                    {routingPriority &&
                      routingPriority.mailingScheme &&
                      routingPriority.mailingScheme.mailingScheme}
                  </div>
                </div>
              </div>

              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Route PPI</div>
                  <div className="view-page-value">
                    {routingPriority && routingPriority.ppiNumber}
                  </div>
                </div>

                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">PPI LicenseNo</div>
                  <div className="view-page-value">
                    {routingPriority && routingPriority.ppiLicenseNo}
                  </div>
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Distributor</div>
                <div className="view-page-value">
                  {routingPriority &&
                    routingPriority.distributor &&
                    routingPriority &&
                    routingPriority.distributor.distributorName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Return Address</div>
                <div className="view-page-value">
                  {routingPriority && routingPriority.returnAddress}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Carrier Sheet Scheme</div>
                <div className="view-page-value">
                  {routingPriority && routingPriority.carrierSheetScheme}
                </div>
              </div>

              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Carrier Sheet Mode</div>
                <div className="view-page-value">
                  {routingPriority && routingPriority.carrierSheetMode}
                </div>
              </div>
            </div>
            <div className="view-page-contents-sub-container">
              <div className="view-page-label">Active</div>
              <div className="view-page-value">{status}</div>
            </div>{" "}
            <Divider style={{ marginTOp: "25px" }} />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={e => this.props.history.push("/routingPriority")}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewRoutingPriority);
