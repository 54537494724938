import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { updateZone, getZoneDetails } from "../masterActions";
import { StateContext } from "../../../shared/globalState";
import { addStyles } from "../../../shared/styles";
const validateMaxLength2 = new Validator("maxlength2");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  container: {
    flexWrap: "wrap",
    margin: "1em"
  },
  textField: {
    // marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "31%",
    fontSize: "11px !important"
  },
  resize: {
    fontSize: 14
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em"
  }
});

class Editzone extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      zone: {
        zoneCode: "",
        zoneName: "",
        isActive: true,
        modifiedBy: 1
      },
      errors: {},
      active: false,
      zoneCodeValid: false,
      zoneNameValid: false,
      formValid: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let zoneId = this.props.match.params.zoneId;

    getZoneDetails(zoneId).then(r => {
      dispatch({
        type: "getZoneDetails",
        newStore: r
      });
      this.setState({ zone: r });
      if (r.isActive) this.setState({ active: r.isActive });
    });
  }

  handleChange = event => {
    const { zone } = this.state;

    if (event.target.name === "isActive") {
      zone[event.target.name] = event.target.checked;
      this.setState({ formValid: true });
    } else {
      zone[event.target.name] = event.target.value;
    }

    this.validateField(event.target.name, event.target.value);
    this.setState({ zone });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let zoneCodeValid = this.state.zoneCodeValid;
    let zoneNameValid = this.state.zoneNameValid;
    switch (fieldName) {
      case "zoneCode":
        zoneCodeValid = validateMaxLength2(value);
        fieldValidationErrors.zoneCode = zoneCodeValid
          ? ""
          : " Please enter alphabet characters only";
        break;
      case "zoneName":
        zoneNameValid = true;
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        zoneCodeValid: zoneCodeValid,
        zoneNameValid: zoneNameValid
      },
      this.validateForm
    );
  }

  validateForm() {
    if (!this.state.formValid) {
      this.setState({
        formValid: this.state.zoneCodeValid || this.state.zoneNameValid
      });
    }

    return this.state.formValid;
  }

  _onKeyPress(event) {
    const re = /[0-9a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onKeyPressZoneName(event) {
    const re = /[0-9a-zA-Z :]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    let zoneId = this.props.match.params.zoneId;
    const { zone } = this.state;
    zone.modifiedBy = localStorage.loggedInId;
    updateZone(zoneId, zone, this.props.history);
  };
  render() {
    let { classes } = this.props;
    let { zone, active } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update zone</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <TextField
                  required
                  disabled
                  variant="filled"
                  label="Zone Code"
                  name="zoneCode"
                  inputProps={{
                    maxLength: 2
                  }}
                  margin="normal"
                  className={classes.textField}
                  onKeyPress={this._onKeyPress}
                  value={zone.zoneCode}
                  onChange={this.handleChange}
                  error={this.state.errors.zoneCode}
                  helperText={
                    this.state.errors.zoneCode ? this.state.errors.zoneCode : ""
                  }
                  autoComplete="off"
                />
                <TextField
                  inputProps={{
                    maxLength: 100
                  }}
                  label="Zone Name"
                  name="zoneName"
                  margin="normal"
                  className={classes.textField}
                  value={zone.zoneName}
                  onChange={this.handleChange}
                  onKeyPress={this._onKeyPressZoneName}
                  error={this.state.errors.zoneName}
                  helperText={
                    this.state.errors.zoneName ? this.state.errors.zoneName : ""
                  }
                  autoComplete="off"
                />
                {active ? (
                  <React.Fragment />
                ) : (
                  <FormControlLabel
                    name="isActive"
                    style={{ marginTop: "25px", marginLeft: "7px" }}
                    control={
                      <Checkbox
                        color="primary"
                        className={classes.Checkbox}
                        checked={zone.isActive}
                        onChange={this.handleChange}
                        value={zone.isActive}
                      />
                    }
                    label="Active"
                  />
                )}
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={e => this.props.history.push("/zone")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(Editzone));
