import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Button, Paper, Divider } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { addStyles } from "../../../shared/styles";
import { StateContext } from "../../../shared/globalState";
import {
  getDispatchMode,
  putDispatchMode,
  getPerticularDispatchMode,
} from "../masterActions";
const validateMaxLength2 = new Validator("maxlength2");
const validateMaxLength100 = new Validator("maxlength100");
class AddDispatchMode extends React.Component {
  static contextType = StateContext;
  state = {
    dispatchModeCode: "",
    dispatchModeName: "",
    isActive: false,
    errors: {
      dispatchModeCode: false,
      dispatchModeName: false,
    },
    active: false,
    apiDispatchModeCode: "",
    apiDispatchModeName: "",
    apiIsActive: "",
  };
  componentDidMount() {
    this.props.handleSettingHeader("Dispatch Mode");
    this.props.setSelectedList("masterDataOpen", "dispatchMode");
    let id = "";
    if (process.browser) id = window.location.pathname.split("/")[2];
    if (id) {
      getPerticularDispatchMode(id).then((r) => {
        this.setState({
          dispatchModeCode:
            r && r.data && r.data.dispatchModeCode && r.data.dispatchModeCode,
          apiDispatchModeCode:
            r && r.data && r.data.dispatchModeCode && r.data.dispatchModeCode,
          dispatchModeName:
            r && r.data && r.data.dispatchModeName && r.data.dispatchModeName,
          apiDispatchModeName:
            r && r.data && r.data.dispatchModeName && r.data.dispatchModeName,
          isActive: r && r.data && r.data.isActive && r.data.isActive,
          apiIsActive: r && r.data && r.data.isActive && r.data.isActive,
        });
        if (r.data.isActive)
          this.setState({ active: r.data && r.data.isActive });
      });
    }
  }
  handleChange = (e, validate) => {
    let newRrrors = { ...this.state.errors };
    let result = validate(e.target.value);
    newRrrors[e.target.name] = !result;
    this.setState({ [e.target.name]: e.target.value, errors: newRrrors });
  };
  handleIsActive = (e, name) => {
    this.setState({ [name]: e.target.checked });
  };
  _onKeyPress(event) {
    const re = /[0-9a-zA-Z ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleClose = () => {
    this.setState({
      dispatchModeCode: "",
      dispatchModeName: "",
    });
  };
  checkValidInputs = () => {
    if (!this.state.dispatchModeCode) return true;
    if (!this.state.dispatchModeName) return true;
    // if (this.state.dispatchModeCode === this.state.apiDispatchModeCode)
    //   return true;
    if (
      this.state.dispatchModeName === this.state.apiDispatchModeName &&
      this.state.isActive === this.state.apiIsActive
    )
      return true;
    else return false;
  };
  handleSubmit = (event) => {
    let id = "";
    if (process.browser) id = window.location.pathname.split("/")[2];
    const dispatch = this.context[1];
    let reqBody = {};
    reqBody.dispatchModeCode = this.state.dispatchModeCode;
    reqBody.dispatchModeName = this.state.dispatchModeName;
    reqBody.isActive = this.state.isActive;
    reqBody.modifiedBy = localStorage.loggedInId;
    putDispatchMode(this.props.history, id, reqBody)
      .then((o) => {
        getDispatchMode()
          .then((r) => {
            dispatch({
              type: "getDispatchModeList",
              newStore: r.data,
            });
          })
          .catch((e) => {});
      })
      .catch((e) => {});
  };
  render() {
    let { classes } = this.props;
    let {
      dispatchModeCode,
      dispatchModeName,
      active,
      isActive,
      errors,
    } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Dispatch Mode</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container}>
                <div>
                  <TextField
                    required
                    disabled
                    variant="filled"
                    label="Dispatch Mode Code"
                    name="dispatchModeCode"
                    inputProps={{
                      maxLength: 2,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={dispatchModeCode}
                    onChange={(e) => this.handleChange(e, validateMaxLength2)}
                    error={errors.dispatchModeCode}
                    helperText={
                      errors.dispatchModeCode
                        ? "Please Enter Dispatch Mode Code"
                        : ""
                    }
                  />
                  <TextField
                    inputProps={{
                      maxLength: 100,
                    }}
                    required
                    onKeyPress={this._onKeyPress}
                    label="Dispatch Mode Name"
                    name="dispatchModeName"
                    margin="normal"
                    className={classes.textField}
                    value={dispatchModeName}
                    onChange={(e) => this.handleChange(e, validateMaxLength100)}
                    error={errors.dispatchModeName}
                    helperText={
                      errors.dispatchModeName
                        ? "Please Enter Dispatch Mode Name"
                        : ""
                    }
                  />{" "}
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      name="isActive"
                      style={{ marginTop: "33px", marginLeft: "7px" }}
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.Checkbox}
                          checked={isActive}
                          onChange={(e) => this.handleIsActive(e, "isActive")}
                          value={isActive}
                        />
                      }
                      label="Active"
                    />
                  )}
                </div>
                <Divider style={{ marginTop: "20px" }} />
                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/DispatchMode")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {this.checkValidInputs() ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        color="primary"
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        //autoFocus
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddDispatchMode));
