import React, { Component } from "react";

class ReceivingReport extends Component {
  state = {};
  componentDidMount() {
    this.props.handleSettingHeader("Receiving Report");
    this.props.setSelectedList("publisherMainLabelOpen", "receivingReport");
  }
  render() {
    return (
      <div>
      <iframe
        style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
        src={`${window.localStorage.ReportURLByID}` +"JRR%20Summary%20Report"}
        />
      </div>
    );
  }
}

export default ReceivingReport;
