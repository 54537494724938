import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_GENRIC_WORK_ORDER;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
export const getJobTypeList = async (id) => {
  let woList = [];
  await pgsService
    .get(`/GetJobTypesDropdown`)
    .then((r) => {
      woList = r;
    })
    .catch((e) => { });
  return woList;
};
export const getWorkOrderList = async () => {
  let woList = [];
  await pgsService
    .get("/WorkOrders")
    .then((r) => {
      woList = r;
    })
    .catch((e) => { });
  return woList;
};

export const getStockIndentForJG = async (id) => {
  let woList = [];
  await pgsService
    .get(`/GetJobGroupStockndentAsync/${id}`)
    .then((r) => {
      woList = r;
    })
    .catch((e) => { });
  return woList;
};
export const getFliteredWorkOrderList = async (reqBody) => {
  let woList = [];
  await pgsService
    .post("/WorkOrderAdvanceSerach", reqBody)
    .then((r) => {
      woList = r;
    })
    .catch((e) => { });
  return woList;
};
export const postForReOpenWO = async (id, lId) => {
  let woList = [];
  await pgsService
    .post(`/ReOpenWO/${id}/${lId}`)
    .then((r) => {
      woList = r;
      toastr.success("Work Order Reopened Successfully")
    })
    .catch((e) => { });
  return woList;
};
export const postWorkOrder = async (reqBody, history, workOrderMode, mode) => {
  let workOrder = {};
  await pgsService
    .post("/WorkOrders", reqBody)
    .then((r) => {
      workOrder = r.data;
      let jobGroupID = workOrder.tbl_PGSJobGroup[0].jobGroupID;
      history.push({
        pathname: "/jobGroup",
        search:
          "?workOrderID=" + r.data.workOrderID + "&jobGroupID=" + jobGroupID,
        state: { workOrderMode: workOrderMode, woMode: mode },
      });
    })
    .catch((e) => { });
  return workOrder;
};

export const postJobSchedulers = async (reqBody, history) => {
  let jobSchedulers = {};
  await pgsService
    .post("/JobSchedulers", reqBody)
    .then((r) => {
      jobSchedulers = r.data;
      toastr.success("Work Order Created Successfully");
      history.push("./workOrders");
    })
    .catch((e) => { });
  return jobSchedulers;
};
export const closeWOBasedOnStatus = async (woId, stId, history) => {
  let woRes = {};
  await pgsService
    .post(`/CloseWO/${woId}/${stId}`,)
    .then((r) => {
      woRes = r.data;
      toastr.success("Work Order Closed Successfully");
      history.push("/workOrders");
    })
    .catch((e) => { });
  return woRes;
};
export const cancelWOBasedOnStatus = async (woId, stId, history) => {
  let woRes = {};
  await pgsService
    .post(`/CancelWO/${woId}/${stId}`,)
    .then((r) => {
      woRes = r.data;
      toastr.success("Work Order Cancelled Successfully");
      history.push("/workOrders");
    })
    .catch((e) => { });
  return woRes;
};
export const updateWorkOrder = async (
  workOrderID,
  reqBody,
  history,
  workOrderMode,
  jobGroupIDDDD,
  mode
) => {
  // console.log(mode);
  let workOrder = {};
  await pgsService
    .put("/WorkOrders/" + workOrderID, reqBody)
    .then((r) => {
      workOrder = r.data;
      let jobGroupID =
        r &&
          r.data &&
          r.data.tbl_PGSJobGroup &&
          r.data.tbl_PGSJobGroup.length > 0 &&
          r.data.tbl_PGSJobGroup[0].jobGroupID
          ? workOrder.tbl_PGSJobGroup[0].jobGroupID
          : jobGroupIDDDD;
      // ? workOrder.tbl_PGSJobGroup[0].jobGroupID
      // : jobGroupIDDDD;

      history.push(
        {
          pathname: "/jobGroup",
          search:
            "?workOrderID=" + r.data.workOrderID + "&jobGroupID=" + jobGroupID,
          state: { workOrderMode: workOrderMode, woMode: mode },
        }
        // "./jobGroup?workOrderID=" +
        //   r.data.workOrderID +
        //   "&jobGroupID=" +
        //   jobGroupID
      );
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return workOrder;
};

export const getChooseWorkOrderList = async () => {
  let woList = [];
  await pgsService
    .get("/GetChooseWorkorder")
    .then((r) => {
      woList = r;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return woList;
};
export const GetDisplayWOInJGView = async (workOrderID) => {
  let workOrderDetails = {};
  await pgsService
    .get("/GetDisplayWOInJGView/" + workOrderID)
    .then((response) => {
      workOrderDetails = response.data;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return workOrderDetails;
};

export const GetDisplayWOInJG = async (workOrderID, jobGroupID) => {
  let workOrderDetails = {};
  await pgsService
    .get("/GetDisplayWOInJG/" + workOrderID + "/" + jobGroupID)
    .then((response) => {
      workOrderDetails = response.data;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return workOrderDetails;
};

export const GetDisplayOWCInJG = async (workOrderID) => {
  let workOrderDetails = {};
  await pgsService
    .get("/GetOWCALLDetails/" + workOrderID)
    .then((response) => {
      workOrderDetails = response.data;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return workOrderDetails;
};

export const getWorkOrder = async (workOrderID) => {
  let workOrder = {};
  await pgsService
    .get("/WorkOrders/" + workOrderID)
    .then((response) => {
      workOrder = response.data;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return workOrder;
};

export const getWorkOrderStatusChangedCriteria = async (workOrderID) => {
  let workOrder = {};
  await pgsService
    .get("/IsAllJobGroupsCompleted/" + workOrderID)
    .then((response) => {
      workOrder = response.data;
    })
    .catch((e) => {
      // if (e.respone && e.response.data.errors[0].message !== undefined) {
      //   toastr.error(e.response.data.errors[0].message);
      // } else if (e.response && e.response.data.errors[0]) {
      //   toastr.error(e.response.data.errors[0]);
      // }
    });
  return workOrder;
};

export const getJobGroupList = async () => {
  let jobGroupList = [];
  await pgsService
    .get("/GetWorkOrderDisplayDetails")
    .then((r) => {
      jobGroupList = r;
    })
    .catch((e) => {

    });
  return jobGroupList;
};

export const getStockDispatchedStatus = async () => {
  let statusList = [];
  await pgsService
    .get("/GetGenStatusForStock")
    .then((r) => {
      statusList = r;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return statusList;
};

export const getOrderList = async (JobgroupID, CustomerID) => {
  let OrderList = [];
  await pgsService
    .get("/SelectOrder/" + `${JobgroupID}` + "/" + `${CustomerID}`)
    .then((r) => {
      OrderList = r;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return OrderList;
};

export const GetJobSchedulerStatusDropdown = async () => {
  let schedulerStatusList = [];
  await pgsService
    .get("/GetJobSchedulerStatusDropdown")
    .then((r) => {
      schedulerStatusList = r;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return schedulerStatusList;
};

export const GetJobSchedulerStatusDropdownWO = async () => {
  let schedulerStatusList = [];
  await pgsService
    .get("/GetJSStatusDDL")
    .then((r) => {
      schedulerStatusList = r;
    })
    .catch((e) => {
      // if (e.respone && e.response.data.errors[0].message !== undefined) {
      //   toastr.error(e.response.data.errors[0].message);
      // } else if (e.response && e.response.data.errors[0]) {
      //   toastr.error(e.response.data.errors[0]);
      // }
    });
  return schedulerStatusList;
};

export const getStockList = async (customerID) => {
  let stockList = [];
  // customerID = 42;
  await pgsService
    .get("/SelectStockIN/" + customerID)
    .then((r) => {
      stockList = r;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return stockList;
};

export const getProcessList = async (customerID) => {
  let processList = [];
  await pgsService
    .get("/GetProcesList/" + customerID)
    .then((r) => {
      processList = r;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return processList;
};
export const getProcessList2 = async (customerID, id) => {
  let processList = [];
  await pgsService
    .get(`/GetProcesList/${customerID}/${id}`)
    .then((r) => {
      processList = r;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return processList;
};
export const getJobGroup = async (jobGroupID) => {
  let JobGroup = {};
  await pgsService
    .get("/JobGroups/" + jobGroupID)
    .then((response) => {
      let data = response.data;
      JobGroup = {
        jobGroupID: data.jobGroupID,
        customerName: data.customerName,
        workOrderNumber: data.workOrderNumber,
        projectTitle: data.projectTitle,
        jobGroupNumber: data.jobGroupNumber,
      };
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return JobGroup;
};

//-------For RunMailList------------//
export const getJobGroupbyJobGroupId = async (jobGroupID) => {
  let JobGroup = {};
  await pgsService
    .get("/GetJobGrpDisplayDetails/" + jobGroupID)
    .then((response) => {
      let data = response.data;
      JobGroup = {
        jobGroupID: data.jobGroupID,
        customerName: data.customerName,
        workOrderNumber: data.workOrderNumber,
        projectTitle: data.projectTitle,
        jobGroupNumber: data.jobGroupNumber,
        customerID: data.customerID,
        workOrderID: data.workOrderId,
        customerPhoneNumber: data.customerPhoneNumber,
        wojgTitle: data.wojgTitle,
      };
    })
    .catch((e) => {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[0].message !== undefined
      ) {
        toastr.error(e.response.data.errors[0].message);
      } else if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[0]
      ) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return JobGroup;
};

export const GetJobGroupsListByWOID = async (workOrderID) => {
  let JobGroup = {};
  await pgsService
    .get("/GetJobGroupsListByWOID/" + workOrderID)
    .then((response) => {
      JobGroup = response.data;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return JobGroup;
};

export const postJobGroup = async (reqBody, history) => {
  let jobGroup = {};
  await pgsService
    .post("/JobGroups", reqBody)
    .then((r) => {
      jobGroup = r.data;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return jobGroup;
};

export const updateJobGroup = async (
  jobGroupID,
  reqBody,
  history,
  workOrderMode
) => {
  let jobGroup = {};
  await pgsService
    .put("/JobGroups/" + jobGroupID, reqBody)
    .then((r) => {
      jobGroup = r.data;
      history.push(
        {
          pathname: "/process",
          search:
            "?workOrderID=" + r.data.workOrderID + "&jobGroupID=" + jobGroupID,
          state: { workOrderMode: workOrderMode },
        }

        // "./process?workOrderID=" +
        //   r.data.workOrderID +
        //   "&jobGroupID=" +
        //   jobGroupID
      );
    })
    .catch((e) => { });
  return jobGroup;
};

export const postStockIndent = async (reqBody) => {
  let stock = {};
  await pgsService
    .post("/CreateIndents", reqBody)
    .then((r) => {
      stock = r.data;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return stock;
};

export const updateJobGroupStocks = async (
  workOrderID,
  jobGroupID,
  reqBody,
  history,
  workOrderMode
) => {
  let stock = {};
  await pgsService
    .put("/JobGroupStocks", reqBody)
    .then((r) => {
      stock = r.data;
      history.push({
        pathname: "/schedule",
        search: "?workOrderID=" + workOrderID + "&jobGroupID=" + jobGroupID,
        state: { workOrderMode: workOrderMode },
      });
    })
    .catch((e) => { });
  return stock;
};

export const postJobGroupStocks = async (
  workOrderID,
  jobGroupID,
  reqBody,
  history,
  workOrderMode
) => {
  let stock = {};
  await pgsService
    .post("/JobGroupStocks", reqBody)
    .then((r) => {
      stock = r.data;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return stock;
};

export const postJobGroupOrders = async (
  workOrderID,
  jobGroupID,
  reqBody,
  history,
  workOrderMode
) => {
  let jobOrders = {};
  await pgsService
    .post("/JobOrders", reqBody)
    .then((r) => {
      jobOrders = r;
      history.push(
        {
          pathname: "/process",
          search: "?workOrderID=" + workOrderID + "&jobGroupID=" + jobGroupID,
          state: { workOrderMode: workOrderMode },
        }

        // "./process?workOrderID=" + workOrderID + "&jobGroupID=" + jobGroupID
      );
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return jobOrders;
};

export const postJobGroupProcesses = async (
  workOrderID,
  jobGroupID,
  reqBody,
  history,
  workOrderMode
) => {
  let jobGroupProcess = {};
  await pgsService
    .post("/JobGroupProcesses", reqBody)
    .then((r) => {
      jobGroupProcess = r;
      history.push({
        pathname: "/stock",
        search: "?workOrderID=" + workOrderID + "&jobGroupID=" + jobGroupID,
        state: { workOrderMode: workOrderMode },
      });
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return jobGroupProcess;
};

export const postDocket = async (reqBody) => {
  let docketList = [];
  await pgsService
    .post("/Dockets", reqBody)
    .then((r) => {
      docketList = r;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return docketList;
};
export const getPaymentModeTypeList = async () => {
  let paymentModeTypeList = [];
  await pgsService
    .get("/getPaymentModeTypeList")
    .then((r) => {
      paymentModeTypeList = r;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return paymentModeTypeList;
};
export const getArticleTypeList = async () => {
  let articleTypeList = [];
  await pgsService
    .get("/getArticleTypeList")
    .then((r) => {
      articleTypeList = r;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return articleTypeList;
};
export const getMailCharacteristicsList = async () => {
  let mailCharacteristicsList = [];
  await pgsService
    .get("/getMailCharacteristicsList")
    .then((r) => {
      mailCharacteristicsList = r;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return mailCharacteristicsList;
};
export const getMarsResellerNumberList = async () => {
  let marsResellerNumberList = [];
  await pgsService
    .get("/getMarsResellerNumberList")
    .then((r) => {
      marsResellerNumberList = r;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return marsResellerNumberList;
};

export const GetProcesListByJobGroupID = async (jobGroupID) => {
  let processList = {};
  await pgsService
    .get("/GetProcesListByJobGroupId/" + jobGroupID)
    .then((response) => {
      processList = response.data;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return processList;
};

export const GetJobGroupStockListByJobGroupID = async (jobGroupID) => {
  let stockList = {};
  await pgsService
    .get("/GetJobGroupStockDetails/" + jobGroupID)
    .then((response) => {
      stockList = response.data;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return stockList;
};

export const GetJobGrpDetails = async (jobGroupID) => {
  let jobGroupDetails = {};
  await pgsService
    .get("/GetJobGrpDetails/" + jobGroupID)
    .then((response) => {
      jobGroupDetails = response.data;
    })
    .catch((e) => {
      // if (e.respone && e.response.data.errors[0].message !== undefined) {
      //   toastr.error(e.response.data.errors[0].message);
      // } else if (e.response && e.response.data.errors[0]) {
      //   toastr.error(e.response.data.errors[0]);
      // }
    });
  return jobGroupDetails;
};

export const GetSelectOrdersForProcess = async (jobGroupID, customerID) => {
  let ordersList = {};
  await pgsService
    .get("/SelectOrdersForProcess/" + jobGroupID + "?customerID=" + customerID)
    .then((response) => {
      ordersList = response;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return ordersList;
};

export const GetJobSchedulerByJobGroupID = async (jobGroupID) => {
  let schedule = {};
  await pgsService
    .get("/JobSchedulerByJobGroupID/" + jobGroupID)
    .then((response) => {
      schedule = response.data;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return schedule;
};

export const deleteJobGroupStock = async (jobGroupStockID) => {
  await pgsService
    .delete("/Delete/" + jobGroupStockID)
    .then((r) => {
      toastr.success("Stock Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const DeleteJobGroupProcess = async (jobGroupProcessID) => {
  await pgsService
    .delete("/DeleteJobGroupProcess/" + jobGroupProcessID)
    .then((r) => {
      toastr.success("Process Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const DeleteJobGroupProcessOrderMapping = async (
  jobGroupProcessOrderMappingID
) => {
  await pgsService
    .delete(
      "/DeleteJobGroupProcessOrderMapping/" + jobGroupProcessOrderMappingID
    )
    .then((r) => {
      toastr.success("Job Order Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const DeleteJobGroup = async (jobGroupID, ModifiedBy) => {
  let deleted = false;
  await pgsService
    .get("/DeleteJobGroup/" + jobGroupID + "/" + ModifiedBy)
    .then((r) => {
      deleted = r.data;
      if (deleted) {
        toastr.success("Job Group Deleted Successfully");
      } else {
        toastr.error("Job Group cannot be deleted. As it's being used");
      }
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return deleted;
};
export const DeleteWorkOrder = async (workOrderID, ModifiedBy) => {
  let deleted = false;
  await pgsService
    .get("/DeleteWorkOrder/" + workOrderID + "/" + ModifiedBy)
    .then((r) => {
      deleted = r.data;
      if (deleted) {
        toastr.success("Work Order Deleted Successfully");
      } else {
        toastr.error("Work Order cannot be deleted. As it's being used");
      }
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return deleted;
};

export const CanDeleteJobOrder = async (jobGroupID) => {
  let CanDeleteJobOrder = false;
  await pgsService
    .get("/CanDeleteJobOrder/" + jobGroupID)
    .then((r) => {
      CanDeleteJobOrder = r.data;
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return CanDeleteJobOrder;
};

export const DeleteJobOrder = async (jobGroupID) => {
  await pgsService
    .delete("/DeleteJobOrder/" + jobGroupID)
    .then((r) => {
      toastr.success("Job order deleted successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const updateSchedulers = async (JobSchedulerID, reqBody, history) => {
  let jobSchedulers = {};
  await pgsService
    .put("/JobSchedulers/" + JobSchedulerID, reqBody)
    .then((r) => {
      jobSchedulers = r.data;
      toastr.success("Scheduler updated successfully");
      history.push("/jobScheduler");
    })
    .catch((e) => { });
  return jobSchedulers;
};
export const updateJobSchedulers = async (JobSchedulerID, reqBody, history) => {
  let jobSchedulers = {};
  await pgsService
    .put("/JobSchedulers/" + JobSchedulerID, reqBody)
    .then((r) => {
      jobSchedulers = r.data;
      toastr.success("Work Order updated successfully");
      history.push("./workOrders");
    })
    .catch((e) => { });
  return jobSchedulers;
};

export const GetMBAByWOID = async (id) => {
  let list = [];
  await pgsService
    .get(`/ManualBillingAdvices/${id}`)
    .then((response) => {
      list = response.data;
    })
    .catch((e) => { });
  return list;
};

export const GetMBAByWOIDView = async (id) => {
  let list = [];
  await pgsService
    .get(`/GetMBALLDetails/${id}`)
    .then((response) => {
      list = response.data;
    })
    .catch((e) => { });
  return list;
};

export const CreateMBA = async (reqBody, history, id) => {
  await pgsService
    .post("/ManualBillingAdvices", reqBody)
    .then((r) => {
      // console.log(r);
      toastr.success("Manual Billing Advise Created Successfully");
      history.push(`/view_mbaReport/${id}`);
    })
    .catch((e) => {
      console.log(e.response, "reponse");
    });
};

export const GetMBAByMBAHeaderID = async (id) => {
  let list = [];
  await pgsService
    .get(`/GetMBAByMBAHeaderID/${id}`)
    .then((response) => {
      list = response.data;
    })
    .catch((e) => {
      console.log(e.response, "reponse");
    });
  return list;
};

export const UpdateMBA = async (reqBody, history, mbaId, id) => {
  await pgsService
    .put(`/ManualBillingAdvices/${mbaId}`, reqBody)
    .then((r) => {
      toastr.success("Manual Billing Advise Updated Successfully");
      history.push(`/view_mbaReport/${id}`);
    })
    .catch((e) => { });
};

export const deleteMBAByID = async (mbaID, history) => {
  await pgsService
    .delete("/DeleteMBADetail/" + mbaID)
    .then((r) => {
      toastr.success("Manual Billing Deleted Successfully");
    })
    .catch((e) => {
      // if (e.response.data.errors[0].message !== undefined) {
      //   toastr.error(e.response.data.errors[0].message);
      // } else if (e.response.data.errors[0]) {
      //   toastr.error(e.response.data.errors[0]);
      // }
    });
};

export const deleteOWCByID = async (owcID, history) => {
  await pgsService
    .delete("/DeleteOWCDetail/" + owcID)
    .then((r) => {
      toastr.success("Out Worker Deleted Successfully");
      // history.push();
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const GetOWCByWOID = async (id) => {
  let list = [];
  await pgsService
    .get(`/GetOwcByWorkOrderID/${id}`)
    .then((response) => {
      list = response.data;
    })
    .catch((e) => { });
  return list;
};

export const getOWCDetailsForView = async (id) => {
  let list = [];
  await pgsService
    .get(`/GetOwcByHeaderID/${id}`)
    .then((response) => {
      list = response.data;
    })
    .catch((e) => { });
  return list;
};

export const CreateOWC = async (reqBody, history, id) => {
  await pgsService
    .post("/OutWorkerClaims", reqBody)
    .then((r) => {
      toastr.success("Outworker Claim Created Successfully");
      history.push(`view_owcReport/${id}`);
      // history.push("/workOrders");
    })
    .catch((e) => {
      console.log(e.response, "reposne");
    });
};

export const UpdateOWC = async (id, reqBody, history, wrkOrderId) => {
  await pgsService
    .put("/OutWorkerClaims/" + id, reqBody)
    .then((r) => {
      toastr.success("Outworker Claim Updated Successfully");
      // history.push("/workOrders");
      history.push(`view_owcReport/${wrkOrderId}`);
    })
    .catch((e) => {
      console.log(e.response, "reposne");
    });
};

export const deleteOWC = async (owcID, history, workOrderID) => {
  await pgsService
    .delete("/DeleteOutWorker/" + owcID)
    .then((r) => {
      toastr.success("Out Worker Deleted Successfully");
      history.push("/view_owcReport/" + workOrderID);
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const GetOWCByWOIDClone = async (ParentOWCHeaderID) => {
  let list = [];
  await pgsService
    .get(`CreateClone/${ParentOWCHeaderID}`)
    .then((response) => {
      list = response.data;
    })
    .catch((e) => { });
  //console.log(list);
  return list;
};

export const deleteMBA = async (mbaID, history, workOrderID) => {
  // console.log(mbaID, history, workOrderID);
  await pgsService
    .delete("/DeleteMBAHeader/" + mbaID)
    .then((r) => {
      toastr.success("Out Worker Deleted Successfully");
      history.push("/view_mbaReport/" + workOrderID);
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const GetOWCByWOIDView = async (id) => {
  let list = [];
  await pgsService
    .get(`/GetOWCALLDetails/${id}`)
    .then((response) => {
      list = response.data;
    })
    .catch((e) => { });
  //console.log(list);
  return list;
};

export const getjobScheduleStatusDropDownList = async () => {
  let statusList = [];
  await pgsService
    .get("/GetJobSchedulerStatusDropdown")
    .then((r) => {
      statusList = r;
    })
    .catch((e) => { });
  return statusList;
};

export const getWorkOrderDistributionsList = async (workOrderID) => {
  let woDistributionsList = [];
  await pgsService
    .get("/WorkOrderDistributions/" + workOrderID)
    .then((r) => {
      woDistributionsList = r;
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return woDistributionsList;
};

export const ProceedToJobGroupComplete = async (id, lId) => {
  let woList = [];
  await pgsService
    .post(`/JobGroupComplete/${id}/${lId}`)
    .then((r) => {
      woList = r;
      toastr.success("Job Group Completed Successfully")
    })
    .catch((e) => { });
  return woList;
};


export const ReOpenCompletedJobGroup = async (id, lId) => {
  let woList = [];
  await pgsService
    .post(`/JobGroupReOpen/${id}/${lId}`)
    .then((r) => {
      woList = r;
      toastr.success("Job Group Reopened Successfully")
    })
    .catch((e) => { });
  return woList;
};