import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import { StateContext } from "../../../shared/globalState";
import { getFilteredMailingList } from "../masterActions";
import Validator from "../../../shared/validator";
import { styles } from "../../../shared/styles";
import { resourceValidation } from "../../../shared/resource";
const maxlength50 = new Validator("maxlength50");

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        ></IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "400px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    marginRight: "2em",
  },
}))(MuiDialogActions);

class MailingSchemeSearch extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    mailingScheme: "",
    errors: {
      mailingScheme: false,
    },
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false, mailingScheme: "" });
  };
  onInputChanged = (e, validate) => {
    let newRrrors = { ...this.state.errors };
    let result = validate(e.target.value);
    newRrrors[e.target.name] = !result;
    this.setState({ [e.target.name]: e.target.value, errors: newRrrors });
  };
  onSearchClicked = () => {
    const dispatch = this.context[1];
    let { mailingScheme } = this.state;
    let reqBody = { mailingScheme };
    this.props.onSearchClicked(reqBody);

  };
  checkButtonDisability = () => {
    let { mailingScheme } = this.state;
    if (this.state.errors.mailingScheme || !mailingScheme) return true;
    else return false;
  };
  render() {
    let { mailingScheme, errors } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <Tooltip title="Search">
          <IconButton
            aria-label="Search"
            className={classes.iconHover}
            onClick={this.handleClickOpen}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          maxWidth="md"
          style={{ minWidth: "200px" }}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Advanced Search
          </DialogTitle>
          <DialogContent dividers>
            <div className={classes.container}>
              <div>
                <TextField
                  id="standard-required"
                  label="Mailing Scheme"
                  name="mailingScheme"
                  placeholder="Mailing Scheme"
                  // className={classes.textField}
                  margin="normal"
                  style={{ width: "383px " }}
                  value={mailingScheme}
                  error={this.state.errors.mailingScheme}
                  onChange={(e) => this.onInputChanged(e, maxlength50)}
                  helperText={
                    errors.mailingScheme ? resourceValidation.alphaNumaric : ""
                  }
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              //disableElevation
              style={{ boxShadow: "none" }}
              onClick={this.handleClose}
            >
              CANCEL
            </Button>
            {this.checkButtonDisability() ? (
              <Button
                disabled
                //autoFocus
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.onSearchClicked()}
              >
                SEARCH
              </Button>
            ) : (
                <Button
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.onSearchClicked()}
                >
                  SEARCH
                </Button>
              )}
          </DialogActions>
          {/*             
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={this.handleClose}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={
                this.checkButtonDisability()
                  ? "disabled-button"
                  : "enabled-button"
              }
              onClick={e => this.onSearchClicked()}
              disabled={this.checkButtonDisability() ? true : false}
            >
              SEARCH
            </Button>
          </DialogActions> */}
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(MailingSchemeSearch);
