import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import {Autocomplete} from "@material-ui/lab" ;
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { postMenu, getSuperMenuList } from "../../userAccess/userAccessAction";
import { addStyles } from "../../../shared/styles";

const validateNotRequired100 = new Validator("maxlength100AllAllow");
const validateNotRequired500 = new Validator("maxlength100AllAllow");
const onlyNumbers = new Validator("onlyNumbers");

class AddMenu extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      menu: {
        menuName: "",
        superMenu: "",
        description: "",
        orderNo: ""
      },
      errors: {},
      menuNameValid: false,
      superMenuValid: false,
      descriptionValid: false,
      orderNoValid: false,
      superMenuList: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Menu");
    this.props.setSelectedList("adminSettingOpen", "menu");
    getSuperMenuList().then(r => {
      dispatch({
        type: "getSuperMenuList",
        newStore: r.data
      });
      this.getAllSuperMenus(r.data);
    });
  }
  getAllSuperMenus = list => {
    let superMenuList = [];
    list &&
      list.length > 0 &&
      list.map(c =>
        c.isActive
          ? superMenuList.push({
              key: c.superMenuID,
              value: c.superMenuName,
              active: c.isActive
            })
          : superMenuList
      );
    this.setState({
      superMenuList: superMenuList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      )
    });
  };
  handleChange = event => {
    const { menu } = this.state;
    menu[event.target.name] = event.target.value;
    this.setState({ menu });

    this.validateField(event.target.name, event.target.value);
  };

  handleSuperMenuChange = (event, values) => {
    const { menu } = this.state;
    if (values === null) {
      menu["superMenu"] = "null";
    } else {
      let superMenuID = values.key;
      menu["superMenu"] = superMenuID;
    }
    this.setState({ menu });

    this.validateField("superMenu", menu["superMenu"]);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let menuNameValid = this.state.menuNameValid;
    let superMenuValid = this.state.superMenuValid;
    let descriptionValid = this.state.descriptionValid;
    let orderNoValid = this.state.orderNoValid;
    switch (fieldName) {
      case "menuName":
        if (value !== "") {
          menuNameValid = validateNotRequired100(value);
          fieldValidationErrors.menuName = menuNameValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          menuNameValid = false;
          fieldValidationErrors.menuName = "";
        }
        break;
      case "superMenu":
        if (value !== "null") {
          superMenuValid = onlyNumbers(value);
          fieldValidationErrors.superMenu = superMenuValid
            ? ""
            : " Please select Super Menu";
        } else {
          superMenuValid = false;
          fieldValidationErrors.superMenu = "";
        }
        break;
      case "description":
        if (value !== "") {
          descriptionValid = validateNotRequired500(value);
          fieldValidationErrors.description = descriptionValid
            ? ""
            : resourceValidation.ValidDescription;
        } else {
          descriptionValid = false;
          fieldValidationErrors.description = "";
        }
        break;
      case "orderNo":
        if (value !== "" && value !== "null") {
          orderNoValid = onlyNumbers(value);
          fieldValidationErrors.orderNo = orderNoValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          orderNoValid = false;
          fieldValidationErrors.orderNo = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        menuNameValid: menuNameValid,
        superMenuValid: superMenuValid,
        descriptionValid: descriptionValid,
        orderNoValid: orderNoValid
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.menuNameValid &&
        this.state.superMenuValid &&
        this.state.descriptionValid &&
        this.state.orderNoValid
    });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      menuName: "",
      superMenu: "",
      description: "",
      orderNo: "",
      errors: {}
    });
  };
  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleSubmit = event => {
    event.preventDefault();

    if (this.validateForm()) {
      const { menu } = this.state;

      let menuName = menu.menuName;
      let superMenuID = menu.superMenu;
      let description = menu.description;
      let orderNo = menu.orderNo;
      let createdBy = localStorage.loggedInId;

      let reqBody = { superMenuID, menuName, description, orderNo, createdBy };
      postMenu(reqBody).then(r => {
        this.props.history.push("/Menu");
        //toastr.success("Menu Created Successfully");
      });

      // pgsService
      //   .post("/Menus", reqBody)
      //   .then(r => {
      //     this.handleClose();
      //     toastr.success("Menu Created Successfully");
      //     this.props.history.push("/Menu");
      //     list.refreshData("getAllMenu");

      //   })
      //   .catch(e => {
      //     this.handleClose();
      //     if (e.response.data.errors[0].message != undefined) {
      //       toastr.error(e.response.data.errors[0].message);
      //     }
      //     else { toastr.error(e.response.data.errors[0]); }
      //   });
    }
  };
  render() {
    let { classes } = this.props;
    let { menu, superMenuList } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Menu</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <TextField
                    required
                    label="Menu Name"
                    name="menuName"
                    inputProps={{
                      maxLength: 50
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={menu.menuName}
                    onChange={this.handleChange}
                    error={this.state.errors.menuName}
                    helperText={
                      this.state.errors.menuName
                        ? this.state.errors.menuName
                        : ""
                    }
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="superMenu"
                    name="superMenu"
                    hintText="superMenu Name"
                    className={classes.comboBox}
                    options={superMenuList}
                    onChange={this.handleSuperMenuChange}
                    getOptionLabel={option => option.value}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Select Super Menu"
                        name="superMenu"
                        id="superMenu"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={menu.superMenu}
                        error={this.state.errors.superMenu}
                        helperText={
                          this.state.errors.superMenu
                            ? this.state.errors.superMenu
                            : ""
                        }
                      />
                    )}
                  />
                </div>
                <div>
                  <TextField
                    required
                    multiline={false}
                    rows={1}
                    rowsMax={4}
                    inputProps={{
                      maxLength: 100
                    }}
                    label="Description"
                    name="description"
                    margin="normal"
                    className={classes.textField}
                    value={menu.description}
                    onChange={this.handleChange}
                    error={this.state.errors.description}
                    helperText={
                      this.state.errors.description
                        ? this.state.errors.description
                        : ""
                    }
                  />

                  <TextField
                    label="Order Number"
                    name="orderNo"
                    inputProps={{
                      maxLength: 3
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={menu.orderNo}
                    onChange={this.handleChange}
                    error={this.state.errors.orderNo}
                    helperText={
                      this.state.errors.orderNo ? this.state.errors.orderNo : ""
                    }
                    onKeyPress={this._onNumberKeyPress}
                  />
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={e => this.props.history.push("/menu")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    <Button
                      disabled={!this.state.formValid}
                      autoFocus
                      variant="contained"
                      onClick={this.handleSubmit}
                      className={
                        !this.state.formValid
                          ? "disabled-button"
                          : "enabled-button "
                      }
                    >
                      SUBMIT
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddMenu));
