import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { Autocomplete } from "@material-ui/lab";
import { resourceValidation } from "../../../shared/resource";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { StateContext } from "../../../shared/globalState";
import {
  getAcronymList,
  getCustomerList,
  getCountryList,
  getLocationList,
  getDispatchTypeList,
  getDispatchModeList,
  getMailingSchemeLists,
  getDDistributorList,
  getSubscriptionList,
  getCustomerDDList,
  getRoutingCustomerList,
} from "../../masters/masterActions";
import { postRoutingPriority } from "../routingActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import { addStyles } from "../../../shared/styles";

import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";

const Reorderqty = new Validator("Reorderqty");
const onlyNumbers = new Validator("onlyNumbers");
const generalValidator = new Validator("general");
const checkrate = new Validator("checkdecimalpoint");

class AddRoutingPriority extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      routingPriority: {
        customerID: "",

        locationID: "",
        priorityCode: "",
        dispatchTypeID: "",
        dispatchModeID: "",
        mailingSchemeID: "",
        distributorID: "",
        noOfOrdersFrom: "0",
        noOfOrdersTo: "0",
        noOfCopiesFrom: "0",
        noOfCopiesTo: "0",
        weightFrom: "0",
        weightTo: "0",
        customer: "",
        country: "",
        acronym: "",
        location: "",
        // weightFrom: 0.0,
        // weightTo: 0.0,
        dispatchType: "",
        dispatchMode: "",
        mailingScheme: "",
      },
      errors: {},
      priorityCodeValid: false,
      customerValid: false,
      locationValid: false,

      dispatchTypeValid: false,
      dispatchModeValid: false,
      mailingschemeValid: false,
      distributorValid: false,
      noOfOrdersFromValid: true,
      noOfOrdersToValid: true,
      noOfCopiesFromValid: true,
      noOfCopiesToValid: true,
      weightFromValid: true,
      weightToValid: true,
      acronymList: [],
      customersList: [],
      LocationList: [],
      DispatchModeList: [],
      DispatchTypeList: [],
      DistributorList: [],
      mailingSchemeList: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  _onCodeKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onCodeKeyPress1(event) {
    const re = /^[0-9.]{1,3}(?:\.[0-9]{1,2})?$/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  componentDidMount() {
    getSubscriptionList().then((r) => {
      r.data &&
        this.setState({
          subscriptionList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.subscriptionName.toLowerCase() >
              b.subscriptionName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });
    getCountryList().then((r) => {
      r.data &&
        this.setState({
          countryList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              //a.countryName.toLowerCase() > b.countryName.toLowerCase() ? 1 : -1
              a.countryCode.toLowerCase() > b.countryCode.toLowerCase() ? 1 : -1
            ),
        });
    });
    getAcronymList().then((r) => {
      r.data &&
        this.setState({
          acronymList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              //a.countryName.toLowerCase() > b.countryName.toLowerCase() ? 1 : -1
              a.acronymSuffix.toLowerCase() > b.acronymSuffix.toLowerCase()
                ? 1
                : -1
            ),
        });
    });
    getRoutingCustomerList().then((r) => {
      r.data &&
        this.setState({
          customersList: r.data
            //.filter(x => x.isActive === true)
            .sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
    });
    getMailingSchemeLists().then((r) => {
      r.data &&
        this.setState(
          {
            mailingSchemeList: r.data
              .filter((x) => x.isActive === true)
              .sort((a, b) =>
                a.mailingScheme.toLowerCase() > b.mailingScheme.toLowerCase()
                  ? 1
                  : -1
              ),
          },
          () => {
            let newMSL = [...this.state.mailingSchemeList];
            newMSL.push({
              mailingScheme: "None",
              mailingSchemeID: null,
            });
            this.setState({ mailingSchemeList: newMSL });
          }
        );
    });
    getDDistributorList().then((r) => {
      r.data &&
        this.setState(
          {
            DistributorList: r.data
              .filter((x) => x.isActive === true)
              .sort((a, b) =>
                a.distributorName.toLowerCase() >
                b.distributorName.toLowerCase()
                  ? 1
                  : -1
              ),
          },
          () => {
            let newDistributorList = [...this.state.DistributorList];
            newDistributorList.push({
              distributorName: "None",
              distributorID: null,
            });
            this.setState({ DistributorList: newDistributorList });
          }
        );
    });
    getDispatchTypeList().then((r) => {
      r.data &&
        this.setState({
          DispatchTypeList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.dispatchTypeCodeName.toLowerCase() >
              b.dispatchTypeCodeName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });
    getDispatchModeList().then((r) => {
      r.data &&
        this.setState(
          {
            DispatchModeList: r.data
              .filter((x) => x.isActive === true)
              .sort((a, b) =>
                a.dispatchModeName.toLowerCase() >
                b.dispatchModeName.toLowerCase()
                  ? 1
                  : -1
              ),
          },
          () => {
            let newDispatchModeList = [...this.state.DispatchModeList];
            newDispatchModeList.push({
              dispatchModeName: "None",
              dispatchModeID: null,
            });
            this.setState({ DispatchModeList: newDispatchModeList });
          }
        );
    });
    getLocationList().then((r) => {
      r.data &&
        this.setState({
          LocationList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.locationName.toLowerCase() > b.locationName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });

    this.props.handleSettingHeader("Routing Priority");
  }

  handleChange1 = (event) => {
    const { routingPriority } = this.state;
    let dhlValues = event.target.value;
    dhlValues = this.checkDHlWeightDec(event.target.value.toString());

    routingPriority[event.target.name] = dhlValues;
    this.validateField(event.target.name, dhlValues);
    this.setState({ routingPriority });
  };
  handleCustomer = (event, values) => {
    event.persist();

    let name = values && values.text;
    let customerID = values && values.id;
    const { routingPriority } = this.state;
    routingPriority["customer"] = name;
    routingPriority["customerID"] = customerID;
    this.validateField("customer", customerID);
    this.setState({ routingPriority });
  };
  handleLocation = (event, values) => {
    event.persist();
    let locationName = values && values.locationName;
    let locationID = values && values.locationID;
    const { routingPriority } = this.state;
    routingPriority["location"] = locationName;
    routingPriority["locationID"] = locationID;
    this.validateField("location", locationID);
    this.setState({ routingPriority });
    //console.log(routingPriority);
  };
  handleDistributorChange = (event, values) => {
    const { routingPriority } = this.state;
    let distributorID = values && values.distributorID;
    routingPriority["distributor"] = values;
    routingPriority["distributorID"] = distributorID;
    // this.validateField("distributor", distributorID);
    this.setState({ routingPriority });
  };
  handleDispatchChange = (event, values) => {
    const { routingPriority } = this.state;
    let dispatchTypeID = values && values.dispatchTypeID;
    routingPriority["dispatchType"] = values;
    routingPriority["dispatchTypeID"] = dispatchTypeID;
    this.validateField("dispatch", dispatchTypeID);
    this.setState({ routingPriority });
  };
  handleDispatchModeChange = (event, values) => {
    const { routingPriority } = this.state;
    let dispatchModeID = values && values.dispatchModeID;
    routingPriority["dispatchMode"] = values;
    routingPriority["dispatchModeID"] = dispatchModeID;
    //this.validateField("dispatchMode", dispatchModeID);
    this.setState({ routingPriority });
  };
  handleMailingSchemeChange = (event, values) => {
    const { routingPriority } = this.state;
    let mailingSchemeID = values && values.mailingSchemeID;
    routingPriority["mailingScheme"] = values;
    routingPriority["mailingSchemeID"] = mailingSchemeID;
    // this.validateField("mailingScheme", mailingSchemeID);
    this.setState({ routingPriority });
  };
  handleChange = (event) => {
    event.persist();

    const { routingPriority } = this.state;
    if (event.target.name === "isPeriodical") {
      routingPriority[event.target.name] = event.target.checked;
    } else {
      routingPriority[event.target.name] = event.target.value;
    }

    this.setState({ routingPriority });

    this.validateField(event.target.name, event.target.value);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let customerValid = this.state.customerValid;
    let priorityCodeValid = this.state.priorityCodeValid;
    let locationValid = this.state.locationValid;
    let dispatchTypeValid = this.state.dispatchTypeValid;
    let noOfOrdersFromValid = this.state.noOfOrdersFromValid;
    let noOfOrdersToValid = this.state.noOfOrdersToValid;
    let noOfCopiesFromValid = this.state.noOfCopiesFromValid;
    let noOfCopiesToValid = this.state.noOfCopiesToValid;

    let weightFromValid = this.state.weightFromValid;
    let weightToValid = this.state.weightToValid;
    let ppiNumberValid = this.state.ppiNumberValid;
    let ppiLicenseNoValid = this.state.ppiLicenseNoValid;

    let returnAddressValid = this.state.returnAddressValid;
    let carrierSheetSchemeValid = this.state.carrierSheetSchemeValid;
    let carrierSheetModeValid = this.state.carrierSheetModeValid;
    switch (fieldName) {
      case "returnAddress":
        returnAddressValid = generalValidator(value);
        fieldValidationErrors.returnAddress = returnAddressValid
          ? ""
          : " Please Enter Alphabets only";
        break;
      case "carrierSheetScheme":
        fieldValidationErrors.carrierSheetScheme = "";
        if (value !== "") {
          carrierSheetSchemeValid = generalValidator(value);
          fieldValidationErrors.carrierSheetScheme = carrierSheetSchemeValid
            ? ""
            : " Please Enter Alphabets only";
        }
        break;
      case "carrierSheetMode":
        fieldValidationErrors.carrierSheetMode = "";
        if (value !== "") {
          carrierSheetModeValid = generalValidator(value);
          fieldValidationErrors.carrierSheetMode = carrierSheetModeValid
            ? ""
            : " Please Enter AlphaNumeric only";
        }
        break;

      case "ppiNumber":
        ppiNumberValid = generalValidator(value);
        fieldValidationErrors.ppiNumber = ppiNumberValid
          ? ""
          : " Please Enter AlphaNumeric only";
        break;
      case "ppiLicenseNo":
        ppiLicenseNoValid = generalValidator(value);
        fieldValidationErrors.ppiLicenseNo = ppiLicenseNoValid
          ? ""
          : " Please Enter AlphaNumeric only";
        break;
      case "weightFrom":
        //fieldValidationErrors.weightFrom = "";
        if (value !== "") {
          weightFromValid = checkrate(value);
          fieldValidationErrors.weightFrom = weightFromValid
            ? ""
            : " Please Enter valid Weight From";
        } else {
          weightFromValid = false;
          fieldValidationErrors.weightFrom = "";
        }

        break;
      case "weightTo":
        //fieldValidationErrors.weightTo = "";
        if (value !== "") {
          weightToValid = checkrate(value);
          fieldValidationErrors.weightTo = weightToValid
            ? ""
            : " Please Enter valid Weight To";
        } else {
          weightToValid = false;
          fieldValidationErrors.weightTo = "";
        }

        break;
      case "noOfCopiesFrom":
        //fieldValidationErrors.noOfCopiesFrom = "";
        if (value !== "") {
          noOfCopiesFromValid = Reorderqty(value);
          fieldValidationErrors.noOfCopiesFrom = noOfCopiesFromValid
            ? ""
            : " Please Enter Numeric only";
        } else {
          noOfCopiesFromValid = false;
          fieldValidationErrors.noOfCopiesFrom = "";
        }
        break;
      case "noOfCopiesTo":
        //fieldValidationErrors.noOfCopiesTo = "";
        if (value !== "") {
          noOfCopiesToValid = Reorderqty(value);
          fieldValidationErrors.noOfCopiesTo = noOfCopiesToValid
            ? ""
            : " Please Enter Numeric only";
        } else {
          noOfCopiesToValid = false;
          fieldValidationErrors.noOfCopiesTo = "";
        }
        break;
      case "noOfOrdersTo":
        if (value !== "") {
          noOfOrdersToValid = Reorderqty(value);
          fieldValidationErrors.noOfOrdersTo = noOfOrdersToValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          noOfOrdersToValid = false;
          fieldValidationErrors.noOfOrdersTo = "";
        }

        break;
      case "noOfOrdersFrom":
        if (value !== "") {
          noOfOrdersFromValid = Reorderqty(value);
          fieldValidationErrors.noOfOrdersFrom = noOfOrdersFromValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          noOfOrdersFromValid = false;
          fieldValidationErrors.noOfOrdersFrom = "";
        }

        break;

      case "location":
        locationValid = onlyNumbers(value);
        fieldValidationErrors.location = locationValid
          ? ""
          : " Please select Location";
        break;
      case "customer":
        customerValid = onlyNumbers(value);
        fieldValidationErrors.customer = customerValid
          ? ""
          : " Please select Customer";
        break;
      case "dispatch":
        dispatchTypeValid = onlyNumbers(value);
        fieldValidationErrors.dispatchType = dispatchTypeValid
          ? ""
          : " Please select Dispatch Type";
        break;
      case "priorityCode":
        priorityCodeValid = generalValidator(value);
        fieldValidationErrors.priorityCode = priorityCodeValid
          ? ""
          : " Please Enter Valid Priority Code";
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        customerValid: customerValid,
        priorityCodeValid: priorityCodeValid,
        locationValid: locationValid,
        dispatchTypeValid: dispatchTypeValid,
        //subscriptionValid: subscriptionValid,
        //supplementaryIndicatorValid: supplementaryIndicatorValid,
        //consolidationTypeValid: consolidationTypeValid,
        noOfOrdersFromValid: noOfOrdersFromValid,
        noOfOrdersToValid: noOfOrdersToValid,
        noOfCopiesFromValid: noOfCopiesFromValid,
        noOfCopiesToValid: noOfCopiesToValid,
        weightFromValid: weightFromValid,
        weightToValid: weightToValid,
        ppiNumberValid: ppiNumberValid,
        ppiLicenseNoValid: ppiLicenseNoValid,
        // returnAddressValid: returnAddressValid,
        // carrierSheetModeValid: carrierSheetModeValid,
        // carrierSheetSchemeValid: carrierSheetSchemeValid
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        //this.state.subscriptionValid &&
        this.state.customerValid &&
        this.state.locationValid &&
        //this.state.subscriptionValid &&
        this.state.priorityCodeValid &&
        this.state.dispatchTypeValid &&
        this.state.noOfOrdersFromValid &&
        this.state.noOfOrdersToValid &&
        this.state.noOfCopiesFromValid &&
        this.state.noOfCopiesToValid &&
        this.state.weightFromValid &&
        this.state.weightToValid &&
        this.state.ppiNumberValid &&
        this.state.ppiLicenseNoValid,
    });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      cptOfficeType: "",
      objectCode: "",
      subCode: "",
      isPeriodical: "",
      cptDescription: "",
      sapglAccount: "",
      errors: {},
    });
  };
  onBlur = (event) => {
    const { routingPriority } = this.state;
    let dhlValues = event.target.value;
    dhlValues = this.addZeroes(dhlValues.toString(), 2);
    routingPriority[event.target.name] = dhlValues;
    this.setState({ routingPriority });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      const { routingPriority } = this.state;

      let customerID = routingPriority.customerID;
      let locationID = routingPriority.locationID;

      let priorityCode = routingPriority.priorityCode;
      let noOfOrdersFrom = routingPriority.noOfOrdersFrom;
      let noOfOrdersTo = routingPriority.noOfOrdersTo;
      let noOfCopiesFrom = routingPriority.noOfCopiesFrom;
      let noOfCopiesTo = routingPriority.noOfCopiesTo;
      let weightFrom = routingPriority.weightFrom;
      let weightTo = routingPriority.weightTo;
      let ppiNumber = routingPriority.ppiNumber;
      let ppiLicenseNo = routingPriority.ppiLicenseNo;
      let dispatchTypeID = routingPriority.dispatchTypeID;
      let dispatchModeID = routingPriority.dispatchModeID;
      let mailingSchemeID = routingPriority.mailingSchemeID;
      let distributorID = routingPriority.distributorID;
      let returnAddress = routingPriority.returnAddress;
      let carrierSheetScheme = routingPriority.carrierSheetScheme;
      let carrierSheetMode = routingPriority.carrierSheetMode;
      let isActive = "true";
      let createdBy = localStorage.loggedInId;

      let reqBody = {
        customerID,
        locationID,
        priorityCode,
        noOfOrdersFrom,
        noOfOrdersTo,
        noOfCopiesFrom,
        noOfCopiesTo,
        weightFrom,
        weightTo,
        ppiNumber,
        ppiLicenseNo,
        dispatchTypeID,
        dispatchModeID,
        mailingSchemeID,
        distributorID,
        returnAddress,
        carrierSheetScheme,
        carrierSheetMode,
        isActive,
        createdBy,
      };
      postRoutingPriority(reqBody, this.props.history);
    }
  };
  render() {
    let { classes } = this.props;
    let {
      routingPriority,
      customersList,
      LocationList,
      DispatchTypeList,
      DispatchModeList,
      mailingSchemeList,
      DistributorList,
    } = this.state;

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Routing Priority</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <p className={classes.subHeader}>Enter Customer Information</p>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    required
                    includeInputInList
                    label="Customer"
                    style={{ display: "inline-flex" }}
                    name="customer"
                    options={customersList}
                    className={classes.comboBox}
                    onChange={this.handleCustomer}
                    value={routingPriority.customer}
                    getOptionLabel={(option) =>
                      option.text || routingPriority.customer
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Customer"
                        name="customer"
                        style={{ width: "100%" }}
                        margin="normal"
                      />
                    )}
                  />
                  <Autocomplete
                    autoComplete
                    required
                    includeInputInList
                    label="Location"
                    name="location"
                    style={{ display: "inline-flex" }}
                    options={LocationList}
                    className={classes.comboBox}
                    onChange={this.handleLocation}
                    value={routingPriority.location}
                    getOptionLabel={(option) =>
                      option.locationName || routingPriority.location
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Location"
                        name="location"
                        style={{ width: "100%" }}
                        margin="normal"
                      />
                    )}
                  />
                  <TextField
                    required
                    label="Priority Code"
                    name="priorityCode"
                    inputProps={{
                      maxLength: 3,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingPriority.priorityCode}
                    onChange={this.handleChange}
                    error={this.state.errors.priorityCode}
                    helperText={
                      this.state.errors.priorityCode
                        ? this.state.errors.priorityCode
                        : ""
                    }
                  />
                </div>
                <p className={classes.subHeader}>Enter Criteria Setting</p>
                <div>
                  <TextField
                    required
                    label="No Of Orders From"
                    name="noOfOrdersFrom"
                    inputProps={{
                      maxLength: 5,
                    }}
                    margin="normal"
                    className={classes.textField}
                    //onKeyPress={this._onCodeKeyPress}
                    onKeyPress={this._onNumberKeyPress}
                    value={routingPriority.noOfOrdersFrom}
                    onChange={this.handleChange}
                    error={this.state.errors.noOfOrdersFrom}
                    helperText={
                      this.state.errors.noOfOrdersFrom
                        ? this.state.errors.noOfOrdersFrom
                        : ""
                    }
                  />
                  <TextField
                    required
                    label="No Of Orders To"
                    name="noOfOrdersTo"
                    inputProps={{
                      maxLength: 5,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingPriority.noOfOrdersTo}
                    // onKeyPress={this._onCodeKeyPress}
                    onKeyPress={this._onNumberKeyPress}
                    onChange={this.handleChange}
                    error={this.state.errors.noOfOrdersTo}
                    helperText={
                      this.state.errors.noOfOrdersTo
                        ? this.state.errors.noOfOrdersTo
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    required
                    label="No Of Copies From"
                    name="noOfCopiesFrom"
                    inputProps={{
                      maxLength: 5,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingPriority.noOfCopiesFrom}
                    // onKeyPress={this._onCodeKeyPress}
                    onKeyPress={this._onNumberKeyPress}
                    onChange={this.handleChange}
                    error={this.state.errors.noOfCopiesFrom}
                    helperText={
                      this.state.errors.noOfCopiesFrom
                        ? this.state.errors.noOfCopiesFrom
                        : ""
                    }
                  />

                  <TextField
                    required
                    label="No Of Copies To"
                    name="noOfCopiesTo"
                    inputProps={{
                      maxLength: 5,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingPriority.noOfCopiesTo}
                    ///onKeyPress={this._onCodeKeyPress}
                    onKeyPress={this._onNumberKeyPress}
                    onChange={this.handleChange}
                    error={this.state.errors.noOfCopiesTo}
                    helperText={
                      this.state.errors.noOfCopiesTo
                        ? this.state.errors.noOfCopiesTo
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    required
                    label="Weight From"
                    id="txtdhlWeight"
                    name="weightFrom"
                    onKeyUp={this._onDecimalKeyPress}
                    className={classes.textField}
                    inputProps={{
                      maxLength: 10,
                      minLength: 2,
                      pattern: /^[0-9]\d*(?:\.\d{0,2})?$/,
                    }}
                    margin="normal"
                    autoComplete="off"
                    value={routingPriority.weightFrom}
                    error={this.state.errors.weightFrom}
                    onChange={this.handleChange1}
                    onBlur={this.onBlur}
                    helperText={
                      this.state.errors.weightFrom
                        ? this.state.errors.weightFrom
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Kg</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    required
                    label="Weight To"
                    id="txtdhlWeight"
                    name="weightTo"
                    onKeyUp={this._onDecimalKeyPress}
                    className={classes.textField}
                    inputProps={{
                      maxLength: 10,
                      minLength: 2,
                      pattern: /^[0-9]\d*(?:\.\d{0,2})?$/,
                    }}
                    margin="normal"
                    autoComplete="off"
                    value={routingPriority.weightTo}
                    error={this.state.errors.weightTo}
                    onChange={this.handleChange1}
                    onBlur={this.onBlur}
                    helperText={
                      this.state.errors.weightTo
                        ? this.state.errors.weightTo
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Kg</InputAdornment>
                      ),
                    }}
                  />
                </div>

                <p className={classes.subHeader}>Enter Routing Configuration</p>
                <div>
                  <TextField
                    required
                    label="Route PPI"
                    name="ppiNumber"
                    inputProps={{
                      maxLength: 20,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingPriority.ppiNumber}
                    onChange={this.handleChange}
                    error={this.state.errors.ppiNumber}
                    helperText={
                      this.state.errors.ppiNumber
                        ? this.state.errors.ppiNumber
                        : ""
                    }
                  />
                  <TextField
                    required
                    label="PPI License No"
                    name="ppiLicenseNo"
                    inputProps={{
                      maxLength: 20,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingPriority.ppiLicenseNo}
                    onChange={this.handleChange}
                    error={this.state.errors.ppiLicenseNo}
                    helperText={
                      this.state.errors.ppiLicenseNo
                        ? this.state.errors.ppiLicenseNo
                        : ""
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    className={classes.comboBox}
                    id="dispatchType"
                    options={DispatchTypeList}
                    onChange={this.handleDispatchChange}
                    value={routingPriority.dispatchType}
                    getOptionLabel={(option) =>
                      option.dispatchTypeCodeName ||
                      routingPriority.dispatchType
                    }
                    renderInput={(params) => (
                      <TextField
                        margin="normal"
                        {...params}
                        required
                        label="Dispatch Type"
                        name="dispatchType"
                        id="dispatchType"
                        // className={classes.textField}
                        style={{ width: "100%" }}
                        value={routingPriority.dispatchType}
                        error={this.state.errors.dispatchType}
                        helperText={
                          this.state.errors.dispatchType
                            ? this.state.errors.dispatchType
                            : ""
                        }
                      />
                    )}
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    className={classes.comboBox}
                    id="dispatchMode"
                    options={DispatchModeList}
                    onChange={this.handleDispatchModeChange}
                    value={routingPriority.dispatchMode}
                    getOptionLabel={(option) =>
                      option.dispatchModeName || routingPriority.dispatchMode
                    }
                    renderInput={(params) => (
                      <TextField
                        margin="normal"
                        {...params}
                        label="Dispatch Mode"
                        name="dispatchMode"
                        id="dispatchMode"
                        margin="normal"
                        // className={classes.textField}
                        style={{ width: "100%" }}
                        value={routingPriority.dispatchMode}
                        error={this.state.errors.dispatchMode}
                        helperText={
                          this.state.errors.dispatchMode
                            ? this.state.errors.dispatchMode
                            : ""
                        }
                      />
                    )}
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    className={classes.comboBox}
                    // style={{ width: "32.5%" }}
                    id="mailingScheme"
                    options={mailingSchemeList}
                    onChange={this.handleMailingSchemeChange}
                    value={routingPriority.mailingScheme}
                    getOptionLabel={(option) =>
                      option.mailingScheme || routingPriority.mailingScheme
                    }
                    renderInput={(params) => (
                      <TextField
                        margin="normal"
                        {...params}
                        label="Mailing Scheme"
                        name="mailingScheme"
                        id="mailingScheme"
                        margin="normal"
                        //className={classes.textField}
                        style={{ width: "100%" }}
                        value={routingPriority.mailingScheme}
                        error={this.state.errors.mailingScheme}
                        helperText={
                          this.state.errors.mailingScheme
                            ? this.state.errors.mailingScheme
                            : ""
                        }
                      />
                    )}
                  />
                </div>

                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    className={classes.comboBox}
                    id="distributor"
                    // style={{ width: "32.5%" }}
                    options={DistributorList}
                    onChange={this.handleDistributorChange}
                    value={routingPriority.distributor}
                    getOptionLabel={(option) =>
                      option.distributorName || routingPriority.distributor
                    }
                    renderInput={(params) => (
                      <TextField
                        margin="normal"
                        {...params}
                        label="Distributor"
                        name="distributor"
                        id="distributor"
                        //style={{ width: "32.5%" }}
                        margin="normal"
                        // className={classes.textField}
                        style={{ width: "100%" }}
                        value={routingPriority.distributor}
                        error={this.state.errors.distributor}
                        helperText={
                          this.state.errors.distributor
                            ? this.state.errors.distributor
                            : ""
                        }
                      />
                    )}
                  />
                  <TextField
                    label="Return Address"
                    name="returnAddress"
                    inputProps={{
                      maxLength: 50,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingPriority.returnAddress}
                    onChange={this.handleChange}
                    error={this.state.errors.returnAddress}
                    helperText={
                      this.state.errors.returnAddress
                        ? this.state.errors.returnAddress
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    label="Carrier Sheet Mode"
                    name="carrierSheetMode"
                    inputProps={{
                      maxLength: 50,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingPriority.carrierSheetMode}
                    onChange={this.handleChange}
                    error={this.state.errors.carrierSheetMode}
                    helperText={
                      this.state.errors.carrierSheetMode
                        ? this.state.errors.carrierSheetMode
                        : ""
                    }
                  />
                  <TextField
                    label="Carrier Sheet Scheme"
                    name="carrierSheetScheme"
                    inputProps={{
                      maxLength: 50,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingPriority.carrierSheetScheme}
                    onChange={this.handleChange}
                    error={this.state.errors.carrierSheetScheme}
                    helperText={
                      this.state.errors.carrierSheetScheme
                        ? this.state.errors.carrierSheetScheme
                        : ""
                    }
                  />
                </div>
                {/* <div>
                  <FormControlLabel
                    name="isPeriodical"
                    style={{ marginTop: "25px", marginLeft: "7px" }}
                    control={
                      <Checkbox
                        className={classes.Checkbox}
                        checked={routingPriority.isPeriodical}
                        color="primary"
                        onChange={this.handleChange}
                        value={routingPriority.isPeriodical}
                      />
                    }
                    label="Is Periodical"
                  />
                </div> */}

                <Divider style={{ marginTop: "25px" }} />

                <div
                  className="button-wrapper"
                  style={{ paddingLeft: "0px", marginLeft: "0px" }}
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/routingPriority")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                        //className="enabled-button"
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                        //className="enabled-button"
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddRoutingPriority));
