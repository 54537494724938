import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { StateContext } from "../../../shared/globalState";
import { postDept } from "../masterActions";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { resourceValidation } from "../../../shared/resource";
import { addStyles } from "../../../shared/styles";

const validateMaxMinLength2 = new Validator("maxmin2length");

class AddDeapartment extends React.Component {
  constructor() {
    super();

    this.state = {
      departmentCode: "",
      departmentName: "",
      errors: {},
      departmentCodeValid: false,
      departmentNameValid: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  static contextType = StateContext;
  componentDidMount() {
    this.props.handleSettingHeader("Department");
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.validateField(e.target.name, e.target.value);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let departmentCodeValid = this.state.departmentCodeValid;
    let departmentNameValid = this.state.departmentNameValid;
    switch (fieldName) {
      case "departmentCode":
        departmentCodeValid = validateMaxMinLength2(value);
        fieldValidationErrors.departmentCode = departmentCodeValid
          ? ""
          : resourceValidation.max2char;
        break;
      case "departmentName":
        departmentNameValid = true;
        fieldValidationErrors.departmentName = "";
        // departmentNameValid = validateMaxLength100(value);
        // fieldValidationErrors.departmentName = departmentNameValid
        //   ? ""
        //   : resourceValidation.onlyAlphabet;
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        departmentCodeValid: departmentCodeValid,
        departmentNameValid: departmentNameValid
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.departmentCodeValid && this.state.departmentNameValid
    });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      departmentCode: "",
      departmentName: "",
      errors: {}
    });
  };

  _onCodeKeyPress = event => {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.validateForm()) {
      const { departmentCode, departmentName } = this.state;
      let createdBy = localStorage.loggedInId;
      let reqBody = { departmentCode: this.state.departmentCode.toUpperCase(), departmentName, createdBy };
      let history = this.props.history;
      postDept(reqBody, history, window.localStorage.token);
    }
  };
  render() {
    let { classes } = this.props;
    let { departmentCode, departmentName } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Deparment</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <TextField
                  required
                  label="Deparment Code"
                  name="departmentCode"
                  inputProps={{
                    maxLength: 2
                  }}
                  margin="normal"
                  className={classes.textField}
                  value={departmentCode.toUpperCase()}
                  onChange={this.handleChange}
                  error={this.state.errors.departmentCode}
                  helperText={
                    this.state.errors.departmentCode
                      ? this.state.errors.departmentCode
                      : ""
                  }
                  onKeyPress={this._onCodeKeyPress}
                />
                <TextField
                  required
                  inputProps={{
                    maxLength: 100
                  }}
                  label="Department Name"
                  name="departmentName"
                  margin="normal"
                  className={classes.textField}
                  value={departmentName}
                  onChange={this.handleChange}
                  error={this.state.errors.departmentName}
                  helperText={
                    this.state.errors.departmentName
                      ? this.state.errors.departmentName
                      : ""
                  }
                />
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={e => this.props.history.push("/department")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddDeapartment));
