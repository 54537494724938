import React, { Component, useState } from "react";
import { TextField, Button, Paper, Divider, Link } from "@material-ui/core";
import { CustomSmallAddIcon } from "../../../shared/customIcons";
import { EditIcon } from "../../../shared/customIcons";
import { resourceValidation } from "../../../shared/resource";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import { lighten, makeStyles } from "@material-ui/core/styles";
import FileImg from "../../../assests/images/svg/filesupload-illustration.svg";
import DoneIcon from "@material-ui/icons/Done";
import Grid from "@material-ui/core/Grid";
import IndividualSearchAddDialog from "../../publisherBackLabel/ebloOrderPlacement/IndividualSearchAddDialog";
import GetAppIcon from "@material-ui/icons/GetApp";
import DescriptionIcon from "@material-ui/icons/Description";
import { addDispatchStyles } from "../../../shared/styles";
import { withStyles } from "@material-ui/core/styles";
import { getDHLRateUponAWeight } from "../../publisher/mainLabelOrderPlacement/action"
import Dialog from "@material-ui/core/Dialog";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";

import TableRow from "@material-ui/core/TableRow";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";

import { Autocomplete } from "@material-ui/lab";

import moment from "moment";

import Typography from "@material-ui/core/Typography";

import {
  stableSort,
  getSorting,
  DialogActions,
} from "../../../shared/tableCommonFunctions";

import LinearProgress from "@material-ui/core/LinearProgress";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";

import Dropzone from "react-dropzone";
import {
  getAllData,
  getDataInformation,
  getOrderDetail,
  getJournalQty,
  getOrderRecepientList,
  getDeliveryList,
  updateBPAOrder,
  getFileDetail,
  submitBPAOrder,
  fileUploadBPA,
  deleteJournalInsideBPA
} from "./action";
import { getCountryDropdownList } from "../../masters/masterActions";
import Validator from "../../../shared/validator";
const emailValidator = new Validator("email");
const headCells1 = [
  // {
  //   id: 1,
  //   code: "orderID",

  //   show: true,

  //   numeric: false,
  //   disablePadding: true,
  //   label: "Order ID",
  // },
  {
    id: 1,
    code: "itemDescription",

    show: true,

    numeric: false,
    disablePadding: true,
    label: "Issue Description",
  },
  {
    id: 2,
    code: "journalTitle",
    numeric: false,
    disablePadding: false,
    label: "Journal Title",
    show: true,
    width: "200px",
  },
  {
    id: 3,
    code: "acronym",

    show: true,

    numeric: false,
    disablePadding: true,
    label: "Acronym",
  },
  {
    id: 4,
    code: "volume",

    show: true,

    numeric: false,
    disablePadding: true,
    label: "Volume",
  },
  {
    id: 5,
    code: "issue",

    show: true,

    numeric: false,
    disablePadding: true,
    label: "Issue",
  },
  {
    id: 6,
    code: "supplement",

    show: true,

    numeric: false,
    disablePadding: true,
    label: "Supplement",
  },
  // {
  //   id: 7,
  //   code: "partNumber",

  //   show: true,
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Part Number",
  // },
  {
    id: 77,
    code: "availableQuantity",
    numeric: false,
    disablePadding: false,
    label: "Available Qty",
    show: true,
    width: "82px",
  },
  {
    id: 78,
    code: "orderedQuantity",
    numeric: false,
    disablePadding: false,
    label: "Ordered Qty",
    show: true,
    width: "82px",
  },
  {
    id: 7,
    code: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Balance Qty",
    show: true,
    width: "82px",
  },
  {
    id: 8,
    code: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
    show: true,
    width: "82px",
  },
  {
    id: 9,
    code: "trackingNumber",
    numeric: false,
    disablePadding: false,
    label: "Tracking Number",
    show: true,
    width: "230px",
  },
  {
    id: 10,
    code: "noOfCartons",
    numeric: false,
    disablePadding: false,
    label: "No of Cartons",
    show: true,
    width: "230px",
  },
  {
    id: 11,
    code: "actualWeight",
    numeric: false,
    disablePadding: false,
    label: "Actual Weight",
    show: true,
    width: "116px",
  },
  {
    id: 12,
    code: "deliveryDate",
    numeric: false,
    disablePadding: false,
    label: "Delivery Date",
    show: true,
    width: "207px",
  },
  {
    id: 13,
    code: "dhlRate",
    numeric: false,
    disablePadding: false,
    label: "DHL Rate",
    show: true,
    width: "207px",
  },
  {
    id: 14,
    code: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    show: true,
  },
];
const headCells = [
  {
    id: "toAsiaSpecific",
    numeric: false,
    disablePadding: true,
    label: "To Asia Specific",
  },
  {
    id: "toUsaEurope",
    numeric: false,
    disablePadding: false,
    label: "To USA./Europe",
  },
  {
    id: "toRestOfWorld",
    numeric: false,
    disablePadding: false,
    label: "To Rest Of World",
  },
];
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderLeft: "1px sold #000", width: `${headCell.width}` }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function EnhancedTableHead1(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    headCells,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{
              borderLeft: "1px sold #000",
              width: `${headCell.width}`,
              color: "rgb(48, 109, 202)",
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const useToolbarStyles1 = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

function Basic(props) {
  return (
    <div
      style={{
        margin: "1em 0 .5em",
        border: "1px solid #eaeaea",
        // width: "97.6%",
        borderRadius: "4px",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0)",
      }}
    >
      <section
        style={{ marginLeft: "1.5em", marginRight: "1.5em", marginTop: "1em" }}
      >
        <Dropzone
          onDrop={props.onDrop}
          accept=".xlsx,.jpg,.doc,.docx,.pdf,.txt,.bmp"
          minSize={0}
          maxSize={1024000}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({})}>
              <input {...getInputProps()} />
              <div
                style={{
                  width: "auto",
                  border: "1.5px dashed #eaeaea",
                  marginBottom: "1em",
                  borderRadius: "4px",
                  minHeight: "70px",
                  cursor: "pointer",
                }}
              >
                <label
                  className="upload excel"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "70px",
                    cursor: "pointer",
                    padding: ".3em",
                  }}
                >
                  <img
                    style={{ height: "60px", marginTop: "4px" }}
                    src={FileImg}
                    alt="File upload img"
                  />

                  <div
                    style={{
                      display: "inline-block",
                      width: "auto",
                      marginLeft: "12px",
                      width: "320px",
                    }}
                  >
                    <div className="file-upload-head">Add files</div>
                    <div className="file-upload-contant">
                      Drop files here or click{" "}
                      <span
                        style={{
                          color: "#306dca",
                          textDecoration: "underline",
                        }}
                      >
                        browse
                      </span>{" "}
                      through your machine{" "}
                    </div>
                    <div className="file-upload-footer">
                      Allowed all types of file, 1MB file limit.
                    </div>
                  </div>
                </label>
              </div>
            </div>
          )}
        </Dropzone>
      </section>
    </div>
  );
}

function DetectionControl(file) {
  return (
    <div
      style={{
        width: "45%",
        display: "flex",
        alignItems: "center",
        marginTop: "-1px",
      }}
    >
      <span>
        {true ? (
          <CheckCircleIcon
            style={{ color: "rgba(59, 218, 182, 0.87)", fontSize: "19px" }}
          />
        ) : (
            <InfoIcon style={{ color: "red", fontSize: "19px" }} />
          )}
      </span>
      <span
        style={{
          marginTop: "-5px",
          marginLeft: "5px",
          fontSize: "11px",
        }}
      >
        {true
          ? "Uploaded successfully"
          : file.fileName && file.fileName.message}
      </span>
    </div>
  );
}

class EditBpaOrderPlacement extends Component {
  state = {
    selRecordForDelete: {},
    deletePopUpOpen: false,
    deletionId: [],
    subMode: false,
    proMode: false,
    update: false,
    errors: {
      emailID: false,
    },
    currentMode: false,
    okToGO: true,
    newPartNumber: 0,
    newQuantity: 0,
    newTrackingNumber: "",
    newActualWeight: "0.000",
    noOfCartons: "",
    dhlRate: "",
    newDeliveryDate: null,
    page: 0,
    rowsPerPage: 100,
    order: "desc",
    orderBy: "modifiedDate",
    selectedEditIndex: null,
    codeTypeList: [],
    fileList: [],
    loading1: false,
    loading: false,
    jouranlUniqueID: "",
    stateFiles: [],
    isManual: true,
    openDialog: false,
    balanceQty: "",
    dataInformation: {
      bpaOrderHeaderID: "",
      bpaRecipientID: "",
      bpaSuffix: "",
      bpaRunningID: "",
      bpaCode: "",
      companyName: "",
      department: "",
      postalCode: "",
      conferenceName: "",
      costCoding: "",
      address: "",
      contactPersonName: "",
      countryID: "",
      phoneNumber: "",
      conferenceRef: "",
      emailID: "",
      address2: "",
      deliveryMethodID: "",
      deliveryInstructions: "",
      remarks: "",
      packingInstructions: "",
      doNotDeliveryBefore: null,
      mustBeDeliveryBy: null,
      mustBeDeliveryOn: null,

      createdDate: "",

      modifiedDate: "",
      status: "",
      statusID: "",
      trackingNumber: null,
      isActive: null,
      deliveredDate: null,
      actualWeight: null,

      bpaRecipient: null,
      country: null,
      createdByNavigation: null,
      deliveryMethod: null,
      modifiedByNavigation: null,
      tbL_PGSBPAAttatchDetail: "",
      tbL_PGSBPAOrderDetail: "",
    },
    recipientList: [],
    deliveryCountryDD: [],
    deliveryListDD: [],
    deliveryID: "",
    deliveryAddress: "",
    deliveryCountry: "",
    countryID: "",
    sadMode: false,
    deliveryListData: "",
    deliveryListDataID: "",
    showError: false,
    deliveryList: [
      {
        region: "To Asia Specific",
        daySpan: "2 - 4 Working Days",
      },
      {
        region: "To USA./Europe",
        daySpan: "3 - 6 Working Days",
      },
      {
        region: "To Rest of the World",
        daySpan: "4 - 7 Working Days",
      },
    ],
    headCellsNew: [
      {
        id: 1,
        code: "issueDescription",
        numeric: false,
        disablePadding: false,
        label: "Issue Description",
        show: true,
        width: "200px",
      },
      {
        id: 2,
        code: "journalTitle",
        numeric: false,
        disablePadding: false,
        label: "Journal Title",
        show: true,
        width: "200px",
      },
      {
        id: 3,
        code: "acronym",
        numeric: false,
        disablePadding: false,
        label: "Acronym",
        show: true,
        width: "58px",
      },

      {
        id: 4,
        code: "volume",
        numeric: false,
        disablePadding: false,
        label: "Volume",
        show: true,
        width: "50px",
      },
      {
        id: 5,
        code: "issue",
        numeric: false,
        disablePadding: false,
        label: "Issue",
        show: true,
        width: "56px",
      },
      {
        id: 6,
        code: "supplement",
        numeric: false,
        disablePadding: false,
        label: "Supplement",
        show: true,
        width: "60px",
      },
      {
        id: 7,
        code: "action",
        numeric: false,
        disablePadding: false,
        label: "Action",
        show: true,
      },
    ],
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.jouranlUniqueID !== prevState.jouranlUniqueID) {
      if (this.state.jouranlUniqueID !== "") {
        getJournalQty(this.state.jouranlUniqueID).then((r) => {
          let balQty = "";
          let availableQty = ""
          let orderedQty = ""
          balQty = r && r.data.balanceQty;
          availableQty = r && r.data.availableQty
          orderedQty = r && r.data.orderedQty
          let length = this.state.codeTypeList.length - 1;

          let change = { ...this.state.codeTypeList[length], balQty, availableQty, orderedQty };
          let arr = [...this.state.codeTypeList];

          if (balQty > 0) {
            arr = [...this.state.codeTypeList, change];
            arr.splice(length, 1);
          } else {
            arr.splice(length, 1);
            alert("No balance quantity for this item");
            this.setState({ jouranlUniqueID: "" });
          }

          if (r)
            this.setState({ balanceQty: r.data.balanceQty, codeTypeList: arr });
        });
      }
    }
  };
  componentDidMount = () => {
    this.props.handleSettingHeader("Order Placement");
    this.props.setSelectedList("bpaMarketing", "bpaOrderPlacement");
    let bpaHeaderID = window.location.pathname.split("/")[2];
    getFileDetail(bpaHeaderID).then((r) => {
      let newList = [...r.data];
      let updateList = newList.map((r) => {
        return { ...r, name: r.fileName, size: r.size.split(" ")[0], sizeVariant: r.size.split(" ")[1] };
      });
      this.setState({ stateFiles: updateList });
    });
    getCountryDropdownList().then((r) => {
      if (r) {
        this.setState({
          deliveryCountryDD:
            r.data &&
            r.data.sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
      }
    });
    let filterData = [];
    const userID = localStorage.loggedInId;

    getOrderRecepientList(userID).then((r) => {
      this.setState({
        recipientList:
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });

    getDeliveryList().then((r) => {
      this.setState({
        deliveryListDD:
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });

    getOrderDetail(bpaHeaderID).then((r) => {
      let newList = [];
      if (r.data) {
        newList = r.data.map((p) => {
          return { ...p, isDB: true };
        });
      }
      getDataInformation(bpaHeaderID).then((r) => {
        let countryName = {
          text: r.data.country.countryCode + " - " + r.data.country.countryName,
        };
        let mode = false;
        let status = r.data.status.pubStatusName;
        if (status !== "SavedAsDraft" || status !== "Submitted") {
          mode = true;
        }
        if (status === "SavedAsDraft" || status === "Saved As Draft" || status === "Submitted") {
          this.setState({ sadMode: true, mode: false })
        }
        // if (status === "Submitted") {
        //   this.setState({ subMode: true });
        // }
        if (status === "Processed" || status === "Completed") {
          this.setState({ proMode: true });
        }

        const { deliveryCountryDD } = this.state;

        let deliveryData = [];

        this.setState({
          countryID: r.data.country.countryID,
          deliveryCountry: countryName,
        });
        if (r.data) {
          let ID = r.data.bpaRecipientID;

          let ID3 = r.data.deliveryMethodID;

          filterData =
            this.state.recipientList &&
            this.state.recipientList.filter((p) => {
              return p.id == ID;
            });

          deliveryData =
            this.state.deliveryListDD &&
            this.state.deliveryListDD.filter((p) => {
              return p.id == ID3;
            });

          let data = r.data;
          // let doNotDeliveryBefore = null;
          // let mustBeDeliveryBy = null;

          // if (r.data.doNotDeliveryBefore !== null) {
          //   doNotDeliveryBefore = moment(
          //     r.data.doNotDeliveryBefore,
          //     "MM/DD/YYYY HH:mm:ss"
          //   ).toLocaleString();
          // }
          // if (r.data.mustBeDeliveryBy !== null) {
          //   mustBeDeliveryBy = moment(
          //     r.data.mustBeDeliveryBy,
          //     "MM/DD/YYYY HH:mm:ss"
          //   ).toLocaleString();
          // }

          data = {
            ...data,
            // doNotDeliveryBefore,
            // mustBeDeliveryBy,
          };
          this.setState({
            currentMode: mode,
            dataInformation: data,
            deliveryID: filterData[0] && filterData[0].id,
            deliveryAddress: filterData[0] && filterData[0].text,

            deliveryListDataID: deliveryData[0] && deliveryData[0].id,
            deliveryListData: deliveryData[0] && deliveryData[0],
          });
        }
      });
      this.setState({ codeTypeList: newList });
    });
  };
  handleAutocompleteDeliveryAddress = (event, values) => {
    let id = values && values.id;
    let deliveryAddress = values;
    let deliveryID = id;
    this.setState({ deliveryAddress, deliveryID, update: true });
  };
  handleCountryChange = (event, values) => {
    let id = values && values.id;
    let deliveryCountry = values;
    let countryID = id;
    this.setState({ deliveryCountry, countryID, update: true });
    if (values) {
      if (this.state.newActualWeight)
        getDHLRateUponAWeight(values.id, this.state.newActualWeight).then(r => {
          // console.log(r)
          if (r && r.data) {
            this.setState({ dhlRate: r.data.dhlRate ? r.data.dhlRate : "" });
          }
          else this.setState({ dhlRate: "" });
        })
    }
  };
  handleDeliveryListData = (event, values) => {
    this.setState({ update: true });
    let id = values && values.id;
    let deliveryListData = values;
    let deliveryListDataID = id;
    this.setState({ deliveryListData, deliveryListDataID });
  };
  deleteSelectedFile = (i, id, id2) => {
    this.setState({ update: true });
    let newStateFiles = [...this.state.stateFiles];
    let newFileList = [...this.state.fileList];
    let idlist = [...this.state.deletionId];
    if (id2) idlist.push(id2);
    // id && deleteOrder(id, window.localStorage.loggedInId).then((r) => {});
    newStateFiles.splice(i, 1);
    newFileList.splice(i, 1);
    this.setState({
      stateFiles: newStateFiles,
      fileList: newFileList,
      deletionId: idlist,
    });
  };
  onDrop = (acceptedFiles) => {
    this.setState({ update: true });
    let newList = [...this.state.stateFiles];

    if (acceptedFiles.length > 2) return alert("Max Two files can be uploaded");
    let flag = false;
    let k = acceptedFiles.map((r) => {
      if (this.state.stateFiles.length > 0) {
        for (let i = 0; i < this.state.stateFiles.length; i++) {
          if (this.state.stateFiles[i].name === r.name) {
            flag = true;
          }
        }
      }
    });
    if (flag === true) return alert("Same name already exist");

    if (this.state.stateFiles.length < 2) {
      newList.push(...acceptedFiles);
      if (newList.length > 2) {
        return alert("Max Two files can be uploaded");
      }

      this.setState({
        loading: true,
        stateFiles: newList,
      });
    } else {
      alert("Max Two files can be uploaded");
    }
  };
  handleChangeActualWeight = (e) => {
    this.setState({ update: true });
    let rr = e.target.value;
    if (e.target.name === "newActualWeight") {
      rr = this.checkDHlWeightDec(rr.toString());
      // console.log(rr)

      if (e.target && e.target.value && this.state.countryID)
        getDHLRateUponAWeight(this.state.countryID, e.target.value).then(r => {
          // console.log(r)
          if (r && r.data) {
            this.setState({ dhlRate: r.data.dhlRate ? r.data.dhlRate : "" });
          }
          else this.setState({ dhlRate: "" });
        })


    }
    this.setState({ [e.target.name]: rr });
  };

  handleChange = (event) => {
    this.setState({ update: true });
    const { dataInformation } = this.state;

    dataInformation[event.target.name] = event.target.value;

    this.setState({ dataInformation });
  };
  // handleTimeChange = (time) => {
  //   this.setState({ update: true });
  //   const { dataInformation } = this.state;
  //   dataInformation["doNotDeliveryBefore"] = time && time;
  //   this.setState({ dataInformation });
  // };
  // handleTimeChange1 = (time) => {
  //   this.setState({ update: true });
  //   const { dataInformation } = this.state;
  //   dataInformation["mustBeDeliveryBy"] = time && time;
  //   this.setState({ dataInformation });
  // };
  handleDateChange = (date) => {
    this.setState({ update: true });
    const { dataInformation } = this.state;
    dataInformation["mustBeDeliveryOn"] = date && date.toLocaleDateString();
    this.setState({ dataInformation });
  };
  handleDateChange2 = (date) => {
    this.setState({ update: true });
    const { dataInformation } = this.state;
    dataInformation["doNotDeliveryBefore"] = date && date.toLocaleDateString();
    this.setState({ dataInformation });
  };
  handleDateChange3 = (date) => {
    this.setState({ update: true });
    const { dataInformation } = this.state;
    dataInformation["mustBeDeliveryBy"] = date && date.toLocaleDateString();
    this.setState({ dataInformation });
  };
  handleChangeEmail = (e, validate) => {
    this.setState({ update: true });
    let newRrrors = { ...this.state.errors };
    let result = validate(e.target.value);
    newRrrors[e.target.name] = !result;
    const { dataInformation } = this.state;

    dataInformation[e.target.name] = e.target.value;
    this.setState({ dataInformation, errors: newRrrors });
  };


  _onMobileNumberKeyPress(event) {
    const re = /[0-9+()-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleKeypress = (e) => {
    e.preventDefault();
    return false;
  };
  handleDateChange1 = (date, name) => {
    this.setState({ update: true });
    this.setState({ [name]: date });
  };
  handleJournalUpdate = (selectedJournalList) => {
    this.setState({ update: true });
    this.setState({ jouranlUniqueID: selectedJournalList[0].journalID });
    let codeListArry = [...this.state.codeTypeList];

    let boolVal = codeListArry.filter((r) => {
      return r.issueDecription == selectedJournalList[0].issueDescription;
    });
    if (boolVal.length > 0) {
      this.setState(
        {
          openDialog: false,
          codeTypeList: codeListArry,
          okToGO: false,
        },
        () => {
          alert("Value already exist in the table");
        }
      );
    } else {
      selectedJournalList.map((journal) => {
        let journalTemp = {
          journalID: journal.journalID,
          acronym: journal.acronym,
          volume: journal.volume,
          issue: journal.issue,
          suppNumber: journal.supplement,
          journalTitle: journal.journalTitle,
          orderID: "",
          issueDecription: journal.issueDescription,
          partNumber: "",
          trackingNumber: "",
          actualWeight: 0,
          delievredDate: null,
          orderQuantity: 0,
          dhlRate: 0,
          noOfCartons: 0
        };

        codeListArry.push(journalTemp);
      });
    }
    if (
      this.state.codeTypeList.length === 0 &&
      selectedJournalList.length > 0
    ) {
      this.setState({
        loading1: false,
      });
    }

    this.setState({
      openDialog: false,
      codeTypeList: codeListArry,
      okToGO: false,
      selectedEditIndex: this.state.codeTypeList.length,
    });
  };
  handleChange1 = (e) => {
    // this.setState({ update: true });
    this.setState({ [e.target.name]: e.target.value });
  };
  handleChange2 = (e, row, i) => {
    let savedVal = e.target.value
    let savedName = e.target.name
    let newList = [...this.state.codeTypeList]
    // console.log(this.state.codeTypeList)
    getJournalQty(row.journalID).then(x => {
      // console.log(x)
      let newBlQty = 0
      if (x && x.data) {
        newBlQty = x.data.balanceQty
        newList[i].balQty = x.data.balanceQty
        newList[i].availableQty = x.data.availableQty
        newList[i].orderedQty = x.data.orderedQty
      }
      this.setState({ update: true, codeTypeList: newList });
      if (savedVal !== "" && savedVal <= newBlQty) {
        this.setState({ okToGO: true });
      } else {
        this.setState({ okToGO: false });
      }
      if (newBlQty < savedVal) {
        alert("Quantity should be less than Balance Quantity");
        this.setState({ okToGO: false });
      }
      this.setState({ [savedName]: savedVal });
    })

  };

  handleAddJournalClose = () => {
    this.setState({
      openDialog: false,
    });
  };

  handleAddJournalClick = () => {
    this.setState({
      openDialog: true,
      update: true,
    });
  };
  handleDoneClicked = (e, i) => {
    this.setState({ update: true });
    let codeTypeCopy = this.state.codeTypeList;

    codeTypeCopy.map((e, index) => {
      if (index === i) {
        e.partNumber = this.state.newPartNumber;
        e.orderQuantity = this.state.newQuantity;
        e.trackingNumber = this.state.newTrackingNumber;
        e.actualWeight = this.state.newActualWeight;
        e.delievredDate = this.state.newDeliveryDate;
        e.dhlRate = this.state.dhlRate
        e.noOfCartons = this.state.noOfCartons
      }
    });

    this.setState({
      selectedEditIndex: null,
      isEditClicked: false,
      okToGO: true,
      newPartNumber: "",
      newQuantity: 0,
      jouranlUniqueID: "",
      newTrackingNumber: "",
      noOfCartons: 0,
      dhlRate: 0,
      newActualWeight: "",
      newDeliveryDate: null,
      codeTypeList: codeTypeCopy,

    });
  };

  handleCancelClicked = (e, i) => {
    this.setState({
      selectedEditIndex: null,
      isEditClicked: false,
      okToGO: false,
      newPartNumber: "",
      newQuantity: 0,
      newTrackingNumber: "",
      noOfCartons: 0,
      dhlRate: 0,
      newActualWeight: "",
      newDeliveryDate: null,
      jouranlUniqueID: "",
      update: false,
    });
  };
  checkDHlWeightDec1(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      // value = value.substring(0, value.length - 1);
      value = value.substring(0, 5);

    }
    return value;
  }
  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, 5);
    }
    return value;
  }
  handleDeleteClicked = (event, index, row) => {
    this.setState({ update: true });
    const journalList = [...this.state.codeTypeList];
    journalList.splice(index, 1);
    this.setState({
      codeTypeList: journalList,
      jouranlUniqueID: "",
      okToGO: false,
    });
  };

  handleDeletefForProcessedClicked = (event, index, row) => {
    this.setState({ deletePopUpOpen: true, selRecordForDelete: { ...row, selIndex: index } })
  }
  onDeleteProcessedRecord = () => {
    let bpaHeaderID = ""
    bpaHeaderID = window.location.pathname.split("/")[2];
    let modifiedBy = localStorage.loggedInId

    // deleteJournalInsideBPA(bpaHeaderID, modifiedBy).then(r => {
    this.setState({ update: true });
    const journalList = [...this.state.codeTypeList];
    journalList.splice(this.state.selRecordForDelete.selIndex, 1);
    this.setState({
      codeTypeList: journalList,
      jouranlUniqueID: "",
      // okToGO: false,
    });

    // else {
    //   toastr.error("Something went wrong")
    // }
    // })

    this.onCloseDeletePopUp()
  }
  onCloseDeletePopUp = () => {
    this.setState({ deletePopUpOpen: false, selRecordForDelete: {} })

  }
  handleEditClicked = (e, i, row) => {
    //this.setState({ update: true });
    let result = row.isDB || false;
    this.setState({
      selectedEditIndex: i,
      isEditClicked: true,
      okToGO: result,
      newPartNumber: this.state.codeTypeList[i].partNumber,
      newQuantity: this.state.codeTypeList[i].orderQuantity,
      newTrackingNumber: this.state.codeTypeList[i].trackingNumber,
      newActualWeight: this.state.codeTypeList[i].actualWeight,
      newDeliveryDate: this.state.codeTypeList[i].delievredDate,
      dhlRate: this.state.codeTypeList[i].dhlRate,
      noOfCartons: this.state.codeTypeList[i].noOfCartons,
    });
    let newList = [...this.state.codeTypeList]
    // console.log(this.state.codeTypeList)
    getJournalQty(row.journalID).then(x => {
      // console.log(x)
      let newBlQty = 0
      if (x && x.data) {
        // newBlQty = x.data.balanceQty
        newList[i].balQty = x.data.balanceQty
        newList[i].availableQty = x.data.availableQty
        newList[i].orderedQty = x.data.orderedQty
      }
      this.setState({ update: true, codeTypeList: newList });
    })
  };

  handleDraft = () => {
    let doNotDeliveryBefore = null;
    let mustBeDeliveryBy = null;
    let mustBeDeliveryOn = null;
    if (this.state.dataInformation.doNotDeliveryBefore !== null) {
      doNotDeliveryBefore = moment(
        this.state.dataInformation.doNotDeliveryBefore
      ).format("MM/DD/YYYY HH:mm:ss");
    }
    if (this.state.dataInformation.mustBeDeliveryBy !== null) {
      mustBeDeliveryBy = moment(
        this.state.dataInformation.mustBeDeliveryBy
      ).format("MM/DD/YYYY HH:mm:ss");
    }
    if (this.state.dataInformation.mustBeDeliveryOn !== null) {
      mustBeDeliveryOn = moment(
        this.state.dataInformation.mustBeDeliveryOn
      ).format("MM/DD/YYYY HH:mm:ss");
    }
    let deletionIds = "";
    if (this.state.deletionId.length > 0)
      deletionIds = [...this.state.deletionId].join(",");
    const formData = new FormData();

    if (this.state.stateFiles.length > 0) {
      this.state.stateFiles.map((f) => {
        if (!f.bpaAttatchDetailID) {
          formData.append("UploadFiles", f);
        } else {
          formData.append("UploadFiles", null);
        }
      });
    } else {
      formData.append("UploadFiles", null);
    }
    let modeAction = false;
    let modifiedBy = localStorage.loggedInId;
    let bpaHeaderID = window.location.pathname.split("/")[2];
    let newList = this.state.codeTypeList.map((p) => {
      return { ...p, isCPTGenrated: true, modifiedBy };
    });

    let reqBody = {
      department: this.state.dataInformation.department,
      companyName: this.state.dataInformation.companyName,
      conferenceName: this.state.dataInformation.conferenceName,
      costCoding: this.state.dataInformation.costCoding,
      address: this.state.dataInformation.address,
      postalCode: this.state.dataInformation.postalCode,
      contactPersonName: this.state.dataInformation.contactPersonName,
      countryID: this.state.countryID,
      phoneNumber: this.state.dataInformation.phoneNumber,
      conferenceRef: this.state.dataInformation.conferenceRef,
      emailID: this.state.dataInformation.emailID,
      address2: this.state.dataInformation.address2 || "",
      deliveryMethodID: this.state.deliveryListDataID,
      Mode: modeAction,
      deliveryInstructions: this.state.dataInformation.deliveryInstructions,
      remarks: this.state.dataInformation.remarks,
      packingInstructions: this.state.dataInformation.packingInstructions,
      doNotDeliveryBefore,
      mustBeDeliveryBy,
      mustBeDeliveryOn,
      modifiedBy,
      updateBPAOrderDetailRequest: newList,
    };
    formData.append("CreatedBy", modifiedBy);
    formData.append("DeletionIds", deletionIds);
    formData.append("BPAOrderHeaderID", bpaHeaderID);

    updateBPAOrder(bpaHeaderID, reqBody, this.props.history).then((r) => {
      fileUploadBPA(
        formData,

        this.props.history
      );
    });
  };
  handleSubmit = () => {

    let doNotDeliveryBefore = null;
    let mustBeDeliveryBy = null;
    let mustBeDeliveryOn = null;
    if (this.state.dataInformation.doNotDeliveryBefore !== null) {
      doNotDeliveryBefore = moment(
        this.state.dataInformation.doNotDeliveryBefore
      ).format("MM/DD/YYYY HH:mm:ss");
    }
    if (this.state.dataInformation.mustBeDeliveryBy !== null) {
      mustBeDeliveryBy = moment(
        this.state.dataInformation.mustBeDeliveryBy
      ).format("MM/DD/YYYY HH:mm:ss");
    }
    if (this.state.dataInformation.mustBeDeliveryOn !== null) {
      mustBeDeliveryOn = moment(
        this.state.dataInformation.mustBeDeliveryOn
      ).format("MM/DD/YYYY HH:mm:ss");
    }
    let deletionIds = "";
    if (this.state.deletionId.length > 0)
      deletionIds = [...this.state.deletionId].join(",");
    const formData = new FormData();

    if (this.state.stateFiles.length > 0) {
      this.state.stateFiles.map((f) => {
        if (!f.bpaAttatchDetailID) {
          formData.append("UploadFiles", f);
        } else {
          formData.append("UploadFiles", null);
        }
      });
    } else {
      formData.append("UploadFiles", null);
    }
    let modeAction = true;
    let modifiedBy = localStorage.loggedInId;
    let bpaHeaderID = window.location.pathname.split("/")[2];
    let newList = this.state.codeTypeList.map((p) => {
      return { ...p, isCPTGenrated: true, modifiedBy };
    });
    let submitList = this.state.codeTypeList.map((p) => {
      // console.log(p)
      let obj = {
        bpaOrderDetailID: p.bpaOrderDetailID,
        trackingNumber: p.trackingNumber,
        delievredDate: p.delievredDate,
        actualWeight: p.actualWeight,
        dhlRate: p.dhlRate,
        noOfCartons: p.noOfCartons,
        orderQuantity: p.orderQuantity,
      };
      return obj;
    });

    // if (this.state.currentMode === true && this.state.dataInformation.status.pubStatusName !== "Submitted") {
    if (this.state.dataInformation.status.pubStatusName !== "SavedAsDraft" && this.state.dataInformation.status.pubStatusName !== "Submitted") {
      let reqBody = {
        department: this.state.dataInformation.department,
        companyName: this.state.dataInformation.companyName,
        conferenceName: this.state.dataInformation.conferenceName,
        costCoding: this.state.dataInformation.costCoding,
        address: this.state.dataInformation.address,
        contactPersonName: this.state.dataInformation.contactPersonName,
        countryID: this.state.countryID,
        phoneNumber: this.state.dataInformation.phoneNumber,
        conferenceRef: this.state.dataInformation.conferenceRef,
        emailID: this.state.dataInformation.emailID,
        address2: this.state.dataInformation.address2 || "",
        deliveryMethodID: this.state.deliveryListDataID,
        Mode: modeAction,
        deliveryInstructions: this.state.dataInformation.deliveryInstructions,
        remarks: this.state.dataInformation.remarks,
        postalCode: this.state.dataInformation.postalCode,
        packingInstructions: this.state.dataInformation.packingInstructions,
        doNotDeliveryBefore,
        mustBeDeliveryBy,
        mustBeDeliveryOn,
        modifiedBy,
        updateSubmittedBPADetails: submitList,
      };

      submitBPAOrder(bpaHeaderID, reqBody, this.props.history);
    } else {
      let reqBody = {
        department: this.state.dataInformation.department,
        companyName: this.state.dataInformation.companyName,
        conferenceName: this.state.dataInformation.conferenceName,
        costCoding: this.state.dataInformation.costCoding,
        address: this.state.dataInformation.address,
        contactPersonName: this.state.dataInformation.contactPersonName,
        countryID: this.state.countryID,
        phoneNumber: this.state.dataInformation.phoneNumber,
        conferenceRef: this.state.dataInformation.conferenceRef,
        emailID: this.state.dataInformation.emailID,
        address2: this.state.dataInformation.address2 || "",
        deliveryMethodID: this.state.deliveryListDataID,
        Mode: modeAction,
        deliveryInstructions: this.state.dataInformation.deliveryInstructions,
        remarks: this.state.dataInformation.remarks,
        packingInstructions: this.state.dataInformation.packingInstructions,
        doNotDeliveryBefore,
        postalCode: this.state.dataInformation.postalCode,
        mustBeDeliveryBy,
        mustBeDeliveryOn,
        modifiedBy,
        updateBPAOrderDetailRequest: newList,
      };
      formData.append("CreatedBy", modifiedBy);
      formData.append("DeletionIds", deletionIds);
      formData.append("BPAOrderHeaderID", bpaHeaderID);
      updateBPAOrder(bpaHeaderID, reqBody, this.props.history).then((r) => {
        fileUploadBPA(
          formData,
          this.props.history
        );
      });
    }
  };
  _onMobileNumberKeyPress(event) {
    const re = /[0-9+()-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertKeyPress(event) {
    const re = /[a-zA-Z0-9-#]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextKeyPress(event) {
    const re = /[a-z A-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusNumberKeyPress(event) {
    const re = /[0-9 a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onWeightKeyPress(event) {
    const re = /[0-9 a-zA-Z.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertSizeKeyPress(event) {
    const re = /[0-9 a-zA-Z-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusAllPress(event) {
    const re = /[a-z A-Z0-9=.;()&@]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  checkToGo = () => {
    if (
      this.state.codeTypeList.length === 0 ||
      //this.state.stateFiles.length === 0 ||
      //this.state.dataInformation.doNotDeliveryBefore === null ||
      //this.state.dataInformation.mustBeDeliveryBy === null ||
      // this.state.dataInformation.mustBeDeliveryOn === null ||
      //this.state.dataInformation.packingInstructions === "" ||
      //this.state.dataInformation.remarks === "" ||
      //this.state.dataInformation.deliveryInstructions === "" ||
      this.state.dataInformation.deliveryMethodID === null ||
      // this.state.dataInformation.address2 === null ||
      //this.state.dataInformation.emailID === "" ||
      this.state.dataInformation.conferenceRef === "" ||
      this.state.dataInformation.phoneNumber === "" ||
      this.state.dataInformation.contactPersonName === "" ||
      this.state.dataInformation.address === "" ||
      this.state.dataInformation.costCoding === "" ||
      this.state.dataInformation.conferenceName === "" ||
      this.state.dataInformation.postalCode === "" ||
      //this.state.dataInformation.department === "" ||
      this.state.dataInformation.companyName === "" ||
      this.state.countryID === null ||
      // this.state.deliveryListDataID === null ||
      //this.state.emailID === "" ||
      this.state.conferenceRef === "" ||
      this.state.conferenceRoom === "" ||
      this.state.costCoding === "" ||
      this.state.selectedEditIndex !== null
    ) {
      return true;
    } else {
      return false;
    }
  };
  neverEmpty = () => {
    let flag = true;
    if (this.state.codeTypeList.length > 0) {
      let k = this.state.codeTypeList.map((p) => {
        if (p.orderQuantity === 0) {
          flag = true;
        } else {
          flag = false;
        }
      });
    }
    if (flag === true) {
      return true;
    } else {
      return false;
    }
  };
  render() {

    const { proMode } = this.state;
    const {
      bpaOrderHeaderID,
      bpaRecipientID,
      bpaSuffix,
      bpaRunningID,
      bpaCode,
      companyName,
      department,
      postalCode,
      conferenceName,
      costCoding,
      address,
      contactPersonName,
      countryID,
      phoneNumber,
      conferenceRef,
      emailID,
      address2,
      deliveryMethodID,
      deliveryInstructions,
      remarks,
      packingInstructions,
      doNotDeliveryBefore,
      mustBeDeliveryBy,
      mustBeDeliveryOn,

      createdDate,

      modifiedDate,
      statusID,
      trackingNumber,

      deliveredDate,
      actualWeight,
      bpaRecipient,
      country,
      createdByNavigation,
      deliveryMethod,
      modifiedByNavigation,
      tbL_PGSBPAAttatchDetail,
      tbL_PGSBPAOrderDetail,
    } = this.state.dataInformation;
    let { classes } = this.props;

    return (
      <React.Fragment>
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.deletePopUpOpen}
        >
          <div style={{ padding: "4em" }}>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>
              {" "}
              Are you sure you want to Delete the Journal ?
            </div>

            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={e => this.onCloseDeletePopUp(e)}
                style={{ marginRight: "1em", boxShadow: "none" }}
              >
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={e => this.onDeleteProcessedRecord(e)}
              >
                Delete
              </Button>
            </div>
          </div>
        </Dialog>
        <Paper>
          <div className="pop-up-header">Update BPA Order Placement</div>
          <Divider />
          <div className={classes.container}>
            <div>
              <div className={classes.container}>
                <div>
                  <p className={classes.subHeader} style={{ marginLeft: 0 }}>
                    Recipient Information
                  </p>
                  <div style={{ display: "flex" }}>
                    {/* <Autocomplete
                      disabled
                      options={this.state.recipientList}
                      value={this.state.deliveryAddress}
                      onChange={this.handleAutocompleteDeliveryAddress}
                      getOptionLabel={(option) => option.text}
                      style={{ flex: 1, marginRight: "16px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Delivery Address"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    /> */}
                    <TextField
                      label="Delivery Address"
                      name="deliveryAddress"
                      inputProps={{
                        maxLength: 100,
                        readOnly: true,
                      }}
                      variant={"filled"}
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={this.state.deliveryAddress}
                    //onChange={this.handleChange}
                    />
                    <TextField
                      required
                      label="Company Name"
                      name="companyName"
                      inputProps={{
                        maxLength: 100,
                        readOnly: proMode,
                      }}
                      variant={proMode ? "filled" : "standard"}
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={companyName}
                      onChange={this.handleChange}
                    />
                    <TextField
                      label="Department"
                      name="department"
                      inputProps={{
                        maxLength: 100,
                        readOnly: proMode,
                      }}
                      variant={proMode ? "filled" : "standard"}
                      onChange={this.handleChange}
                      margin="normal"
                      style={{ flex: 1 }}
                      value={department}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      required
                      label="Postal Code"
                      name="postalCode"
                      inputProps={{
                        maxLength: 50,
                        readOnly: proMode,
                      }}
                      variant={proMode ? "filled" : "standard"}
                      onChange={this.handleChange}
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={postalCode}
                      onKeyPress={this._onNumberKeyPress}
                    />
                    <TextField
                      required
                      label="Conference Name"
                      name="conferenceName"
                      inputProps={{
                        maxLength: 50,
                        readOnly: proMode,
                      }}
                      variant={proMode ? "filled" : "standard"}
                      onChange={this.handleChange}
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={conferenceName}
                    />
                    <TextField
                      required
                      label="Cost Coding"
                      name="costCoding"
                      inputProps={{
                        maxLength: 50,
                        readOnly: proMode,
                      }}
                      variant={proMode ? "filled" : "standard"}
                      onChange={this.handleChange}
                      style={{ flex: 1 }}
                      margin="normal"
                      value={costCoding}
                    />
                  </div>
                  <div style={{ display: "flex", width: "66.4%" }}>
                    <TextField
                      required
                      label="Address"
                      name="address"
                      inputProps={{
                        maxLength: 200,
                        readOnly: proMode,
                      }}
                      variant={proMode ? "filled" : "standard"}
                      onChange={this.handleChange}
                      //onKeyPress={this._onNumberKeyPress}
                      margin="normal"
                      style={{ flex: 1 }}
                      value={address}
                      rowsMax={1}
                      multiline={proMode ? false : true}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "66.4%",
                    }}
                  >
                    <TextField
                      label="Address 2"
                      name="address2"
                      inputProps={{
                        maxLength: 200,
                        readOnly: proMode,
                      }}
                      variant={proMode ? "filled" : "standard"}
                      onChange={this.handleChange}
                      margin="normal"
                      style={{ flex: 1 }}
                      value={address2}
                      rowsMax={4}
                      multiline="true"
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    {proMode ? (
                      <Tooltip title={this.state.deliveryCountry.text}>
                        <TextField
                          required
                          label="Country"
                          name="deliveryAddress"
                          inputProps={{
                            maxLength: 100,
                            readOnly: true,
                          }}
                          variant={"filled"}
                          margin="normal"
                          style={{ flex: 1, marginRight: "16px" }}
                          value={this.state.deliveryCountry.text}
                        //onChange={this.handleChange}
                        />
                      </Tooltip>
                    ) : (
                        <Autocomplete
                          options={this.state.deliveryCountryDD}
                          value={this.state.deliveryCountry}
                          onChange={this.handleCountryChange}
                          getOptionLabel={(option) => option.text}
                          style={{ flex: 1, marginRight: "16px" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Country"
                              margin="normal"
                              required
                              fullWidth
                            />
                          )}
                        />
                      )}
                    <TextField
                      required
                      label="Contact Person Name"
                      name="contactPersonName"
                      inputProps={{
                        maxLength: 50,
                        readOnly: proMode,
                      }}
                      variant={proMode ? "filled" : "standard"}
                      style={{ flex: 1, marginRight: "16px" }}
                      onChange={this.handleChange}
                      // onKeyPress={this._onNumberKeyPress}
                      margin="normal"
                      value={contactPersonName}
                    />
                    <TextField
                      required
                      label="Phone Number"
                      name="phoneNumber"
                      style={{ flex: 1 }}
                      inputProps={{
                        maxLength: 12,
                        readOnly: proMode,
                      }}
                      variant={proMode ? "filled" : "standard"}
                      onChange={this.handleChange}
                      onKeyPress={this._onMobileNumberKeyPress}
                      margin="normal"
                      value={phoneNumber}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      required
                      label="Conference Ref"
                      name="conferenceRef"
                      inputProps={{
                        maxLength: 50,
                        readOnly: proMode,
                      }}
                      variant={proMode ? "filled" : "standard"}
                      onChange={this.handleChange}
                      //onKeyPress={this._onNumberKeyPress}
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={conferenceRef}
                    />
                    <TextField
                      label="Email ID"
                      name="emailID"
                      inputProps={{
                        maxLength: 50,
                        readOnly: proMode,
                      }}
                      onChange={(e) =>
                        this.handleChangeEmail(e, emailValidator)
                      }
                      variant={proMode ? "filled" : "standard"}
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={emailID}
                      error={this.state.errors.emailID}
                      helperText={
                        this.state.errors.emailID
                          ? resourceValidation.validEmailId
                          : ""
                      }
                    />
                    <div style={{ flex: 1 }}></div>
                  </div>
                  <p className={classes.subHeader} style={{ marginLeft: 0 }}>
                    Delivery Information
                  </p>
                  <div style={{ display: "flex" }}>
                    {proMode ? (
                      <TextField
                        required
                        label="Delivery Method"
                        name="deliveryAddress"
                        inputProps={{
                          maxLength: 100,
                          readOnly: true,
                        }}
                        variant={"filled"}
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        value={this.state.deliveryListData.text}
                      //onChange={this.handleChange}
                      />
                    ) : (
                        <Autocomplete
                          options={this.state.deliveryListDD}
                          value={this.state.deliveryListData}
                          onChange={this.handleDeliveryListData}
                          getOptionLabel={(option) => option.text}
                          style={{ flex: 1, marginRight: "16px" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Delivery Method"
                              margin="normal"
                              required
                              fullWidth
                            />
                          )}
                        />
                      )}

                    <TextField
                      label="Delivery Instruction"
                      name="deliveryInstructions"
                      inputProps={{
                        maxLength: 50,
                        readOnly: proMode,
                      }}
                      variant={proMode ? "filled" : "standard"}
                      style={{ flex: 1, marginRight: "16px" }}
                      onChange={this.handleChange}
                      //onKeyPress={this._onNumberKeyPress}
                      margin="normal"
                      value={deliveryInstructions}
                    />
                    <TextField
                      label="Remarks"
                      name="remarks"
                      style={{ flex: 1 }}
                      inputProps={{
                        maxLength: 100,
                        readOnly: proMode,
                      }}
                      variant={proMode ? "filled" : "standard"}
                      onChange={this.handleChange}
                      //onKeyPress={this._onNumberKeyPress}
                      margin="normal"
                      value={remarks}
                    />
                  </div>
                  <div style={{ display: "flex", width: "66.4%" }}>
                    <TextField
                      style={{ flex: 1 }}
                      label="Packing Instruction"
                      name="packingInstructions"
                      inputProps={{
                        maxLength: 100,
                        readOnly: proMode,
                      }}
                      variant={proMode ? "filled" : "standard"}
                      onChange={this.handleChange}
                      //onKeyPress={this._onNumberKeyPress}
                      margin="normal"
                      value={packingInstructions}
                    />
                  </div>
                  {/* <EnhancedTableToolbar />

                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                    style={{ marginLeft: "8px" }}
                  >
                    <EnhancedTableHead />
                    <TableBody>
                      <TableRow hover tabIndex={-1}></TableRow>
                    </TableBody>
                  </Table> */}
                  <div style={{ margin: "8px 0 5px" }}>
                    <h5
                      style={{
                        padding: "10px",
                        border: "1px solid #bbb",
                        borderBottom: 0,
                        margin: "0",
                      }}
                    >
                      Delivery Lead - times (Subjected to Custom Clearance)
                    </h5>
                    <div
                      style={{
                        border: "1px solid #bbb",
                        borderBottom: 0,
                        overflow: "auto",
                      }}
                    >
                      {this.state.deliveryList.map((p, index) => {
                        return (
                          <p
                            key={index}
                            style={{
                              float: "left",
                              width: "200px",
                              padding: "10px",
                              textAlign: "center",
                              borderRight: "1px solid #bbb",
                              margin: "0",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            {p.region}
                          </p>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        border: "1px solid #bbb",
                        borderTop: 0,
                        overflow: "auto",
                      }}
                    >
                      {this.state.deliveryList.map((p, i) => {
                        return (
                          <p
                            key={i}
                            style={{
                              float: "left",
                              width: "200px",
                              padding: "10px",
                              textAlign: "center",
                              borderRight: "1px solid #bbb",
                              borderTop: "1px solid #bbb",
                              margin: "0",
                              fontSize: "14px",
                            }}
                          >
                            {p.daySpan}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div>
                    {this.state.currentMode && this.state.dataInformation.status.pubStatusName !== "SavedAsDraft"
                      //  && this.state.dataInformation.status.pubStatusName !== "Submitted"
                      ? null : (
                        <Basic
                          onDrop={this.onDrop}
                          stateFiles={this.state.stateFiles}
                        />
                      )}
                  </div>

                  <div>
                    {
                      // this.state.currentMode
                      this.state.dataInformation.status.pubStatusName !== "SavedAsDraft"
                        //  && this.state.dataInformation.status.pubStatusName !== "Submitted"
                        ? this.state.stateFiles.map((file, i) => {
                          return (
                            <div key={i}>
                              {i === 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    margin: "1em 0",
                                    // marginTop: "2em",
                                    paddingBottom: ".5em",
                                    borderBottom: "1px solid #eaeaea",
                                    fontSize: "14px",
                                  }}
                                >
                                  <div
                                    style={{ width: "30%", fontWeight: "500" }}
                                  >
                                    File Info
                                  </div>
                                  <div
                                    style={{ width: "10%", fontWeight: "500" }}
                                  >
                                    Size
                                  </div>
                                  {/* <div style={{ width: "45%", fontWeight: "500" }}>
                                Detection
                              </div> */}
                                  {/* <div
                                    style={{
                                      width: "15%",
                                      fontWeight: "500",
                                      textAlign: "right",
                                    }}
                                  >
                                    Action
                                  </div> */}
                                </div>
                              )}
                              {file.bpaOrderHeaderID ? (
                                <div
                                  style={{
                                    display: "flex",
                                    margin: "1em 0",
                                    paddingBottom: ".5em",
                                    borderBottom: "1px solid #eaeaea",
                                    fontSize: "12px",
                                  }}
                                >
                                  <div
                                    style={{ width: "30%", fontWeight: "500" }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <DescriptionIcon
                                        style={{
                                          color: "#306dca",
                                          marginTop: "-4px",
                                          marginRight: "5px",
                                        }}
                                      />{" "}
                                      <span> {file.name}</span>
                                    </div>
                                  </div>
                                  <div style={{ width: "10%" }}>
                                    {/* {Math.ceil(Number(file.size) / 1024) +
                                      " kb"} */}
                                    {file.size} {file.sizeVariant}
                                  </div>

                                  {/* <div
                                    style={{ width: "15%", textAlign: "right" }}
                                  > */}
                                  {/* {!file.isValid && !file.filePath && (
                                      <IconButton
                                        size="small"
                                        style={{ marginTop: "-5px" }}
                                        onClick={(e) =>
                                          this.getExcelFileData(
                                            file.orderFileHeaderID,
                                            file.name
                                          )
                                        }
                                      >
                                        <GetAppIcon
                                          style={{ fontSize: "16px" }}
                                        />
                                      </IconButton>
                                    )} */}
                                  {/* <IconButton
                                  size="small"
                                  style={{ marginTop: "-7px" }}
                                  onClick={(e) => {
                                    this.deleteSelectedFile(
                                      i,
                                      this.state.stateFiles[i].orderFileHeaderID
                                    );
                                  }}
                                >
                                  <DeleteIcon style={{ fontSize: "16px" }} />
                                </IconButton>{" "} */}
                                  {/* </div> */}
                                </div>
                              ) : (
                                  ""
                                )}
                            </div>
                          );
                        })
                        : this.state.stateFiles.map((file, i) => {
                          return (
                            <div key={i}>
                              {i === 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    margin: "1em 0",
                                    // marginTop: "2em",
                                    paddingBottom: ".5em",
                                    borderBottom: "1px solid #eaeaea",
                                    fontSize: "14px",
                                  }}
                                >
                                  <div
                                    style={{ width: "30%", fontWeight: "500" }}
                                  >
                                    File Info
                                  </div>
                                  <div
                                    style={{ width: "10%", fontWeight: "500" }}
                                  >
                                    Size
                                  </div>
                                  <div
                                    style={{ width: "45%", fontWeight: "500" }}
                                  >
                                    Detection
                                  </div>
                                  <div
                                    style={{
                                      width: "15%",
                                      fontWeight: "500",
                                      textAlign: "right",
                                    }}
                                  >
                                    Action
                                  </div>
                                </div>
                              )}
                              {true ? (
                                <div
                                  style={{
                                    display: "flex",
                                    margin: "1em 0",
                                    paddingBottom: ".5em",
                                    borderBottom: "1px solid #eaeaea",
                                    fontSize: "12px",
                                  }}
                                >
                                  <div
                                    style={{ width: "30%", fontWeight: "500" }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <DescriptionIcon
                                        style={{
                                          color: "#306dca",
                                          marginTop: "-4px",
                                          marginRight: "5px",
                                        }}
                                      />{" "}
                                      <span> {file.name}</span>
                                    </div>
                                  </div>
                                  <div style={{ width: "10%" }}>
                                    {typeof file.size === "string"
                                      ? file.size + " KB"
                                      : Math.ceil(file.size / 1024) + " KB"}
                                  </div>

                                  {!file.bpaOrderHeaderID ? (
                                    <DetectionControl
                                      fileName={this.state.fileList[i]}
                                    />
                                  ) : (
                                      <div
                                        style={{
                                          width: "45%",
                                          marginTop: ".6em",
                                        }}
                                      >
                                        {/* <LinearProgress /> */}
                                      </div>
                                    )}

                                  <div
                                    style={{ width: "15%", textAlign: "right" }}
                                  >
                                    {/* {!file.isValid && !file.filePath && (
                                  <IconButton
                                    size="small"
                                    style={{ marginTop: "-5px" }}
                                    onClick={(e) =>
                                      this.getExcelFileData(
                                        file.orderFileHeaderID,
                                        file.name
                                      )
                                    }
                                  >
                                    <GetAppIcon style={{ fontSize: "16px" }} />
                                  </IconButton>
                                )} */}
                                    <IconButton
                                      size="small"
                                      style={{ marginTop: "-7px" }}
                                      onClick={(e) => {
                                        this.deleteSelectedFile(
                                          i,
                                          this.state.stateFiles[i]
                                            .orderFileHeaderID,
                                          this.state.stateFiles[i]
                                            .bpaAttatchDetailID
                                        );
                                      }}
                                    >
                                      <DeleteIcon
                                        style={{ fontSize: "16px" }}
                                      />
                                    </IconButton>{" "}
                                  </div>
                                </div>
                              ) : (
                                  ""
                                )}
                            </div>
                          );
                        })}
                  </div>
                  <p className={classes.subHeader} style={{ marginLeft: 0 }}>
                    Timed Delivery
                  </p>
                  <div style={{ display: "flex" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {proMode ? (
                        <TextField
                          label="Do Not Deliver Before"
                          inputProps={{
                            maxLength: 50,
                            readOnly: proMode,
                          }}
                          variant={proMode ? "filled" : "standard"}
                          //onKeyPress={this._onNumberKeyPress}
                          margin="normal"
                          style={{ flex: 1, marginRight: "16px" }}
                          value={doNotDeliveryBefore ? moment(doNotDeliveryBefore).format("DD/MM/YYYY") : ""}
                        />
                      ) : (
                          // <KeyboardTimePicker
                          //   variant={proMode ? "filled" : "standard"}
                          //   margin="normal"
                          //   id="time-picker"
                          //   label="Do Not Deliver Before"
                          //   style={{ flex: 1, marginRight: "16px" }}
                          //   value={doNotDeliveryBefore}
                          //   onChange={this.handleTimeChange}
                          //   KeyboardButtonProps={{
                          //     "aria-label": "change time",
                          //   }}
                          // />
                          <KeyboardDatePicker
                            autoOk
                            disableToolbar
                            // disablePast
                            margin="normal"
                            disableToolbar
                            variant="inline"
                            id="date-picker-dialog"
                            label="Do Not Deliver Before"
                            minDateMessage="Date cannot be past date"
                            format="dd/MM/yyyy"
                            style={{ flex: 1, marginRight: "16px" }}
                            value={doNotDeliveryBefore}
                            onChange={this.handleDateChange2}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        )}
                      {proMode ? (
                        <TextField
                          label=" Must Deliver By"
                          inputProps={{
                            maxLength: 50,
                            readOnly: proMode,
                          }}
                          variant={proMode ? "filled" : "standard"}
                          //onKeyPress={this._onNumberKeyPress}
                          margin="normal"
                          style={{ flex: 1, marginRight: "16px" }}
                          value={mustBeDeliveryBy ? moment(mustBeDeliveryBy).format("DD/MM/YYYY") : ""}
                        />
                      ) : (
                          // <KeyboardTimePicker
                          //   disabled={proMode}
                          //   margin="normal"
                          //   variant={proMode ? "filled" : "standard"}
                          //   id="time-picker"
                          //   label="Must Deliver By"
                          //   style={{ flex: 1, marginRight: "16px" }}
                          //   value={mustBeDeliveryBy}
                          //   onChange={this.handleTimeChange1}
                          //   KeyboardButtonProps={{
                          //     "aria-label": "change time",
                          //   }}
                          // />
                          <KeyboardDatePicker
                            autoOk
                            disableToolbar
                            // disablePast
                            margin="normal"
                            disableToolbar
                            variant="inline"
                            id="date-picker-dialog"
                            label="Must Deliver By"
                            minDateMessage="Date cannot be past date"
                            format="dd/MM/yyyy"
                            style={{ flex: 1, marginRight: "16px" }}
                            value={mustBeDeliveryBy}
                            onChange={this.handleDateChange3}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        )}
                      {proMode ? (
                        <TextField
                          label="Must Be Deliver On"
                          inputProps={{
                            maxLength: 50,
                            readOnly: proMode,
                          }}
                          variant={proMode ? "filled" : "standard"}
                          //onKeyPress={this._onNumberKeyPress}
                          margin="normal"
                          style={{ flex: 1, marginRight: "16px" }}
                          value={mustBeDeliveryOn ? moment(mustBeDeliveryOn).format("DD/MM/YYYY") : ""}
                        />
                      ) : (
                          <KeyboardDatePicker
                            autoOk
                            // disablePast
                            disableToolbar
                            minDateMessage={
                              proMode ? "" : "Date cannot be past date"
                            }
                            variant={proMode ? "filled" : "standard"}
                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="Must Be Deliver On"
                            format="dd/MM/yyyy"
                            style={{ flex: 1 }}
                            value={mustBeDeliveryOn}
                            // onKeyPress={(event) => {
                            //   this.handleKeypress(event);
                            // }}
                            onChange={this.handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        )}
                    </MuiPickersUtilsProvider>
                  </div>
                  <p className={classes.subHeader} style={{ marginLeft: 0 }}>
                    Item Information
                  </p>
                  <div>
                    {/* {this.state.currentMode ? null : ( */}
                    {this.state.dataInformation.status.pubStatusName !== "SavedAsDraft"
                      && this.state.dataInformation.status.pubStatusName !== "Submitted"
                      ? null : (
                        <div
                          style={{
                            display: "flex",
                            border: "1px solid #eaeaea",
                            borderBottom: "none",
                            borderRadius: "4px",
                            //boxShadow: "0px 7px 6px #ddd",
                          }}
                        >
                          <Typography
                            component="span"
                            style={{
                              width: "100%",
                              padding: "10px",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                boxShadow: "none",
                                padding: "5px 10px 5px 4px",
                                fontSize: "11px",
                              }}
                              onClick={this.handleAddJournalClick}
                            >
                              <CustomSmallAddIcon style={{ fontSize: "18px" }} />{" "}
                            Add Journal
                          </Button>
                          </Typography>
                        </div>
                      )}

                    {this.state.openDialog ? (
                      <IndividualSearchAddDialog
                        title="Journal Pick Up"
                        mode="BPAOrderPlacement"
                        showDialog={this.state.openDialog}
                        handleAddJournalClose={this.handleAddJournalClose}
                        handleJournalUpdate={this.handleJournalUpdate}
                      />
                    ) : null}
                    <div
                      style={{
                        width: "100%",
                        maxHeight: "calc(100vh - 250px)",
                      }}
                    >
                      <div
                        style={
                          !this.props.clearOpenList
                            ? {
                              overflow: "auto",
                              maxWidth: "calc(100vw - 11em)",
                              maxHeight: "calc(100vh - 300px)",
                              margin: "0 auto",
                            }
                            : {
                              overflow: "auto",
                              maxWidth: "calc(100vw - 400px)",
                              maxHeight: "calc(100vh - 300px)",
                              margin: "0 auto",
                            }
                        }
                      >
                        <Table
                          stickyHeader
                          aria-labelledby="tableTitle"
                          size={"small"}
                          aria-label="enhanced table"
                          style={{
                            borderLeft: "1px solid  rgba(224, 224, 224, 1)",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <EnhancedTableHead1
                            order={this.state.order}
                            orderBy={this.state.orderBy}
                            onRequestSort={this.handleRequestSort}
                            headCells={headCells1}
                          />
                          <TableBody style={{ overflow: "hidden" }}>
                            {this.state.codeTypeList.length > 0 ? (
                              // stableSort(
                              //   this.state.codeTypeList,
                              //   getSorting(this.state.order, this.state.orderBy)
                              // )
                              //   .slice(
                              //     this.state.page * this.state.rowsPerPage,
                              //     this.state.page * this.state.rowsPerPage +
                              //     this.state.rowsPerPage
                              //   )
                              this.state.codeTypeList.map((row, index) => {
                                return (
                                  <TableRow
                                    hover
                                    key={index}
                                    tabIndex={-1}
                                    style={
                                      this.state.selectedEditIndex !== index
                                        ? { cursor: "pointer" }
                                        : {
                                          cursor: "pointer",
                                          background: "#ccc",
                                        }
                                    }
                                  >
                                    {/* {headCells1[0].show && (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-cell"
                                          align="left"
                                        >
                                          {row.orderID &&
                                          row.orderID.length > 16 ? (
                                            <Tooltip
                                              title={row.orderID}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.orderID}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.orderID}
                                            </Typography>
                                          )}
                                        </TableCell>
                                      )} */}
                                    {headCells1[0].show && (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-cell"
                                        align="left"
                                        style={{ paddingRight: ".5em" }}
                                      >
                                        {row.issueDecription &&
                                          row.issueDecription.length > 16 ? (
                                            <Tooltip
                                              title={row.issueDecription}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.issueDecription}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.issueDecription}
                                            </Typography>
                                          )}
                                      </TableCell>
                                    )}
                                    {headCells1[1].show && (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-cell"
                                        align="left"
                                        style={{ paddingRight: ".5em" }}
                                      >
                                        {row.journalTitle &&
                                          row.journalTitle.length > 16 ? (
                                            <Tooltip
                                              title={row.journalTitle}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.journalTitle}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.journalTitle}
                                            </Typography>
                                          )}
                                      </TableCell>
                                    )}
                                    {headCells1[2].show && (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-cell"
                                        align="left"
                                      >
                                        {row.acronym &&
                                          row.acronym.length > 16 ? (
                                            <Tooltip
                                              title={row.acronym}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.acronym}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.acronym}
                                            </Typography>
                                          )}
                                      </TableCell>
                                    )}
                                    {headCells1[3].show && (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-cell"
                                        align="left"
                                      >
                                        {row.volume &&
                                          row.volume.length > 16 ? (
                                            <Tooltip
                                              title={row.volume}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.volume}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.volume}
                                            </Typography>
                                          )}
                                      </TableCell>
                                    )}
                                    {headCells1[4].show && (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-cell"
                                        align="left"
                                      >
                                        {row.issue &&
                                          row.issue.length > 16 ? (
                                            <Tooltip
                                              title={row.issue}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.issue}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.issue}
                                            </Typography>
                                          )}
                                      </TableCell>
                                    )}
                                    {headCells1[5].show && (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-cell"
                                        align="left"
                                      >
                                        {row.suppNumber &&
                                          row.suppNumber.length > 16 ? (
                                            <Tooltip
                                              title={row.suppNumber}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.suppNumber}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.suppNumber}
                                            </Typography>
                                          )}
                                      </TableCell>
                                    )}
                                    {/* {this.state.selectedEditIndex !== index ||
                                      row.isDB ? (
                                        headCells1[6].show && (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-right-cell"
                                            align="right"
                                          >
                                            {row.partNumber
                                              ? row.partNumber
                                              : ""}
                                          </TableCell>
                                        )
                                      ) : (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-right-cell"
                                        >
                                          <TextField
                                            margin="dense"
                                            id="name"
                                            onKeyPress={this._onNumberKeyPress}
                                            autoComplete="off"
                                            name="newPartNumber"
                                            onChange={this.handleChange1}
                                            value={this.state.newPartNumber}
                                          />
                                        </TableCell>
                                      )} */}
                                    {
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-right-cell"
                                        align="right"
                                      >
                                        {row.availableQty}
                                      </TableCell>
                                    }
                                    {
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-right-cell"
                                        align="right"
                                      >
                                        {row.orderedQty}
                                      </TableCell>
                                    }
                                    {headCells1[6].show && (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-right-cell"
                                        align="left"
                                      >
                                        {row.balQty &&
                                          row.balQty.length > 16 ? (
                                            <Tooltip
                                              title={row.balQty}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.balQty}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.balQty}
                                            </Typography>
                                          )}
                                      </TableCell>
                                    )}
                                    {this.state.selectedEditIndex !== index ||
                                      (row.isDB && !this.state.sadMode) ? (
                                        headCells1[7].show && (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-right-cell"
                                            align="right"
                                          >
                                            {row.orderQuantity
                                              ? row.orderQuantity
                                              : ""}
                                          </TableCell>
                                        )
                                      ) : (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-right-cell"
                                        >
                                          <TextField
                                            margin="dense"
                                            id="name"
                                            onKeyPress={this._onNumberKeyPress}
                                            autoComplete="off"
                                            // disabled={!row.balQty}
                                            name="newQuantity"
                                            onChange={(e) =>
                                              this.handleChange2(e, row, index)
                                            }
                                            value={this.state.newQuantity}
                                          />
                                        </TableCell>
                                      )}
                                    {this.state.selectedEditIndex !==
                                      index ? (
                                        headCells1[8].show && (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-cell"
                                            align="left"
                                          >
                                            {row.trackingNumber &&
                                              row.trackingNumber.length > 16 ? (
                                                <Tooltip
                                                  title={row.trackingNumber}
                                                  aria-label="add"
                                                >
                                                  <Typography
                                                    noWrap
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    {row.trackingNumber}
                                                  </Typography>
                                                </Tooltip>
                                              ) : (
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.trackingNumber}
                                                </Typography>
                                              )}
                                          </TableCell>
                                        )
                                      ) : (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-cell"
                                        >
                                          <TextField
                                            margin="dense"
                                            id="name"
                                            autoComplete="off"
                                            //onKeyPress={this._onNumberKeyPress}
                                            name="newTrackingNumber"
                                            onChange={this.handleChange1}
                                            value={this.state.newTrackingNumber}
                                          />
                                        </TableCell>
                                      )}
                                    {this.state.selectedEditIndex !==
                                      index ? (
                                        headCells1[9].show && (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-cell"
                                            align="left"
                                          >
                                            {row.noOfCartons &&
                                              row.noOfCartons.length > 16 ? (
                                                <Tooltip
                                                  title={row.noOfCartons}
                                                  aria-label="add"
                                                >
                                                  <Typography
                                                    noWrap
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    {row.noOfCartons}
                                                  </Typography>
                                                </Tooltip>
                                              ) : (
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.noOfCartons}
                                                </Typography>
                                              )}
                                          </TableCell>
                                        )
                                      ) : (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-cell"
                                        >
                                          <TextField
                                            margin="dense"
                                            id="name"
                                            autoComplete="off"
                                            //onKeyPress={this._onNumberKeyPress}
                                            name="noOfCartons"
                                            onChange={this.handleChange1}
                                            value={this.state.noOfCartons}
                                          />
                                        </TableCell>
                                      )}
                                    {this.state.selectedEditIndex !==
                                      index ? (
                                        headCells1[10].show && (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-right-cell"
                                            align="right"
                                          >
                                            {row.actualWeight
                                              ? parseFloat(
                                                row.actualWeight
                                              ).toFixed(3)
                                              : "0.000"}
                                          </TableCell>
                                        )
                                      ) : (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-right-cell"
                                        >
                                          <TextField
                                            margin="dense"
                                            id="name"
                                            autoComplete="off"
                                            name="newActualWeight"
                                            onKeyPress={this._onNumberKeyPress}
                                            onChange={
                                              this.handleChangeActualWeight
                                            }
                                            value={this.state.newActualWeight}
                                          />
                                        </TableCell>
                                      )}
                                    {this.state.selectedEditIndex !==
                                      index ? (
                                        headCells1[11].show && (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-cell"
                                            align="left"
                                            style={{ paddingRight: ".5em" }}
                                          >
                                            {row.delievredDate &&
                                              row.delievredDate.length > 10 ? (
                                                <Tooltip
                                                  title={row.delievredDate ? moment(
                                                    row.delievredDate
                                                  ).format("DD/MM/YYYY") : ""}
                                                  aria-label="add"
                                                >
                                                  <Typography
                                                    noWrap
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    {row.delievredDate ? moment(
                                                      row.delievredDate
                                                    ).format("DD/MM/YYYY") : ""}
                                                  </Typography>
                                                </Tooltip>
                                              ) : (
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.delievredDate
                                                    ? moment(
                                                      row.delievredDate
                                                    ).format("DD/MM/YYYY")
                                                    : ""}
                                                </Typography>
                                              )}
                                          </TableCell>
                                        )
                                      ) : (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-cell"
                                          style={{ paddingRight: ".5em" }}
                                        >
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardDatePicker
                                              autoOk
                                              disableToolbar
                                              variant="inline"
                                              format="dd/MM/yyyy"
                                              minDateMessage={""}
                                              name="newDeliveryDate"
                                              id="newDeliveryDate"
                                              value={this.state.newDeliveryDate}
                                              onChange={(e) =>
                                                this.handleDateChange1(
                                                  e,
                                                  "newDeliveryDate"
                                                )
                                              }
                                              KeyboardButtonProps={{
                                                "aria-label": "change date",
                                              }}
                                            />
                                          </MuiPickersUtilsProvider>
                                        </TableCell>
                                      )}
                                    {this.state.selectedEditIndex !== index ? (
                                      headCells1[12]
                                        .show && (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-cell"
                                          align="left"
                                        >
                                          {row.dhlRate &&
                                            row.dhlRate.length > 16 ? (
                                              <Tooltip
                                                title={row.dhlRate}
                                                aria-label="add"
                                              >
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.dhlRate}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.dhlRate}
                                              </Typography>
                                            )}
                                        </TableCell>
                                      )
                                    ) : (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-cell"
                                        >
                                          {this.state.dhlRate}
                                        </TableCell>
                                      )}
                                    {headCells1[13].show && (
                                      <TableCell
                                        className="table-content-cell"
                                        style={{ textAlign: "center" }}
                                      >
                                        {this.state.selectedEditIndex !==
                                          index ? (
                                            <div>
                                              <Tooltip title="Edit">
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                  onClick={(e) =>
                                                    this.handleEditClicked(
                                                      e,
                                                      index,
                                                      row
                                                    )
                                                  }
                                                >
                                                  <EditIcon
                                                    style={{
                                                      color: "#6c6b6b",
                                                    }}
                                                  />
                                                </span>
                                              </Tooltip>
                                              {/* {this.state.currentMode ? null : ( */}
                                              { this.state.dataInformation.status.pubStatusName !== "SavedAsDraft"
                                                && this.state.dataInformation.status.pubStatusName !== "Submitted"
                                                ? null : (

                                                  <Tooltip title="Delete">
                                                    <span
                                                      style={{
                                                        cursor: "pointer",
                                                        color: "#6c6b6b",
                                                      }}
                                                      onClick={(e) =>
                                                        this.handleDeleteClicked(
                                                          e,
                                                          index,
                                                          row
                                                        )
                                                      }
                                                    >
                                                      <DeleteIcon
                                                        style={{
                                                          cursor: "pointer",
                                                          color: "#6c6b6b",
                                                        }}
                                                      />
                                                    </span>
                                                  </Tooltip>
                                                )}
                                              { this.state.dataInformation.status.pubStatusName === "Processed" ? this.state.codeTypeList && this.state.codeTypeList.length > 1

                                                ? (

                                                  <Tooltip title="Delete">
                                                    <span
                                                      style={{
                                                        cursor: "pointer",
                                                        color: "#6c6b6b",
                                                      }}
                                                      onClick={(e) =>
                                                        this.handleDeletefForProcessedClicked(
                                                          e,
                                                          index,
                                                          row
                                                        )
                                                      }
                                                    >
                                                      <DeleteIcon
                                                        style={{
                                                          cursor: "pointer",
                                                          color: "#6c6b6b",
                                                        }}

                                                      />
                                                    </span>
                                                  </Tooltip>
                                                ) : <DeleteIcon
                                                  disabled
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "lightgray",
                                                  }}

                                                /> : null}
                                            </div>
                                          ) : (
                                            <div>
                                              <Tooltip title="Done">
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                  onClick={(e) =>
                                                    this.handleDoneClicked(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                >
                                                  {this.state.okToGO ? (
                                                    <DoneIcon
                                                      style={{
                                                        color: "#6c6b6b",
                                                      }}
                                                    />
                                                  ) : null}
                                                </span>
                                              </Tooltip>
                                              <Tooltip title="Cancel">
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                  onClick={(e) =>
                                                    this.handleCancelClicked(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <CloseIcon
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#6c6b6b",
                                                    }}
                                                  />
                                                </span>
                                              </Tooltip>
                                            </div>
                                          )}
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })
                            ) : (
                                <TableRow>
                                  {this.state.loading1 ? (
                                    <TableCell
                                      colSpan={15}
                                      style={{ textAlign: "center" }}
                                    >
                                      <LinearProgress />
                                    </TableCell>
                                  ) : (
                                      <TableCell
                                        colSpan={11}
                                        style={{ textAlign: "center" }}
                                      >
                                        No result found
                                      </TableCell>
                                    )}
                                </TableRow>
                              )}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/bpaOrderPlacement")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  {
                    // this.state.currentMode &&
                    this.state.dataInformation.status.pubStatusName !== "SavedAsDraft" ? null :
                      this.checkToGo() ||
                        this.neverEmpty() ||
                        !this.state.update ? (
                          <div>
                            <Button
                              disabled
                              variant="contained"
                              color="primary"
                              style={{ boxShadow: "none", marginLeft: "1em" }}
                              onClick={this.handleDraft}
                            >
                              SAVE AS DRAFT
                      </Button>
                          </div>
                        ) : (
                          <div>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ boxShadow: "none", marginLeft: "1em" }}
                              onClick={this.handleDraft}
                            >
                              SAVE AS DRAFT
                      </Button>
                          </div>
                        )}

                  <div style={{ marginLeft: "1em" }}>
                    {this.checkToGo() ||
                      this.neverEmpty() ||
                      this.state.currentMode ? (
                        this.checkToGo() ||
                          this.neverEmpty() ||
                          !this.state.update ? (
                            <Button
                              disabled
                              variant="contained"
                              color="primary"
                              style={{ boxShadow: "none" }}
                            >
                              SUBMIT
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ boxShadow: "none" }}
                              onClick={this.handleSubmit}
                            >
                              SUBMIT
                            </Button>
                          )
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(addDispatchStyles)(EditBpaOrderPlacement));
