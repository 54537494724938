import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter } from "react-router-dom";

import moment from "moment";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { StateContext } from "../../../shared/globalState";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  stableSort,
  getSorting,
  DialogActions,
} from "../../../shared/tableCommonFunctions";
import { Link } from "react-router-dom";
import { ExportCSV } from "../../../shared/ExportCSV";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import {
  SearchIcon,
  ViewColumnIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";

import { Autocomplete } from "@material-ui/lab";
import {
  getFliteredStockOutList,
  getStockOutList,
  getItemMasterList,
  getCustomerFromItemMaster,
} from "../genericActions";
const columns = [
  // {
  //   name: "Active",
  //   id: 9,
  //   show: false,
  // },
  {
    name: "Modified Date",
    id: 9,
    show: false,
  },
  {
    name: "Modified By",
    id: 10,
  },
  {
    name: "Created Date",
    id: 11,
  },
  {
    name: "Created By",
    id: 12,
  },
];

function createData(
  stockCode,
  stockName,
  warehouseLocation,
  customerCode,
  customerName,
  stockOutQty,
  stockOutdate,
  reason,
  modifiedByName,
  modifiedDate,
  createdByName,
  createdDate,
  isActive,
  id
) {
  return {
    stockCode,
    stockName,
    warehouseLocation,
    customerCode,
    customerName,
    stockOutQty,
    stockOutdate,
    reason,
    modifiedByName,
    modifiedDate,
    createdByName,
    createdDate,
    isActive,
    id,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          {/* <FormControlLabel
            control={
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all StockOut" }}
              />
            }
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.code}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [formValid, setIsformValid] = useState(false);
  const [SOSearchValue, setValues] = useState({
    stock: 0,
    customerID: 0,
    stockOutDateFrom: null,
    stockOutDateTo: null,
  });
  let acnArr = props.roles.filter((u) => u.subMenuName === "Stock Out");
  let acnObj = acnArr[0];

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  const classes = useToolbarStyles();
  const {
    numSelected,
    searchPopupOpen,
    exportDataValues,
    selectedStockOutID,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchStockOut,
    searchClicked,
    ItemMasterList,
    customerList,
    hendleVewColums,
  } = props;

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = SOSearchValue;
    searchValues.stock = "";
    searchValues.customer = "";
    searchValues.stockOutDateFrom = null;
    searchValues.stockOutDateTo = null;
    searchValues = SOSearchValue;
  };

  const searchPopup = () => {
    handleSearchStockOut(SOSearchValue);
    clearSearchValues();
  };

  const handleStockInput = (event, values) => {
    if (values !== null) {
      event.persist();
      setValues((SOSearchValue) => ({
        ...SOSearchValue,
        stock: values && values.stockID,
      }));
    }

    setIsformValid(true);
  };

  const handleCustomerInput = (event, values) => {
    if (values !== null) {
      event.persist();
      setValues((SOSearchValue) => ({
        ...SOSearchValue,
        customerID: values && values.id,
      }));
    }

    setIsformValid(true);
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
  };

  const handleFromDateChange = (date) => {
    setValues((SOSearchValue) => ({
      ...SOSearchValue,
      stockOutDateFrom: date && date.toLocaleDateString(),
    }));

    setIsformValid(true);
  };
  const handleToDateChange = (date) => {
    setValues((SOSearchValue) => ({
      ...SOSearchValue,
      stockOutDateTo: date && date.toLocaleDateString(),
    }));
    setIsformValid(true);
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_StockOut/${selectedStockOutID}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {searchClicked ? (
              <Tooltip title="Clear search result">
                <IconButton
                  aria-label="clear"
                  className={classes.iconHover}
                  onClick={props.clearSearch}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <form onSubmit={searchPopup}>
                {acnObj && acnObj.advanceSearchAction && (
                  <Tooltip title="Search">
                    <IconButton
                      aria-label="Search"
                      className={classes.iconHover}
                      onClick={handleSearchOpen}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Dialog
                  disableBackdropClick
                  disableEscapeKeyDown
                  onClose={handleSearchPopupClose}
                  aria-labelledby="customized-dialog-title"
                  open={searchPopupOpen}
                  maxWidth="md"
                >
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleSearchPopupClose}
                  >
                    Advanced Search
                  </DialogTitle>
                  <DialogContent dividers>
                    <React.Fragment>
                      <div style={{ minWidth: "400px", display: "flex" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            style={{ width: "47%" }}
                            id="FromDate"
                            label="Stock Out From Date"
                            value={SOSearchValue.stockOutDateFrom}
                            onChange={handleFromDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            autoOk
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            style={{ marginLeft: "8px", width: "48%" }}
                            id="ToDate"
                            label="Stock Out To Date"
                            value={SOSearchValue.stockOutDateTo}
                            onChange={handleToDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div style={{ minWidth: "400px", display: "flex" }}>
                        <Autocomplete
                          autoComplete
                          includeInputInList
                          id="stock"
                          name="stock"
                          style={{ width: "50%" }}
                          options={ItemMasterList}
                          onChange={handleStockInput}
                          getOptionLabel={(option) => option.stockCode}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Stock"
                              name="stockID"
                              id="stockID"
                              margin="normal"
                              style={{ width: "96%" }}
                              value={SOSearchValue.stockID}
                            />
                          )}
                        />
                        <Autocomplete
                          autoComplete
                          includeInputInList
                          id="Customer"
                          name="customer"
                          style={{ width: "50%" }}
                          options={customerList}
                          onChange={handleCustomerInput}
                          getOptionLabel={(option) => option.text}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Customer"
                              name="customerID"
                              id="customerID"
                              margin="normal"
                              style={{ width: "96%" }}
                              value={SOSearchValue.customerID}
                            />
                          )}
                        />
                      </div>
                    </React.Fragment>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={handleSearchClose}
                    >
                      CANCEL
                    </Button>

                    {!SOSearchValue.stock &&
                    !SOSearchValue.customerID &&
                    !(
                      SOSearchValue.stockOutDateFrom &&
                      SOSearchValue.stockOutDateTo
                    ) ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={searchPopup}
                      >
                        SEARCH
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={searchPopup}
                      >
                        SEARCH
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
              </form>
            )}
            {acnObj && acnObj.addAction && (
              <Tooltip title="Add">
                <IconButton
                  aria-label="add"
                  className={classes.iconHover}
                  onClick={(e) => props.history.push("/add_StockOut")}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
            {acnObj && acnObj.exportAction && (
              <ExportCSV
                csvData={exportDataValues}
                fileName={"StockOut_Details"}
              />
            )}
            <Tooltip title="View Column">
              <IconButton
                aria-label="viewColumn"
                className={classes.iconHover}
                onClick={handleClickListItem}
              >
                <ViewColumnIcon />
              </IconButton>
            </Tooltip>
            <EnhancedShowColumn
              id="columns-menu"
              keepMounted
              open={open}
              onClose={handleShowColClose}
              column={column}
              hendleVewColums={hendleVewColums}
              headCells={props.headCells}
            />
          </div>
        )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class StockOut extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "createdDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    refreshData: false,
    selectedStockOut: "",
    StockoutList: [],
    selectedStockOutID: 1,
    loading: false,
    searchPopupOpen: false,
    //SearchValues: {},
    searchClicked: false,
    ItemMasterList: [],
    SOSearchValue: {},
    selectedShowColumns: [],
    exportDataValues: [],
    customerList: [],
    headCells: [
      {
        code: "stockCode",
        id: 1,
        numeric: false,
        disablePadding: false,
        label: "Stock Code",
        show: true,
      },
      {
        code: "stockName",
        id: 2,
        numeric: false,
        disablePadding: true,
        label: "Stock Name",
        show: true,
      },
      {
        code: "warehouseLocation",
        id: 3,
        numeric: false,
        disablePadding: false,
        label: "Warehouse Location",
        show: true,
      },
      {
        code: "customerCode",
        id: 4,
        numeric: false,
        disablePadding: false,
        label: "Customer Code",
        show: true,
      },

      {
        code: "customerName",
        id: 5,
        numeric: false,
        disablePadding: false,
        label: "Customer",
        show: true,
      },
      {
        id: 6,
        code: "stockOutQty",
        numeric: false,
        disablePadding: false,
        label: "Stock Out Qty",
        show: true,
      },
      {
        id: 7,
        code: "stockOutdate",
        numeric: false,
        disablePadding: false,
        label: "Stock Out Date",
        show: true,
      },
      {
        id: 8,
        code: "reason",
        numeric: false,
        disablePadding: false,
        label: "Reason",
        show: true,
      },
      // {
      //   id: 9,
      //   code: "active",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Active",
      //   show: true,
      // },
      {
        id: 9,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 10,
        code: "modifiedByName",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 11,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 12,
        code: "createdByName",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };
  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };
  componentDidMount() {
    this.props.handleSettingHeader("Stock Out");
    this.props.setSelectedList(
      "genericSystemOpen",
      "StockOut",
      "warehouseManagementOpen"
    );
    const dispatch = this.context[1];
    getStockOutList().then((r) => {
      dispatch({
        type: "getStockOutList",
        newStore: r.data,
      });
      this.getAllStockOut(r.data);
      this.exportDatas(r.data);
    });
    this.setState({ loading: true });

    // getCustomerFromItemMaster().then((r) => {
    //   this.getAllCustomers(r.data);
    // });
    getCustomerFromItemMaster().then((r) => {
      this.getAllCustomers(r.data);
    });
    getItemMasterList().then((r) => {
      this.setState({
        ItemMasterList:
          r.data &&
          r.data.sort((a, b) =>
            a.stockCode.toLowerCase() > b.stockCode.toLowerCase() ? 1 : -1
          ),
      });
    });
  }

  getAllCustomers = (list) => {
    let customerList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        customerList.findIndex((p) => p.id === c.id) > -1
          ? customerList
          : customerList.push(c)
      );

    this.setState({
      customerList:
        customerList &&
        customerList.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };
  exportDatas = (StockoutList) => {
    let list = StockoutList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          "Stock Code": d.stockCode,
          "Stock Name": d.stockName,

          "Warehouse Location": d.warehouseLocation,
          "Customer Code": d.customerCode,
          Customer: d.customerName,
          "Stock Out Qty": d.stockOutQty,
          "Stock Out date": d.stockOutdate,
          Reason: d.reason,
          "Modified Date": moment(d.modifiedDate).format("DD/MM/YYYY HH:mm:ss"),
          "Modified By": d.modifiedByName,
          "Created Date": moment(d.createdDate).format("DD/MM/YYYY HH:mm:ss"),
          "Created By": d.createdByName,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getAllStockOut = (StockoutList) => {
    let list = StockoutList;
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return newRows.push(
          createData(
            d.stockCode,
            d.stockName,
            d.warehouseLocation,
            d.customerCode,
            d.customerName,
            d.stockOutQty,
            d.stockOutdate,
            d.reason,
            d.modifiedByName,
            d.modifiedDate,
            d.createdByName,
            d.createdDate,
            d.isActive,
            d.stockOutID
          )
        );
      });
    this.setState({ StockoutList: newRows, selected: [], loading: false });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.StockoutList && store.StockoutList.length > 0)
      if (this.state.StockoutList.length !== store.StockoutList.length) {
        this.getAllStockOut(store.StockoutList);
      }
    return true;
  }
  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { StockoutList } = this.state;
    if (event.target.checked) {
      const newSelecteds = StockoutList.map((n) => n.id);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id, StockOut) => {
    let newSelected = [];
    let selected = [];
    if (event.target.checked) {
      newSelected = newSelected.concat(selected, id);
      this.setState({
        selected: newSelected,
        selectedStockOut: StockOut,
        selectedStockOutID: id,
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedStockOut: StockOut,
        selectedStockOutID: id,
      });
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true, page: 0 });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };
  handleSearchStockOut = (handleSearchStockOut) => {
    const dispatch = this.context[1];
    let SOSearchValue = handleSearchStockOut;
    let stockID = SOSearchValue.stock;
    let customerID = SOSearchValue.customerID;
    let stockOutDateFrom =
      SOSearchValue.stockOutDateFrom &&
      moment(SOSearchValue.stockOutDateFrom).format("DD/MM/YYYY");
    if (stockOutDateFrom == null) {
      stockOutDateFrom = "";
    }

    let stockOutDateTo =
      SOSearchValue.stockOutDateTo &&
      moment(SOSearchValue.stockOutDateTo).format("DD/MM/YYYY");
    if (stockOutDateTo == null) {
      stockOutDateTo = "";
    }
    let reqBody = { stockID, customerID, stockOutDateFrom, stockOutDateTo };
    getFliteredStockOutList(reqBody).then((r) => {
      dispatch({
        type: "getFliteredStockOutList",
        newStore: r.data,
      });
      this.getAllStockOut(r.data);
      this.setState({ searchPopupOpen: false, searchClicked: true });
    });
  };

  clearSearch = () => {
    const dispatch = this.context[1];
    getStockOutList().then((r) => {
      dispatch({
        type: "getStockOutList",
        newStore: r.data,
      });
      this.getAllStockOut(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      selectedStockOut,
      StockoutList,
      headCells,
      exportDataValues,
    } = this.state;
    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedStockOut={selectedStockOut}
            onSearchClicked={this.onSearchClicked}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            selected={this.state.selected}
            selectedStockOutID={this.state.selectedStockOutID}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchStockOut={this.handleSearchStockOut}
            SOSearchValue={this.state.SOSearchValue}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            StockoutList={this.state.StockoutList}
            exportDataValues={exportDataValues}
            ItemMasterList={this.state.ItemMasterList}
            customerList={this.state.customerList}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 8em)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
                  : {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 330px)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ whiteSpace: "nowrap" }}
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={StockoutList.length}
                  headCells={this.state.headCells}
                />

                <TableBody style={{ overflow: "hidden" }}>
                  {StockoutList.length > 0 ? (
                    stableSort(StockoutList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )

                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              this.handleClick(event, row.id, row)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.stockCode && row.stockCode.length > 16 ? (
                                  <Tooltip title={row.stockCode}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.stockCode}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockCode}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.stockName && row.stockName.length > 16 ? (
                                  <Tooltip title={row.stockName}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.stockName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.warehouseLocation &&
                                row.warehouseLocation.length > 16 ? (
                                  <Tooltip title={row.warehouseLocation}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.warehouseLocation}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.warehouseLocation}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.customerCode &&
                                row.customerCode.length > 16 ? (
                                  <Tooltip title={row.customerCode}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerCode}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.customerCode}
                                  </Typography>
                                )}
                              </TableCell>
                            )}

                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.customerName &&
                                row.customerName.length > 16 ? (
                                  <Tooltip title={row.customerName}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.customerName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.stockOutQty &&
                                row.stockOutQty.length > 16 ? (
                                  <Tooltip title={row.stockOutQty}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.stockOutQty}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockOutQty}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.stockOutdate &&
                                row.stockOutdate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.stockOutdate).format(
                                      "DD/MM/YYYY  HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.stockOutdate).format(
                                        "DD/MM/YYYY  HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.stockOutdate).format(
                                      "DD/MM/YYYY  HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.reason && row.reason.length > 16 ? (
                                  <Tooltip title={row.reason}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.reason}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.reason}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {/* {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {status}
                                </Typography>
                              </TableCell>
                            )} */}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Moment format="MM/DD/YYYY">{row.createddate}</Moment> */}
                                {/* moddate */}

                                {row.modifiedDate &&
                                row.modifiedDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.modifiedByName}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Moment format="MM/DD/YYYY">{row.createddate}</Moment> */}
                                {/* moddate */}

                                {row.createdDate &&
                                row.createdDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.createdByName}
                                </Typography>
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      {this.state.loading ? (
                        <TableCell colSpan={15} style={{ textAlign: "center" }}>
                          <LinearProgress />
                        </TableCell>
                      ) : (
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                          No result found
                        </TableCell>
                      )}
                      {/* {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )} */}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>

          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={StockoutList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(StockOut);
