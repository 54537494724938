import React, { Component } from "react";

class BLCPTSummaryReport extends Component {
  state = {};
  componentDidMount() {
    this.props.handleSettingHeader("Back Label CPT Summary");
    this.props.setSelectedList("publisherReportsOpen", "BLCPTSummaryReport");
  }
  render() {
    return (
      <div>
      <iframe
        style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
        src={`${window.localStorage.ReportURLByID}` +"BLCPTSummaryReport"}
        />
      </div>
    );
  }
}

export default BLCPTSummaryReport;
