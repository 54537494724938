import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import Dialog from "@material-ui/core/Dialog";
import toastr from "toastr";
import ReportViewer from "../../app/ReportViewer";
import clone from "clone";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import SearchIcon from "@material-ui/icons/Search";
import OfflinePinIcon from "@material-ui/icons/OfflinePin";
import Validator from "../../../shared/validator";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { CustomCheckbox } from "../../../shared/tableCommonFunctions";
import {
  getMainOrderFile,
  getSuplimentOrderFile,
  getbatchDetails,
  postOrderDetails,
  getPickInsertList,
  getCriteriaFilesList,
  postPickInsert,
  postNextInsert,
  getBatchSummaryList,
  postCriteriaByOF,
  getListForView,
  getSecondListForView,
  getprocessingList1,
  getprocessingList2,
  getprocessingList3,
  postCriteriaByZC,
  submitSuplimentFile,
} from "./actions";
import {
  getCustomerList,
  getLocationDropdownList,
  getBucketsDD,
  getcriteriaDD,
  getCriteriacCountryZoneList,
} from "../../masters/masterActions";
import { getPublisherCustomerList } from "../action";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import InfoIcon from "@material-ui/icons/Info";
import PropTypes from "prop-types";
import { TextareaAutosize, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
// stepper
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import { IconButton } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// end stepper
let autoCId = "";
let zoneCList = [];
const baseURL = process.env.REACT_APP_PGS_SERVER_PUBLISHER_ORDER_PROCESSING;
const pgsService = axios.create({
  baseURL,
});
//const testTbl=[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
let needApiCallInStepOne = false
let needApiCallInStepTwo = false
const headCells = [
  {
    id: "orderFileName",
    numeric: false,
    disablePadding: true,
    label: "Order File Name",
  },
  {
    id: "receivedDate",
    numeric: false,
    disablePadding: false,
    label: "Received Date",
  },
  {
    id: "issueDescription",
    numeric: false,
    disablePadding: false,
    label: "Issue Description",
  },
  // {
  //   id: "specialInstruction",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Special Instruction"
  // },
  {
    id: "subscription",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "totalOrder",
    numeric: false,
    disablePadding: false,
    label: "Tot Orders",
  },
  {
    id: "totalCopies",
    numeric: false,
    disablePadding: false,
    label: "Tot Copies",
  },
  {
    id: "bucket",
    numeric: false,
    disablePadding: false,
    label: "Bucket",
  },
  {
    id: "suplimentOrderFile",
    numeric: false,
    disablePadding: false,
    label: "Supp File 1",
  },
  {
    id: "suplimentOrderFile",
    numeric: false,
    disablePadding: false,
    label: "Supp File 2",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];
const headCellsInsertCriteria = [
  {
    id: "issueDescription",
    numeric: false,
    disablePadding: true,
    label: "Issue Description",
  },
  {
    id: "insert",
    numeric: false,
    disablePadding: false,
    label: "Insert#",
  },
  {
    id: "insertName",
    numeric: false,
    disablePadding: false,
    label: "Insert Name",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "criteria",
    numeric: false,
    disablePadding: false,
    label: "Criteria",
  },

  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];
const headCellsConsolidation = [
  {
    id: "sl",
    numeric: false,
    disablePadding: true,
    label: "SNo.",
  },
  {
    id: "bucket",
    numeric: false,
    disablePadding: true,
    label: "Bucket",
  },
  // {
  //   id: "consolidationType",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Consolidation Type"
  // },

  // {
  //   id: "totalWeight",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Total Weight (Kg)"
  // },
  // {
  //   id: "subscription",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Subscription"
  // },
  {
    id: "carrierSheetFileName",
    numeric: false,
    disablePadding: false,
    label: "Carrier Sheet File Name",
  },
  {
    id: "destination",
    numeric: false,
    disablePadding: false,
    label: "Destination",
  },
  {
    id: "singleMultipe",
    numeric: false,
    disablePadding: false,
    label: "S/M",
  },
  {
    id: "issueDescription",
    numeric: false,
    disablePadding: false,
    label: "Issue Description",
  },
  {
    id: "totalOrder",
    numeric: false,
    disablePadding: false,
    label: "Tot. Order",
  },
  {
    id: "totalQuantity",
    numeric: false,
    disablePadding: false,
    label: "Tot. Qty",
  },
  {
    id: "totalJournalWeight",
    numeric: false,
    disablePadding: false,
    label: "Tot. Journal Wt",
  },
  {
    id: "totalSupplimentOneWeight",
    numeric: false,
    disablePadding: false,
    label: "Tot. Supp. 1 Wt(kg)",
  },
  {
    id: "totalSupplimentTwoWeight",
    numeric: false,
    disablePadding: false,
    label: "Tot. Supp. 2 Wt(kg)",
  },
  {
    id: "totalInsertWeight",
    numeric: false,
    disablePadding: false,
    label: "Tot. Insert Wt",
  },
  {
    id: "totalOrderweight",
    numeric: false,
    disablePadding: false,
    label: "Tot. Order Wt",
  },
];
const headCellsConsolidationForFailed = [
  {
    id: "sl",
    numeric: false,
    disablePadding: true,
    label: "SNo.",
  },
  {
    id: "customerCode",
    numeric: false,
    disablePadding: true,
    label: "Customer",
  },
  {
    id: "locationCode",
    numeric: false,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "shipToCustomer",
    numeric: false,
    disablePadding: false,
    label: "Ship To Customer",
  },
  {
    id: "country",
    numeric: false,
    disablePadding: false,
    label: "Country",
  },
  {
    id: "acronym",
    numeric: false,
    disablePadding: false,
    label: "Acronym",
  },
  {
    id: "isPeriodical",
    numeric: false,
    disablePadding: false,
    label: "Periodical",
  },
  {
    id: "issueDescription",
    numeric: false,
    disablePadding: false,
    label: "Issue Description",
  },
  {
    id: "consolidationType",
    numeric: false,
    disablePadding: false,
    label: "Consolidation Type",
  },
  {
    id: "priorityCode",
    numeric: false,
    disablePadding: false,
    label: "Priority Code",
  },
  {
    id: "totalOrder",
    numeric: false,
    disablePadding: false,
    label: "Total Order",
  },
  {
    id: "totalCopies",
    numeric: false,
    disablePadding: false,
    label: "Total Copies",
  },
  {
    id: "totalOrderweight",
    numeric: false,
    disablePadding: false,
    label: "Total Order Weight",
  },
];
const headCellsConsolidationLast = [
  {
    id: "customerName",
    numeric: false,
    disablePadding: true,
    label: "Customer Name ",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "batchCode",
    numeric: false,
    disablePadding: false,
    label: "Batch Code",
  },
  {
    id: "processedDate",
    numeric: false,
    disablePadding: false,
    label: "Processed Date",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
  },
];
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderTop: "1px solid #eaeaea",
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
  search: {
    position: "relative",
    minWidth: "500px",
    display: "flex",
  },
  searchIcon: {
    position: "absolute",
    right: "0",
  },
}));
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow style={{ background: "#eaeaea" }}>
        <TableCell
          padding="checkbox"
          // className="table-cell"
          className="table-content-cell"
          style={{
            borderLeft: "1px solid #eaeaea",
            borderRight: "none",
            borderTop: "1px solid #eaeaea",
          }}
        ></TableCell>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            //align={headCell.numeric ? "right" : "left"}
            className="table-content-cell"
            align="left"
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={
              i === headCells.length - 1
                ? {
                  borderRight: "1px solid #eaeaea",
                }
                : //:
                // i === 0
                // ? {
                //     borderLeft: "1px solid #eaeaea"
                //   }
                {}
            }
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function EnhancedTableHeadInsertCriteria(props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          className="table-content-cell"
          // className="table-cell"

          style={{
            borderLeft: "1px solid #eaeaea",
            borderRight: "none",
            borderTop: "1px solid #eaeaea",
          }}
        ></TableCell>
        {headCellsInsertCriteria.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            //align={headCell.numeric ? "right" : "left"}
            align="left"
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={
              i === headCells.length - 1
                ? {
                  borderRight: "1px solid #eaeaea",
                }
                : //:
                // i === 0
                // ? {
                //     borderLeft: "1px solid #eaeaea"
                //   }
                {}
            }
          >
            {/* <span style={{ color: "#306dca" }}>{headCell.label}</span> */}
            {/* <span style={{ color: "#306dca" }}>{headCell.label === "Criteria" ? <span>{headCell.label}<span style={{ color: "red", marginLeft: "4px" }}>*</span></span> : headCell.label}</span> */}
            <span style={{ color: "#306dca" }}>{props.secondList && props.secondList.length > 0 && headCell.label === "Criteria" ? <span>{headCell.label}<span style={{ color: "red", marginLeft: "4px" }}>*</span></span> : headCell.label}</span>

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function EnhancedTableHeadConsolidation(props) {
  return (
    <TableHead>
      <TableRow style={{ background: "#eaeaea" }}>
        {headCellsConsolidation.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            //align={headCell.numeric ? "right" : "left"}
            align="left"
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={
              i === headCells.length - 1
                ? {
                  borderRight: "1px solid #eaeaea",
                }
                : i === 0
                  ? {
                    borderLeft: "1px solid #eaeaea",
                  }
                  : {}
            }
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function EnhancedTableHeadConsolidationFailed(props) {
  return (
    <TableHead>
      <TableRow>
        {headCellsConsolidationForFailed.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            //align={headCell.numeric ? "right" : "left"}
            align="left"
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={
              i === headCells.length - 1
                ? {
                  borderRight: "1px solid #eaeaea",
                }
                : i === 0
                  ? {
                    borderLeft: "1px solid #eaeaea",
                  }
                  : {}
            }
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function EnhancedTableHeadConsolidationLast(props) {
  return (
    <TableHead>
      <TableRow>
        {headCellsConsolidationLast.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            //align={headCell.numeric ? "right" : "left"}
            align="left"
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={
              i === headCells.length - 1
                ? {
                  borderRight: "1px solid #eaeaea",
                }
                : i === 0
                  ? {
                    borderLeft: "1px solid #eaeaea",
                  }
                  : {}
            }
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { } = props;
  // console.log(props)
  return (
    <div>
      <div
        style={{
          display: "flex",
          //   width: "100%",
          border: "1px solid #eaeaea",
          borderBottom: "none",
          borderRadius: "4px",
          margin: "1em 0 0 0",
        }}
      >
        <Button
          disabled={props.disableAdd}
          color="primary"
          variant="filled"
          // onClick={props.handleChooseOFDialog}
          onClick={(e) => props.handleMainOFButton()}
          style={
            !props.disableAdd
              ? {
                color: "#fff",
                background: "#3982ea",
                padding: "4px",
                margin: ".5em",
                fontSize: "11px",
                boxShadow: "none",
              }
              : { color: "#3982ea", fontSize: "11px" }
          }
        >
          <AddIcon style={{ fontSize: "18px" }} /> Pick Main Order File
        </Button>
        {props.selectedIdToNext.length > 0 && props && props.batchDetails && props.batchDetails.customerCode && props.batchDetails.customerCode === "Wiley" && (

          <Button
            disabled={props.list && props.selectedIdToNext && props.list[props.selectedIdToNext] && props.list[props.selectedIdToNext].supplimentId2}
            color="primary"
            variant="filled"
            //onClick={props.handleChooseOFDialog}
            onClick={(e) => props.handleSOFButton()}
            style={
              props.list[props.selectedIdToNext] && !props.list[props.selectedIdToNext].supplimentId2
                ? {
                  color: "#fff",
                  background: "#3982ea",
                  padding: "4px",
                  margin: ".5em",
                  fontSize: "11px",
                  boxShadow: "none",
                }
                : { display: "none" }
            }
          >
            <AddIcon style={{ fontSize: "18px" }} /> Pick Supplement Order File
          </Button>
        )}
        {props.selectedIdToNext.length > 0 && props.list[props.selectedIdToNext] &&
          (props.list[props.selectedIdToNext[0]].supplimentId1 ||
            props.list[props.selectedIdToNext[0]].supplimentId2) ? (
            <Button
              //disabled={props.disableAdd}
              color="primary"
              variant="filled"
              //onClick={props.handleChooseOFDialog}
              onClick={(e) => props.handleRemoveSuppliment()}
              style={
                !props.disableAdd
                  ? {
                    color: "#fff",
                    background: "#3982ea",
                    padding: "4px",
                    margin: ".5em",
                    fontSize: "11px",
                    boxShadow: "none",
                  }
                  : { color: "#3982ea", fontSize: "11px" }
              }
            >
              <AddIcon style={{ fontSize: "18px" }} /> Remove Supplement
            </Button>
          ) : (
            ""
          )}
        <div>
          {/* <Button
            // className={
            //   woStatus === "choose" || woSearchSubmit === "chooseSubmit"
            //     ? "enabled-tab-outline-button"
            //     : "disabled-tab-outline-button"
            // }
            onClick={props.handleChooseOFDialog}
          >
            {" "}
            Pick Main Order File
          </Button> */}
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={props.openOFModal}
            onClose={props.handleChooseOFDialog}
            maxWidth="md"
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Pick Order File</DialogTitle>
            <DialogContent dividers>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {/* <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast
                      required
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      name="receivedFromDate"
                      className={classes.textField}
                      id="receivedFromDate"
                      label="Received From Date"
                      //value={receivedFromDate}
                      // onChange={e =>
                      //   this.handleDateChange(e, "receivedFromDate")
                      // }
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div> */}
                {/* <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast
                      required
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      name="receivedToDate"
                      className={classes.textField}
                      id="receivedToDate"
                      label="Received To Date"
                     // value={receivedToDate}
                      // onChange={e =>
                      //   this.handleDateChange(e, "receivedToDate")
                      // }
                      
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div> */}
              </div>
              <div className={classes.search}>
                <TextField
                  autoFocus
                  margin="dense"
                  // onChange={this.handleChangeSearchWO}
                  id="name"
                  autoComplete="off"
                  placeholder="Search Issue Description, Order file name, Subscription, Spl instruction"
                  // type="search"
                  fullWidth
                  onChange={props.setOSSearchVal}
                  value={props.searchValOS}
                />
                <div className={classes.searchIcon}>
                  {!props.oFSearchClicked ? (
                    <IconButton
                      aria-label="view"
                      className={classes.iconHover}
                      onClick={props.onOSSearchClicked}
                      disabled={!props.searchValOS}
                    >
                      <SearchIcon />
                    </IconButton>
                  ) : (
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                        onClick={props.clearOSSearchVal}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    )}
                </div>
              </div>
              <div style={{ width: "100%", overflow: "auto" }}>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={"lg"}
                  aria-label="enhanced table"
                  style={{ marginTop: "10px" }}
                >
                  <TableHead>
                    <TableRow style={{ borderTop: "1px solid #eaeaea" }}>
                      <TableCell
                        padding="checkbox"
                        className="table-content-cell-wm"
                        style={{ width: "20px" }}
                      >
                        {" "}
                      </TableCell>
                      <TableCell
                        className="table-content-cell-wm"
                        padding="none"
                        style={{ color: "#306dca", maxWidth: "220px" }}
                      >
                        Order File Name
                      </TableCell>
                      <TableCell
                        padding="default"
                        style={{ maxWidth: "180px" }}
                        className="table-content-cell-wm"
                      >
                        {" "}
                        <span style={{ color: "#306dca" }}>
                          Received Date
                        </span>{" "}
                      </TableCell>
                      <TableCell
                        className="table-content-cell-wm"
                        padding="default"
                        style={{ color: "#306dca", maxWidth: "180px" }}
                      >
                        Issue description
                      </TableCell>
                      {/* <TableCell padding="default"style={{minWidth:"120px"}} className="table-content-cell">
                        {" "}
                        <span style={{ color: "#306dca" }}>
                          Spl Instruction
                        </span>{" "}
                      </TableCell> */}
                      <TableCell
                        padding="default"
                        className="table-content-cell-wm"
                        style={{ maxWidth: "100px" }}
                      >
                        {" "}
                        <span style={{ color: "#306dca" }}>
                          Subscription
                        </span>{" "}
                      </TableCell>{" "}
                      <TableCell
                        className="table-content-cell-wm"
                        padding="default"
                        style={{ maxWidth: "100px" }}
                      >
                        <span style={{ color: "#306dca" }}>Total Order</span>
                      </TableCell>{" "}
                      <TableCell
                        className="table-content-cell-wm"
                        padding="default"
                        style={{ maxWidth: "100px" }}
                      >
                        <span style={{ color: "#306dca" }}>Total Copies</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {props.mofList.length > 0 ? (
                    <TableBody>
                      {props.mofList.length > 0 &&
                        props.mofList.map((row, index) => {
                          // const isItemSelected = isSelected(index);
                          return (
                            <TableRow role="checkbox" tabIndex={-1} key={index}>
                              <TableCell
                                padding="checkbox"
                                className="table-content-cell"
                              // style={{ width: "20px" }}
                              >
                                <FormControlLabel
                                  onClick={(event) =>
                                    props.handleCkBxClick(event, row, index)
                                  }
                                  control={
                                    // props.OSFMode==="SOF" ?
                                    <Checkbox
                                      color="primary"
                                      checked={row.checkBoxVal ? true : false}
                                    />
                                    // :
                                    // <Checkbox
                                    //   color="primary"
                                    //  // checked={row.checkBoxVal ? true:false}
                                    // />
                                  }
                                />
                              </TableCell>
                              <TableCell
                                className="table-content-cell-wm"
                                component="th"
                                scope="row"
                                padding="none"
                                style={{ maxWidth: "220px" }}
                              >
                                {" "}
                                {row.orderFileName &&
                                  row.orderFileName.length > 16 ? (
                                    <Tooltip
                                      title={row.orderFileName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.orderFileName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.orderFileName}
                                    </Typography>
                                  )}
                              </TableCell>
                              <TableCell
                                style={{ maxWidth: "180px" }}
                                className="table-content-cell-wm"
                              >
                                {row.receivedDate &&
                                  moment(row.receivedDate).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                  ).length > 16 ? (
                                    <Tooltip
                                      title={
                                        row.receivedDate
                                          ? moment(row.receivedDate).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                          )
                                          : ""
                                      }
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.receivedDate
                                          ? moment(row.receivedDate).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                          )
                                          : ""}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.receivedDate
                                        ? moment(row.receivedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )
                                        : ""}
                                    </Typography>
                                  )}
                              </TableCell>
                              <TableCell
                                className="table-content-cell-wm"
                                style={{ maxWidth: "180px" }}
                              >
                                {row.issueDescription &&
                                  row.issueDescription.length > 12 ? (
                                    <Tooltip
                                      title={row.issueDescription}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.issueDescription}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issueDescription}
                                    </Typography>
                                  )}
                              </TableCell>
                              {/* <TableCell className="table-content-cell" style={{minWidth:"120px",maxWidth:"120px"}}> 
                            {row.splInstruction && row.splInstruction.length > 16 ? (
                                <Tooltip title={row.splInstruction} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.splInstruction}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography
                                  noWrap
                                  style={{ fontSize: "14px" }}
                                >
                                  {row.splInstruction}
                                </Typography>
                              )}
                            </TableCell> */}
                              <TableCell
                                className="table-content-cell-wm"
                                style={{ maxWidth: "100px" }}
                              >
                                {row.subscription &&
                                  row.subscription.length > 16 ? (
                                    <Tooltip
                                      title={row.subscription}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.subscription}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.subscription}
                                    </Typography>
                                  )}
                              </TableCell>
                              <TableCell
                                className="table-content-cell-wm"
                                style={{
                                  maxWidth: "100px",
                                  textAlign: "right",
                                }}
                              >
                                {row.totalOrders}
                              </TableCell>
                              <TableCell
                                className="table-content-cell-wm"
                                style={{
                                  maxWidth: "100px",
                                  textAlign: "right",
                                }}
                              >
                                {row.totalCopies}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  ) : (
                      <TableBody>
                        <TableRow
                          style={{
                            borderRight: "1px solid #eaeaea",
                            borderLeft: "1px solid #eaeaea",
                          }}
                        >
                          <TableCell colSpan="8" style={{ textAlign: "center" }}>
                            <span>No data found</span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                </Table>
              </div>
            </DialogContent>
            <DialogActions style={{ padding: "10px 24px" }}>
              <Button
                onClick={props.handleChooseOFDialog}
                color="primary"
                variant="contained"
                style={{ boxShadow: "none" }}
              >
                CANCEL
              </Button>
              {props.OSFMode === "MOF" ? <Button
                disabled={
                  props.OSFMode === "MOF"
                    ? props.selectedId.length === 0
                    : props.OSFMode === "SOF"
                      ? props.selectedSupId.length === 0
                      : false
                }
                //onClick={this.handlechooseWoSubmit}
                // variant="contained"
                // className="enabled-button"
                // className={
                //   Object.keys(selected).length === 0
                //     ? "disabled-button"
                //     : "enabled-button "
                // }
                onClick={(e) => props.submitOF("of")}
                color="primary"
                variant="contained"
                style={{ boxShadow: "none" }}
              >
                SUBMIT
              </Button> : !props.spLoading ? <Button
                  disabled={
                    props.OSFMode === "MOF"
                      ? props.selectedId.length === 0
                      : props.OSFMode === "SOF"
                        ? props.selectedSupId.length === 0
                        : false
                  }
                  //onClick={this.handlechooseWoSubmit}
                  // variant="contained"
                  // className="enabled-button"
                  // className={
                  //   Object.keys(selected).length === 0
                  //     ? "disabled-button"
                  //     : "enabled-button "
                  // }
                  onClick={(e) => props.submitOF("of")}
                  color="primary"
                  variant="contained"
                  style={{ boxShadow: "none" }}
                >
                  SUBMIT
              </Button> : <CircularProgress />}

            </DialogActions>
          </Dialog>
        </div>
        {/* <Button
          disabled={props.disableEdit}
          color="primary"
          variant="filled"
          style={
            !props.disableEdit
              ? {
                  color: "#fff",
                  background: "#3982ea",
                  padding: "4px",
                  margin: ".5em",
                  fontSize: "11px"
                }
              : { color: "#3982ea", fontSize: "11px" }
          }
          // onClick={e => props.handleClickButton(e, "reset")}
        >
          <SyncIcon style={{ fontSize: "18px" }} /> Reset
        </Button> */}
      </div>
    </div>
  );
};
const sortZCList = (sList, name) => {
  let list = clone(sList)
  let z = list.sort(function (a, b) {
    var x = a.checkBoxVal && toString(a.checkBoxVal)
    var y = b.checkBoxVal ? toString(b.checkBoxVal) : "False"
    if (x < y) {
      return 1;
    }
    if (x > y) {
      return -1;
    }
    return 0;
  });
  // console.log(z)
  return z
};
const EnhancedTableToolbarInsertCriteria = (props) => {
  const classes = useToolbarStyles();
  const { } = props;
  // console.log(props.secondList[props.secondListSelectedId[0]])
  // console.log(props.secondList[props.secondListSelectedId])
  // let fZCList = sortZCList(props.zoneCountryList)
  let fZCList = props.zoneCountryList
  // console.log(props)

  return (
    <div>
      <div
        style={{
          display: "flex",
          //   width: "100%",
          border: "1px solid #eaeaea",
          borderBottom: "none",
          borderRadius: "4px",
          margin: "1em 0 0 0",
        }}
      >
        <Button
          disabled={props.disableAdd}
          color="primary"
          variant="filled"
          onClick={props.handleChooseICDialog}
          //onClick={e => props.handleClickButton(e, "add")}
          style={
            !props.disableAdd
              ? {
                color: "#fff",
                background: "#3982ea",
                padding: "4px",
                margin: ".5em",
                fontSize: "11px",
                boxShadow: "none",
              }
              : { color: "#3982ea", fontSize: "11px" }
          }
        >
          <AddIcon style={{ fontSize: "18px" }} /> Pick Insert
        </Button>
        {props.secondList &&
          props.secondList.length > 0 &&
          props.secondListSelectedId.length > 0 && props.secondList[props.secondListSelectedId] &&
          !props.secondList[props.secondListSelectedId[0]].criteriaID && props.secondList[props.secondListSelectedId[0]].checkBoxVal && (
            <Button
              disabled={props.disableAdd}
              color="primary"
              variant="filled"
              onClick={props.handleCriteriaModalInCancel}
              //onClick={e => props.handleClickButton(e, "add")}
              style={
                !props.disableAdd
                  ? {
                    color: "#fff",
                    background: "#3982ea",
                    padding: "4px",
                    margin: ".5em",
                    fontSize: "11px",
                    boxShadow: "none",
                  }
                  : { color: "#3982ea", fontSize: "11px" }
              }
            >
              <AddIcon style={{ fontSize: "18px" }} /> Pick Criteria
            </Button>
          )}
        {props.secondList &&
          props.secondList.length > 0 &&
          props.secondListSelectedId.length > 0 && props.secondList[props.secondListSelectedId] &&
          props.secondList[props.secondListSelectedId[0]].criteriaID && props.secondList[props.secondListSelectedId[0]].checkBoxVal && (
            <Button
              disabled={props.disableAdd}
              color="primary"
              variant="filled"
              onClick={props.handleRemoveCriteria}
              //onClick={e => props.handleClickButton(e, "add")}
              style={
                !props.disableAdd
                  ? {
                    color: "#fff",
                    background: "#3982ea",
                    padding: "4px",
                    margin: ".5em",
                    fontSize: "11px",
                    boxShadow: "none",
                  }
                  : { color: "#3982ea", fontSize: "11px" }
              }
            >
              <AddIcon style={{ fontSize: "18px" }} /> Remove Criteria
            </Button>
          )}
        <div>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={props.openICModal}
            onClose={props.handleChooseICDialog}
            maxWidth="md"
            style={{ minWidth: "600px" }}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Pick Insert</DialogTitle>
            <DialogContent dividers>
              <div className={classes.search}>
                <TextField
                  autoFocus
                  margin="dense"
                  // onChange={this.handleChangeSearchWO}
                  id="name"
                  autoComplete="off"
                  placeholder="Search Insert#, Insert Name, Quantity"
                  // type="search"
                  fullWidth
                  value={props.searchValPickInsert}
                  onChange={props.setPickInsertearchVal}
                />
                <div className={classes.searchIcon}>
                  {!props.pickInsertSearchClicked ? (
                    <IconButton
                      aria-label="view"
                      className={classes.iconHover}
                      onClick={props.onPickInsertSearchClicked}
                      disabled={!props.searchValPickInsert}
                    >
                      <SearchIcon />
                    </IconButton>
                  ) : (
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                        onClick={props.clearPickInsertSearchVal}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    )}
                </div>
              </div>
              <div
              // style={{ width: "100%", overflow: "auto" }}
              >
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={"small"}
                  aria-label="enhanced table"
                  style={{ marginTop: "10px" }}
                >
                  <TableHead>
                    <TableRow style={{ borderTop: "1px solid #eaeaea" }}>
                      <TableCell
                        padding="checkbox"
                        className="table-content-cell"
                      >
                        {" "}
                      </TableCell>
                      <TableCell
                        className="table-content-cell"
                        padding="none"
                        style={{ color: "#306dca", minWidth: "140px" }}
                      >
                        Insert#
                      </TableCell>
                      <TableCell
                        padding="default"
                        className="table-content-cell"
                      >
                        {" "}
                        <span style={{ color: "#306dca" }}>
                          Insert Name
                        </span>{" "}
                      </TableCell>

                      <TableCell
                        className="table-content-cell"
                        padding="default"
                        style={{ color: "#306dca", minWidth: "120px" }}
                      >
                        Issue Description
                      </TableCell>
                      <TableCell
                        className="table-content-cell"
                        padding="default"
                        style={{ color: "#306dca", minWidth: "120px" }}
                      >
                        Actual Quantity
                      </TableCell>
                      <TableCell
                        className="table-content-cell"
                        padding="default"
                        style={{ color: "#306dca", minWidth: "120px" }}
                      >
                        Actual Weight
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {props.pickInsertList.length > 0 ? (
                    <TableBody>
                      {props.pickInsertList.length > 0 &&
                        props.pickInsertList.map((row, index) => {
                          // const isItemSelected = isSelected(index);
                          return (
                            row.insertNo && (
                              <TableRow
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                              >
                                <TableCell
                                  padding="checkbox"
                                  className="table-content-cell"
                                >
                                  <FormControlLabel
                                    onClick={(event) =>
                                      props.handlePickInsertCkBxClick(
                                        event,
                                        row,
                                        index
                                      )
                                    }
                                    control={
                                      <Checkbox
                                        color="primary"
                                      //checked={isItemSelected}
                                      />
                                    }
                                  />
                                </TableCell>
                                <TableCell className="table-content-cell">
                                  {" "}
                                  {row.insertNo}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                  className="table-content-cell"
                                >
                                  {" "}
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.insertName}
                                  </Typography>{" "}
                                </TableCell>
                                <TableCell className="table-content-cell">
                                  {row.issueDescription &&
                                    row.issueDescription.length > 12 ? (
                                      <Tooltip
                                        title={row.issueDescription}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.issueDescription}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.issueDescription}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell className="table-content-right-cell">
                                  {" "}
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.quantity}
                                  </Typography>{" "}
                                </TableCell>
                                <TableCell className="table-content-right-cell">
                                  {" "}
                                  {row.actualWeight}
                                </TableCell>
                              </TableRow>
                            )
                          );
                        })}
                    </TableBody>
                  ) : (
                      <TableBody>
                        <TableRow
                          style={{
                            borderRight: "1px solid #eaeaea",
                            borderLeft: "1px solid #eaeaea",
                          }}
                        >
                          <TableCell colSpan="8" style={{ textAlign: "center" }}>
                            <span>No data found</span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                </Table>
              </div>
            </DialogContent>
            <DialogActions style={{ padding: "10px 24px" }}>
              <Button
                onClick={props.handleChooseICDialog}
                color="primary"
                variant="contained"
                style={{ boxShadow: "none" }}
              >
                CANCEL
              </Button>
              <Button
                disabled={
                  props &&
                  props.pickInsertList &&
                  props.pickInsertList.length === 0
                }
                //onClick={this.handlechooseWoSubmit}
                // variant="contained"
                // className="enabled-button"
                // className={
                //   Object.keys(selected).length === 0
                //     ? "disabled-button"
                //     : "enabled-button "
                // }
                onClick={props.submitPickInsert}
                color="primary"
                variant="contained"
                style={{ boxShadow: "none" }}
              >
                SUBMIT
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {/* ............................................................... */}
        <div>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={props.openCriteriaModal}
            onClose={props.handleCriteriaModalInCancel}
            maxWidth="md"
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Pick Criteria</DialogTitle>
            <DialogContent dividers>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <Autocomplete
                    options={props.criteriaList}
                    value={props.criteriaObj}
                    onChange={props.handleAutocompleteCriteria}
                    getOptionLabel={(option) => option.text}
                    style={{
                      width: "300px",
                      // marginLeft: ".5em",
                      // marginRight: ".5em"
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Criteria"
                        style={{
                          width: "100%",

                          // marginLeft: ".5em",
                          marginRight: ".5em",
                          maxHeight: "400px",
                        }}
                        // name="plannerId"
                        margin="normal"
                        required
                      />
                    )}
                  />
                </div>

                <div style={{ flex: 1 }}></div>
              </div>
              {/* {props.criteriaObj  && props.criteriaObj.value==="BYC"&& <div className={classes.search}>
                <TextField
                  autoFocus
                  margin="dense"
                  // onChange={this.handleChangeSearchWO}
                  id="name"
                  autoComplete="off"
                  placeholder="Search Country, Zone, Order File"
                  // type="search"
                  fullWidth
                  value={props.searchValZoneCountry}
                  onChange={props.setZoneCountryVal}
                />
                <div className={classes.searchIcon}>
                { !props.zoneCountrySearchClicked?<IconButton
                    aria-label="view"
                    className={classes.iconHover}
                    onClick={props.onZoneCountrySearchClicked}
                    disabled={!props.searchValZoneCountry}
                   >
                    <SearchIcon />
                  </IconButton>:
                  <IconButton  aria-label="view"
                    className={classes.iconHover}
                    onClick={props.clearZoneCountrySearchVal}>
                    <HighlightOffIcon/>
                  </IconButton>}
                </div>
              </div>} */}
              {props.criteriaObj && props.criteriaObj.value === "BYC" && (
                <div
                  style={{
                    width: "100%",
                    maxHeight: "300px",
                    overflowX: "auto",
                  }}
                >
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={"small"}
                    stickyHeader
                    aria-label="sticky table"
                    style={{ marginTop: "10px", minWidth: "700px" }}
                  >
                    {/* <div style={{ maxHeight: 350, overflow: "auto" }}>
                
              
                <Table
                  aria-labelledby="tableTitle"
                  size="small"
                  stickyHeader
                  aria-label="sticky table"
                > */}
                    <TableHead>
                      <TableRow
                        style={{
                          borderTop: "1px solid #eaeaea",
                          borderRight: "1px solid #eaeaea",

                        }}
                      >
                        <TableCell
                          style={{ borderRight: "1px solid #eaeaea" }}
                          padding="checkbox"
                          className="table-content-cell"
                        >
                          <FormControlLabel
                            onClick={(event) =>
                              props.onSelectAllZoneCountryCheckboxClicked(
                                event,

                              )
                            }
                            control={
                              <Checkbox
                                color="primary"
                              // checked={row.checkBoxVal ? true : false}
                              />
                            }
                          />
                        </TableCell>
                        <TableCell
                          className="table-content-cell"
                          padding="none"
                          style={{
                            color: "#306dca",
                            minWidth: "140px",
                            paddingRight: ".5em",
                          }}
                        >
                          <TextField
                            autoFocus
                            margin="dense"
                            // onChange={this.handleChangeSearchWO}
                            name="criteriaCountryVal"
                            id="name"
                            autoComplete="off"
                            placeholder="Search Country"
                            // type="search"
                            fullWidth
                            //   style={{marginRight:".5em"}}
                            value={props.criteriaCountryVal}
                            onChange={props.setZoneCountryVal}
                            variant="outlined"
                          />
                          <span style={{ color: "#306dca", fontSize: "16px" }}>
                            Country
                          </span>{" "}
                        </TableCell>
                        <TableCell
                          padding="default"
                          className="table-content-cell"
                        >
                          <TextField
                            autoFocus
                            margin="dense"
                            // style={{minWidth:"200px"}}
                            // onChange={this.handleChangeSearchWO}
                            id="name"
                            name="criteriaZoneVal"
                            autoComplete="off"
                            placeholder="Search Zone"
                            // type="search"
                            fullWidth
                            variant="outlined"
                            value={props.criteriaZoneVal}
                            onChange={props.setZoneCountryVal}
                          />
                          <span style={{ color: "#306dca", fontSize: "16px" }}>
                            Zone
                          </span>{" "}
                        </TableCell>
                      </TableRow>
                      {/* <TableRow>
                      <TableCell padding="checkbox" className="table-content-cell"> </TableCell>
                      <TableCell className="table-content-cell"
                        padding="none"
                        style={{ color: "#306dca", minWidth: "140px" }}
                      >
                        Country
                      </TableCell>
                      <TableCell padding="default" className="table-content-cell">
                        {" "}
                       
                      </TableCell>
                    </TableRow> */}
                    </TableHead>
                    {props.zoneCountryList &&
                      props.zoneCountryList.length > 0 ? (
                        <TableBody>
                          {fZCList && fZCList.length > 0 &&
                            fZCList.map((row, index) => {
                              // const isItemSelected = isSelected(index);
                              return (
                                <TableRow
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={index}
                                >
                                  <TableCell
                                    padding="checkbox"
                                    className="table-content-cell"
                                  >
                                    <FormControlLabel
                                      onClick={(event) =>
                                        props.onZoneCountryCheckboxClicked(
                                          event,
                                          row,
                                          index,
                                          row.countryID
                                        )
                                      }
                                      control={
                                        <Checkbox
                                          color="primary"
                                          checked={row.checkBoxVal ? true : false}
                                        />
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="none"
                                    className="table-content-cell"
                                    style={{ width: "auto" }}
                                  >
                                    {" "}
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {/* {row.countryName} */}
                                      {row.countryName && row.countryName.length > 20 ? (
                                        <Tooltip title={row.countryName} aria-label="add">
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.countryName}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.countryName}
                                          </Typography>
                                        )}
                                    </Typography>{" "}
                                  </TableCell>

                                  <TableCell className="table-content-cell">
                                    {" "}
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.zoneName}
                                    </Typography>{" "}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      ) : (props.zcLoader ? <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan="8"
                            style={{ textAlign: "center" }}
                            className="table-content-cell"
                          >
                            <LinearProgress />
                          </TableCell>
                        </TableRow>
                      </TableBody> :
                        <TableBody>
                          <TableRow>
                            <TableCell
                              colSpan="8"
                              style={{ textAlign: "center" }}
                              className="table-content-cell"
                            >
                              <span>No data found</span>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                  </Table>
                </div>
              )}
              {props.criteriaObj && props.criteriaObj.value === "BYO" && (
                <div style={{ width: "100%", overflow: "auto" }}>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={"small"}
                    aria-label="enhanced table"
                    style={{ marginTop: "10px" }}
                  >
                    <TableHead>
                      <TableRow style={{ borderTop: "1px solid #eaeaea" }}>
                        <TableCell
                          className="table-content-cell"
                          padding="none"
                          className="table-content-cell"
                        >
                          {" "}
                        </TableCell>
                        <TableCell
                          className="table-content-cell"
                          padding="none"
                          style={{ color: "#306dca", minWidth: "340px" }}
                        >
                          Order File Name
                        </TableCell>
                        <TableCell
                          padding="default"
                          className="table-content-cell"
                        >
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            Subscription
                          </span>{" "}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {props && props.criteriaModalFiles && props.criteriaModalFiles.length > 0 ? (
                      <TableBody>
                        {props.criteriaModalFiles.length > 0 &&
                          props.criteriaModalFiles.map((row, index) => {
                            // const isItemSelected = isSelected(index);
                            return (
                              <TableRow
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                              >
                                <TableCell
                                  padding="checkbox"
                                  className="table-content-cell"
                                >
                                  <FormControlLabel
                                    onClick={(event) =>
                                      props.handlePickCriteriaOFCkBxClick(
                                        event,
                                        row,
                                        index
                                      )
                                    }
                                    control={
                                      <Checkbox
                                        color="primary"
                                      //checked={isItemSelected}
                                      />
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                  className="table-content-cell"
                                  style={{ width: "auto" }}
                                >
                                  {" "}
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >

                                    {row.orderFileName && row.orderFileName.length > 25 ? (
                                      <Tooltip title={row.orderFileName} aria-label="add">
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.orderFileName}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.orderFileName}
                                        </Typography>
                                      )}
                                  </Typography>{" "}
                                </TableCell>

                                <TableCell className="table-content-cell">
                                  {" "}
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.subscription}
                                  </Typography>{" "}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    ) : (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              colSpan="8"
                              style={{ textAlign: "center" }}
                              className="table-content-cell"
                            >
                              <span>No data found</span>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                  </Table>
                </div>
              )}
            </DialogContent>
            <DialogActions style={{ padding: "10px 24px" }}>
              <Button
                onClick={props.handleCriteriaModalInCancel}
                color="primary"
                variant="contained"
                style={{ boxShadow: "none" }}
              >
                CANCEL
              </Button>
              <Button
                //  disabled={Object.keys(selected).length === 0}
                //onClick={this.handlechooseWoSubmit}
                // variant="contained"
                // className="enabled-button"
                // className={
                //   Object.keys(selected).length === 0
                //     ? "disabled-button"
                //     : "enabled-button "
                // }
                onClick={props.handleCriteriaModal}
                color="primary"
                variant="contained"
                style={{ boxShadow: "none" }}
              >
                SUBMIT
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {/* ............................................................... */}
        {/* <Button
          disabled={props.disableEdit}
          color="primary"
          variant="filled"
          style={
            !props.disableEdit
              ? {
                  color: "#fff",
                  background: "#3982ea",
                  padding: "4px",
                  margin: ".5em",
                  fontSize: "11px"
                }
              : { color: "#3982ea", fontSize: "11px" }
          }
          // onClick={e => props.handleClickButton(e, "reset")}
        >
          <SyncIcon style={{ fontSize: "18px" }} /> Reset
        </Button> */}
      </div>
    </div>
  );
};

const EnhancedTableToolbarConsolidation = (props) => {
  const classes = useToolbarStyles();
  const { batchSummaryList } = props;
  let totOF = 0
  let totO = 0
  let totC = 0

  batchSummaryList && batchSummaryList.length > 0 && batchSummaryList.map(sc => {
    totOF = totOF + sc.totalNoofOrderFiles
    totO = totO + sc.totalMLOrder + sc.totalOMOrder
    totC = totC + sc.totalMLCopies + sc.totalOMCopies
    // if (sc.subscription === "BL") {
    //   blCount = blCount + 1
    //   blCopiesCount = blCopiesCount + sc.totalCopies
    // }

  })
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: ".2em" }}>
        <div
          style={{
            width: "102px",
            padding: ".7em 1em .7em 1em",
            background: "#f2f7fd",
            marginTop: "1em",
            borderRadius: "4px",
            color: "#306dca",
            fontWeight: "500",
          }}
        >
          Batch Summary
      </div>
        {/* <div
          style={{

            padding: ".7em 1em .7em 1em",
            background: "#f2f7fd",
            marginTop: "1em",
            borderRadius: "4px",
            color: "#306dca",
            fontWeight: "500",
            display: "flex"
          }}
        >
          <span style={{ display: "flex" }}> <span className="blueBoxLabel" style={{ padding: "0px" }}>Total Order Files:</span>
            <span style={{ color: "#306dca", fontWeight: "600", marginLeft: ".5em" }}>{totOF}</span></span>
          <span style={{ display: "flex", marginLeft: "1em" }}> <span className="blueBoxLabel" style={{ padding: "0px" }}>Total Orders:</span>
            <span style={{ color: "#306dca", fontWeight: "600", marginLeft: ".5em" }}>{totO}</span></span>
          <span style={{ display: "flex", marginLeft: "1em" }}> <span className="blueBoxLabel" style={{ padding: "0px" }}>Total Copies:</span>
            <span style={{ color: "#306dca", fontWeight: "600", marginLeft: ".5em" }}>{totC}</span></span>
        </div> */}
      </div>
      <div
        style={{
          //display: "flex",
          //   width: "100%",
          border: "1px solid #eaeaea",
          borderBottom: "none",
          borderRadius: "4px",
          margin: "0 0 0 0",
        }}
      >
        <div>
          <div
            style={{
              width: "100%",
              overflow: "auto",
            }}
          >
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
            //style={{ marginTop: "10px" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    padding="default"
                    style={{ color: "#306dca" }}
                    className="table-content-cell"
                  >
                    Acc
                  </TableCell>
                  <TableCell padding="default" className="table-content-cell">
                    {" "}
                    <span style={{ color: "#306dca" }}>Vol</span>{" "}
                  </TableCell>
                  <TableCell
                    padding="default"
                    className="table-content-cell"
                    style={{ color: "#306dca" }}
                  >
                    Iss
                  </TableCell>
                  <TableCell padding="default" className="table-content-cell">
                    {" "}
                    <span style={{ color: "#306dca" }}>Supp</span>{" "}
                  </TableCell>
                  <TableCell padding="default" className="table-content-cell">
                    {" "}
                    <span style={{ color: "#306dca" }}>Type</span>{" "}
                  </TableCell>{" "}
                  <TableCell
                    padding="default"
                    className="table-content-cell"
                    style={{ color: "#306dca", minWidth: "120px" }}
                  >
                    Issue Description
                  </TableCell>{" "}
                  <TableCell
                    className="table-content-cell"
                    padding="default"
                    style={{ color: "#306dca", minWidth: "120px" }}
                  >
                    Total Order Files
                  </TableCell>
                  <TableCell
                    className="table-content-cell"
                    padding="default"
                    style={{ color: "#306dca", minWidth: "110px" }}
                  >
                    Total ML Order
                  </TableCell>
                  <TableCell
                    className="table-content-cell"
                    padding="default"
                    style={{ color: "#306dca", minWidth: "110px" }}
                  >
                    Total ML Copies
                  </TableCell>
                  <TableCell
                    className="table-content-cell"
                    padding="default"
                    style={{ color: "#306dca", minWidth: "110px" }}
                  >
                    Total OM Order
                  </TableCell>
                  <TableCell
                    className="table-content-cell"
                    padding="default"
                    style={{ color: "#306dca", minWidth: "110px" }}
                  >
                    Total OM Copies
                  </TableCell>
                  <TableCell
                    className="table-content-cell"
                    padding="default"
                    style={{ color: "#306dca", minWidth: "110px" }}
                  >
                    Total Print Run
                  </TableCell>
                  {/* <TableCell padding="default" style={{ color: "#306dca" }} className="table-content-cell">
                  Variance
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.batchSummaryList.length > 0 &&
                  props.batchSummaryList.map((row, index) => {
                    // const isItemSelected = isSelected(index);
                    let issDes = ""
                    if (row.issueDiscription) {
                      issDes = row.issueDiscription.replaceAll("+", "+ ")
                    }
                    return (
                      <TableRow role="checkbox" tabIndex={-1} key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          padding="4px"
                          className="table-content-cell"
                        >
                          {row.acronym && row.acronym.length > 16 ? (
                            <Tooltip title={row.acronym} aria-label="add">
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.acronym}
                              </Typography>
                            </Tooltip>
                          ) : (
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.acronym}
                              </Typography>
                            )}
                        </TableCell>
                        <TableCell className="table-content-cell">
                          {" "}
                          {row.volume && row.volume.length > 16 ? (
                            <Tooltip title={row.volume} aria-label="add">
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.volume}
                              </Typography>
                            </Tooltip>
                          ) : (
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.volume}
                              </Typography>
                            )}
                        </TableCell>
                        <TableCell className="table-content-cell">
                          {row.issue && row.issue.length > 16 ? (
                            <Tooltip title={row.issue} aria-label="add">
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.issue}
                              </Typography>
                            </Tooltip>
                          ) : (
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.issue}
                              </Typography>
                            )}
                        </TableCell>
                        <TableCell className="table-content-cell">
                          {" "}
                          {row.supp && row.supp.length > 16 ? (
                            <Tooltip title={row.supp} aria-label="add">
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.supp}
                              </Typography>
                            </Tooltip>
                          ) : (
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.supp}
                              </Typography>
                            )}
                        </TableCell>
                        <TableCell className="table-content-cell">
                          {" "}
                          {row.type && row.type.length > 16 ? (
                            <Tooltip title={row.type} aria-label="add">
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.type}
                              </Typography>
                            </Tooltip>
                          ) : (
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.type}
                              </Typography>
                            )}
                        </TableCell>
                        <TableCell className="table-content-cell">
                          {" "}
                          {issDes &&
                            issDes.length > 12 ? (
                              <Tooltip
                                title={issDes}
                                aria-label="add"
                              >
                                <Typography style={{ fontSize: "14px" }}>
                                  {issDes}
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography style={{ fontSize: "14px" }}>
                                {issDes}
                              </Typography>
                            )}
                        </TableCell>
                        <TableCell className="table-content-right-cell">
                          {" "}
                          {row.totalNoofOrderFiles &&
                            row.totalNoofOrderFiles.length > 16 ? (
                              <Tooltip
                                title={row.totalNoofOrderFiles}
                                aria-label="add"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.totalNoofOrderFiles}
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.totalNoofOrderFiles}
                              </Typography>
                            )}
                        </TableCell>
                        <TableCell className="table-content-right-cell">
                          {" "}
                          {row.totalMLOrder && row.totalMLOrder.length > 16 ? (
                            <Tooltip title={row.totalMLOrder} aria-label="add">
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.totalMLOrder}
                              </Typography>
                            </Tooltip>
                          ) : (
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.totalMLOrder}
                              </Typography>
                            )}
                        </TableCell>
                        <TableCell className="table-content-right-cell">
                          {" "}
                          {row.totalMLCopies &&
                            row.totalMLCopies.length > 16 ? (
                              <Tooltip title={row.totalMLCopies} aria-label="add">
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.totalMLCopies}
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.totalMLCopies}
                              </Typography>
                            )}
                        </TableCell>
                        <TableCell className="table-content-right-cell">
                          {" "}
                          {row.totalOMOrder && row.totalOMOrder.length > 16 ? (
                            <Tooltip title={row.totalOMOrder} aria-label="add">
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.totalOMOrder}
                              </Typography>
                            </Tooltip>
                          ) : (
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.totalOMOrder}
                              </Typography>
                            )}
                        </TableCell>
                        <TableCell className="table-content-right-cell">
                          {" "}
                          {row.totalOMCopies &&
                            row.totalOMCopies.length > 16 ? (
                              <Tooltip title={row.totalOMCopies} aria-label="add">
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.totalOMCopies}
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.totalOMCopies}
                              </Typography>
                            )}
                        </TableCell>
                        <TableCell className="table-content-right-cell">
                          {" "}
                          {row.totalPrintRun &&
                            row.totalPrintRun.length > 16 ? (
                              <Tooltip title={row.totalPrintRun} aria-label="add">
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.totalPrintRun}
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.totalPrintRun}
                              </Typography>
                            )}
                        </TableCell>
                        {/* <TableCell className="table-content-right-cell"> {row.balance && row.balance.length > 16 ? (
                                <Tooltip title={row.balance} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.balance}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography
                                  noWrap
                                  style={{ fontSize: "14px" }}
                                >
                                  {row.balance}
                                </Typography>
                              )}</TableCell> */}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </div>
        {/* <Button
          disabled={props.disableEdit}
          color="primary"
          variant="filled"
          style={
            !props.disableEdit
              ? {
                  color: "#fff",
                  background: "#3982ea",
                  padding: "4px",
                  margin: ".5em",
                  fontSize: "11px",
                  marginTop: "1em"
                }
              : { color: "#3982ea", fontSize: "11px", marginTop: "1em" }
          }
          // onClick={e => props.handleClickButton(e, "reset")}
        >
          Start Processing
        </Button> */}
      </div>
    </div>
  );
};
const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    width: "23%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
  stepper: {
    marginTop: "25px",
    marginBottom: "25px",
  },

  search: {
    position: "relative",
    minWidth: "500px",
    display: "flex",
  },
  searchIcon: {
    position: "absolute",
    right: "0",
  },
});

function getSteps() {
  return ["Order List", "Insert Criteria", "Consolidation & Carrier Sheet"];
}


function getStepContent(
  step,
  clearOpenList,
  list,
  loading,
  handleChooseOFDialog,
  openOFModal,
  openICModal,
  openCriteriaModal,
  handleChooseICDialog,
  handleCriteriaModal,
  handleCriteriaModalInCancel,
  customerList,
  customerObj,
  handleAutocompleteCustomer,
  handleMainOFButton,
  handleSOFButton,
  mofList,
  bucketList,
  handleAutocompletBucket,
  handleCkBxClick,
  selectedId,
  selectedSupId,
  submitOF,
  handleAutocompleteBucket,
  handleAutoOpenClick,
  onCheckboxClicked,
  selectedIdToNext,
  searchValOS,
  setOSSearchVal,
  clearOSSearchVal,
  onOSSearchClicked,
  OSFMode,
  oFSearchClicked,
  onDeleteOFClicked,
  criteriaList,
  criteriaObj,
  handleAutocompleteCriteria,
  batchDetails,
  pickInsertList,
  handlePickInsertCkBxClick,
  submitPickInsert,
  secondList,
  onSecondListCheckboxClicked,
  secondListSelectedId,
  criteriaModalFiles,
  zoneCountryList,
  onDeleteInsertClicked,
  setPickInsertearchVal,
  onPickInsertSearchClicked,
  searchValPickInsert,
  pickInsertSearchClicked,
  clearPickInsertSearchVal,
  setZoneCountryVal,
  onZoneCountrySearchClicked,
  criteriaCountryVal,
  criteriaZoneVal,
  zoneCountrySearchClicked,
  clearZoneCountrySearchVal,
  onZoneCountryCheckboxClicked,
  onSelectAllZoneCountryCheckboxClicked,
  unSubmitedPickCriteriaOF,
  unsubmitedPickCriteriaZC,
  handlePickCriteriaOFCkBxClick,
  batchSummaryList,
  handleRemoveCriteria,
  handleRemoveSuppliment,
  handleStartProcessing,
  processingList1,
  processingList2,
  spLoading,
  getSecondList,
  zcLoader
) {
  let totOF2 = 0
  let totO2 = 0
  let totC2 = 0

  batchSummaryList && batchSummaryList.length > 0 && batchSummaryList.map(sc => {
    totOF2 = totOF2 + sc.totalNoofOrderFiles
    totO2 = totO2 + sc.totalMLOrder + sc.totalOMOrder
    totC2 = totC2 + sc.totalMLCopies + sc.totalOMCopies

  })
  switch (step) {
    case 0:
      return (
        <div style={{ margin: "0px 2em 0px 2em" }}>
          <Typography
            component="div"
            className="blueBox"
            style={{ width: "auto" }}
          >
            <div className="blueBoxLabel">
              <div>Batch ID: </div>
              <div className="blueBoxText">
                {batchDetails && batchDetails.batchCode}
              </div>
            </div>
            <div className="blueBoxLabel">
              <div>Customer: </div>
              <div className="blueBoxText">
                {batchDetails && batchDetails.customerName}
              </div>
            </div>
            <div className="blueBoxLabel">
              <div>Location: </div>
              <div className="blueBoxText">
                {batchDetails && batchDetails.locationName}
              </div>
            </div>
            <div className="blueBoxLabel">
              <div>Status: </div>
              <div className="blueBoxText">
                {batchDetails && batchDetails.statusName === "Complete" ? "Completed" : batchDetails.statusName}

              </div>
            </div>
          </Typography>
          <div>
            <div
            // style={{ margin: "1em 1.2em -14px 1.5em" }}
            >
              <EnhancedTableToolbar
                handleChooseOFDialog={handleChooseOFDialog}
                openOFModal={openOFModal}
                list={list}
                handleMainOFButton={handleMainOFButton}
                handleSOFButton={handleSOFButton}
                mofList={mofList}
                handleCkBxClick={handleCkBxClick}
                selectedId={selectedId}
                selectedSupId={selectedSupId}
                submitOF={submitOF}
                handleAutocompleteBucket={handleAutocompleteBucket}
                handleAutoOpenClick={handleAutoOpenClick}
                selectedIdToNext={selectedIdToNext}
                searchValOS={searchValOS}
                setOSSearchVal={setOSSearchVal}
                clearOSSearchVal={clearOSSearchVal}
                onOSSearchClicked={onOSSearchClicked}
                OSFMode={OSFMode}
                oFSearchClicked={oFSearchClicked}
                onDeleteOFClicked={onDeleteOFClicked}
                handleRemoveSuppliment={handleRemoveSuppliment}
                handleStartProcessing={handleStartProcessing}
                processingList1={processingList1}
                processingList2={processingList2}
                spLoading={spLoading}
                batchDetails={batchDetails}
              />
            </div>
            <div
              style={
                !clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 158px)",
                    maxHeight: "calc(100vh - 300px)",
                    // margin: "0 .8em 0 .6em"
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 388px)",
                    maxHeight: "calc(100vh - 300px)",
                    // margin: "0 .8em 0 .6em"
                  }
              }
            >
              <div
              //style={{ maxHeight: 350, overflow: "auto" }}
              >
                {/* //overflow: "auto" */}
                <Table
                  aria-labelledby="tableTitle"
                  size="small"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <EnhancedTableHead />

                  {!loading ? (
                    list.length > 0 ? (
                      <TableBody style={{ overflow: "hidden" }}>
                        {list &&
                          list.length > 0 &&
                          list.map((row, index) => {
                            // const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;


                            if (
                              (row && row.mlopFileHeaderID) ||
                              (row && row.batchDetailId)
                            )
                              return (
                                <TableRow
                                  // hover
                                  // onClick={event =>
                                  //   this.handleClick(event, row.id, row)
                                  // }
                                  // selected={row.mlopFileHeaderID}

                                  id={`tableRow${index}`}
                                  role="checkbox"

                                  tabIndex={-1}
                                  key={index}

                                >
                                  <TableCell
                                    padding="checkbox"
                                    className="table-content-cell"
                                    style={{ borderLeft: "1px solid #eaeaea" }}
                                  >
                                    <FormControlLabel
                                      onChange={(e) =>
                                        onCheckboxClicked(e, row, index)
                                      }
                                      control={
                                        <CustomCheckbox
                                          color="primary"
                                          checked={
                                            row.checkBoxVal ? true : false
                                          }
                                        />
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    // className="table-content-cell"
                                    style={{
                                      maxWidth: "450px",
                                      borderRight: "1px solid #eaeaea",
                                      padding: "0.5em",
                                    }}
                                  >
                                    {row.orderFileName &&
                                      row.orderFileName.length > 16 ? (
                                        <Tooltip
                                          title={row.orderFileName}
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.orderFileName}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.orderFileName}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    // align="left"
                                    className="table-content-cell"
                                  >
                                    {row.receivedDate &&
                                      row.receivedDate.length > 16 ? (
                                        <Tooltip
                                          title={
                                            row.receivedDate
                                              ? moment(row.receivedDate).format(
                                                "DD/MM/YYYY HH:mm:ss"
                                              )
                                              : ""
                                          }
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.receivedDate
                                              ? moment(row.receivedDate).format(
                                                "DD/MM/YYYY HH:mm:ss"
                                              )
                                              : ""}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.receivedDate
                                            ? moment(row.receivedDate).format(
                                              "DD/MM/YYYY HH:mm:ss"
                                            )
                                            : ""}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    {row.issueDescription &&
                                      row.issueDescription.length > 12 ? (
                                        <Tooltip
                                          title={row.issueDescription}
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.issueDescription}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.issueDescription}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  {/* <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              {row.splInstruction && row.splInstruction.length > 16 ? (
                                <Tooltip title={row.splInstruction} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.splInstruction}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography
                                  noWrap
                                  style={{ fontSize: "14px" }}
                                >
                                  {row.splInstruction}
                                </Typography>
                              )}
                            </TableCell> */}

                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    {row.subscription &&
                                      row.subscription.length > 16 ? (
                                        <Tooltip
                                          title={row.subscription}
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.subscription}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.subscription}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                  >
                                    {row.totalOrders &&
                                      row.totalOrders.length > 16 ? (
                                        <Tooltip
                                          title={row.totalOrders}
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.totalOrders}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.totalOrders}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                    style={{
                                      maxWidth: "250px!important",
                                      borderRight: "1px solid #eaeaea",
                                      paddingLeft: "0.5em!important",
                                    }}
                                  >
                                    {row.totalCopies &&
                                      row.totalCopies.length > 16 ? (
                                        <Tooltip
                                          title={row.totalCopies}
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.totalCopies}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.totalCopies}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="none"
                                    //align="left"
                                    className="table-content-cell"
                                    style={{ minWidth: "125px" }}
                                  >
                                    {batchDetails.customerCode === "Wiley" ? <div
                                      style={{
                                        marginTop: "-20px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      <Autocomplete
                                        options={bucketList}
                                        value={row.bucketObj}
                                        onOpen={(e) =>
                                          handleAutoOpenClick(index)
                                        }
                                        onChange={handleAutocompleteBucket}
                                        getOptionLabel={(option) => option.text}
                                        // style={{
                                        //   width: "100%",
                                        //   marginLeft: ".5em",
                                        //   marginRight: ".5em"
                                        // }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            style={{
                                              width: "100%",
                                              fontSize: "11px !important",
                                              marginLeft: "0px",
                                              marginRight: ".5em",
                                              maxHeight: "400px",
                                            }}
                                            // name="plannerId"
                                            margin="normal"
                                            required
                                          />
                                        )}
                                      />
                                    </div> : row.bucketObj.text}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    {row.supplimentFile1 &&
                                      row.supplimentFile1.length > 16 ? (
                                        <Tooltip
                                          title={row.supplimentFile1}
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.supplimentFile1}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.supplimentFile1}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.supplimentFile2 &&
                                        row.supplimentFile2.length > 16 ? (
                                          <Tooltip
                                            title={row.supplimentFile2}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.supplimentFile2}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.supplimentFile2}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      <Tooltip title="Delete">
                                        <DeleteIcon
                                          style={{
                                            cursor: "pointer",
                                            color: "#6c6b6b",
                                          }}
                                          onClick={(e) =>
                                            onDeleteOFClicked(e, index)
                                          }
                                        />
                                      </Tooltip>
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              );
                          })}
                      </TableBody>
                    ) : (
                        <TableBody>
                          <TableRow
                            style={{
                              borderRight: "1px solid #eaeaea",
                              borderLeft: "1px solid #eaeaea",
                            }}
                          >
                            <TableCell
                              colSpan="11"
                              style={{
                                textAlign: "center",
                                borderRight: "1px solid #eaeaea",
                                borderLeft: "1px solid #eaeaea",
                              }}
                            >
                              No data found
                          </TableCell>
                          </TableRow>
                        </TableBody>
                      )
                  ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan="9">
                            <LinearProgress />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                </Table>
              </div>
            </div>
          </div>
        </div>
      );
    case 1:
      return (
        <div style={{ margin: "0px 2em 0px 2em" }}>
          <Typography
            component="div"
            className="blueBox"
            style={{ width: "auto" }}
          >
            <div className="blueBoxLabel">
              <div>Batch ID: </div>
              <div className="blueBoxText">
                {batchDetails && batchDetails.batchCode}
              </div>
            </div>
            <div className="blueBoxLabel">
              <div>Customer: </div>
              <div className="blueBoxText">
                {batchDetails && batchDetails.customerName}
              </div>
            </div>
            <div className="blueBoxLabel">
              <div>Location: </div>
              <div className="blueBoxText">
                {batchDetails && batchDetails.locationName}
              </div>
            </div>
            <div className="blueBoxLabel">
              <div>Status: </div>
              <div className="blueBoxText">
                {batchDetails && batchDetails.statusName === "Complete" ? "Completed" : batchDetails.statusName}

              </div>
            </div>
          </Typography>
          <div>
            <div
            // style={{ margin: "1em 1.2em -14px 1.5em" }}
            >
              <EnhancedTableToolbarInsertCriteria
                handleChooseICDialog={handleChooseICDialog}
                handleCriteriaModal={handleCriteriaModal}
                handleCriteriaModalInCancel={handleCriteriaModalInCancel}
                openOFModal={openOFModal}
                openICModal={openICModal}
                openCriteriaModal={openCriteriaModal}
                list={list}
                customerList={customerList}
                customerObj={customerObj}
                handleAutocompleteCustomer={handleAutocompleteCustomer}
                criteriaList={criteriaList}
                criteriaObj={criteriaObj}
                handleAutocompleteCriteria={handleAutocompleteCriteria}
                batchDetails={batchDetails}
                pickInsertList={pickInsertList}
                handlePickInsertCkBxClick={handlePickInsertCkBxClick}
                submitPickInsert={submitPickInsert}
                secondList={secondList}
                onSecondListCheckboxClicked={onSecondListCheckboxClicked}
                secondListSelectedId={secondListSelectedId}
                criteriaModalFiles={criteriaModalFiles}
                zoneCountryList={zoneCountryList}
                onDeleteInsertClicked={onDeleteInsertClicked}
                setPickInsertearchVal={setPickInsertearchVal}
                onPickInsertSearchClicked={onPickInsertSearchClicked}
                searchValPickInsert={searchValPickInsert}
                pickInsertSearchClicked={pickInsertSearchClicked}
                clearPickInsertSearchVal={clearPickInsertSearchVal}
                setZoneCountryVal={setZoneCountryVal}
                onZoneCountrySearchClicked={onZoneCountrySearchClicked}
                criteriaCountryVal={criteriaCountryVal}
                criteriaZoneVal={criteriaZoneVal}
                zoneCountrySearchClicked={zoneCountrySearchClicked}
                clearZoneCountrySearchVal={clearZoneCountrySearchVal}
                onZoneCountryCheckboxClicked={onZoneCountryCheckboxClicked}
                onSelectAllZoneCountryCheckboxClicked={onSelectAllZoneCountryCheckboxClicked}
                unSubmitedPickCriteriaOF={unSubmitedPickCriteriaOF}
                unsubmitedPickCriteriaZC={unsubmitedPickCriteriaZC}
                handlePickCriteriaOFCkBxClick={handlePickCriteriaOFCkBxClick}
                batchSummaryList={batchSummaryList}
                handleRemoveCriteria={handleRemoveCriteria}
                zcLoader={zcLoader}
              />
            </div>
            <div
              style={
                !clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 158px)",
                    // maxHeight: "calc(100vh - 300px)"
                    // margin: "0 .8em 0 .6em"
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 388px)",
                    //maxHeight: "calc(100vh - 300px)"
                    // margin: "0 .8em 0 .6em"
                  }
              }
            >
              <div
              // style={{ maxHeight: 350,
              //  // overflow: "auto"
              //    }}
              >
                {/* //overflow: "auto" */}
                <Table
                  aria-labelledby="tableTitle"
                  size="small"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <EnhancedTableHeadInsertCriteria secondList={secondList} />

                  {!loading ? (
                    secondList && secondList.length > 0 ? (
                      <TableBody style={{ overflow: "hidden" }}>
                        {secondList &&
                          secondList.length > 0 &&
                          secondList.map((row, index) => {
                            // const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              // row.batchInsertHeaderID &&<TableRow
                              <TableRow
                                // hover
                                // onClick={event =>
                                //   this.handleClick(event, row.id, row)
                                // }
                                id={`tableRow${index}`}
                                role="checkbox"
                                //  aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={index}
                              //   selected={isItemSelected}
                              >
                                <TableCell
                                  padding="checkbox"
                                  style={{ borderLeft: "1px solid #eaeaea" }}
                                  className="table-content-cell"
                                >
                                  <FormControlLabel
                                    control={
                                      <CustomCheckbox
                                        color="primary"
                                        checked={row.checkBoxVal ? true : false}
                                        //  name={ `${row.checkBoxVal}${index}` }
                                        onChange={(e) =>
                                          onSecondListCheckboxClicked(
                                            e,
                                            row,
                                            index
                                          )
                                        }
                                      />
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  {row.issueDescription &&
                                    row.issueDescription.length > 12 ? (
                                      <Tooltip
                                        title={row.issueDescription}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.issueDescription}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.issueDescription}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    {row.insertNo &&
                                      row.insertNo.length > 16 ? (
                                        <Tooltip
                                          title={row.insertNo}
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.insertNo}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.insertNo}
                                        </Typography>
                                      )}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  {row.insertName &&
                                    row.insertName.length > 16 ? (
                                      <Tooltip
                                        title={row.insertName}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.insertName}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.insertName}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-right-cell"
                                >
                                  {row.quantity && row.quantity.length > 16 ? (
                                    <Tooltip
                                      title={row.quantity}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.quantity}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.quantity}
                                      </Typography>
                                    )}
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    {row.criteriaName && row.criteriaName}
                                  </Typography>
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    <Tooltip title="Delete">
                                      <DeleteIcon
                                        style={{
                                          cursor: "pointer",
                                          color: "#6c6b6b",
                                        }}
                                        onClick={(e) =>
                                          onDeleteInsertClicked(e, index)
                                        }
                                      />
                                    </Tooltip>
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    ) : (
                        <TableBody>
                          <TableRow
                            style={{
                              borderRight: "1px solid #eaeaea",
                              borderLeft: "1px solid #eaeaea",
                            }}
                          >
                            <TableCell
                              colSpan="7"
                              style={{
                                textAlign: "center",
                                borderRight: "1px solid #eaeaea",
                                borderLeft: "1px solid #eaeaea",
                              }}
                            >
                              No data found
                          </TableCell>
                          </TableRow>
                        </TableBody>
                      )
                  ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan="9">
                            <LinearProgress />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                </Table>
              </div>
            </div>
          </div>
        </div>
      );
    case 2:
      return (
        <div style={{ margin: "0px 2em 0px 2em" }}>
          <Typography
            component="div"
            className="blueBox"
            style={{ width: "auto" }}
          >
            <div className="blueBoxLabel">
              <div>Batch ID: </div>
              <div className="blueBoxText">
                {batchDetails && batchDetails.batchCode}
              </div>
            </div>
            <div className="blueBoxLabel">
              <div>Customer: </div>
              <div className="blueBoxText">
                {batchDetails && batchDetails.customerName}
              </div>
            </div>
            <div className="blueBoxLabel">
              <div>Location: </div>
              <div className="blueBoxText">
                {batchDetails && batchDetails.locationName}
              </div>
            </div>
            <div className="blueBoxLabel">
              <div>Status: </div>
              <div className="blueBoxText">
                {batchDetails && batchDetails.statusName === "Complete" ? "Completed" : batchDetails.statusName}

              </div>
            </div>
          </Typography>
          <div>
            {/* {batchDetails &&
              batchDetails.statusName &&
              batchDetails.statusName === "Complete" && ( */}
            <div style={{ marginTop: "1em" }}>
              <Typography
                component="div"
                className="blueBox"
                style={{ width: "auto" }}
              >
                <div className="blueBoxLabel">
                  <div>Processed Date: </div>
                  <div className="blueBoxText">
                    {batchDetails &&
                      batchDetails.processedDate &&
                      moment(batchDetails.processedDate).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                  </div>
                </div>
                <div className="blueBoxLabel">
                  <div>Remarks: </div>
                  <div className="blueBoxText">
                    {batchDetails && batchDetails.remarks}
                  </div>
                </div>
              </Typography>
            </div>
            {/* )} */}

            <div
              style={
                !clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 180px)",
                    //maxHeight: "calc(100vh - 300px)"
                    // margin: "0 .8em 0 .6em"
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 400px)",
                    //maxHeight: "calc(100vh - 300px)"
                    // margin: "0 .8em 0 .6em"
                  }
              }
            >
              <div
              // style={{ margin: "1em 1.2em -14px 1.5em" }}
              >
                <EnhancedTableToolbarConsolidation
                  handleChooseICDialog={handleChooseICDialog}
                  handleCriteriaModal={handleCriteriaModal}
                  handleCriteriaModalInCancel={handleCriteriaModalInCancel}
                  openOFModal={openOFModal}
                  openICModal={openICModal}
                  openCriteriaModal={openCriteriaModal}
                  list={list}
                  criteriaList={criteriaList}
                  criteriaObj={criteriaObj}
                  handleAutocompleteCriteria={handleAutocompleteCriteria}
                  batchDetails={batchDetails}
                  pickInsertList={pickInsertList}
                  handlePickInsertCkBxClick={handlePickInsertCkBxClick}
                  submitPickInsert={submitPickInsert}
                  secondList={secondList}
                  onSecondListCheckboxClicked={onSecondListCheckboxClicked}
                  secondListSelectedId={secondListSelectedId}
                  criteriaModalFiles={criteriaModalFiles}
                  zoneCountryList={zoneCountryList}
                  onDeleteInsertClicked={onDeleteInsertClicked}
                  setPickInsertearchVal={setPickInsertearchVal}
                  onPickInsertSearchClicked={onPickInsertSearchClicked}
                  searchValPickInsert={searchValPickInsert}
                  pickInsertSearchClicked={pickInsertSearchClicked}
                  clearPickInsertSearchVal={clearPickInsertSearchVal}
                  setZoneCountryVal={setZoneCountryVal}
                  onZoneCountrySearchClicked={onZoneCountrySearchClicked}
                  criteriaCountryVal={criteriaCountryVal}
                  criteriaZoneVal={criteriaZoneVal}
                  zoneCountrySearchClicked={zoneCountrySearchClicked}
                  clearZoneCountrySearchVal={clearZoneCountrySearchVal}
                  onZoneCountryCheckboxClicked={onZoneCountryCheckboxClicked}
                  onSelectAllZoneCountryCheckboxClicked={onSelectAllZoneCountryCheckboxClicked}
                  unSubmitedPickCriteriaOF={unSubmitedPickCriteriaOF}
                  unsubmitedPickCriteriaZC={unsubmitedPickCriteriaZC}
                  handlePickCriteriaOFCkBxClick={handlePickCriteriaOFCkBxClick}
                  batchSummaryList={batchSummaryList}
                  handleRemoveCriteria={handleRemoveCriteria}
                  zcLoader={zcLoader}
                />
              </div>
              <div
                style={{
                  border: "1px solid #eaeaea",
                  borderRadius: "4px",
                  padding: ".5em",
                  marginTop: "1.5em",
                  //borderBottom: "none"
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <Button
                  //disabled={props.disableEdit}
                  color="primary"
                  variant="contained"
                  disabled={batchDetails.statusName === "Complete"}
                  // style={{
                  //   color: "#fff",
                  //   background: "#3982ea",
                  //   padding: "4px",
                  //   // margin: ".5em",
                  //   fontSize: "11px"
                  // }}
                  style={{ boxShadow: "none" }}
                  onClick={(e) => handleStartProcessing(e)}
                >
                  Start Processing
                </Button>
                <div
                  style={{

                    padding: ".5em 1em .5em 1em",
                    background: "#f2f7fd",
                    // marginTop: "1em",
                    borderRadius: "4px",
                    color: "#306dca",
                    fontWeight: "500",
                    display: "flex"
                  }}
                >
                  <span style={{ display: "flex" }}> <span className="blueBoxLabel" style={{ padding: "0px" }}>Total Order Files:</span>
                    <span style={{ color: "#306dca", fontWeight: "600", marginLeft: ".5em" }}>{totOF2}</span></span>
                  <span style={{ display: "flex", marginLeft: "1em" }}> <span className="blueBoxLabel" style={{ padding: "0px" }}>Total Orders:</span>
                    <span style={{ color: "#306dca", fontWeight: "600", marginLeft: ".5em" }}>{totO2}</span></span>
                  <span style={{ display: "flex", marginLeft: "1em" }}> <span className="blueBoxLabel" style={{ padding: "0px" }}>Total Copies:</span>
                    <span style={{ color: "#306dca", fontWeight: "600", marginLeft: ".5em" }}>{totC2}</span></span>
                </div>
              </div>
              {batchDetails.statusName === "Failed" && <div
                style={{
                  border: "1px solid #eaeaea",
                  borderRadius: "4px",
                  padding: ".5em",
                  marginTop: "1.5em",
                  color: "red"
                }}
              >Routing Matrix Exception Details </div>}
              <div
                style={{ maxHeight: 350, overflow: "auto", marginTop: "1em" }}
              >
                {/* //overflow: "auto" */}
                {processingList2 && processingList2.length > 0 && (
                  <Table
                    aria-labelledby="tableTitle"
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    {batchDetails.statusName === "Failed" ? (
                      <EnhancedTableHeadConsolidationFailed />
                    ) : (
                        <EnhancedTableHeadConsolidation />
                      )}

                    {!loading ? (
                      batchDetails.statusName !== "Failed" ? (
                        <TableBody style={{ overflow: "hidden" }}>
                          {processingList2.map((row, index) => {
                            // const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                // hover
                                // onClick={event =>
                                //   this.handleClick(event, row.id, row)
                                // }
                                id={`tableRow${index}`}
                                role="checkbox"
                                //  aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={index}
                              //   selected={isItemSelected}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ borderLeft: "1px solid #eaeaea" }}
                                >
                                  <Typography
                                    noWrap
                                    style={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    {index + 1}
                                  </Typography>
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ borderLeft: "1px solid #eaeaea" }}
                                >
                                  <Typography
                                    noWrap
                                    style={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    {row.bucket}
                                  </Typography>
                                </TableCell>
                                {/* <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography
                                noWrap
                                style={{
                                  fontSize: "14px"
                                }}
                              >
                                {row.consolidationType}
                              </Typography>
                            </TableCell> */}

                                {/* <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-right-cell"
                            >
                              <Typography
                                noWrap
                                style={{
                                  fontSize: "14px"
                                }}
                              >
                                {row.totalWeight}
                              </Typography>
                            </TableCell> */}

                                {/* <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography
                                noWrap
                                style={{
                                  fontSize: "14px"
                                }}
                              >
                                {row.subscription}
                              </Typography>
                            </TableCell> */}

                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  style={{
                                    maxWidth: "450px",
                                    borderRight: "1px solid #eaeaea",
                                    padding: "0.5em",
                                  }}
                                >
                                  {row.carrierSheetFileName &&
                                    row.carrierSheetFileName.length > 16 ? (
                                      <Tooltip
                                        title={row.carrierSheetFileName}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.carrierSheetFileName}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.carrierSheetFileName}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.destination}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.singleMultipe}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.issueDescription &&
                                      row.issueDescription.length > 12 ? (
                                        <Tooltip
                                          title={row.issueDescription}
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.issueDescription}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.issueDescription}
                                        </Typography>
                                      )}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-right-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.totalOrder}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-right-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    {row.totalCopies}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-right-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.totalJournalWeight}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-right-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.totalSupplimentOneWeight}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-right-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.totalSupplimentTwoWeight}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-right-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.totalInsertWeight}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-right-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.totalOrderweight}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      ) : (
                          <TableBody style={{ overflow: "hidden" }}>
                            {processingList2.map((row, index) => {
                              // const isItemSelected = isSelected(row.id);
                              const labelId = `enhanced-table-checkbox-${index}`;

                              return (
                                <TableRow
                                  // hover
                                  // onClick={event =>
                                  //   this.handleClick(event, row.id, row)
                                  // }
                                  id={`tableRow${index}`}
                                  role="checkbox"
                                  //  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={index}
                                //   selected={isItemSelected}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={{ borderLeft: "1px solid #eaeaea" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {index + 1}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                    style={{ borderLeft: "1px solid #eaeaea" }}
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.customerCode}
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.locationCode}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.shipToCustomer}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.country}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.acronym}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.isPeriodical ? "Yes" : "No"}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    {row.issueDescription &&
                                      row.issueDescription.length > 12 ? (
                                        <Tooltip
                                          title={row.issueDescription}
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.issueDescription}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.issueDescription}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.consolidationType}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.priorityCode}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalOrder}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalCopies}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    id={labelId}
                                    padding="default"
                                    align="left"
                                    className="table-content-right-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalOrderweight}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        )
                    ) : (
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan="9">
                              <LinearProgress />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                  </Table>
                )}

              </div>
              {spLoading && <LinearProgress />}

              {/* <div style={{ maxHeight: 350, overflow: "auto", marginTop:"1em"}}>
               
                {processingList1 && processingList1.length>0 &&  <Table
                  aria-labelledby="tableTitle"
                  size="small"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <EnhancedTableHeadConsolidationLast />

                  {!loading ? (
                    <TableBody style={{ overflow: "hidden" }}>
                      {processingList1.map((row, index) => {
                      
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                        
                            id={`tableRow${index}`}
                            role="checkbox"
                           
                            tabIndex={-1}
                            key={index}
                           
                          >
                       
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                              style={{ borderLeft: "1px solid #eaeaea", width:"130px" }}
                            >
                              <Typography
                                noWrap
                                style={{
                                  fontSize: "14px"
                                }}
                              >
                                {row.customerName}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                              style={{width:"130px"}}
                            >
                              <Typography
                                noWrap
                                style={{
                                  fontSize: "14px"
                                }}
                              >
                                {row.location}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography
                                noWrap
                                style={{
                                  fontSize: "14px"
                                }}
                              >
                                {row.batchCode}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography
                                noWrap
                                style={{
                                  fontSize: "14px"
                                }}
                              >
                                {row.processedDate ?moment(row.processedDate).format("DD/MM/YYYY HH:mm:ss"):""}
                              </Typography>
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography
                                noWrap
                                style={{
                                  fontSize: "14px"
                                }}
                              >
                                {row.status}
                              </Typography>
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.remarks}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan="9">
                          <LinearProgress />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>}
              </div> */}
            </div>
          </div>
        </div>
      );

    default:
      return "Unknown step";
  }
}

function HorizontalLinearStepper(props) {
  const classes = styles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const isStepOptional = (step) => {
    //return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  let validateNextForStpOne = true
  props.secondList && props.secondList.length > 0 && props.secondList.map(ps => {
    if (!ps.criteriaID)
      validateNextForStpOne = false
  })
  const handleNext = () => {
    // console.log(props.batchDetails)
    let newSkipped = skipped;
    //if(activeStep===-1)
    let mhId = "";
    if (process.browser) mhId = window.location.pathname.split("/")[4];
    let cId = "";
    let lId = "";
    if (process.browser) {
      cId = window.location.pathname.split("/")[2];
      lId = window.location.pathname.split("/")[3];
    }
    if (activeStep === 0) {
      let loggedInId = localStorage.getItem("loggedInId");
      let reqBody = {};
      reqBody.mlBatchHeaderID = +mhId;
      reqBody.customerID = cId;
      //this.state.customerId
      reqBody.locationID = lId;
      //this.state.locationId
      reqBody.isActive = true;
      reqBody.createdBy = loggedInId;
      reqBody.preProcessDetailList = [];
      props.list.length > 0 &&
        props.list.map((s) => {
          //  console.log(s)
          s &&
            reqBody.preProcessDetailList.push({
              mlBatchDetailID: s.batchDetailId,
              mlBatchHeaderID: +mhId,
              mainOrderFileHeaderID: s.mlopFileHeaderID,
              journalID: s.journalID,
              subscriptionID: s.subscriptionID,
              totalOrder: s.totalOrders,
              totalCopies: s.totalCopies,
              bucketID: s.bucketId,
              supplementOneOrderFileHeaderID: s.supplimentId1
                ? s.supplimentId1
                : null,
              supplementTwoOrderFileHeaderID: s.supplimentId2
                ? s.supplimentId2
                : null,
              isActive: true,
              createdBy: loggedInId,
              cpSearchVal: "",
              searchClicked: false,
            });
        });
      if (needApiCallInStepOne)
        postOrderDetails(reqBody).then((r) => {
          // props.getSecondList()
          needApiCallInStepOne = false
          // if (props && props.batchDetails && props.batchDetails.customerCode && props.batchDetails.customerCode !== "Wiley")
          props.callBatchSummaryList();
        });
      else {
        props.callBatchSummaryList();

      }

    }
    if (activeStep === 1) {
      // props.getSecondList();
      let loggedInId = localStorage.getItem("loggedInId");
      let reqBody = [];
      // reqBody.mlBatchHeaderID = 0;
      // console.log(props.secondList)
      props.secondList &&
        props.secondList.length > 0 &&
        props.secondList.map((s) => {
          s.insertMasterID &&
            reqBody.push({
              batchInsertHeaderID: s.batchInsertHeaderID,
              mlBatchHeaderID: +mhId,
              insertMasterID: s.insertMasterID ? s.insertMasterID : 0,
              journalID: s.journalID ? s.journalID : 0,
              //  issueDescription: s.issueDescription,
              criteriaID: s.criteriaID ? s.criteriaID : null,
              createdBy: loggedInId,
            });
        });
      if (needApiCallInStepTwo)
        props.callPostNextInsert(reqBody);
      // props.callBatchSummaryList();

      ///..//......................................................................
    }
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (props && props.batchDetails && props.batchDetails.customerCode && props.batchDetails.customerCode === "Wiley")
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    else
      setActiveStep((prevActiveStep) => prevActiveStep + 2);
    setSkipped(newSkipped);
  };


  const handleBack = () => {
    if (props && props.batchDetails && props.batchDetails.customerCode && props.batchDetails.customerCode === "Wiley")
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    else
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button
              onClick={handleReset}
              className={classes.button}
            // clearOpenList={clearOpenList}
            >
              Reset
            </Button>
          </div>
        ) : (
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(
                  activeStep,
                  props.clearOpenList,
                  props.list,
                  props.loading,
                  props.handleChooseOFDialog,
                  props.openOFModal,
                  props.openICModal,
                  props.openCriteriaModal,
                  props.handleChooseICDialog,
                  props.handleCriteriaModal,
                  props.handleCriteriaModalInCancel,
                  props.customerList,
                  props.customerObj,
                  props.handleAutocompleteCustomer,
                  props.handleMainOFButton,
                  props.handleSOFButton,
                  props.mofList,
                  props.bucketList,
                  props.handleAutocompletBucket,
                  props.handleCkBxClick,
                  props.selectedId,
                  props.selectedSupId,
                  props.submitOF,
                  props.handleAutocompleteBucket,
                  props.handleAutoOpenClick,
                  props.onCheckboxClicked,
                  props.selectedIdToNext,
                  props.searchValOS,
                  props.setOSSearchVal,
                  props.clearOSSearchVal,
                  props.onOSSearchClicked,
                  props.OSFMode,
                  props.oFSearchClicked,
                  props.onDeleteOFClicked,
                  props.criteriaList,
                  props.criteriaObj,
                  props.handleAutocompleteCriteria,
                  props.batchDetails,
                  props.pickInsertList,
                  props.handlePickInsertCkBxClick,
                  props.submitPickInsert,
                  props.secondList,
                  props.onSecondListCheckboxClicked,
                  props.secondListSelectedId,
                  props.criteriaModalFiles,
                  props.zoneCountryList,
                  props.onDeleteInsertClicked,
                  props.setPickInsertearchVal,
                  props.onPickInsertSearchClicked,
                  props.searchValPickInsert,
                  props.pickInsertSearchClicked,
                  props.clearPickInsertSearchVal,
                  props.setZoneCountryVal,
                  props.onZoneCountrySearchClicked,
                  props.criteriaCountryVal,
                  props.criteriaZoneVal,
                  props.zoneCountrySearchClicked,
                  props.clearZoneCountrySearchVal,
                  props.onZoneCountryCheckboxClicked,
                  props.onSelectAllZoneCountryCheckboxClicked,
                  props.unSubmitedPickCriteriaOF,
                  props.unsubmitedPickCriteriaZC,
                  props.handlePickCriteriaOFCkBxClick,
                  props.batchSummaryList,
                  props.handleRemoveCriteria,
                  props.handleRemoveSuppliment,
                  props.handleStartProcessing,
                  props.processingList1,
                  props.processingList2,
                  props.spLoading,
                  props.getSecondList,
                  props.zcLoader
                )}
              </Typography>
              {/* <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button> */}
              {/* {isStepOptional(activeStep) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Skip
                </Button>
              )} */}

              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div> */}
              <div style={{ margin: "0px 1.8em 0px 1.8em" }}>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      //disabled={activeStep === 0}
                      //onClick={handleBack}
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        props.history.push("/pubMainLabelBatchPlacement")
                      }
                    >
                      {activeStep === 2 &&
                        props.batchDetails.statusName === "Complete"
                        ? "GOTO LISTING"
                        : "CANCEL"}
                      {/* CANCEL */}
                    </Button>
                  </div>
                  {activeStep === 2 &&
                    props.batchDetails.statusName === "Complete"
                    ? ""
                    : activeStep !== 0 && (
                      <div style={{ marginLeft: "1em" }}>
                        <Button
                          variant="contained"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          style={{ boxShadow: "none" }}
                          color="primary"
                        // onClick={e =>
                        //   props.history.push("/pubMainLabelBatchPlacement")
                        // }
                        >
                          BACK
                        </Button>
                      </div>
                    )}
                  {activeStep !== steps.length - 1 && (
                    <div style={{ marginLeft: "1em" }}>
                      <Button
                        // disabled={!this.state.formValid}
                        onClick={handleNext}
                        color="primary"
                        disabled={
                          activeStep === 0
                            ? props.list && props.list.length === 0
                            : activeStep === 1
                              ? !validateNextForStpOne :
                              false
                          // : false
                        }
                        // autoFocus
                        variant="contained"
                        style={{ boxShadow: "none" }}
                      // onClick={this.handleSubmit}
                      // className={
                      //   !this.state.formValid ? "disabled-button" : "enabled-button"
                      // }
                      >
                        {"Next"}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

HorizontalLinearStepper.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

class EditBatchProcess extends React.Component {
  state = {
    list: [],
    loading: false,
    openOFModal: false,
    openICModal: false,
    openCriteriaModal: false,
    customerList: [],
    customerObj: {},
    customerId: "",
    locationId: "",
    locationObj: {},
    locationList: [],
    mofList: [],
    bucketList: [],
    selectedId: [],
    unSubmitedList: [],
    batchDetails: {},
    listToNext: [],
    selectedIdToNext: [],
    searchValOS: "",
    OSFMode: "MOF",
    oFSearchClicked: false,
    unSubmitedSupList: [],
    selectedSupId: [],
    criteriaID: "",
    criteriaObj: {},
    criteriaList: [],
    secondList: [],
    pickInsertList: [],
    unSubmitedInsertList: [],
    listToAddCriteria: [],
    secondListSelectedId: [],
    zoneCountryList: [],
    zcLoader: true,
    criteriaModalFiles: [],
    searchValPickInsert: "",
    pickInsertSearchClicked: false,
    criteriaCountryVal: "",
    criteriaZoneVal: "",
    zoneCountrySearchClicked: false,
    unSubmitedPickCriteriaOF: [],
    unsubmitedPickCriteriaZC: [],
    batchSummaryList: [],
    processingList1: [],
    processingList2: [],
    spLoading: false,
    initialZoneCountryList: [],
    selectedIndexForSupplement: "",
    selectedSuppIndexForSupplement: ""
  };
  componentDidMount() {
    let mhId = "";
    if (process.browser) mhId = window.location.pathname.split("/")[4];
    this.props.handleSettingHeader("Main Label Batch Processing");
    this.props.setSelectedList(
      "publisherMainLabelOpen",
      "pubMainLabelBatchPlacement"
    );
    this.props.handleDrawerClose();
    getPublisherCustomerList().then((r) => {
      this.getAllCustomer(r.data);
    });
    getLocationDropdownList().then((r) => {
      this.getAllLocation(r.data);
    });
    getBucketsDD().then((r) => {
      this.setState({ bucketList: r.data }, () => {
        this.getInitialOrderList()
      });
    });
    getbatchDetails(mhId).then((r) => {
      this.setState({ batchDetails: r.data }, () => {
        this.getListOF();
      });
      if (r.data && r.data.statusName === "Complete")
        getprocessingList1(mhId).then((r) => {
          this.setState({ processingList2: r.data });
        });
      else
        if (r.data && r.data.statusName === "Failed")
          getprocessingList3(mhId).then((r) => {
            this.setState({ processingList2: r.data });
          });
      // else
      // if (r.data && r.data.statusName === "Failed")
      //   getprocessingList2(mhId).then((r) => {
      //     this.setState({ processingList2: r.data });
      //   });
    });
    getcriteriaDD().then((r) => {
      this.setState({
        criteriaList: r.data,
        criteriaObj:
          r.data &&
          r.data.length > 0 &&
          r.data.filter((d) => d.value === "ALL")[0],
        //made chenges......................................................................................
        // cruteriaID:
        criteriaID:
          r.data &&
          r.data.length > 0 &&
          r.data.filter((d) => d.value === "ALL")[0].id,
      });
    });
    //this.getSecondList()
    // getprocessingList1(mhId).then(r=>{
    //     this.setState({processingList1:r.data})
    //   })
    //modified for edit readyBatchProcessing call
    // getprocessingList2(mhId).then(r=>{
    //   this.setState({processingList2:r.data})
    // })
    getSecondListForView(mhId).then((r) => {
      let newList = [];
      r &&
        r.data &&
        r.data.length > 0 &&
        r.data.map((d) => {
          newList.push({
            actualWeight: d.actualWeight && d.actualWeight,
            insertMasterID: d.insertMasterID,
            insertName: d.insertName,
            insertNo: d.insertNo,
            issueDescription: d.issueDescription,
            journalID: d.journalID,
            quantity: d.quantity,
            criteriaName: d.criteriaName,
            criteriaID: d.criteriaID,
            batchInsertHeaderID: d.batchInsertHeaderID
          });
        });
      this.setState({ secondList: newList });
    });
  }
  callBatchSummaryList = () => {
    let mhId = "";
    if (process.browser) mhId = window.location.pathname.split("/")[4];
    getBatchSummaryList(mhId).then((x) => {
      this.setState({ batchSummaryList: x.data });
    });
    // if (this.state.batchDetails.statusName === "Failed")
    //   getprocessingList3(mhId).then((r) => {
    //     this.setState({ processingList2: r.data });
    //   });
  };
  callPostNextInsert = (rBody) => {
    let mhId = "";
    if (process.browser) mhId = window.location.pathname.split("/")[4];
    rBody.length > 0 &&
      postNextInsert(rBody).then((r) => {
      });
    if (this.state.batchDetails.statusName === "Complete")
      getprocessingList2(mhId)
        .then((r) => {
          this.setState({ processingList2: r.data, spLoading: false }, () => {
            getbatchDetails(mhId).then((r) => {
              this.setState({ batchDetails: r.data });
            });
          });
        })
        .catch((e) => {
          this.setState({ spLoading: false });
        });

  };
  handleMainOFButton = () => {
    let cId = "";
    let lId = "";
    if (process.browser) {
      cId = window.location.pathname.split("/")[2];
      lId = window.location.pathname.split("/")[3];
    }
    getMainOrderFile(cId, lId).then((r) => {
      this.handleChooseOFDialog("openMode");
      this.setState({
        mofList: r.data,
        unSubmitedList: [],
        OSFMode: "MOF",
        listToNext: [],
        selectedIdToNext: [],
        selectedId: [],
      });
    });
  };
  setOSSearchVal = (e) => {
    this.setState({ searchValOS: e.target.value, oFSearchClicked: false });
  };
  setPickInsertearchVal = (e) => {
    this.setState({
      searchValPickInsert: e.target.value,
      pickInsertSearchClicked: false,
    });
  };
  onOSSearchClicked = () => {
    let newList = clone(this.state.mofList);
    let newRow = [];
    newList.map((n) => {
      if (
        n.issueDescription && n.issueDescription.toLowerCase().includes(this.state.searchValOS && this.state.searchValOS.toLowerCase()) ||
        n.orderFileName && n.orderFileName.toLowerCase().includes(this.state.searchValOS && this.state.searchValOS.toLowerCase()) ||
        n.splInstruction && n.splInstruction.toLowerCase().includes(this.state.searchValOS && this.state.searchValOS.toLowerCase()) ||
        n.subscription && n.subscription.toLowerCase().includes(this.state.searchValOS && this.state.searchValOS.toLowerCase())
      )
        newRow.push(n);
    });
    this.setState({ mofList: newRow, oFSearchClicked: true, });
  };
  onPickInsertSearchClicked = () => {
    let newList = clone(this.state.pickInsertList);
    let newRow = [];
    newList.map((n) => {
      if (
        n.insertNo && n.insertNo.toLowerCase().includes(this.state.searchValPickInsert && this.state.searchValPickInsert.toLowerCase()) ||
        n.insertName && n.insertName.toLowerCase().includes(this.state.searchValPickInsert && this.state.searchValPickInsert.toLowerCase()) ||
        n.quantity == this.state.searchValPickInsert
      )
        newRow.push(n);
    });
    this.setState({ pickInsertList: newRow, pickInsertSearchClicked: true });
  };
  clearPickInsertSearchVal = () => {
    let mhId = "";
    if (process.browser) {
      mhId = window.location.pathname.split("/")[4];
    }
    getPickInsertList(mhId).then((r) => {
      this.setState({ pickInsertList: r.data });
    });
    this.setState({ searchValPickInsert: "", pickInsertSearchClicked: false });
  };
  clearOSSearchVal = () => {
    let cId = "";
    let lId = "";
    if (process.browser) {
      cId = window.location.pathname.split("/")[2];
      lId = window.location.pathname.split("/")[3];
    }
    if (this.state.OSFMode === "MOF") {
      getMainOrderFile(cId, lId).then((r) => {
        this.setState({ mofList: r.data, unSubmitedList: [], OSFMode: "MOF" });
      });
    }
    if (this.state.OSFMode === "SOF") {
      getSuplimentOrderFile(cId, lId).then((r) => {
        this.setState({ mofList: r.data, OSFMode: "SOF" });
      });
    }
    this.setState({ searchValOS: "", oFSearchClicked: false });
  };

  setZoneCountryVal = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      zoneCountrySearchClicked: false,
    });
    let newList = clone(this.state.zoneCountryList);
    let nZoneCList = clone(this.state.initialZoneCountryList);
    // nZoneCList = this.sortZCList(nZoneCList)
    let newRow = [];
    // console.log(newList)
    if (e.target.name === "criteriaCountryVal") {
      if (this.state.criteriaZoneVal) {
        nZoneCList.map((n, indL) => {
          if (
            (n.countryName
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) &&
              n.zoneName
                .toLowerCase()
                .includes(this.state.criteriaZoneVal.toLowerCase()))
          ) {
            newRow.push(n);
            nZoneCList.splice(indL, 1)
          }
        });
        nZoneCList.map((n) => {
          if (
            n.checkBoxVal
          ) {
            newRow.push(n);
          }
        });
      } else if (!this.state.criteriaZoneVal) {
        nZoneCList.map((n, indL) => {
          if (
            (n &&
              n.countryName &&
              n.countryName
                .toLowerCase()
                .includes(e.target.value.toLowerCase()))
          ) {
            newRow.push(n);
            nZoneCList.splice(indL, 1)
          }
        });
        nZoneCList.map((n) => {
          if (

            n.checkBoxVal
          ) {
            newRow.push(n);
          }
        });
      }
      // else if(!e.target.value &&!this.state.criteriaZoneVal){
      //   this.setState({zoneCountryList})
      // }
    }
    if (e.target.name === "criteriaZoneVal") {
      if (this.state.criteriaCountryVal) {
        nZoneCList.map((n, indL) => {
          if (
            (n.zoneName.toLowerCase().includes(e.target.value.toLowerCase()) &&
              n.countryName
                .toLowerCase()
                .includes(this.state.criteriaCountryVal.toLowerCase()))
          ) {
            newRow.push(n);
            nZoneCList.splice(indL, 1)
          }
        });
        nZoneCList.map((n) => {
          if (
            n.checkBoxVal
          ) {
            newRow.push(n);
          }
        });
      } else if (!this.state.criteriaCountryVal) {
        nZoneCList.map((n, indL) => {
          if (
            n.zoneName.toLowerCase().includes(e.target.value.toLowerCase())

          ) {
            newRow.push(n);
            nZoneCList.splice(indL, 1)

          }
        });
        nZoneCList.map((n) => {
          if (

            n.checkBoxVal
          ) {
            newRow.push(n);
          }
        });
      }
    }
    // if(e.target.name==="criteriaCountryVal"){
    //   if(e.target.value)
    //   {
    //     newList&&newList.length>0&& newList.map(n=>{
    //       if(n.countryName.toLowerCase().includes(e.target.value.toLowerCase())||n.checkBoxVal)
    //       newRow.push(n)
    //   })
    // }
    //   else{
    //      if(this.state.criteriaZoneVal)
    //      nZoneCList.map(n=>{
    //        if(n.zoneName.toLowerCase().includes(this.state.criteriaZoneVal.toLowerCase())||n.checkBoxVal)
    //        newRow.push(n)
    //     })
    //     else{
    //       getCriteriacCountryZoneList().then(r=>{
    //         this.setState({zoneCountryList:r.data})
    //       })
    //     }
    //    }
    // this.setState({zoneCountryList:newRow})
    // }
    // if(e.target.name==="criteriaZoneVal"){
    //   if(e.target.value)
    //   newList&&newList.length>0&&  newList.map(n=>{
    //       if(n.zoneName.toLowerCase().includes(e.target.value.toLowerCase())||n.checkBoxVal)
    //     newRow.push(n)
    //   })
    //   else{
    //    if(this.state.criteriaCountryVal)
    //    nZoneCList.map(n=>{
    //       if(n.countryName.toLowerCase().includes(this.state.criteriaCountryVal.toLowerCase())||n.checkBoxVal)
    //       newRow.push(n)
    //     })
    //     else{
    //       getCriteriacCountryZoneList().then(r=>{
    //         this.setState({zoneCountryList:r.data})
    //       })
    //     }
    //   }
    this.setState({ zoneCountryList: newRow });

    /////////////////////////////////////////////////////////////////////////////////
    // if(e.target.name==="criteriaZoneVal"){
    //   if(testCountryList.length>0){
    //     if(e.target.value)
    //      {
    //       testCountryList.map(n=>{
    //       if(n.zoneName.toLowerCase().includes(e.target.value.toLowerCase())||n.checkBoxVal)
    //       testZoneList.push(n)
    //       })
    //     }
    //     else{
    //       this.setState({zoneCountryList:testCountryList})
    //     }
    //   }
    //   else
    //   newList.map(n=>{
    //       if(n.zoneName.toLowerCase().includes(e.target.value.toLowerCase())||n.checkBoxVal)
    //       testZoneList.push(n)
    //   })
    //   this.setState({zoneCountryList:testZoneList})
    // }
    // testCountryList.map((m,i)=>{
    //   testZoneList.map((n,j)=>{
    //     if(m.countryID===n.countryID){
    //       newRow.push(n)
    //     }
    //   })
    // })

    // this.setState({zoneCountryList:newRow,zoneCountrySearchClicked:true})
    // if(!e.target.value&&!this.state.criteriaCountryVal)
    // this.setState({zoneCountryList:zoneCList})
  };
  onZoneCountrySearchClicked = () => { };
  clearZoneCountrySearchVal = () => {
    getCriteriacCountryZoneList().then((r) => {
      this.setState({
        zoneCountryList: r.data,
        initialZoneCountryList: r.data,
        zcLoader: false
      });
    });
    this.setState({
      criteriaCountryVal: "",
      criteriaZoneVal: "",
      zoneCountrySearchClicked: false,
    });
  };

  onCheckboxClicked = (e, data, i) => {
    let newSelectedList = clone(this.state.list);
    if (e.target.checked)
      this.setState({ selectedIndexForSupplement: i })
    else
      this.setState({ selectedIndexForSupplement: "" })

    let newSId = [];
    newSelectedList.map((s) => {
      if (s) s.checkBoxVal = false;
    });
    if (e.target.checked) {
      newSelectedList[i] = data;
      newSId = [i];
      newSelectedList[i].checkBoxVal = true;
    } else {
      //   newSelectedList[i]={}
      newSelectedList[i] = data;
      newSelectedList[i].checkBoxVal = false;
      newSId = [];
    }
    this.setState({ list: newSelectedList, selectedIdToNext: newSId });
  };
  onZoneCountryCheckboxClicked = (e, data, i, cid) => {
    let newSelectedList = clone(this.state.zoneCountryList);
    let copyZCList = clone(this.state.initialZoneCountryList);
    let newUSList = clone(this.state.unsubmitedPickCriteriaZC);
    if (e.target.checked) {
      this.state.zoneCountryList.map((x, y) => {
        if (x.countryID === cid) {
          newSelectedList[y].checkBoxVal = true
          newUSList[y] = data
        }
      })
      this.state.initialZoneCountryList.map((x, y) => {
        if (x.countryID === cid)
          copyZCList[y].checkBoxVal = true
      })

    } else {
      this.state.zoneCountryList.map((x, y) => {
        if (x.countryID === cid) {
          newSelectedList[y].checkBoxVal = false
          newUSList[y] = {}
        }
      })
      this.state.initialZoneCountryList.map((x, y) => {
        if (x.countryID === cid)
          copyZCList[y].checkBoxVal = false
      })

    }
    this.setState({
      zoneCountryList: newSelectedList,
      initialZoneCountryList: copyZCList,
      unsubmitedPickCriteriaZC: newUSList,
    });
  };
  onSelectAllZoneCountryCheckboxClicked = (e) => {
    let newSelectedList = clone(this.state.zoneCountryList);
    let copyZCList = clone(this.state.initialZoneCountryList);
    let newUSList = clone(this.state.unsubmitedPickCriteriaZC);
    if (e.target.checked) {
      this.state.zoneCountryList.map((zc, ind) => {
        newSelectedList[ind].checkBoxVal = true;
        this.state.initialZoneCountryList.map((x, y) => {
          if (zc.countryID === x.countryID)
            copyZCList[y].checkBoxVal = true;
        })
        newUSList[ind] = zc;
      })
    } else {
      this.state.zoneCountryList.map((zc, ind) => {
        newUSList[ind] = {};
        this.state.initialZoneCountryList.map((x, y) => {
          if (zc.countryID === x.countryID)
            copyZCList[y].checkBoxVal = false;
        })
        newSelectedList[ind].checkBoxVal = false;
      })
    }
    this.setState({
      zoneCountryList: newSelectedList,
      initialZoneCountryList: copyZCList,
      unsubmitedPickCriteriaZC: newUSList,
    });
  };
  onSecondListCheckboxClicked = (e, data, i) => {
    let newSelectedList = clone(this.state.secondList);
    let newSId = [];
    newSelectedList.map((s) => {
      s.checkBoxVal = false;
    });
    if (e.target.checked) {
      newSelectedList[i].checkBoxVal = true;
      newSId = [i];
    } else {
      newSelectedList[i].checkBoxVal = false;
      newSId = [];
    }
    this.setState({
      secondList: newSelectedList,
      secondListSelectedId: newSId,
    });
  };
  handlePickInsertCkBxClick = (e, data, i) => {
    let newSelectedList = clone(this.state.unSubmitedInsertList);
    // let newSId=[]
    if (e.target.checked) {
      newSelectedList[i] = data;
      //   newSId=[i]
    } else {
      newSelectedList[i] = {};
      // newSId=[]
    }
    this.setState({ unSubmitedInsertList: newSelectedList });
  };
  handlePickCriteriaOFCkBxClick = (e, data, i) => {
    let newSelectedList = clone(this.state.unSubmitedPickCriteriaOF);
    // let newSId=[]
    if (e.target.checked) {
      newSelectedList[i] = data;
      //   newSId=[i]
    } else {
      newSelectedList[i] = {};
      // newSId=[]
    }
    this.setState({ unSubmitedPickCriteriaOF: newSelectedList });
  };

  handleCkBxClick = (e, data, i) => {
    if (this.state.OSFMode === "MOF") {
      let newSelectedList = clone(this.state.unSubmitedList);
      let newScId = clone(this.state.selectedId);
      let newList = clone(this.state.mofList);

      let bId =
        this.state.bucketList &&
        this.state.bucketList.length > 0 &&
        this.state.bucketList.filter((d) => d.value === "NOR")[0].id;
      let bObj =
        this.state.bucketList &&
        this.state.bucketList.length > 0 &&
        this.state.bucketList.filter((d) => d.value === "NOR")[0];
      if (e.target.checked) {
        newSelectedList[i] = data;
        newScId.push(data.mlopFileHeaderID);
        newSelectedList[i].checkedValMain = e.target.checked;
        newSelectedList[i].bucketId = bId;
        newList[i].checkBoxVal = true;
        newSelectedList[i].bucketObj = bObj;
      } else {
        //
        newSelectedList[i] = {};
        newScId.pop(data.mlopFileHeaderID);
        newList[i].checkBoxVal = false;
        newSelectedList[i].checkedValMain = e.target.checked;
      }
      newSelectedList[i].checkedVal = e.target.checked;
      this.setState({
        unSubmitedList: newSelectedList,
        selectedId: newScId,
        mofList: newList,
      });
    }

    if (this.state.OSFMode === "SOF") {
      if (e.target.checked)
        this.setState({ selectedSuppIndexForSupplement: i })
      else
        this.setState({ selectedSuppIndexForSupplement: "" })

      let newSelectedList = [];
      let newList = clone(this.state.mofList);
      let newScId = [];
      newList.map((s) => {
        s.checkBoxVal = false;
      });
      if (e.target.checked) {
        newSelectedList[i] = data;
        newList[i].checkBoxVal = true;
        newScId = [i];
      } else {
        newSelectedList[i] = {};
        newList[i].checkBoxVal = false;
        newScId = [];
      }
      this.setState({
        mofList: newList,
        selectedSupId: newScId,
        unSubmitedSupList: newSelectedList,
      });
    }
  };
  submitOF = () => {

    let newList = clone(this.state.list);
    let ofId = ""
    let sfId = ""
    if (this.state.selectedIndexForSupplement || this.state.selectedIndexForSupplement === 0) {
      ofId = this.state.list.length > 0 && this.state.list[this.state.selectedIndexForSupplement].mlopFileHeaderID;
    }
    if (this.state.selectedSuppIndexForSupplement || this.state.selectedSuppIndexForSupplement === 0) {
      sfId = this.state.list.length > 0 && this.state.mofList[this.state.selectedSuppIndexForSupplement].mlopFileHeaderID;
    }
    if (this.state.OSFMode === "MOF") {
      this.getListOF("submit");
      needApiCallInStepOne = true
      this.setState({
        //list:this.state.unSubmitedList,
        openOFModal: false,
      });
    }


    if (this.state.OSFMode === "SOF") {
      let res = false;
      this.setState({ spLoading: true })
      submitSuplimentFile(ofId, sfId).then((r) => {
        this.setState({
          //list:this.state.unSubmitedList,
          openOFModal: false,
          spLoading: false
        });
        res = r;
        if (res.data) {
          needApiCallInStepOne = true
          this.state.mofList.length > 0 &&
            this.state.mofList.map((usl, ind) => {
              if (usl.checkBoxVal) {
                if (newList[this.state.selectedIdToNext].supplimentId1) {
                  newList[this.state.selectedIdToNext][`supplimentFile2`] =
                    usl.orderFileName;
                  newList[this.state.selectedIdToNext][`supplimentId2`] =
                    usl.mlopFileHeaderID;
                } else {
                  newList[this.state.selectedIdToNext][`supplimentFile1`] =
                    usl.orderFileName;
                  newList[this.state.selectedIdToNext][`supplimentId1`] =
                    usl.mlopFileHeaderID;
                }
              }
              //newList[this.state.selectedIdToNext][`supplimentFile${ind+1}`]=usl.orderFileName
              //newList[this.state.selectedIdToNext][`supplimentId${ind+1}`]=usl.mloplFileHeaderID
            });
          this.setState({
            list: newList,
            openOFModal: false,
            OSFMode: "MOF",
          });
        } else {
          toastr.error("Supplement not matching");
        }
      });
    }
  };
  submitPickInsert = () => {
    needApiCallInStepTwo = true
    let reqBody = [];
    let mhId = "";
    let loggedInId = "";
    if (process.browser) mhId = window.location.pathname.split("/")[4];
    loggedInId = localStorage.getItem("loggedInId");
    // console.log(this.state.unSubmitedInsertList, "us.........")
    this.state.unSubmitedInsertList.map((u) => {
      // console.log(u)
      if (u.insertMasterID) {
        reqBody.push({
          batchInsertHeaderID: 0,
          mlBatchHeaderID: mhId,
          // issueDescription: u.issueDescription,
          insertMasterID: u.insertMasterID ? u.insertMasterID : null,
          journalID: u.jouranlID ? u.jouranlID : null,
          criteriaID: null,
          createdBy: loggedInId,
        });
      }
    });

    postPickInsert(reqBody, mhId).then((r) => {
      //   let new2ndList=[]
      //       new2ndList.push({
      //         actualWeight: r.data.actualWeight && r.data.actualWeight
      //  insertMasterID: 10095
      // insertName: "Test"
      // insertNo: "Test"
      // issueDescription: "ee340000005"
      // jouranlID: 1
      // quantity: 2
      //       })
      this.setState({ secondList: r.data, openICModal: false });
    });
    // this.setState({secondList:this.state.unSubmitedInsertList,openICModal:false})
  };
  handleAutoOpenClick = (id) => {
    autoCId = id;
  };
  handleAutocompleteBucket = (e, val) => {
    needApiCallInStepOne = true
    let newList = clone(this.state.list);
    let newTS = clone(this.state.listToNext);
    newList[autoCId].bucketId = val && val.id;
    newList[autoCId].bucketObj = val && val;
    if (newTS[autoCId]) newTS[autoCId].bucketId = val && val.id;
    if (newTS[autoCId]) newTS[autoCId].bucketObj = val && val;
    this.setState({ list: newList, listToNext: newTS });
  };
  handleSOFButton = () => {
    let cId = "";
    let lId = "";
    if (process.browser) {
      cId = window.location.pathname.split("/")[2];
      lId = window.location.pathname.split("/")[3];
    }

    getSuplimentOrderFile(cId, lId).then((r) => {
      this.handleChooseOFDialog();
      this.setState({ mofList: r.data, OSFMode: "SOF", selectedSupId: [] });
    });
  };

  getAllCustomer = (list) => {
    let customerList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        customerList.push({ key: c.id, value: c.text, name: c.text });
      });
    this.sortDDList(customerList, "customerList");
  };
  getAllLocation = (list) => {
    let locationList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        locationList.push({ key: c.id, value: c.text, name: c.text });
      });
    this.sortDDList(locationList, "locationList");
  };
  sortDDList = (list, name) => {
    let z = list.sort(function (a, b) {
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ [name]: z });
  };
  handleAutocompleteCustomer = (e, val) => {
    this.setState({ customerId: val && val.key, customerObj: val && val });
  };
  handleAutocompletLocation = (e, val) => {
    this.setState({ locationId: val && val.key, locationObj: val && val });
  };
  handleChooseOFDialog = () => {
    let newList = clone(this.state.list);
    //  if( newList[this.state.selectedIdToNext]){
    //   newList[this.state.selectedIdToNext].supplimentName1=""
    //   newList[this.state.selectedIdToNext].supplimentName2=""
    //   newList[this.state.selectedIdToNext].supplimentId1=null
    //   newList[this.state.selectedIdToNext].supplimentId2=null
    //  }
    this.setState({
      openOFModal: !this.state.openOFModal,
      OSFMode: "MOF",
      unSubmitedSupList: [],
      list: newList,
      searchValOS: "",
      oFSearchClicked: false,
      // searchClicked
    });
  };
  handleRemoveSuppliment = () => {
    needApiCallInStepOne = true
    let newList = clone(this.state.list);
    if (newList[this.state.selectedIdToNext]) {
      newList[this.state.selectedIdToNext].supplimentFile1 = "";
      newList[this.state.selectedIdToNext].supplimentFile2 = "";
      newList[this.state.selectedIdToNext].supplimentId1 = null;
      newList[this.state.selectedIdToNext].supplimentId2 = null;
    }
    this.setState({ list: newList });
  };
  handleChooseICDialog = () => {
    let mhId = "";
    if (process.browser) mhId = window.location.pathname.split("/")[4];
    this.setState({
      //secondList:[],
      unSubmitedInsertList: [],
    });
    getPickInsertList(mhId).then((r) => {
      this.setState({ pickInsertList: r.data });
    });
    this.setState({
      openICModal: !this.state.openICModal, pickInsertSearchClicked: false,
      searchValPickInsert: ""
    });
  };
  handleRemoveCriteria = () => {
    needApiCallInStepTwo = true
    let newSecList = clone(this.state.secondList);
    newSecList[this.state.secondListSelectedId[0]].criteriaName = "";
    newSecList[this.state.secondListSelectedId[0]].criteriaID = "";
    this.setState({
      secondList: newSecList,
      criteriaObj: null,
      //made chenges......................................................................................

      //cruteriaID: "",
      criteriaID: ""
    });
  };

  handleCriteriaModalInCancel = () => {
    this.setState({ openCriteriaModal: !this.state.openCriteriaModal });
    this.setState({
      criteriaCountryVal: "",
      criteriaZoneVal: "",
      zoneCountryList: zoneCList,
      unsubmitedPickCriteriaZC: [],
    });
  };
  handleCriteriaModal = () => {
    needApiCallInStepTwo = true
    let loggedInId = localStorage.getItem("loggedInId");
    let newSecList = clone(this.state.secondList);
    // console.log(newSecList)
    if (!this.state.openCriteriaModal) {
      this.setState({
        criteriaObj: null,
        criteriaID: "",
        unSubmitedPickCriteriaOF: [],
        unsubmitedPickCriteriaZC: [],
      });
    }

    if (this.state.criteriaObj && this.state.criteriaObj.value === "BYO") {
      let reqBody = [];
      // console.log(this.state.secondList, this.state.secondListSelectedId)
      this.state.unSubmitedPickCriteriaOF.length > 0 &&
        this.state.unSubmitedPickCriteriaOF.map((a) => {
          a.mlBatchDetailID &&
            reqBody.push({
              batchInsertHeaderID:
                this.state.secondListSelectedId.length > 0 &&
                this.state.secondList[this.state.secondListSelectedId[0]]
                  .batchInsertHeaderID,
              mlBatchDetailID: a.mlBatchDetailID,
              createdBy: loggedInId,
            });
        });
      this.state.unSubmitedPickCriteriaOF.length > 0 &&
        postCriteriaByOF(reqBody).then((res) => { });
      this.setState({
        criteriaObj: null,
        //made chenges......................................................................................
        // cruteriaID: ""
        criteriaID: ""
      });
    }

    if (this.state.criteriaObj && this.state.criteriaObj.value === "BYC") {
      let reqBody = [];

      this.state.zoneCountryList.length > 0 &&
        this.state.zoneCountryList.map((a) => {
          a.checkBoxVal &&
            reqBody.push({
              batchInsertHeaderID:
                this.state.secondListSelectedId.length > 0 &&
                this.state.secondList[this.state.secondListSelectedId[0]]
                  .batchInsertHeaderID,
              countryID: a.countryID,
              createdBy: loggedInId,
            });
        });
      this.state.zoneCountryList.length > 0 &&
        postCriteriaByZC(reqBody).then((res) => { });
      this.setState({
        criteriaObj: null,
        //made chenges......................................................................................
        // cruteriaID: ""
        criteriaID: ""
      });
    }
    newSecList[this.state.secondListSelectedId[0]].criteriaName =
      this.state.criteriaObj && this.state.criteriaObj.text;
    newSecList[this.state.secondListSelectedId[0]].criteriaID =
      this.state.criteriaObj && this.state.criteriaObj.id;

    // if(ToRemove){
    //   newSecList[this.state.secondListSelectedId[0]].criteriaName=""
    //   newSecList[this.state.secondListSelectedId[0]].criteriaId=""
    //   this.setState({criteriaObj:null,cruteriaID:""})
    // }

    this.setState({
      openCriteriaModal: !this.state.openCriteriaModal,
      secondList: newSecList,
    });
  };
  getSecondList = () => {
    let mhId = "";
    if (process.browser) mhId = window.location.pathname.split("/")[4];
    getSecondListForView(mhId).then((r) => {
      let newList = [];
      r &&
        r.data &&
        r.data.length > 0 &&
        r.data.map((d) => {
          newList.push({
            actualWeight: d.actualWeight && d.actualWeight,
            insertMasterID: d.insertMasterID,
            insertName: d.insertName,
            insertNo: d.insertNo,
            issueDescription: d.issueDescription,
            journalID: d.journalID,
            quantity: d.quantity,
            batchInsertHeaderID: d.batchInsertHeaderID,
            criteriaName: d.criteriaName,
            criteriaID: d.criteriaID
          });
        });
      this.setState({ secondList: newList });
    });
  };
  sortPPList = (list) => {
    let z = list.sort(function (a, b) {
      var x = a.orderFileName && a.orderFileName.toLowerCase();
      var y = b.orderFileName && b.orderFileName.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ list: z });
  };
  getInitialOrderList = (mode) => {
    let mhId = "";
    if (process.browser) mhId = window.location.pathname.split("/")[4];
    getListForView(mhId).then((r) => {
      let newList = [];
      r &&
        r.data &&
        r.data.map((d) => {
          let bId =
            this.state.bucketList &&
            this.state.bucketList.length > 0 &&
            this.state.bucketList.filter((d) => d.value === "NOR")[0].id;
          let bObj =
            this.state.bucketList &&
            this.state.bucketList.length > 0 &&
            this.state.bucketList.filter((d) => d.value === "NOR")[0];
          if (d.bucketID) {
            bId = d.bucketID;
            bObj =
              this.state.bucketList &&
              this.state.bucketList.length > 0 &&
              this.state.bucketList.filter((b) => b.id === d.bucketID)[0];
          }
          newList.push({
            bucketId: bId,
            bucketObj: bObj,
            checkedVal: false,
            checkedValMain: false,
            issueDescription: d.issueDescription,
            mlopFileHeaderID: d.mlopFileHeaderID || d.mloplFileHeaderID,
            orderFileName: d.orderFileName,
            receivedDate: d.receivedDate,
            splInstruction: d.splInstruction,
            subscription: d.subscription,
            subscriptionID: d.subscriptionID,
            totalCopies: d.totalCopies,
            journalID: d.journalID,
            totalOrders: d.totalOrders,
            supplimentFile1: d.supplementOneOrderFile,
            supplimentFile2: d.supplementTwoOrderFile,
            supplimentId1: d.supplementOneOrderFileHeaderID,
            supplimentId2: d.supplementTwoOrderFileHeaderID,
            bucketName: d.bucketName,
            batchDetailId: d.batchDetailID,
          });
        });
      //modified for sorting
      // this.setState({list:newList})
      this.sortPPList(newList);
    });
  };
  getListOF = (mode) => {
    let mhId = "";
    if (process.browser) mhId = window.location.pathname.split("/")[4];
    let newList = []
    if (mode === "submit") {
      let newList = clone(this.state.list)
      this.state.unSubmitedList.map((xy,) => {
        let matchFound = false
        this.state.list.map((yz, idn) => {
          if (xy.mlopFileHeaderID === yz.mlopFileHeaderID) {
            newList[idn] = xy
            matchFound = true
          }
        })
        if (!matchFound) {
          newList.push(xy)
        }
      })
      let listWithBkVal = []
      newList.map(bl => {
        let bKArr = this.state.bucketList.filter(f => f.value === "NOR")
        let bkObj = bKArr && bKArr.length > 0 && bKArr[0]
        let bkId = bkObj && bkObj.id
        listWithBkVal.push({ ...bl, bucketObj: bkObj, bucketId: bkId, bucketID: bkId })
      })
      if (mode === "submit")
        this.setState({ list: listWithBkVal });
    }

  };
  handleAutocompleteCriteria = (e, val) => {
    let mhId = "";
    if (process.browser) mhId = window.location.pathname.split("/")[4];
    this.setState({ criteriaID: val && val.key, criteriaObj: val && val });
    if (val && val.value === "BYO") {
      let jID = 0
      if (this.state.secondList && this.state.secondList.length > 0 && this.state.secondListSelectedId && this.state.secondListSelectedId.length > 0)
        jID = this.state.secondList[this.state.secondListSelectedId[0]].journalID
      // console.log(this.state.secondList[this.state.secondListSelectedId[0]].journalID)
      if (jID)
        getCriteriaFilesList(mhId, jID).then((rr) => {
          this.setState({ criteriaModalFiles: rr.data });
        });
    }
    if (val && val.value === "BYC") {
      getCriteriacCountryZoneList(mhId).then((rr) => {
        this.setState({
          zoneCountryList: rr.data,
          initialZoneCountryList: rr.data,
          zcLoader: false
        });
        zoneCList = rr.data;
      });
    }
    if (val && val.value !== "BYC") {
      this.setState({
        criteriaCountryVal: "",
        criteriaZoneVal: "",
        unsubmitedPickCriteriaZC: [],
      });
    }
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleClose = () => {
    // clean up state and errors
    let newZone = { ...this.state.Zone };
    newZone.ZoneCode = "";
    newZone.ZoneName = "";
    this.setState({
      Zone: newZone,
      errors: {},
    });
  };

  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertKeyPress(event) {
    const re = /[a-zA-Z0-9-#]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextKeyPress(event) {
    const re = /[a-z A-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusNumberKeyPress(event) {
    const re = /[0-9 a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onWeightKeyPress(event) {
    const re = /[0-9 a-zA-Z.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertSizeKeyPress(event) {
    const re = /[0-9 a-zA-Z-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusAllPress(event) {
    const re = /[a-z A-Z0-9=.;()&@]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleCustChange = (event, values) => {
    const { ItemMaster } = this.state;
    //this.setState({ItemMaster, customersList: []})
    let CustomerId = values && values.customerID;
    ItemMaster["Customer"] = values;
    ItemMaster["CustomerId"] = CustomerId;
    ItemMaster["IsVendor"] = values && values.isVendor;
    if (ItemMaster["IsVendor"] === true) {
      ItemMaster["IsConsummable"] = true;
    } else {
      ItemMaster["IsConsummable"] = false;
    }
    this.validateField("Customer", CustomerId);
    this.setState({ ItemMaster });
    // this.validateForm();
  };
  handleAutocompletBucket = () => { };
  handleStartProcessing = () => {
    let mhId = "";
    //431
    if (process.browser) mhId = window.location.pathname.split("/")[4];
    // getprocessingList1(mhId).then(r=>{
    //   this.setState({processingList1:r.data})
    // })
    this.setState({ spLoading: true });
    if (this.state.batchDetails.statusName === "Complete")
      getprocessingList2(mhId)
        .then((r) => {
          this.setState({ processingList2: r.data, spLoading: false }, () => {
            getbatchDetails(mhId).then((r) => {
              this.setState({ batchDetails: r.data });
            });
          });
        })
        .catch((e) => {
          this.setState({ spLoading: false });
        });
    else
      getprocessingList1(mhId)
        .then((r) => {
          this.setState({ processingList2: r.data, spLoading: false }, () => {
            getbatchDetails(mhId).then((r) => {
              this.setState({ batchDetails: r.data });
            });
          });
        })
        .catch((e) => {
          this.setState({ spLoading: false });
        });
  };
  onDeleteOFClicked = (e, i) => {
    needApiCallInStepOne = true
    let newList = clone(this.state.list);
    let newlistToNext = clone(this.state.listToNext);
    let newSITN = clone(this.state.selectedIdToNext);
    if (newList[i].batchDetailId) {
      pgsService
        .delete(`/DeleteOrderList/${newList[i].batchDetailId}`)
        .then((r) => {
          this.handleClose();
          toastr.success("Order file deleted successfully");
        });
    }
    newList.splice(i, 1);
    if (!newlistToNext[i]) newlistToNext[i] = {};
    newlistToNext.splice(i, 1);
    newSITN.splice(i, 1);
    this.setState({
      list: newList,
      listToNext: newlistToNext,
      selectedIdToNext: newSITN,
    });
  };
  onDeleteInsertClicked = (e, i) => {
    needApiCallInStepTwo = true
    let newList = clone(this.state.secondList);
    let newSITN = clone(this.state.secondListSelectedId);
    // console.log(i)
    // this.handleRemoveCriteria()
    if (newList[i].batchInsertHeaderID) {
      pgsService
        .delete(`/DeleteInsertCriteria/${newList[i].batchInsertHeaderID}`)
        .then((r) => {
          newList.splice(i, 1);
          this.handleClose();
          toastr.success("Insert deleted successfully");
        });
    }

    // newSITN.splice(i,1)
    // newSITN=[]
    this.setState({
      secondList: newList,
      // secondListSelectedId:newSITN
    });
  };
  handleReportChange = (e) => {
    if (e.target.value.toLowerCase() !== "none") {
      this.setState({
        showReportModal: true,
        selectedReportName: e.target.value,
        selectedReportID: 0,
      });
    }
    e.target.value = "none";
  };
  handleHidePopup = () => {
    this.setState({
      showReportModal: false,
      selectedReportName: "",
      selectedReportID: 0,
    });
  };
  render() {
    let { classes } = this.props;
    // console.log(this.state.secondList)
    return (
      <React.Fragment>
        {this.state.showReportModal ? (
          <ReportViewer
            url={
              `${window.localStorage.ReportURLByID}` +
              this.state.selectedReportName +
              "&IsDirectPrint=True&ReportParam=MLBatchHeaderID&Param1=" +
              `${this.props.history.location.pathname.split("/")[4]}`
            }
            showPopup={this.state.showReportModal}
            title={
              this.state.selectedReportName
                .replace(/([A-Z])/g, " $1")
                .charAt(0)
                .toUpperCase() +
              this.state.selectedReportName.replace(/([A-Z])/g, " $1").slice(1)
            }
            onClose={() => this.handleHidePopup()}
            handleHidePopup={() => this.handleHidePopup()}
          />
        ) : null}
        <Paper>
          <div className="pop-up-header" style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <span>Edit Batch</span>
            </div>
            {this.state.batchDetails &&
              this.state.batchDetails.statusName === "Complete" && (
                <div
                  style={{ flex: 1, textAlign: "right", paddingRight: "33px" }}
                >
                  <div className="custom-select">
                    <label style={{ paddingRight: "15px", fontSize: "18px" }}>
                      Report
                    </label>
                    <select
                      onChange={(e) => {
                        this.handleReportChange(e);
                      }}
                      style={{ paddingLeft: "10px" }}
                    >
                      <option value="none" className="custom-select-options">
                        None
                      </option>
                      <option
                        value="ConsolidationReport"
                        className="custom-select-options"
                      >
                        Consolidation
                      </option>
                      {/* <option
                        value="DocketChecklistReport"
                        className="custom-select-options"
                      >
                        Docket Checklist
                      </option>
                      <option
                        value="MailLodgementDetailsSheetReport"
                        className="custom-select-options"
                      >
                        Mail Lodgement Detail
                      </option> */}
                      <option
                        value="RunMailListReport"
                        className="custom-select-options"
                      >
                        Run Mail List
                      </option>
                      <option
                        value="RoutingServicesChecklistReport"
                        className="custom-select-options"
                      >
                        Routing Services Checklist
                      </option>
                      <option
                        value="Ezy2ship"
                        className="custom-select-options"
                      >
                        Ezy2ship
                      </option>
                      <option
                        value="CartonBox"
                        className="custom-select-options"
                      >
                        Carton Box
                      </option>
                    </select>
                  </div>
                </div>
              )}
          </div>
          <Divider />
          <div style={{ flexWrap: "wrap", margin: ".2em" }}>
            <div style={{ margin: "25px 0" }}>
              <HorizontalLinearStepper
                clearOpenList={this.props.clearOpenList}
                list={this.state.list}
                loading={this.state.loading}
                handleChooseOFDialog={this.handleChooseOFDialog}
                handleChooseICDialog={this.handleChooseICDialog}
                handleCriteriaModal={this.handleCriteriaModal}
                handleCriteriaModalInCancel={this.handleCriteriaModalInCancel}
                openOFModal={this.state.openOFModal}
                openICModal={this.state.openICModal}
                openCriteriaModal={this.state.openCriteriaModal}
                customerList={this.state.customerList}
                customerObj={this.state.customerObj}
                handleAutocompleteCustomer={this.handleAutocompleteCustomer}
                handleMainOFButton={this.handleMainOFButton}
                handleSOFButton={this.handleSOFButton}
                mofList={this.state.mofList}
                bucketList={this.state.bucketList}
                handleAutocompletBucket={this.handleAutocompletBucket}
                handleCkBxClick={this.handleCkBxClick}
                selectedId={this.state.selectedId}
                selectedSupId={this.state.selectedSupId}
                submitOF={this.submitOF}
                handleAutocompleteBucket={this.handleAutocompleteBucket}
                handleAutoOpenClick={this.handleAutoOpenClick}
                onCheckboxClicked={this.onCheckboxClicked}
                selectedIdToNext={this.state.selectedIdToNext}
                searchValOS={this.state.searchValOS}
                setOSSearchVal={this.setOSSearchVal}
                clearOSSearchVal={this.clearOSSearchVal}
                onOSSearchClicked={this.onOSSearchClicked}
                OSFMode={this.state.OSFMode}
                oFSearchClicked={this.state.oFSearchClicked}
                onDeleteOFClicked={this.onDeleteOFClicked}
                criteriaList={this.state.criteriaList}
                criteriaObj={this.state.criteriaObj}
                handleAutocompleteCriteria={this.handleAutocompleteCriteria}
                batchDetails={this.state.batchDetails}
                pickInsertList={this.state.pickInsertList}
                listToNext={this.state.listToNext}
                handlePickInsertCkBxClick={this.handlePickInsertCkBxClick}
                submitPickInsert={this.submitPickInsert}
                secondList={this.state.secondList}
                onSecondListCheckboxClicked={this.onSecondListCheckboxClicked}
                criteriaModalFiles={this.state.criteriaModalFiles}
                zoneCountryList={this.state.zoneCountryList}
                onDeleteInsertClicked={this.onDeleteInsertClicked}
                setPickInsertearchVal={this.setPickInsertearchVal}
                onPickInsertSearchClicked={this.onPickInsertSearchClicked}
                searchValPickInsert={this.state.searchValPickInsert}
                pickInsertSearchClicked={this.state.pickInsertSearchClicked}
                clearPickInsertSearchVal={this.clearPickInsertSearchVal}
                secondListSelectedId={this.state.secondListSelectedId}
                setZoneCountryVal={this.setZoneCountryVal}
                onZoneCountrySearchClicked={this.onZoneCountrySearchClicked}
                criteriaCountryVal={this.state.criteriaCountryVal}
                criteriaZoneVal={this.state.criteriaZoneVal}
                zoneCountrySearchClicked={this.state.zoneCountrySearchClicked}
                clearZoneCountrySearchVal={this.clearZoneCountrySearchVal}
                onZoneCountryCheckboxClicked={this.onZoneCountryCheckboxClicked}
                onSelectAllZoneCountryCheckboxClicked={this.onSelectAllZoneCountryCheckboxClicked}
                unSubmitedPickCriteriaOF={this.state.unSubmitedPickCriteriaOF}
                unsubmitedPickCriteriaZC={this.state.unsubmitedPickCriteriaZC}
                handlePickCriteriaOFCkBxClick={
                  this.handlePickCriteriaOFCkBxClick
                }
                callBatchSummaryList={this.callBatchSummaryList}
                callPostNextInsert={this.callPostNextInsert}
                batchSummaryList={this.state.batchSummaryList}
                history={this.props.history}
                handleRemoveCriteria={this.handleRemoveCriteria}
                handleRemoveSuppliment={this.handleRemoveSuppliment}
                handleStartProcessing={this.handleStartProcessing}
                processingList1={this.state.processingList1}
                processingList2={this.state.processingList2}
                spLoading={this.state.spLoading}
                getSecondList={this.getSecondList}
                zcLoader={this.state.zcLoader}
              />
            </div>
          </div>
          {/* <div style={{ margin: "0px 2em 0px 2em" }}>
            <Divider style={{ marginTop: "25px" }} />

            <div className="button-wrapper">
              <div>
                <Button
                  variant="outlined"
                   style={{border:"1px solid rgb(0, 105, 217)"}}
                  onClick={e =>
                    this.props.history.push("/pubMainLabelBatchPlacement")
                  }
                >
                  CANCEL
                </Button>
              </div>
              <div style={{ marginLeft: "1em" }}>
                <Button
                  // disabled={!this.state.formValid}
                   style={{border:"1px solid rgb(0, 105, 217)"}}
                  // autoFocus
                  //variant="contained"
                  // onClick={this.handleSubmit}
                  // className={
                  //   !this.state.formValid ? "disabled-button" : "enabled-button"
                  // }
                >
                  Next
                </Button>
              </div>
            </div>
          </div> */}
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(EditBatchProcess));
