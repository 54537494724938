import React, { Component } from "react";

class ReorderLevel extends Component {
  state = {};
  componentDidMount() {
    this.props.handleSettingHeader("ReOrder Level Report");
    this.props.setSelectedList("masterDataOpen", "reOrderReport");
  }
  render() {
    return (
      <div>
      <iframe
        style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
          src={`${window.localStorage.ReportURLByID}` + "ReorderLevel"}
        />
      </div>
    );
  }
}

export default ReorderLevel;
