import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_REPORT_SERVICE;
const pgsService = axios.create({
  baseURL,
});
/*------------ Collection Note functions starts----------------*/

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
export const getNewJobGroupList = async () => {
  let jobGroupList = [];
  await pgsService
    .get("/SelectWorkOrderDetails")
    .then((r) => {
      jobGroupList = r;
    })
    .catch((e) => {

    });
  return jobGroupList;
};
export const getCollectionNoteList = async () => {
  let collectionNoteList = [];
  await pgsService
    .get("/CollectionNotes")
    .then((r) => {
      collectionNoteList = r;
    })
    .catch((e) => { });
  return collectionNoteList;
};

export const getCollectionNoteDetails = async (collectionNoteId) => {
  let collectionNoteList = [];
  await pgsService
    .get("/CollectionNotesDetail/" + collectionNoteId)
    .then((r) => {
      collectionNoteList = r;
    })
    .catch((e) => { });
  return collectionNoteList;
};

export const getCollectionNote = async (collectionNoteId) => {
  let CollectionNoteValue = {};
  await pgsService
    .get("/CollectionNotes/" + collectionNoteId)
    .then((response) => {
      let data = response.data;
      CollectionNoteValue = {
        collectionToAddress: data.collectionToAddress,
        collectionNoteID: data.collectionNoteID,
        collectionNoteNo: data.collectionNoteNo,
        workOrderID: data.workOrderID,
        clientname: data.clientname,
        workOrderNo: data.workOrderNo,
        title: data.title,
        jobGroupNumber: data.jobGroupNumber,
        description: data.description,
        contactPersonName: data.contactPersonName,
        contactNumber: data.contactNumber,
        collectionAddress: data.collectionAddress,
        collectionAddress2: data.collectionNoteAddress2,
        collectionAddress3: data.collectionNoteAddress3,
        zipCode: data.zipCode,
        countryID: data.countryID,
        country: data.country,
        countryList: data.countryList,
        totalQuantity: data.totalQuantity,
        statusID: data.statusID,
        status: data.status,
        statusList: data.statusList,
        pickupDate: data.pickupDate,
        pickupTime: data.pickupTime,
        agentCode: data.agentCode,
        driverName: data.driverName,
        isUrgent: data.isUrgent,
        remarks: data.remarks,
        isActive: data.isActive,
        modifiedDate: data.modifiedDate,
        modifiedBy: data.modifiedBy,
        createdDate: data.createdDate,
        createdBy: data.createdBy,
      };
    })
    .catch((e) => { });
  return CollectionNoteValue;
};

export const postCollectionNote = async (data, details) => {
  let submitSuccess = false;
  let id = 0;
  await pgsService
    .post("/CollectionNotes", data)
    .then((r) => {
      id = r.data.collectionNoteID;
      submitSuccess = postCollectionNoteDetails(id, details);
    })
    .catch((e) => {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors[0].message !== undefined
      ) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return id;
};

export const postCollectionNoteDetails = async (id, details) => {
  let submitSuccess = false;
  if (details && details.length > 0) {
    await pgsService
      .post("/CollectionNotesDetail/" + id, details)
      .then((r) => {
        toastr.success("Collection Note Created Successfully");
        submitSuccess = true;
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors[0].message !== undefined
        ) {
          toastr.error(e.response.data.errors[0].message);
        } else if (e.response && e.response.data && e.response.data.errors[0]) {
          toastr.error(e.response.data.errors[0]);
        }
      });
    return submitSuccess;
  } else {
    toastr.success("Collection Note Created Successfully");
    return true;
  }
};

export const getFliteredCollectionNoteList = async (reqBody) => {
  let collectionNoteList = [];
  await pgsService
    .post("/CollectionNoteAdvanceSerach", reqBody)
    .then((r) => {
      collectionNoteList = r;
    })
    .catch((e) => { });
  return collectionNoteList;
};

export const getFliteredCollectionNoteDetailList = async (reqBody) => {
  let collectionNoteList = [];
  await pgsService
    .post("/CollectionNoteDetailAdvanceSerach", reqBody)
    .then((r) => {
      collectionNoteList = r;
    })
    .catch((e) => { });
  return collectionNoteList;
};

export const updateCollectionNote = async (collectionNoteId, data, details) => {
  let submitSuccess = false;
  await pgsService
    .put("/CollectionNotes/" + collectionNoteId, data)
    .then((r) => {
      let id = r.data.collectionNoteID;
      details && details.map((d) => (d.collectionNoteID = id));
      submitSuccess = updateCollectionNoteDetails(collectionNoteId, details);
    })
    .catch((e) => {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors[0].message !== undefined
      ) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return submitSuccess;
};
export const updateCollectionNoteDetails = async (
  collectionNoteId,
  details
) => {
  let submitSuccess = false;
  await pgsService
    .put("/CollectionNotesDetail/" + collectionNoteId, details)
    .then((r) => {
      toastr.success("Collection Note Updated Successfully");
      submitSuccess = true;
    })
    .catch((e) => {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors[0].message !== undefined
      ) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return submitSuccess;
};
export const deleteCollectionNote = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete("/CollectionNotes", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Collection Note Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
//-----------Delivery Note ---------------//

export const getDeliveryNoteList = async () => {
  let deliveryNoteList = [];
  await pgsService
    .get("/DeliveryNotes")
    .then((r) => {
      deliveryNoteList = r;
    })

    .catch((e) => { });
  return deliveryNoteList;
};

export const getDeliveryNoteDetails = async (deliveryNoteId) => {
  let deliveryNoteList = [];
  await pgsService
    .get("/DeliveryNoteDetails/" + deliveryNoteId)
    .then((r) => {
      deliveryNoteList = r;
    })

    .catch((e) => { });
  return deliveryNoteList;
};

export const getDeliveryNote = async (StockINID) => {
  let DeliveryNoteValue = {};
  await pgsService
    .get("/DeliveryNotes/" + StockINID)
    .then((response) => {
      let data = response.data;
      DeliveryNoteValue = {
        deliveryNoteID: data.deliveryNoteID,
        deliveryNoteNo: data.deliveryNoteNo,
        workOrderID: data.workOrderID,
        clientname: data.clientname,
        workOrderNo: data.workOrderNo,
        title: data.title,
        jobGroupNumber: data.jobGroupNumber,
        description: data.description,
        contactPersonName: data.contactPersonName,
        contactNumber: data.contactNumber,
        zipCode: data.zipCode,
        countryID: data.countryID,
        countryList: data.countryList,
        country: data.country,
        deliveryNoteAddress2: data.deliveryNoteAddress2,
        deliveryNoteAddress3: data.deliveryNoteAddress3,
        agentCode: data.agentCode,
        shipmentType: data.shipmentType,
        shipmentTypeID: data.shipmentTypeID,
        shipmentList: data.shipmentList,
        deliveryDate: data.deliveryDate,
        deliveryTime: data.deliveryTime,
        isUrgent: data.isUrgent,
        totalQuantity: data.totalQuantity,
        status: data.status,
        statusID: data.statusID,
        statusList: data.statusList,
        deliverFromAddress: data.deliverFromAddress,
        deliveryToAddress: data.deliveryToAddress,
        driverName: data.driverName,
        remarks: data.remarks,
        Active: data.isActive,
        ModifiedDate: data.modifiedDate,
        ModifiedBy: data.modifiedBy,
        CreatedDate: data.createdDate,
        CreatedBy: data.createdBy,
      };
    })
    .catch((e) => { });
  return DeliveryNoteValue;
};

export const postDeliveryNote = async (data, details) => {
  let submitSuccess = false;
  let id = 0;
  await pgsService
    .post("/DeliveryNotes", data)
    .then((r) => {
      id = r.data.deliveryNoteID;
      submitSuccess = postDeliveryNoteDetails(id, details);
    })
    .catch((e) => {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors[0].message !== undefined
      ) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return id;
};
export const postDeliveryNoteDetails = async (id, details) => {
  let submitSuccess = false;
  if (details && details.length > 0) {
    await pgsService
      .post("/DeliveryNoteDetails/" + id, details)
      .then((r) => {
        toastr.success("Delivery Note Created Successfully");
        submitSuccess = true;
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors[0].message !== undefined
        ) {
          toastr.error(e.response.data.errors[0].message);
        } else if (e.response && e.response.data && e.response.data.errors[0]) {
          toastr.error(e.response.data.errors[0]);
        }
      });
    return submitSuccess;
  } else {
    toastr.success("Delivery Note Created Successfully");
    return true;
  }
};
export const getFliteredDeliveryNoteList = async (reqBody) => {
  let deliveryNoteList = [];
  await pgsService
    .post("/DeliveryNoteAdvanceSerach", reqBody)
    .then((r) => {
      deliveryNoteList = r;
    })
    .catch((e) => { });
  return deliveryNoteList;
};
export const getFliteredDeliveryNoteDetailList = async (reqBody) => {
  let deliveryNoteList = [];
  await pgsService
    .post("/DeliveryNoteDetailsAdvanceSerach", reqBody)
    .then((r) => {
      deliveryNoteList = r;
    })
    .catch((e) => { });
  return deliveryNoteList;
};

export const updateDeliveryNote = async (deliveryNoteId, data, details) => {
  let submitSuccess = false;
  await pgsService
    .put("/DeliveryNotes/" + deliveryNoteId, data)
    .then((r) => {
      let id = r.data.deliveryNoteID;
      details && details.map((d) => (d.deliveryNoteID = id));
      submitSuccess = updateDeliveryNoteDetails(deliveryNoteId, details);
      //toastr.success("Collection Note Updated Successfully");
    })
    .catch((e) => {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors[0].message !== undefined
      ) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return submitSuccess;
};
export const updateDeliveryNoteDetails = async (deliveryNoteId, details) => {
  let submitSuccess = false;
  await pgsService
    .put("/DeliveryNoteDetails/" + deliveryNoteId, details)
    .then((r) => {
      toastr.success("Delivery Note Updated Successfully");
      submitSuccess = true;
    })
    .catch((e) => {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors[0].message !== undefined
      ) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return submitSuccess;
};
export const deleteDeliveryNote = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete("/DeliveryNotes", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Delivery Note Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

//-------Delivery Note ends------------------//

export const getCollectionStatusList = async () => {
  let statusList = [];
  await pgsService
    .get("/GetCollectionStatus", {
      headers: {
        Authorization: "Bearer " + window.localStorage.token,
      },
    })
    .then((r) => {
      statusList = r;
    })
    .catch((e) => { });
  return statusList;
};

export const getDeliveryStatusList = async () => {
  let statusList = [];
  await pgsService
    .get("/GetDeliveryNoteStatus", {
      headers: {
        Authorization: "Bearer " + window.localStorage.token,
      },
    })
    .then((r) => {
      statusList = r;
    })
    .catch((e) => { });
  return statusList;
};

export const getFromAddress = async () => {
  let fromAddress = "";
  await pgsService
    .get("/GetFromAddress", {
      headers: {
        Authorization: "Bearer " + window.localStorage.token,
      },
    })
    .then((r) => {
      fromAddress = r.data.deliverFromAddress;
    })
    .catch((e) => { });
  return fromAddress;
};
export const getToAddress = async () => {
  let toAddress = "";
  await pgsService
    .get("/GetToAddress", {
      headers: {
        Authorization: "Bearer " + window.localStorage.token,
      },
    })
    .then((r) => {
      toAddress = r.data.collectionToAddress;
    })
    .catch((e) => { });
  return toAddress;
};
export const getCountryDropdownList = async () => {
  let countryList = [];
  await pgsService
    .get("/GetCountryList", {
      headers: {
        Authorization: "Bearer " + window.localStorage.token,
      },
    })
    .then((r) => {
      countryList = r;
    })
    .catch((e) => { });
  return countryList;
};
