import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withRouter } from "react-router-dom";
import {Autocomplete} from "@material-ui/lab" ;
import {
  getMenu,
  updateMenuID,
  getSuperMenuList
} from "../../userAccess/userAccessAction";
import Validator from "../../../shared/validator";
// Include new StateContext
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";
import { addStyles } from "../../../shared/styles";

const validateNotRequired100 = new Validator("maxlength100AllAllow");
const validateNotRequired500 = new Validator("maxlength100AllAllow");
const onlyNumbers = new Validator("onlyNumbers");

class EditMenu extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      menu: {
        superMenuName: "",
        superMenuID: "",
        menuName: "",
        description: "",
        orderNo: "",
        isActive: false,
        modifiedBy: 1
      },
      errors: {},
      active: false,
      selectedDDL: 0,
      superMenuValid: true,
      menuNameValid: true,
      descriptionValid: true,
      orderNoValid: true,
      superMenuList: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let menuId = this.props.match.params.menuId;
    this.props.setSelectedList("adminSettingOpen", "menu");
    getMenu(menuId).then(r => {
      dispatch({
        type: "getMenu",
        newStore: r
      });
      this.setState({ menu: r });
      if (r.isActive) this.setState({ active: r.isActive });
    });
    getSuperMenuList().then(r => {
      dispatch({
        type: "getSuperMenuList",
        newStore: r.data
      });
      //this.setState({superMenuList: r.data});
      this.getAllSuperMenus(r.data);
    });
  }
  getAllSuperMenus = list => {
    let superMenuList = [];
    list &&
      list.length > 0 &&
      list.map(c =>
        c.isActive
          ? superMenuList.push({
              key: c.superMenuID,
              value: c.superMenuName,
              active: c.isActive
            })
          : superMenuList
      );
    this.setState({
      superMenuList: superMenuList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      )
    });
  };

  handleChange = event => {
    const { menu } = this.state;
    if (event.target.name === "isActive") {
      menu[event.target.name] = event.target.checked;
    } else {
      menu[event.target.name] = event.target.value;
    }
    this.setState({ menu });

    this.validateField(event.target.name, event.target.value);
  };

  handleSuperMenuChange = (event, values) => {
    const { menu } = this.state;
    if (values === null) {
      menu["superMenuID"] = "null";
    } else {
      let superMenuID = values.key;
      menu["superMenuID"] = superMenuID;
    }
    this.setState({ menu });

    this.validateField("superMenuID", menu["superMenuID"]);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let menuNameValid = this.state.menuNameValid;
    let superMenuValid = this.state.superMenuValid;
    let descriptionValid = this.state.descriptionValid;
    let orderNoValid = this.state.orderNoValid;
    switch (fieldName) {
      case "menuName":
        if (value !== "") {
          menuNameValid = validateNotRequired100(value);
          fieldValidationErrors.menuName = menuNameValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          menuNameValid = false;
          fieldValidationErrors.menuName = "";
        }
        break;
      case "superMenuID":
        if (value !== "null") {
          superMenuValid = onlyNumbers(value);
          fieldValidationErrors.superMenuID = superMenuValid
            ? ""
            : " Please select Super Menu";
        } else {
          superMenuValid = false;
          fieldValidationErrors.superMenuID = "";
        }
        break;
      case "description":
        if (value !== "") {
          descriptionValid = validateNotRequired500(value);
          fieldValidationErrors.description = descriptionValid
            ? ""
            : resourceValidation.ValidDescription;
        } else {
          descriptionValid = false;
          fieldValidationErrors.description = "";
        }
        break;
      case "orderNo":
        if (value !== "" && value !== "null") {
          orderNoValid = onlyNumbers(value);
          fieldValidationErrors.orderNo = orderNoValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          orderNoValid = false;
          fieldValidationErrors.orderNo = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        menuNameValid: menuNameValid,
        superMenuValid: superMenuValid,
        descriptionValid: descriptionValid,
        orderNoValid: orderNoValid
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.menuNameValid &&
        this.state.superMenuValid &&
        this.state.descriptionValid &&
        this.state.orderNoValid
    });
    return this.state.formValid;
  }
  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    let menuId = this.props.match.params.menuId;
    const { menu } = this.state;
    menu.modifiedBy = localStorage.loggedInId;
    updateMenuID(menuId, menu).then(r => {
      this.props.history.push("/Menu");
      //toastr.success("Menu Updated Successfully");
    });
  }

  render() {
    let { classes } = this.props;
    let { menu, active, selectedDDL, superMenuList } = this.state;
    let loop = 0;
    superMenuList.map(option =>
      option.key === this.state.menu.superMenuID
        ? (selectedDDL = loop)
        : (loop = loop + 1)
    );
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Menu</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    className={classes.comboBox}
                    autoComplete
                    includeInputInList
                    id="superMenu"
                    name="superMenu"
                    options={superMenuList}
                    onChange={this.handleSuperMenuChange}
                    getOptionLabel={option => option.value}
                    value={superMenuList[selectedDDL]}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={"Select Super Menu"}
                        name="superMenuName"
                        id="superMenuName"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={menu.superMenuName}
                        error={this.state.errors.superMenuID}
                        helperText={
                          this.state.errors.superMenuID
                            ? this.state.errors.superMenuID
                            : ""
                        }
                      />
                    )}
                  />

                  <TextField
                    required
                    multiline={false}
                    rows={1}
                    rowsMax={4}
                    inputProps={{
                      maxLength: 100
                    }}
                    label="Description"
                    name="description"
                    margin="normal"
                    className={classes.textField}
                    value={menu.description}
                    onChange={this.handleChange}
                    error={this.state.errors.description}
                    helperText={
                      this.state.errors.description
                        ? this.state.errors.description
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    label="Order Number"
                    name="orderNo"
                    inputProps={{
                      maxLength: 3
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={menu.orderNo}
                    onChange={this.handleChange}
                    error={this.state.errors.orderNo}
                    helperText={
                      this.state.errors.orderNo ? this.state.errors.orderNo : ""
                    }
                    onKeyPress={this._onNumberKeyPress}
                  />
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      name="isActive"
                      className={classes.checkBox}
                      margin="normal"
                      control={
                        <Checkbox
                          color="primary"
                          checked={menu.isActive}
                          onChange={this.handleChange}
                          value={menu.isActive}
                        />
                      }
                      label="Active"
                    />
                  )}
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={e => this.props.history.push("/menu")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    <Button
                      onClick={this.handleSubmit}
                      disabled={!this.state.formValid}
                      autoFocus
                      variant="contained"
                      className={
                        !this.state.formValid
                          ? "disabled-button"
                          : "enabled-button "
                      }
                    >
                      SUBMIT
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditMenu));
