import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { Autocomplete } from "@material-ui/lab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { resourceValidation } from "../../../shared/resource";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  getCustomerList,
  getLocationList,
  getDispatchTypeList,
  getDispatchModeList,
  getMailingSchemeLists,
  getDDistributorList,
  getSubscriptionList,
  getCustomerDDList,
  getRoutingCustomerList,
} from "../../masters/masterActions";
import { postRoutingEMLOEBLO } from "../routingActions";
import { StateContext } from "../../../shared/globalState";
import { addStyles } from "../../../shared/styles";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
const Reorderqty = new Validator("Reorderqty");
const onlyNumbers = new Validator("onlyNumbers");
const generalValidator = new Validator("general");
const checkrate = new Validator("checkdecimalpoint");

class AddRoutingEMLOEBLO extends React.Component {
  static contextType = StateContext;
  constructor() {
    super();

    this.state = {
      routingEMLOEBLO: {
        customerID: "",
        subscriptionID: "",
        locationID: "",
        dispatchTypeID: "",
        dispatchModeID: "",
        mailingSchemeID: "",
        noOfOrdersFrom: "0",
        noOfOrdersTo: "0",
        noOfCopiesFrom: "0",
        noOfCopiesTo: "0",
        weightFrom: "0",
        weightTo: "0",
        distributorID: "",
        subscription: "",
        customer: "",
        location: "",
        dispatchType: "",
        dispatchMode: "",
        mailingScheme: "",
        // weightFrom: 0.0,
        // weightTo: 0.0,
        distributor: "",
      },
      errors: {},
      subscriptionValid: false,
      customerValid: false,
      locationValid: false,
      dispatchTypeValid: false,
      dispatchModeValid: false,
      mailingschemeValid: false,
      distributorValid: false,
      noOfOrdersFromValid: true,
      noOfOrdersToValid: true,
      noOfCopiesFromValid: true,
      noOfCopiesToValid: true,
      weightFromValid: true,
      weightToValid: true,

      countryList: [],
      customersList: [],
      LocationList: [],
      DispatchModeList: [],
      DispatchTypeList: [],
      DistributorList: [],
      mailingSchemeList: [],
      SubscriptionList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  _onCodeKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onCodeKeyPress1(event) {
    const re = /^[0-9.]{1,3}(?:\.[0-9]{1,2})?$/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }
  componentDidMount() {
    //const list = this.context;

    getSubscriptionList().then((r) => {
      r.data &&
        this.setState({
          SubscriptionList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.subscriptionName.toLowerCase() >
              b.subscriptionName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });

    getRoutingCustomerList().then((r) => {
      r.data &&
        this.setState({
          customersList: r.data
            // .filter(x => x.isActive === true)
            .sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
    });

    getMailingSchemeLists().then((r) => {
      r.data &&
        this.setState(
          {
            mailingSchemeList: r.data
              .filter((x) => x.isActive === true)
              .sort((a, b) =>
                a.mailingScheme.toLowerCase() > b.mailingScheme.toLowerCase()
                  ? 1
                  : -1
              ),
          },
          () => {
            let newMSL = [...this.state.mailingSchemeList];
            newMSL.push({
              mailingScheme: "None",
              mailingSchemeID: null,
            });
            this.setState({ mailingSchemeList: newMSL });
          }
        );
    });

    getDDistributorList().then((r) => {
      r.data &&
        this.setState(
          {
            DistributorList: r.data
              .filter((x) => x.isActive === true)
              .sort((a, b) =>
                a.distributorName.toLowerCase() >
                b.distributorName.toLowerCase()
                  ? 1
                  : -1
              ),
          },
          () => {
            let newDistributorList = [...this.state.DistributorList];
            newDistributorList.push({
              distributorName: "None",
              distributorID: null,
            });
            this.setState({ DistributorList: newDistributorList });
          }
        );
    });

    getDispatchTypeList().then((r) => {
      r.data &&
        this.setState({
          DispatchTypeList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.dispatchTypeCodeName.toLowerCase() >
              b.dispatchTypeCodeName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });

    getDispatchModeList().then((r) => {
      r.data &&
        this.setState(
          {
            DispatchModeList: r.data
              .filter((x) => x.isActive === true)
              .sort((a, b) =>
                a.dispatchModeName.toLowerCase() >
                b.dispatchModeName.toLowerCase()
                  ? 1
                  : -1
              ),
          },
          () => {
            let newDispatchModeList = [...this.state.DispatchModeList];
            newDispatchModeList.push({
              dispatchModeName: "None",
              dispatchModeID: null,
            });
            this.setState({ DispatchModeList: newDispatchModeList });
          }
        );
    });

    getLocationList().then((r) => {
      r.data &&
        this.setState({
          LocationList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.locationName.toLowerCase() > b.locationName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });

    this.props.handleSettingHeader("Routing EMLO EBLO");
  }

  handleChange1 = (event) => {
    const { routingEMLOEBLO } = this.state;
    let dhlValues = event.target.value;
    dhlValues = this.checkDHlWeightDec(event.target.value.toString());

    routingEMLOEBLO[event.target.name] = dhlValues;
    this.validateField(event.target.name, dhlValues);
    this.setState({ routingEMLOEBLO });
  };
  handleCustomer = (event, values) => {
    event.persist();

    let name = values && values.text;
    let customerID = values && values.id;
    const { routingEMLOEBLO } = this.state;
    routingEMLOEBLO["customer"] = name;
    routingEMLOEBLO["customerID"] = customerID;
    this.validateField("customer", customerID);
    this.setState({ routingEMLOEBLO });
  };
  handleLocation = (event, values) => {
    event.persist();
    let locationName = values && values.locationName;
    let locationID = values && values.locationID;
    const { routingEMLOEBLO } = this.state;
    routingEMLOEBLO["location"] = locationName;
    routingEMLOEBLO["locationID"] = locationID;
    this.validateField("location", locationID);
    this.setState({ routingEMLOEBLO });
    // console.log(routingPriority);
  };
  handleChange = (event) => {
    const { routingEMLOEBLO } = this.state;
    if (event.target.name === "addressType") {
      routingEMLOEBLO[event.target.name] = event.target.checked;
      this.setState({ formValid: true });
    } else {
      routingEMLOEBLO[event.target.name] = event.target.value;
    }

    this.setState({ routingEMLOEBLO });

    this.validateField(event.target.name, event.target.value);
  };
  handleDistributorChange = (event, values) => {
    const { routingEMLOEBLO } = this.state;
    let distributorID = values && values.distributorID;
    routingEMLOEBLO["distributor"] = values;
    routingEMLOEBLO["distributorID"] = distributorID;
    //this.validateField("distributor", distributorID);
    this.setState({ routingEMLOEBLO });
  };
  handleDispatchChange = (event, values) => {
    const { routingEMLOEBLO } = this.state;
    let dispatchTypeID = values && values.dispatchTypeID;
    routingEMLOEBLO["dispatchType"] = values;
    routingEMLOEBLO["dispatchTypeID"] = dispatchTypeID;
    this.validateField("dispatch", dispatchTypeID);
    this.setState({ routingEMLOEBLO });
  };
  handleDispatchModeChange = (event, values) => {
    const { routingEMLOEBLO } = this.state;
    let dispatchModeID = values && values.dispatchModeID;
    routingEMLOEBLO["dispatchMode"] = values;
    routingEMLOEBLO["dispatchModeID"] = dispatchModeID;
    // this.validateField("dispatchMode", dispatchModeID);
    this.setState({ routingEMLOEBLO });
  };
  handleMailingSchemeChange = (event, values) => {
    const { routingEMLOEBLO } = this.state;
    let mailingSchemeID = values && values.mailingSchemeID;
    routingEMLOEBLO["mailingScheme"] = values;
    routingEMLOEBLO["mailingSchemeID"] = mailingSchemeID;
    // this.validateField("mailingScheme", mailingSchemeID);
    this.setState({ routingEMLOEBLO });
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let customerValid = this.state.customerValid;
    let locationValid = this.state.locationValid;
    let dispatchTypeValid = this.state.dispatchTypeValid;
    let subscriptionValid = this.state.subscriptionValid;

    let noOfOrdersFromValid = this.state.noOfOrdersFromValid;
    let noOfOrdersToValid = this.state.noOfOrdersToValid;
    let noOfCopiesFromValid = this.state.noOfCopiesFromValid;
    let noOfCopiesToValid = this.state.noOfCopiesToValid;

    let weightFromValid = this.state.weightFromValid;
    let weightToValid = this.state.weightToValid;
    let ppiNumberValid = this.state.ppiNumberValid;
    let ppiLicenseNoValid = this.state.ppiLicenseNoValid;

    let returnAddressValid = this.state.returnAddressValid;
    let carrierSheetSchemeValid = this.state.carrierSheetSchemeValid;
    let carrierSheetModeValid = this.state.carrierSheetModeValid;
    switch (fieldName) {
      case "returnAddress":
        returnAddressValid = generalValidator(value);
        fieldValidationErrors.returnAddress = returnAddressValid
          ? ""
          : " Please Enter Alphabets only";
        break;
      case "carrierSheetScheme":
        fieldValidationErrors.carrierSheetScheme = "";
        if (value !== "") {
          carrierSheetSchemeValid = generalValidator(value);
          fieldValidationErrors.carrierSheetScheme = carrierSheetSchemeValid
            ? ""
            : " Please Enter Alphabets only";
        }
        break;
      case "carrierSheetMode":
        fieldValidationErrors.carrierSheetMode = "";
        if (value !== "") {
          carrierSheetModeValid = generalValidator(value);
          fieldValidationErrors.carrierSheetMode = carrierSheetModeValid
            ? ""
            : " Please Enter AlphaNumeric only";
        }
        break;

      case "ppiNumber":
        //ppiNumberValid = Alphanumeric(value);
        ppiNumberValid = generalValidator(value);
        fieldValidationErrors.ppiNumber = ppiNumberValid
          ? ""
          : " Please Enter AlphaNumeric only";
        break;
      case "ppiLicenseNo":
        ppiLicenseNoValid = generalValidator(value);
        fieldValidationErrors.ppiLicenseNo = ppiLicenseNoValid
          ? ""
          : " Please Enter AlphaNumeric only";
        break;
      case "weightFrom":
        //fieldValidationErrors.weightFrom = "";
        if (value !== "") {
          weightFromValid = checkrate(value);
          fieldValidationErrors.weightFrom = weightFromValid
            ? ""
            : " Please Enter valid Weight From";
        } else {
          weightFromValid = false;
          fieldValidationErrors.weightFrom = "";
        }

        break;
      case "weightTo":
        //fieldValidationErrors.weightTo = "";
        if (value !== "") {
          weightToValid = checkrate(value);
          fieldValidationErrors.weightTo = weightToValid
            ? ""
            : " Please Enter valid Weight To";
        } else {
          weightToValid = false;
          fieldValidationErrors.weightTo = "";
        }

        break;
      case "noOfCopiesFrom":
        //fieldValidationErrors.noOfCopiesFrom = "";
        if (value !== "") {
          noOfCopiesFromValid = Reorderqty(value);
          fieldValidationErrors.noOfCopiesFrom = noOfCopiesFromValid
            ? ""
            : " Please Enter Numeric only";
        } else {
          noOfCopiesFromValid = false;
          fieldValidationErrors.noOfCopiesFrom = "";
        }
        break;
      case "noOfCopiesTo":
        //fieldValidationErrors.noOfCopiesTo = "";
        if (value !== "") {
          noOfCopiesToValid = Reorderqty(value);
          fieldValidationErrors.noOfCopiesTo = noOfCopiesToValid
            ? ""
            : " Please Enter Numeric only";
        } else {
          noOfCopiesToValid = false;
          fieldValidationErrors.noOfCopiesTo = "";
        }
        break;
      case "noOfOrdersTo":
        if (value !== "") {
          noOfOrdersToValid = Reorderqty(value);
          fieldValidationErrors.noOfOrdersTo = noOfOrdersToValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          noOfOrdersToValid = false;
          fieldValidationErrors.noOfOrdersTo = "";
        }

        break;
      case "noOfOrdersFrom":
        if (value !== "") {
          noOfOrdersFromValid = Reorderqty(value);
          fieldValidationErrors.noOfOrdersFrom = noOfOrdersFromValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          noOfOrdersFromValid = false;
          fieldValidationErrors.noOfOrdersFrom = "";
        }

        break;

      case "location":
        locationValid = onlyNumbers(value);
        fieldValidationErrors.location = locationValid
          ? ""
          : " Please select Location";
        break;
      case "subscription":
        subscriptionValid = onlyNumbers(value);
        fieldValidationErrors.subscription = subscriptionValid
          ? ""
          : " Please select Subscription";
        break;
      case "customer":
        customerValid = onlyNumbers(value);
        fieldValidationErrors.customer = customerValid
          ? ""
          : " Please select Customer";
        break;
      case "dispatch":
        dispatchTypeValid = onlyNumbers(value);
        fieldValidationErrors.dispatchType = dispatchTypeValid
          ? ""
          : " Please select Dispatch Type";
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        customerValid: customerValid,
        locationValid: locationValid,
        subscriptionValid: subscriptionValid,
        dispatchTypeValid: dispatchTypeValid,
        //  dispatchModeValid: dispatchModeValid,
        // mailingschemeValid: mailingschemeValid,
        //  distributorValid: distributorValid,
        noOfOrdersFromValid: noOfOrdersFromValid,
        noOfOrdersToValid: noOfOrdersToValid,
        noOfCopiesFromValid: noOfCopiesFromValid,
        noOfCopiesToValid: noOfCopiesToValid,
        weightFromValid: weightFromValid,
        weightToValid: weightToValid,
        ppiNumberValid: ppiNumberValid,
        ppiLicenseNoValid: ppiLicenseNoValid,
        returnAddressValid: returnAddressValid,
        carrierSheetModeValid: carrierSheetModeValid,
        carrierSheetSchemeValid: carrierSheetSchemeValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.subscriptionValid &&
        this.state.customerValid &&
        this.state.locationValid &&
        this.state.dispatchTypeValid &&
        this.state.noOfOrdersFromValid &&
        this.state.noOfOrdersToValid &&
        this.state.noOfCopiesFromValid &&
        this.state.noOfCopiesToValid &&
        this.state.weightFromValid &&
        this.state.weightToValid &&
        this.state.ppiNumberValid &&
        this.state.ppiLicenseNoValid,
    });
    return this.state.formValid;
  }
  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      cptOfficeType: "",
      objectCode: "",
      subCode: "",
      cptDescription: "",
      sapglAccount: "",
      errors: {},
    });
  };
  onBlur = (event) => {
    const { routingEMLOEBLO } = this.state;
    let dhlValues = event.target.value;
    dhlValues = this.addZeroes(dhlValues.toString(), 3);
    routingEMLOEBLO[event.target.name] = dhlValues;
    this.setState({ routingEMLOEBLO });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      const { routingEMLOEBLO } = this.state;

      let customerID = routingEMLOEBLO.customerID;
      let locationID = routingEMLOEBLO.locationID;
      let subscriptionID = routingEMLOEBLO.subscription;
      let addressType = routingEMLOEBLO.addressType;
      let noOfOrdersFrom = parseInt(routingEMLOEBLO.noOfOrdersFrom);
      let noOfOrdersTo = parseInt(routingEMLOEBLO.noOfOrdersTo);
      let noOfCopiesFrom = parseInt(routingEMLOEBLO.noOfCopiesFrom);
      let noOfCopiesTo = parseInt(routingEMLOEBLO.noOfCopiesTo);
      let weightFrom = parseFloat(routingEMLOEBLO.weightFrom);
      let weightTo = parseFloat(routingEMLOEBLO.weightTo);
      let ppiNumber = routingEMLOEBLO.ppiNumber;
      let ppiLicenseNo = routingEMLOEBLO.ppiLicenseNo;
      let dispatchTypeID = routingEMLOEBLO.dispatchTypeID;
      let dispatchModeID = routingEMLOEBLO.dispatchModeID;
      let mailingSchemeID = routingEMLOEBLO.mailingSchemeID;
      let distributorID = routingEMLOEBLO.distributorID;
      let returnAddress = routingEMLOEBLO.returnAddress;
      let carrierSheetScheme = routingEMLOEBLO.carrierSheetScheme;
      let carrierSheetMode = routingEMLOEBLO.carrierSheetMode;
      let isActive = "true";
      let createdBy = localStorage.loggedInId;

      let reqBody = {
        customerID,
        locationID,
        subscriptionID,
        addressType,
        noOfOrdersFrom,
        noOfOrdersTo,
        noOfCopiesFrom,
        noOfCopiesTo,
        weightFrom,
        weightTo,
        ppiNumber,
        ppiLicenseNo,
        dispatchTypeID,
        dispatchModeID,
        mailingSchemeID,
        distributorID,
        returnAddress,
        carrierSheetScheme,
        carrierSheetMode,
        isActive,
        createdBy,
      };
      postRoutingEMLOEBLO(reqBody, this.props.history);
    }
  };
  render() {
    let { classes } = this.props;
    let {
      routingEMLOEBLO,
      SubscriptionList,
      customersList,
      LocationList,
      DispatchTypeList,
      DispatchModeList,
      mailingSchemeList,
      DistributorList,
    } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Routing EMLO EBLO</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <p className={classes.subHeader}>Enter Customer Information</p>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    required
                    includeInputInList
                    label="Customer"
                    style={{ display: "inline-flex" }}
                    name="customer"
                    options={customersList}
                    className={classes.comboBox}
                    onChange={this.handleCustomer}
                    value={routingEMLOEBLO.customer}
                    getOptionLabel={(option) =>
                      option.text || routingEMLOEBLO.customer
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Customer"
                        name="customer"
                        style={{ width: "100%" }}
                        margin="normal"
                      />
                    )}
                  />
                  <Autocomplete
                    autoComplete
                    required
                    includeInputInList
                    label="Location"
                    name="location"
                    style={{ display: "inline-flex" }}
                    options={LocationList}
                    className={classes.comboBox}
                    onChange={this.handleLocation}
                    value={routingEMLOEBLO.location}
                    getOptionLabel={(option) =>
                      option.locationName || routingEMLOEBLO.location
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Location"
                        name="location"
                        style={{ width: "100%" }}
                        margin="normal"
                      />
                    )}
                  />

                  <TextField
                    required
                    select
                    label="Subscription"
                    name="subscription"
                    className={classes.textField}
                    value={routingEMLOEBLO.subscription}
                    onChange={this.handleChange}
                    error={this.state.errors.subscription}
                    helperText={
                      this.state.errors.subscription
                        ? this.state.errors.subscription
                        : ""
                    }
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                  >
                    {SubscriptionList.map((option) => (
                      <MenuItem
                        key={option.subscriptionID}
                        value={option.subscriptionID}
                      >
                        {option.subscriptionName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {/* <TextField
                  required
                  label="Address Type"
                  name="addressType"
                  inputProps={{
                    maxLength: 3
                  }}
                  margin="normal"
                  required
                  className={classes.textField}
                  value={routingEMLOEBLO.addressType}
                  onChange={this.handleChange}
                  error={this.state.errors.addressType}
                  helperText={
                    this.state.errors.addressType
                      ? this.state.errors.addressType
                      : ""
                  }
                /> */}
                <div>
                  <FormControlLabel
                    name="addressType"
                    className={classes.checkBox}
                    control={
                      <Checkbox
                        color="primary"
                        checked={routingEMLOEBLO.addressType}
                        onChange={this.handleChange}
                        value={routingEMLOEBLO.addressType}
                      />
                    }
                    label="PO BOX"
                  />
                </div>
                <p className={classes.subHeader}>Enter Criteria Setting</p>
                <div>
                  <TextField
                    required
                    label="No Of Orders From"
                    name="noOfOrdersFrom"
                    inputProps={{
                      maxLength: 5,
                    }}
                    margin="normal"
                    className={classes.textField}
                    // onKeyPress={this._onCodeKeyPress}
                    onKeyPress={this._onNumberKeyPress}
                    value={routingEMLOEBLO.noOfOrdersFrom}
                    onChange={this.handleChange}
                    error={this.state.errors.noOfOrdersFrom}
                    helperText={
                      this.state.errors.noOfOrdersFrom
                        ? this.state.errors.noOfOrdersFrom
                        : ""
                    }
                  />
                  <TextField
                    required
                    label="No Of Orders To"
                    name="noOfOrdersTo"
                    inputProps={{
                      maxLength: 5,
                    }}
                    margin="normal"
                    className={classes.textField}
                    // onKeyPress={this._onCodeKeyPress}
                    onKeyPress={this._onNumberKeyPress}
                    value={routingEMLOEBLO.noOfOrdersTo}
                    onChange={this.handleChange}
                    error={this.state.errors.noOfOrdersTo}
                    helperText={
                      this.state.errors.noOfOrdersTo
                        ? this.state.errors.noOfOrdersTo
                        : ""
                    }
                  />
                </div>

                <div>
                  <TextField
                    required
                    label="No Of Copies From"
                    name="noOfCopiesFrom"
                    inputProps={{
                      maxLength: 5,
                    }}
                    margin="normal"
                    className={classes.textField}
                    // onKeyPress={this._onCodeKeyPress}
                    onKeyPress={this._onNumberKeyPress}
                    value={routingEMLOEBLO.noOfCopiesFrom}
                    onChange={this.handleChange}
                    error={this.state.errors.noOfCopiesFrom}
                    helperText={
                      this.state.errors.noOfCopiesFrom
                        ? this.state.errors.noOfCopiesFrom
                        : ""
                    }
                  />
                  <TextField
                    required
                    label="No Of Copies To"
                    name="noOfCopiesTo"
                    inputProps={{
                      maxLength: 5,
                    }}
                    margin="normal"
                    className={classes.textField}
                    // onKeyPress={this._onCodeKeyPress}
                    onKeyPress={this._onNumberKeyPress}
                    value={routingEMLOEBLO.noOfCopiesTo}
                    onChange={this.handleChange}
                    error={this.state.errors.noOfCopiesTo}
                    helperText={
                      this.state.errors.noOfCopiesTo
                        ? this.state.errors.noOfCopiesTo
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    required
                    label="Weight From"
                    id="txtdhlWeight"
                    name="weightFrom"
                    onKeyUp={this._onDecimalKeyPress}
                    className={classes.textField}
                    inputProps={{
                      maxLength: 10,
                    }}
                    margin="normal"
                    autoComplete="off"
                    value={routingEMLOEBLO.weightFrom}
                    error={this.state.errors.weightFrom}
                    onChange={this.handleChange1}
                    onBlur={this.onBlur}
                    helperText={
                      this.state.errors.weightFrom
                        ? this.state.errors.weightFrom
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Kg</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    required
                    label="Weight To"
                    id="txtdhlWeight"
                    name="weightTo"
                    onKeyUp={this._onDecimalKeyPress}
                    className={classes.textField}
                    inputProps={{
                      maxLength: 10,
                      minLength: 2,
                      pattern: /^[0-9]\d*(?:\.\d{0,2})?$/,
                    }}
                    margin="normal"
                    autoComplete="off"
                    value={routingEMLOEBLO.weightTo}
                    error={this.state.errors.weightTo}
                    onChange={this.handleChange1}
                    onBlur={this.onBlur}
                    helperText={
                      this.state.errors.weightTo
                        ? this.state.errors.weightTo
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Kg</InputAdornment>
                      ),
                    }}
                  />
                </div>

                <p className={classes.subHeader}>Enter Routing Configuration</p>
                <div>
                  <TextField
                    required
                    label="Route PPI"
                    name="ppiNumber"
                    inputProps={{
                      maxLength: 50,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingEMLOEBLO.ppiNumber}
                    onChange={this.handleChange}
                    error={this.state.errors.ppiNumber}
                    helperText={
                      this.state.errors.ppiNumber
                        ? this.state.errors.ppiNumber
                        : ""
                    }
                  />
                  <TextField
                    required
                    label="PPI License No"
                    name="ppiLicenseNo"
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingEMLOEBLO.ppiLicenseNo}
                    onChange={this.handleChange}
                    error={this.state.errors.ppiLicenseNo}
                    helperText={
                      this.state.errors.ppiLicenseNo
                        ? this.state.errors.ppiLicenseNo
                        : ""
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    className={classes.textField}
                    required
                    id="dispatchType"
                    options={DispatchTypeList}
                    onChange={this.handleDispatchChange}
                    value={routingEMLOEBLO.dispatchType}
                    getOptionLabel={(option) => option.dispatchTypeCodeName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Dispatch Type"
                        required
                        name="dispatchType"
                        id="dispatchType"
                        margin="normal"
                        required
                        style={{ width: "100%" }}
                        value={routingEMLOEBLO.dispatchType}
                        error={this.state.errors.dispatchType}
                        helperText={
                          this.state.errors.dispatchType
                            ? this.state.errors.dispatchType
                            : ""
                        }
                      />
                    )}
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    className={classes.textField}
                    id="dispatchMode"
                    options={DispatchModeList}
                    onChange={this.handleDispatchModeChange}
                    value={routingEMLOEBLO.dispatchMode}
                    getOptionLabel={(option) =>
                      option.dispatchModeName || routingEMLOEBLO.dispatchMode
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Dispatch Mode"
                        name="dispatchMode"
                        id="dispatchMode"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={routingEMLOEBLO.dispatchMode}
                        error={this.state.errors.dispatchMode}
                        helperText={
                          this.state.errors.dispatchMode
                            ? this.state.errors.dispatchMode
                            : ""
                        }
                      />
                    )}
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    className={classes.textField}
                    id="mailingScheme"
                    options={mailingSchemeList}
                    onChange={this.handleMailingSchemeChange}
                    value={routingEMLOEBLO.mailingScheme}
                    getOptionLabel={(option) =>
                      option.mailingScheme || routingEMLOEBLO.mailingScheme
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Mailing Scheme"
                        name="mailingScheme"
                        id="mailingScheme"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={routingEMLOEBLO.mailingScheme}
                        error={this.state.errors.mailingScheme}
                        helperText={
                          this.state.errors.mailingScheme
                            ? this.state.errors.mailingScheme
                            : ""
                        }
                      />
                    )}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    className={classes.textField}
                    id="distributor"
                    options={DistributorList}
                    onChange={this.handleDistributorChange}
                    value={routingEMLOEBLO.distributor}
                    getOptionLabel={(option) =>
                      option.distributorName || routingEMLOEBLO.distributor
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Distributor"
                        name="distributor"
                        id="distributor"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={routingEMLOEBLO.distributor}
                        error={this.state.errors.distributor}
                        helperText={
                          this.state.errors.distributor
                            ? this.state.errors.distributor
                            : ""
                        }
                      />
                    )}
                  />
                  <TextField
                    label="Return Address"
                    name="returnAddress"
                    inputProps={{
                      maxLength: 200,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingEMLOEBLO.returnAddress}
                    onChange={this.handleChange}
                    error={this.state.errors.returnAddress}
                    helperText={
                      this.state.errors.returnAddress
                        ? this.state.errors.returnAddress
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    label="Carrier Sheet Mode"
                    name="carrierSheetMode"
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingEMLOEBLO.carrierSheetMode}
                    onChange={this.handleChange}
                    error={this.state.errors.carrierSheetMode}
                    helperText={
                      this.state.errors.carrierSheetMode
                        ? this.state.errors.carrierSheetMode
                        : ""
                    }
                  />
                  <TextField
                    label="Carrier Sheet Scheme"
                    name="carrierSheetScheme"
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingEMLOEBLO.carrierSheetScheme}
                    onChange={this.handleChange}
                    error={this.state.errors.carrierSheetScheme}
                    helperText={
                      this.state.errors.carrierSheetScheme
                        ? this.state.errors.carrierSheetScheme
                        : ""
                    }
                  />
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div
                  className="button-wrapper"
                  style={{ paddingLeft: "0px", marginLeft: "0px" }}
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/routingEMLOEBLO")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                        //className="enabled-button"
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                        //className="enabled-button"
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddRoutingEMLOEBLO));
