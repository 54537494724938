import React from "react";
import Footer from "./footer";
import { StateProvider } from "../../shared/globalState";
import { masterReducers } from "../../components/masters/masterReducers";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ErrorBoundary from "../../shared/errorBoundary";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Switch, BrowserRouter } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import toastr from "toastr"
import Routes from "./routes";
import { tbFunc } from "../../shared/tabWidthHandler"
import "../../shared/testFlexiCol/tab.css"
import { postLoginRefresh, getRoleAccess } from "../userAccess/userAccessAction";
import moment from "moment";
// import { getRoleAccess } from "../userAccess/userAccessAction";

let count = 0;
let parse_date = 0;
let refreshInterval;

export const AuthContext = React.createContext();
var jwt = require("jsonwebtoken");
let history = createBrowserHistory();
const theme = createMuiTheme({
  palette: {
    primary: { main: "#3982ea" },
  },
});

const initialState = {
  store: {
    deptList: [],
    departmentData: {},
    countryList: [],
    country: {},
    journalVersionList: [],
    journalVersion: {},
    customerList: [],
    userList: [],
    user: {},
    letterShopList: [],
    cciList: [],
    ppiList: [],
    zoneList: [],
    zone: {},
    loggedInUser: {},
    cptCode: {},
    cptCodeList: [],
    RoutingZone: {},
    RoutingZoneList: [],
    LocationList: [],
    SubscriptionList: [],
    RoutingCountry: {},
    RoutingCountryList: [],
    RoutingEMLOEBLOList: [],
    RoutingEMLOEBLO: {},
    RoutingTitle: {},
    RoutingTitleList: [],
    RoutingUKUS: {},
    RoutingUKUSList: [],
    RoutingPriority: {},
    RoutingPriorityList: [],
    AcronymList: [],
    countryDDL: [],
    roleList: [],
    superMenuList: [],
    menuList: [],
    subMenuList: [],
    mailingSchemeList: [],
    roleActionList: [],
    masterRoleActionList: [],
    customerPPIList: [],
    filteredCustomerList: [],
    zoneCode: {},
    processTypeList: [],
    provinceList: [],
    codeMasterList: [],
    outworkerClaimRateList: [],
    processList: [],
    DistributorList: [],
    lettershopdata: {},
    dispatchModeList: [],
    DispatchTypeList: [],
    dhlRateList: [],
    dhlRate: {},
    localPostage: [],
    customerDDList: [],
    userDDList: [],
    warehouseLocationList: [],
    warehouselocationdata: {},
    ItemMasterList: [],
    ItemMasterData: {},
    CustomerData: {},
    UOMList: [],
    StockCategoryList: [],
    DistributorData: {},
    acronymList: [],
    acronym: {},
    productionLineCode: {},
    productionLineCodeList: [],
    codeTypeCode: {},
    codeTypeList: [],
    JournalVersionList: [],
    JournalVersionData: {},
    StockoutList: [],
    StockoutData: {},
    serviceList: [],
    placedOrderList: [],
    collectionNoteList: [],
    deliveryNoteList: [],
    docketHeaderList: [],
    docketHeaderData: {},
    selectedDocketIds: "",
    docketList: [],
    docketListData: {},
  },
  isAuthenticated: false,
  user: null,
  token: null,
};

class App extends React.Component {
  state = {
    auth: false,
    userName: "",
    isExpired: false,
  };
  componentDidMount() {
    let user = localStorage.getItem("userName");
    let auth = localStorage.getItem("auth");
    if (localStorage.loggedInId) this.setState({ auth, userName: user });
  }

  shouldComponentUpdate(s, p) {
    let user = localStorage.getItem("userName");
    let auth = localStorage.getItem("auth");
    let refreshTime = parseInt(localStorage.getItem("refreshTime"));
    let info = localStorage.getItem("info")

    if (info) {
      if (info.length > 0)
        try {
          let base64Url = info
          let base64 = base64Url.replace("-", "+").replace("_", "/");
          let decodedData = JSON.parse(
            Buffer.from(base64, "base64").toString("binary")
          );

          let lsId = localStorage.getItem("loggedInId")
          let lsDeptName = localStorage.getItem("department")
          let lsDeptCode = localStorage.getItem("departmentCode")
          let lsSub = localStorage.getItem("sub")
          let lsRefreshTime = localStorage.getItem("refreshTime")
          let lsReportUrl = localStorage.getItem("ReportURLByID")
          let lsExpTime = localStorage.getItem("ExpiryTime")
          let lsIsuTime = localStorage.getItem("IssuedTime")
          let lsPEN = localStorage.getItem("PwdExpiryNotification")
          let decoded_time = Math.round(decodedData.exp * 1000);
          let issued_time = Math.round(decodedData.iat * 1000);
          let lsIsSingpost = localStorage.getItem("isSingpostUser")
          let lsLoggindInUser = localStorage.getItem("userName")
          let auth = localStorage.getItem("auth")


          if (decodedData.id != lsId ||
            decodedData.Department != lsDeptName ||
            decodedData.DepartmentCode != lsDeptCode ||
            decodedData.sub != lsSub ||
            decodedData.refreshTime != lsRefreshTime ||
            // decodedData.ReportURLByID != lsReportUrl ||
            decodedData.PwdExpiryNotification != lsPEN ||

            decoded_time != lsExpTime ||
            issued_time != lsIsuTime
            // auth != true||
          ) {
            localStorage.clear()
            toastr.error("Please Login again !")
          }
          if (localStorage.getItem("userData")) {
            let userData = localStorage.getItem("userData")
            let userDataRow = atob(userData)
            let parsedUD = JSON.parse(userDataRow)
            if (parsedUD && parsedUD.user) {
              if (parsedUD.user != lsLoggindInUser ||
                parsedUD.isSingPost != lsIsSingpost) {
                localStorage.clear()
                toastr.error("Please Login again !")
              }
            }
          }


        }
        catch {
          localStorage.clear()
          toastr.error("Please Login again !")
        }

    }


    // if (window.location.pathname.split("/")[1] !== "runMailList")
    // if (localStorage.loggedInId && localStorage.loggedInId !== "undefined") {
    //   // console.log(localStorage.loggedInId)
    //   getRoleAccess(localStorage.loggedInId, this.props.history)
    // }
    // if (localStorage.data) {
    //   let d = localStorage.data

    // }
    // getRoleAccess
    // console.log()

    tbFunc()
    if (this.state.userName !== user) {
      this.setState({ userName: user, auth });
    }
    var test_data = new Date();
    var newDate = Math.round(moment.utc(test_data)._d.getTime());
    if (count == 0) {
      parse_date = parseInt(window.localStorage.ExpiryTime);
    }

    if (count == 0) {
      count = count + 1;
      refreshInterval = setInterval(() => {
        if (user) {
          this.getRefreshToken();
        } else {
          clearInterval(refreshInterval);
        }
      }, refreshTime);
    }

    if (count !== 0 && newDate > parse_date) {
      parse_date = newDate + refreshTime; // logout time is 20mins,change when needed
      this.getRefreshToken();
      count = count + 1;
    }

    return true;
  }

  getRefreshToken = async () => {
    let token = window.localStorage.token;
    let refreshToken = window.localStorage.refreshToken;
    let reqBody = {
      token: token,
      refreshToken: refreshToken,
    };


    await postLoginRefresh(reqBody, this.props.history).then((stat) => {
      if (stat == false) {
        clearInterval(refreshInterval);
      }
    });
  };

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <StateProvider initialState={initialState} reducer={masterReducers}>
            {" "}
            <div className="App">
              <div style={{ minHeight: "94vh" }}>
                <Switch>
                  <ErrorBoundary>
                    {" "}
                    <Routes auth={this.state.auth} user={this.state.userName} />
                  </ErrorBoundary>
                </Switch>
              </div>

              <Footer history={history} />
            </div>{" "}
          </StateProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}
export default withRouter(App);
