import EmailValidator from "email-validator";
export default function Validator(type) {
  switch (type) {
    case "email":
      return function emailValidator(val) {
        if (EmailValidator.validate(val)) return true;
        else return false;
      };
    case "mobile":
      return function mobileValidator(val) {
        if (/^(\+?(\d){2}-?)?(\d){10}$/.test(val.trim())) return true;
        else return false;
      };
    case "general":
      return function generalValidator(val) {
        if (val !== "") return true;
        else return false;
      };
    case "pincode":
      return function pincodeValidator(val) {
        let pattern = /^\d{6}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength2":
      return function maxlengthValidator2(val) {
        let pattern = /^[a-zA-Z0-9]{1,2}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxmin2length":
      return function maxmin2length(val) {
        let pattern = /^[a-zA-Z]{2,2}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxMinlength2":
      return function maxlengthValidator2(val) {
        let pattern = /^[a-zA-Z]$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlengthWithNumber2":
      return function maxlengthWithNumberValidator2(val) {
        let pattern = /^[a-zA-Z0-9]{1,2}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlengthWithNumber4":
      return function maxlengthWithNumberValidator3(val) {
        let pattern = /^[a-zA-Z0-9]{1,4}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength3":
      return function maxlengthValidator3(val) {
        let pattern = /^[a-zA-Z0-9]{1,3}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "billingACNo":
      return function billingACNo(val) {
        let pattern = /^[0-9]{7}[A-Za-z]$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength4":
      return function maxlengthValidator2(val) {
        let pattern = /^[a-zA-Z]{1,4}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength10":
      return function maxlengthValidator10(val) {
        let pattern = /^[a-zA-Z]{4,10}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength6OnlyNo":
      return function maxlength6OnlyNo(val) {
        let pattern = /^[0-9]{6}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlengthcharnum10":
      return function maxlengthValidatorcharnum10(val) {
        let pattern = /^[a-zA-Z0-9]{4,10}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength2to10":
      return function maxlengthValidator4to10(val) {
        let pattern = /^[a-zA-Z0-9]{2,10}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength10WithNum":
      return function maxlengthValidator10WithNum(val) {
        let pattern = /^[a-zA-Z0-9]{1,10}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength50":
      return function maxlengthValidator50(val) {
        let pattern = /^[a-zA-Z0-9 ]{1,50}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "notRequiredAlpNum10":
      return function notRequiredAlpNum10(val) {
        if (!val) return true;
        let pattern = /^[a-zA-Z0-9 ]{1,10}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "notRequiredAlpNum20":
      return function notRequiredAlpNum20(val) {
        if (!val) return true;
        let pattern = /^[a-zA-Z0-9 ]{1,20}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxLenghtAlpNumHypDot50":
      return function maxLenghtAlpNumHypDot50(val) {
        let pattern = /^[a-zA-Z0-9-. ]{1,50}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "notRequiredAlpNumHypDot50":
      return function notRequiredAlpNumHypDot50(val) {
        if (!val) return true;
        let pattern = /^[a-zA-Z0-9-. ]{1,50}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength10Alphanumaric":
      return function maxlengthValidator10Alphanumaric(val) {
        let pattern = /^[a-zA-Z0-9 ]{1,10}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength10AllAllow":
      return function maxlengthValidator10(val) {
        let pattern = /^[#.0-9a-zA-Z\s,-@()%!$%^&*{}/<>;:"|]+$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength20WithNum":
      return function maxlengthValidator20WithNum(val) {
        let pattern = /^[a-zA-Z0-9]{1,20}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength20":
      return function maxlengthValidator20(val) {
        let pattern = /^[a-zA-Z]{1,20}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength100":
      return function maxlengthValidator100(val) {
        let pattern = /^[#.0-9a-zA-Z\s,-@()%!$%^&*{}/<>;:"| ]{1,100}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength500":
      return function maxlengthValidator500(val) {
        let pattern = /^[a-zA-Z0-9 ]{1,500}$/;
        if (pattern.test(val)) return true;
        else return false;
      };

    case "addressRequierd":
      return function maxlengthValidator100Address(val) {
        let pattern = /^[#.0-9a-zA-Z\s,-@()%!$%^&*{}/<>;:"|]+$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength100AllAllow":
      return function maxlengthValidator100OnlyChar(val) {
        // if(!val) return false 
        let pattern = /^[#.0-9a-zA-Z\s,-@()%!$%^+&*{}/<>_;:'"|]+$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength100AllAllowNewForEmpty":
      return function maxlengthValidator100OnlyChar(val) {
        if (!val) return false
        let pattern = /^[#.0-9a-zA-Z\s,-@()%!$%^+&*{}/<>_;:'"|]+$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength100AllAllowFwdSlash":
      return function maxlengthValidator100OnlyChar(val) {
        let pattern = /^[#.0-9a-zA-Z\s,-@()%!$%^&*{}/\\<>_;:'"|]+$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength200AllAllow":
      return function maxlengthValidator100OnlyChar(val) {
        let pattern = /^[#.0-9a-zA-Z\s,-@()%!$%^&*{}/<>;:"|]+$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "maxlength200":
      return function maxlengthValidator200(val) {
        let pattern = /^[a-zA-Z ]{1,200}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "notRequired2":
      return function maxlengthValidator(val) {
        if (!val) return true;
        let pattern = /^[a-zA-Z ]{1,3}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "notRequired3":
      return function maxlengthValidator(val) {
        if (!val) return true;
        let pattern = /^[a-zA-Z]{1,3}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "notRequired10":
      return function maxlengthValidator(val) {
        if (!val) return true;
        let pattern = /^[a-zA-Z ]{1,10}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "notRequired20":
      return function maxlengthValidator(val) {
        if (!val) return true;
        let pattern = /^[a-zA-Z0-9]{1,20}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "notRequired50":
      return function maxlengthValidator500(val) {
        if (!val) return true;
        let pattern = /^[0-9()+-]{1,50}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "phnNoFCN":
      return function phnNoFCN(val) {
        let pattern = /^[0-9()+ -]{1,50}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "phnNoFCN1":
      return function phnNoFCN(val) {
        let pattern = /^[0-9+-]{1,50}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "notRequired500":
      return function maxlengthValidator500(val) {
        if (!val) return true;
        let pattern = /^[a-zA-Z ]{1,500}$/;
        if (pattern.test(val)) return true;
        else return false;
      };

    case "notRequired100":
      return function maxlengthValidator100(val) {
        if (!val) return true;
        let pattern = /^[a-zA-Z ]{1,100}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "notRequired100AllAllow":
      return function maxlengthValidator100(val) {
        if (!val) return true;
        let pattern = /^[#.0-9a-zA-Z\s,-@()%!$%^&*{}/<>;:"|]+$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "notRequiredEmail":
      return function emailNotRequired(val) {
        if (!val) return true;
        //if (val.length>100) return false
        if (EmailValidator.validate(val)) return true;
        else return false;
      };
    case "notRequired1000":
      return function maxlengthValidator100(val) {
        if (!val) return true;
        let pattern = /^[a-zA-Z]{1,1000}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "Alphanumeric":
      return function Alphanumeric(val) {
        if (!val) return true;
        let pattern = /^[a-zA-Z0-9 ]{1,100}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "onlyNumbers":
      return function onlyNumbers(val) {
        //   console.log(val);
        if (!val) return false;
        //if (val == 0) return true;
        let pattern = /^[0-9 ]*$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "Numberspecial":
      return function Numberspecial(val) {
        let pattern = /^[0-9*]*$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "checkwarehousefloor":
      return function Alphanumeric(val) {
        let pattern = /^[0-9a-zA-Z]{1,4}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "checkdecimalpoint":
      return function checkdecimalpointby3(val) {
        let pattern = /^[0-9]{1,15}(?:\.[0-9]{1,3})?$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "checkdecimalpoint1":
      return function checkdecimalpointby3(val) {
        //let pattern = /^[0-9]{1,18}(?:\.[0-9]{1,2})?$/;
        let pattern = /^[0-9]\d*(?:\.\d{0,2})?$/;
        if (pattern.test(val)) return true;
        else return false;
      };

    case "ratecheckdecimalpoint":
      return function checkdecimalpointby4(val) {
        //let pattern = /^[0-9]{1,18}(?:\.[0-9]{1,2})?$/;
        let pattern = /^[0-9]\d*(?:\.\d{0,4})?$/;
        if (pattern.test(val)) return true;
        else return false;
      };

    case "loginIDValid":
      return function loginIDValid(val) {
        var pattern = /^(?=.{3,20})[a-zA-Z0-9\s\!#%^&=(),_.@-]+.\*?$/;
        // /^(?=.{8,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/;
        // /^(?=.{8,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]$/
        // /^(?=.{8,20}$)([._-a-zA-Z0-9])+(?<![_.])$/;

        if (pattern.test(val)) return true;
        else return false;
      };
    case "ValidYear":
      return function ValidYear(val) {
        var pattern = new RegExp(/^(?:(?:19|20)[0-9]{2})$/);
        if (pattern.test(val)) return true;
        else return false;
      };
    case "CheckLocationcode":
      return function CheckLocationcode(val) {
        let pattern = /^[a-zA-Z0-9-_]+$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "checklettershopcode":
      return function checklettershopcode(val) {
        let pattern = /^[a-zA-Z][a-zA-Z0-9]+$/;
        //let pattern = /^[a-zA-Z0-9]+$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "checklettershopname":
      return function checklettershopname(val) {
        // let pattern = /^[a-zA-Z][a-zA-Z0-9$ ]+$/;

        let pattern = /^[a-zA-Z0-9-$%#@^&*(){}!~ ]{1,50}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "checklettershopname1":
      return function checklettershopname1(val) {
        // let pattern = /^[a-zA-Z][a-zA-Z0-9$ ]+$/;

        let pattern = /^[a-zA-Z0-9-$%#@^&*(){}!~<>,./?';:" ]{1,50}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "checkdistributorname":
      return function checkdistributorname(val) {
        // let pattern = /^[a-zA-Z][a-zA-Z0-9$ ]+$/;

        let pattern = /^[a-zA-Z0-9-$%#@^&*(){}!~ ]{1,100}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "checkDistributioncode":
      return function checkDistributioncode(val) {
        let pattern = /^[a-zA-Z0-9-$%#@^&*(){}!~ ]{1,100}$/;
        // let pattern = /^[a-zA-Z0-9- ]+$/;
        //let pattern = /^[a-zA-Z0-9]+$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "checkunitrate":
      return function checkunitrate(val) {
        //let pattern = /^[0-9]+(\.[0-9][0-9]?)?/;
        let pattern = /^[0-9]+(\.[0-9]{1,2})?$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "checkstockcode":
      return function checkstockcode(val) {
        let pattern = /^[a-zA-Z0-9-/_ ]{1,30}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "Reorderqty":
      return function Reorderqty(val) {
        let pattern = /^[0-9]{0,10}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "checkprice":
      return function checkprice(val) {
        let pattern = /^[0-9]{1,16}(?:\.[0-9]{1,2})?$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "checkweight":
      return function checkweight(val) {
        let pattern = /^[0-9]{1,2}(?:\.[0-9]{0})?$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "checkdimension":
      return function checkdimension(val) {
        let pattern = /^[a-zA-Z]{1}$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "checkremarks":
      return function checkdistributorname(val) {
        // let pattern = /^[a-zA-Z][a-zA-Z0-9$ ]+$/;

        let pattern = /^[a-zA-Z0-9-$%#@^&*(){}!~ ]{1,500}$/;
        if (pattern.test(val)) return true;
        else return false;
      };

    // for login and change password screens
    case "aVIS":
      return function aVIS(val) {
        let pattern = /^[0-9a-zA-Z]$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "aVISForWS":
      return function aVISForWS(val) {
        let pattern = /^[0-9a-zA-Z&-]$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    case "username":
      return function username(val) {
        // let pattern = /^[a-zA-Z][a-zA-Z0-9$ ]+$/;

        let pattern = /^[A-Za-z0-9]+(?:[.-@][A-Za-z0-9]+)*$/;
        if (pattern.test(val)) return true;
        else return false;
      };

    case "password":
      return function password(val) {
        // let pattern = /^[a-zA-Z][a-zA-Z0-9$ ]+$/;

        let pattern = /^[a-zA-Z0-9]$/;
        if (pattern.test(val)) return true;
        else return false;
      };
    default:
      return false;
  }
}
