import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { getCustomerList } from "../../masters/masterActions";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import moment from "moment";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { StateContext } from "../../../shared/globalState";
import LinearProgress from "@material-ui/core/LinearProgress";
import DateFnsUtils from "@date-io/date-fns";
import {
  stableSort,
  getSorting,
  addZeros,
  DialogActions,
} from "../../../shared/tableCommonFunctions";
import { Link } from "react-router-dom";
import { ExportCSV } from "../../../shared/ExportCSV";

import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import {
  SearchIcon,
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";

import {
  getItemMasterList,
  getFliteredItemMasterList,
  deleteItemMaster,
} from "../genericActions";
const columns = [
  {
    name: "Active",
    id: 14,
    show: false,
  },
  {
    name: "Modified Date",
    id: 15,
    show: false,
  },
  {
    name: "Modified By",
    id: 16,
  },
  {
    name: "Created Date",
    id: 17,
  },
  {
    name: "Created By",
    id: 18,
  },
];
function createData(
  customerCode,
  customerName,
  stockCode,
  stockName,
  id,
  uomName,
  // uomCode,
  stockCategoryName,
  // stockCategoryCode,
  reOrderLevelQty,
  price,
  cost,
  isConsumable,
  remarks,
  isActive,
  createdName,
  createdDate,
  modifiedName,
  modifiedDate,
  weightPerItem,
  dimension
) {
  return {
    // customerID,
    customerCode,
    customerName,
    stockCode,
    stockName,
    id,
    uomName,
    // uomCode,
    stockCategoryName,
    // stockCategoryCode,
    reOrderLevelQty,
    price,
    cost,
    isConsumable,
    remarks,
    isActive,
    createdName,
    createdDate,
    modifiedName,
    modifiedDate,
    weightPerItem,
    dimension,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ borderTop: "1px solid #eaeaea", background: "white" }}
          className="table-cell"
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all ItemMaster" }}
              />
            }
          />
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all Zones" }}
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors] = useState({
    customer: 0,
    CustomerId: "",
    StockName: "",
    StockCode: "",
    IsConsumable: false,
    modifiedDateFrom: "",
    modifiedDateTo: "",
  });
  const [formValid, setIsformValid] = useState(false);
  const [ImSearchValue, setValues] = useState({
    customer: 0,

    stockName: "",
    stockCode: "",
    isConsumable: false,
    modifiedDateFrom: null,
    modifiedDateTo: null,
  });
  let acnArr = props.roles.filter((u) => u.subMenuName === "Item Master");
  let acnObj = acnArr[0];
  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handleDeleteItemMaster,
    searchPopupOpen,
    //selectedItemMaster,
    selected,
    exportDataValues,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchItemMaster,
    searchClicked,
    ItemMasterList,
    customersList,
    isActive,
    hendleVewColums,
  } = props;
  // const selectedItemMasterID = selected && selected.length > 0 && selected[0];

  const selectedItemMasterID = selected[0];
  const selectedItemMaster = ItemMasterList.find(
    (x) => x.id === selectedItemMasterID
  );

  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteItemMasterdata = () => {
    handleDeleteItemMaster();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    setValues({
      customer: 0,
      stockName: "",
      stockCode: "",
      isConsumable: false,
      modifiedDateFrom: null,
      modifiedDateTo: null,
    });
  };

  const handleChange = (event, values) => {
    event.persist();
    let value = event.target.checked;
    let name = event.target.name;
    if (name === "isConsummable") {
      setValues((ImSearchValue) => ({
        ...ImSearchValue,

        isConsumable: event.target.checked, // isConsumable:value,
      }));
    } else {
      setValues((ImSearchValue) => ({
        ...ImSearchValue,

        isConsumable: value,
      }));
    }

    setIsformValid(true);
    switch (name) {
      default:
        break;
    }
  };

  const handleCustValuesChange = (event, values) => {
    event.persist();
    let name = event.target.name;
    let name2 = "customer";
    setValues((ImSearchValue) => ({
      ...ImSearchValue,
      [name2]: values && values.customerID,
      //[event.target.name]: event.target.value }));
    }));
    setIsformValid(true);
    switch (name) {
      default:
        break;
    }
  };

  const searchPopup = () => {
    console.log(ImSearchValue);
    handleSearchItemMaster(ImSearchValue);
    clearSearchValues();
  };
  const handleSearchInput = (event, values) => {
    event.persist();
    let name = event.target.name;
    setValues((ImSearchValue) => ({
      ...ImSearchValue,
      [event.target.name]: event.target.value,
    }));
    setIsformValid(true);
    switch (name) {
      default:
        break;
    }
  };

  const handleClickListItem = () => {
    setOpen(true);
  };
  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };
  const handleFromDateChange = (date) => {
    console.log(date);
    let name = "modifiedDateFrom";
    setValues({
      ...ImSearchValue,
      [name]: date && date.toLocaleDateString(),
    });

    setIsformValid(true);
  };
  const handleToDateChange = (date) => {
    console.log(date);
    let name = "modifiedDateTo";
    setValues({
      ...ImSearchValue,
      [name]: date && date.toLocaleDateString(),
    });
    setIsformValid(true);
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_ItemMaster/${selectedItemMasterID}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <Link to={`/edit_ItemMaster/${selectedItemMasterID}`}>
                      <IconButton
                        aria-label="edit"
                        className={classes.iconHover}
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={handleClickOpen}
                  disabled={!isActive}
                >
                  <DeleteIcon className={classes.iconHover} />
                </IconButton>
                {/* <ManageletterShop
              mode="delete"
              selected={selected}
              data={{
                letterShopName: selectedItemMaster.Shopname,
                letterShopCode: selectedItemMaster.Shopcode,
                letterShopID: selectedItemMaster.id
              }}
              onRequestSucceed={props.onRequestSucceed}
              count={props.selected.length}
            /> */}
              </Tooltip>
            )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete Item Master
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1 ? " ?" : selectedItemMaster.stockName + " ?"}
                    {/* {selectedItemMaster.stockName} */}
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteItemMasterdata}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {searchClicked ? (
              <Tooltip title="Clear search result">
                <IconButton
                  aria-label="clear"
                  className={classes.iconHover}
                  onClick={props.clearSearch}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            ) : (
              // <AdvanceSearch onSearchClicked={props.onSearchClicked} />
              <form onSubmit={searchPopup}>
                {acnObj && acnObj.advanceSearchAction && (
                  <Tooltip title="Search">
                    <IconButton
                      aria-label="Search"
                      className={classes.iconHover}
                      onClick={handleSearchOpen}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Dialog
                  disableBackdropClick
                  disableEscapeKeyDown
                  onClose={handleSearchPopupClose}
                  aria-labelledby="customized-dialog-title"
                  open={searchPopupOpen}
                  maxWidth="md"
                >
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleSearchPopupClose}
                  >
                    Advanced Search
                  </DialogTitle>
                  <DialogContent dividers>
                    <React.Fragment>
                      <div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            // disablePast
                            autoOk
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            style={{ marginLeft: "2%", width: "44%" }}
                            id="FromDate"
                            label="Modified From Date"
                            value={ImSearchValue.modifiedDateFrom}
                            onChange={handleFromDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            //  disablePast
                            autoOk
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            style={{ marginLeft: "4%", width: "44%" }}
                            id="ToDate"
                            label="Modified To Date"
                            value={ImSearchValue.modifiedDateTo}
                            onChange={handleToDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div>
                        <TextField
                          label="Stock Code"
                          id="standard-required"
                          placeholder="Stock Code"
                          className={classes.textField}
                          style={{ marginLeft: "2%", width: "208px" }}
                          name="stockCode"
                          margin="normal"
                          value={ImSearchValue.stockCode}
                          error={errors.stockCode}
                          helperText={errors.stockCode ? errors.stockCode : ""}
                          onChange={handleSearchInput}
                        />
                        <TextField
                          label="Stock Name"
                          id="standard-required"
                          placeholder="Stock Name"
                          className={classes.textField}
                          name="stockName"
                          style={{ marginLeft: "2%", width: "210px" }}
                          margin="normal"
                          value={ImSearchValue.stockName}
                          error={errors.stockName}
                          helperText={errors.stockName ? errors.stockName : ""}
                          onChange={handleSearchInput}
                        />
                      </div>

                      <div
                        style={{
                          minWidth: "400px",
                          display: "flex",
                          "margin-left": "0.7em",
                        }}
                      >
                        <Autocomplete
                          autoComplete
                          includeInputInList
                          id="customer"
                          name="customer"
                          hintText="process Name"
                          style={{ width: "47%" }}
                          options={customersList}
                          onChange={handleCustValuesChange}
                          getOptionLabel={(option) => option.customerName}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Customer"
                              name="customerId"
                              id="customerId"
                              margin="normal"
                              style={{ width: "94%" }}
                              //className={classes.textField}
                              value={ImSearchValue.customer}
                              // error={this.state.errors.stockID}
                              // helperText={
                              //   this.state.errors.stockID
                              //     ? this.state.errors.stockID
                              //     : ""
                              // }
                            />
                          )}
                        />

                        <FormControlLabel
                          className={classes.checkbox}
                          name="IsConsummable"
                          style={{ marginTop: "30px", marginLeft: "0px" }}
                          control={
                            <Checkbox
                              className={classes.Checkbox}
                              checked={ImSearchValue.isConsumable}
                              onChange={handleChange}
                              value={ImSearchValue.isConsumable}
                              color="primary"
                            />
                          }
                          label="Consummable"
                        />
                      </div>
                    </React.Fragment>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={handleSearchClose}
                    >
                      CANCEL
                    </Button>
                    {!formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={searchPopup}
                      >
                        SEARCH
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={searchPopup}
                      >
                        SEARCH
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
              </form>
            )}
            {acnObj && acnObj.addAction && (
              <Tooltip title="Add">
                <IconButton
                  aria-label="add"
                  className={classes.iconHover}
                  onClick={(e) => props.history.push("/add_ItemMaster")}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
            {acnObj && acnObj.exportAction && (
              <Tooltip title="Download">
                <ExportCSV
                  csvData={exportDataValues}
                  fileName={"ItemMaster_Details"}
                />
              </Tooltip>
            )}

            <Tooltip title="View Column">
              <IconButton
                aria-label="viewColumn"
                className={classes.iconHover}
                onClick={handleClickListItem}
              >
                <ViewColumnIcon />
              </IconButton>
            </Tooltip>
            <EnhancedShowColumn
              id="columns-menu"
              keepMounted
              open={open}
              onClose={handleShowColClose}
              column={column}
              hendleVewColums={hendleVewColums}
              headCells={props.headCells}
            />
          </div>
        )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class ItemMaster extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    activeRows: 0,
    isActive: false,
    loading: false,
    refreshData: false,
    selectedItemMaster: {},
    ItemMasterList: [],
    searchPopupOpen: false,
    ItemSearchValues: {},
    searchClicked: false,
    selectedShowColumns: [],
    customersList: [],
    exportDataValues: [],
    headCells: [
      {
        code: "stockCode",
        id: 1,
        numeric: false,
        disablePadding: true,
        label: "Stock Code",
        show: true,
      },
      {
        code: "stockName",
        id: 2,
        numeric: false,
        disablePadding: false,
        label: "Stock Name",
        show: true,
      },
      {
        code: "customerCode",
        id: 3,
        numeric: false,
        disablePadding: false,
        label: "Customer Code",
        show: true,
      },
      {
        code: "customerName",
        id: 4,
        numeric: false,
        disablePadding: false,
        label: "Customer Name",
        show: true,
      },
      {
        code: "uomName",
        id: 5,
        numeric: false,
        disablePadding: false,
        label: "UOM Name",
        show: true,
      },
      {
        code: "stockCategoryName",
        id: 6,
        numeric: false,
        disablePadding: false,
        label: "Stock Category",
        show: true,
      },
      {
        code: "reOrderLevelQty",
        id: 7,
        numeric: false,
        disablePadding: false,
        label: "Reorder Qty",
        show: true,
      },
      {
        code: "price",
        id: 8,
        numeric: false,
        disablePadding: false,
        label: "Price ($S)",
        show: true,
      },
      {
        code: "cost",
        id: 9,
        numeric: false,
        disablePadding: false,
        label: "Cost ($S)",
        show: true,
      },
      {
        code: "weightPerItem",
        id: 10,
        numeric: false,
        disablePadding: false,
        label: "Weight Per Item (Kg)",
        show: true,
      },
      {
        code: "dimension",
        id: 11,
        numeric: false,
        disablePadding: false,
        label: "Dimension",
        show: true,
      },
      {
        code: "isConsumable",
        id: 12,
        numeric: false,
        disablePadding: false,
        label: "Consumable",
        show: true,
      },
      {
        code: "remarks",
        id: 13,
        numeric: false,
        disablePadding: false,
        label: "Remarks",
        show: true,
      },
      {
        code: "isActive",
        id: 14,
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true,
      },
      {
        id: 15,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 16,
        code: "modifiedName",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 17,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 18,
        code: "createdName",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };
  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };
  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Item Master");
    this.props.setSelectedList(
      "genericSystemOpen",
      "itemMaster",
      "warehouseManagementOpen"
    );
    this.setState({ loading: true });
    getCustomerList().then((r) => {
      this.setState({
        customersList:
          r.data &&
          r.data.sort((a, b) =>
            a.customerName.toLowerCase() > b.customerName.toLowerCase() ? 1 : -1
          ),
      });
    });

    getItemMasterList().then((r) => {
      dispatch({
        type: "getItemMasterList",
        newStore: r.data,
      });
      this.getAllItemMaster(r.data);
      this.exportDatas(r.data);
    });
    this.setState({ loading: true });
  }

  exportDatas = (ItemMasterList) => {
    let list = ItemMasterList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          "Stock Code": d.stockCode,
          "Stock Name": d.stockName,
          "Customer Code": d.customerCode,
          "Customer Name": d.customerName,
          "UOM Name": d.uomName,
          "Stock Category": d.stockCategoryName,
          "Reorder Qty": d.reOrderLevelQty,
          "Price ($S)": d.price,
          "Cost ($S)": d.cost,
          "Weight Per Item": d.weightPerItem,
          Dimension: d.dimension,
          Consumable: d.isConsumable === true ? "Yes" : "No",
          Remarks: d.remarks,
          Active: d.isActive === true ? "Yes" : "No",
          "Modified Date": moment(d.modifiedDate).format("DD/MM/YYYY HH:mm:ss"),
          "Modified By": d.modifiedName,
          "Created Date": moment(d.createdDate).format("DD/MM/YYYY HH:mm:ss"),
          "Created By": d.createdName,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getAllItemMaster = (ItemMasterList) => {
    let list = ItemMasterList;
    //   .get("/LetterShop")
    //   .then(r => {
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return newRows.push(
          createData(
            d.customerCode,
            d.customerName,
            d.stockCode,
            d.stockName,
            d.stockID,
            d.uomName,
            d.stockCategoryName,
            d.reOrderLevelQty,
            d.price,
            d.cost,
            d.isConsumable,
            d.remarks,
            d.isActive,
            d.createdName,
            d.createdDate,
            d.modifiedName,
            d.modifiedDate,
            d.weightPerItem,
            d.dimension
          )
        );
      });
    this.setState({
      ItemMasterList: newRows,
      selected: [],
      loading: false,
      activeRows: newRows.filter((x) => x.isActive).map((n) => n.id),
    });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.ItemMasterList && store.ItemMasterList.length > 0)
      if (this.state.ItemMasterList.length !== store.ItemMasterList.length) {
        this.getAllItemMaster(store.ItemMasterList);
      }
    return true;
  }
  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { ItemMasterList } = this.state;
    if (event.target.checked) {
      const newSelecteds = ItemMasterList.filter(
        (x) => x.isActive === true
      ).map((n) => n.id);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, data) => {
    let id = data.id;
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedItemMaster: data,
        selectedItemMasterID: id,
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedItemMaster: data,
        selectedItemMasterID: id,
        //selectedItemMaster: {}
        // selectedItemMasterID: 0
      });
    }
  };
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true, page: 0 });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };
  handleSearchItemMaster = (handleSearchItemMaster) => {
    console.log(handleSearchItemMaster);
    const dispatch = this.context[1];
    let ImSearchValue = handleSearchItemMaster;

    let stockName = ImSearchValue.stockName;
    let stockCode = ImSearchValue.stockCode;
    let customerID = ImSearchValue.customer;
    let isConsumable = ImSearchValue.isConsumable;

    let modifiedDateFrom = ImSearchValue.modifiedDateFrom;
    let modifiedDateTo = ImSearchValue.modifiedDateTo;

    let reqBody = {
      stockName,
      stockCode,
      customerID,
      isConsumable,
      modifiedDateFrom,
      modifiedDateTo,
    };
    console.log(reqBody);
    getFliteredItemMasterList(reqBody).then((r) => {
      dispatch({
        type: "getFliteredItemMasterList",
        newStore: r.data,
      });
      this.getAllItemMaster(r.data);
      this.setState({ searchPopupOpen: false, searchClicked: true });
    });
  };

  handleDeleteItemMaster = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedName = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteItemMaster(modifiedName, deletionIds).then((r) => {
      getItemMasterList().then((r) => {
        dispatch({
          type: "getItemMasterList",
          newStore: r.data,
        });
        this.getAllItemMaster(r.data);
      });
      // this.props.history.push("/letterShop");
      this.setState({ deletePopupOpen: false, selected: [] });
      // toastr.success("Lettershop Deleted Successfully");
    });
  };

  clearSearch = () => {
    const dispatch = this.context[1];
    getItemMasterList().then((r) => {
      dispatch({
        type: "getItemMasterList",
        newStore: r.data,
      });
      this.getAllItemMaster(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      selectedItemMaster,
      ItemMasterList,
      headCells,
      exportDataValues,
    } = this.state;
    const isSelected = (id) => selected.indexOf(id) !== -1;

    // const emptyRows =
    //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedItemMaster={selectedItemMaster}
            onSearchClicked={this.onSearchClicked}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            selected={this.state.selected}
            selectedItemMasterID={this.state.selectedItemMasterID}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteItemMaster={this.handleDeleteItemMaster}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchItemMaster={this.handleSearchItemMaster}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            customersList={this.state.customersList}
            ItemMasterList={ItemMasterList}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 8em)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
                  : {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 330px)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ whiteSpace: "nowrap" }}
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />

                <TableBody style={{ overflow: "hidden" }}>
                  {ItemMasterList.length > 0 ? (
                    stableSort(ItemMasterList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )

                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        let { consummable } = "";
                        if (row.isConsumable === true) {
                          consummable = "Yes";
                        } else {
                          consummable = "No";
                        }
                        return (
                          <TableRow
                            hover
                            onClick={(event) => this.handleClick(event, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            //key={row.id}
                            key={index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              {/* <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                                color="primary"
                              /> */}
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    // onChange={handleChange('checkedG')}
                                    // value="checkedG"
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.stockCode && row.stockCode.length > 16 ? (
                                  <Tooltip title={row.stockCode}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.stockCode}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockCode}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.stockName && row.stockName.length > 16 ? (
                                  <Tooltip title={row.stockName}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.stockName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.customerCode &&
                                row.customerCode.length > 16 ? (
                                  <Tooltip title={row.customerCode}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerCode}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.customerCode}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.customerName &&
                                row.customerName.length > 16 ? (
                                  <Tooltip title={row.customerName}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.customerName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.uomName && row.uomName.length > 16 ? (
                                  <Tooltip title={row.uomName}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.uomName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.uomName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.stockCategoryName &&
                                row.stockCategoryName.length > 16 ? (
                                  <Tooltip title={row.stockCategoryName}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.stockCategoryName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockCategoryName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.reOrderLevelQty &&
                                row.reOrderLevelQty.length > 16 ? (
                                  <Tooltip title={row.reOrderLevelQty}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.reOrderLevelQty}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.reOrderLevelQty}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.price && row.price.length > 16 ? (
                                  <Tooltip title={row.price}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.price}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.price}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {addZeros(row.cost.toString(), 2)}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {/* {row.weightPerItem} */}
                                  {addZeros(row.weightPerItem.toString(), 3)}
                                </Typography>
                              </TableCell>
                            )}

                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.dimension && row.dimension.length > 16 ? (
                                  <Tooltip title={row.dimension}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dimension}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.dimension}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {consummable}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.remarks && row.remarks.length > 16 ? (
                                  <Tooltip title={row.remarks}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.remarks}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.remarks}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {status}
                              </TableCell>
                            )}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.modifiedDate &&
                                row.modifiedDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[15].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.modifiedName}
                                </Typography>
                              </TableCell>
                            )}

                            {this.state.headCells[16].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Moment format="MM/DD/YYYY">{row.createddate}</Moment> */}
                                {/* moddate */}

                                {row.createdDate &&
                                row.createdDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[17].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.createdName}
                                </Typography>
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      {this.state.loading ? (
                        <TableCell colSpan={15} style={{ textAlign: "center" }}>
                          <LinearProgress />
                        </TableCell>
                      ) : (
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                          No result found
                        </TableCell>
                      )}
                      {/* {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )} */}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>

          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={ItemMasterList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(ItemMaster);
