import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import clone from "clone"
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { StateContext } from "../../../shared/globalState";
import { getLocationDropdownList } from "../../masters/masterActions"

import { styles } from "../../../shared/styles";
import { getStatusForPreAlertList, getCustomerForPreAlertList } from "./action";
//import { getCustomerList, serchCustomer } from "../masterActions";
const notRequiredAlpNum10 = new Validator("notRequiredAlpNum10");
const notRequired100AllAllow = new Validator("notRequired100AllAllow");
const maxlength10Alphanumaric = new Validator("maxlength10Alphanumaric");
let CategoryList = [
  { id: 0, label: "Replacement" },
  { id: 1, label: "Reprint" },
  { id: 2, label: "MainLabel" },
  { id: 3, label: "CEA" },
  { id: 4, label: "CES" },
  { id: 5, label: "BPA" },
  { id: 5, label: "CPT" },
];
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        ></IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
class PreAlertAdvanceSearch extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    orderId: "",
    groupId: "",
    workOrderId: "",
    orderTitle: "",
    status: "",
    plannerId: "",
    statusObj: {},
    issueDesc: "",
    userObj: {},
    category: "",
    categoryObj: {},
    customer: "",
    customerObj: {},
    customerList: [],
    locationId: "",
    locationObj: {},
    locationList: [],
    nameObj: {},
    typeObj: {},
    userId: "",
    categoryId: "",
    nameId: "",
    typeId: "",
    planner: {},
    modifiedDateFrom: null,
    modifiedDateTo: null,
    plannerList: [],
    statusList: [],

    errors: {
      orderId: false,
      groupId: false,
      orderTitle: false,
      status: false,
      plannerId: false,
      ogCustomer: false,
      ogOrderCode: false,
      processCategory: false,
      process: false,
      processType: false,
    },
    ogCustomer: "",
    ogOrderCode: "",
  };

  componentDidMount() {
    const [{ store }, dispatch] = this.context;

    getStatusForPreAlertList().then((r) => {
      this.getAllOrderStatus(r.data);
    });
    getCustomerForPreAlertList().then((r) => {
      this.getAllCustomer(r.data);
    });
    getLocationDropdownList().then((rr) => {
      this.getAllLocation(rr.data);
    })
  }

  getAllCustomer = (list) => {
    let userList = [];
    list &&
      list.length > 0 &&
      (userList = list.sort((a, b) =>
        a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
      ));
    let newCustomerList = [];
    userList.map((c) => {
      newCustomerList.push({
        key: c.id,
        value: c.value,
        name: c.text,
      });
    });
    this.setState({ customerList: newCustomerList });
  };
  getAllLocation = (list) => {
    let locationList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        locationList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(locationList, "locationList");
  };
  sortDDList = (list, name) => {
    let oldCus = clone(list);

    // if (name === "locationList") {
    //   oldCus &&
    //     oldCus.length > 0 &&
    //     this.setState({
    //       locationId: oldCus.filter((d) => d.value === "SG")[0].key,
    //       locationObj: oldCus.filter((d) => d.value === "SG")[0],
    //     });
    // }
    let z = list.sort(function (a, b) {
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ [name]: z });
  };
  getAllOrderStatus = (list) => {
    let statusList = [];
    list &&
      list.length > 0 &&
      (statusList = list
        .filter((c) => c.value !== "OR")
        .sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ));
    let newStatusList = [];
    statusList.map((c) => {
      newStatusList.push({
        key: c.id,
        value: c.value,
        name: c.text,
      });
    });
    this.setState({ statusList: newStatusList });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      open: false,
      title: "",
      acronym: "",
      volume: "",
      issue: "",
      suppNumber: "",
      category: "",
      status: "",
      modifiedDateFrom: null,
      modifiedDateTo: null,
      errors: {
        orderId: false,
        orderTitle: false,
        status: false,
        plannerId: false,
        ogCustomer: false,
        ogOrderCode: false,
        processCategory: false,
        process: false,
        processType: false,
      },
      ogCustomer: "",
      ogOrderCode: "",
      processCategory: "",
      process: "",
      processType: "",
    });
  };
  _onKeyPressNoOnly(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  onInputChanged = (e, name, type) => {
    // let newError = { ...this.state.errors };
    this.setState({ [name]: e.target.value });
  };

  handleAutocompleteStatus = (e, val) => {
    this.setState({ status: val && val.key, statusObj: val && val });
  };
  handleAutocompleteCategory = (e, val) => {
    this.setState({ category: val && val.label, categoryObj: val && val });
  };
  handleAutocompleteCustomer = (e, val) => {
    console.log(val)
    this.setState({ customer: val && val.key, customerObj: val && val });
  };
  handleAutocompleteLocation = (e, val) => {
    if (val)
      this.setState({ locationId: val && val.key, locationObj: val && val });
    else
      this.setState({ locationId: "", locationObj: {} });

  };
  onSearchClicked = (mode) => {
    const [{ store }, dispatch] = this.context;
    let loggedInId = localStorage.getItem("loggedInId");
    let { orderId, orderTitle, status, plannerId } = this.state;
    let reqBody = {};

    reqBody = {
      title: this.state.title || "",
      acronym: this.state.acronym || "",
      volume: this.state.volume || "",
      issue: this.state.issue || "",
      suppNumber: this.state.supp || "",
      category: this.state.category || "",
      status: this.state.status,
      issueDescription: this.state.issueDesc,
      customerID: this.state.customer || 0,
      modifiedDateTo: this.state.modifiedDateTo || "",
      modifiedDateFrom: this.state.modifiedDateFrom || "",
      locationID: this.state.locationId || 0
    };
    this.props.onSearchClicked(reqBody);
    this.setState({
      open: false,
      title: "",
      acronym: "",
      volume: "",
      issue: "",
      supp: "",
      category: "",
      statusObj: {},
      userObj: {},
      userId: "",
      categoryId: "",
      nameId: "",
      typeId: "",
      planner: {},
      errors: {
        title: false,
        acronym: false,
        volume: false,
        issue: false,
        supp: false,
        category: false,
        status: false,
      },
    });
  };
  checkButtonDisability = (mode) => {
    let { title, acronym, volume, issue, supp, category, status } = this.state;
    if (mode === "preAlert") {
      if (
        this.state.errors.title ||
        this.state.errors.acronym ||
        this.state.errors.volume ||
        this.state.errors.issue ||
        this.state.errors.supp ||
        this.state.errors.category ||
        (!title &&
          !acronym &&
          !volume &&
          !issue &&
          !supp &&
          !category &&
          !status)
      )
        return true;
      else return false;
    }
  };

  handleDateChange = (date) => {
    let { modifiedDateFrom } = this.state;
    modifiedDateFrom = date && date;
    this.setState({ modifiedDateFrom });
  };
  handleDateChange1 = (date) => {
    let { modifiedDateTo } = this.state;
    modifiedDateTo = date && date;
    this.setState({ modifiedDateTo });
  };

  render() {
    let { status, statusList, errors } = this.state;
    const { classes, ...other } = this.props;

    return (
      <React.Fragment>
        <div>
          <Tooltip title="Search">
            <IconButton
              aria-label="Search"
              className={classes.iconHover}
              onClick={this.handleClickOpen}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Dialog
            onClose={this.handleClose}
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
            maxWidth="md"
          >
            <DialogTitle id="customized-dialog-title">
              {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
              Advanced Search
            </DialogTitle>
            <DialogContent
              dividers
              id="preAlertID"
              style={{ paddingTop: "0px!important" }}
            >
              <div>
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      id="standard-required"
                      label="Title"
                      name="preAlert"
                      style={{ flex: 1, marginRight: "16px" }}
                      margin="normal"
                      value={this.state.title}
                      inputProps={{
                        maxLength: 50,
                      }}
                      autoComplete="off"
                      onChange={(e) => this.onInputChanged(e, "title")}
                    // error={errors.title}
                    // helperText={errors.title ? errors.title : ""}
                    />
                    <TextField
                      id="standard-required"
                      label="Acronym"
                      name="preAlert"
                      style={{ flex: 1, marginRight: "16px" }}
                      margin="normal"
                      value={this.state.acronym}
                      inputProps={{
                        maxLength: 50,
                      }}
                      autoComplete="off"
                      onChange={(e) => this.onInputChanged(e, "acronym")}
                    // error={errors.acronym}
                    // helperText={errors.acronym ? errors.acronym : ""}
                    />
                    <TextField
                      id="standard-required"
                      label="Volume"
                      name="preAlert"
                      style={{ flex: 1 }}
                      margin="normal"
                      value={this.state.volume}
                      inputProps={{
                        maxLength: 50,
                      }}
                      autoComplete="off"
                      onChange={(e) => this.onInputChanged(e, "volume")}
                    // error={errors.volume}
                    // helperText={errors.volume ? errors.volume : ""}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      id="standard-required"
                      label="Issue"
                      name="preAlert"
                      style={{ flex: 1, marginRight: "16px" }}
                      margin="normal"
                      value={this.state.issue}
                      inputProps={{
                        maxLength: 50,
                      }}
                      autoComplete="off"
                      onChange={(e) => this.onInputChanged(e, "issue")}
                    // error={errors.issue}
                    // helperText={errors.issue ? errors.issue : ""}
                    />

                    <TextField
                      id="standard-required"
                      label="Supplement"
                      name="preAlert"
                      style={{ flex: 1, marginRight: "16px" }}
                      margin="normal"
                      value={this.state.supp}
                      inputProps={{
                        maxLength: 50,
                      }}
                      autoComplete="off"
                      onChange={(e) => this.onInputChanged(e, "supp")}
                    // error={errors.supp}
                    // helperText={errors.supp ? errors.supp : ""}
                    />

                    <Autocomplete
                      options={CategoryList}
                      value={this.state.categoryObj}
                      style={{ flex: 1, }}
                      onChange={this.handleAutocompleteCategory}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Category"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Autocomplete
                      options={statusList}
                      value={this.state.statusObj}
                      style={{ flex: 1, marginRight: "16px" }}
                      onChange={this.handleAutocompleteStatus}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Status"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                    <Autocomplete
                      options={this.state.customerList}
                      value={this.state.customerObj}
                      style={{ flex: 1, marginRight: "16px" }}
                      onChange={this.handleAutocompleteCustomer}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Customer"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                    <TextField
                      id="standard-required"
                      label="Issue Description"
                      name="issueDesc"
                      style={{ flex: 1, }}
                      margin="normal"
                      value={this.state.issueDesc}
                      inputProps={{
                        maxLength: 50,
                      }}
                      autoComplete="off"
                      onChange={(e) => this.onInputChanged(e, "issueDesc")}
                    // error={errors.supp}
                    // helperText={errors.supp ? errors.supp : ""}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Autocomplete
                      options={this.state.locationList}
                      value={this.state.locationObj}
                      // disableClearable
                      style={{ flex: 1, marginRight: "16px" }}
                      onChange={this.handleAutocompleteLocation}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Location"
                          margin="normal"
                          // required
                          fullWidth
                        />
                      )}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        style={{ flex: 1, marginRight: "16px" }}
                        id="modifiedDateFrom"
                        label="Modified Date From"
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        value={this.state.modifiedDateFrom}
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        disabled={!this.state.modifiedDateFrom}
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        style={{ flex: 1, }}
                        id="modifiedDateTo"
                        minDate={this.state.modifiedDateFrom}
                        label="Modified Date To"
                        value={this.state.modifiedDateTo}
                        onChange={this.handleDateChange1}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>

                    {/* <div style={{ flex: 1 }}></div> */}
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions style={{ padding: "16px 24px" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={this.handleClose}
              >
                CANCEL
              </Button>
              {/* {this.checkButtonDisability("preAlert") ? ( */}
              <Button
                disabled={
                  !(this.state.modifiedDateFrom
                    ? this.state.modifiedDateTo
                    : this.state.title ||
                    this.state.acronym ||
                    this.state.volume ||
                    this.state.issue ||
                    this.state.status ||
                    this.state.supp ||
                    this.state.issueDesc ||
                    this.state.customer ||
                    this.state.locationId ||
                    this.state.category)
                }
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.onSearchClicked("preAlert")}
              >
                SEARCH
              </Button>
              {/* ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.onSearchClicked("preAlert")}
                >
                  SEARCH
                </Button>
              )} */}
            </DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(PreAlertAdvanceSearch));
