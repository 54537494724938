import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_ROUTING_MATRIX;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//-----------------------------------Routing EMLO EBLO
export const getRoutingEMLOEBLOList = async () => {
  let RoutingEMLOEBLOList = [];
  await pgsService
    .get("/RoutingEMLOEBLOs")
    .then((r) => {
      RoutingEMLOEBLOList = r;
    })
    .catch((e) => {});
  return RoutingEMLOEBLOList;
};

export const getRoutingEMLOEBLO = async (routingEMLOEBLOID) => {
  let RoutingEMLOEBLO = {};
  await pgsService

    .get("/RoutingEMLOEBLOs/" + routingEMLOEBLOID)
    .then((response) => {
      RoutingEMLOEBLO = response.data;
    })
    .catch((e) => {});
  return RoutingEMLOEBLO;
};

export const putRoutingEMLOEBLO = async (
  routingEMLOEBLOID,
  routingEMLOEBLO,
  history
) => {
  let RoutingEMLOEBLOList = [];
  await pgsService
    .put(`/RoutingEMLOEBLOs/${routingEMLOEBLOID}`, routingEMLOEBLO)
    .then((r) => {
      toastr.success("Routing EMLO EBLO Updated Successfully !");
      history.push("/routingEMLOEBLO");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return RoutingEMLOEBLOList;
};
export const deleteRoutingEMLOEBLO = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete("/RoutingEMLOEBLOs", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Routing EMLO EBLO Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
// export const deleteRoutingEMLOEBLO = async (modifiedBy, deletionIds) => {
//   await pgsService
//     .delete(
//       `/RoutingEMLOEBLOs`,
//       {
//         data: {
//           modifiedBy,
//           deletionIds
//         }
//       },
//       {
//         headers: {
//           Authorization: "Bearer " + window.localStorage.token
//         }
//       }
//     )
//     .then(r => {
//       toastr.success("Routing EMLO EBLO Deleted Successfully");
//     })
//     .catch(e => {
//       if (e.response && e.response.data.errors[0].message !== undefined) {
//         toastr.error(e.response.data.errors[0].message);
//       } else if (e.response.data.errors[0]) {
//         toastr.error(e.response.data.errors[0]);
//       }
//     });
// };
export const getFliteredRoutingEMLOEBLOList = async (reqBody) => {
  let RoutingEMLOEBLOList = [];

  await pgsService
    .post("/RoutingEMLOEBLOsAdvanceSerach", reqBody)
    .then((r) => {
      RoutingEMLOEBLOList = r;
    })
    .catch((e) => {});
  return RoutingEMLOEBLOList;
};

export const postRoutingEMLOEBLO = async (data, history) => {
  await pgsService
    .post("/RoutingEMLOEBLOs", data)
    .then((r) => {
      toastr.success("Routing EMLO EBLO Created Successfully");
      history.push("/RoutingEMLOEBLO");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
//-------------------------------------------------------Routing Priority
export const postRoutingPriority = async (data, history) => {
  await pgsService
    .post("/RoutingPrioritys", data)
    .then((r) => {
      toastr.success("Rounting Priority Created Successfully");
      history.push("/RoutingPriority");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const getFliteredRoutingPriorityList = async (reqBody) => {
  let RoutingPriorityList = [];

  await pgsService
    .post("/RoutingPrioritysAdvanceSerach", reqBody)
    .then((r) => {
      RoutingPriorityList = r;
    })
    .catch((e) => {});
  return RoutingPriorityList;
};
export const getRoutingPriorityList = async () => {
  let RoutingPriorityList = [];
  await pgsService
    .get("/RoutingPrioritys")
    .then((r) => {
      RoutingPriorityList = r;
    })
    .catch((e) => {});
  return RoutingPriorityList;
};
export const getRoutingPriority = async (routingPriorityID) => {
  let RoutingPriority = {};
  await pgsService

    .get("/RoutingPrioritys/" + routingPriorityID)
    .then((response) => {
      RoutingPriority = response.data;
    })
    .catch((e) => {});
  return RoutingPriority;
};

export const deleteRoutingPriority = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/RoutingPrioritys`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Routing Priority Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const putRoutingPriority = async (
  routingPriorityID,
  routingPriority,
  history
) => {
  let RoutingPriorityList = [];
  await pgsService
    .put(`/RoutingPrioritys/${routingPriorityID}`, routingPriority)
    .then((r) => {
      toastr.success("Routing By Priority Updated Successfully !");
      history.push("/routingPriority");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return RoutingPriorityList;
};
//.................................Routing UKUS
export const getRoutingUKUSList = async () => {
  let RoutingUKUSList = [];
  await pgsService
    .get("/RoutingUKUSs")
    .then((r) => {
      RoutingUKUSList = r;
    })
    .catch((e) => {});
  return RoutingUKUSList;
};
export const getFliteredRoutingUKUSList = async (reqBody) => {
  let RoutingUKUSList = [];

  await pgsService
    .post("/RoutingUKUSsAdvanceSerach", reqBody)
    .then((r) => {
      RoutingUKUSList = r;
    })
    .catch((e) => {});
  return RoutingUKUSList;
};
export const getRoutingUKUS = async (routingUKUSID) => {
  let RoutingUKUS = {};
  await pgsService

    .get("/RoutingUKUSs/" + routingUKUSID)
    .then((response) => {
      RoutingUKUS = response.data;
    })
    .catch((e) => {});
  return RoutingUKUS;
};

export const putRoutingUKUS = async (routingUKUSID, routingUKUS, history) => {
  let RoutingUKUSList = [];
  await pgsService
    .put(`/RoutingUKUSs/${routingUKUSID}`, routingUKUS)
    .then((r) => {
      toastr.success("Routing By UK US Updated Successfully !");
      history.push("/routingUKUS");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return RoutingUKUSList;
};
export const deleteRoutingUKUS = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/RoutingUKUSs`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Routing UK US Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const postRoutingUKUS = async (data, history) => {
  await pgsService
    .post("/RoutingUKUSs", data)
    .then((r) => {
      toastr.success("Routing UK US Created Successfully");
      history.push("/RoutingUKUS");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

//.................................Routing Title
export const getRoutingTitleList = async () => {
  let RoutingTitleList = [];
  await pgsService
    .get("/RoutingTitles")
    .then((r) => {
      RoutingTitleList = r;
    })
    .catch((e) => {});
  return RoutingTitleList;
};
export const getFliteredRoutingTitleList = async (reqBody) => {
  let RoutingTitleList = [];

  await pgsService
    .post("/RoutingTitlesAdvanceSerach", reqBody)
    .then((r) => {
      RoutingTitleList = r;
    })
    .catch((e) => {});
  return RoutingTitleList;
};
export const getRoutingTitle = async (routingTitleID) => {
  let RoutingTitle = {};
  await pgsService

    .get("/RoutingTitles/" + routingTitleID)
    .then((response) => {
      RoutingTitle = response.data;
    })
    .catch((e) => {});
  return RoutingTitle;
};
export const putRoutingTitle = async (
  routingTitleID,
  routingTitle,
  history
) => {
  let RoutingTitleList = [];
  await pgsService
    .put(`/RoutingTitles/${routingTitleID}`, routingTitle)
    .then((r) => {
      toastr.success("Routing Title Updated Successfully !");
      history.push("/routingTitle");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return RoutingTitleList;
};
export const deleteRoutingTitle = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/RoutingTitles`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Routing Title Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const postRoutingTitle = async (data, history) => {
  await pgsService
    .post("/RoutingTitles", data)
    .then((r) => {
      toastr.success("Rounting Title Created Successfully");
      history.push("/RoutingTitle");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

//----------------------------------------------------Routing Country
export const getRoutingCountryList = async () => {
  let RoutingCountryList = [];
  await pgsService
    .get("/RoutingCountrys")
    .then((r) => {
      RoutingCountryList = r;
    })
    .catch((e) => {});
  return RoutingCountryList;
};

export const getRoutingCountry = async (routingCountryID) => {
  let RoutingCountry = {};
  await pgsService

    .get("/RoutingCountrys/" + routingCountryID)
    .then((response) => {
      RoutingCountry = response.data;
    })
    .catch((e) => {});
  return RoutingCountry;
};

export const getFliteredRoutingCountryList = async (reqBody) => {
  let RoutingCountryList = [];

  await pgsService
    .post("/RoutingCountrysAdvanceSerach", reqBody)
    .then((r) => {
      RoutingCountryList = r;
    })
    .catch((e) => {});
  return RoutingCountryList;
};

export const putRoutingCountry = async (
  routingCountryID,
  routingCountry,
  history
) => {
  let RoutingCountryList = [];
  await pgsService
    .put(`/RoutingCountrys/${routingCountryID}`, routingCountry)
    .then((r) => {
      toastr.success("Routing Country Updated Successfully !");
      history.push("/RoutingCountry");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return RoutingCountryList;
};

export const postRoutingCountry = async (data, history) => {
  await pgsService
    .post("/RoutingCountrys", data)
    .then((r) => {
      toastr.success("Routing Country Created Successfully");
      history.push("/RoutingCountry");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const deleteRoutingCountry = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/RoutingCountrys`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Routing Country Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

//-------------------------Cpt code ends---------------------//

export const getRoutingZoneList = async () => {
  let RoutingZoneList = [];
  await pgsService
    .get("/RoutingByZoneOrCountrys")
    .then((r) => {
      RoutingZoneList = r;
    })
    .catch((e) => {});
  return RoutingZoneList;
};
// export const getRoutingCountry = async routingCountryID => {
//   let RoutingCountry = {};
//   await pgsService

//     .get("/RoutingCountrys/" + routingCountryID)
//     .then(response => {
//       RoutingCountry = response.data;
//     })
//     .catch(e => {});
//   return RoutingCountry;
// };
export const getRoutingZone = async (routingZoneGroupID) => {
  let RoutingZone = {};
  await pgsService
    .get("/RoutingByZoneOrCountrys/" + routingZoneGroupID)

    .then((response) => {
      RoutingZone = response.data;
    })
    .catch((e) => {});
  return RoutingZone;
};

export const deleteRoutingZone = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/RoutingByZoneOrCountrys`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Routing By Zone/Country Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const putRoutingZone = async (
  routingZoneGroupID,
  routingZone,
  history
) => {
  let RoutingZoneList = [];
  await pgsService
    .put(`/RoutingByZoneOrCountrys/${routingZoneGroupID}`, routingZone)
    .then((r) => {
      toastr.success("Routing By Zone / Country Updated Successfully !");
      history.push("/CountryZoneGrouping");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return RoutingZoneList;
};
export const getFliteredRoutingZoneList = async (reqBody) => {
  let RoutingZoneList = [];

  await pgsService
    .post("/RoutingByZoneOrCountrysAdvanceSerach", reqBody)
    .then((r) => {
      RoutingZoneList = r;
    })
    .catch((e) => {});
  return RoutingZoneList;
};

export const postRoutingZone = async (data, history) => {
  await pgsService
    .post("/RoutingByZoneOrCountrys", data)
    .then((r) => {
      toastr.success("Routing By Zone Or Country Created Successfully");
      history.push("/CountryZoneGrouping");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
