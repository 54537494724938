import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_PUBLISHER_ORDER_PLACEMENT;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getEMLOList = async () => {
  let jimList = [];
  await pgsService
    .get("/GetEMLOOrderPlacementData")
    .then((r) => {
      jimList = r;
    })
    .catch((e) => {});
  return jimList;
};

export const getEMLONumberList = async () => {
  let jimList = [];
  await pgsService
    .get("/GetAllEMLONo")
    .then((r) => {
      jimList = r;
    })
    .catch((e) => {});
  return jimList;
};

export const deleteEMLO = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/DeleteEMLOOrderPlacement/${deletionIds}/${modifiedBy}`)
    .then((r) => {
      toastr.success("EMLO Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getEMLOByID = async (id) => {
  let data = {};
  await pgsService
    .get("/GetEMLOOrderPlacementDataByID/" + id)
    .then((r) => {
      data = r && r.data;
    })
    .catch((e) => {});
  return data;
};

export const postEmailInsertMaster = async (reqBody, history) => {
  let ItemMasterList = [];
  await pgsService
    .post("/EMLOEmailHeaderCreate", reqBody)
    .then((r) => {
      ItemMasterList = r;
      let getResponse =
        r &&
        r.data &&
        r.data.map((val) => {
          return val.exceptionMessage;
        });

      if (getResponse.includes("File is already Present.")) {
        // toastr.error("Insert Master duplicate data");
      } else {
        history.push("/pubEmloOrderPlacement");
        toastr.success("File is Uploaded Successfully");
      }
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
      ItemMasterList = e.response.data.errors[0];
    });
  return ItemMasterList;
};

export const validateEmailEMLO = async (reqBody) => {
  let jimList = [];
  await pgsService
    .post("/ValidateEMLOEmail", reqBody)
    .then((r) => {
      jimList = r;
    })
    .catch((e) => {
      toastr.error("Mail Validate Error");
    });
  return jimList;
};

export const seacrhEmloAdvanceSearch = async (reqBody) => {
  let searchList = [];
  await pgsService
    .post("/SearchEMLOOrderPlacementDetails", reqBody)
    .then(async (r) => {
      searchList = r.data;
    })
    .catch((e) => {});
  return searchList;
};

export const getStatusForEMLOList = async () => {
  let statusList = [];
  await pgsService
    .get("/GetEMLOStatus")
    .then((r) => {
      statusList = r;
    })
    .catch((e) => {});
  return statusList;
};

export const postEmloList = async (reqBody, history) => {
  let jimList = [];
  await pgsService
    .post("/EMLOOrderPlacementCreate", reqBody)
    .then((r) => {
      toastr.success("EMLO created successfully !");
      history.push("/pubEmloOrderPlacement");
    })
    .catch((e) => {});
};

export const putEmloList = async (id, reqBody, history) => {
  let jimList = [];
  await pgsService
    .put("/UpdateEMLOOrderPlacement/" + id, reqBody)
    .then((r) => {
      toastr.success("EMLO updated successfully !");
      history.push("/pubEmloOrderPlacement");
    })
    .catch((e) => {});
};

export const deleteInsertmasterByID = async (deletionId) => {
  await pgsService
    .delete(`/DeleteHeader/${deletionId}`)
    .then((r) => {
      toastr.success("Email File Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

/////// emlo processing

export const getEMLOProcessList = async () => {
  let data = [];
  await pgsService
    .get("/GetEMLOOrderPlacementSubmittedData")
    .then((r) => {
      data = r;
    })
    .catch((e) => {});
  return data;
};

export const getEMLOListforAdd = async () => {
  let jimList = [];
  await pgsService
    .get("/GetProcessedEMLOData")
    .then((r) => {
      jimList = r;
    })
    .catch((e) => {});
  return jimList;
};

export const putEmloProcessList = async (reqBody, history) => {
  let jimList = [];
  await pgsService
    .put("/UpdateEMLOTrackingNo", reqBody)
    .then((r) => {
      toastr.success("Tracking info submitted successfully");
      history.push("/pubEmloBatchProcessing");
    })
    .catch((e) => {});
};

export const UpdateEMLOOrderPlacementToInProgess = async (reqBody, history) => {
  let jimList = [];
  await pgsService
    .put(
      "/UpdateEMLOOrderPlacementToInProgess                                                                                    ",
      reqBody
    )
    .then((r) => {
      toastr.success(
        "Selected orders will be processed and you will be notified via mail"
      );
      // history.push("/pubEmloBatchProcessing");
    })
    .catch((e) => {});
};

/////// eblo processing

export const getEBLOProcessList = async () => {
  let data = [];
  await pgsService
    .get("/GetEBLOOrderPlacementSubmittedData")
    .then((r) => {
      data = r;
    })
    .catch((e) => {});
  return data;
};

export const getEBLOListforAdd = async () => {
  let jimList = [];
  await pgsService
    .get("/GetProcessedEBLOData")
    .then((r) => {
      jimList = r;
    })
    .catch((e) => {});
  return jimList;
};

export const putEbloProcessList = async (reqBody, history) => {
  let jimList = [];
  await pgsService
    .put("/UpdateEBLOTrackingNo", reqBody)
    .then((r) => {
      toastr.success("Tracking info submitted successfully");
      history.push("/pubEbloBatchProcessing");
    })
    .catch((e) => {});
};

export const UpdateEBLOOrderPlacementToInProgess = async (reqBody, history) => {
  let jimList = [];
  await pgsService
    .put(
      "/UpdateEBLOOrderPlacementToInProgess                                                                                    ",
      reqBody
    )
    .then((r) => {
      toastr.success(
        "Selected orders will be processed and you will be notified via mail"
      );
      // history.push("/pubEmloBatchProcessing");
    })
    .catch((e) => {});
};
