import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { withRouter } from "react-router-dom";
import { getUserList } from "../../userAccess/userAccessAction";
import { getCustomerOrderStatus } from "../../masters/masterActions";
import { Autocomplete } from "@material-ui/lab";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { StateContext } from "../../../shared/globalState";
import { styles } from "../../../shared/styles";
import { resourceValidation } from "../../../shared/resource";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
//import { getCustomerList, serchCustomer } from "../masterActions";
const notRequiredAlpNum10 = new Validator("notRequiredAlpNum10");
const notRequired100AllAllow = new Validator("notRequired100AllAllow");
const maxlength10Alphanumaric = new Validator("maxlength10Alphanumaric");

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        ></IconButton>
      ) : null} */}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
class CustomerOrderAdvanceSearch extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    orderId: "",
    orderTitle: "",
    status: "",
    plannerId: "",
    statusObj: {},
    planner: {},
    plannerList: [],
    statusList: [],
    modifiedDateFrom: null,
    modifiedDateTo: null,
    errors: {
      orderId: false,
      orderTitle: false,
      status: false,
      plannerId: false,
    },
  };

  componentDidMount() {
    const [{ store }, dispatch] = this.context;
    // this.props.setSelectedList("masterDataOpen", "customer");
    //this.props.setSelectedList("masterDataOpen", "customer");
    getUserList().then((r) => {
      dispatch({
        type: "getUserList",
        newStore: r.data,
      });
      this.getAllPlanners(r.data);
    });
    getCustomerOrderStatus().then((r) => {
      this.getAllOrderStatus(r.data);
    });
  }

  getAllPlanners = (list) => {
    let plannerList = [];
    list &&
      list.length > 0 &&
      (plannerList = list
        .filter((x) => x.isActive === true)
        .sort((a, b) =>
          a.loginUserID.toLowerCase() > b.loginUserID.toLowerCase() ? 1 : -1
        ));
    let newPlannerList = [];
    plannerList.map((c) => {
      newPlannerList.push({
        key: c.userId,
        value: c.loginUserID,
        name: c.loginUserID,
      });
    });
    this.setState({ plannerList: newPlannerList });
  };
  getAllOrderStatus = (list) => {
    let statusList = [];
    // list &&
    //   list.length > 0 &&
    //   list.map(c => {
    //     if (c.value !== "OR")
    //       statusList.push({ key: c.id, value: c.value, name: c.text });
    //   });
    // this.setState({ statusList });
    list &&
      list.length > 0 &&
      (statusList = list
        .filter((c) => c.value !== "OR")
        .sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ));
    let newStatusList = [];
    statusList.map((c) => {
      newStatusList.push({
        key: c.id,
        value: c.value,
        name: c.text,
      });
    });
    this.setState({ statusList: newStatusList });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      open: false,
      orderId: "",
      orderTitle: "",
      status: "",
      plannerId: "",
    });
  };
  _onKeyPressNoOnly(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  onInputChanged = (e, name, type) => {
    let newError = { ...this.state.errors };
    if (type === "notRequiredAlpNum10") {
      let result = notRequiredAlpNum10(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else if (type === "maxlength10Alphanumaric") {
      let result = maxlength10Alphanumaric(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else if (type === "notRequired100AllAllow") {
      let result = notRequired100AllAllow(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    }
    this.setState({ [name]: e.target.value });
  };
  handleAutocompletePlanner = (e, val) => {
    this.setState({ plannerId: val && val.key, planner: val && val });
  };
  handleAutocompleteStatus = (e, val) => {
    this.setState({ status: val && val.key, statusObj: val && val });
  };
  onSearchClicked = () => {
    const [{ store }, dispatch] = this.context;
    let loggedInId = localStorage.getItem("loggedInId");
    let { orderId, orderTitle, status, plannerId } = this.state;
    let reqBody = {
      orderID: orderId,
      orderTitle: orderTitle,
      statusID: status,
      plannerID: plannerId,
      userID: loggedInId,
      modifiedDateFrom: this.state.modifiedDateFrom,
      modifiedDateTo: this.state.modifiedDateTo
    };
    this.props.onSearchClicked(reqBody);
    // serchCustomerOrder(reqBody).then(r => {
    //   dispatch({
    //     type: "getCustomerList",
    //     newStore: r.data
    //   });
    //   this.props.onRequestSucceed();
    // });
    this.setState({
      open: false,
      orderId: "",
      orderTitle: "",
      status: "",
      plannerId: "",
      modifiedDateFrom: null,
      modifiedDateTo: null,
    });
  };
  checkButtonDisability = () => {
    let { orderId, orderTitle, status, plannerId } = this.state;
    if (
      this.state.errors.orderId ||
      this.state.errors.orderTitle ||
      this.state.errors.status ||
      this.state.errors.plannerId ||
      (!orderId && !orderTitle && !status && !plannerId)
    )
      return true;
    else return false;
  };
  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };
  render() {
    let {
      orderId,
      orderTitle,
      status,
      plannerId,
      plannerList,
      statusList,
    } = this.state;
    const { classes, ...other } = this.props;
    //console.log(!isVendor && this.checkButtonDisability());
    return (
      <React.Fragment>
        <div>
          <Tooltip title="Search">
            <IconButton
              aria-label="Search"
              className={classes.iconHover}
              onClick={this.handleClickOpen}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            >
              {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
              Advanced Search
            </DialogTitle>
            <DialogContent dividers>
              <div>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <TextField
                      inputProps={{
                        maxLength: 14,
                      }}
                      label="Order Id "
                      className={classes.textField}
                      margin="normal"
                      value={orderId}
                      error={this.state.errors.orderId}
                      onChange={(e) =>
                        this.onInputChanged(
                          e,
                          "orderId",
                          "notRequired100AllAllow"
                        )
                      }
                      helperText={
                        this.state.errors.orderId
                          ? resourceValidation.max10char
                          : ""
                      }
                      style={{
                        // width: "400px",
                        // minWidth: "237px",
                        //display: "inline-block",
                        width: "93%",
                        marginLeft: ".5em",
                        marginRight: ".5em",
                      }}
                      onKeyPress={this._onKeyPress}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <TextField
                      label="Order Title"
                      className={classes.textField}
                      margin="normal"
                      value={orderTitle}
                      inputProps={{
                        maxLength: 100,
                      }}
                      style={{
                        // width: "400px",
                        // minWidth: "237px",
                        //display: "inline-block",
                        width: "93%",
                        marginLeft: ".5em",
                        marginRight: ".5em",
                      }}
                      error={this.state.errors.orderTitle}
                      onChange={(e) =>
                        this.onInputChanged(
                          e,
                          "orderTitle",
                          "notRequired100AllAllow"
                        )
                      }
                      helperText={
                        this.state.errors.orderTitle
                          ? resourceValidation.max100char
                          : ""
                      }
                      onKeyPress={this._onKeyPress}
                    />
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <Autocomplete
                      options={this.state.statusList}
                      value={this.state.statusObj}
                      // style={{
                      //   width: "100%",
                      //   marginLeft: ".5em",
                      //   marginRight: ".5em"
                      // }}
                      onChange={this.handleAutocompleteStatus}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Status"
                          style={{
                            width: "93%",
                            fontSize: "11px !important",
                            marginLeft: "10px",
                            marginRight: ".5em",
                            maxHeight: "400px",
                          }}
                          // name="plannerId"
                          margin="normal"
                        // required
                        />
                      )}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <Autocomplete
                      options={this.state.plannerList}
                      value={this.state.planner}
                      // style={{
                      //   width: "100%",
                      //   marginLeft: ".5em",
                      //   marginRight: ".5em"
                      // }}
                      onChange={this.handleAutocompletePlanner}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Planner"
                          style={{
                            width: "93%",
                            fontSize: "11px !important",
                            marginLeft: "10px",
                            marginRight: ".5em",
                            maxHeight: "400px",
                          }}
                          // name="plannerId"
                          margin="normal"
                        // required
                        />
                      )}
                    />
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        style={{
                          width: "100%",
                          display: "inline-block",
                          marginLeft: ".7em",
                          marginRight: ".5em",
                        }}
                        id="modifiedDateFrom"
                        label="Modified Date From"
                        // onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        name="modifiedDateFrom"
                        value={this.state.modifiedDateFrom}
                        onChange={(e) =>
                          this.handleDateChange(e, "modifiedDateFrom")
                        }
                      //    value={preAlertSearchValue.modifiedDateFrom}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div style={{ flex: 1 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        style={{
                          minWidth: "222px",
                          display: "inline-block",
                          marginLeft: ".8em",
                          // marginRight: ".5em",
                        }}
                        name="modifiedDateTo"
                        value={this.state.modifiedDateTo}
                        id="modifiedDateTo"
                        label="Modified Date To"
                        //   value={preAlertSearchValue.modifiedDateTo}
                        onChange={(e) =>
                          this.handleDateChange(e, "modifiedDateTo")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div>
                  {/* <TextField
                    id="standard-select-currency"
                    select
                    label="Status"
                    className={classes.textField}
                    name="status"
                    style={{
                      width: "45%",
                      fontSize: "11px !important"
                    }}
                    inputProps={{
                      maxLength: 100
                    }}
                    margin="normal"
                    value={status ? status : ""}
                    onChange={e =>
                      this.onInputChanged(e, "status", maxlength10Alphanumaric)
                    }
                  >
                    {statusList.map(option => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Planner"
                    className={classes.textField}
                    name="plannerId"
                    style={{
                      width: "45%",
                      fontSize: "11px !important"
                    }}
                    inputProps={{
                      maxLength: 100
                    }}
                    margin="normal"
                    value={plannerId ? plannerId : ""}
                    onChange={e =>
                      this.onInputChanged(
                        e,
                        "plannerId",
                        maxlength10Alphanumaric
                      )
                    }
                  >
                    {plannerList.map(option => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField> */}
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                //
                // color="primary"
                onClick={this.handleClose}
              >
                CANCEL
              </Button>

              <Button
                disabled={
                  !(this.state.modifiedDateFrom
                    ? this.state.modifiedDateTo
                    : this.state.orderId ||
                    this.state.orderTitle ||
                    this.state.status ||
                    this.state.plannerId)
                }
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.onSearchClicked()}
              >
                SEARCH
                </Button>


              {/* <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={this.handleClose}
              >
                CANCEL
              </Button> */}
            </DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(CustomerOrderAdvanceSearch));
