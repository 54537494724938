import React, { Component } from "react";

class PublisherStockOut extends Component {
  state = {};
  componentDidMount() {
    this.props.handleSettingHeader("Back Label Stock Out");
    this.props.setSelectedList("publisherReportsOpen", "PublisherStockOut");
  }
  render() {
    return (
      <div>
      <iframe
        style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
        src={`${window.localStorage.ReportURLByID}` +"PublisherStockOut"}
        />
      </div>
    );
  }
}

export default PublisherStockOut;
