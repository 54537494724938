import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import ReportViewer from "../../app/ReportViewer";
import {
  EditIcon,
  PrintIcon,
  CustomPrintIcon,
} from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import {
  getCollectionNote,
  getCollectionNoteDetails,
} from "../reportServiceAction";
import { StateContext } from "../../../shared/globalState";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import moment from "moment";
import { stableSort, getSorting } from "../../../shared/tableCommonFunctions";
import Typography from "@material-ui/core/Typography";
import { printDetails } from "../../../shared/tableCommonFunctions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { InlineWrapper } from "@material-ui/pickers/wrappers/InlineWrapper";

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { selectedCollectionNoteId, status } = props;
  let isActive = !(status === "Item Received" || status === "Cancelled");
  let acnArr = props.roles.filter((u) => u.menuName === "Colletion Note");
  let acnObj = acnArr[0];

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              {isActive ? (
                <Link to={`/edit_collectionNote/${selectedCollectionNoteId}`}  >
                  <IconButton aria-label="edit" className={classes.iconHover}>
                    <EditIcon />
                  </IconButton>
                </Link>
              ) : (
                  <IconButton aria-label="edit" disabled={!isActive} className={classes.iconHover} >
                    <EditIcon />
                  </IconButton>
                )}
            </Tooltip>
          )}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"

            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                style={{ padding: "5px", paddingTop: "12px", width: "47px", paddingLeft: "10px" }}

                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

function createData(
  stockDescription,
  noOfPts,
  totalPackage,
  noOfCopies,
  totalQuantity,
  weightPerCortanBox,
  netWeight
) {
  return {
    stockDescription,
    noOfPts,
    totalPackage,
    noOfCopies,
    totalQuantity,
    weightPerCortanBox,
    netWeight,
  };
}
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
                style={{
                  borderLeft: "1px sold #000",
                  width: `${headCell.width}`,
                }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

class ViewCollectionNote extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      collectionNote: {
        collectionNoteNo: "",
        clientname: "",
        workOrderNo: "",
        title: "",
        jobGroupNumber: "",
        description: "",
        contactPersonName: "",
        contactNumber: "",
        collectionAddress: "",
        collectionAddress2: "",
        collectionAddress3: "",
        zipCode: "",
        countryID: 0,
        country: "",
        isUrgent: false,
        totalQuantity: "",
        statusID: "",
        status: "",
        pickupDate: "",
        pickupTime: "",
        agentCode: "",
        driverName: "",
        codeTypeName: "",
        remarks: "",
      },
      collectionNoteDetails: [],
      page: 0,
      rowsPerPage: 100,
      refreshData: false,
      loading: false,
      order: "desc",
      orderBy: "modifiedDate",
      headCells: [
        {
          id: 1,
          code: "lineNo",
          numeric: false,
          disablePadding: true,
          label: "Line No",
          width: "auto",
          show: true,
        },
        {
          id: 2,
          code: "stockDescription",
          numeric: false,
          disablePadding: false,
          label: "Description",
          width: "100%",
          show: true,
        },
        {
          id: 3,
          code: "noOfPts",
          numeric: false,
          disablePadding: false,
          label: "No of Plts",
          width: "auto",
          show: true,
        },
        {
          id: 4,
          code: "totalPackage",
          numeric: false,
          disablePadding: false,
          label: "Total Packages",
          width: "auto",
          show: true,
        },
        {
          id: 5,
          code: "noOfCopies",
          numeric: false,
          disablePadding: false,
          label: "No of Cps/Pk",
          width: "auto",
          show: true,
        },
        {
          id: 6,
          code: "totalQuantity",
          numeric: false,
          disablePadding: false,
          label: "Qty",
          width: "auto",
          show: true,
        },
        {
          id: 7,
          code: "weightPerCartBox",
          numeric: false,
          disablePadding: false,
          label: "Wt / Carton Box(Kg)",
          width: "auto",
          show: true,
        },
        {
          id: 8,
          code: "netWeight",
          numeric: false,
          disablePadding: false,
          label: "Net Wt(Kg)",
          width: "auto",
          show: true,
        },
      ],
      showPopup: false,
    };
  }

  handleShowPopup = () => {
    this.setState({
      showPopup: true,
    });
  };

  handleHidePopup = () => {
    this.setState({
      showPopup: false,
    });
  };

  componentDidMount() {
    const dispatch = this.context[1];
    let collectionNoteID = this.props.match.params.collectionNoteId;
    this.props.handleSettingHeader("Collection Note");
    this.props.setSelectedList("genericSystemOpen", "collectionNote");
    getCollectionNote(collectionNoteID).then((r) => {
      dispatch({
        type: "getCollectionNote",
        newStore: r,
      });
      this.setState({ collectionNote: r });
    });
    getCollectionNoteDetails(collectionNoteID).then((r) => {
      dispatch({
        type: "getCollectionNoteDetails",
        newStore: r.data,
      });
      this.getAllcollectionNoteDetails(r.data);
    });
  }
  getAllcollectionNoteDetails = (collectionNoteDetail) => {
    let list = collectionNoteDetail;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return d.isActive
          ? rows.push(
            createData(
              d.stockDescription,
              d.noOfPts,
              d.totalPackage,
              d.noOfCopies,
              d.totalQuantity,
              d.weightPerCortanBox &&
              this.addZeroes(d.weightPerCortanBox.toString(), 3),
              d.netWeight && this.addZeroes(d.netWeight.toString(), 3)
            )
          )
          : d;
      });
    this.setState({ collectionNoteDetails: rows });
  };
  addZeroes(num, decimalPlaces) {
    var value = Number(num);
    var res = num.split(".");
    if (res.length === 1 || res[1].length < decimalPlaces) {
      value = value.toFixed(decimalPlaces);
    }
    if (value !== "") {
      value = String(value);
    }
    return value;
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  render() {
    let {
      collectionNote,
      collectionNoteDetails,
      page,
      rowsPerPage,
      order,
      orderBy,
      headCells,
    } = this.state;
    let collectionNoteID = this.props.match.params.collectionNoteId;

    return (
      <React.Fragment>
        <Paper
          style={
            !this.props.clearOpenList
              ? {
                overflow: "auto",
                border: "1px solid #eaeaea",
                // maxWidth: "calc(100vw - 8em)",
                //maxHeight: "calc(100vh - 300px)"
              }
              : {
                overflow: "auto",
                border: "1px solid #eaeaea",
                // maxWidth: "calc(100vw - 330px)",
                //maxHeight: "calc(100vh - 300px)"
              }
          }
        >
          <EnhancedTableToolbar
            selectedCollectionNoteId={collectionNoteID}
            status={collectionNote.status}
            roles={this.props.roles}
          />
          {/* <div className="view-page-header">Cost Per Title Information</div> */}
          <Divider />
          <div
            className="view-page-container"
            style={{ overflow: "auto" }}
            id="printable"
          >
            <div className="view-page-headings">
              Collection Note Information
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Collection Note Number</div>
                <div className="view-page-value">
                  {collectionNote.collectionNoteNo}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Total Quantity</div>
                <div className="view-page-value">
                  {collectionNote.totalQuantity}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Customer Name</div>
                <div className="view-page-value">
                  {collectionNote.clientname}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Work Order Number</div>
                <div className="view-page-value">
                  {collectionNote.workOrderNo}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Title</div>
                <div className="view-page-value">{collectionNote.title}</div>
              </div>
              {/* <div className="view-page-contents-sub-container">
                <div className="view-page-label">Job Group Number</div>
                <div className="view-page-value">
                  {collectionNote.jobGroupNumber}
                </div>
              </div> */}
            </div>
            <div className="view-page-headings">To Address</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container1">
                <div className="view-page-label">Collection To Address</div>
                <div className="view-page-value">
                  {collectionNote.collectionToAddress}
                </div>
              </div>
            </div>
            <div className="view-page-headings">From Address</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container1">
                <div className="view-page-label">Company</div>
                <div className="view-page-value">
                  {collectionNote.description}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Address 1</div>
                <div className="view-page-value">
                  {collectionNote.collectionAddress}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Address 2</div>
                <div className="view-page-value">
                  {collectionNote.collectionAddress2}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Address 3</div>
                <div className="view-page-value">
                  {collectionNote.collectionAddress3}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Zip Code</div>
                <div className="view-page-value">{collectionNote.zipCode}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Country</div>
                <div className="view-page-value">
                  {collectionNote.country && collectionNote.country}
                </div>
              </div>
            </div>
            <div className="view-page-headings">Contact Info</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Contact Person</div>
                <div className="view-page-value">
                  {collectionNote.contactPersonName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Contact Number</div>
                <div className="view-page-value">
                  {collectionNote.contactNumber}
                </div>
              </div>
            </div>
            <div className="view-page-headings">Pickup Details</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Pickup Date</div>
                <div className="view-page-value">
                  {collectionNote.pickupDate &&
                    moment(collectionNote.pickupDate).format("DD/MM/YYYY")}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Pickup Time</div>
                <div className="view-page-value">
                  {/* {collectionNote.pickupTime} */}
                  {collectionNote.pickupTime ? moment(collectionNote.pickupTime, 'h:mm A').format("hh:mm A") : ""}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Priority</div>
                <div className="view-page-value">
                  {collectionNote.isUrgent ? "Urgent" : "Normal"}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Agent Code</div>
                <div className="view-page-value">
                  {collectionNote.agentCode}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Driver Name</div>
                <div className="view-page-value">
                  {collectionNote.driverName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Status</div>
                <div className="view-page-value">{collectionNote.status}</div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container1">
                <div className="view-page-label">Special Instruction</div>
                <div className="view-page-value">
                  {collectionNote.remarks && collectionNote.remarks}
                </div>
              </div>
            </div>
            <div className="view-page-headings">Collection Items</div>
            <div
              // style={{ maxHeight: "400px", border: "1px solid #eaeaea" }}
              className="view-page-contents-container1"
            >
              <div
                // style={
                //   !this.props.clearOpenList
                //     ? {
                //         overflow: "auto",
                //         maxWidth: "calc(100vw - 10vw)",
                //         maxHeight: 350
                //       }
                //     : {
                //         overflow: "auto",
                //         maxWidth: "calc(100vw - 23vw)",
                //         maxHeight: 350
                //       }
                // }
                style={
                  !this.props.clearOpenList
                    ? {
                      overflow: "auto",
                      border: "1px solid #eaeaea",
                      // maxWidth: "calc(100vw - 8em)",
                      // maxHeight: "calc(100vh - 300px)",
                    }
                    : {
                      overflow: "auto",
                      border: "1px solid #eaeaea",
                      // maxWidth: "calc(100vw - 330px)",
                      // maxHeight: "calc(100vh - 300px)",
                    }
                }
              >
                <Table
                  stickyHeader
                  aria-labelledby="tableTitle"
                  size="small"
                  aria-label="enhanced table"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={collectionNoteDetails.length}
                    headCells={this.state.headCells}
                  />
                  <TableBody>
                    {collectionNoteDetails.length > 0 ? (
                      stableSort(
                        collectionNoteDetails,
                        getSorting(order, orderBy)
                      )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={row.collectionNoteID}
                              style={{ cursor: "pointer" }}
                            >
                              {this.state.headCells[0].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {index + 1}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[1].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="left"
                                  style={{
                                    maxWidth: "128px",
                                    fontFamily: "Roboto",
                                    fontSize: "14px",
                                    paddingLeft: "0.5em",
                                    borderRight: "1px solid #eaeaea",
                                    whiteSpace: "normal",
                                  }}
                                //className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockDescription}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[2].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.noOfPts}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[3].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.totalPackage}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[4].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.noOfCopies}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[5].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.totalQuantity}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[6].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.weightPerCortanBox}
                                  </Typography>
                                </TableCell>
                              )}
                              {this.state.headCells[7].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.netWeight}
                                  </Typography>
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })
                    ) : (
                        <TableRow>
                          {this.state.loading ? (
                            <TableCell
                              colSpan={8}
                              style={{ textAlign: "center" }}
                            >
                              <LinearProgress />
                            </TableCell>
                          ) : (
                              <TableCell
                                colSpan={8}
                                style={{ textAlign: "center" }}
                              >
                                No result found
                              </TableCell>
                            )}
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                rowsPerPageOptions={[100, 200, 300]}
                component="div"
                count={collectionNoteDetails.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "previous page",
                }}
                nextIconButtonProps={{
                  "aria-label": "next page",
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                className="view-page-contents-container1"
              />
            </div>
            <Divider style={{ marginTop: "25px" }} />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.props.history.push("/collectionNote")}
                >
                  CANCEL
                </Button>
              </div>
              <div style={{ marginLeft: "1em" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={() => this.handleShowPopup()}
                >
                  <IconButton
                    aria-label="print"
                    size="small"
                    style={{ color: "#fff" }}
                  >
                    <CustomPrintIcon style={{ color: "#fff" }} />
                  </IconButton>
                  {"PRINT COLLECTION NOTE"}
                </Button>
              </div>

              {this.state.showPopup && (
                <ReportViewer
                  url={
                    `${window.localStorage.ReportURLByID}` +
                    "CollectionNote&IsDirectPrint=True&ReportParam=CollectionNoteID&Param1=" +
                    `${this.props.match.params.collectionNoteId}`
                  }
                  showPopup={this.state.showPopup}
                  title={"Collection Note"}
                  onClose={() => this.handleHidePopup()}
                  handleHidePopup={() => this.handleHidePopup()}
                />
              )}
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewCollectionNote);
