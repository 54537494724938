import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_GENRIC_WORK_ORDER;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// ----------------------- job scheduler ----------------//

export const getjobScheduleList = async () => {
  let jobScheduleData = [];
  await pgsService
    .get("/JobSchedulers")
    .then((r) => {
      jobScheduleData = r;
    })
    .catch((e) => { });
  return jobScheduleData;
};

export const getJobScheduler = async (codeTypeId) => {
  let jobScheduleData = {};
  await pgsService
    .get("/jobScheduler/" + codeTypeId)
    .then((response) => {
      let data = response.data;
      jobScheduleData = {
        codeTypeID: data.codeTypeID,
        codeType: data.codeType,
        codeTypeDescription: data.codeTypeDescription,
        canAddNew: data.canAddNew,
        canEditStartDate: data.canEditStartDate,
        canEditValue: data.canEditValue,
        serviceSpecific: data.data,
        codevalueDataType: data.codevalueDataType,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });
  return jobScheduleData;
};

export const getjobScheduleStatusDropDownList = async () => {
  let statusList = [];
  await pgsService
    .get("/GetJobSchedulerStatusDropdown", {
      headers: {
        Authorization: "Bearer " + window.localStorage.token,
      },
    })
    .then((r) => {
      statusList = r;
    })
    .catch((e) => { });
  return statusList;
};

export const deleteJobScheduler = async (deletionIds) => {
  let jobScheduleData = {};
  let modifiedBy = localStorage.loggedInId;
  await pgsService
    .delete("/JobSchedulers/", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((response) => {
      toastr.success("Job Scheduler Cancelled Successfully");
    })
    .catch((e) => { });
  return jobScheduleData;
};

export const postJobScheduler = async (data, history) => {
  await pgsService
    .put("/UpdateJobSchedulersPage", data)
    .then((r) => {
      if (r.data === 200) toastr.success("Job Scheduler Updated Successfully");
    })
    .catch((e) => {
      // if (e.response && e.response.data.errors[0].message != undefined) {
      //   toastr.error(e.response.data.errors[0].message);
      // } else if (e.response.data.errors[0]) {
      //   toastr.error(e.response.data.errors[0]);
      // }
    });
};

export const proceedToComplete = async (reqBody) => {
  let jobSchedulerProceedToComplete = [];
  await pgsService
    .put("/ProceedToCompleteJobs", reqBody)
    .then((r) => {
      jobSchedulerProceedToComplete = r;
      if (r.data === 200) toastr.success("Job Scheduler Updated Successfully");
    })
    .catch((e) => { });
  return jobSchedulerProceedToComplete;
};

export const getFliteredJobSchedulerList = async (reqBody) => {
  let jobSchedulerList = [];
  await pgsService
    .post("/JobSchedulerAdvanceSerach", reqBody)
    .then((r) => {
      jobSchedulerList = r;
    })
    .catch((e) => { });
  return jobSchedulerList;
};

export const duplicateJobScheduler = async (
  reqBody,
  ParentJobSchedulerID,
  history
) => {
  let jobScheduleData = {};
  await pgsService
    .post("/JobSchedulers/" + ParentJobSchedulerID, reqBody)
    .then((response) => {
      if (response.status === 201) {
        history.push("/jobScheduler");
        toastr.success("Job Scheduler Duplicate Data Created Successfully");

        jobScheduleData = response.status;
      }
    })
    .catch((e) => { });
  return jobScheduleData;
};

// ----------------------- job scheduler ends ----------------//

export const postForReOpenJS = async (rBody) => {
  let jobSchedulerList = [];

  await pgsService
    .post(`/JobSchedulerReOpen/`, rBody)
    .then((r) => {
      jobSchedulerList = r;
    })
    .catch((e) => { });
  return jobSchedulerList;
};
