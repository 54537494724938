import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Autocomplete } from "@material-ui/lab";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { resourceValidation } from "../../../shared/resource";
import { ExportCSV } from "../../../shared/ExportCSV";
import StatusText from "../../../shared/statusText";
import moment from "moment";
import {
  SearchIcon,
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  CustomPrintIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import BorderColorIcon from '@material-ui/icons/FileCopyTwoTone';
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import {
  stableSort,
  getSorting,
  DialogActions,
} from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import Validator from "../../../shared/validator";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import types from "../genericTypes";
import { StateContext } from "../../../shared/globalState";
import {
  getDeliveryNoteList,
  deleteDeliveryNote,
  getFliteredDeliveryNoteList,
  getDeliveryStatusList,
} from "../reportServiceAction";
import LinearProgress from "@material-ui/core/LinearProgress";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const columns = [
  {
    name: "Modified Date",
    id: 15,
    show: false,
  },
  {
    name: "Modified By",
    id: 16,
  },
  {
    name: "Created Date",
    id: 17,
  },
  {
    name: "Created By",
    id: 18,
  },
];
const getColor = (status) => {
  switch (status) {
    case "In Progress":
      return "#03A6FD"; //In Progress
    case "Out For Delivery":
      return "#ECB507"; //Out For Delivery
    case "Item Delivered":
      return "#16831F"; //Item Delivered
    case "Cancelled":
      return "#AD2219"; //Cancelled
    default:
      return "#03A6FD";
  }
};
const validateNotRequired100 = new Validator("notRequired100");

function createData(
  deliveryNoteID,
  deliveryNoteNo,
  jobGroupID,
  custmerCode,
  clientname,
  workOrderNo,
  title,
  jobGroupNumber,
  shipmentType,
  shipmentTypeID,
  isUrgent,
  deliverFromAddress,
  deliveryToAddress,
  totalQuantity,
  status,
  statusID,
  deliveryDate,
  deliveryTime,
  agentCode,
  driverName,
  createdBy,
  createdByName,
  createdDate,
  modifiedBy,
  modifiedByName,
  modifiedDate
) {
  return {
    deliveryNoteID,
    deliveryNoteNo,
    jobGroupID,
    custmerCode,
    clientname,
    workOrderNo,
    title,
    jobGroupNumber,
    shipmentType,
    shipmentTypeID,
    isUrgent,
    deliverFromAddress,
    deliveryToAddress,
    totalQuantity,
    status,
    statusID,
    deliveryDate,
    deliveryTime,
    agentCode,
    driverName,
    createdBy,
    createdDate,
    createdByName,
    modifiedBy,
    modifiedByName,
    modifiedDate,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          {/* <FormControlLabel
            control={
              <Checkbox
                color="primary"
                //checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                //checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all Acronyms" }}
              />
            }
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    clientname: "",
    workOrderNo: "",
    title: "",
    jobGroupNumber: "",
    status: "",
    deliveryNoteNo: "",
    modifiedDateFrom: null,
    modifiedDateTo: null,
  });
  const [formValid, setIsformValid] = useState(false);
  let acnArr = props.roles.filter((u) => u.menuName === "Delivery Note");
  let acnObj = acnArr[0];

  const [deliveryNoteSearchValue, setValues] = useState({
    clientname: "",
    workOrderNo: "",
    title: "",
    jobGroupNumber: "",
    status: 0,
    deliveryNoteNo: "",
    modifiedDateFrom: null,
    modifiedDateTo: null,
  });

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);

  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handleDeleteDeliveryNote,
    searchPopupOpen,
    selectedDeliveryNote,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchDeliveryNote,
    selected,
    searchClicked,
    isActive,
    statusList,
    hendleVewColums,
    exportDataValues,
  } = props;
  const selectedDeliveryNoteID = selected && selected.length > 0 && selected[0];
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteDeliveryNote = () => {
    handleDeleteDeliveryNote();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = deliveryNoteSearchValue;
    searchValues.clientname = "";
    searchValues.workOrderNo = "";
    searchValues.title = "";
    searchValues.jobGroupNumber = "";
    searchValues.status = 0;
    searchValues.deliveryNoteNo = "";
    searchValues.modifiedDateFrom = null;
    searchValues.modifiedDateTo = null;
    searchValues = deliveryNoteSearchValue;
    setErrors({});
  };

  const searchPopup = () => {
    handleSearchDeliveryNote(deliveryNoteSearchValue);
    clearSearchValues();
  };
  const handleSearchStatusValuesChange = (event, values) => {
    event.persist();
    let value = event.target.value;
    setValues((deliveryNoteSearchValue) => ({
      ...deliveryNoteSearchValue,
      status: values,
    }));
    if (value !== undefined) setIsformValid(true);
    else setIsformValid(false);
  };
  const handleSearchInput = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;
    setValues((deliveryNoteSearchValue) => ({
      ...deliveryNoteSearchValue,
      [event.target.name]: event.target.value,
    }));
    switch (name) {
      case "clientname":
        let clientnameValid = false;
        if (value !== "") {
          clientnameValid = validateNotRequired100(value);
          setIsformValid(clientnameValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: clientnameValid
              ? ""
              : resourceValidation.ValidName,
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: clientnameValid ? "" : "",
          }));
        }
        break;
      case "workOrderNo":
        let workOrderNoValid = false;
        if (value !== "") {
          workOrderNoValid = validateNotRequired100(value);
          setIsformValid(workOrderNoValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: workOrderNoValid
              ? ""
              : resourceValidation.validWorkOrder,
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: workOrderNoValid ? "" : "",
          }));
        }
        break;
      case "title":
        let titleValid = false;
        if (value !== "") {
          titleValid = validateNotRequired100(value);
          setIsformValid(titleValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: titleValid
              ? ""
              : resourceValidation.validTitle,
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: titleValid ? "" : "",
          }));
        }
        break;
      case "jobGroupNumber":
        let jobGroupNumberValid = false;
        if (value !== "") {
          jobGroupNumberValid = true;
          setIsformValid(jobGroupNumberValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: jobGroupNumberValid
              ? ""
              : resourceValidation.validJobGroup,
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: jobGroupNumberValid ? "" : "",
          }));
        }
        break;
      case "deliveryNoteNo":
        let deliveryNoteNoValid = false;
        if (value !== "") {
          deliveryNoteNoValid = true;
          setIsformValid(deliveryNoteNoValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: deliveryNoteNoValid
              ? ""
              : resourceValidation.validDeliveryNoteNo,
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: deliveryNoteNoValid ? "" : "",
          }));
        }
        break;
      default:
        break;
    }
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
  };
  const isDateValid = (name, date) => {
    let searchValues = deliveryNoteSearchValue;
    if (name === "modifiedDateFrom") {
      if (date !== null && searchValues.modifiedDateTo !== null) {
        setIsformValid(
          date.toString() !== "Invalid Date" &&
            searchValues.modifiedDateTo.toString() !== "Invalid Date"
            ? true
            : false
        );
      }
    } else {
      if (date !== null && searchValues.modifiedDateFrom !== null) {
        setIsformValid(
          date.toString() !== "Invalid Date" &&
            searchValues.modifiedDateFrom.toString() !== "Invalid Date"
            ? true
            : false
        );
      }
    }
  };
  const handleDateChange = (date) => {
    let name = "modifiedDateFrom";
    setValues((deliveryNoteSearchValue) => ({
      ...deliveryNoteSearchValue,
      [name]: date && date.toLocaleDateString(),
    }));
    isDateValid(name, date);
  };
  const handleDateChange1 = (date) => {
    let name = "modifiedDateTo";
    setValues((deliveryNoteSearchValue) => ({
      ...deliveryNoteSearchValue,
      [name]: date && date.toLocaleDateString(),
    }));
    isDateValid(name, date);
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div
              color="inherit"
              variant="subtitle1"
              className="row-selected-div"
            >
              {numSelected} row(s) selected
            </div>
          </div>
        )}
        {/* {numSelected === 1 && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%"
            }}
          >
            <Button
              variant="text"
              size="small"
              style={{ width: "300px" }}
              className={"enabled-button"}
            >
              <IconButton aria-label="print" size="small">
                <CustomPrintIcon />
              </IconButton>
              {"PRINT DELIVERY NOTE"}
            </Button>
          </div>
        )} */}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.addAction && (
                  <Tooltip title="Clone">
                    <IconButton
                      aria-label="clone"
                      className={classes.iconHover}
                      onClick={(e) => props.history.push(`/clone_deliveryNote/${selectedDeliveryNoteID}`)}
                    >
                      <BorderColorIcon
                      // style={{ fontSize: "8px" }} 
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    {/* <Link to={`/view_deliveryNote/${selectedDeliveryNoteID}`}> */}
                    <IconButton
                      aria-label="view"
                      className={classes.iconHover}
                      onClick={(e) => props.history.push(`/view_deliveryNote/${selectedDeliveryNoteID}`)}
                    >
                      <ViewIcon />
                    </IconButton>

                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    {isActive ? (
                      // <Link to={`/edit_deliveryNote/${selectedDeliveryNoteID}`} >
                      <IconButton aria-label="edit" className={classes.iconHover}
                        onClick={(e) => props.history.push(`/edit_deliveryNote/${selectedDeliveryNoteID}`)}
                      >
                        <EditIcon />
                      </IconButton>

                    ) : (
                        <IconButton aria-label="edit" disabled={!isActive} className={classes.iconHover}>
                          <EditIcon />
                        </IconButton>
                      )}
                  </Tooltip>
                )}
              </div>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={handleClickOpen}
                  disabled={!isActive}
                >
                  <DeleteIcon className={classes.iconHover} />
                </IconButton>
              </Tooltip>
            )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete Delivery Note
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to cancel the Delivery Note{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selectedDeliveryNote} ?
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteDeliveryNote}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  <form onSubmit={searchPopup}>
                    {acnObj && acnObj.advanceSearchAction && (
                      <Tooltip title="Search">
                        <IconButton
                          aria-label="Search"
                          className={classes.iconHover}
                          onClick={handleSearchOpen}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      onClose={handleSearchPopupClose}
                      aria-labelledby="customized-dialog-title"
                      open={searchPopupOpen}
                      maxWidth="md"
                    >
                      <DialogTitle
                        id="customized-dialog-title"
                        onClose={handleSearchPopupClose}
                      >
                        Advanced Search
                  </DialogTitle>
                      <DialogContent dividers>
                        <React.Fragment>
                          <div style={{ display: "flex", width: "600px" }}>
                            <TextField
                              id="standard-required"
                              label="Customer Name"
                              name="clientname"
                              style={{ width: "31%" }}
                              margin="normal"
                              value={deliveryNoteSearchValue.clientname}
                              inputProps={{
                                maxLength: 50,
                              }}
                              onChange={handleSearchInput}
                              error={errors.clientname}
                              helperText={
                                errors.clientname ? errors.clientname : ""
                              }
                            />
                            <TextField
                              id="standard-required"
                              label="Work Order No"
                              name="workOrderNo"
                              style={{ marginLeft: "2%", width: "31%" }}
                              margin="normal"
                              value={deliveryNoteSearchValue.workOrderNo}
                              inputProps={{
                                maxLength: 50,
                              }}
                              onChange={handleSearchInput}
                              error={errors.workOrderNo}
                              helperText={
                                errors.workOrderNo ? errors.workOrderNo : ""
                              }
                            />
                            <TextField
                              id="standard-required"
                              label="Title"
                              name="title"
                              style={{ marginLeft: "2%", width: "31%" }}
                              margin="normal"
                              value={deliveryNoteSearchValue.title}
                              inputProps={{
                                maxLength: 50,
                              }}
                              onChange={handleSearchInput}
                              error={errors.title}
                              helperText={errors.title ? errors.title : ""}
                            />
                          </div>
                          <div style={{ display: "flex" }}>
                            {/* <TextField
                          id="standard-required"
                          label="Job Group No"
                          name="jobGroupNumber"
                          style={{ width: "31%" }}
                          margin="normal"
                          value={deliveryNoteSearchValue.jobGroupNumber}
                          inputProps={{
                            maxLength: 50,
                          }}
                          onChange={handleSearchInput}
                          error={errors.jobGroupNumber}
                          helperText={
                            errors.jobGroupNumber ? errors.jobGroupNumber : ""
                          }
                        /> */}
                            <TextField
                              id="standard-required"
                              label="Delivery Note No"
                              name="deliveryNoteNo"
                              style={{ width: "31%" }}
                              margin="normal"
                              value={deliveryNoteSearchValue.deliveryNoteNo}
                              inputProps={{
                                maxLength: 50,
                              }}
                              onChange={handleSearchInput}
                              error={errors.deliveryNoteNo}
                              helperText={
                                errors.deliveryNoteNo ? errors.deliveryNoteNo : ""
                              }
                            />
                            <Autocomplete
                              autoComplete
                              includeInputInList
                              id="status"
                              name="status"
                              options={statusList}
                              style={{ marginLeft: "2%", width: "31%" }}
                              onChange={handleSearchStatusValuesChange}
                              getOptionLabel={(option) => option.text}
                              value={deliveryNoteSearchValue.status}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={"Select Status"}
                                  name="status"
                                  id="status"
                                  margin="normal"
                                  style={{ width: "100%" }}
                                  value={deliveryNoteSearchValue.status}
                                />
                              )}
                            />
                          </div>
                          <div style={{ display: "flex" }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                autoOk
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                style={{ width: "31%" }}
                                id="modifiedDateFrom"
                                label="Modified Date From"
                                value={deliveryNoteSearchValue.modifiedDateFrom}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                autoOk
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                style={{ marginLeft: "2%", width: "31%" }}
                                id="date-picker-inline"
                                label="Modified Date To"
                                value={deliveryNoteSearchValue.modifiedDateTo}
                                onChange={handleDateChange1}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </React.Fragment>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={handleSearchClose}
                        >
                          CANCEL
                    </Button>
                        {!formValid ? (
                          <Button
                            disabled
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                            onClick={searchPopup}
                          >
                            SEARCH
                          </Button>
                        ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ boxShadow: "none" }}
                              onClick={searchPopup}
                            >
                              SEARCH
                            </Button>
                          )}
                      </DialogActions>
                    </Dialog>
                  </form>
                )}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="add"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/add_deliveryNote")}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {acnObj && acnObj.exportAction && (
                <Tooltip title="Download">
                  <ExportCSV
                    csvData={exportDataValues}
                    fileName={"DeliveryNote"}
                  />
                </Tooltip>
              )}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                hendleVewColums={hendleVewColums}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class DeliveryNoteMater extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    deliveryNoteList: [],
    refreshData: false,
    selectedDeliveryNote: "",
    selectedDeliveryNoteId: 1,
    loading: false,
    searchPopupOpen: false,
    deliveryNoteSearchValues: {},
    searchClicked: false,
    isActive: true,
    selectedShowColumns: [],
    activeRows: [],
    exportDataValues: [],
    statusList: [],
    headCells: [
      {
        id: 1,
        code: "deliveryNoteNo",
        numeric: false,
        disablePadding: true,
        label: "Delivery Note No",
        show: true,
      },
      {
        id: 2,
        code: "custmerCode",
        numeric: false,
        disablePadding: true,
        label: "Customer Code",
        show: true,
      },
      // {
      //   id: 3,
      //   code: "clientname",
      //   numeric: false,
      //   disablePadding: true,
      //   label: "Customer",
      //   show: true,
      // },
      {
        id: 3,
        code: "workOrderNo",
        numeric: false,
        disablePadding: true,
        label: "Work Order No",
        show: true,
      },
      {
        id: 4,
        code: "title",
        numeric: false,
        disablePadding: false,
        label: "Desc/Title",
        show: true,
      },
      {
        id: 5,
        code: "status",
        numeric: false,
        disablePadding: true,
        label: "Status",
        show: true,
      },
      {
        id: 6,
        code: "deliveryDate",
        numeric: false,
        disablePadding: true,
        label: "Delivery Date",
        show: true,
      },
      {
        id: 7,
        code: "deliveryTime",
        numeric: false,
        disablePadding: true,
        label: "Delivery Time",
        show: true,
      },
      // {
      //   id: 6,
      //   code: "jobGroupNumber",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Job Group No",
      //   show: true,
      // },
      {
        id: 8,
        code: "agentCode",
        numeric: false,
        disablePadding: true,
        label: "Agent Code",
        show: true,
      },
      {
        id: 9,
        code: "shipmentType",
        numeric: false,
        disablePadding: true,
        label: "Shipment Type",
        show: true,
      },

      {
        id: 10,
        code: "isUrgent",
        numeric: false,
        disablePadding: false,
        label: "Priority",
        show: true,
      },
      {
        id: 11,
        code: "totalQuantity",
        numeric: false,
        disablePadding: false,
        label: "Total Qty",
        show: true,
      },

      {
        id: 12,
        code: "deliverFromAddress",
        numeric: false,
        disablePadding: true,
        label: "From",
        show: true,
      },
      {
        id: 13,
        code: "deliveryToAddress",
        numeric: false,
        disablePadding: true,
        label: "To",
        show: true,
      },
      {
        id: 14,
        code: "driverName",
        numeric: false,
        disablePadding: true,
        label: "Driver Name",
        show: true,
      },
      {
        id: 15,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 16,
        code: "modifiedByName",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 17,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 18,
        code: "createdByName",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };

  componentDidMount() {
    this.props.handleSettingHeader("Delivery Note");
    this.props.setSelectedList("genericSystemOpen", "deliveryNote");
    const dispatch = this.context[1];

    getDeliveryNoteList().then((r) => {
      dispatch({
        type: types.DELIVERYNOTE_LIST,
        newStore: r.data,
      });
      this.getAllDeliveryNotes(r.data);
      this.exportDeliveryNotes(r.data);
    });
    getDeliveryStatusList().then((r) => {
      let list = r.data;
      list &&
        list.length > 0 &&
        this.setState({
          statusList: list.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
        });
    });
    this.setState({ loading: true });
  }

  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };
  exportDeliveryNotes = (deliveryNoteList) => {
    let list = deliveryNoteList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          "Delivery Note No": d.deliveryNoteNo,
          "Customer Code": d.custmerCode,
          // "Customer Name": d.clientname,
          "Work Order No": d.workOrderNo,
          "Desc/Title": d.title,
          Status: d.status,
          "Delivery Date": d.deliveryDate,
          "Delivery Time": d.deliveryTime,
          "Agent Code": d.agentCode,
          "Shipment Type": d.shipmentType,
          // "Job Group No": d.jobGroupNumber,

          Priority: d.isUrgent,
          "Total Qty": d.totalQuantity,

          From: d.deliverFromAddress,
          To: d.deliveryToAddress,
          "Driver Name": d.driverName,
          "Modified Date": moment(d.modifiedDate).format("DD/MM/YYYY HH:mm:ss"),

          "Modified By": d.modifiedByName,
          "Created Date": moment(d.createdDate).format("DD/MM/YYYY HH:mm:ss"),

          "Created By": d.createdByName,
        });
      });
    this.setState({ exportDataValues: rows });
  };
  getAllDeliveryNotes = (deliveryNoteList) => {
    let list = deliveryNoteList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.deliveryNoteID,
            d.deliveryNoteNo,
            d.jobGroupID,
            d.custmerCode,
            d.clientname,
            d.workOrderNo,
            d.title,
            d.jobGroupNumber,
            d.shipmentType,
            d.shipmentTypeID,
            d.isUrgent,
            d.deliverFromAddress,
            d.deliveryToAddress,
            d.totalQuantity,
            d.status,
            d.statusID,
            d.deliveryDate,
            d.deliveryTime,
            d.agentCode,
            d.driverName,
            d.createdBy,
            d.createdByName,
            d.createdDate,
            d.modifiedBy,
            d.modifiedByName,
            d.modifiedDate
          )
        );
      });
    this.setState({
      deliveryNoteList: rows,
      loading: false,
      activeRows: rows
        .filter(
          (x) => x.status !== "Item Delivered" && x.status !== "Cancelled"
        )
        .map((n) => n.deliveryNoteID),
    });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.deliveryNoteList && store.deliveryNoteList.length > 0)
      if (
        this.state.deliveryNoteList.length !== store.deliveryNoteList.length
      ) {
        this.getAllDeliveryNotes(store.deliveryNoteList);
      }
    return true;
  }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { deliveryNoteList } = this.state;
    if (event.target.checked) {
      const newSelecteds = deliveryNoteList
        .filter(
          (x) => x.status !== "Item Delivered" && x.status !== "Cancelled"
        )
        .map((n) => n.deliveryNoteID);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, data) {
    let deliveryNoteID = data.deliveryNoteID,
      selected = [],
      newSelected = [];
    if (event.target.checked) {
      newSelected = newSelected.concat(selected, deliveryNoteID);
      // this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedDeliveryNote: data.deliveryNoteNo,
        selectedDeliveryNoteId: deliveryNoteID,
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedDeliveryNote: {},
        selectedDeliveryNoteId: 0,
      });
    }

    let { deliveryNoteList } = this.state;
    // const selectedIndex = selected.indexOf(deliveryNoteID);
    // let newSelected = [];
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, deliveryNoteID);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1)
    //   );
    // }
    // if (newSelected.length === 1) {
    //   this.setState({
    //     selected: newSelected,
    //     selectedDeliveryNote: deliveryNote.deliveryNoteNo,
    //     selectedDeliveryNoteId: deliveryNoteID,
    //   });
    // } else {
    //   this.setState({
    //     selected: newSelected,
    //     selectedDeliveryNote: "",
    //     selectedDeliveryNoteId: 0,
    //   });
    // }
    let active = true;
    deliveryNoteList.map((r) =>
      newSelected.includes(r.deliveryNoteID)
        ? r.status === "Item Delivered" || r.status === "Cancelled"
          ? (active = false)
          : ""
        : ""
    );
    this.setState({ isActive: active });
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true, page: 0 });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchDeliveryNote = (handleSearchDeliveryNote) => {
    let deliveryNoteSearchValue = handleSearchDeliveryNote;
    let clientname = deliveryNoteSearchValue.clientname;
    let workOrderNo = deliveryNoteSearchValue.workOrderNo;
    let title = deliveryNoteSearchValue.title;
    // let jobGroupNumber = deliveryNoteSearchValue.jobGroupNumber;
    let deliveryNoteNo = deliveryNoteSearchValue.deliveryNoteNo;
    let statusID =
      deliveryNoteSearchValue.status && deliveryNoteSearchValue.status.id;
    let modifiedDateFrom = deliveryNoteSearchValue.modifiedDateFrom;
    let modifiedDateTo = deliveryNoteSearchValue.modifiedDateTo;
    let reqBody = {
      clientname,
      workOrderNo,
      title,
      // jobGroupNumber,
      deliveryNoteNo,
      statusID,
      modifiedDateFrom,
      modifiedDateTo,
    };
    const dispatch = this.context[1];
    getFliteredDeliveryNoteList(reqBody).then((r) => {
      dispatch({
        type: types.DELIVERYNOTE_LIST,
        newStore: r.data,
      });
      this.setState({
        loading: true,
        searchPopupOpen: false,
        searchClicked: true,
      });
      this.getAllDeliveryNotes(r.data);
      this.exportDeliveryNotes(r.data);
    });
  };

  handleDeleteDeliveryNote = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteDeliveryNote(modifiedBy, deletionIds).then((r) => {
      getDeliveryNoteList().then((r) => {
        dispatch({
          type: types.DELIVERYNOTE_LIST,
          newStore: r.data,
        });
        this.getAllDeliveryNotes(r.data);
      });
      this.props.history.push("/DeliveryNote");
      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };

  clearSearch = () => {
    const dispatch = this.context[1];
    getDeliveryNoteList().then((r) => {
      dispatch({
        type: "getDeliveryNoteList",
        newStore: r.data,
      });
      this.getAllDeliveryNotes(r.data);
      this.exportDeliveryNotes(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      deliveryNoteList,
      exportDataValues,
      headCells,
    } = this.state;
    const isSelected = (deliveryNoteID) =>
      selected.indexOf(deliveryNoteID) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteDeliveryNote={this.handleDeleteDeliveryNote}
            selectedDeliveryNote={this.state.selectedDeliveryNote}
            selectedDeliveryNoteId={this.state.selectedDeliveryNoteId}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchDeliveryNote={this.handleSearchDeliveryNote}
            deliveryNoteSearchValue={this.state.deliveryNoteSearchValues}
            searchClicked={this.state.searchClicked}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            statusList={this.state.statusList}
            deliveryNoteList={deliveryNoteList}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ whiteSpace: "nowrap" }}
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {deliveryNoteList.length > 0 ? (
                    stableSort(deliveryNoteList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.deliveryNoteID);
                        return (
                          <TableRow
                            hover
                            onClick={(event) => this.handleClick(event, row)}
                            deliveryNote="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.deliveryNoteID}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.deliveryNoteNo &&
                                  row.deliveryNoteNo.length > 16 ? (
                                    <Tooltip title={row.deliveryNoteNo}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.deliveryNoteNo}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.deliveryNoteNo}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.custmerCode &&
                                  row.custmerCode.length > 16 ? (
                                    <Tooltip title={row.custmerCode}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.custmerCode}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.custmerCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {/* {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.clientname &&
                                row.clientname.length > 16 ? (
                                  <Tooltip title={row.clientname}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.clientname}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.clientname}
                                  </Typography>
                                )}
                              </TableCell>
                            )} */}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.workOrderNo &&
                                  row.workOrderNo.length > 16 ? (
                                    <Tooltip title={row.workOrderNo}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.workOrderNo}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.workOrderNo}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.title && row.title.length > 16 ? (
                                  <Tooltip title={row.title}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.title}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.title}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  <StatusText color={getColor(row.status)}>
                                    {row.status}
                                  </StatusText>
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {moment(row.deliveryDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {/* {row.deliveryTime &&
                                    row.deliveryTime.slice(0, -3)} */}
                                  {row.deliveryTime ? moment(row.deliveryTime, 'h:mm A').format("hh:mm A") : ""}

                                </Typography>
                              </TableCell>
                            )}
                            {/* {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.jobGroupNumber &&
                                row.jobGroupNumber.length > 16 ? (
                                  <Tooltip title={row.jobGroupNumber}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.jobGroupNumber}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.jobGroupNumber}
                                  </Typography>
                                )}
                              </TableCell>
                            )} */}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.agentCode && row.agentCode.length > 16 ? (
                                  <Tooltip title={row.agentCode}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.agentCode}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.agentCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.shipmentType &&
                                  row.shipmentType.length > 16 ? (
                                    <Tooltip title={row.shipmentType}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.shipmentType}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.shipmentType}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.isUrgent ? "Urgent" : "Normal"}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.totalQuantity &&
                                  row.totalQuantity.length > 16 ? (
                                    <Tooltip title={row.totalQuantity}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.totalQuantity}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalQuantity}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.deliverFromAddress &&
                                  row.deliverFromAddress.length > 16 ? (
                                    <Tooltip title={row.deliverFromAddress}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.deliverFromAddress}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.deliverFromAddress}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.deliveryToAddress &&
                                  row.deliveryToAddress.length > 16 ? (
                                    <Tooltip title={row.deliveryToAddress}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.deliveryToAddress}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.deliveryToAddress}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.driverName &&
                                  row.driverName.length > 16 ? (
                                    <Tooltip title={row.driverName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.driverName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.driverName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[15].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.modifiedByName &&
                                  row.modifiedByName.length > 16 ? (
                                    <Tooltip title={row.modifiedByName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[16].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[17].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdByName}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={20} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={20} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={deliveryNoteList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(DeliveryNoteMater);
