import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";
import clone from "clone";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import { withRouter } from "react-router-dom";
import TableHead from "@material-ui/core/TableHead";
import { EditIcon } from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import DeleteIcon from "@material-ui/icons/Delete";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CheckIcon from "@material-ui/icons/Check";
import { Autocomplete } from "@material-ui/lab";
import { StateContext } from "../../../shared/globalState";
import { addDispatchStyles } from "../../../shared/styles";
import { getCustomerDropdown } from "../../masters/masterActions";
import { addZeros } from "../../../shared/tableCommonFunctions";
import {
  GetMBAByWOID,
  CreateMBA,
  getOWCDetailsForView,
  GetMBAByMBAHeaderID,
  UpdateMBA,
  deleteMBAByID,
} from "./action";

let gstType = [
  { id: 0, label: "Exclusive" },
  { id: 1, label: "Inclusive" },
];

const headCells = [
  {
    id: "billingDesc",
    numeric: false,
    disablePadding: true,
    label: "Billing Description*",
  },
  {
    id: "qty",
    numeric: false,
    disablePadding: false,
    label: "Qty*",
  },
  {
    id: "rate",
    numeric: false,
    disablePadding: false,
    label: "Rate*",
  },
  {
    id: "billingAmt",
    numeric: false,
    disablePadding: false,
    label: "Billing Amt $S*",
  },
  {
    id: "gsttype",
    numeric: false,
    disablePadding: false,
    label: "GST Type*",
  },
  {
    id: "acCode",
    numeric: false,
    disablePadding: false,
    label: "A/c code*",
  },
  {
    id: "pic",
    numeric: false,
    disablePadding: false,
    label: "PIC*",
  },
  {
    id: "rc",
    numeric: false,
    disablePadding: false,
    label: "RC*",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

const headCells1 = [
  {
    id: "billingDesc",
    numeric: false,
    disablePadding: true,
    label: "Billing Description ",
  },
  {
    id: "qty",
    numeric: false,
    disablePadding: false,
    label: "Qty",
  },
  {
    id: "rate",
    numeric: false,
    disablePadding: false,
    label: "Rate",
  },
  {
    id: "billingAmt",
    numeric: false,
    disablePadding: false,
    label: "Billing Amt $",
  },
  {
    id: "gsttype",
    numeric: false,
    disablePadding: false,
    label: "GST Type ",
  },
  {
    id: "acCode",
    numeric: false,
    disablePadding: false,
    label: "A/c code",
  },
  {
    id: "pic",
    numeric: false,
    disablePadding: false,
    label: "PIC",
  },
  {
    id: "rc",
    numeric: false,
    disablePadding: false,
    label: "RC",
  },
];

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderTop: "1px solid #eaeaea",
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {props.currentLocation === "view"
          ? headCells1.map((headCell, i) => (
            <React.Fragment>
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
                style={{ borderLeft: "1px sold #000" }}
              >
                <span style={{ color: "#306dca" }}>{headCell.label}</span>
              </TableCell>
            </React.Fragment>
          ))
          : headCells.map((headCell, i) => (
            <React.Fragment>
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
                style={{ borderLeft: "1px sold #000" }}
              >
                <span style={{ color: "#306dca" }}>{headCell.label}</span>
              </TableCell>
            </React.Fragment>
          ))}
      </TableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { } = props;

  return (
    <div>
      {props.currentLocation === "view" ? (
        <div
          style={{
            display: "flex",
            marginTop: "1em",
          }}
        />
      ) : (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                //   width: "100%",
                border: "1px solid #eaeaea",
                borderBottom: "none",
                borderRadius: "4px",
                marginTop: "1em",
              }}
            >
              {props.disableAdd ? (
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  style={{
                    boxShadow: "none",
                    padding: "2px",
                    margin: ".5em",
                    fontSize: "11px",
                  }}
                  onClick={(e) => props.handleClickButton(e, "add")}
                // style={
                //   !props.disableAdd
                //     ? {
                //         color: "#fff",
                //         background: "#3982ea",
                //       }
                //     : { color: "#3982ea", fontSize: "11px" }
                // }
                >
                  <AddIcon style={{ fontSize: "18px" }} /> Add
                </Button>
              ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      boxShadow: "none",
                      padding: "2px",
                      margin: ".5em",
                      fontSize: "11px",
                    }}
                    onClick={(e) => props.handleClickButton(e, "add")}
                  // style={
                  //   !props.disableAdd
                  //     ? {
                  //         color: "#fff",
                  //         background: "#3982ea",
                  //       }
                  //     : { color: "#3982ea", fontSize: "11px" }
                  // }
                  >
                    <AddIcon style={{ fontSize: "18px" }} /> Add
                  </Button>
                )}
              {props.disableEdit ? (
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  style={{
                    boxShadow: "none",
                    padding: "2px",
                    margin: ".5em",
                    fontSize: "11px",
                  }}
                  // style={
                  //   !props.disableEdit
                  //     ? {
                  //         color: "#fff",
                  //         background: "#3982ea",
                  //       }
                  //     : { color: "#3982ea", fontSize: "11px" }
                  // }
                  onClick={(e) => props.handleClickButton(e, "reset")}
                >
                  <SyncIcon style={{ fontSize: "18px" }} /> Reset
                </Button>
              ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      boxShadow: "none",
                      padding: "2px",
                      margin: ".5em",
                      fontSize: "11px",
                    }}
                    // style={
                    //   !props.disableEdit
                    //     ? {
                    //         color: "#fff",
                    //         background: "#3982ea",
                    //       }
                    //     : { color: "#3982ea", fontSize: "11px" }
                    // }
                    onClick={(e) => props.handleClickButton(e, "reset")}
                  >
                    <SyncIcon style={{ fontSize: "18px" }} /> Reset
                  </Button>
                )}
            </div>
          </React.Fragment>
        )}
    </div>
  );
};
class CreateMBAReport extends React.Component {
  static contextType = StateContext;
  state = {
    disableAdd: false,
    disableEdit: true,
    list: [],
    customer: "",
    workOrder: "",
    title: "",
    planner: "",
    mbaNo: "",
    specialInstruction: "",
    checkedBy: "",
    approvedBy: "",
    preparedBy: "",
    defaultGST: 0,
    billingDate: "",
    currentLocation: "",
    gstType: "",
    gstTypeID: "",
    isTicked: false,
  };

  componentDidMount() {
    this.props.handleSettingHeader("Work Order");
    this.props.setSelectedList("genericSystemOpen", "workOrder");
    this.props.handleDrawerClose();
    this.props.handleDrawerClose();

    getCustomerDropdown().then((r) => {
      this.getAllCustomer(r.data);
    });

    const [{ store }, dispatch] = this.context;
    let wkOrderId = "";
    if (process.browser)
      wkOrderId = this.props.history.location.search.split("=")[1];

    let newList = clone(this.state.list);

    {
      this.props.history.location.state.mbaReportMode === "add" &&
        GetMBAByWOID(wkOrderId).then((r) => {
          this.setState({
            customer: r.custmerName,
            workOrder: r.workOrderNumber,
            title: r.orderTitle,
            planner: r.plannerName ? r.plannerName : "",
            mbaNo: r.mbaNo ? r.mbaNo : "",
            defaultGST: r.gsTpercentage,
            billingDate: r.billingDate,
            approvedBy: r.approvedBy,
            checkedBy: r.checkedBy,
            preparedBy: r.preparedBy
          });
          r.getMBADetails &&
            r.getMBADetails.length > 0 &&
            r.getMBADetails.map((l) => {
              newList.push({
                processDescription: l.processDescription,
                quantity: l.quantity,
                rate: addZeros(l.rate.toString(), 4),
                billingAmount: l.billingAmount,
                gstType: l.gstType,
                accCode: l.accCode,
                pic: l.pic,
                rc: l.rc,

                processId: l.processID,
                mbaDetailID: l.mbaDetailID,
                jobGroupID: l.jobGroupID,
              });
            });
          this.setState({ list: newList, disableEdit: false });
        });
    }
    if (
      this.props.history.location.state.mbaReportMode === "view" ||
      this.props.history.location.state.mbaReportMode === "edit"
    ) {
      let mbaHeaderID = this.props.history.location.search.split("=")[1];

      GetMBAByMBAHeaderID(mbaHeaderID).then((r) => {
        this.setState({
          customer: r.custmerName,
          workOrder: r.workOrderNumber,
          title: r.orderTitle,
          planner: r.plannerName ? r.plannerName : "",
          mbaNo: r.mbaCode ? r.mbaCode : "",
          defaultGST: r.gsTpercentage,
          billingDate: r.billingDate,
          specialInstruction: r.specialInstructions,
          checkedBy: r.checkedBy,
          approvedBy: r.approvedBy,
          preparedBy: r.preparedBy
        });
        r.getMBADetails &&
          r.getMBADetails.length > 0 &&
          r.getMBADetails.map((l) => {
            newList.push({
              processDescription: l.processDescription,
              quantity: l.quantity,
              rate: addZeros(l.rate.toString(), 4),
              billingAmount: l.billingAmount,
              gstType: l.gstType,
              accCode: l.accCode,
              pic: l.pic,
              rc: l.rc,
              processId: l.processID,
              mbaDetailID: l.mbaDetailID,
              jobGroupID: l.jobGroupID,
            });
          });

        this.setState({
          list: newList,
          disableEdit: false,
          subordinate: newList,
        });
      });
    }
  }

  getAllCustomer = (list) => {
    let customerList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        customerList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.setState({ customerList });
  };
  handleClickButton = (e, name) => {
    let newList = clone(this.state.list);
    let newObj = {
      processDescription: "",
      quantity: 0,
      rate: 0.0,
      billingAmount: 0,
      // gstType:"",

      gstType: "Exclusive",
      gstTypeID: 0,
      accCode: "30200050",
      pic: "80230002",
      rc: "PIVAP00",
      editClicked: true,
      manualEntry: true,
      processId: "",
    };
    newList.push(newObj);
    if (name === "add") {
      this.setState({
        list: newList,
        editableList: newList,
        // disableAdd: true,
        disableEdit: false,
        OrderGroupID: 0,
        isTicked: true,
      });
    } else {
      this.setState({
        list: [],
        editableList: [],
        disableAdd: false,
        disableEdit: true,
        fileName: "",
        OrderGroupID: 0,
        isTicked: false,
      });
    }
  };
  onEditClicked = async (e, i, editClicked) => {
    let newEditableList = clone(this.state.list);
    newEditableList[i].editClicked = !newEditableList[i].editClicked;
    this.setState({
      list: newEditableList,
    });
    if (newEditableList[i].editClicked) {
      this.setState({
        isTicked: true,
      });
    } else {
      this.setState({
        isTicked: false,
      });
    }
  };
  onDeleteClicked = (e, i) => {
    let newList = clone(this.state.list);
    newList[i].editClicked = false;
    newList.splice(i, 1);
    this.setState({
      list: newList,
      isTicked: false,
    });
  };

  onDeleteApiClicked = (e, i, mbaDetailID) => {
    let newList = clone(this.state.list);
    let mbaHeaderID = this.props.history.location.search.split("=")[1];

    deleteMBAByID(mbaDetailID).then((r) => {
      newList[i].editClicked = false;
      newList.splice(i, 1);
      this.setState({
        list: newList,
        disableEdit: false,
        subordinate: newList,
      });
      if (r) {
        this.handleSubmit();
      }
    });
  };

  onSPLInstructionChanged = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // if (this.props.history.location.state.mbaReportMode === "edit") {
    //   this.setState({ isTicked: true });
    // }
  };
  onExcelDataChanged = (e, name, i) => {
    e.persist();
    let newList = clone(this.state.list);
    newList[i][name] = e.target.value;
    if (name === "quantity")
      newList[i].billingAmount =
        Math.round(
          (e.target.value * this.checkDHlWeightDec(newList[i].rate) +
            Number.EPSILON) *
          100
        ) / 100;
    if (name === "rate") {
      let r = e.target.value;
      r = this.checkDHlWeightDec1(e.target.value.toString());

      newList[i].rate = r;
      newList[i].billingAmount =
        Math.round(
          (this.checkDHlWeightDec(newList[i].rate) * newList[i].quantity +
            Number.EPSILON) *
          100
        ) / 100;
    }

    this.setState({ list: newList });
  };

  checkOGSubmit = () => {
    let notValid = false;
    if (this.state.list.length === 0) notValid = true;
    else;
    {
      this.state.list.map((l) => {
        if (!l.manualEntry)
          if (
            l.processDescription === "" ||
            l.gstType === "" ||
            l.accCode === "" ||
            l.pic === "" ||
            l.rc === ""
          )
            notValid = false;
          else {
            notValid = true;
          }
      });

      return notValid;
    }
  };

  handleGstChange = async (event, index, values) => {
    let newList = [...this.state.list];
    let id = "";
    if (values) {
      gstType.map((val) => {
        if (values.label == val.label) {
          id = val.id;
          newList[index]["gstType"] = values.label;
          newList[index]["gstTypeID"] = id;
        }
      });
    } else {
      newList[index]["gstType"] = "";
      newList[index]["gstTypeID"] = "";
    }

    await this.setState({ list: newList });
  };

  _onAlphaKeyPress(event) {
    const re = /[a-zA-Z 0-9. ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onAllCharPress(event) {
    const re = /[a-z A-Z0-9=.;(){}\/*%#$@&,-_]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  checkDHlWeightDec1(value) {
    var re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  _onTextKeyPress(event) {
    const re = /[a-zA-Z ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleSubmit = (e) => {
    //alert(this.props.history.location.state.mbaReportMode);
    if (this.props.history.location.state.mbaReportMode === "add") {
      let newList = clone(this.state.list);
      let loggedInId = localStorage.getItem("loggedInId");
      let wkOrderId = this.props.history.location.state.wkOrderId;

      let createMBADetails = [];
      if (process.browser)
        wkOrderId = this.props.history.location.state.wkOrderId;

      let amountBeforeGST = 0;
      newList.map((l) => {
        amountBeforeGST = amountBeforeGST + l.billingAmount;
      });

      amountBeforeGST =
        Math.round((amountBeforeGST + Number.EPSILON) * 100) / 100;
      let gst = (+amountBeforeGST * this.state.defaultGST) / 100;
      gst = Math.round((gst + Number.EPSILON) * 100) / 100;
      let amountWithGST = amountBeforeGST + gst;
      let reqBody = {};
      reqBody.workOrderID = wkOrderId;
      reqBody.amountBeforeGST = amountBeforeGST;
      reqBody.amountWithGST = amountWithGST;
      // reqBody.gst = gst;
      reqBody.createdBy = loggedInId;
      reqBody.specialInstructions = this.state.specialInstruction;
      reqBody.checkedBy = this.state.checkedBy;
      reqBody.approvedBy = this.state.approvedBy;
      reqBody.preparedBy = this.state.preparedBy;
      // reqBody.billingDate = this.state.billingDate;
      // reqBody.billingDate = new Date();
      newList.map((l) => {
        createMBADetails.push({
          processDescription: l.processDescription,
          quantity: l.quantity,
          rate: addZeros(l.rate.toString(), 4),
          billingAmount: l.billingAmount,
          gstType: (l.gstType == "Inclusive" || l.gstType == true) ? true : false,
          accCode: l.accCode,
          pic: l.pic,
          rc: l.rc,
          processId: l.processId,
          createdBy: loggedInId,
          mbaDetailID: l.mbaDetailID,
          jobGroupID: l.jobGroupID,
        });
      });
      reqBody.createMBADetails = createMBADetails;
      CreateMBA(reqBody, this.props.history, wkOrderId).then((r) => { });
    } else if (this.props.history.location.state.mbaReportMode === "edit") {
      let mbaHeaderID = this.props.history.location.search.split("=")[1];

      let newList = clone(this.state.list);
      let loggedInId = localStorage.getItem("loggedInId");
      let mbaHeaderId = "";
      let createMBADetails = [];
      if (process.browser) mbaHeaderId = window.location.pathname.split("/")[2];
      let amountBeforeGST = 0;
      newList.map((l) => {
        amountBeforeGST = amountBeforeGST + l.billingAmount;
      });
      amountBeforeGST =
        Math.round((amountBeforeGST + Number.EPSILON) * 100) / 100;
      let gst = (+amountBeforeGST * this.state.defaultGST) / 100;
      gst = Math.round((gst + Number.EPSILON) * 100) / 100;
      let amountWithGST = amountBeforeGST + gst;
      let wkOrderId = this.props.history.location.state.wkOrderId;
      let reqBody = {};
      // reqBody.mbaHeaderID = +mbaHeaderId;
      reqBody.workOrderID = wkOrderId;
      reqBody.amountBeforeGST = amountBeforeGST;
      reqBody.amountWithGST = amountWithGST;
      reqBody.gst = gst;
      reqBody.modifiedBy = loggedInId;
      reqBody.specialInstructions = this.state.specialInstruction;
      reqBody.checkedBy = this.state.checkedBy;
      reqBody.approvedBy = this.state.approvedBy;
      reqBody.preparedBy = this.state.preparedBy;
      // reqBody.billingDate = this.state.billingDate;
      // reqBody.billingDate = new Date();
      newList.map((l) => {
        createMBADetails.push({
          processDescription: l.processDescription,
          quantity: l.quantity,
          rate: addZeros(l.rate.toString(), 4),
          gstType: (l.gstType == "Inclusive" || l.gstType == true) ? true : false,
          accCode: l.accCode,
          pic: l.pic,
          rc: l.rc,
          billingAmount: l.billingAmount,
          modifiedBy: loggedInId,
          processId: l.processId ? l.processId : null,
          mbaDetailID: l.mbaDetailID ? l.mbaDetailID : 0,
          jobGroupID: l.jobGroupID ? l.jobGroupID : 0,
          createdBy: loggedInId,
        });
      });
      reqBody.updateMBADetails = createMBADetails;
      UpdateMBA(
        reqBody,
        this.props.history,
        mbaHeaderID,
        wkOrderId
      ).then((r) => { });
    }
  };

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, 4);
    }
    return value;
  }

  onBlur = (event, name, index) => {
    let newList = clone(this.state.list);
    let values = event.target.value;
    values = this.addZeroes(values.toString(), 4);
    newList[index][name] = values;
    this.setState({ list: newList });
  };

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    return value;
  }

  render() {
    let { customer, workOrder, title, planner, mbaNo, list } = this.state;
    let { classes } = this.props;
    let mbid = this.props.history.location.state.wkOrderId;

    // console.log("edit....")
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">
            {this.props.history.location.state.owcReportMode === "view"
              ? "View Manual Billing Advice"
              : this.props.history.location.state.owcReportMode === "edit"
                ? "Edit Manual Billing Advice"
                : "Manual Billing Advice"}{" "}
          </div>
          <Divider />
          <div className="view-page-container" id="printable">
            <div style={{ display: "flex", marginTop: "1em" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  //   marginTop: "1em",
                  background: "#f2f7fd",
                  padding: ".5em",
                  color: "#3982ea",
                  borderRadius: "4px",
                  width: "100%",
                  fontSize: "15px",
                  fontWeight: "400",
                }}
              >
                <div>
                  <span>Customer : </span>
                  <span style={{ fontWeight: "500" }}>{customer}</span>
                </div>
                <div>
                  <span>Work Order : </span>
                  <span style={{ fontWeight: "500" }}>{workOrder}</span>
                </div>
                <div>
                  <span>Planner : </span>
                  <span style={{ fontWeight: "500" }}>{planner}</span>
                </div>
                <div>
                  <span>Order Title : </span>
                  <span style={{ fontWeight: "500" }}>{title}</span>
                </div>
                {mbaNo && (
                  <div>
                    <span>MBA No : </span>
                    <span style={{ fontWeight: "500" }}>{mbaNo}</span>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div>
                <EnhancedTableToolbar
                  //numSelected={selected.length}
                  handleClickButton={this.handleClickButton}
                  disableAdd={this.state.disableAdd}
                  disableEdit={this.state.disableEdit}
                  currentLocation={
                    this.props.history.location.state.mbaReportMode === "view"
                      ? "view"
                      : this.props.history.location.state.mbaReportMode ===
                        "edit"
                        ? "edit"
                        : this.props.history.location.state.mbaReportMode ===
                          "add"
                          ? "add"
                          : null
                  }
                />
              </div>
              <div
                style={{
                  // width: "900px",
                  maxHeight: "400px",
                  margin: "0  0 1em",
                  borderLeft: "1px Solid #eaeaea",
                  borderRight: "1px solid #eaeaea",
                }}
              >
                <div style={{ maxHeight: 350, overflow: "auto" }}>
                  {/* //overflow: "auto" */}
                  <Table
                    aria-labelledby="tableTitle"
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                  // style={{ width: "1800px", overflow: "auto" }}
                  >
                    {" "}
                    <EnhancedTableHead
                      currentLocation={
                        this.props.history.location.state.mbaReportMode ===
                          "view"
                          ? "view"
                          : this.props.history.location.state.mbaReportMode ===
                            "edit"
                            ? "edit"
                            : this.props.history.location.state.mbaReportMode ===
                              "add"
                              ? "add"
                              : null
                      }
                    />
                    <TableBody style={{ overflow: "hidden" }}>
                      {list.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return !this.state.loading ? (
                          <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                              style={{ width: "30%", padding: "6px" }}
                            >
                              {row.editClicked &&
                                !row.fromDB &&
                                row.manualEntry === true ? (
                                  <Input
                                    //required
                                    autoFocus
                                    onKeyPress={this._onAlphaKeyPress}
                                    defaultValue={row.processDescription}
                                    inputProps={{ "aria-label": "description" }}
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "processDescription",
                                        index
                                      );
                                    }}
                                    value={row.processDescription}
                                  />
                                ) : (
                                  row.processDescription
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="right"
                              className="table-content-right-cell"
                              style={{ width: "10%", padding: "6px" }}
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked && !row.fromDB ? (
                                  <Input
                                    onKeyPress={this._onNumberKeyPress}
                                    defaultValue={row.quantity}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "quantity",
                                        index
                                      );
                                    }}
                                    value={row.quantity}
                                  />
                                ) : (
                                    row.quantity
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              required
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="right"
                              className="table-content-right-cell"
                              style={{ width: "10%", padding: "6px" }}
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked && !row.fromDB ? (
                                  <Input
                                    onKeyPress={this._onAlphaKeyPress}
                                    defaultValue={row.rate}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(e, "rate", index);
                                    }}
                                    value={row.rate}
                                    onBlur={(e) =>
                                      this.onBlur(e, "rate", index)
                                    }
                                  />
                                ) : (
                                    row.rate
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="right"
                              className="table-content-right-cell"
                              style={{ width: "10%", padding: "6px" }}
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked &&
                                  !row.fromDB &&
                                  row.manualEntry === true ? (
                                    <Input
                                      disabled
                                      onKeyPress={this._onNumberKeyPress}
                                      defaultValue={row.billingAmount}
                                      inputProps={{
                                        "aria-label": "description",
                                      }}
                                      onChange={(e) => {
                                        this.onExcelDataChanged(
                                          e,
                                          "billingAmount",
                                          index
                                        );
                                      }}
                                      value={row.billingAmount}
                                    />
                                  ) : (
                                    row.billingAmount
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                              style={{
                                width: "12%",
                                padding: "6px",
                                paddingTop: "0px",
                              }}
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked &&
                                  !row.fromDB &&
                                  row.manualEntry === true ? (
                                    <Autocomplete
                                      autoComplete
                                      includeInputInList
                                      options={gstType}
                                      onChange={(e, values) =>
                                        this.handleGstChange(e, index, values)
                                      }
                                      value={this.state.gstType}
                                      getOptionLabel={(option) =>
                                        option.label || row.gstType
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          margin="normal"
                                          style={{ width: "100%" }}
                                        />
                                      )}
                                    />
                                  ) : row.manualEntry === true ? (
                                    row.gstType
                                  ) : row.gstType === true ? (
                                    "Inclusive"
                                  ) : (
                                        "Exclusive"
                                      )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                              style={{ width: "10%", padding: "6px" }}
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked &&
                                  !row.fromDB &&
                                  row.manualEntry === true ? (
                                    <Input
                                      onKeyPress={this._onAlphaKeyPress}
                                      defaultValue={row.accCode}
                                      inputProps={{
                                        "aria-label": "description",
                                      }}
                                      onChange={(e) => {
                                        this.onExcelDataChanged(
                                          e,
                                          "accCode",
                                          index
                                        );
                                      }}
                                      value={row.accCode}
                                    />
                                  ) : (
                                    row.accCode
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                              style={{ width: "10%", padding: "6px" }}
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked &&
                                  !row.fromDB &&
                                  row.manualEntry === true ? (
                                    <Input
                                      onKeyPress={this._onAlphaKeyPress}
                                      defaultValue={row.pic}
                                      inputProps={{
                                        "aria-label": "description",
                                      }}
                                      onChange={(e) => {
                                        this.onExcelDataChanged(e, "pic", index);
                                      }}
                                      value={row.pic}
                                    />
                                  ) : (
                                    row.pic
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                              style={{ width: "13%", padding: "6px" }}
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked &&
                                  !row.fromDB &&
                                  row.manualEntry === true ? (
                                    <Input
                                      onKeyPress={this._onAlphaKeyPress}
                                      defaultValue={row.rc}
                                      inputProps={{
                                        "aria-label": "description",
                                      }}
                                      onChange={(e) => {
                                        this.onExcelDataChanged(e, "rc", index);
                                      }}
                                      value={row.rc}
                                    />
                                  ) : (
                                    row.rc
                                  )}
                              </Typography>
                            </TableCell>
                            {this.props.history.location.state.mbaReportMode ===
                              "view" ? null : (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ width: "5%", padding: "6px" }}
                                >
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {!row.fromDB && (
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: "#6c6b6b",
                                        }}
                                        onClick={(e) =>
                                          this.onEditClicked(
                                            e,
                                            index,
                                            row.editClicked
                                          )
                                        }
                                      >
                                        {!row.editClicked ? (
                                          <EditIcon
                                            style={{
                                              color: "#6c6b6b",
                                            }}
                                          />
                                        ) : row.processDescription !== "" &&
                                          row.gstType !== "" &&
                                          row.accCode !== "" &&
                                          row.pic !== "" &&
                                          row.rc !== "" ? (
                                              <CheckIcon
                                                style={{
                                                  color: "#6c6b6b",
                                                }}
                                              />
                                            ) : null}
                                      </span>
                                    )}

                                    {!row.mbaDetailID ? (
                                      <DeleteIcon
                                        style={{
                                          cursor: "pointer",
                                          color: "#6c6b6b",
                                        }}
                                        onClick={(e) =>
                                          this.onDeleteClicked(e, index)
                                        }
                                      />
                                    ) : (
                                        <DeleteIcon
                                          style={{
                                            cursor: "pointer",
                                            color: "#6c6b6b",
                                          }}
                                          onClick={(e) =>
                                            this.onDeleteApiClicked(
                                              e,
                                              index,
                                              row.mbaDetailID
                                            )
                                          }
                                        />
                                      )}
                                  </Typography>
                                </TableCell>
                              )}
                          </TableRow>
                        ) : (
                            <TableRow>
                              <TableCell colSpan="8">
                                <LinearProgress />
                              </TableCell>
                            </TableRow>
                          );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
            <div style={{ marginBottom: "1em" }}>
              {this.state.list.length > 0 && (
                <div style={{ display: "flex" }}>
                  <div style={{ paddingTop: ".7em", width: "270px" }}>
                    {" "}
                    Approved By :
                  </div>
                  {this.props.history.location.state.mbaReportMode ===
                    "view" ? (
                      <div style={{ paddingLeft: "10px", paddingTop: ".8em", fontWeight: "400", color: "rgb(57, 130, 234)", maxWidth: "300px", minWidth: "300px" }}>
                        {this.state.approvedBy}
                      </div>
                    ) : (
                      <div
                        colSpan="2"
                        style={{
                          //borderBottom: "1px solid #3982ea",
                          //  background: "#f2f7fd"
                          marginLeft: "1em",
                        }}
                      >
                        <TextField
                          onKeyPress={this._onAllCharPress}
                          //defaultValue={row.routing}
                          inputProps={{
                            "aria-label": "description",
                          }}
                          name="approvedBy"
                          value={this.state.approvedBy}
                          onChange={(e) => this.onSPLInstructionChanged(e)}
                          // value={row.routing}
                          style={{ width: "223px" }}
                          rowsMax={4}
                          multiline="true"
                        />
                      </div>
                    )}
                  {/* new fields for MBA  */}
                  <div style={{ paddingTop: ".7em", marginLeft: "2em", width: "100px" }}>
                    {" "}
                    Checked By :
                  </div>
                  {this.props.history.location.state.mbaReportMode ===
                    "view" ? (
                      <div style={{ paddingLeft: "10px", paddingTop: ".8em", fontWeight: "400", color: "rgb(57, 130, 234)", maxWidth: "300px", minWidth: "300px" }}>
                        {this.state.checkedBy}
                      </div>
                    ) : (
                      <div
                        colSpan="2"
                        style={{
                          //borderBottom: "1px solid #3982ea",
                          //  background: "#f2f7fd"
                          marginLeft: "1em",
                        }}
                      >
                        <TextField
                          onKeyPress={this._onAllCharPress}
                          //defaultValue={row.routing}
                          inputProps={{
                            "aria-label": "description",
                          }}
                          name="checkedBy"
                          value={this.state.checkedBy}
                          onChange={(e) => this.onSPLInstructionChanged(e)}
                          // value={row.routing}
                          style={{ width: "223px" }}
                          rowsMax={4}
                          multiline="true"
                        />
                      </div>
                    )}

                </div>
              )}
              {/* new fileds... */}
              {this.state.list.length > 0 && (
                <div style={{ display: "flex", marginTop: ".5em" }}>
                  <div style={{ paddingTop: ".7em", width: "270px" }}>
                    {" "}
                    Special instructions to Finance, if any :{" "}
                  </div>
                  {this.props.history.location.state.mbaReportMode ===
                    "view" ? (
                      <div style={{ paddingLeft: "10px", paddingTop: ".8em", fontWeight: "400", color: "rgb(57, 130, 234)", maxWidth: "300px", minWidth: "300px" }}>
                        {this.state.specialInstruction}
                      </div>
                    ) : (
                      <div
                        colSpan="2"
                        style={{
                          //borderBottom: "1px solid #3982ea",
                          //  background: "#f2f7fd"
                          marginLeft: "1em",

                        }}
                      >
                        <TextField
                          onKeyPress={this._onAllCharPress}
                          //defaultValue={row.routing}
                          inputProps={{
                            "aria-label": "description",
                          }}
                          name="specialInstruction"
                          value={this.state.specialInstruction}
                          onChange={(e) => this.onSPLInstructionChanged(e)}
                          // value={row.routing}
                          style={{ width: "223px" }}
                          rowsMax={4}
                          multiline="true"
                        />
                      </div>
                    )}
                  {/* new fields for MBA  */}
                  <div style={{ paddingTop: ".7em", marginLeft: "2em", width: "100px" }}>
                    {" "}
                    Prepared By :
                  </div>
                  {this.props.history.location.state.mbaReportMode ===
                    "view" ? (
                      <div style={{ paddingLeft: "10px", paddingTop: ".8em", fontWeight: "400", color: "rgb(57, 130, 234)", maxWidth: "300px", minWidth: "300px" }}>
                        {this.state.preparedBy}
                      </div>
                    ) : (
                      <div
                        colSpan="2"
                        style={{
                          //borderBottom: "1px solid #3982ea",
                          //  background: "#f2f7fd"
                          marginLeft: "1em",
                        }}
                      >
                        <TextField
                          onKeyPress={this._onAllCharPress}
                          //defaultValue={row.routing}
                          inputProps={{
                            "aria-label": "description",
                          }}
                          name="preparedBy"
                          value={this.state.preparedBy}
                          onChange={(e) => this.onSPLInstructionChanged(e)}
                          // value={row.routing}
                          style={{ width: "223px" }}
                          rowsMax={4}
                          multiline="true"
                        />
                      </div>
                    )}

                </div>
              )}
            </div>
            <Divider />
          </div>

          <div className="button-wrapper" style={{ paddingTop: "0px" }}>
            <div style={{ marginLeft: "2em" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) =>
                  this.props.history.push(`/view_mbaReport/${mbid}`)
                }
              >
                CANCEL
              </Button>
            </div>
            {this.props.history.location.state.mbaReportMode ===
              "view" ? null : (
                <div
                  style={{
                    marginLeft: "1em",
                  }}
                >
                  {this.props.history.location.state.mbaReportMode == "add" ? (
                    <Button
                      // autoFocus
                      disabled={
                        this.state.isTicked || this.state.list.length === 0
                          ? true
                          : false
                      }
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.handleSubmit(e)}
                    >
                      Submit
                    </Button>
                  ) : (
                      <Button
                        // autoFocus
                        disabled={
                          this.state.isTicked || this.state.list.length === 0
                            ? true
                            : false
                        }
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={(e) => this.handleSubmit(e)}
                      >
                        Submit
                      </Button>
                    )}
                </div>
              )}
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
//export default withRouter();
export default withRouter(withStyles(addDispatchStyles)(CreateMBAReport));
