import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Paper, Divider } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { addDispatchStyles } from "../../../shared/styles";
import { StateContext } from "../../../shared/globalState";
import { getCodeType } from "../../masters/masterActions";
import { printDetails } from "../../../shared/tableCommonFunctions";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { selectedCodeTypeId } = props;
  let acnArr = props.roles.filter(u => u.menuName === "Code Type");
  let acnObj = acnArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%"
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_codeType/${selectedCodeTypeId}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={e => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewCodeType extends React.Component {
  static contextType = StateContext;
  state = {
    codeType: "",
    codeTypeDescription: "",
    canAddNew: "",
    canEditStartDate: "",
    canEditValue: "",
    serviceSpecific: "",
    codevalueDataType: "",
    isActive: ""
  };

  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Code Type");
    this.props.setSelectedList("masterDataOpen", "codeType");

    let codeTypeId =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];

    getCodeType(codeTypeId).then(r => {
      dispatch({
        type: "getCodeType",
        newStore: r
      });

      this.setState({ codeType: r });
    });
  }
  render() {
    let id =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            id={id}
            selectedCodeTypeId={id}
            roles={this.props.roles}
          />
          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">Code Type Information</div>
            {/* <Divider /> */}
            <div>
              <div>
                <div className="view-page-contents-container">
                  <div className="view-page-contents-sub-container">
                    <div className="view-page-label">Code Type</div>
                    <div className="view-page-value">
                      {this.state && this.state.codeType.codeType}
                    </div>
                  </div>
                  <div className="view-page-contents-sub-container">
                    <div className="view-page-label">Code Type Description</div>
                    <div className="view-page-value">
                      {this.state && this.state.codeType.codeTypeDescription}
                    </div>
                  </div>
                  <div className="view-page-contents-sub-container">
                    <div className="view-page-label">DataType</div>
                    <div className="view-page-value">
                      {this.state && this.state.codeType.codevalueDataType}
                    </div>
                  </div>
                </div>
                <div className="view-page-contents-container">
                  <div className="view-page-contents-sub-container">
                    <div className="view-page-label">Add New</div>
                    <div className="view-page-value">
                      {this.state && this.state.codeType.canAddNew === true
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                  <div className="view-page-contents-sub-container">
                    <div className="view-page-label">Edit Value</div>
                    <div className="view-page-value">
                      {this.state && this.state.codeType.canEditValue === true
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                  <div className="view-page-contents-sub-container">
                    <div className="view-page-label">Active</div>
                    <div className="view-page-value">
                      {this.state && this.state.codeType.isActive === true
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                </div>
              </div>
              <Divider style={{ marginTop: "25px" }} />
              <div className="button-wrapper">
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    //disableElevation
                    style={{ boxShadow: "none" }}
                    onClick={e => this.props.history.push("/codeType")}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addDispatchStyles)(ViewCodeType));
