import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { Autocomplete } from "@material-ui/lab";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { resourceValidation } from "../../../shared/resource";
import {
  getCustomerList,
  getUOMIMList,
  getStockCategory,
  getCustomerDDList,
} from "../../masters/masterActions";
import { StateContext } from "../../../shared/globalState";
import { postItemMaster } from "../genericActions";
import { addStyles } from "../../../shared/styles";
const checkstockcode = new Validator("checkstockcode");
const checkrate = new Validator("checkdecimalpoint");
const Reorderqty = new Validator("Reorderqty");
const Alphanumeric = new Validator("Alphanumeric");
const checklettershopname1 = new Validator("checklettershopname1");
const generalValidator = new Validator("general");

class AddItemMaster extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      ItemMaster: {
        Customer: "",
        CustomerId: "",
        StockCode: "",
        StockName: "",
        UOM: "",
        UOMId: "",
        StockCategory: "",
        StockCategoryId: "",
        ReOrderlevelQty: 0,
        Price: "0.00",
        Cost: "0.00",
        IsConsummable: false,
        Remarks: "",
        Weight: "0.000",
        Dimension: "",
        createdBy: localStorage.loggedInId,
        IsActive: true,
        IsVendor: false,
      },
      errors: {},
      CustomerValid: false,
      StockCodeValid: false,
      StockNameValid: false,
      UOMIdValid: false,
      StockCategoryValid: false,
      ReOrderlevelQtyValid: true,
      PriceValid: false,
      CostValid: false,
      RemarksValid: false,
      WeightValid: false,
      DimensionValid: false,
      customersList: [],
      UOMList: [],
      StockCategoryList: [],
      formValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.handleSettingHeader("Item Master");
    getCustomerDDList().then((r) => {
      //console.log(r);
      this.setState({
        customersList:
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });
    getUOMIMList().then((r) => {
      this.setState({
        UOMList:
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });

    getStockCategory().then((r) => {
      r.data.forEach((element) => {
        element.text =
          element.text.charAt(0).toUpperCase() + element.text.slice(1);
      });

      this.setState({
        StockCategoryList:
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });
  }

  onBlur = (event) => {
    const { ItemMaster } = this.state;
    let dhlValues = event.target.value;
    dhlValues = this.addZeroes(dhlValues.toString(), 2);
    ItemMaster[event.target.name] = dhlValues;
    this.setState({ ItemMaster });
  };
  onBlur1 = (event) => {
    const { ItemMaster } = this.state;
    let dhlValues = event.target.value;
    dhlValues = this.addZeroes(dhlValues.toString(), 3);
    ItemMaster[event.target.name] = dhlValues;
    this.setState({ ItemMaster });
  };
  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  checkDHlWeightDec1(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  handleChange = (e) => {
    const { ItemMaster } = this.state;
    // console.lo;
    if (e.target.name === "IsConsummable") {
      ItemMaster[e.target.name] = e.target.checked;
    } else {
      ItemMaster[e.target.name] = e.target.value;
      this.validateField(e.target.name, e.target.value);
    }

    this.setState({ ItemMaster });
  };
  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }
  handleweight = (event) => {
    const { ItemMaster } = this.state;
    let weightValues = event.target.value;
    weightValues = this.checkDHlWeightDec(event.target.value.toString());

    ItemMaster[event.target.name] = weightValues;
    this.validateField(event.target.name, weightValues);
    this.setState({ ItemMaster });
  };
  handleweight1 = (event) => {
    const { ItemMaster } = this.state;
    let weightValues = event.target.value;
    weightValues = this.checkDHlWeightDec1(event.target.value.toString());

    ItemMaster[event.target.name] = weightValues;
    this.validateField(event.target.name, weightValues);
    this.setState({ ItemMaster });
  };
  handleCustChange = (event, values) => {
    const { ItemMaster } = this.state;
    //this.setState({ItemMaster, customersList: []})
    let CustomerId = values && values.id;
    ItemMaster["Customer"] = values;
    ItemMaster["CustomerId"] = CustomerId;
    ItemMaster["IsVendor"] = values && values.isVendor;
    if (ItemMaster["IsVendor"] === true) {
      ItemMaster["IsConsummable"] = true;
    } else {
      ItemMaster["IsConsummable"] = false;
    }
    this.validateField("Customer", CustomerId);
    this.setState({ ItemMaster });
    // this.validateForm();
  };

  handleUOMChange = (event, values) => {
    const { ItemMaster } = this.state;
    if (values) {
      let UOMId = values && values.id;
      ItemMaster["UOM"] = values;
      ItemMaster["UOMId"] = UOMId;
      this.validateField("UOM", UOMId);
    } else {
      ItemMaster["UOM"] = "";
      ItemMaster["UOMId"] = "";
      this.validateField("UOM", "");
    }

    this.setState({ ItemMaster });
    // this.validateForm();
  };

  handleStockChange = (event, values) => {
    const { ItemMaster } = this.state;
    let StockCategoryId = values && values.id;
    ItemMaster["StockCategory"] = values;
    ItemMaster["StockCategoryId"] = StockCategoryId;
    this.validateField("StockCategoryId", StockCategoryId);
    this.setState({ ItemMaster });
    //this.validateForm();
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let CustomerValid = this.state.CustomerValid;
    let StockCodeValid = this.state.StockCodeValid;
    let StockNameValid = this.state.StockNameValid;
    let UOMIdValid = this.state.UOMIdValid;

    let StockCategoryValid = this.state.StockCategoryValid;
    let ReOrderlevelQtyValid = this.state.ReOrderlevelQtyValid;
    let PriceValid = this.state.PriceValid;
    let CostValid = this.state.CostValid;
    let RemarksValid = this.state.RemarksValid;
    let WeightValid = this.state.WeightValid;
    let DimensionValid = this.state.DimensionValid;

    switch (fieldName) {
      case "StockCode":
        if (value !== "") {
          StockCodeValid = checkstockcode(value);
          fieldValidationErrors.StockCode = StockCodeValid
            ? ""
            : resourceValidation.onlyAlphanumeric;
        } else {
          StockCodeValid = false;
          fieldValidationErrors.StockCode = "";
        }
        break;
      case "StockName":
        if (value !== "") {
          StockNameValid = checklettershopname1(value);
          fieldValidationErrors.StockName = StockNameValid
            ? ""
            : resourceValidation.StockName;
        } else {
          StockNameValid = false;
          fieldValidationErrors.StockName = "";
        }

        break;
      case "ReOrderlevelQty":
        if (value !== "") {
          ReOrderlevelQtyValid = Reorderqty(value);

          fieldValidationErrors.ReOrderlevelQty = ReOrderlevelQtyValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          ReOrderlevelQtyValid = false;
          fieldValidationErrors.ReOrderlevelQty = "";
        }

        break;
      case "Price":
        if (value !== "" && value !== 0) {
          // if (value !== "") {
          PriceValid = checkrate(value);
          fieldValidationErrors.Price = PriceValid
            ? ""
            : resourceValidation.price;
        } else {
          PriceValid = false;
          fieldValidationErrors.Price = "";
        }

        break;
      case "Cost":
        if (value !== "") {
          CostValid = checkrate(value);
          fieldValidationErrors.Cost = CostValid
            ? ""
            : resourceValidation.decimalpointby2;
        } else {
          CostValid = false;
          fieldValidationErrors.Cost = "";
        }
        break;
      case "Weight":
        if (value !== "") {
          WeightValid = checkrate(value);
          fieldValidationErrors.Weight = WeightValid
            ? ""
            : resourceValidation.itemweight;
        } else {
          WeightValid = false;
          fieldValidationErrors.Weight = "";
        }
        break;
      case "Dimension":
        DimensionValid = generalValidator(value);
        fieldValidationErrors.Dimension = DimensionValid
          ? ""
          : // : resourceValidation.onlyAlphabet;
          "Please enter valid dimension";
        break;
      case "Customer":
        if (value !== "") {
          CustomerValid = true;
          fieldValidationErrors.CustomerId = CustomerValid ? "" : "";
        } else {
          CustomerValid = false;
          fieldValidationErrors.CustomerId = CustomerValid
            ? ""
            : "Please select customer";
        }
        break;
      case "UOM":
        if (value !== "") {
          UOMIdValid = true;
          fieldValidationErrors.UOM = UOMIdValid ? "" : "";
        } else {
          UOMIdValid = false;
          fieldValidationErrors.UOM = UOMIdValid ? "" : "Please select UOM";
        }
        break;
      case "StockCategoryId":
        if (value !== "") {
          StockCategoryValid = true;
          fieldValidationErrors.StockCategory = StockCategoryValid ? "" : "";
        } else {
          StockCategoryValid = false;
          fieldValidationErrors.StockCategory = StockCategoryValid
            ? ""
            : "Please select stock category";
        }
        break;
      case "Remarks":
        //RemarksValid = checkremarks(value);
        RemarksValid = true;
        fieldValidationErrors.Remarks = RemarksValid
          ? ""
          : "Please enter remarks";

        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,

        CustomerValid: CustomerValid,
        StockCodeValid: StockCodeValid,
        StockNameValid: StockNameValid,
        UOMIdValid: UOMIdValid,

        StockCategoryValid: StockCategoryValid,
        ReOrderlevelQtyValid: ReOrderlevelQtyValid,
        PriceValid: PriceValid,
        CostValid: CostValid,
        RemarksValid: RemarksValid,
        WeightValid: WeightValid,
        DimensionValid: DimensionValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.CustomerValid &&
        this.state.StockCodeValid &&
        this.state.StockNameValid &&
        this.state.UOMIdValid &&
        this.state.StockCategoryValid &&
        this.state.ReOrderlevelQtyValid,
      // this.state.PriceValid &&
      // this.state.CostValid
      //&&
      // this.state.RemarksValid &&
      // this.state.WeightValid &&
      //  this.state.DimensionValid
    });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      Customer: "",
      StockCode: "",
      StockName: "",
      UOMId: "",
      StockCategoryId: "",
      ReOrderlevelQty: "",
      Price: "",
      Cost: "",
      IConsummable: "",
      Remarks: "",
      Weight: "",
      Dimension: "",
      createdBy: "",

      errors: {},
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      const { ItemMaster } = this.state;
      let stockCode = ItemMaster.StockCode.toUpperCase();
      let stockName = ItemMaster.StockName;
      let customerID = ItemMaster.CustomerId;
      let uomid = ItemMaster.UOMId;
      let stockCategoryID = ItemMaster.StockCategoryId;
      let reOrderLevelQty = ItemMaster.ReOrderlevelQty;
      let price = ItemMaster.Price;
      let cost = ItemMaster.Cost;
      let isConsumable = ItemMaster.IsConsummable;
      let remarks = ItemMaster.Remarks;
      let weightPerItem = ItemMaster.Weight;
      let dimension = ItemMaster.Dimension;
      let isActive = ItemMaster.IsActive;

      let createdBy = ItemMaster.createdBy;
      let reqBody = {
        stockCode,
        stockName,
        customerID,
        uomid,
        stockCategoryID,
        reOrderLevelQty,
        price,
        cost,
        isConsumable,
        remarks,
        weightPerItem,
        dimension,
        createdBy,
        isActive,
      };
      postItemMaster(reqBody, this.props.history, window.localStorage.oken);
    }
  };
  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  render() {
    let { classes } = this.props;
    let { ItemMaster, customersList, UOMList, StockCategoryList } = this.state;

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Item Master</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    // className={classes.comboBox}
                    id="Customer"
                    options={customersList}
                    className={classes.comboBox}
                    onChange={this.handleCustChange}
                    value={ItemMaster.Customer}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        autoFocus
                        {...params}
                        required
                        label="Customer"
                        name="Customer"
                        margin="normal"
                        id="Customer"
                        style={{ width: "100%" }}
                        error={this.state.errors.CustomerId}
                        helperText={
                          this.state.errors.CustomerId
                            ? this.state.errors.CustomerId
                            : ""
                        }
                      />
                    )}
                  />
                  <TextField
                    required
                    label="Stock Code"
                    name="StockCode"
                    inputProps={{
                      maxLength: 30,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={ItemMaster.StockCode.toUpperCase()}
                    onChange={this.handleChange}
                    error={this.state.errors.StockCode}
                    helperText={
                      this.state.errors.StockCode
                        ? this.state.errors.StockCode
                        : ""
                    }
                  //  onKeyPress={this._onKeyPressCode}
                  />
                  <TextField
                    required
                    label="Stock Name"
                    name="StockName"
                    inputProps={{
                      maxLength: 50,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={ItemMaster.StockName}
                    onChange={this.handleChange}
                    error={this.state.errors.StockName}
                    helperText={
                      this.state.errors.StockName
                        ? this.state.errors.StockName
                        : ""
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  {/* <div
                  style={{ display: "flex", marginleft: "5px", margin: "1em" }}
                ></div> */}
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="StockCategory"
                    options={StockCategoryList}
                    className={classes.comboBox}
                    onChange={this.handleStockChange}
                    value={ItemMaster.StockCategory}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Stock Category"
                        name="StockCategory"
                        id="StockCategory"
                        margin="normal"
                        style={{ width: "100%" }}
                        error={this.state.errors.StockCategoryId}
                        helperText={
                          this.state.errors.StockCategoryId
                            ? this.state.errors.StockCategoryId
                            : ""
                        }
                      />
                    )}
                  />

                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="UOM"
                    options={UOMList}
                    className={classes.comboBox}
                    onChange={this.handleUOMChange}
                    value={ItemMaster.UOM}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="UOM"
                        name="UOM"
                        id="UOM"
                        margin="normal"
                        style={{ width: "100%" }}
                        error={this.state.errors.UOMId}
                        helperText={
                          this.state.errors.UOMId ? this.state.errors.UOMId : ""
                        }
                      />
                    )}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Cost"
                    name="Cost"
                    margin="normal"
                    autoComplete="off"
                    // required
                    inputProps={{
                      maxLength: 10,
                    }}
                    className={classes.textField}
                    value={ItemMaster.Cost}
                    onChange={this.handleweight}
                    error={this.state.errors.Cost}
                    helperText={
                      this.state.errors.Cost ? this.state.errors.Cost : ""
                    }
                    onBlur={this.onBlur}
                    onKeyPress={this._onDecimalKeyPress}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$S</InputAdornment>
                      ),
                    }}
                  // onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    label="Price"
                    name="Price"
                    margin="normal"
                    autoComplete="off"
                    // required
                    inputProps={{
                      maxLength: 10,
                    }}
                    className={classes.textField}
                    value={ItemMaster.Price}
                    onChange={this.handleweight}
                    //  onBlur={this.onBlur}
                    error={this.state.errors.Price}
                    helperText={
                      this.state.errors.Price ? this.state.errors.Price : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$S</InputAdornment>
                      ),
                    }}
                    onKeyPress={this._onDecimalKeyPress}

                  // onKeyPress={this._onKeyPress}
                  />

                  <TextField
                    label="Weight Per Item"
                    name="Weight"
                    margin="normal"
                    autoComplete="off"
                    onBlur={this.onBlur1}
                    // required
                    inputProps={{
                      maxLength: 10,
                    }}
                    onKeyUp={this._onDecimalKeyPress1}
                    className={classes.textField}
                    value={ItemMaster.Weight}
                    onChange={this.handleweight1}
                    error={this.state.errors.Weight}
                    helperText={
                      this.state.errors.Weight ? this.state.errors.Weight : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Kg</InputAdornment>
                      ),
                    }}
                    onKeyPress={this._onDecimalKeyPress1}
                  // onKeyPress={this._onKeyPress}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Dimension"
                    name="Dimension"
                    margin="normal"
                    inputProps={{
                      maxLength: 100,
                    }}
                    autoComplete="off"
                    // required
                    className={classes.textField}
                    value={ItemMaster.Dimension}
                    onChange={this.handleChange}
                    error={this.state.errors.Dimension}
                    helperText={
                      this.state.errors.Dimension
                        ? resourceValidation.max100char
                        : ""
                    }
                  //onKeyPress={this._onKeyPress}
                  />

                  <TextField
                    required
                    //style={{paddingLeft:"2%"}}
                    label="Reorder Level Qty"
                    name="ReOrderlevelQty"
                    inputProps={{
                      maxLength: 5,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={ItemMaster.ReOrderlevelQty}
                    onChange={this.handleChange}
                    error={this.state.errors.ReOrderlevelQty}
                    helperText={
                      this.state.errors.ReOrderlevelQty
                        ? this.state.errors.ReOrderlevelQty
                        : ""
                    }
                    onKeyPress={this._onNumberKeyPress}
                  />
                  <FormControlLabel
                    className={classes.checkBox}
                    name="IsConsummable"
                    control={
                      <Checkbox
                        checked={ItemMaster.IsConsummable}
                        onChange={this.handleChange}
                        value={ItemMaster.IsConsummable}
                        color="primary"
                      />
                    }
                    label="Consumable"
                  />
                </div>
                <div>
                  <TextField
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    label="Remarks"
                    name="Remarks"
                    margin="normal"
                    autoComplete="off"
                    // required
                    inputProps={{
                      maxLength: 500,
                    }}
                    className={classes.textField}
                    value={ItemMaster.Remarks}
                    onChange={this.handleChange}
                    error={this.state.errors.Remarks}
                    helperText={
                      this.state.errors.Remarks ? this.state.errors.Remarks : ""
                    }

                  // onKeyPress={this._onKeyPress}
                  />
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/ItemMaster")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddItemMaster));
