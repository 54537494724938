import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import { getCptCode, updateCptCodeID } from "../masterActions";
import Validator from "../../../shared/validator";
import { Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { resourceValidation } from "../../../shared/resource";
import { addStyles } from "../../../shared/styles";
// Include new StateContext
import { StateContext } from "../../../shared/globalState";
const maxLenghtAlpNumHypDot50 = new Validator("maxLenghtAlpNumHypDot50");
const maxlengthValidator10WithNum = new Validator("maxlength10WithNum");
const maxlengthValidator20WithNum = new Validator("maxlength20WithNum");
const validateNotRequired100 = new Validator("maxlength100AllAllow");

class EditCptCode extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      cptCode: {
        office: "",
        cptOfficeType: "",
        objectCode: "",
        subCode: "",
        cptDescription: "",
        sapglAccount: "",
        category: "",
        remarks: "",
        isActive: true,
        modifiedBy: 1,
      },
      updatedValues: false,
      errors: {},
      active: false,
      officeValid: false,
      objectCodeValid: false,
      subCodeValid: false,
      cptDescriptionValid: false,
      sapglAccountValid: false,
      categoryValid: false,
      remarksValid: false,
      formValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let cptCodeId = this.props.match.params.cptcodeId;

    getCptCode(cptCodeId).then((r) => {
      dispatch({
        type: "getCptCode",
        newStore: r,
      });
      this.setState({ cptCode: r });
      if (r.isActive) this.setState({ active: r.isActive });
      this.validateField("office", r.office);
      this.validateField("objectCode", r.objectCode);
      this.validateField("subCode", r.subCode);
      this.validateField("cptDescription", r.cptDescription);
      this.validateField("sapglAccount", r.sapglAccount);
      this.validateField("category", r.category);
      this.validateField("remarks", r.remarks);
    });
  }

  handleChange = (event) => {
    const { cptCode } = this.state;
    this.setState({ updatedValues: true });

    if (event.target.name === "isActive") {
      cptCode[event.target.name] = event.target.checked;
    } else {
      cptCode[event.target.name] = event.target.value;
    }
    this.validateField(event.target.name, event.target.value);
    this.setState({ cptCode });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let officeValid = this.state.officeValid;
    let objectCodeValid = this.state.objectCodeValid;
    let subCodeValid = this.state.subCodeValid;
    let cptDescriptionValid = this.state.cptDescriptionValid;
    let sapglAccountValid = this.state.sapglAccountValid;
    let categoryValid = this.state.categoryValid;
    let remarksValid = this.state.remarksValid;
    switch (fieldName) {
      case "office":
        officeValid = maxLenghtAlpNumHypDot50(value);
        fieldValidationErrors.office = officeValid
          ? ""
          : resourceValidation.onlyAlphanumeric;
        break;
      case "objectCode":
        objectCodeValid = maxlengthValidator10WithNum(value);
        fieldValidationErrors.objectCode = objectCodeValid
          ? ""
          : resourceValidation.onlyAlphanumeric;
        break;
      case "subCode":
        subCodeValid = maxlengthValidator10WithNum(value);
        fieldValidationErrors.subCode = subCodeValid
          ? ""
          : resourceValidation.onlyAlphanumeric;
        break;
      case "cptDescription":
        cptDescriptionValid = true;
        fieldValidationErrors.subCode = cptDescriptionValid ? "" : "";
        break;
      case "sapglAccount":
        sapglAccountValid = maxlengthValidator20WithNum(value);
        fieldValidationErrors.sapglAccount = sapglAccountValid
          ? ""
          : resourceValidation.onlyAlphanumeric;
        break;
      case "category":
        // if (value !== "") {
        categoryValid = validateNotRequired100(value);
        fieldValidationErrors.category = categoryValid
          ? ""
          : resourceValidation.validCategory;
        // } else {
        //   categoryValid = true;
        //   fieldValidationErrors.category = "";
        // }
        break;
      case "remarks":
        if (value !== "") {
          remarksValid = validateNotRequired100(value);
          fieldValidationErrors.remarks = remarksValid
            ? ""
            : resourceValidation.validRemarks;
        } else {
          remarksValid = true;
          fieldValidationErrors.remarks = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        objectCodeValid: objectCodeValid,
        officeValid: officeValid,
        subCodeValid: subCodeValid,
        cptDescriptionValid: cptDescriptionValid,
        sapglAccountValid: sapglAccountValid,
        categoryValid: categoryValid,
        remarksValid: remarksValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.state.updatedValues &&
      this.setState({
        formValid:
          this.state.officeValid &&
          this.state.cptDescriptionValid &&
          // this.state.objectCodeValid &&
          // this.state.subCodeValid &&
          this.state.sapglAccountValid &&
          this.state.categoryValid &&
          this.state.remarksValid,
      });
    //this.setState({ formValid: this.state.objectCodeValid && this.state.subCodeValid && this.state.sapglAccountValid });
    return this.state.formValid;
  }

  _onKeyPress(event) {
    const re = /[0-9a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onOfficeTypeKeyPress(event) {
    const re = /[0-9a-zA-Z-. ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    let cptCodeId = this.props.match.params.cptcodeId;
    const { cptCode } = this.state;
    cptCode.cptOfficeType = cptCode.office;
    // cptCode.isActive = true;
    cptCode.modifiedBy = localStorage.loggedInId;
    updateCptCodeID(cptCodeId, cptCode, this.props.history);
  }

  render() {
    let { classes } = this.props;
    let { cptCode, active } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Cost Per Title</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    label="Office"
                    name="office"
                    inputProps={{
                      maxLength: 50,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={cptCode.office}
                    onChange={this.handleChange}
                    error={this.state.errors.office}
                    helperText={
                      this.state.errors.office ? this.state.errors.office : ""
                    }
                    onKeyPress={this._onOfficeTypeKeyPress}
                  />
                  <TextField
                    label="Category"
                    name="category"
                    margin="normal"
                    inputProps={{
                      maxLength: 50,
                    }}
                    required
                    autoComplete="off"
                    className={classes.textField}
                    value={cptCode.category}
                    onChange={this.handleChange}
                    error={this.state.errors.category}
                    helperText={
                      this.state.errors.category
                        ? this.state.errors.category
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    label="Object Code"
                    name="objectCode"
                    // required
                    inputProps={{
                      maxLength: 10,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={cptCode.objectCode.toUpperCase()}
                    onChange={this.handleChange}
                    // error={this.state.errors.objectCode}
                    // helperText={
                    //   this.state.errors.objectCode
                    //     ? this.state.errors.objectCode
                    //     : ""
                    // }
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    label="Sub Code"
                    name="subCode"
                    inputProps={{
                      maxLength: 10,
                    }}
                    autoComplete="off"
                    margin="normal"
                    //required
                    className={classes.textField}
                    value={cptCode.subCode.toUpperCase()}
                    onChange={this.handleChange}
                    error={this.state.errors.subCode}
                    helperText={
                      this.state.errors.subCode ? this.state.errors.subCode : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                </div>
                <div>
                  <TextField
                    multiline={false}
                    rows={1}
                    rowsMax={4}
                    inputProps={{
                      maxLength: 500,
                    }}
                    autoComplete="off"
                    label="Description"
                    name="cptDescription"
                    margin="normal"
                    className={classes.textField}
                    value={cptCode.cptDescription}
                    onChange={this.handleChange}
                    error={this.state.errors.cptDescription}
                    helperText={
                      this.state.errors.cptDescription
                        ? this.state.errors.cptDescription
                        : ""
                    }
                  />
                  <TextField
                    label="Remarks"
                    name="remarks"
                    margin="normal"
                    inputProps={{
                      maxLength: 500,
                    }}
                    autoComplete="off"
                    multiline={true}
                    className={classes.textField}
                    value={cptCode.remarks}
                    onChange={this.handleChange}
                    error={this.state.errors.remarks}
                    helperText={
                      this.state.errors.remarks ? this.state.errors.remarks : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    required
                    label=" SAP GL Account"
                    name="sapglAccount"
                    margin="normal"
                    inputProps={{
                      maxLength: 20,
                    }}
                    autoComplete="off"
                    className={classes.textField}
                    value={cptCode.sapglAccount}
                    onChange={this.handleChange}
                    error={this.state.errors.sapglAccount}
                    helperText={
                      this.state.errors.sapglAccount
                        ? this.state.errors.sapglAccount
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      name="isActive"
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          color="primary"
                          checked={cptCode.isActive}
                          onChange={this.handleChange}
                          value={cptCode.isActive}
                        />
                      }
                      label="Active"
                    />
                  )}
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/cptCode")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        onClick={this.handleSubmit}
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditCptCode));
