import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PopUpSearch from "../../app/poupSearch/popupAndSearch";
import { TextField, Button, Paper, Divider, FormControlLabel } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import Tooltip from "@material-ui/core/Tooltip";
import { StateContext } from "../../../shared/globalState";
import { getWarehouseLocationDDList } from "../../masters/masterActions";
import {
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Autocomplete } from "@material-ui/lab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import IndividualSearchAddDialog from "../ebloOrderPlacement/IndividualSearchAddDialog";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  getStockTypeDD,
  getStockReasonDD,
  createStockOut,
  getStockOutResult,
  getStockDetailsByJournalID,
} from "../stockaction";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  comboBox: {
    margin: "normal",
    width: "100%",
    fontSize: "11px !important",
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
    display: "flex",

    flex: 1,
    alignItems: "left",
    overflow: "hidden",
  },
  textField1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "31%",
    fontSize: "11px !important",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
  table: {
    minWidth: 650,
  },
  textFieldAlign: {
    // marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    // width: "23%",
    fontSize: "11px !important",
    flex: 1,
  },
  emptyField: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
  },
  lastChild: {
    marginRight: 0,
  },
});
const StockTable = (props) => {
  const { stockInList } = props;
  return (
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell className={"headcells"} style={{ width: "50%" }}>
            <span style={{ color: "#306dca" }}>Issue Description</span>
          </TableCell>
          <TableCell className={"headcells"} style={{ width: "50%" }}>
            <span style={{ color: "#306dca" }}>Location</span>
          </TableCell>
          <TableCell className={"headcells"} style={{ width: "10%" }}>
            <span style={{ color: "#306dca" }}>Balance Qty</span>
          </TableCell>
          <TableCell className={"headcells"} style={{ width: "10%" }}>
            <span style={{ color: "#306dca" }}>Stockout Qty</span>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {stockInList &&
          stockInList.map((row) => (
            <TableRow key={row.name}>
              <TableCell
                component="th"
                scope="row"
                padding="default"
                className="table-content-cell"
              >
                {row.issueDiscription && row.issueDiscription.length > 16 ? (
                  <Tooltip title={row.issueDiscription}>
                    <Typography noWrap style={{ fontSize: "14px" }}>
                      {row.issueDiscription}
                    </Typography>
                  </Tooltip>
                ) : (
                    <Typography noWrap style={{ fontSize: "14px" }}>
                      {row.issueDiscription}
                    </Typography>
                  )}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                padding="default"
                className="table-content-cell"
                style={{ minWidth: "150px" }}
              >
                {row.warehouseLocationName &&
                  row.warehouseLocationName.length > 16 ? (
                    <Tooltip title={row.warehouseLocationName}>
                      <Typography noWrap style={{ fontSize: "14px" }}>
                        {row.warehouseLocationName}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography noWrap style={{ fontSize: "14px" }}>
                      {row.warehouseLocationName}
                    </Typography>
                  )}
              </TableCell>

              <TableCell
                component="th"
                scope="row"
                padding="default"
                className="table-content-right-cell"
              >
                {row.balanceQty && row.balanceQty.length > 16 ? (
                  <Tooltip title={row.balanceQty}>
                    <Typography noWrap style={{ fontSize: "14px" }}>
                      {row.balanceQty}
                    </Typography>
                  </Tooltip>
                ) : (
                    <Typography noWrap style={{ fontSize: "14px" }}>
                      {row.balanceQty}
                    </Typography>
                  )}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                padding="default"
                className="table-content-right-cell"
              >
                {row.stockOutQty && row.stockOutQty.length > 16 ? (
                  <Tooltip title={row.stockOutQty}>
                    <Typography noWrap style={{ fontSize: "14px" }}>
                      {row.stockOutQty}
                    </Typography>
                  </Tooltip>
                ) : (
                    <Typography noWrap style={{ fontSize: "14px" }}>
                      {row.stockOutQty}
                    </Typography>
                  )}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
class AddBackLabelStockOut extends Component {
  state = {
    locationList: [],
    location: {},
    locationId: "",
    wlArray: [],
    jounalList: {
      acronym: "",
      issue: "",
      issueDescription: "",
      journalID: "",
      journalTitle: "",
      supplement: "",
      volume: "",
      stocktype: "",
      stockTypeID: "",
      reasonID: "",
      reason: "",
    },

    stockoutQty: "",
    remarks: "",
    errors: {},
    stockDate: new Date(),
    openDialog: false,
    stockoutQtyValid: false,
    stockInList: [],
    sumOfStockOutQty: 0,
    stockTypeList: [],
    stockTypeId: "",

    stockType: {},
    stockReasonDD: [],
    headCellsNew: [
      {
        id: 1,
        code: "issueDescription",
        numeric: false,
        disablePadding: false,
        label: "Issue Description",
        show: true,
        width: "200px",
      },
      {
        id: 2,
        code: "acronym",
        numeric: false,
        disablePadding: false,
        label: "Acronym",
        show: true,
        width: "58px",
      },
      {
        id: 3,
        code: "journalTitle",
        numeric: false,
        disablePadding: false,
        label: "Journal Title",
        show: true,
        width: "200px",
      },
      {
        id: 4,
        code: "volume",
        numeric: false,
        disablePadding: false,
        label: "Volume",
        show: true,
        width: "50px",
      },
      {
        id: 5,
        code: "issue",
        numeric: false,
        disablePadding: false,
        label: "Issue",
        show: true,
        width: "56px",
      },
      {
        id: 6,
        code: "supplement",
        numeric: false,
        disablePadding: false,
        label: "Supplement",
        show: true,
        width: "60px",
      },
      {
        id: 7,
        code: "action",
        numeric: false,
        disablePadding: false,
        label: "Action",
        show: true,
      },
    ],
  };
  getStockLocationDD = async (data) => {
    await this.setState({
      locationList:
        data
      // &&
      // data.sort((a, b) =>
      //   a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
      // ),
    });
  };
  componentDidMount() {
    this.props.handleSettingHeader("Back Label Stock Out");
    this.props.setSelectedList("publisherBackLabelOpen", "backLabelStockOut");
    getWarehouseLocationDDList().then((r) => {
      this.getStockLocationDD(r.data);
    });
    getStockTypeDD().then((r) => {
      this.setState({
        stockTypeList: r.data.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
      });
    });
    getStockReasonDD().then((r) => {
      this.setState({
        stockReasonDD: r.data.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
      });
      if (r && r.data) {
        let nJL = { ...this.state.jounalList }
        r.data.map(x => {
          if (x.value === "BLMO") {
            nJL.reason = x
            nJL.reasonID = x.id
            this.setState({ jounalList: nJL });

          }
        })
      }
    });
  }
  handleAddJournalClose = () => {
    this.setState({
      openDialog: false,
    });
  };
  handleClickOpen = () => {
    this.setState({
      openDialog: true,
    });
  };
  handleJournalUpdate = (selectedJournalList) => {
    let selected = selectedJournalList[0];

    let newList = {
      ...selected,
      stockoutQty: "",
      remarks: "",
      stocktype: null,
      stockTypeID: "",
      reason: this.state.jounalList.reason,
      reasonID: this.state.jounalList.reasonID,
    };

    this.setState({
      jounalList: newList,
      openDialog: false,
      remarks: "",
      stockoutQty: "",
      errors: {},
    });
    if (selectedJournalList[0].journalID)
      getStockDetailsByJournalID(selectedJournalList[0].journalID).then(res => {
        this.setState({ wlArray: res })
      })
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange1 = (event) => {
    this.validateField(event.target.name, event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChange2 = (event) => {
    this.validateField(event.target.name, event.target.value);
    this.setState({ [event.target.name]: event.target.value }, (this.onBlur));
  }
  handleStockTypeChange = (event, values) => {
    const { jounalList } = this.state;
    let newList = { ...jounalList };

    newList["stocktype"] = values && values;
    newList["stockTypeID"] = values && values.id;
    this.setState({
      jounalList: newList,
      stockoutQty: "",
      stockInList: [],
      errors: {},
      stockType: values && values,
      stockTypeId: values && values.id
    });
  };
  handleReasonChange = (event, values) => {
    const { jounalList } = this.state;
    let newList = { ...jounalList };

    newList["reason"] = values && values;
    newList["reasonID"] = values && values.id;
    this.setState({ jounalList: newList });
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;

    let stockoutQtyValid = this.state.stockoutQtyValid;

    switch (fieldName) {
      case "stockoutQty":
        if (value === "0") {
          stockoutQtyValid = false;
          fieldValidationErrors.stockoutQty = stockoutQtyValid
            ? ""
            : "Please enter valid Stock Out Quantity";
        } else {
          if (
            this.state.sumOfStockOutQty > 0 &&
            parseInt(value) > this.state.sumOfStockOutQty
          ) {
            stockoutQtyValid = false;
            fieldValidationErrors.stockoutQty = stockoutQtyValid
              ? ""
              : "StockOut quantity should not exceed " +
              this.state.sumOfStockOutQty;
          } else {
            stockoutQtyValid = true;
            fieldValidationErrors.stockoutQty = stockoutQtyValid
              ? ""
              : "Stock Out Quantity is not Available";
          }
        }

        break;

      default:
        break;
    }
    this.setState({
      errors: fieldValidationErrors,

      stockoutQtyValid: stockoutQtyValid,
    });
  }
  onBlur = (event) => {
    this.handleStockInList();
  };
  _onNumberKey(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleStockInList = () => {
    let errorThrown = false;

    let fieldValidationErrors = this.state.errors;

    if (
      !this.state.stockoutQtyValid
      // this.state.stockoutQty === "" ||
      // this.state.stockoutQty === "0" ||
      // this.state.stockoutQty === 0
    ) {
      fieldValidationErrors.stockoutQty =
        "Please enter valid Stock Out Quantity";
      errorThrown = true;
    }
    this.setState({ errors: fieldValidationErrors });

    if (!errorThrown) {
      let sumOfQty = 0;
      let stID =
        this.state.jounalList.stockTypeID && this.state.jounalList.stockTypeID;
      let stockTypeID =
        this.state.jounalList.journalID && this.state.jounalList.journalID;
      let stockOutQty = this.state.stockoutQty && this.state.stockoutQty;
      let wlId = this.state.locationId ? this.state.locationId : ""
      let reqBody = { stockTypeID, stockOutQty, stID, wlId };
      getStockOutResult(reqBody).then((r) => {
        r &&
          r.data &&
          r.data.forEach((stockIn, i) => {
            sumOfQty = sumOfQty + stockIn.balanceQty;
          });
        if ((+stockOutQty) > sumOfQty || sumOfQty === 0) {
          console.log(stockOutQty, sumOfQty)
          if (sumOfQty === 0) {
            fieldValidationErrors.stockoutQty =
              "StockOut quantity not available";
          } else {
            fieldValidationErrors.stockoutQty =
              "StockOut quantity should not exceed " + sumOfQty;
          }

          this.setState({
            stockInList: [],
            sumOfStockOutQty: sumOfQty,
            stockoutQtyValid: false,
            errors: fieldValidationErrors,
            // formValid: false,
          });
        } else {
          //fieldValidationErrors.stockQty = "";
          this.setState({
            stockInList: r.data,
            sumOfStockOutQty: sumOfQty,
            errors: fieldValidationErrors,
            stockoutQtyValid: true,
          });
        }
      });
    } else {
      this.setState({
        stockInList: [],
        sumOfStockOutQty: 0,
        errors: fieldValidationErrors,
        formValid: false,
      });
    }
  };
  handleSubmit = () => {
    let loggedInId = localStorage.loggedInId;
    const { jounalList } = this.state;
    let journalID = jounalList.journalID;
    let acronym = jounalList.acronym;
    let volume = jounalList.volume;
    let issue = jounalList.issue;
    let supp = jounalList.supplement;
    let issueDescription = jounalList.issueDescription;
    let warehouseLocationID = this.state.locationId
    let stockoutQty = this.state.stockoutQty;
    let stockTypeID = jounalList.stockTypeID;
    let stockoutDate = this.state.stockDate ? moment(this.state.stockDate).format("YYYY-MM-DD HH:mm:ss") : null;
    let isManual = true;
    let reasonID = jounalList.reasonID;
    let remarks = this.state.remarks;
    let createdBy = loggedInId;

    let reqBody = {
      stockInDetails: this.state.stockInList,
      journalID,
      acronym,
      volume,
      issue,
      supp,
      issueDescription,
      warehouseLocationID,
      stockoutQty,
      stockTypeID,
      stockoutDate,
      isManual,
      reasonID,
      remarks,
      createdBy,
    };

    createStockOut(reqBody, this.props.history).then(t => {
      getStockDetailsByJournalID(jounalList.journalID).then(res => {
        this.setState({
          stockTypeId: "", stockType: {}, wlArray: res, locationId: "", location: {}, recievedQty: 0, stockoutQty: "", stockDate: new Date(), remarks: ""
        });
      })
    })
  };
  checkGoodToGo = () => {
    if (
      // this.state.jounalList.acronym === "" ||
      // this.state.jounalList.issue === "" ||
      this.state.jounalList.issueDescription === "" ||
      // this.state.jounalList.supplement === "" ||
      // this.state.jounalList.volume === "" ||
      this.state.jounalList.stocktype === "" ||
      //this.state.jounalList.reason === "" ||
      this.state.stockoutQty === "" ||
      this.state.stockoutQty > this.state.sumOfStockOutQty ||
      this.state.stockoutQty == 0
    )
      return true;
    else return false;
  };
  handleLocationChange = async (e, val) => {
    if (val) {
      let newWlArr = [...this.state.wlArray]
      this.state.wlArray.map((x, j) => {
        newWlArr[j].checkedVal = false
      })

      this.setState({ wlArray: newWlArr })
    }
    this.setState({ locationId: val && val.id, location: val && val, errors: {}, stockoutQty: "" });

  };
  onCkBxClicked = (e, i, data) => {
    let newWlArr = [...this.state.wlArray]
    this.state.wlArray.map((x, j) => {
      newWlArr[j].checkedVal = false
    })
    if (e.target.checked) {
      newWlArr[i].checkedVal = true
      this.setState({ eMsg: false })
      this.state.locationList.map(l => {
        if (l.id === data.warehouseLocationID) {

          this.setState({ locationId: l.id, location: l, errors: {}, stockoutQty: "", })
        }

      })
      this.state.stockTypeList.map(s => {
        if (s.id === data.stockTypeID) {
          // this.setState({ stockTypeId: s.id, stockType: s });
          const { jounalList } = this.state;
          let newList = { ...jounalList };

          newList["stocktype"] = s;
          newList["stockTypeID"] = s.id;
          this.setState({
            jounalList: newList,
            stockoutQty: "",
            stockInList: [],
            errors: {},
            stockType: s,
            stockTypeId: s.id
          });
        }
      })
    }
    else {
      this.setState({ locationId: "", location: {} })
      this.setState({ stockTypeId: "", stockType: {} });
    }
    this.setState({ wlArray: newWlArr })


  }
  handleDateChange = (date) => {
    this.setState({ stockDate: date });
  };
  render() {
    const {
      acronym,

      issue,

      issueDescription,

      journalID,

      journalTitle,

      supplement,

      volume,
      stocktype,
      reason,
    } = this.state.jounalList;
    const { classes } = this.props;
    // console.log(this.state.jounalList)

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Stock Out</div>
          <Divider />
          <div>
            <div>
              {this.state.openDialog ? (
                <IndividualSearchAddDialog
                  title="Journal Pick Up"
                  type="stockOut"
                  showDialog={this.state.openDialog}
                  handleAddJournalClose={this.handleAddJournalClose}
                  handleJournalUpdate={this.handleJournalUpdate}
                  filteredJournalsList={[]}
                  tableheader={this.state.headCellsNew}
                />
              ) : null}
            </div>
          </div>
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex", width: "100%" }}>
                  <Button
                    onClick={this.handleClickOpen}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ boxShadow: "none" }}
                  >
                    <span>CHOOSE JOURNAL</span>
                  </Button>
                  <div className={classes.emptyField}></div>
                  <div
                    className={[classes.emptyField, classes.lastChild].join(
                      " "
                    )}
                  ></div>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  {/* <TextField
                    id="stockOutDate"
                    label="Stock Out Date"
                    variant="filled"
                    value={this.state.stockDate}
                    className={classes.textFieldAlign}
                    margin="normal"
                    readOnly
                  /> */}
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    variant="inline"
                    inputProps={{ readOnly: true }}
                    format="dd/MM/yyyy"
                    margin="normal"
                    style={{
                      flex: 1,
                      marginRight: "16px"
                    }}
                    // disabled={!isManual ? true : false}
                    // disabled
                    id="stockDate"
                    label="Stock Out Date"
                    onChange={this.handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    value={this.state.stockDate ? this.state.stockDate : null}
                  />
                  <div className={classes.emptyField}></div>
                  <div
                    className={[classes.textFieldAlign, classes.lastChild].join(
                      " "
                    )}
                  ></div>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <TextField
                    label="Acronym"
                    name="acronym"
                    inputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                    onKeyPress={this._onTextKeyPress}
                    margin="normal"
                    className={classes.textFieldAlign}
                    value={acronym}
                  // onChange={this.handleChange}
                  />
                  <TextField
                    label="Volume"
                    name="volume"
                    inputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                    //onChange={this.handleChange}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    className={classes.textFieldAlign}
                    value={volume}
                  />
                  <TextField
                    label="Issue"
                    name="issue"
                    inputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                    // onChange={this.handleChange}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    className={[classes.textFieldAlign, classes.lastChild].join(
                      " "
                    )}
                    value={issue}
                  />
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <TextField
                    label="Supplement"
                    name="supplement"
                    inputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                    //onChange={this.handleChange}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    className={classes.textFieldAlign}
                    value={supplement}
                  />
                  <TextField
                    required
                    label="Issue Description"
                    name="issueDiscription"
                    inputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                    // onChange={this.handleChange}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    className={classes.textFieldAlign}
                    value={issueDescription}
                  />
                  <div
                    className={[classes.textFieldAlign, classes.lastChild].join(
                      " "
                    )}
                  ></div>
                </div>
                {this.state.wlArray && this.state.wlArray.length > 0 && <div className="view-page-headings" style={{ height: "35px", fontSize: "17px", width: "100%" }}>Stock Out Information</div>}
                {/* <div className="view-page-contents-container"></div> */}
                {this.state.wlArray && this.state.wlArray.length > 0 && <Divider
                  style={{
                    marginTop: "25px",
                  }}
                />}
                {this.state.wlArray && this.state.wlArray.length > 0 && <div style={{ maxHeight: 500, overflowY: "auto", width: "100%" }}>
                  <Table
                    aria-labelledby="tableTitle"
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell padding="default" className="headcells">
                          {" "}
                          <span style={{ color: "#306dca" }}>

                          </span>{" "}
                        </TableCell>
                        <TableCell padding="default" className="headcells">
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            Warehouse Location Name
                          </span>{" "}
                        </TableCell>
                        <TableCell padding="default" className="headcells">
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            Stock Type
                          </span>{" "}
                        </TableCell>
                        <TableCell padding="default" className="headcells">
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            Balance Qty
                          </span>{" "}
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        this.state.wlArray && this.state.wlArray.length > 0 && this.state.wlArray.map((ja, i) => {
                          return (
                            ja && <TableRow >
                              <TableCell className="table-content-cell" component="th" style={{ padding: "0px .5em", width: "20px" }}
                                scope="row"
                                padding="default"> <FormControlLabel
                                  control={
                                    <CustomCheckbox
                                      color="primary"
                                      checked={ja.checkedVal ? ja.checkedVal : false}
                                      onClick={e => this.onCkBxClicked(e, i, ja)}
                                    />
                                  }
                                /> </TableCell>
                              <TableCell className="table-content-cell" component="th" style={{ padding: ".5em" }}
                                scope="row"
                                padding="default">{ja.warehouseLocationName} </TableCell>
                              <TableCell className="table-content-cell" component="th" style={{ padding: ".5em" }}
                                scope="row"
                                padding="default">{ja.stockType}</TableCell>
                              <TableCell className="table-content-cell" component="th" style={{ padding: ".5em" }}
                                scope="row"
                                padding="default">{ja.balanceQty}</TableCell>

                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </div>}
                <div className="view-page-headings" style={{ height: "35px", fontSize: "17px", width: "100%" }}>Stock Out Details</div>
                <Divider
                  style={{
                    marginTop: "25px",
                  }}
                />

                <div style={{ display: "flex", width: "100%" }}>
                  <Autocomplete
                    options={this.state.locationList}
                    value={this.state.location}
                    // disabled={!isManual ? true : false}
                    onChange={this.handleLocationChange}
                    getOptionLabel={(option) => option.text}
                    style={{
                      flex: 1,
                      marginRight: "16px"
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Location"
                        margin="normal"
                        required
                        fullWidth
                      />
                    )}
                  />
                  <Autocomplete
                    autoComplete
                    className={[
                      classes.autoComplete,
                      classes.lastChild,
                      classes.textFieldAlign,
                    ].join(" ")}
                    id="stockType"
                    includeInputInList
                    disabled={this.state.jounalList.journalID === ""}
                    options={this.state.stockTypeList}
                    value={this.state.stockType}
                    style={{ marginRight: "14px" }}
                    onChange={this.handleStockTypeChange}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Stock Type"
                        style={{ width: "100%" }}
                        name="stockType"
                        margin="normal"

                      // error={this.state.errors.customerID}
                      // helperText={
                      //   this.state.errors.customerID
                      //     ? this.state.errors.customerID
                      //     : ""
                      // }
                      />
                    )}
                  />

                  <TextField
                    required
                    label="Stockout Qty"
                    name="stockoutQty"
                    inputProps={{
                      maxLength: 50,
                    }}
                    disabled={
                      this.state.jounalList.stocktype === "" ||
                      this.state.jounalList.stocktype === null
                    }
                    // onBlur={this.onBlur}
                    onChange={this.handleChange2}
                    onKeyPress={this._onNumberKey}
                    margin="normal"
                    className={[classes.textFieldAlign, classes.lastChild].join(
                      " "
                    )}
                    value={this.state.stockoutQty}
                    error={this.state.errors.stockoutQty}
                    helperText={
                      this.state.errors.stockoutQty
                        ? this.state.errors.stockoutQty
                        : ""
                    }
                  />
                  {/* <div
                    className={[classes.textFieldAlign, classes.lastChild].join(
                      " "
                    )}
                  ></div> */}
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <Autocomplete
                    autoComplete
                    className={[
                      classes.autoComplete,
                      classes.textFieldAlign,
                    ].join(" ")}
                    id="reason"
                    includeInputInList
                    options={this.state.stockReasonDD}
                    value={reason}
                    onChange={this.handleReasonChange}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Reason"
                        className={classes.autocompleteText}
                        name="reason"
                        margin="normal"
                        style={{ width: "100%" }}

                      // error={this.state.errors.customerID}
                      // helperText={
                      //   this.state.errors.customerID
                      //     ? this.state.errors.customerID
                      //     : ""
                      // }
                      />
                    )}
                  />
                  <TextField
                    label="Remarks"
                    name="remarks"
                    multiline={true}
                    rowsMax={1}
                    style={{ marginRight: "10px" }}
                    inputProps={{
                      maxLength: 100,
                    }}
                    onChange={this.handleChange}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    className={classes.textFieldAlign}
                    value={this.state.remarks}
                  />
                  <div
                    className={[classes.textFieldAlign, classes.lastChild].join(
                      " "
                    )}
                  ></div>
                </div>
                <Divider
                  style={{
                    margin: "15px 0",
                    border: "solid 0px #979797",
                    height: "1px",
                    width: "100%",
                  }}
                />
                {/* <div
                  style={{
                    maxHeight: "700px",
                    marginTop: "10px",
                    width: "68%",
                  }}
                >
                  <div style={{ maxHeight: 650, overflow: "auto" }}>
                    <StockTable stockInList={this.state.stockInList} />
                  </div>
                </div> */}
                <Divider
                  style={{
                    marginTop: "25px",
                  }}
                />
                <div style={{ display: "flex", width: "100%" }}>
                  <div className="button-wrapper">
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={(e) =>
                          this.props.history.push("/backLabelStockOut")
                        }
                      >
                        CANCEL
                      </Button>
                    </div>
                    <div style={{ marginLeft: "1em" }}>
                      {this.checkGoodToGo() ? (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                            onClick={this.handleSubmit}
                          >
                            SUBMIT
                          </Button>
                        )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(AddBackLabelStockOut));
