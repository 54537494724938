import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_PUBLISHER_WAREHOUSE_MGT;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getAllListActive = async () => {
  let data = [];
  await pgsService
    .get("/GetJournalDataAsync")
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};
export const getStockInAll = async () => {
  let data = [];
  await pgsService
    .get("/StockIn")
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};

export const getByIdStockIn = async (id) => {
  let customerList = [];
  await pgsService
    .get("/StockIn/" + id)
    .then((r) => {
      customerList = r;
    })
    .catch((e) => { });
  return customerList;
};
export const getIsValidStockInForLocation = async (jId, wId, sId) => {
  let res = [];
  await pgsService
    .get(`/IsValidStockInForLocation/${jId}/${wId}/${sId}`)
    .then((r) => {
      res = r;
    })
    .catch((e) => { });
  return res;
};
// GetDDLStockReason
export const getStockReasonDD = async () => {
  let data = [];
  await pgsService
    .get("/GetDDLStockReason")
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};

export const getStockTypeDD = async () => {
  let data = [];
  await pgsService
    .get("/GetDDLStockType")
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};

export const GetDDLStockInReason = async () => {
  let data = [];
  await pgsService
    .get("/GetDDLStockInReason")
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};


export const getStockDetailsByJournalID = async (id) => {
  let result = [];
  await pgsService
    .get(`/GetStockDetailsByJournalID/${id}`)
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else {
        result = r.data;
      }
    })
    .catch((e) => { });
  return result;
  // }
};
export const uploadStockInFiles = async (data, history) => {
  let result = [];
  await pgsService
    .post("/CreateByFile", data)
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else {
        result = r.data;
        history.push("/backLabelStockIn");
        toastr.success("StockIn Created Successful");
      }
    })
    .catch((e) => {
      if (
        e.response &&
        e.response.data &&
        e.response.data[0].exceptionMessage !== undefined
      ) {
        if (
          e.response &&
          e.response.data &&
          e.response.data[0].exceptionMessage.includes(
            "Unable to load shared library"
          )
        ) {
          toastr.error("Invalid File");
        } else {
          toastr.error(
            e.response && e.response.data && e.response.data[0].exceptionMessage
          );
        }
      } else {
        toastr.error(
          e.response && e.response.data && e.response.data[0].exceptionMessage
        );
      }
    });
  return result;
  // }
};

export const submitFileListForEmail = async (data, history) => {
  let result = [];
  await pgsService
    .post("/Submit", data)
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else {
        result = r.data;
        history.push("/backLabelStockIn");
        toastr.success("StockIn Created Successful");
      }
    })
    .catch((e) => {
      toastr.error("Something went wrong");
    });
  return result;
  // }
};

export const submitStockInFiles = async (data, history) => {
  let result = [];
  await pgsService
    .post("/StockIn", data)
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else {
        toastr.success("StockIn Created Successfully");
        result = r.data;
        // history.push("/backLabelStockIn");
      }
    })
    .catch((e) => { });
  return result;
  // }
};

export const getStockOrder = async () => {
  let result = [];
  try {
    let response = await pgsService.get("/StockOut");
    result = response;
    return result;
  } catch (e) { }
};

export const getStockOutResult = async (reqBody) => {
  let id = reqBody.stockTypeID;
  let id1 = reqBody.stockOutQty;
  let id3 = reqBody.stID;
  let id4 = reqBody.wlId
  let result = [];
  try {
    let response = await pgsService.get(
      `/GetStouckOutResult/${id}/${id1}/${id3}/${id4}`
    );
    result = response;
    return result;
  } catch (e) { }
};
export const createStockOut = async (reqBody, history) => {
  try {
    await pgsService.post("/StockOut", reqBody);
    toastr.success("Stock Out Created Successfully");
    // history.push("/backLabelStockOut");
  } catch (e) { }
};
export const getByIdStockOut = async (id) => {
  let customerList = [];
  await pgsService
    .get("/StockOut/" + id)
    .then((r) => {
      customerList = r;
    })
    .catch((e) => { });
  return customerList;
};

//---------------Stock transfer-----------

export const getStockTransfer = async () => {
  let result = [];
  try {
    let response = await pgsService.get("/StockTransfer");
    result = response;
    return result;
  } catch (e) { }
};

export const createStockTransfer = async (reqBody, history) => {
  try {
    await pgsService.post("/StockTransfer", reqBody);
    toastr.success("Stock Transfer Created Successfully");
    // history.push("/backLabelStockTransfer");
  } catch (e) { }
};

export const getStockTransferByID = async (id) => {
  let result = [];
  try {
    let response = await pgsService.get(`/StockTransfer/${id}`);
    result = response;
    return result;
  } catch (e) { }
};

export const getWHLocation = async (id) => {
  let result = [];
  try {
    let response = await pgsService.get(`/GetWHLocation`);
    result = response;
    return result;
  } catch (e) { }
};

export const advanceSearchForStockIn = async (reqBody) => {
  let searchList = [];
  await pgsService
    .post("/StockInAdvanceSerach", reqBody)
    .then(async (r) => {
      searchList = r;
    })
    .catch((e) => { });
  return searchList;
};

export const advanceSearchForStockOut = async (reqBody) => {
  let searchList = [];
  await pgsService
    .post("/StockOutAdvanceSerach", reqBody)
    .then(async (r) => {
      searchList = r;
    })
    .catch((e) => { });
  return searchList;
};

export const advanceSearchForStockTransfer = async (reqBody) => {
  let searchList = [];
  await pgsService
    .post("/StockTransferAdvanceSerach", reqBody)
    .then(async (r) => {
      searchList = r;
    })
    .catch((e) => { });
  return searchList;
};

//-----balanceQty-----

export const getBalanceQty = async (jid, lid, sid) => {
  let result = [];
  try {
    let response = await pgsService.get(`/GetBalanceQty/${jid}/${lid}/${sid}`);
    result = response;
    return result;
  } catch (e) { }
};

export const getVarianceQuantity = async (id) => {
  let result = [];
  try {
    let response = await pgsService.get(`/GroupInStockStagging/` + id);
    result = response;
    return result;
  } catch (e) { }
};

export const putCallforStockIn = async (id, reqBody) => {
  let data = [];
  await pgsService
    .put(`/Update/${id}`, reqBody)
    .then(async (r) => {
      if (r && r.status == "200") {
        toastr.success("Stock In Updated !");
      }
      data = r;
    })
    .catch((e) => { });
  return data;
};

export const deleteStockById = async (id) => {
  await pgsService
    .delete("/DeleteStockInStagging/" + `${id}`)
    .then((r) => {
      toastr.success("Stock In Staging Detail Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const getStockInDetails = async (id) => {
  let data = [];
  await pgsService
    .get(`/GetStockInDetailsByStagingDetailID/${id}`)
    .then(async (r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};

export const getWarehouseLocationStockType = async (journalId, stockId) => {
  let warehouseLocationList = [];
  await pgsService
    .get("/GetBLWHLocationCode/" + journalId + "/" + stockId)
    .then((r) => {
      warehouseLocationList = r;
    })
    .catch((e) => { });
  return warehouseLocationList;
};

export const getWarehouseLocationStockBalance = async (ws) => {
  let warehouseLocationList = [];
  await pgsService
    .get("/GetBLWHLocationCode/" + ws)
    .then((r) => {
      warehouseLocationList = r;
    })
    .catch((e) => { });
  return warehouseLocationList;
};
export const getWarehouseSuffixStockBalance = async () => {
  let warehouseSuffixList = [];
  await pgsService
    .get("/GetWHLocationSuffix")
    .then((r) => {
      warehouseSuffixList = r;
    })
    .catch((e) => { });
  return warehouseSuffixList;
};