import React, { Component, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import { StateContext } from "../../../shared/globalState";
import { getCountryDropdownList } from "../../masters/masterActions";

import Validator from "../../../shared/validator";
import { resourceValidation } from "../../../shared/resource";
import { createRecipientList } from "./action";
const onlyNumbers = new Validator("onlyNumbers");
const validatePhoneNo = new Validator("phnNoFCN");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: "absolute", right: "0" }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default class AddRecipientList extends React.Component {
  state = {
    open: false,
    recipientList: {
      companyName: "",
      department: "",
      postalCode: "",
      address1: "",
      address2: "",
      phoneNumber: "",
      contactPersonName: "",
      country: "",
      countryID: 0,
    },
    success: false,

    countryList: [],
  };

  componentDidMount() {
    getCountryDropdownList().then((r) => {
      this.getAllCountry(r.data);
    });
  }

  getAllCountry = (list) => {
    this.setState({
      countryList:
        list &&
        list.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let companyValid = this.state.companyValid;
    let departmentValid = this.state.departmentValid;
    let postalCodeValid = this.state.postalCodeValid;
    let address1Valid = this.state.address1Valid;
    let address2Valid = this.state.address2Valid;
    let contactNameValid = this.state.contactNameValid;
    let phoneNumberValid = this.state.phoneNumberValid;

    switch (fieldName) {
      case "company":
        if (value !== "null") {
          companyValid = true;
          fieldValidationErrors.company = companyValid
            ? ""
            : " Please fill Company";
        } else {
          companyValid = true;
          fieldValidationErrors.company = "";
        }
        break;
      case "department":
        if (value !== "null") {
          departmentValid = true;
          fieldValidationErrors.department = departmentValid
            ? ""
            : " Please fill department";
        } else {
          departmentValid = true;
          fieldValidationErrors.department = "";
        }
        break;
      case "postalCode":
        if (value !== "") {
          postalCodeValid = onlyNumbers(value);
          fieldValidationErrors.postalCode = postalCodeValid
            ? ""
            : resourceValidation.onlyNumbers;
        }
        break;
      case "address1":
        if (value !== "null") {
          address1Valid = true;
          fieldValidationErrors.address1 = address1Valid
            ? ""
            : " Please fill Address1";
        } else {
          address1Valid = true;
          fieldValidationErrors.address1 = "";
        }
        break;
      case "address2":
        if (value !== "null") {
          address2Valid = true;
          fieldValidationErrors.address2 = address2Valid
            ? ""
            : " Please fill Address2";
        } else {
          address2Valid = true;
          fieldValidationErrors.address2 = "";
        }
        break;
      case "phoneNumber":
        if (value !== "") {
          phoneNumberValid = validatePhoneNo(value);
          fieldValidationErrors.phoneNumber = phoneNumberValid
            ? ""
            : resourceValidation.validPhoneNo;
        }
        break;

      case "address2":
        if (value !== "null") {
          address2Valid = true;
          resourceValidation.customerName = address2Valid
            ? ""
            : " Please fill Address2";
        } else {
          address2Valid = true;
          resourceValidation.customerName = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        companyValid,
        departmentValid,
        postalCodeValid,
        address1Valid,
        address2Valid,
        contactNameValid,
        phoneNumberValid,
        submitSuccess: false,
      },
      this.validateForm
    );
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    if (this.props.handleDuplicateClose) this.props.handleDuplicateClose();
    this.setState({
      open: false,
      recipientList: {
        companyName: "",
        department: "",
        postalCode: "",
        address1: "",
        address2: "",
        phoneNumber: "",
        contactName: "",
        country: "",
        phoneNumber: "",
      },
      searchVal: "",
      searchClicked: false,
      stateWO: this.props.selectedItemMaster,
    });
  };
  handleDialogOpen = () => {
    this.setState({
      recipientList: {
        companyName: "",
        department: "",
        postalCode: "",
        address1: "",
        address2: "",
        phoneNumber: "",
        contactName: "",
        country: "",
        phoneNumber: "",
      },
    });
  };
  refreshPage() {
    window.location.reload(false);
  }
  handleSubmit = async () => {
    let { recipientList } = this.state;
    let createdBy = localStorage.loggedInId;
    let reqBody = {
      companyName: recipientList.companyName,
      department: recipientList.department,
      postalCode: recipientList.postalCode,
      address1: recipientList.address1,
      address2: recipientList.address2,
      contactPersonName: recipientList.contactName,
      countryID: recipientList.countryID,
      phoneNumber: recipientList.phoneNumber,
      createdBy: createdBy,
    };

    createRecipientList(reqBody).then((r) => {
      if (r === true) {
        this.setState({ success: true }, () => {
          this.props.change(this.state.success);
        });

        //this.refreshPage();
      }
    });
  };

  onInputChanged = (e) => {
    this.setState({ searchVal: e.target.value });
  };

  _onKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onMobileNumberKeyPress(event) {
    const re = /[0-9+() -]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusAllPress(event) {
    const re = /[a-z A-Z0-9=.,;()&@]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleChange = (event) => {
    let { recipientList } = this.state;
    recipientList[event.target.name] = event.target.value;
    this.setState({ recipientList });
    // this.validateField(event.target.name, event.target.value);
  };
  handleCountry = (event, values) => {
    let name = values && values.text;
    let countryID = values && values.id;
    const { recipientList } = this.state;
    recipientList["country"] = name;
    recipientList["countryID"] = countryID;
    this.setState({ recipientList });
  };

  render() {
    let selected = this.props && this.props.selectedItemMaster;

    let { recipientList, success } = this.state;
    let create = null;
    if (success === false) {
      create = (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          onClose={this.props.handleDuplicateClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.duplicateOpen}
          maxWidth="lg"
        >
          <DialogTitle
            style={{ display: "flex", alignItems: "center" }}
            id="customized-dialog-title"
            onClose={this.props.handleDuplicateClose}
          >
            Create New Recipient
          </DialogTitle>
          <DialogContent dividers>
            <div style={{ minWidth: "800px" }}>
              <div>
                <div style={{ marginTop: "-15px" }}>
                  <form onSubmit={this.handleSubmit}>
                    <div
                      style={{
                        fontFamily: "Roboto",
                        fontSize: "15px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        color: "#3982ea",
                        paddingTop: "15px",
                        marginBottom: "10px",
                      }}
                    >
                      Delivery Address
                    </div>
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {" "}
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "33.33%",
                            marginRight: "0",
                          }}
                        >
                          {" "}
                          <TextField
                            label="Company Name"
                            name="companyName"
                            required
                            inputProps={{
                              maxLength: 100,
                            }}
                            style={{
                              marginTop: "5px",
                              width: "100%",
                              marginRight: "0",
                            }}
                            margin="normal"
                            autoComplete="off"
                            value={recipientList.companyName}
                            onChange={this.handleChange}
                            onKeyPress={this._onTextPlusAllPress}
                          // error={this.state.errors.companyName}
                          // helperText={
                          //   this.state.errors.companyName
                          //     ? this.state.errors.companyName
                          //     : ""
                          // }
                          />
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "33.33%",
                            margin: "0 15px",
                          }}
                        >
                          {" "}
                          <TextField
                            label="Department"
                            name="department"
                            // required
                            inputProps={{
                              maxLength: 100,
                            }}
                            style={{
                              marginTop: "5px",
                              width: "100%",
                            }}
                            margin="normal"
                            autoComplete="off"
                            value={recipientList.department}
                            onChange={this.handleChange}
                            onKeyPress={this._onTextPlusAllPress}
                          // error={this.state.errors.department}
                          // helperText={
                          //   this.state.errors.department
                          //     ? this.state.errors.department
                          //     : ""
                          // }
                          />
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "33.33%",
                            marginRight: "0",
                          }}
                        >
                          {" "}
                          <TextField
                            label="Postal Code"
                            name="postalCode"
                            required
                            inputProps={{
                              maxLength: 50,
                            }}
                            style={{
                              marginTop: "5px",
                              width: "100%",
                              marginRight: "0",
                            }}
                            margin="normal"
                            autoComplete="off"
                            value={recipientList.postalCode}
                            onChange={this.handleChange}
                            onKeyPress={this._onNumberKeyPress}
                          // error={this.state.errors.postalCode}
                          // helperText={
                          //   this.state.errors.postalCode
                          //     ? this.state.errors.postalCode
                          //     : ""
                          // }
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "100%",
                            marginRight: "0",
                          }}
                        >
                          <TextField
                            label="Address 1"
                            name="address1"
                            required
                            inputProps={{
                              maxLength: 100,
                            }}
                            style={{
                              marginTop: "5px",
                              width: "100%",
                              marginRight: "0",
                            }}
                            margin="normal"
                            autoComplete="off"
                            value={recipientList.address1}
                            onChange={this.handleChange}
                            onKeyPress={this._onTextPlusAllPress}
                          // error={this.state.errors.address1}
                          // helperText={
                          //   this.state.errors.address1
                          //     ? this.state.errors.address1
                          //     : ""
                          // }
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "100%",
                            marginRight: "0",
                          }}
                        >
                          <TextField
                            label="Address 2"
                            name="address2"
                            // required
                            inputProps={{
                              maxLength: 100,
                            }}
                            style={{
                              marginTop: "5px",
                              width: "100%",
                              marginRight: "0",
                            }}
                            margin="normal"
                            autoComplete="off"
                            value={recipientList.address2}
                            onChange={this.handleChange}
                            onKeyPress={this._onTextPlusAllPress}
                          // error={this.state.errors.address2}
                          // helperText={
                          //   this.state.errors.address2
                          //     ? this.state.errors.address2
                          //     : ""
                          // }
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "15px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          color: "#3982ea",
                          paddingTop: "15px",
                          margin: "5px 0 13px",
                        }}
                      >
                        Contact Information
                      </div>
                      <div style={{ display: "flex", alignItems: "baseline" }}>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "33.33%",
                            margin: "0",
                          }}
                        >
                          {" "}
                          <TextField
                            label="Contact Person Name"
                            name="contactName"
                            required
                            inputProps={{
                              maxLength: 100,
                            }}
                            style={{
                              marginRight: "0",
                              marginTop: "0",
                              width: "100%",
                              margin: "0",
                            }}
                            margin="normal"
                            autoComplete="off"
                            value={recipientList.contactName}
                            onChange={this.handleChange}
                            onKeyPress={this._onTextPlusAllPress}
                          // error={this.state.errors.contactName}
                          // helperText={
                          //   this.state.errors.contactName
                          //     ? this.state.errors.contactName
                          //     : ""
                          // }
                          />
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "top",
                            width: "33.33%",
                            margin: "0 15px",
                          }}
                        >
                          <Autocomplete
                            autoComplete
                            required
                            includeInputInList
                            label="Country"
                            name="country"
                            options={this.state.countryList}
                            onChange={this.handleCountry}
                            value={recipientList.country}
                            getOptionLabel={(option) =>
                              option.text || recipientList.country
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label="Country"
                                name="country"
                                style={{
                                  marginTop: "0px",
                                  marginLeft: "0 15px",
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "33.33%",
                            marginRight: "0",
                          }}
                        >
                          {" "}
                          <TextField
                            label="Phone Number"
                            name="phoneNumber"
                            required
                            inputProps={{
                              maxLength: 20,
                            }}
                            style={{
                              marginRight: "0px",
                              marginTop: "0",
                              width: "100%",
                            }}
                            margin="normal"
                            autoComplete="off"
                            value={recipientList.phoneNumber}
                            onChange={this.handleChange}
                            onKeyPress={this._onMobileNumberKeyPress}
                          // error={this.state.errors.phoneNumber}
                          // helperText={
                          //   this.state.errors.phoneNumber
                          //     ? this.state.errors.phoneNumber
                          //     : ""
                          // }
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "15px 28px",
            }}
          >
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={() => this.handleClose()}
              >
                CANCEL
              </Button>
            </div>
            <div style={{ marginLeft: "1em" }}>
              {!recipientList.postalCode ||
                !recipientList.address1 ||
                !recipientList.companyName ||
                !recipientList.contactName ||
                !recipientList.countryID ||
                !recipientList.phoneNumber ? (
                  <Button
                    disabled
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                  >
                    SUBMIT
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={this.handleSubmit}
                  >
                    SUBMIT
                  </Button>
                )}
            </div>
          </DialogActions>
        </Dialog>
      );
    }
    return <div>{create}</div>;
  }
}
