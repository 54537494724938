import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { DownloadIcon } from "./customIcons";
import { useToolbarStyles } from "../shared/styles";
export const ExportCSV = ({ csvData, fileName }) => {
  const classes = useToolbarStyles();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Tooltip title="Download">
      <IconButton
        aria-label="download"
        className={classes.iconHover}
        onClick={e => exportToCSV(csvData, fileName)}
      >
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
};
