import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import clone from "clone";
import { StateContext } from "../../../shared/globalState";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import LinearProgress from "@material-ui/core/LinearProgress";
import IndividualSearchAddDialog from "../ebloOrderPlacement/IndividualSearchAddDialog";
import * as XLSX from "xlsx";
import { Autocomplete } from "@material-ui/lab";
import Switch from "@material-ui/core/Switch";
import DateFnsUtils from "@date-io/date-fns";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  getStockTypeDD,
  GetDDLStockInReason,
  submitStockInFiles,
  uploadStockInFiles,
  getWHLocation,
  submitFileListForEmail,
  getVarianceQuantity,
  putCallforStockIn,
  getStockInDetails,
  deleteStockById,
  getStockReasonDD,
  getIsValidStockInForLocation,
  getStockDetailsByJournalID
} from "../stockaction";
import {

  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import {
  TextField,
  Button,
  Paper,
  Divider,
  Tooltip,
  FormControlLabel,
} from "@material-ui/core";
import { addDispatchStyles } from "../../../shared/styles";
import Typography from "@material-ui/core/Typography";
import { EditIcon } from "../../../shared/customIcons";
import Input from "@material-ui/core/Input";
import DeleteIcon from "@material-ui/icons/Delete";
import Validator from "../../../shared/validator";
import "../../app/App.css";
import CheckIcon from "@material-ui/icons/Check";
import { getWarehouseLocationDDList } from "../../masters/masterActions";
import { getWarehouseLocationStockType } from "../../publisherBackLabel/stockaction";
import { getCustomerForInsertMasterList } from "../../publisher/action";
import { validate } from "email-validator";

let autoCId = "";

const styles = (theme) => ({
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginRight: theme.spacing(2),
    fontSize: "11px !important",
    flex: 1,
  },
  emptyField: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
  },
  lastChild: {
    marginRight: 0,
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
});

const headCells = [
  {
    id: "sNo",
    numeric: false,
    disablePadding: true,
    label: "S/No",
  },

  {
    id: "acronym",
    numeric: false,
    disablePadding: false,
    label: "Acr*",
  },
  {
    id: "volume",
    numeric: false,
    disablePadding: false,
    label: "Vol*",
  },
  {
    id: "issue",
    numeric: false,
    disablePadding: false,
    label: "Iss*",
  },
  {
    id: "suppNumber",
    numeric: false,
    disablePadding: false,
    label: "Supp",
  },
  {
    id: "issueDescription",
    numeric: false,
    disablePadding: false,
    label: "Issue Description",
  },
  {
    id: "height",
    numeric: false,
    disablePadding: false,
    label: "Height",
  },
  {
    id: "year",
    numeric: false,
    disablePadding: false,
    label: "Year",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "recievedQty",
    numeric: false,
    disablePadding: false,
    label: "Rcvd Qty*",
  },
  {
    id: "varianceQty",
    numeric: false,
    disablePadding: false,
    label: "Variance",
  },
  {
    id: "recievedDate",
    numeric: false,
    disablePadding: false,
    label: "Recvd Date",
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
  },
  {
    id: "exceptionMessage",
    numeric: false,
    disablePadding: false,
    label: "Exception",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

const headCells1 = [
  {
    id: "sNo",
    numeric: false,
    disablePadding: true,
    label: "S/No",
  },

  {
    id: "acronym",
    numeric: false,
    disablePadding: false,
    label: "Acronym",
  },
  {
    id: "volume",
    numeric: false,
    disablePadding: false,
    label: "Volume",
  },
  {
    id: "issue",
    numeric: false,
    disablePadding: false,
    label: "Issue",
  },
  {
    id: "suppNumber",
    numeric: false,
    disablePadding: false,
    label: "Supplement",
  },
  {
    id: "issueDescription",
    numeric: false,
    disablePadding: false,
    label: "Issue Description",
  },

  {
    id: "mlBalance",
    numeric: false,
    disablePadding: false,
    label: "ML Balance",
  },
  {
    id: "recievedQty",
    numeric: false,
    disablePadding: false,
    label: "Recieved Qty",
  },
  {
    id: "varience",
    numeric: false,
    disablePadding: false,
    label: "BL Variance",
  },
  {
    id: "mlvarience",
    numeric: false,
    disablePadding: false,
    label: "ML Variance",
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{
              borderLeft: "1px sold #000",
              minWidth: "0px",
              width: headCell.width,
            }}
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderTop: "1px solid #eaeaea",
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { } = props;

  return <div></div>;
};

function EnhancedTableHead1(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells1.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{
              borderLeft: "1px sold #000",
              minWidth: "0px",
            }}
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar1 = (props) => {
  const classes = useToolbarStyles();
  const { } = props;

  return (
    <div
      style={{
        border: "1px solid rgba(224, 224, 224, 1)",
        paddingLeft: "15px",
        // margin: "0 27px",
        display: "flex",
        padding: "4px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#306dca",
        fontFamily: "Roboto",
        fontSize: "15px",
        paddingLeft: "8px",
      }}
    >
      Main Label Stock and Back Stock Variance
    </div>
  );
};

class StockInPublisher extends React.Component {
  static contextType = StateContext;
  state = {
    wlArray: [],
    submitClicked: false,
    enableSubmit: false,
    deletePopupOpen: false,
    fileUploadSubmitPopupOpen: false,
    customerId: "",
    customerName: "",
    customersList: [],
    value: 0,
    list: [],
    varienceList: [],
    editableList: [],
    fileName: "",
    disableAdd: false,
    disableEdit: true,
    disableAddGP: false,
    disableEditGP: true,
    groupProcessEditClicked: false,
    loading: false,
    loading2: false,
    isManual: false,
    isValidate: false,
    openDialog: false,
    warehouseList: [],
    warehouse: {},
    floorList: [],
    floor: {},
    areaList: [],
    area: {},
    bayList: [],
    bay: {},
    binList: [],
    bin: {},
    stackList: [],
    stack: {},
    sectionList: [],
    section: {},
    locationList: [],
    location: {},
    locationId: "",
    stockTypeList: [],
    stockReasonDD: [],
    stockTypeId: "",
    reasonID: "",
    stockType: {},
    reason: {},
    recievedDate: new Date(),
    recievedQty: 0,
    remarks: "",
    journalData: {
      journalID: "",
      issueDescription: "",
      acronym: "",
      journalTitle: "",
      volume: "",
      issue: "",
      supplement: "",
    },
    locationValue: "null",
    blStagingHeaderID: 0,
    isTicked: false,
  };

  handleAddJournalClick = () => {
    this.setState({
      openDialog: true,
    });
  };

  handleAddJournalClose = () => {
    this.setState({
      openDialog: false,
    });
  };

  handleChange = (e, validate) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  componentDidMount() {
    const [{ store }, dispatch] = this.context;
    this.props.handleSettingHeader("Back Label Stock In");
    this.props.setSelectedList("publisherBackLabelOpen", "backLabelStockIn");

    getStockTypeDD().then((r) => {
      this.getStockyStypeDD(r.data);
    });

    // getWarehouseLocationDDList().then((r) => {
    //   this.getStockLocationDD(r.data);
    // });

    GetDDLStockInReason().then((r) => {
      r.data && this.setState({
        stockReasonDD: r.data.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
      });
      if (r && r.data) {
        r.data.map(x => {
          if (x.value === "BLMI") {
            this.setState({ reason: x, reasonID: x.id });

          }
        })
      }
    });

  }

  getStockLocationDD = (data) => {
    this.setState({
      locationList:
        data
      // &&
      // data.sort((a, b) =>
      //   a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
      // ),
    });
  };

  getStockyStypeDD = async (data) => {
    await this.setState({
      stockTypeList:
        data &&
        data.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };

  handleStockTypeChange = async (e, val) => {
    this.setState({ stockTypeId: val && val.id, stockType: val && val, locationId: "", location: "" });
    getWarehouseLocationStockType(this.state.journalData.journalID, val.id).then((r) => {
      this.getStockLocationDD(r.data);
    });
    this.setState({ enableSubmit: true, eMsg: false })
    // if (val) {
    //   if (this.state.journalData && this.state.journalData.journalID) {
    //     if (this.state.locationId) {
    //       getIsValidStockInForLocation(this.state.journalData.journalID, this.state.locationId, val.id).then(r => {
    //         // console.log(r)
    //         if (r.data)
    //           this.setState({ enableSubmit: true, eMsg: false })
    //         else
    //           this.setState({ enableSubmit: false, eMsg: true })
    //       })
    //     }
    //   }
    // }
  };

  handleReasonChange = (e, val) => {
    this.setState({ reason: val && val, reasonID: val && val.id });
  };

  handleLocationChange = async (e, val) => {
    // console.log(val)
    if (val) {
      let newWlArr = [...this.state.wlArray]
      this.state.wlArray.map((x, j) => {
        newWlArr[j].checkedVal = false
      })

      this.setState({ wlArray: newWlArr })
    }
    this.setState({ locationId: val && val.id, location: val && val });
    if (val) {
      if (this.state.journalData && this.state.journalData.journalID) {
        if (this.state.stockTypeId) {
          getIsValidStockInForLocation(this.state.journalData.journalID, val.id, this.state.stockTypeId).then(r => {
            // console.log(r)
            if (r.data)
              this.setState({ enableSubmit: true, eMsg: false })
            else
              this.setState({ enableSubmit: false, eMsg: true })
          })
        }
      }
    }
  };

  checkOGDisability = () => {
    if (!this.state.customerId) return true;
    else return false;
  };
  _onAlphaKeyPress(event) {
    const re = /[a-zA-Z0-9 ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _freeText(event) {
    const re = /[#.0-9a-zA-Z\s,-@()%!$%^&*{}/<>_;:'" |]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _alphaCharacter(event) {
    const re = /[#.a-zA-Z\s,-@()%!$%^&*{}/<>_;:'" |]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onReplacementPress(event) {
    const re = /[a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onlyAlphaKeyPress(event) {
    const re = /[a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberwithDotKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkOGSubmit = () => {
    let notValid = false;
    if (this.state.list.length === 0) return true;
    else;
    {
      this.state.list.map((l) => {
        if (l.exceptionMessage === "") notValid = false;
        else {
          notValid = true;
        }
      });

      this.state.list.map((l) => {
        if (
          !l.acronym ||
          !l.volume ||
          !l.issue ||
          !l.isValid ||
          !l.receivedQty ||
          !l.receivedDate
        )
          notValid = true;
      });
      return notValid;
    }
  };

  handleUpload = (e) => {
    let files = e.target.files;
    var reader = new FileReader();
    let VarienceList = [];
    let _error = "";
    this.setState({ loading: true, isTicked: false, varienceList: [] });
    if (files[0]) {
      this.setState({
        fileName: files[0].name,
        // disableAdd: true,
        disableEdit: false,
      });
      reader.onload = function (e) {
        let data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      };

      reader.readAsBinaryString(files[0]);
      let loggedInId = localStorage.getItem("loggedInId");
      const formData = new FormData();
      formData.append("uploadFile", files[0]);
      formData.append("CreatedBy", loggedInId);

      uploadStockInFiles(formData).then((r) => {
        let newList = [];
        r.map((res) => {
          newList.push({
            acronym: res.acronym,
            blStagingDetailID: res.blStagingDetailID,
            blStagingHeaderID: res.blStagingHeaderID,
            createdBy: res.createdBy,
            createdDate: res.createdDate,
            exceptionMessage: res.exceptionMessage,
            height: res.height,
            isActive: res.isActive,
            isValid: res.isValid,
            issue: res.issue,
            issueDescription: res.issueDescription,
            journalID: res.journalID,
            journalUniqueID: res.journalUniqueID,
            locationCode: { value: res.locationCode },
            modifiedBy: res.modifiedBy,
            modifiedDate: res.modifiedDate,
            pubYear: res.pubYear,
            reasonID: res.reasonID,
            receivedDate: moment(res.receivedDate).format("DD/MM/YYYY"),
            receivedQty: res.receivedQty,
            remarks: res.remarks,
            supp: res.supp,
            varience: res.varience,
            volume: res.volume,
            stockTypeID: res.stockTypeID
          });
          this.setState({
            blStagingHeaderID: res.blStagingHeaderID,
          });
        });

        this.setState({
          list: newList,
          editableList: newList,
          disableEdit: false,
          loading: false,
        });
      });
    } else {
      this.setState({
        fileName: "",
        loading: false,
      });
    }
  };

  getVarianceQtyList = async () => {
    let vList = [];
    await getVarianceQuantity(this.state.blStagingHeaderID).then((r) => {
      r &&
        r.data &&
        r.data.map((res) => {
          vList.push({
            acronym: res.acronym,
            volume: res.volume,
            issue: res.issue,
            supp: res.supp,
            issueDescription: res.issueDescription,
            receivedQty: res.receivedQty,
            mlBalance: res.mlBalance,
            mlvarience: res.mlVarience,
            varience: res.varience,
          });
        });
      this.setState({
        varienceList: vList,
        loading2: false,
      });
      if (this.state.varienceList > 0) {
        let res = this.state.varienceList.filter((val) => {
          return val;
        });
        if (res[0].varience != 0 || res[0].varience != "0") {
          this.setState({ fileUploadSubmitPopupOpen: true });
        } else {
          this.setState({ fileUploadSubmitPopupOpen: false });
        }
      }
    });
  };

  // componentDidUpdate(s, p) {
  //   if (p.blStagingHeaderID > 0 && this.state.varienceList.length == 0) {
  //     this.getVarianceQtyList();
  //   }
  // }

  handleClickButton = (e, name) => {
    let newList = clone(this.state.list);
    let newObj = {
      sNo: "",
      acronym: "",
      volume: "",
      issue: "",
      suppNumber: "",
      issueDescription: "",
      height: "",
      year: "",
      location: "",
      recievedQty: "",
      varianceQty: "",
      recievedDate: "",
      remarks: "",
      exceptionMessage: "",
      editClicked: true,
    };
    newList.push(newObj);
    if (name === "add") {
      this.setState(
        {
          list: newList,
          editableList: newList,
          // disableAdd: true,
          disableEdit: false,
        },
        () => {
          document.getElementById(`tableRow${newList.length - 1}`).focus();
        }
      );
    } else {
      this.setState({
        list: [],
        editableList: [],
        disableAdd: false,
        disableEdit: true,
        fileName: "",
      });
    }
  };

  onEditClicked = (e, i) => {
    let newEditableList = clone(this.state.list);
    newEditableList[i].editClicked = !newEditableList[i].editClicked;
    // alert("edit");
    getWarehouseLocationStockType(newEditableList[i].journalID, newEditableList[i].stockTypeID).then((r) => {
      console.log("a", r.data);
      this.getStockLocationDD(r.data);
    });
    this.setState({
      //  editableList: newEditableList,
      list: newEditableList,
      isTicked: true,
    });
  };
  onDeleteClicked = async (e, i, row) => {
    let newList = clone(this.state.list);
    newList[i].editClicked = false;
    if (!row.blStagingDetailID) {
      newList.splice(i, 1);
      this.setState({
        list: newList,
        isTicked: false,
      });
    } else {
      await deleteStockById(row.blStagingDetailID).then((r) => {
        newList.splice(i, 1);
        this.setState({
          list: newList,
          isTicked: false,
        });
        this.getVarianceQtyList();
      });
    }
  };
  onExcelDataChanged = (e, name, i) => {
    let newList = clone(this.state.list);
    newList[i][name] = e.target.value;
    this.setState({ list: newList, editClicked: true });
  };
  handleInputChange = (e, validate) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDateChange = (date) => {
    this.setState({ ["recievedDate"]: date });
  };

  onDoneClicked = async (e, i, record) => {
    let newList = [...this.state.list];

    let StaggingDetailID = newList[i].blStagingDetailID;
    let varience = newList[i].varience;
    let locationCode = newList[i].locationCode.value;
    let receivedQty = newList[i].receivedQty;

    let reqBody = {
      varience,
      locationCode,
      receivedQty,
    };

    putCallforStockIn(StaggingDetailID, reqBody).then((val1) => {
      getStockInDetails(val1.data).then((val) => {
        let newList = [];
        val &&
          val.data && val.data.length > 0 && val.data.map(r => {
            newList.push({
              acronym: r.acronym,
              blStagingDetailID: r.blStagingDetailID,
              blStagingHeaderID: r.blStagingHeaderID,
              createdBy: r.createdBy,
              createdDate: r.createdDate,
              exceptionMessage: r.exceptionMessage,
              height: r.height,
              isActive: r.isActive,
              isValid: r.isValid,
              issue: r.issue,
              issueDescription: r.issueDescription,
              journalID: r.journalID,
              journalUniqueID: r.journalUniqueID,
              locationCode: { value: r.locationCode },
              modifiedBy: r.modifiedBy,
              modifiedDate: r.modifiedDate,
              pubYear: r.pubYear,
              reasonID: r.reasonID,
              receivedDate: moment(r.receivedDate).format("DD/MM/YYYY"),
              receivedQty: r.receivedQty,
              remarks: r.remarks,
              supp: r.supp,
              varience: r.varience,
              volume: r.volume,
              editClicked: false,
              stockTypeID: r.stockTypeID
            });
          })


        // this.setState({
        //   blStagingHeaderID: val.blStagingHeaderID,
        // });
        // newList[i].editClicked = false;
        this.setState({ list: newList, isValidate: false, isTicked: false });
        // if (newList.length > 0) {
        //   this.getVarianceQtyList();
        // }
      });
    })

  };

  onSwitch = () => {
    this.setState({ deletePopupOpen: true });
  };

  onValidateButtonClicked = async () => {
    this.setState({ isValidate: true, loading2: true });
    await this.getVarianceQtyList();
  };

  handleDeleteConfirmModalClose = () => {
    this.setState({ deletePopupOpen: false });
  };
  handleDeleteConfirmModalSwitch = () => {
    if (this.state.isManual) {
      this.setState({
        location: {},
        stockType: {},
        reason: {},
        recievedDate: null,
        recievedQty: "",
        remarks: "",
        fileName: "",
        journalData: {
          journalID: "",
          issueDescription: "",
          acronym: "",
          journalTitle: "",
          volume: "",
          issue: "",
          supplement: "",
        },
      });
    }
    this.setState({ isManual: !this.state.isManual, deletePopupOpen: false });
  };

  handleSubmit = (e) => {
    let { journalData } = this.state;
    let journalID = journalData.journalID;
    let acronym = journalData.acronym;
    let volume = journalData.volume;
    let issue = journalData.issue;
    let supp = journalData.supplement;
    let issueDescription = journalData.issueDescription;
    let customerID = "";
    let height = "";
    let pubYear = "";
    let warehouseLocationID = this.state.locationId;
    let receivedQty = this.state.recievedQty;
    let remarks = this.state.remarks;
    let usedQty = "";

    let stockTypeID = this.state.stockTypeId;
    let reasonID = this.state.reasonID;
    let receivedDate = this.state.recievedDate ? moment(this.state.recievedDate).format("YYYY-MM-DD HH:mm:ss") : null;
    let varience = "";
    let createdBy = window.localStorage.loggedInId;

    let reqbody1 = {
      journalID,
      acronym,
      volume,
      issue,
      supp,
      issueDescription,
      customerID,
      height,
      pubYear,
      warehouseLocationID,
      receivedQty,
      remarks,
      usedQty,
      stockTypeID,
      reasonID,
      receivedDate,
      varience,
      createdBy,
    };
    if (this.state.isManual) {
      submitStockInFiles(reqbody1, this.props.history).then(r => {
        getStockDetailsByJournalID(this.state.journalData.journalID).then(res => {
          this.setState({
            stockTypeId: "", stockType: {}, wlArray: res, locationId: "", location: {}, recievedQty: "", remarks: ""
          });
        })
      });
    }
  };

  onFileUploadSwitch = () => {
    this.setState({ fileUploadSubmitPopupOpen: true });
  };

  handleVarienceSubmit = () => {
    let reqbody = {};
    reqbody.staggingHeaderID = this.state.blStagingHeaderID;
    if (!this.state.isManual) {
      this.setState({ submitClicked: true })
      submitFileListForEmail(reqbody, this.props.history).then((r) => {
        this.setState({ submitClicked: false, fileUploadSubmitPopupOpen: false })
      });
    }
  };

  handleVarienceCancel = () => {
    this.setState({ fileUploadSubmitPopupOpen: false });
  };

  handleJournalUpdate = (selectedJournalList) => {
    // console.log(selectedJournalList[0])
    this.setState({
      journalData: selectedJournalList[0],
      openDialog: false,
    });
    if (selectedJournalList.length > 0) {
      if (selectedJournalList[0].journalID) {
        let stId = ""
        let lId = ""
        this.setState({ locationId: "", location: {} })
        this.setState({ stockTypeId: "", stockType: {}, recievedQty: "" });
        // if (this.state.stockTypeId && this.state.locationId) {
        //   getIsValidStockInForLocation(selectedJournalList[0].journalID, this.state.locationId, this.state.stockTypeId).then(r => {
        //     if (r.data)
        //       this.setState({ enableSubmit: true, eMsg: false })
        //     else
        //       this.setState({ enableSubmit: false, eMsg: true })
        //   })
        // }
        getStockDetailsByJournalID(selectedJournalList[0].journalID).then(res => {
          this.setState({ wlArray: res })
        })
      }
    }
  };

  clearJournalInfo = (i) => {
    let newjournalData = { ...this.state.journalData };
    newjournalData = {};
    this.setState({ workOrder: newjournalData });
  };

  handleCoverChange = (event, name, i) => {
    let newList = clone(this.state.list);
    newList[i][name] = event.target.checked;
    this.setState({ list: newList, editClicked: true });
  };

  onBlur = (event, name, index) => {
    // const { dhlRateValues } = this.state;
    // let insertMasterValues = event.target.value;
    // insertMasterValues = this.addZeroes(insertMasterValues.toString(), 3);
    // this.setState({ [event.target.name]: insertMasterValues });

    let newList = clone(this.state.list);
    let values = event.target.value;
    values = this.addZeroes(values.toString(), 3);
    newList[index][name] = values;
    this.setState({ list: newList });
  };

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  handleDDLChangeUpdated = (event, value) => {
    event.persist();
    let index = autoCId;
    // index = event.target.id.split("-")[1];
    // let name = event.target.id.split("-")[0];
    let newEditableList = clone(this.state.list);
    if (value)
      newEditableList[index].locationCode = value;
    else
      newEditableList[index].locationCode = {};

    this.setState({
      list: newEditableList,
    });
  };
  onCkBxClicked = (e, i, data) => {
    let newWlArr = [...this.state.wlArray]
    this.state.wlArray.map((x, j) => {
      newWlArr[j].checkedVal = false
    })
    if (e.target.checked) {
      newWlArr[i].checkedVal = true
      this.setState({ eMsg: false })
      this.state.stockTypeList.map(s => {
        if (s.id === data.stockTypeID) {
          this.setState({ stockTypeId: s.id, stockType: s });
          getWarehouseLocationStockType(this.state.journalData.journalID, s.id).then((r) => {
            this.getStockLocationDD(r.data);
            this.state.locationList && this.state.locationList.map(l => {
              if (l.id === data.warehouseLocationID) {
                this.setState({ locationId: l.id, location: l })
              }

            })
          });
        }
      })
    }
    else {
      this.setState({ locationId: "", location: {} })
      this.setState({ stockTypeId: "", stockType: {} });
    }

    this.setState({ wlArray: newWlArr })


  }
  handleAutoOpenClick = (id) => {
    // console.log(id)
    autoCId = id;
  };
  render() {
    let { list, fileName, journalData, isManual } = this.state;
    let { classes } = this.props;
    // console.log(this.state.wlArray)
    let getvarienceFilter =
      this.state.varienceList &&
      this.state.varienceList.length > 0 &&
      this.state.varienceList.reduce(
        (a, o) => (o.varience && a.push(o.varience), a),
        []
      );

    return (
      <React.Fragment>
        <Paper>
          <div
            style={{ flexGrow: "1", paddingBottom: !isManual ? "0" : "0.5em" }}
          >
            <div className="pop-up-header">Create New Stock In</div>
            <Divider />
            <div
              className={classes.container}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div
                className={classes.container}
                style={{
                  width: "100%",
                  marginBottom: this.state.isManual == false ? "1em" : "0",
                }}
              >
                <div
                  style={{
                    width: "auto",
                    border: isManual
                      ? "2px solid #9e9e9e"
                      : "2px dashed #eaeaea",
                    margin: "0 .8em 0 0",
                    borderRadius: "4px",
                    cursor: isManual ? "default" : "pointer",
                    pointerEvents: isManual ? "none" : "all",
                    display: "inline-block",
                    verticalAlign: "middle",
                    backgroundColor: isManual ? "#9e9e9e" : "#fff",
                  }}
                >
                  <label
                    className="upload excel"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      minHeight: "40px",
                      padding: ".3em",
                    }}
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => this.handleUpload(e)}
                      accept=".xlsx"
                    />
                    <div
                      style={
                        isManual
                          ? {
                            backgroundColor: "#fff",
                            color: "#000",
                            borderRadius: "4px",
                            padding: ".3em 1em .3em 1em",
                          }
                          : {
                            backgroundColor: "#3982ea",
                            color: "#fff",
                            borderRadius: "4px",
                            padding: ".3em 1em .3em 1em",
                            cursor: "pointer",
                          }
                      }
                    >
                      CHOOSE FILE
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "auto",
                        marginLeft: "12px",
                        width: "320px",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "500",
                          fontSize: "13px",
                          color: isManual ? "#fff" : "#000",
                        }}
                      >
                        {fileName ? fileName : "No file choosen"}
                      </div>
                      <div
                        style={{ color: isManual ? "#fff" : "#000" }}
                        className="file-upload-footer"
                      >
                        {fileName
                          ? ""
                          : "Allowed only “.xlsx” format, 500KB file limit."}
                      </div>
                    </div>
                  </label>
                </div>
                <div
                  className={classes.textField}
                  style={{ display: "inline-block", verticalAlign: "middle" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => this.onSwitch()}
                    style={{
                      height: "auto",
                      display: " inline-block",
                      width: "auto",
                      verticalAlign: "middle",
                      boxShadow: "none",
                    }}
                  >
                    {isManual ? "File Upload Mode" : "Manual Mode"}
                  </Button>
                  <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    onClose={this.handleDeleteConfirmModalClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.deletePopupOpen}
                    maxWidth="lg"
                  >
                    <DialogTitle id="customized-dialog-title">
                      Switch to {isManual ? "File Upload Mode" : "Manual Mode"}
                    </DialogTitle>
                    <DialogContent dividers>
                      <div style={{ minWidth: "300px" }}>
                        Are you sure you want to switch to{" "}
                        {isManual ? "File Upload Mode" : "Manual Mode"} ?
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        // autoFocus
                        onClick={this.handleDeleteConfirmModalClose}
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                      >
                        Close
                      </Button>
                      <Button
                        //autoFocus
                        onClick={this.handleDeleteConfirmModalSwitch}
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                      >
                        Switch
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>

                <div style={{ marginTop: "1em" }}>
                  {" "}
                  <EnhancedTableToolbar
                    //numSelected={selected.length}
                    handleClickButton={this.handleClickButton}
                    disableAdd={this.state.disableAdd}
                    disableEdit={this.state.disableEdit}
                  />
                </div>
                {!this.state.isManual && (
                  <div
                    style={{
                      maxHeight: 350,
                      overflow: "auto",
                      maxWidth: !this.props.clearOpenList
                        ? "calc(100vw - 165px)"
                        : "calc(100vw - 390px)",
                    }}
                  >
                    {/* //overflow: "auto" */}
                    <Table
                      aria-labelledby="tableTitle"
                      size="small"
                      stickyHeader
                      aria-label="sticky table"
                    // style={{
                    //   maxHeight: 350,
                    //   overflow: "auto",
                    //   maxWidth: !this.props.clearOpenList
                    //     ? "calc(100vw - 165px)"
                    //     : "calc(100vw - 390px)",
                    // }}
                    >
                      {this.state.list && this.state.list.length > 0 && (
                        <EnhancedTableHead />
                      )}
                      {!this.state.loading ? (
                        <TableBody style={{ overflow: "hidden" }}>
                          {list.map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <TableRow
                                id={`tableRow${index}`}
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                              // style={{
                              //   paddingRight: "6px!important",
                              // }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  {index + 1}
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  style={{
                                    padding: "0 6px",
                                  }}
                                  className="table-content-cell"
                                >
                                  {row.acronym && row.acronym.length > 16 ? (
                                    <Tooltip
                                      title={row.acronym}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.acronym}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.acronym}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{
                                    padding: "0 6px",
                                  }}
                                >
                                  {row.volume && row.volume.length > 16 ? (
                                    <Tooltip
                                      title={row.volume}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.volume}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.volume}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{
                                    padding: "0 6px",
                                  }}
                                >
                                  {row.issue && row.issue.length > 16 ? (
                                    <Tooltip title={row.issue} aria-label="add">
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.issue}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.issue}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ padding: "0 6px" }}
                                >
                                  {row.supp && row.supp.length > 16 ? (
                                    <Tooltip title={row.supp} aria-label="add">
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.supp}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.supp}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ padding: "0 6px" }}
                                >
                                  {row.issueDescription &&
                                    row.issueDescription.length > 16 ? (
                                      <Tooltip
                                        title={row.issueDescription}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.issueDescription}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.issueDescription}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                  style={{ padding: "0 6px" }}
                                >
                                  {row.height && row.height.length > 16 ? (
                                    <Tooltip
                                      title={row.height}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.height}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.height}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                  style={{ padding: "0 6px" }}
                                >
                                  {row.pubYear && row.pubYear.length > 16 ? (
                                    <Tooltip
                                      title={row.pubYear}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.pubYear}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.pubYear}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ padding: "0 6px", width: "200px" }}
                                >
                                  {row.editClicked ? (
                                    <Autocomplete
                                      autoComplete
                                      disableClearable
                                      includeInputInList
                                      id={"locationCode-0"}
                                      className="locWidthClass"
                                      name="locationCode"
                                      onOpen={(e) =>
                                        this.handleAutoOpenClick(index)
                                      }
                                      hintText="locationCode"
                                      options={this.state.locationList}
                                      style={{ marginTop: "0px", minWidth: "85px" }}
                                      getOptionLabel={(option) => option.value}
                                      onChange={this.handleDDLChangeUpdated}
                                      value={row.locationCode}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          // autoFocus
                                          name="location"
                                          id="location"
                                          // style={{
                                          //   marginTop: "0px !important",
                                          //   minWidth: "126px"
                                          // }}
                                          style={{
                                            width: "100%",
                                            marginTop: "0px !important",
                                            fontSize: "11px !important",
                                            marginLeft: "0px",
                                            marginRight: ".5em",
                                            maxHeight: "400px",
                                            minWidth: "130px"
                                          }}
                                          required
                                        />
                                      )}
                                    />
                                  ) : row.locationCode &&
                                    row.locationCode.length > 10 ? (
                                        <Tooltip
                                          title={row.locationCode.value}
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.locationCode.value}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.locationCode.value}
                                        </Typography>
                                      )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                  style={{ padding: "0 6px" }}
                                >
                                  {row.editClicked ? (
                                    <TextField
                                      style={{
                                        fontSize: "12px!important",
                                      }}
                                      onKeyPress={this._onNumberKeyPress}
                                      defaultValue={row.receivedQty}
                                      inputProps={{
                                        "aria-label": "description",
                                        maxLength: 20,
                                      }}
                                      onChange={(e) => {
                                        this.onExcelDataChanged(
                                          e,
                                          "receivedQty",
                                          index
                                        );
                                      }}
                                      value={row.receivedQty}
                                    />
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.receivedQty}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                  style={{ padding: "0 6px" }}
                                >
                                  {row.editClicked ? (
                                    <TextField
                                      style={{
                                        fontSize: "12px!important",
                                      }}
                                      onKeyPress={this._onNumberKeyPress}
                                      defaultValue={row.varience}
                                      inputProps={{
                                        "aria-label": "description",
                                        maxLength: 20,
                                      }}
                                      onChange={(e) => {
                                        this.onExcelDataChanged(
                                          e,
                                          "varience",
                                          index
                                        );
                                      }}
                                      value={row.varience}
                                    />
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.varience}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ padding: "0 6px" }}
                                >
                                  {row.editClicked ? (
                                    <Input
                                      style={{
                                        fontSize: "12px!important",
                                        minWidth: "90px"
                                      }}
                                      onKeyPress={this._onAlphaKeyPress}
                                      defaultValue={row.receivedDate}
                                      inputProps={{
                                        "aria-label": "description",
                                        maxLength: 7,
                                      }}
                                      disabled={row.issue}
                                      onChange={(e) => {
                                        this.onExcelDataChanged(
                                          e,
                                          "receivedDate",
                                          index
                                        );
                                      }}
                                      value={row.receivedDate}
                                    />
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.receivedDate}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ padding: "0 6px" }}
                                >
                                  {row.editClicked ? (
                                    <Input
                                      style={{
                                        fontSize: "12px!important",
                                      }}
                                      onKeyPress={this._onAlphaKeyPress}
                                      defaultValue={row.remarks}
                                      inputProps={{
                                        "aria-label": "description",
                                        maxLength: 7,
                                      }}
                                      disabled={row.issue}
                                      onChange={(e) => {
                                        this.onExcelDataChanged(
                                          e,
                                          "remarks",
                                          index
                                        );
                                      }}
                                      value={row.remarks}
                                    />
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.remarks}
                                      </Typography>
                                    )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{
                                    padding: "0 6px",
                                    minWidth: "40px!important",
                                    maxWidth: "80px",
                                    width: "80px"
                                  }}
                                >
                                  {row.exceptionMessage &&
                                    row.exceptionMessage.length > 12 ? (
                                      <Tooltip
                                        title={row.exceptionMessage}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                            color: "red",
                                          }}
                                        >
                                          {row.exceptionMessage && row.exceptionMessage.slice(0, 13) + ".."}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                          color: "red",
                                        }}
                                      >
                                        {row.exceptionMessage}
                                      </Typography>
                                    )}
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        color: "#6c6b6b",
                                      }}
                                      onClick={(e) =>
                                        this.onEditClicked(e, index)
                                      }
                                    >
                                      {!row.editClicked && (
                                        <EditIcon
                                          style={{
                                            color: "#6c6b6b",
                                          }}
                                        />
                                      )}
                                    </span>
                                    {
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: "#6c6b6b",
                                        }}
                                        // disabled={!row.locationCode}
                                        onClick={(e) =>
                                          this.onDoneClicked(e, index, row)
                                        }
                                      >
                                        {" "}
                                        {row.editClicked &&
                                          row.acronym != "" &&
                                          row.volume != "" &&
                                          row.issue != "" &&
                                          row.replacementReprint != "" &&
                                          row.locationCode &&
                                          row.title != "" &&
                                          row.wpc != "" &&
                                          row.receivedQty != "" &&
                                          row.receivedQty != 0 &&
                                          row.printRun != "" ? (
                                            <CheckIcon
                                              style={{
                                                color: "#6c6b6b",
                                              }}
                                            />
                                          ) : null}
                                      </span>
                                    }
                                    <DeleteIcon
                                      style={{
                                        cursor: "pointer",
                                        color: "#6c6b6b",
                                      }}
                                      onClick={(e) =>
                                        this.onDeleteClicked(e, index, row)
                                      }
                                    />
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      ) : (
                          <Dialog
                            aria-labelledby="simple-dialog-title"
                            open={this.state.loading}
                          >
                            <div style={{ padding: "1em" }}>
                              <CircularProgress />
                            </div>
                          </Dialog>
                        )}
                    </Table>
                  </div>
                )}
                <div style={{ marginTop: "1em" }}>
                  {" "}
                  {this.state.varienceList &&
                    this.state.varienceList.length > 0 &&
                    this.state.isValidate === true &&
                    !this.state.isManual && (
                      <div>
                        <EnhancedTableToolbar1 />
                      </div>
                    )}
                  {this.state.varienceList &&
                    this.state.varienceList.length > 0 &&
                    this.state.isValidate === true &&
                    !this.state.isManual && (
                      <div
                        className={classes.container}
                        style={{
                          // width: "100%",
                          maxHeight: "400px",
                          margin: "0",
                          overflow: "auto"
                        }}
                      >
                        <Table
                          aria-labelledby="tableTitle"
                          size="small"
                          stickyHeader
                          aria-label="sticky table"

                        >
                          {this.state.varienceList &&
                            this.state.varienceList.length > 0 && (
                              <EnhancedTableHead1 />
                            )}

                          {!this.state.loading2 ? (
                            <TableBody style={{ overflow: "hidden" }}>
                              {this.state.varienceList &&
                                this.state.varienceList.length > 0 &&
                                this.state.varienceList.map((row, index) => {
                                  const labelId = `enhanced-table-checkbox-${index}`;
                                  return (
                                    <TableRow
                                      id={`tableRow${index}`}
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={index}
                                    // style={{
                                    //   paddingRight: "6px!important",
                                    // }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        {index + 1}
                                      </TableCell>

                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        style={{
                                          padding: "0 6px",
                                        }}
                                        className="table-content-cell"
                                      >
                                        {row.acronym &&
                                          row.acronym.length > 16 ? (
                                            <Tooltip
                                              title={row.acronym}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{
                                                  fontSize: "14px",
                                                }}
                                              >
                                                {row.acronym}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.acronym}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                        style={{
                                          padding: "0 6px",
                                        }}
                                      >
                                        {row.volume &&
                                          row.volume.length > 16 ? (
                                            <Tooltip
                                              title={row.volume}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{
                                                  fontSize: "14px",
                                                }}
                                              >
                                                {row.volume}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.volume}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                        style={{
                                          padding: "0 6px",
                                        }}
                                      >
                                        {row.issue && row.issue.length > 16 ? (
                                          <Tooltip
                                            title={row.issue}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{
                                                fontSize: "14px",
                                              }}
                                            >
                                              {row.issue}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.issue}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                        style={{ padding: "0 6px" }}
                                      >
                                        {row.supp && row.supp.length > 16 ? (
                                          <Tooltip
                                            title={row.supp}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{
                                                fontSize: "14px",
                                              }}
                                            >
                                              {row.supp}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.supp}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                        style={{ padding: "0 6px" }}
                                      >
                                        {row.issueDescription &&
                                          row.issueDescription.length > 16 ? (
                                            <Tooltip
                                              title={row.issueDescription}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{
                                                  fontSize: "14px",
                                                }}
                                              >
                                                {row.issueDescription}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.issueDescription}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="right"
                                        className="table-content-cell"
                                        style={{ padding: "0 6px" }}
                                      >
                                        {row.mlBalance &&
                                          row.mlBalance.length > 16 ? (
                                            <Tooltip
                                              title={row.mlBalance}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{
                                                  fontSize: "14px",
                                                }}
                                              >
                                                {row.mlBalance}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.mlBalance}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="right"
                                        className="table-content-cell"
                                        style={{ padding: "0 6px" }}
                                      >
                                        {row.receivedQty &&
                                          row.receivedQty.length > 16 ? (
                                            <Tooltip
                                              title={row.receivedQty}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{
                                                  fontSize: "14px",
                                                }}
                                              >
                                                {row.receivedQty}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.receivedQty}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="right"
                                        className="table-content-cell"
                                        style={{ padding: "0 6px" }}
                                      >
                                        {row.varience &&
                                          row.varience.length > 16 ? (
                                            <Tooltip
                                              title={row.varience}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{
                                                  fontSize: "14px",
                                                }}
                                              >
                                                {row.varience}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.varience}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="right"
                                        className="table-content-cell"
                                        style={{ padding: "0 6px" }}
                                      >
                                        {row.mlvarience &&
                                          row.mlvarience.length > 16 ? (
                                            <Tooltip
                                              title={row.mlvarience}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{
                                                  fontSize: "14px",
                                                }}
                                              >
                                                {row.mlvarience}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.mlvarience}
                                            </Typography>
                                          )}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          ) : (
                              <TableBody>
                                <TableRow>
                                  <TableCell colSpan="9">
                                    <LinearProgress />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            )}
                        </Table>
                      </div>
                    )}
                </div>
                {!this.state.isManual && (
                  <React.Fragment>
                    <Divider style={{ marginTop: "15px" }} />
                    <div
                      className="button-wrapper"
                      style={{ paddingBottom: "0.4em" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none", marginRight: "1em" }}
                        onClick={(e) => {
                          this.props.history.push("/backLabelStockIn");
                        }}
                      >
                        CANCEL
                      </Button>

                      {this.state.list &&
                        this.state.list.length > 0 &&
                        !this.checkOGSubmit() ? (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none", marginRight: "1em" }}
                            onClick={() => this.onValidateButtonClicked()}
                          >
                            VALIDATE
                          </Button>
                        ) : (
                          <Button
                            disabled
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none", marginRight: "1em" }}
                          >
                            VALIDATE
                          </Button>
                        )}
                      {!this.state.isManual && (
                        <React.Fragment>
                          <Button
                            disabled={
                              !this.state.varienceList.length ||
                                this.state.isValidate == false ||
                                this.checkOGSubmit() ||
                                this.state.isTicked
                                ? true
                                : false
                            }
                            autoFocus
                            color="primary"
                            variant="contained"
                            style={{ boxShadow: "none" }}
                            onClick={() => this.onFileUploadSwitch()}
                          >
                            SUBMIT
                          </Button>
                          <Dialog
                            disableBackdropClick
                            disableEscapeKeyDown
                            onClose={this.handleVarienceCancel}
                            aria-labelledby="customized-dialog-title"
                            open={this.state.fileUploadSubmitPopupOpen}
                            maxWidth="lg"
                          >
                            {/* <DialogTitle id="customized-dialog-title"></DialogTitle> */}
                            <DialogContent dividers>
                              <div style={{ minWidth: "300px" }}>
                                {"Are you sure you want to submit ?"}
                              </div>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                // autoFocus
                                onClick={this.handleVarienceCancel}
                                variant="contained"
                                color="primary"
                                //disableElevation
                                style={{ boxShadow: "none" }}
                              >
                                CLOSE
                              </Button>
                              {!this.state.submitClicked ? <Button
                                //autoFocus
                                onClick={() => this.handleVarienceSubmit()}
                                variant="contained"
                                color="primary"
                                //disableElevation
                                style={{ boxShadow: "none" }}
                              >
                                SUBMIT
                              </Button> : <div style={{ width: "65px", height: "45px" }}><CircularProgress /></div>}
                            </DialogActions>
                          </Dialog>
                        </React.Fragment>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </div>{" "}
            </div>

            {this.state.isManual && (
              <React.Fragment>
                <Divider />
                <div className={classes.container}>
                  <div style={{ marginTop: "15px" }}>
                    <div className={classes.container}>
                      <p
                        className={classes.subHeader}
                        style={{
                          fontWeight: "500",
                        }}
                      >
                        Create Manual Stock In
                      </p>
                      <Divider />
                      <div style={{ display: "flex", alignItems: "baseline" }}>
                        <p
                          className={classes.subHeader}
                          style={{
                            marginRight: "0.7em",
                            display: "inline-block",
                            verticalAlign: "middle",
                          }}
                        >
                          Journal Details
                        </p>

                        <Button
                          disabled={isManual ? false : true}
                          onClick={this.handleAddJournalClick}
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "172px",
                            padding: ".2em",
                            marginTop: "1em",
                            boxShadow: "none",
                          }}
                        >
                          <span>CHOOSE JOURNAL</span>
                        </Button>
                        {this.state.openDialog && (
                          <IndividualSearchAddDialog
                            title="Journal Pick Up"
                            showDialog={this.state.openDialog}
                            handleAddJournalClose={this.handleAddJournalClose}
                            handleJournalUpdate={this.handleJournalUpdate}
                          />
                        )}
                      </div>
                      <div style={{ display: "flex" }}>
                        <TextField
                          required
                          label="Acronym"
                          name="acronym"
                          inputProps={{
                            maxLength: 4,
                            readOnly: true,
                          }}
                          style={{}}
                          onKeyPress={this._freetext}
                          margin="normal"
                          variant="filled"
                          className={classes.textField}
                          value={journalData.acronym}
                          onChange={this.handleChange}
                        />
                        <TextField
                          required
                          label="Volume"
                          name="volume"
                          inputProps={{
                            maxLength: 4,
                            readOnly: true,
                          }}
                          style={{}}
                          onKeyPress={this._freetext}
                          margin="normal"
                          variant="filled"
                          className={classes.textField}
                          value={journalData.volume}
                          onChange={this.handleChange}
                        />
                        <TextField
                          required={!this.state.supp}
                          label="Issue"
                          name="issue"
                          inputProps={{
                            maxLength: 7,
                            readOnly: true,
                          }}
                          className={[
                            classes.textField,
                            classes.lastChild,
                          ].join(" ")}
                          variant="filled"
                          onKeyPress={this._freetext}
                          margin="normal"
                          value={journalData.issue}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <TextField
                          required={!this.state.iss}
                          label="Supp"
                          name="supplement"
                          inputProps={{
                            maxLength: 7,
                            readOnly: true,
                          }}
                          style={{
                            fontSize: "11px !important",
                          }}
                          onKeyPress={this._freetext}
                          margin="normal"
                          variant="filled"
                          className={classes.textField}
                          value={journalData.supplement}
                          onChange={this.handleChange}
                        />
                        <TextField
                          required
                          label="Issue Description"
                          name="issueDescription"
                          inputProps={{
                            maxLength: 100,
                            readOnly: true,
                          }}
                          style={{}}
                          onKeyPress={this._freetext}
                          margin="normal"
                          variant="filled"
                          className={classes.textField}
                          value={journalData.issueDescription}
                          onChange={this.handleChange}
                        />
                        <div
                          className={[
                            classes.emptyField,
                            classes.lastChild,
                          ].join(" ")}
                        ></div>
                      </div>
                      {this.state.wlArray && this.state.wlArray.length > 0 && <div className="view-page-headings" style={{ height: "35px", fontSize: "17px" }}>Stock In Information</div>}
                      {/* <div className="view-page-contents-container"></div> */}
                      {this.state.wlArray && this.state.wlArray.length > 0 && <Divider
                        style={{
                          marginTop: "25px",
                        }}
                      />}
                      {this.state.wlArray && this.state.wlArray.length > 0 && <div style={{ maxHeight: 500, overflowY: "auto" }}>
                        <Table
                          aria-labelledby="tableTitle"
                          size="small"
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell padding="default" className="headcells">
                                {" "}
                                <span style={{ color: "#306dca" }}>

                                </span>{" "}
                              </TableCell>
                              <TableCell padding="default" className="headcells">
                                {" "}
                                <span style={{ color: "#306dca" }}>
                                  Warehouse Location Name
                          </span>{" "}
                              </TableCell>
                              <TableCell padding="default" className="headcells">
                                {" "}
                                <span style={{ color: "#306dca" }}>
                                  Stock Type
                          </span>{" "}
                              </TableCell>
                              <TableCell padding="default" className="headcells">
                                {" "}
                                <span style={{ color: "#306dca" }}>
                                  Balance Qty
                          </span>{" "}
                              </TableCell>

                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              this.state.wlArray && this.state.wlArray.length > 0 && this.state.wlArray.map((ja, i) => {
                                return (
                                  ja && <TableRow >
                                    <TableCell className="table-content-cell" component="th" style={{ padding: "0px .5em", width: "20px" }}
                                      scope="row"
                                      padding="default"> <FormControlLabel
                                        control={
                                          <CustomCheckbox
                                            color="primary"
                                            checked={ja.checkedVal ? ja.checkedVal : false}
                                            onClick={e => this.onCkBxClicked(e, i, ja)}
                                          />
                                        }
                                      /> </TableCell>
                                    <TableCell className="table-content-cell" component="th" style={{ padding: ".5em" }}
                                      scope="row"
                                      padding="default">{ja.warehouseLocationName} </TableCell>
                                    <TableCell className="table-content-cell" component="th" style={{ padding: ".5em" }}
                                      scope="row"
                                      padding="default">{ja.stockType}</TableCell>
                                    <TableCell className="table-content-cell" component="th" style={{ padding: ".5em" }}
                                      scope="row"
                                      padding="default">{ja.balanceQty}</TableCell>

                                  </TableRow>
                                )
                              })
                            }
                          </TableBody>
                        </Table>
                      </div>}
                      {/* <p
                        className={classes.subHeader}
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                          marginBottom: "0",
                        }}
                      >
                        Stock In Details
                      </p> */}

                      <div className="view-page-headings" style={{ height: "35px", marginBottom: 0, fontSize: "17px" }}>Stock In Details</div>
                      {/* <Divider
                        style={{
                          marginTop: "25px",
                        }}
                      /> */}

                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <Autocomplete
                          options={this.state.stockTypeList}
                          value={this.state.stockType}
                          disabled={!this.state.journalData.journalID}
                          onChange={this.handleStockTypeChange}
                          getOptionLabel={(option) => option.text}
                          style={{
                            flex: 1,
                            marginRight: "16px"
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Stock Type"
                              margin="normal"
                              required
                              fullWidth
                            />
                          )}
                        />
                        <Autocomplete
                          options={this.state.locationList}
                          value={this.state.location}
                          disabled={!this.state.stockTypeId}
                          onChange={this.handleLocationChange}
                          getOptionLabel={(option) => option.text}
                          style={{
                            flex: 1,
                            marginRight: "16px"
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Location"
                              margin="normal"
                              required
                              fullWidth
                            />
                          )}
                        />
                        <Autocomplete
                          autoComplete
                          style={{
                            flex: 1
                          }}
                          // disabled
                          id="reason"
                          // inputProps={{ readOnly: true }}
                          includeInputInList
                          options={this.state.stockReasonDD}
                          value={this.state.reason}
                          onChange={this.handleReasonChange}
                          getOptionLabel={(option) => option.text}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Reason"
                              margin="normal"
                              // inputProps={{ readOnly: true }}

                              fullWidth
                            />
                          )}
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <TextField
                          required
                          label="Received Qty"
                          name="recievedQty"
                          inputProps={{
                            maxLength: 10,
                          }}
                          style={{
                            flex: 1,
                            marginRight: "16px"
                          }}
                          onKeyPress={this._onNumberKeyPress}
                          margin="normal"
                          onChange={this.handleInputChange}
                          disabled={!isManual ? true : false}
                          value={this.state.recievedQty}
                        />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            autoOk
                            variant="inline"
                            inputProps={{ readOnly: true }}
                            format="dd/MM/yyyy"
                            margin="normal"
                            style={{
                              flex: 1,
                              marginRight: "16px"
                            }}
                            disabled={!isManual ? true : false}
                            // disabled
                            id="recievedDate"
                            label="Received Date"
                            onChange={this.handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            value={this.state.recievedDate}
                          />
                        </MuiPickersUtilsProvider>
                        <TextField

                          label="Remarks"
                          name="remarks"
                          inputProps={{
                            maxLength: 50,
                          }}
                          style={{
                            flex: 1,
                            // marginRight: "16px"
                          }}
                          // onKeyPress={this._onNumberKeyPress}
                          margin="normal"
                          onChange={this.handleInputChange}
                          disabled={!isManual ? true : false}
                          value={this.state.remarks}
                        />
                        {/* <div style={{ flexGrow: 1 }}></div> */}
                      </div>
                      <Divider style={{ marginTop: "15px" }} />
                      <div
                        style={{
                          display: "flex",
                          marginTop: "1em",
                          marginBottom: "0",
                          alignItems: "center"
                        }}
                      >
                        <div style={{}}>
                          <Button
                            color="primary"
                            variant="contained"
                            style={{ boxShadow: "none" }}
                            onClick={(e) => {
                              this.props.history.push("/backLabelStockIn");
                            }}
                          >
                            CANCEL
                          </Button>
                        </div>

                        <div style={{ marginLeft: "1em" }}>
                          {this.state.isManual ? (
                            !this.state.eMsg ? <Button
                              disabled={
                                !this.state.journalData.volume ||
                                  !this.state.journalData.issueDescription ||
                                  !this.state.journalData.acronym ||
                                  (!this.state.journalData.issue &&
                                    !this.state.journalData.supplement) ||
                                  !this.state.location ||
                                  !this.state.stockType ||
                                  (this.state.location
                                    ? Object.keys(this.state.location).length ===
                                    0
                                    : 1) ||
                                  (this.state.stockType
                                    ? Object.keys(this.state.stockType).length ===
                                    0
                                    : 1) || !this.state.recievedQty || this.state.recievedQty == 0
                                  ? true
                                  : false
                              }

                              autoFocus
                              color="primary"
                              variant="contained"
                              onClick={(e) => this.handleSubmit(e, true)}
                            // className={
                            //   this.state.list.length == 0 &&
                            //     !this.state.journalData.volume == "" &&
                            //     !this.state.journalData.issueDescription ==
                            //     "" &&
                            //     !this.state.journalData.acronym == "" &&
                            //     (!this.state.journalData.issue == "" ||
                            //       !this.state.journalData.supplement == "") &&
                            //     !this.state.location &&
                            //     !this.state.stockType &&
                            //     (this.state.location
                            //       ? Object.keys(this.state.location).length ===
                            //       0
                            //       : 1) &&
                            //     (this.state.stockType
                            //       ? Object.keys(this.state.stockType).length ===
                            //       0
                            //       : 1) &&
                            //     !this.state.recievedQty
                            //     ? "disabled-button"
                            //     : "enabled-button "
                            // }
                            // style={{ boxShadow: "none" }}
                            >
                              SUBMIT
                            </Button>
                              :
                              <span style={{ alignItems: "center", color: "red" }}>Stock In is already done for this Warehouse Location for another Journal </span>

                          ) : (
                              <Button
                                disabled={this.checkOGSubmit() ? true : false}
                                autoFocus
                                color="primary"
                                variant="contained"
                                style={{ boxShadow: "none" }}
                                onClick={(e) => this.handleSubmit(e, true)}
                              >
                                SUBMIT
                              </Button>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </Paper>
      </React.Fragment >
    );
  }
}
export default withRouter(withStyles(styles)(StockInPublisher));
