import React from "react";
import { withRouter } from "react-router-dom";
import {
  TextField,
  Button,
  Paper,
  Divider,
  FormControlLabel,
  Typography,
  InputBase,
  Checkbox,
  Switch,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import SyncIcon from "@material-ui/icons/Sync";
import { CustomAddIcon, PrintIcon } from "../../../shared/customIcons";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import ReportViewer from "../../app/ReportViewer";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SearchIcon from "@material-ui/icons/Search";
import {
  GetDisplayWOInJG,
  getStockList,
  postStockIndent,
  updateJobGroupStocks,
  //getStockDispatchedStatus,
  GetJobGroupsListByWOID,
  postJobGroupStocks,
  GetJobGroupStockListByJobGroupID,
  deleteJobGroupStock,
  getStockIndentForJG
} from "./action";
import {
  getProductionLineList,
  getProductionLineListDD,
} from "../../masters/masterActions";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "96%",
    fontSize: "11px !important",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "29%",
    fontSize: "11px !important",
  },
  autocompleteText: {
    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
  search: {
    position: "relative",
    minWidth: "500px",
    display: "flex",
    // "&:onChange": {
    //     color: blue[800]
    //   }
  },
  comboBox: {
    margin: "normal",
    width: "31%",
    fontSize: "11px !important",
  },
  searchIcon: {
    position: "absolute",
    right: "0",
  },
});
let workOrderMode = "";
class Stock extends React.Component {
  constructor() {
    super();
    this.state = {
      idForStkreport: "",
      showPopup: false,
      jobGroupStockID: 0,
      jgStkIdArr: [],
      stockPopupOpen: false,
      doStockIndentPopupOpen: false,
      stocksList: [],
      filteredStocksList: [],
      doIntentEnable: false,
      resetEnable: false,
      productionLineList: [],
      selectedStocksList: [],
      changesUpdated: false,
      intentChangesUpdated: false,
      selected: [],
      customerID: 0,
      errors: {},
      filterStr: "",
      woDisplayDetails: {
        workOrderID: "",
        customerName: "",
        workOrderNumber: "",
        projectTitle: "",
        jobGroupNumber: "",
      },
      formValid: false,
      indentDetailList: [],
      createIndent: {
        indentHeaderID: 0,
        jobGroupID: 0,
        productionLineID: 0,
        indentSuffix: "string",
        createdBy: 0,
      },
    };
  }
  getSIUponJG = () => {
    let jobGroupID = this.props.jobGroupID;
    getStockIndentForJG(jobGroupID).then(r => {
      if (r && r.data)
        this.setState({ jgStkIdArr: r.data })
    })
  }
  componentDidMount() {
    this.props.handleDrawerClose();
    let workOrderID = this.props.workOrderID;
    let jobGroupID = this.props.jobGroupID;
    workOrderMode =
      this.props.location.state && this.props.location.state.workOrderMode;

    getProductionLineListDD().then((r) => {
      r.data &&
        this.setState({
          productionLineList: r.data.sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
          ),
        });
    });
    this.getSIUponJG()
    // getStockDispatchedStatus().then(r => {
    //   r.data &&
    //     this.setState({
    //       statusList: r.data.sort((a, b) =>
    //         a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
    //       )
    //     });
    // });

    GetJobGroupStockListByJobGroupID(jobGroupID).then((r) => {
      let enable = r.length > 0 ? true : false;
      let stockList = [];
      //productionList = this.state.productionLineList;
      r &&
        r.forEach(function (item) {
          // item.productionLine =
          //   productionList &&
          //   productionList.find(
          //     x => x.productionLineID === item.productionLineID
          //   );
          item.indentQuantity = 0;
          item.errors = {
            //   productionLine: "",
            indentQuantity: "",
          };
          stockList.push(item);
        });

      this.setState({ selectedStocksList: stockList, doIntentEnable: enable });
    });

    GetDisplayWOInJG(workOrderID, this.props.jobGroupID).then((r) => {
      this.setState({ woDisplayDetails: r });
      getStockList(r.customerID).then((x) => {
        let newFiltered = this.GetNewFilteredStocksList(x.data);

        x.data &&
          this.setState({
            stocksList: x.data.sort((a, b) =>
              a.stockName.toLowerCase() > b.stockName.toLowerCase() ? 1 : -1
            ),
            filteredStocksList: newFiltered.sort((a, b) =>
              a.stockName.toLowerCase() > b.stockName.toLowerCase() ? 1 : -1
            ),
          });
      });
    });

    if (this.state.filterStr === "") {
      let newFiltered = this.GetNewFilteredStocksList(this.state.stocksList);
      this.setState({ filteredStocksList: newFiltered });
    }
  }

  handlestockPopupOpen = () => {
    this.setState({
      stockPopupOpen: true,
      selected: [],
      //filteredStocksList: this.state.stocksList
    });
  };

  handleDoStockIndentPopupOpen = () => {
    this.setState({
      doStockIndentPopupOpen: true,
      formValid: false
    });
  };

  GetNewFilteredStocksList = (stocksList) => {
    let newFiltered = [];
    if (stocksList && stocksList.length > 0) {
      newFiltered = stocksList;
    } else {
      newFiltered = this.state.stocksList;
    }

    this.state.selectedStocksList.forEach(function (item) {
      const index = newFiltered.findIndex((x) => x.stockID === item.stockID);
      if (index > -1) {
        newFiltered = newFiltered
          .slice(0, index)
          .concat(newFiltered.slice(index + 1, newFiltered.length));
      }
    });

    newFiltered = newFiltered.sort((a, b) =>
      a.stockName.toLowerCase() > b.stockName.toLowerCase() ? 1 : -1
    );
    return newFiltered;
  };

  handleStockPopupClose = () => {
    let newFiltered1 = this.GetNewFilteredStocksList(this.state.stocksList);
    this.setState({ filteredStocksList: newFiltered1 });
    this.setState({
      stockPopupOpen: false,
      selected: [],
      //filteredStocksList: this.state.stocksList
    });
  };

  handleDoStockIndentPopupClose = () => {
    this.setState({
      doStockIndentPopupOpen: false,
    });
  };

  handleChange = (event, index, indentQuantity) => {
    const { selectedStocksList, createIndent } = this.state;
    let value = event.target.value,
      outstandingQty = 0,
      balanceQty = selectedStocksList[index].balanceQty;
    let checkSubmitBtnEnabled = true
    if (value === "" || parseInt(value) < 0) {
      selectedStocksList[index]["errors"]["indentQuantity"] =
        "Please enter valid indent quantity";
      this.setState({ formValid: false });
    } else {
      outstandingQty = parseInt(value) - parseInt(balanceQty);
      if (balanceQty > parseInt(value)) {
        selectedStocksList[index]["outstandingQty"] = 0;
      } else {
        selectedStocksList[index]["outstandingQty"] = outstandingQty;
      }
      if (
        createIndent.productionLineID !== null &&
        createIndent.productionLineID !== 0
      ) {
        this.setState({ formValid: true });
      }
      selectedStocksList[index]["errors"]["indentQuantity"] = "";
    }

    selectedStocksList[index]["indentQuantity"] = value;

    this.setState({ selectedStocksList, intentChangesUpdated: true }, () => {
      let validCh = false
      this.state.selectedStocksList.map(t => {
        if (t.indentQuantity > 0) {
          validCh = true
        }
        // if (t.indentQuantity === "" || t.indentQuantity < 0)
        checkSubmitBtnEnabled = validCh
      })
      if (
        createIndent.productionLineID !== null &&
        createIndent.productionLineID !== 0
      )
        this.setState({ formValid: checkSubmitBtnEnabled })
      else
        this.setState({ formValid: false })

    });
  };

  // handleChange = (event, index, balanceQty) => {
  //   const { selectedStocksList } = this.state;
  //   if (event.target.name === "doIndent") {
  //     selectedStocksList[index][event.target.name] = event.target.checked;
  //     if (event.target.checked) {
  //       //selectedStocksList[index]["statusID"] = this.state.statusList[1].id;
  //       //selectedStocksList[index]["statusName"] = this.state.statusList[1].text;
  //       if (
  //         selectedStocksList[index]["indentQuantity"] === "" ||
  //         parseInt(selectedStocksList[index]["indentQuantity"]) <= 0
  //       ) {
  //         selectedStocksList[index]["errors"]["indentQuantity"] =
  //           "Please enter valid stockout quantity";
  //         this.setState({ formValid: false });
  //       }

  //       if (
  //         selectedStocksList[index]["productionLine"] === null ||
  //         selectedStocksList[index]["productionLine"] === undefined ||
  //         selectedStocksList[index]["productionLine"] === ""
  //       ) {
  //         selectedStocksList[index]["errors"]["productionLine"] =
  //           "Please select production line";
  //         this.setState({ formValid: false });
  //       }
  //     } else {
  //       selectedStocksList[index]["statusID"] = this.state.statusList[2].id;
  //       selectedStocksList[index]["statusName"] = this.state.statusList[2].text;
  //       selectedStocksList[index]["errors"]["productionLine"] = "";
  //       selectedStocksList[index]["errors"]["indentQuantity"] = "";
  //       this.setState({ formValid: true });
  //     }
  //   } else {
  //     selectedStocksList[index][event.target.name] = event.target.value;
  //     if (event.target.value === "") {
  //       selectedStocksList[index]["errors"][event.target.name] =
  //         "Please enter valid stockout quantity";
  //       this.setState({ formValid: false });
  //     } else {
  //       selectedStocksList[index]["errors"][event.target.name] = "";
  //       this.setState({ formValid: true });
  //     }
  //     // else if (parseInt(event.target.value) <= balanceQty) {
  //     //   selectedStocksList[index]["errors"][event.target.name] = "";
  //     //   this.setState({ formValid: true });
  //     // } else {
  //     //   selectedStocksList[index]["errors"][event.target.name] =
  //     //     "StockOut quantity should not exceed " + balanceQty;
  //     //   this.setState({ formValid: false });
  //     // }
  //   }
  //   this.setState({ selectedStocksList, changesUpdated: true });
  // };

  handleAddStockPopupSubmit = () => {
    let { selected, stocksList } = {
      ...this.state,
    };

    let newSelectedStocksList = [],
      newStocksList = [],
      newStock = {};

    let jobGroupStockID = 0,
      jobGroupID = this.props.jobGroupID;
    const selectedStocksList = this.state.selectedStocksList;
    selected.forEach(function (item) {
      let stock = stocksList.find((x) => x.stockID === item);
      let boolVal = selectedStocksList.filter((r) => {
        return r.stockID == stock.stockID;
      });
      if (boolVal.length == 0) {
        if (stock && stock !== 0) {
          newStock = {
            jobGroupStockID: jobGroupStockID,
            jobGroupID: jobGroupID,
            stockID: stock.stockID,
            indentQuantity: 0,
            createdBy: localStorage.loggedInId,
            isActive: true,
          };
          newSelectedStocksList.push(newStock);
        }
      }
    });

    if (newSelectedStocksList.length > 0) {
      postJobGroupStocks(
        this.props.workOrderID,
        jobGroupID,
        newSelectedStocksList,
        this.props.history,
        workOrderMode
      ).then((r) => {
        r &&
          r.forEach(function (item) {
            item.indentQuantity = 0;
            item.errors = {
              indentQuantity: "",
            };
            newStocksList.push(item);
          });

        this.setState({ selectedStocksList: newStocksList }, () => {
          let newFiltered1 = this.GetNewFilteredStocksList(this.state.stocksList);
          this.setState({ filteredStocksList: newFiltered1 });
        });

      });
    }



    this.setState({
      //selectedStocksList: newSelectedStocksList,
      stockPopupOpen: false,
      selected: [],
      resetEnable: true,
      doIntentEnable: true,
      changesUpdated: true,
    });
  };

  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleClick = (event, row) => {
    let selected = this.state.selected;
    const selectedIndex = selected.indexOf(row.stockID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.stockID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selected: newSelected });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = this.state.filteredStocksList.map((n) => n.stockID);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleChangeSearchStock = (event) => {
    let value = event.target.value;
    this.setState({ filterStr: value });
    if (value === "") {
      let newFiltered = this.GetNewFilteredStocksList(this.state.stocksList);
      this.setState({ filteredStocksList: newFiltered });
    }
  };

  handleSearchStock = (event) => {
    let { filterStr, filteredStocksList } = this.state;
    let filter = filteredStocksList.filter((r) => {
      return (
        //r.stockID.toString().includes(filterStr) ||
        r.stockCode.toLowerCase().includes(filterStr.toLowerCase()) ||
        r.stockName.toLowerCase().includes(filterStr.toLowerCase()) ||
        r.stockType.toLowerCase().includes(filterStr.toLowerCase()) ||
        r.balanceQty.toString().includes(filterStr)
      );
    });
    this.setState({ filteredStocksList: filter });
  };

  handleStockSubmit = (event) => {
    if (this.state.changesUpdated) {
      let stockList = [],
        jobGroupStockID,
        stock = {};
      let jobGroupID = this.props.jobGroupID;

      this.state.selectedStocksList.forEach(function (item) {
        let jobGroupStockID = 0;
        if (item.jobGroupStockID) {
          jobGroupStockID = item.jobGroupStockID;
        }

        stock = {
          jobGroupStockID: jobGroupStockID,
          jobGroupID: jobGroupID,
          stockID: item.stockID,
          IndentQuantity: item.totalIndentQty,
          modifiedBy: localStorage.loggedInId,
          isActive: true,
        };
        stockList.push(stock);
      });

      updateJobGroupStocks(
        this.props.workOrderID,
        jobGroupID,
        stockList,
        this.props.history,
        workOrderMode
      );

      // postJobGroupStocks(
      //   this.props.workOrderID,
      //   jobGroupID,
      //   stockList,
      //   this.props.history,
      //   workOrderMode
      // );
    } else {
      this.props.history.push({
        pathname: "/schedule",
        search:
          "?workOrderID=" +
          this.props.workOrderID +
          "&jobGroupID=" +
          this.props.jobGroupID,
        state: { workOrderMode: workOrderMode },
      });
    }
  };

  handleAddStockIndentPopupSubmit = () => {
    if (this.state.formValid) {
      const { createIndent } = this.state;
      let indentDetailList = [],
        reqBody = {},
        newStocksList = [],
        indentDetails = {};
      let stockoutQty = 0;
      this.state.selectedStocksList.forEach(function (item) {
        if (item.balanceQty > parseInt(item.indentQuantity)) {
          stockoutQty = parseInt(item.indentQuantity);
        } else {
          stockoutQty = item.balanceQty;
        }

        indentDetails = {
          indentDetailID: 0,
          stockID: item.stockID,
          outstandingQty: item.outstandingQty,
          IndentQty: item.indentQuantity,
          createdBy: localStorage.loggedInId,
          stockOutQty: stockoutQty,
        };
        indentDetailList.push(indentDetails);
      });

      if (indentDetailList.length > 0) {
        let productionLineID = createIndent.productionLineID;
        let indentSuffix = "";
        let jobGroupID = this.props.jobGroupID;

        reqBody = {
          indentHeaderID: 0,
          jobGroupID,
          productionLineID,
          indentSuffix,
          createdBy: localStorage.loggedInId,
          indentDetailList,
        };

        postStockIndent(reqBody).then((r) => {
          r && r.length > 0 &&
            r.forEach(function (item) {
              item.indentQuantity = 0;
              item.errors = {
                indentQuantity: "",
              };
              newStocksList.push(item);
            });
          // createIndent.productionLineID = null
          this.setState({ selectedStocksList: newStocksList });
          // Added for refreshing do intendt list.................................
          let jobGroupID = this.props.jobGroupID;
          GetJobGroupStockListByJobGroupID(jobGroupID).then((r) => {
            let enable = r.length > 0 ? true : false;
            let stockList = [];
            r &&
              r.forEach(function (item) {
                item.indentQuantity = 0;
                item.errors = {
                  indentQuantity: "",
                };
                stockList.push(item);
              });

            this.setState({
              selectedStocksList: stockList,
              doIntentEnable: enable,
            });
            this.getSIUponJG()
          });
          //........................................................................
        });
        this.setState({
          doStockIndentPopupOpen: false,
          changesUpdated: true,
        });
      }
    }
  };

  handleDeleteClicked = (event, index, row) => {
    console.log(index)
    let { selectedStocksList, stocksList, filteredStocksList } = this.state;
    if (
      row.jobGroupStockID !== undefined &&
      row.jobGroupStockID !== null &&
      row.jobGroupStockID !== 0
    ) {
      deleteJobGroupStock(row.jobGroupStockID);
    }
    selectedStocksList = selectedStocksList
      .slice(0, index)
      .concat(selectedStocksList.slice(index + 1, selectedStocksList.length));

    const stockIndex = filteredStocksList.findIndex(
      (x) => x.stockID === row.stockID
    );

    if (stockIndex === -1) {
      let newStock = stocksList.find((x) => x.stockID === row.stockID);
      if (newStock && newStock !== 0) {
        filteredStocksList.push(newStock);
      }
    }

    if (selectedStocksList.length === 0) {
      this.setState({
        resetEnable: false,
        changesUpdated: true,
        doIntentEnable: false,
      });
    }
    this.setState({
      selectedStocksList,
      filteredStocksList: filteredStocksList.sort((a, b) =>
        a.stockName.toLowerCase() > b.stockName.toLowerCase() ? 1 : -1
      ),
    });
  };

  handleClickResetButton = (event) => {
    let { stocksList, filteredStocksList } = this.state;
    let newStocksList = this.state.selectedStocksList,
      newSelectedList = [];

    newStocksList.forEach(function (item, index) {
      if (
        item.jobGroupStockID !== undefined &&
        item.jobGroupStockID !== null &&
        item.jobGroupStockID !== 0
      ) {
        newSelectedList.push(item);
      }

      const stockIndex = filteredStocksList.findIndex(
        (x) => x.stockID === item.stockID
      );

      if (stockIndex === -1) {
        let newStock = stocksList.find((x) => x.stockID === item.stockID);
        if (newStock && newStock !== 0) {
          filteredStocksList.push(newStock);
        }
      }
    });

    //let newFiltered = this.GetNewFilteredStocksList(this.state.stocksList);
    this.setState({
      filteredStocksList: filteredStocksList.sort((a, b) =>
        a.stockName.toLowerCase() > b.stockName.toLowerCase() ? 1 : -1
      ),
      selectedStocksList: newSelectedList,
      changesUpdated: true,
      resetEnable: false,
      doIntentEnable: false,
      //filteredStocksList: newFiltered
    });
  };

  handlCancelClicked = (event, workOrderMode) => {
    // if (workOrderMode === "edit") {
    //   this.props.history.push({
    //     pathname: "/edit_WorkOrder",
    //     search: "?workOrderID=" + this.props.workOrderID,
    //     state: { workOrderMode: this.props.workOrderMode }
    //   });
    // } else if (workOrderMode === "view") {
    //   this.props.history.push({
    //     pathname: "/view_WorkOrder",
    //     search: "?workOrderID=" + this.props.workOrderID,
    //     state: { workOrderMode: this.props.workOrderMode }
    //   });
    // } else {
    //   this.props.history.push("/workOrders");
    // }
    // this.props.history.push("/workOrders");
    GetJobGroupsListByWOID(this.props.workOrderID).then((r) => {
      if (r && r.length === 1) {
        this.props.history.push("/workOrders")
      }
      else if (workOrderMode === "view")
        // this.props.history.push("/view_WorkOrder?workOrderID=114")
        this.props.history.push({
          pathname: "/view_WorkOrder",
          search: "?workOrderID=" + this.props.workOrderID,
          state: { workOrderMode: workOrderMode },
        });
      else if (workOrderMode === "edit")
        // this.props.history.push("/view_WorkOrder?workOrderID=this.props.workOrderID")
        this.props.history.push({
          pathname: "/edit_WorkOrder",
          search: "?workOrderID=" + this.props.workOrderID,
          state: { workOrderMode: workOrderMode },
        });
      else if (this.props.workOrderMode === "create") {
        this.props.history.push("/workOrders")
      }
    });
  };

  handleProductionLineChange = (event, values, index) => {
    const { createIndent } = this.state;
    let checkSubmitBtnEnabled = true
    if (values != null) {
      let productionLineID = values && values.id;
      createIndent["productionLineID"] = productionLineID;
      this.setState({
        createIndent,
        intentChangesUpdated: true,
        // formValid: true,
      });
      let validCh = false
      this.state.selectedStocksList.map(t => {
        if (t.indentQuantity > 0) {
          validCh = true
        }
        // if (t.indentQuantity === "" || t.indentQuantity < 0)
        checkSubmitBtnEnabled = validCh
      })
      this.setState({
        // createIndent,
        // intentChangesUpdated: true,
        formValid: checkSubmitBtnEnabled,
      });
    } else {
      createIndent["productionLineID"] = null;
      this.setState({ createIndent, formValid: false });
    }
  };
  handleShowPopup = (e, id) => {
    this.setState({
      showPopup: true,
      idForStkreport: id
    });
  };

  handleHidePopup = () => {
    this.setState({
      showPopup: false,
      idForStkreport: ""
    });
  };
  render() {
    let {
      filteredStocksList,
      productionLineList,
      selected,
      selectedStocksList,
      formValid,
      woDisplayDetails,
    } = this.state;
    let { classes, workOrderMode } = this.props;
    const numSelected = selected.length,
      rowCount = filteredStocksList.length;
    const isSelected = (stockID) =>
      selected && selected.indexOf(stockID) !== -1;

    return (
      <div className={classes.root}>
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <Typography
                component="div"
                className="blueBox"
                style={{ width: "auto" }}
              >
                <div className="blueBoxLabel">
                  <div>Customer: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.customerName}
                  </div>
                </div>
                <div className="blueBoxLabel">
                  <div>Work Order: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.workOrderNumber}
                  </div>
                </div>
                <div className="blueBoxLabel">
                  <div>Project Title: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.projectTitle}
                  </div>
                </div>
              </Typography>
              <Typography
                component="div"
                className="blueBox"
                style={{ marginTop: "2px", width: "auto" }}
              >
                <div className="blueBoxLabel">
                  <div>Job Group: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.jobGroupNumber}
                  </div>
                </div>
              </Typography>
            </div>

            <Paper style={{ width: "100%", marginTop: "2%" }}>
              {workOrderMode !== "view" && this.props.status != "Completed" && (
                <div
                  style={{
                    display: "flex",
                    margin: "4 .8em 5 .6em",
                  }}
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        boxShadow: "none",
                        padding: "2px",
                        margin: ".5em",
                        fontSize: "11px",
                      }}
                      onClick={this.handlestockPopupOpen}
                    >
                      <CustomAddIcon style={{ fontSize: "18px" }} /> Add
                    </Button>
                    {/* {!this.state.resetEnable ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{
                          boxShadow: "none",
                          padding: "2px",
                          margin: ".5em",
                          fontSize: "11px",
                        }}
                        onClick={this.handleClickResetButton}
                      >
                        <SyncIcon style={{ fontSize: "18px" }} /> Reset
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            boxShadow: "none",
                            padding: "2px",
                            margin: ".5em",
                            fontSize: "11px",
                          }}
                          onClick={this.handleClickResetButton}
                        >
                          <SyncIcon style={{ fontSize: "18px" }} /> Reset
                        </Button>
                      )} */}

                    {!this.state.doIntentEnable ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{
                          boxShadow: "none",
                          padding: "2px",
                          margin: ".5em",
                          fontSize: "11px",
                        }}
                        onClick={this.handleDoStockIndentPopupOpen}
                      >
                        <SyncIcon style={{ fontSize: "18px" }} /> Do Indent
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            boxShadow: "none",
                            padding: "2px",
                            margin: ".5em",
                            fontSize: "11px",
                          }}
                          onClick={this.handleDoStockIndentPopupOpen}
                        >
                          <SyncIcon style={{ fontSize: "18px" }} /> Do Indent
                        </Button>
                      )}
                  </div>
                  <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.stockPopupOpen}
                    onClose={this.handleStockPopupClose}
                    maxWidth="lg"
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">Add Stock</DialogTitle>
                    <DialogContent dividers>
                      <div className={classes.search}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          autoComplete="off"
                          onChange={this.handleChangeSearchStock}
                          placeholder="Search Stock Code, Stock Name, Stock Type,..."
                          //type="search"
                          fullWidth
                        />
                        <div className={classes.searchIcon}>
                          <IconButton
                            aria-label="view"
                            className={classes.iconHover}
                            onClick={this.handleSearchStock}
                          >
                            <SearchIcon />
                          </IconButton>
                        </div>
                      </div>
                      <Table
                        // className={classes.table}
                        aria-labelledby="tableTitle"
                        size={"small"}
                        aria-label="enhanced table"
                        style={{
                          marginTop: "10px",
                          border: "1px solid  rgba(224, 224, 224, 1)",
                          height: "250px",
                          display: "block",
                          overflowY: "auto",
                        }}
                        stickyHeader
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="checkbox"
                              className="table-content-cell"
                            >
                              <Checkbox
                                color="primary"
                                indeterminate={
                                  numSelected > 0 && numSelected < rowCount
                                }
                                checked={numSelected === rowCount}
                                onChange={this.handleSelectAllClick}
                                inputProps={{
                                  "aria-label": "select all orders",
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Stock Code
                              </span>{" "}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              className="headcells"
                              style={{ width: "300px" }}
                            >
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Stock Name
                              </span>{" "}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Stock Type
                              </span>{" "}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Consumable
                              </span>{" "}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Balance Qty
                              </span>{" "}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredStocksList.map((row, index) => {
                            const isItemSelected = isSelected(row.stockID);
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.stockID}
                              >
                                <TableCell padding="checkbox">
                                  <FormControlLabel
                                    onClick={(event) =>
                                      this.handleClick(event, row)
                                    }
                                    control={
                                      <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                      />
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockCode}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockName}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockType}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.isConsumable === true ? "Yes" : "No"}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-right-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.balanceQty}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={this.handleStockPopupClose}
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        CANCEL
                      </Button>
                      {Object.keys(selected).length === 0 ? (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={this.handleAddStockPopupSubmit}
                        >
                          SUBMIT
                        </Button>
                      ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                            onClick={this.handleAddStockPopupSubmit}
                          >
                            SUBMIT
                          </Button>
                        )}
                    </DialogActions>
                  </Dialog>
                </div>
              )}
              <div
                style={{
                  maxHeight: "700px",
                  // margin: "0 .8em 0 .6em"
                }}
              >
                <div style={{ maxHeight: 650, overflow: "auto" }}>
                  <Table
                    aria-labelledby="tableTitle"
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell padding="default" className="headcells">
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            Stock Code
                          </span>{" "}
                        </TableCell>
                        <TableCell padding="default" className="headcells">
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            Stock Name
                          </span>{" "}
                        </TableCell>
                        <TableCell padding="default" className="headcells">
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            Stock Type
                          </span>{" "}
                        </TableCell>
                        <TableCell padding="default" className="headcells">
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            Consumable
                          </span>{" "}
                        </TableCell>
                        {/* <TableCell
                          padding="default"
                          align="left"
                          className="headcells"
                        >
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            Balance Qty (SOH)
                          </span>{" "}
                        </TableCell> */}
                        <TableCell
                          padding="default"
                          align="right"
                          className="headcells"
                        >
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            Total Indent Qty
                          </span>{" "}
                        </TableCell>
                        {workOrderMode !== "view" &&
                          this.props.status != "Completed" && (
                            <TableCell
                              padding="default"
                              align="left"
                              className="headcells"
                            >
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Action
                              </span>{" "}
                            </TableCell>
                          )}
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ overflow: "hidden" }}>
                      {selectedStocksList.map((row, index) => {
                        return (
                          <TableRow role="checkbox" tabIndex={-1}>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              align="left"
                              style={{ padding: "0px 24px 0px 16px" }}
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.stockCode}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              align="left"
                              style={{ padding: "0px 24px 0px 16px" }}
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.stockName}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              align="left"
                              style={{ padding: "0px 24px 0px 16px" }}
                              className="table-content-cell"
                            >
                              {" "}
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.stockType}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              align="left"
                              style={{ padding: "0px 24px 0px 16px" }}
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.isConsumable == true ? "Yes" : "No"}
                              </Typography>
                              {/* <Autocomplete
                                autoComplete
                                className={classes.comboBox}
                                id="productionLineID"
                                includeInputInList
                                options={this.state.productionLineList}
                                style={{ width: "100%" }}
                                value={row.productionLine}
                                onChange={(e, v) =>
                                  this.handleProductionLineChange(e, v, index)
                                }
                                getOptionLabel={option =>
                                  option.productionLineName
                                }
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    inputProps={{
                                      readOnly:
                                        workOrderMode === "view" ? true : false
                                    }}
                                    label="Production Line"
                                    className={classes.textField1}
                                    name="productionLineID"
                                    margin="normal"
                                    style={{ width: "100%", margin: "0px" }}
                                    error={row.errors.productionLine}
                                    helperText={
                                      row.errors.productionLine
                                        ? row.errors.productionLine
                                        : ""
                                    }
                                  />
                                )}
                              /> */}
                            </TableCell>
                            {/* <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              align="right"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.balanceQty}
                              </Typography>
                            </TableCell> */}
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              align="right"
                              style={{ padding: "0px 24px 0px 16px" }}
                              className="table-content-right-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.totalIndentQty}
                              </Typography>
                              {/* <TextField
                                inputProps={{
                                  "aria-label": "dispatchStatus",
                                  maxLength: 6
                                }}
                                disabled
                                onKeyPress={this._onDecimalKeyPress}
                                align="left"
                                style={{ width: "100%" }}
                                name="dispatchStatus"
                                value={row.statusName}
                              /> */}
                              {/* <Autocomplete
                                autoComplete
                                // disabled
                                className={classes.comboBox}
                                id="dispatchStatusID"
                                includeInputInList
                                options={this.state.statusList}
                                style={{ width: "130%" }}
                                value={row.status}
                                getOptionLabel={option => option.text}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Dispatch Status"
                                    className={classes.textField1}
                                    value={row.status}
                                    name="dispatchStatusID"
                                    margin="normal"
                                    style={{ width: "100%", margin: "0px" }}
                                  />
                                )}
                              /> */}
                            </TableCell>
                            {workOrderMode !== "view" &&
                              this.props.status != "Completed" && (
                                <TableCell
                                  style={{ padding: "0px 24px 0px 16px" }}
                                  className="table-content-cell"
                                >
                                  <Tooltip title="Delete">
                                    <IconButton
                                      style={{
                                        cursor: "pointer",
                                        color: "#6c6b6b",
                                      }}
                                      onClick={(e) =>
                                        this.handleDeleteClicked(e, index, row)
                                      }
                                      autoComplete="off"
                                    >
                                      <DeleteIcon
                                        style={{
                                          cursor: "pointer",
                                          color: "#6c6b6b",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.doStockIndentPopupOpen}
                    onClose={this.handleDoStockIndentPopupClose}
                    maxWidth="md"
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">
                      Add Stock Indent
                    </DialogTitle>
                    <DialogContent dividers>
                      <div>
                        <Autocomplete
                          autoComplete
                          className={classes.comboBox}
                          id="productionLineID"
                          includeInputInList
                          options={productionLineList}
                          onChange={this.handleProductionLineChange}
                          getOptionLabel={(option) => option.value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="Production Line"
                              name="productionLineID"
                              margin="normal"
                              style={{ width: "100%" }}
                            />
                          )}
                        />
                      </div>
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={"small"}
                        aria-label="enhanced table"
                        style={{ marginTop: "10px" }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell padding="default" className="headcells">
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Stock Code
                              </span>{" "}
                            </TableCell>
                            <TableCell padding="default" className="headcells">
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Stock Name
                              </span>{" "}
                            </TableCell>
                            <TableCell padding="default" className="headcells">
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Stock Type
                              </span>{" "}
                            </TableCell>
                            <TableCell padding="default" className="headcells">
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Consumable
                              </span>{" "}
                            </TableCell>
                            <TableCell
                              padding="default"
                              align="left"
                              className="headcells"
                            >
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Balance Qty (SOH)
                              </span>{" "}
                            </TableCell>
                            <TableCell
                              padding="default"
                              align="left"
                              className="headcells"
                            >
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Indent Qty
                              </span>{" "}
                            </TableCell>
                            {/* {workOrderMode === "edit" && ( */}
                            <TableCell
                              padding="default"
                              align="left"
                              className="headcells"
                            >
                              {" "}
                              <span style={{ color: "#306dca" }}>
                                Outstanding Qty
                              </span>{" "}
                            </TableCell>
                            {/* )} */}
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ overflow: "hidden" }}>
                          {selectedStocksList.map((row, index) => {
                            return (
                              <TableRow role="checkbox" tabIndex={-1}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockCode}
                                  </Typography>
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockName}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  {" "}
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.stockType}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.isConsumable == true ? "Yes" : "No"}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-right-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.balanceQty}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  align="right"
                                  className="table-content-right-cell"
                                >
                                  <TextField
                                    inputProps={{ maxLength: 8 }}
                                    onKeyPress={this._onDecimalKeyPress}
                                    align="right"
                                    style={{ width: "100%" }}
                                    name="indentQuantity"
                                    value={row.indentQuantity}
                                    onChange={(e) =>
                                      this.handleChange(
                                        e,
                                        index,
                                        row.indentQuantity
                                      )
                                    }
                                    error={row.errors.indentQuantity}
                                    helperText={
                                      row.errors.indentQuantity
                                        ? row.errors.indentQuantity
                                        : ""
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className="table-content-right-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.outstandingQty}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={this.handleDoStockIndentPopupClose}
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        CANCEL
                      </Button>
                      {!this.state.intentChangesUpdated || !formValid ? (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={this.handleAddStockIndentPopupSubmit}
                        >
                          SUBMIT
                        </Button>
                      ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                            onClick={this.handleAddStockIndentPopupSubmit}
                          >
                            SUBMIT
                          </Button>
                        )}
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </Paper>
          </div>
          <Divider
            style={{
              marginTop: "25px",
            }}
          />
          {this.state.jgStkIdArr.length > 0 && <div className="view-page-headings">Stock Indent Information</div>}
          {/* <div className="view-page-contents-container"></div> */}
          {this.state.jgStkIdArr.length > 0 && <Divider
            style={{
              marginTop: "25px",
            }}
          />}
          {this.state.jgStkIdArr.length > 0 && <div style={{ minHeight: 100, maxHeight: 500, overflowY: "auto" }}>
            <Table
              aria-labelledby="tableTitle"
              size="small"
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  <TableCell padding="default" className="headcells">
                    {" "}
                    <span style={{ color: "#306dca" }}>
                      Indent Code
                          </span>{" "}
                  </TableCell>
                  <TableCell padding="default" className="headcells">
                    {" "}
                    <span style={{ color: "#306dca" }}>
                      Production Line
                          </span>{" "}
                  </TableCell>
                  <TableCell padding="default" className="headcells">
                    {" "}
                    <span style={{ color: "#306dca" }}>
                      Status
                          </span>{" "}
                  </TableCell>
                  <TableCell padding="default" className="headcells" style={{ width: "11.5%" }}>
                    {" "}
                    <span style={{ color: "#306dca" }}>
                      Action
                          </span>{" "}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  this.state.jgStkIdArr.map(ja => {
                    return (
                      <TableRow>
                        <TableCell className="table-content-cell" component="th" style={{ padding: ".5em" }}
                          scope="row"
                          padding="default">{ja.indentCode} </TableCell>
                        <TableCell className="table-content-cell" component="th" style={{ padding: ".5em" }}
                          scope="row"
                          padding="default">{ja.productionLineName}</TableCell>
                        <TableCell className="table-content-cell" component="th" style={{ padding: ".5em" }}
                          scope="row"
                          padding="default">{ja.statusName}</TableCell>
                        <TableCell className="table-content-cell" component="th" style={{ padding: ".5em" }}
                          scope="row"
                          padding="default">
                          <Tooltip title="Print">
                            <IconButton
                              onClick={(e) => this.handleShowPopup(e, ja.indentHeaderID)}
                              style={{
                                padding: "12px 5px 5px 10px",
                                width: "42px"
                              }}
                              // onClick={(e) =>
                              //   this.handleDeleteClicked(e, index, row)
                              // }
                              autoComplete="off"
                            >
                              <PrintIcon
                                style={{
                                  cursor: "pointer",
                                  color: "#6c6b6b",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </div>}
          <div className="button-wrapper">
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.handlCancelClicked(e, workOrderMode)}
              >
                CANCEL
              </Button>
            </div>
            <div style={{ marginLeft: "1em" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) =>
                  this.props.history.push({
                    pathname: "/process",
                    search:
                      "?workOrderID=" +
                      this.props.workOrderID +
                      "&jobGroupID=" +
                      this.props.jobGroupID,
                    state: { workOrderMode: workOrderMode },
                  })
                }
              >
                BACK
              </Button>
            </div>
            <div style={{ marginLeft: "1em" }}>
              <Button
                // disabled={this.props.status == "Completed"}
                //autoFocus
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={this.handleStockSubmit}
              //className={!formValid ? "disabled-button" : "enabled-button "}
              >
                NEXT
              </Button>
            </div>
          </div>
        </div>
        {this.state.showPopup && (
          <ReportViewer
            url={
              `${window.localStorage.ReportURLByID}` +
              "Indent&IsDirectPrint=True&ReportParam=IndentHeaderID&Param1=" + this.state.idForStkreport
              // `${
              // this.props.history.location.pathname.split(
              //   "/view_StockIndent/"
              // )[1]
              // }`
            }
            showPopup={this.state.showPopup}
            title={"Stock Indent"}
            onClose={() => this.handleHidePopup()}
            handleHidePopup={() => this.handleHidePopup()}
          />
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Stock));
