import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { resourceValidation } from "../../../shared/resource";
import { postWarehouseLocation } from "../masterActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import { addStyles } from "../../../shared/styles";
const checkdecimal = new Validator("checkdecimalpoint");
const validateMax2 = new Validator("maxmin2length");
const checkonlyNumbers = new Validator("onlyNumbers");
const checkhousefloor = new Validator("checkwarehousefloor");
const checksection = new Validator("checkwarehousefloor");
const checkarea = new Validator("checkwarehousefloor");

class AddWarehouseLocation extends React.Component {
  constructor() {
    super();

    this.state = {
      warehouse: {
        //  Code: "",
        Suffix: "",
        Floor: "",
        Bay: "",
        Bin: "",
        Stack: "",
        Section: "",
        Weight: 0.0,
        Area: "",
        SequenceNo: "",
        createdBy: window.localStorage.loggedInId,
      },
      errors: {},
      formValid: false,
      // locationCodeValid:false,
      warehouseSuffixValid: false,
      warehouseFloorValid: false,
      warehouseBayValid: false,
      warehouseBinValid: false,
      warehouseStackValid: false,
      warehouseSectionValid: false,
      holdingWeightValid: false,
      warehouseAreaValid: false,
      warehouseSequenceNoValid: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // const list = this.context;
    // this.state.customersList = list.customersList;
    // this.state.departmentList = list.departmentList;
    // this.state.customerPPIList = list.customerPPIList;

    this.props.handleSettingHeader("Warehouse Location");
    // this.setState({ rows: ROWS_CONST });
    // this.props.setSelectedList("masterDataOpen", "warehouse");
  }

  handleChange = (e) => {
    const { warehouse } = this.state;
    warehouse[e.target.name] = e.target.value;
    this.setState({ warehouse });
    this.validateField(e.target.name, e.target.value);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    // let locationCodeValid = this.state.locationCodeValid;
    let warehouseSuffixValid = this.state.warehouseSuffixValid;
    let warehouseFloorValid = this.state.warehouseFloorValid;
    let warehouseBayValid = this.state.warehouseBayValid;
    let warehouseBinValid = this.state.warehouseBinValid;
    let warehouseStackValid = this.state.warehouseStackValid;
    let warehouseSectionValid = this.state.warehouseSectionValid;
    let holdingWeightValid = this.state.holdingWeightValid;
    let warehouseAreaValid = this.state.warehouseAreaValid;
    let warehouseSequenceNoValid = this.state.warehouseSequenceNoValid;
    switch (fieldName) {
      // case 'Code':
      //   locationCodeValid = validateMaxLength100(value);
      //   fieldValidationErrors.Code = locationCodeValid ? '' : ' Please enter alphabet characters only';
      //   break;
      case "Suffix":
        if (value) {
          warehouseSuffixValid = validateMax2(value);
          fieldValidationErrors.Suffix = warehouseSuffixValid
            ? ""
            : "Please enter valid suffix";
        } else {
          warehouseSuffixValid = false;
          fieldValidationErrors.Suffix = "";
        }
        break;
      case "Floor":
        if (value) {
          warehouseFloorValid = checkhousefloor(value);
          fieldValidationErrors.Floor = warehouseFloorValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          warehouseFloorValid = false;
        }
        break;
      case "Bay":
        if (value) {
          warehouseBayValid = checkarea(value);
          fieldValidationErrors.Bay = warehouseBayValid
            ? ""
            : resourceValidation.onlyAlphabet;
        } else {
          warehouseBayValid = false;
        }
        break;
      case "Bin":
        if (value) {
          warehouseBinValid = checkhousefloor(value);
          fieldValidationErrors.Bin = warehouseBinValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          warehouseBinValid = false;
        }
        break;
      case "Stack":
        if (value) {
          warehouseStackValid = checkhousefloor(value);
          fieldValidationErrors.Stack = warehouseStackValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          warehouseStackValid = false;
        }
        break;
      case "Section":
        if (value) {
          warehouseSectionValid = checksection(value);
          fieldValidationErrors.Section = warehouseSectionValid
            ? ""
            : resourceValidation.onlyAlphabet;
        } else {
          warehouseSectionValid = false;
          fieldValidationErrors.Section = "";
        }
        break;
      case "Weight":
        if (value) {
          holdingWeightValid = checkdecimal(value);
          fieldValidationErrors.Weight = holdingWeightValid
            ? ""
            : "Please enter valid holding weight";
        } else {
          holdingWeightValid = false;
        }
        break;
      case "Area":
        if (value) {
          warehouseAreaValid = checkarea(value);
          fieldValidationErrors.Area = warehouseAreaValid
            ? ""
            : resourceValidation.onlyAlphabet;
        } else {
          warehouseAreaValid = false;
        }
        break;
      case "SequenceNo":
        if (value) {
          warehouseSequenceNoValid = checkonlyNumbers(value);
          fieldValidationErrors.SequenceNo = warehouseSequenceNoValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          warehouseSequenceNoValid = false;
          //fieldValidationErrors.SequenceNo='Please enter sequence number';
        }
        break;

      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        //locationCodeValid: locationCodeValid,
        warehouseSuffixValid: warehouseSuffixValid,
        warehouseFloorValid: warehouseFloorValid,
        warehouseBayValid: warehouseBayValid,
        warehouseBinValid: warehouseBinValid,
        warehouseStackValid: warehouseStackValid,
        warehouseSectionValid: warehouseSectionValid,
        holdingWeightValid: holdingWeightValid,
        warehouseAreaValid: warehouseAreaValid,
        warehouseSequenceNoValid: warehouseSequenceNoValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.warehouseSuffixValid &&
        this.state.warehouseFloorValid &&
        this.state.warehouseBayValid &&
        this.state.warehouseAreaValid &&
        this.state.warehouseSequenceNoValid,
    });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors when modal closed
    this.setState({
      //Code: "",
      Suffix: "",
      Floor: "",
      Bay: "",
      Bin: "",
      Stack: "",
      Section: "",
      Weight: "",
      Area: "",
      SequenceNo: "",
      createdBy: localStorage.loggedInId,
      errors: {},
    });
  };

  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onAlphaKeyPress(event) {
    const re = /[a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  onBlur = (event) => {
    const { warehouse } = this.state;
    let weightValues = event.target.value;
    weightValues = this.addZeroes(weightValues.toString(), 2);
    warehouse[event.target.name] = weightValues;
    this.setState({ warehouse });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      const { warehouse } = this.state;
      let warehouseSuffix = warehouse.Suffix;
      let warehouseFloor = warehouse.Floor;
      let warehouseBay = warehouse.Bay;
      let warehouseBin = warehouse.Bin;
      let warehouseStack = warehouse.Stack;
      let warehouseSection = warehouse.Section;
      let holdingWeight = warehouse.Weight;
      let warehouseArea = warehouse.Area;
      let warehouseSequenceNo = warehouse.SequenceNo;

      let locationCode =
        warehouse.Suffix +
        "-" +
        warehouse.Floor +
        "-" +
        warehouse.Area +
        "-" +
        warehouse.Bay +
        (warehouse.Bin ? "-" + warehouse.Bin : "") +
        (warehouse.Stack ? "-" + warehouse.Stack : "") +
        (warehouse.Section ? "-" + warehouse.Section : "");

      let createdBy = localStorage.loggedInId;

      let reqBody = {
        locationCode,
        warehouseSuffix,
        warehouseFloor,
        warehouseBay,
        warehouseBin,
        warehouseStack,
        warehouseSection,
        holdingWeight,
        warehouseArea,
        warehouseSequenceNo,
        createdBy,
      };
      postWarehouseLocation(reqBody, this.props.history);
    }
  };
  render() {
    let { classes } = this.props;
    let { warehouse } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Warehouse Location</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                {/* <p className={classes.subHeader}>Enter User details</p> */}
                {/* <TextField
                  required
                  label="Location Code"
                  name="Code"
                  margin = "normal"
                  className={classes.textField}
                  value={warehouse.Code}
                  onChange={this.handleChange}
                  error={this.state.errors.Code}
                  helperText={
                    this.state.errors.Code
                      ? this.state.errors.Code
                      : ""
                  }
                /> */}
                <TextField
                  inputProps={{
                    maxLength: 2,
                  }}
                  autoComplete="off"
                  required
                  label="Warehouse Suffix"
                  name="Suffix"
                  margin="normal"
                  className={classes.textField}
                  value={warehouse.Suffix}
                  onChange={this.handleChange}
                  error={this.state.errors.Suffix}
                  helperText={
                    this.state.errors.Suffix ? this.state.errors.Suffix : ""
                  }
                  onKeyPress={this._onAlphaKeyPress}
                />
                <TextField
                  inputProps={{
                    maxLength: 4,
                  }}
                  autoComplete="off"
                  required
                  label="Warehouse Floor"
                  name="Floor"
                  margin="normal"
                  className={classes.textField}
                  value={warehouse.Floor}
                  onChange={this.handleChange}
                  error={this.state.errors.Floor}
                  helperText={
                    this.state.errors.Floor ? this.state.errors.Floor : ""
                  }
                  onKeyPress={this._onKeyPress}
                />

                <TextField
                  inputProps={{
                    maxLength: 4,
                  }}
                  autoComplete="off"
                  required
                  label="Warehouse Area"
                  name="Area"
                  margin="normal"
                  className={classes.textField}
                  value={warehouse.Area}
                  onChange={this.handleChange}
                  error={this.state.errors.Area}
                  helperText={
                    this.state.errors.Area ? this.state.errors.Area : ""
                  }
                  onKeyPress={this._onKeyPress}
                />
                <TextField
                  inputProps={{
                    maxLength: 4,
                  }}
                  autoComplete="off"
                  required
                  label="Warehouse Bay"
                  name="Bay"
                  margin="normal"
                  className={classes.textField}
                  value={warehouse.Bay}
                  onChange={this.handleChange}
                  error={this.state.errors.Bay}
                  helperText={
                    this.state.errors.Bay ? this.state.errors.Bay : ""
                  }
                  onKeyPress={this._onKeyPress}
                />

                <TextField
                  inputProps={{
                    maxLength: 4,
                  }}
                  autoComplete="off"
                  label="Warehouse Bin"
                  name="Bin"
                  margin="normal"
                  className={classes.textField}
                  value={warehouse.Bin}
                  onChange={this.handleChange}
                  error={this.state.errors.Bin}
                  helperText={
                    this.state.errors.Bin ? this.state.errors.Bin : ""
                  }
                  onKeyPress={this._onKeyPress}
                />
                <TextField
                  inputProps={{
                    maxLength: 4,
                  }}
                  autoComplete="off"
                  label="Warehouse Stack"
                  name="Stack"
                  margin="normal"
                  className={classes.textField}
                  value={warehouse.Stack}
                  onChange={this.handleChange}
                  error={this.state.errors.Stack}
                  helperText={
                    this.state.errors.Stack ? this.state.errors.Stack : ""
                  }
                  onKeyPress={this._onKeyPress}
                />
                <TextField
                  inputProps={{
                    maxLength: 4,
                  }}
                  autoComplete="off"
                  label="Warehouse Section"
                  name="Section"
                  margin="normal"
                  className={classes.textField}
                  value={warehouse.Section}
                  onChange={this.handleChange}
                  error={this.state.errors.Section}
                  helperText={
                    this.state.errors.Section ? this.state.errors.Section : ""
                  }
                  onKeyPress={this._onKeyPress}
                />
                <TextField
                  inputProps={{
                    maxLength: 20,
                  }}
                  autoComplete="off"
                  label="Holding Weight"
                  name="Weight"
                  margin="normal"
                  className={classes.textField}
                  value={warehouse.Weight}
                  onChange={this.handleChange}
                  error={this.state.errors.Weight}
                  helperText={
                    this.state.errors.Weight ? this.state.errors.Weight : ""
                  }
                  onKeyPress={this._onDecimalKeyPress}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Kg</InputAdornment>
                    ),
                  }}
                  onBlur={this.onBlur}
                />

                <TextField
                  inputProps={{
                    maxLength: 2,
                  }}
                  autoComplete="off"
                  required
                  label="Warehouse Sequence No"
                  name="SequenceNo"
                  margin="normal"
                  className={classes.textField}
                  value={warehouse.SequenceNo}
                  onChange={this.handleChange}
                  error={this.state.errors.SequenceNo}
                  helperText={
                    this.state.errors.SequenceNo
                      ? this.state.errors.SequenceNo
                      : ""
                  }
                  onKeyPress={this._onNumberKeyPress}
                />
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/warehouseLocation")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddWarehouseLocation));
