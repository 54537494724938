import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";
import clone from "clone";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { Checkbox } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { getPlacedOrder } from "../../generic/orderPlacement/actions";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AdvanceSearch from "./search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { StateContext } from "../../../shared/globalState";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import Validator from "../../../shared/validator";
import {
  desc,
  stableSort,
  getSorting,
} from "../../../shared/tableCommonFunctions";
import moment from "moment";
import "../../app/App.css";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { ExportCSV } from "../../../shared/ExportCSV";
import {
  ViewColumnIcon,
  EditIcon,
  ViewIcon,
  AddIcon,
} from "../../../shared/customIcons";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getBillingList, advanceSearchForBilling } from "./action";
// import {  } from "../stockaction";

const columns = [
  {
    id: 4,
    name: "Status",
  },

  {
    id: 5,
    name: "Shipment Reference 1",
  },
  {
    id: 26,
    name: "Modified By",
  },

  {
    id: 27,
    name: "Modified Date",
  },
  {
    id: 28,
    name: "Created By",
  },

  {
    id: 29,
    name: "Created Date",
  },
];

let initailData = [];
function createData(
  billingAccount,
  invoiceNumber,
  invoiceDate,
  statusID,
  shipmentReference1,
  shipmentNumber,
  destination,
  destCountryName,
  origin,
  originCountryName,
  productName,
  currency,
  receiverName,
  receiverContact,
  receiverAddress,
  sendersName,
  senderContact,
  shipmentDate,
  weightCharge,
  xC1Charge1,
  xC2Charge1,
  xC2Name1,
  pieces,
  weight_kg_,
  totalAmount_incl__VAT_,
  modifiedBy,
  modifiedDate,
  createdBy,
  createdDate

) {
  return {
    billingAccount,
    invoiceNumber,
    invoiceDate,
    statusID,
    shipmentReference1,
    shipmentNumber,
    destination,
    destCountryName,
    origin,
    originCountryName,
    productName,
    currency,
    receiverName,
    receiverContact,
    receiverAddress,
    sendersName,
    senderContact,
    shipmentDate,
    weightCharge,
    xC1Charge1,
    xC2Charge1,
    xC2Name1,
    pieces,
    weight_kg_,
    totalAmount_incl__VAT_,
    modifiedBy,
    modifiedDate,
    createdBy,
    createdDate
  };
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell
          padding="checkbox"
          style={{ borderTop: "1px solid #eaeaea", background: "white" }}
          className="table-cell"
        >
          {/* <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "Select All Countrys" }}
              />
            }
          /> 
        </TableCell> */}
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.code}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
            //);
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [formValid, setIsformValid] = useState(false);
  var publisherBackLabelManage = props.roles.filter((r) => {
    return r.superMenuName === "Publisher Back Label";
  });
  let bpcptManage =
    publisherBackLabelManage.length > 0 &&
    publisherBackLabelManage.filter(
      (u) => u.menuName === "Billing Process CPT"
    );
  var acnObj = bpcptManage[0];

  const [countrySearchValue, setValues] = useState({
    countryCode: "",
    countryName: "",
  });

  const classes = useToolbarStyles();
  const {
    numSelected,
    selectedLocalPostage,
    selectedLocalPostageID,
    handleDeletePopupOpen,
    deletePopupOpen,
    searchPopupOpen,
    handleDeletePopupClose,
    handleDeleteCountry,
    handleViewColumns,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchCountry,
    searchClicked,
    selected,
    localPostage,
    stockInList,
    editable,
    removable,
    selectedRows,
  } = props;

  let excelFileData = clone(stockInList);

  excelFileData &&
    excelFileData.length > 0 &&
    excelFileData.map((d) => {
      d["Billing Account"] = d["billingAccount"];
      d["Invoice Number"] = d["invoiceNumber"];
      d["Invoice Date"] = d["invoiceDate"] ? moment(d["invoiceDate"]).format("DD/MM/YYYY") : "";
      d["Status"] = d["statusID"];
      d["Shipment Reference 1"] = d["shipmentReference1"];
      // d["Sender's Name"] = d["sendersName"];
      d["Shipment Number"] = d["shipmentNumber"];
      d["Destination"] = d["destination"];
      d["Dest. Country Name"] = d["destCountryName"];
      d["Origin"] = d["origin"];
      d["Origin Country Name"] = d["originCountryName"];
      d["Product Name"] = d["productName"];
      d["Currency"] = d["currency"];
      d["Receiver Name"] = d["receiverName"];
      d["Receiver Contact"] = d["receiverContact"];
      d["Receiver Address"] = d["receiverAddress"];
      d["Sender Name"] = d["sendersName"];
      d["Sender Contact"] = d["senderContact"];
      d["Shipment Date"] = d["shipmentDate"] ? moment(d["shipmentDate"]).format("DD/MM/YYYY") : ""
      d["Weight Charge"] = d["weightCharge"];
      d["xC1Charge"] = d["xC1Charge1"];
      d["xC2Charge"] = d["xC2Charge1"];
      d["xC2Name"] = d["xC2Name1"];
      d["Pieces"] = d["pieces"];
      d["Weight (kg)"] = d["weight_kg_"];
      d["Total amount (incl. VAT)"] = d["totalAmount_incl__VAT_"];
      d["Modified By"] = d["modifiedBy"];
      d["Modified Date"] = d["modifiedDate"] ? moment(d["modifiedDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      ) : ""
      d["Created By"] = d["createdBy"];
      d["Created Date"] = d["createdDate"] ? moment(d["createdDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      ) : "";
      // delete d["modifiedDate"];
      delete d["billingAccount"];
      delete d["invoiceNumber"];
      delete d["invoiceDate"];
      delete d["statusID"];
      delete d["shipmentReference1"];
      delete d["shipmentNumber"];
      delete d["destination"];
      delete d["destCountryName"];
      delete d["pieces"];
      delete d["weight_kg_"];
      delete d["totalAmount_incl__VAT_"];
      delete d["origin"];
      delete d["originCountryName"];
      delete d["productName"];
      delete d["currency"];
      delete d["receiverName"];
      delete d["receiverContact"];
      delete d["receiverAddress"];
      delete d["sendersName"];
      delete d["senderContact"];
      delete d["shipmentDate"];
      delete d["weightCharge"];
      delete d["xC1Charge1"];
      delete d["xC2Charge1"];
      delete d["xC2Name1"];
      delete d["modifiedBy"];
      delete d["modifiedDate"];
      delete d["createdBy"];
      delete d["createdDate"];
    });

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteCountry = () => {
    handleDeleteCountry();
  };
  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = countrySearchValue;
    searchValues.countryCode = "";
    searchValues.countryName = "";
    searchValues = countrySearchValue;
    setIsformValid(false);
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {/* <Tooltip title="View"> */}
                {/* <Link to={`/view_backLabelStockIn/${selected[0]}`}>
                  <IconButton aria-label="view" className={classes.iconHover}>
                    <ViewIcon />
                  </IconButton>
                </Link> */}
                {/* </Tooltip> */}
                {/* <Tooltip title="Edit"> */}
                {/* {false && (
                  <Link to={`/edit_backLabelStockIn/${selected[0]}`}>
                    <IconButton aria-label="edit" className={classes.iconHover}>
                      <EditIcon />
                    </IconButton>
                  </Link>
                )} */}
                {/* </Tooltip> */}
              </div>
            )}
            {/* <Tooltip title="Delete"> */}
            {/* {removable && (
              <DeleteOrder
                mode="delete"
                selected={selected}
                selectedRows={selectedRows}
                onRequestSucceed={props.onRequestSucceed}
                // count={props.selected.length}
                // isActive={props.isActive}
              />
            )} */}
            {/* </Tooltip> */}
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {props.searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={(e) => props.clearSearch()}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  acnObj &&
                  acnObj.advanceSearchAction && (
                    <AdvanceSearch
                      onSearchClicked={props.onSearchClicked}
                      onRequestSucceed={props.onRequestSucceed}
                    />
                  )
                )}

              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <Link to="/add_backLabelBillingProcess">
                    {" "}
                    <IconButton aria-label="add" className={classes.iconHover}>
                      <AddIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              )}
              {acnObj && acnObj.exportAction && (
                <ExportCSV csvData={excelFileData} fileName={"CPT"} />
              )}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                handleViewColumns={handleViewColumns}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 10.2;
  const left = 77;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    handleViewColumns,
    open,
    ...other
  } = props;
  const [column, setColumnIds] = React.useState(valueProp);
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  }, [valueProp, open]);

  const handleClose = () => {
    onClose(setColumnIds);
  };

  const handleChange = (event) => {
    setColumnIds(event.target.value);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => handleViewColumns(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class BackLabelCPT extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    selectedRows: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    editable: true,
    removable: true,
    stockInList: [],
    loading: false,
    selectedLocalPostage: {},
    selectedLocalPostageID: 1,
    selectedShowColumns: [],
    searchPopupOpen: false,
    countrySearchValues: {},
    searchClicked: false,
    activeRows: 0,
    headCells: [
      {
        code: "billingAccount",
        id: 1,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Billing Account",
      },
      {
        code: "invoiceNumber",
        id: 2,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Invoice Number",
      },
      {
        code: "invoiceDate",
        id: 3,
        numeric: false,
        disablePadding: false,
        label: "Invoice Date",
        show: true,
      },
      {
        id: 4,
        code: "statusID",
        numeric: false,
        disablePadding: false,
        label: "Status",
        show: true,
      },
      {
        id: 5,
        code: "shipmentReference1",
        numeric: false,
        disablePadding: false,
        label: "Shipment Reference 1",
        show: true,
      },

      {
        id: 6,
        code: "shipmentNumber",
        numeric: false,
        disablePadding: false,
        label: "Shipment Number",
        show: true,
      },
      {
        id: 7,
        code: "destination",
        numeric: false,
        disablePadding: false,
        label: "Destination",
        show: true,
      },

      {
        id: 8,
        code: "destCountryName",
        numeric: false,
        disablePadding: false,
        label: "Dest. Country Name",
        show: true,
      },
      // new fields......
      {
        id: 9,
        code: "origin",
        numeric: false,
        disablePadding: false,
        label: "Origin",
        show: true,
      },
      {
        id: 10,
        code: "originCountryName",
        numeric: false,
        disablePadding: false,
        label: "Origin Country Name",
        show: true,
      },
      {
        id: 11,
        code: "productName",
        numeric: false,
        disablePadding: false,
        label: "Product Name",
        show: true,
      },
      {
        id: 12,
        code: "currency",
        numeric: false,
        disablePadding: false,
        label: "Currency",
        show: true,
      },
      {
        id: 13,
        code: "receiverName",
        numeric: false,
        disablePadding: false,
        label: "Receiver Name",
        show: true,
      },
      {
        id: 14,
        code: "receiverContact",
        numeric: false,
        disablePadding: false,
        label: "Receiver Contact",
        show: true,
      },
      {
        id: 15,
        code: "receiverAddress",
        numeric: false,
        disablePadding: false,
        label: "Receiver Address",
        show: true,
      },
      {
        id: 16,
        code: "sendersName",
        numeric: false,
        disablePadding: false,
        label: "Sender Name",
        show: true,
      },
      {
        id: 17,
        code: "senderContact",
        numeric: false,
        disablePadding: false,
        label: "Sender Contact",
        show: true,
      },
      {
        id: 18,
        code: "shipmentDate",
        numeric: false,
        disablePadding: false,
        label: "Shipment Date",
        show: true,
      },
      {
        id: 19,
        code: "weightCharge",
        numeric: false,
        disablePadding: false,
        label: "Weight Charge",
        show: true,
      },
      {
        id: 20,
        code: "xC1Charge1",
        numeric: false,
        disablePadding: false,
        label: "xC1Charge",
        show: true,
      },
      {
        id: 21,
        code: "xC2Charge1",
        numeric: false,
        disablePadding: false,
        label: "xC2Charge",
        show: true,
      },
      {
        id: 22,
        code: "xC2Name1",
        numeric: false,
        disablePadding: false,
        label: "xC2Name",
        show: true,
      },
      {
        id: 23,
        code: "pieces",
        numeric: false,
        disablePadding: false,
        label: "Pieces",
        show: true,
      },

      {
        id: 24,
        code: "weight_kg_",
        numeric: false,
        disablePadding: false,
        label: "Weight (kg)",
        show: true,
      },
      {
        id: 25,
        code: "totalAmount_incl__VAT_",
        numeric: false,
        disablePadding: false,
        label: "Total Amount(incl. VAT)",
        show: true,
      },
      {
        id: 26,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 27,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 28,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
      {
        id: 29,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
    ],
  };
  handleViewColumns = (e, id) => {
    let newheadCells = [...this.state.headCells];

    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  componentDidMount() {
    this.props.handleSettingHeader("Billing Process CPT");
    this.props.setSelectedList(
      "publisherBackLabelOpen",
      "backLabelBillingProcess"
    );

    const [{ store }, dispatch] = this.context;

    this.setState({ loading: true });

    getBillingList().then((r) => {
      this.getBilling(r.data);
    });
  }

  getBilling = (billingList) => {
    let list = billingList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.billingAccount,
            d.invoiceNumber,
            d.invoiceDate,
            d.statusID,
            d.shipmentReference1,
            d.shipmentNumber,
            d.destination,
            d.destCountryName,
            d.origin,
            d.origName,
            d.productName,
            d.currency,
            d.receiversName,
            d.receiversContact,
            d.receiversAddress1,
            d.sendersName,
            d.sendersContact,
            d.shipmentDate,
            d.weightCharge,
            d.xC1Charge,
            d.xC2Charge,
            d.xC2Name,
            d.pieces,
            d.weight_kg_,
            d.totalAmount_incl__VAT_,
            d.modifiedByName,
            d.modifiedDate,
            d.createdByName,
            d.createdDate,
          )
        );
      });

    this.setState({
      rows: rows,
      selected: [],
      loading: false,
      activeRows: rows.filter((x) => x.isActive).map((n) => n.invoiceNumber),
    });
  };

  onRequestSucceed = (deleted) => {
    const [{ store }, dispatch] = this.context;
    getPlacedOrder().then((r) => {
      dispatch({
        type: "getPlacedOrderList",
        newStore: r.data,
      });
      if (deleted) this.setState({ searchClicked: false, selected: [] });
      this.getBilling(r.data);
    });
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { rows } = this.state;
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, data, id) {
    let newSelected = [];
    let selected = [];
    let newSelectedRows = [];

    if (event.target.checked) {
      newSelected = newSelected.concat(selected, id);
      newSelectedRows = newSelected.concat(id);
      this.setState({
        selected: newSelected,
        selectedemloID: data,
        selectedemloIDId: id,
        selectedRows: newSelectedRows,
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedemloID: data,
        selectedemloIDId: id,
        selectedRows: newSelectedRows,
      });
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleDeleteCountry = () => {
    let { selected } = this.state;
    const [{ store }, dispatch] = this.context;
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");
  };
  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchCountry = (handleSearchCountry) => {
    const [{ store }, dispatch] = this.context;

    let countryValues = handleSearchCountry;
    let countryCode = countryValues.countryCode;
    let countryName = countryValues.countryName;
    let reqBody = { countryCode, countryName };

    this.setState({ searchPopupOpen: false, searchClicked: true });
  };

  onSearchClicked = (reqBody) => {
    this.setState({ rows: [], loading: true });
    advanceSearchForBilling(reqBody).then((r) => {
      this.getBilling(r && r.data);
    });
    this.setState({ searchClicked: true });
  };
  clearSearch = () => {
    this.setState({ rows: [], loading: true });
    const [{ store }, dispatch] = this.context;
    getBillingList().then((r) => {
      this.getBilling(r.data);
    });
    this.setState({ searchClicked: false });
  };

  render() {
    const [{ store }, dispatch] = this.context;
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      rows,
      selectedLocalPostage,
      selectedLocalPostageID,
      headCells,
      selectedRows,
    } = this.state;
    const isSelected = (id) => selected.indexOf(id) !== -1;
    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedLocalPostage={selectedLocalPostage}
            selectedLocalPostageID={selectedLocalPostageID}
            history={this.props.history}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchCountry={this.handleSearchCountry}
            countrySearchValue={this.state.countrySearchValue}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteCountry={this.handleDeleteCountry}
            handleViewColumns={this.handleViewColumns}
            headCells={headCells}
            stockInList={rows}
            selected={selected}
            onSearchClicked={this.onSearchClicked}
            editable={this.state.editable}
            removable={this.state.removable}
            selectedRows={selectedRows}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{
                  borderLeft: "1px solid  rgba(224, 224, 224, 1)",
                  whiteSpace: "nowrap",
                }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={rows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {rows.length > 0 ? (
                    stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.blStockInID);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive == true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return (
                          <TableRow
                            //  hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                          >
                            {/* <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    onClick={(event) =>
                                      this.handleClick(
                                        event,
                                        row,
                                        row.invoiceNumber
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell> */}
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.billingAccount &&
                                  row.billingAccount.length > 16 ? (
                                    <Tooltip
                                      title={row.billingAccount}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.billingAccount}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.billingAccount}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.invoiceNumber &&
                                    row.invoiceNumber.length > 16 ? (
                                      <Tooltip
                                        title={row.invoiceNumber}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.invoiceNumber}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.invoiceNumber}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.invoiceDate &&
                                    row.invoiceDate.length > 16 ? (
                                      <Tooltip
                                        title={moment(row.invoiceDate).format(
                                          "DD/MM/YYYY"
                                        )}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {moment(row.invoiceDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.invoiceDate
                                          ? moment(row.invoiceDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.statusID && row.statusID.length > 16 ? (
                                    <Tooltip
                                      title={row.statusID}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.statusID}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.statusID}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.shipmentReference1 &&
                                    row.shipmentReference1.length > 16 ? (
                                      <Tooltip
                                        title={row.shipmentReference1}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.shipmentReference1}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.shipmentReference1}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {/* {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {row.sendersName &&
                                  row.sendersName.length > 16 ? (
                                    <Tooltip
                                      title={row.sendersName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.sendersName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.sendersName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )} */}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {/* {row.email} */}
                                {row.shipmentNumber &&
                                  row.shipmentNumber.length > 16 ? (
                                    <Tooltip
                                      title={row.shipmentNumber}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.shipmentNumber}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.shipmentNumber}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {/* {row.email} */}
                                {row.destination &&
                                  row.destination.length > 16 ? (
                                    <Tooltip
                                      title={row.destination}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.destination}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.destination}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {row.destCountryName &&
                                  row.destCountryName.length > 10 ? (
                                    <Tooltip
                                      title={row.destCountryName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.destCountryName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.destCountryName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {/* {row.email} */}
                                {row.origin && row.origin.length > 16 ? (
                                  <Tooltip title={row.origin} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.origin}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.origin}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {/* {row.email} */}
                                {row.originCountryName && row.originCountryName.length > 16 ? (
                                  <Tooltip title={row.originCountryName} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.originCountryName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.originCountryName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {/* {row.email} */}
                                {row.productName && row.productName.length > 16 ? (
                                  <Tooltip title={row.productName} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.productName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.productName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {/* {row.email} */}
                                {row.currency && row.currency.length > 16 ? (
                                  <Tooltip title={row.currency} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.currency}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.currency}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {/* {row.email} */}
                                {row.receiverName && row.receiverName.length > 16 ? (
                                  <Tooltip title={row.receiverName} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.receiverName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.receiverName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {/* {row.email} */}
                                {row.receiverContact && row.receiverContact.length > 16 ? (
                                  <Tooltip title={row.receiverContact} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.receiverContact}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.receiverContact}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {/* {row.email} */}
                                {row.receiverAddress && row.receiverAddress.length > 16 ? (
                                  <Tooltip title={row.receiverAddress} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.receiverAddress}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.receiverAddress}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[15].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {/* {row.email} */}
                                {row.sendersName && row.sendersName.length > 16 ? (
                                  <Tooltip title={row.sendersName} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.sendersName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.sendersName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[16].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {/* {row.email} */}
                                {row.senderContact && row.senderContact.length > 16 ? (
                                  <Tooltip title={row.senderContact} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.senderContact}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.senderContact}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[17].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.shipmentDate &&
                                    row.shipmentDate.length > 16 ? (
                                      <Tooltip
                                        title={moment(row.shipmentDate).format(
                                          "DD/MM/YYYY"
                                        )}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {moment(row.shipmentDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.shipmentDate
                                          ? moment(row.shipmentDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[18].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="right"
                              >
                                {/* {row.email} */}
                                {row.weightCharge && row.weightCharge.length > 16 ? (
                                  <Tooltip title={row.weightCharge} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.weightCharge}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.weightCharge}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[19].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="right"
                              >
                                {/* {row.email} */}
                                {row.xC1Charge1 && row.xC1Charge1.length > 16 ? (
                                  <Tooltip title={row.xC1Charge1} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.xC1Charge1}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.xC1Charge1}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[20].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="right"
                              >
                                {/* {row.email} */}
                                {row.xC2Charge1 && row.xC2Charge1.length > 16 ? (
                                  <Tooltip title={row.xC2Charge1} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.xC2Charge1}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.xC2Charge1}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {/* .............. */}
                            {this.state.headCells[21].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {/* {row.email} */}
                                {row.xC2Name1 && row.xC2Name1.length > 16 ? (
                                  <Tooltip title={row.xC2Name1} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.xC2Name1}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.xC2Name1}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[22].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="right"
                              >
                                {/* {row.email} */}
                                {row.pieces && row.pieces.length > 16 ? (
                                  <Tooltip title={row.pieces} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.pieces}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.pieces}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[23].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="right"
                              >
                                {/* {row.email} */}
                                {row.weight_kg_ &&
                                  row.weight_kg_.length > 16 ? (
                                    <Tooltip
                                      title={row.weight_kg_}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.weight_kg_}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.weight_kg_}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[24].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="right"
                              >
                                {row.totalAmount_incl__VAT_ &&
                                  row.totalAmount_incl__VAT_.length > 16 ? (
                                    <Tooltip
                                      title={row.totalAmount_incl__VAT_}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.totalAmount_incl__VAT_}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalAmount_incl__VAT_}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[25].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {/* {row.email} */}
                                {row.modifiedBy &&
                                  row.modifiedBy.length > 16 ? (
                                    <Tooltip
                                      title={row.modifiedBy}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.modifiedBy}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[26].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[27].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {/* {row.email} */}
                                {row.createdBy &&
                                  row.createdBy.length > 16 ? (
                                    <Tooltip
                                      title={row.createdBy}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.createdBy}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[28].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {/* {row.email} */}
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={10} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(BackLabelCPT);
