import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/app/App";
import toastr from "toastr";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";

import "toastr/build/toastr.css";
toastr.options = {
  positionClass: "toast-top-right"
};
toastr.options.showMethod = "slideDown";
toastr.options.hideMethod = "slideUp";
toastr.options.progressBar = true;
toastr.options.closeButton = true;
toastr.options.preventDuplicates = true;
toastr.options.closeDuration = 900;

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
