import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import clone from "clone";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { styles } from "../../../shared/styles";
import { resourceValidation } from "../../../shared/resource";
import { StateContext } from "../../../shared/globalState";
import {
  getPubCustomerList,
  getLocationDropdownList,
} from "../../masters/masterActions";
import { getPublisherCustomerList, } from "../action";
import { getBPStatus } from "./actions"
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
//import { getFliteredDeptList } from "../masterActions";
const validateNotRequired2 = new Validator("notRequired2");
const validateNotRequired100 = new Validator("notRequired100");
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class JournalItemSearch extends React.Component {
  componentDidMount() {
    getPublisherCustomerList().then((r) => {
      this.getAllCustomer(r.data);
      getLocationDropdownList().then((r) => {
        this.getAllLocation(r.data);
      });
      getBPStatus().then(r => {
        this.getAllStatus(r.data)
      })
    });
  }
  static contextType = StateContext;
  state = {
    customerList: [],
    customerId: 0,
    customerObj: {},
    statusList: [],
    statusId: "",
    statusObj: {},
    locationList: [],
    locationId: 0,
    locationObj: {},
    batchId: "",
    fileName: "",
    modifiedDateFrom: null,
    modifiedDateTo: null,
    open: false,
  };
  constructor() {
    super();
  }
  handleAutocompleteCustomer = (e, val) => {
    this.setState({ customerId: val && val.key, customerObj: val && val });
  };
  handleAutocompletLocation = (e, val) => {
    this.setState({ locationId: val && val.key, locationObj: val && val });
  };
  handleAutocompletStatus = (e, val) => {
    this.setState({ statusId: val && val.key, statusObj: val && val });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      open: false,
      customerId: "",
      customerObj: {},
      locationObj: {},
      locationId: "",
      statusId: "",
      statusObj: {},
      batchId: "",
      fileName: "",
      modifiedDateFrom: null,
      modifiedDateTo: null,
    });
  };
  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };
  onSearchClicked = () => {
    let reqBody = {
      fileName: this.state.fileName,
      batchID: this.state.batchId,
      modifiedDateFrom:
        this.state.modifiedDateFrom && this.state.modifiedDateFrom,
      modifiedDateTo: this.state.modifiedDateTo && this.state.modifiedDateTo,
    };
    if (this.state.customerId)
      reqBody.customerID = this.state.customerId
    if (this.state.locationId)
      reqBody.locationID = this.state.locationId
    if (this.state.statusId)
      reqBody.statusID = this.state.statusId
    this.props.onSearchClicked(reqBody);
    this.setState({
      open: false,
      customerId: "",
      batchId: "",
      fileName: "",
      modifiedDateFrom: null,
      modifiedDateTo: null,
      customerId: "",
      customerObj: {},
      locationId: 0,
      locationObj: {},
      statusId: "",
      statusObj: {},
    });
  };
  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  sortDDList = (list, name) => {
    let oldCus = clone(list);
    // if(name==="customerList")
    // oldCus&& this.setState({customerId: oldCus[0].key,
    //   customerObj: oldCus[0]})
    //   if(name==="locationList")
    //  {
    //   oldCus &&oldCus.length>0&& this.setState({locationId: oldCus.filter(d=> d.value==="SG")[0].key,
    //   locationObj: oldCus.filter(d=> d.value==="SG")[0]})
    // }
    let z = list.sort(function (a, b) {
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ [name]: z });
  };
  getAllCustomer = (list) => {
    let customerList = [];

    list &&
      list.length > 0 &&
      list.map((c) => {
        customerList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(customerList, "customerList");
  };
  getAllLocation = (list) => {
    let locationList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        locationList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(locationList, "locationList");
  };

  getAllStatus = (list) => {
    let statusList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        if (c.value === "BPCOM") {
          statusList.push({ key: c.id, value: c.value, name: "Completed" });
          this.setState({ statusId: c.id, statusObj: { key: c.id, value: c.value, name: "Completed" } });
        }
        else {
          statusList.push({ key: c.id, value: c.value, name: c.text });
        }
      });
    this.sortDDList(statusList, "statusList");

  };
  render() {
    let { departmentCode, departmentName } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <Tooltip title="Search">
          <IconButton
            aria-label="Search"
            className={classes.iconHover}
            onClick={this.handleClickOpen}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          onClose={this.handleClose}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          maxWidth="md"
        >
          <DialogTitle id="customized-dialog-title">
            {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
            <span style={{ marginLeft: ".7em" }}>Advanced Search</span>
          </DialogTitle>
          <DialogContent dividers>
            <div style={{ marginTop: "-25px" }}>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <Autocomplete
                    options={this.state.customerList}
                    value={this.state.customerObj}
                    onChange={this.handleAutocompleteCustomer}
                    getOptionLabel={(option) => option.name}
                    // style={{
                    //   width: "100%",
                    //   marginLeft: ".5em",
                    //   marginRight: ".5em"
                    // }}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // InputProps={{ readOnly: true }}
                        label="Customer"
                        style={{
                          width: "93%",
                          fontSize: "11px !important",
                          marginLeft: ".5em",
                          marginRight: ".5em",
                          maxHeight: "400px",
                        }}
                        // name="plannerId"
                        margin="normal"
                      />
                    )}
                  />
                </div>

                <div style={{ flex: 1 }}>
                  <Autocomplete
                    options={this.state.locationList}
                    value={this.state.locationObj}
                    onChange={this.handleAutocompletLocation}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Location"
                        // InputProps={{ readOnly: true }}
                        style={{
                          width: "93%",
                          fontSize: "11px !important",
                          marginLeft: ".5em",
                          marginRight: ".5em",
                          maxHeight: "400px",
                        }}
                        // name="plannerId"
                        margin="normal"
                      />
                    )}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      style={{
                        width: "93%",
                        display: "inline-block",
                        marginLeft: ".5em",
                        marginRight: ".5em",
                      }}
                      id="modifiedDateFrom"
                      label="Modified Date From"
                      // onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      name="modifiedDateFrom"
                      value={this.state.modifiedDateFrom}
                      onChange={(e) =>
                        this.handleDateChange(e, "modifiedDateFrom")
                      }
                    //    value={preAlertSearchValue.modifiedDateFrom}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div style={{ flex: 1 }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disabled={!this.state.modifiedDateFrom}
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      style={{
                        width: "93%",
                        display: "inline-block",
                        marginLeft: ".5em",
                        marginRight: ".5em",
                      }}
                      name="modifiedDateTo"
                      minDate={this.state.modifiedDateFrom}
                      value={this.state.modifiedDateTo}
                      id="modifiedDateTo"
                      label="Modified Date To"
                      //   value={preAlertSearchValue.modifiedDateTo}
                      onChange={(e) =>
                        this.handleDateChange(e, "modifiedDateTo")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <TextField
                    id="standard-required"
                    label="Batch ID"
                    name="batchId"
                    className={classes.textField}
                    margin="normal"
                    value={this.state.batchId}
                    inputProps={{
                      maxLength: 50,
                    }}
                    style={{
                      // width: "400px",
                      minWidth: "237px",
                      //display: "inline-block",
                      marginLeft: ".5em",
                      marginRight: ".5em",
                    }}
                    onChange={this.handleChange}
                    autoComplete="off"
                  //  onChange={handleSearchInput}
                  //  error={errors.acronym}
                  // helperText={errors.acronym ? errors.acronym : ""}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Autocomplete
                    options={this.state.statusList}
                    value={this.state.statusObj ? this.state.statusObj : {}}
                    onChange={this.handleAutocompletStatus}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Status"
                        // InputProps={{ readOnly: true }}
                        style={{
                          width: "93%",
                          fontSize: "11px !important",
                          marginLeft: ".5em",
                          marginRight: ".5em",
                          maxHeight: "400px",
                        }}
                        // name="plannerId"
                        margin="normal"
                      />
                    )}
                  />
                </div></div>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <TextField
                    id="standard-required"
                    label="File Name"
                    name="fileName"
                    className={classes.textField}
                    margin="normal"
                    value={this.state.fileName}
                    inputProps={{
                      maxLength: 100,
                    }}
                    style={{
                      // width: "400px",
                      minWidth: "237px",
                      //display: "inline-block",
                      marginLeft: ".5em",
                      marginRight: ".5em",
                    }}
                    onChange={this.handleChange}
                    autoComplete="off"
                  //  onChange={handleSearchInput}
                  //  error={errors.acronym}
                  // helperText={errors.acronym ? errors.acronym : ""}
                  />
                </div>
                <div style={{ flex: 1 }}></div>
              </div>

            </div>
          </DialogContent>
          <DialogActions style={{ padding: "10px 33px" }}>
            <Button
              //   autoFocus
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={this.handleClose}
            //   color="primary"
            >
              CANCEL
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={(e) => this.onSearchClicked()}
              disabled={
                !(this.state.modifiedDateFrom
                  ? this.state.modifiedDateTo
                  : this.state.customerId ||
                  this.state.locationId ||
                  this.state.statusId ||
                  this.state.batchId || this.state.fileName)
              }
            >
              SEARCH
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(JournalItemSearch);
