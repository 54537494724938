import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import StatusText from "../../../shared/statusText";
import clsx from "clsx";
import clone from "clone";
import DeleteOrder from "./delete";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Checkbox } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {
  getPlacedOrder,
  serchCustomerOrder,
} from "../../generic/orderPlacement/actions";
import { getPubMainLabelOrderPlacement, searchMLOF } from "./action";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { blue } from "@material-ui/core/colors";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
//import ManageCountry from "./countryManage";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import AdvanceSearch from "./search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { StateContext } from "../../../shared/globalState";
import Typography from "@material-ui/core/Typography";
//import CreateCountry from "./countryAdd";
import FormGroup from "@material-ui/core/FormGroup";
import Validator from "../../../shared/validator";
import MLOPSearch from "./search";
// import DeleteOrder from "./delete";
import {
  desc,
  stableSort,
  getSorting,
  DialogActions,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import toastr from "toastr";
import moment from "moment";
import "../../app/App.css";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { ExportCSV } from "../../../shared/ExportCSV";
import {
  SearchIcon,
  DownloadIcon,
  ViewColumnIcon,
  EditIcon,
  ViewIcon,
  AddIcon,
} from "../../../shared/customIcons";
import LinearProgress from "@material-ui/core/LinearProgress";

const validateMaxlength2 = new Validator("maxlength2");

const columns = [
  {
    id: 5,
    name: "Status",
  },
  {
    id: 15,
    name: "Modified By",
  },
  {
    id: 16,
    name: "Modified Date",
  },
  {
    id: 17,
    name: "Created By",
  },
  {
    id: 18,
    name: "Created Date",
  },
];

// const DialogActions = withStyles(theme => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1)
//   }
// }))(MuiDialogActions);
let initailData = [];
function createData(
  customer,
  originFileName,
  orderFileName,
  fileReceivedDate,
  issueDescription,
  status,
  subscription,
  acronym,
  volume,
  issue,
  supp,
  part,
  location,
  totalOrders,
  totalCopies,
  submittedDate,
  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate,
  id
) {
  return {
    customer,
    originFileName,
    orderFileName,
    fileReceivedDate,
    issueDescription,
    status,
    subscription,
    acronym,
    volume,
    issue,
    supp,
    part,
    location,
    totalOrders,
    totalCopies,
    submittedDate,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
    id,
  };
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ borderTop: "1px solid #eaeaea", background: "white" }}
          className="table-cell"

        >
          {/* <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "Select All Countrys" }}
              />
            }
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={"left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.code}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
            //);
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    countryCode: "",
    countryName: "",
  });
  const [formValid, setIsformValid] = useState(false);

  const [countrySearchValue, setValues] = useState({
    countryCode: "",
    countryName: "",
  });
  let acnArr = props.roles.filter(
    (u) => u.menuName === "Main Label Order Placement"
  );
  let acnObj = acnArr[0];
  const classes = useToolbarStyles();
  const {
    numSelected,
    selectedLocalPostage,
    selectedLocalPostageID,
    handleDeletePopupOpen,
    deletePopupOpen,
    searchPopupOpen,
    handleDeletePopupClose,
    handleDeleteCountry,
    handleViewColumns,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchCountry,
    searchClicked,
    selected,
    localPostage,
    orderPlacementList,
    editable,
    removable,
    selectedRows,
    selcetedRecord
  } = props;
  let selcetedNOOFRecord = selcetedRecord && selcetedRecord.totalOrders ? selcetedRecord.totalOrders : ""
  let excelFileData = clone(orderPlacementList);
  excelFileData.map((d) => {
    // console.log(d)
    d["Customer"] = d["customer"];
    d["Origin File Name"] = d["originFileName"];
    d["Order File Name"] = d["orderFileName"];
    // d["File Received Date"] = moment(d["fileReceivedDate"]).format(
    //   "DD/MM/YYYY"
    // );
    d["Issue Description"] = d["issueDescription"];
    d["Status"] = d["status"];
    d["Subscription"] = d["subscription"];
    d["Acronym"] = d["acronym"];
    d["Volume"] = d["volume"];
    d["Issue"] = d["issue"];
    d["Supp"] = d["supp"];
    d["Part"] = d["part"];
    d["Location"] = d["location"];
    d["Total Orders"] = d["totalOrders"];
    d["Total Copies"] = d["totalCopies"];
    // d["Submitted Date"] = d["submittedDate"];
    d["Modified By"] = d["modifiedBy"];
    d["Modified Date"] = moment(d["modifiedDate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    );
    d["Created By"] = d["createdBy"];
    d["Created Date"] = moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss");

    delete d["id"];
    delete d["customer"];
    delete d["originFileName"];
    delete d["orderFileName"];
    delete d["fileReceivedDate"];
    delete d["issueDescription"];
    delete d["status"];
    delete d["subscription"];
    delete d["acronym"]; delete d["volume"]; delete d["issue"]; delete d["supp"]; delete d["part"];
    delete d["location"];
    delete d["totalOrders"];
    delete d["totalCopies"];
    delete d["submittedDate"];
    delete d["createdBy"];
    delete d["createdDate"];
    delete d["modifiedBy"];
    delete d["modifiedDate"];
  });
  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteCountry = () => {
    handleDeleteCountry();
  };
  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = countrySearchValue;
    searchValues.countryCode = "";
    searchValues.countryName = "";
    searchValues = countrySearchValue;
    setIsformValid(false);
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected === 0 && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="custom-select1">
              <select
                onChange={(e) => {
                  props.handleTableHead(e);
                }}
              >
                <option value="all" className="custom-select-options">
                  All
                </option>
                <option value="mainLabel" className="custom-select-options">
                  Main Label
                </option>
                <option value="backLabel" className="custom-select-options">
                  Back Label
                </option>
              </select>
            </div>
          </div>
        )}
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {editable && numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_mainLabelOrderPlacement/${selected[0]}/${selcetedNOOFRecord}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}

                {/* <Tooltip title="Edit"> */}
                {/* {editable && (
                  <Link to={`/edit_orderPlacement/${selected[0]}`}>
                    <IconButton aria-label="edit" className={classes.iconHover}>
                      <EditIcon />
                    </IconButton>
                  </Link>
                )} */}
                {/* </Tooltip> */}
              </div>
            )}

            {acnObj && acnObj.deleteAction && removable && numSelected === 1 && (
              <Tooltip title="Delete">
                <DeleteOrder
                  mode="delete"
                  selected={selected}
                  selectedRows={selectedRows}
                  data={props.selectedLocalPostage}
                  onRequestSucceed={props.onRequestSucceed}
                />
              </Tooltip>
            )}
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {props.searchClicked ? (
                // <Tooltip title="Clear search result">
                <IconButton
                  aria-label="clear"
                  className={classes.iconHover}
                  onClick={(e) => props.clearSearch()}
                >
                  <HighlightOffIcon />
                </IconButton>
              ) : (
                  // </Tooltip>
                  // <Tooltip title="Advance search">
                  acnObj &&
                  acnObj.advanceSearchAction && (
                    <MLOPSearch
                      onSearchClicked={props.onSearchClicked}
                      onRequestSucceed={props.onRequestSucceed}
                    />
                  )
                  // </Tooltip>
                )}

              {/* <Tooltip title="Add"> */}
              {/* <IconButton
              aria-label="add"
              className={classes.iconHover}
              onClick={e => props.history.push("/add_")}
            >
              <AddIcon />
            </IconButton> */}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="add"
                    className={classes.iconHover}
                    onClick={(e) =>
                      props.history.push("/add_mainLabelOrderPlacement")
                    }
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}

              {/* <IconButton
              aria-label="view"
              className={classes.iconHover}
              onClick={e => props.history.push("/view_mainLabelOrderPlacement")}
            >
              <ViewIcon />
            </IconButton> */}
              {/* </Tooltip> */}
              {acnObj && acnObj.exportAction && (
                <ExportCSV
                  csvData={excelFileData}
                  fileName={"mainLabelOrderPlacement"}
                />
              )}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>

              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                handleViewColumns={handleViewColumns}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 10.2;
  const left = 77;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    handleViewColumns,
    open,
    ...other
  } = props;
  const [column, setColumnIds] = React.useState(valueProp);
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  }, [valueProp, open]);

  const handleClose = () => {
    onClose(setColumnIds);
  };

  const handleChange = (event) => {
    setColumnIds(event.target.value);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => handleViewColumns(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class MainLabelOrderPlacement extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    selectedRows: [],
    selectedLabel: "all",
    page: 0,
    rowsPerPage: 100,
    rows: [],
    originalRows: [],
    editable: true,
    removable: false,
    orderPlacementList: [],
    loading: false,
    selectedLocalPostage: {},
    selectedLocalPostageID: 1,
    selectedShowColumns: [],
    searchPopupOpen: false,
    countrySearchValues: {},
    searchClicked: false,
    headCells: [
      {
        code: "customer",
        id: 1,
        show: false,
        numeric: false,
        disablePadding: true,
        label: "Customer",
        show: true,
      },
      {
        code: "originFileName",
        id: 2,
        show: false,
        numeric: false,
        disablePadding: true,
        label: "Origin File Name",
        show: true,
      },
      {
        code: "orderFileName",
        id: 3,
        numeric: false,
        disablePadding: false,
        label: "Order File Name",
        show: true,
      },
      // {
      //   id: 4,
      //   code: "fileReceivedDate",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "File Received Date",
      //   show: true,
      // },

      {
        id: 4,
        code: "issueDescription",
        numeric: false,
        disablePadding: false,
        label: "Issue Description",
        show: true,
      },
      {
        id: 5,
        code: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        show: true,
      },
      {
        id: 6,
        code: "subscription",
        numeric: false,
        disablePadding: false,
        label: "Subscription",
        show: true,
      },
      {
        id: 7,
        code: "acronym",
        numeric: false,
        disablePadding: false,
        label: "Acronym",
        show: true,
      },
      {
        id: 8,
        code: "volume",
        numeric: false,
        disablePadding: false,
        label: "Volume",
        show: true,
      },
      {
        id: 9,
        code: "issue",
        numeric: false,
        disablePadding: false,
        label: "Issue",
        show: true,
      },
      {
        id: 10,
        code: "supp",
        numeric: false,
        disablePadding: false,
        label: "Supp",
        show: true,
      },
      {
        id: 11,
        code: "part",
        numeric: false,
        disablePadding: false,
        label: "Part",
        show: true,
      },
      {
        id: 12,
        code: "location",
        numeric: false,
        disablePadding: false,
        label: "Location",
        show: true,
      },

      {
        id: 13,
        code: "totalOrders",
        numeric: false,
        disablePadding: false,
        label: "Total Orders",
        show: true,
      },

      {
        id: 14,
        code: "totalCopies",
        numeric: false,
        disablePadding: false,
        label: "Total Copies",
        show: true,
      },

      // {
      //   id: 11,
      //   code: "submittedDate",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Submitted Date",
      //   show: true,
      // },

      {
        id: 15,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },

      {
        id: 16,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 17,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },

      {
        id: 18,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
    ],
    selcetedRecord: {}
  };
  handleViewColumns = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  componentDidMount() {
    this.props.handleSettingHeader("Main Label Order Placement");
    this.props.setSelectedList(
      "publisherMainLabelOpen",
      "pubMainLabelOrderPlacement"
    );

    const [{ store }, dispatch] = this.context;

    this.setState({ loading: true });
    getPubMainLabelOrderPlacement()
      .then((r) => {
        this.getAllPlacedOrder(r.data);
        if (!r) this.setState({ loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  }
  shouldComponentUpdate(s, p) {
    const [{ store }, dispatch] = this.context;
    // if (this.state.rows.length !== store.placedOrderList.length) {
    //   !this.state.searchClicked &&
    //     this.getAllPlacedOrder(store.placedOrderList);
    // }
    // if (this.state.rows.length !== 0) {
    //   this.state.rows.map((cl, i) => {
    //     if (cl.ststus != store.placedOrderList[i].isActive)
    //       !this.state.searchClicked &&
    //         this.getAllPostageList(store.placedOrderList);
    //   });
    // }
    return true;
  }
  onRequestSucceed = (deleted) => {
    const [{ store }, dispatch] = this.context;
    getPubMainLabelOrderPlacement().then((r) => {
      if (deleted) {
        getPubMainLabelOrderPlacement()
          .then((r) => {
            this.getAllPlacedOrder(r.data);
            if (!r) this.setState({ loading: false });
          })
          .catch((e) => {
            this.setState({ loading: false });
          });
        this.setState({ searchClicked: false, selected: [] });
      }
      // this.getAllPlacedOrder(r.data);
    });
  };
  getAllPlacedOrder = (lpList) => {
    let list = lpList;
    let newRows = [];
    let statusList = [];

    list &&
      list.length > 0 &&
      list.map((d) => {
        newRows.push(
          createData(
            d.customerName,
            d.originalFileName,
            d.orderFileName,
            d.fileRecivedDate,
            d.issueDescription,
            d.statusName,
            d.subscription,
            d.acronym,
            d.volume,
            d.issue,
            d.suppNumber,
            d.partNumber,
            d.location,
            d.totalOrders,
            d.totalCopies,
            d.createdDate,
            d.createdByName,
            d.createdDate,
            d.modifiedByName,
            d.modifiedDate,
            d.mlopHeaderID
          )
        );
      });
    let labelRows = [];
    if (this.state.selectedLabel === "mainLabel") {
      labelRows = newRows.filter(
        (l) =>
          l.subscription === "ML" ||
          l.subscription === "OM" ||
          l.subscription === "HC"
      );
    } else if (this.state.selectedLabel === "backLabel") {
      labelRows = newRows.filter(
        (l) =>
          l.subscription === "BL" ||
          l.subscription === "OB" ||
          l.subscription === "SC"
      );
    } else {
      labelRows = newRows;
    }
    this.setState({ rows: labelRows, loading: false, originalRows: newRows });
    initailData = newRows;
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { rows } = this.state;
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, code, data, id) => {
    // if(this.state.selected.lenght>0)
    // return
    let rowSelected = [];
    // let newSelectedRows = [];
    // let { selected, selectedRows } = this.state;
    // const selectedIndex = selected.indexOf(id);
    // let newSelected = [];

    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, id);
    //   newSelectedRows = newSelectedRows.concat(selectedRows, data);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    //   newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    //   newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1)
    //   );
    //   newSelectedRows = newSelectedRows.concat(
    //     selectedRows.slice(0, selectedIndex),
    //     selectedRows.slice(selectedIndex + 1)
    //   );
    // }
    // if (newSelected.length === 1) {
    let newSelected = [];
    let selected = [];
    let newSelectedRows = [];

    if (event.target.checked) {
      newSelected = newSelected.concat(selected, id);
      newSelectedRows = newSelected.concat(id);
      this.setState({
        selected: newSelected,
        selectedRows: newSelectedRows,
        selcetedRecord: data
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedRows: newSelectedRows,
        selcetedRecord: {}
      });
    }
    rowSelected = this.state.rows.filter((r) => r.id === newSelected[0]);
    // }
    if (rowSelected.length > 0) {
      if (rowSelected[0].status.includes("UnSubmitted")) {
        this.setState({ editable: false });
      } else {
        this.setState({ editable: true });
      }
      if (
        rowSelected[0].status.includes("UnSubmitted") ||
        rowSelected[0].status.includes("Submitted") ||
        rowSelected[0].status.includes("OnHold") ||
        rowSelected[0].status.includes("Ready")

      ) {
        this.setState({ removable: true });
      } else {
        this.setState({ removable: false });
      }
    }
    // if (newSelected.length === 1) {
    //   this.setState({
    //     selected: newSelected,
    //     selectedLocalPostage: rowSelected,
    //     selectedLocalPostageID: data.id,
    //     selectedRows: newSelectedRows
    //   });
    // } else {
    //   this.setState({
    //     selected: newSelected,
    //     selectedLocalPostage: rowSelected,
    //     selectedLocalPostageID: 0,
    //     selectedRows: newSelectedRows
    //   });
    // }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleDeleteCountry = () => {
    let { selected } = this.state;
    const [{ store }, dispatch] = this.context;
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");
  };
  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchCountry = (handleSearchCountry) => {
    const [{ store }, dispatch] = this.context;

    let countryValues = handleSearchCountry;
    let countryCode = countryValues.countryCode;
    let countryName = countryValues.countryName;
    let reqBody = { countryCode, countryName };

    this.setState({ searchPopupOpen: false, searchClicked: true });
  };

  onSearchClicked = (reqBody) => {
    this.setState({ rows: [], loading: true });
    searchMLOF(reqBody).then((r) => {
      this.getAllPlacedOrder(r.data);
    });
    this.setState({ searchClicked: true });
  };
  clearSearch = () => {
    this.setState({ rows: [], loading: true });
    getPubMainLabelOrderPlacement()
      .then((r) => {
        this.getAllPlacedOrder(r.data);
        if (!r) this.setState({ loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
    this.setState({ searchClicked: false });
  };
  handleTableHead = (e) => {
    let newList = clone(this.state.originalRows);
    let labelRows = [];
    if (e.target.value === "mainLabel") {
      labelRows = newList.filter(
        (l) =>
          l.subscription === "ML" ||
          l.subscription === "OM" ||
          l.subscription === "HC"
      );
    } else if (e.target.value === "backLabel") {
      labelRows = newList.filter(
        (l) =>
          l.subscription === "BL" ||
          l.subscription === "OB" ||
          l.subscription === "SC"
      );
    } else {
      labelRows = this.state.originalRows;
    }
    this.setState({ selectedLabel: e.target.value, rows: labelRows });
  };
  render() {
    const [{ store }, dispatch] = this.context;
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      rows,
      selectedLocalPostage,
      selectedLocalPostageID,
      headCells,
      selectedRows,
    } = this.state;
    const isSelected = (id) => selected.indexOf(id) !== -1;
    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedLocalPostage={selectedLocalPostage}
            selectedLocalPostageID={selectedLocalPostageID}
            history={this.props.history}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchCountry={this.handleSearchCountry}
            countrySearchValue={this.state.countrySearchValue}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteCountry={this.handleDeleteCountry}
            handleViewColumns={this.handleViewColumns}
            headCells={headCells}
            orderPlacementList={rows}
            selected={selected}
            onSearchClicked={this.onSearchClicked}
            editable={this.state.editable}
            removable={this.state.removable}
            selectedRows={selectedRows}
            handleTableHead={this.handleTableHead}
            roles={this.props.roles}
            selcetedRecord={this.state.selcetedRecord}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={rows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {rows.length > 0 ? (
                    stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive == true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    onClick={(event) =>
                                      this.handleClick(
                                        event,
                                        row.coustomerName,
                                        row,
                                        row.id
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.customer && row.customer.length > 16 ? (
                                  <Tooltip
                                    title={row.customer}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customer}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customer}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                // className="table-content-cell"
                                style={{ minWidth: "auto", padding: ".5em" }}
                              >
                                {/* {row.code} */}
                                {row.originFileName &&
                                  row.originFileName.length > 16 ? (
                                    <Tooltip
                                      title={row.originFileName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.originFileName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.originFileName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.orderFileName &&
                                  row.orderFileName.length > 16 ? (
                                    <Tooltip
                                      title={row.orderFileName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.orderFileName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.orderFileName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {/* {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                
                                {row.fileReceivedDate &&
                                  row.fileReceivedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.fileReceivedDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.fileReceivedDate).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.fileReceivedDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )} */}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.issueDescription &&
                                  row.issueDescription.length > 12 ? (
                                    <Tooltip
                                      title={row.issueDescription}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.issueDescription}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issueDescription}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.status && row.status.length > 16 ? (
                                  <Tooltip title={row.status} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.status == "Submitted" || row.status == "Completed" || row.status == "Received" || row.status == "Complete" ? (
                                        <StatusText color={"#16831F"}>
                                          {row.status}
                                        </StatusText>
                                      ) : row.status == "UnSubmitted" || row.status == "Ready" ? (
                                        <StatusText color={"#F4B43C"}>
                                          {row.status}
                                        </StatusText>
                                      ) : row.status == "Processed" || row.status == "InProgress" ? (
                                        <StatusText color={"#0091ff"}>
                                          {row.status}
                                        </StatusText>
                                      ) : row.status == "Cancelled" || row.status == "OnHold" ? (
                                        <StatusText color={"red"}>
                                          {row.status}
                                        </StatusText>
                                      ) : (
                                                <StatusText color={"grey"}>
                                                  {row.status}
                                                </StatusText>
                                              )
                                      }
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.status == "Submitted" || row.status == "Completed" || row.status == "Received" || row.status == "Complete" ? (
                                        <StatusText color={"#16831F"}>
                                          {row.status}
                                        </StatusText>
                                      ) : row.status == "UnSubmitted" || row.status == "Ready" ? (
                                        <StatusText color={"#F4B43C"}>
                                          {row.status}
                                        </StatusText>
                                      ) : row.status == "Processed" || row.status == "InProgress" ? (
                                        <StatusText color={"#0091ff"}>
                                          {row.status}
                                        </StatusText>
                                      ) : row.status == "Cancelled" || row.status == "OnHold" ? (
                                        <StatusText color={"red"}>
                                          {row.status}
                                        </StatusText>
                                      ) : (
                                                <StatusText color={"grey"}>
                                                  {row.status}
                                                </StatusText>
                                              )
                                      }
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.subscription &&
                                  row.subscription.length > 16 ? (
                                    <Tooltip
                                      title={row.subscription}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.subscription}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.subscription}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}


                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.acronym &&
                                  row.acronym.length > 16 ? (
                                    <Tooltip
                                      title={row.acronym}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.acronym}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.acronym}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.volume &&
                                  row.volume.length > 16 ? (
                                    <Tooltip
                                      title={row.volume}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.volume}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.volume}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.issue &&
                                  row.issue.length > 16 ? (
                                    <Tooltip
                                      title={row.issue}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.issue}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issue}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.supp &&
                                  row.supp.length > 16 ? (
                                    <Tooltip
                                      title={row.supp}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.supp}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.supp}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.part &&
                                  row.part.length > 16 ? (
                                    <Tooltip
                                      title={row.part}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.part}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.part}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {/* ............................................................................. */}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.location && row.location.length > 16 ? (
                                  <Tooltip
                                    title={row.location}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.location}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.location}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.totalOrders &&
                                  row.totalOrders.length > 16 ? (
                                    <Tooltip
                                      title={row.totalOrders}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.totalOrders}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalOrders}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.totalCopies &&
                                  row.totalCopies.length > 16 ? (
                                    <Tooltip
                                      title={row.totalCopies}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.totalCopies}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalCopies}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {/* {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                              
                                {row.submittedDate &&
                                  row.submittedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.submittedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.submittedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.submittedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )} */}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.modifiedBy &&
                                  row.modifiedBy.length > 16 ? (
                                    <Tooltip
                                      title={row.modifiedBy}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedBy}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[15].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[16].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.createdBy && row.createdBy.length > 16 ? (
                                  <Tooltip
                                    title={row.createdBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[17].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={10} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(MainLabelOrderPlacement);

// import React from "react";
// export default class LocalPostage extends React.Component {
//   render() {
//     return <div>dssdsd</div>;
//   }
// }
