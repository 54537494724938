import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import {Autocomplete} from "@material-ui/lab" ;
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  postOutworkerClaimRate,
  getUomDropDownList,
  getProcessList,
} from "../../masters/masterActions";
import { addStyles } from "../../../shared/styles";

const validateNotRequired100 = new Validator("maxlength100AllAllow");
const onlyNumbers = new Validator("onlyNumbers");
const checkrate = new Validator("ratecheckdecimalpoint");

class AddOutworkerClaimRate extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      outworkerClaimRate: {
        processName: "",
        rate: 0,
        uomid: "",
        remarks: "",
      },
      errors: {},
      rateValid: false,
      uomValid: false,
      processNameValid: false,
      remarksValid: true,
      orderNoValid: false,
      processList: [],
      uomList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Outworker Claim Rate");
    this.props.setSelectedList("masterDataOpen", "outworkerClaimRate");
    getProcessList(window.localStorage.token).then((r) => {
      dispatch({
        type: "getProcessList",
        newStore: r.data,
      });
      this.getAllProcess(r.data);
    });
    getUomDropDownList(window.localStorage.token).then((r) => {
      dispatch({
        type: "getUomDropDownList",
        newStore: r.data,
      });
      this.getAllUoms(r.data);
    });
  }
  getAllProcess = (list) => {
    let processList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        c.isActive
          ? processList.push({
              key: c.processID,
              value: c.processName,
              active: c.isActive,
            })
          : processList
      );
    this.setState({
      processList: processList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      ),
    });
  };

  getAllUoms = (list) => {
    let uomList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        uomList.push({
          id: c.id,
          text: c.text,
          value: c.value,
        })
      );
    this.setState({
      uomList: uomList.sort((a, b) =>
        a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
      ),
    });
  };

  handleChange = (event) => {
    const { outworkerClaimRate } = this.state;
    if (event.target.name === "rate") {
      let rate = event.target.value;
      rate = this.checkDHlWeightDec(event.target.value.toString());
      outworkerClaimRate[event.target.name] = rate;
    } else {
      outworkerClaimRate[event.target.name] = event.target.value;
    }
    this.setState({ outworkerClaimRate });

    this.validateField(
      event.target.name,
      outworkerClaimRate[event.target.name]
    );
  };

  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  handleProcessChange = (event, values) => {
    const { outworkerClaimRate } = this.state;
    if (values === null) {
      outworkerClaimRate["processName"] = "null";
    } else {
      let processNameID = values.key;
      outworkerClaimRate["processName"] = processNameID;
    }
    this.setState({ outworkerClaimRate });

    this.validateField("processName", outworkerClaimRate["processName"]);
  };

  handleUomChange = (event, values) => {
    const { outworkerClaimRate } = this.state;
    if (values === null) {
      outworkerClaimRate["uomid"] = "null";
    } else {
      let uomText = values.id;
      outworkerClaimRate["uomid"] = uomText;
    }
    this.setState({ outworkerClaimRate });

    this.validateField("uomid", outworkerClaimRate["uomid"]);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let rateValid = this.state.rateValid;
    let uomValid = this.state.uomValid;
    let processNameValid = this.state.processNameValid;
    let remarksValid = this.state.remarksValid;
    switch (fieldName) {
      case "rate":
        if (value !== "") {
          rateValid = checkrate(value);
          fieldValidationErrors.rate = rateValid
            ? ""
            : resourceValidation.validRate;
        } else {
          rateValid = false;
          fieldValidationErrors.rate = "";
        }
        break;
      case "uomid":
        if (value !== "null") {
          uomValid = onlyNumbers(value);
          fieldValidationErrors.uomid = uomValid ? "" : " Please select UOM";
        } else {
          uomValid = false;
          fieldValidationErrors.uomid = "";
        }
        break;
      case "remarks":
        if (value !== "") {
          remarksValid = validateNotRequired100(value);
          fieldValidationErrors.remarks = remarksValid
            ? ""
            : resourceValidation.validRemarks;
        } else {
          remarksValid = true;
          fieldValidationErrors.remarks = "";
        }
        break;
      case "processName":
        if (value !== "null") {
          processNameValid = onlyNumbers(value);
          fieldValidationErrors.processName = processNameValid
            ? ""
            : " Please select Process";
        } else {
          processNameValid = false;
          fieldValidationErrors.processName = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        rateValid: rateValid,
        uomValid: uomValid,
        remarksValid: remarksValid,
        processNameValid: processNameValid,
      },
      this.validateForm
    );
  }
  onBlur = (event) => {
    const { outworkerClaimRate } = this.state;
    let rate = event.target.value;
    rate = this.addZeroes(rate.toString(), 2);
    outworkerClaimRate[event.target.name] = rate;
    this.setState({ outworkerClaimRate });
  };
  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.rateValid &&
        this.state.uomValid &&
        this.state.processNameValid &&
        this.state.remarksValid,
    });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      rate: "",
      uomid: "",
      remarks: "",
      processName: "",
      errors: {},
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      const { outworkerClaimRate } = this.state;

      let rate = outworkerClaimRate.rate;
      let uomid = outworkerClaimRate.uomid;
      let remarks = outworkerClaimRate.remarks;
      let processID = outworkerClaimRate.processName;
      let createdBy = localStorage.loggedInId;

      let reqBody = { processID, rate, uomid, remarks, createdBy };
      postOutworkerClaimRate(reqBody).then((r) => {
        this.props.history.push("/OutworkerClaimRate");
        //toastr.success("OutworkerClaimRate Created Successfully");
      });
    }
  };
  render() {
    let { classes } = this.props;
    let { outworkerClaimRate, processList, uomList } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Outworker Claim Rate</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="processName"
                    name="processName"
                    hintText="process Name"
                    className={classes.textField}
                    options={processList}
                    onChange={this.handleProcessChange}
                    getOptionLabel={(option) => option.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Process"
                        name="processName"
                        id="processName"
                        margin="normal"
                        required
                        style={{ width: "100%" }}
                        value={outworkerClaimRate.processName}
                        error={this.state.errors.processName}
                        helperText={
                          this.state.errors.processName
                            ? this.state.errors.processName
                            : ""
                        }
                      />
                    )}
                  />
                  <TextField
                    required
                    label="Rate"
                    name="rate"
                    inputProps={{
                      maxLength: 8,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={outworkerClaimRate.rate}
                    onChange={this.handleChange}
                    onBlur={this.onBlur}
                    error={this.state.errors.rate}
                    helperText={
                      this.state.errors.rate ? this.state.errors.rate : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$S</InputAdornment>
                      ),
                    }}
                    onKeyPress={this._onDecimalKeyPress}
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="uomid"
                    name="uomid"
                    hintText="Unit Of Measurement"
                    className={classes.textField}
                    options={uomList}
                    onChange={this.handleUomChange}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="UOM"
                        name="uomid"
                        id="uomid"
                        margin="normal"
                        required
                        style={{ width: "100%" }}
                        value={outworkerClaimRate.uomid}
                        error={this.state.errors.uomid}
                        helperText={
                          this.state.errors.uomid ? this.state.errors.uomid : ""
                        }
                      />
                    )}
                  />
                </div>
                <div>
                  <TextField
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    label="Remarks"
                    name="remarks"
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={outworkerClaimRate.remarks}
                    onChange={this.handleChange}
                    error={this.state.errors.remarks}
                    helperText={
                      this.state.errors.remarks ? this.state.errors.remarks : ""
                    }
                  />
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/outworkerClaimRate")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddOutworkerClaimRate));
