import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_PUBLISHER_ORDER_PROCESSING;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getPubBatchLabelOrderPlacement = async () => {
  let pmlopList = [];
  await pgsService
    .get("/PreProcess")
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getBPStatus = async () => {
  let pmlopList = [];
  await pgsService
    .get("/GetBatchStatus")
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const searchPubBatchLabelOrderPlacement = async (rBody) => {
  let pmlopList = [];
  await pgsService
    .post("/BatchProcessAdvSerach", rBody)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};

export const getOrderFiles = async (cId, lId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetOrderFileReady/${cId}/${lId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getOnHoldFiles = async (cId, lId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetOrderFileOnHold/${cId}/${lId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getBatchSummaryList = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetBatchProcessSummary/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getprocessingList1 = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetBatchProcessing/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => {
      toastr.error("Something went wrong");
    });
  return pmlopList;
};
export const getprocessingList2 = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetBatchResult/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => {
      // toastr.error("Something went wrong")
    });
  return pmlopList;
};
export const getprocessingList3 = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetMLBatchRoutingException/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => {
      // toastr.error("Something went wrong")
    });
  return pmlopList;
};
export const putOnhold = async (mId, lId) => {
  let pmlopList = [];
  await pgsService
    .post(`/PutOnHold/${mId}/${lId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};

export const makeReady = async (mId, lId) => {
  let pmlopList = [];
  await pgsService
    .post(`/MoveToReady/${mId}/${lId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const submitSuplimentFile = async (mId, fId) => {
  let pmlopList = "";
  await pgsService
    .get(`/IsValidSupplimentFileName/${mId}/${fId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const createBatch = async (rBody, history) => {
  let pmlopList = [];
  await pgsService
    .post(`/PreProcess`, rBody)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;

      history.push(
        `/add_pubMainLabelBatchPlacement/${rBody.customerID}/${rBody.locationID}/${r.data}`
      );
      //history.push(`/add_pubMainLabelBatchPlacement/${rBody.customerID}/${rBody.locationID}/34`)
    })
    .catch((e) => { });
  return pmlopList;
};
export const postOrderDetails = async (rBody) => {
  let pmlopList = [];
  await pgsService
    .post(`/PreProcess`, rBody)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const postCriteriaByOF = async (rBody) => {
  let pmlopList = [];
  await pgsService
    .post(`/InsertByOrderFile`, rBody)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const postCriteriaByZC = async (rBody) => {
  let pmlopList = [];
  await pgsService
    .post(`/InsertByCountry`, rBody)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const postPickInsert = async (rBody, mhId) => {
  let pmlopList = [];
  await pgsService
    .post(`/InsertCriteria`, rBody)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const postNextInsert = async (rBody, mhId) => {
  let pmlopList = [];
  await pgsService
    .post(`/InsertCriteria`, rBody)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getListForView = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetBatcDetails/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getSecondListForView = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetInsertCriteriaList/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getPickInsertList = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetPickInsertList/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getCriteriaFilesList = async (mhId, jId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetPickCriteriaByOrderFile/${mhId}/${jId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getbatchDetails = async (mhId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetDisplayBatchDetails/${mhId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getMainOrderFile = async (cId, lId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetPickMainOrderFile/${cId}/${lId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getSuplimentOrderFile = async (cId, lId) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetPickSupplimentOrderFile/${cId}/${lId}`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
