import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import { getCountry } from "../masterActions";
import Validator from "../../../shared/validator";
import { Autocomplete } from "@material-ui/lab";
// Include new StateContext
import { resourceValidation } from "../../../shared/resource";
import MenuItem from "@material-ui/core/MenuItem";
import { getZoneList, getDHLZoneList, updateCountryID } from "../masterActions";
import { StateContext } from "../../../shared/globalState";
import { Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { addStyles } from "../../../shared/styles";

const validateMaxlength2 = new Validator("maxlength2");
const onlyNumbers = new Validator("onlyNumbers");
const shouldNotEmpty = new Validator("general");
var reA = /[^a-zA-Z]/g;
var reN = /[^0-9]/g;
function sortAlphaNum(a, b) {
  var aA = a.dhlZoneCode.replace(reA, "");
  var bA = b.dhlZoneCode.replace(reA, "");
  if (aA === bA) {
    var aN = parseInt(a.dhlZoneCode.replace(reN, ""), 10);
    var bN = parseInt(b.dhlZoneCode.replace(reN, ""), 10);
    if (isNaN(bN) || isNaN(bN)) {
      return a > b ? 1 : -1;
    }
    return aN === bN ? 0 : aN > bN ? 1 : -1;
  } else {
    return aA > bA ? 1 : -1;
  }
}
class EditCountry extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      formValid: true,
      country: {
        countryCode: "",
        countryName: "",
        zoneCode: "",
        zoneID: "",
        dhlZoneID: "",
        dhlZoneCode: "",
        alternateCountryCode: "",
        alternateCountryName: "",
        isActive: true,
        modifiedBy: 1,
        dhlZone: "",
      },
      updatedValues: false,
      active: false,
      zoneList: [],
      dhlZoneList: [],
      errors: {},
      countryCodeValid: false,
      countryNameValid: false,
      zoneValid: false,
      dhlZoneValid: false,
      alternateCountryCodeValid: false,
      alternateCountryNameValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let countryID = this.props.match.params.countryId;

    getZoneList().then((r) => {
      this.setState({
        zoneList: r.data
          .filter((x) => x.isActive === true)
          .sort((a, b) =>
            a.zoneCode.toLowerCase() > b.zoneCode.toLowerCase() ? 1 : -1
          ),
      });
    });

    getDHLZoneList().then((r) => {
      this.setState({
        dhlZoneList: r.data.sort(
          sortAlphaNum
          // (a, b) =>
          // a.dhlZoneCode.toLowerCase() > b.dhlZoneCode.toLowerCase() ? 1 : -1
        ),
      });
    });

    getCountry(countryID).then((r) => {
      let neObj = { ...r, dhlZone: r.dhlZoneName, dhlZoneID: r.dhlZoneID };
      this.setState({ country: neObj });
      if (r.isActive) this.setState({ active: r.isActive });
      this.validateField("countryCode", r.countryCode);
      this.validateField("countryName", r.countryName);
      this.validateField("alternateCountryCode", r.alternateCountryCode);
      this.validateField("alternateCountryName", r.alternateCountryName);
      this.validateField("zoneID", r.zoneID);
      this.validateField("dhlZoneID", r.dhlZoneID);
    });
  }

  handleChange = (event) => {
    this.setState({ updatedValues: true });

    const { country } = this.state;
    if (event.target.name === "isActive") {
      country[event.target.name] = event.target.checked;
    } else {
      country[event.target.name] = event.target.value;
    }
    this.validateField(event.target.name, event.target.value);
    this.setState({ country });
  };
  handleChangeZone = (event, val) => {
    this.setState({ updatedValues: true });
    const { country } = this.state;
    if (val !== null) {
      country["zoneID"] = val.zoneId;
      country["zoneCode"] = val;
      this.setState({ country });
    } else {
      country["zoneID"] = "";
      country["zoneCode"] = "";
      this.setState({ country });
    }
  };
  handleChangeDHL = (event, val) => {
    this.setState({ updatedValues: true });
    const { country } = this.state;
    if (val !== null) {
      country["dhlZoneID"] = val.dhlZoneID;
      country["dhlZone"] = val;
      this.setState({ country });
    } else {
      country["dhlZoneID"] = "";
      country["dhlZone"] = "";
      this.setState({ country });
    }
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;

    let countryCodeValid = this.state.countryCodeValid;
    let countryNameValid = this.state.countryNameValid;
    let alternateCountryCodeValid = this.state.alternateCountryCodeValid;
    let alternateCountryNameValid = this.state.alternateCountryNameValid;
    let dhlZoneValid = this.state.dhlZoneValid;
    let zoneValid = this.state.zoneValid;
    switch (fieldName) {
      case "countryCode":
        countryCodeValid = validateMaxlength2(value);
        if (!countryCodeValid) {
          fieldValidationErrors.countryCode = countryCodeValid
            ? ""
            : resourceValidation.onlyAlphabet;
        } else {
          if (value.length !== 2) {
            countryCodeValid = false;
            fieldValidationErrors.countryCode = resourceValidation.max2char;
          } else {
            countryCodeValid = true;
            fieldValidationErrors.countryCode = "";
          }
        }
        break;
      case "countryName":
        // countryNameValid = true;
        // fieldValidationErrors.countryName = "";
        // letterShopNameValid = shouldNotEmpty(value);
        // fieldValidationErrors.letterShopName = letterShopNameValid ? '' : resourceValidation.letterShopName;

        countryNameValid = shouldNotEmpty(value);
        fieldValidationErrors.countryName = countryNameValid
          ? ""
          : resourceValidation.countryName;
        break;
      case "alternateCountryCode":
        alternateCountryCodeValid = true;
        fieldValidationErrors.alternateCountryCode = "";
        // alternateCountryCodeValid = notRequired100(value);
        // fieldValidationErrors.alternateCountryCode = alternateCountryCodeValid
        //   ? ""
        //   : resourceValidation.onlyAlphabet;
        break;
      case "alternateCountryName":
        // alternateCountryNameValid = validateNotRequired1000(value);
        // fieldValidationErrors.alternateCountryName = alternateCountryNameValid
        //   ? ""
        //   : resourceValidation.onlyAlphabet;
        alternateCountryNameValid = true;
        fieldValidationErrors.alternateCountryName = "";
        break;

      case "zoneID":
        zoneValid = onlyNumbers(value);
        fieldValidationErrors.zone = zoneValid ? "" : " Please select Zone";
        break;
      case "dhlZoneID":
        dhlZoneValid = true;
        fieldValidationErrors.dhlZone = "";
        // dhlZoneValid = validateMaxlength2(value);
        // fieldValidationErrors.dhlZone = dhlZoneValid
        //   ? ""
        //   : " Please enter alphabet characters only";

        // break;
        // dhlZoneValid = validateMaxlength2(value);
        // if (!dhlZoneValid) {
        //   fieldValidationErrors.dhlZone = dhlZoneValid
        //     ? ""
        //     : resourceValidation.onlyAlphabet;
        // } else {
        //   if (value.length != 2) {
        //     dhlZoneValid = false;
        //     fieldValidationErrors.dhlZone = resourceValidation.max2char;
        //   } else {
        //     dhlZoneValid = true;
        //     fieldValidationErrors.dhlZone = "";
        //   }
        // }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,

        countryCodeValid: countryCodeValid,
        countryNameValid: countryNameValid,
        alternateCountryCodeValid: alternateCountryCodeValid,
        dhlZoneValid: dhlZoneValid,
        alternateCountryNameValid: alternateCountryNameValid,
        zoneValid: zoneValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.state.updatedValues &&
      this.setState({
        formValid: this.state.countryCodeValid && this.state.countryNameValid,
      });

    //return this.state.formValid;
  }

  handleSubmit(e) {
    e.preventDefault();
    let countryID = this.props.match.params.countryId;
    const { country } = this.state;

    let countryCode = country.countryCode;
    let countryName = country.countryName;
    let zoneID = country.zoneID;
    let dhlZoneID = country.dhlZoneID;
    let alternateCountryCode = country.alternateCountryCode;
    let alternateCountryName = country.alternateCountryName;
    let isActive = country.isActive;
    let modifiedBy = localStorage.loggedInId;

    let reqBody = {
      countryCode,
      countryName,
      zoneID,
      dhlZoneID,
      alternateCountryCode,
      alternateCountryName,
      isActive,
      modifiedBy,
    };

    updateCountryID(countryID, reqBody, this.props.history);
  }

  render() {
    let { classes } = this.props;
    let { country, zoneList, active, dhlZoneList } = this.state;

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Country</div>
          <Divider />

          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    disabled
                    variant="filled"
                    margin="normal"
                    label="Country Code"
                    name="countryCode"
                    inputProps={{
                      maxLength: 2,
                    }}
                    autoComplete="off"
                    className={classes.textField}
                    value={country.countryCode}
                    onChange={this.handleChange}
                    error={this.state.errors.countryCode}
                    helperText={
                      this.state.errors.countryCode
                        ? this.state.errors.countryCode
                        : ""
                    }
                  />
                  <TextField
                    required
                    margin="normal"
                    label="Country Name"
                    name="countryName"
                    inputProps={{
                      maxLength: 100,
                    }}
                    autoComplete="off"
                    className={classes.textField}
                    value={country.countryName}
                    onChange={this.handleChange}
                    error={this.state.errors.countryName}
                    helperText={
                      this.state.errors.countryName
                        ? this.state.errors.countryName
                        : ""
                    }
                  />
                </div>
                <div>
                  <Autocomplete
                    label="Zone"
                    name="zoneID"
                    style={{ display: "inline-flex" }}
                    options={zoneList}
                    className={classes.comboBox}
                    onChange={this.handleChangeZone}
                    value={country.zoneCode}
                    getOptionLabel={(option) =>
                      option.zoneCode || country.zoneCode
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Zone"
                        name="zoneID"
                        style={{ width: "100%" }}
                        margin="normal"
                        error={this.state.errors.zone}
                        helperText={
                          this.state.errors.zone ? this.state.errors.zone : ""
                        }
                      />
                    )}
                  />
                  <Autocomplete
                    label="DHL Zone"
                    name="dhlZoneID"
                    style={{ display: "inline-flex" }}
                    options={dhlZoneList}
                    className={classes.comboBox}
                    onChange={this.handleChangeDHL}
                    value={country.dhlZone}
                    getOptionLabel={(option) =>
                      option.dhlZoneName || country.dhlZone
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="DHL Zone"
                        name="dhlZoneID"
                        style={{ width: "100%" }}
                        margin="normal"
                        error={this.state.errors.dhlZone}
                        helperText={
                          this.state.errors.dhlZone
                            ? this.state.errors.dhlZone
                            : ""
                        }
                      />
                    )}
                  />
                </div>
                <div>
                  <TextField
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    label="Alternate Country Code"
                    name="alternateCountryCode"
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={country.alternateCountryCode}
                    onChange={this.handleChange}
                    error={this.state.errors.alternateCountryCode}
                    helperText={
                      this.state.errors.alternateCountryCode
                        ? this.state.errors.alternateCountryCode
                        : ""
                    }
                    autoComplete="off"
                  />
                  <TextField
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    label="Alternate Country Name"
                    name="alternateCountryName"
                    inputProps={{
                      maxLength: 1000,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={country.alternateCountryName}
                    onChange={this.handleChange}
                    error={this.state.errors.alternateCountryName}
                    helperText={
                      this.state.errors.alternateCountryName
                        ? this.state.errors.alternateCountryName
                        : ""
                    }
                    autoComplete="off"
                  />
                </div>
                <div>
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      className={classes.checkBox}
                      name="isActive"
                      control={
                        <Checkbox
                          color="primary"
                          checked={country.isActive}
                          onChange={this.handleChange}
                          value={country.isActive}
                        />
                      }
                      label="Active"
                    />
                  )}
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/country")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ||
                    this.state.country.zoneID == "" ||
                    !this.state.updatedValues ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditCountry));
