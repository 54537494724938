//import React from "react";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { Autocomplete } from "@material-ui/lab";
import Divider from "@material-ui/core/Divider";
import { resourceValidation } from "../../../shared/resource";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import moment from "moment";

import { StateContext } from "../../../shared/globalState";
import {
  getCustomerFromItemMaster,
  getStockInByCustIdList,
  postStockIn,
} from "../genericActions";
import { getWarehouseLocationList, getWarehouseLocationDDListForStockIn } from "../../masters/masterActions";
import "date-fns";
//import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
const onlyNumbers = new Validator("onlyNumbers");
const maxlengthValidator500 = new Validator("maxlength100AllAllow");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  comboBox: {
    //marginTop: theme.spacing(1),
    //marginRight: theme.spacing(1),
    margin: "normal",
    width: "100%",
    fontSize: "11px !important",
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
    display: "flex",

    flex: 1,
    alignItems: "left",
    overflow: "hidden",
  },
  textField1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
    fontSize: "11px !important",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
});

class AddStockIn extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      stockIn: {
        customerId: "",
        stockID: "",
        stock: "",
        warehouseLocation: "",
        warehouseLocationID: "",
        receivedQty: "",
        receivedDate: new Date().toLocaleDateString(),
        remarks: "",
      },
      errors: {},
      customerIdValid: false,
      stockIDValid: false,
      warehouseLocationIDValid: false,
      receivedQtyValid: true,
      receivedDateValid: false,
      warehouseLocationList: [],
      customerList: [],
      remarksValid: true,
      refresh: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.handleSettingHeader("Stock In");
    // getItemMasterList().then(r => {
    //   this.setState({
    //     ItemMasterList: r.data && r.data.sort((a, b) =>
    //       a.stockCode.toLowerCase() > b.stockCode.toLowerCase() ? 1 : -1
    //     )
    //   });
    // });
    this.setState({ ItemMasterList: [] });

    getCustomerFromItemMaster().then((r) => {
      // this.setState({
      //   customerList: r.data && r.data.sort((a, b) =>
      //     a.customerName.toLowerCase() > b.customerName.toLowerCase() ? 1 : -1
      //   )
      // });

      this.getAllCustomers(r.data);
    });

    getWarehouseLocationDDListForStockIn().then((r) => {
      this.setState({
        warehouseLocationList:
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });
  }

  getAllCustomers = (list) => {
    let customerList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        customerList.findIndex((p) => p.id === c.id) > -1
          ? customerList
          : customerList.push(c)
      );

    this.setState({
      customerList:
        customerList &&
        customerList.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };
  // shouldComponentUpdate(s, p) {
  //   const [{ store }, dispatch] = this.context;

  //   if (this.state.customerList.length === 0)
  //     if (this.state.customerList.length !== store.customerList.length)
  //       this.setState({
  //         customerList: store.customerList.sort((a, b) =>
  //           a.customerName.toLowerCase() > b.customerName.toLowerCase() ? 1 : -1
  //         )
  //       });
  // }
  handleDateChange = (date) => {
    const { stockIn } = this.state;
    stockIn["receivedDate"] = date;
    this.setState({ stockIn });
    this.validateField("receivedDate", date);
  };

  handleChange = (event) => {
    const { stockIn } = this.state;
    stockIn[event.target.name] = event.target.value;
    this.setState({ stockIn });

    this.validateField(event.target.name, event.target.value);
  };
  handleCustomerChange = (event, values) => {
    const { stockIn, ItemMasterList } = this.state;

    let customerID = values && values.id;
    stockIn["customer"] = values;
    stockIn["customerID"] = customerID;
    this.validateField("customerID", customerID);
    getStockInByCustIdList(customerID).then((r) => {
      this.setState({
        ItemMasterList:
          r.data &&
          r.data.sort((a, b) =>
            a.stockCode.toLowerCase() > b.stockCode.toLowerCase() ? 1 : -1
          ),
      });
    });
    stockIn["stock"] = null;
    stockIn["stockID"] = "";
    this.validateField("stockID", null);
    this.setState({ stockIn, ItemMasterList });
  };

  handleStockChange = (event, values) => {
    const { stockIn } = this.state;
    let stockID = values && values.stockID;
    stockIn["stock"] = values;
    stockIn["stockID"] = stockID;
    this.validateField("stockID", stockID);
    this.setState({ stockIn });
  };
  handleWarehouselocationChange = (event, values) => {
    const { stockIn } = this.state;

    let warehouseLocationID = values && values.id;
    stockIn["warehouseLocation"] = values;
    stockIn["warehouseLocationID"] = warehouseLocationID;
    this.validateField("warehouseLocationID", warehouseLocationID);
    this.setState({ stockIn });
  };
  _onNoKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let customerIdValid = this.state.customerIdValid;
    let stockIDValid = this.state.stockIDValid;
    let warehouseLocationIDValid = this.state.warehouseLocationIDValid;
    let receivedQtyValid = this.state.receivedQtyValid;
    let receivedDateValid = this.state.receivedDateValid;
    let remarksValid = this.state.remarksValid;
    switch (fieldName) {
      case "customerID":
        if (value !== null) {
          customerIdValid = onlyNumbers(value);
          fieldValidationErrors.customerID = customerIdValid
            ? ""
            : " Please select Valid Customer";
        } else {
          customerIdValid = false;
          fieldValidationErrors.customerID = "";
        }
        break;
      case "stockID":
        if (value !== null) {
          stockIDValid = onlyNumbers(value);
          fieldValidationErrors.stockID = stockIDValid
            ? ""
            : " Please select Valid Stock";
        } else {
          stockIDValid = false;
          fieldValidationErrors.stockID = "";
        }
        break;
      case "warehouseLocationID":
        if (value !== null) {
          warehouseLocationIDValid = onlyNumbers(value);
          fieldValidationErrors.warehouseLocationID = warehouseLocationIDValid
            ? ""
            : " Please select Valid Warehouse Location";
        } else {
          warehouseLocationIDValid = false;
          fieldValidationErrors.warehouseLocationID = "";
        }
        break;
      case "receivedQty":
        if (value && value > 0) {
          receivedQtyValid = onlyNumbers(value);
          fieldValidationErrors.receivedQty = receivedQtyValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          receivedQtyValid = false;
          fieldValidationErrors.receivedQty = "";
        }
        break;
      case "receivedDate":
        if (value !== null) {
          receivedDateValid = true;
          fieldValidationErrors.receivedDate = receivedDateValid
            ? ""
            : "Please select valid Date";
        } else {
          receivedDateValid = false;
          fieldValidationErrors.receivedDate = "";
        }
        break;
      case "remarks":
        if (value !== "") {
          remarksValid = maxlengthValidator500(value);
          fieldValidationErrors.remarks = remarksValid
            ? ""
            : resourceValidation.validRemarks;
        } else {
          remarksValid = true;
          fieldValidationErrors.remarks = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        stockIDValid: stockIDValid,
        customerIdValid: customerIdValid,
        warehouseLocationIDValid: warehouseLocationIDValid,
        receivedQtyValid: receivedQtyValid,
        receivedDateValid: receivedDateValid,
        remarksValid: remarksValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.stockIDValid &&
        //this.state.customerIdValid &&
        // this.state.warehouseLocationIDValid &&
        this.state.receivedQtyValid &&
        this.state.stockIn.warehouseLocationID &&
        // this.state.receivedDateValid &&
        // this.state.warehouseLocationID &&
        this.state.remarksValid &&
        this.state.stockIn.receivedQty

    });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      customerID: "",
      stockID: "",
      warehouseLocationID: "",
      receivedQty: "",
      receivedDate: "",
      remarks: "",
      errors: {},
    });
  };

  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    //if (this.validateForm()) {
    const { stockIn } = this.state;

    //let customerId = stockIn.customerID;
    let stockID = stockIn.stockID;
    let warehouseLocationID = stockIn.warehouseLocationID;
    let receivedQty = stockIn.receivedQty;
    //let receivedDate = stockIn.receivedDate.toLocaleDateString();
    let receivedDate = stockIn.receivedDate;
    let usedQty = "0";
    let createdBy = localStorage.loggedInId;
    let isActive = "true";

    let remarks = stockIn.remarks;

    let reqBody = {
      //customerId,
      stockID,
      warehouseLocationID,
      receivedQty,
      usedQty,
      receivedDate,
      remarks,
      isActive,
      createdBy,
    };
    postStockIn(reqBody, this.props.history);
    // }
  };
  render() {
    let { classes } = this.props;

    let {
      stockIn,
      customerList,
      ItemMasterList,
      warehouseLocationList,
    } = this.state;

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Stock In</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ width: "48%" }}>
                  {" "}
                  <TextField
                    required
                    id="receivedDate"
                    label="Received Date"
                    variant="filled"
                    value={moment(stockIn.receivedDate).format("DD/MM/YYYY")}
                    className={classes.textField1}
                    margin="normal"
                    style={{ display: "inline-flex" }}
                    readOnly
                  />
                </div>
                <div
                  style={{ display: "flex", marginLeft: "6px", width: "100%" }}
                >
                  <Autocomplete
                    autoComplete
                    className={classes.comboBox}
                    // style={{ width: "32.5%" }}
                    id="customerID"
                    includeInputInList
                    options={customerList}
                    Value={customerList}
                    onChange={this.handleCustomerChange}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        autoFocus
                        {...params}
                        label="Customer"
                        className={classes.textField}
                        style={{ marginLeft: "0px" }}
                        name="customerID"
                        margin="normal"
                        required
                        error={this.state.errors.customerID}
                        helperText={
                          this.state.errors.customerID
                            ? this.state.errors.customerID
                            : ""
                        }
                      />
                    )}
                  />
                  <Autocomplete
                    autoComplete
                    // disabled
                    className={classes.comboBox}
                    id="stock"
                    style={{ margin: "0 15px" }}
                    includeInputInList
                    options={ItemMasterList}
                    value={stockIn.stock}
                    onChange={this.handleStockChange}
                    getOptionLabel={(option) => option.stockCode}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Stock"
                        className={classes.textField}
                        name="stock"
                        margin="normal"
                        required
                        error={this.state.errors.stockID}
                        helperText={
                          this.state.errors.stockID
                            ? this.state.errors.stockID
                            : ""
                        }
                      />
                    )}
                  />
                </div>
                <div
                  style={{ display: "flex", marginLeft: "0px", width: "100%" }}
                >
                  <TextField
                    required
                    label="Received Qty"
                    name="receivedQty"
                    inputProps={{
                      maxLength: 10,
                    }}
                    style={{ width: "102%", marginLeft: "6px" }}
                    margin="normal"
                    autoComplete="off"
                    onKeyPress={this._onNoKeyPress}
                    className={classes.textField}
                    value={stockIn.receivedQty}
                    onChange={this.handleChange}
                    error={this.state.errors.receivedQty}
                    helperText={
                      this.state.errors.receivedQty
                        ? this.state.errors.receivedQty
                        : ""
                    }
                  />
                  <Autocomplete
                    autoComplete
                    className={classes.comboBox}
                    id="warehouseLocation"
                    includeInputInList
                    style={{ margin: "0 15px", marginTop: "16px" }}
                    options={warehouseLocationList}
                    Value={warehouseLocationList}
                    onChange={this.handleWarehouselocationChange}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Warehouse Location"
                        className={classes.textField}
                        name="warehouseLocation"
                        style={{ margin: "0px 7px 0 0", width: "101.5%" }}
                        margin="normal"
                        required
                        error={this.state.errors.warehouseLocation}
                        helperText={
                          this.state.errors.warehouseLocation
                            ? this.state.errors.warehouseLocation
                            : ""
                        }
                      />
                    )}
                  />
                </div>

                <div style={{ width: "48.2%" }}>
                  {/* <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    style={{ width: "100%" }}
                  >
                    <KeyboardDatePicker
                      // disablePast
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      style={{ width: "100%" }}
                      id="receivedDate"
                      label="Received Date"
                      required
                      value={stockIn.receivedDate}
                      onChange={this.handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </MuiPickersUtilsProvider> */}

                  <TextField
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    inputProps={{
                      maxLength: 500,
                    }}
                    style={{ marginLeft: "6px" }}
                    label="Remarks"
                    name="remarks"
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={stockIn.remarks}
                    onChange={this.handleChange}
                    error={this.state.errors.remarks}
                    helperText={
                      this.state.errors.remarks ? this.state.errors.remarks : ""
                    }
                  />
                </div>
                {/* <div
                  style={{ display: "flex", marginLeft: "0px", width: "100%" }}
                >
                  
                </div> */}

                <Divider
                  style={{
                    marginTop: "25px",
                  }}
                />
                <div
                  style={{ display: "flex", marginLeft: "6px", width: "100%" }}
                >
                  <div className="button-wrapper">
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        //
                        // color="primary"
                        onClick={(e) => this.props.history.push("/stockIn")}
                      >
                        CANCEL
                      </Button>
                    </div>
                    <div style={{ marginLeft: "1em" }}>
                      {!this.state.formValid ? (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                            onClick={this.handleSubmit}
                          >
                            SUBMIT
                          </Button>
                        )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(AddStockIn));
