import React from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  TextField,
  Button,
  Paper,
  Divider,
  FormControlLabel,
  Typography,
  InputBase,
  Checkbox,
} from "@material-ui/core";
import ReportViewer from "../../app/ReportViewer";
import { withStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import SyncIcon from "@material-ui/icons/Sync";
import { CustomAddIcon } from "../../../shared/customIcons";
import { getRoleAccess } from "../../userAccess/userAccessAction";

import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SearchIcon from "@material-ui/icons/Search";
import {
  GetDisplayWOInJG,
  getOrderList,
  updateJobGroup,
  postJobGroupOrders,
  GetJobGrpDetails,
  DeleteJobGroup,
  CanDeleteJobOrder,
  DeleteJobOrder,
  GetJobGroupsListByWOID,
  ProceedToJobGroupComplete,
  ReOpenCompletedJobGroup
} from "./action";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { blue } from "@material-ui/core/colors";

const styles = (theme) => ({
  test: {
    color: "rgba(0, 0, 0, 0.966) !important",
    //cursor: default !important,
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "29%",
    fontSize: "11px !important",
  },
  autocompleteText: {
    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  iconHover: {
    "&:hover": {
      color: blue[800],
    },
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
  search: {
    position: "relative",
    minWidth: "500px",
    display: "flex",
  },
  searchIcon: {
    position: "absolute",
    right: "0",
  },
});

let workOrderMode = "";
class JobGroup extends React.Component {
  constructor() {
    super();
    this.state = {
      materialRecvDate: new Date().toLocaleDateString(),
      produtionDate: new Date().toLocaleDateString(),
      mailingDate: new Date().toLocaleDateString(),
      orderPopupOpen: false,
      deletePopupOpen: false,
      changesUpdated: false,
      ordersList: [],
      selected: [],
      jobTitle: "",
      statusName: "",
      formValid: false,
      jobGroupID: 0,
      filteredList: [],
      resetEnable: false,
      selectedOrdersList: [],
      filterStr: "",
      roleAccessJG: [],
      selectedOrderID: 0,
      showPopup: false,
      woDisplayDetails: {
        workOrderID: "",
        customerName: "",
        workOrderNumber: "",
        projectTitle: "",
        jobGroupNumber: "",
        statusName: "",
        customerID: "",
        jobGroupID: "",
      },
    };
  }

  componentDidMount() {
    // let userID = window.localStorage.loggedInId;
    // // if (userID) this.callUserRoleAccess(userID);
    // if (userID)
    //   getRoleAccess(userID).then((r) => {
    //     this.setState({ roleAccessJG: r });
    //     let acnArr = r.filter((u) => u.menuName === "Work Order");
    //     let acnObj = acnArr[0];
    //   });
    this.cdmAPIs()
  }
  cdmAPIs = () => {
    this.props.handleDrawerClose();
    workOrderMode =
      this.props.location.state && this.props.location.state.workOrderMode;
    let workOrderID = this.props.workOrderID;

    let jobGroupID = this.props.jobGroupID;

    GetDisplayWOInJG(workOrderID, jobGroupID).then((r) => {
      this.getWoDisplayDetails(r);
    });

    GetJobGrpDetails(jobGroupID).then((r) => {
      //let enable = r.jobOrdersList.length > 0 ? true : false;
      this.setState({
        selectedOrdersList: r.jobOrdersList,
        materialRecvDate:
          r.materialReceivedDate !== null
            ? r.materialReceivedDate
            : this.state.materialRecvDate,
        produtionDate:
          r.productionDate != null
            ? r.productionDate
            : this.state.produtionDate,
        mailingDate:
          r.mailingDate != null ? r.mailingDate : this.state.mailingDate,
        jobTitle:
          r &&
            r.jobTitle != null &&
            r &&
            r.jobTitle != undefined &&
            r &&
            r.jobTitle != ""
            ? r && r.jobTitle
            : this.state.woDisplayDetails.projectTitle,
        statusName: r.statusName,
      });
    });

    if (this.state.filterStr === "") {
      let newFiltered = this.GetNewFilteredOrdersList(this.state.ordersList);
      this.setState({ filteredList: newFiltered });
    }
  }

  getWoDisplayDetails = async (data) => {
    await this.setState({
      woDisplayDetails: data,
    });

    let JobgroupID = this.state.woDisplayDetails.jobGroupID;
    let CustomerID = this.state.woDisplayDetails.customerID;
    getOrderList(JobgroupID, CustomerID).then((r) => {
      let newFiltered = this.GetNewFilteredOrdersList(r.data);

      r.data &&
        this.setState({
          ordersList: r.data.sort((a, b) =>
            a.orderGroupCode.toLowerCase() > b.orderGroupCode.toLowerCase()
              ? 1
              : -1
          ),
          filteredList: newFiltered.sort((a, b) =>
            a.orderGroupCode.toLowerCase() > b.orderGroupCode.toLowerCase()
              ? 1
              : -1
          ),
        });
    });
  };

  GetNewFilteredOrdersList = (ordersList) => {
    let newFiltered = [];
    if (ordersList && ordersList.length > 0) {
      newFiltered = ordersList;
    } else {
      newFiltered = this.state.ordersList;
    }

    this.state.selectedOrdersList.forEach(function (item) {
      const index = newFiltered.findIndex((x) => x.orderID === item.orderID);
      if (index > -1) {
        newFiltered = newFiltered
          .slice(0, index)
          .concat(newFiltered.slice(index + 1, newFiltered.length));
      }
    });

    newFiltered = newFiltered.sort((a, b) =>
      a.orderGroupCode.toLowerCase() > b.orderGroupCode.toLowerCase() ? 1 : -1
    );

    return newFiltered;
  };

  handleOrderPopupOpen = () => {
    this.setState({
      orderPopupOpen: true,
      selected: [],
      filterStr: "",
    });
  };

  handleOrderPopupClose = () => {
    this.setState({
      orderPopupOpen: false,
      selected: [],
      filterStr: "",
    });
  };

  handleDeleteClicked = (event, index, row) => {
    let { selectedOrdersList, ordersList, filteredList } = this.state;
    if (
      row.jobOrderID !== undefined &&
      row.jobOrderID !== null &&
      row.jobOrderID !== 0
    ) {
      CanDeleteJobOrder(row.jobOrderID).then((r) => {
        let deleteJobOrder = r;
        if (deleteJobOrder) {
          DeleteJobOrder(row.jobOrderID).then((r) => {
            selectedOrdersList = selectedOrdersList
              .slice(0, index)
              .concat(
                selectedOrdersList.slice(index + 1, selectedOrdersList.length)
              );
            this.setState({ selectedOrdersList });
          });
        } else {
          this.setState({
            deletePopupOpen: true,
            selectedOrderID: row.jobOrderID,
          });
        }
      });
    }

    const orderIndex = filteredList.findIndex((x) => x.orderID === row.orderID);
    if (orderIndex === -1) {
      let newOrder = ordersList.find((x) => x.orderID === row.orderID);
      if (newOrder && newOrder !== 0) {
        filteredList.push(newOrder);
      }
    }

    if (selectedOrdersList.length === 0) {
      this.setState({
        resetEnable: false,
        changesUpdated: true,
      });
    }
    this.setState({
      selectedOrdersList,
      filteredList: filteredList.sort((a, b) =>
        a.orderGroupCode.toLowerCase() > b.orderGroupCode.toLowerCase() ? 1 : -1
      ),
    });
  };

  deleteJobGroupOrder = () => {
    let { selectedOrdersList } = this.state;
    let jobOrderID = this.state.selectedOrderID;
    DeleteJobOrder(jobOrderID).then((r) => {
      const index = selectedOrdersList.findIndex(
        (x) => x.jobOrderID === jobOrderID
      );
      if (index > -1) {
        selectedOrdersList = selectedOrdersList
          .slice(0, index)
          .concat(
            selectedOrdersList.slice(index + 1, selectedOrdersList.length)
          );
      }

      this.setState({
        deletePopupOpen: false,
        selectedOrderID: [],
        selectedOrdersList,
      });
    });
  };

  handleShowPopup = () => {
    this.setState({
      showPopup: true,
    });
  };
  proceedToCompleteBtcHandled = (e, id) => {
    let uId = localStorage.loggedInId
    // console.log("hh", id)
    ProceedToJobGroupComplete(id, uId).then(x => {
      this.cdmAPIs()
    })
  };
  reOpenBtcHandled = (e, id) => {
    let uId = localStorage.loggedInId
    // console.log("hh", id)
    ReOpenCompletedJobGroup(id, uId).then(x => {
      this.cdmAPIs()
    })
  };
  handleHidePopup = () => {
    this.setState({
      showPopup: false,
    });
  };

  handleDeleteOpen = () => {
    this.setState({
      deletePopupOpen: true,
    });
  };

  handleDeleteClose = () => {
    this.setState({
      deletePopupOpen: false,
    });
  };

  handleClick = (event, row) => {
    let selected = this.state.selected;
    const selectedIndex = selected.indexOf(row.orderID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.orderID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selected: newSelected });
  };

  handleAddOrderPopupSubmit = async () => {
    let { selected, selectedOrdersList, ordersList, filteredList } = this.state;
    let newSelectedOrdersList = [],
      order = {},
      newFilteredOrdersList = filteredList;

    if (filteredList.length > 0) {
      newFilteredOrdersList = ordersList;
    }
    if (selectedOrdersList.length > 0) {
      newSelectedOrdersList = selectedOrdersList;
    }

    selected.forEach(function (item) {
      order = ordersList.find((x) => x.orderID === item);
      if (order && order !== 0) {
        newSelectedOrdersList.push(order);
        const index = newFilteredOrdersList.findIndex(
          (x) => x.orderID === item
        );
        if (index > -1) {
          newFilteredOrdersList = newFilteredOrdersList
            .slice(0, index)
            .concat(
              newFilteredOrdersList.slice(
                index + 1,
                newFilteredOrdersList.length
              )
            );
        }
      }
    });

    if (newFilteredOrdersList.length > 0) {
      newFilteredOrdersList = newFilteredOrdersList.sort((a, b) =>
        a.orderGroupCode.toLowerCase() > b.orderGroupCode.toLowerCase() ? 1 : -1
      );
    }

    await this.setState({
      filteredList: newFilteredOrdersList,
      selectedOrdersList: newSelectedOrdersList,
      changesUpdated: true,
      orderPopupOpen: false,
      selected: [],
      resetEnable: true,
      filterStr: "",
    });
  };

  handleChangeJobGroup = (event) => {
    this.setState({ filterStr: event.target.value });
    if (event.target.value === "") {
      let newFiltered = this.GetNewFilteredOrdersList(this.state.ordersList);
      this.setState({ filteredList: newFiltered });
    }
  };

  handleSearchJobGroup = (event) => {
    let { filterStr, filteredList } = this.state;
    let filter = filteredList.filter((r) => {
      return (
        r.orderCode.toLowerCase().includes(filterStr.toLowerCase()) ||
        r.orderGroupCode.toLowerCase().includes(filterStr.toLowerCase()) ||
        r.fileName.toLowerCase().includes(filterStr.toLowerCase()) ||
        r.orderTitle.toLowerCase().includes(filterStr.toLowerCase()) ||
        r.orderQty.toString().includes(filterStr.toLowerCase())
      );
    });
    this.setState({ filteredList: filter });
  };

  handleJobGroupSubmit = (event) => {
    let workOrderID = this.props.workOrderID;
    let jobGroupID = this.props.jobGroupID;
    // console.log(this.state.changesUpdated)
    if (this.state.changesUpdated) {
      let materialReceivedDate = this.state.materialRecvDate;
      let productionDate = this.state.produtionDate;
      let mailingDate = this.state.mailingDate;
      let jobTitle = this.state.jobTitle;
      let modifiedBy = localStorage.loggedInId;
      let isActive = true;

      let jobQuantity = 0;
      this.state.selectedOrdersList.forEach(function (item) {
        jobQuantity = jobQuantity + item.orderQty;
      });

      let reqBody = {
        workOrderID,
        materialReceivedDate,
        productionDate,
        mailingDate,
        jobTitle,
        jobQuantity,
        modifiedBy,
        isActive,
      };
      let ordersList = [];

      updateJobGroup(
        jobGroupID,
        reqBody,
        this.props.history,
        workOrderMode
      ).then((r) => {
        let order = {},
          jobOrderID = 0;
        this.setState({ jobGroupID: r.jobGroupID });
        if (this.state.selectedOrdersList.length > 0) {
          this.state.selectedOrdersList.forEach(function (item) {
            jobOrderID = 0;
            if (item.jobOrderID !== null && item.jobOrderID !== undefined) {
              jobOrderID = item.jobOrderID;
            }

            order = {
              jobOrderID: jobOrderID,
              jobGroupID: r.jobGroupID,
              orderID: item.orderID,
              createdBy: localStorage.loggedInId,
              isActive: true,
            };
            ordersList.push(order);
          });
          postJobGroupOrders(
            r.workOrderID,
            r.jobGroupID,
            ordersList,
            this.props.history,
            workOrderMode
          );
        }
        // else {
        //   order = {
        //     jobOrderID: 0,
        //     jobGroupID: r.jobGroupID,
        //     orderID: 0,
        //     createdBy: localStorage.loggedInId,
        //     isActive: true
        //   };
        //   ordersList.push(order);
        // }
      });
    } else {
      this.props.history.push({
        pathname: "/process",
        search: "?workOrderID=" + workOrderID + "&jobGroupID=" + jobGroupID,
        state: {
          workOrderMode: workOrderMode,
          statusName: this.state.statusName,
        },
        status: this.props.status,
      });
    }
  };

  handleMaterialRecvDateChange = (date) => {
    this.setState({
      materialRecvDate: date.toLocaleDateString(),
      changesUpdated: true,
    });
  };

  handleProdutionDateChange = (date) => {
    this.setState({
      produtionDate: date.toLocaleDateString(),
      changesUpdated: true,
    });
  };

  handleMailingDateChange = (date) => {
    this.setState({
      mailingDate: date.toLocaleDateString(),
      changesUpdated: true,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      changesUpdated: true,
    });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = this.state.filteredList.map((n) => n.orderID);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClickResetButton = (event) => {
    let ordersList = this.state.selectedOrdersList,
      newFilteredOrdersList = this.state.filteredList,
      newSelectedList = [];

    ordersList.forEach(function (item, index) {
      if (
        item.jobOrderID !== undefined &&
        item.jobOrderID !== null &&
        item.jobOrderID !== 0
      ) {
        newSelectedList.push(item);
      }

      const orderIndex = newFilteredOrdersList.findIndex(
        (x) => x.orderID === item.orderID
      );

      if (orderIndex === -1) {
        let newOrder = ordersList.find((x) => x.orderID === item.orderID);
        if (newOrder && newOrder !== 0) {
          newFilteredOrdersList.push(newOrder);
        }
      }
    });
    if (newFilteredOrdersList.length > 0) {
      newFilteredOrdersList = newFilteredOrdersList.sort((a, b) =>
        a.orderGroupCode.toLowerCase() > b.orderGroupCode.toLowerCase() ? 1 : -1
      );
    }

    this.setState({
      filteredList: newFilteredOrdersList,
      selectedOrdersList: newSelectedList,
      changesUpdated: true,
      resetEnable: false,
    });
  };

  handlCancelClicked = (event, workOrderMode,) => {
    // if (workOrderMode === "edit") {
    //   this.props.history.push({
    //     pathname: "/edit_WorkOrder",
    //     search: "?workOrderID=" + this.props.workOrderID,
    //     state: { workOrderMode: this.props.workOrderMode }
    //   });
    // } else if (workOrderMode === "view" || this.state.statusName == "Completed") {
    //   this.props.history.push({
    //     pathname: "/view_WorkOrder",
    //     search: "?workOrderID=" + this.props.workOrderID,
    //     state: { workOrderMode: this.props.workOrderMode }
    //   });
    // } else {
    //   this.props.history.push("/workOrders");
    // }
    // if (isJobFound)
    //   this.props.history.push("/view_WorkOrder?workOrderID=114");
    // else
    // this.props.history.push("/workOrders");
    GetJobGroupsListByWOID(this.props.workOrderID).then((r) => {
      if (r && r.length === 1) {
        this.props.history.push("/workOrders")
      }
      else if (workOrderMode === "view")
        // this.props.history.push("/view_WorkOrder?workOrderID=114")
        this.props.history.push({
          pathname: "/view_WorkOrder",
          search: "?workOrderID=" + this.props.workOrderID,
          state: { workOrderMode: workOrderMode },
        });
      else if (workOrderMode === "edit")
        // this.props.history.push("/view_WorkOrder?workOrderID=this.props.workOrderID")
        this.props.history.push({
          pathname: "/edit_WorkOrder",
          search: "?workOrderID=" + this.props.workOrderID,
          state: { workOrderMode: workOrderMode },
        });
      else if (this.props.workOrderMode === "create") {
        this.props.history.push("/workOrders")
      }
    });

  };

  render() {
    // let acnArr = this.props.roles.filter((u) => u.menuName === "Code Master");
    // let acnObj = acnArr[0];
    // console.log(this.state.roleAccessJG);

    let {
      filteredList,
      woDisplayDetails,
      selected,
      selectedOrdersList,
    } = this.state;
    let { classes, workOrderMode } = this.props;
    const numSelected = selected.length,
      rowCount = filteredList.length;
    const isSelected = (orderID) =>
      selected && selected.indexOf(orderID) !== -1;
    return (
      <div className={classes.root}>
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <Typography
                component="div"
                className="blueBox"
                style={{ width: "auto" }}
              >
                <div className="blueBoxLabel">
                  <div>Customer: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.customerName}
                  </div>
                </div>
                <div className="blueBoxLabel">
                  <div>Work Order: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.workOrderNumber}
                  </div>
                </div>
                <div className="blueBoxLabel">
                  <div>Project Title: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.projectTitle}
                  </div>
                </div>
              </Typography>
              <Typography
                component="div"
                className="blueBox"
                style={{ marginTop: "2px", width: "auto" }}
              >
                <div className="blueBoxLabel">
                  <div>Job Status: </div>
                  <div className="blueBoxText">{this.state.statusName}</div>
                </div>
                <div className="blueBoxLabel">
                  <div>Job Group: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.jobGroupNumber}
                  </div>
                </div>
                <div className="blueBoxLabel">
                  <div> </div>
                  <div className="blueBoxText"></div>
                </div>
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                {(workOrderMode == "view" || workOrderMode == "edit") && (
                  <div style={{ marginTop: ".5em" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        fontSize: "11px",
                        boxShadow: "none",
                        marginRight: "1em",
                      }}
                      onClick={() => this.handleShowPopup()}
                    >
                      WO Report
                    </Button>
                  </div>
                )}

                {/* {this.state.statusName === "Completed" && ( */}
                <div style={{ marginTop: ".5em" }}>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to={{
                      state: {
                        workOrderMode: this.props.workOrderMode,
                      },
                      pathname: `/runMailList/${this.props.jobGroupID}`,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        fontSize: "11px",
                        boxShadow: "none",
                        textDecoration: "none",
                      }}
                      onClick={(e) => this.props.history.push()}
                    >
                      Run Mail List
                      </Button>
                  </Link>
                </div>
                {/* )} */}
                {((this.state.statusName === "Open" || this.state.statusName === "In Progess") && workOrderMode == "edit") && (
                  <div style={{ marginTop: ".5em", marginLeft: ".7em" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        fontSize: "11px",
                        boxShadow: "none",
                        marginRight: "1em",
                      }}
                      onClick={(e) => this.proceedToCompleteBtcHandled(e, this.props.jobGroupID)}
                    >
                      Proceed to Complete
                    </Button>
                  </div>
                )}
                {((this.state.statusName === "Completed") && workOrderMode == "edit") && (
                  <div style={{ marginTop: ".5em", marginLeft: ".7em" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        fontSize: "11px",
                        boxShadow: "none",
                        marginRight: "1em",
                      }}
                      onClick={(e) => this.reOpenBtcHandled(e, this.props.jobGroupID)}
                    >
                      Re Open
                    </Button>
                  </div>
                )}
              </div>
              {this.state.showPopup && (
                <ReportViewer
                  url={
                    `${window.localStorage.ReportURLByID}` +
                    "WorkOrderReport&IsDirectPrint=True&ReportParam=WorkOrderID&Param1=" +
                    `${this.props.jobGroupID}`
                  }
                  showPopup={this.state.showPopup}
                  onClose={() => this.handleHidePopup()}
                  title={"WO"}
                  handleHidePopup={() => this.handleHidePopup()}
                />
              )}
            </div>
            <div style={{ width: "100%" }} className={classes.test}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                style={{ width: "100%", color: "black" }}
              >
                <Grid
                  container
                  justify="space-between"
                  style={{ alignItems: "baseline" }}
                >
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    disabled={
                      workOrderMode === "view" ||
                      (this.state.statusName == "Completed" ? true : false)
                    }
                    inputVariant={
                      workOrderMode === "view" ||
                        this.state.statusName == "Completed"
                        ? "filled"
                        : "standard"
                    }
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="materialRecvDate-date-picker-inline"
                    name="materialRecvDate"
                    label="Material Received Date"
                    value={this.state.materialRecvDate}
                    onChange={this.handleMaterialRecvDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    style={{ width: "31%" }}
                  />
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    disabled={
                      workOrderMode === "view" ||
                      (this.state.statusName == "Completed" ? true : false)
                    }
                    inputVariant={
                      workOrderMode === "view" ||
                        this.state.statusName == "Completed"
                        ? "filled"
                        : "standard"
                    }
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    name="materialproductionDate"
                    id="materialProductionDate-date-picker-inline"
                    label="Production Date"
                    value={this.state.produtionDate}
                    onChange={this.handleProdutionDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    style={{ width: "31%", margin: "0 5px" }}
                  />
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    disabled={
                      workOrderMode === "view" ||
                      (this.state.statusName == "Completed" ? true : false)
                    }
                    inputVariant={
                      workOrderMode === "view" ||
                        this.state.statusName == "Completed"
                        ? "filled"
                        : "standard"
                    }
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="mailingDate-date-picker-inline"
                    label="Mailing Date"
                    value={this.state.mailingDate}
                    onChange={this.handleMailingDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    style={{ width: "31%" }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              <TextField
                required
                label="Job Title"
                id="title"
                name="jobTitle"
                className={classes.textField}
                inputProps={{
                  maxLength: 100,
                  readOnly:
                    workOrderMode === "view" ||
                    (this.state.statusName == "Completed" ? true : false),
                }}
                //disabled={workOrderMode === "view" || this.state.statusName == "Completed"}
                variant={
                  workOrderMode === "view" ||
                    this.state.statusName == "Completed"
                    ? "filled"
                    : "standard"
                }
                margin="normal"
                autoComplete="off"
                onChange={this.handleChange}
                value={this.state.jobTitle}
                style={{ width: "31%" }}
              // error={errors.projectTitle}
              // helperText={errors.projectTitle ? errors.projectTitle : ""}
              />
            </div>
            <div></div>
            <div className="sub-page-header">Order Information</div>
            <Paper>
              {workOrderMode !== "view" &&
                this.state.statusName !== "Completed" && (
                  <div
                    style={{
                      display: "flex",
                      margin: "4 .8em 5 .6em",
                    }}
                  >
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          boxShadow: "none",
                          padding: "2px",
                          margin: ".5em",
                          fontSize: "11px",
                        }}
                        onClick={this.handleOrderPopupOpen}
                      // style={{
                      //   color: "#fff",
                      //   background: "#3982ea",
                      // }}
                      >
                        <CustomAddIcon style={{ fontSize: "18px" }} /> Add
                      </Button>
                      {!this.state.resetEnable ? (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{
                            boxShadow: "none",
                            padding: "2px",
                            margin: ".5em",
                            fontSize: "11px",
                          }}
                          // style={
                          //   this.state.resetEnable
                          //     ? {
                          //         color: "#fff",
                          //         background: "#3982ea",
                          //         padding: "2px",
                          //         margin: ".5em",
                          //         fontSize: "11px",
                          //       }
                          //     : { color: "#3982ea", fontSize: "11px" }
                          // }
                          onClick={this.handleClickResetButton}
                        >
                          <SyncIcon style={{ fontSize: "18px" }} /> Reset
                        </Button>
                      ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              boxShadow: "none",
                              padding: "2px",
                              margin: ".5em",
                              fontSize: "11px",
                            }}
                            // style={
                            //   this.state.resetEnable
                            //     ? {
                            //         color: "#fff",
                            //         background: "#3982ea",
                            //       }
                            //     : { color: "#3982ea", fontSize: "11px" }
                            // }
                            onClick={this.handleClickResetButton}
                          >
                            <SyncIcon style={{ fontSize: "18px" }} /> Reset
                          </Button>
                        )}
                    </div>
                    <Dialog
                      onClose={this.handleDeleteClose}
                      aria-labelledby="customized-dialog-title"
                      open={this.state.deletePopupOpen}
                      maxWidth="md"
                    >
                      <DialogTitle id="customized-dialog-title">
                        Delete Job Group
                      </DialogTitle>
                      <DialogContent dividers>
                        <div style={{ minWidth: "300px" }}>
                          JobGroup: {woDisplayDetails.jobGroupNumber} has been
                          mapped into process, Are you sure you want to delete ?
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={this.handleDeleteClose}
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                        >
                          Close
                        </Button>
                        <Button
                          onClick={this.deleteJobGroupOrder}
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                        >
                          Delete
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      open={this.state.orderPopupOpen}
                      onClose={this.handleOrderPopupClose}
                      maxWidth="lg"
                      aria-labelledby="form-dialog-title"
                    >
                      <DialogTitle id="form-dialog-title">
                        Add Orders
                      </DialogTitle>
                      <DialogContent dividers>
                        <div className={classes.search}>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            autoComplete="off"
                            onChange={this.handleChangeJobGroup}
                            placeholder="Search Group ID, File Name, Order Title"
                            //type="search"
                            fullWidth
                          />
                          <div className={classes.searchIcon}>
                            <IconButton
                              aria-label="view"
                              className={classes.iconHover}
                              onClick={this.handleSearchJobGroup}
                            >
                              <SearchIcon />
                            </IconButton>
                          </div>
                        </div>
                        <Table
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          size={"small"}
                          aria-label="enhanced table"
                          style={{
                            marginTop: "10px",
                            height: "250px",
                            display: "block",
                            overflowY: "auto",
                          }}
                          stickyHeader
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                padding="checkbox"
                                className="headcells"
                              >
                                {" "}
                                <Checkbox
                                  color="primary"
                                  indeterminate={
                                    numSelected > 0 && numSelected < rowCount
                                  }
                                  checked={numSelected === rowCount}
                                  onChange={this.handleSelectAllClick}
                                  inputProps={{
                                    "aria-label": "select all orders",
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                padding="default"
                                className="headcells"
                              >
                                {" "}
                                <span style={{ color: "#306dca" }}>
                                  Order Group Code
                                </span>{" "}
                              </TableCell>
                              {/* <TableCell
                                padding="default"
                                className="headcells"
                              >
                                {" "}
                                <span style={{ color: "#306dca" }}>
                                  Order Code
                                </span>{" "}
                              </TableCell> */}
                              <TableCell
                                padding="default"
                                className="headcells"
                                style={{ width: "200px" }}
                              >
                                {" "}
                                <span style={{ color: "#306dca" }}>
                                  File Name
                                </span>{" "}
                              </TableCell>
                              <TableCell
                                padding="default"
                                className="headcells"
                                style={{ width: "200px" }}
                              >
                                {" "}
                                <span style={{ color: "#306dca" }}>
                                  Order Title
                                </span>{" "}
                              </TableCell>
                              <TableCell
                                padding="default"
                                className="headcells"
                              >
                                {" "}
                                <span style={{ color: "#306dca" }}>
                                  Order Qty
                                </span>{" "}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredList.map((row, index) => {
                              const isItemSelected = isSelected(row.orderID);
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.orderID}
                                  className="table-content-cell"
                                >
                                  <TableCell padding="checkbox">
                                    <FormControlLabel
                                      onClick={(event) =>
                                        this.handleClick(event, row)
                                      }
                                      control={
                                        <Checkbox
                                          color="primary"
                                          checked={isItemSelected}
                                        />
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.orderGroupCode}
                                    </Typography>
                                  </TableCell>
                                  {/* <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    align="right"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.orderCode}
                                    </Typography>
                                  </TableCell> */}
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    {" "}
                                    {row.fileName &&
                                      row.fileName.length > 16 ? (
                                        <Tooltip
                                          title={row.fileName}
                                          aria-label={row.fileName}
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.fileName}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.fileName}
                                        </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    align="left"
                                    className="table-content-cell"
                                  >
                                    {" "}
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.orderTitle &&
                                        row.orderTitle.length > 16 ? (
                                          <Tooltip
                                            title={row.orderTitle}
                                            aria-label={row.orderTitle}
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.orderTitle}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.orderTitle}
                                          </Typography>
                                        )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    align="right"
                                    className="table-content-right-cell"
                                  >
                                    {" "}
                                    <Typography
                                      noWrap
                                      align="right"
                                      style={{
                                        fontSize: "14px",
                                        textAlign: "right",
                                      }}
                                    >
                                      {row.orderQty}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={this.handleOrderPopupClose}
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                        >
                          CANCEL
                        </Button>
                        {Object.keys(selected).length === 0 ? (
                          <Button
                            disabled
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                            onClick={this.handleAddOrderPopupSubmit}
                          >
                            SUBMIT
                          </Button>
                        ) : (
                            <Button
                              onClick={this.handleAddOrderPopupSubmit}
                              variant="contained"
                              color="primary"
                              style={{ boxShadow: "none" }}
                            >
                              SUBMIT
                            </Button>
                          )}
                      </DialogActions>
                    </Dialog>
                  </div>
                )}

              <div
                style={{
                  maxHeight: "700px",
                  //margin: "0 .8em 0 .6em"
                }}
              >
                <div style={{ maxHeight: 650, overflow: "auto" }}>
                  <Table
                    aria-labelledby="tableTitle"
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          padding="default"
                          align="left"
                          className="headcells"
                        // style={{
                        //   background: "#ffffff"
                        // }}
                        >
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            Order Group Code
                          </span>{" "}
                        </TableCell>
                        <TableCell
                          padding="default"
                          className="headcells"
                        // style={{
                        //   background: "#ffffff"
                        // }}
                        >
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            Order Code
                          </span>{" "}
                        </TableCell>
                        <TableCell padding="default" className="headcells">
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            File Name
                          </span>{" "}
                        </TableCell>
                        <TableCell padding="default" className="headcells">
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            Order Title
                          </span>{" "}
                        </TableCell>
                        <TableCell
                          padding="default"
                          className="headcells"
                          align="left"
                        >
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            Order Qty
                          </span>{" "}
                        </TableCell>
                        {workOrderMode !== "view" &&
                          this.state.statusName !== "Completed" && (
                            <TableCell padding="default" className="headcells">
                              <span style={{ color: "#306dca" }}>Actions</span>{" "}
                            </TableCell>
                          )}
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ overflow: "hidden" }}>
                      {selectedOrdersList &&
                        selectedOrdersList.map((row, index) => {
                          return (
                            <TableRow role="checkbox" tabIndex={-1}>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.orderGroupCode}
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.orderCode}
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {" "}
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.fileName}
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {" "}
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.orderTitle}
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {" "}
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.orderQty}
                                </Typography>
                              </TableCell>
                              {workOrderMode !== "view" &&
                                this.state.statusName !== "Completed" && (
                                  <TableCell className="table-content-cell">
                                    <Tooltip title="Delete">
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: "#6c6b6b",
                                        }}
                                        onClick={(e) =>
                                          this.handleDeleteClicked(
                                            e,
                                            index,
                                            row
                                          )
                                        }
                                      >
                                        <DeleteIcon
                                          style={{
                                            cursor: "pointer",
                                            color: "#6c6b6b",
                                          }}
                                        />
                                      </span>
                                    </Tooltip>
                                  </TableCell>
                                )}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </Paper>

            <Divider
              style={{
                marginTop: "25px",
              }}
            />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.handlCancelClicked(e, workOrderMode)}
                >
                  CANCEL
                </Button>
              </div>
              <div style={{ marginLeft: "1em" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) =>
                    // this.props && this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.isJobFound ?
                    // this.props.history.goBack() :
                    this.props.history.push({
                      pathname: "/WorkOrder",
                      search: "?workOrderID=" + this.props.workOrderID,
                      state: {
                        workOrderMode: this.props.workOrderMode,
                        jobGroupID: this.props.jobGroupID,
                        woMode: this.props.location.state.woMode,
                        // isJobFound: true
                      },
                    })
                    // this.props.history.goBack()
                  }
                >
                  BACK
                </Button>
              </div>
              <div style={{ marginLeft: "1em" }}>
                {!this.state.jobTitle
                  // ||
                  //   this.state.statusName == "Completed"
                  ?
                  (
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={this.handleJobGroupSubmit}
                    >
                      NEXT
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={this.handleJobGroupSubmit}
                    >
                      NEXT
                    </Button>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(JobGroup));
