import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import "./home.css";
class home extends Component {
  componentDidMount = () => {
    this.props.handleSettingHeader("Home");
    this.props && this.props.setSelectedList && this.props.setSelectedList("home", "home");
  };
  render() {
    return (
      <div>
        <Paper>
          <p style={{ padding: "15px", background: "#C9D3E7", color: "#BD2D2A", fontWeight: "bold", textAlign: "center" }}>PGS Dashboard coming soon...</p>
          {/* <div>
            <p
              style={{
                "padding-top": "1em",
                margin: "1em",
                "font-size": "15px",
                "font-weight": "normal",
                "font-family": "Roboto",
              }}
            >
              Singapore Post (SingPost) is the leading provider of mail,
              logistics and ecommerce solutions in Singapore and the Asia
              Pacific region, with operations in more than 10 countries.
            </p>
          </div> */}
        </Paper>
      </div>
    );
  }
}

export default home;
