import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Paper, Divider } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { addDispatchStyles } from "../../../shared/styles";
import { StateContext } from "../../../shared/globalState";
import { getinsertMaster } from "../../masters/masterActions";
import { printDetails } from "../../../shared/tableCommonFunctions";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon } from "../../../shared/customIcons";
import PrintIcon from "@material-ui/icons/Print";
import { getByIdStockIn, getByIdStockOut } from "../stockaction";

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { selectedstockId } = props;
  var publisherBackLabelManage = props.roles.filter((r) => {
    return r.superMenuName === "Publisher Back Label";
  });
  let blsiArr =
    publisherBackLabelManage.length > 0 &&
    publisherBackLabelManage.filter((u) => u.menuName === "Stock Out");
  var acnObj = blsiArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "8px 10px 8px 9px", margin: "7px 15px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewBackLabelStockOut extends React.Component {
  static contextType = StateContext;
  state = {
    acronym: "",
    volume: "",
    issue: "",
    suppNumber: "",
    issueDescription: "",
    pubYear: "",
    warehouseLocationName: "",
    stockoutQty: "",
    remarks: "",
    stockType: "",
    reason: "",
    receivedDate: "",
    stockoutDate: "",
    isActive: "",
  };

  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Back Label Stock Out");
    this.props.setSelectedList("publisherBackLabelOpen", "backLabelStockOut");

    let stockId =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];

    getByIdStockOut(stockId).then((r) => {
      this.setState({
        acronym: r.data.acronym != null && r.data.acronym.trim(),
        volume: r.data.volume != null && r.data.volume.trim(),
        issue: r.data.issue != null && r.data.issue.trim(),
        suppNumber: r.data.supp != null && r.data.supp,
        issueDescription: r.data.issueDescription,
        stockType: r.data.stockType,
        reason: r.data.reason != null && r.data.reason.trim(),
        stockoutQty: r.data.stockoutQty,
        remarks: r.data.remarks != null && r.data.remarks.trim(),
        warehouseLocationName:
          r.data.warehouseLocationName != null &&
          r.data.warehouseLocationName.trim(),
        stockoutDate: r.data.stockoutDate != null && r.data.stockoutDate,
      });
    });
  }
  render() {
    let id =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            id={id}
            selectedstockId={id}
            roles={this.props.roles}
          />
          <Divider />
          <div className="view-page-container" id="printable">
            {/* <div
              className="view-page-headings"
              style={{
                height: "2em",
              }}
            >
              Stock Out Information
            </div> */}
            {/* <Divider /> */}
            <div>
              <div>
                <p
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    fontWeight: "normal",
                    paddingTop: "1em",
                    marginBottom: "1em",
                    borderBottom: "1px solid #c1baba",
                    paddingBottom: "0.5em",
                  }}
                >
                  Journal Details
                </p>
                <div className="view-page-contents-container">
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "19.5%" }}
                  >
                    <div className="view-page-label">Acronym</div>
                    <div className="view-page-value">{this.state.acronym}</div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "19.5%" }}
                  >
                    <div className="view-page-label">Volume</div>
                    <div className="view-page-value">{this.state.volume}</div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "19.5%" }}
                  >
                    <div className="view-page-label">Issue No.</div>
                    <div className="view-page-value">{this.state.issue}</div>
                  </div>{" "}
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "19.5%" }}
                  >
                    <div className="view-page-label">Supp No.</div>
                    <div className="view-page-value">
                      {this.state.suppNumber}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "19.5%" }}
                  >
                    <div className="view-page-label">Issue Description</div>
                    <div className="view-page-value">
                      {this.state.issueDescription}
                    </div>
                  </div>
                </div>
              </div>
              <p
                style={{
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  fontWeight: "normal",
                  paddingTop: "1em",
                  marginBottom: "1em",
                  borderBottom: "1px solid #c1baba",
                  height: "2em",
                }}
              >
                Stock Details
              </p>
              <div className="view-page-contents-container">
                <div
                  className="view-page-contents-sub-container"
                  style={{ width: "19.5%" }}
                >
                  <div className="view-page-label">Stock Type</div>
                  <div className="view-page-value">{this.state.stockType}</div>
                </div>
                <div
                  className="view-page-contents-sub-container"
                  style={{ width: "19.5%" }}
                >
                  <div className="view-page-label">Reason</div>
                  <div className="view-page-value">{this.state.reason}</div>
                </div>
                <div
                  className="view-page-contents-sub-container"
                  style={{ width: "19.5%" }}
                >
                  <div className="view-page-label">Remarks</div>
                  <div className="view-page-value">{this.state.remarks}</div>
                </div>
                <div
                  className="view-page-contents-sub-container"
                  style={{ width: "19.5%" }}
                >
                  <div className="view-page-label">Stockout Qty</div>
                  <div className="view-page-value">
                    {this.state.stockoutQty}
                  </div>
                </div>
                <div
                  className="view-page-contents-sub-container"
                  style={{ width: "19.5%" }}
                >
                  <div className="view-page-label">Location</div>
                  <div className="view-page-value">
                    {this.state.warehouseLocationName}
                  </div>
                </div>
              </div>
              <div
                className="view-page-contents-sub-container"
                style={{ width: "19.5%" }}
              >
                <div className="view-page-label">Stockout Date</div>
                <div className="view-page-value">
                  {moment(this.state.stockoutDate).format("DD/MM/YYYY HH:mm:ss")}
                </div>
              </div>
              <Divider style={{ marginTop: "25px" }} />
              <div className="button-wrapper">
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    //disableElevation
                    style={{ boxShadow: "none" }}
                    onClick={(e) =>
                      this.props.history.push("/backLabelStockOut")
                    }
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addDispatchStyles)(ViewBackLabelStockOut));
