import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { uploadCPTFiles, downloadFileForCPT, submitCPTFiles } from "./action";
import * as XLSX from "xlsx";
import InfoIcon from "@material-ui/icons/Info";
import DescriptionIcon from "@material-ui/icons/Description";
import LinearProgress from "@material-ui/core/LinearProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import IconButton from "@material-ui/core/IconButton";
import * as FileSaver from "file-saver";
import { DownloadIcon } from "../../../shared/customIcons";
import moment from "moment";
import { Dialog } from "@material-ui/core";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
const exportToCSV = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

function createData(
  BillingAccount,
  Currency,
  DestinationCountryName,
  Destination,
  InvoiceDate,
  InvoiceNumber,
  OriginName,
  Origin,
  pieces,
  ProductName,
  ReceiversAddress1,
  ReceiversContact,
  ReceiversName,
  SendersAddress1,
  SendersContact,
  SendersName,
  ShipmentDate,
  ShipmentNumber,
  ShipmentReference1,
  TotalAmount_incl__VAT_,
  WeightCharge,
  Weight_kg_,
  XC1Charge,
  XC2Charge,
  XC2Name,
  Valid,
  ExceptionMessage
) {
  return {
    BillingAccount,
    Currency,
    DestinationCountryName,
    Destination,
    InvoiceDate,
    InvoiceNumber,
    OriginName,
    Origin,
    pieces,
    ProductName,
    ReceiversAddress1,
    ReceiversContact,
    ReceiversName,
    SendersAddress1,
    SendersContact,
    SendersName,
    ShipmentDate,
    ShipmentNumber,
    ShipmentReference1,
    TotalAmount_incl__VAT_,
    WeightCharge,
    Weight_kg_,
    XC1Charge,
    XC2Charge,
    XC2Name,
    Valid,
    ExceptionMessage,
  };
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    // marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: "23%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
});

function DetectionControl(file) {
  return (
    <div
      style={{
        width: "45%",
        display: "flex",
        alignItems: "center",
        marginTop: "-1px",
      }}
    >
      <span>
        {file && file.fileName && file.fileName.isValid == false ? (
          <InfoIcon style={{ color: "red", fontSize: "19px" }} />
        ) : (
          <CheckCircleIcon
            style={{ color: "rgba(59, 218, 182, 0.87)", fontSize: "19px" }}
          />
        )}
      </span>
      <span
        style={{
          marginTop: "-5px",
          marginLeft: "5px",
          fontSize: "11px",
        }}
      >
        {file &&
        file.fileName &&
        file.fileName.isValid == false &&
        file.fileName.exceptionMessage.includes("MissMatch")
          ? "Template Mismatch"
          : file &&
            file.fileName &&
            file.fileName.isValid == false &&
            file.fileName.exceptionMessage.includes("download")
          ? "Exception Found please download and reupload."
          : file.fileName.isValid == true
          ? "Uploaded successfully "
          : file.fileName.exceptionMessage}
      </span>
    </div>
  );
}

class AddCPT extends React.Component {
  constructor() {
    super();

    this.state = {
      fileName: "",
      list: [],
      editableList: [],
      loading: false,
    };
    this.myRef = React.createRef();
  }

  handleJournalUpdate = (selectedJournalList) => {
    this.setState({
      journalData: selectedJournalList[0],
      openDialog: false,
    });
  };

  clearJournalInfo = (i) => {
    let newjournalData = { ...this.state.journalData };
    newjournalData = {};
    this.setState({ workOrder: newjournalData });
  };

  handleCapture = ({ target }) => {
    const arrayOfObj = Object.values(target.files);
    let msgArray = this.state.msg;
    let arrayOfObjFiltered = arrayOfObj.filter(
      (x) => !msgArray.filter((y) => y.name === x.name).length
    );
    this.setState({ msg: [...this.state.msg, ...arrayOfObjFiltered] });
  };

  componentDidMount() {
    this.props.handleSettingHeader("Back Label Billing Process");
    this.props.setSelectedList(
      "publisherBackLabelOpen",
      "backLabelBillingProcess"
    );
  }

  downloadSelectedFile = async (id, name) => {
    name = name.slice(0, name.length - 5);

    // name = name + "_" + moment(new Date()).format("DD/MM/YYYY");

    downloadFileForCPT(id).then((r) => {
      let newRows = [];
      r &&
        r.data &&
        r.data.map((d) => {
          newRows.push(
            createData(
              d.billingAccount,
              d.currency,
              d.destCountryName,
              d.destination,
              d.invoiceDate,
              d.invoiceNumber,
              d.origName,
              d.origin,
              d.pieces,
              d.productName,
              d.receiversAddress1,
              d.receiversContact,
              d.receiversName,
              d.sendersAddress1,
              d.sendersContact,
              d.sendersName,
              d.shipmentDate,
              d.shipmentNumber,
              d.shipmentReference1,
              d.totalAmount_incl__VAT_,
              d.weightCharge,
              d.weight_kg_,
              d.xC1Charge,
              d.xC2Charge,
              d.xC2Name,
              d.isValid,
              d.exceptionMessage
            )
          );
        });
      exportToCSV(newRows, name);
    });
  };

  checkOGDisability = () => {
    let notValid = false;
    if (this.state.list.length === 0) return true;
    else;
    {
      this.state.list.map((l) => {
        if (l.exceptionMessage === "") notValid = false;
        else {
          notValid = true;
        }
      });

      return notValid;
    }
  };

  handleUpload = (e) => {
    let files = e.target.files;
    var reader = new FileReader();
    let _error = "";
    this.setState({ loading: true });
    if (files[0]) {
      this.setState({
        fileName: files[0].name,
        // disableAdd: true,
        disableEdit: false,
      });
      reader.onload = function (e) {
        let data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      };

      reader.readAsBinaryString(files[0]);
      let loggedInId = localStorage.getItem("loggedInId");
      const formData = new FormData();
      formData.append("UploadFiles", files[0]);
      formData.append("CreatedBy", loggedInId);

      // formData.append("preAlertheaderID ", this.state.PreAlertHeaderID);

      uploadCPTFiles(formData).then((r) => {
        let newList = [];
        if (Array.isArray(r)) {
          newList.push({
            dHLHeaderID: r && r[0] && r[0].dHLHeaderID,
            exceptionMessage: r && r[0] && r[0].exceptionMessage,
            fileLocation: r && r[0] && r[0].fileLocation,
            fileName: r && r[0] && r[0].fileName,
            isValid: r && r[0] && r[0].isValid,
            size: r && r[0] && r[0].size,
          });
        } else {
          newList.push({
            dHLHeaderID: r && r.dHLHeaderID,
            exceptionMessage: r && r.exceptionMessage,
            fileLocation: r && r.fileLocation,
            fileName: r && r.fileName,
            isValid: r && r.isValid,
            size: r && r.size,
          });
        }

        this.setState({
          list: newList,
          editableList: newList,
          disableEdit: false,
          loading: false,
        });
      });
    } else {
      this.setState({
        fileName: "",
        loading: false,
      });
    }
  };

  handleSubmit = (e) => {
    let gettheid = this.state.list.map((val) => {
      return val.dHLHeaderID;
    });
    let reqBody = {};
    reqBody.invoiceHeaderID = gettheid[0];
    submitCPTFiles(reqBody, this.props.history).then((r) => {});
  };

  render() {
    let { classes } = this.props;
    let { fileName, list, editableList } = this.state;

    let isToShowDownload = list.filter((val) => {
      return val;
    });
    return (
      <React.Fragment>
        <Paper style={{ padding: "0px 0 10px 0" }}>
          <div className="pop-up-header">Create New CPT</div>
          <Divider />
          <div
            className={classes.container}
            style={{ padding: "0.5em 1em 0 1em" }}
          >
            <div>
              <div
                style={{
                  width: "auto",
                  border: "2px dashed #eaeaea",
                  margin: "0",
                  borderRadius: "4px",
                }}
              >
                <label
                  className="upload excel"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    minHeight: "40px",
                    // cursor: "pointer",
                    padding: ".3em",
                  }}
                >
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => this.handleUpload(e)}
                    accept=".xlsx"
                  />
                  <div
                    style={{
                      backgroundColor: "#3982ea",
                      color: "#fff",
                      borderRadius: "4px",
                      padding: ".3em 1em .3em 1em",
                      cursor: "pointer",
                    }}
                  >
                    CHOOSE FILE
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      width: "auto",
                      marginLeft: "12px",
                      width: "320px",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "500",
                        fontSize: "13px",
                      }}
                    >
                      {fileName ? fileName : "No file choosen"}
                    </div>
                    <div className="file-upload-footer">
                      {fileName
                        ? ""
                        : "Allowed only “.xlsx” format, 500KB file limit."}
                    </div>
                  </div>
                </label>
              </div>
              {!this.state.loading ? (
                <div>
                  <div>
                    {list.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          margin: "1em",
                          // marginTop: "2em",
                          paddingBottom: ".5em",
                          borderBottom: "1px solid #eaeaea",
                          fontSize: "14px",
                          marginLeft: "0px",
                          marginRight: "0px",
                        }}
                      >
                        <div style={{ width: "30%", fontWeight: "500" }}>
                          File Info
                        </div>
                        <div
                          style={{
                            width: "10%",
                            fontWeight: "500",
                            margin: "0 10px",
                          }}
                        >
                          Size
                        </div>
                        <div style={{ width: "45%", fontWeight: "500" }}>
                          Detection
                        </div>
                        {isToShowDownload &&
                          isToShowDownload[0] &&
                          isToShowDownload[0].exceptionMessage.includes(
                            "download"
                          ) && (
                            <div style={{ width: "10%", fontWeight: "500" }}>
                              Action
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                  {list.map((file, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          margin: "1em",
                          paddingBottom: ".5em",
                          borderBottom: "1px solid #eaeaea",
                          fontSize: "12px",
                          marginLeft: "0px",
                          marginRight: "0px",
                        }}
                      >
                        <div style={{ width: "30%", fontWeight: "500" }}>
                          <div style={{ display: "flex" }}>
                            <DescriptionIcon
                              style={{
                                color: "#306dca",
                                marginTop: "-4px",
                                marginRight: "5px",
                              }}
                            />{" "}
                            <span> {file.fileName}</span>
                          </div>
                        </div>
                        <div style={{ width: "10%", margin: "0 10px" }}>
                          {Math.ceil(parseInt(file.size) / 1024) + " kb"}
                        </div>
                        {!this.state.loading ? (
                          <DetectionControl fileName={file} />
                        ) : (
                          <div style={{ width: "45%", marginTop: ".6em" }}>
                            <LinearProgress />
                          </div>
                        )}

                        {file.exceptionMessage.includes("download") && (
                          <IconButton
                            size="small"
                            style={{ marginTop: "-7px" }}
                            onClick={(e) => {
                              this.downloadSelectedFile(
                                file.dHLHeaderID,
                                file.fileName
                              );
                            }}
                          >
                            {" "}
                            <DownloadIcon style={{ fontSize: "16px" }} />
                          </IconButton>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Dialog
                  aria-labelledby="simple-dialog-title"
                  open={this.state.loading}
                >
                  <div style={{ padding: "1em" }}>
                    <CircularProgress />
                  </div>
                </Dialog>
              )}
              <Divider style={{ marginTop: "20px" }} />
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  marginLeft: "2px",
                }}
              >
                <div style={{}}>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ boxShadow: "none" }}
                    onClick={(e) => {
                      this.props.history.push("/backLabelBillingProcess");
                    }}
                  >
                    CANCEL
                  </Button>
                </div>
                <div style={{ marginLeft: "1em" }}>
                  {this.checkOGDisability() ? (
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                    >
                      SUBMIT
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.handleSubmit(e)}
                    >
                      SUBMIT
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(AddCPT));
