import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import {Autocomplete} from "@material-ui/lab" ;
import { withRouter } from "react-router-dom";
import { getSubMenu, updateSubMenuID, getMenuList } from "../userAccessAction";
import Validator from "../../../shared/validator";
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";
import { addStyles } from "../../../shared/styles";
const validateNotRequired100 = new Validator("maxlength100AllAllow");
const validateNotRequired500 = new Validator("maxlength100AllAllow");
const onlyNumbers = new Validator("onlyNumbers");

class EditSubMenu extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      subMenu: {
        menuID: "",
        menuName: "",
        subMenuName: "",
        description: "",
        orderNo: "",
        isReport: false,
        isActive: false,
        modifiedBy: 1
      },
      errors: {},
      active: false,
      selectedDDL: 0,
      menuValid: true,
      subMenuNameValid: true,
      descriptionValid: true,
      orderNoValid: true,
      menuList: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let subMenuId = this.props.match.params.subMenuId;

    getSubMenu(subMenuId).then(r => {
      dispatch({
        type: "getSubMenu",
        newStore: r
      });
      this.setState({ subMenu: r });
      if (r.isActive) this.setState({ active: r.isActive });
    });
    getMenuList().then(r => {
      dispatch({
        type: "getMenuList",
        newStore: r.data
      });
      this.getAllMenus(r.data);
    });
  }
  getAllMenus = list => {
    let menuList = [];
    list &&
      list.length > 0 &&
      list.map(c =>
        c.isActive
          ? menuList.push({
              key: c.menuID,
              value: c.menuName,
              active: c.isActive
            })
          : menuList
      );
    this.setState({
      menuList: menuList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      )
    });
  };

  handleChange = event => {
    const { subMenu } = this.state;
    if (event.target.name === "isReport" || event.target.name === "isActive") {
      subMenu[event.target.name] = event.target.checked;
    } else {
      subMenu[event.target.name] = event.target.value;
    }
    this.setState({ subMenu });

    this.validateField(event.target.name, event.target.value);
  };

  handleMenuChange = (event, values) => {
    const { subMenu } = this.state;
    if (values === null) {
      subMenu["menuID"] = "null";
    } else {
      let menuID = values.key;
      let menuName = values.value;
      subMenu["menuID"] = menuID;
      subMenu["menuName"] = menuName;
    }
    this.setState({ subMenu });

    this.validateField("menuID", subMenu["menuID"]);
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let subMenuNameValid = this.state.subMenuNameValid;
    let menuValid = this.state.menuValid;
    let descriptionValid = this.state.descriptionValid;
    let orderNoValid = this.state.orderNoValid;
    switch (fieldName) {
      case "subMenuName":
        if (value !== "") {
          subMenuNameValid = validateNotRequired100(value);
          fieldValidationErrors.subMenuName = subMenuNameValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          subMenuNameValid = false;
          fieldValidationErrors.subMenuName = "";
        }
        break;
      case "menuID":
        if (value !== "null") {
          menuValid = onlyNumbers(value);
          fieldValidationErrors.menuID = menuValid ? "" : " Please select Menu";
        } else {
          menuValid = false;
          fieldValidationErrors.menuName = "";
        }
        break;
      case "description":
        if (value !== "") {
          descriptionValid = validateNotRequired500(value);
          fieldValidationErrors.description = descriptionValid
            ? ""
            : resourceValidation.ValidDescription;
        } else {
          descriptionValid = false;
          fieldValidationErrors.description = "";
        }
        break;
      case "orderNo":
        if (value !== "" && value !== "null") {
          orderNoValid = onlyNumbers(value);
          fieldValidationErrors.orderNo = orderNoValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          orderNoValid = false;
          fieldValidationErrors.orderNo = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        subMenuNameValid: subMenuNameValid,
        menuValid: menuValid,
        descriptionValid: descriptionValid,
        orderNoValid: orderNoValid
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.subMenuNameValid &&
        this.state.menuValid &&
        this.state.descriptionValid &&
        this.state.orderNoValid
    });
    return this.state.formValid;
  }
  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    let subMenuId = this.props.match.params.subMenuId;
    const { subMenu } = this.state;
    subMenu.modifiedBy = localStorage.loggedInId;
    updateSubMenuID(subMenuId, subMenu).then(r => {
      this.props.history.push("/SubMenu");
    });
  }

  render() {
    let { classes } = this.props;
    let { subMenu, active, selectedDDL, menuList } = this.state;
    let loop = 0;
    menuList.map(option =>
      option.key === this.state.subMenu.menuID
        ? (selectedDDL = loop)
        : (loop = loop + 1)
    );
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Sub Menu</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <TextField
                    required
                    label="Sub Menu Name"
                    name="subMenuName"
                    inputProps={{
                      maxLength: 50
                    }}
                    margin="normal"
                    className={classes.autocompleteText}
                    value={subMenu.subMenuName}
                    onChange={this.handleChange}
                    error={this.state.errors.subMenuName}
                    helperText={
                      this.state.errors.subMenuName
                        ? this.state.errors.subMenuName
                        : ""
                    }
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    required
                    id="menu"
                    name="menu"
                    hintText="menu"
                    className={classes.autocompleteText}
                    options={menuList}
                    onChange={this.handleMenuChange}
                    getOptionLabel={option => option.value}
                    value={menuList[selectedDDL]}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Select Menu"
                        name="menuName"
                        id="menuName"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={subMenu.menuName}
                        error={this.state.errors.menuID}
                        helperText={
                          this.state.errors.menuID
                            ? this.state.errors.menuID
                            : ""
                        }
                      />
                    )}
                  />
                  <TextField
                    required
                    multiline={false}
                    rows={1}
                    rowsMax={4}
                    inputProps={{
                      maxLength: 100
                    }}
                    label="Description"
                    name="description"
                    margin="normal"
                    className={classes.autocompleteText}
                    value={subMenu.description}
                    onChange={this.handleChange}
                    error={this.state.errors.description}
                    helperText={
                      this.state.errors.description
                        ? this.state.errors.description
                        : ""
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Order Number"
                    name="orderNo"
                    inputProps={{
                      maxLength: 3
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={subMenu.orderNo}
                    onChange={this.handleChange}
                    error={this.state.errors.orderNo}
                    helperText={
                      this.state.errors.orderNo ? this.state.errors.orderNo : ""
                    }
                    onKeyPress={this._onNumberKeyPress}
                  />
                  <FormControlLabel
                    name="isReport"
                    className={classes.checkBox}
                    control={
                      <Checkbox
                        color="primary"
                        checked={subMenu.isReport}
                        onChange={this.handleChange}
                        value={subMenu.isReport}
                      />
                    }
                    label="Report"
                  />
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      name="isActive"
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          checked={subMenu.isActive}
                          onChange={this.handleChange}
                          value={subMenu.isActive}
                        />
                      }
                      label="Active"
                    />
                  )}
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={e => this.props.history.push("/subMenu")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    <Button
                      onClick={this.handleSubmit}
                      disabled={!this.state.formValid}
                      autoFocus
                      variant="contained"
                      className={
                        !this.state.formValid
                          ? "disabled-button"
                          : "enabled-button "
                      }
                    >
                      SUBMIT
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditSubMenu));
