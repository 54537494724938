import React, { Component } from "react";

class DeliveryNoteManifest extends Component {
  state = {};
  componentDidMount() {
    this.props.handleSettingHeader("Delivery Note Report");
    this.props.setSelectedList("masterDataOpen", "deliveryNoteManifest");
  }
  render() {
    return (
      <div>
        <iframe
          style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
          src={
            `${window.localStorage.ReportURLByID}` + "DeliveryNoteManifestReport"
          }
        />
      </div>
    );
  }
}

export default DeliveryNoteManifest;
