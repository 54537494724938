const COLECTIONNOTE_LIST = "COLECTIONNOTE_LIST";
const JOBSCHEDULE_LIST = "JOBSCHEDULE_LIST";
const DELIVERYNOTE_LIST = 'DELIVERYNOTE_LIST';
const JOBGROUP_LIST = 'JOBGROUP_LIST';

export default {
    COLECTIONNOTE_LIST,
    DELIVERYNOTE_LIST,
    JOBSCHEDULE_LIST,
    JOBGROUP_LIST
};
