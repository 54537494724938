import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { resourceValidation } from "../../../shared/resource";
import { postLetterShop } from "../masterActions";
import { addStyles } from "../../../shared/styles";

const checklettershopcode = new Validator("checklettershopcode");
const shouldNotEmpty = new Validator("general");

const styles = (theme) => ({
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "31%",
    fontSize: "11px !important",
  },
});

class AddLetterShop extends React.Component {
  constructor() {
    super();

    this.state = {
      letterShop: {
        letterShopCode: "",
        letterShopName: "",
        chargeType: "",
        unitRate: "0.00",
        createdBy: 1,
      },
      errors: {},
      letterShopCodeValid: false,
      letterShopNameValid: false,
      chargeTypeValid: false,
      unitRateValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.handleSettingHeader("Lettershop");
  }

  handleChange = (e) => {
    const { letterShop } = this.state;
    let value = e.target.value;
    if (e.target.name === "unitRate") {
      value = this.checkDHlWeightDec(e.target.value.toString());
    }
    letterShop[e.target.name] = value;
    if (e.target.name === "letterShopCode" && e.target.value)
      letterShop[e.target.name] = value.toUpperCase()
    this.validateField(e.target.name, e.target.value);
    this.setState({ letterShop });
  };

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  onBlur = (event) => {
    const { letterShop } = this.state;
    let letterShopValues = event.target.value;
    letterShopValues = this.addZeroes(letterShopValues.toString(), 2);
    letterShop[event.target.name] = letterShopValues;
    this.setState({ letterShop });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let letterShopCodeValid = this.state.letterShopCodeValid;
    let letterShopNameValid = this.state.letterShopNameValid;
    let chargeTypeValid = this.state.chargeTypeValid;
    let unitRateValid = this.state.unitRateValid;
    switch (fieldName) {
      case "letterShopCode":
        if (value.length < 4) {
          letterShopCodeValid = false;
          fieldValidationErrors.letterShopCode = resourceValidation.max4char;
        } else {
          letterShopCodeValid = checklettershopcode(value);
          fieldValidationErrors.letterShopCode = letterShopCodeValid
            ? ""
            : resourceValidation.lettershopcode;
        }
        break;
      case "letterShopName":
        letterShopNameValid = shouldNotEmpty(value);
        fieldValidationErrors.letterShopName = letterShopNameValid
          ? ""
          : resourceValidation.letterShopName;
        break;
      case "chargeType":
        chargeTypeValid = shouldNotEmpty(value);
        fieldValidationErrors.chargeType = chargeTypeValid
          ? ""
          : resourceValidation.chargeType;
        break;
      case "unitRate":
        if (value !== "") {
          unitRateValid = true;
          //unitRateValid =checkdecimalpoint1(value);
          fieldValidationErrors.unitRate = unitRateValid
            ? ""
            : resourceValidation.unitrate;
        } else {
          unitRateValid = false;
          fieldValidationErrors.unitRate = "";
        }

        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        letterShopCodeValid: letterShopCodeValid,
        letterShopNameValid: letterShopNameValid,
        chargeTypeValid: chargeTypeValid,
        unitRateValid: unitRateValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.letterShopCodeValid &&
        this.state.letterShopNameValid &&
        this.state.chargeTypeValid &&
        this.state.unitRateValid,
    });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      letterShopCode: "",
      letterShopName: "",
      chargeType: "",
      unitRate: "",
      createdBy: "",
      // letterShop: {
      //   letterShopCode: "",
      //   letterShopName: "",
      //   chargeType: "",
      //   unitRate: "",
      // },
      errors: {},
    });
  };
  _onKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onKeyPressCode(event) {
    const re = /[a-zA-Z0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      const { letterShop } = this.state;
      let letterShopCode = letterShop.letterShopCode;
      let letterShopName = letterShop.letterShopName;

      let chargeType = letterShop.chargeType;

      let unitRate = letterShop.unitRate;
      let createdBy = letterShop.createdBy;
      let reqBody = {
        letterShopCode,
        letterShopName,
        chargeType,
        unitRate,
        createdBy,
      };
      postLetterShop(reqBody, this.props.history);
    }
  };
  render() {
    let { classes } = this.props;
    let { letterShop } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Lettershop</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    label="Lettershop Code"
                    name="letterShopCode"
                    inputProps={{
                      maxLength: 10,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={letterShop.letterShopCode}
                    onChange={this.handleChange}
                    error={this.state.errors.letterShopCode}
                    helperText={
                      this.state.errors.letterShopCode
                        ? this.state.errors.letterShopCode
                        : ""
                    }
                    onKeyPress={this._onKeyPressCode}
                  />
                  <TextField
                    inputProps={{
                      maxLength: 50,
                    }}
                    required
                    label="Lettershop Name"
                    name="letterShopName"
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={letterShop.letterShopName}
                    onChange={this.handleChange}
                    error={this.state.errors.letterShopName}
                    helperText={
                      this.state.errors.letterShopName
                        ? this.state.errors.letterShopName
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    inputProps={{
                      maxLength: 50,
                    }}
                    required
                    label="Charge Type"
                    name="chargeType"
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={letterShop.chargeType}
                    onChange={this.handleChange}
                    error={this.state.errors.chargeType}
                    helperText={
                      this.state.errors.chargeType
                        ? this.state.errors.chargeType
                        : ""
                    }
                  />
                  <TextField
                    inputProps={{
                      maxLength: 15,
                    }}
                    label="Unit Rate"
                    name="unitRate"
                    margin="normal"
                    autoComplete="off"
                    required
                    className={classes.textField}
                    value={letterShop.unitRate}
                    onChange={this.handleChange}
                    onBlur={this.onBlur}
                    error={this.state.errors.unitRate}
                    helperText={
                      this.state.errors.unitRate
                        ? this.state.errors.unitRate
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$S</InputAdornment>
                      ),
                    }}
                  />
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/letterShop")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddLetterShop));
