import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import clone from "clone";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import moment from "moment";
import { ViewColumnIcon } from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import StatusText from "../../../shared/statusText";
import FormGroup from "@material-ui/core/FormGroup";
import { stableSort, getSorting } from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { Checkbox } from "@material-ui/core";
import { StateContext } from "../../../shared/globalState";
import {
  getEBLOProcessList,
  UpdateEBLOOrderPlacementToInProgess,
} from "../../publisher/emloOrderPlacement/action";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ExportCSV } from "../../../shared/ExportCSV";

const styles = (theme) => ({
  textField: {
    marginRight: theme.spacing(2),
    fontSize: "11px !important",
    flex: 1,
  },
  emptyField: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
  },
  lastChild: {
    marginRight: 0,
  },
});

const columns = [
  {
    name: "Modified By",
    id: 9,
  },
  {
    name: "Modified Date",
    id: 10,
  },
  {
    name: "Created By",
    id: 11,
  },
  {
    name: "Created Date",
    id: 12,
  },
];

function createData(
  ebloOrderFileHeaderID,
  client,
  ebloNo,
  type,
  recipient,
  deliveryMethod,
  countryName,
  orderDate,
  status,
  modifiedByName,
  modifiedDate,
  createdByName,
  createdDate,
  countryID,
  statusID
) {
  return {
    ebloOrderFileHeaderID,
    client,
    ebloNo,
    type,
    recipient,
    deliveryMethod,
    countryName,
    orderDate,
    status,
    modifiedByName,
    modifiedDate,
    createdByName,
    createdDate,
    countryID,
    statusID,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                //checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all EBLO's" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [formValid, setIsformValid] = useState(false);

  const [ebploSearchValue, setValues] = useState({
    eblo: "",
    modifiedDateFrom: null,
    modifiedDateTo: null,
    status: "",
    country: "",
  });

  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,

    handledeleteProductionLineCode,
    searchPopupOpen,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchebloCode,
    searchClicked,

    handleViewColumns,
    codeTypeList,
    statusList,
    statusListInput,
    countryListInput,
    exportDataValues,
    selected,
    isActive,
    SelectedEBLOOrderFileHeaderID,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);

  const selectedEBLOID = selected[0];
  const selectedebloID = codeTypeList.find((x) => x.ebloNo === selectedEBLOID);

  let expData = clone(codeTypeList);
  expData.map((d) => {
    d["Customer"] = d["client"];
    d["EBLO"] = d["ebloNo"];
    d["Type"] = d["type"];
    d["Recipient"] = d["recipient"];
    d["Delivery Method"] = d["deliveryMethod"];
    d["Country"] = d["countryName"];
    d["Order Date"] = d["orderDate"]
      ? moment(d["orderDate"]).format("DD/MM/YYYY")
      : "";
    d["Status"] = d["status"];

    d["Modified By"] = d["modifiedByName"];
    d["Modified Date"] = d["modifiedDate"]
      ? moment(d["modifiedDate"]).format("DD/MM/YYYY HH:mm:ss")
      : "";
    d["Created By"] = d["createdByName"];
    d["Created Date"] = d["createdDate"]
      ? moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss")
      : "";
    delete d["countryID"];
    delete d["statusID"];
    delete d["ebloOrderFileHeaderID"];
    delete d["issueDescription"];
    delete d["client"];
    delete d["ebloNo"];
    delete d["type"];
    delete d["recipient"];
    delete d["deliveryMethod"];
    delete d["countryName"];
    delete d["orderDate"];
    delete d["status"];
    delete d["createdByName"];
    delete d["createdDate"];
    delete d["modifiedByName"];
    delete d["modifiedDate"];
  });
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteProductionLineCode = () => {
    handledeleteProductionLineCode();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = ebploSearchValue;
    searchValues.eblo = "";
    searchValues.country = "";
    searchValues.status = "";
    searchValues.modifiedDateFrom = null;
    searchValues.modifiedDateTo = null;
    searchValues = ebploSearchValue;
  };

  const searchPopup = () => {
    handleSearchebloCode(ebploSearchValue);
    clearSearchValues();
  };

  const handleSearchInput = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;
    setValues((ebploSearchValue) => ({
      ...ebploSearchValue,
      [event.target.name]: event.target.value,
    }));
  };

  const checkButtonDisability = () => {
    let { ebploSearchValue } = this.state;
    if (
      !ebploSearchValue.eblo &&
      !ebploSearchValue.country &&
      !ebploSearchValue.status &&
      !(ebploSearchValue.modifiedDateFrom && ebploSearchValue.modifiedDateTo)
    )
      return true;
    else return false;
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          margin: numSelected > 0 && "5px 0",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={() => props.startProcessingHandler(selected)}
              >
                {" "}
                Start Processing{" "}
              </Button>
            </div>{" "}
            {/* {
              <Tooltip title="Cancel">
                <IconButton
                  aria-label="cancel"
                  className={classes.iconHover}
                  onClick={(e) => props.history.push("/pubEbloOrderPlacement")}
                >
                  <CancelOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            } */}
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            >
              {
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) =>
                    props.history.push("/add_pubEbloBatchProcessing")
                  }
                >
                  {" "}
                Update Tracking Info{" "}
                </Button>
              }
              {
                <Tooltip title="Cancel">
                  <IconButton
                    aria-label="cancel"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/pubEbloOrderPlacement")}
                  >
                    <CancelOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              }
              <ExportCSV csvData={expData} fileName={"EBLO_BATCH_PROCESSING"} />

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                handleViewColumns={handleViewColumns}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    handleViewColumns,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => handleViewColumns(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

class ebloBatchProcessing extends React.Component {
  static contextType = StateContext;
  state = {
    customerId: "",
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    codeTypeList: [],
    statusList: {},
    selectedStatusID: 0,
    selectedCountryID: 0,
    statusListInput: [],
    countryListInput: [],
    rows: [],
    activeRows: 0,
    isActive: false,
    loading: false,
    refreshData: false,
    selectedebloID: {},
    selectedebloIDId: 1,
    searchPopupOpen: false,

    searchClicked: false,
    selectedShowColumns: [],

    SelectedEBLOOrderFileHeaderID: "",

    headCells: [
      {
        id: 1,
        code: "client",
        numeric: false,
        disablePadding: false,
        label: "Customer",
        show: true,
      },
      {
        id: 2,
        code: "ebloNo",
        numeric: false,
        disablePadding: false,
        label: "EBLO",
        show: true,
      },
      {
        id: 3,
        code: "type",
        numeric: false,
        disablePadding: false,
        label: "Type",
        show: true,
      },
      {
        id: 4,
        code: "recipient",
        numeric: false,
        disablePadding: false,
        label: "Recipient",
        show: true,
      },
      {
        id: 5,
        code: "deliveryMethod",
        numeric: false,
        disablePadding: false,
        label: "Delivery Method",
        show: true,
      },
      {
        id: 6,
        code: "countryName",
        numeric: false,
        disablePadding: false,
        label: "Country",
        show: true,
      },
      {
        id: 7,
        code: "orderDate",
        numeric: false,
        disablePadding: false,
        label: "Order Date",
        show: true,
      },
      {
        id: 8,
        code: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        show: true,
      },
      {
        id: 9,
        code: "modifiedByName",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 10,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 11,
        code: "createdByName",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
      {
        id: 12,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
    ],
  };
  componentDidMount() {
    this.props.handleSettingHeader("EBLO Batch Processing");
    this.props.setSelectedList(
      "publisherBackLabelOpen",
      "pubEbloBatchProcessing"
    );
    const dispatch = this.context[1];

    getEBLOProcessList().then((r) => {
      this.getEBLO(r.data);
      this.exportebloDatas(r.data);
    });
    this.setState({ loading: true });
  }

  handleViewColumns = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  exportebloDatas = (ebloList) => {
    let list = ebloList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          Client: d.client,
          EBLO: d.ebloNo,
          Type: d.type,
          Recipient: d.recipient,
          DeliveryMethod: d.deliveryMethod,
          Country: d.countryName,
          OrderDate: d.orderDate,
          Status: d.status,
          CreatedBy: d.createdByName,
          CreatedDate: d.createdDate,
          ModifiedBy: d.modifiedByName,
          ModifiedDate: d.modifiedDate,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getEBLO = (ebloList) => {
    let list = ebloList,
      statusTemp = [],
      statusTextArr = [],
      statusListInputArr = [],
      countryTextArr = [],
      countryListInputArr = [];
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.ebloOrderFileHeaderID,
            d.client,
            d.ebloNo,
            d.type,
            d.recipient,
            d.deliveryMethod,
            d.countryName,
            d.orderDate,
            d.status,
            d.modifiedByName,
            d.modifiedDate,
            d.createdByName,
            d.createdDate,
            d.countryID,
            d.statusID,
            d.ebloOrderFileHeaderID
          )
        );
      });

    this.setState({
      codeTypeList: rows,
      statusList: statusTemp,
      statusListInput: statusListInputArr,
      countryListInput: countryListInputArr,
      selected: [],
      loading: false,
      activeRows: rows.filter((x) => x.isActive).map((n) => n.ebloNo),
    });
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleClick = (event, data, id) => {
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedebloID: data,
        SelectedEBLOOrderFileHeaderID: id,
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedebloID: data,
        SelectedEBLOOrderFileHeaderID: id,
      });
    }
  };

  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  startProcessingHandler = (ids) => {
    let ebloIds = ids.join(",");
    let modifiedBy = window.localStorage.loggedInId;
    let reqBody = { ebloIds, modifiedBy };

    UpdateEBLOOrderPlacementToInProgess(reqBody).then((r) => {
      getEBLOProcessList().then((r) => {
        this.getEBLO(r.data);
      });
    });
    this.setState({ loading: true });
  };

  handleSelectAllClick = (event) => {
    let { codeTypeList } = this.state;
    if (event.target.checked) {
      const newSelecteds = codeTypeList.map((n) => n.ebloOrderFileHeaderID);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      codeTypeList,
      headCells,
      statusList,
      statusListInput,
      countryListInput,
      exportDataValues,
    } = this.state;
    const isSelected = (ebloNo) => selected.indexOf(ebloNo) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            SelectedEBLOOrderFileHeaderID={
              this.state.SelectedEBLOOrderFileHeaderID
            }
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handledeleteProductionLineCode={this.handledeleteProductionLineCode}
            selectedebloID={this.state.selectedebloID}
            selectedebloIDId={this.state.selectedebloIDId}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchebloCode={this.handleSearchebloCode}
            ebploSearchValue={this.state.ebploSearchValues}
            searchClicked={this.state.searchClicked}
            handleViewColumns={this.handleViewColumns}
            headCells={headCells}
            codeTypeList={codeTypeList}
            statusList={statusList}
            statusListInput={statusListInput}
            countryListInput={countryListInput}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            startProcessingHandler={this.startProcessingHandler}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
                style={{
                  borderLeft: "1px solid  rgba(224, 224, 224, 1)",
                  whiteSpace: "nowrap",
                }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.codeTypeList.length}
                  headCells={this.state.headCells}
                  startProcessingHandler={this.startProcessingHandler}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {codeTypeList.length > 0 ? (
                    stableSort(codeTypeList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(
                          row.ebloOrderFileHeaderID
                        );
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    onClick={(event) =>
                                      this.handleClick(
                                        event,
                                        row,
                                        row.ebloOrderFileHeaderID
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {row.client && row.client.length > 16 ? (
                                  <Tooltip title={row.client} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.client}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.client}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {row.ebloNo && row.ebloNo.length > 16 ? (
                                  <Tooltip title={row.ebloNo} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.ebloNo}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.ebloNo}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {row.type && row.type.length > 16 ? (
                                  <Tooltip title={row.type} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.type}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.type}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="left"
                              >
                                {row.recipient && row.recipient.length > 16 ? (
                                  <Tooltip
                                    title={row.recipient}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.recipient}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.recipient}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                align="left"
                              >
                                {row.deliveryMethod &&
                                  row.deliveryMethod.length > 16 ? (
                                    <Tooltip
                                      title={row.deliveryMethod}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.deliveryMethod}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.deliveryMethod}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                align="left"
                              >
                                {row.countryName &&
                                  row.countryName.length > 16 ? (
                                    <Tooltip
                                      title={row.countryName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.countryName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.countryName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                align="left"
                              >
                                {row.orderDate &&
                                  moment(row.orderDate).format("DD/MM/YYYY")
                                    .length > 16 ? (
                                    <Tooltip
                                      title={
                                        row.orderDate
                                          ? moment(row.orderDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""
                                      }
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.orderDate
                                          ? moment(row.orderDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.orderDate
                                        ? moment(row.orderDate).format(
                                          "DD/MM/YYYY"
                                        )
                                        : ""}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                align="left"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.status && row.status.length > 10 ? (
                                    <Tooltip title={row.status}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.status == "Submitted" ? (
                                          <StatusText color={"#16831F"}>
                                            Submitted
                                          </StatusText>
                                        ) : row.status == "UnSubmitted" ? (
                                          <StatusText color={"#F4B43C"}>
                                            UnSubmitted
                                          </StatusText>
                                        ) : row.status == "Processed" ? (
                                          <StatusText color={"#0091ff"}>
                                            Processed
                                          </StatusText>
                                        ) : row.status == "Cancelled" ? (
                                          <StatusText color={"red"}>
                                            Cancelled
                                          </StatusText>
                                        ) : (
                                                  <StatusText color={"grey"}>
                                                    {row.status}
                                                  </StatusText>
                                                )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.status == "Submitted" ? (
                                          <StatusText color={"#16831F"}>
                                            Submitted
                                          </StatusText>
                                        ) : row.status == "UnSubmitted" ? (
                                          <StatusText color={"#F4B43C"}>
                                            UnSubmitted
                                          </StatusText>
                                        ) : row.status == "Processed" ? (
                                          <StatusText color={"#0091ff"}>
                                            Processed
                                          </StatusText>
                                        ) : row.status == "Cancelled" ? (
                                          <StatusText color={"red"}>
                                            Cancelled
                                          </StatusText>
                                        ) : (
                                                  <StatusText color={"grey"}>
                                                    {row.statusName}
                                                  </StatusText>
                                                )}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                align="left"
                              >
                                {row.modifiedByName &&
                                  row.modifiedByName.length > 16 ? (
                                    <Tooltip
                                      title={row.modifiedByName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                                align="left"
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                align="left"
                              >
                                {row.createdByName &&
                                  row.createdByName.length > 16 ? (
                                    <Tooltip
                                      title={row.createdByName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.createdByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                align="left"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={12} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={codeTypeList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ebloBatchProcessing));
