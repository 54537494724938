import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withRouter } from "react-router-dom";
import { getSuperMenu, updateSuperMenuID } from "../userAccessAction";
import Validator from "../../../shared/validator";
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";
import { addStyles } from "../../../shared/styles";
const validateNotRequired100 = new Validator("maxlength100AllAllow");
const validateNotRequired500 = new Validator("maxlength100AllAllow");
const onlyNumbers = new Validator("onlyNumbers");

class EditSuperMenu extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      superMenu: {
        superMenuName: "",
        description: "",
        orderNo: "",
        isActive: false,
        modifiedBy: 1
      },
      errors: {},
      active: false,
      superMenuNameValid: true,
      descriptionValid: true,
      orderNoValid: true
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let superMenuId = this.props.match.params.superMenuId;

    getSuperMenu(superMenuId).then(r => {
      dispatch({
        type: "getSuperMenu",
        newStore: r
      });
      this.setState({ superMenu: r });
      if (r.isActive) this.setState({ active: r.isActive });
    });
  }

  handleChange = event => {
    const { superMenu } = this.state;
    if (event.target.name === "isActive") {
      superMenu[event.target.name] = event.target.checked;
    } else {
      superMenu[event.target.name] = event.target.value;
    }
    this.setState({ superMenu });

    this.validateField(event.target.name, event.target.value);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let superMenuNameValid = this.state.superMenuNameValid;
    let descriptionValid = this.state.descriptionValid;
    let orderNoValid = this.state.orderNoValid;
    switch (fieldName) {
      case "superMenuName":
        if (value !== "") {
          superMenuNameValid = validateNotRequired100(value);
          fieldValidationErrors.superMenuName = superMenuNameValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          superMenuNameValid = false;
          fieldValidationErrors.superMenuName = "";
        }
        break;
      case "description":
        if (value !== "") {
          descriptionValid = validateNotRequired500(value);
          fieldValidationErrors.description = descriptionValid
            ? ""
            : resourceValidation.ValidDescription;
        } else {
          descriptionValid = false;
          fieldValidationErrors.description = "";
        }
        break;
      case "orderNo":
        if (value !== "" && value !== "null") {
          orderNoValid = onlyNumbers(value);
          fieldValidationErrors.orderNo = orderNoValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          orderNoValid = false;
          fieldValidationErrors.orderNo = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        superMenuNameValid: superMenuNameValid,
        descriptionValid: descriptionValid,
        orderNoValid: orderNoValid
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.superMenuNameValid &&
        this.state.descriptionValid &&
        this.state.orderNoValid
    });
    return this.state.formValid;
  }
  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    let superMenuId = this.props.match.params.superMenuId;
    const { superMenu } = this.state;
    superMenu.modifiedBy = localStorage.loggedInId;
    updateSuperMenuID(superMenuId, superMenu).then(r => {
      this.props.history.push("/SuperMenu");
    });
  }

  render() {
    let { classes } = this.props;
    let { superMenu, active } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Super Menu</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    label="Super Menu Name"
                    name="superMenuName"
                    inputProps={{
                      maxLength: 50
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={superMenu.superMenuName}
                    onChange={this.handleChange}
                    error={this.state.errors.superMenuName}
                    helperText={
                      this.state.errors.superMenuName
                        ? this.state.errors.superMenuName
                        : ""
                    }
                  />
                  <TextField
                    required
                    multiline={false}
                    rows={1}
                    rowsMax={4}
                    inputProps={{
                      maxLength: 100
                    }}
                    label="Description"
                    name="description"
                    margin="normal"
                    className={classes.textField}
                    value={superMenu.description}
                    onChange={this.handleChange}
                    error={this.state.errors.description}
                    helperText={
                      this.state.errors.description
                        ? this.state.errors.description
                        : ""
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Order Number"
                    name="orderNo"
                    inputProps={{
                      maxLength: 3
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={superMenu.orderNo}
                    onChange={this.handleChange}
                    error={this.state.errors.orderNo}
                    helperText={
                      this.state.errors.orderNo ? this.state.errors.orderNo : ""
                    }
                    onKeyPress={this._onNumberKeyPress}
                  />
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      name="isActive"
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          checked={superMenu.isActive}
                          onChange={this.handleChange}
                          value={superMenu.isActive}
                        />
                      }
                      label="Active"
                    />
                  )}
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={e => this.props.history.push("/superMenu")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    <Button
                      onClick={this.handleSubmit}
                      disabled={!this.state.formValid}
                      autoFocus
                      variant="contained"
                      className={
                        !this.state.formValid
                          ? "disabled-button"
                          : "enabled-button "
                      }
                    >
                      SUBMIT
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditSuperMenu));
