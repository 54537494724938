import React, { Component } from "react";

class StatusText extends Component {
  state = {};
  render() {
    return (
      <div
        style={{
          width: "auto",
          heigth: "auto",
          display: "flex",
          alignItems: "center",
        }}
      >
        {
          <React.Fragment>
            <div
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                backgroundColor: `${this.props.color}`,
                marginRight: "5px",
              }}
            ></div>
            <div
              style={{
                opacity: "0.87",
                fontFamily: "Roboto",
                fontSize: "14px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                color: `${this.props.color}`,
                //fontSize: this.props.children.length > 12 ? "11px" : "14px"
              }}
            >
              {this.props.children}
            </div>
          </React.Fragment>
        }
      </div>
    );
  }
}

export default StatusText;
