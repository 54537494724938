import React, { Component } from "react";
import { Paper, Divider, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip, IconButton, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import "../../app/App.css";
import { getPreAlertByID, putForPreAlert } from "./action";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginRight: "16px",
    fontSize: "11px !important",
    flex: 1,
  },
  emptyField: {
    marginRight: "16px",
    flexGrow: 1,
  },
  lastChild: {
    marginRight: 0,
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
});

class ViewJournalItemMaster extends Component {
  state = {
    selectedOrder: {},
    stateFiles: [],
    client: "",
    location: "",
    preAlertDate: "",
    office: "",
    acronym: "",
    title: "",
    statusID: "",
    volume: "",
    issue: "",
    suppNumber: "",
    status: "",
    printRun: "",
    processedQuantity: "",
    wpc: "",
    totalWeight: "",
    totalPallet: "",
    newWeight: "",
    thickness: "",
    catagory: "",
    version: "",
    safeOrUnsafe: "",
    inserted: "",
    cover: "",
    isOnHoldJRReport: "",
    isJRRGenerated: "",
    exceptionMessage: "",
    createdDate: "",
    createdName: "",
    modifiedDate: "",
    modifiedByName: "",
  };
  componentDidMount() {
    this.props.handleSettingHeader("Pre Alert");
    this.props.setSelectedList("publisherMainLabelOpen", "pubPreAlert");

    let preAlertId =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];

    getPreAlertByID(preAlertId).then((r) => {
      // dispatch({
      //   type: "getPreAlert",
      //   newStore: r.data,
      // });
      r &&
        r.data &&
        this.setState({
          client: r.data.client,
          location: r.data.locationName,
          preAlertDate: r.data.preAlertDate,
          office: r.data.office,
          acronym: r.data.acronym,
          title: r.data.title,
          statusID: r.data.statusID,
          volume: r.data.volume,
          issue: r.data.issue,
          suppNumber: r.data.suppNumber,
          status: r.data.status,
          printRun: r.data.printRun,
          processedQuantity: r.data.processedQuantity,
          wpc: r.data.wpc,
          totalWeight: r.data.totalWeight,
          totalPallet: r.data.totalPallet,
          newWeight: r.data.newWeight,
          thickness: r.data.thickness,
          catagory: r.data.catagory,
          version: r.data.version,
          safeOrUnsafe: r.data.safeOrUnsafe,
          inserted: r.data.inserted,
          cover: r.data.cover,
          isOnHoldJRReport: r.data.isOnHoldJRReport,
          isJRRGenerated: r.data.isJRRGenerated,
          exceptionMessage: r.data.exceptionMessage,
          createdDate: r.data.createdDate,
          createdName: r.data.createdName,
          modifiedDate: r.data.modifiedDate,
          modifiedByName: r.data.modifiedByName,
        });
    });
  }
  handleCancel = () => {
    this.props.history.push("/pubPreAlert");
  };

  onBlur = (event, name, index) => {
    this.setState({
      [name]: this.addZeroes(event.target.value.toString(), 3),
    });
  };

  onYorNKeyPress(event) {
    const re = /[yYnN]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  checkDHlWeightDec1(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  onExcelDataChanged = (e, name) => {
    this.setState({
      name: e.target.value,
    });
  };

  _onNumberwithDotKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onWeightKeyPress(event) {
    const re = /[0-9 a-zA-Z.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleChange = (e, newValue) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name == "wpc") {
      let weiVal = this.checkDHlWeightDec(e.target.value.toString());
      this.setState({
        [e.target.name]: weiVal,
      });
    }
  };

  handleSubmit = (e) => {
    let reqBody = {};
    let list = [];

    let preAlertId =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];
    let loggedInId = localStorage.getItem("loggedInId");
    reqBody.ModifiedBy = loggedInId;
    reqBody.printRun = this.state.printRun;
    reqBody.wpc = this.state.wpc;
    reqBody.version = this.state.version;
    reqBody.safeOrUnsafe = this.state.safeOrUnsafe;

    putForPreAlert(reqBody, this.props.history, preAlertId).then((r) => { });
  };

  render() {
    let { classes } = this.props;
    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    return (
      <div>
        <Paper id="convertToPdf">
          <div className="pop-up-header">Update Pre Alert</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <div className={classes.container}>
                <div style={{ display: "flex", marginTop: "1.5em" }}>
                  <div style={{ flex: 1, marginRight: "16px" }}>
                    <TextField
                      label="Customer"
                      name="customer"
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      onKeyPress={this._onInsertKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.client}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ flex: 1, marginRight: "16px" }}>
                    <TextField
                      label="Location"
                      name="location"
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      onKeyPress={this._onInsertKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.location}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ flex: 1, marginRight: "16px" }}>
                    <TextField
                      label="Office"
                      name="office"
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      onKeyPress={this._onInsertKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.office}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ flex: 1, }}>
                    <TextField
                      label="Acronym"
                      name="acronym"
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      onKeyPress={this._onInsertKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.acronym}
                      onChange={this.handleChange}
                    />
                  </div>

                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "1.5em",
                    alignItems: "center",
                  }}
                > <div style={{ flex: 1, marginRight: "16px" }}>
                    <TextField
                      label="Volume"
                      name="volume"
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      onKeyPress={this._onInsertKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.volume}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ flex: 1, marginRight: "16px" }}>
                    <TextField
                      label="Issue No."
                      name="issue"
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      onKeyPress={this._onInsertKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.issue}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ flex: 1, marginRight: "16px" }}>
                    <TextField
                      label="Supplement No."
                      name="suppNumber"
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      onKeyPress={this._onInsertKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.suppNumber}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <TextField
                      label="Status"
                      name="status"
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      onKeyPress={this._onInsertKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.status}
                      onChange={this.handleChange}
                    />
                  </div>

                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "1.5em",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1, marginRight: "16px" }}>
                    <div className="view-page-value">
                      <TextField
                        required
                        label="Print Run"
                        name="printRun"
                        inputProps={{
                          maxLength: 18,
                        }}
                        onKeyPress={this._onNumberKeyPress}
                        margin="normal"
                        style={{ width: "100%" }}
                        value={this.state.printRun}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div style={{ flex: 1, marginRight: "16px" }}>
                    <TextField
                      label="Processed Quantity"
                      name="processedQuantity"
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      onKeyPress={this._onInsertKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.processedQuantity}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ flex: 1, marginRight: "16px" }}>
                    <TextField
                      required
                      label="WPC (Kgs)"
                      name="wpc"
                      inputProps={{
                      }}
                      onKeyPress={this._onWeightKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.wpc}
                      onChange={this.handleChange}
                      onBlur={(e) => this.onBlur(e, "wpc")}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <TextField
                      label="Total Weight (Kgs)"
                      name="totalWeight"
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      onKeyPress={this._onInsertKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.totalWeight}
                      onChange={this.handleChange}
                    />
                  </div>

                </div>
                <div style={{ display: "flex", marginTop: "1.5em" }}>
                  <div style={{ flex: 1, marginRight: "16px" }}>
                    <TextField
                      label="Total Pallet"
                      name="totalPallet"
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      onKeyPress={this._onInsertKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.totalPallet}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ flex: 1, marginRight: "16px" }}>
                    <TextField
                      label="New Weight (Kgs)"
                      name="newWeight"
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      onKeyPress={this._onInsertKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.newWeight}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ flex: 1, marginRight: "16px" }}>
                    <TextField
                      label="Thickness"
                      name="thickness"
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      onKeyPress={this._onInsertKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.thickness}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <TextField
                      label="Category"
                      name="catagory"
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      onKeyPress={this._onInsertKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.catagory}
                      onChange={this.handleChange}
                    />
                  </div>

                </div>
                <div style={{ display: "flex", marginTop: "1.5em" }}>
                  <div style={{ flex: 1, marginRight: "16px" }}>
                    <TextField
                      // required
                      label="Version"
                      name="version"
                      inputProps={{
                        maxLength: 10,
                      }}
                      onKeyPress={this._freeText}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.version}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ flex: 1, marginRight: "16px" }}>
                    <TextField
                      // required
                      label="Safe/Unsafe"
                      name="safeOrUnsafe"
                      inputProps={
                        {
                          // maxLength: 1,
                        }
                      }
                      helperText="Please enter Safe or Unsafe"
                      // onKeyPress={this.onYorNKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.safeOrUnsafe}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ flex: 1, marginRight: "16px" }}>
                    <TextField
                      label="Insert"
                      name="inserted"
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      onKeyPress={this._onInsertKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.inserted}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={{ flex: 1, }}>
                    <TextField
                      label="Cover"
                      name="cover"
                      inputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                      onKeyPress={this._onInsertKeyPress}
                      margin="normal"
                      style={{ width: "100%" }}
                      value={this.state.cover}
                      onChange={this.handleChange}
                    />
                  </div>
                  {/* <div style={{ flex: 1 }}></div> */}
                </div>
                <Divider />
              </div>
            </div>
          </div>
          <div className="button-wrapper" style={{ padding: "0 2em 1em 2em" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={(e) => this.handleCancel()}
            >
              CANCEL
            </Button>
            <Button
              disabled={
                !this.state.printRun ||
                this.state.printRun <= 0 ||
                !this.state.wpc
                // !this.state.version ||
                // !this.state.safeOrUnsafe
              }
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={(e) => this.handleSubmit()}
              style={{ marginLeft: "1em" }}
            >
              SUBMIT
            </Button>
          </div>
        </Paper>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(ViewJournalItemMaster));
