import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import clone from "clone";
import { StateContext } from "../../../shared/globalState";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as XLSX from "xlsx";
import { Autocomplete } from "@material-ui/lab";
import Switch from "@material-ui/core/Switch";
import {
  uploadPreAlertFiles,
  getCustomerForPreAlertList,
  submitPreAlertFiles,
  putPrealert,
  deletePreAlertById,
  GetPreAlertDownLoad,
  getResetPreAlertStaging
} from "./action";
import toastr from "toastr";
import { getCountryDropdownList } from "../../masters/masterActions";
import { getLocationDropdownList } from "../../masters/masterActions"

import {
  TextField,
  Button,
  Paper,
  Divider,
  Tooltip,
  FormControlLabel,
} from "@material-ui/core";
import { addDispatchStyles } from "../../../shared/styles";
import Typography from "@material-ui/core/Typography";
import { EditIcon } from "../../../shared/customIcons";
import Input from "@material-ui/core/Input";
import DeleteIcon from "@material-ui/icons/Delete";
import Validator from "../../../shared/validator";
import "../../app/App.css";
import CheckIcon from "@material-ui/icons/Check";
import { getCustomerForInsertMasterList } from "../action";

let replacementReprintData = [
  { id: 0, label: "Replacement" },
  { id: 1, label: "Reprint" },
  { id: 2, label: "MainLabel" },
  { id: 3, label: "CEA" },
  { id: 4, label: "CES" },
  { id: 5, label: "BPA" },
  { id: 5, label: "CPT" },
];

let versionData = [
  { id: 0, label: "" },
  { id: 1, label: "ROW" },
  { id: 2, label: "ROE" },
  { id: 3, label: "NORDIC" },
  { id: 4, label: "ENE" },
  { id: 5, label: "ASEAN" },
];

let safeUnsafeData = [
  { id: 0, label: "" },
  { id: 1, label: "Safe" },
  { id: 2, label: "Unsafe" },
];

function createData(
  sNo,
  invoiceNo,
  currency,
  totalInvoiceAmt,
  doNos,
  office,
  title,
  acronym,
  volume,
  issue,
  supp,
  printRun,
  wpc,
  totalWeight,
  totalPallet,
  preAlertAddress3,
  netWeight,
  thickness,
  replacementReprint,
  version,
  safeUnsafe,
  insert,
  cover,
  exception
) {
  return {
    sNo,
    invoiceNo,
    currency,
    totalInvoiceAmt,
    doNos,
    office,
    title,
    acronym,
    volume,
    issue,
    supp,
    printRun,
    wpc,
    totalWeight,
    totalPallet,
    preAlertAddress3,
    netWeight,
    thickness,
    replacementReprint,
    version,
    safeUnsafe,
    insert,
    cover,
    exception,
  };
}
const headCells = [
  {
    id: "sNo",
    numeric: false,
    disablePadding: true,
    label: "S/No",
  },
  {
    id: "invoiceNo",
    numeric: false,
    disablePadding: false,
    label: "Invoice No",
  },
  {
    id: "currency",
    numeric: false,
    disablePadding: false,
    label: "Currency",
  },
  {
    id: "totalInvoiceAmt",
    numeric: false,
    disablePadding: false,
    label: "Tot Invc Amt",
  },
  {
    id: "doNos",
    numeric: false,
    disablePadding: false,
    label: "DO Nos",
  },
  {
    id: "office",
    numeric: false,
    disablePadding: false,
    label: "Office",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Title",
  },
  {
    id: "acronym",
    numeric: false,
    disablePadding: false,
    label: "Acronym*",
  },
  {
    id: "volume",
    numeric: false,
    disablePadding: false,
    label: "Volume*",
  },
  {
    id: "issue",
    numeric: false,
    disablePadding: false,
    label: "Issue*",
  },
  {
    id: "supp",
    numeric: false,
    disablePadding: false,
    label: "Supplement*",
  },
  {
    id: "printRun",
    numeric: false,
    disablePadding: false,
    label: "Print Run*",
  },
  {
    id: "wpc",
    numeric: false,
    disablePadding: false,
    label: "WPC* (Kgs)",
  },
  {
    id: "totalWeight",
    numeric: false,
    disablePadding: false,
    label: "Total Weight (Kgs)",
  },
  {
    id: "totalPallet",
    numeric: false,
    disablePadding: false,
    label: "Total Pallet",
  },
  {
    id: "netWeight",
    numeric: false,
    disablePadding: false,
    label: "New Weight (Kgs)",
  },
  {
    id: "thickness",
    numeric: false,
    disablePadding: false,
    label: "Thickness",
  },
  {
    id: "replacementReprint",
    numeric: false,
    disablePadding: false,
    label: "Replacement/Reprint*",
  },
  {
    id: "version",
    numeric: false,
    disablePadding: false,
    label: "Version",
  },
  {
    id: "safeUnsafe",
    numeric: false,
    disablePadding: false,
    label: "Safe/Unsafe",
  },
  {
    id: "insert",
    numeric: false,
    disablePadding: false,
    label: "Insert",
  },
  {
    id: "cover",
    numeric: false,
    disablePadding: false,
    label: "Cover",
  },
  {
    id: "exception",
    numeric: false,
    disablePadding: false,
    label: "Exception",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow style={{ background: " #eaeaea" }}>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{
              borderLeft: "1px sold #000",
              minWidth:
                i == 0 || i == 21 || i == 20
                  ? "0px"
                  : i == 23
                    ? "50px"
                    : i == 6
                      ? "150px"
                      : "default",
            }}
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderTop: "1px solid #eaeaea",
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { } = props;

  return (
    <div>
      <div
        style={{
          display: "flex",
          //   width: "100%",
          border: "1px solid #eaeaea",
          borderBottom: "none",
          borderRadius: "4px",
          margin: "0 1em 0 .6em",
        }}
      >
        {/* <Button
          disabled={props.disableAdd}
          color="primary"
          variant="filled"
          onClick={(e) => props.handleClickButton(e, "add")}
          style={
            props.disableAdd
              ? {
                  color: "#fff",
                  background: "#3982ea",
                  padding: "2px",
                  margin: ".5em",
                  fontSize: "11px",
                }
              : { color: "#3982ea", fontSize: "11px" }
          }
        >
          <AddIcon style={{ fontSize: "18px" }} /> Add
        </Button> */}
        {props.disableAdd ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={() => props.handleClickButton("add")}
          >
            <AddIcon style={{ fontSize: "18px" }} /> Add
          </Button>
        ) : (
            <Button
              // disabled={!this.state.locationId}
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              onClick={() => props.handleClickButton("add")}
            >
              <AddIcon style={{ fontSize: "18px" }} /> Add
            </Button>
          )}
        {props.disableEdit ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={() => props.handleClickButton("reset")}
          >
            <SyncIcon style={{ fontSize: "18px" }} /> Reset
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              onClick={() => props.handleClickButton("reset")}
            >
              <SyncIcon style={{ fontSize: "18px" }} /> Reset
            </Button>
          )}
        {props.showText ? (
          <div
            style={{
              color: "red",
              fontSize: "12px",
              fontWeight: "bold",
              padding: "10px",
            }}
          >
            *Please type only "Y" or "N" or leave Blank for Insert/Cover
          </div>
        ) : null}
        {props.showText ? (
          <div
            style={{
              color: "red",
              fontSize: "12px",
              fontWeight: "bold",
              padding: "10px",
            }}
          >
            *Please type only "Safe" or "Unsafe" or leave Blank for Safe/Unsafe
          </div>
        ) : null}
      </div>
    </div>
  );
};

class PreAlertOrder extends React.Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      locationId: "",
      locationObj: {},
      locationList: [],
      list: [],
      editableList: [],
      fileName: "",
      disableAdd: true,
      disableEdit: true,
      disableAddGP: false,
      disableEditGP: true,
      plannerList: [],
      customerList: [],
      customerId: "",
      customer: {},
      preAlertHeaderID: 0,
      groupProcessEditClicked: false,
      loading: false,
      countryList: [],
      replacementReprint: "",
      replacementReprintID: "",
      version: "",
      versionID: "",
      safeUnsafe: "",
      safeUnsafeID: "",
      customerName: "",
      isTicked: false,
      showText: false,
    };
    this.myRef = React.createRef();
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  componentDidMount() {
    const [{ store }, dispatch] = this.context;
    this.props.handleSettingHeader("Pre Alert");
    this.props.setSelectedList("publisherMainLabelOpen", "pubPreAlert");

    getCustomerForPreAlertList().then((r) => {
      r &&
        r.data &&
        this.setState({
          customerList:
            r &&
            r.data &&
            r.data
              // .filter((x) => x.text.toLowerCase().indexOf("wiley") != -1)
              .sort((a, b) =>
                a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
              ),
        });

      const filtered =
        r &&
        r.data &&
        r.data.reduce(
          (a, o) => (
            o.text.toLowerCase().indexOf("wiley") != -1 && a.push(o.id), a
          ),
          []
        );
      const filteredText =
        r &&
        r.data &&
        r.data.reduce(
          (a, o) => (
            o.text.toLowerCase().indexOf("wiley") != -1 && a.push(o), a
          ),
          []
        );
      this.setState({
        customerId: filtered && filtered[0],
        customer: filteredText && filteredText[0],
      });
    });
    getCountryDropdownList().then((r) => {
      this.getAllCountrys(r.data);
    });
    getLocationDropdownList().then((rr) => {
      this.getAllLocation(rr.data);
    })
  }
  getAllLocation = (list) => {
    let locationList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        locationList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(locationList, "locationList");
  };
  sortDDList = (list, name) => {
    let oldCus = clone(list);

    if (name === "locationList") {
      oldCus &&
        oldCus.length > 0 &&
        this.setState({
          locationId: oldCus.filter((d) => d.value === "SG")[0].key,
          locationObj: oldCus.filter((d) => d.value === "SG")[0],
          disableAdd: false
        });
    }
    let z = list.sort(function (a, b) {
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ [name]: z });
  };
  handleReplacementChange = async (event, name, index) => {
    let newList = clone(this.state.list);

    let lastIndexValue = event.target.id.split("option-")[1];

    newList[index][name] =
      replacementReprintData[lastIndexValue] &&
      replacementReprintData[lastIndexValue].label;

    await this.setState({ list: newList });
  };

  handleVersionChange = async (event, name, index) => {
    let newList = clone(this.state.list);

    let lastIndexValue = event.target.id.split("option-")[1];

    newList[index][name] =
      versionData[lastIndexValue] && versionData[lastIndexValue].label;

    await this.setState({ list: newList });
  };

  handleSafeUnsafeChange = async (event, name, index) => {
    let newList = clone(this.state.list);

    let lastIndexValue = event.target.id.split("option-")[1];
    await this.setState({
      safeUnsafe:
        safeUnsafeData[lastIndexValue] && safeUnsafeData[lastIndexValue].label,
      safeUnsafeID:
        safeUnsafeData[lastIndexValue] && safeUnsafeData[lastIndexValue].id,
    });
    newList[index][name] =
      safeUnsafeData[lastIndexValue] && safeUnsafeData[lastIndexValue].label;

    await this.setState({ list: newList });
  };

  getAllCountrys = (list) => {
    let countryList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        countryList.push({ key: c.id, value: c.text });
      });

    this.sortDDList1(countryList, "countryList");
  };
  sortDDList1 = (list, name) => {
    let z = list.sort(function (a, b) {
      if (name === "customerList") {
        var x = a.name.toLowerCase();
        var y = b.name.toLowerCase();
      } else {
        var x = a.value.toLowerCase();
        var y = b.value.toLowerCase();
      }

      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ [name]: z });
  };
  getAllPlanners = (list) => {
    let plannerList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        if (c.isActive)
          plannerList.push({ key: c.userId, value: c.fName, name: c.fName });
      });
    this.setState({ plannerList });
  };
  checkOGDisability = () => {
    if (
      !this.state.customer ||
      (this.state.customer ? Object.keys(this.state.customer).length === 0 : 1)
    ) {
      return true;
    }
    return false;
  };
  _onAlphaKeyPress(event) {
    const re = /[a-zA-Z0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onWSIssueKeyPress(event) {
    const re = /[a-zA-Z0-9&-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _freeText(event) {
    const re = /[#.0-9a-zA-Z\s,-@()%!$%^&*{}/<>_;:'" |]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _alphaCharacter(event) {
    const re = /[#.a-zA-Z\s,-@()%!$%^&*{}/<>_;:'" |]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onReplacementPress(event) {
    const re = /[a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onlyAlphaKeyPress(event) {
    const re = /[a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberwithDotKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onWSTextPlusNumberWithNoSpaceKeyPress(event) {
    const re = /[0-9a-zA-Z&-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleUpload = async (e) => {
    let files = e.target.files;
    var reader = new FileReader();
    let _error = "";
    if (files[0]) {
      this.setState({
        fileName: files[0].name,
        // disableAdd: true,
        disableEdit: false,
      });

      // formData.append("preAlertheaderID ", this.state.PreAlertHeaderID);
      this.setState({ loading: true });
      if (!files[0].name.endsWith(".xlsx")) {
        toastr.error("Unsupported File Format");
        await this.setState({
          loading: false,
          fileName: ""
        });
      } else {
        // reader.onload = function (e) {
        //   let data = e.target.result;
        //   let readedData = XLSX.read(data, { type: "binary" });
        //   const wsname = readedData.SheetNames[0];
        //   const ws = readedData.Sheets[wsname];
        //   const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
        // };

        reader.readAsBinaryString(files[0]);
        let loggedInId = localStorage.getItem("loggedInId");
        const formData = new FormData();
        formData.append("UploadFiles", files[0]);
        formData.append("CustomerID", this.state.customerId);
        if (this.state.locationId)
          formData.append("LocationID", this.state.locationId);
        formData.append("CreatedBy", loggedInId);
        formData.append("ModifiedBy", loggedInId);
        formData.append("IsEmailSent", false);
        uploadPreAlertFiles(formData).then((r) => {
          GetPreAlertDownLoad(r).then((r) => {
            let newList = [];
            r &&
              r.data &&
              r.data.map((res) => {
                newList.push({
                  sNo: res.sNo,
                  invoiceNo: res.invoiceNos,
                  currency: res.currency,
                  totalInvoiceAmt: res.totalInvoiceAmt,
                  doNos: res.doNos,
                  office: res.office,
                  title: res.title,
                  acronym: res.acronym,
                  volume: res.volume,
                  issue: res.issue,
                  supp: res.suppNumber,
                  printRun: res.printRun,
                  wpc: res.wpc,
                  totalWeight: res.totalWeight,
                  totalPallet: res.totalPallet,
                  netWeight: res.newWeight,
                  thickness: res.thickness,
                  replacementReprint: res.replacementReprint,
                  version: res.version,
                  safeUnsafe: res.safeOrUnsafe,
                  insert: res.inserted,
                  cover: res.cover,
                  exception: res.exceptionMessage,
                  fromDB: true,
                  preAlertHeaderID: res.preAlertHeaderID,
                  preAlertStaggingID: res.preAlertStaggingID,
                  editIconEnable: true,
                });
                this.setState({
                  preAlertHeaderID: res.preAlertHeaderID,
                });
              });
            this.setState({
              list: newList,
              editableList: newList,
              // disableAdd: true,
              disableEdit: false,
              // PreAlertHeaderID: r.length > 0 && r[0].preAlertHeaderID,
              loading: false,
            });
          });
        });
      }
    } else {
      this.setState({
        fileName: "",
        loading: false,
      });
    }
  };

  handleClickButton = (name) => {
    let newList = []
    if (this.state.list && this.state.list.length > 0)
      newList = clone(this.state.list);
    let newObj = {
      sNo: "",
      invoiceNo: "",
      currency: "",
      totalInvoiceAmt: "0.00",
      doNos: "",
      office: "",
      title: "",
      acronym: "",
      volume: "",
      issue: "",
      supp: "",
      printRun: "",
      wpc: "0.000",
      totalWeight: "0.000",
      totalPallet: "",
      netWeight: "0.000",
      thickness: "",
      replacementReprint: "",
      version: "",
      safeUnsafe: "",
      insert: "",
      cover: "",
      exception: "",
      editClicked: true,
      isValid: true,
    };

    if (name === "add") {
      newList.push(newObj);
      this.setState(
        {
          list: newList,
          editableList: newList,
          // disableAdd: true,
          disableEdit: false,
          preAlertHeaderID: this.state.preAlertHeaderID,
          isTicked: true,
        },
        () => {
          document.getElementById(`tableRow${newList.length - 1}`).focus();
        }
      );
    } else {
      // e.persist();
      if (name === "reset")
        this.myRef.current.value = "";
      // console.log(this.state.preAlertHeaderID)
      if (this.state.preAlertHeaderID)
        getResetPreAlertStaging(this.state.preAlertHeaderID).then(r => {
          this.setState({
            list: r && r.data,
            editableList: [],
            // disableAdd: false,
            disableEdit: true,
            fileName: "",
            preAlertHeaderID: 0,
          });
        })
      else {
        this.setState({
          list: [],
          editableList: [],
          // disableAdd: false,
          disableEdit: true,
          fileName: "",
          preAlertHeaderID: this.state.preAlertHeaderID,
        });
      }

    }
  };

  onEditClicked = (e, i) => {
    let newEditableList = clone(this.state.list);
    newEditableList.map((list) => {
      list.editIconEnable = false;
    });
    newEditableList[i].editClicked = !newEditableList[i].editClicked;
    newEditableList[i].editIconEnable = true;
    // newEditableList[i].exception = "";
    // alert("edit");
    this.setState({
      //  editableList: newEditableList,
      list: newEditableList,
      isTicked: true,
    });
  };
  onDeleteClicked = async (e, i, row) => {
    let newList = clone(this.state.list);
    if (newList && newList.length === 1)
      this.myRef.current.value = "";
    newList[i].editClicked = false;
    if (!row.preAlertStaggingID) {
      newList.splice(i, 1);
      this.setState({
        list: newList,
        isTicked: false,
        fileName: ""
      });
    } else {
      await deletePreAlertById(row.preAlertStaggingID).then((r) => {
        newList.splice(i, 1);
        this.setState({
          list: newList,
          isTicked: false,
          fileName: ""
        });
      });
    }
  };
  onExcelDataChanged = (e, name, i) => {
    if (name == "totalInvoiceAmt") {
      let newList = clone(this.state.list);
      newList[i][name] = this.checkDHlWeightDec(e.target.value.toString());
      this.setState({ list: newList, editClicked: true });
    } else if (name == "wpc" || name == "totalWeight" || name == "netWeight") {
      let newList = clone(this.state.list);
      newList[i][name] = this.checkDHlWeightDec1(e.target.value.toString());
      this.setState({ list: newList, editClicked: true });
    } else if (name === "acronym" || name === "volume" || name === "issue" || name === "supp") {
      let newList = clone(this.state.list);
      newList[i][name] = e.target.value ? e.target.value.toUpperCase() : ""
      this.setState({ list: newList, editClicked: true });
    }
    else {
      let newList = clone(this.state.list);
      newList[i][name] = e.target.value;
      this.setState({ list: newList, editClicked: true });
    }
  };
  handleInputChange = (e, validate) => {
    let newRrrors = { ...this.state.errors };
    let result = validate(e.target.value);
    newRrrors[e.target.name] = !result;
    this.setState({ [e.target.name]: e.target.value, errors: newRrrors });
  };

  handleAutocompleteCustomer = (e, val) => {
    this.handleClickButton("reset");
    this.setState({ customerId: val && val.id, customer: val && val });
  };
  handleAutocompleteLocation = (e, val) => {
    this.handleClickButton("reset1");
    if (val)
      this.setState({ locationId: val && val.key, locationObj: val && val, disableAdd: false });
    else
      this.setState({ locationId: "", locationObj: {}, disableAdd: true });

  };
  onDoneClicked = (e, i, record) => {
    let newList = [...this.state.list];

    newList.map((list) => {
      list.editIconEnable = true;
    });
    let customerID = this.state.customerId;
    let locationID = this.state.locationId ? this.state.locationId : null;
    let jouranlID = this.state.jouranlID || 0;
    let sNo = i + 1;
    let preAlertHeaderID = this.state.preAlertHeaderID;
    let invoiceNos = newList[i].invoiceNo;
    let currency = newList[i].currency;
    let totalInvoiceAmt = newList[i].totalInvoiceAmt;
    let doNos = newList[i].doNos;
    let office = newList[i].office;
    let title = newList[i].title;
    let acronym = newList[i].acronym;
    let volume = newList[i].volume;
    let issue = newList[i].issue;
    let suppNumber = newList[i].supp;
    let printRun = newList[i].printRun ? +newList[i].printRun : "";
    let processedQuantity = newList[i].processedQuantity || 0;
    let wpc = newList[i].wpc;
    let totalWeight = newList[i].printRun * newList[i].wpc;
    let totalPallet = newList[i].totalPallet;
    let newWeight = newList[i].netWeight !== null ? newList[i].netWeight : 0;
    let thickness = newList[i].thickness;
    let replacementOrReprint = newList[i].replacementReprint;
    let catagory = newList[i].catagory;
    let version = newList[i].version;
    let safeOrUnsafe = newList[i].safeUnsafe;
    let inserted = newList[i].insert;
    let cover = newList[i].cover;
    let isOnHoldJRReport = newList[i].isOnHoldJRReport;
    let isJRRGenerated = newList[i].isJRRGenerated;
    let exceptionMessage = newList[i].exceptionMessage;
    let modifiedBy = window.localStorage.loggedInId;
    let isValid = newList[i].isValid;
    let preAlertStaggingID = newList[i].preAlertStaggingID || 0;
    let reqBody = {
      customerID,
      locationID,
      jouranlID,
      sNo,
      preAlertHeaderID,
      preAlertStaggingID,
      invoiceNos,
      currency,
      totalInvoiceAmt,
      doNos,
      office,
      title,
      acronym,
      volume,
      issue,
      suppNumber,
      processedQuantity,
      wpc,
      totalWeight,
      totalPallet,
      newWeight,
      thickness,
      replacementOrReprint,
      catagory,
      version,
      safeOrUnsafe,
      inserted,
      cover,
      isOnHoldJRReport,
      isJRRGenerated,
      exceptionMessage,
      modifiedBy,
      isValid,
    };

    if (printRun)
      reqBody.printRun = printRun
    putPrealert(preAlertStaggingID, reqBody).then((val) => {
      GetPreAlertDownLoad(val.preAlertHeaderID).then((r) => {
        let newList = [];
        r.data.map((res) => {
          newList.push({
            sNo: res.sNo,
            invoiceNo: res.invoiceNos,
            currency: res.currency,
            totalInvoiceAmt: res.totalInvoiceAmt,
            doNos: res.doNos,
            office: res.office,
            title: res.title,
            acronym: res.acronym,
            volume: res.volume,
            issue: res.issue,
            supp: res.suppNumber,
            printRun: res.printRun ? res.printRun : "",
            wpc: res.wpc,
            totalWeight: res.totalWeight,
            totalPallet: res.totalPallet,
            netWeight: res.newWeight,
            thickness: res.thickness,
            replacementReprint: res.replacementReprint,
            version: res.version,
            safeUnsafe: res.safeOrUnsafe,
            insert: res.inserted,
            cover: res.cover,
            exception: res.exceptionMessage ? res.exceptionMessage : "",
            fromDB: true,
            preAlertHeaderID: res.preAlertHeaderID,
            preAlertStaggingID: res.preAlertStaggingID,
            editIconEnable: true,
          });
          this.setState({
            preAlertHeaderID: val.preAlertHeaderID,
          });
        });
        this.setState({
          list: newList,
          editableList: newList,
          // disableAdd: true,
          disableEdit: false,
          // PreAlertHeaderID: r.length > 0 && r[0].preAlertHeaderID,
          loading: false,
        });
      });
      // this.setState({
      //   PreAlertHeaderID: val.preAlertHeaderID,
      // });
    });
    newList[i].editClicked = false;
    this.setState({ list: newList, isTicked: false });
  };

  handleSubmit = (e) => {
    let reqBody = {};
    let list = [];
    let loggedInId = localStorage.getItem("loggedInId");

    // reqBody.IsEmailSent = false;
    reqBody.CustomerID = this.state.customerId;
    reqBody.LocationID = this.state.locationId ? this.state.locationId : null
    // reqBody.CreatedBy = loggedInId;
    reqBody.ModifiedBy = loggedInId;
    reqBody.preAlertheaderID = this.state.preAlertHeaderID;
    if (this.state.list.length === 0)
      reqBody.preAlertheaderID = 0
    // reqBody.UploadFiles = null;
    this.state.list && this.state.list.map((r) => {
      list.push({
        fileName: r.fileName,
        invoiceNo: r.invoiceNo,
        currency: r.currency,
        totalInvoiceAmt: r.totalInvoiceAmt,
        doNos: r.doNos,
        office: r.office,
        title: r.title,
        acronym: r.acronym,
        volume: r.volume,
        issue: r.issue,
        supp: r.supp,
        printRun: r.printRun,
        wpc: r.wpc,
        totalWeight: r.printRun * r.wpc,
        totalPallet: r.totalPallet,
        netWeight: r.netWeight,
        thickness: r.thickness,
        replacementReprint: r.replacementReprint,
        version: r.version,
        safeUnsafe: r.safeUnsafe,
        insert: r.insert,
        cover: r.cover,
        exception: r.exception,
        preAlertHeaderID: this.state.preAlertHeaderID,
      });
    });
    // reqBody.abc = list;

    submitPreAlertFiles(reqBody, this.props.history).then((r) => { });
  };

  checkOGSubmit = () => {
    let notValid = false;
    if (this.state.list.length === 0) return true;
    else;
    {
      this.state.list.map((l) => {
        if (l.exception === "") notValid = false;
        else {
          notValid = true;
        }
      });

      this.state.list.map((l) => {
        if (
          !l.acronym ||
          !l.volume ||
          !(l.issue || l.supp) ||
          !l.printRun ||
          !l.wpc ||
          !l.replacementReprint
          // !l.isValid
        ) {
          notValid = true;
        }
      });
      return notValid;
    }
  };

  // handleCoverChange = (event, name, i) => {
  //   let newList = clone(this.state.list);
  //   newList[i][name] = event.target.checked ? "Y" : "N";
  //   this.setState({ list: newList, editClicked: true });
  // };

  onBlur = (event, name, index) => {
    // const { dhlRateValues } = this.state;
    // let insertMasterValues = event.target.value;
    // insertMasterValues = this.addZeroes(insertMasterValues.toString(), 3);
    // this.setState({ [event.target.name]: insertMasterValues });

    let newList = clone(this.state.list);
    let values = event.target.value;
    if (name == "totalInvoiceAmt") {
      values = this.addZeroes(values.toString(), 2);
    } else {
      values = this.addZeroes(values.toString(), 3);
    }

    newList[index][name] = values;
    this.setState({ list: newList });
  };

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  checkDHlWeightDec1(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  onYorNKeyPress(event) {
    const re = /[yYnN]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  handleCountryChange = async (event, name, index) => {
    let newList = clone(this.state.list);

    let lastIndexValue = event.target.id.split("option-")[1];
    await this.setState({
      country:
        this.state.countryList[lastIndexValue] &&
        this.state.countryList[lastIndexValue].value,
      gstTypeID:
        this.state.countryList[lastIndexValue] &&
        this.state.countryList[lastIndexValue].key,
    });
    newList[index][name] =
      this.state.countryList[lastIndexValue] &&
      this.state.countryList[lastIndexValue].value.split("- ")[1];

    await this.setState({ list: newList });
  };

  render() {
    let { value, list, fileName } = this.state;
    let { classes } = this.props;
    let replacementOptions = replacementReprintData.map((val) => {
      return val.label;
    });

    let versionOptions = versionData.map((val) => {
      return val.label;
    });
    // console.log(this.state.locationId)
    return (
      <Paper>
        <div
          style={
            !this.props.clearOpenList
              ? {
                maxWidth: "calc(100vw - 102px)",
                paddingBottom: "0.5em",
              }
              : {
                paddingBottom: "0.5em",
              }
          }
        >
          <div className="pop-up-header">Create New Pre Alert</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ display: "flex" }}>
              {/* <TextField
                disabled
                label="Customer"
                name="customer"
                className={classes.textField}
                value={this.state.customerName}
                style={{ width: "100%", marginLeft: "16px" }}
                margin="normal"
                variant="filled"
              /> */}
              <Autocomplete
                autoComplete
                includeInputInList
                disableClearable
                options={this.state.customerList}
                onChange={this.handleAutocompleteCustomer}
                value={this.state.customer}
                getOptionLabel={(option) => option.text}
                style={{ width: "25%", marginLeft: "16px", marginRight: "16px" }}
                renderInput={(params) => (
                  <TextField {...params}
                    required
                    margin="normal"
                    label="Customer"
                    fullWidth />
                )}
              />
              <Autocomplete
                options={this.state.locationList}
                value={this.state.locationObj}
                disableClearable
                style={{ width: "25%" }}
                onChange={this.handleAutocompleteLocation}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Location"
                    margin="normal"
                    required
                    fullWidth
                  />
                )}
              />
            </div>

            {this.state.locationId ? <div
              style={{
                width: "auto",
                border: "2px dashed #eaeaea",
                margin: "15px 1em 30px 1em",
                borderRadius: "4px",
              }}
            >
              <label
                className="upload excel"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  minHeight: "40px",
                  // cursor: "pointer",
                  padding: ".3em",
                }}
              >
                <input
                  // disabled={this.checkOGDisability()}
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => this.handleUpload(e)}
                  accept=".xlsx"
                  ref={this.myRef}
                />
                <div
                  style={
                    // this.checkOGDisability()
                    //   ? {
                    //       backgroundColor: "#cccccc",
                    //       color: "#515151",
                    //       borderRadius: "4px",
                    //       padding: ".3em 1em .3em 1em",
                    //     } :
                    {
                      backgroundColor: "#3982ea",
                      color: "#fff",
                      borderRadius: "4px",
                      padding: ".3em 1em .3em 1em",
                      cursor: "pointer",
                    }
                  }
                >
                  CHOOSE FILE
                </div>
                <div
                  style={{
                    display: "inline-block",
                    width: "auto",
                    marginLeft: "12px",
                    width: "320px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "13px",
                    }}
                  >
                    {fileName ? fileName : "No file choosen"}
                  </div>
                  <div className="file-upload-footer">
                    {!fileName
                      ? "Allowed only “.xlsx” format, 500KB file limit."
                      : ""}
                  </div>
                </div>
              </label>
            </div> : <div
              style={{
                width: "auto",
                border: "2px dashed #eaeaea",
                margin: "15px 1em 30px 1em",
                borderRadius: "4px",
                padding: "1em"
              }}
            >Please select a Location
              </div>}

            {/* {!this.checkOGDisability() && ( */}
            <div style={{ margin: "0 0 0 0.4em" }}>
              <EnhancedTableToolbar
                //numSelected={selected.length}
                handleClickButton={this.handleClickButton}
                disableAdd={this.state.disableAdd}
                disableEdit={this.state.disableEdit}
                showText={this.state.isTicked}
              />
            </div>
            {/* )} */}
            {
              <div
                style={
                  !this.props.clearOpenList
                    ? {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 100px)",
                      margin: "0 1em 0 1em",
                    }
                    : {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 390px)",
                      margin: "0 1em 0 1em",
                    }
                }
              >
                {/* {!this.checkOGDisability() && ( */}
                <div style={{ overflow: "auto" }}>
                  {/* //overflow: "auto" */}
                  <Table
                    stickyHeader
                    aria-labelledby="tableTitle"
                    size="small"
                    aria-label="enhanced table"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <EnhancedTableHead />

                    {!this.state.loading ? (
                      <TableBody style={{ overflow: "hidden" }}>
                        {list && list.length > 0 && list.map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              id={`tableRow${index}`}
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                              style={
                                !row.isValid
                                  ? {
                                    backgroundColor: "#ebeced",
                                  }
                                  : null
                              }
                            // style={{
                            //   paddingRight: "6px!important",
                            // }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                                style={{
                                  padding: "0 6px",
                                  minWidth: "120px !important",
                                }}
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.editClicked ? (
                                    <Input
                                      style={{ fontSize: "12px!important" }}
                                      onKeyPress={this._onAlphaKeyPress}
                                      defaultValue={row.invoiceNo}
                                      inputProps={{
                                        "aria-label": "description",
                                      }}
                                      onChange={(e) => {
                                        this.onExcelDataChanged(
                                          e,
                                          "invoiceNo",
                                          index
                                        );
                                      }}
                                      value={row.invoiceNo}
                                    />
                                  ) : row.invoiceNo &&
                                    row.invoiceNo.length > 16 ? (
                                        <Tooltip
                                          title={row.invoiceNo}
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.invoiceNo}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.invoiceNo}
                                        </Typography>
                                      )}
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                                style={{ padding: "0 6px" }}
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.editClicked ? (
                                    <Input
                                      style={{ fontSize: "12px!important" }}
                                      onKeyPress={this._onlyAlphaKeyPress}
                                      defaultValue={row.currency}
                                      inputProps={{
                                        "aria-label": "description",
                                      }}
                                      onChange={(e) => {
                                        this.onExcelDataChanged(
                                          e,
                                          "currency",
                                          index
                                        );
                                      }}
                                      value={row.currency}
                                    />
                                  ) : row.currency &&
                                    row.currency.length > 16 ? (
                                        <Tooltip
                                          title={row.currency}
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.currency}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.currency}
                                        </Typography>
                                      )}
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-right-cell"
                                style={{ padding: "0 6px" }}
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.editClicked ? (
                                    <Input
                                      style={{ fontSize: "12px!important" }}
                                      onKeyPress={this._onNumberwithDotKeyPress}
                                      defaultValue={row.totalInvoiceAmt}
                                      inputProps={{
                                        "aria-label": "description",
                                      }}
                                      onChange={(e) => {
                                        this.onExcelDataChanged(
                                          e,
                                          "totalInvoiceAmt",
                                          index
                                        );
                                      }}
                                      onBlur={(e) =>
                                        this.onBlur(e, "totalInvoiceAmt", index)
                                      }
                                      value={row.totalInvoiceAmt}
                                    />
                                  ) : row.totalInvoiceAmt &&
                                    row.totalInvoiceAmt.length > 16 ? (
                                        <Tooltip
                                          title={row.totalInvoiceAmt}
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.totalInvoiceAmt}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.totalInvoiceAmt}
                                        </Typography>
                                      )}
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="right"
                                className="table-content-cell"
                                style={{
                                  padding: "0 6px",
                                  minWidth: "120px!important",
                                }}
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.editClicked ? (
                                    <Input
                                      style={{ fontSize: "12px!important" }}
                                      onKeyPress={this._freeText}
                                      defaultValue={row.doNos}
                                      inputProps={{
                                        "aria-label": "description",
                                      }}
                                      onChange={(e) => {
                                        this.onExcelDataChanged(
                                          e,
                                          "doNos",
                                          index
                                        );
                                      }}
                                      value={row.doNos}
                                    />
                                  ) : row.doNos && row.doNos.length > 16 ? (
                                    <Tooltip title={row.doNos} aria-label="add">
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.doNos}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.doNos}
                                        </Typography>
                                      )}
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                // padding="default"
                                align="left"
                                className="table-content-cell"
                                style={{
                                  padding: "0 6px",
                                  minWidth: "120px!important",
                                }}
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.editClicked ? (
                                    <Input
                                      style={{ fontSize: "12px!important" }}
                                      onKeyPress={this._freeText}
                                      defaultValue={row.office}
                                      inputProps={{
                                        "aria-label": "description",
                                      }}
                                      onChange={(e) => {
                                        this.onExcelDataChanged(
                                          e,
                                          "office",
                                          index
                                        );
                                      }}
                                      value={row.office}
                                    />
                                  ) : row.office && row.office.length > 16 ? (
                                    <Tooltip
                                      title={row.office}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.office}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.office}
                                        </Typography>
                                      )}
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                                style={{
                                  padding: "0 6px",
                                  minWidth: "150px!important",
                                }}
                              >
                                {row.editClicked ? (
                                  <Input
                                    style={{ fontSize: "12px!important" }}
                                    onKeyPress={this._freeText}
                                    defaultValue={row.title}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "title",
                                        index
                                      );
                                    }}
                                    value={row.title}
                                  />
                                ) : row.title && row.title.length > 16 ? (
                                  <Tooltip title={row.title} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.title}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.title}
                                      </Typography>
                                    )}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                style={{
                                  padding: "0 6px",
                                  minWidth: "120px!important",
                                }}
                                className="table-content-cell"
                              >
                                {row.editClicked ? (
                                  <Input
                                    style={{ fontSize: "12px!important" }}
                                    onKeyPress={this._onAlphaKeyPress}
                                    defaultValue={row.acronym}
                                    inputProps={{
                                      maxLength:
                                        this.state.customer &&
                                          this.state.customer.value === "Wiley"
                                          ? 4
                                          : 14,
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "acronym",
                                        index
                                      );
                                    }}
                                    value={row.acronym}
                                  />
                                ) : row.acronym && row.acronym.length > 16 ? (
                                  <Tooltip title={row.acronym} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.acronym}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.acronym}
                                      </Typography>
                                    )}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-right-cell"
                                style={{
                                  padding: "0 6px",
                                  minWidth: "120px!important",
                                }}
                              >
                                {row.editClicked ? (
                                  <Input
                                    style={{ fontSize: "12px!important" }}
                                    onKeyPress={this._onAlphaKeyPress}
                                    defaultValue={row.volume}
                                    inputProps={{
                                      "aria-label": "description",
                                      maxLength: 4,
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "volume",
                                        index
                                      );
                                    }}
                                    value={row.volume}
                                  />
                                ) : row.volume && row.volume.length > 16 ? (
                                  <Tooltip title={row.volume} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.volume}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.volume}
                                      </Typography>
                                    )}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-right-cell"
                                style={{
                                  padding: "0 6px",
                                  minWidth: "120px!important",
                                }}
                              >
                                {row.editClicked ? (
                                  <Input
                                    style={{ fontSize: "12px!important" }}
                                    onKeyPress={this.state.customer && this.state.customer.value === "WS" ? this._onWSIssueKeyPress : this._onAlphaKeyPress}
                                    defaultValue={row.issue}
                                    inputProps={{
                                      "aria-label": "description",
                                      maxLength: 7,
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "issue",
                                        index
                                      );
                                    }}
                                    value={row.issue}
                                    disabled={row.supp}
                                  />
                                ) : row.issue && row.issue.length > 16 ? (
                                  <Tooltip title={row.issue} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.issue}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.issue}
                                      </Typography>
                                    )}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                                style={{ padding: "0 6px" }}
                              >
                                {row.editClicked ? (
                                  <Input
                                    style={{ fontSize: "12px!important" }}
                                    // onKeyPress={this._onAlphaKeyPress}
                                    onKeyPress={this.state.customer && this.state.customer.value === "WS" ?
                                      this._onWSTextPlusNumberWithNoSpaceKeyPress : this._onAlphaKeyPress}

                                    defaultValue={row.supp}
                                    inputProps={{
                                      "aria-label": "description",
                                      maxLength: 7,
                                    }}
                                    disabled={row.issue}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(e, "supp", index);
                                    }}
                                    value={row.supp}
                                  />
                                ) : row.supp && row.supp.length > 16 ? (
                                  <Tooltip title={row.supp} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.supp}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.supp}
                                      </Typography>
                                    )}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-right-cell"
                                style={{ padding: "0 6px" }}
                              >
                                {row.editClicked ? (
                                  <Input
                                    style={{ fontSize: "12px!important" }}
                                    onKeyPress={this._onNumberKeyPress}
                                    defaultValue={row.printRun}
                                    inputProps={{
                                      "aria-label": "description",
                                      maxLength: 18,
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "printRun",
                                        index
                                      );
                                    }}
                                    value={row.printRun}
                                  />
                                ) : row.printRun && row.printRun.length > 16 ? (
                                  <Tooltip
                                    title={row.printRun}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.printRun}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.printRun}
                                      </Typography>
                                    )}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-right-cell"
                                style={{
                                  padding: "0 6px",
                                  minWidth: "120px!important",
                                }}
                              >
                                {row.editClicked ? (
                                  <Input
                                    style={{ fontSize: "12px!important" }}
                                    onKeyPress={this._onNumberwithDotKeyPress}
                                    defaultValue={row.wpc}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(e, "wpc", index);
                                    }}
                                    value={row.wpc}
                                    onBlur={(e) => this.onBlur(e, "wpc", index)}
                                  />
                                ) : row.wpc && row.wpc.length > 16 ? (
                                  <Tooltip title={row.wpc} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.wpc}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.wpc}
                                      </Typography>
                                    )}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-right-cell"
                                style={{
                                  padding: "0 6px",
                                  minWidth: "120px!important",
                                }}
                              >
                                {row.editClicked ? (
                                  <Input
                                    disabled
                                    style={{ fontSize: "12px!important" }}
                                    onKeyPress={this._onNumberwithDotKeyPress}
                                    defaultValue={row.totalWeight}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "totalWeight",
                                        index
                                      );
                                    }}
                                    // value={row.totalWeight}
                                    value={
                                      row.printRun && row.wpc
                                        ? parseFloat(
                                          parseFloat(row.printRun).toFixed(
                                            3
                                          ) * parseFloat(row.wpc).toFixed(3)
                                        ).toFixed(3)
                                        : 0.0
                                    }
                                    onBlur={(e) =>
                                      this.onBlur(e, "totalWeight", index)
                                    }
                                  />
                                ) : row.printRun * row.wpc &&
                                  (row.printRun * row.wpc).length > 16 ? (
                                      <Tooltip
                                        title={(row.printRun * row.wpc).toFixed(3)}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {(row.printRun * row.wpc).toFixed(3)}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {(row.printRun * row.wpc).toFixed(3)}
                                      </Typography>
                                    )}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-right-cell"
                                style={{ padding: "0 6px" }}
                              >
                                {row.editClicked ? (
                                  <Input
                                    style={{ fontSize: "12px!important" }}
                                    onKeyPress={this._onNumberwithDotKeyPress}
                                    defaultValue={row.totalPallet}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "totalPallet",
                                        index
                                      );
                                    }}
                                    value={row.totalPallet}
                                  />
                                ) : row.totalPallet &&
                                  row.totalPallet.length > 16 ? (
                                      <Tooltip
                                        title={row.totalPallet}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.totalPallet}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.totalPallet}
                                      </Typography>
                                    )}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-right-cell"
                                style={{ padding: "0 6px" }}
                              >
                                {row.editClicked ? (
                                  <Input
                                    style={{ fontSize: "12px!important" }}
                                    onKeyPress={this._onNumberwithDotKeyPress}
                                    defaultValue={row.netWeight}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "netWeight",
                                        index
                                      );
                                    }}
                                    onBlur={(e) =>
                                      this.onBlur(e, "netWeight", index)
                                    }
                                    value={row.netWeight}
                                  />
                                ) : row.netWeight &&
                                  row.netWeight.length > 16 ? (
                                      <Tooltip
                                        title={row.netWeight}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.netWeight}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.netWeight}
                                      </Typography>
                                    )}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-right-cell"
                                style={{ padding: "0 6px" }}
                              >
                                {row.editClicked ? (
                                  <Input
                                    style={{ fontSize: "12px!important" }}
                                    onKeyPress={this._freeText}
                                    defaultValue={row.thickness}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "thickness",
                                        index
                                      );
                                    }}
                                    value={row.thickness}
                                  />
                                ) : row.thickness &&
                                  row.thickness.length > 16 ? (
                                      <Tooltip
                                        title={row.thickness}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.thickness}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.thickness}
                                      </Typography>
                                    )}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                                style={{
                                  padding: "0 6px",
                                  minWidth: "150px!important",
                                }}
                              >
                                {row.editClicked ? (
                                  <Autocomplete
                                    autoComplete
                                    includeInputInList
                                    options={replacementOptions}
                                    onChange={(e) =>
                                      this.handleReplacementChange(
                                        e,
                                        "replacementReprint",
                                        index
                                      )
                                    }
                                    value={row.replacementReprint}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        //required
                                        // label="replacementReprint"
                                        margin="normal"
                                        style={{
                                          width: "100%",
                                          marginTop: "8px",
                                        }}
                                      />
                                    )}
                                  />
                                ) : row.replacementReprint &&
                                  row.replacementReprint.length > 16 ? (
                                      <Tooltip
                                        title={row.replacementReprint}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.replacementReprint}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.replacementReprint}
                                      </Typography>
                                    )}
                                {/* {row.editClicked ? (
                                  <Input
                                    style={{ fontSize: "12px!important" }}
                                    onKeyPress={this._onReplacementPress}
                                    defaultValue={row.replacementReprint}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "replacementReprint",
                                        index
                                      );
                                    }}
                                    value={row.replacementReprint}
                                    style={{ padding: "0 6px" }}
                                  />
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.replacementReprint}
                                  </Typography>
                                )} */}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                                style={{
                                  padding: "0 6px",
                                  minWidth: "120px!important",
                                }}
                              >
                                {row.editClicked ? (
                                  // <Autocomplete
                                  //   autoComplete
                                  //   includeInputInList
                                  //   options={versionOptions}
                                  //   onChange={(e) =>
                                  //     this.handleVersionChange(
                                  //       e,
                                  //       "version",
                                  //       index
                                  //     )
                                  //   }
                                  //   value={row.version}
                                  //   getOptionLabel={(option) => option}
                                  //   renderInput={(params) => (
                                  //     <TextField
                                  //       {...params}
                                  //       //required
                                  //       // label="replacementReprint"
                                  //       margin="normal"
                                  //       style={{
                                  //         width: "100%",
                                  //         marginTop: "8px",
                                  //       }}
                                  //     />
                                  //   )}
                                  // />
                                  <Input
                                    style={{ fontSize: "12px!important" }}
                                    onKeyPress={this._freeText}
                                    defaultValue={row.version}
                                    inputProps={{
                                      "aria-label": "description",
                                      maxLength: 100,
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "version",
                                        index
                                      );
                                    }}
                                    value={row.version}
                                    style={{ padding: "0 6px" }}
                                  />
                                ) : row.version && row.version.length > 16 ? (
                                  <Tooltip title={row.version} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.version}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.version}
                                      </Typography>
                                    )}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                                style={{ padding: "0 6px" }}
                              >
                                {row.editClicked ? (
                                  // <FormControlLabel
                                  //   control={
                                  //     <Switch
                                  //       checked={
                                  //         row.safeUnsafe == "Y" ? true : false
                                  //       }
                                  //       onChange={(e) =>
                                  //         this.handleCoverChange(
                                  //           e,
                                  //           "safeUnsafe",
                                  //           index
                                  //         )
                                  //       }
                                  //       value={row.safeUnsafe}
                                  //       color="primary"
                                  //     />
                                  //   }
                                  //   className={classes.switchControl}
                                  // />
                                  <Input
                                    style={{ fontSize: "12px!important" }}
                                    onKeyPress={this._freeText}
                                    defaultValue={row.safeUnsafe}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "safeUnsafe",
                                        index
                                      );
                                    }}
                                    value={row.safeUnsafe}
                                    style={{ padding: "0 6px" }}
                                  />
                                ) : row.safeUnsafe &&
                                  row.safeUnsafe.length > 16 ? (
                                      <Tooltip
                                        title={row.safeUnsafe}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.safeUnsafe}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.safeUnsafe}
                                      </Typography>
                                    )}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                                style={{
                                  padding: "0 6px",
                                  minWidth: "40px!important",
                                }}
                              >
                                {row.editClicked ? (
                                  // <FormControlLabel
                                  //   control={
                                  //     <Switch
                                  //       checked={
                                  //         row.insert == "Y" ? true : false
                                  //       }
                                  //       onChange={(e) =>
                                  //         this.handleCoverChange(
                                  //           e,
                                  //           "insert",
                                  //           index
                                  //         )
                                  //       }
                                  //       value={row.insert}
                                  //       color="primary"
                                  //     />
                                  //   }
                                  //   className={classes.switchControl}
                                  // />
                                  <Input
                                    style={{ fontSize: "12px!important" }}
                                    // onKeyPress={this._freeText}
                                    onKeyPress={this.onYorNKeyPress}
                                    defaultValue={row.insert}
                                    inputProps={{
                                      "aria-label": "description",
                                      maxLength: 1,
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "insert",
                                        index
                                      );
                                    }}
                                    value={row.insert}
                                    style={{ padding: "0 6px" }}
                                  />
                                ) : row.insert && row.insert.length > 16 ? (
                                  <Tooltip title={row.insert} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.insert}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {row.insert}
                                      </Typography>
                                    )}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                                style={{
                                  padding: "0 6px",
                                  minWidth: "40px!important",
                                }}
                              >
                                {row.editClicked ? (
                                  // <FormControlLabel
                                  //   control={
                                  //     <Switch
                                  //       checked={
                                  //         row.cover == "Y" ? true : false
                                  //       }
                                  //       onChange={(e) =>
                                  //         this.handleCoverChange(
                                  //           e,
                                  //           "cover",
                                  //           index
                                  //         )
                                  //       }
                                  //       value={row.cover}
                                  //       color="primary"
                                  //     />
                                  //   }
                                  //   className={classes.switchControl}
                                  // />
                                  <Input
                                    style={{ fontSize: "12px!important" }}
                                    // onKeyPress={this._freeText}

                                    onKeyPress={this.onYorNKeyPress}
                                    defaultValue={row.cover}
                                    inputProps={{
                                      "aria-label": "description",
                                      maxLength: 1,
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "cover",
                                        index
                                      );
                                    }}
                                    value={row.cover}
                                    style={{ padding: "0 6px" }}
                                  />
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.cover}
                                    </Typography>
                                  )}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                                style={{
                                  padding: "0 6px",
                                  minWidth: "40px!important",
                                }}
                              >
                                {row.exception && row.exception.length > 16 ? (
                                  <Tooltip
                                    title={row.exception}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                        color: "red",
                                      }}
                                    >
                                      {row.exception}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{
                                        fontSize: "14px",
                                        color: "red",
                                      }}
                                    >
                                      {row.exception}
                                    </Typography>
                                  )}
                              </TableCell>

                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                                style={{ minWidth: "50px" }}
                              >
                                {!row.editClicked && row.editIconEnable ? (
                                  <div style={{ display: "inline-block" }}>
                                    <Tooltip title="Edit">
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: "#6c6b6b",
                                        }}
                                        onClick={(e) =>
                                          this.onEditClicked(e, index)
                                        }
                                      >
                                        <EditIcon
                                          style={{
                                            color: "#6c6b6b",
                                          }}
                                        // disabled={row.editIconEnable}
                                        />
                                      </span>
                                    </Tooltip>
                                  </div>
                                ) : null}
                                {row.editClicked &&
                                  row.acronym != "" &&
                                  row.volume != "" &&
                                  (row.issue != "" || row.supp != "") &&
                                  row.replacementReprint != "" &&
                                  row.wpc != "" &&
                                  (row.replacementReprint === "CES" || row.replacementReprint === "CEA" || row.replacementReprint === "CPT") ?
                                  (
                                    <div style={{ display: "inline-block" }}>
                                      <Tooltip title="Done">
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            color: "#6c6b6b",
                                          }}
                                          onClick={(e) =>
                                            this.onDoneClicked(e, index, row)
                                          }
                                        >
                                          <CheckIcon
                                            style={{
                                              color: "#6c6b6b",
                                            }}
                                          />
                                        </span>
                                      </Tooltip>
                                    </div>
                                  ) : (row.printRun != "") && <div style={{ display: "inline-block" }}>
                                    <Tooltip title="Done">
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: "#6c6b6b",
                                        }}
                                        onClick={(e) =>
                                          this.onDoneClicked(e, index, row)
                                        }
                                      >
                                        <CheckIcon
                                          style={{
                                            color: "#6c6b6b",
                                          }}
                                        />
                                      </span>
                                    </Tooltip>
                                  </div>}
                                <div style={{ display: "inline-block" }}>
                                  <Tooltip title="Delete">
                                    <DeleteIcon
                                      style={{
                                        cursor: "pointer",
                                        color: "#6c6b6b",
                                      }}
                                      onClick={(e) =>
                                        this.onDeleteClicked(e, index, row)
                                      }
                                    />
                                  </Tooltip>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : (
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan="10">
                              <LinearProgress />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                  </Table>
                </div>
                {/* )} */}
                <Divider style={{ marginTop: "25px" }} />
                <div
                  style={{
                    display: "flex",
                    marginTop: "1em",
                    marginLeft: "2px",
                  }}
                >
                  <div style={{}}>
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => {
                        this.props.history.push("/pubPreAlert");
                      }}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {/* {this.checkOGSubmit() || */}

                    <Button
                      variant="contained"
                      disabled={this.state.list.length === 0}
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.handleSubmit(e)}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </Paper>
    );
  }
}
export default withRouter(withStyles(addDispatchStyles)(PreAlertOrder));
