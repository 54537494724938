import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import { StateContext } from "../../../shared/globalState";
import { getLocalPostageLists, getFilteredLocalPostage, getCustomerDDList } from "../masterActions";
import Validator from "../../../shared/validator";
import { styles } from "../../../shared/styles";
const validateMaxLength100 = new Validator("validateMaxLength100");

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        ></IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "400px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    marginRight: "2em",
  },
}))(MuiDialogActions);

class MailingSchemeSearch extends React.Component {
  static contextType = StateContext;
  state = {
    customerName: "",
    open: false,
    customerID: "",
    customerList: [],
    errors: {
      customerID: false,
    },
  };
  componentDidMount() {
    const [{ store }, dispatch] = this.context;
    //this.props.handleSettingHeader("Local Postage");
    //this.props.setSelectedList("masterDataOpen", "localPostage");
    getLocalPostageLists().then(x => {
      getCustomerDDList().then((r) => {
        let filterArr = []
        dispatch({
          type: "getCustomerDropdownList",
          newStore: r.data,
        });
        if (r.data && r.data.length > 0 && x.data && x.data.length > 0) {
          r.data.map(a => {
            let found = false
            x.data.map(b => {
              if (b.customerCode === a.value) {
                if (b.customerCode === "NA") {
                  if (a.text.slice(5) === b.customerName) {
                    found = true
                  }
                }
                else
                  found = true
                return
              }
            })
            if (found)
              filterArr.push(a)
          })
        }
        this.getAllCustomers(filterArr);
      });
    })
  }
  // shouldComponentUpdate(s, p) {
  //   const { store } = this.context[0];
  //   if (store.customerDDList && store.customerDDList.length > 0) {
  //     if (this.state.customerList.length !== store.customerDDList.length) {
  //       this.getAllCustomers(store.customerDDList);
  //     }
  //   }
  //   return true;
  // }
  getAllCustomers = (list) => {
    let customerList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        return customerList.push({ key: c.id, value: c.text });
      });
    this.setState({ customerList });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleChange = (e, validate) => {
    let newRrrors = { ...this.state.errors };
    // let result = validate(e.target.value);
    //  newRrrors[e.target.name] = !result;
    this.setState({ [e.target.name]: e.target.value, errors: newRrrors });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleCustomer = (event, values) => {
    if (values) {
      let name = values;
      let customerID = values && values.key;

      // this.validateField("customer", customerID);
      this.setState({ customerID, customerName: name });
    } else {
      this.setState({ customerID: "", customerName: "" });
    }
  };
  onInputChanged = (e, validate) => {
    let newRrrors = { ...this.state.errors };
    let result = validate(e.target.value);
    newRrrors[e.target.name] = !result;
    this.setState({ [e.target.name]: e.target.value, errors: newRrrors });
  };
  onSearchClicked = () => {
    const dispatch = this.context[1];
    let { customerID } = this.state;
    let reqBody = { customerID };
    this.props.onSearchClicked(reqBody);
    // getFilteredLocalPostage(reqBody)
    //   .then((r) => {
    //     dispatch({
    //       type: "getLocalPostage",
    //       newStore: r.data,
    //     });
    //     this.props.onRequestSucceed();
    //   })
    //   .catch((e) => { });
    this.setState({ open: false });
  };
  checkButtonDisability = () => {
    let { customerID } = this.state;
    if (!customerID) return true;
    else return false;
  };
  render() {
    let { customerID, customerList, errors } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <Tooltip title="Search">
          <IconButton
            aria-label="Search"
            className={classes.iconHover}
            onClick={this.handleClickOpen}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          maxWidth="md"
          style={{ minWidth: "200px" }}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Advanced Search
          </DialogTitle>
          <DialogContent dividers>
            <div className={classes.container}>
              <div style={{ width: "100%" }}>
                <Autocomplete
                  autoComplete
                  includeInputInList
                  label="Customer"
                  //style={{ display: "inline-flex" }}
                  name="customer"
                  options={customerList}
                  className={classes.comboBox}
                  onChange={this.handleCustomer}
                  value={this.state.customerName}
                  getOptionLabel={(option) => option.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Customer"
                      name="customer"
                      style={{ width: "100%" }}
                      margin="normal"
                    />
                  )}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              //disableElevation
              style={{ boxShadow: "none" }}
              onClick={this.handleClose}
            >
              CANCEL
            </Button>
            {this.checkButtonDisability() ? (
              <Button
                disabled
                //autoFocus
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.onSearchClicked()}
              >
                SEARCH
              </Button>
            ) : (
                <Button
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.onSearchClicked()}
                >
                  SEARCH
                </Button>
              )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(MailingSchemeSearch);
