import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Autocomplete } from "@material-ui/lab";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import { stableSort, getSorting } from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { Checkbox } from "@material-ui/core";
import types from "../userAccessTypes";
import { StateContext } from "../../../shared/globalState";
import {
  getRoleActionList,
  deleteRoleAction,
  getRoleList,
  getMasterRoleActionList,
  postRoleAction,
} from "../userAccessAction";
import { withStyles } from "@material-ui/core/styles";
import { addStyles } from "../../../shared/styles";

const columns = [
  {
    name: "Created By",
    id: 14,
  },
  {
    name: "Created Date",
    id: 15,
  },
  {
    name: "Modified By",
    id: 16,
  },
  {
    name: "Modified Date",
    id: 17,
    show: false,
  },
  {
    name: "Active",
    id: 18,
    show: false,
  },
];

function createData(
  masterDataRoleActionMappingResultID,
  roleID,
  superMenuID,
  menuID,
  subMenuID,
  superMenuName,
  menuName,
  subMenuName,
  viewAction,
  addAction,
  editAction,
  deleteAction,
  advanceSearchAction,
  exportAction,
  approveAction,
  reportAction,
  printAction,
  isReport
) {
  return {
    masterDataRoleActionMappingResultID,
    roleID,
    superMenuID,
    menuID,
    subMenuID,
    superMenuName,
    menuName,
    subMenuName,
    viewAction,
    addAction,
    editAction,
    deleteAction,
    advanceSearchAction,
    exportAction,
    approveAction,
    reportAction,
    printAction,
    isReport,
  };
}

function createRoleData(
  roleID,
  menuID,
  subMenuID,
  superMenuID,
  viewAction,
  addAction,
  editAction,
  deleteAction,
  advanceSearchAction,
  exportAction,
  approveAction,
  reportAction,
  printAction,
  isReport,
  createdBy
) {
  return {
    roleID,
    menuID,
    subMenuID,
    superMenuID,
    viewAction,
    addAction,
    editAction,
    deleteAction,
    advanceSearchAction,
    exportAction,
    approveAction,
    reportAction,
    printAction,
    isReport,
    createdBy,
  };
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          ></div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class EditRoleAction extends React.Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      loading1: false,
      role: {
        masterDataRoleActionMappingResultID: "",
        roleID: "",
        menuID: "",
        menuName: "",
        subMenuID: "",
        subMenuName: "",
        viewAction: false,
        addAction: false,
        editAction: false,
        deleteAction: false,
        advanceSearchAction: false,
        exportAction: false,
        approveAction: false,
        reportAction: false,
        printAction: false,
        isReport: false,
        createdBy: 1,
      },
      deletePopupOpen: false,
      order: "desc",
      orderBy: "modifiedDate",
      selected: [],
      page: 0,
      rowsPerPage: 100,
      roleActionList: [],
      roleList: [],
      refreshData: false,
      selectedRoleAction: "",
      selectedRoleActionId: 1,
      selectedRoleId: 0,
      selectedDDL: -1,
      loading: false,
      searchPopupOpen: false,
      roleActionSearchValues: {},
      searchClicked: false,
      formValid: false,
      selectedShowColumns: [],
      headCells: [
        {
          id: 1,
          code: "superMenuName",
          numeric: false,
          disablePadding: true,
          label: "Super Menu Name",
          show: true,
        },
        {
          id: 2,
          code: "menuName",
          numeric: false,
          disablePadding: true,
          label: "Menu Name",
          show: true,
        },
        {
          id: 3,
          code: "subMenuName",
          numeric: false,
          disablePadding: true,
          label: "Sub Menu Name",
          show: true,
        },
        {
          id: 4,
          code: "viewAction",
          numeric: false,
          disablePadding: false,
          label: "View",
          show: true,
        },
        {
          id: 5,
          code: "addAction",
          numeric: false,
          disablePadding: false,
          label: "Add",
          show: true,
        },
        {
          id: 6,
          code: "editAction",
          numeric: false,
          disablePadding: false,
          label: "Edit",
          show: true,
        },
        {
          id: 7,
          code: "deleteAction",
          numeric: false,
          disablePadding: false,
          label: "Delete",
          show: true,
        },
        {
          id: 8,
          code: "advanceSearchAction",
          numeric: false,
          disablePadding: false,
          label: "Adv Search",
          show: true,
        },
        {
          id: 9,
          code: "exportAction",
          numeric: false,
          disablePadding: false,
          label: "Export",
          show: true,
        },
        {
          id: 10,
          code: "approveAction",
          numeric: false,
          disablePadding: false,
          label: "Approve",
          show: true,
        },
        {
          id: 11,
          code: "reportAction",
          numeric: false,
          disablePadding: false,
          label: "Report",
          show: false,
        },
        {
          id: 12,
          code: "printAction",
          numeric: false,
          disablePadding: false,
          label: "Print",
          show: true,
        },
        // {
        //   id: 13,
        //   code: "isReport",
        //   numeric: false,
        //   disablePadding: false,
        //   label: "Is Report",
        //   show: true
        // },
        // {
        //   id: 14,
        //   code: "isActive",
        //   numeric: false,
        //   disablePadding: false,
        //   label: "Active",
        //   show: true
        // }
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event) => {
    const { role } = this.state;

    let labelName = event.target.name;
    let index = parseInt(labelName.split("-")[1]);
    let fieldName = labelName.split("-")[0];
    role.map((c) =>
      index === c.masterDataRoleActionMappingResultID
        ? ((c[fieldName] = event.target.checked), (c.createdBy = 1))
        : c
    );
    this.setState({ role });
  };

  componentDidMount() {
    this.props.handleSettingHeader("Role Action");
    this.props.setSelectedList("adminSettingOpen", "roleAction");
    const dispatch = this.context[1];
    getRoleActionList().then((r) => {
      dispatch({
        type: "getRoleActionList",
        newStore: r.data,
      });
      this.getAllRoleActions(r.data);
      getRoleList().then((r) => {
        dispatch({
          type: "getRoleList",
          newStore: r.data,
        });
        this.getAllRoles(r.data);
      });
    });
    getMasterRoleActionList().then((r) => {
      dispatch({
        type: "getMasterRoleActionList",
        newStore: r.data,
      });
      this.getAllRoleActionsByRole(r.data);
    });
  }

  getAllRoleActions = (list) => {
    let roleActionList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        roleActionList.findIndex((p) => p.value === c.roleName) > -1
          ? roleActionList
          : roleActionList.push({ key: c.roleID, value: c.roleName })
      );

    this.setState({
      roleActionList: roleActionList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      ),
    });
  };

  getAllRoles = (list) => {
    let uniqueList = [];
    let roleList = [];
    let roleActionList = this.state.roleActionList;
    list &&
      list.length > 0 &&
      list.map((c) =>
        uniqueList.findIndex((p) => p.value === c.roleName) > -1
          ? uniqueList
          : uniqueList.push({
              key: c.roleID,
              value: c.roleName,
              active: c.isActive,
            })
      );
    uniqueList &&
      uniqueList.length > 0 &&
      uniqueList.map((c) =>
        roleActionList.findIndex((p) => p.value === c.value) > -1 || !c.active
          ? c
          : roleList.push({ key: c.key, value: c.value })
      );
    this.setState({
      roleList: roleList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      ),
    });
  };
  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  getAllRoleActionsByRole = (role) => {
    let list = role;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.masterDataRoleActionMappingResultID,
            d.roleID,
            d.superMenuID,
            d.menuID,
            d.subMenuID,
            //d.subMenuID !== null ? d.subMenuID : 0,
            d.superMenuName,
            d.menuName,
            d.subMenuName,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true,
            (d.createdBy = localStorage.loggedInId)
          )
        );
      });
    this.setState({ role: rows, loading: false });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.role && store.role.length > 0)
      if (this.state.role.length !== store.role.length) {
        this.getAllRoleActionsByRole(store.role);
      }

    return true;
  }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { role } = this.state;
    if (event.target.checked) {
      const newSelecteds = role.map(
        (n) => n.masterDataRoleActionMappingResultID
      );
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, roleActionID, roleAction) {}

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchRoleAction = (handleSearchRoleAction) => {};

  handleDeleteRoleAction = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteRoleAction(modifiedBy, deletionIds).then((r) => {
      getRoleList().then((r) => {
        dispatch({
          type: types.ROLE_LIST,
          newStore: r.data,
        });
        this.getAllRoleActions(r.data);
      });
      this.props.history.push("/RoleAction");
      this.setState({ deletePopupOpen: false, selected: [] });
      //toastr.success("Role Action Deleted Successfully");
    });
  };

  handleRoleChange = (event, values) => {
    let selectedRoleId = this.state.selectedRoleId;
    let formValid = this.state.formValid;
    if (values === null) {
      //role["roleID"] = "null";
      formValid = false;
    } else {
      selectedRoleId = values.key;
      formValid = true;
    }
    this.setState({ selectedRoleId, formValid });

    //this.validateField("roleID", role["roleID"]);
  };

  handleSubmit(e) {
    this.setState({ loading1: true });
    //console.log(this.state.role, "role", this.state.selectedRoleId, "roleid");
    e.preventDefault();
    let selectedRoleId = this.state.selectedRoleId;
    const { role } = this.state;
    role.map((r) => {
      r.roleID = selectedRoleId;
      r.createdBy = localStorage.loggedInId;
      //r.createdBy = 1;
      // r.menuID = 0;
      // ((r.createdBy = 1));
    });
    // console.log(role);
    let finalList = [];
    role &&
      role.length > 0 &&
      role.map((d) => {
        return finalList.push(
          createRoleData(
            d.roleID,
            d.menuID,
            d.subMenuID,
            d.superMenuID,
            d.viewAction,
            d.addAction,
            d.editAction,
            d.deleteAction,
            d.advanceSearchAction,
            d.exportAction,
            d.approveAction,
            d.reportAction,
            d.printAction,
            d.isReport,
            d.createdBy
          )
        );
      });

    postRoleAction(finalList).then((r) => {
      this.props.history.push("/RoleAction");
    });
  }

  render() {
    let { classes } = this.props;
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      roleActionList,
      roleList,
      role,
      selectedDDL,
      selectedRoleId,
      headCells,
    } = this.state;
    const isSelected = (masterDataRoleActionMappingResultID) =>
      selected.indexOf(masterDataRoleActionMappingResultID) !== -1;
    let loop = 0;
    let list = roleList;
    list.map((option) =>
      option.key === parseInt(selectedRoleId)
        ? (selectedDDL = loop)
        : (loop = loop + 1)
    );

    return (
      <React.Fragment>
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.loading1}
        >
          <div style={{ padding: "1em" }}>
            <CircularProgress />
          </div>
        </Dialog>
        <Paper>
          <div className="pop-up-header">Role Access Mapping</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form
                className={classes.container}
                onSubmit={this.handleRoleSubmit}
              >
                <div>
                  <Autocomplete
                    className={classes.comboBox}
                    autoComplete
                    includeInputInList
                    required
                    id="role"
                    name="role"
                    hintText="role"
                    options={roleList}
                    value={roleList[selectedDDL]}
                    onChange={this.handleRoleChange}
                    getOptionLabel={(option) => option.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Role"
                        name="roleName"
                        id="roleName"
                        margin="normal"
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </div>

                <div
                  style={
                    !this.props.clearOpenList
                      ? {
                          overflow: "auto",
                          maxHeight: "calc(100vh - 400px)",
                          maxWidth: "calc(100vw - 167px)",
                        }
                      : {
                          overflow: "auto",
                          maxHeight: "calc(100vh - 400px)",
                          maxWidth: "calc(100vw - 390px)",
                        }
                  }
                >
                  <Table
                    stickyHeader
                    aria-labelledby="tableTitle"
                    size="small"
                    aria-label="enhanced table"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                      onRequestSort={this.handleRequestSort}
                      rowCount={role.length}
                      headCells={this.state.headCells}
                    />
                    <TableBody style={{ overflow: "hidden" }}>
                      {role.length > 0 ? (
                        stableSort(role, getSorting(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            const isItemSelected = isSelected(
                              row.masterDataRoleActionMappingResultID
                            );
                            return (
                              <TableRow
                                hover
                                onClick={(event) =>
                                  this.handleClick(
                                    event,
                                    row.masterDataRoleActionMappingResultID,
                                    row.roleActionName
                                  )
                                }
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.roleActionID}
                                selected={isItemSelected}
                                style={{ cursor: "pointer" }}
                              >
                                {this.state.headCells[0].show && (
                                  <TableCell
                                    component="th"
                                    // id={labelId}
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.superMenuName}
                                    </Typography>
                                  </TableCell>
                                )}
                                {this.state.headCells[1].show && (
                                  <TableCell
                                    component="th"
                                    // id={labelId}
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.menuName}
                                    </Typography>
                                  </TableCell>
                                )}
                                {this.state.headCells[2].show && (
                                  <TableCell
                                    component="th"
                                    // id={labelId}
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.subMenuName}
                                    </Typography>
                                  </TableCell>
                                )}
                                {this.state.headCells[3].show && (
                                  <TableCell
                                    padding="checkbox"
                                    className="table-content-cell"
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={
                                            "viewAction-" +
                                            row.masterDataRoleActionMappingResultID
                                          }
                                          component="th"
                                          scope="row"
                                          color="primary"
                                          checked={row.viewAction}
                                          onChange={this.handleChange}
                                          value={row.viewAction}
                                        />
                                      }
                                    />
                                  </TableCell>
                                )}
                                {this.state.headCells[4].show && (
                                  <TableCell
                                    padding="checkbox"
                                    className="table-content-cell"
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={
                                            "addAction-" +
                                            row.masterDataRoleActionMappingResultID
                                          }
                                          component="th"
                                          scope="row"
                                          color="primary"
                                          checked={row.addAction}
                                          onChange={this.handleChange}
                                          value={row.addAction}
                                        />
                                      }
                                    />
                                  </TableCell>
                                )}
                                {this.state.headCells[5].show && (
                                  <TableCell
                                    padding="checkbox"
                                    className="table-content-cell"
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={
                                            "editAction-" +
                                            row.masterDataRoleActionMappingResultID
                                          }
                                          component="th"
                                          scope="row"
                                          color="primary"
                                          checked={row.editAction}
                                          onChange={this.handleChange}
                                          value={row.editAction}
                                        />
                                      }
                                    />
                                  </TableCell>
                                )}
                                {this.state.headCells[6].show && (
                                  <TableCell
                                    padding="checkbox"
                                    className="table-content-cell"
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={
                                            "deleteAction-" +
                                            row.masterDataRoleActionMappingResultID
                                          }
                                          component="th"
                                          scope="row"
                                          color="primary"
                                          checked={row.deleteAction}
                                          onChange={this.handleChange}
                                          value={row.deleteAction}
                                        />
                                      }
                                    />
                                  </TableCell>
                                )}
                                {this.state.headCells[7].show && (
                                  <TableCell
                                    padding="checkbox"
                                    className="table-content-cell"
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={
                                            "advanceSearchAction-" +
                                            row.masterDataRoleActionMappingResultID
                                          }
                                          component="th"
                                          scope="row"
                                          color="primary"
                                          checked={row.advanceSearchAction}
                                          onChange={this.handleChange}
                                          value={row.advanceSearchAction}
                                        />
                                      }
                                    />
                                  </TableCell>
                                )}
                                {this.state.headCells[8].show && (
                                  <TableCell
                                    padding="checkbox"
                                    className="table-content-cell"
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={
                                            "exportAction-" +
                                            row.masterDataRoleActionMappingResultID
                                          }
                                          component="th"
                                          scope="row"
                                          color="primary"
                                          checked={row.exportAction}
                                          onChange={this.handleChange}
                                          value={row.exportAction}
                                        />
                                      }
                                    />
                                  </TableCell>
                                )}
                                {this.state.headCells[9].show && (
                                  <TableCell
                                    padding="checkbox"
                                    className="table-content-cell"
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={
                                            "approveAction-" +
                                            row.masterDataRoleActionMappingResultID
                                          }
                                          component="th"
                                          scope="row"
                                          color="primary"
                                          checked={row.approveAction}
                                          onChange={this.handleChange}
                                          value={row.approveAction}
                                        />
                                      }
                                    />
                                  </TableCell>
                                )}
                                {this.state.headCells[10].show && (
                                  <TableCell
                                    padding="checkbox"
                                    className="table-content-cell"
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={
                                            "reportAction-" +
                                            row.masterDataRoleActionMappingResultID
                                          }
                                          component="th"
                                          scope="row"
                                          color="primary"
                                          checked={row.reportAction}
                                          onChange={this.handleChange}
                                          value={row.reportAction}
                                        />
                                      }
                                    />
                                  </TableCell>
                                )}
                                {this.state.headCells[11].show && (
                                  <TableCell
                                    padding="checkbox"
                                    className="table-content-cell"
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={
                                            "printAction-" +
                                            row.masterDataRoleActionMappingResultID
                                          }
                                          component="th"
                                          scope="row"
                                          color="primary"
                                          checked={row.printAction}
                                          onChange={this.handleChange}
                                          value={row.printAction}
                                        />
                                      }
                                    />
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={16}
                            style={{ textAlign: "center" }}
                          >
                            No result found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[100, 200, 300]}
                  component="div"
                  count={role.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/roleAction")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        onClick={this.handleSubmit}
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(addStyles)(EditRoleAction));
