import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withRouter } from "react-router-dom";
import {
  getProcessType,
  updateProcessTypeID,
} from "../../masters/masterActions";
import Validator from "../../../shared/validator";
// Include new StateContext
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";
import { addStyles } from "../../../shared/styles";
const validateNotRequired100 = new Validator("maxlength100AllAllow");
const validateCode = new Validator("maxlengthWithNumber4");

class EditProcessType extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      processType: {
        processTypeName: "",
        processTypeCode: "",
        isActive: false,
        modifiedBy: 1,
      },
      errors: {},
      active: false,
      processTypeNameValid: true,
      processTypeCodeValid: true,
      descriptionValid: true,
      orderNoValid: true,
      statusCk: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let processTypeId = this.props.match.params.processTypeId;

    getProcessType(processTypeId).then((r) => {
      dispatch({
        type: "getProcessType",
        newStore: r,
      });

      this.setState({ processType: r, statusCk: r.isActive, });
      if (r.isActive) this.setState({ isActive: r.isActive });
    });
  }

  handleChange = (event) => {
    const { processType } = this.state;
    if (event.target.name === "isActive") {
      processType[event.target.name] = event.target.checked;
    } else {
      processType[event.target.name] = event.target.value;
    }
    //processType[event.target.name] = event.target.value;
    this.setState({ processType });

    this.validateField(event.target.name, event.target.value);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let processTypeNameValid = this.state.processTypeNameValid;
    let processTypeCodeValid = this.state.processTypeCodeValid;
    switch (fieldName) {
      case "processTypeName":
        if (value !== "") {
          processTypeNameValid = validateNotRequired100(value);
          fieldValidationErrors.processTypeName = processTypeNameValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          processTypeNameValid = false;
          fieldValidationErrors.processTypeName = "";
        }
        break;
      case "processTypeCode":
        if (value !== "") {
          processTypeCodeValid = validateCode(value);
          fieldValidationErrors.processTypeCode = processTypeCodeValid
            ? ""
            : resourceValidation.onlyAlphanumeric;
        } else {
          processTypeCodeValid = false;
          fieldValidationErrors.processTypeCode = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        processTypeNameValid: processTypeNameValid,
        processTypeCodeValid: processTypeCodeValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.processTypeNameValid && this.state.processTypeCodeValid,
    });
    return this.state.formValid;
  }

  handleSubmit(e) {
    e.preventDefault();
    let processTypeId = this.props.match.params.processTypeId;
    const { processType } = this.state;
    processType.modifiedBy = localStorage.loggedInId;
    updateProcessTypeID(processTypeId, processType).then((r) => {
      this.props.history.push("/ProcessType");
      //toastr.success("ProcessType Updated Successfully");
    });
  }

  render() {
    let { classes } = this.props;
    let { processType, active } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Process Type</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    label="Process Type Code"
                    name="processTypeCode"
                    inputProps={{
                      maxLength: 3,
                    }}
                    disabled
                    variant="filled"
                    margin="normal"
                    className={classes.textField}
                    value={processType.processTypeCode}
                    onChange={this.handleChange}
                    error={this.state.errors.processTypeCode}
                    helperText={
                      this.state.errors.processTypeCode
                        ? this.state.errors.processTypeCode
                        : ""
                    }
                  />
                  <TextField
                    required
                    label="Process Type Name"
                    name="processTypeName"
                    inputProps={{
                      maxLength: 50,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={processType.processTypeName}
                    onChange={this.handleChange}
                    error={this.state.errors.processTypeName}
                    helperText={
                      this.state.errors.processTypeName
                        ? this.state.errors.processTypeName
                        : ""
                    }
                  />
                  {/* {active ? (
                    <React.Fragment />
                  ) : ( */}

                  {!this.state.statusCk && <FormControlLabel
                    name="isActive"
                    className={classes.checkBox}
                    control={
                      <Checkbox
                        color="primary"
                        onChange={this.handleChange}
                        value={processType.isActive}
                        checked={processType.isActive}
                      />
                    }
                    label="Active"
                  />}
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/processType")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        onClick={this.handleSubmit}
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          onClick={this.handleSubmit}
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditProcessType));
