import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { resourceValidation } from "../../../shared/resource";
import { StateContext } from "../../../shared/globalState";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { postRole, roleCheck } from "../../userAccess/userAccessAction";
import { addStyles } from "../../../shared/styles";

const validateNotRequired100 = new Validator("maxlength100AllAllow");
const validateNotRequired500 = new Validator("maxlength100AllAllow");
const onlyNumbers = new Validator("onlyNumbers");

class AddRole extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      valChange: 0,

      role: {
        roleName: "",
        description: "",
        orderNo: "",
        isReport: false,
      },
      errors: {},
      roleNameValid: false,
      descriptionValid: false,
      orderNoValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.handleSettingHeader("Role");
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.role["roleName"] !== "") {
      if (this.state.valChange !== prevState.valChange)
        roleCheck(this.state.role["roleName"]).then((r) => {
          if (r === true) {
            let obj = { ...this.state.errors, roleName: "Role already exists" };
            this.setState({ errors: obj, roleNameValid: false });
          }
        });
    }
  }
  handleChange2 = (event) => {
    let count = this.state.valChange;
    const { role } = this.state;
    count = count + 1;
    role[event.target.name] = event.target.value;

    this.setState({ role, valChange: count });

    this.validateField(event.target.name, event.target.value);
  };
  handleChange = (event) => {
    const { role } = this.state;
    if (event.target.name === "isReport") {
      role[event.target.name] = event.target.checked;
    } else {
      role[event.target.name] = event.target.value;
    }
    this.setState({ role });

    this.validateField(event.target.name, event.target.value);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let roleNameValid = this.state.roleNameValid;
    let descriptionValid = this.state.descriptionValid;
    let orderNoValid = this.state.orderNoValid;
    switch (fieldName) {
      case "roleName":
        if (value !== "") {
          roleNameValid = validateNotRequired100(value);
          fieldValidationErrors.roleName = roleNameValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          roleNameValid = false;
          fieldValidationErrors.roleName = "";
        }
        break;
      case "description":
        if (value !== "") {
          descriptionValid = validateNotRequired500(value);
          fieldValidationErrors.description = descriptionValid
            ? ""
            : resourceValidation.ValidDescription;
        } else {
          descriptionValid = false;
          fieldValidationErrors.description = "";
        }
        break;
      case "orderNo":
        if (value !== "" && value !== "null") {
          orderNoValid = onlyNumbers(value);
          fieldValidationErrors.orderNo = orderNoValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          orderNoValid = false;
          fieldValidationErrors.orderNo = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        roleNameValid: roleNameValid,
        descriptionValid: descriptionValid,
        orderNoValid: orderNoValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.roleNameValid && this.state.descriptionValid,
      // this.state.orderNoValid,
    });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      roleName: "",
      description: "",
      orderNo: "",
      isReport: false,
      errors: {},
    });
  };
  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      const { role } = this.state;

      let roleName = role.roleName;
      let description = role.description;
      //let orderNo = role.orderNo;
      //let isReport = role.isReport;
      let createdBy = localStorage.loggedInId;

      let reqBody = { roleName, description, createdBy };
      postRole(reqBody).then((r) => {
        this.props.history.push("/Role");
        //toastr.success("Role Created Successfully");
      });
      // pgsService
      //   .post("/Roles", reqBody)
      //   .then(r => {
      //     this.handleClose();
      //     toastr.success("Role Created Successfully");
      //     this.props.history.push("/Role");
      //     list.refreshData("getAllRole");

      //   })
      //   .catch(e => {
      //     this.handleClose();
      //     if (e.response.data.errors[0].message != undefined) {
      //       toastr.error(e.response.data.errors[0].message);
      //     }
      //     else { toastr.error(e.response.data.errors[0]); }
      //   });
    }
  };
  render() {
    let { classes } = this.props;
    let { role } = this.state;

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Role</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    label="Role Name"
                    name="roleName"
                    inputProps={{
                      maxLength: 50,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={role.roleName}
                    onChange={this.handleChange2}
                    error={this.state.errors.roleName}
                    helperText={
                      this.state.errors.roleName
                        ? this.state.errors.roleName
                        : ""
                    }
                  />
                  <TextField
                    required
                    multiline={false}
                    rows={1}
                    rowsMax={4}
                    inputProps={{
                      maxLength: 100,
                    }}
                    label="Description"
                    name="description"
                    margin="normal"
                    className={classes.textField}
                    value={role.description}
                    onChange={this.handleChange}
                    error={this.state.errors.description}
                    helperText={
                      this.state.errors.description
                        ? this.state.errors.description
                        : ""
                    }
                  />
                </div>
                <div>
                  {/* <TextField
                    required
                    label="Order Number"
                    name="orderNo"
                    inputProps={{
                      maxLength: 3,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={role.orderNo}
                    onChange={this.handleChange}
                    error={this.state.errors.orderNo}
                    helperText={
                      this.state.errors.orderNo ? this.state.errors.orderNo : ""
                    }
                    onKeyPress={this._onNumberKeyPress}
                  />
                  <FormControlLabel
                    name="isReport"
                    className={classes.checkBox}
                    margin="normal"
                    control={
                      <Checkbox
                        color="primary"
                        checked={role.isReport}
                        onChange={this.handleChange}
                        value={role.isReport}
                      />
                    }
                    label="Report"
                  /> */}
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/role")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddRole));
