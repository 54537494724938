export const resourceValidation = {
  max1char: "Please enter one character",
  max2char: "Please enter minimum two characters",
  max4char: "Please enter atleast 4 characters",
  max10Char: "Please enter 10 characters",
  max20Char: "Please enter 20 characters",
  max50Char: "Please enter 50 characters",
  max100char: "Please enter 100 characters",
  max1000char: "Please enter 1000 characters",
  alphaNumaric: "Please enter Alpha numeric value only !",
  validPhoneNo: "Please enter valid phone no",
  validEmailId: "Please enter valid email",

  uniquePPINo: "Please enter 6 numeric value",
  uniqueBillinAccNo: "Please enter unique billing account no",
  validYear: "Please enter Only Valid Year",

  onlyAlphabet: "Please enter alphabet characters only",
  onlyAlphanumeric: "Please enter alphanumeric characters",

  onlyNumbers: "Please enter numbers only",
  onlyDecimal: "Please enter numbers only",

  dhlRate: "Please enter valid DHL Rate",
  dhlWeight: "Please enter valid DHL Weight",

  cptofficeType: "Please enter valid office type",
  cptobjectCode: "Please enter valid object code",
  cptsubCode: "Please enter valid sub code",
  cptsapglAccount: "Please enter valid sa pgl account",

  lettershopcode:
    "Enter LetterShop Code starts with alphabet and remaining alphanumerics, without space or special characters",
  unitrate: "UnitRate can't have more than 2 decimal places",

  letterShopName: " Please enter letter shop name",
  chargeType: " Please enter charge type",
  countryName: " Please enter country name",
  customerName: " Please enter customer name",
  Address1: " Please enter address",
  Distributorname: "Please enter a valid distributor name",
  ValidName: " Please enter valid Name",
  ValidDescription: " Please enter valid Description",
  validRate: "Please enter valid Rate",
  validRemarks: "Please enter valid Remarks",
  validUOM: "Please enter valid Unit Of Measurement",
  validAddress: " Please enter valid Address",
  validZipCode: " Please enter valid Zip Code",
  validCategory: " Please enter valid Category",
  validWorkOrder: " Please enter valid Work Order Number",
  validContact:
    " Tel of contact person format is invalid! sample tel no: +65-9898096200",
  validTitle: " Please enter valid Title",
  validJobGroup: " Please enter valid Job Group Number",
  validCollectionNoteNo: " Please enter valid Collection Note Number",
  validDeliveryNoteNo: " Please enter valid Delivery Note Number",

  Stockname: "Please enter stock name",
  decimalpointby2: "Please enter only 2 decimal places",
  stockoutid: "Please select stock",
  stockoutdate: "Please select stockout date",
  Reason: "Please enter Reason",
  itemweight: "Please enter a valid item weight",
  price: "Please enter a valid item price",
  billingACNo:
    "Billing Account No must be always 8 character with Alphabet at the end. Eg. 0058930F",

  insertNumber: "Please enter a unique insert number",
  emloNumber: "Please enter a unique EMLO number",
  ebloNumber: "Please enter a unique EBLO number",
};
