import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { resourceValidation } from "../../../shared/resource";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import {
  postproductionLineCode,
  getProductionLineList,
} from "../masterActions";
import { FormControlLabel } from "@material-ui/core";
import { CustomCheckbox } from "../../../shared/tableCommonFunctions";
import { addStyles } from "../../../shared/styles";

const validateMaxlength2 = new Validator("maxlength2");

const maxlengthValidator50 = new Validator("maxlength50");
const validateMaxlength100 = new Validator("maxlength100");
const validateNotRequired2 = new Validator("notRequired2");
const validateNotRequired100 = new Validator("notRequired100");
const validateNotRequired1000 = new Validator("notRequired1000");
const generalValidator = new Validator("general");

class AddProdLineCode extends React.Component {
  state = {
    productionCode: {
      productionLineCode: "",
      productionLineName: "",
      productionLineDescription: "",
      isInternal: false,
      isActive: true,
    },
    errors: {},
    productionLineCodeValid: false,
    productionLineNameValid: false,
    productionLineDescriptionValid: false,
    isInternalValid: false,
    isActiveValid: false,
  };

  componentDidMount() {
    this.props.handleSettingHeader("Production Line");
    this.props.setSelectedList("masterDataOpen", "productionLine");
  }

  handleChange = (event) => {
    const { productionCode } = this.state;
    productionCode[event.target.name] = event.target.value;

    if (event.target.name === "singpostUser") {
      productionCode[event.target.name] = event.target.checked;
      // productionCode.isPeriodical = "";
      productionCode.isInternal = "";
    } else {
      productionCode[event.target.name] = event.target.value;
    }

    this.setState({ productionCode });

    this.validateField(event.target.name, event.target.value);
  };

  checkValidInputs = () => {
    if (!this.state.productionCode.productionLineCode) return true;
    if (!this.state.productionCode.productionLineName) return true;
    if (!this.state.productionCode.productionLineDescription) return true;
    else return false;
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let productionLineCodeValid = this.state.productionLineCodeValid;
    let productionLineNameValid = this.state.productionLineNameValid;
    let productionLineDescriptionValid = this.state
      .productionLineDescriptionValid;
    let isInternalValid = this.state.isInternalValid;
    let isActiveValid = this.state.isActiveValid;
    switch (fieldName) {
      case "productionLineCode":
        fieldValidationErrors.productionLineCode = "";
        if (value !== "") {
          productionLineCodeValid = maxlengthValidator50(value);
          fieldValidationErrors.productionLineCode = productionLineCodeValid
            ? ""
            : resourceValidation.onlyAlphanumeric;
        }
        break;
      case "productionLineName":
        fieldValidationErrors.productionLineName = "";
        if (value !== "") {
          productionLineNameValid = generalValidator(value);
          fieldValidationErrors.productionLineName = productionLineNameValid
            ? ""
            : "Please enter valid production line name";
        }
        break;
      case "productionLineDescription":
        fieldValidationErrors.productionLineDescription = "";
        if (value !== "") {
          productionLineDescriptionValid = generalValidator(value);
          fieldValidationErrors.productionLineDescription = productionLineDescriptionValid
            ? ""
            : "Please enter valid production line description";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        productionLineCodeValid: productionLineCodeValid,
        productionLineNameValid: productionLineNameValid,
        productionLineDescriptionValid: productionLineDescriptionValid,
        isInternalValid: isInternalValid,
        isActiveValid: isActiveValid,
      },
      this.validateForm
    );
  }

  validateForm = () => {
    this.setState({
      formValid:
        this.state.productionLineCodeValid &&
        this.state.productionLineNameValid &&
        this.state.productionLineDescription &&
        this.state.isInternalValid &&
        this.state.isActiveValid,
    });
    return this.state.formValid;
  };

  handleClose = () => {
    // clean up state and errors
    this.setState({
      productionLineCode: "",
      productionLineName: "",
      productionLineDescription: "",
      isInternal: "",
      isActive: "",
      errors: {},
    });
  };

  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleSubmit = (event) => {
    const dispatch = this.context;

    const { productionCode } = this.state;

    let productionLineCode = productionCode.productionLineCode.toUpperCase();
    let productionLineName = productionCode.productionLineName;
    let productionLineDescription = productionCode.productionLineDescription;
    let isInternal = productionCode.isInternal;
    let isActive = productionCode.isActive;
    let createdBy = localStorage.loggedInId;
    let reqBody = {
      productionLineCode,
      productionLineName,
      productionLineDescription,
      isInternal,
      isActive,
      createdBy,
    };
    // const { productionCode } = this.state;

    // postproductionLineCode(reqBody, this.props.history);
    postproductionLineCode(reqBody, this.props.history)
      .then((r) => {
        getProductionLineList()
          .then((r) => {
            dispatch({
              type: "getProductionLineList",
              newStore: r.data,
            });
          })
          .catch((e) => { });
      })
      .catch((e) => { });
  };

  onInputChanged = (e, name, type) => {
    let newError = { ...this.state.errors };
    if (type === "maxlength2") {
      let result = validateMaxlength2(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else if (type === "maxlength100") {
      let result = validateMaxlength100(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else if (type === "notRequired2") {
      let result = validateNotRequired2(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else if (type === "notRequired100") {
      let result = validateNotRequired100(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else {
      let result = validateNotRequired1000(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    }
    this.setState({ [name]: e.target.value });
  };

  render() {
    let { classes } = this.props;
    let { productionCode } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Production Line</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    label="Production Line Code"
                    name="productionLineCode"
                    inputProps={{
                      maxLength: 5,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={productionCode.productionLineCode.toUpperCase()}
                    onChange={this.handleChange}
                    error={this.state.errors.productionLineCode}
                    helperText={
                      this.state.errors.productionLineCode
                        ? this.state.errors.productionLineCode
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    required
                    inputProps={{
                      maxLength: 50,
                    }}
                    label="Production Line Name"
                    name="productionLineName"
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={productionCode.productionLineName}
                    onChange={this.handleChange}
                    error={this.state.errors.productionLineName}
                    helperText={
                      this.state.errors.productionLineName
                        ? this.state.errors.productionLineName
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    inputProps={{
                      maxLength: 50,
                    }}
                    rows={1}
                    rowsMax={4}
                    multiline={true}
                    label="Production Line Description"
                    name="productionLineDescription"
                    margin="normal"
                    autoComplete="off"
                    required
                    className={classes.textField}
                    value={productionCode.productionLineDescription}
                    onChange={this.handleChange}
                    error={this.state.errors.productionLineDescription}
                    helperText={
                      this.state.errors.productionLineDescription
                        ? this.state.errors.productionLineDescription
                        : ""
                    }
                  />
                </div>
                <div>
                  <FormControlLabel
                    className={classes.checkBox}
                    control={
                      <CustomCheckbox
                        value={productionCode.isInternal}
                        onChange={(e) =>
                          this.onInputChanged(e, "isInternal", "notRequired2")
                        }
                        name="isInternal"
                      />
                    }
                    label="Internal"
                  />
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/productionLine")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {this.checkValidInputs() ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddProdLineCode));
