import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import moment from "moment";
import {
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import { ExportCSV } from "../../../shared/ExportCSV";
import {
  stableSort,
  getSorting,
  DialogActions,
} from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import Validator from "../../../shared/validator";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import types from "../userAccessTypes";
import { StateContext } from "../../../shared/globalState";
import { getRoleActionList, deleteRoleAction } from "../userAccessAction";
import LinearProgress from "@material-ui/core/LinearProgress";

const columns = [
  {
    name: "Active",
    id: 2,
    show: false,
  },
  {
    numeric: false,
    textAlign: "right",
    name: "Modified Date",
    id: 3,
    show: false,
  },
  {
    name: "Modified By",
    id: 4,
  },
  {
    numeric: false,
    textAlign: "right",
    name: "Created Date",
    id: 5,
  },
  {
    name: "Created By",
    id: 6,
  },
];
const validateNotRequired100 = new Validator("notRequired100");

function createData(
  roleMappingID,
  roleID,
  roleName,
  isActive,
  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate
) {
  return {
    roleMappingID,
    roleID,
    roleName,
    isActive,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
  };
}
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all Role Action" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    roleName: "",
    subMenuName: "",
  });
  const [formValid, setIsformValid] = useState(false);

  const [roleActionSearchValue, setValues] = useState({
    roleName: "",
    subMenuName: "",
  });

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);

  const classes = useToolbarStyles();
  const {
    numSelected,
    deletePopupOpen,
    handleDeletePopupClose,
    handleDeleteRoleAction,
    searchPopupOpen,
    selectedRoleAction,
    selected,
    handleSearchPopupClose,
    handleSearchRoleAction,
    searchClicked,
    hendleVewColums,
    exportDataValues,
  } = props;
  const selectedRoleActionID = selected && selected.length > 0 && selected[0];

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteRoleAction = () => {
    handleDeleteRoleAction();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = roleActionSearchValue;
    searchValues.roleName = "";
    searchValues.subMenuName = "";
    // searchValues.description = '';
    // searchValues.isReport = false;
    searchValues = roleActionSearchValue;
  };

  const searchPopup = () => {
    handleSearchRoleAction(roleActionSearchValue);
    clearSearchValues();
  };

  const handleSearchInput = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;
    // if(event.target.name==="isReport"){
    //     setValues(roleActionSearchValue => ({ ...roleActionSearchValue, [event.target.name]: event.target.checked }));
    // }else{
    setValues((roleActionSearchValue) => ({
      ...roleActionSearchValue,
      [event.target.name]: event.target.value,
    }));
    //}
    switch (name) {
      case "roleName":
        let roleNameValid = validateNotRequired100(value);
        setIsformValid(roleNameValid);
        setErrors((errors) => ({
          ...errors,
          [event.target.name]: roleNameValid ? "" : " Please enter valid Name",
        }));
        break;
      case "subMenuName":
        let subMenuNameValid = validateNotRequired100(value);
        setIsformValid(subMenuNameValid);
        setErrors((errors) => ({
          ...errors,
          [event.target.name]: subMenuNameValid
            ? ""
            : " Please enter valid Name",
        }));
        break;
      // case 'description':
      //   let descriptionValid = validateNotRequired100(value);
      //   setIsformValid(descriptionValid);
      //   setErrors(errors => ({ ...errors, [event.target.name]: descriptionValid ? '' : ' Please enter only Alphabets' }));
      //   break;
      // case 'isReport':
      //   // let SubMenuDescriptionValid = validateNotRequired100(value);
      //   // setIsformValid(SubMenuDescriptionValid);
      //   // setErrors(errors => ({ ...errors, [event.target.name]: SubMenuDescriptionValid ? '' : ' Please enter only Alphabets' }));
      //   break;
      default:
        break;
    }

    // if(formValid){
    //   setFormvalid();
    // }
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };

  // const setFormvalid = () =>{
  //   if (
  //     subMenuSearchValue.subMenuOfficeType ||
  //     subMenuSearchValue.objectCode ||
  //     subMenuSearchValue.subCode ||
  //     subMenuSearchValue.sapglAccount ||
  //     (! subMenuSearchValue.subMenuOfficeType && !subMenuSearchValue.objectCode && !subMenuSearchValue.subCode && !subMenuSearchValue.sapglAccount)
  //   )
  //     return true;
  //   else return false;
  // }

  // const validateField = (fieldName, value) => {
  //   let errors = {};
  //   switch (fieldName) {
  //     case 'subMenuOfficeType':
  //       let officeTypevalid = maxlengthValidator50(value);
  //       errors.subMenuOfficeType = officeTypevalid ? '' : ' Please enter valid office type';

  //       break;
  //     case 'objectCode':
  //         let objectCodeValid  = maxlengthValidator10WithNum(value);
  //         errors.objectCode = objectCodeValid ? '' : ' Please enter valid office type';
  //       break;
  //     case 'subCode':
  //      let subCodeValid = maxlengthValidator10WithNum(value);
  //      errors.subCode = subCodeValid ? '' : ' Please enter valid office type';
  //       break;
  //     case 'sapglAccount':
  //       let sapglAccountValid = maxlengthValidator20WithNum(value);
  //       errors.sapglAccount = sapglAccountValid ? '' : ' Please enter valid office type';
  //       break;
  //     default:
  //       break;
  //   }
  //   return errors;
  // };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                <Tooltip title="View">
                  <Link to={`/view_roleAction/${selectedRoleActionID}`}>
                    <IconButton aria-label="view" className={classes.iconHover}>
                      <ViewIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
                <Tooltip title="Edit">
                  <Link to={`/edit_roleAction/${selectedRoleActionID}`}>
                    <IconButton aria-label="edit">
                      <EditIcon className={classes.iconHover} />
                    </IconButton>
                  </Link>

                  {/* this.props.history.push(/home:${this.state.userID}) onClick={e => props.history.push('/edit_subMenu/${selectedSubMenuId}')} */}
                </Tooltip>
              </div>
            )}
            {/* <Tooltip title="Delete">
              <IconButton aria-label="delete" onClick={handleClickOpen}>
                <DeleteIcon className={classes.iconHover} />
              </IconButton>
            </Tooltip> */}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete Sub Menu
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selectedRoleAction} ?
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteRoleAction}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {searchClicked ? (
              <Tooltip title="Clear search result">
                <IconButton
                  aria-label="clear"
                  className={classes.iconHover}
                  onClick={props.clearSearch}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <form onSubmit={searchPopup}>
                {/* <Tooltip title="Search">
                  <IconButton
                    aria-label="Search"
                    className={classes.iconHover}
                    onClick={handleSearchOpen}
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip> */}
                <Dialog
                  disableBackdropClick
                  disableEscapeKeyDown
                  onClose={handleSearchPopupClose}
                  aria-labelledby="customized-dialog-title"
                  open={searchPopupOpen}
                  maxWidth="md"
                >
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleSearchPopupClose}
                  >
                    Advanced Search
                  </DialogTitle>
                  <DialogContent dividers>
                    <React.Fragment>
                      <div>
                        <TextField
                          id="standard-required"
                          label="Role Name"
                          name="roleName"
                          className={classes.textField}
                          margin="normal"
                          value={roleActionSearchValue.roleName}
                          inputProps={{
                            maxLength: 50,
                          }}
                          onChange={handleSearchInput}
                          error={errors.roleName}
                          helperText={errors.roleName ? errors.roleName : ""}
                        />
                        <TextField
                          id="standard-required"
                          label="Sub Menu Name"
                          name="subMenuName"
                          className={classes.textField}
                          margin="normal"
                          value={roleActionSearchValue.subMenuName}
                          inputProps={{
                            maxLength: 50,
                          }}
                          onChange={handleSearchInput}
                          error={errors.subMenuName}
                          helperText={
                            errors.subMenuName ? errors.subMenuName : ""
                          }
                        />
                      </div>
                      {/* <div>
                            <TextField
                              label="Description"
                              name="description"
                              inputProps={{
                                maxLength: 10
                              }}
                              margin="normal"
                              className={classes.textField}
                              value={roleActionSearchValue.description}
                              onChange={handleSearchInput}
                              error={errors.description}
                              helperText={
                                errors.description
                                  ? errors.description
                                  : ""
                              }
                            />
                            <FormControlLabel
                                name="isReport"
                                style={{ marginTop: "25px", marginLeft: "7px" }}
                                control={
                                    <CustomCheckbox
                                    className={classes.Checkbox}
                                    checked={roleActionSearchValue.isReport}
                                    onChange={handleSearchInput}
                                    value={roleActionSearchValue.isReport}
                                    />
                                }
                                label="Is Report"
                            />
                          </div> */}
                    </React.Fragment>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={handleSearchClose}
                    >
                      CANCEL
                    </Button>
                    {!formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={searchPopup}
                      >
                        SEARCH
                      </Button>
                    ) : (
                      <Button
                        onClick={searchPopup}
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SEARCH
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
              </form>
            )}
            <Tooltip title="Add">
              <IconButton
                aria-label="add"
                className={classes.iconHover}
                onClick={(e) => props.history.push("/add_roleAction")}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            <ExportCSV csvData={exportDataValues} fileName={"Role_Action"} />
            <Tooltip title="View Column">
              <IconButton
                aria-label="viewColumn"
                className={classes.iconHover}
                onClick={handleClickListItem}
              >
                <ViewColumnIcon />
              </IconButton>
            </Tooltip>
            <EnhancedShowColumn
              id="columns-menu"
              keepMounted
              open={open}
              onClose={handleShowColClose}
              column={column}
              hendleVewColums={hendleVewColums}
              headCells={props.headCells}
            />
          </div>
        )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

class RoleActionMater extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    roleActionList: [],
    refreshData: false,
    selectedRoleAction: "",
    selectedRoleActionId: 1,
    selectedRoleId: 1,
    loading: false,
    searchPopupOpen: false,
    roleActionSearchValues: {},
    searchClicked: false,
    selectedShowColumns: [],
    exportDataValues: [],
    headCells: [
      {
        id: 1,
        code: "roleName",
        numeric: false,
        disablePadding: true,
        label: "Role Name",
        show: true,
      },
      {
        id: 2,
        code: "isActive",
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true,
      },
      {
        id: 3,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 4,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 5,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 6,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };
  componentDidMount() {
    this.props.handleSettingHeader("Role Action");
    this.props.setSelectedList("adminSettingOpen", "roleAction");
    const dispatch = this.context[1];

    getRoleActionList(localStorage.token).then((r) => {
      dispatch({
        type: types.ROLEACTION_LIST,
        newStore: r.data,
      });
      this.getAllRoleActions(r.data);
      this.exportRoleActions(r.data);
    });
    this.setState({ loading: true });
  }
  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };
  exportRoleActions = (roleList) => {
    let list = roleList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          RoleName: d.roleName,
          Active: d.isActive,
          ModifiedDate: d.modifiedDate,
          ModifiedBy: d.modifiedBy,
          CreatedDate: d.createdDate,
          CreatedBy: d.createdBy,
        });
      });
    this.setState({ exportDataValues: rows });
  };
  getAllRoleActions = (roleActionList) => {
    let list = roleActionList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.roleMappingID,
            d.roleID,
            d.roleName,
            d.isActive,
            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate
          )
        );
      });
    this.setState({ roleActionList: rows, loading: false });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.roleActionList && store.roleActionList.length > 0)
      if (this.state.roleActionList.length !== store.roleActionList.length) {
        this.getAllRoleActions(store.roleActionList);
      }
    return true;
  }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { roleActionList } = this.state;
    if (event.target.checked) {
      const newSelecteds = roleActionList.map((n) => n.roleID);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, roleActionID, roleID, roleAction) {
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(roleActionID);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, roleActionID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.setState({
        selected: newSelected,
        selectedRoleAction: roleAction,
        selectedRoleActionId: roleActionID,
        selectedRoleId: roleID,
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedRoleAction: "",
        selectedRoleActionId: 0,
        selectedRoleId: 0,
      });
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchRoleAction = (handleSearchRoleAction) => {};

  handleDeleteRoleAction = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteRoleAction(modifiedBy, deletionIds).then((r) => {
      getRoleActionList().then((r) => {
        dispatch({
          type: types.ROLEACTION_LIST,
          newStore: r.data,
        });
        this.getAllRoleActions(r.data);
      });
      this.props.history.push("/RoleAction");
      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };

  clearSearch = () => {
    const list = this.context;
    setTimeout(() => {
      let roleActions = list.state.roleActionList;
      this.getAllRoleActions(roleActions);
    }, 1700);

    this.setState({ loading: true, searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      roleActionList,
      exportDataValues,
      headCells,
    } = this.state;
    const isSelected = (roleID) => selected.indexOf(roleID) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteRoleAction={this.handleDeleteRoleAction}
            selectedRoleAction={this.state.selectedRoleAction}
            selectedRoleActionId={this.state.selectedRoleActionId}
            selectedRoleId={this.state.selectedRoleId}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchRoleAction={this.handleSearchRoleAction}
            roleActionSearchValue={this.state.roleActionSearchValues}
            searchClicked={this.state.searchClicked}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            roleActionList={roleActionList}
            exportDataValues={exportDataValues}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 8em)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
                  : {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 330px)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={roleActionList.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {roleActionList.length > 0 ? (
                    stableSort(roleActionList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.roleID);
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              this.handleClick(
                                event,
                                row.roleID,
                                row.roleID,
                                row.roleActionName
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.roleActionID}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.roleName}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                // id={labelId}
                              >
                                {status}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                // id={labelId}
                              >
                                {row.modifiedDate &&
                                row.modifiedDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                // id={labelId}
                              >
                                {row.modifiedBy}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdDate &&
                                row.createdDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdBy}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      {this.state.loading ? (
                        <TableCell colSpan={15} style={{ textAlign: "center" }}>
                          <LinearProgress />
                        </TableCell>
                      ) : (
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                          No result found
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={roleActionList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(RoleActionMater);
