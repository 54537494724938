import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import clone from "clone";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { resourceValidation } from "../../../shared/resource";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FormGroup from "@material-ui/core/FormGroup";
import { StateContext } from "../../../shared/globalState";
import {
  stableSort,
  getSorting,
  DialogActions,
} from "../../../shared/tableCommonFunctions";
import Typography from "@material-ui/core/Typography";
import toastr from "toastr";
import { Link } from "react-router-dom";
import { ExportCSV } from "../../../shared/ExportCSV";
import Validator from "../../../shared/validator";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

import {
  SearchIcon,
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import {
  getWarehouseLocationList,
  deleteWarehouseLocation,
} from "../masterActions";
const Alphanumeric = new Validator("Alphanumeric");
const columns = [
  {
    name: "Warehouse Bay",
    id: 5,
    show: false,
  },
  {
    name: "Warehouse Bin",
    id: 6,
    show: false,
  },
  {
    name: "Warehouse Stack",
    id: 7,
    show: false,
  },
  {
    name: "Active",
    id: 11,
    show: false,
  },
  {
    name: "Modified Date",
    id: 12,
    show: false,
  },
  {
    name: "Modified By",
    id: 13,
  },
  {
    name: "Created Date",
    id: 14,
  },
  {
    name: "Created By",
    id: 15,
  },
];
const containerstyle = (theme) => ({
  container: {
    display: "inline-block",
    flexWrap: "wrap",
  },
});
const baseURL = process.env.REACT_APP_PGS_SERVER_MASTER_DATA_MANAGEMENT;
const pgsService = axios.create({
  baseURL,
});
function createData(
  code,
  suffix,
  id,
  floor,
  bay,
  bin,
  stack,
  section,
  weight,
  area,
  seqno,
  createdByName,
  createddate,
  modifiedByName,
  modifieddate,
  isActive
) {
  return {
    code,
    suffix,
    id,
    floor,
    bay,
    bin,
    stack,
    section,
    weight,
    area,
    seqno,
    createdByName,
    createddate,
    modifiedByName,
    modifieddate,
    isActive,
  };
}
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                // checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "Select all Locations" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  //classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    locationCode: "",
    warehouseSuffix: "",
    warehouseFloor: "",
    warehouseArea: "",
    warehouseBay: "",
  });
  const [formValid, setIsformValid] = useState(false);
  const [WLSearchValue, setValues] = useState({
    locationCode: "",
    warehouseSuffix: "",
    warehouseFloor: "",
    warehouseArea: "",
    warehouseBay: "",
  });
  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);

  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handleDeleteLocation,
    searchPopupOpen,
    //selectedLocation,
    handleViewColumns,
    selected,
    warehouselocationList,
    // selectedwarehouseID,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchWarehouse,
    searchClicked,
    exportDataValues,
    isActive,
  } = props;
  //const selectedwarehouseID = selected && selected.length> 0 && selected[0];
  const selectedwarehouseID = selected[0];
  const selectedLocation = warehouselocationList.find(
    (x) => x.id === selectedwarehouseID
  );
  let acnArr = props.roles.filter((u) => u.menuName === "Warehouse Location");
  let acnObj = acnArr[0];

  let expData = clone(warehouselocationList);
  expData.map((d) => {
    let Val = "No";
    if (d["isActive"]) {
      Val = "Yes";
    }
    d["Location Code"] = d["code"];
    d["Suffix"] = d["suffix"];
    d["Floor"] = d["floor"];
    d["Bay"] = d["bay"];
    d["Bin"] = d["bin"];
    d["Stack"] = d["stack"];
    d["Section"] = d["section"];
    d["Weight"] = d["weight"];
    d["Area"] = d["area"];
    d["Sequence No"] = d["seqno"];
    d["Active"] = Val;
    d["Created By"] = d["createdByName"];
    d["Created Date"] = moment(d["createddate"]).format("DD/MM/YYYY HH:mm:ss");
    d["Modified By"] = d["modifiedByName"];

    d["Modified Date"] = moment(d["modifieddate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    );

    delete d["code"];

    delete d["suffix"];

    delete d["floor"];

    delete d["bay"];

    delete d["bin"];

    delete d["stack"];

    delete d["section"];
    delete d["weight"];

    delete d["area"];

    delete d["seqno"];

    delete d["isActive"];

    delete d["createdByName"];

    delete d["createddate"];

    delete d["modifiedByName"];

    delete d["modifieddate"];
    delete d["id"];
  });
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteLocation = () => {
    handleDeleteLocation();
  };
  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };
  const clearSearchValues = () => {
    let searchValues = WLSearchValue;
    searchValues.locationCode = "";
    searchValues.warehouseSuffix = "";
    searchValues.warehouseFloor = "";
    searchValues.warehouseArea = "";
    searchValues.warehouseBay = "";
    searchValues = WLSearchValue;
  };
  const searchPopup = () => {
    handleSearchWarehouse(WLSearchValue);
    clearSearchValues();
  };
  const handleSearchInput = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;
    setValues((WLSearchValue) => ({
      ...WLSearchValue,
      [event.target.name]: event.target.value,
    }));

    switch (name) {
      case "locationCode":
        if (value) {
          let locationCodeValid = Alphanumeric(value);
          setIsformValid(locationCodeValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: locationCodeValid
              ? ""
              : resourceValidation.onlyAlphanumeric,
          }));
        } else {
          setErrors((errors) => ({ ...errors, [event.target.name]: "" }));
        }
        break;
      case "warehouseSuffix":
        if (value) {
          let warehouseSuffixValid = Alphanumeric(value);
          setIsformValid(warehouseSuffixValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: warehouseSuffixValid
              ? ""
              : resourceValidation.onlyAlphanumeric,
          }));
        } else {
          setErrors((errors) => ({ ...errors, [event.target.name]: "" }));
        }
        break;
      case "warehouseFloor":
        if (value) {
          let warehouseFloorValid = Alphanumeric(value);
          setIsformValid(warehouseFloorValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: warehouseFloorValid
              ? ""
              : resourceValidation.onlyAlphanumeric,
          }));
        } else {
          setErrors((errors) => ({ ...errors, [event.target.name]: "" }));
        }
        break;
      case "warehouseArea":
        if (value) {
          let warehouseAreaValid = Alphanumeric(value);
          setIsformValid(warehouseAreaValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: warehouseAreaValid
              ? ""
              : resourceValidation.onlyAlphanumeric,
          }));
        } else {
          setErrors((errors) => ({ ...errors, [event.target.name]: "" }));
        }
        break;
      case "warehouseBay":
        if (value) {
          let warehouseBayValid = Alphanumeric(value);
          setIsformValid(warehouseBayValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: warehouseBayValid
              ? ""
              : resourceValidation.onlyAlphanumeric,
          }));
        } else {
          setErrors((errors) => ({ ...errors, [event.target.name]: "" }));
        }
        break;
      default:
        break;
    }
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_warehouseLocation/${selectedwarehouseID}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <Link to={`/edit_warehouseLocation/${selectedwarehouseID}`}>
                      <IconButton
                        aria-label="edit"
                        className={classes.iconHover}
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={handleClickOpen}
                  disabled={!isActive}
                >
                  <DeleteIcon className={classes.iconHover} />
                </IconButton>
              </Tooltip>
            )}

            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete Warehouse Location
              </DialogTitle>
              <DialogContent dividers>
                {/* <div className={classes.deleteContainer}> */}

                <div>
                  Are you sure you want to delete {""}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1 ? " ?" : selectedLocation.code + " ?"}
                    {/* {selectedLocation.code} */}
                  </span>
                  {/* <h5>{this.props.user}</h5> */}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteLocation}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  // <AdvanceSearch onSearchClicked={props.onSearchClicked} />
                  <form onSubmit={searchPopup}>
                    {acnObj && acnObj.advanceSearchAction && (
                      <Tooltip title="Search">
                        <IconButton
                          aria-label="Search"
                          className={classes.iconHover}
                          onClick={handleSearchOpen}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      onClose={handleSearchPopupClose}
                      aria-labelledby="customized-dialog-title"
                      open={searchPopupOpen}
                      maxWidth="md"
                    >
                      <DialogTitle
                        id="customized-dialog-title"
                        onClose={handleSearchPopupClose}
                      >
                        Advanced Search
                  </DialogTitle>
                      <DialogContent dividers>
                        <React.Fragment>
                          <div className={containerstyle.container}>
                            <div>
                              <TextField
                                id="standard-required"
                                label="Location Code"
                                placeholder="Location Code"
                                name="locationCode"
                                className={classes.textField}
                                margin="normal"
                                style={{ marginRight: "1em" }}
                                value={WLSearchValue.locationCode}
                                error={errors.locationCode}
                                onChange={handleSearchInput}
                                helperText={
                                  errors.locationCode ? errors.locationCode : ""
                                }
                              />
                              <TextField
                                label="Warehouse Suffix"
                                id="standard-required"
                                placeholder="Warehouse Suffix"
                                className={classes.textField}
                                margin="normal"
                                value={WLSearchValue.warehouseSuffix}
                                name="warehouseSuffix"
                                error={errors.warehouseSuffix}
                                onChange={handleSearchInput}
                                helperText={
                                  errors.warehouseSuffix
                                    ? errors.warehouseSuffix
                                    : ""
                                }
                              />{" "}
                            </div>

                            <div>
                              <TextField
                                label="Warehouse Floor"
                                id="standard-required"
                                placeholder="Warehouse Floor"
                                className={classes.textField}
                                margin="normal"
                                style={{ marginRight: "1em" }}
                                name="warehouseFloor"
                                value={WLSearchValue.warehouseFloor}
                                error={errors.warehouseFloor}
                                onChange={handleSearchInput}
                                helperText={
                                  errors.warehouseFloor ? errors.warehouseFloor : ""
                                }
                              />
                              <TextField
                                label="Warehouse Area"
                                id="standard-required"
                                placeholder="Warehouse Area"
                                className={classes.textField}
                                name="warehouseArea"
                                margin="normal"
                                value={WLSearchValue.warehouseArea}
                                error={errors.warehouseArea}
                                onChange={handleSearchInput}
                                helperText={
                                  errors.warehouseArea ? errors.warehouseArea : ""
                                }
                              />
                            </div>
                            <TextField
                              label="Warehouse Bay"
                              id="standard-required"
                              placeholder="Warehouse Bay"
                              className={classes.textField}
                              name="warehouseBay"
                              margin="normal"
                              value={WLSearchValue.warehouseBay}
                              error={errors.warehouseBay}
                              onChange={handleSearchInput}
                              helperText={
                                errors.warehouseBay ? errors.warehouseBay : ""
                              }
                            />
                          </div>
                        </React.Fragment>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={handleSearchClose}
                        >
                          CANCEL
                    </Button>
                        {!WLSearchValue.locationCode &&
                          !WLSearchValue.warehouseSuffix &&
                          !WLSearchValue.warehouseFloor &&
                          !WLSearchValue.warehouseArea &&
                          !WLSearchValue.warehouseBay ? (
                            <Button
                              disabled
                              variant="contained"
                              color="primary"
                              style={{ boxShadow: "none" }}
                              onClick={searchPopup}
                            >
                              SEARCH
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ boxShadow: "none" }}
                              onClick={searchPopup}
                            >
                              SEARCH
                            </Button>
                          )}
                      </DialogActions>
                    </Dialog>
                  </form>
                )}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="add"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/add_warehouse")}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {acnObj && acnObj.exportAction && (
                <Tooltip>
                  <ExportCSV
                    csvData={expData}
                    fileName={"WarehouseLocation_Details"}
                  />
                </Tooltip>
              )}

              {/* <Tooltip title="Download">
            <IconButton aria-label="download" className={classes.iconHover}>
              <DownloadIcon />
            </IconButton>
          </Tooltip> */}
              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                handleViewColumns={handleViewColumns}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    handleViewColumns,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => handleViewColumns(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class WarehouseLocationMaster extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifieddate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    activeRows: 0,
    isActive: false,
    refreshData: false,
    selectedLocation: "",
    warehouselocationList: [],
    selectedwarehouseID: 1,
    loading: false,
    searchPopupOpen: false,
    searchClicked: false,
    selectedShowColumns: [],
    exportDataValues: [],
    headCells: [
      {
        code: "code",
        id: 1,
        numeric: false,
        disablePadding: true,
        label: "Location Code",
        show: true,
      },
      {
        code: "suffix",
        id: 2,
        numeric: false,
        disablePadding: false,
        label: "Suffix",
        show: true,
      },
      {
        code: "floor",
        id: 3,
        numeric: false,
        disablePadding: false,
        label: "Floor",
        show: true,
      },
      {
        code: "area",
        id: 4,
        numeric: false,
        disablePadding: false,
        label: "Area",
        show: true,
      },
      {
        code: "bay",
        id: 5,
        numeric: false,
        disablePadding: false,
        label: "Bay",
        show: true,
      },
      {
        code: "bin",
        id: 6,
        numeric: false,
        disablePadding: false,
        label: "Bin",
        show: true,
      },
      {
        code: "stack",
        id: 7,
        numeric: false,
        disablePadding: false,
        label: "Stack",
        show: true,
      },
      {
        code: "section",
        id: 8,
        numeric: false,
        disablePadding: false,
        label: "Section",
        show: true,
      },
      {
        code: "seqno",
        id: 9,
        numeric: false,
        disablePadding: false,
        label: "Sequence No",
        show: true,
      },
      {
        code: "holdingWeight",
        id: 10,
        numeric: false,
        disablePadding: false,
        label: "Weight (Kg)",
        show: true,
      },
      {
        code: "isActive",
        id: 15,
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true,
      },
      {
        code: "modifieddate",
        id: 14,
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        code: "modifiedByName",
        id: 13,
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        code: "createddate",
        id: 12,
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        code: "createdByName",
        id: 11,
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };
  handleViewColumns = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  sortStateList = (list) => {
    let z = list.sort(function (a, b) {
      let x = a.isActive && a.isActive;
      let y = b.isActive && b.isActive;
      let u = a.modifieddate && a.modifieddate;
      let v = b.modifieddate && b.modifieddate;
      if (x < y) {
        return 1;
      }
      if (u < v) {
        return -1;
      }
      if (x > y) {
        return -1;
      }
      if (u > v) {
        return 1;
      }
      return 0;
    });
    // console.log(z);
    this.setState({ rows: z });
  };

  componentDidMount() {
    this.props.handleSettingHeader("Warehouse Location");

    this.props.setSelectedList("masterDataOpen", "warehouseLocation");
    const dispatch = this.context[1];
    this.setState({ loading: true });

    getWarehouseLocationList(window.localStorage.token).then((r) => {
      dispatch({
        type: "getWarehouseLocationList",
        newStore: r.data,
      });
      this.getAllWarehouseLocations(r.data);
      this.exportWarehouseLocationsDatas(r.data);
    });
  }

  exportWarehouseLocationsDatas = (warehouselocationList) => {
    let list = warehouselocationList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          LocationCode: d.locationCode,
          WarehouseSuffix: d.warehouseSuffix,
          WarehouseFloor: d.warehouseFloor,
          WarehouseBay: d.warehouseBay,
          WarehouseBin: d.warehouseBin,
          WarehouseStack: d.warehouseStack,
          WarehouseSection: d.warehouseSection,
          HoldingWeight: d.holdingWeight,
          WarehouseArea: d.warehouseArea,
          WarehouseSequenceNo: d.warehouseSequenceNo,
          Active: d.isActive === true ? "Yes" : "No",
          CreatedBy: d.createdByName,
          CreatedDate: d.createdDate,
          ModifiedBy: d.modifiedByName,
          ModifiedDate: d.modifiedDate,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getAllWarehouseLocations = (warehouselocationList) => {
    let list = warehouselocationList;
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return newRows.push(
          createData(
            d.locationCode,
            d.warehouseSuffix,
            d.warehouseLocationID,
            d.warehouseFloor,
            d.warehouseBay,
            d.warehouseBin,
            d.warehouseStack,
            d.warehouseSection,
            d.holdingWeight,
            d.warehouseArea,
            d.warehouseSequenceNo,
            d.createdByName,
            d.createdDate,
            d.modifiedByName,
            d.modifiedDate,
            d.isActive
          )
        );
      });
    this.sortStateList(newRows);
    this.setState({
      warehouselocationList: newRows,
      selected: [],
      loading: false,
      activeRows: newRows.filter((x) => x.isActive).map((n) => n.id),
    });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.warehouselocationList && store.letterShopList.length > 0)
      if (
        this.state.warehouselocationList.length !==
        store.warehouselocationList.length
      ) {
        !this.state.searchClicked &&
          this.getAllWarehouseLocations(store.warehouselocationList);
      }
    return true;
  }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    //   var start = page * rowsPerPage;
    //  var end = page * rowsPerPage + rowsPerPage;
    let { warehouselocationList } = this.state;
    if (event.target.checked) {
      const newSelecteds = warehouselocationList
        .filter((x) => x.isActive === true)
        .map((n) => n.id);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, data) => {
    let id = data.id;
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedLocation: data,
        selectedwarehouseID: id,
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        // selectedLocation: {}
        selectedLocation: data,
        selectedwarehouseID: id,
      });
    }
    //this.setState({ selected: newSelected, selectedLocation: data });
  };

  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };
  handleSearchWarehouse = (handleSearchWarehouse) => {
    let WLSearchValue = handleSearchWarehouse;
    let locationCode = WLSearchValue.locationCode;
    let warehouseSuffix = WLSearchValue.warehouseSuffix;
    let warehouseFloor = WLSearchValue.warehouseFloor;
    let warehouseArea = WLSearchValue.warehouseArea;
    let warehouseBay = WLSearchValue.warehouseBay;

    let reqBody = {
      locationCode,
      warehouseSuffix,
      warehouseFloor,
      warehouseArea,
      warehouseBay,
    };
    pgsService
      .post(`/WarehouseLocationsAdvanceSerach`, reqBody, {
        headers: {
          Authorization: "Bearer " + window.localStorage.token,
        },
      })
      .then((r) => {
        this.setState({ searchPopupOpen: false, searchClicked: true, page: 0 });
        this.getAllWarehouseLocations(r.data);
      })
      .catch((e) => {
        if (e.response.data.errors[0].message !== undefined) {
          toastr.error(e.response.data.errors[0].message);
        } else {
          toastr.error(e.response.data.errors[0]);
        }
        this.setState({ deletePopupOpen: false });
      });
  };
  handleDeleteLocation = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedByName = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteWarehouseLocation(modifiedByName, deletionIds).then((r) => {
      getWarehouseLocationList().then((r) => {
        dispatch({
          type: "getwarehouseLocationList",
          newStore: r.data,
        });
        this.getAllWarehouseLocations(r.data);
      });
      // this.props.history.push("/letterShop");
      this.setState({ deletePopupOpen: false, selected: [] });
      // toastr.success("Lettershop Deleted Successfully");
    });
  };

  clearSearch = () => {
    const dispatch = this.context[1];
    this.setState({ loading: true });

    getWarehouseLocationList().then((r) => {
      dispatch({
        type: "getWarehouseLocationList",
        newStore: r.data,
      });
      this.getAllWarehouseLocations(r.data);
    });
    this.setState({ searchClicked: false });

    // this.setState({ warehouselocationList: initailData, searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      warehouselocationList,
      selectedLocation,
      headCells,
    } = this.state;
    const isSelected = (id) => selected.indexOf(id) !== -1;
    return (
      <div>
        {/* className={classes.root} */}
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedLocation={selectedLocation}
            onSearchClicked={this.onSearchClicked}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            selected={this.state.selected}
            selectedwarehouseID={this.state.selectedwarehouseID}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteLocation={this.handleDeleteLocation}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchWarehouse={this.handleSearchWarehouse}
            WLSearchValue={this.state.WLSearchValue}
            exportDataValues={this.state.exportDataValues}
            handleViewColumns={this.handleViewColumns}
            headCells={headCells}
            warehouselocationList={warehouselocationList}
            isActive={this.state.isActive}
            roles={this.props.roles}
          // selectedWarehouseLocation={selectedWarehouseLocation}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {warehouselocationList.length > 0 ? (
                    stableSort(
                      warehouselocationList,
                      getSorting(order, orderBy)
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        let { locationcode } = "";
                        locationcode =
                          row.suffix +
                          "_" +
                          row.floor +
                          "_" +
                          row.area +
                          "_" +
                          row.bay +
                          "_" +
                          row.bin +
                          "_" +
                          row.stack;
                        return (
                          //+row.bay+row.bin+row.stack
                          <TableRow
                            hover
                            onClick={(event) => this.handleClick(event, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              {/* <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                                name="all"
                              /> */}
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  // onChange={handleChange('checkedG')}
                                  // value="checkedG"
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {locationcode}
                                </Typography> */}
                                {row.code && row.code.length > 16 ? (
                                  <Tooltip title={row.code} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.code}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.code}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.suffix}
                                </Typography> */}
                                {row.suffix && row.suffix.length > 16 ? (
                                  <Tooltip title={row.suffix} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.suffix}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.suffix}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.floor} */}
                                {row.floor && row.floor.length > 16 ? (
                                  <Tooltip title={row.floor} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.floor}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.floor}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.rate}
                                </Typography>
                                {row.area} */}
                                {row.area && row.area.length > 16 ? (
                                  <Tooltip title={row.area} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.area}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.area}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.rate}
                                </Typography>
                                {row.bay} */}
                                {row.bay && row.bay.length > 16 ? (
                                  <Tooltip
                                    title={row.bay + " " + row.bay}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.bay}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.bay}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.rate}
                                </Typography>
                                {row.bin} */}
                                {row.bin && row.bin.length > 16 ? (
                                  <Tooltip title={row.bin} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.bin}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.bin}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.rate}
                                </Typography>
                                {row.stack} */}
                                {row.stack && row.stack.length > 16 ? (
                                  <Tooltip title={row.stack} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.stack}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.stack}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.rate}
                                </Typography>
                                {row.section} */}
                                {row.section && row.section.length > 16 ? (
                                  <Tooltip title={row.section} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.section}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.section}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.seqno}
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.rate}
                                </Typography> */}
                                {row.seqno && row.seqno.length > 16 ? (
                                  <Tooltip title={row.seqno} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.seqno}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.seqno}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.weight}
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.rate}
                                </Typography> */}
                                {row.weight && row.weight.length > 16 ? (
                                  <Tooltip title={row.weight} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.weight}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.weight}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {status}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.modifieddate &&
                                  row.modifieddate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifieddate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifieddate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifieddate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                                {/* moment({row.modifiedDate}).format("MM/DD/YYYY") */}
                                {/* moment().format('dddd');    */}
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.modifiedByName} */}
                                {row.modifiedByName &&
                                  row.modifiedByName.length > 16 ? (
                                    <Tooltip
                                      title={row.modifiedByName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.createddate &&
                                  row.createddate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createddate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createddate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createddate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.createdByName} */}
                                {row.createdByName &&
                                  row.createdByName.length > 16 ? (
                                    <Tooltip
                                      title={row.createdByName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.createdByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={14} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={warehouselocationList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
//export default withStyles(styles)(WarehouseLocationMaster);
export default withRouter(WarehouseLocationMaster);
