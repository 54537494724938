// import React, { Component } from "react";

// class PublisherStockMovement extends Component {
//   state = {};
//   componentDidMount() {
//     this.props.handleSettingHeader("Back Label Stock Movement");
//     this.props.setSelectedList("publisherReportsOpen", "PublisherStockMovement");
//   }
//   render() {
//     // alert("collectionnote");
//     //const url = "http://172.22.43.196/report/reportviewer?Reportname=ReorderLevel";
//     return (
//       <div>
//       <iframe
//         style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
//         src={`${window.localStorage.ReportURLByID}` +"PublisherStockMovement"}
//         />
//       </div>
//     );
//   }
// }

// // "http://10.10.11.131:81/ReportServer/Pages/ReportViewer.aspx?%2fPGS_Report_QA%2fReorderLevel&rs:Command=Render&rs:Embed=true"

// export default PublisherStockMovement;


import React, { Component } from "react";
import { Autocomplete } from "@material-ui/lab";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  getStockInByCustIdList,
  getCustomerFromItemMaster,
} from "../../generic/genericActions";

import {
  getAllAcronymList,
  getAllVolumeList,
  getAllIssueList,
  getAllSuppList
} from "../../publisher/action"
import { getStockTypeDD } from "../../publisherBackLabel/stockaction"
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  comboBox: {
    //marginTop: theme.spacing(1),
    //marginRight: theme.spacing(1),
    margin: "normal",
    width: "100%",
    fontSize: "11px !important",
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
    display: "flex",

    flex: 1,
    alignItems: "left",
    overflow: "hidden",
  },
  textField1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
    fontSize: "11px !important",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
});
class StockBalance extends Component {
  state = {
    showPopup: false,
    fromDate: null,
    toDate: null,
    stockIn: {
      stockID: "",
      stock: "",
      customerID: "",
      customer: "",
    },
    accList: [],
    accObj: {},
    accId: "",
    volList: [],
    volObj: {},
    volId: "",
    issueList: [],
    issueObj: {},
    issueId: "",
    suppList: [],
    suppObj: {},
    suppId: "",
    stockTypeList: [],
    stockTypeObj: {},
    stockTypeId: "",
  };
  componentDidMount() {
    this.props.handleSettingHeader("Back Label Stock Movement");
    this.props.setSelectedList("publisherReportsOpen", "PublisherStockMovement");
    // getCustomerFromItemMaster().then((r) => {
    //   this.getAllCustomers(r.data);
    // });
    getStockTypeDD().then((r) => {
      this.getStockyStypeDD(r.data);
    });
    getAllAcronymList().then(r => {
      // this.setState({ accObj: { id: "all", text: "All", value: "All" } })
      if (r && r.data)
        this.setSortedList("accList", r.data)
      else
        this.setSortedList("accList", [])
    })
    getAllVolumeList("all",).then(r => {
      if (r && r.data)
        this.setSortedList("volList", r.data)
      else
        this.setSortedList("volList", [])
    })
    getAllIssueList("all", "all").then(r => {
      // this.setState({ issueObj: { id: "all", text: "All", value: "All" } })
      if (r && r.data)
        this.setSortedList("issueList", r.data)
      else
        this.setSortedList("issueList", [])
    })
    getAllSuppList("all", "all").then(r => {
      // this.setState({ suppObj: { id: "all", text: "All", value: "All" } })
      if (r && r.data)
        this.setSortedList("suppList", r.data)
      else
        this.setSortedList("suppList", [])
    })

    // this.setSortedList("accList", [{ id: "all", text: "Bll", value: "Bll" }, { id: "all", text: "Tll", value: "Tll" }, { id: "all", text: "Dll", value: "Dll" }])

  }
  getStockyStypeDD = async (data) => {
    await this.setState({
      stockTypeList:
        data &&
        data.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    }, () => {
      this.setState({ stockTypeObj: this.state.stockTypeList[0] })
    });
  };
  getAllCustomers = (list) => {
    let customerList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        customerList.findIndex((p) => p.id === c.id) > -1
          ? customerList
          : customerList.push(c)
      );

    this.setState({
      customerList:
        customerList &&
        customerList.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };
  setSortedList = (name, list) => {
    // let defaultList = [{ id: "all", text: "All", value: "All" }]
    let sortedList = []
    let allEliminetedList = []
    if (list && list.length > 0)
      sortedList = list.sort((a, b) =>
        a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
      )
    sortedList.length > 0 && sortedList.map(sl => {
      if (sl.text && sl.text.toLowerCase() !== "all")
        allEliminetedList.push(sl)
    })
    this.setState({ [name]: allEliminetedList })
    // this.setState({ [name]: [...defaultList, ...sortedList] })
  }
  handleDateChange = (date) => {
    this.setState({ ["fromDate"]: date });
  };
  handleDateChange1 = (date) => {
    this.setState({ ["toDate"]: date });
  };
  handleStockChange = (event, values) => {
    const { stockIn } = this.state;
    let stockID = values && values.stockID;
    stockIn["stock"] = values;
    stockIn["stockID"] = stockID;
    //this.validateField("stockID", stockID);
    this.setState({ stockIn });
  };
  handleCustomerChange = (event, values) => {
    const { stockIn, ItemMasterList } = this.state;

    let customerID = values && values.id;
    stockIn["customer"] = values;
    stockIn["customerID"] = customerID;
    //this.validateField("customerID", customerID);
    getStockInByCustIdList(customerID).then((r) => {
      this.setState({
        stockList:
          r.data &&
          r.data.sort((a, b) =>
            a.stockCode.toLowerCase() > b.stockCode.toLowerCase() ? 1 : -1
          ),
      });
    });
    stockIn["stock"] = null;
    stockIn["stockID"] = 0;
    //this.validateField("stockID", null);
    this.setState({ stockIn, ItemMasterList });
  };
  handleAccChange = (event, values) => {
    if (values) {
      this.setState({ accObj: values, accId: values.text })
      getAllVolumeList(values.text).then(r => {
        if (r && r.data)
          this.setSortedList("volList", r.data)
        else
          this.setSortedList("volList", [])
      })
      getAllIssueList(values.text, this.state.volObj.text).then(r => {
        if (r && r.data)
          this.setSortedList("issueList", r.data)
        else
          this.setSortedList("issueList", [])
      })
      getAllSuppList(values.text, this.state.volObj.text).then(r => {
        if (r && r.data)
          this.setSortedList("suppList", r.data)
        else
          this.setSortedList("suppList", [])
      })
      if (values.text && values.text.toLowerCase() !== "all") {
        this.setState({
          volObj: {},
          volId: "",
          issueObj: {},
          issueId: "",
          suppObj: {},
          suppId: ""
        })
      }
    }
    else
      this.setState({ accObj: {}, accId: "" })
  };
  handleVolChange = (event, values) => {
    if (values) {
      this.setState({ volObj: values, volId: values.text })
      getAllIssueList(this.state.accObj.text, values.text).then(r => {
        if (r && r.data)
          this.setSortedList("issueList", r.data)
        else
          this.setSortedList("issueList", [])
      })
      getAllSuppList(this.state.accObj.text, values.text).then(r => {
        if (r && r.data)
          this.setSortedList("suppList", r.data)
        else
          this.setSortedList("suppList", [])
      })
      if (values.text && values.text.toLowerCase() !== "all") {
        this.setState({
          issueObj: {},
          suppObj: {},
          issueId: "",
          suppId: ""
        })
      }
    }
    else
      this.setState({ volObj: {}, volId: "" })
  };
  handleIssueChange = (event, values) => {
    if (values)
      this.setState({ issueObj: values, issueId: values.id })
    else
      this.setState({ issueObj: {}, issueId: "" })
  };
  handleSuppChange = (event, values) => {
    if (values)
      this.setState({ suppObj: values, suppId: values.id })
    else
      this.setState({ suppObj: {}, suppId: "" })
  };
  handleStockTypeChange = (event, values) => {
    if (values)
      this.setState({ stockTypeObj: values, stockTypeId: values.id })
    else
      this.setState({ stockTypeObj: {}, stockTypeId: "" })
  };

  handleSubmit = () => {
    this.setState({ showPopup: true });
  };
  handleHidePopup = () => {
    this.setState({
      showPopup: false,
    });
  };
  render() {
    let { classes } = this.props;
    let para1 = this.state.fromDate ? moment(this.state.fromDate).format("MM/DD/YYYY") : ""
    let para2 = this.state.toDate ? moment(this.state.toDate).format("MM/DD/YYYY") : ""
    let para3 = this.state.accObj.text ? this.state.accObj.text : ""
    let para4 = this.state.volObj.text ? this.state.volObj.text : ""
    let para5 = this.state.issueObj.text ? this.state.issueObj.text : "All"
    let para6 = this.state.suppObj.text ? this.state.suppObj.text : "All"
    return (
      <Paper>
        <div style={{
          // border: "1px solid grey",
          margin: "0",
          padding: "10px 15px",
          // background: "#ECE9D8",
          display: "flex"
        }}>
          <div style={{ width: "75%", marginLeft: ".5em" }}>
            <div style={{ display: "flex", width: "100%", marginTop: ".2em" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  margin="normal"
                  disableToolbar
                  variant="inline"
                  id="date-picker-dialog"
                  required
                  label="From Date"
                  format="dd/MM/yyyy"
                  style={{ flex: 1, marginTop: 0, marginRight: "15px" }}
                  value={this.state.fromDate}
                  onChange={this.handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />

                <KeyboardDatePicker
                  autoOk
                  disabled={!this.state.fromDate}
                  disableToolbar
                  margin="normal"
                  disableToolbar
                  required
                  variant="inline"
                  id="date-picker-dialog"
                  label="To Date"
                  minDate={this.state.fromDate}
                  format="dd/MM/yyyy"
                  style={{ flex: 1, marginTop: 0 }}
                  value={this.state.toDate}
                  onChange={this.handleDateChange1}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            {/* <div style={{ display: "flex", width: "100%", marginTop: ".2em" }}>
              <Autocomplete
                autoComplete
                disableClearable
                className={classes.comboBox}
                id="customerID"
                includeInputInList
                options={this.state.customerList}
                style={{ marginTop: 0, marginRight: "15px" }}
                Value={this.state.customer}
                onChange={this.handleCustomerChange}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer"
                    name="customerID"
                    fullWidth
                  />
                )}
              />
              <Autocomplete
                autoComplete
                disableClearable
                className={classes.comboBox}
                id="stock"
                style={{ marginTop: 0 }}
                includeInputInList
                options={this.state.stockList}
                value={this.state.stockIn.stock}
                onChange={this.handleStockChange}
                getOptionLabel={(option) => option.stockCode}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Stock"
                    name="stock"
                    fullWidth
                  />
                )}
              />
            </div> */}
            <div style={{ display: "flex", width: "100%", marginTop: ".2em" }}>
              <Autocomplete
                autoComplete
                disableClearable
                className={classes.comboBox}
                id="acc"

                includeInputInList
                options={this.state.accList}
                style={{ marginTop: 0, marginRight: "15px" }}
                value={this.state.accObj}
                onChange={this.handleAccChange}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Acronym"
                    name="acc"
                    fullWidth
                  />
                )}
              />
              <Autocomplete
                autoComplete
                disableClearable
                className={classes.comboBox}
                id="vol"

                style={{ marginTop: 0 }}
                includeInputInList
                options={this.state.volList}
                value={this.state.volObj}
                onChange={this.handleVolChange}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Volume"
                    name="vol"
                    fullWidth
                    required
                  />
                )}
              />
            </div>
            <div style={{ display: "flex", width: "100%", marginTop: ".2em" }}>
              <Autocomplete
                autoComplete
                disableClearable
                className={classes.comboBox}
                id="issue"
                includeInputInList
                options={this.state.issueList}
                style={{ marginTop: 0, marginRight: "15px" }}
                value={this.state.issueObj}
                onChange={this.handleIssueChange}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Issue"
                    required={this.state.suppId ? false : true}
                    name="issue"
                    fullWidth
                  />
                )}
              />
              <Autocomplete
                autoComplete
                disableClearable
                className={classes.comboBox}
                id="supp"
                style={{ marginTop: 0 }}
                includeInputInList
                options={this.state.suppList}
                value={this.state.suppObj}
                onChange={this.handleSuppChange}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Supp"
                    name="supp"
                    fullWidth
                    required={this.state.issueId ? false : true}

                  />
                )}
              />
            </div>
            <div style={{ display: "flex", width: "100%", marginTop: ".2em", marginBottom: "2em" }}>
              <Autocomplete
                autoComplete
                disableClearable

                className={classes.comboBox}
                id="stockType"
                includeInputInList
                options={this.state.stockTypeList}
                style={{ marginTop: 0, marginRight: "15px" }}
                value={this.state.stockTypeObj}
                onChange={this.handleStockTypeChange}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Stock Type"
                    name="stockType"
                    fullWidth
                    required
                  />
                )}
              />
              <Autocomplete
                autoComplete
                className={classes.comboBox}
                id="stock"
                style={{ marginTop: 0, visibility: "hidden" }}
                includeInputInList
                options={this.state.stockList}
                value={this.state.stockIn.stock}
                // onChange={this.handleStockChange}
                getOptionLabel={(option) => option.stockCode}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Stock"
                    name="stock"
                    fullWidth
                  />
                )}
              />
            </div>
          </div>
          <div style={{
            width: "23%",
            marginLeft: "2%",
            padding: "15px",
            borderLeft: "1px solid #ccc"
          }}>
            <Button
              disabled={
                !this.state.fromDate ||
                !this.state.toDate ||
                !this.state.accId ||
                !this.state.volId ||
                !this.state.issueId && !this.state.suppId
              }
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={this.handleSubmit}
            >
              View Report
            </Button>
          </div>
        </div>
        <div>
          {this.state.showPopup && (
            <iframe
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    width: "calc(100vw - 8em)",
                    height: "calc(100vh - 281px)",
                    borderWidth: "1px 0 0",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    width: "calc(100vw - 335px)",
                    height: "calc(100vh - 281px)",
                    borderWidth: "1px 0 0",
                    margin: "0 auto",
                  }

              }
              src={
                `${window.localStorage.ReportURLByID}` +
                "PublisherStockMovement&IsDirectPrint=False&ReportParam=fromdate&Param1=" + `${para1}` + "&ReportParam=todate&Param2=" + `${para2}` + "&ReportParam=Acronym&Param3=" + `${para3}` + "&ReportParam=Volume&Param4=" + `${para4}` + "&ReportParam=Issue&Param5=" + `${para5}` + "&ReportParam=SuppNumber&Param6=" + `${para6}` + "&ReportParam=StockType&Param7=" + `${this.state.stockTypeObj.id}`
              }
            />
          )}
        </div>
      </Paper>
    );
  }
}

export default withRouter(withStyles(styles)(StockBalance));
