import React from "react";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { resourceValidation } from "../../../shared/resource";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { StateContext } from "../../../shared/globalState";
import clone from "clone";
import { getCustomerList } from "../../masters/masterActions";
import {
  getOneJournalitemMasterListEdit,
  updateJournalitemMasterList,
} from "./action";
import { Autocomplete } from "@material-ui/lab";
import { getLocationDropdownList } from "../../masters/masterActions"
// import { postRoutingCountry } from "../routingActions";
import InputAdornment from "@material-ui/core/InputAdornment";
const Reorderqty = new Validator("Reorderqty");
const onlyNumbers = new Validator("onlyNumbers");
const generalValidator = new Validator("general");
const checkrate = new Validator("checkdecimalpoint");
const minDate = new Date(new Date().getTime() - 86400000);
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    // marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: "31%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    // marginLeft: "7px",
    // marginLeft: "1.5em",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
});

class EditJournalItemMaster extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      //customerList: [],
      locationId: "",
      locationObj: {},
      locationList: [],
      customerId: "",
      customer: "",
      journalTypeList: [],
      jtObj: {},
      type: "",
      acronym: "",
      volume: "",
      issue: "",
      title: "",
      Remarks: "",
      suppNo: "",
      partNo: "",
      issueDesc: "",
      issueId: "",
      insert: "",
      office: "",
      expectedDeliveryDate: null,
      printRun: "",
      estMLR: "",
      estOMR: "",
      estBalanceOfStock: "",
      estUnitWeight: "",
      estTotalWeight: "",
      returnByAir: "",
      returnBySea: "",
      division: "",
      dimension: "",
      printer: "",
      splInstruction: "",
      splDeliveryInstruction: "",
      offlineFlag: "",
      distributor: "",
      uspsPermit: "",
      priorityTitle: "",
      journalId: "",
      splHandling: "",
      journalReceivedDate: null,
      actualPrintRunReceived: "",
      actualWeight: "",
      CPTWeight: "",
      BLWeight: "",
      mlCopies: "",
      omCopies: "",
      bpaMarketingQty: "",
      otCopies: "",
      hcCopies: "",
      uniqueJournalId: "",
      eboCopies: "",
      issnNo: "",
      publicationDate: null,
      publicationYear: null,
      offlineQty: "",
      cea: "",
      ces: "",
      status: "",
      active: "",
      emloEmailUpload: "",
      emloManual: "",
      returnByAirTrakingNo: "",
      returnBySeaTrakingNo: "",
      detailDescription: "",
      routingCountry: {
        customerID: "",
        countryID: "",
        locationID: "",
        dispatchTypeID: "",
        dispatchModeID: "",
        mailingSchemeID: "",
        distributorID: "",
        noOfOrdersFrom: "0",
        noOfOrdersTo: "0",
        noOfCopiesFrom: "0",
        noOfCopiesTo: "0",
        weightFrom: "0",
        weightTo: "0",
        ppiNumber: "",
        ppiLicenseNo: "",
        country: "",
        returnAddress: "",
        carrierSheetScheme: "",
        carrierSheetMode: "",
        customer: "",
        location: "",
        dispatchType: "",
        dispatchMode: "",
        mailingScheme: "",
        distributor: "",
      },
      errors: {},
      countryValid: false,
      customerValid: false,
      locationValid: false,
      dispatchTypeValid: false,
      dispatchModeValid: false,
      mailingschemeValid: false,
      distributorValid: false,
      noOfOrdersFromValid: true,
      noOfOrdersToValid: true,
      noOfCopiesFromValid: true,
      noOfCopiesToValid: true,
      weightFromValid: true,
      weightToValid: true,
      ppiLicenseNoValid: false,
      ppiNumberValid: false,
      returnAddressValid: false,
      carrierSheetSchemeValid: false,
      carrierSheetModeValid: false,
      countryList: [],
      customersList: [],
    };
  }
  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  _onCodeKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onCodeKeyPress1(event) {
    const re = /^[0-9.]{1,3}(?:\.[0-9]{1,2})?$/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    //Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }
  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.props.handleSettingHeader("Journal Item Master");
    this.props.setSelectedList(
      "publisherMainLabelOpen",
      "pubJournalItemMaster"
    );
    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    let newResData = clone(this.state);

    getOneJournalitemMasterListEdit(orderId).then((r) => {
      this.setState({
        bpaMarketingQty: r.data.bpaMarketingQty,
        customer: r.data.customer && r.data.customer.customerName,
        customerId: r.data.customerID,
        office: r.data.office,
        acronym: r.data.acronym,
        volume: r.data.volume,
        issue: r.data.issue,
        suppNo: r.data.suppNumber,
        publicationYear:
          r.data.publishingYear && new Date(`${r.data.publishingYear}`),
        expectedDeliveryDate: r.data.expectedDeliveryDate,
        detailDescription: r.data.detailDescription,
        journalDesc: r.data.journalDescription,
        type: r.data.journalType,
        issueId: r.data.uniqueIssueID,
        insert: r.data.insert,
        splInstruction: r.data.specialInstruction,
        hcCopies: r.data.hcCopies,
        emloManual: r.data.emloManual,
        emloEmailUpload: r.data.emloEmailUpload,
        // offlineQty: r.data.offlineQty &&r.data.offlineQty,
        offineFlag: r.data.offineFlag && r.data.offineFlag,
        issnNo: r.data.issnNo,
        publicationDate: r.data.publicationDate,
        publishingYear: r.data.publishingYear,
        createdBy: r.data.createdBy,
        createdDate: r.data.createdDate,
        modifiedBy: r.data.modifiedBy,
        modifiedDate: r.data.modifiedDate,
        printer: r.data.printer,
        title: r.data.titleDescription,
        Remarks: r.data.remarks,
        partNo: r.data.partNumber,
        printRun: r.data.printRun,
        estMLR: r.data.estMLR,
        estOMR: r.data.estOMR,
        estBalanceOfStock: r.data.estBalanceofStock,
        estUnitWeight: r.data.estUnitWeight,
        estTotalWeight: r.data.estTotalWeight,
        returnByAir: r.data.returnbyAir,
        returnBySea: r.data.returnbySea,
        division: r.data.division,
        dimension: r.data.dimensions,
        splDeliveryInstruction: r.data.specialDeliveryInstructions,
        offlineFlag: r.data.offineFlag,
        distributor: r.data.distributor,
        uspsPermit: r.data.uspsPermit,
        priorityTitle: r.data.priorityTitle,
        journalId: r.data.journalID,
        splHandling: r.data.specialHandling,
        journalReceivedDate: r.data.journalReceivedDate,
        actualPrintRunReceived: r.data.actualPrintRunReceived,
        actualWeight: r.data.actualWeight,
        CPTWeight: r.data.cptWeight,
        BLWeight: r.data.blWeight,
        mlCopies: r.data.mlCopies,
        omCopies: r.data.omCopies,
        otCopies: r.data.otCopies,
        hcCopies: r.data.hcCopies,
        eboCopies: r.data.eboCopies,
        cea: r.data.cea,
        ces: r.data.ces,
        status: r.data.statusID,
        isActive: r.data.isActive,
        uniqueJournalId: r.data.journalUniqueID,
        returnByAirTrakingNo: r.data.ceaTrackingNO,
        returnBySeaTrakingNo: r.data.cesTrackingNO,
      });
      getLocationDropdownList().then((rr) => {
        this.getAllLocation(rr.data, r.data.locationID);
      })
    });

    getCustomerList().then((r) => {
      r.data &&
        this.setState({
          customersList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.customerName.toLowerCase() > b.customerName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });
  }
  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextKeyPress(event) {
    const re = /[a-z A-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusNumberKeyPress(event) {
    const re = /[0-9 a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusDotPress(event) {
    const re = /[a-z A-Z.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusHyphenPress(event) {
    const re = /[a-z A-Z-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onUSPSKeyPress(event) {
    const re = /[0-9a-zA-Z-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberPlusHyphenPress(event) {
    const re = /[0-9-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusAllPress(event) {
    const re = /[a-z A-Z0-9=.;()-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onAllCharPress(event) {
    const re = /[a-z A-Z0-9=.;(){}\/*%#$@&,-_]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  onYorNKeyPress(event) {
    const re = /[yYnN]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSubmit = () => {
    let loggedInId = localStorage.getItem("loggedInId");
    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    let reqBody = {
      locationID: this.state.locationId ? this.state.locationId : 0,
      customerID: this.state.customerId && this.state.customerId,
      acronym: this.state.acronym,
      volume: this.state.volume,
      issue: this.state.issue,
      uniqueIssueID: this.state.issueId,
      journalUniqueID: this.state.uniqueJournalId,
      suppNumber: this.state.suppNo,
      partNumber: this.state.partNo,
      titleDescription: this.state.title,
      Remarks: this.state.Remarks,
      detailDescription: this.state.detailDescription,
      insert: this.state.insert,
      journalType: this.state.type,
      office: this.state.office,
      expectedDeliveryDate: this.state.expectedDeliveryDate,
      printRun: this.state.printRun ? +this.state.printRun : "",
      estMLR: this.state.estMLR ? +this.state.estMLR : "",
      estOMR: this.state.estOMR ? +this.state.estOMR : "",
      estBalanceofStock: this.state.estBalanceOfStock
        ? this.state.estBalanceOfStock
        : "",
      estUnitWeight: this.state.estUnitWeight ? +this.state.estUnitWeight : "",

      returnbyAir: this.state.returnByAir ? +this.state.returnByAir : "",
      returnbySea: this.state.returnBySea ? +this.state.returnBySea : "",
      ceaTrackingNO: this.state.returnByAirTrakingNo
        ? +this.state.returnByAirTrakingNo
        : "",
      cesTrackingNO: this.state.returnBySeaTrakingNo
        ? +this.state.returnBySeaTrakingNo
        : "",

      division: this.state.division,
      dimensions: this.state.dimension,
      printer: this.state.printer,
      specialInstruction: this.state.splInstruction,
      specialDeliveryInstructions: this.state.splDeliveryInstruction,
      offineFlag: this.state.offlineFlag,
      // offlineQty:this.state.offlineQty,
      distributor: this.state.distributor,
      uspsPermit: this.state.uspsPermit,
      priorityTitle: this.state.priorityTitle,
      specialHandling: this.state.splHandling,
      journalReceivedDate: this.state.journalReceivedDate,
      actualPrintRunReceived: this.state.actualPrintRunReceived
        ? +this.state.actualPrintRunReceived
        : "",
      actualWeight: this.state.actualWeight ? +this.state.actualWeight : "",
      CPTWeight: this.state.CPTWeight ? +this.state.CPTWeight : "",
      BLWeight: this.state.BLWeight ? +this.state.BLWeight : "",
      mlCopies: this.state.mlCopies ? +this.state.mlCopies : "",
      omCopies: this.state.omCopies ? +this.state.omCopies : "",
      otCopies: this.state.otCopies ? +this.state.otCopies : "",
      hcCopies: this.state.hcCopies ? +this.state.hcCopies : "",
      eboCopies: this.state.eboCopies ? +this.state.eboCopies : "",
      issnNo: this.state.issnNo,
      publicationDate: this.state.publicationDate,
      publishingYear: +moment(this.state.publicationYear).format("YYYY"),
      cea: this.state.cea ? +this.state.cea : "",
      ces: this.state.ces ? +this.state.ces : "",
      emloManual: this.state.emloManual ? +this.state.emloManual : "",
      emloEmailUpload: this.state.emloEmailUpload
        ? +this.state.emloEmailUpload
        : "",
      bpaMarketingQty: this.state.bpaMarketingQty
        ? +this.state.bpaMarketingQty
        : "",
      createdBy: +loggedInId,
      modifiedBy: +loggedInId,
      estTotalWeight: this.state.estTotalWeight
        ? +this.state.estTotalWeight
        : "",
      isActive: true,
      statusID: null,
      // ceaTrackingNO: 0,
      // cesTrackingNO: 0,
      createdDate: new Date(),
      modifiedDate: new Date(),
    };

    updateJournalitemMasterList(
      orderId,
      reqBody,
      this.props.history
    ).then((r) => { });
  };
  handleClose = () => {
    // clean up state and errors
    this.setState({
      country: "",
      customer: "",

      location: "",
      dispatchType: "",
      noOfOrdersFrom: "",
      noOfOrdersTo: "",
      noOfCopiesFrom: "",
      noOfCopiesTo: "",
      weightFrom: "",
      weightTo: "",
      ppiNumber: "",
      ppiLicenseNo: "",
      returnAddress: "",
      carrierSheetScheme: "",
      carrierSheetMode: "",

      errors: {},
    });
  };
  onBlur = (event) => { };
  handleAutocompleteLocation = (e, val) => {
    this.setState({ locationId: val && val.key, locationObj: val && val });
  };
  getAllLocation = (list, id) => {
    let locationList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        locationList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(locationList, "locationList", id);
  };
  sortDDList = (list, name, id) => {
    let oldCus = clone(list);
    if (name === "locationList") {
      oldCus &&
        oldCus.length > 0 &&
        oldCus.map(d => {
          if (d.key == id)
            this.setState({
              locationId: d.key,
              locationObj: d,
            });
        })

    }
    let z = list.sort(function (a, b) {
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ [name]: z });
  };
  render() {
    let { classes } = this.props;
    let {
      routingCountry,
      countryList,
      customersList,
      customer,
      acronym,
      volume,
      issue,
      title,
      Remarks,
      suppNo,
      partNo,
      issueDesc,
      issueId,
      insert,
      type,
      office,
      expectedDeliveryDate,
      printRun,
      estMLR,
      estOMR,
      estBalanceOfStock,
      estUnitWeight,
      estTotalWeight,
      returnByAir,
      returnBySea,
      division,
      dimension,
      printer,
      splInstruction,
      splDeliveryInstruction,
      offlineFlag,
      distributor,
      uspsPermit,
      priorityTitle,
      splHandling,
      journalReceivedDate,
      actualPrintRunReceived,
      actualWeight,
      CPTWeight,
      BLWeight,
      mlCopies,
      omCopies,
      otCopies,
      hcCopies,
      eboCopies,
      issnNo,
      publicationDate,
      publicationYear,
      offlineQty,
      cea,
      ces,
      status,
      active,
      emloEmailUpload,
      emloManual,
      returnByAirTrakingNo,
      returnBySeaTrakingNo,
      detailDescription,
    } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Journal Item Master</div>
          <Divider />
          <div className={classes.container}>
            <div>
              <div className={classes.container}>
                <p className={classes.subHeader}>Journal Primary Info</p>
                <div style={{ display: "flex" }}>
                  {/* <TextField
                    required
                    label="Customer"
                    name="customer"
                    InputProps={{ readOnly: true }}
                    variant={"filled"}
                    inputProps={{
                      maxLength: 50
                    }}
                    onKeyPress={this._onTextKeyPress}
                    //   onKeyPress={this._onNumberKeyPress}
                    // onKeyPress={this._onCodeKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={this.state.customer.customerName}
                    onChange={this.handleChange}
                  /> */}
                  <TextField
                    required
                    label="Customer"
                    name="customer"
                    InputProps={{ readOnly: true }}
                    variant={"filled"}
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onTextKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.customer}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required
                    label="Journal Type"
                    name="type"
                    InputProps={{ readOnly: true }}
                    variant={"filled"}
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onTextKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={type}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required
                    label="Acronym"
                    name="acronym"
                    InputProps={{ readOnly: true }}
                    variant={"filled"}
                    inputProps={{
                      maxLength: 15,
                    }}
                    onKeyPress={this._onTextKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={acronym}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    required
                    label="Volume"
                    name="volume"
                    InputProps={{ readOnly: true }}
                    variant={"filled"}
                    inputProps={{
                      maxLength: 10,
                    }}
                    onChange={this.handleChange}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={volume}
                  />
                  <TextField
                    label="Issue"
                    name="issue"
                    InputProps={{ readOnly: true }}
                    variant={"filled"}
                    inputProps={{
                      maxLength: 10,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={issue}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Supp Number"
                    name="suppNo"
                    InputProps={{ readOnly: true }}
                    variant={"filled"}
                    inputProps={{
                      maxLength: 10,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={suppNo}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Part Number"
                    InputProps={{ readOnly: true }}
                    variant={"filled"}
                    name="partNo"
                    inputProps={{
                      maxLength: 7,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={partNo}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Title Description"
                    // InputProps={{ readOnly: true }}
                    // variant={"filled"}
                    name="title"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onAllCharPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={title}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Detail Description"
                    // InputProps={{ readOnly: true }}
                    // variant={"filled"}
                    name="detailDescription"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onAllCharPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={detailDescription}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Unique Issue Id"
                    InputProps={{ readOnly: true }}
                    variant={"filled"}
                    name="issueId"
                    inputProps={{
                      maxLength: 36,
                    }}
                    // onKeyPress={this._onTextPlusNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.issueId}
                  // onChange={this.handleChange}
                  />
                  <TextField
                    label="Issue Description"
                    name="uniqueJournalId"
                    InputProps={{ readOnly: true }}
                    variant={"filled"}
                    inputProps={{
                      maxLength: 36,
                    }}
                    //  onKeyPress={this._onTextKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.uniqueJournalId}
                  //  onChange={this.handleChange}
                  />
                  <TextField
                    label="Insert"
                    name="insert"
                    InputProps={{ readOnly: true }}
                    variant={"filled"}
                    inputProps={{
                      maxLength: 1,
                    }}
                    helperText="Please enter Y or N"
                    onKeyPress={this.onYorNKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={insert}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Office"
                    name="office"
                    inputProps={{
                      maxLength: 20,
                    }}
                    InputProps={{ readOnly: true }}
                    variant={"filled"}
                    onKeyPress={this._onTextPlusNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={office}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Remarks"
                    name="Remarks"
                    inputProps={{
                      maxLength: 50,
                    }}
                    // InputProps={{ readOnly: true }}
                    // variant={"filled"}
                    // onKeyPress={this._onTextPlusNumberKeyPress}
                    onKeyPress={this._onAllCharPress}

                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={Remarks}
                    onChange={this.handleChange}
                  />
                  <Autocomplete
                    options={this.state.locationList}
                    value={this.state.locationObj}
                    disableClearable
                    style={{ flex: 1 }}
                    onChange={this.handleAutocompleteLocation}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="BL Location"
                        margin="normal"
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>

                {/* ............................................................... */}
                <p className={classes.subHeader}>Journal Primary Instruction</p>
                <div style={{ display: "flex" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      name="expectedDeliveryDate"
                      style={{ flex: 1, marginRight: "16px" }}
                      id="pickupDate"
                      label="Expected Delivery Date"
                      minDate={new Date("01-01-2020")}
                      error={false}
                      minDateMessage={""}
                      value={expectedDeliveryDate}
                      onChange={(e) =>
                        this.handleDateChange(e, "expectedDeliveryDate")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <TextField
                    label="Print Run"
                    name="printRun"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={printRun}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Est MLR"
                    name="estMLR"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={estMLR}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Est OMR"
                    name="estOMR"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={estOMR}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Est Balance Of Stock"
                    name="estBalanceOfStock"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={estBalanceOfStock}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Est Unit Weight"
                    name="estUnitWeight"
                    inputProps={{
                      maxLength: 10,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={estUnitWeight}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Est Total Weight"
                    name="estTotalWeight"
                    inputProps={{
                      maxLength: 10,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={estTotalWeight}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Return By Air"
                    name="returnByAir"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={returnByAir}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Return By Sea"
                    name="returnBySea"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={returnBySea}
                    onChange={this.handleChange}
                  />
                </div>
                {/* .................................................................. */}
                <p className={classes.subHeader}>
                  Journal Secondary Instruction
                </p>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Division"
                    name="division"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onAllCharPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={division}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Dimensions"
                    name="dimension"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={dimension}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Printer"
                    name="printer"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={printer}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    multiline
                    label="Special Instruction"
                    name="splInstruction"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onKeyPress={this._onAllCharPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={splInstruction}
                    onChange={this.handleChange}
                  />
                  <TextField
                    multiline

                    label="Special Delivery Instruction"
                    name="splDeliveryInstruction"
                    inputProps={{
                      maxLength: 300,
                    }}
                    onKeyPress={this._onAllCharPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={splDeliveryInstruction}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Offline Flag"
                    name="offlineFlag"
                    inputProps={{
                      maxLength: 1,
                    }}
                    helperText="Please enter Y or N"
                    onKeyPress={this.onYorNKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginBottom: "-12px" }}
                    value={offlineFlag}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Distributor"
                    name="distributor"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onAllCharPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={distributor}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="USPS Permit"
                    name="uspsPermit"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onUSPSKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={uspsPermit}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Priority Title"
                    name="priorityTitle"
                    inputProps={{
                      maxLength: 1,
                    }}
                    helperText="Please enter Y or N"
                    onKeyPress={this.onYorNKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={priorityTitle}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    multiline
                    label="Special Handling"
                    name="splHandling"
                    inputProps={{
                      maxLength: 300,
                    }}
                    onKeyPress={this._onAllCharPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={splHandling}
                    onChange={this.handleChange}
                  />
                  <div style={{ flexGrow: 1, marginRight: "16px" }}></div>
                  <div style={{ flexGrow: 1 }}></div>
                </div>
                {/* .............................................................. */}
                <p className={classes.subHeader}>Journal Actual Info</p>
                <div style={{ display: "flex" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      minDate={new Date("01-01-2020")}
                      error={false}
                      minDateMessage={""}
                      name="journalReceivedDate"
                      style={{ flex: 1, marginRight: "16px" }}
                      id="pickupDate"
                      label="Journal Received Date"
                      value={journalReceivedDate}
                      onChange={(e) =>
                        this.handleDateChange(e, "journalReceivedDate")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <TextField
                    label="Actual Print Run Received"
                    name="actualPrintRunReceived"
                    inputProps={{
                      maxLength: 9,
                    }}
                    // InputProps={{ readOnly: true }}
                    // variant={"filled"}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={actualPrintRunReceived}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Actual Weight"
                    name="actualWeight"
                    inputProps={{
                      maxLength: 15,
                    }}
                    // InputProps={{ readOnly: true }}
                    // variant={"filled"}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={actualWeight}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="ML Copies"
                    name="mlCopies"
                    inputProps={{
                      maxLength: 9,
                    }}
                    // InputProps={{ readOnly: true }}
                    // variant={"filled"}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={mlCopies}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="OM Copies"
                    name="omCopies"
                    inputProps={{
                      maxLength: 9,
                    }}
                    // InputProps={{ readOnly: true }}
                    // variant={"filled"}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={omCopies}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Culling Copies"
                    name="otCopies"
                    inputProps={{
                      maxLength: 9,
                    }}
                    // InputProps={{ readOnly: true }}
                    // variant={"filled"}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={otCopies}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  {/* <TextField
                    label="EBO Copies"
                    name="eboCopies"
                    inputProps={{
                      maxLength: 50
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{flex: 1, marginRight: "16px"}}
                    value={eboCopies}
                    onChange={this.handleChange}
                  /> */}
                  <TextField
                    label="EMLO Email Upload"
                    name="emloEmailUpload"
                    inputProps={{
                      maxLength: 9,
                    }}
                    // InputProps={{ readOnly: true }}
                    // variant={"filled"}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={emloEmailUpload}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="EMLO Manual"
                    name="emloManual"
                    inputProps={{
                      maxLength: 9,
                    }}
                    // InputProps={{ readOnly: true }}
                    // variant={"filled"}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={emloManual}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="HC Copies"
                    name="hcCopies"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={hcCopies}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  {/* <TextField
                    label="Offline Qty"
                    name="offlineQty"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{flex: 1, marginRight: "16px"}}
                    value={offlineQty}
                    onChange={this.handleChange}
                  /> */}
                  <TextField
                    label="CEA"
                    name="cea"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={cea}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="CES"
                    name="ces"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={ces}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Bpa Marketing Qty"
                    name="bpaMarketingQty"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1 }}
                    value={this.state.bpaMarketingQty}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Return By Air Tracking No"
                    name="returnByAirTrakingNo"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={returnByAirTrakingNo}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Return By Sea Tracking No"
                    name="returnBySeaTrakingNo"
                    inputProps={{
                      maxLength: 9,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={returnBySeaTrakingNo}
                    onChange={this.handleChange}
                  />
                  <div style={{ flexGrow: 1 }}></div>
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="CPT Weight"
                    name="CPTWeight"
                    inputProps={{
                      maxLength: 15,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={CPTWeight}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="BL Weight"
                    name="BLWeight"
                    inputProps={{
                      maxLength: 15,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={BLWeight}
                    onChange={this.handleChange}
                  />
                  <div style={{ flexGrow: 1 }}></div>
                </div>
                <p className={classes.subHeader}>Journal WSM Info</p>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="ISSN No"
                    name="issnNo"
                    inputProps={{
                      maxLength: 15,
                    }}
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    value={issnNo}
                    onChange={this.handleChange}
                  />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      minDate={new Date("01-01-2020")}
                      error={false}
                      minDateMessage={""}
                      name="publicationDate"
                      style={{ flex: 1, marginRight: "16px" }}
                      id="pickupDate"
                      label="Publication Date"
                      value={publicationDate}
                      onChange={(e) =>
                        this.handleDateChange(e, "publicationDate")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      //disablePast
                      views={["year"]}
                      disableToolbar
                      variant="inline"
                      format="yyyy"
                      margin="normal"
                      name="publicationYear"
                      // minDate={new Date("01-01-2020")}
                      error={false}
                      minDateMessage={""}
                      style={{ flex: 1 }}
                      id="pickupDate"
                      label="Publication Year"
                      value={publicationYear}
                      onChange={(e) =>
                        this.handleDateChange(e, "publicationYear")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <Divider style={{ margin: "15px 0 5px" }} />
                <div
                  className="button-wrapper"
                  style={{ paddingLeft: "0px", marginLeft: "0px" }}
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/pubJournalItemMaster")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    <Button
                      disabled={!this.state.locationId}
                      autoFocus
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={this.handleSubmit}
                      //className="enabled-button"
                      className={
                        !this.state.formValid
                          ? "disabled-button"
                          : "enabled-button "
                      }
                    >
                      SUBMIT
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(EditJournalItemMaster));
