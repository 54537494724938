import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import clone from "clone";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { styles } from "../../../shared/styles";
import { resourceValidation } from "../../../shared/resource";
import { StateContext } from "../../../shared/globalState";
import { getUserList, getPlannerList } from "../../userAccess/userAccessAction";
import {
  getCustomerDropdown,
  getLocationDropdownList,
  getServiceList,
  getOrderStatusDP
} from "../../masters/masterActions";
// import { getPublisherCustomerList } from "../action";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
//import { getFliteredDeptList } from "../masterActions";
const validateNotRequired2 = new Validator("notRequired2");
const validateNotRequired100 = new Validator("notRequired100");
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class JournalItemSearch extends React.Component {
  componentDidMount() {
    getPlannerList().then((r) => {
      this.getAllPlanners(r.data);
    });
    getCustomerDropdown().then((r) => {
      this.getAllCustomer(r.data);
    });
    getServiceList().then((r) => {
      this.getSvList(r.data);
    });
    getOrderStatusDP().then((r) => {
      this.getAllOrderStatus(r.data);
    });
  }
  getAllPlanners = (list) => {
    let plannerList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        // if (c.isActive)
        plannerList.push({ key: c.id, value: c.text, name: c.text });
      });
    this.setState({ plannerList });
    //this.sortDDList(plannerList, "plannerList");
  };
  getAllCustomer = (list) => {
    let userList = [];
    list &&
      list.length > 0 &&
      (userList = list.sort((a, b) =>
        a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
      ));
    let newCustomerList = [];
    userList.map((c) => {
      newCustomerList.push({
        key: c.id,
        value: c.value,
        name: c.text,
      });
    });
    this.setState({ customerList: newCustomerList });
  };
  getSvList = (list) => {
    let serviceList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        return serviceList.push({ key: c.id, value: c.value, name: c.value });
      });
    this.setState({ serviceList });
  };
  getAllOrderStatus = (list) => {
    let statusList = [];
    list &&
      list.length > 0 &&
      (statusList = list
        .filter((c) => c.value !== "OR")
        .sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ));
    let newStatusList = [];
    statusList.map((c) => {
      newStatusList.push({
        key: c.id,
        value: c.value,
        name: c.text,
      });
    });
    this.setState({ statusList: newStatusList });
  };
  static contextType = StateContext;
  state = {
    serviceList: [],
    serviceId: "",
    serviceObj: {},
    statusList: [],
    statusId: "",
    statusObj: {},
    customerList: [],
    customerId: 0,
    customerObj: {},
    plannerList: [],
    plannerId: 0,
    plannerObj: {},
    workOrderNo: "",
    jobTitle: "",
    jobGroupNo: "",
    pgsDocketNo: "",
    edsDocketNo: "",
    accountNo: "",
    submittedDate: null,
    lodgementDate: null,
    modifiedDateFrom: null,
    modifiedDateTo: null,
    open: false,
  };
  constructor() {
    super();
  }
  handleAutocompleteCustomer = (e, val) => {
    this.setState({ customerId: val && val.key, customerObj: val && val });
  };
  handleAutocompletPlanner = (e, val) => {
    this.setState({ plannerId: val && val.key, plannerObj: val && val });
  };
  handleAutocompletService = (e, val) => {
    this.setState({ serviceId: val && val.key, serviceObj: val && val });
  };
  handleAutocompletStatus = (e, val) => {
    this.setState({ statusId: val && val.key, statusObj: val && val });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      serviceList: [],
      serviceId: "",
      serviceObj: {},
      statusList: [],
      statusId: "",
      statusObj: {},
      customerList: [],
      customerId: "",
      customerObj: {},
      plannerList: [],
      plannerId: "",
      plannerObj: {},
      workOrderNo: "",
      jobTitle: "",
      jobGroupNo: "",
      pgsDocketNo: "",
      edsDocketNo: "",
      accountNo: "",
      submittedDate: null,
      lodgementDate: null,
      modifiedDateFrom: null,
      modifiedDateTo: null,
      open: false,
    });
  };
  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };
  onSearchClicked = () => {
    let reqBody = {
      customerName: this.state.customerId ? this.state.customerId : "",
      workOrderNumber: this.state.workOrderNo,
      jobTitle: this.state.jobTitle,
      jobGroupNumber: this.state.jobGroupNo,
      planner: "",
      pgsDocketNumber: this.state.pgsDocketNo,
      edsDocketNumber: this.state.edsDocketNo,
      accountNumber: "",
      lodgementDate: this.state.lodgementDate,
      submittedDate: this.state.submittedDate,
      serviceType: this.state.serviceId ? this.state.serviceId : "",
      status: this.state.statusId ? this.state.statusId : "",
      modifiedDateFrom: this.state.modifiedDateFrom,
      modifiedDateTo: this.state.modifiedDateTo,
      userId: +localStorage.loggedInId
    };
    this.props.onSearchClicked(reqBody);
    this.setState({
      serviceList: [],
      serviceId: "",
      serviceObj: {},
      statusList: [],
      statusId: "",
      statusObj: {},
      customerList: [],
      customerId: 0,
      customerObj: {},
      plannerList: [],
      plannerId: 0,
      plannerObj: {},
      workOrderNo: "",
      jobTitle: "",
      jobGroupNo: "",
      pgsDocketNo: "",
      edsDocketNo: "",
      accountNo: "",
      submittedDate: null,
      lodgementDate: null,
      modifiedDateFrom: null,
      modifiedDateTo: null,
      open: false,
    });
  };
  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  sortDDList = (list, name) => {
    let oldCus = clone(list);
    // if(name==="customerList")
    // oldCus&& this.setState({customerId: oldCus[0].key,
    //   customerObj: oldCus[0]})
    //   if(name==="locationList")
    //  {
    //   oldCus &&oldCus.length>0&& this.setState({locationId: oldCus.filter(d=> d.value==="SG")[0].key,
    //  plannerObj: oldCus.filter(d=> d.value==="SG")[0]})
    // }
    let z = list.sort(function (a, b) {
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ [name]: z });
  };
  getAllCustomer = (list) => {
    let customerList = [];

    list &&
      list.length > 0 &&
      list.map((c) => {
        customerList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(customerList, "customerList");
  };
  getAllLocation = (list) => {
    let plannerList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        plannerList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(plannerList, "plannerList");
  };
  render() {
    let { departmentCode, departmentName } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <Tooltip title="Search">
          <IconButton
            aria-label="Search"
            className={classes.iconHover}
            onClick={this.handleClickOpen}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          onClose={this.handleClose}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          maxWidth="md"
        >
          <DialogTitle id="customized-dialog-title">
            {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
            <span style={{ marginLeft: ".7em" }}>Advanced Search</span>
          </DialogTitle>
          <DialogContent dividers>
            <div style={{ marginTop: "-25px" }}>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <Autocomplete
                    options={this.state.customerList}
                    value={this.state.customerObj}
                    onChange={this.handleAutocompleteCustomer}
                    getOptionLabel={(option) => option.name}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // InputProps={{ readOnly: true }}
                        label="Customer"
                        style={{
                          width: "93%",
                          fontSize: "11px !important",
                          marginLeft: ".5em",
                          marginRight: ".5em",
                          maxHeight: "400px",
                        }}
                        // name="plannerId"
                        margin="normal"
                      />
                    )}
                  />
                </div>

                <div style={{ flex: 1 }}>
                  {/* <Autocomplete
                    options={this.state.plannerList}
                    value={this.state.plannerObj}
                    onChange={this.handleAutocompletPlanner}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Planner"
                        // InputProps={{ readOnly: true }}
                        style={{
                          width: "93%",
                          fontSize: "11px !important",
                          marginLeft: ".5em",
                          marginRight: ".5em",
                          maxHeight: "400px",
                        }}
                        // name="plannerId"
                        margin="normal"
                      />
                    )}
                  /> */}
                  <TextField
                    id="standard-required"
                    label="Eds Docket No"
                    name="edsDocketNo"
                    className={classes.textField}
                    margin="normal"
                    value={this.state.edsDocketNo}
                    inputProps={{
                      maxLength: 50,
                    }}
                    style={{
                      // width: "400px",
                      // minWidth: "237px",
                      //display: "inline-block",
                      width: "93%",
                      marginLeft: ".5em",
                      marginRight: ".5em",
                    }}
                    onChange={this.handleChange}
                    autoComplete="off"
                  //  onChange={handleSearchInput}
                  //  error={errors.acronym}
                  // helperText={errors.acronym ? errors.acronym : ""}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <TextField
                    id="standard-required"
                    label="Work Order No"
                    name="workOrderNo"
                    className={classes.textField}
                    margin="normal"
                    value={this.state.workOrderNo}
                    inputProps={{
                      maxLength: 50,
                    }}
                    style={{
                      // width: "400px",
                      // minWidth: "237px",
                      //display: "inline-block",
                      width: "93%",
                      marginLeft: ".5em",
                      marginRight: ".5em",
                    }}
                    onChange={this.handleChange}
                    autoComplete="off"
                  //  onChange={handleSearchInput}
                  //  error={errors.acronym}
                  // helperText={errors.acronym ? errors.acronym : ""}
                  />
                </div>

              </div>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <Autocomplete
                    options={this.state.serviceList}
                    value={this.state.serviceObj}
                    onChange={this.handleAutocompletService}
                    getOptionLabel={(option) => option.name}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // InputProps={{ readOnly: true }}
                        label="Service Type"
                        style={{
                          width: "93%",
                          fontSize: "11px !important",
                          marginLeft: ".5em",
                          marginRight: ".5em",
                          maxHeight: "400px",
                        }}
                        // name="plannerId"
                        margin="normal"
                      />
                    )}
                  />
                </div>

                <div style={{ flex: 1 }}>
                  <Autocomplete
                    options={this.state.statusList}
                    value={this.state.statusObj}
                    onChange={this.handleAutocompletStatus}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Status"
                        // InputProps={{ readOnly: true }}
                        style={{
                          width: "93%",
                          fontSize: "11px !important",
                          marginLeft: ".5em",
                          marginRight: ".5em",
                          maxHeight: "400px",
                        }}
                        // name="plannerId"
                        margin="normal"
                      />
                    )}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <TextField
                    id="standard-required"
                    label="Job Title"
                    name="jobTitle"
                    className={classes.textField}
                    margin="normal"
                    value={this.state.jobTitle}
                    inputProps={{
                      maxLength: 50,
                    }}
                    style={{
                      // width: "400px",
                      // minWidth: "237px",
                      //display: "inline-block",
                      width: "93%",
                      marginLeft: ".5em",
                      marginRight: ".5em",
                    }}
                    onChange={this.handleChange}
                    autoComplete="off"
                  //  onChange={handleSearchInput}
                  //  error={errors.acronym}
                  // helperText={errors.acronym ? errors.acronym : ""}
                  />
                </div>

              </div>
              {/* <div style={{ display: "flex" }}>
              
              
                <div style={{ flex: 1 }}>

                </div>
              </div> */}
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      style={{
                        width: "93%",
                        display: "inline-block",
                        marginLeft: ".5em",
                        marginRight: ".5em",
                      }}
                      id="lodgementDate"
                      label="Lodgement Date"
                      // onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      name="lodgementDate"
                      value={this.state.lodgementDate}
                      onChange={(e) =>
                        this.handleDateChange(e, "lodgementDate")
                      }
                    //    value={preAlertSearchValue.modifiedDateFrom}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div style={{ flex: 1 }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      style={{
                        width: "93%",
                        display: "inline-block",
                        marginLeft: ".5em",
                        marginRight: ".5em",
                      }}
                      name="submittedDate"
                      value={this.state.submittedDate}
                      id="submittedDate"
                      label="Submitted Date"
                      //   value={preAlertSearchValue.submittedDate}
                      onChange={(e) =>
                        this.handleDateChange(e, "submittedDate")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div style={{ flex: 1 }}>
                  <TextField
                    id="standard-required"
                    label="Job Group No"
                    name="jobGroupNo"
                    className={classes.textField}
                    margin="normal"
                    value={this.state.jobGroupNo}
                    inputProps={{
                      maxLength: 50,
                    }}
                    style={{
                      // width: "400px",
                      // minWidth: "237px",
                      //display: "inline-block",
                      width: "93%",
                      marginLeft: ".5em",
                      marginRight: ".5em",
                    }}
                    onChange={this.handleChange}
                    autoComplete="off"
                  //  onChange={handleSearchInput}
                  //  error={errors.acronym}
                  // helperText={errors.acronym ? errors.acronym : ""}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      style={{
                        width: "93%",
                        display: "inline-block",
                        marginLeft: ".5em",
                        marginRight: ".5em",
                      }}
                      id="modifiedDateFrom"
                      label="Modified Date From"
                      // onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      name="modifiedDateFrom"
                      value={this.state.modifiedDateFrom}
                      onChange={(e) =>
                        this.handleDateChange(e, "modifiedDateFrom")
                      }
                    //    value={preAlertSearchValue.modifiedDateFrom}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div style={{ flex: 1 }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      style={{
                        width: "93%",
                        display: "inline-block",
                        marginLeft: ".5em",
                        marginRight: ".5em",
                      }}
                      name="modifiedDateTo"
                      value={this.state.modifiedDateTo}
                      id="modifiedDateTo"
                      label="Modified Date To"
                      //   value={preAlertSearchValue.modifiedDateTo}
                      onChange={(e) =>
                        this.handleDateChange(e, "modifiedDateTo")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div style={{ flex: 1 }}>
                  <TextField
                    id="standard-required"
                    label="Pgs Docket No"
                    name="pgsDocketNo"
                    className={classes.textField}
                    margin="normal"
                    value={this.state.pgsDocketNo}
                    inputProps={{
                      maxLength: 50,
                    }}
                    style={{
                      // width: "400px",
                      // minWidth: "237px",
                      //display: "inline-block",
                      width: "93%",
                      marginLeft: ".5em",
                      marginRight: ".5em",
                    }}
                    onChange={this.handleChange}
                    autoComplete="off"
                  //  onChange={handleSearchInput}
                  //  error={errors.acronym}
                  // helperText={errors.acronym ? errors.acronym : ""}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ padding: "10px 33px" }}>
            <Button
              //   autoFocus
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={this.handleClose}
            //   color="primary"
            >
              CANCEL
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={(e) => this.onSearchClicked()}
              disabled={
                !(this.state.modifiedDateFrom
                  ? this.state.modifiedDateTo
                  : this.state.customerId ||
                  this.state.plannerId ||
                  this.state.serviceId ||
                  this.state.statusId ||
                  this.state.workOrderNo ||
                  this.state.jobTitle ||
                  this.state.jobGroupNo ||
                  this.state.pgsDocketNo ||
                  this.state.edsDocketNo ||
                  this.state.accountNo ||
                  this.state.submittedDate ||
                  this.state.lodgementDate)
              }
            >
              SEARCH
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(JournalItemSearch);
