import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from "prop-types";
import { StateContext } from "../../../shared/globalState";
import { stableSort, getSorting } from "../../../shared/tableCommonFunctions";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getJobGroup, getJobGroupList, } from "../workOrder/action";
import { getNewJobGroupList } from "../reportServiceAction"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 8 * 2,
  },
  closeButton: {
    position: "absolute",
    right: 8 * 1,
    top: 8 * 1,
    color: theme.palette.grey[500],
  },
});

// function createData(
//   workOrderID,
//   customerName,
//   workOrderNumber,
//   projectTitle,
//   jobGroupNumber
// ) {
//   return {
//     workOrderID,
//     customerName,
//     workOrderNumber,
//     projectTitle,
//     jobGroupNumber,
//   };
// }

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow >
        <TableCell padding="checkbox" className="table-cell"
          style={{ border: "1px solid #eaeaea", }}
        ></TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
                style={{ width: "auto", padding: ".5em" }
                  // ,i === 0 ? { borderLeft: "none" } : { borderLeft: "1px solid #eaeaea" }
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead >
  );
}

const EnhancedTableToolbar = (props) => {
  return <div></div>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default class WorkOrderModal extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    selectedWO: {
      // workOrderID: "",
      // customerName: "",
      // workOrderNumber: "",
      // projectTitle: "",
      // jobGroupNumber: "",
    },
    searchVal: "",
    searchClicked: false,
    selected: [],
    stateWO: [],
    deletePopupOpen: false,
    order: "desc",
    orderBy: "workOrderNumber",
    page: 0,
    rowsPerPage: 100,
    rows: [],
    activeRows: 0,
    isActive: false,
    loading: true,
    refreshData: false,
    selectedWorkOrder: {},
    selectedWorkOrderId: 1,
    searchPopupOpen: false,
    workOrderSearchValues: {},
    selectedDDL: 0,
    filteredList: [],
    jobGroupList: [],
    exportDataValues: [],
    selectedShowColumns: [],
    headCells: [
      {
        id: 1,
        code: "customerName",
        numeric: false,
        disablePadding: true,
        label: "Customer",
        show: true,
      },
      {
        id: 2,
        code: "workOrderNumber",
        numeric: false,
        disablePadding: true,
        label: "Work Order Number",
        show: true,
      },
      {
        id: 3,
        code: "projectTitle",
        numeric: false,
        disablePadding: true,
        label: "Title",
        show: true,
      },
      // {
      //   id: 4,
      //   code: "jobGroupNumber",
      //   numeric: false,
      //   disablePadding: true,
      //   label: "Job Group Number",
      //   show: true,
      // },
    ],
  };
  componentDidMount() {
    //this.props.setSelectedList("masterDataOpen", "customer");
    const dispatch = this.context[1];
    getNewJobGroupList().then((r) => {
      dispatch({
        //type: types.CPTCODE_LIST,
        type: "getJobGroup",
        newStore: r.data,
      });
      this.getAllWorkOrder(r.data);
    });
    this.setState({ stateWO: this.props.jobGroupList });
  }
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.jobGroupList && store.jobGroupList.length > 0)
      if (this.state.jobGroupList.length !== store.jobGroupList.length) {
        this.getAllWorkOrder(store.jobGroupList);
      }
    return true;
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      open: false,
      selectedWO: {
        // workOrderID: "",
        //   customerName: "",
        //   workOrderNumber: "",
        //   projectTitle: "",
        //   jobGroupNumber: "",
      },
      searchVal: "",
      selected: [],
      newSelected: [],
      searchClicked: false,
      stateWO: this.props.jobGroupList,
    });
  };
  handleDialogOpen = () => {
    this.setState({
      selectedWO: {
        // workOrderID: "",
        // customerName: "",
        // workOrderNumber: "",
        // projectTitle: "",
        // jobGroupNumber: "",
      },
      searchVal: "",
      searchClicked: false,
      stateWO: this.props.jobGroupList,
    });
  };
  handleSubmit = () => {
    this.props.handleWorkOrderInfo(this.state.selectedWO);
    this.setState({
      open: false,
      selectedWO: {
        // workOrderID: "",
        // customerName: "",
        // workOrderNumber: "",
        // projectTitle: "",
        // jobGroupNumber: "",
      },
      searchVal: "",
      searchClicked: false,
      stateWO: this.props.jobGroupList,
    });
  };

  onInputChanged = (e) => {
    this.setState({ searchVal: e.target.value });
  };
  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  clearSearch = () => {
    const dispatch = this.context[1];
    getNewJobGroupList().then((r) => {
      dispatch({
        //type: types.CPTCODE_LIST,
        type: "getJobGroupList",
        newStore: r.data,
      });
      this.getAllWorkOrder(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  // handleSearchWorkOrder = (handleSearchWorkOrder) => {
  //   const dispatch = this.context[1];
  //   let workOrderSearchValue = handleSearchWorkOrder;
  //   let customerName = workOrderSearchValue.customerName;
  //   let workOrderNumber = workOrderSearchValue.workOrderNumber;
  //   let projectTitle = workOrderSearchValue.projectTitle;
  //   let jobGroupNumber = workOrderSearchValue.jobGroupNumber;

  //   let reqBody = {
  //     customerName,
  //     workOrderNumber,
  //     projectTitle,
  //     jobGroupNumber,
  //   };
  //   getJobGroup(reqBody).then((r) => {
  //     dispatch({
  //       type: "getJobGroup",
  //       newStore: r.data,
  //     });

  //     this.getAllWorkOrder(r.data);
  //     this.setState({ searchPopupOpen: false, searchClicked: true });
  //   });
  // };
  getAllWorkOrder = (jobGroupList) => {
    let list = jobGroupList;
    let rows = [];
    // list &&
    //   list.length > 0 &&
    //   list.map((d) => {
    //     return rows.push(
    //       createData(
    //         d.workOrderID,
    //         d.customerName,
    //         d.workOrderNumber,
    //         d.projectTitle,
    //         d.jobGroupNumber
    //       )
    //     );
    //   });
    this.setState({ jobGroupList, filteredList: jobGroupList, loading: false });
  };
  onClearSearch = () => {
    const dispatch = this.context[1];
    getNewJobGroupList().then((r) => {
      dispatch({
        //type: types.CPTCODE_LIST,
        type: "getJobGroupList",
        newStore: r.data,
      });
      this.getAllWorkOrder(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
    this.setState({
      stateWO: this.props.jobGroupList,
      searchVal: "",
      searchClicked: false,
    });
  };
  onSearchClicked = () => {
    //this.props.clearWorkOrderInfo(this.props.i);
    let filteredList = [];
    const jobGroupList = this.state.jobGroupList;
    if (this.state.searchVal !== "") {
      filteredList = jobGroupList.filter((r) => {
        return (
          r.customerName
            .toLowerCase()
            .includes(this.state.searchVal.toLowerCase()) ||
          r.workOrderNumber
            .toLowerCase()
            .includes(this.state.searchVal.toLowerCase()) ||
          r.projectTitle
            .toLowerCase()
            .includes(this.state.searchVal.toLowerCase())

          //||
          // r.jobGroupNumber
          //   .toLowerCase()
          //   .includes(this.state.searchVal.toLowerCase())

        );
      });
    } else {
      filteredList = jobGroupList;
    }
    this.setState({ filteredList: filteredList });

    let se = this.state.stateWO.filter((n) => {
      if (
        n &&
        n.value.toLowerCase().includes(this.state.searchVal.toLowerCase())
      )
        return n;
    });
    this.setState({ stateWO: se, searchClicked: true });
  };
  handleClick(event, data) {
    // console.log(event.target.checked)
    let workOrderID = data.workOrderID;
    if (event.target.checked)
      this.setState({ selectedWO: data });
    else
      this.setState({ selectedWO: {} });
    let { selected } = this.state;

    const selectedIndex = selected.indexOf(workOrderID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, workOrderID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (newSelected.length === 1) {
      //this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedWorkOrder: data,
        selectedWorkOrderId: data.workOrderID,
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedWorkOrderId: 0,
      });
    }
  }
  render() {
    let { open } = this.state;
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      filteredList,
      jobGroupList,
    } = this.state;
    const isSelected = (workOrderID) => selected.indexOf(workOrderID) !== -1;
    if (selected.length > 1) {
      selected.shift();
    }

    return (
      <div >
        <Button
          onClick={this.handleClickOpen}
          variant="contained"
          color="primary"
          style={{
            // display: "flex",
            // alignItems: "center",
            width: "200px",
            // padding: ".2em",
            // marginTop: "1em",
            // boxShadow: "none",
          }}
        >
          <span>CHOOSE WORK ORDER</span>
        </Button>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-projectTitle"
          open={open}
          maxWidth="xl"
        >
          <DialogTitle
            id="customized-dialog-projectTitle"
            onClose={this.handleClose}
          >
            Choose Work Order
          </DialogTitle>
          <DialogContent dividers>
            <div
              style={{
                margin: "1em 0",
                display: "flex",
              }}
            >
              <div
                style={{
                  borderBottom: "1px solid #949191",
                  fontSize: "17px",
                  fontFamily: "Roboto",
                  marginTop: "4px",
                  display: "flex",
                }}
              >
                {/* {this.state.selectedWO.map((s, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        marginRight: "4px",
                        backgroundColor: "#3982ea",
                        height: "23px",
                        padding: "3px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "-6px",
                        borderRadius: "4px",
                        color: "#fff",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}
                    >
                      <span>{s.value}</span>
                      <span
                        style={{
                          marginLeft: ".5em",
                          marginTop: ".3em"
                        }}
                      >
                         <HighlightOffIcon
                          style={{ cursor: "pointer" }}
                          onClick={e => this.onDeleteWOClicked(e, i, s)}
                        />
                      </span>
                    </div>
                  );
                })} */}
              </div>
              <TextField
                value={this.state.searchVal}
                onChange={this.onInputChanged}
                style={{ width: "100%" }}
                placeholder="Search Customer, Work Order Number, Work Order Title"
                InputProps={{
                  endAdornment: !this.state.searchClicked ? (
                    <InputAdornment onClick={(e) => this.onSearchClicked()}>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                      <InputAdornment onClick={this.onClearSearch}>
                        <IconButton>
                          <HighlightOffIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                }}
              />
            </div>
            <EnhancedTableToolbar />
            <div style={{ height: "300px", maxWidth: "100%" }}>
              <div
                style={
                  !this.props.clearOpenList
                    ? {
                      overflow: "auto",
                      //maxWidth: "calc(100vw - 8em)",
                      maxHeight: 300,
                    }
                    : {
                      overflow: "auto",
                      //maxWidth: "calc(100vw - 330px)",
                      maxHeight: 300,
                    }
                }
              >
                <Table
                  stickyHeader
                  aria-labelledby="tableTitle"
                  size="small"
                  aria-label="enhanced table"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                    onRequestSort={this.handleRequestSort}
                    rowCount={this.state.activeRows.length}
                    headCells={this.state.headCells}
                  />
                  <TableBody style={{ overflow: "hidden" }}>
                    {filteredList && filteredList.length > 0 ? (
                      stableSort(filteredList, getSorting(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.workOrderID);
                          return (
                            <TableRow
                              hover
                              onClick={(event) => this.handleClick(event, row)}
                              WorkOrder="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.workOrderID}
                              selected={isItemSelected}
                              style={{ cursor: "pointer" }}
                            >
                              <TableCell padding="checkbox" style={{ borderLeft: "1px solid #eaeaea" }}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      checked={isItemSelected}
                                    />
                                  }
                                />
                              </TableCell>
                              {this.state.headCells[0].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-cell"
                                >
                                  {row.customerName}
                                </TableCell>
                              )}
                              {this.state.headCells[1].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-cell"
                                >
                                  {row.workOrderNumber}
                                </TableCell>
                              )}
                              {this.state.headCells[2].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-cell"
                                >
                                  {row.projectTitle}
                                </TableCell>
                              )}
                              {/* {this.state.headCells[3].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-cell"
                                >
                                  {row.jobGroupNumber}
                                </TableCell>
                              )} */}
                            </TableRow>
                          );
                        })
                    ) : (
                        <TableRow>
                          {this.state.loading ? (
                            <TableCell
                              colSpan={5}
                              style={{ textAlign: "center" }}
                            >
                              <LinearProgress />
                            </TableCell>
                          ) : (
                              <TableCell
                                colSpan={5}
                                style={{ textAlign: "center" }}
                              >
                                No result found
                              </TableCell>
                            )}
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </div>
            </div>
            <TablePagination
              rowsPerPageOptions={[100, 200, 300]}
              component="div"
              count={jobGroupList && jobGroupList.length > 0 ? jobGroupList.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
            {/* <div style={{ margin: "1em", display: "flex", flexWrap: "wrap" }}>
              {this.state.stateWO && this.state.stateWO.map((n, i) => (
                <div
                  id={n.key}
                  key={i}
                  onClick={e =>
                    document.getElementById(n.key).getAttribute("disabled")
                      ? ""
                      : this.handleChange(n.key, n.value)
                  }
                  style={{
                    border: "1px solid #eaeaea",
                    margin: "4px",
                    padding: ".5em",
                    borderRadius: "4px",
                    cursor: "pointer"
                  }}
                >
                  {" "}
                  <span id={`${n.key}Icon`}></span>
                  {n.value}
                </div>
              ))}
            </div> */}
          </DialogContent>
          <DialogActions
            style={{ padding: "10px 24px" }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ boxShadow: "none", margin: ".5em " }}
              onClick={this.handleClose}
            // style={{ padding: "10px 24px" }}
            >
              CANCEL
            </Button>
            {this.state.selectedWO.length > 0 ? (
              <Button
                disabled
                variant="contained"
                color="primary"
                style={{ boxShadow: "none", margin: ".5em " }}
                onClick={(e) => this.handleSubmit()}
              >
                Submit
              </Button>
            ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none", margin: ".5em " }}
                  onClick={(e) => this.handleSubmit()}
                >
                  Submit
                </Button>
              )}
          </DialogActions>
        </Dialog>
      </div >
    );
  }
}
