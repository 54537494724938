import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { resourceValidation } from "../../../shared/resource";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { postCodeType, getCodeTypeList } from "../masterActions";
import { addStyles } from "../../../shared/styles";
import { FormControlLabel, Checkbox } from "@material-ui/core";
const maxlengthValidator20WithNum = new Validator("maxlength20WithNum");
const maxLenghtAlpNumHypDot50 = new Validator("maxLenghtAlpNumHypDot50");
class AddCodeType extends React.Component {
  state = {
    codeTypeCode: {
      //codeTypeID: "",
      codeType: "",
      codeTypeDescription: "",
      canAddNew: false,
      canEditStartDate: false,
      canEditValue: false,
      serviceSpecific: false,
      codevalueDataType: ""
    },
    errors: {},

    codeTypeValid: false,
    codeTypeDescriptionValid: false,
    canAddNewValid: false,
    canEditStartDateValid: false,
    canEditValueValid: false,
    serviceSpecificValid: false,
    codevalueDataTypeValid: false
  };

  componentDidMount() {
    this.props.handleSettingHeader("Code Type");
    this.props.setSelectedList("masterDataOpen", "codeType");
  }

  handleChange = event => {
    const { codeTypeCode } = this.state;
    codeTypeCode[event.target.name] = event.target.value;

    if (
      event.target.name === "isActive" ||
      event.target.name === "canAddNew" ||
      event.target.name === "canEditStartDate" ||
      event.target.name === "canEditValue" ||
      event.target.name === "serviceSpecific"
    ) {
      codeTypeCode[event.target.name] = event.target.checked;
      // codeTypeCode.isPeriodical = "";
      // codeTypeCode.canAddNewValid = "";
      // codeTypeCode.canEditStartDateValid = "";
      // codeTypeCode.canEditValueValid = "";
      //   codeTypeCode.serviceSpecificValid="";
      //   codeTypeCode.codevalueDataTypeValid = "";
      //   codeTypeCode.isInternal = "";
    } else {
      codeTypeCode[event.target.name] = event.target.value;

      this.validateField(event.target.name, event.target.value);
    }

    this.setState({ codeTypeCode });
  };

  checkValidInputs = () => {
    //if (!this.state.codeType.codeTypeID) return true;
    if (!this.state.codeTypeCode.codeType) return true;
    if (!this.state.codeTypeCode.codeTypeDescription) return true;
    // if (!this.state.codeTypeCode.canAddNew) return true;
    // if (!this.state.codeTypeCode.canEditStartDate) return true;
    // if (!this.state.codeTypeCode.canEditValue) return true;
    // if (!this.state.codeTypeCode.serviceSpecific) return true;
    // if (!this.state.codeTypeCode.codevalueDataType) return true;
    else return false;
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;

    //let codeTypeIDValid = this.state.//codeTypeIDValid;
    let codeTypeValid = this.state.codeTypeValid;
    let codeTypeDescriptionValid = this.state.codeTypeDescriptionValid;
    // let canAddNewValid = this.state.canAddNewValid;
    // let canEditStartDateValid = this.state.canEditStartDateValid;
    // let canEditValueValid = this.state.canEditValueValid;
    // let serviceSpecificValid = this.state.serviceSpecificValid;
    let codevalueDataTypeValid = this.state.codevalueDataTypeValid;

    switch (fieldName) {
      //   case "//codeTypeIDValid":
      //     //codeTypeIDValid = maxlengthValidator10WithNum(value);
      //     fieldValidationErrors.//codeTypeIDValid = //codeTypeIDValid
      //       ? ""
      //       : resourceValidation.max2char;
      //     break;
      case "codeTypeValid":
        codeTypeValid = maxLenghtAlpNumHypDot50(value);
        fieldValidationErrors.codeTypeValid = codeTypeValid
          ? ""
          : resourceValidation.onlyAlphanumeric;
        break;
      case "codeTypeDescriptionValid":
        codeTypeDescriptionValid = true;
        fieldValidationErrors.codeTypeDescriptionValid = codeTypeDescriptionValid
          ? ""
          : "";
        break;
      // case "canAddNewValid":
      //   canAddNewValid = maxlengthValidator20WithNum(value);
      //   fieldValidationErrors.canAddNewValid = canAddNewValid
      //     ? ""
      //     : resourceValidation.onlyAlphanumeric;
      //   break;
      // case "canEditStartDate":
      //   canEditStartDateValid = maxlengthValidator20WithNum(value);
      //   fieldValidationErrors.canEditStartDateValid = canEditStartDateValid
      //     ? ""
      //     : resourceValidation.onlyAlphanumeric;
      //   break;
      // case "canEditValue":
      //   canEditValueValid = maxlengthValidator20WithNum(value);
      //   fieldValidationErrors.canEditValueValid = canEditValueValid
      //     ? ""
      //     : resourceValidation.onlyAlphanumeric;
      //   break;
      // case "serviceSpecific":
      //   serviceSpecificValid = maxlengthValidator20WithNum(value);
      //   fieldValidationErrors.serviceSpecificValid = serviceSpecificValid
      //     ? ""
      //     : resourceValidation.onlyAlphanumeric;
      //   break;
      case "codevalueDataType":
        codevalueDataTypeValid = maxlengthValidator20WithNum(value);
        fieldValidationErrors.codevalueDataTypeValid = codevalueDataTypeValid
          ? ""
          : "";
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        //codeTypeIDValid : //codeTypeIDValid,
        codeTypeValid: codeTypeValid,
        codeTypeDescriptionValid: codeTypeDescriptionValid,
        // canAddNewValid: canAddNewValid,
        // canEditStartDateValid: canEditStartDateValid,
        // canEditValueValid: canEditValueValid,
        // serviceSpecificValid: serviceSpecificValid,
        codevalueDataTypeValid: codevalueDataTypeValid
      },
      this.validateForm
    );
  }

  validateForm = () => {
    this.setState({
      formValid:
        //   this.state.//codeTypeIDValid &&
        this.state.codeTypeValid && this.state.codeTypeDescriptionValid
      // this.state.canAddNewValid &&
      // this.state.canEditStartDateValid &&
      // this.state.canEditValueValid &&
      // this.state.serviceSpecificValid &&
      // this.state.codevalueDataTypeValid
    });
    return this.state.formValid;
  };

  handleClose = () => {
    // clean up state and errors
    this.setState({
      //codeTypeID: "",
      codeType: "",
      codeTypeDescription: "",
      canAddNew: "",
      canEditStartDate: "",
      canEditValue: "",
      serviceSpecific: "",
      codevalueDataType: "",
      errors: {}
    });
  };

  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleSubmit = event => {
    const dispatch = this.context;
    const { codeTypeCode } = this.state;

    //letcodeTypeID =  codeType.//codeTypeID;
    let codeType = codeTypeCode.codeType;
    let codeTypeDescription = codeTypeCode.codeTypeDescription;
    let canAddNew = codeTypeCode.canAddNew;
    let canEditStartDate = codeTypeCode.canEditStartDate;
    let canEditValue = codeTypeCode.canEditValue;
    let serviceSpecific = codeTypeCode.serviceSpecific;
    let codevalueDataType = codeTypeCode.codevalueDataType;
    let isActive = true;
    let createdBy = localStorage.loggedInId;
    let reqBody = {
      //codeTypeID,
      codeType,
      codeTypeDescription,
      canAddNew,
      canEditStartDate,
      canEditValue,
      serviceSpecific,
      codevalueDataType,
      isActive,
      createdBy
    };
    postCodeType(reqBody, this.props.history)
      .then(r => {
        getCodeTypeList()
          .then(r => {
            dispatch({
              type: "getCodeType",
              newStore: r.data
            });
          })
          .catch(e => {});
      })
      .catch(e => {});
  };

  handleChangeVendor = name => event => {
    let newBA = { ...this.state.codeTypeCode };
    newBA[name] = event.target.checked;
    this.setState({ codeTypeCode: newBA });
  };

  render() {
    //console.log("this.state", this.state);
    let { classes } = this.props;
    let { codeTypeCode } = this.state;

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Code Type</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container}>
                <div style={{ display: "flex" }}>
                  <TextField
                    required
                    label="Code Type"
                    name="codeType"
                    inputProps={{
                      maxLength: 6
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={codeTypeCode.codeType}
                    onChange={this.handleChange}
                    error={this.state.errors.codeType}
                    helperText={
                      this.state.errors.codeType
                        ? this.state.errors.codeType
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />

                  <TextField
                    required
                    multiline={false}
                    label="Code Type Description"
                    rows={1}
                    rowsMax={4}
                    name="codeTypeDescription"
                    inputProps={{
                      maxLength: 100
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={codeTypeCode.codeTypeDescription}
                    onChange={this.handleChange}
                    error={this.state.errors.codeTypeDescription}
                    helperText={
                      this.state.errors.codeTypeDescription
                        ? this.state.errors.codeTypeDescription
                        : ""
                    }
                  />
                  {/* <TextField
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    inputProps={{
                      maxLength: 10
                    }}
                    label="Code Value Data Type"
                    name="codevalueDataType"
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={codeTypeCode.codevalueDataType}
                    onChange={this.handleChange}
                    error={this.state.errors.codevalueDataType}
                    helperText={
                      this.state.errors.codevalueDataType
                        ? this.state.errors.codevalueDataType
                        : ""
                    }
                  /> */}
                </div>
                <div>
                  <FormControlLabel
                    className={classes.checkBox}
                    control={
                      <Checkbox
                        color="primary"
                        value={codeTypeCode.canAddNew}
                        onChange={this.handleChange}
                        checked={codeTypeCode.canAddNew}
                        name="canAddNew"
                      />
                    }
                    label="Add New"
                  />
                  <FormControlLabel
                    className={classes.checkBox}
                    control={
                      <Checkbox
                        color="primary"
                        value={codeTypeCode.canEditValue}
                        onChange={this.handleChange}
                        name="canEditValue"
                        checked={codeTypeCode.canEditValue}
                      />
                    }
                    label="Edit Value"
                  />
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={e => this.props.history.push("/codeType")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {this.checkValidInputs() ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>

            {/* </form> */}
          </div>
          {/* </div> */}
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddCodeType));
