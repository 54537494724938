import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { Autocomplete } from "@material-ui/lab";
import { getDHLRateUponAWeight } from "../../publisher/mainLabelOrderPlacement/action"
import moment from "moment";
import InfoIcon from "@material-ui/icons/Info";
import DescriptionIcon from "@material-ui/icons/Description";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import toastr from "toastr"
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { CustomSmallAddIcon, EditIcon } from "../../../shared/customIcons";
import { stableSort, getSorting } from "../../../shared/tableCommonFunctions";
import IndividualSearchAddDialog from "./IndividualSearchAddDialog";
import { resourceValidation } from "../../../shared/resource";
import clone from "clone";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Paper,
  Divider,
  IconButton,
  Tooltip,
  LinearProgress,
  Typography,
} from "@material-ui/core";

import {
  getCustomerList,
  getCountryDropdownList,
} from "../../masters/masterActions";
import {
  postEbloList,
  getEBLOList,
  getEBLONumberList,
  postEbloMailList,
  validateEmailEBLO,
} from "./action";
import { getCustomerForInsertMasterList } from "../../publisher/action";

const notrequired50 = new Validator("notRequired50");
const validateMaxLength2 = new Validator("maxlength2");
const emailValidator = new Validator("email");

const styles = (theme) => ({
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginRight: theme.spacing(2),
    fontSize: "11px !important",
    flex: 1,
  },
  emptyField: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
  },
  lastChild: {
    marginRight: 0,
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
});

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={
                  headCell.label == "Action"
                    ? "center"
                    : headCell.numeric
                      ? "right"
                      : "left"
                }
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
                style={{ width: headCell.width }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

function DetectionControl(props) {
  return (
    <div
      style={{
        width: "45%",
        display: "flex",
        alignItems: "center",
      }}
    >
      {props.message && props.message.toLowerCase().indexOf("present") > -1 || props.message && props.message.toLowerCase().includes("contain") ? (
        <span>
          <InfoIcon style={{ color: "red", fontSize: "19px" }} />
          <span style={{ position: "relative", top: "-5px", left: "4px" }}>
            {props.message}
          </span>
        </span>
      ) : (
          <span>
            <CheckCircleIcon
              style={{ color: "rgba(59, 218, 182, 0.87)", fontSize: "19px" }}
            />
            <span style={{ position: "relative", top: "-5px", left: "4px" }}>
              {props.message}
            </span>
          </span>
        )}
    </div>
  );
}

class AddEBLOOrderPlacement extends React.Component {
  constructor() {
    super();

    this.state = {
      customersList: [],
      customer: {},
      customerId: "",
      customerName: "",
      eblo: "",
      orderCreatedBy: "",
      department: "",
      orderDate: null,
      warehouseEmail: "",
      recipient: "",
      specialInstructions: "",
      deliveryMethod: "",
      deliveryDetails: "",
      addressLine: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      state: "",
      postCode: "",
      countryList: [],
      PGSCountry: "",
      country: "",
      countryCode: "",
      customerCountryCode: "",
      areaCode: "",
      city: "",
      number: "",
      journalDispatch: "",
      errors: {},
      loading: false,
      openDialog: false,
      codeTypeList: [],
      page: 0,
      rowsPerPage: 100,
      order: "desc",
      orderBy: "modifiedDate",
      isManual: false,
      msg: [],
      msgDispArray: [],
      msgDispObj: {},
      selectedEditIndex: null,
      isEditClicked: false,
      ebloList: [],
      reqJournalDetail: [],
      deletePopupOpen: false,
      newQuantity: 0,
      newTrackingNumber: "",
      newActualWeight: 0.0,
      newDeliveryDate: null,
      quantityCheck: false,
      headCellsNew: [
        {
          id: 1,
          code: "jouranlUniqueID",
          numeric: false,
          disablePadding: false,
          label: "Issue Description",
          show: true,
          width: "200px",
        },
        {
          id: 2,
          code: "acronym",
          numeric: false,
          disablePadding: false,
          label: "Acronym",
          show: true,
          width: "58px",
        },
        {
          id: 3,
          code: "journalTitle",
          numeric: false,
          disablePadding: false,
          label: "Journal Title",
          show: true,
          width: "200px",
        },
        {
          id: 4,
          code: "volume",
          numeric: false,
          disablePadding: false,
          label: "Volume",
          show: true,
          width: "50px",
        },
        {
          id: 5,
          code: "issue",
          numeric: false,
          disablePadding: false,
          label: "Issue",
          show: true,
          width: "56px",
        },
        {
          id: 6,
          code: "supplement",
          numeric: false,
          disablePadding: false,
          label: "Supplement",
          show: true,
          width: "60px",
        },
        {
          id: 7,
          code: "action",
          numeric: false,
          disablePadding: false,
          label: "Action",
          show: true,
        },
      ],
      headCellsInternal: [
        {
          id: 1,
          code: "issueDescription",
          numeric: false,
          disablePadding: false,
          label: "Issue Description",
          show: true,
          width: "260px",
        },
        {
          id: 2,
          code: "quantity",
          numeric: false,
          disablePadding: false,
          label: "Quantity*",
          show: true,
          width: "82px",
        },
        {
          id: 3,
          code: "trackingNumber",
          numeric: false,
          disablePadding: false,
          label: "Tracking Number",
          show: true,
          width: "230px",
        },
        {
          id: 4,
          code: "noOfCartons",
          numeric: false,
          disablePadding: false,
          label: "No Of Cartons",
          show: true,
          width: "230px",
        },
        {
          id: 5,
          code: "actualWeight",
          numeric: false,
          disablePadding: false,
          label: "Actual Weight",
          show: true,
          width: "116px",
        },
        {
          id: 6,
          code: "deliveryDate",
          numeric: false,
          disablePadding: false,
          label: "Delivery Date",
          show: true,
          width: "207px",
        },
        {
          id: 7,
          code: "dhlRate",
          numeric: false,
          disablePadding: false,
          label: "DHL Rate",
          show: true,
          width: "207px",
        },
        {
          id: 8,
          code: "action",
          numeric: false,
          disablePadding: false,
          label: "Action",
          show: true,
          width: "120px",
        },
      ],
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.props.handleSettingHeader("EBLO Order Placement");
    this.props.setSelectedList(
      "publisherBackLabelOpen",
      "pubEbloOrderPlacement"
    );

    getEBLONumberList().then((r) => {
      this.getEBLO(r.data);
    });

    getCustomerForInsertMasterList().then((r) => {
      r &&
        r.data &&
        this.setState({
          customerList:
            r &&
            r.data &&
            r.data
              .filter((x) => x.text.toLowerCase().indexOf("wiley") != -1)
              .sort((a, b) =>
                a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
              ),
        });

      const filtered =
        r &&
        r.data &&
        r.data.reduce(
          (a, o) => (
            o.text.toLowerCase().indexOf("wiley") != -1 && a.push(o.id), a
          ),
          []
        );
      const filteredText =
        r &&
        r.data &&
        r.data.reduce(
          (a, o) => (
            o.text.toLowerCase().indexOf("wiley") != -1 && a.push(o.text), a
          ),
          []
        );
      this.setState({
        customerId: filtered && filtered[0],
        customerName: filteredText && filteredText[0],
      });
    });

    getCountryDropdownList().then((r) => {
      this.getAllCountries(r.data);
    });
  }

  getEBLO = (lpList) => {
    let list = lpList;
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        newRows.push(d.ebloNo);
      });
    this.setState({ ebloList: newRows });
  };

  getAllCountries = (list) => {
    let countryList = [];
    countryList = list && list;
    this.setState({
      countryList:
        countryList &&
        countryList.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleChange = async (e, validate) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name == "eblo") {
      let newErrors = { ...this.state.errors };
      let result = this.state.ebloList.includes(e.target.value);
      newErrors[e.target.name] = result;
      this.setState({
        [e.target.name]: e.target.value,
        errors: newErrors,
      });
    } else if (e.target.name == "number") {
      let newErrors = { ...this.state.errors };
      let result = notrequired50(e.target.value);
      newErrors[e.target.name] = result;
      this.setState({
        [e.target.name]: e.target.value,
        errors: newErrors,
      });
    } else if (e.target.name == "newActualWeight") {
      let weiVal = this.checkDHlWeightDec(e.target.value.toString());
      this.setState({
        [e.target.name]: weiVal,
      });
      if (e.target && e.target.value && this.state.countryId)
        getDHLRateUponAWeight(this.state.countryId, e.target.value).then(r => {
          // console.log(r)
          if (r && r.data) {
            this.setState({ dhlRate: r.data.dhlRate ? r.data.dhlRate : "" });
          }
          else this.setState({ dhlRate: "" });
        })
    } else if (e.target.name == "warehouseEmail") {
      let newRrrors = { ...this.state.errors };
      let result = validate(e.target.value);
      newRrrors[e.target.name] = !result;
      this.setState({ email: e.target.value, errors: newRrrors });
    }
  };
  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };
  handleClose = () => {
    let newZone = { ...this.state.Zone };
    newZone.ZoneCode = "";
    newZone.ZoneName = "";
    this.setState({
      Zone: newZone,
      errors: {},
    });
  };

  handleDoneClicked = (e, i) => {
    let codeTypeCopy = clone(this.state.codeTypeList);
    let reqJournalDetailCopy = this.state.reqJournalDetail;
    let quantityCheck = parseFloat(this.state.newQuantity) < 1;
    codeTypeCopy.map((e, index) => {
      if (index === i) {
        e.quantity = this.state.newQuantity;
        e.trackingNumber = this.state.newTrackingNumber;
        e.actualWeight = this.state.newActualWeight;
        e.deliveryDate = this.state.newDeliveryDate;
        e.dhlRate = this.state.dhlRate
        e.noOfCartons = this.state.noOfCartons
      }
    });
    reqJournalDetailCopy.map((e, index) => {
      if (index === i) {
        e.quantity = this.state.newQuantity;
        e.trackingNumber = this.state.newTrackingNumber;
        e.actualWeight = this.state.newActualWeight;
        e.deliveryDate = this.state.newDeliveryDate;
        e.dhlRate = this.state.dhlRate
        e.noOfCartons = this.state.noOfCartons
      }
    });
    this.setState({
      selectedEditIndex: null,
      isEditClicked: false,
      newQuantity: 0,
      noOfCartons: 0,
      dhlRate: 0,
      quantityCheck: quantityCheck,
      newTrackingNumber: "",
      newActualWeight: 0.0,
      newDeliveryDate: null,
      codeTypeList: codeTypeCopy,
      reqJournalDetail: reqJournalDetailCopy,
    });
  };

  handleCancelClicked = (e, i) => {
    this.setState({
      selectedEditIndex: null,
      isEditClicked: false,
    });
  };

  handleDeleteClicked = (event, index, row) => {
    let journalList = clone(this.state.codeTypeList);
    let nerReqJournalDetail = clone(this.state.reqJournalDetail);
    journalList.splice(index, 1);
    this.setState({
      codeTypeList: journalList,
      reqJournalDetail: nerReqJournalDetail,
    });
  };

  handleEditClicked = (e, i) => {
    this.setState({
      selectedEditIndex: i,
      isEditClicked: true,
      newQuantity: this.state.codeTypeList[i].quantity,
      newTrackingNumber: this.state.codeTypeList[i].trackingNumber,
      newActualWeight: this.state.codeTypeList[i].actualWeight,
      newDeliveryDate: this.state.codeTypeList[i].deliveryDate,
      dhlRate: this.state.codeTypeList[i].dhlRate,
      noOfCartons: this.state.codeTypeList[i].noOfCartons,
    });
  };

  handleAddJournalClick = () => {
    this.setState({
      openDialog: true,
    });
  };

  handleJournalUpdate = (selectedJournalList) => {
    let codeListArry = clone(this.state.codeTypeList);
    let boolVal = codeListArry.filter((r) => {
      return r.issueDescription == selectedJournalList[0].issueDescription;
    });
    let reqJournalDetailArr = this.state.reqJournalDetail;
    if (boolVal.length > 0) {
      this.setState(
        {
          openDialog: false,
          codeTypeList: codeListArry,
        },
        () => {
          alert("Journal already exists in the table");
        }
      );
    } else {
      selectedJournalList.map((journal) => {
        let journalTemp = {
          issueDescription: journal.issueDescription,
          quantity: 0,
          trackingNumber: "",
          actualWeight: 0.0,
          deliveryDate: "",
          journalId: journal.journalID,
          dhlRate: 0,
          noOfCartons: 0
        };
        let reqJournalDetailTemp = {
          jouranlID: journal.journalID,
          jouranlUniqueID: journal.issueDescription,
          quantity: 1,
          isValid: true,
          trackingNumber: null,
          actualWeight: 0.0,
          deliveryDate: "",
          exceptionMessage: null,
          isProcessed: false,
          isCarrierSheetGenerated: false,
          isActive: true,
          detailCreatedBy: localStorage.loggedInId,
          detailModifiedBy: localStorage.loggedInId,
        };
        codeListArry.push(journalTemp);
        reqJournalDetailArr.push(reqJournalDetailTemp);
      });
      if (
        this.state.codeTypeList.length === 0 &&
        selectedJournalList.length > 0
      ) {
        this.setState({
          loading: false,
        });
      }
      this.setState({
        openDialog: false,
        codeTypeList: codeListArry,
        reqJournalDetail: reqJournalDetailArr,
        selectedEditIndex: codeListArry.length - 1,
      });
    }
  };

  handleAddJournalClose = () => {
    this.setState({
      openDialog: false,
    });
  };

  onSwitch = () => {
    this.setState({ deletePopupOpen: true });
  };

  handleDeleteConfirmModalClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleDeleteConfirmModalSwitch = () => {
    if (this.state.isManual) {
      this.setState({
        eblo: "",
        orderCreatedBy: "",
        department: "",
        orderDate: null,
        warehouseEmail: "",
        recipient: "",
        specialInstructions: "",
        deliveryMethod: "",
        deliveryDetails: "",
        addressLine: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        state: "",
        postCode: "",
        PGSCountry: "",
        country: "",
        countryCode: "",
        customerCountryCode: "",
        areaCode: "",
        city: "",
        number: "",
        journalDispatch: "",
        codeTypeList: [],
        errors: {},
      });
    } else {
      this.setState({
        msg: [],
        msgDispArray: [],
        msgDispObj: {},
      });
    }
    this.setState({ isManual: !this.state.isManual, deletePopupOpen: false });
  };

  handleCapture = ({ target }) => {
    const arrayOfObjOld = Object.values(target.files);
    let arrayOfObj = []
    let invalidfilesArr = []

    let testMsgFile = false
    arrayOfObjOld.map(x => {
      if (x.name.toLowerCase().endsWith(".msg")) {
        testMsgFile = true
        if (x.name.toLowerCase().includes("eblo")) {
          arrayOfObj.push(x)
        }
        else {
          invalidfilesArr.push({
            name: x.name,
            size: x.size,
            message: "File name must contain EBLO",
          })
        }
      }
      else {
        toastr.error("Only .msg files can be uploaded !")
      }
    })
    if (!testMsgFile)
      return
    let msgArray = this.state.msg;
    let msgDispArray = this.state.msgDispArray;
    let msgDispObj = this.state.msgDispObj;
    let arrayOfObjFiltered = arrayOfObj.filter(
      (x) => !msgArray.filter((y) => y.name === x.name).length
    );
    this.setState({ msg: [...this.state.msg, ...arrayOfObjFiltered] }, () => {
      this.state.msg.map((file, i) => {
        msgDispArray[i] = msgDispArray[i] || "Uploaded Successfully";
        msgDispObj[file] = msgDispArray[file] || "Uploaded Successfully";
      });
      this.setState({
        msgDispArray: msgDispArray,
        msgDispObj: msgDispObj,
      });

      let files = [...this.state.msg];
      let _error = "";

      if (files.length > 0) {
        let customerId = this.state.customerId;
        let createdBy = window.localStorage.loggedInId;
        let modifiedBy = window.localStorage.loggedInId;
        let customerName = this.state.customerName;
        let formData = new FormData();
        let stf = [];
        let secondCheck = false

        Array.from(files).map((f) => {
          if (!f.apiCallNotRequired) {
            secondCheck = true
            formData.append("Uploadfiles", f);
            stf.push({
              name: f.name,
              size: f.size,
              message: "",
            });
          }
        });

        formData.append("CustomerID", customerId);
        formData.append("CreatedBy", createdBy);
        formData.append("ModifiedBy", modifiedBy);
        formData.append("CustomerName", customerName);
        if (secondCheck)
          validateEmailEBLO(formData).then((r) => {
            let msgDispArray = [];
            let msgDispObj = {};
            r.data.map((val, i) => {
              if (val.exceptionMessage == "") {
                msgDispArray[i] = "Uploaded Successfully";
                msgDispObj["expMsg"] = "Uploaded Successfully";
              } else {
                msgDispArray[i] = val.exceptionMessage;
                msgDispObj["expMsg"] = val.exceptionMessage;
              }
            });
            this.setState({
              msgDispArray: msgDispArray,
              msgDispObj: msgDispObj,
              // msg: r.data,

            });
          });
      }
      let xp = []
      let msgDispArray2 = this.state.msgDispArray
      let msgDispObj2 = this.state.msgDispObj
      let filteredXP = invalidfilesArr.filter(
        (x) => !msgArray.filter((y) => y.name === x.name).length
      );
      filteredXP.length > 0 && filteredXP.map(sd => {
        xp.push({
          name: sd.name,
          size: sd.size,
          message: sd.message,
          apiCallNotRequired: true
        });
        msgDispArray2.push("File name must contain EBLO")
        msgDispObj2["expMsg"] = "File name must contain EBLO"
      })
      let pc = [...this.state.msg, ...xp]
      this.setState({ msg: pc, msgDispArray: msgDispArray2, msgDispObj: msgDispObj2 })
    });
  };

  deleteSelectedFile = (e, i, id) => {
    e.persist();
    this.myRef.current.value = "";
    let msgDispArray = [...this.state.msgDispArray];
    let msgDispObj = this.state.msgDispObj;
    let newStateFiles = [...this.state.msg];
    newStateFiles.splice(i, 1);
    msgDispArray.splice(i, 1);
    delete msgDispObj[id.name];
    this.setState({
      msg: newStateFiles,
      msgDispArray: msgDispArray,
      msgDispObj: msgDispObj,
    });
  };

  handleAutocompleteCustomer = (e, val) => {
    this.setState({ customerId: val && val.id, customer: val && val });
  };

  handleAutocompleteCountry = (e, val) => {
    // this.state.PGSCountry ? this.setState({
    //   countryId: val && val.id,
    //   PGSCountry: val && val,
    //   countryCode: val.value,
    // }) : this.setState({
    //   countryId: "",
    //   PGSCountry: "",
    //   countryCode: "",
    // })
    val
      ? this.setState({
        countryId: val && val.id,
        PGSCountry: val && val,
        countryCode: val.value,
      })
      : this.setState({
        countryId: "",
        PGSCountry: "",
        countryCode: "",
      });


  };

  handleSubmit = (event, manual) => {
    const {
      customersList,
      customer,
      eblo,
      orderCreatedBy,
      department,
      orderDate,
      warehouseEmail,
      recipient,
      specialInstructions,
      deliveryMethod,
      deliveryDetails,
      addressLine,
      addressLine1,
      addressLine2,
      addressLine3,
      state,
      postCode,
      countryList,
      PGSCountry,
      country,
      countryCode,
      countryId,
      customerCountryCode,
      areaCode,
      city,
      number,
      journalDispatch,
      codeTypeList,
      page,
      rowsPerPage,
      order,
      orderBy,
      openDialog,
      isManual,
      msg,
      selectedEditIndex,
      isEditClicked,
      reqJournalDetail,
    } = this.state;

    let ebloNo = eblo;
    let customerID = this.state.customerId;
    let companyName = null;
    // let city = null;
    let countryID = countryId;
    let isActive = true;
    let createdBy = localStorage.loggedInId;
    let modifiedBy = localStorage.loggedInId;
    // let countryName =
    //   this.state.PGSCountry &&
    //   this.state.PGSCountry.text &&
    //   this.state.PGSCountry.text.split("- ")[1];
    let countryName = country;
    let jArr = [];
    codeTypeList.map((r) => {
      jArr.push({
        actualWeight: r.actualWeight,
        deliveryDate: r.deliveryDate,
        jouranlUniqueID: r.issueDescription,
        quantity: r.quantity,
        trackingNumber: r.trackingNumber,
        dhlRate: r.dhlRate,
        noOfCartons: r.noOfCartons,
        jouranlID: r.journalId,
        isActive: true,
      });
    });
    let reqbody1 = {
      customerID,
      isManual,
      ebloNo,
      orderCreatedBy,
      department,
      orderDate,
      warehouseEmail,
      recipient,
      specialInstructions,
      deliveryMethod,
      deliveryDetails,
      companyName,
      addressLine: addressLine1,
      addressLine2,
      addressLine3,
      state,
      postCode,
      countryID,
      countryCode: customerCountryCode,
      areaCode,
      city,
      number,
      journalDispatch,
      isActive,
      createdBy,
      modifiedBy,
      countryName,
      // createEBLOOrderDetailRequest: reqJournalDetail,
      createEBLOOrderDetailRequest: jArr,
    };

    if (manual) {
      postEbloList(reqbody1, this.props.history);
    } else {
      let files = [...this.state.msg];
      let _error = "";

      if (files.length > 0) {
        let loggedInId = localStorage.getItem("loggedInId");
        let formData = new FormData();
        let stf = [];
        Array.from(files).map((f) => {
          formData.append("Uploadfiles", f);
          stf.push({
            name: f.name,
            size: f.size,
            message: "",
          });
        });

        formData.append("CustomerID", customerID);
        formData.append("CreatedBy", createdBy);
        formData.append("ModifiedBy", modifiedBy);
        formData.append("CustomerName", this.state.customerName);

        postEbloMailList(formData, this.props.history).then((r) => {
          // let msgDispArray = [];
          // r.data.map((val, i) => {
          //   if (val.exceptionMessage == "")
          //     msgDispArray[i] = "Uploaded Successfully";
          //   else msgDispArray[i] = val.exceptionMessage;
          // });
          // this.setState({
          //   msgDispArray: msgDispArray,
          // });
        });
      }
    }
  };

  _onMobileNumberKeyPress(event) {
    const re = /[0-9+() -]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertKeyPress(event) {
    const re = /[a-zA-Z0-9-#]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextKeyPress(event) {
    const re = /[a-z A-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberOnlyKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusNumberKeyPress(event) {
    const re = /[0-9 a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onWeightKeyPress(event) {
    const re = /[0-9 a-zA-Z.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertSizeKeyPress(event) {
    const re = /[0-9 a-zA-Z-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusAllPress(event) {
    const re = /[a-z A-Z0-9=.,;()&@]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, 5);
    }
    return value;
  }
  checkInvalidFiles = () => {
    let valid = false
    this.state.msg && this.state.msg.length > 0 && this.state.msg.map(mg => {
      if (mg.apiCallNotRequired)
        valid = true
    })
    return valid
  }
  render() {
    let { classes } = this.props;
    // console.log(this.state.codeTypeList)
    let {
      customersList,
      customer,
      eblo,
      orderCreatedBy,
      department,
      orderDate,
      warehouseEmail,
      recipient,
      specialInstructions,
      deliveryMethod,
      deliveryDetails,
      addressLine,
      state,
      postCode,
      countryList,
      PGSCountry,
      country,
      countryCode,
      customerCountryCode,
      areaCode,
      city,
      number,
      journalDispatch,
      codeTypeList,
      page,
      rowsPerPage,
      order,
      orderBy,
      openDialog,
      isManual,
      msg,
      selectedEditIndex,
      isEditClicked,
    } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create EBLO Order Placement</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <div className={classes.container}>
                <div style={{ display: "flex" }}>
                  <TextField
                    inputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                    label="Customer"
                    name="customer"
                    className={classes.textField}
                    value={this.state.customerName}
                    margin="normal"
                  />
                  <div
                    className={classes.textField}
                    style={{ alignSelf: "flex-end", margin: "16px 16px 8px 0" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => this.onSwitch()}
                      style={{
                        height: "auto",
                        display: " inline-block",
                        width: "100%",
                        verticalAlign: "middle",
                        boxShadow: "none",
                      }}
                    >
                      {isManual ? "File Upload Mode" : "Manual Mode"}
                    </Button>
                  </div>
                  <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    onClose={this.handleDeleteConfirmModalClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.deletePopupOpen}
                    maxWidth="lg"
                  >
                    <DialogTitle id="customized-dialog-title">
                      Switch to {isManual ? "File Upload Mode" : "Manual Mode"}
                    </DialogTitle>
                    <DialogContent dividers>
                      <div style={{ minWidth: "300px" }}>
                        Are you sure you want to switch to{" "}
                        {isManual ? "File Upload Mode" : "Manual Mode"} ?
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={this.handleDeleteConfirmModalClose}
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        Close
                      </Button>
                      <Button
                        onClick={this.handleDeleteConfirmModalSwitch}
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        Switch
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <div className={classes.emptyField}></div>
                  <div
                    className={[classes.emptyField, classes.lastChild].join(
                      " "
                    )}
                  ></div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    style={
                      !isManual
                        ? {
                          backgroundColor: "#cccccc",
                          color: "#515151",
                          borderRadius: "4px",
                          padding: ".3em 1em .3em 1em",
                          width: "122px",
                          height: "24px",
                          marginTop: "1.5em",
                          border: "1px dashed #3982ea",
                          backgroundColor: "white",
                          cursor: "pointer",
                        }
                        : {
                          backgroundColor: "#cccccc",
                          color: "#515151",
                          borderRadius: "4px",
                          padding: ".3em 1em .3em 1em",
                          width: "122px",
                          height: "24px",
                          marginTop: "1.5em",
                          border: "1px dashed #9e9e9e",
                          backgroundColor: "#9e9e9e",
                        }
                    }
                  >
                    <label
                      className="upload excel"
                      style={!isManual ? { cursor: "pointer" } : {}}
                    >
                      <input
                        disabled={!isManual ? false : true}
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => this.handleCapture(e)}
                        accept=".msg"
                        multiple={true}
                        ref={this.myRef}
                      />
                      <div
                        style={
                          !isManual
                            ? { display: "flex", alignItems: "center" }
                            : { display: "flex", alignItems: "center" }
                        }
                      >
                        <AddCircleIcon
                          style={
                            !isManual
                              ? {
                                color: "rgb(57, 130, 234)",
                                marginLeft: "-10px",
                              }
                              : { color: "#FFF", marginLeft: "-10px" }
                          }
                        />{" "}
                        <span
                          style={
                            !isManual
                              ? { marginLeft: "2px", fontWeight: "500" }
                              : {
                                marginLeft: "2px",
                                fontWeight: "500",
                                color: "#fff",
                              }
                          }
                        >
                          Upload E-Mail
                        </span>
                      </div>
                    </label>
                  </div>
                  <div
                    style={
                      !isManual
                        ? {
                          padding: ".3em 1em .3em 1em",
                          marginTop: "1.5em",
                          marginLeft: "1em",
                          borderRadius: "4px",
                          boxShadow:
                            "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                        }
                        : {
                          background: "#9F9F9F",
                          padding: ".3em 1em .3em 1em",
                          marginTop: "1.5em",
                          marginLeft: "1em",
                          borderRadius: "4px",
                          boxShadow:
                            "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                        }
                    }
                  >
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <InfoIcon
                          style={
                            !isManual
                              ? { color: "#3982ea", marginLeft: "-10px" }
                              : { color: "#FFF", marginLeft: "-10px" }
                          }
                        />{" "}
                        <span
                          style={
                            !isManual
                              ? {
                                marginLeft: "4px",
                                fontSize: "11px",
                                color: "gray",
                              }
                              : {
                                marginLeft: "4px",
                                fontSize: "11px",
                                color: "#FFF",
                              }
                          }
                        >
                          Upload outlook email .msg file only
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "30px" }}>
                  <div>
                    {msg.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          margin: "1em",
                          paddingBottom: ".5em",
                          borderBottom: "1px solid #eaeaea",
                          fontSize: "14px",
                          marginLeft: "0px",
                          marginRight: "0px",
                        }}
                      >
                        <div style={{ width: "30%", fontWeight: "500" }}>
                          File Info
                        </div>
                        <div
                          style={{
                            width: "10%",
                            fontWeight: "500",
                            margin: "0 10px",
                          }}
                        >
                          Size
                        </div>
                        <div style={{ width: "45%", fontWeight: "500" }}>
                          Detection
                        </div>
                        <div style={{ width: "10%", fontWeight: "500" }}>
                          Action
                        </div>
                      </div>
                    )}

                    {msg.map((file, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            margin: "1em",
                            paddingBottom: ".5em",
                            borderBottom: "1px solid #eaeaea",
                            fontSize: "12px",
                            marginLeft: "0px",
                            marginRight: "0px",
                          }}
                        >
                          <div style={{ width: "30%", fontWeight: "500" }}>
                            <div style={{ display: "flex" }}>
                              <DescriptionIcon
                                style={{
                                  color: "#306dca",
                                  marginTop: "-4px",
                                  marginRight: "5px",
                                }}
                              />{" "}
                              <span> {file.name}</span>


                            </div>
                          </div>
                          <div style={{ width: "10%", margin: "0 10px" }}>
                            {Math.ceil(file.size / 1024) + " kb"}
                            {/* {file.fileSize} */}
                          </div>
                          <DetectionControl
                            message={file.message ? file.message : "Uploaded Successfully"}
                          />
                          {/* {this.state.msgDispObj["expMsg"] ? (
                            <DetectionControl
                              // message={this.state.msgDispArray[i]}
                              message={this.state.msgDispObj["expMsg"]}
                            />
                          ) : (
                              <LinearProgress style={{ width: "45%" }} />
                            )} */}
                          <IconButton
                            size="small"
                            style={{ marginTop: "-7px", width: "4%" }}
                            onClick={(e) => {
                              this.deleteSelectedFile(e, i, msg[i]);
                            }}
                          >
                            <DeleteIcon style={{ fontSize: "16px" }} />
                          </IconButton>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {this.state.isManual && (
                  <React.Fragment>
                    {" "}
                    <p
                      className={classes.subHeader}
                      style={{ fontWeight: "500" }}
                    >
                      Manual EBLO Order Placement
                    </p>
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                      <TextField
                        disabled={isManual ? false : true}
                        required
                        label="EBLO#"
                        name="eblo"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._onInsertKeyPress}
                        margin="normal"
                        className={classes.textField}
                        value={eblo}
                        onChange={this.handleChange}
                        error={this.state.errors.eblo}
                        helperText={
                          this.state.errors.eblo
                            ? resourceValidation.ebloNumber
                            : ""
                        }
                      />
                      <TextField
                        disabled={isManual ? false : true}
                        label="Order Created By"
                        name="orderCreatedBy"
                        inputProps={{
                          maxLength: 100,
                        }}
                        multiline
                        rowsMax="4"
                        onKeyPress={this._onTextPlusAllPress}
                        margin="normal"
                        className={classes.textField}
                        value={orderCreatedBy}
                        onChange={this.handleChange}
                      />
                      <TextField
                        disabled={isManual ? false : true}
                        label="Department"
                        name="department"
                        inputProps={{
                          maxLength: 100,
                        }}
                        multiline
                        rowsMax="4"
                        onKeyPress={this._onTextPlusAllPress}
                        margin="normal"
                        className={classes.textField}
                        value={department}
                        onChange={this.handleChange}
                      />
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          autoOk
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          minDateMessage={""}
                          name="orderDate"
                          className={[
                            classes.textField,
                            classes.lastChild,
                          ].join(" ")}
                          id="orderDate"
                          label="Order Date"
                          value={orderDate}
                          onChange={(e) =>
                            this.handleDateChange(e, "orderDate")
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          disabled={isManual ? false : true}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        disabled={isManual ? false : true}
                        label="Warehouse Email"
                        name="warehouseEmail"
                        inputProps={{
                          maxLength: 50,
                        }}
                        //onKeyPress={this._onTextPlusAllPress}
                        margin="normal"
                        className={classes.textField}
                        value={warehouseEmail}
                        onChange={(e) => this.handleChange(e, emailValidator)}
                      // error={this.state.errors.warehouseEmail}
                      // helperText={
                      //   this.state.errors.warehouseEmail
                      //     ? resourceValidation.validEmailId
                      //     : ""
                      // }
                      />
                      <TextField
                        disabled={isManual ? false : true}
                        required
                        label="Recipient"
                        name="recipient"
                        inputProps={{
                          maxLength: 50,
                        }}
                        multiline
                        rowsMax="4"
                        onKeyPress={this._onTextPlusAllPress}
                        margin="normal"
                        className={classes.textField}
                        value={recipient}
                        onChange={this.handleChange}
                      />
                      <TextField
                        disabled={isManual ? false : true}
                        label="Special Instructions"
                        name="specialInstructions"
                        inputProps={{
                          maxLength: 100,
                        }}
                        multiline
                        rowsMax="4"
                        onKeyPress={this._onTextPlusAllPress}
                        margin="normal"
                        className={classes.textField}
                        value={specialInstructions}
                        style={{ marginTop: "13px" }}
                        multiline
                        rowsMax="4"
                        onChange={this.handleChange}
                      />
                      <TextField
                        disabled={isManual ? false : true}
                        label="Delivery Method"
                        name="deliveryMethod"
                        inputProps={{
                          maxLength: 100,
                        }}
                        multiline
                        rowsMax="4"
                        onKeyPress={this._onTextPlusAllPress}
                        margin="normal"
                        className={[classes.textField, classes.lastChild].join(
                          " "
                        )}
                        value={deliveryMethod}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        disabled={isManual ? false : true}
                        label="Delivery Details"
                        name="deliveryDetails"
                        inputProps={{
                          maxLength: 100,
                        }}
                        multiline
                        rowsMax="4"
                        onKeyPress={this._onTextPlusAllPress}
                        margin="normal"
                        className={classes.textField}
                        value={deliveryDetails}
                        onChange={this.handleChange}
                      />
                      <TextField
                        disabled={isManual ? false : true}
                        required={!this.state.addressLine1 && !this.state.addressLine2 && !this.state.addressLine3}
                        label="Address Line 1"
                        name="addressLine1"
                        inputProps={{
                          maxLength: 100,
                        }}
                        multiline
                        rowsMax="4"
                        onKeyPress={this._onTextPlusAllPress}
                        margin="normal"
                        className={classes.textField}
                        value={this.state.addressLine1}
                        onChange={this.handleChange}
                      />
                      <TextField
                        disabled={isManual ? false : true}
                        required={!this.state.addressLine1 && !this.state.addressLine2 && !this.state.addressLine3}
                        label="Address Line 2"
                        name="addressLine2"
                        inputProps={{
                          maxLength: 100,
                        }}
                        multiline
                        rowsMax="4"
                        onKeyPress={this._onTextPlusAllPress}
                        margin="normal"
                        className={classes.textField}
                        value={this.state.addressLine2}
                        onChange={this.handleChange}
                      />
                      <TextField
                        disabled={isManual ? false : true}
                        required={!this.state.addressLine1 && !this.state.addressLine2 && !this.state.addressLine3}
                        label="Address Line 3"
                        name="addressLine3"
                        inputProps={{
                          maxLength: 100,
                        }}
                        multiline
                        rowsMax="4"
                        onKeyPress={this._onTextPlusAllPress}
                        margin="normal"
                        // className={classes.textField}
                        className={[classes.textField, classes.lastChild].join(
                          " "
                        )}
                        value={this.state.addressLine3}
                        onChange={this.handleChange}
                      />

                    </div>
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        disabled={isManual ? false : true}
                        label="State"
                        name="state"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._onTextPlusAllPress}
                        margin="normal"
                        className={classes.textField}
                        value={state}
                        onChange={this.handleChange}
                      />
                      <TextField
                        disabled={isManual ? false : true}
                        required
                        label="Post Code"
                        name="postCode"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._onNumberKeyPress}
                        margin="normal"

                        className={classes.textField}
                        value={postCode}
                        onChange={this.handleChange}
                      />
                      <Autocomplete
                        autoComplete
                        includeInputInList
                        id={"country-1"}
                        name="PGSCountry"
                        value={PGSCountry}
                        className={[
                          classes.autoComplete,
                          classes.textField,
                        ].join(" ")}
                        options={countryList}
                        getOptionLabel={(option) => option.text}
                        disabled={isManual ? false : true}
                        onChange={this.handleAutocompleteCountry}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="PGS Country"
                            className={classes.autocompleteText}
                            fullWidth={true}
                            margin="normal"
                            required
                          />
                        )}
                      />
                      <TextField
                        disabled={isManual ? false : true}
                        required
                        label="Customer Country"
                        name="country"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._onTextPlusAllPress}
                        margin="normal"
                        // className={classes.textField}
                        className={[classes.textField, classes.lastChild].join(
                          " "
                        )}
                        value={country}
                        onChange={this.handleChange}
                      />

                    </div>
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        disabled={isManual ? false : true}
                        label="Country Code"
                        name="customerCountryCode"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._onTextKeyPress}
                        margin="normal"
                        className={classes.textField}
                        value={customerCountryCode}
                        onChange={this.handleChange}
                      />
                      <TextField
                        disabled={isManual ? false : true}
                        label="Area Code"
                        name="areaCode"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._onTextPlusAllPress}
                        margin="normal"
                        className={classes.textField}


                        value={areaCode}
                        onChange={this.handleChange}
                      />
                      <TextField
                        disabled={isManual ? false : true}
                        label="City"
                        name="city"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onKeyPress={this._onTextPlusAllPress}
                        margin="normal"
                        className={classes.textField}


                        value={city}
                        onChange={this.handleChange}
                      />
                      <TextField
                        disabled={isManual ? false : true}
                        label="Phone Number"
                        name="number"
                        inputProps={{
                          maxLength: 20,
                        }}
                        onKeyPress={this._onMobileNumberKeyPress}
                        margin="normal"
                        className={[classes.textField, classes.lastChild].join(
                          " "
                        )}
                        value={number}
                        onChange={this.handleChange}
                      // error={this.state.errors.number}
                      // helperText={
                      //   this.state.errors.phoneNumber
                      //     ? resourceValidation.validPhoneNo
                      //     : ""
                      // }
                      />

                      {/* <div
                        className={[classes.emptyField, classes.lastChild].join(
                          " "
                        )}
                      ></div> */}
                    </div>
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        disabled={isManual ? false : true}
                        label="Journal Dispatch"
                        name="journalDispatch"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onKeyPress={this._onTextPlusAllPress}
                        margin="normal"
                        className={classes.textField}
                        value={journalDispatch}
                        style={{ maxWidth: "275px" }}
                        multiline
                        rowsMax="4"
                        onChange={this.handleChange}
                        className={classes.textField}

                      />
                    </div>
                    <div style={{ marginTop: "2%" }}>
                      <p className={classes.subHeader}>Item Detail</p>
                      <div
                        style={{
                          display: "flex",
                          border: "1px solid #eaeaea",
                          borderBottom: "none",
                          borderRadius: "4px",
                          boxShadow: "0px 7px 6px #ddd",
                        }}
                      >
                        <Typography
                          component="div"
                          style={{
                            width: "100%",
                            padding: "10px",
                          }}
                        >
                          <Button
                            disabled={isManual ? false : true}
                            variant="contained"
                            color="primary"
                            style={{
                              boxShadow: "none",
                              padding: "5px 10px 5px 4px",
                              fontSize: "11px",
                              boxShadow: "none",
                            }}
                            onClick={this.handleAddJournalClick}
                          >
                            <CustomSmallAddIcon style={{ fontSize: "18px" }} />{" "}
                            Add Journal
                          </Button>
                        </Typography>
                      </div>
                      {openDialog ? (
                        <IndividualSearchAddDialog
                          title="Journal Pick Up"
                          showDialog={this.state.openDialog}
                          handleAddJournalClose={this.handleAddJournalClose}
                          handleJournalUpdate={this.handleJournalUpdate}
                        />
                      ) : null}
                      <div
                        style={{
                          maxHeight: "300px",
                          overflowY: "auto",
                          boxShadow: "0px 3px 4px #ddd",
                        }}
                      >
                        <Table
                          stickyHeader
                          aria-labelledby="tableTitle"
                          size="small"
                          aria-label="enhanced table"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            headCells={this.state.headCellsInternal}
                          />
                          <TableBody style={{ overflow: "hidden" }}>
                            {codeTypeList.length > 0 ? (
                              stableSort(
                                codeTypeList,
                                getSorting(order, orderBy)
                              )
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                  return (
                                    <TableRow
                                      hover
                                      tabIndex={-1}
                                      style={
                                        selectedEditIndex !== index
                                          ? { cursor: "pointer" }
                                          : {
                                            cursor: "pointer",
                                            background: "#ccc",
                                          }
                                      }
                                    >
                                      {this.state.headCellsInternal[0].show && (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-cell"
                                          align="left"
                                        >
                                          {row.issueDescription &&
                                            row.issueDescription.length > 16 ? (
                                              <Tooltip
                                                title={row.issueDescription}
                                                aria-label="add"
                                              >
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.issueDescription}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.issueDescription}
                                              </Typography>
                                            )}
                                        </TableCell>
                                      )}
                                      {selectedEditIndex !== index ? (
                                        this.state.headCellsInternal[1]
                                          .show && (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-cell"
                                            align="right"
                                          >
                                            {row.quantity ? row.quantity : ""}
                                          </TableCell>
                                        )
                                      ) : (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-cell"
                                          >
                                            <TextField
                                              autoFocus
                                              margin="dense"
                                              id="name"
                                              autoComplete="off"
                                              name="newQuantity"
                                              onKeyPress={
                                                this._onNumberOnlyKeyPress
                                              }
                                              onChange={this.handleChange}
                                              value={this.state.newQuantity}
                                              placeholder="Quantity"
                                            />
                                          </TableCell>
                                        )}
                                      {selectedEditIndex !== index ? (
                                        this.state.headCellsInternal[2]
                                          .show && (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-cell"
                                            align="left"
                                          >
                                            {row.trackingNumber &&
                                              row.trackingNumber.length > 16 ? (
                                                <Tooltip
                                                  title={row.trackingNumber}
                                                  aria-label="add"
                                                >
                                                  <Typography
                                                    noWrap
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    {row.trackingNumber}
                                                  </Typography>
                                                </Tooltip>
                                              ) : (
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.trackingNumber}
                                                </Typography>
                                              )}
                                          </TableCell>
                                        )
                                      ) : (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-cell"
                                          >
                                            <TextField
                                              margin="dense"
                                              id="name"
                                              autoComplete="off"
                                              name="newTrackingNumber"
                                              onChange={this.handleChange}
                                              value={this.state.newTrackingNumber}
                                              placeholder="Tracking Number"
                                            />
                                          </TableCell>
                                        )}
                                      {selectedEditIndex !== index ? (
                                        this.state.headCellsInternal[3]
                                          .show && (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-cell"
                                            align="left"
                                          >
                                            {row.noOfCartons &&
                                              row.noOfCartons.length > 16 ? (
                                                <Tooltip
                                                  title={row.noOfCartons}
                                                  aria-label="add"
                                                >
                                                  <Typography
                                                    noWrap
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    {row.noOfCartons}
                                                  </Typography>
                                                </Tooltip>
                                              ) : (
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.noOfCartons}
                                                </Typography>
                                              )}
                                          </TableCell>
                                        )
                                      ) : (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-cell"
                                          >
                                            <TextField
                                              margin="dense"
                                              id="name"
                                              autoComplete="off"
                                              name="noOfCartons"
                                              onChange={this.handleChange}
                                              value={this.state.noOfCartons}
                                              placeholder="No Of Cartons"
                                            />
                                          </TableCell>
                                        )}
                                      {selectedEditIndex !== index ? (
                                        this.state.headCellsInternal[4]
                                          .show && (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-cell"
                                            align="right"
                                          >
                                            {row.actualWeight && row.actualWeight
                                              ? parseFloat(
                                                row.actualWeight
                                              ).toFixed(3)
                                              : "0.000"}
                                          </TableCell>
                                        )
                                      ) : (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-cell"
                                          >
                                            <TextField
                                              margin="dense"
                                              id="name"
                                              autoComplete="off"
                                              name="newActualWeight"
                                              onChange={this.handleChange}
                                              value={this.state.newActualWeight}
                                              placeholder="Actual Weight"
                                            />
                                          </TableCell>
                                        )}

                                      {selectedEditIndex !== index ? (
                                        this.state.headCellsInternal[5]
                                          .show && (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-cell"
                                            align="left"
                                          >
                                            {row.deliveryDate &&
                                              row.deliveryDate.length > 16 ? (
                                                <Tooltip
                                                  title={moment(
                                                    row.deliveryDate
                                                  ).format("DD/MM/YYYY")}
                                                  aria-label="add"
                                                >
                                                  <Typography
                                                    noWrap
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    {moment(
                                                      row.deliveryDate
                                                    ).format("DD/MM/YYYY")}
                                                  </Typography>
                                                </Tooltip>
                                              ) : (
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.deliveryDate
                                                    ? moment(
                                                      row.deliveryDate
                                                    ).format("DD/MM/YYYY")
                                                    : ""}
                                                </Typography>
                                              )}
                                          </TableCell>
                                        )
                                      ) : (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-cell"
                                          >
                                            <MuiPickersUtilsProvider
                                              utils={DateFnsUtils}
                                            >
                                              <KeyboardDatePicker
                                                autoOk
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                minDateMessage={""}
                                                name="newDeliveryDate"
                                                id="newDeliveryDate"
                                                value={this.state.newDeliveryDate}
                                                onChange={(e) =>
                                                  this.handleDateChange(
                                                    e,
                                                    "newDeliveryDate"
                                                  )
                                                }
                                                KeyboardButtonProps={{
                                                  "aria-label": "change date",
                                                }}
                                              />
                                            </MuiPickersUtilsProvider>
                                          </TableCell>
                                        )}
                                      {selectedEditIndex !== index ? (
                                        this.state.headCellsInternal[6]
                                          .show && (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-cell"
                                            align="left"
                                          >
                                            {row.dhlRate &&
                                              row.dhlRate.length > 16 ? (
                                                <Tooltip
                                                  title={row.dhlRate}
                                                  aria-label="add"
                                                >
                                                  <Typography
                                                    noWrap
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    {row.dhlRate}
                                                  </Typography>
                                                </Tooltip>
                                              ) : (
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.dhlRate}
                                                </Typography>
                                              )}
                                          </TableCell>
                                        )
                                      ) : (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-cell"
                                          >
                                            {this.state.dhlRate}
                                          </TableCell>
                                        )}
                                      {this.state.headCellsInternal[7].show && (
                                        <TableCell
                                          className="table-content-cell"
                                          style={{ textAlign: "center" }}
                                        >
                                          {selectedEditIndex !== index ? (
                                            <div>
                                              <Tooltip title="Edit">
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                  onClick={(e) =>
                                                    this.handleEditClicked(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <EditIcon
                                                    style={{
                                                      color: "#6c6b6b",
                                                    }}
                                                  />
                                                </span>
                                              </Tooltip>
                                              <Tooltip title="Delete">
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                  onClick={(e) =>
                                                    this.handleDeleteClicked(
                                                      e,
                                                      index,
                                                      row
                                                    )
                                                  }
                                                >
                                                  <DeleteIcon
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#6c6b6b",
                                                    }}
                                                  />
                                                </span>
                                              </Tooltip>
                                            </div>
                                          ) : (
                                              <div>
                                                {this.state.newQuantity > 0 ? (
                                                  <Tooltip title="Done">
                                                    <span
                                                      style={{
                                                        cursor: "pointer",
                                                        color: "#6c6b6b",
                                                      }}
                                                      onClick={(e) =>
                                                        this.handleDoneClicked(
                                                          e,
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <DoneIcon
                                                        style={{
                                                          color: "#6c6b6b",
                                                        }}
                                                      />
                                                    </span>
                                                  </Tooltip>
                                                ) : null}
                                                <Tooltip title="Cancel">
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#6c6b6b",
                                                    }}
                                                    onClick={(e) =>
                                                      this.handleCancelClicked(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <CloseIcon
                                                      style={{
                                                        cursor: "pointer",
                                                        color: "#6c6b6b",
                                                      }}
                                                    />
                                                  </span>
                                                </Tooltip>
                                              </div>
                                            )}
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  );
                                })
                            ) : (
                                <TableRow>
                                  {this.state.loading ? (
                                    <TableCell
                                      colSpan={15}
                                      style={{ textAlign: "center" }}
                                    >
                                      <LinearProgress />
                                    </TableCell>
                                  ) : (
                                      <TableCell
                                        colSpan={10}
                                        style={{ textAlign: "center" }}
                                      >
                                        No result found
                                      </TableCell>
                                    )}
                                </TableRow>
                              )}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <Divider style={{ margin: "25px 0 5px" }} />

                <div
                  className="button-wrapper"
                  style={{ paddingLeft: "0px", marginLeft: "0px" }}
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/pubEbloOrderPlacement")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.isManual ? (
                      <Button
                        disabled={
                          !this.state.msg.length ||
                            Object.values(this.state.msgDispObj).includes(
                              "File is already Present."
                            ) || this.checkInvalidFiles()
                            ? true
                            : false
                        }
                        autoFocus
                        color="primary"
                        variant="contained"
                        style={{ boxShadow: "none" }}
                        onClick={(e) => this.handleSubmit(e, false)}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          disabled={
                            !this.state.eblo ||
                              this.state.errors.eblo ||
                              //this.state.errors.warehouseEmail ||
                              !this.state.recipient ||
                              (!this.state.addressLine1 && !this.state.addressLine2 && !this.state.addressLine3) ||
                              !this.state.postCode ||
                              !this.state.PGSCountry ||
                              !this.state.country ||
                              this.state.selectedEditIndex !== null ||
                              this.state.codeTypeList.length === 0 ||
                              this.state.quantityCheck ||
                              (this.state.codeTypeList.length > 0
                                ? this.state.codeTypeList[0]["quantity"] <= 0
                                : 1)
                              ? true
                              : false
                          }
                          autoFocus
                          color="primary"
                          variant="contained"
                          style={{ boxShadow: "none" }}
                          onClick={(e) => this.handleSubmit(e, true)}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(AddEBLOOrderPlacement));
