import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import {
  getWareLocationDD,
  getCountryDropdownList,
} from "../../masters/masterActions";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { StateContext } from "../../../shared/globalState";
import { styles } from "../../../shared/styles";
import { resourceValidation } from "../../../shared/resource";
import { getOrderRecepientList, getStatusDropdownList } from "./action";

const notRequiredAlpNum10 = new Validator("notRequiredAlpNum10");
const notRequired100AllAllow = new Validator("notRequired100AllAllow");
const maxlength10Alphanumaric = new Validator("maxlength10Alphanumaric");

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        ></IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
class EmloStockinAdvanceSearch extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    deliveryID: 0,
    deliveryAddress: "",
    location: {},
    bpaCode: "",
    recipientList: [],
    deliveryCountryDD: [],
    statusList: [],
    status: "",
    StatusID: 0,
    deliveryCountryID: 0,
    deliveryCountry: "",
    trackingNumber: "",
    modifiedDateFrom: null,
    modifiedDateTo: null,
  };

  componentDidMount() {
    const [{ store }, dispatch] = this.context;
    const userID = localStorage.loggedInId;
    getOrderRecepientList(userID).then((r) => {
      this.setState({
        recipientList:
          r &&
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });
    getCountryDropdownList().then((r) => {
      if (r) {
        this.setState({
          deliveryCountryDD:
            r &&
            r.data &&
            r.data.sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
      }
    });
    getStatusDropdownList().then((r) => {
      if (r) {
        this.setState({
          statusList:
            r &&
            r.data &&
            r.data.sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
      }
    });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      open: false,
      location: {},
      bpaCode: "",
      deliveryID: "",
      deliveryAddress: "",
      modifiedDateFrom: null,
      modifiedDateTo: null,
      trackingNumber: "",
      locationId: "",
    });
  };
  _onKeyPressNoOnly(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  onInputChanged = (e, name, type) => {
    let newError = { ...this.state.errors };
    this.setState({ [name]: e.target.value });
  };

  handleAutocompleteStatus = (e, val) => {
    this.setState({ locationId: val && val.id, location: val && val });
  };

  onSearchClicked = () => {
    let reqBody = {};

    reqBody = {
      StatusID: this.state.StatusID,
      CountryID: this.state.deliveryCountryID,
      bpaCode: this.state.bpaCode,
      bpaReceipientID: this.state.deliveryID,
      modifiedDateTo: this.state.modifiedDateTo || "",
      modifiedDateFrom: this.state.modifiedDateFrom || "",
      LoginUserID: localStorage.isSingpostUser == "true" ? 0 : localStorage.loggedInId,
      trackingNumber: this.state.trackingNumber
    };
    this.props.onSearchClicked(reqBody);
    this.setState({
      open: false,
      bpaCode: "",
      deliveryID: "",
      deliveryAddress: "",
      modifiedDateFrom: null,
      modifiedDateTo: null,
      locationId: "",
      trackingNumber: ""
    });
  };

  checkButtonDisability = () => {
    let { modifiedDateFrom, modifiedDateTo } = this.state;
    if (
      !this.state.bpaCode &&
      !this.state.deliveryID &&
      !this.state.deliveryCountryID &&
      !this.state.StatusID &&
      !this.state.trackingNumber &&
      !(modifiedDateFrom && modifiedDateTo)
    )
      return true;
    else return false;
  };

  handleDateChange = (date) => {
    let { modifiedDateFrom } = this.state;
    modifiedDateFrom = date && date;
    this.setState({ modifiedDateFrom });
  };
  handleDateChange1 = (date) => {
    let { modifiedDateTo } = this.state;
    modifiedDateTo = date && date;
    this.setState({ modifiedDateTo });
  };

  handleJournalInfo = async (e, selectedWO) => {
    await this.setState({ journalData: e });
  };
  handleAutocompleteDeliveryAddress = (event, values) => {
    let id = values && values.id;
    let deliveryAddress = values;
    let deliveryID = id;
    this.setState({ deliveryAddress, deliveryID });
  };
  handleAutocompleteStatus = (event, values) => {
    let id = values && values.id;
    let status = values;
    let StatusID = id;
    this.setState({ status, StatusID });
  };
  handleCountryChange = (event, values) => {
    let id = values && values.id;
    let deliveryCountry = values;
    let deliveryCountryID = id;
    this.setState({ deliveryCountry, deliveryCountryID });
  };
  clearJournalInfo = (i) => {
    let newjournalData = { ...this.state.journalData };
    newjournalData = {};
    this.setState({ workOrder: newjournalData });
  };

  handleAutocompleteCustomer = (e, val) => {
    this.setState({ locationId: val && val.id, location: val && val });
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    let { status, statusList, errors } = this.state;
    const { classes, ...other } = this.props;

    return (
      <React.Fragment>
        <div>
          <Tooltip title="Search">
            <IconButton
              aria-label="Search"
              className={classes.iconHover}
              onClick={this.handleClickOpen}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
            maxWidth="md"
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            >
              {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
              Advanced Search
            </DialogTitle>
            <DialogContent
              dividers
              id="preAlertID"
              style={{ paddingTop: "0px!important" }}
            >
              <div>
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      label="Order ID"
                      name="bpaCode"
                      style={{
                        flex: 1,
                        marginRight: "16px",
                      }}
                      onChange={this.handleChange}
                      //onKeyPress={this._onNumberKeyPress}
                      margin="normal"
                      value={this.state.bpaCode}
                    />
                    <Autocomplete
                      //autoFocus
                      options={this.state.recipientList}
                      value={this.state.deliveryAddress}
                      onChange={this.handleAutocompleteDeliveryAddress}
                      getOptionLabel={(option) => option.text}
                      style={{
                        flex: 1,
                        marginRight: "16px",
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Delivery Address"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                    <Autocomplete
                      //autoFocus
                      options={this.state.statusList}
                      value={this.state.status}
                      onChange={this.handleAutocompleteStatus}
                      getOptionLabel={(option) => option.text}
                      style={{
                        flex: 1,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Status"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Autocomplete
                      options={this.state.deliveryCountryDD}
                      value={this.state.deliveryCountry}
                      onChange={this.handleCountryChange}
                      getOptionLabel={(option) => option.text}
                      style={{ flex: 1, marginRight: "16px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Receiver Country"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        style={{
                          flex: 1,
                          marginRight: "16px",
                        }}
                        id="modifiedDateFrom"
                        label="Modified Date From"
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        value={this.state.modifiedDateFrom}
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disabled={!this.state.modifiedDateFrom}
                        disableToolbar
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        style={{
                          flex: 1,
                        }}
                        minDate={this.state.modifiedDateFrom}
                        id="modifiedDateTo"
                        label="Modified Date To"
                        value={this.state.modifiedDateTo}
                        onChange={this.handleDateChange1}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div style={{ display: "fles" }}>
                    <TextField
                      id="standard-required"
                      label="Tracking Number"
                      name="trackingNumber"
                      margin="normal"
                      value={this.state.trackingNumber}
                      inputProps={{
                        maxLength: 50,
                      }}
                      style={{ flex: 1, paddingRight: "26px" }}
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                    <div style={{ flex: 2 }}></div>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions style={{ padding: "10px 24px" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={this.handleClose}
              >
                CANCEL
              </Button>
              {this.checkButtonDisability("preAlert") ? (
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  SEARCH
                </Button>
              ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={(e) => this.onSearchClicked()}
                  >
                    SEARCH
                  </Button>
                )}
            </DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(EmloStockinAdvanceSearch));
