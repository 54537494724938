import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  Paper,
  Divider,
  FormControlLabel,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { Autocomplete } from "@material-ui/lab";
import { Checkbox } from "@material-ui/core";
import {
  getCustomerPPIList,
  getCustomerList,
  getDeptList,
  getPPIList,
} from "../../masters/masterActions";
import { updateUser, getUserDetails, getRoleList } from "../userAccessAction";
import { resourceValidation } from "../../../shared/resource";
import { StateContext } from "../../../shared/globalState";
import { addStyles } from "../../../shared/styles";
const emailValidator = new Validator("email");
const notRequired100 = new Validator("notRequired100");
const onlyNumbers = new Validator("onlyNumbers");
const loginIDValid = new Validator("loginIDValid");

class EditUser extends React.Component {
  static contextType = StateContext;
  constructor() {
    super();

    this.state = {
      user: {
        fName: "",
        mName: "",
        lName: "",
        eMailID: "",
        loginUserID: "",
        accountPassword: "",
        role: {},
        roleID: "",
        customerID: "",
        customer: "",
        departmentID: "",
        department: "",
        customerPPI: "",
        customerPPIID: "",
        pwdExpieryDate: "",
        isSingpostUser: true,
        isActive: true,
      },
      updatedValues: false,
      customerSelected: false,
      active: false,
      errors: {},
      emailValid: false,
      passwordValid: false,
      formValid: false,
      firstNameValid: false,
      middleNameValid: false,
      lastNameValid: false,
      loginUserIDValid: false,
      roleIdValid: false,
      departmentValid: false,
      customersList: [],
      departmentList: [],
      customerPPIList: [],
      roleList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.handleSettingHeader("User");
    const dispatch = this.context[1];
    let userId = this.props.match.params.userId;

    getUserDetails(userId).then((r) => {
      dispatch({
        type: "getUserDetails",
        newStore: r,
      });
      this.setState({ user: r });
      this.setState({
        roleID: r.role && r.role.roleID,
        departmentID: r.department && r.department.departmentID,
      });
      if (r.isActive) this.setState({ active: r.isActive });
      r.customer && this.setState({ customerID: r.customer.customerID });
      r.customerPPI &&
        this.setState({ customerPPIID: r.customer.customerPPIID });

      this.validateField("fName", r.fName);
      this.validateField("loginUserID", r.loginUserID);
      this.validateField(
        "department",
        r.department && r.department.departmentID
      );
      this.validateField("roleID", r.role && r.role.roleID);
      this.validateField("eMailID", r.eMailID);
    });

    getCustomerList().then((r) => {
      r.data &&
        this.setState({
          customersList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.customerName.toLowerCase() > b.customerName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });

    getDeptList().then((r) => {
      r.data &&
        this.setState({
          departmentList: r.data.sort((a, b) =>
            a.departmentName.toLowerCase() > b.departmentName.toLowerCase()
              ? 1
              : -1
          ),
        });
    });

    getPPIList().then((r) => {
      r.data &&
        this.setState({
          customerPPIList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.ppiNumber.toLowerCase() > b.ppiNumber.toLowerCase() ? 1 : -1
            ),
        });
    });

    getRoleList().then((r) => {
      r.data &&
        this.setState({
          roleList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.roleName.toLowerCase() > b.roleName.toLowerCase() ? 1 : -1
            ),
        });
    });
  }
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.customerPPIList && store.customerPPIList.length > 0)
      if (this.state.customerPPIList.length !== store.customerPPIList.length) {
        this.state.customerSelected &&
          this.setState({
            customerPPIList: store.customerPPIList,
            customerSelected: false,
          });
      }
    return true;
  }
  handleDeptChange = (event, values) => {
    this.setState({ updatedValues: true });
    const { user } = this.state;
    let departmentID = values && values.departmentId;
    user["department"] = values;
    user["departmentID"] = departmentID;
    this.validateField("department", departmentID);
    this.setState({ user });
    this.validateForm();
  };

  handleCustChange = (event, values) => {
    this.setState({ updatedValues: true });
    const { user } = this.state;
    user["customerPPI"] = "";
    user["customerPPIID"] = "";
    this.setState({ customerPPIList: [], customerSelected: true });
    const dispatch = this.context[1];
    let customerID = values && values.customerID;
    user["customer"] = values;
    user["customerID"] = customerID;
    getCustomerPPIList(customerID).then((r) => {
      dispatch({
        type: "getCustomerPPIList",
        newStore: r.data,
      });
      this.setState({ customerPPIList: r.data });
    });
    this.setState({ user });
    this.validateForm();
  };

  handleCustPPIChange = (event, values) => {
    this.setState({ updatedValues: true });
    const { user } = this.state;
    let customerPPIID = values && values.customerPPIID;
    user["customerPPI"] = values;
    user["customerPPIID"] = customerPPIID;
    this.validateField("customerPPI", customerPPIID);
    this.setState({ user });
    this.validateForm();
  };
  _onKeyPress(event) {
    const re = /[a-zA-Z ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onKeyNumberPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleRoleChange = (event, values) => {
    this.setState({ updatedValues: true });
    const { user } = this.state;
    let roleID = values && values.roleID;
    user["role"] = values;
    user["roleID"] = roleID;
    this.validateField("roleID", roleID);
    this.setState({ user });
    this.validateForm();
  };

  handleChange = (event, values) => {
    const { user } = this.state;
    this.setState({ updatedValues: true });
    if (event.target.name === "isSingpostUser") {
      user[event.target.name] = event.target.checked;
      user["customer"] = "";
      user["customerID"] = "";
      user["customerPPI"] = "";
      user["customerPPIID"] = "";
      this.validateForm();
    } else if (event.target.name === "isActive") {
      user[event.target.name] = event.target.checked;
    } else {
      user[event.target.name] = event.target.value;
    }
    this.validateField(event.target.name, event.target.value);
    this.setState({ user });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let firstNameValid = this.state.firstNameValid;
    let middleNameValid = this.state.middleNameValid;
    let lastNameValid = this.state.lastNameValid;
    let loginUserIDValid = this.state.loginUserIDValid;
    let roleIdValid = this.state.roleIdValid;
    let departmentValid = this.state.departmentValid;
    switch (fieldName) {
      case "fName":
        if (value !== "") {
          firstNameValid = notRequired100(value);
          fieldValidationErrors.fName = firstNameValid
            ? ""
            : resourceValidation.onlyAlphabet;
        } else {
          firstNameValid = false;
          fieldValidationErrors.fName = "";
        }
        break;
      case "mName":
        middleNameValid = notRequired100(value);
        fieldValidationErrors.mName = middleNameValid
          ? ""
          : resourceValidation.onlyAlphabet;
        break;
      case "lName":
        lastNameValid = notRequired100(value);
        fieldValidationErrors.lName = lastNameValid
          ? ""
          : resourceValidation.onlyAlphabet;
        break;
      case "loginUserID":
        loginUserIDValid = loginIDValid(value);
        fieldValidationErrors.loginUserID = loginUserIDValid
          ? ""
          : "Invalid Login Id(Minimum 3 charaters required)";
        break;
      case "roleID":
        roleIdValid = onlyNumbers(value);
        fieldValidationErrors.roleID = roleIdValid
          ? ""
          : resourceValidation.onlyNumbers;
        break;
      case "department":
        departmentValid = onlyNumbers(value);
        fieldValidationErrors.department = departmentValid
          ? ""
          : " Please select department";
        break;
      case "eMailID":
        emailValid = emailValidator(value);
        fieldValidationErrors.email = emailValid
          ? ""
          : resourceValidation.validEmailId;
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid,
        firstNameValid: firstNameValid,
        middleNameValid: middleNameValid,
        lastNameValid: lastNameValid,
        loginUserIDValid: loginUserIDValid,
        roleIdValid: roleIdValid,
        departmentValid: departmentValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    const { user } = this.state;
    let fieldValidationErrors = this.state.errors;
    let customerValid = true;
    let customerPPIvalid = true;

    if (!user.isSingpostUser) {
      if (!user.customer) {
        customerValid = false;
      }
      fieldValidationErrors.customer = customerValid
        ? ""
        : "*Please select customer";

      if (!user.customerPPI) {
        customerPPIvalid = false;
      }

      fieldValidationErrors.customerPPI = customerPPIvalid
        ? ""
        : "*Please select customerPPI";
    } else {
      fieldValidationErrors.customerPPI = customerPPIvalid
        ? ""
        : "*Please select customerPPI";
      fieldValidationErrors.customer = customerValid
        ? ""
        : "*Please select customer";
    }
    if (this.state.updatedValues) {
      if (!user.singpostUser) {
        this.setState({
          formValid:
            this.state.firstNameValid &&
            this.state.loginUserIDValid &&
            this.state.emailValid &&
            this.state.roleIdValid &&
            this.state.departmentValid &&
            customerValid &&
            customerPPIvalid,
        });
      } else {
        this.setState({
          formValid:
            this.state.firstNameValid &&
            this.state.loginUserIDValid &&
            this.state.emailValid &&
            this.state.roleIdValid &&
            this.state.departmentValid,
        });
      }
    }

    return this.state.formValid;
  }

  handleClose = () => {
    this.setState({
      fName: "",
      mName: "",
      lName: "",
      loginUserID: "",
      isSingpostUser: "",
      customer: "",
      customerPPI: "",
      roleID: "",
      department: "",
      eMailID: "",
      errors: {},
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      let userId = this.props.match.params.userId;

      const { user } = this.state;
      let fName = user.fName;
      let mName = user.mName;
      let lName = user.lName;
      let loginUserID = user.loginUserID;
      let isSingpostUser = user.isSingpostUser;
      let customerID = user.customerID;
      let customerPPIID = user.customerPPIID;
      let roleID = user.roleID;
      let departmentID = user.departmentID;
      let eMailID = user.eMailID;
      let isActive = user.isActive;
      let modifiedBy = localStorage.loggedInId;
      let reqBody = {
        fName,
        mName,
        lName,
        loginUserID,
        isSingpostUser,
        customerID,
        customerPPIID,
        roleID,
        departmentID,
        eMailID,
        isActive,
        modifiedBy,
      };
      updateUser(userId, reqBody, this.props.history);
    }
  };
  render() {
    let { classes } = this.props;
    let {
      user,
      customersList,
      departmentList,
      customerPPIList,
      roleList,
      active,
    } = this.state;

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update User</div>
          <Divider />

          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <p className={classes.subHeader}>Enter User Details</p>
                <div>
                  <TextField
                    margin="normal"
                    required
                    inputProps={{
                      maxLength: 50,
                    }}
                    label="First Name"
                    name="fName"
                    autoComplete="off"
                    className={classes.textField}
                    value={user.fName}
                    onChange={this.handleChange}
                    error={this.state.errors.fName}
                    helperText={
                      this.state.errors.fName ? this.state.errors.fName : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    margin="normal"
                    label="Middle Name"
                    name="mName"
                    inputProps={{
                      maxLength: 50,
                    }}
                    autoComplete="off"
                    onKeyPress={this._onKeyPress}
                    className={classes.textField}
                    value={user.mName}
                    onChange={this.handleChange}
                    error={this.state.errors.mName}
                    helperText={
                      this.state.errors.mName ? this.state.errors.mName : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    margin="normal"
                    label="Last Name"
                    name="lName"
                    autoComplete="off"
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyPress={this._onKeyPress}
                    className={classes.textField}
                    value={user.lName}
                    onChange={this.handleChange}
                    error={this.state.errors.lName}
                    helperText={
                      this.state.errors.lName ? this.state.errors.lName : ""
                    }
                  />
                  <TextField
                    margin="normal"
                    label="Email-Id"
                    name="eMailID"
                    margin="normal"
                    autoComplete="off"
                    inputProps={{
                      maxLength: 50,
                    }}
                    required
                    className={classes.textField}
                    value={user.eMailID}
                    onChange={this.handleChange}
                    error={this.state.errors.email}
                    helperText={
                      this.state.errors.email ? this.state.errors.email : ""
                    }
                  />
                </div>
                <p className={classes.subHeader}>Enter Login Details</p>
                <div style={{ display: "flex" }}>
                  <TextField
                    margin="normal"
                    inputProps={{
                      maxLength: 50,
                    }}
                    required
                    disabled
                    label="Username"
                    name="loginUserID"
                    autoComplete="off"
                    className={classes.textField}
                    value={user.loginUserID}
                    onChange={this.handleChange}
                    error={this.state.errors.loginUserID}
                    helperText={
                      this.state.errors.loginUserID
                        ? this.state.errors.loginUserID
                        : ""
                    }
                  />

                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="role"
                    className={classes.comboBox}
                    options={roleList}
                    onChange={this.handleRoleChange}
                    value={user.role}
                    getOptionLabel={(option) => option.roleName}
                    renderInput={(params) => (
                      <TextField
                        margin="normal"
                        {...params}
                        required
                        label="Role"
                        name="role"
                        id="role"
                        style={{ width: "100%" }}
                        error={this.state.errors.roleID}
                        helperText={
                          this.state.errors.roleID
                            ? this.state.errors.roleID
                            : ""
                        }
                      />
                    )}
                  />

                  <Autocomplete
                    autoComplete
                    includeInputInList
                    required
                    className={classes.comboBox}
                    id="department"
                    options={departmentList}
                    onChange={this.handleDeptChange}
                    value={user.department}
                    getOptionLabel={(option) => option.departmentName}
                    renderInput={(params) => (
                      <TextField
                        margin="normal"
                        {...params}
                        required
                        label="Department"
                        name="department"
                        id="department"
                        margin="normal"
                        //className={classes.textField}
                        style={{ width: "100%" }}
                        value={user.department}
                        error={this.state.errors.department}
                        helperText={
                          this.state.errors.department
                            ? this.state.errors.department
                            : ""
                        }
                      />
                    )}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    disabled={user.isSingpostUser}
                    autoComplete
                    id="customer"
                    includeInputInList
                    className={classes.comboBox}
                    value={user.customer}
                    options={customersList}
                    onChange={this.handleCustChange}
                    getOptionLabel={(option) => option.customerName}
                    renderInput={(params) => (
                      <TextField
                        margin="normal"
                        {...params}
                        label="Customer"
                        //className={classes.textField}
                        style={{ width: "100%" }}
                        name="customer"
                        margin="normal"
                        error={this.state.errors.customer}
                        helperText={
                          this.state.errors.customer
                            ? this.state.errors.customer
                            : ""
                        }
                      />
                    )}
                  />
                  <Autocomplete
                    disabled={user.isSingpostUser}
                    autoComplete
                    includeInputInList
                    id="customerPPI"
                    options={customerPPIList}
                    className={classes.comboBox}
                    onChange={this.handleCustPPIChange}
                    value={user.customerPPI}
                    getOptionLabel={(option) => option.ppiNumber}
                    renderInput={(params) => (
                      <TextField
                        margin="normal"
                        {...params}
                        label="CustomerPPI"
                        name="customerPPI"
                        margin="normal"
                        style={{ width: "100%" }}
                        error={this.state.errors.customerPPI}
                        helperText={
                          this.state.errors.customerPPI
                            ? this.state.errors.customerPPI
                            : ""
                        }
                      />
                    )}
                  />
                  <FormControlLabel
                    name="isSingpostUser"
                    className={classes.checkBox}
                    control={
                      <Checkbox
                        color="primary"
                        className={classes.Checkbox}
                        checked={user.isSingpostUser}
                        onChange={this.handleChange}
                        value={user.isSingpostUser}
                      />
                    }
                    label="SingpostUser"
                  />
                  {active ? (
                    <React.Fragment />
                  ) : (
                      <FormControlLabel
                        name="isActive"
                        className={classes.checkBox}
                        control={
                          <Checkbox
                            color="primary"
                            className={classes.Checkbox}
                            checked={user.isActive}
                            onChange={this.handleChange}
                            value={user.isActive}
                          />
                        }
                        label="Active"
                      />
                    )}
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/user")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditUser));
