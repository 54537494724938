import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import clone from "clone";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { styles } from "../../../shared/styles";
import { resourceValidation } from "../../../shared/resource";
import { StateContext } from "../../../shared/globalState";
import { getPublisherCustomerList } from "../action";
import { getStatusList, getSubscriptionList } from "./action";
//import {getPublisherCustomerList,searchJournalitemMasterList} from "./actions"
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
//import { getFliteredDeptList } from "../masterActions";
const validateNotRequired2 = new Validator("notRequired2");
const validateNotRequired100 = new Validator("notRequired100");
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        ></IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class MLOPSearch extends React.Component {
  componentDidMount() {
    getPublisherCustomerList().then((r) => {
      this.getAllCustomer(r.data);
      getStatusList().then((r) => {
        this.getAllStatus(r.data);
      });
      getSubscriptionList().then((r) => {
        this.getAllSubscription(r.data);
      });
    });
  }
  static contextType = StateContext;
  state = {
    customerList: [],
    customerId: 0,
    customerObj: {},
    statusList: [],
    statusId: 0,
    statusObj: {},
    subscriptionList: [],
    subscriptionId: 0,
    subscriptionObj: {},
    issueDescription: "",
    fileReceivedDateFrom: null,
    fileReceivedDateTo: null,
    modifiedDateFrom: null,
    modifiedDateTo: null,
    open: false,
  };
  constructor() {
    super();
  }
  handleAutocompleteCustomer = (e, val) => {
    this.setState({ customerId: val && val.key, customerObj: val && val });
  };
  handleAutocompletStatus = (e, val) => {
    this.setState({ statusId: val && val.key, statusObj: val && val });
  };
  handleAutocompletSubscription = (e, val) => {
    this.setState({
      subscriptionId: val && val.key,
      subscriptionObj: val && val,
    });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      open: false,
      customerId: "",
      issueDescription: "",
      fileReceivedDateFrom: null,
      fileReceivedDateTo: null,
      customerId: "",
      customerObj: {},
      statusId: 0,
      statusObj: {},
      subscriptionId: 0,
      subscriptionObj: {},
      orderFileName: "",
    });
  };
  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };
  onSearchClicked = () => {
    let reqBody = {
      customerID: this.state.customerId ? this.state.customerId : 0,
      statusId: this.state.statusId ? this.state.statusId : 0,
      subscriptionId: this.state.subscriptionId ? this.state.subscriptionId : 0,
      issueDescription: this.state.issueDescription,
      originFileName: this.state.orderFileName,
      fileReceivedDateFrom: this.state.fileReceivedDateFrom,
      fileReceivedDateTo: this.state.fileReceivedDateTo,
      acronym: this.state.acronym ? this.state.acronym : "",
      volume: this.state.volume ? this.state.volume : "",
      issue: this.state.issue ? this.state.issue : "",
      suppNumber: this.state.supp ? this.state.supp : "",
      partNumber: this.state.part ? this.state.part : "",
      modifiedDateFrom:
        this.state.modifiedDateFrom && this.state.modifiedDateFrom,
      modifiedDateTo: this.state.modifiedDateTo && this.state.modifiedDateTo,
    };
    this.props.onSearchClicked(reqBody);
    this.setState({
      open: false,
      customerId: "",
      issueDescription: "",
      fileReceivedDateFrom: null,
      fileReceivedDateTo: null,
      modifiedDateFrom: null,
      modifiedDateTo: null,
      customerId: "",
      customerObj: {},
      locationId: 0,
      locationObj: {},
      orderFileName: "",
      acronym: "",
      volume: "",
      issue: "",
      supp: "",
      part: "",
    });
  };
  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  //   handleAutocompleteCustomer = (e, val) => {
  //     this.setState({ customerId: val && val.id, customer: val && val });
  //   };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  sortDDList = (list, name) => {
    let oldCus = clone(list);
    // if(name==="customerList")
    // oldCus&& this.setState({customerId: oldCus[0].key,
    //   customerObj: oldCus[0]})
    //   if(name==="locationList")
    //  {
    //   oldCus &&oldCus.length>0&& this.setState({locationId: oldCus.filter(d=> d.value==="SG")[0].key,
    //   locationObj: oldCus.filter(d=> d.value==="SG")[0]})
    // }
    let z = list.sort(function (a, b) {
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ [name]: z });
  };
  getAllCustomer = (list) => {
    let customerList = [];

    list &&
      list.length > 0 &&
      list.map((c) => {
        customerList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(customerList, "customerList");
  };
  getAllStatus = (list) => {
    let locationList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        locationList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(locationList, "statusList");
  };
  getAllSubscription = (list) => {
    let locationList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        locationList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(locationList, "subscriptionList");
  };
  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };
  render() {
    let { departmentCode, departmentName } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <Tooltip title="Search">
          <IconButton
            aria-label="Search"
            className={classes.iconHover}
            onClick={this.handleClickOpen}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          onClose={this.handleClose}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          maxWidth="md"
        >
          <DialogTitle id="customized-dialog-title">
            {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
            <span style={{ marginLeft: ".4em" }}>Advanced Search</span>
          </DialogTitle>
          <DialogContent dividers>
            <div style={{ display: "flex" }}>
              <Autocomplete
                options={this.state.customerList}
                value={this.state.customerObj}
                onChange={this.handleAutocompleteCustomer}
                getOptionLabel={(option) => option.name}
                style={{ flex: 1, marginRight: "16px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer"
                    margin="normal"
                    style={{ width: "180px" }}
                  />
                )}
              />
              <Autocomplete
                options={this.state.statusList}
                value={this.state.statusObj}
                onChange={this.handleAutocompletStatus}
                getOptionLabel={(option) => option.name}
                style={{ flex: 1, marginRight: "16px" }}

                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    margin="normal"
                    style={{ width: "180px" }}
                  />
                )}
              />
              <TextField
                id="standard-required"
                label="Issue Description"
                name="issueDescription"
                margin="normal"
                value={this.state.issueDescription}
                inputProps={{
                  maxLength: 50,
                }}
                style={{ flex: 1, width: "180px" }}
                onChange={this.handleChange}
                autoComplete="off"
              />
            </div>
            {/* <div style={{ display: "flex" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  style={{ flex: 1, marginRight: "16px" }}
                  id="fileReceivedDateFrom"
                  label="File Received Date From"
                  // onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  name="fileReceivedDateFrom"
                  value={this.state.fileReceivedDateFrom}
                  onChange={(e) =>
                    this.handleDateChange(e, "fileReceivedDateFrom")
                  }
                  //    value={preAlertSearchValue.fileReceivedDateFrom}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disabled={!this.state.fileReceivedDateFrom}
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  style={{ flex: 1 }}
                  name="fileReceivedDateTo"
                  value={this.state.fileReceivedDateTo}
                  minDate={this.state.fileReceivedDateFrom}
                  id="fileReceivedDateTo"
                  label="File Received Date To"
                  //   value={preAlertSearchValue.fileReceivedDateTo}
                  onChange={(e) =>
                    this.handleDateChange(e, "fileReceivedDateTo")
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div> */}
            <div style={{ display: "flex" }}>

              <TextField
                id="standard-required"
                label="File Name"
                name="orderFileName"
                margin="normal"
                value={this.state.orderFileName}
                inputProps={{
                  maxLength: 50,
                }}
                style={{ flex: 1, width: "180px", marginRight: "16px" }}
                onChange={this.handleChange}
                autoComplete="off"

              />
              <Autocomplete
                options={this.state.subscriptionList}
                value={this.state.subscriptionObj}
                onChange={this.handleAutocompletSubscription}
                getOptionLabel={(option) => option.name}
                style={{ flex: 1, marginRight: "16px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Subscription"
                    margin="normal"
                    style={{ flex: 1, width: "180px" }}
                  />
                )}
              />
              <TextField
                id="standard-required"
                label="Acronym"
                name="acronym"
                margin="normal"
                value={this.state.acronym}
                inputProps={{
                  maxLength: 50,
                }}
                style={{ flex: 1, width: "180px" }}
                onChange={this.handleChange}
                autoComplete="off"
              />

            </div>
            <div style={{ display: "flex" }}>

              <TextField
                id="standard-required"
                label="Volume"
                name="volume"
                margin="normal"
                value={this.state.volume}
                inputProps={{
                  maxLength: 50,
                }}
                style={{ flex: 1, width: "180px", marginRight: "16px" }}
                onChange={this.handleChange}
                autoComplete="off"
              />
              <TextField
                id="standard-required"
                label="Issue"
                name="issue"
                margin="normal"
                value={this.state.issue}
                inputProps={{
                  maxLength: 50,
                }}
                style={{ flex: 1, width: "180px", marginRight: "16px" }}
                onChange={this.handleChange}
                autoComplete="off"
              />
              <TextField
                id="standard-required"
                label="Supp"
                name="supp"
                margin="normal"
                value={this.state.supp}
                inputProps={{
                  maxLength: 50,
                }}
                style={{ flex: 1, width: "180px" }}
                onChange={this.handleChange}
                autoComplete="off"
              />
            </div>
            <div>
              <TextField
                id="standard-required"
                label="Part"
                name="part"
                margin="normal"
                value={this.state.part}
                inputProps={{
                  maxLength: 50,
                }}
                style={{ flex: 1, width: "180px", marginRight: "16px" }}
                onChange={this.handleChange}
                autoComplete="off"
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  style={{
                    width: "180px",
                    display: "inline-block",

                    marginRight: "16px",
                  }}
                  id="modifiedDateFrom"
                  label="Modified Date From"
                  // onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  name="modifiedDateFrom"
                  value={this.state.modifiedDateFrom}
                  onChange={(e) =>
                    this.handleDateChange(e, "modifiedDateFrom")
                  }
                //    value={preAlertSearchValue.modifiedDateFrom}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disabled={!this.state.modifiedDateFrom}
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  style={{
                    width: "180px",
                    display: "inline-block",
                    // marginLeft: ".5em",
                    // marginRight: ".5em",
                  }}
                  name="modifiedDateTo"
                  minDate={this.state.modifiedDateFrom}
                  value={this.state.modifiedDateTo}
                  id="modifiedDateTo"
                  label="Modified Date To"
                  //   value={preAlertSearchValue.modifiedDateTo}
                  onChange={(e) =>
                    this.handleDateChange(e, "modifiedDateTo")
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              {/* <div style={{ flex: 1, width: "180px" }}></div> */}
            </div>
          </DialogContent>
          <DialogActions style={{ padding: "10px 24px" }}>
            <Button
              //   autoFocus
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={this.handleClose}
            //   color="primary"
            >
              CANCEL
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={(e) => this.onSearchClicked()}
              disabled={
                !(this.state.modifiedDateFrom
                  ? this.state.modifiedDateTo
                  : this.state.customerId ||
                  this.state.statusId ||
                  this.state.subscriptionId ||
                  this.state.orderFileName ||
                  this.state.acronym ||
                  this.state.volume ||
                  this.state.issue ||
                  this.state.supp ||
                  this.state.part ||
                  this.state.issueDescription)
              }
            >
              SEARCH
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(MLOPSearch);
