import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";
import clone from "clone";
import moment from "moment"
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import ReportViewer from "../../app/ReportViewer";
import { withRouter } from "react-router-dom";
import TableHead from "@material-ui/core/TableHead";
import { EditIcon } from "../../../shared/customIcons";
import { Divider, Tooltip } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import DeleteIcon from "@material-ui/icons/Delete";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CheckIcon from "@material-ui/icons/Check";
import { Autocomplete } from "@material-ui/lab";
import { StateContext } from "../../../shared/globalState";
import { addDispatchStyles } from "../../../shared/styles";
import { addZeros } from "../../../shared/tableCommonFunctions";
import {
  getViewDetailsForMainLabelDocketBagging,
  postDocketBaggingList,
  getMailSchemeForDocketBagging,
  getDestinationForDocketBagging,
  getDocketBaggingHeaderInfo,
} from "./action";

const headCells = [
  {
    id: "mailingScheme",
    numeric: false,
    disablePadding: true,
    label: "Mailing Scheme",
  },
  {
    id: "destination",
    numeric: false,
    disablePadding: false,
    label: "Destination",
  },
  {
    id: "noofBags",
    numeric: false,
    disablePadding: false,
    label: "No. of Bags",
  },
  {
    id: "bagWeight",
    numeric: false,
    disablePadding: false,
    label: "Per Bag Weight(kg)",
  },
  {
    id: "totalWeight",
    numeric: false,
    disablePadding: false,
    label: "Total Bag Weight(kg)",
  },

  // {
  //   id: "action",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Action",
  // },
];

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderTop: "1px solid #eaeaea",
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow style={{ background: "#eaeaea" }}>
        {headCells.map((headCell, i) => (
          <React.Fragment>
            <TableCell
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "default"}
              className={i !== headCells.length - 1 ? "headcells" : "headcell"}
              style={{ borderLeft: "1px sold #000", width: "20%" }}
            >
              <span style={{ color: "#306dca" }}>{headCell.label}</span>
            </TableCell>
          </React.Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { } = props;
  return <div></div>;
};

class DocketBagging extends React.Component {
  static contextType = StateContext;
  state = {
    selectedFilteredMSObj: {},
    selectedFilteredMSId: "",
    selectedFilteredDesObj: {},
    selectedFilteredDesId: "",
    destinationListForFiltering: [],
    disableAdd: false,
    disableEdit: true,
    list: [],
    selectedEditIndex: "",
    mailingSchemeList: [],
    mailingScheme: {},
    destinationList: [],
    destination: {},
    destinationId: "",
    mailingSchemeId: "",
    showPopup: false,
    docketbaggingHeaderInfo: {},
    showReportModal: false,
    selectedReportName: "",
  };

  handleShowPopup = () => {
    this.setState({
      showPopup: true,
    });
  };

  handleHidePopup = () => {
    this.setState({
      showPopup: false,
    });
  };

  onBlur = (event, name, index) => {
    let newList = clone(this.state.list);
    let values = event.target.value;
    values = this.addZeroes(values.toString(), 3);
    newList[index][name] = values;
    this.setState({ list: newList });
  };

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    return value;
  }

  componentDidMount() {
    this.props.handleSettingHeader("Main Label Docket Bagging");
    this.props.setSelectedList(
      "publisherMainLabelOpen",
      "pubMainLabelDocketBagging"
    );
    this.props.handleDrawerClose();
    const [{ store }, dispatch] = this.context;
    let batchHeaderId = "";
    if (process.browser) batchHeaderId = window.location.pathname.split("/")[2];
    // batchHeaderId = window.location.pathname.split("/")[2];
    getMailSchemeForDocketBagging(batchHeaderId).then((r) => {
      r.data &&
        this.setState({
          mailingSchemeList: r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
        });
    });
    let newList = clone(this.state.list);
    getDocketBaggingHeaderInfo(batchHeaderId).then((r) => {
      this.setState({
        docketbaggingHeaderInfo: r.data,
      });
    });
    getViewDetailsForMainLabelDocketBagging(batchHeaderId).then((r) => {
      r &&
        r.data &&
        r.data.length > 0 &&
        r.data.map((l) => {
          newList.push({
            mailingScheme: {
              id: l.mailingSchemeID,
              value: l.mailingScheme,
              text: l.mailingScheme,
            },
            mailingSchemeId: l.mailingSchemeID,
            destination: {
              id: l.countryID == null ? l.zoneID : l.countryID,
              value: l.destination,
              text: l.destination,
            },
            destinationId: l.countryID == null ? l.zoneID : l.countryID,
            noofBags: l.noofBags ? l.noofBags : "",
            bagWeight: l.bagWeight ? l.bagWeight : "",
            totalWeight: l.totalWeight ? l.totalWeight.toFixed(3) : "",
            createdBy: window.localStorage.loggedInId,
            mlDocketBagID: l.mlDocketBagID,
            // countryID: l.countryID,
            // destination: l.destination,
            // mailingSchemeID: l.mailingSchemeID,
            mlDocketBagID: l.mlDocketBagID,
            displayInLiist: true
            // zoneID: l.zoneID,
          });
        });

      this.setState({
        list: newList,
        disableEdit: false,
        subordinate: newList,
      }, () => {
        if (document.getElementById("focusMe")) {
          document.getElementById("focusMe").focus()
        }
      });
    });
  }

  handleReportChange = (e) => {
    if (e.target.value.toLowerCase() !== 'none') {
      this.setState({
        showReportModal: true,
        selectedReportName: e.target.value
      });
    }
    e.target.value = "none";
  };

  handleHidePopup = () => {
    this.setState({
      showReportModal: false,
      selectedReportName: ""
    });
  };
  handleDesFilterOnAutoOpenClick = (id) => {
    let batchHeaderId = "";
    if (process.browser) batchHeaderId = window.location.pathname.split("/")[2];
    if (this.state.selectedFilteredMSId) {
      this.setState({ destinationListForFiltering: [{ id: 4, text: "Loading...", value: "Loading..." }] })
      getDestinationForDocketBagging(batchHeaderId, this.state.selectedFilteredMSId).then((r) => {
        r.data &&
          this.setState({
            destinationListForFiltering: r.data.sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
          });
        if (!r.data)
          this.setState({ destinationListForFiltering: [] })
      });

    }
    else this.setState({ destinationList: [] })
  };
  handleMSFilterAutoComplete = (e, value) => {

    let newList = clone(this.state.list)
    if (value) {
      this.state.list.map((d, idn) => {
        if (d.mailingSchemeId === value.id)
          newList[idn].displayInLiist = true
        else
          newList[idn].displayInLiist = false
      })
      this.setState({
        selectedFilteredMSObj: value,
        selectedFilteredMSId: value.id,
        selectedFilteredDesObj: {},
        selectedFilteredDesId: "",
        list: newList,
        destinationListForFiltering: []


      }, () => {
        if (document.getElementById("focusMe")) {
          document.getElementById("focusMe").focus()
        }
      })
    }
    else {
      this.state.list.map((d, idn) => {
        newList[idn].displayInLiist = true
      })
      this.setState({
        selectedFilteredMSObj: {},
        selectedFilteredMSId: "",
        selectedFilteredDesObj: {},
        selectedFilteredDesId: "",
        list: newList,
        destinationListForFiltering: []
      }, () => {
        console.log(document.getElementById("focusMe"))
        if (document.getElementById("focusMe"))
          document.getElementById("focusMe").focus()
      })
    }
  };
  handleDesFilterAutoComplete = (e, value) => {
    let newList = clone(this.state.list)
    if (value) {
      this.state.list.map((d, idn) => {
        if (d.mailingSchemeId === this.state.selectedFilteredMSId && d.destinationId === value.id)
          newList[idn].displayInLiist = true
        else
          newList[idn].displayInLiist = false
      })
      this.setState({
        selectedFilteredDesObj: value,
        selectedFilteredDesId: value.id,
      })
    }
    else if (this.state.selectedFilteredMSId) {
      this.state.list.map((d, idn) => {
        if (d.mailingSchemeId === this.state.selectedFilteredMSId)
          newList[idn].displayInLiist = true
        else
          newList[idn].displayInLiist = false
      })
      this.setState({
        selectedFilteredDesObj: {},
        selectedFilteredDesId: "",
      })
    }
    else {
      this.state.list.map((d, idn) => {
        newList[idn].displayInLiist = true
      })
      this.setState({
        selectedFilteredDesObj: {},
        selectedFilteredDesId: "",
      })
    }
    this.setState({
      list: newList
    }, () => {
      if (document.getElementById("focusMe")) {
        document.getElementById("focusMe").focus()
      }
    })

  };
  render() {
    let {
      list,
      customerList,
      categoryList,
      processList,
      docketbaggingHeaderInfo,
    } = this.state;
    let { classes } = this.props;
    let noBag = 0
    let noWeight = 0
    list && list.length > 0 && list.map(g => {
      if (g.displayInLiist) {
        noBag = noBag + (+g.noofBags)
        noWeight = noWeight + (+g.totalWeight)
      }
    })
    let newList = clone(list);

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header" style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <span>View Docket</span>
            </div>
            <div style={{ flex: 1, textAlign: "right", paddingRight: "33px" }}>
              <div className="custom-select">
                <label style={{ paddingRight: "15px", fontSize: "18px" }}>Report</label>
                <select
                  onChange={(e) => {
                    this.handleReportChange(e);
                  }}
                  style={{ paddingLeft: "10px" }}
                >
                  <option value="none" className="custom-select-options">
                    None
                  </option>
                  <option
                    value="MailLodgementDetailsSheetReport"
                    className="custom-select-options"
                  >
                    Mail Lodgement Detail
                  </option>
                  <option
                    value="DocketChecklistReport"
                    className="custom-select-options"
                  >
                    Docket Checklist
                  </option>
                  <option value="RunMailListFinal" className="custom-select-options">
                    Run Mail List Final
                  </option>
                  <option value="PublisherPostingDocketInternational" className="custom-select-options">
                    Posting Docket International
                  </option>
                  <option value="PublisherPostingDocketLocal" className="custom-select-options">
                    Posting Docket Local
                  </option>
                </select>
              </div>
            </div>
          </div>
          <Divider />

          <div
            className="view-page-container"
            style={{ paddingTop: "2em" }}
            id="printable"
          >
            <div>
              <div style={{ marginBottom: "1em" }}>
                <Typography
                  component="div"
                  className="blueBox"
                  style={{ width: "auto" }}
                >
                  <div className="blueBoxLabel" style={{ width: "23%" }}>
                    <div>Batch ID: </div>
                    <div className="blueBoxText">
                      {docketbaggingHeaderInfo.mlBatchHeader}
                    </div>
                  </div>
                  <div className="blueBoxLabel" style={{ width: "31%" }}>
                    <div>Customer: </div>
                    <div className="blueBoxText">
                      {docketbaggingHeaderInfo.customerName}
                    </div>
                  </div>
                  <div className="blueBoxLabel" style={{ width: "23%" }}>
                    <div>Lodgement Date: </div>
                    <div className="blueBoxText">
                      {this.state.docketbaggingHeaderInfo.lodgementDate ? moment(this.state.docketbaggingHeaderInfo.lodgementDate).format("DD/MM/YYYY") : ""}
                    </div>
                  </div>
                  <div className="blueBoxLabel" style={{ width: "23%" }}>
                    <div>Status: </div>
                    <div className="blueBoxText">
                      {docketbaggingHeaderInfo.status}
                    </div>
                  </div>
                </Typography>
              </div>

              <EnhancedTableToolbar
                //numSelected={selected.length}
                handleClickButton={this.handleClickButton}
                disableAdd={this.state.disableAdd}
                disableEdit={this.state.disableEdit}
              />
              <div style={!this.props.clearOpenList
                ? {
                  maxWidth: "calc(100vw - 8em)", border: "1px solid #eaeaea", borderRadius: "4px", display: "flex"
                }
                : {
                  maxWidth: "calc(100vw - 330px)", border: "1px solid #eaeaea", borderRadius: "4px", display: "flex"
                }}>
                <div
                  style={!this.props.clearOpenList
                    ? {
                      width: "22%", maxWidth: "22%", margin: ".5em"
                    }
                    : {
                      width: "26%", maxWidth: "26%", margin: ".5em"
                    }}
                >
                  <Autocomplete
                    autoComplete
                    // includeInputInList
                    // disableClearable
                    options={this.state.mailingSchemeList}
                    // onOpen={(e) =>
                    //   this.handleAutoOpenClick(index)
                    // }
                    onChange={this.handleMSFilterAutoComplete}
                    // id={"mailingScheme-" + index}
                    name="mailingScheme"
                    hintText="mailingScheme"
                    value={this.state.selectedFilteredMSObj}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        // value={row.mailingScheme}
                        placeholder="Filter by Mailing Scheme"

                        margin="normal"
                        style={{
                          width: "100%",
                          margin: "0px",
                          padding: "0px",
                        }}
                      />
                    )}
                  />
                </div>

                <div

                  style={!this.props.clearOpenList
                    ? {
                      width: "28%", maxWidth: "28%", margin: ".5em"
                    }
                    : {
                      width: "31%", maxWidth: "31%", margin: ".5em"
                    }}>
                  <Autocomplete
                    //  autoComplete
                    //  includeInputInList
                    // disableClearable
                    options={this.state.destinationListForFiltering}
                    onOpen={(e) =>
                      this.handleDesFilterOnAutoOpenClick(e)
                    }
                    onChange={this.handleDesFilterAutoComplete}
                    //  id={"destination-" + index}
                    //  name="destination"
                    // hintText="destination"
                    value={this.state.selectedFilteredDesObj}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        // value={row.destination}
                        placeholder="Filter by Destination"
                        style={{
                          width: "100%",
                          margin: "0px",
                          padding: "0px",
                        }}
                      // label="Description"
                      // margin="normal"
                      />
                    )}
                  />
                </div>
                <div
                  style={!this.props.clearOpenList
                    ? {
                      width: "25%", maxWidth: "25%", margin: ".5em", display: "flex", alignItems: "flex-end"
                    }
                    : {
                      width: "15%", maxWidth: "15%", margin: ".5em", display: "flex", alignItems: "flex-end"
                    }}

                // style={{ width: "18%", maxWidth: "18%", margin: ".5em", display: "flex", alignItems: "flex-end" }}
                >
                  <span className="blueBoxLabel" style={{ padding: "0px" }}>Total Bags : <span style={{ color: "#306dca", fontWeight: "600", marginLeft: ".5em" }}> {noBag}</span></span>
                  {/* <span style={{ display: "flex" }}> <span className="blueBoxLabel" style={{ padding: "0px" }}>Total Bags :</span>
                    <span style={{ color: "#306dca", fontWeight: "600", marginLeft: ".5em" }}> {noBag}</span></span> */}

                </div>
                <div

                  style={!this.props.clearOpenList
                    ? {
                      width: "25%", maxWidth: "25%", margin: ".5em", display: "flex", alignItems: "flex-end"
                    }
                    : {
                      width: "18%", maxWidth: "18%", margin: ".5em", display: "flex", alignItems: "flex-end"
                    }}>
                  <span className="blueBoxLabel" style={{ padding: "0px" }}>Total Weight :<span style={{ color: "#306dca", fontWeight: "600", marginLeft: ".5em" }}> {noWeight.toFixed(3)}</span></span>

                </div>

              </div>
            </div>
            <div style={!this.props.clearOpenList
              ? {
                maxWidth: "calc(100vw - 8em)", border: "1px solid #eaeaea", borderRadius: "4px", display: "flex"
              }
              : {
                maxWidth: "calc(100vw - 330px)", border: "1px solid #eaeaea", borderRadius: "4px", display: "flex"
              }}> <span style={{ margin: ".8em .5em", color: "#306dca", fontWeight: "600" }}>Docket Bagging lists</span>
            </div>
            <div
              style={{
                // width: "900px",
                maxHeight: "400px",
                margin: "0  0 1em",
                borderLeft: "1px Solid #eaeaea",
                // borderRight: "1px solid #eaeaea"
              }}
            >
              <div
                // style={{ maxHeight: 350, overflow: "auto" }}
                style={
                  !this.props.clearOpenList
                    ? {
                      overflow: "auto",
                      border: "1px solid #eaeaea",
                      maxWidth: "calc(100vw - 8em)",
                      maxHeight: "400px",
                    }
                    : {
                      overflow: "auto",
                      border: "1px solid #eaeaea",
                      maxWidth: "calc(100vw - 330px)",
                      maxHeight: "400px",
                    }
                }
              >
                {/* //overflow: "auto" */}
                <Table
                  aria-labelledby="tableTitle"
                  size="small"
                  stickyHeader
                  aria-label="sticky table"
                // style={{ width: "1800px", overflow: "auto" }}
                >
                  {" "}
                  <EnhancedTableHead />
                  {!this.state.loading ? (
                    list.length > 0 ? (
                      <TableBody style={{ overflow: "hidden" }}>
                        {list.map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          let focusMe = ""
                          let focusFirst = true
                          if (row.isActive && row.displayInLiist && focusFirst) {
                            focusMe = "focusMe"
                            focusFirst = false
                          }
                          return row.displayInLiist && (
                            <TableRow
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                                style={!this.props.clearOpenList
                                  ? {

                                    minWidth: "280px",
                                  }
                                  : {
                                    minWidth: "280px"
                                  }}
                              >
                                {row.mailingScheme &&
                                  row.mailingScheme.length > 10 ? (
                                    <Tooltip
                                      title={row.mailingScheme.value}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.mailingScheme.value}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.mailingScheme.value}
                                    </Typography>
                                  )}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                                style={!this.props.clearOpenList
                                  ? {

                                    minWidth: "340px",
                                  }
                                  : {
                                    minWidth: "340px"
                                  }}
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.destination.text}
                                    </Typography>
                                  }
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                // id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-right-cell"
                                style={{
                                  width: "10%",
                                  padding: " 6px 6px",
                                }}
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                      id={focusMe ? focusMe : labelId}

                                    >
                                      {row.noofBags}
                                    </Typography>
                                  }
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-right-cell"
                                style={{ width: "5%", padding: " 6px 6px" }}
                              >
                                <Typography
                                  noWrap
                                  style={{ fontSize: "14px" }}
                                // className="table-content-right-cell"
                                >
                                  {" "}
                                  {
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.bagWeight ? (+row.bagWeight).toFixed(3) : ""}
                                    </Typography>
                                  }
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-right-cell"
                                style={{
                                  // width: "5%",
                                  padding: " 6px 6px",
                                }}
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.totalWeight}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : (
                        <TableBody>
                          <TableRow
                            style={{
                              borderRight: "1px solid #eaeaea",
                              borderLeft: "1px solid #eaeaea",
                            }}
                          >
                            <TableCell
                              colSpan="7"
                              style={{ textAlign: "center" }}
                            >
                              No docket found
                          </TableCell>
                          </TableRow>
                        </TableBody>
                      )
                  ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan="9">
                            <LinearProgress />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                </Table>
              </div>
            </div>
            <Divider style={{ marginTop: "25px" }} />
            <div className="button-wrapper">
              <div>
                {newList.length !== list.length ? (
                  <Button
                    disabled
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                  >
                    CANCEL
                  </Button>
                ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.goBack()}
                    >
                      CANCEL
                    </Button>
                  )}
              </div>
            </div>
          </div>
          {this.state.showReportModal ?
            <ReportViewer
              url={`${window.localStorage.ReportURLByID}` + this.state.selectedReportName +
                "&IsDirectPrint=True&ReportParam=MLBatchHeaderID&Param1="
                + `${this.props.history.location.pathname.split("/")[2]}`}
              showPopup={this.state.showReportModal}
              title={this.state.selectedReportName.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + this.state.selectedReportName.replace(/([A-Z])/g, " $1").slice(1)}
              onClose={() => this.handleHidePopup()}
              handleHidePopup={() => this.handleHidePopup()}
            /> : null
          }
        </Paper>
      </React.Fragment>
    );
  }
}
//export default withRouter();
export default withRouter(withStyles(addDispatchStyles)(DocketBagging));
