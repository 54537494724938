import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { printDetails, addZeros } from "../../../shared/tableCommonFunctions";
import { Divider } from "@material-ui/core";

import { getRoutingUKUS } from "../routingActions";
import { StateContext } from "../../../shared/globalState";

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { selectedroutingUKUSID } = props;
  let acnArr = props.roles.filter((u) => u.menuName === "Routing UK US");
  let acnObj = acnArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_RoutingUKUS/${selectedroutingUKUSID}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewRoutingUKUS extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      routingUKUS: {
        customer: {},
        location: {},
        subscription: {},
        country: {},
        supplementaryIndicator: "",
        consolidationType: "",
        isPeriodical: "",
        dispatchType: {},
        dispatchMode: {},
        mailingScheme: {},
        distributor: {},
        customerName: "",
        locationName: "",
        subscriptionName: "",
        noOfOrdersFrom: "",
        noOfOrdersTo: "",
        noOfCopiesFrom: "",
        noOfCopiesTo: "",
        weightFrom: "",
        weightTo: "",
        ppiNumber: "",
        ppiLicenseNo: "",
        dispatchTypeName: "",
        dispatchModeName: "",
        mailingSchemeName: "",
        distributorName: "",
        returnAddress: "",
        carrierSheetScheme: "",
        carrierSheetMode: "",
      },
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let routingUKUSID = this.props.match.params.routingUKUSID;

    getRoutingUKUS(routingUKUSID).then((r) => {
      dispatch({
        type: "getRoutingTitle",
        newStore: r,
      });
      this.setState({ routingUKUS: r });
    });
  }

  render() {
    let { routingUKUS } = this.state;
    let routingUKUSID = this.props.match.params.routingUKUSID;
    let { status } = "";
    if (routingUKUS.isPeriodical === true) {
      status = "Yes";
    } else {
      status = "No";
    }
    let { status1 } = "";
    if (routingUKUS.isActive === true) {
      status1 = "Yes";
    } else {
      status1 = "No";
    }
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selectedroutingUKUSID={routingUKUSID}
            roles={this.props.roles}
          />
          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">Customer Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Customer</div>
                <div className="view-page-value">
                  {routingUKUS &&
                    routingUKUS.customer &&
                    routingUKUS.customer.customerName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Location</div>
                <div className="view-page-value">
                  {routingUKUS &&
                    routingUKUS.location &&
                    routingUKUS.location.locationName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Subscription</div>
                <div className="view-page-value">
                  {routingUKUS &&
                    routingUKUS.subscription &&
                    routingUKUS.subscription.subscriptionName}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Country Code</div>
                <div className="view-page-value">
                  {routingUKUS &&
                    routingUKUS.country &&
                    routingUKUS.country.countryCode}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Country</div>
                <div className="view-page-value">
                  {routingUKUS &&
                    routingUKUS.country &&
                    routingUKUS.country.countryName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Supplementary Indicator</div>
                <div className="view-page-value">
                  {routingUKUS && routingUKUS.supplementaryIndicator}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Consolidation Type</div>
                <div className="view-page-value">
                  {routingUKUS && routingUKUS.consolidationType}
                </div>
              </div>
            </div>
            <div className="view-page-headings">
              Criteria Setting Information
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No Of Orders From</div>
                <div className="view-page-value">
                  {routingUKUS && routingUKUS.noOfOrdersFrom}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No Of Orders To</div>
                <div className="view-page-value">
                  {routingUKUS && routingUKUS.noOfOrdersTo}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No Of Copies From</div>
                <div className="view-page-value">
                  {routingUKUS && routingUKUS.noOfCopiesFrom}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">No Of Copies To</div>
                <div className="view-page-value">
                  {routingUKUS && routingUKUS.noOfCopiesTo}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Weight From</div>
                <div className="view-page-value">
                  {addZeros(
                    routingUKUS && routingUKUS.weightFrom.toString(),
                    2
                  )}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Weight To</div>
                <div className="view-page-value">
                  {addZeros(routingUKUS && routingUKUS.weightTo.toString(), 2)}
                </div>
              </div>
            </div>
            <div className="view-page-headings">
              Routing Configurtion Information
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Route PPI</div>
                <div className="view-page-value">
                  {routingUKUS && routingUKUS.ppiNumber}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">PPI LicenseNo</div>
                <div className="view-page-value">
                  {routingUKUS && routingUKUS.ppiLicenseNo}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Dispatch Type</div>
                <div className="view-page-value">
                  {routingUKUS &&
                    routingUKUS.dispatchType &&
                    routingUKUS.dispatchType.dispatchTypeCodeName}
                </div>
              </div>

              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Dispatch Mode</div>
                <div className="view-page-value">
                  {routingUKUS &&
                    routingUKUS.dispatchMode &&
                    routingUKUS.dispatchMode.dispatchModeName}
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Mailing Scheme</div>
                  <div className="view-page-value">
                    {routingUKUS &&
                      routingUKUS.mailingScheme &&
                      routingUKUS.mailingScheme.mailingScheme}
                  </div>
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Distributor</div>
                <div className="view-page-value">
                  {routingUKUS &&
                    routingUKUS.distributor &&
                    routingUKUS &&
                    routingUKUS.distributor.distributorName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Return Address</div>
                <div className="view-page-value">
                  {routingUKUS && routingUKUS.returnAddress}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Carrier Sheet Scheme</div>
                <div className="view-page-value">
                  {routingUKUS && routingUKUS.carrierSheetScheme}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Carrier Sheet Mode</div>
                <div className="view-page-value">
                  {routingUKUS && routingUKUS.carrierSheetMode}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Active</div>
                <div className="view-page-value">{status1}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Is Periodical</div>
                <div className="view-page-value">{status}</div>
              </div>
            </div>
          </div>
          <Divider />
          <div className="button-wrapper">
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none", marginLeft: "2em" }}
                onClick={(e) => this.props.history.push("/routingUKUS")}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewRoutingUKUS);
