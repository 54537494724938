import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { StateContext } from "../../../shared/globalState";
import { styles } from "../../../shared/styles";
import { resourceValidation } from "../../../shared/resource";
import { serchCustomer } from "../masterActions";
const validateNotRequired2 = new Validator("notRequired2");
const validateNotRequired100 = new Validator("notRequired100");
const maxlength10Alphanumaric = new Validator("maxlength10Alphanumaric");

// const DialogTitle = withStyles(styles)(props => {
//   const { children, classes, onClose, ...other } = props;
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <Typography variant="h6">{children}</Typography>
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           className={classes.closeButton}
//           onClick={onClose}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

// const DialogContent = withStyles(theme => ({
//   root: {
//     padding: theme.spacing(2)
//   }
// }))(MuiDialogContent);

// const DialogActions = withStyles(theme => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1)
//   }
// }))(MuiDialogActions);
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        ></IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
class CustomerAdvanceSearch extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    customerCode: "",
    customerName: "",
    isVendor: false,
    billingAccountNo: "",
    ppiNumber: "",
    costCenterName: "",
    letterShop: "",
    errors: {
      customerCode: false,
      customerName: false,
      isVendor: false,
      billingAccountNo: false,
      ppiNumber: false,
      costCenterName: false,
      letterShop: false,
    },
  };

  componentDidMount() {
    // this.props.setSelectedList("masterDataOpen", "customer");
    //this.props.setSelectedList("masterDataOpen", "customer");
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      open: false,
      customerCode: "",
      customerName: "",
      isVendor: false,
      billingAccountNo: "",
      ppiNumber: "",
      costCenterName: "",
      letterShop: "",
    });
  };

  onInputChanged = (e, name, type) => {
    let newError = { ...this.state.errors };
    if (type === "notRequired2") {
      let result = validateNotRequired2(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else if (type === "maxlength10Alphanumaric") {
      let result = maxlength10Alphanumaric(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else if (type === "notRequired100") {
      let result = validateNotRequired100(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    }
    if (name === "isVendor") this.setState({ [name]: e.target.checked });
    else this.setState({ [name]: e.target.value });
  };
  onSearchClicked = () => {
    const dispatch = this.context[1];
    let {
      customerCode,
      customerName,
      isVendor,
      billingAccountNo,
      ppiNumber,
      letterShop,
      costCenterName,
    } = this.state;
    let reqBody = {
      customerCode,
      customerName,
      isVendor: isVendor === true ? "1" : null,
      billingAccountNo,
      ppiNumber,
      letterShop,
      costCenterName,
    };
    this.props.onSearchClicked();
    serchCustomer(reqBody).then((r) => {
      dispatch({
        type: "getCustomerList",
        newStore: r.data,
      });
      this.props.onRequestSucceed();
    });
    this.setState({
      open: false,
      customerCode: "",
      customerName: "",
      isVendor: false,
      billingAccountNo: "",
      ppiNumber: "",
      costCenterName: "",
      letterShop: "",
    });
  };
  checkButtonDisability = () => {
    let {
      customerCode,
      customerName,
      isVendor,
      billingAccountNo,
      ppiNumber,
      letterShop,
      costCenterName,
    } = this.state;
    // if (
    //   isVendor &&
    //   !this.state.errors.customerCode &&
    //   this.state.errors.customerName
    // )
    //   return false;
    if (
      this.state.errors.customerCode ||
      this.state.errors.customerName ||
      this.state.errors.isVendor ||
      this.state.errors.ppiNumber ||
      this.state.errors.billingAccountNo ||
      this.state.errors.costCenterName ||
      this.state.errors.letterShop ||
      (!customerCode &&
        !customerName &&
        !isVendor &&
        !ppiNumber &&
        !billingAccountNo &&
        !costCenterName &&
        !letterShop)
    )
      return true;
    if (isVendor) return false;
    else return false;
  };
  render() {
    let {
      customerCode,
      customerName,
      isVendor,
      billingAccountNo,
      ppiNumber,
      letterShop,
      costCenterName,
    } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div>
          <Tooltip title="Search">
            <IconButton
              aria-label="Search"
              className={classes.iconHover}
              onClick={this.handleClickOpen}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
            maxWidth="md"
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            >
              {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
              Advanced Search
            </DialogTitle>
            <DialogContent dividers>
              <div>
                <div>
                  <TextField
                    inputProps={{
                      maxLength: 10,
                    }}
                    label="Customer Code"
                    placeholder="Customer Code"
                    className={classes.textField}
                    margin="normal"
                    value={customerCode}
                    error={this.state.errors.customerCode}
                    onChange={(e) =>
                      this.onInputChanged(
                        e,
                        "customerCode",
                        "maxlength10Alphanumaric"
                      )
                    }
                    helperText={
                      this.state.errors.customerCode
                        ? resourceValidation.max10char
                        : ""
                    }
                  />
                  <TextField
                    label="Customer Name"
                    placeholder="Customer Name"
                    className={classes.textField}
                    margin="normal"
                    value={customerName}
                    error={this.state.errors.customerName}
                    onChange={(e) =>
                      this.onInputChanged(e, "customerName", "notRequired100")
                    }
                    helperText={
                      this.state.errors.customerName
                        ? resourceValidation.max100char
                        : ""
                    }
                  />

                  <FormControlLabel
                    style={{ marginTop: "2.2em", marginLeft: "-5px" }}
                    control={
                      <Checkbox
                        color="primary"
                        value={isVendor}
                        onChange={(e) => this.onInputChanged(e, "isVendor")}
                      />
                    }
                    label="Vendor"
                  />
                </div>
                <div>
                  <TextField
                    inputProps={{
                      maxLength: 10,
                    }}
                    label="Billing AC No"
                    placeholder="Billing AC No"
                    className={classes.textField}
                    margin="normal"
                    value={billingAccountNo}
                    error={this.state.errors.billingAccountNo}
                    onChange={(e) =>
                      this.onInputChanged(
                        e,
                        "billingAccountNo",
                        "maxlength10Alphanumaric"
                      )
                    }
                    helperText={
                      this.state.errors.billingAccountNo
                        ? resourceValidation.max10char
                        : ""
                    }
                  />
                  <TextField
                    inputProps={{
                      maxLength: 10,
                    }}
                    label="PPI No"
                    placeholder="PPI No"
                    className={classes.textField}
                    margin="normal"
                    value={ppiNumber}
                    error={this.state.errors.ppiNumber}
                    onChange={(e) =>
                      this.onInputChanged(
                        e,
                        "ppiNumber",
                        "maxlength10Alphanumaric"
                      )
                    }
                    helperText={
                      this.state.errors.ppiNumber
                        ? resourceValidation.max10char
                        : ""
                    }
                  />
                  <TextField
                    inputProps={{
                      maxLength: 10,
                    }}
                    label="Cost Center"
                    placeholder="Cost Center"
                    className={classes.textField}
                    margin="normal"
                    value={costCenterName}
                    error={this.state.errors.costCenterName}
                    onChange={(e) =>
                      this.onInputChanged(
                        e,
                        "costCenterName",
                        "maxlength10Alphanumaric"
                      )
                    }
                    helperText={
                      this.state.errors.costCenterName
                        ? resourceValidation.max100char
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    inputProps={{
                      maxLength: 10,
                    }}
                    label="Letter Shop"
                    placeholder="Letter Shop"
                    className={classes.textField}
                    margin="normal"
                    value={letterShop}
                    error={this.state.errors.letterShop}
                    onChange={(e) =>
                      this.onInputChanged(
                        e,
                        "letterShop",
                        "maxlength10Alphanumaric"
                      )
                    }
                    helperText={
                      this.state.errors.letterShop
                        ? resourceValidation.max10Char
                        : ""
                    }
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                //disableElevation
                style={{ boxShadow: "none" }}
                onClick={this.handleClose}
              >
                CANCEL
              </Button>
              {!isVendor && this.checkButtonDisability() ? (
                <Button
                  disabled
                  //autoFocus
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.onSearchClicked()}
                >
                  SEARCH
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.onSearchClicked()}
                >
                  SEARCH
                </Button>
              )}

              {/* <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={this.handleClose}
              >
                CANCEL
              </Button> */}
            </DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(CustomerAdvanceSearch));
