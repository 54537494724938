import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  Paper,
  Divider,
  Button,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { PrintIcon } from "../../../shared/customIcons";
import { stableSort, getSorting } from "../../../shared/tableCommonFunctions";
import { getViewEBLOByID } from "./action";
import { getCustomerForInsertMasterList } from "../../publisher/action";
import { printDetails } from "../../../shared/tableCommonFunctions";
import { getCountryDropdownList } from "../../masters/masterActions";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  emptyField: {
    marginRight: "16px",
    flexGrow: 1,
  },
  emptyFieldLast: {
    flexGrow: 1,
  },
  viewRow: {
    display: "flex",
    marginBottom: "30px",
  },
  viewRowLast: {
    display: "flex",
  },
  viewFlex: {
    flex: 1,
    marginRight: "16px",
  },
  viewFlexLast: {
    flex: 1,
  },
  iconHover: {
    "&:hover": {
      color: blue[800],
    },
  },
});

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ background: "#eaeaea" }}>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={
                  headCell.label == "Action"
                    ? "center"
                    : headCell.numeric
                      ? "right"
                      : "left"
                }
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
                style={{ width: headCell.width }}
              >
                {/* <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                > */}
                <span style={{ color: "#306dca" }}>{headCell.label}</span>
                {/* </TableSortLabel> */}
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

class ViewEbloOrderPlacement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customersList: [],
      countryObj: {},
      customerID: 0,
      customer: "",
      client: "",
      ebloNo: "",
      orderCreatedBy: "",
      department: "",
      orderDate: "",
      warehouseEmail: "",
      recipient: "",
      specialInstructions: "",
      deliveryMethod: "",
      PGSCountry: "",
      country: "",
      deliveryDetails: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      state: "",
      postCode: 0,
      countryID: "",
      countryCode: "",
      areaCode: "",
      city: "",
      number: "",
      journalDispatch: "",
      totalQuantity: "",
      ebloOrderDetailViewModel: [],
      // journalData: [
      //   {
      //     issueDescription: "rr_v_3434_i_3434",
      //     quantity: 9,
      //     trackingNumber: "LKJLK98768K",
      //     actualWeight: 0.45,
      //     deliveryDate: "2020-05-13T16:25:25.48"
      //   },
      //   {
      //     issueDescription: "0AMP02360000786",
      //     quantity: 4,
      //     trackingNumber: "LKJLK58768K",
      //     actualWeight: 0.65,
      //     deliveryDate: "2020-04-28T16:25:25.48"
      //   },
      //   {
      //     issueDescription: "00er0rer000wewe",
      //     quantity: 8,
      //     trackingNumber: "LKJLK98768K",
      //     actualWeight: 0.48,
      //     deliveryDate: "2020-02-13T16:25:25.48"
      //   },
      //   {
      //     issueDescription: "rr_v_3434_i_3434",
      //     quantity: 1,
      //     trackingNumber: "LKJRF98998K",
      //     actualWeight: 0.15,
      //     deliveryDate: "2020-01-13T16:25:25.48"
      //   },
      //   {
      //     issueDescription: "0AMP02360000786",
      //     quantity: 5,
      //     trackingNumber: "LKJLK98998K",
      //     actualWeight: 0.46,
      //     deliveryDate: "2019-12-13T16:25:25.48"
      //   }
      // ],
      headCellsInternal: [
        {
          id: 1,
          code: "issueDescription",
          numeric: false,
          disablePadding: false,
          label: "Issue Description",
          show: true,
          width: "260px",
        },
        {
          id: 2,
          code: "quantity",
          numeric: false,
          disablePadding: false,
          label: "Quantity*",
          show: true,
          width: "82px",
        },
        {
          id: 3,
          code: "trackingNumber",
          numeric: false,
          disablePadding: false,
          label: "Tracking Number",
          show: true,
          width: "230px",
        },
        {
          id: 4,
          code: "noOfCartons",
          numeric: false,
          disablePadding: false,
          label: "No Of Cartons",
          show: true,
          width: "230px",
        },
        {
          id: 5,
          code: "actualWeight",
          numeric: false,
          disablePadding: false,
          label: "Actual Weight",
          show: true,
          width: "116px",
        },
        {
          id: 6,
          code: "deliveryDate",
          numeric: false,
          disablePadding: false,
          label: "Delivery Date",
          show: true,
          width: "207px",
        },
        {
          id: 7,
          code: "dhlRate",
          numeric: false,
          disablePadding: false,
          label: "DHL Rate",
          show: true,
          width: "207px",
        },
        // {
        //   id: 8,
        //   code: "action",
        //   numeric: false,
        //   disablePadding: false,
        //   label: "Action",
        //   show: true,
        //   width: "120px",
        // },
      ],
      page: 0,
      rowsPerPage: 100,
      order: "desc",
      orderBy: "modifiedDate",
    };
  }

  componentDidMount() {
    this.props.handleSettingHeader("EBLO Order Placement");
    this.props.setSelectedList(
      "publisherBackLabelOpen",
      "pubEbloOrderPlacement"
    );

    this.getCustomerList();
  }

  getCustomerList = () => {
    getCustomerForInsertMasterList().then((r) => {
      r.data &&
        this.setState({
          customersList: r.data,
        });

      const filtered =
        r &&
        r.data &&
        r.data.reduce(
          (a, o) => (
            o.text.toLowerCase().indexOf("wiley") != -1 && a.push(o.id), a
          ),
          []
        );
      this.setState({
        customerId: filtered && filtered[0],
      });
      getCountryDropdownList().then((r) => {
        this.getAllCountries(r.data);
      });
    });
  };

  getAllCountries = (list) => {
    let countryList = [],
      countryObj = {};
    countryList = list && list;
    countryList.map((list) => {
      countryObj[list.id] = list && list.text && list.text.split("- ")[1];
    });
    this.setState({
      countryObj: countryObj,
    });
    this.getAllData();
  };

  getAllData = async () => {
    let ebloHeaderID = window.location.pathname.split("/")[2];
    await getViewEBLOByID(ebloHeaderID).then((r) => {
      this.setState({
        // ebloData: r,
        customerID: r.customerID,
        customer: this.state.customersList.filter((cust) => {
          return cust.id === r.customerID;
        }),
        client: r.client,
        ebloNo: r.ebloNo,
        orderCreatedBy: r.orderCreatedBy,
        department: r.department,
        orderDate: r.orderDate,
        warehouseEmail: r.warehouseEmail,
        recipient: r.recipient,
        specialInstructions: r.specialInstructions,
        deliveryMethod: r.deliveryMethod,
        PGSCountry: this.state.countryObj[r.countryID],
        country: r.countryName,
        deliveryDetails: r.deliveryDetails,
        // addressLine: r.addressLine,
        addressLine1: r.addressLine,
        addressLine2: r.addressLine2,
        addressLine3: r.addressLine3,
        state: r.state,
        postCode: r.postCode,
        countryID: r.countryID,
        countryCode: r.countryCode,
        areaCode: r.areaCode,
        city: r.city,
        number: r.number,
        journalDispatch: r.journalDispatch,
        totalQuantity: r.totalQuantity,
        ebloOrderDetailViewModel: r.ebloOrderDetailViewModel,
      });
    });
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  render() {
    const {
      customersList,
      customerID,
      client,
      ebloNo,
      orderCreatedBy,
      department,
      orderDate,
      warehouseEmail,
      recipient,
      specialInstructions,
      deliveryMethod,
      PGSCountry,
      country,
      deliveryDetails,
      addressLine1,
      addressLine2,
      addressLine3,
      state,
      postCode,
      countryID,
      countryCode,
      areaCode,
      city,
      number,
      journalDispatch,
      totalQuantity,
      ebloOrderDetailViewModel,
      rowsPerPage,
      page,
      orderBy,
      order,
    } = this.state;

    let { classes } = this.props;
    var publisherBackLabelManage = this.props.roles.filter((r) => {
      return r.superMenuName === "Publisher Back Label";
    });
    let blebloArr =
      publisherBackLabelManage.length > 0 &&
      publisherBackLabelManage.filter(
        (u) => u.menuName === "EBLO Order Placement"
      );
    var acnObj = blebloArr[0];

    return (
      <React.Fragment>
        <Paper>
          <div
            style={{ borderTop: "1px solid #eaeaea", padding: "0 8px 0 16px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                {acnObj && acnObj.printAction && (
                  <Tooltip
                    title="Print"
                    style={{
                      padding: "16px 9px 8px 15px",
                    }}
                  >
                    <IconButton
                      aria-label="print"
                      className={classes.iconHover}
                      onClick={(e) => printDetails()}
                    >
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">EBLO Details</div>
            {/* <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Customer</div>
                <div className="view-page-value">
                  {this.state.customer.length > 0
                    ? this.state.customer[0].text
                    : ""}
                </div>
              </div>
              <div className={classes.emptyField}></div>
              <div className={classes.emptyField}></div>
              <div className={classes.emptyFieldLast}></div>
            </div> */}
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">EBLO Number</div>
                <div className="view-page-value">{ebloNo ? ebloNo : ""}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Order Created By</div>
                <div className="view-page-value">
                  {orderCreatedBy ? orderCreatedBy : ""}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Department</div>
                <div className="view-page-value">
                  {department ? department : ""}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Order Date</div>
                <div className="view-page-value">
                  {orderDate ? moment(orderDate).format("DD/MM/YYYY") : ""}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Warehouse Email</div>
                <div className="view-page-value">
                  {warehouseEmail ? warehouseEmail : ""}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Recipient</div>
                <div className="view-page-value">
                  {recipient ? recipient : ""}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Special Instructions</div>
                <div className="view-page-value">
                  {specialInstructions ? specialInstructions : ""}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Delivery Method</div>
                <div className="view-page-value">
                  {deliveryMethod ? deliveryMethod : ""}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Delivery Details</div>
                <div className="view-page-value">
                  {deliveryDetails ? deliveryDetails : ""}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Address Line 1</div>
                <div className="view-page-value">
                  {addressLine1 ? addressLine1 : ""}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Address Line 2</div>
                <div className="view-page-value">
                  {addressLine2 ? addressLine2 : ""}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Address Line 3</div>
                <div className="view-page-value">
                  {addressLine3 ? addressLine3 : ""}
                </div>
              </div>

            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">State</div>
                <div className="view-page-value">{state ? state : ""}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Post Code</div>
                <div className="view-page-value">
                  {postCode ? postCode : ""}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">PGS Country</div>
                <div className="view-page-value">
                  {PGSCountry ? PGSCountry : ""}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Customer Country</div>
                <div className="view-page-value">{country ? country : ""}</div>
              </div>

            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Country Code</div>
                <div className="view-page-value">
                  {countryCode ? countryCode : ""}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Area Code</div>
                <div className="view-page-value">
                  {areaCode ? areaCode : ""}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">City</div>
                <div className="view-page-value">{city ? city : ""}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Phone Number</div>
                <div className="view-page-value">{number ? number : ""}</div>
              </div>


              {/* <div className={classes.emptyField}></div>
              <div className={classes.emptyFieldLast}></div> */}
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Total Quantity</div>
                <div className="view-page-value">
                  {totalQuantity ? totalQuantity : ""}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Journal Dispatch</div>
                <div className="view-page-value">
                  {journalDispatch ? journalDispatch : ""}
                </div>
              </div>
            </div>
            <div
              style={{
                // width: "100%",
                maxHeight: "300px",
                // overflowY: "auto",
                boxShadow: "0px 3px 4px #ddd",
              }}
              className="view-page-contents-container"
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
                style={{ whiteSpace: "nowrap" }}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  headCells={this.state.headCellsInternal}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {ebloOrderDetailViewModel.length > 0 ? (
                    stableSort(
                      ebloOrderDetailViewModel,
                      getSorting(order, orderBy)
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        if (row)
                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              style={{ cursor: "pointer" }}
                            >
                              {this.state.headCellsInternal[0].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-cell"
                                  align="left"
                                >
                                  {row.jouranlUniqueID ? row.jouranlUniqueID : ""}
                                </TableCell>
                              )}
                              {this.state.headCellsInternal[1].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-right-cell"
                                  align="right"
                                >
                                  {row.quantity ? row.quantity : ""}
                                </TableCell>
                              )}
                              {this.state.headCellsInternal[2].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-cell"
                                  align="left"
                                >
                                  {row.trackingNumber ? row.trackingNumber : ""}
                                </TableCell>
                              )}
                              {this.state.headCellsInternal[3].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-right-cell"
                                  align="left"
                                >
                                  {row.noOfCartons ? row.noOfCartons : ""}
                                </TableCell>
                              )}
                              {this.state.headCellsInternal[4].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-right-cell"
                                  align="right"
                                >
                                  {row.actualWeight
                                    ? parseFloat(row.actualWeight).toFixed(3)
                                    : "0.000"}
                                </TableCell>
                              )}
                              {this.state.headCellsInternal[5].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-cell"
                                  align="left"
                                >
                                  {row.deliveryDate
                                    ? moment(row.deliveryDate).format(
                                      "DD/MM/YYYY"
                                    )
                                    : ""}
                                </TableCell>
                              )}
                              {this.state.headCellsInternal[6].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-right-cell"
                                  align="left"
                                >
                                  {row.dhlRate ? row.dhlRate : ""}
                                </TableCell>
                              )}
                            </TableRow>
                          );
                      })
                  ) : (
                      <TableRow>
                        <TableCell colSpan={10} style={{ textAlign: "center" }}>
                          No Data
                      </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
            <Divider style={{ marginTop: "25px" }} />
          </div>
          <div className="button-wrapper" style={{ padding: "0 2em 1em 2em" }}>
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) =>
                  this.props.history.push("/pubEbloOrderPlacement")
                }
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(ViewEbloOrderPlacement));
