import React, { Component } from "react";

class StockIn extends Component {
  state = {};
  componentDidMount() {
    this.props.handleSettingHeader("Stock In Report");
    this.props.setSelectedList("masterDataOpen", "stockInReport");
  }
  render() {
    const url =  `${window.localStorage.ReportURLByID}` + "StockIn";
    return (
      <div>
        <iframe
          style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
          src={url}
        />
      </div>
    );
  }
}

export default StockIn;
