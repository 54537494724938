import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import clone from "clone"
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { styles } from "../../../shared/styles";
import { resourceValidation } from "../../../shared/resource";
import { StateContext } from "../../../shared/globalState";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { getCustomerList, getIMStatusList } from "../../masters/masterActions";
import {
  seacrhItemMasterAdvanceSearch,
  getInsertMasterList,
  getCustomerForInsertMasterList,
} from "../action";
import { Autocomplete } from "@material-ui/lab";
//import { getFliteredDeptList } from "../masterActions";
const validateNotRequired2 = new Validator("notRequired2");
const validateNotRequired100 = new Validator("notRequired100");
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        ></IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class InsertMasterSearch extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    statusList: [],
    statusObj: {},
    statusId: "",
    actualQty: "",
    customerId: "",
    customer: {},
    issueDescription: "",
    insertNo: "",
    acronym: "",
    volume: "",
    issue: "",
    suppNumber: "",
    modifiedDateFrom: null,
    modifiedDateTo: null,
    errors: {
      customerId: false,
      issueDescription: false,
      insertNo: false,
      acronym: false,
      volume: false,
      issue: false,
      suppNumber: false,
    },
    customersList: [],
  };

  componentDidMount() {
    getIMStatusList().then(r => {
      this.getAllStatus(r.data)
    })
    getCustomerForInsertMasterList().then((r) => {
      r &&
        r.data &&
        this.setState({
          customersList: r.data
            .filter((x) => x.text.toLowerCase().indexOf("wiley") != -1)
            .sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });

      const filtered =
        r &&
        r.data &&
        r.data.reduce(
          (a, o) => (
            o.text.toLowerCase().indexOf("wiley") != -1 && a.push(o.id), a
          ),
          []
        );
      this.setState({
        customerId: filtered && filtered[0],
      });
    });
  }
  handleAutocompleteCustomer = (e, val) => {
    this.setState({ customerId: val && val.id, customer: val && val });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      open: false,

      issueDescription: "",
      insertNo: "",
      acronym: "",
      volume: "",
      issue: "",
      suppNumber: "",
      modifiedDateFrom: null,
      modifiedDateTo: null,
      errors: {
        issueDescription: false,
        insertNo: false,
        acronym: false,
        volume: false,
        issue: false,
        suppNumber: false,
      },
    });
  };

  onInputChanged = (e, name, type) => {
    let newError = { ...this.state.errors };
    // if (type === "notRequired2") {
    //   let result = validateNotRequired2(e.target.value);
    //   newError[name] = !result;
    //   this.setState({ errors: newError });
    // } else if (type === "notRequired100") {
    //   let result = validateNotRequired100(e.target.value);
    //   newError[name] = !result;
    //   this.setState({ errors: newError });
    // }
    this.setState({ [name]: e.target.value });
  };
  onSearchClicked = () => {
    const dispatch = this.context[1];
    let {
      issueDescription,
      insertNo,
      acronym,
      volume,
      issue,
      suppNumber,
      modifiedDateFrom,
      modifiedDateTo,
      statusId,
      actualQty
    } = this.state;
    let reqBody = {
      issueDescription,
      insertNo,
      acronym,
      volume,
      issue,
      suppNumber,
      modifiedDateFrom,
      modifiedDateTo,
    };
    if (this.state.statusId)
      reqBody.status = this.state.statusId + ""
    if (this.state.actualQty)
      reqBody.actualQty = this.state.actualQty + ""
    this.props.onSearchClicked(reqBody);

    this.setState({
      open: false,

      issueDescription: "",
      insertNo: "",
      acronym: "",
      volume: "",
      issue: "",
      suppNumber: "",
      modifiedDateFrom: "",
      modifiedDateTo: "",
    });
  };
  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkButtonDisability = () => {
    let {
      issueDescription,
      insertNo,
      acronym,
      volume,
      issue,
      suppNumber,
      modifiedDateFrom,
      modifiedDateTo,
    } = this.state;
    if (
      !issueDescription &&
      !insertNo &&
      !acronym &&
      !volume &&
      !issue &&
      !suppNumber &&
      !(modifiedDateFrom && modifiedDateTo)
    )
      return true;
    else return false;
  };
  handleDateChange = (date) => {
    let { modifiedDateFrom } = this.state;
    modifiedDateFrom = date && date;
    this.setState({ modifiedDateFrom });
  };
  handleDateChange1 = (date) => {
    let { modifiedDateTo } = this.state;
    modifiedDateTo = date && date;
    this.setState({ modifiedDateTo });
  };
  getAllStatus = (list) => {
    let statusList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        statusList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(statusList, "statusList");
  };
  sortDDList = (list, name) => {
    let oldCus = clone(list);
    let z = list.sort(function (a, b) {
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ [name]: z });
  };
  handleAutocompletStatus = (e, val) => {
    this.setState({ statusId: val && val.key, statusObj: val && val });
  };
  render() {
    let {
      issueDescription,
      insertNo,
      acronym,
      volume,
      issue,
      suppNumber,
      modifiedDateFrom,
      modifiedDateTo,
      customersList,
    } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <Tooltip title="Search">
          <IconButton
            aria-label="Search"
            className={classes.iconHover}
            onClick={this.handleClickOpen}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          onClose={this.handleClose}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          maxWidth="md"
        >
          <DialogTitle id="customized-dialog-title">
            {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
            Advanced Search
          </DialogTitle>
          <DialogContent dividers>
            <div style={{ marginTop: "-25px" }}>
              <div style={{ display: "flex" }}>
                <TextField
                  id="standard-required"
                  label="Acronym"
                  name="acronym"
                  margin="normal"
                  value={this.state.acronym}
                  inputProps={{
                    maxLength: 50,
                  }}
                  style={{ flex: 1, marginRight: "16px" }}
                  autoComplete="off"
                  onChange={(e) =>
                    this.onInputChanged(e, "acronym", "notRequired2")
                  }
                />
                <TextField
                  id="standard-required"
                  label="Volume"
                  name="volume"
                  margin="normal"
                  value={this.state.volume}
                  inputProps={{
                    maxLength: 50,
                  }}
                  style={{ flex: 1, marginRight: "16px" }}
                  autoComplete="off"
                  onChange={(e) =>
                    this.onInputChanged(e, "volume", "notRequired2")
                  }
                />
                <TextField
                  id="standard-required"
                  label="Issue"
                  name="issue"
                  margin="normal"
                  value={this.state.issue}
                  inputProps={{
                    maxLength: 50,
                  }}
                  style={{ flex: 1 }}
                  autoComplete="off"
                  onChange={(e) =>
                    this.onInputChanged(e, "issue", "notRequired2")
                  }
                />
              </div>
              <div style={{ display: "flex" }}>
                {/* <Autocomplete
                  options={customersList}
                  value={this.state.customer}
                  onChange={this.handleAutocompleteCustomer}
                  getOptionLabel={(option) => option.text}
                  style={{flex: 1, marginRight: "16px"}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Customer"
                      margin="normal"
                      required
                      fullWidth
                    />
                  )}
                /> */}
                <TextField
                  id="standard-required"
                  label="Issue Description"
                  name="issueDescription"
                  style={{ flex: 1, marginRight: "16px" }}
                  margin="normal"
                  value={this.state.issueDescription}
                  inputProps={{
                    maxLength: 50,
                  }}
                  autoComplete="off"
                  onChange={(e) =>
                    this.onInputChanged(e, "issueDescription", "notRequired2")
                  }
                />
                <TextField
                  id="standard-required"
                  label="Insert No."
                  name="insertNo"
                  margin="normal"
                  value={this.state.insertNo}
                  inputProps={{
                    maxLength: 50,
                  }}
                  style={{ flex: 1, marginRight: "16px" }}
                  autoComplete="off"
                  onChange={(e) =>
                    this.onInputChanged(e, "insertNo", "notRequired2")
                  }
                />
                <TextField
                  id="standard-required"
                  label="Supplement"
                  name="suppNumber"
                  margin="normal"
                  value={this.state.suppNumber}
                  inputProps={{
                    maxLength: 50,
                  }}
                  style={{ flex: 1 }}
                  autoComplete="off"
                  onChange={(e) =>
                    this.onInputChanged(e, "suppNumber", "notRequired2")
                  }
                />
              </div>
              <div style={{ display: "flex" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    id="modifiedDateFrom"
                    label="Modified Date From"
                    onChange={this.handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    value={this.state.modifiedDateFrom}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disabled={!this.state.modifiedDateFrom}
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    id="modifiedDateTo"
                    label="Modified Date To"
                    minDate={this.state.modifiedDateFrom}
                    value={this.state.modifiedDateTo}
                    onChange={this.handleDateChange1}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
                <div style={{ flex: 1 }}>
                  <Autocomplete
                    options={this.state.statusList}
                    value={this.state.statusObj}
                    onChange={this.handleAutocompletStatus}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Status"
                        // InputProps={{ readOnly: true }}
                        style={{
                          width: "100%",
                          fontSize: "11px !important",
                          // marginLeft: ".5em",
                          // marginRight: ".5em",
                          maxHeight: "400px",
                        }}
                        // name="plannerId"
                        margin="normal"
                      />
                    )}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ flex: "1" }}>
                  <TextField
                    id="standard-required"
                    label="Actual Qty"
                    name="actualQty"
                    margin="normal"
                    value={this.state.actualQty}
                    inputProps={{
                      maxLength: 50,
                    }}
                    style={{ flex: 1 }}
                    autoComplete="off"
                    onChange={(e) =>
                      this.onInputChanged(e, "actualQty", "notRequired10")
                    }
                  />
                </div>
                <div style={{ flex: "1" }}></div>
                <div style={{ flex: "1" }}></div>
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ padding: "10px 24px" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={this.handleClose}
            >
              CANCEL
            </Button>

            {/* {this.checkButtonDisability() ? ( */}
            <Button
              disabled={
                !(modifiedDateFrom
                  ? modifiedDateTo
                  : issueDescription ||
                  insertNo ||
                  acronym ||
                  volume ||
                  issue ||
                  this.state.statusId ||
                  this.state.actualQty ||
                  suppNumber)
              }
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={(e) => this.onSearchClicked("preAlert")}
            >
              SEARCH
            </Button>
            {/* ) : (
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.onSearchClicked("preAlert")}
              >
                SEARCH
              </Button>
            )} */}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(InsertMasterSearch);
