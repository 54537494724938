import axios from "axios";
import toastr from "toastr";
var jwt = require("jsonwebtoken");
const baseURL = process.env.REACT_APP_PGS_SERVER_USER_ACCESS_MANAGEMENT;
const baseURL_ADFS = process.env.REACT_APP_PGS_SERVER_ADFS;

const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

/*------------User functions starts -------------------------*/

export const getPlannerList = async () => {
  let plannerList = [];
  await pgsService
    .get("/PlannerDropdown")
    .then((r) => {
      plannerList = r;
    })
    .catch((e) => { });
  return plannerList;
};
export const getPlannerBaseOnCustomerList = async (id) => {
  let plannerList = [];
  await pgsService
    .get("/GetPlannerBaseOnCustomer/" + id)
    .then((r) => {
      plannerList = r;
    })
    .catch((e) => { });
  return plannerList;
};
export const getUserList = async () => {
  let UserList = [];
  await pgsService
    .get("/Users")
    .then((r) => {
      UserList = r;
    })
    .catch((e) => { });
  return UserList;
};
export const postUser = async (data, history) => {
  await pgsService
    .post("/Users", data)
    .then((r) => {
      history.push("/user");
      toastr.success("User Created Successfully");
    })
    .catch((e) => {
      console.log(e.response.data);
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateUser = async (UserId, data, history) => {
  await pgsService
    .put("/Users/" + UserId, data)
    .then((r) => {
      history.push("/user");
      toastr.success("User Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getUserDetails = async (UserId) => {
  let User = {};
  await pgsService
    .get("/Users/" + UserId)
    .then((response) => {
      User = response.data;
    })
    .catch((e) => { });
  return User;
};

export const getFliteredUserList = async (reqBody) => {
  let UserList = [];
  await pgsService
    .post("/UsersAdvanceSerach", reqBody)
    .then((r) => {
      UserList = r;
    })
    .catch((e) => { });
  return UserList;
};

export const deleteUser = async (deletionIds) => {
  let UserList = [];
  let modifiedBy = localStorage.loggedInId;
  await pgsService
    .delete("/Users", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("User Status Changed To Inactive");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return UserList;
};

//-------------------------User ends---------------------//

export const getRoleList = async () => {
  let roleList = [];
  await pgsService
    .get("/Roles")
    .then((r) => {
      roleList = r;
    })
    .catch((e) => { });
  return roleList;
};

export const getRole = async (roleId) => {
  let role = {};
  await pgsService
    .get("/Roles/" + roleId)
    .then((response) => {
      let data = response.data;
      role = {
        roleID: data.roleID,
        roleName: data.roleName,
        description: data.description,
        orderNo: data.orderNo,
        isReport: data.isReport,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });
  return role;
};

export const deleteRole = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/Roles`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Role Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const roleCheck = async (data) => {
  let result = "";
  await pgsService.post("/IsRoleNameExist/" + data).then((r) => {
    result = r.data;
  });
  return result;
};

export const roleEditCheck = async (data, id) => {
  let result = "";
  await pgsService.post(`/IsRoleNameUpdateExist/${data}/${id}`).then((r) => {
    result = r.data;
  });
  return result;
};

export const postRole = async (data) => {
  await pgsService
    .post("/Roles", data)
    .then((r) => {
      toastr.success("Role Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateRoleID = async (roleId, data) => {
  await pgsService
    .put("/Roles/" + roleId, data)
    .then((r) => {
      toastr.success("Role Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getRoleActionList = async () => {
  let roleActionList = [];
  await pgsService
    .get("/RoleActions")
    .then((r) => {
      roleActionList = r;
    })
    .catch((e) => { });
  return roleActionList;
};

export const getMasterRoleActionList = async () => {
  let masterRoleActionList = [];
  await pgsService
    .get("/MasterRoleActions")
    .then((r) => {
      masterRoleActionList = r;
    })
    .catch((e) => { });
  return masterRoleActionList;
};

export const getRoleAction = async (roleActionId) => {
  let roleAction = {};
  await pgsService
    .get("/RoleActions/" + roleActionId)
    .then((response) => {
      let data = response.data;
      roleAction = {
        roleID: data.roleID,
        roleName: data.roleName,
        menuID: data.menuID,
        menuName: data.menuName,
        subMenuName: data.subMenuName,
        subMenuID: data.subMenuID,
        viewAction: data.viewAction,
        addAction: data.addAction,
        editAction: data.editAction,
        deleteAction: data.deleteAction,
        advanceSearchAction: data.advanceSearchAction,
        exportAction: data.exportAction,
        approveAction: data.approveAction,
        reportAction: data.reportAction,
        printAction: data.printAction,
        description: data.description,
        orderNo: data.orderNo,
        isReport: data.isReport,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });
  return roleAction;
};

export const getRoleActionByRoleId = async (roleId) => {
  let roleActionByRoleList = {};
  await pgsService
    .get("/RoleActionsByRole/" + roleId)
    .then((response) => {
      roleActionByRoleList = response;
    })
    .catch((e) => { });
  return roleActionByRoleList;
};

export const deleteRoleAction = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/RoleActions`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Role Action Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const postRoleAction = async (data) => {
  await pgsService
    .post("/RoleActions", data)
    .then((r) => {
      toastr.success("Role Action Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateRoleActionID = async (roleActionId, data) => {
  await pgsService
    .put("/RoleActions/" + roleActionId, data)
    .then((r) => {
      toastr.success("Role Action Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateRoleActionByRoleID = async (data) => {
  await pgsService
    .put("/UpdateRoleActionsByRole", data)
    .then((r) => {
      toastr.success("Role Action Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getSuperMenuList = async () => {
  let superMenuList = [];
  await pgsService
    .get("/SuperMenus")
    .then((r) => {
      superMenuList = r;
    })
    .catch((e) => { });
  return superMenuList;
};

export const getSuperMenu = async (superMenuId) => {
  let superMenu = {};
  await pgsService
    .get("/SuperMenus/" + superMenuId)
    .then((response) => {
      let data = response.data;
      superMenu = {
        superMenuID: data.superMenuID,
        superMenuName: data.superMenuName,
        description: data.description,
        orderNo: data.orderNo,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });
  return superMenu;
};

export const deleteSuperMenu = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/SuperMenus`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Super Menu Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const postSuperMenu = async (data) => {
  await pgsService
    .post("/SuperMenus", data)
    .then((r) => {
      toastr.success("Super Menu Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateSuperMenuID = async (superMenuId, data) => {
  await pgsService
    .put("/SuperMenus/" + superMenuId, data)
    .then((r) => {
      toastr.success("Super Menu Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getMenuList = async (token) => {
  let menuList = [];
  await pgsService
    .get("/Menus")
    .then((r) => {
      menuList = r;
    })
    .catch((e) => { });
  return menuList;
};

export const getMenu = async (menuId) => {
  let menu = {};
  await pgsService
    .get("/Menus/" + menuId)
    .then((response) => {
      let data = response.data;
      menu = {
        menuID: data.menuID,
        superMenuID: data.superMenuID,
        superMenuName: data.superMenuName,
        menuName: data.menuName,
        description: data.description,
        orderNo: data.orderNo,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });
  return menu;
};

export const deleteMenu = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/Menus`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Menu Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const postMenu = async (data) => {
  await pgsService
    .post("/Menus", data)
    .then((r) => {
      toastr.success("Menu Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateMenuID = async (menuId, data) => {
  await pgsService
    .put("/Menus/" + menuId, data)
    .then((r) => {
      toastr.success("Menu Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getSubMenuList = async () => {
  let subMenuList = [];
  await pgsService
    .get("/SubMenus")
    .then((r) => {
      subMenuList = r;
    })
    .catch((e) => { });
  return subMenuList;
};

export const getSubMenu = async (subMenuId) => {
  let subMenu = {};
  await pgsService
    .get("/SubMenus/" + subMenuId)
    .then((response) => {
      let data = response.data;
      subMenu = {
        subMenuID: data.subMenuID,
        menuID: data.menuID,
        menuName: data.menuName,
        subMenuName: data.subMenuName,
        description: data.description,
        orderNo: data.orderNo,
        isReport: data.isReport,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });
  return subMenu;
};

export const deleteSubMenu = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/SubMenus`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Sub Menu Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const postSubMenu = async (data) => {
  await pgsService
    .post("/SubMenus", data)
    .then((r) => {
      toastr.success("Sub Menu Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateSubMenuID = async (subMenuId, data) => {
  await pgsService
    .put("/SubMenus/" + subMenuId, data)
    .then((r) => {
      toastr.success("Sub Menu Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const postLogin = async (data, history,) => {
  let dataLogin = {};
  await pgsService
    .post("/Login", data)
    .then((r) => {
      // const dispatch = this.context[1];
      let base64Url = r.data.token.split(".")[1]; // token you get
      let base64 = base64Url.replace("-", "+").replace("_", "/");
      let decodedData = JSON.parse(
        Buffer.from(base64, "base64").toString("binary")
      );
      let decoded_time = Math.round(decodedData.exp * 1000);
      let issued_time = Math.round(decodedData.iat * 1000);
      localStorage.setItem("sub", decodedData.sub);
      localStorage.setItem("token", r.data.token);
      localStorage.setItem("loggedInId", decodedData.id);
      localStorage.setItem("refreshToken", r.data.refreshToken);
      localStorage.setItem("department", decodedData.Department);
      localStorage.setItem("departmentCode", decodedData.DepartmentCode);
      localStorage.setItem("PwdExpiryNotification", decodedData.PwdExpiryNotification);
      localStorage.setItem("refreshTime", decodedData.refreshTime);
      localStorage.setItem("ReportURLByID", "https://pgsapi.postsvcs.com/report/ReportViewer?Reportname=");
      localStorage.setItem("ExpiryTime", decoded_time);
      localStorage.setItem("IssuedTime", issued_time);
      localStorage.setItem("info", r.data.token.split(".")[1]);
      dataLogin = { r };
      if (r.status === 200 && decodedData.FirstTimeLogin !== "") {

        localStorage.setItem("userName", data.userLoginID);
        localStorage.setItem("isSingpostUser", false);
        localStorage.setItem("auth", true);
        let uObj = {
          user: data.userLoginID,
          isSingPost: "false"
        }
        localStorage.setItem("userData", btoa(JSON.stringify(uObj)));
        history.push("/");

      } else if (r.status === 200 && decodedData.FirstTimeLogin === "") {
        history.push("/ChangePassword");
      }
    })
    .catch((e) => {
      if (e.response && e.response.message !== undefined) {
        toastr.error(e.response.message);
      }
      //  localStorage.setItem("error", e.response.message);
      return (dataLogin = e.response);
    });

  return dataLogin;
};

export const postLoginUN = async (data, history) => {
  let dataLogin = {};
  await pgsService
    .post("/IsSingPostUser", data)
    .then((r) => {
      dataLogin = { r };
      if (r.status === 200) {
        return (dataLogin = r);
      }
    })
    .catch((e) => {
      if (e.response && e.response.message !== undefined) {
        toastr.error(e.response.message);
      }
      //  localStorage.setItem("error", e.response.message);
      return (dataLogin = e.response);
    });

  return dataLogin;
};

export const SingPostUserLogin = async (data, history) => {
  let dataLogin = {};
  await pgsService
    .post("/SingPostUserLogin", data)
    .then((r) => {
      let base64Url = r.data.token.split(".")[1];
      let base64 = base64Url.replace("-", "+").replace("_", "/");
      let decodedData = JSON.parse(
        Buffer.from(base64, "base64").toString("binary")
      );
      let decoded_time = Math.round(decodedData.exp * 1000);
      let issued_time = Math.round(decodedData.iat * 1000);
      localStorage.setItem("sub", decodedData.sub);
      localStorage.setItem("token", r.data.token);
      localStorage.setItem("loggedInId", decodedData.id);
      localStorage.setItem("refreshToken", r.data.refreshToken);
      localStorage.setItem("department", decodedData.Department);
      localStorage.setItem("departmentCode", decodedData.DepartmentCode);
      localStorage.setItem("PwdExpiryNotification", decodedData.PwdExpiryNotification);
      localStorage.setItem("refreshTime", decodedData.refreshTime);
      // localStorage.setItem("ReportURLByID", decodedData.ReportURLByID);
      localStorage.setItem("ReportURLByID", "https://pgsapi.postsvcs.com/report/ReportViewer?Reportname=");

      localStorage.setItem("ExpiryTime", decoded_time);
      localStorage.setItem("IssuedTime", issued_time);
      localStorage.setItem("info", r.data.token.split(".")[1]);

      dataLogin = { r };
      if (r.status === 200) {

        localStorage.setItem("userName", data.userName);
        localStorage.setItem("isSingpostUser", true);
        localStorage.setItem("auth", true);
        let uObj = {
          user: data.userName,
          isSingPost: "true"
        }
        localStorage.setItem("userData", btoa(JSON.stringify(uObj)));
        history.push("/");
      }
    })
    .catch((e) => {
      if (e.response && e.response.message !== undefined) {
        toastr.error(e.response.message);
      }
      //  localStorage.setItem("error", e.response.message);
      return (dataLogin = e.response);
    });

  return dataLogin;
};

export const postForgotPassword = async (data, history) => {
  let dataLogin = {};
  await pgsService
    .post("/Login/ForgotPassword", data)
    .then((r) => {
      dataLogin = true;
    })
    .catch((e) => {
      dataLogin = e.response;
    });
  return dataLogin;
};
export const postChangePassword = async (data, history) => {
  let dataLogin = {};
  await pgsService
    .post("/Login/ChangePassword", data)
    .then((r) => {
      if (r.data !== false) {
        dataLogin = r.response
        history.push("/");
      } else {
        // toastr.error("Old Password Mismatch");
        dataLogin = r.response;
      }
      return (dataLogin = r.response);

    })
    .catch((e) => {
      if (e.response && e.response.message !== undefined) {
        toastr.error(e.response.message);
      }
      // toastr.error("Old Password Mismatch");
      return (dataLogin = e.response);
    });
  return dataLogin;
};
export const validateChangePassword = async (data,) => {
  let dataLogin = {};
  await pgsService
    .post("/ValidateUserFromEmailURL", data)
    .then((r) => {
      dataLogin = r
    })
    .catch((e) => {

    });
  return dataLogin;
};

export const postLoginRefresh = async (data, history) => {
  let isSucceed;
  await pgsService
    .post("/Login/refresh", data)
    .then((r) => {
      // alert("success");
      console.log("e", r);
      localStorage.setItem("token", r.data.token);
      // localStorage.setItem("loggedInId", decodedData.id);
      localStorage.setItem("refreshToken", r.data.refreshToken);
      isSucceed = true;
    })
    .catch((e) => {
      if (
        (e.response.data &&
          e.response.data.errors &&
          e.response.data.errors[0] === "This refresh token does not exist") ||
        (e.response.data &&
          e.response.data.errors &&
          e.response.data.errors[0] === "This refresh token has expired") ||
        (e.response.data &&
          e.response.data.errors &&
          e.response.data.errors[0] ===
          "This refresh token has been invalidated")
      ) {
        toastr.error("Session time out!");
        const isSingpostUser = localStorage.getItem("isSingpostUser");
        localStorage.clear();
        if (isSingpostUser == "true")
          window.location.replace(baseURL_ADFS + "/Home/logout");
        else history.push("/");
        isSucceed = false;
      }
    });
  return (isSucceed);
};

// user role
export const getRoleAccess = async (userID) => {
  let roleAccess = [];
  if (userID)
    await pgsService
      .get("/GetUserRoleAccessList/" + userID)
      .then((response) => {
        roleAccess = response.data;
        // roleAccess = data.map(val => {
        //   roleAccess.push({
        //     roleMappingID: val.roleMappingID,
        //     roleID: val.roleID,
        //     roleName: val.roleName,
        //     menuID: val.menuID,
        //     menuName: val.menuName,
        //     subMenuID: val.subMenuID,
        //     subMenuName: val.subMenuName,
        //     superMenuID: val.superMenuID,
        //     superMenuName: val.superMenuName,
        //     viewAction: val.viewAction,
        //     addAction: val.addAction,
        //     editAction: val.editAction,
        //     deleteAction: val.deleteAction,
        //     advanceSearchAction: val.advanceSearchAction,
        //     exportAction: val.exportAction,
        //     approveAction: val.approveAction,
        //     reportAction: val.reportAction,
        //     printAction: val.printAction,
        //     description: val.description,
        //     isActive: val.isActive,
        //     isReport: val.isReport
        //   });
        // });
      })
      .catch((e) => { });
  return roleAccess;
};
