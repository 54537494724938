import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import clone from "clone";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import moment from "moment";
import {
  ViewColumnIcon,
  ViewIcon,
  EditIcon,
  AddIcon,
  SearchIcon,
} from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import AdvanceSearch from "../user/UserAdvSearch";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import FormGroup from "@material-ui/core/FormGroup";
import {
  getUserList,
  deleteUser,
  getFliteredUserList,
} from "../userAccessAction";
import {
  stableSort,
  getSorting,
  DialogActions,
} from "../../../shared/tableCommonFunctions";
import "../../app/App.css";
import { StateContext } from "../../../shared/globalState";
import { Link } from "react-router-dom";
import { ExportCSV } from "../../../shared/ExportCSV";

const columns = [
  {
    id: 10,
    name: "EMail ID",
  },
  {
    id: 11,
    name: "Active",
  },
  {
    id: 12,
    name: "Modified Date",
  },
  {
    id: 13,
    name: "Modified By",
  },
  {
    id: 14,
    name: "Created Date",
  },
  {
    id: 15,
    name: "Created By",
  },
];

let initailData = [];

function createData(
  userId,
  fName,
  mName,
  lName,
  loginUserID,
  isSingpostUser,
  customerID,
  custName,
  custPpiID,
  customerPPI,
  roleID,
  roleName,
  deptID,
  department,
  emailID,
  isActive,
  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate
) {
  return {
    userId,
    fName,
    mName,
    lName,
    loginUserID,
    isSingpostUser,
    customerID,
    custName,
    custPpiID,
    customerPPI,
    roleID,
    roleName,
    deptID,
    department,
    emailID,
    isActive,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    heafNameells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all users" }}
              />
            }
          />
        </TableCell>
        {heafNameells.map((heafNameell, i) => {
          return (
            heafNameell.show === true && (
              <TableCell
                key={heafNameell.id}
                align={heafNameell.numeric ? "right" : "left"}
                padding={heafNameell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === heafNameell.code ? order : false}
                className={
                  i !== heafNameells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === heafNameell.id}
                  direction={order}
                  onClick={createSortHandler(heafNameell.code)}
                >
                  <span style={{ color: "#306dca" }}>{heafNameell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handleDeleteUser,
    selected,
    handleViewColumns,
    isActive,
    usersList,
    exportDataValues,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  let acnArr = props.roles.filter((u) => u.menuName === "User");
  let acnObj = acnArr[0];

  const selectedUserId = selected[0];
  const selectedUser = usersList.find((x) => x.userId === selectedUserId);
  let expData = clone(usersList);
  expData.map((d) => {
    let Val = "No";
    if (d["isActive"]) {
      Val = "Yes";
    }
    let val = "No";
    if (d["isSingpostUser"]) {
      val = "Yes";
    }
    d["First Name"] = d["fName"];
    d["Middle Name"] = d["mName"];
    d["Last Name"] = d["lName"];
    d["Username"] = d["loginUserID"];
    d["Singpost User"] = val;
    //d["Customer ID"] = d["customerID"];
    d["Customer"] = d["custName"];
    //d["Customer PPI"] = d["custPpiID"];
    d["Customer PPI"] = d["customerPPI"];
    d["Role Name"] = d["roleName"];
    d["Department"] = d["department"];
    d["EMail ID"] = d["emailID"];

    d["Active"] = Val;

    d["Modified Date"] = moment(d["modifiedDate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    );
    d["Modified By"] = d["modifiedBy"];

    d["Created Date"] = moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss");
    d["Created By"] = d["createdBy"];

    delete d["fName"];

    delete d["mName"];

    delete d["lName"];

    delete d["loginUserID"];

    delete d["isSingpostUser"];
    delete d["customerID"];

    delete d["custName"];

    delete d["custPpiID"];

    delete d["customerPPI"];

    delete d["roleName"];

    delete d["department"];

    delete d["emailID"];

    delete d["isActive"];

    delete d["createdBy"];

    delete d["createdDate"];

    delete d["modifiedBy"];

    delete d["modifiedDate"];
    delete d["userId"];
    delete d["deptID"];
    delete d["roleID"];
  });
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteUser = () => {
    handleDeleteUser();
  };
  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_user/${selectedUserId}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <Link to={`/edit_user/${selectedUserId}`}>
                      <IconButton
                        aria-label="edit"
                        className={classes.iconHover}
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={handleClickOpen}
                  disabled={!isActive}
                >
                  <DeleteIcon className={classes.iconHover} />
                </IconButton>
              </Tooltip>
            )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete User
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1 ? " ?" : selectedUser.loginUserID + " ?"}
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteUser}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {props.searchClicked ? (
              <Tooltip title="Clear search result">
                <IconButton
                  aria-label="clear"
                  className={classes.iconHover}
                  onClick={props.clearSearch}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            ) : (
              acnObj &&
              acnObj.advanceSearchAction && (
                <AdvanceSearch onSearchClicked={props.onSearchClicked} />
              )
              // <Tooltip title="Search">
              //   <IconButton
              //     aria-label="Search"
              //     className={classes.iconHover}
              //     onSearchClicked={props.onSearchClicked}
              //   >
              //     <SearchIcon />
              //   </IconButton>
              // </Tooltip>
            )}
            {acnObj && acnObj.addAction && (
              <Tooltip title="Add">
                <IconButton
                  className={classes.iconHover}
                  aria-label="add"
                  onClick={(e) => props.history.push("/add_user")}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
            {acnObj && acnObj.exportAction && (
              <Tooltip>
                <ExportCSV csvData={expData} fileName={"users"} />
              </Tooltip>
            )}

            <Tooltip title="View Column">
              <IconButton
                aria-label="viewColumn"
                className={classes.iconHover}
                onClick={handleClickListItem}
              >
                <ViewColumnIcon />
              </IconButton>
            </Tooltip>
            <EnhancedShowColumn
              id="columns-menu"
              keepMounted
              open={open}
              onClose={handleShowColClose}
              column={column}
              handleViewColumns={handleViewColumns}
              heafNameells={props.heafNameells}
            />
          </div>
        )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const left = 78;
  const bottom = 25;

  return {
    left: `${left}%`,
    bottom: `${bottom}%`,
    fontSize: 14,
  };
}

const EnhancedShowColumn = (props) => {
  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    heafNameells,
    handleViewColumns,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };
  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      heafNameells[column.id - 1] &&
                      heafNameells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => handleViewColumns(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

class UserMaster extends React.Component {
  static contextType = StateContext;

  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    usersList: [],
    selectedUser: {},
    activeRows: 0,
    isActive: false,
    loading: false,
    selectedShowColumns: [],
    exportDataValues: [],
    heafNameells: [
      {
        id: 1,
        code: "fName",
        numeric: false,
        disablePadding: true,
        label: "First Name",
        show: true,
      },

      {
        id: 2,
        code: "mName",
        numeric: false,
        disablePadding: false,
        label: "Middle Name",
        show: true,
      },

      {
        id: 3,
        code: "lName",
        numeric: false,
        disablePadding: false,
        label: "Last Name",
        show: true,
      },
      {
        id: 4,
        code: "loginUserID",
        numeric: false,
        disablePadding: false,
        label: "Username",
        show: true,
      },
      {
        id: 5,
        code: "isSingpostUser",
        numeric: false,
        disablePadding: false,
        label: "Singpost User",
        show: true,
      },
      {
        id: 6,
        code: "custName",
        numeric: false,
        disablePadding: false,
        label: "Customer",
        show: true,
      },
      {
        id: 7,
        code: "customerPPI",
        numeric: false,
        disablePadding: false,
        label: "Customer PPI",
        show: true,
      },
      {
        id: 8,
        code: "roleName",
        numeric: false,
        disablePadding: false,
        label: "Role Name",
        show: true,
      },
      {
        id: 9,
        code: "department",
        numeric: false,
        disablePadding: false,
        label: "Department",
        show: true,
      },
      {
        id: 10,
        code: "emailID",
        numeric: false,
        disablePadding: false,
        label: "EMail ID",
        show: true,
      },
      {
        id: 11,
        code: "isActive",
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true,
      },
      {
        id: 12,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 13,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 14,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 15,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };
  handleViewColumns = (e, id) => {
    let newheafNameells = [...this.state.heafNameells];
    newheafNameells[id - 1].show = e.target.checked;
    this.setState({ heafNameells: newheafNameells });
  };

  componentDidMount() {
    this.props.handleSettingHeader("User");
    this.props.setSelectedList("masterDataOpen", "user");
    const dispatch = this.context[1];

    getUserList(localStorage.token).then((r) => {
      dispatch({
        type: "getUserList",
        newStore: r.data,
      });
      this.getAllUsers(r.data);
      this.exportUserDatas(r.data);
    });
    this.setState({ loading: true });
  }
  shouldComponentUpdate(p, s) {
    const { store } = this.context[0];
    if (store.usersList && store.usersList.length > 0)
      if (this.state.usersList.length !== store.usersList.length) {
        !this.state.searchClicked && this.getAllUsers(store.usersList);
      }
    return true;
  }

  exportUserDatas = (userList) => {
    let list = userList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          FirstName: d.fName,
          MiddleName: d.mName,
          LastName: d.lName,
          LoginUserID: d.loginUserID,
          SingpostUser: d.isSingpostUser === true ? "Yes" : "No",
          Customer: d.customer,
          CustomerPPINumber: d.customerPPI,
          Role: d.roleName,
          Department: d.department,
          EmailID: d.eMailID,
          Active: d.isActive === true ? "Yes" : "No",
          CreatedBy: d.createdBy,
          CreatedDate: d.createdDate,
          ModifiedBy: d.modifiedBy,
          ModifiedDate: d.modifiedDate,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getAllUsers = (usersList) => {
    let list = usersList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.userId,
            d.fName,
            d.mName,
            d.lName,
            d.loginUserID,
            d.isSingpostUser,
            d.customerID,
            d.customer,
            d.customerPPIID,
            d.customerPPI,
            d.roleID,
            d.roleName,
            d.departmentID,
            d.department,
            d.eMailID,
            d.isActive,
            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate
          )
        );
      });
    this.sortStateList(rows);
    this.setState({
      usersList: rows,
      loading: false,
      selected: [],
      activeRows: rows.filter((x) => x.isActive).map((n) => n.userId),
    });
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };
  sortStateList = (list) => {
    let z = list.sort(function (a, b) {
      let x = a.isActive && a.isActive;
      let y = b.isActive && b.isActive;
      let u = a.modifiedDate && a.modifiedDate;
      let v = b.modifiedDate && b.modifiedDate;
      if (x < y) {
        return 1;
      }
      if (u < v) {
        return -1;
      }
      if (x > y) {
        return -1;
      }
      if (u > v) {
        return 1;
      }
      return 0;
    });
    // console.log(z);
    this.setState({ rows: z });
  };

  handleSelectAllClick = (event) => {
    let { usersList } = this.state;
    if (event.target.checked) {
      const newSelecteds = usersList
        .filter((x) => x.isActive === true)
        .map((n) => n.userId);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, userId, userName) {
    let { selected } = this.state;

    const selectedIndex = selected.indexOf(userId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, userId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedUser: userName,
        selectedUserId: userId,
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedUser: userName,
        selectedUserId: userId,
      });
    }
  }
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleDeleteUser = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let deletionIds = selected.join(",");
    deleteUser(deletionIds).then((r) => {
      getUserList().then((r) => {
        dispatch({
          type: "getUserList",
          newStore: r.data,
        });
        this.getAllUsers(r.data);
      });
      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };

  onSearchClicked = (fName, lName, loginUserID, isSingpostUser) => {
    const dispatch = this.context[1];
    fName && (fName = fName.trim());
    lName && (lName = lName.trim());
    loginUserID && (loginUserID = loginUserID.trim());
    let modifiedData = [];
    let reqBody = { fName, lName, loginUserID, isSingpostUser };

    if (fName || lName || loginUserID || isSingpostUser) {
      getFliteredUserList(reqBody).then((r) => {
        dispatch({
          type: "getUserList",
          newStore: r.data,
        });
        modifiedData = r.data;
        this.getAllUsers(r.data);
      });
    } else {
      modifiedData = initailData;
    }
    this.setState({ usersList: modifiedData, searchClicked: true, page: 0 });
  };
  clearSearch = () => {
    const dispatch = this.context[1];
    getUserList().then((r) => {
      dispatch({
        type: "getUserList",
        newStore: r.data,
      });
      this.getAllUsers(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      usersList,
      heafNameells,
    } = this.state;
    const isSelected = (userId) => selected.indexOf(userId) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteUser={this.handleDeleteUser}
            selectedUser={this.state.selectedUser}
            onSearchClicked={this.onSearchClicked}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            handleViewColumns={this.handleViewColumns}
            heafNameells={heafNameells}
            usersList={this.state.usersList}
            isActive={this.state.isActive}
            exportDataValues={this.state.exportDataValues}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 8em)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
                  : {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 330px)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  heafNameells={this.state.heafNameells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {usersList.length > 0 ? (
                    stableSort(usersList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.userId);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              this.handleClick(
                                event,
                                row.userId,
                                row.loginUserID
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.heafNameells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.fName} */}
                                {row.fName && row.fName.length > 16 ? (
                                  <Tooltip title={row.fName} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.fName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.fName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.heafNameells[1].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.mName} */}
                                {row.mName && row.mName.length > 16 ? (
                                  <Tooltip title={row.mName} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.mName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.mName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.heafNameells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.lName} */}
                                {row.lName && row.lName.length > 16 ? (
                                  <Tooltip title={row.lName} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.lName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.lName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}

                            {this.state.heafNameells[3].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                                // align={row.numeric ? "right" : "left"}
                              >
                                {/* {row.loginUserID} */}
                                {row.loginUserID &&
                                row.loginUserID.length > 16 ? (
                                  <Tooltip
                                    title={row.loginUserID}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.loginUserID}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.loginUserID}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.heafNameells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.isSingpostUser === true ? "Yes" : "No"}
                              </TableCell>
                            )}
                            {this.state.heafNameells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.custName} */}
                                {row.custName && row.custName.length > 16 ? (
                                  <Tooltip
                                    title={row.custName}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.custName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.custName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.heafNameells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.customerPPI} */}
                                {row.customerPPI &&
                                row.customerPPI.length > 16 ? (
                                  <Tooltip
                                    title={row.customerPPI}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerPPI}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.customerPPI}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.heafNameells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.roleName} */}
                                {row.roleName && row.roleName.length > 16 ? (
                                  <Tooltip
                                    title={row.roleName}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.roleName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.roleName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.heafNameells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.department} */}
                                {row.department &&
                                row.department.length > 16 ? (
                                  <Tooltip
                                    title={row.department}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.department}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.department}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.heafNameells[9].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.emailID} */}
                                {row.emailID && row.emailID.length > 16 ? (
                                  <Tooltip title={row.emailID} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.emailID}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.emailID}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.heafNameells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {status}
                              </TableCell>
                            )}
                            {this.state.heafNameells[11].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.modifiedDate &&
                                row.modifiedDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.heafNameells[12].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.modifiedBy} */}
                                {row.modifiedBy &&
                                row.modifiedBy.length > 16 ? (
                                  <Tooltip
                                    title={row.modifiedBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.modifiedBy}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.heafNameells[13].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.createdDate &&
                                row.createdDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.heafNameells[14].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.createdBy} */}
                                {row.createdBy && row.createdBy.length > 16 ? (
                                  <Tooltip
                                    title={row.createdBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.createdBy}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      {this.state.loading ? (
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                          <LinearProgress />
                        </TableCell>
                      ) : (
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                          No result found
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={usersList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(UserMaster);
