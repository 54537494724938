import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import clone from "clone";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { Checkbox } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter } from "react-router-dom";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StatusText from "../../../shared/statusText";
import { StateContext } from "../../../shared/globalState";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import { stableSort, getSorting } from "../../../shared/tableCommonFunctions";
import moment from "moment";
import "../../app/App.css";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { ExportCSV } from "../../../shared/ExportCSV";
import { ViewColumnIcon } from "../../../shared/customIcons";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  getBPAProcessList,
  UpdateBPAOrderPlacementToInProgess,
} from "../orderPlacement/action";

const columns = [
  {
    id: 9,
    name: "Modified By",
  },

  {
    id: 10,
    name: "Modified Date",
  },

  {
    id: 12,
    name: "Created Date",
  },

  {
    id: 11,
    name: "Created By",
  },
];

let initailData = [];
function createData(
  address,
  address2,
  bpaCode,
  bpaOrderHeaderID,
  bpaRecipientID,
  bpaRunningID,
  bpaSuffix,
  companyName,
  conferenceName,
  conferenceRef,
  contactPersonName,
  costCoding,
  countryID,
  countryName,
  createdBy,
  createdByName,
  createdDate,
  deliveryInstructions,
  deliveryMethodID,
  deliveryMethodName,
  department,
  doNotDeliveryBefore,
  emailID,
  modifiedBy,
  modifiedByName,
  modifiedDate,
  mustBeDeliveryBy,
  mustBeDeliveryOn,
  orderDate,
  packingInstructions,
  phoneNumber,
  postalCode,
  remarks,
  statusName
) {
  return {
    address,
    address2,
    bpaCode,
    bpaOrderHeaderID,
    bpaRecipientID,
    bpaRunningID,
    bpaSuffix,
    companyName,
    conferenceName,
    conferenceRef,
    contactPersonName,
    costCoding,
    countryID,
    countryName,
    createdBy,
    createdByName,
    createdDate,
    deliveryInstructions,
    deliveryMethodID,
    deliveryMethodName,
    department,
    doNotDeliveryBefore,
    emailID,
    modifiedBy,
    modifiedByName,
    modifiedDate,
    mustBeDeliveryBy,
    mustBeDeliveryOn,
    orderDate,
    packingInstructions,
    phoneNumber,
    postalCode,
    remarks,
    statusName,
  };
}

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    numSelected,
    onSelectAllClick,
    rowCount,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all BPA's" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handleDeleteCountry,
    handleViewColumns,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handledeleteProductionLineCode,
    selected,
    editable,
  } = props;

  let excelFileData = clone(props.rows);
  excelFileData.map((d) => {
    d["Order ID"] = d["bpaCode"];
    d["Status"] = d["statusName"];
    d["Order Date"] = moment(d["orderDate"]).format("DD/MM/YYYY");
    d["Delivery Method"] = d["deliveryMethodName"];
    d["Receiver Company"] = d["companyName"];
    d["Receiver Name"] = d["contactPersonName"];
    d["Receiver Country"] = d["countryName"];
    d["Remarks"] = d["remarks"];
    d["Created By"] = d["createdByName"];
    d["Created Date"] = moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss");
    d["Modified By"] = d["modifiedByName"];
    d["Modified Date"] = moment(d["modifiedDate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    );

    delete d["bpaCode"];
    delete d["statusName"];
    delete d["orderDate"];
    delete d["companyName"];
    delete d["deliveryMethodName"];
    delete d["countryName"];
    delete d["contactPersonName"];
    delete d["countryName"];

    delete d["remarks"];
    delete d["createdDate"];
    delete d["modifiedDate"];
    delete d["createdByName"];
    delete d["modifiedByName"];
    delete d["countryID"];
    delete d["address"];
    delete d["address2"];
    delete d["bpaOrderHeaderID"];
    delete d["bpaRecipientID"];
    delete d["bpaRunningID"];
    delete d["bpaSuffix"];
    delete d["conferenceName"];
    delete d["conferenceRef"];
    delete d["costCoding"];
    delete d["createdBy"];
    delete d["deliveryInstructions"];
    delete d["deliveryMethodID"];

    delete d["deliveryMethodName"];
    delete d["department"];
    delete d["doNotDeliveryBefore"];
    delete d["emailID"];
    delete d["modifiedBy"];
    delete d["mustBeDeliveryBy"];
    delete d["mustBeDeliveryOn"];
    delete d["packingInstructions"];
    delete d["phoneNumber"];
    delete d["postalCode"];
  });

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };
  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          margin: numSelected > 0 && "5px 0",
          alignItems: "center",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={() => props.startProcessingHandler(selected)}
              >
                {" "}
                Start Processing{" "}
              </Button>
            </div>
            {/* {
              <Tooltip title="Cancel">
                <IconButton
                  aria-label="cancel"
                  className={classes.iconHover}
                  onClick={(e) => props.history.push("/bpaOrderPlacement")}
                >
                  <CancelOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            } */}
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              {
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) =>
                    props.history.push("/add_pubBpaBatchProcessing")
                  }
                >
                  {" "}
                Update Tracking Info{" "}
                </Button>
              }
              {
                <Tooltip title="Cancel">
                  <IconButton
                    aria-label="cancel"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/bpaOrderPlacement")}
                  >
                    <CancelOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              }
              {/* </Tooltip> */}
              <ExportCSV
                csvData={excelFileData}
                fileName={"BPA_Order_Batch_Processing"}
              />

              {/* <Tooltip title="View Column"> */}
              <IconButton
                aria-label="viewColumn"
                className={classes.iconHover}
                onClick={handleClickListItem}
              >
                <ViewColumnIcon />
              </IconButton>
              {/* </Tooltip> */}
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                handleViewColumns={handleViewColumns}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 10.2;
  const left = 77;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
  };
}

const EnhancedShowColumn = (props) => {
  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    handleViewColumns,
    open,
    ...other
  } = props;
  const [column, setColumnIds] = React.useState(valueProp);
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  }, [valueProp, open]);

  const handleClose = () => {
    onClose(setColumnIds);
  };

  const handleChange = (event) => {
    setColumnIds(event.target.value);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => handleViewColumns(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class BPAOrderProcess extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    selectedRows: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    editable: true,
    removable: true,
    orderPlacementList: [],
    loading: false,
    selectedLocalPostage: {},
    selectedLocalPostageID: 1,
    selectedShowColumns: [],
    searchPopupOpen: false,
    countrySearchValues: {},
    searchClicked: false,
    headCells: [
      {
        code: "bpaCode",
        id: 1,
        show: false,
        numeric: false,
        disablePadding: true,
        label: "Order Id",
        show: true,
      },
      {
        code: "statusName",
        id: 2,
        show: false,
        numeric: false,
        disablePadding: true,
        label: "Status",
        show: true,
      },
      {
        code: "orderDate",
        id: 3,
        numeric: false,
        disablePadding: false,
        label: "Order Date",
        show: true,
      },
      {
        id: 4,
        code: "deliveryMethodName",
        numeric: false,
        disablePadding: false,
        label: "Delivery Method",
        show: true,
      },
      {
        id: 5,
        code: "companyName",
        numeric: false,
        disablePadding: false,
        label: "Receiver Company",
        show: true,
      },
      {
        id: 6,
        code: "contactPersonName",
        numeric: false,
        disablePadding: false,
        label: "Receiver Name",
        show: true,
      },
      {
        id: 7,
        code: "countryName",
        numeric: false,
        disablePadding: false,
        label: "Receiver Country",
        show: true,
      },
      {
        id: 7,
        code: "remarks",
        numeric: false,
        disablePadding: false,
        label: "Remarks",
        show: true,
      },
      {
        id: 8,
        code: "modifiedByName",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },

      {
        id: 9,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 10,
        code: "createdByName",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },

      {
        id: 11,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
    ],
  };
  handleViewColumns = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  componentDidMount() {
    this.props.handleSettingHeader("BPA Batch Processing");
    this.props.setSelectedList("bpaMarketing", "backLabelBPAOrderProcessing");

    const [{ store }, dispatch] = this.context;
    getBPAProcessList().then((r) => {
      dispatch({
        type: "getPlacedOrderList",
        newStore: r.data,
      });
      // this.getAllPlacedOrder(r.data);
      this.getAllPlacedOrder(r.data);
    });
    this.setState({ loading: true });
  }

  getAllPlacedOrder = (lpList) => {
    let list = lpList;
    let newRows = [];

    list &&
      list.length > 0 &&
      list.map((d) => {
        newRows.push(
          createData(
            d.address,
            d.address2,
            d.bpaCode,
            d.bpaOrderHeaderID,
            d.bpaRecipientID,
            d.bpaRunningID,
            d.bpaSuffix,
            d.companyName,
            d.conferenceName,
            d.conferenceRef,
            d.contactPersonName,
            d.costCoding,
            d.countryID,
            d.countryName,
            d.createdBy,
            d.createdByName,
            d.createdDate,
            d.deliveryInstructions,
            d.deliveryMethodID,
            d.deliveryMethodName,
            d.department,
            d.doNotDeliveryBefore,
            d.emailID,
            d.modifiedBy,
            d.modifiedByName,
            d.modifiedDate,
            d.mustBeDeliveryBy,
            d.mustBeDeliveryOn,
            d.orderDate,
            d.packingInstructions,
            d.phoneNumber,
            d.postalCode,
            d.remarks,
            d.statusName
          )
        );
      });
    this.setState({ rows: newRows, loading: false });
    initailData = newRows;
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { rows } = this.state;
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.bpaOrderHeaderID);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, data, id) => {
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (newSelected.length === 1) {
      this.setState({
        selected: newSelected,
        selectedemloID: data,
        selectedemloIDId: id,
        selectedRows: newSelected,
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedemloID: data,
        selectedemloIDId: id,
        selectedRows: newSelected,
      });
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  startProcessingHandler = (ids) => {
    let bpaOrderHeaderIDs = ids.join(",");
    let modifiedBy = window.localStorage.loggedInId;
    let reqBody = { bpaOrderHeaderIDs, modifiedBy };

    UpdateBPAOrderPlacementToInProgess(reqBody).then((r) => {
      getBPAProcessList().then((r) => {
        this.getAllPlacedOrder(r.data);
      });
    });
    this.setState({ loading: true, selected: [], selectedRows: [] });
  };

  render() {
    const [{ store }, dispatch] = this.context;
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      rows,
      selectedLocalPostage,
      selectedLocalPostageID,
      headCells,
      selectedRows,
    } = this.state;

    const isSelected = (id) => selected.indexOf(id) !== -1;
    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedLocalPostage={selectedLocalPostage}
            selectedLocalPostageID={selectedLocalPostageID}
            history={this.props.history}
            countrySearchValue={this.state.countrySearchValue}
            handleViewColumns={this.handleViewColumns}
            headCells={headCells}
            orderPlacementList={rows}
            selected={selected}
            editable={this.state.editable}
            removable={this.state.removable}
            selectedRows={selectedRows}
            rows={this.state.rows}
            startProcessingHandler={this.startProcessingHandler}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 335px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{
                  borderLeft: "1px solid  rgba(224, 224, 224, 1)",
                  whiteSpace: "nowrap",
                }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={rows.length}
                  headCells={this.state.headCells}
                  startProcessingHandler={this.startProcessingHandler}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {rows.length > 0 ? (
                    stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.bpaOrderHeaderID);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            //  hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    onClick={(event) =>
                                      this.handleClick(
                                        event,

                                        row,
                                        row.bpaOrderHeaderID
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                // className="table-content-cell"
                                style={{ width: "auto", padding: ".5em", borderLeft: "1px solid #eaeaea" }}

                              >
                                {row.bpaCode && row.bpaCode.length > 16 ? (
                                  <Tooltip title={row.bpaCode}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.bpaCode}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.bpaCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.statusName &&
                                    row.statusName.length > 10 ? (
                                      <Tooltip title={row.statusName}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.statusName == "Submitted" ? (
                                            <StatusText color={"#16831F"}>
                                              Submitted
                                            </StatusText>
                                          ) : row.statusName == "UnSubmitted" ? (
                                            <StatusText color={"#F4B43C"}>
                                              UnSubmitted
                                            </StatusText>
                                          ) : row.statusName == "Processed" ? (
                                            <StatusText color={"#0091ff"}>
                                              Processed
                                            </StatusText>
                                          ) : row.statusName == "Cancelled" ? (
                                            <StatusText color={"red"}>
                                              Cancelled
                                            </StatusText>
                                          ) : (
                                                    <StatusText color={"grey"}>
                                                      {row.statusName}
                                                    </StatusText>
                                                  )}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.statusName == "Submitted" ? (
                                          <StatusText color={"#16831F"}>
                                            Submitted
                                          </StatusText>
                                        ) : row.statusName == "UnSubmitted" ? (
                                          <StatusText color={"#F4B43C"}>
                                            UnSubmitted
                                          </StatusText>
                                        ) : row.statusName == "Processed" ? (
                                          <StatusText color={"#0091ff"}>
                                            Processed
                                          </StatusText>
                                        ) : row.statusName == "Cancelled" ? (
                                          <StatusText color={"red"}>
                                            Cancelled
                                          </StatusText>
                                        ) : (
                                                  <StatusText color={"grey"}>
                                                    {row.statusName}
                                                  </StatusText>
                                                )}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.orderDate &&
                                    row.orderDate.length > 16 ? (
                                      <Tooltip
                                        title={moment(row.orderDate).format(
                                          "DD/MM/YYYY"
                                        )}
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {moment(row.orderDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.orderDate).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.deliveryMethodName &&
                                    row.deliveryMethodName.length > 16 ? (
                                      <Tooltip title={row.deliveryMethodName}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.deliveryMethodName}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.deliveryMethodName}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.companyName &&
                                  row.companyName.length > 16 ? (
                                    <Tooltip title={row.companyName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.companyName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.companyName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.contactPersonName &&
                                  row.contactPersonName.length > 16 ? (
                                    <Tooltip title={row.contactPersonName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.contactPersonName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.contactPersonName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.countryName &&
                                  row.countryName.length > 16 ? (
                                    <Tooltip title={row.countryName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.countryName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.countryName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.remarks && row.remarks.length > 16 ? (
                                  <Tooltip title={row.remarks}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.remarks}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.remarks}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.modifiedByName &&
                                  row.modifiedByName.length > 16 ? (
                                    <Tooltip title={row.modifiedByName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.createdByName &&
                                  row.createdByName.length > 16 ? (
                                    <Tooltip title={row.createdByName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.createdByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={12} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(BPAOrderProcess);
