import React, { Component } from "react";
import styles from "./login.module.css";
import { TextField, Button } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import Validator from "../../shared/validator";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { postChangePassword, validateChangePassword } from "../userAccess/userAccessAction";
import toastr from "toastr"
const password = new Validator("password");
let curentLocation = window.location.search.split("=")[1];
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      errors: {},
      formValid: false,
      oldPasswordValid: false,
      newPasswordValid: false,
      confirmPasswordValid: false,
      showPassword: false,
      isSubmitting: false,
      errorMessage: null,
      firstPCondition: false,
      secondPCondition: false,
      thirdPCondition: false,
      fourthPCondition: false,
      showValidationModal: false
    };
  }

  _onKeyPress(event) {
    const re = /[a-zA-Z0-9\s!@#\$%\^\&*\)\(+=._-~`:;"'\\/,|]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.oldPasswordValid &&
        this.state.newPasswordValid &&
        this.state.confirmPasswordValid,
    });
    return this.state.formValid;
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let oldPasswordValid = this.state.oldPasswordValid;
    let newPasswordValid = this.state.newPasswordValid;
    let confirmPasswordValid = this.state.confirmPasswordValid;
    switch (fieldName) {
      case "oldPassword":
        oldPasswordValid = password(value);
        fieldValidationErrors.oldPassword = oldPasswordValid ? "" : "";
        break;
      case "newPassword":
        // newPasswordValid = password(value);
        newPasswordValid = value.match(/[a-z]/g) && value.match(/[A-Z]/g) && value.match(/[0-9]/g) && value.match(/[^a-zA-Z\d]/g) && value.length >= 8 && value.match(/[\s!@#\$%\^\&*\)\(+=._-~`:;"'\\/,|]/g);
        if (value.length >= 8) {
          this.setState({ firstPCondition: true });
        } else {
          this.setState({ firstPCondition: false });
        }
        if (value.match(/[0-9]/g)) {
          this.setState({ secondPCondition: true });
        } else {
          this.setState({ secondPCondition: false });
        }
        if (value.match(/[A-Z]/g)) {
          this.setState({ thirdPCondition: true });
        } else {
          this.setState({ thirdPCondition: false });
        }
        if (value.match(/[!@#\$%\^\&*\)\(+=._-]/g)) {
          this.setState({ fourthPCondition: true });
        } else {
          this.setState({ fourthPCondition: false });
        }
        console.log("Validity of Password", newPasswordValid);
        fieldValidationErrors.newPassword = newPasswordValid ? "" : "";
        break;
      case "confirmPassword":
        confirmPasswordValid = value.match(/[a-z]/g) && value.match(/[A-Z]/g) && value.match(/[0-9]/g) && value.match(/[^a-zA-Z\d]/g) && value.length >= 8 && value.match(/[\s!@#\$%\^\&*\)\(+=._-~`:;"'\\/,|]/g);
        fieldValidationErrors.confirmPassword = confirmPasswordValid ? "" : "";
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        oldPasswordValid: oldPasswordValid,
        newPasswordValid: newPasswordValid,
        confirmPasswordValid: confirmPasswordValid,
      },
      this.validateForm
    );
  }

  handleChange = (e) => {
    // console.log([e.target.name], "event");
    // console.log(e.target.value, "event");
    this.setState({ [e.target.name]: e.target.value });
    // e.target.name = e.target.value;
    // this.setState({ oldPassword });
    // this.setState({ newPassword });
    // this.setState({ confirmPassword });
    this.validateField(e.target.name, e.target.value);
  };

  checkValidInputs = () => {
    if (
      // !this.state.oldPassword || 
      !this.state.newPassword || !this.state.confirmPassword || !this.state.firstPCondition || !this.state.secondPCondition || !this.state.thirdPCondition || !this.state.fourthPCondition)
      return true;
    if (this.state.oldPassword === this.state.newPassword) return true;
    if (this.state.newPassword !== this.state.confirmPassword) return true;
    else return false;
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleSubmit = () => {
    // let userLoginID = "";
    // if (process.browser) {
    let userLoginID = this.props && this.props.location && this.props.location.state && this.props.location.state.userLoginID || curentLocation;
    // }

    // let userLoginID =
    //   localStorage.loggedInId || window.location.pathname.split("=")[1];
    let oldPassword = this.props && this.props.location && this.props.location.state && this.props.location.state.oldPassword;
    let newPassword = this.state.newPassword;
    let reqBody = {
      userLoginID: userLoginID,
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    if (this.state.newPassword.length >= 8) {
      postChangePassword(reqBody, this.props.history).then((r) => {
        if (r.status == 400 && r.data.errors.length > 0) {
          let fieldValidationErrors = this.state.errors;
          fieldValidationErrors.existPwd = true;
          this.setState({
            errorMessage: r.data.errors[0],
            errors: fieldValidationErrors,
          });
        } else {
          let fieldValidationErrors = this.state.errors;
          fieldValidationErrors.existPwd = false;
          this.setState({
            errorMessage: "",
            errors: fieldValidationErrors,
          });
          if (r.status === 200)
            toastr.success("Password changed successfully ! Please Login.")
        }
        if (r.data === true) {
          this.props.history.push("/")
          localStorage.clear()
        }
      });
    } else {
      let fieldValidationErrors = this.state.errors;
      fieldValidationErrors.existPwd = true;
      this.setState({
        errorMessage: "Minimum Password length should be 8",
        errors: fieldValidationErrors,
      });
    }
    // if (localStorage.loggedInId !== "") {
    //   this.setState({
    //     errorMessage: ""
    //   });
    // } else {
    //   this.setState({
    //     errorMessage: "User/password combination is wrong"
    //   });
    // }
  };

  componentDidMount() {
    if (process.browser) {
      localStorage.clear()
      let urlParamsArr = (window.location.pathname + window.location.hash).split("/")
      if (urlParamsArr.length >= 2) {
        // console.log(urlParamsArr)

        let userLoginID = urlParamsArr[2]
        if (userLoginID && userLoginID.length > 0)
          userLoginID = userLoginID.replaceAll("@#$&", "/")
        let password = urlParamsArr[3]
        if (password && password.length > 0)
          password = password.replaceAll("@#$&", "/")
        let reqBody = { userLoginID, password }

        validateChangePassword(reqBody).then(r => {
          if (r && r.data) {
            if (r.data.isVaild) {
              this.props.history.push({
                pathname: "/ResetPassword",
                state: { oldPassword: r.data.oldPassword, userLoginID: r.data.userLoginID },
              });
            }
            else {
              toastr.error(r.data.message)
              this.props.history.push("/")
            }
          }
        })
      }
      // else {
      //   if (this.props.location.state.oldPassword) {
      //     console.log("gashdjashd")
      //     this.setState({ oldPassword: this.props.location.state.oldPassword })
      //     this.validateField("oldPassword", this.props.location.state.oldPassword);
      //   }
      // }
    }
  }

  render() {
    let { classes } = this.props;
    return (
      <div className={styles.base}>
        <div className={styles.main}>
          <div>
            <div className={styles.header} />
            <div className={styles.headerText}>Publisher & Generic System</div>
          </div>
          <div className={styles.userInputField}>
            <form className={classes.container} onSubmit={this.handleSubmit}>
              {/* <div style={{ display: "flex" }}>
                <TextField
                  id="standard-adornment-password"
                  required
                  label="Old Password"
                  name="oldPassword"
                  inputProps={{
                    maxLength: 50,
                  }}
                  style={{ marginTop: "10px", flex: 1 }}
                  autoComplete="off"
                  className={classes.textField}
                  onChange={this.handleChange}
                  type={"password"}
                  value={this.state.oldPassword}
                  error={this.state.errors.oldPassword}
                  helperText={
                    this.state.errors.oldPassword
                      ? this.state.errors.oldPassword
                      : ""
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                            <VisibilityOff />
                          )}
                      </IconButton>
                    </InputAdornment>
                  }
                  onKeyPress={this._onKeyPress}
                />
              </div> */}
              <div style={{ display: "flex", position: "relative" }}>
                <TextField
                  id="standard-adornment-password"
                  required
                  label="New Password"
                  name="newPassword"
                  inputProps={{
                    maxLength: 50,
                  }}
                  style={{ marginTop: "10px", flex: 1 }}
                  autoComplete="off"
                  className={classes.textField}
                  onChange={this.handleChange}
                  onFocus={() => { this.setState({ showValidationModal: true }) }}
                  onBlur={() => { this.setState({ showValidationModal: false }) }}
                  type={"password"}
                  value={this.state.newPassword}
                  error={this.state.errors.newPassword}
                  helperText={
                    this.state.errors.newPassword
                      ? this.state.errors.newPassword
                      : ""
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                            <VisibilityOff />
                          )}
                      </IconButton>
                    </InputAdornment>
                  }
                  onKeyPress={this._onKeyPress}
                />
                {this.state.showValidationModal ?
                  <div style={{ position: "absolute", right: "-220px", top: '15px', zIndex: 100, width: "220px", background: "#FFF", textAlign: "left", fontSize: "13px", boxShadow: "0px 0px 3px #555" }}>
                    <ul style={{ listStyle: 'none', paddingLeft: '10px' }}>
                      <li>{this.state.firstPCondition ? <CheckCircleIcon style={{ color: 'green', fontSize: '12px', paddingRight: '5px' }} /> : <ClearIcon style={{ color: 'red', fontSize: '12px', paddingRight: '5px' }} />} Minimum length should be 8</li>
                      <li>{this.state.secondPCondition ? <CheckCircleIcon style={{ color: 'green', fontSize: '12px', paddingRight: '5px' }} /> : <ClearIcon style={{ color: 'red', fontSize: '12px', paddingRight: '5px' }} />} Atleast one Integer</li>
                      <li>{this.state.thirdPCondition ? <CheckCircleIcon style={{ color: 'green', fontSize: '12px', paddingRight: '5px' }} /> : <ClearIcon style={{ color: 'red', fontSize: '12px', paddingRight: '5px' }} />} Atleast one Uppercase Letter</li>
                      <li>{this.state.fourthPCondition ? <CheckCircleIcon style={{ color: 'green', fontSize: '12px', paddingRight: '5px' }} /> : <ClearIcon style={{ color: 'red', fontSize: '12px', paddingRight: '5px' }} />} Atleast one Special Character</li>
                    </ul>
                  </div>
                  : null}
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  disabled={!(this.state.firstPCondition && this.state.secondPCondition && this.state.thirdPCondition && this.state.fourthPCondition)}
                  id="standard-adornment-password"
                  required
                  label="Confirm Password"
                  name="confirmPassword"
                  inputProps={{
                    maxLength: 50,
                  }}
                  style={{ marginTop: "10px", flex: 1 }}
                  autoComplete="off"
                  className={classes.textField}
                  onChange={this.handleChange}
                  type={"password"}
                  value={this.state.confirmPassword}
                  error={this.state.errors.confirmPassword}
                  helperText={
                    this.state.errors.confirmPassword
                      ? this.state.errors.confirmPassword
                      : ""
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                            <VisibilityOff />
                          )}
                      </IconButton>
                    </InputAdornment>
                  }
                  onKeyPress={this._onKeyPress}
                />
              </div>
              {/* </div> */}
              <div>
                {this.state.errors.oldPassword ||
                  this.state.errors.newPassword ||
                  (this.state.errors.confirmPassword && (
                    <span className={styles.errorMessage}>
                      {this.state.errorMessage}
                    </span>
                  ))}
              </div>
              <div style={{ textAlign: "left" }}>
                {this.state.errors.existPwd && (
                  <span className={styles.errorMessage}>
                    {this.state.errorMessage}
                  </span>
                )}
              </div>

              <div className={styles.button}>
                {this.checkValidInputs() ? (
                  <Button
                    disabled
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={this.handleSubmit}
                    style={{ width: "100%", height: "40px" }}
                  >
                    SUBMIT
                  </Button>
                ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={this.handleSubmit}
                      style={{ width: "100%", height: "40px" }}
                    >
                      SUBMIT
                    </Button>
                  )}
              </div>
            </form>
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ChangePassword));
