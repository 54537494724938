import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { blue } from "@material-ui/core/colors";
import { withRouter } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import ManageDispatchMode from "./dispatchModeManage";
import { getDispatchMode, getFliteredDispatchModeList } from "../masterActions";
import { StateContext } from "../../../shared/globalState";
import AdvanceSearch from "./dispatchSearch";
import moment from "moment";
import clone from "clone";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import { ExportCSV } from "../../../shared/ExportCSV";
import { AddIcon, ViewIcon, EditIcon } from "../../../shared/customIcons";
import {
  stableSort,
  getSorting,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import "../../app/App.css";
import { Checkbox } from "@material-ui/core";

function createData(
  dispatchcode,
  dispatchname,
  id,
  createdby,
  createddate,
  modifiedby,
  modifieddate,
  isActive
) {
  return {
    dispatchcode,
    dispatchname,
    createdby,
    id,
    createddate,
    modifiedby,
    modifieddate,
    isActive,
  };
}

const headCells = [
  {
    id: "dispatchcode",
    numeric: false,
    disablePadding: true,
    label: "Dispatch Mode Code",
  },
  {
    id: "dispatchname",
    numeric: false,
    disablePadding: false,
    label: "Dispatch Mode",
  },
  {
    id: "isActive",
    numeric: false,
    disablePadding: false,
    label: "Active",
  },
  {
    id: "modifieddate",
    numeric: false,
    disablePadding: false,
    label: "Modified Date",
  },
  {
    id: "modifiedby",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
  {
    id: "createddate",
    numeric: false,
    disablePadding: false,
    label: "Created Date",
  },
  {
    id: "createdby",
    numeric: false,
    disablePadding: false,
    label: "Created By",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all dispatchmodes" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              <span style={{ color: "#306dca" }}>{headCell.label}</span>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderTop: "1px solid #eaeaea",
  },
  iconHover: {
    "&:hover": {
      color: blue[800],
    },
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          // backgroundColor: lighten(theme.palette.secondary.light, 0.85)
          backgroundColor: "transparent",
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    //selecteddispatch,
    selected,
    disPatchModeList,
    exportDataValues,
  } = props;
  //const selectedDispatchID = selected && selected.length > 0 && selected[0];
  const selectedDispatchID = selected[0];
  const selecteddispatch = disPatchModeList.find(
    (x) => x.id === selectedDispatchID
  );

  let acnArr = props.roles.filter((u) => u.menuName === "Dispatch Mode");
  let acnObj = acnArr[0];
  let expData = clone(disPatchModeList);
  expData.map((d) => {
    let Val = "No";
    if (d["isActive"]) {
      Val = "Yes";
    }
    d["Dispatch Mode Code"] = d["dispatchcode"];
    d["Dispatch Mode"] = d["dispatchname"];

    d["Active"] = Val;
    d["Created By"] = d["createdby"];
    d["Created Date"] = moment(d["createddate"]).format("DD/MM/YYYY HH:mm:ss");
    d["Modified By"] = d["modifiedby"];

    d["Modified Date"] = moment(d["modifieddate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    );

    delete d["dispatchcode"];
    delete d["dispatchname"];

    delete d["isActive"];

    delete d["createdby"];

    delete d["createddate"];

    delete d["modifiedby"];

    delete d["modifieddate"];
    delete d["id"];
  });
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}

        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <React.Fragment>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <IconButton
                      aria-label="add"
                      className={classes.iconHover}
                      onClick={(e) =>
                        props.history.push(
                          `/view_dispatchMode/${[selectedDispatchID]}`
                        )
                      }
                    >
                      <ViewIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <IconButton
                      aria-label="add"
                      className={classes.iconHover}
                      onClick={(e) =>
                        props.history.push(
                          `/edit_dispatchMode/${selectedDispatchID}`
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </React.Fragment>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <ManageDispatchMode
                  mode="delete"
                  selected={selected}
                  data={{
                    dispatchname: selecteddispatch.dispatchname,
                    dispatchcode: selecteddispatch.dispatchcode,
                    dispatchID: selecteddispatch.id,
                  }}
                  onRequestSucceed={props.onRequestSucceed}
                  count={props.selected.length}
                  isActive={props.isActive}
                />
              </Tooltip>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {props.searchClicked ? (
              <Tooltip title="Clear search result">
                <IconButton
                  aria-label="clear"
                  className={classes.iconHover}
                  onClick={props.clearSearch}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            ) : (
              acnObj &&
              acnObj.advanceSearchAction && (
                <AdvanceSearch
                  onSearchClicked={props.onSearchClicked}
                  onRequestSucceed={props.onRequestSucceed}
                />
              )
            )}
            {acnObj && acnObj.addAction && (
              <Tooltip title="Add">
                <IconButton
                  aria-label="add"
                  className={classes.iconHover}
                  onClick={(e) => props.history.push("/add_dispatchMode")}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
            {acnObj && acnObj.exportAction && (
              <Tooltip title="Download">
                <ExportCSV csvData={expData} fileName="Dispatch_Mode" />
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

class DispatchModeMaster extends React.Component {
  static contextType = StateContext;
  state = {
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    disPatchModeList: [],
    rows: [],
    activeRows: 0,
    isActive: false,
    loading: false,
    refreshData: false,
    selecteddispatch: {},
    searchClicked: false,
    exportDataValues: [],
  };
  componentDidMount() {
    this.props.handleSettingHeader("Dispatch Mode");
    this.props.setSelectedList("masterDataOpen", "dispatchMode");
    const [{ store }, dispatch] = this.context;
    this.setState({ loading: true });
    getDispatchMode(window.localStorage.token)
      .then((r) => {
        dispatch({
          type: "getDispatchModeList",
          newStore: r.data,
        });
        this.getAllDispatchMode(r.data);
        this.exportDatas(r.data);
      })
      .catch((e) => {});
  }
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.dispatchModeList && store.dispatchModeList.length > 0)
      if (this.state.rows.length !== store.dispatchModeList.length) {
        !this.state.searchClicked &&
          this.getAllDispatchMode(store.dispatchModeList);
      }
    return true;
  }
  onRequestSucceed = (deleted) => {
    const [{ store }, dispatch] = this.context;
    getDispatchMode().then((r) => {
      console.log(r.data);
      dispatch({
        type: "getDispatchModeList",
        newStore: r.data,
      });
      if (deleted) this.setState({ searchClicked: false });
      this.getAllDispatchMode(r.data);
    });
  };
  exportDatas = (dispatchList) => {
    let list = dispatchList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          DispatchModeCode: d.dispatchModeCode,
          DispatchModeName: d.dispatchModeName,
          IsActive: d.isActive === true ? "Yes" : "No",
          ModifiedDate: d.modifiedDate,
          ModifiedBy: d.modifiedBy,
          CreatedDate: d.createdDate,
          CreatedBy: d.createdBy,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getAllDispatchMode = (dispatchList) => {
    let list = dispatchList;
    let newRows = [];
    list &&
      list.map((d) => {
        return newRows.push(
          createData(
            d.dispatchModeCode,
            d.dispatchModeName,
            d.dispatchModeID,
            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate,
            d.isActive
          )
        );
      });
    // this.sortStateList(newRows);
    this.setState({
      rows: newRows,
      loading: false,
      selected: [],
      activeRows: newRows.filter((x) => x.isActive).map((n) => n.id),
    });

    this.sortStateList(newRows);
  };

  sortStateList = (list) => {
    let z = list.sort(function (a, b) {
      let x = a.isActive && a.isActive;
      let y = b.isActive && b.isActive;
      let u = a.modifiedDate && a.modifiedDate;
      let v = b.modifiedDate && b.modifiedDate;
      if (x < y) {
        return 1;
      }
      if (u < v) {
        return -1;
      }
      if (x > y) {
        return -1;
      }
      if (u > v) {
        return 1;
      }
      return 0;
    });
    // console.log(z);
    this.setState({ rows: z });
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { rows } = this.state;
    if (event.target.checked) {
      const newSelecteds = rows
        .filter((x) => x.isActive === true)
        .map((n) => n.id);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, data) => {
    let id = data.id;
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selecteddispatch: data,
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selecteddispatch: {},
      });
    }
  };
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  onSearchClicked = (reqBody) => {
    getFliteredDispatchModeList(reqBody).then((r) => {
      this.getAllDispatchMode(r.data);
    });
    this.setState({ searchClicked: true, page: 0 });
  };
  clearSearch = () => {
    const [{ store }, dispatch] = this.context;
    getDispatchMode().then((r) => {
      this.getAllDispatchMode(store.dispatchModeList);
    });
    this.setState({ searchClicked: false });
  };
  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      rows,
      selecteddispatch,
      exportDataValues,
    } = this.state;
    // console.log(this.state.rows);
    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (
      <div
      //className={classes.root}
      >
        <Paper
        //className={classes.paper}
        >
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selecteddispatch={selecteddispatch}
            onSearchClicked={this.onSearchClicked}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            selected={this.state.selected}
            disPatchModeList={this.state.rows}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            roles={this.props.roles}
          />
          <div
            style={{
              width: "99%",
              maxHeight: "calc(100vh - 225px)",
              margin: "0 auto",
              border: "1px solid  rgba(224, 224, 224, 1)",
            }}
          >
            <div
              style={{ maxHeight: " calc(100vh - 225px)", overflow: "auto" }}
            >
              <Table
                //className={classes.table}
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="sticky table"
              >
                <EnhancedTableHead
                  //classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                />

                <TableBody style={{ overflow: "hidden" }}>
                  {rows.length > 0 ? (
                    stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )

                      .map((row, index) => {
                        //console.log(row);
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return (
                          <TableRow
                            hover
                            onClick={(event) => this.handleClick(event, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            // key={row.id}
                            key={index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              {/* <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                                color="primary"
                              /> */}
                              <FormControlLabel
                                control={
                                  <CustomCheckbox
                                    checked={isItemSelected}
                                    // onChange={handleChange('checkedG')}
                                    // value="checkedG"
                                  />
                                }
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.dispatchcode} */}
                              {row.dispatchcode &&
                              row.dispatchcode.length > 16 ? (
                                <Tooltip
                                  title={row.dispatchcode}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.dispatchcode}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.dispatchcode}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.dispatchname}
                              </Typography> */}
                              {row.dispatchname &&
                              row.dispatchname.length > 16 ? (
                                <Tooltip
                                  title={row.dispatchname}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.dispatchname}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.dispatchname}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {status}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                              visibility="hidden"
                              id={labelId}
                            >
                              <Tooltip
                                title={moment(row.modifieddate).format(
                                  "DD/MM/YYYY HH:mm:ss"
                                )}
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {moment(row.modifieddate).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                  )}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.modifiedby} */}
                              {row.modifiedby && row.modifiedby.length > 16 ? (
                                <Tooltip
                                  title={row.modifiedby}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.modifiedby}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.modifiedby}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                              visibility="hidden"
                              id={labelId}
                            >
                              <Tooltip
                                title={moment(row.createddate).format(
                                  "DD/MM/YYYY HH:mm:ss"
                                )}
                              >
                                <Typography noWrap style={{ fontSize: "2px" }}>
                                  {moment(row.createddate).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                  )}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.createdby} */}
                              {row.createdby && row.createdby.length > 16 ? (
                                <Tooltip title={row.createdby} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.createdby}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.createdby}
                                </Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      {this.state.loading ? (
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                          <LinearProgress />
                        </TableCell>
                      ) : (
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                          No result found
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                  {/* {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </div>
          </div>

          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(DispatchModeMaster);
