import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import axios from "axios";
import toastr from "toastr";
import { styles } from "../../../shared/styles";
import { StateContext } from "../../../shared/globalState";
const baseURL = process.env.REACT_APP_PGS_SERVER_PUBLISHER_ORDER_PROCESSING;
const pgsService = axios.create({
  baseURL,
});
pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiDialogContent);

const P = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    fontSize: 20,
    color: "#3982ea",
    fontFamily: "Roboto",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class DeletePMLO extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    modifiedBy: 1,
  };
  onDeletePMLOrder = () => {
    const [{ store }, dispatch] = this.context;
    let { selected } = this.props;
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    pgsService
      .delete(`/DeletePreProcess/${deletionIds}`)
      .then((r) => {
        this.handleClose();
        // if (r.data)
        //   toastr.success(r.data)
        // else
        //   toastr.success("Order deleted successfully");
        if (r.data.includes("Already"))
          toastr.error(r.data);
        else if (r.data.includes("cannot be Rolledback"))
          toastr.error(r.data);
        else
          toastr.success(r.data);
        this.props.onRequestSucceed("deleted");
        this.props.history.push("/pubMainLabelBatchPlacement");
      })
      .catch((e) => {
        this.handleClose();
        this.props.onRequestSucceed("called");
      });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { classes, selected } = this.props;
    // console.log(this.props)
    return (
      <div>
        <IconButton
          aria-label="add"
          className={classes.iconHover}
          onClick={this.handleClickOpen}
        //  disabled={!isActive}
        >
          <DeleteIcon />
        </IconButton>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="lg"
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Delete Batch Processing
          </DialogTitle>

          <DialogContent dividers>
            <div className={classes.deleteContainer}>
              Are you sure you want to {this.props.completed ? "Roll Back ?" : "Delete ?"}
              <span style={{ fontWeight: "600" }}>
                {/* {selected.length ===1 ? this.props.data[0].orderFileName&&this.props.data[0].orderFileName   : ""} */}
                {/* {data.mailingScheme} */}
              </span>
            </div>
          </DialogContent>

          <DialogActions style={{ padding: "10px 24px" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={this.handleClose}
            >
              CANCEL
            </Button>

            <Button
              //variant="outlined"
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={this.onDeletePMLOrder}
              variant="contained"
            >
              {this.props.completed ? "ROLLBACK" : "DELETE"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(DeletePMLO));
