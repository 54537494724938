import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import clone from "clone";
import DeleteJIM from "./delete";
import { Autocomplete } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Checkbox } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { serchCustomerOrder } from "../../generic/orderPlacement/actions";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AdvanceSearch from "./search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { getPlacedOrder } from "../../generic/orderPlacement/actions";
import { StateContext } from "../../../shared/globalState";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import Validator from "../../../shared/validator";
import { resourceValidation } from "../../../shared/resource";
import {
  getJournalitemMasterList,
  searchJournalitemMasterList,
  getStagingJournalitemMasterList,
} from "./action";
import { } from "../action";
import {
  desc,
  stableSort,
  getSorting,
  DialogActions,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import moment from "moment";
import "../../app/App.css";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { ExportCSV } from "../../../shared/ExportCSV";
import {
  SearchIcon,
  DownloadIcon,
  ViewColumnIcon,
  EditIcon,
  ViewIcon,
  AddIcon,
} from "../../../shared/customIcons";
import LinearProgress from "@material-ui/core/LinearProgress";

const validateMaxlength2 = new Validator("maxlength2");

const columns = [
  {
    id: 50,
    name: "Modified By",
  },

  {
    id: 49,
    name: "Modified Date",
  },
  {
    id: 52,
    name: "Created By",
  },

  {
    id: 51,
    name: "Created Date",
  },
];

// const DialogActions = withStyles(theme => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1)
//   }
// }))(MuiDialogActions);
let initailData = [];
function createData(
  client,
  uniqueIssueId,
  acronym,
  volume,
  issue,
  title,
  suppNo,
  partNo,
  issueDesc,
  insert,
  type,
  office,
  expectedDeliveryDate,
  printRun,
  estMLR,
  estOMR,
  estBalanceOfStock,
  estUnitWeight,
  estTotalWeight,
  returnByAir,
  returnBySea,
  division,
  dimension,
  printer,
  splInstruction,
  splDeliveryInstruction,
  offlineFlag,
  distributor,
  uspsPermit,
  priorityTitle,
  splHandling,
  journalReceivedDate,
  actualPrintRunReceived,
  actualWeight,
  cptWeight,
  blWeight,
  mlCopies,
  omCopies,
  otCopies,
  hcCopies,
  eboCopies,
  issnNo,
  publicationDate,
  publicationYear,
  // offlineQty,
  cea,
  ces,
  status,
  active,
  mdfDate,
  mdfBy,
  ctdDate,
  ctdBy,
  id
) {
  return {
    client,
    uniqueIssueId,
    acronym,
    volume,
    issue,
    title,
    suppNo,
    partNo,
    issueDesc,
    insert,
    type,
    office,
    expectedDeliveryDate,
    printRun,
    estMLR,
    estOMR,
    estBalanceOfStock,
    estUnitWeight,
    estTotalWeight,
    returnByAir,
    returnBySea,
    division,
    dimension,
    printer,
    splInstruction,
    splDeliveryInstruction,
    offlineFlag,
    distributor,
    uspsPermit,
    priorityTitle,
    splHandling,
    journalReceivedDate,
    actualPrintRunReceived,
    actualWeight,
    cptWeight,
    blWeight,
    mlCopies,
    omCopies,
    otCopies,
    hcCopies,
    eboCopies,
    issnNo,
    publicationDate,
    publicationYear,
    // offlineQty,
    cea,
    ces,
    status,
    active,
    mdfDate,
    mdfBy,
    ctdDate,
    ctdBy,
    id,
  };
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ borderTop: "1px solid #eaeaea", background: "white" }}
          className="table-cell"
        >
          {/* <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "Select All Countrys" }}
              />
            }
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={"left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
                style={{ minWidth: headCell.minWidth }}
              >
                <TableSortLabel
                  active={orderBy === headCell.code}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
            //);
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    countryCode: "",
    countryName: "",
  });
  const [formValid, setIsformValid] = useState(false);

  const [preAlertSearchValue, setValues] = useState({
    preAlert: "",
  });
  const classes = useToolbarStyles();
  const {
    numSelected,
    jimSelectedData,
    jimSelectedDataID,
    handleDeletePopupOpen,
    deletePopupOpen,
    searchPopupOpen,
    handleDeletePopupClose,
    handleDeleteCountry,
    handleViewColumns,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchpreAlertCode,
    searchClicked,
    selected,
    localPostage,
    orderPlacementList,
    editable,
  } = props;
  let acnArr = props.roles.filter((u) => u.menuName === "Journal Item Master");
  let acnObj = acnArr[0];
  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  let expData = clone(orderPlacementList);
  expData.map((d) => {
    d["Customer"] = d["client"];
    d["Issue Desc"] = d["issueDesc"];
    d["Acronym"] = d["acronym"];
    d["Volume"] = d["volume"];
    d["Issue"] = d["issue"];
    d["Supp No"] = d["suppNo"];
    d["Part No"] = d["partNo"];
    d["Title/Description"] = d["title"];
    d["Unique Issue Id"] = d["uniqueIssueId"];
    d["Insert"] = d["insert"];
    d["Journal Type"] = d["type"];
    d["Office"] = d["office"];
    d["Expected Delivery Date"] = d["expectedDeliveryDate"]
      ? moment(d["expectedDeliveryDate"]).format("DD/MM/YYYY")
      : "";
    d["Print Run"] = d["printRun"];
    d["Est MLR"] = d["estMLR"];
    d["Est OMR"] = d["estOMR"];
    d["Est Balance Of Stock"] = d["estBalanceOfStock"];
    d["Est Unit Weight"] = d["estUnitWeight"];
    d["Est Total Weight"] = d["estTotalWeight"];
    d["Return By Air"] = d["returnByAir"];
    d["Return By Sea"] = d["returnBySea"];
    d["Division"] = d["division"];
    d["Dimension"] = d["dimension"];
    d["Printer"] = d["printer"];
    d["Spl Instruction"] = d["splInstruction"];
    d["Spl Delivery Instruction"] = d["splDeliveryInstruction"];
    d["Offline Flag"] = d["offlineFlag"];
    d["Distributor"] = d["distributor"];
    d["Usps Permit"] = d["uspsPermit"];
    d["Priority Title"] = d["priorityTitle"];
    d["Spl Handling"] = d["splHandling"];
    d["Journal Received Date"] = d["journalReceivedDate"] ? moment(d["journalReceivedDate"]).format("DD/MM/YYYY HH:mm:ss") : "";
    d["Actual Print Run Received"] = d["actualPrintRunReceived"];
    d["Actual Weight"] = d["actualWeight"];
    d["CPT Weight"] = d["cptWeight"];
    d["BL Weight"] = d["blWeight"];
    d["ML Copies"] = d["mlCopies"];
    d["OM Copies"] = d["omCopies"];
    d["Culling Copies"] = d["otCopies"];
    d["HC Copies"] = d["hcCopies"];
    d["EBO Copies"] = d["eboCopies"];
    d["ISSN No."] = d["issnNo"];
    d["Publication Date"] = d["publicationDate"] ? moment(d["publicationDate"]).format("DD/MM/YYYY") : "";
    d["Publication Year"] = d["publicationYear"];
    // d["Offline Qty"] = d["offlineQty"];
    d["CEA"] = d["cea"];
    d["CES"] = d["ces"];
    // d["Status"]=d["status"]
    d["Active"] = d["active"] ? "Yes" : "No";
    d["Modified Date"] = moment(d["mdfDate"]).format("DD/MM/YYYY HH:mm:ss");
    d["Modified By"] = d["mdfBy"];
    d["Created Date"] = moment(d["ctdDate"]).format("DD/MM/YYYY HH:mm:ss");
    d["Created By"] = d["ctdBy"];

    delete d["active"];
    delete d["id"];
    delete d["client"];
    delete d["uniqueIssueId"];
    delete d["acronym"];
    delete d["volume"];
    delete d["issue"];
    delete d["title"];
    delete d["suppNo"];
    delete d["partNo"];
    delete d["issueDesc"];
    delete d["insert"];
    delete d["type"];
    delete d["office"];
    delete d["expectedDeliveryDate"];
    delete d["printRun"];
    delete d["estMLR"];
    delete d["estOMR"];
    delete d["estBalanceOfStock"];
    delete d["estUnitWeight"];
    delete d["estTotalWeight"];
    delete d["returnByAir"];
    delete d["returnBySea"];
    delete d["division"];
    delete d["dimension"];
    delete d["printer"];
    delete d["splInstruction"];
    delete d["splDeliveryInstruction"];
    delete d["offlineFlag"];
    delete d["distributor"];
    delete d["uspsPermit"];
    delete d["priorityTitle"];
    delete d["splHandling"];
    delete d["journalReceivedDate"];
    delete d["actualPrintRunReceived"];
    delete d["actualWeight"];
    delete d["cptWeight"];
    delete d["blWeight"];
    delete d["mlCopies"];
    delete d["omCopies"];
    delete d["otCopies"];
    delete d["hcCopies"];
    delete d["eboCopies"];
    delete d["issnNo"];
    delete d["publicationDate"];
    delete d["publicationYear"];
    // delete d["offlineQty"];
    delete d["cea"];
    delete d["ces"];
    delete d["status"];
    delete d["active"];
    delete d["ctdBy"];
    delete d["ctdDate"];
    delete d["mdfBy"];
    delete d["mdfDate"];
  });
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteCountry = () => {
    handleDeleteCountry();
  };
  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };
  const searchPopup = () => {
    handleSearchpreAlertCode(preAlertSearchValue);
    clearSearchValues();
  };
  const handleSearchInput = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;
    setValues((preAlertSearchValue) => ({
      ...preAlertSearchValue,
      [event.target.name]: event.target.value,
    }));

    switch (name) {
      case "client":
        let client = value;
        setIsformValid(client);
        setErrors((errors) => ({
          ...errors,
          [event.target.name]: client ? "" : resourceValidation.client,
        }));
        break;

      default:
        break;
    }
  };
  const clearSearchValues = () => {
    let searchValues = preAlertSearchValue;
    searchValues.countryCode = "";
    searchValues.countryName = "";
    searchValues = preAlertSearchValue;
    setIsformValid(false);
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <div style={{ marginTop: "5px" }}>
              {/* {acnObj && acnObj.editAction && ( */}
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={props.handleStageOpen}
              >
                View Journal Stage
                </Button>
              {/* )} */}
            </div>
            {numSelected === 1 && (
              <div style={{ display: "flex" }}>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_journalItem/${selected[0]}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}

                {/* {editable && ( */}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <Link to={`/edit_journalItem/${selected[0]}`}>
                      <IconButton
                        aria-label="edit"
                        className={classes.iconHover}
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}

                {/* )} */}
                {editable && acnObj && acnObj.deleteAction && (
                  <Tooltip title="Delete">
                    <DeleteJIM
                      mode="delete"
                      selected={props.selected}
                      onRequestSucceed={props.onRequestSucceed}
                      data={props.jimSelectedData}
                    // count={props.selected.length}
                    // isActive={props.isActive}
                    />
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {props.searchClicked ? (
                // <Tooltip title="Clear search result">
                <IconButton
                  aria-label="clear"
                  className={classes.iconHover}
                  onClick={(e) => props.clearSearch()}
                >
                  <HighlightOffIcon />
                </IconButton>
              ) : (
                  acnObj &&
                  acnObj.advanceSearchAction && (
                    <Tooltip title="Advance search">
                      <AdvanceSearch
                        onSearchClicked={props.onSearchClicked}
                        onRequestSucceed={props.onRequestSucceed}
                      />
                    </Tooltip>
                  )
                )}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="add"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/add_journalItem")}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}

              {/* <Tooltip title="Add"> */}

              {/* <IconButton
              aria-label="view"
              className={classes.iconHover}
              onClick={e => props.history.push("/view_journalItem")}
            >
              <ViewIcon />
            </IconButton>
            <IconButton
              aria-label="add"
              className={classes.iconHover}
              onClick={e => props.history.push("/edit_journalItem")}
            >
              <EditIcon />
            </IconButton> */}
              {/* </Tooltip> */}
              {acnObj && acnObj.exportAction && (
                <ExportCSV csvData={expData} fileName={"Journal_Item_Master"} />
              )}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                handleViewColumns={handleViewColumns}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 10.2;
  const left = 77;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    handleViewColumns,
    open,
    ...other
  } = props;
  const [column, setColumnIds] = React.useState(valueProp);
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  }, [valueProp, open]);

  const handleClose = () => {
    onClose(setColumnIds);
  };

  const handleChange = (event) => {
    setColumnIds(event.target.value);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => handleViewColumns(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

function StageModal(props) {
  const classes = useToolbarStyles();
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={props.open}
      onClose={props.handleChooseOFDialog}
      maxWidth="xl"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Stage View</DialogTitle>
      <DialogContent dividers>
        {/* <div style={{display:"flex"}}><span></span> <span>{props.journalDesc}</span></div> */}
        <div
        // style={{ margin: "0px 2em 0px 2em" }}
        >
          <Typography
            component="div"
            className="blueBox"
            style={{ padding: ".5em 0 .5em 0" }}
          >
            <div className="blueBoxLabel">
              <div className="blueBoxText">
                {props.issueDesc}
                {props.journalDesc ? ` (${props.journalDesc}) ` : ""}
              </div>
            </div>
          </Typography>
        </div>
        <div style={{ width: "100%", overflow: "auto" }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
            style={{ marginTop: "10px" }}
          >
            <TableHead>
              <TableRow style={{ borderTop: "1px solid #eaeaea" }}>
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  className="table-content-cell"
                  style={{ color: "#306dca", minWidth: "40px" }}
                >
                  Sr No
                </TableCell>
                <TableCell
                  padding="default"
                  style={{ minWidth: "145px" }}
                  className="table-content-cell"
                >
                  {" "}
                  <span style={{ color: "#306dca" }}>Stage Date Time</span>{" "}
                </TableCell>
                <TableCell
                  className="table-content-cell"
                  padding="default"
                  style={{ color: "#306dca", minWidth: "120px" }}
                >
                  Action By
                </TableCell>
                <TableCell
                  padding="default"
                  style={{ minWidth: "145px" }}
                  className="table-content-cell"
                >
                  {" "}
                  <span style={{ color: "#306dca" }}>
                    Modified Date Time
                  </span>{" "}
                </TableCell>
                <TableCell
                  padding="default"
                  style={{ minWidth: "290px" }}
                  className="table-content-cell"
                >
                  {" "}
                  <span style={{ color: "#306dca" }}>
                    Task description
                  </span>{" "}
                </TableCell>{" "}
                <TableCell
                  padding="default"
                  style={{ color: "#306dca", minWidth: "72px" }}
                  className="table-content-cell"
                >
                  Completed
                </TableCell>{" "}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.list.length > 0 ?
                (props.list.map((row, index) => {
                  // const isItemSelected = isSelected(index);
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={index}>
                      <TableCell
                        //component="th"
                        //scope="row"
                        padding="none"
                        className="table-content-cell"
                      >
                        {" "}
                        <Typography noWrap style={{ fontSize: "14px" }}>
                          {+index + 1}
                        </Typography>{" "}
                      </TableCell>
                      <TableCell className="table-content-cell">
                        {" "}
                        {row.stageDateTime
                          ? moment(row.stageDateTime).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                          : ""}
                      </TableCell>
                      <TableCell className="table-content-cell">
                        {" "}
                        <Typography noWrap style={{ fontSize: "14px" }}>
                          {row.actionBy}
                        </Typography>{" "}
                      </TableCell>
                      <TableCell className="table-content-cell">
                        {" "}
                        {row.dateTime
                          ? moment(row.dateTime).format("DD/MM/YYYY HH:mm:ss")
                          : ""}
                      </TableCell>
                      <TableCell className="table-content-cell">
                        {row.taskDescription}
                      </TableCell>
                      <TableCell className="table-content-cell">
                        {row.completed ? "Yes" : "No"}
                      </TableCell>
                    </TableRow>
                  );
                })) : (
                  <TableRow>
                    {/* {props.stageLoading ? ( */}
                    <TableCell colSpan={6} style={{ textAlign: "center" }}>
                      <LinearProgress />
                    </TableCell>
                    {/* ) : (
                        <TableCell colSpan={10} style={{ textAlign: "center" }}>
                          No result found
                        </TableCell>
                      )} */}
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "10px 24px" }}>
        <Button
          variant="contained"
          color="primary"
          style={{ boxShadow: "none" }}
          onClick={props.handleStageOpen}
        >
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
}

class JournalItemMaster extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "mdfDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    editable: true,
    orderPlacementList: [],
    loading: false,
    jimSelectedData: [],
    jimSelectedDataID: 1,
    selectedShowColumns: [],
    searchPopupOpen: false,
    countrySearchValues: {},
    searchClicked: false,
    stageOpen: false,
    stageLoading: false,
    stageList: [],
    selectedIssueDesc: "",
    selectedJournalDesc: "",
    headCells: [
      {
        code: "client",
        id: 1,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Customer",
        show: true,
      },

      {
        id: 2,
        code: "issueDesc",
        numeric: false,
        disablePadding: false,
        label: "Issue Description",
        show: true,
      },
      {
        code: "acronym",
        id: 3,
        numeric: false,
        disablePadding: false,
        label: "Acronym",
        show: true,
      },
      {
        id: 4,
        code: "volume",
        numeric: false,
        disablePadding: false,
        label: "Volume",
        show: true,
      },
      {
        id: 5,
        code: "issue",
        numeric: false,
        disablePadding: false,
        label: "Issue",
        show: true,
      },
      {
        id: 6,
        code: "suppNo",
        numeric: false,
        disablePadding: false,
        label: "Supp No",
        show: true,
      },
      {
        id: 7,
        code: "partNo",
        numeric: false,
        disablePadding: false,
        label: "Part No",
        show: true,
      },
      {
        id: 8,
        code: "title",
        numeric: false,
        disablePadding: false,
        label: "Title/Desc",
        show: true,
      },
      {
        code: "uniqueIssueId",
        id: 9,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Unique Issue Id",
        show: true,
        minWidth: "180px",
      },

      {
        id: 10,
        code: "insert",
        numeric: false,
        disablePadding: false,
        label: "Insert",
        show: true,
      },

      {
        id: 11,
        code: "type",
        numeric: false,
        disablePadding: false,
        label: "Journal Type",
        show: true,
      },

      {
        id: 12,
        code: "office",
        numeric: false,
        disablePadding: false,
        label: "Office",
        show: true,
      },

      {
        id: 13,
        code: "expectedDeliveryDate",
        numeric: false,
        disablePadding: false,
        label: "Exptd Delivery Date",
        show: true,
      },

      {
        id: 14,
        code: "printRun",
        numeric: false,
        disablePadding: false,
        label: "Print Run",
        show: true,
      },

      {
        id: 15,
        code: "estMLR",
        numeric: false,
        disablePadding: false,
        label: "Est MLR",
        show: true,
      },

      {
        id: 16,
        code: "estOMR",
        numeric: false,
        disablePadding: false,
        label: "Est OMR",
        show: true,
      },
      {
        id: 17,
        code: "estBalanceOfStock",
        numeric: false,
        disablePadding: false,
        label: "Est Balance Of Stock",
        show: true,
      },

      {
        id: 18,
        code: "estUnitWeight",
        numeric: false,
        disablePadding: false,
        label: "Est Unit Weight",
        show: true,
      },

      {
        id: 19,
        code: "estTotalWeight",
        numeric: false,
        disablePadding: false,
        label: "Est Total Weight",
        show: true,
      },

      {
        id: 20,
        code: "returnByAir",
        numeric: false,
        disablePadding: false,
        label: "Return By Air",
        show: true,
      },

      {
        id: 21,
        code: "returnBySea",
        numeric: false,
        disablePadding: false,
        label: "Return By Sea",
        show: true,
      },

      {
        id: 22,
        code: "division",
        numeric: false,
        disablePadding: false,
        label: "Division",
        show: true,
      },

      {
        id: 23,
        code: "dimension",
        numeric: false,
        disablePadding: false,
        label: "Dimension",
        show: true,
      },

      {
        id: 24,
        code: "printer",
        numeric: false,
        disablePadding: false,
        label: "Printer",
        show: true,
      },

      {
        id: 25,
        code: "splInstruction",
        numeric: false,
        disablePadding: false,
        label: "Spl Instruction",
        show: true,
      },

      {
        id: 26,
        code: "splDeliveryInstruction",
        numeric: false,
        disablePadding: false,
        label: "Spl Delivery Instruction",
        show: true,
      },

      {
        id: 27,
        code: "offlineFlag",
        numeric: false,
        disablePadding: false,
        label: "Offline Flag",
        show: true,
      },

      {
        id: 28,
        code: "distributor",
        numeric: false,
        disablePadding: false,
        label: "Distributor",
        show: true,
      },

      {
        id: 29,
        code: "uspsPermit",
        numeric: false,
        disablePadding: false,
        label: "USPS Permit",
        show: true,
      },

      {
        id: 30,
        code: "priorityTitle",
        numeric: false,
        disablePadding: false,
        label: "Priority Title",
        show: true,
      },

      {
        id: 31,
        code: "splHandling",
        numeric: false,
        disablePadding: false,
        label: "Spl Handling",
        show: true,
      },

      {
        id: 32,
        code: "journalReceivedDate",
        numeric: false,
        disablePadding: false,
        label: "Journal Recvd. Date",
        show: true,
      },

      {
        id: 33,
        code: "actualPrintRunReceived",
        numeric: false,
        disablePadding: false,
        label: "Act. Print Run Recvd.",
        show: true,
      },

      {
        id: 34,
        code: "actualWeight",
        numeric: false,
        disablePadding: false,
        label: "Act. Weight",
        show: true,
      },

      {
        id: 35,
        code: "cptWeight",
        numeric: false,
        disablePadding: false,
        label: "CPT Weight",
        show: true,
      },

      {
        id: 36,
        code: "blWeight",
        numeric: false,
        disablePadding: false,
        label: "BL Weight",
        show: true,
      },

      {
        id: 37,
        code: "mlCopies",
        numeric: false,
        disablePadding: false,
        label: "ML Copies",
        show: true,
      },

      {
        id: 38,
        code: "omCopies",
        numeric: false,
        disablePadding: false,
        label: "OM Copies",
        show: true,
      },

      {
        id: 39,
        code: "otCopies",
        numeric: false,
        disablePadding: false,
        label: "Culling Copies",
        show: true,
      },

      {
        id: 40,
        code: "hcCopies",
        numeric: false,
        disablePadding: false,
        label: "HC Copies",
        show: true,
      },

      {
        id: 41,
        code: "eboCopies",
        numeric: false,
        disablePadding: false,
        label: "EBO Copies",
        show: true,
      },

      {
        id: 42,
        code: "issnNo",
        numeric: false,
        disablePadding: false,
        label: "ISSN No",
        show: true,
      },

      {
        id: 43,
        code: "publicationDate",
        numeric: false,
        disablePadding: false,
        label: "Publication Date",
        show: true,
      },

      {
        id: 44,
        code: "publicationYear",
        numeric: false,
        disablePadding: false,
        label: "Publication Year",
        show: true,
      },

      // {
      //   id: 43,
      //   code: "offlineQty",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Offline Qty",
      //   show: true,
      // },

      {
        id: 45,
        code: "cea",
        numeric: false,
        disablePadding: false,
        label: "CEA",
        show: true,
      },

      {
        id: 46,
        code: "ces",
        numeric: false,
        disablePadding: false,
        label: "CES",
        show: true,
      },

      {
        id: 47,
        code: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        show: false,
      },

      {
        id: 48,
        code: "active",
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true,
      },
      {

        id: 49,
        code: "mdfDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },

      {
        id: 50,
        code: "mdfBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 51,
        code: "ctdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 52,
        code: "ctdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };
  handleViewColumns = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  componentDidMount() {
    this.props.handleSettingHeader("Journal Item Master");
    this.props.setSelectedList(
      "publisherMainLabelOpen",
      "pubJournalItemMaster"
    );
    this.setState({ loading: true });
    getJournalitemMasterList().then((r) => {
      this.getAllJournalItemList(r.data);
    });

    // const [{ store }, dispatch] = this.context;
    // getPlacedOrder().then(r => {

    //   dispatch({
    //     type: "getPlacedOrderList",
    //     newStore: r.data
    //   });
    //   // this.getAllPlacedOrder(r.data);
    //   this.getAllPlacedOrder(r.data);
    // });
    // this.setState({ loading: true });
  }

  shouldComponentUpdate(s, p) {
    const [{ store }, dispatch] = this.context;
    // if (this.state.rows.length !== store.placedOrderList.length) {
    //   !this.state.searchClicked &&
    //     this.getAllPlacedOrder(store.placedOrderList);
    // }
    // if (this.state.rows.length !== 0) {
    //   this.state.rows.map((cl, i) => {
    //     if (cl.ststus != store.placedOrderList[i].isActive)
    //       !this.state.searchClicked &&
    //         this.getAllPostageList(store.placedOrderList);
    //   });
    // }
    return true;
  }
  onRequestSucceed = (deleted) => {
    const [{ store }, dispatch] = this.context;

    getJournalitemMasterList().then((r) => {
      if (deleted) this.setState({ searchClicked: false, selected: [] });
      this.getAllJournalItemList(r.data);
      //this.setState({ loading: true });
    });
  };
  handleStageOpen = () => {
    this.setState({ stageOpen: !this.state.stageOpen }, () => {
      if (this.state.stageOpen) {
        this.setState({ stageLoading: true })
        let orderId = this.state.jimSelectedDataID;
        getStagingJournalitemMasterList(orderId).then((r) => {
          this.setState({ stageList: r.data, stageLoading: false });
        });
      } else {
        this.setState({ stageList: [] });
      }
    });

  };
  getAllJournalItemList = (list) => {
    let newRows = [];

    list &&
      list.length > 0 &&
      list.map((d) => {
        newRows.push(
          createData(
            d.client,
            d.uniqueIssueID,
            d.acronym,
            d.volume,
            d.issue,
            d.titleDescription,
            d.suppNumber,
            d.partNumber,
            d.journalUniqueID,
            d.insert,
            d.journalType,
            d.office,
            d.expectedDeliveryDate,
            d.printRun,
            d.estMLR,
            d.estOMR,
            d.estBalanceofStock,
            d.estUnitWeight,
            d.estTotalWeight,
            d.returnbyAir,
            d.returnbySea,
            d.division,
            d.dimensions,
            d.printer,
            d.specialInstruction,
            d.specialDeliveryInstructions,
            d.offineFlag,
            d.distributor,
            d.uspsPermit,
            d.priorityTitle,
            d.specialHandling,
            d.journalReceivedDate,
            d.actualPrintRunReceived,
            d.actualWeight,
            d.cptWeight,
            d.blWeight,
            d.mlCopies,
            d.omCopies,
            d.otCopies,
            d.hcCopies,
            d.eboCopies,
            d.issnNo,
            d.publicationDate,
            d.publishingYear,
            // d.publishingYear,
            d.cea,
            d.ces,
            d.statusID,
            d.isActive,
            d.modifiedDate,
            d.modifiedByName,
            d.createdDate,
            d.createdByName,
            d.journalID
          )
        );
      });
    this.setState({ rows: newRows, loading: false });
    initailData = newRows;
  };
  getAllPlacedOrder = (lpList) => {
    let list = lpList;
    let newRows = [];

    list &&
      list.length > 0 &&
      list.map((d) => {
        newRows.push(
          createData(
            d.orderID,
            d.orderTitle,
            d.noOfOrderFiles,
            d.specialInstruction,
            d.status,
            d.planner,
            d.contactEmailID,
            d.modifiedBy,
            d.modifiedDate,
            d.createdBy,
            d.createdDate,
            d.customerOrderHeaderID
          )
        );
      });
    this.setState({ rows: newRows, loading: false });
    initailData = newRows;
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { rows } = this.state;
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  // handleClick = (event, code, data, id) => {
  //   let rowSelected = [];
  //   let { selected } = this.state;
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   //  if (newSelected.length === 1) {
  //   rowSelected = this.state.rows.filter((r) => r.id === newSelected[0]);
  //   // }

  //   if (rowSelected.length > 0) {
  //     if (rowSelected[0].active) {
  //       this.setState({ editable: true });
  //     } else {
  //       this.setState({ editable: false });
  //     }
  //   }
  //   if (newSelected.length === 1) {
  //     this.setState({
  //       selected: newSelected,
  //       jimSelectedData: rowSelected,
  //       jimSelectedDataID: data.id,
  //     });
  //   } else {
  //     this.setState({
  //       selected: newSelected,
  //       jimSelectedData: rowSelected,
  //       jimSelectedDataID: 0,
  //     });
  //   }
  // };

  handleClick(event, code, data, id, issueDesc, title) {
    let newSelected = [];
    let selected = [];
    let newSelectedRows = [];

    if (event.target.checked) {
      newSelected = newSelected.concat(selected, id);
      newSelectedRows = newSelected.concat(id);
      this.setState({
        selected: newSelected,
        jimSelectedData: newSelectedRows,
        jimSelectedDataID: id,
        selectedIssueDesc: issueDesc,
        selectedJournalDesc: title
      });
    } else {
      this.setState({
        selected: newSelected,
        jimSelectedData: newSelectedRows,
        jimSelectedDataID: id,
        selectedIssueDesc: "",
        selectedJournalDesc: ""
      });
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleDeleteCountry = () => {
    let { selected } = this.state;
    const [{ store }, dispatch] = this.context;
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");
  };
  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchCountry = (handleSearchCountry) => {
    const [{ store }, dispatch] = this.context;

    let countryValues = handleSearchCountry;
    let countryCode = countryValues.countryCode;
    let countryName = countryValues.countryName;
    let reqBody = { countryCode, countryName };

    this.setState({ searchPopupOpen: false, searchClicked: true });
  };

  onSearchClicked = (reqBody) => {
    this.setState({ rows: [], loading: true });
    searchJournalitemMasterList(reqBody).then((r) => {
      this.getAllJournalItemList(r);
    });
    this.setState({ searchClicked: true });
  };
  clearSearch = () => {
    this.setState({ rows: [], loading: true });
    getJournalitemMasterList().then((r) => {
      // if (deleted) this.setState({ searchClicked: false, selected: [] });
      this.getAllJournalItemList(r.data);
      //this.setState({ loading: true });
    });
    this.setState({ loading: true, searchClicked: false });
  };

  render() {
    const [{ store }, dispatch] = this.context;
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      rows,
      jimSelectedData,
      jimSelectedDataID,
      headCells,
    } = this.state;

    const isSelected = (id) => selected.indexOf(id) !== -1;
    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            handleStageOpen={this.handleStageOpen}
            stageLoading={this.state.stageLoading}
            jimSelectedData={jimSelectedData}
            jimSelectedDataID={jimSelectedDataID}
            history={this.props.history}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchCountry={this.handleSearchCountry}
            countrySearchValue={this.state.countrySearchValue}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteCountry={this.handleDeleteCountry}
            handleViewColumns={this.handleViewColumns}
            headCells={headCells}
            orderPlacementList={rows}
            selected={selected}
            onSearchClicked={this.onSearchClicked}
            editable={this.state.editable}
            roles={this.props.roles}
          />
          <StageModal
            open={this.state.stageOpen}
            handleStageOpen={this.handleStageOpen}
            list={this.state.stageList}
            issueDesc={this.state.selectedIssueDesc}
            journalDesc={this.state.selectedJournalDesc}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={rows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {rows.length > 0 ? (
                    stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.active === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    onClick={(event) =>
                                      this.handleClick(
                                        event,
                                        row.coustomerName,
                                        row,
                                        row.id,
                                        row.issueDesc,
                                        row.title
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.client && row.client.length > 16 ? (
                                  <Tooltip title={row.client} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.client}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.client}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.issueDesc && row.issueDesc.length > 14 ? (
                                  <Tooltip
                                    title={row.issueDesc}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issueDesc}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issueDesc}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.acronym && row.acronym.length > 14 ? (
                                  <Tooltip title={row.acronym} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.acronym}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.acronym}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.volume && row.volume.length > 14 ? (
                                  <Tooltip title={row.volume} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.volume}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.volume}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.issue && row.issue.length > 14 ? (
                                  <Tooltip title={row.issue} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issue}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issue}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.suppNo && row.suppNo.length > 14 ? (
                                  <Tooltip title={row.suppNo} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.suppNo}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.suppNo}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.partNo && row.partNo.length > 14 ? (
                                  <Tooltip title={row.partNo} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.partNo}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.partNo}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.title && row.title.length > 14 ? (
                                  <Tooltip title={row.title} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.title}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.title}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.uniqueIssueId &&
                                  row.uniqueIssueId.length > 14 ? (
                                    <Tooltip
                                      title={row.uniqueIssueId}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.uniqueIssueId}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.uniqueIssueId}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.insert && row.insert.length > 14 ? (
                                  <Tooltip title={row.insert} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.insert}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.insert}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.type && row.type.length > 14 ? (
                                  <Tooltip title={row.type} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.type}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.type}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.office && row.office.length > 14 ? (
                                  <Tooltip title={row.office} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.office}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.office}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.expectedDeliveryDate &&
                                  row.expectedDeliveryDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(
                                        row.expectedDeliveryDate
                                      ).format("DD/MM/YYYY")}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.expectedDeliveryDate).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.expectedDeliveryDate
                                        ? moment(row.expectedDeliveryDate).format(
                                          "DD/MM/YYYY"
                                        )
                                        : ""}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.printRun && row.printRun.length > 14 ? (
                                  <Tooltip
                                    title={row.printRun}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.printRun}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.printRun}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.estMLR && row.estMLR.length > 16 ? (
                                  <Tooltip title={row.estMLR} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.estMLR}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.estMLR}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[15].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.estOMR && row.estOMR.length > 16 ? (
                                  <Tooltip title={row.estOMR} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.estOMR}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.estOMR}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[16].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.estBalanceOfStock &&
                                  row.estBalanceOfStock.length > 16 ? (
                                    <Tooltip
                                      title={row.estBalanceOfStock}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.estBalanceOfStock}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.estBalanceOfStock}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[17].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.estUnitWeight &&
                                  row.estUnitWeight.length > 16 ? (
                                    <Tooltip
                                      title={row.estUnitWeight}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.estUnitWeight}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.estUnitWeight}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[18].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.estTotalWeight &&
                                  row.estTotalWeight.length > 16 ? (
                                    <Tooltip
                                      title={row.estTotalWeight}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.estTotalWeight}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.estTotalWeight}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[19].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.returnByAir &&
                                  row.returnByAir.length > 16 ? (
                                    <Tooltip
                                      title={row.returnByAir}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.returnByAir}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.returnByAir}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[20].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.returnBySea &&
                                  row.returnBySea.length > 16 ? (
                                    <Tooltip
                                      title={row.returnBySea}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.returnBySea}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.returnBySea}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[21].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.division && row.division.length > 16 ? (
                                  <Tooltip
                                    title={row.division}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.division}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.division}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[22].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.dimension && row.dimension.length > 16 ? (
                                  <Tooltip
                                    title={row.dimension}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dimension}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dimension}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[23].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.printer && row.printer.length > 16 ? (
                                  <Tooltip title={row.printer} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.printer}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.printer}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[24].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.splInstruction &&
                                  row.splInstruction.length > 16 ? (
                                    <Tooltip
                                      title={row.splInstruction}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.splInstruction}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.splInstruction}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[25].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.splDeliveryInstruction &&
                                  row.splDeliveryInstruction.length > 16 ? (
                                    <Tooltip
                                      title={row.splDeliveryInstruction}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.splDeliveryInstruction}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.splDeliveryInstruction}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[26].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.offlineFlag &&
                                  row.offlineFlag.length > 16 ? (
                                    <Tooltip
                                      title={row.offlineFlag}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.offlineFlag}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.offlineFlag}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[27].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.distributor &&
                                  row.distributor.length > 16 ? (
                                    <Tooltip
                                      title={row.distributor}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.distributor}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.distributor}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[28].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.uspsPermit &&
                                  row.uspsPermit.length > 16 ? (
                                    <Tooltip
                                      title={row.uspsPermit}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.uspsPermit}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.uspsPermit}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[29].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.priorityTitle &&
                                  row.priorityTitle.length > 16 ? (
                                    <Tooltip
                                      title={row.priorityTitle}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.priorityTitle}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.priorityTitle}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[30].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.splHandling &&
                                  row.splHandling.length > 16 ? (
                                    <Tooltip
                                      title={row.splHandling}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.splHandling}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.splHandling}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[31].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.journalReceivedDate &&
                                  row.journalReceivedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(
                                        row.journalReceivedDate
                                      ).format("DD/MM/YYYY HH:mm:ss")}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.journalReceivedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.journalReceivedDate
                                        ? moment(row.journalReceivedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )
                                        : ""}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[32].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.actualPrintRunReceived &&
                                  row.actualPrintRunReceived.length > 16 ? (
                                    <Tooltip
                                      title={row.actualPrintRunReceived}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.actualPrintRunReceived}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.actualPrintRunReceived}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[33].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.actualWeight &&
                                  row.actualWeight.length > 16 ? (
                                    <Tooltip
                                      title={row.actualWeight}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.actualWeight}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.actualWeight}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[34].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.cptWeight &&
                                  row.cptWeight.length > 16 ? (
                                    <Tooltip
                                      title={row.cptWeight}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.cptWeight}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.cptWeight}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[35].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.blWeight &&
                                  row.blWeight.length > 16 ? (
                                    <Tooltip
                                      title={row.blWeight}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.blWeight}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.blWeight}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[36].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.mlCopies && row.mlCopies.length > 16 ? (
                                  <Tooltip
                                    title={row.mlCopies}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.mlCopies}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.mlCopies}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[37].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.omCopies && row.omCopies.length > 16 ? (
                                  <Tooltip
                                    title={row.omCopies}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.omCopies}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.omCopies}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[38].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.otCopies && row.otCopies.length > 16 ? (
                                  <Tooltip
                                    title={row.otCopies}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.otCopies}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.otCopies}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[39].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.hcCopies && row.hcCopies.length > 16 ? (
                                  <Tooltip
                                    title={row.hcCopies}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.hcCopies}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.hcCopies}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[40].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.eboCopies && row.eboCopies.length > 16 ? (
                                  <Tooltip
                                    title={row.eboCopies}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.eboCopies}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.eboCopies}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[41].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.issnNo && row.issnNo.length > 16 ? (
                                  <Tooltip title={row.issnNo} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issnNo}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issnNo}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[42].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.publicationDate &&
                                  row.publicationDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.publicationDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.publicationDate).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.publicationDate
                                        ? moment(row.publicationDate).format(
                                          "DD/MM/YYYY"
                                        )
                                        : ""}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[43].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.publicationYear}
                              </TableCell>
                            )}
                            {/* {this.state.headCells[42].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.offlineQty &&
                                row.offlineQty.length > 16 ? (
                                  <Tooltip
                                    title={row.offlineQty}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.offlineQty}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.offlineQty}
                                  </Typography>
                                )}
                              </TableCell>
                            )} */}
                            {this.state.headCells[44].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.cea && row.cea.length > 16 ? (
                                  <Tooltip title={row.cea} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.cea}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.cea}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[45].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.ces && row.ces.length > 16 ? (
                                  <Tooltip title={row.ces} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.ces}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.ces}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[46].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.status && row.status.length > 16 ? (
                                  <Tooltip title={row.status} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.status}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.status}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[47].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {status}
                              </TableCell>
                            )}
                            {this.state.headCells[48].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.mdfDate && row.mdfDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.mdfDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.mdfDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.mdfDate
                                        ? moment(row.mdfDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )
                                        : ""}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[49].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.mdfBy && row.mdfBy.length > 16 ? (
                                  <Tooltip title={row.mdfBy} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.mdfBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.mdfBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[50].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.code} */}
                                {row.ctdDate && row.ctdDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.ctdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.ctdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.ctdDate
                                        ? moment(row.ctdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )
                                        : ""}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[51].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}

                                {row.ctdBy && row.ctdBy.length > 16 ? (
                                  <Tooltip title={row.ctdBy} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.ctdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.ctdBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={10} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(JournalItemMaster);

// import React from "react";
// export default class LocalPostage extends React.Component {
//   render() {
//     return <div>dssdsd</div>;
//   }
// }
