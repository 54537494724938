import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { Checkbox } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import InputLabel from "@material-ui/core/InputLabel";
import clone from "clone";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Select from "@material-ui/core/Select";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
//import ManageCountry from "./countryManage";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AdvanceSearch from "./search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  getOrderGroup,
  getOrderList,
  getGroupProcess,
  serchOrderGroupOrder,
  serchOrderListOrder,
  serchGroupProcessOrder,
} from "../orderPlacement/actions";
import { StateContext } from "../../../shared/globalState";
import Typography from "@material-ui/core/Typography";
import DeleteOrder from "./delete";
import FormGroup from "@material-ui/core/FormGroup";
import Validator from "../../../shared/validator";
import {
  desc,
  stableSort,
  getSorting,
  DialogActions,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import toastr from "toastr";
import moment from "moment";
import "../../app/App.css";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { ExportCSV } from "../../../shared/ExportCSV";
import {
  SearchIcon,
  DownloadIcon,
  ViewColumnIcon,
  EditIcon,
  ViewIcon,
  AddIcon,
} from "../../../shared/customIcons";
import LinearProgress from "@material-ui/core/LinearProgress";
import "../../app/App.css";
const validateMaxlength2 = new Validator("maxlength2");

const columns = [
  {
    id: 10,
    name: "Modified By",
  },
  {
    id: 11,
    name: "Modified Date",
  },
  {
    id: 12,
    name: "Created By",
  },
  {
    id: 13,
    name: "Created Date",
  },

  {
    id: 9,
    name: "Status",
  },
];
const columns1 = [
  {
    id: 10,
    name: "Modified By",
  },
  {
    id: 11,
    name: "Modified Date",
  },
  {
    id: 12,
    name: "Created By",
  },
  {
    id: 13,
    name: "Created Date",
  },
];
const columns2 = [
  {
    id: 9,
    name: "Modified By",
  },
  {
    id: 10,
    name: "Modified Date",
  },
  {
    id: 11,
    name: "Created By",
  },
  {
    id: 12,
    name: "Created Date",
  },
];
function createData(
  customerCode,
  customer,
  groupId,
  noOfOrder,
  totalOrderQty,
  orderTitle,
  splInstruction,
  planner,
  status,
  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate,
  id,
  customerOrderHeaderID,
  statusCode
) {
  return {
    customerCode,
    customer,
    groupId,
    noOfOrder,
    totalOrderQty,
    orderTitle,
    splInstruction,
    planner,
    status,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
    id,
    customerOrderHeaderID,
    statusCode,
  };
}
function createDataList(
  customerCode,
  customerName,
  groupId,
  orderId,
  fileName,
  workOrderNo,
  orderTitle,
  quantity,
  status,
  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate,
  id,
  totalOrderQty
) {
  return {
    customerCode,
    customerName,
    groupId,
    orderId,
    fileName,
    workOrderNo,
    orderTitle,
    quantity,
    status,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
    id,
    totalOrderQty,
  };
}
function createDataProcess(
  customerCode,
  customerName,
  groupId,
  print,
  processName,
  processType,
  processQty,

  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate,
  id,
  orderTitle,
  totalOrderQty
) {
  return {
    customerCode,
    customerName,
    groupId,
    print,
    processName,
    processType,
    processQty,

    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
    id,
    orderTitle,
    totalOrderQty,
  };
}
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow hover>
        {props.selectedTable === "orderGroup" ? (
          <TableCell
            padding="checkbox"
            style={{ borderTop: "1px solid #eaeaea", background: "white" }}
            className="table-cell"
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={numSelected === rowCount}
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={numSelected === rowCount}
                  onChange={onSelectAllClick}
                  name="all"
                  inputProps={{ "aria-label": "Select All Countrys" }}
                />
              }
            />
          </TableCell>
        ) : null}
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                // align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.code}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
            //);
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  //console.log(props);
  const [errors, setErrors] = useState({
    countryCode: "",
    countryName: "",
  });
  const [formValid, setIsformValid] = useState(false);
  let acnArr = props.roles.filter((u) => u.menuName === "User Order Placement");
  let acnObj = acnArr[0];

  const [countrySearchValue, setValues] = useState({
    countryCode: "",
    countryName: "",
  });

  const classes = useToolbarStyles();
  const {
    numSelected,
    selectedLocalPostage,
    selectedLocalPostageID,
    handleDeletePopupOpen,
    deletePopupOpen,
    searchPopupOpen,
    handleDeletePopupClose,
    handleDeleteCountry,
    handleViewColumns,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchCountry,
    searchClicked,
    selected,
    localPostage,
    orderPlacementList,
    handleTableHead,
    selectedTable,
    customerOrder,
    customerOrderHeaderID,
    editable,
    removable,
  } = props;

  let expData = clone(orderPlacementList);

  if (selectedTable === "orderGroup") {
    expData.map((d) => {
      d["Customer Code"] = d["customerCode"];
      d["Customer"] = d["customer"];
      d["Group Id"] = d["groupId"];
      d["Order Title"] = d["orderTitle"];
      d["No. Of Order"] = d["noOfOrder"];
      d["Total Order Qty"] = d["totalOrderQty"];
      d["Spl Instruction"] = d["splInstruction"];
      d["Planner"] = d["planner"];
      d["Status"] = d["status"];
      d["Active"] = d["isActive"];
      d["Modified By"] = d["modifiedBy"];

      d["Modified Date"] = moment(d["Modified Date"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      d["Created By"] = d["createdBy"];
      d["Created Date"] = moment(d["createdDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );

      delete d["totalOrderQty"];
      delete d["statusCode"];
      delete d["status"];
      delete d["splInstruction"];
      delete d["planner"];
      delete d["orderTitle"];
      delete d["noOfOrder"];
      delete d["groupId"];
      delete d["customerOrderHeaderID"];
      delete d["customerCode"];
      delete d["customer"];
      delete d["Special Instruction"];
      delete d["Office Name"];
      delete d["modifiedDate"];
      delete d["modifiedBy"];
      delete d["Journal Type"];
      delete d["Internal"];
      delete d["id"];
      delete d["Dimension"];
      delete d["createdDate"];

      delete d["createdBy"];
      delete d["Business Unit"];
      delete d["Active"];
      delete d["Acronym Suffix"];
      delete d["Acronym Number"];
    });
  } else if (selectedTable === "orderList") {
    expData.map((d) => {
      d["Customer Code"] = d["customerCode"];
      d["Customer"] = d["customerName"];
      d["Group Id"] = d["groupId"];
      d["Order Id"] = d["orderId"];
      d["File Name"] = d["fileName"];
      d["Work Order No"] = d["workOrderNo"];
      d["Order Title"] = d["orderTitle"];
      d["Quantity"] = d["quantity"];

      d["Status"] = d["status"];

      d["Modified By"] = d["modifiedBy"];

      d["Modified Date"] = moment(d["modifiedDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      d["Created By"] = d["createdBy"];
      d["Created Date"] = moment(d["createdDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );

      delete d["fileName"];
      delete d["id"];
      delete d["Periodical"];
      delete d["quantity"];

      delete d["totalOrderQty"];
      delete d["statusCode"];
      delete d["status"];
      delete d["splInstruction"];
      delete d["planner"];
      delete d["orderTitle"];
      delete d["noOfOrder"];
      delete d["groupId"];
      delete d["customerOrderHeaderID"];
      delete d["customerCode"];
      delete d["customerName"];
      delete d["Special Instruction"];
      delete d["Office Name"];
      delete d["modifiedDate"];
      delete d["modifiedBy"];
      delete d["JournalType"];
      delete d["Internal"];
      delete d["orderId"];
      delete d["Dimension"];
      delete d["createdDate"];
      delete d["workOrderNo"];

      delete d["createdBy"];
      delete d["Business Unit"];
      delete d["Active"];
      delete d["Acronym Suffix"];
      delete d["Acronym Number"];
    });
  } else {
    expData.map((d) => {
      d["Customer Code"] = d["customerCode"];
      d["Customer Name"] = d["customerName"];
      d["Group Id"] = d["groupId"];
      d["Print"] = d["print"];
      d["Process Name"] = d["processName"];
      d["Process Type"] = d["processType"];
      d["Process Qty"] = d["processQty"];

      d["Modified By"] = d["modifiedBy"];

      d["Modified Date"] = moment(d["modifiedDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      d["Created By"] = d["createdBy"];
      d["Created Date"] = moment(d["createdDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );

      delete d["customerCode"];
      delete d["totalOrderQty"];
      delete d["processType"];
      delete d["processQty"];
      delete d["processName"];
      delete d["print"];
      delete d["orderTitle"];
      delete d["id"];
      delete d["groupId"];
      delete d["customerName"];
      delete d["createdBy"];
      delete d["createdDate"];
      delete d["modifiedBy"];
      delete d["modifiedDate"];
    });
  }

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteCountry = () => {
    handleDeleteCountry();
  };
  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = countrySearchValue;
    searchValues.countryCode = "";
    searchValues.countryName = "";
    searchValues = countrySearchValue;
    setIsformValid(false);
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };
  const dropdownMenuProps = {
    menuStyle: {
      border: "1px solid black",
      borderRadius: "5%",
      backgroundColor: "lightgrey",
    },
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {" "}
        {numSelected === 0 && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="custom-select">
              <select
                onChange={(e) => {
                  handleTableHead(e);
                }}
              >
                <option value="orderGroup" className="custom-select-options">
                  Order Group
                </option>
                <option value="orderList" className="custom-select-options">
                  Order List
                </option>
                <option value="groupProcess" className="custom-select-options">
                  Group Process
                </option>
              </select>
            </div>
          </div>
        )}
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {customerOrder && (
                  <span>
                    <Link
                      to={`/view_orderPlacement/${customerOrderHeaderID}/${selected[0]}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          boxShadow: "none",
                          fontSize: "12px",
                          fontWeight: "500",
                          // color: "#3982ea",
                          margin: "4px",
                        }}
                      >
                        DOWNLOAD CUSTOMER ORDER
                      </Button>
                    </Link>
                  </span>
                )}
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_userOrder/${selected[0]}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && editable && (
                  <Tooltip title="Edit">
                    {/* <Link to={`/edit_orderPlacement/${selected[0]}`}> */}
                    <Link
                      to={{
                        pathname: `/edit_userOrder/${selected[0]}`,
                        state: { approve: acnObj.approveAction },
                      }}
                    >
                      <IconButton
                        aria-label="edit"
                        className={classes.iconHover}
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            )}
            {acnObj &&
              acnObj.deleteAction &&
              selectedTable === "orderGroup" &&
              removable && (
                <DeleteOrder
                  mode="delete"
                  selected={selected}
                  selectedRows={props.selectedRows}
                  onRequestSucceed={props.onRequestSucceed}
                // count={props.selected.length}
                // isActive={props.isActive}
                />
              )}
            {/* <Tooltip title="Delete">
              <DeleteLocalPostage
                mode="delete"
                selected={selected}
                onRequestSucceed={props.onRequestSucceed}
                count={props.selected.length}
              />
            </Tooltip> */}
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {acnObj && acnObj.advanceSearchAction && searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  acnObj &&
                  acnObj.advanceSearchAction && (
                    <AdvanceSearch
                      onSearchClicked={props.onSearchClicked}
                      onRequestSucceed={props.onRequestSucceed}
                      searchMode={selectedTable}
                    />
                  )
                )}

              {acnObj &&
                acnObj.addAction &&
                props.selectedTable === "orderGroup" && (
                  <Tooltip title="Add">
                    <IconButton
                      aria-label="add"
                      className={classes.iconHover}
                      onClick={(e) => props.history.push("/add_userOrder")}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                )}
              {acnObj && acnObj.exportAction && (
                <Tooltip title="Download">
                  <ExportCSV csvData={expData} fileName={"orderPlacement"} />
                </Tooltip>
              )}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                handleViewColumns={handleViewColumns}
                headCells={props.headCells}
                selectedTable={selectedTable}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 10.2;
  const left = 77;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    handleViewColumns,
    open,
    ...other
  } = props;
  const [column, setColumnIds] = React.useState(valueProp);
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  }, [valueProp, open]);

  const handleClose = () => {
    onClose(setColumnIds);
  };

  const handleChange = (event) => {
    setColumnIds(event.target.value);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        {props.selectedTable === "orderGroup" ? (
          <FormGroup>
            {columns.map((column) => {
              return (
                <FormControlLabel
                  key={column.name}
                  control={
                    <Checkbox
                      color="primary"
                      checked={
                        headCells[column.id - 1] &&
                        headCells[column.id - 1].show
                      }
                      value={true}
                      onChange={(e) => handleViewColumns(e, column.id)}
                    />
                  }
                  label={column.name}
                />
              );
            })}
          </FormGroup>
        ) : props.selectedTable === "orderList" ? (
          <FormGroup>
            {columns1.map((column) => {
              return (
                <FormControlLabel
                  key={column.name}
                  control={
                    <Checkbox
                      color="primary"
                      checked={
                        headCells[column.id - 1] &&
                        headCells[column.id - 1].show
                      }
                      value={true}
                      onChange={(e) => handleViewColumns(e, column.id)}
                    />
                  }
                  label={column.name}
                />
              );
            })}
          </FormGroup>
        ) : (
              <FormGroup>
                {columns2.map((column) => {
                  return (
                    <FormControlLabel
                      key={column.name}
                      control={
                        <Checkbox
                          color="primary"
                          checked={
                            headCells[column.id - 1] &&
                            headCells[column.id - 1].show
                          }
                          value={true}
                          onChange={(e) => handleViewColumns(e, column.id)}
                        />
                      }
                      label={column.name}
                    />
                  );
                })}
              </FormGroup>
            )}
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class UserOrderPlacement extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    selRecArr: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    orderPlacementList: [],
    loading: false,
    selectedLocalPostage: {},
    selectedLocalPostageID: 1,
    selectedShowColumns: [],
    searchPopupOpen: false,
    countrySearchValues: {},
    searchClicked: false,
    selectedTable: "orderGroup",
    ogList: [],
    olList: [],
    gpList: [],
    customerOrder: false,
    viewCustomerOrderHeaderID: 0,
    selectedRows: {},
    editable: true,
    removable: true,
    headCells: [
      {
        code: "customerCode",
        id: 1,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Customer Code",
        show: true,
      },
      {
        code: "customer",
        id: 2,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Customer",
        show: true,
      },
      {
        code: "groupId",
        id: 3,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Group Id",
        show: true,
      },
      {
        code: "orderTitle",
        id: 4,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Order Title",
        show: true,
      },

      {
        code: "noOfOrder",
        id: 5,
        numeric: false,
        disablePadding: false,
        label: "No. Of Order",
        show: true,
      },
      {
        id: 6,
        code: "totalOrderQty",
        numeric: false,
        disablePadding: false,
        label: "Total Order Qty",
        show: true,
      },

      {
        id: 7,
        code: "splInstruction",
        numeric: false,
        disablePadding: false,
        label: "Spl Instruction",
        show: true,
      },
      {
        id: 8,
        code: "planner",
        numeric: false,
        disablePadding: false,
        label: "Planner",
        show: true,
      },
      {
        id: 9,
        code: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        show: true,
      },
      {
        id: 10,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 11,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 12,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },

      {
        id: 13,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
    ],
    orderGroup: [
      {
        code: "customerCode",
        id: 1,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Customer Code",
        show: true,
      },
      {
        code: "customer",
        id: 2,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Customer",
        show: true,
      },
      {
        code: "groupId",
        id: 3,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Group Id",
        show: true,
      },
      {
        code: "orderTitle",
        id: 4,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Order Title",
        show: true,
      },

      {
        code: "noOfOrder",
        id: 5,
        numeric: false,
        disablePadding: false,
        label: "No. Of Order",
        show: true,
      },
      {
        id: 6,
        code: "totalOrderQty",
        numeric: false,
        disablePadding: false,
        label: "Total Order Qty",
        show: true,
      },

      {
        id: 7,
        code: "splInstruction",
        numeric: false,
        disablePadding: false,
        label: "Spl Instruction",
        show: true,
      },
      {
        id: 8,
        code: "planner",
        numeric: false,
        disablePadding: false,
        label: "Planner",
        show: true,
      },
      {
        id: 9,
        code: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        show: true,
      },
      {
        id: 10,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 11,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 12,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },

      {
        id: 13,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
    ],
    orderList: [
      {
        code: "customerCode",
        id: 1,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Customer Code",
        show: true,
      },
      {
        code: "customerName",
        id: 2,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Customer Name",
        show: true,
      },
      {
        code: "groupId",
        id: 3,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Group Id",
        show: true,
      },
      {
        code: "orderId",
        id: 4,
        numeric: false,
        disablePadding: false,
        label: "Order Id",
        show: true,
      },
      {
        id: 5,
        code: "fileName",
        numeric: false,
        disablePadding: false,
        label: "File Name",
        show: true,
      },
      {
        id: 6,
        code: "workOrderNo",
        numeric: false,
        disablePadding: false,
        label: "Work Order No",
        show: true,
      },
      {
        id: 7,
        code: "orderTitle",
        numeric: false,
        disablePadding: false,
        label: "Order Title",
        show: true,
      },
      {
        id: 8,
        code: "quantity",
        numeric: false,
        disablePadding: false,
        label: "Quantity",
        show: true,
      },
      {
        id: 9,
        code: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        show: true,
      },
      {
        id: 10,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },

      {
        id: 11,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 12,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },

      {
        id: 13,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
    ],
    groupProcess: [
      {
        code: "customerCode",
        id: 1,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Customer Code",
        show: true,
      },
      {
        code: "customerName",
        id: 2,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Customer Name",
        show: true,
      },
      {
        code: "groupId",
        id: 3,
        show: true,
        numeric: false,
        disablePadding: true,
        label: "Group Id",
        show: true,
      },
      {
        code: "print",
        id: 4,
        numeric: false,
        disablePadding: false,
        label: "Print",
        show: true,
      },
      {
        id: 5,
        code: "processName",
        numeric: false,
        disablePadding: false,
        label: "Process Name",
        show: true,
      },
      {
        id: 6,
        code: "processType",
        numeric: false,
        disablePadding: false,
        label: "Process Type",
        show: true,
      },
      {
        id: 7,
        code: "processQty",
        numeric: false,
        disablePadding: false,
        label: "Process Qty",
        show: true,
      },
      {
        id: 8,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },

      {
        id: 9,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 10,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },

      {
        id: 11,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
    ],
  };
  handleViewColumns = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  componentDidMount() {
    this.props.handleSettingHeader("User Order Placement");
    this.props.setSelectedList("genericSystemOpen", "genericSystem");

    const [{ store }, dispatch] = this.context;
    getOrderGroup().then((r) => {
      // console.log(r);
      this.getAllOrderGroup(r.data);
    });

    this.setState({ loading: true });
  }

  shouldComponentUpdate(s, p) {
    const [{ store }, dispatch] = this.context;

    return true;
  }
  onRequestSucceed = (deleted) => {
    if (deleted) this.setState({ searchClicked: false, selected: [], selRecArr: [] });
    getOrderGroup().then((r) => {
      this.getAllOrderGroup(r.data);
    });
  };
  getAllOrderGroup = (lpList) => {
    let list = lpList;
    let newRows = [];

    list &&
      list.length > 0 &&
      list.map((d) => {
        newRows.push(
          createData(
            d.customerCode,
            d.customer,
            d.groupID,
            d.noOfOrders,
            d.totalOrderQty,
            d.orderTitle,
            d.specialInstruction,
            d.planner,
            d.status,

            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate,
            d.orderGroupID,
            d.customerOrderHeaderID,
            d.statusCode
          )
        );
      });
    this.setState({ rows: newRows, loading: false });
  };
  getAllOrderList = (lpList) => {
    let list = lpList;
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        newRows.push(
          createDataList(
            d.customerCode,
            d.customerName,
            d.groupID,
            d.orderCode,
            d.fileName,
            d.workOrderCode,
            d.orderTitle,
            d.quantity,
            d.status,
            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate,
            d.orderId
          )
        );
      });
    this.setState({ rows: newRows, loading: false });
  };
  getAllGroupProcess = (lpList) => {
    let list = lpList;
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        newRows.push(
          createDataProcess(
            d.customerCode,
            d.customerName,
            d.groupID,
            d.print,
            d.processName,
            d.processType,
            d.processQuantity,
            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate,
            d.customerOrderHeaderID,
            d.orderTitle
          )
        );
      });
    this.setState({ rows: newRows, loading: false });
  };
  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { rows } = this.state;
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };
  handleTableHead = (e) => {
    let list = [];

    if (e.target.value === "orderGroup") {
      this.setState({ loading: true });
      getOrderGroup().then((r) => {
        this.getAllOrderGroup(r.data);
      });
    }
    if (e.target.value === "orderList") {
      this.setState({ loading: true });
      getOrderList().then((r) => {
        this.getAllOrderList(r.data);
      });
    }
    if (e.target.value === "groupProcess") {
      this.setState({ loading: true });
      getGroupProcess().then((r) => {
        this.getAllGroupProcess(r.data);
      });
    }

    this.setState({
      selectedTable: e.target.value,
      headCells: this.state[e.target.value],
      rows: list,
      searchClicked: false,
    });
  };
  handleClick = (event, code, data, id) => {
    let rowSelected = [];
    let removable = true
    let { selected, selRecArr } = this.state;
    const selectedIndex = selected.indexOf(id);
    // const selectedRecIndex = selected.indexOf(id);

    let newSelected = [];
    let newSelRecArr = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      newSelRecArr = newSelRecArr.concat(selRecArr, data);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelRecArr = newSelRecArr.concat(selRecArr.slice(1));

    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelRecArr = newSelRecArr.concat(selRecArr.slice(0, -1));

    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      newSelRecArr = newSelRecArr.concat(
        selRecArr.slice(0, selectedIndex),
        selRecArr.slice(selectedIndex + 1)
      );
    }

    if (newSelected.length === 1) {
      this.setState({
        selected: newSelected,
        selectedLocalPostage: data,
        selectedLocalPostageID: data.id,
        selRecArr: newSelRecArr
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedLocalPostage: {},
        selectedLocalPostageID: 0,
        selRecArr: newSelRecArr
      });
    }
    if (newSelected.length === 1) {
      rowSelected = this.state.rows.filter((r) => r.id === newSelected[0]);
      this.setState({ selectedRows: rowSelected });
    }

    if (newSelRecArr.length > 0) {
      newSelRecArr.map(rs => {

        if (
          rs.statusCode === "C" ||
          rs.statusCode === "IP" ||
          rs.statusCode === "CD"
        )
          removable = false
      })
      this.setState({ removable });

    }
    this.setState({
      viewCustomerOrderHeaderID:
        rowSelected &&
        rowSelected.length > 0 &&
        rowSelected[0].customerOrderHeaderID,
    });
    if (rowSelected.length > 0) {
      if (rowSelected[0].groupId.startsWith("AO")) {
        this.setState({ customerOrder: true });
      } else {
        this.setState({ customerOrder: false });
      }
    }

    if (rowSelected.length > 0) {
      if (
        rowSelected[0].statusCode === "C" ||
        rowSelected[0].statusCode === "CD"
      ) {
        this.setState({ editable: false });
      } else {
        this.setState({ editable: true });
      }

      // if (
      //   rowSelected[0].statusCode === "C" ||
      //   rowSelected[0].statusCode === "IP" ||
      //   rowSelected[0].statusCode === "CD"
      // ) {
      //   this.setState({ removable: false });
      // } else {
      //   this.setState({ removable: true });
      // }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleDeleteCountry = () => {
    let { selected } = this.state;
    const [{ store }, dispatch] = this.context;
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");
  };
  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true, page: 0 });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchCountry = (handleSearchCountry) => {
    const [{ store }, dispatch] = this.context;

    let countryValues = handleSearchCountry;
    let countryCode = countryValues.countryCode;
    let countryName = countryValues.countryName;
    let reqBody = { countryCode, countryName };

    this.setState({ searchPopupOpen: false, searchClicked: true });
  };

  onSearchClicked = (reqBody) => {
    if (this.state.selectedTable === "orderGroup")
      serchOrderGroupOrder(reqBody).then((r) => {
        this.getAllOrderGroup(r.data);
      });
    if (this.state.selectedTable === "orderList")
      serchOrderListOrder(reqBody).then((r) => {
        this.getAllOrderList(r.data);
      });
    if (this.state.selectedTable === "groupProcess")
      serchGroupProcessOrder(reqBody).then((r) => {
        this.getAllGroupProcess(r.data);
      });
    this.setState({ searchClicked: true, page: 0 });
  };

  clearSearch = () => {
    if (this.state.selectedTable === "orderGroup")
      getOrderGroup().then((r) => {
        this.getAllOrderGroup(r.data);
      });
    if (this.state.selectedTable === "orderList")
      getOrderList().then((r) => {
        this.getAllOrderList(r.data);
      });
    if (this.state.selectedTable === "groupProcess")
      getGroupProcess().then((r) => {
        this.getAllGroupProcess(r.data);
      });
    this.setState({ searchClicked: false });
  };
  render() {
    // console.log(this.state.rows);
    const [{ store }, dispatch] = this.context;
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      rows,
      selectedLocalPostage,
      selectedLocalPostageID,
      headCells,
      selectedTable,
    } = this.state;
    //console.log(rows);
    const isSelected = (id) => selected.indexOf(id) !== -1;
    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedLocalPostage={selectedLocalPostage}
            selectedLocalPostageID={selectedLocalPostageID}
            history={this.props.history}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchCountry={this.handleSearchCountry}
            countrySearchValue={this.state.countrySearchValue}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteCountry={this.handleDeleteCountry}
            handleViewColumns={this.handleViewColumns}
            headCells={headCells}
            orderPlacementList={rows}
            selected={selected}
            onSearchClicked={this.onSearchClicked}
            handleTableHead={this.handleTableHead}
            selectedTable={selectedTable}
            customerOrder={this.state.customerOrder}
            customerOrderHeaderID={this.state.viewCustomerOrderHeaderID}
            selectedRows={this.state.selectedRows}
            editable={this.state.editable}
            removable={this.state.removable}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ whiteSpace: "nowrap" }}
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={rows.length}
                  headCells={this.state.headCells}
                  selectedTable={selectedTable}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {rows.length > 0 ? (
                    stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive == true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return selectedTable === "orderGroup" ? (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                style={{ cursor: "pointer" }}
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    onClick={(event) =>
                                      this.handleClick(
                                        event,
                                        row.coustomerName,
                                        row,
                                        row.id
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.customer} */}
                                {row.customerCode &&
                                  row.customerCode.length > 16 ? (
                                    <Tooltip
                                      title={row.customerCode}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.customerCode}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.customer} */}
                                {row.customer && row.customer.length > 16 ? (
                                  <Tooltip
                                    title={row.customer}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customer}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customer}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.groupId && row.groupId.length > 16 ? (
                                  <Tooltip title={row.groupId}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.groupId}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.groupId}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.orderTitle &&
                                  row.orderTitle.length > 16 ? (
                                    <Tooltip title={row.orderTitle}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.orderTitle}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.orderTitle}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.noOfOrder && row.noOfOrder.length > 16 ? (
                                  <Tooltip title={row.noOfOrder}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noOfOrder}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noOfOrder}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.totalOrderQty &&
                                  row.totalOrderQty.length > 16 ? (
                                    <Tooltip title={row.totalOrderQty}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.totalOrderQty}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalOrderQty}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.splInstruction &&
                                  row.splInstruction.length > 16 ? (
                                    <Tooltip title={row.splInstruction}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.splInstruction}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.splInstruction}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.planner && row.planner.length > 16 ? (
                                  <Tooltip title={row.planner}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.planner}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.planner}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell-status"
                              >
                                <Typography
                                  noWrap
                                  style={{
                                    fontSize: "14px",
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: row.status.includes("Placed")
                                      ? "pointer"
                                      : "default",
                                  }}
                                  onClick={(e) =>
                                    row.status.includes("Order Placed")
                                      ? this.props.history.push(
                                        `/view_userOrder/${row.id}`
                                      )
                                      : ""
                                  }
                                >
                                  {/* style={{ color: makeStatus(row.status) }} */}
                                  {row && (
                                    <FiberManualRecordIcon
                                      style={{
                                        fontSize: "11px",
                                        color: row.status.includes("Saved")
                                          ? "gray"
                                          : row.status.includes("Progess")
                                            ? "#f4b43c"
                                            : row.status.includes("Cancelled")
                                              ? "red"
                                              : row.status.includes("Completed")
                                                ? "green"
                                                : row.status.includes("Order Placed")
                                                  ? "#0d6cdf"
                                                  : row.status.includes("LC Pending")
                                                    ? "#f4b43c"
                                                    : row.status.includes("LC Approved")
                                                      ? "#0d6cdf"
                                                      : "black",
                                      }}
                                    />
                                  )}
                                  <span
                                    style={{
                                      marginLeft: ".5em",
                                      color: row.status.includes("Saved")
                                        ? "gray"
                                        : row.status.includes("Progess")
                                          ? "#f4b43c"
                                          : row.status.includes("Cancelled")
                                            ? "red"
                                            : row.status.includes("Completed")
                                              ? "green"
                                              : row.status.includes("Order Placed")
                                                ? "#0d6cdf"
                                                : row.status.includes("LC Pending")
                                                  ? "#f4b43c"
                                                  : row.status.includes("LC Approved")
                                                    ? "#0d6cdf"
                                                    : "black",
                                    }}
                                  >
                                    {row.status}
                                  </span>
                                </Typography>
                              </TableCell>
                            )}

                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.modifiedBy &&
                                  row.modifiedBy.length > 16 ? (
                                    <Tooltip title={row.modifiedBy}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedBy}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.createdBy} */}
                                {row.createdBy && row.createdBy.length > 16 ? (
                                  <Tooltip title={row.createdBy}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                                {/* {row.createdDate} */}
                                {/* {moment(row.createdDate).format(
                                  "MMMM Do YYYY, h:mm:ss a"
                                )} */}
                              </TableCell>
                            )}
                          </TableRow>
                        ) : selectedTable === "orderList" ? (
                          <TableRow
                            hover
                            style={{ cursor: "pointer" }}
                          // onClick={event =>
                          //   this.handleClick(
                          //     event,
                          //     row.coustomerName,
                          //     row,
                          //     row.id
                          //   )
                          // }
                          // role="checkbox"
                          // aria-checked={isItemSelected}
                          // tabIndex={-1}
                          // key={row.id}
                          // selected={isItemSelected}
                          //style={{ cursor: "pointer" }}
                          >
                            {/* <TableCell padding="checkbox">
                              <FormControlLabel
                                style={{ visibility: "hidden" }}
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell> */}
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.customerName} */}
                                {row.customerCode &&
                                  row.customerCode.length > 16 ? (
                                    <Tooltip
                                      title={row.customerCode}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.customerCode}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.customerName} */}
                                {row.customerName &&
                                  row.customerName.length > 16 ? (
                                    <Tooltip
                                      title={row.customerName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.customerName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.groupId}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.orderId}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.fileName}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.workOrderNo}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.orderTitle}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.quantity}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell-status"
                              >
                                <Typography noWrap>
                                  {/* style={{ color: makeStatus(row.status) }} */}
                                  {row && (
                                    <FiberManualRecordIcon
                                      style={{
                                        fontSize: "11px",
                                        color: row.status.includes("Saved")
                                          ? "gray"
                                          : row.status.includes("Progess")
                                            ? "#f4b43c"
                                            : row.status.includes("Cancelled")
                                              ? "red"
                                              : row.status.includes("Completed")
                                                ? "green"
                                                : row.status.includes("Order Placed")
                                                  ? "#0d6cdf"
                                                  : row.status.includes("LC Pending")
                                                    ? "#f4b43c"
                                                    : row.status.includes("LC Approved")
                                                      ? "#0d6cdf"
                                                      : "black",
                                      }}
                                    />
                                  )}
                                  <span
                                    style={{
                                      marginLeft: ".5em",
                                      color: row.status.includes("Saved")
                                        ? "gray"
                                        : row.status.includes("Progess")
                                          ? "#f4b43c"
                                          : row.status.includes("Cancelled")
                                            ? "red"
                                            : row.status.includes("Completed")
                                              ? "green"
                                              : row.status.includes("Order Placed")
                                                ? "#0d6cdf"
                                                : row.status.includes("LC Pending")
                                                  ? "#f4b43c"
                                                  : row.status.includes("LC Approved")
                                                    ? "#0d6cdf"
                                                    : "black",
                                    }}
                                  >
                                    {row.status}
                                  </span>
                                </Typography>
                              </TableCell>
                            )}

                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.modifiedBy &&
                                  row.modifiedBy.length > 16 ? (
                                    <Tooltip title={row.modifiedBy}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedBy}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.createdBy && row.createdBy.length > 16 ? (
                                  <Tooltip title={row.createdBy}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        ) : (
                              <TableRow
                                hover
                                style={{ cursor: "pointer" }}
                              // onClick={event =>
                              //   this.handleClick(
                              //     event,
                              //     row.coustomerName,
                              //     row,
                              //     row.id
                              //   )
                              // }
                              // role="checkbox"
                              // aria-checked={isItemSelected}
                              // tabIndex={-1}
                              // key={row.id}
                              // selected={isItemSelected}
                              // //style={{ cursor: "pointer" }}
                              >
                                {/* <TableCell padding="checkbox">
                              <FormControlLabel
                                style={{ visibility: "hidden" }}
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell> */}
                                {this.state.headCells[0].show && (
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                  >
                                    {/* {row.customerName} */}
                                    {row.customerCode &&
                                      row.customerCode.length > 16 ? (
                                        <Tooltip
                                          title={row.customerCode}
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.customerCode}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.customerCode}
                                        </Typography>
                                      )}
                                  </TableCell>
                                )}
                                {this.state.headCells[1].show && (
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                  >
                                    {/* {row.customerName} */}
                                    {row.customerName &&
                                      row.customerName.length > 16 ? (
                                        <Tooltip
                                          title={row.customerName}
                                          aria-label="add"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.customerName}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.customerName}
                                        </Typography>
                                      )}
                                  </TableCell>
                                )}
                                {this.state.headCells[2].show && (
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                  >
                                    <Typography noWrap style={{ fontSize: "14px" }}>
                                      {row.groupId}
                                    </Typography>
                                  </TableCell>
                                )}
                                {this.state.headCells[3].show && (
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                  >
                                    <Typography noWrap style={{ fontSize: "14px" }}>
                                      {row.print}
                                    </Typography>
                                  </TableCell>
                                )}
                                {this.state.headCells[4].show && (
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                  >
                                    {row.processName &&
                                      row.processName.length > 16 ? (
                                        <Tooltip title={row.processName}>
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.processName}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.processName}
                                        </Typography>
                                      )}
                                  </TableCell>
                                )}
                                {this.state.headCells[5].show && (
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                  >
                                    <Typography noWrap style={{ fontSize: "14px" }}>
                                      {row.processType}
                                    </Typography>
                                  </TableCell>
                                )}
                                {this.state.headCells[6].show && (
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="default"
                                    className="table-content-right-cell"
                                  >
                                    <Typography noWrap style={{ fontSize: "14px" }}>
                                      {row.processQty}
                                    </Typography>
                                  </TableCell>
                                )}

                                {this.state.headCells[7].show && (
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                  >
                                    {row.modifiedBy &&
                                      row.modifiedBy.length > 16 ? (
                                        <Tooltip title={row.modifiedBy}>
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.modifiedBy}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.modifiedBy}
                                        </Typography>
                                      )}
                                  </TableCell>
                                )}
                                {this.state.headCells[8].show && (
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="default"
                                    className="table-content-right-cell"
                                  >
                                    {row.modifiedDate &&
                                      row.modifiedDate.length > 16 ? (
                                        <Tooltip
                                          title={moment(row.modifiedDate).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                          )}
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {moment(row.modifiedDate).format(
                                              "DD/MM/YYYY HH:mm:ss"
                                            )}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {moment(row.modifiedDate).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                          )}
                                        </Typography>
                                      )}
                                  </TableCell>
                                )}
                                {this.state.headCells[9].show && (
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                  >
                                    {row.createdBy && row.createdBy.length > 16 ? (
                                      <Tooltip title={row.createdBy}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.createdBy}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.createdBy}
                                        </Typography>
                                      )}
                                  </TableCell>
                                )}
                                {this.state.headCells[10].show && (
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="default"
                                    className="table-content-right-cell"
                                  >
                                    {row.createdDate &&
                                      row.createdDate.length > 16 ? (
                                        <Tooltip
                                          title={moment(row.createdDate).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                          )}
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {moment(row.createdDate).format(
                                              "DD/MM/YYYY HH:mm:ss"
                                            )}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {moment(row.createdDate).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                          )}
                                        </Typography>
                                      )}
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                      })
                  ) : (
                      <TableRow hover>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={10} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(UserOrderPlacement);
