import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import toastr from "toastr";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import { StateContext } from "../../../shared/globalState";
import Divider from "@material-ui/core/Divider";
import { getCustomer } from "../../masters/masterActions";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withRouter } from "react-router-dom";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LetterModal from "./letterModal";
import { AddIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { resourceValidation } from "../../../shared/resource";
import {
  getCustomerList,
  postCustomer,
  getletterShopDropDown,
  getPPIList,
  getCCIList,
  getCountryDropdownList,
  putCustomer,
  getServiceList,
  deletePPI,
  checkValidCC
} from "../masterActions";
import { getUserList, getPlannerList } from "../../userAccess/userAccessAction";

import "../../app/App.css";
const shouldNotEmpty = new Validator("general");
const maxlength10 = new Validator("maxlength10AllAllow");
const notrequired20 = new Validator("notRequired20");
const maxlength100 = new Validator("maxlength100");
const notrequired50 = new Validator("notRequired50");
const notrequired100 = new Validator("notRequired100");
const notRequiredEmail = new Validator("notRequiredEmail");
const notRequired100CharAndNo = new Validator("notRequired100AllAllow");
const maxlength10Alphanumaric = new Validator("maxlength10Alphanumaric");
const billingACNo = new Validator("billingACNo");
const maxlength6OnlyNo = new Validator("maxlength6OnlyNo");
const validatePhoneNo = new Validator("phnNoFCN");

// import { ISO_8601 } from "moment";
// import { isGenericTypeAnnotation } from "@babel/types";

function getSteps() {
  return ["Customer information", "Billing information"];
}
function checkService(val, serviceList) {
  let sv = "";
  if (val) {
    sv = serviceList.find((r) => {
      return r.key == val;
    });
    sv = sv.value;
  }

  if (sv === "PMK" || sv === "TBOX" || sv === "PRE") return true;
  else return false;
}
function getStepContent(
  stepIndex,
  //classes,
  increaseBillingAdderss,
  decreaseBillingAdderss,
  billingAddress,
  ba,
  handleCustomerInfo,
  handleChangeVendor,
  handleBillingAdderss,
  modifyLetterShopInfo,
  cci,
  handleCCI,
  modifyCostCenterInfo,
  handleBIReset,
  handleExpand,
  clearLetterShopInfo,
  countryList,
  letterShopList,
  checkValidPPIInfo,
  plannerList,
  inValidCCi,
  mode,
  active,
  _onKeyPress,
  _onKeyPressNoOnly,
  serviceList,
  cciArr,
  _onKeyPressPhnNoOnly
) {
  //console.log(ba);
  // console.log(ba.countryID);

  switch (stepIndex) {
    case 0:
      return (
        <div className="container">
          <div style={{ paddingLeft: "1.8em", marginTop: "-32px " }}>
            <TextField
              required
              autoFocus
              id="standard-required"
              label="Customer Code"
              //placeholder="Customer Code"
              autoComplete="off"
              // className={classes.textField}
              style={{
                width: "30%",
                fontSize: "11px !important",
                marginLeft: ".5em",
                marginRight: ".5em",
                // paddingTop: "1em"
              }}
              inputProps={{
                maxLength: 10,
              }}
              onKeyPress={_onKeyPress}
              InputProps={
                mode === "edit"
                  ? {
                    readOnly: true,
                  }
                  : { readOnly: false }
              }
              variant={mode === "edit" ? "filled" : "standard"}
              margin="normal"
              value={ba && ba.customerCode ? ba.customerCode.toUpperCase() : ""}
              error={ba.errors.customerCode}
              helperText={
                ba.errors.customerCode ? resourceValidation.alphaNumaric : ""
              }
              onChange={(e) =>
                handleCustomerInfo(e, "customerCode", maxlength10Alphanumaric)
              }
            />
            <TextField
              required
              id="standard-required"
              label="Customer Name"
              //placeholder="Customer Name"
              autoComplete="off"
              //className={classes.textField}
              style={{
                width: "30%",
                fontSize: "11px !important",
                marginLeft: ".5em",
                marginRight: ".5em",
              }}
              inputProps={{
                maxLength: 100,
              }}
              margin="normal"
              value={ba && ba.customerName ? ba.customerName : ""}
              error={ba.errors.customerName}
              helperText={
                ba.errors.customerName ? resourceValidation.customerName : ""
              }
              onChange={(e) =>
                handleCustomerInfo(e, "customerName", shouldNotEmpty)
              }
            />
          </div>
          <div style={{ paddingLeft: "1.8em", marginTop: "-10px " }}>
            <TextField
              required
              autoComplete="off"
              id="standard-required"
              label="Address 1"
              // placeholder="Primary Address"
              // className="text-field-large"
              style={{
                width: "62%",
                fontSize: "11px !important",
                marginLeft: ".5em",
                marginRight: ".5em",
              }}
              inputProps={{
                maxLength: 100,
              }}
              margin="normal"
              value={ba && ba.addressLine1 ? ba.addressLine1 : ""}
              error={ba.errors.addressLine1}
              helperText={
                ba.errors.addressLine1 ? resourceValidation.Address1 : ""
              }
              onChange={(e) =>
                handleCustomerInfo(e, "addressLine1", shouldNotEmpty)
              }
            />
          </div>
          <div style={{ paddingLeft: "1.8em", marginTop: "-10px " }}>
            <TextField
              autoComplete="off"
              id="standard-required"
              label="Address 2"
              // placeholder="Secondary Address"
              //className={classes.textFieldLarge}
              style={{
                width: "62%",
                fontSize: "11px !important",
                marginLeft: ".5em",
                marginRight: ".5em",
              }}
              inputProps={{
                maxLength: 100,
              }}
              margin="normal"
              value={ba && ba.addressLine2 ? ba.addressLine2 : ""}
              error={ba.errors.addressLine2}
              helperText={
                ba.errors.addressLine2 ? resourceValidation.onlyAlphabet : ""
              }
              onChange={(e) =>
                handleCustomerInfo(e, "addressLine2", notRequired100CharAndNo)
              }
            />
          </div>
          <div style={{ paddingLeft: "1.8em", marginTop: "-10px " }}>
            <TextField
              autoComplete="off"
              id="standard-required"
              label="Address 3"
              // placeholder="Secondary Address"
              //className={classes.textFieldLarge}
              style={{
                width: "62%",
                fontSize: "11px !important",
                marginLeft: ".5em",
                marginRight: ".5em",
              }}
              inputProps={{
                maxLength: 100,
              }}
              margin="normal"
              value={ba && ba.addressLine3 ? ba.addressLine3 : ""}
              error={ba.errors.addressLine3}
              helperText={
                ba.errors.addressLine3 ? resourceValidation.onlyAlphabet : ""
              }
              onChange={(e) =>
                handleCustomerInfo(e, "addressLine3", notRequired100CharAndNo)
              }
            />
          </div>
          <div style={{ paddingLeft: "1.8em", marginTop: "-10px " }}>
            <TextField
              autoComplete="off"
              id="standard-required"
              label="Postal Code"
              // placeholder="Postal Code"
              //className={classes.textField}
              style={{
                width: "30%",
                fontSize: "11px !important",
                marginLeft: ".5em",
                marginRight: ".5em",
              }}
              inputProps={{
                maxLength: 20,
              }}
              margin="normal"
              value={ba && ba.postalCode ? ba.postalCode : ""}
              error={ba.errors.postalCode}
              helperText={
                ba.errors.postalCode ? resourceValidation.onlyAlphabet : ""
              }
              onChange={(e) =>
                handleCustomerInfo(e, "postalCode", notrequired20)
              }
            />
            <TextField
              autoComplete="off"
              required
              id="standard-select-currency"
              select
              label="Country"
              //className={classes.textField}
              style={{
                width: "30%",
                fontSize: "11px !important",
                marginLeft: ".5em",
                marginRight: ".5em",
                maxHeight: "400px",
              }}
              inputProps={{
                maxLength: 100,
              }}
              margin="normal"
              value={ba && ba.countryID ? ba.countryID : ""}
              error={ba.errors.countryID}
              helperText={ba.errors.countryID ? "Country can't be empty" : ""}
              onChange={(e) => handleCustomerInfo(e, "countryID", maxlength100)}
            >
              {countryList.map((option) => (
                <MenuItem
                  key={option.key}
                  value={option.key}
                //selected={(option.value = "SG" ? true : false)}
                >
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div style={{ paddingLeft: "1.8em", marginTop: "-10px " }}>
            <TextField
              autoComplete="off"
              id="standard-required"
              label="Contact Person Name"
              // placeholder="Contact Person Name"
              // className={classes.textField}
              style={{
                width: "30%",
                fontSize: "11px !important",
                marginLeft: ".5em",
                marginRight: ".5em",
              }}
              inputProps={{
                maxLength: 100,
              }}
              margin="normal"
              value={ba && ba.contactPersonName ? ba.contactPersonName : ""}
              error={ba.errors.contactPersonName}
              helperText={
                ba.errors.contactPersonName
                  ? resourceValidation.onlyAlphabet
                  : ""
              }
              onChange={(e) =>
                handleCustomerInfo(e, "contactPersonName", notrequired100)
              }
            />
            <TextField
              autoComplete="off"
              id="standard-required"
              label="Phone No"
              // placeholder="Phone No"
              // className={classes.textField}
              //type="number"
              onKeyPress={_onKeyPressPhnNoOnly}
              style={{
                width: "30%",
                fontSize: "11px !important",
                marginLeft: ".5em",
                marginRight: ".5em",
              }}
              inputProps={{
                maxLength: 20,
              }}
              margin="normal"
              value={ba && ba.phoneNumber ? ba.phoneNumber : ""}
              error={ba.errors.phoneNumber}
              helperText={
                ba.errors.phoneNumber ? resourceValidation.validPhoneNo : ""
              }
              onChange={(e) =>
                handleCustomerInfo(e, "phoneNumber", validatePhoneNo)
              }
            />
          </div>
          <div style={{ paddingLeft: "1.8em", marginTop: "-10px " }}>
            <TextField
              autoComplete="off"
              id="standard-required"
              label="Email"
              //placeholder="Email"
              //className={classes.textFieldLarge}
              style={{
                width: "62%",
                fontSize: "11px !important",
                marginLeft: ".5em",
                marginRight: ".5em",
              }}
              inputProps={{
                maxLength: 100,
              }}
              margin="normal"
              value={ba && ba.emailID ? ba.emailID : ""}
              error={ba.errors.emailID}
              helperText={
                ba.errors.emailID ? resourceValidation.validEmailId : ""
              }
              onChange={(e) =>
                handleCustomerInfo(e, "emailID", notRequiredEmail)
              }
            />
          </div>
          <div style={{ paddingLeft: "2.2em", marginTop: "4px " }}>
            {" "}
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value={ba.isVendor}
                  checked={ba.isVendor}
                  onChange={handleChangeVendor("isVendor")}
                  style={{ color: "#306dca" }}
                />
              }
              label="Vendor"
            />
            {mode === "edit" &&
              (active ? (
                <React.Fragment />
              ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        value={ba.isActive}
                        checked={ba.isActive}
                        onChange={handleChangeVendor("isActive")}
                        style={{ color: "#306dca", marginLeft: "1em" }}
                      />
                    }
                    label="Active"
                  />
                ))}
          </div>
          <div
            style={{
              paddingLeft: "2.2em",
              marginTop: "4px ",
              marginRight: "2.4em",
            }}
          >
            <Divider />
          </div>
        </div>
      );
    case 1:
      return (
        <div style={{ paddingLeft: "2.2em", paddingRight: "2.2em" }}>
          {billingAddress.map((g, i) => {
            if (g !== "deleted")
              return (
                <div key={i} style={{ marginBottom: ".5em" }}>
                  <ExpansionPanel
                    expanded={
                      ba.bi[i] && ba.bi[i].expand ? ba.bi[i].expand : false
                    }
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      onClick={(e) => handleExpand(i)}
                    >
                      {ba.bi[i] && !ba.bi[i].expand ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "95%",
                          }}
                        >
                          <Typography style={{ width: "25%" }}>
                            Service Id:{" "}
                            {ba.bi[i] && ba.bi[i].serviceID
                              ? serviceList.map((r) => {
                                if (ba.bi[i].serviceID === r.key)
                                  return r.value;
                              })
                              : ""}
                          </Typography>
                          <Typography style={{ width: "25%" }}>
                            PPI Number:{" "}
                            {ba.bi[i] && ba.bi[i].ppiNumber
                              ? ba.bi[i].ppiNumber
                              : ""}
                          </Typography>
                          <Typography style={{ width: "25%" }}>
                            Billing AC:{" "}
                            {ba.bi[i] && ba.bi[i].billingAccountNo
                              ? ba.bi[i].billingAccountNo
                              : ""}
                          </Typography>
                          <Typography style={{ width: "25%" }}>
                            Planner:{" "}
                            {ba.bi[i] && ba.bi[i].plannerID
                              ? plannerList.map((r) => {
                                if (ba.bi[i].plannerID === r.key)
                                  return r.name;
                              })
                              : ""}
                          </Typography>
                        </div>
                      ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "95%",
                            }}
                          >
                            <Typography style={{ width: "25%" }}>
                              Service Id:{" "}
                              {ba.bi[i] && ba.bi[i].serviceID
                                ? serviceList.map((r) => {
                                  if (ba.bi[i].serviceID === r.key)
                                    return r.value;
                                })
                                : ""}
                            </Typography>
                            <Typography style={{ width: "25%" }}>
                              PPI Number:{" "}
                              {checkService(
                                ba.bi[i] && ba.bi[i].serviceID,
                                serviceList
                              )
                                ? ""
                                : ba.bi[i] && ba.bi[i].ppiNumber
                                  ? ba.bi[i].ppiNumber
                                  : ""}
                            </Typography>
                            <Typography style={{ width: "25%" }}>
                              Billing AC:{" "}
                              {ba.bi[i] && ba.bi[i].billingAccountNo
                                ? ba.bi[i].billingAccountNo
                                : ""}
                            </Typography>
                            <Typography style={{ width: "25%" }}>
                              Planner:{" "}
                              {ba.bi[i] && ba.bi[i].plannerID
                                ? plannerList.map((r) => {
                                  if (ba.bi[i].plannerID === r.key)
                                    return r.name;
                                })
                                : ""}
                            </Typography>
                          </div>
                        )}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ display: "block" }}>
                      <div
                        style={{
                          fontSize: "18px",
                          marginTop: "-4px",
                          fontWeight: "500",
                        }}
                      >
                        PPI Info
                      </div>

                      <div className="container">
                        <div
                          style={{ marginTop: "-10px ", marginLeft: "-7px" }}
                        >
                          <TextField
                            required
                            id="standard-select-currency"
                            select
                            label="Service"
                            // className={classes.textField1}
                            style={{
                              width: "23%",
                              fontSize: "11px !important",
                              marginLeft: ".5em",
                              marginRight: ".5em",
                            }}
                            margin="normal"
                            value={
                              ba.bi[i] && ba.bi[i].serviceID
                                ? ba.bi[i].serviceID
                                : ""
                            }
                            InputProps={
                              ba.bi[i] &&
                                ba.bi[i].noneEditableField &&
                                ba.bi[i].serviceID
                                ? {
                                  readOnly: true,
                                }
                                : { readOnly: false }
                            }
                            variant={
                              ba.bi[i] &&
                                ba.bi[i].noneEditableField &&
                                ba.bi[i].serviceID
                                ? "filled"
                                : "standard"
                            }
                            // helperText={
                            //   ba.bi[i] &&
                            //   ba.bi[i].errors &&
                            //   ba.bi[i].errors.plannerID
                            //     ? "Please select a planner"
                            //     : ""
                            // }
                            onChange={(e) =>
                              handleBillingAdderss(
                                e,
                                "serviceID",
                                i,
                                maxlength10
                              )
                            }
                          >
                            {serviceList.map((option) => (
                              <MenuItem
                                key={option.key}
                                value={option.key}
                                name={option.value}
                              >
                                {option.value}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            required
                            autoComplete="off"
                            id="standard-required"
                            label="PPI Number"
                            // placeholder="PPI Number"
                            // className={classes.textField1}
                            style={{
                              width: "23%",
                              fontSize: "11px !important",
                              marginLeft: ".5em",
                              marginRight: ".5em",
                            }}
                            margin="normal"
                            inputProps={{
                              maxLength: 6,
                            }}
                            onKeyPress={_onKeyPressNoOnly}
                            // variant={
                            //   mode === "edit" &&
                            //   ba.bi[i] &&
                            //   ba.bi[i].ppiNumber
                            //     ? "filled"
                            //     : "standard"
                            // }
                            // InputProps={
                            //   mode === "edit" &&
                            //   ba.bi[i] &&
                            //   ba.bi[i].ppiNumber
                            //     ? {
                            //         readOnly: true
                            //       }
                            //     : { readOnly: false }
                            // }
                            value={
                              checkService(
                                ba.bi[i] && ba.bi[i].serviceID,
                                serviceList
                              )
                                ? ""
                                : ba.bi[i] && ba.bi[i].ppiNumber
                                  ? ba.bi[i].ppiNumber
                                  : ""
                            }
                            disabled={
                              checkService(
                                ba.bi[i] && ba.bi[i].serviceID,
                                serviceList
                              ) ||
                              (ba.bi[i] &&
                                !ba.bi[i].serviceID &&
                                ba.bi[i].ppiNumber) ||
                              (ba.bi[i] && ba.bi[i].noneEditableField)
                            }
                            // InputProps={
                            //   checkService(
                            //     ba.bi[i] && ba.bi[i].serviceID,
                            //     serviceList
                            //   ) ||
                            //   (ba.bi[i] && !ba.bi[i].serviceID)
                            //     ? {
                            //         readOnly: true
                            //       }
                            //     : { readOnly: false }
                            // }
                            variant={
                              ba.bi[i] && ba.bi[i].noneEditableField
                                ? //&&
                                // ba.bi[i].ppiNumber
                                "filled"
                                : "standard"
                            }
                            error={
                              ba.bi[i] &&
                              ba.bi[i].errors &&
                              ba.bi[i].errors.ppiNumber
                            }
                            helperText={
                              ba.bi[i] &&
                                ba.bi[i].errors &&
                                ba.bi[i].errors.ppiNumber
                                ? resourceValidation.uniquePPINo
                                : ""
                            }
                            onChange={(e) =>
                              handleBillingAdderss(
                                e,
                                "ppiNumber",
                                i,
                                maxlength6OnlyNo
                              )
                            }
                          />
                          <TextField
                            autoComplete="off"
                            required
                            id="standard-required"
                            label="Billing Account No"
                            // placeholder="Billing Account No"
                            // className={classes.textField1}
                            style={{
                              width: "23%",
                              fontSize: "11px !important",
                              marginLeft: ".5em",
                              marginRight: ".5em",
                            }}
                            onKeyPress={_onKeyPress}
                            // variant={mode === "edit" ? "filled" : "standard"}
                            margin="normal"
                            inputProps={{
                              maxLength: 8,
                            }}
                            // InputProps={
                            //   mode === "edit"
                            //     ? {
                            //         readOnly: true
                            //       }
                            //     : { readOnly: false }
                            // }
                            value={
                              ba.bi[i] && ba.bi[i].billingAccountNo
                                ? ba.bi[i].billingAccountNo
                                : ""
                            }
                            error={
                              ba.bi[i] &&
                              ba.bi[i].errors &&
                              ba.bi[i].errors.billingAccountNo
                            }
                            helperText={
                              ba.bi[i] &&
                                ba.bi[i].errors &&
                                ba.bi[i].errors.billingAccountNo
                                ? resourceValidation.billingACNo
                                : ""
                            }
                            onChange={(e) =>
                              handleBillingAdderss(
                                e,
                                "billingAccountNo",
                                i,
                                billingACNo
                              )
                            }
                          />
                          <TextField
                            required
                            id="standard-select-currency"
                            select
                            label="Planner"
                            // className={classes.textField1}
                            style={{
                              width: "23%",
                              fontSize: "11px !important",
                              marginLeft: ".5em",
                              marginRight: ".5em",
                            }}
                            margin="normal"
                            value={
                              ba.bi[i] && ba.bi[i].plannerID
                                ? ba.bi[i].plannerID
                                : ""
                            }
                            // helperText={
                            //   ba.bi[i] &&
                            //   ba.bi[i].errors &&
                            //   ba.bi[i].errors.plannerID
                            //     ? "Please select a planner"
                            //     : ""
                            // }
                            onChange={(e) =>
                              handleBillingAdderss(
                                e,
                                "plannerID",
                                i,
                                maxlength10
                              )
                            }
                          >
                            {plannerList.map((option) => (
                              <MenuItem
                                key={option.key}
                                value={option.key}
                                name={option.name}
                              >
                                {option.value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "23px",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Lettershop Info
                      </div>

                      <LetterModal
                        handleBillingAdderss={handleBillingAdderss}
                        clearLetterShopInfo={clearLetterShopInfo}
                        i={i}
                        letterShopList={letterShopList}
                        selLSArr={ba && ba.bi[i] && ba.bi[i].selLSArray}
                      />
                      <div
                        style={{
                          // borderBottom: "1px solid #949191",
                          fontSize: "17px",
                          fontFamily: "Roboto",
                          margin: "1em",
                          marginLeft: "0px",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {ba.bi[i] &&
                          ba.bi[i].letterShopInfo &&
                          ba.bi[i].letterShopInfo.map((s, j) => {
                            return (
                              <div
                                key={j}
                                style={{
                                  marginRight: "4px",
                                  backgroundColor: "#3982ea",
                                  height: "23px",
                                  padding: "3px",
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  marginTop: "-6px",
                                  borderRadius: "4px",
                                  color: "#fff",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  marginBottom: "12px",
                                }}
                              >
                                <span>{s.value}</span>
                                <span
                                  style={{
                                    marginLeft: ".5em",
                                    marginTop: ".3em",
                                  }}
                                >
                                  <HighlightOffIcon
                                    // fontSize="small"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => modifyLetterShopInfo(i, j)}
                                  />
                                </span>
                              </div>
                            );
                          })}
                      </div>
                      <div
                        style={{
                          marginTop: "8px",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Cost Center Info
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginTop: "1em",
                          // marginBottom: "1em"
                        }}
                      >
                        <div
                          style={{
                            // borderBottom: "1px solid #949191",
                            fontSize: "17px",
                            fontFamily: "Roboto",
                            marginTop: "4px",
                            display: "flex",
                            marginBottom: ".5em",
                            flexWrap: "wrap",
                          }}
                        >
                          {ba.bi[i] &&
                            ba.bi[i].costCenterInfo &&
                            ba.bi[i].costCenterInfo.map((s, j) => {
                              //  console.log(i);
                              return (
                                <div
                                  key={j}
                                  style={{
                                    marginRight: "4px",
                                    border: "1px solid #eaeaea",
                                    height: "23px",
                                    padding: "3px",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    marginTop: "-3px",
                                    borderRadius: "4px",
                                    marginBottom: "8px",
                                    //color: "#fff",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>{s}</span>
                                  <span
                                    style={{
                                      marginLeft: ".5em",
                                      marginTop: ".3em",
                                    }}
                                  >
                                    <CancelIcon
                                      fontSize="small"
                                      // color="primary"
                                      style={{
                                        cursor: "pointer",
                                        color: "black",
                                      }}
                                      onClick={(e) =>
                                        modifyCostCenterInfo(i, j)
                                      }
                                    />
                                  </span>
                                </div>
                              );
                            })}
                          <TextField
                            placeholder="Enter cost center code"
                            // value={
                            //   ba.bi && ba.bi[i] ? ba.bi[i].costCenter : cci
                            // }
                            value={cciArr[i]}
                            inputProps={{
                              maxLength: 40,
                            }}
                            // inputProps={{
                            //   minWidth: "200px"
                            // }}
                            onChange={(e) => handleCCI(e, i)}
                            style={{ minWidth: "200px" }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    !inValidCCi &&
                                    handleBillingAdderss(e, "costCenterInfo", i,)
                                  }
                                >
                                  {/* <IconButton> */}
                                  {
                                    !inValidCCi &&
                                    <AddIcon />}
                                  {/* </IconButton> */}
                                </InputAdornment>
                              ),
                            }}
                          // style={{ width: "100%" }}
                          />
                        </div>
                      </div>

                      <Divider />
                      <div
                        style={{
                          display: "flex",
                          padding: "1em",
                          justifyContent: "flex-end",
                          paddingBottom: "0px",
                        }}
                      >
                        {i !== 0 && (
                          <Button
                            variant="contained"
                            color="primary"
                            color="primary"
                            style={{ marginLeft: "1em" }}
                            onClick={(e) => decreaseBillingAdderss(i, ba.bi[i])}
                          >
                            Delete
                          </Button>
                        )}
                        <Button
                          //variant="contained"handleBIReset
                          onClick={(e) => handleBIReset(i)}
                          variant="contained"
                          color="primary"
                          style={{ marginLeft: "1em", boxShadow: "none" }}
                        >
                          Reset
                        </Button>
                        {checkValidPPIInfo(i) ? (
                          <Button
                            disabled
                            variant="contained"
                            color="primary"
                            style={{
                              marginLeft: "1em",
                              marginRight: "-1em",
                              boxShadow: "none",
                            }}
                            onClick={(e) => handleExpand(i, true)}
                          >
                            Save
                          </Button>
                        ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                marginLeft: "1em",
                                marginRight: "-1em",
                                boxShadow: "none",
                              }}
                              onClick={(e) => handleExpand(i, true)}
                            >
                              Save
                            </Button>
                          )}
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              );
          })}

          <Paper style={{ marginTop: "1em" }}>
            <div
              style={{
                // padding: "1em",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ paddingLeft: "1.5em" }}>
                Add more billing
              </Typography>
              <div>
                <IconButton
                  aria-label="download"
                  // className={classes.iconHover}
                  onClick={increaseBillingAdderss}
                // onClick={e => props.history.push("/add_customer")}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </div>
          </Paper>
        </div>
      );

    default:
      return "Unknown stepIndex";
  }
}

class AddCustomer extends React.Component {
  static contextType = StateContext;
  state = {
    mode: "add",
    activeStep: 0,
    billingAddress: [{}],
    ba: {
      countryID: "",
      bi: [{}],
      errors: {
        customerCode: false,
        customerName: false,
        addressLine1: false,
        addressLine2: false,
        addressLine3: false,
        postalCode: false,
        countryID: false,
        contactPersonName: false,
        phoneNumber: false,
        emailID: false,
      },
    },
    cci: "",
    active: false,
    countryList: [],
    letterShopList: [],
    plannerList: [],
    customerList: [],
    ppiInfo: [],
    ccInfo: [],
    cciArr: [],
    serviceList: [],
    inValidCCi: false,
    loading: false,
  };
  componentDidMount() {
    this.props.setSelectedList("masterDataOpen", "customer");
    const [{ store }, dispatch] = this.context;
    let customerId = "";
    if (process.browser) customerId = window.location.pathname.split("/")[2];
    //console.log(cousomerId);
    if (customerId) {
      this.setState({ mode: "edit", loading: true });
      let newBA = { ...this.state.ba };
      let newBillingAddress = [...this.state.billingAddress];
      getCustomer(customerId).then((r) => {
        //console.log(r.data);
        newBA.customerCode = r.data.customerCode;
        newBA.customerName = r.data.customerName;
        newBA.addressLine1 = r.data.addressLine1;
        newBA.addressLine2 = r.data.addressLine2;
        newBA.addressLine3 = r.data.addressLine3;
        newBA.postalCode = r.data.postalCode;
        newBA.countryID = r.data.countryID;
        newBA.isVendor = r.data.isVendor;
        newBA.isActive = r.data.isActive;
        newBA.contactPersonName = r.data.contactPersonName;
        newBA.phoneNumber = r.data.phoneNumber;
        newBA.emailID = r.data.emailID;
        newBA.customerId = r.data.customerID;
        r.data &&
          r.data.customerPPIList &&
          r.data.customerPPIList.length > 0 &&
          r.data.customerPPIList.map((p, i) => {
            if (!newBA.bi[i]) newBA.bi[i] = {};
            if (!newBA.bi[i].errors) newBA.bi[i].errors = {};
            if (i !== 0) newBillingAddress.push({});
            newBA.bi[i].noneEditableField = true;
            newBA.bi[i].billingAccountNo = p.billingAccountNo;
            newBA.bi[i].isActive = p.isActive;
            newBA.bi[i].originalBillingAccountNo = p.billingAccountNo;
            newBA.bi[i].errors.billingAccountNo = false;
            newBA.bi[i].ppiNumber = p.ppiNumber;
            newBA.bi[i].originalPpiNumber = p.ppiNumber;
            newBA.bi[i].errors.ppiNumber = false;
            newBA.bi[i].plannerID = p.plannerID;
            newBA.bi[i].errors.plannerID = false;
            newBA.bi[i].serviceID = p.serviceID;
            newBA.bi[i].errors.serviceID = false;
            newBA.bi[i].customerPPIID = p.customerPPIID;
            newBA.bi[i].letterShopInfo = [];
            newBA.bi[i].originalLetterShopInfo = [];
            newBA.bi[i].costCenterInfo = [];
            newBA.bi[i].originalLetterShopInfo = [];
            newBA.bi[i].costCenterIds = [];
            newBA.bi[i].selLSArray = [];
            r.data &&
              r.data.ppiLetterShopList &&
              r.data.ppiLetterShopList.length > 0 &&
              r.data.ppiLetterShopList.map((l) => {
                if (p.customerPPIID === l.customerPPIID) {
                  newBA.bi[i].letterShopInfo.push({
                    key: l.letterShopID,
                    value: l.letterShopCode,
                    ppiLetterShopID: l.ppiLetterShopID,
                  });
                  newBA.bi[i].originalLetterShopInfo.push({
                    key: l.letterShopID,
                    value: l.letterShopCode,
                    ppiLetterShopID: l.ppiLetterShopID,
                  });
                  newBA.bi[i].selLSArray.push({
                    key: l.letterShopID,
                    value: l.letterShopCode,
                  });
                }
              });
            r.data &&
              r.data.ppiCostCenterList &&
              r.data.ppiCostCenterList.length > 0 &&
              r.data.ppiCostCenterList.map((c) => {
                if (p.customerPPIID === c.customerPPIID)
                  newBA.bi[i].costCenterInfo.push(c.costCenterName);
                // console.log(c);
                newBA.bi[i].costCenterIds.push({
                  key: c.customerPPIID,
                  ppiCostCenterID: c.ppiCostCenterID,
                  name: c.costCenterName,
                });
              });
          });

        this.setState({
          ba: newBA,
          loading: false,
          billingAddress: newBillingAddress,
        });
        if (newBA.isActive) this.setState({ active: newBA.isActive });
      });
    }
    getletterShopDropDown().then((r) => {
      this.getAllLetterShops(r.data);
    });
    // if (!customerId)
    // getUserList().then((r) => {
    //   dispatch({
    //     type: "getUserList",
    //     newStore: r.data,
    //   });
    //   // this.getAllPlanners(r.data);
    // });
    getPlannerList().then((r) => {
      dispatch({
        type: "getUserList",
        newStore: r.data,
      });
      this.getAllPlanners(r.data);
    });
    getCountryDropdownList().then((r) => {
      // console.log(r);
      if (r.data) {
        var IDs = r.data.filter((r) => {
          return r.value.toUpperCase() === "SG";
        });
      }

      IDs = IDs[0].id;
      let newBa = { ...this.state.ba, countryID: IDs };

      this.setState({ ba: newBa });
      dispatch({
        type: "getCountryDDList",
        newStore: r.data,
      });
      this.getAllCountrys(r.data);
    });
    getCustomerList().then((r) => {
      dispatch({
        type: "getCustomerList",
        newStore: r.data,
      });
      this.getAllCustomers(store.customerList);
    });
    getCCIList().then((r) => {
      dispatch({
        type: "getCCIList",
        newStore: r.data,
      });
      this.getAllCCIs(store.cciList);
    });
    getPPIList().then((r) => {
      this.getAllPPIs(r.data);
    });
    getServiceList().then((r) => {
      //console.log(r);
      dispatch({
        type: "getServiceLists",
        newStore: r.data,
      });
      this.getSvList(store.serviceList);
    });
  }
  getSvList = (list) => {
    let serviceList = [];
    //console.log(list);
    list &&
      list.length > 0 &&
      list.map((c) => {
        return serviceList.push({ key: c.id, value: c.value });
      });
    this.setState({ serviceList });
  };
  getAllLetterShops = (list) => {
    let letterShopList = [];
    //console.log(list);
    list &&
      list.length > 0 &&
      list.map((c) => {
        return letterShopList.push({
          key: c.id,
          value: c.value,
        });
      });
    this.setState({ letterShopList });
  };
  getAllPlanners = (list) => {
    let plannerList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        // if (c.isActive)
        plannerList.push({ key: c.id, value: c.text, name: c.text });
      });
    this.setState({ plannerList });
    //this.sortDDList(plannerList, "plannerList");
  };
  // getAllPlanners = list => {
  //   let plannerList = [];
  //   list &&
  //     list.length > 0 &&
  //     list.map(c => {
  //       if (c.isActive)
  //         plannerList.push({ key: c.userId, value: c.fName, name: c.fName });
  //     });
  //   this.setState({ plannerList });
  // };
  getAllCountrys = (list) => {
    // console.log(list);
    let countryList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        countryList.push({
          key: c.id,
          value: c.text.toUpperCase(),
        });
      });
    // console.log(countryList);
    this.sortCountry(countryList);
  };
  sortCountry = (list) => {
    let z = list.sort(function (a, b) {
      var x = a.value.toLowerCase();
      var y = b.value.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ countryList: z });
  };
  getAllCCIs = (list) => {
    let ccInfo = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        return ccInfo.push({
          ccID: c.id,
          ccName: c.text,
        });
      });
    this.setState({ ccInfo });
  };
  getAllPPIs = (list) => {
    let ppiInfo = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        return ppiInfo.push({
          ppiNum: c.text,
          billingAC: c.value,
          id: c.id,
        });
      });
    this.setState({ ppiInfo });
  };
  getAllCustomers = (list) => {
    let customerList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        if (c.isActive)
          customerList.push({ key: c.customerCode, value: c.customerName });
      });
    this.setState({ customerList });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    // to update
    if (
      store.userList &&
      store.userList.length > 0 &&
      this.state.plannerList.length !== store.userList.length
    ) {
      let modifiedList = [];
      store.userList.map((c) => {
        if (c.isActive)
          modifiedList.push({ key: c.userId, value: c.fName, name: c.fName });
      });
      if (this.state.plannerList.length !== modifiedList.length)
        this.getAllPlanners(store.userList);
    }
    if (
      store.countryDDL &&
      store.countryDDL.length > 0 &&
      this.state.countryList.length !== store.countryDDL.length
    ) {
      let modifiedList = [];
      store.countryDDL.map((c) => {
        modifiedList.push({ key: c.id, value: c.text });
      });
      if (this.state.countryList.length !== modifiedList.length)
        this.getAllCountrys(store.countryDDL);
    }
    // if (
    //   store.letterShopList &&
    //   store.letterShopList.length > 0 &&
    //   this.state.letterShopList.length !== store.letterShopList.length
    // ) {
    //   this.getAllLetterShops(store.letterShopList);
    // }
    if (
      store.serviceList &&
      store.serviceList.length > 0 &&
      this.state.serviceList.length !== store.serviceList.length
    ) {
      this.getSvList(store.serviceList);
    }
    if (
      store.customerList &&
      store.customerList.length > 0 &&
      this.state.customerList.length !== store.customerList.length
    ) {
      let modifiedList = [];
      store.customerList.map((c) => {
        if (c.isActive)
          modifiedList.push({ key: c.customerCode, value: c.customerName });
      });
      if (this.state.customerList.length !== modifiedList.length)
        this.getAllCustomers(store.customerList);
    }
    // if (
    //   store.ppiList &&
    //   store.ppiList.length > 0 &&
    //   this.state.ppiInfo.length !== store.ppiList.length
    // ) {
    //   this.getAllPPIs(store.ppiList);
    // }
    if (
      store.cciList &&
      store.cciList.length > 0 &&
      this.state.ccInfo.length !== store.cciList.length
    ) {
      this.getAllCCIs(store.cciList);
    }
    return true;
  }
  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onKeyPressNoOnly(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onKeyPressPhnNoOnly(event) {
    const re = /[0-9+]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  checkIsValidCustomerInfo = () => {
    if (
      //   this.state.ba.errors.customerCode ||
      //   this.state.ba.errors.customerName ||
      //   this.state.ba.errors.addressLine1 ||
      //  // this.state.ba.errors.addressLine2 ||
      //  // this.state.ba.errors.addressLine3 ||
      //  // this.state.ba.errors.postalCode ||
      //   this.state.ba.errors.countryID ||
      // this.state.ba.errors.contactPersonName ||
      //  this.state.ba.errors.phoneNumber ||
      // this.state.ba.errors.emailID ||
      !this.state.ba.customerCode ||
      !this.state.ba.customerName ||
      !this.state.ba.addressLine1 ||
      !this.state.ba.countryID
    )
      return true;
    else return false;
  };
  checkValidPPIInfo = (i) => {
    let newBA = { ...this.state.ba };
    let valid = false;
    let sv = checkService(
      newBA.bi[i] && newBA.bi[i].serviceID,
      this.state.serviceList
    );
    if (sv) {
      newBA.bi[i].errors.ppiNumber = false;
    }
    if (newBA.bi[i] && newBA.bi[i].errors) {
      // console.log(newBA.bi[i].errors.ppiNumber, newBA.bi[i].ppiNumber);
      if (
        (newBA.bi[i].ppiNumber && newBA.bi[i].errors.ppiNumber) ||
        (newBA.bi[i].billingAccountNo && newBA.bi[i].errors.billingAccountNo) ||
        // newBA.bi[i].errors.plannerID ||
        (!sv && !newBA.bi[i].ppiNumber) ||
        !newBA.bi[i].billingAccountNo ||
        !newBA.bi[i].plannerID ||
        !newBA.bi[i].serviceID
      )
        valid = true;
      else valid = false;
    } else valid = true;
    return valid;
  };

  checkGoodToGo = () => {
    let newBA = { ...this.state.ba };
    let validPPI = [];
    let result = false;
    if (newBA.isVendor) return false;
    if (!newBA.bi[0].serviceID) return true;

    newBA.bi.map((d, i) => {
      if (d.serviceID) {
        if (!newBA.bi[i].serviceID) return true;

        validPPI.push(this.checkValidPPIInfo(i));
      }
    });
    if (this.state.activeStep !== 0) {
      validPPI.map((v) => {
        if (v) result = true;
      });
    }

    return result;
  };

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleSubmit = () => {
    const dispatch = this.context[1];
    let newBA = { ...this.state.ba };

    newBA.bi.map((s, i) => {
      if (checkService(s.serviceID, this.state.serviceList))
        newBA.bi[i].ppiNumber = "";
    });

    this.setState({ ba: newBA });
    let customerRequestBody = {};
    customerRequestBody.customerCode = this.state.ba.customerCode ? this.state.ba.customerCode.toUpperCase() : "";
    customerRequestBody.customerName = this.state.ba.customerName;
    customerRequestBody.addressLine1 = this.state.ba.addressLine1;
    customerRequestBody.addressLine2 = this.state.ba.addressLine2;
    customerRequestBody.addressLine3 = this.state.ba.addressLine3;

    customerRequestBody.postalCode = this.state.ba.postalCode;
    customerRequestBody.countryID = this.state.ba.countryID;
    customerRequestBody.contactPersonName = this.state.ba.contactPersonName;
    customerRequestBody.phoneNumber = this.state.ba.phoneNumber;
    customerRequestBody.emailID = this.state.ba.emailID;
    customerRequestBody.isVendor = this.state.ba.isVendor;
    customerRequestBody.isActive = this.state.ba.isActive;
    // Add Customer post call
    //console.log(this.state.ba);
    if (this.state.mode === "add")
      postCustomer(
        this.props.history,
        customerRequestBody,
        this.state.ba.bi
      ).then((r) => {
        getCustomerList().then((cl) => {
          // to get the new customer list
          dispatch({
            type: "getCustomerList",
            newStore: cl.data,
          });
        });
      });
    else
      putCustomer(
        this.state.ba.customerId,
        this.props.history,
        customerRequestBody,
        this.state.ba.bi
      ).then((r) => {
        getCustomerList().then((cl) => {
          // to get the new customer list
          dispatch({
            type: "getCustomerList",
            newStore: cl.data,
          });
        });

        // toastr.success("Customer Created Successfully");
      });
  };
  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };
  handleReset = () => {
    this.setState({ activeStep: 0 });
  };
  increaseBillingAdderss = () => {
    let newBillingAddress = [...this.state.billingAddress];
    newBillingAddress.push({});
    this.setState({ billingAddress: newBillingAddress });
  };
  decreaseBillingAdderss = (i, data) => {
    let newBillingAddress = [...this.state.billingAddress];
    newBillingAddress[i] = "deleted";
    let newBA = { ...this.state.ba };
    //newBA.bi.splice(i, 1);
    //newBA.bi[i] = {};
    newBA.bi[i].isActive = false;
    newBA.bi[i].ppiDeleted = true;
    //  console.log(this.state.ppiInfo);
    // console.log(data.ppiNumber)
    this.state.ppiInfo.map((s) => {
      if (s.ppiNum == data.ppiNumber) {
        // console.log(s.ppiNum==data.ppiNumber)
        deletePPI(s.id).then((r) => {
          getPPIList().then((r) => {
            this.getAllPPIs(r.data);
          });
        });
      }
    });

    //console.log(newBA.bi);
    this.setState({ billingAddress: newBillingAddress, ba: newBA });
  };
  handleBIReset = (i) => {
    let newBA = { ...this.state.ba };
    newBA.bi[i].ppiNumber = "";
    newBA.bi[i].billingAccountNo = "";
    newBA.bi[i].plannerID = "";
    newBA.bi[i].serviceID = "";
    newBA.bi[i].costCenterInfo = [];
    newBA.bi[i].letterShopInfo = [];
    this.setState({ ba: newBA });
  };
  handleCustomerInfo = (e, name, validate) => {
    //console.log(e.target.value);
    let uniqueCustomerCode = false;
    let newBA = { ...this.state.ba };
    let result = "";
    if (name === "customerCode" && e.target.value) {
      this.state.customerList.map((c) => {
        if (c.key === e.target.value) {
          toastr.error("Customer code already exist");
          uniqueCustomerCode = true;
        }
      });
      result = validate(e.target.value);
      newBA.errors.customerCode = uniqueCustomerCode || !result;
    }
    if (name === "countryID") result = validate(e.target.checked);
    else {
      result = validate(e.target.value);
      newBA.errors[name] = !result;
    }
    newBA[name] = e.target.value;
    this.setState({ ba: newBA });
  };
  handleChangeVendor = (name) => (event) => {
    let newBA = { ...this.state.ba };
    newBA[name] = event.target.checked;
    this.setState({ ba: newBA });
  };
  handleBillingAdderss = (e, name, i, validate,) => {
    //console.log(e, name, i, validate);
    let newBA = { ...this.state.ba };

    let result = "";
    // if (name === "plannerID") newBA.bi[i].errors.plannerID = true;
    if (
      name !== "letterShopInfo" &&
      name !== "costCenterInfo" &&
      name !== "billingAccountNo" &&
      name !== "ppiNumber"
    )
      result = validate(e.target.value);
    if (!newBA.bi[i]) {
      newBA.bi[i] = {};
      if (!newBA.bi[i].errors) {
        newBA.bi[i].errors = {};
        newBA.bi[i].errors[name] = !result;
      }
    } else {
      if (!newBA.bi[i].errors) {
        newBA.bi[i].errors = {};
        newBA.bi[i].errors[name] = !result;
      } else newBA.bi[i].errors[name] = !result;
    }

    if (name === "letterShopInfo") {
      if (!newBA.bi[i]) newBA.bi[i] = {};
      newBA.bi[i][name] = e;
      newBA.bi[i].selLSArray = e;
    } else if (name === "costCenterInfo") {
      if (!newBA.bi[i]) newBA.bi[i] = {};
      if (!newBA.bi[i].costCenterInfo) newBA.bi[i].costCenterInfo = [];
      // if (this.state.cci) {
      //   let newCCIArr = [...this.state.cciArr];
      //   newBA.bi[i].costCenterInfo.push(this.state.cci);
      //   this.setState({ cci: "" }, () => {
      //     newCCIArr[i] = this.state.cci;
      //     this.setState({ cciArr: newCCIArr });
      //   });
      // }
      if (this.state.cci) {
        let newCCIArr = [...this.state.cciArr];
        let checkResult = ""
        if (this.state.cci) {
          let savedData = this.state.cci
          checkValidCC(this.state.cci, newBA.bi[i].customerPPIID || 0).then(res => {
            if (res && res.data) {
              newBA.bi[i].costCenterInfo.push(savedData);
              this.setState({ cci: "" }, () => {
                newCCIArr[i] = this.state.cci;
                this.setState({ cciArr: newCCIArr, ba: newBA });
              });
            }
            else {
              toastr.error("Cost center name already exist!")
            }
          })
        }


      }

    } else {
      if (!newBA.bi[i]) newBA.bi[i] = {};
      newBA.bi[i][name] = e.target.value;
    }
    this.setState({ ba: newBA, cci: "" });
    if (name === "billingAccountNo") {
      if (newBA.bi[i].billingAccountNo && e.target.value) {
        let checkVal = false;
        result = !validate(e.target.value);
        // console.log(result);
        newBA.bi.map((b, j) => {
          if (!newBA.bi[j].errors) newBA.bi[j].errors = {};
          // newBA.bi[j].errors.billingAccountNo = false;
          // if (i !== j && b.billingAccountNo === e.target.value) {
          //   checkVal = true;
          // }
        });

        this.state.ppiInfo.map((b, j) => {
          if (this.state.mode === "edit") {
            if (b.billingAC === e.target.value) {
              let found = true;
              newBA.bi.map((m) => {
                if (e.target.value === m.originalBillingAccountNo)
                  found = false;
              });
              if (found) checkVal = true;
            }
          } else if (this.state.mode === "add")
            if (b.billingAC === e.target.value) checkVal = true;
        });
        // if (!newBA.bi[i].billingAccountNo) checkVal = true;
        newBA.bi[i].errors.billingAccountNo = checkVal || result;
        if (checkVal) {
          newBA.bi[i].billingAccountNo = null;
          newBA.bi[i].errors.billingAccountNo = !checkVal;
          toastr.error("Billing AC No already exist");
        }
      }
    }
    if (name === "ppiNumber") {
      if (newBA.bi[i].ppiNumber && e.target.value) {
        let checkValPPI = false;
        result = !validate(e.target.value);
        //  console.log(result);
        // console.log(newBA.bi);
        newBA.bi.map((b, j) => {
          if (!newBA.bi[j].errors) newBA.bi[j].errors = {};
          // newBA.bi[j].errors.ppiNumber = false;
          if (i !== j && b.ppiNumber === e.target.value) {
            checkValPPI = true;
            if (b.originalPpiNumber && !b.isActive) checkValPPI = false;
          }
          // else checkValPPI = false;
        });
        this.state.ppiInfo.map((p, j) => {
          //  console.log(p)
          if (this.state.mode === "edit") {
            // console.log(newBA.bi[i].billingAccountNo, e.target.value);
            if (newBA.bi[i].originalPpiNumber !== e.target.value) {
              if (p.ppiNum === e.target.value) checkValPPI = true;
              // else checkValPPI = false;
            }
          } else if (this.state.mode === "add")
            if (p.ppiNum === e.target.value) checkValPPI = true;
          // else checkValPPI = false;
        });
        // if (!newBA.bi[i].ppiNumber) checkValPPI = true;

        newBA.bi[i].errors.ppiNumber = checkValPPI || result;
        if (checkValPPI) {
          newBA.bi[i].ppiNumber = null;
          newBA.bi[i].errors.ppiNumber = !checkValPPI;
          toastr.error("PPI Number already exist");
        }
      }
      this.setState({ ba: newBA, cci: "" });
    }
  };
  clearLetterShopInfo = (i) => {
    let newBA = { ...this.state.ba };
    newBA.bi[i].letterShopInfo = [];
    this.setState({ ba: newBA });
  };
  modifyLetterShopInfo = (i, j) => {
    let newBA = { ...this.state.ba };
    newBA.bi[i].letterShopInfo.splice(j, 1);
    this.setState({ ba: newBA });
  };
  modifyCostCenterInfo = (i, j) => {
    let newBA = { ...this.state.ba };
    newBA.bi[i].costCenterInfo.splice(j, 1);
    this.setState({ ba: newBA });
  };
  handleCCI = (e, i) => {
    let newBA = { ...this.state.ba };
    let newCCIArr = [...this.state.cciArr];
    newCCIArr[i] = e.target.value;
    this.setState({ inValidCCi: false });
    let checkVal = false;
    e.target.value && newBA && newBA.bi && newBA.bi.length > 0 && newBA.bi[i].costCenterInfo && newBA.bi[i].costCenterInfo.length > 0 && newBA.bi[i].costCenterInfo.map(xy => {
      if (xy.toLowerCase() === e.target.value.toLowerCase()) {
        toastr.error("Cost center name already exist");
        this.setState({ inValidCCi: true })
      }
    })
    // this.state.ccInfo.map(c => {
    //   if (c.ccName === e.target.value) checkVal = true;
    // });
    //if (this.state.inValidCCi)
    //if (c === e.target.value) checkVal = true;

    // console.log(this.state.ccInfo);
    // this.state.ccInfo.map((b, j) => {
    //   if (this.state.mode === "edit") {
    //     if (b.billingAC === e.target.value) {
    //       let found = true;
    //       newBA.bi.map(m => {
    //         if (e.target.value === m.originalBillingAccountNo)
    //           found = false;
    //       });
    //       if (found) checkVal = true;
    //     }
    //   } else if (this.state.mode === "add")
    //     if (b.billingAC === e.target.value) checkVal = true;
    // });
    // newBA.bi &&
    //   newBA.bi.map((c) => {
    //     // console.log(c.costCenterIds);
    //     c.costCenterInfo &&
    //       c.costCenterInfo.map((cc) => {
    //         //console.log(cc);
    //         if (this.state.mode === "edit") {
    //           if (cc === e.target.value) {
    //             let found = true;
    //             // c.costCenterIds.map(m => {
    //             //   if (e.target.value === m.name) found = true;
    //             // });
    //             if (found) checkVal = true;
    //           }
    //         } else if (this.state.mode === "add")
    //           if (cc === e.target.value) checkVal = true;

    //         // if (this.state.mode === "edit") {
    //         //   c.originalCostCenterInfo.map(cn => {

    //         //     if (cn !== e.target.value) {
    //         //       if (cc === e.target.value) checkVal = true;
    //         //     }
    //         //   });
    //         // } else
    //         //console.log(cc);
    //         // if (cc === e.target.value) checkVal = true;
    //       });
    //   });

    // if (checkVal) {
    //   e.target.value && toastr.error("Cost center name already exist");
    // }
    this.setState({
      // inValidCCi: checkVal,
      cci: e.target.value,
      cciArr: newCCIArr,
    });
  };
  handleExpand = (i, needToCkeck) => {
    //console.log(i);
    let newBA = { ...this.state.ba };
    if (needToCkeck) {
      // if (
      //   (newBA.bi[i] && newBA.bi[i].expand && !newBA.bi[i].letterShopInfo) ||
      //   (newBA.bi[i] &&
      //     newBA.bi[i].letterShopInfo &&
      //     newBA.bi[i].letterShopInfo.length) === 0
      // ) {
      //   toastr.error("Letter shop info is required");
      //   return;
      // }
      // if (
      //   (newBA.bi[i] && newBA.bi[i].expand && !newBA.bi[i].costCenterInfo) ||
      //   (newBA.bi[i] &&
      //     newBA.bi[i].costCenterInfo &&
      //     newBA.bi[i].costCenterInfo.length) === 0
      // ) {
      //   toastr.error("Cost center info is required");
      //   return;
      // }
    }
    if (!newBA.bi[i]) {
      newBA.bi[i] = {};
      newBA.bi[i].expand = false;
    }
    newBA.bi[i].expand = !newBA.bi[i].expand;
    this.setState({ ba: newBA });
  };
  render() {
    const steps = getSteps();
    // console.log(this.state.ba);
    let {
      activeStep,
      billingAddress,
      cci,
      countryList,
      letterShopList,
      mode,
    } = this.state;
    // console.log(this.state.ba);
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">
            {mode === "edit" ? "Update Customer" : "Create New Customer"}
          </div>
          <Divider />
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {!this.state.loading ? (
            <div>
              {activeStep === steps.length ? (
                <div style={{ padding: "1.2em", marginLeft: "4px" }}>
                  <Typography>All steps completed</Typography>
                </div>
              ) : (
                  <div>
                    <span>
                      {getStepContent(
                        activeStep,
                        // classes,
                        this.increaseBillingAdderss,
                        this.decreaseBillingAdderss,
                        billingAddress,
                        this.state.ba,
                        this.handleCustomerInfo,
                        this.handleChangeVendor,
                        this.handleBillingAdderss,
                        this.modifyLetterShopInfo,
                        cci,
                        this.handleCCI,
                        this.modifyCostCenterInfo,
                        this.handleBIReset,
                        this.handleExpand,
                        this.clearLetterShopInfo,
                        countryList,
                        letterShopList,
                        this.checkValidPPIInfo,
                        this.state.plannerList,
                        this.state.inValidCCi,
                        mode,
                        this.state.active,
                        this._onKeyPress,
                        this._onKeyPressNoOnly,
                        this.state.serviceList,
                        this.state.cciArr,
                        this._onKeyPressPhnNoOnly
                      )}
                    </span>
                    <div className="button-wrapper" style={{ marginLeft: "2em" }}>
                      {activeStep === 0 ? (
                        <Button
                          onClick={(e) => this.props.history.push("/customer")}
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                        >
                          CANCEL
                        </Button>
                      ) : activeStep === 0 ? (
                        <Button
                          onClick={this.handleBack}
                          disabled
                          //autoFocus
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                        >
                          Back
                        </Button>
                      ) : (
                            <Button
                              onClick={this.handleBack}
                              variant="contained"
                              color="primary"
                              //disableElevation
                              style={{ boxShadow: "none" }}
                            >
                              Back
                            </Button>
                          )}
                      {activeStep !== 0 ? (
                        this.checkGoodToGo() ? (
                          <Button
                            disabled
                            //autoFocus
                            variant="contained"
                            color="primary"
                            onClick={
                              activeStep === 0
                                ? this.handleNext
                                : this.handleSubmit
                            }
                            style={{ marginLeft: "1em", boxShadow: "none" }}
                          >
                            {activeStep === steps.length - 1
                              ? this.state.mode === "add"
                                ? "Finish"
                                : "Update"
                              : "Next"}
                          </Button>
                        ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              //disableElevation

                              onClick={
                                activeStep === 0
                                  ? this.handleNext
                                  : this.handleSubmit
                              }
                              style={{ marginLeft: "1em", boxShadow: "none" }}
                            >
                              {activeStep === steps.length - 1
                                ? this.state.mode === "add"
                                  ? "Finish"
                                  : "Update"
                                : "Next"}
                            </Button>
                          )
                      ) : this.checkIsValidCustomerInfo() ? (
                        <Button
                          disabled
                          //autoFocus
                          variant="contained"
                          color="primary"
                          onClick={
                            activeStep === 0 ? this.handleNext : this.handleSubmit
                          }
                          style={{ marginLeft: "1em", boxShadow: "none" }}
                        >
                          {activeStep === steps.length - 1
                            ? this.state.mode === "add"
                              ? "Finish"
                              : "Update"
                            : "Next"}
                        </Button>
                      ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              //disableElevation

                              onClick={
                                activeStep === 0 ? this.handleNext : this.handleSubmit
                              }
                              style={{ marginLeft: "1em", boxShadow: "none" }}
                            >
                              {activeStep === steps.length - 1
                                ? this.state.mode === "add"
                                  ? "Finish"
                                  : "Update"
                                : "Next"}
                            </Button>
                          )}
                    </div>
                  </div>
                )}
            </div>
          ) : (
              <div>
                <LinearProgress />
              </div>
            )}
        </Paper>
      </React.Fragment>
    );
  }
}

export default withRouter(AddCustomer);
