import React from "react";
import {
  TextField,
  Button,
  FormControlLabel,
  Typography,
  Checkbox,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { StateContext } from "../../../shared/globalState";
import { getAllList } from "../../app/poupSearch/action";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Autocomplete } from "@material-ui/lab";

const styles = (theme) => ({
  textField: {
    marginRight: theme.spacing(1),
    width: "29%",
    fontSize: "11px !important",
  },
  search: {
    position: "relative",
    minWidth: "500px",
    display: "flex",
  },
  tableContentCell: {
    borderRight: "1px solid #eaeaea",
    borderLeft: "1px solid #eaeaea",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Roboto",
    fontSize: "14px !important",
    padding: "0.5em !important",
  },
  tabHeader: {
    display: "flex",
    background: "#fafafa",
  },
  tabBody: {
    maxHeight: "250px",
    overflowY: "auto",
  },
  tabHeaderField: {
    border: "1px solid #eaeaea",
    padding: "10px",
    color: "rgb(48, 109, 202)",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "14px",
    fontWeight: 500,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  tabBodyField: {
    border: "1px solid #eaeaea",
    padding: "10px",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "14px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  inputRadio: {
    position: "relative",
    width: "16px",
    height: "16px",
    opacity: 1,
    marginLeft: "6px",
  },
});

function createData(
  journalID,
  issueDescription,
  acronym,
  journalTitle,
  volume,
  issue,
  supplement
) {
  return {
    journalID,
    issueDescription,
    acronym,
    journalTitle,
    volume,
    issue,
    supplement,
  };
}

class JournalSearchDialog extends React.Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.state = {
      dialogTitle: this.props.title,
      stockPopupOpen: this.props.showDialog,
      selected: [],
      selectedJournalsList: [],
      filteredJournalsList: this.props.filteredJournalsList,
      // displayJournalsList: [],
      // initialFilteredJournalistsList: [],
      displayJournalsList: this.props.journalList,
      initialFilteredJournalistsList: this.props.journalList,
      searchText: "",
      journalIDSearchText: "",
      issueDescSearchText: "",
      acronymSearchText: "",
      journalTitleSearchText: "",
      volumeSearchText: "",
      issueSearchText: "",
      suppSearchText: "",
      journalLoading: false,
      page: 0,
      rowsPerPage: 100,
      issueDescList: [],
      acronymList: [],
      journalTitleList: [],
      volumeList: [],
      issueList: [],
      suppList: [],
      headCells: [
        {
          id: 1,
          code: "issueDescription",
          numeric: false,
          disablePadding: false,
          label: "Issue Description",
          show: true,
          // width: "200px",
          width: "191px",
        },
        {
          id: 2,
          code: "acronym",
          numeric: false,
          disablePadding: false,
          label: "Acronym",
          show: true,
          // width: "58px",
          width: "53px",
        },
        {
          id: 3,
          code: "journalTitle",
          numeric: false,
          disablePadding: false,
          label: "Journal Title",
          show: true,
          // width: "200px",
          width: "196px",
        },
        {
          id: 4,
          code: "volume",
          numeric: false,
          disablePadding: false,
          label: "Volume",
          show: true,
          // width: "50px",
          width: "44px",
        },
        {
          id: 5,
          code: "issue",
          numeric: false,
          disablePadding: false,
          label: "Issue",
          show: true,
          // width: "56px",
          width: "48px",
        },
        {
          id: 6,
          code: "supplement",
          numeric: false,
          disablePadding: false,
          label: "Supplement",
          show: true,
          width: "60px",
        },
        {
          id: 7,
          code: "action",
          numeric: false,
          disablePadding: false,
          label: "Action",
          show: true,
        },
      ],
    };
  }

  componentDidMount() {
    let issueDescList = [];
    let acronymList = [];
    let journalTitleList = [];
    let volumeList = [];
    let issueList = [];
    let suppList = [];

    this.state.displayJournalsList.map((d) => {
      issueDescList.push(d.issueDescription);
      acronymList.push(d.acronym);
      journalTitleList.push(d.journalTitle);
      volumeList.push(d.volume);
      issueList.push(d.issue);
      suppList.push(d.supplement);
    });

    this.setState({
      issueDescList: issueDescList,
      acronymList: acronymList,
      journalTitleList: journalTitleList,
      volumeList: volumeList,
      issueList: issueList,
      suppList: suppList,
    });
  }

  getJournalInformation = (journalList) => {
    let list = journalList;
    let rows = [];
    let issueDescList = [];
    let acronymList = [];
    let journalTitleList = [];
    let volumeList = [];
    let issueList = [];
    let suppList = [];
    list &&
      list.length > 0 &&
      list.map((d, index) => {
        // if(index<1000){
        issueDescList.push(d.issueDescription);
        acronymList.push(d.acronym);
        journalTitleList.push(d.journalTitle);
        volumeList.push(d.volume);
        issueList.push(d.issue);
        suppList.push(d.supplement);
        return rows.push(
          createData(
            d.journalID ? d.journalID : "",
            d.issueDescription ? d.issueDescription : "",
            d.acronym ? d.acronym : "",
            d.journalTitle ? d.journalTitle : "",
            d.volume ? d.volume : "",
            d.issue ? d.issue : "",
            d.supplement ? d.supplement : ""
          )
        );
        // }
      });
    this.setState({
      displayJournalsList: rows,
      initialFilteredJournalistsList: rows,
      journalLoading: false,
      issueDescList: issueDescList,
      acronymList: acronymList,
      journalTitleList: journalTitleList,
      volumeList: volumeList,
      issueList: issueList,
      suppList: suppList,
    });
  };

  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleClick = (event, row) => {
    // let issueDescription = data.issueDescription,
    //     selected = [],
    //     newSelected = [];
    // if (event.target.checked) {
    //   newSelected = newSelected.concat(selected, issueDescription);
    //   this.checkActiveStatus(newSelected);
    //   this.setState({ selected: newSelected, selectedJournalsList: data });
    // } else {
    //   this.setState({ selected: newSelected, selectedJournalsList: {} });
    // }

    let selected = this.state.selected;
    const selectedIndex = selected.indexOf(row.issueDescription);
    let newSelected = [];
    let selectedJournalsList = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.issueDescription);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.state.displayJournalsList.forEach(function (journal) {
      if (newSelected.includes(journal.issueDescription)) {
        selectedJournalsList.push(journal);
      }
    });
    this.setState({
      selected: newSelected,
      selectedJournalsList: selectedJournalsList,
    });
  };

  handleAddJournalPopupSubmit = () => {
    this.setState(
      {
        stockPopupOpen: false,
        selected: [],
      },
      () => {
        this.props.handleJournalUpdate(this.state.selectedJournalsList);
      }
    );
  };

  handlePopupClose = () => {
    this.setState({
      stockPopupOpen: false,
      selected: [],
    });
    this.props.handleAddJournalClose();
  };

  handleAutocomplete = (e, val, name) => {
    this.setState({ [name]: val }, () => {
      const {
        issueDescSearchText,
        acronymSearchText,
        journalTitleSearchText,
        volumeSearchText,
        issueSearchText,
        suppSearchText,
      } = this.state;

      let {
        searchText,
        displayJournalsList,
        initialFilteredJournalistsList,
      } = this.state;
      let filter = initialFilteredJournalistsList.filter((r) => {
        return (
          r.issueDescription
            .toLowerCase()
            .includes(issueDescSearchText.toLowerCase()) &&
          r.acronym.toLowerCase().includes(acronymSearchText.toLowerCase()) &&
          r.journalTitle
            .toLowerCase()
            .includes(journalTitleSearchText.toLowerCase()) &&
          r.volume
            .toString()
            .toLowerCase()
            .includes(volumeSearchText.toLowerCase()) &&
          r.issue
            .toString()
            .toLowerCase()
            .includes(issueSearchText.toLowerCase()) &&
          r.supplement.toLowerCase().includes(suppSearchText.toLowerCase())
          // r
        );
      });
      this.setState({ displayJournalsList: filter });
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      const {
        // journalIDSearchText,
        issueDescSearchText,
        acronymSearchText,
        journalTitleSearchText,
        volumeSearchText,
        issueSearchText,
        suppSearchText,
      } = this.state;

      let {
        searchText,
        displayJournalsList,
        initialFilteredJournalistsList,
      } = this.state;
      // let filter = initialFilteredJournalistsList.filter((r) => {
      //   return (
      //     // r.journalID.toString().includes(journalIDSearchText) &&
      //     r.issueDescription.toLowerCase().includes(issueDescSearchText.toLowerCase()) &&
      //     r.acronym.toLowerCase().includes(acronymSearchText.toLowerCase()) &&
      //     r.journalTitle.toLowerCase().includes(journalTitleSearchText.toLowerCase()) &&
      //     r.volume.toString().toLowerCase().includes(volumeSearchText.toLowerCase()) &&
      //     r.issue.toString().toLowerCase().includes(issueSearchText.toLowerCase()) &&
      //     r.supplement.toLowerCase().includes(suppSearchText.toLowerCase())
      //     // r
      //   );
      // });
      // this.setState({ displayJournalsList: filter });
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  render() {
    const {
      dialogTitle,
      selected,
      displayJournalsList,
      journalIDSearchText,
      issueDescSearchText,
      acronymSearchText,
      journalTitleSearchText,
      volumeSearchText,
      issueSearchText,
      suppSearchText,
      rowsPerPage,
      page,
      issueDescList,
      acronymList,
      journalTitleList,
      volumeList,
      issueList,
      suppList,
    } = this.state;
    let { classes } = this.props;
    const numSelected = selected.length,
      rowCount = displayJournalsList.length;
    const isSelected = (issueDescription) =>
      selected && selected.indexOf(issueDescription) !== -1;
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={this.state.stockPopupOpen}
        onClose={this.handlePopupClose}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent dividers style={{ paddingBottom: "0" }}>
          <div className={classes.search}>
            <div
              style={{ display: "flex", width: "100%", paddingLeft: "49px" }}
            >
              {/* <TextField
                margin="dense"
                id="name"
                autoComplete="off"
                name="journalIDSearchText"
                onChange={this.handleChange}
                value={journalIDSearchText}
                placeholder="Journal ID"
                style={{flex: 1, border: "1px solid #ddd", padding: "6px 6px 0", maxWidth: "100px"}}
              /> */}
              <Autocomplete
                autoComplete
                includeInputInList
                name="issueDescSearchText"
                value={issueDescSearchText}
                style={{ flex: 1, maxWidth: "214px" }}
                options={issueDescList}
                onChange={(e, val) =>
                  this.handleAutocomplete(e, val, "issueDescSearchText")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="issueDescSearchText"
                    label="Issue Desc"
                    style={{ width: "214px", maxWidth: "214px" }}
                  />
                )}
              />
              <Autocomplete
                autoComplete
                includeInputInList
                name="acronymSearchText"
                value={acronymSearchText}
                style={{ flex: 1, maxWidth: "75px" }}
                options={acronymList}
                onChange={(e, val) =>
                  this.handleAutocomplete(e, val, "acronymSearchText")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="acronymSearchText"
                    label="Acr"
                    style={{ width: "75px", maxWidth: "75px" }}
                  />
                )}
              />
              <Autocomplete
                autoComplete
                includeInputInList
                name="journalTitleSearchText"
                value={journalTitleSearchText}
                style={{ flex: 1, maxWidth: "216px" }}
                options={journalTitleList}
                onChange={(e, val) =>
                  this.handleAutocomplete(e, val, "journalTitleSearchText")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="journalTitleSearchText"
                    label="Journal Title"
                    style={{ width: "216px", maxWidth: "216px" }}
                  />
                )}
              />
              <Autocomplete
                autoComplete
                includeInputInList
                name="volumeSearchText"
                value={volumeSearchText}
                style={{ flex: 1, maxWidth: "66px" }}
                options={volumeList}
                onChange={(e, val) =>
                  this.handleAutocomplete(e, val, "volumeSearchText")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="volumeSearchText"
                    label="Vol"
                    style={{ width: "66px", maxWidth: "66px" }}
                  />
                )}
              />
              <Autocomplete
                autoComplete
                includeInputInList
                name="issueSearchText"
                value={issueSearchText}
                style={{ flex: 1, maxWidth: "72px" }}
                options={issueList}
                onChange={(e, val) =>
                  this.handleAutocomplete(e, val, "issueSearchText")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="issueSearchText"
                    label="Iss"
                    style={{ width: "72px", maxWidth: "72px" }}
                  />
                )}
              />
              <Autocomplete
                autoComplete
                includeInputInList
                name="suppSearchText"
                value={suppSearchText}
                style={{ flex: 1, maxWidth: "81px" }}
                options={suppList}
                onChange={(e, val) =>
                  this.handleAutocomplete(e, val, "suppSearchText")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="suppSearchText"
                    label="Supp"
                    style={{ width: "81px", maxWidth: "81px" }}
                  />
                )}
              />
              {/* <TextField
                margin="dense"
                id="name"
                autoComplete="off"
                name="issueDescSearchText"
                onChange={this.handleChange}
                value={issueDescSearchText}
                placeholder="Issue Desc"
                style={{flex: 1, border: "1px solid #eee", padding: "6px 6px 0", maxWidth: "200px", marginBottom: "0"}}
              />
              <TextField
                margin="dense"
                id="name"
                autoComplete="off"
                name="acronymSearchText"
                onChange={this.handleChange}
                value={acronymSearchText}
                placeholder="Acronym"
                style={{flex: 1, border: "1px solid #eee", padding: "6px 6px 0", maxWidth: "60px", marginBottom: "0"}}
              />
              <TextField
                margin="dense"
                id="name"
                autoComplete="off"
                name="journalTitleSearchText"
                onChange={this.handleChange}
                value={journalTitleSearchText}
                placeholder="Journal Title"
                style={{flex: 1, border: "1px solid #eee", padding: "6px 6px 0", maxWidth: "203px", marginBottom: "0"}}
              />
              <TextField
                margin="dense"
                id="name"
                autoComplete="off"
                name="volumeSearchText"
                onChange={this.handleChange}
                value={volumeSearchText}
                placeholder="Volume"
                style={{flex: 1, border: "1px solid #eee", padding: "6px 6px 0", maxWidth: "51px", marginBottom: "0"}}
              />
              <TextField
                margin="dense"
                id="name"
                autoComplete="off"
                name="issueSearchText"
                onChange={this.handleChange}
                value={issueSearchText}
                placeholder="Issue"
                style={{flex: 1, border: "1px solid #eee", padding: "6px 6px 0", maxWidth: "57px", marginBottom: "0"}}
              />
              <TextField
                margin="dense"
                id="name"
                autoComplete="off"
                name="suppSearchText"
                onChange={this.handleChange}
                value={suppSearchText}
                placeholder="Supplement"
                style={{flex: 1, border: "1px solid #eee", padding: "6px 6px 0", maxWidth: "66px", marginBottom: "0"}}
              /> */}
            </div>
          </div>
          {this.state.journalLoading ? (
            <div style={{ textAlign: "center" }}>
              <LinearProgress />
            </div>
          ) : null}
          <div>
            <div className={classes.tabHeader}>
              <div
                className={classes.tabHeaderField}
                style={{ width: "27px" }}
              ></div>
              {this.state.headCells.map((headCell, index) => {
                return (
                  index != this.state.headCells.length - 1 && (
                    <div
                      className={classes.tabHeaderField}
                      style={{ width: headCell.width }}
                    >
                      {headCell.label}
                    </div>
                  )
                );
              })}
            </div>
            <div className={classes.tabBody}>
              {displayJournalsList.map((row, key) => {
                return (
                  <div style={{ display: "flex" }}>
                    <div
                      className={classes.tabBodyField}
                      style={{ width: "27px" }}
                    >
                      <input
                        className={classes.inputRadio}
                        type="radio"
                        name="rowselected"
                        value={key}
                      ></input>
                    </div>
                    <div
                      className={classes.tabBodyField}
                      style={{ width: "191px" }}
                    >
                      {row.issueDescription}
                    </div>
                    <div
                      className={classes.tabBodyField}
                      style={{ width: "53px" }}
                    >
                      {row.acronym}
                    </div>
                    <div
                      className={classes.tabBodyField}
                      style={{ width: "196px" }}
                    >
                      {row.journalTitle}
                    </div>
                    <div
                      className={classes.tabBodyField}
                      style={{ width: "44px" }}
                    >
                      {row.volume}
                    </div>
                    <div
                      className={classes.tabBodyField}
                      style={{ width: "48px" }}
                    >
                      {row.issue}
                    </div>
                    <div
                      className={classes.tabBodyField}
                      style={{ width: "60px" }}
                    >
                      {row.supplement}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* <Table
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
            style={{
              border: "1px solid  rgba(224, 224, 224, 1)",
              height: "250px",
              display: "block",
              overflowY: "auto",
            }}
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  padding="checkbox"
                  className={classes.tableContentCell}
                >
                </TableCell>
                {this.state.headCells.map((headCell, i) => {
                  return (
                    headCell.show === true && i != this.state.headCells.length-1 && (
                      <TableCell
                        component="th"
                        scope="row"
                        padding="default"
                        className={classes.tableContentCell}
                        style={{maxWidth: headCell.width, width: headCell.width}}
                      >
                        {" "}
                        <span style={{ color: "#306dca" }}>
                          {headCell.label}
                            </span>{" "}
                      </TableCell>
                    )
                  );
                })}
              </TableRow>
            </TableHead>
            { displayJournalsList.length > 0 ? 
              <TableBody>
                {displayJournalsList.map((row, index) => {
                  const isItemSelected = isSelected(row.issueDescription);
                   return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        <TableCell padding="checkbox">
                          <FormControlLabel
                            onClick={(event) =>
                              this.handleClick(event, row)
                            }
                            control={
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                              />
                            }
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          padding="default"
                          align="left"
                          className={classes.tableContentCell}
                        >
                          <Typography
                            noWrap
                            style={{ fontSize: "14px" }}
                          >
                            {row.issueDescription}
                          </Typography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          padding="default"
                          align="left"
                          className={classes.tableContentCell}
                        >
                          <Typography
                            noWrap
                            style={{ fontSize: "14px" }}
                          >
                            {row.acronym}
                          </Typography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          padding="default"
                          align="left"
                          className={classes.tableContentCell}
                        >
                          <Typography
                            noWrap
                            style={{ fontSize: "14px" }}
                          >
                            {row.journalTitle}
                          </Typography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          padding="default"
                          align="right"
                          className="table-content-right-cell"
                        >
                          <Typography
                            noWrap
                            style={{ fontSize: "14px" }}
                          >
                            {row.volume}
                          </Typography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          padding="default"
                          align="right"
                          className="table-content-right-cell"
                        >
                          <Typography
                            noWrap
                            style={{ fontSize: "14px" }}
                          >
                            {row.issue}
                          </Typography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          padding="default"
                          align="right"
                          className="table-content-right-cell"
                        >
                          <Typography
                            noWrap
                            style={{ fontSize: "14px" }}
                          >
                            {row.supplement}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                })}
              </TableBody> 
            : 
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8} style={{ textAlign: "center" }}>
                    No result found
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                    {this.state.journalLoading ? (
                      <TableCell
                        colSpan={8}
                        style={{ textAlign: "center" }}
                      >
                        <LinearProgress />
                      </TableCell>
                    ) : (
                      <TableCell
                        colSpan={8}
                        style={{ textAlign: "center" }}
                      >
                        No result found
                      </TableCell>
                    )}
                  </TableRow>
              </TableBody>
            }
          </Table> */}
          {/* <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={displayJournalsList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handlePopupClose}
            variant="contained"
            color="primary"
            style={{ boxShadow: "none" }}
          >
            CANCEL
          </Button>
          {Object.keys(selected).length === 0 ? (
            <Button
              disabled
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={this.handleAddJournalPopupSubmit}
            >
              SUBMIT
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={this.handleAddJournalPopupSubmit}
            >
              SUBMIT
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withRouter(withStyles(styles)(JournalSearchDialog));
