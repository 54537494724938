import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import DepartmentMaster from "../masters/department";
import CountryMaster from "../masters/country";
import AddCountry from "../masters/country/countryAdd";
import StockInMater from "../generic/stockIn/index";
import AddStockIn from "../generic/stockIn/addStockIn";
import ViewStockIn from "../generic/stockIn/viewStockIn";

import ZoneMaster from "../masters/zone";
import UserMaster from "../userAccess/user";
import AddUser from "../userAccess/user/addUser";
import AddWarehouseLocation from "../masters/warehouseLocation/addWarehouseLocation";
import AddCustomer from "../masters/customer/addCustomer";
import CustomerMaster from "../masters/customer";

import AddMailingScheme from "../masters/mailingScheme/addMailingScheme";
import Acronym from "../masters/acronym";
// import ReportsDashboard from "../reports/reportsDashboard";
import ReceivingReport from "../reports/receivingReport";
import BPLOversReport from "../reports/publisherReports/BPLOversReport";
import MailLodgementVolume from "../reports/publisherReports/MailLodgementVolume";
import BLCPTVolumeReport from "../reports/publisherReports/BLCPTVolumeReport";
import BLCPTSummaryReport from "../reports/publisherReports/BLCPTSummaryReport";
import BLOrderCancellationReport from "../reports/publisherReports/BLOrderCancellationReport";
import BLDetailedCostingReport from "../reports/publisherReports/BLDetailedCostingReport";
import PublisherEmloCpt from "../reports/publisherReports/emloCPT"

import PublisherEbloCpt from "../reports/publisherReports/ebloCPT"
import PublisherEMLOSummery from "../reports/publisherReports/emloSummary"
import PublisherBLCPTG from "../reports/publisherReports/BLCPTG"

import MLCPTWiley from "../reports/publisherReports/MLCPTWiley";
import MLCPTMacMillan from "../reports/publisherReports/MLCPTMacMillan";
import MLCPTWS from "../reports/publisherReports/MLCPTWS";
import PublisherStockIn from "../reports/publisherReports/PublisherStockIn";
import PublisherStockOut from "../reports/publisherReports/PublisherStockOut";
import PublisherStockTransfer from "../reports/publisherReports/PublisherStockTransfer";
import PublisherStockBalance from "../reports/publisherReports/PublisherStockBalance";
import PublisherStockMovement from "../reports/publisherReports/PublisherStockMovement";
import StockInRoute from "../reports/stockInRoute";
import StockOutReport from "../reports/stockOutRoute";
import StockMovement from "../reports/stockMovementReport";
import StockBalance from "../reports/stockBalance";
import ReOrderLevel from "../reports/reOrderLevel";
import CollectionNoteManifest from "../reports/collectionNoteManifest";
import DeliveryNoteManifest from "../reports/deliveryNoteManifest";
import StockAging from "../reports/stockAging";
import WorkOrderManifest from "../reports/workOrderManifest";
import MBAManifestReport from "../reports/MBAManifestReport";
import DocketPostingManifestReport from "../reports/DocketPostingManifestReport";
import RevenueReport from "../reports/RevenueReport";
import ProductionSummaryReport from "../reports/ProductionSummaryReport";
import ProductionReport from "../reports/ProductionReport";
import DistributorMaster from "../masters/distributor";
import AddDistributor from "../masters/distributor/adddistributor";
import EditDistributor from "../masters/distributor/editdistributor";
import ViewDistributor from "../masters/distributor/viewdistributor";
import DispatchModeMaster from "../masters/dispatchMode";
import AddAcronym from "../masters/acronym/addAcronym";
import LetterShopMaster from "../masters/letterShop";
import ViewLetterShop from "../masters/letterShop/viewlettershop";
import EditLetterShop from "../masters/letterShop/editlettershop";
import DHLRateMaster from "../masters/DHLRate";
import WarehouseLocationMaster from "../masters/warehouseLocation";
import EditWarehouseLocation from "../masters/warehouseLocation/editWarehouseLocation";
import ViewWarehouse from "../masters/warehouseLocation/viewWarehouseLocation";
import CPTCodeMater from "../masters/cptCode";
import SubMenuMater from "../userAccess/subMenu";
import MenuMater from "../userAccess/menu";
import SuperMenuMater from "../userAccess/superMenu";
import RoleMater from "../userAccess/role";
import RoleActionMater from "../userAccess/roleAction";
import AddProcessType from "../masters/processType/addProcessType";
import AddProcessRate from "../masters/processRate/add";
import ProcessTypeMater from "../masters/processType";
import ProcessRateMaster from "../masters/processRate";
import AddProcess from "../masters/processMaster/addProcess";
import EditProcess from "../masters/processMaster/editProcess";
import ViewProcessMaster from "../masters/processMaster/viewProcess";
import ProcessMaster from "../masters/processMaster";
import AddBackLabelStockOut from "../../components/publisherBackLabel/backLabelStockOut/add";
import ViewBackLabelStockOut from "../publisherBackLabel/backLabelStockOut/view";

import EditProcessType from "../masters/processType/editProcessType";
import ViewProcessType from "../masters/processType/viewProcessType";
import EditProcessRate from "../masters/processRate/edit";
import ViewProcessRate from "../masters/processRate/view";
import AddProvince from "../masters/province/addProvince";
import ProvinceMater from "../masters/province";
import EditProvince from "../masters/province/editProvince";
import ViewProvince from "../masters/province/viewProvince";
import AddBackLabelStockTransfer from "../publisherBackLabel/backLabelStockTransfer/add";
import ViewStockTransfer from "../publisherBackLabel/backLabelStockTransfer/view";

import AddCodeMaster from "../masters/codeMaster/addCodeMaster";
import CodeMasterMater from "../masters/codeMaster";
import EditCodeMaster from "../masters/codeMaster/editCodeMaster";
import ViewCodeMaster from "../masters/codeMaster/viewCodeMaster";
import AddOutworkerClaimRate from "../masters/outworkerClaimRate/addOutworkerClaimRate";
import OutworkerClaimRateMater from "../masters/outworkerClaimRate";
import EditOutworkerClaimRate from "../masters/outworkerClaimRate/editOutworkerClaimRate";
import ViewOutworkerClaimRate from "../masters/outworkerClaimRate/viewOutworkerClaimRate";
import RoutingZoneOrCountryMaster from "../routing/routingByZoneOrCountry";
import RoutingCountryMaster from "../routing/routingByCountry";
import EditRoutingCountry from "../routing/routingByCountry/editRoutingCountry";
import ViewRoutingCountry from "../routing/routingByCountry/viewRoutingCountry";
import AddCptCode from "../masters/cptCode/addCpt";
import AddSubMenu from "../userAccess/subMenu/addSubMenu";
import AddMenu from "../userAccess/menu/addMenu";
import AddSuperMenu from "../userAccess/superMenu/addSuperMenu";
import AddRole from "../userAccess/role/addRole";
import AddRoleAction from "../userAccess/roleAction/addRoleAction";
import ViewRole from "../userAccess/role/viewRole";
import EditRole from "../userAccess/role/editRole";
import ViewRoleAction from "../userAccess/roleAction/viewRoleAction";
import EditRoleAction from "../userAccess/roleAction/editRoleAction";
import ViewSuperMenu from "../userAccess/superMenu/viewSuperMenu";
import EditSuperMenu from "../userAccess/superMenu/editSuperMenu";
import ViewMenu from "../userAccess/menu/viewMenu";
import EditMenu from "../userAccess/menu/editMenu";
import ViewSubMenu from "../userAccess/subMenu/viewSubMenu";
import EditSubMenu from "../userAccess/subMenu/editSubMenu";
import AddRoutingZoneCountry from "../routing/routingByZoneOrCountry/addRoutingZone";
import EditRoutingZone from "../routing/routingByZoneOrCountry/editRoutingZone";
import EditCountry from "../masters/country/editCountry";
import AddRoutingCountry from "../routing/routingByCountry/addRoutingCountry";
import ViewCountry from "../masters/country/viewCountry";
import RoutingEMLOEBLOMaster from "../routing/routingEMLOEBLO/index";
import AddRoutingEMLOEBLO from "../routing/routingEMLOEBLO/addRoutingEMLOEBLO";
import ViewRoutingEMLOEBLO from "../routing/routingEMLOEBLO/viewRoutingEMLOEBLO";
import EditRoutingEMLOEBLO from "../routing/routingEMLOEBLO/editRoutingEMLOEBLO";
import RoutingTitleMaster from "../routing/routingTitle/index";
import AddRoutingTitle from "../routing/routingTitle/addRoutingTitle";
import EditRoutingTitle from "../routing/routingTitle/editRoutingTitle";
import ViewRoutingTitle from "../routing/routingTitle/viewRoutingTitle";
import RoutingUKUSMaster from "../routing/routingUKUS/index";
import AddRoutingUKUS from "../routing/routingUKUS/addRoutingUKUS";
import RoutingPriorityMaster from "../routing/routingPriority/index";
import AddRoutingPriority from "../routing/routingPriority/addRoutingPriority";
import ViewRoutingPriority from "../routing/routingPriority/viewRoutingPriority";
import EditRoutingPriority from "../routing/routingPriority/editRoutingPriority";
import ViewRoutingUKUS from "../routing/routingUKUS/viewRoutingUKUS";
import EditRoutingUKUS from "../routing/routingUKUS/editRoutingUKUS";
import ViewCptCode from "../masters/cptCode/viewCpt";
import ViewRoutingZone from "../routing/routingByZoneOrCountry/viewRoutingZone";
import EditCptCode from "../masters/cptCode/editCpt";
import ViewCustomer from "../masters/customer/viewCustomer";
import AddDepartment from "../masters/department/addDepartment";
import AddLetterShop from "../masters/letterShop/addLetterShop";
import AddZone from "../masters/zone/addZone";
import AddDispatchMode from "../masters/dispatchMode/addDispatchmode";
import ViewDispatchMode from "../masters/dispatchMode/viewDispatchMode";
import EditDispatchMode from "../masters/dispatchMode/editDispatchMode";
import MailingSchemeMaster from "../masters/mailingScheme";
import EditMailingScheme from "../masters/mailingScheme/editMailingScheme";
import ViewMailingScheme from "../masters/mailingScheme/viewMailingScheme";

import ProductionLine from "../masters/productionLine";
import AddProductionLine from "../masters/productionLine/addProductionLine";
import ViewProductionLine from "../masters/productionLine/viewProductionLine";
import EditProductionLine from "../masters/productionLine/editProductionLine";
// import DeleteProductionLine from "../masters/mailingScheme/viewMailingScheme";
import CodeType from "../masters/codeType";
import AddCodeType from "../masters/codeType/addCodeType";
import ViewCodeType from "../masters/codeType/viewCodeType";
import EditCodeType from "../masters/codeType/editCodeType";

import LocalPostage from "../masters/localPostage";
import AddLocalPostage from "../masters/localPostage/add";
import UserOrderPlacement from "../generic/userOrder";
import ViewUserOrder from "../generic/userOrder/view";
import AddUserOrder from "../generic/userOrder/add";
import EditUserOrder from "../generic/userOrder/edit";
import ViewLocalPostage from "../masters/localPostage/view";
import EditLocalPostage from "../masters/localPostage/edit";
import OrderPlacement from "../generic/orderPlacement";
import AddOrderPlacement from "../generic/orderPlacement/add";
import ViewCustomerOrder from "../generic/orderPlacement/view";
import EditCustomerOrder from "../generic/orderPlacement/edit";

import EditDepartment from "../masters/department/editDepartment";
import ViewDepartment from "../masters/department/viewDepartment";
import EditZone from "../masters/zone/editZone";
import ViewZone from "../masters/zone/viewZone";
import AddDHLRate from "../masters/DHLRate/addDHLRate";
import EditDHLRate from "../masters/DHLRate/editDHLRate";
import ViewDHLRate from "../masters/DHLRate/viewDHLRate";
import EditUser from "../userAccess/user/editUser";
import ViewUser from "../userAccess/user/viewUser";

//import EditDepartment from "../masters/department/editDepartment";
import ItemMaster from "../generic/ItemMaster/index.js";
import AddItemMaster from "../generic/ItemMaster/addItem.js";
import ViewItemMaster from "../generic/ItemMaster/viewItem.js";
import EditItemMaster from "../generic/ItemMaster/editItem.js";
import EditAcronym from "../masters/acronym/editAcronym";
import ViewAcronym from "../masters/acronym/viewAcronym";
import JournalMatrixMaster from "../masters/journalVersionMatrix";
import AddJournalMatrix from "../masters/journalVersionMatrix/addJournalMatrix";
import EditJournalMatrix from "../masters/journalVersionMatrix/editJouralMatrix";
import ViewJournalMatrix from "../masters/journalVersionMatrix/viewJournalMatrix";
import JobScheduler from "../generic/jobScheduler/index.js";

import CollectionNoteMater from "../generic/collectionNote";
import AddCollectionNote from "../generic/collectionNote/addCollectionNote";
import CloneCollectionNote from "../generic/collectionNote/cloneCollectionNote";

import ViewCollectionNote from "../generic/collectionNote/viewCollectionNote";
import EditCollectionNote from "../generic/collectionNote/editCollectionNote";
import DeliveryNoteMater from "../generic/deliveryNote";
import AddDeliveryNote from "../generic/deliveryNote/addDeliveryNote";
import CloneDeliveryNote from "../generic/deliveryNote/cloneDeliveryNote"
import ViewDeliveryNote from "../generic/deliveryNote/viewDeliveryNote";
import EditDeliveryNote from "../generic/deliveryNote/editDeliveryNote";
import AddDocket from "../generic/workOrder/createNewDocket";
import UpdateDocket from "../generic/workOrder/updateDocket";
import AddRunMailList from "../generic/workOrder/runMailList";

// import Report from "../masters/reports/index.js";

import StockOut from "../generic/stockOut";
import AddStockOut from "../generic/stockOut/addStockOut";
import ViewStockOut from "../generic/stockOut/viewStockOut";

import StockIndent from "../generic/stockIndent";
import ViewStockIndent from "../generic/stockIndent/view";
import navbar from "./navbar";
import login from "../login/login1";
import changePassword from "../login/changePassword";
import ChangePasswordByEmail from "../login/changePasswordByEmail"
import ForgotPassword from "../login/forgotPassword";
//import EditJobScheduler from "../generic/jobScheduler/editJobScheduler";
import WorkOrders from "../generic/workOrder";
import EditWorkOrder from "../generic/workOrder/editWorkOrder";
// import ViewWorkOrder from "../generic/workOrder/viewWorkOrder";
// import JobGroup from "../generic/workOrder/jobGroup";
// import Process from "../generic/workOrder/process";
import WorkOrderTab from "../generic/workOrder/workOrderTabPanel";
import DocketPosting from "../generic/docketPosting";
import ViewDocketPosting from "../generic/docketPosting/view";
import ViewPubDocInfo from "../publisher/mainLabelDocketBagging/viewDocInfo";
import EditPubDocInfo from "../publisher/mainLabelDocketBagging/editDocInfo";
import ViewPubBLDocInfo from "../publisherBackLabel/docketBagging/viewDocInfo";
import EditPubBLDocInfo from "../publisherBackLabel/docketBagging/editDocInfo";
import UpdateMBAReport from "../generic/workOrder/EditMBAReport";
import ViewOWCReportByID from "../generic/workOrder/viewOWCByID";

//edit mba report has npot been committed
//Publisher...................................
import JournalItemMaster from "../publisher/journalItemMaster";
import AddJournalItemMaster from "../publisher/journalItemMaster/add";
import AddMLOP from "../publisher/mainLabelOrderPlacement/add";
import PreAlert from "../publisher/preAlert";
import AddPreAlert from "../publisher/preAlert/add";
import ViewPreAlert from "../publisher/preAlert/view";
import EditPreAlert from "../publisher/preAlert/edit";
import ViewJournalItemMaster from "../publisher/journalItemMaster/view";
import EditJournalItemMaster from "../publisher/journalItemMaster/edit";
import InsertMaster from "../publisher/insertMaster";
import AddInsertMaster from "../publisher/insertMaster/add";
import ViewInsertMaster from "../publisher/insertMaster/view";
import EditInsertMaster from "../publisher/insertMaster/edit";
import MainLabelOrderPlacement from "../publisher/mainLabelOrderPlacement";
import ViewMainLabelOrderPlacement from "../publisher/mainLabelOrderPlacement/view";
import EmloOrderPlacement from "../publisher/emloOrderPlacement";
import AddEmloOrderPlacement from "../publisher/emloOrderPlacement/add";
import EditEmloOrderPlacement from "../publisher/emloOrderPlacement/edit";
import ViewEmloOrderPlacement from "../publisher/emloOrderPlacement/view";
import EmloBatchProcessing from "../publisher/emloBatchProcessing";
import CreateEmloBatchProcessing from "../publisher/emloBatchProcessing/add.js";
import EbloBatchProcessing from "../publisherBackLabel/ebloBatchProcessing";
import AddEbloBatchProcessing from "../publisherBackLabel/ebloBatchProcessing/add.js";

import BpaBatchProcessing from "../bpaMarketing/bpaOrderProcessing";
import AddBpaBatchProcessing from "../bpaMarketing/bpaOrderProcessing/add.js";
import BackLabelStockIn from "../publisherBackLabel/backLabelStockIn";
import AddBackLabelStockIn from "../publisherBackLabel/backLabelStockIn/add";
import EditBackLabelStockIn from "../publisherBackLabel/backLabelStockIn/edit";
import ViewBackLabelStockIn from "../publisherBackLabel/backLabelStockIn/view";

import BackLabelStockOut from "../publisherBackLabel/backLabelStockOut";
import BackLabelStockTransfer from "../publisherBackLabel/backLabelStockTransfer";
import EbloOrderPlacement from "../publisherBackLabel/ebloOrderPlacement";
import AddEbloOrderPlacement from "../publisherBackLabel/ebloOrderPlacement/add";
import EditEbloOrderPlacement from "../publisherBackLabel/ebloOrderPlacement/edit";
import ViewEbloOrderPlacement from "../publisherBackLabel/ebloOrderPlacement/view";
import BackLabelBatchProcessing from "../publisherBackLabel/backLabelBatchProcessing";
// import BackLabelBPAOrderProcessing from "../publisherBackLabel/backLabelBPAOrderProcessing";
import BackLabelDocketPosting from "../publisherBackLabel/backLabelDocketPosting";
import BackLabelBillingProcess from "../publisherBackLabel/backLabelBillingProcess";
import AddBackLabelBillingProcess from "../publisherBackLabel/backLabelBillingProcess/add";

import MainLabelBatchPlacement from "../publisher/mainLabelBatchPlacement";
import AddMainLabelBatchPlacement from "../publisher/mainLabelBatchPlacement/add";
import EditMainLabelBatchPlacement from "../publisher/mainLabelBatchPlacement/edit";

import ViewWorkOrder from "../generic/workOrder/viewWorkOrder";
import ViewMBAReport from "../generic/workOrder/viewMBAReport";
import ViewOWCReport from "../generic/workOrder/viewOWCReport";
import CreateOWCReport from "../generic/workOrder/createOWCReport";
import CreateMBAReport from "../generic/workOrder/createMBAReport";
import BPAOrderPlacement from "../bpaMarketing/orderPlacement";
import RecipientList from "../bpaMarketing/recipientList";

import CreatePreProcess from "../publisher/mainLabelBatchPlacement/createPreProcess";
// import CreatePreProcessView from "../publisher/mainLabelBatchPlacement/createPreProcessForView";
// import CreatePreProcessEdit from "../publisher/mainLabelBatchPlacement/createPreProcessForEdit";
import ViewBatchProcessOF from "../publisher/mainLabelBatchPlacement/view";
import DataMapping from "../publisher/dataMapping/index";
import { getRoleAccess } from "../userAccess/userAccessAction";
import AddBpaOrderPlacement from "../bpaMarketing/orderPlacement/add";
import EditBpaOrderPlacement from "../bpaMarketing/orderPlacement/edit";
import ViewBpaOrderPlacement from "../bpaMarketing/orderPlacement/view";

import BackLabelCreatePreProcess from "../publisherBackLabel/backLabelBatchProcessing/createPreProcess";
import AddBackLabelBatchPlacement from "../publisherBackLabel/backLabelBatchProcessing/add";
import ViewBackLabelBatchPlacement from "../publisherBackLabel/backLabelBatchProcessing/view";
import EditBackLabelBatchPlacement from "../publisherBackLabel/backLabelBatchProcessing/edit";

import MainLabelDocketBagging from "../publisher/mainLabelDocketBagging/";
import MainLabelDocketBaggingViewDetails from "../publisher/mainLabelDocketBagging/viewDetailsDoc";
import EditMainLabelDocketBagging from "../publisher/mainLabelDocketBagging/edit";
import ViewMainLabelDocketBagging from "../publisher/mainLabelDocketBagging/view";
import PublisherDocketBaggingForMainLabel from "../publisher/mainLabelDocketBagging/docketBagging";

import BackLabelDocketBagging from "../publisherBackLabel/docketBagging";
import BackLabelDocketBaggingViewDetails from "../publisherBackLabel/docketBagging/viewDetailsDoc";
import EditBackLabelDocketBagging from "../publisherBackLabel/docketBagging/edit";
import ViewBackLabelDocketBagging from "../publisherBackLabel/docketBagging/view";
import PublisherDocketBaggingForBackLabel from "../publisherBackLabel/docketBagging/docketBagging";
import PubJDR from "../reports/publisherReports/jdr"
import PubJRR from "../reports/publisherReports/jrr"
import Home from "./home";
import NotFound from "./notFound"
class Routes extends React.Component {
  state = {
    auth: "false",
    userName: "",
    newLocation: null,
    prevLocation: null,
    currentLocation: null,
    roleAccess: [],
  };
  componentDidMount() {
    let user = localStorage.getItem("userName");
    let auth = localStorage.getItem("auth");
    this.setState({ auth });
    let userID = window.localStorage.loggedInId;
    if (user) this.setState({ userName: user });
    if (userID) this.callUserRoleAccess(userID);
  }

  callUserRoleAccess = async (userID) => {
    await getRoleAccess(userID).then((r) => {
      this.setState({ roleAccess: r });
    });
  };

  shouldComponentUpdate(s, p) {
    let user = localStorage.getItem("userName");
    let auth = localStorage.getItem("auth");
    if (this.state.userName !== user) {
      this.setState({ userName: user, auth });
    }
    return true;
  }

  sessionExpire = () => {
    const isSingpostUser = localStorage.getItem("isSingpostUser");
    localStorage.clear();
    const baseURL = process.env.REACT_APP_PGS_SERVER_ADFS;
    if (isSingpostUser == "true")
      window.location.replace(baseURL + "/Home/logout");
    else this.props.history.push("/");
  };

  render() {

    let getUrlUserId = this.props.location.pathname.split("?")[1];
    //setTimeout(this.sessionExpire, 1800000);
    return (
      <div>
        <Switch>
          {/* {this.props.auth === "true" && ( */}
          <Route
            path="/ChangePassword"
            component={changePassword}
            exact={true}
          ></Route>
          <Route
            path="/ResetPassword"
            component={ChangePasswordByEmail}
          // exact={true}
          ></Route>
          {this.props.auth === "true" && (
            <Route path="/" component={navbar}></Route>
          )}

          {/* {this.props.auth == false || this.props.auth === null ? (
            <Route path="/" component={login} exact={true}></Route>
          ) : null} */}

          {this.state.auth === null && (
            <Route path="/" component={login} exact={true}></Route>
          )}

          {this.state.auth === "true" ? (
            <React.Fragment>
              <Switch>
                <Route
                  path={"/home"}
                  render={(props) => (
                    <Home
                      handleSettingHeader={this.props.handleSettingHeader}
                      roles={this.state.roleAccess}
                      clearOpenList={this.props.clearOpenList}
                      setSelectedList={this.props.setSelectedList}

                    />
                  )}
                />


                <Route
                  path={"/orderPlacement"}
                  render={(props) => (
                    <OrderPlacement
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/add_orderPlacement"}
                  render={(props) => (
                    <AddOrderPlacement
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                    />
                  )}
                />
                <Route
                  path={"/view_orderPlacement"}
                  render={(props) => (
                    <ViewCustomerOrder
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/edit_orderPlacement"}
                  render={(props) => (
                    <EditCustomerOrder
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                    />
                  )}
                />

                <Route
                  path={"/stockInReport"}
                  render={(props) => (
                    <StockInRoute
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/stockOutReport"}
                  render={(props) => (
                    <StockOutReport
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/reOrderReport"}
                  render={(props) => (
                    <ReOrderLevel
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/collectionNoteManifest"}
                  render={(props) => (
                    <CollectionNoteManifest
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/deliveryNoteManifest"}
                  render={(props) => (
                    <DeliveryNoteManifest
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/stockAgingReport"}
                  render={(props) => (
                    <StockAging
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/workOrderManifest"}
                  render={(props) => (
                    <WorkOrderManifest
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/stockBalanceReport"}
                  render={(props) => (
                    <StockBalance
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/stockMovementReport"}
                  render={(props) => (
                    <StockMovement
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/MBAManifestReport"}
                  render={(props) => (
                    <MBAManifestReport
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/DocketPostingManifestReport"}
                  render={(props) => (
                    <DocketPostingManifestReport
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/RevenueReport"}
                  render={(props) => (
                    <RevenueReport
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/ProductionSummaryReport"}
                  render={(props) => (
                    <ProductionSummaryReport
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/ProductionReport"}
                  render={(props) => (
                    <ProductionReport
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />





                <Route
                  path={"/receivingReport"}
                  render={(props) => (
                    <ReceivingReport
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />


                <Route
                  path={"/view_mbaReport"}
                  render={(props) => (
                    <ViewMBAReport
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      handleDrawerClose={this.props.handleDrawerClose}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/view_owcReport"}
                  render={(props) => (
                    <ViewOWCReport
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      handleDrawerClose={this.props.handleDrawerClose}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/owcReport"}
                  render={(props) => (
                    <CreateOWCReport
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      handleDrawerClose={this.props.handleDrawerClose}
                    />
                  )}
                />
                <Route
                  path={"/mbaReport"}
                  render={(props) => (
                    <CreateMBAReport
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      handleDrawerClose={this.props.handleDrawerClose}
                    />
                  )}
                />


                <Route
                  path={"/bpaRecipientList"}
                  render={(props) => (
                    <RecipientList
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/bpaOrderPlacement"}
                  render={(props) => (
                    <BPAOrderPlacement
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/add_bpaOrderPlacement"}
                  render={(props) => (
                    <AddBpaOrderPlacement
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                    />
                  )}
                />
                <Route
                  path={"/edit_bpaOrderPlacement/:bpaId"}
                  render={(props) => (
                    <EditBpaOrderPlacement
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                    />
                  )}
                />
                <Route
                  path={"/view_bpaOrderPlacement/:bpaId"}
                  render={(props) => (
                    <ViewBpaOrderPlacement
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />

                <Route
                  path={"/BPLOversReport"}
                  render={(props) => (
                    <BPLOversReport
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/MailLodgementVolume"}
                  render={(props) => (
                    <MailLodgementVolume
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/BLCPTVolumeReport"}
                  render={(props) => (
                    <BLCPTVolumeReport
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/BLCPTSummaryReport"}
                  render={(props) => (
                    <BLCPTSummaryReport
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/BLOrderCancellationReport"}
                  render={(props) => (
                    <BLOrderCancellationReport
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/BLDetailedCostingReport"}
                  render={(props) => (
                    <BLDetailedCostingReport
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/PublisherEmloCpt"}
                  render={(props) => (
                    <PublisherEmloCpt
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/PublisherEbloCpt"}
                  render={(props) => (
                    <PublisherEbloCpt
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/PublisherEMLOSummery"}
                  render={(props) => (
                    <PublisherEMLOSummery
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/PublisherBLCPTG"}
                  render={(props) => (
                    <PublisherBLCPTG
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/MLCPTWiley"}
                  render={(props) => (
                    <MLCPTWiley
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/MLCPTMacMillan"}
                  render={(props) => (
                    <MLCPTMacMillan
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/MLCPTWS"}
                  render={(props) => (
                    <MLCPTWS
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/PublisherStockIn"}
                  render={(props) => (
                    <PublisherStockIn
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />

                <Route
                  path={"/PublisherStockOut"}
                  render={(props) => (
                    <PublisherStockOut
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />

                <Route
                  path={"/PublisherStockTransfer"}
                  render={(props) => (
                    <PublisherStockTransfer
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />

                <Route
                  path={"/PublisherStockBalance"}
                  render={(props) => (
                    <PublisherStockBalance
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />

                <Route
                  path={"/PublisherStockMovement"}
                  render={(props) => (
                    <PublisherStockMovement
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      roles={this.state.roleAccess}
                    />
                  )}
                />


                {/* ----new reports jdr jrr....... */}
                <Route
                  path={"/pubReportMLJDR"}
                  render={(props) => (
                    <PubJDR
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      handleDrawerClose={this.props.handleDrawerClose}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                <Route
                  path={"/pubReportMLJRR"}
                  render={(props) => (
                    <PubJRR
                      handleSettingHeader={this.props.handleSettingHeader}
                      setSelectedList={this.props.setSelectedList}
                      clearOpenList={this.props.clearOpenList}
                      handleDrawerClose={this.props.handleDrawerClose}
                      roles={this.state.roleAccess}
                    />
                  )}
                />
                {window.location.pathname !== "/" &&
                  <Route component={NotFound} />}
              </Switch>
            </React.Fragment>
          ) : (
              <Route path="/" component={login} exact={true}></Route>
            )}
          <Route path={"/forgotPassword"} component={ForgotPassword} />
          {this.state.auth === null && <Redirect to="/"></Redirect>}

        </Switch>
      </div>
    );
  }
}

export default withRouter(Routes);
