import React, { Component, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import { StateContext } from "../../../shared/globalState";
import {
  desc,
  stableSort,
  getSorting,
} from "../../../shared/tableCommonFunctions";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TablePagination,
  TableRow,
  TableSortLabel,
  Divider,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getFliteredWorkOrderList, getWorkOrderList } from "../genericActions";
import Validator from "../../../shared/validator";
import { resourceValidation } from "../../../shared/resource";
import { duplicateJobScheduler, getjobScheduleList } from "./action";
import { getjobScheduleProductionDropDownList } from "../../masters/masterActions";
const onlyNumbers = new Validator("onlyNumbers");
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function createData(workOrderID, customerName, workOrderNo, title, jobGroupNo) {
  return {
    workOrderID,
    customerName,
    workOrderNo,
    title,
    jobGroupNo,
  };
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: "absolute", right: "0" }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}
const workOrderSearchValue = {
  stock: 0,
  stockID: "",
  modifiedDateFrom: null,
  modifiedDateTo: null,
  warehouseLocation: 0,
  warehouseLocationID: "",
};
const EnhancedTableToolbar = (props) => {
  const { handleSearchWorkOrder } = props;

  return <div></div>;
};
const clearSearchValues = () => {
  let searchValues = workOrderSearchValue;
  searchValues.customerName = "";
  searchValues.workOrderNo = "";
  searchValues.title = "";
  searchValues.jobGroupNo = "";
  searchValues = workOrderSearchValue;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default class WorkOrderModal extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    selectedJobScheduler: {
      jobQuantity: null,
      productionLineID: null,
      priority: null,
    },
    searchVal: "",
    searchClicked: false,
    selected: [],
    stateWO: [],
    deletePopupOpen: false,
    order: "desc",
    orderBy: "workOrderNo",
    page: 0,
    rowsPerPage: 100,
    rows: [],
    activeRows: 0,
    isActive: false,
    loading: false,
    refreshData: false,
    selectedWorkOrder: {},
    selectedWorkOrderId: 1,
    searchPopupOpen: false,
    workOrderSearchValues: {},
    selectedDDL: 0,
    workOrderList: [],
    exportDataValues: [],
    selectedShowColumns: [],
    errors: {},
    prodLineId: [],
  };

  componentDidMount() {
    const [{ store }, dispatch] = this.context;
    getjobScheduleProductionDropDownList().then((r) => {
      dispatch({
        type: "getProductionLineList",
        newStore: r.data,
      });
      this.getAllStatus(r.data);
    });
  }

  getAllStatus = (list) => {
    this.setState({
      prodLineId:
        list &&
        list.sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
        ),
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let jobQuantityValid = this.state.jobQuantityValid;
    let priorityValid = this.state.priorityValid;
    let statusValid = this.state.statusValid;

    switch (fieldName) {
      case "jobQuantity":
        let selected = this.props && this.props.selectedItemMaster;
        if (value !== "") {
          if (value >= selected.jobQty)
            fieldValidationErrors.jobQuantity = jobQuantityValid
              ? ""
              : "Job Quantity should be less than the actual";
        } else {
          jobQuantityValid = false;
          fieldValidationErrors.jobQuantity = "";
        }
        break;
      case "status":
        if (value !== "null") {
          statusValid = true;
          fieldValidationErrors.status = statusValid
            ? ""
            : " Please select Status";
        } else {
          statusValid = true;
          fieldValidationErrors.status = "";
        }
        break;
      case "priority":
        if (value !== "") {
          priorityValid = onlyNumbers(value);
          fieldValidationErrors.priority = priorityValid
            ? ""
            : resourceValidation.onlyNumbers;
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        jobQuantityValid,
        priorityValid,
        statusValid,
        submitSuccess: false,
      },
      this.validateForm
    );
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    if (this.props.handleDuplicateClose) this.props.handleDuplicateClose();
    this.setState({
      open: false,
      selectedJobScheduler: {
        jobQuantity: "",
        productionLineID: "",
        priority: "",
      },
      searchVal: "",
      searchClicked: false,
      stateWO: this.props.selectedItemMaster,
    });
  };
  handleDialogOpen = () => {
    this.setState({
      selectedJobScheduler: {
        jobQuantity: "",
        productionLineID: "",
        priority: "",
      },
      searchVal: "",
      searchClicked: false,
      stateWO: this.props.selectedItemMaster,
    });
  };
  handleSubmit = async () => {
    let { selectedJobScheduler } = this.state;
    let selected = this.props && this.props.selectedItemMaster;

    let reqBody = {
      productionLineID: selectedJobScheduler.productionLineID,
      jobQuantity: Number(selectedJobScheduler.jobQuantity),
      priority: Number(selectedJobScheduler.priority),
      createdBy: localStorage.loggedInId,
    };
    let ParentJobSchedulerID = this.props && this.props.ParentJobSchedulerID;

    const [{ store }, dispatch] = this.context;
    await duplicateJobScheduler(
      reqBody,
      ParentJobSchedulerID,
      this.props.history
    ).then((r) => {
      if (r === 201) {
        getjobScheduleList().then((r) => {
          dispatch({
            type: "jobScheduleData",
            newStore: r.data,
          });
          if (r.status === 200) {
            this.props.getAllItemMaster(r.data);
            // this.exportDatas(r.data);
            this.handleClose();
            // window.location.reload();
          }
        });
      }
    });
  };

  onInputChanged = (e) => {
    this.setState({ searchVal: e.target.value });
  };

  handleClick(event, data) {
    let workOrderID = data.workOrderID;
    this.setState({ selectedWO: data });
    let { selected } = this.state;

    const selectedIndex = selected.indexOf(workOrderID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, workOrderID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedWorkOrder: data,
        selectedWorkOrderId: data.workOrderID,
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedWO: "",
        selectedWorkOrderId: 0,
      });
    }
  }

  _onKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleChange = (event) => {
    let { selectedJobScheduler } = this.state;
    selectedJobScheduler[event.target.name] = event.target.value;
    this.setState({ selectedJobScheduler });
    this.validateField(event.target.name, event.target.value);
  };

  handleProductionLineIdChange = (event, index) => {
    const { selectedJobScheduler } = this.state;
    let lastIndexValue = event.target.id.split("option-")[1];
    selectedJobScheduler["productionLineValue"] = this.state.prodLineId[
      lastIndexValue
    ].value;
    selectedJobScheduler["productionLineID"] = this.state.prodLineId[
      lastIndexValue
    ].id;

    this.setState({ selectedJobScheduler });

    // const { jobScheduleList } = this.state;

    // let lastIndexValue = event.target.id.split("option-")[1];
    // selectedJobScheduler["productionLineID"] =
    //   this.state.prodLineId &&
    //   this.state.prodLineId[lastIndexValue] &&
    //   this.state.prodLineId[lastIndexValue].value;

    // selectedJobScheduler["productionLineCode"] =
    //   this.state.prodLineId &&
    //   this.state.prodLineId[lastIndexValue] &&
    //   this.state.prodLineId[lastIndexValue].value;
    // this.setState({ selectedJobScheduler });
  };

  render() {
    let selected = this.props && this.props.selectedItemMaster;

    let { selectedJobScheduler, prodLineId } = this.state;
    let productionLineId =
      prodLineId &&
      prodLineId.map((val) => {
        return val.value;
      });
    return (
      <div>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          onClose={this.props.handleDuplicateClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.duplicateOpen}
          maxWidth="lg"
        >
          <DialogTitle
            style={{ display: "flex", alignItems: "center" }}
            id="customized-dialog-title"
            onClose={this.props.handleDuplicateClose}
          >
            Duplicate Job
          </DialogTitle>
          <DialogContent dividers>
            <div style={{ minWidth: "400px" }}>
              <div>
                <div style={{ marginTop: "-15px" }}>
                  <form onSubmit={this.handleSubmit}>
                    <div
                      style={{
                        fontFamily: "Roboto",
                        fontSize: "15px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        color: "#3982ea",
                        paddingTop: "15px",
                      }}
                    >
                      Existing Job
                    </div>
                    <TextField
                      required
                      disabled
                      variant="filled"
                      label="Production Line"
                      name="productionLine"
                      inputProps={{
                        maxLength: 2,
                      }}
                      style={{ marginRight: "20px" }}
                      margin="normal"
                      value={selected.productionLineCode}
                      autoComplete="off"
                    />
                    <TextField
                      required
                      disabled
                      variant="filled"
                      inputProps={{
                        maxLength: 100,
                      }}
                      label="Job Quantity"
                      name="jobQuantity"
                      margin="normal"
                      value={selected.jobQty}
                      autoComplete="off"
                    />
                    <Divider style={{ margin: "15px 0" }} />
                    <div
                      style={{
                        fontFamily: "Roboto",
                        fontSize: "15px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        color: "#3982ea",
                        paddingTop: "15px",
                      }}
                    >
                      New Job
                    </div>
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {" "}
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "50%",
                            marginRight: "7px",
                          }}
                        >
                          {" "}
                          <TextField
                            label="Job Quantity"
                            name="jobQuantity"
                            required
                            inputProps={{
                              maxLength: 50,
                            }}
                            style={{
                              marginTop: "5px",
                              width: "100%",
                              marginRight: "7px",
                            }}
                            margin="normal"
                            autoComplete="off"
                            value={selectedJobScheduler.jobQuantity}
                            onChange={(e) =>
                              this.handleChange(e, "jobQuantity")
                            }
                            error={this.state.errors.jobQuantity}
                            helperText={
                              this.state.errors.jobQuantity
                                ? this.state.errors.jobQuantity
                                : ""
                            }
                            onKeyPress={this._onKeyPress}
                          />
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "50%",
                            marginTop: "-2px",
                            marginLeft: "7px",
                          }}
                        >
                          <Autocomplete
                            required
                            autoComplete
                            includeInputInList
                            id="productionLineID-index"
                            name="productionLineID"
                            label="Production Line"
                            hintText="Production Line ID"
                            options={productionLineId && productionLineId}
                            style={{ marginTop: "0px" }}
                            onChange={(e) =>
                              this.handleProductionLineIdChange(
                                e,
                                "productionLineID"
                              )
                            }
                            value={selectedJobScheduler.productionLineValue}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Production Line"
                                name="productionLineID"
                                id="productionLineID"
                                style={{
                                  marginTop: "0px !important",
                                  marginLeft: "7px",
                                  width: "100%",
                                }}
                                value={selectedJobScheduler.productionLineValue}
                                required
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "50%",
                            marginRight: "7px",
                          }}
                        >
                          {" "}
                          <TextField
                            label="Priority"
                            name="priority"
                            required
                            inputProps={{
                              maxLength: 50,
                            }}
                            style={{
                              marginRight: "15px",
                              marginTop: "6px",
                              width: "100%",
                            }}
                            margin="normal"
                            autoComplete="off"
                            value={selectedJobScheduler.priority}
                            onChange={(e) => this.handleChange(e, "priority")}
                            error={this.state.errors.priority}
                            helperText={
                              this.state.errors.priority
                                ? this.state.errors.priority
                                : ""
                            }
                            onKeyPress={this._onKeyPress}
                          />
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "50%",
                            marginLeft: "7px",
                          }}
                        ></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "end",
              padding: "20px 10px",
            }}
          >
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={() => this.handleClose()}
              >
                CANCEL
              </Button>
            </div>
            <div style={{ marginLeft: "1em" }}>
              {!selectedJobScheduler.jobQuantity ||
              !selectedJobScheduler.priority ||
              !selectedJobScheduler.productionLineValue ? (
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={this.handleSubmit}
                >
                  SUBMIT
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={this.handleSubmit}
                >
                  SUBMIT
                </Button>
              )}
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
