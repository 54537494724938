import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Button, Paper, Divider } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import Validator from "../../../shared/validator";
import { Autocomplete } from "@material-ui/lab";
import { resourceValidation } from "../../../shared/resource";
import { StateContext } from "../../../shared/globalState";
import {
  getLocalPostageLists,
  postLocalPostageLists,
  getCustomerDDList,
} from "../masterActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import { addStyles } from "../../../shared/styles";

const maxLenghtAlpNumHypDot50 = new Validator("maxLenghtAlpNumHypDot50");
const validateMaxLength100 = new Validator("maxlength100");

class AddDispatchMode extends React.Component {
  static contextType = StateContext;
  state = {
    customerName: "",
    customerID: "",
    noCopiesFrom: 0,
    noCopiesTo: 0,
    weightFrom: 0.0,
    weightTo: 0.0,
    postageRate: "0.00",
    customerList: [],
    errors: {
      customerID: false,
      noCopiesFrom: false,
      noCopiesTo: false,
      weightFrom: false,
      weightTo: false,
      postageRate: false,
    },
  };
  componentDidMount() {
    const [{ store }, dispatch] = this.context;
    this.props.handleSettingHeader("Local Postage");
    this.props.setSelectedList("masterDataOpen", "localPostage");
    getCustomerDDList(window.localStorage.token).then((r) => {
      dispatch({
        type: "getCustomerDropdownList",
        newStore: r.data,
      });
      // this.getAllCustomers(store.customerList);
      this.getAllCustomers(r.data);
    });
  }
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.customerDDList && store.customerDDList.length > 0) {
      if (this.state.customerList.length !== store.customerDDList.length) {
        this.getAllCustomers(store.customerDDList);
      }
    }
    return true;
  }
  getAllCustomers = (list) => {
    //console.log(list);
    let customerList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        return customerList.push({ key: c.id, value: c.text });
      });

    this.setState({ customerList });
  };
  handleCustomer = (event, values) => {
    if (values) {
      let name = values;
      let customerID = values && values.key;

      // this.validateField("customer", customerID);
      this.setState({ customerID, customerName: name });
    } else {
      this.setState({ customerID: "", customerName: "" });
    }
  };
  handleChange = (e, validate) => {
    let newRrrors = { ...this.state.errors };

    let value = e.target.value;
    if (
      e.target.name === "postageRate" ||
      e.target.name === "weightFrom" ||
      e.target.name === "weightTo"
    ) {
      value = this.checkDHlWeightDec(e.target.value.toString());
    }

    let result = validate(e.target.value);
    newRrrors[e.target.name] = !result;

    // if(e.target.name === "noCopiesTo" && value){
    //   let copiesFrom = this.state.noCopiesFrom;
    //   if(copiesFrom  === "") {
    //     newRrrors["noCopiesFrom"] = true;
    //   }
    //   else{
    //     let copiesTo = parseInt(value);
    //     if(copiesFrom < copiesFrom){
    //       newRrrors["noCopiesTo"] = true;
    //     }
    //   }
    // }

    // if(e.target.name === "noCopiesFrom" && value){
    //   let copiesTo = this.state.noCopiesTo;

    // }

    this.setState({ [e.target.name]: value, errors: newRrrors });
  };
  _onKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleClose = () => {
    this.setState({
      customerID: "",
      noCopiesFrom: "",
      noCopiesTo: "",
      weightFrom: "",
      weightTo: "",
      postageRate: "",
    });
  };
  checkValidInputs = () => {
    if (
      this.state.customerID == "" ||
      this.state.noCopiesFrom === "" ||
      this.state.noCopiesTo == 0 ||
      this.state.noCopiesTo == "" ||
      this.state.postageRate == "" ||
      // this.state.weightFrom === "" ||
      this.state.weightTo == 0 ||
      this.state.weightTo == ""
    ) {
      return true;
    } else return false;
  };

  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  onBlur = (e) => {
    let value = e.target.value;
    value = this.addZeroes(value.toString(), 3);
    this.setState({ [e.target.name]: value });
  };

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  handleSubmit = (event) => {
    const dispatch = this.context[1];
    let reqBody = {};
    reqBody.customerID = +this.state.customerID;
    reqBody.noCopiesFrom = +this.state.noCopiesFrom;
    reqBody.noCopiesTo = +this.state.noCopiesTo;
    reqBody.weightFrom = +this.state.weightFrom;
    reqBody.weightTo = +this.state.weightTo;
    reqBody.postageRate = +this.state.postageRate;
    reqBody.createdBy = localStorage.loggedInId;
    postLocalPostageLists(this.props.history, reqBody)
      .then((r) => {
        getLocalPostageLists()
          .then((r) => {
            dispatch({
              type: "getLocalPostage",
              newStore: r.data,
            });
          })
          .catch((e) => { });
      })
      .catch((e) => { });
  };
  render() {
    let { classes } = this.props;
    let {
      customerList,
      customerID,
      noCopiesFrom,
      noCopiesTo,
      weightFrom,
      weightTo,
      postageRate,
      errors,
    } = this.state;
    //console.log(r);
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Local Postage</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <Autocomplete
                    autoComplete
                    required
                    includeInputInList
                    label="Customer"
                    style={{ display: "inline-flex" }}
                    name="customer"
                    options={customerList}
                    className={classes.comboBox}
                    onChange={this.handleCustomer}
                    value={this.state.customerName}
                    getOptionLabel={(option) => option.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Customer"
                        name="customer"
                        style={{ width: "100%" }}
                        margin="normal"
                      />
                    )}
                  />
                  <TextField
                    required
                    label="Postage Rate"
                    name="postageRate"
                    inputProps={{
                      maxLength: 8,
                    }}
                    onKeyPress={this._onDecimalKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={postageRate}
                    onChange={(e) =>
                      this.handleChange(e, maxLenghtAlpNumHypDot50)
                    }
                    error={errors.postageRate}
                    helperText={
                      errors.postageRate ? resourceValidation.onlyNumbers : ""
                    }
                    // onBlur = {this.onBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$S</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div>
                  <TextField
                    required
                    label="No Of Copies From"
                    name="noCopiesFrom"
                    inputProps={{
                      maxLength: 6,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={noCopiesFrom}
                    onChange={(e) => this.handleChange(e, validateMaxLength100)}
                    error={errors.noCopiesFrom}
                    helperText={
                      errors.noCopiesFrom
                        ? "Please enter valid no of copies from"
                        : ""
                    }
                  />
                  <TextField
                    required
                    label="No Of Copies To"
                    name="noCopiesTo"
                    inputProps={{
                      maxLength: 6,
                    }}
                    onKeyPress={this._onKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={noCopiesTo}
                    onChange={(e) => this.handleChange(e, validateMaxLength100)}
                    error={errors.noCopiesTo}
                    helperText={
                      errors.noCopiesTo
                        ? "Please enter valid no of copies to"
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    required
                    label="Weight From"
                    name="weightFrom"
                    inputProps={{
                      maxLength: 8,
                    }}
                    onKeyPress={this._onDecimalKeyPress}
                    margin="normal"
                    onBlur={this.onBlur}
                    className={classes.textField}
                    value={weightFrom}
                    onChange={(e) =>
                      this.handleChange(e, maxLenghtAlpNumHypDot50)
                    }
                    // error={errors.weightFrom}
                    // helperText={
                    //   errors.weightFrom ? resourceValidation.onlyNumbers : ""
                    // }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Kg</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    required
                    label="Weight To"
                    name="weightTo"
                    inputProps={{
                      maxLength: 8,
                    }}
                    onKeyPress={this._onDecimalKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={weightTo}
                    onBlur={this.onBlur}
                    onChange={(e) =>
                      this.handleChange(e, maxLenghtAlpNumHypDot50)
                    }
                    error={errors.weightTo}
                    helperText={
                      errors.weightTo ? resourceValidation.onlyNumbers : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Kg</InputAdornment>
                      ),
                    }}
                  />
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/localPostage")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {this.checkValidInputs() ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddDispatchMode));
