import React, { Component } from "react";

class StockAging extends Component {
  state = {};
  componentDidMount() {
    this.props.handleSettingHeader("Stock Aging Report");
    this.props.setSelectedList("masterDataOpen", "stockAgingReport");
  }
  render() {
    return (
      <div>
        <iframe
          style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
          src={`${window.localStorage.ReportURLByID}` + "StockAgingReport"}
        />
      </div>
    );
  }
}

export default StockAging;
