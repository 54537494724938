import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";
import clone from "clone";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import { withRouter } from "react-router-dom";
import TableHead from "@material-ui/core/TableHead";
import { EditIcon } from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import DeleteIcon from "@material-ui/icons/Delete";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CheckIcon from "@material-ui/icons/Check";
import { Autocomplete } from "@material-ui/lab";
import { StateContext } from "../../../shared/globalState";
import { addDispatchStyles } from "../../../shared/styles";
import { getCustomerDropdown } from "../../masters/masterActions";
import { GetMBAByMBAHeaderID, UpdateMBA } from "./action";
const headCells = [
  {
    id: "billingDesc",
    numeric: false,
    disablePadding: true,
    label: "Billing Description ",
  },
  {
    id: "qty",
    numeric: false,
    disablePadding: false,
    label: "Qty",
  },
  {
    id: "rate",
    numeric: false,
    disablePadding: false,
    label: "Rate",
  },
  {
    id: "billingAmt",
    numeric: false,
    disablePadding: false,
    label: "Billing Amt $",
  },
  {
    id: "gsttype",
    numeric: false,
    disablePadding: false,
    label: "GST Type",
  },
  {
    id: "acCode",
    numeric: false,
    disablePadding: false,
    label: "A/c code",
  },
  {
    id: "pic",
    numeric: false,
    disablePadding: false,
    label: "PIC",
  },
  {
    id: "rc",
    numeric: false,
    disablePadding: false,
    label: "RC",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderTop: "1px solid #eaeaea",
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderLeft: "1px sold #000" }}
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { } = props;

  return (
    <div>
      <div
        style={{
          display: "flex",
          //   width: "100%",
          border: "1px solid #eaeaea",
          borderBottom: "none",
          borderRadius: "4px",
          marginTop: "1em",
        }}
      >
        {props.disableAdd ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={(e) => props.handleClickButton(e, "add")}
          // style={
          //   !props.disableAdd
          //     ? {
          //         color: "#fff",
          //         background: "#3982ea",
          //       }
          //     : { color: "#3982ea", fontSize: "11px" }
          // }
          >
            <AddIcon style={{ fontSize: "18px" }} /> Add
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              onClick={(e) => props.handleClickButton(e, "add")}
            // style={
            //   !props.disableAdd
            //     ? {
            //         color: "#fff",
            //         background: "#3982ea",
            //       }
            //     : { color: "#3982ea", fontSize: "11px" }
            // }
            >
              <AddIcon style={{ fontSize: "18px" }} /> Add
            </Button>
          )}
        {props.disableEdit ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            // style={
            //   !props.disableEdit
            //     ? {
            //         color: "#fff",
            //         background: "#3982ea",
            //       }
            //     : { color: "#3982ea", fontSize: "11px" }
            // }
            onClick={(e) => props.handleClickButton(e, "reset")}
          >
            <SyncIcon style={{ fontSize: "18px" }} /> Reset
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              // style={
              //   !props.disableEdit
              //     ? {
              //         color: "#fff",
              //         background: "#3982ea",
              //       }
              //     : { color: "#3982ea", fontSize: "11px" }
              // }
              onClick={(e) => props.handleClickButton(e, "reset")}
            >
              <SyncIcon style={{ fontSize: "18px" }} /> Reset
            </Button>
          )}
      </div>
    </div>
  );
};
class UpdateMBAReport extends React.Component {
  static contextType = StateContext;
  state = {
    disableAdd: false,
    disableEdit: true,
    list: [],
    customer: "",
    workOrder: "",
    title: "",
    planner: "",
    mbaNo: "",
    specialInstruction: "",
    defaultGST: 0,
    billingDate: "",
    workOrderID: "",
  };
  componentDidMount() {
    this.props.handleSettingHeader("Work Order");
    this.props.setSelectedList("genericSystemOpen", "workOrder");
    this.props.handleDrawerClose();

    let wkOrderId = "";
    if (process.browser) wkOrderId = window.location.pathname.split("/")[2];

    let newList = clone(this.state.list);
    GetMBAByMBAHeaderID(wkOrderId).then((r) => {
      this.setState({
        customer: r.custmerName,
        workOrder: r.workOrderNumber,
        title: r.orderTitle,
        planner: r.plannerName ? r.plannerName : "",
        mbaNo: r.mbaNo ? r.mbaNo : "",
        defaultGST: +r.gsTpercentage,
        billingDate: r.billingDate,
        workOrderID: r.workOrderID,
      });
      r.getMBADetails &&
        r.getMBADetails.length > 0 &&
        r.getMBADetails.map((l) => {
          newList.push({
            processDescription: l.processDescription,
            quantity: l.quantity,
            rate: l.rate,
            billingAmount: l.billingAmount,
            gstType: l.gstType,
            accCode: l.accCode,
            pic: l.pic,
            rc: l.rc,
            fromDB: true,
            editClicked: true,
            mbaDetailID: l.mbaDetailID,
          });
        });
      this.setState({ list: newList, disableEdit: false });
    });
  }
  getAllCustomer = (list) => {
    let customerList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        customerList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.setState({ customerList });
  };
  handleClickButton = (e, name) => {
    let newList = clone(this.state.list);
    // let newObj = {
    //   fileName: "",
    //   country: "",
    //   onOfCopies: "",
    //   quantity: "",
    //   remark: "",
    //   routing: "",
    //   exception: "",
    //   editClicked: true
    // };
    let newObj = {
      processDescription: "",
      quantity: "",
      rate: "",
      billingAmount: "",
      gstType: "",
      accCode: "",
      pic: "",
      rc: "",
      editClicked: true,
      manualEntry: true,
    };
    newList.push(newObj);
    if (name === "add") {
      this.setState({
        list: newList,
        editableList: newList,
        // disableAdd: true,
        disableEdit: false,
        OrderGroupID: 0,
      });
    } else {
      this.setState({
        list: [],
        editableList: [],
        disableAdd: false,
        disableEdit: true,
        fileName: "",
        OrderGroupID: 0,
      });
    }
  };
  onEditClicked = (e, i) => {
    let newEditableList = clone(this.state.list);
    newEditableList[i].editClicked = !newEditableList[i].editClicked;
    this.setState({
      list: newEditableList,
    });
  };
  onDeleteClicked = (e, i) => {
    let newList = clone(this.state.list);
    newList[i].editClicked = false;
    newList.splice(i, 1);
    this.setState({
      list: newList,
    });
  };
  onSPLInstructionChanged = (e) => {
    this.setState({ specialInstruction: e.target.value });
  };
  onExcelDataChanged = (e, name, i) => {
    let newList = clone(this.state.list);
    newList[i][name] = e.target.value;
    if (name === "quantity")
      newList[i].billingAmount =
        Math.round((e.target.value * newList[i].rate + Number.EPSILON) * 100) /
        100;
    if (name === "rate")
      newList[i].billingAmount =
        Math.round(
          (e.target.value * newList[i].quantity + Number.EPSILON) * 100
        ) / 100;
    this.setState({ list: newList });
  };
  _onAlphaKeyPress(event) {
    const re = /[a-zA-Z 0-9 ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextKeyPress(event) {
    const re = /[a-zA-Z ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onAllCharPress(event) {
    const re = /[a-z A-Z0-9=.;(){}\/*%#$@&,-_]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleSubmit = (e) => {
    let newList = clone(this.state.list);
    let loggedInId = localStorage.getItem("loggedInId");
    let mbaHeaderId = "";
    let createMBADetails = [];
    if (process.browser) mbaHeaderId = window.location.pathname.split("/")[2];
    let amountBeforeGST = 0;
    newList.map((l) => {
      amountBeforeGST = amountBeforeGST + l.billingAmount;
    });
    amountBeforeGST =
      Math.round((amountBeforeGST + Number.EPSILON) * 100) / 100;
    let gst = (+amountBeforeGST * this.state.defaultGST) / 100;
    gst = Math.round((gst + Number.EPSILON) * 100) / 100;
    let amountWithGST = amountBeforeGST + gst;
    let reqBody = {};
    // reqBody.mbaHeaderID = +mbaHeaderId;
    reqBody.amountBeforeGST = amountBeforeGST;
    reqBody.amountWithGST = amountWithGST;
    reqBody.gst = gst;
    reqBody.modifiedBy = +loggedInId;
    reqBody.specialInstructions = this.state.specialInstruction;
    // reqBody.billingDate = this.state.billingDate;
    // reqBody.billingDate = new Date();
    newList.map((l) => {
      createMBADetails.push({
        quantity: +l.quantity,
        rate: +l.rate,
        billingAmount: l.billingAmount,
        modifiedBy: +loggedInId,
        mbaDetailID: l.mbaDetailID,
      });
    });
    reqBody.updateMBADetails = createMBADetails;
    UpdateMBA(
      reqBody,
      this.props.history,
      mbaHeaderId,
      this.state.workOrderID
    ).then((r) => { });
  };
  render() {
    let { customer, workOrder, title, planner, mbaNo, list } = this.state;
    let { classes } = this.props;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Manual Billing Advice </div>
          <Divider />
          <div className="view-page-container" id="printable">
            <div style={{ display: "flex", marginTop: "1em" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  //   marginTop: "1em",
                  background: "#eaf3fb",
                  padding: ".5em",
                  color: "#3982ea",
                  borderRadius: "4px",
                  width: "100%",
                  fontSize: "15px",
                  fontWeight: "400",
                }}
              >
                <div>
                  <span>Customer : </span>
                  <span style={{ fontWeight: "500" }}>{customer}</span>
                </div>
                <div>
                  <span>Work Order : </span>
                  <span style={{ fontWeight: "500" }}>{workOrder}</span>
                </div>
                <div>
                  <span>Planner : </span>
                  <span style={{ fontWeight: "500" }}>{planner}</span>
                </div>
                <div>
                  <span>Order Title : </span>
                  <span style={{ fontWeight: "500" }}>{title}</span>
                </div>
                {mbaNo && (
                  <div>
                    <span>MBA No : </span>
                    <span style={{ fontWeight: "500" }}>{mbaNo}</span>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div>
                <EnhancedTableToolbar
                  //numSelected={selected.length}
                  handleClickButton={this.handleClickButton}
                  disableAdd={this.state.disableAdd}
                  disableEdit={this.state.disableEdit}
                />
              </div>
              <div
                style={{
                  // width: "900px",
                  maxHeight: "400px",
                  margin: "0  0 1em",
                  borderLeft: "1px Solid #eaeaea",
                  borderRight: "1px solid #eaeaea",
                }}
              >
                <div style={{ maxHeight: 350, overflow: "auto" }}>
                  {/* //overflow: "auto" */}
                  <Table
                    aria-labelledby="tableTitle"
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                  // style={{ width: "1800px", overflow: "auto" }}
                  >
                    {" "}
                    <EnhancedTableHead
                    //numSelected={selected.length}
                    // order={order}
                    // orderBy={orderBy}
                    // onSelectAllClick={e => this.handleSelectAllClick(e)}
                    //  onRequestSort={this.handleRequestSort}
                    //  rowCount={departmentList.length}
                    />
                    <TableBody style={{ overflow: "hidden" }}>
                      {list.map((row, index) => {
                        // const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return !this.state.loading ? (
                          <TableRow
                            // hover
                            // onClick={event =>
                            //   this.handleClick(event, row.id, row)
                            // }
                            role="checkbox"
                            //  aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                          //   selected={isItemSelected}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              {row.editClicked && !row.fromDB ? (
                                <Input
                                  onKeyPress={this._onAlphaKeyPress}
                                  defaultValue={row.processDescription}
                                  inputProps={{ "aria-label": "description" }}
                                  onChange={(e) => {
                                    this.onExcelDataChanged(
                                      e,
                                      "processDescription",
                                      index
                                    );
                                  }}
                                  value={row.processDescription}
                                />
                              ) : (
                                  row.processDescription
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked ? (
                                  <Input
                                    onKeyPress={this._onNumberKeyPress}
                                    defaultValue={row.quantity}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "quantity",
                                        index
                                      );
                                    }}
                                    value={row.quantity}
                                  />
                                ) : (
                                    row.quantity
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked ? (
                                  <Input
                                    onKeyPress={this._onNumberKeyPress}
                                    defaultValue={row.rate}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(e, "rate", index);
                                    }}
                                    value={row.rate}
                                  />
                                ) : (
                                    row.rate
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked && !row.fromDB ? (
                                  <Input
                                    onKeyPress={this._onNumberKeyPress}
                                    defaultValue={row.billingAmount}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "billingAmount",
                                        index
                                      );
                                    }}
                                    value={row.billingAmount}
                                  />
                                ) : (
                                    row.billingAmount
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked && !row.fromDB ? (
                                  <Input
                                    onKeyPress={this._onTextKeyPress}
                                    defaultValue={row.gstType}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "gstType",
                                        index
                                      );
                                    }}
                                    value={row.gstType}
                                  />
                                ) : row.gstType ? (
                                  "Inclusive"
                                ) : (
                                      "Exclusive"
                                    )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked && !row.fromDB ? (
                                  <Input
                                    onKeyPress={this._onAlphaKeyPress}
                                    defaultValue={row.accCode}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "accCode",
                                        index
                                      );
                                    }}
                                    value={row.accCode}
                                  />
                                ) : (
                                    row.accCode
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked && !row.fromDB ? (
                                  <Input
                                    onKeyPress={this._onAlphaKeyPress}
                                    defaultValue={row.pic}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(e, "pic", index);
                                    }}
                                    value={row.pic}
                                  />
                                ) : (
                                    row.pic
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked && !row.fromDB ? (
                                  <Input
                                    onKeyPress={this._onAlphaKeyPress}
                                    defaultValue={row.rc}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(e, "rc", index);
                                    }}
                                    value={row.rc}
                                  />
                                ) : (
                                    row.rc
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {!row.fromDB && (
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      color: "#6c6b6b",
                                    }}
                                    onClick={(e) =>
                                      this.onEditClicked(e, index)
                                    }
                                  >
                                    {!row.editClicked ? (
                                      <EditIcon
                                        style={{
                                          color: "#6c6b6b",
                                        }}
                                      />
                                    ) : (
                                        <CheckIcon
                                          style={{
                                            color: "#6c6b6b",
                                          }}
                                        />
                                      )}
                                  </span>
                                )}

                                <DeleteIcon
                                  style={{
                                    cursor: "pointer",
                                    color: "#6c6b6b",
                                  }}
                                  onClick={(e) =>
                                    this.onDeleteClicked(e, index)
                                  }
                                />
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                            <TableRow>
                              <TableCell colSpan="8">
                                <LinearProgress />
                              </TableCell>
                            </TableRow>
                          );
                      })}

                      {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 25 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                    </TableBody>
                    {/* {this.state.list.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell
                            colSpan="2"
                            style={{
                              borderBottom: "1px solid #3982ea",
                              background: "rgb(234, 243, 251)"
                            }}
                          >
                            Amount Before GST
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "1px solid #3982ea",
                              background: "rgb(234, 243, 251)",
                              paddingLeft: ".5em",
                              fontSize: "16px"
                            }}
                          >
                            45.66
                          </TableCell>
                          <TableCell colSpan="5"></TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {this.state.list.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell
                            colSpan="2"
                            style={{
                              borderBottom: "1px solid #3982ea",
                              background: "rgb(234, 243, 251)"
                            }}
                          >
                            Amount With GST
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "1px solid #3982ea",
                              background: "rgb(234, 243, 251)",
                              paddingLeft: ".5em",
                              fontSize: "16px"
                            }}
                          >
                            453.6622
                          </TableCell>
                          <TableCell colSpan="5"></TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {this.state.list.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell
                            colSpan="2"
                            style={{
                              borderBottom: "1px solid #3982ea",
                              background: "rgb(234, 243, 251)"
                            }}
                          >
                            GST ( 7% )
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "1px solid #3982ea",
                              background: "rgb(234, 243, 251)",
                              paddingLeft: ".5em",
                              fontSize: "16px"
                            }}
                          >
                            66.89
                          </TableCell>
                          <TableCell colSpan="5"></TableCell>
                        </TableRow>
                      </TableBody>
                    )} */}
                    {/* {this.state.list.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan="3" style={{ textAlign: "right" }}>
                            {" "}
                            Special instructions to Finance, if any:
                          </TableCell>
                          <TableCell
                            colSpan="2"
                            style={{
                              borderBottom: "1px solid #3982ea",
                              background: "rgb(234, 243, 251)"
                            }}
                          >
                            <Input
                              onKeyPress={this._onAlphaKeyPress}
                              //defaultValue={row.routing}
                              inputProps={{
                                "aria-label": "description"
                              }}
                              onChange={e => {
                                // this.onExcelDataChanged(e, "routing", index);
                              }}
                              // value={row.routing}
                              style={{ width: "223px" }}
                            />
                          </TableCell>

                          <TableCell colSpan="4"></TableCell>
                        </TableRow>
                      </TableBody>
                    )} */}
                  </Table>
                </div>
              </div>
              {/* <div
                style={{
                  marginLeft: "131px",
                  display: "flex",
                  borderBottom: "1px solid #3982ea",
                  marginBottom: ".5em",
                  paddingBottom: ".5em",
                  width: "280px",
                  background: "rgb(234, 243, 251)",
                  padding: ".5em"
                }}
              >
                <div style={{ width: "200px" }}>Amount Before GST</div>
                <div> 45</div>
              </div>
              <div
                style={{
                  marginLeft: "131px",
                  display: "flex",
                  borderBottom: "1px solid #3982ea",
                  marginBottom: ".5em",
                  paddingBottom: ".5em",
                  width: "280px",
                  background: "rgb(234, 243, 251)",
                  padding: ".5em"
                }}
              >
                <div style={{ width: "200px" }}>Amount With GST</div>
                <div> 453</div>
              </div>
              <div
                style={{
                  marginLeft: "131px",
                  display: "flex",
                  borderBottom: "1px solid #3982ea",
                  marginBottom: ".5em",
                  paddingBottom: ".5em",
                  width: "280px",
                  background: "rgb(234, 243, 251)",
                  padding: ".5em"
                }}
              >
                <div style={{ width: "200px" }}>GST ( 7% )</div>
                <div> 415</div>
              </div> */}
            </div>
            <div style={{ marginBottom: "1em" }}>
              {this.state.list.length > 0 && (
                <div style={{ display: "flex" }}>
                  <div style={{ paddingTop: ".2em" }}>
                    {" "}
                    Special instructions to Finance, if any :
                  </div>
                  <div
                    colSpan="2"
                    style={{
                      //borderBottom: "1px solid #3982ea",
                      //  background: "rgb(234, 243, 251)"
                      marginLeft: "1em",
                    }}
                  >
                    <Input
                      onKeyPress={this._onAllCharPress}
                      //defaultValue={row.routing}
                      inputProps={{
                        "aria-label": "description",
                      }}
                      value={this.state.specialInstruction}
                      onChange={(e) => this.onSPLInstructionChanged(e)}
                      // value={row.routing}
                      style={{ width: "223px" }}
                    />
                  </div>
                </div>
              )}
            </div>
            <Divider />
          </div>

          <div className="button-wrapper" style={{ paddingTop: "0px" }}>
            <div style={{ marginLeft: "2em" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.props.history.push("/workOrders")}
              >
                CANCEL
              </Button>
            </div>
            <div>
              <Button
                // autoFocus
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.handleSubmit(e)}
              >
                Submit
              </Button>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
//export default withRouter();
export default withRouter(withStyles(addDispatchStyles)(UpdateMBAReport));
