import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import { withRouter } from "react-router-dom";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {
  SearchIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import DeleteIcon from "@material-ui/icons/Delete";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import toastr from "toastr";
import clone from "clone";
//import { DownloadIcon } from "../../../shared/customIcons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import moment from "moment";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { resourceValidation } from "../../../shared/resource";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";

import { StateContext } from "../../../shared/globalState";
import LinearProgress from "@material-ui/core/LinearProgress";

import {
  stableSort,
  getSorting,
  DialogActions,
} from "../../../shared/tableCommonFunctions";
import Validator from "../../../shared/validator";
import { Link } from "react-router-dom";
import { ExportCSV } from "../../../shared/ExportCSV";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { getDistributorList, deleteDistributor } from "../masterActions";
const Alphanumeric = new Validator("Alphanumeric");
const checklettershopname = new Validator("checklettershopname");
const columns = [
  {
    name: "Active",
    id: 3,
    show: false,
  },
  {
    name: "Modified Date",
    id: 4,
    show: false,
  },
  {
    name: "Modified By",
    id: 5,
  },
  {
    name: "Created Date",
    id: 6,
  },
  {
    name: "Created By",
    id: 7,
  },
];
const baseURL = process.env.REACT_APP_PGS_SERVER_MASTER_DATA_MANAGEMENT;
const pgsService = axios.create({
  baseURL,
});

function createData(
  id,
  distributorCode,
  distributorName,
  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate,
  isActive
) {
  return {
    id,
    distributorCode,
    distributorName,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
    isActive,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all Distributor" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.code}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                  {/* {orderBy === headCell.id ? <span></span> : null} */}
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    distributorCode: "",
    distributorName: "",
  });
  const [formValid, setIsformValid] = useState(false);
  const [DLSearchValue, setValues] = useState({
    distributorCode: "",
    distributorName: "",
  });
  const classes = useToolbarStyles();
  const {
    numSelected,
    exportDataValues,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handledeleteDistributor,
    searchPopupOpen,
    //selectedDistributor,
    selected,

    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchdistributor,
    searchClicked,
    DistributorList,
    isActive,
  } = props;
  // const deleteUser = () => {
  //   this.handleDeleteUser();
  // };
  // const selecteddistributorID = selected && selected.length > 0 && selected[0];
  const selecteddistributorID = selected[0];
  const selectedDistributor = DistributorList.find(
    (x) => x.id === selecteddistributorID
  );
  let acnArr = props.roles.filter((u) => u.menuName === "Distributor");
  let acnObj = acnArr[0];
  let expData = clone(DistributorList);
  expData.map((d) => {
    let Val = "No";
    if (d["isActive"]) {
      Val = "Yes";
    }
    d["Distributor Code"] = d["distributorCode"];
    d["Distributor Name"] = d["distributorName"];
    d["Active"] = Val;
    d["Created By"] = d["createdBy"];
    d["Created Date"] = moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss");
    d["Modified By"] = d["modifiedBy"];

    d["Modified Date"] = moment(d["modifiedDate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    );

    delete d["distributorCode"];
    delete d["distributorName"];

    delete d["isActive"];

    delete d["createdBy"];

    delete d["createdDate"];

    delete d["modifiedBy"];

    delete d["modifiedDate"];
    delete d["id"];
  });

  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteDistributor = () => {
    handledeleteDistributor();
  };
  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };
  const clearSearchValues = () => {
    let searchValues = DLSearchValue;
    searchValues.distributorName = "";
    searchValues.distributorCode = "";
    searchValues = DLSearchValue;
  };
  const searchPopup = () => {
    handleSearchdistributor(DLSearchValue);
    clearSearchValues();
  };
  const handleSearchInput = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;
    setValues((DLSearchValue) => ({
      ...DLSearchValue,
      [event.target.name]: event.target.value,
    }));

    switch (name) {
      case "distributorCode":
        let codeValid = Alphanumeric(value);
        setIsformValid(codeValid);
        setErrors((errors) => ({
          ...errors,
          [event.target.name]: codeValid
            ? ""
            : resourceValidation.onlyAlphanumeric,
        }));
        break;
      case "distributorName":
        let NameValid = checklettershopname(value);
        setIsformValid(NameValid);
        setErrors((errors) => ({
          ...errors,
          [event.target.name]: NameValid
            ? ""
            : resourceValidation.Distributorname,
        }));
        break;

      default:
        break;
    }
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_distributor/${selecteddistributorID}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <Link to={`/edit_distributor/${selecteddistributorID}`}>
                      <IconButton
                        aria-label="edit"
                        className={classes.iconHover}
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            )}

            {/* <Tooltip title="Delete">
            <ManageDistributor
              mode="delete"
              selected={selected}
              data={{
                departmentName: selectedDepartment.name,
                departmentCode: selectedDepartment.code,
                departmentId: selectedDepartment.id
              }}
              onRequestSucceed={props.onRequestSucceed}
              count={props.selected.length}
            /> 
            
          </Tooltip>*/}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={handleClickOpen}
                  disabled={!isActive}
                >
                  <DeleteIcon className={classes.iconHover} />
                </IconButton>
              </Tooltip>
            )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle
                id="customized-dialog-title"
                //onClose={handleClose}
              >
                Delete Distributor
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  {/* <h5>{this.props.user}</h5> */}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1
                      ? " ?"
                      : selectedDistributor.distributorName + " ?"}
                    {/* {selectedDistributor.distributorName} */}
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={deleteDistributor}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {searchClicked ? (
              <Tooltip title="Clear search result">
                <IconButton
                  aria-label="clear"
                  className={classes.iconHover}
                  onClick={props.clearSearch}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            ) : (
              //<AdvanceSearch onSearchClicked={props.onSearchClicked} />
              <form onSubmit={searchPopup}>
                {acnObj && acnObj.advanceSearchAction && (
                  <Tooltip title="Search">
                    <IconButton
                      aria-label="Search"
                      className={classes.iconHover}
                      onClick={handleSearchOpen}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Dialog
                  disableBackdropClick
                  disableEscapeKeyDown
                  onClose={handleSearchPopupClose}
                  aria-labelledby="customized-dialog-title"
                  open={searchPopupOpen}
                  maxWidth="md"
                >
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleSearchPopupClose}
                  >
                    Advanced Search
                  </DialogTitle>
                  <DialogContent dividers>
                    <React.Fragment>
                      <div>
                        <TextField
                          id="standard-required"
                          label="Distributor Code"
                          placeholder="Distributor Code"
                          className={classes.textField}
                          margin="normal"
                          inputProps={{
                            maxLength: 10,
                          }}
                          style={{ marginRight: "1em" }}
                          autoComplete="off"
                          value={DLSearchValue.distributorCode}
                          error={errors.distributorCode}
                          onChange={handleSearchInput}
                          name="distributorCode"
                          // onChange={e =>
                          //   this.onInputChanged(e, "distributorCode", "notRequired2")
                          // }
                          helperText={
                            errors.distributorCode ? errors.distributorCode : ""
                          }
                        />
                        <TextField
                          label="Distributor Name"
                          id="standard-required"
                          placeholder="Distributor Name"
                          className={classes.textField}
                          margin="normal"
                          autoComplete="off"
                          inputProps={{
                            maxLength: 100,
                          }}
                          value={DLSearchValue.distributorName}
                          error={errors.distributorName}
                          name="distributorName"
                          // onChange={e =>
                          //   this.onInputChanged(
                          //     e,
                          //     "distributorName",
                          //     "notRequired100"
                          //   )
                          // }
                          onChange={handleSearchInput}
                          helperText={
                            errors.distributorName ? errors.distributorName : ""
                          }
                        />
                      </div>
                    </React.Fragment>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={handleSearchClose}
                    >
                      CANCEL
                    </Button>
                    {!formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={searchPopup}
                      >
                        SEARCH
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={searchPopup}
                      >
                        SEARCH
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
              </form>
            )}
            {acnObj && acnObj.addAction && (
              <Tooltip title="Add">
                <IconButton
                  aria-label="add"
                  className={classes.iconHover}
                  onClick={(e) => props.history.push("/add_distributor")}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
            {acnObj && acnObj.exportAction && (
              <ExportCSV csvData={expData} fileName={"Distributor_Details"} />
            )}
            {/* <Tooltip title="Download">
            <IconButton aria-label="download" className={classes.iconHover}>
              <DownloadIcon />
            </IconButton>
          </Tooltip> */}
            {/* <Tooltip title="View Column">
            <IconButton aria-label="viewColumn" className={classes.iconHover}>
              <ViewColumnIcon />
            </IconButton>
          </Tooltip>
          <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                hendleVewColums={hendleVewColums}
                headCells={props.headCells}
              /> */}
          </div>
        )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}
const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class DistributorMaster extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    activeRows: 0,
    isActive: false,
    refreshData: false,
    selectedDistributor: "",
    DistributorList: [],
    selecteddistributorID: 1,
    loading: false,
    searchPopupOpen: false,
    searchClicked: false,
    selectedShowColumns: [],

    headCells: [
      {
        code: "distributorCode",
        id: 1,
        numeric: false,
        disablePadding: true,
        label: "Distributor Code",
        show: true,
      },
      {
        code: "distributorName",
        id: 2,
        numeric: false,
        disablePadding: false,
        label: "Distributor Name",
        show: true,
      },
      {
        code: "isActive",
        id: 3,
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true,
      },
      {
        code: "modifiedDate",
        id: 4,
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        code: "modifiedBy",
        id: 5,
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        code: "createdDate",
        id: 6,
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        code: "createdBy",
        id: 7,
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };
  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };
  componentDidMount() {
    this.props.handleSettingHeader("Distributor");
    this.props.setSelectedList("masterDataOpen", "distributor");
    const dispatch = this.context[1];
    getDistributorList(window.localStorage.token).then((r) => {
      dispatch({
        type: "getDistributorList",
        newStore: r.data,
      });
      this.getAllDistributor(r.data);
      this.exportDatas(r.data);
    });
    this.setState({ loading: true });
  }
  getAllDistributor = (DistributorList) => {
    let list = DistributorList;
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return newRows.push(
          createData(
            d.distributorID,
            d.distributorCode,
            d.distributorName,
            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate,
            d.isActive
          )
        );
      });
    this.sortStateList(newRows);
    this.setState({
      DistributorList: newRows,
      selected: [],
      loading: false,
      activeRows: newRows.filter((x) => x.isActive).map((n) => n.id),
    });
    // initailData = newRows;
    // })
    // .catch(e => {
    //   console.log(e);
    // });
  };
  sortStateList = (list) => {
    let z = list.sort(function (a, b) {
      let x = a.isActive && a.isActive;
      let y = b.isActive && b.isActive;
      let u = a.modifiedDate && a.modifiedDate;
      let v = b.modifiedDate && b.modifiedDate;
      if (x < y) {
        return 1;
      }
      if (u < v) {
        return -1;
      }
      if (x > y) {
        return -1;
      }
      if (u > v) {
        return 1;
      }
      return 0;
    });
    // console.log(z);
    this.setState({ rows: z });
  };
  exportDatas = (DistributorList) => {
    let list = DistributorList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          DistributorCode: d.distributorCode,
          DistributorName: d.distributorName,
          Active: d.isActive === true ? "Yes" : "No",
          CreatedBy: d.createdBy,
          CreatedDate: d.createdDate,
          ModifiedBy: d.modifiedBy,
          ModifiedDate: d.modifiedDate,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.DistributorList && store.DistributorList.length > 0)
      if (this.state.DistributorList.length !== store.DistributorList.length) {
        !this.state.searchClicked &&
          this.getAllDistributor(store.DistributorList);
      }
    return true;
  }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { DistributorList } = this.state;
    if (event.target.checked) {
      const newSelecteds = DistributorList.filter(
        (x) => x.isActive === true
      ).map((n) => n.id);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, data) => {
    let id = data.id;
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedDistributor: data,
        selecteddistributorID: id,
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        //selectedDistributor: {}
        selectedDistributor: data,
        selecteddistributorID: id,
      });
    }
  };
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };
  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };
  handleSearchdistributor = (handleSearchdistributor) => {
    let DLSearchValue = handleSearchdistributor;
    let distributorName = DLSearchValue.distributorName;
    let distributorCode = DLSearchValue.distributorCode;

    let reqBody = { distributorName, distributorCode };
    pgsService
      .post(`/DistributorsAdvanceSerach`, reqBody, {
        headers: {
          Authorization: "Bearer " + window.localStorage.token,
        },
      })
      .then((r) => {
        this.setState({ searchPopupOpen: false, searchClicked: true, page: 0 });
        this.getAllDistributor(r.data);
      })
      .catch((e) => {
        if (e.response.data.errors[0].message !== undefined) {
          toastr.error(e.response.data.errors[0].message);
        } else {
          toastr.error(e.response.data.errors[0]);
        }
        this.setState({ deletePopupOpen: false });
      });
  };

  handledeleteDistributor = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteDistributor(modifiedBy, deletionIds).then((r) => {
      getDistributorList().then((r) => {
        dispatch({
          type: "getDistributorList",
          newStore: r.data,
        });
        this.getAllDistributor(r.data);
      });
      // this.props.history.push("/letterShop");
      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };

  clearSearch = () => {
    const [{ store }, dispatch] = this.context;
    getDistributorList().then((r) => {
      dispatch({
        type: "getDistributorList",
        newStore: r.data,
      });
      this.getAllDistributor(store.DistributorList);
    });
    this.setState({ loading: true, searchClicked: false });
  };
  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      selectedDistributor,
      DistributorList,
      headCells,
      exportDataValues,
    } = this.state;
    const isSelected = (id) => selected.indexOf(id) !== -1;

    // const emptyRows =
    //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedDistributor={selectedDistributor}
            onSearchClicked={this.onSearchClicked}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            selected={this.state.selected}
            selecteddistributorID={this.state.selecteddistributorID}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handledeleteDistributor={this.handledeleteDistributor}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchdistributor={this.handleSearchdistributor}
            DLSearchValue={this.state.DLSearchValue}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            DistributorList={DistributorList}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            roles={this.props.roles}
          />

          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 8em)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
                  : {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 330px)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                {/* <caption style={{ borderBottom: "1px solid #eaeaea" }}>
                  A barbone structure table example with a caption
                </caption> */}
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />

                <TableBody style={{ overflow: "hidden" }}>
                  {DistributorList.length > 0 ? (
                    stableSort(DistributorList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return (
                          <TableRow
                            hover
                            onClick={(event) => this.handleClick(event, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    // onChange={handleChange('checkedG')}
                                    // value="checkedG"
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.distributorCode}
                                </Typography> */}
                                {row.distributorCode &&
                                row.distributorCode.length > 16 ? (
                                  <Tooltip
                                    title={row.distributorCode}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.distributorCode}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.distributorCode}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.distributorName}
                                </Typography> */}
                                {row.distributorName &&
                                row.distributorName.length > 16 ? (
                                  <Tooltip
                                    title={row.distributorName}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.distributorName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.distributorName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {status}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                // id={labelId}
                              >
                                {row.modifiedDate &&
                                row.modifiedDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.modifiedBy} */}
                                {row.modifiedBy &&
                                row.modifiedBy.length > 16 ? (
                                  <Tooltip
                                    title={row.modifiedBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.modifiedBy}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                // id={labelId}
                              >
                                {row.createdDate &&
                                row.createdDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.createdBy} */}
                                {row.createdBy && row.createdBy.length > 16 ? (
                                  <Tooltip
                                    title={row.createdBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.createdBy}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      {this.state.loading ? (
                        <TableCell colSpan={15} style={{ textAlign: "center" }}>
                          <LinearProgress />
                        </TableCell>
                      ) : (
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                          No result found
                        </TableCell>
                      )}
                      {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 25 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>

          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={DistributorList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(DistributorMaster);
