import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { withRouter } from "react-router-dom";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Autocomplete } from "@material-ui/lab";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import clone from "clone";
import { getPublisherCustomerList, getAllJDR, putJDRList } from "../../../publisher/action"
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { ExportCSV } from "../../../../shared/ExportCSV";
import {
  stableSort,
  getSorting,
} from "../../../../shared/tableCommonFunctions";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const exportToCSV = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

const baseURL = process.env.REACT_APP_PGS_SERVER_MASTER_DATA_MANAGEMENT;
const pgsService = axios.create({
  baseURL,
});
let excelFileData;

function EnhancedTableHead(props) {
  const {
    headCells,
    orderBy,
    order,
    onRequestSort
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
                // style={i === 7 ? { width: "60px" } : i === 8 ? { width: "34px" } : {}}
                style={i === 7 ? { width: "34px" } : {}}

              >
                {/* <span style={{ color: "#306dca" }}>{headCell.label}</span> */}
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

class PubJDR extends React.Component {
  state = {

    deletePopupOpen: false,
    selected: [],
    page: 0,
    sDate: new Date(),
    rowsPerPage: 100,
    rows: [],
    order: "desc",
    orderBy: "modifiedDate",
    excelFileData: [],
    listFromApi: [],
    listForSubmiting: [],
    activeRows: 0,
    customerId: "",
    customer: {},
    customerObjForWilly: {},
    customerList: [],
    isActive: false,
    loading: false,
    dateChanged: false,
    currentOrFutureDate: true,
    disableRefresh: true,
    disableCancel: true,
    headCells: [
      {
        code: "journalUniqueID",
        id: 1,
        numeric: false,
        disablePadding: true,
        label: "Issue Desc",
        show: true,
      },
      {
        code: "jdrDate",
        id: 2,
        numeric: false,
        disablePadding: false,
        label: "Received Date",
        show: true,
      },
      {
        code: "acronym",
        id: 3,
        numeric: false,
        disablePadding: false,
        label: "Acronym",
        show: true,
      },
      {
        code: "volume",
        id: 4,
        numeric: false,
        disablePadding: false,
        label: "Volume",
        show: true,
      },
      {
        code: "issue",
        id: 5,
        numeric: false,
        disablePadding: false,
        label: "Issue",
        show: true,
      },
      {
        code: "suppNumber",
        id: 6,
        numeric: false,
        disablePadding: false,
        label: "Supp",
        show: true,
      },
      {
        code: "partNumber",
        id: 7,
        numeric: false,
        disablePadding: false,
        label: "Part",
        show: true,
      },
      // {
      //   code: "actualPrintRun",
      //   id: 8,
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Print Run",
      //   show: true,
      // },
      {
        code: "actualWeight",
        id: 9,
        numeric: false,
        disablePadding: false,
        label: "WPC",
        show: true,
      },
      {
        code: "status",
        id: 10,
        numeric: false,
        disablePadding: false,
        label: "Status",
        show: true,
      },
    ],
  };

  componentDidMount() {
    this.props.handleSettingHeader("ML Journal Dispatch Report (JDR)");
    this.props.setSelectedList("publisherReportsOpen", "pubReportMLJDR");
    getPublisherCustomerList().then((r) => {
      let oldCus = clone(r.data);
      r.data &&
        this.setState({
          customersList: r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
        });
      oldCus &&
        this.setState({ customerId: oldCus[0].id, customer: oldCus[0], customerObjForWilly: oldCus[0] }, () => {
          this.getListData()
        });
    });
  }
  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;

    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };
  getListData = (onReload) => {
    this.setState({ loading: true, rows: [] })
    let date = this.state.sDate ? moment(this.state.sDate).format("YYYY-MM-DD") : null
    let id = this.state.customerId
    if (onReload) {
      date = moment(new Date()).format("YYYY-MM-DD")
      getAllJDR(date, id).then(r => {
        this.setState({ loading: false })
        this.setState({ rows: r.data, listFromApi: r.data, }, () => {
          this.setExportData();
        })
      })
    }
    else
      if (date && id) {
        getAllJDR(date, id).then(r => {

          this.setState({ loading: false })
          this.setState({ rows: r.data, listFromApi: r.data, }, () => {
            this.setExportData();
          })
        })
      }
  }
  onSearchClicked = () => {
    this.getListData()
    this.setState({ dateChanged: false, disableRefresh: true, });
  }

  setExportData = () => {
    console.log("this.state.rows : ", this.state.rows);
    let excelFileData = clone(this.state.rows);
    console.log("setExportData : ", excelFileData);
    excelFileData &&
      excelFileData.length > 0 &&
      excelFileData.map((d) => {
        d["Issue Desc"] = d["journalUniqueID"];
        d["Received Date"] = d["jdrDate"] ? moment(d["orderDate"]).format("DD/MM/YYYY") : "";
        d["Acronym"] = d["acronym"];
        d["Volume"] = d["volume"];
        d["Issue"] = d["issue"];
        d["Supp"] = d["suppNumber"];
        d["Part"] = d["partNumber"];
        d["WPC"] = d["actualWeight"];
        d["Status"] = d["jrrStatus"];

        delete d["actualPrintRun"];
        delete d["jrrjdrid"];
        delete d["journalUniqueID"];
        delete d["jdrDate"];
        delete d["acronym"];
        delete d["volume"];
        delete d["issue"];
        delete d["suppNumber"];
        delete d["partNumber"];
        delete d["actualWeight"];
        delete d["jrrStatus"];
      });
    this.setState({ excelFileData: excelFileData });
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleAutocompleteCustomer = (e, val) => {
    if (val)
      this.setState({ customerId: val && val.id, customer: val && val });
    else {
      this.setState({ customerId: "", customer: {} });
    }
    this.setState({ rows: [], dateChanged: true, disableCancel: false });

  };
  handleDateChange = (date, name) => {
    this.setState({ [name]: date, rows: [], dateChanged: true });
    if (moment(date) >= moment().startOf('day'))
      this.setState({ currentOrFutureDate: true })
    else
      this.setState({ currentOrFutureDate: false })
    this.setState({ disableCancel: false })
  };
  handleReceivedDateChange = (date, name, i, status) => {
    let newRows = clone(this.state.rows);
    let newListForSubmiting = clone(this.state.listForSubmiting)
    let checkWeekends = this.disableWeekends(date)
    if (!date || date == "Invalid Date") {
      this.setState({ disableRefresh: true })
      return
    }
    // if (status === "Pending" && date && moment(date) < moment().startOf('day')) {
    //   this.setState({ disableRefresh: true })
    //   return
    // }
    // if (status === "Completed" && date && moment(date) > moment().startOf('day')) {
    //   this.setState({ disableRefresh: true })
    //   return
    // }
    if (checkWeekends) {
      newRows[i]["jdrDate"] = null
      newListForSubmiting[i] = {}
      newListForSubmiting[i]["modified"] = false
      this.setState({ rows: newRows, listForSubmiting: newListForSubmiting, disableRefresh: true });
      return
    }
    else newRows[i]["jdrDate"] = date

    if (moment(date).format("DD/MM/YYYY") !== moment(this.state.listFromApi[i].jdrDate).format("DD/MM/YYYY")) {
      newListForSubmiting[i] = newRows[i]
      newListForSubmiting[i]["jdrDate"] = date
      newListForSubmiting[i]["modified"] = true
    }
    else {
      newListForSubmiting[i] = newRows[i]
      newListForSubmiting[i]["jdrDate"] = date
      newListForSubmiting[i]["modified"] = false

    }
    this.setState({ rows: newRows, listForSubmiting: newListForSubmiting });
    let noChangeFound = true
    newListForSubmiting.length > 0 && newListForSubmiting.map((n, ind) => {
      if (n && n.modified)
        noChangeFound = false
    })
    this.setState({ disableRefresh: noChangeFound, disableCancel: false })

  }

  disableWeekends = (date) => {
    if (date)
      return date.getDay() === 0 || date.getDay() === 6;
  }
  onActionBottonClicked = (mode) => {
    if (mode === "cancel") {
      // this.setState({ disableCancel: true, sDate: new Date(), customer: this.state.customerObjForWilly, customerId: this.state.customerObjForWilly.id }, () => {
      //   this.getListData("cancel")
      // })
      this.props.history.push("/home")
    }
    else {
      let reqBody = []
      let loggedInId = localStorage.getItem("loggedInId");

      this.state.listForSubmiting.map(r => {
        if (r && r.modified)
          reqBody.push({
            jrrjdrid: r.jrrjdrid,
            jdrDate: moment(r.jdrDate).format("YYYY-MM-DD"),
            modifiedBy: loggedInId
          })
      })
      putJDRList(reqBody).then(r => {
        // this.setState({ sDate: new Date(), customer: this.state.customerObjForWilly, customerId: this.state.customerObjForWilly.id }, () => {
        //    this.getListData("reload")
        // })
        this.getListData()

      })
    }
    this.setState({ disableRefresh: true, listForSubmiting: [] })
  }
  render() {
    let {
      selected,
      rowsPerPage,
      page,
      rows,
      sDate,
      customersList,
      customer,
      currentOrFutureDate,
      dateChanged,
      disableRefresh,
      disableCancel
    } = this.state;

    const isSelected = (id) => selected.indexOf(id) !== -1;
    return (
      <div>
        <Paper>
          <div style={{ width: "99%" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 9em)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 340px)",
                    margin: "0 auto",
                  }
              }
            ><div style={{ margin: "1.5em" }}>
                <div style={{ display: 'flex' }}>
                  <Autocomplete
                    options={customersList}
                    value={customer}
                    disableClearable
                    onChange={this.handleAutocompleteCustomer}
                    getOptionLabel={option => option.text}
                    style={{ flex: 1, marginRight: "16px" }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Customer"
                        margin="normal"
                        required
                        fullWidth
                      />
                    )}
                  />
                  <div style={{ flexGrow: 1, marginRight: "16px" }}></div>
                  <div style={{ flexGrow: 1, marginRight: "16px" }}></div>
                  <div style={{ flexGrow: 1 }}></div>
                </div>
                <div style={{ display: "flex" }}>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast={false}
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      name="sDate"
                      style={{ flex: 1.15, marginRight: "16px", }}
                      id="searchDate"
                      label="Search Date"
                      value={sDate}
                      onChange={(e) =>
                        this.handleDateChange(e, "sDate")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>

                  <div style={{ flex: 1, }}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!this.state.sDate || !this.state.customerId}
                      style={{ boxShadow: "none", padding: ".5em 6em", marginTop: "25px" }}
                      onClick={this.onSearchClicked}>
                      Search
                    </Button>

                  </div>
                  <div style={{ flex: 1.5, marginLeft: "16px" }}>
                    {this.state.rows && this.state.rows.length > 0 ? (
                      // <ExportCSV
                      //   csvData={this.state.excelFileData}
                      //   fileName={"JDR"}
                      // />
                      <Button
                        onClick={() => { exportToCSV(this.state.excelFileData, "JDR"); }}
                        variant="contained"
                        style={{ boxShadow: "none", padding: ".5em 1em", marginTop: "25px" }}
                        color="primary">
                        Export to Excel
                      </Button>
                    ) : null}
                  </div>
                  <div style={{ flexGrow: 1 }}></div>
                </div>
              </div>
              {!dateChanged && <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)", margin: "1.5em", width: "97%" }}
              >

                <EnhancedTableHead
                  numSelected={selected.length}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                  onRequestSort={this.handleRequestSort}
                  order={this.state.order}
                  orderBy={this.state.orderBy}

                />

                <TableBody style={{ overflow: "hidden" }}>
                  {rows && rows.length > 0 ? (
                    stableSort(rows, getSorting(this.state.order, this.state.orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (row &&
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {row.journalUniqueID &&
                                row.journalUniqueID.length > 16 ? (
                                  <Tooltip
                                    title={row.journalUniqueID}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.journalUniqueID}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.journalUniqueID}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >{
                                // currentOrFutureDate && 
                                // row.jrrStatus === "Pending" ?
                                true ?
                                  <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                  >
                                    <KeyboardDatePicker
                                      autoOk
                                      // disablePast={row.jrrStatus === "Pending" ? true : false}
                                      // disableFuture={row.jrrStatus === "Pending" ? false : true}
                                      required
                                      disableToolbar
                                      variant="inline"
                                      format="dd/MM/yyyy"
                                      margin="normal"
                                      id={"jdrDate" + index}
                                      minDateMessage=""
                                      name="jdrDate"
                                      value={row.jdrDate ? row.jdrDate : null}
                                      shouldDisableDate={this.disableWeekends}
                                      onChange={e => this.handleReceivedDateChange(e, "jdrDate", index, row.jrrStatus)}
                                      KeyboardButtonProps={{
                                        "aria-label": "change date",
                                      }}
                                      style={{
                                        width: "100%",
                                        textAlign: "left",
                                        margin: "0",
                                      }}

                                    />
                                  </MuiPickersUtilsProvider>
                                  : row.jdrDate ? moment(row.jdrDate).format("DD/MM/YYYY") : ""}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {row.acronym}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                              visibility="hidden"
                            >
                              {row.volume}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {row.issue}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                              visibility="hidden"
                            >
                              {row.suppNumber}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {row.partNumber}
                            </TableCell>
                            {/* <TableCell
                            component="th"
                            scope="row"
                            id={labelId}
                            padding="default"
                            className="table-content-right-cell"
                          >
                            {row.actualPrintRun}
                          </TableCell> */}
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-right-cell"
                            >
                              {row.actualWeight}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {row.jrrStatus}
                            </TableCell>

                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={10} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={10} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>

              } </div>
          </div>
          {!dateChanged && <div style={{ padding: "10px 31px" }}>
            <Button
              onClick={(e) => this.onActionBottonClicked("cancel")}
              color="primary"
              variant="contained"
              // disabled={disableCancel}
              style={{ boxShadow: "none" }}
            >
              cancel
              </Button>
            <Button
              onClick={(e) => this.onActionBottonClicked("refresh")}
              color="primary"
              disabled={disableRefresh}
              variant="contained"
              style={{ boxShadow: "none", marginLeft: "1em" }}
            >
              Refresh
              </Button>
          </div>}
          {!dateChanged && <TablePagination
            style={{ marginRight: ".5em" }}
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={rows && rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />}
          {(dateChanged && this.state.sDate) && <div style={{ padding: "10px 31px 2em 31px", }}>
            <div style={{ borderTop: "1px solid #eaeaea", marginBottom: "1em" }}></div>
            <span>Please click the <span style={{ color: "red" }}>SEARCH</span> button to get the result for {moment(this.state.sDate).format("MMMM Do YYYY")}</span>
          </div>}
        </Paper>
      </div>
    );
  }
}
export default withRouter(PubJDR);
