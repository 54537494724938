import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import { Divider } from "@material-ui/core";
import { getStockIn } from "../genericActions";
import { StateContext } from "../../../shared/globalState";
import { printDetails } from "../../../shared/tableCommonFunctions";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { PrintIcon } from "../../../shared/customIcons";
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  let acnArr = props.roles.filter((u) => u.subMenuName === "Stock In");
  let acnObj = acnArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                style={{}}
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewStockIn extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      StockIn: {
        stockID: "",
        stockINID: "",
        stockName: "",
        customerName: "",
        warehouseLocationID: "",
        warehouseLocation: "",
        receivedQty: "",
        usedQty: "",
        receivedDate: "",
        remarks: "",
      },
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let stockINID = this.props.match.params.stockINID;
    getStockIn(stockINID).then((r) => {
      dispatch({
        type: "getStockIn",
        newStore: r,
      });
      this.setState({ StockIn: r });
    });
  }

  render() {
    let { StockIn } = this.state;
    let stockINID = this.props.match.params.stockINID;
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selectedStockINID={stockINID}
            roles={this.props.roles}
          />

          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">Stock In Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Stock</div>
                <div className="view-page-value">{StockIn.stockCode}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Customer</div>
                <div className="view-page-value">{StockIn.customerName}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Warehouse Location</div>
                <div className="view-page-value">
                  {StockIn.warehouseLocation}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Received Qty</div>
                <div className="view-page-value">{StockIn.receivedQty}</div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Used Qty</div>
                <div className="view-page-value">{StockIn.usedQty}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Received Date</div>
                <div className="view-page-value">
                  {moment(StockIn.receivedDate).format("DD/MM/YYYY HH:mm:ss")}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Remarks</div>
                <div className="view-page-value">{StockIn.remarks}</div>
              </div>
            </div>
          </div>
          <Divider />
          <div className="button-wrapper">
            <div style={{ marginLeft: "2em" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                //
                // color="primary"
                onClick={(e) => this.props.history.push("/stockIn")}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewStockIn);
