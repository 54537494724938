import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import IndividualSearchAddDialog from "../ebloOrderPlacement/IndividualSearchAddDialog";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { StateContext } from "../../../shared/globalState";
import { styles } from "../../../shared/styles";
import { resourceValidation } from "../../../shared/resource";
import { getWHLocation, getStockTypeDD } from "../stockaction";
import { getWareLocationDD } from "../../masters/masterActions";
// import { getStatusForPreAlertList, getCustomerForPreAlertList } from "./action";
//import { getCustomerList, serchCustomer } from "../masterActions";
const notRequiredAlpNum10 = new Validator("notRequiredAlpNum10");
const notRequired100AllAllow = new Validator("notRequired100AllAllow");
const maxlength10Alphanumaric = new Validator("maxlength10Alphanumaric");

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
class EmloStockinAdvanceSearch extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    locationID: "",
    location: {},
    createdDateFrom: null,
    createdDateTo: null,
    stockLocationDD: [],
    stockType: [],
    stock: {},
    stockID: "",
    acronym: "",
    issueDescription: "",

    openDialog: false,
  };

  componentDidMount() {
    const [{ store }, dispatch] = this.context;

    getStockTypeDD().then((r) => {
      this.setState({
        stockType:
          r &&
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });
    getWareLocationDD().then((r) => {
      this.setState({
        stockLocationDD:
          r &&
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      open: false,
      location: {},
      createdDateFrom: null,
      createdDateTo: null,
      locationID: "",
      stock: {},
      stockID: "",
      acronym: "",
      issueDescription: "",
    });
  };
  _onKeyPressNoOnly(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleAutocompleteLocation = (e, val) => {
    this.setState({ locationID: val && val.id, location: val && val });
  };
  handleAutocompleteStock = (e, val) => {
    this.setState({ stockID: val && val.id, stock: val && val });
  };

  onSearchClicked = (mode) => {
    let reqBody = {};

    reqBody = {
      acronym: this.state.acronym,
      issueDescription: this.state.issueDescription,
      stockTypeID: this.state.stockID,

      locationID: this.state.locationID,
      createdDateTo: this.state.createdDateTo || "",
      createdDateFrom: this.state.createdDateFrom || "",
    };

    this.props.onSearchClicked(reqBody);
    this.setState({
      open: false,
      location: {},
      createdDateFrom: null,
      createdDateTo: null,
      locationID: "",
      stock: {},
      stockID: "",
      acronym: "",
      issueDescription: "",
    });
  };

  checkButtonDisability = () => {
    let { createdDateFrom, createdDateTo } = this.state;
    if (
      !this.state.stockID &&
      !this.state.acronym &&
      !this.state.issueDescription &&
      !this.state.locationID &&
      !this.state.issueDescription &&
      !(createdDateFrom && createdDateTo)
    )
      return true;
    else return false;
  };

  handleDateChange = (date) => {
    let { createdDateFrom } = this.state;
    createdDateFrom = date && date;
    this.setState({ createdDateFrom });
  };
  handleDateChange1 = (date) => {
    let { createdDateTo } = this.state;
    createdDateTo = date && date;
    this.setState({ createdDateTo });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    let { status, statusList, errors } = this.state;
    const { classes, ...other } = this.props;

    return (
      <React.Fragment>
        <div>
          <Tooltip title="Search">
            <IconButton
              aria-label="Search"
              className={classes.iconHover}
              onClick={this.handleClickOpen}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
            maxWidth="sm"
            fullWidth={true}
          >
            <DialogTitle
              id="customized-dialog-title"
              style={{ padding: "10px 24px" }}
            >
              {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
              Advanced Search
            </DialogTitle>
            <DialogContent
              dividers
              id="preAlertID"
              style={{ paddingTop: "0px!important" }}
            >
              <div>
                {/* {" "}
                {/* <PopupSearch
                  // jobGroupList={jobGroupList}
                  clearJournalInfo={this.clearJournalInfo}
                  handleJournalInfo={this.handleJournalInfo}
                  selectedJournal={this.selectedJournal}
                /> */}
                {/* <Button
                  onClick={this.handleAddJournalClick}
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "172px",
                    padding: ".2em",
                    marginTop: "1em",
                    boxShadow: "none",
                  }}
                >
                  <span>CHOOSE JOURNAL</span>
                </Button>
                {this.state.openDialog && (
                  <IndividualSearchAddDialog
                    title="Journal Pick Up"
                    showDialog={this.state.openDialog}
                    handleAddJournalClose={this.handleAddJournalClose}
                    handleJournalUpdate={this.handleJournalUpdate}
                  />
                )} 
                */}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    label="Acronym"
                    name="acronym"
                    inputProps={{
                      maxLength: 100,
                    }}
                    style={{ marginLeft: "0", flex: 1, marginRight: "16px" }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    value={this.state.acronym}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Issue Description"
                    name="issueDescription"
                    inputProps={{
                      maxLength: 100,
                    }}
                    style={{ marginLeft: "0", flex: 1, marginRight: "16px" }}
                    onKeyPress={this._freetext}
                    margin="normal"
                    className={classes.textField}
                    value={this.state.issueDescription}
                    onChange={this.handleChange}
                  />
                  <Autocomplete
                    options={this.state.stockLocationDD}
                    value={this.state.location}
                    style={{
                      flex: 1,
                    }}
                    onChange={this.handleAutocompleteLocation}
                    getOptionLabel={(option) => option.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Location"
                        style={{
                          width: "100%",
                        }}
                        margin="normal"
                      />
                    )}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Autocomplete
                    options={this.state.stockType}
                    value={this.state.stock}
                    style={{
                      flex: 1,
                      marginRight: "16px",
                    }}
                    onChange={this.handleAutocompleteStock}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Stock Type"
                        style={{
                          width: "100%",
                        }}
                        margin="normal"
                      />
                    )}
                  />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="createdDateFrom"
                      label="Created Date From"
                      onChange={this.handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      style={{ flex: 1, marginRight: "16px" }}
                      value={this.state.createdDateFrom}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disabled={!this.state.createdDateFrom}
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      style={{ flex: 1 }}
                      minDate={this.state.createdDateFrom}
                      id="createdDateTo"
                      label="Created Date To"
                      value={this.state.createdDateTo}
                      onChange={this.handleDateChange1}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </DialogContent>
            <DialogActions style={{ padding: "10px 24px" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={this.handleClose}
              >
                CANCEL
              </Button>
              {this.checkButtonDisability("preAlert") ? (
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  SEARCH
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.onSearchClicked("preAlert")}
                >
                  SEARCH
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(EmloStockinAdvanceSearch));
