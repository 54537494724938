import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import { StateContext } from "../../../shared/globalState";
import { getFliteredDispatchModeList } from "../masterActions";
import Validator from "../../../shared/validator";
import { styles } from "../../../shared/styles";
const validateMaxLength2 = new Validator("maxlength2");
const validateMaxLength100 = new Validator("maxlength100");

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        ></IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    marginRight: "1.5em",
  },
}))(MuiDialogActions);

class DispatchAdvanceSearch extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    dispatchModeName: "",
    dispatchModeCode: "",
    errors: {
      dispatchModeName: false,
      dispatchModeCode: false,
    },
  };
  _onKeyPress(event) {
    const re = /[0-9a-zA-Z ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false, dispatchModeName: "", dispatchModeCode: "" });
  };
  onInputChanged = (e, validate) => {
    let newRrrors = { ...this.state.errors };
    let result = validate(e.target.value);
    newRrrors[e.target.name] = !result;
    this.setState({ [e.target.name]: e.target.value, errors: newRrrors });
  };
  onSearchClicked = () => {
    const dispatch = this.context[1];
    let { dispatchModeCode, dispatchModeName } = this.state;
    let reqBody = { dispatchModeCode, dispatchModeName };
    this.props.onSearchClicked(reqBody);
    this.setState({ open: false, dispatchModeCode: "", dispatchModeName: "" });
  };
  checkButtonDisability = () => {
    let { dispatchModeCode, dispatchModeName } = this.state;
    if (!dispatchModeCode && !dispatchModeName) return true;
    else return false;
  };
  render() {
    let { dispatchModeCode, dispatchModeName } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <Tooltip title="Search">
          <IconButton
            aria-label="Search"
            className={classes.iconHover}
            onClick={this.handleClickOpen}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          maxWidth="md"
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Advanced Search
          </DialogTitle>
          <DialogContent dividers>
            <div className={classes.container}>
              <div>
                <TextField
                  id="standard-required"
                  label="Dispatch Mode Code"
                  name="dispatchModeCode"
                  placeholder="DispatchMode Code"
                  className={classes.textField}
                  margin="normal"
                  value={dispatchModeCode}
                  //error={this.state.errors.dispatchModeCode}
                  onChange={(e) => this.onInputChanged(e, validateMaxLength2)}
                  // helperText={
                  //   errors.dispatchModeCode
                  //     ? resourceValidation.alphaNumaric
                  //     : ""
                  // }
                  inputProps={{
                    maxLength: 3,
                  }}
                  onKeyPress={this._onKeyPress}
                />
                <TextField
                  label="Dispatch Mode Name"
                  id="standard-required"
                  placeholder="Dispatch Mode Name"
                  name="dispatchModeName"
                  className={classes.textField}
                  margin="normal"
                  value={dispatchModeName}
                  //error={this.state.errors.dispatchModeName}
                  onChange={(e) => this.onInputChanged(e, validateMaxLength100)}
                  // helperText={
                  //   errors.dispatchModeName
                  //     ? resourceValidation.alphaNumaric
                  //     : ""
                  // }
                  inputProps={{
                    maxLength: 100,
                  }}
                  onKeyPress={this._onKeyPress}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              //disableElevation
              style={{ boxShadow: "none" }}
              onClick={this.handleClose}
            >
              CANCEL
            </Button>
            {this.checkButtonDisability() ? (
              <Button
                disabled
                //autoFocus
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.onSearchClicked()}
              >
                SEARCH
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                //disableElevation
                style={{ boxShadow: "none" }}
                onClick={(e) => this.onSearchClicked()}
              >
                SEARCH
              </Button>
            )}
          </DialogActions>

          {/* <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={this.handleClose}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={e => this.onSearchClicked()}
              disabled={this.checkButtonDisability() ? true : false}
            >
              SEARCH
            </Button>
          </DialogActions> */}
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(DispatchAdvanceSearch);
