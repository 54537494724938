import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import clone from "clone";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ManageDepartment from "./departmentManage";
import AdvanceSearch from "./departmentSearch";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Typography from "@material-ui/core/Typography";
import { EditIcon, AddIcon, ViewIcon } from "../../../shared/customIcons";
// Include getDeptList for api call
import { getDeptList, getFliteredDeptList } from "../masterActions";
// Include new StateContext
import { StateContext } from "../../../shared/globalState";
import {
  stableSort,
  getSorting,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import { Link } from "react-router-dom";
import { ExportCSV } from "../../../shared/ExportCSV";

function createData(
  id,
  code,
  name,
  modifiedDate,
  modifiedBy,
  createdDate,
  createdBy,
) {
  return {
    id,
    code,
    name,
    modifiedDate,
    modifiedBy,
    createdDate,
    createdBy,
  };
}

// function desc(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function stableSort(array, cmp) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = cmp(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map(el => el[0]);
// }

// function getSorting(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => desc(a, b, orderBy)
//     : (a, b) => -desc(a, b, orderBy);
// }

const headCells = [
  {
    id: "code",
    numeric: false,
    disablePadding: true,
    label: "Department Code",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Department Name",
  },
  {
    id: "modifiedDate",
    numeric: false,
    disablePadding: false,
    label: "Modified Date",
  },
  {
    id: "modifiedBy",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
  {
    id: "createdDate",
    numeric: false,
    disablePadding: false,
    label: "Created Date",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: false,
    label: "Created By",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all departments" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              <span style={{ color: "#306dca" }}>{headCell.label}</span>
              {/* {orderBy === headCell.id ? <span></span> : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderTop: "1px solid #eaeaea",
  },
  // iconHover: {
  //   "&:hover": {
  //     color: blue[800]
  //   }
  // },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        //backgroundColor: lighten(theme.palette.primary.light, 0.85)
        backgroundColor: "transparent",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  const {
    numSelected,
    selectedDepartment,
    selected,
    exportDataValues,
    departmentList,
  } = props;
  let acnArr = props.roles.filter((u) => u.menuName === "Department");
  let acnObj = acnArr[0];

  let expData = clone(departmentList);
  expData.map((d) => {
    d["Department Code"] = d["code"];
    d["Department Name"] = d["name"];

    d["Modified Date"] = moment(d["modifiedDate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    );
    d["Modified By"] = d["modifiedBy"];
    d["Created Date"] = moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss");
    d["Created By"] = d["createdBy"];

    delete d["code"];

    delete d["name"];

    delete d["createdBy"];

    delete d["createdDate"];

    delete d["modifiedBy"];

    delete d["modifiedDate"];
    delete d["id"];
  });
  //console.log(props.searchClicked, "getFliteredDeptList");
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <React.Fragment>
                <div>
                  {acnObj && acnObj.viewAction && (
                    <Tooltip title="View">
                      <Link to={`/view_department/${selected[0]}`}>
                        <IconButton
                          aria-label="view"
                          className={classes.iconHover}
                        >
                          <ViewIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  )}
                  {acnObj && acnObj.editAction && (
                    <Tooltip title="Edit">
                      <Link to={`/edit_department/${selected[0]}`}>
                        <IconButton
                          aria-label="edit"
                          className={classes.iconHover}
                        >
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  )}
                </div>
              </React.Fragment>
            )}
            <Tooltip title="Delete">
              <ManageDepartment
                mode="delete"
                selected={selected}
                data={{
                  departmentName: selectedDepartment.name,
                  departmentCode: selectedDepartment.code,
                  departmentId: selectedDepartment.id
                }}
                onRequestSucceed={props.onRequestSucceed}
                count={props.selected.length}
              />
            </Tooltip>
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {props.searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  acnObj &&
                  acnObj.advanceSearchAction && (
                    <AdvanceSearch
                      onSearchClicked={props.onSearchClicked}
                      onRequestSucceed={props.onRequestSucceed}
                    />
                  )
                )}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  {/* <ManageDepartment
                mode="add"
                onRequestSucceed={props.onRequestSucceed}
              /> */}
                  <IconButton
                    aria-label="add"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/add_department")}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {acnObj && acnObj.exportAction && (
                <ExportCSV csvData={expData} fileName={"Department"} />
              )}
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

class DepartmentMaster extends React.Component {
  static contextType = StateContext;
  state = {
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    refreshData: false,
    departmentList: [],
    selectedDepartment: {},
    searchClicked: false,
    loading: false,
    exportDataValues: [],
  };
  // Modify componentDidMount
  componentDidMount() {
    const dispatch = this.context[1];
    const { store } = this.context;
    //console.log(store)
    this.props.handleSettingHeader("Department");
    this.props.setSelectedList("masterDataOpen", "department");
    this.setState({ loading: true });
    getDeptList(window.localStorage.token).then((r) => {
      this.getAllDepartment(r.data);
    });
  }

  sortStateList = (list) => {
    // let z = list.sort(function (a, b) {
    //   let x = a.isActive && a.isActive;
    //   let y = b.isActive && b.isActive;
    //   let u = a.modifiedDate && a.modifiedDate;
    //   let v = b.modifiedDate && b.modifiedDate;
    //   if (x < y) {
    //     return 1;
    //   }
    //   if (u < v) {
    //     return -1;
    //   }
    //   if (x > y) {
    //     return -1;
    //   }
    //   if (u > v) {
    //     return 1;
    //   }
    //   return 0;
    // });
    // console.log(z);
    this.setState({ rows: list });
  };
  // Modify shouldComponentUpdate
  // shouldComponentUpdate(s, p) {
  //   const { store } = this.context[0];
  //   if (store.deptList && store.deptList.length > 0)
  //     if (
  //       this.state.departmentList.length !== store.deptList.length &&
  //       !this.state.searchClicked
  //     ) {
  //       !this.state.searchClicked && this.getAllDepartment(store.deptList);
  //     }
  //   return true;
  // }
  // Modify onRequestSucceed
  // onRequestSucceed = () => {
  onRequestSucceed = () => {
    getDeptList(window.localStorage.token).then((r) => {
      this.getAllDepartment(r.data);
      // this.exportDatas(r.data);

    });
  };


  // Modify getAllDepartment
  getAllDepartment = (departmentList) => {
    let newRows = [];
    departmentList &&
      departmentList.length > 0 &&
      departmentList.map((d) => {
        return newRows.push(
          createData(
            d.departmentId,
            d.departmentCode,
            d.departmentName,
            d.modifiedDate,
            d.modifiedBy,
            d.createdDate,
            d.createdBy,
          )
        );
      });
    // this.sortStateList(newRows);
    // don't forget to put, selected: [] , in this.setState
    this.setState({ departmentList: newRows, loading: false, selected: [] });
  };
  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { departmentList } = this.state;
    if (event.target.checked) {
      const newSelecteds = departmentList.map((n) => n.id);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id, data) => {
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (newSelected.length === 1) {
      this.setState({ selected: newSelected, selectedDepartment: data });
    } else {
      this.setState({ selected: newSelected, selectedDepartment: {} });
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  onSearchClicked = (reqBody) => {
    this.setState({ searchClicked: true, page: 0 });
    getFliteredDeptList(reqBody).then((r) => {
      this.getAllDepartment(r.data);
    });
  };
  clearSearch = () => {
    getDeptList(window.localStorage.token).then((r) => {
      this.getAllDepartment(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };
  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      selectedDepartment,
      departmentList,
      exportDataValues,
    } = this.state;
    const isSelected = (code) => selected.indexOf(code) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedDepartment={selectedDepartment}
            onSearchClicked={this.onSearchClicked}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            selected={this.state.selected}
            departmentList={departmentList}
            exportDataValues={departmentList}
            roles={this.props.roles}
          />

          <div
            style={{
              width: "99%",
              maxHeight: "calc(100vh - 225px)",
              margin: "0 auto",
              border: "1px solid  rgba(224, 224, 224, 1)",
            }}
          >
            <div
              style={{ maxHeight: " calc(100vh - 225px)", overflow: "auto" }}
            >
              {/* //overflow: "auto" */}
              <Table
                aria-labelledby="tableTitle"
                size="small"
                stickyHeader
                aria-label="sticky table"
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                {/* <caption style={{ borderBottom: "1px solid #eaeaea" }}>
                  A barbone structure table example with a caption
                </caption> */}
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={departmentList.length}
                />

                <TableBody style={{ overflow: "hidden" }}>
                  {departmentList.length > 0 ? (
                    stableSort(departmentList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              this.handleClick(event, row.id, row)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <CustomCheckbox
                                    checked={isItemSelected}
                                  // onChange={handleChange('checkedG')}
                                  // value="checkedG"
                                  />
                                }
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.code && row.code.length > 16 ? (
                                <Tooltip title={row.code} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.code}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {row.code}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.name}
                              </Typography> */}
                              {row.name && row.name.length > 16 ? (
                                <Tooltip title={row.name} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.name}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {row.name}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.modifiedDate !== null ? (
                                  row.modifiedDate &&
                                    row.modifiedDate.length > 16 ? (
                                      <Tooltip
                                        title={moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {moment(row.modifiedDate).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                          )}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    )
                                ) : null}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              {/* <Typography noWrap style={{ fontSize: "14px" }}>
                              
                                {row.modifiedBy}
                              </Typography> */}
                              {row.modifiedBy && row.modifiedBy.length > 16 ? (
                                <Tooltip
                                  title={row.modifiedBy}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.modifiedBy}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {row.modifiedBy}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.createdDate !== null ? (
                                  row.createdDate &&
                                    row.createdDate.length > 16 ? (
                                      <Tooltip
                                        title={moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {moment(row.createdDate).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                          )}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    )
                                ) : null}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                            >
                              {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.createdBy}
                              </Typography> */}
                              {row.createdBy && row.createdBy.length > 16 ? (
                                <Tooltip title={row.createdBy} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.createdBy}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {row.createdBy}
                                  </Typography>
                                )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={10} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={8} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 25 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </div>
          </div>

          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={departmentList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(DepartmentMaster);
