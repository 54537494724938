import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import { addZeros } from "../../../shared/tableCommonFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import clone from "clone";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { ExportCSV } from "../../../shared/ExportCSV";
import moment from "moment";
import {
  SearchIcon,
  ViewColumnIcon,
  ViewIcon,
  EditIcon,
  AddIcon,
} from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import {
  stableSort,
  getSorting,
  DialogActions,
  // CustomCheckbox
} from "../../../shared/tableCommonFunctions";
import { Checkbox } from "@material-ui/core";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import FormGroup from "@material-ui/core/FormGroup";
import {
  getCustomerList,
  getLocationDropdownList,
  getRoutingCustomerList,
} from "../../masters/masterActions";
import {
  getRoutingEMLOEBLOList,
  deleteRoutingEMLOEBLO,
  getFliteredRoutingEMLOEBLOList,
} from "../routingActions";
import { StateContext } from "../../../shared/globalState";
import types from "../routingTypes";
import { Link } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
const columns = [
  {
    name: "Weight From",
    id: 10,
    show: false,
  },
  {
    name: "Weight To",
    id: 11,
    show: false,
  },
  {
    name: "PPI Number",
    id: 12,
    show: false,
  },
  {
    name: "PPI License No",
    id: 13,
    show: false,
  },
  {
    name: "Dispatch Type",
    id: 14,
    show: false,
  },
  {
    name: "Dispatch Mode",
    id: 15,
    show: false,
  },
  {
    name: "Mailing Scheme",
    id: 16,
    show: false,
  },
  {
    name: "Distributor",
    id: 17,
    show: false,
  },
  {
    name: "Return Address",
    id: 18,
    show: false,
  },
  {
    name: "Carrier Sheet Scheme",
    id: 19,
    show: false,
  },
  {
    name: "Carrier Sheet Mode",
    id: 20,
    show: false,
  },
  {
    name: "Active",
    id: 21,
    show: false,
  },
  {
    name: "Created By",
    id: 22,
  },
  {
    name: "Created Date",
    id: 23,
  },
  {
    name: "Modified By",
    id: 24,
  },
  {
    name: "Modified Date",
    id: 25,
    show: false,
  },
];

function createData(
  customerCode,
  customerName,
  locationName,
  subscriptionName,
  addressType,
  noOfOrdersFrom,
  noOfOrdersTo,
  noOfCopiesFrom,
  noOfCopiesTo,
  weightFrom,
  weightTo,
  ppiNumber,
  ppiLicenseNo,
  dispatchTypeName,
  dispatchModeName,
  mailingSchemeName,
  distributorID,
  returnAddress,
  carrierSheetScheme,
  carrierSheetMode,
  isActive,
  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate,
  routingEMLOEBLOID
) {
  return {
    customerCode,
    customerName,
    locationName,
    subscriptionName,
    addressType,
    noOfOrdersFrom,
    noOfOrdersTo,
    noOfCopiesFrom,
    noOfCopiesTo,
    weightFrom,
    weightTo,
    ppiNumber,
    ppiLicenseNo,
    dispatchTypeName,
    dispatchModeName,
    mailingSchemeName,
    distributorID,
    returnAddress,
    carrierSheetScheme,
    carrierSheetMode,
    isActive,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
    routingEMLOEBLOID,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ borderTop: "1px solid #eaeaea", background: "white" }}
          className="table-cell"
        >
          <FormControlLabel
            control={
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                color="primary"
                name="all"
                inputProps={{ "aria-label": "select all Routing EMLO EBLO" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [formValid, setIsformValid] = useState(false);

  const [routingEMLOEBLOSearchValue, setValues] = useState({
    customer: "",
    location: "",
  });
  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  let acnArr = props.roles.filter((u) => u.menuName === "Routing By EMLO EBLO");
  let acnObj = acnArr[0];
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handledeleteroutingEMLOEBLO,
    searchPopupOpen,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchRoutingEMLOEBLO,
    searchClicked,
    customersList,
    LocationList,
    RoutingEMLOEBLOList,
    exportDataValues,
    selected,
    isActive,
    hendleVewColums,
  } = props;
  const selectedRoutingEMLOEBLOID = selected[0];
  const selectedRoutingEMLOEBLO = RoutingEMLOEBLOList.find(
    (x) => x.routingEMLOEBLOID === selectedRoutingEMLOEBLOID
  );
  let expData = clone(RoutingEMLOEBLOList);

  expData &&
    expData.length > 0 &&
    expData.map((d) => {
      d["Customer Code"] = d["customerCode"];
      d["Customer Name"] = d["customerName"];
      d["Location Name"] = d["locationName"];
      d["Subscription Name"] = d["subscriptionName"];
      d["No Of Orders From"] = d["noOfOrdersFrom"];
      d["No Of Orders To"] = d["noOfOrdersTo"];
      d["No Of Copies From"] = d["noOfCopiesFrom"];
      d["No Of Copies To"] = d["noOfCopiesTo"];
      d["Weight From"] = d["weightFrom"];
      d["Weight To"] = d["weightTo"];
      d["Ppi Number"] = d["ppiNumber"];
      d["ppi License No"] = d["ppiLicenseNo"];
      d["Dispatch Type Name"] = d["dispatchTypeName"];
      d["Dispatch Mode Name"] = d["dispatchModeName"];
      d["Mailing Scheme Name"] = d["mailingSchemeName"];
      d["Distributor ID"] = d["distributorID"];
      d["returnAddress"] = d["returnAddress"];
      d["Carrier Sheet Mode"] = d["carrierSheetMode"];
      d["Carrier Sheet Scheme"] = d["carrierSheetScheme"];
      d["isActive"] = d["isActive"];
      d["Modified By"] = d["modifiedBy"];

      d["Modified Date"] = moment(d["modifiedDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );

      d["Created By"] = d["createdBy"];
      d["Created Date"] = moment(d["createdDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );

      delete d["addressType"];
      delete d["carrierSheetMode"];
      delete d["carrierSheetScheme"];
      delete d["createdBy"];
      delete d["createdDate"];
      delete d["customerCode"];
      delete d["customerName"];
      delete d["dispatchModeName"];
      delete d["dispatchTypeName"];
      delete d["distributorID"];
      delete d["isActive"];
      delete d["locationName"];
      delete d["mailingSchemeName"];
      delete d["modifiedBy"];
      delete d["modifiedDate"];
      delete d["noOfCopiesFrom"];
      delete d["noOfCopiesTo"];
      delete d["noOfOrdersFrom"];
      delete d["noOfOrdersTo"];
      delete d["ppiLicenseNo"];
      delete d["ppiNumber"];
      delete d["returnAddress"];
      delete d["routingEMLOEBLOID"];
      delete d["subscriptionName"];
      delete d["weightFrom"];
      delete d["weightTo"];
    });

  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteroutingEMLOEBLO = () => {
    handledeleteroutingEMLOEBLO();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const handleSearchValuesChange = (event, values) => {
    event.persist();
    let name = event.target.name;
    setValues((routingEMLOEBLOSearchValue) => ({
      ...routingEMLOEBLOSearchValue,
      customer: values,
    }));
    //setValues(cptSearchValue => ({ ...cptSearchValue, [event.target.name]: event.target.value }));
    setIsformValid(true);
    switch (name) {
    }
  };
  const handleSearchLocationValuesChange = (event, values) => {
    event.persist();
    let name = event.target.name;
    setValues((routingEMLOEBLOSearchValue) => ({
      ...routingEMLOEBLOSearchValue,
      location: values,
    }));
    setIsformValid(true);
    switch (name) {
    }
  };

  const clearSearchValues = () => {
    let searchValues = routingEMLOEBLOSearchValue;
    searchValues.customer = "";
    searchValues.location = "";
    searchValues = routingEMLOEBLOSearchValue;
  };

  const searchPopup = () => {
    handleSearchRoutingEMLOEBLO(routingEMLOEBLOSearchValue);
    clearSearchValues();
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link
                      to={`/view_RoutingEMLOEBLO/${selectedRoutingEMLOEBLOID}`}
                    >
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <Link
                      to={`/edit_RoutingEMLOEBLO/${selectedRoutingEMLOEBLOID}`}
                    >
                      <IconButton
                        aria-label="edit"
                        className={classes.iconHover}
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={handleClickOpen}
                  disabled={!isActive}
                >
                  <DeleteIcon className={classes.iconHover} />
                </IconButton>
              </Tooltip>
            )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete Routing EMLO EBLO
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1
                      ? " ?"
                      : selectedRoutingEMLOEBLO.customerName + " ?"}
                    {/* {selectedRoutingEMLOEBLO.customerName} */}
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteroutingEMLOEBLO}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  <form onSubmit={searchPopup}>
                    {acnObj && acnObj.advanceSearchAction && (
                      <Tooltip title="Search">
                        <IconButton
                          aria-label="Search"
                          className={classes.iconHover}
                          onClick={handleSearchOpen}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      onClose={handleSearchPopupClose}
                      aria-labelledby="customized-dialog-title"
                      open={searchPopupOpen}
                      maxWidth="md"
                    >
                      <DialogTitle
                        id="customized-dialog-title"
                        onClose={handleSearchPopupClose}
                      >
                        Advanced Search
                  </DialogTitle>
                      <DialogContent dividers>
                        <React.Fragment>
                          <div style={{ minWidth: "400px", display: "flex" }}>
                            <Autocomplete
                              autoComplete
                              className={classes.comboBox}
                              id="customer"
                              includeInputInList
                              value={routingEMLOEBLOSearchValue.customer}
                              options={customersList}
                              onChange={handleSearchValuesChange}
                              style={{ marginRight: "1em" }}
                              //onChange={handleSearchInput}
                              getOptionLabel={(option) => option.text}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Customer"
                                  className={classes.textField}
                                  name="customer"
                                  margin="normal"
                                />
                              )}
                            />
                            <Autocomplete
                              autoComplete
                              className={classes.comboBox}
                              id="location"
                              includeInputInList
                              value={routingEMLOEBLOSearchValue.location}
                              options={LocationList}
                              onChange={handleSearchLocationValuesChange}
                              //onChange={handleSearchInput}
                              getOptionLabel={(option) => option.text}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Location"
                                  className={classes.textField}
                                  name="location"
                                  margin="normal"
                                />
                              )}
                            />
                          </div>
                        </React.Fragment>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={handleSearchClose}
                        >
                          CANCEL
                    </Button>
                        {!routingEMLOEBLOSearchValue.customer &&
                          !routingEMLOEBLOSearchValue.location ? (
                            <Button
                              disabled
                              variant="contained"
                              color="primary"
                              style={{ boxShadow: "none" }}
                              onClick={searchPopup}
                            >
                              SEARCH
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ boxShadow: "none" }}
                              onClick={searchPopup}
                            >
                              SEARCH
                            </Button>
                          )}
                      </DialogActions>
                    </Dialog>
                  </form>
                )}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="add"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/add_RoutingEMLOEBLO")}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {acnObj && acnObj.exportAction && (
                <Tooltip title="Download">
                  <ExportCSV csvData={expData} fileName={"RoutingByEMLOEBLO"} />
                </Tooltip>
              )}

              {/* <Tooltip title="Download">
              <IconButton aria-label="download" className={classes.iconHover}>
                <DownloadIcon />
              </IconButton>
            </Tooltip> */}
              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                hendleVewColums={hendleVewColums}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class RoutingEMLOEBLOMaster extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    activeRows: 0,
    isActive: false,
    RoutingEMLOEBLOList: [],
    refreshData: false,
    selectedRoutingEMLOEBLO: {},
    //selectedRoutingEMLOEBLOID: 1,
    loading: false,
    searchPopupOpen: false,
    routingEMLOEBLOSearchValues: {},
    searchClicked: false,
    customersList: [],
    LocationList: [],
    selectedShowColumns: [],
    exportDataValues: [],
    headCells: [
      {
        code: "customerCode",
        id: "1",
        numeric: false,
        disablePadding: true,
        label: "Customer Code",
        show: true,
      },
      {
        code: "customerName",
        id: "2",
        numeric: false,
        disablePadding: true,
        label: "Customer",
        show: true,
      },
      {
        code: "locationName",
        id: "3",
        numeric: false,
        disablePadding: false,
        label: "Location",
        show: true,
      },
      {
        code: "subscriptionName",
        id: "4",
        numeric: false,
        disablePadding: false,
        label: "Subscription",
        show: true,
      },
      {
        code: "addressType",
        id: "5",
        numeric: false,
        disablePadding: false,
        label: "PO BOX",
        show: true,
      },
      {
        code: "noOfOrdersFrom",
        id: "6",
        numeric: false,
        disablePadding: false,
        label: "No Of Orders From",
        show: true,
      },
      {
        code: "noOfOrdersTo",
        id: "7",
        numeric: false,
        disablePadding: false,
        label: "No Of Orders To",
        show: true,
      },
      {
        code: "noOfCopiesFrom",
        id: "8",
        numeric: false,
        disablePadding: false,
        label: "No Of Copies From",
        show: true,
      },
      {
        code: "noOfCopiesTo",
        id: "9",
        numeric: false,
        disablePadding: false,
        label: "No Of Copies To",
        show: true,
      },
      {
        code: "weightFrom",
        id: "10",
        numeric: false,
        disablePadding: false,
        label: "Weight From (Kg)",
        show: true,
      },
      {
        code: "weightTo",
        id: "11",
        numeric: false,
        disablePadding: false,
        label: "Weight To (Kg)",
        show: true,
      },
      {
        code: "ppiNumber",
        id: "12",
        numeric: false,
        disablePadding: false,
        label: "Route PPI",
        show: true,
      },
      {
        code: "ppiLicenseNo",
        id: "13",
        numeric: false,
        disablePadding: false,
        label: "PPI License No",
        show: true,
      },
      {
        code: "dispatchTypeName",
        id: "14",
        numeric: false,
        disablePadding: false,
        label: "Dispatch Type",
        show: true,
      },
      {
        code: "dispatchModeName",
        id: "15",
        numeric: false,
        disablePadding: false,
        label: "Dispatch Mode",
        show: true,
      },
      {
        code: "mailingScheme",
        id: "16",
        numeric: false,
        disablePadding: false,
        label: "Mailing Scheme",
        show: true,
      },
      {
        code: "distributorName",
        id: "17",
        numeric: false,
        disablePadding: false,
        label: "Distributor",
        show: true,
      },
      {
        code: "returnAddress",
        id: "18",
        numeric: false,
        disablePadding: false,
        label: "Return Address",
        show: true,
      },
      {
        code: "carrierSheetScheme",
        id: "19",
        numeric: false,
        disablePadding: false,
        label: "Carrier Sheet Scheme",
        show: true,
      },
      {
        code: "carrierSheetMode",
        id: "20",
        numeric: false,
        disablePadding: false,
        label: "Carrier Sheet Mode",
        show: true,
      },
      {
        code: "isActive",
        id: "21",
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true,
      },
      {
        code: "createdBy",
        id: "22",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },

      {
        code: "createdDate",
        id: "23",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        code: "modifiedBy",
        id: "24",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        code: "modifiedDate",
        id: "25",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
    ],
  };
  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Routing EMLO EBLO");
    this.props.setSelectedList("masterDataOpen", "routingEMLOEBLO");
    this.setState({ loading: true });

    getRoutingCustomerList(window.localStorage.token).then((r) => {
      let list = r.data;

      list &&
        list.length > 0 &&
        this.setState({
          customersList: list.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
        });
    });
    getLocationDropdownList().then((r) => {
      let list = r.data;
      list &&
        list.length > 0 &&
        this.setState({
          // LocationList: r.data.sort((a, b) =>
          LocationList: list.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
        });
    });

    getRoutingEMLOEBLOList().then((r) => {
      dispatch({
        type: types.RoutingEMLOEBLOList,
        newStore: r.data,
      });

      this.getAllRoutingEMLOEBLO(r.data);
      this.exportCptDatas(r.data);
    });
  }

  onRequestSucceed = () => {
    const list = this.context;
    setTimeout(() => {
      let RoutingEMLOEBLOs = list.state.RoutingEMLOEBLOList;
      this.getAllRoutingEMLOEBLO(RoutingEMLOEBLOs);
    }, 1700);
  };
  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };
  exportCptDatas = (RoutingEMLOEBLOList) => {
    let list = RoutingEMLOEBLOList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          customerCode: d.customerCode,
          customerName: d.customerName,
          locationName: d.locationName,
          subscriptionName: d.subscriptionName,
          addressType: d.addressType,
          noOfOrdersFrom: d.noOfOrdersFrom,
          noOfOrdersTo: d.noOfOrdersTo,
          noOfCopiesFrom: d.noOfCopiesFrom,
          noOfCopiesTo: d.noOfCopiesTo,
          weightFrom: d.weightFrom,
          weightTo: d.weightTo,
          ppiNumber: d.ppiNumber,
          ppiLicenseNo: d.ppiLicenseNo,
          dispatchTypeName: d.dispatchTypeName,
          dispatchModeName: d.dispatchModeName,
          mailingSchemeName: d.mailingSchemeName,
          distributorID: d.distributorID,
          returnAddress: d.returnAddress,
          carrierSheetScheme: d.carrierSheetScheme,
          carrierSheetMode: d.carrierSheetMode,
          isActive: d.isActive === true ? "Yes" : "No",
          createdBy: d.createdBy,
          createdDate: d.createdDate,
          modifiedBy: d.modifiedBy,
          modifiedDate: d.modifiedDate,
        });
      });
    this.setState({ exportDataValues: rows });
  };
  getAllRoutingEMLOEBLO = (RoutingEMLOEBLOList) => {
    let list = RoutingEMLOEBLOList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.customerCode,
            d.customerName,
            d.locationName,
            d.subscriptionName,
            d.addressType,
            d.noOfOrdersFrom,
            d.noOfOrdersTo,
            d.noOfCopiesFrom,
            d.noOfCopiesTo,
            d.weightFrom,
            d.weightTo,
            d.ppiNumber,
            d.ppiLicenseNo,
            d.dispatchTypeName,
            d.dispatchModeName,
            d.mailingSchemeName,
            d.distributorName,
            d.returnAddress,
            d.carrierSheetScheme,
            d.carrierSheetMode,
            d.isActive,
            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate,
            d.routingEMLOEBLOID
          )
        );
      });
    this.setState({
      RoutingEMLOEBLOList: rows,
      loading: false,
      selected: [],
      activeRows: rows
        .filter((x) => x.isActive)
        .map((n) => n.routingEMLOEBLOID),
    });
    this.sortStateList(rows);
  };

  sortStateList = (list) => {
    let z = list.sort(function (a, b) {
      let x = a.isActive && a.isActive;
      let y = b.isActive && b.isActive;
      let u = a.modifiedDate && a.modifiedDate;
      let v = b.modifiedDate && b.modifiedDate;
      if (x < y) {
        return 1;
      }
      if (u < v) {
        return -1;
      }
      if (x > y) {
        return -1;
      }
      if (u > v) {
        return 1;
      }
      return 0;
    });
    // console.log(z);
    this.setState({ rows: z });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];

    if (store.RoutingEMLOEBLOList && store.RoutingEMLOEBLOList.length > 0)
      if (
        this.state.RoutingEMLOEBLOList.length !==
        store.RoutingEMLOEBLOList.length
      ) {
        this.getAllRoutingEMLOEBLO(store.RoutingEMLOEBLOList);
      }
    return true;
  }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { RoutingEMLOEBLOList } = this.state;
    if (event.target.checked) {
      const newSelecteds = RoutingEMLOEBLOList.filter(
        (x) => x.isActive === true
      ).map((n) => n.routingEMLOEBLOID);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, data) {
    let routingEMLOEBLOID = data.routingEMLOEBLOID;
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(routingEMLOEBLOID);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, routingEMLOEBLOID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedRoutingEMLOEBLO: data,
        selectedRoutingEMLOEBLOID: routingEMLOEBLOID,
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        // selectedRoutingEMLOEBLO: {}
        selectedRoutingEMLOEBLO: data,
        selectedRoutingEMLOEBLOID: routingEMLOEBLOID,
      });
    }
  }
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchRoutingEMLOEBLO = (handleSearchRoutingEMLOEBLO) => {
    let routingEMLOEBLOSearchValue = handleSearchRoutingEMLOEBLO;
    const dispatch = this.context[1];
    let customerID = routingEMLOEBLOSearchValue.customer && routingEMLOEBLOSearchValue.customer.id ? routingEMLOEBLOSearchValue.customer.id : 0;
    let locationID = routingEMLOEBLOSearchValue.location && routingEMLOEBLOSearchValue.location.id ? routingEMLOEBLOSearchValue.location.id : 0;
    let reqBody = { customerID, locationID };

    getFliteredRoutingEMLOEBLOList(reqBody).then((r) => {
      dispatch({
        type: "getFliteredRoutingEMLOEBLOList",
        newStore: r.data,
      });
      this.getAllRoutingEMLOEBLO(r.data);
      this.setState({ searchPopupOpen: false, searchClicked: true, page: 0 });
    });
  };

  handledeleteroutingEMLOEBLO = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteRoutingEMLOEBLO(modifiedBy, deletionIds).then((r) => {
      getRoutingEMLOEBLOList().then((r) => {
        dispatch({
          type: types.RoutingEMLOEBLOList,
          newStore: r.data,
        });
        this.getAllRoutingEMLOEBLO(r.data);
      });

      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };

  clearSearch = () => {
    const dispatch = this.context[1];
    getRoutingEMLOEBLOList().then((r) => {
      dispatch({
        type: types.RoutingEMLOEBLOList,
        newStore: r.data,
      });
      this.getAllRoutingEMLOEBLO(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      RoutingEMLOEBLOList,
      headCells,
      exportDataValues,
    } = this.state;
    const isSelected = (routingEMLOEBLOID) =>
      selected.indexOf(routingEMLOEBLOID) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handledeleteroutingEMLOEBLO={this.handledeleteroutingEMLOEBLO}
            selectedRoutingEMLOEBLO={this.state.selectedRoutingEMLOEBLO}
            selectedRoutingEMLOEBLOID={this.state.selectedRoutingEMLOEBLOID}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchRoutingEMLOEBLO={this.handleSearchRoutingEMLOEBLO}
            routingEMLOEBLOSearchValue={this.state.routingEMLOEBLOSearchValues}
            searchClicked={this.state.searchClicked}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            customersList={this.state.customersList}
            LocationList={this.state.LocationList}
            exportDataValues={exportDataValues}
            RoutingEMLOEBLOList={this.state.RoutingEMLOEBLOList}
            isActive={this.state.isActive}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ whiteSpace: "nowrap" }}
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {RoutingEMLOEBLOList.length > 0 ? (
                    stableSort(RoutingEMLOEBLOList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(
                          row.routingEMLOEBLOID
                        );
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        let { status1 } = "";
                        if (row.addressType === true) {
                          status1 = "Yes";
                        } else {
                          status1 = "No";
                        }
                        //console.log(RoutingEMLOEBLOList);
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              this.handleClick(
                                event,
                                row
                                // row.routingEMLOEBLOID,
                                // row.customerName
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            //key={row.routingEMLOEBLOID}
                            key={index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.customerName}
                                </Typography> */}
                                {row.customerCode &&
                                  row.customerCode.length > 16 ? (
                                    <Tooltip
                                      title={row.customerCode}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.customerCode}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.customerName}
                                </Typography> */}
                                {row.customerName &&
                                  row.customerName.length > 16 ? (
                                    <Tooltip
                                      title={row.customerName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.customerName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.customerName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.locationName} */}
                                {row.locationName &&
                                  row.locationName.length > 16 ? (
                                    <Tooltip
                                      title={row.locationName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.locationName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.locationName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.subscriptionName} */}
                                {row.subscriptionName &&
                                  row.subscriptionName.length > 16 ? (
                                    <Tooltip
                                      title={row.subscriptionName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.subscriptionName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.subscriptionName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.addressType} */}
                                {status1}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* <Typography noWrap>
                                  {row.noOfOrdersFrom}
                                </Typography> */}
                                {row.noOfOrdersFrom &&
                                  row.noOfOrdersFrom.length > 16 ? (
                                    <Tooltip
                                      title={row.noOfOrdersFrom}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.noOfOrdersFrom}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noOfOrdersFrom}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.noOfOrdersTo} */}
                                {row.noOfOrdersTo &&
                                  row.noOfOrdersTo.length > 16 ? (
                                    <Tooltip
                                      title={row.noOfOrdersTo}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.noOfOrdersTo}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noOfOrdersTo}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.noOfCopiesFrom} */}
                                {row.noOfCopiesFrom &&
                                  row.noOfCopiesFrom.length > 16 ? (
                                    <Tooltip
                                      title={row.noOfCopiesFrom}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.noOfCopiesFrom}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noOfCopiesFrom}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {/* {row.noOfCopiesTo} */}
                                {row.noOfCopiesTo &&
                                  row.noOfCopiesTo.length > 16 ? (
                                    <Tooltip
                                      title={row.noOfCopiesTo}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.noOfCopiesTo}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noOfCopiesTo}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {addZeros(row.weightFrom.toString(), 2)}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {addZeros(row.weightTo.toString(), 2)}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.ppiNumber} */}
                                {row.ppiNumber && row.ppiNumber.length > 16 ? (
                                  <Tooltip
                                    title={row.ppiNumber}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.ppiNumber}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.ppiNumber}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.ppiLicenseNo} */}
                                {row.ppiLicenseNo &&
                                  row.ppiLicenseNo.length > 16 ? (
                                    <Tooltip
                                      title={row.ppiLicenseNo}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.ppiLicenseNo}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.ppiLicenseNo}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.dispatchTypeName} */}
                                {row.dispatchTypeName &&
                                  row.dispatchTypeName.length > 16 ? (
                                    <Tooltip
                                      title={row.dispatchTypeName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.dispatchTypeName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dispatchTypeName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.dispatchModeName} */}
                                {row.dispatchModeName &&
                                  row.dispatchModeName.length > 16 ? (
                                    <Tooltip
                                      title={row.dispatchModeName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.dispatchModeName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dispatchModeName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[15].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.mailingSchemeName} */}
                                {row.mailingSchemeName &&
                                  row.mailingSchemeName.length > 16 ? (
                                    <Tooltip
                                      title={row.mailingSchemeName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.mailingSchemeName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.mailingSchemeName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[16].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.distributorID} */}
                                {row.distributorID &&
                                  row.distributorID.length > 16 ? (
                                    <Tooltip
                                      title={row.distributorID}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.distributorID}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.distributorID}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[17].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.returnAddress} */}
                                {row.returnAddress &&
                                  row.returnAddress.length > 16 ? (
                                    <Tooltip
                                      title={row.returnAddress}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.returnAddress}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.returnAddress}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[18].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.carrierSheetScheme} */}
                                {row.carrierSheetScheme &&
                                  row.carrierSheetScheme.length > 16 ? (
                                    <Tooltip
                                      title={row.carrierSheetScheme}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.carrierSheetScheme}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.carrierSheetScheme}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[19].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.carrierSheetMode} */}
                                {row.carrierSheetMode &&
                                  row.carrierSheetMode.length > 16 ? (
                                    <Tooltip
                                      title={row.carrierSheetMode}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.carrierSheetMode}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.carrierSheetMode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[20].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                id={labelId}
                              >
                                {status}
                              </TableCell>
                            )}
                            {this.state.headCells[21].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                id={labelId}
                                className="table-content-cell"
                              >
                                {/* {row.createdBy} */}
                                {row.createdBy && row.createdBy.length > 16 ? (
                                  <Tooltip
                                    title={row.createdBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[22].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                id={labelId}
                                className="table-content-cell"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[23].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                id={labelId}
                              >
                                {/* {row.modifiedBy} */}
                                {row.modifiedBy &&
                                  row.modifiedBy.length > 16 ? (
                                    <Tooltip
                                      title={row.modifiedBy}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedBy}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[24].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                id={labelId}
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={8} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={RoutingEMLOEBLOList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(RoutingEMLOEBLOMaster);
