import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import FileCopyTwoToneIcon from "@material-ui/icons/FileCopyTwoTone";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  LinearProgress,
} from "@material-ui/core";

import { StateContext } from "../../../shared/globalState";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { EditIcon, ViewIcon } from "../../../shared/customIcons";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { GetOWCByWOIDView, deleteOWC } from "./action";
import ReportViewer from "../../app/ReportViewer";

function EnhancedTableHead(props) {
  const { order, orderBy, headCells } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={i !== headCells.length ? "headcells" : "headcell"}
              >
                <TableSortLabel
                  active={orderBy === headCell.code}
                  direction={order}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  return <React.Fragment></React.Fragment>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

class ViewOWCReport extends React.Component {
  static contextType = StateContext;
  state = {
    emptyList: true,
    customer: "",
    workOrder: "",
    title: "",
    status: "",
    list: [],
    owcReportMode: "",
    selectedReportIndex: 0,
    headCells: [
      {
        code: "owcCode",
        id: 1,
        numeric: false,
        disablePadding: false,
        label: "OWC Code",
        show: true,
      },
      {
        code: "preparedBy",
        id: 2,
        numeric: false,
        disablePadding: true,
        label: "Prepared By",
        show: true,
      },
      {
        code: "generateDate",
        id: 3,
        numeric: false,
        disablePadding: false,
        label: "Prepared Date",
        show: true,
      },

      {
        code: "totalBillingAmount",
        id: 4,
        numeric: false,
        disablePadding: false,
        label: "Total Billing Amount($S)",
        show: true,
      },
      {
        id: 5,
        code: "action",
        numeric: false,
        disablePadding: false,
        label: "Action",
        show: true,
      },
    ],
    showPopup: false,
  };

  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  componentDidMount() {
    this.props.handleSettingHeader("Work Order");
    this.props.setSelectedList("genericSystemOpen", "workOrder");
    this.props.handleDrawerClose();
    let wkOrderId = "";
    if (process.browser) wkOrderId = window.location.pathname.split("/")[2];
    GetOWCByWOIDView(wkOrderId).then((r) => {
      this.setState({
        customer: r.custmerName,
        workOrder: r.workOrderNumber,
        title: r.orderTitle,
        status: r.status ? r.status : "",
        list: r.owcList,
      });
      if (r.owcList && r.owcList.length > 0) {
        this.setState({ emptyList: false });
      }
    });
    // this.getOWCList
  }
  onEditClicked = (id) => {
    let wkOrderId = "";
    if (process.browser) wkOrderId = window.location.pathname.split("/")[2];
    this.props.history.push({
      pathname: "/owcReport",
      search: "?owcHeaderID=" + id,
      state: { owcReportMode: "edit", wkOrderId: wkOrderId },
    });
  };
  onColneClicked = (id) => {
    let wkOrderId = "";
    if (process.browser) wkOrderId = window.location.pathname.split("/")[2];
    this.props.history.push({
      pathname: "/owcReport",
      search: "?owcHeaderID=" + id,
      state: { owcReportMode: "clone", wkOrderId: wkOrderId },
    });

    // let wkOrderId = window.location.pathname.split("/")[2];
    // console.log(wkOrderId);
    // GetWCByWOIDClone(id).then(r => {
    //   console.log(r);
    // });
  };

  onViewClicked = (id) => {
    let wkOrderId = "";
    if (process.browser) wkOrderId = window.location.pathname.split("/")[2];
    this.props.history.push({
      pathname: "/owcReport",
      search: "?owcHeaderID=" + id,
      state: { owcReportMode: "view", wkOrderId: wkOrderId },
    });
  };

  onAddClicked = (id, newClone) => {
    let wkOrderId = "";
    if (process.browser) wkOrderId = window.location.pathname.split("/")[2];
    this.props.history.push({
      pathname: "/owcReport",
      search: "?owcHeaderID=" + id,
      state: { owcReportMode: "add", wkOrderId: wkOrderId, newClone },
    });
  };

  onDeleteClicked = (id) => {
    let wkOrderId = "";
    if (process.browser) wkOrderId = window.location.pathname.split("/")[2];
    deleteOWC(id, this.props.history, wkOrderId).then((r) => {
      GetOWCByWOIDView(wkOrderId).then((r) => {
        this.setState({
          customer: r.custmerName,
          workOrder: r.workOrderNumber,
          title: r.orderTitle,
          status: r.status ? r.status : "",
          list: r.owcList,
        });
      });
      GetOWCByWOIDView(wkOrderId).then((r) => {
        this.setState({
          customer: r.custmerName,
          workOrder: r.workOrderNumber,
          title: r.orderTitle,
          status: r.status ? r.status : "",
          list: r.owcList,
        });
      });
    });
  };

  handleShowPopup = (e, index) => {
    this.setState({
      showPopup: true,
      selectedReportIndex: index,
    });
  };

  handleHidePopup = () => {
    this.setState({
      showPopup: false,
      selectedReportIndex: 0,
    });
  };

  render() {
    let { customer, workOrder, title, status, list, headCells } = this.state;
    let wkOrderId = "";
    if (process.browser) wkOrderId = window.location.pathname.split("/")[2];

    return (
      <React.Fragment>
        <Paper>
          <div
            className="view-page-container"
            id="printable"
            style={{ paddingTop: "1em" }}
          >
            <div style={{ display: "flex", marginTop: "1em" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  //   marginTop: "1em",
                  background: "#f2f7fd",
                  padding: ".5em",
                  color: "#3982ea",
                  borderRadius: "4px",
                  width: "100%",
                  fontSize: "15px",
                  fontWeight: "400",
                }}
              >
                <div>
                  <span>Customer : </span>
                  <span style={{ fontWeight: "500" }}>{customer}</span>
                </div>
                <div>
                  <span>Work Order : </span>
                  <span style={{ fontWeight: "500" }}>{workOrder}</span>
                </div>
                <div>
                  <span>Title/Desc : </span>
                  <span style={{ fontWeight: "500" }}>{title}</span>
                </div>
                <div>
                  <span>Status : </span>
                  <span style={{ fontWeight: "500" }}>{status}</span>
                </div>
              </div>
            </div>
            <div
              // onClick={e =>
              //   this.props.history.push(`/owcReport/${wkOrderId}`)
              // }
              onClick={
                // this.props.history.push(`/owcReport/${wkOrderId}`)
                () => this.onAddClicked(wkOrderId)
              }
              style={{
                background: this.state.status == "Open" ? "#3982ea" : "grey",
                color: this.state.status == "Open" ? "#fff" : "white",
                borderRadius: "4px",
                padding: ".5em",
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "1em",
                width: "auto",
                display: "inline-block",
                cursor: this.state.status == "Open" ? "pointer" : "default",
                pointerEvents: this.state.status == "Open" ? "auto" : "none ",
              }}
            >
              Generate OWC Report
            </div>
            {this.state.emptyList && (
              <div
                style={{
                  height: "140px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#494848",
                  fontSize: "14px",
                }}
              >
                Initiate the Generate OWC Report to display
              </div>
            )}
            <div style={{ marginTop: "1em" }}>
              <Paper>
                <EnhancedTableToolbar
                  headCells={headCells}
                  hendleVewColums={this.hendleVewColums}
                />
                <Table
                  aria-labelledby="tableTitle"
                  size={"small"}
                  aria-label="enhanced table"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <EnhancedTableHead
                    onRequestSort={this.handleRequestSort}
                    rowCount={list.length}
                    headCells={this.state.headCells}
                  />
                  <TableBody style={{ overflow: "hidden" }}>
                    {list.length > 0 ? (
                      list.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { statusName } = "";
                        return (
                          <TableRow
                            tabIndex={-1}
                            key={row.index}
                            // selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                align="left"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.owcCode}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.preparedBy}
                                </Typography>
                              </TableCell>
                            )}

                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {moment(row.generateDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                align="right"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.totalBillingAmount.toFixed(2)}
                                </Typography>
                              </TableCell>
                            )}
                            {
                              <TableCell
                                component="th"
                                scope="row"
                                id={labelId}
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography
                                  noWrap
                                  style={{
                                    fontSize: "14px",
                                    display: "flex",
                                    alignItems: "baseline",
                                  }}
                                >
                                  <Tooltip title="Report">
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        color: "#6c6b6b",
                                      }}
                                    >
                                      <InsertDriveFileIcon
                                        style={{
                                          color: "#6c6b6b",
                                          marginBottom: "1px",
                                          fontSize: "21px",
                                        }}
                                        onClick={(e) =>
                                          this.handleShowPopup(e, index)
                                        }
                                      />
                                      {/* </a> */}
                                    </span>
                                  </Tooltip>
                                  {this.state.showPopup &&
                                    index == this.state.selectedReportIndex && (
                                      <ReportViewer
                                        url={
                                          `${window.localStorage.ReportURLByID}` +
                                          "OWCREPORT&IsDirectPrint=True&ReportParam=OWCHeaderID&Param1=" +
                                          `${row && row.owcHeaderID}`
                                        }
                                        showPopup={this.state.showPopup}
                                        onClose={() => this.handleHidePopup()}
                                        title={"OWC"}
                                        handleHidePopup={() =>
                                          this.handleHidePopup()
                                        }
                                      />
                                    )}
                                  <Tooltip title="View">
                                    <span
                                      style={{
                                        cursor: " pointer",
                                        color: "#6c6b6b",
                                      }}
                                      onClick={() =>
                                        this.onViewClicked(
                                          row && row.owcHeaderID
                                        )
                                      }
                                    >
                                      <ViewIcon
                                        style={{
                                          color:
                                            this.state.status == "Open"
                                              ? "#6c6b6b"
                                              : "rgba(0, 0, 0, 0.26)",
                                        }}
                                      />
                                    </span>
                                  </Tooltip>
                                  <Tooltip title="Clone">
                                    <span
                                      style={{
                                        cursor:
                                          this.state.status == "Open"
                                            ? " pointer"
                                            : "default",
                                        color:
                                          this.state.status == "Open"
                                            ? "#6c6b6b"
                                            : "rgba(0, 0, 0, 0.26)",
                                        pointerEvents:
                                          this.state.status == "Open"
                                            ? "auto"
                                            : "none",
                                      }}
                                      onClick={() =>
                                        // this.onColneClicked(
                                        //   row && row.owcHeaderID
                                        // )
                                        this.onAddClicked(wkOrderId, "newClone")
                                      }
                                    >
                                      <FileCopyTwoToneIcon
                                        style={{
                                          color:
                                            this.state.status == "Open"
                                              ? "#6c6b6b"
                                              : "rgba(0, 0, 0, 0.26)",
                                        }}
                                      />
                                    </span>
                                  </Tooltip>
                                  {/* <Tooltip title="Edit">
                                    <span
                                      style={{
                                        cursor:
                                          this.state.status == "Open"
                                            ? " pointer"
                                            : "default",
                                        color:
                                          this.state.status == "Open"
                                            ? "#6c6b6b"
                                            : "rgba(0, 0, 0, 0.26)",
                                        pointerEvents:
                                          this.state.status == "Open"
                                            ? "auto"
                                            : "none",
                                      }}
                                      onClick={() =>
                                        this.onEditClicked(
                                          row && row.owcHeaderID
                                        )
                                      }
                                    >
                                      <EditIcon
                                        style={{
                                          color:
                                            this.state.status == "Open"
                                              ? "#6c6b6b"
                                              : "rgba(0, 0, 0, 0.26)",
                                        }}
                                      />
                                    </span>
                                  </Tooltip> */}
                                  <Tooltip title="Delete">
                                    <DeleteIcon
                                      style={{
                                        cursor:
                                          this.state.status == "Open"
                                            ? "pointer"
                                            : "default",
                                        color:
                                          this.state.status == "Open"
                                            ? "#6c6b6b"
                                            : "rgba(0, 0, 0, 0.26)",
                                        pointerEvents:
                                          this.state.status == "Open"
                                            ? "all"
                                            : "none",
                                      }}
                                      onClick={(e) =>
                                        this.onDeleteClicked(
                                          row && row.owcHeaderID
                                        )
                                      }
                                    />
                                  </Tooltip>
                                </Typography>
                              </TableCell>
                            }
                          </TableRow>
                        );
                      })
                    ) : (
                        <TableRow>
                          {this.state.loading ? (
                            <TableCell
                              colSpan={15}
                              style={{ textAlign: "center" }}
                            >
                              <LinearProgress />
                            </TableCell>
                          ) : (
                              <TableCell
                                colSpan={12}
                                style={{ textAlign: "center" }}
                              >
                                No result found
                              </TableCell>
                            )}
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </Paper>
            </div>
            <Divider style={{ marginTop: "25px" }} />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.props.history.push("/workOrders")}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewOWCReport);
