import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { StateContext } from "../../../shared/globalState";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { getFliteredAcronymList } from "../masterActions";
import { styles } from "../../../shared/styles";
import { resourceValidation } from "../../../shared/resource";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { Tooltip } from "@material-ui/core";
const validateGeneral = new Validator("general");

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        ></IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
// test
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class CountryAdvanceSearch extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    acronymSuffix: "",
    acronymNumber: "",
    officeName: "",
    businessUnit: "",
    isPeriodical: false,

    errors: {
      acronymSuffix: false,
      acronymNumber: false,
      officeName: false,
      businessUnit: false,
      isPeriodical: false,
    },
  };
  handleClose = () => {
    this.setState({
      open: false,
      acronymSuffix: "",
      acronymNumber: "",
      officeName: "",
      businessUnit: "",
      isPeriodical: false,
    });
  };
  onSearchClicked = () => {
    const dispatch = this.context[1];

    let {
      acronymSuffix,
      acronymNumber,
      officeName,
      businessUnit,
      isPeriodical,
    } = this.state;
    let reqBody = {
      acronymSuffix,
      acronymNumber,
      officeName,
      businessUnit,
      isPeriodical,
    };
    this.props.onSearchClicked(reqBody);
    // getFliteredAcronymList(reqBody).then((r) => {
    //   this.props.getAllAcronyms(r.data);
    // dispatch({
    //   type: "getFliteredAcronymList",
    //   newStore: r.data,

    // });
    //this.props.onRequestSucceed();

    // this.setState({
    //   open: false,
    //   acronymSuffix: "",
    //   acronymNumber: "",
    //   officeName: "",
    //   businessUnit: "",
    // });
  };

  checkButtonDisability = () => {
    let {
      acronymSuffix,
      acronymNumber,
      officeName,
      businessUnit,
      isPeriodical,
    } = this.state;
    if (
      acronymSuffix != "" ||
      acronymNumber != "" ||
      officeName != "" ||
      isPeriodical != "" ||
      businessUnit != ""
    )
      return true;
    else return false;
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false, acronymSuffix: "", acronymNumber: "" });
  };
  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  onInputChanged = (e, name, type) => {
    let newError = { ...this.state.errors };

    if (type === "general") {
      let result = validateGeneral(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    }
    if (name === "isPeriodical") this.setState({ [name]: e.target.checked });
    else this.setState({ [name]: e.target.value });
  };
  render() {
    let {
      acronymSuffix,
      acronymNumber,
      officeName,
      businessUnit,
      isPeriodical,
    } = this.state;
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div>
          <Tooltip title="Search">
            <IconButton
              aria-label="Search"
              className={classes.iconHover}
              onClick={this.handleClickOpen}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>

          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
            maxWidth="md"
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            >
              Advanced Search
            </DialogTitle>
            <DialogContent dividers>
              <div>
                <div style={{ minWidth: "400px", display: "flex" }}>
                  <TextField
                    inputProps={{
                      maxLength: 10,
                    }}
                    autoComplete="off"
                    id="standard-required"
                    label="Acronym Suffix"
                    placeholder="Acronym Suffix"
                    className={classes.textField}
                    margin="normal"
                    value={acronymSuffix}
                    error={this.state.errors.acronymSuffix}
                    onChange={(e) =>
                      this.onInputChanged(e, "acronymSuffix", "notRequired2")
                    }
                    helperText={
                      this.state.errors.acronymSuffix
                        ? resourceValidation.max2char
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />

                  <TextField
                    inputProps={{
                      maxLength: 10,
                    }}
                    autoComplete="off"
                    label="Acronym Number"
                    id="standard-required"
                    placeholder="Acronym Number"
                    className={classes.textField}
                    margin="normal"
                    value={acronymNumber}
                    error={this.state.errors.acronymNumber}
                    onChange={(e) =>
                      this.onInputChanged(e, "acronymNumber", "notRequired100")
                    }
                    helperText={
                      this.state.errors.acronymNumber
                        ? resourceValidation.max100char
                        : ""
                    }
                    onKeyPress={this._onNumberKeyPress}
                  />
                </div>
                <div style={{ minWidth: "400px", display: "flex" }}>
                  <TextField
                    //required
                    label="Office Name"
                    name="officeName"
                    inputProps={{
                      maxLength: 50,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={officeName}
                    // onChange={this.handleChange}
                    onChange={(e) =>
                      this.onInputChanged(e, "officeName", "notRequired2")
                    }
                    error={this.state.errors.officeName}
                    helperText={
                      this.state.errors.officeName
                        ? resourceValidation.max50char
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />

                  <TextField
                    //required
                    label="Business Unit"
                    name="businessUnit"
                    inputProps={{
                      maxLength: 10,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={businessUnit}
                    // onChange={this.handleChange}
                    onChange={(e) =>
                      this.onInputChanged(e, "businessUnit", "notRequired2")
                    }
                    error={this.state.errors.businessUnit}
                    helperText={
                      this.state.errors.businessUnit
                        ? "Maximum 10 characters allowed"
                        : ""
                    }
                  />
                </div>
                <div style={{ minWidth: "400px", display: "flex" }}>
                  <FormControlLabel
                    style={{ marginTop: "2.2em", marginLeft: ".10em" }}
                    name="isPeriodical"
                    margin="normal"
                    control={
                      <Checkbox
                        color="primary"
                        checked={isPeriodical}
                        className={classes.Checkbox}
                        value={isPeriodical}
                        onChange={(e) => this.onInputChanged(e, "isPeriodical")}
                      />
                    }
                    label="Periodical"
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                //disableElevation
                style={{ boxShadow: "none" }}
                onClick={this.handleClose}
              >
                CANCEL
              </Button>

              {!this.checkButtonDisability() ? (
                <Button
                  disabled
                  //autoFocus
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  // onClick={e => this.handleSubmit(e)}
                  onClick={(e) =>
                    this.onSearchClicked(
                      this.state.acronymSuffix,
                      this.state.acronymNumber,
                      this.state.officeName,
                      this.state.businessUnit,
                      this.state.isPeriodical
                    )
                  }
                  // className={
                  //   this.checkButtonDisability()
                  //     ? "disabled-button"
                  //     : "enabled-button "
                  // }
                >
                  SEARCH
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                  // onClick={e => this.handleSubmit(e)}
                  onClick={(e) =>
                    this.onSearchClicked(
                      this.state.acronymSuffix,
                      this.state.acronymNumber,
                      this.state.officeName,
                      this.state.businessUnit,
                      this.state.isPeriodical
                    )
                  }
                >
                  SEARCH
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </form>
    );
  }
}
export default withStyles(styles)(CountryAdvanceSearch);
