import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { styles } from "../../../shared/styles";
import { resourceValidation } from "../../../shared/resource";
import { StateContext } from "../../../shared/globalState";
import { getFliteredDeptList } from "../masterActions";

//import { getFliteredDeptList } from "../masterActions";
const validateNotRequired2 = new Validator("notRequired2");
const validateNotRequired100 = new Validator("notRequired100");
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        ></IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class DeptAdvanceSearch extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    departmentCode: "",
    departmentName: "",
    errors: {
      departmentCode: false,
      departmentName: false,
    },
  };
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    debugger;
    event.preventDefault();
    const data = new FormData(event.target);

    fetch("/api/form-submit-url", {
      method: "POST",
      body: data,
    });
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false, departmentCode: "", departmentName: "" });
  };

  onInputChanged = (e, name, type) => {
    let newError = { ...this.state.errors };
    if (type === "notRequired2") {
      let result = validateNotRequired2(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else if (type === "notRequired100") {
      let result = validateNotRequired100(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    }
    this.setState({ [name]: e.target.value });
  };
  onSearchClicked = () => {
    const dispatch = this.context[1];
    let { departmentCode, departmentName } = this.state;
    let reqBody = { departmentCode, departmentName };
    this.props.onSearchClicked(reqBody);
    // getFliteredDeptList(reqBody).then((r) => {
    //   dispatch({
    //     type: "getDeptList",
    //     newStore: r.data,
    //   });
    //   this.props.onRequestSucceed();
    // });
    this.setState({ open: false, departmentCode: "", departmentName: "" });
  };
  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkButtonDisability = () => {
    let { departmentCode, departmentName } = this.state;
    if (
      this.state.errors.departmentName ||
      this.state.errors.departmentCode ||
      (!departmentCode && !departmentName)
    )
      return true;
    else return false;
  };
  render() {
    let { departmentCode, departmentName } = this.state;
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div>
          <Tooltip title="Search">
            <IconButton
              aria-label="Search"
              className={classes.iconHover}
              onClick={this.handleClickOpen}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
            maxWidth="md"
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            >
              {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
              Advanced Search
            </DialogTitle>
            <DialogContent dividers>
              <div className={classes.container}>
                <div>
                  <TextField
                    inputProps={{
                      maxLength: 2,
                    }}
                    id="standard-required"
                    label="Department Code"
                    placeholder="Department Code"
                    className={classes.textField}
                    margin="normal"
                    value={departmentCode}
                    error={this.state.errors.departmentCode}
                    onChange={(e) =>
                      this.onInputChanged(e, "departmentCode", "notRequired2")
                    }
                    autoComplete="off"
                    helperText={
                      this.state.errors.departmentCode
                        ? resourceValidation.onlyAlphabet
                        : ""
                    }
                    onKeyPress={this._onCodeKeyPress}
                  />
                  <TextField
                    label="Department Name"
                    id="standard-required"
                    placeholder="Department Name"
                    className={classes.textField}
                    margin="normal"
                    value={departmentName}
                    error={this.state.errors.departmentName}
                    onChange={(e) =>
                      this.onInputChanged(e, "departmentName", "notRequired100")
                    }
                    autoComplete="off"
                    helperText={
                      this.state.errors.departmentName
                        ? resourceValidation.onlyAlphabet
                        : ""
                    }
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                //disableElevation
                style={{ boxShadow: "none" }}
                onClick={this.handleClose}
              >
                CANCEL
              </Button>
              {this.checkButtonDisability() ? (
                <Button
                  disabled
                  //autoFocus
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.onSearchClicked()}
                >
                  SEARCH
                </Button>
              ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    //disableElevation
                    style={{ boxShadow: "none" }}
                    onClick={(e) => this.onSearchClicked()}
                  >
                    SEARCH
                  </Button>
                )}

              {/* <Button
                //variant="outlined"
                color="primary"
                className={classes.button}
                onClick={this.handleClose}
              >
                CANCEL
              </Button> */}
            </DialogActions>
          </Dialog>
        </div>
      </form>
    );
  }
}
export default withStyles(styles)(DeptAdvanceSearch);
