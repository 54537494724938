import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import { getCodeMaster } from "../../masters/masterActions";
import { StateContext } from "../../../shared/globalState";
import { printDetails } from "../../../shared/tableCommonFunctions";
import { getCodeTypeList } from "../masterActions"
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { selectedCodeMasterId } = props;
  let acnArr = props.roles.filter((u) => u.menuName === "Code Master");
  let acnObj = acnArr[0];

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_codeMaster/${selectedCodeMasterId}`}>
                <IconButton aria-label="edit" className={classes.iconHover} disabled={!props.enableEdit}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewCodeMaster extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      codeMaster: {
        codeTypeName: "",
        code: "",
        codeValue: "",
        effectiveStartDate: "",
        remarks: "",
        processName: "",
      },
      codeTypeList: [],
      enableEdit: false
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let codeMasterID = this.props.match.params.codeMasterId;
    getCodeMaster(codeMasterID).then((rr) => {
      dispatch({
        type: "getCodeMaster",
        newStore: rr,
      });
      this.setState({ codeMaster: rr });
      getCodeTypeList().then((r) => {
        let list = r.data;
        list &&
          list.length > 0 &&
          this.setState({
            codeTypeList: list
              .filter((x) => x.isActive === true && x.canEditValue === true)
              .sort((a, b) =>
                a.codeType.toLowerCase() > b.codeType.toLowerCase() ? 1 : -1
              ),
          }, () => {
            let found = false
            this.state.codeTypeList.map(
              (t) => {
                console.log(t.codeTypeID, rr.codeType)
                if (t.codeTypeID === rr.codeType)
                  found = true
                this.setState({ enableEdit: found })

              }
            );
          });

      });
    });

  }

  render() {
    let { codeMaster } = this.state;
    let codeMasterID = this.props.match.params.codeMasterId;
    let { status } = "";
    if (codeMaster.isActive === true) {
      status = "Yes";
    } else {
      status = "No";
    }
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selectedCodeMasterId={codeMasterID}
            roles={this.props.roles}
            enableEdit={this.state.enableEdit}
          />
          {/* <div className="view-page-header">Cost Per Title Information</div> */}
          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">Code Master Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Code Type Name</div>
                <div className="view-page-value">{codeMaster.codeTypeName}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Code</div>
                <div className="view-page-value">{codeMaster.code}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Code Value</div>
                <div className="view-page-value">{codeMaster.codeValue}</div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Remarks</div>
                <div className="view-page-value">
                  {codeMaster.remarks && codeMaster.remarks}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Active</div>
                <div className="view-page-value">{status}</div>
              </div>
            </div>
            <Divider style={{ marginTop: "25px" }} />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.props.history.push("/codeMaster")}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewCodeMaster);
