import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { AddIcon } from "../../../shared/customIcons";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 8 * 2,
  },
  closeButton: {
    position: "absolute",
    right: 8 * 1,
    top: 8 * 1,
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default class LetterModal extends React.Component {
  state = {
    open: false,
    selectedLSC: [],
    searchVal: "",
    searchClicked: false,
    stateConst: [],
    updateUI:false
  };
  componentDidMount() {
    this.setState({ stateConst: this.props.letterShopList });
    // this.setState({selectedLSC: this.props.selLSArr})
  }

  handleClickOpen = () => {
    this.setState({ open: true });
    setTimeout(() => {
      this.props.selLSArr&& this.props.selLSArr.length>0 && this.props.selLSArr.map(l=>{
          this.handleChange(l.key,l.value)
        })
    });
  };
  handleClose = () => {
    this.setState({
      open: false,
      selectedLSC: [],
      searchVal: "",
      searchClicked: false,
      stateConst: this.props.letterShopList,
    });
  };
  handleDialogOpen = () => {
    this.setState({
      selectedLSC: [],
      searchVal: "",
      searchClicked: false,
      stateConst: this.props.letterShopList,
    });
  };
  handleSubmit = () => {
    console.log(this.state.selectedLSC)
    this.props.handleBillingAdderss(
      this.state.selectedLSC,
      "letterShopInfo",
      this.props.i
    );
    this.setState({
      open: false,
      selectedLSC: [],
      searchVal: "",
      searchClicked: false,
      stateConst: this.props.letterShopList,
    });
  };
  handleChange = (n, v) => {
    let newSelectedLSC = [...this.state.selectedLSC];
    newSelectedLSC.push({ key: n, value: v });
    this.setState({ selectedLSC: newSelectedLSC });
    let selectedLSC = document.getElementById(n);
    let selectedLSCIcon = document.getElementById(`${n}Icon`);
    selectedLSC.setAttribute("disabled", "disabled");
    selectedLSC.style.backgroundColor = "#3982ea";
    selectedLSC.style.color = "#fff";
    selectedLSC.style.cursor = "context-menu";
    selectedLSC.style.padding = "0.5em .5em .5em 0";
    //selectedLSCIcon.className = "fa fa-check";
    selectedLSCIcon.style.fontSize = "11px";
    selectedLSCIcon.style.marginRight = "9px";
    // console.log(newSelectedLSC);
  };
  onDeleteLSCClicked = (e, i, n) => {
    let newSelectedLSC = [...this.state.selectedLSC];
    newSelectedLSC.splice(i, 1);
    this.setState({ selectedLSC: newSelectedLSC });
    let selectedLSC = document.getElementById(n.key);
    let selectedLSCIcon = document.getElementById(`${n.key}Icon`);
    selectedLSC.style.padding = "0.5em";
    selectedLSC.removeAttribute("disabled");
    selectedLSC.style.backgroundColor = "#fff";
    selectedLSC.style.color = "rgba(0, 0, 0, 0.87)";
    selectedLSC.style.cursor = "pointer";
    selectedLSCIcon.style.display = "none";
  };
  onInputChanged = (e) => {
    this.setState({ searchVal: e.target.value });
  };
  onSearchClicked = () => {
    this.props.clearLetterShopInfo(this.props.i);
    this.setState({ selectedLSC: [] });
    //console.log(this.state.stateConst);
    let se = this.state.stateConst.filter((n) => {
      if (
        n &&
        n.value.toLowerCase().includes(this.state.searchVal.toLowerCase())
      )
        return n;
    });
    this.setState({ stateConst: se, searchClicked: true });
  };
  onClearSearch = () => {
    this.setState({
      stateConst: this.props.letterShopList,
      searchVal: "",
      searchClicked: false,
    });
    setTimeout(() => {
      this.props.selLSArr&& this.props.selLSArr.length>0 && this.props.selLSArr.map(l=>{
          this.handleChange(l.key,l.value)
        })
    });
  };
  render() {
    let { open } = this.state;
    // console.log(this.state.stateConst);
    return (
      <div>
        <div
          onClick={this.handleClickOpen}
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #eaeaea",
            width: "172px",
            padding: ".2em",
            borderRadius: "4px",
            marginTop: "1em",
            cursor: "pointer",
          }}
        >
          <AddIcon />

          <span>Pick Lettershop Info</span>
        </div>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          // onOpen={this.handleDialogOpen}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Pick Lettershop Code
          </DialogTitle>
          <DialogContent dividers>
            <div
              style={{
                margin: "1em",
                display: "flex",
              }}
            >
              <div
                style={{
                  borderBottom: "1px solid #949191",
                  fontSize: "17px",
                  fontFamily: "Roboto",
                  marginTop: "4px",
                  display: "flex",
                  flexWrap: "wrap",
                  maxWidth: "425px",
                }}
              >
                {this.state.selectedLSC.map((s, i) => {
                  //console.log(s);
                  return (
                    <div
                      key={i}
                      style={{
                        margin: "4px",
                        backgroundColor: "#3982ea",
                        height: "23px",
                        padding: "3px",
                        // paddingLeft: "10px",
                        // paddingRight: "10px",
                        marginTop: "6px",
                        borderRadius: "4px",
                        color: "#fff",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>{s.value}</span>
                      <span
                        style={{
                          marginLeft: ".5em",
                          marginTop: ".3em",
                        }}
                      >
                        <HighlightOffIcon
                          //fontSize="small"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => this.onDeleteLSCClicked(e, i, s)}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
              <div style={{ display: "flex", flexDirection: "column-reverse" }}>
                <TextField
                  //label="With normal TextField"
                  value={this.state.searchVal}
                  onChange={this.onInputChanged}
                  style={{ width: "100px" }}
                  InputProps={{
                    endAdornment: !this.state.searchClicked ? (
                      <InputAdornment onClick={(e) => this.onSearchClicked()}>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <InputAdornment onClick={this.onClearSearch}>
                        <IconButton>
                          <HighlightOffIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div style={{ margin: "1em", display: "flex", flexWrap: "wrap" }}>
              {this.state.stateConst.map((n, i) => (
                <div
                  id={n.key}
                  key={i}
                  onClick={(e) =>
                    document.getElementById(n.key).getAttribute("disabled")
                      ? ""
                      : this.handleChange(n.key, n.value)
                  }
                  style={{
                    border: "1px solid #eaeaea",
                    margin: "4px",
                    padding: ".5em",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <span id={`${n.key}Icon`}></span>
                  {n.value}
                </div>
              ))}
            </div>
          </DialogContent>
          <DialogActions
            style={{ marginLeft: "1.2em", justifyContent: "flex-start" }}
          >
            <Button
              autoFocus
              onClick={this.handleClose}
              variant="contained"
              color="primary"
              //disableElevation
              style={{ boxShadow: "none" }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              //disableElevation
              style={{ boxShadow: "none" }}
              onClick={(e) => this.handleSubmit()}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
