import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import toastr from "toastr";
import { AddIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { getDeptList, deleteDepartment } from "../masterActions";
import { StateContext } from "../../../shared/globalState";
import { styles } from "../../../shared/styles";
import { resourceValidation } from "../../../shared/resource";
import axios from "axios";
const validateMaxMinLength2 = new Validator("maxmin2length");
const validateMaxLength100 = new Validator("maxlength100OnlyChar");

const baseURL = process.env.REACT_APP_PGS_SERVER_MASTER_DATA_MANAGEMENT;
const pgsService = axios.create({
  baseURL
});
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

class ManageDepartment extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    departmentCode: "",
    departmentName: "",
    errors: {
      departmentCode: false,
      departmentName: false
    }
  };
  onCreateDepartment = () => {
    const dispatch = this.context[1];
    // const list = this.context;
    let notValid = false;
    if (!this.state.departmentCode || !this.state.departmentName) return;
    for (let err in this.state.errors) {
      //console.log(this.state.errors[err]);
      if (this.state.errors[err]) notValid = true;
    }
    if (notValid) return;
    let { departmentCode, departmentName } = this.state;
    let reqBody = { departmentCode, departmentName };
    pgsService
      .post("/Departments", reqBody)
      .then(r => {
        //const [{ store }, dispatch] = this.context;

        getDeptList().then(r => {
          dispatch({
            type: "getDeptList",
            newStore: { deptList: r.data }
          });
        });

        this.handleClose();

        toastr.success("Department Created Successfully");
        this.props.onRequestSucceed();
        this.props.history.push("/department");
      })
      .catch(e => {
        if (e.response.data.errors[0].message !== undefined) {
          toastr.error(e.response.data.errors[0].message);
        } else if (e.response.data.errors[0]) {
          toastr.error(e.response.data.errors[0]);
        }

        this.handleClose();
      });
  };
  onUpdateDepartment = () => {
    let notValid = false;
    for (let err in this.state.errors) {
      if (this.state.errors[err]) notValid = true;
    }
    if (notValid) return;
    let { departmentCode, departmentName } = this.state;
    let {
      data: { departmentId }
    } = this.props;
    let reqBody = { departmentCode, departmentName };
    pgsService
      .put(`/Departments/${departmentId}`, reqBody)
      .then(r => {
        this.handleClose();
        this.props.onRequestSucceed();
        toastr.success("Departement Updated Successfully");
      })
      .catch(e => {
        this.handleClose();
        e.response.data && toastr.error(e.response.data.errors[0].message);
        this.props.onRequestSucceed();
      });
  };
  onDeleteDepartment = () => {
    let { selected } = this.props;
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteDepartment(modifiedBy, deletionIds).then(r => {
      this.handleClose();
      this.props.onRequestSucceed();
    });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
    //setting departments state when modal open
    if (this.props.mode === "edit" || this.props.mode === "view") {
      this.setState({
        departmentCode: this.props.data.departmentCode,
        departmentName: this.props.data.departmentName
      });
    }
  };
  handleClose = () => {
    // clean up state and errors when modal closed
    let newErrors = { ...this.state.errors };
    newErrors.departmentCode = false;
    newErrors.departmentName = false;
    this.setState({
      open: false,
      departmentCode: "",
      departmentName: "",
      errors: newErrors
    });
  };
  //changing state data, validating that data with error info, if any, on input changed
  onInputChanged = (e, name, type) => {
    e.preventDefault();
    let newError = { ...this.state.errors };
    if (type === "maxmin2length") {
      let result = validateMaxMinLength2(e.target.value);
      newError[name] = !result;
    } else if (type === "maxlength100OnlyChar") {
      let result = validateMaxLength100(e.target.value);
      newError[name] = !result;
    }

    this.setState({ errors: newError });
    this.setState({ [name]: e.target.value });
  };

  render() {
    let { departmentCode, departmentName } = this.state;
    const { classes, mode, data } = this.props;
    // console.log(this.props);
    return (
      <div>
        <IconButton
          aria-label="add"
          className={classes.iconHover}
          onClick={this.handleClickOpen}
        >
          {mode && mode === "edit" ? (
            <EditIcon />
          ) : mode && mode === "view" ? (
            <VisibilityIcon />
          ) : mode && mode === "delete" ? (
            <DeleteIcon className={classes.iconHover} />
          ) : (
            <AddIcon />
          )}
        </IconButton>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="lg"
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {" "}
            {/* Setting  Modal Title base on the mode */}
            {mode && mode === "edit"
              ? "Update Department Details"
              : mode && mode === "view"
              ? "Department Details"
              : mode && mode === "delete"
              ? "Delete Department"
              : "Create New Department"}
          </DialogTitle>
          {mode === "delete" ? (
            <DialogContent dividers>
              <div className={classes.deleteContainer}>
                Are you sure you want to delete {""}
                <span style={{ fontWeight: "600" }}>{data.departmentCode} ?</span>
              </div>
            </DialogContent>
          ) : (
            <DialogContent dividers>
              {mode && mode === "add"}
              <div className={classes.container}>
                <div>
                  <TextField
                    required
                    InputProps={{
                      readOnly: mode === "view" ? true : false
                    }}
                    inputProps={{
                      maxLength: 2
                    }}
                    id="txtdeptCode"
                    label="Department Code"
                    placeholder="Department Code"
                    className={classes.textField}
                    margin="normal"
                    value={departmentCode}
                    error={this.state.errors.departmentCode}
                    helperText={
                      this.state.errors.departmentCode
                        ? resourceValidation.onlyAlphabet
                        : ""
                    }
                    onChange={e =>
                      this.onInputChanged(e, "departmentCode", "maxmin2length")
                    }
                    // onKeyPress={this.onKeyPress}
                  />
                  <TextField
                    required
                    label="Department Name"
                    // id="txtdeptName"
                    placeholder="Department Name"
                    className={classes.textField}
                    InputProps={{
                      readOnly: mode === "view" ? true : false
                    }}
                    inputProps={{
                      maxLength: 100
                    }}
                    margin="normal"
                    value={departmentName}
                    error={this.state.errors.departmentName}
                    helperText={
                      this.state.errors.departmentName
                        ? resourceValidation.onlyAlphabet
                        : ""
                    }
                    onChange={e =>
                      this.onInputChanged(
                        e,
                        "departmentName",
                        "maxlength100OnlyChar"
                      )
                    }
                  />
                </div>
              </div>
            </DialogContent>
          )}
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant="contained"
              color="primary"
              //disableElevation
              style={{ boxShadow: "none" }}
            >
              Close
            </Button>
            <Button
              onClick={this.onDeleteDepartment}
              variant="contained"
              color="primary"
              //disableElevation
              style={{ boxShadow: "none" }}
            >
              Delete
            </Button>
            {/*  <Button
              variant="outlined"
               style={{border:"1px solid rgb(0, 105, 217)"}}
              onClick={this.handleClose}
            >
              CANCEL
            </Button>
             {mode && mode !== "view" && (
              <Button
                disabled={
                  mode === "delete"
                    ? false
                    : (mode === "add" && !departmentName) ||
                      !departmentCode ||
                      this.state.errors.departmentName ||
                      this.state.errors.departmentCode ||
                      (mode === "edit" &&
                        departmentCode === this.props.data.departmentCode &&
                        departmentName === this.props.data.departmentName)
                    ? true
                    : false
                }
                autoFocus
                onClick={
                  mode === "add"
                    ? this.onCreateDepartment
                    : mode === "edit"
                    ? this.onUpdateDepartment
                    : this.onDeleteDepartment
                }
                variant="contained"
                className={
                  !(mode === "delete"
                    ? false
                    : (mode === "add" && !departmentName) ||
                      !departmentCode ||
                      this.state.errors.departmentName ||
                      this.state.errors.departmentCode ||
                      (mode === "edit" &&
                        departmentCode === this.props.data.departmentCode &&
                        departmentName === this.props.data.departmentName)
                    ? true
                    : false)
                    ? "enabled-button"
                    : "disabled-button"
                }
              >
                {mode === "edit"
                  ? "Update"
                  : mode === "delete"
                  ? "Delete"
                  : "Create"}
              </Button>
            )} */}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(ManageDepartment));
