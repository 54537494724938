import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { StateContext } from "../../../shared/globalState";
import { getFliteredZoneList } from "../masterActions";
import { styles } from "../../../shared/styles";
const validateNotRequired2 = new Validator("notRequired2");
const validateNotRequired100 = new Validator("notRequired100");

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
         
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class ZoneAdvanceSearch extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    zoneName: "",
    zoneCode: "",
    errors: {
      zoneName: false,
      zoneCode: false,
    },
  };
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {}
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false, zoneName: "", zoneCode: "" });
  };
  onInputChanged = (e, name, type) => {
    let newError = { ...this.state.errors };
    if (type === "notRequired2") {
      let result = validateNotRequired2(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    }
    // else if (type === "notRequired100") {
    //   let result = validateNotRequired100(e.target.value);
    //   newError[name] = !result;
    //   this.setState({ errors: newError });
    // }
    this.setState({ [name]: e.target.value });
  };
  onSearchClicked = () => {
    const dispatch = this.context[1];
    let { zoneCode, zoneName } = this.state;
    let reqBody = { zoneCode, zoneName };
    this.props.onSearchClicked();
    getFliteredZoneList(reqBody).then((r) => {
      dispatch({
        type: "getZoneList",
        newStore: r.data,
      });
      this.props.onRequestSucceed();
    });
    this.setState({ open: false, zoneCode: "", zoneName: "" });
  };
  checkButtonDisability = () => {
    let { zoneCode, zoneName } = this.state;
    if (
      this.state.errors.zoneName ||
      this.state.errors.zoneCode ||
      (!zoneCode && !zoneName)
    )
      return true;
    else return false;
  };
  render() {
    let { zoneCode, zoneName } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div>
          <Tooltip title="Search">
            <IconButton
              aria-label="Search"
              className={classes.iconHover}
              onClick={this.handleClickOpen}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
            maxWidth="md"
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            >
              {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
              Advanced Search
            </DialogTitle>
            <DialogContent dividers>
              <div className={classes.container}>
                <div>
                  <TextField
                    inputProps={{
                      maxLength: 2,
                    }}
                    id="standard-required"
                    label="Zone Code"
                    placeholder="Zone Code"
                    className={classes.textField}
                    margin="normal"
                    value={zoneCode}
                    error={this.state.errors.zoneCode}
                    onChange={(e) =>
                      this.onInputChanged(e, "zoneCode", "notRequired2")
                    }
                    helperText={
                      this.state.errors.zoneCode
                        ? "Please enter only alphabets"
                        : ""
                    }
                    autoComplete="off"
                  />
                  <TextField
                    label="Zone Name"
                    inputProps={{
                      maxLength: 100,
                    }}
                    id="standard-required"
                    placeholder="Zone Name"
                    className={classes.textField}
                    margin="normal"
                    value={zoneName}
                    error={this.state.errors.zoneName}
                    onChange={(e) =>
                      this.onInputChanged(e, "zoneName", "notRequired100")
                    }
                    helperText={
                      this.state.errors.zoneName
                        ? // ? "Please enter only alphabets"
                          "Please enter valid Zone Name"
                        : ""
                    }
                    autoComplete="off"
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={this.handleClose}
              >
                CANCEL
              </Button>
              {this.checkButtonDisability() ? (
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  // className={classes.button}
                  onClick={(e) => this.onSearchClicked()}
                >
                  SEARCH
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  // className={classes.button}
                  onClick={(e) => this.onSearchClicked()}
                >
                  SEARCH
                </Button>
              )}

              {/* <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={this.handleClose}
              >
                CANCEL
              </Button> */}
            </DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ZoneAdvanceSearch);
