import React, { Component } from "react";
import styles from "./login.module.css";
import { TextField, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import Validator from "../../shared/validator";
import {
  postLogin,
  postLoginUN,
  SingPostUserLogin,
} from "../userAccess/userAccessAction";
import { Link } from "react-router-dom";
import toastr from "toastr";
import { isValid } from "date-fns";

const username = new Validator("username");
const password = new Validator("password");
const baseURL = process.env.REACT_APP_PGS_SERVER_ADFS;
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      USERNAME: {
        userName: "",
        password: "",
        isValid: false,
        UserName: "",
      },
      errors: {},
      formValid: false,
      usernameValid: false,
      passwordValid: false,
      showPassword: false,
      isSubmitting: false,
      errorMessage: null,
      msgType: "",
      errorMessageUN: null,
      auth: false,
      isNotSingpostUser: false,
    };
  }

  _onKeyPress(event) {
    const re = /[a-zA-Z0-9\s\!#%^&=(),_.@-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  validateForm() {
    this.setState({
      formValid: this.state.userNameValid && this.state.passwordValid,
    });
    return this.state.formValid;
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let usernameValid = this.state.usernameValid;
    let passwordValid = this.state.passwordValid;
    switch (fieldName) {
      case "userName":
        usernameValid = username(value);
        fieldValidationErrors.username = usernameValid ? "" : "";
        break;
      case "password":
        passwordValid = password(value);
        fieldValidationErrors.password = passwordValid ? "" : "";
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        usernameValid: usernameValid,
        passwordValid: passwordValid,
      },
      this.validateForm
    );
  }

  handleChange = (e) => {
    const { USERNAME } = this.state;
    USERNAME[e.target.name] = e.target.value;

    this.setState({ USERNAME });
    this.validateField(e.target.name, e.target.value);
  };

  checkValidInputs = () => {
    if (!this.state.USERNAME.userName) return true;
    // if (!this.state.USERNAME.password) return true;
    else return false;
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let { USERNAME } = this.state;

    let userLoginID = USERNAME.userName;

    if (!this.state.isNotSingpostUser) {
      let reqBody = {
        userLoginID: userLoginID,
      };
      // Singpost User Login
      postLoginUN(reqBody, this.props.history).then((r) => {
        this.setState({
          errorMessageUN: null
        });
        if (r.data.isValid != undefined && r.data.isValid == true) {
          window.location.replace(
            baseURL +
            "/Home/login?UserName=" +
            userLoginID +
            "&UniqueID=" +
            r.data.uniqueID
          );
        } else if (r.data.isValid != undefined && r.data.isValid == false) {
          this.setState({
            errorMessageUN: null,
            isNotSingpostUser: true,
          });
        } else {
          this.setState({
            errorMessageUN: r.data.message,
          });
        }
      });
    } else {
      let password = USERNAME.password;
      let reqBody = {
        userLoginID: userLoginID,
        password: password,
      };
      postLogin(reqBody, this.props.history).then((r) => {
        // if (localStorage.getItem("PwdExpiryNotification") != "") {
        //   toastr.error(localStorage.getItem("PwdExpiryNotification"));
        // }
        let pEEx = localStorage.getItem("PwdExpiryNotification") || ""
        if (pEEx) {
          toastr.error(localStorage.getItem("PwdExpiryNotification"));
        }
        if (r && r.status === 400) {
          let username = {
            userName: this.state.USERNAME.userName,
            password: "",
          };
          this.setState({
            errorMessage: r.data.message,
            msgType: r.data.type,
            USERNAME: username,
          });
        }
      });
    }
  };

  componentDidMount() {
    // localStorage.clear();
    // let newUSERNAME = { ...this.state.USERNAME };
    // const userName = localStorage.getItem("userName") === "true";
    //  newUSERNAME.username = userName;
    //  this.setState({ USERNAME: newUSERNAME });
    let { USERNAME } = this.state;

    let userLoginID = USERNAME.userName;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const IsValid = urlParams.get("IsValid");
    const userName = urlParams.get("UserName");
    const message = urlParams.get("Message");
    const uniqueID = urlParams.get("UniqueID");
    // const urlParams = function(name){
    //   var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    //   if (results == null){
    //      return null;
    //   }
    //   else {
    //      return decodeURI(results[1]) || 0;
    //   }
    // }
    // const IsValid = urlParams("IsValid");
    // const userName = urlParams("UserName");
    // const message = urlParams("Message");
    // const uniqueID = urlParams("UniqueID");

    if (IsValid && IsValid.toLowerCase() == "true") {
      let reqBody = {
        uniqueID,
        IsValid,
        message,
        userName,
      };
      SingPostUserLogin(reqBody, this.props.history).then((r) => {
        if (r && r.status === 400) {
          let username = {
            userName: this.state.USERNAME.userName,
            password: "",
          };
          this.setState({
            errorMessage: r.data.message,
            msgType: r.data.type,
            USERNAME: username,
          });
        }
      });

      localStorage.setItem("userName", userName);
      localStorage.setItem("auth", true);
    } else if (IsValid == null) {
      // console.log("Not necessary");
    } else if (
      IsValid !== null &&
      IsValid &&
      IsValid.toLowerCase() !== "true"
    ) {
      toastr.error("Authentication Failed");
      window.location.replace(baseURL + "/Home/logout");
      // this.props.history.push("/");
    }
    // this.setState({
    //   isValid: isValid,
    //   UserName: UserName
    // })
  }

  // onKeyPress = event => {
  //   if (event.key === 13) {
  //     console.log("enter press here! ");
  //     this.handleSubmit();
  //   }
  // };

  render() {
    let { classes } = this.props;
    let { USERNAME } = this.state;
    return (
      <div className={styles.base}>
        <div className={styles.main}>
          <div>
            <div className={styles.header} />
            <div className={styles.headerText}>Publisher & Generic System</div>
          </div>
          <div className={styles.userInputField}>
            <form className={classes.container} onSubmit={this.handleSubmit}>
              <div>
                <TextField
                  label="User Name"
                  name="userName"
                  // disabled={this.state.isNotSingpostUser}
                  inputProps={{
                    maxLength: 50,
                    background: "transparent!important",
                  //  readOnly: this.state.isNotSingpostUser,
                  }}
                  type="text"
                  autoComplete="off"
                  style={{ marginTop: "40px", width: "100%" }}
                  className={classes.textField}
                  onChange={this.handleChange}
                  value={USERNAME.userName ? USERNAME.userName : ""}
                  error={this.state.errors.userName}
                  helperText={
                    this.state.errors.userName ? this.state.errors.userName : ""
                  }
                  onKeyPress={this._onKeyPress}
                />
              </div>
              <div style={{ textAlign: "left", maxWidth: "199px" }}>
                {this.state.errorMessageUN ? (
                  <span className={styles.errorMessage}>
                    {this.state.errorMessageUN}
                  </span>
                ) : null}
              </div>
              {this.state.isNotSingpostUser ? (
                <div>
                  <div>
                    <TextField
                      autoFocus
                      label="Password"
                      name="password"
                      inputProps={{
                        maxLength: 50,
                        background: "transparent!important",
                      }}
                      type="text"
                      autoComplete="off"
                      style={{ marginTop: "40px", width: "100%" }}
                      className={classes.textField}
                      onChange={this.handleChange}
                      type={"password"}
                      value={USERNAME.password}
                      error={this.state.errors.password}
                      helperText={
                        this.state.errors.password
                          ? this.state.errors.password
                          : ""
                      }
                      onKeyPress={this._onKeyPress}
                    />
                  </div>
                  <div style={{ textAlign: "left", maxWidth: "199px" }}>
                    {this.state.errorMessage ? (
                      <span className={styles.errorMessage}>
                        {this.state.errorMessage}
                      </span>
                    ) : null}
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      marginTop: "1em",
                      color: "rgb(60, 103, 171)",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    <Link
                      to={"/forgotPassword"}
                      style={{
                        textDecoration: "none",
                        color: "rgb(60, 103, 171)",
                      }}
                    >
                      {this.state.msgType === "Reset" ? "Reset Password" : "Forgot Password ?"}
                    </Link>
                  </div>
                </div>
              ) : null}
              <div className={styles.button}>
                {this.checkValidInputs() ? (
                  <Button
                    type="submit"
                    disabled
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={this.handleSubmit}
                    // onKeyPress={this.onKeyPress}

                    style={{
                      width: "100%",
                      height: "40px",
                    }}
                  >
                    LOGIN
                  </Button>
                ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={this.handleSubmit}
                      // onKeyPress={this.onKeyPress}

                      style={{
                        width: "100%",
                        height: "40px",
                      }}
                    >
                      LOGIN
                    </Button>
                  )}
              </div>
            </form>
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Login));
