import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";
import clone from "clone";

import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { Checkbox } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import StatusText from "../../../shared/statusText";
import {
  serchCustomerOrder,
  getPlacedOrder,
} from "../../generic/orderPlacement/actions";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { blue } from "@material-ui/core/colors";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
//import ManageCountry from "./countryManage";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AdvanceSearch from "./search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { StateContext } from "../../../shared/globalState";
import Typography from "@material-ui/core/Typography";
//import CreateCountry from "./countryAdd";
import FormGroup from "@material-ui/core/FormGroup";
import Validator from "../../../shared/validator";
//import DeleteOrder from "./delete";
import {
  desc,
  stableSort,
  getSorting,
  DialogActions,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import toastr from "toastr";
import moment from "moment";
import "../../app/App.css";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { ExportCSV } from "../../../shared/ExportCSV";
import {
  SearchIcon,
  DownloadIcon,
  ViewColumnIcon,
  EditIcon,
  ViewIcon,
  AddIcon,
} from "../../../shared/customIcons";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getBpaOrderAll, serchBPAOrder, deleteBPAOrder } from "./action";

const validateMaxlength2 = new Validator("maxlength2");

// const columns = [
//   {
//     id: 10,
//     name: "Modified By",
//   },

//   {
//     id: 11,
//     name: "Modified Date",
//   },
//   {
//     id: 12,
//     name: "Created By",
//   },
//   {
//     id: 13,
//     name: "Created Date",
//   },
// ];
const columns = [
  {
    name: "Modified By",
    id: 20,
  },
  {
    name: "Modified Date",
    id: 21,
    // show: false
  },
  {
    name: "Created By",
    id: 22,
  },
  {
    name: "Created Date",
    id: 23,
  },
];
// const DialogActions = withStyles(theme => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1)
//   }
// }))(MuiDialogActions);
let initailData = [];
// function createData(
//   address,
//   address2,
//   bpaCode,
//   bpaOrderHeaderID,
//   bpaRecipientID,
//   bpaRunningID,
//   bpaSuffix,
//   companyName,
//   conferenceName,
//   conferenceRef,
//   contactPersonName,
//   costCoding,
//   countryID,
//   countryName,
//   createdBy,
//   createdByName,
//   createdDate,
//   deliveryInstructions,
//   deliveryMethodID,
//   deliveryMethodName,
//   department,
//   doNotDeliveryBefore,
//   emailID,
//   modifiedBy,
//   modifiedByName,
//   modifiedDate,
//   mustBeDeliveryBy,
//   mustBeDeliveryOn,
//   orderDate,
//   packingInstructions,
//   phoneNumber,
//   postalCode,
//   remarks,
//   statusName,
//   trackingNumber
// ) {
//   return {
//     statusName,
//     address,
//     address2,
//     bpaCode,
//     bpaOrderHeaderID,
//     bpaRecipientID,
//     bpaRunningID,
//     bpaSuffix,
//     companyName,
//     conferenceName,
//     conferenceRef,
//     contactPersonName,
//     costCoding,
//     countryID,
//     countryName,
//     createdBy,
//     createdByName,
//     createdDate,
//     deliveryInstructions,
//     deliveryMethodID,
//     deliveryMethodName,
//     department,
//     doNotDeliveryBefore,
//     emailID,
//     modifiedBy,
//     modifiedByName,
//     modifiedDate,
//     mustBeDeliveryBy,
//     mustBeDeliveryOn,
//     orderDate,
//     packingInstructions,
//     phoneNumber,
//     postalCode,
//     remarks,
//     statusName,
//     trackingNumber
//   };
// }
function createData(
  emloOrderID,
  companyName,
  type,
  status,
  emloNo,
  acronym,
  volume,
  issue,
  part,
  supp,
  qty,
  country,
  recipient,
  orderDate,
  deliveryDate,
  trackingNumber,
  noOfCartons,
  dhlRate,
  totalWeight,
  specialInstruction,
  modifiedByName,
  modifiedDate,
  createdByName,
  createdDate,
  id
) {
  return {
    emloOrderID,
    companyName,
    type,
    status,
    emloNo,
    acronym,
    volume,
    issue,
    part,
    supp,
    qty,
    country,
    recipient,
    orderDate,
    deliveryDate,
    trackingNumber,
    noOfCartons,
    dhlRate,
    totalWeight,
    specialInstruction,
    modifiedByName,
    modifiedDate,
    createdByName,
    createdDate,
    id
  };
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ borderTop: "1px solid #eaeaea", background: "white" }}
          className="table-cell"
        >
          {/* <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "Select All Countrys" }}
              />
            }
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.code}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
            //);
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    countryCode: "",
    countryName: "",
  });
  const [formValid, setIsformValid] = useState(false);

  const [countrySearchValue, setValues] = useState({
    countryCode: "",
    countryName: "",
  });
  var BPAMarketManage = props.roles.filter((r) => {
    return r.superMenuName === "BPA Markerting";
  });
  // var BPAMarketProcessManage = props.roles.filter((r) => {
  //   return r.superMenuName === "BPA Markerting";
  // });

  let BPAorderplaceManage =
    BPAMarketManage.length > 0 &&
    BPAMarketManage.filter((u) => u.menuName === "Order Placement");
  var acnObj = BPAorderplaceManage[0];

  let BPAOrderProcessingManage =
    props.roles.length > 0 &&
    props.roles.filter((u) => u.menuName === "BPA Order Processing");
  var bpaOPObj = BPAOrderProcessingManage[0];
  const classes = useToolbarStyles();
  const {
    numSelected,
    selectedLocalPostage,
    selectedLocalPostageID,
    handleDeletePopupOpen,
    deletePopupOpen,
    searchPopupOpen,
    handleDeletePopupClose,
    handleDeleteCountry,
    handleViewColumns,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchCountry,
    handledeleteProductionLineCode,
    searchClicked,
    selected,
    localPostage,
    orderPlacementList,
    editable,
    removable,
    selectedStatus,
    selectedRows,
  } = props;

  let excelFileData = clone(props.rows);
  // excelFileData.map((d) => {
  //   d["Order ID"] = d["bpaCode"];
  //   d["Status"] = d["statusName"];
  //   d["Order Date"] = moment(d["orderDate"]).format("DD/MM/YYYY");
  //   d["Delivery Method"] = d["deliveryMethodName"];
  //   d["Receiver Company"] = d["companyName"];
  //   d["Receiver Name"] = d["contactPersonName"];
  //   d["Receiver Country"] = d["countryName"];
  //   d["Remarks"] = d["remarks"];
  //   d["Tracking Number"] = d["trackingNumber"];
  //   d["Modified By"] = d["modifiedByName"];
  //   d["Modified Date"] = moment(d["modifiedDate"]).format(
  //     "DD/MM/YYYY HH:mm:ss"
  //   );
  //   d["Created By"] = d["createdByName"];
  //   d["Created Date"] = moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss");

  //   delete d["bpaCode"];
  //   delete d["statusName"];
  //   delete d["orderDate"];
  //   delete d["companyName"];
  //   delete d["deliveryMethodName"];
  //   delete d["countryName"];
  //   delete d["contactPersonName"];
  //   delete d["countryName"];

  //   delete d["remarks"];
  //   delete d["trackingNumber"]
  //   delete d["createdDate"];
  //   delete d["modifiedDate"];
  //   delete d["createdByName"];
  //   delete d["modifiedByName"];
  //   delete d["countryID"];
  //   delete d["address"];
  //   delete d["address2"];
  //   delete d["bpaOrderHeaderID"];
  //   delete d["bpaRecipientID"];
  //   delete d["bpaRunningID"];
  //   delete d["bpaSuffix"];
  //   delete d["conferenceName"];
  //   delete d["conferenceRef"];
  //   delete d["costCoding"];
  //   delete d["createdBy"];
  //   delete d["deliveryInstructions"];
  //   delete d["deliveryMethodID"];

  //   delete d["deliveryMethodName"];
  //   delete d["department"];
  //   delete d["doNotDeliveryBefore"];
  //   delete d["emailID"];
  //   delete d["modifiedBy"];
  //   delete d["mustBeDeliveryBy"];
  //   delete d["mustBeDeliveryOn"];
  //   delete d["packingInstructions"];
  //   delete d["phoneNumber"];
  //   delete d["postalCode"];
  // });

  // let expData = clone(
  //   props.codeTypeList && props.codeTypeList.length > 0 && props.codeTypeList
  // );
  excelFileData &&
    excelFileData.length > 0 &&
    excelFileData.map((d) => {
      // d["emloOrderID"]=d["emloOrderID"];
      d["Company Name"] = d["companyName"];
      // d["Type"] = d["type"];
      d["Status"] = d["status"];
      d["BPA"] = d["emloNo"];
      d["Acronym"] = d["acronym"];
      d["Volume"] = d["volume"];
      d["Issue"] = d["issue"];
      d["Part"] = d["part"];
      d["Supp"] = d["supp"];
      d["Quantity"] = d["qty"];
      d["Country"] = d["country"];
      d["Contact Person"] = d["recipient"];
      d["Order Date"] = d["orderDate"] ? moment(d["orderDate"]).format("DD/MM/YYYY") : ""
      d["Delivery Date"] = d["deliveryDate"] ? moment(d["deliveryDate"]).format("DD/MM/YYYY") : "";
      d["Tracking Number"] = d["trackingNumber"];
      d["No Of Cartons"] = d["noOfCartons"];
      d["DHL Rate"] = d["dhlRate"];
      d["Total Weight"] = d["totalWeight"];
      d["Special Instruction"] = d["specialInstruction"];
      d["Modified By Name"] = d["modifiedByName"];
      d["Modified Date"] = moment(d["modifiedDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      d["Created By Name"] = d["createdByName"];
      d["Created Date"] = moment(d["createdDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      delete d["emloOrderID"];
      delete d["id"];
      delete d["status"];
      delete d["volume"];
      delete d["modifiedDate"];
      delete d["modifiedByName"];
      delete d["createdDate"];
      delete d["createdByName"];
      delete d["client"];
      delete d["companyName"];
      delete d["emloNo"];
      delete d["type"];
      delete d["acronym"];
      delete d["issue"];
      delete d["part"];
      delete d["supp"];
      delete d["trackingNumber"];
      delete d["deliveryDate"];
      delete d["qty"];
      delete d["country"];
      delete d["noOfCartons"];
      delete d["recipient"];
      delete d["orderDate"];
      delete d["totalWeight"];
      delete d["dhlRate"];
      delete d["specialInstruction"];
    });
  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteCountry = () => {
    handleDeleteCountry();
  };
  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = countrySearchValue;
    searchValues.countryCode = "";
    searchValues.countryName = "";
    searchValues = countrySearchValue;
    setIsformValid(false);
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };
  const deleteProductionLineCode = () => {
    handledeleteProductionLineCode();
  };
  // console.log(props)
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_bpaOrderPlacement/${props.checkedRow.emloOrderID}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}

                {/* {acnObj &&
                  acnObj.editAction &&
                  selectedStatus !== "Cancelled" &&
                  selectedStatus !== "Processed" &&
                  selectedStatus !== "In-Progress" &&
                  selectedStatus !== "Completed" &&
                  selectedStatus !== "Dispatched" ? (
                    <Tooltip title="Edit">
                      <Link to={`/edit_bpaOrderPlacement/${props.checkedRow.emloOrderID}`}>
                        <IconButton
                          aria-label="edit"
                          className={classes.iconHover}
                        >
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  ) : null} */}

                {acnObj &&
                  acnObj.editAction && props.checkedRow.status === "Submitted" ||
                  props.checkedRow.status === "SavedAsDraft" ||
                  props.checkedRow.status === "Saved As Draft" ||
                  props.checkedRow.status === "Completed" ||
                  props.checkedRow.status === "Processed" ? <Tooltip title="Edit">
                    <Link to={`/edit_bpaOrderPlacement/${props.checkedRow.emloOrderID}`}>
                      <IconButton
                        aria-label="edit"
                        className={classes.iconHover}
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </Tooltip> : ""
                }
              </div>
            )}
            {/* {acnObj &&
              acnObj.deleteAction &&
              selectedStatus !== "Cancelled" &&
              selectedStatus !== "Processed" &&
              selectedStatus !== "In-Progress" &&
              selectedStatus !== "Completed" &&
              selectedStatus !== "Dispatched" ? (
                <Tooltip title="Delete">
                  <IconButton aria-label="delete" onClick={handleClickOpen}>
                    <DeleteIcon className={classes.iconHover} />
                  </IconButton>
                </Tooltip>
              ) : null} */}
            {acnObj &&
              acnObj.deleteAction &&
              props.checkedRow.status === "Submitted" ||
              props.checkedRow.status === "SavedAsDraft" ||
              props.checkedRow.status === "Saved As Draft" ||
              props.checkedRow.status === "Processed" ? (
                <Tooltip title="Delete">
                  <IconButton aria-label="delete" onClick={handleClickOpen}>
                    <DeleteIcon className={classes.iconHover} />
                  </IconButton>
                </Tooltip>
              ) : ""}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1
                      ? " ?"
                      : props.checkedRow.emloNo + " ?"}
                    {/* {selectedebloID.codeType} */}
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteProductionLineCode}
                  color="primary"
                  variant="contained"
                  style={{ boxShadow: "none" }}
                  className="enabled-button"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
              }}
            >
              {/* { BPA Order Processing Button should only show for Singpost user, the user boolean value will be stored in the localstorage  === > Note to self} */}
              {props.singpost == "true" && bpaOPObj && bpaOPObj.addAction ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => props.history.push("/pubBpaBatchProcessing")}
                >
                  {" "}
                BPA Order Processing{" "}
                </Button>
              ) : null}
              {props.searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={(e) => props.clearSearch()}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  acnObj &&
                  acnObj.advanceSearchAction && (
                    <Tooltip title="Advance search">
                      <React.Fragment>
                        {" "}
                        <AdvanceSearch
                          onSearchClicked={props.onSearchClicked}
                          onRequestSucceed={props.onRequestSucceed}
                        />
                      </React.Fragment>
                    </Tooltip>
                  )
                )}

              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <Link to="/add_bpaOrderPlacement">
                    {" "}
                    <IconButton aria-label="add" className={classes.iconHover}>
                      <AddIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              )}
              {acnObj && acnObj.exportAction && (
                <ExportCSV
                  csvData={excelFileData}
                  fileName={"BPAOrderPlacement"}
                />
              )}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                handleViewColumns={handleViewColumns}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
function getModalStyle() {
  const top = 10.2;
  const left = 77;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    handleViewColumns,
    open,
    ...other
  } = props;
  const [column, setColumnIds] = React.useState(valueProp);
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  }, [valueProp, open]);

  const handleClose = () => {
    onClose(setColumnIds);
  };

  const handleChange = (event) => {
    setColumnIds(event.target.value);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => handleViewColumns(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class BPAOrderPlacement extends React.Component {
  static contextType = StateContext;
  state = {
    singpost: localStorage.isSingpostUser,
    selectedStatus: "",
    checkedRow: {},
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    selectedRows: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    editable: true,
    removable: true,
    orderPlacementList: [],
    loading: false,
    selectedLocalPostage: {},
    selectedLocalPostageID: 1,
    selectedShowColumns: [],
    searchPopupOpen: false,
    countrySearchValues: {},
    searchClicked: false,
    headCells: [
      {
        id: 1,
        code: "companyName",
        numeric: false,
        disablePadding: false,
        label: "Company Name",
        show: true,
      },
      {
        id: 2,
        code: "type",
        numeric: false,
        disablePadding: false,
        label: "Type",
        show: false,
      },
      {
        id: 3,
        code: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        show: true,
      },
      {
        id: 4,
        code: "emloNo",
        numeric: false,
        disablePadding: false,
        label: "BPA",
        show: true,
      },
      {
        id: 5,
        code: "acronym",
        numeric: false,
        disablePadding: false,
        label: "Acronym",
        show: true,
      },
      {
        id: 6,
        code: "volume",
        numeric: false,
        disablePadding: false,
        label: "Volume",
        show: true,
      },
      {
        id: 7,
        code: "issue",
        numeric: false,
        disablePadding: false,
        label: "Issue",
        show: true,
      },
      {
        id: 8,
        code: "part",
        numeric: false,
        disablePadding: false,
        label: "Part",
        show: true,
      },
      {
        id: 9,
        code: "supp",
        numeric: false,
        disablePadding: false,
        label: "Supp",
        show: true,
      },
      {
        id: 10,
        code: "qty",
        numeric: false,
        disablePadding: false,
        label: "Quantity",
        show: true,
      },
      {
        id: 11,
        code: "country",
        numeric: false,
        disablePadding: false,
        label: "Country",
        show: true,
      },
      {
        id: 12,
        code: "recipient",
        numeric: false,
        disablePadding: false,
        label: "Contact Person",
        show: true,
      },
      {
        id: 13,
        code: "orderDate",
        numeric: false,
        disablePadding: false,
        label: "Order Date",
        show: true,
      },
      {
        id: 14,
        code: "deliveryDate",
        numeric: false,
        disablePadding: false,
        label: "Delivery Date",
        show: true,
      },
      {
        id: 15,
        code: "trackingNumber",
        numeric: false,
        disablePadding: false,
        label: "Tracking Number",
        show: true,
      },
      {
        id: 16,
        code: "noOfCartons",
        numeric: false,
        disablePadding: false,
        label: "No Of Cartons",
        show: true,
      },
      {
        id: 17,
        code: "dhlRate",
        numeric: false,
        disablePadding: false,
        label: "DHL Rate",
        show: true,
      },
      {
        id: 18,
        code: "totalWeight",
        numeric: false,
        disablePadding: false,
        label: "Total Weight",
        show: true,
      },
      {
        id: 19,
        code: "specialInstruction",
        numeric: false,
        disablePadding: false,
        label: "Special Instructions",
        show: true,
      },
      {
        id: 20,
        code: "modifiedByName",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 21,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 22,
        code: "createdByName",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
      {
        id: 23,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
    ],
    // headCells: [
    //   {
    //     code: "bpaCode",
    //     id: 1,
    //     show: false,
    //     numeric: false,
    //     disablePadding: true,
    //     label: "Order Id",
    //     show: true,
    //   },
    //   {
    //     code: "statusName",
    //     id: 2,
    //     show: false,
    //     numeric: false,
    //     disablePadding: true,
    //     label: "Status",
    //     show: true,
    //   },
    //   {
    //     code: "orderDate",
    //     id: 3,
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Order Date",
    //     show: true,
    //   },
    //   {
    //     id: 4,
    //     code: "deliveryMethodName",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Delivery Method",
    //     show: true,
    //   },
    //   {
    //     id: 5,
    //     code: "companyName",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Receiver Company",
    //     show: true,
    //   },
    //   {
    //     id: 6,
    //     code: "contactPersonName",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Receiver Name",
    //     show: true,
    //   },
    //   {
    //     id: 7,
    //     code: "countryName",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Receiver Country",
    //     show: true,
    //   },
    //   {
    //     id: 8,
    //     code: "remarks",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Remarks",
    //     show: true,
    //   }
    //   ,
    //   {
    //     id: 9,
    //     code: "trackingNumber",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Tracking Number",
    //     show: true,
    //   },
    //   {
    //     id: 10,
    //     code: "modifiedByName",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Modified By",
    //     show: true,
    //   },

    //   {
    //     id: 11,
    //     code: "modifiedDate",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Modified Date",
    //     show: true,
    //   },
    //   {
    //     id: 12,
    //     code: "createdByName",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Created By",
    //     show: true,
    //   },

    //   {
    //     id: 13,
    //     code: "createdDate",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Created Date",
    //     show: true,
    //   },
    // ],
  };
  handleViewColumns = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  componentDidMount() {
    this.props.handleSettingHeader("Order Placement");
    this.props.setSelectedList("bpaMarketing", "bpaOrderPlacement");
    const [{ store }, dispatch] = this.context;
    const userID = this.state.singpost == "true" ? 0 : localStorage.loggedInId;
    getBpaOrderAll(userID).then((r) => {
      dispatch({
        type: "getPlacedOrderList",
        newStore: r.data,
      });
      this.getAllPlacedOrder(r.data);
    });
    this.setState({ loading: true });
  }
  shouldComponentUpdate(s, p) {
    const [{ store }, dispatch] = this.context;
    // if (this.state.rows.length !== store.placedOrderList.length) {
    //   !this.state.searchClicked &&
    //     this.getAllPlacedOrder(store.placedOrderList);
    // }
    // if (this.state.rows.length !== 0) {
    //   this.state.rows.map((cl, i) => {
    //     if (cl.ststus != store.placedOrderList[i].isActive)
    //       !this.state.searchClicked &&
    //         this.getAllPostageList(store.placedOrderList);
    //   });
    // }
    return true;
  }
  handledeleteProductionLineCode = () => {
    const deletionID = this.state.checkedRow.emloOrderID;
    const modifiedBy = Number(localStorage.loggedInId);
    const userID = this.state.singpost == "true" ? 0 : localStorage.loggedInId;
    deleteBPAOrder(deletionID, modifiedBy).then((r) => {
      getBpaOrderAll(userID).then((r) => {
        this.getAllPlacedOrder(r.data);
      });
      this.setState({
        deletePopupOpen: false,
        selected: [],
        selectedEBLOID: "",
        SelectedEBLOOrderFileHeaderID: "",
      });
    });
  };
  onRequestSucceed = (deleted) => {
    const [{ store }, dispatch] = this.context;
    getPlacedOrder().then((r) => {
      dispatch({
        type: "getPlacedOrderList",
        newStore: r.data,
      });
      if (deleted) this.setState({ searchClicked: false, selected: [] });
      this.getAllPlacedOrder(r.data);
    });
  };
  getAllPlacedOrder = (lpList) => {
    let list = lpList;
    let newRows = [];

    list &&
      list.length > 0 &&
      list.map((d, io) => {
        newRows.push(
          // createData(
          //   d.address,
          //   d.address2,
          //   d.bpaCode,
          //   d.bpaOrderHeaderID,
          //   d.bpaRecipientID,
          //   d.bpaRunningID,
          //   d.bpaSuffix,
          //   d.companyName,
          //   d.conferenceName,
          //   d.conferenceRef,
          //   d.contactPersonName,
          //   d.costCoding,
          //   d.countryID,
          //   d.countryName,
          //   d.createdBy,
          //   d.createdByName,
          //   d.createdDate,
          //   d.deliveryInstructions,
          //   d.deliveryMethodID,
          //   d.deliveryMethodName,
          //   d.department,
          //   d.doNotDeliveryBefore,
          //   d.emailID,
          //   d.modifiedBy,
          //   d.modifiedByName,
          //   d.modifiedDate,
          //   d.mustBeDeliveryBy,
          //   d.mustBeDeliveryOn,
          //   d.orderDate,
          //   d.packingInstructions,
          //   d.phoneNumber,
          //   d.postalCode,
          //   d.remarks,
          //   d.statusName,
          //   d.trackingNumber,
          // )
          createData(
            d.bpaOrderHeaderID,
            d.companyName,
            d.type,
            d.statusName,
            d.bpaCode,
            d.acronym,
            d.volume,
            d.issue,
            d.partNumber,
            d.suppNumber,
            d.orderQuantity,
            d.countryName,
            d.contactPersonName,
            d.orderDate,
            d.deliveredDate,
            d.trackingNumber,
            d.noOfCartons,
            d.dhlRate,
            d.actualWeight,
            d.deliveryInstructions,
            d.modifiedByName,
            d.modifiedDate,
            d.createdByName,
            d.createdDate,
            d.bpaOrderDetailID
          )
        );
      });
    this.setState({ rows: newRows, loading: false });
    initailData = newRows;
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { rows } = this.state;
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, data, id) {
    let newSelected = [];
    let selected = [];
    let newSelectedRows = [];
    let selectedStatus = data.statusName;

    if (event.target.checked) {
      newSelected = newSelected.concat(selected, id);
      newSelectedRows = newSelected.concat(id);
      this.setState({
        selected: newSelected,
        selectedemloID: data,
        selectedemloIDId: id,
        checkedRow: data,
        selectedRows: newSelectedRows,
        selectedStatus,
      });
    } else {
      this.setState({
        checkedRow: {},
        selected: newSelected,
        selectedemloID: data,
        selectedemloIDId: id,
        selectedRows: newSelectedRows,
        selectedStatus: "",
      });
    }
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleDeleteCountry = () => {
    let { selected } = this.state;
    const [{ store }, dispatch] = this.context;
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");
  };
  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchCountry = (handleSearchCountry) => {
    const [{ store }, dispatch] = this.context;

    let countryValues = handleSearchCountry;
    let countryCode = countryValues.countryCode;
    let countryName = countryValues.countryName;
    let reqBody = { countryCode, countryName };

    this.setState({ searchPopupOpen: false, searchClicked: true });
  };

  onSearchClicked = (reqBody) => {
    this.setState({ rows: [], loading: true });
    serchBPAOrder(reqBody).then((r) => {
      if (r && r.data) this.getAllPlacedOrder(r && r.data);
    });
    this.setState({ searchClicked: true });
  };
  clearSearch = () => {
    this.setState({ rows: [], loading: true });
    const [{ store }, dispatch] = this.context;
    const userID = this.state.singpost == "true" ? 0 : localStorage.loggedInId;
    getBpaOrderAll(userID).then((r) => {
      this.getAllPlacedOrder(r.data);
    });
    this.setState({ searchClicked: false });
  };

  render() {
    const [{ store }, dispatch] = this.context;
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      rows,
      selectedLocalPostage,
      selectedLocalPostageID,
      headCells,
      selectedRows,
    } = this.state;
    // console.log(this.state.rows)
    const isSelected = (id) => selected.indexOf(id) !== -1;
    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedLocalPostage={selectedLocalPostage}
            selectedLocalPostageID={selectedLocalPostageID}
            history={this.props.history}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchCountry={this.handleSearchCountry}
            handledeleteProductionLineCode={this.handledeleteProductionLineCode}
            countrySearchValue={this.state.countrySearchValue}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteCountry={this.handleDeleteCountry}
            handleViewColumns={this.handleViewColumns}
            headCells={headCells}
            orderPlacementList={rows}
            selected={selected}
            onSearchClicked={this.onSearchClicked}
            editable={this.state.editable}
            removable={this.state.removable}
            selectedRows={selectedRows}
            rows={this.state.rows}
            checkedRow={this.state.checkedRow}
            roles={this.props.roles}
            selectedStatus={this.state.selectedStatus}
            singpost={this.state.singpost}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{
                  borderLeft: "1px solid  rgba(224, 224, 224, 1)",
                  whiteSpace: "nowrap",
                }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={rows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {rows.length > 0 ? (
                    stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            //  hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    onClick={(event) =>
                                      this.handleClick(
                                        event,

                                        row,
                                        row.id
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                            {/* {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.bpaCode && row.bpaCode.length > 16 ? (
                                  <Tooltip title={row.bpaCode}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.bpaCode}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.bpaCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row && (
                                    <FiberManualRecordIcon
                                      style={{
                                        fontSize: "11px",
                                        color: row.statusName.includes(
                                          "SavedAsDraft"
                                        )
                                          ? "#16831F"
                                          : row.statusName.includes(
                                            "In-Progress"
                                          )
                                            ? "#f4b43c"
                                            : row.statusName.includes("Cancelled")
                                              ? "red"
                                              : row.statusName.includes("Submitted")
                                                ? "green"
                                                : row.statusName.includes(
                                                  "Order Placed"
                                                )
                                                  ? "#0091ff"
                                                  : row.statusName.includes("Processed")
                                                    ? "#0d6cdf"
                                                    : row.statusName.includes(
                                                      "Dispatched"
                                                    )
                                                      ? "#F4B43C"
                                                      : row.statusName.includes(
                                                        "Completed"
                                                      )
                                                        ? "green"
                                                        : "black",
                                      }}
                                    />
                                  )}
                                  <span
                                    style={{
                                      marginLeft: ".5em",
                                      color: row.statusName.includes(
                                        "SavedAsDraft"
                                      )
                                        ? "#16831F"
                                        : row.statusName.includes("In-Progress")
                                          ? "#f4b43c"
                                          : row.statusName.includes("Cancelled")
                                            ? "red"
                                            : row.statusName.includes("Submitted")
                                              ? "green"
                                              : row.statusName.includes(
                                                "Order Placed"
                                              )
                                                ? "#0091ff"
                                                : row.statusName.includes("Processed")
                                                  ? "#0d6cdf"
                                                  : row.statusName.includes("Dispatched")
                                                    ? "#F4B43C"
                                                    : row.statusName.includes("Completed")
                                                      ? "green"
                                                      : "black",
                                    }}
                                  >
                                    {row.statusName}
                                  </span>
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.orderDate &&
                                    row.orderDate.length > 16 ? (
                                      <Tooltip
                                        title={moment(row.orderDate).format(
                                          "DD/MM/YYYY"
                                        )}
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {moment(row.orderDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.orderDate).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.deliveryMethodName &&
                                    row.deliveryMethodName.length > 16 ? (
                                      <Tooltip title={row.deliveryMethodName}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.deliveryMethodName}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.deliveryMethodName}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.companyName &&
                                  row.companyName.length > 16 ? (
                                    <Tooltip title={row.companyName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.companyName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.companyName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.contactPersonName &&
                                  row.contactPersonName.length > 16 ? (
                                    <Tooltip title={row.contactPersonName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.contactPersonName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.contactPersonName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.countryName &&
                                  row.countryName.length > 16 ? (
                                    <Tooltip title={row.countryName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.countryName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.countryName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.remarks && row.remarks.length > 16 ? (
                                  <Tooltip title={row.remarks}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.remarks}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.remarks}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.trackingNumber && row.trackingNumber.length > 16 ? (
                                  <Tooltip title={row.trackingNumber}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.trackingNumber}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.trackingNumber}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.modifiedByName &&
                                  row.modifiedByName.length > 16 ? (
                                    <Tooltip title={row.modifiedByName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.createdByName &&
                                  row.createdByName.length > 16 ? (
                                    <Tooltip title={row.createdByName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.createdByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )} */}
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.companyName && row.companyName.length > 16 ? (
                                    <Tooltip title={row.companyName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.companyName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.companyName}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}

                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.type && row.type.length > 16 ? (
                                  <Tooltip title={row.type}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.type}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.type}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.status && row.status.length > 10 ? (
                                    <Tooltip title={row.status}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.status == "Submitted" ? (
                                          <StatusText color={"#16831F"}>
                                            Submitted
                                          </StatusText>
                                        ) : row.status == "UnSubmitted" ? (
                                          <StatusText color={"#F4B43C"}>
                                            UnSubmitted
                                          </StatusText>
                                        ) : row.status == "Processed" ? (
                                          <StatusText color={"#0091ff"}>
                                            Processed
                                          </StatusText>
                                        ) : row.status == "In-Progress" ? (
                                          <StatusText color={"#f4b43c"}>
                                            In-Progress
                                          </StatusText>
                                        ) : row.status == "Cancelled" ? (
                                          <StatusText color={"red"}>
                                            Cancelled
                                          </StatusText>
                                        ) : row.status == "Completed" ? (
                                          <StatusText color={"#16831F"}>
                                            Completed
                                          </StatusText>
                                        ) : (
                                                      <StatusText color={"#16831F"}>
                                                        {row.status === "SavedAsDraft" ? "Saved As Draft" : row.status}
                                                      </StatusText>
                                                    )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.status == "Submitted" ? (
                                          <StatusText color={"#16831F"}>
                                            Submitted
                                          </StatusText>
                                        ) : row.status == "UnSubmitted" ? (
                                          <StatusText color={"#F4B43C"}>
                                            UnSubmitted
                                          </StatusText>
                                        ) : row.status == "Processed" ? (
                                          <StatusText color={"#0091ff"}>
                                            Processed
                                          </StatusText>
                                        ) : row.status == "In-Progress" ? (
                                          <StatusText color={"#0091ff"}>
                                            In-Progress
                                          </StatusText>
                                        ) : row.status == "Cancelled" ? (
                                          <StatusText color={"red"}>
                                            Cancelled
                                          </StatusText>
                                        ) : row.status == "Completed" ? (
                                          <StatusText color={"#16831F"}>
                                            Completed
                                          </StatusText>
                                        ) : (
                                                      <StatusText color={"#16831F"}>
                                                        {row.status === "SavedAsDraft" ? "Saved As Draft" : row.status}
                                                      </StatusText>
                                                    )}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                // className="table-content-cell"
                                style={{ width: "auto", padding: ".5em" }}
                              >
                                {row.emloNo && row.emloNo.length > 12 ? (
                                  <Tooltip title={row.emloNo}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.emloNo}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.emloNo}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.acronym && row.acronym.length > 16 ? (
                                  <Tooltip title={row.acronym}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.acronym}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.acronym}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.volume && row.volume.length > 16 ? (
                                  <Tooltip title={row.volume}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.volume}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.volume}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.issue && row.issue.length > 16 ? (
                                  <Tooltip title={row.issue}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issue}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issue}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.part && row.part.length > 16 ? (
                                  <Tooltip title={row.part}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.part}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.part}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.supp && row.supp.length > 16 ? (
                                  <Tooltip title={row.supp}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.supp}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.supp}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.qty && row.qty.length > 16 ? (
                                  <Tooltip title={row.qty}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.qty}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.qty}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.country &&
                                  row.country.length > 16 ? (
                                    <Tooltip title={row.country}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.country}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.country}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.recipient &&
                                  row.recipient.length > 16 ? (
                                    <Tooltip title={row.recipient}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.recipient}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.recipient}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.orderDate &&
                                  moment(row.orderDate).format("DD/MM/YYYY")
                                    .length > 16 ? (
                                    <Tooltip
                                      title={
                                        row.orderDate
                                          ? moment(row.orderDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""
                                      }
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.orderDate
                                          ? moment(row.orderDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.orderDate
                                        ? moment(row.orderDate).format(
                                          "DD/MM/YYYY"
                                        )
                                        : ""}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.deliveryDate &&
                                  moment(row.deliveryDate).format("DD/MM/YYYY")
                                    .length > 16 ? (
                                    <Tooltip
                                      title={
                                        row.deliveryDate
                                          ? moment(row.deliveryDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""
                                      }
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.deliveryDate
                                          ? moment(row.deliveryDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : ""}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.deliveryDate
                                        ? moment(row.deliveryDate).format(
                                          "DD/MM/YYYY"
                                        )
                                        : ""}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.trackingNumber && row.trackingNumber.length > 12 ? (
                                  <Tooltip title={row.trackingNumber}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.trackingNumber}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.trackingNumber}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[15].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.noOfCartons && row.noOfCartons.length > 16 ? (
                                  <Tooltip title={row.noOfCartons}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noOfCartons}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.noOfCartons}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[16].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.dhlRate && row.dhlRate.length > 16 ? (
                                  <Tooltip title={row.dhlRate}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dhlRate}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.dhlRate}
                                    </Typography>
                                  )}
                              </TableCell>

                            )}
                            {this.state.headCells[17].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.totalWeight && row.totalWeight.length > 16 ? (
                                  <Tooltip title={row.totalWeight}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalWeight}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalWeight}
                                    </Typography>
                                  )}
                              </TableCell>

                            )}
                            {this.state.headCells[18].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.specialInstruction && row.specialInstruction.length > 16 ? (
                                  <Tooltip title={row.specialInstruction}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.specialInstruction}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.specialInstruction}
                                    </Typography>
                                  )}
                              </TableCell>

                            )}
                            {this.state.headCells[19].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.modifiedByName &&
                                  row.modifiedByName.length > 16 ? (
                                    <Tooltip title={row.modifiedByName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[20].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip title={row.modifiedDate}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[21].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdByName &&
                                  row.createdByName.length > 16 ? (
                                    <Tooltip title={row.createdByName}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.createdByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[22].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-right-cell"
                                visibility="hidden"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip title={row.createdDate}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={10} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(BPAOrderPlacement);

// import React from "react";
// export default class LocalPostage extends React.Component {
//   render() {
//     return <div>dssdsd</div>;
//   }
// }
