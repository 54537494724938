import React from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Paper,
  Divider,
  Typography,
  InputBase,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  GetDisplayWOInJGView,
  GetJobGroupsListByWOID,
  DeleteJobGroup,
} from "./action";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import * as QueryString from "query-string";
import { ViewIcon, EditIcon } from "../../../shared/customIcons";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
const headCells = [
  {
    id: 1,
    code: "jobGroupNo",
    numeric: false,
    disablePadding: true,
    label: "Job Group No",
    show: true,
  },
  {
    id: 2,
    code: "jobGroupTitle",
    numeric: false,
    disablePadding: false,
    label: "Job Group Title",
    show: true,
  },

  {
    id: 3,
    code: "orderQty",
    numeric: false,
    disablePadding: false,
    label: "Order Qty",
    show: true,
  },
  {
    id: 4,
    code: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    show: true,
  },

  {
    id: 5,
    code: "mbaStatus",
    numeric: false,
    disablePadding: false,
    label: "MBA Status",
    show: true,
  },
  {
    id: 6,
    code: "owcStatus",
    numeric: false,
    disablePadding: false,
    label: "OWC Status",
    show: true,
  },
  // {
  //   id: 6,
  //   code: "docketStatus",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Docket Status",
  //   show: false
  // },
  {
    id: 7,
    code: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    show: true,
  },
];
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "29%",
    fontSize: "11px !important",
  },
  autocompleteText: {
    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
  search: {
    position: "relative",
    minWidth: "500px",
    display: "flex",
    // "&:onChange": {
    //     color: blue[800]
    //   }
  },
  searchIcon: {
    position: "absolute",
    right: "0",
  },
});

let workOrderID = 0;
class ViewWorkOrder extends React.Component {
  constructor() {
    super();
    this.state = {
      jobGroupsList: [],
      woDisplayDetails: {
        workOrderID: "",
        customerName: "",
        workOrderNumber: "",
        projectTitle: "",
        jobGroupNumber: "",
        splInstructions: "",
        status: "",
      },
    };
  }

  componentDidMount() {
    this.callCDMAPIs()
  }
  callCDMAPIs = () => {
    const params = QueryString.parse(this.props.location.search);
    workOrderID = params.workOrderID;
    this.props.handleSettingHeader("Work Order");
    GetDisplayWOInJGView(workOrderID).then((r) => {
      this.setState({ woDisplayDetails: r });
    });
    //console.log(this.props)
    if (this.props && this.props.location && this.props.location.state && this.props.location.state.jgFoundNo) {
      this.props.history.push({
        pathname: "/jobGroup",
        search: "?workOrderID=" + workOrderID + "&jobGroupID=" + this.props.location.state.jgFoundNo,
        state: { workOrderMode: this.props.workOrderMode },
      });
    }
    else
      GetJobGroupsListByWOID(workOrderID).then((r) => {
        if (r && r.length === 1) {
          let id = r[0].jobGroupID;
          this.props.history.push({
            pathname: "/jobGroup",
            search: "?workOrderID=" + workOrderID + "&jobGroupID=" + id,
            state: { workOrderMode: this.props.workOrderMode },
          });
        }
        this.setState({ jobGroupsList: r });
      });
  }

  onDeleteClicked = (event, index, row) => {
    if (
      row.jobGroupID !== undefined &&
      row.jobGroupID !== null &&
      row.jobGroupID !== 0
    ) {
      DeleteJobGroup(row.jobGroupID, localStorage.loggedInId).then(d => {
        this.callCDMAPIs()
      })
    }
  };

  render() {
    let { jobGroupsList, woDisplayDetails } = this.state;
    let { classes } = this.props;
    return (
      <Paper position="static">
        <div className={classes.root}>
          <div className={classes.container} style={{ marginTop: "0%" }}>
            <div style={{ width: "100%" }}>
              <Typography
                component="div"
                className="blueBox"
                style={{ marginTop: "2px" }}
              >
                <div className="blueBoxLabel">
                  <div>Customer: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.customerName}
                  </div>
                </div>
                <div className="blueBoxLabel">
                  <div>Work Order: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.workOrderNumber}
                  </div>
                </div>
                <div className="blueBoxLabel">
                  <div>Project Title: </div>
                  <div className="blueBoxText">
                    {woDisplayDetails.projectTitle}
                  </div>
                </div>
              </Typography>
            </div>
            <div style={{ width: "100%", marginTop: "3px" }}>
              <Typography
                component="div"
                className="blueBox"
                style={{ marginTop: "2px" }}
              >
                {/* {(
                  <div className="blueBoxLabel">
                    <div>Job Group: </div>
                    <div className="blueBoxText">
                      {woDisplayDetails.jobGroupNumber}
                    </div>
                  </div>
                )} */}

                <div className="blueBoxLabel">
                  <div>WO Status: </div>
                  <div className="blueBoxText">{woDisplayDetails.status}</div>
                </div>
                <div className="blueBoxLabel">
                  <div> </div>
                  <div className="blueBoxText"></div>
                </div>
              </Typography>
            </div>

            <Paper style={{ width: "100%", marginTop: "2%" }}>
              <div style={{ width: "100%", maxHeight: "calc(100vh - 250px)" }}>
                <div
                  style={
                    !this.props.clearOpenList
                      ? {
                        overflow: "auto",
                        maxWidth: "calc(100vw - 8em)",
                        maxHeight: "calc(100vh - 300px)",
                      }
                      : {
                        overflow: "auto",
                        maxWidth: "calc(100vw - 330px)",
                        maxHeight: "calc(100vh - 300px)",
                      }
                  }
                >
                  <Table
                    aria-labelledby="tableTitle"
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        {headCells.map((headCell, i) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={
                              headCell.disablePadding ? "none" : "default"
                            }
                            className={
                              i !== headCells.length - 1
                                ? "headcells"
                                : "headcell"
                            }
                          >
                            <span style={{ color: "#306dca" }}>
                              {headCell.label}
                            </span>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {jobGroupsList.map((row, index) => {
                        return (
                          <TableRow tabIndex={-1} key={row.jobGroupID}>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.jobGroupNumber}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.jobGroupTitle}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="right"
                              padding="default"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.jobQuantity}
                              </Typography>
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {row.statusName}
                              </Typography>
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {row.isMBACompleted === true ? "Yes" : "No"}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {row.isOWCCompleted === true ? "Yes" : "No"}
                            </TableCell>
                            {/* <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {row.isDocketGenerated === true ? "Yes" : "No"}
                            </TableCell> */}
                            <TableCell>
                              <Typography
                                noWrap
                                style={{
                                  fontSize: "14px",
                                  display: "flex",
                                }}
                              >
                                <div>
                                  <span>
                                    <Tooltip title="View">
                                      <Link
                                        to={{
                                          pathname: "/jobGroup",
                                          search:
                                            "?workOrderID=" +
                                            workOrderID +
                                            "&jobGroupID=" +
                                            row.jobGroupID,
                                          state: { workOrderMode: "view", isJobFound: true },
                                        }}
                                      >
                                        <span
                                          aria-label="view"
                                          style={{
                                            color: "#6c6b6b",
                                          }}
                                        >
                                          <ViewIcon />
                                        </span>
                                      </Link>
                                    </Tooltip>
                                  </span>
                                  {/* <span>
                                    <Tooltip title="Edit">
                                      <Link
                                        to={{
                                          pathname: "/jobGroup",
                                          search:
                                            "?workOrderID=" +
                                            workOrderID +
                                            "&jobGroupID=" +
                                            row.jobGroupID,
                                          state: { workOrderMode: "edit" }
                                        }}
                                      >
                                        <span
                                          aria-label="view"
                                          style={{
                                            color: "#6c6b6b"
                                          }}
                                        >
                                          <EditIcon />
                                        </span>
                                      </Link>
                                    </Tooltip>
                                  </span> */}
                                  <span
                                    // style={{
                                    //   cursor: "pointer",
                                    //   color: "#6c6b6b",
                                    // }}
                                    style={{
                                      cursor:
                                        row.statusName == "Completed" ||
                                          row.statusName == "Cancelled"
                                          ? "default"
                                          : "pointer",
                                      color:
                                        row.statusName == "Completed" ||
                                          row.statusName == "Cancelled"
                                          ? "rgba(0, 0, 0, 0.26)"
                                          : "#6c6b6b",
                                      pointerEvents:
                                        row.statusName == "Cancelled"
                                          ? "none"
                                          : "all",
                                    }}
                                    onClick={(e) =>
                                      this.onDeleteClicked(e, index, row)
                                    }
                                  >
                                    <DeleteIcon
                                      // style={{
                                      //   cursor: "pointer",
                                      //   color: "#6c6b6b",
                                      // }}
                                      style={{
                                        color:
                                          row.statusName == "Completed" ||
                                            row.statusName == "Cancelled"
                                            ? "rgba(0, 0, 0, 0.26)"
                                            : "#6c6b6b",

                                        cursor:
                                          row.statusName == "Completed" ||
                                            row.statusName == "Cancelled"
                                            ? "pointer"
                                            : "default",
                                        pointerEvents:
                                          row.statusName == "Completed" ||
                                            row.statusName == "Cancelled"
                                            ? "none"
                                            : "all",
                                      }}
                                    />
                                  </span>
                                </div>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </Paper>
            <Divider
              style={{
                marginTop: "25px",
              }}
            />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.props.history.push("/workOrders")}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    );
  }
}

export default withRouter(withStyles(styles)(ViewWorkOrder));
