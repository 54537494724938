import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { styles } from "../../../shared/styles";
import { StateContext } from "../../../shared/globalState";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  seacrhItemMasterAdvanceSearch,
  getInsertMasterList,
  getCustomerForInsertMasterList
} from "../action";
import {
  getStatusForEMLOList
} from "./action";
import { Autocomplete } from "@material-ui/lab";
import { seacrhEmloAdvanceSearch } from "./action";
//import { getFliteredDeptList } from "../masterActions";
const validateNotRequired2 = new Validator("notRequired2");
const validateNotRequired100 = new Validator("notRequired100");
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        ></IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class EmloMasterSearch extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    customerId: "",
    customer: {},
    issueDescription: "",
    emloNo: "",
    acronym: "",
    volume: "",
    issue: "",
    suppNumber: "",
    trackingNumber: "",
    status: 0,
    statusObj: {},
    statusList: [],
    modifiedDateFrom: null,
    modifiedDateTo: null,
    errors: {
      customerId: false,
      issueDescription: false,
      emloNo: false,
      acronym: false,
      volume: false,
      issue: false,
      suppNumber: false,
      trackingNumber: false
    },
    customersList: [],
  };

  componentDidMount() {
    getCustomerForInsertMasterList().then((r) => {
      r &&
        r.data &&
        this.setState({
          customersList: r.data
            .filter((x) => x.text.toLowerCase().indexOf("wiley") != -1)
            .sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });

      // const filtered =
      //   r &&
      //   r.data &&
      //   r.data.reduce(
      //     (a, o) => (o.text == "NA - Wiley" && a.push(o.id), a),
      //     []
      //   );
      // this.setState({
      //   customerId: filtered && filtered[0],
      // });
    });
    getStatusForEMLOList().then((r) => {
      this.getAllOrderStatus(r.data);
    });
  }
  handleAutocompleteCustomer = (e, val) => {
    this.setState({ customerId: val && val.id, customer: val && val });
  };

  getAllOrderStatus = (list) => {
    let statusList = [];
    list &&
      list.length > 0 &&
      (statusList = list
        .filter((c) => c.value !== "OR")
        .sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ));
    let newStatusList = [];
    statusList.map((c) => {
      newStatusList.push({
        key: c.id,
        value: c.value,
        name: c.text,
      });
    });
    this.setState({ statusList: newStatusList });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      open: false,
      // customerId: "",
      issueDescription: "",
      emloNo: "",
      acronym: "",
      volume: "",
      issue: "",
      suppNumber: "",
      status: 0,
      modifiedDateFrom: null,
      modifiedDateTo: null,
      trackingNumber: "",
      errors: {
        customerId: false,
        issueDescription: false,
        emloNo: false,
        acronym: false,
        volume: false,
        issue: false,
        suppNumber: false,
        trackingNumber: ""
      },
    });
  };

  onInputChanged = (e, name, type) => {
    let newError = { ...this.state.errors };
    // if (type === "notRequired2") {
    //   let result = validateNotRequired2(e.target.value);
    //   newError[name] = !result;
    //   this.setState({ errors: newError });
    // } else if (type === "notRequired100") {
    //   let result = validateNotRequired100(e.target.value);
    //   newError[name] = !result;
    //   this.setState({ errors: newError });
    // }
    this.setState({ [name]: e.target.value });
  };

  handleAutocompleteStatus = (e, val) => {
    this.setState({ status: val && val.key, statusObj: val && val });
  };

  onSearchClicked = () => {
    const dispatch = this.context[1];
    let {
      customerId,
      issueDescription,
      emloNo,
      acronym,
      volume,
      issue,
      suppNumber,
      modifiedDateFrom,
      modifiedDateTo,
      status,
      trackingNumber
    } = this.state;
    let reqBody = {
      customerId: customerId ? customerId : 0,
      issueDescription,
      emloNo,
      acronym,
      volume,
      issue,
      suppNumber,
      modifiedDateFrom,
      modifiedDateTo,
      statusID: status,
      trackingNumber
    };
    this.props.onSearchClicked(reqBody);

    this.setState({
      open: false,
      customerId: "",
      issueDescription: "",
      emloNo: "",
      acronym: "",
      volume: "",
      issue: "",
      suppNumber: "",
      modifiedDateFrom: "",
      modifiedDateTo: "",
      statusObj: {},
      trackingNumber: ""
    });
  };
  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkButtonDisability = () => {
    let {
      customerId,
      issueDescription,
      emloNo,
      acronym,
      volume,
      issue,
      suppNumber,
      modifiedDateFrom,
      modifiedDateTo,
      status,
      trackingNumber
    } = this.state;
    if (
      !(modifiedDateFrom
        ? modifiedDateTo
        : customerId ||
        issueDescription ||
        emloNo ||
        acronym ||
        volume ||
        issue ||
        suppNumber ||
        trackingNumber ||
        status)
    )
      return true;
    else return false;
  };
  handleDateChange = (date) => {
    let { modifiedDateFrom } = this.state;
    modifiedDateFrom = date && date;
    this.setState({ modifiedDateFrom });
  };
  handleDateChange1 = (date) => {
    let { modifiedDateTo } = this.state;
    modifiedDateTo = date && date;
    this.setState({ modifiedDateTo });
  };
  render() {
    let {
      customerId,
      issueDescription,
      emloNo,
      acronym,
      volume,
      issue,
      suppNumber,
      modifiedDateFrom,
      modifiedDateTo,
      customersList,
      status,
      statusList,
      trackingNumber
    } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <Tooltip title="Search">
          <IconButton
            aria-label="Search"
            className={classes.iconHover}
            onClick={this.handleClickOpen}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          onClose={this.handleClose}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          maxWidth="md"
        >
          <DialogTitle id="customized-dialog-title">
            {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
            Advanced Search
          </DialogTitle>
          <DialogContent dividers>
            <div style={{ marginTop: "-10px" }}>
              <div style={{ display: "flex" }}>
                {/* <Autocomplete
                  options={customersList}
                  value={this.state.customer}
                  onChange={this.handleAutocompleteCustomer}
                  getOptionLabel={(option) => option.text}
                  style={{ flex: 1, marginRight: "16px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Customer"
                      margin="normal"
                      required
                      fullWidth
                    />
                  )}
                /> */}

                <TextField
                  id="standard-required"
                  label="EMLO No."
                  name="emloNo"
                  margin="normal"
                  value={this.state.emloNo}
                  inputProps={{
                    maxLength: 50,
                  }}
                  style={{ flex: 1, marginRight: "16px" }}
                  autoComplete="off"
                  onChange={(e) =>
                    this.onInputChanged(e, "emloNo", "notRequired2")
                  }
                />
                <TextField
                  id="standard-required"
                  label="Acronym"
                  name="acronym"
                  margin="normal"
                  value={this.state.acronym}
                  inputProps={{
                    maxLength: 50,
                  }}
                  style={{ flex: 1, marginRight: "16px" }}
                  autoComplete="off"
                  onChange={(e) =>
                    this.onInputChanged(e, "acronym", "notRequired2")
                  }
                />
                <TextField
                  id="standard-required"
                  label="Volume"
                  name="volume"
                  margin="normal"
                  value={this.state.volume}
                  inputProps={{
                    maxLength: 50,
                  }}
                  style={{ flex: 1 }}
                  autoComplete="off"
                  onChange={(e) =>
                    this.onInputChanged(e, "volume", "notRequired2")
                  }
                />
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  id="standard-required"
                  label="Issue"
                  name="issue"
                  margin="normal"
                  value={this.state.issue}
                  inputProps={{
                    maxLength: 50,
                  }}
                  style={{ flex: 1, marginRight: "16px" }}
                  autoComplete="off"
                  onChange={(e) =>
                    this.onInputChanged(e, "issue", "notRequired2")
                  }
                />
                <TextField
                  id="standard-required"
                  label="Supplement"
                  name="suppNumber"
                  margin="normal"
                  value={this.state.suppNumber}
                  inputProps={{
                    maxLength: 50,
                  }}
                  style={{ flex: 1, marginRight: "16px" }}
                  autoComplete="off"
                  onChange={(e) =>
                    this.onInputChanged(e, "suppNumber", "notRequired2")
                  }
                />
                <TextField
                  id="standard-required"
                  label="Issue Description"
                  name="issueDescription"
                  margin="normal"
                  value={this.state.issueDescription}
                  inputProps={{
                    maxLength: 50,
                  }}
                  style={{ flex: 1 }}
                  autoComplete="off"
                  onChange={(e) =>
                    this.onInputChanged(e, "issueDescription", "notRequired2")
                  }
                />
              </div>
              <div style={{ display: "flex" }}>
                <Autocomplete
                  options={statusList}
                  value={this.state.statusObj}
                  style={{ flex: 1, marginRight: "16px" }}
                  onChange={this.handleAutocompleteStatus}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    id="modifiedDateFrom"
                    label="Modified Date From"
                    onChange={this.handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    value={this.state.modifiedDateFrom}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disabled={!this.state.modifiedDateFrom}
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    style={{ flex: 1 }}
                    id="modifiedDateTo"
                    minDate={this.state.modifiedDateFrom}
                    label="Modified Date To"
                    value={this.state.modifiedDateTo}
                    onChange={this.handleDateChange1}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  id="standard-required"
                  label="Tracking Number"
                  name="trackingNumber"
                  margin="normal"
                  value={this.state.trackingNumber}
                  inputProps={{
                    maxLength: 50,
                  }}
                  style={{ flex: 1, paddingRight: "26px" }}
                  autoComplete="off"
                  onChange={(e) =>
                    this.onInputChanged(e, "trackingNumber", "notRequired2")
                  }
                />
                <div style={{ flex: 2 }}></div>
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ padding: "10px 24px" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={this.handleClose}
            >
              CANCEL
            </Button>

            <Button
              disabled={
                !(modifiedDateFrom
                  ? modifiedDateTo
                  : customerId ||
                  issueDescription ||
                  emloNo ||
                  acronym ||
                  volume ||
                  issue ||
                  suppNumber ||
                  trackingNumber ||
                  status)
              }
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={(e) => this.onSearchClicked("preAlert")}
            >
              SEARCH
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(EmloMasterSearch);
