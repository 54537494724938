import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_PUBLISHER_MASTER;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const uploadDataMappingFiles = async (data) => {
  let result = [];
  await pgsService
    .post("/CreateDataMapping", data)
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else result = r.data;
    })
    .catch((e) => {
      //   e.response && e.response.data,
      //   "e.response && e.response.data"
      // );
      result =
        (e.response && e.response.data) ||
        (e.response && e.response.data && e.response.data[0]);
    });
  return result;
  // }
};

export const getSubscriptionForDataMapping = async () => {
  let subscriptionList = [];
  await pgsService
    .get("/GetSubscription")
    .then((r) => {
      subscriptionList = r;
    })
    .catch((e) => {});
  return subscriptionList;
};

export const downloadFileForDataMapping = async (id) => {
  let dataMappingList = [];
  await pgsService
    .get("/DetailDownload/" + id)
    .then((r) => {
      dataMappingList = r;
    })
    .catch((e) => {});
  return dataMappingList;
};
