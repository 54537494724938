import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { styles } from "../../../shared/styles";
import { getFliteredDHLRateList } from "../masterActions";
import { StateContext } from "../../../shared/globalState";
import { Autocomplete } from "@material-ui/lab";
import { getDHLZoneList, getCountryDropdownList } from "../masterActions";

const validateMaxLength2 = new Validator("maxlength2");

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        ></IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class DHLRateSearch extends React.Component {
  static contextType = StateContext;

  state = {
    open: false,
    error:false,
    errorMessage: "",
    dhlZone: "",
    dhlCountry: "",
    weightFrom: "",
    weightTo: "",
    year: null,
    selectedZone: false,
    countryID: "",
    dhlZoneList: [],
    deliveryCountryDD: [],
    errors: {
      dhlZone: false,
    },
  };
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    debugger;
    event.preventDefault();
    const data = new FormData(event.target);

    fetch("/api/form-submit-url", {
      method: "POST",
      body: data,
    });
  }

  componentDidMount() {
    getDHLZoneList().then((r) => {
      r.data &&
        r.data.length > 0 &&
        this.setState({
          dhlZoneList: r.data.sort((a, b) =>
            a.dhlZoneCode.toLowerCase() > b.dhlZoneCode.toLowerCase() ? 1 : -1
          ),
        });
    });
    getCountryDropdownList().then((r) => {
      if (r) {
        this.setState({
          deliveryCountryDD:
            r &&
            r.data &&
            r.data.sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
      }
    });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false, dhlZone: "", dhlCountry: "", weightFrom: "", weightTo: "", year: null, countryID: ""});
  };
  onInputChanged = (e, name, type) => {
    let newError = { ...this.state.errors };
    if (type === "maxlength2") {
      let result = validateMaxLength2(e.target.value);
      newError[name] = !result;
    }

    this.setState({ errors: newError });
    this.setState({ [name]: e.target.value });
  };
  onSearchClicked = () => {
    const dispatch = this.context[1];
    const { dhlZone, dhlCountry, weightFrom, weightTo, year, countryID } = this.state;
    let dhlZoneID = dhlZone.dhlZoneID;
    let dhlCountryID = dhlCountry.id;
    console.log("dhlCountry",dhlCountry);
    let reqBody = { 
      dhlZoneID,
      countryID: dhlCountryID ? dhlCountryID : 0,
      weightFrom: weightFrom ? weightFrom : 0,
      weightTo: weightTo ? weightTo : 0,
      year: (year == null) ? 0 : parseInt(year)
    };
    this.props.onSearchClicked();
    getFliteredDHLRateList(reqBody).then((r) => {
      dispatch({
        type: "getDHLRateList",
        newStore: r.data,
      });
      this.props.onRequestSucceed();
    });
    this.setState({ open: false, dhlZone: "", dhlCountry: "", weightFrom: "",  weightTo: "", year: null,  countryID: ""});

    // let { dhlZone} = this.state;
    // this.props.onSearchClicked(dhlZone);
    // this.setState({ open: false, dhlZone: ""});
  };

  handledhlZoneChange = (event, values) => {
    this.setState({ dhlZone: values, selectedZone: true });
  };

  handledhlCountryChange = (event, values) => {
    this.setState({ dhlCountry: values, selectedCountry: true });
  };

  handleChange = (e) => {
    let dhlValues = e.target.value;
    dhlValues = this.checkDHlWeightDec(e.target.value.toString());

    this.setState({ [e.target.name]: dhlValues, error:false, errorMessage:"" });
  };

  handleDateChange = (year) => {
    let selectedYear = year && year.getFullYear().toString();
    this.setState({ year: selectedYear });
  };
  
  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  checkButtonDisability = () => {
    let { dhlZone, weightFrom, weightTo, year, countryID, dhlCountry } = this.state;
    if (
      this.state.errors.dhlZone ||
      // ||this.state.errors.letterShopName ||
      !dhlZone
      // && !letterShopName)
    )
      return true;
    else return false;
  };
  render() {
    let { dhlZone, dhlZoneList, deliveryCountryDD, dhlCountry } = this.state;
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div>
          <Tooltip title="Search">
            <IconButton
              aria-label="Search"
              className={classes.iconHover}
              onClick={this.handleClickOpen}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
            maxWidth="lg"
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            >
              {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
              Advanced Search
            </DialogTitle>
            <DialogContent dividers>
              <React.Fragment>
                <div style={{display: "flex"}}>
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="dhlZone"
                    options={dhlZoneList}
                    style={{ flex: 1, marginRight: "16px" }}
                    onChange={this.handledhlZoneChange}
                    value={dhlZone}
                    getOptionLabel={(option) => option.dhlZoneName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="DHL Zone"
                        name="dhlZone"
                        id="dhlZone"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    views={["year"]}
                    style={{ flex: 1 }}
                    label="DHL Year"
                    id="txtdhlYear"
                    name="year"
                    margin="normal"
                    value={this.state.year}
                    onChange={this.handleDateChange}
                  />
                </div>
                <div style={{display: "flex"}}>
                  <TextField
                    id="standard-required"
                    label="Weight From"
                    name="weightFrom"
                    style={{ flex: 1, marginRight: "16px" }}
                    margin="normal"
                    value={this.state.weightFrom}
                    inputProps={{
                      maxLength: 7,
                    }}
                    autoComplete="off"
                    onKeyPress={this._onDecimalKeyPress}
                    onChange={this.handleChange}
                  />
                  <TextField
                    id="standard-required"
                    label="Weight To"
                    name="weightTo"
                    disabled={!this.state.weightFrom}
                    style={{ flex: 1 }}
                    margin="normal"
                    value={this.state.weightTo}
                    inputProps={{
                      maxLength: 7,
                    }}
                    autoComplete="off"
                    onKeyPress={this._onDecimalKeyPress}
                    onChange={this.handleChange}
                    error={this.state.error}
                    helperText={
                      this.state.error
                        ? this.state.errorMessage
                        : ""
                    }
                  />
                </div>
                <div style={{display: "flex"}}>
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="dhlCountry"
                    options={deliveryCountryDD}
                    style={{ flex: 1, marginRight: "16px" }}
                    onChange={this.handledhlCountryChange}
                    value={dhlCountry}
                    getOptionLabel={(option) => option.text}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="DHL Country"
                        name="dhlCountry"
                        id="dhlCountry"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                  <div style={{flexGrow: 1}}></div>
                </div>
              </React.Fragment>
            </DialogContent>
            <DialogActions style={{ padding: "16px 24px" }}>
              <Button
                variant="contained"
                color="primary"
                //disableElevation
                style={{ boxShadow: "none" }}
                onClick={this.handleClose}
              >
                CANCEL
              </Button>
                <Button
                  disabled={
                    !(
                        this.state.weightFrom ? 
                        this.state.weightTo : 
                        this.state.selectedZone ||
                        this.state.year ||
                        this.state.dhlCountry
                      )
                  }
                  //autoFocus
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.onSearchClicked()}
                  // disabled={this.checkButtonDisability() ? true : false}
                >
                  SEARCH
                </Button>
            </DialogActions>
            {/* <DialogActions>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={e => this.onSearchClicked()}
                disabled={this.checkButtonDisability() ? true : false}
              >
            
                SEARCH
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={this.handleClose}
              >
                CANCEL
              </Button>
            </DialogActions> */}
          </Dialog>
        </div>
      </form>
    );
  }
}
export default withStyles(styles)(DHLRateSearch);
