import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_MASTER_DATA_MANAGEMENT;

const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

/*------------Department functions starts -------------------------*/


export const getIMStatusList = async () => {
  let deptList = [];
  await pgsService
    .get("/GetInsetMasterDropdown")
    .then((r) => {
      deptList = r;
    })

    .catch((e) => { });
  return deptList;
};
export const getDeptList = async () => {
  let deptList = [];
  await pgsService
    .get("/Departments")
    .then((r) => {
      deptList = r;
    })

    .catch((e) => { });
  return deptList;
};
export const getPlannerBasedOnCusList = async (id) => {
  let pList = [];
  await pgsService
    .get(`/GetPlannerDropdownBasedOnCustomer/${id}`)
    .then((r) => {
      pList = r;
    })
    .catch((e) => { });
  return pList;
};
export const getDepartment = async (deptId) => {
  let dept = {};
  await pgsService
    .get("/Departments/" + deptId)
    .then((response) => {
      dept = response.data;
    })
    .catch((e) => { });
  return dept;
};
export const postDept = async (reqBody, history, token) => {
  let deptList = [];
  await pgsService
    .post("/Departments", reqBody)
    .then((r) => {
      deptList = r;
      toastr.success("Department Created Successfully");
      history.push("/department");
    })
    .catch((e) => {
      // this.handleClose();
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return deptList;
};
export const deleteDepartment = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/Departments`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Department Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const postDepartment = async (data) => {
  await pgsService
    .post("/Departments", data)
    .then((r) => { })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const updateDepartment = async (deptId, data, history) => {
  await pgsService
    .put("/Departments/" + deptId, data)
    .then((r) => {
      history.push("/department");
      toastr.success("Department Updated Successfully");
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const postDepartmentAdvanceSearch = async (reqBody) => {
  let searchList = [];
  await pgsService
    .post("/DepartmentsAdvSerach", reqBody)
    .then(async (r) => {
      searchList = r.data;
    })
    .catch((e) => { });
  return searchList;
};
//-------------------------Department ends---------------------//

export const getAllMailingSchemeList = async () => {
  let mailingSchemeList = [];
  await pgsService
    .get("/MailingSchemes")
    .then((r) => {
      mailingSchemeList = r;
    })
    .catch((e) => { });
  return mailingSchemeList;
};

export const getMailingSchemeDropDown = async () => {
  let mailingSchemeList = [];
  await pgsService
    .get("/MailingSchemesDropdown")
    .then((r) => {
      mailingSchemeList = r;
    })
    .catch((e) => { });
  return mailingSchemeList;
};

export const getLocalMailingSchemeDropDown = async () => {
  let mailingSchemeList = [];
  await pgsService
    .get("/LocalMailingSchemesDropdown")
    .then((r) => {
      mailingSchemeList = r;
    })
    .catch((e) => { });
  return mailingSchemeList;
};
export const notPPIMailingSchemeDropDown = async () => {
  let mailingSchemeList = [];
  await pgsService
    .get("/MailingSchemesDropdown")
    .then((r) => {
      mailingSchemeList = r;
    })
    .catch((e) => { });
  return mailingSchemeList;
};

export const getInternationalMailingSchemeDropDown = async () => {
  let mailingSchemeList = [];
  await pgsService
    .get("/IntMailingSchemesDropdown")
    .then((r) => {
      mailingSchemeList = r;
    })
    .catch((e) => { });
  return mailingSchemeList;
};

export const getMenuList = async () => {
  let menuList = [];
  await pgsService
    .get("/Menus")
    .then((r) => {
      menuList = r;
    })
    .catch((e) => { });
  return menuList;
};
export const getFliteredDeptList = async (reqBody) => {
  let deptList = [];

  await pgsService
    .post("/DepartmentsAdvSerach", reqBody)
    .then((r) => {
      deptList = r;
    })
    .catch((e) => { });
  return deptList;
};
export const deleteMailingScheme = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/MailingSchemes`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("MailingScheme Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

/*------------Zone functions starts -------------------------*/
export const getZoneList = async () => {
  let zoneList = [];
  await pgsService
    .get("/Zones")
    .then((r) => {
      zoneList = r;
    })
    .catch((e) => { });
  return zoneList;
};
export const getZoneDropDownList = async () => {
  let zoneList = [];
  await pgsService
    .get("/ZonesDropdown", {
      headers: {
        Authorization: "Bearer " + window.localStorage.token,
      },
    })
    .then((r) => {
      zoneList = r;
    })
    .catch((e) => { });
  return zoneList;
};
export const postZone = async (data, history) => {
  await pgsService
    .post("/Zones", data)
    .then((r) => {
      history.push("/zone");
      toastr.success("Zone Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateZone = async (zoneId, data, history) => {
  await pgsService
    .put("/Zones/" + zoneId, data)
    .then((r) => {
      history.push("/zone");
      toastr.success("Zone Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getZoneDetails = async (zoneId) => {
  let zone = {};
  await pgsService
    .get("/Zones/" + zoneId)
    .then((response) => {
      zone = response.data;
    })
    .catch((e) => { });
  return zone;
};

export const getZoneCode = async (zoneId) => {
  let Zones = {};
  await pgsService
    .get("/Zones/" + zoneId)
    .then((response) => {
      let data = response.data;
      Zones = {
        zoneCode: data.zoneCode,
        zoneName: data.zoneName,
        subCode: data.subCode,
        isActive: data.isActive,
        createdBy: data.createdBy,
        createdDate: data.createdDate,
        modifiedBy: data.modifiedBy,
        modifiedDate: data.modifiedDate,
      };
    })
    .catch((e) => { });
  return Zones;
};

export const getFliteredZoneList = async (reqBody) => {
  let zoneList = [];

  await pgsService
    .post("/ZonesAdvSerach", reqBody)
    .then((r) => {
      zoneList = r;
    })
    .catch((e) => { });
  return zoneList;
};

export const deleteZone = async (deletionIds) => {
  let zoneList = [];

  let modifiedBy = localStorage.loggedInId;
  await pgsService
    .delete("/Zones", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      // zoneList = r;
      toastr.success("Zone Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return zoneList;
};

export const deletePPI = async (deletionIds) => {
  let data = [];
  let modifiedBy = localStorage.loggedInId;
  await pgsService
    .delete("/CustomerPPI", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};
//-------------------------Zone ends---------------------//
/*------------DHLRate functions starts -------------------------*/
export const getDHLRateList = async () => {
  let DHLRateList = [];
  await pgsService
    .get("/DHLRates")
    .then((r) => {
      DHLRateList = r;
    })
    .catch((e) => { });
  return DHLRateList;
};
export const postDHLRate = async (data, history) => {
  await pgsService
    .post("/DHLRates", data)
    .then((r) => {
      history.push("/DHLRate");
      toastr.success("DHLRate Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateDHLRate = async (DHLRateId, data, history) => {
  await pgsService
    .put("/DHLRates/" + DHLRateId, data)
    .then((r) => {
      history.push("/DHLRate");
      toastr.success("DHLRate Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getDHLRateDetails = async (DHLRateId) => {
  let DHLRate = {};
  await pgsService
    .get("/DHLRates/" + DHLRateId)
    .then((response) => {
      DHLRate = response.data;
    })
    .catch((e) => { });
  return DHLRate;
};

export const getFliteredDHLRateList = async (reqBody) => {
  let DHLRateList = [];
  await pgsService
    .post("/DHLRatesAdvSerach", reqBody)
    .then((r) => {
      DHLRateList = r;
    })
    .catch((e) => { });
  return DHLRateList;
};

export const deleteDHLRate = async (deletionIds, history) => {
  let DHLRateList = [];
  let modifiedBy = localStorage.loggedInId;
  await pgsService
    .delete("/DHLRates", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      history.push("/DHLRate");
      toastr.success("DHLRate Deleted Successfully");
    })
    .catch((e) => {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors[0].message !== undefined
      ) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.responsee.data.errors[0]);
      }
    });
  return DHLRateList;
};

//-------------------------DHLRate ends---------------------//

/*------------Acronym functions starts -------------------------*/
export const getAcronymList = async () => {
  //alert(1);
  let AcronymList = [];
  await pgsService
    .get("/Acronyms")
    .then((r) => {
      AcronymList = r;
    })
    .catch((e) => { });
  return AcronymList;
};
export const postAcronym = async (data, history) => {
  await pgsService
    .post("/Acronyms", data)
    .then((r) => {
      history.push("/acronym");
      toastr.success("Acronym Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateAcronym = async (AcronymId, data, history) => {
  await pgsService
    .put("/Acronyms/" + AcronymId, data)
    .then((r) => {
      history.push("/acronym");
      toastr.success("Acronym Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getAcronymDetails = async (AcronymId) => {
  let Acronym = {};
  await pgsService
    .get("/Acronyms/" + AcronymId)
    .then((response) => {
      Acronym = response.data;
    })
    .catch((e) => { });
  return Acronym;
};

export const getFliteredAcronymList = async (reqBody) => {
  let AcronymList = [];
  await pgsService
    .post("/AcronymsAdvSerach", reqBody)
    .then((r) => {
      AcronymList = r;
    })
    .catch((e) => { });
  return AcronymList;
};

export const deleteAcronym = async (deletionIds, history, token) => {
  let AcronymList = [];
  let modifiedBy = localStorage.loggedInId;

  await pgsService
    .delete("/Acronyms", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      history.push("/acronym");
      toastr.success("Acronym Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });

  return AcronymList;
};

//-------------------------Acronym ends---------------------//

/*------------Country functions starts -------------------------*/

export const getCountryList = async () => {
  let countryList = [];
  await pgsService
    .get("/Country")
    .then((r) => {
      countryList = r;
    })
    .catch((e) => { });
  return countryList;
};
export const getCountryDropdownList = async () => {
  let countryList = [];
  await pgsService
    .get("/CountryDropdown")
    .then((r) => {
      countryList = r;
    })
    .catch((e) => { });
  return countryList;
};
export const getUpdatedCountryDropdownList = async () => {
  let countryList = [];
  await pgsService
    .get("/UOPCountryDropdown")
    .then((r) => {
      countryList = r;
    })
    .catch((e) => { });
  return countryList;
};
export const getPubCustomerList = async () => {
  let customerList = [];
  await pgsService
    .get("/GetPubCustomerDropdown")
    .then((r) => {
      customerList = r;
    })
    .catch((e) => { });
  return customerList;
};
export const getBucketsDD = async () => {
  let bList = [];
  await pgsService
    .get("/BucketsDropdown")
    .then((r) => {
      bList = r;
    })
    .catch((e) => { });
  return bList;
};
export const getCriteriacCountryZoneList = async () => {
  let pmlopList = [];
  await pgsService
    .get(`GetCountrywithZone`)
    //.get(`/GetOrderFileReady/4/11`)
    .then((r) => {
      pmlopList = r;
      //  console.log(r)
    })
    .catch((e) => { });
  return pmlopList;
};
export const getcriteriaDD = async () => {
  let bList = [];
  await pgsService
    .get("/CriteriasDropdown")
    .then((r) => {
      bList = r;
    })
    .catch((e) => { });
  return bList;
};
export const getFliteredCountryList = async (reqBody) => {
  let countryList = [];
  await pgsService
    .post("/CountryAdvSerach", reqBody)
    .then((r) => {
      countryList = r;
    })
    .catch((e) => { });
  return countryList;
};
export const getCountry = async (countryID) => {
  let country = {};
  await pgsService
    .get("/Country/" + countryID)
    .then((response) => {
      let data = response.data;
      country = {
        countryCode: data.countryCode,
        countryName: data.countryName,
        zoneName: data.zoneName,
        zoneCode: data.zoneCode,
        zoneID: data.zoneID,
        dhlZoneID: data.dhlZoneID,
        dhlZoneName: data.dhlZoneName,
        dhlZoneCode: data.dhlZoneCode,
        alternateCountryCode: data.alternateCountryCode,
        alternateCountryName: data.alternateCountryName,
        isActive: data.isActive,
      };
    })
    .catch((e) => { });
  return country;
};
export const updateCountryID = async (countryId, data, history) => {
  await pgsService
    .put("/Country/" + countryId, data)
    .then((r) => {
      toastr.success("Country Updated Successfully");
      history.push("/country");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const postCountry = async (data, history) => {
  await pgsService
    .post("/country", data)
    .then((r) => {
      toastr.success("Country Created Successfully");
      history.push("/country");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const deleteCountry = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/Country`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Country Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
//--------------------lettershop functions starts----------------------//
export const getletterShopList = async () => {
  let letterShopList = [];
  await pgsService
    .get("/LetterShop")
    .then((r) => {
      letterShopList = r;
    })
    .catch((e) => { });
  return letterShopList;
};
export const getletterShopDropDown = async () => {
  let letterShopList = [];
  await pgsService
    .get("/LetterShopDropdown")
    .then((r) => {
      letterShopList = r;
    })
    .catch((e) => { });
  return letterShopList;
};
export const updateLetterShop = async (lettershopId, data, history) => {
  await pgsService
    .put("/LetterShop/" + lettershopId, data)
    .then((r) => {
      history.push("/letterShop");
      toastr.success("Lettershop Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const deleteLetterShop = async (modifiedBy, deletionIds) => {
  //let lettershoplist = [];

  //let modifiedBy = localStorage.loggedInId;
  await pgsService
    .delete("/LetterShop", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Lettershop Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const getlettershopCode = async (lettershopId) => {
  let lettershop = {};
  await pgsService
    .get("/LetterShop/" + lettershopId)
    .then((response) => {
      let data = response.data;
      lettershop = {
        letterShopCode: data.letterShopCode,
        letterShopName: data.letterShopName,
        chargeType: data.chargeType,
        isActive: data.isActive,
        createdBy: data.createdBy,
        createdDate: data.createdDate,
        modifiedBy: data.modifiedBy,
        modifiedDate: data.modifiedDate,
        unitRate: data.unitRate,
      };
    })
    .catch((e) => { });
  return lettershop;
};
export const getFliteredLetterShopList = async (reqBody) => {
  let Lettershoplist = [];

  await pgsService
    .post("/LetterShopAdvanceSerach", reqBody)
    .then((r) => {
      Lettershoplist = r;
    })
    .catch((e) => { });
  return Lettershoplist;
};
export const postLetterShop = async (data, history) => {
  await pgsService
    .post("/LetterShop", data)
    .then((r) => {
      history.push("/letterShop");
      toastr.success("Lettershop Created Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
//-------------------------lettershop ends---------------------//
export const getPPIList = async () => {
  let ppiList = [];
  await pgsService
    .get("/CustomerPPIDropdown")
    .then((r) => {
      ppiList = r;
    })
    .catch((e) => { });
  return ppiList;
};
export const getCCIList = async () => {
  let cciList = [];
  await pgsService
    .get("/CostCentersDropdown")
    .then((r) => {
      cciList = r;
    })
    .catch((e) => { });
  return cciList;
};
export const checkValidCC = async (ccValue, ppiId,) => {
  let cciList = "";
  await pgsService
    .get(`/IsValidCostCenter/${ccValue}/${ppiId}`)
    .then((r) => {
      cciList = r;
    })
    .catch((e) => { });
  return cciList;
};
export const getPPIDropDown = async (id) => {
  let PPIList = [];
  await pgsService
    .get("/PPIDropdown/" + id)
    .then((r) => {
      PPIList = r;
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return PPIList;
};
export const getCCIDropDown = async (id) => {
  let cciList = [];
  await pgsService
    .get("/GetPPICostCenterByCustomerId/" + id)
    .then((r) => {
      cciList = r;
    })
    .catch((e) => { });
  return cciList;
};
export const getCCIDropDownPPI = async (id, pId) => {
  let cciList = [];
  await pgsService
    .get("/GetPPICostCenterByCustomerId/" + id + "/" + pId)
    .then((r) => {
      cciList = r;
    })
    .catch((e) => { });
  return cciList;
};
export const getCustomerPPIList = async (customerId) => {
  let cPPIList = [];
  await pgsService
    .get("/PPIDropdown/" + customerId)
    .then((r) => {
      cPPIList = r;
    })
    .catch((e) => {
      //this.handleClose();
      // if (e.response.data.errors[0].message !== undefined) {
      //   toastr.error(e.response.data.errors[0].message);
      // } else {
      //   toastr.error(e.response.data.errors[0]);
      // }
    });
  return cPPIList;
};
export const getProcessCategoryList = async () => {
  let processCategoryList = [];
  await pgsService
    .get("/GetProcessCategoryDropdown")
    .then((r) => {
      processCategoryList = r.data;
    })
    .catch((e) => { });
  return processCategoryList;
};

export const getProcessDropDown = async () => {
  let customerDDList = [];
  await pgsService
    .get("/GetProcessDropdown")
    .then((r) => {
      customerDDList = r;
    })
    .catch((e) => { });
  return customerDDList;
};

export const getProcessDetails = async (processID) => {
  let Process = {};
  await pgsService
    .get("/Process/" + processID)
    .then((response) => {
      Process = response.data;
    })
    .catch((e) => { });
  return Process;
};

export const getCustomerList = async () => {
  let customerList = [];
  await pgsService
    .get("/Customer")
    .then((r) => {
      customerList = r;
    })
    .catch((e) => { });
  return customerList;
};

export const getRoutingCustomerList = async () => {
  let customerList = [];
  await pgsService
    .get("/GetPubCustomerDropdown")
    .then((r) => {
      customerList = r;
      // console.log(r)
    })
    .catch((e) => { });
  return customerList;
};
export const getPublisherCustomerList = async () => {
  let customerList = [];
  await pgsService
    .get("/GetPublisherCustomer")
    .then((r) => {
      customerList = r;
      // console.log(r)
    })
    .catch((e) => { });
  return customerList;
};
export const getCustomerDDList = async () => {
  let customerDDList = [];
  await pgsService
    .get("/CustomerDropdown")
    .then((r) => {
      customerDDList = r;
    })
    .catch((e) => { });
  return customerDDList;
};
export const getCustomer = async (id) => {
  let customer = {};
  await pgsService
    .get(`/GetCustomerByIDDetailsAsync/${id}`)
    .then((r) => {
      customer = r;
    })
    .catch((e) => { });
  return customer;
};

export const deleteCustomer = async (deletionIds, history) => {
  let customerList = [];
  let modifiedBy = localStorage.loggedInId;
  await pgsService
    .delete("/Customer", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      history.push("/Customer");
      toastr.success("Customer Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return customerList;
};

//--------------------Cpt code functions starts----------------------//

export const getCPTCodeList = async () => {
  let cptCodeList = [];
  await pgsService
    .get("/CPTCodes")
    .then((r) => {
      cptCodeList = r;
    })
    .catch((e) => { });
  return cptCodeList;
};

export const getCptCode = async (cptCodeId) => {
  let cptCode = {};
  await pgsService
    .get("/CPTCodes/" + cptCodeId)
    .then((response) => {
      let data = response.data;
      cptCode = {
        office: data.office,
        objectCode: data.objectCode,
        subCode: data.subCode,
        cptDescription: data.cptDescription,
        sapglAccount: data.sapglAccount,
        category: data.category,
        remarks: data.remarks,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });
  return cptCode;
};
export const deleteCptCode = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/CPTCodes`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Cost Per Title Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const postCptCode = async (data, history) => {
  await pgsService
    .post("/CPTCodes", data)
    .then((r) => {
      toastr.success("Cost Per Title Created Successfully");
      history.push("/CPTCode");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const updateCptCodeID = async (cptCodeId, data, history) => {
  await pgsService
    .put("/CPTCodes/" + cptCodeId, data)
    .then((r) => {
      toastr.success("Cost Per Title Updated Successfully");
      history.push("/CPTCode");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      } else {
        toastr.error(e);
      }
    });
};

export const getFliteredCptCodeList = async (reqBody) => {
  let cptCodeList = [];

  await pgsService
    .post("/CPTCodesAdvanceSerach", reqBody)
    .then((r) => {
      cptCodeList = r;
    })
    .catch((e) => { });
  return cptCodeList;
};
//--------------------Journal version Matrix functions starts----------------------//

export const getJournalVersionList = async () => {
  let JournalList = [];
  await pgsService
    .get("/JournalVersionMatrixs")

    .then((r) => {
      JournalList = r;
    })
    .catch((e) => { });
  return JournalList;
};

export const getJournalVersion = async (journalVersionId) => {
  let journal = {};
  await pgsService
    .get("/JournalVersionMatrixs/" + journalVersionId)
    .then((response) => {
      journal = response.data;
    })
    .catch((e) => { });
  return journal;
};
export const deleteJournalVersion = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/JournalVersionMatrixs`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Journal Version Matrix Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const postJournalVersion = async (data, history) => {
  await pgsService
    .post("/JournalVersionMatrixs", data)
    .then((r) => {
      toastr.success("Journal Version Matrix Created Successfully");
      history.push("/journalVersionMatrix");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const updateJournalVersion = async (journalVersionId, data, history) => {
  await pgsService
    .put("/JournalVersionMatrixs/" + journalVersionId, data)
    .then((r) => {
      toastr.success("Journal Version Matrix Updated Successfully");
      history.push("/journalVersionMatrix");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getFliteredJournalVersionList = async (reqBody) => {
  let JournalList = [];

  await pgsService
    .post("/JournalVersionMatrixsAdvanceSerach", reqBody)
    .then((r) => {
      JournalList = r;
    })
    .catch((e) => { });
  return JournalList;
};

export const getLocationDropdownList = async () => {
  let LocationList = [];
  await pgsService
    .get("/GetLocationsDropdown")
    .then((r) => {
      LocationList = r;
    })
    .catch((e) => { });
  return LocationList;
};
export const getDDistributorList = async () => {
  let DistributorList = [];
  await pgsService
    .get("/Distributors")
    .then((r) => {
      DistributorList = r;
    })
    .catch((e) => { });
  return DistributorList;
};
export const getLocationList = async () => {
  let LocationList = [];
  await pgsService
    .get("/Locations")
    .then((r) => {
      LocationList = r;
    })
    .catch((e) => { });
  return LocationList;
};
export const getDispatchTypeList = async () => {
  let DispatchTypeList = [];
  await pgsService
    .get("/DispatchTypes")
    .then((r) => {
      DispatchTypeList = r;
    })
    .catch((e) => { });
  return DispatchTypeList;
};
export const getDispatchModeList = async () => {
  let DispatchModeList = [];
  await pgsService
    .get("/DispatchModes")
    .then((r) => {
      DispatchModeList = r;
    })
    .catch((e) => { });
  return DispatchModeList;
};

export const getDHLZoneList = async () => {
  let dhlZoneList = {};
  await pgsService
    .get("/DHLZones")
    .then((r) => {
      dhlZoneList = r;
    })
    .catch((e) => { });
  return dhlZoneList;
};

export const postCustomer = async (history, customerRequestBody, stateData) => {
  customerRequestBody.createdBy = localStorage.loggedInId;
  await pgsService
    .post("/Customer", customerRequestBody)
    .then(async (r) => {
      let customerID = r.data.customerID;
      stateData.map((b, i) => {
        let ppiRequestBody = {};
        if (b.serviceID) {
          ppiRequestBody.customerID = customerID;
          ppiRequestBody.ppiNumber = b.ppiNumber;
          ppiRequestBody.billingAccountNo = b.billingAccountNo;
          ppiRequestBody.plannerID = b.plannerID;
          //for time being
          ppiRequestBody.createdBy = localStorage.loggedInId;
          ppiRequestBody.serviceID = b.serviceID;
          if (!b.ppiDeleted)
            // ppiRequestBody.isActive=false
            pgsService
              .post("/CustomerPPI", ppiRequestBody)
              .then((res) => {
                let customerPPIID = res.data.customerPPIID;
                b.letterShopInfo &&
                  b.letterShopInfo.length > 0 &&
                  b.letterShopInfo.map((lsi) => {
                    let lsiRequestBody = {};
                    lsiRequestBody.createdBy = localStorage.loggedInId;
                    lsiRequestBody.customerPPIID = customerPPIID;
                    lsiRequestBody.letterShopID = lsi.key;
                    lsi &&
                      pgsService
                        .post("/PPILetterShops", lsiRequestBody)
                        .then((lisRes) => { })
                        .catch((e) => { });
                  });
                b.costCenterInfo &&
                  b.costCenterInfo.length > 0 &&
                  b.costCenterInfo.map((cci) => {
                    let cciRequestBody = {};
                    cciRequestBody.createdBy = localStorage.loggedInId;
                    cciRequestBody.customerPPIID = customerPPIID;
                    cciRequestBody.costCenterName = cci;
                    cci &&
                      pgsService
                        .post("/CostCenters", cciRequestBody)
                        .then((cciRes) => { })
                        .catch((e) => { });
                  });
              })
              .catch((e) => { });
        }
      });
      toastr.success("Customer Created Successfully");
      history.push("/customer");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const putCustomer = async (
  id,
  history,
  customerRequestBody,
  stateData
) => {
  customerRequestBody.modifiedBy = localStorage.loggedInId;

  await pgsService
    .put(`/Customer/${id}`, customerRequestBody)
    .then(async (r) => {
      let customerID = id;
      stateData.map(async (b, i) => {
        let ppiRequestBody = {};
        let deletionIds = "";
        let cciDeletionIds = "";
        b.originalLetterShopInfo &&
          b.originalLetterShopInfo.length > 0 &&
          b.originalLetterShopInfo.map((l) => {
            deletionIds = deletionIds + l.ppiLetterShopID + ",";
          });
        b.costCenterIds &&
          b.costCenterIds.length > 0 &&
          b.costCenterIds.map((c) => {
            cciDeletionIds = cciDeletionIds + c.ppiCostCenterID + ",";
          });
        deletionIds = deletionIds.slice(0, deletionIds.length - 1);
        cciDeletionIds = cciDeletionIds.slice(0, cciDeletionIds.length - 1);

        if (b.serviceID) {
          if (b.originalLetterShopInfo && b.originalLetterShopInfo.length > 0) {
            pgsService
              .delete(
                "/PPILetterShops",

                {
                  data: {
                    modifiedBy: localStorage.loggedInId,
                    deletionIds: deletionIds,
                  },
                }
              )
              .then((r) => { })
              .catch((e) => { });
          }
          if (b.costCenterIds && b.costCenterIds.length > 0) {
            await pgsService
              .delete("/CostCenters", {
                data: {
                  modifiedBy: 1,
                  deletionIds: cciDeletionIds,
                },
              })
              .then((r) => { })
              .catch((e) => { });
          }
          ppiRequestBody.customerID = customerID;
          ppiRequestBody.ppiNumber = b.ppiNumber;
          ppiRequestBody.billingAccountNo = b.billingAccountNo;
          ppiRequestBody.plannerID = b.plannerID;
          ppiRequestBody.serviceID = b.serviceID;
          ppiRequestBody.isActive = b.isActive;

          if (b.customerPPIID) {
            console.log(b);
            ppiRequestBody.modifiedBy = localStorage.loggedInId;
            await pgsService
              .put(`/CustomerPPI/${b.customerPPIID}`, ppiRequestBody)
              .then((res) => {
                b.letterShopInfo &&
                  b.letterShopInfo.length > 0 &&
                  b.letterShopInfo.map(async (lsi) => {
                    let lsiRequestBody = {};
                    lsiRequestBody.letterShopID = lsi.key;
                    lsiRequestBody.customerPPIID = b.customerPPIID;
                    lsiRequestBody.createdBy = localStorage.loggedInId;
                    await pgsService
                      .post(`/PPILetterShops`, lsiRequestBody)
                      .then((lisRes) => { })
                      .catch((e) => { });
                  });
                b.costCenterInfo &&
                  b.costCenterInfo.length > 0 &&
                  b.costCenterInfo.map(async (cci, k) => {
                    let cciRequestBody = {};
                    cciRequestBody.createdBy = localStorage.loggedInId;
                    cciRequestBody.customerPPIID = b.customerPPIID;
                    cciRequestBody.costCenterName = cci;
                    await pgsService
                      .post(`/CostCenters`, cciRequestBody)
                      .then((cciRes) => { })
                      .catch((e) => { });
                  });
              })
              .catch((e) => { });
          } else {
            ppiRequestBody.createdBy = localStorage.loggedInId;
            ppiRequestBody.isActive = b.isActive;
            if (!b.ppiDeleted)
              await pgsService
                .post(`/CustomerPPI`, ppiRequestBody)
                .then((res) => {
                  b.letterShopInfo &&
                    b.letterShopInfo.length > 0 &&
                    b.letterShopInfo.map(async (lsi) => {
                      let lsiRequestBody = {};
                      lsiRequestBody.letterShopID = lsi.key;
                      lsiRequestBody.createdBy = localStorage.loggedInId;
                      lsiRequestBody.customerPPIID = res.data.customerPPIID;

                      await pgsService
                        .post(`/PPILetterShops`, lsiRequestBody)
                        .then((lisRes) => { })
                        .catch((e) => { });
                    });

                  b.costCenterInfo &&
                    b.costCenterInfo.length > 0 &&
                    b.costCenterInfo.map(async (cci, k) => {
                      let cciRequestBody = {};
                      cciRequestBody.customerPPIID = res.data.customerPPIID;
                      cciRequestBody.costCenterName = cci;
                      cciRequestBody.createdBy = localStorage.loggedInId;
                      await pgsService
                        .post(`/CostCenters`, cciRequestBody)
                        .then((cciRes) => { })
                        .catch((e) => { });
                      // }
                    });
                })
                .catch((e) => { });
          }
        }
      });
      toastr.success("Customer updated Successfully");
      history.push("/customer");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const serchCustomer = async (data) => {
  let customerList = [];
  await pgsService
    .post("/CustomerAdvanceSerach", data)
    .then((r) => {
      customerList = r;
    })
    .catch((e) => {
      // if (e.response && e.response.data.errors[0].message !== undefined) {
      //   toastr.error(e.response.data.errors[0].message);
      // } else if (e.response.data.errors[0]) {
      //   toastr.error(e.response.data.errors[0]);
      // }
    });
  return customerList;
};
export const getCustomerDropdown = async () => {
  let cList = [];
  await pgsService
    .get("/CustomerDropdown")
    .then((r) => {
      cList = r;
    })
    .catch((e) => { });
  return cList;
};
export const getProcessTypeList = async () => {
  let processTypeList = [];
  await pgsService
    .get("/ProcessTypes")
    .then((r) => {
      processTypeList = r;
    })
    .catch((e) => { });
  return processTypeList;
};
export const getProcessRates = async () => {
  let prList = [];
  await pgsService
    .get("/ProcessRates")
    .then((r) => {
      prList = r;
    })
    .catch((e) => { });
  return prList;
};
export const getProcessRateList = async () => {
  let processRateList = [];
  await pgsService
    .get("/ProcessRates")
    .then((r) => {
      processRateList = r;
    })
    .catch((e) => { });
  return processRateList;
};

export const getFilteredProcessTypeList = async (reqBody) => {
  let processTypeList = [];
  await pgsService
    .post("/ProcessTypesAdvSerach", reqBody)
    .then((r) => {
      processTypeList = r;
    })
    .catch((e) => { });
  return processTypeList;
};

export const getFilteredProcessRateList = async (reqBody) => {
  let processrateList = [];
  await pgsService
    .post(
      "/ProcessRatesAdvanceSerach/" + reqBody.process + "/" + reqBody.customer
    )
    .then((r) => {
      processrateList = r;
    })
    .catch((e) => { });
  return processrateList;
};

export const getProcessType = async (processTypeId) => {
  let processType = {};
  await pgsService
    .get("/ProcessTypes/" + processTypeId)
    .then((response) => {
      let data = response.data;
      processType = {
        processTypeID: data.processTypeID,
        processTypeCode: data.processTypeCode,
        processTypeName: data.processTypeName,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });
  return processType;
};

export const getProcessRate = async (processRateId) => {
  let processRate = {};
  await pgsService
    .get("/ProcessRates/" + processRateId)
    .then((response) => {
      let data = response.data;
      processRate = {
        // processTypeID: data.processTypeID,
        // processTypeCode: data.processTypeCode,
        // processTypeName: data.processTypeName,
        // isActive: data.isActive,
        // modifiedBy: data.modifiedBy

        customerID: data.customerID,
        customerName: data.customerName,
        processID: data.processID,
        processName: data.processName,
        unitRate: data.unitRate,
      };
    })
    .catch((e) => { });
  return processRate;
};

export const deleteProcessRate = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/ProcessRates`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Process Rate Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const deleteProcessType = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/ProcessTypes`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Process Type Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const postProcessType = async (data) => {
  await pgsService
    .post("/ProcessTypes", data)
    .then((r) => {
      toastr.success("Process Type Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.reponse && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateProcessTypeID = async (processTypesId, data) => {
  await pgsService
    .put("/ProcessTypes/" + processTypesId, data)
    .then((r) => {
      toastr.success("Process Type Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getProvinceList = async () => {
  let provinceList = [];
  await pgsService
    .get("/Provinces")
    .then((r) => {
      provinceList = r;
    })
    .catch((e) => { });
  return provinceList;
};

export const getProvinceDropDownList = async () => {
  let provinceList = [];
  await pgsService
    .get("/GetProvincesDropdown", {
      headers: {
        Authorization: "Bearer " + window.localStorage.token,
      },
    })
    .then((r) => {
      provinceList = r;
    })
    .catch((e) => { });
  return provinceList;
};

export const getFilteredProvinceList = async (reqBody) => {
  let provinceList = [];
  await pgsService
    .post("/ProvincesAdvanceSerach", reqBody)
    .then((r) => {
      provinceList = r;
    })
    .catch((e) => { });
  return provinceList;
};

export const getProvince = async (provinceId) => {
  let province = {};
  await pgsService
    .get("/Provinces/" + provinceId)
    .then((response) => {
      let data = response.data;
      province = {
        provinceID: data.provinceID,
        countryID: data.countryID,
        countryName: data.countryName,
        provinceName: data.provinceName,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });

  return province;
};

export const deleteProvince = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/Provinces`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Province Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const postProvince = async (data) => {
  await pgsService
    .post("/Provinces", data)
    .then((r) => {
      toastr.success("Province Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.reponse && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateProvinceID = async (provincesId, data) => {
  await pgsService
    .put("/Provinces/" + provincesId, data)
    .then((r) => {
      toastr.success("Province Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getCodeMasterList = async () => {
  let codeMasterList = [];
  await pgsService
    .get("/CodeMaster")
    .then((r) => {
      codeMasterList = r;
    })
    .catch((e) => { });
  return codeMasterList;
};

export const getCodeMaster = async (codeMasterId) => {
  let codeMaster = {};
  await pgsService
    .get("/CodeMaster/" + codeMasterId)
    .then((response) => {
      let data = response.data;
      codeMaster = {
        codeID: data.codeID,
        codeType: data.codeType,
        codeTypeName: data.codeTypeName,
        code: data.code,
        codeValue: data.codeValue,
        effectiveStartDate: data.effectiveStartDate,
        remarks: data.remarks,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });

  return codeMaster;
};

export const deleteCodeMaster = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/CodeMaster`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Code Master Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const postCodeMaster = async (data) => {
  await pgsService
    .post("/CodeMaster", data)
    .then((r) => {
      toastr.success("Code Master Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.reponse && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateCodeMasterID = async (codeMastersId, data) => {
  await pgsService
    .put("/CodeMaster/" + codeMastersId, data)
    .then((r) => {
      toastr.success("Code Master Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getProcessList = async (token) => {
  let processList = [];
  await pgsService
    .get("/Process")
    .then((r) => {
      processList = r;
    })
    .catch((e) => { });
  return processList;
};

export const getOutworkerClaimRateList = async () => {
  let outworkerClaimRateList = [];
  await pgsService
    .get("/OutworkerClaimRate")
    .then((r) => {
      outworkerClaimRateList = r;
    })
    .catch((e) => { });
  return outworkerClaimRateList;
};

export const getFilteredOutworkerClaimRateList = async (reqBody) => {
  let outworkerClaimRateList = [];
  await pgsService
    .post("/OutworkerClaimRateAdvSerach", reqBody)
    .then((r) => {
      outworkerClaimRateList = r;
    })
    .catch((e) => { });
  return outworkerClaimRateList;
};

export const getUomDropDownList = async (token) => {
  let uomList = [];
  await pgsService
    .get("/GetUOMsDropdown")
    .then((r) => {
      uomList = r;
    })
    .catch((e) => { });
  return uomList;
};

export const getOutworkerClaimRate = async (outworkerClaimRateId) => {
  let outworkerClaimRate = {};

  await pgsService
    .get("/OutworkerClaimRate/" + outworkerClaimRateId)
    .then((response) => {
      let data = response.data;
      outworkerClaimRate = {
        owcRateID: data.owcRateID,
        processID: data.processID,
        processName: data.processName,
        rate: data.rate,
        uom: data.uom,
        uomName: data.uomName,
        uomid: data.uomid,
        remarks: data.remarks,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });

  return outworkerClaimRate;
};

export const deleteOutworkerClaimRate = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/OutworkerClaimRate`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Outworker Claim Rate Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const postOutworkerClaimRate = async (data) => {
  await pgsService
    .post("/OutworkerClaimRate", data)
    .then((r) => {
      toastr.success("Outworker Claim Rate Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.reponse && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateOutworkerClaimRateID = async (
  outworkerClaimRateId,
  data
) => {
  await pgsService
    .put("/OutworkerClaimRate/" + outworkerClaimRateId, data)
    .then((r) => {
      toastr.success("Outworker Claim Rate Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getRoleList = async () => {
  let roleList = [];
  await pgsService
    .get("/Roles")
    .then((r) => {
      roleList = r;
    })
    .catch((e) => { });
  return roleList;
};

export const getRole = async (roleId) => {
  let role = {};
  await pgsService
    .get("/Roles/" + roleId)
    .then((response) => {
      let data = response.data;
      role = {
        roleID: data.roleID,
        roleName: data.roleName,
        description: data.description,
        orderNo: data.orderNo,
        isReport: data.isReport,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });
  return role;
};

export const deleteRole = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/Roles`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Role Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const postRole = async (data) => {
  await pgsService
    .post("/Roles", data)
    .then((r) => {
      toastr.success("Role Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateRoleID = async (roleId, data) => {
  await pgsService
    .put("/Roles/" + roleId, data)
    .then((r) => {
      toastr.success("Role Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getRoleActionList = async () => {
  let roleActionList = [];
  await pgsService
    .get("/RoleActions")
    .then((r) => {
      roleActionList = r;
    })
    .catch((e) => { });
  return roleActionList;
};

export const getMasterRoleActionList = async () => {
  let masterRoleActionList = [];
  await pgsService
    .get("/MasterRoleActions")
    .then((r) => {
      masterRoleActionList = r;
    })
    .catch((e) => { });
  return masterRoleActionList;
};

export const getRoleAction = async (roleActionId) => {
  let roleAction = {};
  await pgsService
    .get("/RoleActions/" + roleActionId)
    .then((response) => {
      let data = response.data;
      roleAction = {
        roleID: data.roleID,
        roleName: data.roleName,
        menuID: data.menuID,
        menuName: data.menuName,
        subMenuName: data.subMenuName,
        subMenuID: data.subMenuID,
        viewAction: data.viewAction,
        addAction: data.addAction,
        editAction: data.editAction,
        deleteAction: data.deleteAction,
        advanceSearchAction: data.advanceSearchAction,
        exportAction: data.exportAction,
        approveAction: data.approveAction,
        reportAction: data.reportAction,
        printAction: data.printAction,
        description: data.description,
        orderNo: data.orderNo,
        isReport: data.isReport,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });
  return roleAction;
};

export const getRoleActionByRoleId = async (roleId) => {
  let roleActionByRoleList = {};
  await pgsService
    .get("/RoleActionsByRole/" + roleId)
    .then((response) => {
      roleActionByRoleList = response;
    })
    .catch((e) => { });
  return roleActionByRoleList;
};

export const deleteRoleAction = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/RoleActions`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Role Action Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const postRoleAction = async (data) => {
  await pgsService
    .post("/RoleActions", data)
    .then((r) => {
      toastr.success("Role Action Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateRoleActionID = async (roleActionId, data) => {
  await pgsService
    .put("/RoleActions/" + roleActionId, data)
    .then((r) => {
      toastr.success("Role Action Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateRoleActionByRoleID = async (roleId, data) => {
  await pgsService
    .put("/UpdateRoleActionsByRole/" + roleId, data)
    .then((r) => {
      toastr.success("Role Action Updated Successfully");
    })
    .catch((e) => {
      //alert(e);
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response && e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getSuperMenuList = async () => {
  let superMenuList = [];
  await pgsService
    .get("/SuperMenus")
    .then((r) => {
      superMenuList = r;
    })
    .catch((e) => { });
  return superMenuList;
};

export const getSuperMenu = async (superMenuId) => {
  let superMenu = {};
  await pgsService
    .get("/SuperMenus/" + superMenuId)
    .then((response) => {
      let data = response.data;
      superMenu = {
        superMenuID: data.superMenuID,
        superMenuName: data.superMenuName,
        description: data.description,
        orderNo: data.orderNo,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });
  return superMenu;
};

export const deleteSuperMenu = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/SuperMenus`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Super Menu Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const postSuperMenu = async (data) => {
  await pgsService
    .post("/SuperMenus", data)
    .then((r) => {
      toastr.success("Super Menu Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateSuperMenuID = async (superMenuId, data) => {
  await pgsService
    .put("/SuperMenus/" + superMenuId, data)
    .then((r) => {
      toastr.success("Super Menu Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

// export const getMenuList = async () => {
//   let menuList = [];
//   await pgsService
//     .get("/Menus")
//     .then(r => {
//       menuList = r;
//     })
//     .catch(e => {});
//   return menuList;
// };
// export const getMenuList = async () => {
//   let menuList = [];
//   await pgsService
//     .get("/Menus")
//     .then(r => {
//       menuList = r;
//     })
//     .catch(e => {});
//   return menuList;
// };

export const getMenu = async (menuId) => {
  let menu = {};
  await pgsService
    .get("/Menus/" + menuId)
    .then((response) => {
      let data = response.data;
      menu = {
        menuID: data.menuID,
        superMenuID: data.superMenuID,
        superMenuName: data.superMenuName,
        menuName: data.menuName,
        description: data.description,
        orderNo: data.orderNo,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });
  return menu;
};

export const deleteMenu = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/Menus`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Menu Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const postMenu = async (data) => {
  await pgsService
    .post("/Menus", data)
    .then((r) => {
      toastr.success("Menu Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateMenuID = async (menuId, data) => {
  await pgsService
    .put("/Menus/" + menuId, data)
    .then((r) => {
      toastr.success("Menu Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getSubMenuList = async () => {
  let subMenuList = [];
  await pgsService
    .get("/SubMenus")
    .then((r) => {
      subMenuList = r;
    })
    .catch((e) => { });
  return subMenuList;
};

export const getSubMenu = async (subMenuId) => {
  let subMenu = {};
  await pgsService
    .get("/SubMenus/" + subMenuId)
    .then((response) => {
      let data = response.data;
      subMenu = {
        subMenuID: data.subMenuID,
        menuID: data.menuID,
        menuName: data.menuName,
        subMenuName: data.subMenuName,
        description: data.description,
        orderNo: data.orderNo,
        isReport: data.isReport,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });
  return subMenu;
};

export const deleteSubMenu = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/SubMenus`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Sub Menu Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const postSubMenu = async (data) => {
  await pgsService
    .post("/SubMenus", data)
    .then((r) => {
      toastr.success("Sub Menu Created Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getOrderStatus = async () => {
  let orderList = [];
  await pgsService
    .get("/GENStatusDropdown")
    .then((r) => {
      orderList = r;
    })
    .catch((e) => { });
  return orderList;
};
export const getOrderStatusDP = async () => {
  let orderList = [];
  await pgsService
    .get("/GENStatusDropdownForDP")
    .then((r) => {
      orderList = r;
    })
    .catch((e) => { });
  return orderList;
};
export const getCustomerOrderStatus = async () => {
  let orderList = [];
  await pgsService
    .get("/GENStatusDropdownForCOP")
    .then((r) => {
      orderList = r;
    })
    .catch((e) => { });
  return orderList;
};
export const getUserOrderStatus = async () => {
  let orderList = [];
  await pgsService
    .get("/GENStatusDropdownForUOP")
    .then((r) => {
      orderList = r;
    })
    .catch((e) => { });
  return orderList;
};
export const updateSubMenuID = async (subMenuId, data) => {
  await pgsService
    .put("/SubMenus/" + subMenuId, data)
    .then((r) => {
      toastr.success("Sub Menu Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getDispatchMode = async () => {
  let dispatchModeList = [];
  await pgsService
    .get("/DispatchModes")
    .then((r) => {
      dispatchModeList = r;
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return dispatchModeList;
};
export const postDispatchMode = async (history, reqBody) => {
  let dispatchModeList = [];
  await pgsService
    .post("/DispatchModes", reqBody)
    .then((r) => {
      toastr.success("Dispatch Mode Created Successfully");
      history.push("/DispatchMode");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return dispatchModeList;
};
export const putDispatchMode = async (history, id, reqBody) => {
  let dispatchModeList = [];
  await pgsService
    .put(`/DispatchModes/${id}`, reqBody)
    .then((r) => {
      toastr.success("Dispatch Mode Updated Successfully !");
      history.push("/DispatchMode");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return dispatchModeList;
};
export const getFliteredDispatchModeList = async (reqBody) => {
  let dispatchModeList = [];
  await pgsService
    .post("/DispatchModesAdvSerach", reqBody)
    .then((r) => {
      dispatchModeList = r;
    })
    .catch((e) => { });
  return dispatchModeList;
};
export const getPerticularDispatchMode = async (id) => {
  let dpm = {};
  await pgsService
    .get(`/DispatchModes/${id}`)
    .then((r) => {
      dpm = r;
    })
    .catch((e) => { });
  return dpm;
};
export const getMailingSchemeLists = async () => {
  let msl = [];
  await pgsService
    .get(`/MailingSchemes`)
    .then((r) => {
      msl = r;
    })
    .catch((e) => { });
  return msl;
};
export const postMailingScheme = async (history, reqBody) => {
  let msl = [];
  await pgsService
    .post("/MailingSchemes", reqBody)
    .then((r) => {
      msl = r;
      toastr.success("Mailing Scheme Created Successfully !");
      history.push("/mailingScheme");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return msl;
};
export const putMailingScheme = async (history, id, reqBody) => {
  let msl = [];
  await pgsService
    .put(`/MailingSchemes/${id}`, reqBody)
    .then((r) => {
      toastr.success("Mailing Scheme Updated Successfully !");
      history.push("/mailingScheme");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return msl;
};
export const getPerticularMailingScheme = async (id) => {
  let msl = {};
  await pgsService
    .get(`/MailingSchemes/${id}`)
    .then((r) => {
      msl = r;
    })
    .catch((e) => { });
  return msl;
};
export const getFilteredMailingList = async (reqBody) => {
  let fliterdMSL = [];

  await pgsService
    .post("/MailingSchemesAdvanceSerach", reqBody)
    .then((r) => {
      fliterdMSL = r;
    })
    .catch((e) => { });
  return fliterdMSL;
};
export const getLocalPostageLists = async () => {
  let lpl = [];
  await pgsService
    .get(`/LocalPostages`)
    .then((r) => {
      lpl = r;
    })
    .catch((e) => { });
  return lpl;
};
export const postLocalPostageLists = async (history, reqBody) => {
  let msl = [];
  await pgsService
    .post("/LocalPostages", reqBody)
    .then((r) => {
      msl = r;
      toastr.success("Local Postage Created Successfully !");
      history.push("/localPostage");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return msl;
};
export const getParticularLocalPostage = async (id) => {
  let lp = {};
  await pgsService
    .get(`/LocalPostages/${id}`)
    .then((r) => {
      lp = r;
    })
    .catch((e) => { });
  return lp;
};

export const putLocalPostage = async (history, id, reqBody) => {
  let msl = [];
  await pgsService
    .put(`/LocalPostages/${id}`, reqBody)
    .then((r) => {
      toastr.success("Local Postage Updated Successfully");
      history.push("/localPostage");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return msl;
};
export const getFilteredLocalPostage = async (reqBody) => {
  let fliterdLPL = [];

  await pgsService
    .post("/LocalPostagesAdvanceSerach", reqBody)
    .then((r) => {
      fliterdLPL = r;
    })
    .catch((e) => { });
  return fliterdLPL;
};

export const deleteLocalPostage = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/LocalPostages`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Local Postage Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

// export const getUserDDList = async () => {
//   let uddl = [];
//   await pgsService
//     .get(`/LocalPostages`)
//     .then(r => {
//       uddl = r;

//     })
//     .catch(e => {});
//   return uddl;
// };||||||| .r1001

//--------------------warehouselocation functions starts----------------------//
export const getWarehouseLocationList = async () => {
  let warehouseLocationList = [];
  await pgsService
    .get("/WarehouseLocations")
    .then((r) => {
      warehouseLocationList = r;
    })
    .catch((e) => { });
  return warehouseLocationList;
};

export const getWarehouseLocationDDList = async () => {
  let warehouseLocationList = [];
  await pgsService
    .get("/WarehouseLocationsDropdown")
    .then((r) => {
      warehouseLocationList = r;
    })
    .catch((e) => { });
  return warehouseLocationList;
};
export const getWarehouseLocationDDListForStockIn = async () => {
  let warehouseLocationList = [];
  await pgsService
    .get("/GetDDLWareLocationListForGenWR")
    .then((r) => {
      warehouseLocationList = r;
    })
    .catch((e) => { });
  return warehouseLocationList;
};
export const updateWarehouseLocation = async (
  warehouselocationId,
  data,
  history
) => {
  await pgsService
    .put("/WarehouseLocations/" + warehouselocationId, data)
    .then((r) => {
      toastr.success("WarehouseLocation Updated Successfully");
      history.push("/warehouseLocation");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const deleteWarehouseLocation = async (modifiedBy, deletionIds) => {
  //let lettershoplist = [];

  //let modifiedBy = localStorage.loggedInId;
  await pgsService
    .delete("/WarehouseLocations", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("WarehouseLocation Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const deleteDispatchMode = async (modifiedBy, deletionIds) => {
  //let lettershoplist = [];

  //let modifiedBy = localStorage.loggedInId;
  await pgsService
    .delete("/DispatchModes", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Dispatch Mode Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const getWarehouseLocationCode = async (warehouselocationId) => {
  let warehouseLocationCode = {};
  await pgsService
    .get("/WarehouseLocations/" + warehouselocationId)
    .then((response) => {
      let data = response.data;
      warehouseLocationCode = {
        locationCode: data.locationCode,
        warehouseSuffix: data.warehouseSuffix,
        warehouseFloor: data.warehouseFloor,
        warehouseBay: data.warehouseBay,
        warehouseBin: data.warehouseBin,
        warehouseStack: data.warehouseStack,
        warehouseSection: data.warehouseSection,
        holdingWeight: data.holdingWeight,
        warehouseArea: data.warehouseArea,
        warehouseSequenceNo: data.warehouseSequenceNo,
        isActive: data.isActive,
        createdBy: data.createdBy,
        createdDate: data.createdDate,
        modifiedBy: data.modifiedBy,
        modifiedDate: data.modifiedDate,
      };
    })
    .catch((e) => { });
  return warehouseLocationCode;
};
export const getFliteredWarehouseLocationList = async (reqBody) => {
  let warehouseLocationList = [];

  await pgsService
    .post("/WarehouseLocationsAdvanceSerach", reqBody)
    .then((r) => {
      warehouseLocationList = r;
    })
    .catch((e) => { });
  return warehouseLocationList;
};
export const postWarehouseLocation = async (data, history) => {
  await pgsService
    .post("/WarehouseLocations", data)
    .then((r) => {
      toastr.success("WarehouseLocation Created Successfully");
      history.push("/warehouseLocation");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

//-------------------------warehouselocation ends---------------------//
//--------------------distributor functions starts----------------------//
export const getDistributorList = async () => {
  let DistributorList = [];
  await pgsService
    .get("/Distributors")
    .then((r) => {
      DistributorList = r;
    })
    .catch((e) => { });
  return DistributorList;
};
export const updateDistributor = async (distributorId, data, history) => {
  await pgsService
    .put("/Distributors/" + distributorId, data)
    .then((r) => {
      toastr.success("Distributor Updated Successfully");
      history.push("/distributor");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const deleteDistributor = async (modifiedBy, deletionIds) => {
  //let lettershoplist = [];

  //let modifiedBy = localStorage.loggedInId;
  await pgsService
    .delete("/Distributors", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Distributor Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const getFilteredCodeMasterList = async (reqBody) => {
  let codeMasterList = [];
  await pgsService
    .post("/CodeMasterAdvSerach", reqBody)
    .then((r) => {
      codeMasterList = r;
    })
    .catch((e) => { });
  return codeMasterList;
};
export const getFliteredProcessTypeList = async (reqBody) => {
  let processTypeList = [];

  await pgsService
    .post("/ProcessTypesAdvSerach", reqBody)
    .then((r) => {
      processTypeList = r;
    })
    .catch((e) => { });
  return processTypeList;
};

export const getDistributorCode = async (distributorId) => {
  let Distributorcode = {};
  await pgsService
    .get("/Distributors/" + distributorId)
    .then((response) => {
      let data = response.data;
      Distributorcode = {
        distributorCode: data.distributorCode,
        distributorName: data.distributorName,
        isActive: data.isActive,
        createdBy: data.createdBy,
        createdDate: data.createdDate,
        modifiedBy: data.modifiedBy,
        modifiedDate: data.modifiedDate,
      };
    })
    .catch((e) => { });
  return Distributorcode;
};
export const getFliteredDistributorList = async (reqBody) => {
  let DistributorList = [];

  await pgsService
    .post("/DistributorsAdvanceSerach", reqBody)
    .then((r) => {
      DistributorList = r;
    })
    .catch((e) => { });
  return DistributorList;
};
export const postDistributor = async (data, history) => {
  await pgsService
    .post("/Distributors", data)
    .then((r) => {
      toastr.success("Distributor Created Successfully");
      history.push("/distributor");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
//-------------------------distributor ends---------------------//

//------------------- production line --------------------------//

export const getProductionLineList = async () => {
  let prodLineList = [];
  await pgsService
    .get("/ProductionLines")
    .then((r) => {
      prodLineList = r;
    })
    .catch((e) => { });
  return prodLineList;
};

export const getProductionLineListDD = async () => {
  let prodLineList = [];
  await pgsService
    .get("/GetProductionLinesDropdown")
    .then((r) => {
      prodLineList = r;
    })
    .catch((e) => { });
  return prodLineList;
};

export const getProductionLineCode = async (productionLineId) => {
  let productionLineCodes = {};
  await pgsService
    .get("/ProductionLines/" + productionLineId)
    .then((response) => {
      let data = response.data;
      productionLineCodes = {
        productionLineCode: data.productionLineCode,
        productionLineName: data.productionLineName,
        productionLineDescription: data.productionLineDescription,
        isInternal: data.isInternal,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });
  return productionLineCodes;
};
export const deleteProductionLineCode = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/ProductionLines`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Production Line Code Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const postproductionLineCode = async (data, history) => {
  await pgsService
    .post("/ProductionLines", data)
    .then((r) => {
      toastr.success("Production Line Created Successfully");
      history.push("/productionLine");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const updateProductionLineID = async (cptCodeId, data, history) => {
  await pgsService
    .put("/ProductionLines/" + cptCodeId, data)
    .then((r) => {
      toastr.success("Production Line Updated Successfully");
      history.push("/productionLine");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getFliteredproductionLineCodeList = async (reqBody) => {
  let productionLineCodeList = [];

  await pgsService
    .post("/ProductionLinesAdvanceSerach", reqBody)
    .then((r) => {
      productionLineCodeList = r;
    })
    .catch((e) => { });
  return productionLineCodeList;
};

export const getjobScheduleProductionDropDownList = async () => {
  let prodLineId = [];
  await pgsService
    .get("/GetProductionLinesDropdown")
    .then((r) => {
      prodLineId = r;
    })
    .catch((e) => { });
  return prodLineId;
};

// ----------------production line ends ------------------//

export const getServiceList = async () => {
  let svList = [];
  await pgsService
    .get("/ServicesDropdown")
    .then((r) => {
      svList = r;
    })
    .catch((e) => { });
  return svList;
};

export const getServiceDropDown = async () => {
  let svList = [];
  await pgsService
    .get("/ServicesDropdown")
    .then((r) => {
      svList = r;
    })
    .catch((e) => { });
  return svList;
};

//------------------- code type --------------------------//

export const getCodeTypeList = async () => {
  let codeTypeList = [];
  await pgsService
    .get("/CodeType")
    .then((r) => {
      codeTypeList = r;
    })
    .catch((e) => { });
  return codeTypeList;
};

export const getCodeType = async (codeTypeId) => {
  let codeTypeCodes = {};
  await pgsService
    .get("/CodeType/" + codeTypeId)
    .then((response) => {
      let data = response.data;
      codeTypeCodes = {
        codeTypeID: data.codeTypeID,
        codeType: data.codeType,
        codeTypeDescription: data.codeTypeDescription,
        canAddNew: data.canAddNew,
        canEditStartDate: data.canEditStartDate,
        canEditValue: data.canEditValue,
        serviceSpecific: data.data,
        codevalueDataType: data.codevalueDataType,
        isActive: data.isActive,
        modifiedBy: data.modifiedBy,
      };
    })
    .catch((e) => { });
  return codeTypeCodes;
};
export const deleteCodeType = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/CodeType`, {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      toastr.success("Code Type Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const postCodeType = async (data, history) => {
  await pgsService
    .post("/CodeType", data)
    .then((r) => {
      toastr.success("Code Type Created Successfully");
      history.push("/codeType");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const updateCodeID = async (codeTypeId, data, history) => {
  await pgsService
    .put("/CodeType/" + codeTypeId, data)
    .then((r) => {
      toastr.success("Code Type Updated Successfully");
      history.push("/codeType");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getFliteredcodeTypeList = async (reqBody) => {
  let codeTypeList = [];
  await pgsService
    .post("/CodeTypeAdvSerach", reqBody)
    .then((r) => {
      codeTypeList = r;
    })
    .catch((e) => { });
  return codeTypeList;
};

// ----------------code type ends ------------------//>>>>>>> .r1112

export const getUOMList = async () => {
  let UOMList = [];
  await pgsService
    .get("/GetUOMsDropdown")
    .then((r) => {
      UOMList = r;
    })
    .catch((e) => { });
  return UOMList;
};

export const getUOMPMList = async () => {
  let UOMList = [];
  await pgsService
    .get("/GetUOMPMDropdown")
    .then((r) => {
      UOMList = r;
    })
    .catch((e) => { });
  return UOMList;
};

export const getUOMIMList = async () => {
  let UOMList = [];
  await pgsService
    .get("/GetUOMIMDropdown")
    .then((r) => {
      UOMList = r;
    })
    .catch((e) => { });
  return UOMList;
};

export const getUOMList1 = async () => {
  let UOMList = [];
  await pgsService
    .get("/UOMs")
    .then((r) => {
      UOMList = r;
    })
    .catch((e) => { });
  return UOMList;
};

export const getStockCategory = async () => {
  let StockCategoryList = [];
  await pgsService
    .get("/GetStockCategorysDropdown")
    .then((r) => {
      StockCategoryList = r;
    })
    .catch((e) => { });
  return StockCategoryList;
};

export const getStockCategory1 = async () => {
  let StockCategoryList1 = [];
  await pgsService
    .get("/StockCategorys")
    //     .then(r => {
    //       StockCategoryList1 = r;
    //     })
    //     .catch(e => {});
    //   return StockCategoryList1;
    // };
    .then((r) => {
      StockCategoryList1 = r;
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return StockCategoryList1;
};

export const getSubscriptionList = async () => {
  let SubscriptionList = [];
  await pgsService
    .get("/Subscriptions")
    .then((r) => {
      SubscriptionList = r;
    })
    .catch((e) => { });
  return SubscriptionList;
};

export const getStatusList = async () => {
  let statusList = [];
  await pgsService
    .get("/GENStatus")
    .then((r) => {
      statusList =
        r.data &&
        r.data.length > 0 &&
        r.data.filter((x) => x.genStatusGroupCode === "GEN WO");
    })
    .catch((e) => { });
  return statusList;
};

export const getShipmentTypeList = async () => {
  let shipmentTypeList = [];
  await pgsService
    .get("/GetDropdownForShipmentType")
    .then((r) => {
      shipmentTypeList = r;
    })

    .catch((e) => { });
  return shipmentTypeList;
};

export const getPrintingProcess = async () => {
  let pp = [];
  await pgsService
    .get("/PrintingProcessDropdown")
    .then((r) => {
      pp = r;
    })

    .catch((e) => { });
  return pp;
};
export const getProcessTypes = async () => {
  let pp = [];
  await pgsService
    .get("/ProcessTypesDropdown")
    .then((r) => {
      pp = r;
    })

    .catch((e) => { });
  return pp;
};

export const getProcessCategory = async () => {
  let pg = [];
  await pgsService
    .get("/GetProcessCategoryForGenDropdown")
    .then((r) => {
      pg = r;
    })

    .catch((e) => { });
  return pg;
};

export const getUOProcess = async () => {
  let pp = [];
  await pgsService
    .get("/GetUOPProcessDropdown")
    .then((r) => {
      pp = r;
    })

    .catch((e) => { });
  return pp;
};

export const getUOProcessNonDS = async () => {
  let pp = [];
  await pgsService
    .get("/GetNonDataServiceProcessDropdown")
    .then((r) => {
      pp = r;
    })

    .catch((e) => { });
  return pp;
};

export const updateProcessRate = async (id, data, history) => {
  let lp = {};
  await pgsService
    .put("/ProcessRates/" + id, data)
    .then((r) => {
      lp = r;
      toastr.success("Process Rate Updated Successfully");
      history.push("/processRate");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return lp;
};
export const createProcessRate = async (reqBody, history) => {
  let codeTypeList = [];
  await pgsService
    .post("/ProcessRates", reqBody)
    .then((r) => {
      //console.log(r);
      toastr.success("Process Rate Created Successfully");
      history.push("/processRate");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return codeTypeList;
};

export const getParticularProcessRate = async (id) => {
  let lp = {};
  await pgsService
    .get(`/ProcessRates/${id}`)
    .then((r) => {
      lp = r;
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return lp;
};

// Process master starts

export const getProcessMasterList = async () => {
  let processMasterList = [];
  await pgsService
    .get("/Process")
    .then((r) => {
      processMasterList = r;
    })
    .catch((e) => { });
  return processMasterList;
};

export const getFliteredProcessList = async (reqBody) => {
  let processMasterList = [];
  await pgsService
    .post("/ProcessAdvanceSerach", reqBody)
    .then((r) => {
      processMasterList = r;
    })
    .catch((e) => { });
  return processMasterList;
};

export const deleteProcess = async (deletionIds, history, token) => {
  let ProcessList = [];
  let modifiedBy = localStorage.loggedInId;

  await pgsService
    .delete("/Process", {
      data: {
        modifiedBy,
        deletionIds,
      },
    })
    .then((r) => {
      history.push("/processMaster");
      toastr.success("Process Deleted Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });

  return ProcessList;
};

export const postProcess = async (reqBody, history) => {
  await pgsService
    .post("/Process", reqBody)
    .then((r) => {
      toastr.success("Process Created Successfully");
      history.push("/processMaster");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const updateProcess = async (processId, data, history) => {
  await pgsService
    .put("/Process/" + processId, data)
    .then((r) => {
      history.push("/processMaster");
      toastr.success("Process Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const viewProcess = async (processId) => {
  let process = {};
  await pgsService
    .get("/Process/" + processId)
    .then((r) => {
      process = r;
      // history.push("/processMaster");
      // toastr.success("Process Updated Successfully");
    })
    .catch((e) => {
      if (e.response && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
  return process;
};
// process master ends

export const getOWCDescriptionPerCategoryDropDown = async (id) => {
  let list = [];
  await pgsService
    .get(`/GetDropdownBYCatId/${id}`)
    .then((response) => {
      // console.log(response);
      list = response.data;
    })
    .catch((e) => { });
  return list;
};

export const GetLetterShopByID = async (customerID) => {
  let Customer = {};
  await pgsService
    .get("/GetPPILetterShopByCustPPIId/" + customerID)
    .then((response) => {
      Customer = response.data;
    })
  // .catch((e) => {
  //   if (e.respone && e.response.data.errors[0].message !== undefined) {
  //     toastr.error(e.response.data.errors[0].message);
  //   } else if (e.response && e.response.data.errors[0]) {
  //     toastr.error(e.response.data.errors[0]);
  //   }
  // });
  return Customer;
};
export const GetLetterShopByPPPID = async (customerID, ppiNo) => {
  let Customer = {};
  await pgsService
    .get("/GetPPILetterShopByCustPPIId/" + customerID + "/" + ppiNo)
    .then((response) => {
      Customer = response.data;
    })
  // .catch((e) => {
  //   if (e.respone && e.response.data.errors[0].message !== undefined) {
  //     toastr.error(e.response.data.errors[0].message);
  //   } else if (e.response && e.response.data.errors[0]) {
  //     toastr.error(e.response.data.errors[0]);
  //   }
  // });
  return Customer;
};
export const getWareLocationDD = async () => {
  let data = [];
  await pgsService
    .get("/WarehouseLocationsDropdown")
    .then((r) => {
      data = r;
    })
    .catch((e) => { });
  return data;
};
