import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withRouter } from "react-router-dom";
import {Autocomplete} from "@material-ui/lab" ;
import InputAdornment from "@material-ui/core/InputAdornment";
import { addZeros } from "../../../shared/tableCommonFunctions";
import {
  getOutworkerClaimRate,
  updateOutworkerClaimRateID,
  getUomDropDownList,
  getProcessList,
} from "../../masters/masterActions";
import Validator from "../../../shared/validator";
// Include new StateContext
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";

const validateNotRequired100 = new Validator("maxlength100AllAllow");
const onlyNumbers = new Validator("onlyNumbers");
const checkrate = new Validator("ratecheckdecimalpoint");

const CustomCheckbox = withStyles({
  root: {
    color: "gray",
    "&$checked": {
      color: "#3982ea",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const styles = (theme) => ({
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "31%",
    fontSize: "11px !important",
  },
  comboBox: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "31%",
    fontSize: "11px !important",
  },
  checkBox: {
    marginTop: theme.spacing(3.5),
    marginLeft: theme.spacing(1.5),
    marginRight: "1.5em !important",
  },
});

class EditOutworkerClaimRate extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      outworkerClaimRate: {
        owcRateID: "",
        processName: "",
        processID: "",
        rate: 0,
        uomid: 0,
        remarks: "",
        isActive: false,
        modifiedBy: 1,
      },
      errors: {},
      selectedDDL: 0,
      selectedDDL2: 0,
      processValid: true,
      rateValid: true,
      uomValid: true,
      remarksValid: true,
      processList: [],
      active: false,
      uomList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let owcRateID = this.props.match.params.outworkerClaimRateId;
    this.props.handleSettingHeader("Outworker Claim Rate");
    this.props.setSelectedList("masterDataOpen", "outworkerClaimRate");
    getOutworkerClaimRate(owcRateID).then((r) => {
      let data = r;
      data = {
        processName: data.processName,
        rate: data.rate && addZeros(data.rate.toString(), 2),
        uomid: data.uomid,
        remarks: data.remarks,
        isActive: data.isActive,
      };
      // dispatch({
      //   type: "getOutworkerClaimRate",
      //   newStore: r
      // });
      this.setState({ outworkerClaimRate: data });
      if (r.isActive) this.setState({ active: r.isActive });
    });
    getProcessList().then((r) => {
      dispatch({
        type: "getProcessList",
        newStore: r.data,
      });
      //this.setState({processList: r.data});
      this.getAllProcess(r.data);
    });
    getUomDropDownList().then((r) => {
      dispatch({
        type: "getUomDropDownList",
        newStore: r.data,
      });
      //this.setState({processList: r.data});
      this.getAllUoms(r.data);
    });
  }
  getAllProcess = (list) => {
    let processList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        c.isActive
          ? processList.push({
              key: c.processID,
              value: c.processName,
              active: c.isActive,
            })
          : processList
      );
    this.setState({
      processList: processList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      ),
    });
  };
  getAllUoms = (list) => {
    let uomList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        uomList.push({
          id: c.id,
          text: c.text,
          value: c.value,
        })
      );
    this.setState({
      uomList: uomList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      ),
    });
  };

  handleChange = (event) => {
    const { outworkerClaimRate } = this.state;
    if (event.target.name === "isActive") {
      outworkerClaimRate[event.target.name] = event.target.checked;
    } else if (event.target.name === "rate") {
      let rate = event.target.value;
      rate = this.checkDHlWeightDec(event.target.value.toString());
      outworkerClaimRate[event.target.name] = rate;
    } else {
      outworkerClaimRate[event.target.name] = event.target.value;
    }
    this.setState({ outworkerClaimRate });

    this.validateField(event.target.name, event.target.value);
  };
  _onDecimalKeyPress(event) {
    const re = /{[0-9.]{0,4}}+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  onBlur = (event) => {
    const { outworkerClaimRate } = this.state;
    let rate = event.target.value;
    rate = this.addZeroes(rate.toString(), 2);
    outworkerClaimRate[event.target.name] = rate;
    this.setState({ outworkerClaimRate });
  };
  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }
  handleProcessChange = (event, values) => {
    const { outworkerClaimRate } = this.state;
    if (values === null) {
      outworkerClaimRate["processID"] = "null";
      this.setState({ selectedDDL: -1 });
    } else {
      let processID = values.key;
      outworkerClaimRate["processID"] = processID;
    }
    this.setState({ outworkerClaimRate });

    this.validateField("processID", outworkerClaimRate["processID"]);
  };
  handleUomChange = (event, values) => {
    const { outworkerClaimRate } = this.state;
    if (values === null) {
      outworkerClaimRate["uomid"] = "null";
      this.setState({ selectedDDL2: -1 });
    } else {
      let uomText = values.id;
      outworkerClaimRate["uomid"] = uomText;
    }
    this.setState({ outworkerClaimRate });

    this.validateField("uomid", outworkerClaimRate["uomid"]);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let rateValid = this.state.rateValid;
    let uomValid = this.state.uomValid;
    let remarksValid = this.state.remarksValid;
    let processValid = this.state.processValid;
    switch (fieldName) {
      case "rate":
        if (value !== "") {
          rateValid = checkrate(value);
          fieldValidationErrors.rate = rateValid
            ? ""
            : resourceValidation.validRate;
        } else {
          rateValid = false;
          fieldValidationErrors.rate = "";
        }
        break;
      case "uomid":
        if (value !== "null") {
          uomValid = onlyNumbers(value);
          fieldValidationErrors.uomid = uomValid ? "" : " Please select UOM";
        } else {
          uomValid = false;
          fieldValidationErrors.uomid = "";
        }
        break;
      case "remarks":
        if (value !== "") {
          remarksValid = validateNotRequired100(value);
          fieldValidationErrors.remarks = remarksValid
            ? ""
            : resourceValidation.validRemarks;
        } else {
          remarksValid = true;
          fieldValidationErrors.remarks = "";
        }
        break;
      case "processID":
        if (value !== "null") {
          processValid = onlyNumbers(value);
          fieldValidationErrors.processID = processValid
            ? ""
            : " Please select Process";
        } else {
          processValid = false;
          fieldValidationErrors.processID = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        rateValid: rateValid,
        uomValid: uomValid,
        remarksValid: remarksValid,
        processValid: processValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.rateValid &&
        this.state.uomValid &&
        this.state.remarksValid &&
        this.state.processValid,
    });
    return this.state.formValid;
  }

  handleSubmit(e) {
    e.preventDefault();
    let owcRateID = this.props.match.params.outworkerClaimRateId;
    const { outworkerClaimRate } = this.state;
    outworkerClaimRate.modifiedBy = localStorage.loggedInId;
    updateOutworkerClaimRateID(owcRateID, outworkerClaimRate).then((r) => {
      this.props.history.push("/OutworkerClaimRate");
      //toastr.success("OutworkerClaimRate Updated Successfully");
    });
  }

  render() {
    let { classes } = this.props;
    let {
      outworkerClaimRate,
      active,
      selectedDDL,
      selectedDDL2,
      processList,
      uomList,
    } = this.state;
    let loop = 0;

    processList.map((option) =>
      option.value === outworkerClaimRate.processName
        ? (selectedDDL = loop)
        : (loop = loop + 1)
    );

    let loop2 = 0;

    uomList.map((option) =>
      option.id === this.state.outworkerClaimRate.uomid
        ? (selectedDDL2 = loop2)
        : (loop2 = loop2 + 1)
    );
    //('#processName').val(selectedProcessName);

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Outworker Claim Rate</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="process"
                    name="process"
                    options={processList}
                    className={classes.textField}
                    onChange={this.handleProcessChange}
                    getOptionLabel={(option) => option.value}
                    value={processList.length > 0 && processList[selectedDDL]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label={"Process"}
                        name="processName"
                        id="processName"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={outworkerClaimRate.processName}
                        error={this.state.errors.processID}
                        helperText={
                          this.state.errors.processID
                            ? this.state.errors.processID
                            : ""
                        }
                      />
                    )}
                  />
                  <TextField
                    required
                    label="Rate"
                    name="rate"
                    inputProps={{
                      maxLength: 8,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={outworkerClaimRate.rate}
                    onChange={this.handleChange}
                    onBlur={this.onBlur}
                    error={this.state.errors.rate}
                    helperText={
                      this.state.errors.rate ? this.state.errors.rate : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$S</InputAdornment>
                      ),
                    }}
                    onKeyPress={this._onDecimalKeyPress}
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="uomid"
                    name="uomid"
                    options={uomList}
                    onChange={this.handleUomChange}
                    className={classes.textField}
                    getOptionLabel={(option) => option.text}
                    value={uomList.length > 0 && uomList[selectedDDL2]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label={"UOM"}
                        name="uomid"
                        id="uomid"
                        margin="normal"
                        style={{ width: "100%" }}
                        error={this.state.errors.uomid}
                        helperText={
                          this.state.errors.uomid ? this.state.errors.uomid : ""
                        }
                      />
                    )}
                  />
                </div>
                <div>
                  <TextField
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    label="Remarks"
                    name="remarks"
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={outworkerClaimRate.remarks}
                    onChange={this.handleChange}
                    error={this.state.errors.remarks}
                    helperText={
                      this.state.errors.remarks ? this.state.errors.remarks : ""
                    }
                  />
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      name="isActive"
                      className={classes.checkBox}
                      control={
                        <CustomCheckbox
                          checked={outworkerClaimRate.isActive}
                          onChange={this.handleChange}
                          value={outworkerClaimRate.isActive}
                        />
                      }
                      label="Active"
                    />
                  )}
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/outworkerClaimRate")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        onClick={this.handleSubmit}
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(EditOutworkerClaimRate));
