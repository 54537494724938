import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_PUBLISHER_MASTER;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getJournalitemMasterList = async () => {
  let jimList = [];
  await pgsService
    .get("/JournalItemGetAll")
    .then((r) => {
      jimList = r;
    })
    .catch((e) => { });
  return jimList;
};
export const getAllJDR = async (date, id) => {
  let jimList = [];
  await pgsService
    .get(`/GetJDRAll/${date}/${id}`)
    .then((r) => {
      jimList = r;
    })
    .catch((e) => { });
  return jimList;
};
export const putJDRList = async (reqBody) => {
  let jimList = [];
  await pgsService
    .put("/JDRDetailsList", reqBody)
    .then((r) => {
      toastr.success("JDR updated successfully !");
    })
    .catch((e) => { });
};
export const getAllJRR = async (date, id) => {
  let jimList = [];
  await pgsService
    .get(`/GetJRRAll/${date}/${id}`)
    .then((r) => {
      jimList = r;
    })
    .catch((e) => { });
  return jimList;
};
export const putJRRList = async (reqBody) => {
  let jimList = [];
  await pgsService
    .put("/JRRDetailsList", reqBody)
    .then((r) => {
      toastr.success("JRR updated successfully !");
    })
    .catch((e) => { });
};
export const getOneJournalitemMasterList = async (id) => {
  let jimList = [];
  await pgsService
    .get(`JournalItemGetByID/${id}`)
    .then((r) => {
      jimList = r;
    })
    .catch((e) => { });
  return jimList;
};

export const postJournalitemMasterList = async (reqBody, history) => {
  let jimList = [];
  await pgsService
    .post("/JournalItemCreate", reqBody)
    .then((r) => {
      toastr.success("Journal created successfully !");
    })
    .catch((e) => { });
};

// Insert Master
export const uploadeMLOPFiles = async (data) => {
  let result = [];
  await pgsService
    .post("/MLOPCreate", data)
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else result = r.data;
    })
    .catch((e) => { });
  return result;
};
export const getInsertMasterList = async () => {
  let insertMasterList = [];
  await pgsService
    .get("/GetInsertMasterAll")
    .then((r) => {
      insertMasterList = r;
    })
    .catch((e) => { });
  return insertMasterList;
};

export const getInsertMasterNumberList = async () => {
  let insertMasterList = [];
  await pgsService
    .get("/GetAllInsertNo")
    .then((r) => {
      insertMasterList = r;
    })
    .catch((e) => { });
  return insertMasterList;
};

export const getCustomerForInsertMasterList = async () => {
  let customerList = [];
  await pgsService
    .get("/GetPublisherCustomer")
    .then((r) => {
      customerList = r;
    })
    .catch((e) => { });
  return customerList;
};

export const postInsertMaster = async (reqBody, history) => {
  let ItemMasterList = [];

  await pgsService
    .post("/InsertMasterCreate", reqBody)
    .then((r) => {
      ItemMasterList = r;
      toastr.success("Insert Master Created Successfully");
      history.push("/pubInsertMaster");
    })
    .catch((e) => { });
  return ItemMasterList;
};

export const validateEmailInsertMaster = async (reqBody) => {
  let ItemMasterList = [];
  await pgsService
    .post("/VaidateEmail", reqBody)
    .then((r) => {
      ItemMasterList = r;
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
      ItemMasterList = e.response.data.errors[0];
    });
  return ItemMasterList;
};

export const postEmailInsertMaster = async (reqBody, history) => {
  let ItemMasterList = [];
  await pgsService
    .post("/InsertMasterEmailCreate", reqBody)
    .then((r) => {
      ItemMasterList = r;
      let getResponse =
        r &&
        r.data &&
        r.data.map((val) => {
          return val.exceptionMessage;
        });

      if (getResponse.includes("File is already Present.")) {
        // toastr.error("Insert Master duplicate data");
      } else {
        history.push("/pubInsertMaster");
        toastr.success("File is Uploaded Successfully");
      }
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
      ItemMasterList = e.response.data.errors[0];
    });
  return ItemMasterList;
};

export const seacrhItemMasterAdvanceSearch = async (reqBody) => {
  let searchList = [];
  await pgsService
    .post("/insertMasterRequest", reqBody)
    .then(async (r) => {
      searchList = r;
    })
    .catch((e) => { });
  return searchList;
};

export const getViewDetailsForInsertMaster = async (id) => {
  let customerList = [];
  await pgsService
    .get("/GetInsertMasterByID/" + id)
    .then((r) => {
      customerList = r;
    })
    .catch((e) => { });
  return customerList;
};

export const deleteInsertmaster = async (modifiedBy, deletionIds) => {
  await pgsService
    .delete(`/DeleteInsertMaster/${deletionIds}`)
    .then((r) => {
      toastr.success("Insert master Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const putInsertMaster = async (id, data, history) => {
  let ItemMasterList = [];
  await pgsService
    .put("/InsertMaster/" + id, data)
    .then((r) => {
      ItemMasterList = r;
      history.push("/pubInsertMaster");
      toastr.success("Insert Master Updated Successfully");
    })
    .catch((e) => { });
  return ItemMasterList;
};

export const getPubMainLabelOrderPlacement = async () => {
  let pmlopList = [];
  await pgsService
    .get("/GetMLOPHeader")
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};

export const getPublisherCustomerList = async () => {
  let customerList = [];
  await pgsService
    .get("/GetPublisherCustomer")
    .then((r) => {
      customerList = r;
    })
    .catch((e) => { });
  return customerList;
};

export const deleteInsertmasterByID = async (deletionId) => {
  await pgsService
    .delete(`/DeleteInsertMasterHeader/${deletionId}`)
    .then((r) => {
      toastr.success("Email File Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getAllAcronymList = async () => {
  let accList = [];
  await pgsService
    .get("/GetAcronymList")
    .then((r) => {
      accList = r;
    })
    .catch((e) => { });
  return accList;
};
export const getAllVolumeList = async (id) => {
  let volList = [];
  await pgsService
    .get(`/GetVolumeList/${id}`)
    .then((r) => {
      volList = r;
    })
    .catch((e) => { });
  return volList;
};
export const getAllIssueList = async (id, id1) => {
  let issueList = [];
  await pgsService
    .get(`/GetIssueList/${id}/${id1}`)
    .then((r) => {
      issueList = r;
    })
    .catch((e) => { });
  return issueList;
};
export const getAllSuppList = async (id, id1) => {
  let suppList = [];
  await pgsService
    .get(`/GetSuppNumberList/${id}/${id1}`)
    .then((r) => {
      suppList = r;
    })
    .catch((e) => { });
  return suppList;
};