import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import MuiDialogActions from "@material-ui/core/DialogActions";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const CustomCheckbox = withStyles({
  root: {
    color: "gray",
    "&$checked": {
      color: "#3982ea",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export function desc(a, b, orderBy) {
  // var va = a[orderBy] === null ? "" : a[orderBy],
  //   vb = b[orderBy] === null ? "" : b[orderBy];
  // if (va && typeof (va) === "string")
  //   va = va.toLowerCase()
  // if (vb && typeof (vb) === "string")
  //   va = vb.toLowerCase()
  // console.log(typeof (a[orderBy]) === "string")

  // if (vb < va) {
  //   return -1;
  // }
  // if (vb > va) {
  //   return 1;
  // }
  // return 0;
  if (a[orderBy] === null)
    a[orderBy] = ""
  if (b[orderBy] === null)
    b[orderBy] = ""

  if (b[orderBy] && a[orderBy] && typeof (b[orderBy]) === "string") {
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
      return 1;
    }
    return 0;
  }
  else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

export function stableSort(array, cmp) {

  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);

    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

export const printDetails = () => {
  window.print();
};

export const pdfDownloadDetails = (information) => {
  html2canvas(document.querySelector("#printable"), {}).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    pdf.addImage(imgData, "JPEG", -6, 10);
    pdf.save(information);
  });
};

export const checkTwoDecimalPlaces = (value) => {
  var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
  if (re.test(value) === false) {
    value = value.substring(0, value.length - 1);
  }
  return value;
};

export const addZeros = (num, decimalPlaces) => {
  if (num == 0) {
    var value = Number(num);
    value = value.toFixed(decimalPlaces);
    return value;
  }
  // Convert input string to a number and store as a variable.
  var value = Number(num);
  // Split the input string into two arrays containing integers/decimals
  var res = num.split(".");
  // If there is no decimal point or only one decimal place found.
  if (res.length === 1 || res[1].length < decimalPlaces) {
    // Set the number to two decimal places
    value = value.toFixed(decimalPlaces);
  }
  // Return updated or original number.
  return value;
};
