import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import {
  getProductionLineCode,
  updateProductionLineID,
} from "../masterActions";
import Validator from "../../../shared/validator";
import { Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { resourceValidation } from "../../../shared/resource";
import { addStyles } from "../../../shared/styles";
// Include new StateContext
import { StateContext } from "../../../shared/globalState";
const maxlengthValidator50 = new Validator("maxlength50");
const generalValidator = new Validator("maxlength100");

const styles = (theme) => ({
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "31%",
    fontSize: "11px !important",
  },
  comboBox: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "31%",
    fontSize: "11px !important",
  },
  checkBox: {
    marginTop: theme.spacing(3.5),
    marginLeft: theme.spacing(1.5),
    marginRight: "1.5em !important",
  },
});

class EditProductionLineCode extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      prodLineCode: {
        productionLineCode: "",
        productionLineName: "",
        productionLineDescription: "",
        isInternal: "",
        isActive: "",
        modifiedBy: 1,
        updatedValues: false,
      },
      errors: {},
      active: false,
      productionLineCodeValid: true,
      productionLineNameValid: true,
      productionLineDescriptionValid: true,
      isInternalValid: true,
      formValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Production Line");
    this.props.setSelectedList("masterDataOpen", "productionLine");
    let productionCodeId =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];

    getProductionLineCode(productionCodeId).then((r) => {
      dispatch({
        type: "getProductionLineCode",
        newStore: r,
      });
      this.setState({ prodLineCode: r });
      if (r.isActive) this.setState({ active: r.isActive });
      this.validateField("productionLineCode", r.productionLineCode);
      this.validateField("productionLineName", r.productionLineName);
      this.validateField(
        "productionLineDescription",
        r.productionLineDescription
      );
      // this.validateField("isInternal", r.isInternal);
    });
  }

  handleChange = (event) => {
    const { prodLineCode } = this.state;
    this.setState({ updatedValues: true });

    if (
      event.target.name === "isActive" ||
      event.target.name === "isInternal"
    ) {
      prodLineCode[event.target.name] = event.target.checked;
    } else {
      prodLineCode[event.target.name] = event.target.value;
    }
    this.validateField(event.target.name, event.target.value);
    this.setState({ prodLineCode });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let productionLineCodeValid = this.state.productionLineCodeValid;
    let productionLineNameValid = this.state.productionLineNameValid;
    let productionLineDescriptionValid = this.state
      .productionLineDescriptionValid;
    // let isInternalValid = this.state.isInternalValid;
    switch (fieldName) {
      case "productionLineCode":
        fieldValidationErrors.productionLineCode = "";
        if (value !== "") {
          productionLineCodeValid = maxlengthValidator50(value);
          fieldValidationErrors.productionLineCode = productionLineCodeValid
            ? ""
            : resourceValidation.onlyAlphanumeric;
        }
        break;
      case "productionLineName":
        fieldValidationErrors.productionLineName = "";
        if (value !== "") {
          productionLineNameValid = generalValidator(value);
          fieldValidationErrors.productionLineName = productionLineNameValid
            ? ""
            : resourceValidation.onlyAlphanumeric;
        } else {
          productionLineNameValid = false;
        }
        break;
      case "productionLineDescription":
        fieldValidationErrors.productionLineDescription = "";
        if (value !== "") {
          productionLineDescriptionValid = generalValidator(value);

          fieldValidationErrors.subCode = productionLineDescriptionValid
            ? ""
            : "";
        } else {
          productionLineDescriptionValid = false;
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        productionLineCodeValid: productionLineCodeValid,
        productionLineNameValid: productionLineNameValid,
        productionLineDescriptionValid: productionLineDescriptionValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.state.updatedValues &&
      this.setState({
        formValid:
          this.state.productionLineCodeValid &&
          this.state.productionLineNameValid &&
          this.state.productionLineDescriptionValid,
      });
    //return this.state.formValid;
  }

  _onKeyPress(event) {
    const re = /[0-9a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    let productionCodeId =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];
    const { prodLineCode } = this.state;
    prodLineCode.modifiedBy = localStorage.loggedInId;
    updateProductionLineID(productionCodeId, prodLineCode, this.props.history);
  }

  render() {
    let { classes } = this.props;
    let { prodLineCode, active } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Production Line</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    label="Production Line Code"
                    name="productionLineCode"
                    inputProps={{
                      maxLength: 5,
                    }}
                    disabled
                    variant="filled"
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={prodLineCode.productionLineCode}
                    onChange={this.handleChange}
                    error={this.state.errors.productionLineCode}
                    helperText={
                      this.state.errors.productionLineCode
                        ? this.state.errors.productionLineCode
                        : ""
                    }
                  />
                  <TextField
                    label="Production Line Name"
                    name="productionLineName"
                    required
                    inputProps={{
                      maxLength: 50,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={prodLineCode.productionLineName}
                    onChange={this.handleChange}
                    error={this.state.errors.productionLineName}
                    helperText={
                      this.state.errors.productionLineName
                        ? this.state.errors.productionLineName
                        : ""
                    }
                    //onKeyPress={this._onKeyPress}
                  />
                </div>
                <div>
                  <TextField
                    label="Production Line Description"
                    name="productionLineDescription"
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    inputProps={{
                      maxLength: 50,
                    }}
                    autoComplete="off"
                    margin="normal"
                    required
                    className={classes.textField}
                    value={prodLineCode.productionLineDescription}
                    onChange={this.handleChange}
                    error={this.state.errors.productionLineDescription}
                    helperText={
                      this.state.errors.productionLineDescription
                        ? this.state.errors.productionLineDescription
                        : ""
                    }
                    // onKeyPress={this._onKeyPress}
                  />
                </div>
                <div>
                  <FormControlLabel
                    name="isInternal"
                    className={classes.checkBox}
                    control={
                      <Checkbox
                        color="primary"
                        checked={prodLineCode.isInternal}
                        onChange={this.handleChange}
                        value={prodLineCode.isInternal}
                      />
                    }
                    label="Internal"
                  />
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      name="isActive"
                      className={classes.checkBox}
                      control={
                        <Checkbox
                          color="primary"
                          checked={prodLineCode.isActive}
                          onChange={this.handleChange}
                          value={prodLineCode.isActive}
                        />
                      }
                      label="Active"
                    />
                  )}
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/productionLine")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        onClick={this.handleSubmit}
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditProductionLineCode));
