import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Validator from "../../../shared/validator";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { resourceValidation } from "../../../shared/resource";
import { getAcronymDetails, updateAcronym } from "../masterActions";
import { StateContext } from "../../../shared/globalState";
import { addStyles } from "../../../shared/styles";
import { Autocomplete } from "@material-ui/lab";
const validateMaxlength4to10 = new Validator("maxlength2to10");
const notRequired100 = new Validator("notRequired100");
const Alphanumeric = new Validator("Alphanumeric");

class EditAcronym extends Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      acronym: {
        acronymSuffix: "",
        acronymNumber: "",
        isPeriodical: "",
        specialInstruction: "",
        dimension: "",
        journalType: "",
        journalTitle: "",
        journalID: "",
        isInternal: "",
        officeName: "",
        businessUnit: "",
        isActive: "",
        modifiedBy: 1,
      },
      errors: {},
      active: false,
      acronymSuffixValid: false,
      acronymNumberValid: false,
      specialInstructionValid: false,
      altCountryNameValid: false,
      dimensionValid: false,
      journalTypeValid: false,
      updatedValues: false,
      officeNameValid: false,
      journaltypeList: [
        { values: "ISS", id: "ISS" },
        { values: "SUPP", id: "SUPP" },
      ],
      businessUnitValid: false,
      acronymList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let acronymID = this.props.match.params.acronymID;

    getAcronymDetails(acronymID).then((r) => {
      dispatch({
        type: "getAcronym",
        newStore: r,
      });
      this.setState({ acronym: r });
      if (r.isActive) this.setState({ active: r.isActive });
      this.validateField("acronymSuffix", r.acronymSuffix);
      this.validateField("acronymNumber", r.acronymNumber);
      this.validateField("specialInstruction", r.specialInstruction);
      this.validateField("officeName", r.officeName);
      this.validateField("dimension", r.dimension);
      this.validateField("businessUnit", r.businessUnit);
      this.validateField("journalType", r.journalType);
    });
  }
  handleJournalType = (event, values) => {
    this.setState({ updatedValues: true, formValid: true });
    const { acronym } = this.state;
    if (values) {
      acronym["journalType"] = values.values;
      acronym["journalID"] = values.id;
    } else {
      acronym["journalType"] = "";
      acronym["journalID"] = "";
    }

    this.setState({ acronym });
    // console.log(acronym);
  };
  handleChange = (event) => {
    let val = event.target.value
    this.setState({ updatedValues: true });
    const { acronym } = this.state;
    if (
      event.target.name === "isPeriodical" ||
      event.target.name === "isInternal" ||
      event.target.name === "isActive"
    ) {
      acronym[event.target.name] = event.target.checked;
    } else {
      if (event.target.name === "acronymSuffix")
        val = val.toUpperCase()
      acronym[event.target.name] = val;
      this.validateField(event.target.name, event.target.value);
    }
    this.setState({ acronym });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;

    let acronymSuffixValid = this.state.acronymSuffixValid;
    let acronymNumberValid = this.state.acronymNumberValid;
    let specialInstructionValid = this.state.specialInstructionValid;
    let businessUnitValid = this.state.businessUnitValid;
    let dimensionValid = this.state.dimensionValid;
    let journalTypeValid = this.state.journalTypeValid;
    let officeNameValid = this.state.officeNameValid;
    switch (fieldName) {
      case "acronymSuffix":
        if (value !== "") {
          acronymSuffixValid = validateMaxlength4to10(value);
          fieldValidationErrors.acronymSuffix = acronymSuffixValid
            ? ""
            : resourceValidation.max2char;
        } else {
          acronymSuffixValid = false;
          fieldValidationErrors.acronymSuffix = "Please Enter Mandatory Field";
        }
        break;
      case "acronymNumber":
        // acronymNumberValid = maxlengthValidatorcharnum10(value);
        acronymNumberValid = true;
        fieldValidationErrors.acronymNumber = acronymNumberValid
          ? ""
          : resourceValidation.max10char;
        break;
      case "specialInstruction":
        //specialInstructionValid = notRequired1000(value);
        specialInstructionValid = true;
        fieldValidationErrors.specialInstruction = specialInstructionValid
          ? ""
          : " Please enter alphabet characters only";
        break;
      case "officeName":
        if (value !== "") {
          officeNameValid = true;
          fieldValidationErrors.officeName = officeNameValid
            ? ""
            : resourceValidation.onlyAlphabet;
        } else {
          officeNameValid = false;
          fieldValidationErrors.officeName = "Please Enter Mandatory Field";
        }
        break;
      case "dimension":
        dimensionValid = Alphanumeric(value);
        fieldValidationErrors.dimension = dimensionValid
          ? ""
          : resourceValidation.onlyAlphabet;
        break;
      case "businessUnit":
        //businessUnitValid = Alphanumeric(value);
        businessUnitValid = true;
        fieldValidationErrors.businessUnit = businessUnitValid
          ? ""
          : resourceValidation.onlyAlphabet;
        break;
      // case "journalType":
      //   if (value !== "") {
      //     journalTypeValid = notRequired100(value);
      //     fieldValidationErrors.journalType = journalTypeValid
      //       ? ""
      //       : resourceValidation.onlyAlphabet;
      //   } else {
      //     journalTypeValid = false;
      //     fieldValidationErrors.journalType = "Please Enter Mandatory Field";
      //   }
      //   break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,

        acronymSuffixValid: acronymSuffixValid,
        acronymNumberValid: acronymNumberValid,
        specialInstructionValid: specialInstructionValid,
        dimensionValid: dimensionValid,
        journalTypeValid: journalTypeValid,
        businessUnitValid: businessUnitValid,
        officeNameValid: officeNameValid,
      },
      this.validateForm
    );
  }

  validateForm = () => {
    this.state.updatedValues &&
      this.setState({
        formValid:
          this.state.acronymSuffixValid &&
          //this.state.businessUnitValid &&
          //this.state.journalTypeValid &&
          this.state.officeNameValid,
      });
    return this.state.formValid;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      const { acronym } = this.state;
      let acronymID = this.props.match.params.acronymID;
      let acronymSuffix = acronym.acronymSuffix;
      let acronymNumber = acronym.acronymNumber;
      let specialInstruction = acronym.specialInstruction;
      let dimension = acronym.dimension;
      let journalType = acronym.journalType;
      let journalTitle = acronym.journalTitle;
      let officeName = acronym.officeName;
      let businessUnit = acronym.businessUnit;
      let isPeriodical = acronym.isPeriodical;
      let isInternal = acronym.isInternal;
      let isActive = acronym.isActive;

      let reqBody = {
        acronymSuffix,
        acronymNumber,
        specialInstruction,
        dimension,
        journalType,
        journalTitle,
        officeName,
        businessUnit,
        isPeriodical,
        isInternal,
        isActive,
      };
      reqBody.modifiedBy = localStorage.loggedInId;

      updateAcronym(acronymID, reqBody, this.props.history);
    }
  };

  _onKeyPress(event) {
    const re = /[0-9a-zA-Z ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onAlphaKeyPress(event) {
    const re = /[a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onDimensionKeyPress(event) {
    const re = /[0-9*]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  render() {
    let { classes } = this.props;
    let { acronym, errors, active } = this.state;

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Acronym</div>
          <Divider />

          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    label="Acronym Suffix"
                    name="acronymSuffix"
                    inputProps={{
                      maxLength: 10,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={acronym.acronymSuffix}
                    onChange={this.handleChange}
                    error={this.state.errors.acronymSuffix}
                    helperText={
                      this.state.errors.acronymSuffix
                        ? errors.acronymSuffix
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    label="Acronym Number"
                    name="acronymNumber"
                    inputProps={{
                      maxLength: 10,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={acronym.acronymNumber}
                    onChange={this.handleChange}
                    error={this.state.errors.acronymNumber}
                    helperText={
                      this.state.errors.acronymNumber
                        ? resourceValidation.max10char
                        : ""
                    }
                    onKeyPress={this._onNumberKeyPress}
                  />
                </div>
                <div>
                  <TextField
                    required
                    margin="normal"
                    label="Office Name"
                    name="officeName"
                    inputProps={{
                      maxLength: 50,
                    }}
                    autoComplete="off"
                    className={classes.textField}
                    value={acronym.officeName}
                    onChange={this.handleChange}
                    error={this.state.errors.officeName}
                    helperText={
                      this.state.errors.officeName ? errors.officeName : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    //required
                    label="Business Unit"
                    name="businessUnit"
                    margin="normal"
                    inputProps={{
                      maxLength: 10,
                    }}
                    autoComplete="off"
                    className={classes.textField}
                    value={acronym.businessUnit}
                    onChange={this.handleChange}
                    error={this.state.errors.businessUnit}
                    helperText={
                      this.state.errors.businessUnit
                        ? "Maximum 10 characters allowed"
                        : ""
                    }
                  />
                </div>
                {/* 2nd line */}
                <div>
                  <TextField
                    label="Dimension"
                    name="dimension"
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={acronym.dimension}
                    onChange={this.handleChange}
                    error={this.state.errors.dimension}
                    helperText={
                      this.state.errors.dimension
                        ? resourceValidation.max100char
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    multiline={true}
                    rows={1}
                    disableClearable
                    rowsMax={4}
                    label="Special Instruction"
                    name="specialInstruction"
                    inputProps={{
                      maxLength: 1000,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={acronym.specialInstruction}
                    onChange={this.handleChange}
                    error={this.state.errors.specialInstruction}
                    helperText={
                      this.state.errors.specialInstruction
                        ? resourceValidation.max1000char
                        : ""
                    }
                  />
                </div>
                <div>
                  <Autocomplete
                    autoComplete
                    // required
                    includeInputInList
                    label="Journal Type"
                    name="journalType"
                    style={{ display: "inline-flex" }}
                    options={this.state.journaltypeList}
                    className={classes.comboBox}
                    onChange={this.handleJournalType}
                    value={acronym.journalType}
                    getOptionLabel={(option) =>
                      option.values || acronym.journalType
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Journal Type"
                        name="journalType"
                        style={{ width: "100%" }}
                        margin="normal"
                      />
                    )}
                  />
                  <TextField
                    label="Title Description"
                    name="journalTitle"
                    required
                    inputProps={{
                      maxLength: 50,
                    }}
                    className={classes.textField}

                    // onKeyPress={this._onAllCharPress}
                    margin="normal"
                    // style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.acronym.journalTitle}
                    onChange={this.handleChange}
                  />
                </div>
                <div>
                  <FormControlLabel
                    className={classes.checkBox}
                    margin="normal"
                    name="isPeriodical"
                    control={
                      <Checkbox
                        color="primary"
                        checked={acronym.isPeriodical}
                        value={acronym.isPeriodical}
                        onChange={this.handleChange}
                      />
                    }
                    label="Periodical"
                  />
                  {active ? (
                    <React.Fragment />
                  ) : (
                      <FormControlLabel
                        className={classes.checkBox}
                        margin="normal"
                        name="isActive"
                        control={
                          <Checkbox
                            color="primary"
                            checked={acronym.isActive}
                            value={acronym.isActive}
                            onChange={this.handleChange}
                          />
                        }
                        label="Active"
                      />
                    )}

                  {/* {isInternal ? (
                    <React.Fragment />
                  ) : ( */}
                  <FormControlLabel
                    className={classes.checkBox}
                    name="isInternal"
                    control={
                      <Checkbox
                        color="primary"
                        checked={acronym.isInternal}
                        value={acronym.isInternal}
                        onChange={this.handleChange}
                      />
                    }
                    label="Internal"
                  />
                  {/* )} */}
                </div>
                <Divider style={{ marginTop: "25px" }} />
                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/Acronym")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>


                    {!this.state.updatedValues ||
                      (this.state.acronym.journalType == "" || this.state.acronym.journalTitle == "" || !this.state.acronymSuffixValid || this.state.acronym.officeName == "") ? (
                        <Button
                          disabled
                          //autoFocus
                          //disableElevation
                          style={{ boxShadow: "none" }}
                          variant="contained"
                          onClick={this.handleSubmit}
                        // className={
                        //   !this.state.formValid
                        //     ? "disabled-button"
                        //     : "enabled-button "
                        // }
                        >
                          SUBMIT
                        </Button>
                      ) : (
                        <Button
                          //autoFocus
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        // className={
                        //   !this.state.formValid
                        //     ? "disabled-button"
                        //     : "enabled-button "
                        // }
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditAcronym));
