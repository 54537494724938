import React from "react";
// import * as XLSX from "xlsx";
import excel from "xlsx";
import axios from "axios";
import clone from "clone"
import { withStyles } from "@material-ui/core/styles";
import { TextField, Button, Paper, Divider } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import Validator from "../../../shared/validator";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import { addDispatchStyles } from "../../../shared/styles";
import moment from "moment";
import { resourceValidation } from "../../../shared/resource";
import { StateContext } from "../../../shared/globalState";
import LinearProgress from "@material-ui/core/LinearProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";
import Dropzone from "react-dropzone";
import {
  uploadCustomerOrderFiles,
  deleteOrder,
  submitCustomerOrder,
  //getCustomerExcelData,
  getCustomerStagingExcelData,
  onOrderCancel,
  updateUploadCustomerOrderFiles,
} from "./actions";
import {
  getUserList,
  getPlannerList,
  getPlannerBaseOnCustomerList,
} from "../../userAccess/userAccessAction";
import DescriptionIcon from "@material-ui/icons/Description";
import FileImg from "../../../assests/images/svg/filesupload-illustration.svg";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const maxlength50 = new Validator("maxlength50");
const emailValidator = new Validator("email");
const maxlength200AllAllow = new Validator("maxlength200AllAllow");
const notRequired100AllAllow = new Validator("notRequired100AllAllow");
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
const exportToCSV = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
function createData(
  salutation,
  first_name,
  last_name,
  designation,
  department,
  company_name,
  addr1,
  addr2,
  addr3,
  addr4,
  city,
  state,
  postal_code,
  country_name,
  copies,
  reference_no,
  job_sheet_no,
  membership_no,
  isValid,
  exceptionMessage
) {
  return {
    salutation,
    first_name,
    last_name,
    designation,
    department,
    company_name,
    addr1,
    addr2,
    addr3,
    addr4,
    city,
    state,
    postal_code,
    country_name,
    copies,
    reference_no,
    job_sheet_no,
    membership_no,
    isValid,
    exceptionMessage,
  };
}
function DetectionControl(file) {
  return (
    <div
      style={{
        width: "45%",
        display: "flex",
        alignItems: "center",
        marginTop: "-1px",
      }}
    >
      <span>
        {file.fileName && file.fileName.isValid ? (
          <CheckCircleIcon
            style={{ color: "rgba(59, 218, 182, 0.87)", fontSize: "19px" }}
          />
        ) : (
            <InfoIcon style={{ color: "red", fontSize: "19px" }} />
          )}
      </span>
      <span
        style={{
          marginTop: "-5px",
          marginLeft: "5px",
          fontSize: "11px",
        }}
      >
        {file.fileName && file.fileName.isValid
          ? "Uploaded successfully"
          : file.fileName && file.fileName.message}
      </span>
    </div>
  );
}
function checkBeforeFileUpolad(makeError, name) {

  document.getElementById(name) && document.getElementById(name).focus();
  if (name != "planner") makeError(name);
}
function Basic(props) {
  //console.log(props.checkValidData())
  let { validOrderTitle, validEmail, validPlanner } = props;
  console.log(validOrderTitle);
  return (
    <div
      style={{
        margin: "1em .8em 1em .5em",
        border: "1px solid #eaeaea",
        // width: "97.6%",
        borderRadius: "4px",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0)",
      }}
    >
      <section
        style={{ marginLeft: "1.5em", marginRight: "1.5em", marginTop: "1em" }}
      >
        <Dropzone
          onDrop={props.onDrop}
          accept=".xlsx"
          minSize={0}
          maxSize={512000}
        // disabled={props.checkValidData()}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({})}>
              <input
                id="inputId"
                {...getInputProps()}
                onClick={(e) => {
                  if (!validOrderTitle || !validEmail || !validPlanner)
                    e.preventDefault();
                  return !validOrderTitle
                    ? checkBeforeFileUpolad(props.makeError, "orderTitle")
                    : !validEmail
                      ? checkBeforeFileUpolad(props.makeError, "email")
                      : !validPlanner
                        ? checkBeforeFileUpolad(props.makeError, "planner")
                        : "";
                }}
              />

              <div
                style={{
                  width: "auto",
                  border: "1.5px dashed #eaeaea",
                  marginBottom: "1em",
                  borderRadius: "4px",
                  minHeight: "70px",
                  cursor: "pointer",
                }}
              >
                <label
                  className="upload excel"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "70px",
                    cursor: "pointer",
                    padding: ".3em",
                  }}
                >
                  <img
                    style={{ height: "60px", marginTop: "4px" }}
                    src={FileImg}
                    alt="File upload img"
                  />

                  <div
                    style={{
                      display: "inline-block",
                      width: "auto",
                      marginLeft: "12px",
                      width: "320px",
                    }}
                  >
                    <div className="file-upload-head">Add files</div>
                    <div className="file-upload-contant">
                      Drop files here or click{" "}
                      <span
                        style={{
                          color: "#306dca",
                          textDecoration: "underline",
                        }}
                      >
                        browse
                      </span>{" "}
                      through your machine{" "}
                    </div>
                    <div className="file-upload-footer">
                      Allowed only “.xlsx” format, 500KB file limit.
                    </div>
                  </div>
                </label>
              </div>
            </div>
          )}

          {/* {props.stateFiles.length > 0 && (
            <div
              style={{
                width: "auto",
                border: "1px solid #eaeaea",
                marginTop: "1em",
                borderRadius: "4px",
                // minHeight: "70px",
                //  cursor: "pointer",
                marginBottom: "1em",
                padding: ".5em 1em .5em 1em",
                boxShadow:
                  "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0)"
              }}
            >
              errors.......
            </div>
          )} */}
        </Dropzone>
      </section>
    </div>
  );
}
class AddCustomerOrder extends React.Component {
  static contextType = StateContext;
  state = {
    orderTitle: "",
    email: "",
    splInstruction: "",
    isActive: true,
    errors: {
      orderTitle: false,
      email: false,
      planner: false,
    },
    userId: "",
    finalSubmitPopUpOpen: false,
    planner: {},
    plannerList: [],
    fileList: [],
    loading: false,
    stateFiles: [],
    firstCall: true,
    customerOrderHeaderID: 0,
    submitLoading: false,
    showError: true,
  };
  componentDidMount() {
    const [{ store }, dispatch] = this.context;
    this.props.handleSettingHeader("Generic Customer");
    this.props.setSelectedList("genericCustomOpen", "orderPlacement");
    document.addEventListener("mousedown", this.handleClickOutside);
    let loggedInId = localStorage.getItem("loggedInId");
    let newErrrors = clone(this.state.errors)
    getPlannerBaseOnCustomerList(loggedInId).then((r) => {
      if (r && r.data && r.data.length > 0) {
        newErrrors.planner = true
        r.data.map((l) => {
          if (l.isSelected) {
            this.setState({
              userId: l.id,
              planner: { key: l.id, value: l.text, name: l.text },

            });
          }
        });
        this.setState({ errors: newErrrors })
      }
      this.getAllPlanners(r.data);
    });
  }
  //   shouldComponentUpdate(s, p) {
  //     const [{ store }, dispatch] = this.context;
  //     // to update
  //     if (
  //       store.userList &&
  //       store.userList.length > 0 &&
  //       this.state.plannerList.length !== store.userList.length
  //     ) {
  //       let modifiedList = [];
  //       store.userList.map(c => {
  //         if (c.isActive)
  //           modifiedList.push({ key: c.userId, value: c.fName, name: c.fName });
  //       });
  //       if (this.state.plannerList.length !== modifiedList.length)
  //         this.getAllPlanners(store.userList);
  //     }
  //   }
  getAllPlanners = (list) => {
    let plannerList = [];
    list &&
      list.length > 0 &&
      (plannerList = list
        // .filter(x => x.isActive === true)
        .sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ));
    let newPlannerList = [];
    plannerList.map((c) => {
      newPlannerList.push({
        key: c.id,
        value: c.text,
        name: c.text,
      });
    });
    this.setState({ plannerList: newPlannerList });
  };
  handleChange = (e, validate) => {
    let newRrrors = { ...this.state.errors };
    let result = validate(e.target.value);
    newRrrors[e.target.name] = !result;
    this.setState({ [e.target.name]: e.target.value, errors: newRrrors });
  };
  // _onKeyPress(event) {
  //   const re = /[0-9a-zA-Z]+/g;
  //   if (!re.test(event.key)) {
  //     event.preventDefault();
  //   }
  // }
  handleClose = () => {
    this.setState({
      orderTitle: "",
      email: "",
    });
  };
  checkValidInputs = () => {
    //alert("sagjd")
    let validFiles = false;
    let duplicateFound = false;
    let templeteFound = false;
    let errorFound = false;
    let notSuccess = true;
    if (!this.state.orderTitle || this.state.errors.orderTitle) return true;
    if (!this.state.email || this.state.errors.email) return true;
    if (!this.state.userId) return true;
    // if (!this.state.splInstruction) return true;

    // if (!this.state.firstCall) {
    // if (this.state.stateFiles.length === 0) return true;

    this.state.stateFiles.map((s) => {
      if (!s.isValid) {
        if (
          s.message &&
          s.message ===
          "Error Records Found, please download the exception file for more details"
        )
          errorFound = true;
        else if (s.message && s.message.includes("already exists"))
          duplicateFound = true;
        else if (s.message && s.message.includes("Max"))
          templeteFound = true;
      } else {
        notSuccess = false;
      }
    });
    //console.log(duplicateFound);
    if (templeteFound) validFiles = true;
    else if (errorFound) validFiles = true;
    else if (duplicateFound) {
      //  console.log("1....");
      if (notSuccess) {
        //    console.log("2....");
        validFiles = true;
      }
    }
    // else if ((!templeteFound && !errorFound) || duplicateFound)
    //   validFiles = false;
    else if (!templeteFound && !errorFound && !duplicateFound)
      validFiles = false;
    // }
    if (validFiles) return true;
    else return false;
  };
  checkValidData = () => {
    if (!this.state.orderTitle || this.state.errors.orderTitle) return true;
    else if (!this.state.email || this.state.errors.email) return true;
    else if (!this.state.userId) return true;
    else return false;
  };

  setSelectedFiles = (files) => {
    let loggedInId = localStorage.getItem("loggedInId");
    const formData = new FormData();
    files.map((f) => {
      // console.log()
      formData.append("Uploadfiles", f);
    });
    formData.append("orderTitle", this.state.orderTitle);
    formData.append("contactEmailID", this.state.email);
    formData.append("specialInstruction", this.state.splInstruction);
    formData.append("noOfOrderFiles", files.length);
    formData.append("plannerID", this.state.userId);
    formData.append("isEmailSent", false);
    formData.append("createdBy", loggedInId);
    uploadCustomerOrderFiles(formData).then((r) => {
      //console.log(r);
      this.setState({ loading: false, fileList: r, stateFiles: r });
    });
    //count = 0;
  };
  handleSubmitPopUp = (val) => {
    this.setState({ finalSubmitPopUpOpen: !this.state.finalSubmitPopUpOpen })
    if (val === "Yes")
      this.handleSubmit("e", true, "Order Placed Successfully")
  }
  handleSubmit = (event, status, msg) => {
    //console.log(this.state.fileList)
    this.setState({ submitLoading: true, open: true });
    let loggedInId = localStorage.getItem("loggedInId");
    let { fileList, stateFiles } = this.state;
    const [{ store }, dispatch] = this.context;
    const formData = new FormData();
    let count = 0;
    stateFiles.map((f) => {
      if (f.orderFileHeaderID) {
        formData.append("Uploadfiles", f);
        count++;
      }
    });
    formData.append("orderTitle", this.state.orderTitle);
    formData.append("contactEmailID", this.state.email);
    formData.append("specialInstruction", this.state.splInstruction);
    formData.append("noOfOrderFiles", count);
    formData.append("plannerID", this.state.userId);
    formData.append("isEmailSent", false);
    formData.append("modifiedBy", loggedInId);
    formData.append("customerOrderHeaderID", this.state.customerOrderHeaderID);
    formData.append("IsFinalSubmit", status);
    submitCustomerOrder(formData, this.props.history, "placed", msg)
      .then((r) => {
        this.setState({
          loading: false,
          fileList: r,
          submitLoading: false,
          open: false,
        });
      })
      .catch((e) => {
        this.setState({
          submitLoading: false,
          open: false,
        });
      });
    //reqBody.customerOrderHeaderID=fileList[0].customerOrderHeaderID

    // submitCustomerOrder(reqBody).then(r=>{

    // })
  };
  deleteSelectedFile = (i, id) => {
    let newStateFiles = [...this.state.stateFiles];
    let newFileList = [...this.state.fileList];
    id && deleteOrder(id, window.localStorage.loggedInId).then((r) => { });
    newStateFiles.splice(i, 1);
    newFileList.splice(i, 1);
    this.setState({ stateFiles: newStateFiles, fileList: newFileList });
  };
  getExcelFileData = (id, name) => {
    name = name.slice(0, name.length - 5);

    name = name + "_" + moment(new Date()).format("DD/MM/YYYY");
    //getCustomerExcelData(id).then(r => {
    getCustomerStagingExcelData(id).then((r) => {
      let newRows = [];
      r.map((d) => {
        newRows.push(
          createData(
            d.salutation,
            d.first_name,
            d.last_name,
            d.designation,
            d.department,
            d.company_name,
            d.addr1,
            d.addr2,
            d.addr3,
            d.addr4,
            d.city,
            d.state,
            d.postal_code,
            d.country_name,
            d.copies,
            d.reference_no,
            d.job_sheet_no,
            d.membership_no,
            d.isValid,
            d.exceptionMessage
          )
        );
      });
      //  console.log(r);
      exportToCSV(newRows, name);
    });
  };
  onDrop = (acceptedFiles) => {
    let loggedInId = localStorage.getItem("loggedInId");
    let uploaded = true;
    this.setState({
      loading: true,
      stateFiles: [...this.state.fileList, ...acceptedFiles],
    });

    const formData = new FormData();
    acceptedFiles.map((f) => {
      // Object.defineProperty(f, 'name', {
      //   value: f.name,
      //   writable: true
      // });
      // f.name = f.name.replace(".xlsx", (moment(new Date()).format("DD/MM/YYYY HH:mm:ss") + ".xlsx"))
      // console.log(f.name)
      formData.append("Uploadfiles", f);
    });
    formData.append("orderTitle", this.state.orderTitle);
    formData.append("contactEmailID", this.state.email);
    formData.append("specialInstruction", this.state.splInstruction);
    formData.append("noOfOrderFiles", acceptedFiles.length);
    formData.append("plannerID", this.state.userId);
    formData.append("isEmailSent", false);

    if (this.state.firstCall) {
      formData.append("CreatedBy", loggedInId);
      uploadCustomerOrderFiles(formData).then((r) => {
        //console.log(r);
        let isValidFile = false;
        r.map((file) => {
          if (file.customerOrderHeaderID) uploaded = false;
          if (file.message.includes("already exists")) isValidFile = true;
        });

        this.setState({
          loading: false,
          fileList: r,
          stateFiles: r,
          firstCall: uploaded,
          customerOrderHeaderID: r.length > 0 && r[0].customerOrderHeaderID,
          showError: isValidFile,
        }, () => {
          if (document.getElementById("inputId"))
            document.getElementById("inputId").value = "";
        });
      });
    } else {
      formData.append(
        "customerOrderHeaderID",
        this.state.customerOrderHeaderID
      );
      formData.append("ModifiedBy", loggedInId);
      this.state.customerOrderHeaderID &&
        updateUploadCustomerOrderFiles(formData)
          .then((r) => {
            let isValidFile = false;
            r.map((file) => {
              if (file.message.includes("already exists")) isValidFile = true;
            });
            this.setState({
              loading: false,
              fileList: r,
              stateFiles: r,
              showError: isValidFile,
            });
          }, () => {
            if (document.getElementById("inputId"))
              document.getElementById("inputId").value = "";
          })
          .catch((e) => {
            // this.setState({
            //   loading: false
            // });
          });
    }
  };
  // onSubmit=()=>{

  // }
  onCancel = () => {
    //console.log(this.state.stateFiles)
    let reqBody = {};
    let fileNames = [];
    this.state.stateFiles.length >= 0 &&
      this.state.stateFiles.map((r) => {
        fileNames.push(r.name);
      });
    reqBody.CustomerOrderHeaderID = 0;
    reqBody.FileName = fileNames.join(",");
    reqBody.IsFileDeleted = false;
    //console.log(reqBody.FileName)
    onOrderCancel(0, this.props.history, reqBody).then((r) => { });
  };
  handleAutocompletePlanner = (e, val) => {
    this.setState({ userId: val && val.key, planner: val && val });
  };
  onCloseClicked = () => {
    let newStateFiles = [];
    this.state.stateFiles.map((s) => {
      if (s.message.includes("already exists") === false) {
        newStateFiles.push(s);
      }
    });
    this.setState({
      showError: false,
      stateFiles: newStateFiles,
      fileList: newStateFiles,
    });
  };
  makeError = (name) => {
    let err = this.state.errors;
    err[name] = true;
    this.setState({ errors: err });
  };
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      console.log(event.target);
      // <Dialog aria-labelledby="simple-dialog-title" open={this.state.open}>
      //   <div style={{ padding: "1em" }}>
      //     <Button>Yes</Button> <Button>No</Button>
      //   </div>
      // </Dialog>;
    }
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }
  render() {
    let { classes } = this.props;
    const [{ store }, dispatch] = this.context;
    let {
      orderTitle,
      email,
      splInstruction,
      errors,
      plannerList,
      userId,
      fileList,
      stateFiles,
      planner,
    } = this.state;

    return (
      <React.Fragment>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.finalSubmitPopUpOpen}
          onClose={this.handleSubmitPopUp}
          maxWidth="xl"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Proceed to submit
            </DialogTitle>
          <DialogContent>
            <div style={{ padding: "1.5em 2px" }}>
              {/* <div style={{ fontSize: "13", fontWeight: "400" }}>
                You have submitted your order.
              </div> */}
              <div style={{ fontSize: "13", fontWeight: "400" }}>

                No amendment is allowed after submitting.
              </div>
              <div style={{ fontSize: "13", fontWeight: "400" }}>
                Please confirm to submit your Order.
          </div>
            </div>

          </DialogContent>
          <DialogActions style={{ padding: "10px 24px" }}>

            <Button
              onClick={this.handleSubmitPopUp}
              color="primary"
              variant="contained"
              style={{ boxShadow: "none" }}
            >
              Cancel
              </Button>
            <Button
              onClick={e => this.handleSubmitPopUp("Yes")}
              color="primary"
              variant="contained"
              style={{ boxShadow: "none", marginLeft: "1em" }}
            >
              Confirm
              </Button>
          </DialogActions>
        </Dialog>
        <Dialog aria-labelledby="simple-dialog-title" open={this.state.open}>
          <div style={{ padding: "1em" }}>
            <CircularProgress />
          </div>
        </Dialog>
        <Paper
        // ref={this.setWrapperRef}
        >
          <div className="pop-up-header">Create Order Placement</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container}>
                <div>
                  <TextField
                    required
                    label="Order Title"
                    name="orderTitle"
                    id="orderTitle"
                    inputProps={{
                      maxLength: 50,
                    }}
                    //onKeyPress={this._onKeyPress}
                    margin="normal"
                    className={classes.textFieldModified}
                    value={orderTitle}
                    onChange={(e) => this.handleChange(e, notRequired100AllAllow)}
                    error={errors.orderTitle}
                    helperText={
                      errors.orderTitle ? resourceValidation.alphaNumaric : ""
                    }
                  />
                  <TextField
                    inputProps={{
                      maxLength: 100,
                    }}
                    required
                    //onKeyPress={this._onKeyPress}
                    label="Email"
                    name="email"
                    id="email"
                    margin="normal"
                    className={classes.textFieldModified}
                    value={email}
                    onChange={(e) => this.handleChange(e, emailValidator)}
                    error={errors.email}
                    helperText={
                      errors.email ? resourceValidation.validEmailId : ""
                    }
                  />
                </div>
                <div>
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <div style={{ flex: 1 }}>
                      <Autocomplete
                        options={this.state.plannerList}
                        value={planner}
                        // style={{
                        //   width: "100%",
                        //   marginLeft: ".5em",
                        //   marginRight: ".5em"
                        // }}
                        id="planner"
                        onChange={this.handleAutocompletePlanner}
                        getOptionLabel={(option) => option.value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Planner"
                            style={{
                              width: "93%",
                              fontSize: "11px !important",
                              marginLeft: "10px",
                              marginRight: ".5em",
                              maxHeight: "400px",
                            }}
                            // name="plannerId"
                            margin="normal"
                            required
                          />
                        )}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <TextField
                        inputProps={{
                          maxLength: 200,
                        }}
                        multiline={true}
                        rows={1}
                        rowsMax={4}
                        //required
                        //onKeyPress={this._onKeyPress}
                        label="Special Instruction"
                        name="splInstruction"
                        margin="normal"
                        className={classes.textFieldModified}
                        style={{ width: "93%", marginLeft: "3px" }}
                        value={splInstruction}
                        onChange={(e) =>
                          this.handleChange(e, notRequired100AllAllow)
                        }
                        error={errors.splInstruction}
                        helperText={
                          errors.splInstruction
                            ? resourceValidation.alphaNumaric
                            : ""
                        }
                      />
                    </div>
                    <div style={{ flex: 1 }}></div>
                  </div>
                  {/* <TextField
                  required
                  id="standard-select-currency"
                  select
                  label="Select Planner"
                  //className={classes.textField}
                  name="userId"
                  style={{
                    width: "31%",
                    fontSize: "11px !important",
                    marginLeft: ".5em",
                    marginRight: ".5em",
                    maxHeight: "400px",
                    overflow: "auto"
                  }}
                  inputProps={{
                    maxLength: 100
                  }}
                  margin="normal"
                  value={userId ? userId : ""}
                  error={errors.userId}
                  helperText={errors.userId ? "User can't be empty" : ""}
                  onChange={e => this.handleChange(e, notRequired100AllAllow)}
                >
                  {plannerList.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  inputProps={{
                    maxLength: 200
                  }}
                  required
                  //onKeyPress={this._onKeyPress}
                  label="Special Instruction"
                  name="splInstruction"
                  margin="normal"
                  className={classes.textFieldModified}
                  value={splInstruction}
                  onChange={e => this.handleChange(e, notRequired100AllAllow)}
                  error={errors.splInstruction}
                  helperText={
                    errors.splInstruction ? resourceValidation.alphaNumaric : ""
                  }
                /> */}
                </div>
                <div>
                  <Basic
                    loading={this.state.loading}
                    fileList={this.state.fileList}
                    stateFiles={this.state.stateFiles}
                    onDrop={this.onDrop}
                    checkValidData={this.checkValidData}
                    stateData={this.state}
                    makeError={this.makeError}
                    validOrderTitle={
                      this.state.orderTitle && !this.state.errors.orderTitle
                    }
                    validEmail={this.state.email && !this.state.errors.email}
                    validPlanner={
                      this.state.userId && !this.state.errors.userId
                    }
                  />
                </div>
                {/* {fileList.length > 0 &&
                  stateFiles.length > 0 &&
                  this.state.showError && (
                    <div
                      style={{
                        textAlign: "right",
                        right: "10px",
                        position: "absolute",
                        top: "10px",
                      }}
                    >
                      <span>
                        <CloseIcon
                          style={{
                            color: "gray",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                          onClick={this.onCloseClicked}
                        />
                      </span>
                    </div>
                  )} */}
                {fileList.length > 0 &&
                  stateFiles.length > 0 &&
                  this.state.showError && (
                    <div
                      style={{
                        position: "relative",
                        width: "auto",
                        border: "1px solid #eaeaea",
                        margin: "1em .8em 1em .5em",
                        borderRadius: "4px",
                        // minHeight: "70px",
                        //  cursor: "pointer",
                        //marginBottom: "1em",

                        fontSize: "12px",
                        padding: ".5em 1em .5em 1em",
                        boxShadow:
                          "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0)",
                      }}
                    >
                      {fileList.length > 0 &&
                        fileList.map((file, i) => {
                          if (
                            file &&
                            // !file.isValid &&
                            file.message.includes("already exists")
                          )
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                key={i}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>
                                    <InfoIcon
                                      style={{
                                        color: "#f4b43c",
                                        fontSize: "19px",
                                      }}
                                    />
                                  </span>
                                  <span
                                    style={{
                                      marginLeft: ".5em",
                                      marginTop: "-2px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {file.fileName}
                                  </span>

                                  <span
                                    style={{
                                      marginLeft: "1em",
                                      marginTop: "-2px",
                                      color: "rgb(48, 109, 202)",
                                    }}
                                  >
                                    "{file.message}"
                                  </span>
                                </div>
                                {/* <div>
                                {" "}
                                {i === 0 && (
                                  
                                )}
                              </div> */}
                              </div>
                            );
                        })}
                      <div
                        style={{
                          textAlign: "right",
                          right: "10px",
                          position: "absolute",
                          top: "10px",
                        }}
                      >
                        <span>
                          <CloseIcon
                            style={{
                              color: "gray",
                              fontSize: "14px",
                              cursor: "pointer",
                            }}
                            onClick={this.onCloseClicked}
                          />
                        </span>
                      </div>
                    </div>
                  )}
                <div>
                  {this.state.stateFiles.map((file, i) => {
                    return (
                      <div key={i}>
                        {i === 0 && (
                          <div
                            style={{
                              display: "flex",
                              margin: "1em",
                              // marginTop: "2em",
                              paddingBottom: ".5em",
                              borderBottom: "1px solid #eaeaea",
                              fontSize: "14px",
                            }}
                          >
                            <div style={{ width: "30%", fontWeight: "500" }}>
                              File Info
                            </div>
                            <div style={{ width: "10%", fontWeight: "500" }}>
                              Size
                            </div>
                            <div style={{ width: "45%", fontWeight: "500" }}>
                              Detection
                            </div>
                            <div
                              style={{
                                width: "15%",
                                fontWeight: "500",
                                textAlign: "right",
                              }}
                            >
                              Actions
                            </div>
                          </div>
                        )}
                        {file.customerOrderHeaderID ? (
                          <div
                            style={{
                              display: "flex",
                              margin: "1em",
                              paddingBottom: ".5em",
                              borderBottom: "1px solid #eaeaea",
                              fontSize: "12px",
                            }}
                          >
                            <div style={{ width: "30%", fontWeight: "500" }}>
                              <div style={{ display: "flex" }}>
                                <DescriptionIcon
                                  style={{
                                    color: "#306dca",
                                    marginTop: "-4px",
                                    marginRight: "5px",
                                  }}
                                />{" "}
                                <span> {file.name}</span>
                              </div>
                            </div>
                            <div style={{ width: "10%" }}>
                              {/* {Math.ceil(file.size / 1024) + " kb"} */}
                              {file.size}
                            </div>

                            {!this.state.loading ? (
                              <DetectionControl
                                fileName={this.state.fileList[i]}
                              />
                            ) : (
                                <div style={{ width: "45%", marginTop: ".6em" }}>
                                  <LinearProgress />
                                </div>
                              )}

                            <div style={{ width: "15%", textAlign: "right" }}>
                              {!file.isValid && !file.filePath && (
                                <IconButton
                                  size="small"
                                  style={{ marginTop: "-5px" }}
                                  onClick={(e) =>
                                    this.getExcelFileData(
                                      file.orderFileHeaderID,
                                      file.name
                                    )
                                  }
                                >
                                  <GetAppIcon style={{ fontSize: "16px" }} />
                                </IconButton>
                              )}
                              <IconButton
                                size="small"
                                style={{ marginTop: "-7px" }}
                                onClick={(e) => {
                                  this.deleteSelectedFile(
                                    i,
                                    fileList[i].orderFileHeaderID
                                  );
                                }}
                              >
                                <DeleteIcon style={{ fontSize: "16px" }} />
                              </IconButton>{" "}
                            </div>
                          </div>
                        ) : (
                            ""
                          )}
                      </div>
                    );
                  })}
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div style={{ paddingLeft: "0px", marginLeft: "4px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.onCancel()}
                    >
                      CANCEL
                    </Button>
                  </div>

                  <div style={{ marginLeft: "1em" }}>
                    {this.checkValidInputs() ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={(e) =>
                          this.handleSubmit(e, false, "Customer Order Saved")
                        }
                      >
                        Save Draft
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={(e) =>
                            this.handleSubmit(e, false, "Customer Order Saved")
                          }
                        >
                          Save Draft
                        </Button>
                      )}
                  </div>

                  <div style={{ marginLeft: "1em" }}>
                    {this.checkValidInputs() ||
                      !this.state.stateFiles.length > 0 ? (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={(e) =>
                            // this.handleSubmit(
                            //   e,
                            //   true,
                            //   "Order Placed Successfully"
                            // )
                            this.handleSubmitPopUp(e, true, "Order Placed Successfully")

                          }
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={(e) =>
                            // this.handleSubmit(
                            //   e,
                            //   true,
                            //   "Order Placed Successfully"
                            // )
                            this.handleSubmitPopUp(e, true, "Order Placed Successfully")

                          }
                        >
                          Submit
                        </Button>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addDispatchStyles)(AddCustomerOrder));
