import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withRouter } from "react-router-dom";
import {
  getRole,
  updateRoleID,
  roleEditCheck,
} from "../../userAccess/userAccessAction";
import Validator from "../../../shared/validator";
// Include new StateContext
import { addStyles } from "../../../shared/styles";
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";
const validateNotRequired100 = new Validator("maxlength100AllAllow");
const validateNotRequired500 = new Validator("maxlength100AllAllow");
const onlyNumbers = new Validator("onlyNumbers");

class EditRole extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      valChange: 0,
      role: {
        roleName: "",
        description: "",
        orderNo: "",
        isReport: false,
        isActive: false,
        roleID: "",
        modifiedBy: 1,
      },
      errors: {},
      active: false,
      roleNameValid: true,
      descriptionValid: true,
      orderNoValid: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.role["roleName"] !== "") {
      if (this.state.valChange !== prevState.valChange)
        roleEditCheck(
          this.state.role["roleName"],
          this.state.role["roleID"]
        ).then((r) => {
          if (r === true) {
            let obj = { ...this.state.errors, roleName: "Role already exists" };
            this.setState({ errors: obj, roleNameValid: false });
          }
        });
    }
  }
  componentDidMount() {
    const dispatch = this.context[1];
    let roleId = this.props.match.params.roleId;

    getRole(roleId).then((r) => {
      dispatch({
        type: "getRole",
        newStore: r,
      });
      this.setState({ role: r });
      if (r.isActive) this.setState({ active: r.isActive });
    });
  }
  handleChange2 = (event) => {
    let count = this.state.valChange;
    const { role } = this.state;
    count = count + 1;
    role[event.target.name] = event.target.value;

    this.setState({ role, valChange: count });

    this.validateField(event.target.name, event.target.value);
  };
  handleChange = (event) => {
    const { role } = this.state;
    if (event.target.name === "isReport" || event.target.name === "isActive") {
      role[event.target.name] = event.target.checked;
    } else {
      role[event.target.name] = event.target.value;
    }
    this.setState({ role });

    this.validateField(event.target.name, event.target.value);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let roleNameValid = this.state.roleNameValid;
    let descriptionValid = this.state.descriptionValid;
    let orderNoValid = this.state.orderNoValid;
    switch (fieldName) {
      case "roleName":
        if (value !== "") {
          roleNameValid = validateNotRequired100(value);
          fieldValidationErrors.roleName = roleNameValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          roleNameValid = false;
          fieldValidationErrors.roleName = "";
        }
        break;
      case "description":
        if (value !== "") {
          descriptionValid = validateNotRequired500(value);
          fieldValidationErrors.description = descriptionValid
            ? ""
            : resourceValidation.ValidDescription;
        } else {
          descriptionValid = false;
          fieldValidationErrors.description = "";
        }
        break;
      case "orderNo":
        if (value !== "" && value !== "null") {
          orderNoValid = onlyNumbers(value);
          fieldValidationErrors.orderNo = orderNoValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          orderNoValid = false;
          fieldValidationErrors.orderNo = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        roleNameValid: roleNameValid,
        descriptionValid: descriptionValid,
        orderNoValid: orderNoValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.roleNameValid && this.state.descriptionValid,
      //this.state.orderNoValid,
    });
    return this.state.formValid;
  }
  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    let roleId = this.props.match.params.roleId;
    const { role } = this.state;
    role.modifiedBy = localStorage.loggedInId;
    updateRoleID(roleId, role).then((r) => {
      this.props.history.push("/Role");
      //toastr.success("Role Updated Successfully");
    });
  }

  render() {
    let { classes } = this.props;
    let { role, active } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Role</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <TextField
                    required
                    label="Role Name"
                    name="roleName"
                    inputProps={{
                      maxLength: 50,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={role.roleName}
                    onChange={this.handleChange2}
                    error={this.state.errors.roleName}
                    helperText={
                      this.state.errors.roleName
                        ? this.state.errors.roleName
                        : ""
                    }
                  />
                  <TextField
                    multiline={false}
                    rows={1}
                    rowsMax={4}
                    inputProps={{
                      maxLength: 100,
                    }}
                    label="Description"
                    name="description"
                    margin="normal"
                    className={classes.textField}
                    value={role.description}
                    onChange={this.handleChange}
                    error={this.state.errors.description}
                    helperText={
                      this.state.errors.description
                        ? this.state.errors.description
                        : ""
                    }
                  />
                  {/* <TextField
                    label="Order Number"
                    name="orderNo"
                    inputProps={{
                      maxLength: 3,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={role.orderNo}
                    onChange={this.handleChange}
                    error={this.state.errors.orderNo}
                    helperText={
                      this.state.errors.orderNo ? this.state.errors.orderNo : ""
                    }
                    onKeyPress={this._onNumberKeyPress}
                  /> */}
                </div>
                <div>
                  {/* <FormControlLabel
                    className={classes.checkBox}
                    margin="normal"
                    name="isReport"
                    control={
                      <Checkbox
                        color="primary"
                        checked={role.isReport}
                        onChange={this.handleChange}
                        value={role.isReport}
                      />
                    }
                    label="Report"
                  /> */}
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      className={classes.checkBox}
                      margin="normal"
                      name="isActive"
                      control={
                        <Checkbox
                          color="primary"
                          checked={role.isActive}
                          onChange={this.handleChange}
                          value={role.isActive}
                        />
                      }
                      label="Active"
                    />
                  )}
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/role")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        onClick={this.handleSubmit}
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditRole));
