import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";

import Divider from "@material-ui/core/Divider";
import { StateContext } from "../../../shared/globalState";
import { addStyles } from "../../../shared/styles";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { getDistributorCode, updateDistributor } from "../masterActions";

const checkdistributorname = new Validator("checkdistributorname");

// const styles = theme => ({
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1),
//     width: "31%",
//     fontSize: "11px !important"
//   }
// });

class EditDistributor extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      Distributor: {
        distributorCode: "",
        distributorName: "",
        modifiedBy: 1,
        isActive: true,
      },
      errors: {},
      active: false,
      // updatedValues : false,
      distributorNameValid: false,
      formValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let distributorID = this.props.match.params.distributorId;
    //console.log(this.props)
    getDistributorCode(distributorID).then((r) => {
      dispatch({
        type: "getDistributorCode",
        newStore: r,
      });
      this.setState({ Distributor: r });
      if (r.isActive) this.setState({ active: r.isActive });
      //  this.validateField("distributorName", r.distributorName);
    });
  }

  handleChange = (e) => {
    // this.setState({updatedValues: true});
    const { Distributor } = this.state;
    if (e.target.name === "isActive") {
      Distributor[e.target.name] = e.target.checked;
      this.setState({ formValid: true });
    } else {
      Distributor[e.target.name] = e.target.value;
    }
    this.validateField(e.target.name, e.target.value);

    this.setState({ Distributor });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    // let letterShopCodeValid = this.state.letterShopCodeValid;
    // let distributorCodeValid = this.state.distributorCodeValid;
    let distributorNameValid = this.state.distributorNameValid;

    switch (fieldName) {
      case "distributorName":
        if (value) {
          distributorNameValid = checkdistributorname(value);
          fieldValidationErrors.distributorName = distributorNameValid
            ? ""
            : "Please enter distributor name";
        } else {
          distributorNameValid = false;
        }
        break;

      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        distributorNameValid: distributorNameValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    //this.state.updatedValues &&
    if (!this.state.formValid) {
      this.setState({
        formValid: this.state.distributorNameValid,
      });
    }
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      // letterShopCode: "",
      // distributorCode: "",
      distributorName: "",
      // letterShop: {
      //   letterShopCode: "",
      //   letterShopName: "",
      //   chargeType: "",
      //   unitRate: "",
      // },
      errors: {},
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let distributorID = this.props.match.params.distributorId;
    if (this.validateForm()) {
      const { Distributor } = this.state;
      let isActive = Distributor.isActive;
      let distributorName = Distributor.distributorName;
      // let distributorCode = Distributor.distributorCode;

      Distributor.modifiedBy = localStorage.loggedInId;
      let modifiedBy = Distributor.modifiedBy;
      let reqBody = { distributorName, modifiedBy, isActive };

      updateDistributor(distributorID, reqBody, this.props.history);
    }
  };
  render() {
    let { classes } = this.props;
    let { Distributor, active } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Distributor</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    disabled
                    variant="filled"
                    label="Distributor Code"
                    name="distributorCode"
                    placeholder="Distributor Code"
                    className={classes.textField}
                    margin="normal"
                    value={Distributor.distributorCode}
                    //error={this.state.errors.distributorCode}
                    // helperText={
                    //   this.state.errors.distributorCode
                    //     ? resourceValidation.max2char
                    //     : ""
                    // }
                    //onChange={this.handleChange}
                  />
                  <TextField
                    required
                    label="Distributor Name"
                    id="standard-required"
                    placeholder="Distributor Name"
                    name="distributorName"
                    autoComplete="off"
                    className={classes.textField}
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    value={Distributor.distributorName}
                    error={this.state.errors.distributorName}
                    helperText={
                      this.state.errors.distributorName
                        ? this.state.errors.distributorName
                        : ""
                    }
                    onChange={this.handleChange}
                  />
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      name="isActive"
                      style={{ marginTop: "25px", marginLeft: "7px" }}
                      control={
                        <Checkbox
                          color="primary"
                          className={classes.Checkbox}
                          checked={Distributor.isActive}
                          onChange={this.handleChange}
                          value={Distributor.isActive}
                        />
                      }
                      label="Active"
                    />
                  )}
                </div>
                <Divider style={{ marginTop: "20px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/distributor")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditDistributor));
