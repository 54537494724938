import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import clone from "clone";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Switch from '@material-ui/core/Switch';
import { StateContext } from "../../../shared/globalState";
import PropTypes from "prop-types";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { withRouter, Link } from "react-router-dom";
import ReportViewer from "../../app/ReportViewer";
import StatusText from "../../../shared/statusText";
import moment from "moment";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import TimeFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import { Checkbox } from "@material-ui/core";
import EdsErrorModal from "../../generic/docketPosting/edsErrorModal";

import {
  getDocInfo,
  generateLocalEDocket,
  generateInternationalEDocket,
  getDocketBaggingHeaderInfo,
  getEdsErrorDetails
} from "./action";
import {
  EditIcon,
  ViewIcon,
  CustomSmallAddIcon,
} from "../../../shared/customIcons";
import "date-fns";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
// import {
//   getMailSizeList,
//   getRunMailList,
//   deleteDocket,
//   GetAllDocketsByJGID,
//   createRunMailList,
//   updateRunMailList,
//   deleteRunMailList,
//   getFileNamesDropDown,
//   getFileNamesDropDownWithID,
// } from "../genericDocketAction";
import { stableSort, getSorting } from "../../../shared/tableCommonFunctions";
// import { getJobGroupbyJobGroupId } from "../workOrder/action";
import {
  generateEDocket,
  generateEDocketInternational,
} from "../../generic/docketPosting/actionsDocket";
// import { getDocketList } from "../docketPosting/actions";
let docketStatusSelection = {
  Open: true,
  "Manual Docket Generated": false,
  "EDS Error": true,
};
let docsel = [];

let manDoc = [];
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <div />
      ) : null}
    </MuiDialogTitle>
  );
});
const headCells1 = [
  {
    id: 1,
    code: "pgsDocketNO",
    numeric: false,
    disablePadding: true,
    label: "PGS Docket No",
    width: "auto",
  },
  {
    id: 2,
    code: "eDocketNO",
    numeric: false,
    disablePadding: false,
    label: "EDS Docket No",
    width: "auto",
  },
  {
    id: 3,
    code: "accountNo",
    numeric: false,
    disablePadding: false,
    label: "Account No",
    width: "auto",
  },

  {
    id: 4,
    code: "estimatedLodgementDate",
    numeric: false,
    disablePadding: false,
    label: "Estd Lodgement Date",
    width: "auto",
  },
  {
    id: 5,
    code: "submittedDate",
    numeric: false,
    disablePadding: false,
    label: "Submitted Date",
    width: "auto",
  },
  {
    id: 6,
    code: "service",
    numeric: false,
    disablePadding: false,
    label: "Service",
    width: "auto",
  },
  {

    id: 39,
    code: "mailingScheme",
    numeric: false,
    disablePadding: false,
    label: "Mailing Scheme",
    width: "auto",
  },
  {
    id: 6.5,
    code: "distributorCode",
    numeric: false,
    disablePadding: false,
    label: "Distributor",
    width: "auto",
  },
  {
    id: 6.9,
    code: "totalNoOfArticle",
    numeric: false,
    disablePadding: false,
    label: "No Of Articles",
    width: "auto",
  },
  {
    id: 7,
    code: "isLocal",
    numeric: false,
    disablePadding: false,
    label: "Type",
    width: "auto",
  },
  {
    id: 8,
    code: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    width: "auto",
  },
  {
    id: 9,
    code: "edsExceptionMessage",
    numeric: false,
    disablePadding: false,
    label: "Exception",
    width: "auto",
  },
  {
    id: 10,
    code: "modifiedDate",
    numeric: false,
    disablePadding: false,
    label: "Modified Date",
    width: "auto",
  },
  {
    id: 11,
    code: "modifiedBy",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
    width: "auto",
  },
  {
    id: 12,
    code: "createdDate",
    numeric: false,
    disablePadding: false,
    label: "Created Date",
    width: "auto",
  },
  {
    id: 13,
    code: "createdBy",
    numeric: false,
    disablePadding: false,
    label: "Created By",
    width: "auto",
  },
  {
    id: 14,
    code: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    width: "auto",
  },
];

function createDocket(
  docketHeaderID,
  customerName,
  workOrderNumber,
  projectTitle,
  jobGroupNumber,
  planner,
  pgsDocketNO,
  eDocketNO,
  pgsDocketNo,
  edsDocketNo,
  accountNo,
  estimatedLodgementDate,
  submittedDate,
  service,
  distributorCode,
  totalNoOfArticle,
  type,
  isManual,
  status,
  edsExceptionMessage,
  statusId,
  modifiedByName,
  modifiedDate,
  createdByName,
  createdDate
) {
  return {
    docketHeaderID,
    customerName,
    workOrderNumber,
    projectTitle,
    jobGroupNumber,
    planner,
    pgsDocketNO,
    eDocketNO,
    pgsDocketNo,
    edsDocketNo,
    accountNo,
    estimatedLodgementDate,
    submittedDate,
    service,
    distributorCode,
    totalNoOfArticle,
    type,
    isManual,
    status,
    edsExceptionMessage,
    statusId,
    modifiedByName,
    modifiedDate,
    createdByName,
    createdDate,
  };
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "31%",
    fontSize: "11px !important",
  },
  autocompleteText: {
    marginRight: theme.spacing(2),
    width: "100%",
    fontSize: "11px !important",
  },
  autocomplete: {
    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
});

const getColor = (status) => {
  switch (status && status.trim()) {
    case "In Progress":
      return "#03A6FD";
    case "EDS Error":
      return "gray";
    case "Open":
      return "#ECB507";
    case "E Docket Generated":
      return "#16831F";
    case "Manual Docket Generated":
      return "#16831F";
    case "Lodged":
      return "#16831F";
    case "Cancelled":
      return "#AD2219";
    default:
      return "#03A6FD";
  }
};

function EnhancedTableHead1(props) {
  const { order, orderBy, onRequestSort, onSelectAllClicked, allSelected, docketInfoList, isLocalType } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  let showCkBx = false
  docketInfoList && docketInfoList.length > 0 && docketInfoList.map(m => {
    // console.log(isLocalType, m.isLocal, m.statusCode, m.status)
    if (isLocalType == m.isLocal)
      if (m.status.trim() !== "Cancelled" && m.statusCode !== "DPEDG" && m.statusCode !== "DPMDG")
        showCkBx = true
  })
  // console.log(allSelected, "allSelected")
  return (
    <TableHead>

      <TableRow style={{ background: "#eaeaea" }}>
        <TableCell padding="checkbox" className="table-cell">
          {showCkBx ? <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={allSelected ? true : false}
                onChange={e => onSelectAllClicked(e)}
              />
            }
          /> : <FormControlLabel
              style={{ visibility: "hidden" }}
              control={
                <Checkbox
                  color="primary"
                  checked={allSelected}
                // onChange={e => onSelectAllClicked(e)}

                />
              }
            />}


        </TableCell>
        {headCells1.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCell.length - 1 ? "headcells" : "headcell"}
            style={{ borderLeft: "1px sold #000", width: `${headCell.width}` }}
          >
            {/* <span style={{ color: "#306dca" }}>{headCell.label}</span> */}
            <TableSortLabel
              active={orderBy === headCell.code}
              direction={order}
              onClick={createSortHandler(headCell.code)}
            >
              <span style={{ color: "#306dca" }}>{headCell.label}</span>

            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar2 = (props) => {
  let countNoOfSelected = 0
  props && props.docketInfoList && props.docketInfoList.length > 0 && props.docketInfoList.map(m => {
    if (m.checkBoxVal)
      countNoOfSelected = countNoOfSelected + 1
  })
  return (
    <div>
      <div
        style={{
          display: "flex",
          border: "1px solid #eaeaea",
          borderBottom: "none",
          borderRadius: "4px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontWeight: "bolder",
            padding: "4px",
            paddingLeft: "15px",
            paddingRight: "16px",
          }}
        >
          Docket Info
        </div>

        <Button
          disabled={props.selected.length === 0 || props.disableEDOCBtn}
          variant="contained"
          color="primary"
          style={{
            boxShadow: "none",
            margin: ".5em",
            fontSize: "11px",
            boxShadow: "none",
          }}
          onClick={(e) => props.openPopUpModalForLdgDate(e, "generateEDocket")}
        >
          {" "}
          Generate E-Docket
        </Button>

        <Button
          disabled={props.selected.length === 0 || props.disableEDOCBtn}
          variant="contained"
          color="primary"
          style={{
            boxShadow: "none",

            margin: ".5em",
            fontSize: "11px",
          }}
          onClick={(e) =>
            props.openPopUpModalForLdgDate(e, "generateManualDocketReport")
          }
        >
          {" "}
          Generate Manual Docket
        </Button>
        <Link
          to={`/backLabelDocketBagging/${window.location.pathname.split("/")[2]
            }`}
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              // padding: "2px",
              margin: ".5em",
              fontSize: "11px",
              textDecoration: "none",
            }}
          // onClick={(e) =>
          //   props.history.push(
          //     "/MainDocketBagging/" + window.location.pathname.split("/")[2]
          //   )
          // }
          >
            {" "}
            GoTo Docket Bagging
          </Button>
        </Link>
        {countNoOfSelected > 0 && <div>
          <div className="blueBoxLabel">
            <div>Total Selected Docket : </div>
            <div className="blueBoxText">
              {countNoOfSelected}
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

EnhancedTableToolbar2.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

class EditMainLabelDocket extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      openLdgDatePopUp: false,
      checkIsManual: "",
      isLocalType: true,
      allSelected: false,
      docketbaggingHeaderInfo: {},
      selectedRows: [],
      docketInfoList: [],
      distributoListForFiltering: [],
      selectedDistributor: {},
      originalDocketInfoList: [],
      disableManual: [],
      disableEDOCBtn: [],
      selectedEditIndex: "",
      order: "",
      orderBy: "",
      confirmDelete: false,
      isManualDocketRecord: false,
      isdeleteRunMailList: false,
      workOrder: {
        jobGroupID: "",
        customerName: "",
        workOrderNumber: "",
        projectTitle: "",
        jobGroupNumber: "",
        customerID: "",
        workOrderID: "",
      },
      deleteRunMailListValues: {
        index: 0,
        id: 0,
      },
      deleteDocketListValues: {
        index: 0,
        id: 0,
      },
      createDocket: {},
      formValid: false,
      submitSuccess: false,
      localDocket: false,
      //localDocket2: false,
      manualDocket: false,
      runMailListValid: true,
      validSelection: false,
      //validDocketSelection: false,
      mailSizeRequired: false,
      isMan: false,
      isGen: false,
      loading: false,
      loading2: false,
      errors: {},
      selected: [],
      selected2: [],
      docselected: false,
      selectedDocket: [],
      localDocketList: [],
      internationalDocketList: [],
      selectedRML: [],
      fileNameList: [],
      zoneList: [],
      countryList: [],
      provinceList: [],
      mailSizeList: [],
      runMailListDetailList: [],
      editableRunMailListDetailList: [],
      resetList: [],
      docketDetailList: [],
      editableDocketDetailList: [],
      jobGroupList: [],
      isZoneOrCountrySelected: "",
      zoneValue: null,
      countryValue: null,
      showText: false,
      lodgementDate: new Date(),
      lodgementTime: new Date(),
      edsErrorData: "",
      openEdsErrorModal: false
    };
  }

  componentDidMount() {
    let dId = "";
    if (process.browser) dId = window.location.pathname.split("/")[2];
    const dispatch = this.context[1];
    this.props.handleDrawerClose();
    this.props.handleSettingHeader("Back Label Docket Bagging");
    this.props.setSelectedList(
      "publisherBackLabelOpen",
      "backLabelDocketPosting"
    );
    let jobGroupID = this.props.match.params.jobGroupId;
    this.setState({ disableEdit: true, loading: true, loading2: true });
    getDocInfo(dId).then((r) => {
      let ddList = []

      this.setState({ docketInfoList: r.data, loading: false, originalDocketInfoList: r.data });
      r.data && r.data.length > 0 && r.data.map(m => {
        let foundDDVal = false
        ddList.map(n => {
          if (n.id === m.distributorID)
            foundDDVal = true
        })
        if (!foundDDVal) {
          ddList.push({ id: m.distributorID, text: m.distributorName })
        }
      })
      this.setState({ distributoListForFiltering: ddList })
    });
    getDocketBaggingHeaderInfo(dId).then((r) => {
      this.setState({
        docketbaggingHeaderInfo: r.data,
      });
    });
    // getRunMailList(jobGroupID).then((r) => {
    //   dispatch({
    //     type: "getRunMailList",
    //     newStore: r.data,
    //   });
    //   this.getAllRunMailDetails(r.data);
    // });
    // GetAllDocketsByJGID(jobGroupID).then((r) => {
    //   dispatch({
    //     type: "GetAllDocketsByJGID",
    //     newStore: r.data,
    //   });
    //   this.getAllDocketDetails(r.data);
    // });
    // getFileNamesDropDownWithID(jobGroupID).then((r) => {
    //   dispatch({
    //     type: "getFileNamesDropDown",
    //     newStore: r.data,
    //   });
    //   if (r.data) this.getAllFileNames(r.data);
    // });
    // getZoneDropDownList().then((r) => {
    //   dispatch({
    //     type: "getZoneDropDownList",
    //     newStore: r.data,
    //   });
    //   this.getAllZones(r.data);
    // });
    // getCountryDropdownList().then((r) => {
    //   dispatch({
    //     type: "getCountryDropdownList",
    //     newStore: r.data,
    //   });
    //   this.getAllCountries(r.data);
    // });
    // getProvinceDropDownList().then((r) => {
    //   dispatch({
    //     type: "getProvinceDropDownList",
    //     newStore: r.data,
    //   });
    //   this.getAllProvince(r.data);
    // });
    // getMailSizeList().then((r) => {
    //   dispatch({
    //     type: "getMailSizeList",
    //     newStore: r.data,
    //   });
    //   this.getAllMailSize(r.data);
    // });
    // getJobGroupbyJobGroupId(jobGroupID).then((r) => {
    //   dispatch({
    //     type: "getJobGroupbyJobGroupId",
    //     newStore: r,
    //   });
    //   this.getWorkOrderInfo(r);
    // });
    // dispatch({
    //   type: "getSelectedDocketId",
    //   newStore: "0",
    // });
  }

  getWorkOrderInfo = (info) => {
    const { workOrder } = this.state;
    workOrder.customerName = info.customerName;
    workOrder.jobGroupID = info.jobGroupID;
    workOrder.workOrderNumber = info.workOrderNumber;
    workOrder.jobGroupNumber = info.jobGroupNumber;
    workOrder.projectTitle = info.projectTitle;
    workOrder.customerID = info.customerID;
    workOrder.workOrderID = info.workOrderID;
    this.setState({ workOrder });
  };

  getAllRunMailDetails = (list) => {
    // let runMailListDetailList = [];
    // list &&
    //   list.length > 0 &&
    //   list.map((d) => {
    //     return d.isActive
    //       ? runMailListDetailList.push(
    //           createData(
    //             false,
    //             true,
    //             d.runMailListID,
    //             d.filenameList && d.filenameList[0],
    //             d.zoneList && d.zoneList[0],
    //             d.countryList && d.countryList[0],
    //             d.provinceList.length > 0 ? d.provinceList[0] : null,
    //             d.copies,
    //             d.quantity,
    //             d.copies * d.quantity,
    //             d.remarks,
    //             d.rounting,
    //             d.pgsDocketNo,
    //             d.isCOP,
    //             d.isBM1500Countries,
    //             d.mailsizeList.length > 0 ? d.mailsizeList[0] : null,
    //             d.noOfBags,
    //             d.weightPerBag && this.addZeroes(d.weightPerBag.toString(), 3),
    //             d.weightPerItem,
    //             d.noOfArticles,
    //             d.totalChargeableWeight &&
    //               this.addZeroes(d.totalChargeableWeight.toString(), 3),
    //             d.postageRate && this.addZeroes(d.postageRate.toString(), 3),
    //             d.totalCharges && this.addZeroes(d.totalCharges.toString(), 3),
    //             d.isLocal,
    //             d.docketHeaderID,
    //             d.isActive
    //           )
    //         )
    //       : d;
    //   });
    // this.setState({
    //   runMailListDetailList: runMailListDetailList,
    //   editableRunMailListDetailList: runMailListDetailList,
    //   resetList: runMailListDetailList,
    //   loading: false,
    // });
  };

  getAllDocketDetails = (list) => {
    let docketDetailList = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return docketDetailList.push(
          createDocket(
            d.docketHeaderID,
            d.customerName,
            d.workOrderNumber,
            d.projectTitle,
            d.jobGroupNumber,
            d.planner,
            d.pgsDocketNO,
            d.eDocketNO,
            d.pgsDocketNo,
            d.edsDocketNo,
            d.accountNo,
            d.estimatedLodgementDate,
            d.submittedDate,
            d.service,
            d.distributorCode,
            d.totalNoOfArticle,
            d.isLocal ? "Local" : "International",
            d.isManual,
            d.genStatusName,
            d.edsExceptionMessage,
            d.statusId,
            d.modifiedByName,
            d.modifiedDate,
            d.createdByName,
            d.createdDate
          )
        );
      });
    this.setState({
      docketDetailList: docketDetailList,
      editableDocketDetailList: docketDetailList,
      loading2: false,
    });
  };

  getAllFileNames = (list) => {
    let fileNameList = [];
    fileNameList = list && list;
    this.setState({
      fileNameList:
        fileNameList &&
        fileNameList.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };

  getAllZones = (list) => {
    let zoneList = [];
    zoneList = list && list;
    this.setState(
      {
        zoneList:
          zoneList &&
          zoneList.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      }
      // () => {
      //   let zoneList = [...this.state.zoneList];
      //   zoneList.unshift({
      //     text: "None",
      //     id: null
      //   });
      //   this.setState({ zoneList: zoneList });
      // }
    );
  };

  getAllCountries = (list) => {
    let countryList = [];
    countryList = list && list;
    this.setState(
      {
        countryList:
          countryList &&
          countryList.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      }
      // () => {
      //   let countryList = [...this.state.countryList];
      //   countryList.unshift({
      //     text: "None",
      //     id: null
      //   });
      //   this.setState({ countryList: countryList });
      // }
    );
  };

  getAllProvince = (list) => {
    let provinceList = [];
    provinceList = list && list;
    this.setState({
      provinceList:
        provinceList &&
        provinceList.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };

  getAllMailSize = (list) => {
    let mailSizeList = [];
    mailSizeList = list && list;
    this.setState({
      mailSizeList:
        mailSizeList &&
        mailSizeList.sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
        ),
    });
  };

  _onKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  handleClickButton = (e, name) => {
    let newList = [...this.state.editableRunMailListDetailList];
    const { selected } = this.state;
    let jobGroupID = this.props.match.params.jobGroupId;
    newList.push({});
    if (name === "add") {
      if (selected.length === 1) {
        newList[newList.length - 1] = clone(
          newList.filter((r) => r.runMailListID === selected[0])[0]
        );
        newList[newList.length - 1].runMailListID = 0;
        newList[newList.length - 1].editClicked = true;
      } else {
        newList[newList.length - 1].editClicked = true;
        newList[newList.length - 1].runMailListID = 0;
        newList[newList.length - 1].fileName = "";
        newList[newList.length - 1].zone = "";
        newList[newList.length - 1].country = "";
        newList[newList.length - 1].province = "";
        newList[newList.length - 1].copies = "";
        newList[newList.length - 1].quantity = "";
        newList[newList.length - 1].totalOrderQuantity = 0;
        newList[newList.length - 1].remarks = "";
        newList[newList.length - 1].routing = "";
        newList[newList.length - 1].pgsDocketNumber = "";
        newList[newList.length - 1].cop = false;
        newList[newList.length - 1].isBM1500Countries = false;
        newList[newList.length - 1].mailSize = null;
        newList[newList.length - 1].noOfBags = "";
        newList[newList.length - 1].weightPerBag = "";
        newList[newList.length - 1].weightPerItem = "";
        newList[newList.length - 1].noOfArticles = "";
        newList[newList.length - 1].totalChargeableWeight = "";
        newList[newList.length - 1].postageCharges = "";
        newList[newList.length - 1].totalCharges = "";
        newList[newList.length - 1].isLocal = false;
        newList[newList.length - 1].docketHeaderID = "";
        newList[newList.length - 1].isActive = "";
        newList[newList.length - 1].createdBy = localStorage.loggedInId;
      }
      this.setState(
        {
          //runMailListDetailList: newList,
          editableRunMailListDetailList: newList,
          disableAdd: true,
          disableEdit: false,
          selectedEditIndex: newList.length - 1,
        },
        () => {
          document.getElementById(`tableRow${newList.length - 1}`).focus();
        }
      );
    } else if (name === "reset") {
      this.setState({
        runMailListDetailList: this.state.resetList,
        editableRunMailListDetailList: this.state.resetList,
        disableAdd: false,
        disableEdit: true,
      });
    } else if (name === "createManualDocket") {
      let valid = false;
      let [{ store }, dispatch] = this.context;
      const { runMailListDetailList, selected } = this.state;
      let filteredList = runMailListDetailList.filter(
        (r) => selected.indexOf(r.runMailListID) >= 0
      );
      valid = filteredList.every(
        (r) =>
          r.postageCharges !== "" &&
          r.totalCharges !== "" &&
          r.postageCharges !== 0 &&
          r.totalCharges !== 0
      );
      if (valid) {
        let values = store.selectedDocketIds;
        values = values && values.concat("-", "true");
        dispatch({
          type: "getSelectedDocketId",
          newStore: values,
        });
        this.props.history.push("/add_docket/" + jobGroupID);
      } else {
        this.setState({ isManualDocketRecord: true });
      }
    } else if (name === "createEDocket") {
      let [{ store }, dispatch] = this.context;
      let values = store.selectedDocketIds;
      values = values && values.concat("-", "false");
      dispatch({
        type: "getSelectedDocketId",
        newStore: values,
      });
      this.props.history.push("/add_docket/" + jobGroupID);
    }
  };
  openPopUpModalForLdgDate = (e, name) => {
    let nD = new Date()
    nD.setHours("18", "00", "00")
    this.setState({
      openLdgDatePopUp: true, checkIsManual: name, lodgementDate: new Date(),
      lodgementTime: nD
    })
  }
  generateDocketClick = (event, name = this.state.checkIsManual) => {
    let ldgDate = this.state.lodgementDate
    let ldgTime =
      this.state.lodgementTime &&
      this.state.lodgementTime.getHours() +
      ":" +
      this.state.lodgementTime.getMinutes() +
      ":" +
      this.state.lodgementTime.getSeconds();
    this.setState({ openLdgDatePopUp: false })
    if (name === "generateEDocket") {
      let dId = "";
      if (process.browser) dId = window.location.pathname.split("/")[2];

      this.setState({ loading: true });
      let selLocalIds = [];
      let selInternationalIds = [];
      this.state.selectedRows.map((r, i) => {
        if (r.isLocal) {
          selLocalIds.push(r.mlDocketHeaderID);
        }
        if (!r.isLocal) {
          selInternationalIds.push(r.mlDocketHeaderID);
        }
      });

      if (selLocalIds.length > 0) {
        this.setState({ loading: true });
        generateLocalEDocket(selLocalIds.join(","), "auto", ldgDate, ldgTime)
          .then((r) => {
            this.setState({ loading: false, selected2: [], selectedRows: [] });
            getDocInfo(dId).then((r) => {
              this.setState({ docketInfoList: r.data, loading: false, originalDocketInfoList: r.data, selectedDistributor: {} });
            });
          })
          .catch((e) => {
            this.setState({ loading: false, selected2: [], selectedRows: [] });
          });
      }
      if (selInternationalIds.length > 0) {
        this.setState({ loading: true });
        generateInternationalEDocket(selInternationalIds.join(","), "auto", ldgDate, ldgTime)
          .then((r) => {
            this.setState({ loading: false, selected2: [], selectedRows: [] });
            getDocInfo(dId).then((r) => {
              this.setState({ docketInfoList: r.data, loading: false, originalDocketInfoList: r.data, selectedDistributor: {} });
            });
          })
          .catch((e) => {
            this.setState({ loading: false, selected2: [], selectedRows: [] });
          });
      }
    }
    else {
      let dId = "";
      if (process.browser) dId = window.location.pathname.split("/")[2];
      this.setState({ loading: true });
      let selLocalIds = [];
      let selInternationalIds = [];
      this.state.selectedRows.map((r, i) => {
        if (r.isLocal) {
          selLocalIds.push(r.mlDocketHeaderID);
        }
        if (!r.isLocal) {
          selInternationalIds.push(r.mlDocketHeaderID);
        }
      });

      if (selLocalIds.length > 0) {
        this.setState({ loading: true });
        generateLocalEDocket(selLocalIds.join(","), "manual", ldgDate, ldgTime)
          .then((r) => {
            this.setState({ loading: false, selected2: [], selectedRows: [] });
            getDocInfo(dId).then((r) => {
              this.setState({ docketInfoList: r.data, loading: false, originalDocketInfoList: r.data, selectedDistributor: {} });
            });
          })
          .catch((e) => {
            this.setState({ loading: false, selected2: [], selectedRows: [] });
          });
      }
      if (selInternationalIds.length > 0) {
        this.setState({ loading: true });
        generateInternationalEDocket(selInternationalIds.join(","), "manual", ldgDate, ldgTime)
          .then((r) => {
            this.setState({ loading: false, selected2: [], selectedRows: [] });
            getDocInfo(dId).then((r) => {
              this.setState({ docketInfoList: r.data, loading: false, originalDocketInfoList: r.data, selectedDistributor: {} });
            });
          })
          .catch((e) => {
            this.setState({ loading: false, selected2: [], selectedRows: [] });
          });
      }
    }
  };

  onEditClicked = (e, i) => {
    this.setState({ selectedEditIndex: i });
    let newList = clone(this.state.editableRunMailListDetailList);
    let mailSizeRequired = null;
    let showText = null;
    if (newList[i].country && newList[i].country.value.toLowerCase() === "sg") {
      mailSizeRequired = true;
      showText = true;
    } else {
      mailSizeRequired = false;
      showText = false;
    }

    newList[i].editClicked = true;
    this.setState({
      editableRunMailListDetailList: newList,
      disableAdd: true,
      isZoneOrCountrySelected: "",
      mailSizeRequired: mailSizeRequired,
      showText: showText,
    });
  };

  onDocketEditClicked = (e, i, docketHeaderID) => {
    this.props.history.push("/update_docket/" + docketHeaderID);
  };

  deleteRunMailListConfirm = (e, i, id, res) => {
    if (res === "Yes") {
      let modifiedBy = localStorage.loggedInId;
      const dispatch = this.context[1];
      //   deleteRunMailList(modifiedBy, id)
      //     .then((r) => {
      //       if (r === true) {
      //         let jobGroupID = this.props.match.params.jobGroupId;
      //         this.setState({ disableEdit: true, loading: true });
      //         getRunMailList(jobGroupID).then((r) => {
      //           dispatch({
      //             type: "getRunMailList",
      //             newStore: r.data,
      //           });
      //           this.getAllRunMailDetails(r.data);
      //         });
      //       }
      //       this.setState({ isdeleteRunMailList: false });
      //     })
      // .catch(this.setState({ isdeleteRunMailList: false }));
    } else {
      this.setState({ isdeleteRunMailList: false });
    }
  };

  deleteRunMailListClicked = (e, i, row) => {
    const { deleteRunMailListValues } = this.state;
    deleteRunMailListValues.index = i;
    deleteRunMailListValues.id = row.runMailListID;
    this.setState({
      isdeleteRunMailList: true,
      deleteRunMailListValues: deleteRunMailListValues,
    });
  };

  onDeleteDocketClicked = (e, i, row) => {
    const { deleteDocketListValues } = this.state;
    deleteDocketListValues.index = i;
    deleteDocketListValues.id = row.docketHeaderID;
    this.setState({
      confirmDelete: true,
      deleteDocketListValues: deleteDocketListValues,
    });
  };

  onDeleteDocket = (e, i, id, res) => {
    if (res === "Yes") {
      let modifiedBy = localStorage.loggedInId;
      const dispatch = this.context[1];
      //   deleteDocket(modifiedBy, id.toString())
      //     .then((r) => {
      //       if (r === true) {
      //         let jobGroupID = this.props.match.params.jobGroupId;
      //         this.setState({ disableEdit: true, loading: true, loading2: true });
      //         getRunMailList(jobGroupID).then((r) => {
      //           dispatch({
      //             type: "getRunMailList",
      //             newStore: r.data,
      //           });
      //           this.getAllRunMailDetails(r.data);
      //         });
      //         GetAllDocketsByJGID(jobGroupID).then((r) => {
      //           dispatch({
      //             type: "GetAllDocketsByJGID",
      //             newStore: r.data,
      //           });
      //           this.getAllDocketDetails(r.data);
      //         });
      //       }
      //       this.setState({ confirmDelete: false });
      //     })
      //     .catch(this.setState({ confirmDelete: false }));
    } else {
      this.setState({ confirmDelete: false });
    }
    this.setState({ confirmDelete: false });
  };

  continueCreateDocket = () => {
    this.setState({ isManualDocketRecord: false });
  };

  onDoneClicked = (e, i, record) => {
    let newList = [...this.state.editableRunMailListDetailList];
    newList[i] = record;
    newList[i].editClicked = false;

    newList[i].totalChargeableWeight =
      newList[i].noOfArticles &&
      newList[i].weightPerItem &&
      (parseInt(newList[i].noOfArticles) * parseInt(newList[i].weightPerItem)) /
      1000;

    newList[i].totalChargeableWeight = this.addZeroes(
      newList[i].totalChargeableWeight.toString(),
      3
    );

    let runMailList = newList[i];
    const { workOrder } = this.state;
    let jobOrderGroupID = workOrder.jobGroupID;
    let filename = runMailList.fileName && runMailList.fileName.text;
    let zoneID = runMailList.zone && runMailList.zone.id;
    // let zoneID = null;
    let countryID = runMailList.country && runMailList.country.id;
    let provinceID = !runMailList.province
      ? null
      : runMailList.province && runMailList.province.id;
    let mailSize = !runMailList.mailSize
      ? null
      : runMailList.mailSize && runMailList.mailSize.id;
    let copies = runMailList.copies;
    let quantity = runMailList.quantity;
    let isCOP = runMailList.cop;
    let weightPerItem = runMailList.weightPerItem;
    let noOfArticles = runMailList.noOfArticles;
    let totalChargeableWeight = runMailList.totalChargeableWeight;
    let noOfBags = runMailList.noOfBags;
    let weightPerBag = runMailList.weightPerBag;
    let isLocal =
      runMailList.country && runMailList.country.value.toLowerCase() === "sg"
        ? true
        : false;
    let postageRate = runMailList.postageCharges;
    let totalCharges = runMailList.totalCharges;
    let remarks = runMailList.remarks;
    let docketHeaderID = runMailList.docketHeaderID;
    let routing = runMailList.routing;
    let modifiedBy = localStorage.loggedInId;
    let createdBy = localStorage.loggedInId;
    let isActive = true;
    let isBM1500Countries = runMailList.isBM1500Countries;

    if (parseInt(runMailList.runMailListID) === 0) {
      let reqBody = {
        filename,
        jobOrderGroupID,
        zoneID,
        countryID,
        provinceID,
        isCOP,
        mailSize,
        weightPerItem,
        noOfArticles,
        totalChargeableWeight,
        noOfBags,
        weightPerBag,
        isLocal,
        postageRate,
        totalCharges,
        remarks,
        docketHeaderID,
        createdBy,
        routing,
        isBM1500Countries,
        copies,
        quantity,
      };
      //   createRunMailList(reqBody).then((r) => {
      //     let jobGroupID = this.props.match.params.jobGroupId;
      //     const dispatch = this.context[1];

      //     if (r === true) {
      //       this.setState({ loading: true });

      //       // window.location.reload();
      //       getRunMailList(jobGroupID).then((r) => {
      //         dispatch({
      //           type: "getRunMailList",
      //           newStore: r.data,
      //         });
      //         this.getAllRunMailDetails(r.data);
      //       });
      //     }
      //   });
    } else {
      let reqBody = {
        filename,
        jobOrderGroupID,
        zoneID,
        countryID,
        provinceID,
        isCOP,
        mailSize,
        weightPerItem,
        noOfArticles,
        totalChargeableWeight,
        noOfBags,
        weightPerBag,
        isLocal,
        postageRate,
        totalCharges,
        remarks,
        docketHeaderID,
        modifiedBy,
        isActive,
        routing,
        isBM1500Countries,
        copies,
        quantity,
      };
      //   updateRunMailList(reqBody, runMailList.runMailListID).then((r) => {
      //     if (r === false) {
      //       //
      //     }
      //   });
    }

    this.setState({
      editableRunMailListDetailList: newList,
      runMailListDetailList: newList,
      resetList: newList,
      disableAdd: false,
      isZoneOrCountrySelected: "",
      showText: false,
    });
  };

  DetailsValid = (List) => {
    List.map((element) => {
      return (element.validRecord =
        element.country && element.country.id === 186
          ? element.fileName !== "" &&
          // element.zone !== "" &&
          element.country !== null &&
          element.copies !== "" &&
          element.mailSize !== null &&
          element.copies !== 0 &&
          element.quantity !== "" &&
          element.quantity !== 0 &&
          element.weightPerItem !== "" &&
          element.quantity !== 0 &&
          element.noOfArticles !== "" &&
          element.noOfArticles !== 0 &&
          element.totalChargeableWeight !== "" &&
          element.totalChargeableWeight !== 0
          : element.fileName !== "" &&
          // element.zone !== "" &&
          element.country !== null &&
          element.copies !== "" &&
          element.copies !== 0 &&
          element.quantity !== "" &&
          element.quantity !== 0 &&
          element.weightPerItem !== "" &&
          element.quantity !== 0 &&
          element.noOfArticles !== "" &&
          element.noOfArticles !== 0 &&
          element.totalChargeableWeight !== "" &&
          element.totalChargeableWeight !== 0);
    });
    this.setState({ editableRunMailListDetailList: List });
  };

  onCancelClicked = (e, i, record) => {
    let newList = clone(this.state.editableRunMailListDetailList);
    let newList2 = clone(this.state.runMailListDetailList);

    newList = newList2;
    this.setState({
      editableRunMailListDetailList: newList,
      disableAdd: false,
      showText: false,
      // isZoneOrCountrySelected: ""
    });
  };

  onBlur = (event, index) => {
    const { editableRunMailListDetailList } = this.state;
    let values = event.target.value;
    values = this.addZeroes(values.toString(), 3);
    editableRunMailListDetailList[index][event.target.name] = values;
    this.setState({ editableRunMailListDetailList });
  };

  addZeroes(num, decimalPlaces) {
    var value = Number(num);
    var res = num.split(".");
    if (res.length === 1 || res[1].length < decimalPlaces) {
      value = value.toFixed(decimalPlaces);
    }
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  handleRowChange = (event, index) => {
    let newEditableList = clone(this.state.editableRunMailListDetailList);
    newEditableList[index][event.target.name] = event.target.value;
    if (event.target.name === "copies" || event.target.name === "quantity") {
      if (
        parseInt(newEditableList[index]["copies"]) > 0 &&
        parseInt(newEditableList[index]["quantity"]) > 0
      ) {
        newEditableList[index]["totalOrderQuantity"] =
          parseInt(newEditableList[index]["copies"]) *
          parseInt(newEditableList[index]["quantity"]);
      } else {
        newEditableList[index]["totalOrderQuantity"] = 0;
      }
    }
    if (
      event.target.name === "weightPerItem" ||
      event.target.name === "noOfArticles"
    ) {
      if (
        parseInt(newEditableList[index]["weightPerItem"]) > 0 &&
        parseInt(newEditableList[index]["noOfArticles"]) > 0
      ) {
        newEditableList[index]["totalChargeableWeight"] =
          (parseInt(newEditableList[index]["weightPerItem"]) *
            parseInt(newEditableList[index]["noOfArticles"])) /
          1000;
      } else {
        newEditableList[index]["totalChargeableWeight"] = 0;
      }
    }
    if (event.target.name === "totalChargeableWeight") {
      if (
        parseInt(newEditableList[index]["totalChargeableWeight"]) > 0 &&
        parseInt(newEditableList[index]["noOfArticles"]) > 0
      ) {
        newEditableList[index]["weightPerItem"] =
          (parseInt(newEditableList[index]["totalChargeableWeight"]) * 1000) /
          parseInt(newEditableList[index]["noOfArticles"]);
      } else {
        newEditableList[index]["weightPerItem"] = 0;
      }
    }
    this.setState({ editableRunMailListDetailList: newEditableList });

    this.DetailsValid(newEditableList);
  };

  handleSwitchChange = (event, index) => {
    let newEditableList = clone(this.state.editableRunMailListDetailList);
    newEditableList[index][event.target.name] = event.target.checked;
    this.setState({ editableRunMailListDetailList: newEditableList });
  };

  handleDDLChangeUpdated = (event, value) => {
    event.persist();
    let index = "";
    index = this.state.selectedEditIndex;

    let name = event.target.id.split("-")[0];
    let newEditableList = clone(this.state.editableRunMailListDetailList);
    let mailSizeRequired = this.state.mailSizeRequired;
    newEditableList[index][name] = value;

    if (name == "zone") {
      value &&
        this.setState({
          zoneValue: value.id,
        });
    }

    if (name == "country") {
      if (value !== null && value.value !== "SG")
        newEditableList[index]["mailSize"] = null;

      value &&
        this.setState({
          countryValue: value.id,
        });
    }

    if (this.state.countryValue) {
      document
        .getElementById("zoneCell" + index)
        .setAttribute("style", "pointer-events: none");
      newEditableList[index].zone = "";
    } else {
      document
        .getElementById("zoneCell" + index)
        .setAttribute("style", "pointer-events: auto");
    }
    if (this.state.zoneValue) {
      document
        .getElementById("countryCell" + index)
        .setAttribute("style", "pointer-events: none");
      newEditableList[index].country = "";
    } else {
      document
        .getElementById("countryCell" + index)
        .setAttribute("style", "pointer-events: auto");
    }
    let showText = null;
    if (
      newEditableList[index].country &&
      newEditableList[index].country.value.toLowerCase() === "sg"
    ) {
      mailSizeRequired = true;
      showText = true;
    } else {
      mailSizeRequired = false;
      showText = false;
    }
    this.setState({
      editableRunMailListDetailList: newEditableList,
      mailSizeRequired: mailSizeRequired,
      showText: showText,
    });
    this.DetailsValid(newEditableList);
  };

  // handleDDLChange = async (event, index, name, list) => {
  //   // mailSizeRequired;
  //   let newEditableList = clone(this.state.editableRunMailListDetailList);
  //   let mailSizeRequired = this.state.mailSizeRequired;
  //   if (event.target.id !== "") {
  //     let id = event.target.id.split("-")[3];
  //     newEditableList[index][name] = list[id];
  //   } else {
  //     newEditableList[index][name] = null;
  //   }

  //   if (name == "zone") {
  //     let currentZone = event.target.id.split("option-")[1];
  //     await this.setState({
  //       zoneValue: currentZone,
  //     });
  //   }

  //   if (name == "country") {
  //     let currentCountry = event.target.id.split("option-")[1];
  //     await this.setState({
  //       countryValue: currentCountry,
  //     });
  //   }

  //   if (this.state.countryValue) {
  //     document
  //       .getElementById("zoneCell" + index)
  //       .setAttribute("style", "pointer-events: none");
  //     newEditableList[index].zone = "";
  //   } else {
  //     document
  //       .getElementById("zoneCell" + index)
  //       .setAttribute("style", "pointer-events: auto");
  //   }
  //   if (this.state.zoneValue) {
  //     document
  //       .getElementById("countryCell" + index)
  //       .setAttribute("style", "pointer-events: none");
  //     newEditableList[index].country = "";
  //   } else {
  //     document
  //       .getElementById("countryCell" + index)
  //       .setAttribute("style", "pointer-events: auto");
  //   }
  //   let showText = null;
  //   if (
  //     newEditableList[index].country &&
  //     newEditableList[index].country.value.toLowerCase() === "sg"
  //   ) {
  //     mailSizeRequired = true;
  //     showText = true;
  //   } else {
  //     mailSizeRequired = false;
  //     showText = false;
  //   }
  //   this.setState({
  //     editableRunMailListDetailList: newEditableList,
  //     mailSizeRequired: mailSizeRequired,
  //     showText: showText,
  //   });
  //   this.DetailsValid(newEditableList);
  // };

  checkedStatusArray(arr, flag, id, checked) {
    let stateObj = { flag: flag, id: id };

    if (arr.length == 0) {
      arr.push(stateObj);
      if (flag) {
        return flag;
      } else {
        return !flag;
      }
    } else {
      if (checked) {
        arr.push(stateObj);

        // for (let i = 0; i < arr.length; i++) {
        //  // flag = flag ^ arr[i].flag;
        //    if(flag){

        //    }
        //  }
        // if (!flag) {
        //   arr.push(stateObj);
        //   return !flag;
        // }
        if (flag) {
          for (let i = 0; i < arr.length; i++) {
            if (!arr[i].flag) {
              return false;
            }
          }
        } else {
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].flag) {
              return false;
            }
          }
        }
        return true;
      } else {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].id == id) {
            arr.splice(i, 1);
          }
        }
        if (arr.length == 0) {
          return false;
        } else {
          flag = arr[0].flag;
          if (flag) {
            for (let i = 0; i < arr.length; i++) {
              if (!arr[i].flag) {
                return false;
              }
            }
          } else {
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].flag) {
                return false;
              }
            }
          }
          return true;
        }
      }
    }
  }
  handleClick(event, runMailListID, runMailList) {
    // if(event.target.checked){

    // }
    let flag = runMailList.isLocal;
    let id = runMailListID;
    let stateObj = { flag: flag, id: id };
    if (docsel.length == 0) {
      docsel.push(stateObj);
    } else {
      if (event.target.checked) {
        for (let i = 0; i < docsel.length; i++) {
          flag = flag ^ docsel[i].flag;
        }
        if (!flag) {
          docsel.push(stateObj);
          this.setState({
            validSelection: true,
          });
        }
      } else {
        for (let i = 0; i < docsel.length; i++) {
          if (docsel[i].id == runMailListID) {
            docsel.splice(i, 1);
          }
        }
      }
    }

    if (runMailList.runMailListID > 0) {
      const dispatch = this.context[1];
      let { selected, runMailListDetailList } = this.state;
      const selectedIndex = selected.indexOf(runMailListID);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, runMailListID);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      if (newSelected.length === 1) {
        this.setState({
          selected: newSelected,
          selectedRunMailList: runMailList.runMailListNo,
          selectedRunMailListId: runMailListID,
        });
      } else {
        this.setState({
          selected: newSelected,
          selectedRunMailList: "",
          selectedRunMailListId: 0,
        });
      }

      let runMailListIds = clone(newSelected);
      let docketList = runMailListDetailList.filter(
        (d) => runMailListIds.indexOf(d.runMailListID) >= 0
      );

      let localDocket = false;
      let validSelection = false;
      localDocket = docketList.every((d) => d.isLocal === true);
      if (localDocket) {
        validSelection = true;
      } else {
        validSelection = docketList.every((d) => d.isLocal === false);
      }
      this.setState({
        localDocket: localDocket,
        validSelection: validSelection,
      });
      let values = newSelected.join(",");
      values = values.concat("-", localDocket);

      dispatch({
        type: "getSelectedDocketId",
        newStore: values,
      });
    }
  }
  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };
  onSelectAllClicked = (e) => {
    // console.log(e.target.checked)
    let sDList = clone(this.state.docketInfoList)
    let sRows = []
    let newSelected = []
    this.setState({ allSelected: e.target.checked, })
    let disableEDOCBtn = true
    if (e.target.checked) {
      this.state.docketInfoList.map((m, i) => {
        console.log(m.isLocal, m.statusCode)
        if (this.state.isLocalType === m.isLocal) {
          if (m.status.trim() !== "Cancelled" && m.statusCode !== "DPEDG" && m.statusCode !== "DPMDG") {
            disableEDOCBtn = false
            sDList[i].checkBoxVal = true
            newSelected.push(m.mlDocketHeaderID)
            sRows.push(m)
          }
        }
        else {

        }
      })
    }
    else {
      this.state.docketInfoList.map((m, i) => {
        sDList[i].checkBoxVal = false
      })
      newSelected = []
      disableEDOCBtn = true
      sRows = []
    }
    this.setState({ docketInfoList: sDList, disableEDOCBtn, selected2: newSelected, selectedRows: sRows })
  }
  handleDocketClick = async (event, docketID, docket, indX) => {
    let newSelectedRows = [];
    let { selectedRows } = this.state;
    let sDList = clone(this.state.docketInfoList)
    let type = docket.statusName;
    let flag = docketStatusSelection[type];
    let returnStatus = this.checkedStatusArray(
      manDoc,
      flag,
      docketID,
      event.target.checked
    );
    // this.state.docketInfoList.map
    // console.log(this.state.docketInfoList, docketID, docket, indX)
    let checkedSelAll = true
    sDList.forEach(el => {
      if (el.mlDocketHeaderID === docketID)
        el.checkBoxVal = event.target.checked

      if (this.state.isLocalType === el.isLocal) {

        if (el.status.trim() !== "Cancelled" && el.statusCode !== "DPEDG" && el.statusCode !== "DPMDG") {
          console.log(el)
          if (!el.checkBoxVal || el.checkBoxVal === false) {
            checkedSelAll = false
            console.log(el)
          }
        }
      }


    })
    if (checkedSelAll)
      this.setState({ allSelected: true })


    if (!event.target.checked)
      this.setState({ allSelected: false })

    this.setState({ docketInfoList: sDList })
    if (returnStatus && manDoc.length > 0) {
      if (manDoc[0].flag == true) {
        this.setState({ isGen: true, isMan: false });
      } else {
        this.setState({ isGen: false, isMan: true });
      }
    } else {
      this.setState({ isGen: false, isMan: false });
    }
    let { selected2, docketDetailList } = this.state;
    const selectedIndex = selected2.indexOf(docketID);
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected2, docketID);
      newSelectedRows = newSelectedRows.concat(selectedRows, docket);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected2.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected2.length - 1) {
      newSelected = newSelected.concat(selected2.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected2.slice(0, selectedIndex),
        selected2.slice(selectedIndex + 1)
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.setState({
        selected2: newSelected,
        selectedDocket: docket.DocketNo,
        selectedDocketId: docketID,
        selectedRows: newSelectedRows,
      });
    } else {
      this.setState({
        selected2: newSelected,
        selectedDocket: "",
        selectedDocketId: 0,
        selectedRows: newSelectedRows,
      });
    }

    let checkManual = true;
    let checkEDOC = true
    if (newSelectedRows.length > 0) {
      checkManual = false;
      checkEDOC = false
      newSelectedRows.map((m) => {
        if (m.statusCode !== "DPMDG") {
          checkManual = true;
        }
        if (m.statusCode == "DPEDG" && m.statusCode == "DPMDG") {
          checkEDOC = true;
        }
      });
    }
    this.setState({ disableManual: checkManual, disableEDOCBtn: checkEDOC });
    let docketIds = clone(newSelected);
    let docketList = docketDetailList.filter(
      (d) => docketIds.indexOf(d.docketHeaderID) >= 0
    );

    //let localDocket = false;
    let manualDocket = false;
    //let validSelection = false;
    //localDocket = docketList.every((d) => d.type === "Local");
    manualDocket = docketList.some((d) => d.isManual === true);
    const arr = docketList;
    const filtered = arr.reduce(
      (a, o) => (o.type === "Local" && a.push(o.docketHeaderID), a),
      []
    );
    const filtered1 = arr.reduce(
      (a, o) => (o.type === "International" && a.push(o.docketHeaderID), a),
      []
    );

    await this.setState({
      localDocketList: filtered,
    });

    await this.setState({
      internationalDocketList: filtered1,
    });
    // const arr = docketList;
    // const filtered = arr.reduce(
    //   (a, o) => (o.type === "Local" && a.push(o.docketHeaderID), a),
    //   []
    // );
    // const filtered1 = arr.reduce(
    //   (a, o) => (o.type === "International" && a.push(o.docketHeaderID), a),
    //   []
    // );

    // await this.setState({
    //   localDocketList: filtered,
    // });

    // await this.setState({
    //   internationalDocketList: filtered1,
    // });

    // this.setState({localDocket2:localDocket, validDocketSelection: validSelection, manualDocket: manualDocket});
    this.setState({ manualDocket: manualDocket });

  };
  // handleDocketClick = async (event, docketID, docket) => {
  //   let newSelectedRows = [];
  //   let { selectedRows } = this.state;
  //   let type = docket.statusName;
  //   let flag = docketStatusSelection[type];
  //   let returnStatus = this.checkedStatusArray(
  //     manDoc,
  //     flag,
  //     docketID,
  //     event.target.checked
  //   );

  //   if (returnStatus && manDoc.length > 0) {
  //     if (manDoc[0].flag == true) {
  //       this.setState({ isGen: true, isMan: false });
  //     } else {
  //       this.setState({ isGen: false, isMan: true });
  //     }
  //   } else {
  //     this.setState({ isGen: false, isMan: false });
  //   }
  //   let { selected2, docketDetailList } = this.state;
  //   const selectedIndex = selected2.indexOf(docketID);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected2, docketID);
  //     newSelectedRows = newSelectedRows.concat(selectedRows, docket);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected2.slice(1));
  //     newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
  //   } else if (selectedIndex === selected2.length - 1) {
  //     newSelected = newSelected.concat(selected2.slice(0, -1));
  //     newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected2.slice(0, selectedIndex),
  //       selected2.slice(selectedIndex + 1)
  //     );
  //     newSelectedRows = newSelectedRows.concat(
  //       selectedRows.slice(0, selectedIndex),
  //       selectedRows.slice(selectedIndex + 1)
  //     );
  //   }
  //   if (newSelected.length === 1) {
  //     this.setState({
  //       selected2: newSelected,
  //       selectedDocket: docket.DocketNo,
  //       selectedDocketId: docketID,
  //       selectedRows: newSelectedRows,
  //     });
  //   } else {
  //     this.setState({
  //       selected2: newSelected,
  //       selectedDocket: "",
  //       selectedDocketId: 0,
  //       selectedRows: newSelectedRows,
  //     });
  //   }
  //   let checkManual = true;
  //   let checkEDOC = true
  //   if (newSelectedRows.length > 0) {
  //     checkManual = false;
  //     checkEDOC = false
  //     newSelectedRows.map((m) => {
  //       if (m.statusCode !== "DPMDG") {
  //         checkManual = true;
  //       }
  //       if (m.statusCode == "DPEDG" || m.statusCode == "DPMDG") {
  //         checkEDOC = true;
  //       }
  //     });
  //   }
  //   this.setState({ disableManual: checkManual, disableEDOCBtn: checkEDOC });
  //   let docketIds = clone(newSelected);
  //   let docketList = docketDetailList.filter(
  //     (d) => docketIds.indexOf(d.docketHeaderID) >= 0
  //   );

  //   //let localDocket = false;
  //   let manualDocket = false;
  //   //let validSelection = false;
  //   //localDocket = docketList.every((d) => d.type === "Local");
  //   manualDocket = docketList.some((d) => d.isManual === true);
  //   const arr = docketList;
  //   const filtered = arr.reduce(
  //     (a, o) => (o.type === "Local" && a.push(o.docketHeaderID), a),
  //     []
  //   );
  //   const filtered1 = arr.reduce(
  //     (a, o) => (o.type === "International" && a.push(o.docketHeaderID), a),
  //     []
  //   );

  //   await this.setState({
  //     localDocketList: filtered,
  //   });

  //   await this.setState({
  //     internationalDocketList: filtered1,
  //   });
  //   // const arr = docketList;
  //   // const filtered = arr.reduce(
  //   //   (a, o) => (o.type === "Local" && a.push(o.docketHeaderID), a),
  //   //   []
  //   // );
  //   // const filtered1 = arr.reduce(
  //   //   (a, o) => (o.type === "International" && a.push(o.docketHeaderID), a),
  //   //   []
  //   // );

  //   // await this.setState({
  //   //   localDocketList: filtered,
  //   // });

  //   // await this.setState({
  //   //   internationalDocketList: filtered1,
  //   // });

  //   // this.setState({localDocket2:localDocket, validDocketSelection: validSelection, manualDocket: manualDocket});
  //   this.setState({ manualDocket: manualDocket });
  // };
  handleReportChange = (e) => {
    if (e.target.value.toLowerCase() !== 'none') {
      this.setState({
        showReportModal: true,
        selectedReportName: e.target.value
      });
    }
    e.target.value = "none";
  };

  handleHidePopup = () => {
    this.setState({
      showReportModal: false,
      selectedReportName: ""
    });
  };
  setLocalType = e => {
    console.log(e.target.checked)
    let sRows = clone(this.state.docketInfoList)
    this.setState({ isLocalType: e.target.checked })
    this.setState({ selected2: [], selectedRows: [], allSelected: false });
    this.state.docketInfoList.map((d, i) => {
      sRows[i].checkBoxVal = false
    })
    this.setState({ docketInfoList: sRows })

  }
  handleDistributorDDChanged = (e, value) => {
    let newList = []
    if (value) {
      this.state.originalDocketInfoList.map(m => {
        if (value.id === m.distributorID) {
          newList.push(m)
        }
      })
      this.setState({ docketInfoList: newList, selectedDistributor: value })
    }
    else {
      this.setState({ docketInfoList: this.state.originalDocketInfoList, selectedDistributor: {} })
    }
    this.setState({ allSelected: false, disableEDOCBtn: true })

  }
  handleDateChange = (date) => {
    console.log(date)
    if (date && date !== "Invalid Date")
      this.setState({ lodgementDate: date });
    else
      this.setState({ lodgementDate: "Invalid Date" });

  };

  handleTimeChange = (time) => {
    if (time && time !== "Invalid Date")
      this.setState({ lodgementTime: time });
    else
      this.setState({ lodgementTime: "Invalid Date" });

  };
  handleEdsErrorModal = (e, id) => {
    getEdsErrorDetails(id).then(r => {
      this.setState({ edsErrorData: r })
    })
    this.setState({ openEdsErrorModal: true })
  }
  handleEdsErrorModalClose = () => {
    this.setState({ openEdsErrorModal: false })
  }
  render() {
    let cId = "";
    if (process.browser) cId = window.location.pathname.split("/")[3];
    let { classes } = this.props;
    let {
      fileNameList,
      provinceList,
      countryList,
      order,
      orderBy,
      mailSizeList,
      disableAdd,
      selected,
      selected2,
      editableRunMailListDetailList,
      editableDocketDetailList,
      deleteRunMailListValues,
      deleteDocketListValues,
      workOrder,
      zoneList,
      mailSizeRequired,
    } = this.state;
    const isSelected2 = (id) => selected2.indexOf(id) !== -1;

    let isLocal =
      editableRunMailListDetailList.country &&
        editableRunMailListDetailList.country.value.toLowerCase() === "sg"
        ? true
        : false;
    return (
      <React.Fragment>
        {/* Delete Docket Confirmation Dialog */}
        {/* Delete Docket Confirmation Dialog */}
        { this.state.openEdsErrorModal && <EdsErrorModal open={this.state.openEdsErrorModal}
          handleEdsErrorModalClose={this.handleEdsErrorModalClose}
          data={this.state.edsErrorData} />}
        {this.state.showReportModal ?
          <ReportViewer
            url={`${window.localStorage.ReportURLByID}` + this.state.selectedReportName +
              "&IsDirectPrint=True&ReportParam=MLBatchHeaderID&Param1="
              + `${this.props.history.location.pathname.split("/")[2]}`}
            showPopup={this.state.showReportModal}
            // title={this.state.selectedReportName.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + this.state.selectedReportName.replace(/([A-Z])/g, " $1").slice(1)}
            title={this.state.selectedReportName && this.state.selectedReportName === "RunMailListFinal" ? "BL Run Mail List Final" : this.state.selectedReportName.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + this.state.selectedReportName.replace(/([A-Z])/g, " $1").slice(1)}

            onClose={() => this.handleHidePopup()}
            handleHidePopup={() => this.handleHidePopup()}
          /> : null
        }
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.openLdgDatePopUp}
        >
          <div >
            <DialogTitle id="customized-dialog-title">
              {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
              <span style={{ marginBottom: "1em", padding: ".5em 0" }}>Please select Lodgement Date and Time</span>
            </DialogTitle>
            <div style={{ fontSize: "20px", }}>

              <Divider />
              <div style={{ display: "flex", padding: "2em 1.2em" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disablePast
                    required
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    // className={classes.textField}
                    style={{ minWidth: "200p", marginRight: "15px" }}
                    id="pickupDate"
                    label="Estimated Lodgement Date"
                    value={this.state.lodgementDate}
                    onChange={this.handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={TimeFnsUtils}>
                  <KeyboardTimePicker
                    autoOk
                    required
                    disablePast
                    variant="inline"
                    margin="normal"
                    // className={classes.textField}
                    style={{ minWidth: "200p" }}

                    id="pickupTime"
                    label="Estimated Lodgement Time"
                    value={this.state.lodgementTime}
                    onChange={this.handleTimeChange}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <Divider />

            </div>

            <DialogActions style={{ padding: "16px 24px" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) =>
                  this.setState({ openLdgDatePopUp: false, checkIsManual: "" })
                }
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={this.state.lodgementDate == "Invalid Date" || this.state.lodgementTime == "Invalid Date"}
                color="primary"
                style={{ boxShadow: "none", marginLeft: "1em" }}
                onClick={(e) =>
                  this.generateDocketClick()
                }
              >
                Generate
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.confirmDelete}
        >
          <div style={{ padding: "4em" }}>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>
              {" "}
              Are you sure you want to cancel the docket ?
            </div>
            <div style={{ fontSize: "13px" }}>
              The docket will be Cancelled and you can't undo the process!!
            </div>
            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) =>
                  this.onDeleteDocket(
                    e,
                    deleteDocketListValues.index,
                    deleteDocketListValues.id,
                    "no"
                  )
                }
              >
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none", marginLeft: "1em" }}
                onClick={(e) =>
                  this.onDeleteDocket(
                    e,
                    deleteDocketListValues.index,
                    deleteDocketListValues.id,
                    "Yes"
                  )
                }
              >
                Yes
              </Button>
            </div>
          </div>
        </Dialog>
        {/* Manual Docket Confirmation Dialog */}
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.isManualDocketRecord}
        >
          <div style={{ padding: "4em" }}>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>
              {" "}
              Missing Postage charges and Total charges
            </div>
            <div style={{ fontSize: "13px" }}>
              Please provide Postage charges and Total charges for creating
              Manual Docket.
            </div>
            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={this.continueCreateDocket}
              >
                OK
              </Button>
            </div>
          </div>
        </Dialog>
        {/* Delete RunMailList Confirmation Dialog */}
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.isdeleteRunMailList}
        >
          <div style={{ padding: "4em" }}>
            <div style={{ fontSize: "20px", fontWeight: "500" }}>
              {" "}
              Are you sure you want to delete the Run Mail List ?
            </div>
            <div style={{ fontSize: "13px" }}>
              The Run Mail List will be Deleted and you can't undo the process!!
            </div>
            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) =>
                  this.deleteRunMailListConfirm(
                    e,
                    deleteRunMailListValues.index,
                    deleteRunMailListValues.id,
                    "no"
                  )
                }
              >
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none", marginLeft: "1em" }}
                onClick={(e) =>
                  this.deleteRunMailListConfirm(
                    e,
                    deleteRunMailListValues.index,
                    deleteRunMailListValues.id,
                    "Yes"
                  )
                }
              >
                Yes
              </Button>
            </div>
          </div>
        </Dialog>
        <Paper style={{ width: "100%" }}>
          {this.state.docketbaggingHeaderInfo && this.state.docketbaggingHeaderInfo.status && this.state.docketbaggingHeaderInfo.status === "Complete" && <div className="pop-up-header" style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <span>Update Docket</span>
            </div>
            <div style={{ flex: 1, textAlign: "right", paddingRight: "33px" }}>
              <div className="custom-select">
                <label style={{ paddingRight: "15px", fontSize: "18px" }}>Report</label>
                <select
                  onChange={(e) => {
                    this.handleReportChange(e);
                  }}
                  style={{ paddingLeft: "10px" }}
                >
                  <option value="none" className="custom-select-options">
                    None
                  </option>
                  <option
                    value="MailLodgementDetailsSheetReport"
                    className="custom-select-options"
                  >
                    Mail Lodgement Detail
                  </option>
                  <option
                    value="DocketChecklistReport"
                    className="custom-select-options"
                  >
                    Docket Checklist
                  </option>
                  <option value="RunMailListFinal" className="custom-select-options">
                    BL Run Mail List Final
                  </option>
                  <option value="PublisherPostingDocketInternational" className="custom-select-options">
                    Posting Docket International
                  </option>
                  <option value="PublisherPostingDocketLocal" className="custom-select-options">
                    Posting Docket Local
                  </option>
                </select>
              </div>
            </div>
          </div>}
          {cId && <Divider />}
          <div
            style={
              !this.props.clearOpenList
                ? {
                  overflow: "auto",
                  maxWidth: "calc(100vw - 106px)",
                  //maxHeight: "calc(100vh - 300px)"
                }
                : {
                  overflow: "auto",
                  maxWidth: "calc(100vw - 335px)",
                  //maxHeight: "calc(100vh - 300px)"
                }
            }
          >
            <div className={classes.container}>
              <div>
                <form
                  style={{ marginTop: "2em" }}
                  className={classes.container}
                >
                  <div style={{ marginBottom: "1em" }}>
                    <Typography
                      component="div"
                      className="blueBox"
                      style={{ width: "auto" }}
                    >
                      <div className="blueBoxLabel">
                        <div>Batch ID: </div>
                        <div className="blueBoxText">
                          {this.state.docketbaggingHeaderInfo.mlBatchHeader}
                        </div>
                      </div>
                      <div className="blueBoxLabel">
                        <div>Customer: </div>
                        <div className="blueBoxText">
                          {this.state.docketbaggingHeaderInfo.customerName}
                        </div>
                      </div>
                      <div className="blueBoxLabel">
                        <div>Status: </div>
                        <div className="blueBoxText">
                          {this.state.docketbaggingHeaderInfo.status}
                        </div>
                      </div>
                    </Typography>
                  </div>
                  <div style={{ marginTop: "1em" }}>
                    <EnhancedTableToolbar2
                      numSelected={selected2.length}
                      handleClickButton={this.handleClickButton}
                      generateDocketClick={this.generateDocketClick}
                      openPopUpModalForLdgDate={this.openPopUpModalForLdgDate}
                      disableAdd={this.state.disableAdd}
                      disableEdit={this.state.disableEdit}
                      order={order}
                      orderBy={orderBy}
                      headCells={headCells1}
                      // validDocketSelection={this.state.validDocketSelection}
                      //type={this.state.localDocket2}
                      isManual={this.state.manualDocket}
                      isMan={this.state.isMan}
                      isGen={this.state.isGen}
                      selected={this.state.selected2}
                      selectedRows={this.state.selectedRows}
                      disableManual={this.state.disableManual}
                      disableEDOCBtn={this.state.disableEDOCBtn}
                      docketInfoList={this.state.docketInfoList}

                    />
                    <div style={{ width: "100%" }}>
                      <div
                        style={
                          !this.props.clearOpenList
                            ? {
                              overflow: "auto",
                              border: "1px solid #eaeaea",
                              //maxWidth: "calc(100vw - 8em)",
                              height: "337px",
                            }
                            : {
                              overflow: "auto",
                              border: "1px solid #eaeaea",
                              //maxWidth: "calc(100vw - 330px)",
                              height: "337px",
                            }
                        }
                      >  <div style={{ display: "flex" }}>
                          <FormControlLabel style={{ marginLeft: ".1em" }}
                            control={<Switch name="isLocalType" color="primary" onChange={this.setLocalType} checked={this.state.isLocalType} value={this.state.isLocalType} />}
                            label={this.state.isLocalType ? "Local" : "International"}
                          />
                          <div style={{ width: "200px", marginLeft: "1em" }}>
                            <Autocomplete
                              autoComplete
                              // includeInputInList
                              // disableClearable
                              options={this.state.distributoListForFiltering}
                              onChange={this.handleDistributorDDChanged}

                              name="distributor"
                              hintText="distributor"
                              value={this.state.selectedDistributor}
                              getOptionLabel={(option) => option.text}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  placeholder="Distributor"
                                  margin="normal"
                                  style={{
                                    width: "100%",
                                    margin: "0px",
                                    fontSize: "12px",
                                    marginTop: "2px",
                                    fontWeight: 400
                                  }}
                                />
                              )}
                            /></div>
                        </div>
                        <Table
                          stickyHeader
                          aria-labelledby="tableTitle"
                          size="small"
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <EnhancedTableHead1
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            onSelectAllClicked={this.onSelectAllClicked}
                            allSelected={this.state.allSelected}
                            docketInfoList={this.state.docketInfoList}
                            isLocalType={this.state.isLocalType}
                          />

                          <TableBody style={{ overflow: "hidden" }}>
                            {this.state.docketInfoList.length > 0 &&
                              !this.state.loading ? (
                                stableSort(
                                  this.state.docketInfoList,
                                  getSorting(order, orderBy)
                                ).map((row, index) => {
                                  const isItemSelected = isSelected2(
                                    row.docketHeaderID
                                  );
                                  const labelId = `enhanced-table-checkbox-${index}`;

                                  return (
                                    <TableRow
                                      role="checkbox"
                                      aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      key={row.docketHeaderID}
                                      selected2={isItemSelected}
                                      style={
                                        row.status &&
                                          row.status.trim() === "Cancelled"
                                          ? { backgroundColor: "#EAEAEA" }
                                          : {}
                                      }
                                    >
                                      <TableCell padding="checkbox">
                                        {row.status &&
                                          row.status.trim() !== "Cancelled" && row.statusCode !== "DPEDG" && row.statusCode !== "DPMDG" && row.isLocal === this.state.isLocalType ? (
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  color="primary"
                                                  // checked={isItemSelected}
                                                  checked={row.checkBoxVal ? true : false}
                                                  onClick={(event) =>
                                                    this.handleDocketClick(
                                                      event,
                                                      row.mlDocketHeaderID,
                                                      row
                                                    )
                                                  }
                                                />
                                              }
                                            />
                                          ) : null}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="right"
                                        className="table-content-cell"
                                      >
                                        {row.pgsDocketNO}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.eDocketNO}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="right"
                                        className="table-content-cell"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.accountNo}
                                        </Typography>
                                      </TableCell>

                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="none"
                                        style={{ paddingRight: "5px" }}
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        {row.estimatedLodgementDate &&
                                          row.estimatedLodgementDate.length > 7 ? (
                                            <Tooltip
                                              title={
                                                moment(
                                                  row.estimatedLodgementDate
                                                ).format("DD/MM/YYYY") +
                                                " " +
                                                row.estimatedLodgementTime.slice(
                                                  0,
                                                  8
                                                )
                                              }
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {moment(
                                                  row.estimatedLodgementDate
                                                ).format("DD/MM/YYYY ") +
                                                  " " +
                                                  row.estimatedLodgementTime.slice(
                                                    0,
                                                    8
                                                  )}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.estimatedLodgementDate ? moment(
                                                row.estimatedLodgementDate
                                              ).format("DD/MM/YYYY") +
                                                " " +
                                                row.estimatedLodgementTime.slice(
                                                  0,
                                                  8
                                                ) : ""}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="none"
                                        style={{ paddingRight: "5px" }}
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        {row.submittedDate &&
                                          row.submittedDate.length > 16 ? (
                                            <Tooltip
                                              title={moment(
                                                row.submittedDate
                                              ).format("DD/MM/YYYY")}
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {moment(row.submittedDate).format(
                                                  "DD/MM/YYYY"
                                                )}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.submittedDate ? moment(row.submittedDate).format(
                                                "DD/MM/YYYY"
                                              ) : ""}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.service}
                                        </Typography>
                                      </TableCell>
                                      <TableCell component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        //align="right"
                                        className="table-content-cell">
                                        {row.mailingScheme &&
                                          row.mailingScheme.length > 12 ? (
                                            <Tooltip
                                              title={row.mailingScheme}
                                              aria-label="add"
                                            >
                                              <Typography noWrap>
                                                {row.mailingScheme}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography noWrap>
                                              {row.mailingScheme}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.distributorCode}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-right-cell"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.totalNoOfArticle}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.isLocal
                                            ? "Local"
                                            : "International"}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="none"
                                        align="left"
                                        // className="table-content-cell"
                                        className="table-content-cell-eds-error"
                                        style={{ minWidth: "180px", }}
                                        onClick={e => this.handleEdsErrorModal(e, row.mlDocketHeaderID)}
                                      >
                                        <Typography noWrap>
                                          <StatusText
                                            color={getColor(row.status)}
                                          >
                                            {row.status &&
                                              row.status.length > 16 ? (
                                                <Tooltip
                                                  title={row.status}
                                                  aria-label="add"
                                                >
                                                  <Typography noWrap>
                                                    {row.status}
                                                  </Typography>
                                                </Tooltip>
                                              ) : (
                                                <Typography noWrap>
                                                  {row.status}
                                                </Typography>
                                              )}
                                          </StatusText>
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        {row.edsExceptionMessage &&
                                          row.edsExceptionMessage.length > 16 ? (
                                            <Tooltip
                                              title={row.edsExceptionMessage}
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.edsExceptionMessage}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.edsExceptionMessage}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="none"
                                        style={{ paddingRight: "5px" }}
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        {row.modifiedDate &&
                                          row.modifiedDate.length > 16 ? (
                                            <Tooltip
                                              title={moment(
                                                row.modifiedDate
                                              ).format("DD/MM/YYYY HH:mm:ss")}
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {moment(row.modifiedDate).format(
                                                  "DD/MM/YYYY HH:mm:ss"
                                                )}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {moment(row.modifiedDate).format(
                                                "DD/MM/YYYY HH:mm:ss"
                                              )}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.modifiedName && row.modifiedName}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="none"
                                        style={{ paddingRight: "5px" }}
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        {row.createdDate &&
                                          row.createdDate.length > 16 ? (
                                            <Tooltip
                                              title={moment(row.createdDate).format(
                                                "DD/MM/YYYY HH:mm:ss"
                                              )}
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {moment(row.createdDate).format(
                                                  "DD/MM/YYYY HH:mm:ss"
                                                )}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {moment(row.createdDate).format(
                                                "DD/MM/YYYY HH:mm:ss"
                                              )}
                                            </Typography>
                                          )}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.createdName && row.createdName}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        id={labelId}
                                        padding="default"
                                        align="left"
                                        className="table-content-cell"
                                      >
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                            display: "flex",
                                          }}
                                        >
                                          <div>
                                            <span
                                              style={{
                                                cursor: "pointer",
                                                color: "#6c6b6b",
                                              }}
                                              onClick={(e) =>
                                                this.props.history.push(
                                                  "/view_pubBackLabelDocketPosting/" +
                                                  row.mlDocketHeaderID
                                                )
                                              }
                                            >
                                              <ViewIcon
                                                style={{
                                                  color: "#6c6b6b",
                                                }}
                                              />
                                            </span>
                                            {
                                              row.statusCode === "DPEDG" || row.statusCode === "DPMDG" ? <span
                                                style={{
                                                  color: "rgb(185 179 179)",
                                                }}

                                              >
                                                <EditIcon

                                                  style={{
                                                    color: "#6c6b6b",
                                                  }}
                                                />

                                              </span> : <span
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                                onClick={(e) =>
                                                  this.props.history.push(
                                                    "/edit_pubBackLabelDocketPosting/" +
                                                    row.mlDocketHeaderID
                                                  )
                                                }
                                              >
                                                  <EditIcon

                                                    style={{
                                                      color: "#6c6b6b",
                                                    }}
                                                  />

                                                </span>
                                            }

                                            {/* {row.status && row.status.trim() !==
                                          "Cancelled" ? (
                                            <span
                                              style={{
                                                cursor: "pointer",
                                                color: "#6c6b6b",
                                              }}
                                              onClick={(e) =>
                                                this.onDeleteDocketClicked(
                                                  e,
                                                  index,
                                                  row
                                                )
                                              }
                                            >
                                              <DeleteIcon
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                              />
                                            </span>
                                          ) : null} */}
                                          </div>
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })
                              ) : this.state.loading ? (
                                <TableCell
                                  colSpan={15}
                                  style={{ textAlign: "center" }}
                                >
                                  <LinearProgress />
                                </TableCell>
                              ) : (
                                  <TableRow
                                    style={{
                                      borderBottom: "none",
                                      height: "180px",
                                    }}
                                  >
                                    {this.state.loading2 ? (
                                      <TableCell
                                        colSpan={15}
                                        style={{ textAlign: "center" }}
                                      >
                                        <div style={{ fontWeight: "bolder" }}>
                                          No docket found
                                    </div>
                                        {/* <div>
                                          Initiate from the country break down/wait
                                          for the process to initiate
                                    </div> */}
                                      </TableCell>
                                    ) : this.state.loading ? (
                                      <TableCell
                                        colSpan={15}
                                        style={{ textAlign: "center" }}
                                      >
                                        <LinearProgress />
                                      </TableCell>
                                    ) : null}
                                  </TableRow>
                                )}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </div>
                  <Divider
                    style={{
                      marginTop: "25px",
                    }}
                  />
                  <div className="button-wrapper" style={{ marginTop: ".5em" }}>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={(e) =>
                          this.props.history.push("/backLabelDocketPosting")
                        }
                      // onClick={this.onCancelClick}
                      >
                        CANCEL
                      </Button>
                    </div>
                    {/* <div style={{ marginLeft: "1em" }}>
                    <Button
                      autoFocus
                      variant="contained"
                      onClick={e =>
                        this.props.history.push(
                          "/runMailList/" + workOrder.jobGroupID
                        )
                      }
                      className="enabled-button"
                    >
                      SUBMIT
                    </Button>
                  </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(EditMainLabelDocket));
