import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from "prop-types";
import { StateContext } from "../../../shared/globalState";
import { stableSort, getSorting } from "../../../shared/tableCommonFunctions";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getAllList } from "./action";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 8 * 2,
  },
  closeButton: {
    position: "absolute",
    right: 8 * 1,
    top: 8 * 1,
    color: theme.palette.grey[500],
  },
});

function createData(
  journalID,
  issueDescription,
  acronym,
  journalTitle,
  volume,
  issue,
  supplement
) {
  return {
    journalID,
    issueDescription,
    acronym,
    journalTitle,
    volume,
    issue,
    supplement,
  };
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          {/* <FormControlLabel
            control={
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all collectionNote" }}
              />
            }
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                  style={{ minWidth: i == 2 ? "200px" : "auto" }}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  return <div></div>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default class WorkOrderModal extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    selectedJournal: {
      journalID: "",
      issueDescription: "",
      acronym: "",
      journalTitle: "",
      volume: "",
      issue: "",
      supplement: "",
    },
    searchVal: "",
    searchClicked: false,
    selected: [],
    stateJournal: [],
    deletePopupOpen: false,
    order: "desc",
    orderBy: "workOrderNumber",
    page: 0,
    rowsPerPage: 100,
    rows: [],
    activeRows: 0,
    isActive: false,
    loading: false,
    refreshData: false,
    selectedJournal: {},
    selectedJournalId: 1,
    searchPopupOpen: false,
    journalSearchValues: {},
    selectedDDL: 0,
    journalList: [],
    exportDataValues: [],
    selectedShowColumns: [],
    filteredList: [],
    headCells: [
      {
        id: 1,
        code: "issueDescription",
        numeric: false,
        disablePadding: true,
        label: "Issue Description",
        show: true,
      },
      {
        id: 2,
        code: "acronym",
        numeric: false,
        disablePadding: true,
        label: "Acronym",
        show: true,
      },
      {
        id: 3,
        code: "journalTitle",
        numeric: false,
        disablePadding: true,
        label: "Journal Title",
        show: true,
      },
      {
        id: 4,
        code: "volume",
        numeric: false,
        disablePadding: true,
        label: "Volume",
        show: true,
      },
      {
        id: 5,
        code: "issue",
        numeric: false,
        disablePadding: true,
        label: "Issue",
        show: true,
      },
      {
        id: 6,
        code: "supplement",
        numeric: false,
        disablePadding: true,
        label: "Supplement",
        show: true,
      },
    ],
  };
  componentDidMount() {
    //this.props.setSelectedList("masterDataOpen", "customer");
    const dispatch = this.context[1];
    getAllList().then((r) => {
      dispatch({
        //type: types.CPTCODE_LIST,
        type: "getJobGroup",
        newStore: r.data,
      });

      this.getJournalInformation(r.data);
    });
    this.setState({
      selectedJournal: this.state.journalList,
      filteredList: this.state.journalList,
    });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = async () => {
    await this.setState({
      open: false,
      selectedJournal: {
        journalID: "",
        issueDescription: "",
        acronym: "",
        journalTitle: "",
        volume: "",
        issue: "",
        supplement: "",
      },
      searchVal: "",
      searchClicked: false,
      stateJournal: this.state.journalList,
    });
  };
  handleDialogOpen = () => {
    this.setState({
      selectedJournal: {
        journalID: "",
        issueDescription: "",
        acronym: "",
        journalTitle: "",
        volume: "",
        issue: "",
        supplement: "",
      },
      searchVal: "",
      searchClicked: false,
      stateJournal: this.state.journalList,
    });
  };
  handleSubmit = async () => {
    this.props.handleJournalInfo(this.state.selectedJournal);
    await this.setState({
      open: false,
      selectedJournal: {
        journalID: "",
        issueDescription: "",
        acronym: "",
        journalTitle: "",
        volume: "",
        issue: "",
        supplement: "",
      },
      searchVal: "",
      searchClicked: false,
      stateJournal: this.state.journalList,
    });
    await getAllList().then((r) => {
      this.getJournalInformation(r.data);
    });
  };

  onInputChanged = (e) => {
    this.setState({ searchVal: e.target.value });
  };
  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  clearSearch = () => {
    const dispatch = this.context[1];
    getAllList().then((r) => {
      dispatch({
        //type: types.CPTCODE_LIST,
        type: "getJobGroup",
        newStore: r.data,
      });

      this.getJournalInformation(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  handleSearchJournal = (handleSearchJournal) => {
    const dispatch = this.context[1];
    let journalSearchValue = this.props.selectedJournal;
    let issueDescription = journalSearchValue.issueDescription;
    let acronym = journalSearchValue.acronym;
    let journalTitle = journalSearchValue.journalTitle;
    let volume = journalSearchValue.volume;
    let issue = journalSearchValue.issue;
    let supplement = journalSearchValue.supplement;

    let reqBody = {
      issueDescription,
      acronym,
      journalTitle,
      volume,
      issue,
      supplement,
    };

    console.log(reqBody, "reqBody");
    // getJobGroup(reqBody).then((r) => {
    //   dispatch({
    //     type: "getJobGroup",
    //     newStore: r.data,
    //   });

    //   this.getJournalInformation(r.data);
    //   this.setState({ searchPopupOpen: false, searchClicked: true });
    // });
  };
  getJournalInformation = async (journalList) => {
    let list = journalList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.journalID,
            d.issueDescription,
            d.acronym,
            d.journalTitle,
            d.volume,
            d.issue,
            d.supplement
          )
        );
      });
    await this.setState({
      journalList: rows,
      filteredList: rows,
      loading: false,
    });
  };
  onClearSearch = () => {
    const dispatch = this.context[1];
    getAllList().then((r) => {
      dispatch({
        //type: types.CPTCODE_LIST,
        type: "getJobGroup",
        newStore: r.data,
      });

      this.getJournalInformation(r.data);
    });
    this.setState({
      stateJournal: this.state.journalList,
      searchVal: "",
      searchClicked: false,
      loading: true,
    });
  };
  onSearchClicked = () => {
    let filteredList = [];
    const selectedJournal = this.props.selectedJournal;
    if (this.state.searchVal !== "") {
      filteredList = this.state.journalList.filter((r) => {
        return (
          (r &&
            r.issueDescription &&
            r.issueDescription.toLowerCase() == this.state.searchVal) ||
          (r && r.acronym && r.acronym.toLowerCase() == this.state.searchVal) ||
          (r &&
            r.journalTitle &&
            r.journalTitle.toLowerCase() == this.state.searchVal) ||
          (r && r.volume && r.volume.toLowerCase() == this.state.searchVal) ||
          (r && r.issue && r.issue.toLowerCase() == this.state.searchVal) ||
          (r &&
            r.supplement &&
            r.supplement.toLowerCase() == this.state.searchVal)
        );
      });
    } else {
      filteredList = selectedJournal;
    }
    this.setState({ filteredList: filteredList });
    let se = this.state.stateJournal.filter((n) => {
      if (
        n &&
        n.value &&
        n.value.toLowerCase() &&
        n.value
          .toLowerCase()
          .includes(this.state.searchVal && this.state.searchVal.toLowerCase())
      )
        return n;
    });
    this.setState({ stateJournal: se, searchClicked: true });
  };
  handleClick(event, data) {
    console.log(data, "data");
    let journalID = data.journalID;
    this.setState({ selectedJournal: data });
    let { selected } = this.state;

    const selectedIndex = selected.indexOf(journalID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, journalID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (newSelected.length === 1) {
      //this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedJournal: data,
        selectedJournalId: data.journalID,
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedJournalId: 0,
      });
    }
  }
  render() {
    let { open } = this.state;
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      exportDataValues,
      selectedJournal,
      filteredList,
      journalList,
      headCells,
    } = this.state;
    const isSelected = (journalID) => selected.indexOf(journalID) !== -1;
    if (selected.length > 1) {
      selected.shift();
    }
    return (
      <div>
        <Button
          onClick={this.handleClickOpen}
          variant="contained"
          color="primary"
          size="small"
          style={{
            display: "flex",
            alignItems: "center",
            width: "172px",
            padding: ".2em",
            marginTop: "1em",
            boxShadow: "none",
          }}
        >
          <span>CHOOSE JOURNAL</span>
        </Button>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-journalTitle"
          open={open}
          maxWidth="xl"
        >
          <DialogTitle
            id="customized-dialog-projectTitle"
            onClose={this.handleClose}
          >
            Choose Journal
          </DialogTitle>
          <DialogContent dividers>
            <div
              style={{
                marginBottom: "1em",
                display: "flex",
              }}
            >
              <div
                style={{
                  borderBottom: "1px solid #949191",
                  fontSize: "17px",
                  fontFamily: "Roboto",
                  marginTop: "4px",
                  display: "flex",
                }}
              ></div>
              <TextField
                value={this.state.searchVal}
                onChange={this.onInputChanged}
                style={{ width: "100%" }}
                placeholder="Search Issue Description, Acronym, Journal Title, Volume, Issue, Supplement"
                InputProps={{
                  endAdornment: !this.state.searchClicked ? (
                    <InputAdornment onClick={(e) => this.onSearchClicked()}>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <InputAdornment onClick={this.onClearSearch}>
                      <IconButton>
                        <HighlightOffIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <EnhancedTableToolbar
              numSelected={selected.length}
              history={this.props.history}
              selected={this.state.selected}
              deletePopupOpen={this.state.deletePopupOpen}
              handleDeletePopupOpen={this.handleDeletePopupOpen}
              handleDeletePopupClose={this.handleDeletePopupClose}
              handleDeleteJournal={this.handleDeleteJournal}
              selectedJournal={selectedJournal}
              selectedJournalId={this.state.selectedJournalId}
              clearSearch={this.clearSearch}
              searchPopupOpen={this.state.searchPopupOpen}
              handleSearchPopupOpen={this.handleSearchPopupOpen}
              handleSearchPopupClose={this.handleSearchPopupClose}
              handleSearchJournal={this.handleSearchJournal}
              journalSearchValue={this.state.journalSearchValues}
              searchClicked={this.state.searchClicked}
              hendleVewColums={this.hendleVewColums}
              headCells={headCells}
              journalList={journalList}
              exportDataValues={exportDataValues}
              isActive={this.state.isActive}
            />
            <div
              style={{
                width: "100%",
                height: "300px",
                border: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
              <div
                style={
                  !this.props.clearOpenList
                    ? {
                        overflow: "auto",
                        //maxWidth: "calc(100vw - 8em)",
                        maxHeight: 300,
                      }
                    : {
                        overflow: "auto",
                        //maxWidth: "calc(100vw - 330px)",
                        maxHeight: 300,
                      }
                }
              >
                <Table
                  stickyHeader
                  aria-labelledby="tableTitle"
                  size="small"
                  aria-label="enhanced table"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                    onRequestSort={this.handleRequestSort}
                    rowCount={this.state.activeRows.length}
                    headCells={this.state.headCells}
                  />
                  <TableBody style={{ overflow: "hidden" }}>
                    {filteredList && filteredList.length > 0 ? (
                      stableSort(filteredList, getSorting(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.journalID);
                          return (
                            <TableRow
                              hover
                              onClick={(event) => this.handleClick(event, row)}
                              WorkOrder="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.journalID}
                              selected={isItemSelected}
                              style={{ cursor: "pointer" }}
                            >
                              <TableCell padding="checkbox">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      checked={isItemSelected}
                                    />
                                  }
                                />
                              </TableCell>
                              {this.state.headCells[0].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-cell"
                                >
                                  {row.issueDescription &&
                                  row.issueDescription.length > 12 ? (
                                    <Tooltip title={row.issueDescription}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.issueDescription}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issueDescription}
                                    </Typography>
                                  )}
                                </TableCell>
                              )}
                              {this.state.headCells[1].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-cell"
                                >
                                  {row.acronym && row.acronym.length > 12 ? (
                                    <Tooltip title={row.acronym}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.acronym}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.acronym}
                                    </Typography>
                                  )}
                                </TableCell>
                              )}
                              {this.state.headCells[2].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-cell"
                                >
                                  {row.journalTitle &&
                                  row.journalTitle.length > 12 ? (
                                    <Tooltip title={row.journalTitle}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.journalTitle}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.journalTitle}
                                    </Typography>
                                  )}
                                </TableCell>
                              )}
                              {this.state.headCells[3].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-cell"
                                >
                                  {row.volume && row.volume.length > 12 ? (
                                    <Tooltip title={row.volume}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.volume}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.volume}
                                    </Typography>
                                  )}
                                </TableCell>
                              )}
                              {this.state.headCells[3].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-cell"
                                >
                                  {row.issue && row.issue.length > 12 ? (
                                    <Tooltip title={row.issue}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.issue}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issue}
                                    </Typography>
                                  )}
                                </TableCell>
                              )}
                              {this.state.headCells[3].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-cell"
                                >
                                  {row.supplement &&
                                  row.supplement.length > 12 ? (
                                    <Tooltip title={row.supplement}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.supplement}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.supplement}
                                    </Typography>
                                  )}
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell
                            colSpan={7}
                            style={{ textAlign: "center" }}
                          >
                            <LinearProgress />
                          </TableCell>
                        ) : (
                          <TableCell
                            colSpan={7}
                            style={{ textAlign: "center" }}
                          >
                            No result found
                          </TableCell>
                        )}
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
            <TablePagination
              rowsPerPageOptions={[100, 200, 300]}
              component="div"
              count={journalList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </DialogContent>
          <DialogActions style={{ marginLeft: "1.2em" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={this.handleClose}
            >
              CANCEL
            </Button>
            {selected.length > 0 ? (
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.handleSubmit()}
              >
                Submit
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.handleSubmit()}
              >
                Submit
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
