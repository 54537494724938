import React, { Component } from "react";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { TextField, Button, Paper, Divider, Link } from "@material-ui/core";
import { stableSort, getSorting, printDetails } from "../../../shared/tableCommonFunctions";
import { addDispatchStyles } from "../../../shared/styles";
import { withStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import moment from "moment";

import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";

import { blue } from "@material-ui/core/colors";
import { withRouter, } from "react-router-dom";
import { serchBPAOrder, serchBPAOrderByID, getBpaFileDownload } from "./action";


const headCells2 = [
  {
    id: 1,
    code: "fileName",
    show: true,
    numeric: false,
    disablePadding: true,
    label: "File Name",
    width: "70%",
  },
  {
    id: 2,
    code: "size",
    show: true,
    numeric: false,
    disablePadding: true,
    label: "size",
  },
  {
    id: 3,
    code: "action",
    show: true,
    numeric: false,
    disablePadding: true,
    label: "Action",
  },
];
const headCells1 = [
  {
    id: 1,
    code: "orderID",
    show: true,
    numeric: false,
    disablePadding: true,
    label: "Order ID",
  },
  {
    id: 2,
    code: "itemDescription",
    show: true,
    numeric: false,
    disablePadding: true,
    label: "Issue Description",
  },
  {
    id: 3,
    code: "journalTitle",
    show: true,
    numeric: false,
    disablePadding: true,
    label: "Order ID",
  },
  {
    id: 4,
    code: "acronym",
    show: true,
    numeric: false,
    disablePadding: true,
    label: "Acr",
  },
  {
    id: 5,
    code: "volume",
    show: true,
    numeric: false,
    disablePadding: true,
    label: "Vol",
  },
  {
    id: 6,
    code: "issue",
    show: true,
    numeric: false,
    disablePadding: true,
    label: "Iss",
  },
  {
    id: 7,
    code: "supplement",
    show: true,
    numeric: false,
    disablePadding: true,
    label: "Supp",
  },
  // {
  //   id: 7,
  //   code: "partNumber",
  //   show: true,
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Part Number",
  // },
  {
    id: 8,
    code: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Qty",
    show: true,
    width: "82px",
  },
  {
    id: 9,
    code: "trackingNumber",
    numeric: false,
    disablePadding: false,
    label: "Tracking No",
    show: true,
    width: "230px",
  },
  {
    id: 9.5,
    code: "noOfCartons",
    numeric: false,
    disablePadding: false,
    label: "No Of Cartons",
    show: true,
    width: "230px",
  },
  {
    id: 10,
    code: "actualWeight",
    numeric: false,
    disablePadding: false,
    label: "Act. Weight",
    show: true,
    width: "116px",
  },
  {
    id: 11,
    code: "deliveryDate",
    numeric: false,
    disablePadding: false,
    label: "Delivery Date",
    show: true,
    width: "207px",
  },
  {
    id: 12,
    code: "dhlRate",
    numeric: false,
    disablePadding: false,
    label: "DHL Rate",
    show: true,
    width: "207px",
  },
];
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { selectedstockId } = props;
  //   let acnArr = props.roles.filter((u) => u.menuName === "Code Type");
  //   let acnObj = acnArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {
            <Tooltip
              title="Print"
              style={{ padding: "11px 5px 4px 12px", margin: "7px 15px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          }
        </div>
      </div>
    </div>
  );
};
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={
                  headCell.label == "Action"
                    ? "center"
                    : headCell.numeric
                      ? "right"
                      : "left"
                }
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
                style={{ width: headCell.width }}
              >
                {/* <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                > */}
                <span style={{ color: "#306dca" }}>{headCell.label}</span>
                {/* </TableSortLabel> */}
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}
function EnhancedTableHead2(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={
                  headCell.label == "Action"
                    ? "center"
                    : headCell.numeric
                      ? "right"
                      : "left"
                }
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
                style={{ width: headCell.width }}
              >
                {/* <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                > */}
                <span style={{ color: "#306dca" }}>{headCell.label}</span>
                {/* </TableSortLabel> */}
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

class ViewBpaOrderPlacement extends React.Component {
  state = {
    viewBPAOrder: {
      actualWeight: null,
      address: "",
      address2: "",
      bpaCode: "",
      bpaOrderFileResponse: [],
      bpaOrderHeaderID: "",
      bpaRecipientID: "",
      bpaRunningID: "",
      bpaSuffix: null,
      companyName: "",
      conferenceName: "",
      conferenceRef: "",
      contactPersonName: "",
      costCoding: "",
      countryID: "",
      countryName: "",
      createdBy: "",
      createdByName: "",
      createdDate: "",
      deliveredDate: null,
      deliveryInstructions: "",
      deliveryMethodID: "",
      deliveryMethodName: null,
      department: "",
      doNotDeliveryBefore: null,
      emailID: "",
      isActive: null,
      modifiedBy: "",
      modifiedByName: "",
      modifiedDate: "",
      mustBeDeliveryBy: "",
      mustBeDeliveryOn: "",
      orderDate: "",
      packingInstructions: "",
      phoneNumber: "",
      postalCode: "",
      remarks: "",
      viewBPAOrderDetailModel: [],
      trackingNumber: null,
    },
    bpaOrderDetails: [],
    page: 0,
    rowsPerPage: 100,
    order: "desc",
    orderBy: "modifiedDate",
  };

  componentDidMount() {
    this.props.handleSettingHeader("Order Placement");
    this.props.setSelectedList("bpaMarketing", "bpaOrderPlacement");
    const dispatch = this.context[1];

    let BPAOrderHeaderID =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];

    serchBPAOrderByID(BPAOrderHeaderID).then((r) => {
      let code = r.data.bpaCode;
      let newList = { ...r.data };
      let newData = r.data.viewBPAOrderDetailModel.map((r) => {
        return { ...r, code };
      });
      let final = { ...newList, viewBPAOrderDetailModel: newData };
      this.setState({ viewBPAOrder: final });
    });
  }
  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };
  getExcelFileData = (a) => {
    // console.log(a)
    // getBpaFileDownload(a.bpaAttatchDetailID).then(r => {
    //   console.log(r)
    //   this.setState({ filoeDownloadUrl: r })
    // })
  }
  render() {
    const { rowsPerPage, page, orderBy, order, bpaOrderDetails } = this.state;
    let id =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];
    return (
      <React.Fragment>
        {/* {this.state.filoeDownloadUrl && <a target="_black" href={this.state.filoeDownloadUrl}></a>} */}
        <Paper>
          <EnhancedTableToolbar
            id={id}
            selectedstockId={id}
            roles={this.props.roles}
          />
          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">BPA Order Placement</div>
            {/* <Divider /> */}
            <div>
              <div>
                <p
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    fontWeight: "normal",
                    paddingTop: "1em",
                    marginBottom: "1em",
                    borderBottom: "1px solid #c1baba",
                    paddingBottom: "0.5em",
                  }}
                >
                  Recipient Information
                </p>
                <div className="view-page-contents-container">
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Order ID</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.bpaCode}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Company Name</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.companyName}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Department</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.department}
                    </div>
                  </div>{" "}
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Postal Code</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.postalCode}
                    </div>
                  </div>
                </div>
                <div className="view-page-contents-container">
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Conference Name</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.conferenceName}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Cost Coding</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.costCoding}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Country</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.countryName}
                    </div>
                  </div>
                </div>
                <div className="view-page-contents-container">
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "50%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Address1</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.address}
                    </div>
                  </div>
                </div>
                <div className="view-page-contents-container">
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Address2</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.address2}
                    </div>
                  </div>
                </div>
                <div className="view-page-contents-container">
                  <div className="view-page-contents-container"></div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Contact Person Name</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.contactPersonName}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Phone Number</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.phoneNumber}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Conference Ref</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.conferenceRef}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Email ID</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.emailID}
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    fontWeight: "normal",
                    paddingTop: "1em",
                    marginBottom: "1em",
                    borderBottom: "1px solid #c1baba",
                    paddingBottom: "0.5em",
                  }}
                >
                  Delivery Information
                </p>
                <div className="view-page-contents-container">
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Delivery Method</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.deliveryMethodName}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Delivery Instructions</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.deliveryInstructions}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Remarks</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.remarks}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Packing Instructions</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.packingInstructions}
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    fontWeight: "normal",
                    paddingTop: "1em",
                    marginBottom: "1em",
                    borderBottom: "1px solid #c1baba",
                    paddingBottom: "0.5em",
                  }}
                >
                  Timed Delivery
                </p>
                <div className="view-page-contents-container">
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Do Not Deliver Before</div>
                    <div className="view-page-value">

                      {this.state.viewBPAOrder.doNotDeliveryBefore === null
                        ? null
                        : moment(
                          this.state.viewBPAOrder.doNotDeliveryBefore
                        ).format("DD/MM/YYYY")}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Must Deliver By</div>
                    <div className="view-page-value">

                      {this.state.viewBPAOrder.mustBeDeliveryBy === null
                        ? null
                        : moment(
                          this.state.viewBPAOrder.mustBeDeliveryBy
                        ).format("DD/MM/YYYY")}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Must Be Delivered On</div>
                    <div className="view-page-value">
                      {this.state.viewBPAOrder.mustBeDeliveryOn === null
                        ? null
                        : moment(
                          this.state.viewBPAOrder.mustBeDeliveryOn
                        ).format("DD/MM/YYYY")}
                    </div>
                  </div>
                </div>
              </div>

              <p
                style={{
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  fontWeight: "normal",
                  paddingTop: "1em",
                  marginBottom: "1em",
                  borderBottom: "1px solid #c1baba",
                  paddingBottom: "0.5em",
                }}
              >
                File Details
              </p>
              <div
                style={{
                  // width: "100%",
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
                className="view-page-contents-container"
              >
                <Table
                  stickyHeader
                  aria-labelledby="tableTitle"
                  size="small"
                  aria-label="enhanced table"
                  style={{
                    border: "1px solid  rgba(224, 224, 224, 1)",
                    whiteSpace: "nowrap",
                  }}
                >
                  <EnhancedTableHead2
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    headCells={headCells2}
                  />
                  <TableBody style={{ overflow: "hidden" }}>
                    {this.state.viewBPAOrder.bpaOrderFileResponse.length >
                      0 ? (
                        stableSort(
                          this.state.viewBPAOrder.bpaOrderFileResponse,
                          getSorting(order, orderBy)
                        )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            return (
                              <TableRow
                                // hover
                                tabIndex={-1}

                              >
                                {headCells1[0].show && (
                                  <TableCell
                                    padding="default"
                                    className="table-content-cell"
                                  >
                                    {row.fileName &&
                                      row.fileName.length > 16 ? (
                                        <Tooltip title={row.fileName}>
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.fileName}
                                          </Typography>
                                        </Tooltip>
                                      ) : (
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.fileName}
                                        </Typography>
                                      )}
                                  </TableCell>
                                )}
                                {headCells1[1].show && (
                                  <TableCell
                                    padding="default"
                                    className="table-content-cell"
                                  >
                                    {row.size ? row.size : ""}
                                  </TableCell>
                                )}
                                {headCells1[2].show && (
                                  <TableCell
                                    padding="default"
                                    className="table-content-cell"
                                    align="center"
                                  >
                                    <Link target="_blank" href={`${process.env.REACT_APP_PGS_SERVER_PUBLISHER_RECIPIENT_LIST}/Download/${row.bpaAttatchDetailID}`}>
                                      <GetAppIcon
                                        style={{
                                          fontSize: "18px",
                                          color: "#646060",
                                          cursor: "pointer",
                                        }}
                                      // onClick={(e) =>
                                      //   this.getExcelFileData(
                                      //     row
                                      //   )
                                      // }
                                      />
                                    </Link>
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={10}
                            style={{ textAlign: "center" }}
                          >
                            No Data
                        </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </div>

              <p
                style={{
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  fontWeight: "normal",
                  paddingTop: "1em",
                  marginBottom: "1em",
                  borderBottom: "1px solid #c1baba",
                  paddingBottom: "0.5em",
                }}
              >
                Item Description
              </p>

              <div
                // style={{
                //   maxWidth: "calc(100vw - 394px)",
                //   maxHeight: "300px",
                //   overflowY: "auto",
                //   boxShadow: "0px 3px 4px #ddd",
                // }}
                style={
                  !this.props.clearOpenList
                    ? {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 158px)",
                      maxHeight: "calc(100vh - 300px)",
                      // margin: "0 .8em 0 .6em"
                    }
                    : {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 400px)",
                      maxHeight: "calc(100vh - 300px)",
                      // margin: "0 .8em 0 .6em"
                    }
                }
                className="view-page-contents-container"
              >
                <Table
                  stickyHeader
                  aria-labelledby="tableTitle"
                  size="small"
                  aria-label="enhanced table"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    headCells={headCells1}
                  />
                  <TableBody style={{ overflow: "hidden" }}>
                    {this.state.viewBPAOrder.viewBPAOrderDetailModel.length >
                      0 ? (
                        stableSort(
                          this.state.viewBPAOrder.viewBPAOrderDetailModel,
                          getSorting(order, orderBy)
                        )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            return (
                              <TableRow
                                hover
                                tabIndex={-1}
                                style={{ cursor: "pointer" }}
                              >
                                {headCells1[0].show && (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                    style={{ width: "auto", minWidth: "150px" }}
                                  // align="left"
                                  >
                                    {row.code ? row.code : ""}
                                  </TableCell>
                                )}
                                {headCells1[1].show && (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                  // align="right"
                                  >
                                    {row.issueDecription
                                      ? row.issueDecription
                                      : ""}
                                  </TableCell>
                                )}
                                {headCells1[2].show && (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                    align="left"
                                  >
                                    {row.journalTitle ? row.journalTitle : ""}
                                  </TableCell>
                                )}
                                {headCells1[3].show && (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                    align="left"
                                  >
                                    {row.acronym ? row.acronym : ""}
                                  </TableCell>
                                )}
                                {headCells1[4].show && (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                  //align="right"
                                  >
                                    {row.volume ? row.volume : ""}
                                  </TableCell>
                                )}
                                {headCells1[5].show && (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                  //align="right"
                                  >
                                    {row.issue ? row.issue : ""}
                                  </TableCell>
                                )}
                                {headCells1[6].show && (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                  // align="right"
                                  >
                                    {row.suppNumber ? row.suppNumber : ""}
                                  </TableCell>
                                )}
                                {/* {headCells1[6].show && (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="default"
                                  className="table-content-right-cell"
                                  align="right"
                                >
                                  {row.partNumber ? row.partNumber : ""}
                                </TableCell>
                              )} */}
                                {headCells1[7].show && (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    className="table-content-right-cell"
                                    align="right"
                                  >
                                    {row.orderQuantity ? row.orderQuantity : ""}
                                  </TableCell>
                                )}
                                {headCells1[8].show && (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                  // align="right"
                                  >
                                    <a
                                      href={`https://www.dhl.co.in/en/express/tracking.html?AWB=${row.trackingNumber}&brand=DHL`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {row.trackingNumber
                                        ? row.trackingNumber
                                        : ""}
                                    </a>
                                  </TableCell>
                                )}
                                {headCells1[9].show && (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    className="table-content-right-cell"
                                    align="right"
                                  >
                                    {row.noOfCartons ? row.noOfCartons : ""}
                                  </TableCell>
                                )}
                                {headCells1[10].show && (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    className="table-content-right-cell"
                                    align="right"
                                  >
                                    {row.actualWeight && row.actualWeight
                                      ? parseFloat(
                                        row.actualWeight
                                      ).toFixed(3)
                                      : "0.000"}
                                  </TableCell>
                                )}
                                {headCells1[11].show && (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    className="table-content-cell"
                                    align="left"
                                  >
                                    {row.delievredDate
                                      ? moment(row.delievredDate).format(
                                        "DD/MM/YYYY"
                                      )
                                      : ""}
                                  </TableCell>
                                )}
                                {headCells1[12].show && (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="default"
                                    className="table-content-right-cell"
                                    align="right"
                                  >
                                    {row.dhlRate ? row.dhlRate : ""}
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={10}
                            style={{ textAlign: "center" }}
                          >
                            No Data
                        </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </div>
              <Divider style={{ marginTop: "25px" }} />
            </div>
          </div>
          <div className="button-wrapper" style={{ padding: "0 2em 1em 2em" }}>
            <div>
              <Button
                variant="contained"
                color="primary"
                //disableElevation
                style={{ boxShadow: "none" }}
                onClick={(e) => this.props.history.push("/bpaOrderPlacement")}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(addDispatchStyles)(ViewBpaOrderPlacement));
