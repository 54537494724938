import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import { StateContext } from "../../../shared/globalState";
import { printDetails } from "../../../shared/tableCommonFunctions";
import {
  getAcronymDetails,
  getProcessMasterList,
  viewProcess,
} from "../masterActions";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
});

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  //console.log(props);
  const { selectedProcessMasterID } = props;
  //console.log(selectedProcessMasterID);
  let acnArr = props.roles.filter((u) => u.menuName === "Process Master");
  let acnObj = acnArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_Process/${selectedProcessMasterID}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewProcessMaster extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      processMaster: {
        processCategoryID: "",
        categoryCode: "",
        categoryName: "",
        processName: "",
        processDescription: "",
        isWOProcess: "", // check
        acct: "",
        pic: "",
        rc: "",
        gstType: "", // check
        uomName: "",
        price: "",
        outWorkerClaimRate: "",
        isActive: "", // check,
        selectedProcessMasterID: "",
      },
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let processMasterID = this.props.history.location.pathname.split("/")[2];

    viewProcess(processMasterID).then((r) => {
      dispatch({
        type: "getProcessMaster",
        newStore: r.data,
      });
      this.setState({ processMaster: r.data });
    });
  }

  render() {
    let { classes } = this.props;
    let { processMaster } = this.state;
    let processMasterID = this.props.history.location.pathname.split("/")[2];
    return (
      <React.Fragment>
        <Paper>
          <div className={classes.container}>
            <EnhancedTableToolbar
              selectedProcessMasterID={processMasterID}
              roles={this.props.roles}
            />
            <Divider />
            <div className="view-page-container" id="printable">
              <div className="view-page-headings">Process Information</div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Category Name</div>
                  <div className="view-page-value">
                    {processMaster.categoryName}
                  </div>
                </div>
                <div className="view-page-contents-sub-container" style={{ marginRight: "1em" }}>
                  <div className="view-page-label">Process Name</div>
                  <div className="view-page-value">
                    {processMaster.processName}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Process Description</div>
                  <div className="view-page-value">
                    {processMaster.processDescription}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">ACCT</div>
                  <div className="view-page-value">{processMaster.acct}</div>
                </div>
                <div className="view-page-contents-sub-container" style={{ marginRight: "1em" }}>
                  <div className="view-page-label">PIC</div>
                  <div className="view-page-value">{processMaster.pic}</div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">RC </div>
                  <div className="view-page-value">{processMaster.rc}</div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">UOM Name </div>
                  <div className="view-page-value">{processMaster.uomName}</div>
                </div>
                <div className="view-page-contents-sub-container" style={{ marginRight: "1em" }}>
                  <div className="view-page-label">Price</div>
                  <div className="view-page-value">{processMaster.price}</div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Out Worker ClaimRate</div>
                  <div className="view-page-value">
                    {processMaster.outWorkerClaimRate}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Customised</div>
                  <div className="view-page-value">
                    {processMaster.isWOProcess === true ? "Yes" : "No"}
                  </div>
                </div>
                <div className="view-page-contents-sub-container" style={{ marginRight: "1em" }}>
                  <div className="view-page-label">GST Type</div>
                  <div className="view-page-value">
                    {processMaster.gstType === true ? "Yes" : "No"}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Active</div>
                  <div className="view-page-value">
                    {processMaster.isActive === true ? "Yes" : "No"}
                  </div>
                </div>
              </div>

              <Divider style={{ marginTop: "25px" }} />
              <div className="button-wrapper">
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    //disableElevation
                    style={{ boxShadow: "none" }}
                    onClick={(e) => this.props.history.push("/processMaster")}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(ViewProcessMaster));
