import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { StateContext } from "../../shared/globalState";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { styles } from "../../shared/styles";

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
// test
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class ReportViewer extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
  };
  handleClose = () => {
    if (this.props.showPopup === true) {
      this.props.handleHidePopup();
    }
    if (this.props.showPopup1 === true) {
      this.props.handleHidePopup1();
    }

    this.setState({
      open: false,
    });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.props.showPopup || this.props.showPopup1}
            maxWidth="lg"
            onClick={this.handleClickOpen}
          >
            <DialogTitle
              id="reportViewerId"
              style={{ paddingLeft: "25px", paddingBottom: "10px" }}
              onClose={this.handleClose}
            >
              {this.props.title}
            </DialogTitle>
            <DialogContent dividers>
              <React.Fragment>
                <iframe
                  style={{
                    width: "calc(75vw)",
                    height: "calc(100vh - 190px)",
                    // display: "none"
                  }}
                  src={this.props.url} //change the 19 with stock indent id
                />
              </React.Fragment>
            </DialogContent>
          </Dialog>
        </div>
      </form>
    );
  }
}
export default withStyles(styles)(ReportViewer);
