import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import { getCodeType, updateCodeID } from "../masterActions";
import Validator from "../../../shared/validator";
import { Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { resourceValidation } from "../../../shared/resource";
import { addStyles } from "../../../shared/styles";
// Include new StateContext
import { StateContext } from "../../../shared/globalState";
const maxLenghtAlpNumHypDot50 = new Validator("maxLenghtAlpNumHypDot50");
const maxlength50 = new Validator("maxlength50");

class EditCodeType extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      codeTypeCode: {
        codeTypeID: "",
        codeType: "",
        codeTypeDescription: "",
        canAddNew: "",
        canEditStartDate: "",
        canEditValue: "",
        serviceSpecific: "",
        codevalueDataType: "",
        isActive: true,
        modifiedBy: 1,
        updatedValues: false
      },
      errors: {},
      active: false,
      codeTypeValid: true,
      codeTypeDescriptionValid: true,
      canAddNewValid: true,
      canEditStartDateValid: true,
      canEditValueValid: true,
      serviceSpecificValid: true,
      codevalueDataTypeValid: true,
      formValid: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Code Type");
    this.props.setSelectedList("masterDataOpen", "codeType");
    let codeTypeId =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];

    getCodeType(codeTypeId).then(r => {
      dispatch({
        type: "getCodeType",
        newStore: r
      });
      this.setState({ codeTypeCode: r });
      if (r.isActive) this.setState({ active: r.isActive });
      this.validateField("codeType", r.codeType);
      this.validateField("codeTypeDescription", r.codeTypeDescription);
      this.validateField("codevalueDataType", r.codevalueDataType);
    });
  }

  handleChange = event => {
    const { codeTypeCode } = this.state;
    this.setState({ updatedValues: true });

    if (
      event.target.name === "isActive" ||
      event.target.name === "canAddNew" ||
      event.target.name === "canEditStartDate" ||
      event.target.name === "canEditValue" ||
      event.target.name === "serviceSpecific"
    ) {
      codeTypeCode[event.target.name] = event.target.checked;
    } else {
      codeTypeCode[event.target.name] = event.target.value;
    }

    this.validateField(event.target.name, event.target.value);
    this.setState({ codeTypeCode });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;

    let codeTypeValid = this.state.codeTypeValid;
    let codeTypeDescriptionValid = this.state.codeTypeDescriptionValid;
    let codevalueDataTypeValid = this.state.codevalueDataTypeValid;

    switch (fieldName) {
      case "codeType":
        codeTypeValid = maxLenghtAlpNumHypDot50(value);
        fieldValidationErrors.codeType = codeTypeValid
          ? ""
          : "Please enter valid code type";
        break;
      case "codeTypeDescription":
        // acronymNumberValid = maxlengthValidatorcharnum10(value);
        codeTypeDescriptionValid = maxlength50(true);
        fieldValidationErrors.codeTypeDescription = codeTypeDescriptionValid
          ? ""
          : "";
        break;
      case "codevalueDataType":
        //specialInstructionValid = notRequired1000(value);
        codevalueDataTypeValid = true;
        fieldValidationErrors.codevalueDataType = codevalueDataTypeValid
          ? ""
          : " Please enter alphabet characters only";
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        codeTypeValid: codeTypeValid,
        codeTypeDescriptionValid: codeTypeDescriptionValid,
        // canAddNewValid: canAddNewValid,
        // canEditStartDateValid: canEditStartDateValid,
        // canEditValueValid: canEditValueValid,
        // serviceSpecificValid: serviceSpecificValid,
        codevalueDataTypeValid: codevalueDataTypeValid
      },
      this.validateForm
    );
  }

  validateForm() {
    this.state.updatedValues &&
      this.setState({
        formValid:
          this.state.codeTypeValid &&
          this.state.codeTypeDescriptionValid &&
          // this.state.canAddNewValid &&
          // this.state.canEditStartDateValid &&
          // this.state.canEditValueValid &&
          // this.state.serviceSpecificValid &&
          this.state.codevalueDataTypeValid
      });
    return this.state.formValid;
  }

  _onKeyPress(event) {
    const re = /[0-9a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onAlphaKeyPress(event) {
    const re = /[a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onDimensionKeyPress(event) {
    const re = /[0-9*]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.validateForm()) {
      const { codeTypeCode } = this.state;

      let codeTypeIDs =
        this.props &&
        this.props.location &&
        this.props.location.pathname &&
        this.props.location.pathname.split("/")[2];

      let codeTypeID = codeTypeIDs;
      let codeType = codeTypeCode.codeType;
      let codeTypeDescription = codeTypeCode.codeTypeDescription;
      let canAddNew = codeTypeCode.canAddNew;
      let canEditStartDate = codeTypeCode.canEditStartDate;
      let canEditValue = codeTypeCode.canEditValue;
      let serviceSpecific = codeTypeCode.serviceSpecific;
      let codevalueDataType = codeTypeCode.codevalueDataType;
      let isActive = codeTypeCode.isActive;

      let reqBody = {
        codeType,
        codeTypeDescription,
        canAddNew,
        canEditStartDate,
        canEditValue,
        serviceSpecific,
        codevalueDataType,
        isActive
      };
      reqBody.modifiedBy = localStorage.loggedInId;

      updateCodeID(codeTypeID, reqBody, this.props.history);
    }
  }

  render() {
    let { classes } = this.props;
    let { codeTypeCode, active } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Code Type</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <TextField
                    disabled={true}
                    required
                    label="Code Type"
                    name="codeType"
                    inputProps={{
                      maxLength: 6
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={codeTypeCode.codeType}
                    onChange={this.handleChange}
                    error={this.state.errors.codeType}
                    helperText={
                      this.state.errors.codeType
                        ? resourceValidation.max10char
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    disabled={true}
                    required
                    multiline={false}
                    label="Code Type Description"
                    name="codeTypeDescription"
                    inputProps={{
                      maxLength: 100
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={codeTypeCode.codeTypeDescription}
                    onChange={this.handleChange}
                    error={this.state.errors.codeTypeDescription}
                    helperText={
                      this.state.errors.codeTypeDescription
                        ? resourceValidation.max1000char
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  {/* <TextField
                    label="Code Value Data Type"
                    name="codevalueDataType"
                    inputProps={{
                      maxLength: 10
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={codeTypeCode.codevalueDataType}
                    onChange={this.handleChange}
                    error={this.state.errors.codevalueDataType}
                    helperText={
                      this.state.errors.codevalueDataType
                        ? resourceValidation.max1000char
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  /> */}
                </div>
                <div>
                  <FormControlLabel
                    className={classes.checkBox}
                    name="canAddNew"
                    control={
                      <Checkbox
                        color="primary"
                        checked={codeTypeCode.canAddNew}
                        value={codeTypeCode.canAddNew}
                        onChange={this.handleChange}
                      />
                    }
                    label="Add New"
                  />
                  <FormControlLabel
                    className={classes.checkBox}
                    margin="normal"
                    name="canEditValue"
                    control={
                      <Checkbox
                        color="primary"
                        checked={codeTypeCode.canEditValue}
                        value={codeTypeCode.canEditValue}
                        onChange={this.handleChange}
                      />
                    }
                    label="Edit Value"
                  />

                  <FormControlLabel
                    className={classes.checkBox}
                    margin="normal"
                    name="isActive"
                    control={
                      <Checkbox
                        disabled={true}
                        color="primary"
                        checked={codeTypeCode.isActive}
                        value={codeTypeCode.isActive}
                        onChange={this.handleChange}
                      />
                    }
                    label="Active"
                  />
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={e => this.props.history.push("/codeType")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        onClick={this.handleSubmit}
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditCodeType));
