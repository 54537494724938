import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { Autocomplete } from "@material-ui/lab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import {
  getCustomerList,
  getCountryList,
  getCustomerDDList,
  getCountryDropdownList,
  getRoutingCustomerList,
} from "../../masters/masterActions";
import { postRoutingZone } from "../routingActions";
import { StateContext } from "../../../shared/globalState";
import { Checkbox } from "@material-ui/core";
import { addStyles } from "../../../shared/styles";
const onlyNumbers = new Validator("onlyNumbers");

class AddRoutingZoneCountry extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      routingZone: {
        customerID: "",
        countryID: "",
        isByZone: "",
        isActive: "",
        country: "",
        customer: "",
      },
      errors: {},

      countryValid: false,
      customerValid: false,
      countryList: [],
      customersList: [],
    };
    this.state.routingZone.isByZone = true;
    this.state.routingZone.isActive = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    getRoutingCustomerList().then((r) => {
      //console.log(r);
      r.data &&
        this.setState({
          customersList: r.data
            //.filter(x => x.isActive === true)
            .sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
    });
    // getCountryList().then(r => {
    //   r.data &&
    //     this.setState({
    //       countryList: r.data
    //         .filter(x => x.isActive === true)
    //         .sort((a, b) =>
    //           a.countryName.toLowerCase() > b.countryName.toLowerCase() ? 1 : -1
    //         )
    //     });
    // });
    getCountryDropdownList().then((r) => {
      // let newCountryList = [];
      // r.data &&
      //   r.data.map(d => {
      //     newCountryList.push({
      //       countryID: d.countryID,
      //       countryCode: d.countryCode,
      //       countryName: d.countryCode + " - " + d.countryName,
      //       zoneID: d.zoneID,
      //       zoneName: d.zoneName,
      //       zoneCode: d.zoneCode,
      //       dhlZoneID: d.zoneCode,
      //       dhlZoneName: d.zoneCode,
      //       dhlZoneCode: d.dhlZoneCode,
      //       alternateCountryCode: d.alternateCountryCode,
      //       alternateCountryName: d.alternateCountryName,
      //       isActive: d.isActive,
      //       createdByID: d.createdByID,
      //       createdBy: d.createdBy,
      //       createdDate: d.createdDate,
      //       modifiedByID: d.modifiedByID,
      //       modifiedBy: d.modifiedBy,
      //       modifiedDate: d.modifiedDate
      //     });
      //   });

      r.data &&
        this.setState({
          countryList: r.data
            //.filter(x => x.isActive === true)
            .sort((a, b) =>
              //a.countryName.toLowerCase() > b.countryName.toLowerCase() ? 1 : -1
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
    });

    this.props.handleSettingHeader("Country Zone Grouping");
  }
  handleCustomer = (event, values) => {
    event.persist();

    let name = values && values.text;
    let customerID = values && values.id;
    const { routingZone } = this.state;
    routingZone["customer"] = name;
    routingZone["customerID"] = customerID;
    this.validateField("customer", customerID);
    this.setState({ routingZone });
    //console.log(routingZone);
  };
  handleCountry = (event, values) => {
    let name = values && values.text;
    let countryID = values && values.id;
    const { routingZone } = this.state;
    routingZone["country"] = name;
    routingZone["countryID"] = countryID;
    this.validateField("country", countryID);
    this.setState({ routingZone });
  };
  handleChange = (event) => {
    // Actual Code
    // const { routingZone } = this.state;
    // routingZone[event.target.name] = event.target.value;
    // this.setState({ routingZone });

    // this.validateField(event.target.name, event.target.value);

    const { routingZone } = this.state;
    if (event.target.name === "isByZone" || event.target.name === "isActive") {
      routingZone[event.target.name] = event.target.checked;
    } else {
      routingZone[event.target.name] = event.target.value;
    }
    this.setState({ routingZone });

    this.validateField(event.target.name, event.target.value);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    // let cptOfficeTypeValid = this.state.cptOfficeTypeValid;
    // let objectCodeValid = this.state.objectCodeValid;
    // let subCodeValid = this.state.subCodeValid;
    // let cptDescriptionValid = this.state.cptDescriptionValid;
    let customerValid = this.state.customerValid;
    let countryValid = this.state.countryValid;
    switch (fieldName) {
      // case "cptOfficeType":
      //   cptOfficeTypeValid = maxLenghtAlpNumHypDot50(value);
      //   fieldValidationErrors.cptOfficeType = cptOfficeTypeValid
      //     ? ""
      //     : " Please enter alphabet characters only";
      //   break;
      // case "objectCode":
      //   objectCodeValid = maxlengthValidator10WithNum(value);
      //   fieldValidationErrors.objectCode = objectCodeValid
      //     ? ""
      //     : " Please enter alphabet characters only";
      //   break;
      // case "subCode":
      //   subCodeValid = maxlengthValidator10WithNum(value);
      //   fieldValidationErrors.subCode = subCodeValid
      //     ? ""
      //     : " Please enter alphabet characters only";
      //   break;
      // case "cptDescription":
      //   // cptDescriptionValid = maxlengthValidator500(value);
      //   // fieldValidationErrors.cptDescription = cptDescriptionValid ? '' : ' Please enter alphabet characters only';
      //   break;
      case "country":
        countryValid = onlyNumbers(value);
        fieldValidationErrors.country = countryValid
          ? ""
          : " Please select Country";
        break;
      case "customer":
        customerValid = onlyNumbers(value);
        fieldValidationErrors.customer = customerValid
          ? ""
          : " Please select Customer";
        break;
      // case "sapglAccount":
      //   sapglAccountValid = maxlengthValidator20WithNum(value);
      //   fieldValidationErrors.sapglAccount = sapglAccountValid
      //     ? ""
      //     : " Please enter alphabet characters only";
      //   break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        // objectCodeValid: objectCodeValid,
        // cptOfficeTypeValid: cptOfficeTypeValid,
        // subCodeValid: subCodeValid,
        // cptDescriptionValid: cptDescriptionValid,
        customerValid: customerValid,
        countryValid: countryValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.countryValid && this.state.customerValid,
    });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      country: "",
      customer: "",
      isByZone: "",
      isActive: "",

      errors: {},
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      const { routingZone } = this.state;

      let customerID = routingZone.customerID;
      let countryID = routingZone.countryID;
      let isByZone = routingZone.isByZone;
      // let isActive = routingZone.isActive;
      let isActive = "true";
      let createdBy = localStorage.loggedInId;

      let reqBody = {
        customerID,
        countryID,
        isByZone,
        isActive,
        createdBy,
      };
      postRoutingZone(reqBody, this.props.history);
      // pgsService
      //   .post("/RoutingByZoneOrCountrys", reqBody)
      //   .then(r => {
      //     this.handleClose();
      //     toastr.success("Routing By Zone/Country Created Successfully");
      //     this.props.history.push("/routingZone");
      //     //list.refreshData("getAllroutingZones");
      //   })
      //   .catch(e => {
      //     //this.handleClose();
      //     if (e.response.data.errors[0].message != undefined) {
      //       toastr.error(e.response.data.errors[0].message);
      //     } else {
      //       toastr.error(e.response.data.errors[0]);
      //     }
      //   });
    }
  };
  render() {
    let { classes } = this.props;
    let { routingZone, countryList, customersList } = this.state;

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Country Zone Grouping</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    required
                    includeInputInList
                    label="Customer"
                    style={{ display: "inline-flex" }}
                    name="customer"
                    options={customersList}
                    className={classes.comboBox}
                    onChange={this.handleCustomer}
                    value={routingZone.customer}
                    getOptionLabel={(option) =>
                      option.text || routingZone.customer
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Customer"
                        name="customer"
                        style={{ width: "100%" }}
                        margin="normal"
                      />
                    )}
                  />

                  <Autocomplete
                    autoComplete
                    required
                    includeInputInList
                    label="Country"
                    name="country"
                    style={{ display: "inline-flex" }}
                    options={countryList}
                    className={classes.comboBox}
                    onChange={this.handleCountry}
                    value={routingZone.country}
                    getOptionLabel={(option) =>
                      option.text || routingZone.country
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Country"
                        name="country"
                        style={{ width: "100%" }}
                        margin="normal"
                      />
                    )}
                  />

                  {/* <FormControlLabel
                    name="isByZone"
                    className={classes.checkBox}
                    control={
                      <Checkbox
                        color="primary"
                        checked={routingZone.isByZone}
                        onChange={this.handleChange}
                        value={routingZone.isByZone}
                      />
                    }
                    label="Is Zone"
                  /> */}
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/countryZoneGrouping")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                        //className="enabled-button"
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                        //className="enabled-button"
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddRoutingZoneCountry));
