import React, { Component } from "react";
import PopUpSearch from "../../app/poupSearch/popupAndSearch";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { FormControlLabel } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import IndividualSearchAddDialog from "../ebloOrderPlacement/IndividualSearchAddDialog";
import moment from "moment";
import {
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import Divider from "@material-ui/core/Divider";
import {
  getStockTypeDD,
  createStockTransfer,
  getBalanceQty,
  getStockDetailsByJournalID
} from "../stockaction";
import { getWarehouseLocationStockType } from "../../publisherBackLabel/stockaction";
import { getWareLocationDD } from "../../masters/masterActions";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    // marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: "23%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
  textFieldAlign: {
    // marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    // width: "23%",
    fontSize: "11px !important",
    flex: 1,
  },
  emptyField: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
  },
  lastChild: {
    marginRight: 0,
  },
});

class AddBackLabelStockTransfer extends Component {
  state = {
    fLocationId: "",
    fLocation: {},
    fStockType: {},
    fStockTypeId: "",
    tLocationId: "",
    tLocation: {},
    tStockType: {},
    tStockTypeId: "",
    wlArray: [],
    jounalList: {
      acronym: "",
      issue: "",
      issueDescription: "",
      journalID: null,
      journalTitle: "",
      supplement: "",
      volume: "",
      stocktype: "",
      stockTypeID: "",
      locationID: "",
      location: "",
    },
    fromLocation: {
      stocktype: "",
      stockTypeID: "",
      locationID: "",
      location: "",
    },
    sumOfStockOutQty: 0,
    stockTransferValid: false,
    errors: {},
    transferQty: "",
    disableTQ: false,

    balanceQty: "",
    transferDate: new Date(),
    openDialog: false,
    stockTypeList: [],
    stockTypeList2: [],
    stockLocationDD: [],
    stockLocationDD2: [],
    headCellsNew: [
      {
        id: 1,
        code: "issueDescription",
        numeric: false,
        disablePadding: false,
        label: "Issue Description",
        show: true,
        width: "200px",
      },
      {
        id: 2,
        code: "acronym",
        numeric: false,
        disablePadding: false,
        label: "Acronym",
        show: true,
        width: "58px",
      },
      {
        id: 3,
        code: "journalTitle",
        numeric: false,
        disablePadding: false,
        label: "Journal Title",
        show: true,
        width: "200px",
      },
      {
        id: 4,
        code: "volume",
        numeric: false,
        disablePadding: false,
        label: "Volume",
        show: true,
        width: "50px",
      },
      {
        id: 5,
        code: "issue",
        numeric: false,
        disablePadding: false,
        label: "Issue",
        show: true,
        width: "56px",
      },
      {
        id: 6,
        code: "supplement",
        numeric: false,
        disablePadding: false,
        label: "Supplement",
        show: true,
        width: "60px",
      },
      {
        id: 7,
        code: "action",
        numeric: false,
        disablePadding: false,
        label: "Action",
        show: true,
      },
    ],
  };
  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     this.state.jounalList.locationID !== prevState.jounalList.locationID ||
  //     this.state.jounalList.stockTypeID !== prevState.jounalList.stockTypeID
  //   ) {
  //     if (
  //       this.state.jounalList.locationID !== "" &&
  //       this.state.jounalList.stockTypeID !== ""
  //     ) {
  //       getBalanceQty(
  //         this.state.jounalList.journalID,
  //         this.state.jounalList.locationID,
  //         this.state.jounalList.stockTypeID
  //       ).then((r) => {
  //         this.setState({ balanceQty: r.data, transferQty: "" });
  //       });
  //     }
  //   }
  // }
  componentDidMount() {
    this.props.handleSettingHeader("Back Label Stock Transfer");
    this.props.setSelectedList(
      "publisherBackLabelOpen",
      "backLabelStockTransfer"
    );
    getWareLocationDD().then((r) => {
      let newData = r && r.data && r.data.length > 0 && r.data.sort((a, b) =>
        a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
      );
      this.setState({
        // stockLocationDD: newData,
        stockLocationDD2: newData,
      });
    });

    getStockTypeDD().then((r) => {
      this.setState({
        stockTypeList: r && r.data && r.data.length > 0 && r.data.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
      });
    });
  }
  handleAddJournalClose = () => {
    this.setState({
      openDialog: false,
    });
  };
  handleClickOpen = () => {
    this.setState({
      openDialog: true,
    });
  };
  handleJournalUpdate = (selectedJournalList) => {
    let selected = selectedJournalList[0];
    let newList = {
      ...selected,
      stocktype: "",
      stockTypeID: "",
      locationID: "",
      location: "",
    };
    let newList2 = {
      stocktype: "",
      stockTypeID: "",
      locationID: "",
      location: "",
    };

    this.setState({
      jounalList: newList,
      openDialog: false,
      balanceQty: "",
      transferQty: "",
      // fromLocation: newList2,
      errors: {},
    });
    if (selectedJournalList[0].journalID)
      getStockDetailsByJournalID(selectedJournalList[0].journalID).then(res => {
        this.setState({ wlArray: res })
      })
  };
  handleStockTypeChangeFromLocation = (event, values) => {
    this.setState({ fStockType: values, fStockTypeId: values && values.id })
    if (values && values.id) {
      getBalanceQty(
        this.state.jounalList.journalID,
        this.state.fLocationId,
        values.id,
      ).then((r) => {
        this.setState({ balanceQty: r.data, transferQty: "" });
      });
    }
  };
  handleLocationFromLocation = (event, values) => {
    if (values) {
      let newWlArr = [...this.state.wlArray]
      this.state.wlArray.map((x, j) => {
        newWlArr[j].checkedVal = false
      })

      this.setState({ wlArray: newWlArr })
    }
    this.setState({ fLocation: values, fLocationId: values && values.id })
    if (values && values.id) {
      getBalanceQty(
        this.state.jounalList.journalID,
        values.id,
        this.state.fStockTypeId,

      ).then((r) => {
        this.setState({ balanceQty: r.data, transferQty: "" });
      });
    }

  };
  handleToStockTypeChange = (event, values) => {
    getWarehouseLocationStockType(this.state.jounalList.journalID, values.id).then((r) => {
      let fLocationId = this.state.fLocationId;
      let listObj = [];
      r.data.forEach(function (item, key) {
        if (item.id !== fLocationId)
          listObj.push(item);
      });
      this.getStockLocationDD(listObj);
    });
    this.setState({ tStockTypeId: values && values.id ? values.id : "", tStockType: values, tLocation: "" });

  };
  getStockLocationDD = (data) => {
    this.setState({
      stockLocationDD:
        data
      //  &&
      // data.sort((a, b) =>
      //   a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
      // ),
    });
  };
  handleToLocationChange = (event, values) => {

    this.setState({ tLocationId: values && values.id ? values.id : "", tLocation: values })

  };
  handleChange = (event, values) => {
    this.validateField(event.target.name, event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  onBlur = (event) => {
    this.handleStockInList();
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;

    let stockTransferValid = this.state.stockTransferValid;

    switch (fieldName) {
      case "transferQty":
        if (value === "0") {
          stockTransferValid = false;
          fieldValidationErrors.transferQty = stockTransferValid
            ? ""
            : "Please enter valid Stock transfer Quantity";
        } else {
          if (
            this.state.balanceQty >= 0 &&
            parseInt(value) > this.state.balanceQty
          ) {
            stockTransferValid = false;
            fieldValidationErrors.transferQty = stockTransferValid
              ? ""
              : "StockTransfer quantity should not exceed " +
              this.state.balanceQty;
          } else {
            stockTransferValid = true;
            fieldValidationErrors.transferQty = stockTransferValid ? "" : "";
          }
        }

        break;

      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,

        stockTransferValid: stockTransferValid,
      },
      this.validateForm
      // this.validateSearchStock
    );
  }

  handleSubmit = () => {
    let loggedInId = localStorage.loggedInId;
    const { jounalList } = this.state;
    let journalID = jounalList.journalID;
    let acronym = jounalList.acronym;
    let volume = jounalList.volume;
    let issue = jounalList.issue;
    let supp = jounalList.supplement;
    let toLocationID = this.state.tLocationId
    let fromLocationID = this.state.fLocationId;
    let issueDescription = jounalList.issueDescription;
    let transferDate = this.state.transferDate ? moment(this.state.transferDate).format("YYYY-MM-DD HH:mm:ss") : null;
    let fromStockTypeID = this.state.fStockTypeId;
    let toStockTypeID = this.state.tStockTypeId;
    let transferedQty = this.state.transferQty;
    let isActive = true;
    let remarks = "";
    let createdBy = loggedInId;

    let reqBody = {
      journalID,
      acronym,
      volume,
      issue,
      supp,
      fromLocationID,
      toLocationID,
      issueDescription,
      fromStockTypeID,
      toStockTypeID,
      transferedQty,
      transferDate,
      remarks,
      isActive,
      createdBy,
    };

    createStockTransfer(reqBody, this.props.history).then(t => {
      getStockDetailsByJournalID(this.state.jounalList.journalID).then(res => {
        this.setState({ wlArray: res })
        this.setState({ fLocationId: "", fLocation: {}, tLocationId: "", tLocation: {}, transferDate: new Date() })
        this.setState({ fStockType: {}, fStockTypeId: "", tStockTypeId: "", tStockType: {} })
        this.setState({ balanceQty: "", transferQty: "" })
      })
    })
  };
  checkGoodToGo = () => {
    if (
      // this.state.jounalList.acronym === "" ||
      // this.state.jounalList.issue === "" ||
      !this.state.jounalList.issueDescription ||
      // this.state.jounalList.supplement ||
      // this.state.jounalList.volume ||
      !this.state.fStockTypeId ||
      !this.state.fLocationId ||
      !this.state.tStockTypeId ||
      !this.state.tLocationId ||
      !this.state.transferQty ||
      !this.state.balanceQty ||
      this.state.transferQty > this.state.balanceQty

    ) {
      return true;
    }
    else return false;
  };
  onCkBxClicked = (e, i, data) => {
    this.setState({ tStockTypeId: "", tStockType: {}, tLocationId: "", tLocation: {} })
    let newWlArr = [...this.state.wlArray]
    this.state.wlArray.map((x, j) => {
      newWlArr[j].checkedVal = false
    })
    if (e.target.checked) {
      this.setState({ disableTQ: false })
      newWlArr[i].checkedVal = true
      this.setState({ eMsg: false })
      // this.state.stockLocationDD.map(l => {
      //   if (l.id === data.warehouseLocationID) {
      //     this.setState({ fLocationId: l.id, fLocation: l, })
      //   }
      // })
      this.setState({ fLocationId: data.warehouseLocationID, fLocation: data })
      this.state.stockTypeList.map(s => {
        if (s.id === data.stockTypeID) {
          this.setState({ fStockType: s, fStockTypeId: s && s.id })
        }
      })
      this.setState({ balanceQty: data.balanceQty })
    }
    else {
      this.setState({ fLocationId: "", fLocation: {}, })
      this.setState({ fStockType: {}, fStockTypeId: "" })
      this.setState({ balanceQty: "" })
    }
    let nErr = { ...this.state.errors }
    nErr.transferQty = ""
    this.setState({ wlArray: newWlArr, transferQty: "", errors: nErr })

  }
  handleDateChange = (date) => {
    this.setState({ transferDate: date });
  };
  render() {
    let { classes } = this.props;
    let {
      journalID,
      issueDescription,
      acronym,
      journalTitle,
      volume,
      issue,
      supplement,
      stocktype,
      location,
    } = this.state.jounalList;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create Stock Transfer</div>
          <Divider />
          {this.state.openDialog ? (
            <IndividualSearchAddDialog
              title="Journal Pick Up"
              type="stockTransfer"
              showDialog={this.state.openDialog}
              handleAddJournalClose={this.handleAddJournalClose}
              handleJournalUpdate={this.handleJournalUpdate}
              filteredJournalsList={[]}
              tableheader={this.state.headCellsNew}
            />
          ) : null}
          <div className={classes.container}>
            <div>
              <div className={classes.container}>
                <p className={classes.subHeader}>From Location</p>
                <div style={{ display: "flex", width: "100%" }}>
                  <Button
                    onClick={this.handleClickOpen}
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    size="small"
                  >
                    <span>JOURNAL PICK UP</span>
                  </Button>
                  <div className={classes.emptyField}></div>
                  <div className={classes.emptyField}></div>
                  <div
                    className={[classes.emptyField, classes.lastChild].join(
                      " "
                    )}
                  ></div>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <TextField
                    label="Acronym"
                    name="acronym"
                    className={classes.textFieldAlign}
                    inputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                    onKeyPress={this._onTextKeyPress}
                    //   onKeyPress={this._onNumberKeyPress}
                    // onKeyPress={this._onCodeKeyPress}
                    margin="normal"
                    value={acronym}
                  />
                  <TextField
                    label="Volume"
                    name="volume"
                    className={classes.textFieldAlign}
                    inputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    value={volume}
                  />
                  <TextField
                    label="Issue"
                    name="issue"
                    className={[classes.textFieldAlign, classes.lastChild].join(
                      " "
                    )}
                    inputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    value={issue}
                  />

                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <TextField
                    label="Supplement"
                    name="supplement"
                    inputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    className={classes.textFieldAlign}

                    value={supplement}
                  />
                  {/* <Autocomplete
                    //autoFocus
                    options={this.state.stockTypeList}
                    value={this.state.fStockType}
                    // disabled={this.state.jounalList.journalID === null}
                    onChange={this.handleStockTypeChangeFromLocation}
                    getOptionLabel={(option) => option.text}
                    className={[
                      classes.autoComplete,
                      classes.textFieldAlign,
                    ].join(" ")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        //autoFocus
                        style={{ width: "100%" }}
                        label="Stock type"
                        className={classes.autocompleteText}
                        margin="normal"
                        required
                      />
                    )}
                  /> */}
                  {/* <Autocomplete
                    //autoFocus
                    options={this.state.stockLocationDD}
                    value={this.state.fLocation}
                    // disabled={this.state.jounalList.stockTypeID === ""}
                    // disabled={this.state.fromLocation.stockTypeID ? false : true}
                    onChange={this.handleLocationFromLocation}
                    getOptionLabel={(option) => option.text}
                    className={[
                      classes.autoComplete,
                      classes.textFieldAlign,
                    ].join(" ")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        //autoFocus
                        style={{ width: "100%" }}
                        label="Location"
                        className={classes.autocompleteText}
                        margin="normal"
                        required
                      />
                    )}
                  /> */}
                  <TextField
                    required
                    variant="filled"
                    label="Issue Description"
                    name="issueDescription"
                    className={classes.textFieldAlign}
                    inputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    value={issueDescription}
                  />
                  <div className={[classes.textFieldAlign, classes.lastChild].join(
                    " "
                  )}></div>
                  {/* <TextField
                    variant="filled"
                    label="Balance Qty"
                    name="balanceQty"
                   
                    inputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    value={this.state.balanceQty}
                  /> */}
                </div>
                {this.state.wlArray && this.state.wlArray.length > 0 && <div className="view-page-headings" style={{ height: "35px", fontSize: "17px", width: "100%" }}>Stock Transfer Information</div>}
                {/* <div className="view-page-contents-container"></div> */}
                {this.state.wlArray && this.state.wlArray.length > 0 && <Divider
                  style={{
                    marginTop: "25px",
                  }}
                />}
                {this.state.wlArray && this.state.wlArray.length > 0 && <div style={{ maxHeight: 500, width: "100%" }}>
                  <Table
                    aria-labelledby="tableTitle"
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow style={{ background: "#eaeaea" }}>
                        <TableCell padding="default" className="headcells">
                          {" "}
                          <span style={{ color: "#306dca" }}>

                          </span>{" "}
                        </TableCell>
                        <TableCell padding="default" className="headcells">
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            Warehouse Location Name
                          </span>{" "}
                        </TableCell>
                        <TableCell padding="default" className="headcells">
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            Stock Type
                          </span>{" "}
                        </TableCell>
                        <TableCell padding="default" className="headcells">
                          {" "}
                          <span style={{ color: "#306dca" }}>
                            Balance Qty
                          </span>{" "}
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        this.state.wlArray && this.state.wlArray.length > 0 && this.state.wlArray.map((ja, i) => {
                          return (
                            ja && <TableRow >
                              <TableCell className="table-content-cell" component="th" style={{ padding: "0px .5em", width: "20px" }}
                                scope="row"
                                padding="default"> <FormControlLabel
                                  control={
                                    <CustomCheckbox
                                      color="primary"
                                      checked={ja.checkedVal ? ja.checkedVal : false}
                                      onClick={e => this.onCkBxClicked(e, i, ja)}
                                    />
                                  }
                                /> </TableCell>
                              <TableCell className="table-content-cell" component="th" style={{ padding: ".5em" }}
                                scope="row"
                                padding="default">{ja.warehouseLocationName} </TableCell>
                              <TableCell className="table-content-cell" component="th" style={{ padding: ".5em" }}
                                scope="row"
                                padding="default">{ja.stockType}</TableCell>
                              <TableCell className="table-content-cell" component="th" style={{ padding: ".5em" }}
                                scope="row"
                                padding="default">{ja.balanceQty}</TableCell>

                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </div>}
                <div className="view-page-headings" style={{ height: "35px", fontSize: "17px", width: "100%" }}>Stock Transfer Details</div>
                {/* <Divider
                  style={{
                    marginTop: "25px",
                  }}
                /> */}
                <p className={classes.subHeader}>To Location</p>
                <div style={{ display: "flex", width: "100%" }}>
                  <Autocomplete
                    options={this.state.stockTypeList}
                    value={this.state.tStockType}
                    disabled={!this.state.fLocationId}
                    onChange={this.handleToStockTypeChange
                    }
                    getOptionLabel={(option) => option.text}
                    className={[
                      classes.autoComplete,
                      classes.textFieldAlign,
                    ].join(" ")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "100%" }}
                        label="Stock type"
                        disabled={!this.state.fLocationId}
                        margin="normal"
                        className={classes.autocompleteText}
                        required
                      />
                    )}
                  />
                  <Autocomplete
                    options={this.state.stockLocationDD}
                    disabled={!this.state.tStockTypeId || !this.state.fLocationId}
                    value={this.state.tLocation}
                    onChange={this.handleToLocationChange}
                    getOptionLabel={(option) => option.text}
                    className={[
                      classes.autoComplete,
                      classes.textFieldAlign,
                    ].join(" ")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "100%" }}
                        disabled={!this.state.tStockTypeId || !this.state.fLocationId}
                        label="Location"
                        margin="normal"
                        className={classes.autocompleteText}
                        required
                      />
                    )}
                  />
                  <div className={classes.textFieldAlign}></div>
                  <div
                    className={[classes.textFieldAlign, classes.lastChild].join(
                      " "
                    )}
                  ></div>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <TextField
                    required
                    label="Transfer Qty"
                    name="transferQty"
                    inputProps={{
                      maxLength: 4,
                    }}
                    disabled={this.state.balanceQty === ""}
                    onKeyPress={this._onTextKeyPress}
                    margin="normal"
                    value={this.state.transferQty}
                    onChange={this.handleChange}
                    className={classes.textFieldAlign}
                    error={this.state.errors.transferQty}
                    helperText={
                      this.state.errors.transferQty
                        ? this.state.errors.transferQty
                        : ""
                    }
                  />
                  {/* <TextField
                    id="currentDate"
                    label="Transfer Date"
                    variant="filled"
                    value={this.state.transferDate}
                    margin="normal"
                    readOnly
                    className={classes.textFieldAlign}
                  /> */}
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    variant="inline"
                    inputProps={{ readOnly: true }}
                    format="dd/MM/yyyy"
                    margin="normal"
                    style={{
                      flex: 1,
                      marginRight: "16px"
                    }}
                    // disabled={!isManual ? true : false}
                    // disabled
                    id="transferDate"
                    label="Transfer Date"
                    onChange={this.handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    value={this.state.transferDate ? this.state.transferDate : null}
                  />
                  <div className={classes.textFieldAlign}></div>
                  <div
                    className={[classes.textFieldAlign, classes.lastChild].join(
                      " "
                    )}
                  ></div>
                </div>
                <Divider style={{ margin: "15px 0 5px" }} />
                <div style={{ display: "flex", width: "100%" }}>
                  <div className="button-wrapper">
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={(e) =>
                          this.props.history.push("/backLabelStockTransfer")
                        }
                      >
                        CANCEL
                      </Button>
                    </div>
                    <div style={{ marginLeft: "1em" }}>
                      {this.checkGoodToGo() ? (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      ) : (
                          <Button

                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                            onClick={this.handleSubmit}
                          >
                            SUBMIT
                          </Button>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(AddBackLabelStockTransfer));
