import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import { getRole } from "../../userAccess/userAccessAction";
import { StateContext } from "../../../shared/globalState";
import { printDetails } from "../../../shared/tableCommonFunctions";

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { selectedRoleId } = props;
  let acnArr = props.roles.filter((u) => u.menuName === "Role");
  let acnObj = acnArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_role/${selectedRoleId}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}

          {/* <Tooltip title="Download">
            <IconButton aria-label="download" className={classes.iconHover} onClick={e => pdfDownloadDetails("Role_details.pdf")}>
              <DownloadIcon />
            </IconButton>
          </Tooltip> */}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewRole extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      role: {
        roleName: "",
        description: "",
        orderNo: "",
        isReport: false,
      },
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let roleID = this.props.match.params.roleId;
    getRole(roleID).then((r) => {
      dispatch({
        type: "getRole",
        newStore: r,
      });
      this.setState({ role: r });
    });
  }

  render() {
    let { role } = this.state;
    let roleID = this.props.match.params.roleId;
    let { statusReport } = "";
    if (role.isReport === true) {
      statusReport = "Yes";
    } else {
      statusReport = "No";
    }
    let { status } = "";
    if (role.isActive === true) {
      status = "Yes";
    } else {
      status = "No";
    }
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selectedRoleId={roleID}
            roles={this.props.roles}
          />
          {/* <div className="view-page-header">Cost Per Title Information</div> */}
          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">Role Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Role Name</div>
                <div className="view-page-value">{role.roleName}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Description</div>
                <div className="view-page-value">{role.description}</div>
              </div>
              {/* <div className="view-page-contents-sub-container">
                <div className="view-page-label">Order Number</div>
                <div
                  className="view-page-value"
                  // style={{ textAlign: "right", paddingRight: "130px" }}
                >
                  {role.orderNo}
                </div>
              </div> */}
            </div>
            <div className="view-page-contents-container">
              {/* <div className="view-page-contents-sub-container">
                <div className="view-page-label">Report</div>
                <div className="view-page-value">{statusReport}</div>
              </div> */}
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Active</div>
                <div className="view-page-value">{status}</div>
              </div>
            </div>
          </div>
          <Divider />
          <div className="button-wrapper">
            <div style={{ marginLeft: "2em" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.props.history.push("/role")}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewRole);
