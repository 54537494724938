import React, { Component, useState } from "react";
import { TextField, Button, Paper, Divider, Link } from "@material-ui/core";
import { CustomSmallAddIcon } from "../../../shared/customIcons";

import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import SyncIcon from "@material-ui/icons/Sync";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { EditIcon } from "../../../shared/customIcons";
import InfoIcon from "@material-ui/icons/Info";
import IndividualSearchAddDialog from "../../publisherBackLabel/ebloOrderPlacement/IndividualSearchAddDialog";
import { lighten, makeStyles } from "@material-ui/core/styles";
import FileImg from "../../../assests/images/svg/filesupload-illustration.svg";
import Grid from "@material-ui/core/Grid";

import GetAppIcon from "@material-ui/icons/GetApp";
import DescriptionIcon from "@material-ui/icons/Description";
import { addDispatchStyles } from "../../../shared/styles";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Autocomplete } from "@material-ui/lab";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { resourceValidation } from "../../../shared/resource";
import { ExportCSV } from "../../../shared/ExportCSV";
import StatusText from "../../../shared/statusText";
import moment from "moment";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import {
  stableSort,
  getSorting,
  DialogActions,
} from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import Validator from "../../../shared/validator";
import { Checkbox } from "@material-ui/core";

import { StateContext } from "../../../shared/globalState";

import LinearProgress from "@material-ui/core/LinearProgress";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";

import Dropzone from "react-dropzone";
import {
  getOrderRecepientList,
  getOrderRecepientListbyID,
  getDeliveryList,
  getJournalQty,
  getLocation,
  postBPAOrder,
  CreateBPADetail,
} from "./action";
import {
  getWareLocationDD,
  getCountryDropdownList,
} from "../../masters/masterActions";
import { validate } from "email-validator";
const emailValidator = new Validator("email");
// const fileType =
//   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
// const fileExtension = ".xlsx";
// const exportToCSV = (csvData, fileName) => {
//   const ws = XLSX.utils.json_to_sheet(csvData);
//   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
//   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
//   const data = new Blob([excelBuffer], { type: fileType });
//   FileSaver.saveAs(data, fileName + fileExtension);
// };
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  comboBox: {
    margin: "normal",
    width: "100%",
    fontSize: "11px !important",
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
    display: "flex",

    flex: 1,
    alignItems: "left",
    overflow: "hidden",
  },
  textField1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "31%",
    fontSize: "11px !important",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
  table: {
    minWidth: 650,
  },
  textFieldAlign: {
    // marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    // width: "23%",
    fontSize: "11px !important",
    flex: 1,
  },
  emptyField: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
  },
  lastChild: {
    marginRight: 0,
  },
});
const headCells1 = [
  // {
  //   id: 1,
  //   code: "orderID",

  //   show: true,

  //   numeric: false,
  //   disablePadding: true,
  //   label: "Order ID",
  // },
  {
    id: 1,
    code: "itemDescription",

    show: true,

    numeric: false,
    disablePadding: true,
    label: "Issue Description",
  },
  {
    id: 2,
    code: "journalTitle",

    show: true,

    numeric: false,
    disablePadding: true,
    label: "Journal Title",
  },
  {
    id: 3,
    code: "acronym",

    show: true,

    numeric: false,
    disablePadding: true,
    label: "Acronym",
  },
  {
    id: 4,
    code: "volume",

    show: true,

    numeric: false,
    disablePadding: true,
    label: "Volume",
  },
  {
    id: 5,
    code: "issue",

    show: true,

    numeric: false,
    disablePadding: true,
    label: "Issue",
  },
  {
    id: 6,
    code: "suppNumber",

    show: true,

    numeric: false,
    disablePadding: true,
    label: "Supplement",
  },
  // {
  //   id: 6,
  //   code: "partNumber",

  //   show: true,
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Part Number",
  // },
  {
    id: 77,
    code: "availableQuantity",
    numeric: false,
    disablePadding: false,
    label: "Available Qty",
    show: true,
    width: "82px",
  },
  {
    id: 78,
    code: "orderedQuantity",
    numeric: false,
    disablePadding: false,
    label: "Ordered Qty",
    show: true,
    width: "82px",
  },
  {
    id: 7,
    code: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Balance Qty",
    show: true,
    width: "82px",
  },

  {
    id: 8,
    code: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
    show: true,
    width: "82px",
  },
  // {
  //   id: 10,
  //   code: "trackingNumber",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Tracking Number",
  //   show: true,
  //   width: "230px",
  // },
  // {
  //   id: 11,
  //   code: "actualWeight",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Actual Weight",
  //   show: true,
  //   width: "116px",
  // },
  // {
  //   id: 12,
  //   code: "deliveryDate",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Delivery Date",
  //   show: true,
  //   width: "207px",
  // },
  {
    id: 9,
    code: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    show: true,
  },
];
const headCells = [
  {
    id: "toAsiaSpecific",
    numeric: false,
    disablePadding: true,
    label: "To Asia Specific",
  },
  {
    id: "toUsaEurope",
    numeric: false,
    disablePadding: false,
    label: "To USA./Europe",
  },
  {
    id: "toRestOfWorld",
    numeric: false,
    disablePadding: false,
    label: "To Rest Of World",
  },
];
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderLeft: "1px sold #000", width: `${headCell.width}` }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function EnhancedTableHead1(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    headCells,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{
              borderLeft: "1px sold #000",
              width: `${headCell.width}`,
              color: "rgb(48, 109, 202)",
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const useToolbarStyles1 = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

function Basic(props) {
  return (
    <div
      style={{
        margin: "1em 0 .5em",
        border: "1px solid #eaeaea",
        // width: "97.6%",
        borderRadius: "4px",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0)",
      }}
    >
      <section
        style={{ marginLeft: "1.5em", marginRight: "1.5em", marginTop: "1em" }}
      >
        <Dropzone
          onDrop={props.onDrop}
          accept=".xlsx,.jpg,.doc,.docx,.pdf,.txt,.bmp"
          minSize={0}
          maxSize={1024000}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({})}>
              <input {...getInputProps()} />
              <div
                style={{
                  width: "auto",
                  border: "1.5px dashed #eaeaea",
                  marginBottom: "1em",
                  borderRadius: "4px",
                  minHeight: "70px",
                  cursor: "pointer",
                }}
              >
                <label
                  className="upload excel"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "70px",
                    cursor: "pointer",
                    padding: ".3em",
                  }}
                >
                  <img
                    style={{ height: "60px", marginTop: "4px" }}
                    src={FileImg}
                    alt="File upload img"
                  />

                  <div
                    style={{
                      display: "inline-block",
                      width: "auto",
                      marginLeft: "12px",
                      width: "320px",
                    }}
                  >
                    <div className="file-upload-head">Add files</div>
                    <div className="file-upload-contant">
                      Drop files here or click{" "}
                      <span
                        style={{
                          color: "#306dca",
                          textDecoration: "underline",
                        }}
                      >
                        browse
                      </span>{" "}
                      through your machine{" "}
                    </div>
                    <div className="file-upload-footer">
                      Allowed all types of file, 1MB file limit.
                    </div>
                  </div>
                </label>
              </div>
            </div>
          )}
        </Dropzone>
      </section>
    </div>
  );
}

function DetectionControl(file) {
  return (
    <div
      style={{
        width: "45%",
        display: "flex",
        alignItems: "center",
        marginTop: "-1px",
      }}
    >
      <span>
        {true ? (
          <CheckCircleIcon
            style={{ color: "rgba(59, 218, 182, 0.87)", fontSize: "19px" }}
          />
        ) : (
            <InfoIcon style={{ color: "red", fontSize: "19px" }} />
          )}
      </span>
      <span
        style={{
          marginTop: "-5px",
          marginLeft: "5px",
          fontSize: "11px",
        }}
      >
        {true
          ? "Uploaded successfully"
          : file.fileName && file.fileName.message}
      </span>
    </div>
  );
}

class AddBpaOrderPlacement extends Component {
  state = {
    mode: "",
    errors: {
      email: false,
    },
    okToGO: false,
    newPartNumber: 0,
    newQuantity: 0,
    newTrackingNumber: "",
    newActualWeight: "0.000",
    newDeliveryDate: null,
    page: 0,
    rowsPerPage: 100,
    order: "desc",
    orderBy: "modifiedDate",
    remarks: "",
    loading: false,
    loading1: false,
    reqJournalDetail: [],
    selectedEditIndex: null,
    codeTypeList: [],
    isManual: true,
    openDialog: false,
    recipientDetails: {
      companyName: "",
      contactPersonName: "",
      department: "",
      countryID: "",
      countryName: "",
      postalCode: "",
      phoneNumber: "",
      address1: "",
      address2: "",
    },
    jouranlUniqueID: "",
    deliveryAddress: "",
    conferenceRef: "",
    conferenceRoom: "",
    costCoding: "",
    bpaRecipientID: "",
    emailID: "",
    deliveryInstructions: "",
    packingInstructions: "",
    packingInstruction: "",
    deliveryInstruction: "",
    balanceQty: "",
    deliveryID: "",
    deliveryCountry: "",
    deliveryCountryDD: [],
    countryID: "",
    journalID: "",
    deliveryListDD: [],
    deliveryListData: "",
    deliveryListDataID: "",
    recipientList: [],
    doNotDeliveryBefore: "",
    mustBeDeliveryBy: "",
    mustBeDeliveryOn: "",
    fileList: [],
    stateFiles: [],
    showError: false,
    journalDetail: {
      journalID: "",
      acronym: "",
      volume: "",
      issue: "",
      suppNumber: "",
      orderID: "",
      itemDescription: "",
      partNumber: "",
      trackingNumber: "",
      actualWeight: 0,
      noOfCartons: "",
      dhlRate: "",
      deliveryDate: null,
      OrderQuantity: 0,
    },
    deliveryList: [
      {
        region: "To Asia Specific",
        daySpan: "2 - 4 Working Days",
      },
      {
        region: "To USA./Europe",
        daySpan: "3 - 6 Working Days",
      },
      {
        region: "To Rest of the World",
        daySpan: "4 - 7 Working Days",
      },
    ],
    headCellsNew: [
      {
        id: 1,
        code: "issueDescription",
        numeric: false,
        disablePadding: false,
        label: "Issue Description",
        show: true,
        width: "200px",
      },
      {
        id: 2,
        code: "journalTitle",
        numeric: false,
        disablePadding: false,
        label: "Journal Title",
        show: true,
        width: "200px",
      },
      {
        id: 3,
        code: "acronym",
        numeric: false,
        disablePadding: false,
        label: "Acronym",
        show: true,
        width: "58px",
      },

      {
        id: 4,
        code: "volume",
        numeric: false,
        disablePadding: false,
        label: "Volume",
        show: true,
        width: "50px",
      },
      {
        id: 5,
        code: "issue",
        numeric: false,
        disablePadding: false,
        label: "Issue",
        show: true,
        width: "56px",
      },
      {
        id: 6,
        code: "suppNumber",
        numeric: false,
        disablePadding: false,
        label: "Supplement",
        show: true,
        width: "60px",
      },
      {
        id: 7,
        code: "action",
        numeric: false,
        disablePadding: false,
        label: "Action",
        show: true,
      },
    ],
    bpaOrderHeaderID: 0,
    mustBeDeliveryOn: null,
    doNotDeliveryBefore: null,
    mustBeDeliveryBy: null,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.deliveryID !== prevState.deliveryID) {
      if (this.state.deliveryID !== "" && this.state.deliveryID !== null) {
        getOrderRecepientListbyID(this.state.deliveryID).then((r) => {
          if (r.data) {
            let newData = this.state.deliveryCountryDD.filter((p) => {
              return p.id == r.data[0].countryID;
            });

            this.setState({
              recipientDetails: r.data[0],
              deliveryCountry: newData && newData[0],
              countryID: newData[0] ? newData[0].id : null,
            });
          }
        });
      }
    }
    if (this.state.jouranlUniqueID !== prevState.jouranlUniqueID) {
      if (this.state.jouranlUniqueID !== "") {
        getJournalQty(this.state.jouranlUniqueID).then((r) => {
          let balQty = "";
          let availableQty = ""
          let orderedQty = ""
          balQty = r && r.data.balanceQty;
          availableQty = r && r.data.availableQty
          orderedQty = r && r.data.orderedQty
          let length = this.state.codeTypeList.length - 1;

          let change = { ...this.state.codeTypeList[length], balQty, availableQty, orderedQty };
          let arr = [...this.state.codeTypeList];

          if (balQty > 0) {
            arr = [...this.state.codeTypeList, change];
            arr.splice(length, 1);
          } else {
            arr.splice(length, 1);
            alert("No balance quantity for this item");
            this.setState({ jouranlUniqueID: "" });
          }

          if (r)
            this.setState({ balanceQty: r.data.balanceQty, codeTypeList: arr });
        });
      }
    }
  };

  componentDidMount = () => {
    this.props.handleSettingHeader("Order Placement");
    this.props.setSelectedList("bpaMarketing", "bpaOrderPlacement");
    const userID = localStorage.loggedInId;
    getOrderRecepientList(userID).then((r) => {
      this.setState({
        recipientList:
          r &&
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });

    getDeliveryList().then((r) => {
      this.setState({
        deliveryListDD:
          r &&
          r.data &&
          r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
      });
    });
    getCountryDropdownList().then((r) => {
      if (r) {
        this.setState({
          deliveryCountryDD:
            r &&
            r.data &&
            r.data.sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
      }
    });
  };
  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };
  deleteSelectedFile = (i, id) => {
    let newStateFiles = [...this.state.stateFiles];
    let newFileList = [...this.state.fileList];
    // id && deleteOrder(id, window.localStorage.loggedInId).then((r) => {});
    newStateFiles.splice(i, 1);
    newFileList.splice(i, 1);
    this.setState({ stateFiles: newStateFiles, fileList: newFileList });
  };
  getExcelFileData = (id, name) => {
    //exportToCSV(newRows, name);
  };
  onDrop = (acceptedFiles) => {
    let newList = [...this.state.stateFiles];

    if (acceptedFiles.length > 2) return alert("Max Two files can be uploaded");
    let flag = false;
    let k = acceptedFiles.map((r) => {
      if (this.state.stateFiles.length > 0) {
        for (let i = 0; i < this.state.stateFiles.length; i++) {
          if (this.state.stateFiles[i].name === r.name) {
            flag = true;
          }
        }
      }
    });
    if (flag === true) return alert("Same name already exist");

    if (this.state.stateFiles.length < 2) {
      newList.push(...acceptedFiles);
      if (newList.length > 2) {
        return alert("Max Two files can be uploaded");
      }

      this.setState({
        loading: true,
        stateFiles: newList,
      });
    } else {
      alert("Max Two files can be uploaded");
    }
  };
  handleAutocompleteDeliveryAddress = (event, values) => {
    let { recipientDetails } = this.state;
    let newObj = {
      ...recipientDetails,
      companyName: "",
      contactPersonName: "",
      department: "",
      countryID: "",
      countryName: "",
      postalCode: "",
      phoneNumber: "",
      address1: "",
      address2: "",
    };

    if (true) {
      this.setState({
        recipientDetails: newObj,
      });
    }
    let id = values && values.id;
    let deliveryAddress = values;
    let deliveryID = id;
    this.setState({ deliveryAddress, deliveryID });
  };
  handleCountryChange = (event, values) => {
    let id = values && values.id;
    let deliveryCountry = values;
    let countryID = id;
    this.setState({ deliveryCountry, countryID });
  };
  handleDeliveryListData = (event, values) => {
    let id = values && values.id;
    let deliveryListData = values;
    let deliveryListDataID = id;
    this.setState({ deliveryListData, deliveryListDataID });
  };
  handleJournalUpdate = (selectedJournalList) => {
    this.setState({ jouranlUniqueID: selectedJournalList[0].journalID });
    let codeListArry = this.state.codeTypeList;

    let boolVal = codeListArry.filter((r) => {
      return r.itemDescription == selectedJournalList[0].issueDescription;
    });

    if (boolVal.length > 0) {
      this.setState(
        {
          openDialog: false,
          codeTypeList: codeListArry,
          okToGO: false,
        },
        () => {
          alert("Value already exist in the table");
        }
      );
    } else {
      selectedJournalList.map((journal) => {
        let journalTemp = {
          journalID: journal.journalID,
          acronym: journal.acronym,
          volume: journal.volume,
          issue: journal.issue,
          suppNumber: journal.supplement,
          journalTitle: journal.journalTitle,
          orderID: "",
          itemDescription: journal.issueDescription,
          partNumber: "",
          trackingNumber: "",
          actualWeight: 0,
          deliveryDate: null,
          OrderQuantity: 0,
        };
        codeListArry.push(journalTemp);
      });
    }

    if (
      this.state.codeTypeList.length === 0 &&
      selectedJournalList.length > 0
    ) {
      this.setState({
        loading1: false,
      });
    }

    this.setState({
      openDialog: false,
      codeTypeList: codeListArry,
      okToGO: false,
      selectedEditIndex: this.state.codeTypeList.length - 1,
    });
  };

  handleAddJournalClose = () => {
    this.setState({
      openDialog: false,
    });
  };

  handleAddJournalClick = () => {
    this.setState({
      openDialog: true,
    });
  };

  handleChangeEmail = (e, validate) => {
    let newRrrors = { ...this.state.errors };
    let result = validate(e.target.value);
    newRrrors[e.target.name] = !result;
    this.setState({ [e.target.name]: e.target.value, errors: newRrrors });
  };
  handleChangeActualWeight = (e) => {
    let r = e.target.value;
    if (e.target.name === "newActualWeight") {
      r = this.checkDHlWeightDec1(r.toString());
      // if (event.target && event.target.value && row && row.countryID)
      // getDHLRateUponAWeight(row.countryID, event.target.value).then(r => {
      //   // console.log(r)
      //   if (r && r.data) {
      //     list[index]["dhlRate"] = r.data.dhlRate ? r.data.dhlRate : "";
      //   }
      //   else list[index]["dhlRate"] = "";
      // })
    }
    this.setState({ [e.target.name]: r });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleChange3 = (e) => {
    let { recipientDetails } = this.state;
    let name = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => ({
      recipientDetails: {
        ...prevState.recipientDetails,
        [name]: value,
      },
    }));
  };
  handleChange1 = (e, row) => {
    if (e.target.value !== "") {
      this.setState({ okToGO: true });
    } else {
      this.setState({ okToGO: false });
    }
    if (row.balQty < e.target.value) {
      alert("Quantity should be less than Balance Quantity");
      this.setState({ okToGO: false });
    }
    this.setState({ [e.target.name]: e.target.value });
  };
  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };
  checkDHlWeightDec1(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }

    return value;
  }
  handleDoneClicked = (e, i) => {
    let codeTypeCopy = this.state.codeTypeList;
    let reqJournalDetailCopy = this.state.reqJournalDetail;
    codeTypeCopy.map((e, index) => {
      if (index === i) {
        e.partNumber = this.state.newPartNumber;
        e.OrderQuantity = this.state.newQuantity;
        e.trackingNumber = this.state.newTrackingNumber;
        e.actualWeight = this.state.newActualWeight;
        e.deliveryDate = this.state.newDeliveryDate;
      }
    });
    reqJournalDetailCopy.map((e, index) => {
      if (index === i) {
        e.partNumber = this.state.newPartNumber;
        e.OrderQuantity = this.state.newQuantity;
        e.trackingNumber = this.state.newTrackingNumber;
        e.actualWeight = this.state.newActualWeight;
        e.deliveryDate = this.state.newDeliveryDate;
      }
    });
    this.setState({
      selectedEditIndex: null,
      okToGO: true,
      isEditClicked: false,
      newQuantity: 0,
      jouranlUniqueID: "",
      newPartNumber: "",
      newTrackingNumber: "",
      newActualWeight: "0.000",
      newDeliveryDate: null,
      codeTypeList: codeTypeCopy,
      reqJournalDetail: reqJournalDetailCopy,
    });
  };

  handleCancelClicked = (e, i) => {
    this.setState({
      selectedEditIndex: null,
      isEditClicked: false,
      okToGO: false,
      newQuantity: 0,
      jouranlUniqueID: "",
      newPartNumber: "",
      newTrackingNumber: "",
      newActualWeight: "0.000",
      newDeliveryDate: null,
    });
  };

  handleDeleteClicked = (event, index, row) => {
    const journalList = [...this.state.codeTypeList];
    journalList.splice(index, 1);
    this.setState({
      codeTypeList: journalList,
      jouranlUniqueID: "",
    });
  };

  handleEditClicked = (e, i) => {
    this.setState({
      selectedEditIndex: i,
      isEditClicked: true,
      okToGO: false,
      newPartNumber: this.state.codeTypeList[i].partNumber,
      newQuantity: this.state.codeTypeList[i].OrderQuantity,
      newTrackingNumber: this.state.codeTypeList[i].trackingNumber,
      newActualWeight: this.state.codeTypeList[i].actualWeight,
      newDeliveryDate: this.state.codeTypeList[i].deliveryDate,
    });
  };
  handleTimeChange1 = (time) => {
    let doNotDeliveryBefore = time && time;
    this.setState({ doNotDeliveryBefore });
  };
  handleTimeChange2 = (time) => {
    let mustBeDeliveryBy = time && time;
    this.setState({ mustBeDeliveryBy });
  };
  handleDateChange1 = (date) => {
    this.setState({ ["mustBeDeliveryOn"]: date });
  };
  handleDateChange2 = (date) => {
    this.setState({ ["doNotDeliveryBefore"]: date });
  };
  handleDateChange3 = (date) => {
    this.setState({ ["mustBeDeliveryBy"]: date });
  };
  handleKeypress = (e) => {
    e.preventDefault();
    return false;
  };
  handleDraft = (e) => {
    let createdBy = localStorage.loggedInId;
    let doNotDeliveryBefore = "";
    let mustBeDeliveryBy = "";
    let mustBeDeliveryOn = "";
    if (this.state.doNotDeliveryBefore !== null) {
      doNotDeliveryBefore = moment(this.state.doNotDeliveryBefore).format(
        "MM/DD/YYYY HH:mm:ss"
      );
    }
    if (this.state.mustBeDeliveryBy !== null) {
      mustBeDeliveryBy = moment(this.state.mustBeDeliveryBy).format("MM/DD/YYYY HH:mm:ss");
    }
    if (this.state.mustBeDeliveryOn !== null) {
      mustBeDeliveryOn = moment(this.state.mustBeDeliveryOn).format(
        "MM/DD/YYYY HH:mm:ss"
      );
    }

    let newList = this.state.codeTypeList.map((p) => {
      return { ...p, isCPTGenrated: true, createdBy };
    });

    const formData = new FormData();
    this.state.stateFiles.map((f) => {
      formData.append("Uploadfiles", f);
    });
    formData.append(
      "bpaRecipientID",
      this.state.recipientDetails.bpaRecipientID ? this.state.recipientDetails.bpaRecipientID : ""
    );

    formData.append("companyName", this.state.recipientDetails.companyName);
    formData.append("department", this.state.recipientDetails.department);
    formData.append("postalCode", this.state.recipientDetails.postalCode);
    formData.append("conferenceName", this.state.conferenceRoom);
    formData.append("Mode", false);
    formData.append("costCoding", this.state.costCoding);
    formData.append("address", this.state.recipientDetails.address1);
    formData.append(
      "contactPersonName",
      this.state.recipientDetails.contactPersonName
    );

    formData.append("countryID", this.state.countryID);
    formData.append("phoneNumber", this.state.recipientDetails.phoneNumber);
    formData.append("conferenceRef", this.state.conferenceRef);
    formData.append("emailID", this.state.emailID);
    formData.append("address2", this.state.recipientDetails.address2 || "");
    formData.append("deliveryMethodID", this.state.deliveryListDataID);
    formData.append("deliveryInstructions", this.state.deliveryInstruction);
    formData.append("remarks", this.state.remarks);
    formData.append("packingInstructions", this.state.packingInstruction);
    formData.append("doNotDeliveryBefore", doNotDeliveryBefore);
    formData.append("mustBeDeliveryBy", mustBeDeliveryBy);
    formData.append("mustBeDeliveryOn", mustBeDeliveryOn);
    formData.append("createdBy", createdBy);
    // formData.append("createBPAOrderDetailRequest", newList);

    postBPAOrder(formData, this.props.history).then((r) => {
      let Mode = false;

      CreateBPADetail(
        r && r.data && r.data.bpaOrderHeaderID,
        Mode,
        newList,
        this.props.history
      );
    });
  };
  handleSubmit = (e) => {
    let createdBy = localStorage.loggedInId;
    // console.log(this.state.recipientDetails)
    let doNotDeliveryBefore = "";
    let mustBeDeliveryBy = "";
    let mustBeDeliveryOn = "";
    if (this.state.doNotDeliveryBefore !== null) {
      doNotDeliveryBefore = moment(this.state.doNotDeliveryBefore).format(
        "MM/DD/YYYY HH:mm:ss"
      );
    }
    if (this.state.mustBeDeliveryBy !== null) {
      mustBeDeliveryBy = moment(this.state.mustBeDeliveryBy).format("MM/DD/YYYY HH:mm:ss");
    }
    if (this.state.mustBeDeliveryOn !== null) {
      mustBeDeliveryOn = moment(this.state.mustBeDeliveryOn).format(
        "MM/DD/YYYY HH:mm:ss"
      );
    }

    let newList = this.state.codeTypeList.map((p) => {
      return { ...p, isCPTGenrated: true, createdBy };
    });

    const formData = new FormData();
    this.state.stateFiles.map((f) => {
      formData.append("Uploadfiles", f);
    });
    formData.append(
      "bpaRecipientID",
      this.state.recipientDetails.bpaRecipientID ? this.state.recipientDetails.bpaRecipientID : ""
    );

    formData.append("companyName", this.state.recipientDetails.companyName ? this.state.recipientDetails.companyName : "");
    formData.append("department", this.state.recipientDetails.department ? this.state.recipientDetails.department : "");
    formData.append("postalCode", this.state.recipientDetails.postalCode);
    formData.append("conferenceName", this.state.conferenceRoom ? this.state.conferenceRoom : "");
    formData.append("costCoding", this.state.costCoding ? this.state.costCoding : "");
    formData.append("Mode", true);
    formData.append("address", this.state.recipientDetails.address1);
    formData.append(
      "contactPersonName",
      this.state.recipientDetails.contactPersonName
    );

    formData.append("countryID", this.state.countryID);
    formData.append("phoneNumber", this.state.recipientDetails.phoneNumber);
    formData.append("conferenceRef", this.state.conferenceRef);
    formData.append("emailID", this.state.emailID);
    formData.append("address2", this.state.recipientDetails.address2 || "");
    formData.append("deliveryMethodID", this.state.deliveryListDataID);
    formData.append("deliveryInstructions", this.state.deliveryInstruction);
    formData.append("remarks", this.state.remarks);
    formData.append("packingInstructions", this.state.packingInstruction);
    formData.append("doNotDeliveryBefore", doNotDeliveryBefore);
    formData.append("mustBeDeliveryBy", mustBeDeliveryBy);
    formData.append("mustBeDeliveryOn", mustBeDeliveryOn);
    formData.append("createdBy", createdBy);
    // formData.append("createBPAOrderDetailRequest", newList);

    postBPAOrder(formData, this.props.history).then((r) => {
      let Mode = true;

      CreateBPADetail(
        r && r.data && r.data.bpaOrderHeaderID,
        Mode,
        newList,
        this.props.history
      );
    });
  };
  _onMobileNumberKeyPress(event) {
    const re = /[0-9+()-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertKeyPress(event) {
    const re = /[a-zA-Z0-9-#]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextKeyPress(event) {
    const re = /[a-z A-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress1(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusNumberKeyPress(event) {
    const re = /[0-9 a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onWeightKeyPress(event) {
    const re = /[0-9 a-zA-Z.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertSizeKeyPress(event) {
    const re = /[0-9 a-zA-Z-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusAllPress(event) {
    const re = /[a-z A-Z0-9=.;()&@]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  checkToGo = () => {
    if (
      this.state.codeTypeList.length === 0 ||
      //this.state.stateFiles.length === 0 ||
      //this.state.mustBeDeliveryOn === null ||
      //this.state.mustBeDeliveryBy === null ||
      //this.state.doNotDeliveryBefore === null ||
      //this.state.packingInstruction === "" ||
      //this.state.remarks === "" ||
      //this.state.deliveryInstruction === "" ||
      // this.state.deliveryListDataID === null ||
      //this.state.deliveryListDataID === null ||
      //this.state.recipientDetails.address2 === "" ||
      this.state.recipientDetails.address1 === "" ||
      this.state.recipientDetails.companyName === "" ||
      this.state.recipientDetails.contactPersonName === "" ||
      //this.state.recipientDetails.department === "" ||
      this.state.recipientDetails.phoneNumber === "" ||
      this.state.recipientDetails.postalCode === "" ||
      // (this.state.deliveryListData && this.state.deliveryListData.text !== "") ||
      this.state.countryID === null ||
      //this.state.emailID === "" ||
      this.state.conferenceRef === "" ||
      this.state.conferenceRoom === "" ||
      this.state.costCoding === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  neverEmpty = () => {
    let bool = false;
    let flag = [];
    if (this.state.codeTypeList.length > 0) {
      let k = this.state.codeTypeList.map((p) => {
        if (p.OrderQuantity == 0) {
          flag.push(true);
        } else {
          flag.push(false);
        }
      });
    }
    bool = flag.some((r) => {
      return r === true;
    });
    if (bool) {
      return true;
    } else {
      return false;
    }
  };
  render() {
    const {
      companyName,
      contactPersonName,
      department,
      countryID,
      countryName,
      postalCode,
      phoneNumber,
      address1,
      address2,
    } = this.state.recipientDetails;
    let { classes } = this.props;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create BPA Order Placement</div>
          <Divider />
          <div className={classes.container}>
            <div>
              <div className={classes.container}>
                <div>
                  <p className={classes.subHeader} style={{ marginLeft: "0" }}>
                    Recipient Information
                  </p>
                  <div style={{ display: "flex" }}>
                    <Autocomplete
                      options={this.state.recipientList}
                      value={this.state.recipientDetails.deliveryAddress}
                      onChange={this.handleAutocompleteDeliveryAddress}
                      getOptionLabel={(option) => option.text}
                      style={{ flex: 1, marginRight: "16px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Delivery Address"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                    <TextField
                      required
                      label="Company Name"
                      name="companyName"
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={this.handleChange3}
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={companyName}
                    />
                    <TextField
                      label="Department"
                      name="department"
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={this.handleChange3}
                      margin="normal"
                      style={{ flex: 1 }}
                      value={department}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      required
                      label="Postal Code"
                      name="postalCode"
                      onKeyPress={this._onNumberKeyPress}
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={this.handleChange3}
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={postalCode}
                    />
                    <TextField
                      required
                      label="Conference Name"
                      name="conferenceRoom"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={this.handleChange}
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={this.state.conferenceRoom}
                    />
                    <TextField
                      required
                      label="Cost Coding"
                      name="costCoding"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={this.handleChange}
                      margin="normal"
                      style={{ flex: 1 }}
                      value={this.state.costCoding}
                    />
                  </div>
                  <div style={{ display: "flex", width: "66.4%" }}>
                    <TextField
                      required
                      style={{ width: "98.6%" }}
                      label="Address 1"
                      name="address1"
                      inputProps={{
                        maxLength: 200,
                      }}
                      onChange={this.handleChange3}
                      margin="normal"
                      style={{ flex: 1 }}
                      value={address1}
                      rowsMax={4}
                      multiline="true"
                    />
                  </div>
                  <div style={{ display: "flex", width: "66.4%" }}>
                    <TextField
                      style={{ flex: 1 }}
                      label="Address 2"
                      name="address2"
                      inputProps={{
                        maxLength: 200,
                      }}
                      onChange={this.handleChange3}
                      margin="normal"
                      value={address2}
                      rowsMax={4}
                      multiline="true"
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <Autocomplete
                      options={this.state.deliveryCountryDD}
                      value={
                        this.state.deliveryID === null
                          ? ""
                          : this.state.deliveryCountry
                      }
                      onChange={this.handleCountryChange}
                      getOptionLabel={(option) => option.text}
                      style={{ flex: 1, marginRight: "16px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          margin="normal"
                          required
                          fullWidth
                        />
                      )}
                    />
                    <TextField
                      required
                      label="Contact Person Name"
                      name="contactPersonName"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={this.handleChange3}
                      style={{ flex: 1, marginRight: "16px" }}
                      margin="normal"
                      value={contactPersonName}
                    />
                    <TextField
                      required
                      label="Phone Number"
                      name="phoneNumber"
                      onKeyPress={this._onNumberKeyPress}
                      inputProps={{
                        maxLength: 12,
                      }}
                      onChange={this.handleChange3}
                      margin="normal"
                      style={{ flex: 1 }}
                      value={phoneNumber}
                    />
                  </div>
                  <div style={{ display: "flex", width: "100%" }}>
                    <TextField
                      required
                      label="Conference Ref"
                      name="conferenceRef"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={this.handleChange}
                      margin="normal"
                      //className={classes.textFieldAlign}
                      style={{ flex: 1, marginRight: "16px" }}
                      value={this.state.conferenceRef}
                    />
                    <TextField
                      label="Email ID"
                      name="emailID"
                      inputProps={{
                        maxLength: 50,
                      }}
                      //className={classes.textFieldAlign}
                      onChange={(e) =>
                        this.handleChangeEmail(e, emailValidator)
                      }
                      margin="normal"
                      style={{ flex: 1, marginRight: "16px" }}
                      value={this.state.emailID}
                      error={this.state.errors.emailID}
                      helperText={
                        this.state.errors.emailID
                          ? resourceValidation.validEmailId
                          : ""
                      }
                    />
                    <div style={{ flex: 1 }}></div>
                  </div>
                  <p className={classes.subHeader} style={{ marginLeft: 0 }}>
                    Delivery Information
                  </p>
                  <div style={{ display: "flex" }}>
                    <Autocomplete
                      options={this.state.deliveryListDD}
                      value={this.state.deliveryListData}
                      onChange={this.handleDeliveryListData}
                      getOptionLabel={(option) => option.text}
                      style={{ flex: 1, marginRight: "16px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Delivery Method"
                          margin="normal"
                          required
                          fullWidth
                        />
                      )}
                    />
                    <TextField
                      label="Delivery Instruction"
                      name="deliveryInstruction"
                      inputProps={{
                        maxLength: 50,
                      }}
                      style={{ flex: 1, marginRight: "16px" }}
                      onChange={this.handleChange}
                      margin="normal"
                      value={this.state.deliveryInstruction}
                    />
                    <TextField
                      label="Remarks"
                      name="remarks"
                      style={{ flex: 1 }}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={this.handleChange}
                      margin="normal"
                      value={this.state.remarks}
                    />
                  </div>
                  <div style={{ display: "flex", width: "66.4%" }}>
                    <TextField
                      label="Packing Instruction"
                      name="packingInstruction"
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={this.handleChange}
                      margin="normal"
                      style={{ flex: 1 }}
                      value={this.state.packingInstruction}
                    />
                  </div>
                  {/* <EnhancedTableToolbar />

                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                    style={{ marginLeft: "8px" }}
                  >
                    <EnhancedTableHead />
                    <TableBody>
                      <TableRow hover tabIndex={-1}></TableRow>
                    </TableBody>
                  </Table> */}
                  <div style={{ margin: "8px 0 5px" }}>
                    <h5
                      style={{
                        padding: "10px",
                        border: "1px solid #bbb",
                        borderBottom: 0,
                        margin: "0",
                      }}
                    >
                      Delivery Lead - times (Subjected to Custom Clearance)
                    </h5>
                    <div
                      style={{
                        border: "1px solid #bbb",
                        borderBottom: 0,
                        overflow: "auto",
                      }}
                    >
                      {this.state.deliveryList.map((p, i) => {
                        return (
                          <p
                            key={i}
                            style={{
                              float: "left",
                              width: "200px",
                              margin: "0",
                              padding: "10px",
                              textAlign: "center",
                              borderRight: "1px solid #bbb",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            {p.region}
                          </p>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        border: "1px solid #bbb",
                        borderTop: 0,
                        overflow: "auto",
                      }}
                    >
                      {this.state.deliveryList.map((p, i) => {
                        return (
                          <p
                            key={i}
                            style={{
                              float: "left",
                              width: "200px",
                              padding: "10px",
                              textAlign: "center",
                              borderRight: "1px solid #bbb",
                              borderTop: "1px solid #bbb",
                              margin: "0",
                              fontSize: "14px",
                            }}
                          >
                            {p.daySpan}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div>
                    <Basic
                      onDrop={this.onDrop}
                      stateFiles={this.state.stateFiles}
                    />
                  </div>

                  <div>
                    {this.state.stateFiles.map((file, i) => {
                      return (
                        <div key={i}>
                          {i === 0 && (
                            <div
                              style={{
                                display: "flex",
                                margin: "1em 0",
                                // marginTop: "2em",
                                paddingBottom: ".5em",
                                borderBottom: "1px solid #eaeaea",
                                fontSize: "14px",
                              }}
                            >
                              <div style={{ width: "30%", fontWeight: "500" }}>
                                File Info
                              </div>
                              <div style={{ width: "10%", fontWeight: "500" }}>
                                Size
                              </div>
                              <div style={{ width: "45%", fontWeight: "500" }}>
                                Detection
                              </div>
                              <div
                                style={{
                                  width: "15%",
                                  fontWeight: "500",
                                  textAlign: "right",
                                }}
                              >
                                Actions
                              </div>
                            </div>
                          )}
                          {true ? (
                            <div
                              style={{
                                display: "flex",
                                margin: "1em 0",
                                paddingBottom: ".5em",
                                borderBottom: "1px solid #eaeaea",
                                fontSize: "12px",
                              }}
                            >
                              <div style={{ width: "30%", fontWeight: "500" }}>
                                <div style={{ display: "flex" }}>
                                  <DescriptionIcon
                                    style={{
                                      color: "#306dca",
                                      marginTop: "-4px",
                                      marginRight: "5px",
                                    }}
                                  />{" "}
                                  <span> {file.name}</span>
                                </div>
                              </div>
                              <div style={{ width: "10%" }}>
                                {Math.ceil(file.size / 1024) + " KB"}
                              </div>

                              {true ? (
                                <DetectionControl
                                  fileName={this.state.fileList[i]}
                                />
                              ) : (
                                  <div
                                    style={{ width: "45%", marginTop: ".6em" }}
                                  >
                                    {/* <LinearProgress /> */}
                                  </div>
                                )}

                              <div style={{ width: "15%", textAlign: "right" }}>
                                {/* {!file.isValid && !file.filePath && (
                                  <IconButton
                                    size="small"
                                    style={{ marginTop: "-5px" }}
                                    onClick={(e) =>
                                      this.getExcelFileData(
                                        file.orderFileHeaderID,
                                        file.name
                                      )
                                    }
                                  >
                                    <GetAppIcon style={{ fontSize: "16px" }} />
                                  </IconButton>
                                )} */}
                                <IconButton
                                  size="small"
                                  style={{ marginTop: "-7px" }}
                                  onClick={(e) => {
                                    this.deleteSelectedFile(
                                      i,
                                      this.state.stateFiles[i].orderFileHeaderID
                                    );
                                  }}
                                >
                                  <DeleteIcon style={{ fontSize: "16px" }} />
                                </IconButton>{" "}
                              </div>
                            </div>
                          ) : (
                              ""
                            )}
                        </div>
                      );
                    })}
                  </div>
                  <p className={classes.subHeader} style={{ marginLeft: 0 }}>
                    Timed Delivery
                  </p>
                  <div style={{ display: "flex" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {/* <KeyboardDatePicker
                        margin="normal"
                        id="time-picker"
                        label="Do Not Deliver Before"
                        style={{ flex: 1, marginRight: "16px" }}
                        value={this.state.doNotDeliveryBefore}
                        onChange={this.handleTimeChange1}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />{" "}
                      <KeyboardDatePicker
                        margin="normal"
                        id="time-picker"
                        label="Must Deliver By"
                        style={{ flex: 1, marginRight: "16px" }}
                        value={this.state.mustBeDeliveryBy}
                        onChange={this.handleTimeChange2}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      /> */}
                      <KeyboardDatePicker
                        autoOk
                        disableToolbar
                        // disablePast
                        margin="normal"
                        disableToolbar
                        variant="inline"
                        id="date-picker-dialog"
                        label="Do Not Deliver Before"
                        minDateMessage="Date cannot be past date"
                        format="dd/MM/yyyy"
                        style={{ flex: 1, marginRight: "16px" }}
                        value={this.state.doNotDeliveryBefore}
                        onChange={this.handleDateChange2}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <KeyboardDatePicker
                        autoOk
                        disableToolbar
                        // disablePast
                        margin="normal"
                        disableToolbar
                        variant="inline"
                        id="date-picker-dialog"
                        label="Must Deliver By"
                        minDateMessage="Date cannot be past date"
                        format="dd/MM/yyyy"
                        style={{ flex: 1, marginRight: "16px" }}
                        value={this.state.mustBeDeliveryBy}
                        onChange={this.handleDateChange3}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <KeyboardDatePicker
                        autoOk
                        disableToolbar
                        // disablePast
                        margin="normal"
                        disableToolbar
                        variant="inline"
                        id="date-picker-dialog"
                        label="Must Be Deliver On"
                        minDateMessage="Date cannot be past date"
                        format="dd/MM/yyyy"
                        style={{ flex: 1 }}
                        value={this.state.mustBeDeliveryOn}
                        onChange={this.handleDateChange1}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <p className={classes.subHeader} style={{ marginLeft: 0 }}>
                    Item Information
                  </p>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid #eaeaea",
                        borderBottom: "none",
                        borderRadius: "4px",
                        //boxShadow: "0px 7px 6px #ddd",
                      }}
                    >
                      <Typography
                        component="div"
                        style={{
                          width: "100%",
                          padding: "10px",
                        }}
                      >
                        <Button
                          disabled={this.state.isManual ? false : true}
                          variant="contained"
                          color="primary"
                          style={{
                            boxShadow: "none",
                            padding: "5px 10px 5px 4px",
                            fontSize: "11px",
                          }}
                          onClick={this.handleAddJournalClick}
                        >
                          <CustomSmallAddIcon style={{ fontSize: "18px" }} />{" "}
                          Add Journal
                        </Button>
                      </Typography>
                    </div>
                    {this.state.openDialog ? (
                      <IndividualSearchAddDialog
                        title="Journal Pick Up"
                        mode="BPAOrderPlacement"
                        showDialog={this.state.openDialog}
                        handleAddJournalClose={this.handleAddJournalClose}
                        handleJournalUpdate={this.handleJournalUpdate}
                      />
                    ) : null}
                    <div
                      style={{
                        width: "100%",
                        maxHeight: "calc(100vh - 250px)",
                      }}
                    >
                      <div
                        style={
                          !this.props.clearOpenList
                            ? {
                              overflow: "auto",
                              maxWidth: "calc(100vw - 8em)",
                              maxHeight: "calc(100vh - 300px)",
                              margin: "0 auto",
                            }
                            : {
                              overflow: "auto",
                              maxWidth: "calc(100vw - 389px)",
                              maxHeight: "calc(100vh - 300px)",
                              margin: "0 auto",
                            }
                        }
                      >
                        <Table
                          stickyHeader
                          aria-labelledby="tableTitle"
                          size={"small"}
                          aria-label="enhanced table"
                          style={{
                            borderLeft: "1px solid  rgba(224, 224, 224, 1)",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <EnhancedTableHead1
                            order={this.state.order}
                            orderBy={this.state.orderBy}
                            onRequestSort={this.handleRequestSort}
                            headCells={headCells1}
                          />
                          <TableBody style={{ overflow: "hidden" }}>
                            {this.state.codeTypeList.length > 0 ? (
                              stableSort(
                                this.state.codeTypeList,
                                getSorting(this.state.order, this.state.orderBy)
                              )
                                .slice(
                                  this.state.page * this.state.rowsPerPage,
                                  this.state.page * this.state.rowsPerPage +
                                  this.state.rowsPerPage
                                )
                                .map((row, index) => {
                                  return (
                                    <TableRow
                                      hover
                                      tabIndex={-1}
                                      style={
                                        this.state.selectedEditIndex !== index
                                          ? { cursor: "pointer" }
                                          : {
                                            cursor: "pointer",
                                            background: "#ccc",
                                          }
                                      }
                                    >
                                      {/* {headCells1[0].show && (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-cell"
                                          align="left"
                                        >
                                          {row.orderID &&
                                          row.orderID.length > 16 ? (
                                            <Tooltip
                                              title={row.orderID}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.orderID}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.orderID}
                                            </Typography>
                                          )}
                                        </TableCell>
                                      )} */}
                                      {headCells1[0].show && (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-cell"
                                          align="left"
                                        >
                                          {row.itemDescription &&
                                            row.itemDescription.length > 16 ? (
                                              <Tooltip
                                                title={row.itemDescription}
                                                aria-label="add"
                                              >
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.itemDescription}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.itemDescription}
                                              </Typography>
                                            )}
                                        </TableCell>
                                      )}
                                      {headCells1[1].show && (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-cell"
                                          align="left"
                                        >
                                          {row.journalTitle &&
                                            row.journalTitle.length > 16 ? (
                                              <Tooltip
                                                title={row.journalTitle}
                                                aria-label="add"
                                              >
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.journalTitle}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.journalTitle}
                                              </Typography>
                                            )}
                                        </TableCell>
                                      )}
                                      {headCells1[2].show && (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-cell"
                                          align="left"
                                        >
                                          {row.acronym &&
                                            row.acronym.length > 16 ? (
                                              <Tooltip
                                                title={row.acronym}
                                                aria-label="add"
                                              >
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.acronym}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.acronym}
                                              </Typography>
                                            )}
                                        </TableCell>
                                      )}
                                      {headCells1[3].show && (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-cell"
                                          align="left"
                                        >
                                          {row.volume &&
                                            row.volume.length > 16 ? (
                                              <Tooltip
                                                title={row.volume}
                                                aria-label="add"
                                              >
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.volume}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.volume}
                                              </Typography>
                                            )}
                                        </TableCell>
                                      )}
                                      {headCells1[4].show && (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-cell"
                                          align="left"
                                        >
                                          {row.issue &&
                                            row.issue.length > 16 ? (
                                              <Tooltip
                                                title={row.issue}
                                                aria-label="add"
                                              >
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.issue}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.issue}
                                              </Typography>
                                            )}
                                        </TableCell>
                                      )}
                                      {headCells1[5].show && (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-cell"
                                          align="left"
                                        >
                                          {row.suppNumber &&
                                            row.suppNumber.length > 16 ? (
                                              <Tooltip
                                                title={row.suppNumber}
                                                aria-label="add"
                                              >
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.suppNumber}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.suppNumber}
                                              </Typography>
                                            )}
                                        </TableCell>
                                      )}
                                      {
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-right-cell"
                                          align="right"
                                        >
                                          {row.availableQty}
                                        </TableCell>
                                      }
                                      {
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-right-cell"
                                          align="right"
                                        >
                                          {row.orderedQty}
                                        </TableCell>
                                      }
                                      {/* {this.state.selectedEditIndex !==
                                      index ? (
                                        headCells1[6].show && (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-right-cell"
                                            align="right"
                                          >
                                            {row.partNumber
                                              ? row.partNumber
                                              : ""}
                                          </TableCell>
                                        )
                                      ) : (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-right-cell"
                                        >
                                          <TextField
                                            margin="dense"
                                            id="name"
                                            autoComplete="off"
                                            onKeyPress={this._onNumberKeyPress}
                                            name="newPartNumber"
                                            onChange={this.handleChange}
                                            value={this.state.newPartNumber}
                                          />
                                        </TableCell>
                                      )} */}
                                      {headCells1[6].show && (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-right-cell"
                                          align="left"
                                        >
                                          {row.balQty &&
                                            row.balQty.length > 16 ? (
                                              <Tooltip
                                                title={row.balQty}
                                                aria-label="add"
                                              >
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.balQty}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.balQty}
                                              </Typography>
                                            )}
                                        </TableCell>
                                      )}
                                      {this.state.selectedEditIndex !==
                                        index ? (
                                          headCells1[7].show && (
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              padding="default"
                                              className="table-content-right-cell"
                                              align="right"
                                            >
                                              {row.OrderQuantity
                                                ? row.OrderQuantity
                                                : ""}
                                            </TableCell>
                                          )
                                        ) : (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-right-cell"
                                          >
                                            <TextField
                                              margin="dense"
                                              id="name"
                                              disabled={!row.balQty}
                                              onKeyPress={this._onNumberKeyPress1}
                                              autoComplete="off"
                                              name="newQuantity"
                                              onChange={(e) =>
                                                this.handleChange1(e, row)
                                              }
                                              value={this.state.newQuantity}
                                            />
                                          </TableCell>
                                        )}
                                      {/* {this.state.selectedEditIndex !==
                                      index ? (
                                        headCells1[9].show && (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-right-cell"
                                            align="left"
                                          >
                                            {row.trackingNumber &&
                                            row.trackingNumber.length > 16 ? (
                                              <Tooltip
                                                title={row.trackingNumber}
                                                aria-label="add"
                                              >
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.trackingNumber}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.trackingNumber}
                                              </Typography>
                                            )}
                                          </TableCell>
                                        )
                                      ) : (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-right-cell"
                                        >
                                          <TextField
                                            margin="dense"
                                            id="name"
                                            autoComplete="off"
                                            name="newTrackingNumber"
                                            onChange={this.handleChange}
                                            value={this.state.newTrackingNumber}
                                          />
                                        </TableCell>
                                      )}
                                      {this.state.selectedEditIndex !==
                                      index ? (
                                        headCells1[10].show && (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-right-cell"
                                            align="right"
                                          >
                                            {row.actualWeight
                                              ? row.actualWeight
                                              : ""}
                                          </TableCell>
                                        )
                                      ) : (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-right-cell"
                                        >
                                          <TextField
                                            margin="dense"
                                            id="name"
                                            autoComplete="off"
                                            name="newActualWeight"
                                            onKeyPress={this._onNumberKeyPress}
                                            onChange={
                                              this.handleChangeActualWeight
                                            }
                                            value={this.state.newActualWeight}
                                          />
                                        </TableCell>
                                      )}
                                      {this.state.selectedEditIndex !==
                                      index ? (
                                        headCells1[11].show && (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="default"
                                            className="table-content-cell"
                                            align="left"
                                          >
                                            {row.deliveryDate &&
                                            row.deliveryDate.length > 10 ? (
                                              <Tooltip
                                                title={moment(
                                                  row.deliveryDate
                                                ).format("DD/MM/YYYY")}
                                                aria-label="add"
                                              >
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {moment(
                                                    row.deliveryDate
                                                  ).format("DD/MM/YYYY")}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.deliveryDate
                                                  ? moment(
                                                      row.deliveryDate
                                                    ).format("DD/MM/YYYY")
                                                  : ""}
                                              </Typography>
                                            )}
                                          </TableCell>
                                        )
                                      ) : (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-cell"
                                        >
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardDatePicker
                                              autoOk
                                              disableToolbar
                                              variant="inline"
                                              format="dd/MM/yyyy"
                                              minDateMessage={""}
                                              name="newDeliveryDate"
                                              id="newDeliveryDate"
                                              value={this.state.newDeliveryDate}
                                              onChange={(e) =>
                                                this.handleDateChange(
                                                  e,
                                                  "newDeliveryDate"
                                                )
                                              }
                                              KeyboardButtonProps={{
                                                "aria-label": "change date",
                                              }}
                                            />
                                          </MuiPickersUtilsProvider>
                                        </TableCell>
                                      )} */}
                                      {headCells1[8].show && (
                                        <TableCell
                                          className="table-content-cell"
                                          style={{ textAlign: "center" }}
                                        >
                                          {this.state.selectedEditIndex !==
                                            index ? (
                                              <div>
                                                <Tooltip title="Edit">
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#6c6b6b",
                                                    }}
                                                    onClick={(e) =>
                                                      this.handleEditClicked(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <EditIcon
                                                      style={{
                                                        color: "#6c6b6b",
                                                      }}
                                                    />
                                                  </span>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#6c6b6b",
                                                    }}
                                                    onClick={(e) =>
                                                      this.handleDeleteClicked(
                                                        e,
                                                        index,
                                                        row
                                                      )
                                                    }
                                                  >
                                                    <DeleteIcon
                                                      style={{
                                                        cursor: "pointer",
                                                        color: "#6c6b6b",
                                                      }}
                                                    />
                                                  </span>
                                                </Tooltip>
                                              </div>
                                            ) : (
                                              <div>
                                                {this.state.okToGO ? (
                                                  <Tooltip title="Done">
                                                    <span
                                                      style={{
                                                        cursor: "pointer",
                                                        color: "#6c6b6b",
                                                      }}
                                                      onClick={(e) =>
                                                        this.handleDoneClicked(
                                                          e,
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <DoneIcon
                                                        style={{
                                                          color: "#6c6b6b",
                                                        }}
                                                      />
                                                    </span>
                                                  </Tooltip>
                                                ) : null}
                                                <Tooltip title="Cancel">
                                                  <span
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#6c6b6b",
                                                    }}
                                                    onClick={(e) =>
                                                      this.handleCancelClicked(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <CloseIcon
                                                      style={{
                                                        cursor: "pointer",
                                                        color: "#6c6b6b",
                                                      }}
                                                    />
                                                  </span>
                                                </Tooltip>
                                              </div>
                                            )}
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  );
                                })
                            ) : (
                                <TableRow>
                                  {this.state.loading1 ? (
                                    <TableCell
                                      colSpan={15}
                                      style={{ textAlign: "center" }}
                                    >
                                      <LinearProgress />
                                    </TableCell>
                                  ) : (
                                      <TableCell
                                        colSpan={11}
                                        style={{ textAlign: "center" }}
                                      >
                                        No result found
                                      </TableCell>
                                    )}
                                </TableRow>
                              )}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/bpaOrderPlacement")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div>
                    {this.checkToGo() || this.neverEmpty() ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none", marginLeft: "1em" }}
                        onClick={this.handleDraft}
                      >
                        SAVE AS DRAFT
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none", marginLeft: "1em" }}
                          onClick={this.handleDraft}
                        >
                          SAVE AS DRAFT
                        </Button>
                      )}
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {this.checkToGo() || this.neverEmpty() ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(addDispatchStyles)(AddBpaOrderPlacement));
