import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  Paper,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import {
  updateJournalVersion,
  getCountryList,
  getJournalVersion,
  getCountryDropdownList,
} from "../masterActions";
import { addStyles } from "../../../shared/styles";
import { Autocomplete } from "@material-ui/lab";
import { StateContext } from "../../../shared/globalState";

const maxlength50 = new Validator("maxlength50");

class EditJournalMatrix extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      journalVersion: {
        countryID: 0,
        isROW: false,
        isROE: false,
        isNordic: false,
        priorityOrder: "",
        isEne: false,
        isAsean: false,
        isActive: false,
        country: {},
      },
      updatedValues: false,
      errors: {},
      active: false,
      countryList: [],
      priorityOrderList: [],
      formValid: false,
      priorityOrderValid: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.handleSettingHeader("Journal Version Matrix");
    let journalVersionID = this.props.match.params.journalVersionID;
    const dispatch = this.context[1];
    let priorityOrderList = [];
    getJournalVersion(journalVersionID).then((r) => {
      dispatch({
        type: "getJournalVersionMatrixs",
        newStore: r,
      });
      r.country.countryName =
        r.country.countryCode + " - " + r.country.countryName;
      priorityOrderList = r.priorityOrder && r.priorityOrder.split(", ");
      this.setState({
        journalVersion: r,
        priorityOrderList: priorityOrderList,
      });
      if (r.isActive) this.setState({ active: r.isActive });
      r.country && this.setState({ countryID: r.country.countryID });

      this.validateField("priorityOrder", r.priorityOrder);
    });

    getCountryDropdownList().then((r) => {
      r.data &&
        this.setState({
          countryList: r.data
            //.filter(x => x.isActive === true)
            .sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
    });
  }
  handleChange2 = (event, name) => {
    const { journalVersion, priorityOrderList } = this.state;
    this.setState({ updatedValues: true });

    journalVersion[event.target.name] = event.target.checked;

    this.setState({ journalVersion });
    this.validateField(event.target.name, event.target.value);
  };
  handleChange = (event, name) => {
    const { journalVersion, priorityOrderList } = this.state;
    this.setState({ updatedValues: true });

    if (
      event.target.name === "isROW" ||
      // event.target.name === "isActive" ||
      event.target.name === "isROE" ||
      event.target.name === "isNordic" ||
      event.target.name === "isEne" ||
      event.target.name === "isAsean"
    ) {
      journalVersion[event.target.name] = event.target.checked;
    } else {
      journalVersion[event.target.name] = event.target.value;
    }
    if (journalVersion[event.target.name]) {
      priorityOrderList.push(name);
    } else {
      priorityOrderList.splice(priorityOrderList.indexOf(name), 1);
    }
    journalVersion.priorityOrder = priorityOrderList.join(", ");
    this.setState({ journalVersion });
    this.validateField(event.target.name, event.target.value);
  };

  handleCountryChange = (event, values) => {
    this.setState({ updatedValues: true });
    const { journalVersion } = this.state;
    let countryID = values && values.id;
    journalVersion["country"] = values;
    journalVersion["countryID"] = countryID;
    this.validateField("countryID", countryID);
    this.setState({ journalVersion });
    this.validateForm();
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let priorityOrderValid = this.state.priorityOrderValid;
    switch (fieldName) {
      // case "priorityOrder":
      //   if (value !== "") {
      //     priorityOrderValid = maxlength50(value);
      //     fieldValidationErrors.priorityOrder = priorityOrderValid
      //       ? ""
      //       : " Please enter valid priority order";
      //   } else {
      //     priorityOrderValid = false;
      //     fieldValidationErrors.priorityOrder = "";
      //   }

      //   break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        priorityOrderValid: priorityOrderValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.state.updatedValues &&
      this.setState({
        formValid:
          this.state.priorityOrderValid &&
          this.state.journalVersion.countryID != null &&
          this.state.priorityOrderList.length > 0,
      });
    return this.state.formValid;
  }

  _onKeyPress(event) {
    const re = /[0-9a-zA-Z ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      const { journalVersion } = this.state;

      let journalVersionID = this.props.match.params.journalVersionID;
      let countryID = journalVersion.countryID;
      let isROW = journalVersion.isROW;
      let isROE = journalVersion.isROE;
      let isNordic = journalVersion.isNordic;
      let priorityOrder = journalVersion.priorityOrder;
      let isEne = journalVersion.isEne;
      let isAsean = journalVersion.isAsean;
      let isActive = journalVersion.isActive;
      let modifiedBy = localStorage.loggedInId;

      let reqBody = {
        countryID,
        isROW,
        isROE,
        isNordic,
        priorityOrder,
        isEne,
        isAsean,
        isActive,
        modifiedBy,
      };
      updateJournalVersion(journalVersionID, reqBody, this.props.history);
    }
  };
  render() {
    let { classes } = this.props;
    let { journalVersion, active, countryList } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Journal Version</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <Autocomplete
                    className={classes.comboBox}
                    autoComplete
                    includeInputInList
                    id="country"
                    options={countryList}
                    onChange={this.handleCountryChange}
                    value={journalVersion.country}
                    getOptionLabel={(option) =>
                      option.text || journalVersion.country.countryName
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        margin="normal"
                        label="Country Code"
                        name="country"
                        id="country"
                        style={{ width: "100%" }}
                        error={this.state.errors.country}
                        helperText={
                          this.state.errors.country
                            ? this.state.errors.country
                            : ""
                        }
                      />
                    )}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <FormControlLabel
                    className={classes.checkBox}
                    margin="normal"
                    name="isROW"
                    control={
                      <Checkbox
                        checked={journalVersion.isROW}
                        onChange={(e) => this.handleChange(e, "ROW")}
                        value={journalVersion.isROW}
                        color="primary"
                      />
                    }
                    label="ROW"
                  />
                  <FormControlLabel
                    className={classes.checkBox}
                    margin="normal"
                    name="isROE"
                    control={
                      <Checkbox
                        checked={journalVersion.isROE}
                        onChange={(e) => this.handleChange(e, "ROE")}
                        value={journalVersion.isROE}
                        color="primary"
                      />
                    }
                    label="ROE"
                  />
                  <FormControlLabel
                    className={classes.checkBox}
                    margin="normal"
                    name="isNordic"
                    control={
                      <Checkbox
                        checked={journalVersion.isNordic}
                        onChange={(e) => this.handleChange(e, "NORDIC")}
                        value={journalVersion.isNordic}
                        color="primary"
                      />
                    }
                    label="NORDIC"
                  />

                  <FormControlLabel
                    className={classes.checkBox}
                    margin="normal"
                    name="isEne"
                    control={
                      <Checkbox
                        checked={journalVersion.isEne}
                        onChange={(e) => this.handleChange(e, "ENE")}
                        value={journalVersion.isEne}
                        color="primary"
                      />
                    }
                    label="ENE"
                  />
                  <FormControlLabel
                    className={classes.checkBox}
                    margin="normal"
                    name="isAsean"
                    control={
                      <Checkbox
                        checked={journalVersion.isAsean}
                        onChange={(e) => this.handleChange(e, "ASEAN")}
                        value={journalVersion.isAsean}
                        color="primary"
                      />
                    }
                    label="ASEAN"
                  />
                </div>
                <div>
                  <TextField
                    label="Priority Order"
                    name="priorityOrder"
                    required
                    inputProps={{
                      maxLength: 50,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={journalVersion.priorityOrder}
                    error={this.state.errors.priorityOrder}
                    helperText={
                      this.state.errors.priorityOrder
                        ? this.state.errors.priorityOrder
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      className={classes.checkBox}
                      margin="normal"
                      name="isActive"
                      control={
                        <Checkbox
                          checked={journalVersion.isActive}
                          onChange={this.handleChange2}
                          value={journalVersion.isActive}
                          color="primary"
                        />
                      }
                      label="Active"
                    />
                  )}
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/journalVersionMatrix")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditJournalMatrix));
