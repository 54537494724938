import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import { getProductionLineCode } from "../../masters/masterActions";
import { StateContext } from "../../../shared/globalState";
import { printDetails } from "../../../shared/tableCommonFunctions";

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { selectedProdLineCodeId } = props;
  let acnArr = props.roles.filter(u => u.menuName === "Production Line");
  let acnObj = acnArr[0];

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%"
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_ProdLine/${selectedProdLineCodeId}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={e => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewProductionLine extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      ProductionLine: {
        productionLineID: "",
        productionLineCode: "",
        productionLineName: "",
        productionLineDescription: "",
        isInternal: "",
        isActive: ""
      }
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let productionLineID = this.props.match.params.productionLineID;

    getProductionLineCode(productionLineID).then(r => {
      dispatch({
        type: "getProductionLineCode",
        newStore: r
      });
      this.setState({ ProductionLine: r });
    });
  }

  render() {
    let { ProductionLine } = this.state;
    let productionLineID = this.props.match.params.productionLineID;
    let { status } = "";
    if (ProductionLine.isActive === true) {
      status = "Yes";
    } else {
      status = "No";
    }
    let { status1 } = "";
    if (ProductionLine.isInternal === true) {
      status1 = "Yes";
    } else {
      status1 = "No";
    }
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selectedProdLineCodeId={productionLineID}
            roles={this.props.roles}
          />
          {/* <div className="view-page-header">Cost Per Title Information</div> */}
          <Divider />
          <div className="view-page-container" id="printable">
            <div className="view-page-headings">
              Production Line Information
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Production Line Code</div>
                <div className="view-page-value">
                  {ProductionLine.productionLineCode}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Production Line Name</div>
                <div className="view-page-value">
                  {ProductionLine.productionLineName}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">
                  Production Line Description
                </div>
                <div className="view-page-value">
                  {ProductionLine.productionLineDescription}
                </div>
              </div>
            </div>

            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Internal</div>
                <div className="view-page-value">{status1}</div>
              </div>

              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Active</div>
                <div className="view-page-value">{status}</div>
              </div>
            </div>
            <Divider style={{ marginTop: "25px" }} />
            <div className="button-wrapper">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                  onClick={e => this.props.history.push("/productionLine")}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewProductionLine);
