import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";

import clone from "clone";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import toastr from "toastr";
import Typography from "@material-ui/core/Typography";
import { ViewColumnIcon, ViewIcon, AddIcon } from "../../../shared/customIcons";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import moment from "moment";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

import LinearProgress from "@material-ui/core/LinearProgress";

import { getCustomerList, deleteCustomer } from "../masterActions";

import { NewEditIcon } from "../../../shared/customIcons";
import { StateContext } from "../../../shared/globalState";
import FormGroup from "@material-ui/core/FormGroup";
import AdvanceSearch from "./customerSearch";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { ExportCSV } from "../../../shared/ExportCSV";
import { stableSort, getSorting } from "../../../shared/tableCommonFunctions";
import "../../app/App.css";
const columns = [
  {
    id: 9,
    name: "Phone No",
  },
  {
    id: 10,
    name: "Email",
  },
  {
    id: 11,
    name: "Vendor",
  },
  {
    id: 12,
    name: "Active",
    show: false,
  },
  {
    id: 13,
    name: "Modified Date",
    show: false,
  },
  {
    id: 14,
    name: "Modified By",
    show: false,
  },
  {
    id: 15,
    name: "Created Date",
    show: false,
  },

  {
    id: 16,
    name: "Created By",
    show: false,
  },
];
const baseURL = process.env.REACT_APP_PGS_SERVER_MASTER_DATA_MANAGEMENT;
const pgsService = axios.create({
  baseURL,
});
function createData(
  code,
  name,
  postalCode,
  country,
  addressLine1,
  addressLine2,
  addressLine3,
  contactPerson,
  phoneNo,
  email,
  vendor,
  isActive,
  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate,
  customerID
) {
  return {
    code,
    name,
    postalCode,
    country,
    addressLine1,
    addressLine2,
    addressLine3,
    contactPerson,
    phoneNo,
    email,
    vendor,
    isActive,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
    customerID,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all customers" }}
              //style={{ marginRight: "0px !important" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.code}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    deletePopupOpen,
    handleDeletePopupOpen,
    handleDeletePopupClose,
    selected,
    handleViewColumns,
    handleDeleteCustomer,
    customerList,
    exportDataValues,
    isActive,
  } = props;
  //console.log(selected, numSelected);
  //const selectedCustomerID = selected && selected.length> 0 && selected[0];
  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);

  const selectedCustomerID = selected[0];
  const selectedCustomer = customerList.find(
    (x) => x.customerID === selectedCustomerID
  );
  let acnArr = props.roles.filter((u) => u.menuName === "Customer");
  let acnObj = acnArr[0];

  let expData = clone(customerList);
  expData.map((d) => {
    let Val = "No";
    if (d["isActive"]) {
      Val = "Yes";
    }
    d["Customer Code"] = d["code"];
    d["Customer Name"] = d["name"];
    d["Postal Code"] = d["postalCode"];
    d["Country"] = d["country"];
    d["Address 1"] = d["addressLine1"];
    d["Address 2"] = d["addressLine2"];
    d["Address 3"] = d["addressLine3"];
    d["Contact Person"] = d["contactPerson"];
    d["Phone No"] = d["phoneNo"];
    d["Email"] = d["email"];
    d["Vendor"] = d["vendor"];

    d["Active"] = Val;
    d["Modified By"] = d["modifiedBy"];

    d["Modified Date"] = moment(d["modifiedDate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    );
    d["Created By"] = d["createdBy"];
    d["Created Date"] = moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss");

    delete d["code"];

    delete d["name"];

    delete d["postalCode"];

    delete d["country"];

    delete d["addressLine1"];

    delete d["addressLine2"];

    delete d["addressLine3"];

    delete d["contactPerson"];
    delete d["phoneNo"];

    delete d["email"];

    delete d["vendor"];

    delete d["isActive"];

    delete d["createdBy"];

    delete d["createdDate"];

    delete d["modifiedBy"];

    delete d["modifiedDate"];
    delete d["customerID"];
  });
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteCustomer = () => {
    handleDeleteCustomer();
  };
  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <IconButton
                      aria-label="download"
                      className={classes.iconHover}
                      onClick={(e) =>
                        props.history.push(`/view_customer/${selected[0]}`)
                      }
                    >
                      <ViewIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <IconButton
                      aria-label="clear"
                      className={classes.iconHover}
                      onClick={(e) =>
                        props.history.push(`/manage_customer/${selected[0]}`)
                      }
                    >
                      <NewEditIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={handleClickOpen}
                  disabled={!isActive}
                >
                  <DeleteIcon className={classes.iconHover} />
                </IconButton>
              </Tooltip>
            )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="md"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete Customer
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "300px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1 ? " ?" : selectedCustomer.name + " ?"}
                    {/* {selected.length === 1 ? selectedCustomer.name : ""} */}
                    {/* {selectedCustomer.name} */}
                  </span>
                </div>
              </DialogContent>
              {/* <DialogActions> */}
              <div
                className="button-wrapper"
                style={{ justifyContent: "flex-end" }}
              >
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    //disableElevation
                    style={{ boxShadow: "none" }}
                    onClick={handleClose}
                  >
                    CANCEL
                  </Button>
                </div>
                <div style={{ marginLeft: "1em" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    //disableElevation
                    style={{ boxShadow: "none" }}
                    onClick={deleteCustomer}
                  >
                    Delete
                  </Button>
                </div>
              </div>
              {/* <Button
                  autoFocus
                  onClick={deleteCustomer}
                  variant="contained"
                  color="primary"
                >
                  Delete
                </Button>
                <Button autoFocus onClick={handleClose} color="primary">
                  Close
                </Button> */}
              {/* </DialogActions> */}
            </Dialog>
          </div>
        ) : (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {props.searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={(e) => props.clearSearch()}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  acnObj &&
                  acnObj.advanceSearchAction && (
                    <Tooltip title="Advance search">
                      <AdvanceSearch
                        onSearchClicked={props.onSearchClicked}
                        onRequestSucceed={props.onRequestSucceed}
                      />
                    </Tooltip>
                  )
                )}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="download"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/manage_customer")}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {acnObj && acnObj.exportAction && (
                <Tooltip title="Download">
                  {/* <IconButton aria-label="download" className={classes.iconHover}>
                <DownloadIcon />
              </IconButton> */}
                  <ExportCSV csvData={expData} fileName="Customer" />
                </Tooltip>
              )}
              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                handleViewColumns={handleViewColumns}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
// function getModalStyle() {
//   const top = 14;
//   const left = 78;
//   const bottom = 25;

//   return {
//     //top: `${top}%`,
//     left: `${left}%`,
//     bottom: `${bottom}%`,
//     fontSize: 14
//   };
// }
function getModalStyle() {
  const top = 9;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}
const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    handleViewColumns,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => handleViewColumns(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class CustomerMaster extends React.Component {
  // Replace Storecontext with StateContext
  static contextType = StateContext;
  state = {
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    rows: [],
    activeRows: 0,
    isActive: false,
    loading: false,
    refreshData: false,
    customerList: [],
    selectedCustomer: {},
    searchClicked: false,
    deletePopupOpen: false,
    selectedShowColumns: [],
    exportDataValues: [],
    headCells: [
      {
        code: "code",
        id: 1,
        numeric: false,
        disablePadding: true,
        label: "Customer Code",
        show: true,
      },
      {
        code: "name",
        id: 2,
        numeric: false,
        disablePadding: false,
        label: "Customer Name",
        show: true,
      },
      {
        code: "postalCode",
        id: 3,
        numeric: false,
        disablePadding: false,
        label: "Postal Code",
        show: true,
      },
      {
        code: "country",
        id: 4,
        numeric: false,
        disablePadding: false,
        label: "Country",
        show: true,
      },
      {
        code: "addressLine1",
        id: 5,
        numeric: false,
        disablePadding: false,
        label: "Address1",
        show: true,
      },
      {
        code: "addressLine2",
        id: 6,
        numeric: false,
        disablePadding: false,
        label: "Address2",
        show: true,
      },
      {
        code: "addressLine3",
        id: 7,
        numeric: false,
        disablePadding: false,
        label: "Address3",
        show: true,
      },
      {
        code: "contactPerson",
        id: 8,
        numeric: false,
        disablePadding: false,
        label: "Contact Person",
        show: true,
      },
      {
        code: "phoneNo",
        id: 9,
        numeric: false,
        disablePadding: false,
        label: "Phone No",
        show: true,
      },
      {
        id: 10,
        code: "email",
        numeric: false,
        disablePadding: false,
        label: "Email",
        show: true,
      },
      {
        code: "vendor",
        id: 11,
        numeric: false,
        disablePadding: false,
        label: "Vendor",
        show: true,
      },
      {
        id: 12,
        code: "isActive",
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true,
      },
      {
        id: 13,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 14,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 15,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 16,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };
  handleViewColumns = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };
  componentDidMount() {
    // const list = this.context;
    // include store, dispatch from context
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Customer");
    this.props.setSelectedList("masterDataOpen", "customer");

    getCustomerList(window.localStorage.token)
      .then((r) => {
        dispatch({
          type: "getCustomerList",
          newStore: r.data,
        });
        this.getAllCustomers(r.data);
        this.exportDatas(r.data);
      })
      .catch((e) => {
        // throw new Error("I crashed!");
      });
    this.setState({ loading: true });
  }
  // onSearchClicked=()=>{

  // }
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.customerList && store.customerList.length > 0)
      if (this.state.rows.length !== store.customerList.length) {
        !this.state.searchClicked && this.getAllCustomers(store.customerList);
      }
    // if (this.state.rows.length !== 0) {
    //   this.state.rows.map((cl, i) => {
    //     if (cl.isActive !== store.customerList[i].isActive)
    //       !this.state.searchClicked && this.getAllCustomers(store.customerList);
    //   });
    // }

    return true;
  }
  onRequestSucceed = (deleted) => {
    const [{ store }, dispatch] = this.context;
    getCustomerList().then((r) => {
      dispatch({
        type: "getCustomerList",
        newStore: r.data,
      });
      if (deleted) this.setState({ searchClicked: false });
      this.getAllCustomers(store.customerList);
    });
  };
  exportDatas = (customerList) => {
    let list = customerList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          CustomerName: d.customerName,
          PostalCode: d.postalCode,
          Country: d.country,
          ContactPersonName: d.contactPersonName,
          PhoneNumber: d.phoneNumber,
          EmailID: d.emailID,
          Vendor: d.isVendor === true ? "Yes" : "No",
          Active: d.isActive === true ? "Yes" : "No",
          CreatedBy: d.createdBy,
          CreatedDate: d.createdDate,
          ModifiedBy: d.modifiedBy,
          ModifiedDate: d.modifiedDate,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  sortStateList = (list) => {
    let z = list.sort(function (a, b) {
      let x = a.isActive && a.isActive;
      let y = b.isActive && b.isActive;
      let u = a.modifiedDate && a.modifiedDate;
      let v = b.modifiedDate && b.modifiedDate;
      if (x < y) {
        return 1;
      }
      if (u < v) {
        return -1;
      }
      if (x > y) {
        return -1;
      }
      if (u > v) {
        return 1;
      }
      return 0;
    });
    // console.log(z);
    this.setState({ rows: z });
  };

  getAllCustomers = (customerList) => {
    let newRows = [];
    customerList &&
      customerList.length > 0 &&
      customerList.map((c) => {
        return newRows.push(
          createData(
            c.customerCode,
            c.customerName,
            c.postalCode,
            c.country,
            c.addressLine1,
            c.addressLine2,
            c.addressLine3,
            c.contactPersonName,
            c.phoneNumber,
            c.emailID,
            c.isVendor,
            c.isActive,
            c.createdBy,
            c.createdDate,
            c.modifiedBy,
            c.modifiedDate,
            c.customerID
          )
        );
      });
    this.sortStateList(newRows);
    this.setState({
      rows: newRows,
      selected: [],
      loading: false,
      activeRows: newRows.filter((x) => x.isActive).map((n) => n.customerID),
    });
  };
  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { rows } = this.state;
    if (event.target.checked) {
      const newSelecteds = rows
        .filter((x) => x.isActive === true)
        .map((n) => n.customerID);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  // handleClick = (event, code, data, customerID) => {
  handleClick = (event, data) => {
    //console.log(data);
    let customerID = data.customerID;
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(customerID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, customerID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    //console.log(newSelected);
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedCustomer: data,
        selectedCustomerID: customerID,
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        // selectedCustomer: {}
        selectedCustomer: data,
        selectedCustomerID: customerID,
      });
    }
  };
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleDeleteCustomer = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let deletionIds = selected.join(",");

    deleteCustomer(deletionIds, this.props.history).then((r) => {
      getCustomerList().then((r) => {
        dispatch({
          type: "getCustomerList",
          newStore: r.data,
        });
        this.getAllCustomers(r.data);
      });
      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };

  onSearchClicked = () => {
    this.setState({ searchClicked: true, page: 0 });
  };
  clearSearch = () => {
    const [{ store }, dispatch] = this.context;

    getCustomerList().then((r) => {
      dispatch({
        type: "getCustomerList",
        newStore: r.data,
      });
      this.getAllCustomers(store.customerList);
      this.setState({ searchClicked: false });
    });
  };
  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      rows,
      selectedCustomer,
      headCells,
      exportDataValues,
    } = this.state;

    const isSelected = (customerID) => selected.indexOf(customerID) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            onRequestSucceed={this.onRequestSucceed}
            selectedCustomer={selectedCustomer}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            onSearchClicked={this.onSearchClicked}
            searchClicked={this.state.searchClicked}
            clearSearch={this.clearSearch}
            selected={this.state.selected}
            handleDeleteCustomer={this.handleDeleteCustomer}
            handleViewColumns={this.handleViewColumns}
            headCells={headCells}
            rows={rows}
            customerList={rows}
            setSelectedList={this.props.setSelectedList}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            roles={this.props.roles}
          />

          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
                style={{ whiteSpace: "nowrap" }}
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                {/* <caption style={{ borderBottom: "1px solid #eaeaea" }}>
                  A barbone structure table example with a caption
                </caption> */}
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />

                <TableBody style={{ overflow: "hidden" }}>
                  {rows.length > 0 ? (
                    stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.customerID);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              this.handleClick(
                                event,
                                //row.code,
                                row
                                // row.customerID
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.code} */}
                                {row.code && row.code.length > 16 ? (
                                  <Tooltip title={row.code} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.code}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.code}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                // className="table-content-cell"
                              >
                                {/* {row.name} */}
                                {row.name && row.name.length > 16 ? (
                                  <Tooltip title={row.name} aria-label="add">
                                    <Typography
                                       noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.name}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.name}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.postalCode} */}
                                {row.postalCode &&
                                  row.postalCode.length > 16 ? (
                                    <Tooltip
                                      title={row.postalCode}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.postalCode}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.postalCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.country} */}
                                {row.country && row.country.length > 16 ? (
                                  <Tooltip title={row.country} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.country}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.country}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.addressLine1} */}
                                {row.addressLine1 &&
                                  row.addressLine1.length > 16 ? (
                                    <Tooltip
                                      title={row.addressLine1}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.addressLine1}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.addressLine1}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.addressLine2} */}
                                {row.addressLine2 &&
                                  row.addressLine2.length > 16 ? (
                                    <Tooltip
                                      title={row.addressLine2}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.addressLine2}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.addressLine2}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.addressLine3} */}
                                {row.addressLine3 &&
                                  row.addressLine3.length > 16 ? (
                                    <Tooltip
                                      title={row.addressLine3}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.addressLine3}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.addressLine3}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.contactPerson} */}
                                {row.contactPerson &&
                                  row.contactPerson.length > 16 ? (
                                    <Tooltip
                                      title={row.contactPerson}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.contactPerson}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.contactPerson}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              //className="table-content-right-cell"
                              >
                                {/* {row.phoneNo} */}
                                {row.phoneNo && row.phoneNo.length > 16 ? (
                                  <Tooltip title={row.phoneNo} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.phoneNo}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.phoneNo}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.email} */}
                                {row.email && row.email.length > 16 ? (
                                  <Tooltip title={row.email} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.email}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.email}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {!!row.vendor ? "Yes" : "No"}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {!!row.isActive ? "Yes" : "No"}
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.modifiedBy} */}
                                {row.modifiedBy &&
                                  row.modifiedBy.length > 16 ? (
                                    <Tooltip
                                      title={row.modifiedBy}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedBy}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[15].show && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.createdBy} */}
                                {row.createdBy && row.createdBy.length > 16 ? (
                                  <Tooltip
                                    title={row.createdBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={12} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </div>
          </div>

          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(CustomerMaster);
