import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";
import clone from "clone";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import ReportViewer from "../../app/ReportViewer";
import { withRouter } from "react-router-dom";
import TableHead from "@material-ui/core/TableHead";
import { EditIcon } from "../../../shared/customIcons";
import { Divider, Tooltip } from "@material-ui/core";
import toastr from "toastr"
import { TextField } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import DeleteIcon from "@material-ui/icons/Delete";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CheckIcon from "@material-ui/icons/Check";
import { Autocomplete } from "@material-ui/lab";
import { StateContext } from "../../../shared/globalState";
import { addDispatchStyles } from "../../../shared/styles";
import { addZeros } from "../../../shared/tableCommonFunctions";
import {
  getjobScheduleProductionDropDownList,
  getProcessCategory,
} from "../../masters/masterActions";
import {
  GetOWCByWOID,
  getprocessCategoryDropDownList,
  CreateOWC,
  getjobScheduleStatusDropDownList,
  getOWCDetailsForView,
  UpdateOWC,
  deleteOWC,
  GetOWCByWOIDClone,
  deleteOWCByID,
} from "../workOrder/action";

import { getOWCDescriptionPerCategoryDropDown } from "../../masters/masterActions";

import moment from "moment";
const headCells = [
  {
    id: "processCategoryName",
    numeric: false,
    disablePadding: true,
    label: "Category Name *",
  },
  {
    id: "processDescription",
    numeric: false,
    disablePadding: false,
    label: "Description *",
  },
  {
    id: "processInstruction",
    numeric: false,
    disablePadding: false,
    label: "Instruction",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity ",
  },
  {
    id: "rate",
    numeric: false,
    disablePadding: false,
    label: "Rate (S$) ",
  },
  {
    id: "addedQuantity",
    numeric: false,
    disablePadding: false,
    label: "Added Quantity ",
  },
  {
    id: "spRate",
    numeric: false,
    disablePadding: false,
    label: "SpRate (S$) ",
  },
  {
    id: "totalAmount",
    numeric: false,
    disablePadding: false,
    label: "Total Amount",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

const headCells1 = [
  {
    id: "processCategoryName",
    numeric: false,
    disablePadding: true,
    label: "Category Name ",
  },
  {
    id: "processDescription",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "processInstruction",
    numeric: false,
    disablePadding: false,
    label: "Instruction",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "rate",
    numeric: false,
    disablePadding: false,
    label: "Rate ($S)",
  },
  {
    id: "addedQuantity",
    numeric: false,
    disablePadding: false,
    label: "Added Quantity",
  },
  {
    id: "spRate",
    numeric: false,
    disablePadding: false,
    label: "SpRate ($S)",
  },
  {
    id: "totalAmount",
    numeric: false,
    disablePadding: false,
    label: "Total Amount",
  },
];

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderTop: "1px solid #eaeaea",
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow style={{ background: "#eaeaea" }}>
        {props.currentLocation === "view"
          ? headCells1.map((headCell, i) => (
            <React.Fragment>
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
                style={{ borderLeft: "1px sold #000" }}
              >
                <span style={{ color: "#306dca" }}>{headCell.label}</span>
              </TableCell>
            </React.Fragment>
          ))
          : headCells.map((headCell, i) => (
            <React.Fragment>
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
                style={{ borderLeft: "1px sold #000", }}
              >
                <span style={{ color: "#306dca" }}>{headCell.label}</span>
              </TableCell>
            </React.Fragment>
          ))}
      </TableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { } = props;
  return (
    <div>
      {props.currentLocation === "view" ? (
        <div
          style={{
            display: "flex",
            marginTop: "1em",
          }}
        />
      ) : (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                //   width: "100%",
                border: "1px solid #eaeaea",
                borderBottom: "none",
                borderRadius: "4px",
                marginTop: "1em",
              }}
            >
              {props.disableAdd ? (
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  style={{
                    boxShadow: "none",
                    padding: "2px",
                    margin: ".5em",
                    fontSize: "11px",
                  }}
                  onClick={(e) => props.handleClickButton(e, "add")}
                // style={
                //   !props.disableAdd
                //     ? {
                //         color: "#fff",
                //         background: "#3982ea",
                //       }
                //     : { color: "#3982ea", fontSize: "11px" }
                // }
                >
                  <AddIcon style={{ fontSize: "18px" }} /> Add
                </Button>
              ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      boxShadow: "none",
                      padding: "2px",
                      margin: ".5em",
                      fontSize: "11px",
                    }}
                    onClick={(e) => props.handleClickButton(e, "add")}
                  // style={
                  //   !props.disableAdd
                  //     ? {
                  //         color: "#fff",
                  //         background: "#3982ea",
                  //       }
                  //     : { color: "#3982ea", fontSize: "11px" }
                  // }
                  >
                    <AddIcon style={{ fontSize: "18px" }} /> Add
                  </Button>
                )}
              {props.disableEdit ? (
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  style={{
                    boxShadow: "none",
                    padding: "2px",
                    margin: ".5em",
                    fontSize: "11px",
                  }}
                  // style={
                  //   !props.disableEdit
                  //     ? {
                  //         color: "#fff",
                  //         background: "#3982ea",
                  //       }
                  //     : { color: "#3982ea", fontSize: "11px" }
                  // }
                  onClick={(e) => props.handleClickButton(e, "reset")}
                >
                  <SyncIcon style={{ fontSize: "18px" }} /> Reset
                </Button>
              ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      boxShadow: "none",
                      padding: "2px",
                      margin: ".5em",
                      fontSize: "11px",
                    }}
                    // style={
                    //   !props.disableEdit
                    //     ? {
                    //         color: "#fff",
                    //         background: "#3982ea",
                    //       }
                    //     : { color: "#3982ea", fontSize: "11px" }
                    // }
                    onClick={(e) => props.handleClickButton(e, "reset")}
                  >
                    <SyncIcon style={{ fontSize: "18px" }} /> Reset
                  </Button>
                )}
            </div>
          </React.Fragment>
        )}
    </div>
  );
};

class CreateOWCReport extends React.Component {
  static contextType = StateContext;
  state = {
    disableAdd: false,
    disableEdit: true,
    list: [],
    customer: "",
    workOrder: "",
    title: "",
    planner: "",
    plannereName: "",
    mbaNo: "",
    productionLineName: "",
    productionLineID: "",
    processDescription: "",
    processID: "",
    processCategoryName: "",
    processCategoryID: "",
    specialInstruction: "",
    defaultGST: 0,
    customerList: [],
    workOrderID: "",
    workOrderName: "",
    categoryList: [],
    billingDate: "",
    owcHeaderID: "",
    subordinate: [],
    currentLocation: "",
    processList: [],
    showPopup: false,
    isTicked: false,
  };

  handleShowPopup = () => {
    this.setState({
      showPopup: true,
    });
  };

  handleHidePopup = () => {
    this.setState({
      showPopup: false,
    });
  };

  onBlur = (event, name, index) => {
    let newList = clone(this.state.list);
    let values = event.target.value;
    values = this.addZeroes(values.toString(), 4);
    newList[index][name] = values;
    this.setState({ list: newList });
  };

  addZeroes(num, decimalPlaces) {
    // console.log(num, decimalPlaces);
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    return value;
  }

  componentDidMount() {
    this.props.handleSettingHeader("Work Order");
    this.props.setSelectedList("genericSystemOpen", "workOrder");
    this.props.handleDrawerClose();
    getjobScheduleStatusDropDownList().then((r) => {
      this.getAllCustomer(r.data);
    });

    const [{ store }, dispatch] = this.context;
    let wkOrderId = "";
    if (process.browser)
      wkOrderId = this.props.history.location.search.split("=")[1];
    // wkOrderId = window.location.pathname.split("/")[2];

    let newList = clone(this.state.list);
    {
      this.props.history.location.state.owcReportMode === "add" &&
        GetOWCByWOID(wkOrderId).then((r) => {
          this.setState({
            customer: r.custmerName,
            workOrder: r.workOrderNumber,
            title: r.orderTitle,
            planner: r.planner ? r.planner : "",
            plannereName: r.plannereName,
            owcNo: r.owcNo ? r.owcNo : "",
            workOrderID: r.workOrderID,
            workOrderName: r.workOrderName,
            billingDate: r.billingDate,
            owcHeaderID: r.owcHeaderID,
            processID: r.processID,
          });
          r.getOutWorkerClaimDetails &&
            r.getOutWorkerClaimDetails.length > 0 &&
            r.getOutWorkerClaimDetails.map((l) => {
              newList.push({
                processCategoryName: l.processCategoryName,
                processDescription: l.processDescription,
                processInstruction: l.processInstruction,
                quantity: l.quantity,
                rate: addZeros(l.rate.toString(), 4),
                addedQuantity: l.addedQuantity,
                spRate: addZeros(l.spRate.toString(), 4),
                totalAmount: l.quantity * l.rate + (l.addedQuantity + l.spRate),
                owcHeaderID: l.owcHeaderID,
                processID: l.processID,
                createdBy: window.localStorage.loggedInId,
                jobGroupID: l.jobGroupID,
                balanceQty: l.maxProcessQuantity
              });
            });

          this.setState({
            list: newList,
            disableEdit: false,
            subordinate: newList,
          });
        });
    }

    if (
      this.props.history.location.state.owcReportMode === "view" ||
      this.props.history.location.state.owcReportMode === "edit"
    ) {
      let owcHeaderID = this.props.history.location.search.split("=")[1];

      getOWCDetailsForView(owcHeaderID).then((r) => {
        this.setState({
          customer: r.custmerName,
          workOrder: r.workOrderNumber,
          title: r.orderTitle,
          planner: r.plannereName ? r.plannereName : r.plannerID,
          plannereName: r.plannereName,
          owcNo: r.owcNo ? r.owcNo : "",
          workOrderID: r.workOrderName,
          workOrderName: r.workOrderName,
          billingDate: r.billingDate,
          owcHeaderID: r.owcHeaderID,
          processID: r.processID,
          productionLineName: r.productionLineName,
          productionLineID: r.productionLineID,
        });
        r.getOutWorkerClaimDetails &&
          r.getOutWorkerClaimDetails.length > 0 &&
          r.getOutWorkerClaimDetails.map((l) => {
            newList.push({
              processCategoryName: l.processCategoryName,
              processDescription: l.processDescription,
              processInstruction: l.processInstruction,
              quantity: l.quantity,
              rate: addZeros(l.rate.toString(), 4),
              addedQuantity: l.addedQuantity,
              spRate: addZeros(l.spRate.toString(), 4),
              totalAmount: l.totalAmount,
              owcHeaderID: l.owcHeaderID ? l.owcHeaderID : null,
              processID: l.processID,
              createdBy: window.localStorage.loggedInId,
              owcDetailID: l.owcDetailID ? l.owcDetailID : 0,
              createdBy: window.localStorage.loggedInId,
              modifiedBy: window.localStorage.loggedInId,
              jobGroupID: l.jobGroupID ? l.jobGroupID : 0,
              balanceQty: l.maxProcessQuantity
            });
          });

        this.setState({
          list: newList,
          disableEdit: false,
          subordinate: newList,
        });
      });
    }

    if (this.props.history.location.state.owcReportMode === "clone") {
      let owcHeaderID = this.props.history.location.search.split("=")[1];

      GetOWCByWOIDClone(owcHeaderID).then((r) => {
        this.setState({
          customer: r.custmerName,
          workOrder: r.workOrderNumber,
          title: r.orderTitle,
          planner: r.plannereName ? r.plannereName : r.plannerID,
          plannereName: r.plannereName,
          owcNo: r.owcNo ? r.owcNo : "",
          workOrderID: r.workOrderID,
          workOrderName: r.workOrderName,
          billingDate: r.billingDate,
          owcHeaderID: r.owcHeaderID,
          processID: r.processID,
          productionLineName: r.productionLineName,
          productionLineID: r.productionLineID,
        });
        r.getOutWorkerClaimDetails &&
          r.getOutWorkerClaimDetails.length > 0 &&
          r.getOutWorkerClaimDetails.map((l) => {
            newList.push({
              processCategoryName: l.processCategoryName,
              processDescription: l.processDescription,
              processInstruction: l.processInstruction,
              quantity: l.quantity,
              rate: addZeros(l.rate.toString(), 4),
              addedQuantity: l.addedQuantity,
              spRate: addZeros(l.spRate.toString(), 4),
              totalAmount: l.totalAmount ? l.totalAmount : 0,
              owcHeaderID: l.owcHeaderID ? l.owcHeaderID : null,
              processID: l.processID,
              createdBy: window.localStorage.loggedInId,
              owcDetailID: l.owcDetailID ? l.owcDetailID : 0,
              createdBy: window.localStorage.loggedInId,
              modifiedBy: window.localStorage.loggedInId,
              jobGroupID: l.jobGroupID ? l.jobGroupID : 0,
              balanceQty: l.maxProcessQuantity
            });
          });

        this.setState({
          list: newList,
          disableEdit: false,
          subordinate: newList,
        });
      });
    }

    getjobScheduleProductionDropDownList().then((r) => {
      this.getAllCustomer(r.data);
    });

    getProcessCategory().then((r) => {
      dispatch({
        type: "getProcessTypeList",
        newStore: r.data,
      });
      this.getAllCategory(r.data);
    });

    // getProcessDropDown().then(r => {
    //   dispatch({
    //     type: "getProcessTypeList",
    //     newStore: r.data
    //   });
    //   this.getAllProcess(r.data);
    // });
  }

  // getAllProcess = list => {
  //   this.setState({
  //     processList:
  //       list &&
  //       list.sort((a, b) =>
  //         a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
  //       )
  //   });
  // };

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    return value;
  }

  getAllCustomer = (list) => {
    this.setState({
      customerList:
        list &&
        list.sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
        ),
    });
  };

  getAllCategory = (list) => {
    let categoryList = [];
    list &&
      list.length > 0 &&
      list
        .filter((r) => r.value !== "DS")
        .map((c) => {
          categoryList.push({ key: c.id, value: c.value, name: c.text });
        });
    this.setState({ categoryList });
  };

  handleClickButton = (e, name) => {
    let newList = clone(this.state.list);
    let newObj = {
      processCategoryName: "",
      processDescription: "",
      processInstruction: "",
      quantity: 0,
      rate: 0.0,
      addedQuantity: 0,
      spRate: 0.0,
      totalAmount: 0,
      editClicked: true,
      manualEntry: true,
      processID: "",
      createdBy: window.localStorage.loggedInId,
    };
    newList.push(newObj);

    if (name === "add") {
      this.setState({
        list: newList,
        editableList: newList,
        // disableAdd: true,
        disableEdit: false,
        OrderGroupID: 0,
        isTicked: true,
      });
    } else {
      this.setState({
        list: [],
        editableList: [],
        disableAdd: false,
        disableEdit: true,
        fileName: "",
        OrderGroupID: 0,
        isTicked: false,
      });
    }
  };
  onEditClicked = async (e, i, editClicked) => {
    let newEditableList = clone(this.state.list);
    newEditableList[i].editClicked = !newEditableList[i].editClicked;
    this.setState({
      list: newEditableList,
    });
    if (newEditableList[i].editClicked) {
      this.setState({
        isTicked: true,
      });
    } else {
      this.setState({
        isTicked: false,
      });
    }
  };
  onDeleteClicked = (e, i) => {
    let newList = clone(this.state.list);
    newList[i].editClicked = false;
    newList.splice(i, 1);
    this.setState({
      list: newList,
      isTicked: false,
    });
  };

  onDeleteApiClicked = (e, i, owcDetailID) => {
    let owcHeaderID = this.props.history.location.search.split("=")[1];
    let newList = clone(this.state.list);
    deleteOWCByID(owcDetailID).then((r) => {
      newList[i].editClicked = false;
      newList.splice(i, 1);

      this.setState({
        list: newList,
        disableEdit: false,
        subordinate: newList,
      });
      if (r) {
        this.handleSubmit();
      }
    });
    // window.location.reload();
  };

  onSPLInstructionChanged = (e) => {
    this.setState({ specialInstruction: e.target.value });
  };

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, 4);
    }
    return value;
  }

  onExcelDataChanged = (e, name, i) => {
    let newList = clone(this.state.list);
    newList[i][name] = e.target.value;
    if (name === "quantity") {
      if (newList[i].balanceQty || newList[i].balanceQty === 0) {
        if (e.target.value > newList[i].balanceQty) {
          toastr.error(`Process Qty should not exceed the WO process Qty ${newList[i].balanceQty}`)
          newList[i][name] = newList[i].balanceQty === 0 ? 0 : this.state.list[i].quantity
          return
        }
      }
      console.log("after return")
      newList[i].totalAmount =
        newList[i].quantity * newList[i].rate +
        newList[i].addedQuantity * newList[i].spRate;
    }
    if (name === "rate") {
      let r = e.target.value;
      r = this.checkDHlWeightDec1(e.target.value.toString());

      newList[i].rate = r;
      newList[i].totalAmount =
        newList[i].quantity * newList[i].rate +
        newList[i].addedQuantity * newList[i].spRate;
    }

    if (name === "addedQuantity") {
      let r = e.target.value;
      r = this._onNumberWithNegativeKeyPress(e.target.value);

      newList[i].addedQuantity = r;
      newList[i].totalAmount =
        isNaN(newList[i].quantity * newList[i].rate +
          newList[i].addedQuantity * newList[i].spRate) ? 0 : newList[i].quantity * newList[i].rate +
          newList[i].addedQuantity * newList[i].spRate;
    }
    if (name === "spRate") {
      let r = e.target.value;
      r = this.checkDHlWeightDec1(e.target.value.toString());

      newList[i].spRate = r;
      newList[i].totalAmount =
        newList[i].quantity * newList[i].rate +
        newList[i].addedQuantity * newList[i].spRate;
    }
    this.setState({ list: newList });
  };
  _onAlphaKeyPress(event) {
    const re = /[a-zA-Z 0-9. ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  checkDHlWeightDec1(value) {
    var re = /^[0-9]\d*(?:\.\d{0,4})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onNumberWithMinusKeyPress(event) {
    const re = /^-?[0-9]*$/;
    console.log("re.test(event.key)", re.test(event.key), event.key);
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onNumberWithNegativeKeyPress(value) {
    // const re = /^-?[0-9]\d*(\.\d+)?$/;
    const re = /^-?[0-9]*$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  handleSubmit = (e) => {
    if (
      this.props.history.location.state.owcReportMode === "add" ||
      this.props.history.location.state.owcReportMode === "clone"
    ) {
      let newList = clone(this.state.list);
      if (!this.state.productionLineName && !this.state.productionLineID) {
        document.getElementById("productionLineID-index") &&
          document.getElementById("productionLineID-index").focus();
        return;
      }
      let GrandTotalAmount = 0;
      let workOrderID = this.state.workOrderID;
      let productionLineID = this.state.productionLineID;

      newList.map((l) => {
        GrandTotalAmount = GrandTotalAmount + l.totalAmount;
      });

      let isActive = true;
      let createdBy = window.localStorage.loggedInId;
      let createOutWorkerClaimDetails = this.state.list.map((val) => {
        return val;
      });

      let reqBody = {
        workOrderID,
        productionLineID,
        GrandTotalAmount,
        isActive,
        createdBy,
        createOutWorkerClaimDetails,
      };

      let wkOrderId = this.props.history.location.state.wkOrderId;

      CreateOWC(reqBody, this.props.history, wkOrderId).then((r) => { });
    } else if (this.props.history.location.state.owcReportMode === "edit") {
      let owcHeaderID = this.props.history.location.search.split("=")[1];

      let newList = clone(this.state.list);
      let productionLineID = this.state.productionLineID;

      let isActive = true;
      let modifiedBy = window.localStorage.loggedInId;
      let grandTotalAmount = 0;
      newList.map((l) => {
        grandTotalAmount = grandTotalAmount + l.totalAmount;
      });
      let updateOutWorkerClaimDetails = clone(this.state.list);


      let reqBody = {
        productionLineID,
        isActive,
        modifiedBy,
        grandTotalAmount,
        updateOutWorkerClaimDetails,
      };

      let wkOrderId = this.props.history.location.state.wkOrderId;
      UpdateOWC(owcHeaderID, reqBody, this.props.history, wkOrderId);
    }
  };

  handleProductionLineIdChange = (event, index) => {
    let lastIndexValue = event.target.id.split("option-")[1];
    this.setState({
      productionLineName:
        this.state.customerList &&
        this.state.customerList[lastIndexValue] &&
        this.state.customerList[lastIndexValue].value,
      productionLineID:
        this.state.customerList &&
        this.state.customerList[lastIndexValue] &&
        this.state.customerList[lastIndexValue].id,
    });
  };

  handleProcessCategoryChange = async (event, index, values) => {
    let listDD = [...this.state.processList];
    let newList = [...this.state.list];
    let id = "";
    if (values) {
      newList[index]["processCategoryName"] = values.name;
      newList[index]["processCategoryID"] = values.key;
      newList[index]["processDescription"] = "";
      newList[index]["processID"] = "";
    } else {
      newList[index]["processCategoryName"] = "";
      newList[index]["processCategoryID"] = "";
      newList[index]["processDescription"] = "";
      newList[index]["processID"] = "";
      listDD = [];
    }

    this.setState({ list: newList, processList: listDD });
    if (newList[index]["processCategoryID"]) {
      await getOWCDescriptionPerCategoryDropDown(
        newList[index]["processCategoryID"]
      ).then((r) => {
        this.setState({
          processList:
            r &&
            r.sort((a, b) =>
              a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
            ),
        });
      });
    }
  };

  handleProcessChange = (event, index, values) => {
    let newList = [...this.state.list];
    let id = "";
    if (values) {
      newList[index]["processDescription"] = values.text;
      newList[index]["processID"] = values.id;
    } else {
      newList[index]["processDescription"] = "";
      newList[index]["processID"] = "";
    }

    this.setState({ list: newList });
  };

  render() {
    let {
      customer,
      title,
      plannereName,
      list,
      owcNo,
      customerList,
      productionLineName,
      processCategoryName,
      categoryList,
      workOrderName,
      processList,
      processDescription,
    } = this.state;

    let productionLineIds =
      customerList &&
      customerList.map((val) => {
        return val.value;
      });
    let categoryProcessList =
      categoryList &&
      categoryList.map((val) => {
        return val.name;
      });
    let processIds =
      processList &&
      processList.map((val) => {
        return val.value;
      });

    let newList = clone(list);
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">
            {this.props.history.location.state.owcReportMode === "view"
              ? "View Out Worker"
              : this.props.history.location.state.owcReportMode === "edit"
                ? "Edit Out Worker"
                : this.props.history.location.state.owcReportMode === "clone" || this.props.history.location.state.newClone === "newClone"
                  ? "Clone Out Worker"
                  : "Out Worker Claim"}{" "}
          </div>
          <Divider />
          <div className="view-page-container" id="printable">
            <div
              className="blueBox"
              style={{ width: "auto", marginTop: "2px" }}
            >
              <div className="blueBoxLabel">
                <span>Customer : </span>
                <span className="blueBoxText">{customer}</span>
              </div>
              <div className="blueBoxLabel">
                <span>Work Order : </span>
                <span className="blueBoxText">{workOrderName}</span>
              </div>
              <div className="blueBoxLabel">
                <span>Planner : </span>
                <span className="blueBoxText">{plannereName}</span>
              </div>
            </div>
            <div
              className="blueBox"
              style={{ width: "auto", marginTop: "2px" }}
            >
              <div className="blueBoxLabel">
                <span>Order Title : </span>
                <span className="blueBoxText">{title}</span>
              </div>
              {this.props.history.location.state.owcReportMode === "view" && <div className="blueBoxLabel">
                <span>OWC No : </span>
                <span className="blueBoxText">{owcNo}</span>
              </div>}
              {this.props.history.location.state.owcReportMode === "view" ? (
                <div className="blueBoxLabel">
                  <span>Production Line : </span>
                  <span className="blueBoxText">{productionLineName}</span>
                </div>
              ) : (
                  <div className="blueBoxLabel">
                    <span></span>
                    <span className="blueBoxText"></span>
                  </div>
                )}
            </div>
            {this.props.history.location.state.owcReportMode ===
              "view" ? null : (
                <div style={{ marginLeft: "-8px" }}>
                  <Autocomplete
                    options={productionLineIds}
                    value={productionLineName}
                    id="productionLineID-index"
                    name="productionLineID"
                    label="Production Line"
                    includeInputInList
                    onChange={(e) =>
                      this.handleProductionLineIdChange(e, "productionLineID")
                    }
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Production Line"
                        style={{
                          width: "31%",
                          fontSize: "11px !important",
                          marginLeft: ".5em",
                          marginRight: ".5em",
                          maxHeight: "400px",
                        }}
                        // name="plannerId"
                        margin="normal"
                        required
                      />
                    )}
                  />
                </div>
              )}
            <div>
              <div>
                <EnhancedTableToolbar
                  //numSelected={selected.length}
                  handleClickButton={this.handleClickButton}
                  disableAdd={this.state.disableAdd}
                  disableEdit={this.state.disableEdit}
                  currentLocation={
                    this.props.history.location.state.owcReportMode === "view"
                      ? "view"
                      : this.props.history.location.state.owcReportMode ===
                        "edit"
                        ? "edit"
                        : this.props.history.location.state.owcReportMode ===
                          "clone"
                          ? "clone"
                          : this.props.history.location.state.owcReportMode ===
                            "add"
                            ? "add"
                            : null
                  }
                />
              </div>
              <div
                style={{
                  // width: "900px",
                  maxHeight: "400px",
                  margin: "0  0 1em",
                  borderLeft: "1px Solid #eaeaea",
                  // borderRight: "1px solid #eaeaea"
                }}
              >
                <div
                  // style={{ maxHeight: 350, overflow: "auto" }}
                  style={
                    !this.props.clearOpenList
                      ? {
                        overflow: "auto",
                        border: "1px solid #eaeaea",
                        maxWidth: "calc(100vw - 8em)",
                        maxHeight: "calc(100vh - 300px)",
                      }
                      : {
                        overflow: "auto",
                        border: "1px solid #eaeaea",
                        maxWidth: "calc(100vw - 330px)",
                        maxHeight: "calc(100vh - 300px)",
                      }
                  }
                >
                  {/* //overflow: "auto" */}
                  <Table
                    aria-labelledby="tableTitle"
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                  // style={{ width: "1800px", overflow: "auto" }}
                  >
                    {" "}
                    <EnhancedTableHead
                      currentLocation={
                        this.props.history.location.state.owcReportMode ===
                          "view"
                          ? "view"
                          : this.props.history.location.state.owcReportMode ===
                            "edit"
                            ? "edit"
                            : this.props.history.location.state.owcReportMode ===
                              "clone"
                              ? "clone"
                              : this.props.history.location.state.owcReportMode ===
                                "add"
                                ? "add"
                                : null
                      }
                    />
                    <TableBody style={{ overflow: "hidden" }}>
                      {list.map((row, index) => {
                        // const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return !this.state.loading ? (
                          <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                              style={{
                                width: "25%",
                                padding: " 6px 6px",
                                paddingTop: "0px",
                              }}
                            >
                              {row.editClicked &&
                                !row.fromDB &&
                                row.manualEntry === true ? (
                                  <Autocomplete
                                    autoComplete
                                    includeInputInList
                                    options={this.state.categoryList}
                                    onChange={(e, values) =>
                                      this.handleProcessCategoryChange(
                                        e,
                                        index,
                                        values
                                      )
                                    }
                                    value={row.processCategoryName}
                                    getOptionLabel={(option) =>
                                      option.name || row.processCategoryName
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        required
                                        margin="normal"
                                        style={{ width: "100%" }}
                                      />
                                    )}
                                  />
                                ) : row.processCategoryName &&
                                  row.processCategoryName.length > 10 ? (
                                    <Tooltip
                                      title={row.processCategoryName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.processCategoryName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography noWrap style={{ fontSize: "14px" }}>
                                      {row.processCategoryName}
                                    </Typography>
                                  )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                              style={{
                                width: "25%",
                                padding: " 6px 6px",
                                paddingTop: "0px",
                              }}
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked &&
                                  !row.fromDB &&
                                  row.manualEntry === true ? (
                                    // <Autocomplete
                                    //   autoComplete
                                    //   includeInputInList
                                    //   options={this.state.processList}
                                    //   onChange={(e, values) =>
                                    //     this.handleProcessChange(e, index, values)
                                    //   }
                                    //   id="processDescription-index"
                                    //   name="processDescription"
                                    //   value={this.state.processDescription}
                                    //   getOptionLabel={(option) =>
                                    //     option || row.processDescription
                                    //   }
                                    //   renderInput={(params) => (
                                    //     <TextField
                                    //       {...params}
                                    //       required
                                    //       style={{ width: "100%" }}
                                    //       margin="normal"
                                    //     />
                                    //   )}
                                    // />
                                    <Autocomplete
                                      autoComplete
                                      includeInputInList
                                      options={this.state.processList}
                                      onChange={(e, values) =>
                                        this.handleProcessChange(e, index, values)
                                      }
                                      value={row.processDescription}
                                      getOptionLabel={(option) =>
                                        option.value || row.processDescription
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          required
                                          margin="normal"
                                          style={{ width: "100%" }}
                                        />
                                      )}
                                    />
                                  ) : row.processDescription &&
                                    row.processDescription.length > 10 ? (
                                      <Tooltip
                                        title={row.processDescription}
                                        aria-label="add"
                                      >
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.processDescription}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.processDescription}
                                      </Typography>
                                    )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-cell"
                              style={{
                                width: "25%",
                                padding: " 6px 6px",
                              }}
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked ? (
                                  <TextField
                                    autoFocus
                                    defaultValue={row.processInstruction}
                                    inputProps={{
                                      "aria-label": "instruction",
                                    }}
                                    style={{ width: "100%" }}
                                    multiline={true}
                                    rows={1}
                                    rowsMax={4}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "processInstruction",
                                        index
                                      );
                                    }}
                                    value={row.processInstruction}
                                  />
                                ) : row.processInstruction &&
                                  row.processInstruction.length > 16 ? (
                                      <Tooltip title={row.processInstruction}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.processInstruction}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.processInstruction}
                                      </Typography>
                                    )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-right-cell"
                              style={{ width: "5%", padding: " 6px 6px" }}
                            >
                              <Typography
                                noWrap
                                style={{ fontSize: "14px" }}
                              // className="table-content-right-cell"
                              >
                                {" "}
                                {row.editClicked ? (
                                  <Input
                                    onKeyPress={this._onNumberKeyPress}
                                    defaultValue={row.quantity}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    // onBlur={e => this.onBlur(e, index)}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "quantity",
                                        index
                                      );
                                    }}
                                    value={row.quantity}
                                  />
                                ) : (
                                    row.quantity
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-right-cell"
                              style={{ width: "5%", padding: " 6px 6px" }}
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked ? (
                                  <Input
                                    onKeyPress={this._onAlphaKeyPress}
                                    defaultValue={row.rate}
                                    // onBlur={e => this.onBlur(e, index)}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(e, "rate", index);
                                    }}
                                    value={row.rate}
                                    onBlur={(e) =>
                                      this.onBlur(e, "rate", index)
                                    }
                                  />
                                ) : (
                                    row.rate
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-right-cell"
                              style={{ width: "5%", padding: " 6px 6px" }}
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked ? (
                                  <Input
                                    label="AQ"
                                    onKeyPress={this._onNumberWithMinusKeyPress}
                                    defaultValue={row.addedQuantity}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "addedQuantity",
                                        index
                                      );
                                    }}
                                    value={row.addedQuantity}
                                  />
                                ) : (
                                    row.addedQuantity
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-right-cell"
                              style={{ width: "5%", padding: " 6px 6px" }}
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked ? (
                                  <Input
                                    onKeyPress={this._onAlphaKeyPress}
                                    defaultValue={row.spRate}
                                    inputProps={{
                                      "aria-label": "description",
                                    }}
                                    // onBlur={e => this.onBlur(e, index)}
                                    onChange={(e) => {
                                      this.onExcelDataChanged(
                                        e,
                                        "spRate",
                                        index
                                      );
                                    }}
                                    onBlur={(e) =>
                                      this.onBlur(e, "spRate", index)
                                    }
                                    value={row.spRate}
                                  />
                                ) : (
                                    row.spRate
                                    // row.spRate
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              align="left"
                              className="table-content-right-cell"
                              style={{ width: "5%", padding: " 6px 6px" }}
                            >
                              <Typography noWrap style={{ fontSize: "14px" }}>
                                {" "}
                                {row.editClicked &&
                                  !row.fromDB &&
                                  row.manualEntry === true ? (
                                    <Input
                                      disabled
                                      onKeyPress={this._onAlphaKeyPress}
                                      defaultValue={row.totalAmount}
                                      inputProps={{
                                        "aria-label": "description",
                                      }}
                                      onChange={(e) => {
                                        this.onExcelDataChanged(
                                          e,
                                          "totalAmount",
                                          index
                                        );
                                      }}
                                      value={parseFloat(
                                        row.quantity *
                                        parseFloat(row.rate).toFixed(4) +
                                        row.addedQuantity *
                                        parseFloat(row.spRate).toFixed(4)
                                      ).toFixed(3)}
                                    />
                                  ) : (
                                    // (
                                    //   row.quantity * row.rate +
                                    //   row.addedQuantity * row.spRate
                                    // ).toFixed(3)
                                    row.totalAmount && (+row.totalAmount).toFixed(3)
                                  )}
                              </Typography>
                            </TableCell>
                            {this.props.history.location.state.owcReportMode ===
                              "view" ? null : (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="left"
                                  className="table-content-cell"
                                  style={{ width: "5%", padding: " 6px 6px" }}
                                >
                                  <Typography noWrap style={{ fontSize: "14px" }}>
                                    {!row.fromDB && (
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: "#6c6b6b",
                                        }}
                                        onClick={(e) =>
                                          this.onEditClicked(
                                            e,
                                            index,
                                            row.editClicked
                                          )
                                        }
                                      >
                                        {!row.editClicked ? (
                                          <EditIcon
                                            style={{
                                              color: "#6c6b6b",
                                            }}
                                          />
                                        ) : row.processCategoryName !== "" &&
                                          row.processDescription !== "" &&
                                          row.quantity >= 0 &&
                                          row.addedQuantity != "-" &&
                                          row.rate >= 0 ? (
                                              <CheckIcon
                                                style={{
                                                  color: "#6c6b6b",
                                                }}
                                              />
                                            ) : null}
                                      </span>
                                    )}
                                    {!row.owcDetailID ? (
                                      <DeleteIcon
                                        style={{
                                          cursor: "pointer",
                                          color: "#6c6b6b",
                                        }}
                                        onClick={(e) =>
                                          this.onDeleteClicked(e, index)
                                        }
                                      />
                                    ) : (
                                        <DeleteIcon
                                          style={{
                                            cursor: "pointer",
                                            color: "#6c6b6b",
                                          }}
                                          onClick={(e) =>
                                            this.onDeleteApiClicked(
                                              e,
                                              index,
                                              row.owcDetailID
                                            )
                                          }
                                        />
                                      )}
                                  </Typography>
                                </TableCell>
                              )}
                          </TableRow>
                        ) : (
                            <TableRow>
                              <TableCell colSpan="8">
                                <LinearProgress />
                              </TableCell>
                            </TableRow>
                          );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "25px" }} />
            <div className="button-wrapper">
              <div>
                {newList.length !== list.length ? (
                  <Button
                    disabled
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={(e) => this.props.history.goBack()}
                  >
                    CANCEL
                  </Button>
                ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.goBack()}
                    >
                      CANCEL
                    </Button>
                  )}
              </div>
              {this.props.history.location.state.owcReportMode ===
                "view" ? null : (
                  <div
                    style={{
                      marginLeft: "1em",
                    }}
                  >
                    {this.props.history.location.state.owcReportMode == "add" ? (
                      <Button
                        // autoFocus
                        disabled={
                          this.state.isTicked ||
                            !this.state.productionLineID ||
                            this.state.productionLineID == "" ||
                            this.state.productionLineID === undefined ||
                            this.state.list.length == 0
                            ? true
                            : false
                        }
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={(e) => this.handleSubmit(e)}
                      >
                        Submit
                      </Button>
                    ) : (
                        <Button
                          // autoFocus
                          disabled={
                            this.state.isTicked ||
                              !this.state.productionLineID ||
                              this.state.productionLineID == "" ||
                              this.state.productionLineID === undefined ||
                              this.state.list.length == 0
                              ? true
                              : false
                          }
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={(e) => this.handleSubmit(e)}
                        >
                          Submit
                        </Button>
                      )}
                  </div>
                )}
              {this.props.history.location.state.owcReportMode === "view" && (
                <div
                  style={{
                    marginLeft: "1em",
                  }}
                >
                  <Button
                    // autoFocus
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={() => this.handleShowPopup()}
                  >
                    Print OWC Report
                  </Button>
                </div>
              )}
              {this.state.showPopup && (
                <ReportViewer
                  url={
                    `${window.localStorage.ReportURLByID}` +
                    "OWCREPORT&IsDirectPrint=True&ReportParam=OWCHeaderID&Param1=" +
                    `${this.props.history.location.search.split("=")[1]}`
                  }
                  showPopup={this.state.showPopup}
                  onClose={() => this.handleHidePopup()}
                  title={"OWC"}
                  handleHidePopup={() => this.handleHidePopup()}
                />
              )}
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
//export default withRouter();
export default withRouter(withStyles(addDispatchStyles)(CreateOWCReport));
