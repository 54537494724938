import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { addStyles } from "../../../shared/styles";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { resourceValidation } from "../../../shared/resource";
import { postDistributor } from "../masterActions";
const checkDistributioncode = new Validator("checkDistributioncode");
const checkdistributorname = new Validator("checkdistributorname");

class AddDistributor extends React.Component {
  constructor() {
    super();

    this.state = {
      Distributor: {
        distributorCode: "",
        distributorName: "",
        createdBy: 1,
      },
      errors: {},
      distributorCodeValid: false,
      distributorNameValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.handleSettingHeader("Distributor");
  }

  handleChange = (e) => {
    const { Distributor } = this.state;
    Distributor[e.target.name] = e.target.value;
    this.setState({ Distributor });
    //this.setState({[e.target.name]:e.target.value });

    this.validateField(e.target.name, e.target.value);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let distributorCodeValid = this.state.distributorCodeValid;
    let distributorNameValid = this.state.distributorNameValid;

    switch (fieldName) {
      case "distributorCode":
        if (value) {
          distributorCodeValid = checkDistributioncode(value);
          fieldValidationErrors.distributorCode = distributorCodeValid
            ? ""
            : resourceValidation.onlyAlphanumeric;
        } else {
          distributorCodeValid = false;
          fieldValidationErrors.distributorCode = "";
        }
        break;
      case "distributorName":
        if (value) {
          distributorNameValid = checkdistributorname(value);
          fieldValidationErrors.distributorName = distributorNameValid
            ? ""
            : resourceValidation.Distributorname;
        } else {
          distributorNameValid = false;
          fieldValidationErrors.distributorName = "";
        }
        break;

      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        distributorCodeValid: distributorCodeValid,
        distributorNameValid: distributorNameValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.distributorCodeValid && this.state.distributorNameValid,
    });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      distributorCode: "",
      distributorName: "",

      createdBy: "",
      // letterShop: {
      //   letterShopCode: "",
      //   letterShopName: "",
      //   chargeType: "",
      //   unitRate: "",
      // },
      errors: {},
    });
  };
  _onKeyPress(event) {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();
    //const list = this.context;

    if (this.validateForm()) {
      const { Distributor } = this.state;
      let distributorCode = Distributor.distributorCode;
      let distributorName = Distributor.distributorName;

      let createdBy = Distributor.createdBy;
      let reqBody = { distributorCode, distributorName, createdBy };
      postDistributor(reqBody, this.props.history);
    }
  };
  render() {
    let { classes } = this.props;
    let { Distributor } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Distributor</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    inputProps={{
                      maxLength: 10,
                    }}
                    autoComplete="off"
                    id="txtdeptCode"
                    label="Distributor Code"
                    name="distributorCode"
                    //onKeyPress={this._onKeyPress}
                    placeholder="Distributor Code"
                    className={classes.textField}
                    margin="normal"
                    value={Distributor.distributorCode.toUpperCase()}
                    error={this.state.errors.distributorCode}
                    helperText={
                      this.state.errors.distributorCode
                        ? this.state.errors.distributorCode
                        : ""
                    }
                    onChange={this.handleChange}
                    // onChange={e =>
                    //   this.onInputChanged(e, "distributorCode", "maxlength2")
                    // }
                    // onKeyPress={this.onKeyPress}
                  />
                  <TextField
                    required
                    label="Distributor Name"
                    id="standard-required"
                    placeholder="Distributor Name"
                    name="distributorName"
                    autoComplete="off"
                    className={classes.textField}
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    value={Distributor.distributorName}
                    error={this.state.errors.distributorName}
                    helperText={
                      this.state.errors.distributorName
                        ? this.state.errors.distributorName
                        : ""
                    }
                    onChange={this.handleChange}
                    // onChange={e =>
                    //   this.onInputChanged(e, "distributorName", "maxlength100")
                    // }
                  />
                </div>

                <Divider style={{ marginTop: "20px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/distributor")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddDistributor));
