import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { blue } from "@material-ui/core/colors";
import { styles } from "../../../shared/styles";
import { Tooltip } from "@material-ui/core";

const validateGeneral = new Validator("general");

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        ></IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const CustomCheckbox = withStyles({
  root: {
    color: "gray",
    "&$checked": {
      color: "#3982ea",
    },
    iconHover: {
      "&:hover": {
        color: blue[800],
      },
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class UserAdvanceSearch extends React.Component {
  state = {
    open: false,
    fName: "",
    lName: "",
    loginUserID: "",
    isSingpostUser: false,

    errors: {
      fName: false,
      lName: false,
      loginUserID: false,
      isSingpostUser: false,
    },
  };
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    fetch("/api/form-submit-url", {
      method: "POST",
      body: data,
    });
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      open: false,
      fName: "",
      lName: "",
      loginUserID: "",
      isSingpostUser: "",
    });
  };
  onSearchClicked = () => {
    let { fName, lName, loginUserID, isSingpostUser } = this.state;
    this.props.onSearchClicked(fName, lName, loginUserID, isSingpostUser);
    this.setState({
      open: false,
      fName: "",
      lName: "",
      loginUserID: "",
      isSingpostUser: "",
    });
  };
  checkButtonDisability = () => {
    let { fName, lName, loginUserID, isSingpostUser } = this.state;
    if (
      this.state.errors.fName ||
      this.state.errors.lName ||
      this.state.errors.loginUserID ||
      this.state.errors.isSingpostUser ||
      (!fName && !lName && !loginUserID && !isSingpostUser)
    )
      return true;
    else return false;
  };
  onInputChanged = (e, name, type) => {
    let newError = { ...this.state.errors };
    if (type === "general") {
      let result = validateGeneral(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    }
    this.setState({ [name]: e.target.value });
  };
  setSignPostUser = (e) => {
    this.setState({ isSingpostUser: !this.state.isSingpostUser });
  };
  render() {
    let { fName, lName, loginUserID, isSingpostUser } = this.state;
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div>
          <Tooltip title="Search">
            <IconButton
              aria-label="Search"
              className={classes.iconHover}
              onClick={this.handleClickOpen}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
            maxWidth="md"
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            >
              Advanced Search
            </DialogTitle>
            <DialogContent dividers>
              <div className={classes.container}>
                <div>
                  <TextField
                    id="standard-required"
                    label="First Name"
                    placeholder="First Name"
                    className={classes.textField}
                    margin="normal"
                    autoComplete="off"
                    value={fName}
                    error={this.state.errors.fName}
                    onChange={(e) =>
                      this.onInputChanged(e, "fName", "notRequired2")
                    }
                    helperText={
                      this.state.errors.mailingScheme
                        ? "First Name can be either empty or maximum 2 characters"
                        : ""
                    }
                  />
                  <TextField
                    label="Last Name"
                    id="standard-required"
                    placeholder="Last Name"
                    className={classes.textField}
                    margin="normal"
                    autoComplete="off"
                    value={lName}
                    error={this.state.errors.lName}
                    onChange={(e) =>
                      this.onInputChanged(e, "lName", "notRequired100")
                    }
                    helperText={
                      this.state.errors.description
                        ? "Last Name can be either empty or maximum 100 characters"
                        : ""
                    }
                  />

                  <TextField
                    label="Username"
                    id="standard-required"
                    placeholder="Login UserId"
                    className={classes.textField}
                    margin="normal"
                    autoComplete="off"
                    value={loginUserID}
                    error={this.state.errors.loginUserID}
                    onChange={(e) =>
                      this.onInputChanged(e, "loginUserID", "notRequired100")
                    }
                    helperText={
                      this.state.errors.description
                        ? "Login UserId can be either empty or maximum 100 characters"
                        : ""
                    }
                  />
                  <div>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          checked={isSingpostUser}
                          onChange={(e) => this.setSignPostUser(e)}
                          value={isSingpostUser}
                        />
                      }
                      label="SingpostUser"
                    />
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={this.handleClose}
              >
                CANCEL
              </Button>
              {this.checkButtonDisability() ? (
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.onSearchClicked()}
                >
                  SEARCH
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={(e) => this.onSearchClicked()}
                >
                  SEARCH
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </form>
    );
  }
}
export default withStyles(styles)(UserAdvanceSearch);
