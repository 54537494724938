import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import {Autocomplete} from "@material-ui/lab" ;
import Tooltip from "@material-ui/core/Tooltip";
import clone from "clone";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { ExportCSV } from "../../../shared/ExportCSV";
import LinearProgress from "@material-ui/core/LinearProgress";
import moment from "moment";
import {
  SearchIcon,
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import {
  stableSort,
  getSorting,
  addZeros,
  DialogActions,
} from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import types from "../masterTypes";
import { StateContext } from "../../../shared/globalState";
import {
  getOutworkerClaimRateList,
  deleteOutworkerClaimRate,
  getProcessList,
  getFilteredOutworkerClaimRateList,
  getUomDropDownList,
} from "../masterActions";

const columns = [
  {
    name: "Active",
    id: 5,
    show: false,
  },
  {
    name: "Modified Date",
    id: 6,
    show: false,
  },
  {
    name: "Modified By",
    id: 7,
  },
  {
    name: "Created Date",
    id: 8,
  },
  {
    name: "Created By",
    id: 9,
  },
];

function createData(
  owcRateID,
  processID,
  processName,
  rate,
  uomid,
  uomName,
  remarks,
  isActive,
  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate
) {
  return {
    owcRateID,
    processID,
    processName,
    rate,
    uomid,
    uomName,
    remarks,
    isActive,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all outworkerClaimRate" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors] = useState({
    process: "",
    uomid: "",
  });
  const [formValid, setIsformValid] = useState(false);

  const [outworkerClaimRateSearchValue, setValues] = useState({
    process: 0,
    uomid: 0,
  });

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);

  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handleDeleteOutworkerClaimRate,
    searchPopupOpen,
    //selectedOutworkerClaimRate,
    selected,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchOutworkerClaimRate,
    searchClicked,
    hendleVewColums,
    exportDataValues,
    processList,
    uomList,
    outworkerClaimRateList,
    isActive,
  } = props;
  //const selectedOutworkerClaimRateId = selected && selected.length > 0 && selected[0];
  const selectedOutworkerClaimRateId = selected[0];
  const selectedOutworkerClaimRate = outworkerClaimRateList.find(
    (x) => x.owcRateID === selectedOutworkerClaimRateId
  );
  let acnArr = props.roles.filter((u) => u.menuName === "Outworker Claim Rate");
  let acnObj = acnArr[0];

  let expData = clone(outworkerClaimRateList);
  expData.map((d) => {
    d["Process Name"] = d["processName"];
    d["Rate"] = d["rate"];
    //d["UOM"] = d["uomid"];
    d["UOM"] = d["uomName"];
    d["Remarks"] = d["remarks"];
    d["Active"] = d["isActive"];
    d["Created By"] = d["createdBy"];
    d["Created Date"] = d["createdDate"];
    d["Modified By"] = d["modifiedBy"];
    d["Modified Date"] = d["modifiedDate"];

    delete d["processName"];
    delete d["rate"];

    delete d["uomid"];

    delete d["uomName"];

    delete d["remarks"];

    delete d["isActive"];

    delete d["createdBy"];

    delete d["createdDate"];

    delete d["modifiedBy"];

    delete d["modifiedDate"];
    delete d["owcRateID"];
    delete d["processID"];
  });
  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteOutworkerClaimRate = () => {
    handleDeleteOutworkerClaimRate();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };
  const handleSearchProcessValuesChange = (event, values) => {
    event.persist();
    let value = event.target.value;
    setValues((outworkerClaimRateSearchValue) => ({
      ...outworkerClaimRateSearchValue,
      process: values,
    }));
    if (value !== undefined) {
      if (errors.uomid !== "") setIsformValid(false);
      else setIsformValid(true);
    } else {
      if (outworkerClaimRateSearchValue.uomid === null || errors.uomid !== "")
        setIsformValid(false);
      else setIsformValid(true);
    }
  };

  const handleSearchUomValuesChange = (event, values) => {
    event.persist();
    let value = event.target.value;
    setValues((outworkerClaimRateSearchValue) => ({
      ...outworkerClaimRateSearchValue,
      uomid: values,
    }));
    if (value !== undefined) {
      if (errors.process !== "") setIsformValid(false);
      else setIsformValid(true);
    } else {
      if (
        outworkerClaimRateSearchValue.process === null ||
        errors.process !== ""
      )
        setIsformValid(false);
      else setIsformValid(true);
    }
  };

  const clearSearchValues = () => {
    let searchValues = outworkerClaimRateSearchValue;
    searchValues.uomid = 0;
    searchValues.process = 0;
    searchValues = outworkerClaimRateSearchValue;
  };

  const searchPopup = () => {
    handleSearchOutworkerClaimRate(outworkerClaimRateSearchValue);
    clearSearchValues();
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
    // onColumnUpdate={this.onColumnUpdate}
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link
                      to={`/view_outworkerClaimRate/${selectedOutworkerClaimRateId}`}
                    >
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <Link
                      to={`/edit_outworkerClaimRate/${selectedOutworkerClaimRateId}`}
                    >
                      <IconButton aria-label="edit">
                        <EditIcon className={classes.iconHover} />
                      </IconButton>
                    </Link>

                    {/* this.props.history.push(/home:${this.state.userID}) onClick={e => props.history.push('/edit_outworkerClaimRate/${selectedOutworkerClaimRateId}')} */}
                  </Tooltip>
                )}
              </div>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={handleClickOpen}
                  disabled={!isActive}
                >
                  <DeleteIcon className={classes.iconHover} />
                </IconButton>
              </Tooltip>
            )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete Outworker Claim Rate
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1
                      ? " ?"
                      : selectedOutworkerClaimRate.processName + " ?"}
                    {/* {selectedOutworkerClaimRate.processName} */}
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteOutworkerClaimRate}
                  variant="contained"
                  color="primary"
                  //disableElevation
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {searchClicked ? (
              <Tooltip title="Clear search result">
                <IconButton
                  aria-label="clear"
                  className={classes.iconHover}
                  onClick={props.clearSearch}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <form onSubmit={searchPopup}>
                {acnObj && acnObj.advanceSearchAction && (
                  <Tooltip title="Search">
                    <IconButton
                      aria-label="Search"
                      className={classes.iconHover}
                      onClick={handleSearchOpen}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Dialog
                  disableBackdropClick
                  disableEscapeKeyDown
                  onClose={handleSearchPopupClose}
                  aria-labelledby="customized-dialog-title"
                  open={searchPopupOpen}
                  maxWidth="md"
                >
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleSearchPopupClose}
                  >
                    Advanced Search
                  </DialogTitle>
                  <DialogContent dividers>
                    <React.Fragment>
                      <div style={{ minWidth: "400px", display: "flex" }}>
                        <Autocomplete
                          autoComplete
                          includeInputInList
                          id="process"
                          name="process"
                          options={processList}
                          style={{ width: "49%" }}
                          onChange={handleSearchProcessValuesChange}
                          getOptionLabel={(option) => option.processName}
                          value={outworkerClaimRateSearchValue.process}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={"Select Process"}
                              name="processName"
                              id="processName"
                              margin="normal"
                              style={{ width: "98%" }}
                              //className={classes.textField}
                              value={outworkerClaimRateSearchValue.process}
                            />
                          )}
                        />
                        <Autocomplete
                          autoComplete
                          includeInputInList
                          id="uomid"
                          name="uomid"
                          options={uomList}
                          style={{ width: "49%" }}
                          onChange={handleSearchUomValuesChange}
                          getOptionLabel={(option) => option.text}
                          value={outworkerClaimRateSearchValue.uomid}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={"Select UOM"}
                              name="uomid"
                              id="uomid"
                              margin="normal"
                              style={{ width: "98%" }}
                              //className={classes.textField}
                              value={outworkerClaimRateSearchValue.uomid}
                            />
                          )}
                        />
                        {/* <TextField
                          id="standard-required"
                          label="UOM"
                          name="uomName"
                          className={classes.textField}
                          margin="normal"
                          value={outworkerClaimRateSearchValue.uomName}
                          inputProps={{
                            maxLength: 100
                          }}
                          onChange={handleSearchInput}
                          error={errors.uomName}
                          helperText={
                            errors.uomName ? errors.uomName : ""
                          }
                        /> */}
                      </div>
                    </React.Fragment>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={handleSearchClose}
                    >
                      CANCEL
                    </Button>
                    {!formValid ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={searchPopup}
                      >
                        SEARCH
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={searchPopup}
                      >
                        SEARCH
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
              </form>
            )}
            {acnObj && acnObj.addAction && (
              <Tooltip title="Add">
                <IconButton
                  aria-label="add"
                  className={classes.iconHover}
                  onClick={(e) => props.history.push("/add_outworkerClaimRate")}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
            {acnObj && acnObj.exportAction && (
              <Tooltip>
                <ExportCSV csvData={expData} fileName={"OutworkerClaimRate"} />
              </Tooltip>
            )}

            <Tooltip title="View Column">
              <IconButton
                aria-label="viewColumn"
                className={classes.iconHover}
                onClick={handleClickListItem}
              >
                <ViewColumnIcon />
              </IconButton>
            </Tooltip>
            <EnhancedShowColumn
              id="columns-menu"
              keepMounted
              open={open}
              onClose={handleShowColClose}
              column={column}
              hendleVewColums={hendleVewColums}
              headCells={props.headCells}
            />
          </div>
        )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class OutworkerClaimRateMater extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    outworkerClaimRateList: [],
    rows: [],
    activeRows: 0,
    isActive: false,
    refreshData: false,
    selectedOutworkerClaimRate: {},
    //selectedOutworkerClaimRateId: 1,
    loading: false,
    searchPopupOpen: false,
    outworkerClaimRateSearchValues: {},
    searchClicked: false,
    selectedDDL: 0,
    processList: [],
    uomList: [],
    process: "",
    uomid: "",
    processID: "",
    exportDataValues: [],
    selectedShowColumns: [],
    headCells: [
      {
        id: 1,
        code: "processName",
        numeric: false,
        disablePadding: true,
        label: "Process Name",
        show: true,
      },
      {
        id: 2,
        code: "rate",
        numeric: false,
        disablePadding: true,
        label: "Rate ($S)",
        show: true,
      },
      {
        id: 3,
        code: "uomName",
        numeric: false,
        disablePadding: true,
        label: "UOM",
        show: true,
      },
      {
        id: 4,
        code: "remarks",
        numeric: false,
        disablePadding: true,
        label: "Remarks",
        show: true,
      },
      {
        id: 5,
        code: "isActive",
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true,
      },
      {
        id: 6,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 7,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 8,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
      {
        id: 9,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
    ],
  };

  componentDidMount() {
    this.props.handleSettingHeader("Outworker Claim Rate");
    this.props.setSelectedList("masterDataOpen", "outworkerClaimRate");
    const dispatch = this.context[1];

    getOutworkerClaimRateList(window.localStorage.token).then((r) => {
      dispatch({
        type: types.OUTWORKERCLAIMRATE_LIST,
        newStore: r.data,
      });
      this.getAllOutworkerClaimRates(r.data);
      this.exportOutworkerClaimRates(r.data);
      // this.setState({ loading: true });
    });
    this.setState({ loading: true });
    getProcessList(window.localStorage.token).then((r) => {
      let list = r.data;
      list &&
        list.length > 0 &&
        this.setState({
          processList: list.sort((a, b) =>
            a.processName.toLowerCase() > b.processName.toLowerCase() ? 1 : -1
          ),
        });
    });
    getUomDropDownList(window.localStorage.token).then((r) => {
      let list = r.data;
      list &&
        list.length > 0 &&
        this.setState({
          uomList: list.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
        });
    });
  }
  getAllProcess = (list) => {
    let processList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        c.isActive
          ? processList.push({
              key: c.processID,
              value: c.processName,
              active: c.isActive,
            })
          : processList
      );
    this.setState({
      processList: processList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      ),
    });
  };

  getAllUoms = (list) => {
    let uomList = [];
    list &&
      list.length > 0 &&
      list.map((c) =>
        uomList.push({
          key: c.id,
          value: c.text,
          active: c.value,
        })
      );
    this.setState({
      uomList: uomList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      ),
    });
  };

  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };
  exportOutworkerClaimRates = (outworkerClaimRateList) => {
    let list = outworkerClaimRateList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          ProcessName: d.processName,
          Rate: d.rate,
          UOM: d.uomName,
          Remarks: d.remarks,
          Active: d.isActive === true ? "Yes" : "No",
          ModifiedDate: d.modifiedDate,
          ModifiedBy: d.modifiedBy,
          CreatedDate: d.createdDate,
          CreatedBy: d.createdBy,
        });
      });
    this.setState({ exportDataValues: rows });
  };
  getAllOutworkerClaimRates = (outworkerClaimRateList) => {
    let list = outworkerClaimRateList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.owcRateID,
            d.processID,
            d.processName,
            d.rate,
            d.uomid,
            d.uomName,
            d.remarks,
            d.isActive,
            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate
          )
        );
      });
    this.sortStateList(rows);
    this.setState({
      outworkerClaimRateList: rows,
      loading: false,
      selected: [],
      activeRows: rows.filter((x) => x.isActive).map((n) => n.owcRateID),
    });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.outworkerClaimRateList && store.outworkerClaimRateList.length > 0)
      if (
        this.state.outworkerClaimRateList.length !==
        store.outworkerClaimRateList.length
      ) {
        this.getAllOutworkerClaimRates(store.outworkerClaimRateList);
      }
    return true;
  }

  sortStateList = (list) => {
    let z = list.sort(function (a, b) {
      let x = a.isActive && a.isActive;
      let y = b.isActive && b.isActive;
      let u = a.modifiedDate && a.modifiedDate;
      let v = b.modifiedDate && b.modifiedDate;
      if (x < y) {
        return 1;
      }
      if (u < v) {
        return -1;
      }
      if (x > y) {
        return -1;
      }
      if (u > v) {
        return 1;
      }
      return 0;
    });
    // console.log(z);
    this.setState({ rows: z });
  };
  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { outworkerClaimRateList } = this.state;
    if (event.target.checked) {
      const newSelecteds = outworkerClaimRateList
        .filter((x) => x.isActive === true)
        .map((n) => n.owcRateID);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, data) {
    let owcRateID = data.owcRateID;
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(owcRateID);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, owcRateID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedOutworkerClaimRate: data,
        // selectedOutworkerClaimRateId: owcRateID
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedOutworkerClaimRate: {},
        // selectedOutworkerClaimRateId: 0
      });
    }
  }
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchOutworkerClaimRate = (handleSearchOutworkerClaimRate) => {
    let outworkerClaimRateSearchValue = handleSearchOutworkerClaimRate;
    let uomid =
      outworkerClaimRateSearchValue.uomid &&
      outworkerClaimRateSearchValue.uomid.id;
    let processID =
      outworkerClaimRateSearchValue.process &&
      outworkerClaimRateSearchValue.process.processID;
    let reqBody = { processID, uomid };
    const dispatch = this.context[1];
    getFilteredOutworkerClaimRateList(reqBody).then((r) => {
      dispatch({
        type: types.OUTWORKERCLAIMRATE_LIST,
        newStore: r.data,
      });
      this.getAllOutworkerClaimRates(r.data);
      this.setState({
        loading: true,
        searchPopupOpen: false,
        searchClicked: true,
        page: 0,
      });
    });
  };

  handleDeleteOutworkerClaimRate = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteOutworkerClaimRate(modifiedBy, deletionIds).then((r) => {
      getOutworkerClaimRateList().then((r) => {
        dispatch({
          type: types.OUTWORKERCLAIMRATE_LIST,
          newStore: r.data,
        });
        this.getAllOutworkerClaimRates(r.data);
      });
      this.props.history.push("/OutworkerClaimRate");
      this.setState({ deletePopupOpen: false, selected: [] });
      //toastr.success("OutworkerClaimRate Deleted Successfully");
    });
  };

  clearSearch = () => {
    const dispatch = this.context[1];
    getOutworkerClaimRateList().then((r) => {
      dispatch({
        type: types.PROCESSTYPE_LIST,
        newStore: r.data,
      });
      this.getAllOutworkerClaimRates(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      outworkerClaimRateList,
      exportDataValues,
      headCells,
    } = this.state;
    const isSelected = (owcRateID) => selected.indexOf(owcRateID) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteOutworkerClaimRate={this.handleDeleteOutworkerClaimRate}
            selectedOutworkerClaimRate={this.state.selectedOutworkerClaimRate}
            selectedOutworkerClaimRateId={
              this.state.selectedOutworkerClaimRateId
            }
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchOutworkerClaimRate={this.handleSearchOutworkerClaimRate}
            outworkerClaimRateSearchValue={
              this.state.outworkerClaimRateSearchValues
            }
            searchClicked={this.state.searchClicked}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            processList={this.state.processList}
            uomList={this.state.uomList}
            outworkerClaimRateList={outworkerClaimRateList}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            roles={this.props.roles}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 8em)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
                  : {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 330px)",
                      maxHeight: "calc(100vh - 225px)",
                      margin: "0 auto",
                    }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
                style={{ borderLeft: "1px solid  rgba(224, 224, 224, 1)" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {outworkerClaimRateList.length > 0 ? (
                    stableSort(
                      outworkerClaimRateList,
                      getSorting(order, orderBy)
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.owcRateID);
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              this.handleClick(
                                event,
                                row
                                // row.owcRateID,
                                // row.rate+row.uomName
                              )
                            }
                            outworkerClaimRate="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            //key={row.owcRateID}
                            key={index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.processName} */}
                                {row.processName &&
                                row.processName.length > 16 ? (
                                  <Tooltip
                                    title={row.processName}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.processName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.processName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {addZeros(row.rate.toString(), 2)}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.uomName}
                                </Typography> */}
                                {row.uomName && row.uomName.length > 16 ? (
                                  <Tooltip title={row.uomName} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.uomName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.uomName}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {/* {row.remarks} */}
                                {row.remarks && row.remarks.length > 16 ? (
                                  <Tooltip title={row.remarks} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.remarks}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.remarks}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                // id={labelId}
                              >
                                {status}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                // id={labelId}
                              >
                                {row.modifiedDate &&
                                row.modifiedDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                                // id={labelId}
                              >
                                {/* {row.modifiedBy} */}
                                {row.modifiedBy &&
                                row.modifiedBy.length > 16 ? (
                                  <Tooltip
                                    title={row.modifiedBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.modifiedBy}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdDate &&
                                row.createdDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {/* {row.createdBy} */}
                                {row.createdBy && row.createdBy.length > 16 ? (
                                  <Tooltip
                                    title={row.createdBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.createdBy}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      {this.state.loading ? (
                        <TableCell colSpan={15} style={{ textAlign: "center" }}>
                          <LinearProgress />
                        </TableCell>
                      ) : (
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                          No result found
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={outworkerClaimRateList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(OutworkerClaimRateMater);
