import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { Autocomplete } from "@material-ui/lab";
import Divider from "@material-ui/core/Divider";
import { StateContext } from "../../../shared/globalState";
import Tabs from "@material-ui/core/Tabs";
import clone from "clone";
import Tab from "@material-ui/core/Tab";
import { withRouter } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import LinearProgress from "@material-ui/core/LinearProgress";
import SearchIcon from "@material-ui/icons/Search";
import { resourceValidation } from "../../../shared/resource";
import { addStyles } from "../../../shared/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  getPubCustomerList,
  getLocationDropdownList,
} from "../../masters/masterActions";
import { getPublisherCustomerList } from "../../publisher/action";
import { IconButton } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import Table from "@material-ui/core/Table";
import { CustomCheckbox } from "../../../shared/tableCommonFunctions";
import OfflinePinIcon from "@material-ui/icons/OfflinePin";
import {
  getOrderFiles,
  getOnHoldFiles,
  putOnhold,
  makeReady,
  createBatch,
} from "./actions";
const headCells = [
  {
    id: "acc",
    numeric: false,
    disablePadding: true,
    label: "Acc",
  },
  {
    id: "vol",
    numeric: false,
    disablePadding: false,
    label: "Vol",
  },
  {
    id: "iss",
    numeric: false,
    disablePadding: false,
    label: "Iss",
  },
  {
    id: "supp",
    numeric: false,
    disablePadding: false,
    label: "Supp",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "issueDiscription",
    numeric: false,
    disablePadding: false,
    label: "Issue Discription",
  },
  {
    id: "orderFileName",
    numeric: false,
    disablePadding: false,
    label: "Order File Name",
  },
  {
    id: "subscription",
    numeric: false,
    disablePadding: false,
    label: "Subscription",
  },
  {
    id: "totalOrder",
    numeric: false,
    disablePadding: false,
    label: "Total Order",
  },
  {
    id: "totalCopies",
    numeric: false,
    disablePadding: false,
    label: "Total Copies",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          className="table-cell"
          style={{ borderLeft: "1px solid #eaeaea" }}
        >
          {props.tabValue === 0 && (
            <FormControlLabel
              control={
                <CustomCheckbox
                  // indeterminate={numSelected > 0 && numSelected < rowCount}
                  // checked={numSelected === rowCount}
                  onChange={props.onSelectAllClicked}
                  name="all"
                  inputProps={{ "aria-label": "select all production code" }}
                />
              }
            />
          )}
        </TableCell>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={
              i === headCells.length - 1
                ? {
                    borderRight: "1px solid #eaeaea",
                  }
                : // :
                  // i === 0
                  // ? {
                  //     borderLeft: "1px solid #eaeaea"
                  //   }
                  {}
            }
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Typography>
  );
}
const AntTab = withStyles((theme) => ({
  root: {
    marginLeft: "-12px",
    textTransform: "none",
    minWidth: 72,
    fontSize: "18px",
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    fontFamily: ["Roboto"].join(","),
    "&:hover": {
      opacity: 1,
    },
    "&$selected": {
      color: "#000",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      //   color: "#40a9ff"
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
class CreatePreProcess extends React.Component {
  state = {
    customerList: [],
    countryList: [],
    customerObj: {},
    countryObj: {},
    customerId: "",
    country: "",
    oflClicked: true,
    ohlClicked: false,
    list: [],
    value: 0,
    locationList: [],
    locationObj: {},
    locationId: {},
    selectedList: [],
    selectedId: [],
  };
  componentDidMount() {
    this.props.handleSettingHeader("Back Label Batch Processing");
    this.props.setSelectedList(
      "publisherBackLabelOpen",
      "backLabelBatchProcessing"
    );
    this.props.handleDrawerClose();
    getPublisherCustomerList().then((r) => {
      this.getAllCustomer(r.data);
      getLocationDropdownList().then((r) => {
        this.getAllLocation(r.data);
        this.getOrderFile();
      });
    });
  }
  onTabClicked = (e, name) => {
    this.setState({
      oflClicked: !this.state.oflClicked,
      ohlClicked: !this.state.ohlClicked,
    });
  };
  sortDDList = (list, name) => {
    let oldCus = clone(list);
    if (name === "customerList")
      oldCus &&
        this.setState({ customerId: oldCus[0].key, customerObj: oldCus[0] });
    if (name === "locationList") {
      oldCus &&
        oldCus.length > 0 &&
        this.setState({
          locationId: oldCus.filter((d) => d.value === "SG")[0].key,
          locationObj: oldCus.filter((d) => d.value === "SG")[0],
        });
    }
    let z = list.sort(function (a, b) {
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ [name]: z });
  };
  getAllCustomer = (list) => {
    let customerList = [];

    list &&
      list.length > 0 &&
      list.map((c) => {
        customerList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(customerList, "customerList");
  };
  getAllLocation = (list) => {
    let locationList = [];
    list &&
      list.length > 0 &&
      list.map((c) => {
        locationList.push({ key: c.id, value: c.value, name: c.text });
      });
    this.sortDDList(locationList, "locationList");
  };
  _onCodeKeyPress = (event) => {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  };
  handleAutocompleteCustomer = (e, val) => {
    this.setState({ customerId: val && val.key, customerObj: val && val });
  };
  handleAutocompletLocation = (e, val) => {
    this.setState({ locationId: val && val.key, locationObj: val && val });
  };
  handleSubmit = () => {
    let loggedInId = localStorage.getItem("loggedInId");
    let reqBody = {};
    reqBody.mlBatchHeaderID = 0;
    reqBody.customerID = this.state.customerId;
    reqBody.locationID = this.state.locationId;
    reqBody.isActive = true;
    reqBody.createdBy = loggedInId;
    reqBody.preProcessDetailList = [];
    this.state.selectedList.length > 0 &&
      this.state.selectedList.map((s) => {
        s.mloplFileHeaderID &&
          reqBody.preProcessDetailList.push({
            mlBatchDetailID: 0,
            mlBatchHeaderID: 0,
            mainOrderFileHeaderID: s.mloplFileHeaderID,
            journalID: s.journalID,
            subscriptionID: s.subscriptionID,
            totalOrder: s.noOfOrders,
            totalCopies: s.totalNoOfCopies,
            bucketID: null,
            supplementOneOrderFileHeaderID: null,
            supplementTwoOrderFileHeaderID: null,
            isActive: true,
            createdBy: loggedInId,
            cpSearchVal: "",
            searchClicked: false,
          });
      });

    // createBatch(reqBody,this.props.history).then(r=>{

    // })
    this.props.history.push("/add_pubBackLabelBatchPlacement/125/11/15");
  };
  onCheckboxClicked = (e, data, i) => {
    let newSelectedList = clone(this.state.selectedList);
    let newList = clone(this.state.list);
    let newScId = clone(this.state.selectedId);

    // if(newList[i].checkBoxVal){
    //   newSelectedList[i]={}
    //   newList[i].checkBoxVal=false
    //   newScId.pop(data.mloplFileHeaderID)
    // }
    //   else
    //  {
    if (e.target.checked) {
      newSelectedList[i] = data;
      newScId.push(data.mloplFileHeaderID);
      newList[i].checkBoxVal = true;
    } else {
      newSelectedList[i] = {};
      newList[i].checkBoxVal = false;
      newScId.pop(data.mloplFileHeaderID);
    }
    //  }
    this.setState({
      selectedList: newSelectedList,
      selectedId: newScId,
      list: newList,
    });
  };
  onSelectAllClicked = (e) => {
    let newScId = clone(this.state.selectedId);
    let newSelectedList = clone(this.state.selectedList);
    let newList = clone(this.state.list);
    if (e.target.checked) {
      newSelectedList = clone(this.state.list);

      newList.map((n, i) => {
        // if(i===0)
        newScId.push(n.mloplFileHeaderID);
        n.checkBoxVal = true;
      });
    } else {
      newList.map((n, i) => {
        //if(i===0)
        newScId.pop(n.mloplFileHeaderID);
        n.checkBoxVal = false;
      });
      newSelectedList = [];
    }

    this.setState({
      selectedList: newSelectedList,
      list: newList,
      selectedId: newScId,
    });
  };
  handleChange = (event, newValue) => {
    this.setState({ value: newValue, selectedId: [] });
    if (newValue === 0) this.getOrderFile();
    if (newValue === 1) this.getOnHoldfile();
  };
  getOrderFile = () => {
    getOrderFiles(this.state.customerId, this.state.locationId).then((r) => {
      this.setState({ list: r.data, searchClicked: false, cpSearchVal: "" });
    });
  };
  getOnHoldfile = () => {
    getOnHoldFiles(this.state.customerId, this.state.locationId).then((r) => {
      this.setState({ list: r.data, searchClicked: false, cpSearchVal: "" });
    });
  };
  onHoldClicked = (mhId) => {
    let loggedInId = localStorage.getItem("loggedInId");
    putOnhold(mhId, loggedInId).then((r) => {
      if (r.data) this.getOrderFile();
    });
  };
  onReadyClicked = (mhId) => {
    let loggedInId = localStorage.getItem("loggedInId");
    makeReady(mhId, loggedInId).then((r) => {
      if (r.data) this.getOnHoldfile();
    });
  };
  handleChangeCP = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSearchCP = (mode) => {
    let newList = clone(this.state.list);
    let newRow = [];
    newList.map((n) => {
      if (
        n.acronym.includes(this.state.cpSearchVal) ||
        n.volume.includes(this.state.cpSearchVal) ||
        n.issue.includes(this.state.cpSearchVal) ||
        n.suppNumber.includes(this.state.cpSearchVal) ||
        n.journalType.includes(this.state.cpSearchVal) ||
        n.urnalUniqueID.includes(this.state.cpSearchVal) ||
        n.changedFileName.includes(this.state.cpSearchVal)
      )
        newRow.push(n);
    });
    this.setState({ list: newRow, searchClicked: true });
  };
  clearSearch = (mode) => {
    if (mode === "hold") this.getOrderFile();
    if (mode === "ready") this.getOnHoldfile();
  };
  render() {
    let { classes } = this.props;
    let { customerObj } = this.state;

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">
            <span style={{ marginLeft: ".5em" }}>
              Back Label Pre Processing
            </span>
          </div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <Autocomplete
                      options={this.state.customerList}
                      value={customerObj}
                      onChange={this.handleAutocompleteCustomer}
                      getOptionLabel={(option) => option.name}
                      // style={{
                      //   width: "100%",
                      //   marginLeft: ".5em",
                      //   marginRight: ".5em"
                      // }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Customer"
                          style={{
                            width: "93%",
                            fontSize: "11px !important",
                            marginLeft: ".5em",
                            marginRight: ".5em",
                            maxHeight: "400px",
                          }}
                          // name="plannerId"
                          margin="normal"
                          required
                        />
                      )}
                    />
                  </div>

                  <div style={{ flex: 1 }}>
                    <Autocomplete
                      options={this.state.locationList}
                      value={this.state.locationObj}
                      onChange={this.handleAutocompletLocation}
                      getOptionLabel={(option) => option.name}
                      // style={{
                      //   width: "100%",
                      //   marginLeft: ".5em",
                      //   marginRight: ".5em"
                      // }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Location"
                          style={{
                            width: "93%",
                            fontSize: "11px !important",
                            marginLeft: ".5em",
                            marginRight: ".5em",
                            maxHeight: "400px",
                          }}
                          // name="plannerId"
                          margin="normal"
                          required
                        />
                      )}
                    />
                  </div>

                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "flex-end",
                      marginBottom: "6px",
                      marginLeft: "3px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.getOrderFile()}
                    >
                      Get Order File
                    </Button>
                  </div>
                </div>
                <div style={{ margin: ".5em" }}>
                  <AntTabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    aria-label="ant example"
                  >
                    <AntTab label="Order File List" />
                    <AntTab
                      label="On Hold List"
                      // disabled={!this.state.orderSubmited}
                    />
                  </AntTabs>
                </div>
                <TabPanel value={this.state.value} index={0}>
                  <React.Fragment>
                    <div
                      className={classes.search}
                      style={{ padding: ".5em", marginTop: ".5em" }}
                    >
                      <TextField
                        autoFocus
                        margin="dense"
                        onChange={this.handleChangeCP}
                        id="name"
                        name="cpSearchVal"
                        value={this.state.cpSearchVal}
                        autoComplete="off"
                        placeholder="Search Issue Description, Acronym, Volume, Issue, Supp"
                        // type="search"
                        fullWidth
                      />
                      <div className={classes.searchIcon}>
                        {this.state.searchClicked ? (
                          <IconButton
                            aria-label="clear"
                            className={classes.iconHover}
                            onClick={(e) => this.clearSearch("hold")}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="view"
                            className={classes.iconHover}
                            onClick={(e) => this.handleSearchCP("hold")}
                          >
                            <SearchIcon />
                          </IconButton>
                        )}
                      </div>
                    </div>
                    {/* .................................................................. */}
                    <div
                      style={
                        !this.props.clearOpenList
                          ? {
                              overflow: "auto",
                              maxWidth: "calc(100vw - 13em)",
                              maxHeight: "calc(100vh - 300px)",
                              margin: "1.5em .5em 0 .5em",
                            }
                          : {
                              overflow: "auto",
                              maxWidth: "calc(100vw - 406px)",
                              maxHeight: "calc(100vh - 300px)",
                              margin: "1.5em .5em 0 .5em",
                            }
                      }
                    >
                      <div style={{ maxHeight: 350, overflow: "auto" }}>
                        {/* //overflow: "auto" */}
                        <Table
                          aria-labelledby="tableTitle"
                          size="small"
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <EnhancedTableHead
                            tabValue={this.state.value}
                            onSelectAllClicked={this.onSelectAllClicked}
                          />

                          {!this.state.loading ? (
                            this.state.list.length > 0 ? (
                              <TableBody style={{ overflow: "hidden" }}>
                                {this.state.list &&
                                  this.state.list.length > 0 &&
                                  this.state.list.map((row, index) => {
                                    // const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                      <TableRow
                                        // hover
                                        // onClick={event =>
                                        //   this.handleClick(event, row.id, row)
                                        // }
                                        id={`tableRow${index}`}
                                        role="checkbox"
                                        //  aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        //   selected={isItemSelected}
                                      >
                                        <TableCell
                                          padding="checkbox"
                                          style={{
                                            borderLeft: "1px solid #eaeaea",
                                          }}
                                        >
                                          <FormControlLabel
                                            control={
                                              <CustomCheckbox
                                                color="primary"
                                                checked={
                                                  row.checkBoxVal ? true : false
                                                }
                                                onClick={(e) =>
                                                  this.onCheckboxClicked(
                                                    e,
                                                    row,
                                                    index
                                                  )
                                                }
                                              />
                                            }
                                          />
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.acronym}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.volume}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.issue}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.suppNumber}
                                          </Typography>
                                        </TableCell>

                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.journalType}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.urnalUniqueID}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          //   className="table-content-cell"
                                          style={{
                                            maxWidth: "250px!important",
                                            borderRight: "1px solid #eaeaea",
                                            paddingLeft: "0.5em!important",
                                          }}
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.changedFileName}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.subscriptionCode}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-right-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.noOfOrders}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-right-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.totalNoOfCopies}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            <Tooltip title="Put On Hold">
                                              <TouchAppIcon
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                                onClick={(e) =>
                                                  this.onHoldClicked(
                                                    row.mloplFileHeaderID
                                                  )
                                                }
                                              />
                                            </Tooltip>
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            ) : (
                              <TableBody>
                                <TableRow
                                  style={{
                                    borderRight: "1px solid #eaeaea",
                                    borderLeft: "1px solid #eaeaea",
                                  }}
                                >
                                  <TableCell
                                    colSpan="12"
                                    style={{ textAlign: "center" }}
                                  >
                                    No data found
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            )
                          ) : (
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan="9">
                                  <LinearProgress />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          )}
                        </Table>
                      </div>
                    </div>
                    <Divider style={{ margin: "25px 10px 0px 8px" }} />

                    <div
                      className="button-wrapper"
                      style={{ marginLeft: "7px" }}
                    >
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={(e) =>
                            this.props.history.push("/backLabelBatchProcessing")
                          }
                        >
                          CANCEL
                        </Button>
                      </div>
                      <div style={{ marginLeft: "1em" }}>
                        <Button
                          //  disabled={this.state.selectedId.length===0}
                          //autoFocus
                          style={{ boxShadow: "none" }}
                          color="primary"
                          variant="contained"
                          onClick={this.handleSubmit}
                          //   className={
                          //     !this.state.formValid
                          //       ? "disabled-button"
                          //       : "enabled-button"
                          //   }
                        >
                          CREATE Batch
                        </Button>
                      </div>
                    </div>
                  </React.Fragment>
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                  <React.Fragment>
                    <div
                      className={classes.search}
                      style={{ padding: ".5em", marginTop: ".5em" }}
                    >
                      <TextField
                        autoFocus
                        margin="dense"
                        onChange={this.handleChangeCP}
                        id="name"
                        name="cpSearchVal"
                        value={this.state.cpSearchVal}
                        autoComplete="off"
                        placeholder="Search Issue Description, Acronym, Volume, Issue, Supp"
                        // type="search"
                        fullWidth
                      />
                      <div className={classes.searchIcon}>
                        {this.state.searchClicked ? (
                          <IconButton
                            aria-label="clear"
                            className={classes.iconHover}
                            onClick={(e) => this.clearSearch("ready")}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="view"
                            className={classes.iconHover}
                            onClick={(e) => this.handleSearchCP("ready")}
                          >
                            <SearchIcon />
                          </IconButton>
                        )}
                      </div>
                    </div>
                    {/* .................................................................. */}
                    <div
                      style={
                        !this.props.clearOpenList
                          ? {
                              overflow: "auto",
                              maxWidth: "calc(100vw - 12.5em)",
                              maxHeight: "calc(100vh - 300px)",
                              margin: "1.5em .5em 0 .5em",
                            }
                          : {
                              overflow: "auto",
                              maxWidth: "calc(100vw - 406px)",
                              maxHeight: "calc(100vh - 300px)",
                              margin: "1.5em .5em 0 .5em",
                            }
                      }
                    >
                      <div style={{ maxHeight: 350, overflow: "auto" }}>
                        {/* //overflow: "auto" */}
                        <Table
                          aria-labelledby="tableTitle"
                          size="small"
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <EnhancedTableHead tabValue={this.state.value} />

                          {!this.state.loading ? (
                            this.state.list.length > 0 ? (
                              <TableBody style={{ overflow: "hidden" }}>
                                {this.state.list &&
                                  this.state.list.length > 0 &&
                                  this.state.list.map((row, index) => {
                                    // const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                      <TableRow
                                        // hover
                                        // onClick={event =>
                                        //   this.handleClick(event, row.id, row)
                                        // }
                                        id={`tableRow${index}`}
                                        role="checkbox"
                                        //  aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        //   selected={isItemSelected}
                                      >
                                        <TableCell
                                          padding="checkbox"
                                          style={{
                                            borderLeft: "1px solid #eaeaea",
                                          }}
                                        >
                                          <FormControlLabel
                                            control={
                                              <CustomCheckbox
                                                color="primary"
                                                // checked={isItemSelected}
                                              />
                                            }
                                          />
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.acronym}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.volume}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.issue}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.suppNumber}
                                          </Typography>
                                        </TableCell>

                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.journalType}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.urnalUniqueID}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          //   className="table-content-cell"
                                          style={{
                                            maxWidth: "250px!important",
                                            borderRight: "1px solid #eaeaea",
                                            paddingLeft: "0.5em!important",
                                          }}
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.changedFileName}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.subscriptionCode}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-right-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.noOfOrders}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-right-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.totalNoOfCopies}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          id={labelId}
                                          padding="default"
                                          align="left"
                                          className="table-content-cell"
                                        >
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            <Tooltip title="Move To Ready">
                                              <OfflinePinIcon
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                                onClick={(e) =>
                                                  this.onReadyClicked(
                                                    row.mloplFileHeaderID
                                                  )
                                                }
                                              />
                                            </Tooltip>
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            ) : (
                              <TableBody>
                                <TableRow
                                  style={{
                                    borderRight: "1px solid #eaeaea",
                                    borderLeft: "1px solid #eaeaea",
                                  }}
                                >
                                  <TableCell
                                    colSpan="12"
                                    style={{ textAlign: "center" }}
                                  >
                                    No data found
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            )
                          ) : (
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan="9">
                                  <LinearProgress />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          )}
                        </Table>
                      </div>
                    </div>
                    <Divider style={{ margin: "25px 10px 0px 8px" }} />

                    <div
                      className="button-wrapper"
                      style={{ marginLeft: "7px" }}
                    >
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={(e) =>
                            this.props.history.push(
                              "/pubMainLabelBatchPlacement"
                            )
                          }
                        >
                          CANCEL
                        </Button>
                      </div>
                    </div>
                  </React.Fragment>
                </TabPanel>
                {/* <div style={{ display: "flex", marginTop: "1.5em" }}>
                  <div
                    style={{ flex: 1, display: "flex", alignItems: "flex-end" }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{
                        width: "93%",
                        fontSize: "11px !important",
                        marginLeft: ".5em",
                        marginRight: ".5em",
                        maxHeight: "400px"
                      }}
                      disabled={this.state.oflClicked}
                      // name="plannerId"
                      onClick={e => this.onTabClicked(e, "oflClicked")}
                    >
                      {" "}
                      Order File List
                    </Button>
                  </div>

                  <div
                    style={{ flex: 1, display: "flex", alignItems: "flex-end" }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={this.state.ohlClicked}
                      onClick={e => this.onTabClicked(e, "ohlClicked")}
                      style={{
                        width: "93%",
                        fontSize: "11px !important",
                        marginLeft: ".5em",
                        marginRight: ".5em",
                        maxHeight: "400px"
                      }}
                      // name="plannerId"
                    >
                      {" "}
                      On Hold List
                    </Button>
                  </div>
                  <div
                    style={{
                      flex: 1
                    }}
                  ></div>
                </div> */}

                {/* ................................................................... */}
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(CreatePreProcess));
