import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import clone from "clone"
import Divider from "@material-ui/core/Divider";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { getCountryDropdownList } from "../../masters/masterActions";
import { getCustomerForInsertMasterList } from "../../publisher/action";
import { Autocomplete } from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getEditEBLOByID, getEditJournalEBLOByID, updateEBLO } from "./action";
import { getDHLRateUponAWeight } from "../../publisher/mainLabelOrderPlacement/action"

import { CustomSmallAddIcon, EditIcon } from "../../../shared/customIcons";
import { stableSort, getSorting } from "../../../shared/tableCommonFunctions";
import JournalSearchDialog from "./JournalSearchDialog";
import IndividualSearchAddDialog from "./IndividualSearchAddDialog";
import { getAllList } from "../../app/poupSearch/action";
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";

const notrequired50 = new Validator("notRequired50");
const validateMaxLength2 = new Validator("maxlength2");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginRight: "16px",
    fontSize: "11px !important",
    flex: 1,
  },
  emptyField: {
    marginRight: "16px",
    flexGrow: 1,
  },
  lastChild: {
    marginRight: 0,
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
});

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={
                  headCell.label == "Action"
                    ? "center"
                    : headCell.numeric
                      ? "right"
                      : "left"
                }
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
                style={{ width: headCell.width }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

function createData(
  journalID,
  issueDescription,
  acronym,
  journalTitle,
  volume,
  issue,
  supplement
) {
  return {
    journalID,
    issueDescription,
    acronym,
    journalTitle,
    volume,
    issue,
    supplement,
  };
}

class EditEBLOOrderPlacement extends React.Component {
  static contextType = StateContext;
  constructor() {
    super();

    this.state = {
      proMode: false,
      ebloHeaderID: "",
      customersList: [],
      customer: {},
      customerId: "",
      eblo: "",
      orderCreatedBy: "",
      department: "",
      orderDate: null,
      warehouseEmail: "",
      recipient: "",
      specialInstructions: "",
      deliveryMethod: "",
      deliveryDetails: "",
      addressLine: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      state: "",
      postCode: "",
      countryList: [],
      countryObj: {},
      PGSCountry: "",
      country: "",
      countryCode: "",
      areaCode: "",
      city: "",
      number: "",
      journalDispatch: "",
      errors: {},
      loading: false,
      openDialog: false,
      codeTypeList: [],
      page: 0,
      rowsPerPage: 100,
      order: "desc",
      orderBy: "modifiedDate",
      isManual: true,
      loadingFile: false,
      msg: [],
      selectedEditIndex: null,
      isEditClicked: false,
      reqJournalDetail: [],
      journalList: [],
      newQuantity: 0,
      newTrackingNumber: "",
      dhlRate: "0.00",
      noOfCartons: 0,
      newActualWeight: 0.0,
      newDeliveryDate: null,
      reqJournalDetailArr: [],
      quantityCheck: false,
      headCellsNew: [
        {
          id: 1,
          code: "issueDescription",
          numeric: false,
          disablePadding: false,
          label: "Issue Description",
          show: true,
          width: "200px",
        },
        {
          id: 2,
          code: "acronym",
          numeric: false,
          disablePadding: false,
          label: "Acronym",
          show: true,
          width: "58px",
        },
        {
          id: 3,
          code: "journalTitle",
          numeric: false,
          disablePadding: false,
          label: "Journal Title",
          show: true,
          width: "200px",
        },
        {
          id: 4,
          code: "volume",
          numeric: false,
          disablePadding: false,
          label: "Volume",
          show: true,
          width: "50px",
        },
        {
          id: 5,
          code: "issue",
          numeric: false,
          disablePadding: false,
          label: "Issue",
          show: true,
          width: "56px",
        },
        {
          id: 6,
          code: "supplement",
          numeric: false,
          disablePadding: false,
          label: "Supplement",
          show: true,
          width: "60px",
        },
        {
          id: 7,
          code: "action",
          numeric: false,
          disablePadding: false,
          label: "Action",
          show: true,
        },
      ],
      headCellsInternal: [
        {
          id: 1,
          code: "issueDescription",
          numeric: false,
          disablePadding: false,
          label: "Issue Description",
          show: true,
          width: "260px",
        },
        {
          id: 2,
          code: "quantity",
          numeric: false,
          disablePadding: false,
          label: "Quantity*",
          show: true,
          width: "82px",
        },
        {
          id: 3,
          code: "trackingNumber",
          numeric: false,
          disablePadding: false,
          label: "Tracking Number",
          show: true,
          width: "230px",
        },
        {
          id: 4,
          code: "noOfCartons",
          numeric: false,
          disablePadding: false,
          label: "No Of Cartons",
          show: true,
          width: "230px",
        },
        {
          id: 5,
          code: "actualWeight",
          numeric: false,
          disablePadding: false,
          label: "Actual Weight",
          show: true,
          width: "116px",
        },
        {
          id: 6,
          code: "deliveryDate",
          numeric: false,
          disablePadding: false,
          label: "Delivery Date",
          show: true,
          width: "207px",
        },
        {
          id: 7,
          code: "dhlRate",
          numeric: false,
          disablePadding: false,
          label: "DHL Rate",
          show: true,
          width: "207px",
        },
        {
          id: 8,
          code: "action",
          numeric: false,
          disablePadding: false,
          label: "Action",
          show: true,
          width: "120px",
        },
      ],
      headCells: [
        {
          id: 1,
          code: "issueDesc",
          numeric: false,
          disablePadding: false,
          label: "Issue Description",
          show: true,
        },
        {
          id: 2,
          code: "quantity",
          numeric: false,
          disablePadding: false,
          label: "Quantity",
          show: true,
        },
        {
          id: 3,
          code: "trackingNumber",
          numeric: false,
          disablePadding: false,
          label: "Tracking Number",
          show: true,
        },
        {
          id: 4,
          code: "actualWeight",
          numeric: false,
          disablePadding: false,
          label: "Actual Weight",
          show: true,
        },
        {
          id: 5,
          code: "deliveryDate",
          numeric: false,
          disablePadding: false,
          label: "Delivery Date",
          show: true,
        },
        {
          id: 6,
          code: "action",
          numeric: false,
          disablePadding: false,
          label: "Action",
          show: true,
        },
      ],
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("EBLO Order Placement");
    this.props.setSelectedList(
      "publisherBackLabelOpen",
      "pubEbloOrderPlacement"
    );

    getCustomerForInsertMasterList().then((r) => {
      r.data &&
        this.setState({
          customersList: r.data,
        });

      const filtered =
        r &&
        r.data &&
        r.data.reduce(
          (a, o) => (
            o.text.toLowerCase().indexOf("wiley") != -1 && a.push(o.id), a
          ),
          []
        );
      this.setState({
        customerId: filtered && filtered[0],
      });
      getCountryDropdownList().then((r) => {
        this.getAllCountries(r.data);
      });
    });

    // this.getAllData();

    this.getJournals();

    // getCountryDropdownList().then((r) => {
    //   this.getAllCountries(r.data);
    // });

    getAllList().then((r) => {
      dispatch({
        type: "getJobGroup",
        newStore: r.data,
      });
      this.getJournalInformation(r.data);
    });
  }

  getJournalInformation = (journalList) => {
    let list = journalList;
    let rows = [];

    list &&
      list.length > 0 &&
      list.map((d, index) => {
        return rows.push(
          createData(
            d.journalID ? d.journalID : "",
            d.issueDescription ? d.issueDescription : "",
            d.acronym ? d.acronym : "",
            d.journalTitle ? d.journalTitle : "",
            d.volume ? d.volume : "",
            d.issue ? d.issue : "",
            d.supplement ? d.supplement : ""
          )
        );
      });
    this.setState({
      journalList: rows,
    });
  };

  getAllData = async () => {
    let ebloHeaderID = window.location.pathname.split("/")[2];
    await getEditEBLOByID(ebloHeaderID).then((r) => {
      let proMode = false;
      if (r.status.pubStatusName === "Processed" || r.status.pubStatusName === "Completed") {
        proMode = true;
      }
      this.setState({
        proMode,
        customerID: r.customerID,
        customer: this.state.customersList.filter((cust) => {
          return cust.id === r.customerID;
        }),
        eblo: r.ebloNo,
        orderCreatedBy: r.orderCreatedBy,
        department: r.department,
        orderDate: r.orderDate,
        warehouseEmail: r.warehouseEmail,
        recipient: r.recipient,
        specialInstructions: r.specialInstructions,
        deliveryMethod: r.deliveryMethod,
        country: r.countryName,
        PGSCountry: this.state.countryObj[r.countryID],
        deliveryDetails: r.deliveryDetails,
        addressLine: r.addressLine,
        addressLine1: r.addressLine,
        addressLine2: r.addressLine2,
        addressLine3: r.addressLine3,
        state: r.state,
        postCode: r.postCode,
        countryID: r.countryID,
        countryCode: r.countryCode,
        areaCode: r.areaCode,
        city: r.city,
        number: r.number,

        journalDispatch: r.journalDispatch,
      });
    });
  };

  getJournals = async () => {
    let ebloHeaderID = window.location.pathname.split("/")[2];
    const codeListArry = [],
      reqJournalDetailArr = [];
    await getEditJournalEBLOByID(ebloHeaderID).then((r) => {
      r.map((journal) => {
        let journalTemp = {
          jouranlUniqueID: journal.jouranlUniqueID,
          quantity: journal.quantity,
          dhlRate: journal.dhlRate,
          noOfCartons: journal.noOfCartons,
          trackingNumber: journal.trackingNumber,
          actualWeight: journal.actualWeight,
          deliveryDate: journal.deliveryDate,
          journalId: journal.jouranlID
        };
        let reqJournalDetailTemp = {
          ebloOrderFileHeaderID: journal.ebloOrderFileHeaderID,
          jouranlID: journal.jouranlID,
          jouranlUniqueID: journal.jouranlUniqueID,
          quantity: journal.quantity,
          dhlRate: journal.dhlRate,
          noOfCartons: journal.noOfCartons,
          trackingNumber: journal.trackingNumber,
          actualWeight: journal.actualWeight,
          deliveryDate: journal.deliveryDate,
          isActive: true,
          detailModifiedBy: localStorage.loggedInId,
        };
        if (journal.isActive)
          codeListArry.push(journalTemp);
        reqJournalDetailArr.push(reqJournalDetailTemp);
      });
      this.setState({
        codeTypeList: codeListArry,
        reqJournalDetail: reqJournalDetailArr,
        ebloHeaderID: ebloHeaderID,
      });
    });
  };

  getAllCountries = (list) => {
    let countryList = [],
      countryObj = {};
    countryList = list && list;
    countryList &&
      countryList.map((list) => {
        countryObj[list.id] = list.text;
      });
    this.setState({
      countryList:
        countryList &&
        countryList.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
      countryObj: countryObj,
    });
    this.getAllData();
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleChange = (e) => {
    console.log(e.target.name, this.state)
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name == "number") {
      let newErrors = { ...this.state.errors };
      let result = notrequired50(e.target.value);
      newErrors[e.target.name] = result;
      this.setState({
        [e.target.name]: e.target.value,
        errors: newErrors,
      });
    } else if (e.target.name == "newActualWeight") {
      let weiVal = this.checkDHlWeightDec(e.target.value.toString());
      this.setState({
        [e.target.name]: weiVal,
      });

      if (e.target && e.target.value && this.state.countryID)
        getDHLRateUponAWeight(this.state.countryID, e.target.value).then(r => {
          // console.log(r)
          if (r && r.data) {
            this.setState({ dhlRate: r.data.dhlRate ? r.data.dhlRate : "" });
          }
          else this.setState({ dhlRate: "" });
        })
    }
  };
  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleDoneClicked = (e, i) => {
    let codeTypeCopy = this.state.codeTypeList;
    let reqJournalDetailCopy = this.state.reqJournalDetail;
    let quantityCheck = parseFloat(this.state.newQuantity) < 1;
    codeTypeCopy.map((e, index) => {
      if (index === i) {
        e.quantity = this.state.newQuantity;
        e.trackingNumber = this.state.newTrackingNumber;
        e.actualWeight = this.state.newActualWeight;
        e.deliveryDate = this.state.newDeliveryDate;
        e.dhlRate = this.state.dhlRate
        e.noOfCartons = this.state.noOfCartons
      }
    });
    reqJournalDetailCopy.map((e, index) => {
      if (index === i) {
        e.quantity = this.state.newQuantity;
        e.trackingNumber = this.state.newTrackingNumber;
        e.actualWeight = this.state.newActualWeight;
        e.deliveryDate = this.state.newDeliveryDate;
        e.dhlRate = this.state.dhlRate
        e.noOfCartons = this.state.noOfCartons
      }
    });
    this.setState({
      selectedEditIndex: null,
      isEditClicked: false,
      newQuantity: 0,
      quantityCheck: quantityCheck,
      noOfCartons: 0,
      dhlRate: 0,
      newTrackingNumber: "",
      newActualWeight: 0.0,
      newDeliveryDate: null,
      codeTypeList: codeTypeCopy,
      reqJournalDetail: reqJournalDetailCopy,
    });
  };

  handleCancelClicked = (e, i) => {
    this.setState({
      selectedEditIndex: null,
      isEditClicked: false,
    });
  };

  handleDeleteClicked = (event, index, row) => {
    let journalList = clone(this.state.codeTypeList);
    let nerReqJournalDetail = clone(this.state.reqJournalDetail)
    journalList.splice(index, 1);
    this.setState({
      codeTypeList: journalList,
      reqJournalDetail: nerReqJournalDetail
    });
  };

  handleEditClicked = (e, i) => {
    this.setState({
      selectedEditIndex: i,
      isEditClicked: true,
      newQuantity: this.state.codeTypeList[i].quantity,
      newTrackingNumber: this.state.codeTypeList[i].trackingNumber,
      newActualWeight: this.state.codeTypeList[i].actualWeight,
      newDeliveryDate: this.state.codeTypeList[i].deliveryDate,
      dhlRate: this.state.codeTypeList[i].dhlRate,
      noOfCartons: this.state.codeTypeList[i].noOfCartons,
    });
  };

  handleAddJournalClick = () => {
    this.setState({
      openDialog: true,
    });
  };

  handleJournalUpdate = (selectedJournalList) => {
    let codeListArry = clone(this.state.codeTypeList);
    let boolVal = codeListArry.filter((r) => {
      return r.jouranlUniqueID == selectedJournalList[0].issueDescription;
    });
    if (boolVal.length > 0) {
      this.setState(
        {
          openDialog: false,
          codeTypeList: codeListArry,
        },
        () => {
          alert("Journal already exists in the table");
        }
      );
      return
    }
    let reqJournalDetailArr = this.state.reqJournalDetail;
    selectedJournalList.map((journal) => {
      let journalTemp = {
        jouranlUniqueID: journal.issueDescription,
        quantity: 0,
        trackingNumber: "",
        actualWeight: 0.0,
        deliveryDate: null,
        journalId: journal.journalID,
        dhlRate: 0,
        noOfCartons: 0

      };
      let reqJournalDetailTemp = {
        ebloOrderFileHeaderID: this.state.ebloHeaderID,
        jouranlID: journal.journalID,
        jouranlUniqueID: journal.issueDescription,
        quantity: 0,
        trackingNumber: "",
        actualWeight: 0.0,
        deliveryDate: "",
        isActive: true,
        detailModifiedBy: localStorage.loggedInId,
      };
      codeListArry.push(journalTemp);
      reqJournalDetailArr.push(reqJournalDetailTemp);
    });
    console.log("jkasdjkhasjdhkjashkdhaskjdhkj")
    if (
      this.state.codeTypeList.length === 0 &&
      selectedJournalList.length > 0
    ) {
      this.setState({
        loading: false,
      });
    }
    this.setState({
      openDialog: false,
      codeTypeList: codeListArry,
      reqJournalDetail: reqJournalDetailArr,
      selectedEditIndex: codeListArry.length - 1,
    });
  };

  handleAddJournalClose = () => {
    this.setState({
      openDialog: false,
    });
  };

  handleAutocompleteCustomer = (e, val) => {
    this.setState({ customerId: val && val.id, customer: val && val });
  };

  handleAutocompleteCountry = (e, val) => {
    this.setState({
      countryID: val && val.id,
      PGSCountry: val && val,
      // countryCode: val.value,
    });
  };

  handleSubmit = (event, manual) => {
    let ebloHeaderID = window.location.pathname.split("/")[2];
    const {
      orderCreatedBy,
      department,
      orderDate,
      warehouseEmail,
      recipient,
      specialInstructions,
      deliveryMethod,
      deliveryDetails,
      addressLine,
      addressLine1,
      addressLine2,
      addressLine3,
      state,
      postCode,
      countryList,
      PGSCountry,
      country,
      countryCode,
      countryID,
      areaCode,
      city,
      number,
      journalDispatch,
      reqJournalDetail,
      codeTypeList
    } = this.state;

    let createdBy = localStorage.loggedInId;
    let modifiedBy = localStorage.loggedInId;
    // let countryName =
    //   this.state.PGSCountry &&
    //   this.state.PGSCountry.text &&
    //   this.state.PGSCountry.text.split("- ")[1];
    let countryName = country;
    let jArr = []
    codeTypeList.map(r => {
      jArr.push({
        actualWeight: r.actualWeight,
        deliveryDate: r.deliveryDate,
        jouranlUniqueID: r.jouranlUniqueID,
        quantity: r.quantity,
        dhlRate: r.dhlRate,
        noOfCartons: r.noOfCartons,
        trackingNumber: r.trackingNumber,
        jouranlID: r.journalId,
        ebloOrderFileHeaderID: +ebloHeaderID,
        detailModifiedBy: modifiedBy,
        isActive: true
      })
    })
    let reqbody = {
      orderCreatedBy,
      department,
      orderDate,
      warehouseEmail,
      recipient,
      specialInstructions,
      deliveryMethod,
      deliveryDetails,
      addressLine: addressLine1
      ,
      addressLine2,
      addressLine3,
      state,
      postCode,
      countryID,
      countryCode: countryCode,
      areaCode,
      city,
      number,
      journalDispatch,
      modifiedBy,
      countryName,
      // updateEBLOOrderDetailRequest: reqJournalDetail,
      updateEBLOOrderDetailRequest: jArr,
    };
    updateEBLO(ebloHeaderID, reqbody, this.props.history);
  };

  _onMobileNumberKeyPress(event) {
    const re = /[0-9+() -]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertKeyPress(event) {
    const re = /[a-zA-Z0-9-#]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextKeyPress(event) {
    const re = /[a-z A-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberOnlyKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusNumberKeyPress(event) {
    const re = /[0-9 a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onWeightKeyPress(event) {
    const re = /[0-9 a-zA-Z.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertSizeKeyPress(event) {
    const re = /[0-9 a-zA-Z-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusAllPress(event) {
    const re = /[a-z A-Z0-9=.;()&@]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, 5);
    }
    return value;
  }
  render() {
    let { classes } = this.props;
    let {
      reqJournalDetail,
      customersList,
      customer,
      eblo,
      orderCreatedBy,
      department,
      orderDate,
      warehouseEmail,
      recipient,
      specialInstructions,
      deliveryMethod,
      deliveryDetails,
      addressLine,
      addressLine1,
      addressLine2,
      addressLine3,
      state,
      postCode,
      countryList,
      PGSCountry,
      country,
      countryCode,
      areaCode,
      city,
      number,
      journalDispatch,
      codeTypeList,
      page,
      rowsPerPage,
      order,
      orderBy,
      openDialog,
      selectedEditIndex,
      journalList,
    } = this.state;

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update EBLO Order</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <div className={classes.container}>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Customer"
                    name="customer"
                    inputProps={{
                      maxLength: 50,
                      readOnly: true,
                    }}
                    variant="filled"
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    className={classes.textField}
                    value={
                      this.state.customer.length > 0
                        ? this.state.customer[0].text
                        : ""
                    }
                    onChange={this.handleChange}
                  />
                  <div className={classes.emptyField}></div>
                  <div className={classes.emptyField}></div>
                  <div
                    className={[classes.emptyField, classes.lastChild].join(
                      " "
                    )}
                  ></div>
                </div>
                <p className={classes.subHeader} style={{ fontWeight: "500" }}>
                  EBLO Details
                </p>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    required
                    label="EBLO#"
                    name="eblo"
                    inputProps={{
                      maxLength: 50,
                      readOnly: true,
                    }}
                    variant="filled"
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    className={classes.textField}
                    value={eblo}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Order Created By"
                    name="orderCreatedBy"
                    inputProps={{
                      maxLength: 100,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    multiline
                    rowsMax="4"
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    className={classes.textField}
                    value={orderCreatedBy}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Department"
                    name="department"
                    inputProps={{
                      maxLength: 100,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    multiline={department.length > 20}
                    rowsMax="4"
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    className={classes.textField}
                    value={department}
                    onChange={this.handleChange}
                  />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {this.state.proMode ? (
                      <TextField
                        label="Order Date"
                        name="orderDate"
                        inputProps={{
                          maxLength: 50,
                          readOnly: this.state.proMode ? true : false,
                        }}
                        variant={this.state.proMode ? "filled" : "standard"}
                        margin="normal"
                        className={classes.textField}
                        value={orderDate ? moment(orderDate).format("DD/MM/YYYY") : ""}
                      />
                    ) : (
                        <KeyboardDatePicker
                          autoOk
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          minDateMessage={""}
                          name="orderDate"
                          className={[classes.textField, classes.lastChild].join(
                            " "
                          )}
                          id="orderDate"
                          label="Order Date"
                          value={orderDate}
                          onChange={(e) => this.handleDateChange(e, "orderDate")}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      )}
                  </MuiPickersUtilsProvider>
                </div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    label="Warehouse Email"
                    name="warehouseEmail"
                    inputProps={{
                      maxLength: 50,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    // onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    className={classes.textField}
                    value={warehouseEmail}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required
                    label="Recipient"
                    name="recipient"
                    inputProps={{
                      maxLength: 50,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    multiline
                    rowsMax="4"
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    className={classes.textField}
                    value={recipient}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Special Instructions"
                    name="specialInstructions"
                    inputProps={{
                      maxLength: 100,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    multiline
                    rowsMax="4"
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    className={classes.textField}
                    value={specialInstructions}
                    style={{ marginTop: "13px" }}
                    multiline
                    rowsMax="4"
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Delivery Method"
                    name="deliveryMethod"
                    inputProps={{
                      maxLength: 100,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    multiline
                    rowsMax="4"
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    className={[classes.textField, classes.lastChild].join(" ")}
                    value={deliveryMethod}
                    onChange={this.handleChange}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    label="Delivery Details"
                    name="deliveryDetails"
                    inputProps={{
                      maxLength: 100,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    multiline
                    rowsMax="4"
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    className={classes.textField}
                    value={deliveryDetails}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required={!this.state.addressLine1 && !this.state.addressLine2 && !this.state.addressLine3}
                    label="Address Line 1"
                    name="addressLine1"
                    inputProps={{
                      maxLength: 100,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    multiline
                    rowsMax="4"
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    className={classes.textField}
                    value={this.state.addressLine1}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required={!this.state.addressLine1 && !this.state.addressLine2 && !this.state.addressLine3}
                    label="Address Line 2"
                    name="addressLine2"
                    inputProps={{
                      maxLength: 100,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    multiline
                    rowsMax="4"
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    className={classes.textField}
                    value={this.state.addressLine2}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required={!this.state.addressLine1 && !this.state.addressLine2 && !this.state.addressLine3}
                    label="Address Line 3"
                    name="addressLine3"
                    inputProps={{
                      maxLength: 100,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    multiline
                    rowsMax="4"
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    // className={classes.textField}
                    className={[classes.textField, classes.lastChild].join(" ")}

                    value={this.state.addressLine3}
                    onChange={this.handleChange}
                  />

                </div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    label="State"
                    name="state"
                    inputProps={{
                      maxLength: 50,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    className={classes.textField}
                    value={state}
                    onChange={this.handleChange}
                  />
                  <TextField
                    required
                    label="Post Code"
                    name="postCode"
                    inputProps={{
                      maxLength: 50,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    // className={[classes.textField, classes.lastChild].join(" ")}
                    className={classes.textField}
                    value={postCode}
                    onChange={this.handleChange}
                  />
                  {this.state.proMode ? (
                    <TextField
                      required
                      label="PGS Country"
                      name="country"
                      inputProps={{
                        maxLength: 50,
                        readOnly: this.state.proMode ? true : false,
                      }}
                      variant={this.state.proMode ? "filled" : "standard"}
                      margin="normal"
                      className={classes.textField}
                      value={PGSCountry}
                    />
                  ) : (
                      <Autocomplete
                        autoComplete
                        includeInputInList
                        id={"country-1"}
                        name="country"

                        className={classes.textField}
                        value={this.state.PGSCountry}
                        options={countryList}
                        getOptionLabel={(option) =>
                          option.text || this.state.PGSCountry
                        }
                        onChange={this.handleAutocompleteCountry}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="PGS Country"
                            className={classes.autocompleteText}
                            fullWidth={true}
                            margin="normal"
                            value={PGSCountry}
                            required
                          />
                        )}
                      />
                    )}

                  <TextField
                    required
                    label="Customer Country"
                    name="country"
                    inputProps={{
                      maxLength: 50,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    // className={classes.textField}
                    // className={[classes.autoComplete, classes.textField].join(
                    //   " "
                    // )}
                    className={[classes.textField, classes.lastChild].join(" ")}

                    value={country}
                    onChange={this.handleChange}
                  />

                </div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    label="Country Code"
                    name="countryCode"
                    inputProps={{
                      maxLength: 50,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    onKeyPress={this._onTextKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={countryCode}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Area Code"
                    name="areaCode"
                    inputProps={{
                      maxLength: 50,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    className={classes.textField}

                    value={areaCode}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="City"
                    name="city"
                    inputProps={{
                      maxLength: 50,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    className={classes.textField}

                    value={city}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Phone Number"
                    name="number"
                    inputProps={{
                      maxLength: 20,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    onKeyPress={this._onMobileNumberKeyPress}
                    margin="normal"
                    className={[classes.textField, classes.lastChild].join(" ")}

                    value={number}
                    onChange={this.handleChange}
                  // error={this.state.errors.number}
                  // helperText={
                  //   this.state.errors.phoneNumber
                  //     ? resourceValidation.validPhoneNo
                  //     : ""
                  // }
                  />
                </div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    label="Journal Dispatch"
                    name="journalDispatch"
                    inputProps={{
                      maxLength: 100,
                      readOnly: this.state.proMode ? true : false,
                    }}
                    variant={this.state.proMode ? "filled" : "standard"}
                    onKeyPress={this._onTextPlusAllPress}
                    margin="normal"
                    className={classes.textField}

                    value={journalDispatch}
                    style={{ maxWidth: "275px" }}
                    multiline
                    rowsMax="4"
                    onChange={this.handleChange}
                  />
                  {/* <div
                    className={[classes.emptyField, classes.lastChild].join(
                      " "
                    )}
                  ></div> */}
                </div>

                <div style={{ marginTop: "2%" }}>
                  <p className={classes.subHeader}>Item Detail</p>
                  {this.state.proMode ? null : (
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid #eaeaea",
                        borderBottom: "none",
                        borderRadius: "4px",
                        boxShadow: "0px 7px 6px #ddd",
                      }}
                    >
                      <Typography
                        component="div"
                        style={{
                          width: "100%",
                          padding: "10px",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            boxShadow: "none",
                            padding: "5px 10px 5px 4px",
                            fontSize: "11px",
                            boxShadow: "none",
                          }}
                          onClick={this.handleAddJournalClick}
                        >
                          <CustomSmallAddIcon style={{ fontSize: "18px" }} />{" "}
                          Add Journal
                        </Button>
                      </Typography>
                    </div>
                  )}

                  {openDialog ? (
                    // <JournalSearchDialog
                    //   title="Journal Pick Up"
                    //   showDialog={this.state.openDialog}
                    //   handleAddJournalClose={this.handleAddJournalClose}
                    //   handleJournalUpdate={this.handleJournalUpdate}
                    //   filteredJournalsList={codeTypeList}
                    //   journalList={journalList}
                    // />
                    <IndividualSearchAddDialog
                      title="Journal Pick Up"
                      showDialog={this.state.openDialog}
                      handleAddJournalClose={this.handleAddJournalClose}
                      handleJournalUpdate={this.handleJournalUpdate}
                    />
                  ) : null}
                  <div
                    style={{
                      maxHeight: "300px",
                      overflowY: "auto",
                      boxShadow: "0px 3px 4px #ddd",
                    }}
                  >
                    <Table
                      stickyHeader
                      aria-labelledby="tableTitle"
                      size="small"
                      aria-label="enhanced table"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={this.handleRequestSort}
                        headCells={this.state.headCellsInternal}
                      />
                      <TableBody style={{ overflow: "hidden" }}>
                        {codeTypeList.length > 0 ? (
                          stableSort(codeTypeList, getSorting(order, orderBy))
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              return (
                                <TableRow
                                  hover
                                  tabIndex={-1}
                                  style={
                                    selectedEditIndex !== index
                                      ? { cursor: "pointer" }
                                      : {
                                        cursor: "pointer",
                                        background: "#ccc",
                                      }
                                  }
                                >
                                  {this.state.headCellsInternal[0].show && (
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      padding="default"
                                      className="table-content-cell"
                                      align="left"
                                    >
                                      {row.jouranlUniqueID &&
                                        row.jouranlUniqueID.length > 16 ? (
                                          <Tooltip
                                            title={row.jouranlUniqueID}
                                            aria-label="add"
                                          >
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.jouranlUniqueID}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            noWrap
                                            style={{ fontSize: "14px" }}
                                          >
                                            {row.jouranlUniqueID}
                                          </Typography>
                                        )}
                                    </TableCell>
                                  )}
                                  {selectedEditIndex !== index ||
                                    this.state.proMode ? (
                                      this.state.headCellsInternal[1].show && (
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="default"
                                          className="table-content-cell"
                                          align="right"
                                        >
                                          {row.quantity ? row.quantity : ""}
                                        </TableCell>
                                      )
                                    ) : (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-cell"
                                      >
                                        <TextField
                                          autoFocus
                                          margin="dense"
                                          id="name"
                                          autoComplete="off"
                                          name="newQuantity"
                                          onKeyPress={this._onNumberOnlyKeyPress}
                                          onChange={this.handleChange}
                                          value={this.state.newQuantity}
                                          placeholder="Quantity"
                                        />
                                      </TableCell>
                                    )}
                                  {selectedEditIndex !== index ? (
                                    this.state.headCellsInternal[2].show && (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-cell"
                                        align="left"
                                      >
                                        {row.trackingNumber &&
                                          row.trackingNumber.length > 16 ? (
                                            <Tooltip
                                              title={row.trackingNumber}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.trackingNumber}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.trackingNumber}
                                            </Typography>
                                          )}
                                      </TableCell>
                                    )
                                  ) : (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-cell"
                                      >
                                        <TextField
                                          margin="dense"
                                          id="name"
                                          autoComplete="off"
                                          name="newTrackingNumber"
                                          onChange={this.handleChange}
                                          value={this.state.newTrackingNumber}
                                          placeholder="Tracking Number"
                                        />
                                      </TableCell>
                                    )}
                                  {selectedEditIndex !== index ? (
                                    this.state.headCellsInternal[3]
                                      .show && (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-cell"
                                        align="left"
                                      >
                                        {row.noOfCartons &&
                                          row.noOfCartons.length > 16 ? (
                                            <Tooltip
                                              title={row.noOfCartons}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.noOfCartons}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.noOfCartons}
                                            </Typography>
                                          )}
                                      </TableCell>
                                    )
                                  ) : (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-cell"
                                      >
                                        <TextField
                                          margin="dense"
                                          id="name"
                                          autoComplete="off"
                                          name="noOfCartons"
                                          onChange={this.handleChange}
                                          value={this.state.noOfCartons}
                                          placeholder="No Of Cartons"
                                        />
                                      </TableCell>
                                    )}
                                  {selectedEditIndex !== index ? (
                                    this.state.headCellsInternal[4].show && (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-cell"
                                        align="right"
                                      >
                                        {row.actualWeight && row.actualWeight
                                          ? parseFloat(
                                            row.actualWeight
                                          ).toFixed(3)
                                          : "0.000"}
                                      </TableCell>
                                    )
                                  ) : (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-cell"
                                      >
                                        <TextField
                                          margin="dense"
                                          id="name"
                                          autoComplete="off"
                                          name="newActualWeight"
                                          onChange={this.handleChange}
                                          value={this.state.newActualWeight}
                                          placeholder="Actual Weight"
                                        />
                                      </TableCell>
                                    )}
                                  {selectedEditIndex !== index ? (
                                    this.state.headCellsInternal[5].show && (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-cell"
                                        align="left"
                                      >
                                        {row.deliveryDate &&
                                          row.deliveryDate.length > 16 ? (
                                            <Tooltip
                                              title={moment(
                                                row.deliveryDate
                                              ).format("DD/MM/YYYY")}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {moment(row.deliveryDate).format(
                                                  "DD/MM/YYYY"
                                                )}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.deliveryDate
                                                ? moment(row.deliveryDate).format(
                                                  "DD/MM/YYYY"
                                                )
                                                : ""}
                                            </Typography>
                                          )}
                                      </TableCell>
                                    )
                                  ) : (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-cell"
                                      >
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <KeyboardDatePicker
                                            autoOk
                                            disableToolbar
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            minDateMessage={""}
                                            name="newDeliveryDate"
                                            id="newDeliveryDate"
                                            value={this.state.newDeliveryDate}
                                            onChange={(e) =>
                                              this.handleDateChange(
                                                e,
                                                "newDeliveryDate"
                                              )
                                            }
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </TableCell>
                                    )}
                                  {selectedEditIndex !== index ? (
                                    this.state.headCellsInternal[6]
                                      .show && (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-cell"
                                        align="left"
                                      >
                                        {row.dhlRate &&
                                          row.dhlRate.length > 16 ? (
                                            <Tooltip
                                              title={row.dhlRate}
                                              aria-label="add"
                                            >
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.dhlRate}
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              noWrap
                                              style={{ fontSize: "14px" }}
                                            >
                                              {row.dhlRate}
                                            </Typography>
                                          )}
                                      </TableCell>
                                    )
                                  ) : (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        className="table-content-cell"
                                      >
                                        {this.state.dhlRate}
                                      </TableCell>
                                    )}
                                  {this.state.headCellsInternal[7].show && (
                                    <TableCell
                                      className="table-content-cell"
                                      style={{ textAlign: "center" }}
                                    >
                                      {selectedEditIndex !== index ? (
                                        <div>
                                          <Tooltip title="Edit">
                                            <span
                                              style={{
                                                cursor: "pointer",
                                                color: "#6c6b6b",
                                              }}
                                              onClick={(e) =>
                                                this.handleEditClicked(e, index)
                                              }
                                            >
                                              <EditIcon
                                                style={{
                                                  color: "#6c6b6b",
                                                }}
                                              />
                                            </span>
                                          </Tooltip>
                                          {this.state.proMode ? null : (
                                            <Tooltip title="Delete">
                                              <span
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                                onClick={(e) =>
                                                  this.handleDeleteClicked(
                                                    e,
                                                    index,
                                                    row
                                                  )
                                                }
                                              >
                                                <DeleteIcon
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                />
                                              </span>
                                            </Tooltip>
                                          )}
                                        </div>
                                      ) : (
                                          <div>
                                            {this.state.newQuantity > 0 ? (
                                              <Tooltip title="Done">
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                  onClick={(e) =>
                                                    this.handleDoneClicked(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <DoneIcon
                                                    style={{
                                                      color: "#6c6b6b",
                                                    }}
                                                  />
                                                </span>
                                              </Tooltip>
                                            ) : null}
                                            <Tooltip title="Cancel">
                                              <span
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                                onClick={(e) =>
                                                  this.handleCancelClicked(
                                                    e,
                                                    index
                                                  )
                                                }
                                              >
                                                <CloseIcon
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                />
                                              </span>
                                            </Tooltip>
                                          </div>
                                        )}
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })
                        ) : (
                            <TableRow>
                              {this.state.loading ? (
                                <TableCell
                                  colSpan={15}
                                  style={{ textAlign: "center" }}
                                >
                                  <LinearProgress />
                                </TableCell>
                              ) : (
                                  <TableCell
                                    colSpan={8}
                                    style={{ textAlign: "center" }}
                                  >
                                    No result found
                                  </TableCell>
                                )}
                            </TableRow>
                          )}
                      </TableBody>
                    </Table>
                  </div>
                </div>

                <Divider style={{ margin: "25px 0 5px" }} />

                <div
                  className="button-wrapper"
                  style={{ paddingLeft: "0px", marginLeft: "0px" }}
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/pubEbloOrderPlacement")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.isManual ? (
                      <Button
                        autoFocus
                        color="primary"
                        variant="contained"
                        style={{ boxShadow: "none" }}
                        onClick={(e) => this.handleSubmit(e, false)}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          disabled={
                            !this.state.recipient ||
                              (!this.state.addressLine1 && !this.state.addressLine2 && !this.state.addressLine3) ||
                              !this.state.postCode ||
                              !this.state.PGSCountry ||
                              !this.state.country ||
                              this.state.codeTypeList.length === 0 ||
                              this.state.quantityCheck ||
                              this.state.selectedEditIndex !== null
                              ? true
                              : false
                          }
                          autoFocus
                          color="primary"
                          variant="contained"
                          style={{ boxShadow: "none" }}
                          onClick={(e) => this.handleSubmit(e, true)}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(EditEBLOOrderPlacement));
