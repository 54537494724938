import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Validator from "../../../shared/validator";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { resourceValidation } from "../../../shared/resource";
import { postAcronym } from "../masterActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { addStyles } from "../../../shared/styles";
import { Autocomplete } from "@material-ui/lab";
import { Prompt } from "react-router-dom";

const validateMaxlength2 = new Validator("maxlength2");
const validateMaxlength2to10 = new Validator("maxlength2to10");
const validateMaxlength100 = new Validator("maxlength100");
const validateNotRequired2 = new Validator("notRequired2");
const validateNotRequired100 = new Validator("notRequired100");
const validateNotRequired1000 = new Validator("notRequired1000");
const notRequired100 = new Validator("notRequired100");
const Alphanumeric = new Validator("Alphanumeric");

class AddAcronym extends Component {
  constructor() {
    super();

    this.state = {
      //isBlocking: false,//uncomment it for Prompt box
      acronym: {
        acronymSuffix: "",
        acronymNumber: "",
        isPeriodical: false,
        specialInstruction: "",
        dimension: "",
        journalType: "",
        journalID: "",
        isInternal: true,
        officeName: "",
        businessUnit: "",
        journalTitle: ""
      },
      errors: {},
      acronymSuffixValid: false,
      acronymNumberValid: false,
      specialInstructionValid: false,
      altCountryNameValid: false,
      dimensionValid: false,
      journalTypeValid: false,
      officeNameValid: false,
      businessUnitValid: false,
      acronymList: [],
      journaltypeList: [
        { values: "ISS", id: "ISS" },
        { values: "SUPP", id: "SUPP" },
      ],
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.handleSettingHeader("Acronym");
  }
  handleJournalType = (event, values) => {
    //console.log(values);
    const { acronym } = this.state;
    if (values) {
      acronym["journalType"] = values.values;
      acronym["journalID"] = values.id;
    }
    else {
      acronym["journalType"] = {}
      acronym["journalID"] = ""
    }
    this.setState({ acronym });
  };
  handleChange = (event) => {
    let val = event.target.value
    const { acronym } = this.state;
    if (
      event.target.name === "isPeriodical" ||
      event.target.name === "isInternal"
    ) {
      acronym[event.target.name] = event.target.checked;
    } else {
      if (event.target.name === "acronymSuffix") {
        val = val.toUpperCase()
      }
      acronym[event.target.name] = val;
      this.validateField(event.target.name, val);
    }
    this.setState({ acronym });
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;

    let acronymSuffixValid = this.state.acronymSuffixValid;
    let acronymNumberValid = this.state.acronymNumberValid;
    let specialInstructionValid = this.state.specialInstructionValid;
    let businessUnitValid = this.state.businessUnitValid;
    let dimensionValid = this.state.dimensionValid;
    let journalTypeValid = this.state.journalTypeValid;
    let officeNameValid = this.state.officeNameValid;
    switch (fieldName) {
      case "acronymSuffix":
        if (value !== "") {
          acronymSuffixValid = validateMaxlength2to10(value);
          fieldValidationErrors.acronymSuffix = acronymSuffixValid
            ? ""
            : resourceValidation.max2char;
        } else {
          acronymSuffixValid = false;
          fieldValidationErrors.acronymSuffix = "Please Enter Mandatory Field";
        }
        break;
      case "acronymNumber":
        acronymNumberValid = true;
        fieldValidationErrors.acronymNumber = acronymNumberValid
          ? ""
          : resourceValidation.max10char;
        break;
      case "specialInstruction":
        specialInstructionValid = true;
        fieldValidationErrors.specialInstruction = specialInstructionValid
          ? ""
          : " Please enter alphabet characters only";
        break;
      case "officeName":
        if (value !== "") {
          officeNameValid = true;
          fieldValidationErrors.officeName = officeNameValid
            ? ""
            : resourceValidation.onlyAlphabet;
        } else {
          officeNameValid = false;
          fieldValidationErrors.officeName = "Please Enter Mandatory Field";
        }
        break;
      case "dimension":
        dimensionValid = Alphanumeric(value);
        fieldValidationErrors.dimension = dimensionValid
          ? ""
          : resourceValidation.onlyAlphabet;
        break;
      case "businessUnit":
        businessUnitValid = true;
        fieldValidationErrors.businessUnit = businessUnitValid
          ? ""
          : resourceValidation.onlyAlphabet;
        break;
      case "journalType":
        if (value !== "") {
          journalTypeValid = notRequired100(value);
          fieldValidationErrors.journalType = journalTypeValid
            ? ""
            : resourceValidation.onlyAlphabet;
        } else {
          journalTypeValid = false;
          fieldValidationErrors.journalType = "Please Enter Mandatory Field";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,

        acronymSuffixValid: acronymSuffixValid,
        acronymNumberValid: acronymNumberValid,
        specialInstructionValid: specialInstructionValid,
        dimensionValid: dimensionValid,
        journalTypeValid: journalTypeValid,
        businessUnitValid: businessUnitValid,
        officeNameValid: officeNameValid,
      },
      this.validateForm
    );
  }

  validateForm = () => {
    this.setState({
      formValid:
        this.state.acronymSuffixValid &&
        // this.state.journalTypeValid &&
        this.state.officeNameValid,
    });

    return this.state.formValid;
  };

  handleClose = () => {
    // clean up state and errors when modal closed
    this.setState({
      acronymSuffix: "",
      acronymNumber: "",
      isPeriodical: "",
      specialInstruction: "",
      dimension: "",
      journalType: "",
      isInternal: "",
      officeName: "",
      businessUnit: "",
      errors: {},
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // this.setState({ isBlocking: true });//uncomment it for Prompt box
    if (this.validateForm()) {
      // this.setState({ loading: true });
      const { acronym } = this.state;
      let acronymSuffix = acronym.acronymSuffix;
      let acronymNumber = acronym.acronymNumber;
      let specialInstruction = acronym.specialInstruction;
      let dimension = acronym.dimension;
      let journalType = acronym.journalType;
      let officeName = acronym.officeName;
      let businessUnit = acronym.businessUnit;
      let journalTitle = acronym.journalTitle
      let isPeriodical = acronym.isPeriodical;
      let isInternal = acronym.isInternal;

      let reqBody = {
        acronymSuffix,
        acronymNumber,
        isPeriodical,
        specialInstruction,
        dimension,
        journalType,
        journalTitle,
        isInternal,
        officeName,
        businessUnit,
      };
      reqBody.createdBy = localStorage.loggedInId;
      reqBody.isActive = "true";

      postAcronym(reqBody, this.props.history)
        .then((r) => {
          this.setState({ loading: false });
        })
        .catch((e) => {
          this.setState({ loading: false });
        });
    }
  };
  onInputChanged = (e, name, type) => {
    let newError = { ...this.state.errors };
    if (type === "maxlength2") {
      let result = validateMaxlength2(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else if (type === "maxlength100") {
      let result = validateMaxlength100(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else if (type === "notRequired2") {
      let result = validateNotRequired2(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else if (type === "notRequired100") {
      let result = validateNotRequired100(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else {
      let result = validateNotRequired1000(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    }
    this.setState({ [name]: e.target.value });
  };

  _onKeyPress(event) {
    const re = /[0-9a-zA-Z ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onAlphaKeyPress(event) {
    const re = /[a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onNumberKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onDimensionKeyPress(event) {
    const re = /[0-9*]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  render() {
    let { classes } = this.props;
    let { acronym, errors, isBlocking } = this.state;
    return (
      <React.Fragment>
        <Paper>
          {/* <Prompt
            message={() => {
              return this.props.location.pathname === "/acronym" ||
                isBlocking == true
                ? true
                : `Are you sure you want to leave this page?`;
            }}
          /> */}
          {/* uncomment it to enable to Prompt */}
          <div className="pop-up-header">Create New Acronym</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    label="Acronym Suffix"
                    name="acronymSuffix"
                    inputProps={{
                      maxLength: 10,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={acronym.acronymSuffix}
                    onChange={this.handleChange}
                    error={this.state.errors.acronymSuffix}
                    helperText={
                      this.state.errors.acronymSuffix
                        ? errors.acronymSuffix
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    label="Acronym Number"
                    name="acronymNumber"
                    inputProps={{
                      maxLength: 10,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={acronym.acronymNumber}
                    onChange={this.handleChange}
                    error={this.state.errors.acronymNumber}
                    helperText={
                      this.state.errors.acronymNumber
                        ? resourceValidation.max10char
                        : ""
                    }
                    onKeyPress={this._onNumberKeyPress}
                  />
                </div>
                <div>
                  <TextField
                    required
                    label="Office Name"
                    name="officeName"
                    inputProps={{
                      maxLength: 50,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={acronym.officeName}
                    onChange={this.handleChange}
                    error={this.state.errors.officeName}
                    helperText={
                      this.state.errors.officeName ? errors.officeName : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    label="Business Unit"
                    name="businessUnit"
                    inputProps={{
                      maxLength: 10,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={acronym.businessUnit}
                    onChange={this.handleChange}
                    error={this.state.errors.businessUnit}
                    helperText={
                      this.state.errors.businessUnit
                        ? "Maximum 10 characters allowed"
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    label="Dimension"
                    name="dimension"
                    inputProps={{
                      maxLength: 100,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={acronym.dimension}
                    onChange={this.handleChange}
                    error={this.state.errors.dimension}
                    helperText={
                      this.state.errors.dimension
                        ? resourceValidation.max100char
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    label="Special Instruction"
                    name="specialInstruction"
                    inputProps={{
                      maxLength: 1000,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    value={acronym.specialInstruction}
                    onChange={this.handleChange}
                    error={this.state.errors.specialInstruction}
                    helperText={
                      this.state.errors.specialInstruction
                        ? resourceValidation.max1000char
                        : ""
                    }
                  />
                </div>
                <div>
                  <Autocomplete
                    autoComplete
                    required
                    disableClearable
                    includeInputInList
                    label="Journal Type"
                    name="journalType"
                    style={{ display: "inline-flex" }}
                    options={this.state.journaltypeList}
                    className={classes.comboBox}
                    onChange={this.handleJournalType}
                    value={acronym.journalType}
                    getOptionLabel={(option) =>
                      option.values || acronym.journalType
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Journal Type"
                        name="journalType"
                        style={{ width: "100%" }}
                        margin="normal"
                      />
                    )}
                  />
                  <TextField
                    label="Title Description"
                    name="journalTitle"
                    required
                    inputProps={{
                      maxLength: 50,
                    }}
                    className={classes.textField}

                    // onKeyPress={this._onAllCharPress}
                    margin="normal"
                    // style={{ flex: 1, marginRight: "16px" }}
                    value={this.state.acronym.journalTitle}
                    onChange={this.handleChange}
                  />
                  {/* <TextField
                    required
                    
                    inputProps={{
                      maxLength: 20,
                    }}
                    autoComplete="off"
                    margin="normal"
                    className={classes.textField}
                    
                    
                    error={this.state.errors.journalType}
                    helperText={
                      this.state.errors.journalType ? errors.journalType : ""
                    }
                    onKeyPress={this._onAlphaKeyPress}
                  /> */}
                </div>
                {/* 2nd line */}
                <div>
                  <FormControlLabel
                    className={classes.checkBox}
                    name="isPeriodical"
                    margin="normal"
                    control={
                      <Checkbox
                        color="primary"
                        checked={acronym.isPeriodical}
                        value={acronym.isPeriodical}
                        onChange={this.handleChange}
                      />
                    }
                    label="Periodical"
                  />

                  <FormControlLabel
                    className={classes.checkBox}
                    name="isInternal"
                    control={
                      <Checkbox
                        color="primary"
                        checked={acronym.isInternal}
                        value={acronym.isInternal}
                        onChange={this.handleChange}
                      />
                    }
                    label="Internal"
                  />
                </div>

                <Divider style={{ marginTop: "25px" }} />
                <div className="button-wrapper">
                  {this.state.loading ? (
                    ""
                  ) : (
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          //disableElevation
                          style={{ boxShadow: "none" }}
                          onClick={(e) => {
                            this.props.history.push("/acronym");
                          }}
                        >
                          CANCEL
                      </Button>
                      </div>
                    )}
                  <div style={{ marginLeft: "1em" }}>
                    {this.state.loading ? (
                      <CircularProgress />
                    ) : !this.state.formValid ||
                      (this.state.acronym.journalType == "" || this.state.acronym.journalTitle == "") ? (
                          <Button
                            disabled
                            //autoFocus
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                            //disableElevation
                            onClick={this.handleSubmit}
                          // className={
                          //   !this.state.formValid
                          //     ? "disabled-button"
                          //     : "enabled-button "
                          // }
                          >
                            SUBMIT
                          </Button>
                        ) : (
                          <Button
                            //autoFocus
                            variant="contained"
                            color="primary"
                            style={{ boxShadow: "none" }}
                            onClick={this.handleSubmit}
                          // className={
                          //   !this.state.formValid
                          //     ? "disabled-button"
                          //     : "enabled-button "
                          // }
                          >
                            SUBMIT
                          </Button>
                        )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddAcronym));
