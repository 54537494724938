import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import { getItemMasterCode } from "../genericActions";
import { StateContext } from "../../../shared/globalState";
import { printDetails, addZeros } from "../../../shared/tableCommonFunctions";

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { selectedStockID } = props;
  let acnArr = props.roles.filter((u) => u.subMenuName === "Item Master");
  let acnObj = acnArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_ItemMaster/${selectedStockID}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {/* <Tooltip title="Download">
            <IconButton aria-label="download" className={classes.iconHover} onClick={e => saveCustomerDetails()}>
              <DownloadIcon />
            </IconButton>
          </Tooltip> */}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewItemMaster extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      ItemMaster: {
        customerName: "",
        stockCode: "",
        stockName: "",
        uomName: "",
        stockCategoryName: "",
        reOrderLevelQty: "",
        price: 0,
        cost: 0,
        isConsumable: true,
        remarks: "",
        isActive: false,
        weightPerItem: 0.0,
        dimension: "",
      },
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let StockID = this.props.match.params.StockID;
    getItemMasterCode(StockID).then((r) => {
      dispatch({
        type: "getItemMasterCode",
        newStore: r,
      });
      this.setState({ ItemMaster: r });
    });
  }

  onBlur = (event) => {
    const { ItemMaster } = this.state;
    let dhlValues = event.target.value;
    dhlValues = this.addZeroes(dhlValues.toString(), 2);
    ItemMaster[event.target.name] = dhlValues;
    this.setState({ ItemMaster });
  };

  render() {
    let { ItemMaster } = this.state;
    let StockID = this.props.match.params.StockID;
    let { consummable } = "";
    if (ItemMaster.isConsumable === true) {
      consummable = "Yes";
    } else {
      consummable = "No";
    }
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            selectedStockID={StockID}
            roles={this.props.roles}
          />
          {/* <div className="view-page-header">Cost Per Title Information</div> */}
          <Divider />

          <div className="view-page-container" id="printable">
            <div className="view-page-headings">Item Master Information</div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Customer Name</div>
                <div className="view-page-value">{ItemMaster.customerName}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">UOM Name</div>
                <div className="view-page-value">{ItemMaster.uomName}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Stock Category</div>
                <div className="view-page-value">
                  {ItemMaster.stockCategoryName}
                </div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Stock Code</div>
                <div className="view-page-value">{ItemMaster.stockCode}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Stock Name</div>
                <div className="view-page-value">{ItemMaster.stockName}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Reorder Level Qty</div>
                <div className="view-page-value">
                  {ItemMaster.reOrderLevelQty}
                </div>
              </div>
            </div>

            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Price ($S)</div>
                <div className="view-page-value">{ItemMaster.price}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Cost ($S)</div>
                <div className="view-page-value">
                  {addZeros(ItemMaster.cost.toString(), 2)}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Dimension</div>
                <div className="view-page-value">{ItemMaster.dimension}</div>
              </div>
            </div>

            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Weight Per Item (Kg)</div>
                <div className="view-page-value">
                  {addZeros(ItemMaster.weightPerItem.toString(), 3)}
                </div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Remarks</div>
                <div className="view-page-value">{ItemMaster.remarks}</div>
              </div>
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Consumable</div>
                <div className="view-page-value">{consummable}</div>
              </div>
            </div>
            <div className="view-page-contents-container">
              <div className="view-page-contents-sub-container">
                <div className="view-page-label">Active</div>
                <div className="view-page-value">
                  {ItemMaster.isActive === true ? "Yes" : "No"}
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div className="button-wrapper">
            <div style={{ marginLeft: "2em" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.props.history.push("/ItemMaster")}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(ViewItemMaster);
