import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import { StateContext } from "../../../shared/globalState";
import { printDetails } from "../../../shared/tableCommonFunctions";
import { getAcronymDetails } from "../masterActions";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    flexWrap: "wrap",
    margin: "1em"
  }
});

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { selectedAcronymId } = props;
  let acnArr = props.roles.filter(u => u.menuName === "Acronym");
  let acnObj = acnArr[0];

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%"
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_acronym/${selectedAcronymId}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={e => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewAcronym extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      acronym: {
        acronymSuffix: "",
        acronymNumber: "",
        isPeriodical: "",
        specialInstruction: "",
        dimension: "",
        journalType: "",
        isInternal: "",
        officeName: "",
        businessUnit: "",
        isActive: ""
      }
    };
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let acronymID = this.props.match.params.acronymID;

    getAcronymDetails(acronymID).then(r => {
      dispatch({
        type: "getAcronym",
        newStore: r
      });
      this.setState({ acronym: r });
    });
  }

  render() {
    let { classes } = this.props;
    let { acronym } = this.state;
    let acronymID = this.props.match.params.acronymID;
    return (
      <React.Fragment>
        <Paper>
          <div className={classes.container}>
            <EnhancedTableToolbar
              selectedAcronymId={acronymID}
              roles={this.props.roles}
            />
            <Divider />
            <div className="view-page-container" id="printable">
              <div className="view-page-headings">Acronym Information</div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container2">
                  <div className="view-page-label">Acronym Suffix</div>
                  <div className="view-page-value">{acronym.acronymSuffix}</div>
                </div>
                <div className="view-page-contents-sub-container2">
                  <div className="view-page-label">Acronym Number</div>
                  <div className="view-page-value">{acronym.acronymNumber}</div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container2">
                  <div className="view-page-label">Office Name</div>
                  <div className="view-page-value">{acronym.officeName}</div>
                </div>
                <div className="view-page-contents-sub-container2">
                  <div className="view-page-label">Business Unit</div>
                  <div className="view-page-value">{acronym.businessUnit}</div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container2">
                  <div className="view-page-label">Dimension</div>
                  <div className="view-page-value">{acronym.dimension}</div>
                </div>
                <div className="view-page-contents-sub-container2">
                  <div className="view-page-label">Special Instruction</div>
                  <div className="view-page-value">
                    {acronym.specialInstruction}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container2">
                  <div className="view-page-label">Periodical</div>
                  <div className="view-page-value">
                    {acronym.isPeriodical === true ? "Yes" : "No"}
                  </div>
                </div>
                <div className="view-page-contents-sub-container2">
                  <div className="view-page-label">Active</div>
                  <div className="view-page-value">
                    {acronym.isActive === true ? "Yes" : "No"}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container2">
                  <div className="view-page-label">Journal Type</div>
                  <div className="view-page-value">{acronym.journalType}</div>
                </div>
                <div className="view-page-contents-sub-container2">
                  <div className="view-page-label">Title Description</div>
                  <div className="view-page-value">{acronym.journalTitle}</div>
                </div>
                <div className="view-page-contents-sub-container2">
                  <div className="view-page-label">Internal</div>
                  <div className="view-page-value">
                    {acronym.isInternal === true ? "Yes" : "No"}
                  </div>
                </div>
              </div>
              <Divider style={{ marginTop: "25px" }} />
              <div className="button-wrapper">
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    //disableElevation
                    style={{ boxShadow: "none" }}
                    onClick={e => this.props.history.push("/Acronym")}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(ViewAcronym));
