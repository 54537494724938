const ZONE_LIST = "ZONE_LIST";
const COUNTRY_LIST = "COUNTRY_LIST";
const CUSTOMER_LIST = "CUSTOMER_LIST";
const PPI_LIST = "PPI_LIST";
const CCI_LIST = "CCI_LIST";
const USER_LIST = "USER_LIST";
const LETTERSHOP_LIST = "LETTERSHOP_LIST";
const CPTCODE_LIST = "CPTCODE_LIST";
const DEPARTMENT_LIST = "DEPARTMENT_LIST";
const PROCESSTYPE_LIST = "PROCESSTYPE_LIST";
const PROCESSMASTER_LIST = "PROCESSMASTER_LIST";
const PROCESSRATE_LIST = "PROCESSRATE_LIST";
const PROVINCE_LIST = "PROVINCE_LIST";

const GET_DEPARTMENT_DATA = "GET_DEPARTMENT_DATA";
const GET_CPTCODE_DATA = "GET_CPTCODE_DATA";
const PRODLINE_LIST = "PRODLINE_LIST";
const OUTWORKERCLAIMRATE_LIST = "OUTWORKERCLAIMRATE_LIST";
const PROCESS_LIST = "PROCESS_LIST";
const CODEMASTER_LIST = "CODEMASTER_LIST";
const CODETYPE_LIST = "CODETYPE_LIST";
const UOMDDL_LIST = "UOMDDL_LIST";

export default {
  ZONE_LIST,
  COUNTRY_LIST,
  CUSTOMER_LIST,
  PPI_LIST,
  CCI_LIST,
  USER_LIST,
  LETTERSHOP_LIST,
  CPTCODE_LIST,
  GET_CPTCODE_DATA,
  DEPARTMENT_LIST,
  PROCESSTYPE_LIST,
  PROCESSRATE_LIST,
  PROVINCE_LIST,
  GET_DEPARTMENT_DATA,
  PRODLINE_LIST,
  OUTWORKERCLAIMRATE_LIST,
  PROCESS_LIST,
  CODEMASTER_LIST,
  CODETYPE_LIST,
  UOMDDL_LIST,PROCESSMASTER_LIST
};
