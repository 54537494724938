import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import clone from "clone";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import DeletePP from "./delete";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { resourceValidation } from "../../../shared/resource";
import AdvanceSearch from "./search";
import {
  getPubBatchLabelOrderPlacement,
  searchPubBatchLabelOrderPlacement,
} from "./actions";
import moment from "moment";
import {
  SearchIcon,
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import {
  stableSort,
  getSorting,
  DialogActions,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import Validator from "../../../shared/validator";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import { StateContext } from "../../../shared/globalState";
import //   getCodeTypeList,
  //   deleteCodeType,
  //   getFliteredcodeTypeList
  //   //   getProductionLineList
  "./actions";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ExportCSV } from "../../../shared/ExportCSV";

const columns = [
  {
    name: "Status",
    id: 7,
    // show: false
  },
  {
    name: "Modified By",
    id: 8,
  },
  {
    name: "Modified Date",
    id: 9,
  },
  {
    name: "Created By",
    id: 10,
    // show: false
  },
  {
    name: "Created Date",
    id: 11,
  },
];
const notRequiredAlpNumHypDot50 = new Validator("notRequiredAlpNumHypDot50");

function createData(
  client,
  location,
  batchId,
  processedDate,
  totalOrderFiles,
  totalQuantity,
  status,
  // carrierSheet,
  // docketBagging,
  // docketSubmitted,
  modifiedBy,
  modifiedDate,
  createdBy,
  createdDate,

  id,
  cId,
  lId
) {
  return {
    client,
    location,
    batchId,
    processedDate,
    totalOrderFiles,
    totalQuantity,
    status,
    // carrierSheet,
    // docketBagging,
    // docketSubmitted,
    modifiedBy,
    modifiedDate,
    createdBy,
    createdDate,

    id,
    cId,
    lId,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    selectedRow,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"
          style={{ borderTop: "1px solid #eaeaea", background: "white" }}
          className="table-cell">
          {/* <FormControlLabel
            control={
              <CustomCheckbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all production code" }}
              />
            }
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={"left"}
                // padding={headCell.disablePadding ? "none" : "default"}
                padding="none"
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
                style={{ padding: ".5em" }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <Tooltip title={headCell.label}>
                    <span style={{ color: "#306dca" }}>
                      {headCell.sortLable}
                    </span>
                  </Tooltip>
                </TableSortLabel>
                {/* <span style={{ color: "#306dca" }}>{headCell.sortLable}</span> */}
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    client: "",
    location: "",
    batchId: "",
    processedDate: "",
    totalOrderFiles: "",
    totalQuantity: "",
    status: "",
    carrierSheet: "",
    docketBagging: "",
    docketSubmitted: "",
  });
  const [formValid, setIsformValid] = useState(false);
  let acnArr = props.roles.filter(
    (u) => u.menuName === "Main Label Batch Processing"
  );
  let acnObj = acnArr[0];

  const [labelBatchSearchValue, setValues] = useState({
    client: "",
    location: "",
    batchId: "",
    processedDate: "",
    totalOrderFiles: "",
    totalQuantity: "",
    status: "",
    carrierSheet: "",
    docketBagging: "",
    docketSubmitted: "",
  });

  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,

    handledeleteProductionLineCode,
    searchPopupOpen,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchlabelBatchCode,
    searchClicked,

    hendleVewColums,
    codeTypeList,
    exportDataValues,
    selected,
    isActive,
    selectedRow,
    rows,
  } = props;

  //const selectedProductionID = selected && selected.length > 0 && selected[0];
  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  const selectedProductionID = selected[0];
  const selectedlabelBatchID = codeTypeList.find(
    (x) => x.id === selectedProductionID
  );

  let expData = clone(rows);

  expData.map((d) => {
    d["Customer"] = d["client"];
    d["Location"] = d["location"];
    d["Batch Id"] = d["batchId"];
    // d["Processed Date"] = moment(d["processedDate"]).format("DD/MM/YYYY");
    d["Total Order Files"] = d["totalOrderFiles"];
    d["Total Quantity"] = d["totalQuantity"];
    d["Status"] = d["status"] && d["status"] === "Complete" ? "Completed" : d["status"];
    d["Modified By"] = d["modifiedBy"];
    d["Modified Date"] = d["modifiedDate"]
      ? moment(d["modifiedDate"]).format("DD/MM/YYYY HH:mm:ss")
      : "";
    d["Created By"] = d["createdBy"];
    d["Created Date"] = d["createdDate"]
      ? moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss")
      : "";
    delete d["id"];
    delete d["client"];
    delete d["location"];
    delete d["batchId"];
    delete d["processedDate"];
    delete d["totalOrderFiles"];
    delete d["totalQuantity"];
    delete d["status"];
    delete d["cId"];
    delete d["lId"];
    delete d["createdBy"];
    delete d["createdDate"];
    delete d["modifiedBy"];
    delete d["modifiedDate"];
  });

  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteProductionLineCode = () => {
    handledeleteProductionLineCode();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = labelBatchSearchValue;
    searchValues.client = "";
    searchValues.location = "";
    searchValues.batchId = "";
    searchValues.processedDate = "";
    searchValues.totalOrderFiles = "";
    searchValues.totalQuantity = "";
    searchValues.status = "";
    searchValues.carrierSheet = "";
    searchValues.docketBagging = "";
    searchValues.docketSubmitted = "";
    searchValues = labelBatchSearchValue;
  };

  const searchPopup = () => {
    handleSearchlabelBatchCode(labelBatchSearchValue);
    clearSearchValues();
  };

  const handleSearchInput = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;
    setValues((labelBatchSearchValue) => ({
      ...labelBatchSearchValue,
      [event.target.name]: event.target.value,
    }));

    switch (name) {
      case "client":
        // let client = notRequiredAlpNumHypDot50(value);
        // setIsformValid(client);
        // setErrors(errors => ({
        //   ...errors,
        //   [event.target.name]: client ? "" : resourceValidation.client
        // }));
        break;

      default:
        break;
    }
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div style={{ display: "flex", alignItems: "center" }}>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link
                      to={`/view_pubMainLabelBatchPlacement/${selectedRow.cId}/${selectedRow.lId}/${selectedRow.id}`}
                    >
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj && acnObj.editAction && props.editable && (
                  <Tooltip title="Edit">
                    <Link
                      to={`/edit_pubMainLabelBatchPlacement/${selectedRow.cId}/${selectedRow.lId}/${selectedRow.id}`}
                    >
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </div>
            )}
            {acnObj && acnObj.deleteAction && props.editableDelete && (
              <Tooltip title="Delete">
                <DeletePP
                  mode="delete"
                  selected={selected}
                  selectedRows={props.rows}
                  data={props.selectedLocalPostage}
                  onRequestSucceed={props.onRequestSucceed}
                  completed={props.isRollBack}
                />
              </Tooltip>
            )}
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {" "}
              {acnObj && acnObj.addAction && <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: ".5em",
                }}
              >
                <Link
                  to={`/create_preProcess`}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    //style={{ height: "36px" }}
                    color="primary"
                    style={{ boxShadow: "none" }}
                    variant="contained"
                  >
                    Pre Process
                </Button>
                </Link>
              </div>}
              {props.searchClicked ? (
                // <Tooltip title="Clear search result">
                <IconButton
                  aria-label="clear"
                  className={classes.iconHover}
                  onClick={(e) => props.clearSearch()}
                >
                  <HighlightOffIcon />
                </IconButton>
              ) : (
                  acnObj &&
                  acnObj.advanceSearchAction && (
                    <Tooltip title="Advance search">
                      <AdvanceSearch
                        onSearchClicked={props.onSearchClicked}
                        onRequestSucceed={props.onRequestSucceed}
                      />
                    </Tooltip>
                  )
                )}
              {acnObj && acnObj.exportAction && (
                <ExportCSV
                  csvData={expData}
                  fileName={"MAIN_LABEL_BATCH_ORDER_PLACEMENT"}
                />
              )}
              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                hendleVewColums={hendleVewColums}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class labelBatchCodeMater extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    editable: true,
    isRollBack: false,
    editableDelete: true,
    removable: true,
    rowsPerPage: 100,
    codeTypeList: [],
    rows: [],
    activeRows: 0,
    isActive: false,
    loading: false,
    refreshData: false,
    selectedlabelBatchID: {},
    selectedlabelBatchIDId: 1,
    searchPopupOpen: false,
    labelBatchSearchValues: {},
    searchClicked: false,
    selectedShowColumns: [],
    exportDataValues: [],
    selectedRow: {},
    headCells: [
      {
        id: 1,
        code: "client",
        numeric: false,
        disablePadding: false,
        label: "Customer",
        sortLable: "Customer",
        show: true,
      },
      {
        id: 2,
        code: "location",
        numeric: false,
        disablePadding: false,
        label: "Location",
        sortLable: "Location",
        show: true,
      },
      {
        id: 3,
        code: "batchId",
        numeric: false,
        disablePadding: false,
        label: "Batch Id",
        sortLable: "Batch Id",
        show: true,
      },
      {
        id: 4,
        code: "processedDate",
        numeric: false,
        disablePadding: false,
        label: "Processed Date",
        sortLable: "Processed Date",
        show: false,
      },
      {
        id: 5,
        code: "totalOrderFiles",
        numeric: false,
        disablePadding: false,
        label: "Total Order Files",
        sortLable: "Orders",
        show: true,
      },
      {
        id: 6,
        code: "totalQuantity",
        numeric: false,
        disablePadding: false,
        label: "Total Quantity",
        sortLable: "Qty",
        show: true,
      },
      {
        id: 7,
        code: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        sortLable: "Status",
        show: true,
      },
      // {
      //   id: 8,
      //   code: "carrierSheet",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Carrier Sheet",
      //   show: true
      // },
      // {
      //   id: 9,
      //   code: "docketBagging",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Docket Bagging",
      //   show: true
      // },
      // {
      //   id: 10,
      //   code: "docketSubmitted",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Docket Submitted",
      //   show: true
      // },

      {
        id: 8,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        sortLable: "Modified By",
        show: true,
      },
      {
        id: 9,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        sortLable: "Modified Date",
        show: true,
      },
      {
        id: 10,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        sortLable: "Created By",
        show: true,
      },
      {
        id: 11,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        sortLable: "Created Date",
        show: true,
      },
    ],
  };
  componentDidMount() {
    this.props.handleSettingHeader("Main Label Batch Processing");
    this.props.setSelectedList(
      "publisherMainLabelOpen",
      "pubMainLabelBatchPlacement"
    );
    const dispatch = this.context[1];

    getPubBatchLabelOrderPlacement().then((r) => {
      this.getAllPlacedOrder(r.data);
    });
    this.setState({ loading: true });
  }

  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  getAllPlacedOrder = (lpList) => {
    let list = lpList;
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        newRows.push(
          createData(
            d.customerName,
            d.locationCode,
            d.batchCode,
            d.batchProcessedDate,
            d.totalOrder,
            d.totalCopies,
            d.statusName,
            d.modifiedName,
            d.modifiedDate,
            d.createdByName,
            d.createdDate,
            d.mlBatchHeaderID,
            d.customerID,
            d.locationID
          )
        );
      });
    this.setState({ rows: newRows, loading: false });
    //initailData = newRows;
  };

  getCodeType = (codeTypeList) => {
    // const [{ store }, dispatch] = this.context;
    let list = codeTypeList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.client,
            d.labelBatch,
            d.type,
            d.description,
            d.acronym,
            d.volume,
            d.issue,
            d.part,
            d.support,
            d.trackingNumber,
            d.orderDate,
            d.orderCreated,
            d.department,
            d.issueType,
            d.category,
            d.quantity,
            d.deliveryMethod
          )
        );
      });
    this.setState({
      codeTypeList: rows,
      selected: [],
      loading: false,
      activeRows: rows.filter((x) => x.isActive).map((n) => n.id),
    });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    // if (store.codeTypeList && store.codeTypeList.length > 0)
    //   if (
    //     this.state.codeTypeList.length !== store.codeTypeList.length &&
    //     !this.state.searchClicked
    //   ) {
    //     !this.state.searchClicked && this.getCodeType(store.codeTypeList);
    //   }
    return true;
  }

  // compo

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  //   handleSelectAllClick = event => {
  //     let { codeTypeList } = this.state;
  //     if (event.target.checked) {
  //       const newSelecteds = codeTypeList
  //         .filter(x => x.isActive === true)
  //         .map(n => n.id);
  //       this.setState({ selected: newSelecteds, isActive: true });
  //       return;
  //     }
  //     this.setState({ selected: [] });
  //   };

  handleClick(event, data) {
    let id = data.id;
    // const selectedIndex = selected.indexOf(id);
    // let newSelected = [];
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, id);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1)
    //   );
    // }
    // if (newSelected.length === 1) {
    //   this.setState({
    //     selected: newSelected,
    //     selectedlabelBatchID: data.codeType,
    //     selectedProductionID: id,
    //   });
    // } else {
    //   this.setState({
    //     selected: newSelected,
    //     selectedlabelBatchID: data.codeType,
    //     selectedProductionID: id,
    //   });
    // }
    let newSelected = [];
    let selected = [];
    let newSelectedRows = [];

    if (event.target.checked) {
      newSelected = newSelected.concat(selected, id);
      newSelectedRows = newSelected.concat(id);
      this.setState({
        selected: newSelected,
        selectedRows: newSelectedRows,
      });
    } else {
      this.setState({
        selected: newSelected,
        selectedRows: newSelectedRows,
      });
    }
    let newSR = [];
    if (newSelected.length > 0) {
      newSR = this.state.rows.filter((d) => d.id === newSelected[0]);
      this.setState({ selectedRow: newSR[0] });
    }
    if (newSR.length === 1) {
      if (newSR[0].status === "Complete" || newSR[0].status === "Cancelled") {
        this.setState({ editable: false });
      } else {
        this.setState({ editable: true });
      }
      if (newSR[0].status === "Complete")
        this.setState({ isRollBack: true })
      else {
        this.setState({ isRollBack: false })
      }
    }
    if (newSR.length === 1) {
      if (newSR[0].status === "Cancelled") {
        this.setState({ editableDelete: false });
      } else {
        this.setState({ editableDelete: true });
      }
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchlabelBatchCode = (handleSearchlabelBatchCode) => {
    this.setState({ searchPopupOpen: false, searchClicked: true });
    let labelBatchSearchValue = handleSearchlabelBatchCode;
    const dispatch = this.context[1];

    // let codeType = labelBatchSearchValue.codeType;
    // let codeTypeDescription = labelBatchSearchValue.codeTypeDescription;
    // let canAddNew = labelBatchSearchValue.canAddNew;
    // let canEditStartDate = labelBatchSearchValue.canEditStartDate;
    // let canEditValue = labelBatchSearchValue.canEditValue;
    // let serviceSpecific = labelBatchSearchValue.serviceSpecific;
    // let codevalueDataType = labelBatchSearchValue.codevalueDataType;

    let client = labelBatchSearchValue.client;
    let labelBatch = labelBatchSearchValue.labelBatch;
    let type = labelBatchSearchValue.type;
    let description = labelBatchSearchValue.description;
    let acronym = labelBatchSearchValue.acronym;
    let volume = labelBatchSearchValue.volume;
    let issue = labelBatchSearchValue.issue;
    let part = labelBatchSearchValue.part;
    let support = labelBatchSearchValue.support;
    let trackingNumber = labelBatchSearchValue.trackingNumber;
    let orderDate = labelBatchSearchValue.orderDate;
    let orderCreated = labelBatchSearchValue.orderCreated;
    let department = labelBatchSearchValue.department;
    let issueType = labelBatchSearchValue.issueType;
    let category = labelBatchSearchValue.category;
    let quantity = labelBatchSearchValue.quantity;
    let deliveryMethod = labelBatchSearchValue.deliveryMethod;

    let reqBody = {
      client,
      labelBatch,
      type,
      description,
      acronym,
      volume,
      issue,
      part,
      support,
      trackingNumber,
      orderDate,
      orderCreated,
      department,
      issueType,
      category,
      quantity,
      deliveryMethod,
    };
    // getFliteredcodeTypeList(reqBody).then(r => {
    //   dispatch({
    //     type: "getCodeType",
    //     newStore: r.data
    //   });
    //   this.getCodeType(r.data);
    // });
  };

  handledeleteProductionLineCode = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    // deleteCodeType(modifiedBy, deletionIds).then(r => {
    //   getCodeTypeList().then(r => {
    //     dispatch({
    //       type: "getCodeType",
    //       newStore: r.data
    //     });
    //     this.getCodeType(r.data);
    //   });
    //   // this.props.history.push("/codeType");
    //   this.setState({ deletePopupOpen: false, selected: [] });
    // });
  };

  clearSearch = () => {
    this.setState({ rows: [], loading: true });
    let newheadCells = [...this.state.headCells];
    newheadCells["3"].show = false;
    this.setState({ headCells: newheadCells });

    getPubBatchLabelOrderPlacement().then((r) => {
      this.getAllPlacedOrder(r.data);
    });
    this.setState({ loading: true, searchClicked: false });
  };
  onRequestSucceed = () => {
    this.clearSearch();
    this.setState({ selected: [] });
  };
  onSearchClicked = (reqBody) => {
    let newheadCells = [...this.state.headCells];
    newheadCells["3"].show = true;
    this.setState({ headCells: newheadCells });

    this.setState({ rows: [], loading: true });
    searchPubBatchLabelOrderPlacement(reqBody).then((r) => {
      this.getAllPlacedOrder(r.data);
    });
    this.setState({ searchClicked: true });
  };
  render() {
    let {
      rows,
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      codeTypeList,
      headCells,
      exportDataValues,
    } = this.state;
    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handledeleteProductionLineCode={this.handledeleteProductionLineCode}
            selectedlabelBatchID={this.state.selectedlabelBatchID}
            selectedlabelBatchIDId={this.state.selectedlabelBatchIDId}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchlabelBatchCode={this.handleSearchlabelBatchCode}
            labelBatchSearchValue={this.state.labelBatchSearchValues}
            searchClicked={this.state.searchClicked}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            codeTypeList={codeTypeList}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            selectedRow={this.state.selectedRow}
            onSearchClicked={this.onSearchClicked}
            rows={this.state.rows}
            onRequestSucceed={this.onRequestSucceed}
            roles={this.props.roles}
            editable={this.state.editable}
            editableDelete={this.state.editableDelete}
            isRollBack={this.state.isRollBack}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
                style={{ whiteSpace: "nowrap" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                  selectedRow={this.state.selectedRow}
                  onSearchClicked={this.onSearchClicked}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {rows.length > 0 ? (
                    stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id); //paste the given id
                        return (
                          <TableRow
                            hover
                            onClick={(event) => this.handleClick(event, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <CustomCheckbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.client && row.client.length > 16 ? (
                                  <Tooltip title={row.client} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.client}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.client}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.location && row.location.length > 16 ? (
                                  <Tooltip
                                    title={row.location}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.location}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.location}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.batchId && row.batchId.length > 16 ? (
                                  <Tooltip title={row.batchId} aria-label="add">
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.batchId}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.batchId}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {/* {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {canEditStartDate}
                              </TableCell>
                            )} */}

                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              // style={{padding:".5em" ,borderRight:"1px solid #eaeaea",textAlign:"right"}}
                              //style={{maxWidth:"87px",padding:".5em",textAlign:"left",borderRight:"1px solid #eaeaea"}}
                              >
                                {row.processedDate &&
                                  row.processedDate.length > 16 ? (
                                    <Tooltip
                                      title={
                                        row.processedDate
                                          ? moment(row.processedDate).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                          )
                                          : ""
                                      }
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.processedDate
                                          ? moment(row.processedDate).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                          )
                                          : ""}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.processedDate
                                        ? moment(row.processedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )
                                        : ""}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {/* {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {serviceSpecific}
                              </TableCell>
                            )} */}

                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                              >
                                {row.totalOrderFiles &&
                                  row.totalOrderFiles.length > 16 ? (
                                    <Tooltip
                                      title={row.totalOrderFiles}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.totalOrderFiles}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalOrderFiles}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.totalQuantity &&
                                  row.totalQuantity.length > 16 ? (
                                    <Tooltip
                                      title={row.totalQuantity}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.totalQuantity}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.totalQuantity}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.status && (
                                  <FiberManualRecordIcon
                                    style={{
                                      fontSize: "11px",
                                      color: row.status.includes("InProgress")
                                        ? "#0091ff"
                                        : row.status.includes("Cancelled")
                                          ? "red"
                                          : row.status.includes("Failed")
                                            ? "red"
                                            : row.status.includes("Complete")
                                              ? "#16831F"
                                              : "grey",
                                    }}
                                  />
                                )}
                                <span
                                  style={{
                                    marginLeft: ".5em",
                                    color: row.status.includes("InProgress")
                                      ? "#0091ff"
                                      : row.status.includes("Cancelled")
                                        ? "red"
                                        : row.status.includes("Failed")
                                          ? "red"
                                          : row.status.includes("Complete")
                                            ? "green"
                                            : "black",
                                  }}
                                >
                                  {row.status === "Complete" ? "Completed" : row.status}
                                </span>
                              </TableCell>
                            )}

                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.modifiedBy &&
                                  row.modifiedBy.length > 16 ? (
                                    <Tooltip
                                      title={row.modifiedBy}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedBy}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                              // visibility="hidden"
                              //style={{maxWidth:"87px",padding:".5em",textAlign:"left"}}
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.createdBy && row.createdBy.length > 16 ? (
                                  <Tooltip
                                    title={row.createdBy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdBy}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                              //  visibility="hidden"
                              // style={{maxWidth:"87px",padding:".5em",textAlign:"left"}}
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={10} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(labelBatchCodeMater);
