import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Paper, Divider } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { addDispatchStyles } from "../../../shared/styles";
import { StateContext } from "../../../shared/globalState";
import moment from "moment";
import { getinsertMaster } from "../../masters/masterActions";
import { printDetails } from "../../../shared/tableCommonFunctions";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { getByIdStockIn, getStockTransferByID } from "../stockaction";

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { selectedstockId } = props;
  var publisherBackLabelManage = props.roles.filter((r) => {
    return r.superMenuName === "Publisher Back Label";
  });
  let blsiArr =
    publisherBackLabelManage.length > 0 &&
    publisherBackLabelManage.filter((u) => u.menuName === "Stock Transfer");
  var acnObj = blsiArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "11px 5px 4px 12px", margin: "7px 15px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewStockTransfer extends React.Component {
  static contextType = StateContext;
  state = {
    acronym: "",
    volume: "",
    issue: "",
    suppNumber: "",
    issueDescription: "",
    pubYear: "",
    warehouseLocationName: "",
    receivedQty: "",
    remarks: "",
    stockType: "",
    reason: "",
    receivedDate: "",
    isActive: "",
  };

  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Back Label Stock Transfer");
    this.props.setSelectedList(
      "publisherBackLabelOpen",
      "backLabelStockTransfer"
    );

    let stockId =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];

    getStockTransferByID(stockId).then((r) => {
      this.setState({
        acronym: r.data.acronym != null && r.data.acronym.trim(),
        volume: r.data.volume != null && r.data.volume.trim(),
        issue: r.data.issue != null && r.data.issue.trim(),
        supp: r.data.supp != null && r.data.supp.trim(),
        issueDescription:
          r.data.issueDescription != null && r.data.issueDescription.trim(),
        fromLocation: r.data.fromLocation,
        toLocation: r.data.toLocation,
        fromStockType: r.data.fromStockType,
        remarks: r.data.remarks,
        toLocation: r.data.toLocation,
        toStockType: r.data.toStockType,
        transferedQty: r.data.transferedQty,
        isActive: r.data.isActive,
        transferDate: r.data.transferDate
      });
    });
  }
  render() {
    let id =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            id={id}
            selectedstockId={id}
            roles={this.props.roles}
          />
          <Divider />
          <div className="view-page-container" id="printable">
            <div
              className="view-page-headings"
              style={{
                height: "2em",
              }}
            >
              Stock Transfer Information
            </div>
            {/* <Divider /> */}
            <div>
              <div>
                <p
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    fontWeight: "normal",
                    paddingTop: "1em",
                    marginBottom: "1em",
                    borderBottom: "1px solid #c1baba",

                    borderBottom: "1px solid #c1baba",
                    height: "2em",
                  }}
                >
                  From Location
                </p>
                <div className="view-page-contents-container">
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Acronym</div>
                    <div className="view-page-value">{this.state.acronym}</div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Volume</div>
                    <div className="view-page-value">{this.state.volume}</div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Issue No.</div>
                    <div className="view-page-value">{this.state.issue}</div>
                  </div>{" "}
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Supp No.</div>
                    <div className="view-page-value">{this.state.supp}</div>
                  </div>
                </div>
                <div className="view-page-contents-container">
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Issue Description</div>
                    <div className="view-page-value">
                      {this.state.issueDescription}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Location</div>
                    <div className="view-page-value">
                      {this.state.fromLocation}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Stock type</div>
                    <div className="view-page-value">
                      {this.state.fromStockType}
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    fontWeight: "normal",
                    paddingTop: "1em",
                    marginBottom: "1em",
                    borderBottom: "1px solid #c1baba",
                    height: "2em",
                  }}
                >
                  To Location
                </p>
                <div className="view-page-contents-container">
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Transfer Date</div>
                    <div className="view-page-value">
                      {moment(this.state.transferDate).format("DD/MM/YYYY HH:mm:ss")}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Transfered Qty</div>
                    <div className="view-page-value">
                      {this.state.transferedQty}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Location</div>
                    <div className="view-page-value">
                      {this.state.toLocation}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container"
                    style={{ width: "20%", marginRight: "20px" }}
                  >
                    <div className="view-page-label">Stock Type</div>
                    <div className="view-page-value">
                      {this.state.toStockType}
                    </div>
                  </div>
                </div>
              </div>
              <Divider style={{ marginTop: "25px" }} />
            </div>
          </div>
          <div className="button-wrapper" style={{ padding: "0 2em 1em 2em" }}>
            <div>
              <Button
                variant="contained"
                color="primary"
                //disableElevation
                style={{ boxShadow: "none" }}
                onClick={(e) =>
                  this.props.history.push("/backLabelStockTransfer")
                }
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addDispatchStyles)(ViewStockTransfer));
