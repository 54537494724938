import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { resourceValidation } from "../../../shared/resource";
import MenuItem from "@material-ui/core/MenuItem";
import { Autocomplete } from "@material-ui/lab";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { StateContext } from "../../../shared/globalState";
import {
  getCustomerList,
  getCountryList,
  getRoutingCustomerList,
  getLocationList,
  getDispatchTypeList,
  getDispatchModeList,
  getMailingSchemeLists,
  getDDistributorList,
  getCountryDropdownList,
  getCustomerDDList,
  getPublisherCustomerList,
} from "../../masters/masterActions";
import { postRoutingCountry } from "../routingActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import { addStyles } from "../../../shared/styles";
const Reorderqty = new Validator("Reorderqty");
const onlyNumbers = new Validator("onlyNumbers");
const generalValidator = new Validator("general");
const checkrate = new Validator("checkdecimalpoint");

class AddRoutingCountry extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      routingCountry: {
        customerID: "",
        countryID: "",
        locationID: "",
        dispatchTypeID: "",
        dispatchModeID: "",
        mailingSchemeID: "",
        distributorID: "",
        noOfOrdersFrom: "0",
        noOfOrdersTo: "0",
        noOfCopiesFrom: "0",
        noOfCopiesTo: "0",
        weightFrom: "0",
        weightTo: "0",
        ppiNumber: "",
        ppiLicenseNo: "",
        country: "",
        returnAddress: "",
        carrierSheetScheme: "",
        carrierSheetMode: "",
        customer: "",
        location: "",
        dispatchType: "",
        dispatchMode: "",
        mailingScheme: "",
        distributor: "",
      },
      errors: {},
      countryValid: false,
      customerValid: false,
      locationValid: false,
      dispatchTypeValid: false,
      dispatchModeValid: false,
      mailingschemeValid: false,
      distributorValid: false,
      noOfOrdersFromValid: true,
      noOfOrdersToValid: true,
      noOfCopiesFromValid: true,
      noOfCopiesToValid: true,
      weightFromValid: true,
      weightToValid: true,
      ppiLicenseNoValid: false,
      ppiNumberValid: false,
      returnAddressValid: false,
      carrierSheetSchemeValid: false,
      carrierSheetModeValid: false,
      countryList: [],
      customersList: [],
      LocationList: [],
      DispatchModeList: [],
      DispatchTypeList: [],
      DistributorList: [],
      mailingSchemeList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  _onCodeKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onCodeKeyPress1(event) {
    const re = /^[0-9.]{1,3}(?:\.[0-9]{1,2})?$/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    //Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }
  componentDidMount() {
    getCountryDropdownList().then((r) => {
      // let newCountryList = [];
      // r.data &&
      //   r.data.map(d => {
      //     return newCountryList.push({
      //       countryID: d.countryID,
      //       countryCode: d.countryCode,
      //       countryName: d.countryCode + "-" + d.countryName,
      //       zoneID: d.zoneID,
      //       zoneName: d.zoneName,
      //       zoneCode: d.zoneCode,
      //       dhlZoneID: d.zoneCode,
      //       dhlZoneName: d.zoneCode,
      //       dhlZoneCode: d.dhlZoneCode,
      //       alternateCountryCode: d.alternateCountryCode,
      //       alternateCountryName: d.alternateCountryName,
      //       isActive: d.isActive,
      //       createdByID: d.createdByID,
      //       createdBy: d.createdBy,
      //       createdDate: d.createdDate,
      //       modifiedByID: d.modifiedByID,
      //       modifiedBy: d.modifiedBy,
      //       modifiedDate: d.modifiedDate
      //     });
      //   });

      r.data &&
        this.setState({
          countryList: r.data
            //.filter(x => x.isActive === true)
            .sort((a, b) =>
              //a.countryName.toLowerCase() > b.countryName.toLowerCase() ? 1 : -1
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
    });
    getRoutingCustomerList().then((r) => {
      r.data &&
        this.setState({
          customersList: r.data
            // .filter(x => x.isActive === true)
            .sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
    });
    getMailingSchemeLists().then((r) => {
      r.data &&
        this.setState(
          {
            msl: r.data
              .filter((x) => x.isActive === true)
              .sort((a, b) =>
                a.mailingScheme.toLowerCase() > b.mailingScheme.toLowerCase()
                  ? 1
                  : -1
              ),
          },
          () => {
            let newMSL = [...this.state.msl];
            newMSL.push({
              mailingScheme: "None",
              mailingSchemeID: null,
            });
            this.setState({ msl: newMSL });
          }
        );
    });
    getDDistributorList().then((r) => {
      r.data &&
        this.setState(
          {
            DistributorList: r.data
              .filter((x) => x.isActive === true)
              .sort((a, b) =>
                a.distributorName.toLowerCase() >
                b.distributorName.toLowerCase()
                  ? 1
                  : -1
              ),
          },
          () => {
            let newDistributorList = [...this.state.DistributorList];
            newDistributorList.push({
              distributorName: "None",
              distributorID: null,
            });
            this.setState({ DistributorList: newDistributorList });
          }
        );
    });
    getDispatchTypeList().then((r) => {
      r.data &&
        this.setState({
          DispatchTypeList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              //a.countryName.toLowerCase() > b.countryName.toLowerCase() ? 1 : -1
              a.dispatchTypeCodeName.toLowerCase() >
              b.dispatchTypeCodeName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });
    getDispatchModeList().then((r) => {
      r.data &&
        this.setState(
          {
            DispatchModeList: r.data
              .filter((x) => x.isActive === true)
              .sort((a, b) =>
                //a.countryName.toLowerCase() > b.countryName.toLowerCase() ? 1 : -1
                a.dispatchModeCode.toLowerCase() >
                b.dispatchModeCode.toLowerCase()
                  ? 1
                  : -1
              ),
          },
          () => {
            let newDispatchModeList = [...this.state.DispatchModeList];
            newDispatchModeList.push({
              dispatchModeCode: "None",
              dispatchModeID: null,
            });
            this.setState({ DispatchModeList: newDispatchModeList });
          }
        );
    });
    getLocationList().then((r) => {
      r.data &&
        this.setState({
          LocationList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              //a.countryName.toLowerCase() > b.countryName.toLowerCase() ? 1 : -1
              a.locationName.toLowerCase() > b.locationName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });

    this.props.handleSettingHeader("Routing Country");
  }
  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleChange1 = (event) => {
    const { routingCountry } = this.state;
    let dhlValues = event.target.value;
    dhlValues = this.checkDHlWeightDec(event.target.value.toString());

    routingCountry[event.target.name] = dhlValues;
    this.validateField(event.target.name, dhlValues);
    this.setState({ routingCountry });
  };
  handleDispatchModeChange = (event, values) => {
    const { routingCountry } = this.state;
    let dispatchModeID = values && values.dispatchModeID;
    routingCountry["dispatchMode"] = values;
    routingCountry["dispatchModeID"] = dispatchModeID;
    // this.validateField("dispatchMode", dispatchModeID);
    this.setState({ routingCountry });
  };
  handleMailingSchemeChange = (event, values) => {
    const { routingCountry } = this.state;
    let mailingSchemeID = values && values.mailingSchemeID;
    routingCountry["mailingScheme"] = values;
    routingCountry["mailingSchemeID"] = mailingSchemeID;
    //this.validateField("mailingScheme", mailingSchemeID);
    this.setState({ routingCountry });
  };
  handleDistributorChange = (event, values) => {
    const { routingCountry } = this.state;
    let distributorID = values && values.distributorID;
    routingCountry["distributor"] = values;
    routingCountry["distributorID"] = distributorID;
    // this.validateField("distributor", distributorID);
    this.setState({ routingCountry });
  };
  handleDispatchChange = (event, values) => {
    const { routingCountry } = this.state;
    let dispatchTypeID = values && values.dispatchTypeID;
    routingCountry["dispatchType"] = values;
    routingCountry["dispatchTypeID"] = dispatchTypeID;
    this.validateField("dispatch", dispatchTypeID);
    this.setState({ routingCountry });
  };
  handleCustomer = (event, values) => {
    event.persist();
    // console.log(event, values);
    let name = values && values.text;
    let customerID = values && values.id;
    const { routingCountry } = this.state;
    routingCountry["customer"] = name;
    routingCountry["customerID"] = customerID;
    this.validateField("customer", customerID);
    this.setState({ routingCountry });
    //console.log(routingZone);
  };
  handleCountry = (event, values) => {
    // console.log(event, values);
    let name = values && values.text;
    let countryID = values && values.id;
    const { routingCountry } = this.state;
    routingCountry["country"] = name;
    routingCountry["countryID"] = countryID;
    this.validateField("country", countryID);
    this.setState({ routingCountry });
  };
  handleLocation = (event, values) => {
    event.persist();
    let locationName = values && values.locationName;
    let locationID = values && values.locationID;
    const { routingCountry } = this.state;
    routingCountry["location"] = locationName;
    routingCountry["locationID"] = locationID;
    this.validateField("location", locationID);
    this.setState({ routingCountry });
    // console.log(routingPriority);
  };
  handleChange = (event) => {
    const { routingCountry } = this.state;
    routingCountry[event.target.name] = event.target.value;
    this.setState({ routingCountry });

    this.validateField(event.target.name, event.target.value);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;

    let customerValid = this.state.customerValid;
    let countryValid = this.state.countryValid;
    let locationValid = this.state.locationValid;
    let dispatchTypeValid = this.state.dispatchTypeValid;
    // let distributorValid = this.state.distributorValid;
    // let dispatchModeValid = this.state.dispatchModeValid;
    // let mailingschemeValid = this.state.mailingschemeValid;

    let noOfOrdersFromValid = this.state.noOfOrdersFromValid;
    let noOfOrdersToValid = this.state.noOfOrdersToValid;
    let noOfCopiesFromValid = this.state.noOfCopiesFromValid;
    let noOfCopiesToValid = this.state.noOfCopiesToValid;

    let weightFromValid = this.state.weightFromValid;
    let weightToValid = this.state.weightToValid;
    let ppiNumberValid = this.state.ppiNumberValid;
    let ppiLicenseNoValid = this.state.ppiLicenseNoValid;

    let returnAddressValid = this.state.returnAddressValid;
    let carrierSheetSchemeValid = this.state.carrierSheetSchemeValid;
    let carrierSheetModeValid = this.state.carrierSheetModeValid;

    switch (fieldName) {
      case "returnAddress":
        fieldValidationErrors.returnAddress = "";
        if (value !== "") {
          returnAddressValid = generalValidator(value);
          fieldValidationErrors.returnAddress = returnAddressValid
            ? ""
            : " Please Enter Alphabets only";
        }
        break;
      case "carrierSheetScheme":
        fieldValidationErrors.carrierSheetScheme = "";
        if (value !== "") {
          carrierSheetSchemeValid = generalValidator(value);
          fieldValidationErrors.carrierSheetScheme = carrierSheetSchemeValid
            ? ""
            : " Please Enter AlphaNumeric only";
        }
        break;
      case "carrierSheetMode":
        fieldValidationErrors.carrierSheetMode = "";
        if (value !== "") {
          carrierSheetModeValid = generalValidator(value);
          fieldValidationErrors.carrierSheetMode = carrierSheetModeValid
            ? ""
            : " Please Enter AlphaNumeric only";
        }
        break;

      case "ppiNumber":
        ppiNumberValid = generalValidator(value);
        fieldValidationErrors.ppiNumber = ppiNumberValid
          ? ""
          : " Please Enter AlphaNumeric only";
        break;
      case "ppiLicenseNo":
        ppiLicenseNoValid = generalValidator(value);
        fieldValidationErrors.ppiLicenseNo = ppiLicenseNoValid
          ? ""
          : " Please Enter AlphaNumeric only";
        break;
      case "weightFrom":
        //fieldValidationErrors.weightFrom = "";
        if (value !== "") {
          weightFromValid = checkrate(value);
          fieldValidationErrors.weightFrom = weightFromValid
            ? ""
            : " Please Enter valid Weight From";
        } else {
          weightFromValid = false;
          fieldValidationErrors.weightFrom = "";
        }

        break;
      case "weightTo":
        //fieldValidationErrors.weightTo = "";
        if (value !== "") {
          weightToValid = checkrate(value);
          fieldValidationErrors.weightTo = weightToValid
            ? ""
            : " Please Enter valid Weight To";
        } else {
          weightToValid = false;
          fieldValidationErrors.weightTo = "";
        }

        break;
      case "noOfCopiesFrom":
        //fieldValidationErrors.noOfCopiesFrom = "";
        if (value !== "") {
          noOfCopiesFromValid = Reorderqty(value);
          fieldValidationErrors.noOfCopiesFrom = noOfCopiesFromValid
            ? ""
            : " Please Enter Numeric only";
        } else {
          noOfCopiesFromValid = false;
          fieldValidationErrors.noOfCopiesFrom = "";
        }
        break;
      case "noOfCopiesTo":
        //fieldValidationErrors.noOfCopiesTo = "";
        if (value !== "") {
          noOfCopiesToValid = Reorderqty(value);
          fieldValidationErrors.noOfCopiesTo = noOfCopiesToValid
            ? ""
            : " Please Enter Numeric only";
        } else {
          noOfCopiesToValid = false;
          fieldValidationErrors.noOfCopiesTo = "";
        }
        break;
      case "noOfOrdersTo":
        if (value !== "") {
          noOfOrdersToValid = Reorderqty(value);
          fieldValidationErrors.noOfOrdersTo = noOfOrdersToValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          noOfOrdersToValid = false;
          fieldValidationErrors.noOfOrdersTo = "";
        }

        break;
      case "noOfOrdersFrom":
        if (value !== "") {
          noOfOrdersFromValid = Reorderqty(value);
          fieldValidationErrors.noOfOrdersFrom = noOfOrdersFromValid
            ? ""
            : resourceValidation.onlyNumbers;
        } else {
          noOfOrdersFromValid = false;
          fieldValidationErrors.noOfOrdersFrom = "";
        }

        break;
      case "location":
        locationValid = onlyNumbers(value);
        fieldValidationErrors.location = locationValid
          ? ""
          : " Please select Location";
        break;
      case "country":
        countryValid = onlyNumbers(value);
        fieldValidationErrors.country = countryValid
          ? ""
          : " Please select Country";
        break;
      case "customer":
        customerValid = onlyNumbers(value);
        fieldValidationErrors.customer = customerValid
          ? ""
          : " Please select Customer";

        break;
      case "dispatch":
        dispatchTypeValid = onlyNumbers(value);
        fieldValidationErrors.dispatchType = dispatchTypeValid
          ? ""
          : " Please select Dispatch Type";
        break;
      // case "dispatchMode":
      //   dispatchModeValid = onlyNumbers(value);
      //   fieldValidationErrors.dispatchMode = dispatchModeValid
      //     ? ""
      //     : " Please select Dispatch Mode";
      //   break;

      // case "mailingscheme":
      //   mailingschemeValid = onlyNumbers(value);
      //   fieldValidationErrors.mailingscheme = mailingschemeValid
      //     ? ""
      //     : " Please select MailingScheme";
      //   break;
      // case "distributor":
      //   distributorValid = onlyNumbers(value);
      //   fieldValidationErrors.distributor = distributorValid
      //     ? ""
      //     : " Please select Distributor";
      //   break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        customerValid: customerValid,
        countryValid: countryValid,
        locationValid: locationValid,
        dispatchTypeValid: dispatchTypeValid,
        // dispatchModeValid: dispatchModeValid,
        // mailingschemeValid: mailingschemeValid,
        // distributorValid: distributorValid,
        noOfOrdersFromValid: noOfOrdersFromValid,
        noOfOrdersToValid: noOfOrdersToValid,
        noOfCopiesFromValid: noOfCopiesFromValid,
        noOfCopiesToValid: noOfCopiesToValid,
        weightFromValid: weightFromValid,
        weightToValid: weightToValid,
        ppiNumberValid: ppiNumberValid,
        ppiLicenseNoValid: ppiLicenseNoValid,
        returnAddressValid: returnAddressValid,
        carrierSheetModeValid: carrierSheetModeValid,
        carrierSheetSchemeValid: carrierSheetSchemeValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.countryValid &&
        this.state.customerValid &&
        this.state.locationValid &&
        this.state.dispatchTypeValid &&
        this.state.noOfOrdersFromValid &&
        // (this.state.noOfOrdersFromValid ||
        //   this.state.routingCountry.noOfOrdersFromValid != "") &&

        // (this.state.noOfOrdersToValid ||
        //   this.state.routingCountry.noOfOrdersToValid != "") &&

        this.state.noOfOrdersToValid &&
        this.state.noOfCopiesFromValid &&
        this.state.noOfCopiesToValid &&
        this.state.weightFromValid &&
        this.state.weightToValid &&
        this.state.ppiNumberValid &&
        this.state.ppiLicenseNoValid,
      //this.state.returnAddressValid &&
      //this.state.carrierSheetSchemeValid &&
      //this.state.carrierSheetModeValid,
    });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      country: "",
      customer: "",

      location: "",
      dispatchType: "",
      noOfOrdersFrom: "",
      noOfOrdersTo: "",
      noOfCopiesFrom: "",
      noOfCopiesTo: "",
      weightFrom: "",
      weightTo: "",
      ppiNumber: "",
      ppiLicenseNo: "",
      returnAddress: "",
      carrierSheetScheme: "",
      carrierSheetMode: "",

      errors: {},
    });
  };
  onBlur = (event) => {
    const { routingCountry } = this.state;
    let dhlValues = event.target.value;
    dhlValues = this.addZeroes(dhlValues.toString(), 2);
    routingCountry[event.target.name] = dhlValues;
    this.setState({ routingCountry });
  };
  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      const { routingCountry } = this.state;

      let customerID = routingCountry.customerID;
      let locationID = routingCountry.locationID;
      let countryID = routingCountry.countryID;
      let noOfOrdersFrom = parseInt(routingCountry.noOfOrdersFrom);
      let noOfOrdersTo = parseInt(routingCountry.noOfOrdersTo);
      let noOfCopiesFrom = parseInt(routingCountry.noOfCopiesFrom);
      let noOfCopiesTo = parseInt(routingCountry.noOfCopiesTo);
      let weightFrom = parseFloat(routingCountry.weightFrom);
      let weightTo = parseFloat(routingCountry.weightTo);
      let ppiNumber = routingCountry.ppiNumber;
      let ppiLicenseNo = routingCountry.ppiLicenseNo;
      let dispatchTypeID = routingCountry.dispatchTypeID;
      let dispatchModeID = routingCountry.dispatchModeID;
      let mailingSchemeID = routingCountry.mailingSchemeID;
      let distributorID = routingCountry.distributorID;
      let returnAddress = routingCountry.returnAddress;
      let carrierSheetScheme = routingCountry.carrierSheetScheme;
      let carrierSheetMode = routingCountry.carrierSheetMode;
      let isActive = "true";
      let createdBy = localStorage.loggedInId;

      let reqBody = {
        customerID,
        locationID,
        countryID,
        noOfOrdersFrom,
        noOfOrdersTo,
        noOfCopiesFrom,
        noOfCopiesTo,
        weightFrom,
        weightTo,
        ppiNumber,
        ppiLicenseNo,
        dispatchTypeID,
        dispatchModeID,
        mailingSchemeID,
        distributorID,
        returnAddress,
        carrierSheetScheme,
        carrierSheetMode,
        isActive,
        createdBy,
      };
      postRoutingCountry(reqBody, this.props.history);
    }
  };
  render() {
    let { classes } = this.props;
    let {
      routingCountry,
      countryList,
      customersList,
      LocationList,
      DispatchTypeList,
      DispatchModeList,
      msl,
      DistributorList,
    } = this.state;
    //console.log(customersList);
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New Routing Country</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <p className={classes.subHeader}>Enter Customer Information</p>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    required
                    includeInputInList
                    label="Customer"
                    style={{ display: "inline-flex" }}
                    name="customer"
                    options={customersList}
                    className={classes.comboBox}
                    onChange={this.handleCustomer}
                    value={routingCountry.customer}
                    getOptionLabel={(option) =>
                      option.text || routingCountry.customer
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Customer"
                        name="customer"
                        style={{ width: "100%" }}
                        margin="normal"
                      />
                    )}
                  />
                  <Autocomplete
                    autoComplete
                    required
                    includeInputInList
                    label="Location"
                    name="location"
                    style={{ display: "inline-flex" }}
                    options={LocationList}
                    className={classes.comboBox}
                    onChange={this.handleLocation}
                    value={routingCountry.location}
                    getOptionLabel={(option) =>
                      option.locationName || routingCountry.location
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Location"
                        name="location"
                        style={{ width: "100%" }}
                        margin="normal"
                      />
                    )}
                  />
                  <Autocomplete
                    autoComplete
                    required
                    includeInputInList
                    label="Country"
                    name="country"
                    style={{ display: "inline-flex" }}
                    options={countryList}
                    className={classes.comboBox}
                    onChange={this.handleCountry}
                    value={routingCountry.country}
                    getOptionLabel={(option) =>
                      option.text || routingCountry.country
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Country"
                        name="country"
                        style={{ width: "100%" }}
                        margin="normal"
                      />
                    )}
                  />
                </div>
                <p className={classes.subHeader}>Enter Criteria Setting</p>
                <div>
                  <TextField
                    required
                    label="No Of Orders From"
                    name="noOfOrdersFrom"
                    inputProps={{
                      maxLength: 5,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    //onKeyPress={this._onCodeKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={routingCountry.noOfOrdersFrom}
                    onChange={this.handleChange}
                    error={this.state.errors.noOfOrdersFrom}
                    helperText={
                      this.state.errors.noOfOrdersFrom
                        ? this.state.errors.noOfOrdersFrom
                        : ""
                    }
                  />
                  <TextField
                    required
                    label="No Of Orders To"
                    name="noOfOrdersTo"
                    inputProps={{
                      maxLength: 5,
                    }}
                    onKeyPress={this._onNumberKeyPress}
                    // onKeyPress={this._onCodeKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={routingCountry.noOfOrdersTo}
                    onChange={this.handleChange}
                    error={this.state.errors.noOfOrdersTo}
                    helperText={
                      this.state.errors.noOfOrdersTo
                        ? this.state.errors.noOfOrdersTo
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    required
                    label="No Of Copies From"
                    name="noOfCopiesFrom"
                    inputProps={{
                      maxLength: 5,
                    }}
                    // onKeyPress={this._onCodeKeyPress}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={routingCountry.noOfCopiesFrom}
                    onChange={this.handleChange}
                    error={this.state.errors.noOfCopiesFrom}
                    helperText={
                      this.state.errors.noOfCopiesFrom
                        ? this.state.errors.noOfCopiesFrom
                        : ""
                    }
                  />

                  <TextField
                    required
                    label="No Of Copies To"
                    name="noOfCopiesTo"
                    inputProps={{
                      maxLength: 5,
                    }}
                    //onKeyPress={this._onCodeKeyPress}
                    onKeyPress={this._onNumberKeyPress}
                    margin="normal"
                    className={classes.textField}
                    value={routingCountry.noOfCopiesTo}
                    onChange={this.handleChange}
                    error={this.state.errors.noOfCopiesTo}
                    helperText={
                      this.state.errors.noOfCopiesTo
                        ? this.state.errors.noOfCopiesTo
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    required
                    label="Weight From"
                    // id="txtdhlWeight"
                    name="weightFrom"
                    onKeyUp={this._onDecimalKeyPress}
                    className={classes.textField}
                    inputProps={{
                      maxLength: 10,
                      minLength: 2,
                      pattern: /^[0-9]\d*(?:\.\d{0,2})?$/,
                    }}
                    margin="normal"
                    autoComplete="off"
                    value={routingCountry.weightFrom}
                    error={this.state.errors.weightFrom}
                    onChange={this.handleChange1}
                    onBlur={this.onBlur}
                    helperText={
                      this.state.errors.weightFrom
                        ? this.state.errors.weightFrom
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Kg</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    required
                    label="Weight To"
                    id="txtdhlWeight"
                    name="weightTo"
                    onKeyUp={this._onDecimalKeyPress}
                    className={classes.textField}
                    inputProps={{
                      maxLength: 10,
                      minLength: 2,
                      pattern: /^[0-9]\d*(?:\.\d{0,2})?$/,
                    }}
                    margin="normal"
                    autoComplete="off"
                    value={routingCountry.weightTo}
                    error={this.state.errors.weightTo}
                    onChange={this.handleChange1}
                    onBlur={this.onBlur}
                    helperText={
                      this.state.errors.weightTo
                        ? this.state.errors.weightTo
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Kg</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <p className={classes.subHeader}>Enter Routing Configuration</p>
                <div>
                  <TextField
                    required
                    label="Route PPI"
                    name="ppiNumber"
                    inputProps={{
                      maxLength: 20,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingCountry.ppiNumber}
                    onChange={this.handleChange}
                    error={this.state.errors.ppiNumber}
                    helperText={
                      this.state.errors.ppiNumber
                        ? this.state.errors.ppiNumber
                        : ""
                    }
                  />
                  <TextField
                    required
                    label="PPI License No"
                    name="ppiLicenseNo"
                    inputProps={{
                      maxLength: 20,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={routingCountry.ppiLicenseNo}
                    onChange={this.handleChange}
                    error={this.state.errors.ppiLicenseNo}
                    helperText={
                      this.state.errors.ppiLicenseNo
                        ? this.state.errors.ppiLicenseNo
                        : ""
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    className={classes.textField}
                    id="dispatchType"
                    options={DispatchTypeList}
                    onChange={this.handleDispatchChange}
                    value={routingCountry.dispatchType}
                    getOptionLabel={(option) => option.dispatchTypeCodeName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Dispatch Type"
                        name="dispatchType"
                        id="dispatchType"
                        margin="normal"
                        required
                        style={{ width: "100%" }}
                        value={routingCountry.dispatchType}
                        error={this.state.errors.dispatchType}
                        helperText={
                          this.state.errors.dispatchType
                            ? this.state.errors.dispatchType
                            : ""
                        }
                      />
                    )}
                  />

                  <Autocomplete
                    autoComplete
                    includeInputInList
                    className={classes.textField}
                    id="dispatchMode"
                    options={DispatchModeList}
                    onChange={this.handleDispatchModeChange}
                    value={routingCountry.dispatchMode}
                    getOptionLabel={(option) => option.dispatchModeCode}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Dispatch Mode"
                        name="dispatchMode"
                        id="dispatchMode"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={routingCountry.dispatchMode}
                        error={this.state.errors.dispatchMode}
                        helperText={
                          this.state.errors.dispatchMode
                            ? this.state.errors.dispatchMode
                            : ""
                        }
                      />
                    )}
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    className={classes.textField}
                    id="mailingScheme"
                    options={msl}
                    onChange={this.handleMailingSchemeChange}
                    value={routingCountry.mailingScheme}
                    getOptionLabel={(option) => option.mailingScheme}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Mailing Scheme"
                        name="mailingScheme"
                        id="mailingScheme"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={routingCountry.mailingScheme}
                        error={this.state.errors.mailingScheme}
                        helperText={
                          this.state.errors.mailingScheme
                            ? this.state.errors.mailingScheme
                            : ""
                        }
                      />
                    )}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    className={classes.textField}
                    id="distributor"
                    defaultValue={[1, 2]}
                    options={DistributorList}
                    onChange={this.handleDistributorChange}
                    value={routingCountry.distributor}
                    getOptionLabel={(option) => option.distributorName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Distributor"
                        name="distributor"
                        id="distributor"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={routingCountry.distributor}
                        error={this.state.errors.distributor}
                        helperText={
                          this.state.errors.distributor
                            ? this.state.errors.distributor
                            : ""
                        }
                      />
                    )}
                  />
                  <TextField
                    label="Return Address"
                    name="returnAddress"
                    inputProps={{
                      maxLength: 200,
                    }}
                    margin="normal"
                    //required
                    className={classes.textField}
                    value={routingCountry.returnAddress}
                    onChange={this.handleChange}
                    error={this.state.errors.returnAddress}
                    helperText={
                      this.state.errors.returnAddress
                        ? this.state.errors.returnAddress
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    label="Carrier Sheet Mode"
                    name="carrierSheetMode"
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    // required
                    className={classes.textField}
                    value={routingCountry.carrierSheetMode}
                    onChange={this.handleChange}
                    error={this.state.errors.carrierSheetMode}
                    helperText={
                      this.state.errors.carrierSheetMode
                        ? this.state.errors.carrierSheetMode
                        : ""
                    }
                  />
                  <TextField
                    label="Carrier Sheet Scheme"
                    name="carrierSheetScheme"
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    //  required
                    className={classes.textField}
                    value={routingCountry.carrierSheetScheme}
                    onChange={this.handleChange}
                    error={this.state.errors.carrierSheetScheme}
                    helperText={
                      this.state.errors.carrierSheetScheme
                        ? this.state.errors.carrierSheetScheme
                        : ""
                    }
                  />
                </div>

                {/* <TextField
                  required
                  label="Office Type"
                  name="cptOfficeType"
                  inputProps={{
                    maxLength: 50
                  }}
                  margin="normal"
                  required
                  className={classes.textField}
                  value={cptCode.cptOfficeType}
                  onChange={this.handleChange}
                  error={this.state.errors.cptOfficeType}
                  helperText={
                    this.state.errors.cptOfficeType
                      ? this.state.errors.cptOfficeType
                      : ""
                  }
                />
                <TextField
                  label="Object Code"
                  name="objectCode"
                  required
                  inputProps={{
                    maxLength: 10
                  }}
                  margin="normal"
                  className={classes.textField}
                  value={cptCode.objectCode}
                  onChange={this.handleChange}
                  error={this.state.errors.objectCode}
                  helperText={
                    this.state.errors.objectCode
                      ? this.state.errors.objectCode
                      : ""
                  }
                />
                <TextField
                  label="Sub Code"
                  name="subCode"
                  inputProps={{
                    maxLength: 10
                  }}
                  margin="normal"
                  required
                  className={classes.textField}
                  value={cptCode.subCode}
                  onChange={this.handleChange}
                  error={this.state.errors.subCode}
                  helperText={
                    this.state.errors.subCode ? this.state.errors.subCode : ""
                  }
                />
                <TextField
                  multiline={true}
                  rows={1}
                  rowsMax={4}
                  inputProps={{
                    maxLength: 500
                  }}
                  label="Description"
                  name="cptDescription"
                  margin="normal"
                  className={classes.textField}
                  value={cptCode.cptDescription}
                  onChange={this.handleChange}
                  error={this.state.errors.cptDescription}
                  helperText={
                    this.state.errors.cptDescription
                      ? this.state.errors.cptDescription
                      : ""
                  }
                />
                <TextField
                  required
                  label=" SAP GL Account"
                  name="sapglAccount"
                  margin="normal"
                  inputProps={{
                    maxLength: 20
                  }}
                  className={classes.textField}
                  value={cptCode.sapglAccount}
                  onChange={this.handleChange}
                  error={this.state.errors.sapglAccount}
                  helperText={
                    this.state.errors.sapglAccount
                      ? this.state.errors.sapglAccount
                      : ""
                  }
                /> */}
                <Divider style={{ marginTop: "25px" }} />

                <div
                  className="button-wrapper"
                  style={{ paddingLeft: "0px", marginLeft: "0px" }}
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/routingCountry")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                        //className="enabled-button"
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                        //className="enabled-button"
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddRoutingCountry));
