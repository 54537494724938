import React from "react";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import { useToolbarStyles } from "../../../shared/styles";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Autocomplete } from "@material-ui/lab";
import { getCountryDropdownList } from "../../masters/masterActions";
import {
  getOnePubMainLabelOrderPlacement,
  getExcelPubMainLabelOrderPlacement,
  UpdateParticularOrder
} from "./action";
import { ExportCSV } from "../../../shared/ExportCSV";
import clone from "clone";
import moment from "moment";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { Divider } from "@material-ui/core";
import { StateContext } from "../../../shared/globalState";
import { printDetails } from "../../../shared/tableCommonFunctions";

import { stableSort, getSorting, CustomCheckbox, } from "../../../shared/tableCommonFunctions";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip,
  LinearProgress,
  Typography,
  FormControlLabel,
} from "@material-ui/core";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const exportToCSV = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
let priorityList = [
  { id: "80", text: "80", value: "80" },
  { id: "90", text: "90", value: "90" },
  { id: "95", text: "95", value: "95" },
];
const headCells = [
  {
    id: "",
    numeric: false,
    disablePadding: true,
    label: "Job ID",
  },
  {
    id: "shipToCustomer",
    numeric: false,
    disablePadding: false,
    label: "Ship To Customer",
  },
  {
    id: "issueDescription",
    numeric: false,
    disablePadding: false,
    label: "Issue Description",
  },
  {
    id: "journalTitle",
    numeric: false,
    disablePadding: false,
    label: "Journal Title",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "zone",
    numeric: false,
    disablePadding: false,
    label: "Zone",
  },
  {
    id: "country",
    numeric: false,
    disablePadding: false,
    label: "PGS Country",
  },
  {
    id: "onHold",
    numeric: false,
    disablePadding: false,
    label: "On Hold",
  },
  {
    id: "processed",
    numeric: false,
    disablePadding: false,
    label: "Processed",
  },
  {
    id: "active",
    numeric: false,
    disablePadding: false,
    label: "Active",
  },
  {
    id: "acronym",
    numeric: false,
    disablePadding: false,
    label: "Acronym",
  },
  {
    id: "volume",
    numeric: false,
    disablePadding: false,
    label: "Volume",
  },
  {
    id: "issue",
    numeric: false,
    disablePadding: false,
    label: "Issue",
  },
  {
    id: "part",
    numeric: false,
    disablePadding: false,
    label: "Part",
  },
  {
    id: "supplement",
    numeric: false,
    disablePadding: false,
    label: "Supplement",
  },
  {
    id: "productType",
    numeric: false,
    disablePadding: false,
    label: "Product Type",
  },
  {
    id: "endUserCustomer",
    numeric: false,
    disablePadding: false,
    label: "End User Customer",
  },
  {
    id: "subscriptionRef",
    numeric: false,
    disablePadding: false,
    label: "Subscription Ref",
  },
  {
    id: "freeIssueRef",
    numeric: false,
    disablePadding: false,
    label: "Free Issue Ref",
  },
  {
    id: "claimRef",
    numeric: false,
    disablePadding: false,
    label: "Claim Ref",
  },
  {
    id: "sampleCopyRef",
    numeric: false,
    disablePadding: false,
    label: "Sample Copy Ref",
  },
  {
    id: "poReference",
    numeric: false,
    disablePadding: false,
    label: "PO Reference",
  },
  {
    id: "shippingReference",
    numeric: false,
    disablePadding: false,
    label: "Shipping Reference",
  },
  {
    id: "canadianGSTNumber",
    numeric: false,
    disablePadding: false,
    label: "Canadian GST Number",
  },
  {
    id: "addressLine1",
    numeric: false,
    disablePadding: false,
    label: "Address Line 1",
  },
  {
    id: "addressLine2",
    numeric: false,
    disablePadding: false,
    label: "Address Line 2",
  },
  {
    id: "addressLine3",
    numeric: false,
    disablePadding: false,
    label: "Address Line 3",
  },
  {
    id: "addressLine4",
    numeric: false,
    disablePadding: false,
    label: "Address Line 4",
  },
  {
    id: "addressLine5",
    numeric: false,
    disablePadding: false,
    label: "Address Line 5",
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "State",
  },
  {
    id: "countryName",
    numeric: false,
    disablePadding: false,
    label: "Country Name",
  },
  {
    id: "postCode",
    numeric: false,
    disablePadding: false,
    label: "Post Code",
  },
  {
    id: "countryCode",
    numeric: false,
    disablePadding: false,
    label: "Country Code",
  },
  {
    id: "consolidationType",
    numeric: false,
    disablePadding: false,
    label: "Consolidation Type",
  },
  {
    id: "uniqueIssueIdentifier",
    numeric: false,
    disablePadding: false,
    label: "Unique Issue Identifier",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
  },
  {
    id: "sentDate",
    numeric: false,
    disablePadding: false,
    label: "Sent Date",
  },
  {
    id: "offlineSocietyName",
    numeric: false,
    disablePadding: false,
    label: "Offline Society Name",
  },
  {
    id: "telephoneNumber",
    numeric: false,
    disablePadding: false,
    label: "Telephone Number",
  },
  {
    id: "pubSet",
    numeric: false,
    disablePadding: false,
    label: "Pub Set",
  },
];
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  //   const { zoneId } = props;
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {/* <Tooltip title="Edit">
            <Link to={`/edit_zone/${zoneId}`}>
              <IconButton aria-label="edit" className={classes.iconHover}>
                <EditIcon />
              </IconButton>
            </Link>
          </Tooltip> */}
          {/* <Tooltip title="Download">
            <IconButton aria-label="download" className={classes.iconHover} onClick={e => pdfDownloadDetails()}>
              <DownloadIcon />
            </IconButton>
          </Tooltip> */}
          <Tooltip title="Print" style={{ padding: "5px", paddingTop: "12px" }}>
            <IconButton
              aria-label="print"
              className={classes.iconHover}
              onClick={(e) => printDetails()}
            >
              <PrintIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
const ViewHeader = (props) => {
  let acnArr = props.roles.filter((u) => u.menuName === "Main Label Order Placement");
  let acnObj = acnArr[0];
  const classes = useToolbarStyles();
  console.log(props)
  return (
    <div className="view-page-header">
      {acnObj && acnObj.editAction && (
        <IconButton aria-label="edit" className={classes.iconHover}>
          <EditIcon />
        </IconButton>
      )}

    </div>
  );
};
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    status
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {status !== "Processed" && status !== "Cancelled" && <TableCell
          padding="checkbox"
          style={{ borderLeft: "1px solid #eaeaea", background: "#eaeaea" }}
          className="table-cell"

        />}
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              <span style={{ color: "#306dca" }}>{headCell.label}</span>
            </TableSortLabel> */}
            <span style={{ color: "#306dca" }}>{headCell.label}</span>

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

class ViewMainLabelOrderPlacement extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      aPage: 1,
      nextDisable: true,
      loading: false,
      showEditButton: false,
      openEditModal: false,
      editModalData: {},
      selected: [],
      page: 0,
      rowsPerPage: 10000,
      rows: [],
      activeRows: 0,
      isActive: false,
      zone: {
        zoneCode: "",
        zoneName: "",
        isActive: true,
      },
      zoneList: [],
      customerName: "",
      location: "",
      subscription: "",
      fileName: "",
      tableList: [],
      countryList: [],
      countryObj: {},
      countryId: "",
      consolidationType: "",
      priority: "",
      priorityObj: {}
    };
  }
  downloadExcelData = () => {
    let orderId = "";
    let nor = ""
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    if (process.browser) nor = window.location.pathname.split("/")[3];
    getExcelPubMainLabelOrderPlacement(orderId, this.state.aPage).then((r) => {
      let excelFileData = clone(r.data);
      // console.log(excelFileData)
      excelFileData.map((d) => {
        d["Job Id"] = d["jobID"];
        d["Ship To Customer"] = d["shiptoCustomer"];

        d["Issue Description"] = d["issueDescription"];
        d["Journa lTitle"] = d["journalTitle"];
        d["Quantity"] = d["quantity"];
        d["Zone"] = d["zone"];
        d["PGS Country"] = d["pgsCountry"];
        d["On Hold"] = d["isOnHold"] ? "Yes" : "No";
        d["Processed"] = d["isProcessed"] ? "Yes" : "No";
        d["Active"] = d["isActive"] ? "Yes" : "No";
        d["Acronym"] = d["acronym"];
        d["Volume"] = d["volume"];
        d["Issue"] = d["issue"];
        d["Part"] = d["part"];
        d["Supplement"] = d["supplement"];
        d["Product Type"] = d["productType"];
        d["End User Customer"] = d["endUserCustomer"];
        d["Subscription Ref"] = d["subscriptionRef"];
        d["Free Issue Ref"] = d["freeIssueRef"];
        d["Claim Ref"] = d["claimRef"];
        d["Sample Copy Ref"] = d["sampleCopyRef"];
        d["PO Reference"] = d["poReference"];
        d["Shipping Reference"] = d["shippingReference"];
        d["Canadian GST Number"] = d["canadianGSTNumber"];
        d["Address Line 1"] = d["addressLine1"];
        d["Address Line 2"] = d["addressLine2"];
        d["Address Line 3"] = d["addressLine3"];
        d["Address Line 4"] = d["addressLine4"];
        d["Address Line 5"] = d["addressLine5"];
        d["State"] = d["state"];
        d["Country Name"] = d["countryName"];
        d["Post Code"] = d["postcode"];
        d["Country Code"] = d["countryCode"];
        d["Consolidation Type"] = d["consolidationType"];
        d["Unique Issue Identifier"] = d["uniqueIssueIdentifier"];
        d["Type"] = d["type"];
        d["Priority"] = d["priority"];
        d["Sent Date"] = d["sentDate"] ? moment(d["sentDate"]).format("DD/MM/YYYY") : "";
        d["Offline Society Name"] = d["offlineSocietyName"];
        d["Telephone Number"] = d["telephonenumber"];
        d["Pub Set"] = d["pubSet"];
        delete d["orderDetailID"];
        delete d["countryID"];
        delete d["jobID"];
        delete d["shiptoCustomer"];
        delete d["statusName"];
        delete d["issueDescription"];
        delete d["journalTitle"];
        delete d["quantity"];
        delete d["countryName"];
        delete d["postcode"];
        delete d["countryCode"];
        delete d["acronym"];
        delete d["zone"];
        delete d["pgsCountry"];
        delete d["volume"];
        delete d["issue"];
        delete d["part"];
        delete d["supplement"];
        delete d["state"];
        delete d["productType"];
        delete d["endUserCustomer"];
        delete d["isOnHold"];
        delete d["isProcessed"];
        delete d["subscriptionRef"];
        delete d["freeIssueRef"];
        delete d["claimRef"];
        delete d["sampleCopyRef"];
        delete d["poReference"];
        delete d["shippingReference"];
        delete d["canadianGSTNumber"];
        delete d["addressLine1"];
        delete d["addressLine2"];
        delete d["addressLine3"];
        delete d["addressLine4"];
        delete d["addressLine5"];
        delete d["consolidationType"];
        delete d["uniqueIssueIdentifier"];
        delete d["type"];
        delete d["priority"];
        delete d["sentDate"];
        delete d["offlineSocietyName"];
        delete d["telephonenumber"];
        delete d["pubSet"];
        delete d["isActive"]
      });
      exportToCSV(excelFileData, "orderPlacementExcel");
    });
  };
  componentDidMount() {
    this.props.handleSettingHeader("Main Label Order Placement");
    this.props.setSelectedList(
      "publisherMainLabelOpen",
      "pubMainLabelOrderPlacement"
    );
    let orderId = "";
    let nor = ""
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    if (process.browser) nor = window.location.pathname.split("/")[3];
    this.getOFDetails(1)
    if (nor) {
      if (+nor > 100)
        this.setState({ nextDisable: false })
    }
    getCountryDropdownList().then((r) => {
      r.data &&
        this.setState({
          countryList:
            r &&
            r.data &&
            r.data.sort((a, b) =>
              a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
            ),
        });
    });
  }
  onNextPageClicked = () => {
    let orderId = "";
    let nor = ""
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    if (process.browser) nor = window.location.pathname.split("/")[3];
    let rem = 0
    if (nor) {
      rem = +nor / (+this.state.aPage + 1)
      this.getOFDetails(this.state.aPage + 1)
      this.setState({ aPage: this.state.aPage + 1 })
      if (rem < 100)
        this.setState({ nextDisable: true })
    }
    else {
      this.setState({ nextDisable: true })
    }
  }
  onPrevPageClicked = () => {
    let orderId = "";
    let nor = ""
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    if (process.browser) nor = window.location.pathname.split("/")[3];
    let rem = 0
    if (this.state.aPage > 1) {
      this.getOFDetails(this.state.aPage - 1)
      this.setState({ aPage: this.state.aPage - 1 })
      this.setState({ nextDisable: false })
    }
    // if (nor) {
    //   rem = +nor / +this.state.aPage
    // }
    // if (rem > 100) {
    //   this.getOFDetails(this.state.aPage - 1)
    //   this.setState({ aPage: this.state.aPage - 1 })
    // }
    // else {
    //   this.setState({ nextDisable: true })
    // }
  }
  getOFDetails = (pNo) => {
    let orderId = "";
    let nor = ""
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    if (process.browser) nor = window.location.pathname.split("/")[3];
    this.setState({ loading: true });
    getOnePubMainLabelOrderPlacement(orderId, pNo)
      .then((r) => {
        this.setState({
          customerName: r && r.customer && r.customer,
          location: r && r.location && r.location,
          subscription: r && r.subscription && r.subscription,
          fileName: r && r.fileName && r.fileName,
          statusName: r && r.statusName
        });
        let newTableArr = [];
        r &&
          r.getMLOPDetails &&
          r.getMLOPDetails.length > 0 &&
          r.getMLOPDetails.map((l) => {
            newTableArr.push({
              jobId: l && l.jobID && l.jobID,
              shipToCustomer: l && l.shiptoCustomer && l.shiptoCustomer,
              issueDescription: l && l.issueDescription && l.issueDescription,
              journalType: l && l.journalTitle && l.journalTitle,
              quantity: l && l.quantity && l.quantity,
              zone: l && l.zone && l.zone,
              country: l && l.pgsCountry && l.pgsCountry,
              onHold: l && l.isOnHold && l.isOnHold,
              processed: l && l.isProcessed && l.isProcessed,
              active: l && l.isActive && l.isActive,
              acronym: l && l.acronym && l.acronym,
              volume: l && l.volume && l.volume,
              issue: l && l.issue && l.issue,
              part: l && l.part && l.part,
              supplement: l && l.supplement && l.supplement,
              productType: l && l.productType && l.productType,
              endUserCustomer: l && l.endUserCustomer && l.endUserCustomer,
              subscriptionRef: l && l.subscriptionRef && l.subscriptionRef,
              freeIssueRef: l && l.freeIssueRef && l.freeIssueRef,
              claimRef: l && l.claimRef && l.claimRef,
              sampleRefCopy: l && l.sampleCopyRef && l.sampleCopyRef,
              poReference: l && l.poReference && l.poReference,
              shippingRef: l && l.shippingReference && l.shippingReference,
              canadianGstNo: l && l.canadianGSTNumber && l.canadianGSTNumber,
              address1: l && l.addressLine1 && l.addressLine1,
              address2: l && l.addressLine2 && l.addressLine2,
              address3: l && l.addressLine3 && l.addressLine3,
              address4: l && l.addressLine4 && l.addressLine4,
              address5: l && l.addressLine5 && l.addressLine5,
              state: l && l.state && l.state,
              countryName: l && l.countryName && l.countryName,
              postalCode: l && l.postcode && l.postcode,
              countryCode: l && l.countryCode && l.countryCode,
              consolidationType:
                l && l.consolidationType && l.consolidationType,
              uniqueIssueIdentifier:
                l && l.uniqueIssueIdentifier && l.uniqueIssueIdentifier,
              type: l && l.type && l.type,
              priority: l && l.priority && l.priority,
              sentDate: l && l.sentDate && l.sentDate,
              offlineSocietyName:
                l && l.offlineSocietyName && l.offlineSocietyName,
              telephoneNumber: l && l.telephonenumber && l.telephonenumber,
              pubSet: l && l.pubSet && l.pubSet,
              orderId: l && l.orderDetailID,
              countryId: l && l.countryID
            });
          });
        this.setState({ tableList: newTableArr, loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  }
  handleCancel = () => {
    this.props.history.push("/pubMainLabelOrderPlacement");
  };
  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    let { zoneList } = this.state;
    if (event.target.checked) {
      const newSelecteds = zoneList
        .filter((x) => x.isActive === true)
        .map((n) => n.id);
      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, data) => {
    let id = data.id;
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedZone: data,
        SelectedZoneId: id,
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        // selectedZone: {}
        selectedZone: data,
        SelectedZoneId: id,
      });
    }
  };
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows && this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  onCheckBoxClicked = (e, data, ind) => {
    let newList = clone(this.state.tableList)
    this.state.tableList.map((x, i) => {
      newList[i].ckBkClicked = false
    })
    if (e.target.checked) {
      newList[ind].ckBkClicked = true
      this.state.countryList.map(x => {
        if (x.id === data.countryId) {
          this.setState({ countryId: x.id, countryObj: x })
        }
      })
      priorityList.map(x => {
        if (x.id == data.priority) {
          this.setState({ priority: x.id, priorityObj: x })
        }
      })
      this.setState({ showEditButton: true, editModalData: data, consolidationType: data.consolidationType, })
    }
    else {
      newList[ind].ckBkClicked = false
      this.setState({ countryId: "", countryObj: {} })
      this.setState({ priority: "", priorityObj: {} })

      this.setState({ showEditButton: false, editModalData: {}, consolidationType: "", })
    }
    this.setState({ tableList: newList })
  }
  onEditClicked = () => {
    this.setState({ openEditModal: true })
  }
  onEditModalClosed = () => {
    this.setState({ openEditModal: false })
  }
  handleAutocompleteCountry = (e, val) => {
    this.setState({
      countryId: val && val.id,
      countryObj: val && val,
    });
  }
  handleAutocompletePriority = (e, val) => {
    this.setState({
      priority: val && val.id,
      priorityObj: val && val,
    });
  }
  handleChange = (e) => {
    let val = ""
    if (e.target.value)
      val = e.target.value.toUpperCase()
    this.setState({ [e.target.name]: val })
  }
  onUpdateOrderClicked = () => {
    let reqBody = {}
    let loggedInId = localStorage.getItem("loggedInId");
    let id = this.state.editModalData.orderId
    reqBody.countryID = this.state.countryId
    reqBody.consolidationType = this.state.consolidationType
    reqBody.priority = this.state.priority
    reqBody.modifiedBy = +loggedInId
    UpdateParticularOrder(id, reqBody).then(r => {
      this.getOFDetails(1)
      this.setState({ countryId: "", countryObj: {}, consolidationType: "", priority: "", priorityObj: {}, openEditModal: false, showEditButton: false })
    })
  }
  _onAlphaKeyPress(event) {
    const re = /[dfDF]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onPriorityKeyPress(e) {
    e.preventDefault()
  }
  render() {
    let { zone } = this.state;
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      selectedZone,
      zoneList,
    } = this.state;
    const isSelected = (code) => selected.indexOf(code) !== -1;
    let acnArr = this.props.roles.filter((u) => u.menuName === "Main Label Order Placement");
    let acnObj = acnArr && acnArr.length > 0 && acnArr[0];
    // console.log()
    // let zoneId = this.props.match.params.zoneId;
    return (
      <React.Fragment>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.openEditModal}
          // onClose={handleConfirmModalOpen}
          maxWidth="md"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Update Order
            <Divider />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1em",
                background: "#f2f7fd",
                padding: ".5em",
                color: "#3982ea",
                borderRadius: "4px",
                width: "98%",
                fontSize: "13px",
                fontWeight: "100",
              }}
            >
              <div style={{ width: "120px" }}>
                <span>Job Id : </span>
                <span style={{ fontWeight: "400", marginRight: "2em" }}>
                  {this.state.editModalData.jobId}
                </span>
              </div>
              <div style={{ width: "350px" }}>
                <span>Recipient Name : </span>
                <span style={{ fontWeight: "400", marginRight: "2em" }}>
                  {this.state.editModalData.address1}
                </span>
              </div>
              <div style={{ width: "250px" }}>
                <span>Ship To Customer : </span>
                <span style={{ fontWeight: "400" }}>
                  {this.state.editModalData.shipToCustomer}
                </span>
              </div>

            </div>
            <div style={{ display: "flex" }}>
              <Autocomplete
                disableClearable
                options={this.state.countryList}
                value={this.state.countryObj}
                onChange={this.handleAutocompleteCountry}
                getOptionLabel={(option) => option.text}
                style={{ flex: 1, marginRight: "16px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    margin="normal"
                    required
                    fullWidth
                    style={{ fontWeight: "400" }}
                  />
                )}
              />
              <TextField
                required
                label="Consolidation Type"
                name="consolidationType"
                inputProps={{
                  maxLength: 1,
                }}
                // onKeyPress={this._onNumberKeyPress}
                onKeyPress={this._onAlphaKeyPress}
                margin="normal"
                style={{ flex: 1, marginRight: "16px", fontWeight: "400" }}
                value={this.state.consolidationType}
                onChange={this.handleChange}
                helperText="Please enter D or F"

              />
              {/* <TextField
                required
                label="Priority"
                name="priority"
                inputProps={{
                  maxLength: 10,
                }}
                // onKeyPress={this._onNumberKeyPress}
                margin="normal"
                style={{ flex: 1, }}
                value={this.state.priority}
                onChange={this.handleChange}
              /> */}
              <Autocomplete
                disableClearable
                options={priorityList}
                value={this.state.priorityObj}
                onChange={this.handleAutocompletePriority}
                getOptionLabel={(option) => option.text}
                style={{ flex: 1 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Priority"
                    margin="normal"
                    required
                    fullWidth
                    style={{ fontWeight: "400" }}
                    onKeyPress={this._onPriorityKeyPress}
                  />
                )}
              />
            </div>
          </DialogTitle>
          <DialogContent>
            {/* <div style={{ padding: "2em 2px" }}>
              "kllkllkll"
          </div> */}

          </DialogContent>
          <DialogActions style={{ padding: "10px 24px" }}>

            <Button
              // onClick={handleConfirmModalOpen}
              onClick={this.onEditModalClosed}
              color="primary"
              variant="contained"
              style={{ boxShadow: "none" }}
            >
              Close
              </Button>
            <Button
              onClick={this.onUpdateOrderClicked}
              color="primary"
              variant="contained"
              style={{ boxShadow: "none" }}
              disabled={!this.state.countryId || !this.state.consolidationType || !this.state.priority}
            >
              Update
              </Button>
          </DialogActions>
        </Dialog>
        <Paper>
          {/* <EnhancedTableToolbar /> */}
          {/* <div className="view-page-header">Cost Per Title Information</div> */}
          {/* <ViewHeader
            roles={this.props.roles}
          /> */}
          <div
            className="view-page-container"
            id="printable"
            style={{ paddingTop: "1em" }}
          >
            <div
              className="view-page-headings"
              style={{ borderBottom: "none" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1em",
                    background: "#f2f7fd",
                    padding: ".5em",
                    color: "#3982ea",
                    borderRadius: "4px",
                    width: "88%",
                    fontSize: "12px",
                    fontWeight: "100",
                  }}
                >
                  <div>
                    <span>Customer : </span>
                    <span style={{ fontWeight: "400" }}>
                      {this.state.customerName}
                    </span>
                  </div>
                  <div>
                    <span>Location : </span>
                    <span style={{ fontWeight: "400" }}>
                      {this.state.location}
                    </span>
                  </div>
                  <div>
                    <span>Subscription : </span>
                    <span style={{ fontWeight: "400" }}>
                      {this.state.subscription}
                    </span>
                  </div>
                  <div>
                    <span>Status : </span>
                    <span style={{ fontWeight: "400" }}>
                      {this.state.statusName}
                    </span>
                  </div>
                  <div>
                    <span>File Name : </span>
                    <span style={{ fontWeight: "400" }}>
                      {this.state.fileName}
                    </span>
                  </div>
                </div>
                {!this.state.loading && acnObj && acnObj.exportAction && <div
                  style={{ marginTop: ".6em", marginLeft: "1em", width: "12%", textAlign: "right" }}
                >
                  <Button variant="contained"
                    color="primary"
                    onClick={this.downloadExcelData}
                    style={{
                      // background: "#3982ea ",
                      // color: "#fff",
                      // borderRadius: "4px",
                      // padding: ".5em",
                      // cursor: "pointer",
                      fontSize: "9px",
                      // textTransform: "none"
                      // fontWeight: "100",
                      // textAlign: "center",
                    }}
                  >
                    Export to Excel
                  </Button>
                </div>}
              </div>
            </div>

          </div>
          {/* <Divider />
          <div className="button-wrapper">
            <div>
              <Button
                variant="outlined"
                 style={{border:"1px solid rgb(0, 105, 217)"}}
                onClick={e =>
                  this.props.history.push("/pubMainLabelOrderPlacement")
                }
              >
                CANCEL
              </Button>
            </div>
          </div> */}
          {/* .................................................................... */}
          {this.state.statusName !== "Processed" && this.state.statusName !== "Cancelled" && acnObj && acnObj.editAction &&
            <div style={{ margin: "0 2em", textAlign: "right" }}>
              <Button variant="contained" onClick={this.onEditClicked} disabled={!this.state.showEditButton}
                style={{ fontSize: "10.6px" }} color="primary">Edit Order</Button>
            </div>
          }
          <div
            style={{
              width: "95%",
              maxHeight: "calc(100vh - 250px)",
              padding: "1em 0em 0em 2em ",
            }}
          >
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 300px)",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 383px)",
                    maxHeight: "calc(100vh - 300px)",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="sticky table"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  status={this.state.statusName}
                />

                {!this.state.loading ? (
                  <TableBody style={{ overflow: "hidden" }}>
                    {this.state.tableList.length > 0 ? (
                      // stableSort(
                      //   this.state.tableList,
                      //   getSorting(order, orderBy)
                      // )
                      //   .slice(
                      //     page * rowsPerPage,
                      //     page * rowsPerPage + rowsPerPage
                      //   )

                      this.state.tableList.map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        return (
                          <TableRow
                            hover
                            onClick={(event) => this.handleClick(event, row)}
                            role="checkbox"
                            //  aria-checked={isItemSelected}
                            tabIndex={-1}
                            //key={row.id}
                            key={index}
                            // selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            {this.state.statusName !== "Processed" && this.state.statusName !== "Cancelled" && <TableCell padding="checkbox" className="table-content-cell">
                              <FormControlLabel
                                control={
                                  <CustomCheckbox
                                    checked={row.ckBkClicked ? true : false}
                                    onClick={e => this.onCheckBoxClicked(e, row, index)}
                                  />
                                }
                              />
                            </TableCell>}
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.jobId && row.jobId.length > 12 ? (
                                <Tooltip title={row.jobId} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.jobId}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.jobId}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.shipToCustomer &&
                                row.shipToCustomer.length > 12 ? (
                                  <Tooltip
                                    title={row.shipToCustomer}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.shipToCustomer}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.shipToCustomer}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.issueDescription &&
                                row.issueDescription.length > 12 ? (
                                  <Tooltip
                                    title={row.issueDescription}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.issueDescription}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.issueDescription}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.journalType &&
                                row.journalType.length > 12 ? (
                                  <Tooltip
                                    title={row.journalType}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.journalType}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.journalType}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-right-cell"
                            >
                              {/* {row.code} */}
                              {row.quantity && row.quantity.length > 12 ? (
                                <Tooltip
                                  title={row.quantity}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.quantity}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.quantity}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.zone && row.zone.length > 12 ? (
                                <Tooltip title={row.zone} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.zone}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.zone}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >

                              {row.country && row.country.length > 16 ? (
                                <Tooltip title={row.country} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.country}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.country}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.onHold && row.onHold.length > 12 ? (
                                <Tooltip
                                  title={row.onHold ? "Yes" : "No"}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.onHold ? "Yes" : "No"}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.onHold ? "Yes" : "No"}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.processed && row.processed.length > 12 ? (
                                <Tooltip
                                  title={row.processed ? "Yes" : "No"}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.processed ? "Yes" : "No"}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.processed ? "Yes" : "No"}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              id={labelId}
                              padding="default"
                              className="table-content-cell"
                            >
                              {row.active ? "Yes" : "No"}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.acronym && row.acronym.length > 12 ? (
                                <Tooltip title={row.acronym} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.acronym}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.acronym}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.volume && row.volume.length > 12 ? (
                                <Tooltip title={row.volume} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.volume}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.volume}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.issue && row.issue.length > 12 ? (
                                <Tooltip title={row.issue} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.issue}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.issue}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.part && row.part.length > 12 ? (
                                <Tooltip title={row.part} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.part}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.part}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.supplement && row.supplement.length > 12 ? (
                                <Tooltip
                                  title={row.supplement}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.supplement}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.supplement}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.productType &&
                                row.productType.length > 12 ? (
                                  <Tooltip
                                    title={row.productType}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.productType}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.productType}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.endUserCustomer &&
                                row.endUserCustomer.length > 12 ? (
                                  <Tooltip
                                    title={row.endUserCustomer}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.endUserCustomer}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.endUserCustomer}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.subscriptionRef &&
                                row.subscriptionRef.length > 12 ? (
                                  <Tooltip
                                    title={row.subscriptionRef}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.subscriptionRef}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.subscriptionRef}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.freeIssueRef &&
                                row.freeIssueRef.length > 12 ? (
                                  <Tooltip
                                    title={row.freeIssueRef}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.freeIssueRef}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.freeIssueRef}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.claimRef && row.claimRef.length > 12 ? (
                                <Tooltip
                                  title={row.claimRef}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.claimRef}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.claimRef}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.sampleRefCopy &&
                                row.sampleRefCopy.length > 12 ? (
                                  <Tooltip
                                    title={row.sampleRefCopy}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.sampleRefCopy}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.sampleRefCopy}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.poReference &&
                                row.poReference.length > 12 ? (
                                  <Tooltip
                                    title={row.poReference}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.poReference}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.poReference}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.shippingRef &&
                                row.shippingRef.length > 12 ? (
                                  <Tooltip
                                    title={row.shippingRef}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.shippingRef}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.shippingRef}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.canadianGstNo &&
                                row.canadianGstNo.length > 12 ? (
                                  <Tooltip
                                    title={row.canadianGstNo}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.canadianGstNo}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.canadianGstNo}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.address1 && row.address1.length > 12 ? (
                                <Tooltip
                                  title={row.address1}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.address1}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.address1}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.address2 && row.address2.length > 12 ? (
                                <Tooltip
                                  title={row.address2}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.address2}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.address2}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.address3 && row.address3.length > 12 ? (
                                <Tooltip
                                  title={row.address3}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.address3}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.address3}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.address4 && row.address4.length > 12 ? (
                                <Tooltip
                                  title={row.address4}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.address4}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.address4}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.address5 && row.address5.length > 12 ? (
                                <Tooltip
                                  title={row.address5}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.address5}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.address5}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.state && row.state.length > 12 ? (
                                <Tooltip title={row.state} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.state}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.state}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.countryName &&
                                row.countryName.length > 12 ? (
                                  <Tooltip
                                    title={row.countryName}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.countryName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.countryName}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.postalCode &&
                                row.postalCode.length > 12 ? (
                                  <Tooltip
                                    title={row.postalCode}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.postalCode}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.postalCode}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.countryCode &&
                                row.countryCode.length > 12 ? (
                                  <Tooltip
                                    title={row.countryCode}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.countryCode}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.countryCode}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.consolidationType &&
                                row.consolidationType.length > 12 ? (
                                  <Tooltip
                                    title={row.consolidationType}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.consolidationType}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.consolidationType}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.uniqueIssueIdentifier &&
                                row.uniqueIssueIdentifier.length > 12 ? (
                                  <Tooltip
                                    title={row.uniqueIssueIdentifier}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.uniqueIssueIdentifier}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.uniqueIssueIdentifier}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.type && row.type.length > 12 ? (
                                <Tooltip title={row.type} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.type}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.type}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.priority && row.priority.length > 12 ? (
                                <Tooltip
                                  title={row.priority}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.priority}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.priority}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.sentDate && moment(row.sentDate).format(
                                "DD/MM/YYYY"
                              ).length > 12 ? (
                                  <Tooltip
                                    title={row.sentDate ? moment(row.sentDate).format(
                                      "DD/MM/YYYY"
                                    ) : ""}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.sentDate ? moment(row.sentDate).format(
                                        "DD/MM/YYYY"
                                      ) : ""}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.sentDate ? moment(row.sentDate).format(
                                      "DD/MM/YYYY"
                                    ) : ""}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.offlineSocietyName &&
                                row.offlineSocietyName.length > 12 ? (
                                  <Tooltip
                                    title={row.offlineSocietyName}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.offlineSocietyName}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.offlineSocietyName}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.telephoneNumber &&
                                row.telephoneNumber.length > 14 ? (
                                  <Tooltip
                                    title={row.telephoneNumber}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.telephoneNumber}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.telephoneNumber}
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell
                              component="th"
                              // id={labelId}
                              scope="row"
                              padding="default"
                              className="table-content-cell"
                            >
                              {/* {row.code} */}
                              {row.pubSet && row.pubSet.length > 12 ? (
                                <Tooltip title={row.pubSet} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.pubSet}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.pubSet}
                                  </Typography>
                                )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                        <TableRow>
                          {this.state.loading ? (
                            <TableCell
                              colSpan={16}
                              style={{ textAlign: "center" }}
                            >
                              <LinearProgress />
                            </TableCell>
                          ) : (
                              <TableCell
                                colSpan={42}
                                style={{
                                  border: "1px solid #eaeaea",
                                  borderTop: "none",
                                }}
                              >
                                <span style={{ marginLeft: "9%" }}>
                                  No result found
                            </span>
                              </TableCell>
                            )}
                        </TableRow>
                      )}
                  </TableBody>
                ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={11}>
                          <LinearProgress />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
              </Table>
            </div>
            {/* <TablePagination
              rowsPerPageOptions={[100, 200, 300]}
              component="div"
              // count={rows.length}
              rowsPerPage={rowsPerPage}
              // page={page}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
            // onChangePage={this.handleChangePage}
            // onChangeRowsPerPage={this.handleChangeRowsPerPage}
            /> */}

          </div>
          <div style={{
            display: "flex", margin: "1em 2em .5em 2em",
            justifyContent: "flex-end"
          }}>
            <div><Button circular variant="contained" style={{ fontSize: "10.6px" }}
              color="primary" disabled={this.state.aPage <= 1 || this.state.loading} onClick={this.onPrevPageClicked} >Prev</Button></div>
            <div style={{ marginLeft: "1em" }}><Button variant="contained" style={{ fontSize: "10.6px" }}
              color="primary" disabled={this.state.nextDisable || this.state.loading || (this.state.tableList && this.state.tableList.length === 0)} onClick={this.onNextPageClicked}  >Next</Button></div>
            {/* <Fab size="small" color="primary" aria-label="add" disabled >
              <AddIcon />
            </Fab> */}
          </div>

          <div
            className="view-page-footer"
            style={{
              paddingLeft: "0px",
              marginRight: "0px",
              borderTop: "none",
            }}
          >
            <Divider style={{ margin: "25px 0 15px" }} />
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                onClick={(e) => this.handleCancel()}
              >
                CANCEL
              </Button>
            </div>
          </div>
          {/* ..................................................................... */}
        </Paper>
      </React.Fragment >
    );
  }
}
export default withRouter(ViewMainLabelOrderPlacement);
