import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import { Autocomplete } from "@material-ui/lab";
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";
import { withRouter } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  AddIcon,
} from "../../../shared/customIcons";
import clone from "clone";
import Validator from "../../../shared/validator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  postDocket,
  getPaymentModeTypeList,
  getArticleTypeList,
  getMailCharacteristicsList,
  getMarsResellerNumberList,
  getPreDefinedDocketData,
  getTransmissionModeDropDown,
} from "../genericDocketAction";
import {
  getMailingSchemeDropDown,
  getletterShopDropDown,
  getPPIDropDown,
  getCCIDropDown,
  getServiceDropDown,
  getInternationalMailingSchemeDropDown,
  getLocalMailingSchemeDropDown,
  notPPIMailingSchemeDropDown,
  GetLetterShopByID,
  GetLetterShopByPPPID,
  getCCIDropDownPPI
} from "../../masters/masterActions";
import { getJobGroupbyJobGroupId } from "../workOrder/action";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import TimeFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";

const validateNotRequired100 = new Validator("maxlength100AllAllow");
const validatePhoneNo = new Validator("notRequired50");
let autoCId = ""
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: "31%",
    fontSize: "11px !important",
  },
  autocompleteText: {
    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
  Checkbox: {
    marginLeft: theme.spacing(2),
  },
});

class AddDocket extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      PPIDropDown: [],
      docket: {
        letterShopArray: [{ letterShop: 0, letterShopObj: {}, noOfUnits: "" }],
        workOrderNumber: "",
        jobGroupID: "",
        workOrderID: "",
        customerName: "",
        customerID: "",
        projectTitle: "",
        service: 0,
        pickupDate: null,
        pickupTime: null,
        ppiPermitNumber: "",
        modeOfPayment: 0,
        articleType: 0,
        mailingScheme: 0,
        costCenter: 0,
        customersRefNo: "",
        mailCharacteristics: "",
        totalWeightOfConsignment: "",
        numberOfBags: "",
        netWeightPerReceptacle: "",
        contactPersonName: "",
        contactPersonNumber: "",
        contactPersonFax: "",
        ppiOwner: "",
        customerPPI: "",
        custPPIID: "",
        service: {},
        customerPPIList: [],
        mailingHouse: "",
        spBrandingMarsReseller: "",
        marsResellerNumber: 0,
        companyName: "",
        remarksTitle: "",
        remarksTitle1: "",
        remarks: "",
        letterShop: 0,
        noOfUnits: "",
        isAR: false,
        isRA: false,
        transmissionMode: "",
      },
      errors: {},
      localDocket: true,
      serviceValid: false,
      pickupDateValid: false,
      pickupTimeValid: false,
      ppiPermitNumberValid: true,
      modeOfPaymentValid: false,
      articleTypeValid: false,
      mailingSchemeValid: false,
      costCenterValid: true,
      customersRefNoValid: true,
      mailCharacteristicsValid: false,
      totalWeightOfConsignmentValid: true,
      numberOfBagsValid: true,
      netWeightPerReceptacleValid: true,
      contactPersonNameValid: false,
      contactPersonNumberValid: false,
      contactPersonFaxValid: true,
      ppiOwnerValid: true,
      mailingHouseValid: true,
      spBrandingMarsResellerValid: true,
      marsResellerNumberValid: true,
      workOrderNumberValid: true,
      transmissionModeValid: false,
      companyNameValid: true,
      remarksTitleValid: true,
      remarksValid: true,
      letterShopValid: true,
      noOfUnitsValid: true,
      serviceTypeList: [],
      paymentModeList: [],
      articleTypeList: [],
      mailingSchemeList: [],
      mailingSchemeList2: [],
      costCenterList: [],
      mailCharacteristicsList: [],
      marsResellerNumberList: [],
      transmissionModeList: [],
      customerPPIList: [],
      letterShopList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { store } = this.context[0];
    const dispatch = this.context[1];
    let jobGroupID = this.props.match.params.jobGroupId;
    let orderId = "";
    if (process.browser) orderId = window.location.pathname.split("/")[2];
    if (store.selectedDocketIds === "" || store.selectedDocketIds === "0") {
      this.props.history.goBack();

    } else {
      let localDocket =
        store.selectedDocketIds.split("-")[1].toString() === "true"
          ? true
          : false;
      this.setState({
        localDocket: localDocket,
        transmissionModeValid: localDocket,
      });
    }

    this.props.handleSettingHeader("Generic System");
    this.props.setSelectedList("genericSystemOpen", "docket");
    getServiceDropDown().then((r) => {
      dispatch({
        type: "getServiceDropDown",
        newStore: r.data,
      });
      this.getAllServiceType(r.data);
    });
    getPaymentModeTypeList().then((r) => {
      dispatch({
        type: "getPaymentModeTypeList",
        newStore: r.data,
      });
      this.getAllPaymentModeTypes(r.data);
      // console.log(r.data)
      let newDoc = clone(this.state.docket)
      r && r.data && r.data.length > 0 && r.data.map(d => {
        if (d.text === "CRD")
          newDoc.modeOfPayment = d
      })
      this.setState({ docket: newDoc, modeOfPaymentValid: true })
    });
    getArticleTypeList().then((r) => {
      dispatch({
        type: "getArticleTypeList",
        newStore: r.data,
      });
      this.getAllArticleTypes(r.data);
    });

    if (
      store &&
      store.selectedDocketIds &&
      store.selectedDocketIds.split("-")[1].toString() === "true"
    ) {
      getLocalMailingSchemeDropDown().then((r) => {
        this.getAllMailingSchemes(r.data);
      });
    }

    if (
      store &&
      store.selectedDocketIds &&
      store.selectedDocketIds.split("-")[1].toString() === "false"
    ) {
      getInternationalMailingSchemeDropDown().then((r) => {
        dispatch({
          type: "getMailingSchemeDropDown",
          newStore: r.data,
        });
        this.getAllMailingSchemes(r.data);
      });
    }
    if (
      this.state.docket &&
      this.state.docket.service &&
      this.state.docket.service.value !== "PPI"
    ) {
      notPPIMailingSchemeDropDown().then((r) => {
        this.getAllMailingSchemes2(r.data);
      });
    }
    getMailCharacteristicsList().then((r) => {
      dispatch({
        type: "getMailCharacteristicsList",
        newStore: r.data,
      });
      this.getAllMailCharacteristics(r.data);
    });
    getMarsResellerNumberList().then((r) => {
      dispatch({
        type: "getMarsResellerNumberList",
        newStore: r.data,
      });
      this.getAllMarsResellerNumbers(r.data);
    });

    getJobGroupbyJobGroupId(jobGroupID).then((r) => {
      dispatch({
        type: "getJobGroupbyJobGroupId",
        newStore: r,
      });
      this.getJobGroupsInfo(r);
      let id = clone(r.workOrderID);
      getPreDefinedDocketData(id).then((r1) => {
        dispatch({
          type: "getPreDefinedDocketData",
          newstore: r1.data,
        });
        this.getDocketData(r1.data, r.customerID);
      });
    });

    getTransmissionModeDropDown().then((r) => {
      dispatch({
        type: "getTransmissionModeDropDown",
        newstore: r.data,
      });
      this.getAllTransmissionModes(r.data);
    });
  }

  getJobGroupsInfo = async (info) => {
    const dispatch = this.context[1];
    const { docket } = this.state;
    docket.customerName = info.customerName;
    docket.customerID = info.customerID && info.customerID;
    docket.projectTitle = info.projectTitle;
    docket.workOrderNumber = info.workOrderNumber;
    docket.jobGroupID = info.jobGroupID;
    docket.workOrderID = info.workOrderID;
    docket.contactPersonNumber = info.customerPhoneNumber;
    docket.remarksTitle1 = info.wojgTitle;
    this.setState({ docket });

    // if (info)
    //   await getCCIDropDown(info.customerID).then((r) => {
    //     dispatch({
    //       type: "getCCIDropDown",
    //       newStore: r.data,
    //     });
    //     this.getAllCostCenters(r.data);
    //   });
    // if (info)
    await getPPIDropDown(info.customerID).then((r) => {
      dispatch({
        type: "getCustomerPPIDropDown",
        newstore: r.data,
      });
      this.setState({ PPIDropDown: r.data });
      this.getAllCustomerPPIs(r.data);
      let ccustomerPPIList = []
      if (r.data && r.data.length > 0) {
        r.data.map(v => {
          if (v.ppiNumber)
            ccustomerPPIList.push(v)
        })
        if (ccustomerPPIList && ccustomerPPIList.length > 0) {
          // console.log(ccustomerPPIList[0])
          GetLetterShopByPPPID(info.customerID, ccustomerPPIList[0].ppiNumber).then((r) => {
            this.getAllLetterShops(r);
          });

          getCCIDropDownPPI(info.customerID, ccustomerPPIList[0].billingAccountNo).then((r) => {
            this.getAllCostCenters(r.data);
          });
        }
      }
    });
  };

  getDocketData = async (info, cId) => {
    const { docket } = this.state;
    //docket.contactPersonNumber = info.customerPhoneNumber;
    docket.contactPersonName = info.contactPerson;
    docket.companyName = info.customerName;
    docket.customerID = cId
    docket.customersRefNo = info.customerCode;
    docket.service = info.serviceDropdown !== null && info.serviceDropdown[0];
    // docket.ppiPermitNumber =
    //   info.customerPPIDropdown !== null && info.customerPPIDropdown[0];

    this.setState({ docket });
    this.validateField("contactPersonNumber", docket.contactPersonNumber);
    this.validateField("contactPersonName", docket.contactPersonName);
    this.validateField("companyName", docket.companyName);
    this.validateField("customersRefNo", docket.customersRefNo);
    this.validateField("service", docket.service);
    // this.validateField("ppiPermitNumber", docket.ppiPermitNumber);
    // if (info.customerPPIDropdown !== null) {
    //   // getPPIDropDown(info.customerDropdown[0].id).then((r) => {
    //   //   this.getAllCustomerPPIs(r.data, info.customerPPIDropdown[0]);
    //   // });
    //   if (info)
    //     await GetLetterShopByPPPID(cId, info.customerPPIDropdown[0].id).then((r) => {
    //       this.getAllLetterShops(r);
    //     });
    //   if (info)
    //     await getCCIDropDownPPI(cId, info.customerPPIDropdown[0].id).then((r) => {
    //       this.getAllCostCenters(r.data);
    //     });
    // }
  };

  getAllServiceType = (list) => {
    let serviceTypeList = [];
    serviceTypeList = list && list;
    this.setState({
      serviceTypeList:
        serviceTypeList &&
        serviceTypeList.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };

  getAllPaymentModeTypes = (list) => {
    let paymentModeList = [];
    paymentModeList = list && list;
    this.setState({
      paymentModeList:
        paymentModeList &&
        paymentModeList.sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
        ),
    });
  };

  getAllCustomerPPIs = (list, mode) => {
    let customerPPIList = [];
    let obj = {};
    if (list.length > 0) {
      list.map(v => {
        if (v.ppiNumber)
          customerPPIList.push(v)
      })
    }

    if (
      customerPPIList.length > 0 &&
      this.state.docket && this.state.docket.service && this.state.docket.service.value === "PPI" || this.state.docket.service.value == "undefined"

    ) {
      // console.log("mode1", customerPPIList[0])
      // this.validateField("ppiPermitNumber", customerPPIList[0]);
      this.setState({ ppiPermitNumberValid: true })
      this.validateField("ppiPermitNumber", customerPPIList[0]);

      obj = {
        ...this.state.docket,
        ppiPermitNumber: customerPPIList[0],
        ppiID: customerPPIList[0].customerPPIID,
        ppivalid: true,
        // customerPPI: customerPPIList[0],
      };
    } else {
      // console.log("mode2", customerPPIList[0])
      obj = {
        ...this.state.docket,
        customerPPI: customerPPIList[0],
        custPPIID: customerPPIList[0].customerPPIID,
        ppivalid: true,
        ppiPermitNumber: customerPPIList[0],
        ppiID: customerPPIList[0].customerPPIID,

      };
    }
    this.setState({
      docket: obj,
      customerPPIList,
    });
  };
  // getAllCustomerPPIs = (list, ppiList) => {
  //   let customerPPIList = [];
  //   let obj = {};
  //   customerPPIList = list && list;
  //   let ppiObj = {}
  //   list.length > 0 && list.map(d => {
  //     if (d.customerPPIID == ppiList.id)
  //       ppiObj = d
  //   })
  //   if (
  //     customerPPIList.length > 0 &&
  //     this.state.docket.service.value === "PPI"
  //   ) {
  //     obj = {
  //       ...this.state.docket,
  //       ppiPermitNumber: ppiObj,
  //       ppiID: ppiObj.customerPPIID,
  //       ppivalid: true,
  //     };
  //   } else {
  //     obj = {
  //       ...this.state.docket,
  //       customerPPI: customerPPIList[0],
  //       custPPIID: customerPPIList[0].customerPPIID,
  //       ppivalid: false,
  //     };
  //   }
  //   this.setState({
  //     docket: obj,
  //     customerPPIList,
  //   });
  // };
  getAllArticleTypes = (list) => {
    let articleTypeList = [];
    articleTypeList = list && list;
    this.setState({
      articleTypeList:
        articleTypeList &&
        articleTypeList.sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
        ),
    });
  };

  getAllMailingSchemes = (list) => {
    let mailingSchemeList = [];
    mailingSchemeList = list && list;
    this.setState({
      mailingSchemeList:
        mailingSchemeList &&
        mailingSchemeList.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };
  getAllMailingSchemes2 = (list) => {
    let mailingSchemeList2 = [];
    mailingSchemeList2 = list && list;
    this.setState({
      mailingSchemeList2:
        mailingSchemeList2 &&
        mailingSchemeList2.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };

  getAllCostCenters = (list) => {
    let costCenterList = [];
    costCenterList = list && list;
    this.setState({
      costCenterList:
        costCenterList &&
        costCenterList.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };

  getAllMailCharacteristics = (list) => {
    let mailCharacteristicsList = [];
    mailCharacteristicsList = list && list;
    this.setState({
      mailCharacteristicsList:
        mailCharacteristicsList &&
        mailCharacteristicsList.sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
        ),
    });
  };

  getAllMarsResellerNumbers = (list) => {
    let marsResellerNumberList = [];
    marsResellerNumberList = list && list;
    this.setState({
      marsResellerNumberList:
        marsResellerNumberList &&
        marsResellerNumberList.sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
        ),
    });
  };

  getAllLetterShops = (list) => {
    let letterShopList = [];
    letterShopList = list && list;
    this.setState({
      letterShopList:
        letterShopList &&
        letterShopList.sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
        ),
    });
  };

  getAllTransmissionModes = (list) => {
    let transmissionModeList = [];
    transmissionModeList = list && list;
    this.setState({
      transmissionModeList:
        transmissionModeList &&
        transmissionModeList.sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
        ),
    });
  };
  handleTitle = (event) => {
    const { docket } = this.state;
    docket[event.target.name] = event.target.value;
    this.setState({ docket });
  };
  handleChange = (event) => {
    const { docket } = this.state;
    if (event.target.name === "rate") {
      let rate = event.target.value;
      rate = this.checkDHlWeightDec(event.target.value.toString());
      docket[event.target.name] = rate;
    } else {
      docket[event.target.name] = event.target.value;
    }
    this.setState({ docket });

    this.validateField(event.target.name, docket[event.target.name]);
  };
  handleChangeNOU = (event, i) => {
    let newDKT = clone(this.state.docket)
    newDKT.letterShopArray[i].noOfUnits = event.target.value
    this.setState({ docket: newDKT });

    // this.validateField(event.target.name, docket[event.target.name]);
  };
  handleCheckBoxChangeAR = (event, name) => {
    const { docket } = this.state;
    docket[name] = event.target.checked;
    docket["isRA"] = event.target.checked;

    this.setState({ docket });

    this.validateField(event.target.name, docket[event.target.name]);
  };
  handleCheckBoxChange = (event, name) => {
    const { docket } = this.state;
    docket[name] = event.target.checked;

    this.setState({ docket });

    this.validateField(event.target.name, docket[event.target.name]);
  };

  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onNumericKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  handleServiceTypeChange = (event, values) => {
    const { docket } = this.state;
    let dock = clone(docket);

    if (values === null) {
      dock["service"] = "null";
    } else {
      let serviceID = values;
      dock["ppivalid"] = true;
      dock["ppiPermitNumber"] = "null";
      dock["ppiID"] = "";
      dock["service"] = serviceID;
      dock["costCenter"] = {}
      dock["letterShop"] = {}
      dock["lettershopID"] = ""
      dock["noOfUnits"] = ""
      dock["letterShopArray"] = [{ letterShop: 0, letterShopObj: {}, noOfUnits: "" }]

    }
    // if (values && values.value !== "PPI") {
    //   dock["customerPPI"] = this.state.customerPPIList[0];
    //   dock["ppivalid"] = false;
    //   dock["custPPIID"] = this.state.customerPPIList[0].customerPPIID;
    //   dock["ppiPermitNumber"] = null;
    // }

    this.setState({ docket: dock }
      , () => {
        this.getAllCustomerPPIs(this.state.PPIDropDown,);
      }
    );

    this.validateField("service", dock["service"]);
  };

  handleDateChange = (date) => {
    const { docket } = this.state;
    docket["pickupDate"] = date && date;
    this.setState({ docket });
    this.validateField("pickupDate", date && date);
  };

  handleTimeChange = (time) => {
    const { docket } = this.state;
    docket["pickupTime"] = time && time;
    this.setState({ docket });
    this.validateField("pickupTime", time && time);
  };

  handlePaymentMode = (event, values) => {
    const { docket } = this.state;
    if (values === null) {
      docket["modeOfPayment"] = "null";
    } else {
      let modeOfPaymentValue = values;
      docket["modeOfPayment"] = modeOfPaymentValue;
    }
    this.setState({ docket });

    this.validateField("modeOfPayment", docket["modeOfPayment"]);
  };

  handlePPIChange = (event, values) => {
    // console.log(values, this.state.docket)
    const { docket } = this.state;
    if (values === null) {
      docket["ppiPermitNumber"] = "null";
      docket["ppiID"] = "";


    } else {
      let modeOfPaymentValue = values;
      docket["ppiPermitNumber"] = modeOfPaymentValue;
      docket["ppivalid"] = true;
      docket["ppiID"] = modeOfPaymentValue.customerPPIID;
      docket["costCenter"] = {}
      docket["letterShop"] = {}
      docket["noOfUnits"] = ""
      // console.log(values)
      docket["letterShopArray"] = [{ letterShop: 0, letterShopObj: {}, noOfUnits: "" }]

      GetLetterShopByPPPID(this.state.docket.customerID, values.ppiNumber).then((r) => {
        this.getAllLetterShops(r);
      });

      getCCIDropDownPPI(this.state.docket.customerID, values.billingAccountNo).then((r) => {
        this.getAllCostCenters(r.data);
      });
    }
    this.setState({ docket });

    this.validateField("ppiPermitNumber", docket["ppiPermitNumber"]);
  };
  handleChangeAccount = (event, values) => {
    const { docket } = this.state;
    if (values === null) {
      docket["customerPPI"] = "null";
    } else {
      let modeOfPaymentValue = values;
      docket["customerPPI"] = modeOfPaymentValue;
      // docket["ppivalid"] = false;
      // docket["ppivalid"] = true;
      // docket["customerPPI"] = modeOfPaymentValue;
      // docket["ppivalid"] = false;
      // docket["custPPIID"] = modeOfPaymentValue.customerPPIID;
      docket["costCenter"] = {}
      docket["letterShop"] = {}
      docket["noOfUnits"] = ""
      docket["custPPIID"] = modeOfPaymentValue.customerPPIID;
      docket["letterShopArray"] = [{ letterShop: 0, letterShopObj: {}, noOfUnits: "" }]

      GetLetterShopByPPPID(this.state.docket.customerID, values.ppiNumber).then((r) => {
        this.getAllLetterShops(r);
      });

      getCCIDropDownPPI(this.state.docket.customerID, values.billingAccountNo).then((r) => {
        this.getAllCostCenters(r.data);
      });

    }
    this.setState({ docket });

    //this.validateField("ppiPermitNumber", docket["ppiPermitNumber"]);
  };
  handleArticleType = (event, values) => {
    const { docket } = this.state;
    if (values === null) {
      docket["articleType"] = "null";
    } else {
      let articleTypeValue = values;
      docket["articleType"] = articleTypeValue;
    }
    this.setState({ docket });

    this.validateField("articleType", docket["articleType"]);
  };

  handleMailingScheme = (event, values) => {
    const { docket } = this.state;
    // console.log(values, "values");
    if (values === null) {
      docket["mailingScheme"] = "null";
    } else {
      let mailingSchemeValue = values;
      docket["mailingScheme"] = mailingSchemeValue;
    }
    this.setState({ docket });

    this.validateField("mailingScheme", docket["mailingScheme"]);
  };

  handleCostCenter = (event, values) => {
    const { docket } = this.state;
    if (values === null) {
      docket["costCenter"] = "null";
    } else {
      let costCenterValue = values;
      docket["costCenter"] = costCenterValue;
    }
    this.setState({ docket });

    this.validateField("costCenter", docket["costCenter"]);
  };

  handleMailCharacteristics = (event, values) => {
    const { docket } = this.state;
    if (values === null) {
      docket["mailCharacteristics"] = "";
    } else {
      let mailCharacteristicsValue = values;
      docket["mailCharacteristics"] = mailCharacteristicsValue;
    }
    this.setState({ docket });

    this.validateField("mailCharacteristics", docket["mailCharacteristics"]);
  };

  handleMarsResellerNumber = (event, values) => {
    const { docket } = this.state;
    if (values === null) {
      docket["marsResellerNumber"] = "null";
    } else {
      let marsResellerNumberValue = values;
      docket["marsResellerNumber"] = marsResellerNumberValue;
    }
    this.setState({ docket });

    this.validateField("marsResellerNumber", docket["marsResellerNumber"]);
  };

  handletransmissionMode = (event, values) => {
    // console.log(values, "values");
    const { docket } = this.state;
    if (values === null) {
      docket["transmissionMode"] = "null";
    } else {
      let transmissionModeValue = values;
      docket["transmissionMode"] = transmissionModeValue;
    }
    this.setState({ docket });

    this.validateField("transmissionMode", docket["transmissionMode"]);
  };

  handleLetterShop = (event, values) => {
    // const { docket } = this.state;
    // if (values === null) {
    //   docket["letterShop"] = {};
    //   docket["noOfUnits"] = ""
    // } else {
    //   let letterShopValue = values;
    //   docket["letterShop"] = letterShopValue;
    // }
    // this.setState({ docket });

    // this.validateField("letterShop", docket["letterShop"]);
    let newDKT = clone(this.state.docket)
    if (values) {
      newDKT.letterShopArray[autoCId].letterShop = values.id
      newDKT.letterShopArray[autoCId].letterShopObj = values
    }
    else {
      newDKT.letterShopArray[autoCId].letterShop = 0
      newDKT.letterShopArray[autoCId].letterShopObj = {}
      newDKT.letterShopArray[autoCId].noOfUnits = ""

    }
    this.setState({ docket: newDKT });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let serviceValid = this.state.serviceValid;
    let pickupDateValid = this.state.pickupDateValid;
    let pickupTimeValid = this.state.pickupTimeValid;
    let ppiPermitNumberValid = this.state.ppiPermitNumberValid;
    let modeOfPaymentValid = this.state.modeOfPaymentValid;
    let articleTypeValid = this.state.articleTypeValid;
    let mailingSchemeValid = this.state.mailingSchemeValid;
    let costCenterValid = this.state.costCenterValid;
    let customersRefNoValid = this.state.customersRefNoValid;
    let mailCharacteristicsValid = this.state.mailCharacteristicsValid;
    let totalWeightOfConsignmentValid = this.state
      .totalWeightOfConsignmentValid;
    let numberOfBagsValid = this.state.numberOfBagsValid;
    let netWeightPerReceptacleValid = this.state.netWeightPerReceptacleValid;
    let contactPersonNameValid = this.state.contactPersonNameValid;
    let contactPersonNumberValid = this.state.contactPersonNumberValid;
    let contactPersonFaxValid = this.state.contactPersonFaxValid;
    let ppiOwnerValid = this.state.ppiOwnerValid;
    let mailingHouseValid = this.state.mailingHouseValid;
    let spBrandingMarsResellerValid = this.state.spBrandingMarsResellerValid;
    let marsResellerNumberValid = this.state.marsResellerNumberValid;
    let companyNameValid = this.state.companyNameValid;
    let remarksTitleValid = this.state.remarksTitleValid;
    let transmissionModeValid = this.state.transmissionModeValid;
    let remarksValid = this.state.remarksValid;
    let letterShopValid = this.state.letterShopValid;
    let noOfUnitsValid = this.state.noOfUnitsValid;
    switch (fieldName) {
      case "remarks":
        if (value !== "") {
          remarksValid = validateNotRequired100(value);
          fieldValidationErrors.remarks = remarksValid
            ? ""
            : resourceValidation.validRemarks;
        } else {
          remarksValid = true;
          fieldValidationErrors.remarks = "";
        }
        break;
      case "service":
        if (value !== "null") {
          serviceValid = true;
          fieldValidationErrors.service = serviceValid
            ? ""
            : " Please select ServiceType";
        } else {
          serviceValid = false;
          fieldValidationErrors.service = "";
        }
        break;
      case "pickupDate":
        if (value !== "Invalid Date") {
          pickupDateValid = true;
          fieldValidationErrors.pickupDate = pickupDateValid
            ? ""
            : " Please select pickupDate";
        } else {
          pickupDateValid = false;
          fieldValidationErrors.pickupDate = "";
        }
        break;
      case "pickupTime":
        if (value !== "Invalid Date") {
          pickupTimeValid = true;
          fieldValidationErrors.pickupTime = pickupTimeValid
            ? ""
            : " Please select pickupTime";
        } else {
          pickupTimeValid = false;
          fieldValidationErrors.pickupTime = "";
        }
        break;
      case "ppiPermitNumber":
        if (value !== "") {
          ppiPermitNumberValid = true;
          fieldValidationErrors.ppiPermitNumber = ppiPermitNumberValid
            ? ""
            : " Please enter valid PPI Number";
        } else {
          ppiPermitNumberValid = false;
          fieldValidationErrors.ppiPermitNumber = "";
        }
        break;
      case "ppiOwner":
        if (value !== "") {
          ppiOwnerValid = validateNotRequired100(value);
          fieldValidationErrors.ppiOwner = ppiOwnerValid
            ? ""
            : " Please enter valid PPI Owner";
        } else {
          ppiOwnerValid = true;
          fieldValidationErrors.ppiOwner = "";
        }
        break;
      case "modeOfPayment":
        if (value !== "null") {
          modeOfPaymentValid = true;
          fieldValidationErrors.modeOfPayment = modeOfPaymentValid
            ? ""
            : " Please select Payment Mode";
        } else {
          modeOfPaymentValid = false;
          fieldValidationErrors.modeOfPayment = "";
        }
        break;
      case "mailingScheme":
        if (value !== "null") {
          mailingSchemeValid = true;
          fieldValidationErrors.mailingScheme = mailingSchemeValid
            ? ""
            : " Please select Mailing Scheme";
        } else {
          mailingSchemeValid = false;
          fieldValidationErrors.mailingScheme = "";
        }
        break;
      case "mailCharacteristics":
        if (value !== "null") {
          mailCharacteristicsValid = true;
          fieldValidationErrors.mailCharacteristics = mailCharacteristicsValid
            ? ""
            : " Please select Mail Characteristics";
        } else {
          mailCharacteristicsValid = false;
          fieldValidationErrors.mailCharacteristics = "";
        }
        break;
      case "mailingHouse":
        if (value !== "") {
          mailingHouseValid = validateNotRequired100(value);
          fieldValidationErrors.mailingHouse = mailingHouseValid
            ? ""
            : " Please enter valid Mailing House";
        } else {
          mailingHouseValid = true;
          fieldValidationErrors.mailingHouse = "";
        }
        break;
      case "articleType":
        if (value !== "null") {
          articleTypeValid = true;
          fieldValidationErrors.articleType = articleTypeValid
            ? ""
            : " Please select Article Type";
        } else {
          articleTypeValid = false;
          fieldValidationErrors.articleType = "";
        }
        break;
      case "costCenter":
        if (value !== "null") {
          costCenterValid = true;
          fieldValidationErrors.costCenter = costCenterValid
            ? ""
            : " Please select Cost Center";
        } else {
          costCenterValid = true;
          fieldValidationErrors.costCenter = "";
        }
        break;
      case "customersRefNo":
        if (value !== "") {
          customersRefNoValid = validateNotRequired100(value);
          fieldValidationErrors.customersRefNo = customersRefNoValid
            ? ""
            : " Please enter valid Customer Reference Number";
        } else {
          customersRefNoValid = true;
          fieldValidationErrors.customersRefNo = "";
        }
        break;
      case "totalWeightOfConsignment":
        if (value !== "") {
          totalWeightOfConsignmentValid = !isNaN(value);
          fieldValidationErrors.totalWeightOfConsignment = totalWeightOfConsignmentValid
            ? ""
            : " Please enter valid Total Weight of Consignment";
        } else {
          totalWeightOfConsignmentValid = true;
          fieldValidationErrors.totalWeightOfConsignment = "";
        }
        break;
      case "numberOfBags":
        if (value !== "") {
          numberOfBagsValid = validateNotRequired100(value);
          fieldValidationErrors.numberOfBags = numberOfBagsValid
            ? ""
            : " Please enter valid Number of Bags";
        } else {
          numberOfBagsValid = true;
          fieldValidationErrors.numberOfBags = "";
        }
        break;
      case "netWeightPerReceptacle":
        if (value !== "") {
          netWeightPerReceptacleValid = !isNaN(value);
          fieldValidationErrors.netWeightPerReceptacle = netWeightPerReceptacleValid
            ? ""
            : " Please enter valid Net Weight per Receptacles";
        } else {
          netWeightPerReceptacleValid = true;
          fieldValidationErrors.netWeightPerReceptacle = "";
        }
        break;
      case "contactPersonName":
        if (value !== "") {
          contactPersonNameValid = validateNotRequired100(value);
          fieldValidationErrors.contactPersonName = contactPersonNameValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          contactPersonNameValid = false;
          fieldValidationErrors.contactPersonName = "";
        }
        break;
      case "contactPersonNumber":
        if (value !== "") {
          contactPersonNumberValid = validatePhoneNo(value);
          fieldValidationErrors.contactPersonNumber = contactPersonNumberValid
            ? ""
            : resourceValidation.validContact;
        } else {
          contactPersonNumberValid = false;
          fieldValidationErrors.contactPersonNumber = "";
        }
        break;
      case "contactPersonFax":
        if (value !== "") {
          contactPersonFaxValid = validatePhoneNo(value);
          fieldValidationErrors.contactPersonFax = contactPersonFaxValid
            ? ""
            : " Please enter valid Fax Number";
        } else {
          contactPersonFaxValid = true;
          fieldValidationErrors.contactPersonFax = "";
        }
        break;
      case "companyName":
        if (value !== "") {
          companyNameValid = validateNotRequired100(value);
          fieldValidationErrors.companyName = companyNameValid
            ? ""
            : " Please enter valid Company Name";
        } else {
          companyNameValid = true;
          fieldValidationErrors.companyName = "";
        }
        break;
      case "remarksTitle":
        if (value !== "") {
          remarksTitleValid = validateNotRequired100(value);
          fieldValidationErrors.remarksTitle = remarksTitleValid
            ? ""
            : " Please enter valid Title on Remarks";
        } else {
          remarksTitleValid = true;
          fieldValidationErrors.remarksTitle = "";
        }
        break;
      case "spBrandingMarsReseller":
        if (value !== "") {
          spBrandingMarsResellerValid = validateNotRequired100(value);
          fieldValidationErrors.spBrandingMarsReseller = spBrandingMarsResellerValid
            ? ""
            : " Please enter valid SP Branding MARS Reseller";
        } else {
          spBrandingMarsResellerValid = true;
          fieldValidationErrors.spBrandingMarsReseller = "";
        }
        break;
      case "marsResellerNumber":
        if (value !== "null") {
          marsResellerNumberValid = true;
          fieldValidationErrors.marsResellerNumber = marsResellerNumberValid
            ? ""
            : " Please select MARS Reseller Number";
        } else {
          marsResellerNumberValid = true;
          fieldValidationErrors.marsResellerNumber = "";
        }
        break;
      case "transmissionMode":
        if (value !== "null") {
          transmissionModeValid = true;
          fieldValidationErrors.transmissionMode = transmissionModeValid
            ? ""
            : " Please select Transmission Mode";
        } else {
          transmissionModeValid = false;
          fieldValidationErrors.transmissionMode = "";
        }
        break;
      case "letterShop":
        if ((value && value.id) || value !== "null") {
          letterShopValid = true;
          fieldValidationErrors.letterShop = letterShopValid
            ? ""
            : " Please select Letter Shop";
        } else {
          letterShopValid = true;
          fieldValidationErrors.letterShop = "";
        }
        break;
      case "noOfUnits":
        if (value !== "") {
          noOfUnitsValid = validateNotRequired100(value);
          fieldValidationErrors.noOfUnits = noOfUnitsValid
            ? ""
            : " Please enter valid Number of Units";
        } else {
          noOfUnitsValid = true;
          fieldValidationErrors.noOfUnits = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        serviceValid: serviceValid,
        pickupDateValid: pickupDateValid,
        pickupTimeValid: pickupTimeValid,
        // ppiPermitNumberValid: ppiPermitNumberValid,
        transmissionModeValid: transmissionModeValid,
        modeOfPaymentValid: modeOfPaymentValid,
        articleTypeValid: articleTypeValid,
        mailingSchemeValid: mailingSchemeValid,
        costCenterValid: costCenterValid,
        customersRefNoValid: customersRefNoValid,
        mailCharacteristicsValid: mailCharacteristicsValid,
        totalWeightOfConsignmentValid: totalWeightOfConsignmentValid,
        numberOfBagsValid: numberOfBagsValid,
        netWeightPerReceptacleValid: netWeightPerReceptacleValid,
        contactPersonNameValid: contactPersonNameValid,
        contactPersonNumberValid: contactPersonNumberValid,
        contactPersonFaxValid: contactPersonFaxValid,
        ppiOwnerValid: ppiOwnerValid,
        mailingHouseValid: mailingHouseValid,
        spBrandingMarsResellerValid: spBrandingMarsResellerValid,
        marsResellerNumberValid: marsResellerNumberValid,
        companyNameValid: companyNameValid,
        remarksTitleValid: remarksTitleValid,
        remarksValid: remarksValid,
        letterShopValid: letterShopValid,
        noOfUnitsValid: noOfUnitsValid,
      },
      this.validateForm
    );
  }

  onBlur = (event) => {
    const { docket } = this.state;
    let rate = event.target.value;
    rate = this.addZeroes(rate.toString(), 3);
    docket[event.target.name] = rate;
    this.setState({ docket });
  };

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  validateForm() {
    // console.log(this.state.docket["mailingScheme"].value)
    if (
      (this.state.docket &&
        this.state.docket["mailingScheme"] &&
        (this.state.docket["mailingScheme"].value == "CONTRACT") || this.state.docket["mailingScheme"].value == "PUBLIC") ||
      !this.state.localDocket
    ) {
      if (this.state.docket.letterShop && this.state.docket.letterShop.id)
        this.setState({
          formValid:
            this.state.serviceValid &&
            this.state.pickupDateValid &&
            this.state.pickupTimeValid &&
            // this.state.ppiPermitNumberValid &&
            this.state.modeOfPaymentValid &&
            this.state.articleTypeValid &&
            this.state.mailingSchemeValid &&
            this.state.docket.noOfUnits &&
            // this.state.costCenterValid &&
            // this.state.customersRefNoValid &&
            //this.state.totalWeightOfConsignmentValid &&
            // this.state.numberOfBagsValid &&
            // this.state.netWeightPerReceptacleValid &&
            this.state.contactPersonNameValid &&
            this.state.contactPersonNumberValid &&
            // this.state.contactPersonFaxValid &&
            // this.state.ppiOwnerValid &&
            //this.state.mailingHouseValid &&
            // this.state.spBrandingMarsResellerValid &&
            //this.state.marsResellerNumberValid &&
            //this.state.workOrderNumberValid &&
            // this.state.companyNameValid &&
            // this.state.remarksTitleValid &&
            // this.state.remarksValid &&
            // this.state.letterShopValid &&
            // this.state.noOfUnitsValid &&
            this.state.transmissionModeValid,
        });
      else
        this.setState({
          formValid:
            this.state.serviceValid &&
            this.state.pickupDateValid &&
            this.state.pickupTimeValid &&
            // this.state.ppiPermitNumberValid &&
            this.state.modeOfPaymentValid &&
            this.state.articleTypeValid &&
            this.state.mailingSchemeValid &&
            // this.state.costCenterValid &&
            // this.state.customersRefNoValid &&
            //this.state.totalWeightOfConsignmentValid &&
            // this.state.numberOfBagsValid &&
            // this.state.netWeightPerReceptacleValid &&
            this.state.contactPersonNameValid &&
            this.state.contactPersonNumberValid &&
            // this.state.contactPersonFaxValid &&
            // this.state.ppiOwnerValid &&
            //this.state.mailingHouseValid &&
            // this.state.spBrandingMarsResellerValid &&
            //this.state.marsResellerNumberValid &&
            //this.state.workOrderNumberValid &&
            // this.state.companyNameValid &&
            // this.state.remarksTitleValid &&
            // this.state.remarksValid &&
            // this.state.letterShopValid &&
            // this.state.noOfUnitsValid &&
            this.state.transmissionModeValid,
        });
      return this.state.formValid;
    } else if (
      this.state.docket &&
      this.state.docket["mailingScheme"] &&
      this.state.docket["mailingScheme"].value == "NON BULK MAIL"
    ) {
      if (this.state.docket.letterShop && this.state.docket.letterShop.id)
        this.setState({
          formValid:
            this.state.serviceValid &&
            this.state.pickupDateValid &&
            this.state.pickupTimeValid &&
            // this.state.ppiPermitNumberValid &&
            this.state.modeOfPaymentValid &&
            this.state.articleTypeValid &&
            this.state.mailingSchemeValid &&
            this.state.docket.noOfUnits &&
            // this.state.costCenterValid &&
            // this.state.customersRefNoValid &&
            //this.state.totalWeightOfConsignmentValid &&
            // this.state.numberOfBagsValid &&
            // this.state.netWeightPerReceptacleValid &&
            this.state.contactPersonNameValid &&
            this.state.contactPersonNumberValid,
        });
      else {
        this.setState({
          formValid:
            this.state.serviceValid &&
            this.state.pickupDateValid &&
            this.state.pickupTimeValid &&
            // this.state.ppiPermitNumberValid &&
            this.state.modeOfPaymentValid &&
            this.state.articleTypeValid &&
            this.state.mailingSchemeValid &&
            // this.state.costCenterValid &&
            // this.state.customersRefNoValid &&
            //this.state.totalWeightOfConsignmentValid &&
            // this.state.numberOfBagsValid &&
            // this.state.netWeightPerReceptacleValid &&
            this.state.contactPersonNameValid &&
            this.state.contactPersonNumberValid,
        });
      }
      return this.state.formValid;
    } else {
      if (this.state.docket.letterShop && this.state.docket.letterShop.id)
        this.setState({
          formValid:
            this.state.serviceValid &&
              this.state.pickupDateValid &&
              this.state.pickupTimeValid &&
              // this.state.ppiPermitNumberValid &&
              this.state.modeOfPaymentValid &&
              this.state.articleTypeValid &&
              this.state.mailingSchemeValid &&
              this.state.docket.noOfUnits &&
              // this.state.costCenterValid &&
              // this.state.customersRefNoValid &&
              //this.state.totalWeightOfConsignmentValid &&
              // this.state.numberOfBagsValid &&
              // this.state.netWeightPerReceptacleValid &&
              this.state.contactPersonNameValid &&
              this.state.contactPersonNumberValid &&
              this.state.docket.mailCharacteristics ? true : false
        });
      else {
        this.setState({
          formValid:
            this.state.serviceValid &&
              this.state.pickupDateValid &&
              this.state.pickupTimeValid &&
              // this.state.ppiPermitNumberValid &&
              this.state.modeOfPaymentValid &&
              this.state.articleTypeValid &&
              this.state.mailingSchemeValid &&
              // this.state.costCenterValid &&
              // this.state.customersRefNoValid &&
              //this.state.totalWeightOfConsignmentValid &&
              // this.state.numberOfBagsValid &&
              // this.state.netWeightPerReceptacleValid &&
              this.state.contactPersonNameValid &&
              this.state.contactPersonNumberValid &&
              this.state.docket.mailCharacteristics ? true : false
        });
      }
      return this.state.formValid;
    }
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      customerName: "",
      projectTitle: "",
      service: 0,
      pickupDate: null,
      pickupTime: null,
      ppiPermitNumber: "",
      modeOfPayment: 0,
      articleType: 0,
      mailingScheme: 0,
      costCenter: 0,
      customersRefNo: "",
      mailCharacteristics: "",
      totalWeightOfConsignment: "",
      numberOfBags: "",
      netWeightPerReceptacle: "",
      contactPersonName: "",
      contactPersonNumber: "",
      contactPersonFax: "",
      ppiOwner: "",
      mailingHouse: "",
      spBrandingMarsReseller: "",
      marsResellerNumber: 0,
      workOrderNumber: "",
      companyName: "",
      remarksTitle: "",
      remarksTitle1: "",
      remarks: "",
      isAR: false,
      isRA: false,
      letterShop: 0,
      noOfUnits: "",
      errors: {},
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { store } = this.context[0];
    let PPI = "";
    if (this.state.docket.ppivalid == false) {
      PPI = this.state.docket.custPPIID;
    } else {
      PPI = this.state.docket.ppiID;
    }

    if (
      this.state.docket &&
      this.state.docket["mailingScheme"] &&
      this.state.docket["mailingScheme"].value == "CONTRACT"
    ) {
      const { docket, localDocket } = this.state;

      let serviceTypeID = docket.service && docket.service.id;
      let modeOfPaymentID = docket.modeOfPayment && docket.modeOfPayment.id;
      let typeofArticleID = docket.articleType && docket.articleType.id;
      let mailSchemeID = docket.mailingScheme && docket.mailingScheme.id;
      let costCenterID =
        docket.costCenter && docket.costCenter.id > 0
          ? docket.costCenter.id
          : null;
      let customersRefNo = docket.customersRefNo;
      let mailCharacteristicsID =
        docket.mailCharacteristics && docket.mailCharacteristics.id;
      let totalWeightConsignment = docket.totalWeightOfConsignment;
      let noOfBagsReceptacle = docket.numberOfBags;
      let netWtReceptacle = docket.netWeightPerReceptacle;
      let nameContactPerson = docket.contactPersonName;
      let telephoneContactPerson = docket.contactPersonNumber;
      let faxContactPerson = docket.contactPersonFax;
      let ownerofPPI = docket.ppiOwner;
      let mailingHouse = docket.mailingHouse;
      let spBrandingMARSReseller = docket.spBrandingMarsReseller;
      let marsEesellerNoID =
        docket.marsResellerNumber && docket.marsResellerNumber.id > 0
          ? docket.marsResellerNumber.id
          : null;
      let companyname = docket.companyName;
      let remarks = docket.remarks;
      // let lettershopID =
      //   docket.letterShop && +docket.letterShop.id > 0
      //     ? docket.letterShop.id
      //     : null;
      let lettershopID = null
      // let noOfUnits = docket.noOfUnits;
      let noOfUnits = null

      let createdBy = localStorage.loggedInId;
      let runmailIds = store.selectedDocketIds.split("-")[0];
      let isAR = docket.isAR;
      let isRA = docket.isRA;
      let isEDSAccepted = false;
      let edsExceptionMessage = "";
      let titleOfArticleID = null;
      let transmissionModeID =
        docket.transmissionMode && docket.transmissionMode.id;
      let jobOrderGroupID = docket.jobGroupID;
      let workOrderNoID = docket.workOrderID;
      let isManual = store.selectedDocketIds.split("-")[2];
      let isLocal = localDocket;
      let customerPPIID = PPI;
      let title = docket.remarksTitle;
      let titleOfArticle = docket.remarksTitle1;
      let estimatedLodgementDate =
        docket.pickupDate && docket.pickupDate.toLocaleDateString();
      let estimatedLodgementTime =
        docket.pickupTime &&
        docket.pickupTime.getHours() +
        ":" +
        docket.pickupTime.getMinutes() +
        ":" +
        docket.pickupTime.getSeconds();
      //console.log(title2);
      let docketLetterShops = []
      this.state.docket.letterShopArray.length > 0 && this.state.docket.letterShopArray.map(dl => {
        dl.letterShop &&
          docketLetterShops.push({
            docketLettershopID: 0,
            docketHeaderID: 0,
            lettershopID: dl.letterShop,
            noOfUnits: dl.noOfUnits,
            isActive: true
          })
      })
      let reqBody = {
        jobOrderGroupID,
        workOrderNoID,
        isManual,
        isLocal,
        serviceTypeID,
        isAR,
        isRA,
        costCenterID,
        customersRefNo,
        marsEesellerNoID,
        mailCharacteristicsID,
        transmissionModeID,
        mailSchemeID,
        mailingHouse,
        modeOfPaymentID,
        nameContactPerson,
        netWtReceptacle,
        noOfBagsReceptacle,
        ownerofPPI,
        customerPPIID,
        title,
        titleOfArticle,
        remarks,
        spBrandingMARSReseller,
        telephoneContactPerson,
        titleOfArticleID,
        totalWeightConsignment,
        typeofArticleID,
        isEDSAccepted,
        edsExceptionMessage,
        lettershopID,
        createdBy,
        estimatedLodgementDate,
        estimatedLodgementTime,
        noOfUnits,
        faxContactPerson,
        companyname,
        runmailIds,
        docketLetterShops
      };

      //console.log(reqBody);
      postDocket(reqBody).then((r) => {
        // console.log(r);
        if (r) {
          this.props.history.push({
            pathname: `/runMailList/${docket.jobGroupID}`,
            state: { workOrderMode: this.props.location.state.workOrderMode },
          });
        }
      });
    } else if (
      this.state.localDocket &&
      this.state.docket &&
      this.state.docket["mailingScheme"] &&
      this.state.docket["mailingScheme"].value == "NON BULK MAIL"
    ) {
      const { docket, localDocket } = this.state;
      // console.log(docket);
      let serviceTypeID = docket.service && docket.service.id;
      let modeOfPaymentID = docket.modeOfPayment && docket.modeOfPayment.id;
      let typeofArticleID = docket.articleType && docket.articleType.id;
      let mailSchemeID = docket.mailingScheme && docket.mailingScheme.id;
      let costCenterID =
        docket.costCenter && docket.costCenter.id > 0
          ? docket.costCenter.id
          : null;
      let customersRefNo = docket.customersRefNo;
      // let mailCharacteristicsID =
      //   docket.mailCharacteristics && docket.mailCharacteristics.id;
      let totalWeightConsignment = docket.totalWeightOfConsignment;
      let noOfBagsReceptacle = docket.numberOfBags;
      let netWtReceptacle = docket.netWeightPerReceptacle;
      let nameContactPerson = docket.contactPersonName;
      let telephoneContactPerson = docket.contactPersonNumber;
      let faxContactPerson = docket.contactPersonFax;
      let ownerofPPI = docket.ppiOwner;
      let mailingHouse = docket.mailingHouse;
      let spBrandingMARSReseller = docket.spBrandingMarsReseller;
      let marsEesellerNoID =
        docket.marsResellerNumber && docket.marsResellerNumber.id > 0
          ? docket.marsResellerNumber.id
          : null;
      let companyname = docket.companyName;
      let remarks = docket.remarks;
      // let lettershopID =
      //   docket.letterShop && docket.letterShop.id > 0
      //     ? docket.letterShop.id
      //     : null;
      // let noOfUnits = docket.noOfUnits;
      let lettershopID = null
      // let noOfUnits = docket.noOfUnits;
      let noOfUnits = null
      let createdBy = localStorage.loggedInId;
      let runmailIds = store.selectedDocketIds.split("-")[0];
      let isAR = docket.isAR;
      let isRA = docket.isRA;
      let isEDSAccepted = false;
      let edsExceptionMessage = "";
      let titleOfArticleID = null;
      let transmissionModeID =
        docket.transmissionMode && docket.transmissionMode.id;
      let jobOrderGroupID = docket.jobGroupID;
      let workOrderNoID = docket.workOrderID;
      let isManual = store.selectedDocketIds.split("-")[2];
      let isLocal = localDocket;
      let customerPPIID = PPI;
      let title = docket.remarksTitle;
      let titleOfArticle = docket.remarksTitle1;
      let estimatedLodgementDate =
        docket.pickupDate && docket.pickupDate.toLocaleDateString();
      let estimatedLodgementTime =
        docket.pickupTime &&
        docket.pickupTime.getHours() +
        ":" +
        docket.pickupTime.getMinutes() +
        ":" +
        docket.pickupTime.getSeconds();
      let docketLetterShops = []
      this.state.docket.letterShopArray.length > 0 && this.state.docket.letterShopArray.map(dl => {
        dl.letterShop &&
          docketLetterShops.push({
            docketLettershopID: 0,
            docketHeaderID: 0,
            lettershopID: dl.letterShop,
            noOfUnits: dl.noOfUnits,
            isActive: true
          })
      })
      let reqBody = {
        jobOrderGroupID,
        workOrderNoID,
        isManual,
        isLocal,
        serviceTypeID,
        isAR,
        isRA,
        costCenterID,
        customersRefNo,
        marsEesellerNoID,
        // mailCharacteristicsID,
        transmissionModeID,
        mailSchemeID,
        mailingHouse,
        modeOfPaymentID,
        nameContactPerson,
        netWtReceptacle,
        noOfBagsReceptacle,
        ownerofPPI,
        customerPPIID,
        title,
        titleOfArticle,
        remarks,
        spBrandingMARSReseller,
        telephoneContactPerson,
        titleOfArticleID,
        totalWeightConsignment,
        typeofArticleID,
        isEDSAccepted,
        edsExceptionMessage,
        lettershopID,
        createdBy,
        estimatedLodgementDate,
        estimatedLodgementTime,
        noOfUnits,
        faxContactPerson,
        companyname,
        runmailIds,
        docketLetterShops
      };

      // console.log(reqBody);
      postDocket(reqBody).then((r) => {
        // console.log(r);
        if (r) {
          this.props.history.push({
            pathname: `/runMailList/${docket.jobGroupID}`,
            state: { workOrderMode: this.props.location.state.workOrderMode },
          });
        }
      });
    } else {
      if (this.validateForm()) {
        const { docket, localDocket } = this.state;
        // console.log(docket);
        let serviceTypeID = docket.service && docket.service.id;
        let modeOfPaymentID = docket.modeOfPayment && docket.modeOfPayment.id;
        let typeofArticleID = docket.articleType && docket.articleType.id;
        let mailSchemeID = docket.mailingScheme && docket.mailingScheme.id;
        let costCenterID =
          docket.costCenter && docket.costCenter.id > 0
            ? docket.costCenter.id
            : null;
        let customersRefNo = docket.customersRefNo;
        let mailCharacteristicsID =
          docket.mailCharacteristics && docket.mailCharacteristics.id;
        let totalWeightConsignment = docket.totalWeightOfConsignment;
        let noOfBagsReceptacle = docket.numberOfBags;
        let netWtReceptacle = docket.netWeightPerReceptacle;
        let nameContactPerson = docket.contactPersonName;
        let telephoneContactPerson = docket.contactPersonNumber;
        let faxContactPerson = docket.contactPersonFax;
        let ownerofPPI = docket.ppiOwner;
        let mailingHouse = docket.mailingHouse;
        let spBrandingMARSReseller = docket.spBrandingMarsReseller;
        let marsEesellerNoID =
          docket.marsResellerNumber && docket.marsResellerNumber.id > 0
            ? docket.marsResellerNumber.id
            : null;
        let companyname = docket.companyName;
        let remarks = docket.remarks;
        // let lettershopID =
        //   docket.letterShop && docket.letterShop.id > 0
        //     ? docket.letterShop.id
        //     : null;
        // let noOfUnits = docket.noOfUnits;
        let lettershopID = null
        // let noOfUnits = docket.noOfUnits;
        let noOfUnits = null
        let createdBy = localStorage.loggedInId;
        let runmailIds = store.selectedDocketIds.split("-")[0];
        let isAR = docket.isAR;
        let isRA = docket.isRA;
        let isEDSAccepted = false;
        let edsExceptionMessage = "";
        let titleOfArticleID = null;
        let transmissionModeID =
          docket.transmissionMode && docket.transmissionMode.id;
        let jobOrderGroupID = docket.jobGroupID;
        let workOrderNoID = docket.workOrderID;
        let isManual = store.selectedDocketIds.split("-")[2];
        let isLocal = localDocket;
        let customerPPIID = PPI;
        let title = docket.remarksTitle;
        let titleOfArticle = docket.remarksTitle1;
        let estimatedLodgementDate =
          docket.pickupDate && docket.pickupDate.toLocaleDateString();
        let estimatedLodgementTime =
          docket.pickupTime &&
          docket.pickupTime.getHours() +
          ":" +
          docket.pickupTime.getMinutes() +
          ":" +
          docket.pickupTime.getSeconds();
        let docketLetterShops = []
        this.state.docket.letterShopArray.length > 0 && this.state.docket.letterShopArray.map(dl => {
          dl.letterShop &&
            docketLetterShops.push({
              docketLettershopID: 0,
              docketHeaderID: 0,
              lettershopID: dl.letterShop,
              noOfUnits: dl.noOfUnits,
              isActive: true
            })
        })
        let reqBody = {
          jobOrderGroupID,
          workOrderNoID,
          isManual,
          isLocal,
          serviceTypeID,
          isAR,
          isRA,
          costCenterID,
          customersRefNo,
          marsEesellerNoID,
          mailCharacteristicsID,
          transmissionModeID,
          mailSchemeID,
          mailingHouse,
          modeOfPaymentID,
          nameContactPerson,
          netWtReceptacle,
          noOfBagsReceptacle,
          ownerofPPI,
          customerPPIID,
          title,
          titleOfArticle,
          remarks,
          spBrandingMARSReseller,
          telephoneContactPerson,
          titleOfArticleID,
          totalWeightConsignment,
          typeofArticleID,
          isEDSAccepted,
          edsExceptionMessage,
          lettershopID,
          createdBy,
          estimatedLodgementDate,
          estimatedLodgementTime,
          noOfUnits,
          faxContactPerson,
          companyname,
          runmailIds,
          docketLetterShops
        };

        // console.log(reqBody);
        postDocket(reqBody).then((r) => {
          // console.log(r);
          if (r) {
            this.props.history.push({
              pathname: `/runMailList/${docket.jobGroupID}`,
              state: { workOrderMode: this.props.location.state.workOrderMode },
            });
          }
        });
      }
    }
  };
  onAddNewRowForLettershop = (e, i) => {
    let newDKTForLS = clone(this.state.docket)
    let newObj = {
      letterShop: 0,
      letterShopObj: {},
      noOfUnits: ""
    }
    // console.log(i)
    newDKTForLS.letterShopArray.splice(i + 1, 0, newObj)
    this.setState({ docket: newDKTForLS })
  }
  onDeleteRowForLettershop = (e, i) => {
    let newDKTForLS = clone(this.state.docket)
    newDKTForLS.letterShopArray.splice(i, 1)
    this.setState({ docket: newDKTForLS })

  }
  handleAutoOpenClick = (id) => {
    autoCId = id;
  };
  validateLS = () => {
    let valid = true
    if (this.state.docket.letterShopArray.length > 0) {
      if (this.state.docket.letterShopArray.length > 1)
        this.state.docket.letterShopArray.map(d => {
          if (d.letterShop && !d.noOfUnits)
            valid = false
        })
      else if (this.state.docket.letterShopArray.length === 1) {
        if (this.state.docket.letterShopArray[0].letterShop && !this.state.docket.letterShopArray[0].noOfUnits)
          valid = false
      }

    }

    return valid
  }
  render() {
    console.log(this.state.docket.letterShopArray)
    let { classes } = this.props;
    let {
      docket,
      serviceTypeList,
      paymentModeList,
      articleTypeList,
      mailingSchemeList,
      costCenterList,
      mailCharacteristicsList,
      transmissionModeList,
      customerPPIList,
      marsResellerNumberList,
      letterShopList,
      localDocket,
    } = this.state;

    let isDisabled = false;
    // console.log(customerPPIList);
    if (
      localDocket &&
      docket &&
      docket["mailingScheme"] &&
      docket["mailingScheme"].value == "NON BULK MAIL"
    ) {
      isDisabled = true;
    } else {
      isDisabled = false;
    }
    // console.log(isDisabled, "disabled");
    return (
      <React.Fragment>
        <Paper>
          {localDocket ? (
            <div className="pop-up-header">Create Local Docket</div>
          ) : (
              <div className="pop-up-header">Create International Docket</div>
            )}
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container}>
                <Typography component="div" className="blueBox">
                  <div className="blueBoxLabel">
                    <div>Customer: </div>
                    <div className="blueBoxText">{docket.customerName}</div>
                  </div>
                  <div className="blueBoxLabel">
                    <div>Work Order: </div>
                    <div className="blueBoxText">{docket.workOrderNumber}</div>
                  </div>
                  <div className="blueBoxLabel">
                    <div>Project Title: </div>
                    <div className="blueBoxText">{docket.projectTitle}</div>
                  </div>
                </Typography>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="service"
                    name="service"
                    hintText="service Type"
                    className={classes.textField}
                    value={docket.service}
                    options={serviceTypeList}
                    onChange={this.handleServiceTypeChange}
                    getOptionLabel={(option) => option.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Service Type"
                        name="service"
                        id="service"
                        margin="normal"
                        required
                        className={classes.autocompleteText}
                        error={this.state.errors.service}
                        helperText={
                          this.state.errors.service
                            ? this.state.errors.service
                            : ""
                        }
                      />
                    )}
                  />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast
                      required
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      className={classes.textField}
                      id="pickupDate"
                      label="Estimated Lodgement Date"
                      value={docket.pickupDate}
                      onChange={this.handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={TimeFnsUtils}>
                    <KeyboardTimePicker
                      autoOk
                      required
                      disablePast
                      variant="inline"
                      margin="normal"
                      className={classes.textField}
                      id="pickupTime"
                      label="Estimated Lodgement Time"
                      value={docket.pickupTime}
                      onChange={this.handleTimeChange}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div style={{ display: "flex" }}>
                  {this.state.docket.service.text === "Postmarking" ? (
                    <Autocomplete
                      autoComplete
                      includeInputInList
                      disableClearable
                      id="ppiPermitNumber"
                      name="ppiPermitNumber"
                      hintText="Mode of Payment"
                      className={classes.textField}
                      options={customerPPIList}
                      value={docket.customerPPI}
                      onChange={this.handleChangeAccount}
                      getOptionLabel={(option) => option.billingAccountNo}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="PPI Permit Number/Customer Account"
                          name="ppiPermitNumber"
                          id="ppiPermitNumber"
                          margin="normal"
                          required
                          className={classes.autocompleteText}
                        // error={this.state.errors.ppiPermitNumber}
                        // helperText={
                        //   this.state.errors.ppiPermitNumber
                        //     ? this.state.errors.ppiPermitNumber
                        //     : ""
                        // }
                        />
                      )}
                    />
                  ) : (
                      <Autocomplete
                        autoComplete
                        includeInputInList
                        disableClearable
                        id="ppiPermitNumber1"
                        name="ppiPermitNumber"
                        // hintText="Mode of Payment"
                        className={classes.textField}
                        options={customerPPIList}
                        value={docket.ppiPermitNumber}
                        onChange={this.handlePPIChange}
                        getOptionLabel={(option) => option.ppiNumber}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="PPI Permit Number/Customer Account"
                            name="ppiPermitNumber"
                            id="ppiPermitNumber2"
                            margin="normal"
                            required
                            className={classes.autocompleteText}
                          // error={this.state.errors.ppiPermitNumber}
                          // helperText={
                          //   this.state.errors.ppiPermitNumber
                          //     ? this.state.errors.ppiPermitNumber
                          //     : ""
                          // }
                          />
                        )}
                      />
                    )}
                  <TextField
                    label="Owner of PPI"
                    name="ppiOwner"
                    inputProps={{
                      maxLength: 200,
                    }}
                    id="ppiOwner1"
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={docket.ppiOwner}
                    onChange={this.handleChange}
                  // error={this.state.errors.ppiOwner}
                  // helperText={
                  //   this.state.errors.ppiOwner
                  //     ? this.state.errors.ppiOwner
                  //     : ""
                  // }
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="modeOfPayment1"
                    name="modeOfPayment"
                    hintText="Mode of Payment"
                    className={classes.textField}
                    options={paymentModeList}
                    value={docket.modeOfPayment}
                    onChange={this.handlePaymentMode}
                    getOptionLabel={(option) => option.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Mode of Payment"
                        name="modeOfPayment"
                        id="modeOfPayment1"
                        margin="normal"
                        required
                        className={classes.autocompleteText}
                      // error={this.state.errors.modeOfPayment}
                      // helperText={
                      //   this.state.errors.modeOfPayment
                      //     ? this.state.errors.modeOfPayment
                      //     : ""
                      // }
                      />
                    )}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  {this.state.docket.service.value !== "PPI" ? (
                    <Autocomplete
                      autoComplete
                      includeInputInList
                      id="mailingScheme"
                      name="mailingScheme"
                      hintText="Mailing Scheme"
                      className={classes.textField}
                      options={this.state.mailingSchemeList2}
                      onChange={this.handleMailingScheme}
                      value={docket.mailingScheme}
                      getOptionLabel={(option) => option.text}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Mailing Scheme"
                          name="mailingScheme"
                          id="mailingScheme"
                          margin="normal"
                          required
                          className={classes.autocompleteText}
                          error={this.state.errors.mailingScheme}
                          helperText={
                            this.state.errors.mailingScheme
                              ? this.state.errors.mailingScheme
                              : ""
                          }
                        />
                      )}
                    />
                  ) : (
                      <Autocomplete
                        autoComplete
                        includeInputInList
                        id="mailingScheme"
                        name="mailingScheme"
                        hintText="Mailing Scheme"
                        className={classes.textField}
                        options={mailingSchemeList}
                        onChange={this.handleMailingScheme}
                        value={docket.mailingScheme}
                        getOptionLabel={(option) => option.text}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Mailing Scheme"
                            name="mailingScheme"
                            id="mailingScheme"
                            margin="normal"
                            required
                            className={classes.autocompleteText}
                            error={this.state.errors.mailingScheme}
                            helperText={
                              this.state.errors.mailingScheme
                                ? this.state.errors.mailingScheme
                                : ""
                            }
                          />
                        )}
                      />
                    )}

                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="mailCharacteristics"
                    name="mailCharacteristics"
                    hintText="Mail Characteristics"
                    className={classes.textField}
                    options={mailCharacteristicsList}
                    onChange={this.handleMailCharacteristics}
                    // value={docket.mailCharacteristics}
                    // value={
                    //   this.state.localDocket ||
                    //   docket.mailingScheme.value !== "NON BULK MAIL"
                    //     ? docket.mailCharacteristics
                    //     : null
                    // }
                    getOptionLabel={(option) => option.value}
                    value={
                      !this.state.localDocket ||
                        isDisabled ||
                        this.state.docket.service.value !== "PPI"
                        ? null
                        : docket.mailCharacteristics
                    }
                    disabled={
                      !this.state.localDocket ||
                        isDisabled ||
                        this.state.docket.service.value !== "PPI"
                        ? true
                        : false
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Mail Characteristics"
                        name="mailCharacteristics"
                        id="mailCharacteristics"
                        margin="normal"
                        required={
                          !this.state.localDocket ||
                            isDisabled ||
                            (docket["mailingScheme"].value == "CONTRACT" || docket["mailingScheme"].value == "PUBLIC") ||
                            (localDocket &&
                              docket &&
                              docket["mailingScheme"] &&
                              docket["mailingScheme"].value == "NON BULK MAIL")
                            ? false
                            : true
                        }
                        className={classes.autocompleteText}
                        error={this.state.errors.mailCharacteristics}
                        helperText={
                          this.state.errors.mailCharacteristics
                            ? this.state.errors.mailCharacteristics
                            : ""
                        }
                      />
                    )}
                  />
                  <TextField
                    label="Mailing House"
                    name="mailingHouse"
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={docket.mailingHouse}
                    onChange={this.handleChange}
                    error={this.state.errors.mailingHouse}
                    helperText={
                      this.state.errors.mailingHouse
                        ? this.state.errors.mailingHouse
                        : ""
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="articleType"
                    name="articleType"
                    hintText="Type of articles by content"
                    className={classes.textField}
                    options={articleTypeList}
                    onChange={this.handleArticleType}
                    getOptionLabel={(option) => option.value}
                    value={docket.articleType}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type of articles by content"
                        name="articleType"
                        id="articleType"
                        margin="normal"
                        required
                        className={classes.autocompleteText}
                        error={this.state.errors.articleType}
                        helperText={
                          this.state.errors.articleType
                            ? this.state.errors.articleType
                            : ""
                        }
                      />
                    )}
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="costCenter"
                    name="costCenter"
                    hintText="Cost Center"
                    className={classes.textField}
                    options={costCenterList}
                    onChange={this.handleCostCenter}
                    getOptionLabel={(option) => option.text}
                    value={docket.costCenter}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cost Center"
                        name="costCenter"
                        id="costCenter"
                        margin="normal"
                        className={classes.autocompleteText}
                        error={this.state.errors.costCenter}
                        helperText={
                          this.state.errors.costCenter
                            ? this.state.errors.costCenter
                            : ""
                        }
                      />
                    )}
                  />
                  <TextField
                    label="Customer's Ref Number"
                    name="customersRefNo"
                    inputProps={{
                      maxLength: 10,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={docket.customersRefNo}
                    onChange={this.handleChange}
                    error={this.state.errors.customersRefNo}
                    helperText={
                      this.state.errors.customersRefNo
                        ? this.state.errors.customersRefNo
                        : ""
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="Total Weight of Consignment"
                    name="totalWeightOfConsignment"
                    inputProps={{
                      maxLength: 10,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={docket.totalWeightOfConsignment}
                    onChange={this.handleChange}
                    onBlur={this.onBlur}
                    error={this.state.errors.totalWeightOfConsignment}
                    helperText={
                      this.state.errors.totalWeightOfConsignment
                        ? this.state.errors.totalWeightOfConsignment
                        : ""
                    }
                    onKeyPress={this._onDecimalKeyPress}
                  />
                  <TextField
                    label="No of Bags/Receptacles"
                    name="numberOfBags"
                    inputProps={{
                      maxLength: 10,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={docket.numberOfBags}
                    onChange={this.handleChange}
                    error={this.state.errors.numberOfBags}
                    helperText={
                      this.state.errors.numberOfBags
                        ? this.state.errors.numberOfBags
                        : ""
                    }
                    onKeyPress={this._onNumericKeyPress}
                  />
                  <TextField
                    label="Net weight per receptacle"
                    name="netWeightPerReceptacle"
                    inputProps={{
                      maxLength: 20,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={docket.netWeightPerReceptacle}
                    onChange={this.handleChange}
                    error={this.state.errors.netWeightPerReceptacle}
                    helperText={
                      this.state.errors.netWeightPerReceptacle
                        ? this.state.errors.netWeightPerReceptacle
                        : ""
                    }
                    onKeyPress={this._onDecimalKeyPress}
                    onBlur={this.onBlur}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    required
                    label="Name of Contact Person"
                    name="contactPersonName"
                    inputProps={{
                      maxLength: 50,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={docket.contactPersonName}
                    onChange={this.handleChange}
                    error={this.state.errors.contactPersonName}
                    helperText={
                      this.state.errors.contactPersonName
                        ? this.state.errors.contactPersonName
                        : ""
                    }
                  />
                  <TextField
                    required
                    label="Tel of Contact Person"
                    name="contactPersonNumber"
                    inputProps={{
                      maxLength: 15,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={docket.contactPersonNumber}
                    onChange={this.handleChange}
                    error={this.state.errors.contactPersonNumber}
                    helperText={
                      this.state.errors.contactPersonNumber
                        ? this.state.errors.contactPersonNumber
                        : ""
                    }
                  />
                  <TextField
                    label="Fax of Contact Person"
                    name="contactPersonFax"
                    inputProps={{
                      maxLength: 15,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={docket.contactPersonFax}
                    onChange={this.handleChange}
                    error={this.state.errors.contactPersonFax}
                    helperText={
                      this.state.errors.contactPersonFax
                        ? this.state.errors.contactPersonFax
                        : ""
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    //marginTop: "20px"
                  }}
                >
                  <TextField
                    label="Name of the Company"
                    name="companyName"
                    inputProps={{
                      maxLength: 50,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={docket.companyName}
                    onChange={this.handleChange}
                    error={this.state.errors.companyName}
                    helperText={
                      this.state.errors.companyName
                        ? this.state.errors.companyName
                        : ""
                    }
                  />
                  <TextField
                    rows={1}
                    rowsMax={4}
                    multiline="true"
                    label="Title of Article"
                    //placeholder="(please indicate the full title) For annual report, please indicate the name of the company"
                    name="remarksTitle"
                    inputProps={{
                      maxLength: 200,
                    }}
                    style={{ marginTop: "0px" }}
                    // id="titleOfArticle"
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={docket.remarksTitle}
                    onChange={this.handleChange}
                    error={this.state.errors.remarksTitle}
                    helperText={
                      this.state.errors.remarksTitle
                        ? this.state.errors.remarksTitle
                        : ""
                    }
                  />
                  <TextField
                    rows={1}
                    rowsMax={4}
                    multiline="true"
                    label="Title of Article1"
                    //placeholder="(please indicate the full title) For annual report, please indicate the name of the company"
                    name="remarksTitle1"
                    inputProps={{
                      maxLength: 200,
                    }}
                    style={{ marginTop: "0px" }}
                    // id="titleOfArticle"
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={docket.remarksTitle1}
                    onChange={this.handleTitle}
                    error={this.state.errors.remarksTitle1}
                    helperText={
                      this.state.errors.remarksTitle1
                        ? this.state.errors.remarksTitle1
                        : ""
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextField
                    label="SP Branding MARS reseller"
                    name="spBrandingMarsReseller"
                    inputProps={{
                      maxLength: 15,
                    }}
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={docket.spBrandingMarsReseller}
                    onChange={this.handleChange}
                    error={this.state.errors.spBrandingMarsReseller}
                    helperText={
                      this.state.errors.spBrandingMarsReseller
                        ? this.state.errors.spBrandingMarsReseller
                        : ""
                    }
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="marsResellerNumber"
                    name="marsResellerNumber"
                    hintText="MARS reseller number"
                    className={classes.textField}
                    options={marsResellerNumberList}
                    onChange={this.handleMarsResellerNumber}
                    getOptionLabel={(option) => option.value}
                    value={docket.marsResellerNumber}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="MARS reseller number"
                        name="marsResellerNumber"
                        id="marsResellerNumber"
                        margin="normal"
                        className={classes.autocompleteText}
                        error={this.state.errors.marsResellerNumber}
                        helperText={
                          this.state.errors.marsResellerNumber
                            ? this.state.errors.marsResellerNumber
                            : ""
                        }
                      />
                    )}
                  />

                  <TextField
                    multiline={true}
                    rows={1}
                    rowsMax={4}
                    label="Remarks"
                    name="remarks"
                    inputProps={{
                      maxLength: 200,
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={docket.remarks}
                    onChange={this.handleChange}
                    error={this.state.errors.remarks}
                    helperText={
                      this.state.errors.remarks ? this.state.errors.remarks : ""
                    }
                  />
                </div>
                <div style={{ display: "flex" }}>
                  {localDocket ? null : (
                    <Autocomplete
                      autoComplete
                      includeInputInList
                      id="transmissionMode"
                      name="transmissionMode"
                      hintText="MARS reseller number"
                      className={classes.textField}
                      options={transmissionModeList}
                      onChange={this.handletransmissionMode}
                      getOptionLabel={(option) => option.value}
                      value={docket.transmissionMode}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Transmission Mode"
                          name="transmissionMode"
                          id="transmissionMode"
                          margin="normal"
                          className={classes.autocompleteText}
                          error={this.state.errors.transmissionMode}
                          helperText={
                            this.state.errors.transmissionMode
                              ? this.state.errors.transmissionMode
                              : ""
                          }
                        />
                      )}
                    />
                  )}

                  <FormControlLabel
                    style={localDocket ? { marginTop: "34px", marginLeft: "-19px" } : { marginTop: "34px", marginLeft: ".10em" }}
                    margin="normal"
                    name="isAR"
                    control={
                      <Checkbox
                        color="primary"
                        checked={docket.isAR}
                        className={classes.Checkbox}
                        value={docket.isAR}
                        onChange={(e) => this.handleCheckBoxChangeAR(e, "isAR")}
                      />
                    }
                    label="AR"
                  />
                  <FormControlLabel
                    style={{ marginTop: "34px", marginLeft: ".10em" }}
                    margin="normal"
                    name="isRA"
                    control={
                      <Checkbox
                        color="primary"
                        checked={docket.isRA}
                        className={classes.Checkbox}
                        value={docket.isRA}
                        onChange={(e) => this.handleCheckBoxChange(e, "isRA")}
                      />
                    }
                    label="RA"
                  />
                </div>
                {this.state.docket.letterShopArray && this.state.docket.letterShopArray.length > 0 &&
                  this.state.docket.letterShopArray.map((lsObj, lsIn) => {
                    return <div style={{ display: "flex" }} key={lsIn}>
                      {" "}
                      <Autocomplete
                        autoComplete
                        includeInputInList
                        id="letterShop"
                        name="letterShop"
                        hintText="Letter Shop"
                        className={classes.textField}
                        options={letterShopList}
                        onOpen={(e) =>
                          this.handleAutoOpenClick(lsIn)
                        }
                        onChange={this.handleLetterShop}
                        getOptionLabel={(option) => option.value}
                        value={lsObj.letterShopObj}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Letter Shop"
                            name="letterShop"
                            id="letterShop"
                            margin="normal"
                            className={classes.autocompleteText}
                          // error={this.state.errors.letterShop}
                          // helperText={
                          //   this.state.errors.letterShop
                          //     ? this.state.errors.letterShop
                          //     : ""
                          // }
                          />
                        )}
                      />
                      <TextField
                        label="No of Units"
                        name="noOfUnits"
                        inputProps={{
                          maxLength: 10,
                        }}
                        required={lsObj.letterShopObj.id ? true : false}
                        disabled={!lsObj.letterShopObj.id}
                        margin="normal"
                        autoComplete="off"
                        className={classes.textField}
                        value={lsObj.noOfUnits}
                        onChange={e => this.handleChangeNOU(e, lsIn)}
                        // error={this.state.errors.noOfUnits}
                        // helperText={
                        //   this.state.errors.noOfUnits
                        //     ? this.state.errors.noOfUnits
                        //     : ""
                        // }
                        onKeyPress={this._onNumericKeyPress}
                      />
                      <div style={{
                        display: "flex",
                        alignItems: "flex-end",
                        marginBottom: "-3px",
                        marginLeft: "1em"
                      }}>
                        <div> <Tooltip title="Insert new row">
                          <IconButton
                            aria-label="add"
                            className={classes.iconHover}
                            onClick={e => this.onAddNewRowForLettershop(e, lsIn)}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip></div>
                        {this.state.docket.letterShopArray && this.state.docket.letterShopArray.length > 1 && <div style={{ marginLeft: "1em" }}> <Tooltip title="Remove this row">
                          <IconButton
                            aria-label="add"
                            className={classes.iconHover}
                            onClick={e => this.onDeleteRowForLettershop(e, lsIn)}

                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip></div>}
                      </div>
                    </div>
                  })
                }

                <Divider style={{ marginTop: "25px" }} />
                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none", marginLeft: "8px" }}
                      onClick={(e) =>
                        this.props.history.push({
                          pathname: "/runMailList/" + docket.jobGroupID,
                          state: {
                            workOrderMode: this.props.location.state
                              .workOrderMode,
                          },
                        })
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    <Button
                      disabled={!this.state.formValid || !this.state.docket.contactPersonName || docket.ppiPermitNumber == "null" || docket.mailingScheme == "null" || !this.validateLS()}
                      autoFocus
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={this.handleSubmit}
                      className={
                        (this.state.docket &&
                          this.state.docket["mailingScheme"] &&
                          this.state.docket["mailingScheme"].value ==
                          "CONTRACT") ||
                          (localDocket &&
                            docket &&
                            docket["mailingScheme"] &&
                            docket["mailingScheme"].value == "NON BULK MAIL")
                          ? this.state.serviceValid &&
                          this.state.pickupDateValid &&
                          this.state.pickupTimeValid &&
                          // this.state.ppiPermitNumberValid &&
                          this.state.mailingSchemeValid &&
                          this.state.modeOfPaymentValid &&
                          // this.state.docket.contactPersonName &&
                          this.state.articleTypeValid &&
                          this.state.contactPersonNameValid &&
                          this.state.contactPersonNumberValid
                          //  &&
                          // this.state.errors.ppiPermitNumber
                          : !this.state.formValid
                            ? "disabled-button"
                            : "enabled-button"
                      }
                    >
                      SUBMIT
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(AddDocket));
