import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { AddIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import axios from "axios";
import toastr from "toastr";
import { styles } from "../../../shared/styles";
import { StateContext } from "../../../shared/globalState";
import { getZoneList, deleteZone, postZone } from "../masterActions";
const validateMaxLength2 = new Validator("maxlength2");
const validateMaxLength100 = new Validator("maxlength100OnlyChar");
const baseURL = process.env.REACT_APP_PGS_SERVER_MASTER_DATA_MANAGEMENT;
const pgsService = axios.create({
  baseURL,
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class ManageZone extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    zoneCode: "",
    zoneName: "",
    isActive: true,
    createdBy: 1,
    modifiedBy: 1,
    remarks: "",
    errors: {
      zoneCode: false,
      zoneName: false,
      //   isActive:false,
      //  createdBy:false,
      //  modifiedBy:false,
      //  remarks:false,
      //  zonelist: [],
    },
  };

  onCreateZone = () => {
    const list = this.context;
    let notValid = false;
    if (
      !this.state.zoneCode ||
      !this.state.zoneName
      // !this.state.isActive ||
      // !this.state.createdBy
    )
      return;
    for (let err in this.state.errors) {
      if (this.state.errors[err]) notValid = true;
    }
    if (notValid) return;

    let { zoneCode, zoneName, isActive, createdBy } = this.state;
    let reqBody = { zoneCode, zoneName, isActive, createdBy };
    postZone(reqBody).then((r) => {
      this.handleClose();
      toastr.success("Zone Created Successfully");
      list.refreshData("getAllZone");
      this.props.history.push("/zone");
      this.props.onRequestSucceed();
    });
  };

  onUpdateZone = () => {
    let notValid = false;
    for (let err in this.state.errors) {
      if (this.state.errors[err]) notValid = true;
    }
    if (notValid) return;
    let { zoneCode, zoneName, isActive, modifiedBy } = this.state;
    let {
      data: { zoneID },
    } = this.props;
    let reqBody = { zoneCode, zoneName, isActive, modifiedBy };
    pgsService
      .put(`/Zones/${zoneID}`, reqBody)
      .then((r) => {
        this.handleClose();
        this.props.onRequestSucceed();
        toastr.success("Zone Updated Successfully");
      })
      .catch((e) => {
        this.handleClose();
        e.response.data && toastr.error(e.response.data.errors[0].message);
        this.props.onRequestSucceed();
      });
  };

  onDeleteZone = () => {
    const dispatch = this.context[1];
    let { selected } = this.props;
    let deletionIds = selected.join(",");
    deleteZone(deletionIds).then((z) => {
      getZoneList().then((r) => {
        dispatch({
          type: "getZoneList",
          newStore: r.data,
        });
        this.handleClose();
        //toastr.success("Zone Deleted Successfully");
        this.props.onRequestSucceed("deleted");
      });
    });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
    //setting departments state when modal open
    if (this.props.mode === "edit" || this.props.mode === "view") {
      this.setState({
        zoneCode: this.props.data.zoneCode,
        zoneName: this.props.data.zoneName,
      });
    }
  };
  handleClose = () => {
    // clean up state and errors when modal closed
    let newErrors = { ...this.state.errors };
    newErrors.zoneCode = false;
    newErrors.zoneName = false;
    // newErrors.isActive=false;
    // newErrors.modifiedBy=false
    this.setState({
      open: false,
      zoneCode: "",
      zoneName: "",
      // isActive:"",
      // modifiedBy:"",
      errors: newErrors,
    });
  };
  //changing state data, validating that data with error info, if any, on input changed
  onInputChanged = (e, name, type) => {
    let newError = { ...this.state.errors };
    if (type === "maxlength2") {
      let result = validateMaxLength2(e.target.value);
      newError[name] = !result;
    } else if (type === "maxlength100OnlyChar") {
      let result = validateMaxLength100(e.target.value);
      newError[name] = !result;
    }
    this.setState({ errors: newError });
    this.setState({ [name]: e.target.value });
  };

  render() {
    let { zoneCode, zoneName } = this.state;
    const { classes, mode, selected, data, isActive } = this.props;

    return (
      <div>
        <IconButton
          aria-label="add"
          className={classes.iconHover}
          onClick={this.handleClickOpen}
          disabled={!isActive}
        >
          {mode && mode === "edit" ? (
            <EditIcon />
          ) : mode && mode === "view" ? (
            <VisibilityIcon />
          ) : mode && mode === "delete" ? (
            <DeleteIcon />
          ) : (
            <AddIcon />
          )}
        </IconButton>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="lg"
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {" "}
            {/* Setting  Modal Title base on the mode */}
            {mode && mode === "edit"
              ? "Update Zone details"
              : mode && mode === "view"
              ? "Zone details"
              : mode && mode === "delete"
              ? "Delete Zone"
              : "Create New Zone"}
          </DialogTitle>
          {mode === "delete" ? (
            <DialogContent dividers>
              <div className={classes.deleteContainer}>
                Are you sure you want to delete {""}
                <span style={{ fontWeight: "600" }}>
                  {selected.length > 1 ? " ?" : data.zoneName + " ?"}
                  {/* {data.zoneName} */}
                </span>
              </div>
            </DialogContent>
          ) : (
            <DialogContent dividers>
              {mode && mode === "add"}
              {/* && (
                <P style={{ fontSize: "16px" }}>Enter Zone details</P>
              )} */}
              <div className={classes.container}>
                <div>
                  <TextField
                    required
                    InputProps={{
                      readOnly: mode === "view" ? true : false,
                    }}
                    inputProps={{
                      maxLength: 2,
                    }}
                    label="Zone Code"
                    placeholder="zone Code"
                    className={classes.textField}
                    margin="normal"
                    value={zoneCode}
                    error={this.state.errors.zoneCode}
                    helperText={
                      this.state.errors.zoneCode
                        ? "Please enter only alphabets"
                        : ""
                    }
                    onChange={(e) =>
                      this.onInputChanged(e, "zoneCode", "maxlength2")
                    }
                  />
                  <TextField
                    required
                    label="Zone Name"
                    placeholder="Zone Name"
                    className={classes.textField}
                    InputProps={{
                      readOnly: mode === "view" ? true : false,
                    }}
                    inputProps={{
                      maxLength: 100,
                    }}
                    margin="normal"
                    value={zoneName}
                    error={this.state.errors.zoneName}
                    helperText={
                      this.state.errors.zoneName
                        ? "Please enter only alphabets"
                        : ""
                    }
                    onChange={(e) =>
                      this.onInputChanged(e, "zoneName", "maxlength100OnlyChar")
                    }
                  />
                </div>{" "}
              </div>
              {/* <div className={classes.container}>
                <div>
                <Checkbox
        checked={this.state.isActive}
        onChange={onInputChanged(e,'isActive',"")}
        value="isActive"
        color="primary"
        inputProps={{
          'aria-label': 'Is Active',
        }}
      />
                </div>
                </div> */}
            </DialogContent>
          )}
          <DialogActions>
            {/* <div className="button-wrapper">
                  <div> */}
            <Button
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={this.handleClose}
            >
              CANCEL
            </Button>
            {/* </div>
            <div style={{ marginLeft: "1em" }}> */}
            {mode && mode !== "view" && mode === "delete" ? (
              <Button
                onClick={
                  mode === "add"
                    ? this.onCreateZone
                    : mode === "edit"
                    ? this.onUpdateZone
                    : this.onDeleteZone
                }
                // disabled
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                //color="primary"
              >
                {mode === "edit"
                  ? "Update"
                  : mode === "delete"
                  ? "Delete"
                  : "Create"}
              </Button>
            ) : (mode === "add" && !zoneName) ||
              !zoneCode ||
              this.state.errors.zoneName ||
              this.state.errors.zoneCode ||
              (mode === "edit" &&
                zoneCode === this.props.data.zoneCode &&
                zoneName === this.props.data.zoneName) ? (
              <Button
                onClick={
                  mode === "add"
                    ? this.onCreateZone
                    : mode === "edit"
                    ? this.onUpdateZone
                    : this.onDeleteZone
                }
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}

                //color="primary"
              >
                {mode === "edit"
                  ? "Update"
                  : mode === "delete"
                  ? "Delete"
                  : "Create"}
              </Button>
            ) : (
              <Button
                onClick={
                  mode === "add"
                    ? this.onCreateZone
                    : mode === "edit"
                    ? this.onUpdateZone
                    : this.onDeleteZone
                }
                // disabled
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
                //color="primary"
              >
                {mode === "edit"
                  ? "Update"
                  : mode === "delete"
                  ? "Delete"
                  : "Create"}
              </Button>
            )}
            {/* </div>
             </div> */}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ManageZone));
