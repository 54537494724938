import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_PUBLISHER_ORDER_PLACEMENT;
const pgsService = axios.create({
  baseURL,
});

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
export const getDHLRateUponAWeight = async (cId, aw) => {
  let dhlVal = [];
  await pgsService
    .get(`/GetDHLRate/${cId}/${aw}`)
    .then((r) => {
      dhlVal = r;
    })
    .catch((e) => { });
  return dhlVal;
};
export const deleteMLOPFiles = async (deletionId, loggedInId) => {
  let result = [];
  await pgsService
    .delete(`/DeleteMLOPHeader/${deletionId}/${loggedInId}`)
    .then((r) => {
      toastr.success("Order file deleted successfully");
    })
    .catch((e) => { });
  return result;
};
export const deleteSnigleMLOPFiles = async (deletionId, loggedInId, msg) => {
  let result = [];
  await pgsService
    .delete(`/DeleteMLOPHeader/${deletionId}/${loggedInId}`)
    .then((r) => { })
    .catch((e) => { });
  return result;
};
export const uploadeMLOPFiles = async (data) => {
  let result = [];
  await pgsService
    .post("/MLOPCreate", data)
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else result = r.data;
    })
    .catch((e) => {
      toastr.error("Something went wrong. Please try again");
    });
  return result;
};
export const uploadeMLOPFilesOnProceed = async (data) => {
  let result = [];
  await pgsService
    .put("/UpdateToProcede", data)
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else result = r.data;
    })
    .catch((e) => {
      toastr.error("Something went wrong. Please try again");
    });
  return result;
};
export const getPubMainLabelOrderPlacement = async () => {
  let pmlopList = [];
  await pgsService
    .get("/GetMLOPHeader")
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getExcelPubMainLabelOrderPlacement = async (id, noOfPage) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetExcelDownload/${id}/${noOfPage}`)
    .then((r) => {
      pmlopList = r;
    })
    .catch((e) => { });
  return pmlopList;
};

export const getOnePubMainLabelOrderPlacement = async (id, noOfPage) => {
  let pmlopList = [];
  await pgsService
    .get(`/GetMLOPDetailByHeaderID/${id}/${noOfPage}`)
    .then((r) => {
      pmlopList = r.data;
    })
    .catch((e) => { });
  return pmlopList;
};
export const getPublisherCustomerList = async () => {
  let customerList = [];
  await pgsService
    .get("/GetPublisherCustomer")
    .then((r) => {
      customerList = r;
    })
    .catch((e) => { });
  return customerList;
};

export const searchMLOF = async (rBody) => {
  let searchList = [];
  await pgsService
    .post("/SearchMLOrderPlacementDetails", rBody)
    .then((r) => {
      searchList = r;
    })
    .catch((e) => { });
  return searchList;
};

export const getStatusList = async () => {
  let customerList = [];
  await pgsService
    .get("/GetMLStatus")
    .then((r) => {
      customerList = r;
    })
    .catch((e) => { });
  return customerList;
};
export const getSubscriptionList = async () => {
  let customerList = [];
  await pgsService
    .get("/GetSubscription")
    .then((r) => {
      customerList = r;
    })
    .catch((e) => { });
  return customerList;
};

export const UpdateParticularOrder = async (id, data) => {
  let result = [];
  await pgsService
    .put(`/UpdateOrderDetail/${id}`, data)
    .then((r) => {
      toastr.success("Order Updated Successfully");
    })
    .catch((e) => {
      toastr.error("Something went wrong. Please try again");
    });
  return result;
};