import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import {
  getDeliveryNote,
  getDeliveryNoteDetails,
  updateDeliveryNote,
  getDeliveryStatusList,
  //getCountryDropdownList
} from "../reportServiceAction";
import {
  getShipmentTypeList,
  getCountryDropdownList,
} from "../../masters/masterActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Validator from "../../../shared/validator";
import { StateContext } from "../../../shared/globalState";
import moment from "moment";
import { resourceValidation } from "../../../shared/resource";
import InputBase from "@material-ui/core/InputBase";
import PropTypes from "prop-types";
import clone from "clone";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import SyncIcon from "@material-ui/icons/Sync";
import ReportViewer from "../../app/ReportViewer";
import {
  EditIcon,
  CustomPrintIcon,
  CustomSmallAddIcon,
} from "../../../shared/customIcons";
import "date-fns";
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from "@date-io/date-fns";
import TimeFnsUtils from "@date-io/date-fns";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const validateNotRequired100 = new Validator("maxlength100AllAllow");
const validatePhoneNo = new Validator("phnNoFCN");
const validateZipCode = new Validator("pincode");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "31%",
    fontSize: "11px !important",
  },
  textField1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "95.5%",
    fontSize: "11px !important",
  },
  autocompleteText: {
    width: "100%",
    fontSize: "11px !important",
  },
  autoComplete: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: "31%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  switchControl: {
    marginTop: "38px",
    marginLeft: "1em",
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    // marginLeft: "1.5em",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
});
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderLeft: "1px sold #000", width: `${headCell.width}` }}
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
function createData(
  editClicked,
  validRecord,
  stockDescription,
  noOfPts,
  totalPackage,
  noOfCopies,
  totalQuantity,
  weightPerCortanBox,
  netWeight,
  isActive,
  createdBy
) {
  return {
    editClicked,
    validRecord,
    stockDescription,
    noOfPts,
    totalPackage,
    noOfCopies,
    totalQuantity,
    weightPerCortanBox,
    netWeight,
    isActive,
    createdBy,
  };
}
const EnhancedTableToolbar = (props) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  let { validList } = props;
  const handleAccept = () => {
    setOpen(false);
    props.handleClickButton("", "reset");
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          //   width: "100%",
          border: "1px solid #eaeaea",
          borderBottom: "none",
          borderRadius: "4px",
          margin: "0 .8em 0 .6em",
        }}
      >
        {props.disableAdd ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={(e) => props.handleClickButton(e, "add")}
          // style={
          //   !props.disableAdd
          //     ? {
          //         color: "#fff",
          //         background: "#3982ea",
          //         padding: "2px",
          //         margin: ".5em",
          //         fontSize: "11px",
          //       }
          //     : { color: "#3982ea", fontSize: "11px" }
          // }
          >
            <CustomSmallAddIcon style={{ fontSize: "18px" }} /> Add
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              onClick={(e) => props.handleClickButton(e, "add")}
            // style={
            //   !props.disableAdd
            //     ? {
            //         color: "#fff",
            //         background: "#3982ea",
            //       }
            //     : { color: "#3982ea", fontSize: "11px" }
            // }
            >
              <CustomSmallAddIcon style={{ fontSize: "18px" }} /> Add
            </Button>
          )}
        {props.editableDeliveryNoteDetailList === props.resetList ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            // style={
            //   !(props.editableDeliveryNoteDetailList === props.resetList)
            //     ? {
            //         color: "#fff",
            //         background: "#3982ea",
            //         padding: "2px",
            //         margin: ".5em",
            //         fontSize: "11px",
            //       }
            //     : { color: "#3982ea", fontSize: "11px" }
            // }
            onClick={handleClickOpen}
          >
            <SyncIcon style={{ fontSize: "18px" }} /> Reset
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              // style={
              //   !(props.editableDeliveryNoteDetailList === props.resetList)
              //     ? {
              //         color: "#fff",
              //         background: "#3982ea",
              //         padding: "2px",
              //         margin: ".5em",
              //         fontSize: "11px",
              //       }
              //     : { color: "#3982ea", fontSize: "11px" }
              // }
              onClick={handleClickOpen}
            >
              <SyncIcon style={{ fontSize: "18px" }} /> Reset
            </Button>
          )}
        <div>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {'Are you sure you want to "Reset"?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div style={{ textAlign: "center" }}>
                  This will remove all actions immediately,
                  <br />
                  You can't undo this action!
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={handleClose}
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
              >
                No
              </Button>
              <Button
                onClick={handleAccept}
                variant="contained"
                color="primary"
                style={{ boxShadow: "none" }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {!validList ? (
          <div
            style={{
              marginLeft: "1%",
              marginRight: "1%",
              marginTop: "1%",
              color: "red",
              width: "70%",
              fontSize: "small",
            }}
          >
            * No of Copies, Weight Per Carton Box and Net Weight is required
          </div>
        ) : null}
      </div>
    </div>
  );
};
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const headCells = [
  {
    id: 1,
    code: "lineNo",
    numeric: false,
    disablePadding: true,
    label: "Line No",
    width: "auto",
  },
  {
    id: 2,
    code: "stockDescription",
    numeric: false,
    disablePadding: false,
    label: "Description *",
    width: "100%",
  },
  {
    id: 3,
    code: "noOfPts",
    numeric: false,
    disablePadding: false,
    label: "No of Plts",
    width: "auto",
  },
  {
    id: 4,
    code: "totalPackage",
    numeric: false,
    disablePadding: false,
    label: "Total Packages *",
    width: "auto",
  },
  {
    id: 5,
    code: "noOfCopies",
    numeric: false,
    disablePadding: false,
    label: "No of Cps/Pk",
    width: "auto",
  },
  {
    id: 6,
    code: "totalQuantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity *",
    width: "auto",
  },
  {
    id: 7,
    code: "weightPerCartBox",
    numeric: false,
    disablePadding: false,
    label: "Weight per Carton Box (Kg)",
    width: "auto",
  },
  {
    id: 8,
    code: "netWeight",
    numeric: false,
    disablePadding: false,
    label: "Net Weight (Kg)",
    width: "auto",
  },
  {
    id: 9,
    code: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    width: "auto",
  },
];
class EditDeliveryNote extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      deliveryNote: {
        deliveryNoteNo: "",
        jobGroupID: "",
        clientname: "",
        workOrderNo: "",
        title: "",
        jobGroupNumber: "",
        description: "",
        contactPersonName: "",
        contactNumber: "",
        zipCode: "",
        country: "",
        countryID: 0,
        shipmentType: "",
        isUrgent: false,
        agentCode: "",
        deliverFromAddress: "",
        deliveryToAddress: "",
        deliveryNoteAddress2: "",
        deliveryNoteAddress3: "",
        totalQuantity: "",
        status: "",
        deliveryDate: null,
        deliveryByTime: null,
        driverName: "",
        remarks: "",
      },
      deliveryNoteDetails: {
        stockDescription: "",
        noOfPts: 0,
        totalPackage: 0,
        noOfCopies: 0,
        totalQuantity: 0,
        weightPerCortanBox: 0,
        netWeight: 0,
      },
      workOrder: {
        jobGroupID: "",
        clientname: "",
        workOrderNo: "",
        title: "",
        jobGroupNumber: "",
      },
      formValid: false,
      submitSuccess: true,
      detailListValid: true,
      currentStatus: "",
      errors: {},
      agentCodeValid: true,
      driverNameValid: true,
      deliveryDateValid: true,
      deliveryTimeValid: true,
      shipmentTypeValid: true,
      descriptionValid: true,
      zipCodeValid: true,
      contactPersonNameValid: true,
      contactNumberValid: true,
      countryValid: true,
      deliverFromAddressValid: true,
      deliveryToAddressValid: true,
      deliveryNoteAddress2Valid: true,
      deliveryNoteAddress3Valid: true,
      remarksValid: true,
      statusValid: true,
      workOrderList: [],
      deliveryNoteDetailList: [],
      editableDeliveryNoteDetailList: [],
      resetList: [],
      statusList: [],
      countryList: [],
      shipmentTypeList: [],
      showPopup: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleShowPopup = () => {
    this.setState({
      showPopup: true,
    });
  };

  handleHidePopup = () => {
    this.setState({
      showPopup: false,
    });
  };

  componentDidMount() {
    const dispatch = this.context[1];
    let deliveryNoteID = this.props.match.params.deliveryNoteId;
    this.props.handleSettingHeader("Delivery Note");
    this.props.setSelectedList("genericSystemOpen", "deliveryNote");
    this.props.handleDrawerClose();
    getDeliveryNote(deliveryNoteID).then((r) => {
      dispatch({
        type: "getDeliveryNote",
        newStore: r,
      });
      let newList = r;
      newList.deliveryByTime =
        r.deliveryTime && moment(r.deliveryTime, "HH:mm:ss").toLocaleString();
      newList.status = newList.statusList && newList.statusList[0];
      newList.shipmentType = newList.shipmentList && newList.shipmentList[0];
      newList.country = newList.countryList && newList.countryList[0];
      this.setState({ deliveryNote: newList, currentStatus: newList.status });
      this.validateField("agentCode", newList["agentCode"]);
      this.validateField("driverName", newList["driverName"]);
      this.setState({ formValid: false });
    });
    getDeliveryStatusList().then((r) => {
      dispatch({
        type: "getDeliveryStatusList",
        newStore: r.data,
      });
      this.getAllStatus(r.data);
    });
    getShipmentTypeList().then((r) => {
      dispatch({
        type: "getShipmentTypeList",
        newStore: r.data,
      });
      //this.setState({statusList: r.data});
      this.getAllShipmentTypes(r.data);
    });
    getCountryDropdownList().then((r) => {
      dispatch({
        type: "getCountryDropdownList",
        newStore: r.data,
      });
      //this.setState({statusList: r.data});
      this.getAllCountries(r.data);
    });
    getDeliveryNoteDetails(deliveryNoteID).then((r) => {
      dispatch({
        type: "getDeliveryNoteDetails",
        newStore: r.data,
      });
      this.getAllDeliveryNoteDetails(r.data);
    });
  }
  getAllStatus = (list) => {
    this.setState({
      statusList:
        list &&
        list
          .filter((r) => r.value !== "DCan")
          .sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
    });
  };
  getAllCountries = (list) => {
    this.setState({
      countryList:
        list &&
        list.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };
  getAllShipmentTypes = (list) => {
    this.setState({
      shipmentTypeList:
        list &&
        list.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };
  getAllDeliveryNoteDetails = (deliveryNoteDetail) => {
    let list = deliveryNoteDetail;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return d.isActive
          ? rows.push(
            createData(
              false,
              true,
              d.stockDescription,
              d.noOfPts,
              d.totalPackage,
              d.noOfCopies,
              d.totalQuantity,
              d.weightPerCortanBox &&
              this.addZeroes(d.weightPerCortanBox.toString(), 3),
              d.netWeight && this.addZeroes(d.netWeight.toString(), 3),
              true,
              localStorage.loggedInId
            )
          )
          : d;
      });
    this.setState({
      editableDeliveryNoteDetailList: rows,
      deliveryNoteDetailList: rows,
      resetList: rows,
    });
    this.detailListValid(rows);
  };
  _onKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  checkWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  handleClickButton = (e, name) => {
    let newList = [...this.state.editableDeliveryNoteDetailList];
    //let newList2 = [...this.state.deliveryNoteDetailList];
    newList.push({});
    if (name === "add") {
      newList[newList.length - 1].editClicked = true;
      newList[newList.length - 1].validRecord = false;
      newList[newList.length - 1].stockDescription = "";
      newList[newList.length - 1].noOfPts = 0;
      newList[newList.length - 1].totalPackage = 0;
      newList[newList.length - 1].noOfCopies = 0;
      newList[newList.length - 1].totalQuantity = 0;
      newList[newList.length - 1].weightPerCortanBox = 0;
      newList[newList.length - 1].netWeight = 0;
      newList[newList.length - 1].isActive = true;
      newList[newList.length - 1].createdBy = localStorage.loggedInId;
      //newList2.push(newList[newList.length - 1]);
      this.setState(
        {
          //deliveryNoteDetailList: newList2,
          editableDeliveryNoteDetailList: newList,
          disableAdd: true,
          disableEdit: false,
        },
        () => {
          document.getElementById(`tableRow${newList.length - 1}`).focus();
        }
      );
    } else {
      this.setState({
        deliveryNoteDetailList: this.state.resetList,
        editableDeliveryNoteDetailList: this.state.resetList,
        disableAdd: false,
        disableEdit: true,
      });
      this.DetailsValid(this.state.resetList);
    }
    this.validateField("", "");
  };
  onEditClicked = (e, i) => {
    let newList = clone(this.state.editableDeliveryNoteDetailList);
    newList[i].editClicked = true;
    this.setState({ editableDeliveryNoteDetailList: newList });
    this.validateField("", "");
  };

  onDeleteClicked = (e, i, row) => {
    let newList = clone(this.state.deliveryNoteDetailList);
    newList[i].isActive = false;
    newList[i].editClicked = false;
    this.setState({ deliveryNoteDetailList: newList });
    newList.splice(i, 1);
    this.setState({ editableDeliveryNoteDetailList: newList });
    this.detailListValid(newList);
    this.validateField(e.target.name, e.target.value);
  };

  onDoneClicked = (e, i, record) => {
    let newList = [...this.state.editableDeliveryNoteDetailList];
    newList[i] = record;
    // newList[i].netWeight =
    //   newList[i].totalQuantity &&
    //   newList[i].weightPerCortanBox &&
    //   parseFloat(newList[i].weightPerCortanBox) *
    //   parseInt(newList[i].totalQuantity);
    // newList[i].netWeight = this.addZeroes(newList[i].netWeight.toString(), 3);
    newList[i].editClicked = false;
    this.setState({
      editableDeliveryNoteDetailList: newList,
      deliveryNoteDetailList: newList,
      disableAdd: false,
    });
    this.DetailsValid(newList);
    this.validateField(e.target.name, e.target.value);
  };

  DetailsValid = (List) => {
    let valid = true;

    List.forEach((element) => {
      if (
        this.state.deliveryNote.status.text === "Item Delivered" &&
        (element.noOfCopies === "" ||
          element.noOfCopies === null ||
          parseInt(element.noOfCopies) === 0 ||
          element.weightPerCortanBox === "" ||
          element.weightPerCortanBox === null ||
          parseFloat(element.weightPerCortanBox) === 0)
      ) {
        valid = false;
      }
    });
    this.setState({ detailListValid: valid, formValid: valid });
  };

  onCancelClicked = (e, i, record) => {
    let newList = clone(this.state.editableDeliveryNoteDetailList);
    let newList2 = clone(this.state.deliveryNoteDetailList);
    newList = newList2;
    this.setState({
      editableDeliveryNoteDetailList: newList,
      disableAdd: false,
    });
    this.DetailsValid(newList);
    this.validateField("", "");
  };

  handleWeightPerCartonChange = (event, index) => {
    let values = event.target.value;
    values = this.checkWeightDec(event.target.value.toString());
    let newEditableList = clone(this.state.editableDeliveryNoteDetailList);
    newEditableList[index][event.target.name] = values;
    // newEditableList[index].netWeight =
    //   newEditableList[index].weightPerCortanBox &&
    //   newEditableList[index].totalQuantity &&
    //   parseFloat(newEditableList[index].weightPerCortanBox) *
    //   parseInt(newEditableList[index].totalQuantity);
    // newEditableList[index].netWeight = parseFloat(
    //   this.addZeroes(newEditableList[index].netWeight.toString(), 3)
    // ).toFixed(3);

    if (
      newEditableList[index].description !== "" &&
      newEditableList[index].totalPackage !== "" &&
      newEditableList[index].totalQuantity !== ""
    ) {
      newEditableList[index].validRecord = true;
    } else {
      newEditableList[index].validRecord = false;
    }
    this.setState({ editableDeliveryNoteDetailList: newEditableList });
    //this.validateField(event.target.name, event.target.value);
  };

  handleRowChange = (event, index) => {
    let newEditableList = clone(this.state.editableDeliveryNoteDetailList);
    newEditableList[index][event.target.name] = event.target.value;

    if (event.target.name === "totalPackage") {
      newEditableList[index].totalQuantity = +event.target.value * newEditableList[index].noOfCopies
      newEditableList[index].netWeight = +event.target.value * newEditableList[index].weightPerCortanBox
    }
    if (event.target.name === "noOfCopies") {
      newEditableList[index].totalQuantity = +event.target.value * newEditableList[index].totalPackage
    }

    if (event.target.name === "weightPerCortanBox") {
      newEditableList[index].netWeight = +event.target.value * newEditableList[index].totalPackage
    }


    // if (event.target.name === "totalQuantity") {
    //   newEditableList[index].netWeight =
    //     newEditableList[index].weightPerCortanBox &&
    //     newEditableList[index].totalQuantity &&
    //     parseFloat(newEditableList[index].weightPerCortanBox) *
    //     parseInt(newEditableList[index].totalQuantity);
    //   newEditableList[index].netWeight = parseFloat(
    //     this.addZeroes(newEditableList[index].netWeight.toString(), 3)
    //   ).toFixed(3);
    // }
    if (
      newEditableList[index].description !== "" &&
      newEditableList[index].totalPackage !== "" &&
      newEditableList[index].totalQuantity !== ""
    ) {
      newEditableList[index].validRecord = true;
    } else {
      newEditableList[index].validRecord = false;
    }
    this.setState({ editableDeliveryNoteDetailList: newEditableList });
    //this.validateField(event.target.name, event.target.value);
  };

  handleChange = (event) => {
    const { deliveryNote } = this.state;
    if (event.target.name === "isActive") {
      deliveryNote[event.target.name] = event.target.checked;
    } else {
      deliveryNote[event.target.name] = event.target.value;
    }
    this.setState({ deliveryNote });

    this.validateField(event.target.name, event.target.value);
  };
  handlePriorityChange = (name) => (event) => {
    const { deliveryNote } = this.state;
    deliveryNote[name] = event.target.checked;
    this.setState({ deliveryNote });
    this.validateField(event.target.name, event.target.value);
  };
  handleCountryChange = (event, values) => {
    const { deliveryNote } = this.state;
    if (values === null) {
      deliveryNote["country"] = "null";
      deliveryNote["countryID"] = "";
    } else {
      let countryList = values;
      deliveryNote["country"] = countryList;
      deliveryNote["countryID"] = countryList.id;
    }
    this.setState({ deliveryNote });

    this.validateField("countryID", deliveryNote["countryID"]);
  };
  handleStatusChange = (event, values) => {
    const { deliveryNote, deliveryNoteDetailList, currentStatus } = this.state;
    let tempList = [...deliveryNoteDetailList];
    if (values === null) {
      deliveryNote["status"] = currentStatus;
    } else {
      let status = values;
      deliveryNote["status"] = status;
      if (status.text === "Item Delivered") {
        //TODO - Check details table
        //tempList && tempList.map(r=>(r.weightPerCortanBox>0 && r.netWeight>0 && r.noOfCopies>0)?r.validRecord=false:r.validRecord=true);
      }
    }
    this.validateField("agentCode", deliveryNote["agentCode"]);
    this.validateField("driverName", deliveryNote["driverName"]);
    this.setState({ deliveryNote, deliveryNoteDetailList: tempList });
    this.DetailsValid(tempList);
    this.validateField("status", deliveryNote["status"]);
  };
  onBlur = (event, index) => {
    const { editableDeliveryNoteDetailList } = this.state;
    let values = event.target.value;
    values = this.addZeroes(values.toString(), 3);
    editableDeliveryNoteDetailList[index][event.target.name] = values;
    this.setState({ editableDeliveryNoteDetailList });
  };
  addZeroes(num, decimalPlaces) {
    var value = Number(num);
    var res = num.split(".");
    if (res.length === 1 || res[1].length < decimalPlaces) {
      value = value.toFixed(decimalPlaces);
    }
    if (value !== "") {
      value = String(value);
    }
    return value;
  }
  handleShipmentChange = (event, values) => {
    const { deliveryNote } = this.state;
    if (values === null) {
      deliveryNote["shipmentType"] = "null";
    } else {
      let shipmentType = values;
      deliveryNote["shipmentType"] = shipmentType;
    }
    this.setState({ deliveryNote });

    this.validateField("shipmentType", deliveryNote["shipmentType"]);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let agentCodeValid = this.state.agentCodeValid;
    let driverNameValid = this.state.driverNameValid;
    let deliveryDateValid = this.state.deliveryDateValid;
    let deliveryTimeValid = this.state.deliveryTimeValid;
    let shipmentTypeValid = this.state.shipmentTypeValid;
    let deliverFromAddressValid = this.state.deliverFromAddressValid;
    let deliveryToAddressValid = this.state.deliveryToAddressValid;
    let deliveryNoteAddress2Valid = this.state.deliveryNoteAddress2Valid;
    let deliveryNoteAddress3Valid = this.state.deliveryNoteAddress3Valid;
    let remarksValid = this.state.remarksValid;
    let statusValid = this.state.statusValid;
    let descriptionValid = this.state.descriptionValid;
    let contactPersonNameValid = this.state.contactPersonNameValid;
    let contactNumberValid = this.state.contactNumberValid;
    let zipCodeValid = this.state.zipCodeValid;
    let countryValid = this.state.countryValid;
    let currentStatus =
      this.state.deliveryNote.status && this.state.deliveryNote.status.text;
    switch (fieldName) {
      case "agentCode":
        if (value !== "") {
          agentCodeValid = validateNotRequired100(value);
          this.setState({
            agentCodeValid,
          });
          fieldValidationErrors.agentCode = agentCodeValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          if (currentStatus !== "In Progress") {
            this.setState({ agentCodeValid: false });
            fieldValidationErrors.agentCode = " Please enter Agent Code";
          } else {
            this.setState({ agentCodeValid: true });
            fieldValidationErrors.agentCode = "";
          }
        }
        break;
      case "driverName":
        if (value !== "") {
          driverNameValid = validateNotRequired100(value);
          this.setState({
            driverNameValid,
          });
          fieldValidationErrors.driverName = driverNameValid
            ? ""
            : resourceValidation.validName;
        } else {
          if (currentStatus !== "In Progress") {
            this.setState({ driverNameValid: false });
            fieldValidationErrors.driverName = " Please enter Driver Name";
          } else {
            this.setState({ driverNameValid: true });
            fieldValidationErrors.driverName = "";
          }
        }
        break;
      case "description":
        if (value !== "") {
          descriptionValid = validateNotRequired100(value);
          fieldValidationErrors.description = descriptionValid
            ? ""
            : resourceValidation.ValidDescription;
        } else {
          descriptionValid = false;
          fieldValidationErrors.description = "";
        }
        break;
      case "contactPersonName":
        if (value !== "") {
          contactPersonNameValid = validateNotRequired100(value);
          fieldValidationErrors.contactPersonName = contactPersonNameValid
            ? ""
            : resourceValidation.ValidName;
          this.setState({ contactPersonNameValid })
        } else {
          contactPersonNameValid = false;
          fieldValidationErrors.contactPersonName = "";
          this.setState({ contactPersonNameValid })
        }
        break;
      case "contactNumber":
        if (value !== "") {
          contactNumberValid = validatePhoneNo(value);
          fieldValidationErrors.contactNumber = contactNumberValid
            ? ""
            : resourceValidation.validContact;
          this.setState({ contactNumberValid })
        } else {
          contactNumberValid = true;
          fieldValidationErrors.contactNumber = "";
          this.setState({ contactNumberValid })
        }
        break;
      case "zipCode":
        if (value !== "") {
          zipCodeValid = validateZipCode(value);
          fieldValidationErrors.zipCode = zipCodeValid
            ? ""
            : resourceValidation.validZipCode;

          this.setState({ zipCodeValid })
        } else {
          zipCodeValid = false;
          fieldValidationErrors.zipCode = "";
          this.setState({ zipCodeValid })
        }
        break;
      case "countryID":
        if (value) {
          countryValid = true;
          this.setState({ countryValid })
        } else {
          countryValid = false;
          this.setState({ countryValid })
        }
        break;
      case "shipmentType":
        if (value !== "null") {
          shipmentTypeValid = true;
          fieldValidationErrors.shipmentType = shipmentTypeValid
            ? ""
            : " Please select Shipment Type";
        } else {
          shipmentTypeValid = false;
          fieldValidationErrors.shipmentType = "";
        }
        break;
      case "status":
        if (value !== "null") {
          statusValid = true;
          fieldValidationErrors.status = statusValid
            ? ""
            : " Please select Status";
        } else {
          statusValid = false;
          fieldValidationErrors.status = "";
        }
        break;
      case "deliverFromAddress":
        if (value !== "") {
          deliverFromAddressValid = validateNotRequired100(value);
          fieldValidationErrors.deliverFromAddress = deliverFromAddressValid
            ? ""
            : resourceValidation.validAddress;
        } else {
          deliverFromAddressValid = false;
          fieldValidationErrors.deliverFromAddress = "";
        }
        break;
      case "deliveryToAddress":
        if (value !== "") {
          deliveryToAddressValid = validateNotRequired100(value);
          fieldValidationErrors.deliveryToAddress = deliveryToAddressValid
            ? ""
            : resourceValidation.validZipCode;
          this.setState({ deliveryToAddressValid })
        } else {
          deliveryToAddressValid = false;
          fieldValidationErrors.deliveryToAddress = "";
          this.setState({ deliveryToAddressValid })
        }
        break;
      case "deliveryNoteAddress2":
        if (value !== "") {
          deliveryNoteAddress2Valid = validateNotRequired100(value);
          fieldValidationErrors.deliveryNoteAddress2 = deliveryNoteAddress2Valid
            ? ""
            : resourceValidation.validAddress;
        } else {
          deliveryNoteAddress2Valid = true;
          fieldValidationErrors.deliveryNoteAddress2 = "";
        }
        break;
      case "deliveryNoteAddress3":
        if (value !== "") {
          deliveryNoteAddress3Valid = validateNotRequired100(value);
          fieldValidationErrors.deliveryNoteAddress3 = deliveryNoteAddress3Valid
            ? ""
            : resourceValidation.validAddress;
        } else {
          deliveryNoteAddress3Valid = true;
          fieldValidationErrors.deliveryNoteAddress3 = "";
        }
        break;
      case "remarks":
        if (value !== "") {
          remarksValid = validateNotRequired100(value);
          fieldValidationErrors.remarks = remarksValid
            ? ""
            : resourceValidation.validRemarks;
        } else {
          remarksValid = true;
          fieldValidationErrors.remarks = "";
        }
        break;
      case "deliveryDate":
        if (value !== null) {
          deliveryDateValid =
            value.toString() === "Invalid Date" ? false : true;
          fieldValidationErrors.deliveryDate = deliveryDateValid
            ? ""
            : " Invalid Date Format";
        } else {
          deliveryDateValid = false;
          fieldValidationErrors.deliveryDate = "";
        }
        break;
      case "deliveryTime":
        if (value !== null) {
          deliveryTimeValid =
            value.toString() === "Invalid Date" ? false : true;
          fieldValidationErrors.deliveryByTime = deliveryTimeValid
            ? ""
            : " Invalid Time Format";
        } else {
          deliveryTimeValid = false;
          fieldValidationErrors.deliveryByTime = "";
        }
        break;
      default:
        break;
    }

    this.setState(
      {
        errors: fieldValidationErrors,
        shipmentTypeValid: shipmentTypeValid,
        //agentCodeValid: agentCodeValid,
        //driverNameValid: driverNameValid,
        remarksValid: remarksValid,
        deliverFromAddressValid: deliverFromAddressValid,
        // deliveryToAddressValid: deliveryToAddressValid,
        deliveryDateValid: deliveryDateValid,
        deliveryTimeValid: deliveryTimeValid,
        statusValid: statusValid,
        descriptionValid: descriptionValid,
        // contactPersonNameValid: contactPersonNameValid,
        // contactNumberValid: contactNumberValid,
        // zipCodeValid: zipCodeValid,
        // countryValid: countryValid,
        submitSuccess: false,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.shipmentTypeValid &&
        this.state.agentCodeValid &&
        this.state.remarksValid &&
        this.state.driverNameValid &&
        this.state.deliverFromAddressValid &&
        this.state.deliveryToAddressValid &&
        this.state.deliveryDateValid &&
        this.state.deliveryTimeValid &&
        this.state.statusValid &&
        this.state.descriptionValid &&
        this.state.deliveryNoteAddress2Valid &&
        this.state.deliveryNoteAddress3Valid &&
        this.state.contactPersonNameValid &&
        this.state.contactNumberValid &&
        this.state.zipCodeValid &&
        this.state.countryValid &&
        this.detailListValid(),
    });
    return this.state.formValid;
  }
  detailListValid() {
    let valid = false;
    const { deliveryNoteDetailList } = this.state;
    if (deliveryNoteDetailList.length > 0) {
      valid = true;
      deliveryNoteDetailList.map((r) => (valid = valid && r.validRecord));
      valid = valid && this.state.detailListValid ? true : false;
    }
    return valid;
  }
  handleDateChange = (date) => {
    const { deliveryNote } = this.state;
    deliveryNote["deliveryDate"] = date && date.toLocaleDateString();
    this.setState({ deliveryNote });
    this.validateField("deliveryDate", date && date.toLocaleDateString());
  };
  handleTimeChange = (time) => {
    const { deliveryNote } = this.state;
    deliveryNote["deliveryByTime"] = time && time;
    this.setState({ deliveryNote });
    this.validateField("deliveryByTime", time && time);
  };
  handleClose = () => {
    // clean up state and errors
    this.setState({
      shipmentType: "",
      isUrgent: "",
      deliverFromAddress: "",
      deliveryToAddress: "",
      deliveryDate: null,
      deliveryByTime: null,
      agentCode: "",
      driverName: "",
      remarks: "",
      errors: {},
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let deliveryNoteID = this.props.match.params.deliveryNoteId;
    if (this.validateForm()) {
      const { deliveryNote } = this.state;
      let shipmentTypeID = deliveryNote.shipmentType.id;
      let workOrderID = deliveryNote.workOrderID;
      let isUrgent = deliveryNote.isUrgent;
      let deliverFromAddress = deliveryNote.deliverFromAddress;
      let deliveryToAddress = deliveryNote.deliveryToAddress;
      let deliveryDate = deliveryNote.deliveryDate && deliveryNote.deliveryDate;
      let deliveryTime =
        // moment(deliveryNote.deliveryByTime) &&
        // moment(deliveryNote.deliveryByTime).hours() +
        // ":" +
        // moment(deliveryNote.deliveryByTime).minutes() +
        // ":" +
        // moment(deliveryNote.deliveryByTime).seconds();
        moment(deliveryNote.deliveryByTime).format("HH:mm")

      let remarks = deliveryNote.remarks;
      let statusID = deliveryNote.status.id;
      let agentCode = deliveryNote.agentCode;
      let driverName = deliveryNote.driverName;
      let modifiedBy = localStorage.loggedInId;
      let isActive = true;
      let totalQuantity = 0;
      let deliveryNoteDetailList = this.state.deliveryNoteDetailList;
      deliveryNoteDetailList.map(
        (r) =>
          (totalQuantity = parseInt(totalQuantity) + parseInt(r.totalQuantity))
      );

      let deliveryNoteAddress2 = deliveryNote.deliveryNoteAddress2;
      let deliveryNoteAddress3 = deliveryNote.deliveryNoteAddress3;
      let description = deliveryNote.description;
      let contactPersonName = deliveryNote.contactPersonName;
      let contactNumber = deliveryNote.contactNumber;
      let zipCode = deliveryNote.zipCode;
      let countryID = deliveryNote.countryID;

      let reqBody = {
        workOrderID,
        shipmentTypeID,
        isUrgent,
        deliverFromAddress,
        deliveryToAddress,
        deliveryNoteAddress2,
        deliveryNoteAddress3,
        description,
        contactPersonName,
        contactNumber,
        zipCode,
        countryID,
        deliveryDate,
        deliveryTime,
        totalQuantity,
        statusID,
        agentCode,
        driverName,
        remarks,
        isActive,
        modifiedBy,
      };
      updateDeliveryNote(deliveryNoteID, reqBody, deliveryNoteDetailList).then(
        (r) => {
          this.setState({ submitSuccess: r });
          if (r === true) {
            this.setState({
              resetList: deliveryNoteDetailList,
              formValid: false,
              currentStatus: deliveryNote.status,
            });
            if (deliveryNote.status.text === "Item Delivered") {
              this.props.history.push("/view_deliveryNote/" + deliveryNoteID);
            }
          }
        }
      );
    }
  };

  render() {
    let { classes } = this.props;
    let {
      deliveryNote,
      statusList,
      disableAdd,
      shipmentTypeList,
      currentStatus,
      countryList,
      editableDeliveryNoteDetailList,
    } = this.state;
    let totalQuantity = 0;

    editableDeliveryNoteDetailList.map(
      (r) =>
        (totalQuantity = r.totalQuantity
          ? parseInt(totalQuantity) + parseInt(r.totalQuantity)
          : totalQuantity)
    );
    let filteredStatusList = [];
    if (currentStatus && currentStatus.text === "In Progress") {
      statusList &&
        statusList
          .filter((r) => r.value === "DOD")
          .map((r) => filteredStatusList.push(r));
    } else if (currentStatus && currentStatus.text === "Out For Delivery") {
      statusList &&
        statusList
          .filter((r) => r.value === "DID")
          .map((r) => filteredStatusList.push(r));
    }
    let currentStatusValue = "";
    currentStatusValue = currentStatus && currentStatus.text;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Delivery Note</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-4%" }}>
              <div className={classes.container} style={{ marginTop: "5%" }}>
                <div style={{ width: "99%" }}>
                  <Typography component="div" className="blueBox">
                    <div className="blueBoxLabel">
                      <div>Customer: </div>
                      <div className="blueBoxText">
                        {deliveryNote.clientname}
                      </div>
                    </div>
                    <div className="blueBoxLabel">
                      <div>Work Order: </div>
                      <div className="blueBoxText">
                        {deliveryNote.workOrderNo}
                      </div>
                    </div>
                    <div className="blueBoxLabel">
                      <div>Project Title: </div>
                      <div className="blueBoxText">{deliveryNote.title}</div>
                    </div>
                    {/* <div className="blueBoxLabel">
                      <div>Job Group: </div>
                      <div className="blueBoxText">
                        {deliveryNote.jobGroupNumber}
                      </div>
                    </div> */}
                  </Typography>
                  <Typography
                    component="div"
                    className="blueBox"
                    style={{
                      marginTop: "2px",
                    }}
                  >
                    <div className="blueBoxLabel">
                      <div>Delivery Note Number: </div>
                      <div className="blueBoxText">
                        {deliveryNote.deliveryNoteNo}
                      </div>
                    </div>
                  </Typography>
                </div>
              </div>
              <form
                style={{ marginTop: "-1%" }}
                className={classes.container}
                onSubmit={this.handleSubmit}
              >
                <div style={{ overflow: "auto" }}>
                  <p className={classes.subHeader}>From Address</p>
                  <div>
                    {/* <TextField
                      required
                      label="Deliver From Address"
                      name="deliverFromAddress"
                      autoComplete="off"
                      margin="normal"
                      inputProps={{
                        readOnly: true,
                      }}
                      className={classes.textField1}
                      value={deliveryNote.deliverFromAddress}
                      onChange={this.handleChange}
                      error={this.state.errors.deliverFromAddress}
                      helperText={
                        this.state.errors.deliverFromAddress
                          ? this.state.errors.deliverFromAddress
                          : ""
                      }
                    /> */}
                    <TextField
                      required
                      multiline={true}
                      rows={1}
                      rowsMax={6}
                      inputProps={{
                        maxLength: 500,
                      }}
                      label="Deliver From Address"
                      name="deliverFromAddress"
                      autoComplete="off"
                      margin="normal"
                      // inputProps={{
                      //   readOnly: true,
                      // }}
                      className={classes.textField}
                      value={deliveryNote.deliverFromAddress}
                      onChange={this.handleChange}
                      error={this.state.errors.deliverFromAddress}
                      helperText={
                        this.state.errors.deliverFromAddress
                          ? this.state.errors.deliverFromAddress
                          : ""
                      }
                    />
                  </div>
                  <p className={classes.subHeader}>To Address</p>
                  <div>
                    <TextField
                      required
                      multiline={false}
                      rows={1}
                      rowsMax={4}
                      label="Company"
                      name="description"
                      inputProps={{
                        maxLength: 200,
                      }}
                      margin="normal"
                      className={classes.textField1}
                      value={deliveryNote.description}
                      onChange={this.handleChange}
                      error={this.state.errors.description}
                      helperText={
                        this.state.errors.description
                          ? this.state.errors.description
                          : ""
                      }
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      required
                      label="Deliver To Address"
                      name="deliveryToAddress"
                      margin="normal"
                      inputProps={{
                        maxLength: 6,
                      }}
                      className={classes.textField}
                      value={deliveryNote.deliveryToAddress}
                      onChange={this.handleChange}
                      error={this.state.errors.deliveryToAddress}
                      helperText={
                        this.state.errors.deliveryToAddress
                          ? this.state.errors.deliveryToAddress
                          : ""
                      }
                    />
                    <TextField
                      label="Deliver To Address 2"
                      name="deliveryNoteAddress2"
                      autoComplete="off"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      value={deliveryNote.deliveryNoteAddress2}
                      onChange={this.handleChange}
                      error={this.state.errors.deliveryNoteAddress2}
                      helperText={
                        this.state.errors.deliveryNoteAddress2
                          ? this.state.errors.deliveryNoteAddress2
                          : ""
                      }
                    />
                    <TextField
                      label="Deliver To Address 3"
                      name="deliveryNoteAddress3"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      autoComplete="off"
                      value={deliveryNote.deliveryNoteAddress3}
                      onChange={this.handleChange}
                      error={this.state.errors.deliveryNoteAddress3}
                      helperText={
                        this.state.errors.deliveryNoteAddress3
                          ? this.state.errors.deliveryNoteAddress3
                          : ""
                      }
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      required
                      label="Zip Code"
                      name="zipCode"
                      margin="normal"
                      inputProps={{
                        maxLength: 6,
                      }}
                      className={classes.textField}
                      value={deliveryNote.zipCode}
                      onChange={this.handleChange}
                      error={this.state.errors.zipCode}
                      helperText={
                        this.state.errors.zipCode
                          ? this.state.errors.zipCode
                          : ""
                      }
                    />
                    <Autocomplete
                      autoComplete
                      includeInputInList
                      id="country"
                      options={countryList}
                      className={classes.autoComplete}
                      onChange={this.handleCountryChange}
                      getOptionLabel={(option) => option.text}
                      value={deliveryNote.country}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Select Country"
                          name="country"
                          id="country"
                          className={classes.autocompleteText}
                          error={this.state.errors.country}
                          // value={deliveryNote.country}
                          helperText={
                            this.state.errors.country
                              ? this.state.errors.country
                              : ""
                          }
                        />
                      )}
                    />
                  </div>
                  <p className={classes.subHeader}>Contact Info</p>
                  <div style={{ display: "flex" }}>
                    <TextField
                      required
                      label="Contact Person Name"
                      name="contactPersonName"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      value={deliveryNote.contactPersonName}
                      onChange={this.handleChange}
                      error={this.state.errors.contactPersonName}
                      helperText={
                        this.state.errors.contactPersonName
                          ? this.state.errors.contactPersonName
                          : ""
                      }
                    />
                    <TextField
                      required
                      label="Contact Number"
                      name="contactNumber"
                      margin="normal"
                      inputProps={{
                        maxLength: 20,
                      }}
                      className={classes.textField}
                      value={deliveryNote.contactNumber}
                      onChange={this.handleChange}
                      error={this.state.errors.contactNumber}
                      helperText={
                        this.state.errors.contactNumber
                          ? this.state.errors.contactNumber
                          : ""
                      }
                    />
                  </div>
                  <p className={classes.subHeader}>Delivery Details</p>
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        disablePast
                        required
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        // minDate={new Date("01-01-2020")}
                        // error={false}
                        // minDateMessage={""}
                        className={classes.textField}
                        id="deliveryDate"
                        label="Deliver By Date"
                        value={deliveryNote.deliveryDate}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={TimeFnsUtils}>
                      <KeyboardTimePicker
                        autoOk
                        required
                        variant="inline"
                        margin="normal"
                        className={classes.textField}
                        id="deliveryByTime"
                        label="Deliver By Time"
                        value={deliveryNote.deliveryByTime}
                        onChange={this.handleTimeChange}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <span >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={deliveryNote.isUrgent}
                            onChange={this.handlePriorityChange("isUrgent")}
                            value="isUrgent"
                            color="primary"
                          />
                        }
                        label="Urgent"
                        className={classes.switchControl}
                      />
                    </span>
                  </div>{" "}
                  <div style={{ display: "flex" }}>
                    <TextField
                      label="Agent Code"
                      name="agentCode"
                      margin="normal"
                      inputProps={{
                        maxLength: 50,
                      }}
                      className={classes.textField}
                      value={deliveryNote.agentCode}
                      onChange={this.handleChange}
                      error={this.state.errors.agentCode}
                      helperText={
                        this.state.errors.agentCode
                          ? this.state.errors.agentCode
                          : ""
                      }
                    />
                    <TextField
                      label="Driver Name"
                      name="driverName"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      value={deliveryNote.driverName}
                      onChange={this.handleChange}
                      error={this.state.errors.driverName}
                      helperText={
                        this.state.errors.driverName
                          ? this.state.errors.driverName
                          : ""
                      }
                    />
                    <Autocomplete
                      autoComplete
                      includeInputInList
                      id="shipmentType"
                      options={shipmentTypeList}
                      className={classes.autoComplete}
                      onChange={this.handleShipmentChange}
                      getOptionLabel={(option) => option.text}
                      value={deliveryNote.shipmentType}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Select Shipment Type"
                          name="shipmentType"
                          id="shipmentType"
                          className={classes.autocompleteText}
                          error={this.state.errors.shipmentType}
                          value={deliveryNote.shipmentType}
                          helperText={
                            this.state.errors.shipmentType
                              ? this.state.errors.shipmentType
                              : ""
                          }
                        />
                      )}
                    />
                  </div>
                  <div>
                    <TextField
                      multiline={true}
                      rows={1}
                      rowsMax={4}
                      label="Special Instructions"
                      name="remarks"
                      inputProps={{
                        maxLength: 500,
                      }}
                      margin="normal"
                      className={classes.textField}
                      value={deliveryNote.remarks}
                      onChange={this.handleChange}
                      error={this.state.errors.remarks}
                      helperText={
                        this.state.errors.remarks
                          ? this.state.errors.remarks
                          : ""
                      }
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <Autocomplete
                      autoComplete
                      includeInputInList
                      id="status"
                      options={filteredStatusList}
                      className={classes.autoComplete}
                      onChange={this.handleStatusChange}
                      getOptionLabel={(option) => option.text}
                      value={deliveryNote.status}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Update Status"
                          name="status"
                          id="status"
                          className={classes.autocompleteText}
                          error={this.state.errors.status}
                          value={deliveryNote.status}
                          helperText={
                            this.state.errors.status
                              ? this.state.errors.status
                              : ""
                          }
                        />
                      )}
                    />
                    <TextField
                      label="Current Status"
                      name="status"
                      margin="normal"
                      inputProps={{
                        readOnly: true,
                      }}
                      className={classes.textField}
                      value={currentStatusValue}
                    />
                  </div>
                  <div style={{ marginTop: "2%" }}>
                    <p className={classes.subHeader}>Delivery Items</p>
                    <div
                      style={{
                        display: "flex",
                        //   width: "100%",
                        border: "1px solid #eaeaea",
                        borderBottom: "none",
                        borderRadius: "4px",
                        margin: "0 .8em 0 .6em",
                      }}
                    >
                      <Typography
                        component="div"
                        style={{
                          width: "100%",
                        }}
                        className="blueBox"
                      >
                        <div className="blueBoxLabel">
                          <div>Total Quantity: </div>
                          <div className="blueBoxText">{totalQuantity}</div>
                        </div>
                      </Typography>
                    </div>
                    <div>
                      <EnhancedTableToolbar
                        //numSelected={selected.length}
                        editableDeliveryNoteDetailList={
                          this.state.editableDeliveryNoteDetailList
                        }
                        resetList={this.state.resetList}
                        handleClickButton={this.handleClickButton}
                        disableAdd={this.state.disableAdd}
                        disableEdit={this.state.disableEdit}
                        validList={this.state.detailListValid}
                      />
                    </div>
                    <div
                      style={{
                        // width: "100%",
                        maxHeight: "700px",
                        margin: "0 .8em 0 .6em",
                      }}
                    >
                      <div
                        // style={{
                        //   maxHeight: 200,
                        //   overflow: "auto",
                        //   border: "1px solid #eaeaea"
                        // }}
                        style={
                          !this.props.clearOpenList
                            ? {
                              overflow: "auto",
                              border: "1px solid #eaeaea",
                              maxWidth: "calc(100vw - 8em)",
                              maxHeight: "calc(100vh - 300px)",
                            }
                            : {
                              overflow: "auto",
                              border: "1px solid #eaeaea",
                              maxWidth: "calc(100vw - 330px)",
                              maxHeight: "calc(100vh - 300px)",
                            }
                        }
                      >
                        {/* //overflow: "auto" */}
                        <Table
                          aria-labelledby="tableTitle"
                          size="small"
                          stickyHeader
                          aria-label="sticky table"
                        >
                          {/* <caption style={{ borderBottom: "1px solid #eaeaea" }}>
                  A barbone structure table example with a caption
                </caption> */}
                          <EnhancedTableHead
                          //numSelected={selected.length}
                          // order={order}
                          // orderBy={orderBy}
                          // onSelectAllClick={e => this.handleSelectAllClick(e)}
                          //  onRequestSort={this.handleRequestSort}
                          //  rowCount={departmentList.length}
                          />

                          <TableBody style={{ overflow: "hidden" }}>
                            {editableDeliveryNoteDetailList.map(
                              (row, index) => {
                                // const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    // hover
                                    // onClick={event =>
                                    //   this.handleClick(event, row.id, row)
                                    // }
                                    role="checkbox"
                                    //  aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    id={`tableRow${index}`}
                                  //   selected={isItemSelected}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                    >
                                      {index + 1}
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="left"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {editableDeliveryNoteDetailList[index]
                                          .editClicked ? (
                                            <Input
                                              defaultValue=""
                                              inputProps={{
                                                "aria-label": "description",
                                                maxLength: 200,
                                              }}
                                              name="stockDescription"
                                              style={{ width: "100%" }}
                                              value={row.stockDescription}
                                              onChange={(e) =>
                                                this.handleRowChange(e, index)
                                              }
                                            />
                                          ) : (
                                            row.stockDescription
                                          )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {editableDeliveryNoteDetailList[index]
                                          .editClicked ? (
                                            <Input
                                              defaultValue=""
                                              inputProps={{
                                                "aria-label": "description",
                                                maxLength: 10,
                                              }}
                                              name="noOfPts"
                                              onKeyPress={this._onKeyPress}
                                              style={{ width: "100%" }}
                                              value={row.noOfPts}
                                              onChange={(e) =>
                                                this.handleRowChange(e, index)
                                              }
                                            />
                                          ) : (
                                            row.noOfPts
                                          )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {editableDeliveryNoteDetailList[index]
                                          .editClicked ? (
                                            <Input
                                              defaultValue=""
                                              inputProps={{
                                                "aria-label": "description",
                                                maxLength: 10,
                                              }}
                                              name="totalPackage"
                                              onKeyPress={this._onKeyPress}
                                              style={{ width: "100%" }}
                                              value={row.totalPackage}
                                              onChange={(e) =>
                                                this.handleRowChange(e, index)
                                              }
                                            />
                                          ) : (
                                            row.totalPackage
                                          )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {editableDeliveryNoteDetailList[index]
                                          .editClicked ? (
                                            <Input
                                              defaultValue=""
                                              inputProps={{
                                                "aria-label": "description",
                                                maxLength: 10,
                                              }}
                                              name="noOfCopies"
                                              onKeyPress={this._onKeyPress}
                                              style={{ width: "100%" }}
                                              value={row.noOfCopies}
                                              onChange={(e) =>
                                                this.handleRowChange(e, index)
                                              }
                                            />
                                          ) : (
                                            row.noOfCopies
                                          )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.totalQuantity}
                                        {/* {editableDeliveryNoteDetailList[index]
                                          .editClicked ? (
                                            <Input
                                              defaultValue=""
                                              inputProps={{
                                                "aria-label": "description",
                                                maxLength: 10,
                                              }}
                                              name="totalQuantity"
                                              onKeyPress={this._onKeyPress}
                                              style={{ width: "100%" }}
                                              value={row.totalQuantity}
                                              onChange={(e) =>
                                                this.handleRowChange(e, index)
                                              }
                                            />
                                          ) : (
                                            row.totalQuantity
                                          )} */}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {editableDeliveryNoteDetailList[index]
                                          .editClicked ? (
                                            <Input
                                              defaultValue=""
                                              inputProps={{
                                                "aria-label": "description",
                                                maxLength: 10,
                                              }}
                                              name="weightPerCortanBox"
                                              onKeyPress={this._onDecimalKeyPress}
                                              style={{ width: "100%" }}
                                              value={row.weightPerCortanBox}
                                              onBlur={(e) =>
                                                this.onBlur(e, index)
                                              }
                                              onChange={(e) =>
                                                this.handleRowChange(e, index)
                                              }
                                            />
                                          ) : (
                                            row.weightPerCortanBox
                                          )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {/* {" "}
                                        {editableDeliveryNoteDetailList[index]
                                          .editClicked ? (
                                          <Input
                                            defaultValue=""
                                            inputProps={{
                                              "aria-label": "description",
                                              maxLength: 10
                                            }}
                                            name="netWeight"
                                            onKeyPress={this._onDecimalKeyPress}
                                            style={{ width: "100%" }}
                                            value={row.netWeight}
                                            onChange={e =>
                                              this.handleNetWeightChange(e, index)
                                            }
                                          />
                                        ) : ( */}
                                        {(+row.netWeight).toFixed(3)}
                                        {/* )} */}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="left"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                          display: "flex",
                                        }}
                                      >
                                        {editableDeliveryNoteDetailList[index]
                                          .editClicked ? (
                                            <div>
                                              {row.validRecord ? (
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                  onClick={(e) =>
                                                    this.onDoneClicked(
                                                      e,
                                                      index,
                                                      row
                                                    )
                                                  }
                                                >
                                                  <DoneIcon
                                                    style={{
                                                      color: "#6c6b6b",
                                                    }}
                                                  />
                                                </span>
                                              ) : (
                                                  <React.Fragment></React.Fragment>
                                                )}
                                              <span
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                                onClick={(e) =>
                                                  this.onCancelClicked(
                                                    e,
                                                    index,
                                                    row
                                                  )
                                                }
                                              >
                                                <CloseIcon
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          ) : (
                                            <div>
                                              {!disableAdd ? (
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                  onClick={(e) =>
                                                    this.onEditClicked(e, index)
                                                  }
                                                >
                                                  <EditIcon
                                                    style={{
                                                      color: "#6c6b6b",
                                                    }}
                                                  />
                                                </span>
                                              ) : (
                                                  <React.Fragment></React.Fragment>
                                                )}
                                              <span
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                                onClick={(e) =>
                                                  this.onDeleteClicked(
                                                    e,
                                                    index,
                                                    row
                                                  )
                                                }
                                              >
                                                <DeleteIcon
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          )}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}

                            {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 25 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/deliveryNote")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        onClick={this.handleSubmit}
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          onClick={this.handleSubmit}
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {/* {!this.state.submitSuccess ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        <IconButton aria-label="print" size="small">
                          <CustomPrintIcon />
                        </IconButton>
                        {"PRINT DELIVERY NOTE"}
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                        >
                          <IconButton aria-label="print" size="small">
                            <CustomPrintIcon />
                          </IconButton>
                          {"PRINT DELIVERY NOTE"}
                        </Button>
                      )} */}
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={() => this.handleShowPopup()}
                    >
                      <IconButton
                        aria-label="print"
                        size="small"
                        style={{ color: "#fff" }}
                      >
                        <CustomPrintIcon style={{ color: "#fff" }} />
                      </IconButton>
                      {"PRINT DELIVERY NOTE"}
                    </Button>
                  </div>
                  {this.state.showPopup && (
                    <ReportViewer
                      url={
                        `${window.localStorage.ReportURLByID}` +
                        "DeliveryNote&IsDirectPrint=True&ReportParam=DeliveryNoteID&Param1=" +
                        `${this.props.match.params.deliveryNoteId}`
                      }
                      showPopup={this.state.showPopup}
                      title={"Delivery Note"}
                      onClose={() => this.handleHidePopup()}
                      handleHidePopup={() => this.handleHidePopup()}
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(EditDeliveryNote));
