import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { StateContext } from "../../../shared/globalState";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { resourceValidation } from "../../../shared/resource";
import { getlettershopCode, updateLetterShop } from "../masterActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import { addZeros } from "../../../shared/tableCommonFunctions";
import { addStyles } from "../../../shared/styles";

const checklettershopcode = new Validator("checklettershopcode");
const checkdecimalpoint1 = new Validator("checkdecimalpoint1");
const shouldNotEmpty = new Validator("general");

class EditLetterShop extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      letterShop: {
        letterShopCode: "",
        letterShopName: "",
        chargeType: "",
        unitRate: "0.00",
        isActive: true,
        modifiedBy: 1,
      },
      updatedValues: false,
      errors: {},
      active: false,
      //letterShopCodeValid: false,
      letterShopNameValid: false,
      chargeTypeValid: false,
      unitRateValid: false,
      formValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    let lettershopId = this.props.match.params.letterShopID;
    getlettershopCode(lettershopId).then((r) => {
      let data = r;
      data = {
        letterShopCode: data.letterShopCode,
        letterShopName: data.letterShopName,
        chargeType: data.chargeType,
        unitRate: addZeros(data.unitRate.toString(), 2),
        isActive: data.isActive,
      };

      this.setState({ letterShop: data });
      if (r.isActive) this.setState({ active: r.isActive });
      this.validateField("letterShopCode", r.letterShopCode);
      this.validateField("letterShopName", r.letterShopName);
      this.validateField("chargeType", r.chargeType);
      this.validateField("unitRate", r.unitRate);
    });
  }

  addZeroes(num, decimalPlaces) {
    // Convert input string to a number and store as a variable.
    var value = Number(num);
    // Split the input string into two arrays containing integers/decimals
    var res = num.split(".");
    // If there is no decimal point or only one decimal place found.
    if (res.length === 1 || res[1].length < decimalPlaces) {
      // Set the number to two decimal places
      value = value.toFixed(decimalPlaces);
    }
    // Return updated or original number.
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  checkDHlWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,2})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }

  handleChange = (e) => {
    this.setState({ updatedValues: true });
    const { letterShop } = this.state;
    if (e.target.name === "isActive") {
      letterShop[e.target.name] = e.target.checked;
    } else if (e.target.name === "unitRate") {
      let unitRate = e.target.value;
      unitRate = this.checkDHlWeightDec(unitRate.toString());
      letterShop[e.target.name] = unitRate;
    } else {
      letterShop[e.target.name] = e.target.value;
    }
    this.validateField(e.target.name, e.target.value);
    this.setState({ letterShop });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let letterShopCodeValid = this.state.letterShopCodeValid;
    let letterShopNameValid = this.state.letterShopNameValid;
    let chargeTypeValid = this.state.chargeTypeValid;
    let unitRateValid = this.state.unitRateValid;
    switch (fieldName) {
      case "letterShopCode":
        if (value.length < 4) {
          letterShopCodeValid = false;
          fieldValidationErrors.letterShopCode = resourceValidation.max4char;
        } else {
          letterShopCodeValid = checklettershopcode(value);
          fieldValidationErrors.letterShopCode = letterShopCodeValid
            ? ""
            : resourceValidation.lettershopcode;
        }
        break;
      case "letterShopName":
        letterShopNameValid = shouldNotEmpty(value);
        fieldValidationErrors.letterShopName = letterShopNameValid
          ? ""
          : resourceValidation.letterShopName;
        break;
      case "chargeType":
        chargeTypeValid = shouldNotEmpty(value);
        fieldValidationErrors.chargeType = chargeTypeValid
          ? ""
          : resourceValidation.chargeType;
        break;
      case "unitRate":
        unitRateValid = checkdecimalpoint1(value);
        fieldValidationErrors.unitRate = unitRateValid
          ? ""
          : resourceValidation.unitrate;
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        letterShopCodeValid: letterShopCodeValid,
        letterShopNameValid: letterShopNameValid,
        chargeTypeValid: chargeTypeValid,
        unitRateValid: unitRateValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.state.updatedValues &&
      this.setState({
        formValid:
          this.state.letterShopCodeValid &&
          this.state.letterShopNameValid &&
          this.state.chargeTypeValid &&
          this.state.unitRateValid,
      });
    return this.state.formValid;
  }

  handleClose = () => {
    // clean up state and errors
    this.setState({
      // letterShopCode: "",
      letterShopName: "",
      chargeType: "",
      unitRate: "",
      errors: {},
    });
  };

  _onKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  onBlur = (event) => {
    const { letterShop } = this.state;
    let letterShopValues = event.target.value;
    letterShopValues = this.addZeroes(letterShopValues.toString(), 2);
    letterShop[event.target.name] = letterShopValues;
    this.setState({ letterShop });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let lettershopId = this.props.match.params.letterShopID;
    if (this.validateForm()) {
      const { letterShop } = this.state;
      let isActive = letterShop.isActive;
      let letterShopName = letterShop.letterShopName;
      let chargeType = letterShop.chargeType;
      let unitRate = letterShop.unitRate;
      letterShop.modifiedBy = localStorage.loggedInId;
      let modifiedBy = letterShop.modifiedBy;
      let reqBody = {
        letterShopName,
        chargeType,
        unitRate,
        modifiedBy,
        isActive,
      };

      updateLetterShop(lettershopId, reqBody, this.props.history);
    }
  };
  render() {
    let { classes } = this.props;
    let { letterShop, active, addZeroes } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Lettershop</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div>
                  <TextField
                    required
                    disabled
                    label="Lettershop Code"
                    name="letterShopCode"
                    inputProps={{
                      maxLength: 10,
                    }}
                    margin="normal"
                    variant="filled"
                    autoComplete="off"
                    className={classes.textField}
                    value={letterShop.letterShopCode}
                    onChange={this.handleChange}
                    error={this.state.errors.letterShopCode}
                    helperText={
                      this.state.errors.letterShopCode
                        ? this.state.errors.letterShopCode
                        : ""
                    }
                  />

                  <TextField
                    inputProps={{
                      maxLength: 50,
                    }}
                    required
                    label="Lettershop Name"
                    name="letterShopName"
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={letterShop.letterShopName}
                    onChange={this.handleChange}
                    error={this.state.errors.letterShopName}
                    helperText={
                      this.state.errors.letterShopName
                        ? this.state.errors.letterShopName
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    inputProps={{
                      maxLength: 50,
                    }}
                    required
                    label="Charge Type"
                    name="chargeType"
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={letterShop.chargeType}
                    onChange={this.handleChange}
                    error={this.state.errors.chargeType}
                    helperText={
                      this.state.errors.chargeType
                        ? this.state.errors.chargeType
                        : ""
                    }
                  />
                  <TextField
                    inputProps={{
                      maxLength: 15,
                    }}
                    label="Unit Rate"
                    name="unitRate"
                    margin="normal"
                    required
                    autoComplete="off"
                    className={classes.textField}
                    value={letterShop.unitRate}
                    onChange={this.handleChange}
                    error={this.state.errors.unitRate}
                    helperText={
                      this.state.errors.unitRate
                        ? this.state.errors.unitRate
                        : ""
                    }
                    onBlur={this.onBlur}
                    onKeyPress={this._onKeyPress}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">S$</InputAdornment>
                      ),
                    }}
                  />
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      name="isActive"
                      className={classes.checkBox}
                      margin="normal"
                      control={
                        <Checkbox
                          color="primary"
                          checked={letterShop.isActive}
                          onChange={this.handleChange}
                          value={letterShop.isActive}
                        />
                      }
                      label="Active"
                    />
                  )}
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/letterShop")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(EditLetterShop));
