import React, { Component } from "react";

class ProductionReport extends Component {
  state = {};
  componentDidMount() {
    this.props.handleSettingHeader("Production Report");
    this.props.setSelectedList("masterDataOpen", "ProductionReport");
  }
  render() {
    return (
      <div>
      <iframe
        style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
        src={`${window.localStorage.ReportURLByID}` +"ProductionReport"}
        />
      </div>
    );
  }
}

export default ProductionReport;
