import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import * as XLSX from "xlsx";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import clone from "clone";
import Validator from "../../../shared/validator";
import { Autocomplete } from "@material-ui/lab";
import MenuItem from "@material-ui/core/MenuItem";
import toastr from "toastr"
import {
  getPublisherCustomerList,
  uploadeMLOPFiles,
  deleteMLOPFiles,
  deleteSnigleMLOPFiles,
  uploadeMLOPFilesOnProceed
} from "./action";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import DescriptionIcon from "@material-ui/icons/Description";
import GetAppIcon from "@material-ui/icons/GetApp";
import { getPubCustomerList } from "../../masters/masterActions";
//import {getPublisherCustomerList} from "./actions"
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
const validateMaxLength2 = new Validator("maxlength2");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    // marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: "23%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    // marginLeft: "7px",
    // marginLeft: "1.5em",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
});
function DetectionControl(props) {
  return (
    <div
      style={{
        width: "45%",
        display: "flex",
        alignItems: "center",
        marginTop: "-1px",
      }}
    >
      <span>
        {props.fileName &&
          props.fileName.isValid &&
          !props.fileName.exceptionMessage ? (
            <CheckCircleIcon
              style={{ color: "rgba(59, 218, 182, 0.87)", fontSize: "19px" }}
            />
          ) : (
            <InfoIcon
              style={
                props.fileName &&
                  props.fileName.exceptionMessage &&
                  props.fileName.exceptionMessage.includes("already")
                  ? { color: "#f9a60e", fontSize: "19px" }
                  : { color: "red", fontSize: "19px" }
              }
            />
          )}
      </span>
      <span
        style={{
          marginTop: "-5px",
          marginLeft: "5px",
          fontSize: "11px",
          display: "flex",
        }}
      >
        {props.fileName.isValid && !props.fileName.exceptionMessage ? (
          "Uploaded Successfully"
        ) : props.fileName &&
          props.fileName.exceptionMessage &&
          props.fileName.exceptionMessage.includes("already") ? (
              <span style={{ display: "flex" }}>
                {props.fileName.exceptionMessage} Do you want to overwrite ?
                <div>
                  <label
                    className="upload excel"
                    style={{ cursor: "pointer", marginLeft: ".5em" }}
                  >
                    {/* <input
                  onChange={(e) =>
                    props.handleAlreadyExistUpload(
                      e,
                      props.id,
                      props.fileName.mlopHeaderID
                    )
                  }
                  type="file"
                  style={{ display: "none" }}
                  accept={
                    props.customerId && props.customerId.value !== "Npub_Mac"
                      ? ".txt"
                      : ".xlsx" || ".csv"
                  }
                />{" "} */}
                    <span
                      onClick={e => props.onProceedClicked(e,
                        props.id,
                        props.fileName.mlopHeaderID)}
                      style={{
                        background: "#3982ea",
                        padding: ".5em 1em",
                        borderRadius: "4px",
                        color: "#fff",
                      }}
                    >
                      Proceed
                </span>
                  </label>
                </div>
              </span>
            ) : (
              props.fileName.exceptionMessage
            )}
      </span>
    </div>
  );
}
class AddMLOP extends React.Component {
  constructor() {
    super();

    this.state = {
      //upload:false,
      customersList: [],
      customer: {},
      customerId: 0,
      insert: "",
      acronym: "",
      vol: "",
      iss: "",
      part: "",
      supp: "",
      issueDesc: "",
      insertName: "",
      type: "",
      insertWeight: "",
      quantity: "",
      noOfpages: "",
      insertSize: "",
      foldingRequired: "",
      applyTo: "",
      recipients: "",
      placement: "",
      oversHandling: "",
      societyInsert: "",
      jdsrInstruction: "",
      contactName: "",
      email: "",
      errors: {},
      ZoneCodeValid: false,
      ZoneNameValid: false,
      successUpload: false,
      stateFiles: [
        // {
        //   customerOrderHeaderID: 104,
        //   orderFileHeaderID: 66,
        //   name: "q1.xlsx",
        //   filePath: "",
        //   message: "",
        //   size: "64.98 KB",
        //   isValid: true,
        //   noOfOrderFiles: 1008,
        //   totalCopies: 2016
        // },
        // {
        //   customerOrderHeaderID: 104,
        //   orderFileHeaderID: 66,
        //   name: "q111112.xlsx",
        //   filePath: "",
        //   message: "Template Mismatch",
        //   size: "64.98 KB",
        //   isValid: false,
        //   noOfOrderFiles: 1008,
        //   totalCopies: 2016
        // }
      ],
      existingFiles: []
    };
  }
  handleAutocompleteCustomer = (e, val) => {
    this.setState({ customerId: val && val.id, customer: val && val, stateFiles: [], });
  };
  componentDidMount() {
    this.props.handleSettingHeader("Main Label Order Placement");
    this.props.setSelectedList(
      "publisherMainLabelOpen",
      "pubMainLabelOrderPlacement"
    );
    getPublisherCustomerList().then((r) => {
      let oldCus = clone(r.data);
      r.data &&
        this.setState({
          customersList: r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
        });
      oldCus &&
        this.setState({ customerId: oldCus[0].id, customer: oldCus[0] });
    });
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleClose = () => {
    // clean up state and errors
    let newZone = { ...this.state.Zone };
    newZone.ZoneCode = "";
    newZone.ZoneName = "";
    this.setState({
      Zone: newZone,
      errors: {},
    });
  };

  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertKeyPress(event) {
    const re = /[a-zA-Z0-9-#]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextKeyPress(event) {
    const re = /[a-z A-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusNumberKeyPress(event) {
    const re = /[0-9 a-zA-Z]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onWeightKeyPress(event) {
    const re = /[0-9 a-zA-Z.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onInsertSizeKeyPress(event) {
    const re = /[0-9 a-zA-Z-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onTextPlusAllPress(event) {
    const re = /[a-z A-Z0-9=.;()&@]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  handleUpload = (e) => {
    //e.preventDefault();
    let files = e.target.files;
    var reader = new FileReader();
    let _error = "";
    // if (document.getElementById("inputId"))
    //   console.log(document.getElementById("inputId").value)
    if (files[0]) {
      this.setState({
        fileName: files[0].name,
        stateFiles: [],
        //disableAdd: true,
        disableEdit: false,
        existingFiles: files
      });
      const formData = new FormData();
      let stf = [];
      let x = false
      let checkInvalidFile = false
      let wsCheckInvalidFile = false
      Array.from(files).map((f, ind) => {
        if (this.state.customer && this.state.customer.value === "Npub_Mac") {
          if (f.name.toLowerCase().endsWith("csv") || f.name.toLowerCase().endsWith("xlsx"))
            checkInvalidFile = false
          else
            checkInvalidFile = true
        }
        else {
          if (f.name.toLowerCase().endsWith("txt"))
            checkInvalidFile = false
          else
            checkInvalidFile = true
          if (this.state.customer && this.state.customer.value === "WS") {
            if (f.name.includes("_"))
              wsCheckInvalidFile = false
            else
              wsCheckInvalidFile = true
          }
          console.log(this.state.customer.value)
        }
      })

      if (checkInvalidFile) {
        toastr.error("Unsupported file format. Upload only accepted file formats")
        return
      }
      // if (wsCheckInvalidFile) {
      //   toastr.error("Unsupported file format. File name must contain '_' ")
      //   return
      // }
      Array.from(files).map((f, ind) => {
        // console.log(f.size, f.size < 1024000)
        // console.log(f.size)
        if ((f.size) <= (1024 * 10000)) {
          if (this.state.customer && this.state.customer.value != "Npub_Mac") {
            // x = true
            // formData.append("Uploadfiles", f);
            // stf.push({ name: f.name, size: f.size, message: "" });
            if (this.state.customer && this.state.customer.value === "WS") {
              if (f.name.includes("_")) {
                x = true
                formData.append("Uploadfiles", f);
                stf.push({ name: f.name, size: f.size, message: "" });
              }
              else {
                toastr.error("Unsupported file format. File name must contain '_' ")
              }
            }
            else {
              if (f.name.toUpperCase().startsWith("70033800ML") ||
                f.name.toUpperCase().startsWith("70036721ML") ||
                f.name.toUpperCase().startsWith("70036709ML") ||
                f.name.toUpperCase().startsWith("SPOM") ||
                f.name.toUpperCase().startsWith("Z001BL") ||
                f.name.toUpperCase().startsWith("SPOB") ||
                f.name.toUpperCase().startsWith("SPHC") ||
                f.name.toUpperCase().startsWith("SPOMQSJP") ||
                f.name.toUpperCase().startsWith("SPOMQSAU")

              ) {

                x = true
                formData.append("Uploadfiles", f);
                stf.push({ name: f.name, size: f.size, message: "" });
              }
              else {
                toastr.error("Unsupported file format. File name is not correct ")
              }
            }
          }
          else {
            if (f.name.endsWith("csv")) {
              if (f.name.startsWith("ID_41401")) {
                x = true
                formData.append("Uploadfiles", f);
                stf.push({ name: f.name, size: f.size, message: "" });
              }
              else {
                if (files && Array.from(files).length > 1)
                  toastr.error(`${f.name} is not allowed. File name must starts with ID_41401 for .csv files `)
              }
            }
            else {
              if (f.name.startsWith("APS")) {
                x = true
                formData.append("Uploadfiles", f);
                stf.push({ name: f.name, size: f.size, message: "" });
              }
              else {
                if (files && Array.from(files).length > 1)
                  toastr.error(`${f.name} is not allowed. File name must starts with APS for .xlsx files`)
              }
            }
          }
        }
        else {
          if (files && Array.from(files).length > 1)
            toastr.error(`${f.name} can not be processed. Max file size limit is 10 MB`)

        }
      });
      if (!x && files && Array.from(files).length === 1) {
        let msg = "Max file size limit is 10 MB"
        if (this.state.customer && this.state.customer.value === "Npub_Mac") {
          if (files[0].name.endsWith("csv")) {
            if (!files[0].name.startsWith("ID_41401"))
              msg = "File name must starts with ID_41401 for .csv files"
          }
          else {
            if (!files[0].name.startsWith("APS"))
              msg = "File name must starts with APS for .xlsx files"
          }
        }
        if (this.state.customer && this.state.customer.value === "WS") {
          if (!files[0].name.includes("_")) {
            msg = "Unsupported file format. File name must contain '_' "

          }

        }
        if (this.state.customer && this.state.customer.value === "Wiley") {
          if (!files[0].name.toUpperCase().startsWith("70033800ML") ||
            !files[0].name.toUpperCase().startsWith("70036721ML") ||
            !files[0].name.toUpperCase().startsWith("70036709ML") ||
            !files[0].name.toUpperCase().startsWith("SPOM") ||
            !files[0].name.toUpperCase().startsWith("Z001BL") ||
            !files[0].name.toUpperCase().startsWith("SPOB") ||
            !files[0].name.toUpperCase().startsWith("SPHC") ||
            !files[0].name.toUpperCase().startsWith("SPOMQSJP") ||
            !files[0].name.toUpperCase().startsWith("SPOMQSAU")
          )
            msg = "Unsupported file format. File name is not correct "

        }
        toastr.error(msg)
        return
      }
      // console.log(this.state)
      if (this.state.customer && this.state.customer.value != "Npub_Mac") {
        reader.onload = function (e) {
          let data = e.target.result;
          let readedData = XLSX.read(data, { type: "binary" });
          const wsname = readedData.SheetNames[0];
          const ws = readedData.Sheets[wsname];
          const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
        };

        reader.readAsBinaryString(files[0]);
      }

      let loggedInId = localStorage.getItem("loggedInId");

      // return
      this.setState({ stateFiles: stf });
      // formData.append("UploadFiles", files[0]);
      formData.append("CustomerID", this.state.customerId);
      formData.append("CreatedBy", loggedInId);
      formData.append("ModifiedBy", loggedInId);
      formData.append(
        "CustomerName",
        this.state.customer &&
        this.state.customer.value
        //  &&
        // this.state.customer.text.split("-")[1].trim()
      );
      this.setState({ loading: true });
      //let reqBody={CustomerID:this.state.customerId,CreatedBy:++loggedInId,ModifiedBy:++loggedInId,CustomerName:"Willy" }

      if (x)
        uploadeMLOPFiles(formData).then((r) => {
          // if (document.getElementById("inputId"))
          //   document.getElementById("inputId").value = "";
          this.setState({ successUpload: false });
          let newList = [];
          // r &&
          //   r.length > 0 &&
          this.setState({ loading: false, stateFiles: r });
          if (r.length > 0) {
            r.map((f) => {
              if (f.isValid && !f.exceptionMessage)
                this.setState({ successUpload: true }, () => {

                });
            });
          }
        });
    }
  };
  onProceedClicked = (e, i, id) => {
    // console.log(this.state)
    // uploadeMLOPFilesOnProceed
    let loggedInId = localStorage.getItem("loggedInId");
    const formData = new FormData();
    this.state.existingFiles.length > 0 &&
      formData.append("Uploadfile", Array.from(this.state.existingFiles)[i]);
    formData.append("CustomerID", this.state.customerId);
    // formData.append("CreatedBy", loggedInId);
    formData.append("ModifiedBy", loggedInId);
    formData.append(
      "CustomerName",
      this.state.customer &&
      this.state.customer.value
      //  &&
      // this.state.customer.text.split("-")[1].trim()
    );
    // this.setState({ loading: true });
    uploadeMLOPFilesOnProceed(formData).then((r) => {

      this.setState({ successUpload: false });
      let newList = [];
      // r &&
      //   r.length > 0 &&
      let newStateFiles = clone(this.state.stateFiles)
      newStateFiles[i] = r
      this.setState({ loading: false, stateFiles: newStateFiles });
      if (r.length > 0) {
        r.map((f) => {
          if (f.isValid && !f.exceptionMessage)
            this.setState({ successUpload: true });
        });
      }
    });
    // if (document.getElementById("inputId"))
    //   document.getElementById("inputId").value = "";

  }
  handleAlreadyExistUpload = (e, i, id) => {
    //e.preventDefault();
    let files = e.target.files;
    var reader = new FileReader();
    let _error = "";

    if (files[0]) {
      //this.setState({
      //   fileName: files[0].name,
      //   //disableAdd: true,
      //   disableEdit: false,
      // });
      reader.onload = function (e) {
        let data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      };

      reader.readAsBinaryString(files[0]);

      let loggedInId = localStorage.getItem("loggedInId");
      const formData = new FormData();

      Array.from(files).map((f) => {
        formData.append("Uploadfiles", f);
      });

      // formData.append("UploadFiles", files[0]);
      formData.append("CustomerID", this.state.customerId);
      formData.append("CreatedBy", loggedInId);
      formData.append("ModifiedBy", loggedInId);
      formData.append(
        "CustomerName",
        this.state.customer &&
        this.state.customer.value
        // &&
        // this.state.customer.text.split("-")[1].trim()
      );
      this.setState({ loading: true });
      //let reqBody={CustomerID:this.state.customerId,CreatedBy:++loggedInId,ModifiedBy:++loggedInId,CustomerName:"Willy" }
      //   if(data.isValid )
      deleteSnigleMLOPFiles(id, loggedInId, "noMessage").then((r) => {
        uploadeMLOPFiles(formData).then((r) => {
          this.setState({ successUpload: false });
          let newList = [];

          let newStFiles = clone(this.state.stateFiles);
          if (r && r.length > 0) newStFiles[i] = r[0];
          this.setState({ loading: false, stateFiles: newStFiles });
          if (r.length > 0) {
            r.map((f) => {
              if (f.isValid && !f.exceptionMessage)
                this.setState({ successUpload: true });
            });
          }
        });
      });
    }
  };
  deleteUploadedFile = (id, i, data) => {
    // if(data.isValid && data.exceptionMessage.length===0)
    let loggedInId = localStorage.getItem("loggedInId");
    if (data.isValid)
      deleteMLOPFiles(id, loggedInId).then((r) => {
        let newStateFile = clone(this.state.stateFiles);
        newStateFile.splice(i, 1);
        this.setState({ stateFiles: newStateFile });
      });
    else {
      let newStateFile = clone(this.state.stateFiles);
      newStateFile.splice(i, 1);
      this.setState({ stateFiles: newStateFile });
    }
    if (this.state.stateFiles.length === 1) {
      document.getElementById("inputId").value = "";
    }
  };
  render() {
    let { classes } = this.props;
    // console.log(this.state.customer)
    let {
      customersList,
      customer,
      insert,
      acronym,
      vol,
      iss,
      part,
      supp,
      issueDesc,
      insertName,
      type,
      insertWeight,
      quantity,
      noOfpages,
      insertSize,
      foldingRequired,
      applyTo,
      recipients,
      placement,
      oversHandling,
      societyInsert,
      jdsrInstruction,
      contactName,
      email,
    } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Upload Order File</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <div className={classes.container}>
                <div>
                  {/* <TextField
                    required
                    select
                    label="Customer"
                    // label="Select Customer"
                    name="customer"
                    className={classes.textField}
                    //value={customer}
                    //onChange={this.handleChange}
                    // error={this.state.errors.customer}

                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                  >
                    {customersList.map(option => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                      >
                        {option.text}
                      </MenuItem>
                    ))}
                  </TextField> */}
                  <div style={{ width: "300px", marginLeft: "-6px" }}>
                    <Autocomplete
                      //autoFocus
                      options={this.state.customersList}
                      value={this.state.customer}
                      onChange={this.handleAutocompleteCustomer}
                      getOptionLabel={option => option.text}
                      renderInput={params => (
                        <TextField
                          {...params}
                          // autoFocus
                          label="Customer"
                          style={{
                            width: "93%",
                            fontSize: "11px !important",
                            marginLeft: ".5em",
                            marginRight: ".5em",
                            maxHeight: "400px"
                          }}
                          // name="plannerId"
                          margin="normal"
                          required
                        />
                      )}
                    />
                  </div>
                </div>
                {/* <Paper style={{ padding: "1em", margin: "1em 0px 1em 0px" }}> */}
                <div
                  style={{
                    display: "flex",
                    // paddingLeft: "1em"
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#cccccc",
                      color: "#515151",
                      borderRadius: "4px",
                      padding: ".3em 1em .3em 1em",
                      width: "122px",
                      height: "24px",
                      marginTop: "1em",
                      border: "1px dashed #3982ea",
                      backgroundColor: "white",
                      cursor: "pointer",
                    }}
                  >
                    <label
                      className="upload excel"
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        multiple
                        id="inputId"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => this.handleUpload(e)}
                        accept={
                          this.state.customer &&
                            this.state.customer.value !== "Npub_Mac"
                            ? ".txt"
                            : ".xlsx" || ".csv"
                        }
                      />
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <AddCircleIcon
                          style={{ color: "#3982ea", marginLeft: "-10px" }}
                        />{" "}
                        <span style={{ marginLeft: "2px", fontWeight: "500" }}>
                          Add File
                        </span>
                      </div>
                    </label>
                  </div>
                  <div
                    style={{
                      padding: ".3em 1em .3em 1em",
                      marginTop: "1em",
                      marginLeft: "1em",
                      borderRadius: "4px",
                      boxShadow:
                        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                    }}
                  >
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <InfoIcon
                          style={{ color: "#3982ea", marginLeft: "-10px" }}
                        />{" "}
                        <span
                          style={{
                            marginLeft: "4px",
                            fontSize: "11px",
                            color: "gray",
                          }}
                        >
                          Allowed for{" "}
                          {this.state.customer &&
                            this.state.customer.text &&
                            this.state.customer.text.split("-")[1].trim()}{" "}
                          upload only{" "}
                          {this.state.customer &&
                            this.state.customer.value !== "Npub_Mac"
                            ? ".txt"
                            : ".xlsx or .csv "}{" "}
                          file format,10 MB file limit.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {this.state.stateFiles.map((file, i) => {
                    return (
                      <div key={i}>
                        {i === 0 && (
                          <div
                            style={{
                              display: "flex",
                              margin: "1em 0px 1em 0px",

                              // marginTop: "2em",
                              paddingBottom: ".5em",
                              borderBottom: "1px solid #eaeaea",
                              fontSize: "14px",
                            }}
                          >
                            <div style={{ width: "38%", fontWeight: "500" }}>
                              File Info
                            </div>
                            <div style={{ width: "10%", fontWeight: "500" }}>
                              Size
                            </div>
                            <div style={{ width: "40%", fontWeight: "500" }}>
                              Detection
                            </div>
                            <div
                              style={{
                                width: "12%",
                                fontWeight: "500",
                                textAlign: "right",
                              }}
                            >
                              Actions
                            </div>
                          </div>
                        )}
                        {/* {file.customerOrderHeaderID ? ( */}
                        <div
                          style={{
                            display: "flex",
                            margin: "1em 0px 1em 0px",
                            paddingBottom: ".5em",
                            borderBottom: "1px solid #eaeaea",
                            fontSize: "12px",
                          }}
                        >
                          <div style={{ width: "40%", fontWeight: "500" }}>
                            <div style={{ display: "flex" }}>
                              <DescriptionIcon
                                style={{
                                  color: "#306dca",
                                  marginTop: "-4px",
                                  marginRight: "5px",
                                }}
                              />{" "}
                              <span>
                                {" "}
                                {file.name ? file.name : file.fileName}
                              </span>
                            </div>
                          </div>
                          <div style={{ width: "10%" }}>
                            {/* {Math.ceil(file.size / 1024) + " kb"} */}
                            {file.size ? file.size : file.fileSize}
                          </div>

                          {!this.state.loading ? (
                            <DetectionControl
                              fileName={file}
                              customerId={this.state.customer}
                              id={i}
                              onProceedClicked={
                                this.onProceedClicked
                              }
                            />
                          ) : (
                              <div style={{ width: "40%", marginTop: ".6em" }}>
                                <LinearProgress />
                              </div>
                            )}

                          <div style={{ width: "10%", textAlign: "right" }}>
                            {/* {!file.isValid && !file.filePath && (
                                  <IconButton
                                    size="small"
                                    style={{ marginTop: "-5px" }}
                                    // onClick={e =>
                                    //   this.getExcelFileData(
                                    //     file.orderFileHeaderID,
                                    //     file.name
                                    //   )
                                    // }
                                  >
                                    <GetAppIcon style={{ fontSize: "16px" }} />
                                  </IconButton>
                                )} */}
                            {(file.isValid && !file.exceptionMessage) && <IconButton
                              size="small"
                              style={{ marginTop: "-7px" }}
                              onClick={(e) => {
                                this.deleteUploadedFile(
                                  this.state.stateFiles[i].mlopHeaderID &&
                                  this.state.stateFiles[i].mlopHeaderID,
                                  i,
                                  this.state.stateFiles[i]
                                );
                              }}
                            >
                              <DeleteIcon style={{ fontSize: "16px" }} />
                            </IconButton>}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* </Paper> */}
                {this.state.successUpload && this.state.stateFiles.length > 0 && (
                  <Paper style={{ padding: ".5em" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span>
                        <CheckCircleIcon
                          style={{
                            color: "rgba(59, 218, 182, 0.87)",
                            fontSize: "19px",
                          }}
                        />
                      </span>
                      <span
                        style={{
                          marginTop: "-4px",
                          marginLeft: ".5em",
                          fontSize: "13px",
                        }}
                      >
                        Order File Posted Successfully. Our Job on Completion,
                        you will get notification on Home Screen and through
                        e-mail notification
                      </span>
                    </div>
                  </Paper>
                )}
                <Divider style={{ marginTop: "20px" }} />

                <div
                  className="button-wrapper"
                  style={{ paddingLeft: "0px", marginLeft: "0px" }}
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/pubMainLabelOrderPlacement")
                      }
                    >
                      Close
                    </Button>
                  </div>
                  {/* <div style={{ marginLeft: "1em" }}>
                    <Button
                      disabled={!this.state.formValid}
                      autoFocus
                      variant="contained"
                      color="primary"
                      //  onClick={this.handleSubmit}
                      className={
                        !this.state.formValid
                          ? "disabled-button"
                          : "enabled-button "
                      }
                    >
                      SUBMIT
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(AddMLOP));
