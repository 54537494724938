import React, { Component } from "react";

class BLCPTVolumeReport extends Component {
  state = {};
  componentDidMount() {
    this.props.handleSettingHeader("Back Label CPT Volume");
    this.props.setSelectedList("publisherReportsOpen", "BLCPTVolumeReport");
  }
  render() {
    return (
      <div>
      <iframe
        style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
        src={`${window.localStorage.ReportURLByID}` +"CPTVolumeReport"}
        />
      </div>
    );
  }
}

export default BLCPTVolumeReport;
