import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import clone from "clone";
import SyncIcon from "@material-ui/icons/Sync";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";
import moment from "moment"
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { postCollectionNote, getToAddress } from "../reportServiceAction";
import Typography from "@material-ui/core/Typography";
import {
  getCountryList,
  getCountryDropdownList,
} from "../../masters/masterActions";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import DoneIcon from "@material-ui/icons/Done";
import {
  EditIcon,
  CustomPrintIcon,
  CustomSmallAddIcon,
} from "../../../shared/customIcons";
import IconButton from "@material-ui/core/IconButton";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import TimeFnsUtils from "@date-io/date-fns";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import WorkOrderModal from "./workOrderModel";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const validateNotRequired100 = new Validator("maxlength100AllAllowNewForEmpty");
const validatePhoneNo = new Validator("phnNoFCN");
const validateZipCode = new Validator("pincode");

const headCells = [
  {
    id: 1,
    code: "lineNo",
    numeric: false,
    disablePadding: true,
    label: "Line No",
    width: "auto",
  },
  {
    id: 2,
    code: "stockDescription",
    numeric: false,
    disablePadding: false,
    label: "Description *",
    width: "100%",
  },
  {
    id: 3,
    code: "noOfPts",
    numeric: false,
    disablePadding: false,
    label: "No of Plts",
    width: "auto",
  },
  {
    id: 4,
    code: "totalPackage",
    numeric: false,
    disablePadding: false,
    label: "Total Packages *",
    width: "auto",
  },
  {
    id: 5,
    code: "noOfCopies",
    numeric: false,
    disablePadding: false,
    label: "No of Cps/Pk",
    width: "auto",
  },
  {
    id: 6,
    code: "totalQuantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity *",
    width: "auto",
  },
  {
    id: 7,
    code: "weightPerCartBox",
    numeric: false,
    disablePadding: false,
    label: "Weight per Carton Box (Kg)",
    width: "auto",
  },
  {
    id: 8,
    code: "netWeight",
    numeric: false,
    disablePadding: false,
    label: "Net Weight (Kg)",
    width: "auto",
  },
  {
    id: 9,
    code: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    width: "auto",
  },
];

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: "31%",
    fontSize: "11px !important",
  },
  textFieldWide: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: "95.5%",
    fontSize: "11px !important",
  },
  autocompleteText: {
    width: "100%",
    fontSize: "11px !important",
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    // marginLeft: "1.5em",
    paddingTop: "1em",
    marginBottom: "1em",
    fontFamily: "Roboto",
  },
  switchControl: {
    marginTop: "38px",
    marginLeft: "1em",
  },
  autoComplete: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: "31%",
    fontSize: "11px !important",
  },
  requiredField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    color: "red",
    width: "95.5%",
    fontSize: "11px !important",
  },
  requiredField2: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    color: "red",

    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  // subHeader: {
  //   fontSize: "14px",
  //   fontWeight: "600",
  //   color: "#21529f",
  //   marginLeft: "1.5em",
  //   paddingTop: "1em"
  // }
});
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderLeft: "1px sold #000", width: `${headCell.width}` }}
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          //   width: "100%",
          border: "1px solid #eaeaea",
          borderBottom: "none",
          borderRadius: "4px",
          margin: "0 .8em 0 .6em",
        }}
      >
        {props.disableAdd ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            onClick={(e) => props.handleClickButton(e, "add")}
          // style={
          //   !props.disableAdd
          //     ? {
          //         color: "#fff",
          //         background: "#3982ea",
          //         padding: "2px",
          //         margin: ".5em",
          //         fontSize: "11px",
          //       }
          //     : { color: "#3982ea", fontSize: "11px" }
          // }
          >
            <CustomSmallAddIcon style={{ fontSize: "18px" }} /> Add
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              onClick={(e) => props.handleClickButton(e, "add")}
            // style={
            //   !props.disableAdd
            //     ? {
            //         color: "#fff",
            //         background: "#3982ea",
            //         padding: "2px",
            //         margin: ".5em",
            //         fontSize: "11px",
            //       }
            //     : { color: "#3982ea", fontSize: "11px" }
            // }
            >
              <CustomSmallAddIcon style={{ fontSize: "18px" }} /> Add
            </Button>
          )}
        {props.disableEdit ? (
          <Button
            disabled
            variant="contained"
            color="primary"
            style={{
              boxShadow: "none",
              padding: "2px",
              margin: ".5em",
              fontSize: "11px",
            }}
            // style={
            //   props.disableEdit
            //     ? {
            //         color: "#fff",
            //         background: "#3982ea",
            //         padding: "2px",
            //         margin: ".5em",
            //         fontSize: "11px",
            //       }
            //     : { color: "#3982ea", fontSize: "11px" }
            // }
            onClick={(e) => props.handleClickButton(e, "reset")}
          >
            <SyncIcon style={{ fontSize: "18px" }} /> Reset
          </Button>
        ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "2px",
                margin: ".5em",
                fontSize: "11px",
              }}
              // style={
              //   props.disableEdit
              //     ? {
              //         color: "#fff",
              //         background: "#3982ea",
              //         padding: "2px",
              //         margin: ".5em",
              //         fontSize: "11px",
              //       }
              //     : { color: "#3982ea", fontSize: "11px" }
              // }
              onClick={(e) => props.handleClickButton(e, "reset")}
            >
              <SyncIcon style={{ fontSize: "18px" }} /> Reset
            </Button>
          )}
      </div>
    </div>
  );
};
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
class AddCollectionNote extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      collectionNote: {
        collectionToAddress: "",
        collectionNoteNo: "",
        jobGroupID: "",
        customerName: "",
        workOrderNumber: "",
        projectTitle: "",
        contactPersonName: "",
        description: "",
        contactNumber: "",
        collectionAddress: "",
        collectionAddress2: "",
        collectionAddress3: "",
        isUrgent: false,
        zipCode: "",
        countryID: "",
        totalQuantity: 0,
        status: "",
        pickupDate: null,
        pickupTime: moment().startOf('day'),
        driverName: "",
      },
      workOrder: {
        jobGroupID: "",
        customerName: "",
        workOrderNumber: "",
        projectTitle: "",
        jobGroupNumber: "",
        workOrderID: "",
      },
      formValid: false,
      submitSuccess: false,
      errors: {},
      agentCodeValid: true,
      driverNameValid: true,
      descriptionValid: false,
      pickupDateValid: false,
      pickupTimeValid: true,
      contactPersonNameValid: false,
      selectedWO: [],
      contactNumberValid: false,
      collectionAddressValid: false,
      collectionAddress2Valid: true,
      collectionAddress3Valid: true,
      zipCodeValid: false,
      countryValid: false,
      collectionNoteDetailList: [],
      countryList: [],
      editableCollectionNoteDetailList: [],
      remarksValid: true,
      jobGroupList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Collection Note");
    this.props.setSelectedList("genericSystemOpen", "collectionNote");
    this.props.handleDrawerClose();
    let newDN = { ...this.state.collectionNote }
    let nErrors = { ...this.state.errors }
    getCountryDropdownList().then((r) => {
      // let newDN = { ...this.state.collectionNote }
      // let nErrors = { ...this.state.errors }
      if (r && r.data) {
        r.data.map((rr) => {
          if (rr.value === "sg") {
            this.setState({ countryValid: true })
            nErrors["country"] = ""
            newDN["countryID"] = rr.id
            newDN["countryList"] = rr
          }
        });
        this.setState({ errors: nErrors, collectionNote: newDN })
      }

      dispatch({
        type: "getCountryList",
        newStore: r.data,
      });
      this.getAllCountries(r.data);
    });
    getToAddress().then((r) => {
      // this.setState({ deliverFromAddressValid: true })

      newDN.collectionToAddress = r;
      // this.setState({ deliveryNote: newDN });
      nErrors.collectionToAddress = ""
      this.setState({ errors: nErrors, collectionNote: newDN })

    });
    this.addFirstRow();
  }
  addFirstRow() {
    let newList = [];
    newList.push({});
    newList[newList.length - 1].editClicked = true;
    newList[newList.length - 1].validRecord = false;
    newList[newList.length - 1].stockDescription = "";
    newList[newList.length - 1].noOfPts = 0;
    newList[newList.length - 1].totalPackage = 0;
    newList[newList.length - 1].noOfCopies = 0;
    newList[newList.length - 1].totalQuantity = 0;
    newList[newList.length - 1].weightPerCortanBox = 0;
    newList[newList.length - 1].netWeight = 0;
    newList[newList.length - 1].isActive = true;
    newList[newList.length - 1].createdBy = localStorage.loggedInId;
    this.setState({
      editableCollectionNoteDetailList: newList,
      collectionNoteDetailList: [],
      disableAdd: true,
      disableEdit: false,
    });
  }
  getAllCountries = (list) => {
    this.setState({
      countryList:
        list &&
        list.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };
  _onKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onDecimalKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  checkWeightDec(value) {
    var re = /^[0-9]\d*(?:\.\d{0,3})?$/;
    if (re.test(value) === false) {
      value = value.substring(0, value.length - 1);
    }
    return value;
  }
  handleClickButton = (e, name) => {
    let newList = [...this.state.editableCollectionNoteDetailList];
    //let newList2 = [...this.state.collectionNoteDetailList];
    newList.push({});
    if (name === "add") {
      newList[newList.length - 1].editClicked = true;
      newList[newList.length - 1].validRecord = false;
      newList[newList.length - 1].stockDescription = "";
      newList[newList.length - 1].noOfPts = 0;
      newList[newList.length - 1].totalPackage = 0;
      newList[newList.length - 1].noOfCopies = 0;
      newList[newList.length - 1].totalQuantity = 0;
      newList[newList.length - 1].weightPerCortanBox = 0;
      newList[newList.length - 1].netWeight = 0;
      newList[newList.length - 1].isActive = true;
      newList[newList.length - 1].createdBy = localStorage.loggedInId;
      //newList2.push(newList[newList.length-1]);
      this.setState(
        {
          //collectionNoteDetailList: newList2,
          editableCollectionNoteDetailList: newList,
          disableAdd: true,
          disableEdit: false,
        },
        () => {
          document.getElementById(`tableRow${newList.length - 1}`).focus();
        }
      );
    } else {
      // this.setState({
      //   collectionNoteDetailList: [],
      //   editableCollectionNoteDetailList: [],
      //   disableAdd: false,
      //   disableEdit: true
      // });
      this.addFirstRow();
    }
    this.validateField("", "");
  };
  onEditClicked = (e, i) => {
    let newList = clone(this.state.editableCollectionNoteDetailList);
    newList[i].editClicked = true;
    this.setState({
      editableCollectionNoteDetailList: newList,
      disableAdd: true,
    });
    this.validateField("", "");
  };

  onDeleteClicked = (e, i, row) => {
    let newList = clone(this.state.editableCollectionNoteDetailList);
    newList.isActive = false;
    newList[i].editClicked = false;
    this.setState({ collectionNoteDetailList: newList });
    newList.splice(i, 1);
    this.setState({ editableCollectionNoteDetailList: newList });
    this.validateField("", "");
  };

  onDoneClicked = (e, i, record) => {
    let newList = [...this.state.editableCollectionNoteDetailList];
    newList[i] = record;
    newList[i].editClicked = false;
    // if (record.netWeight === "" || parseFloat(record.netWeight) === 0) {
    //   newList[i].netWeight =
    //     newList[i].totalQuantity &&
    //     newList[i].weightPerCortanBox &&
    //     parseFloat(newList[i].weightPerCortanBox) *
    //     parseInt(newList[i].totalQuantity);
    // }
    this.setState({
      editableCollectionNoteDetailList: newList,
      collectionNoteDetailList: newList,
      disableAdd: false,
    });
    this.validateField("", "");
  };

  onCancelClicked = (e, i, record) => {
    let newList = clone(this.state.editableCollectionNoteDetailList);
    let newList2 = clone(this.state.collectionNoteDetailList);
    newList = newList2;
    this.setState({
      editableCollectionNoteDetailList: newList,
      disableAdd: false,
    });

    this.validateField("", "");
  };
  handleDecimalValueChange = (event, index) => {
    let values = event.target.value;
    values = this.checkWeightDec(event.target.value.toString());
    let newEditableList = clone(this.state.editableCollectionNoteDetailList);
    newEditableList[index][event.target.name] = values;
    // if (event.target.name === "weightPerCortanBox") {
    //   newEditableList[index].netWeight =
    //     newEditableList[index].weightPerCortanBox &&
    //     newEditableList[index].totalQuantity &&
    //     parseFloat(newEditableList[index].weightPerCortanBox) *
    //     parseInt(newEditableList[index].totalQuantity);
    //   newEditableList[index].netWeight = parseFloat(
    //     this.addZeroes(newEditableList[index].netWeight.toString(), 3)
    //   ).toFixed(3);
    // }

    if (
      newEditableList[index].description !== "" &&
      newEditableList[index].totalPackage !== "" &&
      newEditableList[index].totalQuantity !== ""
    ) {
      newEditableList[index].validRecord = true;
    } else {
      newEditableList[index].validRecord = false;
    }
    this.setState({ editableCollectionNoteDetailList: newEditableList });
    //this.validateField(event.target.name, event.target.value);
  };

  handleRowChange = (event, index) => {
    let newEditableList = clone(this.state.editableCollectionNoteDetailList);
    newEditableList[index][event.target.name] = event.target.value;
    if (event.target.name === "totalPackage") {
      newEditableList[index].totalQuantity = +event.target.value * newEditableList[index].noOfCopies
      newEditableList[index].netWeight = +event.target.value * newEditableList[index].weightPerCortanBox
    }
    if (event.target.name === "noOfCopies") {
      newEditableList[index].totalQuantity = +event.target.value * newEditableList[index].totalPackage
    }

    if (event.target.name === "weightPerCortanBox") {
      newEditableList[index].netWeight = +event.target.value * newEditableList[index].totalPackage
    }



    // if (event.target.name === "totalQuantity") {
    //   newEditableList[index].netWeight =
    //     newEditableList[index].weightPerCortanBox &&
    //     newEditableList[index].totalQuantity &&
    //     parseFloat(newEditableList[index].weightPerCortanBox) *
    //     parseInt(newEditableList[index].totalQuantity);
    //   newEditableList[index].netWeight = parseFloat(
    //     this.addZeroes(newEditableList[index].netWeight.toString(), 3)
    //   ).toFixed(3);
    // }

    if (
      newEditableList[index].description !== "" &&
      newEditableList[index].totalPackage !== "" &&
      newEditableList[index].totalQuantity !== ""
    ) {
      newEditableList[index].validRecord = true;
    } else {
      newEditableList[index].validRecord = false;
    }
    this.setState({ editableCollectionNoteDetailList: newEditableList });
    //this.validateField(event.target.name, event.target.value);
  };

  handleChange = (event) => {
    const { collectionNote } = this.state;
    collectionNote[event.target.name] = event.target.value;
    this.setState({ collectionNote });

    this.validateField(event.target.name, event.target.value);
  };

  handlePriorityChange = (name) => (event) => {
    const { collectionNote } = this.state;
    collectionNote[name] = event.target.checked;
    this.setState({ collectionNote });
    this.validateField(event.target.name, event.target.value);
  };
  handleCountryChange = (event, values) => {
    // const { collectionNote } = this.state;
    // console.log(values === null)
    let newCNote = clone(this.state.collectionNote)
    if (values === null) {
      newCNote["countryList"] = "null";
      newCNote["countryID"] = "";
    } else {
      let countryVal = values;
      newCNote["countryID"] = countryVal.id;
      newCNote["countryList"] = countryVal;
    }
    this.setState({ collectionNote: newCNote });

    this.validateField("countryID", newCNote["countryID"]);
  };
  handleWorkOrderChange = (event, values) => {
    const { collectionNote } = this.state;
    if (values === null) {
      collectionNote["workOrderName"] = "null";
    } else {
      let workOrderNameID = values.key;
      collectionNote["workOrderName"] = workOrderNameID;
    }
    this.setState({ collectionNote });

    this.validateField("workOrderName", collectionNote["workOrderName"]);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let agentCodeValid = this.state.agentCodeValid;
    let driverNameValid = this.state.driverNameValid;
    let descriptionValid = this.state.descriptionValid;
    let pickupDateValid = this.state.pickupDateValid;
    let pickupTimeValid = this.state.pickupTimeValid;
    let contactPersonNameValid = this.state.contactPersonNameValid;
    let contactNumberValid = this.state.contactNumberValid;
    let collectionAddressValid = this.state.collectionAddressValid;
    let collectionAddress2Valid = this.state.collectionAddress2Valid;
    let collectionAddress3Valid = this.state.collectionAddress3Valid;
    let zipCodeValid = this.state.zipCodeValid;
    let countryValid = this.state.countryValid;
    let remarksValid = this.state.remarksValid;
    switch (fieldName) {
      case "agentCode":
        if (value !== "") {
          agentCodeValid = validateNotRequired100(value);
          fieldValidationErrors.agentCode = agentCodeValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          agentCodeValid = true;
          fieldValidationErrors.agentCode = "";
        }
        break;
      case "driverName":
        if (value !== "") {
          driverNameValid = validateNotRequired100(value);
          fieldValidationErrors.driverName = driverNameValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          driverNameValid = true;
          fieldValidationErrors.driverName = "";
        }
        break;
      case "description":
        if (value !== "") {
          descriptionValid = validateNotRequired100(value);
          fieldValidationErrors.description = descriptionValid
            ? ""
            : resourceValidation.ValidDescription;
          this.setState({ descriptionValid })
        } else {
          descriptionValid = false;
          fieldValidationErrors.description = "";
          this.setState({ descriptionValid })
        }
        break;
      case "contactPersonName":
        if (value) {
          contactPersonNameValid = validateNotRequired100(value);
          // console.log(value, contactPersonNameValid)
          fieldValidationErrors.contactPersonName = contactPersonNameValid
            ? ""
            : resourceValidation.ValidName;
          this.setState({ contactPersonNameValid })
        } else {
          contactPersonNameValid = false;
          fieldValidationErrors.contactPersonName = "";
          this.setState({ contactPersonNameValid })
        }
        break;
      case "contactNumber":
        if (value !== "") {
          contactNumberValid = validatePhoneNo(value);
          fieldValidationErrors.contactNumber = contactNumberValid
            ? ""
            : resourceValidation.validContact;
          this.setState({ contactNumberValid })
        } else {
          contactNumberValid = false;
          fieldValidationErrors.contactNumber = "";
          this.setState({ contactNumberValid })
        }
        break;
      case "collectionAddress":
        if (value !== "") {
          collectionAddressValid = validateNotRequired100(value);
          fieldValidationErrors.collectionAddress = collectionAddressValid
            ? ""
            : resourceValidation.validAddress;
          this.setState({ collectionAddressValid })
        } else {
          collectionAddressValid = false;
          fieldValidationErrors.collectionAddress = "";
          this.setState({ collectionAddressValid })
        }
        break;
      case "collectionAddress2":
        if (value !== "") {
          collectionAddress2Valid = validateNotRequired100(value);
          fieldValidationErrors.collectionAddress2 = collectionAddress2Valid
            ? ""
            : resourceValidation.validAddress;
          this.setState({ collectionAddress2Valid })
        } else {
          collectionAddress2Valid = true;
          fieldValidationErrors.collectionAddress2 = "";
          // this.setState({ collectionAddress2Valid })

        }
        break;
      case "collectionAddress3":
        if (value !== "") {
          collectionAddress3Valid = validateNotRequired100(value);
          fieldValidationErrors.collectionAddress3 = collectionAddress3Valid
            ? ""
            : resourceValidation.validAddress;
          this.setState({ collectionAddress3Valid })
        } else {
          collectionAddress3Valid = true;
          fieldValidationErrors.collectionAddress3 = "";
          // this.setState({ collectionAddress3Valid })
        }
        break;
      case "zipCode":
        if (value !== "") {
          zipCodeValid = validateZipCode(value);
          fieldValidationErrors.zipCode = zipCodeValid
            ? ""
            : resourceValidation.validZipCode;
          this.setState({ zipCodeValid })
        } else {
          zipCodeValid = false;
          fieldValidationErrors.zipCode = "";
          this.setState({ zipCodeValid })
        }
        break;
      case "countryID":
        if (value) {
          countryValid = true;
          // fieldValidationErrors.countryValid = countryValid
          //   ? ""
          //   : resourceValidation.validAddress;
          this.setState({ countryValid })
        } else {
          countryValid = false;
          // fieldValidationErrors.countryValid = "";
          this.setState({ countryValid })
        }
        break;
      case "remarks":
        if (value !== "") {
          remarksValid = validateNotRequired100(value);
          fieldValidationErrors.remarks = remarksValid
            ? ""
            : resourceValidation.validRemarks;
        } else {
          remarksValid = false;
          fieldValidationErrors.remarks = "";
        }
        break;
      case "pickupDate":
        if (value !== null) {
          pickupDateValid = value.toString() === "Invalid Date" ? false : true;
          fieldValidationErrors.pickupDate = pickupDateValid
            ? ""
            : " Invalid Date Format";
        } else {
          pickupDateValid = false;
          fieldValidationErrors.pickupDate = "";
        }
        break;
      case "pickupTime":
        if (value !== null) {
          pickupTimeValid = value.toString() === "Invalid Date" ? false : true;
          fieldValidationErrors.pickupTime = pickupTimeValid
            ? ""
            : " Invalid Time Format";
        } else {
          pickupTimeValid = false;
          fieldValidationErrors.pickupTime = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        // contactPersonNameValid: contactPersonNameValid,
        // contactNumberValid: contactNumberValid,
        // descriptionValid: descriptionValid,
        agentCodeValid: agentCodeValid,
        driverNameValid: driverNameValid,
        remarksValid: remarksValid,
        // collectionAddressValid: collectionAddressValid,
        // collectionAddress2Valid: collectionAddress2Valid,
        // collectionAddress3Valid: collectionAddress3Valid,
        // zipCodeValid: zipCodeValid,
        // countryValid: countryValid,
        pickupDateValid: pickupDateValid,
        pickupTimeValid: pickupTimeValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.collectionNote.collectionToAddress &&
        this.state.contactPersonNameValid &&
        this.state.contactNumberValid &&
        this.state.descriptionValid &&
        this.state.agentCodeValid &&
        this.state.remarksValid &&
        this.state.driverNameValid &&
        this.state.collectionAddressValid &&
        this.state.collectionAddress2Valid &&
        this.state.collectionAddress3Valid &&
        this.state.zipCodeValid &&
        this.state.countryValid &&
        this.state.pickupDateValid &&
        this.state.pickupTimeValid &&
        this.state.workOrder.workOrderID !== "" &&
        this.detailListValid(),
    });
    return this.state.formValid;
  }
  detailListValid() {
    let valid = false;
    const { collectionNoteDetailList } = this.state;
    if (collectionNoteDetailList.length > 0) {
      valid = true;
      collectionNoteDetailList.map((r) => (valid = valid && r.validRecord));
    }
    return valid;
  }
  onBlur = (event, index) => {
    const { editableCollectionNoteDetailList } = this.state;
    let values = event.target.value;
    values = this.addZeroes(values.toString(), 3);
    editableCollectionNoteDetailList[index][event.target.name] = values;
    this.setState({ editableCollectionNoteDetailList });
  };
  addZeroes(num, decimalPlaces) {
    var value = Number(num);
    var res = num.split(".");
    if (res.length === 1 || res[1].length < decimalPlaces) {
      value = value.toFixed(decimalPlaces);
    }
    if (value !== "") {
      value = String(value);
    }
    return value;
  }
  handleDateChange = (date) => {
    const { collectionNote } = this.state;
    collectionNote["pickupDate"] = date && date;
    this.setState({ collectionNote });
    this.validateField("pickupDate", date && date);
  };
  handleTimeChange = (time) => {
    const { collectionNote } = this.state;
    collectionNote["pickupTime"] = time && time;
    this.setState({ collectionNote });
    this.validateField("pickupTime", time && time);
  };
  handleClose = () => {
    // clean up state and errors
    this.setState({
      contactPersonName: "",
      contactNumber: "",
      collectionAddress: "",
      zipCode: "",
      pickupDate: null,
      pickupTime: null,
      agentCode: "",
      driverName: "",
      remarks: "",
      errors: {},
    });
  };
  handleWorkOrderInfo = (e, selectedWO) => {
    //let newBA = { ...this.state.ba };
    this.setState({ errors: {} })
    let newCW = clone(this.state.collectionNote)
    if (e && e.workOrderID) {
      newCW.customerName = e.customerName
      newCW.description = e.customerName
      this.validateField("description", e.customerName)
      newCW.workOrderNumber = e.workOrderNumber ? e.workOrderNumber : ""
      newCW.projectTitle = e.projectTitle ? e.projectTitle : ""
      newCW.contactPersonName = e.contactPersonName ? e.contactPersonName : ""
      // if (e.contactPersonName)
      this.validateField("contactPersonName", e.contactPersonName)
      newCW.contactNumber = e.phoneNumber ? e.phoneNumber : ""
      // if (e.phoneNumber)
      this.validateField("contactNumber", e.phoneNumber)
      newCW.collectionAddress = e.addressLine1 ? e.addressLine1 : ""
      // if (e.addressLine1)
      this.validateField("collectionAddress", e.addressLine1)
      newCW.collectionAddress2 = e.addressLine2 ? e.addressLine2 : ""
      // this.validateField("collectionAddress2", e.addressLine2)
      newCW.collectionAddress3 = e.addressLine3 ? e.addressLine3 : ""
      // this.validateField("collectionAddress3", e.addressLine3)
      newCW.zipCode = e.postalCode ? e.postalCode : ""
      // if (e.postalCode)
      this.validateField("zipCode", e.postalCode)
      newCW.countryID = e.countryList && e.countryList.length > 0 ? e.countryList[0].id : ""
      // newCW.countryList = e.countryList && e.countryList.length > 0 ? e.countryList[0] : {}
      newCW.countryList = e.countryList[0]

      this.validateField("countryID", e.countryList && e.countryList.length > 0 ? e.countryList[0].id : "")

    }
    else {
      newCW.customerName = ""
      newCW.workOrderNumber = ""
      newCW.projectTitle = ""
      newCW.contactPersonName = ""
      newCW.contactNumber = ""
      newCW.collectionAddress = ""
      newCW.collectionAddress2 = ""
      newCW.collectionAddress3 = ""
      newCW.zipCode = ""
      newCW.countryID = ""
      newCW.countryList = {}
    }
    // console.log(newCW.country)
    this.setState({ workOrder: e, collectionNote: newCW });
    // this.validateField("", "");
  };
  clearWorkOrderInfo = (i) => {
    let newWorkOrder = { ...this.state.workOrder };
    newWorkOrder = {};
    this.setState({ workOrder: newWorkOrder });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    //const collectionNoteDetailList = this.context;

    if (this.validateForm()) {
      const {
        collectionNote,
        workOrder,
        collectionNoteDetailList,
      } = this.state;
      let workOrderID = workOrder.workOrderID;
      let contactPersonName = collectionNote.contactPersonName;
      let contactNumber = collectionNote.contactNumber;
      let collectionAddress = collectionNote.collectionAddress;
      let description = collectionNote.description;
      let collectionNoteAddress2 = collectionNote.collectionAddress2;
      let collectionNoteAddress3 = collectionNote.collectionAddress3;
      let collectionToAddress = collectionNote.collectionToAddress;
      let zipCode = collectionNote.zipCode;
      let countryID = collectionNote.countryID;
      let isUrgent = collectionNote.isUrgent;
      let totalQuantity = 0;
      let pickupDate =
        collectionNote.pickupDate &&
        collectionNote.pickupDate.toLocaleDateString();
      let pickupTime = collectionNote.pickupTime && collectionNote.pickupTime._d ?
        // collectionNote.pickupTime._d.getHours() +
        // ":" +
        // collectionNote.pickupTime._d.getMinutes() +
        // ":" +
        // collectionNote.pickupTime._d.getSeconds() 
        moment(collectionNote.pickupTime._d).format("HH:mm")
        :
        collectionNote.pickupTime ?
          // collectionNote.pickupTime.getHours() +
          //   ":" +
          //   collectionNote.pickupTime.getMinutes() +
          //   ":" +
          //   collectionNote.pickupTime.getSeconds()
          moment(collectionNote.pickupTime).format("HH:mm")
          : null;
      let remarks = collectionNote.remarks;
      let agentCode = collectionNote.agentCode;
      let driverName = collectionNote.driverName;
      let createdBy = localStorage.loggedInId;
      let isActive = true;
      let collectionNoteDetails = collectionNoteDetailList;
      collectionNoteDetails.map(
        (r) =>
          (totalQuantity = parseInt(totalQuantity) + parseInt(r.totalQuantity))
      );
      let reqBody = {
        workOrderID,
        collectionToAddress,
        contactPersonName,
        contactNumber,
        collectionAddress,
        collectionNoteAddress2,
        collectionNoteAddress3,
        description,
        countryID,
        isUrgent,
        zipCode,
        totalQuantity,
        pickupDate,
        pickupTime,
        agentCode,
        driverName,
        remarks,
        isActive,
        createdBy,
      };
      postCollectionNote(reqBody, collectionNoteDetails).then((r) => {
        if (r > 0) {
          this.setState({ submitSuccess: true, formValid: false });
          this.props.history.push("/edit_collectionNote/" + r);
        } else {
          this.setState({ submitSuccess: false });
        }
      });
    }
  };
  render() {
    let { classes } = this.props;
    // console.log(this.state.collectionNote.countryList)
    let {
      collectionNote,
      jobGroupList,
      editableCollectionNoteDetailList,
      countryList,
      disableAdd,
      workOrder,
    } = this.state;
    let totalQuantity = 0;

    editableCollectionNoteDetailList.map(
      (r) =>
        (totalQuantity = r.totalQuantity
          ? parseInt(totalQuantity) + parseInt(r.totalQuantity)
          : totalQuantity)
    );
    // console.log(this.state.collectionNote, countryList)
    return (
      <React.Fragment>
        <Paper
          style={
            !this.props.clearOpenList
              ? {
                overflow: "auto",
                border: "1px solid #eaeaea",
                maxWidth: "calc(100vw - 8em)",
                //maxHeight: "calc(100vh - 300px)"
              }
              : {
                overflow: "auto",
                border: "1px solid #eaeaea",
                maxWidth: "calc(100vw - 330px)",
                //maxHeight: "calc(100vh - 300px)"
              }
          }
        >
          <div className="pop-up-header">Create New Collection Note</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-4%" }}>
              <div className={classes.container} style={{ marginTop: "5%" }}>
                <div style={{ display: "flex", paddingTop: "1em" }}>

                  <WorkOrderModal
                    jobGroupList={jobGroupList}
                    clearWorkOrderInfo={this.clearWorkOrderInfo}
                    handleWorkOrderInfo={this.handleWorkOrderInfo}
                    selectedWO={this.selectedWO}
                  />
                  {workOrder.jobGroupID !== "" ? null : (
                    <span
                      className={classes.requiredField2}
                    >
                      * Please choose a work order
                    </span>
                  )}
                </div>
                {workOrder.jobGroupID === "" ? null : (
                  <div style={{ width: "99%", marginTop: ".5em" }}>
                    <Typography component="div" className="blueBox">
                      <div className="blueBoxLabel">
                        <div>Customer: </div>
                        <div className="blueBoxText">
                          {workOrder.customerName}
                        </div>
                      </div>
                      <div className="blueBoxLabel">
                        <div>Work Order: </div>
                        <div className="blueBoxText">
                          {workOrder.workOrderNumber}
                        </div>
                      </div>
                      <div className="blueBoxLabel">
                        <div>Project Title: </div>
                        <div className="blueBoxText">
                          {workOrder.projectTitle}
                        </div>
                      </div>
                      {/* <div className="blueBoxLabel">
                        <div>Job Group: </div>
                        <div className="blueBoxText">
                          {workOrder.jobGroupNumber}
                        </div>
                      </div> */}
                    </Typography>
                  </div>
                )}
              </div>
              <form
                style={{ marginTop: "-1%" }}
                className={classes.container}
                onSubmit={this.handleSubmit}
              >
                <div
                  style={{
                    overflow: "auto",
                    // maxHeight: "calc(100vh - 350px)",
                    // minHeight: "300px"
                  }}
                > <p className={classes.subHeader}>To Address</p>
                  <div>

                    <TextField
                      required
                      multiline={true}
                      rows={1}
                      rowsMax={6}
                      inputProps={{
                        maxLength: 500,
                      }}
                      label="Collection To Address"
                      name="collectionToAddress"
                      autoComplete="off"
                      margin="normal"
                      // inputProps={{
                      //   readOnly: true,
                      // }}
                      className={classes.textField}
                      value={collectionNote.collectionToAddress}
                      onChange={this.handleChange}
                      error={this.state.errors.collectionToAddress}
                      helperText={
                        this.state.errors.collectionToAddress
                          ? this.state.errors.collectionToAddress
                          : ""
                      }
                    />
                  </div>
                  <p className={classes.subHeader}>From Address</p>
                  <TextField
                    required
                    rows={1}
                    rowsMax={4}
                    label="Company"
                    name="description"
                    inputProps={{
                      maxLength: 200,
                    }}
                    margin="normal"
                    className={classes.textFieldWide}
                    value={collectionNote.description}
                    onChange={this.handleChange}
                    error={this.state.errors.description}
                    helperText={
                      this.state.errors.description
                        ? this.state.errors.description
                        : ""
                    }
                  />
                  <div>
                    <TextField
                      required
                      label="Address 1"
                      name="collectionAddress"
                      autoComplete="off"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      value={collectionNote.collectionAddress}
                      onChange={this.handleChange}
                      error={this.state.errors.collectionAddress}
                      helperText={
                        this.state.errors.collectionAddress
                          ? this.state.errors.collectionAddress
                          : ""
                      }
                    />
                    <TextField
                      label="Address 2"
                      name="collectionAddress2"
                      autoComplete="off"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      value={collectionNote.collectionAddress2}
                      onChange={this.handleChange}
                      error={this.state.errors.collectionAddress2}
                      helperText={
                        this.state.errors.collectionAddress2
                          ? this.state.errors.collectionAddress2
                          : ""
                      }
                    />
                    <TextField
                      label="Address 3"
                      name="collectionAddress3"
                      autoComplete="off"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      value={collectionNote.collectionAddress3}
                      onChange={this.handleChange}
                      error={this.state.errors.collectionAddress3}
                      helperText={
                        this.state.errors.collectionAddress3
                          ? this.state.errors.collectionAddress3
                          : ""
                      }
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      required
                      label="Zip Code"
                      name="zipCode"
                      margin="normal"
                      inputProps={{
                        maxLength: 6,
                      }}
                      className={classes.textField}
                      value={collectionNote.zipCode}
                      onChange={this.handleChange}
                      error={this.state.errors.zipCode}
                      helperText={
                        this.state.errors.zipCode
                          ? this.state.errors.zipCode
                          : ""
                      }
                    />
                    <Autocomplete
                      autoComplete
                      includeInputInList
                      id="countryList"
                      options={countryList}
                      value={collectionNote.countryList ? collectionNote.countryList : {}}
                      className={classes.autoComplete}
                      onChange={this.handleCountryChange}
                      getOptionLabel={(option) => option.text}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Select Country"
                          // name="countryList"
                          // id="countryList"
                          className={classes.autocompleteText}
                          // error={this.state.errors.countryList}
                          // value={collectionNote.countryID}
                          helperText={
                            this.state.errors.countryList
                              ? this.state.errors.countryList
                              : ""
                          }
                        />
                      )}
                    />
                  </div>
                  <p className={classes.subHeader}>Contact Info</p>
                  <div>
                    <TextField
                      required
                      label="Contact Person"
                      name="contactPersonName"
                      inputProps={{
                        maxLength: 100,
                      }}
                      margin="normal"
                      autoComplete="off"
                      className={classes.textField}
                      value={collectionNote.contactPersonName}
                      onChange={this.handleChange}
                      error={this.state.errors.contactPersonName}
                      helperText={
                        this.state.errors.contactPersonName
                          ? this.state.errors.contactPersonName
                          : ""
                      }
                    />
                    <TextField
                      required
                      label="Contact Person Number"
                      name="contactNumber"
                      inputProps={{
                        maxLength: 20,
                      }}
                      margin="normal"
                      autoComplete="off"
                      className={classes.textField}
                      value={collectionNote.contactNumber}
                      onChange={this.handleChange}
                      error={this.state.errors.contactNumber}
                      helperText={
                        this.state.errors.contactNumber
                          ? this.state.errors.contactNumber
                          : ""
                      }
                    />
                  </div>
                  <p className={classes.subHeader}>Pickup Details</p>
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        disablePast
                        required
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        className={classes.textField}
                        id="pickupDate"
                        label="Pickup Date"
                        value={collectionNote.pickupDate}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={TimeFnsUtils}>
                      <KeyboardTimePicker
                        autoOk
                        required
                        variant="inline"
                        margin="normal"
                        className={classes.textField}
                        id="pickupTime"
                        label="Pickup Time"
                        value={collectionNote.pickupTime}
                        onChange={this.handleTimeChange}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={collectionNote.isUrgent}
                          onChange={this.handlePriorityChange("isUrgent")}
                          value="isUrgent"
                          color="primary"
                        />
                      }
                      label="Urgent"
                      className={classes.switchControl}
                    />
                  </div>
                  <div>
                    <TextField
                      label="Agent Code"
                      name="agentCode"
                      margin="normal"
                      inputProps={{
                        maxLength: 50,
                      }}
                      className={classes.textField}
                      value={collectionNote.agentCode}
                      onChange={this.handleChange}
                      error={this.state.errors.agentCode}
                      helperText={
                        this.state.errors.agentCode
                          ? this.state.errors.agentCode
                          : ""
                      }
                    />
                    <TextField
                      label="Driver Name"
                      name="driverName"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                      }}
                      className={classes.textField}
                      value={collectionNote.driverName}
                      onChange={this.handleChange}
                      error={this.state.errors.driverName}
                      helperText={
                        this.state.errors.driverName
                          ? this.state.errors.driverName
                          : ""
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      multiline={true}
                      rows={1}
                      rowsMax={4}
                      label="Special Instructions"
                      name="remarks"
                      inputProps={{
                        maxLength: 500,
                      }}
                      margin="normal"
                      className={classes.textField}
                      value={collectionNote.remarks}
                      onChange={this.handleChange}
                      error={this.state.errors.remarks}
                      helperText={
                        this.state.errors.remarks
                          ? this.state.errors.remarks
                          : ""
                      }
                    />
                  </div>
                  <div style={{ marginTop: "2%" }}>
                    <p className={classes.subHeader}>Collection Items</p>
                    <div
                      style={{
                        display: "flex",
                        //   width: "100%",
                        border: "1px solid #eaeaea",
                        borderBottom: "none",
                        borderRadius: "4px",
                        margin: "0 .8em 0 .6em",
                      }}
                    >
                      <Typography
                        component="div"
                        style={{
                          width: "100%",
                        }}
                        className="blueBox"
                      >
                        <div className="blueBoxLabel">
                          <div>Total Quantity: </div>
                          <div className="blueBoxText">{totalQuantity}</div>
                        </div>
                      </Typography>
                    </div>
                    <div>
                      <EnhancedTableToolbar
                        //numSelected={selected.length}
                        handleClickButton={this.handleClickButton}
                        disableAdd={this.state.disableAdd}
                        disableEdit={this.state.disableEdit}
                      />
                    </div>
                    <div
                      style={{
                        // width: "100%",
                        maxHeight: "700px",
                        margin: "0 .8em 0 .6em",
                      }}
                    >
                      <div
                        // style={{
                        //   maxHeight: 200,
                        //   overflow: "auto",
                        //   border: "1px solid #eaeaea"
                        // }}
                        style={
                          !this.props.clearOpenList
                            ? {
                              overflow: "auto",
                              border: "1px solid #eaeaea",
                              maxWidth: "calc(100vw - 8em)",
                              maxHeight: "calc(100vh - 300px)",
                            }
                            : {
                              overflow: "auto",
                              border: "1px solid #eaeaea",
                              maxWidth: "calc(100vw - 330px)",
                              maxHeight: "calc(100vh - 300px)",
                            }
                        }
                      >
                        {/* //overflow: "auto" */}
                        <Table
                          aria-labelledby="tableTitle"
                          size="small"
                          stickyHeader
                          aria-label="sticky table"
                        >
                          {/* <caption style={{ borderBottom: "1px solid #eaeaea" }}>
                  A barbone structure table example with a caption
                </caption> */}
                          <EnhancedTableHead
                          //numSelected={selected.length}
                          // order={order}
                          // orderBy={orderBy}
                          // onSelectAllClick={e => this.handleSelectAllClick(e)}
                          //  onRequestSort={this.handleRequestSort}
                          //  rowCount={departmentList.length}
                          />

                          <TableBody style={{ overflow: "hidden" }}>
                            {editableCollectionNoteDetailList.map(
                              (row, index) => {
                                // const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    // hover
                                    // onClick={event =>
                                    //   this.handleClick(event, row.id, row)
                                    // }
                                    role="checkbox"
                                    //  aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    id={`tableRow${index}`}
                                  //   selected={isItemSelected}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                    >
                                      {index + 1}
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="left"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {editableCollectionNoteDetailList[index]
                                          .editClicked ? (
                                            <Input
                                              defaultValue=""
                                              inputProps={{
                                                "aria-label": "stockDescription",
                                                maxLength: 200,
                                              }}
                                              style={{ width: "100%" }}
                                              name="stockDescription"
                                              value={row.stockDescription}
                                              onChange={(e) =>
                                                this.handleRowChange(e, index)
                                              }
                                            />
                                          ) : row.stockDescription &&
                                            row.stockDescription.length > 16 ? (
                                              <Tooltip title={row.stockDescription}>
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.stockDescription}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.stockDescription}
                                              </Typography>
                                            )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {editableCollectionNoteDetailList[index]
                                          .editClicked ? (
                                            <Input
                                              defaultValue=""
                                              inputProps={{
                                                "aria-label": "stockDescription",
                                                maxLength: 10,
                                              }}
                                              style={{ width: "100%" }}
                                              name="noOfPts"
                                              value={row.noOfPts}
                                              onKeyPress={this._onKeyPress}
                                              onChange={(e) =>
                                                this.handleRowChange(e, index)
                                              }
                                            />
                                          ) : row.noOfPts &&
                                            row.noOfPts.length > 16 ? (
                                              <Tooltip title={row.noOfPts}>
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.noOfPts}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.noOfPts}
                                              </Typography>
                                            )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {editableCollectionNoteDetailList[index]
                                          .editClicked ? (
                                            <Input
                                              defaultValue=""
                                              inputProps={{
                                                "aria-label": "stockDescription",
                                                maxLength: 10,
                                              }}
                                              style={{ width: "100%" }}
                                              name="totalPackage"
                                              value={row.totalPackage}
                                              onKeyPress={this._onKeyPress}
                                              onChange={(e) =>
                                                this.handleRowChange(e, index)
                                              }
                                            />
                                          ) : row.totalPackage &&
                                            row.totalPackage.length > 16 ? (
                                              <Tooltip title={row.totalPackage}>
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.totalPackage}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.totalPackage}
                                              </Typography>
                                            )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {editableCollectionNoteDetailList[index]
                                          .editClicked ? (
                                            <Input
                                              defaultValue=""
                                              inputProps={{
                                                "aria-label": "stockDescription",
                                                maxLength: 10,
                                              }}
                                              style={{ width: "100%" }}
                                              name="noOfCopies"
                                              onKeyPress={this._onKeyPress}
                                              value={row.noOfCopies}
                                              onChange={(e) =>
                                                this.handleRowChange(e, index)
                                              }
                                            />
                                          ) : row.noOfCopies &&
                                            row.noOfCopies.length > 16 ? (
                                              <Tooltip title={row.noOfCopies}>
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.noOfCopies}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.noOfCopies}
                                              </Typography>
                                            )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      > {row.totalQuantity}
                                        {/* {" "}
                                        {editableCollectionNoteDetailList[index]
                                          .editClicked ? (
                                            <Input
                                              defaultValue=""
                                              inputProps={{
                                                "aria-label": "stockDescription",
                                                maxLength: 10,
                                              }}
                                              style={{ width: "100%" }}
                                              name="totalQuantity"
                                              onKeyPress={this._onKeyPress}
                                              value={row.totalQuantity}
                                              onChange={(e) =>
                                                this.handleRowChange(e, index)
                                              }
                                            />
                                          ) : row.totalQuantity &&
                                            row.totalQuantity.length > 16 ? (
                                              <Tooltip title={row.totalQuantity}>
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.totalQuantity}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.totalQuantity}
                                              </Typography>
                                            )} */}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {" "}
                                        {editableCollectionNoteDetailList[index]
                                          .editClicked ? (
                                            <Input
                                              defaultValue=""
                                              inputProps={{
                                                "aria-label": "stockDescription",
                                                maxLength: 10,
                                              }}
                                              style={{ width: "100%" }}
                                              name="weightPerCortanBox"
                                              onKeyPress={this._onDecimalKeyPress}
                                              value={row.weightPerCortanBox}
                                              onBlur={(e) =>
                                                this.onBlur(e, index)
                                              }
                                              onChange={(e) =>
                                                this.handleRowChange(e, index)
                                              }
                                            />
                                          ) : row.weightPerCortanBox &&
                                            row.weightPerCortanBox.length > 16 ? (
                                              <Tooltip
                                                title={row.weightPerCortanBox}
                                              >
                                                <Typography
                                                  noWrap
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {row.weightPerCortanBox}
                                                </Typography>
                                              </Tooltip>
                                            ) : (
                                              <Typography
                                                noWrap
                                                style={{ fontSize: "14px" }}
                                              >
                                                {row.weightPerCortanBox}
                                              </Typography>
                                            )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="right"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {(+row.netWeight).toFixed(3)}
                                        {/* {editableCollectionNoteDetailList[index]
                                          .editClicked ? (
                                            <Input
                                              defaultValue=""
                                              inputProps={{
                                                "aria-label": "stockDescription",
                                                maxLength: 10,
                                              }}
                                              style={{ width: "100%" }}
                                              name="netWeight"
                                              onKeyPress={this._onDecimalKeyPress}
                                              value={row.netWeight}
                                              onBlur={(e) =>
                                                this.onBlur(e, index)
                                              }
                                              onChange={(e) =>
                                                this.handleDecimalValueChange(
                                                  e,
                                                  index
                                                )
                                              }
                                            />
                                          ) : (
                                            row.netWeight
                                          )} */}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                      padding="default"
                                      align="left"
                                      className="table-content-cell"
                                    >
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "14px",
                                          display: "flex",
                                        }}
                                      >
                                        {editableCollectionNoteDetailList[index]
                                          .editClicked ? (
                                            <div>
                                              {row.validRecord ? (
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                  onClick={(e) =>
                                                    this.onDoneClicked(
                                                      e,
                                                      index,
                                                      row
                                                    )
                                                  }
                                                >
                                                  <DoneIcon
                                                    style={{
                                                      color: "#6c6b6b",
                                                    }}
                                                  />
                                                </span>
                                              ) : (
                                                  <React.Fragment></React.Fragment>
                                                )}
                                              <span
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                                onClick={(e) =>
                                                  this.onCancelClicked(
                                                    e,
                                                    index,
                                                    row
                                                  )
                                                }
                                              >
                                                <CloseIcon
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          ) : (
                                            <div>
                                              {!disableAdd ? (
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                  onClick={(e) =>
                                                    this.onEditClicked(e, index)
                                                  }
                                                >
                                                  <EditIcon
                                                    style={{
                                                      color: "#6c6b6b",
                                                    }}
                                                  />
                                                </span>
                                              ) : (
                                                  <React.Fragment></React.Fragment>
                                                )}
                                              <span
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#6c6b6b",
                                                }}
                                                onClick={(e) =>
                                                  this.onDeleteClicked(
                                                    e,
                                                    index,
                                                    row
                                                  )
                                                }
                                              >
                                                <DeleteIcon
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#6c6b6b",
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          )}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}

                            {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 25 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>

                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) =>
                        this.props.history.push("/collectionNote")
                      }
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                  {/* <div style={{ marginLeft: "1em" }}>
                    <Button
                      disabled={!this.state.submitSuccess}
                      autoFocus
                      variant="contained"
                      className={
                        !this.state.submitSuccess
                          ? "disabled-button"
                          : "enabled-button "
                      }
                      style={{ color: "#fff" }}
                    >
                      <IconButton aria-label="print" size="small">
                        <CustomPrintIcon style={{ color: "#fff" }} />
                      </IconButton>
                      {"PRINT COLLECTION NOTE"}
                    </Button>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(AddCollectionNote));
