import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { SearchIcon } from "../../../shared/customIcons";
import Validator from "../../../shared/validator";
import { styles } from "../../../shared/styles";
import { resourceValidation } from "../../../shared/resource";
import { StateContext } from "../../../shared/globalState";
import {
  getPublisherCustomerList,
  searchJournalitemMasterList,
  getJrnlStageList
} from "./action";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
//import { getFliteredDeptList } from "../masterActions";
const validateNotRequired2 = new Validator("notRequired2");
const validateNotRequired100 = new Validator("notRequired100");
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        ></IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class JournalItemSearch extends React.Component {
  componentDidMount() {
    getPublisherCustomerList().then((r) => {
      r.data &&
        this.setState({
          customerList: r.data.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          ),
        });
    });
    getJrnlStageList().then((r) => {
      r.data &&
        this.setState({
          stageList: r.data
          // .sort((a, b) =>
          //   a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          // ),
        });
    });
  }
  static contextType = StateContext;
  state = {
    customerList: [],
    customerId: 0,
    customer: {},
    stageList: [],
    stageId: 0,
    stage: {},
    issueDescription: "",
    title: "",
    acronym: "",
    volume: "",
    issue: "",
    suppNumber: "",
    partNumber: "",
    modifiedDateFrom: null,
    modifiedDateTo: null,
    stageDateFrom: null,
    stageDateTo: null,
    open: false,
    departmentCode: "",
    departmentName: "",
    errors: {
      departmentCode: false,
      departmentName: false,
    },
  };
  constructor() {
    super();
  }

  handleSubmit(event) {
    debugger;
    event.preventDefault();
    const data = new FormData(event.target);

    fetch("/api/form-submit-url", {
      method: "POST",
      body: data,
    });
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({
      open: false,
      customerId: "",
      issueDescription: "",
      title: "",
      acronym: "",
      volume: "",
      issue: "",
      suppNumber: "",
      partNumber: "",
      modifiedDateFrom: null,
      modifiedDateTo: null,
    });
  };
  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };
  onInputChanged = (e, name, type) => {
    let newError = { ...this.state.errors };
    if (type === "notRequired2") {
      let result = validateNotRequired2(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    } else if (type === "notRequired100") {
      let result = validateNotRequired100(e.target.value);
      newError[name] = !result;
      this.setState({ errors: newError });
    }
    this.setState({ [name]: e.target.value });
  };
  onSearchClicked = () => {
    let reqBody = {
      customerID: this.state.customerId ? this.state.customerId : 0,
      // stageID: this.state.stageId ? this.state.stageId : 0,
      issueDescription: this.state.issueDescription,
      title: this.state.title,
      acronym: this.state.acronym,
      volume: this.state.volume,
      issue: this.state.issue,
      suppNumber: this.state.suppNumber,
      partNumber: this.state.partNumber,
    };
    if (this.state.stageId) {
      reqBody.stageStatus = this.state.stageId
      reqBody.stageFrom = this.state.modifiedDateFrom
      reqBody.stageTo = this.state.modifiedDateTo
    }
    else {
      reqBody.modifiedDateFrom = this.state.modifiedDateFrom
      reqBody.modifiedDateTo = this.state.modifiedDateTo
    }
    this.props.onSearchClicked(reqBody);

    this.setState({
      open: false,
      customerId: "",
      issueDescription: "",
      title: "",
      acronym: "",
      volume: "",
      issue: "",
      suppNumber: "",
      partNumber: "",
      modifiedDateFrom: null,
      modifiedDateTo: null,
    });
  };
  _onCodeKeyPress(event) {
    const re = /[a-zA-Z:]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  checkButtonDisability = () => {
    let { departmentCode, departmentName } = this.state;
    if (
      this.state.errors.departmentName ||
      this.state.errors.departmentCode ||
      (!departmentCode && !departmentName)
    )
      return true;
    else return false;
  };
  handleAutocompleteCustomer = (e, val) => {
    this.setState({ customerId: val && val.id, customer: val && val });
  };
  handleAutocompleteStage = (e, val) => {
    this.setState({ stageId: val && val.id, stage: val && val });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    let { departmentCode, departmentName } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <Tooltip title="Search">
          <IconButton
            aria-label="Search"
            className={classes.iconHover}
            onClick={this.handleClickOpen}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          onClose={this.handleClose}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          maxWidth="md"
        >
          <DialogTitle id="customized-dialog-title">
            {/* <div className="AdvancedSearchtitle">Advance Search</div>  */}
            Advanced Search
          </DialogTitle>
          <DialogContent dividers>
            <div style={{ marginTop: "-10px" }}>
              <div style={{ display: "flex" }}>
                <Autocomplete
                  options={this.state.customerList}
                  value={this.state.customer}
                  onChange={this.handleAutocompleteCustomer}
                  getOptionLabel={(option) => option.text}
                  style={{ flex: 1, marginRight: "16px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Customer"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
                <TextField
                  id="standard-required"
                  label="Issue Description"
                  name="issueDescription"
                  style={{ flex: 1, marginRight: "16px" }}
                  margin="normal"
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={this.handleChange}
                  value={this.state.issueDescription}
                  autoComplete="off"
                />
                <TextField
                  id="standard-required"
                  label="Title"
                  name="title"
                  style={{ flex: 1 }}
                  margin="normal"
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={this.handleChange}
                  value={this.state.title}
                  autoComplete="off"
                />
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  id="standard-required"
                  label="Acronym"
                  name="acronym"
                  style={{ flex: 1, marginRight: "16px" }}
                  margin="normal"
                  value={this.state.acronym}
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={this.handleChange}
                  autoComplete="off"
                />
                <TextField
                  id="standard-required"
                  label="Volume"
                  name="volume"
                  style={{ flex: 1, marginRight: "16px" }}
                  margin="normal"
                  value={this.state.volume}
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={this.handleChange}
                  autoComplete="off"
                />
                <TextField
                  id="standard-required"
                  label="Issue"
                  name="issue"
                  style={{ flex: 1 }}
                  margin="normal"
                  value={this.state.issue}
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={this.handleChange}
                  autoComplete="off"
                />
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  id="standard-required"
                  label="Supp Number"
                  name="suppNumber"
                  style={{ flex: 1, marginRight: "16px" }}
                  margin="normal"
                  value={this.state.suppNumber}
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={this.handleChange}
                  autoComplete="off"
                />
                <TextField
                  id="standard-required"
                  label="Part"
                  name="partNumber"
                  style={{ flex: 1, marginRight: "16px" }}
                  margin="normal"
                  value={this.state.partNumber}
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={this.handleChange}
                  autoComplete="off"
                />
                <div style={{ flexGrow: 1 }}></div>
              </div>
              <div style={{ display: "flex" }}>
                <Autocomplete
                    options={this.state.stageList}
                    value={this.state.stage}
                    onChange={this.handleAutocompleteStage}
                    getOptionLabel={(option) => option.value}
                    style={{ flex: 2, marginRight: "7px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Stage"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                  <div style={{ flexGrow: 1 }}></div>
              </div>
              <div style={{ display: "flex" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="modifiedDateFrom"
                    label={this.state.stageId ? "Stage Date From" : "Modified Date From"}
                    style={{ flex: 1, marginRight: "16px" }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    name="modifiedDateFrom"
                    value={this.state.modifiedDateFrom}
                    onChange={(e) =>
                      this.handleDateChange(e, "modifiedDateFrom")
                    }
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disabled={!this.state.modifiedDateFrom}
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    name="modifiedDateTo"
                    minDate={this.state.modifiedDateFrom}
                    value={this.state.modifiedDateTo}
                    id="modifiedDateTo"
                    label={this.state.stageId ? "Stage Date To" : "Modified Date To"}
                    onChange={(e) => this.handleDateChange(e, "modifiedDateTo")}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
                <div style={{ flexGrow: 1 }}></div>
              </div>
              {/* <div style={{ display: "flex" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="modifiedDateFrom"
                    label="Modified Date From"
                    style={{ flex: 1, marginRight: "16px" }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    name="modifiedDateFrom"
                    value={this.state.modifiedDateFrom}
                    onChange={(e) =>
                      this.handleDateChange(e, "modifiedDateFrom")
                    }
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disabled={!this.state.modifiedDateFrom}
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    style={{ flex: 1, marginRight: "16px" }}
                    name="modifiedDateTo"
                    minDate={this.state.modifiedDateFrom}
                    value={this.state.modifiedDateTo}
                    id="modifiedDateTo"
                    label="Modified Date To"
                    onChange={(e) => this.handleDateChange(e, "modifiedDateTo")}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
                <div style={{ flexGrow: 1 }}></div>
              </div> */}
            </div>
          </DialogContent>
          <DialogActions style={{ padding: "10px 24px" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={this.handleClose}
            >
              CANCEL
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              onClick={(e) => this.onSearchClicked()}
              disabled={
                !(this.state.modifiedDateFrom
                  ? this.state.modifiedDateTo
                  : this.state.customerId ||
                  this.state.issueDescription ||
                  this.state.title ||
                  this.state.acronym ||
                  this.state.volume ||
                  this.state.stageId ||
                  this.state.issue ||
                  this.state.suppNumber ||
                  this.state.partNumber)
              }
            >
              SEARCH
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(JournalItemSearch);
