import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { ExportCSV } from "../../../shared/ExportCSV";
import moment from "moment";
import {
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  ViewIcon
} from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import {
  stableSort,
  getSorting,
  DialogActions
} from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import Validator from "../../../shared/validator";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import types from "../userAccessTypes";
import { StateContext } from "../../../shared/globalState";
import { getSubMenuList, deleteSubMenu } from "../userAccessAction";
import LinearProgress from "@material-ui/core/LinearProgress";

const columns = [
  {
    name: "Active",
    id: 6,
    show: false
  },
  {
    name: "Modified Date",
    id: 7,
    show: false
  },
  {
    name: "Modified By",
    id: 8
  },
  {
    name: "Created Date",
    id: 9
  },
  {
    name: "Created By",
    id: 10
  }
];
const validateNotRequired100 = new Validator("notRequired100");

function createData(
  subMenuID,
  menuID,
  menuName,
  subMenuName,
  description,
  orderNo,
  isReport,
  isActive,
  createdBy,
  createdDate,
  modifiedBy,
  modifiedDate
) {
  return {
    subMenuID,
    menuID,
    menuName,
    subMenuName,
    description,
    orderNo,
    isReport,
    isActive,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate
  };
}
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all subMenu" }}
              />
            }
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const EnhancedTableToolbar = props => {
  const [errors, setErrors] = useState({
    menuName: "",
    subMenuName: ""
  });
  const [formValid, setIsformValid] = useState(false);

  const [subMenuSearchValue, setValues] = useState({
    menuName: "",
    subMenuName: ""
  });

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);

  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handleDeleteSubMenu,
    searchPopupOpen,
    handleSearchPopupClose,
    handleSearchSubMenu,
    searchClicked,
    selected,
    hendleVewColums,
    exportDataValues,
    subMenuList,
    isActive
  } = props;
  const selectedSubMenuID = selected[0];
  const selectedSubMenu = subMenuList.find(
    x => x.subMenuID === selectedSubMenuID
  );

  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteSubMenu = () => {
    handleDeleteSubMenu();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = subMenuSearchValue;
    searchValues.menuName = "";
    searchValues.subMenuName = "";
    searchValues = subMenuSearchValue;
  };

  const searchPopup = () => {
    handleSearchSubMenu(subMenuSearchValue);
    clearSearchValues();
  };

  const handleSearchInput = event => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;
    setValues(subMenuSearchValue => ({
      ...subMenuSearchValue,
      [event.target.name]: event.target.value
    }));
    switch (name) {
      case "menuName":
        let menuNameValid = validateNotRequired100(value);
        setIsformValid(menuNameValid);
        setErrors(errors => ({
          ...errors,
          [event.target.name]: menuNameValid ? "" : " Please enter valid Name"
        }));
        break;
      case "subMenuName":
        let subMenuNameValid = validateNotRequired100(value);
        setIsformValid(subMenuNameValid);
        setErrors(errors => ({
          ...errors,
          [event.target.name]: subMenuNameValid
            ? ""
            : " Please enter valid Name"
        }));
        break;
      default:
        break;
    }
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = newValue => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%"
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%"
            }}
          >
            {numSelected === 1 && (
              <div>
                <Tooltip title="View">
                  <Link to={`/view_subMenu/${selectedSubMenuID}`}>
                    <IconButton aria-label="view" className={classes.iconHover}>
                      <ViewIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
                <Tooltip title="Edit">
                  <Link to={`/edit_subMenu/${selectedSubMenuID}`}>
                    <IconButton aria-label="edit">
                      <EditIcon className={classes.iconHover} />
                    </IconButton>
                  </Link>
                </Tooltip>
              </div>
            )}
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={handleClickOpen}
                disabled={!isActive}
              >
                <DeleteIcon className={classes.iconHover} />
              </IconButton>
            </Tooltip>
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete Sub Menu
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1 ? " ?" : selectedSubMenu.menuName + " ?"}
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
                <Button
                  onClick={deleteSubMenu}
                  variant="contained"
                  className="enabled-button"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%"
            }}
          >
            {searchClicked ? (
              <Tooltip title="Clear search result">
                <IconButton
                  aria-label="clear"
                  className={classes.iconHover}
                  onClick={props.clearSearch}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <form onSubmit={searchPopup}>
                <Dialog
                  disableBackdropClick
                  disableEscapeKeyDown
                  onClose={handleSearchPopupClose}
                  aria-labelledby="customized-dialog-title"
                  open={searchPopupOpen}
                  maxWidth="md"
                >
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleSearchPopupClose}
                  >
                    Advanced Search
                  </DialogTitle>
                  <DialogContent dividers>
                    <React.Fragment>
                      <div>
                        <TextField
                          id="standard-required"
                          label="SubMenu Name"
                          name="subMenuName"
                          className={classes.textField}
                          margin="normal"
                          value={subMenuSearchValue.subMenuName}
                          inputProps={{
                            maxLength: 50
                          }}
                          onChange={handleSearchInput}
                          error={errors.subMenuName}
                          helperText={
                            errors.subMenuName ? errors.subMenuName : ""
                          }
                        />
                        <TextField
                          id="standard-required"
                          label="Menu Name"
                          name="menuName"
                          className={classes.textField}
                          margin="normal"
                          value={subMenuSearchValue.menuName}
                          inputProps={{
                            maxLength: 50
                          }}
                          onChange={handleSearchInput}
                          error={errors.menuName}
                          helperText={errors.menuName ? errors.menuName : ""}
                        />
                      </div>
                    </React.Fragment>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleSearchClose}
                    >
                      CANCEL
                    </Button>

                    <Button
                      disabled={!formValid}
                      autoFocus
                      variant="contained"
                      onClick={searchPopup}
                      className={
                        !formValid ? "disabled-button" : "enabled-button "
                      }
                    >
                      SEARCH
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            )}
            <Tooltip title="Add">
              <IconButton
                aria-label="add"
                className={classes.iconHover}
                onClick={e => props.history.push("/add_subMenu")}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            <ExportCSV csvData={exportDataValues} fileName={"Sub_Menu"} />
            <Tooltip title="View Column">
              <IconButton
                aria-label="viewColumn"
                className={classes.iconHover}
                onClick={handleClickListItem}
              >
                <ViewColumnIcon />
              </IconButton>
            </Tooltip>
            <EnhancedShowColumn
              id="columns-menu"
              keepMounted
              open={open}
              onClose={handleShowColClose}
              column={column}
              hendleVewColums={hendleVewColums}
              headCells={props.headCells}
            />
          </div>
        )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px"
  };
}

const EnhancedShowColumn = props => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root
        }
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map(column => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={e => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

class SubMenuMater extends React.Component {
  static contextType = StateContext;
  state = {
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    subMenuList: [],
    activeRows: 0,
    isActive: false,
    refreshData: false,
    selectedSubMenu: "",
    selectedSubMenuId: 1,
    loading: false,
    searchPopupOpen: false,
    subMenuSearchValues: {},
    searchClicked: false,
    selectedShowColumns: [],
    exportDataValues: [],
    headCells: [
      {
        id: 1,
        code: "menuName",
        numeric: false,
        disablePadding: true,
        label: "Menu Name",
        show: true
      },
      {
        id: 2,
        code: "subMenuName",
        numeric: false,
        disablePadding: true,
        label: "Sub Menu Name",
        show: true
      },
      {
        id: 3,
        code: "description",
        numeric: false,
        disablePadding: false,
        label: "Description",
        show: true
      },
      {
        id: 4,
        code: "orderNo",
        numeric: false,
        disablePadding: false,
        label: "Order No",
        show: true
      },
      {
        id: 5,
        code: "isReport",
        numeric: false,
        disablePadding: false,
        label: "Report",
        show: true
      },
      {
        id: 6,
        code: "isActive",
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: true
      },
      {
        id: 7,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true
      },
      {
        id: 8,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true
      },
      {
        id: 9,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true
      },
      {
        id: 10,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true
      }
    ]
  };
  componentDidMount() {
    this.props.handleSettingHeader("Sub Menu");
    this.props.setSelectedList("adminSettingOpen", "subMenu");
    const dispatch = this.context[1];

    getSubMenuList(localStorage.token).then(r => {
      dispatch({
        type: types.SUBMENU_LIST,
        newStore: r.data
      });
      this.getAllSubMenus(r.data);
      this.exportSubMenus(r.data);
    });
    this.setState({ loading: true });
  }
  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };
  exportSubMenus = subMenuList => {
    let list = subMenuList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map(d => {
        return rows.push({
          MenuName: d.menuName,
          SubMenuName: d.subMenuName,
          Description: d.description,
          OrderNo: d.orderNo,
          IsReport: d.isReport,
          Active: d.isActive,
          ModifiedDate: d.modifiedDate,
          ModifiedBy: d.modifiedBy,
          CreatedDate: d.createdDate,
          CreatedBy: d.createdBy
        });
      });
    this.setState({ exportDataValues: rows });
  };
  getAllSubMenus = subMenuList => {
    let list = subMenuList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map(d => {
        return rows.push(
          createData(
            d.subMenuID,
            d.menuID,
            d.menuName,
            d.subMenuName,
            d.description,
            d.orderNo,
            d.isReport,
            d.isActive,
            d.createdBy,
            d.createdDate,
            d.modifiedBy,
            d.modifiedDate
          )
        );
      });
    this.setState({
      subMenuList: rows,
      loading: false,
      selected: [],
      activeRows: rows.filter(x => x.isActive).map(n => n.subMenuID)
    });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.subMenuList && store.subMenuList.length > 0)
      if (this.state.subMenuList.length !== store.subMenuList.length) {
        this.getAllSubMenus(store.subMenuList);
      }
    // }
    return true;
  }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = event => {
    let { subMenuList } = this.state;
    if (event.target.checked) {
      const newSelecteds = subMenuList
        .filter(x => x.isActive === true)
        .map(n => n.subMenuID);

      this.setState({ selected: newSelecteds, isActive: true });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick(event, subMenuID, subMenu) {
    let { selected } = this.state;
    const selectedIndex = selected.indexOf(subMenuID);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, subMenuID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedSubMenu: subMenu,
        selectedSubMenuId: subMenuID
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedSubMenu: subMenu,
        selectedSubMenuId: subMenuID
      });
    }
  }
  checkActiveStatus = selected => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchSubMenu = handleSearchSubMenu => {};

  handleDeleteSubMenu = () => {
    let { selected } = this.state;
    const dispatch = this.context[1];
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteSubMenu(modifiedBy, deletionIds).then(r => {
      getSubMenuList().then(r => {
        dispatch({
          type: types.SUBMENU_LIST,
          newStore: r.data
        });
        this.getAllSubMenus(r.data);
      });
      this.props.history.push("/SubMenu");
      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };

  clearSearch = () => {
    const list = this.context;
    setTimeout(() => {
      let subMenus = list.state.subMenuList;
      this.getAllSubMenus(subMenus);
    }, 1700);

    this.setState({ loading: true, searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      subMenuList,
      exportDataValues,
      headCells
    } = this.state;
    const isSelected = subMenuID => selected.indexOf(subMenuID) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handleDeleteSubMenu={this.handleDeleteSubMenu}
            selectedSubMenu={this.state.selectedSubMenu}
            selectedSubMenuId={this.state.selectedSubMenuId}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchSubMenu={this.handleSearchSubMenu}
            subMenuSearchValue={this.state.subMenuSearchValues}
            searchClicked={this.state.searchClicked}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            subMenuList={subMenuList}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 8em)",
                      maxHeight: "calc(100vh - 225px)"
                    }
                  : {
                      overflow: "auto",
                      maxWidth: "calc(100vw - 330px)",
                      maxHeight: "calc(100vh - 225px)"
                    }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
                style={{ whiteSpace: "nowrap" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={e => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {subMenuList.length > 0 ? (
                    stableSort(subMenuList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.subMenuID);
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }
                        let { statusReport } = "";
                        if (row.isReport === true) {
                          statusReport = "Yes";
                        } else {
                          statusReport = "No";
                        }
                        return (
                          <TableRow
                            hover
                            onClick={event =>
                              this.handleClick(
                                event,
                                row.subMenuID,
                                row.subMenuName
                              )
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.subMenuID}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.menuName}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.subMenuName}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.description}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.orderNo}
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {statusReport}
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {status}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.modifiedDate &&
                                row.modifiedDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.modifiedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.modifiedBy}
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdDate &&
                                row.createdDate.length > 16 ? (
                                  <Tooltip
                                    title={moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(row.createdDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </Typography>
                                )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdBy}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      {this.state.loading ? (
                        <TableCell colSpan={15} style={{ textAlign: "center" }}>
                          <LinearProgress />
                        </TableCell>
                      ) : (
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                          No result found
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={subMenuList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page"
            }}
            nextIconButtonProps={{
              "aria-label": "next page"
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(SubMenuMater);
