import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Paper, Divider } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { addDispatchStyles } from "../../../shared/styles";
import { StateContext } from "../../../shared/globalState";
import { getPerticularDispatchMode } from "../../masters/masterActions";
import { printDetails } from "../../../shared/tableCommonFunctions";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { id } = props;
  let acnArr = props.roles.filter((u) => u.menuName === "Dispatch Mode");
  let acnObj = acnArr[0];

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.editAction && (
            <Tooltip title="Edit">
              <Link to={`/edit_dispatchMode/${id}`}>
                <IconButton aria-label="edit" className={classes.iconHover}>
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewDispatchMode extends React.Component {
  static contextType = StateContext;
  state = {
    dispatchModeCode: "",
    dispatchModeName: "",
    isActive: "",
  };
  componentDidMount() {
    this.props.handleSettingHeader("DispatchMode");
    this.props.setSelectedList("masterDataOpen", "dispatchMode");
    let id = "";
    if (process.browser) id = window.location.pathname.split("/")[2];
    if (id) {
      getPerticularDispatchMode(id).then((r) => {
        this.setState({
          dispatchModeCode:
            r && r.data && r.data.dispatchModeCode && r.data.dispatchModeCode,
          dispatchModeName:
            r && r.data && r.data.dispatchModeName && r.data.dispatchModeName,
          isActive: r && r.data && r.data.isActive && r.data.isActive,
        });
      });
    }
  }

  render() {
    let { dispatchModeCode, dispatchModeName, isActive } = this.state;
    let { classes } = this.props;
    let id = "";
    if (process.browser) id = window.location.pathname.split("/")[2];
    return (
      <React.Fragment>
        <Paper>
          <div className={classes.container}>
            <EnhancedTableToolbar id={id} roles={this.props.roles} />
            <Divider />
            <div className="view-page-container" id="printable">
              <div className="view-page-headings"> View Dispatch Mode</div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Dispatch Mode Code</div>
                  <div className="view-page-value">
                    {dispatchModeCode && dispatchModeCode}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Dispatch Mode Name</div>
                  <div className="view-page-value">
                    {" "}
                    {dispatchModeName && dispatchModeName}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Active</div>
                  <div className="view-page-value">
                    {" "}
                    {isActive === true ? "Yes" : "No"}
                  </div>
                </div>
              </div>
              <Divider style={{ marginTop: "25px" }} />
              <div className="button-wrapper">
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    //disableElevation
                    style={{ boxShadow: "none" }}
                    onClick={(e) => this.props.history.push("/DispatchMode")}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addDispatchStyles)(ViewDispatchMode));
