import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { resourceValidation } from "../../../shared/resource";
import StatusText from "../../../shared/statusText";
import moment from "moment";
import AdvanceSearch from "./search";
import {
  SearchIcon,
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import {
  stableSort,
  getSorting,
  DialogActions,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import Validator from "../../../shared/validator";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import { StateContext } from "../../../shared/globalState";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ExportCSV } from "../../../shared/ExportCSV";
import {
  getPreAlertAll,
  deletePreAlert,
  getFliteredpreAlertList,
  updateJRApi,
} from "./action";
import clone from "clone";
const validateNotRequired100 = new Validator("maxlength100AllAllow");
const onlyNumbers = new Validator("onlyNumbers");

const columns = [
  {
    name: "Modified By",
    id: 25,
  },
  {
    name: "Modified Date",
    id: 26,
  },
  {
    name: "Created By",
    id: 27,
  },
  {
    name: "Created Date",
    id: 28,
  },
];

function createData(
  preAlertID,
  client,
  location,
  preAlertDate,
  office,
  issueDescription,
  acronym,
  title,
  statusID,
  volume,
  issue,
  suppNumber,
  status,
  printRun,
  processedQuantity,
  wpc,
  totalWeight,
  totalPallet,
  newWeight,
  thickness,
  catagory,
  version,
  safeOrUnsafe,
  inserted,
  cover,
  isActive,
  isOnHoldJRReport,
  isJRRGenerated,
  exceptionMessage,
  createdDate,
  createdName,
  modifiedDate,
  modifiedByName
) {
  return {
    preAlertID,
    client,
    location,
    preAlertDate,
    office,
    issueDescription,
    acronym,
    title,
    statusID,
    volume,
    issue,
    suppNumber,
    status,
    printRun,
    processedQuantity,
    wpc,
    totalWeight,
    totalPallet,
    newWeight,
    thickness,
    catagory,
    version,
    safeOrUnsafe,
    inserted,
    cover,
    isActive,
    isOnHoldJRReport,
    isJRRGenerated,
    exceptionMessage,
    createdDate,
    createdName,
    modifiedDate,
    modifiedByName,
  };
}

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    data
  } = props;
  let showSelAll = false
  data && data.length > 0 && data.map(x => {
    if (x.status === "Received")
      showSelAll = true
  })
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          {showSelAll && <FormControlLabel
            control={
              <CustomCheckbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={props.onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all pre alert" }}
              />
            }
          />}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  //rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [errors, setErrors] = useState({
    preAlert: "",
  });
  const [formValid, setIsformValid] = useState(false);

  const [preAlertSearchValue, setValues] = useState({
    preAlert: "",
  });
  let acnArr = props.roles.filter((u) => u.menuName === "Pre Alert");
  let acnObj = acnArr[0];
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handledeletepreAlertCode,
    searchPopupOpen,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchpreAlertCode,
    searchClicked,
    hendleVewColums,
    preAlertList,
    rows,
    exportDataValues,
    selected,
    isActive,
    handleSearchpreAlert,
    enableDelete,
    enableProceedButton
  } = props;
  // const selectedpreAlertID = selected && selected.length > 0
  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  const selectedpreAlertID = selected[0];
  // const selectedpreAlertID = preAlertList.find(
  //   (x) => x.preAlertID === selectedpreAlertID
  // );

  const handleClickOpen = () => {
    handleDeletePopupOpen();
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deletepreAlertCode = () => {
    handledeletepreAlertCode();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = preAlertSearchValue;
    searchValues.preAlert = "";
    searchValues = preAlertSearchValue;
  };

  const searchPopup = () => {
    handleSearchpreAlertCode(preAlertSearchValue);
    clearSearchValues();
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
  };

  const handleSearchInput = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;
    setValues((collectionNoteSearchValue) => ({
      ...collectionNoteSearchValue,
      [event.target.name]: event.target.value,
    }));
    switch (name) {
      case "title":
        let titleValid = false;
        if (value !== "") {
          titleValid = validateNotRequired100(value);
          setIsformValid(titleValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: titleValid ? "" : resourceValidation.ValidName,
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: titleValid ? "" : "",
          }));
        }
        break;
      case "acronym":
        let acronymValid = false;
        if (value !== "") {
          acronymValid = validateNotRequired100(value);
          setIsformValid(acronymValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: acronymValid
              ? ""
              : resourceValidation.validWorkOrder,
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: acronymValid ? "" : "",
          }));
        }
        break;
      case "volume":
        let volumeValid = false;
        if (value !== "") {
          volumeValid = validateNotRequired100(value);
          setIsformValid(volumeValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: volumeValid
              ? ""
              : resourceValidation.validvolume,
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: volumeValid ? "" : "",
          }));
        }
        break;
      case "issue":
        let issueValid = false;
        if (value !== "") {
          issueValid = validateNotRequired100(value);
          setIsformValid(issueValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: issueValid
              ? ""
              : resourceValidation.validJobGroup,
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: issueValid ? "" : "",
          }));
        }
        break;
      case "supp":
        let suppValid = false;
        if (value !== "") {
          suppValid = validateNotRequired100(value);
          setIsformValid(suppValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: suppValid ? "" : resourceValidation.validsupp,
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: suppValid ? "" : "",
          }));
        }
        break;
      case "category":
        let categoryValid = false;
        if (value !== "") {
          categoryValid = validateNotRequired100(value);
          setIsformValid(categoryValid);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: categoryValid
              ? ""
              : resourceValidation.validcategory,
          }));
        } else {
          setIsformValid(false);
          setErrors((errors) => ({
            ...errors,
            [event.target.name]: categoryValid ? "" : "",
          }));
        }
        break;
      default:
        break;
    }
  };

  let expData = clone(props.rows && props.rows.length > 0 && props.rows);

  expData &&
    expData.length > 0 &&
    expData.map((d) => {
      let Val = "No";
      let ValJrr = "No";
      if (d["isOnHoldJRReport"]) {
        Val = "Yes";
      }
      if (d["isJRRGenerated"]) {
        ValJrr = "Yes";
      }

      d["Customer"] = d["client"];
      d["Location"] = d["location"]
      d["Pre Alert Date"] = moment(d["preAlertDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      d["Office"] = d["office"];
      d["Issue Description"] = d["issueDescription"];
      d["Title Description"] = d["title"];
      d["Acronym"] = d["acronym"];
      d["Volume"] = d["volume"];
      d["Issue"] = d["issue"];
      d["Supplement"] = d["suppNumber"];
      d["Status"] = d["status"];
      d["Print Run"] = d["printRun"];
      d["WPC"] = d["wpc"];
      d["Total Weight"] = d["totalWeight"];
      d["On Hold JR Report"] = Val;
      d["JRR Generated"] = ValJrr;
      d["Category"] = d["catagory"];
      d["Version"] = d["version"];
      d["Safe or Unsafe"] = d["safeOrUnsafe"];
      d["Insert"] = d["inserted"];
      d["Cover"] = d["cover"];
      d["Modified By"] = d["modifiedByName"];

      d["Modified Date"] = moment(d["modifiedDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );

      d["Created By"] = d["createdName"];
      d["Created Date"] = moment(d["createdDate"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );

      delete d["isActive"];
      delete d["exceptionMessage"];
      delete d["preAlertID"];
      delete d["client"];
      delete d["location"];
      delete d["preAlertDate"];
      delete d["office"];
      delete d["issueDescription"];
      delete d["acronym"];
      delete d["title"];
      delete d["statusID"];
      delete d["volume"];
      delete d["issue"];
      delete d["suppNumber"];
      delete d["status"];
      delete d["printRun"];
      delete d["processedQuantity"];
      delete d["wpc"];
      delete d["totalWeight"];
      delete d["totalPallet"];
      delete d["newWeight"];
      delete d["thickness"];
      delete d["catagory"];
      delete d["version"];
      delete d["safeOrUnsafe"];
      delete d["inserted"];
      delete d["cover"];
      delete d["isOnHoldJRReport"];
      delete d["isJRRGenerated"];
      delete d["exceptionMessage"];
      delete d["createdDate"];
      delete d["createdName"];
      delete d["modifiedDate"];
      delete d["modifiedByName"];
    });
  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 && (
          <div color="inherit" variant="subtitle1" className="row-selected-div">
            {numSelected} row(s) selected
          </div>
        )}
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <div style={{ marginTop: "5px" }}>
              {acnObj && acnObj.editAction &&
                enableProceedButton && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: "none" }}
                    onClick={props.updateJRButton}
                  >
                    Journal Processed
                  </Button>
                )}
            </div>
            <div style={{ height: "48px", visibility: "hidden" }}></div>
            {numSelected === 1 && (
              <div>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <Link to={`/view_preAlert/${selectedpreAlertID}`}>
                      <IconButton
                        aria-label="view"
                        className={classes.iconHover}
                      >
                        <ViewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
                {acnObj &&
                  acnObj.editAction &&
                  props.selectedpreAlertData[0].status === "Received" &&
                  (
                    <Tooltip title="Edit">
                      <Link to={`/edit_preAlert/${selectedpreAlertID}`}>
                        <IconButton
                          aria-label="edit"
                          className={classes.iconHover}
                        >
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  )}
              </div>
            )}
            {acnObj &&
              acnObj.deleteAction && enableDelete && (
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    onClick={handleClickOpen}
                    disabled={!isActive}
                  >
                    <DeleteIcon className={classes.iconHover} />
                  </IconButton>
                </Tooltip>
              )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete the Pre Alert ?
                </div>
              </DialogContent>
              <DialogActions style={{ padding: "10px 16px" }}>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deletepreAlertCode}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  acnObj &&
                  acnObj.advanceSearchAction && (
                    <AdvanceSearch
                      onSearchClicked={props.onSearchClicked}
                      onRequestSucceed={props.onRequestSucceed}
                    // searchMode={selectedTable}
                    />
                  )
                )}
              {acnObj && acnObj.addAction && (
                <Tooltip title="Add">
                  <IconButton
                    aria-label="add"
                    className={classes.iconHover}
                    onClick={(e) => props.history.push("/add_preAlert")}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}

              {acnObj && acnObj.exportAction && (
                <ExportCSV csvData={expData} fileName={"PreAlert"} />
              )}

              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                hendleVewColums={hendleVewColums}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class PreAlert extends React.Component {
  static contextType = StateContext;

  state = {
    enableDelete: true,
    enableProceedButton: true,
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    preAlertList: [],
    rows: [],
    activeRows: 0,
    isActive: false,
    loading: false,
    refreshData: false,
    selectedpreAlertID: {},
    selectedpreAlertIDId: 1,
    selectedpreAlertData: [],
    searchPopupOpen: false,
    preAlertSearchValues: {},
    searchClicked: false,
    selectedShowColumns: [],
    exportDataValues: [],
    headCells: [
      {
        id: 1,
        code: "client",
        numeric: false,
        disablePadding: false,
        label: "Customer",
        show: true,
      },
      {
        id: 2,
        code: "location",
        numeric: false,
        disablePadding: false,
        label: "Location",
        show: true,
      },
      {
        id: 3,
        code: "preAlertDate",
        numeric: false,
        disablePadding: false,
        label: "Pre Alert Date",
        show: true,
      },
      {
        id: 4,
        code: "office",
        numeric: false,
        disablePadding: false,
        label: "Office",
        show: true,
      },
      {
        id: 5,
        code: "issueDescription",
        numeric: false,
        disablePadding: false,
        label: "Issue Description",
        show: true,
      },
      {
        id: 6,
        code: "title",
        numeric: false,
        disablePadding: false,
        label: "Title Description",
        show: true,
      },
      {
        id: 7,
        code: "acronym",
        numeric: false,
        disablePadding: false,
        label: "Acronym",
        show: true,
      },
      {
        id: 8,
        code: "volume",
        numeric: false,
        disablePadding: false,
        label: "Volume",
        show: true,
      },
      //issue
      {
        id: 9,
        code: "issue",
        numeric: false,
        disablePadding: false,
        label: "Issue",
        show: true,
      },
      //supp
      {
        id: 10,
        code: "suppNumber",
        numeric: false,
        disablePadding: false,
        label: "Supplement",
        show: true,
      },
      //status
      {
        id: 11,
        code: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        show: true,
      },
      //jrecieveddate
      {
        id: 12,
        code: "jRecievedDate",
        numeric: false,
        disablePadding: false,
        label: "JRecievedDate",
        show: false,
      },
      //printRun
      {
        id: 13,
        code: "printRun",
        numeric: false,
        disablePadding: false,
        label: "Print Run",
        show: true,
      },
      //wpc
      {
        id: 14,
        code: "wpc",
        numeric: false,
        disablePadding: false,
        label: "WPC",
        show: true,
      },
      //totalweight
      {
        id: 15,
        code: "totalWeight",
        numeric: false,
        disablePadding: false,
        label: "Total Weight",
        show: true,
      },
      //onholdjrreport
      {
        id: 16,
        code: "isOnHoldJRReport",
        numeric: false,
        disablePadding: false,
        label: "On Hold JR Report",
        show: true,
      },
      //jrr generated
      {
        id: 17,
        code: "isJRRGenerated",
        numeric: false,
        disablePadding: false,
        label: "JRR Generated",
        show: true,
      },
      //category
      {
        id: 18,
        code: "catagory",
        numeric: false,
        disablePadding: false,
        label: "Category",
        show: true,
      },
      //version
      {
        id: 19,
        code: "version",
        numeric: false,
        disablePadding: false,
        label: "Version",
        show: true,
      },
      //safeorunsafe
      {
        id: 20,
        code: "safeOrUnsafe",
        numeric: false,
        disablePadding: false,
        label: "Safe Or Unsafe",
        show: true,
      },
      //insert
      {
        id: 21,
        code: "inserted",
        numeric: false,
        disablePadding: false,
        label: "Insert",
        show: true,
      },
      //specialinstruction
      {
        id: 22,
        code: "specialInstruction",
        numeric: false,
        disablePadding: false,
        label: "Special Instruction",
        show: false,
      },
      //cover
      {
        id: 23,
        code: "cover",
        numeric: false,
        disablePadding: false,
        label: "Cover",
        show: true,
      },
      {
        id: 24,
        code: "isActive",
        numeric: false,
        disablePadding: false,
        label: "Active",
        show: false,
      },
      {
        id: 25,
        code: "modifiedByName",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
      },
      {
        id: 26,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
      },
      {
        id: 27,
        code: "createdName",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
      },
      {
        id: 28,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
      },
    ],
    statusList: [],
    IdsList: [],
  };

  componentDidMount() {
    this.props.handleSettingHeader("Pre Alert");
    this.props.setSelectedList("publisherMainLabelOpen", "pubPreAlert");
    const dispatch = this.context[1];

    getPreAlertAll().then((r) => {

      this.getPreAlert(r.data);
      this.exportpreAlertDatas(r.data);
    });

    this.setState({ loading: true });
  }

  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  exportpreAlertDatas = (preAlertList) => {
    let list = preAlertList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          preAlertID: d.preAlertID,
          client: d.client,
          location: d.locationName,
          preAlertDate: d.preAlertDate,
          office: d.office,
          issueDescription: d.issueDescription,
          titleDescription: d.title,
          acronym: d.acronym,
          volume: d.volume,
          issue: d.issue,
          suppNumber: d.suppNumber,
          status: d.status,
          jRecievedDate: d.jRecievedDate,
          printRun: d.printRun,
          wpc: d.wpc,
          totalWeight: d.totalWeight,
          isOnHoldJRReport: d.isOnHoldJRReport == true ? "Yes" : "No",
          isJRRGenerated: d.isJRRGenerated == true ? "Yes" : "No",
          catagory: d.category,
          version: d.version,
          safeOrUnsafe: d.safeOrUnsafe,
          inserted: d.inserted,
          specialInstruction: d.specialInstruction,
          cover: d.cover,
          isActive: d.isActive === true ? "Yes" : "No",
          createdName: d.createdName,
          createdDate: d.createdDate,
          modifiedByName: d.modifiedByName,
          modifiedDate: d.modifiedDate,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getPreAlert = async (lpList) => {
    let list = lpList;
    let newRows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        newRows.push(
          createData(
            d.preAlertID,
            d.client,
            d.locationName,
            d.preAlertDate,
            d.office,
            d.issueDescription,
            d.acronym,
            d.title,
            d.statusID,
            d.volume,
            d.issue,
            d.suppNumber,
            d.status,
            d.printRun,
            d.processedQuantity,
            d.wpc,
            d.totalWeight,
            d.totalPallet,
            d.newWeight,
            d.thickness,
            d.catagory,
            d.version,
            d.safeOrUnsafe,
            d.inserted,
            d.cover,
            d.isActive,
            d.isOnHoldJRReport,
            d.isJRRGenerated,
            d.exceptionMessage,
            d.createdDate,
            d.createdName,
            d.modifiedDate,
            d.modifiedByName
          )
        );
      });
    await this.setState({
      rows: newRows,
      selected: [],
      selectedpreAlertData: [],

      loading: false,
      activeRows: newRows.filter((x) => x.status === "Received").map((n) => n.preAlertID),
    });
  };

  // shouldComponentUpdate(s, p) {
  //   const { store } = this.context[0];
  //   if (store.preAlertList && store.preAlertList.length > 0)
  //     if (
  //       this.state.preAlertList.length !== store.preAlertList.length &&
  //       !this.state.searchClicked
  //     ) {
  //       // !this.state.searchClicked && this.getpreAlert(store.preAlertList);
  //     }
  //   return true;
  // }

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  // handleClick(event, code, data, id) {
  //   let newSelected = [];
  //   let selected = [];
  //   let newSelectedRows = [];

  //   if (event.target.checked) {
  //     newSelected = newSelected.concat(selected, id);
  //     newSelectedRows = newSelected.concat(id);

  //     this.checkActiveStatus(newSelected);
  //     this.setState({
  //       selected: newSelected,
  //       selectedpreAlertID: id,
  //       selectedpreAlertData: data,
  //     });
  //   } else {
  //     this.checkActiveStatus(newSelected);
  //     this.setState({
  //       selected: newSelected,
  //       selectedpreAlertID: id,
  //       selectedpreAlertData: data,
  //     });
  //   }
  // }
  handleClick = (event, code, data, id) => {
    //console.log(data);

    let { selected, selectedpreAlertData } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    let newSelectedpreAlertData = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      newSelectedpreAlertData = newSelectedpreAlertData.concat(selectedpreAlertData, data);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedpreAlertData = newSelectedpreAlertData.concat(selectedpreAlertData.slice(1));

    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedpreAlertData = newSelectedpreAlertData.concat(selectedpreAlertData.slice(0, -1));

    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      newSelectedpreAlertData = newSelectedpreAlertData.concat(
        selectedpreAlertData.slice(0, selectedIndex),
        selectedpreAlertData.slice(selectedIndex + 1)
      );
    }
    //console.log(newSelected);
    if (newSelected.length === 1) {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        selectedpreAlertData: newSelectedpreAlertData,
        selectedpreAlertID: id,
      });
    } else {
      this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        // selectedpreAlertData: {}
        selectedpreAlertData: newSelectedpreAlertData,
        selectedpreAlertID: id,
      });
    }
    let enableProceedButton = true
    let enableDelete = true
    newSelectedpreAlertData && newSelectedpreAlertData.length > 0 && newSelectedpreAlertData.map(d => {
      if (d.status !== "Received") {
        enableDelete = false
        enableProceedButton = false
      }
      this.setState({ enableProceedButton, enableDelete })
    })
    // console.log(newSelectedpreAlertData, enableProceedButton)
  };
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handledeletepreAlertCode = () => {
    let { selected } = this.state;
    const [{ store }, dispatch] = this.context;
    let modifiedBy = localStorage.loggedInId;
    let deletionIds = selected.join(",");
    deletePreAlert(modifiedBy, deletionIds).then((r) => {
      getPreAlertAll().then((r) => {

        this.getPreAlert(r.data);
        this.exportpreAlertDatas(r.data);
      });
      // this.props.history.push("/CollectionNote");
      this.setState({ deletePopupOpen: false, searchClicked: false, });
      this.setState({ selected: [], selectedpreAlertData: [] });

    });
  };

  onSearchClicked = (reqBody) => {
    this.setState({ rows: [], loading: true });
    getFliteredpreAlertList(reqBody).then((r) => {
      this.getPreAlert(r);
    });

    this.setState({ searchClicked: true, page: 0, loading: true });
  };

  clearSearch = () => {
    this.setState({ rows: [], loading: true });
    getPreAlertAll().then((r) => {
      this.getPreAlert(r.data);

    });

    this.setState({ searchClicked: false, loading: true });
  };

  updateJRButton = () => {
    this.setState({ jpdPopupOpen: true })

  };
  onProceedClicked = async () => {
    let preAlertHeaderIDs = this.state.selected.join(",");
    let modifiedBy = window.localStorage.loggedInId;
    let reqBody = {
      modifiedBy,
      preAlertHeaderIDs,
    };
    await updateJRApi(reqBody).then((rr) => {
      getPreAlertAll().then((r) => {
        this.getPreAlert(r.data);
        this.exportpreAlertDatas(r.data);
        this.setState({ jpdPopupOpen: false, searchClicked: false, })
      });
    });
  }
  handleSelectAllClick = (event) => {
    let { rows, selectedpreAlertData } = this.state;
    if (event.target.checked) {
      const newSelecteds = rows
        .filter((x) => x.status === "Received")
        .map((n) => n.preAlertID);
      const newSelecteData = rows
        .filter((x) => x.status === "Received")
      let enableProceedButton = true
      let enableDelete = true
      // newSelecteds && newSelecteds.length > 0 && newSelecteds.map(d => {
      //   if (d.status !== "Received") {
      //     console.log(d)
      //     enableDelete = false
      //     enableProceedButton = false
      //   }
      this.setState({ enableProceedButton, enableDelete })
      // })
      this.setState({ selected: newSelecteds, isActive: true, selectedpreAlertData: newSelecteData });
      return;
    }
    this.setState({ selected: [], selectedpreAlertData: [] });
  };
  handleCloseJPD = () => {
    this.setState({ jpdPopupOpen: false })
  }
  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      preAlertList,

      headCells,
      exportDataValues,
    } = this.state;
    const isSelected = (preAlertID) => selected.indexOf(preAlertID) !== -1;
    return (
      <div>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          onClose={this.handleCloseJPD}
          aria-labelledby="customized-dialog-title"
          open={this.state.jpdPopupOpen}
          maxWidth="lg"
        >

          <DialogContent dividers>
            <div style={{ padding: "2em 2em" }}>
              <div style={{ minWidth: "400px" }}>
                <span style={{ fontSize: "20px", fontWeight: "500" }}>
                  Are you sure you want to change the status as Processed{" "} ?

                  {/* {selected.length > 1 ? " ?" : selectedItemMaster.stockName} */}
                  {/* {selectedItemMaster.stockName} */}
                </span>
              </div>
              <div
                style={{
                  marginTop: "2em",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={this.handleCloseJPD}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none", marginRight: "1em" }}
                >
                  Cancel
                    </Button>
                <Button
                  onClick={this.onProceedClicked}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Ok
              </Button>
              </div>
            </div>
          </DialogContent>

        </Dialog>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            selectedpreAlertData={this.state.selectedpreAlertData}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handledeleteProductionLineCode={this.handledeleteProductionLineCode}
            selectedpreAlertID={this.state.selectedpreAlertID}
            selectedpreAlertIDId={this.state.selectedpreAlertID}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchCptCode={this.handleSearchCptCode}
            cptSearchValue={this.state.cptSearchValues}
            searchClicked={this.state.searchClicked}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            preAlertList={preAlertList}
            rows={this.state.rows}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            onSearchClicked={this.onSearchClicked}
            clearSearch={this.clearSearch}
            handledeletepreAlertCode={this.handledeletepreAlertCode}
            updateJRButton={this.updateJRButton}
            roles={this.props.roles}
            enableDelete={this.state.enableDelete}
            enableProceedButton={this.state.enableProceedButton}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
                style={{ whiteSpace: "nowrap" }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={e => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                  data={this.state.rows}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {this.state.rows.length > 0 ? (
                    stableSort(this.state.rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {

                        const isItemSelected = isSelected(row.preAlertID);
                        let { status } = "";
                        if (row.isActive === true) {
                          status = "Yes";
                        } else {
                          status = "No";
                        }

                        let { isOnHoldJRReport } = "";
                        if (row.isOnHoldJRReport == true) {
                          isOnHoldJRReport = "Yes";
                        } else {
                          isOnHoldJRReport = "No";
                        }

                        let { isJRRGenerated } = "";
                        if (row.isJRRGenerated === true) {
                          isJRRGenerated = "Yes";
                        } else {
                          isJRRGenerated = "No";
                        }
                        // console.log(isItemSelected)

                        return (
                          <TableRow
                            hover
                            // onClick={(event) => this.handleClick(event, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.preAlertID}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    onClick={(event) =>
                                      this.handleClick(
                                        event,
                                        row.coustomerName,
                                        row,
                                        row.preAlertID
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.client && row.client.length > 16 ? (
                                      <Tooltip title={row.client}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.client}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.client}
                                        </Typography>
                                      )}
                                  </Typography>
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.location && row.location.length > 16 ? (
                                      <Tooltip title={row.location}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.location}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.location}
                                        </Typography>
                                      )}
                                  </Typography>
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.preAlertDate &&
                                  row.preAlertDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.preAlertDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.preAlertDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.preAlertDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.office && row.office.length > 10 ? (
                                    <Tooltip title={row.office}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.office}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.office}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.issueDescription &&
                                    row.issueDescription.length > 10 ? (
                                      <Tooltip title={row.issueDescription}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.issueDescription}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.issueDescription}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {" "}
                                  {row.title && row.title.length > 10 ? (
                                    <Tooltip title={row.title}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.title}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.title}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.acronym && row.acronym.length > 10 ? (
                                  <Tooltip acronym={row.acronym}>
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.acronym}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.acronym}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.volume && row.volume.length > 10 ? (
                                    <Tooltip volume={row.volume}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.volume}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.volume}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.issue && row.issue.length > 10 ? (
                                    <Tooltip issue={row.issue}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.issue}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.issue}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.suppNumber &&
                                    row.suppNumber.length > 10 ? (
                                      <Tooltip suppNumber={row.suppNumber}>
                                        <Typography
                                          noWrap
                                          style={{ fontSize: "14px" }}
                                        >
                                          {row.suppNumber}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.suppNumber}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.status && row.status.length > 10 ? (
                                    <Tooltip status={row.status}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.status == "Submitted" || row.status == "Completed" || row.status == "Received" || row.status == "Complete" ? (
                                          <StatusText color={"#16831F"}>
                                            {row.status}
                                          </StatusText>
                                        ) : row.status == "UnSubmitted" || row.status == "Ready" ? (
                                          <StatusText color={"#F4B43C"}>
                                            {row.status}
                                          </StatusText>
                                        ) : row.status == "Processed" || row.status == "InProgress" ? (
                                          <StatusText color={"#0091ff"}>
                                            {row.status}
                                          </StatusText>
                                        ) : row.status == "Cancelled" || row.status == "OnHold" ? (
                                          <StatusText color={"red"}>
                                            {row.status}
                                          </StatusText>
                                        ) : (
                                                  <StatusText color={"grey"}>
                                                    {row.status}
                                                  </StatusText>
                                                )
                                        }
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.status == "Received" ? (
                                          <StatusText color={"green"}>
                                            Received
                                          </StatusText>
                                        ) : row.status == "Cancelled" ? (
                                          <StatusText color={"red"}>
                                            Cancelled
                                          </StatusText>
                                        ) : row.status == "Processed" ? (
                                          <StatusText color={"rgb(0, 145, 255)"}>
                                            Processed
                                          </StatusText>
                                        ) : (
                                                <StatusText color={"grey"}>
                                                  {row.status}
                                                </StatusText>
                                              )}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.status && row.status.length > 10 ? (
                                    <Tooltip status={row.status}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.jRecievedDate}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.jRecievedDate}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}
                            {this.state.headCells[12].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="right"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.printRun && row.printRun.length > 10 ? (
                                    <Tooltip printRun={row.printRun}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.printRun}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.printRun}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[13].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="right"
                              >
                                <Typography noWrap style={{ fontSize: "14px" }}>
                                  {row.wpc && row.wpc.length > 10 ? (
                                    <Tooltip wpc={row.wpc}>
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.wpc}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.wpc}
                                      </Typography>
                                    )}
                                </Typography>
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[14].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                align="right"
                              >
                                <Tooltip
                                  title={row.totalWeight}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.totalWeight}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[15].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Tooltip
                                  title={
                                    isOnHoldJRReport == "Yes"
                                      ? isOnHoldJRReport
                                      : ""
                                  }
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {isOnHoldJRReport == "Yes"
                                      ? isOnHoldJRReport
                                      : ""}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[16].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Tooltip
                                  title={isJRRGenerated}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {isJRRGenerated}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[17].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Tooltip title={row.catagory} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.catagory}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[18].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Tooltip title={row.version} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.version}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[19].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Tooltip
                                  title={row.safeOrUnsafe}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.safeOrUnsafe}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[20].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Tooltip title={row.inserted} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.inserted}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[21].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Tooltip
                                  title={row.specialInstruction}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.specialInstruction}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            )}{" "}
                            {this.state.headCells[22].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                <Tooltip title={row.cover} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.cover}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            )}
                            {this.state.headCells[23].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                <Tooltip title={row.status} aria-label="add">
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.status}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            )}
                            {this.state.headCells[24].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {" "}
                                <Tooltip
                                  title={row.modifiedByName}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.modifiedByName}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            )}
                            {this.state.headCells[25].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-right-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {" "}
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[26].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                <Tooltip
                                  title={row.createdName}
                                  aria-label="add"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.createdName}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            )}
                            {this.state.headCells[27].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-right-cell"
                                visibility="hidden"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={8} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={this.state.rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}
export default withRouter(PreAlert);
