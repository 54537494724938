import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import clone from "clone";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { resourceValidation } from "../../../shared/resource";
import moment from "moment";
import {
  SearchIcon,
  ViewColumnIcon,
  EditIcon,
  AddIcon,
  ViewIcon,
} from "../../../shared/customIcons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import {
  stableSort,
  getSorting,
  DialogActions,
  CustomCheckbox,
} from "../../../shared/tableCommonFunctions";
import { useToolbarStyles, useShownColumnStyles } from "../../../shared/styles";
import Validator from "../../../shared/validator";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import { StateContext } from "../../../shared/globalState";
import AddRecipientList from "../recipientList/add";
import EditRecipientList from "../recipientList/edit";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ExportCSV } from "../../../shared/ExportCSV";
import { Autocomplete } from "@material-ui/lab";
import {
  getrecipientList,
  getRecipientList,
  getFliteredRecipientList,
  deleteRecipient,
} from "./action";
import { getCountryDropdownList } from "../../masters/masterActions";

const columns = [
  {
    name: "Modified Date",
    id: 9,
    // show: false
  },
  {
    name: "Modified By",
    id: 10,
  },
  {
    name: "Created Date",
    id: 11,
  },
  {
    name: "Created By",
    id: 12,
  },
];
const notRequiredAlpNumHypDot50 = new Validator("notRequiredAlpNumHypDot50");

function createData(
  address1,
  address2,
  bpaRecipientID,
  companyName,
  contactPersonName,
  countryID,
  countryName,
  createdByName,
  createdDate,
  department,
  modifiedByName,
  modifiedDate,
  phoneNumber,
  postalCode
) {
  return {
    address1,
    address2,
    bpaRecipientID,
    companyName,
    contactPersonName,
    countryID,
    countryName,
    createdByName,
    createdDate,
    department,
    modifiedByName,
    modifiedDate,
    phoneNumber,
    postalCode,
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="table-cell">
          {/* <FormControlLabel
            control={
              <CustomCheckbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                name="all"
                inputProps={{ "aria-label": "select all Recipients" }}
              />
            }
          /> */}
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            headCell.show === true && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.code ? order : false}
                className={
                  i !== headCells.length - 1 ? "headcells" : "headcell"
                }
                style={{ maxWidth: headCell.width }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.code)}
                >
                  <span style={{ color: "#306dca" }}>{headCell.label}</span>
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTableToolbar = (props) => {
  // const [errors, setErrors] = useState({
  //   customer: "",
  //   contactPersonName: "",
  //   department: "",
  //   country: "",
  //   postalCode: "",
  //   phoneNumber: "",
  // });
  const [formValid, setIsformValid] = useState(false);

  const [recipientSearchValue, setValues] = useState({
    companyName: "",
    contactPersonName: "",
    department: "",
    postalCode: "",
    phoneNumber: "",
    countryID: 0,
  });
  var BPAMarketManage = props.roles.filter((r) => {
    return r.superMenuName === "BPA Markerting";
  });
  let BPAorderplaceManage =
    BPAMarketManage.length > 0 &&
    BPAMarketManage.filter((u) => u.menuName === "BPA Recipient List");
  var acnObj = BPAorderplaceManage[0];
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleDeletePopupOpen,
    deletePopupOpen,
    handleDeletePopupClose,
    handledeleteProductionLineCode,
    searchPopupOpen,
    handleSearchPopupOpen,
    handleSearchPopupClose,
    handleSearchrecipientCode,
    searchClicked,
    hendleVewColums,
    recipientList,
    exportDataValues,
    selected,
    isActive,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [column, setColumnIds] = React.useState([]);
  const [addOpen, setAddOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [viewOpen, setViewOpen] = React.useState(false);

  const selectedRecipientID = selected[0];
  const selectedrecipientID = recipientList.find(
    (x) => x.recipientID === selectedRecipientID
  );

  let expData = clone(recipientList);
  expData.map((d) => {
    d["Company Name"] = d["companyName"];
    d["Department"] = d["department"];
    d["Country"] = d["countryName"];
    d["Contact Person"] = d["contactPersonName"];
    d["Phone Number"] = d["phoneNumber"];
    d["Address 1"] = d["address1"];
    d["Address 2"] = d["address2"];
    d["Postal Code"] = d["postalCode"];
    d["Modified Date"] = moment(d["modifiedDate"]).format(
      "DD/MM/YYYY HH:mm:ss"
    );
    d["Modified By"] = d["modifiedByName"];
    d["Created Date"] = moment(d["createdDate"]).format("DD/MM/YYYY HH:mm:ss");

    d["Created By"] = d["createdByName"];
    delete d["address1"];
    delete d["address2"];
    delete d["phoneNumber"];
    delete d["postalCode"];
    delete d["countryID"];
    delete d["countryName"];
    delete d["modifiedByName"];
    delete d["postalCode"];

    delete d["contactPersonName"];
    delete d["createdDate"];
    delete d["modifiedDate"];
    delete d["createdByName"];
    delete d["department"];
    delete d["companyName"];
    delete d["bpaRecipientID"];
  });

  const handleAddOpen = () => {
    setEditOpen(false);
    setAddOpen(true);
    setViewOpen(false);
  };

  const handleAddClose = () => {
    setAddOpen(false);
  };
  const handleViewOpen = () => {
    setViewOpen(true);
  };

  const handleViewClose = () => {
    setViewOpen(false);
  };
  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleClickOpen = () => {
    handleDeletePopupOpen();
    setAddOpen(false);
  };

  const handleClose = () => {
    handleDeletePopupClose();
  };

  const deleteProductionLineCode = () => {
    handledeleteProductionLineCode();
  };

  const handleSearchOpen = () => {
    handleSearchPopupOpen();
  };

  const handleSearchClose = () => {
    clearSearchValues();
    handleSearchPopupClose();
  };

  const clearSearchValues = () => {
    let searchValues = { ...recipientSearchValue };
    searchValues.companyName = "";
    searchValues.contactPersonName = "";
    searchValues.department = "";
    searchValues.countryName = "";
    searchValues.countryID = null;
    searchValues.postalCode = "";
    searchValues.phoneNumber = "";
    setValues({ recipientSearchValue: searchValues });
  };

  const searchPopup = () => {
    handleSearchrecipientCode(recipientSearchValue);
    clearSearchValues();
  };
  const handleSearchInputCountry = (event, value) => {
    setValues({
      ...recipientSearchValue,
      ["countryName"]: value && value.text,
      ["countryID"]: value && value.id,
    });
  };
  const handleSearchInputCompany = (event) => {
    event.persist();
    let value = event.target.value;

    setValues({
      ...recipientSearchValue,
      ["companyName"]: value && value,
    });
  };
  const handleSearchInputPerson = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;

    setValues({
      ...recipientSearchValue,
      ["contactPersonName"]: value,
    });
  };
  const handleSearchInputPhone = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;
    setValues({
      ...recipientSearchValue,
      ["phoneNumber"]: value,
    });
  };
  const handleSearchInputPostal = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;

    setValues({
      ...recipientSearchValue,
      ["postalCode"]: value,
    });
  };
  const handleSearchInputDepartment = (event) => {
    event.persist();
    let value = event.target.value;
    let name = event.target.name;

    setValues({
      ...recipientSearchValue,
      ["department"]: value,
    });
  };

  //   switch (name) {
  //     case "customer":
  //       let customer = notRequiredAlpNumHypDot50(value);
  //       setIsformValid(customer);
  //       setErrors((errors) => ({
  //         ...errors,
  //         [event.target.name]: customer ? "" : resourceValidation.customerName,
  //       }));
  //       break;
  //     case "contactPerson":
  //       let contactPerson = notRequiredAlpNumHypDot50(value);
  //       setIsformValid(contactPerson);
  //       setErrors((errors) => ({
  //         ...errors,
  //         [event.target.name]: contactPerson
  //           ? ""
  //           : resourceValidation.customerName,
  //       }));
  //       break;
  //     case "department":
  //       let department = notRequiredAlpNumHypDot50(value);
  //       setIsformValid(department);
  //       setErrors((errors) => ({
  //         ...errors,
  //         [event.target.name]: department ? "" : " Please select department",
  //       }));
  //       break;
  //     case "country":
  //       let country = notRequiredAlpNumHypDot50(value);
  //       setIsformValid(country);
  //       setErrors((errors) => ({
  //         ...errors,
  //         [event.target.name]: country ? "" : resourceValidation.country,
  //       }));
  //       break;
  //     case "postalCode":
  //       let postalCode = notRequiredAlpNumHypDot50(value);
  //       setIsformValid(postalCode);
  //       setErrors((errors) => ({
  //         ...errors,
  //         [event.target.name]: postalCode ? "" : resourceValidation.postalCode,
  //       }));
  //       break;
  //     case "phoneNumber":
  //       let phoneNumber = notRequiredAlpNumHypDot50(value);
  //       setIsformValid(phoneNumber);
  //       setErrors((errors) => ({
  //         ...errors,
  //         [event.target.name]: phoneNumber
  //           ? ""
  //           : resourceValidation.phoneNumber,
  //       }));
  //       break;

  //     default:
  //       break;
  //   }
  // };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleShowColClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setColumnIds(newValue);
    }
  };

  const _onKeyPress = (event) => {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  };
  const _onMobileNumberKeyPress = (event) => {
    const re = /[0-9+()-]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  };
  const _onTextPlusAllPress = (event) => {
    const re = /[a-z A-Z0-9=.,;()&@]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {numSelected > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {numSelected === 1 && (
              <div style={{ display: "flex" }}>
                {acnObj && acnObj.viewAction && (
                  <Tooltip title="View">
                    <IconButton
                      aria-label="view"
                      className={classes.iconHover}
                      onClick={handleViewOpen}
                    >
                      <ViewIcon />
                    </IconButton>
                  </Tooltip>
                )}

                {viewOpen && (
                  <EditRecipientList
                    handleDuplicateClose={handleViewClose}
                    duplicateOpen={viewOpen}
                    history={props.history}
                    selectedID={selected && selected[0]}
                    viewMode={props.viewMode}
                    change={props.change}
                  />
                )}
                {acnObj && acnObj.editAction && (
                  <Tooltip title="Edit">
                    <IconButton
                      aria-label="edit"
                      className={classes.iconHover}
                      onClick={handleEditOpen}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {editOpen && (
                  <EditRecipientList
                    handleDuplicateClose={handleEditClose}
                    duplicateOpen={editOpen}
                    history={props.history}
                    selectedID={selected && selected[0]}
                    change={props.change}
                  />
                )}
              </div>
            )}
            {acnObj && acnObj.deleteAction && (
              <Tooltip title="Delete">
                <IconButton aria-label="delete" onClick={handleClickOpen}>
                  <DeleteIcon className={classes.iconHover} />
                </IconButton>
              </Tooltip>
            )}
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={deletePopupOpen}
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Delete
              </DialogTitle>
              <DialogContent dividers>
                <div style={{ minWidth: "400px" }}>
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "600" }}>
                    {selected.length > 1 ? " ?" : props.namedelete + " ?"}
                  </span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Close
                </Button>
                <Button
                  onClick={deleteProductionLineCode}
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {searchClicked ? (
                <Tooltip title="Clear search result">
                  <IconButton
                    aria-label="clear"
                    className={classes.iconHover}
                    onClick={props.clearSearch}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                  <form onSubmit={searchPopup}>
                    {acnObj && acnObj.advanceSearchAction && (
                      <Tooltip title="Search">
                        <IconButton
                          aria-label="Search"
                          className={classes.iconHover}
                          onClick={handleSearchOpen}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      onClose={handleSearchPopupClose}
                      aria-labelledby="customized-dialog-title"
                      open={searchPopupOpen}
                      maxWidth="lg"
                    >
                      <DialogTitle
                        id="customized-dialog-title"
                        onClose={handleSearchPopupClose}
                      >
                        Advanced Search
                  </DialogTitle>
                      <DialogContent dividers>
                        <React.Fragment>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "20px",
                            }}
                          >
                            <TextField
                              id="standard-required"
                              label="Company"
                              name="companyName"
                              className={classes.textField}
                              margin="normal"
                              value={recipientSearchValue.companyName}
                              inputProps={{
                                maxLength: 50,
                              }}
                              style={{ width: "33.33%", margin: "0" }}
                              autoComplete="off"
                              onChange={handleSearchInputCompany}
                              onKeyPress={_onTextPlusAllPress}
                            //   error={errors.recipient}
                            //   helperText={errors.recipient ? errors.recipient : ""}
                            />
                            <TextField
                              id="standard-required"
                              label="Contact Person"
                              name="contactPersonName"
                              className={classes.textField}
                              margin="normal"
                              value={recipientSearchValue.contactPersonName}
                              inputProps={{
                                maxLength: 50,
                              }}
                              style={{ width: "33.33%", margin: "0 15px" }}
                              autoComplete="off"
                              onChange={handleSearchInputPerson}
                              onKeyPress={_onTextPlusAllPress}
                            //   error={errors.recipient}
                            //   helperText={errors.recipient ? errors.recipient : ""}
                            />
                            <TextField
                              id="standard-required"
                              label="Department"
                              name="department"
                              className={classes.textField}
                              margin="normal"
                              value={recipientSearchValue.department}
                              inputProps={{
                                maxLength: 50,
                              }}
                              style={{ width: "33.33%", margin: "0" }}
                              autoComplete="off"
                              onChange={handleSearchInputDepartment}
                              onKeyPress={_onTextPlusAllPress}
                            //   error={errors.department}
                            //   helperText={errors.department ? errors.department : ""}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "8px 0 10px",
                            }}
                          >
                            <Autocomplete
                              includeInputInList
                              label="Country"
                              style={{ width: "33.33%" }}
                              name="countryName"
                              options={props.countryList}
                              onChange={handleSearchInputCountry}
                              value={recipientSearchValue.countryName}
                              getOptionLabel={(option) => option.text}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Country"
                                  name="country"
                                  style={{
                                    marginTop: "0px",
                                    marginLeft: "0 15px",
                                    width: "100%",
                                  }}
                                />
                              )}
                            />
                            <TextField
                              id="standard-required"
                              label="Postal Code"
                              name="postalCode"
                              className={classes.textField}
                              margin="normal"
                              value={recipientSearchValue.postalCode}
                              inputProps={{
                                maxLength: 50,
                              }}
                              style={{ width: "33.33%", margin: "0 15px" }}
                              autoComplete="off"
                              onChange={handleSearchInputPostal}
                              onKeyPress={_onKeyPress}
                            //   error={errors.postalCode}
                            //   helperText={errors.postalCode ? errors.postalCode : ""}
                            />
                            <TextField
                              id="standard-required"
                              label="Phone Number"
                              name="phoneNumber"
                              className={classes.textField}
                              margin="normal"
                              value={recipientSearchValue.phoneNumber}
                              inputProps={{
                                maxLength: 50,
                              }}
                              style={{ width: "33.33%", margin: "0" }}
                              autoComplete="off"
                              onChange={handleSearchInputPhone}
                              onKeyPress={_onMobileNumberKeyPress}
                            //   error={errors.phoneNumber}
                            //   helperText={errors.phoneNumber ? errors.phoneNumber : ""}
                            />
                          </div>
                        </React.Fragment>
                      </DialogContent>
                      <DialogActions style={{ padding: "16px 24px" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={handleSearchClose}
                        >
                          CANCEL
                    </Button>

                        <Button
                          disabled={
                            !(
                              recipientSearchValue.phoneNumber ||
                              recipientSearchValue.postalCode ||
                              recipientSearchValue.countryName ||
                              recipientSearchValue.department ||
                              recipientSearchValue.contactPersonName ||
                              recipientSearchValue.companyName
                            )
                          }
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={searchPopup}
                        >
                          SEARCH
                    </Button>
                      </DialogActions>
                    </Dialog>
                  </form>
                )}
              {acnObj && acnObj.addAction && <Tooltip title="Add">
                <IconButton
                  aria-label="add"
                  className={classes.iconHover}
                  onClick={handleAddOpen}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>}
              <React.Fragment>
                {acnObj && acnObj.addAction && addOpen ? (
                  <AddRecipientList
                    handleDuplicateClose={handleAddClose}
                    duplicateOpen={addOpen}
                    history={props.history}
                    change={props.change}
                  // selectedItemMaster={selectedItemMaster}
                  // ParentJobSchedulerID={selected && selected[0]}
                  // getAllItemMaster={props.getAllItemMaster}
                  />
                ) : null}
              </React.Fragment>
              {acnObj && acnObj.exportAction && (
                <ExportCSV csvData={expData} fileName={"Recipient_List"} />
              )}
              <Tooltip title="View Column">
                <IconButton
                  aria-label="viewColumn"
                  className={classes.iconHover}
                  onClick={handleClickListItem}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>

              <EnhancedShowColumn
                id="columns-menu"
                keepMounted
                open={open}
                onClose={handleShowColClose}
                column={column}
                hendleVewColums={hendleVewColums}
                headCells={props.headCells}
              />
            </div>
          )}
      </div>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function getModalStyle() {
  const top = 5;
  const left = 73;

  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: "100",
    fontSize: 14,
    overflow: "auto",
    height: "400px",
    width: "450px",
  };
}

const EnhancedShowColumn = (props) => {
  //For show or hide columns

  const classes = useShownColumnStyles();
  const {
    onClose,
    value: valueProp,
    headCells,
    hendleVewColums,
    open,
    ...other
  } = props;
  const setColumnIds = React.useState(valueProp)[1];
  const [modalStyle] = React.useState(getModalStyle);

  React.useEffect(() => {
    if (!open) {
      setColumnIds(valueProp);
    }
  });

  const handleClose = () => {
    onClose(setColumnIds);
  };

  return (
    <Dialog
      onClose={handleClose}
      style={modalStyle}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Show Column</DialogTitle>
      <DialogContent>
        <FormGroup>
          {columns.map((column) => {
            return (
              <FormControlLabel
                key={column.name}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      headCells[column.id - 1] && headCells[column.id - 1].show
                    }
                    value={true}
                    onChange={(e) => hendleVewColums(e, column.id)}
                  />
                }
                label={column.name}
              />
            );
          })}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

EnhancedShowColumn.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //value: PropTypes.string.isRequired
};

class RecipientList extends React.Component {
  static contextType = StateContext;
  state = {
    dataChange: false,
    namedelete: "",
    viewMode: true,
    deletePopupOpen: false,
    order: "desc",
    orderBy: "modifiedDate",
    selected: [],
    page: 0,
    rowsPerPage: 100,
    recipientList: [],
    rows: [],
    activeRows: 0,
    isActive: false,
    loading: false,
    refreshData: false,
    selectedrecipientID: {},
    selectedrecipientIDId: 1,
    searchPopupOpen: false,
    countryList: [],
    searchClicked: false,
    selectedShowColumns: [],
    exportDataValues: [],
    singpost: localStorage.isSingpostUser,
    headCells: [
      {
        id: 1,
        code: "companyName",
        numeric: false,
        disablePadding: false,
        label: "Company Name",
        show: true,
        width: "300px",
      },
      {
        id: 2,
        code: "department",
        numeric: false,
        disablePadding: false,
        label: "Department",
        show: true,
        width: "150px",
      },
      {
        id: 3,
        code: "countryName",
        numeric: false,
        disablePadding: false,
        label: "Country",
        show: true,
        width: "150px",
      },

      {
        id: 4,
        code: "contactPersonName",
        numeric: false,
        disablePadding: false,
        label: "Contact Person",
        show: true,
        width: "300px",
      },
      {
        id: 5,
        code: "phoneNumber",
        numeric: false,
        disablePadding: false,
        label: "Phone Number",
        show: true,
        width: "128px",
      },
      {
        id: 6,
        code: "address1",
        numeric: false,
        disablePadding: false,
        label: "Address1",
        show: true,
        width: "300px",
      },
      {
        id: 7,
        code: "address2",
        numeric: false,
        disablePadding: false,
        label: "Address2",
        show: true,
        width: "300px",
      },
      {
        id: 8,
        code: "postalCode",
        numeric: false,
        disablePadding: false,
        label: "Postal Code",
        show: true,
        width: "128px",
      },
      {
        id: 9,
        code: "modifiedDate",
        numeric: false,
        disablePadding: false,
        label: "Modified Date",
        show: true,
        width: "128px",
      },
      {
        id: 10,
        code: "modifiedBy",
        numeric: false,
        disablePadding: false,
        label: "Modified By",
        show: true,
        width: "128px",
      },

      {
        id: 11,
        code: "createdDate",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        show: true,
        width: "128px",
      },
      {
        id: 12,
        code: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        show: true,
        width: "128px",
      },
    ],
  };
  componentDidMount() {
    this.props.handleSettingHeader("BPA Recipient List");
    this.props.setSelectedList("bpaMarketing", "bpaRecipientList");
    const dispatch = this.context[1];
    let loggedInId =
      this.state.singpost == "true" ? 0 : localStorage.loggedInId;

    getRecipientList(loggedInId).then((r) => {
      dispatch({
        type: "getrecipient",
        newStore: r.data,
      });
      this.getrecipient(r.data);
      this.exportrecipientDatas(r.data);
    });
    getCountryDropdownList().then((r) => {
      dispatch({
        type: "getCountryDropdownList",
        newStore: r.data,
      });
      this.getAllStatus(r.data);
    });

    this.setState({ loading: true });
  }
  getAllStatus = (list) => {
    this.setState({
      countryList:
        list &&
        list.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };
  hendleVewColums = (e, id) => {
    let newheadCells = [...this.state.headCells];
    newheadCells[id - 1].show = e.target.checked;
    this.setState({ headCells: newheadCells });
  };

  exportrecipientDatas = (recipientList) => {
    let list = recipientList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push({
          // recipientID: d.recipientID,
          Customer: d.customer,
          ContactPerson: d.contactPerson,
          Department: d.department,
          Country: d.country,
          PostalCode: d.postalCode,
          PhoneNumber: d.phoneNumber,
          Address1: d.address1,
          Address2: d.address2,
          CreatedBy: d.createdBy,
          CreatedDate: d.createdDate,
          ModifiedBy: d.modifiedBy,
          ModifiedDate: d.modifiedDate,
        });
      });
    this.setState({ exportDataValues: rows });
  };

  getrecipient = (recipientList) => {
    // const [{ store }, dispatch] = this.context;
    let list = recipientList;
    let rows = [];
    list &&
      list.length > 0 &&
      list.map((d) => {
        return rows.push(
          createData(
            d.address1,
            d.address2,
            d.bpaRecipientID,
            d.companyName,
            d.contactPersonName,
            d.countryID,
            d.countryName,
            d.createdByName,
            d.createdDate,
            d.department,
            d.modifiedByName,
            d.modifiedDate,
            d.phoneNumber,
            d.postalCode
          )
        );
      });
    this.setState({
      recipientList: rows,
      selected: [],
      loading: false,
    });
  };
  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    // if (store.recipientList && store.recipientList.length > 0)
    //   if (
    //     this.state.recipientList.length !== store.recipientList.length &&
    //     !this.state.searchClicked
    //   ) {
    //     !this.state.searchClicked && this.getrecipient(store.recipientList);
    //   }
    return true;
  }

  // compo

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };

  handleSelectAllClick = (event) => { };

  handleClick(event, data) {
    let namedelete = data.companyName;
    let recipientID = data.bpaRecipientID;
    let newSelectedRows = [];
    let { selected } = this.state;

    //const selectedIndex = selected.indexOf(recipientID);
    let newSelected = [];
    if (event.target.checked) {
      newSelectedRows = newSelected.concat(recipientID);
      // if (selectedIndex === -1) {
      //   newSelected = newSelected.concat(selected, recipientID);
      // } else if (selectedIndex === 0) {
      //   newSelected = newSelected.concat(selected.slice(1));
      // } else if (selectedIndex === selected.length - 1) {
      //   newSelected = newSelected.concat(selected.slice(0, -1));
      // } else if (selectedIndex > 0) {
      //   newSelected = newSelected.concat(
      //     selected.slice(0, selectedIndex),
      //     selected.slice(selectedIndex + 1)
      //   );
      // }

      this.setState({
        selected: newSelectedRows,
        namedelete,

        selectedRecipientID: recipientID,
      });
    } else {
      // this.checkActiveStatus(newSelected);
      this.setState({
        selected: newSelected,
        namedelete: "",

        selectedRecipientID: recipientID,
      });
    }
  }
  checkActiveStatus = (selected) => {
    let newSelected = selected;
    let isActive = true;
    var x;
    for (x of newSelected) {
      isActive = this.state.activeRows.includes(x);
      if (!isActive) {
        this.setState({ isActive: false });
        return;
      } else {
        this.setState({ isActive: true });
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleDeletePopupOpen = () => {
    this.setState({ deletePopupOpen: true });
  };
  handleDeletePopupClose = () => {
    this.setState({ deletePopupOpen: false });
  };

  handleSearchPopupOpen = () => {
    this.setState({ searchPopupOpen: true });
  };
  editData = (data) => {
    let loggedInId =
      this.state.singpost == "true" ? 0 : localStorage.loggedInId;
    if (data) {
      getRecipientList(loggedInId).then((r) => {
        this.getrecipient(r.data);
        this.exportrecipientDatas(r.data);
      });
    }
  };
  handleSearchPopupClose = () => {
    this.setState({ searchPopupOpen: false });
  };

  handleSearchrecipientCode = (handleSearchrecipientCode) => {
    this.setState({ searchPopupOpen: false, searchClicked: true });
    let recipientSearchValue = handleSearchrecipientCode;

    let companyName = recipientSearchValue.companyName;
    let contactPersonName = recipientSearchValue.contactPersonName;
    let department = recipientSearchValue.department;
    let countryID = recipientSearchValue.countryID;
    let postalCode = recipientSearchValue.postalCode;
    let phoneNumber = recipientSearchValue.phoneNumber;
    let loginUserID = localStorage.loggedInId;

    let reqBody = {
      companyName,
      contactPersonName,
      department,
      countryID,
      postalCode,
      phoneNumber,
      loginUserID,
    };
    this.setState({ rows: [], loading: true });
    getFliteredRecipientList(reqBody).then((r) => {
      this.getrecipient(r.data);
    });
  };

  handledeleteProductionLineCode = () => {
    let { selected } = this.state;
    let loggedInId =
      this.state.singpost == "true" ? 0 : localStorage.loggedInId;
    let deletionIds = selected.join(",");

    deleteRecipient(deletionIds, this.props.history).then((r) => {
      getRecipientList(loggedInId).then((r) => {
        this.getrecipient(r.data);
      });

      this.setState({ deletePopupOpen: false, selected: [] });
    });
  };

  clearSearch = () => {
    this.setState({ rows: [], loading: true });
    let loggedInId =
      this.state.singpost == "true" ? 0 : localStorage.loggedInId;
    getRecipientList(loggedInId).then((r) => {
      this.getrecipient(r.data);
      this.exportrecipientDatas(r.data);
    });

    this.setState({ searchClicked: false });
  };

  render() {
    let {
      selected,
      rowsPerPage,
      page,
      order,
      orderBy,
      recipientList,
      headCells,
      exportDataValues,
    } = this.state;
    const isSelected = (recipientID) => selected.indexOf(recipientID) !== -1;

    return (
      <div>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            history={this.props.history}
            selected={this.state.selected}
            deletePopupOpen={this.state.deletePopupOpen}
            handleDeletePopupOpen={this.handleDeletePopupOpen}
            handleDeletePopupClose={this.handleDeletePopupClose}
            handledeleteProductionLineCode={this.handledeleteProductionLineCode}
            selectedrecipientID={this.state.selectedrecipientID}
            selectedrecipientIDId={this.state.selectedrecipientIDId}
            clearSearch={this.clearSearch}
            searchPopupOpen={this.state.searchPopupOpen}
            handleSearchPopupOpen={this.handleSearchPopupOpen}
            handleSearchPopupClose={this.handleSearchPopupClose}
            handleSearchrecipientCode={this.handleSearchrecipientCode}
            recipientSearchValue={this.state.recipientSearchValues}
            searchClicked={this.state.searchClicked}
            hendleVewColums={this.hendleVewColums}
            headCells={headCells}
            recipientList={recipientList}
            exportDataValues={exportDataValues}
            isActive={this.state.isActive}
            viewMode={this.state.viewMode}
            countryList={this.state.countryList}
            namedelete={this.state.namedelete}
            roles={this.props.roles}
            change={this.editData}
          />
          <div style={{ width: "100%", maxHeight: "calc(100vh - 225px)" }}>
            <div
              style={
                !this.props.clearOpenList
                  ? {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 8em)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
                  : {
                    overflow: "auto",
                    maxWidth: "calc(100vw - 330px)",
                    maxHeight: "calc(100vh - 225px)",
                    margin: "0 auto",
                  }
              }
            >
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
                style={{
                  borderLeft: "1px solid  rgba(224, 224, 224, 1)",
                  whiteSpace: "nowrap",
                }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.activeRows.length}
                  headCells={this.state.headCells}
                />
                <TableBody style={{ overflow: "hidden" }}>
                  {recipientList.length > 0 ? (
                    stableSort(recipientList, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.bpaRecipientID);

                        return (
                          <TableRow
                            hover
                            onClick={(event) => this.handleClick(event, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <CustomCheckbox
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                }
                              />
                            </TableCell>
                            {this.state.headCells[0].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell-max"
                              >
                                {row.companyName &&
                                  row.companyName.length > 16 ? (
                                    <Tooltip
                                      title={row.companyName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.companyName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.companyName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[1].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.department &&
                                  row.department.length > 16 ? (
                                    <Tooltip
                                      title={row.department}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.department}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.department}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[2].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.countryName &&
                                  row.countryName.length > 16 ? (
                                    <Tooltip
                                      title={row.countryName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.countryName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.countryName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[3].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell-max"
                              >
                                {row.contactPersonName &&
                                  row.contactPersonName.length > 16 ? (
                                    <Tooltip
                                      title={row.contactPersonName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.contactPersonName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.contactPersonName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[4].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.phoneNumber &&
                                  row.phoneNumber.length > 16 ? (
                                    <Tooltip
                                      title={row.phoneNumber}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.phoneNumber}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.phoneNumber}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[5].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell-max"
                              >
                                {row.address1 && row.address1.length > 16 ? (
                                  <Tooltip
                                    title={row.address1}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.address1}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.address1}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[6].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell-max"
                              >
                                {row.address2 && row.address2.length > 16 ? (
                                  <Tooltip
                                    title={row.address2}
                                    aria-label="add"
                                  >
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.address2}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.address2}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[7].show && (
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                              >
                                {row.postalCode &&
                                  row.postalCode.length > 16 ? (
                                    <Tooltip
                                      title={row.postalCode}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.postalCode}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.postalCode}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[8].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.modifiedDate &&
                                  row.modifiedDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.modifiedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.modifiedDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[9].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="table-content-cell"
                                visibility="hidden"
                              // id={labelId}
                              >
                                {row.modifiedByName &&
                                  row.modifiedByName.length > 16 ? (
                                    <Tooltip
                                      title={row.modifiedByName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.modifiedByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.modifiedByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}

                            {this.state.headCells[10].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdDate &&
                                  row.createdDate.length > 16 ? (
                                    <Tooltip
                                      title={moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {moment(row.createdDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {moment(row.createdDate).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                            {this.state.headCells[11].show && (
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                // id={labelId}
                                className="table-content-cell"
                                visibility="hidden"
                              >
                                {row.createdByName &&
                                  row.createdByName.length > 16 ? (
                                    <Tooltip
                                      title={row.createdByName}
                                      aria-label="add"
                                    >
                                      <Typography
                                        noWrap
                                        style={{ fontSize: "14px" }}
                                      >
                                        {row.createdByName}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Typography
                                      noWrap
                                      style={{ fontSize: "14px" }}
                                    >
                                      {row.createdByName}
                                    </Typography>
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                  ) : (
                      <TableRow>
                        {this.state.loading ? (
                          <TableCell colSpan={15} style={{ textAlign: "center" }}>
                            <LinearProgress />
                          </TableCell>
                        ) : (
                            <TableCell colSpan={13} style={{ textAlign: "center" }}>
                              No result found
                            </TableCell>
                          )}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[100, 200, 300]}
            component="div"
            count={recipientList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default withRouter(RecipientList);
