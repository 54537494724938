import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";

export const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },

  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: "1em"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dropdown: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
    marginTop: theme.spacing(2)
  },
  deleteContainer: {
    padding: "1em"
  },
  searchTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 285
  },
  iconHover: {
    "&:hover": {
      color: blue[800]
    },
    "&:after": {
      display: "none"
    }
  },
  combobox: {
    width: "31%",
    fontSize: "11px !important"
  }
});

export const addStyles = theme => ({
  container: {
    flexWrap: "wrap",
    margin: "1em"
  },
  textField: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "31%",
    fontSize: "11px !important"
  },
  comboBox: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "31%",
    fontSize: "11px !important"
  },
  checkBox: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(0),
    marginRight: "1.5em !important"
  },
  KeyboardDatePicker: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "31%",
    fontSize: "11px !important"
  },
  subHeader: {
    fontSize: "16px",
    fontWeight: "normal",
    marginLeft: "7px",
    paddingTop: "1em",
    fontFamily: "Roboto"
  },
  search: {
    position: "relative",
    minWidth: "500px",
    display: "flex"
  },
  searchIcon: {
    position: "absolute",
    right: "0"
  }
});

const drawerWidth = 285;
export const useStyles = makeStyles(theme => ({
  navRoot: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: `calc(100% - 72px)!important`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    paddingLeft: "1em",
    width: `calc(100% - 281px) !important`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    marginRight: "1em"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7)
    // [theme.breakpoints.up("sm")]: {
    //   width: theme.spacing(9)
    // }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
    // paddingLeft: "0px"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    textAlign: "left",
    padding: ".5em 24px 24px 0px"
  },
  tableCellElipsis: {
    borderRight: "1px solid #eaeaea",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "10rem"
  }
}));
export const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    borderTop: "1px solid #eaeaea"
  },
  iconHover: {
    "&:hover": {
      color: blue[800]
    }
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent"
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  title: {
    flex: "1 1 100%"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

export const useShownColumnStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "transparent"
  }
}));

export const addDispatchStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  container: {
    flexWrap: "wrap",
    margin: "1em"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "48%",
    fontSize: "11px !important"
  },
  textFieldModified: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "31%",
    fontSize: "11px !important"
  },
  textFieldSmall: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "31.3%",
    fontSize: "11px !important"
  },
  resize: {
    fontSize: 14
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em"
  },
  iconHover: {
    "&:hover": {
      color: blue[800]
    }
  }
});
export const editDispatchStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  container: {
    flexWrap: "wrap",
    margin: "1em"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "33%",
    fontSize: "11px !important"
  },
  resize: {
    fontSize: 14
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em"
  }
});
export const addScreenstyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: "1em"
  },
  textField: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "31%",
    fontSize: "11px !important"
  },
  resize: {
    fontSize: 14
  }
});
