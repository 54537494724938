import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_GENRIC_ORDER_PLACEMENT;
const pgsService = axios.create({
  baseURL,
});
let count = 1;

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getPlacedOrder = async () => {
  let pol = [];
  //console.log(localStorage);
  let customerId = localStorage.getItem("loggedInId");
  await pgsService
    .get(`/GetAll/${customerId}`)
    .then((r) => {
      // console.log(r);
      pol = r;
    })
    .catch((e) => {});
  return pol;
};
export const getOrderGroup = async () => {
  let pol = [];
  //console.log(localStorage);
  let customerId = localStorage.getItem("loggedInId");
  await pgsService
    .get(`/OGGetAll/${customerId}`)
    .then((r) => {
      // console.log(r);
      pol = r;
    })
    .catch((e) => {});
  return pol;
};
export const getOrderList = async () => {
  let pol = [];
  //console.log(localStorage);
  let customerId = localStorage.getItem("loggedInId");
  await pgsService
    .get(`/OLGetAll/${customerId}`)
    .then((r) => {
      // console.log(r);
      pol = r;
    })
    .catch((e) => {});
  return pol;
};
export const getGroupProcess = async () => {
  let pol = [];
  //console.log(localStorage);
  let customerId = localStorage.getItem("loggedInId");
  await pgsService
    .get(`/GPGetAll/${customerId}`)
    .then((r) => {
      // console.log(r);
      pol = r;
    })
    .catch((e) => {});
  return pol;
};
// export const deleteOrder = async id => {
//   let pol = [];
//   await pgsService
//     .delete(`/CustomerOrderPlacements/${id}`)
//     .then(r => {
//       pol = r;
//     })
//     .catch(e => {});
//   return pol;
// };
export const deleteOrder = async (id, modifiedBy) => {
  await pgsService
    .delete(`/DeleteOrderHeaderFile`, {
      data: {
        modifiedBy,
        DeletionIds: id,
      },
    })
    .then((r) => {
      toastr.success("Customer order file Deleted Successfully");
    })
    .catch((e) => {
      if (e.response.data.errors[0].message != undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else {
        toastr.error(e.response.data.errors[0]);
      }
    });
};
export const getParticularOrderEdit = async (id) => {
  let pol = [];
  await pgsService
    .get(`/GetByIDEdit/${id}`)
    .then((r) => {
      pol = r.data;
    })
    .catch((e) => {});
  return pol;
};
export const getParticularUserOrderEdit = async (id) => {
  let pol = [];
  await pgsService
    .get(`/UOPGetByIDEdit/${id}`)
    .then((r) => {
      pol = r.data;
    })
    .catch((e) => {});
  return pol;
};
export const getParticularOrderView = async (id) => {
  let pol = [];
  await pgsService
    .get(`/GetByIDView/${id}`)
    .then((r) => {
      pol = r.data;
    })
    .catch((e) => {});
  return pol;
};
export const getParticularUserOrderView = async (id) => {
  let pol = [];
  await pgsService
    .get(`/UOPGetByIDView/${id}`)
    .then((r) => {
      pol = r.data;
    })
    .catch((e) => {});
  return pol;
};
export const getParticularGroupProcessView = async (id) => {
  let pol = [];
  await pgsService
    .get(`/GPGetByID/${id}`)
    .then((r) => {
      pol = r.data;
    })
    .catch((e) => {});
  return pol;
};
export const onOrderCancel = async (id, history, body) => {
  await pgsService
    .post(`/Cancel`, body)
    .then((r) => {
      history.push("/orderPlacement");
    })
    .catch((e) => {});
};
export const onEditOrderCancel = async (id, history, body) => {
  await pgsService
    .post(`/Cancel`, body)
    .then((r) => {
      history.push("/orderPlacement");
    })
    .catch((e) => {});
};
export const getParticularOrder = async (id) => {
  let pol = [];
  await pgsService
    .get(`/GetByID/${id}`)
    .then((r) => {
      pol = r.data;
    })
    .catch((e) => {});
  return pol;
};
export const getCustomerExcelData = async (id, orderHeaderId) => {
  let pol = [];
  await pgsService
    .get(`/Export/${id}/${orderHeaderId}`)
    .then((r) => {
      pol = r.data;
    })
    .catch((e) => {});
  return pol;
};
export const getCustomerStagingExcelData = async (id, orderHeaderId) => {
  let pol = [];
  await pgsService
    .get(`/StagingExport/${id}`)
    .then((r) => {
      pol = r.data;
    })
    .catch((e) => {});
  return pol;
};
let result = [];
export const uploadCustomerOrderFiles = async (data) => {
  await pgsService
    .post(
      "/Create",
      data
      // { crossdomain: true },
      // {
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
      //     "content-type": "application/vnd.ms-excel;charset=UTF-8"
      //   }
      // }
    )
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else result = r.data;
      // return r.data;
    })
    .catch((e) => {
      // if (e.respone && e.response.data.errors[0].message != undefined) {
      //   toastr.error(e.response.data.errors[0].message);
      // } else if (e.response.data.errors[0]) {
      //   toastr.error(e.response.data.errors[0]);
      // }
    });
  return result;
  // }
};
export const updateUploadCustomerOrderFiles = async (data) => {
  var xhr = new XMLHttpRequest();
  // xhr.open("PUT", "http://10.2.0.132:31025/genoplapi/V1/Update", true);
  // xhr.withCredentials = true;
  // xhr.send(data).then(r => {
  //   console.log(r);
  // });
  await pgsService
    .put("/Update", data, {
      // headers: {
      //   "Access-Control-Allow-Origin": "*",
      //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
      //   "content-type": "application/vnd.ms-excel;charset=UTF-8"
      // }
    })
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else result = r.data;
    });

  // .catch(e => {
  //   if (e.respone && e.response.data.errors[0].message != undefined) {
  //     toastr.error(e.response.data.errors[0].message);
  //   } else if (e.response.data.errors[0]) {
  //     toastr.error(e.response.data.errors[0]);
  //   }
  //});
  return result;
  // }
};
export const submitCustomerOrder = async (data, history, status, msg) => {
  await pgsService
    .post("/Submit", data, {
      // headers: {
      //   "Access-Control-Allow-Origin": "*",
      //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
      //   "content-type": "application/vnd.ms-excel;charset=UTF-8"
      // }
    })
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else {
        result = r.data;
        history.push("/orderPlacement");
        toastr.success(`${msg}`);
      }
    });
  // .catch(e => {
  //   if (e.respone && e.response.data.errors[0].message != undefined) {
  //     toastr.error(e.response.data.errors[0].message);
  //   } else if (e.response.data.errors[0]) {
  //     toastr.error(e.response.data.errors[0]);
  //   }
  //});
  return result;
  // }
};
export const updateCustomerOrder = async (data, history) => {
  // xhr.withCredentials = true;
  await pgsService
    .put("/Submit", data, {
      // headers: {
      //   "Access-Control-Allow-Origin": "*",
      //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
      //   "content-type": "application/vnd.ms-excel;charset=UTF-8"
      // }
    })
    .then((r) => {
      //  console.log(r);
      result = r.data;
      // return r.data;
      history.push("/orderPlacement");
      toastr.success("Order placed successfully");
    });
  // .catch(e => {
  //   if (e.respone && e.response.data.errors[0].message != undefined) {
  //     toastr.error(e.response.data.errors[0].message);
  //   } else if (e.response.data.errors[0]) {
  //     toastr.error(e.response.data.errors[0]);
  //   }
  //});
  return result;
  // }
};
export const serchCustomerOrder = async (data) => {
  let customerOrderList = [];
  await pgsService
    .post("/AdvanceSerach", data)
    .then((r) => {
      customerOrderList = r;
    })
    .catch((e) => {});
  return customerOrderList;
};
export const serchOrderGroupOrder = async (data) => {
  let customerOrderList = [];
  await pgsService
    .post("/OGAdvanceSerach", data)
    .then((r) => {
      customerOrderList = r;
    })
    .catch((e) => {});
  return customerOrderList;
};
export const serchOrderListOrder = async (data) => {
  let customerOrderList = [];
  await pgsService
    .post("/OLAdvanceSerach", data)
    .then((r) => {
      customerOrderList = r;
    })
    .catch((e) => {});
  return customerOrderList;
};
export const serchGroupProcessOrder = async (data) => {
  let customerOrderList = [];
  await pgsService
    .post("/GPAdvanceSerach", data)
    .then((r) => {
      customerOrderList = r;
    })
    .catch((e) => {});
  return customerOrderList;
};
export const uploaduserOrderFiles = async (data) => {
  await pgsService
    .post("/UOPCreate", data)
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else result = r.data;
    })
    .catch((e) => {});
  return result;
  // }
};
export const uploaduserOrderFilesEdit = async (data) => {
  await pgsService
    .put("/UOPUpdate", data)
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else result = r.data;
    })
    .catch((e) => {});
  return result;
  // }
};
export const submitUserOrder = async (data, history) => {
  await pgsService
    .post("/UOPSubmit", data)
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else {
        result = r.data;
        // history.push("/genericSystem");
        //toastr.success(`${msg}`);
      }
      // result = r.data;
    })
    .catch((e) => {});
  return result;
  // }
};

// export const updateUserOrder = async (data, history) => {
//   await pgsService
//     .put("/UOPUpdate", data)
//     .then(r => {
//       toastr.success("User Order Updated Successfully");
//       if (r.data.errors && r.data.errors.length > 0) {
//         toastr.error(r.data.errors[0].message);
//         return;
//       } else {
//         result = r.data;
//         // history.push("/genericSystem");
//         //toastr.success(`${msg}`);
//       }
//       // result = r.data;
//     })
//     .catch(e => {});
//   return result;
//   // }
// };
export const submitGroupProcess = async (data, history, msg, ogId) => {
  await pgsService
    .post("/GPSubmit", data)
    .then((r) => {
      if (r.data.errors && r.data.errors.length > 0) {
        toastr.error(r.data.errors[0].message);
        return;
      } else {
        toastr.success(`Group Process ${msg} Successfully`);
        // history.push("/genericSystem");
        history.push(`/view_userOrder/${ogId}/1`);
        result = r.data;
      }
    })
    .catch((e) => {});
  return result;
  // }
};
