import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  Paper,
  Divider,
  FormControlLabel,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import { Autocomplete } from "@material-ui/lab";
import { Checkbox } from "@material-ui/core";
import {
  getCustomerPPIList,
  getCustomerList,
  getDeptList,
} from "../../masters/masterActions";
import { resourceValidation } from "../../../shared/resource";
import { StateContext } from "../../../shared/globalState";
import { postUser, getRoleList } from "../userAccessAction";
import { addStyles } from "../../../shared/styles";
const emailValidator = new Validator("email");
const notRequired100 = new Validator("notRequired100");
const onlyNumbers = new Validator("onlyNumbers");
const loginIDValid = new Validator("loginIDValid");

class AddUser extends React.Component {
  static contextType = StateContext;
  constructor() {
    super();

    this.state = {
      user: {
        firstName: "",
        middleName: "",
        lastName: "",
        emailId: "",
        loginUserId: "",
        accountPassword: "",
        role: {},
        roleId: "",
        customer: "",
        customerID: "",
        department: "",
        departmentID: "",
        customerPPI: "",
        customerPPIID: "",
        pwdExpiryDate: "",
        singpostUser: "",
      },
      customer: "",
      errors: {},
      emailValid: false,
      passwordValid: false,
      formValid: false,
      firstNameValid: false,
      middleNameValid: false,
      lastNameValid: false,
      loginUserIdValid: false,
      roleIdValid: false,
      departmentValid: false,
      customersList: [],
      departmentList: [],
      customerPPIList: [],
      roleList: [],
      customerClicked: false,
    };
    this.state.user.singpostUser = true;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    getCustomerList().then((r) => {
      r.data &&
        this.setState({
          customersList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.customerName.toLowerCase() > b.customerName.toLowerCase()
                ? 1
                : -1
            ),
        });
    });

    getDeptList().then((r) => {
      r.data &&
        this.setState({
          departmentList: r.data.sort((a, b) =>
            a.departmentName.toLowerCase() > b.departmentName.toLowerCase()
              ? 1
              : -1
          ),
        });
    });

    getRoleList().then((r) => {
      r.data &&
        this.setState({
          roleList: r.data
            .filter((x) => x.isActive === true)
            .sort((a, b) =>
              a.roleName.toLowerCase() > b.roleName.toLowerCase() ? 1 : -1
            ),
        });
    });

    this.props.handleSettingHeader("User");
  }

  shouldComponentUpdate(s, p) {
    const { store } = this.context[0];
    if (store.customerPPIList && store.customerPPIList.length > 0)
      if (this.state.customerPPIList.length !== store.customerPPIList.length) {
        this.state.customerClicked &&
          this.setState({
            customerPPIList: store.customerPPIList,
            customerClicked: false,
          });
      }
    return true;
  }

  _onKeyPress(event) {
    const re = /[a-zA-Z ]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  _onKeyNumberPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleCustChange = (event, values) => {
    const { user } = this.state;
    user["customerPPI"] = "";
    user["customerPPIID"] = "";
    this.setState({ user, customerPPIList: [], customerClicked: true });
    const dispatch = this.context[1];
    let customerId = values && values.customerID;
    user["customer"] = values;
    user["customerId"] = customerId;
    getCustomerPPIList(customerId).then((r) => {
      dispatch({
        type: "getCustomerPPIList",
        newStore: r.data,
      });
      this.setState({ customerPPIList: r.data });
    });
    this.setState({ user });
    this.validateForm();
  };

  handleCustPPIChange = (event, values) => {
    const { user } = this.state;
    let customerPPIID = values && values.customerPPIID;
    user["customerPPI"] = values;
    user["customerPPIID"] = customerPPIID;
    this.validateField("customerPPI", customerPPIID);
    this.setState({ user });
    this.validateForm();
  };

  handleDeptChange = (event, values) => {
    const { user } = this.state;
    let departmentID = values && values.departmentId;
    user["department"] = values;
    user["departmentID"] = departmentID;
    this.validateField("department", departmentID);
    this.setState({ user });
    this.validateForm();
  };

  handleRoleChange = (event, values) => {
    const { user } = this.state;
    let roleID = values && values.roleID;
    user["role"] = values;
    user["roleId"] = roleID;
    this.validateField("roleId", roleID);
    this.setState({ user });
    this.validateForm();
  };

  handleChange = (event, values) => {
    const { user } = this.state;
    if (event.target.name === "singpostUser") {
      user[event.target.name] = event.target.checked;
      user.customer = "";
      user.customerPPI = "";
      user.customerId = "";
      user.customerPPIID = "";
      this.validateForm();
    } else {
      user[event.target.name] = event.target.value;
    }
    this.setState({ user });

    this.validateField(event.target.name, event.target.value);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let firstNameValid = this.state.firstNameValid;
    let middleNameValid = this.state.middleNameValid;
    let lastNameValid = this.state.lastNameValid;
    let loginUserIdValid = this.state.loginUserIdValid;
    let roleIdValid = this.state.roleIdValid;
    let departmentValid = this.state.departmentValid;
    switch (fieldName) {
      case "firstName":
        if (value !== "") {
          firstNameValid = notRequired100(value);
          fieldValidationErrors.fName = firstNameValid
            ? ""
            : resourceValidation.onlyAlphabet;
        } else {
          firstNameValid = false;
          fieldValidationErrors.fName = "";
        }
        break;
      case "middleName":
        middleNameValid = notRequired100(value);
        fieldValidationErrors.middleName = middleNameValid
          ? ""
          : resourceValidation.onlyAlphabet;
        break;
      case "lastName":
        lastNameValid = notRequired100(value);
        fieldValidationErrors.lastName = lastNameValid
          ? ""
          : resourceValidation.onlyAlphabet;
        break;
      case "loginUserId":
        loginUserIdValid = loginIDValid(value);
        fieldValidationErrors.loginUserId = loginUserIdValid
          ? ""
          : "Invalid Login Id(Minimum 3 charaters required)";
        break;
      case "roleId":
        roleIdValid = onlyNumbers(value);
        fieldValidationErrors.roleId = roleIdValid
          ? ""
          : resourceValidation.onlyNumbers;
        break;
      case "department":
        departmentValid = onlyNumbers(value);
        fieldValidationErrors.department = departmentValid
          ? ""
          : " Please select department";
        break;
      case "emailId":
        emailValid = emailValidator(value);
        fieldValidationErrors.email = emailValid
          ? ""
          : resourceValidation.validEmailId;
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid,
        firstNameValid: firstNameValid,
        middleNameValid: middleNameValid,
        lastNameValid: lastNameValid,
        loginUserIdValid: loginUserIdValid,
        roleIdValid: roleIdValid,
        departmentValid: departmentValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    const { user } = this.state;
    let fieldValidationErrors = this.state.errors;
    let customerValid = true;
    let customerPPIvalid = true;

    if (!user.singpostUser) {
      if (!user.customer) {
        customerValid = false;
      }
      fieldValidationErrors.customer = customerValid
        ? ""
        : "*Please select customer";

      if (!user.customerPPI) {
        customerPPIvalid = false;
      }

      fieldValidationErrors.customerPPI = customerPPIvalid
        ? ""
        : "*Please select customerPPI";
    } else {
      fieldValidationErrors.customerPPI = customerPPIvalid
        ? ""
        : "*Please select customerPPI";
      fieldValidationErrors.customer = customerValid
        ? ""
        : "*Please select customer";
    }

    if (!user.singpostUser) {
      this.setState({
        formValid:
          this.state.firstNameValid &&
          this.state.loginUserIdValid &&
          this.state.emailValid &&
          this.state.roleIdValid &&
          this.state.departmentValid &&
          customerValid &&
          customerPPIvalid,
      });
    } else {
      this.setState({
        formValid:
          this.state.firstNameValid &&
          this.state.loginUserIdValid &&
          this.state.emailValid &&
          this.state.roleIdValid &&
          this.state.departmentValid,
      });
    }

    return this.state.formValid;
  }

  handleClose = () => {
    this.setState({
      firstName: "",
      middleName: "",
      lastName: "",
      loginUserId: "",
      singpostUser: "",
      customer: "",
      customerPPI: "",
      roleId: "",
      department: "",
      emailId: "",
      errors: {},
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      const { user } = this.state;
      let fName = user.firstName;
      let mName = user.middleName;
      let lName = user.lastName;
      let loginUserID = user.loginUserId;
      let isSingpostUser = user.singpostUser;
      let customerID = user.customerId;
      let customerPPIID = user.customerPPIID;
      let roleID = user.roleId;
      let departmentID = user.departmentID;
      let eMailID = user.emailId;
      let createdBy = localStorage.loggedInId;

      let reqBody = {
        fName,
        mName,
        lName,
        loginUserID,
        isSingpostUser,
        customerID,
        customerPPIID,
        roleID,
        departmentID,
        eMailID,
        createdBy,
      };
      postUser(reqBody, this.props.history);
    }
  };
  render() {
    let { classes } = this.props;
    let {
      user,
      customersList,
      departmentList,
      customerPPIList,
      roleList,
    } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Create New User</div>
          <Divider />

          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <p className={classes.subHeader}>Enter User Details</p>
                <div>
                  {" "}
                  <TextField
                    required
                    inputProps={{
                      maxLength: 50,
                    }}
                    label="First Name"
                    name="firstName"
                    margin="normal"
                    autoComplete="off"
                    className={classes.textField}
                    value={user.firstName}
                    onChange={this.handleChange}
                    error={this.state.errors.firstName}
                    helperText={
                      this.state.errors.firstName
                        ? this.state.errors.firstName
                        : ""
                    }
                    onKeyPress={this._onKeyPress}
                  />
                  <TextField
                    inputProps={{
                      maxLength: 50,
                    }}
                    label="Middle Name"
                    name="middleName"
                    margin="normal"
                    autoComplete="off"
                    onKeyPress={this._onKeyPress}
                    className={classes.textField}
                    value={user.middleName}
                    onChange={this.handleChange}
                    error={this.state.errors.middleName}
                    helperText={
                      this.state.errors.middleName
                        ? this.state.errors.middleName
                        : ""
                    }
                  />
                </div>
                <div>
                  <TextField
                    inputProps={{
                      maxLength: 50,
                    }}
                    label="Last Name"
                    name="lastName"
                    margin="normal"
                    autoComplete="off"
                    onKeyPress={this._onKeyPress}
                    className={classes.textField}
                    value={user.lastName}
                    onChange={this.handleChange}
                    error={this.state.errors.lastName}
                    helperText={
                      this.state.errors.lastName
                        ? this.state.errors.lastName
                        : ""
                    }
                  />
                  <TextField
                    inputProps={{
                      maxLength: 50,
                    }}
                    label="Email-Id"
                    name="emailId"
                    margin="normal"
                    autoComplete="off"
                    required
                    className={classes.textField}
                    value={user.emailId}
                    onChange={this.handleChange}
                    error={this.state.errors.email}
                    helperText={
                      this.state.errors.email ? this.state.errors.email : ""
                    }
                  />
                </div>

                <p className={classes.subHeader}>Enter Login Details</p>
                <div style={{ display: "flex" }}>
                  <TextField
                    required
                    inputProps={{
                      maxLength: 50,
                    }}
                    margin="normal"
                    label="Username"
                    name="loginUserId"
                    autoComplete="off"
                    className={classes.textField}
                    value={user.loginUserId}
                    onChange={this.handleChange}
                    error={this.state.errors.loginUserId}
                    helperText={
                      this.state.errors.loginUserId
                        ? this.state.errors.loginUserId
                        : ""
                    }
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="role"
                    options={roleList}
                    className={classes.comboBox}
                    onChange={this.handleRoleChange}
                    value={user.role}
                    getOptionLabel={(option) => option.roleName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        // className={classes.textField}
                        label="Role"
                        margin="normal"
                        name="role"
                        id="role"
                        style={{ width: "100%" }}
                        error={this.state.errors.roleId}
                        helperText={
                          this.state.errors.roleId
                            ? this.state.errors.roleId
                            : ""
                        }
                      />
                    )}
                  />

                  <Autocomplete
                    required
                    autoComplete
                    includeInputInList
                    id="department"
                    options={departmentList}
                    className={classes.comboBox}
                    onChange={this.handleDeptChange}
                    value={user.department}
                    getOptionLabel={(option) => option.departmentName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // className={classes.textField}
                        required
                        label="Department"
                        name="department"
                        id="department"
                        margin="normal"
                        style={{ width: "100%" }}
                        error={this.state.errors.department}
                        helperText={
                          this.state.errors.department
                            ? this.state.errors.department
                            : ""
                        }
                      />
                    )}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    autoComplete
                    disabled={user.singpostUser}
                    includeInputInList
                    id="customer"
                    // style={{ width: "31%" }}
                    options={customersList}
                    onChange={this.handleCustChange}
                    className={classes.comboBox}
                    value={user.customer}
                    getOptionLabel={(option) => option.customerName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Customer"
                        name="customer"
                        id="customer"
                        margin="normal"
                        style={{ width: "100%" }}
                        error={this.state.errors.customer}
                        helperText={
                          this.state.errors.customer
                            ? this.state.errors.customer
                            : ""
                        }
                      />
                    )}
                  />
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    disabled={user.singpostUser}
                    id="customerPPI"
                    options={customerPPIList}
                    className={classes.comboBox}
                    onChange={this.handleCustPPIChange}
                    value={user.customerPPI}
                    getOptionLabel={(option) => option.ppiNumber}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="CustomerPPI"
                        name="customerPPI"
                        id="customerPPI"
                        margin="normal"
                        style={{ width: "100%" }}
                        error={this.state.errors.customerPPI}
                        helperText={
                          this.state.errors.customerPPI
                            ? this.state.errors.customerPPI
                            : ""
                        }
                      />
                    )}
                  />

                  <FormControlLabel
                    className={classes.checkBox}
                    name="singpostUser"
                    control={
                      <Checkbox
                        checked={user.singpostUser}
                        onChange={this.handleChange}
                        value={user.singpostUser}
                        color="primary"
                      />
                    }
                    label="SingpostUser"
                  />
                </div>
                <Divider
                  style={{
                    marginTop: "25px",
                  }}
                />
                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.push("/user")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        disabled
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: "none" }}
                          onClick={this.handleSubmit}
                        >
                          SUBMIT
                        </Button>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(addStyles)(AddUser));
