import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withRouter } from "react-router-dom";
import {Autocomplete} from "@material-ui/lab" ;
import {
  getProvince,
  updateProvinceID,
  getCountryList
} from "../../masters/masterActions";
import Validator from "../../../shared/validator";
// Include new StateContext
import { StateContext } from "../../../shared/globalState";
import { resourceValidation } from "../../../shared/resource";

const validateNotRequired100 = new Validator("maxlength100AllAllow");
const onlyNumbers = new Validator("onlyNumbers");

const CustomCheckbox = withStyles({
  root: {
    color: "gray",
    "&$checked": {
      color: "#3982ea"
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  container: {
    flexWrap: "wrap",
    margin: "1em"
  },
  textField: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "31%",
    fontSize: "11px !important"
  },
  comboBox: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "31%",
    fontSize: "11px !important"
  },
  checkBox: {
    marginTop: theme.spacing(3.5),
    marginLeft: theme.spacing(1.5),
    marginRight: "1.5em !important"
  }
});

class EditProvince extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.state = {
      province: {
        provinceId: "",
        countryName: "",
        countryID: "",
        provinceName: "",
        isActive: false,
        modifiedBy: 1
      },
      active: false,
      errors: {},
      selectedDDL: 0,
      countryValid: true,
      provinceNameValid: true,
      countryList: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Province");
    let provinceId = this.props.match.params.provinceId;
    this.props.setSelectedList("masterDataOpen", "province");
    getProvince(provinceId).then(r => {
      dispatch({
        type: "getProvince",
        newStore: r
      });
      this.setState({ province: r });
      if (r.isActive) this.setState({ active: r.isActive });
    });
    getCountryList().then(r => {
      dispatch({
        type: "getCountryList",
        newStore: r.data
      });
      //this.setState({countryList: r.data});
      this.getAllCountries(r.data);
    });
  }
  getAllCountries = list => {
    let countryList = [];
    list &&
      list.length > 0 &&
      list.map(c =>
        c.isActive
          ? countryList.push({
              key: c.countryID,
              // value: c.countryName,  d.countryCode + "-" + d.countryName,
              value: c.countryCode + "-" + c.countryName,
              active: c.isActive
            })
          : countryList
      );
    this.setState({
      countryList: countryList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      )
    });
  };

  handleChange = event => {
    const { province } = this.state;
    if (event.target.name === "isActive") {
      province[event.target.name] = event.target.checked;
    } else {
      province[event.target.name] = event.target.value;
    }
    this.setState({ province });

    this.validateField(event.target.name, event.target.value);
  };

  handleCountryChange = (event, values) => {
    const { province } = this.state;
    if (values === null) {
      province["countryID"] = "null";
    } else {
      let countryID = values.key;
      province["countryID"] = countryID;
    }
    this.setState({ province });

    this.validateField("countryID", province["countryID"]);
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let provinceNameValid = this.state.provinceNameValid;
    let countryValid = this.state.countryValid;
    switch (fieldName) {
      case "provinceName":
        if (value !== "") {
          provinceNameValid = validateNotRequired100(value);
          fieldValidationErrors.provinceName = provinceNameValid
            ? ""
            : resourceValidation.ValidName;
        } else {
          provinceNameValid = false;
          fieldValidationErrors.provinceName = "";
        }
        break;
      case "countryID":
        if (value !== "null") {
          countryValid = onlyNumbers(value);
          fieldValidationErrors.countryID = countryValid
            ? ""
            : " Please select Country";
        } else {
          countryValid = false;
          fieldValidationErrors.countryID = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        provinceNameValid: provinceNameValid,
        countryValid: countryValid
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.provinceNameValid && this.state.countryValid
    });
    return this.state.formValid;
  }

  handleSubmit(e) {
    e.preventDefault();
    let provinceId = this.props.match.params.provinceId;
    const { province } = this.state;
    province.modifiedBy = localStorage.loggedInId;
    updateProvinceID(provinceId, province).then(r => {
      this.props.history.push("/Province");
      //toastr.success("Province Updated Successfully");
    });
  }

  render() {
    let { classes } = this.props;
    let { province, errors, active, selectedDDL, countryList } = this.state;
    let loop = 0;
    countryList.map(option =>
      option.key === this.state.province.countryID
        ? (selectedDDL = loop)
        : (loop = loop + 1)
    );

    return (
      <React.Fragment>
        <Paper>
          <div className="pop-up-header">Update Province</div>
          <Divider />
          <div className={classes.container}>
            <div style={{ marginTop: "-15px" }}>
              <form className={classes.container} onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  {/* {countryList.length > 0 && ( */}
                  <Autocomplete
                    autoComplete
                    includeInputInList
                    id="country"
                    name="country"
                    options={countryList}
                    onChange={this.handleCountryChange}
                    className={classes.comboBox}
                    getOptionLabel={option => option.value}
                    value={countryList.length > 0 && countryList[selectedDDL]}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={"Country"}
                        name="countryName"
                        id="countryName"
                        margin="normal"
                        required
                        style={{ width: "100%" }}
                        value={province.countryName}
                        error={this.state.errors.countryID}
                        helperText={
                          this.state.errors.countryID
                            ? this.state.errors.countryID
                            : ""
                        }
                      />
                    )}
                  />
                  {/* )} */}
                  <TextField
                    required
                    label="Province Name"
                    name="provinceName"
                    inputProps={{
                      maxLength: 50
                    }}
                    margin="normal"
                    className={classes.textField}
                    value={province.provinceName}
                    onChange={this.handleChange}
                    error={this.state.errors.provinceName}
                    helperText={
                      this.state.errors.provinceName
                        ? this.state.errors.provinceName
                        : ""
                    }
                  />
                  {active ? (
                    <React.Fragment />
                  ) : (
                    <FormControlLabel
                      name="isActive"
                      className={classes.checkBox}
                      control={
                        <CustomCheckbox
                          checked={province.isActive}
                          onChange={this.handleChange}
                          value={province.isActive}
                        />
                      }
                      label="Active"
                    />
                  )}
                </div>
                <Divider style={{ marginTop: "25px" }} />

                <div className="button-wrapper">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      //disableElevation
                      style={{ boxShadow: "none" }}
                      onClick={e => this.props.history.push("/province")}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    {!this.state.formValid ? (
                      <Button
                        onClick={this.handleSubmit}
                        disabled
                        //autoFocus
                        variant="contained"
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="primary"
                        //disableElevation
                        style={{ boxShadow: "none" }}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(EditProvince));
