import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Paper, Divider } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { addDispatchStyles } from "../../../shared/styles";
import { StateContext } from "../../../shared/globalState";
import { getinsertMaster } from "../../masters/masterActions";
import { printDetails } from "../../../shared/tableCommonFunctions";
import { useToolbarStyles } from "../../../shared/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon, PrintIcon } from "../../../shared/customIcons";
import { getViewDetailsForInsertMaster } from "../action";

const styles = (theme) => ({
  subContainer: {
    marginRight: "1em",
  },
});

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { selectedinsertmasterId } = props;
  let acnArr = props.roles.filter((u) => u.menuName === "Insert Master");
  let acnObj = acnArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj &&
            acnObj.editAction &&
            props.statusName != "Cancelled" &&
            props.statusName != "Processed" && (
              <Tooltip title="Edit">
                <Link to={`/edit_pubInsertMaster/${selectedinsertmasterId}`}>
                  <IconButton aria-label="edit" className={classes.iconHover}>
                    <EditIcon />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={
                props.statusName != "Cancelled"
                  ? { padding: "8px 9px 0px 15px" }
                  : { padding: "15px 9px 6px 15px" }
              }
            >
              <IconButton
                aria-label="print"
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

class ViewInsertMaster extends React.Component {
  static contextType = StateContext;
  state = {
    statusName: "",
    customerName: "",
    insertNo: "",
    acronym: "",
    volume: "",
    issue: "",
    suppNumber: "",
    issueDescription: "",
    insertName: "",
    type: "",
    insertWeight: "",
    quantity: "",
    actualInsertWeight: "",
    actualQuantity: "",
    noofPages: "",
    insertSize: "",
    foldingRequired: "",
    applyTo: "",
    recipients: "",
    placement: "",
    oversHandling: "",
    societyInsert: "",
    contactName: "",
    eMail: "",
    isActive: "",
    part: "",
    jdsrInstruction: "",
  };

  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Insert Master");
    this.props.setSelectedList("publisherMainLabelOpen", "pubInsertMaster");

    let insertmasterId =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];

    getViewDetailsForInsertMaster(insertmasterId).then((r) => {
      dispatch({
        type: "getInsertmaster",
        newStore: r.data,
      });
      this.setState({
        statusName: r.data.statusName != null && r.data.statusName.trim(),
        customerName: r.data.customerName != null && r.data.customerName.trim(),
        insertNo: r.data.insertNo != null && r.data.insertNo.trim(),
        acronym: r.data.acronym != null && r.data.acronym.trim(),
        volume: r.data.volume != null && r.data.volume.trim(),
        issue: r.data.issue != null && r.data.issue.trim(),
        part: r.data.part != null && r.data.part.trim(),
        suppNumber: r.data.suppNumber,
        issueDescription: r.data.issueDescription,
        insertName: r.data.insertName != null && r.data.insertName.trim(),
        type: r.data.type != null && r.data.type.trim(),
        insertWeight: r.data.insertWeight,
        quantity: r.data.quantity,
        actualInsertWeight: r.data.actualInsertWeight,
        actualQuantity: r.data.actualQuantity,
        noofPages: r.data.noofPages != null && r.data.noofPages.trim(),
        insertSize: r.data.insertSize != null && r.data.insertSize.trim(),
        foldingRequired:
          r.data.foldingRequired != null && r.data.foldingRequired.trim(),
        applyTo: r.data.applyTo != null && r.data.applyTo.trim(),
        recipients: r.data.recipients != null && r.data.recipients.trim(),
        placement: r.data.placement != null && r.data.placement.trim(),
        oversHandling:
          r.data.oversHandling != null && r.data.oversHandling.trim(),
        societyInsert:
          r.data.societyInsert != null && r.data.societyInsert.trim(),
        contactName: r.data.contactName != null && r.data.contactName.trim(),
        eMail: r.data.eMail != null && r.data.eMail.trim(),
        isActive: r.data.isActive,
        jdsrInstruction:
          r.data.jdsrInstruction != null && r.data.jdsrInstruction.trim(),
      });
    });
  }
  render() {
    let { classes } = this.props;
    let id =
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.split("/")[2];
    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar
            id={id}
            selectedinsertmasterId={id}
            roles={this.props.roles}
            statusName={this.state.statusName}
          />
          <Divider />
          <div className="view-page-container" id="printable">
            {/* <div className="view-page-headings">Insert Master Information</div> */}
            {/* <Divider /> */}
            <div>
              <div>
                {/* <p
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    fontWeight: "normal",
                    paddingTop: "1em",
                    marginBottom: "1em",
                  }}
                >
                  Journal Details
                </p> */}
                <div className="view-page-headings">Journal Details</div>
                <div className="view-page-contents-container">
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Acronym</div>
                    <div className="view-page-value">{this.state.acronym}</div>
                  </div>
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Volume</div>
                    <div className="view-page-value">{this.state.volume}</div>
                  </div>
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Issue No.</div>
                    <div className="view-page-value">{this.state.issue}</div>
                  </div>{" "}
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Part</div>
                    <div className="view-page-value">{this.state.part}</div>
                  </div>
                </div>
                <div className="view-page-contents-container">
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Supp No.</div>
                    <div className="view-page-value">
                      {this.state.suppNumber}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Issue Description</div>
                    <div className="view-page-value">
                      {this.state.issueDescription}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">JDSR Instruction</div>
                    <div className="view-page-value">
                      {this.state.jdsrInstruction}
                    </div>
                  </div>
                </div>
                {/* <p
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    fontWeight: "normal",
                    paddingTop: "1em",
                    marginBottom: "1em",
                  }}
                >
                  Insert Details
                </p> */}
                <div className="view-page-headings">Insert Details</div>
                <div className="view-page-contents-container">
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Insert No.</div>
                    <div className="view-page-value">{this.state.insertNo}</div>
                  </div>
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Insert Name</div>
                    <div className="view-page-value">
                      {this.state.insertName}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Type</div>
                    <div className="view-page-value">{this.state.type}</div>
                  </div>

                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">No. of Pages</div>
                    <div className="view-page-value">
                      {this.state.noofPages}
                    </div>
                  </div>
                </div>
                <div className="view-page-contents-container">
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Insert Weight</div>
                    <div className="view-page-value">
                      {this.state.insertWeight}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Quantity</div>
                    <div className="view-page-value">{this.state.quantity}</div>
                  </div>
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Insert Size</div>
                    <div className="view-page-value">
                      {this.state.insertSize}
                    </div>
                  </div>{" "}
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Folding required</div>
                    <div className="view-page-value">
                      {this.state.foldingRequired}
                    </div>
                  </div>
                </div>
                <div className="view-page-contents-container">
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Apply To</div>
                    <div className="view-page-value">{this.state.applyTo}</div>
                  </div>
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Recipients</div>
                    <div className="view-page-value">
                      {this.state.recipients}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Placement</div>
                    <div className="view-page-value">
                      {this.state.placement}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Over Handling</div>
                    <div className="view-page-value">
                      {this.state.oversHandling}
                    </div>
                  </div>
                </div>
                <div className="view-page-contents-container">
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Society Insert</div>
                    <div className="view-page-value">
                      {this.state.societyInsert}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Contact Name</div>
                    <div className="view-page-value">
                      {this.state.contactName}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Email</div>
                    <div className="view-page-value">{this.state.eMail}</div>
                  </div>
                </div>
                <div className="view-page-headings"> Actual Info</div>
                <div className="view-page-contents-container">
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Actual Weight(Gms)</div>
                    <div className="view-page-value">
                      {this.state.actualInsertWeight
                        ? this.state.actualInsertWeight
                        : "0.000"}
                    </div>
                  </div>
                  <div
                    className="view-page-contents-sub-container-insert"
                    style={{ marginRight: "1em" }}
                  >
                    <div className="view-page-label">Actual Quantity</div>
                    <div className="view-page-value">
                      {this.state.actualQuantity
                        ? this.state.actualQuantity
                        : 0}
                    </div>
                  </div>
                </div>
              </div>
              <Divider style={{ marginTop: "25px" }} />
            </div>
          </div>
          <div className="button-wrapper" style={{ padding: "0 2em 1em 2em" }}>
            <div>
              <Button
                variant="contained"
                color="primary"
                //disableElevation
                style={{ boxShadow: "none" }}
                onClick={(e) => this.props.history.push("/pubInsertMaster")}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles)(ViewInsertMaster));
