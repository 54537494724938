import React, { Component } from "react";

class PublisherBLCPTG extends Component {
    state = {};
    componentDidMount() {
        this.props.handleSettingHeader("BL CPT G3");
        this.props.setSelectedList("publisherReportsOpen", "PublisherBLCPTG");
    }
    render() {
        // alert("collectionnote");
        //const url = "http://172.22.43.196/report/reportviewer?Reportname=ReorderLevel";
        return (
            <div>
                <iframe
                    style={{ width: "calc(100vw - 330px)", height: "calc(100vh - 134px)" }}
                    src={`${window.localStorage.ReportURLByID}` + "BL_CPT_G3"}
                />
            </div>
        );
    }
}

export default PublisherBLCPTG;
