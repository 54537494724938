import React, { Component, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { addStyles } from "../../../shared/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import { StateContext } from "../../../shared/globalState";
import { getCountryDropdownList } from "../../masters/masterActions";
import {
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import Validator from "../../../shared/validator";
import { resourceValidation } from "../../../shared/resource";
import { getRecipientListByID, updateRecipientList } from "./action";
const validatePhoneNo = new Validator("phnNoFCN");
const onlyNumbers = new Validator("onlyNumbers");
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: "absolute", right: "0" }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class EditRecipientList extends React.Component {
  static contextType = StateContext;
  state = {
    open: false,
    recipientList: {
      companyName: "",
      department: "",
      postalCode: "",
      address1: "",
      address2: "",
      phoneNumber: "",
      contactPersonName: "",
      countryName: "",
      phoneNumber: "",
    },
    success: false,
    updateValue: false,

    countryList: [],
  };

  componentDidMount() {
    const [{ store }, dispatch] = this.context;
    let loggedInId = localStorage.loggedInId;
    getCountryDropdownList().then((r) => {
      dispatch({
        type: "getCountryDropdownList",
        newStore: r.data,
      });
      this.getAllStatus(r.data);
    });
    getRecipientListByID(this.props.selectedID, loggedInId).then((r) => {
      let newList = r;

      this.setState({ recipientList: newList });
    });
  }

  getAllStatus = (list) => {
    this.setState({
      countryList:
        list &&
        list.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
        ),
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.errors;
    let companyValid = this.state.companyValid;
    let departmentValid = this.state.departmentValid;
    let postalCodeValid = this.state.postalCodeValid;
    let address1Valid = this.state.address1Valid;
    let address2Valid = this.state.address2Valid;
    let contactNameValid = this.state.contactNameValid;
    let phoneNumberValid = this.state.phoneNumberValid;

    switch (fieldName) {
      case "company":
        if (value !== "null") {
          companyValid = true;
          fieldValidationErrors.company = companyValid
            ? ""
            : " Please fill Company";
        } else {
          companyValid = true;
          fieldValidationErrors.company = "";
        }
        break;
      case "department":
        if (value !== "null") {
          departmentValid = true;
          fieldValidationErrors.department = departmentValid
            ? ""
            : " Please fill department";
        } else {
          departmentValid = true;
          fieldValidationErrors.department = "";
        }
        break;
      case "postalCode":
        if (value !== "") {
          postalCodeValid = onlyNumbers(value);
          fieldValidationErrors.postalCode = postalCodeValid
            ? ""
            : resourceValidation.onlyNumbers;
        }
        break;
      case "address1":
        if (value !== "null") {
          address1Valid = true;
          fieldValidationErrors.address1 = address1Valid
            ? ""
            : " Please fill Address1";
        } else {
          address1Valid = true;
          fieldValidationErrors.address1 = "";
        }
        break;
      case "address2":
        if (value !== "null") {
          address2Valid = true;
          fieldValidationErrors.address2 = address2Valid
            ? ""
            : " Please fill Address2";
        } else {
          address2Valid = true;
          fieldValidationErrors.address2 = "";
        }
        break;
      case "phoneNumber":
        if (value !== "") {
          phoneNumberValid = validatePhoneNo(value);
          fieldValidationErrors.phoneNumber = phoneNumberValid
            ? ""
            : resourceValidation.validPhoneNo;
        }
        break;

      case "address2":
        if (value !== "null") {
          address2Valid = true;
          resourceValidation.customerName = address2Valid
            ? ""
            : " Please fill Address2";
        } else {
          address2Valid = true;
          resourceValidation.customerName = "";
        }
        break;
      default:
        break;
    }
    this.setState(
      {
        errors: fieldValidationErrors,
        companyValid,
        departmentValid,
        postalCodeValid,
        address1Valid,
        address2Valid,
        contactNameValid,
        phoneNumberValid,
        submitSuccess: false,
      },
      this.validateForm
    );
  }

  handleClose = () => {
    if (this.props.handleDuplicateClose) this.props.handleDuplicateClose();
    this.setState({
      open: false,
      recipientList: {
        companyName: "",
        department: "",
        postalCode: "",
        address1: "",
        address2: "",
        phoneNumber: "",
        contactName: "",
        country: "",
        phoneNumber: "",
      },
      searchVal: "",
      searchClicked: false,
      stateWO: this.props.selectedID,
    });
  };
  handleDialogOpen = () => {
    this.setState({
      recipientList: {
        companyName: "",
        department: "",
        postalCode: "",
        address1: "",
        address2: "",
        phoneNumber: "",
        contactName: "",
        country: "",
        phoneNumber: "",
      },
      searchVal: "",
      searchClicked: false,
      stateWO: this.props.selectedID,
    });
  };
  refreshPage() {
    window.location.reload(false);
  }
  handleSubmit = () => {
    let { recipientList } = this.state;
    let selected = this.props && this.props.selectedID;
    let modifiedBy = localStorage.loggedInId;

    let reqBody = {
      companyName: recipientList.companyName,
      department: recipientList.department,
      postalCode: recipientList.postalCode,
      address1: recipientList.address1,
      address2: recipientList.address2,
      phoneNumber: recipientList.phoneNumber,
      contactPersonName: recipientList.contactPersonName,
      countryID: recipientList.countryID,
      phoneNumber: recipientList.phoneNumber,
      modifiedBy: modifiedBy,
    };

    updateRecipientList(selected, reqBody).then((r) => {
      if (r === true) {
        this.setState({ success: true }, () => {
          this.props.change(this.state.success);
        });
        if (this.props.handleDuplicateClose) this.props.handleDuplicateClose();
        //this.refreshPage();
      }
    });
  };

  onInputChanged = (e) => {
    this.setState({ searchVal: e.target.value });
  };

  _onKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }

  handleChange = (event) => {
    let { recipientList } = this.state;
    recipientList[event.target.name] = event.target.value;
    this.setState({ recipientList: recipientList, updateValue: true });
    // this.validateField(event.target.name, event.target.value);
  };
  handleCountry = (event, values) => {
    let name = values && values.text;
    let countryID = values && values.id;
    const { recipientList } = this.state;
    recipientList["countryName"] = name;
    recipientList["countryID"] = countryID;
    this.setState({ recipientList: recipientList, updateValue: true });
  };
  _onMobileNumberKeyPress(event) {
    const re = /[0-9+() -]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  _onNumberKeyPress(event) {
    const re = /[0-9.]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  render() {
    let selected = this.props && this.props.selectedID;
    let { classes } = this.props;

    let { recipientList, success } = this.state;
    let Update = null;
    if (success === false) {
      Update = (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          onClose={this.props.handleDuplicateClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.duplicateOpen}
          maxWidth="lg"
        >
          {this.props.viewMode ? (
            <DialogTitle
              style={{ display: "flex", alignItems: "center" }}
              id="customized-dialog-title"
              onClose={this.props.handleDuplicateClose}
            >
              View Recipient
            </DialogTitle>
          ) : (
              <DialogTitle
                style={{ display: "flex", alignItems: "center" }}
                id="customized-dialog-title"
                onClose={this.props.handleDuplicateClose}
              >
                Update Recipient
              </DialogTitle>
            )}

          <DialogContent dividers>
            <div style={{ minWidth: "800px" }}>
              <div>
                <div style={{ marginTop: "-15px" }}>
                  <form onSubmit={this.handleSubmit}>
                    <div
                      style={{
                        fontFamily: "Roboto",
                        fontSize: "15px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        color: "#3982ea",
                        paddingTop: "15px",
                        marginBottom: "10px",
                      }}
                    >
                      Delivery Address
                    </div>
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {" "}
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "33.33%",
                            marginRight: "0",
                          }}
                        >
                          {" "}
                          <TextField
                            label="Company Name"
                            name="companyName"
                            required
                            inputProps={{
                              maxLength: 100,
                              readOnly: this.props.viewMode || true,
                            }}
                            variant="filled"
                            style={{
                              marginTop: "5px",
                              width: "100%",
                              marginRight: "0",
                            }}
                            margin="normal"
                            autoComplete="off"
                            value={recipientList.companyName}
                            onChange={this.handleChange}
                          // error={this.state.errors.companyName}
                          // helperText={
                          //   this.state.errors.companyName
                          //     ? this.state.errors.companyName
                          //     : ""
                          // }
                          />
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "33.33%",
                            margin: "0 15px",
                          }}
                        >
                          {" "}
                          <TextField
                            label="Department"
                            name="department"
                            // required

                            inputProps={{
                              maxLength: 100,
                              readOnly: this.props.viewMode,
                            }}
                            style={{
                              marginTop: "5px",
                              width: "100%",
                            }}
                            margin="normal"
                            autoComplete="off"
                            value={recipientList.department}
                            onChange={this.handleChange}
                          // error={this.state.errors.department}
                          // helperText={
                          //   this.state.errors.department
                          //     ? this.state.errors.department
                          //     : ""
                          // }
                          />
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "33.33%",
                            marginRight: "0",
                          }}
                        >
                          {" "}
                          <TextField
                            label="Postal Code"
                            name="postalCode"
                            required
                            inputProps={{
                              maxLength: 50,
                              readOnly: this.props.viewMode,
                            }}
                            style={{
                              marginTop: "5px",
                              width: "100%",
                              marginRight: "0",
                            }}
                            margin="normal"
                            autoComplete="off"
                            onKeyPress={this._onNumberKeyPress}
                            value={recipientList.postalCode}
                            onChange={this.handleChange}
                          // error={this.state.errors.postalCode}
                          // helperText={
                          //   this.state.errors.postalCode
                          //     ? this.state.errors.postalCode
                          //     : ""
                          // }
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "100%",
                            marginRight: "0",
                          }}
                        >
                          <TextField
                            label="Address 1"
                            name="address1"
                            required
                            inputProps={{
                              readOnly: this.props.viewMode,
                              maxLength: 100,
                            }}
                            style={{
                              marginTop: "5px",
                              width: "100%",
                              marginRight: "0",
                            }}
                            margin="normal"
                            autoComplete="off"
                            value={recipientList.address1}
                            onChange={this.handleChange}
                          // error={this.state.errors.address1}
                          // helperText={
                          //   this.state.errors.address1
                          //     ? this.state.errors.address1
                          //     : ""
                          // }
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "100%",
                            marginRight: "0",
                          }}
                        >
                          <TextField
                            label="Address 2"
                            name="address2"
                            // required
                            inputProps={{
                              readOnly: this.props.viewMode,
                              maxLength: 100,
                            }}
                            style={{
                              marginTop: "5px",
                              width: "100%",
                              marginRight: "0",
                            }}
                            margin="normal"
                            autoComplete="off"
                            value={recipientList.address2}
                            onChange={(e) => this.handleChange(e, "address2")}
                          // error={this.state.errors.address2}
                          // helperText={
                          //   this.state.errors.address2
                          //     ? this.state.errors.address2
                          //     : ""
                          // }
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "15px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          color: "#3982ea",
                          paddingTop: "15px",
                          margin: "5px 0 13px",
                        }}
                      >
                        Contact Information
                      </div>
                      <div style={{ display: "flex", alignItems: "baseline" }}>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "33.33%",
                            margin: "0",
                          }}
                        >
                          {" "}
                          <TextField
                            label="Contact Person Name"
                            name="contactPersonName"
                            required
                            inputProps={{
                              maxLength: 100,
                              readOnly: this.props.viewMode,
                            }}
                            style={{
                              marginRight: "0",
                              marginTop: "0",
                              width: "100%",
                              margin: "0",
                            }}
                            margin="normal"
                            autoComplete="off"
                            value={recipientList.contactPersonName}
                            onChange={this.handleChange}
                          // error={this.state.errors.contactName}
                          // helperText={
                          //   this.state.errors.contactName
                          //     ? this.state.errors.contactName
                          //     : ""
                          // }
                          />
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "33.33%",
                            margin: "0 15px",
                          }}
                        >
                          {this.props.viewMode ? (
                            <TextField
                              label="Country"
                              name="countryName"
                              required
                              style={{
                                marginRight: "0px",
                                marginTop: "0",
                                width: "100%",
                              }}
                              margin="normal"
                              autoComplete="off"
                              value={recipientList.countryName}
                            />
                          ) : (
                              <Autocomplete
                                required
                                includeInputInList
                                label="Country"
                                name="countryName"
                                options={this.state.countryList}
                                onChange={this.handleCountry}
                                value={recipientList.countryName}
                                getOptionLabel={(option) =>
                                  option.text || recipientList.countryName
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label="Country"
                                    name="country"
                                    style={{
                                      marginTop: "0px",
                                      marginLeft: "0 15px",
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            )}
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            width: "33.33%",
                            marginRight: "0",
                          }}
                        >
                          {" "}
                          <TextField
                            label="Phone Number"
                            name="phoneNumber"
                            required
                            inputProps={{
                              maxLength: 20,
                              readOnly: this.props.viewMode,
                            }}
                            style={{
                              marginRight: "0px",
                              marginTop: "0",
                              width: "100%",
                            }}
                            margin="normal"
                            autoComplete="off"
                            value={recipientList.phoneNumber}
                            onChange={(e) =>
                              this.handleChange(e, "phoneNumber")
                            }
                            onKeyPress={this._onMobileNumberKeyPress}
                          // error={this.state.errors.phoneNumber}
                          // helperText={
                          //   this.state.errors.phoneNumber
                          //     ? this.state.errors.phoneNumber
                          //     : ""
                          // }
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </DialogContent>
          {this.props.viewMode ? null : (
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "15px 28px",
              }}
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ boxShadow: "none" }}
                  onClick={() => this.handleClose()}
                >
                  CANCEL
                </Button>
              </div>
              <div style={{ marginLeft: "1em" }}>
                {recipientList.postalCode === "" ||
                  recipientList.address1 === "" ||
                  recipientList.companyName === "" ||
                  recipientList.contactPersonName === "" ||
                  recipientList.countryID === null ||
                  recipientList.phoneNumber === "" ||
                  !this.state.updateValue ? (
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={this.handleSubmit}
                    >
                      Submit
                    </Button>
                  )}
              </div>
            </DialogActions>
          )}
        </Dialog>
      );
    }

    return <div>{Update}</div>;
  }
}

export default withRouter(withStyles(addStyles)(EditRecipientList));
