import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { StateContext } from "../../../shared/globalState";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Validator from "../../../shared/validator";
import clone from "clone"
import TextField from "@material-ui/core/TextField";
import { printDetails } from "../../../shared/tableCommonFunctions";
import Typography from "@material-ui/core/Typography";
import { Tooltip } from "@material-ui/core";
import {
  PrintIcon,
} from "../../../shared/customIcons";
import IconButton from "@material-ui/core/IconButton";
import "date-fns";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
// import WorkOrderModal from "../docketHeader/workOrderModel";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { getDocHeaderForView, getDocDetailsForView, updateDocketPublisher } from "./action";
import moment from "moment";

const headCells = [

  {
    id: 7,
    code: "destination",
    numeric: false,
    disablePadding: false,
    label: "Destination",
    width: "40%",
  },
  {
    id: 17,
    code: "ms",
    numeric: false,
    disablePadding: false,
    label: "Mail Size",
    width: "10%",
  },
  {
    id: 8,
    code: "weightPerItem",
    numeric: false,
    disablePadding: false,
    label: "Wt per Item(gm)",
    width: "auto",
  },
  {
    id: 9,
    code: "noOfArticles",
    numeric: false,
    disablePadding: false,
    label: "No of Articles *",
    width: "auto",
  },

  {
    id: 10,
    code: "totalWeight",
    numeric: false,
    disablePadding: false,
    label: "Total Chargeable Wt(kg)",
    width: "auto",
  },
  {
    id: 11,
    code: "postageCharges",
    numeric: false,
    disablePadding: false,
    label: "Postage Charges",
    width: "auto",
  },
  {
    id: 12,
    code: "totalCharge",
    numeric: false,
    disablePadding: false,
    label: "Total Charge",
    width: "auto",
  },
];

const headCellslocal = [
  {
    id: 1,
    code: "fileName",
    numeric: false,
    disablePadding: true,
    label: "File Name",
    width: "auto",
  },
  // {
  //   id: 2,
  //   code: "zone",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Zone",
  //   width: "auto"
  // },
  {
    id: 2,
    code: "country",
    numeric: false,
    disablePadding: false,
    label: "Country",
    width: "auto",
  },
  // {
  //   id: 3,
  //   code: "province",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Province",
  //   width: "auto",
  // },
  {
    id: 3,
    code: "copies",
    numeric: false,
    disablePadding: false,
    label: "Copies",
    width: "auto",
  },
  {
    id: 4,
    code: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
    width: "auto",
  },
  // {
  //   id: 5,
  //   code: "totalOrderQuantity",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Tot Order Qty",
  //   width: "auto",
  // },
  // {
  //   id: 6,
  //   code: "remarks",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Remarks",
  //   width: "auto",
  // },
  {
    id: 5,
    code: "routing",
    numeric: false,
    disablePadding: false,
    label: "Routing",
    width: "auto",
  },
  // {
  //   id: 8,
  //   code: "cop",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "COP",
  //   width: "auto",
  // },
  {
    id: 6,
    code: "mailSize",
    numeric: false,
    disablePadding: false,
    label: "Mail Size",
    width: "auto",
  },
  {
    id: 7,
    code: "weightPerItem",
    numeric: false,
    disablePadding: false,
    label: "Wt per Item(gm)",
    width: "auto",
  },
  {
    id: 8,
    code: "noOfArticles",
    numeric: false,
    disablePadding: false,
    label: "No of Articles",
    width: "auto",
  },

  // {
  //   id: 12,
  //   code: "noOfBag",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "No of Bag(kg)",
  //   width: "auto"
  // },
  {
    id: 9,
    code: "totalWeight",
    numeric: false,
    disablePadding: false,
    label: "Total Chargeable Wt(kg)",
    width: "auto",
  },
  {
    id: 10,
    code: "postageCharges",
    numeric: false,
    disablePadding: false,
    label: "Postage Charges",
    width: "auto",
  },
  {
    id: 11,
    code: "totalCharges",
    numeric: false,
    disablePadding: false,
    label: "Total Charges",
    width: "auto",
  },
];

function createData(
  docketHeaderID,
  fileName,
  zone,
  country,
  province,
  copies,
  quantity,
  totalOrderQuantity,
  remarks,
  routing,
  //pgsDocketNumber,
  cop,
  isBM1500Countries,
  // mailSize,
  //noOfBags,
  //weightPerBag,
  weightPerItem,
  noOfArticles,
  totalWeight,
  postageCharges,
  totalCharges,
  isActive
) {
  return {
    docketHeaderID,
    fileName,
    zone,
    country,
    province,
    copies,
    quantity,
    totalOrderQuantity,
    remarks,
    routing,
    //pgsDocketNumber,
    cop,
    isBM1500Countries,
    // mailSize,
    //noOfBags,
    //weightPerBag,
    weightPerItem,
    noOfArticles,
    totalWeight,
    postageCharges,
    totalCharges,
    isActive,
  };
}
function createDatalocal(
  docketHeaderID,
  fileName,
  // zone,
  country,
  province,
  copies,
  quantity,
  totalOrderQuantity,
  remarks,
  routing,
  //pgsDocketNumber,
  cop,
  mailSize,
  //noOfBags,
  //weightPerBag,
  weightPerItem,
  noOfArticles,
  totalWeight,
  postageCharges,
  totalCharges,
  isActive
) {
  return {
    docketHeaderID,
    fileName,
    // zone,
    country,
    province,
    copies,
    quantity,
    totalOrderQuantity,
    remarks,
    routing,
    //pgsDocketNumber,
    cop,
    mailSize,
    //noOfBags,
    //weightPerBag,
    weightPerItem,
    noOfArticles,
    totalWeight,
    postageCharges,
    totalCharges,
    isActive,
  };
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    flexWrap: "wrap",
    margin: "1em",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "31%",
    fontSize: "11px !important",
  },
  autocompleteText: {
    marginRight: theme.spacing(2),
    width: "100%",
    fontSize: "11px !important",
  },
  autocomplete: {
    width: "100%",
    fontSize: "11px !important",
  },
  resize: {
    fontSize: 14,
  },
  subHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#21529f",
    marginLeft: "1.5em",
    paddingTop: "1em",
  },
});

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    DHeaderList,
  } = props;
  return (
    <TableHead>
      <TableRow>
        {DHeaderList && DHeaderList.isLocal ? headCells.map((headCell, i) => (

          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderLeft: "1px sold #000", width: `${headCell.width}` }}
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        )) : headCells.map((headCell, i) => (
          i !== 1 &&
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderLeft: "1px sold #000", width: `${headCell.width}` }}
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function EnhancedTableHeadlocal(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  return (
    <TableHead>
      <TableRow>
        {headCellslocal.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            className={i !== headCells.length - 1 ? "headcells" : "headcell"}
            style={{ borderLeft: "1px sold #000", width: `${headCell.width}` }}
          >
            <span style={{ color: "#306dca" }}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    //borderTop: "1px solid #eaeaea"
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let acnArr = props.roles.filter((u) => u.menuName === "Docket Posting");
  let acnObj = acnArr[0];

  return (
    <div className={classes.root}>
      {/* <div
        style={{
          display: "flex",
          width: "100%"
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Tooltip title="Print" style={{ padding: "5px", paddingTop: "12px" }}>
            <IconButton
              aria-label="print"
              style={{}}
              className={classes.iconHover}
              onClick={e => printDetails()}
            >
              <PrintIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div> */}
    </div>
  );
};
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const EnhancedTableToolbar1 = (props) => {
  const classes = useToolbarStyles();
  const { } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let acnArr = props.roles.filter((u) => u.menuName === "Docket Posting");
  let acnObj = acnArr[0];
  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {/* <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {acnObj && acnObj.printAction && (
            <Tooltip
              title="Print"
              style={{ padding: "5px", paddingTop: "12px" }}
            >
              <IconButton
                aria-label="print"
                style={{}}
                className={classes.iconHover}
                onClick={(e) => printDetails()}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
        </div> */}
      </div>
    </div>
  );
};
EnhancedTableToolbar1.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

class ViewDocketPosting extends React.Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      disableSubmitButton: true,
      DHeaderList: [],
      DDetailList: [],
      oreginalDDetailList: [],
      docketHeader: {
        docketHeaderID: "",
        customerName: "",
        workOrderNumber: "",
        title: "",
        docketHeaderNumber: "",
        pgsDocketNO: "",
        eDocketNO: "",
        status: "",
        modifiedBy: "",
        modifiedDate: "",
      },
      docketHeaderDetails: [],
      page: 0,
      rowsPerPage: 100,
      refreshData: false,
      loading: false,
      order: "desc",
      orderBy: "modifiedDate",
      headCells: [
        {
          id: 1,
          code: "fileName",
          numeric: false,
          disablePadding: true,
          label: "File Name",
          width: "auto",
        },
        {
          id: 2,
          code: "zone",
          numeric: false,
          disablePadding: false,
          label: "Zone",
          width: "auto",
        },
        {
          id: 3,
          code: "country",
          numeric: false,
          disablePadding: false,
          label: "Country",
          width: "auto",
        },
        {
          id: 4,
          code: "province",
          numeric: false,
          disablePadding: false,
          label: "Province",
          width: "auto",
        },
        {
          id: 5,
          code: "copies",
          numeric: false,
          disablePadding: false,
          label: "Copies",
          width: "auto",
        },
        {
          id: 6,
          code: "quantity",
          numeric: false,
          disablePadding: false,
          label: "Quantity",
          width: "auto",
        },
        {
          id: 7,
          code: "totalOrderQuantity",
          numeric: false,
          disablePadding: false,
          label: "Tot Order Qty",
          width: "auto",
        },
        {
          id: 8,
          code: "remarks",
          numeric: false,
          disablePadding: false,
          label: "Remarks",
          width: "auto",
        },
        {
          id: 9,
          code: "routing",
          numeric: false,
          disablePadding: false,
          label: "Routing",
          width: "auto",
        },
        {
          id: 10,
          code: "cop",
          numeric: false,
          disablePadding: false,
          label: "COP",
          width: "auto",
        },
        {
          id: 11,
          code: "isBM1500Countries",
          numeric: false,
          disablePadding: false,
          label: "COP",
          width: "BM 1500 Countries",
        },
        // {
        //   id: 9,
        //   code: "mailSize",
        //   numeric: false,
        //   disablePadding: false,
        //   label: "Mail Size",
        //   width: "auto"
        // },
        {
          id: 12,
          code: "weightPerItem",
          numeric: false,
          disablePadding: false,
          label: "Wt per Item(gm)",
          width: "auto",
        },
        {
          id: 13,
          code: "noOfArticles",
          numeric: false,
          disablePadding: false,
          label: "No of Articles",
          width: "auto",
        },
        // {
        //   id: 12,
        //   code: "noOfBag",
        //   numeric: false,
        //   disablePadding: false,
        //   label: "No of Bag(kg)",
        //   width: "auto"
        // },
        {
          id: 14,
          code: "totalWeight",
          numeric: false,
          disablePadding: false,
          label: "Total Chargeable Wt(kg)",
          width: "auto",
        },
        {
          id: 15,
          code: "postageCharges",
          numeric: false,
          disablePadding: false,
          label: "Postage Charges",
          width: "auto",
        },
        {
          id: 16,
          code: "totalCharge",
          numeric: false,
          disablePadding: false,
          label: "Total Charge",
          width: "auto",
        },
      ],
      headCellslocal: [
        {
          id: 1,
          code: "fileName",
          numeric: false,
          disablePadding: true,
          label: "File Name",
          width: "auto",
        },
        // {
        //   id: 2,
        //   code: "zone",
        //   numeric: false,
        //   disablePadding: false,
        //   label: "Zone",
        //   width: "auto"
        // },
        {
          id: 2,
          code: "country",
          numeric: false,
          disablePadding: false,
          label: "Country",
          width: "auto",
        },
        {
          id: 3,
          code: "province",
          numeric: false,
          disablePadding: false,
          label: "Province",
          width: "auto",
        },
        {
          id: 3,
          code: "copies",
          numeric: false,
          disablePadding: false,
          label: "Copies",
          width: "auto",
        },
        {
          id: 4,
          code: "quantity",
          numeric: false,
          disablePadding: false,
          label: "Quantity",
          width: "auto",
        },
        {
          id: 5,
          code: "totalOrderQuantity",
          numeric: false,
          disablePadding: false,
          label: "Tot Order Qty",
          width: "auto",
        },
        {
          id: 6,
          code: "remarks",
          numeric: false,
          disablePadding: false,
          label: "Remarks",
          width: "auto",
        },
        {
          id: 7,
          code: "routing",
          numeric: false,
          disablePadding: false,
          label: "Routing",
          width: "auto",
        },
        {
          id: 8,
          code: "cop",
          numeric: false,
          disablePadding: false,
          label: "COP",
          width: "auto",
        },
        {
          id: 9,
          code: "mailSize",
          numeric: false,
          disablePadding: false,
          label: "Mail Size",
          width: "auto",
        },
        {
          id: 10,
          code: "weightPerItem",
          numeric: false,
          disablePadding: false,
          label: "Wt per Item(gm)",
          width: "auto",
        },
        {
          id: 11,
          code: "noOfArticles",
          numeric: false,
          disablePadding: false,
          label: "No of Articles",
          width: "auto",
        },

        // {
        //   id: 12,
        //   code: "noOfBag",
        //   numeric: false,
        //   disablePadding: false,
        //   label: "No of Bag(kg)",
        //   width: "auto"
        // },
        {
          id: 12,
          code: "totalWeight",
          numeric: false,
          disablePadding: false,
          label: "Total Chargeable Wt(kg)",
          width: "auto",
        },
        {
          id: 13,
          code: "postageCharges",
          numeric: false,
          disablePadding: false,
          label: "Postage Charges",
          width: "auto",
        },
        {
          id: 14,
          code: "totalCharges",
          numeric: false,
          disablePadding: false,
          label: "Total Charges",
          width: "auto",
        },
      ],
    };
  }
  componentDidMount() {
    const dispatch = this.context[1];
    this.props.handleSettingHeader("Back Label Docket Bagging");
    this.props.setSelectedList(
      "publisherBackLabelOpen",
      "backLabelDocketPosting"
    );

    let docketHeaderID
    if (process.browser)
      docketHeaderID = window.location.pathname.split("/")[2]
    getDocHeaderForView(docketHeaderID).then((r) => {
      this.setState({
        DHeaderList: r.data,
      });
    });
    getDocDetailsForView(docketHeaderID).then((r) => {
      this.setState({
        DDetailList: r.data,
        oreginalDDetailList: r.data
      });
    });
  }


  addZeroes(num, decimalPlaces) {
    var value = Number(num);
    var res = num.split(".");
    if (res.length === 1 || res[1].length < decimalPlaces) {
      value = value.toFixed(decimalPlaces);
    }
    if (value !== "") {
      value = String(value);
    }
    return value;
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleRequestSort = (event, property) => {
    let { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  };
  updateDocket = () => {
    let rBody = []
    let id = ""
    if (process.browser)
      id = window.location.pathname.split("/")[2]

    let loggedInUser = localStorage.getItem("loggedInId")
    this.state.DDetailList && this.state.DDetailList.length > 0 && this.state.DDetailList.map(d => {
      rBody.push({
        mlDocketDetailID: d.mlDocketDetailID,
        noOfArticles: +d.noOfArticles,
        modifiedBy: +loggedInUser,
        totalChargeableWeight: d.totalChargeableWeight
      })
    })
    if (id)
      updateDocketPublisher(id, rBody, this.state.DHeaderList.titleofArticle, this.props.history).then(r => {
      }).catch(e => {
      })
  }
  onArticleChange = (e, i) => {
    let newDDetailsList = clone(this.state.DDetailList)
    if (e.target.value && e.target.value != this.state.oreginalDDetailList[i].noOfArticles)
      this.setState({ disableSubmitButton: false })
    else
      this.setState({ disableSubmitButton: true })
    newDDetailsList[i].noOfArticles = e.target.value
    let newTCGValue = ""
    newTCGValue = (newDDetailsList[i].weightPerItem * e.target.value)
    newDDetailsList[i].totalChargeableWeight = newTCGValue
    this.setState({ DDetailList: newDDetailsList })
  }
  onTitleOfArticleChange = e => {
    let nD = this.state.DHeaderList
    nD.titleofArticle = e.target.value
    this.setState({ DHeaderList: nD })
    if (e.target.value) {
      let x = false
      this.state.DDetailList && this.state.DDetailList.length > 0 && this.state.DDetailList.map(g => {
        if (!g.noOfArticles)
          x = true
      })
      this.setState({ disableSubmitButton: x })
    }
    else {
      this.setState({ disableSubmitButton: true })

    }
  }
  _onKeyPress(event) {
    const re = /[0-9]+/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  render() {
    let { classes } = this.props;
    let {
      docketHeader,
      docketHeaderDetails,
      selected,
      order,
      orderBy,
      editableDocketDetailList,
      workOrder,
      DHeaderList,
      DDetailList
    } = this.state;
    // const isSelected = id => selected.indexOf(id) !== -1;
    let docketHeaderID = this.props.history.location.pathname.split(
      "/view_docketPosting/"
    )[1];

    return (
      <React.Fragment>
        <Paper>
          <EnhancedTableToolbar1 roles={this.props.roles} />
          <Divider />
          <div id="printable" className="container">


            <Divider />
            <div style={{ margin: "1em 2em 0px 2em" }}>
              <Typography component="div" className="blueBox" style={{ width: "auto" }}>
                <div className="blueBoxLabel">
                  <div>Batch ID: </div>
                  <div className="blueBoxText">{DHeaderList && DHeaderList.batchCode}</div>
                </div>
                <div className="blueBoxLabel">
                  <div>Customer: </div>
                  <div className="blueBoxText">{DHeaderList && DHeaderList.companyName}</div>
                </div>
                {/* <div className="blueBoxLabel">
              <div>Location: </div>
              <div className="blueBoxText">{DHeaderList &&DHeaderList.locationName}</div>
            </div> */}
                <div className="blueBoxLabel">
                  <div>Status: </div>
                  <div className="blueBoxText">{DHeaderList && DHeaderList.status}</div>
                </div>
              </Typography>
              <Typography component="div" className="blueBox" style={{ width: "auto", marginTop: ".5em" }}>
                <div className="blueBoxLabel">
                  <div>PGS Docket No: </div>
                  <div className="blueBoxText">{DHeaderList && DHeaderList.pgsDocketNO}</div>
                </div>
                <div className="blueBoxLabel">
                  <div>EDS Docket No: </div>
                  <div className="blueBoxText">{DHeaderList && DHeaderList.eDocketNO}</div>
                </div>
                <div className="blueBoxLabel" />

              </Typography>
            </div>
            <div
              className="view-page-container"
              style={{
                //  maxHeight: "calc(100vh - 265px)",
                //  minHeight: "300px",
                overflow: "auto",
              }}
              id="printable"
            >

              <div className="view-page-headings">
                Docket Header Information
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Service Type(*)</div>
                  <div className="view-page-value">
                    {DHeaderList.serviceType}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">PPI Permit No.</div>
                  <div className="view-page-value">{DHeaderList.ppiNumber}</div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Account No.</div>
                  <div className="view-page-value">{DHeaderList.accountNo}</div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Name of Contact Person</div>
                  <div className="view-page-value">
                    {DHeaderList.nameOfContactPerson}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Fax of Contact Person</div>
                  <div className="view-page-value">
                    {DHeaderList.faxOfContactPerson}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Mode of Payment</div>
                  <div className="view-page-value">
                    {DHeaderList.modeOfPayment}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Type of articles</div>
                  <div className="view-page-value">
                    {DHeaderList.typeOfArticles}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Mailing Scheme</div>
                  <div className="view-page-value">
                    {DHeaderList.mailingSchema}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Mail characteristics</div>
                  <div className="view-page-value">
                    {DHeaderList.mailCharacteristics ? DHeaderList.mailCharacteristics : ""}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">MARS reseller number</div>
                  <div className="view-page-value">
                    {DHeaderList.marSresellernumber ? DHeaderList.marSresellernumber : ""}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">
                    SP Branding MARS reseller number
                  </div>
                  <div className="view-page-value">
                    {DHeaderList.spBrandingMARSReseller}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">
                    Amendment agreed by PPI permit holder/ mailing house/
                    reseller
                  </div>
                  <div className="view-page-value">
                    {DHeaderList.mailingHouse}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">
                    {/* Estimated */}
                    Lodgement Date
                  </div>
                  <div className="view-page-value">
                    {moment(DHeaderList.lodgementDate).format("DD/MM/YYYY")}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">
                    {/* Estimated */}
                    Lodgement Time
                  </div>
                  <div className="view-page-value">
                    {DHeaderList &&
                      DHeaderList.lodgementTime &&
                      DHeaderList.lodgementTime.slice(0, 8)}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Tel of contact person</div>
                  <div className="view-page-value">{DHeaderList.telephone}</div>
                </div>

                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Cost centre</div>
                  <div className="view-page-value">
                    {DHeaderList.costCenter}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Customer's Ref no</div>
                  <div className="view-page-value">
                    {DHeaderList.customerRefNo}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">
                    Total weight of consignment
                  </div>
                  <div className="view-page-value">
                    {DHeaderList.totalweightofconsignment ? DHeaderList.totalweightofconsignment : ""}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">No of Bags/ receptacle</div>
                  <div className="view-page-value">{DHeaderList.noofBags}</div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Letter Shop</div>
                  <div className="view-page-value">
                    {DHeaderList.letterShop}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">
                    Net weight per receptacle
                  </div>
                  <div className="view-page-value">
                    {DHeaderList.netweightperreceptacle ? DHeaderList.netweightperreceptacle : ""}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Owner of PPI</div>
                  <div className="view-page-value">
                    {DHeaderList.ownerofPPI}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Mailing house</div>
                  <div className="view-page-value">
                    {DHeaderList.mailingHouse}
                  </div>
                </div>
                {/* <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Work Order Number</div>
                  <div className="view-page-value">
                    {DHeaderList.workOrderNumber}
                  </div>
                </div> */}
                {/* <div className="view-page-contents-sub-container">
                <div className="view-page-label">Sender signature and date</div>
                <div className="view-page-value">
                </div>
              </div> */}
              </div>
              {/* <div className="view-page-contents-container">
              
            </div> */}
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Company Name</div>
                  <div className="view-page-value">
                    {DHeaderList.companyName}
                  </div>
                </div>

                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Title of Article 1</div>
                  <div className="view-page-value">
                    {DHeaderList.titleofArticle1}
                  </div>
                </div>

                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">No Of Units</div>
                  <div className="view-page-value">
                    {DHeaderList.noOfUnits ? DHeaderList.noOfUnits : ""}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Remarks</div>
                  <div className="view-page-value" style={{ width: "280px" }}>
                    {DHeaderList.remarks}
                  </div>
                </div>
              </div>
              <div className="view-page-contents-container">
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">AR</div>
                  <div className="view-page-value">
                    {DHeaderList.isAR ? "Yes" : "No"}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">RA</div>
                  <div className="view-page-value">
                    {DHeaderList.isRA ? "Yes" : "No"}
                  </div>
                </div>

                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Transmission Mode</div>
                  <div className="view-page-value">
                    {DHeaderList.transmissionMode}
                  </div>
                </div>
                <div className="view-page-contents-sub-container">
                  <div className="view-page-label">Title of Article *</div>
                  <div className="view-page-value">

                    <TextField
                      style={{
                        width: "93%",
                        fontSize: "11px !important",
                        // marginLeft: ".5em",
                        marginRight: ".5em",
                        maxHeight: "400px",
                        marginTop: "-6px",
                        height: "17px",
                        fontSize: "14px"

                      }}
                      inputProps={{
                        maxLength: 100,
                      }}
                      // onKeyPress={this._onKeyPress}
                      value={DHeaderList.titleofArticle}
                      margin="normal"
                      onChange={e => this.onTitleOfArticleChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <Divider /> */}
            <form
              className={classes.container}
              style={{ marginBottom: "2px" }}
              onSubmit={this.handleSubmit}
            >
              <div
                style={
                  !this.props.clearOpenList
                    ? {
                      maxWidth: "calc(100vw - 137px)",
                      height: "100%",
                    }
                    : {
                      maxWidth: "calc(100vw - 359px)",
                      height: "100%",
                    }
                }
              >
                <div className="view-page-headings" style={{ margin: "0px .7em 1em .8em" }}>
                  Docket Detail Information
              </div>
                <EnhancedTableToolbar
                  numSelected={selected && selected.length}
                  history={this.props.history}
                  selected={this.state.selected}
                  deletePopupOpen={this.state.deletePopupOpen}
                  handleDeletePopupOpen={this.handleDeletePopupOpen}
                  handleDeletePopupClose={this.handleDeletePopupClose}
                  handleDeleteRunMailList={this.handleDeleteRunMailList}
                  selectedRunMailList={this.state.selectedRunMailList}
                  selecteddocketHeaderID={this.state.selecteddocketHeaderID}
                  clearSearch={this.clearSearch}
                  searchPopupOpen={this.state.searchPopupOpen}
                  handleSearchPopupOpen={this.handleSearchPopupOpen}
                  handleSearchPopupClose={this.handleSearchPopupClose}
                  handleSearchRunMailList={this.handleSearchRunMailList}
                  runMailListSearchValue={this.state.RunMailListSearchValues}
                  searchClicked={this.state.searchClicked}
                  hendleVewColums={this.hendleVewColums}
                  headCells={headCells}
                  isActive={this.state.isActive}
                  handleClickButton={this.handleClickButton}
                  editableDocketDetailList={this.state.editableDocketDetailList}
                  resetList={this.state.resetList}
                  disableAdd={this.state.disableAdd}
                  disableEdit={this.state.disableEdit}
                  roles={this.props.roles}
                />
                <div
                  style={{
                    //  maxHeight: "180px",
                    //  overflow: "auto",
                    // border: ".1px solid #eaeaea",
                    marginLeft: "14px",
                    marginRight: "13px",
                  }}
                >
                  <div
                    style={{
                      overflow: "auto",
                      border: "1px solid #eaeaea",
                    }}
                  >

                    <Table
                      aria-labelledby="tableTitle"
                      size="small"
                      stickyHeader
                      aria-label="sticky table"
                    >
                      <EnhancedTableHead
                        numSelected={selected && selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={(e) => this.handleSelectAllClick(e)}
                        onRequestSort={this.handleRequestSort}
                        DHeaderList={DHeaderList}
                      // rowCount={editableDocketDetailList.length}
                      />
                      <TableBody style={{ overflow: "hidden" }}>
                        {DDetailList &&
                          DDetailList.length > 0 &&
                          DDetailList.map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover

                                tabIndex={-1}
                                key={row.mlDocketHeaderID}
                                id={`tableRow${index}`}

                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  //align="right"
                                  className="table-content-cell"
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.destination}
                                  </Typography>
                                </TableCell>
                                {DHeaderList && DHeaderList.isLocal && <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"

                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {row.mailSizeName}
                                  </Typography>
                                </TableCell>}
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                  style={{ padding: "0px 8px 0px 16px" }}
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {" "}
                                    {row.weightPerItem * 1000}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                  style={{ padding: "0px 8px 0px 16px" }}
                                >
                                  <Typography
                                    //   noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    <TextField
                                      style={{
                                        width: "93%",
                                        fontSize: "11px !important",
                                        marginLeft: ".5em",
                                        marginRight: ".5em",
                                        maxHeight: "400px",
                                        marginTop: "-6px",
                                        height: "17px",
                                        fontSize: "14px"

                                      }}
                                      onKeyPress={this._onKeyPress}
                                      value={row.noOfArticles}
                                      margin="normal"
                                      onChange={e => this.onArticleChange(e, index)}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  align="right"
                                  className="table-content-cell"
                                  style={{ padding: "0px 8px 0px 16px" }}
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {" "}
                                    {row.totalChargeableWeight && (+row.totalChargeableWeight).toFixed(3)}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                  style={{ padding: "0px 8px 0px 16px" }}
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {" "}
                                    {row.postageRate}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  id={labelId}
                                  padding="default"
                                  align="right"
                                  className="table-content-cell"
                                  style={{ padding: "0px 8px 0px 16px" }}
                                >
                                  <Typography
                                    noWrap
                                    style={{ fontSize: "14px" }}
                                  >
                                    {" "}
                                    {row.totalCharges}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                      </TableBody>
                    </Table>
                  </div>
                </div>
                <Divider style={{ margin: "25px .8em" }} />
                <div className="button-wrapper" style={{ margin: "0px .8em", padding: "0px 0px 1em 0px" }}>
                  <div style={{ marginBottom: "1em" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.props.history.goBack()}
                    >
                      CANCEL
                    </Button>
                  </div>
                  <div style={{ marginLeft: "1em" }}>
                    <Button
                      disabled={!DHeaderList.titleofArticle || this.state.disableSubmitButton}
                      variant="contained"
                      color="primary"
                      style={{ boxShadow: "none" }}
                      onClick={(e) => this.updateDocket()}
                    >
                      SUBMIT
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(ViewDocketPosting));
