import axios from "axios";
import toastr from "toastr";

const baseURL = process.env.REACT_APP_PGS_SERVER_STOCK_MANAGEMENT;
const pgsService = axios.create({
  baseURL,
});

/*------------ stock Indent functions starts----------------*/

pgsService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getStockIndentList = async () => {
  let StockoutList = [];
  await pgsService
    .get("/StockIntents")
    .then((r) => {
      StockoutList = r;
    })
    .catch((e) => {});
  return StockoutList;
};

export const getStockIndentData = async (stockIndentID) => {
  let list = [];
  await pgsService
    .get(`/StockIntents/${stockIndentID}`)
    .then((response) => {
      list = response.data;
    })
    .catch((e) => {});
  return list;
};
export const getFliteredStockIndentList = async (reqBody) => {
  let StockoutList = [];

  await pgsService
    .post("/StockIndentsAdvanceSearch", reqBody)
    .then((r) => {
      StockoutList = r.data;
    })
    .catch((e) => {});
  return StockoutList;
};

export const postStockIndent = async (stockIndentID, reqBody, history) => {
  await pgsService
    .put(`/StockIntents/${stockIndentID}`, reqBody)
    .then((r) => {
      history.push("/stockIndent");
      toastr.success("StockIndent Updated Successfully");
    })
    .catch((e) => {
      if (e.respone && e.response.data.errors[0].message !== undefined) {
        toastr.error(e.response.data.errors[0].message);
      } else if (e.response.data.errors[0]) {
        toastr.error(e.response.data.errors[0]);
      }
    });
};

export const getStockIndentsBQList = async (reqBody) => {
  let StockInList = [];

  await pgsService
    .post("/StockIndentsBQAdvanceSerach", reqBody)
    .then((r) => {
      StockInList = r;
    })
    .catch((e) => {});
  return StockInList;
};

export const getStockIndentsFilterList = async (reqBody) => {
  let StockInList = [];
  await pgsService
    .get("/GetDropdownIndentStatus", reqBody)
    .then((r) => {
      console.log(r, "r");
      StockInList = r;
    })
    .catch((e) => {});
  return StockInList;
};

export const getCustomerFromItemMaster = async () => {
  let customerList = [];
  await pgsService
    .get("/ItemMasters")
    .then((r) => {
      customerList = r;
    })
    .catch((e) => {});
  return customerList;
};

export const deleteStockIndent = async (deletionIds) => {
  let jobScheduleData = {};
  let modifiedBy = localStorage.loggedInId;
  await pgsService
    .delete(`/StockIntents/${deletionIds}`)
    .then((response) => {
      toastr.success("Stock Indent Cancelled Successfully");
    })
    .catch((e) => {});
  return jobScheduleData;
};
/**---stock Indent ends */
